import { useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Table } from "antd";

// Import Components:
import OnTraccrTextInput from "../../common/inputs/OnTraccrTextInput";
import BorderlessButton from "../../common/buttons/BorderlessButton";
import OnTraccrButton from "../../common/buttons/OnTraccrButton";
import CustomConfirmModal from "../../common/modals/CustomConfirmModal";
import CompanySettingsCard from "../CompanySettingsCard";
import SettingsCardHeader from "../SettingsCardHeader";
import SimpleTextInputModal from "../../common/modals/SimpleTextInputModal";

// Import Actions:
import { 
  createGlobalMaterialLocation,
  updateGlobalMaterialLocation,
  deleteGlobalMaterialLocation,
 } from "../../globalMaterialLocations/state/globalMaterialLocations.actions";

// Import Helpers:
import { getIdMap } from "../../helpers/helpers";

// Import Constants:
import colors from "../../constants/Colors";

const headerStyles = () => {
  return {
    style: {
      backgroundColor: "white"
    }
  }
}

// Helpers:
// returns true if the location entry is the edit location, false otherwise:
const getIsEdit = (editLocation, locationEntry) => editLocation.id && (editLocation.id === locationEntry.id);

const Locations = () => {
  const dispatch = useDispatch();
  const globalMaterialLocations = useSelector((state) => state.globalMaterialLocations.globalMaterialLocations);

  const [editLocation, setEditLocation] = useState({});
  const [editInput, setEditInput] = useState("");
  const [modalOpen, setModalOpen] = useState(false);

  const globalMaterialLocationsMap = getIdMap(globalMaterialLocations);

  const addNewLocation = async (locationText) => {
    if (await dispatch(createGlobalMaterialLocation(locationText))) {
      setModalOpen(false);
    }
  }

  const onEditStarted = (id = "") => {
    if (globalMaterialLocationsMap[id]){
      const locationToEdit = globalMaterialLocationsMap[id];
      setEditLocation(locationToEdit);
      setEditInput(locationToEdit.locationText);
    }
  }

  const onEditCancel = () => {
    setEditInput("");
    setEditLocation({});
  }

  const onSaveClicked = async (id = "") => {
    if (await dispatch(updateGlobalMaterialLocation(id, editInput))) {
      setEditInput("");
      setEditLocation({});
    }
  }

  const promptForDelete = (id) => {
    CustomConfirmModal({
      title: `Materials placed at this location will be removed. Would you like to proceed?`,
      okText: `Delete`,
      cancelText: `Cancel`,
      onOk: () => dispatch(deleteGlobalMaterialLocation(id)),
    });
  }

  const columns = useMemo(() => [{
    title: 'Name',
    dataIndex: 'locationText',
    headerStyles,
    render:(text,locationEntry) => {
      const isEdit = getIsEdit(editLocation, locationEntry);
      if(isEdit) return (
        <Row align='middle'>
          <OnTraccrTextInput
            value={editInput}
            onChange={(e) => setEditInput(e.target.value)}
          />
        </Row>
      )
      return text;
    }
  },
  {
    dataIndex:'edit',
    align:'edit',
    headerStyles,
    width: 60,
    render:(_,locationEntry) => {
      const isEdit = getIsEdit(editLocation, locationEntry);
      const isDefault = locationEntry.locationText === 'DEFAULT';
      const icon = isEdit ? "close" : "edit";
      const onClickHandler = () => isEdit ? onEditCancel() : onEditStarted(locationEntry.id);
      // Do not give option to edit/modify default globalMaterialLocation
      if (isDefault) return null;
      return (
        <BorderlessButton
          title=''
          icon={icon}
          color={colors.ONTRACCR_BLACK}
          onClick={onClickHandler}
          style={{
            paddingRight:8, 
            paddingLeft:0,
          }}
        />
      )
    }
  },
  {
    dataIndex:'action',
    key:'action',
    align:'center',
    width: 60,
    headerStyles,
    render:(_,locationEntry) => {
      const isEdit = getIsEdit(editLocation, locationEntry);
      const isDefault = locationEntry.locationText === 'DEFAULT';
      const icon = isEdit ? 'check' : 'delete'
      const color = isEdit ? colors.ONTRACCR_BLACK: colors.ONTRACCR_RED
      const onClickHandler = () => isEdit ? onSaveClicked(locationEntry.id) : promptForDelete(locationEntry.id);
      // Do not give option to edit/modify default globalMaterialLocation
      if (isDefault) return null;
      return (
        <BorderlessButton
          title=''
          icon={icon}
          color={color}
          onClick={onClickHandler}
          style={{
            paddingRight:8, 
            paddingLeft:0,
          }}
        />
      )
    }
  }],[dispatch, promptForDelete, onSaveClicked, onEditStarted, onEditCancel, setEditInput, editInput, editLocation]);

  return (
    <Row id='divisions-container' style={{
      width: '100%',
      height: '100%',
      marginLeft: 180,
      marginTop: 30
    }}>
      <SettingsCardHeader title='Material Locations'>
        <OnTraccrButton
          title='Add'
          type='primary'
          onClick={() => setModalOpen(true)}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        style={{
          position:'absolute',
          top: 130,
          bottom: 20,
          padding: 0,
          height:'auto',
        }}
      >
        <Table
          columns={columns}
          dataSource={globalMaterialLocations}
          pagination={false}
          size='small'
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
        />
      </CompanySettingsCard>
      <SimpleTextInputModal
        title='Add Location'
        visible={modalOpen}
        placeholder='Enter Location name'
        onClose={() => setModalOpen(false)}
        onSave={addNewLocation}
      />
    </Row>
);
}

export default Locations;