import React, { useCallback } from 'react';
import { Typography } from 'antd';
import PropTypes from 'prop-types';

import colors from '../../constants/Colors';

function DisplayText({
  id,
  title,
  style,
  ellipsis,
  supportMultiLine,
}) {
  const body = useCallback((value) => (
    <Typography.Paragraph
      id={id}
      ellipsis={ellipsis}
      style={{
        width: '100%',
        fontFamily: 'roboto-light',
        fontSize: 12,
        color: colors.ONTRACCR_BLACK,
        ...style,
      }}
    >
      {value}
    </Typography.Paragraph>
  ), [id, ellipsis, style]);
  if (!supportMultiLine || !title) return body(title);
  return title.split('\n').map((text) => (
    text === ''
      ? <br />
      : body(text)
  ));
}

DisplayText.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  ellipsis: PropTypes.bool,
  supportMultiLine: PropTypes.bool,
};

DisplayText.defaultProps = {
  style: {},
  title: null,
  ellipsis: false,
  supportMultiLine: false,
};

export default DisplayText;
