import React, {
  useCallback, useMemo, useRef, useState, useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Dropdown, Menu, Input,
} from 'antd';

import {
  CUSTOM_EXPORT_PROP_TYPE,
} from '../../settings/Exports/exports.constants';

import { includesTerm } from '../../helpers/helpers';

function ExportSelector({
  selectedExport,
  onExportSelected,
}) {
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const exportList = useSelector((state) => state.reports.customExports);

  const inputRef = useRef(null);
  const [searchTerm, setSearchTerm] = useState();
  const [open, setOpen] = useState(false);

  const onClick = useCallback(({ key: exportId }) => {
    onExportSelected(exportId);
  }, [onExportSelected]);

  const filteredExports = useMemo(() => (
    exportList.filter((ex) => (
      selectedDivisions.has(ex.divisionId)
      && ex.title
      && includesTerm(ex.title, searchTerm)
    ))
  ), [exportList, selectedDivisions, searchTerm]);

  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value: searchText,
      } = {},
    } = e;
    setSearchTerm(searchText);
  }, []);

  // Auto focus search upon opening
  useEffect(() => {
    if (open && inputRef && inputRef.current) {
      // Don't like this, but couldn't get it to work without timeout
      // We do similar in PDFDesignerItem
      setTimeout(inputRef.current.focus, 200);
    }
  }, [open, inputRef]);

  return (
    <span>
      <Dropdown
        trigger={['click']}
        onVisibleChange={setOpen}
        overlay={(
          <Menu style={{ maxHeight: 500, overflow: 'scroll', paddingTop: 0 }}>
            <Menu.Item
              disabled
              style={{
                cursor: 'default',
                position: 'sticky',
                top: 0,
                backgroundColor: 'white',
                borderBottom: '1px lightgray solid',
                zIndex: 1000,
                paddingTop: 4,
              }}
            >
              <Input.Search
                key="Search"
                type="search"
                className="searchbar"
                placeholder="Search"
                allowClear
                onChange={onSearchChange}
                ref={inputRef}
              />
            </Menu.Item>
            {filteredExports.map((customExport) => {
              const { id: exportId } = customExport;
              const style = {};
              if (selectedExport && exportId === selectedExport.id) style.fontWeight = 'bold';
              return (
                <Menu.Item
                  key={exportId}
                  style={style}
                  onClick={onClick}
                >
                  {customExport.title}
                </Menu.Item>
              );
            })}
          </Menu>
      )}
      >
        <span id="board-crumb-button">
          {selectedExport ? selectedExport.title : 'Custom Export'}
        </span>
      </Dropdown>
    </span>
  );
}

ExportSelector.propTypes = {
  selectedExport: CUSTOM_EXPORT_PROP_TYPE,
  onExportSelected: PropTypes.func.isRequired,
};

ExportSelector.defaultProps = {
  selectedExport: null,
};

export default ExportSelector;
