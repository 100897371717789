import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Badge, Divider } from 'antd';
import { DeleteOutlined, NotificationOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import HoverHelp from '../../common/HoverHelp';
import ProfileCircle from '../../common/profile/ProfileCircle';
import MoreButton from '../../common/buttons/MoreButton';

import Permissions from '../../auth/Permissions';
import colors from '../../constants/Colors';
import { deletePost } from './state/livefeed.actions';
import { initialsFromName } from '../../helpers/users';

const UNREAD_DOT_SIZE = 14;

const formatTimeDiff = (num,mod) => `${num} ${mod}${num === 1 ? '' : 's'} ago`;

const units = ['month','day','hour','minute','second'];
const getTimeDiff = (date,) => {
  for (let i = 0;i < units.length; i += 1) {
    const unit = units[i];
    const val = Math.floor(DateTime.local().diff(date,unit).get(unit));
    if(val >= 1) return formatTimeDiff(val,unit);
  }
  return 'Right now';
};


export default ({
  id,
  type,
  children,
  unread,
  customIcon,
  user = {},
  action,
  date = DateTime.local(),
  isCurrentUser,
  hideDivider = false,
  extra,
  reverse,
  hideIcon = false,
  isBroadcast,
  channelId,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    id: userId = '',
    name = '',
  } = user;
  const initials = initialsFromName(name);
  const justify = reverse ? 'end' : 'start';
  const showDeleteOption = Permissions.has('POSTS_EDIT') && Permissions.id === userId && type === 'POST';

  const onDeleteClick = useCallback(() => {
    dispatch(deletePost(channelId, id));
  }, [id, channelId]);

  return (
    <Row style={{ width: '100%'}}>
      <Row style={{
        padding: 20,
        width: '100%',
        flexDirection: reverse ? 'row-reverse' : 'row'
      }}>
        {!hideIcon && <Col flex="50px">
          {customIcon || <ProfileCircle
            initials={initials}
          />}
        </Col>}
        <Col flex="auto">
          <Row justify={justify} align="middle" gutter={10 }>
            <Col>
              <span style={{ fontFamily: 'roboto-bold'}}>{isCurrentUser ? 'You' : name}</span> {action}
            </Col>
            {
              isBroadcast
                ? <Col>
                    <HoverHelp
                      Icon={NotificationOutlined}
                      content={`This message was broadcasts to all ${t('Customer', { count: 2 })}`}
                      placement={reverse ? 'topRight' : 'topLeft'}
                    />
                </Col>
                : null
            }
          </Row>
          <Row style={{ fontSize: 10, color: colors.ONTRACCR_OPACITY_GRAY}} justify={justify}>
            {getTimeDiff(date)}
          </Row>
          {extra &&
            <Row style={{ width:'100%'}} justify={justify}>
              {extra}
            </Row>
          }
        </Col>
        <Col flex={children ? 'auto' : '0px'}>
          <Row justify="center" style={{ width: '100%'}}>
            {children}
          </Row>
        </Col>
        <Col flex="50px">
          <Row align="top" justify="center" style={{ height: '100%' }}>
            <Badge
              offset={[-5, 15]}
              dot={unread}
              style={{ width: UNREAD_DOT_SIZE, height: UNREAD_DOT_SIZE, backgroundColor: colors.ONTRACCR_RED }}
            />
            {showDeleteOption && <MoreButton
              placement= "bottomLeft"
              options={[
                {
                  title: 'Delete',
                  style: { color: colors.ONTRACCR_RED },
                  onClick: onDeleteClick,
                  iconNode: <DeleteOutlined style={{ color: colors.ONTRACCR_RED }}/>,
                },
              ]}
            />}
          </Row>
        </Col>
      </Row>
      {!hideDivider && <Divider style={{
        backgroundColor:colors.LIGHT_GRAY,
        margin: '0px 20px',
        width:'calc(100% - 40px)',
        minWidth:'calc(100% - 40px)',
      }}/>}
    </Row>
  );
}
