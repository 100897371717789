import React, { useState, useCallback } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import SimpleTextInputModal from '../common/modals/SimpleTextInputModal';

import BoardStatusCard from './BoardStatusCard';
import BoardStatusDeleteModal from './BoardStatusDeleteModal';
import BoardStatusPreviewConfigureDrawer from './BoardStatusPreviewConfigureDrawer';

function BoardStatusConfigure({
  value: statuses = [],
  onChange,
  cardTypeId,
  isTemplate
}) {
  const [selectedStatus, setSelectedStatus] = useState();
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [configureDrawerVisible, setConfigureDrawerVisible] = useState(false);

  const {
    id,
    title,
    displayFields = [],
  } = selectedStatus || {};

  const onDeleteInner = useCallback((deleteId) => {
    const newStatuses = statuses.filter((status) => status.id !== deleteId);
    onChange(newStatuses);
  }, [statuses, onChange]);

  const showDeleteModal = useCallback((status) => () => {
    setSelectedStatus(status);
    if (isTemplate) {
      onDeleteInner(status.id);
    } else {
      setDeleteModalVisible(true);
    }
  }, [isTemplate, onDeleteInner]);
  const hideDeleteModal = useCallback(() => {
    setSelectedStatus();
    setDeleteModalVisible(false);
  }, []);

  const showAddModal = useCallback(() => setAddModalVisible(true),[]);
  const hideAddModal = useCallback(() => setAddModalVisible(false),[]);

  const hideConfigureDrawer = useCallback(() => {
    setSelectedStatus();
    setConfigureDrawerVisible(false);
  }, []);
  const onSettingsClicked = useCallback((status) => () => {
    setSelectedStatus(status);
    setConfigureDrawerVisible(true);
  }, []);

  const onDelete = useCallback(() => {
    onDeleteInner(id);
    hideDeleteModal();
  }, [id, onDeleteInner, hideDeleteModal]);

  const onAdd = useCallback((newTitle) => {
    const newStatus = {
      id: `${newTitle}-${DateTime.local().toMillis()}`,
      title: newTitle,
      orderIndex: statuses.length,
      isNew: true,
    };
    onChange(statuses.concat(newStatus));
    hideAddModal();
  },[statuses, onChange, hideAddModal]);

  const onTitleChanged = useCallback(({ id: statusId, title: newTitle }) => {
    const newStatuses = statuses.map((status) => {
      if (status.id !== statusId) return status;
      return {
        ...status,
        title: newTitle,
      };
    });
    onChange(newStatuses);
  },[statuses, onChange]);

  const onStatusDisplayFieldsChanged = useCallback((newDisplayFields = new Set()) => {
    if (!id) return;
    const fieldList = Array.from(newDisplayFields);
    const newStatuses = statuses.map((status) => {
      if (status.id !== id) return status;
      return {
        ...status,
        displayFields: fieldList,
      };
    });
    onChange(newStatuses);
    hideConfigureDrawer();
  },[id, statuses, onChange, hideConfigureDrawer]);

  const onDragEnd = useCallback(({ destination, source, draggableId: statusId }) => {
    if (!destination) return;
    const {
      droppableId: destinationId,
      index: destinationIndex,
    } = destination;
    const {
      droppableId: sourceId,
      index: sourceIndex,
    } = source;
    if(
      destinationId === sourceId
      && destinationIndex === sourceIndex
      ) return;
    const newStatuses = [...statuses];
    newStatuses.splice(sourceIndex,1);
    newStatuses.splice(destinationIndex,0,statuses[sourceIndex]);
    const indexedNew = newStatuses.map((status, idx) => ({
      ...status,
      orderIndex: idx,
    }));
    onChange(indexedNew);
  },[statuses, onChange]);

  return (
    <>
      <DragDropContext onDragEnd={onDragEnd}>
        <OnTraccrButton title='Add' icon={<PlusOutlined/>} onClick={showAddModal} id='board-status-add-button'/>
        <Droppable droppableId='parent'>
          {(provided) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
            {
              statuses.map((status) => (
                <BoardStatusCard
                  {...status}
                  showSettings={!!cardTypeId}
                  onDeleteClicked={showDeleteModal(status)}
                  onSettingsClicked={onSettingsClicked(status)}
                  onTitleChanged={onTitleChanged}
                  key={status.id}
                />
              ))
            }
            {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <BoardStatusDeleteModal
        id={id}
        title={title}
        visible={deleteModalVisible}
        onClose={hideDeleteModal}
        onDelete={onDelete}
      />
      <SimpleTextInputModal
        title='Add Status'
        visible={addModalVisible}
        onClose={hideAddModal}
        onSave={onAdd}
      />
      {cardTypeId && (
        <BoardStatusPreviewConfigureDrawer
          title={title}
          displayFields={displayFields}
          cardTypeId={cardTypeId}
          visible={configureDrawerVisible}
          onClose={hideConfigureDrawer}
          onSubmit={onStatusDisplayFieldsChanged}
        />
      )}
    </>
  )
}

export default BoardStatusConfigure;
