import React, { useState, useCallback, useMemo } from 'react';
import { Table, Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import { PropTypes } from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import BoardWorkflowAddDrawer from './BoardWorkflowAddDrawer';

import { settingsColumns } from './boardHelpers';

export default function BoardWorkflows({
  value = [],
  onChange,
  cardTypeId,
  currentStatuses = [],
}) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState();

  const showDrawer = useCallback(() => setDrawerVisible(true), []);
  const hideDrawer = useCallback(() => {
    setDrawerVisible(false);
    setSelectedWorkflow();
  }, []);

  const onWorkflowSave = useCallback((newWorklow) => {
    if (!selectedWorkflow) {
      onChange(value.concat(newWorklow));
    } else {
      onChange(
        value.map((val) => {
          if (val.id !== selectedWorkflow.id) return val;
          return {
            ...val,
            ...newWorklow,
          };
        }),
      );
    }
    hideDrawer();
  }, [value, selectedWorkflow, hideDrawer]);

  const onEdit = useCallback((record) => () => {
    setSelectedWorkflow(record);
    setDrawerVisible(true);
  }, [value, onChange]);

  const onDelete = useCallback((record) => () => {
    const { id } = record;
    onChange(value.filter((val) => val.id !== id));
  }, [value, onChange]);

  const columns = useMemo(settingsColumns(onEdit, onDelete), [onEdit, onDelete]);

  return (
    <>
      <div>
        <Row style={{ marginTop: 10 }}>
          <OnTraccrButton title="Add" icon={<PlusOutlined />} onClick={showDrawer} />
        </Row>
        <div className="file-list-container" style={{ marginTop: 15 }}>
          <Table
            dataSource={value}
            columns={columns}
            size="small"
            pagination={false}
            scroll
          />
        </div>
      </div>
      <BoardWorkflowAddDrawer
        selectedWorkflow={selectedWorkflow}
        visible={drawerVisible}
        onClose={hideDrawer}
        onSubmit={onWorkflowSave}
        cardTypeId={cardTypeId}
        currentStatuses={currentStatuses}
      />
    </>
  );
}

BoardWorkflows.propTypes = {
  value: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func.isRequired,
  cardTypeId: PropTypes.string.isRequired,
  currentStatuses: PropTypes.array, // eslint-disable-line react/forbid-prop-types
};

BoardWorkflows.defaultProps = {
  value: [],
  currentStatuses: [],
};
