import axios from 'axios';
import { message } from 'antd';
import { request } from '../../helpers/requests';

const NotificationsService = {
  async getAll() {
    try {
      const { data } = await axios.get('/notifications');
      return data;
    } catch (err) {
      message.error('Could not retrieve notifications');
      return [];
    }
  },

  async create(notification) {
    try {
      const { data } = await axios.post('/notifications', notification);
      message.success('Notification sucessfully created.');
      return data;
    } catch (err) {
      message.error('Could not create notification');
      return [];
    }
  },
  delete: (id) => request({
    call: axios.delete(`/notification/${id}`),
    errMsg: 'Failed to delete notification',
    successMsg: 'Notification deleted successfully',
    hideSuccessToast: true,
  }),
  markAsRead: (id) => request({
    call: axios.put(`/notification/read/${id}`),
    errMsg: 'Failed to mark notification as read',
    successMsg: 'Notification updated successfully',
    hideSuccessToast: true,
  }),
};

export default NotificationsService;
