import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, TimePicker, Checkbox,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import DisplayText from '../common/text/DisplayText';

import Colors from '../constants/Colors';

import {
  updatePositionWorkingHours,
} from './state/settings.actions';

function WorkingHours({
  roleName,
}) {
  const dispatch = useDispatch();

  const workingHours = useSelector((state) => state.settings.workingHours);
  const myHours = workingHours?.[roleName] ?? {};
  const hasNoHours = !myHours.startTime || !myHours.endTime;

  const onCheckChanged = useCallback((e) => {
    if (hasNoHours) return;
    const newChecked = e?.target?.checked;

    const payload = {
      startTime: myHours.startTime,
      endTime: myHours.endTime,
      allowOutside: newChecked,
    };

    dispatch(updatePositionWorkingHours(roleName, payload));
  }, [roleName, myHours, hasNoHours]);

  const onTimesChanged = useCallback((key) => (momentInstance) => {
    dispatch(updatePositionWorkingHours(roleName, {
      startTime: myHours.startTime,
      endTime: myHours.endTime,
      [key]: momentInstance?.format('HH:mm') ?? null,
      allowOutside: !!myHours.allowOutside,
    }));
  }, [roleName, myHours]);

  return (
    <div style={{ margin: '0px 10px 10px 10px' }}>
      <Row gutter={10}>
        <Col>
          <TimePicker
            use12Hours
            format="hh:mm a"
            onChange={onTimesChanged('startTime')}
            value={myHours.startTime ? moment(`2000-01-01 ${myHours.startTime}`) : null}
            showNow={false}
          />
        </Col>
        <Col>
          <TimePicker
            use12Hours
            format="hh:mm a"
            onChange={onTimesChanged('endTime')}
            value={myHours.endTime ? moment(`2000-01-01 ${myHours.endTime}`) : null}
            showNow={false}
          />
        </Col>
      </Row>
      <Row style={{ width: '100%', marginTop: 5 }} gutter={10} align="middle">
        <Col>
          <DisplayText
            style={{
              fontFamily: 'roboto-regular',
              marginBottom: 0,
              color: Colors.ONTRACCR_OPACITY_GRAY,
            }}
            title="Allowing clocking outside of working hours"
          />
        </Col>
        <Col>
          <Checkbox
            checked={myHours.allowOutside}
            disabled={hasNoHours}
            onChange={onCheckChanged}
          />
        </Col>
      </Row>
    </div>
  );
}

WorkingHours.propTypes = {
  roleName: PropTypes.string.isRequired,
};

export default WorkingHours;
