import React from 'react';

import FormTemplates from '../forms/FormTemplates';

export default ({
  projectId,
}) => {

  return (
    <div className='project-form-template-container'>
      <FormTemplates projectId={projectId}/>
    </div>
  );
}