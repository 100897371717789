export default class Filter {
  constructor({
    defaultFilters,
    data,
    filterManipulators = {},
  }) {
    this.filterManipulators = filterManipulators;
    this.data = data;

    this.activeFilters = {};
    this.defaultFilters = {};
    // Deep Copy default Filters
    Object.keys(defaultFilters).forEach((key) => {
      this.activeFilters[key] = new Set([...defaultFilters[key]]);
      this.defaultFilters[key] = new Set([...defaultFilters[key]]);
    });
  }

  setFilter(checkedFilters, filterCategory) {
    const realFilters = checkedFilters.filter((filterName) => filterName !== filterCategory);
    this.activeFilters[filterCategory] = new Set(realFilters);
  }

  applyFilters() {
    return this.data.filter((item) => Object.keys(this.activeFilters)
      .every((key) => {
        if (!this.activeFilters) return false;
        let itemData = item[key];
        if (key in this.filterManipulators) {
          itemData = this.filterManipulators[key](item);
        }
        return this.activeFilters[key].has(itemData);
      }));
  }

  filterIsAtDefault() {
    return Object.keys(this.defaultFilters).every((key) => {
      /*
        Find if active filter set matches default. Inefficient, but
        filter set should be small.
      */
      const difference = new Set(this.activeFilters[key]);
      this.defaultFilters[key].forEach((elem) => {
        if (difference.has(elem)) {
          difference.delete(elem);
        } else {
          difference.add(elem);
        }
      });
      return difference.size === 0;
    });
  }
}
