export const validFieldTypes = new Set([
  'text',
  'attribute',
  'dropdown',
  'attachment',
  'yes-no',
]);

export const validTableTypes = new Set([
  'Materials',
  'Equipment',
  'Custom',
  'TimeEntry',
]);

export const isFieldValid = (field, customTables = {}) => {
  if (!field) return false;

  const {
    selectedType,
    configProps: {
      dataType,
    } = {},
  } = field;

  // We only allow certain field types to be used as a source
  // and certain table data types to be used as a source
  return validFieldTypes.has(selectedType) || (
    selectedType === 'table' && (validTableTypes.has(dataType) || dataType in customTables)
  );
};
