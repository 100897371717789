import axios from 'axios';

import { request } from '../../../../helpers/requests';

export default {
  getDivisions: () => request({
    call: axios.get('/eclipse/data/divisions'),
    errMsg: 'Could not get Eclipse divisions',
    hideSuccessToast: true,
  }),
  linkDivision: ({ eclipseId, name, divisionId }) => request({
    call: axios.post('/eclipse/division/link', { eclipseId, divisionId }),
    errMsg: `Could not link ${name}`,
    successMsg: `Successfully link ${name}`,
  }),
  importDivision: ({ eclipseId, name }) => request({
    call: axios.post('/eclipse/division/import', { eclipseId, name }),
    errMsg: `Could not import ${name}`,
    successMsg: `Successfully imported ${name}`,
  }),
  getEclipseWorkflows: () => request({
    call: axios.get('/eclipse/workflows'),
    errMsg: 'Could not get Eclpise workflows',
    hideSuccessToast: true,
  }),
  createEclipseWorkflow: (payload) => request({
    call: axios.post('/eclipse/workflow', payload),
    errMsg: 'Could not create Eclpise workflow',
  }),
  updateEclipseWorkflow: (id, payload) => request({
    call: axios.put(`/eclipse/workflow/${id}`, payload),
    errMsg: 'Could not get Eclpise workflows',
  }),
  deleteEclipseWorkflow: (id) => request({
    call: axios.delete(`/eclipse/workflow/${id}`),
    errMsg: 'Could not delete Eclpise workflow',
  }),
};
