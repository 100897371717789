import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import budgetTrackingEnabled from '../../assets/images/NUX/budgetTrackingEnabled.png';
import addEstimates from '../../assets/images/NUX/addEstimates.png';
import createPhases from '../../assets/images/NUX/createPhases.png';

const getStartDesc = (t) => `To enable budget tracking for the ${t('Project').toLowerCase()},
start by checking the 'Track Budget?' box.`;

const getAddingDesc = (t) => (
<div style={{ fontSize: 13 }}>
Define your total estimated labour hours and labour costs for the {t('Project').toLowerCase()}.
These hours can then be allocated to each of the unphased cost codes as well
as any phased cost codes.
</div>
);

const getPhasedDesc = (t) => (
<div style={{ fontSize: 13 }}>
Create any desired phases for the {t('Project').toLowerCase()} and
add to them the cost codes you selected to be 'phased' in step 2.
Note that you have to create the phase first before you can see the available phased codes.
All remaining cost codes will be visible under 'Unphased Cost Codes'.
</div>);

export default function NuxBudgetTracking({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const startDesc = getStartDesc(t);
  const addingDesc = getAddingDesc(t);
  const phasedDesc = getPhasedDesc(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 74px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:'',
        description:startDesc,
        cols:[0,12,12],
        image:{
          src:budgetTrackingEnabled,
          style:{
            height:31,
            width:350,
          }
        },
      },{
        title:'Add Estimates',
        description:addingDesc,
        cols:[0,12,12],
        image:{
          src:addEstimates,
          style:{
            height:38,
            width:325,
          }
        },
      },{
        title:`Add ${t('Project')} Phases`,
        description:phasedDesc,
        cols:[0,18,6],
        image:{
          src:createPhases,
          style:{
            height:125,
            width:143,
          }
        },
      }]}
      tutorialLink={'PM2. Job Costing _ Budget Tracking.mp4'}
    />
  );
}
