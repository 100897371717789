import React, { useMemo, useState, forwardRef, useImperativeHandle, useCallback } from 'react';
import { Chart } from 'react-google-charts';
import { DateTime } from 'luxon';

import { downloadFile } from '../files/fileHelpers';

import {
  formatDate,
  getRowName,
  getDateRangeFromConfig,
} from './analytics.helpers';

import { getWeekStart } from '../schedule/scheduleHelpers';
import AnalyticsDetailDrawer from './AnalyticsDetailDrawer';

const OPTIONS = {
  curveType: 'linear',
  legend: { position: 'right' },
}

export default forwardRef(({
  fieldTitle = 'Data',
  analyticsConfig,
},ref) => {
  const {
    data = [],
    columns = [],
    dateRange: selectedRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    breakdown = [],
    chartType: configChartType,
    fieldId,
  } = analyticsConfig ?? {};

  const breakdownByColor = breakdown.includes('color');

  const [chart, setChart] = useState();
  const [drawerConfig, setDrawerConfig] = useState();

  useImperativeHandle(ref, () => ({
    export: (name) => {
      if (!chart) return;
     const image = chart.getChart().getImageURI();
     downloadFile({
      fileURL: image,
      fileDetails: { name },
    });
    }
  }), [fieldTitle, chart, data, breakdown]);

  const dateRange = useMemo(() => (
    getDateRangeFromConfig({ dateRange: selectedRange, datePreset })
  ),[selectedRange, datePreset]);

  const {
    matrix: chartData,
    ids: chartIds,
  } = useMemo(() => {
    const numLines = data.length;
    if (!dateRange) return [];
    const isPie = configChartType === 'pie';
    const numBuckets = numLines > 0 && data[0].values ? data[0].values.length : 0;
    const isWeek = dateBreakdown === 'week';
    let startDT = DateTime.fromMillis(dateRange[0].valueOf());
    if (isWeek) {
      startDT = getWeekStart(startDT);
    }
    const matrix = isPie
      ? new Array(numLines + 1).fill().map(() => new Array(2).fill())
      : new Array(numBuckets + 1).fill().map(() => new Array(numLines + 1).fill());

    const ids = isPie
      ? new Array(numLines + 1).fill().map(() => [])
      : new Array(numBuckets + 1).fill().map(() => new Array(numLines + 1).fill());

    matrix[0][0] = fieldTitle;
    if (isPie) {
      matrix[0][1] = 'Count';
    } else {
      for(let i = 0; i < numBuckets; i += 1) {
        if (dateBreakdown) {
          const dt = startDT.plus({ [dateBreakdown]: i });
          matrix[i+1][0] = formatDate(dt, dateBreakdown);
        } else {
          matrix[i+1][0] = columns[i];
        }
      }
    }
   
    data.forEach((line, idx) => {
      const { values = [], ids: lineIds = [] } = line;
      const rowName = getRowName({ breakdown, fieldTitle, record: line });
      if (isPie) {
        matrix[idx+1][0] = rowName;
        matrix[idx+1][1] = values[0] ?? 0;
        ids[idx + 1] = lineIds;
      } else {
        for(let j = 0; j < numBuckets; j += 1) {
          matrix[j + 1][idx + 1] = values[j];
        }
        matrix[0][idx+1] = rowName;
      }
    });
    return {
      matrix,
      ids,
    };
  },[fieldTitle, fieldId, data, columns, dateRange, dateBreakdown, fieldBreakdown, breakdown, configChartType]);

  const chartType = useMemo(() => {
    switch (configChartType) {
      case 'bar': return 'ColumnChart';
      case 'pie': return 'PieChart';
      case 'stackedLine': return 'SteppedAreaChart';
      case 'line':
      default:
        return 'LineChart';
    }
  }, [configChartType]);

  const options = useMemo(() => {
    const baseOpts = {...OPTIONS};
    if (configChartType === 'stackedLine') baseOpts.isStacked = 'relative';
    if (breakdownByColor && breakdown.length === 1) {
      baseOpts.series = {};
      data.forEach((datum, idx) => {
        if (datum.color) {
          if (datum.color === '#FFFFFFFF') {
            baseOpts.series[idx] = { color: 'lightgray', lineDashStyle: [12, 3] }
          } else {
            baseOpts.series[idx] = { color: datum.color.substring(0, 7) }
          }
        }
      });
    }
    return baseOpts;
  },[chartType, data, breakdown, breakdownByColor]);

  const onCloseDrawer = useCallback(() => setDrawerConfig(), []);

  const onPieClick = useCallback((targetID) => {
    const rowNum = parseInt(targetID?.split('#')?.pop(), 10);
    const label = chartData[rowNum + 1]?.[0];
    const relevantIds = chartIds[rowNum + 1];
    console.log({ label, rowNum, targetID, relevantIds })
    setDrawerConfig({
      title: label,
      ids: relevantIds,
    });
  }, [chartData, chartIds]);

  const chartEvents = useMemo(() => {
    const isPie = configChartType === 'pie';
    return [
      {
        eventName: 'ready',
        callback: ({ chartWrapper, google }) => {
          google.visualization.events.addListener(chartWrapper.getChart(), 'click', (e) => {
            const { targetID } = e ?? {};
            if (isPie) {
              onPieClick(targetID);
            }
          });
        },
      },
    ];
  }, [configChartType, onPieClick]);

  return (
    <>
      <Chart
        chartType={chartType}
        width='100%'
        height='100%'
        data={chartData}
        options={options}
        getChartWrapper={(c) => setChart(c)}
        chartEvents={chartEvents}
      />
      <AnalyticsDetailDrawer
        config={drawerConfig}
        onClose={onCloseDrawer}
        analyticsConfig={analyticsConfig}
      />
    </>
  )
});
