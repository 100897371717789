import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer, Form, Select,
} from 'antd';
import axios from 'axios';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import FormColorPicker from '../../../common/inputs/FormColorPicker';

import AuroraSolarFieldMappings from './AuroraSolarFieldMappings';

import {
  TITLE_FIELD,
} from '../../../boards/boardWorkflowConfig';

import { getBoards } from '../../../boards/state/boards.actions';

import sortByString from '../../../helpers/helpers';

const validateMappings = (_rule, value) => {
  if (!value?.cardTitle) return Promise.reject(new Error('Card Title is required'));
  return Promise.resolve();
};

function AuroraSolarWorkflowDrawer({
  visible,
  onClose,
  config,
  update,
}) {
  const title = 'Configure Aurora Solar Workflow';
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const boards = useSelector((state) => state.boards.boards);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const divisions = useSelector((state) => state.settings.divisions);

  const [formValues, setFormValues] = useState({});
  const [lastBoardId, setLastBoardId] = useState(config?.mappings?.targetBoardId ?? null);
  const [boardStatuses, setBoardStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  const {
    [formValues?.targetBoardId]: {
      cardTypeId,
    } = {},
  } = boards;

  const cardFields = useMemo(() => {
    const {
      [cardTypeId]: {
        fields: sections = [],
      } = {},
    } = cardTemplates;

    const formFields = sections.map((section) => {
      const { fields = [] } = section;
      return fields;
    }).flat();
    return [TITLE_FIELD].concat(formFields).map((field) => {
      const {
        configProps: { title: label } = {},
        selectedType,
        id,
      } = field;
      return { value: id, label, type: selectedType };
    });
  }, [cardTemplates, cardTypeId]);

  const boardList = useMemo(() => {
    const bl = Object.values(boards);
    bl.sort(sortByString('title'));
    return bl.map((board) => ({ value: board.id, label: board.title }));
  }, [boards]);

  const onSubmit = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      // no-op
    }
    if (!values) return;
    setLoading(true);

    const passed = await update({ mappings: values });

    setLoading(false);
    if (passed) onClose();
  }, [config, divisions, update, onClose]);

  const onValuesChange = useCallback((_, allValues) => {
    const {
      divisionId: oldDivisionId,
      targetBoardId: oldTargetBoardId,
    } = formValues ?? {};
    const {
      divisionId: newDivisionId,
      targetBoardId: newTargetBoardId,
    } = allValues ?? {};
    if (oldDivisionId && oldDivisionId !== newDivisionId) {
      // Reset data on division change
      const newValues = {
        title: allValues.title,
        type: allValues.type,
        divisionId: newDivisionId,
        triggerSalesRabbitStatusId: null,
        createProject: false,
        projectMappings: undefined,
        createCard: false,
        targetBoardId: null,
        cardMappings: undefined,
      };
      form.setFieldsValue(newValues);
      setFormValues(newValues);
      return;
    }
    if (oldTargetBoardId !== newTargetBoardId) {
      const newValues = {
        ...allValues,
        initialStatusId: null,
      };
      form.setFieldsValue(newValues);
      setFormValues(newValues);
    }
    setFormValues(allValues);
  }, [form, formValues]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
      setBoardStatuses([]);
      setFormValues({});
      setLastBoardId();
    } else {
      dispatch(getBoards());
    }
  }, [visible, form]);

  useEffect(() => {
    if (visible && config) {
      setFormValues(config.mappings);
      form.setFieldsValue(config.mappings);
    }
  }, [visible, config, form]);

  useEffect(() => {
    const getStatuses = async (boardId) => {
      try {
        const {
          data: {
            statuses: bStatuses = [],
          } = {},
        } = await axios.get(`/boards/${boardId}`);
        setBoardStatuses(
          bStatuses.map((status) => ({ label: status.title, value: status.id })),
        );
      } catch (err) {
        setBoardStatuses([]);
      }
    };

    if (formValues) {
      const currentBoardId = formValues?.targetBoardId;
      if (currentBoardId && currentBoardId !== lastBoardId) {
        getStatuses(currentBoardId);
      }
    }
  }, [formValues, lastBoardId]);

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      width={800}
      maskClosable={false}
      bodyStyle={{ padding: '0px 24px' }}
    >
      <Form
        form={form}
        onValuesChange={onValuesChange}
        layout="vertical"
        style={{ marginBottom: 50 }}
      >
        <Form.Item
          name="targetBoardId"
          label="Board"
          rules={[{ required: true, message: 'Board is required' }]}
          style={{ marginTop: 15 }}
        >
          <Select
            options={boardList}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="initialStatusId"
          label="Initial Status"
          rules={[{ required: true, message: 'Initial Status is required' }]}
          style={{ marginTop: 15 }}
        >
          <Select
            options={boardStatuses}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="color"
          label="Initial Color"
          rules={[{ required: true, message: 'Initial Color is required' }]}
          style={{ marginTop: 15 }}
        >
          <FormColorPicker isNotDisplay />
        </Form.Item>
        <Form.Item
          name="cardMappings"
          label="Card Mappings"
          rules={[{
            required: true, message: 'Card Mappings are required',
          }, {
            validator: validateMappings,
          }]}
          style={{ marginTop: 15 }}
        >
          <AuroraSolarFieldMappings
            targetFields={cardFields}
          />
        </Form.Item>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Drawer>
  );
}

AuroraSolarWorkflowDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  config: PropTypes.shape({
    id: PropTypes.string,
    mappings: PropTypes.shape({
      targetBoardId: PropTypes.string,
    }),
  }),
  update: PropTypes.func.isRequired,
};

AuroraSolarWorkflowDrawer.defaultProps = {
  config: null,
};

export default AuroraSolarWorkflowDrawer;
