import React from 'react';
import { Row, Typography, } from 'antd';

const { Text, } = Typography;

export default ({
    note,
    style = {}
  }) => (
<Row 
  justify='center'
  style={{
    borderRadius:5,
    backgroundColor: '#FFDC73',
    borderColor: 'black',
    color:'black',
    width: 'calc(100%-20px)',
    marginLeft:10,
    marginRight:10,
    transition: '0.8s',
    ...style,
  }}>
     <Text style={{ width: '100%', textAlign:'left', margin:3, padding: 5}}>
        {note}
      </Text>
  </Row> )