import React, { useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  Typography,
  Card,
  Tooltip,
} from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Common, TaskHelpers } from 'ontraccr-common';

import BasicTimer from './BasicTimer';

import colors from '../../constants/Colors';

const { Text } = Typography;
const titleStyle = {
  fontFamily: 'roboto-regular',
  fontSize: 8,
  color: colors.MODAL_COLOR,
  width: '100%',
};

const valueStyle = {
  fontFamily: 'raleway-semibold',
  fontSize: 10,
  color: colors.MODAL_COLOR,
  width: '100%',
};

const PROJECT_COSTCODE_DEFAULT = '--';
const getProjectText = ({
  t,
  task = {},
  projectIdMap,
}) => {
  const {
    projectId,
    serviceLocation,
  } = task;
  if (serviceLocation) {
    // Task is a service task
    return {
      header: 'Service Location',
      text: serviceLocation,
    };
  }
  return {
    header: t('Project'),
    text: projectIdMap[projectId]?.name ?? PROJECT_COSTCODE_DEFAULT,
  };
};

function ClockTimeCard({
  formattedDay = {},
  task,
  style,
  onClick,
  showClock = true,
  showTooltip = false,
}) {
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const projects = useSelector((state) => state.projects.projects);

  const costcodeIdMap = useMemo(() => Common.getIdMap(costcodes), [costcodes]);
  const projectIdMap = useMemo(() => Common.getIdMap(projects), [projects]);

  const [runtimeTooltip, setRuntimeTooltip] = useState();

  const { t } = useTranslation();
  const { serviceLocation } = task ?? {};
  const { header: projectHeader, text: projectText } = getProjectText({ t, task, projectIdMap });

  useEffect(() => {
    const {
      currentTask,
    } = formattedDay;
    if (showTooltip && currentTask?.type && TaskHelpers.getStartTime(currentTask)) {
      const {
        formattedCurrentTime,
      } = TaskHelpers.getClockTimes({ formattedDay });
      return setRuntimeTooltip(formattedCurrentTime);
    }

    return () => {
      setRuntimeTooltip();
    };
  }, [formattedDay, showTooltip]);

  return (
    <Tooltip title={runtimeTooltip}>
      <Card
        hoverable={onClick}
        onClick={onClick ? (e) => {
          e.stopPropagation();
          onClick();
        } : null}
        bodyStyle={{
          padding: 0,
        }}
        style={{
          borderRadius: 5,
          backgroundColor: colors.ONTRACCR_BLACK,
          padding: '3px 5px 3px 5px',
          color: 'white',
          ...style,
        }}
      >
        <Row justify="space-between">
          <Col span={serviceLocation ? 24 : 12}>
            <Row className="time-card-text">
              <Text style={titleStyle}>
                {projectHeader}
              </Text>
            </Row>
            <Row className="time-card-text">
              <Text ellipsis style={valueStyle}>
                {projectText}
              </Text>
            </Row>

          </Col>
          <Col span={serviceLocation ? 0 : 12} style={{ textAlign: 'right' }}>
            <Row className="time-card-text">
              <Text style={titleStyle}>
                Cost Code
              </Text>
            </Row>
            <Row className="time-card-text">
              <Text style={valueStyle}>
                {costcodeIdMap[task?.costcodeId]?.name ?? PROJECT_COSTCODE_DEFAULT}
              </Text>
            </Row>
          </Col>
        </Row>
        <Row justify="space-around" type="flex">
          {showClock
          && (
            <BasicTimer
              formattedDay={formattedDay}
              style={{
                fontSize: 16,
                width: '100%',
                textAlign: 'center',
                fontFamily: 'raleway-semibold',
                color: colors.MODAL_COLOR,
              }}
            />
          )}
        </Row>
      </Card>
    </Tooltip>
  );
}

/* eslint-disable react/forbid-prop-types */
ClockTimeCard.propTypes = {
  formattedDay: PropTypes.shape({
    currentTask: PropTypes.object,
    totalWorkTimeTodayInMS: PropTypes.number,
    totalCurrentTaskTimeInMS: PropTypes.number,
  }),
  task: PropTypes.shape({
    serviceLocation: PropTypes.string,
    costcodeId: PropTypes.string,
    projectId: PropTypes.string,
  }),
  style: PropTypes.object,
  onClick: PropTypes.func,
  showClock: PropTypes.bool,
  showTooltip: PropTypes.bool,
};

ClockTimeCard.defaultProps = {
  formattedDay: {},
  task: {},
  style: {},
  onClick: null,
  showClock: true,
  showTooltip: false,
};

export default ClockTimeCard;
