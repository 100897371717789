import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IntegrationUserSync from '../IntegrationUserSync';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Employees from Sage.
    <br/><br/>1. We will automatically set their Ontraccr username and password when importing and send this information to their email.
    <br/><br/>2. All users will be assigned the ‘Worker’ role after importing, make sure you change each user’s role afterwards as needed.
    <br/><br/>If you would rather see exactly which data will be imported from Sage and fill in any missing fields, disable Auto Import to do this manually.
  </div>
);

const importHelpText = (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new Employees from Sage
    or <b>Link</b> Sage Employees to existing Ontraccr Users
    <br/><br/> <b>Import:</b> User will be created in Ontraccr.
    <br/> <b>Link:</b> Select an existing Ontraccr User to link with the Sage Employees.
  </div>
);

export default function SageUserSync({
  importUsers,
  onUsersChanged,
  divisionFilter,
}) {
  const {
    positions,
    sageData: {
      employees: sageEmployees,
    } = {},
  } = useSelector((state) => state.settings);
  const users = useSelector((state) => state.users.users);
  return (
    <IntegrationUserSync
      title="Sage"
      integrationUsers={sageEmployees}
      integrationKey="sageId"
      users={users}
      positions={positions}
      onUsersChanged={onUsersChanged}
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      importUsers={importUsers}
      canAutoImport={false}
      divisionFilter={divisionFilter}
    />
  );
}

SageUserSync.propTypes = {
  importUsers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  onUsersChanged: PropTypes.func,
  divisionFilter: PropTypes.instanceOf(Set)
};

SageUserSync.defaultProps = {
  importUsers: [],
  onUsersChanged: () => {},
  divisionFilter: null,
};
