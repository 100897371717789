import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { withRouter, } from 'react-router-dom';
import {
  MailOutlined,
 } from '@ant-design/icons';
import { Popover, Row, Badge, Tabs, Spin } from 'antd';

import Debouncer from '../../../helpers/Debouncer';
import Permissions from '../../../auth/Permissions';

import config from '../../../config';

import NotificationItem from './NotificationItem';

import { deleteNotification, markAsRead } from '../../../notifications/state/notifications.actions';
import { 
  getFormApprovals,
  getAssignedForms,
  getDrafts,
 } from '../../../forms/state/forms.actions';
import { getIdMap } from '../../../helpers/helpers';

import { getMyCardTags } from '../../../boards/state/boards.actions';
import { initialsFromName } from '../../../helpers/users';

const CUSTOMER_ROUTE = '/contacts/customers';


const decorateTags = ({
  tags = [], userMap = {}, read
}) => (
  tags.map((tag) => {
    const { cardNoteAuthorId } = tag;
    const {
      [cardNoteAuthorId]: { name: author } = {},
    } = userMap;
    return { ...tag, author, read };
  })
);

const debouncer = new Debouncer();
const tabStyle = {
  height: 500,
  width: 400,
  overflowY: 'auto'
}
export const NotificationsDropdown = connect(
    (state,ownProps) => {
      return {
          ...ownProps,
          notifications:state.notifications.notifications,
          approvals:state.approvals.approvals,
          readCount:state.approvals.approvalCount + state.notifications.notifications.filter(n => !n.read).length,
          count:state.approvals.approvalCount + state.notifications.notifications.length,
          users:state.users.users,
          formApprovals:state.forms.approvals,
          cardTags: state.boards.myCardTags,
          unreadClientComms: state.customers.unreadClientComms,
      }
    },
    {
      deleteNotification,
      markAsRead,
      getFormApprovals,
      getAssignedForms,
      getDrafts
    }
  )(({
    notifications,
    approvals,
    count = 0,
    readCount,
    users,
    deleteNotification,
    markAsRead,
    formApprovals = {},
    getFormApprovals,
    getAssignedForms,
    getDrafts,
    cardTags = [],
    unreadClientComms = [],
  }) => {
    const dispatch = useDispatch();

    const myReadCardTags = useSelector(state => state.boards.myReadCardTags);

    const [visible,setVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const hasFormsPerms = Permissions.has('FORMS_READ');
    const shouldShowForms =  config.showForms  && hasFormsPerms;
    const formApprovalList = shouldShowForms ? Object.values(formApprovals) : [];
    const notificatonCount = count + formApprovalList.length + cardTags.length + unreadClientComms.length;

    const closeDropdown = useCallback(() => setVisible(false),[]);
    const onTabChanged = useCallback(async (tabKey) => {
      if (tabKey === 'read') {
        setLoading(true);
        await debouncer.debounce(() => {
          dispatch(getMyCardTags({ hasRead: true }))
        }, 500);
        setLoading(false);
      }
    },[dispatch]);
  
    const userMap = useMemo(() => getIdMap(users),[users]);
    const decoratedCardTags = useMemo(() => (
      decorateTags({ tags: cardTags, userMap, read: false })
    ),[cardTags, userMap]);
    const decoratedReadTags = useMemo(() => (
      decorateTags({ tags: myReadCardTags, userMap, read: true })
    ),[myReadCardTags, userMap]);
    let content = <i key="-">You don't have any notifications</i> ;
    if (notificatonCount > 0) {
      content = (
        <Tabs tabBarStyle={{ marginTop: -10 }} onChange={onTabChanged}>
          <Tabs.TabPane tab='Unread' key='unread' style={tabStyle}>
            <NotificationsList
              key={notifications.concat(approvals)} 
              notifications={notifications} 
              unreadClientComms={unreadClientComms}
              approvals={approvals}
              users={users} 
              deleteNotification={deleteNotification}
              markAsRead={markAsRead}
              formApprovalList={formApprovalList}
              decoratedCardTags={decoratedCardTags}
              onCloseDropdown={closeDropdown}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab='Read' key='read' style={tabStyle}>
            {
              loading
              ? <Row justify='center' align='middle'><Spin/></Row>
              : <NotificationsList
                key={notifications.concat(approvals)} 
                notifications={notifications} 
                users={users} 
                deleteNotification={deleteNotification}
                markAsRead={markAsRead}
                decoratedCardTags={decoratedReadTags}
                onCloseDropdown={closeDropdown}
              />

            }
          </Tabs.TabPane>
        </Tabs>
      );
    }

    useEffect(() => {
      if(getFormApprovals) getFormApprovals();
    },[getFormApprovals]);
    
    useEffect(() => {
      if(getAssignedForms) getAssignedForms();
    },[getAssignedForms]);

    useEffect(() => {
      if(getDrafts) getDrafts();
    },[getDrafts]);
    
    return (
      <Popover
        content={content}
        trigger='click'
        placement='bottomRight'
        visible={visible}
        onVisibleChange={setVisible}
      >
        <Badge count={notificatonCount || null} size='small'>
          <div className='header-container'>
            <MailOutlined/>
          </div>
        </Badge>
      </Popover>
      );
})


export const NotificationsList = withRouter(({
  users = [],
  notifications = [],
  unreadClientComms = [],
  approvals = [],
  history,
  deleteNotification,
  markAsRead,
  location: {
    pathname = '',
  } = {},
  formApprovalList = [],
  decoratedCardTags = [],
  onCloseDropdown,
}) => {
    let submissions = {};
    approvals.forEach(a => {
      if (submissions[a.userId]) {
          submissions[a.userId].push(a);
      } else {
          submissions[a.userId] = [a];
      }
    })
    
    let notificationList = notifications.map(n => (
      <Row style={{ minWidth: '30em' }} key={n.id+n.read}>
        <NotificationItem 
          title={n.message}
          onClick={n.pathname ? () =>  history.push({
            pathname: n.pathname,
            state: n.args || {}
          }) : null}
          onDelete={()=> {
            deleteNotification(n.id);
          }}
          onMouseEnter={()=> {
            if (!n.read) markAsRead(n.id)
          }}
          read={n.read}
          type={n.type}
          timestamp={n.timestamp}
        />
      </Row>
    ))

    notificationList = notificationList.concat(
      unreadClientComms.map((note) => 
        <NotificationItem 
          title={`${note.email} has sent you a message`}
          onClick={() => {
            if(pathname !== CUSTOMER_ROUTE) {
              history.push({
                pathname: CUSTOMER_ROUTE,
                state: { targetId: note.customerId }
              });
            }
          }}
          read
          timestamp={note.timestamp}
        />
      )
    )

    notificationList = notificationList.concat(
      formApprovalList.map((approval) => 
        <NotificationItem 
          title={`${approval.user} has submitted a ${approval.templateName} form`}
          onClick={() => {
            if(pathname !== '/forms/log') {
              history.push('/forms/log')
            }
          }}
          read
          type='submission'
          timestamp={approval.timestamp}
          initials={initialsFromName(approval.user)}
        />
      )
    )

    notificationList = notificationList.concat(
      decoratedCardTags.map((tag) => 
        <NotificationItem 
          title={`${tag.author} has tagged you in a note on ${tag.cardTitle} card on board ${tag.boardTitle}`}
          onClick={() => {
            history.push('/boards', { boardId: tag.boardId, cardId: tag.cardId, activeTab: 'notes' })
            onCloseDropdown();
          }}
          read={tag.read}
          type='submission'
          initials={initialsFromName(tag.author)}
          truncate={false}
        />
      )
    )

    return notificationList.concat(Object.entries(submissions).map((entry) => {
        const user = users.filter(u => u.id === entry[0])[0];
        if (!user) return <></>;
        const name = user.name;
        const initials = initialsFromName(name);
        const userSubmissionCount = entry[1].length;
        return (
          <Row style={{ minWidth: '30em' }} key={name}>
              <NotificationItem
              title={`${name} has submitted ${userSubmissionCount} ${userSubmissionCount > 1 ? 'tasks' : 'task'}`}
              onClick={() =>  history.push({
                pathname: '/timecards/team',
                state: { user: user.id, submissions: true }
              })}
              type={'submission'}
              initials={initials}
              />
          </Row>)
        }))
      });
