import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  Table,
  Row,
  Col,
  Spin,
} from 'antd';

import AutoImport from './IntegrationAutoImport';
import CanImportCol from './IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from './IntegrationIsConnectedCol';
import { createImportData, linkInitialData } from './integrationHelpers';

import sortByString, { getIdMap, includesTerm } from '../../helpers/helpers';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';

const { Panel } = Collapse;

const canImport = (divisionId, divisionList) => (record) => (
  record.name
  && (divisionId || record.linkValue || record.divisionId || divisionList.length === 1)
);

function IntegrationEquipmentSync({
  integrationKey,
  title,
  integrationEquipment = [],
  onEquipmentChanged,
  autoImportContent,
  importHelpText,
  importEquipment,
  divisionId,
  loading,
}) {
  const equipment = useSelector((state) => state.equipment.equipment);
  const divisions = useSelector((state) => state.settings.divisions);

  const [stateEquipment, setStateEquipment] = useState([]);
  const [activePanels, setActivePanels] = useState(['qb']);
  const [searchStr, setSearchStr] = useState();
  const [importList, setImportList] = useState(new Set());
  const [autoImport, setAutoImport] = useState(true);
  const [connected, setConnected] = useState({});

  const activeEquipment = useMemo(() => (
    equipment.filter((eq) => (
      (!divisionId || eq.divisionIds.includes(divisionId))
    ))
  ), [equipment, divisionId, searchStr]);

  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);

  const divisionList = useMemo(() => Object.values(divisions), [divisions]);

  const updateEquipment = (record) => {
    setStateEquipment(stateEquipment.map((user) => {
      if (user[integrationKey] !== record[integrationKey]) return user;
      return record;
    }));
  };

  const resetImportList = (list) => () => {
    setImportList(new Set(list.map((cc) => cc[integrationKey])));
  };

  useEffect(() => {
    setConnected(createConnectedArray(activeEquipment, integrationKey));
  }, [activeEquipment, integrationKey]);

  useEffect(() => {
    if (integrationEquipment.length) {
      setStateEquipment(linkInitialData({
        items: integrationEquipment, connected, integrationKey,
      }));
    }
    resetImportList(integrationEquipment)();
  }, [integrationEquipment, connected]);

  useEffect(() => {
    const importData = createImportData({
      integrationKey,
      newData: integrationEquipment,
      stateData: stateEquipment,
      originalData: activeEquipment,
      importList,
      canImport: canImport(divisionId, divisionList),
      connected,
      divisionList,
    });
    onEquipmentChanged(importData);
  }, [
    autoImport,
    importList,
    stateEquipment,
    activeEquipment,
    onEquipmentChanged,
    integrationEquipment,
    connected,
    integrationKey,
    divisionList,
    divisionId,
  ]);

  const getCols = (editable) => {
    const cols = [
      IsConnectedCol({
        integrationKey,
        title,
        connected,
        onUnLink: (record) => {
          const newConnected = { ...connected };
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if (editable) {
            record.linkValue = null;
            updateEquipment(record);
          } else {
            setStateEquipment(stateEquipment.map((cc) => {
              if (cc[integrationKey] !== record[integrationKey]) return cc;
              const newUser = { ...cc };
              delete newUser.linkValue;
              return newUser;
            }));
          }
        },
      }),
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: sortByString('name'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Division',
        dataIndex: 'divisionId',
        key: 'divisionId',
        align: 'center',
        fixed: 'left',
        width: 170,
        render: (_, record) => (
          <DivisionSelector
            key={record.id}
            displayMode={!editable}
            style={{ width: '100%' }}
            displayStyle={{ marginBottom: 0 }}
            onChange={(newDivisionId) => {
              if (newDivisionId !== record.divisionId) {
                record.divisionId = newDivisionId;
                updateEquipment(record);
              }
            }}
            divisionId={divisionId || record.divisionId}
            disabled={divisionId || record.viewType === 'link'}
          />
        ),
      },
    ];
    if (editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          title,
          connected,
          helpText: importHelpText,
          canImport: canImport(divisionId, divisionList),
          importList,
          setImportList,
          resetImportList: resetImportList(integrationEquipment),
          onViewTypeChanged: (record, viewType) => {
            record.viewType = viewType;
            updateEquipment(record);
          },
          linkOptions: activeEquipment,
          onLinkChanged: (record, linkValue) => {
            const newConnected = { ...connected };
            const oldLink = connected[record[integrationKey]];
            if (oldLink) delete oldLink[integrationKey];
            if (linkValue) {
              newConnected[record[integrationKey]] = equipmentMap[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
            } else {
              delete newConnected[record[integrationKey]];
            }

            setConnected(newConnected);

            record.linkValue = linkValue;
            updateEquipment(record);
          },
        }),
      );
    }
    return cols;
  };

  const activeStateEq = useMemo(() => (
    stateEquipment.filter((eq) => (
      includesTerm(eq.name, searchStr) || includesTerm(eq.code, searchStr)
    ))
  ), [stateEquipment, searchStr]);

  if (loading) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }

  return (
    <div style={{ height: 'calc(100% - 55px)', width: '100%', overflowY: 'scroll' }}>
      <AutoImport
        setActivePanels={setActivePanels}
        setAutoImport={setAutoImport}
        autoImport={autoImport}
        helpContent={autoImportContent}
        importType="Equipment"
        importData={importEquipment}
      />
      {!autoImport && (
      <OnTraccrTextInput
        allowClear
        style={{
          width: 250,
        }}
        search
        onChange={(e) => {
          const {
            target: {
              value,
            } = {},
          } = e;
          setSearchStr(value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
      )}
      {!autoImport && (
      <Collapse ghost activeKey={activePanels} onChange={setActivePanels}>
        <Panel
          header={`New ${title} Equipment`}
          key="new"
          className="ontraccr-collapse-panel"
        >
          <Table
            bordered
            size="small"
            columns={getCols(true)}
            dataSource={activeStateEq}
            scroll={{
              y: '40%',
              x: 1000,
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              showSizeChanger: false,
            }}
            rowKey={integrationKey}
          />
        </Panel>
        <Panel
          header="Ontraccr Equipment"
          key="ontraccr"
          className="ontraccr-collapse-panel"
        >
          <Table
            bordered
            size="small"
            columns={getCols(false)}
            dataSource={activeEquipment}
            scroll={{
              y: '40%',
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              showSizeChanger: false,
            }}
            rowKey="id"
          />
        </Panel>
      </Collapse>
      )}
    </div>
  );
}

export default IntegrationEquipmentSync;
