import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Switch,
  Input,
} from 'antd';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  LeftOutlined,
  RightOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DateTime } from 'luxon';
import { Payroll } from 'ontraccr-common';

import ReportDateSelector from './ReportDateSelector';
import ReportSageExport from './ReportSageExport';

import colors from '../../constants/Colors';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import MoreButton from '../../common/buttons/MoreButton';

import {
  getNextPayroll,
  findPayrollStartAndEndDates,
  getFirstPayrollDay,
} from '../../helpers/payroll';

import { getCompanyImageURL } from '../../settings/state/settings.actions';
import TimeTracking from '../../state/timeTracking';
import { getTimesFromTimeRange, getUsersFromSelectedDivisions } from '../../timecards/timecard.helpers';

const getRightSideWidth = ({ onDelete, isEmployeePayroll }) => {
  let width = 200;
  if (onDelete) width += 90;
  if (isEmployeePayroll) width += 170;
  return width;
}

const headerIconStyle = { fontSize: 20, marginLeft:0, color: 'black'  };
const HeaderButton = ({ icon, onClick }) => (
  <Row justify='center' align='middle'>
    <BorderlessButton
      style={{ width: 30,
        backgroundColor:colors.ONTRACCR_DARK_YELLOW,
        padding:0,
        borderRadius:4,
      }}
      iconNode={icon}
      onClick={onClick}
    />
  </Row>

);

export default ({
  title,
  onDateRangeChanged,
  onExport,
  onSave,
  onFullScreenToggle,
  isFullScreen,
  showDate = true,
  showSearch = false,
  showSave = true,
  dateRange = [moment().subtract(7,'d'), moment()],
  onDelete,
  onSearch,
  onRoundedChanged,
  isEmployeePayroll,
  isRounded: propisRounded,
  filters = {},
}) => {
  const dispatch = useDispatch();
  const {
    payPeriod,
    payPeriodDates,
    companyImageURL,
    semiMonthlyPayPeriodDates,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            payPeriod,
            payPeriodDates,
            semiMonthlyPayPeriodDates,
          } = {},
          companyImageURL = '',
        } = {},
      } = {},
    } = state;
    return {
      payPeriod,
      payPeriodDates,
      companyImageURL,
      semiMonthlyPayPeriodDates,
    };
  });
  const users = useSelector((state) => state.users.users);
  const divisions = useSelector((state) => state.settings.divisions);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  const [selectedType,setType] = useState('payPeriod');
  const [rangeValue,setRange] = useState(dateRange);
  const [fsDisable,setFSDisable] = useState(false);
  const [isRounded,setIsRounded] = useState(propisRounded);
  const [initialLoad, setInitialLoad] = useState(false);

  const firstDayOfPayroll = useMemo(() => (
    getFirstPayrollDay({ payPeriod, payPeriodDates, semiMonthlyPayPeriodDates })
  ), [payPeriod, payPeriodDates, semiMonthlyPayPeriodDates]);

  const isCustom = selectedType === 'custom';
  const [startMoment, endMoment] = dateRange ?? [];

  useEffect(() => {
    setRange(dateRange);
  },[dateRange]);

  useEffect(() => {
    setIsRounded(true);
  },[title]);

  useEffect(() => {
    if(onRoundedChanged) onRoundedChanged(isRounded);
  },[isRounded,onRoundedChanged]);

  useEffect(() => {
    dispatch(getCompanyImageURL());
  }, []);

  useEffect(() => {
    const [startMom, endMom] = rangeValue;
    const { startTime, endTime } = getTimesFromTimeRange([
      DateTime.fromMillis(startMom.valueOf()),
      DateTime.fromMillis(endMom.valueOf()),
    ]);
    const selectedDivUsers = getUsersFromSelectedDivisions({
      users,
      selectedDivisions,
      divisions,
    });

    dispatch(TimeTracking.getTimeEntries({
      startTime,
      endTime,
      getRunningTasks: true,
      userIds: selectedDivUsers.map((u) => u.id),
    }));
  }, [rangeValue, users, divisions, selectedDivisions]);

  useEffect(() => {
    if (
      initialLoad
      || !payPeriod
      || !firstDayOfPayroll
      || !startMoment
      || !moment.isMoment(startMoment)
    ) return;
    const [startDt,endDt] = findPayrollStartAndEndDates({
      payPeriod,
      payPeriodFirstDay: firstDayOfPayroll,
      startDay: DateTime.fromMillis(startMoment.valueOf()),
      semiMonthlyPayPeriodDates,
    });
    setInitialLoad(true);
    onDateRangeChanged([
      moment(startDt.toMillis()),
      moment(endDt.toMillis()),
    ]);
  },[initialLoad, payPeriod, firstDayOfPayroll, startMoment, semiMonthlyPayPeriodDates]);

  const FullScreenIcon = isFullScreen ? FullscreenExitOutlined : FullscreenOutlined;
  const rightSideWidth = getRightSideWidth({ onDelete, isEmployeePayroll });

  const onPrevious = useCallback(() => {
    if (!startMoment || !moment.isMoment(startMoment)) return;
    let newRange;
    if (selectedType === 'week') {
      newRange = [startMoment.clone().subtract(7, 'days'), startMoment.subtract(1, 'days')];
    } else {
      const startDay = getNextPayroll({
        payPeriod,
        firstDay: DateTime.fromMillis(startMoment.valueOf()),
        forward: false,
        semiMonthlyPayPeriodDates,
      });
      const endDay = startMoment.subtract(1, 'days').endOf('day');
      newRange = [moment(startDay.toMillis()), endDay];
    }
    onDateRangeChanged(newRange);
  },[startMoment, selectedType, payPeriod, semiMonthlyPayPeriodDates]);

  const onNext = useCallback(() => {
    if (!startMoment || !moment.isMoment(startMoment)) return;
    let newRange;
    if (selectedType === 'week') {
      newRange = [startMoment.clone().add(7, 'days'), startMoment.clone().add(14, 'days')];
    } else {
      const relevantMoment = payPeriod === 'Semi-Monthly'
        ? endMoment
        : startMoment;
      const startDay = getNextPayroll({
        payPeriod,
        firstDay: DateTime.fromMillis(relevantMoment.valueOf()),
        forward: true,
        semiMonthlyPayPeriodDates,
      });
      const endDay = payPeriod === 'Semi-Monthly'
      ? Payroll.findSemiMonthlyPayPeriod({
        startDay,
        semiMonthlyPayPeriodDates,
      })[1]
     : getNextPayroll({
        payPeriod,
        firstDay: startDay,
        forward: true,
        semiMonthlyPayPeriodDates,
      }).minus({ day: 1 }).endOf('day');
      newRange = [moment(startDay.toMillis()), moment(endDay.toMillis())];
    }
    onDateRangeChanged(newRange);
  },[startMoment, endMoment, selectedType, payPeriod, semiMonthlyPayPeriodDates]);

  const onChange = useCallback((newStart) => {
    if (selectedType === 'week') {
      return onDateRangeChanged([
        newStart.clone().startOf('week'),
        newStart.clone().endOf('week'),
      ]);
    }
    // Has to be pay period here. Custom range uses onRangeChange
    const dt = DateTime.fromMillis(newStart.valueOf());
    onDateRangeChanged(
      findPayrollStartAndEndDates({
        startDay: dt,
        payPeriodFirstDay: firstDayOfPayroll,
        payPeriod,
        semiMonthlyPayPeriodDates,
      }).map((d) => moment(d.toMillis()))
    );
  },[selectedType, payPeriod, firstDayOfPayroll, semiMonthlyPayPeriodDates]);

  const onTypeChange = useCallback((newType) => {
    setType(newType);
    let newRange;
    const now = moment();
    const endTime = endMoment.isAfter(now) ? now : endMoment;
    const startDay = DateTime.fromMillis(endTime.valueOf());
    if (newType === 'payPeriod') {
      newRange = findPayrollStartAndEndDates({
        startDay,
        payPeriodFirstDay: firstDayOfPayroll,
        payPeriod,
        semiMonthlyPayPeriodDates,
      });
    } else if (newType === 'week') {
      if (startDay.weekday === 7) {
        // 7 === Sunday.
        newRange = [
          startDay,
          startDay.plus({ days: 7 }),
        ];
      } else {
        newRange = [
          startDay.startOf('week').minus({ day: 1 }),
          startDay.endOf('week').minus({ day: 1 }),
        ];
      }

    }
    if(newRange) {
      onDateRangeChanged( newRange.map((dt) => moment(dt.toMillis())))
    }
  },[endMoment, firstDayOfPayroll, payPeriod, semiMonthlyPayPeriodDates]);

  const exportPDF = useCallback(() => onExport('pdf', companyImageURL), [companyImageURL]);
  const exportExcel = useCallback(() => onExport('xlsx'), []);
  const exportByProject = useCallback(() => onExport('byProject', companyImageURL), [companyImageURL]);

  const now = moment();

  return (
    <Row style={{width:'100%', height:32 }} justify='space-between' align='middle'>
      <Col style={{ width: `calc(100% - ${rightSideWidth}px)` }}>
        <Row justify='start' gutter={16} align='middle'>
          {showDate && !isCustom && <Col>
             <HeaderButton
              icon={<LeftOutlined style={headerIconStyle}/>}
              onClick={onPrevious}
            />
          </Col>}
          {showDate && <Col>
            <ReportDateSelector
              onTypeChange={onTypeChange}
              selectedType={selectedType}
              value={dateRange[0]}
              hideCustom={false}
              onChange={onChange}
              range={rangeValue}
              onRangeChange={(range) => {
                const realRange = range
                ? [range[0], range[1].endOf('day')]
                : range;
                setRange(realRange)
                if (realRange) onDateRangeChanged(realRange)
              }}
              allowClear={false}
            />
          </Col>}
          {showDate && !isCustom && endMoment.isBefore(now) && <Col>
            <HeaderButton
              icon={<RightOutlined style={headerIconStyle}/>}
              onClick={onNext}
            />
          </Col>}
          { showSearch &&
            <Col>
              <Input.Search
                className="searchbar"
                placeHolder="Search"
                onChange={onSearch}
                allowClear
              />
            </Col>
          }
          {onRoundedChanged && (
            <Col style={{height:28, width: 'calc(100% - 400px)'}}>
              <Row gutter={10} style={{ height: '100%' }} align='middle'>
                <Col>
                  <Switch
                    checked={isRounded}
                    onChange={(checked) => setIsRounded(checked)}
                  />
                </Col>
                <Col style={{ cursor: 'none' }}>
                  {isRounded ? 'Rounded' : 'Actual' }
                </Col>
              </Row>
            </Col>
          )}
        </Row>
      </Col>
      <Col flex={`${rightSideWidth}px`}>
        <Row justify='end' gutter={16}>
          <Col>
            <BorderlessButton
              style={{ width: 30,backgroundColor:'transparent', padding:0 }}
              iconNode={<FullScreenIcon style={{ fontSize: 30, marginLeft:0  }}/>}
              onClick={() => {
                if(fsDisable) return;
                setFSDisable(true);
                onFullScreenToggle();
                setTimeout(() => setFSDisable(false),1000); // Debounce
              }}
            />
          </Col>
          {onDelete && <Col>
            <OnTraccrButton
              title='Delete'
              type='primary'
              onClick={onDelete}
            />
          </Col>}
          { showSave && (
            <Col>
              <OnTraccrButton
                title='Save'
                type='cancel'
                onClick={onSave}
              />
            </Col>
          )}
          <Col>
            <MoreButton
              options={[
                {
                  title: 'Export to Excel',
                  icon: 'file-excel',
                  style: { padding: 0 },
                  onClick: exportExcel,
                },
                {
                  title: 'Export to PDF',
                  icon: 'file-text',
                  style: { padding: 0 },
                  onClick: exportPDF,
                },
                {
                  title: 'Export by Project',
                  icon: 'project',
                  style: { padding: 0 },
                  onClick: exportByProject,
                },
              ]}
            />
          </Col>
          <Col>
            <ReportSageExport
              isEmployeePayroll={isEmployeePayroll}
              rangeValue={rangeValue}
              isRounded={isRounded}
              filters={filters}
            />
          </Col>
        </Row>
      </Col>
    </Row>
    );
};