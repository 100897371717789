import {
  Col, Row, Switch,
} from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import HoverHelp from '../../common/HoverHelp';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export default function DivisionCodeInput({
  style = {},
  code,
  onCodeUpdate,
}) {
  const [selected, setSelected] = useState(!!code);
  const [initCode, setInitCode] = useState(code);
  const [newCode, setNewCode] = useState(code);
  const [disabled, setDisabled] = useState(true);

  const onChange = useCallback(() => {
    if (selected) {
      onCodeUpdate(null);
      setInitCode('');
      setNewCode('');
    }
    setSelected(!selected);
  }, [selected, onCodeUpdate]);

  const onType = useCallback((e) => {
    const { value } = e.target;

    const isNumber = /^\d+$/.test(value);

    if ((isNumber || value === '') && value !== undefined) {
      setNewCode(value);
    }
  }, []);

  const onSubmit = useCallback(() => {
    if (newCode === '') {
      onCodeUpdate(null);
      setSelected(false);
    } else {
      onCodeUpdate(newCode);
      setInitCode(newCode);
    }
  }, [onCodeUpdate, newCode]);

  useEffect(() => {
    if (newCode === initCode) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [newCode, initCode]);

  return (
    <Col
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: 5,
        ...style,
      }}
    >
      <Row
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
        gutter={10}
      >
        <Col>
          Division Code
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Enable this option to add a code to identify this division.
              </div>
            )}
          />
        </Col>
        <Col style={{
          paddingRight: 0,
        }}
        >
          <Switch
            onChange={onChange}
            checked={selected}
            style={{
              marginBottom: '0.2em',
            }}
          />
        </Col>

      </Row>
      {selected && (
        <Row style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 5,
        }}
        >
          <Col style={{
            flexGrow: '1',
          }}
          >
            <OnTraccrTextInput
              style={{
                textAlign: 'left',
              }}
              min={1}
              value={newCode}
              placeholder="Enter a division code"
              onBlur={null}
              onMouseLeave={null}
              onChange={onType}
              onClick={null}
              onPressEnter={null}
            />
          </Col>
          <Col>
            <OnTraccrButton
              size="small"
              title="update"
              onClick={onSubmit}
              disabled={disabled}
            />
          </Col>
        </Row>
      )}
    </Col>
  );
}

DivisionCodeInput.propTypes = {
  style: PropTypes.object,
  code: PropTypes.number.isRequired,
  onCodeUpdate: PropTypes.func.isRequired,
};

DivisionCodeInput.defaultProps = {
  style: {},
};
