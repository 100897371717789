import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import generalInfo from '../../assets/images/NUX/generalInfo.png';
import projectApprover from '../../assets/images/NUX/projectApprover.png';
import locationGeofence from '../../assets/images/NUX/locationGeofence.png';

const getGeneralDesc = (t) => `This is where you add the ${t('Project').toLowerCase()} name,
number, and ${t('Customer').toLowerCase()}(optional).`;

const getApproverDesc = (t) => `Define which user will be the approver for all
Time Cards relating to this ${t('Project').toLowerCase()}. You can also add a second
layer of approver if you'd like`;

const getLocationDesc = (t) => `Enter the ${t('Project').toLowerCase()}'s site address and
select whether you want to add a geofence and how big you want it to be.`;

export default function NuxProjectInfo({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const generalDesc = getGeneralDesc(t);
  const approverDesc = getApproverDesc(t);
  const locationDesc = getLocationDesc(t);

  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        padding:'0px 40px'
      }}
      rows={[{
        title:'General Information',
        description:generalDesc,
        cols:[0,12,12],
        image:{
          src:generalInfo,
          style:{
            height:57,
            width:350,
          }
        },
      },{
        title:'Time Card Approver(s)',
        description:approverDesc,
        cols:[0,12,12],
        image:{
          src:projectApprover,
          style:{
            height:45,
            width:325,
          }
        },
      },{
        title:`${t('Project')} Location (and Geofence)`,
        description:locationDesc,
        cols:[0,12,12],
        image:{
          src:locationGeofence,
          style:{
            height:76,
            width:300,
          }
        },
      }]}
      tutorialLink={'PM1. Projects Overview.mp4'}
    />
  );
}
