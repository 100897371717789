import React from "react";
import * as XLSX from "xlsx";
import { ExportOutlined } from '@ant-design/icons'
import OnTraccrButton from '../common/buttons/OnTraccrButton'

const ExportXLSX = ({ csvData, style}) => {
  /*This component is used to create a button that will take two props, CsvData, and filename
   which will take an array of arrays (CsvData) and generate an XLSX file with a specified filename */

  const exportToCSV = (csvData) => {
    const ws = XLSX.utils.aoa_to_sheet(csvData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "Timesheet");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "timesheet.xlsx")
  };

  return (
    <OnTraccrButton title='Export' style={style} type='cancel' icon={<ExportOutlined/>}  onClick={(e) => exportToCSV(csvData)}/>
  );
};

export default ExportXLSX;
