import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer, Form,
} from 'antd';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../../common/inputs/FormTextInput';
import DivisionSelector from '../../../common/inputs/DivisionSelector';

import EclipseFieldMapping from './EclipseFieldMapping';

import {
  eclipseTimeFields,
} from './eclipse.constants';

import {
  createEclipseWorkflow,
  updateEclipseWorkflow,
} from './state/eclipse.actions';

import {
  getCustomFields,
} from '../../../timecards/state/timecards.actions';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

function EclipseWorkflowDrawer({
  visible,
  onClose,
  onDelete,
  selectedWorkflowId,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const workflows = useSelector((state) => state.eclipse.workflows);
  const divisions = useSelector((state) => state.settings.divisions);

  const selectedWorkflow = workflows[selectedWorkflowId] ?? {};

  const [loading, setLoading] = useState(false);
  const [selectedDivsionId, setSelectedDivisionId] = useState(selectedWorkflow.divisionId);

  const onDeleteClicked = useCallback(() => {
    if (!onDelete || !selectedWorkflowId) return;
    onDelete(selectedWorkflowId);
  }, [onDelete, selectedWorkflowId]);

  const onValuesChange = useCallback(async (_, allVals) => {
    setSelectedDivisionId(allVals.divisionId);
    if (selectedDivsionId !== allVals.divisionId) {
      const formValues = {
        ...allVals,
        mappings: {},
      };
      await form.resetFields();
      await form.setFieldsValue(formValues);
    }
  }, [form, selectedDivsionId]);

  const onSubmit = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      // no-op
    }
    if (!values) return;
    setLoading(true);

    const action = selectedWorkflowId
      ? dispatch(updateEclipseWorkflow(selectedWorkflowId, values))
      : dispatch(createEclipseWorkflow(values));
    const res = await action;
    setLoading(false);
    if (res) onClose();
  }, [selectedWorkflowId]);

  const multiDivision = useMemo(() => (
    Object.keys(divisions).length > 1
  ), [divisions]);

  useEffect(() => {
    if (visible) {
      const newWorkflow = workflows[selectedWorkflowId] ?? {};
      form.setFieldsValue(newWorkflow);
      setSelectedDivisionId(newWorkflow?.divisionId);
      dispatch(getCustomFields());
    } else {
      form.resetFields();
    }
  }, [form, selectedWorkflowId, workflows, visible]);

  const title = selectedWorkflow.id
    ? `Edit ${selectedWorkflow.title}`
    : 'Add Eclipse Workflow';

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      width={800}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ marginBottom: 50 }}
        initialValues={selectedWorkflow}
        onValuesChange={onValuesChange}
      >
        <FormTextInput
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Title is required' }]}
          isNotDisplay
        />

        {multiDivision && (
          <Form.Item
            name="divisionId"
            label="Division"
            rules={[{ required: multiDivision, message: 'Division is required' }]}
            labelCol={formLabelStyle}
            style={{ marginBottom: 0 }}
            valuePropName="divisionId"
          >
            <DivisionSelector allowClear={false} />
          </Form.Item>
        )}

        <FormTextInput
          name="queueUser"
          label="Eclipse User Queue"
          rules={[{ required: true, message: 'Eclipse User Queue is required' }]}
          isNotDisplay
        />

        <Form.Item
          name="mappings"
          label="Field Mappings"
          rules={[
            { required: true, message: 'Field Mappings are required' },
            {
              validateTrigger: 'onSubmit',
              validator: (_, value) => {
                if (!value) return Promise.resolve(); // Will be caught above
                const hasAllRequired = eclipseTimeFields.every((field) => (
                  !field.required || value[field.value]
                ));
                return hasAllRequired
                  ? Promise.resolve()
                  : Promise.reject(new Error('Please enter a value for required Eclipse fields'));
              },
              message: 'Please enter a value for required Eclipse fields',
            },
          ]}
          style={{ marginTop: 15 }}
        >
          <EclipseFieldMapping
            divisionId={selectedDivsionId}
          />
        </Form.Item>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
        onDelete={selectedWorkflowId ? onDeleteClicked : null}
      />
    </Drawer>
  );
}

EclipseWorkflowDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedWorkflowId: PropTypes.string,
};

EclipseWorkflowDrawer.defaultProps = {
  selectedWorkflowId: null,
};

export default EclipseWorkflowDrawer;
