import {
  Checkbox, Col, Row, Select,
} from 'antd';
import React from 'react';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OptionalRow from './OptionalRow';
import ConditionalRenderingRow from './ConditionalRenderingRow';
import CheckboxRow from './CheckboxRow';

import HoverHelp from '../../../common/HoverHelp';
import WeatherFieldPreview from './WeatherFieldPreview';
import getGoogleMapKey from '../../../common/keys';

const UNIT_OPTIONS = [
  { value: 'metric', label: 'Metric' },
  { value: 'imperial', label: 'Imperial' },
];

const configure = ({
  setConfigProps,
  configProps = {},
  sections = [],
  id,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
} = {}) => {
  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    const newConfig = {
      ...configProps,
      numAnswers: configProps?.numAnswers ?? 1,
      title: value,
    };
    setConfigProps(newConfig);
  };

  const onCheckChanged = (key) => (e) => {
    const { target: { checked } = {} } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  const onDataChanged = (key) => (data) => {
    setConfigProps({
      ...configProps,
      [key]: data,
    });
  };

  const {
    optional,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    showDailySnapshot = true,
    showWeatherReport = true,
  } = configProps;

  const linkOptions = sections.reduce((acc, section) => {
    const { fields = [], name: sectionName } = section;
    return acc.concat(
      fields.reduce((fieldAcc, field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            title: fieldTitle,
          } = {},
        } = field;
        if (selectedType !== 'dateTime') return fieldAcc;
        return fieldAcc.concat([{
          label: `${sectionName} - ${fieldTitle}`,
          value: fieldId,
        }]);
      }, []),
    );
  }, []);

  return (
    <div style={{ width: 860 }}>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5, width: 350 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={onCheckChanged('optional')} optional={optional} />
      <Row className="form-required-field">
        Units:
      </Row>
      <Row style={{ marginTop: 5, width: 350, marginBottom: 5 }}>
        <Select
          style={{ width: 350 }}
          placeholder="Units"
          onChange={onDataChanged('units')}
          value={configProps.units ?? 'metric'}
          optionFilterProp="label"
          options={UNIT_OPTIONS}
        />
      </Row>
      <ConditionalRenderingRow
        onChange={onCheckChanged('hasConditionalRendering')}
        onFormulaChange={onDataChanged('conditionalRenderingFormula')}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        id={id}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Col>
          <Checkbox
            onChange={onCheckChanged('prefillFromProject')}
            checked={configProps.prefillFromProject}
          >
            Prefill from Project?
          </Checkbox>
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div
                style={{ width: 250 }}
              >
                {'Check this box if you want the weather location to automatically be set to the selected Project\'s location.'}
              </div>
            )}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Col>
          <Checkbox
            onChange={onCheckChanged('isEditable')}
            checked={configProps.isEditable}
          >
            Is Editable?
          </Checkbox>
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Check this box if you want the allow the form author to manually set the location.
              </div>
            )}
          />
        </Col>
      </Row>
      <Row style={{ marginTop: 15 }} gutter={10}>
        <Col>
          Link to date field:
        </Col>
        <Col>
          <HoverHelp placement="top" content="If set, weather will be pulled based on the linked date" />
        </Col>
      </Row>
      <Row style={{ marginTop: 10 }} gutter={10}>
        <Select
          style={{ width: 350 }}
          placeholder="Date/Time Field"
          onChange={onDataChanged('linkField')}
          value={configProps.linkField}
          optionFilterProp="label"
          options={linkOptions}
          allowClear
        />
      </Row>
      <CheckboxRow
        title="Show Weather Report"
        checked={showWeatherReport}
        onChange={onCheckChanged('showWeatherReport')}
        disabled={showWeatherReport && !showDailySnapshot}
      />
      <CheckboxRow
        title="Show Daily Snapshot"
        checked={showDailySnapshot}
        onChange={onCheckChanged('showDailySnapshot')}
        disabled={showDailySnapshot && !showWeatherReport}
      />
    </div>
  );
};

const preview = (props = {}) => (
  <WeatherFieldPreview
    // eslint-disable-next-line react/jsx-props-no-spreading
    {...props}
    googleMapURL={getGoogleMapKey()}
    loadingElement={<div style={{ height: '100%' }} />}
  />
);

export default {
  configure,
  preview,
  title: 'Weather',
  description: 'User can load weather data for their location',
  paidFlag: 'FORMS_WEATHER',
};
