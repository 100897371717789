import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Notes from '../notes/Notes';

import ClientPortalNoteRow from './ClientPortalNoteRow';

import {
  sendMessage,
  markClientCommsAsRead
} from './state/clientPortal.actions';

import Debouncer from '../helpers/Debouncer';

const debouncer = new Debouncer();
export default () => {
  const dispatch = useDispatch();
  const clientComms = useSelector(state => state.clientPortal.comms);
  const profile = useSelector(state => state.clientPortal.profile);
  const companyName = useSelector(state => state.clientPortal.companyName);

  const unreadComms = useMemo(() => (
    clientComms.filter((comm) => comm.unread)
      .map((comm) => comm.id)
  ),[clientComms]);

  useEffect(() => {
    const updateRead = async () => {
      await debouncer.debounce(() => {
        dispatch(markClientCommsAsRead({ isExternal: true, ids: unreadComms }));
      }, 1000);
    }
    if (unreadComms.length > 0) updateRead();
  },[dispatch, unreadComms]);

  return (
    <Notes
      id={'id'}
      notes={{ id: clientComms }}
      addNote={sendMessage}
      style={{
        top: 0,
        left: 24,
        right: 24,
      }}
      customerNoteRender={(note) => (
        <ClientPortalNoteRow
          {...note}
          key={note.id}
          isCurrentUser={note.email === profile.email}
          user={{
            name: note.outgoing ? companyName : note.email,
          }}
        />
      )}
    />
  )
}