import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  DatePicker, Drawer, Form, Row, Col, Select,
} from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import FormTextInput from '../../../../common/inputs/FormTextInput';
import OnTraccrDatePicker from '../../../../common/inputs/OnTraccrDatePicker';
import DrawerSubmitFooter from '../../../../common/containers/DrawerSubmitFooter';

import { validateRepeatEndDate } from '../../../../helpers/subtaskHelpers';
import { dateRangeValidator } from '../../../../schedule/scheduleHelpers';
import ScheduleDateRepeatPopover from '../../../../schedule/ScheduleDateRepeatPopover';
import ScheduleUserSelector from '../../../../schedule/ScheduleUserSelector';

import { isNullOrUndefined } from '../../../../helpers/helpers';
import { getProjectPhases, UNPHASED } from '../../../../clock/ManualEntry/manualEntryHelpers';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 5,
  },
};
const getCCLabel = (cc) => `${cc.code} - ${cc.name}`;

const getPhase = (selectedShift) => (
  selectedShift?.phaseId === null
    && selectedShift?.costcodeId
    ? UNPHASED
    : selectedShift?.phaseId
);

function ShiftTableAddDrawer({
  visible,
  onClose,
  selectedShift,
  onSubmit,
  projects,
  projectId,
  projectLocked,
}) {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const selectedFormTemplate = useSelector((state) => state.forms.selectedFormTemplate);
  const selectedForm = useSelector((state) => state.forms.selectedForm);
  const users = useSelector((state) => state.users.users);
  const divisions = useSelector((state) => state.settings.divisions);
  const phases = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const divUsers = useMemo(() => {
    let ourDivisionId;
    if (selectedForm && selectedForm.divisionId) {
      ourDivisionId = selectedForm.divisionId;
    } else if (selectedFormTemplate && selectedFormTemplate.divisionId) {
      ourDivisionId = selectedFormTemplate.divisionId;
    }
    const {
      [ourDivisionId]: {
        users: divisionUsers = new Set(),
      } = {},
    } = divisions;
    return users.filter((user) => user.active && divisionUsers.has(user.id));
  }, [users, selectedForm, selectedFormTemplate, divisions]);

  const [dateRange, setDateRange] = useState();
  const [repeatValue, setRepeatValue] = useState();
  const [selectedProject, setSelectedProject] = useState(projectId ?? selectedShift?.projectId);
  const [selectedPhase, setSelectedPhase] = useState(getPhase(selectedShift));

  const onSubmitClicked = useCallback(async () => {
    try {
      const values = await form.validateFields();
      if (values.repeatEndDate && moment.isMoment(values.repeatEndDate)) {
        values.repeatEndDate = values.repeatEndDate.startOf('day').valueOf();
      }
      onSubmit(values);
    } catch (err) {
      //
    }
  }, [onSubmit, form]);

  const onFormValuesChanged = useCallback((_, values) => {
    const {
      times: newDateRange = [],
      repeat: newRepeat,
      projectId: newProjectId,
      phaseId: newPhaseId,
    } = values;
    if (newRepeat) {
      setRepeatValue(newRepeat);
    }
    if (newProjectId !== selectedProject) {
      setSelectedProject(newProjectId);
      setSelectedPhase();
      form.setFieldsValue({
        ...values,
        phaseId: null,
        costcodeId: null,
      });
    } else if (newPhaseId !== selectedPhase) {
      setSelectedPhase(newPhaseId);
      form.setFieldsValue({
        ...values,
        costcodeId: null,
      });
    }
    if (newDateRange?.length === 2) {
      const [newStart, newEnd] = newDateRange;
      if (!dateRange
        || dateRange[0] !== newStart
        || dateRange[1] !== newEnd
      ) {
        setDateRange([newStart, newEnd]);
      }
    }
  }, [form, dateRange, selectedProject, selectedPhase]);

  const onUserClick = useCallback(async (userId) => {
    if (!form) return;
    const values = await form.getFieldsValue();
    const { userIds: formUsers = [] } = values;
    const uniqueUsers = new Set(formUsers);
    uniqueUsers.add(userId);
    form.setFieldsValue({
      ...values,
      userIds: Array.from(uniqueUsers),
    });
  }, [form]);

  const parsedRepeatEndDate = useMemo(() => (
    selectedShift?.repeatEndDate
      ? moment(selectedShift.repeatEndDate)
      : undefined
  ), [
    selectedShift,
  ]);
  const repeatEndValidator = useCallback(() => {
    if (!form) return Promise.reject();
    const values = form.getFieldsValue();
    const {
      dateRange: [newStartTime] = [],
      repeat: newRepeat,
      repeatEndDate: newRepeatEndDateMoment,
    } = values;
    if (!newRepeat) {
      return Promise.resolve();
    }
    const newRepeatEndDate = newRepeatEndDateMoment && moment.isMoment(newRepeatEndDateMoment)
      ? newRepeatEndDateMoment.valueOf()
      : null;
    return validateRepeatEndDate({
      startDate: newStartTime,
      repeat: newRepeat,
      repeatEndDate: newRepeatEndDate,
      type: 'Start Time',
    });
  }, [form]);

  useEffect(() => {
    if (visible && (selectedShift || projectId)) {
      const ourProject = projectId ?? selectedShift?.projectId;
      const phaseId = getPhase(selectedShift);
      form.setFieldsValue({
        ...(selectedShift ?? {}),
        repeatEndDate: parsedRepeatEndDate,
        projectId: ourProject,
        phaseId,
      });

      const { times, repeat } = selectedShift ?? {};
      setDateRange(times);
      setRepeatValue(repeat);
      setSelectedProject(ourProject);
      setSelectedPhase(phaseId);
    } else {
      form.resetFields();
      setDateRange();
      setRepeatValue();
      setSelectedProject();
      setSelectedPhase();
    }
  }, [form, visible, projectId, selectedShift, parsedRepeatEndDate]);

  const projectOptions = useMemo(() => (
    projects
      .filter((project) => project.active)
      .map((project) => {
        const prefix = isNullOrUndefined(project.number)
          ? ''
          : `${project.number} - `;
        return { label: `${prefix}${project.name}`, value: project.id };
      })
  ), [projects]);

  const activeCostcodes = useMemo(() => (
    costcodes.filter((cc) => cc.active)
  ), [costcodes]);

  const {
    phases: phaseList,
    phaseMap: phaseIdMap,
  } = useMemo(() => (
    getProjectPhases({
      costcodes: activeCostcodes,
      phases,
      projectId: selectedProject,
    })
  ), [selectedProject, phases, activeCostcodes]);

  const phaseOptions = useMemo(() => {
    if (!selectedProject) return [];
    return phaseList.map((phase) => ({ label: phase.name, value: phase.id }));
  }, [selectedProject, phaseList]);

  const costcodeOptions = useMemo(() => {
    if (!selectedProject) {
      return activeCostcodes.filter((cc) => !cc.projectId)
        .map((cc) => ({ label: getCCLabel(cc), value: cc.id }));
    }
    const {
      [selectedPhase]: {
        costcodes: phaseCC = [],
      } = {},
    } = phaseIdMap;
    return phaseCC.map((cc) => ({ label: getCCLabel(cc), value: cc.id }));
  }, [phaseIdMap, selectedProject, selectedPhase, activeCostcodes]);

  return (
    <Drawer
      title="Add Shifts"
      visible={visible}
      onClose={onClose}
      width={700}
    >

      <Form form={form} layout="vertical" onValuesChange={onFormValuesChanged}>
        <FormTextInput
          isNotDisplay
          label="Title"
          name="title"
          rules={[{ required: true, message: 'Title is required' }]}
        />

        <Form.Item
          label="Dates"
          name="times"
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
          rules={[
            { required: true, message: 'Please select a start and end date' },
            { validator: dateRangeValidator },
          ]}
        >
          <OnTraccrDatePicker
            isDisplay={false}
          />
        </Form.Item>
        <Form.Item
          name="repeat"
          className="schedule-form-item"
          style={{ marginTop: -10 }}
        >
          <ScheduleDateRepeatPopover startTime={dateRange ? dateRange[0] : null} />
        </Form.Item>

        {repeatValue && (
          <Form.Item
            label="Repeat End Date"
            name="repeatEndDate"
            style={{ marginTop: -10 }}
            rules={[{
              required: !!repeatValue,
              validator: repeatEndValidator,
            }]}
            initialValue={parsedRepeatEndDate}
          >
            <DatePicker format="MMM Do YY" allowClear />
          </Form.Item>
        )}

        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="projectId"
              label={t('Project')}
              className="schedule-form-item"
              labelCol={formLabelStyle}
            >
              <Select
                placeholder={`Select a ${t('Project')}`}
                optionFilterProp="label"
                showSearch
                allowClear
                options={projectOptions}
                disabled={projectLocked}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="phaseId"
              label="Phase"
              className="schedule-form-item"
              labelCol={formLabelStyle}
            >
              <Select
                placeholder="Select a Phase"
                optionFilterProp="label"
                showSearch
                disabled={!selectedProject}
                allowClear
                options={phaseOptions}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="costcodeId"
              label="Costcode"
              className="schedule-form-item"
              labelCol={formLabelStyle}
            >
              <Select
                placeholder="Select a Cost Code"
                optionFilterProp="label"
                showSearch
                disabled={!selectedProject || !selectedPhase}
                allowClear
                options={costcodeOptions}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item
          name="userIds"
          label="Users"
          className="schedule-form-item"
        >
          <ScheduleUserSelector users={divUsers} onUserClick={onUserClick} dateRange={dateRange} />
        </Form.Item>

        <FormTextInput
          isNotDisplay
          label="Description"
          name="description"
          textarea
        />
      </Form>

      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
      />
    </Drawer>
  );
}

ShiftTableAddDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedShift: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onSubmit: PropTypes.func.isRequired,
  projects: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  projectId: PropTypes.string,
  projectLocked: PropTypes.bool,
};

ShiftTableAddDrawer.defaultProps = {
  visible: false,
  selectedShift: null,
  projects: [],
  projectId: null,
  projectLocked: false,
};

export default ShiftTableAddDrawer;
