import axios from 'axios';

import { request, archive } from '../../helpers/requests';

export default {
  create: (payload) => request({
    call: axios.post('/billing/rates', payload),
    errMsg: 'Could not create billing rate',
  }),
  get: () => request({
    call: axios.get('/billing/rates'),
    errMsg: 'Could not get billing rates',
    hideSuccessToast: true,
  }),
  archive: (id, active) => archive({
    id, active, type: 'billing/rate',
  }),
  deleteRate: (id) => request({
    call: axios.delete(`/billing/rates/${id}`),
    errMsg: 'Could not delete billing rate',
  }),
  update: (id, payload) => request({
    call: axios.put(`/billing/rates/${id}`, payload),
    errMsg: 'Could not update billing rate',
  }),
};
