import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { NotificationOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import Permissions from '../auth/Permissions';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import CardGrid from '../common/cardGrid/cardGrid';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import FilterDropdown from '../common/FilterDropdown';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import ContactAddView from './ContactAddView';
import ContactEditView from './ContactEditView';
import ContactCard from './ContactCard';
import CustomerClientBroadcast from './customers/CustomerClientBroadcast';

import { filterOptions } from './contactFilters';

import { getGlobalAddressBook } from './state/contacts.actions';
import {
  createCustomer,
  getCustomers,
  archiveCustomer,
  updateCustomer,
  deleteCustomer,
  getCustomerLabels,
} from './customers/state/customers.actions';

import {
  getAllCostCodes,
  getAllPhases,
} from '../costcodes/state/costcodes.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import {
  getProjects,
  getProjectEquipment,
} from '../projects/state/projects.actions';
import { getBillingRates } from '../billingRates/state/billingRates.actions';
import { getTemplates } from '../forms/state/forms.actions';
import { getCardLinks } from '../boards/state/boards.actions';
import {
  getClientPortalAccounts,
  getClientPortalSettings,
} from '../clientPortal/state/clientPortal.actions';

import sortByString from '../helpers/helpers';
import {
  archiveVendor,
  createVendor,
  deleteVendor,
  getVendorLabels,
  getVendors,
  updateVendor,
} from './vendors/state/vendors.actions';
import { getLabels } from '../labels/state/labels.actions';

const getCustomerCrumbs = (t) => [{
  text: t('Customer', { count: 2 }),
  icon: 'customers',
}];

const VENDOR_CRUMBS = [{
  text: 'Vendors',
  icon: 'vendors',
}];

export default function Contacts({
  history,
  isVendor,
}) {
  const { t } = useTranslation();
  const hasWritePerms = isVendor
    ? Permissions.has('VENDORS_WRITE') : Permissions.has('CUSTOMERS_WRITE');
  const hasReadPerms = isVendor
    ? Permissions.has('VENDORS_READ') : Permissions.has('CUSTOMERS_READ');
  const dispatch = useDispatch();
  const {
    state: {
      targetId: locationStateId,
    } = {},
    pathname,
  } = useLocation();
  let cardGridRef = useRef(null);

  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const vendors = useSelector((state) => state.vendors.vendors);
  const vendorToLabel = useSelector((state) => state.vendors.vendorToLabel);
  const customers = useSelector((state) => state.customers.customers);
  const customerToLabel = useSelector((state) => state.customers.customerToLabel);
  const unreadClientComms = useSelector((state) => state.customers.unreadClientComms);
  const unreadVendorComms = useSelector((state) => state.vendors.unreadVendorComms);
  const {
    communicators = [],
  } = useSelector((state) => state.clientPortal.settings);
  const company = useSelector((state) => state.settings.company);
  const { userId: ownerId } = company ?? {};

  const labels = useSelector((state) => state.labels);
  const labelToCustomer = useSelector((state) => state.customers.labelToCustomer);
  const labelToVendor = useSelector((state) => state.vendors.labelToVendor);
  const contactLabels = useMemo(() => (
    labels.filter((label) => label.type === (isVendor ? 'vendors' : 'customers'))
  ), [isVendor, labels]);

  const [activeFilters, setActiveFilters] = useState({
    active: new Set([1]),
    unread: new Set([0, 1]),
    labels: new Set(),
    type: new Set(['Subcontractor', 'Supplier']),
  });
  const [activeSort, setActiveSort] = useState('Name: Ascending');
  const [showBroadcastDrawer, setShowBroadcastDrawer] = useState(false);

  const hideBroadcastDrawer = useCallback(() => setShowBroadcastDrawer(false), []);
  const openBroadcastDrawer = useCallback(() => setShowBroadcastDrawer(true), []);
  const onFilter = useCallback((checkedFilters, key) => {
    const newFilters = new Set(checkedFilters);
    setActiveFilters({
      ...activeFilters,
      [key]: newFilters,
    });
  }, [activeFilters]);

  const onAdd = useCallback((values) => dispatch(
    isVendor
      ? createVendor(values)
      : createCustomer(values),
  ), [dispatch]);

  const onEdit = useCallback((id, data) => dispatch(
    isVendor
      ? updateVendor(id, data, vendorToLabel)
      : updateCustomer(id, data, customerToLabel),
  ), [
    dispatch,
    vendorToLabel,
    customerToLabel,
  ]);

  const onArchive = useCallback((contact) => {
    const mode = contact.active ? 'Archive' : 'Activate';
    CustomConfirmModal({
      title: `${mode} ${isVendor ? 'Vendor' : t('Customer')} '${contact.name}'?`,
      okText: mode,
      cancelText: 'Cancel',
      onOk() {
        dispatch(isVendor
          ? archiveVendor(contact.id, !contact.active)
          : archiveCustomer(contact.id, !contact.active));
      },
    });
  }, [isVendor, dispatch]);

  const onDelete = useCallback((contact) => {
    CustomConfirmModal({
      title: `Delete ${isVendor ? 'Vendor' : t('Customer')} '${contact.name}'?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk() {
        dispatch(isVendor
          ? deleteVendor(contact.id)
          : deleteCustomer(contact.id));
      },
    });
  }, [isVendor, dispatch]);

  useEffect(() => {
    dispatch(getLabels());
    dispatch(getVendors());
    dispatch(getVendorLabels());
    dispatch(getCustomers());
    dispatch(getCustomerLabels());
    dispatch(getProjects());
    dispatch(getAllCostCodes());
    dispatch(getAllPhases());
    dispatch(getProjectEquipment());
    dispatch(getEquipment());
    dispatch(getBillingRates());
    dispatch(getTemplates());
    dispatch(getCardLinks());
    dispatch(getClientPortalSettings());
    dispatch(getClientPortalAccounts());
    dispatch(getGlobalAddressBook());
  }, [dispatch]);

  useEffect(() => {
    const {
      [locationStateId]: selectedCustomer,
    } = customers;
    if (cardGridRef && cardGridRef.displayItem && selectedCustomer) {
      cardGridRef.displayItem(selectedCustomer); // Clear location state
      history?.replace(pathname);
    }
  }, [cardGridRef, locationStateId, customers, history, pathname]);

  useEffect(() => {
    const cachedSort = window.localStorage.getItem('contactsSort') || 'Name: Ascending';
    setActiveSort(cachedSort);
  }, []);

  const unreadCountMap = useMemo(() => {
    const map = {};
    const contactId = isVendor ? 'vendorId' : 'customerId';
    (isVendor ? unreadVendorComms : unreadClientComms).forEach((note) => {
      const {
        [note[contactId]]: oldValue = 0,
      } = map;
      map[note[contactId]] = oldValue + 1;
    });
    return map;
  }, [isVendor, unreadClientComms, unreadVendorComms]);
  const contactList = useMemo(() => {
    const {
      labels: activeLabels = new Set(),
      type,
    } = activeFilters;
    const labelToContact = isVendor ? labelToVendor : labelToCustomer;

    const contactsFromLabels = new Set();
    if (activeLabels.size > 0) {
      Array.from(activeLabels).forEach((label) => {
        const {
          [label]: labelContactIds = [],
        } = labelToContact;
        labelContactIds.forEach((contactId) => {
          contactsFromLabels.add(contactId);
        });
      });
    }

    const list = Object.values(isVendor ? vendors : customers)
      .filter((contact) => {
        if (!activeFilters.active.has(contact.active)) return false;
        if (isVendor && !type.has(contact.type)) return false;
        if (!contact?.divisionIds?.find((divisionId) => selectedDivisions.has(divisionId))) return false;
        const { id: contactId } = contact;
        const { [contactId]: unreadCount = 0 } = unreadCountMap;
        const { unread: unreadFilter = new Set() } = activeFilters;

        const unreadMatch = (
          (unreadCount > 0 && unreadFilter.has(1))
          || (unreadCount === 0 && unreadFilter.has(0))
        );

        // If labels filter is empty we show everyone
        const labelMatch = activeLabels.size === 0 || contactsFromLabels.has(contactId);
        return unreadMatch && labelMatch;
      });
    const mod = activeSort === 'Name: Ascending' ? 1 : -1;
    list.sort((a, b) => sortByString('name')(a, b) * mod);
    return list;
  }, [
    isVendor,
    customers,
    vendors,
    activeFilters,
    activeSort,
    unreadCountMap,
    labelToVendor,
    labelToCustomer,
    selectedDivisions,
  ]);

  const filterIsAtDefault = useMemo(() => {
    const { active, unread, labels } = activeFilters;
    return active.has(1) && !active.has(0) && unread.has(0) && unread.has(1) && labels.size === 0;
  }, [activeFilters]);

  const sortData = useMemo(() => ({
    sortOptions: [
      {
        title: 'Name: Ascending',
        icon: 'sort-ascending',
        action: () => {
          window.localStorage.setItem('contactsSort', 'Name: Ascending');
          setActiveSort('Name: Ascending');
        },
      }, {
        title: 'Name: Descending',
        icon: 'sort-descending',
        action: () => {
          window.localStorage.setItem('contactsSort', 'Name: Descending');
          setActiveSort('Name: Descending');
        },
      },
    ],
    sortActive: activeSort,
  }), [activeSort]);

  const canSend = useMemo(() => (
    Permissions.id === ownerId || communicators.includes(Permissions.id)
  ), [communicators, ownerId]);

  const CUSTOMER_CRUMBS = getCustomerCrumbs(t);

  if (!hasReadPerms) {
    history.replace('/dashboard');
    return <></>;
  }

  return (
    <>
      <BreadCrumbContainer crumbs={isVendor ? VENDOR_CRUMBS : CUSTOMER_CRUMBS}>
        <CardGrid
          onRef={(ref) => cardGridRef = ref}
          dataSource={contactList}
          itemView={ContactCard}
          itemDimensions={{ width: 239, height: 190 }}
          add={hasWritePerms ? {
            title: `Enter ${isVendor ? 'Vendor' : t('Customer')} Details`,
            formView: (form, formProps) => <ContactAddView form={form} isVendor={isVendor} {...formProps} />,
            width: 1000,
            onClose: onAdd,
            numberOfSteps: 2,
          } : null}
          edit={{
            title: `Enter ${isVendor ? 'Vendor' : t('Customer')} Details`,
            formView: (form, formProps) => <ContactEditView form={form} isVendor={isVendor} {...formProps} />,
            width: 1000,
            onClose: onEdit,
            numberOfSteps: 2,
            canEdit: () => hasWritePerms,
            canArchive: () => hasWritePerms,
          }}
          onArchive={onArchive}
          onDelete={onDelete}
          filter={(
            <FilterDropdown
              filters={[filterOptions({ labels: contactLabels, isVendor })]}
              activeFilters={activeFilters}
              onFilter={onFilter}
            />
          )}
          filterActive={filterIsAtDefault}
          sort={sortData}
          extra={
            canSend && !isVendor && (
            <OnTraccrButton
              type="cancel"
              title="Message"
              icon={<NotificationOutlined />}
              onClick={openBroadcastDrawer}
            />
            )
          }
        />
      </BreadCrumbContainer>
      <CustomerClientBroadcast
        visible={showBroadcastDrawer}
        onClose={hideBroadcastDrawer}
      />
    </>
  );
}

Contacts.propTypes = {
  isVendor: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};
