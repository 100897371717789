import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Row, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import SheetUploadPrompt from '../../common/excel/SheetUploadPrompt';
import StepsContainer from '../../common/containers/StepsContainer';
import Permissions from '../../auth/Permissions';
import FormImportSheetSelector from './FormImportSheetSelector';
import { simpleTextBasedFieldTypes } from '../formHelpers';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FormUploadPreview from './FormUploadPreview';
import { constructFormPayloadForAPI, prepareResponsePayload } from '../ResponderHelpers';
import { importForm } from '../state/forms.actions';
import SheetHeaderMatcher from '../../common/excel/SheetHeaderMatcher';

const LAST_UPLOAD_STEPS = 2;
const NOTIFICATION_WARN_KEY = 'formImportKey';

const parseUploadData = (uploadData) => {
  const parsedData = [];
  uploadData.forEach((data) => {
    const newData = {
      ...data,
    };
    delete newData.id;
    delete newData.formNumber;

    // convert to response format
    const responseMap = Object.keys(newData).reduce((acc, key) => {
      acc[key] = {
        value: newData[key],
      };
      return acc;
    }, {});

    parsedData.push({
      responses: responseMap,
      formNumber: data.formNumber?.toString(),
    });
  });

  return parsedData;
};

export default function FormImport({
  history,
}) {
  const dispatch = useDispatch();
  const formTemplates = useSelector((state) => state.forms.templates);

  const [uploadFile, setUploadFile] = useState(null);
  const [selectedSheetName, setSelectedSheetName] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [selectedUser, setSelectedUser] = useState(null);
  const [headerMapping, setHeaderMapping] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [massUploadData, setMassUploadData] = useState([]);
  const [shouldSaveMapping, setShouldSaveMapping] = useState(false);

  const sheets = uploadFile?.Sheets ?? null;

  const selectedSheet = useMemo(() => {
    if (!sheets || !selectedSheetName) return [];
    const {
      [selectedSheetName]: fullSheet = {},
    } = sheets;

    return XLSX.utils.sheet_to_json(fullSheet, { defval: '' });
  }, [sheets, selectedSheetName]);

  useEffect(() => {
    if (!uploadFile) return;
    const {
      SheetNames = [],
    } = uploadFile;
    if (!selectedSheetName || (SheetNames.length === 1 && selectedSheetName !== SheetNames[0])) {
      setSelectedSheetName(SheetNames[0]);
    }
  }, [selectedSheetName, uploadFile]);

  const {
    headerOptions,
    templateSections,
  } = useMemo(() => {
    if (!selectedTemplate) {
      return {
        headerOptions: [],
        templateSections: [],
      };
    }
    const formData = formTemplates[selectedTemplate]?.jsonFormData;
    const relevantFields = [
      { key: 'formNumber', title: 'Form Number' },
    ];
    let sections;

    try {
      const parsedFormData = JSON.parse(formData);
      sections = parsedFormData?.sections;
      sections?.forEach(({ name, fields = [] }) => {
        fields?.forEach(({ id, configProps, selectedType }) => {
          if (simpleTextBasedFieldTypes.has(selectedType)) {
            relevantFields.push({
              key: id,
              title: `${name} - ${configProps.title}`,
            });
          }
        });
      });
    } catch (e) {
      // fail silently
    }

    return {
      headerOptions: relevantFields,
      templateSections: sections,
    };
  }, [selectedTemplate, formTemplates]);

  const sheetHeaders = useMemo(() => {
    if (!selectedSheet || selectedSheet.length === 0) return [];
    // This will be slow for big sheets that have many sparse rows
    const firstRow = selectedSheet.find((row = {}) => Object.keys(row).length > 0);
    return Object.keys(firstRow);
  }, [selectedSheet]);

  const onMassUpload = useCallback(async (data) => {
    const template = formTemplates[selectedTemplate];
    if (!template) return false;

    const parsedData = await Promise.all(data.map(async ({
      responses,
      formNumber,
    }) => {
      const responsePayload = prepareResponsePayload({
        sections: templateSections,
        responses,
        title: template.name,
        templateId: template.id,
      });

      const formPayload = await constructFormPayloadForAPI({
        form: {
          ...responsePayload,
        },
        addSectionId: true,
      });
      return {
        ...formPayload?.data ?? {},
        formNumber,
      };
    }));

    return dispatch(importForm({
      templateId: selectedTemplate,
      data: parsedData,
      userId: selectedUser,
    }));
  }, [
    selectedTemplate,
    formTemplates,
    templateSections,
    selectedUser,
  ]);

  const resetValues = () => {
    setCurrentStep(0);
    setSelectedSheetName(null);
    setSelectedTemplate(null);
    setSelectedUser(null);
    setHeaderMapping({});
    setMassUploadData([]);
    setUploadFile(null);
    setShouldSaveMapping(false);
  };

  const onBack = useCallback(() => setCurrentStep(currentStep - 1), [currentStep]);
  const onNext = useCallback(async () => {
    if (!selectedTemplate || !selectedUser) {
      notification.warn({
        key: NOTIFICATION_WARN_KEY,
        message: 'Warning',
        description: 'Please select a template and user',
      });
      return;
    }
    notification.close(NOTIFICATION_WARN_KEY);

    if (currentStep < LAST_UPLOAD_STEPS) {
      setCurrentStep(currentStep + 1);
      return;
    }

    setLoading(true);
    const result = await onMassUpload(
      parseUploadData(massUploadData),
    );

    if (result) {
      const key = `${selectedTemplate}-${JSON.stringify(sheetHeaders)}`;

      if (!shouldSaveMapping) {
        window.localStorage.removeItem(key);
      } else {
        window.localStorage.setItem(key, JSON.stringify(headerMapping));
      }

      notification.open({
        message: 'Successfully imported forms',
        duration: 5,
        icon: <CheckCircleOutlined />,
      });
      resetValues();
    }
    setLoading(false);
  }, [
    currentStep,
    selectedTemplate,
    selectedUser,
    massUploadData,
    headerOptions,
    sheetHeaders,
    shouldSaveMapping,
  ]);

  useEffect(() => {
    if (
      !selectedSheet
      || !selectedTemplate
    ) return;

    const key = `${selectedTemplate}-${JSON.stringify(sheetHeaders)}`;

    const headerMappings = window.localStorage.getItem(key);
    if (headerMappings) {
      setHeaderMapping(JSON.parse(headerMappings));
      setShouldSaveMapping(true);
    } else {
      setHeaderMapping({});
      setShouldSaveMapping(false);
    }
  }, [selectedTemplate, selectedSheet, sheetHeaders]);

  if (!Permissions.has('FORMS_WRITE')) {
    history.replace('/dashboard');
    return null;
  }

  return (
    <div className="form-import-container">
      {uploadFile ? (
        <>
          <StepsContainer
            currentStep={currentStep}
            containerStyle={{ height: 'unset' }}
            steps={[
              {
                title: 'Select Sheet',
                content: (
                  <FormImportSheetSelector
                    workbook={uploadFile}
                    selectedSheetName={selectedSheetName}
                    onSheetChanged={setSelectedSheetName}
                    selectedTemplate={selectedTemplate}
                    onTemplateChanged={setSelectedTemplate}
                    selectedUser={selectedUser}
                    onUserChanged={setSelectedUser}
                  />
                ),
              },
              {
                title: 'Select Headers',
                content: (
                  <SheetHeaderMatcher
                    headerOptions={headerOptions}
                    selectedSheet={selectedSheet}
                    headerMapping={headerMapping}
                    setHeaderMapping={setHeaderMapping}
                    shouldSaveMapping={shouldSaveMapping}
                    setShouldSaveMapping={setShouldSaveMapping}
                  />
                ),
              },
              {
                title: 'Preview',
                content: (
                  <FormUploadPreview
                    options={headerOptions}
                    selectedSheet={selectedSheet}
                    headerMapping={headerMapping}
                    onMassUploadDataChanged={setMassUploadData}
                  />
                ),
              },
            ]}
          />
          <Row justify="space-between" style={{ height: 60 }}>
            <Col>
              <OnTraccrButton
                title="Cancel"
                type="back"
                id="customer-add-project-footer-cancel"
                onClick={resetValues}
              />
            </Col>
            <Col>
              <Row justify="end" align="middle" gutter={8}>
                {currentStep > 0 && (
                  <OnTraccrButton
                    title="Back"
                    type="back"
                    id="customer-add-project-footer-back"
                    onClick={onBack}
                  />
                )}
                <OnTraccrButton
                  title={currentStep < LAST_UPLOAD_STEPS ? 'Next' : 'Submit'}
                  onClick={onNext}
                  disabled={!uploadFile}
                  loading={loading}
                />
              </Row>
            </Col>
          </Row>
        </>
      ) : (
        <SheetUploadPrompt onFileChange={setUploadFile} showUploadList={false} />
      )}
    </div>
  );
}

FormImport.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func.isRequired,
  }).isRequired,
};
