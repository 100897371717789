import React, { useState, useEffect, useMemo } from 'react';
import { Button, Tooltip } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import FilePreview from './FilePreview';

import { getCroppedDimensions } from '../../helpers/photos';

const IMAGE_DIM = 100;
const SMALL_DIM = 50;

const getTimeText = (ts) => (
  DateTime
    .fromMillis(ts)
    .toLocaleString(DateTime.DATETIME_MED)
);

export default ({
  rotation = 0,
  uri,
  file,
  type,
  shadow,
  onDelete,
  onClick,
  small,
  showCondensedView,
  locationMap = {},
  timestamp,
}) => {
  const cursor = onClick ? 'pointer' : 'auto';
  const ourDim = small ? SMALL_DIM : IMAGE_DIM;
  const [style,setStyle] = useState({ });
  const [loaded,setLoaded] = useState(false);
  useEffect(() => {
    setLoaded(false);
  },[file,uri]);

  const onImageLoad = ({ target:img }) => {
    if(!loaded) {
      setLoaded(true);
      setStyle(getCroppedDimensions({
        img,
        scale:8,
        height:ourDim,
        width:ourDim,
        position:'absolute'
      }));
    }

  };

  const updateTimeText = useMemo(() => (
    timestamp
     ? getTimeText(timestamp)
     : null
  ), [timestamp]);

  const getLocations = (id) => {
    let locationString = '';
    if (!id || !locationMap[id]) {
      return locationString;
    }
    if (locationMap[id]) {
      locationMap[id].forEach(obj => {
        const appendedString = `${locationString} ${obj.location}`
        if (obj.timestamp) {
          locationString = `${appendedString} ${getTimeText(obj.timestamp)},`
        } else {
          locationString = `${appendedString},`
        }
      });
    }
    return `Location(s): ${locationString}`;
  };
  const getChildView = () => {
    const { type = 'other' } = file;
    switch(type) {
      case 'image':
      case 'image/png':
      case 'image/jpeg':
      case 'image/svg+xml':
        return (
          <Tooltip placement='bottom' title={getLocations(file?.id)}>
            <img
              className={small ? 'photo-preview-small' : 'photo-preview'}
              src={file instanceof File ? URL.createObjectURL(file) : file.url}
              key={uri}
              alt='Post'
              onLoad={onImageLoad}
              style={{
                ...style,
                cursor,
                width: '100%',
                height: '100%',
                transform: `rotate(${rotation}deg)`,
              }}
              onClick={onClick}
            />
          </Tooltip>);
      default:
        return <FilePreview file={file} onClick={onClick} small={small} location={getLocations(file?.id)}/>
    }
  }

  const width = showCondensedView ? ourDim : ourDim + 10;
  const height = showCondensedView ? ourDim : ourDim + 20;
  return (
    <div
      key={uri}
        style={{
        height: height + (timestamp ? 40 : 0),
        width,
        borderRadius:4,
      }}>
      <div
        className={`photo-preview-container ${shadow ? 'photo-shadow' : ''}`}
        style={{
          height:ourDim,
          width:ourDim,
        }}
      >
        {uri && type === 'image' ? <img
          className={small ? 'photo-preview-small' : 'photo-preview'}
          src={file instanceof File ? URL.createObjectURL(file) : uri}
          key={uri}
          alt='Post'
          onLoad={onImageLoad}
          style={{
            ...style,
            cursor,
          }}
          onClick={onClick}
        /> : getChildView()}
      </div>
      {
        updateTimeText
        && (
          <div style={{ width, fontSize: 10, textAlign: 'center' }}>
            {updateTimeText}
          </div>
        )
      }
      {onDelete && <Button
        className='photo-preview-delete'
        icon={
          <CloseOutlined className='photo-preview-delete-icon'/>
        }
        onClick={onDelete}
      />}
    </div>
  );
}
