import React, { useEffect, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';
import { Row, Col, Divider } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';

import { showDisconnectConfirm } from '../integrationHelpers';
import Colors from '../../../constants/Colors';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DisplayText from '../../../common/text/DisplayText';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import googleImage from '../../../assets/images/google-signin.png';

import {
  checkIntegrationConnection,
} from '../../state/settings.actions';
import {
  disconnectGmail,
  getGmailCode,
} from '../../../profile/state/profile.actions';

const buttonDimensions = { 
  height:37,
  width:154,
  cursor:'pointer',
};

const flavourText = `
Deactivating your Gmail integration will disable any
further syncing between OnTraccr and Gmail.
`;

const hideCode = new Array(36).fill('•').join('');
export default ({ visible }) => {
  const dispatch = useDispatch();

  const  {
    connectedToGmail
  } = useSelector(state => state.settings.company ?? {});
  const gmailCode = useSelector(state => state.profile.gmailCode);

  const [showCode,setShowCode] = useState(false);

  const toggleShowCode = useCallback(() => {
    setShowCode(!showCode);
  },[showCode]);

  const checkStatus = useCallback(() => {
    dispatch(checkIntegrationConnection('gmail'));
  },[dispatch]);

  const onConnect = useCallback(async () => {
    const { data: uri } = await axios.get('/gmail/connect/initiate');
    if(uri) window.location.href = uri;
  },[]);

  const onDisconnect = useCallback(() => {
    showDisconnectConfirm({
      type:'Gmail',
      flavourText,
      onConfirm:async () => {
        await dispatch(disconnectGmail());
        checkStatus();
      }
    });
  },[checkStatus])

  useEffect(() => {
    checkStatus();
    dispatch(getGmailCode());
  },[dispatch]);

  useEffect(() => {
    setShowCode(false);
  },[visible]);

  if(!connectedToGmail) {
    return (
      <div style={{ paddingTop: 30, paddingBottom:30}}>
        <div style={buttonDimensions}>
          <img 
            alt='Connect to Gmail'
            src={googleImage} 
            style={buttonDimensions}
            onClick={onConnect}
          />
        </div>
      </div>
    );
  }

  return (
    <div style={{ width:'100%', position: 'relative'}}>
      <Row style={{minHeight:32, width:'100%', paddingLeft: 10}} justify='space-between' align='middle'>
        <Col>
          <DisplayText
            title='Authorization Code'
            style={{ fontFamily: 'roboto-medium', marginBottom:0}}/>
        </Col>
      </Row>
      <DisplayText
        title='Use this code to authorize Gmail to connect to OnTraccr'
        style={{
          marginBottom:0,
          maxWidth:275,
          paddingLeft:10,
          fontFamily:'roboto-regular',
          color:Colors.ONTRACCR_OPACITY_GRAY
        }}
      />
      <div className='integation-auth-code'>
        {showCode || !gmailCode ? gmailCode : hideCode}
      </div>
      {
        gmailCode &&
        <div id='gmail-visibility-toggle-container'>
          <BorderlessButton
            iconNode={showCode ? <EyeInvisibleOutlined/> : <EyeOutlined/>}
            onClick={toggleShowCode}
            style={{ padding: 0 }}
          />
        </div>
      }
      <Divider />
      <Row style={{minHeight:32, width:'100%', paddingLeft: 10}} justify='space-between' align='middle'>
        <Col>
          <DisplayText
            title='Deactivate Gmail Integration'
            style={{ fontFamily: 'roboto-medium', marginBottom:0}}/>
        </Col>
      </Row>
      <DisplayText
        title={flavourText}
        style={{
          marginBottom:0,
          maxWidth:275,
          paddingLeft:10,
          fontFamily:'roboto-regular',
          color:Colors.ONTRACCR_OPACITY_GRAY
        }}
      />
      <Row style={{width:'100%', }} justify='center'>
        <OnTraccrButton
          title='Deactivate'
          style={{ margin: 10, }}
          onClick={onDisconnect}
        />
      </Row>
  </div>
  );
};
