import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'antd';

// Import Components/Constants:
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE } from './invoiceConstants';

/** Invoice add drawer footer */
export default function InvoiceAddDrawerFooter({
  mode,
  isLoading,
  onDeleteInvoiceClick,
  onInvoiceSubmitClick,
  onInvoiceModeChange,
  linkedPoIsClosed,
}) {
  const onInvoiceEdit = useCallback(() => onInvoiceModeChange(INVOICE_DRAWER_EDIT_MODE), []);
  const onInvoiceCancelEdit = useCallback(() => onInvoiceModeChange(INVOICE_DRAWER_VIEW_MODE), []);
  const onInvoiceSubmit = useCallback(() => onInvoiceSubmitClick(mode), [mode, onInvoiceSubmitClick]);

  return (
    <Row
      className="drawer-footer"
      justify="end"
      gutter={10}
    >
      {(mode === INVOICE_DRAWER_EDIT_MODE || mode === INVOICE_DRAWER_VIEW_MODE) &&
        <OnTraccrButton
          style={{ marginRight: 'auto' }}
          title='Delete'
          type='back'
          onClick={onDeleteInvoiceClick}
          loading={isLoading}
        />}
      {mode === INVOICE_DRAWER_VIEW_MODE && !linkedPoIsClosed
        && <OnTraccrButton
            title='Edit'
            type='cancel'
            onClick={onInvoiceEdit}
          />}
      {mode === INVOICE_DRAWER_EDIT_MODE
        && <OnTraccrButton
            title='Cancel'
            type='cancel'
            style={{ marginRight: 8 }}
            onClick={onInvoiceCancelEdit}
          />}
      {mode !== INVOICE_DRAWER_VIEW_MODE
        && <OnTraccrButton
            title='Save'
            loading={isLoading}
            onClick={onInvoiceSubmit}
          />}
    </Row>
  );
}

InvoiceAddDrawerFooter.propTypes = {
  mode: PropTypes.oneOf([INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE, INVOICE_DRAWER_ADD_MODE]),
  isLoading: PropTypes.bool.isRequired,
  onDeleteInvoiceClick: PropTypes.func.isRequired,
  onInvoiceSubmitClick: PropTypes.func.isRequired,
  onInvoiceModeChange: PropTypes.func.isRequired,
  linkedPoIsClosed: PropTypes.bool.isRequired,
};
