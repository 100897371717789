import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import TableAddDrawer from '../TableAddDrawer';

import { includesTerm } from '../../../../helpers/helpers';
import useValidateMaxSelect from '../../../../common/hooks/useValidateMaxSelect';

const cols = [
  {
    title: 'Name',
    dataIndex: 'name',
  },
  {
    title: 'ID',
    dataIndex: 'code',
  },
];

function EquipmentTableAddDrawer({
  equipment,
  visible,
  onClose,
  onSubmit,
  selected: initialSelected = [],
  maxExistingAllowed,
}) {
  const [searchStr, setSearchStr] = useState();
  const [selected, setSelected] = useState(initialSelected);

  const data = useMemo(() => {
    if (!searchStr) return equipment;
    return equipment.filter((eq) => (
      includesTerm(eq.name, searchStr)
    ));
  }, [equipment, searchStr]);

  useEffect(() => {
    setSelected(initialSelected);
  }, [initialSelected]);

  useEffect(() => {
    if (!visible) {
      setSearchStr();
      setSelected([]);
    }
  }, [visible]);

  const onSubmitClicked = useCallback(() => {
    const isValid = useValidateMaxSelect({
      maxExistingAllowed,
      selected,
      type: 'equipment',
    });

    if (isValid) onSubmit(selected);
  }, [
    selected,
    onSubmit,
    maxExistingAllowed,
  ]);

  const onSelectRow = useCallback((record, checked) => {
    if (checked) {
      setSelected(selected.concat(record));
    } else {
      setSelected(selected.filter((s) => s.id !== record.id));
    }
  }, [selected, setSelected]);

  const selectedRowKeys = useMemo(() => (
    selected.map((s) => s.id)
  ), [selected]);

  return (
    <TableAddDrawer
      title="Add Equipment"
      visible={visible}
      onClose={onClose}
      onSearch={setSearchStr}
      onSubmit={onSubmitClicked}
      searchStr={searchStr}
      destroyOnClose
    >
      <Table
        size="small"
        dataSource={data}
        columns={cols}
        pagination={false}
        rowKey="id"
        rowSelection={{
          fixed: true,
          hideSelectAll: true,
          preserveSelectedRowKeys: true,
          onSelect: onSelectRow,
          selectedRowKeys,
        }}
      />
    </TableAddDrawer>
  );
}

/* eslint-disable react/forbid-prop-types */
EquipmentTableAddDrawer.propTypes = {
  equipment: PropTypes.array,
  selected: PropTypes.array,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  maxExistingAllowed: PropTypes.number,
};

EquipmentTableAddDrawer.defaultProps = {
  equipment: [],
  selected: [],
  visible: false,
  maxExistingAllowed: null,
};

export default EquipmentTableAddDrawer;
