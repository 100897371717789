export default {
  buttonStyle: {
    width: 85,
    padding: 0,
    textAlign: 'center',
    fontFamily: 'roboto-regular',
  },
};


export const projectCardDimensions = { width: 285, height: 197 };
