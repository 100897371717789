import React from 'react';
import { Row, Col, Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import TitleRow from './TitleRow';
import titleOnlyConfigure from './titleOnlyConfigure';

import PhotoReel from '../../../common/photos/PhotoReel';
import colors from '../../../constants/Colors';

import FieldTriggerFlag from './FieldTriggerFlag';

const preview = ({
  setPreviewProps,
  previewProps = {},
  configProps = {},
  id,
  onOpenFileModal,
  onOpenFile,
  setCurrAttachmentFieldId,
  setOpenFileIndex,
  responses = {},
  setResponses,
  responding = false,
  isDisplay,
  sections,
  isExternalForm,
  projectId,
  templateId,
  divisions,
  fieldTriggerMap = {},
  setFieldTriggerMap,
  name,
  showCondensedView,
  fileMap,
  editing = false,
  setCurrFieldId,
} = {}) => {
  const {
    optional,
    title = 'Title goes here',
    showButton = true,
    fieldTrigger,
  } = configProps;

  const {
    files = [],
    onClick,
    hideDelete,
    timestamps = {},
  } = previewProps;

  const {
    [id]: {
      files: responseFiles = [],
      timestamps: responseTimestamps = {},
    } = {},
  } = responses;

  const onAttachDelete = hideDelete ? null : (idx) => {
    if (responding) {
      // set responses without the file in question
      const newFiles = [...responseFiles];
      newFiles.splice(idx, 1);
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          files: newFiles,
        },
      });
    } else {
      const newFiles = [...files];
      newFiles.splice(idx,1);
      setPreviewProps({
        ...previewProps,
        files:newFiles,
      });
    }
  };
  const onAttachAdd = () => {
    if (responding) {
      onOpenFileModal(id);
    } else {
      const newFiles = files.concat([new File([],`file-${files.length + 1}.png`)]);
      setPreviewProps({
        ...previewProps,
        files:newFiles,
      })
    }
  };

  const onFileClick = (index) => {
    if (responding) {
      if (setOpenFileIndex) setOpenFileIndex(index);
      if (setCurrAttachmentFieldId) setCurrAttachmentFieldId(id);
      onOpenFile(responseFiles[index]);
      setCurrFieldId(id);
    } else {
      onClick(index);
    }
  };

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
        filter={
          fieldTrigger && !isDisplay
            ? (
              <FieldTriggerFlag
                sections={sections}
                isExternalForm={isExternalForm}
                templateId={templateId}
                projectId={projectId}
                divisions={divisions}
                configProps={configProps}
                responding={responding}
                id={id}
                fieldTriggerMap={fieldTriggerMap}
                setFieldTriggerMap={setFieldTriggerMap}
                name={name}
              />
            ) : null
        }
      />
      <Row style={{ marginTop: 5 }}>
        {showButton && !isDisplay && <Col style={{ marginTop: 10, width:60 }}>
          <Row style={{ height: 80}} align='bottom'>
            <Row style={{ height:70}} align='top'>
              <Button
                className='form-attachment-add-button'
                icon={
                  <FileAddOutlined
                    style={{
                      color:colors.ONTRACCR_RED,
                    }}
                  />
                }
                onClick={onAttachAdd}
              />
            </Row>
          </Row>
        </Col>}
        <Col style={{
          width:showButton ? 'calc(100% - 60px)' : '100%',
          ...onClick
          ? { pointerEvents:'all' }
          : {}
        }}>
          <PhotoReel
            fileMap={fileMap}
            editing={editing}
            small
            files={responding ? responseFiles : files}
            timestamps={responding ? responseTimestamps : timestamps}
            onDelete={isDisplay ? null : onAttachDelete}
            onClick={onFileClick}
            showCondensedView={showCondensedView}
          />
        </Col>
      </Row>
    </div>
  );
}

export default {
  configure:titleOnlyConfigure,
  preview,
  title:'Attachment',
  description:'User can add one or more files/photos'
};
