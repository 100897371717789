import React, { useCallback } from 'react';
import { Draggable } from 'react-beautiful-dnd';
import { Row, Col } from 'antd';
import {
  DeleteOutlined,
  DragOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../common/buttons/BorderlessButton';

function BoardStatusCard ({
  id = '',
  orderIndex,
  title,
  showSettings,
  onDeleteClicked,
  onSettingsClicked,
  onTitleChanged,
}) {
  const onChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onTitleChanged({ id, title: value });
  },[onTitleChanged, id]);
  
  return (
    <Draggable draggableId={id.toString()} index={orderIndex}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          className='boards-status-card'
        >
          <Row justify='space-between'>
            <Col flex='18px'>
              <Row justify='center' style={{ height: '100%', paddingRight: 5 }} align='middle'>
              <DragOutlined  {...provided.dragHandleProps} className='boards-status-dragger'/>
              </Row>
            </Col>
            <Col flex='auto'>
              <OnTraccrTextInput
                value={title}
                onChange={onChange}
              />
            </Col>
            <Col flex='30px'>
              {showSettings && (
                <BorderlessButton
                  iconNode={<SettingOutlined style={{ margin: 0 }}/>}
                  onClick={onSettingsClicked}
                />
              )}
            </Col>
            <Col flex='30px'>
              <BorderlessButton
                iconNode={<DeleteOutlined style={{ margin: 0, color:'red' }}/>}
                onClick={onDeleteClicked}
              />
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
}

export default BoardStatusCard;
