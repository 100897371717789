import {
  CREATE_BILLING_RATE,
  GET_BILLING_RATES,
  DELETE_BILLING_RATE,
  UPDATE_BILLING_RATE,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import RatesService from './billingRates.service';

export const createBillingRate = (payload) => async (dispatch) => {
  Analytics.track('BillingRates/Create');
  const { data } = await RatesService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_BILLING_RATE,
    payload: {
      rate: data,
    },
  });
  return true;
};

export const getBillingRates = () => async (dispatch) => {
  const { data } = await RatesService.get();
  if (!data) return false;
  dispatch({
    type: GET_BILLING_RATES,
    payload: {
      rates: data,
    },
  });
  return true;
};

export const deleteBillingRate = (id) => async (dispatch) => {
  const { data } = await RatesService.deleteRate(id);
  if (!data) return false;
  dispatch({
    type: DELETE_BILLING_RATE,
    payload: {
      rateId: id,
    },
  });
  return true;
};

export const updateBillingRate = (id, payload) => async (dispatch) => {
  Analytics.track('BillingRates/Update');
  const { data } = await RatesService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_BILLING_RATE,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};
