import {
  CLEAR_BOARD_CARD_ANALYTICS_CONFIG,
  CREATE_BOARD_CARD_ANALYTICS_REPORT,
  CREATE_BOARD_CARD_DASHBOARD,
  DELETE_BOARD_CARD_ANALYTICS_REPORT,
  DELETE_BOARD_CARD_DASHBOARD,
  FAVORITE_BOARD_CARD_DASHBOARD,
  GET_BOARD_CARD_ANALYTICS_DATA,
  GET_BOARD_CARD_DASHBOARD_ANALYTICS_DATA,
  GET_SAVED_BOARD_CARD_ANALYTICS_REPORTS,
  GET_SAVED_BOARD_CARD_DASHBOARDS,
  SELECT_BOARD_CARD_DASHBOARD,
  SELECT_SAVED_BOARD_CARD_REPORT,
  SET_BOARD_CARD_ANALYTICS_CONFIG,
  SET_BOARD_CARD_DASHBOARD_EDIT,
  SET_INITIAL_BOARD_CARD_DASHBOARD,
  UPDATE_BOARD_CARD_ANALYTICS_FILTERS,
  UPDATE_BOARD_CARD_ANALYTICS_REPORT,
  UPDATE_BOARD_CARD_DASHBOARD,
} from '../../../state/actionTypes';

import {
  initialReports,
  initialDashboard,
  createAnalyticsReport,
  deleteSavedAnalyticsReport,
  getAnalyticsData,
  getSavedAnalyticsReports,
  selectSavedReport,
  setAnalyticsConfig,
  updateAnalyticsFilters,
  updateAnalyticsReport,
  clearAnalyticsConfig,
  getSavedDashboards,
  setDashboardEdit,
  selectDashboard,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  getDashboardAnalyticsData,
  setInitialDashboard,
  favoriteDashboard,
} from '../../../helpers/dashboardAnalytics';

const initialState = {
  dashboard: initialDashboard,
  reports: initialReports,
};

export default function boardAnalyticsActions(state = initialState, action) {
  switch (action.type) {
    case GET_SAVED_BOARD_CARD_ANALYTICS_REPORTS: {
      return getSavedAnalyticsReports(action, state);
    }
    case DELETE_BOARD_CARD_ANALYTICS_REPORT: {
      return deleteSavedAnalyticsReport(action, state);
    }
    case CREATE_BOARD_CARD_ANALYTICS_REPORT: {
      return createAnalyticsReport(action, state);
    }
    case UPDATE_BOARD_CARD_ANALYTICS_REPORT: {
      return updateAnalyticsReport(action, state);
    }
    case SET_BOARD_CARD_ANALYTICS_CONFIG: {
      return setAnalyticsConfig(action, state);
    }
    case UPDATE_BOARD_CARD_ANALYTICS_FILTERS: {
      return updateAnalyticsFilters(action, state);
    }
    case SELECT_SAVED_BOARD_CARD_REPORT: {
      return selectSavedReport(action, state);
    }
    case GET_BOARD_CARD_ANALYTICS_DATA: {
      return getAnalyticsData(action, state);
    }
    case CLEAR_BOARD_CARD_ANALYTICS_CONFIG: {
      return clearAnalyticsConfig(state);
    }
    case GET_SAVED_BOARD_CARD_DASHBOARDS: {
      return getSavedDashboards(action, state);
    }
    case SET_BOARD_CARD_DASHBOARD_EDIT: {
      return setDashboardEdit(action, state);
    }
    case SELECT_BOARD_CARD_DASHBOARD: {
      return selectDashboard(action, state);
    }
    case CREATE_BOARD_CARD_DASHBOARD: {
      return createDashboard(action, state);
    }
    case UPDATE_BOARD_CARD_DASHBOARD: {
      return updateDashboard(action, state);
    }
    case DELETE_BOARD_CARD_DASHBOARD: {
      return deleteDashboard(action, state);
    }
    case GET_BOARD_CARD_DASHBOARD_ANALYTICS_DATA: {
      return getDashboardAnalyticsData(action, state);
    }
    case SET_INITIAL_BOARD_CARD_DASHBOARD: {
      return setInitialDashboard(action, state);
    }
    case FAVORITE_BOARD_CARD_DASHBOARD: {
      return favoriteDashboard(action, state);
    }
    default:
      return state;
  }
}
