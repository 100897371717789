import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col,
  Divider,
  Drawer,
  Menu,
  Row,
  Tooltip,
} from 'antd';
import { DeleteOutlined, PlusOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

// Import Components:
import LiveFeedChannelModal from './LiveFeedChannelModal';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';

// Import Actions/Helpers:
import { deleteCustomChannel } from '../state/livefeed.actions';
import Permissions from '../../../auth/Permissions';
import sortByString from '../../../helpers/helpers';
import colors from '../../../constants/Colors';
import { getChannelType } from '../liveFeedHelpers';

const { Item, SubMenu } = Menu;

function LiveFeedMenu({
  visible,
  selectedChannelId,
  onSelectedChannelChange,
  onClose,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const globalChannel = useSelector((state) => state.livefeed.globalChannel);
  const customChannels = useSelector((state) => state.livefeed.customChannels);
  const projectChannels = useSelector((state) => state.livefeed.projectChannels);

  const [openSection, setOpenSection] = useState();
  const [channelModalOpen, setChannelModalOpen] = useState(false);

  const customChannelOptions = useMemo(() => Object.values(customChannels)
    .sort(sortByString('name')), [customChannels]);
  const projectChannelOptions = useMemo(() => Object.values(projectChannels)
    .sort(sortByString('projectName')), [projectChannels]);

  const handleCustomChannelDelete = useCallback((key) => (e) => {
    e.stopPropagation();
    const { name: channelName = '' } = customChannels[key] || {};
    const title = channelName
      ? `Delete Channel - '${channelName}'?`
      : 'Delete Channel?';
    const content = 'this action will delete all content contained in the channel, would you like to proceed?';
    CustomConfirmModal({
      title,
      content,
      onOk: () => dispatch(deleteCustomChannel(key)),
      okText: 'Delete',
    });
  }, [customChannels]);

  const openCustomChannelModal = useCallback((key) => (e) => {
    e.stopPropagation();
    onSelectedChannelChange({ type: 'custom', key });
    setChannelModalOpen(true);
  }, [onSelectedChannelChange]);

  const closeCustomChannelModal = useCallback(() => {
    onSelectedChannelChange({});
    setChannelModalOpen(false);
  }, [onSelectedChannelChange]);

  const onChannelChange = useCallback(({ key }) => {
    const type = getChannelType({ key, projectChannels, customChannels });
    onSelectedChannelChange({ type, key });
    onClose();
  }, [onSelectedChannelChange, onClose, projectChannels, customChannels]);

  // Ensures that at most only one section is open at any given time
  const onSectionChange = useCallback((sectionKey) => () => (
    setOpenSection((prvSectionKey) => {
      if (prvSectionKey === sectionKey) return null;
      return sectionKey;
    })
  ), []);

  useEffect(() => {
    try {
      // Set current channel to last open channel if possible:
      const lastUsedChannelDetails = window.localStorage.getItem('liveFeedCurrentChannel');
      const {
        type: lastUsedChannelType,
        key: lastUsedChannelId,
      } = JSON.parse(lastUsedChannelDetails);
      onSelectedChannelChange({ type: lastUsedChannelType, key: lastUsedChannelId });
    } catch (error) {
      // Set current channel to global:
      const { id: globalChannelId } = globalChannel || {};
      onSelectedChannelChange({ type: 'global', key: globalChannelId });
    }
  }, [onSelectedChannelChange, globalChannel]);

  return (
    <Drawer
      className="livefeed-menu"
      title="Channels"
      visible={visible}
      placement="left"
      getContainer={false}
      closable={false}
      onClose={onClose}
    >
      <Menu
        mode="inline"
        inlineIndent={13}
        openKeys={openSection ? [openSection] : []}
        selectedKeys={selectedChannelId ? [selectedChannelId] : []}
        onSelect={onChannelChange}
      >
        <Item className="livefeed-menu-item-custom-channel" key={globalChannel?.id} onClick={onSectionChange('global')}>Global</Item>
        {(Permissions.has('CHANNELS_READ') || Permissions.has('CHANNELS_WRITE')) && <Divider className="livefeed-menu-divider" />}
        {(Permissions.has('CHANNELS_READ') || Permissions.has('CHANNELS_WRITE')) && (
          <SubMenu
            key="custom"
            title="Custom"
            onTitleClick={onSectionChange('custom')}
            icon={Permissions.has('CHANNELS_WRITE')
              ? (
                <Tooltip title="Create Channel" mouseLeaveDelay={0}>
                  <PlusOutlined
                    style={{
                      position: 'absolute',
                      padding: 10,
                      right: 0,
                      alignItems: 'center',
                      color: colors.ONTRACCR_BLACK,
                    }}
                    onClick={openCustomChannelModal()}
                  />
                </Tooltip>
              ) : null}
          >
            {customChannelOptions.map(({ name, id }) => (
              <Item className="livefeed-menu-item-custom-channel" key={id}>
                <Row align="middle" justify="space-between">
                  <Col className="livefeed-menu-item-custom-channel-label">{name}</Col>
                  {Permissions.has('CHANNELS_WRITE') && (
                    <Col className="livefeed-menu-item-custom-channel-settings">
                      <Row>
                        <Col>
                          <Tooltip title="Delete Channel" mouseLeaveDelay={0}>
                            <DeleteOutlined
                              style={{ padding: '5 0', color: colors.ONTRACCR_RED }}
                              onClick={handleCustomChannelDelete(id)}
                            />
                          </Tooltip>
                        </Col>
                        <Col>
                          <Tooltip title="Edit Channel" mouseLeaveDelay={0}>
                            <SettingOutlined
                              style={{ padding: '5 0', color: colors.ONTRACCR_BLACK }}
                              onClick={openCustomChannelModal(id)}
                            />
                          </Tooltip>
                        </Col>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Item>
            ))}
          </SubMenu>
        )}
        <Divider className="livefeed-menu-divider" />
        <SubMenu
          key="project"
          title={t('Project')}
          onTitleClick={onSectionChange('project')}
        >
          {projectChannelOptions.map(({ projectName, projectNumber, id }) => (
            <Item className="livefeed-menu-item-custom-channel" key={id}>
              {projectNumber ? `${projectName} - ${projectNumber}` : projectName}
            </Item>
          ))}
        </SubMenu>
      </Menu>
      <LiveFeedChannelModal
        visible={channelModalOpen}
        selectedId={selectedChannelId}
        onClose={closeCustomChannelModal}
      />
    </Drawer>
  );
}

LiveFeedMenu.propTypes = {
  visible: PropTypes.bool,
  selectedChannelId: PropTypes.string,
  onSelectedChannelChange: PropTypes.func,
  onClose: PropTypes.func,
};

LiveFeedMenu.defaultProps = {
  visible: false,
  selectedChannelId: '',
  onSelectedChannelChange: () => null,
  onClose: () => null,
};

export default LiveFeedMenu;
