import axios from 'axios';

import { request } from '../../helpers/requests';

export default {
  get: () => request({
    call: axios.get('/labels'),
    errMsg: 'Could not get labels',
    hideSuccessToast: true,
  }),
  create: (payload) => request({
    call: axios.post('/labels', payload),
    errMsg: 'Could not create label',
  }),
  delete: (id) => request({
    call: axios.delete(`/labels/${id}`),
    errMsg: 'Could not delete label',
  }),
  update: (id, payload) => request({
    call: axios.put(`/labels/${id}`, payload),
    errMsg: 'Could not update label',
  }),
};
