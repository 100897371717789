import React, { useCallback } from 'react';
import { Row, Col, Select } from 'antd';

import OnTraccrNumberInput from './OnTraccrNumberInput';

const opts = [
  { label: 'Days', value: 'days' },
  { label: 'Weeks', value: 'weeks' },
  { label: 'Months', value: 'months' },
];

export default ({
  value: timeFrame = { type: 'months', value: 3 },
  onChange,
}) => {
  const onTypeSelect = useCallback((type) => {
    onChange({
      type,
      value: timeFrame.value,
    })
  }, [timeFrame]);

  const onValueChange = useCallback((newValue) => {
    onChange({
      type: timeFrame.type,
      value: newValue
    })
  }, [timeFrame]);
  return (
    <Row align='middle' style={{ height: 32 }}>
      <Col span={8} style={{ height: 32 }}>
        <OnTraccrNumberInput
          onChange={onValueChange}
          min={0}
          precision={0}
          value={timeFrame.value}
        />
      </Col>
      <Col span={16} style={{ height: 32 }}>
        <Select
          style={{ width: 100 }}
          onSelect={onTypeSelect}
          value={timeFrame.type}
          options={opts}
        />
      </Col>
    </Row>
  )
}