import React from 'react';
import { DownloadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import BorderlessButton from './BorderlessButton';

export default function SliderDownloadButton({
  onDownload,
  style = {},
}) {
  return (
    <BorderlessButton
      className="slider-download-button"
      style={style}
      iconNode={<DownloadOutlined style={{ marginLeft: 0, fontSize: 16 }} />}
      onClick={onDownload}
    />
  );
}

SliderDownloadButton.propTypes = {
  onDownload: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

SliderDownloadButton.defaultProps = {
  style: {},
};
