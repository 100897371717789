import React from 'react';

import PropTypes from 'prop-types';
import LiveFeedEntry from '../dashboard/LiveFeed/LiveFeedEntry';
import LiveFeedPost from '../dashboard/LiveFeed/LiveFeedPost';

export default function NotesRow ({
  note,
  timestamp,
  tags = [],
  user,
  date,
  isCurrentUser,
  hideDivider,
  unread,
  files,
  onPostClick,
  type = '',
}) {
  return <LiveFeedEntry
    unread={unread}
    user={user}
    date={date}
    isCurrentUser={isCurrentUser}
    hideDivider={hideDivider}
    extra={
      <LiveFeedPost
        post={{
          text: note,
          tags,
          files,
          userId: user?.id,
          // Needs createdAt for PostDrawer header
          // https://projectharbour.atlassian.net/browse/HARBOUR-4308
          createdAt: timestamp,
          onPostClick,
        }}
        type={type}/>
    }
  />;
}

NotesRow.propTypes = {
  note: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  user: PropTypes.object, // Assuming 'user' is an object
  date: PropTypes.string,
  isCurrentUser: PropTypes.bool,
  hideDivider: PropTypes.bool,
  unread: PropTypes.bool,
  files: PropTypes.arrayOf(PropTypes.object), // Assuming 'files' is an array of objects
  onPostClick: PropTypes.func,
  type: PropTypes.string,
};
