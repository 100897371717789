import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import CustomerClientPortalAccounts from './CustomerClientPortalAccounts';
import CustomerClientCommunications from './CustomerClientCommunications';
import BadgedTab from '../../common/containers/BadgedTab';

const { TabPane } = Tabs;

const tabStyle = {
  minHeight:'100%',
  width:'100%',
};

export default ({
  id: customerId,
  unreadCount,
}) => {
  const portalAccounts = useSelector(state => state.clientPortal.accounts);
  const hasAccounts = useMemo(() => (
    portalAccounts.filter((acc) => acc.customerId === customerId).length > 0
  ), [customerId, portalAccounts]);

  const [activeKey,setActiveKey] = useState(hasAccounts ? 'comms' : 'accounts');

  useEffect(() => {
    setActiveKey(hasAccounts ? 'comms' : 'accounts');
  },[]); // We dont include hasAccounts as dep because we only want to set active key at first open

  return (
    <Tabs className='customer-client-portal-container' activeKey={activeKey} onChange={setActiveKey}>
      <TabPane tab='Accounts' key='accounts' style={tabStyle}>
        <CustomerClientPortalAccounts customerId={customerId}/>
      </TabPane>
      <TabPane
        tab={
          <BadgedTab
            title='Communication'
            unreadCount={unreadCount}
          />
        }
        key='comms'
        style={tabStyle}
        disabled={!hasAccounts}
      >
        <CustomerClientCommunications customerId={customerId} active={activeKey === 'comms'}/>
      </TabPane>
    </Tabs>
  );
}