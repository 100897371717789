import React from 'react';
import { Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

export default ({
  hasWritePerms,
  editing,
  onCancel,
  onSave,
  onEdit,
  loading
}) => {
  if (!hasWritePerms) return null;
  return (
    editing
      ? <Row gutter={12} style={{ margin:'0px 12px'}}>
          <Col>
          <OnTraccrButton
            title='Cancel'
            type='cancel'
            onClick={onCancel}
          />
          </Col>
          <Col>
          <OnTraccrButton
            title='Save'
            onClick={onSave}
            loading={loading}
          />
            </Col>
        </Row>
      : <BorderlessButton
          title='Rename'
          iconNode={<EditOutlined/>}
          onClick={onEdit}
        />
  );
}