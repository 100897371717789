export default {};

export const displayColumns = [
  {
    title: 'Name',
    dataIndex: 'name',
    width: 200,
  },
  {
    title: 'Number',
    dataIndex: 'number',
    width: 100,
  },
  {
    title: 'Division',
    dataIndex: 'division',
    width: 100,
  },
  {
    title: 'Address',
    dataIndex: 'address',
    width: 100,
  },
  {
    title: 'Active Users',
    dataIndex: 'activeUsers',
    width: 50,
    render: (users) => users?.length ?? 0,
  },
  {
    title: 'Inactive Users',
    dataIndex: 'inactiveUsers',
    width: 50,
    render: (users) => users?.length ?? 0,
  },
];

export const FIELDS_BY_EDIT_STEP = [
  [
    'name',
    'number',
    'divisionId',
    'customer',
    'firstApprover',
    'secondApprover',
    'projectTypeId',
    'geofence',
    'info',
    'qboClassId',
    'address',
  ],
  [
    'customBillingRate',
    'billingRateId',
    'materialDiscount',
    'billingContact',
    'hasContract',
    'contractExpiration',
    'contractReminder',
  ],
];
