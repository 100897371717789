import React from 'react';

export default ({ children, style = {} }) => (
  <div
    style={{
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      borderTop: '1px solid #e9e9e9',
      padding: '10px 16px',
      background: '#fff',
      textAlign: 'right',
      ...style,
    }}
  >
    {children}
  </div>
)