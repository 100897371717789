export default {};

export const getDefaultFilters = (types = []) => ({
  active: new Set([1]),
  typeId: new Set(types.map((type) => type.id)),
});

export const getFilterData = (types = []) => [
  [
    {
      title: 'Archive',
      key: 'active',
      children: [
        {
          title: 'Active',
          key: 1,
        },
        {
          title: 'Archived',
          key: 0,
        },
      ],
    },
  ],
  [
    {
      title: 'Type',
      key: 'typeId',
      children: types.map((type) => ({ title: type.name, key: type.id })),
    },
  ],
];
