import { Drawer, Form } from 'antd';
import {
  React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { createSubtaskTemplate, getSubtaskTemplateFormData, resetSubtaskFormData, updateSubtaskTemplate } from './state/subtasks.actions';
import SubtaskForm from './SubtaskForm';

function SubtaskTemplateDrawer({
  visible,
  onClose,
  selectedTemplateId,
  onSubmit,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();

  const {
    templates,
    formData: templateFormData,
  } = useSelector((state) => state.subtasks);

  const drawerTitle = useMemo(() => (
    selectedTemplateId
      ? 'Edit Template'
      : 'Add Template'
  ));

  const selectedTemplate = useMemo(() => {
    if (!selectedTemplateId) return {};
    return templates.find((template) => template.id === selectedTemplateId) ?? {};
  }, [selectedTemplateId, templates]);

  const {
    reminderEmails = [],
    reminders = [],
  } = selectedTemplate;

  useEffect(() => {
    if (!visible && form) {
      dispatch(resetSubtaskFormData());
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    form.setFieldsValue({
      ...selectedTemplate,
    });
  }, [form, selectedTemplate]);

  useEffect(() => {
    setFormData();
    if (selectedTemplateId) {
      dispatch(getSubtaskTemplateFormData(selectedTemplateId));
    }
  }, [selectedTemplateId]);

  const onSubmitClicked = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      return;
    }
    if (!values) return;

    const payload = { ...values };

    if (values.reminders) {
      payload.reminders = values.reminders.map((reminder) => ({
        time: reminder.time,
        amount: reminder.amount,
      }));
    }

    if (formData) {
      payload.formData = formData;
    }

    if (!payload.formTemplateId) {
      payload.formTemplateId = null;
    }

    let res;
    if (selectedTemplateId) {
      // Edit
      res = await dispatch(updateSubtaskTemplate(selectedTemplateId, payload));
    } else {
      // Add
      res = await dispatch(createSubtaskTemplate(payload));
    }
    onSubmit(res);
  }, [
    form,
    selectedTemplateId,
    onSubmit,
    formData,
  ]);

  const onFormDataChanged = useCallback((newFormData) => {
    setFormData(newFormData);
  }, []);

  return (
    <Drawer
      title={drawerTitle}
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <SubtaskForm
        visible={visible}
        form={form}
        isDisplay={false}
        isTemplate
        selectedTask={selectedTemplate}
        reminderEmails={reminderEmails}
        reminders={reminders}
        initialFormData={templateFormData || {}}
        onFormDataChanged={onFormDataChanged}
      />
      <DrawerSubmitFooter
        onSubmit={onSubmitClicked}
        onClose={onClose}
      />
    </Drawer>
  );
}

SubtaskTemplateDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedTemplateId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

SubtaskTemplateDrawer.defaultProps = {
  visible: false,
  selectedTemplateId: null,
};

export default SubtaskTemplateDrawer;
