import React, { useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col } from 'antd';
import { useDrop } from 'react-dnd';
import { DateTime } from 'luxon';

import ScheduleCalendarDay from '../ScheduleCalendarDay';
import {
  splitMultiDayShifts,
  prepareDragShiftUpdatePayload,
} from '../scheduleHelpers';

import {
  updateShift
} from '../state/schedule.actions';

export default ({
  day,
  weekStart,
  monthStart,
  monthEnd,
  onDaySelect,
  onShiftSelect,
  height,
  shifts = [],
}) => {
  const dispatch = useDispatch();
  const companySettings = useSelector((state) => state.settings.company);

  const [{ isOver }, drop] = useDrop(() => ({
    accept: ['MonthShiftDayEntry', 'MonthMultiDayEntry', 'MonthMultiDayEntrySimple'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
    drop: ({ shift }) => {
      const shiftStartDT = DateTime.fromMillis(shift.startTime);
      if (shiftStartDT.hasSame(day, 'day')) return;

      const { emailNotification, pushNotification } = companySettings?.settings ?? {};
      const payload = prepareDragShiftUpdatePayload({
        shift,
        startOfNewDay: day,
        emailNotification,
        pushNotification,
        updateUsers: false,
      });
      dispatch(updateShift(shift.id, payload));
    },
  }));

  const textClass  = day < monthStart || day > monthEnd
  ? 'schedule-month-day-text-faded'
  : 'schedule-month-day-text';

  const { multiDay, interDay } = useMemo(() => {
    const sortedShifts = shifts.sort((a, b) => a.startTime - b.startTime);
    return splitMultiDayShifts(sortedShifts, day);
  }, [shifts, day]);

  return (
    <Col
      className='schedule-month-day'
      ref={drop}
      style={{
        boxShadow: isOver ? '0px 0px 10px lightgray' : '',
      }}
    >
      <span className={textClass} onClick={() => onDaySelect(day)}>
        {day.day}
      </span>
      <ScheduleCalendarDay
        day={day}
        weekStart={weekStart}
        shifts={shifts}
        height={height}
        onShiftSelect={onShiftSelect}
        multiDay={multiDay}
        interDay={interDay}
      />
    </Col>
  );
}
