import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Popover, Row } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

import BorderlessButton from './BorderlessButton';

// Constants:
const DEFAULT_MORE_BUTTON_STYLE = {
  fontSize: 25,
  height: 30,
  paddingTop: 2.5,
};

/** More Button Component */
function MoreButton({
  options = [],
  trigger = 'click',
  placement = 'bottomRight',
  showMoreBadge = false,
  btnStyle = {},
}) {
  const [visible, setVisible] = useState(false);

  const moreOptions = useMemo(() => (
    <div>
      {options.map(({ title = '', icon = '', iconNode, style = {}, onClick }) => (
        <Row key={title}>
          <BorderlessButton
            title={title}
            icon={icon}
            iconNode={iconNode}
            style={style}
            onClick={() => {
              setVisible(false);
              onClick();
            }}
          />
        </Row>
      ))}
    </div>
  ), [options]);

  return (
    <Popover
      trigger={trigger}
      placement={placement}
      content={moreOptions}
      visible={visible}
      onVisibleChange={setVisible}
    >
      <Badge dot count={showMoreBadge ? 1 : 0 }>
        <MoreOutlined className='more-button' style={{
          ...DEFAULT_MORE_BUTTON_STYLE,
          ...btnStyle,
        }}/>
      </Badge>
    </Popover>
  );
}

MoreButton.propTypes = {
  options: PropTypes.array.isRequired,
  trigger: PropTypes.string,
  placement: PropTypes.string,
  showMoreBadge: PropTypes.bool,
  btnStyle: PropTypes.object,
};

export default MoreButton;
