import { DateTime, IANAZone } from 'luxon';

import { isNullOrUndefined } from '../../helpers/helpers';

export default {};

const parseWorkingHours = (dt, myHours) => {
  const sql = dt.toSQLDate();
  const startDT = DateTime.fromSQL(`${sql} ${myHours.startTime}:00`, { zone: dt.zone });
  const endDT = DateTime.fromSQL(`${sql} ${myHours.endTime}:00`, { zone: dt.zone });
  const isOvernight = endDT < startDT;
  return {
    isOvernight,
    startDT,
    endDT,
  };
};

const dtIsWorkingHours = (dt, myHours) => {
  const {
    startDT,
    endDT,
    isOvernight,
  } = parseWorkingHours(dt, myHours);
  if (!isOvernight) return dt >= startDT && dt <= endDT;
  return (
    (dt >= startDT && dt <= startDT.endOf('day'))
    || (dt >= endDT.startOf('day') && dt <= endDT)
  );
};

export const nowIsWorkingHours = (myHours = {}) => {
  if (!myHours.startTime || !myHours.endTime || myHours.allowOutside) return true;
  return dtIsWorkingHours(DateTime.local(), myHours);
};

export const taskIsOutsideWorkingHours = (task = {}, myHours = {}) => {
  if (
    !task
    || isNullOrUndefined(task?.startTime)
    || task?.hourBased
    || !myHours?.startTime
    || !myHours?.endTime
  ) return false;
  const {
    startTime,
    endTime,
    timezone,
  } = task;
  const zone = timezone && IANAZone.isValidZone(timezone)
    ? IANAZone.create(timezone)
    : DateTime.local().zone;

  const startDT = DateTime.fromMillis(startTime).setZone(zone);
  const endDT = !isNullOrUndefined(endTime)
    ? DateTime.fromMillis(endTime).setZone(zone)
    : DateTime.local().setZone(zone);

  if (!dtIsWorkingHours(startDT, myHours)) return true;
  if (!dtIsWorkingHours(endDT, myHours)) return true;
  /*
    Need to handle the scenario where
    both start and end time are in working hours
    but still time is logged outside of working hours

    1. Multi day shift
    2. Overnight hours from 9pm to 5am and user clocks in at 4am and out at 10pm.
  */
  const dayDiff = endDT.diff(startDT, 'days').as('days');
  if (dayDiff > 1) return true; // Multiday

  const {
    isOvernight,
    startDT: workingHoursStart,
  } = parseWorkingHours(endDT, myHours);
  if (!isOvernight) return false; // Should be caught by line 47/48
  const {
    endDT: workingHoursEnd,
  } = parseWorkingHours(startDT, myHours);

  return (
    startDT >= workingHoursEnd.startOf('day') && startDT <= workingHoursEnd
    && endDT <= workingHoursStart.endOf('day') && endDT >= workingHoursStart
  );
};
