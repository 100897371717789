import React, {
  useCallback,
  useMemo,
  useState,
  useEffect,
  useRef,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Drawer, Row } from 'antd';
import { FormInstance } from 'antd/lib/form'; //eslint-disable-line
// Need this FormInstance to work with legacy version of antd form in ProjectAddView
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import config from '../../config';
import Permissions from '../../auth/Permissions';


import DrawerFooter from '../containers/DrawerFooter';
import OnTraccrButton from '../buttons/OnTraccrButton';

import ProjectAddView from '../../projects/projectAddView';
import { prepareProjectPayload } from '../../projects/projectHelpers';
import { createProject } from '../../projects/state/projects.actions';

import { createNuxEntry } from '../../nux/state/nux.actions';
import { getCompanyCustomizationTabs } from '../../settings/state/settings.actions';
import { isNullOrUndefined } from '../../helpers/helpers';
import { formatCustomFieldCreatePayload } from '../../helpers/costcodeHelpers';

const ADD_EDIT_TABS = ['Billing', 'Files', 'Cost Codes', 'Equipment', 'Budget Tracking'];

export default function AddProjectDrawer({
  customerId,
  name,
  visible,
  onClose,
  onAddNew,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const form = useRef(FormInstance);

  const nux = useSelector((state) => state.nux.nux);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const {
    company: {
      settings = {},
    } = {},
    divisions,
    positionNames,
    approverPositions,
  } = useSelector((state) => state.settings);
  const tabs = useSelector((state) => state.settings.tabs);

  const [currentStep, setCurrentStep] = useState(0);
  const [showFiles, setShowFiles] = useState(false);
  const [lastOpen, setLastOpen] = useState(0);
  const [values, setValues] = useState({});
  const [projectType, setProjectType] = useState(null);
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const numAddSteps = useMemo(() => {
    const displayedTabs = tabs
      .filter((tab) => tab.category === 'Projects' && tab.enabled && tab.projectTypeId === projectType)
      .map((tab) => tab.tabName);
    let addSteps = 1;
    ADD_EDIT_TABS.forEach((tab) => {
      if (displayedTabs.includes(tab)) {
        if ((tab === 'Equipment' && !config.showEquipment)
          || (tab === 'Files' && !Permissions.has('FILES_WRITE'))
        ) {
          return;
        }
        addSteps += 1;
      }
    });
    return addSteps;
  }, [tabs, projectType]);

  const onCreateNuxEntry = useCallback((nuxEntry) => (
    dispatch(createNuxEntry(nuxEntry))
  ), []);

  const onValuesChanged = useCallback((key, value) => {
    const newValues = { ...values };
    const {
      [key]: existing,
    } = newValues;
    if (value !== existing) { // Prevent infinite loop by only updating if there is a change.
      newValues[key] = value;
      setValues(newValues);
    }

    if (key === 'projectTypeId') {
      setProjectType(value);
    }
  }, [values]);

  const validateCustomFields = useCallback((formValues) => {
    const payload = formatCustomFieldCreatePayload({
      payload: formValues,
      customFields,
    });

    if (payload?.error || !payload) {
      setErrors(payload?.errorMap);

      throw new Error('Validation error with custom fields');
    }

    setErrors({});
  }, [customFields]);

  const onNext = useCallback(async () => {
    try {
      const formValues = await form.current.validateFields();
      if (currentStep === 0) {
        validateCustomFields(formValues);
      }

      if (currentStep < (numAddSteps) - 1) {
        setCurrentStep(currentStep + 1);
      } else {
        // Create the project
        const {
          payload,
          ourFileMap,
          divisionName,
        } = prepareProjectPayload({
          formValues,
          storedValues: values,
          showFiles,
          divisions,
          customFields,
        });

        if (isNullOrUndefined(payload.customerId)) {
          delete payload.customerId;
        }

        const result = await dispatch(createProject(payload, ourFileMap, divisionName));

        if (result) {
          onAddNew(result);
          onClose();
        }
      }
    } catch (err) {
      // Error in the validation, dont progress
    }
  }, [
    currentStep,
    form,
    values,
    showFiles,
    numAddSteps,
    customFields,
    onAddNew,
    validateCustomFields,
  ]);

  const onBack = useCallback(() => setCurrentStep(currentStep - 1), [currentStep]);

  useEffect(() => {
    if (!visible) {
      setCurrentStep(0);
      setLastOpen(DateTime.local().toMillis());
    } else {
      dispatch(getCompanyCustomizationTabs());
    }
  }, [visible]);

  const globalCodes = useMemo(() => costcodes.filter((cc) => cc.type === 'global'), [costcodes]);

  return (
    <Drawer
      title={`Add ${t('Project')} ${name ? `to ${name}` : ''}`}
      width={950}
      onClose={onClose}
      visible={visible}
    >
      <ProjectAddView
        key={lastOpen}
        isAdd
        costcodes={globalCodes}
        settings={settings}
        users={users}
        projects={projects}
        approverPositions={approverPositions}
        positionNames={positionNames}
        onValuesChanged={onValuesChanged}
        createNuxEntry={onCreateNuxEntry}
        nux={nux}
        history={history}
        divisions={divisions}
        phases={[]}
        customerId={customerId}
        onShowFilesChanged={setShowFiles}
        formRef={form}
        currentStep={currentStep}
        showFiles={showFiles}
        tabs={tabs}
        projectTypes={projectTypes}
        hideBucketDropdown
        errors={errors}
        customFields={customFields}
        setCustomFields={setCustomFields}
      />
      <DrawerFooter>
        <Row justify="end" align="middle" gutter={8}>
          <OnTraccrButton
            title="Cancel"
            type="cancel"
            id="customer-add-project-footer-cancel"
            onClick={onClose}
          />
          {currentStep > 0 && (
            <OnTraccrButton
              title="Back"
              type="back"
              id="customer-add-project-footer-back"
              onClick={onBack}
            />
          )}
          <OnTraccrButton
            title={currentStep < (numAddSteps - 1) ? 'Next' : 'Submit'}
            onClick={onNext}
          />
        </Row>
      </DrawerFooter>
    </Drawer>
  );
}

AddProjectDrawer.propTypes = {
  customerId: PropTypes.string,
  name: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onAddNew: PropTypes.func.isRequired,
};

AddProjectDrawer.defaultProps = {
  customerId: null,
  name: '',
};
