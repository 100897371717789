import React, { useCallback, useState } from 'react';
import { Upload, message } from 'antd';
import { PictureOutlined } from '@ant-design/icons';

import FullPhoto from '../common/photos/FullPhoto';

import colors from '../constants/Colors';

const isImage = (file) => {
  if (file.type === 'image/png' || file.type === 'image/jpeg') return true;
  message.error('Error: Equipment picture must be a JPEG or PNG');
  return false;
}
export default ({
  isDisplay,
  value,
  onChange,
}) => {
  const [previewProps, setPreviewProps] = useState({});

  const onCloseImage = useCallback(() => setPreviewProps({}),[]);
  const onUpload = useCallback(({ file, onSuccess, onError }) => {
    if (!isImage(file)) {
      setImmediate(() => onError());
      return;
    }
    setImmediate(() => onSuccess());
    onChange(file);
  },[onChange]);
  const onRemove = useCallback(() => onChange(),[onChange]);
  const onPreview = useCallback(() => {
    setPreviewProps({ file: value });
  },[value]);

  const imgSrc = value && value instanceof File ? URL.createObjectURL(value) : value;
  let body;
  if (isDisplay) {
    if (value) {
      body = (
        <img
          className='equipment-profile-picture'
          src={imgSrc}
          onClick={() => setPreviewProps({ url: imgSrc })}
          onError={(i) => {
            i.target.style.display='none';
          }}
        />
      );
    }
  } else {
    body = (
      <Upload
        listType='picture-card'
        customRequest={onUpload}
        onRemove={onRemove}
        onPreview={onPreview}
        {
          ...value
            ? {
              fileList: [{...value, url: URL.createObjectURL(value)}]
            }
            : {}
        }
      >
        {

          !value &&
          <div>
            <PictureOutlined style={{ fontSize: 30, color: colors.ONTRACCR_RED  }}/>
           </div>
        }
      </Upload>
    );
  }

  return (
    <>
      {body}
      <FullPhoto
        {...previewProps}
        onClose={onCloseImage}
        type='image'
      />
    </>
  )
};
