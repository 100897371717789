import React from 'react';
import ReactDOM from 'react-dom';

import { Divider } from 'antd';

export default ({
  workflowPreview,
  pdfPreview
}) => {
  return ReactDOM.createPortal(
    <div className='form-library-preview-modal' id='form-library-portal'>
      <div className='form-library-preview-modal-container'>
        <img src={workflowPreview} className='form-library-preview-image'/>
        <Divider className='form-library-preview-divider'/>
        <img src={pdfPreview} className='form-library-preview-image'/>
      </div>
    </div>
  , document.body)
}