export const getLabelTypes = (t) => [
  { value: 'users', label: 'User Labels' },
  { value: 'equipment', label: 'Equipment Labels' },
  { value: 'projectGanttSchedule', label: 'Gantt Labels' },
  { value: 'invoices', label: 'Invoice Status Labels' },
  { value: 'customers', label: `${t('Customer')} Labels` },
  { value: 'vendors', label: 'Vendor Labels' },
];

export const STATUS_TYPES = [
  { value: 'equipment', label: 'Equipment Statuses' },
];
