import React from 'react';

import ScheduleHour from './ScheduleHour'

const hours = new Array(24).fill(1);
export default ({ hideGrid, hideFirst, className }) => (
  <div className={className}>
    {hours.map((_,idx) => 
      <ScheduleHour
        key={idx}
        id={idx}
        hideGrid={hideGrid || (hideFirst && idx === 0)} // Hide first row when we are in week mode
      />
    )}
    
    {!hideGrid && <div className='schedule-divider'/>}
    {!hideGrid && <div className='schedule-hour-text'>12 AM</div>}
  </div>
)