import PropTypes from "prop-types"
import React, { useCallback } from 'react';
import { Row, Select } from 'antd';

import FormVariableTextInput from './selectors/FormVariableTextInput';

export default function EmailBody({
  id,
  data,
  setDataMap,
  fields = [],
  visible,
  dateTimeFields = [],
}) {
  const { text, bodyField } = data || {};

  const onDataChange = useCallback((key) => (newValue) => {
    if (setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            [key]: newValue,
          },
        };
      });
    }
  }, [id, setDataMap]);

  const onSelect = useCallback((newField) => {
    if (setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            bodyField: newField,
          },
        };
      });
    }
  }, [id, setDataMap]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Default Email Body:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <FormVariableTextInput
          textAreaProps={{
            defaultValue: text,
          }}
          onTextChange={onDataChange('text')}
          text={text}
          visible={visible}
          additionalVariables={dateTimeFields}
        />
      </Row>
      <Row style={{ marginTop: 5 }}>
        Use field input as body:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          optionLabelProp="label"
          value={bodyField}
          dropdownMatchSelectWidth={false}
          onSelect={onSelect}
          allowClear
          style={{ width: '100%' }}
          filterOption={(input, option) => typeof (option.props.children) === 'string'
              && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
        >
          {
              fields.map((opt) => (
                <Select.Option key={opt.id} value={opt.id} label={opt.label} title={opt.label}>
                  {opt.children}
                </Select.Option>
              ))
            }
        </Select>
      </Row>
    </>
  );
}

EmailBody.propTypes = {
  data: PropTypes.shape({}),
  // eslint-disable-next-line react/forbid-prop-types
  fields: PropTypes.array,
  id: PropTypes.string,
  setDataMap: PropTypes.func,
  dateTimeFields: PropTypes.arrayOf(PropTypes.shape({})),
};

EmailBody.defaultProps = {
  data: undefined,
  fields: [],
  id: undefined,
  setDataMap: undefined,
  dateTimeFields: [],
};
