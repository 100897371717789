import React from 'react';
import { Handle } from 'react-flow-renderer';
import {
  ArrowDownOutlined,
} from '@ant-design/icons';

export default ({
  id,
  style,
  disabled,
  type = 'source',
  position = 'bottom'
}) => (
  <Handle
    className={position === 'top' ? 'workflow-handle-top' : 'workflow-handle-bottom'}
    id={id}
    type={type}
    position={position}
    style={{
      ...style,
      ...disabled 
       ? { pointerEvents: 'none' }
       : {}
    }}
  >
    <ArrowDownOutlined style={{ pointerEvents:'none'}}/>
  </Handle>
);
