import React from 'react';

import NuxDetailPane from '../NuxDetailPane';
import overtimeRules from '../../assets/images/NUX/overtimeRules.png';

const geoDesc = `Define weekly, daily and daily double-pay
overtime rules. Each of these settings can be enabled or
disabled easily`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    rows={[{
      title:'',
      description:geoDesc,
      cols:[0,10,14],
      image:{
        src:overtimeRules,
        style:{
          height:200,
          width:360,
        }
      },
    }]}
  />
);
