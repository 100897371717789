import React from 'react';

import IntegrationCostcodeSync from '../IntegrationCostcodeSync';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Items from QuickBooks.
    <br/><br/>1.  Make sure the Items have a code, name and description set.
    <br/><br/>2. Items with codes or names that conflict with existing Ontraccr Costcodes will not be imported.
    <br/><br/>If you would rather see exactly which data will be imported from QuickBooks and fill in any missing fields, disable Auto Import to do this manually.
  </div>
);

const importHelpText = (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new Items from QuickBooks 
    or <b>Link</b> QuickBooks Items to existing Ontraccr Cost Codes
    <br/><br/> <b>Import:</b> Cost Codes must have code, name and description to be imported into Ontraccr.
    <br/> <b>Link:</b> Select an existing Ontraccr Cost Code to link with the QuickBooks Item.
  </div>
);

export default ({
  quickbooksCostcodes,
  onCostcodesChanged,
  importCostcodes,
  divisionFilter,
}) => (
  <IntegrationCostcodeSync
    integrationCodes={quickbooksCostcodes}
    title='QuickBooks'
    integrationKey='intuitId'
    onCostcodesChanged={onCostcodesChanged}
    autoImportContent={autoImportContent}
    importHelpText={importHelpText}
    importCostcodes={importCostcodes}
    divisionFilter={divisionFilter}
  />
);
