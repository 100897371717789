import React, { useState, useCallback } from 'react';
import { Row, Col, Spin } from 'antd';

import FilePreview from '../../common/photos/FilePreview';

export default ({
  files = [],
  onFileClick: propOnFileClick,
}) => {
  const [loading, setLoading] = useState();

  const onFileClick = useCallback((file) => async (e) => {
    e.stopPropagation();
    setLoading(file.attachmentId);
    await propOnFileClick(file);
    setLoading();
  },[propOnFileClick]);

  if (files.length === 0) return null;
  return (
    <Row className='board-card-email-attachments'>
      {
        files.map((file) => (
          <Col>
            <div
              className='photo-preview-container'
              style={{
                height: 50,
                width: 50,
              }}
            >
            {
              loading === file.attachmentId
              ? <Row justify='center' align='middle' style={{ height: '100%', width: '100%'}}><Spin size='small'/></Row>
              :  <FilePreview
                file={file}
                onClick={onFileClick(file)}
              />
            }
          
            </div>
          </Col>
        ))
      }
    </Row>
  )
}