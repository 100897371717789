import React, { useState } from 'react';
import { Tooltip, Typography } from 'antd';

import BorderlessButton from '../common/buttons/BorderlessButton';

export default ({
  team,
  onTeamSelected,
  onItemHover,
}) => {
  const [isTruncated,setIsTruncated] = useState();
  return (
    <Tooltip 
      destroyTooltipOnHide
      onItemHover={onItemHover}
      placement='right'
      title={isTruncated ? team.name : undefined}
    >
      <BorderlessButton  style={{
        width: '6vw',
        textAlign: 'left',
        padding: '0 0 0 0'
      }}
      onClick={() => {
        setIsTruncated(false);
        onTeamSelected(team);
      }}
      title={
        <Typography.Paragraph style={{ width: '100%'}} ellipsis={{
          rows:1,
          onEllipsis:setIsTruncated,
        }}>
          {team.name}
        </Typography.Paragraph>
      }/>
    </Tooltip>
  );
}