import React, { useCallback, useState} from 'react';

export default ({
  headerOptions = {},
  rowHeight,
  rows = [],
  style = {},
  onResize,
}) => {
  const [moveStart,setMoveStart] = useState(null);
  const [moveIndex,setMoveIndex] = useState(null);
  const onMouseDown = useCallback((index) => (e) => {
    if(!onResize) return;
    e.stopPropagation();
    const { clientX } = e;
    setMoveStart(clientX);
    setMoveIndex(index);
  },[onResize]);
  const onMouseMove = useCallback((e) => {
    if(moveStart === null || !onResize || moveIndex === null) return;
    e.stopPropagation();
    const { clientX } = e;
    const deltaX = (clientX - moveStart);
    setMoveStart(clientX);
    onResize({ index: moveIndex, deltaX });
  },[onResize, moveStart, moveIndex]);
  const onMouseUp = useCallback((e) => {
    e.stopPropagation();
    setMoveStart(null);
    setMoveIndex(null);
  },[]);
  const {
    headerHeight = rowHeight,
  } = headerOptions;
  const headerStyle = { ...headerOptions, height: headerHeight, };
  return (
    <table className='pdf-designer-table' style={{ width: style.width }}>
      <tbody>
      {rows.map(({ cols = [], isHeader }, rowIdx) => {
        if(isHeader) {
          return (
            <tr key={rowIdx}>
              {cols.map((item,index) => 
                <th key={index} className='pdf-designer-table-header' style={{
                  ...headerStyle,
                  width: item.width,
                }}>
                   <div className='pdf-designer-table-text'>{item.value}</div>
                  {(moveIndex === null || index === moveIndex) && <div
                    className='pdf-designer-table-column-resizer'
                    onMouseDown={onMouseDown(index)}
                    onMouseMove={onMouseMove}
                    onMouseUp={onMouseUp}
                    onMouseLeave={onMouseUp}
                    style={index === moveIndex ? {
                      width:200,
                      transform: 'translateX(100px)',
                    } :  {}}
                  />}
                </th>
              )}
            </tr>
          )
        } 
        return (
          <tr style={{ ...style, height: rowHeight }}>
          {cols.map((item,index) => 
            <td key={index} className='pdf-designer-table-item' style={style}>
                <div className='pdf-designer-table-text'>{item.value}</div>
              {/* <div className='pdf-designer-table-column-resizer'/> */}
            </td>)}
          </tr>
        )
      })}
      </tbody>
    </table>
  );
}
