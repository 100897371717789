import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { Row, Col } from 'antd';
import { TaskHelpers } from 'ontraccr-common';

import ProfileAvatar from '../common/ProfileAvatar';
import ListCard from '../common/ListCard';
import ClockTimeCard from '../common/clock/ClockTimeCard';
import UserWeekView from './UserWeekView';

import UserLabels from './UserLabels';

import { getCardColor } from '../helpers/clock';
import { hasPermissions } from './userHelpers';

import Permissions from '../auth/Permissions';
import { getRuntime, roundDayRuntime } from '../timecards/timecard.helpers';

const formatRuntime = (duration) => {
  if (!duration) return '--';
  const minutes = Math.floor((duration / (1000 * 60)) % 60),
  hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
  return `${hours < 10 ? '0' + hours : hours}${minutes < 10 ? '0' + minutes : minutes}`;
}

export default (props) => {
    const {
      style = {},
      project = {
        name:'--'
      },
      costcode = {
        code:'--'
      },
      active,
      id,
      // firstDayOfRunningPayPeriod if needed
      roundingInterval = 1,
      roundingSetting,
      roundingType,
      isOwner = false,
    } = props;

    const {
      [id]: userLabels = [],
    } = useSelector(state => state.users.userToLabel);
    const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

    const tasks = timeEntryUserMap[id] ?? [];
    const formattedDay = useMemo(() => {
      return TaskHelpers.formatTodaysTasks(tasks);
    }, [timeEntryUserMap, id]);

    const taskLen = tasks.length;
    const clockTimeProp = {};
    let lastTask = taskLen > 0 ? tasks[taskLen - 1] : {
      project,
      costcode,
    };

    if(lastTask.endTime && !TaskHelpers.taskIsToday(lastTask)) {
      lastTask = {};
    }

    const formattedPosition = Permissions.formatPosition(props.position);


    const onArchive = hasPermissions(props) && !isOwner ?
      () => props.onArchive(props) : null;

    const onDelete = hasPermissions(props) && !isOwner ?
    () => props.onDelete(props) : null;

    const onEdit = hasPermissions(props) && (!isOwner || Permissions.id === id) ?
      () => props.onEdit(props) : null;

    const canClock = (Permissions.id === id && Permissions.has('TIME_TRACKING_SELF'))
      || Permissions.has(`TIME_TRACKING_${formattedPosition}`);
    
    const canViewCards = (
      (Permissions.id === id && Permissions.has('TIMECARD_VIEW_SELF'))
      || Permissions.has(`TIMECARD_VIEW_${formattedPosition}`)
    )

    const weekStart = moment().weekday(1).startOf('day');
    // shows first week of running pay period
    const tasksThisWeek = useMemo(() => (
      tasks.filter(t => t.startTime >= weekStart && t.startTime < weekStart.clone().add(1,'w'))
    ),[tasks, weekStart])
    const days = useMemo(() => {
      const d = [0,0,0,0,0,0,0];
      for (const task of tasksThisWeek) {
        const weekday = parseInt(moment(TaskHelpers.getStartTime(task)).format('E'))
        const runtimes = TaskHelpers.getRuntimes(task, false);
        d[weekday-1]+= runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
      }

      return d.map((runtime) => (
        roundDayRuntime(runtime, roundingInterval, roundingType, roundingSetting)
      ));
    }, [tasksThisWeek, roundingInterval, roundingType, roundingSetting]);
    return (
      <ListCard
        onArchive={onArchive}
        onEdit={onEdit}
        onDelete={onDelete}
        onClick={hasPermissions ? () => props.onClick(props) : null}
        style={style}
        isActive={active}
      >
        <Row justify='start' type='flex' align='top'>
          <Col
            span={23}
            style={{maxWidth:'80%', marginTop:12.5}}
            onClick={hasPermissions ? () => props.onClick(props.idx) : null}
          >
            <ProfileAvatar
              name={props.name}
              role={props.position}
            />
          </Col>
        </Row>
        <Row style={{ marginTop:5,marginLeft:20}}>
          <Col span={14}>
            <Row style={{ flex: 1 }}>
              <UserLabels userId={id} size='small'/>
            </Row>
            <Row
              align='middle'
              justify='center'
              style={{
                height: userLabels.length === 0 ? '100%' : 'inherit',
                weight: '100%',
                marginTop: userLabels.length === 0 ? 0 : 5,
              }}
            >
              <ClockTimeCard
                formattedDay={formattedDay}
                key={lastTask.id}
                task={formattedDay?.currentTask}
                project={lastTask.project ? lastTask.project.name : '--'}
                costcode={lastTask.costcode ? lastTask.costcode.code : '--'}
                onClick={canClock ? () => props.onUserClock(props) : null}
                style={{
                  maxHeight:55,
                  maxWidth:146,
                  backgroundColor:getCardColor(lastTask),
                  flex: 1,
                }}
                alwaysFrozen={lastTask.type === 'break'}
                {...clockTimeProp}
                showTooltip
              />
            </Row>
          </Col>
          <Col span={10}>
            {
              canViewCards
              ? (
                <UserWeekView
                  style={{ marginLeft:20, }}
                  days={days.map(d=>formatRuntime(d))}
                  onClick={props.onTimeCardClick}
                  tasks={tasks}
                  userId={id}
                />
              )
              : <div style={{ height: 105 }}/>
            }

          </Col>
        </Row>
      </ListCard>
    );
};
