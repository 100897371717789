import React from 'react';
import PropTypes from 'prop-types';
import { Row, Divider } from 'antd';

function ManualEntrySignature({
  signatureUrl,
}) {
  if (!signatureUrl) return null;
  return (
    <>
      <Divider style={{ margin: 0 }} />
      <Row justify="space-around" type="flex" style={{ position: 'relative' }}>
        <span
          style={{
            position: 'absolute',
            top: 10,
            left: 10,
          }}
        >
          Signature
        </span>
        <img
          alt="signature"
          className="card-shadow"
          src={signatureUrl}
          style={{
            borderRadius: 5,
            height: 80,
            marginTop: 10,
          }}
        />
      </Row>
    </>
  );
}

ManualEntrySignature.propTypes = {
  signatureUrl: PropTypes.string,
};

ManualEntrySignature.defaultProps = {
  signatureUrl: null,
};

export default ManualEntrySignature;
