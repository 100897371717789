import React from 'react';

import { 
  USER_ATTRIBUTES_TYPE,
  USER_SHORTCUT_TYPE,
} from '../nux.constants';

import NuxTabPane from '../NuxTabPane';

import NuxUserAttributes from './NuxUserAttributes';
import NuxUserShortcuts from './NuxUserShortcuts';

import userAttributes from '../../assets/images/NUX/userAttributes.svg';
import userShortcuts from '../../assets/images/NUX/userShortcuts.svg';

import Analytics from '../../helpers/Analytics';

const attributesTitle = 'Setting User Attributes';
const shortcutTitle = 'User Card Shortcuts';

export default ({
  setDetailView,
  setTitle,
  setDescription,
  resetToDefault,
  history,
  onCloseClicked,
  nux = new Set(),
}) => (
  <NuxTabPane
    setDetailView={setDetailView}
    setTitle={setTitle}
    setDescription={setDescription}
    buttons={[
      {
        title: attributesTitle,
        description:'User profiles in Ontraccr can have a lot of attributes, including the following:',
        complete:nux.has(USER_ATTRIBUTES_TYPE),
        src:userAttributes,
        detailView:(
          <NuxUserAttributes
            onBack={resetToDefault}
            onTry={() => {
              Analytics.track('NUX/TryIt', { NuxPage: attributesTitle });
              history.push('/users');
              onCloseClicked();
            }}
          />
         ),
      },
      {
        title: shortcutTitle,
        description:'User cards have some key shortcuts on them for you to manage different actions for the user:',
        complete:nux.has(USER_SHORTCUT_TYPE),
        src:userShortcuts,
        detailView:(
          <NuxUserShortcuts
            onBack={resetToDefault}
            onTry={() => {
              Analytics.track('NUX/TryIt', { NuxPage: shortcutTitle });
              history.push('/users');
              onCloseClicked();
            }}
          />
         ),
      }
    ]}
  />
);
