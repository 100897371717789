import React, { useMemo } from 'react';
import { Drawer, Divider, Table } from 'antd';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

import WorkflowConfigureSourceSelector from './selectors/WorkflowConfigureSourceSelector';

import { getSourceFieldMap } from './workflowHelpers';

export default function WorkflowSimpleMappingConfigureDrawer({
  visible,
  onClose,
  onSubmit,
  sourceSections = [],
  fieldMappings = {},
  onFieldMappingsChange,
  fields = [],
  fieldRenderMap = {},
  requiredFields = new Set(),
  title,
  targetHeader,
}) {
  const sourceFieldMap = useMemo(() => getSourceFieldMap(sourceSections), [sourceSections]);
  const columns = [{
    dataIndex: 'sourceField',
    title: 'Source Field',
    width: 425,
    render: (_, record) => {
      const {
        key,
        type, // TODO: change all existing type keys to a types array
        types = [],
        dataType,
      } = record;
      
      let newType;
      if (dataType) {
        newType = `${type} - ${dataType}`;
      } else {
        newType = type;
      }

      const {
        [newType]: sourceFields = [],
      } = sourceFieldMap;

      let eligibleSourceFields = sourceFields;

      if (types.length) {
        types.forEach((typeValue) => {
          const {
            [typeValue]: typeSourceFields = [],
          } = sourceFieldMap;

          eligibleSourceFields = eligibleSourceFields.concat(typeSourceFields);
        });
      }

      const {
        [key]: defaultValue,
      } = fieldMappings;

      if (key in fieldRenderMap) {
        return fieldRenderMap[key]({
          fieldMappings,
          sourceFieldMap,
          defaultValue,
          onFieldMappingsChange,
        });
      }

      return (
        <WorkflowConfigureSourceSelector
          fieldMappings={fieldMappings}
          defaultValue={defaultValue}
          onFieldMappingsChange={onFieldMappingsChange}
          targetId={key}
          eligibleSourceFields={eligibleSourceFields}
        />
      );
    },
  }, {
    dataIndex: 'text',
    title: targetHeader,
    render: (_, record) => {
      const className = requiredFields.has(record.key) ? 'form-required-field' : null;
      return <span className={className}>{record.text}</span>;
    },
  }];

  return (
    <Drawer
      title="Configure Field Mapping"
      width={1000}
      onClose={onClose}
      visible={visible}
    >
      {title}
      <Divider style={{ marginBottom: 0 }} />
      <Table
        dataSource={fields}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: 'calc(100vh - 218px)' }}
        rowKey="id"
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}
