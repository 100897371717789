import {
  GET_NUX,
  CREATE_NUX,
  DELETE_NUX,
  START_NUX_ACTION,
  FINISH_NUX_ACTION,
} from '../../state/actionTypes';

const intialState = {
  nux: new Set(),
  nuxLoaded: false,
  activeNuxAction: null,
};

export default function nuxReducer(state = intialState, action) {
  switch (action.type) {
    case GET_NUX: {
      const {
        nux = [],
      } = action.payload;
      const nuxTypes = nux.map(({ type }) => type);
      return {
        ...state,
        nux: new Set(nuxTypes),
        nuxLoaded: true,
      };
    }
    case CREATE_NUX: {
      const {
        type,
      } = action.payload;
      const {
        nux,
      } = state;
      if (nux.has(type)) return state;
      const newState = new Set(nux);
      newState.add(type);
      return {
        ...state,
        nux: newState,
      };
    }
    case DELETE_NUX: {
      const {
        type,
      } = action.payload;
      const {
        nux,
      } = state;
      if (!nux.has(type)) return state;
      const newState = new Set(nux);
      newState.delete(type);
      return {
        ...state,
        nux: newState,
      };
    }
    case START_NUX_ACTION: {
      const {
        activeNuxAction,
      } = action.payload;
      return {
        ...state,
        activeNuxAction,
      };
    }
    case FINISH_NUX_ACTION: {
      return {
        ...state,
        activeNuxAction: null,
      };
    }
    default:
      return state;
  }
}
