import colors from '../constants/Colors';

import logo from '../assets/images/Ontraccr-Logo-B-1400x400.png';

export default {};

export const parseClientPortalSettings = (portalSettings = {}) => {
  const {
    primaryColor,
    accentColor,
    image,
  } = portalSettings;

  const safePrimaryColor = primaryColor ?? colors.MAIN_BACKGROUND;
  const safeAccentColor = accentColor ?? colors.ONTRACCR_RED;
  const safeImage = image ?? logo;

  return {
    ...portalSettings,
    primaryColor: safePrimaryColor,
    accentColor: safeAccentColor,
    image: safeImage,
  };
}