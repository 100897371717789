import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';

import { updateScheduleRestrictions } from './state/settings.actions';

const getWeekOptions = (numWeeks) => {
  const weeks = new Array(numWeeks + 1).fill().map((_, index) => ({
    label: `${index} Week${index === 1 ? '' : 's'}`,
    value: index,
  }));

  weeks[0] = {
    label: 'Current Week',
    value: 0,
  };

  return weeks;
};

export default function ScheduleRestriction({
  roleName,
}) {
  const dispatch = useDispatch();

  const scheduleRestriction = useSelector((state) => state.settings.scheduleRestriction);
  const myRestriction = scheduleRestriction?.[roleName] ?? {};

  const onWeekChanged = useCallback((weekVal) => {
    dispatch(updateScheduleRestrictions(roleName, { weeks: weekVal }));
  }, [roleName]);

  return (
    <div style={{ margin: '0px 10px 10px 10px' }}>
      <Row>
        <Select
          options={getWeekOptions(4)}
          placeholder="Select Week(s)"
          value={myRestriction?.weeks}
          allowClear
          onChange={onWeekChanged}
          style={{ width: 200 }}
        />
      </Row>
    </div>
  );
}

ScheduleRestriction.propTypes = {
  roleName: PropTypes.string.isRequired,
};
