import {
  UPDATE_CLIENT_PORTAL_SETTINGS,
  GET_CLIENT_PORTAL_SETTINGS,
  CLIENT_PORTAL_SELECT_CARD,
  GET_CLIENT_PORTAL_ACCOUNTS,
  CREATE_CLIENT_PORTAL_ACCOUNT,
  DELETE_CLIENT_PORTAL_ACCOUNT,
  UPDATE_CLIENT_PORTAL_ACCOUNT,
  CLIENT_PORTAL_LOGIN,
  CLIENT_PORTAL_LOGOUT,
  CLIENT_PORTAL_GET_DATA,
  CLIENT_PORTAL_SEND_MESSAGE,
  MARK_CUSTOMER_CLIENT_COMM_AS_READ,
} from '../../state/actionTypes';

import colors from '../../constants/Colors';

import { parseClientPortalSettings } from '../clientPortalHelpers';
import { getIdMap } from '../../helpers/helpers';

const initialState = {
  settings: {
    primaryColor: `${colors.ONTRACCR_BLACK.toUpperCase()}FF`,
    accentColor: `${colors.ONTRACCR_RED.toUpperCase()}FF`,
  },
  profile: {},
  cards: {},
  accounts: [],
  loggedIn: false,
  data: {},
  comms: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CLIENT_PORTAL_SETTINGS:
    case UPDATE_CLIENT_PORTAL_SETTINGS: {
      const {
        payload: newSettings = {},
      } = action;
      const { settings: oldSettings = {} } = state;
      return {
        ...state,
        settings: parseClientPortalSettings({ ...oldSettings, ...newSettings }),
      };
    }
    case CLIENT_PORTAL_SELECT_CARD: {
      const {
        payload: {
          cardId,
        } = {},
      } = action;
      return {
        ...state,
        selectedCardId: cardId,
      };
    }
    case GET_CLIENT_PORTAL_ACCOUNTS: {
      const {
        payload: {
          accounts: newAccounts = [],
        } = {},
      } = action;
      return {
        ...state,
        accounts: newAccounts,
      };
    }
    case CREATE_CLIENT_PORTAL_ACCOUNT: {
      const {
        payload: {
          newAccount = {},
        } = {},
      } = action;
      const { accounts: oldAccounts = [] } = state;
      const accountIds = new Set(oldAccounts.map((acc) => acc.id));
      if (accountIds.has(newAccount.id)) return state;
      return {
        ...state,
        accounts: oldAccounts.concat([newAccount]),
      };
    }
    case DELETE_CLIENT_PORTAL_ACCOUNT: {
      const {
        payload: {
          accountId,
        } = {},
      } = action;
      const { accounts: oldAccounts = [] } = state;
      return {
        ...state,
        accounts: oldAccounts.filter((acc) => acc.id !== accountId),
      };
    }
    case UPDATE_CLIENT_PORTAL_ACCOUNT: {
      const {
        payload: {
          accountId,
          newData = {},
        } = {},
      } = action;
      const { accounts: oldAccounts = [] } = state;
      return {
        ...state,
        accounts: oldAccounts.map((acc) => {
          if (acc.id !== accountId) return acc;
          return {
            ...acc,
            ...newData,
          };
        }),
      };
    }
    case CLIENT_PORTAL_LOGIN: {
      return { ...state, loggedIn: true };
    }
    case CLIENT_PORTAL_LOGOUT: {
      return { ...state, loggedIn: false };
    }
    case CLIENT_PORTAL_GET_DATA: {
      const {
        payload: {
          companyName,
          profile = [],
          comms = [],
          cards = [],
          fileMap = {},
        } = {},
      } = action;
      return {
        ...state, profile, cards: getIdMap(cards), fileMap, comms, companyName,
      };
    }
    case CLIENT_PORTAL_SEND_MESSAGE: {
      const {
        payload: newMessage = {},
      } = action;
      const { comms: oldComms = [] } = state;
      return {
        ...state,
        comms: oldComms.concat(newMessage),
      };
    }
    case MARK_CUSTOMER_CLIENT_COMM_AS_READ: {
      const {
        payload: {
          isExternal,
          ids = [],
        } = {},
      } = action;
      if (!isExternal) return state;
      const { comms: oldComms = [] } = state;
      const idSet = new Set(ids);
      return {
        ...state,
        comms: oldComms.map((comm) => {
          if (!idSet.has(comm.id)) return comm;
          return {
            ...comm,
            unread: false,
          };
        }),
      };
    }
    default:
      return state;
  }
};
