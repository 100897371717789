import {
  GET_ECLIPSE_DIVISIONS,
  ECLIPSE_LINK_DIVISION,
  ECLIPSE_IMPORT_DIVISION,
  CREATE_DIVISIONS,
  ECLIPSE_SET_DIVISION,
  CREATE_ECLIPSE_WORKFLOW,
  UPDATE_ECLIPSE_WORKFLOW,
  DELETE_ECLIPSE_WORKFLOW,
  GET_ECLIPSE_WORKFLOWS,
} from '../../../../state/actionTypes';

import EclipseService from './eclipse.service';

export const getEclipseDivisions = () => async (dispatch) => {
  const { data } = await EclipseService.getDivisions();
  if (!data) return false;
  dispatch({
    type: GET_ECLIPSE_DIVISIONS,
    payload: data,
  });
  return true;
};

export const importEclipseDivision = (payload) => async (dispatch) => {
  const { data } = await EclipseService.importDivision(payload);
  if (!data) return false;
  dispatch({
    type: ECLIPSE_IMPORT_DIVISION,
    payload: data,
  });

  dispatch({
    type: CREATE_DIVISIONS,
    payload: {
      name: payload.name,
      id: data.divisionId,
    },
  });
  return true;
};

export const linkEclipseDivision = (payload) => async (dispatch) => {
  const { data } = await EclipseService.linkDivision(payload);
  if (!data) return false;
  dispatch({
    type: ECLIPSE_LINK_DIVISION,
    payload,
  });
  return true;
};

export const setSelectedEclipseDivision = (divisionId) => ({
  type: ECLIPSE_SET_DIVISION,
  payload: { divisionId },
});

export const createEclipseWorkflow = (payload) => async (dispatch) => {
  const { data } = await EclipseService.createEclipseWorkflow(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_ECLIPSE_WORKFLOW,
    payload: {
      workflow: data,
    },
  });
  return true;
};

export const updateEclipseWorkflow = (id, newData) => async (dispatch) => {
  const { data } = await EclipseService.updateEclipseWorkflow(id, newData);
  if (!data) return false;
  dispatch({
    type: UPDATE_ECLIPSE_WORKFLOW,
    payload: {
      id,
      newData,
    },
  });
  return true;
};

export const deleteEclipseWorkflow = (id) => async (dispatch) => {
  const { data } = await EclipseService.deleteEclipseWorkflow(id);
  if (!data) return false;
  dispatch({
    type: DELETE_ECLIPSE_WORKFLOW,
    payload: {
      id,
    },
  });
  return true;
};

export const getEclipseWorkflows = () => async (dispatch) => {
  const { data } = await EclipseService.getEclipseWorkflows();
  if (!data) return false;
  dispatch({
    type: GET_ECLIPSE_WORKFLOWS,
    payload: {
      workflows: data,
    },
  });
  return true;
};
