import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';

import * as XLSX from 'xlsx';

import { useSelector } from 'react-redux';
import StepsContainer from '../../common/containers/StepsContainer';
import SheetUploadPrompt from '../../common/excel/SheetUploadPrompt';

import BoardCardSelectSheet from './BoardCardSelectSheet';
import BoardCardSelectHeader from './BoardCardSelectHeader';
import BoardCardUploadPreview from './BoardCardUploadPreview';

const TEMPLATE_LINK = 'https://ontraccr-public.s3.us-west-2.amazonaws.com/Ontraccr%2BBoard%2BCards.xlsx';

function BoardCardUpload({
  uploadFile,
  onFileChange,
  currentStep,
  onMassUploadDataChanged,
  sections,
  visible,
  useCardNumberAsCardTitle,
  statusId,
  setStatusId,
}) {
  const selectedBoard = useSelector((state) => state.boards.selectedBoard);

  const [selectedSheetName, setSelectedSheetName] = useState();
  const [headerMapping, setHeaderMapping] = useState({});
  const [link, setLink] = useState({});

  const sheets = useMemo(() => {
    if (!uploadFile) return {};
    const {
      Sheets = {},
    } = uploadFile;
    return Sheets;
  }, [uploadFile]);

  const selectedSheet = useMemo(() => {
    if (!sheets || !selectedSheetName) return [];
    const {
      [selectedSheetName]: fullSheet = {},
    } = sheets;

    return XLSX.utils.sheet_to_json(fullSheet, { defval: '' });
  }, [sheets, selectedSheetName]);

  const customHeaders = useMemo(() => {
    const headers = [];
    sections.forEach((section) => {
      const { fields = [] } = section;
      fields.forEach((field) => {
        const { configProps = {}, selectedType, id: fieldId } = field;
        const { title } = configProps;
        if (selectedType === 'text') {
          headers.push({ key: fieldId, title });
        }
      });
    });

    return headers;
  }, [sections]);

  useEffect(() => {
    if (!uploadFile) return;
    const {
      SheetNames = [],
    } = uploadFile;
    if (!selectedSheetName || (SheetNames.length === 1 && selectedSheetName !== SheetNames[0])) {
      setSelectedSheetName(SheetNames[0]);
    }
  }, [selectedSheetName, uploadFile]);

  useEffect(() => {
    if (!visible) {
      setSelectedSheetName();
      setHeaderMapping({});
      setLink({});
    }
  }, [visible]);

  useEffect(() => {
    onMassUploadDataChanged((prevState) => ({
      ...prevState,
      link,
    }));
  }, [link]);

  return (
    <div style={{ padding: 24 }}>
      {!uploadFile ? (
        <SheetUploadPrompt onFileChange={onFileChange} templateLink={TEMPLATE_LINK} />
      ) : (
        <StepsContainer
          currentStep={currentStep}
          steps={[
            {
              title: 'Select Sheet',
              content: (
                <BoardCardSelectSheet
                  workbook={uploadFile}
                  onSheetChanged={setSelectedSheetName}
                  selectedBoard={selectedBoard}
                  onStatusChanged={setStatusId}
                  statusId={statusId}
                />
              ),
            }, {
              title: 'Select Headers and Links',
              content: (
                <BoardCardSelectHeader
                  selectedSheet={selectedSheet}
                  headerMapping={headerMapping}
                  setHeaderMapping={setHeaderMapping}
                  customHeaders={customHeaders}
                  link={link}
                  onLinkChange={setLink}
                  useCardNumberAsCardTitle={!!useCardNumberAsCardTitle}
                />
              ),
            }, {
              title: 'Preview',
              content: (
                <BoardCardUploadPreview
                  selectedSheet={selectedSheet}
                  headerMapping={headerMapping}
                  onMassUploadDataChanged={onMassUploadDataChanged}
                  customHeaders={customHeaders}
                  useCardNumberAsCardTitle={!!useCardNumberAsCardTitle}
                />
              ),
            },
          ]}
        />
      )}
    </div>
  );
}

export default BoardCardUpload;

/* eslint-disable react/forbid-prop-types */
BoardCardUpload.propTypes = {
  uploadFile: PropTypes.object.isRequired,
  currentStep: PropTypes.number.isRequired,
  onFileChange: PropTypes.func.isRequired,
  onMassUploadDataChanged: PropTypes.func.isRequired,
  sections: PropTypes.array,
  visible: PropTypes.bool,
  useCardNumberAsCardTitle: PropTypes.bool,
  statusId: PropTypes.number,
  setStatusId: PropTypes.func.isRequired,
};

BoardCardUpload.defaultProps = {
  visible: false,
  sections: [],
  useCardNumberAsCardTitle: false,
  statusId: null,
};
