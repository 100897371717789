import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  createProjectGroup,
  deleteProjectGroup,
  getProjectGroups,
  updateProjectGroup,
} from '../projects/state/projects.actions';
import {
  createBucket,
  deleteBucket,
  getBuckets,
  updateBucket,
} from './state/buckets.actions';
import CardGrid from '../common/cardGrid/cardGrid';
import BreadcrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import BucketAddView from './BucketAddView';
import BucketCard from './BucketCard';
import { getIdMap } from '../helpers/helpers';
import {
  bucketLinkTypeToDisplayName,
  formatBucketPayload,
  getDownstreamName,
  getUpstreamName,
} from './bucketHelpers';
import Permissions from '../auth/Permissions';
import { getCustomers } from '../contacts/customers/state/customers.actions';
import { formatCustomFieldCreatePayload } from '../helpers/costcodeHelpers';

const getTypeMap = (t, bucketTemplateId) => ({
  ProjectGroups: {
    name: t('ProjectGroup'),
    get: getProjectGroups,
    create: createProjectGroup,
    update: updateProjectGroup,
    delete: deleteProjectGroup,
    writePerm: 'PROJECTS_WRITE',
    viewTypeKey: 'projectGroupsViewType',
  },
  Buckets: {
    name: 'Bucket',
    get: getBuckets,
    create: createBucket,
    update: updateBucket,
    delete: deleteBucket,
    writePerm: 'BUCKETS_WRITE',
    viewTypeKey: `bucket-${bucketTemplateId}viewType`,
  },
});

const getProjectGroupCrumbs = (t) => [{
  text: t('ProjectGroup', { count: 2 }),
  icon: 'group',
}];

export default function Bucket({
  type,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { bucketTemplateId } = useParams();

  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);
  const buckets = useSelector((state) => state.buckets.buckets);
  const customers = useSelector((state) => state.customers.customers);
  const projectGroups = useSelector((state) => state.projects.projectGroups);
  const projects = useSelector((state) => state.projects.projects);

  const [crumbs, setCrumbs] = useState([]);
  const [viewType, setViewType] = useState('grid');
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const typeMap = getTypeMap(t);
  const localStorageViewTypeKey = typeMap[type].viewTypeKey;
  const hasWritePerms = Permissions.has(typeMap[type].writePerm);

  useEffect(() => {
    if (type === 'Buckets') dispatch(getCustomers());
    dispatch(typeMap[type].get());
  }, [type]);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const bucketTemplateMap = useMemo(() => getIdMap(bucketTemplates), [bucketTemplates]);
  const bucketMap = useMemo(() => getIdMap(buckets), [buckets]);

  const relevantBucketTemplate = useMemo(() => (
    bucketTemplateMap[bucketTemplateId]
  ), [bucketTemplateMap, bucketTemplateId]);

  const relevantSource = useMemo(() => (
    type === 'ProjectGroups'
      ? projectGroups
      : buckets.filter((bucket) => bucket.bucketTemplateId === bucketTemplateId)
  ), [projectGroups, buckets, bucketTemplateId]);

  useEffect(() => {
    // Load saved view type
    const storedViewType = window.localStorage.getItem(localStorageViewTypeKey);

    if (storedViewType) {
      setViewType(storedViewType);
    }

    // Load crumbs
    if (type === 'ProjectGroups') {
      setCrumbs(getProjectGroupCrumbs(t));
      return;
    }

    setCrumbs([{
      text: relevantBucketTemplate?.name ?? 'Bucket',
      icon: relevantBucketTemplate?.icon ?? 'group',
    }]);
  }, [relevantBucketTemplate, type]);

  useEffect(() => {
    setCustomFields(relevantBucketTemplate?.customFields?.sections);
  }, [relevantBucketTemplate]);

  const onViewTypeChanged = useCallback((checked) => {
    const newViewType = checked ? 'list' : 'grid';
    window.localStorage.setItem(localStorageViewTypeKey, newViewType);
    setViewType(newViewType);
  }, [bucketTemplateId, localStorageViewTypeKey]);

  const validateAndFormatPayload = (payload) => {
    const formattedValues = formatCustomFieldCreatePayload({
      payload,
      customFields,
    });

    if (formattedValues?.error) {
      setErrors(formattedValues.errorMap);
      return false;
    }

    setErrors({});
    return formattedValues;
  };

  const onAdd = (bucket) => {
    const formattedPayload = validateAndFormatPayload(bucket);
    if (!formattedPayload) return false;

    return dispatch(typeMap[type].create(
      formatBucketPayload(
        { ...formattedPayload, bucketTemplateId },
        relevantBucketTemplate?.upstreamTypes,
        relevantBucketTemplate?.downstreamTypes,
      ),
    ))
  };

  const onEdit = (id, bucket) => {
    const formattedPayload = validateAndFormatPayload(bucket);
    if (!formattedPayload) return false;

    return dispatch(typeMap[type].update(
      id,
      formatBucketPayload(
        { ...formattedPayload, bucketTemplateId },
        relevantBucketTemplate?.upstreamTypes,
        relevantBucketTemplate?.downstreamTypes,
      ),
    ));
  };

  const onDelete = ({ id, name }) => new Promise((resolve) => (
    CustomConfirmModal({
      title: `Delete ${typeMap[type].name} ${name}`,
      okText: 'Delete',
      content: `Are you sure you want to delete the ${typeMap[type].name.toLowerCase()} ${name}?`,
      onOk: async () => {
        const result = await dispatch(typeMap[type].delete(id));
        if (result) resolve(true);
      },
      onCancel() {
        resolve(true);
      },
    })
  ));

  const columnsToDisplay = useMemo(() => {
    const baseColumns = [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: 100,
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        width: 200,
      },
    ];

    if (type !== 'ProjectGroups' && relevantBucketTemplate?.upstreamTypes?.length) {
      relevantBucketTemplate.upstreamTypes.forEach((upstreamType) => {
        baseColumns.push({
          title: bucketLinkTypeToDisplayName(t, upstreamType, bucketTemplateMap),
          dataIndex: 'upstreamIds',
          key: 'upstreamIds',
          width: 300,
          render: (_, record) => (
            getUpstreamName({
              upstreamType,
              upstreamIds: record[upstreamType] ?? [],
              customers,
              t,
              bucketTemplateMap,
              bucketMap,
            })
          ),
        });
      });
    }

    if (type === 'ProjectGroups') {
      baseColumns.push({
        title: t('Project', { count: 0 }),
        dataIndex: 'downstreamIds',
        key: 'downstreamIds',
        width: 300,
        render: (_, record) => (
          getDownstreamName({
            ...record,
            downstreamIds: record.downstreamIds,
            entityType: type,
            projectMap,
            bucketMap,
            bucketTemplateMap,
            t,
          })
        ),
      });
    }

    if (relevantBucketTemplate?.downstreamTypes?.length) {
      relevantBucketTemplate.downstreamTypes.forEach((downstreamType) => {
        baseColumns.push({
          title: bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap),
          dataIndex: 'downstreamIds',
          key: 'downstreamIds',
          width: 300,
          render: (_, record) => (
            getDownstreamName({
              downstreamIds: record[downstreamType] ?? [],
              downstreamType,
              projectMap,
              bucketMap,
              bucketTemplateMap,
              t,
            })
          ),
        });
      });
    }

    return baseColumns;
  }, [
    projectMap,
    type,
    customers,
    projectMap,
    t,
    relevantBucketTemplate,
    bucketMap,
    bucketTemplateMap,
  ]);

  const addView = (form) => (
    <BucketAddView
      {...form}
      hasWritePerms={hasWritePerms}
      type={type}
      bucketTemplate={relevantBucketTemplate}
      customFields={customFields}
      errors={errors}
    />
  );

  const editView = (form, formProps) => (
    <BucketAddView
      {...form}
      formProps={formProps}
      hasWritePerms={hasWritePerms}
      type={type}
      bucketTemplate={relevantBucketTemplate}
      customFields={customFields}
      errors={errors}
    />
  );

  return (
    <BreadcrumbContainer crumbs={crumbs}>
      <CardGrid
        dataSource={relevantSource}
        itemView={BucketCard}
        itemDimensions={{ width: 239, height: 190 }}
        onToggleListViewSwitch
        isListView={viewType === 'list'}
        onViewTypeChanged={onViewTypeChanged}
        displayColumns={columnsToDisplay}
        add={hasWritePerms ? {
          title: `Add ${typeMap[type].name}`,
          formView: addView,
          width: 900,
          onClose: onAdd,
        } : null}
        edit={{
          title: `Edit ${typeMap[type].name}`,
          formView: editView,
          width: 900,
          onClose: onEdit,
          getTitle: ({ name, editing }) => (editing ? `Edit ${name}` : name),
          canEdit: () => hasWritePerms,
          canArchive: () => false,
        }}
        onDelete={onDelete}
        itemProps={{ entityType: type }}
      />
    </BreadcrumbContainer>
  );
}

Bucket.propTypes = {
  type: PropTypes.string.isRequired,
};
