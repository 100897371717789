import React from 'react';

import WarningHeader from '../common/text/WarningHeader';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

export default ({
  showCancel,
  onClear,
}) => {
  CustomConfirmModal({
    title: 'Clear shadow?',
    content: (
      <div>
        <WarningHeader justify="center" />
        <br />
        Clearing a shadow is permanent and will clear the shadow for all users in this board.
        <br />
        The card will still be available in its current board.
      </div>
    ),
    onOk: onClear,
    showCancel,
  });
};
