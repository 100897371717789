import React from 'react';

import Permissions from '../auth/Permissions';

import FilteredFormList from '../forms/FilteredFormList';

export default ({
  id,
  isNotDisplay,
  visible,
  isVendor,
}) => {
  const excludedFormsTypes = isVendor ? new Set(['Invoice', 'PO']) : new Set(['Work Order', 'Invoice'])
  const perms = isVendor
    ? Permissions.hasWrite('vendors') : Permissions.hasWrite('customers');
  return (
    <FilteredFormList
      exclude={excludedFormsTypes}
      filterId={id}
      filterType={isVendor ? 'Vendors' : 'Customers'}
      isNotDisplay={isNotDisplay}
      topOffset={perms ? 240 : 184}
      visible={visible}
    />
  );
};
