import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  Table,
  Row,
  Col,
  Spin,
} from 'antd';

import AutoImport from './IntegrationAutoImport';
import CanImportCol from './IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from './IntegrationIsConnectedCol';
import { createImportData, linkInitialData } from './integrationHelpers';

import sortByString from '../../helpers/helpers';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';

const { Panel } = Collapse;

const canImport = (divisionId, divisionList) => (record) => (
  record.name
  && record.description
  && (divisionId || record.linkValue || record.divisionId || divisionList.length === 1)
);

const includesTerm = ({
  name = '',
  description = '',
}, searchStr) => {
  if (!searchStr) return true;
  const sLower = searchStr.toLowerCase();
  return name.toLowerCase().includes(sLower)
  || description?.toLowerCase()?.includes(sLower);
};

function IntegrationMaterialsSync({
  integrationKey,
  title,
  integrationMaterials = [],
  onMaterialsChanged,
  autoImportContent,
  importHelpText,
  importMaterials,
  loading,
  // these division filters should be XOR
  divisionId, // For single division integrations
  divisionFilter, // Multi division integrations (e.g. QBO)
}) {
  const materials = useSelector((state) => state.materials.materials);
  const divisions = useSelector((state) => state.settings.divisions);

  const [stateMaterials, setStateMaterials] = useState([]);
  const [activePanels, setActivePanels] = useState(['qb']);
  const [searchStr, setSearchStr] = useState();
  const [importList, setImportList] = useState(new Set());
  const [autoImport, setAutoImport] = useState(true);
  const [connected, setConnected] = useState({});

  const activeMaterials = useMemo(() => (
    Object.values(materials).filter((material) => (
      material.active
      && (!divisionId || material.divisionId === divisionId)
      && (!divisionFilter || divisionFilter.has(material.divisionId))
    ))
  ), [materials, divisionId, searchStr]);

  const divisionList = useMemo(() => (
    Object.values(divisions)
      .filter((division) => !divisionFilter || divisionFilter.has(division.id))
  ), [divisions, divisionFilter]);

  const updateMaterials = (record) => {
    setStateMaterials(stateMaterials.map((user) => {
      if (user[integrationKey] !== record[integrationKey]) return user;
      return record;
    }));
  };

  const resetImportList = (list) => () => {
    setImportList(new Set(list.map((cc) => cc[integrationKey])));
  };

  useEffect(() => {
    setConnected(createConnectedArray(activeMaterials, integrationKey));
  }, [activeMaterials, integrationKey]);

  useEffect(() => {
    if (integrationMaterials.length) {
      setStateMaterials(linkInitialData({
        items: integrationMaterials, connected, integrationKey,
      }));
    }
    resetImportList(integrationMaterials)();
  }, [integrationMaterials, connected]);

  useEffect(() => {
    const importData = createImportData({
      integrationKey,
      newData: integrationMaterials,
      stateData: stateMaterials,
      originalData: activeMaterials,
      importList,
      canImport: canImport(divisionId, divisionList),
      connected,
      divisionList,
    });
    onMaterialsChanged(importData);
  }, [
    autoImport,
    importList,
    stateMaterials,
    activeMaterials,
    onMaterialsChanged,
    integrationMaterials,
    connected,
    integrationKey,
    divisionList,
    divisionId,
  ]);

  const getCols = (editable) => {
    const cols = [
      IsConnectedCol({
        integrationKey,
        title,
        connected,
        onUnLink: (record) => {
          const newConnected = { ...connected };
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if (editable) {
            record.linkValue = null;
            updateMaterials(record);
          } else {
            setStateMaterials(stateMaterials.map((cc) => {
              if (cc[integrationKey] !== record[integrationKey]) return cc;
              const newUser = { ...cc };
              delete newUser.linkValue;
              return newUser;
            }));
          }
        },
      }),
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: sortByString('name'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
      {
        title: 'Division',
        dataIndex: 'divisionId',
        key: 'divisionId',
        align: 'center',
        fixed: 'left',
        width: 170,
        render: (_, record) => (
          <DivisionSelector
            key={record.id}
            displayMode={!editable}
            style={{ width: '100%' }}
            displayStyle={{ marginBottom: 0 }}
            onChange={(newDivisionId) => {
              if (newDivisionId !== record.divisionId) {
                record.divisionId = newDivisionId;
                updateMaterials(record);
              }
            }}
            divisionId={divisionId || record.divisionId}
            disabled={divisionId || record.viewType === 'link'}
            filter={divisionFilter}
          />
        ),
      },
      {
        title: 'Description',
        dataIndex: 'description',
        key: 'description',
        align: 'center',
        sorter: sortByString('description'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      },
    ];

    if (integrationKey === 'intuitId') {
      cols.push(
        {
          title: 'Quantity',
          dataIndex: 'quantity',
          key: 'quantity',
          align: 'center',
          sorter: (a, b) => a.quantity - b.quantity,
          showSorterTooltip: false,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Cost',
          dataIndex: 'cost',
          key: 'cost',
          align: 'center',
          sorter: (a, b) => a.cost - b.cost,
          showSorterTooltip: false,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Markup',
          dataIndex: 'markup',
          key: 'markup',
          align: 'center',
          sorter: (a, b) => a.markup - b.markup,
          showSorterTooltip: false,
          sortDirections: ['descend', 'ascend'],
        },
        {
          title: 'Price',
          dataIndex: 'price',
          key: 'price',
          align: 'center',
          render: (_, record) => {
            const {
              cost,
              markup,
            } = record;
            const price = (markup * cost + cost).toFixed(2);
            // eslint-disable-next-line no-restricted-globals
            return isNaN(price) ? 0 : price;
          },
        },
      );
    }

    if (editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          title,
          connected,
          helpText: importHelpText,
          canImport: canImport(divisionId, divisionList),
          importList,
          setImportList,
          resetImportList: resetImportList(integrationMaterials),
          onViewTypeChanged: (record, viewType) => {
            record.viewType = viewType;
            updateMaterials(record);
          },
          linkOptions: activeMaterials,
          onLinkChanged: (record, linkValue) => {
            const newConnected = { ...connected };
            const oldLink = connected[record[integrationKey]];
            if (oldLink) delete oldLink[integrationKey];
            if (linkValue) {
              newConnected[record[integrationKey]] = materials[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
            } else {
              delete newConnected[record[integrationKey]];
            }

            setConnected(newConnected);

            record.linkValue = linkValue;
            updateMaterials(record);
          },
        }),
      );
    }
    return cols;
  };

  const activeStateMaterials = useMemo(() => (
    stateMaterials.filter((cc) => includesTerm(cc, searchStr))
  ), [stateMaterials, searchStr]);

  if (loading) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }

  return (
    <div style={{ height: 'calc(100% - 55px)', width: '100%', overflowY: 'scroll' }}>
      <AutoImport
        setActivePanels={setActivePanels}
        setAutoImport={setAutoImport}
        autoImport={autoImport}
        helpContent={autoImportContent}
        importType="Material"
        importData={importMaterials}
      />
      {!autoImport && (
      <OnTraccrTextInput
        allowClear
        style={{
          width: 250,
        }}
        search
        onChange={(e) => {
          const {
            target: {
              value,
            } = {},
          } = e;
          setSearchStr(value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
      )}
      {!autoImport && (
      <Collapse ghost activeKey={activePanels} onChange={setActivePanels}>
        <Panel
          header={`New ${title} Materials`}
          key="new"
          className="ontraccr-collapse-panel"
        >
          <Table
            bordered
            size="small"
            columns={getCols(true)}
            dataSource={activeStateMaterials}
            scroll={{
              y: '40%',
              x: 1000,
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              showSizeChanger: false,
            }}
            rowKey={integrationKey}
          />
        </Panel>
        <Panel
          header="Ontraccr Materials"
          key="ontraccr"
          className="ontraccr-collapse-panel"
        >
          <Table
            bordered
            size="small"
            columns={getCols(false)}
            dataSource={activeMaterials}
            scroll={{
              y: '40%',
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              showSizeChanger: false,
            }}
            rowKey="id"
          />
        </Panel>
      </Collapse>
      )}
    </div>
  );
}

export default IntegrationMaterialsSync;
