import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';

import { getIdMap } from '../../../../helpers/helpers';
import { getPhaseCostcodeKey, getPhaseCostcodeTreeData } from '../../../formHelpers';

export default function CostCodeTableSelector({
  projectId,
  onCostcodeUpdated,
  value,
  entryId,
}) {
  const phasedCostcodes = useSelector((state) => state.costcodes.phases);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phases = useSelector((state) => state.costcodes.phases);
  const projects = useSelector((state) => state.projects.projects);

  const phaseMap = useMemo(() => getIdMap(phasedCostcodes), [phasedCostcodes]);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const projectCostcodes = useMemo(() => {
    if (!projectId) return [];
    const treeData = getPhaseCostcodeTreeData({
      projectId,
      phases,
      costcodes,
      costcodeMap,
      projectMap,
    });
    const flattenedCCData = treeData.flatMap((phase) => phase.children.map((cc) => cc.value));
    return flattenedCCData.map((cc) => {
      const [phaseId, costcodeId] = cc.split('.');
      const key = getPhaseCostcodeKey(phaseId, costcodeId);
      const { name: phaseName = 'Unphased' } = phaseMap[phaseId] || {};
      const { name: costcodeName, code } = costcodeMap[costcodeId] || {};
      return {
        phaseId: phaseId === 'unphased' ? null : phaseId,
        costcodeId,
        phaseName,
        phaseCostcodeKey: key,
        costcode: `${phaseName || 'Unphased'} - ${code} ${costcodeName}`,
      };
    });
  }, [projectId, phases, costcodes, costcodeMap, projectMap, phaseMap]);

  const costcodeTreeOptions = useMemo(() => {
    const treeData = {};

    projectCostcodes.forEach((phaseCostcode) => {
      const {
        phaseId,
        phaseName,
        costcode,
        phaseCostcodeKey,
      } = phaseCostcode;
      if (!treeData[phaseId]) {
        treeData[phaseId] = {
          value: phaseId ?? 'unphased',
          title: phaseName,
          selectable: false,
          children: [],
        };
      }
      treeData[phaseId].children.push({
        value: phaseCostcodeKey,
        title: costcode,
        isLeaf: true,
      });
    });
    return Object.values(treeData);
  }, [projectCostcodes]);

  return (
    <TreeSelect
      showSearch
      treeDefaultExpandAll
      treeNodeFilterProp="title"
      treeData={costcodeTreeOptions}
      value={value}
      onSelect={(val) => onCostcodeUpdated(entryId, val)}
      style={{ width: '95%' }}
    />
  );
}

CostCodeTableSelector.propTypes = {
  projectId: PropTypes.string,
  onCostcodeUpdated: PropTypes.func.isRequired,
  entryId: PropTypes.string.isRequired,
  value: PropTypes.string,
};

CostCodeTableSelector.defaultProps = {
  projectId: undefined,
  value: undefined,
};
