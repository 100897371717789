import axios from 'axios';
import { message } from 'antd';
import { request } from '../../helpers/requests';

const ProfileService = {
  async get() {
    try {
      const { data } = await axios.get('/profile');
      return data;
    } catch (err) {
      message.error('Could not retrieve profile');
      return {};
    }
  },
  update: (details) => request({
    call: axios.put('/profile', details),
    errMsg: 'Failed to update profile',
  }),

  changePassword: (payload) => request({
    call: axios.put('/profile/password', payload),
    errMsg: 'Failed to change password',
  }),

  connectGmail: (payload) => request({
    call: axios.post('/gmail/connect/complete', payload),
    errMsg: 'Failed to connect Gmail',
  }),
  getGmailCode: () => request({
    call: axios.get('/gmail/authorization_code'),
    errMsg: 'Failed to get Gmail code',
    hideSuccessToast: true,
  }),
  cycleGmailCode: () => request({
    call: axios.post('/gmail/authorization_code/cycle'),
    errMsg: 'Failed to change Gmail code',
  }),
  disconnectGmail: () => request({
    call: axios.delete('/gmail/disconnect'),
    errMsg: 'Failed to disconnect Gmail',
  }),
};

export default ProfileService;
