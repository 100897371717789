import axios from 'axios';
import { request } from '../../helpers/requests';
import { isNullOrUndefined } from '../../helpers/helpers';

const ReportsService = {
  save: async (payload) => request({
    call: axios.post('/reports', payload),
    errMsg: (error) => {
      if (!error?.message) return 'Failed to save report';
      const errorMessage = error.message;
      if (errorMessage.includes('already exists')) return errorMessage;
      return 'Failed to save report';
    },
  }),
  updateReport: async (id, payload) => request({
    call: axios.put(`/reports/${id}`, payload),
    errMsg: (error) => {
      if (!error?.message) return 'Failed to save report';
      const errorMessage = error.message;
      if (errorMessage.includes('already exists')) return errorMessage;
      return 'Failed to save report';
    },
  }),
  getAll: async () => request({
    call: axios.get('/reports'),
    errMsg: 'Failed to get reports',
    defaultData: [],
    hideSuccessToast: true,
  }),
  delete: async (id) => request({
    call: axios.delete(`reports/${id}`),
    errMsg: 'Failed to delete report',
  }),
  updateProgress: async (payload) => request({
    call: axios.post('reports/progress', {
      ...payload,
      progress: !isNullOrUndefined(payload.progress) ? payload.progress / 100 : undefined,
      device: 'web',
    }),
    errMsg: 'Failed to update progress',
    defaultData: [],
  }),
  getProgress: async (query) => request({
    call: axios.get('reports/progress', { params: query }),
    errMsg: 'Failed to get progress',
    defaultData: [],
    hideSuccessToast: true,
  }),
  getCustomExports: async () => request({
    call: axios.get('exports'),
    errMsg: 'Failed to get custom export',
    hideSuccessToast: true,
  }),
  createCustomExport: async (payload) => request({
    call: axios.post('exports', payload),
    errMsg: 'Failed to create custom export',
  }),
  updateCustomExport: async (id, payload) => request({
    call: axios.put(`exports/${id}`, payload),
    errMsg: 'Failed to update custom export',
  }),
  deleteCustomExport: async (id) => request({
    call: axios.delete(`exports/${id}`),
    errMsg: 'Failed to delete custom export',
  }),
};

export default ReportsService;
