import {
  SET_BREADCRUMB,
} from '../../../state/actionTypes';

export const setBreadcrumb = (crumbs) => ({
  type: SET_BREADCRUMB,
  payload: crumbs,
});

export default {};
