import React from 'react';
import { Row,Col, Empty } from 'antd';

import CustomGantt from './CustomGantt';

export default ({ colFilters,minProjectStart, maxProjectEnd, projects, height }) => {
  return (<div align='top' style={{width:'100%', height: 'calc(100% - 32px)'}}>
  <Row>
    <Col style={{ width: '100%' }}>
      {colFilters}
    </Col>
  </Row>
  <Row style={{width:'100%', height:'90%'}}>
    <Col style={{ width: '100%',paddingTop: 20, }}>
     {projects.length === 0 ? <Row justify='center' align='middle' style={{ width: '100%', height: '100%'}}>
          <Empty/>
       </Row> : 
     <CustomGantt
      minProjectStart={minProjectStart}
      maxProjectEnd={maxProjectEnd}
      projects={projects}
      height={height}
    />
     }
    </Col>
  </Row>
</div>)
}
