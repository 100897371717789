import React, { useState, useMemo, useCallback } from 'react';
import { Popover, Select, Row, Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import DisplayText from '../common/text/DisplayText';
import BorderlessButton from '../common/buttons/BorderlessButton';

import { getRepeatText } from '../helpers/time';

const getPopoverTitle = ({ onClose }) => (
  <Row justify='space-between' align='middle'>
    <Col>
      Repeat
    </Col>
    <Col>
      <CloseOutlined
        className='schedule-month-more-close'
        onClick={onClose}
      />
    </Col>
  </Row>
);

export default ({
  startTime,
  value: repeatMode = null,
  onChange,
  isDisplay,
  isTemplate,
  timezone,
}) => {
  const [visible,setVisible] = useState(false);
  const startDT = useMemo(() => startTime 
    ? DateTime.fromMillis(startTime, { zone: timezone })
    : DateTime.local(),[startTime, timezone]);
  const title = useMemo(() => getRepeatText({ mode: repeatMode, datetime: startDT }), [repeatMode, startDT]);
  const onClose = useCallback(() => setVisible(false),[]);
  const onClick = useCallback(() => setVisible(!visible),[visible]);
  const onSelect = useCallback((val) => {
    onChange(val);
    setVisible(false);
  },[onChange]);

  if (isDisplay) {
    return (
      <DisplayText
        title={getRepeatText({ mode: repeatMode, datetime: startDT })}
      />
    )
  }
  return (
    <Popover
      placement='left'
      trigger='click'
      visible={visible}
      title={getPopoverTitle({ onClose })}
      content={
        <Select
          value={repeatMode}
          onSelect={onSelect}
          style={{ width: 400 }}
          options={[
            { label: getRepeatText({ mode: null, datetime: startDT, isTemplate}), value: null },
            { label: getRepeatText({ mode: 'daily', datetime:  startDT, isTemplate}), value: 'daily' },
            { label: getRepeatText({ mode: 'weekly', datetime:  startDT, isTemplate}), value: 'weekly' },
            { label: getRepeatText({ mode: 'monthlyNumber', datetime:  startDT, isTemplate}), value: 'monthlyNumber' },
            { label: getRepeatText({ mode: 'monthlyDay', datetime:  startDT, isTemplate}), value: 'monthlyDay' },
            { label: getRepeatText({ mode: 'weekdays', datetime:  startDT, isTemplate}), value: 'weekdays' },
            { label: getRepeatText({ mode: 'annually', datetime:  startDT, isTemplate}), value: 'annually' },
          ]}
        />
      }
    >
      <BorderlessButton
        title={title}
        style={{ width: 300, padding: 0, textAlign: 'left', marginLeft: 13 }}
        onClick={onClick}
      />
    </Popover>
  );
}