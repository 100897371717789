import React from 'react';
import { Row, Col } from 'antd';
import DisplayText from '../common/text/DisplayText';

export default ({
  title,
  subtitle,
  children,
  style = {},
}) => (
  <Row style={{ width:500, height: 32, ...style }} align='middle' justify='space-between'>
    <Col>
      <Row>
        <Col>
          <DisplayText 
            title={title}
            style={{
              fontSize:16,
              fontFamily: 'roboto-bold',
              marginBottom:0,
            }}
          />
        </Col>
        <Col>
          <DisplayText
            title={subtitle}
            style={{
              fontSize:16,
              marginBottom:0,
            }}
          />
        </Col>
      </Row>
    </Col>
    <Col>
      {children}
    </Col>
  </Row>
)