import axios from 'axios';
import { getFileType, removeTrailingSlash } from '../../../files/fileHelpers';
import { uploadFilesToS3 } from '../../../files/state/files.service';
import { prepareCostcodeDistributionPayload } from '../invoiceHelpers';
import Analytics from '../../../helpers/Analytics';
import { request } from '../../../helpers/requests';

const InvoiceService = {
  getInvoiceStatusLabels: () => request({
    call: axios.get('/invoices/labels'),
    errMsg: 'Could not get invoice status labels',
    hideSuccessToast: true,
  }),
  getInvoices: (query = {}) => request({
    call: axios.get('/invoices', { params: query }),
    errMsg: 'Could not get invoices',
    hideSuccessToast: true,
  }),
  createInvoice: (payload) => request({
    call: async () => {
      const newPayload = { ...payload };
      const fileToUpload = [];
      const fileMetadata = [];
      const { files, filePath } = newPayload;
      delete newPayload.filePath;
      delete newPayload.files;
      files.forEach((file) => {
        fileToUpload.push({
          jsFileObject: file,
          path: filePath,
        });
        fileMetadata.push({
          type: file.type,
          size: file.size,
        });
      });
      // Upload Invoice Attachments to S3:
      Analytics.track('Files/UploadFiles', { files: fileMetadata });
      const uploadedFiles = await uploadFilesToS3(fileToUpload) ?? [];
      // Create Invoice:
      const refinedPayload = {
        ...newPayload,
        files: uploadedFiles.map(({ id, name, path }) => ({
          id,
          name,
          path: path ? removeTrailingSlash(path) : '',
        })),
        device: 'web',
      };
      return axios.post('/invoices', refinedPayload);
    },
    errMsg: 'could not create invoice',
  }),
  updateInvoice: (id, payload) => request({
    call: async () => {
      const newPayload = { ...payload };
      const fileToUpload = [];
      const fileMetadata = [];
      const { filesToAdd, filesToUpdate, newFilePath } = newPayload;
      delete newPayload.filesToAdd;
      filesToAdd.forEach((file) => {
        fileToUpload.push({
          jsFileObject: file,
          path: newFilePath,
        });
        fileMetadata.push({
          type: file.type,
          size: file.size,
        });
      });
      // Upload Invoice Attachments to S3, if it is new:
      Analytics.track('Files/UploadFiles', { files: fileMetadata });
      const uploadedFiles = await uploadFilesToS3(fileToUpload) ?? [];
      return axios.put(`/invoices/${id}`, {
        ...newPayload,
        filesToUpdate: filesToUpdate.map((file) => {
          const { id, name, path } = file;
          return {
            id,
            name,
            path: path ? removeTrailingSlash(path) : '',
            type: getFileType(file),
          };
        }),
        filesToAdd: uploadedFiles.map(({ id, name, path }) => ({
          id,
          name,
          path: path ? removeTrailingSlash(path) : '',
        })),
        device: 'web',
      });
    },
    errMsg: 'could not update invoice',
  }),
  deleteInvoice: (id) => request({
    call: axios.delete(`/invoices/${id}`),
    errMsg: 'could not delete invoice',
  }),
  getFormInvoicesCostToDate: (payload) => request({
    call: axios.post('/forms/costToDate', payload),
    errMsg: 'Could not retrieve form invoices cost to date',
    hideSuccessToast: true,
  }),
  addCostcodeDistribution: (payload) => request({
    call: async () => {
      const refinedPayload = prepareCostcodeDistributionPayload(payload);
      return axios.post(`/invoices/costcodeDistributions`, refinedPayload)
    },
    errMsg: 'Could not add costcode distribution',
  }),
  updateCostcodeDistribution: (id, payload) => request({
    call: axios.put(`/invoices/costcodeDistributions/${id}`, payload),
    errMsg: 'Could not updated costcode distribution',
  }),
  deleteCostcodeDistribution: (id) => request({
    call: axios.delete(`invoices/costcodeDistributions/${id}`),
    errMsg: 'Could not delete costcode distribution',
  }),
};

export default InvoiceService;
