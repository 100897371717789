import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Form,
  Checkbox,
  Divider,
  Row,
  Collapse,
  Table,
  Col,
} from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ExportOutlined
} from '@ant-design/icons';

import {
  addGanttScheduleHolidays,
  deleteGanttScheduleHoliday,
} from '../../state/schedule.actions';
import Permissions from '../../../auth/Permissions';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import getGanttScheduleHolidayColumns from '../GanttScheduleHolidayColumns';
import GanttScheduleAddHolidayDrawer from '../GanttScheduleAddHolidayDrawer';
import { defaultWorkingDays } from '../ganttScheduleHelpers';

const CheckboxGroup = Checkbox.Group;
const { Panel } = Collapse;

const workingDays = [
  {
    label: 'Monday',
    value: 1,
  },
  {
    label: 'Tuesday',
    value: 2,
  },
  {
    label: 'Wednesday',
    value: 3,
  },
  {
    label: 'Thursday',
    value: 4,
  },
  {
    label: 'Friday',
    value: 5,
  },
  {
    label: 'Saturday',
    value: 6,
  },
  {
    label: 'Sunday',
    value: 7,
  },
];

export default function GanttScheduleGeneralSettingsTab(props) {
  const {
    isReadOnly = false,
    scheduleId,
    settings = {
      workingDays: defaultWorkingDays,
    },
    holidays = [],
    workingDaysList = [],
    setWorkingDaysList,
    visible,
    history,
    onExport,
  } = props;

  const dispatch = useDispatch();

  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const onChange = useCallback((list) => {
    setWorkingDaysList(list);
    setIndeterminate(!!list.length && list.length < workingDays.length);
    setCheckAll(list.length === workingDays.length);
  }, [workingDays]);

  const onCheckAllChange = useCallback((e) => {
    setWorkingDaysList(e.target.checked ? workingDays.map((task) => task.value) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  }, [workingDays]);

  const onDeleteClick = useCallback((record) => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Holiday',
      content: (
        <p>
          Are you sure you wish to delete holiday?
          <br />
          <br />
          Deleting the holiday will result in the end date and
          progress being modified for existing rows.
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        resolve(await dispatch(deleteGanttScheduleHoliday(scheduleId, record.id)));
      },
      onCancel() {
        resolve();
      },
    });
  }), [dispatch, scheduleId]);

  const columns = useMemo(() => getGanttScheduleHolidayColumns({
    onDelete: onDeleteClick,
    readOnly: isReadOnly,
    scheduleId,
  }), [scheduleId, onDeleteClick, isReadOnly]);

  useEffect(() => {
    if (visible) {
      if (settings.workingDays) {
        onChange(settings.workingDays);
      } else {
        onChange(defaultWorkingDays);
      }
    }
  }, [settings, visible]);

  const getPanelButton = ({ onClick, disabled = false }) => {
    const onButtonClick = (event) => {
      // Prevent panel from collapsing;
      event.stopPropagation();
      onClick();
    };

    return (
      <OnTraccrButton
        title="Add Holiday"
        onClick={onButtonClick}
        disabled={disabled}
      />
    );
  };

  const onAddSubmit = (payload) => {
    const result = dispatch(addGanttScheduleHolidays(scheduleId, {
      name: payload.name,
      date: payload.date.valueOf() / 1000,
    }));
    if (result) {
      setVisibleDrawer(false);
    }
  };

  return (
    <>
      <Form
        style={{
          maxWidth: '95%',
        }}
        layout="vertical"
      >
        <h3>Working Days</h3>
        <p>Please note that at least 1 working day must be selected.</p>
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Check all working days
        </Checkbox>
        <br />
        <br/>
        <CheckboxGroup
          value={workingDaysList}
          onChange={onChange}
        >
          <Row>
            {workingDays.map((workingDay) => (
              <Col span={6} key={workingDay.value}>
                <Checkbox value={workingDay.value}>{workingDay.label}</Checkbox>
              </Col>
            ))}
          </Row>
        </CheckboxGroup>
        <Divider />
        <Collapse defaultActiveKey="holidays">
          <Panel
            header="Holidays"
            key="holidays"
            extra={!isReadOnly && getPanelButton({ onClick: () => setVisibleDrawer(true) })}
          >
            <Table
              columns={columns}
              dataSource={holidays}
              rowKey="id"
              pagination={false}
            />
          </Panel>
        </Collapse>
        { Permissions.has('SETTINGS_WRITE')
          && (
            <>
              <Divider />
              <h3>Labels</h3>
              <OnTraccrButton
                title="Manage Labels"
                onClick={() => history.push('/settings/labels')}
              />
            </>
          )}
        <Divider />
        <h3>Export</h3>
        <OnTraccrButton
          title="Export Schedule"
          onClick={onExport}
          icon={<ExportOutlined />}
        />
      </Form>
      <GanttScheduleAddHolidayDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        handleSubmit={onAddSubmit}
      />
    </>
  );
}

GanttScheduleGeneralSettingsTab.propTypes = {
  settings: PropTypes.shape({
    workingDays: PropTypes.arrayOf(PropTypes.number),
  }),
  scheduleId: PropTypes.string,
  isReadOnly: PropTypes.bool,
  holidays: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.number,
  })),
  workingDaysList: PropTypes.arrayOf(PropTypes.number).isRequired,
  setWorkingDaysList: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  onExport: PropTypes.func.isRequired,
};

GanttScheduleGeneralSettingsTab.defaultProps = {
  settings: {
    workingDays: defaultWorkingDays,
  },
  isReadOnly: false,
  holidays: [],
  scheduleId: '',
};
