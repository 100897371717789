import React, {
  useMemo, useState, useCallback, useRef, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FixedSizeList as List } from 'react-window';
import ResizeObserver from 'rc-resize-observer';

import { getIdMap, isNullOrUndefined } from '../../helpers/helpers';

import LargeBoardCard from './LargeBoardCard';

import {
  openCardDrawer,
  openFormMapDrawer,
} from '../state/boards.actions';

const CARD_HEIGHT = 22;

function LargeBoard() {
  const listRef = useRef();
  const dispatch = useDispatch();
  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const {
    boardId: targetBoardId,
    cardId: targetCardId,
  } = useSelector((state) => state.boards.targetCard);

  const [dimensions, setDimensions] = useState({ width: 1000, height: 1000 });
  const {
    id: boardId,
    statuses = [],
    cards = [],
  } = selectedBoard || {};

  const {
    sortedCards,
    cardMap,
  } = useMemo(() => {
    const statusMap = getIdMap(statuses);
    const sorted = cards.map((card) => {
      const { statusId } = card;
      const {
        [statusId]: { title: status } = {},
      } = statusMap;
      return { ...card, status };
    });
    sorted.sort((a, b) => {
      const {
        [a.statusId]: { orderIndex: aStatus = Number.MAX_SAFE_INTEGER } = {},
        [b.statusId]: { orderIndex: bStatus = Number.MAX_SAFE_INTEGER } = {},
      } = statusMap;
      if (aStatus < bStatus) {
        return -1;
      }
      if (bStatus < aStatus) {
        return 1;
      }
      return a.orderIndex - b.orderIndex;
    });
    const cMap = {};
    sorted.forEach((card, index) => {
      cMap[card.id] = { ...card, index };
    });
    return {
      sortedCards: sorted,
      cardMap: cMap,
    };
  }, [statuses, cards]);

  const onForm = useCallback((cardId) => {
    const {
      [cardId]: newSelectedCard,
    } = cardMap;
    if (!newSelectedCard) return;
    dispatch(openFormMapDrawer({
      selectedCardId: cardId,
    }));
  }, [dispatch, cardMap]);

  const onCardClick = useCallback((index) => {
    const card = sortedCards[index];
    dispatch(openCardDrawer({
      selectedCardId: card.id,
      onForm,
    }));
  }, [dispatch, onForm]);

  const onResize = useCallback(({ height, width }) => {
    setDimensions({ height, width });
  }, []);

  useEffect(() => {
    if (targetBoardId === boardId && targetCardId in cardMap) {
      const {
        [targetCardId]: { index } = {},
      } = cardMap;
      if (!isNullOrUndefined(index)) {
        onCardClick(index);
        if (listRef && listRef.current) {
          listRef.current.scrollToItem(index, 'smart');
        }
      }
    }
  }, [dispatch, listRef, boardId, cardMap, targetBoardId, targetCardId, onCardClick]);

  return (
    <ResizeObserver onResize={onResize}>
      <div className="board-large-list-view-container-regular">
        <List
          height={dimensions.height}
          itemCount={sortedCards.length}
          itemSize={CARD_HEIGHT}
          width={dimensions.width}
          ref={listRef}
        >
          {({ index, style }) => {
            const card = sortedCards[index];
            return (
              <LargeBoardCard
                card={card}
                index={index}
                style={style}
                onClick={onCardClick}
              />
            );
          }}
        </List>
      </div>
    </ResizeObserver>
  );
}

export default LargeBoard;
