import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import TeamTimeCardContainer from './TeamTimeCardContainer';

import { setBreadcrumb } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import { getUsers } from '../users/state/users.actions';
import { getTeams } from '../teams/state/teams.actions';
import { getApprovals } from '../clock/state/clockin.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getSageShifts } from '../sage/state/sage.actions';
import { getTablets } from '../tablets/state/tablets.actions';

const BASE_CRUMBS = [{
  text: 'Time Cards',
  icon: 'carry-out',
}, {
  text: 'Team Cards',
}];

function TeamTimeCards() {
  const dispatch = useDispatch();

  const crumbs = useSelector((state) => state.breadcrumb);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const menuCollapsed = useSelector((state) => state.main.menuCollapsed);

  useEffect(() => {
    dispatch(getTeams());
    dispatch(getUsers());
    dispatch(getApprovals());
    dispatch(getUnions());
    dispatch(getTablets());
    dispatch(setBreadcrumb(BASE_CRUMBS));
  }, []);

  useEffect(() => {
    dispatch(getSageShifts({ divisionIds: Array.from(selectedDivisions) }));
  }, [selectedDivisions]);

  const onUserSelected = useCallback((name) => {
    dispatch(setBreadcrumb(BASE_CRUMBS.concat({ text: name })));
  }, [dispatch, crumbs]);

  return (
    <BreadCrumbContainer
      crumbs={crumbs}
      fullscreen={menuCollapsed}
      bodyStyle={{ marginTop: 10 }}
    >
      <TeamTimeCardContainer
        onUserSelect={onUserSelected}
      />
    </BreadCrumbContainer>
  );
}

export default TeamTimeCards;
