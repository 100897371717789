import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import { PropTypes } from 'prop-types';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomModal from '../../common/modals/CustomModal';

import colors from '../../constants/Colors';

import { deleteBoardCardTemplate } from '../../boards/state/boards.actions';

export default function BoardCardDeleteModal({
  id,
  title,
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const [text, setText] = useState();
  const onChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setText(value);
  }, []);

  const onDeleteClicked = useCallback(async () => {
    if (await dispatch(deleteBoardCardTemplate(id, text))) {
      onClose();
    }
  }, [dispatch, id, text]);

  useEffect(() => {
    if (!visible) setText();
  }, [visible]);

  const titleText = `Delete "${title}" card template?`;
  return (
    <CustomModal
      title={titleText}
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
    >
      <div style={{ fontSize: 14, padding: 10 }}>
        <b>Warning:</b>
        {' '}
        Deleting a board card template is permanent
        <br />

        Deleting this card will delete any boards using this card.

        <br />
        <br />
        Please enter your password below to confirm
      </div>
      <Row style={{ margin: '20px 5px' }}>
        <OnTraccrTextInput
          password
          autoComplete="new-password"
          placeholder="Enter your password"
          onChange={onChange}
          value={text}
        />
      </Row>

      <Row justify="center" gutter={12} style={{ marginBottom: 10 }}>
        <Col>
          <OnTraccrButton
            type="cancel"
            title="Cancel"
            roundStyle
            onClick={onClose}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title="Delete"
            roundStyle
            disabled={!text}
            onClick={onDeleteClicked}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}

BoardCardDeleteModal.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string,
  visible: PropTypes.number,
  onClose: PropTypes.func.isRequired,
};

BoardCardDeleteModal.defaultProps = {
  title: '',
  visible: false,
};
