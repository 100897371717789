import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Checkbox,
  Row,
  Col,
  message,
} from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

import FormSelector from '../../FormWorkflows/selectors/FormSelector';
import UserAssignmentSelector from '../../FormWorkflows/selectors/UserAssignmentSelector';
import HoverHelp from '../../../common/HoverHelp';

import { createTargetToSourceMap, createSourceToTargetMap } from '../../formHelpers';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import WorkflowTriggerConfigureDrawer from '../../FormWorkflows/WorkflowTriggerConfigureDrawer';

const AUTHOR_HELP = `The new form will be assigned to the original form's author`;

function FieldTriggerPopover({
  sections,
  projectId,
  templateId,
  divisions,
  isExternalForm,
  setConfigProps,
  configProps,
  responding = false,
  id,
  fieldTriggerMap,
  setFieldTriggerMap,
  name,
}) {
  const [divisionId] = divisions;

  const {
    fieldTriggerProps: {
      form: initialForm,
      users: initialUsers,
      fieldMappings: initialFieldMappings = {},
      sharedForm: initialSharedForm,
      linkedNumber: initialLinkedNumber,
      editable = false,
    } = {},
  } = configProps;

  const locked = !editable && responding;

  const [messageApi, contextHolder] = message.useMessage();

  const [selectedForm, setSelectedForm] = useState(initialForm);
  const [selectedUsers, setSelectedUsers] = useState(initialUsers || []);
  const [sharedForm, setSharedForm] = useState(initialSharedForm);
  const [linkedNumber, setLinkedNumber] = useState(initialLinkedNumber);
  const [fieldMappings, setFieldMappings] = useState(
    initialFieldMappings?.version === 'v2' ? initialFieldMappings : createTargetToSourceMap(initialFieldMappings),
  );

  const [triggered, setTriggered] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);

  const openDrawer = useCallback(() => setShowDrawer(true), []);
  const closeDrawer = useCallback(() => setShowDrawer(false), []);

  const onFormChange = useCallback((newForm) => {
    setSelectedForm(newForm);
    if (!setConfigProps) return; // switching preview & responding settings wont affect config
    setConfigProps({
      ...configProps,
      fieldTriggerProps: {
        ...configProps.fieldTriggerProps,
        form: newForm,
      },
    });
  }, [configProps]);

  const onRecipientChange = useCallback((newUsers) => {
    setSelectedUsers(newUsers);
    if (!setConfigProps) return;
    setConfigProps({
      ...configProps,
      fieldTriggerProps: {
        ...configProps.fieldTriggerProps,
        users: newUsers,
      },
    });
  }, [configProps]);

  const onSharedChange = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setSharedForm(checked);
    if (!setConfigProps) return;
    setConfigProps({
      ...configProps,
      fieldTriggerProps: {
        ...configProps.fieldTriggerProps,
        sharedForm: checked,
      },
    });
  }, [configProps]);

  const onLinkChange = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setLinkedNumber(checked);
    if (!setConfigProps) return;
    setConfigProps({
      ...configProps,
      fieldTriggerProps: {
        ...configProps.fieldTriggerProps,
        linkedNumber: checked,
      },
    });
  }, [configProps]);

  const onFieldMappingsChange = useCallback((newMappings, formId) => {
    const updatedFieldMappings = {
      ...fieldMappings,
      version: 'v2',
      [formId]: createSourceToTargetMap(newMappings),
    };
    setFieldMappings(updatedFieldMappings);
    if (!setConfigProps) return;
    setConfigProps({
      ...configProps,
      fieldTriggerProps: {
        ...configProps.fieldTriggerProps,
        fieldMappings: updatedFieldMappings,
      },
    });
  }, [fieldMappings, configProps]);

  const onTrigger = useCallback(() => {
    setFieldTriggerMap({
      ...fieldTriggerMap,
      [id]: {
        form: selectedForm,
        users: selectedUsers,
        fieldMappings,
        sharedForm,
        linkedNumber,
      },
    });
    setTriggered(true);
    messageApi.info('Form will be triggered on submission');
  }, [
    id,
    fieldTriggerMap,
    selectedForm,
    selectedUsers,
    sharedForm,
    linkedNumber,
  ]);

  const onCancel = useCallback(() => {
    const newObj = { ...fieldTriggerMap };
    delete newObj[id];
    setFieldTriggerMap(newObj);
    setTriggered(false);
  }, [id, fieldTriggerMap]);

  useEffect(() => {
    // clears new fields in form template configure
    setSelectedForm(initialForm);
    setSelectedUsers(initialUsers || []);
  }, [configProps]);

  return (
    <div style={{ width: 200 }}>
      {contextHolder}
      {!responding && (
        <>
          <Row>
            <Col>
              <Checkbox
                onChange={onSharedChange}
                defaultChecked={initialSharedForm}
                disabled={triggered}
              />
            </Col>
            <Col style={{ padding: '0px 5px' }}>
              Shared Form?
            </Col>
            <Col>
              <HoverHelp
                content={(
                  <div style={{ width: 300 }}>
                    Check this box if you want to create one form and assign it to multiple people
                    <br />
                    <br />
                    Leave this box unchecked if you want to create a separate form for each person.
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <Checkbox
                onChange={onLinkChange}
                defaultChecked={initialLinkedNumber}
                disabled={triggered}
              />
            </Col>
            <Col style={{ padding: '0px 5px' }}>
              Link Number?
            </Col>
            <Col>
              <HoverHelp
                content={(
                  <div style={{ width: 300 }}>
                    Check this box if you want the triggered form&#39;s
                    number to be linked to this form&#39;s number
                    <br />
                    <br />
                    Leave this box unchecked if you want the triggered form to have its own number.
                  </div>
                )}
              />
            </Col>
          </Row>
        </>
      )}
      <FormSelector
        divisionId={divisionId}
        templateId={templateId}
        projectId={projectId}
        hideExternalForms
        onChange={onFormChange}
        selected={selectedForm}
        disable={triggered || locked}
      />
      <UserAssignmentSelector
        type="user"
        divisions={[divisionId]}
        showPositions
        showFields
        sections={sections}
        showFormAuthor={!isExternalForm}
        onChange={onRecipientChange}
        selected={selectedUsers}
        disabled={triggered || locked}
        formAuthorHelperText={AUTHOR_HELP}
      />
      {locked && (
        <Row>
          <b>Options cannot be changed for this field.</b>
        </Row>
      )}
      {responding && (
        <Row justify="end">
          {triggered && (
            <OnTraccrButton
              title="Cancel"
              onClick={onCancel}
              style={{
                backgroundColor: 'grey',
                borderColor: 'grey',
                margin: '0px 5px',
                marginTop: '10px',
              }}
            />
          )}
          <OnTraccrButton
            title="Trigger"
            onClick={onTrigger}
            disabled={triggered || !selectedForm || selectedUsers.length === 0}
            style={{
              marginTop: '10px',
            }}
          />
        </Row>
      )}
      {!responding && (
        <>
          <BorderlessButton
            title="Configure"
            style={{ margin: 5 }}
            iconNode={<SettingOutlined />}
            onClick={openDrawer}
            disabled={!selectedForm}
          />
          <WorkflowTriggerConfigureDrawer
            visible={showDrawer}
            onClose={closeDrawer}
            onSubmit={closeDrawer}
            targetFormId={selectedForm}
            sourceName={name}
            sourceSections={sections}
            fieldMappings={fieldMappings}
            onFieldMappingsChange={onFieldMappingsChange}
            divisionId={divisionId}
            templateId={templateId}
            zIndex={1031} // popover z-index is 1030
          />
        </>
      )}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
FieldTriggerPopover.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  projectId: PropTypes.string,
  templateId: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  configProps: PropTypes.object,
  setConfigProps: PropTypes.func,
  isExternalForm: PropTypes.bool,
  responding: PropTypes.bool,
  id: PropTypes.string,
  fieldTriggerMap: PropTypes.object,
  setFieldTriggerMap: PropTypes.func,
  name: PropTypes.string,
};

FieldTriggerPopover.defaultProps = {
  sections: [],
  projectId: null,
  templateId: null,
  divisions: [],
  configProps: {},
  isExternalForm: false,
  responding: false,
  setConfigProps: null,
  id: null,
  fieldTriggerMap: {},
  setFieldTriggerMap: null,
  name: null,
};

export default FieldTriggerPopover;
