import React from 'react';
import { NotificationOutlined } from '@ant-design/icons';

import LiveFeedEntry from '../../dashboard/LiveFeed/LiveFeedEntry';
import LiveFeedPost from '../../dashboard/LiveFeed/LiveFeedPost';

export default ({
  note,
  user,
  date,
  isCurrentUser,
  hideDivider,
  unread,
  userId,
  isBroadcast
}) => (
  <LiveFeedEntry
    unread={unread}
    user={user}
    date={date}
    isCurrentUser={isCurrentUser}
    hideDivider={hideDivider}
    customIcon={<></>}
    isBroadcast={isBroadcast}
    extra={
      <LiveFeedPost post={{ text: note }} reverse={!!userId}/>
    }
    reverse={!!userId}
    hideIcon
  />
)