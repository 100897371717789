import React, { useCallback, useMemo, useState } from 'react';
import {
  Table,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';

const expandableColumnMap = new Set([
  'Actual User Count',
  'Actual/Expected User Count',
  'Equipment',
  'Materials',
  'Cost Codes',
  'Parent Dependencies',
  'Child Dependencies',
]);

export default function GanttScheduleList(props) {
  const {
    tasks,
    visibleColumns,
    isReadOnly,
    onEditClick,
  } = props;

  const [expandableRowKeys, setExpandableRowKeys] = useState([]);

  const ganttColumns = useMemo(() => {
    const columns = [];
    const expandableColumns = [];

    visibleColumns.forEach((column) => {
      if (expandableColumnMap.has(column.label)) {
        expandableColumns.push(column);
      }

      columns.push({
        title: column.label,
        dataIndex: column.property,
        width: column.width,
        render: column.formatter,
      });
    });

    if (!isReadOnly) {
      columns.push({
        title: '',
        dataIndex: 'id',
        width: 100,
        render: (text) => <EditOutlined onClick={() => onEditClick({ id: text })} />,
      });
    }

    setExpandableRowKeys(expandableColumns);

    return columns;
  }, [visibleColumns, isReadOnly]);

  const expandedRowRender = useCallback((record) => (
    <Row>
      {expandableRowKeys.map((column) => (
        <div style={{ paddingLeft: 20, paddingRight: 20 }} key={record.id + column.label}>
          {column.listFormat(record[column.property])}
        </div>
      ))}
    </Row>
  ), [expandableRowKeys]);

  return (
    <div style={{ width: '100%', height: '100%', overflow: 'scroll' }}>
      <Table
        columns={ganttColumns}
        dataSource={tasks}
        pagination={false}
        rowKey="id"
        size="small"
        className="gantt-schedule-list-table"
        expandable={{
          expandedRowRender,
          rowExpandable: () => expandableRowKeys.length,
        }}
      />
    </div>
  );
}

GanttScheduleList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    progress: PropTypes.number.isRequired,
    dependencies: PropTypes.string,
  })).isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
    width: PropTypes.number.isRequired,
    formatter: PropTypes.func,
  })).isRequired,
  isReadOnly: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
};
