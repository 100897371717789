import React from 'react';

const splitInstructions = (string) => string.split('\n').map((s) => {
  if (!s) return <br/>
  return (
    <div style={{
      padding: s.match(/^\d/) ? '0px 10px' : 0,
    }}>{s}</div>
  )
});

export default ({
  instructions,
}) => {
  if (!instructions) return null;
  const {
    details = '',
    fields = '',
    workflow = '',
    pdfDesigner = '',
  } = instructions;
  return (
    <>
      <div className='form-library-add-section-header'>Instructions: </div>
      <div className='form-library-add-text'>
        <div className='form-library-add-section-subheader'>Details:</div>
        {splitInstructions(details)}
      </div>
      <div className='form-library-add-text'>
        <div className='form-library-add-section-subheader'>Fields:</div>
        {splitInstructions(fields)}
      </div>
      <div className='form-library-add-text'>
        <div className='form-library-add-section-subheader'>Workflow:</div>
        {splitInstructions(workflow)}
      </div>
      <div className='form-library-add-text'>
        <div className='form-library-add-section-subheader'>PDF Designer:</div>
        {splitInstructions(pdfDesigner)}
      </div>
    </>
  )
};
