import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  Col,
  Drawer,
  Row,
  Select,
} from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';

import {
  createAnalyticsReport,
  deleteAnalyticsReport,
  selectSavedReport,
  updateAnalyticsReport,
  clearAnalyticsConfig,
} from '../../analytics/state/analytics.actions';

import Colors from '../../constants/Colors';

import OnTraccrTextInput from '../inputs/OnTraccrTextInput';
import CustomConfirmModal from '../modals/CustomConfirmModal';
import BorderlessButton from '../buttons/BorderlessButton';

import { dateRangeToDayDiffs } from '../../analytics/analytics.helpers';
import { getFilteredReports } from '../../helpers/dashboardAnalytics';

import AnalyticsHeader from '../../analytics/AnalyticsHeader';
import AnalyticsBody from '../../analytics/AnalyticsBody';

const DEFAULT_NAME = 'Untitled';

function AnalyticsSlider({
  visible,
  onClose,
  projectId,
  projectTypeId,
  type,
  analyticsConfig,
  cardId,
  cardTypeId,
  boardId: currentBoardId,
}) {
  const dispatch = useDispatch();

  const reportRef = useRef();

  const {
    boardId,
    formTemplateId,
    dateRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    chartType,
    fieldId,
    aggregate,
    breakdown,
    filters,
    savedReports,
    selectedReportId,
    groups = {},
    filterByFieldUpdatedTime = false,
    statusFilter = [],
  } = analyticsConfig;

  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(DEFAULT_NAME);

  const filteredReports = useMemo(() => (
    getFilteredReports({
      savedReports,
      type,
      projectTypeId,
      cardTypeId,
    })
  ), [savedReports, type, projectTypeId, cardTypeId]);

  const selectedReport = useMemo(() => (
    savedReports[selectedReportId] ?? {}
  ), [selectedReportId, savedReports]);

  const onExport = useCallback(() => {
    if (reportRef?.current?.export) reportRef.current.export(name);
  }, [reportRef, name]);

  const onOpenReport = useCallback((reportId) => {
    dispatch(selectSavedReport({
      type,
      id: reportId,
    }));
  }, [type]);

  const onEditClicked = useCallback((e) => {
    e.stopPropagation();
    setEditMode(true);
  }, []);

  const onEditBlur = useCallback(() => setEditMode(false), []);

  const onTextChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setName(value);
  }, []);

  const onDeleteClicked = useCallback((e) => {
    e.stopPropagation();
    CustomConfirmModal({
      title: `Delete Report '${name}'?`,
      onOk: () => {
        dispatch(deleteAnalyticsReport({
          type,
          id: selectedReportId,
        }));
      },
    });
  }, [selectedReportId, name, type]);

  const onSaveClicked = useCallback((e) => {
    e.stopPropagation();
    const [startTime, endTime] = dateRangeToDayDiffs(dateRange);
    const payload = {
      title: name,
      startTime,
      endTime,
      datePreset,
      chartType,
      fieldId,
      aggregate,
      breakdown,
      filters,
      groups,
      projectTypeId,
      analyticsType: type,
      cardTypeId,
      filterByFieldUpdatedTime,
      statusFilter,
    };
    if (boardId) {
      payload.boardId = boardId;
    } else {
      payload.formTemplateId = formTemplateId;
    }
    if (dateBreakdown) {
      payload.dateBreakdown = dateBreakdown;
    } else {
      payload.fieldBreakdown = fieldBreakdown;
    }
    if (selectedReportId) {
      dispatch(updateAnalyticsReport({
        // edit
        type,
        id: selectedReportId,
        payload,
      }));
    } else {
      // create
      dispatch(createAnalyticsReport({
        type,
        payload,
      }));
    }
  }, [
    dateRange,
    name,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    chartType,
    fieldId,
    aggregate,
    breakdown,
    filters,
    groups,
    boardId,
    formTemplateId,
    selectedReportId,
    projectTypeId,
    type,
    cardTypeId,
    filterByFieldUpdatedTime,
    statusFilter,
  ]);

  const canSave = useMemo(() => (
    (formTemplateId || boardId)
    && fieldId
    && (dateRange || datePreset)
    && (dateBreakdown || chartType === 'pie')
  ), [formTemplateId, boardId, fieldId, dateRange, datePreset, dateBreakdown, chartType]);

  useEffect(() => {
    if (selectedReport?.title) {
      setName(selectedReport.title);
    } else {
      setName(DEFAULT_NAME);
    }
  }, [selectedReport]);

  useEffect(() => {
    if (!visible) {
      setName(DEFAULT_NAME);
      setEditMode(false);
      dispatch(clearAnalyticsConfig({ type }));
    }
  }, [visible, type]);

  const titleNode = useMemo(() => (
    <Row align="middle" gutter={20}>
      <Col>
        {editMode
          ? (
            <OnTraccrTextInput
              autoFocus
              style={{ width: 200 }}
              defaultValue={name}
              onClick={(e) => e.stopPropagation()}
              onChange={onTextChange}
              onBlur={onEditBlur}
              onKeyDown={(e) => {
                if (e.key === 'Enter') onEditBlur();
              }}
            />
          ) : (
            <Select
              onSelect={onOpenReport}
              style={{ minWidth: 200 }}
              value={name}
            >
              <Select.Option value={null} key={DEFAULT_NAME}>
                <b>New Report</b>
                {' '}
                <span><PlusOutlined /></span>
              </Select.Option>
              {filteredReports.map((report) => (
                <Select.Option value={report.id} key={report.id}>
                  {report.title}
                </Select.Option>
              ))}
            </Select>
          )}
      </Col>
      {!editMode && (
        <Col>
          <BorderlessButton
            iconNode={<EditOutlined />}
            onClick={onEditClicked}
            style={{ padding: 0 }}
          />
        </Col>
      )}
      {!editMode && (
        <Col>
          <BorderlessButton
            iconNode={<SaveOutlined />}
            onClick={onSaveClicked}
            style={{
              padding: 0,
              backgroundColor: 'white',
            }}
            disabled={!canSave}
          />
        </Col>
      )}
      {(!editMode && selectedReportId) && (
        <Col>
          <BorderlessButton
            style={{
              color: Colors.ONTRACCR_RED,
              padding: 0,
            }}
            iconNode={<DeleteOutlined />}
            onClick={onDeleteClicked}
          />
        </Col>
      )}
    </Row>
  ), [
    editMode,
    name,
    selectedReportId,
    onEditClicked,
    onTextChange,
    onEditBlur,
    onSaveClicked,
    onDeleteClicked,
    filteredReports,
    onOpenReport,
    canSave,
  ]);

  return (
    <Drawer
      className="project-edit-drawer"
      title={titleNode}
      visible={visible}
      onClose={onClose}
    >
      <AnalyticsHeader
        type={type}
        onExport={onExport}
        analyticsConfig={analyticsConfig}
        currentBoardId={currentBoardId}
      />
      <AnalyticsBody
        type={type}
        ref={reportRef}
        analyticsConfig={analyticsConfig}
        projectId={projectId}
        cardId={cardId}
      />
    </Drawer>
  );
}

AnalyticsSlider.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  projectTypeId: PropTypes.string,
  type: PropTypes.string,
  analyticsConfig: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  cardId: PropTypes.string,
  cardTypeId: PropTypes.string,
  boardId: PropTypes.string,
};

AnalyticsSlider.defaultProps = {
  visible: false,
  projectTypeId: null,
  type: undefined,
  analyticsConfig: {},
  cardId: undefined,
  cardTypeId: undefined,
  boardId: undefined,
};

export default AnalyticsSlider;
