import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Select } from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FormTextInput from '../common/inputs/FormTextInput';
import DivisionSelector from '../common/inputs/DivisionSelector';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import BoardLibraryImportDrawer from './BoardLibraryImportDrawer';

import {
  createBoard,
} from './state/boards.actions';

import { pinValidator } from '../helpers/validators';

export default function ({
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const boards = useSelector((state) => state.boards.boards);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);

  const [showImportDrawer, setShowImportDrawer] = useState(false);

  const onShowImportClicked = useCallback(() => setShowImportDrawer(true), []);
  const hideImportDrawer = useCallback(() => setShowImportDrawer(false), []);
  const onImportSubmit = useCallback(() => {
    setShowImportDrawer(false);
    onClose();
  }, [onClose]);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields(); // Will throw if error
      const values = form.getFieldsValue();
      if (await dispatch(createBoard(values))) {
        onClose();
      }
    } catch (err) {
      //
    }
  }, [dispatch, form]);

  const boardTitleMap = useMemo(() => {
    const titleMap = {};
    Object.values(boards).forEach((board) => {
      const { title: existingTitle = '' } = board;
      titleMap[existingTitle.toLowerCase()] = board;
    });
    return titleMap;
  }, [boards]);

  const cardTemplateOptions = useMemo(() => (
    Object.values(cardTemplates).map((card) => ({
      label: card.title, value: card.id,
    }))
  ), [cardTemplates]);

  const titleValidator = useCallback((_, value) => {
    const lowerValue = value ? value.toLowerCase() : value;
    const {
      [lowerValue]: {
        id: existingId,
        title: existingTitle,
      } = {},
    } = boardTitleMap;
    if (existingId) return Promise.reject(`Board with title ${existingTitle} already exists`);
    return Promise.resolve();
  }, [boardTitleMap]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <Drawer
      title="Create a Board"
      visible={visible}
      onClose={onClose}
      width={500}
      bodyStyle={{
        padding: '0px 24px',
      }}
    >
      <Form form={form} layout="vertical">
        <FormTextInput
          name="title"
          label="Board Title"
          rules={[
            { required: true, message: 'Title is required' },
            { validator: titleValidator },
          ]}
          isNotDisplay
          placeholder="Enter a title"
        />
        <Form.Item
          name="divisions"
          label="Divisions"
          style={{ marginBottom: 0, marginTop: 5 }}
          rules={[
            { required: true, message: 'Please select at least one division' },
            { min: 1, type: 'array', message: 'Please select at least one division' },
          ]}
          valuePropName="divisions"
        >
          <DivisionSelector mode="multiple" />
        </Form.Item>
        <Form.Item
          name="cardTypeId"
          label="Card Type"
          style={{ marginBottom: 0, marginTop: 5 }}
          rules={[{ required: true, message: 'Please select a card type' }]}
        >
          <Select
            showSearch
            options={cardTemplateOptions}
            optionFilterProp="label"
            placeholder="Select a card type"
          />
        </Form.Item>

        <Form.Item
          name="statuses"
          label="Statuses"
          style={{ marginBottom: 0, marginTop: 5 }}
        >
          <Select
            mode="tags"
          />
        </Form.Item>
        <FormTextInput
          name="pin"
          label="Lock PIN"
          rules={[
            { validator: pinValidator },
          ]}
          isNotDisplay
          placeholder="Enter a PIN"
          password
        />
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
      <div className="form-template-global-import">
        <OnTraccrButton title="Import Board from Template" onClick={onShowImportClicked} />
      </div>
      <BoardLibraryImportDrawer
        visible={showImportDrawer}
        onClose={hideImportDrawer}
        onSubmit={onImportSubmit}
      />
    </Drawer>
  );
}
