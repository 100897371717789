import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Dropdown, Menu } from 'antd';
import { useDispatch } from 'react-redux';
import {
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import { createQuery, deleteQuery, updateQuery } from './state/search.actions';
import Colors from '../constants/Colors';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';

const DEFAULT_NAME = 'New Query';

export default function QuerySelector({
  currentQuery,
  searchQueryMap,
  selectedQuery,
  onSelectQuery,
  type,
}) {
  const dispatch = useDispatch();

  const searchQueries = useMemo(() => Object.values(searchQueryMap), [searchQueryMap]);

  const [edit, setEdit] = useState(false);
  const [name, setName] = useState(DEFAULT_NAME);

  const selectQuery = useCallback((q) => {
    const { key: queryId } = q;
    onSelectQuery(queryId === DEFAULT_NAME ? undefined : queryId);
  }, [onSelectQuery]);

  const onSaveQuery = useCallback(async (e) => {
    e.stopPropagation();
    if (selectedQuery) {
      dispatch(updateQuery(selectedQuery, { name, query: currentQuery }, type));
    } else {
      const newQuery = await dispatch(createQuery({ name, type, query: currentQuery }, type));
      if (newQuery) onSelectQuery(newQuery);
    }
  }, [selectedQuery, currentQuery, name, type]);

  const onDeleteQuery = useCallback((e) => {
    e.stopPropagation();
    if (!selectedQuery) return;
    dispatch(deleteQuery(selectedQuery, type));
    onSelectQuery(undefined);
    setName(DEFAULT_NAME);
  }, [selectedQuery, onSelectQuery, type]);

  const onEditClicked = useCallback((e) => {
    e.stopPropagation();
    setEdit(true);
  }, []);
  const onEditBlur = useCallback(() => setEdit(false), []);
  const onTextChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setName(value);
  }, []);

  const title = useMemo(() => (
    edit
      ? (
        <OnTraccrTextInput
          autoFocus
          defaultValue={name}
          onBlur={onEditBlur}
          onChange={onTextChange}
          style={{ width: 250, top: -5 }}
          onClick={(e) => e.stopPropagation()}
          onKeyDown={(e) => {
            if (e.key === 'Enter') onEditBlur();
          }}
        />
      )
      : name
  ), [edit, name]);

  useEffect(() => {
    const { name: newName = DEFAULT_NAME } = searchQueryMap[selectedQuery] || {};
    setName(newName);
  }, [selectedQuery, searchQueryMap]);

  return (
    <Dropdown
      trigger={['click']}
      overlay={(
        <Menu onClick={selectQuery}>
          <Menu.Item key={DEFAULT_NAME}>
            New Query
            {' '}
            <span><PlusOutlined /></span>
          </Menu.Item>
          {searchQueries.map((q) => (
            <Menu.Item
              key={q.id}
            >
              {q.name}
            </Menu.Item>
          ))}
        </Menu>
      )}
    >
      <span>
        <span {...edit ? { } : { id: 'board-crumb-button' }}>
          {title}
        </span>
        {!edit && (
          <span className="analytics-title-button">
            <EditOutlined onClick={onEditClicked} />
          </span>
        )}
        {!edit && (
          <span className="analytics-title-button">
            <SaveOutlined onClick={onSaveQuery} />
          </span>
        )}
        {(!edit && selectedQuery) && (
          <span className="analytics-title-button">
            <DeleteOutlined style={{ color: Colors.ONTRACCR_RED }} onClick={onDeleteQuery} />
          </span>
        )}
      </span>
    </Dropdown>
  );
}

QuerySelector.propTypes = {
  currentQuery: PropTypes.shape({}),
  onSelectQuery: PropTypes.func.isRequired,
  searchQueryMap: PropTypes.shape({}),
  selectedQuery: PropTypes.string,
  type: PropTypes.string.isRequired,
};

QuerySelector.defaultProps = {
  currentQuery: {},
  selectedQuery: undefined,
  searchQueryMap: {},
};
