import React from 'react';
import { useTranslation } from 'react-i18next';

import DisplayText from '../../common/text/DisplayText';
const ReportColumnHeader = ({ title, style = {} }) => {
  const { t } = useTranslation();
  return (
    <DisplayText
      title={t(title)}
      style={{
        fontSize: 12,
        marginBottom: 0,
        padding: 0,
        fontFamily:'roboto-medium',
        ...style,
      }}
    />
  );
}
export default ReportColumnHeader;

export const ReportColumn = (title, meta = {}, style) => ({
  title: <ReportColumnHeader title = {title} style={style}/>,
  titleString: title,
  align:'center',
  width: 150,
  key:title.toLowerCase(),
  dataIndex:title.toLowerCase(),
  ...meta,
  render:(val) => (
    <div className='ontraccr-cell'>
      {val}
    </div>
  )
})
