import React from 'react';
import { Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types';

import HoverHelp from '../../../common/HoverHelp';

function CheckboxRow({
  checked,
  title,
  onChange,
  hoverText,
  disabled,
}) {
  return (
    <Row style={{ marginTop: 10 }} gutter={10}>
      <Col>
        <Checkbox
          onChange={onChange}
          checked={checked}
          disabled={disabled}
        >
          {title}
        </Checkbox>
      </Col>
      {!!hoverText && (
      <Col>
        <HoverHelp
          placement="topRight"
          content={(
            <div style={{ width: 250 }}>
              {hoverText}
            </div>
          )}
        />
      </Col>
      )}
    </Row>
  );
}

CheckboxRow.propTypes = {
  checked: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  hoverText: PropTypes.node,
  disabled: PropTypes.bool,
};

CheckboxRow.defaultProps = {
  checked: false,
  hoverText: null,
  disabled: false,
};

export default CheckboxRow;
