const config = {
  baseURL: 'https://api.alpha.ontraccr.com',
};

const {
  REACT_APP_API_URL,
  REACT_APP_SENTRY_DNS,
  REACT_APP_NEW_MENU_TIMESTAMP,
  REACT_APP_ENV = 'beta',
  REACT_APP_SHOW_FORMS = false,
  REACT_APP_SHOW_SCHEDULE = false,
  REACT_APP_SHOW_DIVISIONS = false,
  REACT_APP_SHOW_PDF_DESIGNER = false,
  REACT_APP_SHOW_PROGRESS = false,
  REACT_APP_SHOW_EQUIPMENT = false,
  REACT_APP_SHOW_MATERIALS = false,
  REACT_APP_SHOW_SCHEDULE_OF_VALUES = false,
  REACT_APP_SHOW_PAYMENT_FIELD = false,
  REACT_APP_SHOW_BOARDS = false,
  REACT_APP_SHOW_GANTT = false,
  REACT_APP_SHOW_CLIENT_PORTAL = false,
  REACT_APP_SHOW_LEARNING_CENTER = false,
  REACT_APP_SHOW_ANALYTICS = false,
  REACT_APP_SHOW_PAYABLES_PAGE = false,
  REACT_APP_SHOW_ECLIPSE = false,
  REACT_APP_ALLOW_UPDATE_PROMPT = false,
  REACT_APP_APRYSE_KEY,
  REACT_APP_PRODUCT_FRUITS_CODE,
  REACT_APP_SHOW_PROJECT_ANALYTICS = false,
  REACT_APP_SHOW_DOCUSIGN = false,
} = process.env;

const isLocal = REACT_APP_ENV === 'local';

config.showForms = isLocal || REACT_APP_SHOW_FORMS;
config.showSchedule = isLocal || REACT_APP_SHOW_SCHEDULE;
config.showDivisions = isLocal || REACT_APP_SHOW_DIVISIONS;
config.env = REACT_APP_ENV;
config.newMenuTimestamp = REACT_APP_NEW_MENU_TIMESTAMP;
config.showPDFDesigner = isLocal || REACT_APP_SHOW_PDF_DESIGNER;
config.showProgress = isLocal || REACT_APP_SHOW_PROGRESS;
config.sentryDNS = REACT_APP_SENTRY_DNS;
config.showEquipment = isLocal || REACT_APP_SHOW_EQUIPMENT;
config.showMaterials = isLocal || REACT_APP_SHOW_MATERIALS;
config.showScheduleOfValues = isLocal || REACT_APP_SHOW_SCHEDULE_OF_VALUES;
config.showPaymentField = isLocal || REACT_APP_SHOW_PAYMENT_FIELD;
config.showBoards = isLocal || REACT_APP_SHOW_BOARDS;
config.showGantt = isLocal || REACT_APP_SHOW_GANTT;
config.showClientPortal = isLocal || REACT_APP_SHOW_CLIENT_PORTAL;
config.showLearningCenter = isLocal || REACT_APP_SHOW_LEARNING_CENTER;
config.showAnalytics = isLocal || REACT_APP_SHOW_ANALYTICS;
config.showPayablesPage = isLocal || REACT_APP_SHOW_PAYABLES_PAGE;
config.showEclipse = isLocal || REACT_APP_SHOW_ECLIPSE;
config.allowUpdatePrompt = isLocal || REACT_APP_ALLOW_UPDATE_PROMPT;
config.productFruitsCode = REACT_APP_PRODUCT_FRUITS_CODE;
config.showProjectAnalytics = isLocal || REACT_APP_SHOW_PROJECT_ANALYTICS;
config.showDocusign = isLocal || REACT_APP_SHOW_DOCUSIGN;
config.apryseKey = config.env === 'prod'
  ? REACT_APP_APRYSE_KEY
  : 'demo:1687890598485:7d91781b03000000005d6b2fd21d2fdd76a5488468af6c3286869dc045';

if (REACT_APP_API_URL || isLocal) {
  config.baseURL = REACT_APP_API_URL || 'http://localhost:3001';
}

export default config;
