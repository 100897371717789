import axios from 'axios';

import { request } from '../../../helpers/requests';

const FormLibraryService = {
  getLibrary: () => request({
    call: axios.get('/form/library'),
    errMsg: 'Could not get form library',
    hideSuccessToast: true,
  }),
  getFormLibraryTemplates: ({ name, key }) => request({
    call: axios.get(`/form/library/${key}`),
    errMsg: `Could not get form library template ${name}`,
    hideSuccessToast: true,
  }),
};

export default FormLibraryService;
