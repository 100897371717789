import React, { useCallback, useMemo } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Buckets } from 'ontraccr-common';

import {
  getProjectGroupNotes,
  addProjectGroupNote,
  updateProjectGroup,
} from '../projects/state/projects.actions';
import {
  addBucketNote,
  getBucketNotes,
  updateBucket,
} from './state/buckets.actions';
import Notes from '../notes/Notes';
import ProjectList from '../common/projects/ProjectList';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import { getIdMap, isNullOrUndefined } from '../helpers/helpers';
import FilteredFormList from '../forms/FilteredFormList';
import BucketDetailView from './BucketDetailView';
import { bucketLinkTypeToDisplayName, formatBucketPayload, getMaxAllowed } from './bucketHelpers';
import BucketList from './BucketList';

const { TabPane } = Tabs;

const typeToActionMap = {
  ProjectGroups: {
    getNotes: getProjectGroupNotes,
    addNote: addProjectGroupNote,
    updateItem: updateProjectGroup,
  },
  Buckets: {
    getNotes: getBucketNotes,
    addNote: addBucketNote,
    updateItem: updateBucket,
  },
};

export default function BucketAddView({
  formProps = {},
  formRef,
  isAdd,
  hasWritePerms,
  type,
  bucketTemplate,
  errors,
  customFields,
}) {
  const { t } = useTranslation();
  const {
    id,
    editing = false,
  } = formProps;

  const {
    id: templateId,
    upstreamTypes,
    downstreamTypes,
  } = bucketTemplate;

  const isDisplay = !(isAdd || editing);
  const dispatch = useDispatch();

  const projectGroups = useSelector((state) => state.projects.projectGroups);
  const buckets = useSelector((state) => state.buckets.buckets);
  const projectGroupNotes = useSelector((state) => state.projects.projectGroupsNotes);
  const bucketNotes = useSelector((state) => state.buckets.bucketNotes);
  const projects = useSelector((state) => state.projects.projects);
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const bucketTemplateMap = useMemo(() => getIdMap(bucketTemplates), [bucketTemplates]);
  const bucketMap = useMemo(() => getIdMap(buckets), [buckets]);

  const {
    relevantDataSource,
    relevantNotes,
    relevantName,
  } = useMemo(() => {
    switch (type) {
      case 'ProjectGroups':
        return {
          relevantDataSource: projectGroups,
          relevantNotes: projectGroupNotes,
          relevantName: 'Group',
          relevantProjectDownstreamKey: 'downstreamIds',
        };
      case 'Buckets':
        return {
          relevantDataSource: buckets,
          relevantNotes: bucketNotes,
          relevantName: null,
        };
      default:
        return {
          relevantDataSource: [],
          relevantNotes: {},
          relevantName: '',
        };
    }
  }, [
    type,
    buckets,
    projectGroups,
    bucketNotes,
    projectGroupNotes,
  ]);

  const {
    name,
    description,
    bucketTemplateId,
    upstreamIdMap,
    downstreamIds,
    downstreamIdMap,
    selectedProjectIds,
  } = useMemo(() => {
    const relevantItem = relevantDataSource.find((pg) => pg.id === id) || {};
    const relevantItemUpstreamIdMap = {};
    const relevantItemDownstreamIdMap = {};
    const projectIds = [];

    upstreamTypes?.forEach((upstreamType) => {
      relevantItemUpstreamIdMap[upstreamType] = relevantItem[upstreamType] ?? [];
    });

    downstreamTypes?.forEach((downstreamType) => {
      if (downstreamType.includes('project')) {
        projectIds.push(...(relevantItem[downstreamType] ?? []));
      }


      relevantItemDownstreamIdMap[downstreamType] = relevantItem[downstreamType] ?? [];
    });

    // For project groups as they do not have a downstream type
    if (relevantItem.downstreamIds) {
      relevantItemDownstreamIdMap.downstreamIds = relevantItem.downstreamIds;
      projectIds.push(...relevantItem.downstreamIds);
    }

    return {
      ...relevantItem,
      upstreamIdMap: relevantItemUpstreamIdMap,
      downstreamIdMap: relevantItemDownstreamIdMap,
      downstreamIds: Object.values(relevantItemDownstreamIdMap).flat(),
      selectedProjectIds: projectIds
    };
  }, [relevantDataSource, upstreamTypes, downstreamTypes]);

  const detailBody = useMemo(() => (
    <BucketDetailView
      id={id}
      templateId={templateId}
      name={name}
      description={description}
      upstreamTypes={upstreamTypes}
      upstreamIdMap={upstreamIdMap}
      downstreamIds={downstreamIds}
      isDisplay={isDisplay}
      isAdd={isAdd}
      formRef={formRef}
      projectMap={projectMap}
      bucketTemplateMap={bucketTemplateMap}
      errors={errors}
      customFields={customFields}
    />
  ), [
    templateId,
    id,
    name,
    description,
    upstreamTypes,
    upstreamIdMap,
    downstreamIds,
    isDisplay,
    isAdd,
    formRef,
    projectMap,
    bucketTemplateMap,
    errors,
    customFields,
  ]);

  const onAddNew = useCallback((downstreamKey = 'downstreamIds') => async (newChild) => dispatch(
    typeToActionMap[type].updateItem(id, formatBucketPayload({
      [downstreamKey]: [
        ...downstreamIds,
        newChild.id,
      ],
      bucketTemplateId,
    })),
  ), [downstreamIds, type, bucketTemplateId]);

  const onAddExistingDownstream = useCallback((downstreamKey = 'downstreamIds') => async (newDownstreamIds) => dispatch(
    typeToActionMap[type].updateItem(id, formatBucketPayload({
      ...downstreamIdMap,
      [downstreamKey]: [
        ...(downstreamIdMap[downstreamKey] ?? []),
        ...newDownstreamIds,
      ],
      bucketTemplateId,
    })),
  ), [
    type,
    bucketTemplateId,
    downstreamIdMap,
  ]);

  const onRemove = useCallback((downstreamKey = 'downstreamIds') => async (
    relevantId,
    removeType = 'project',
  ) => new Promise((resolve) => {
    let relevantMap = {};
    let relevantType = '';

    switch (removeType) {
      case 'project':
        relevantMap = projectMap;
        relevantType = t('Project');
        break;
      case 'bucket':
        relevantMap = bucketMap;
        relevantType = 'Bucket';
        break;
      default:
        break;
    }

    const relevantEntity = relevantMap[relevantId];
    CustomConfirmModal({
      title: `Remove ${relevantType} ${relevantEntity?.name} from ${relevantName ?? name}`,
      okText: 'Remove',
      cancelText: 'Cancel',
      onCancel() {
        resolve();
      },
      onOk() {
        const newDownstreamIds = (downstreamIdMap[downstreamKey] ?? [])
          .filter((downstreamId) => downstreamId !== relevantId);
        dispatch(typeToActionMap[type].updateItem(id, formatBucketPayload({
          ...downstreamIdMap,
          [downstreamKey]: [
            ...newDownstreamIds,
          ],
          bucketTemplateId,
        })));
        resolve(true);
      },
    });
  }), [
    projectMap,
    name,
    relevantName,
    type,
    bucketTemplateId,
    downstreamIdMap,
    bucketMap,
  ]);

  const projectMaxAllowed = getMaxAllowed({ entityType: type, downstreamTypes });
  const isProjectReadOnly = (
    !hasWritePerms
    || (
      !isNullOrUndefined(projectMaxAllowed)
      && projectMaxAllowed <= selectedProjectIds?.length
    )
  );

  const ProjectTabPane = useCallback(({ downstreamKey }) => (
    <div className="project-group-project-list">
      <ProjectList
        name={name}
        isDisplay={isProjectReadOnly}
        onAddExistingProjects={onAddExistingDownstream(downstreamKey)}
        selectedProjectIds={selectedProjectIds}
        type="ProjectGroup"
        onAddNew={onAddNew(downstreamKey)}
        onRemove={onRemove(downstreamKey)}
        maxAllowed={projectMaxAllowed}
      />
    </div>
  ), [
    name,
    isProjectReadOnly,
    onAddExistingDownstream,
    selectedProjectIds,
    onAddNew,
    onRemove,
    projectMaxAllowed,
  ]);

  if (!isDisplay) return detailBody;

  return (
    <Tabs className="user-add-form">
      <TabPane tab="Details" key="details">
        {detailBody}
      </TabPane>
      { type === 'ProjectGroups' && (
        <TabPane tab={t('Project', { count: 2 })} key="projects">
          <ProjectTabPane />
        </TabPane>
      )}
      { downstreamTypes?.map((downstreamType) => (
        <TabPane
          tab={bucketLinkTypeToDisplayName(t, downstreamType, bucketTemplateMap)}
          key={downstreamType}
        >
          { Buckets.bucketLinkTypeToEntity(downstreamType) === 'projects'
            ? (
              <ProjectTabPane downstreamKey={downstreamType} />
            ) : (
              <BucketList
                currentTemplateId={templateId}
                bucketTemplate={bucketTemplateMap[Buckets.getTemplateIdFromType(downstreamType)]}
                selectedBucketIds={downstreamIdMap[downstreamType] ?? []}
                downstreamKey={downstreamType}
                onAddExistingBucket={onAddExistingDownstream(downstreamType)}
                onRemove={onRemove(downstreamType)}
              />
            )}
        </TabPane>
      ))}
      <TabPane tab="Notes" key="notes">
        <Notes
          id={id}
          notes={relevantNotes}
          addNote={typeToActionMap[type].addNote}
          getNotes={typeToActionMap[type].getNotes}
          headerStyle={{ paddingLeft: 24 }}
          canSend={hasWritePerms}
        />
      </TabPane>
      <TabPane tab="Forms" key="forms">
        <FilteredFormList
          filterId={id}
          filterIds={downstreamIds}
          filterType={type}
          isNotDisplay={false}
          visible
        />
      </TabPane>
    </Tabs>
  );
}

BucketAddView.propTypes = {
  formProps: PropTypes.shape({}),
  formRef: PropTypes.shape({}),
  isAdd: PropTypes.bool,
  hasWritePerms: PropTypes.bool,
  type: PropTypes.string,
  bucketTemplate: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    upstreamTypes: PropTypes.arrayOf(PropTypes.string),
    downstreamTypes: PropTypes.arrayOf(PropTypes.string),
  }),
  errors: PropTypes.shape({}).isRequired,
  customFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

BucketAddView.defaultProps = {
  formProps: {},
  formRef: {},
  isAdd: false,
  hasWritePerms: false,
  type: null,
  bucketTemplate: {},
};
