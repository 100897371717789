import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import NuxTab from './NuxTab';
import NuxCompanySettings from './CompanySettings/NuxCompanySettings';
import NuxCostcodes from './Costcodes/NuxCostcodes';
import NuxProjects from './Projects/NuxProjects';
import NuxUsers from './Users/NuxUsers';
import NuxDocuments from './Documents/NuxDocuments';
import NuxLiveFeed from './LiveFeed/NuxLiveFeed';
import Welcome from './Welcome'

import {
  COMPANY_SETTINGS_TYPES,
  COSTCODE_TYPES,
  PROJECT_TYPES,
  USER_TYPES,
  DOCUMENT_TYPES,
  LIVE_FEED_TYPES,
} from './nux.constants';

import CustomModal from '../common/modals/CustomModal';

import Analytics from '../helpers/Analytics';

const { TabPane, } = Tabs;

const textStyle = {
  fontFamily:'roboto-regular',
  fontSize:16,
  paddingLeft: 20,
  paddingRight: 20,
};

const defaultTitle = 'Getting Started';
const defaultDesc = 'Try these quick exercises to get up and running.';

const getPercentageDone = (nux = new Set(),steps = []) => {
  if(steps.length === 0) return 0;
  const total = steps.length;
  return (100 * steps.reduce((acc,step) => acc + (nux.has(step) ? 1 : 0),0)) / total;
};

export default ({
  history,
  visible,
  onCloseClicked,
  nux = new Set(),
  createNuxEntry,
  acceptToS
}) => {
  const { t } = useTranslation();
  let isFirst = false;
  let acceptedTos = false;
  try {
    const firstLogin = window.sessionStorage.getItem('firstLogin');
    acceptedTos = window.sessionStorage.getItem('acceptedToS') === '1';

    isFirst = firstLogin === 'true' || !acceptedTos;
  } catch(err) {
    console.log('Failed loading first login screen');
  }
  const [isFirstState,setIsFirst] = useState(isFirst);
  const [detailView,setDetailView] = useState();
  const [title,setTitle] = useState(defaultTitle);
  const [description,setDescription] = useState(defaultDesc);
  const [activeTab,setActiveTab] = useState('settings');

  const resetToDefault = () => {
    setDetailView();
    setTitle(defaultTitle);
    setDescription(defaultDesc);
  };

  useEffect(() => {
    if(!visible) {
      resetToDefault();
    }
  }, [ visible ]);

  const getCloseHandler = () => {
    if(isFirstState) return null;
    return detailView ? resetToDefault : onCloseClicked;
  }

  return (
    <CustomModal
      title={isFirstState ? 'Welcome to Ontraccr' : title}
      visible={visible}
      onCloseClicked={getCloseHandler()}
      width={800}
      hideCloseButton={isFirstState}
      CloseIcon={detailView ? ArrowLeftOutlined : CloseOutlined}
    >
      {isFirstState ?
      <Welcome
        acceptedTos={acceptedTos}
        onNext={async () => {
          if(await acceptToS()) {
            setIsFirst(false);
            Analytics.track('GetStarted/AcceptedTOS')
            window.sessionStorage.removeItem('firstLogin');
            window.sessionStorage.setItem('acceptedToS','1');
          }
        }}/> :

      <div style={{ width:'100%', height: 450 }}>
        <p style={textStyle}>{description}</p>
        {detailView ? detailView : <Tabs
            style={{ height:400, width:'100%' }}
            defaultActiveKey='settings'
            activeKey={activeTab}
            tabPosition={'left'}
            tabBarStyle={{marginTop:15, width:180, textAlign:'right'}}
            onChange={(tab) => {
              Analytics.track('NUX/TabSelect',{ NuxTab: tab });
              setActiveTab(tab)
            }}
            tabBarGutter={0}
          >
            <TabPane
              tab={
                <NuxTab
                  title='Company Settings'
                  percent={getPercentageDone(nux,COMPANY_SETTINGS_TYPES)}
                />
              }
              key='settings'
              style={{
                minHeight:400,
                position:'relative',
                padding:'0px 20px'
              }}>
                <NuxCompanySettings
                  setDetailView={setDetailView}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  resetToDefault={resetToDefault}
                  history={history}
                  onCloseClicked={onCloseClicked}
                  nux={nux}
                />
              </TabPane>
            <TabPane
              tab={
                <NuxTab
                  title='Cost Codes'
                  percent={getPercentageDone(nux,COSTCODE_TYPES)}
                />
                }
              key='costcodes'
              style={{
                minHeight:400,
                position:'relative',
                padding:'0px 20px'
              }}>
                <NuxCostcodes
                  setDetailView={setDetailView}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  resetToDefault={resetToDefault}
                  history={history}
                  onCloseClicked={onCloseClicked}
                  nux={nux}
                  createNuxEntry={createNuxEntry}
                />
            </TabPane>

            <TabPane
              tab={
                <NuxTab
                  title={t('Project', { count: 2 })}
                  percent={getPercentageDone(nux,PROJECT_TYPES)}
                />
              }
              key='projects'
              style={{
                minHeight:400,
                position:'relative',
                padding:'0px 20px'
              }}>
                <NuxProjects
                  setDetailView={setDetailView}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  resetToDefault={resetToDefault}
                  history={history}
                  onCloseClicked={onCloseClicked}
                  nux={nux}
                />
            </TabPane>
            <TabPane
              tab={
                <NuxTab
                  title='Users'
                  percent={getPercentageDone(nux,USER_TYPES)}
                />
              }
              key='users'
              style={{
                minHeight:400,
                position:'relative',
                padding:'0px 20px'
              }}>
                <NuxUsers
                   setDetailView={setDetailView}
                   setTitle={setTitle}
                   setDescription={setDescription}
                   resetToDefault={resetToDefault}
                   history={history}
                   onCloseClicked={onCloseClicked}
                   nux={nux}
                />
            </TabPane>
            <TabPane
              tab={
                <NuxTab
                  title='Documents'
                  percent={getPercentageDone(nux,DOCUMENT_TYPES)}
                />
              }
              key='documents'
              style={{
                minHeight:400,
                position:'relative',
                padding:'0px 20px'
              }}>
                <NuxDocuments
                  setDetailView={setDetailView}
                  setTitle={setTitle}
                  setDescription={setDescription}
                  resetToDefault={resetToDefault}
                  history={history}
                  onCloseClicked={onCloseClicked}
                  nux={nux}
                  createNuxEntry={createNuxEntry}
                />
            </TabPane>
            <TabPane
              tab={
                <NuxTab
                  title='Live Feed'
                  percent={getPercentageDone(nux,LIVE_FEED_TYPES)}
                />
              }
              key='liveFeed'
              style={{
                minHeight:400,
                position:'relative',
                padding:'0px 20px'
              }}>
                <NuxLiveFeed
                   setDetailView={setDetailView}
                   setTitle={setTitle}
                   setDescription={setDescription}
                   resetToDefault={resetToDefault}
                   history={history}
                   onCloseClicked={onCloseClicked}
                   nux={nux}
                   createNuxEntry={createNuxEntry}
                />
            </TabPane>
          </Tabs>}
      </div>}
    </CustomModal>
  );
}
