import React from 'react';
import { Checkbox } from 'antd';
import PropTypes from 'prop-types';
import CompanyEditRow from './CompanyEditRow';

export default function CheckRows({
  data,
  onChange = () => null,
  isChecked,
  checkBoxDisabled = () => false,
  getMultiView = () => null,
  getView = () => null,
}) {
  return data.map((datum, index) => {
    const {
      key, title, helpText, multi = false, divider = true, style,
    } = datum;
    return (
      <CompanyEditRow
        key={key}
        title={title}
        helpText={helpText}
        divider={divider}
        style={style}
      >
        {multi
          ? getMultiView()
          : getView(index) || (
          <Checkbox
            onChange={() => onChange(datum)}
            style={{ marginRight: 56 }}
            checked={isChecked(datum)}
            disabled={checkBoxDisabled(datum)}
          />
          )}

      </CompanyEditRow>
    );
  });
}

CheckRows.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    helpText: PropTypes.string,
    multi: PropTypes.bool,
    divider: PropTypes.bool,
    style: PropTypes.shape({}),
  })).isRequired,
  onChange: PropTypes.func,
  isChecked: PropTypes.bool.isRequired,
  checkBoxDisabled: PropTypes.func,
  getMultiView: PropTypes.func,
  getView: PropTypes.func,
};

CheckRows.defaultProps = {
  onChange: () => null,
  checkBoxDisabled: () => false,
  getMultiView: () => null,
  getView: () => null,
};
