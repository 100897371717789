import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { TaskHelpers } from 'ontraccr-common';

import FormResponseFields from '../../forms/FormResponseFields';
import { getIdMap } from '../../helpers/helpers';

function ManualEntryCustomFields({
  initialValues,
  divisionId,
  errors,
  fileMap,
  timeData,
  onChange,
  value,
  project,
  costcode,
}) {
  const isEditData = !Array.isArray(initialValues);
  const customFields = useSelector((state) => state.timecards.customFields);
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const [responses, setResponses] = useState(isEditData ? initialValues : {});

  const ourCustomFields = useMemo(() => {
    const {
      [divisionId]: { fields: sections = [] } = {},
    } = customFields;

    return sections;
  }, [customFields, divisionId]);

  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const divisionList = useMemo(() => [divisionId], [divisionId]);

  useEffect(() => {
    const newTimeData = {
      total_hours: {
        value: TaskHelpers.convertMillisecondsToHours(timeData?.totalTime),
      },
      work_hours: {
        value: TaskHelpers.convertMillisecondsToHours(timeData?.workTime),
      },
      break_hours: {
        value: TaskHelpers.convertMillisecondsToHours(timeData?.breakTime),
      },
      project: {
        value: project,
      },
      costcode: {
        value: costcode,
      },
      costcode_code: {
        value: costcodeMap[costcode]?.code,
      },
      costcode_name: {
        value: costcodeMap[costcode]?.name,
      },
    };

    setResponses((prevResponses) => ({
      ...prevResponses,
      ...newTimeData,
    }));
  }, [timeData, project, costcode, costcodeMap]);

  useEffect(() => {
    onChange(responses);
  }, [responses]);

  if (!ourCustomFields || ourCustomFields.length === 0) return null;
  return (
    <div style={{ display: 'flex', flex: 1, position: 'relative' }}>
      <FormResponseFields
        errors={errors}
        sections={ourCustomFields}
        visible
        responses={{ ...value, ...responses }}
        onResponsesChanged={setResponses}
        fileMap={fileMap}
        initialData={isEditData ? null : { sections: initialValues }}
        divisions={divisionList}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'visible',
          padding: 0,
        }}
      />
    </div>
  );
}

ManualEntryCustomFields.propTypes = {
  value: PropTypes.objectOf({
    fieldId: PropTypes.string,
  }),
  initialValues: PropTypes.arrayOf({
    fieldId: PropTypes.string,
  }),
  errors: PropTypes.objectOf({
    fieldId: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  divisionId: PropTypes.string,
  fileMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  timeData: PropTypes.shape({
    totalTime: PropTypes.number,
    workTime: PropTypes.number,
    breakTime: PropTypes.number,
  }),
  project: PropTypes.string,
  costcode: PropTypes.string,
};

ManualEntryCustomFields.defaultProps = {
  value: {},
  initialValues: [],
  errors: {},
  divisionId: null,
  timeData: null,
  project: null,
  costcode: null,
};

export default ManualEntryCustomFields;
