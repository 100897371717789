import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Checkbox } from 'antd';
import CustomModal from '../common/modals/CustomModal';

import PhotoReel from '../common/photos/PhotoReel';
import FileUpload from '../common/files/FileUpload';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import colors from '../constants/Colors';

export default function FileAddView({
  visible,
  onUploadEnd,
  onAddFile,
  onFileRemove,
  onSave,
  files = [],
  showNotifyCheckbox = true,
  customValidator,
}) {
  const [loading, setLoading] = useState(false);
  const [notify, setNotify] = useState(false);

  const toggleNotify = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setNotify(checked);
  }, []);

  useEffect(() => {
    if (visible && showNotifyCheckbox) {
      const storedNotify = window.localStorage.getItem('notifyUsers');
      setNotify(storedNotify === 'true');
    }
  }, [visible, showNotifyCheckbox]);

  const save = useCallback(async () => {
    setLoading(true);
    if (showNotifyCheckbox) window.localStorage.setItem('notifyUsers', notify);
    await onSave(notify);
    setLoading(false);
  }, [onSave, notify, showNotifyCheckbox]);

  return (
    <CustomModal
      title="Upload Files"
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
      width={800}
    >
      <div
        style={{
          height: '50vh',
          margin: '20px 0px',
        }}
      >
        <div style={{ height: '80%', width: '100%', position: 'relative' }}>
          <FileUpload
            addFile={onAddFile}
            customValidator={customValidator}
          />
        </div>
        <div className="files-file-reel-container">
          <PhotoReel
            files={files}
            shadow
            small
            onDelete={onFileRemove}
          />
        </div>
      </div>
      <Row justify="center" gutter={12} style={{ marginBottom: 10 }}>
        <Col span={4} />
        <Col>
          <OnTraccrButton
            type="cancel"
            title="Cancel"
            roundStyle
            onClick={onUploadEnd}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title="Save"
            roundStyle
            onClick={save}
            loading={loading}
          />
        </Col>
        {showNotifyCheckbox
          && (
          <Col span={4}>
            <Checkbox
              checked={notify}
              onChange={toggleNotify}
              style={{ marginBottom: 10 }}
            >
              Notify Users
            </Checkbox>
          </Col>
          )}
      </Row>
    </CustomModal>
  );
}

FileAddView.propTypes = {
  visible: PropTypes.bool.isRequired,
  onUploadEnd: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onFileRemove: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  files: PropTypes.arrayOf(PropTypes.object).isRequired,
  showNotifyCheckbox: PropTypes.bool,
  customValidator: PropTypes.func,
};

FileAddView.defaultProps = {
  showNotifyCheckbox: true,
  customValidator: undefined,
};
