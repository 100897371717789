import sortByString from './helpers';

export default {};
export const updateArray = ({
  oldArray,
  id,
  newData,
}) => {
  const newArray = [...oldArray];
  return newArray.map((item) => {
    if (item.id !== id) return item;
    return {
      ...item,
      ...newData,
    };
  }).sort(sortByString('name'));
};

export const updateObject = ({
  oldObject,
  id,
  newData,
}) => {
  const newObject = { ...oldObject };
  newObject[id] = {
    ...oldObject[id],
    ...newData,
  };
  return newObject;
};

export const removeFromObject = ({
  oldObject,
  id,
}) => {
  const newObject = { ...oldObject };
  delete newObject[id];
  return newObject;
};
