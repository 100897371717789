import React, { useCallback } from "react";
import BorderlessButton from "../common/buttons/BorderlessButton";
import { ShareAltOutlined, GlobalOutlined } from "@ant-design/icons";

const MaterialsShareButton = ({ record, onShare }) => {

  const onClick = useCallback((e) => {
    e.stopPropagation();
    onShare(record);
  }, [record, onShare])

  return (
    <BorderlessButton 
      style={{ padding: 0, margin: '0px 10px', width: 39}}
      onClick={onClick}
      iconNode={record.isPublic
        ? <GlobalOutlined style={{ margin: 0 }}/>
        : <ShareAltOutlined style={{ margin: 0 }}/>
      }
    />
  )
}

export default MaterialsShareButton;