import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Tabs } from 'antd';

import BoardLinkView from '../boards/BoardLinkView';

import MaterialInfo from './MaterialInfo';
import MaterialUpload from './MaterialUpload/MaterialUpload';
import MaterialUpdateHistory from './MaterialUpdateHistory';

const { TabPane } = Tabs;

export default function MaterialDrawerTabContainer({
  item,
  isNotDisplay,
  onDivisionChange,
  locations = [],
  onLocationsChanged,
  price,
  totalQuantity,
  totalQuantityAllocated,
  relevantCC,
  onTabChange,
  activeTab,
  uploadFile,
  onFileChange,
  currentStep,
  onMassUploadDataChanged,
  visible,
}) {
  const cardLinks = useSelector(state => state.boards.cardLinks);
  const ourLinks = useMemo(() => {
    if (!item || !item.id || isNotDisplay) return [];
    return cardLinks.filter((cardLink) => (
      cardLink.materialId === item.id
    ));
  },[item,isNotDisplay,cardLinks]);

  const hasTabs = !isNotDisplay && ourLinks.length > 0;

  const infoView = (
    <MaterialInfo
      isNotDisplay={isNotDisplay}
      item={item}
      onDivisionChange={onDivisionChange}
      locations={locations}
      onLocationsChanged={onLocationsChanged}
      totalQuantity={totalQuantity}
      totalQuantityAllocated={totalQuantityAllocated}
      price={price}
      relevantCC={relevantCC}
      hasTabs={hasTabs}
    />
  );


  if (!isNotDisplay || (item && item.id)) { // If we have an id we're editing.
    const {
      id: itemId,
      name: itemName,
    } = item ?? {};
    return (
      <Tabs tabBarStyle={{ paddingLeft: 24, marginBottom: 0 }}>
        <TabPane tab='Details' key='details'>
          {infoView}
        </TabPane>
        <TabPane tab='Timeline' key='timeline'>
          <MaterialUpdateHistory materialId={itemId} name={itemName}/>
        </TabPane>
        {ourLinks.length > 0
        && <TabPane tab='Cards' key='cards'>
          <BoardLinkView links={ourLinks} linkType='materialId' linkId={itemId} style={{ padding: '0px 24px', marginTop: 24 }}/>
        </TabPane>}
      </Tabs>
    )
  }
  return (
    <Tabs
      tabPosition='left'
      tabBarStyle={{ paddingTop: 24 }}
      onChange={onTabChange}
      activeKey={activeTab}
    >
      <TabPane tab='Create' key='create' className='material-tab-pane'>
        {infoView}
      </TabPane>
      <TabPane tab='Upload' key='upload' className='material-tab-pane'>
        <MaterialUpload
          uploadFile={uploadFile}
          onFileChange={onFileChange}
          currentStep={currentStep}
          onMassUploadDataChanged={onMassUploadDataChanged}
          onDivisionChange={onDivisionChange}
          visible={visible}
        />
      </TabPane>
    </Tabs>
  );
};
