import React from 'react';
import { DateTime } from 'luxon';

const getDate = (ts, format = DateTime.DATETIME_MED ) => DateTime.fromMillis(ts).toLocaleString(format);

function TimelineStepText({
  title,
  color,
  timestamp,
  text,
  format,
  note,
}) {
  const displayText = note ? `${text} with a note: ${note}` : text;

  return (
    <div>
      <div style={{ fontWeight: 500, color }}>
        {title} - {getDate(timestamp, format)}
      </div>
      <div style={{ marginTop: 5, fontFamily: 'roboto-light', fontSize: 12 }}>
        {displayText}
      </div>
    </div>
  );
}

export default TimelineStepText;
