import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import { DateTime } from 'luxon';

import ScheduleDay from './ScheduleDay';
import DailyScheduleHeader from './DailySchedule/DailyScheduleHeader';

export default function ScheduleDayWithHeader({
  userId,
  day,
  userShifts,
  onShiftCreate,
  newShift,
  userAllDay,
  onNewShiftChange,
  onShiftSelect,
  editShift,
  onShiftEdit,
  scrollTop,
  containerY,
}) {
  const userDivs = useSelector((state) => state.users.userDivisions);

  const newShiftUserSet = useMemo(() => {
    if (!newShift) return new Set();
    const { users: shiftUsers = [] } = newShift;
    return new Set(shiftUsers);
  }, [newShift]);

  const editShiftUserSet = useMemo(() => {
    if (!editShift) return new Set();
    const { users: shiftUsers = [] } = editShift;
    return new Set(shiftUsers);
  }, [editShift]);

  const onNewUserShift = useCallback((userId) => (userShift) => {
    const fullShift = { ...userShift, users: [userId] };
    const {
      [userId]: divisions = [],
    } = userDivs;
    if (divisions.length > 0) {
      const [divId] = divisions;
      fullShift.divisionId = divId;
    }
    onNewShiftChange(fullShift);
  }, [day, onShiftCreate, onNewShiftChange, userDivs]);

  const onAllDayClick = useCallback((userId) => (e) => {
    e.stopPropagation();
    const {
      [userId]: divisions = [],
    } = userDivs;
    const startTime = day.startOf('day').toMillis();
    const endTime = day.endOf('day').toMillis();
    const allDayShift = {
      id: DateTime.local().toMillis(),
      day,
      startTime,
      endTime,
      users: [userId],
      divisionId: divisions.length > 0 ? divisions[0] : null,
    };
    onShiftCreate(allDayShift);
    onNewShiftChange(allDayShift);
  }, [day, onShiftCreate, onNewShiftChange, userDivs]);

  return (
    <>
      <ScheduleDay
        key="daily"
        day={day}
        shifts={userShifts}
        onShiftCreate={onShiftCreate}
        newShift={newShiftUserSet.has(userId) ? newShift : null}
        onNewShiftChange={onNewUserShift(userId)}
        onShiftSelect={onShiftSelect}
        editShift={editShiftUserSet.has(userId) ? editShift : null}
        onShiftEdit={onShiftEdit}
        scrollTop={scrollTop}
        containerY={containerY}
      />
      <DailyScheduleHeader
        onClick={onAllDayClick(userId)}
        shifts={userAllDay}
        onShiftSelect={onShiftSelect}
        onShiftEdit={onShiftEdit}
        style={{
          top: 0,
          left: 1,
          right: 1,
        }}
      />
    </>
  );
}
