import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, Select } from 'antd';

import {
  fields as auroraFields,
} from './auroraSolar.constants';

function AuroraSolarFieldMapping({
  targetFields,
  value: mappings,
  onChange: onMappingsChanged,
}) {
  const columns = useMemo(() => [
    {
      dataIndex: 'auroraSolarField',
      title: 'Aurora Solar Field',
      width: 300,
      render: (_, record) => {
        const { type: fieldType, value } = record;

        const {
          [fieldType]: options = [],
        } = auroraFields;

        const {
          [value]: mappingValue,
        } = mappings;
        return (
          <Select
            options={options}
            value={mappingValue}
            showSearch
            allowClear
            onChange={(fieldKey) => {
              if (!onMappingsChanged) return;
              const newData = {
                ...mappings,
                [value]: fieldKey,
              };
              if (!fieldKey) delete newData[value];
              onMappingsChanged(newData);
            }}
          />
        );
      },
    }, {
      dataIndex: 'targetField',
      title: 'Card Field',
      width: 300,
      render: (_, record) => {
        const className = record.value === 'cardTitle'
          ? 'form-required-field'
          : null;
        return <span className={className}>{record.label}</span>;
      },
    },
  ], [targetFields, mappings, onMappingsChanged]);

  return (
    <Table
      dataSource={targetFields}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="value"
    />
  );
}

AuroraSolarFieldMapping.propTypes = {
  targetFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })),
  value: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
};

AuroraSolarFieldMapping.defaultProps = {
  targetFields: [],
  value: {},
  onChange: null,
};

export default AuroraSolarFieldMapping;
