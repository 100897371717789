import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import {
  Drawer,
  List,
  Row,
} from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { applyScheduleOfValueTemplate, getScheduleOfValueTemplates } from '../state/projects.actions';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export default function ScheduleOfValuesTemplateImport({
  visible,
  onClose,
  projectId,
}) {
  const dispatch = useDispatch();
  const templates = useSelector((state) => state.projects.scheduleOfValueTemplates);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  const filteredTemplates = useMemo(() => (
    templates.filter((template) => selectedDivisions.has(template.divisionId))
  ), [templates, selectedDivisions]);

  const applyTemplate = useCallback(async (templateId) => {
    const result = await dispatch(applyScheduleOfValueTemplate(projectId, templateId));

    if (result) {
      onClose();
    }
  }, [projectId]);

  const renderItem = useCallback((item) => {
    const {
      id,
      name,
      description,
    } = item;

    return (
      <List.Item style={{ position: 'relative', width: '100%', borderBottom: 'none' }}>
        <Row style={{ width: '100%' }}>
          <Row className="form-field-type-title">
            {name}
          </Row>
          <Row className="form-field-type-description">
            {description}
          </Row>
        </Row>
        <OnTraccrButton
          title="Apply"
          style={{ pointerEvents: 'auto' }}
          onClick={() => applyTemplate(id)}
        />
      </List.Item>
    );
  }, [applyTemplate]);

  useEffect(() => {
    dispatch(getScheduleOfValueTemplates());
  }, []);

  return (
    <Drawer
      visible={visible}
      width={600}
      onClose={onClose}
      title="Import Schedule of Value Template"
    >
      <List
        style={{ marginLeft: 15 }}
        dataSource={filteredTemplates}
        renderItem={renderItem}
      />
    </Drawer>
  );
}

ScheduleOfValuesTemplateImport.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
};
