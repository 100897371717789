import axios from 'axios';

import { request, archive } from '../../../helpers/requests';

export default {
  create: (payload) => request({
    call: axios.post('/vendors', payload),
    errMsg: 'Could not create vendor',
  }),
  get: () => request({
    call: axios.get('/vendors'),
    errMsg: 'Could not get vendors',
    hideSuccessToast: true,
  }),
  update: (id, payload) => request({
    call: axios.put(`/vendors/${id}`, payload),
    errMsg: 'Could not update vendor',
  }),
  delete: (id) => request({
    call: axios.delete(`/vendors/${id}`),
    errMsg: 'Could not delete vendor',
  }),
  archive: (id, active) => archive({
    id, active, type: 'vendors',
  }),
  getNotes: (id) => request({
    call: axios.get(`/vendors/${id}/notes`),
    errMsg: 'Could not get vendor notes',
    hideSuccessToast: true,
  }),
  addNote: (id, note) => request({
    call: axios.post(`/vendors/${id}/note`, { note }),
    errMsg: 'Could not create vendor note',
  }),
  getVendorLabels: () => request({
    call: axios.get('/vendors/labels'),
    errMsg: 'Could not get vendor labels',
    hideSuccessToast: true,
  }),
};
