import sortByString from '../helpers/helpers';

/**
 * Returns true if the range is valid, false otherwise
 * @param {number[]} - tuple containing the startTime and endTime
 * @returns {boolean}
 */
export const isValidRange = ([startTime, endTime]) => typeof startTime === 'number'
  && typeof endTime === 'number' && startTime < endTime;

/**
 * Constructs antd Tree Select Options
 * for PO or Sub-Contract forms which are currently active and not deleted
 * @param {string} type // Form type. Currently either PO or Sub-Contract
 * @param {string} projectId
 * @param {array} assignedFormTemplates
 * @param {object} projectMap
 * @param {object} t // i18n function
 * @returns {object[]}
 */
export const getPayableFormOptions = ({
  type: targetFormType,
  projectId: targetProjectId,
  assignedFormTemplates = [],
  projectMap = {},
  t,
}) => {
  const globalOptions = [];
  const projectOptions = {};
  assignedFormTemplates
    .forEach(({
      id: formTemplateId, name: formName, projectId, active, notDeleted, type,
    }) => {
      if (!active
          || !notDeleted
          || type !== targetFormType
          || (targetProjectId && projectId && projectId !== targetProjectId)
      ) return;
      const { name: projectName } = projectMap[projectId] || {};
      if (projectId) {
        const newOption = {
          isLeaf: true,
          value: formTemplateId,
          title: formName,
        };
        if (!(projectId in projectOptions)) {
          projectOptions[projectId] = {
            selectable: false,
            title: projectName,
            value: projectId,
            children: [newOption],
          };
        } else {
          projectOptions[projectId].children.push(newOption);
        }
      } else {
        globalOptions.push({
          isLeaf: true,
          value: formTemplateId,
          title: formName,
        });
      }
    });
  const options = [];
  const projectOptionsArr = Object.values(projectOptions);
  projectOptionsArr.sort(sortByString('title'));
  // Conditionally add global options, if there is at least one global form option:
  if (globalOptions.length) {
    options.push({
      selectable: false,
      title: 'Global',
      value: 'global',
      children: globalOptions,
    });
  }
  // Conditionally add project options, if there is at least one project form option:
  if (projectOptionsArr.length) {
    options.push({
      selectable: false,
      title: t('Project'),
      value: 'project',
      children: projectOptionsArr,
    });
  }
  return options;
};
