import React from 'react';
import {
  Row, Col, Button, Select,
  Tooltip,
} from 'antd';
import {
  ZoomOutOutlined,
  ZoomInOutlined,
  RotateRightOutlined,
  UndoOutlined,
  SaveOutlined,
  RotateLeftOutlined,
} from '@ant-design/icons';
import PDFAnnotationButton from './PDFAnnotationButton';

export const zoomLevels = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4, 6];

const changeZoom = (zoom, direction) => {
  const currIndex = zoomLevels.findIndex((item) => item === zoom);
  if (currIndex < 0) return 0.75;
  return zoomLevels[currIndex + direction];
}

export default function PDFZoomControls({
  zoom,
  onZoomChanged,
  onRotateLeft,
  onRotateRight,
  annotationMode,
  onAnnotationModeChanged,
  editable,
  onUndo,
  onSave,
  className,
}) {
  return zoom ? (
    <Row justify="center" className={className ? `${className}-pdf-zoom-controls-container` : 'pdf-zoom-controls-container'} gutter={20}>
      {onRotateLeft && (
        <Tooltip placement="left" title="Rotate Left">
          <Button
            className="pdf-control-button"
            style={{ position: 'absolute', left: '20%' }}
            icon={
              <RotateLeftOutlined />
            }
            onClick={onRotateLeft}
          />
        </Tooltip>
      )}
      {onRotateRight && (
        <Tooltip placement="right" title="Rotate Right">
          <Button
            className="pdf-control-button"
            style={{ position: 'absolute', left: '25%' }}
            icon={
              <RotateRightOutlined />
            }
            onClick={onRotateRight}
          />
        </Tooltip>
      )}
      <Col>
        <Button
          className={zoom > 0.1 ? 'pdf-control-button' : 'pdf-control-button-disabled'}
          icon={
            <ZoomOutOutlined />
          }
          onClick={() => onZoomChanged(changeZoom(zoom, -1))}
        />
      </Col>
      <Col>
        <Select className="pdf-zoom-controls" value={zoom} onChange={(val) => onZoomChanged(val)}>
          {zoomLevels.map((level) => (
            <Select.Option
              key={level}
              value={level}
            >
              {`${parseInt(level * 100, 10)} %`}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <Button
          className={zoom < zoomLevels[zoomLevels.length - 1] ? 'pdf-control-button' : 'pdf-control-button-disabled'}
          icon={
            <ZoomInOutlined />
          }
          onClick={() => onZoomChanged(changeZoom(zoom, 1))}
        />
      </Col>
      <div style={{ position: 'absolute', right: '25%' }}>
        { onUndo && (
          <Tooltip placement="bottom" title="Undo">
            <Button
              className="pdf-control-button"
              icon={
                <UndoOutlined />
              }
              onClick={onUndo}
            />
          </Tooltip>
        )}
        { editable && (
          <>
            <PDFAnnotationButton
              annotationType="text"
              annotationMode={annotationMode}
              onAnnotationModeChanged={onAnnotationModeChanged}
            />
            <PDFAnnotationButton
              annotationType="pencil"
              annotationMode={annotationMode}
              onAnnotationModeChanged={onAnnotationModeChanged}
            />
            <PDFAnnotationButton
              annotationType="highlight"
              annotationMode={annotationMode}
              onAnnotationModeChanged={onAnnotationModeChanged}
            />
          </>
        )}
        {onSave && (
          <Tooltip placement="bottom" title="Save">
            <Button
              className="pdf-control-button"
              icon={<SaveOutlined />}
              onClick={onSave}
            />
          </Tooltip>
        )}
      </div>
    </Row>
  ) : null;
}
