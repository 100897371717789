import React from 'react';
import { Row, Col } from 'antd';
import {
  ArrowRightOutlined,
} from '@ant-design/icons';

import {
  PROGRESS_UPDATE_TYPE_INVOICE_ADD,
  PROGRESS_UPDATE_TYPE_INVOICE_DELETE,
  PROGRESS_UPDATE_TYPE_INVOICE_UPDATE,
  PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD,
} from './historyConstants';

const parseValue = (val, type) => {
  if (type === 'currency'
    || type === PROGRESS_UPDATE_TYPE_INVOICE_ADD
    || type === PROGRESS_UPDATE_TYPE_INVOICE_UPDATE
    || type === PROGRESS_UPDATE_TYPE_INVOICE_DELETE
    || type === PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD
  ) {
    return `$ ${val.toFixed(2)}`;
  } else if (type === 'percent') {
    return `${(val * 100).toFixed(2)} %`;
  }
  return val;
};

export default function HistoryEntryDiff({
  value,
  diff,
  lastValue,
  type,
  field
}) {
  let color = 'black';
  let prefix = '';

  if (type !== 'text') {
    prefix = diff >= 0 ? '+' : '';
    if (diff > 0) {
      color = 'forestgreen';
    } else if (diff < 0) {
      color = 'red';
    }
  }

  return (
    <Row justify='center' align='middle' gutter={10}>
    { field !== 'Notes' &&
      <>
        <Col style={{ color: '#9e9b9b' }}>
          {parseValue(lastValue, type)}
        </Col>
        <Col style={{ width: 40, color }}>
          <div className='progress-diff'>
            {prefix + (parseValue(diff, type) || '')}
          </div>
          <Row justify='center' align='middle'>
            <ArrowRightOutlined style={{ fontSize: 10 }}/>
          </Row>
        </Col>
      </>
    }
    <Col style={{ fontFamily: 'roboto-bold' }}>
      {parseValue(value, type)}
    </Col>
  </Row>
  );
}
