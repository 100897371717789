import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row } from 'antd';

import CompanySettingsCard from '../CompanySettingsCard';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal'
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import colors from '../../constants/Colors';

import {
  currencyFormatter,
  currencyParser,
} from '../../helpers/inputParsers';
import {
  createBillingRate,
  deleteBillingRate,
  updateBillingRate,
} from '../../billingRates/state/billingRates.actions';

const onHeaderCell = () => ({
  style:{
    backgroundColor:'white'
  }
});

const promptForDelete = ({ onDelete, name, id }) => {
  CustomConfirmModal({
    title: `Delete ${name} rate?`,
    okText: 'Delete',
    cancelText: 'Cancel',
    onOk:() => onDelete(id),
  });
};

export default ({
  visible,
}) => {
  const dispatch = useDispatch();
  const rates = useSelector(state => state.billingRates);
  const ratesList = useMemo(() => [{}].concat(Object.values(rates)),[rates]);
  const rateNames = useMemo(() => new Set(Object.values(rates).map(({ name: rateName }) => rateName)),[rates]);

  const [name,setName] = useState();
  const [rate,setRate] = useState();
  const [editData,setEditData] = useState({});

  const onNameChange = useCallback((editId) => (e) => {
    const  {
      target:{
        value
      } = {},
    } = e;
    if (editId) {
      const newEditData = {...editData};
      newEditData[editId].name = value;
      setEditData(newEditData);
    } else {
      setName(value);
    }
  },[editData]);

  const onRateChange = useCallback((editId) => (value) => {
    if (editId) {
      const newEditData = {...editData};
      newEditData[editId].rate = value;
      setEditData(newEditData);
    } else {
      setRate(value);
    }
  },[editData]);

  const onSave = useCallback(async () => {
    if(await dispatch(createBillingRate({ name,  rate }))) {
      setName();
      setRate();
    }
  },[name,rate,dispatch]);

  const onSaveEdit = useCallback(async (editId) => {
    const {
      [editId]: {
        name: editName,
        rate: editRate,
      } = {},
    } = editData;
    if(await dispatch(updateBillingRate(editId, {
      name: editName,
      rate: editRate,
    }))) {
      const newEditData = {...editData};
      delete newEditData[editId];
      setEditData(newEditData);
    }
  },[editData, dispatch]);

  const onDelete = useCallback((id) => {
    dispatch(deleteBillingRate(id));
  },[dispatch,name]);

  const onDeleteClicked = useCallback(({ id }) => {
    const {
      [id]: {
        name: rateName
      } = {},
    } = rates;
    promptForDelete({ onDelete, name: rateName, id })
  },[onDelete, rates]);

  const onEditStarted = useCallback((editId) => {
    const newEditData = {...editData};
    newEditData[editId] = {...rates[editId] };
    setEditData(newEditData);
  },[editData, rates]);

  const onEditCancel = useCallback((editId) => {
    const newEditData = {...editData};
    delete newEditData[editId];
    setEditData(newEditData);
  },[editData]);

  useEffect(() => {
    if (!visible) setEditData({});
  },[visible]);

  const columns = useMemo(() => [{
    title: 'Name',
    dataIndex: 'name',
    onHeaderCell,
    render:(text,record,index) => {
      const isEdit = record.id in editData;
      const {
        [record.id]: {
          name: editName,
        } = {},
      } = editData;
      if(index === 0 || isEdit) return (
        <Row align='middle'>
          <OnTraccrTextInput
            value={isEdit ? editName : name}
            onChange={onNameChange(record.id)}
            style={
              rateNames.has(name) ? { borderColor: 'red' } : {}
            }
          />
        </Row>
      )
      return text;
    }
  },
  {
    title: 'Rate',
    dataIndex: 'rate',
    onHeaderCell,
    render:(text,record,index) => {
      const isEdit = record.id in editData;
      const {
        [record.id]: {
          rate: editRate,
        } = {},
      } = editData;
      if(index === 0 || isEdit) return (
        <Row align='middle'>
          <OnTraccrNumberInput 
            style={{ height: 32 }}
            value={isEdit ? editRate : rate}
            onChange={onRateChange(record.id)}
            formatter={currencyFormatter}
            parser={currencyParser}
            precision={2}
            min={0}
          />
        </Row>
      )
      return text ? `$${text.toFixed(2)}` : text;
    }
  },
  {
    dataIndex:'edit',
    align:'edit',
    onHeaderCell,
    width: 60,
    render:(_,record,index) => {
      const shouldHide = index === 0;
      let icon;
      let onClick;
      if (!shouldHide) {
        const isEdit = record.id in editData;
        icon = isEdit ? 'close' : 'edit';
        onClick = () => {
          isEdit
          ? onEditCancel(record.id)
          : onEditStarted(record.id);
        }
      }
      return (
        <BorderlessButton
          title=''
          icon={icon}
          color={colors.ONTRACCR_BLACK}
          onClick={onClick}
          style={{
            paddingRight:8, 
            paddingLeft:0,
            visibility:shouldHide ? 'hidden' : 'visible'
          }}
        />
      )
    }
  },
  {
    dataIndex:'action',
    key:'action',
    align:'center',
    width: 60,
    onHeaderCell,
    render:(_,record,index) => {
      const isFirst = index === 0;
      const shouldHide = (isFirst && (!name || !rate || rateNames.has(name)));
      let icon;
      let color = colors.ONTRACCR_BLACK;
      let onClick = () => onSave();
      if (!isFirst) {
        const isEdit = record.id in editData;
        icon = isEdit ? 'check' : 'delete';
        onClick = () => {
          isEdit
          ? onSaveEdit(record.id)
          : onDeleteClicked(record);
        };
        if (!isEdit) color = colors.ONTRACCR_RED;
      }
      return (
        <BorderlessButton
          title={isFirst ? 'Save' : ''}
          icon={icon}
          color={color}
          onClick={onClick}
          style={{
            paddingRight:8, 
            paddingLeft:0,
            visibility:shouldHide ? 'hidden' : 'visible'
          }}
        />
      )
    }
  }],[onSave, onDelete, onNameChange, onRateChange, onEditCancel, onEditStarted, rate, name, rateNames, editData]);
  return (
    <CompanySettingsCard
      title='Rates'
    >
      <Table
        columns={columns}
        dataSource={ratesList}
        pagination={false}
        size='small'
        scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
      />
    </CompanySettingsCard>
  );
}
