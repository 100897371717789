import { decorateFormWithFiles } from '../../forms/formHelpers';
import {
  ADD_BUCKET_NOTE,
  CREATE_BUCKET,
  CREATE_BUCKET_TEMPLATE,
  DELETE_BUCKET,
  DELETE_BUCKET_TEMPLATE,
  GET_BUCKETS,
  GET_BUCKET_NOTES,
  GET_BUCKET_TEMPLATES,
  UPDATE_BUCKET,
  UPDATE_BUCKET_TEMPLATE,
  GET_BUCKET_CUSTOM_DATA,
} from '../../state/actionTypes';
import
BucketsService from './buckets.service';

export const getBucketTemplates = () => async (dispatch) => {
  const { data } = await BucketsService.getTemplates();
  if (!data) return false;
  dispatch({
    type: GET_BUCKET_TEMPLATES,
    payload: data,
  });
  return true;
};

export const createBucketTemplate = (payload) => async (dispatch) => {
  const { data } = await BucketsService.createTemplate(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_BUCKET_TEMPLATE,
    payload: data,
  });
  return true;
};

export const updateBucketTemplate = (id, payload) => async (dispatch) => {
  const { data } = await BucketsService.updateTemplate(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_BUCKET_TEMPLATE,
    payload: data,
  });
  return true;
};

export const deleteBucketTemplate = (id) => async (dispatch) => {
  const { data } = await BucketsService.deleteTemplate(id);
  if (!data) return false;
  dispatch({
    type: DELETE_BUCKET_TEMPLATE,
    payload: data,
  });
  return true;
};

export const getBuckets = (query = {}) => async (dispatch) => {
  const { data } = await BucketsService.getBuckets(query);
  if (!data) return false;
  dispatch({
    type: GET_BUCKETS,
    payload: {
      data,
      query,
    },
  });
  return true;
};

export const createBucket = (payload) => async (dispatch) => {
  const { data } = await BucketsService.createBucket(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_BUCKET,
    payload: data,
  });
  return true;
};

export const updateBucket = (id, payload) => async (dispatch) => {
  const { data } = await BucketsService.updateBucket(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_BUCKET,
    payload: data,
  });
  return true;
};

export const deleteBucket = (id) => async (dispatch) => {
  const { data } = await BucketsService.deleteBucket(id);
  if (!data) return false;
  dispatch({
    type: DELETE_BUCKET,
    payload: data,
  });
  return true;
};

export const getBucketNotes = (id) => async (dispatch) => {
  const { data } = await BucketsService.getBucketNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_BUCKET_NOTES,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const addBucketNote = ({ id, text }) => async (dispatch) => {
  const { data } = await BucketsService.addBucketNote(id, text);
  if (!data) return false;
  dispatch({
    type: ADD_BUCKET_NOTE,
    payload: data,
  });
  return true;
};

export const getBucketCustomData = (id) => async (dispatch) => {
  if (!id) return dispatch({ type: GET_BUCKET_CUSTOM_DATA, payload: { data: [] } });

  const { data } = await BucketsService.getBucketCustomData(id);
  await decorateFormWithFiles({ fileMap: data.fileMap });

  if (!data) return false;
  dispatch({
    type: GET_BUCKET_CUSTOM_DATA,
    payload: {
      data,
    },
  });
  return true;
};