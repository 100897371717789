import React, { useCallback } from 'react';
import { Drawer, Select, Row } from 'antd';
import PropTypes from 'prop-types';

import EmailSenderSelector from './selectors/EmailSenderSelector';
import EmailBody from './EmailBody';
import EmailSubject from './EmailSubject';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import EmailRecipientSelectors from './selectors/EmailRecipientSelectors';
import useFormEmailOptions from '../../common/hooks/useFormEmailOptions';

export default function WorkflowEmailConfigureDrawer({
  id,
  visible,
  onClose,
  onSubmit,
  data,
  setDataMap,
  divisionId,
  sections = [],
  isExternalForm,
}) {
  const {
    additionalOptions,
    subjectOptions,
    bodyOptions,
    attachmentFieldOptions,
    dateTimeFields,
  } = useFormEmailOptions(sections);

  const onAttachmentFieldChanged = useCallback((value) => {
    setDataMap((dataMap) => {
      const {
        [id]: oldData = {},
      } = dataMap;
      return {
        ...dataMap,
        [id]: {
          ...oldData,
          attachmentFields: [value],
          // Leaving this open for multiple
        },
      };
    });
  }, [id, setDataMap]);

  return (
    <Drawer
      title="Configure Email Options"
      width={750}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 60 }}
      onMouseDown={(e) => e.stopPropagation()}
    >
      <EmailSenderSelector
        id={id}
        data={data}
        setDataMap={setDataMap}
      />
      <EmailSubject
        id={id}
        data={data}
        setDataMap={setDataMap}
        fields={subjectOptions}
        visible={visible}
        dateTimeFields={dateTimeFields}
      />
      <EmailBody
        id={id}
        data={data}
        setDataMap={setDataMap}
        fields={bodyOptions}
        visible={visible}
        dateTimeFields={dateTimeFields}
      />
      <EmailRecipientSelectors
        id={id}
        data={data}
        setDataMap={setDataMap}
        divisionId={divisionId}
        additionalOptions={additionalOptions}
        sections={sections}
        isExternalForm={isExternalForm}
      />
      <Row style={{ marginTop: 5 }}>
        Attach files from field:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          options={attachmentFieldOptions}
          defaultValue={data?.attachmentFields}
          onChange={onAttachmentFieldChanged}
          allowClear
          style={{ width: '100%' }}
        />
      </Row>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

WorkflowEmailConfigureDrawer.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({
    sender: PropTypes.string,
    subject: PropTypes.string,
    body: PropTypes.string,
    recipients: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })),
    attachmentFields: PropTypes.arrayOf(PropTypes.string),
  }),
  setDataMap: PropTypes.func,
  divisionId: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    fields: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      selectedType: PropTypes.string,
      configProps: PropTypes.shape({
        dataType: PropTypes.string,
        title: PropTypes.string,
      }),
    })),
  })),
  isExternalForm: PropTypes.bool,
};

WorkflowEmailConfigureDrawer.defaultProps = {
  id: null,
  visible: false,
  onClose: null,
  onSubmit: null,
  data: null,
  setDataMap: null,
  divisionId: null,
  sections: [],
  isExternalForm: false,
};
