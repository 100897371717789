import React from 'react';

import NuxDetailPane from '../NuxDetailPane';
import projectApprovers from '../../assets/images/NUX/projectApprovers.png';
import companywideApprovers from '../../assets/images/NUX/companywideApprovers.png';
import { useTranslation } from 'react-i18next';

const getProjectDesc = (t) => `If you'd like to set approvers for a
specific ${t('Project')}, you can do so when creating the ${t('Project')} and
also anytime afterwards via the ${t('Project')}'s Edit menu.`;

const getCompanyDesc = (t) => `Any Time Cards that do not have an
assigned ${t('Project')} approver will go to the companywide approvers
for approval. This is especially relevant when the worker
has not clocked in to a specific ${t('Project')}`;

const warning = `Note: All desired approver users
must have the 'Managed Teams' permission`;

export default function NuxTimeCard({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const projectDesc = getProjectDesc(t);
  const companyDesc = getCompanyDesc(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      warning={warning}
      style={{
        height: 'calc(100% - 74px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:`${t('Project')} Approvers`,
        description:projectDesc,
        cols:[0,16,8],
        image:{
          src:projectApprovers,
          style:{
            height:75,
            width:188,
          }
        },
      },{
        title:'Companywide Approvers',
        description:companyDesc,
        cols:[0,16,8],
        image:{
          src:companywideApprovers,
          style:{
            height:75,
            width:205,
          }
        },
      }]}
      tutorialLink={'TT4. Web Time Card Management.mp4'}
    />
  );
}
