import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import CustomModal from '../../common/modals/CustomModal';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DisplayText from '../../common/text/DisplayText';

import colors from '../../constants/Colors';

const errorText = 'Division names cannot contain the following characters: /, \\, [, ], `, ~, #, {, }, |, <, >, %, ^';
export default function DivisionAddModal({
  visible,
  onClose,
}) {
  const [divisionName, setDivisionName] = useState();
  const [loading, setLoading] = useState(false);
  const [textIsInvalid, setTextIsInvalid] = useState(false);
  const onChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setDivisionName(value);
  }, []);
  const onCancel = useCallback(() => {
    onClose();
    setDivisionName();
  }, [onClose]);
  const onSave = useCallback(async () => {
    setLoading(true);
    if (await onClose(divisionName)) {
      setDivisionName();
    }
    setLoading(false);
  }, [divisionName, onClose]);

  useEffect(() => {
    if (!divisionName) return;
    const match = divisionName.match(/[\/\\\[\]%\~`\{}#|<>^]/g); // eslint-disable-line  no-useless-escape
    setTextIsInvalid(match);
  }, [divisionName]);

  return (
    <CustomModal
      title="Add Division"
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
    >
      <Row style={{ margin: '20px' }}>
        <OnTraccrTextInput
          placeholder="Enter a division name"
          onChange={onChange}
          value={divisionName}
        />
      </Row>
      <Row
        justify="center"
        gutter={12}
        style={{
          marginBottom: 10,
          display: 'flex',
          flexDirection: 'rol',
        }}
      >
        <Col style={{
          flexBasis: '1',
          width: '40%',
        }}
        >
          <OnTraccrButton
            type="cancel"
            title="Cancel"
            roundStyle
            onClick={onCancel}
            style={{
              width: '100%',
            }}
          />
        </Col>
        <Col style={{
          flexBasis: '1',
          width: '40%',
        }}
        >
          <OnTraccrButton
            title="Save"
            roundStyle
            disabled={!divisionName || textIsInvalid}
            onClick={onSave}
            loading={loading}
            style={{
              width: '100%',
            }}
          />
        </Col>
      </Row>
      <Row>
        <DisplayText
          style={{
            color: 'red',
            height: textIsInvalid ? 'auto' : 0,
            opacity: textIsInvalid ? 1 : 0,
          }}
          title={errorText}
        />
      </Row>
    </CustomModal>
  );
}
