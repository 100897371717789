import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { Row, Col } from 'antd';

import { updateProfile, changePassword, } from './state/profile.actions';

import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import ProfileAvatar from '../common/ProfileAvatar';
import DisplayText from '../common/text/DisplayText';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';

import colors from '../constants/Colors';

const textWidth = 400;
const inputWidth = 200;

const largeWidth = 800;
const smallWidth = 500;

const styles = {
  inputStyle: {
    textAlign:'right',
    width:inputWidth
  },
  rowStyle: {
    width:textWidth,
    marginBottom:16,
    height: 37
  },
  passwordHelpText:{
    width:'auto',
    marginLeft: 10,
    marginBottom:0,
    color:'red'
  },
  passwordRow:{
    height:37,
    marginBottom:16,
    width:'100%'
  }
};

const getTitle = (editDetails,editPassword) => {
  if (editDetails) return 'Edit Details';
  if (editPassword) return 'Edit Password';
  return 'My Profile';
};

const Content = ({ 
  username = '', 
  email = '', 
  name = '', 
  position = '',
  editDetails,
  editPassword,
  onEditDetailClick,
  onEditPasswordClick,
  onCancel,
  onConfirm,
  onUserNameChange,
  onEmailChange,
  onPasswordChange,
  oldPass,
  newPass = '',
  confirmPass,
}) => (
  <Row style={{ width: '100%' }} justify='center'>
  

    <Row style={{ marginBottom: 30, width:'100%'}}  justify='center'>
      <Col>
        <ProfileAvatar
          name={name}
          role={position}
          style={{paddingRight:15}}
          avatarRadius={36}
          nameStyle={{
            fontSize:20,
          }}
          roleStyle={{
            fontSize:16,
          }}
          maxWidth={400}
        />
      </Col>
    </Row>
    <Col>
    <Row 
      justify='space-between' 
      align='middle'
      style={styles.rowStyle}>
        <Col>
          <DisplayText
            title={editPassword ? 'Old Password' : 'Username'}
            style={{
              fontFamily: 'roboto-medium',
              fontSize: 16,
              marginBottom:0
            }}/>
        </Col>
        <Col>
          {editDetails || editPassword ? 
          <OnTraccrTextInput
            defaultValue={editDetails ? username : null}
            style={styles.inputStyle}
            onChange={(e) => {
              const {
                target: {
                  value,
                },
              }= e;
              if(editPassword) {
                return onPasswordChange('old',value);
              }
              onUserNameChange(value);
            }}
            password={editPassword}
          /> :
          <DisplayText
            title={username}
            style={{
              fontFamily: 'roboto-regular',
              fontSize: 16,
              color: colors.DARK_GRAY,
              marginBottom:0
            }}
          />}
        </Col>
    </Row>

    <Row 
      justify='space-between'
      align='middle'
      style={styles.rowStyle}>
        <Col>
          <DisplayText
            title={editPassword ? 'New Password' : 'Email'}
            style={{
              fontFamily: 'roboto-medium',
              fontSize: 16,
              marginBottom:0
            }}/>
        </Col>
        <Col>
          {editDetails || editPassword ? 
          <OnTraccrTextInput
            defaultValue={editDetails ? email : null}
            style={styles.inputStyle}
            onChange={(e) => {
              const {
                target: {
                  value,
                },
              }= e;
              if(editPassword) {
                return onPasswordChange('new',value);
              }
              onEmailChange(value);
            }}
            password={editPassword}
          /> :
          <DisplayText
            title={email}
            style={{
              fontFamily: 'roboto-regular',
              fontSize: 16,
              color: colors.DARK_GRAY,
              marginBottom:0
            }}
          />}
        </Col>
    </Row>


    <Row 
      justify='space-between'
      align='middle'
      style={{
        ...styles.rowStyle,
        height: editPassword ? 37 : 0,
        opacity: editPassword ? 1 : 0,
        transition: 'height 0.5s, opacity 0.5s'
      }}>
        <Col>
          <DisplayText
            title='Confirm Password'
            style={{
              fontFamily: 'roboto-medium',
              fontSize: 16,
              marginBottom:0
            }}/>
        </Col>
        <Col>
          <OnTraccrTextInput
            style={styles.inputStyle}
            onChange={(e) => {
              const {
                target: {
                  value,
                },
              }= e;
              onPasswordChange('confirm',value);
            }}
            password={editPassword}
          />
        </Col>
    </Row>
    </Col>
       
    <Col style={{
      width: editPassword ? largeWidth - smallWidth : 0,
      transition:'width 0.5',
    }}>
      <Row style={{height:'100%'}} align='bottom'>
        <Row justify='start' style={styles.passwordRow} align='middle'>
          {editPassword && !oldPass && <DisplayText
            title='Old Password cannot be blank'
            style={styles.passwordHelpText}/>}
        </Row>
        <Row justify='start' style={styles.passwordRow} align='middle'>
          {editPassword && newPass.length < 5 && <DisplayText title='Password must be longer than 5 characters' style={styles.passwordHelpText}/>}
        </Row>
        <Row justify='start' style={styles.passwordRow} align='middle'>
          {editPassword && (!confirmPass || confirmPass !== newPass) && <DisplayText title='Passwords must match' style={styles.passwordHelpText}/>}
        </Row>
      </Row>
      
    </Col>
    <Row style={{width: '100%', marginBottom: 30 }} justify='center'>
      <Row style={styles.rowStyle} justify='space-around'>

        <Col>
          <OnTraccrButton
            title={editDetails || editPassword ? 'Cancel' : 'Edit Details'}
            type='cancel'
            roundStyle
            onClick={editDetails || editPassword ? onCancel : onEditDetailClick}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title={editDetails || editPassword ? 'Confirm' : 'Edit Password'}
            type={editDetails || editPassword ? 'primary' : 'cancel'}
            roundStyle
            onClick={editDetails || editPassword ? onConfirm : onEditPasswordClick}
            disabled={editPassword && (!oldPass || newPass.length < 5 || newPass !== confirmPass)}
          />
        </Col>
      </Row>
    </Row>
  </Row>
)

export default connect(
  (state,ownProps) => {
    return { 
      ...ownProps,
      profile:state.profile.profile
    };
  },{
    updateProfile,
    changePassword,
  }
)(({
  afterClose = () => null,
  visible,
  onCloseClicked,
  profile = {},
  updateProfile,
  changePassword,
}) => {
  const {
    username:propUsername,
    email:propEmail,
  } = profile;
  const [isEditDetails,setEditDetails] = useState(false);
  const [isEditPassword,setEditPassword] = useState(false);

  const [username, setUsername] = useState(propUsername);
  const [email, setEmail] = useState(propEmail);
  
  const [width, setWidth] = useState(500);

  const [oldPass,setOldPass] = useState();
  const [newPass,setNewPass] = useState();
  const [confirmPass,setConfirmPass] = useState();

  useEffect(() => {
    setTimeout(() => {
      setEditDetails(false);
      setEditPassword(false);
      setWidth(smallWidth);
    },100);
  },[visible]);

  useEffect(() => {
    const {
      username,
      email,
    } = profile;
    setUsername(username);
    setEmail(email)
  },[profile]);
  return  (
    <CustomModal
      title={getTitle(isEditDetails,isEditPassword)}
      visible={visible}
      onCloseClicked={onCloseClicked}
      afterClose={afterClose}
      width={width}
    >
      <Content
        {...profile}
        editDetails={isEditDetails}
        editPassword={isEditPassword}
        oldPass={oldPass}
        newPass={newPass}
        confirmPass={confirmPass}
        onEditDetailClick={() => {
          setEditDetails(true);
          setWidth(smallWidth);
        }}
        onEditPasswordClick={() => {
          setEditPassword(true);
          setWidth(largeWidth);
        }}
        onCancel={() => {
          setWidth(smallWidth);
          setEditDetails(false);
          setEditPassword(false);
        }}
        onConfirm={async () => {
          if(isEditDetails) {
            const payload = {};
            if(username !== propUsername) payload.username = username;
            if(email !== propEmail) payload.email = email;
            if(Object.keys(payload).length === 0) return;
            if(await updateProfile(payload)) {
              setEditDetails(false);
            }
          } else {
            if(!oldPass || newPass.length < 5 || newPass !== confirmPass) return;
            const payload = { newPassword: newPass, oldPassword: oldPass, };
            if(await changePassword(payload)) {
              setEditPassword(false);
              setWidth(smallWidth);
            }
          }
          
        }}
        onUserNameChange={setUsername}
        onEmailChange={setEmail}
        onPasswordChange={(type,value) => {
          switch(type) {
            case 'old':
              return setOldPass(value);
            case 'new':
              return setNewPass(value);
            default:
              return setConfirmPass(value);
          }
        }}
      />
    </CustomModal>
  );
});
