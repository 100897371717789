import React from 'react';
import PropTypes from 'prop-types';

function BoardSearchDropdownItem({
  item: {
    title: cardTitle,
    number: cardNumber,
    board: cardBoard,
    status: cardStatus,
  } = {},
}) {
  return (
    <>
      <div>{cardNumber && `#${cardNumber}`}</div>
      <div className="board-search-item-title">{cardTitle}</div>
      <div>
        {cardBoard}
        {' '}
        -
        {' '}
        {cardStatus}
      </div>
    </>
  );
}

BoardSearchDropdownItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    number: PropTypes.number,
    board: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default BoardSearchDropdownItem;
