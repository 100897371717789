import React from 'react'
import { KeyboardTimePicker} from '@material-ui/pickers';

export default function SheetsTimePicker({getTimeValue, mobileOnTimeSelected, dayIndex, pickerType}) {

    const properLabel = (pickerType === "startTime" ?  "Start Time" : "End Time")

    return(
            <KeyboardTimePicker
            margin="normal"
            className="timePicker"
            label={properLabel}
            value={getTimeValue(pickerType)}
            onChange={(momentTime) => mobileOnTimeSelected(momentTime, dayIndex, pickerType)}
            KeyboardButtonProps={{
            'aria-label': 'change time',
            }}
            />

    )
}