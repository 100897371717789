import axios from 'axios';
import qs from 'query-string';

export default class Debouncer {
  constructor() {
    this.timer = null;
  }

  debounce(func, timeout) {
    return new Promise((resolve) => {
      if (this.timer) clearTimeout(this.timer);
      this.timer = setTimeout(() => resolve(func()), timeout);
    });
  }

  clear() {
    if (this.timer) {
      clearTimeout(this.timer);
      this.timer = null;
    }
  }

  checkUsernames(usernames, timeout = 250) {
    return this.debounce(async () => {
      const { data } = await axios.get(
        '/check/username',
        {
          params: { usernames },
          paramsSerializer: qs.stringify,
        },
      );
      return data;
    }, timeout);
  }
}
