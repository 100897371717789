import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Input,
  Radio,
  Row,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';

// Import Components:
import FormResponder from '../FormResponder';
import FormsTable from '../FormsTable';

// Import Actions:
import { favoriteFormTemplate, getAssignedFormTemplates } from '../state/forms.actions';

// Import Helpers:
import sortByString, { getIdMap, includesTerm } from '../../helpers/helpers';
import { getManualFormColumns } from '../FormColumns';
import { getFilters, handleFormClick, parseFormDates } from '../formHelpers';

import {
  formatProjectLabelFromCompanySettings,
} from '../../projects/projectHelpers';

// Constants:
export const MANUAL_FAVORITE_TAB = 'favorite';
export const MANUAL_PROJECT_TAB = 'project';
export const MANUAL_GLOBAL_TAB = 'global';

/** Manual Forms Section */
function ManualForms({
  visible,
  history,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects.projects);
  const assignedFormTemplates = useSelector((state) => state.forms.assignedFormTemplates);
  const latestManualDraftId = useSelector((state) => state.forms.latestManualDraftId);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const [tab, setTab] = useState(MANUAL_FAVORITE_TAB);
  const [assignedForm, setAssignedForm] = useState({});
  const [showDetail, setShowDetail] = useState(false);
  const [selectedIsEdit, setSelectedIsEdit] = useState(false);
  const [selectedIsResubmit, setSelectedIsResubmit] = useState(false);
  const [searchStr, setSearchStr] = useState();
  const [loading, setLoading] = useState(false);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const clearFormSelection = useCallback(() => setShowDetail(false), []);
  const assignedTemplateMap = useMemo(() => getIdMap(assignedFormTemplates), [assignedFormTemplates]);

  useEffect(() => {
    setAssignedForm((prevState) => ({
      ...prevState,
      draftId: latestManualDraftId,
    }));
  }, [latestManualDraftId]);

  useEffect(() => {
    if (dispatch) {
      dispatch(getAssignedFormTemplates());
    }
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      const lastSelectedTab = window.sessionStorage.getItem('manualFormsSectionTab') ?? MANUAL_FAVORITE_TAB;
      if (lastSelectedTab !== MANUAL_FAVORITE_TAB
        && lastSelectedTab !== MANUAL_PROJECT_TAB
        && lastSelectedTab !== MANUAL_GLOBAL_TAB) return;
      setTab(lastSelectedTab);
    }
  }, [visible]);

  // Handlers:
  // -----------------------------------------------------
  const onFormClick = useCallback(async (record) => {
    await handleFormClick({
      record,
      dispatch,
      loading,
      setLoading,
      setAssignedForm,
      setShowDetail,
      setSelectedIsEdit,
      setSelectedIsResubmit,
    });
  }, [dispatch, loading]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onManualFormTypeChange = useCallback((e) => {
    const {
      target: {
        value: newTab,
      } = {},
    } = e;
    setTab(newTab);
    window.sessionStorage.setItem('manualFormsSectionTab', newTab);
  }, []);

  const onFavoriteClick = useCallback((e, form = {}) => {
    e.stopPropagation();
    const { id, isFavorite } = form;
    if (!id) return;
    dispatch(favoriteFormTemplate(id, isFavorite));
  }, [dispatch]);

  // Prepare data:
  // -----------------------------------------------------
  const tableData = useMemo(() => {
    const newTableData = (assignedFormTemplates || [])
      .filter(({
        active, notDeleted, isFavorite, projectId,
      }) => {
        if (!active || !notDeleted) return false;
        if (tab === MANUAL_FAVORITE_TAB) return isFavorite;
        if (tab === MANUAL_PROJECT_TAB) return projectId;
        return !projectId;
      })
      .filter(({ name, project }) => (!searchStr
        || (name && includesTerm(name, searchStr))
        || (project && includesTerm(project, searchStr))))
      .map((t) => {
        const { id: templateId, timestamp, projectId } = t;
        const {
          [templateId]: {
            name: templateName,
          } = {},
        } = assignedTemplateMap;
        const {
          [projectId]: {
            name,
            number,
          } = {},
        } = projectIdMap;

        const project = formatProjectLabelFromCompanySettings({ name, number, settings });

        const {
          createdAtDate,
        } = parseFormDates({ createdAt: timestamp });
        return {
          ...t,
          templateName,
          createdAt: timestamp,
          project,
          createdAtDate,
        };
      });
    newTableData.sort(sortByString('name'));
    return newTableData;
  }, [
    tab,
    assignedFormTemplates,
    searchStr,
    assignedTemplateMap,
    projectIdMap,
    settings,
  ]);

  // Prepare filters:
  // -----------------------------------------------------
  const manualProjectFilters = useMemo(() => {
    const filterIds = new Set();
    tableData.forEach((row) => {
      if (row.projectId) filterIds.add(row.projectId);
    });
    return Array.from(filterIds).map((id) => {
      const {
        [id]: { name, number } = {},
      } = projectIdMap;
      const project = formatProjectLabelFromCompanySettings({ name, number, settings });
      return { text: project, value: project };
    });
  }, [projectIdMap, tableData, settings]);

  // Get columns:
  // -----------------------------------------------------
  const manualColumns = useMemo(() => (
    getManualFormColumns({
      projectIdMap,
      projectFilters: manualProjectFilters,
      tab,
      onFavoriteClick,
      settings,
      t,
    })
  ), [manualProjectFilters, tab, settings, projectIdMap]);

  return (
    <div>
      <Row
        className="manual-forms-header"
        align="middle"
        style={{ marginBottom: 14 }}
      >
        <Col style={{ paddingRight: 20 }}>
          <Radio.Group
            value={tab}
            size="medium"
            buttonStyle="solid"
            onChange={onManualFormTypeChange}
          >
            <Radio.Button value={MANUAL_FAVORITE_TAB}>Favorite</Radio.Button>
            <Radio.Button value={MANUAL_PROJECT_TAB}>{t('Project')}</Radio.Button>
            <Radio.Button value={MANUAL_GLOBAL_TAB}>Global</Radio.Button>
          </Radio.Group>
        </Col>
        <Col>
          <Input.Search
            type="search"
            style={{ width: 250 }}
            className="searchbar"
            placeholder="Search"
            allowClear
            onChange={onSearch}
            value={searchStr}
          />
        </Col>
      </Row>
      <div>
        <FormsTable
          data={tableData}
          columns={manualColumns}
          onClickRow={onFormClick}
        />
        <FormResponder
          visible={showDetail}
          onClose={clearFormSelection}
          history={history}
          assignedForm={assignedForm}
          isOnEditStep={selectedIsEdit}
          isResubmit={selectedIsResubmit}
        />
      </div>
      {loading
          && (
          <Row justify="center" align="middle" className="form-loading-container">
            <Spin size="large" />
          </Row>
          )}
    </div>
  );
}

ManualForms.propTypes = {
  visible: PropTypes.bool.isRequired,
  history: PropTypes.shape({}).isRequired,
};

export default ManualForms;
