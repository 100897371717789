import React, { useCallback, useState } from 'react';
import { Row, Col, Checkbox } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import UserAssignmentSelector from './selectors/UserAssignmentSelector';

import { AdditionalItem, AdditionalLabel } from './AssignmentDropdownItems';

const HOVER_TEXT = `
Users or roles added to this step can approve or reject a submitted form.
`;

const AUTHOR_HELP = 'An approval request will be sent to the form\'s author';

const TEAM_TITLE = 'Team Supervisor';
const TEAM_HELP = (
  <div>
    An approval request will be sent to the form's author's team supervisor(s).
    <br />
    <br />
    If the user has no team supervisor the approval will go to the company wide first approver.
  </div>
);
const teamSupervisorOption = ({
  id: 'teamSupervisor',
  children: <AdditionalItem title={TEAM_TITLE} help={TEAM_HELP} />,
  label: <AdditionalLabel title={TEAM_TITLE} help={TEAM_HELP} />,
});

export default ({
  setElements,
  setDataMap,
  isDisplay,
  divisionId,
  sections = [],
  isExternalForm,
} = {}) => ({
  id,
  draggable,
  data = {},
}) => {
  const {
    users: initialSelected = [],
  } = data;
  const [selected,setSelected] = useState(initialSelected);
  const [checked, setChecked] = useState(data.checked !== undefined ? data.checked : false);

  const onChange = useCallback((newSelected) => {
    setDataMap((dataMap) => ({
      ...dataMap,
      [id]: { ...dataMap[id], users: newSelected}
    }));
    setSelected(newSelected);
  },[id, checked]);

  const onCheckChange = useCallback(() => {
    setChecked(!checked)
    setDataMap((dataMap) => ({
      ...dataMap,
      [id]: { ...dataMap[id], checked: !checked},
    }))
  }, [checked])

  return (
    <WorkflowActionNode
      id={id}
      title={'Approval'}
      Icon={CheckCircleOutlined}
      type='approval'
      draggable={draggable}
      onNodeUpdate={setElements}
      isDisplay={isDisplay}
      hover={HOVER_TEXT}
    >
      {!draggable && <div>
        <UserAssignmentSelector
          text='Select approver(s):'
          type='user'
          isDisplay={isDisplay}
          draggable={draggable}
          divisions={[divisionId]}
          onChange={onChange}
          selected={selected}
          showFields
          sections={sections}
          showPositions
          showFormAuthor={!isExternalForm}
          formAuthorHelperText={AUTHOR_HELP}
          additionalOptions={[teamSupervisorOption]}
        />
      <Checkbox
        checked={checked}
        onChange={onCheckChange}
        style={{ color:'black' }}
      >
        Edit
      </Checkbox>
        <Row justify='space-between' style={{ marginBottom: 5 }}>
          <Col>
            Approved
          </Col>
          <Col>
            Rejected
          </Col>
        </Row>

        <WorkflowHandle type='target' position='top' disabled={isDisplay}/>

        <WorkflowHandle
          type='source'
          position='bottom'
          style={{ left:'20%' }}
          id={`${id}-approved`}
          disabled={isDisplay}
        />
        <WorkflowHandle
          type='source'
          position='bottom'
          style={{ left:'80%' }}
          id={`${id}-rejected`}
          disabled={isDisplay}
        />
      </div>}
    </WorkflowActionNode>
  )
}
