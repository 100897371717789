import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Colors from '../../constants/Colors';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DisplayText from '../../common/text/DisplayText';

import CompanySettingsCard from '../CompanySettingsCard';
import CustomTableListItem from './CustomTableListItem';
import CustomTableDrawer from './CustomTableDrawer';
import CustomTableDeleteModal from './CustomTableDeleteModal';

import {
  getCustomTables,
  deleteCustomTable
} from '../../forms/state/forms.actions';

export default () => {
  const dispatch = useDispatch();
  const customTables = useSelector(state => state.forms.customTables);

  const tableList = useMemo(() => Object.values(customTables),[customTables]);

  const [showAddDrawer, setShowAddDrawer] = useState(false);
  const [selectedTable, setSelectedTable] = useState();

  useEffect(() => {
    dispatch(getCustomTables())
  },[dispatch]);

  const onAddClicked = useCallback(() => setShowAddDrawer(true),[]);
  const closeAddDrawer = useCallback(() => setShowAddDrawer(false),[]);
  const onDelete = useCallback((id) => {
    CustomTableDeleteModal(async () => dispatch(deleteCustomTable(id)));
  }, [dispatch]);
  const onEdit = useCallback((tableId) => {
    setSelectedTable(customTables[tableId]);
    setShowAddDrawer(true);
  }, [customTables]);
  const renderItem = useCallback((item) => (
      <CustomTableListItem
        onDelete={onDelete}
        onEdit={onEdit}
        item={item}
      />
    )
  ,[onDelete, onEdit]);

  useEffect(() => {
    if(!showAddDrawer) setSelectedTable();
  },[showAddDrawer]);

  return (
    <>
      <CompanySettingsCard title='Custom Tables' containerStyle={{ height: 'calc(100vh - 170px)'}}>
        <Row style={{ margin: 10 }} align='bottom'>
          <Col span={18}>
            <DisplayText
              title='Custom Form Tables'
              style={{ fontFamily: 'roboto-medium', marginBottom:0}}
            />
            <DisplayText
              title={(
                <div style={{ width: '100%' }}>
                  Configure custom tables with preset options<br/><br/>
                  Tables configured here can be added as form fields in the Forms page.
                </div>
              )}
              style={{
                fontFamily: 'roboto-regular',
                marginBottom:0,
                color:Colors.ONTRACCR_OPACITY_GRAY
              }}
            />
          </Col>
          <Col span={6}>
            <OnTraccrButton title='Add' icon={<PlusOutlined />} onClick={onAddClicked}/>
          </Col>
        </Row>
        <List
          style={{ marginLeft: 15, height: 'calc(100% - 120px)', overflowY: 'auto' }}
          dataSource={tableList}
          renderItem={renderItem}
        />
      </CompanySettingsCard>
      <CustomTableDrawer
        selectedTable={selectedTable}
        visible={showAddDrawer}
        onClose={closeAddDrawer}
      />
    </>
  );
};