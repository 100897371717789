import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

function SimpleShiftTable({
  shifts = [],
  showHeader = false,
  onRowClick,
  rowClassName = 'whose-scheduled-date-row',
}) {
  const { t } = useTranslation();
  return (
    <Table
      showHeader={showHeader}
      rowKey="id"
      columns={[
        {
          dataIndex: 'title',
          title: 'Title',
        }, {
          dataIndex: 'startTime',
          title: 'Time',
          render: (startTime, { endTime }) => {
            const startDay = DateTime.fromMillis(startTime);
            const endDay = DateTime.fromMillis(endTime);
            const endOfEndDay = endDay.endOf('day');
            const isAllDay = endDay.equals(endOfEndDay);
            const isMultiDay = !endDay.hasSame(startDay, 'day');
            let text = '';
            if (isAllDay) {
              text = isMultiDay
                ? `${startDay.toLocaleString(DateTime.DATE_MED)} -> ${endDay.toLocaleString(DateTime.DATE_MED)}`
                : startDay.toLocaleString(DateTime.DATE_MED);
            } else {
              text = isMultiDay
                ? `${startDay.toLocaleString(DateTime.DATETIME_FULL)} -> ${endDay.toLocaleString(DateTime.DATETIME_FULL)}`
                : `${startDay.toLocaleString(DateTime.DATE_MED)} ${startDay.toLocaleString(DateTime.TIME_SIMPLE)} -> ${endDay.toLocaleString(DateTime.TIME_SIMPLE)}`;
            }
            return (
              <span className={showHeader ? '' : 'whose-scheduled-date'}>
                {text}
              </span>
            );
          },
        },
        {
          dataIndex: 'projectName',
          title: t('Project'),
        },
        {
          dataIndex: 'phaseName',
          title: 'Phase',
        },
        {
          dataIndex: 'costcodeName',
          title: 'Cost Code',
        },
      ]}
      dataSource={shifts}
      pagination={false}
      rowClassName={() => rowClassName}
      size="small"
      onRow={(record) => ({
        onClick: onRowClick ? () => onRowClick(record) : null,
      })}
    />
  );
}

SimpleShiftTable.propTypes = {
  shifts: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  showHeader: PropTypes.bool,
  onRowClick: PropTypes.func,
  rowClassName: PropTypes.string,
};

SimpleShiftTable.defaultProps = {
  showHeader: false,
  onRowClick: null,
  rowClassName: 'whose-scheduled-date-row',
};

export default SimpleShiftTable;
