import React, { useEffect, useState } from 'react';
import { InputNumber, Select } from 'antd';
import PropTypes from 'prop-types';

const { Option } = Select;

export default function DurationInput({
  value,
  onChange,
  min,
  max,
}) {
  const [duration, setDuration] = useState(value?.duration || 0);
  const [durationUnit, setDurationUnit] = useState(value?.durationUnit || 'hours');

  useEffect(() => {
    setDuration(value?.duration || 0);
    setDurationUnit(value?.durationUnit || 'hours');
  }, [value]);

  return (
    <div className="ant-input-addOnAfterInput ant-input-wrapper ant-input-group">
      <InputNumber
        value={duration}
        onChange={(newValue) => {
          setDuration(newValue);
          onChange({ duration: newValue, durationUnit });
        }}
        min={min}
        max={max}
        style={{ width: 100 }}
        addonAfter="$"
      />
      <span className="ant-input-group-addon">
        <Select
          defaultValue="hours"
          value={durationUnit}
          onChange={(newValue) => {
            setDurationUnit(newValue);
            onChange({ duration, durationUnit: newValue });
          }}
        >
          <Option value="minutes">minutes</Option>
          <Option value="hours">hours</Option>
          <Option value="days">days</Option>
        </Select>
      </span>
    </div>
  );
}

DurationInput.propTypes = {
  value: PropTypes.shape({
    duration: PropTypes.number,
    durationUnit: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
  min: PropTypes.number,
  max: PropTypes.number,
};

DurationInput.defaultProps = {
  value: {
    duration: 0,
    durationUnit: 'hours',
  },
  min: 0,
  max: 999,
};
