import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import addManageFiles from '../../assets/images/NUX/addManageFiles.png';

const defaultDesc = `
Add as many folders and files as your storage space will allow.
Simply select the checkbox next to any folder or file to bring up additional controls.
`

const fileTypeDesc = (
  <div>
    Ontraccr currently lets you upload the following file types: <i>.jpg/.png/.svg/.pdf/.xls(x)/.csv/.doc(x)/.txt</i>
    <br/>
    The following file types are viewable inside the app: <i>.jpg/.png/.svg/.pdf/.xls(x)/.csv</i>
  </div>
);

const warning = `
Notes: Files not viewable within the app can still be downloaded to view using standard software.

If you require support for additional file types, please contact your Ontraccr account team.
`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 65px)',
      padding:'0px 40px',
      textAlign:'left',
    }}
    warning={warning}
    rows={[{
      title:'Adding & Managing Folders/Files',
      description:defaultDesc,
      cols:[0,12,12],
      image:{
        src:addManageFiles,
        style:{
          height:40,
          width:282,
        }
      },
    },{
      title:'Supported File Types',
      description:fileTypeDesc,
      cols:[0,24,0],
    }]}
  />
);
