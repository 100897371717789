import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Timeline, Spin, Row, Divider,
} from 'antd';
import { DateTime } from 'luxon';

import Permissions from '../../auth/Permissions';

import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import SimpleShiftTable from '../../schedule/SimpleShiftTable';
import ScheduleDrawer from '../../schedule/ScheduleDrawer';

import { getFormById } from '../state/forms.actions';
import { updateShift, deleteShift } from '../../schedule/state/schedule.actions';

import { prepareShiftUpdatePayload } from '../../schedule/scheduleHelpers';

import colors from '../../constants/Colors';

const getTitle = ({
  formId,
  link = {},
  onSelect,
}) => {
  const {
    createdAt = 0,
    number,
    templateName,
    id,
  } = link;
  const dt = DateTime.fromMillis(createdAt);
  const dateText = dt.toLocaleString(DateTime.DATETIME_MED);
  const suffix = number ? ` - ${number}` : '';
  const text = `${dateText}: ${templateName}${suffix}`;
  return id === formId
    ? <b>{text}</b>
    : <span className="form-link-title" onClick={() => onSelect(link)}>{text}</span>; // eslint-disable-line
};

function FormLinks({
  formId,
  triggeredItems: {
    forms = [],
    shifts = [],
  } = {},
  loading,
  onShiftChanged,
}) {
  const canEdit = Permissions.has('SCHEDULE_WRITE');
  const dispatch = useDispatch();

  const [selectedShift, setSelectedShift] = useState({});

  const onSelect = useCallback((selectedForm) => {
    dispatch(getFormById(selectedForm.id));
  }, [dispatch]);
  const closeShiftDrawer = useCallback(() => {
    setSelectedShift({});
  }, []);

  const onShiftSave = useCallback(async (shift) => {
    if (!selectedShift) return false;
    const existingShift = shifts.find((formShift) => formShift.id === selectedShift.id);
    if (!existingShift) return false;
    const payload = prepareShiftUpdatePayload(existingShift, {
      ...selectedShift,
      ...shift,
    });
    const passed = await dispatch(updateShift(selectedShift.id, payload));
    if (passed) {
      setSelectedShift({});
      onShiftChanged();
    }
    return passed;
  }, [dispatch, onShiftChanged, selectedShift, shifts]);

  const onShiftDelete = useCallback(() => {
    CustomConfirmModal({
      title: `Delete ${selectedShift.title}`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        if (await dispatch(deleteShift(selectedShift))) {
          setSelectedShift({});
          onShiftChanged();
        }
      },
    });
  }, [dispatch, onShiftChanged, selectedShift]);

  useEffect(() => {
    setSelectedShift({});
  }, [formId]);

  const items = useMemo(() => (
    forms.map((link) => (
      <Timeline.Item color={colors.ONTRACCR_RED}>
        {getTitle({ link, formId, onSelect })}
      </Timeline.Item>
    ))
  ), [formId, forms]);

  /*
    ScheduleDrawer allows the user to open a form if formId exists.
    Since we already have the form open, we don't want the user to try
    to open it again.
  */
  const parsedShifts = useMemo(() => (
    shifts.map((shift) => {
      const parsedShift = { ...shift };
      delete parsedShift.formId;
      return parsedShift;
    })
  ), [shifts]);

  if (loading) {
    return (
      <Row style={{ height: 100, width: 100 }} justify="center" align="middle">
        <Spin />
      </Row>
    );
  }
  return (
    <>
      <div style={{
        overflow: 'scroll',
        maxHeight: 'calc(100vh - 10em)',
      }}
      >
        <div style={{ marginBottom: 10 }}>Forms</div>
        <Timeline>
          {items}
        </Timeline>
        <Divider />
        <div style={{ marginBottom: 10 }}>Shifts</div>
        <div className="form-shift-container">
          <SimpleShiftTable
            shifts={parsedShifts}
            showHeader
            onRowClick={setSelectedShift}
            rowClassName="form-shift-row"
          />
        </div>
      </div>
      <ScheduleDrawer
        visible={selectedShift.id}
        onClose={closeShiftDrawer}
        shift={selectedShift}
        isDisplay={!canEdit}
        onSave={onShiftSave}
        onDelete={onShiftDelete}
      />
    </>
  );
}

const formShape = {
  createdAt: PropTypes.number.isRequired,
  number: PropTypes.string.isRequired,
  templateName: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};

const shiftShape = {
  id: PropTypes.string.isRequired,
};

FormLinks.propTypes = {
  formId: PropTypes.string.isRequired,
  triggeredItems: PropTypes.objectOf(PropTypes.shape({
    forms: PropTypes.arrayOf(PropTypes.shape(formShape)),
    shifts: PropTypes.arrayOf(PropTypes.shape(shiftShape)),
  })).isRequired,
  loading: PropTypes.bool.isRequired,
};

export default FormLinks;
