import React from 'react';
import PropTypes from 'prop-types';

import { autoImportHeader, autoImportUncheckText } from '../integrationHelpers';
import IntegrationCustomerVendorSync from '../IntegrationCustomerVendorSync';

import { toTitleCase } from '../../../helpers/helpers';

const autoImportContent = (type) => {
  const contactText = type === 'customer' ? 'Customers' : 'Vendors';
  return (
    <div style={{ width: 500 }}>
      {autoImportHeader('Quickbooks', contactText)}
      <br />
      <br />
      1. Make sure the
      {' '}
      {contactText}
      {' '}
      have a name set.
      <br />
      <br />
      2.
      {' '}
      {contactText}
      {' '}
      with names that conflict with existing Ontraccr
      {' '}
      {contactText}
      {' '}
      will not be imported.
      <br />
      <br />
      {autoImportUncheckText('Quickbooks')}
    </div>
  );
};

const importHelpText = (type) => {
  const contactText = type === 'customer' ? 'Customers' : 'Vendors';

  return (
    <div style={{ width: 500 }}>
      Select from this dropdown to either
      {' '}
      <b>Import</b>
      {' '}
      new
      {' '}
      {contactText}
      {' '}
      from QuickBooks or
      {' '}
      <b>Link</b>
      {' '}
      QuickBooks
      {' '}
      {contactText}
      {' '}
      to existing Ontraccr
      {' '}
      {contactText}
      <br />
      <br />
      <b>Import:</b>
      {' '}
      {contactText}
      {' '}
      must have a name to be imported into Ontraccr.
      <br />
      <b>Link:</b>
      {' '}
      Select an existing Ontraccr
      {' '}
      {toTitleCase(type)}
      {' '}
      to link with the QuickBooks
      {' '}
      {toTitleCase(type)}
      .
    </div>
  );
};

export default function QuickBooksContactSync({
  type,
  quickBooksContacts,
  onContactsChanged,
  importContacts,
  divisionFilter,
}) {
  return (
    <IntegrationCustomerVendorSync
      integrationValues={quickBooksContacts}
      title="QuickBooks"
      integrationKey="intuitId"
      onValuesChanged={onContactsChanged}
      autoImportContent={autoImportContent(type)}
      importHelpText={importHelpText(type)}
      importValues={importContacts}
      divisionFilter={divisionFilter}
      isVendor={type === 'vendor'}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
QuickBooksContactSync.propTypes = {
  type: PropTypes.string.isRequired,
  quickBooksContacts: PropTypes.array,
  onContactsChanged: PropTypes.func.isRequired,
  importContacts: PropTypes.array,
  divisionFilter: PropTypes.instanceOf(Set),
};

QuickBooksContactSync.defaultProps = {
  quickBooksContacts: [],
  importContacts: [],
  divisionFilter: null,
};
