import React, {
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Spin } from 'antd';
import pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import PDFPageControls from './PDFPageControls';
import PDFZoomControls from './PDFZoomControls';

import { annotatePDF, rotateCoords } from './pdfHelpers';
import { getIdMap, uuid } from '../../helpers/helpers';

import Analytics from '../../helpers/Analytics';
import { PDF_SCALE as SCALE, defaultHightlightAnnotationStyles, } from './pdfAnnotationTypes.constants';
import PDFAnnotationStyleDrawer from './PDFAnnotationStyleDrawer';

let renderContext;
let currentPDFPage;
let isMouseDown = false;

const strokeObject = ({
  x,
  y,
  groupId,
  containerX,
  containerY,
  rotation,
}) => ({
  x: x / SCALE,
  y: y / SCALE,
  groupId,
  containerX: containerX / SCALE,
  containerY: containerY / SCALE,
  rotation,
});

export default function BasicPDF({
  url,
  file,
  onSave,
  hideControls,
  darkMode = true,
  style = {},
  canvasStyle = {},
  initialZoom = 1,
  className,
  annotatedFileName,
}) {
  const canvasRef = useRef();
  pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;
  const [PDF, setPdfRef] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [numPages, setNumPages] = useState(1);
  const [PDFContext, setPDFContext] = useState();
  const [rotation, setRotation] = useState(0);
  const [strokes, setStrokes] = useState({});
  const [zoom, setZoom] = useState(initialZoom);
  const [annotationMode, setAnnotationMode] = useState();
  const [scaleX, setScaleX] = useState(2);
  const [scaleY, setScaleY] = useState(2);
  const [eventListeners, setEventListeners] = useState({ down: null, move: null });
  const [loading, setLoading] = useState(false);
  const [selectedAnnotation, setSelectedAnnotation] = useState();
  const [isStyleDrawerOpen, setIsStyleDrawerOpen] = useState(false);

  const saveAnnotations = useCallback(async () => {
    setLoading(true);
    if (!file && !url) {
      onSave(file);
      return setLoading(false);
    };
    Analytics.track('Files/Annotate/Save', { fileType: file?.type });
    const newPDF = await annotatePDF({
      file,
      url,
      annotations: strokes,
      annotatedFileName,
    });
    onSave(file, newPDF);
    return setLoading(false);
  }, [url, file, onSave, strokes, annotatedFileName]);

  const getCoords = useCallback((e) => {
    // Need to rotate coords the inverse of page rotation
    if (!canvasRef || !canvasRef.current) return { x: 0, y: 0 };
    const bounds = canvasRef.current.getBoundingClientRect();
    const hOffset = bounds.left;
    const vOffset = bounds.top;

    const clientX = parseInt(((e.clientX - hOffset) * scaleX) / zoom, 10);
    const clientY = parseInt(((e.clientY - vOffset) * scaleY) / zoom, 10);

    // Ignore scale for html elements
    const containerX = parseInt((e.clientX - hOffset) / zoom, 10);
    const containerY = parseInt((e.clientY - vOffset) / zoom, 10);

    const result = rotateCoords({
      height: canvasRef.current.height,
      width: canvasRef.current.width,
      clientX,
      clientY,
      rotation,
    });

    return {
      ...result,
      containerX,
      containerY,
    };
  }, [rotation, zoom, scaleX, scaleY, canvasRef]);

  const strokeMap = useMemo(() => getIdMap(strokes[currentPage]), [strokes, currentPage]);

  const getBoundingRectangle = (currentBoundingRectangle, x, y, bufferRoom) => ({
    minX: Math.min(currentBoundingRectangle.minX, x - bufferRoom),
    minY: Math.min(currentBoundingRectangle.minY, y - bufferRoom),
    maxX: Math.max(currentBoundingRectangle.maxX, x + bufferRoom),
    maxY: Math.max(currentBoundingRectangle.maxY, y + bufferRoom),
  });

  const getClickedAnnotation = useCallback(({ x, y }) => {
    const pageStrokes = strokes[currentPage];
    if (!pageStrokes?.length) return null;

    const annotationCanvas = document.getElementById('annotation-canvas');
    const annotationContext = annotationCanvas.getContext('2d');

    return pageStrokes.find((stroke) => {
      const { points, type, width } = stroke;

      // Handled by onclick
      if (type === 'text') return false;

      let boundingRectangle = {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      };

      points.forEach(({ x: pointX, y: pointY }) => {
        boundingRectangle = getBoundingRectangle(boundingRectangle, pointX, pointY, width / 2);
      });

      const textBox = new Path2D();
      textBox.rect(
        boundingRectangle.minX * SCALE,
        boundingRectangle.minY * SCALE,
        (boundingRectangle.maxX - boundingRectangle.minX) * SCALE,
        (boundingRectangle.maxY - boundingRectangle.minY) * SCALE,
      );
      return annotationContext.isPointInPath(textBox, x, y);
    });
  }, [strokes, currentPage]);

  const onTextValueChange = (id) => (e) => {
    const stroke = strokeMap[id];
    stroke.text = e.target.value;
  };

  const createTextElement = ({
    text,
    fontSize,
    x,
    y,
    id,
    color,
    isSelected,
    rotation: textRotation,
  }) => {
    const createInputElement = () => {
      const textInput = document.createElement('input');
      textInput.type = 'text';
      textInput.value = text;
      textInput.addEventListener('input', onTextValueChange(id));
      textInput.addEventListener('input', (e) => {
        textInput.style.width = `${e.target.value.length + 1}ch`;
      });
      textInput.focus();
      textInput.style.width = `${text.length + 1}ch`;
      return textInput;
    };

    const textDiv = document.createElement('div');
    textDiv.className = 'pdf-text-annotation';
    textDiv.id = id;
    textDiv.style.position = 'absolute';
    textDiv.style.left = `${x * SCALE}px`;
    textDiv.style.top = `${y * SCALE}px`;
    textDiv.style.fontSize = `${fontSize * SCALE}px`;
    textDiv.style.color = color;
    textDiv.style.transform = `rotate(${textRotation}deg)`;
    textDiv.innerText = text;

    if (isSelected) {
      textDiv.style.border = '5px dashed blue';
      textDiv.innerText = null;
      textDiv.appendChild(createInputElement());
    }

    textDiv.addEventListener('mousedown', (e) => {
      e.stopPropagation();
      isMouseDown = true;
      setSelectedAnnotation(id);
    });

    textDiv.addEventListener('mouseup', (e) => {
      e.stopPropagation();
      isMouseDown = false;
      setIsStyleDrawerOpen(true);
    });

    return textDiv;
  };

  const draw = useCallback((pageToDraw) => {
    // Draw strokes onto canvas
    if (!canvasRef?.current || !(pageToDraw in strokes)) return;
    const context = canvasRef.current.getContext('2d');
    const pageStrokes = strokes[pageToDraw];
    pageStrokes.forEach(({
      points,
      type,
      fontSize,
      color,
      text,
      id,
      width,
      opacity,

    }) => {
      const isSelected = selectedAnnotation === id;

      let boundingRectangle = {
        minX: Infinity,
        minY: Infinity,
        maxX: -Infinity,
        maxY: -Infinity,
      };

      context.globalAlpha = opacity;

      if (type === 'pencil' || type === 'highlight') {
        const bufferRoom = width / 2;

        points.forEach(({ x, y }, index) => {
          boundingRectangle = getBoundingRectangle(boundingRectangle, x, y, bufferRoom);
          if (index === 0) {
            context.beginPath();
            context.moveTo(x * SCALE, y * SCALE);
          } else {
            context.lineWidth = width; // width of the line
            context.strokeStyle = color; // color of the line
            context.lineTo(x * SCALE, y * SCALE); // used to create a pointer based on x and y
            if (index === points.length - 1) {
              context.stroke(); // this is where the actual drawing happens.
              context.save();
            }
          }
        });

        // Draw bounding rectangle after user finishes drawing with pencil
        if (isSelected && !isMouseDown) {
          context.strokeStyle = 'blue';
          context.lineWidth = 2;
          context.setLineDash([6]);
          context.strokeRect(
            boundingRectangle.minX * SCALE,
            boundingRectangle.minY * SCALE,
            (boundingRectangle.maxX - boundingRectangle.minX) * SCALE,
            (boundingRectangle.maxY - boundingRectangle.minY) * SCALE,
          );
          context.setLineDash([]);
          context.save();
        }
      } else if (type === 'text') {
        const {
          containerX,
          containerY,
          rotation: textRotation,
        } = points[0];

        const isAlignedWithCanvas = rotation === textRotation;

        // Canvas container is rotated but pdf-wrapper is not
        // So if text annotation is not rotated, it should be appended to canvas-container
        const wrapperId = isAlignedWithCanvas ? 'pdf-wrapper' : 'canvas-container';
        const textDiv = createTextElement({
          text,
          fontSize,
          x: containerX,
          y: containerY,
          id,
          color,
          isSelected,
          rotation: isAlignedWithCanvas ? textRotation : 0,
        });

        const wrapper = document.getElementById(wrapperId);
        wrapper.appendChild(textDiv);
      }

      context.globalAlpha = 1;
    });
  }, [
    canvasRef,
    strokes,
    selectedAnnotation,
    rotation,
  ]);

  const clearAnnotationCanvas = () => {
    const annotationCanvas = document.getElementById('annotation-canvas');
    const annotationContext = annotationCanvas.getContext('2d');
    annotationContext.clearRect(0, 0, annotationCanvas.width, annotationCanvas.height);

    document.querySelectorAll('.pdf-text-annotation').forEach((e) => e.remove());
  };

  const processTouchEvents = (e) => {
    const relevantStroke = strokeMap[selectedAnnotation];
    // If the user has a different annotation type selected or no annotation selected, ignore
    if (!relevantStroke || (annotationMode && relevantStroke.type !== annotationMode)) {
      setSelectedAnnotation();
      return;
    }

    const {
      type,
      width,
      opacity,
      points,
      color,
    } = relevantStroke;

    const {
      x,
      y,
      containerX,
      containerY,
    } = getCoords(e);

    const newStroke = strokeObject({
      x,
      y,
      groupId: selectedAnnotation,
      containerX,
      containerY,
      rotation,
    });

    if (type === 'pencil' || type === 'highlight') {
      points.push(newStroke);
      const annotationCanvas = document.getElementById('annotation-canvas');
      const annotationContext = annotationCanvas.getContext('2d');

      annotationContext.globalAlpha = opacity;
      annotationContext.strokeStyle = color;
      annotationContext.lineWidth = width; // width of the line
      annotationContext.lineTo(x, y); // used to create a pointer based on x and y
      annotationContext.stroke(); // this is where the actual drawing happens.
      annotationContext.save();
    } else if (type === 'text') {
      points[0] = newStroke;
      const textDiv = document.getElementById(selectedAnnotation);

      if (textDiv) {
        textDiv.style.left = `${containerX}px`;
        textDiv.style.top = `${containerY}px`;
      }
    }
  };

  useEffect(() => {
    clearAnnotationCanvas();
    draw(currentPage);
  }, [selectedAnnotation, currentPage]);

  const onMouseDown = useCallback((e) => {
    setSelectedAnnotation();

    const {
      x,
      y,
      containerX,
      containerY,
    } = getCoords(e);

    const clickedAnnotation = getClickedAnnotation({ x, y });
    if (clickedAnnotation) {
      setSelectedAnnotation(clickedAnnotation.id);
      return;
    }

    if (!annotationMode) return;
    isMouseDown = true;

    if (!(currentPage in strokes)) {
      strokes[currentPage] = [];
    }

    const groupId = uuid();
    const baseStroke = {
      id: groupId,
      color: '#FF0000',
      opacity: 1,
      width: 5 / SCALE,
      points: [
        strokeObject({
          x,
          y,
          groupId,
          containerX,
          containerY,
          rotation,
        }),
      ],
      type: annotationMode,
    };

    switch (annotationMode) {
      case 'pencil': {
        strokes[currentPage].push(baseStroke);
        break;
      }
      case 'highlight': {
        strokes[currentPage].push({
          ...baseStroke,
          ...defaultHightlightAnnotationStyles,
        });
        break;
      }
      case 'text': {
        strokes[currentPage].push({
          ...baseStroke,
          text: 'Insert Text Here',
          fontSize: 12,
        });
        break;
      }
      default:
    }

    PDFContext.beginPath();
    PDFContext.moveTo(x, y);
    e.preventDefault();
    setStrokes({ ...strokes });
    draw(currentPage);
    setSelectedAnnotation(groupId);
  }, [
    annotationMode,
    PDFContext,
    strokes,
    currentPage,
    rotation,
    getCoords,
    getClickedAnnotation,
  ]);

  const onMouseMove = (e) => {
    if (!isMouseDown || !selectedAnnotation) return;
    e.preventDefault();
    processTouchEvents(e);
  };

  const resizeCanvas = ({ viewport, canvas }) => {
    const isLandscape = rotation === 90 || rotation === 270;
    let vHeight = viewport.height / SCALE;
    let vWidth = viewport.width / SCALE;
    const aspect = vHeight / vWidth;

    const windowHeight = window.outerHeight - 200;

    const hExcess = vHeight ? (vHeight - windowHeight) / vHeight : 0;
    const wExcess = vWidth ? (vWidth - window.outerWidth) / vWidth : 0;

    if (hExcess && hExcess > wExcess) {
      setScaleY(viewport.height / windowHeight);
      vHeight = windowHeight;
      const newWidth = vHeight / aspect;
      setScaleX(viewport.width / newWidth);
      vWidth = newWidth;
    } else if (wExcess && wExcess > hExcess) {
      setScaleX(viewport.width / window.outerWidth);
      vWidth = window.outerWidth;
      const newHeight = (vWidth * aspect);
      setScaleY(viewport.height / newHeight);
      vHeight = newHeight;
    }

    canvas.style.margin = 'auto';
    canvas.style.height = '100%';
    canvas.style.width = 'auto';

    const annotationCanvas = document.getElementById('annotation-canvas');
    annotationCanvas.style.margin = 'auto';
    annotationCanvas.style.height = '100%';
    annotationCanvas.style.width = 'auto';

    const canvasContainer = document.getElementById('canvas-container');
    canvasContainer.style.height = `${vHeight}px`;
    canvasContainer.style.width = `${vWidth}px`;

    const wrapper = document.getElementById('pdf-wrapper');
    wrapper.style.height = `${isLandscape ? vWidth : vHeight}px`;
    wrapper.style.width = `${isLandscape ? vHeight : vWidth}px`;
  };

  const renderPage = async () => {
    setLoading(true);
    const page = await PDF.getPage(currentPage);
    const canvas = document.getElementById('pdf-canvas');

    const viewport = page.getViewport({ scale: SCALE });
    const canvasContainer = document.getElementById('canvas-container');
    canvasContainer.style.transform = `rotate(${rotation}deg)`;
    resizeCanvas({ canvas, viewport });
    setLoading(false);

    // Clear annotation canvas
    clearAnnotationCanvas();

    // Draw strokes onto annotation canvas
    draw(currentPage);
  };

  const onUndo = useCallback(async () => {
    if (
      !canvasRef
      || !canvasRef.current
      || !(currentPage in strokes)
      || strokes[currentPage].length === 0
    ) return;
    strokes[currentPage].pop();

    // Empty annotationCanvas
    clearAnnotationCanvas();

    // Redraw strokes
    draw(currentPage);
  }, [canvasRef, strokes, currentPage, draw]);

  // This is a hack to fix a bug where the canvas doesn't resize properly on zoom
  const recalculateCSSLayout = () => {
    document.body.style.zoom = 1.0000001;
    setTimeout(() => {
      document.body.style.zoom = 1;
    }, 50);
  };

  useEffect(() => {
    if (PDF) renderPage();
    recalculateCSSLayout();
  }, [rotation, PDF, currentPage, zoom]);

  useEffect(() => {
    if (!canvasRef?.current) return;
    canvasRef.current.removeEventListener('mousedown', eventListeners.down);
    canvasRef.current.removeEventListener('mousemove', eventListeners.move);
    const down = (e) => {
      onMouseDown(e);
    };
    const move = (e) => {
      onMouseMove(e);
    };

    const onMouseUp = () => {
      isMouseDown = false;
      setIsStyleDrawerOpen(true);
      clearAnnotationCanvas();
      draw(currentPage);
    };

    canvasRef.current.addEventListener('mousedown', down, false);
    canvasRef.current.addEventListener('mousemove', move, false);
    canvasRef.current.addEventListener('mouseup', onMouseUp, false);
    setEventListeners({
      move, down,
    });

    // eslint-disable-next-line consistent-return
    return () => {
      canvasRef.current?.removeEventListener('mousedown', down);
      canvasRef.current?.removeEventListener('mousemove', move);
      canvasRef.current?.removeEventListener('mouseup', onMouseUp);
    };
  }, [canvasRef, onMouseDown, strokes, draw, currentPage]);

  const renderPDF = useCallback(async () => {
    const viewport = currentPDFPage.getViewport({ scale: SCALE });
    const mainCanvas = document.getElementById('pdf-canvas');
    const context = mainCanvas.getContext('2d');

    context.clearRect(0, 0, mainCanvas.width, mainCanvas.height);
    mainCanvas.width = viewport.width;
    mainCanvas.height = viewport.height;

    renderContext = {
      canvasContext: context,
      viewport,
    };

    await currentPDFPage.render(renderContext).promise;
    resizeCanvas({ viewport, canvas: mainCanvas });
  }, [PDF, currentPage]);

  const setPage = useCallback(async (num) => {
    if (!PDF || !canvasRef || !canvasRef.current) return;
    setLoading(true);
    currentPDFPage = await PDF.getPage(num);
    const viewport = currentPDFPage.getViewport({ scale: SCALE });

    // Resize annotation canvas
    const annotationCanvas = document.getElementById('annotation-canvas');
    annotationCanvas.height = viewport.height;
    annotationCanvas.width = viewport.width;

    // Clear annotation canvas
    clearAnnotationCanvas();

    // render PDF
    await renderPDF();

    // Set current page
    setCurrentPage(num);

    // Draw strokes onto annotation canvas
    draw(num);

    setLoading(false);
  }, [PDF, canvasRef, currentPage]);

  useEffect(() => {
    const initialLoad = async () => {
      setNumPages(PDF.numPages);

      const page = await PDF.getPage(1);
      const viewport = page.getViewport({ scale: SCALE });
      const canvas = document.getElementById('pdf-canvas');
      const context = canvas.getContext('2d');

      canvas.height = viewport.height;
      canvas.width = viewport.width;
      setPDFContext(context);
      setPage(1);
      renderPage();
    };
    if (PDF) initialLoad();
  }, [PDF]);

  useEffect(() => {
    const getPDF = async () => {
      const pdfRef = await pdfjsLib.getDocument(url || URL.createObjectURL(file)).promise;
      setPdfRef(pdfRef);
    };
    if (url || file) {
      getPDF();
    } else {
      setPdfRef();
    }
  }, [url, file]);

  const onAnnotationModeChanged = (newAnnotationMode) => {
    setAnnotationMode(newAnnotationMode);
    Analytics.track(`Files/Annotate/${newAnnotationMode}`);
  };

  const annotationToUpdate = useMemo(() => {
    if (!selectedAnnotation) return null;
    return strokeMap[selectedAnnotation];
  }, [selectedAnnotation, strokeMap]);

  const updateAnnotationStyle = useCallback((newStyle) => {
    const stroke = strokeMap[selectedAnnotation];
    Object.keys(newStyle).forEach((key) => {
      stroke[key] = newStyle[key];
    });

    const newStrokes = {
      ...strokes,
      [currentPage]: strokes[currentPage].map(
        (s) => (s.id === selectedAnnotation ? stroke : s),
      ),
    };

    setStrokes(newStrokes);

    clearAnnotationCanvas();
    draw(currentPage);
  }, [
    strokeMap,
    selectedAnnotation,
    strokes,
    currentPage,
    draw,
  ]);

  const onDelete = useCallback(() => {
    const newStrokes = {
      ...strokes,
      [currentPage]: strokes[currentPage].filter((s) => s.id !== selectedAnnotation),
    };

    setStrokes(newStrokes);
    setSelectedAnnotation();
    clearAnnotationCanvas();
    draw(currentPage);
  }, [selectedAnnotation]);

  const closeStyleDrawer = useCallback(() => {
    setIsStyleDrawerOpen(false);
  }, []);

  const showUndo = strokes[currentPage] && strokes[currentPage].length > 0;
  return (
    <div className={className ? `${className}-pdf-container basic-pdf` : 'pdf-container basic-pdf'} style={style}>
      <div
        id="pdf-wrapper"
        className="pdf-wrapper"
        style={{ transform: `scale(${zoom})`, transformOrigin: zoom > 1 ? 'top left' : 'center' }}
      >
        <div id="canvas-container">
          <canvas
            id="annotation-canvas"
            style={{ position: 'absolute', zIndex: 99 }}
            ref={canvasRef}
          />
          <canvas
            id="pdf-canvas"
            style={canvasStyle}
          />
        </div>
        <PDFAnnotationStyleDrawer
          visible={isStyleDrawerOpen && annotationToUpdate}
          annotation={annotationToUpdate}
          onAnnotationUpdated={updateAnnotationStyle}
          onClose={closeStyleDrawer}
          onDelete={onDelete}
        />
      </div>
      {(!PDF || loading)
        && (
        <div id="pdf-spinner">
          <Spin />
        </div>
        )}
      <PDFPageControls
        numberOfPages={numPages}
        activePage={currentPage}
        onActivePageChange={setPage}
        darkMode={darkMode}
        className={className}
      />
      {!hideControls && (
        <PDFZoomControls
          editable={onSave}
          annotationMode={annotationMode}
          onAnnotationModeChanged={onAnnotationModeChanged}
          zoom={zoom}
          onZoomChanged={setZoom}
          onRotateRight={() => setRotation((rotation + 90) % 360)}
          onRotateLeft={() => setRotation((rotation - 90) % 360)}
          onUndo={showUndo ? onUndo : null}
          onSave={onSave ? saveAnnotations : null}
          className={className}
        />
      )}
    </div>
  );
}
