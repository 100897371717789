import React from 'react';
import WorkflowNotificationNode from './WorkflowNotificationNode';

const HOVER_TEXT = `
Users added to this step will receive a push notification
in the Ontraccr mobile app.
`;

const AUTHOR_HELP = 'An push notification will be sent to the form\'s author';

export default ({ setElements, setDataMap, isDisplay, divisionId, sections = [], isExternalForm, } = {}) => ({
  id,
  draggable,
  data,
}) => (
  <WorkflowNotificationNode
    type='push'
    id={id}
    draggable={draggable}
    data={data}
    onNodeUpdate={setElements}
    setDataMap={setDataMap}
    isDisplay={isDisplay}
    hover={HOVER_TEXT}
    divisionId={divisionId}
    showFormAuthor
    formAuthorHelperText={AUTHOR_HELP}
    sections={sections}
    isExternalForm={isExternalForm}
  />
)