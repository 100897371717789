import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import {
  Breadcrumb,
  Checkbox,
  Row,
  Col,
} from 'antd';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import FilesList from '../files/FilesList';
import FolderAddModal from '../files/FolderAddModal';
import FileAddView from '../files/FileAddView';
import FileMoveDrawer from '../files/FileMoveDrawer';

import {
  addItemsToStructure,
  parseFile,
  deleteItems,
  getFileType,
  getLevelFromPath,
  getPathPrefix,
  constructFullPath,
  downloadFile,
  uploadFile,
} from '../files/fileHelpers';
import FullPhoto from '../common/photos/FullPhoto';
import {
  getFileStructure,
  loadImage,
  uploadFiles,
  renameFolder,
  createFolders,
  moveFiles,
  hideImage,
  deleteItems as deleteItemsAction,
  getFileAccessList,
} from '../files/state/files.actions';
import Analytics from '../helpers/Analytics';
import Permissions from '../auth/Permissions';
import FileDetailDrawer from '../files/FileDetailDrawer';
import FilePermsDrawer from '../files/FilePermsDrawer';
import ProjectAddFileTemplate from './ProjectAddFileTemplate';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { ShareAltOutlined } from '@ant-design/icons';

const now = () => DateTime.local().toMillis();
const constructCrumbs = (crumbs = []) => (
  crumbs.map((crumb, index) => {
    const {
      text,
      icon = '',
      onClick,
      fullPath,
    } = crumb;
    return (
      <Breadcrumb.Item key={index}>
        {onClick && (
          <span
            className='breadcrumb-link'
            onClick={() => onClick(fullPath)}
            style={{ cursor: 'pointer' }}
          >
            {icon}
            {text}
          </span>
        )}
        {!onClick && icon}
        {!onClick && text}
      </Breadcrumb.Item>
    );
  })
);

const EDIT_HEADER_HEIGHT = 80;
const VIEW_HEADER_HEIGHT = 150;

export default function ProjectAddFiles({
  name = '',
  onValuesChanged,
  showFiles = false,
  onShowFilesChanged,
  pathname = '',
  isEdit = false,
  divisionName,
  divisionId,
  showAddFileButton = true,
  showAddFileCheckbox = true,
  defaultFileStructure = null,
  isFromProjectAddFileTemplates = false,
}) {
  const hasWritePerms = !isEdit || Permissions.has('FILES_WRITE');

  const rootPath = pathname;

  const initialCrumbs = [
    { text: 'Files' },
    ...(divisionName ? [{ text: divisionName }] : []),
    { text: 'Projects' },
  ];

  const defaultRootNode = {
    fullPath: rootPath,
    children: [],
    type: 'folder',
  };

  const dispatch = useDispatch();

  const [baseCrumbs, setBaseCrumbs] = useState(initialCrumbs);
  const [fileMap, setFileMap] = useState({ [rootPath]: defaultRootNode });
  const [crumbs, setCrumbs] = useState(initialCrumbs);
  const [showAddFolder, setShowAddFolder] = useState(false);
  const [currentPath, setCurrentPath] = useState(rootPath);
  const [data, setData] = useState([fileMap[rootPath]]);
  const [selectedItems, setSelectedItems] = useState([[], []]);
  const [showUpload, setShowUpload] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [isCopy, setIsCopy] = useState(false);
  const [showFileDrawer, setShowFileDrawer] = useState(false);
  const [selectedPermsFile, setSelectedPermsFile] = useState(false);
  const [selectedFileDetail, setSelectedFileDetail] = useState();
  const [fileDestination, setFileDestination] = useState([]);
  const [prefix, setPrefix] = useState(getPathPrefix(''));
  const [rootNode, setRootNode] = useState(defaultRootNode);
  const [hasPermissions, setHasPermissions] = useState(false);

  const fileStructure = useSelector((state) => state.files.fileStructure);
  const selectedPhoto = useSelector((state) => state.files.selectedPhoto);
  const fileAccessLists = useSelector((state) => state.files.fileAccessLists);
  const company = useSelector((state) => state.settings.company);

  const {
    image: selectedImage = {},
    path: selectedImagePath,
    name: selectedImageName,
  } = selectedPhoto || {};

  const rootFiles = useMemo(() => {
    const rootFileNode = fileMap[rootPath];
    const rootItems = [];
    if (rootFileNode) {
      const { children = [] } = rootFileNode;
      children.forEach((childKey) => {
        const child = fileMap[childKey];
        if (child) {
          rootItems.push({
            ...child,
            fullPath: child.fullPath.replace('/files/', ''),
          });
        }
      });
    }
    return rootItems;
  }, [fileMap, rootPath]);

  const onShowPerms = useCallback((permsFile) => setSelectedPermsFile(permsFile), []);
  const onHidePerms = useCallback(() => setSelectedPermsFile(), []);

  useEffect(() => {
    if (isEdit) {
      dispatch(getFileStructure());
    }
  }, [isEdit]);

  useEffect(() => {
    if (isEdit && rootNode?.id) {
      dispatch(getFileAccessList(rootNode.id));
    }
  }, [isEdit, rootNode]);

  useEffect(() => {
    const {
      [rootNode?.id]: newACL = new Set(),
    } = fileAccessLists;

    if (rootNode.isPublic || newACL.has(Permissions.id) || company?.userId === Permissions.id) {
      setHasPermissions(true);
    }
  }, [fileAccessLists, rootNode]);

  useEffect(() => {
    if (defaultFileStructure) {
      setFileMap(defaultFileStructure);
    }
  }, [defaultFileStructure]);

  useEffect(() => {
    const newCrumbs = [...baseCrumbs];
    // We only want to add crumbs for items that could be clickable
    // Only folders that are the root and beyond can be clickable (project name)
    const basePath = rootPath.replace(`/${name}`, '');

    // These are paths that could be clickable
    const newPath = currentPath.replace(basePath, '');
    const levels = newPath.split('/');
    let path = `${basePath}/`;

    levels.forEach((level, index) => {
      if (!level) return;
      path += level;
      newCrumbs.push({
        text: level,
        fullPath: path,
        onClick: index < levels.length - 1 ? setCurrentPath : null,
      });
      path += '/';
    });
    setCrumbs(newCrumbs);
    setPrefix(getPathPrefix(currentPath.replace(rootPath, '')));
  }, [currentPath, baseCrumbs, rootPath]);

  const addItemsToFileMap = useCallback((items = []) => {
    let updatedFileMap = { ...fileMap };

    items.forEach((item) => {
      const key = `${currentPath}/${item.name}`;
      const { fileStructure: newStruct } = addItemsToStructure({
        fileStructure: updatedFileMap,
        items: [{
          ...item,
          path: currentPath,
          parent: currentPath,
        }],
      });
      newStruct[key].children = [];
      updatedFileMap = newStruct;
    });
    setFileMap(updatedFileMap);
  }, [currentPath, fileMap]);

  useEffect(() => {
    if (!isEdit) {
      const ourFile = fileMap[currentPath];
      const newFiles = [];

      if (!ourFile || !ourFile.children) {
        return;
      }

      ourFile.children.forEach((key) => {
        if (key in fileMap) {
          newFiles.push(parseFile(fileMap[key]));
        }
      });

      setData(newFiles);
    }
  }, [isEdit, fileMap, currentPath]);

  useEffect(() => {
    if (fileStructure && isEdit) {
      const { path } = getLevelFromPath({
        fileStructure,
        pathname: decodeURIComponent(currentPath),
      });
      let fileData = [];
      const filesToAdd = [];

      const {
        [path]: {
          children = [],
          ...rootData
        } = {},
      } = fileStructure;
      fileData = children;

      const newData = [];
      fileData.forEach((child) => {
        if (child in fileStructure) {
          const file = fileStructure[child];
          filesToAdd.push(file);
          newData.push(parseFile(file));
        }
      });
      setData(newData);
      addItemsToFileMap(filesToAdd);
      setRootNode(rootData);
    }
  }, [isEdit, fileStructure, currentPath]);

  const onBack = useCallback(currentPath !== rootPath ? () => {
    const levels = currentPath.split('/');
    levels.pop();
    setCurrentPath(levels.join('/'));
  } : null, [currentPath]);
  const onAddFolder = useCallback(() => setShowAddFolder(true), []);
  const onCreateFolder = useCallback(async (folderName) => {
    if (folderName) {
      const key = `${currentPath}/${folderName}`;
      const newPath = `${key}/`;

      if (isEdit) {
        const prefix = getPathPrefix(currentPath);
        const fullPathName = constructFullPath(prefix, folderName);
        if (!await dispatch(createFolders({ names: [fullPathName] }))) {
          return false;
        }
      }

      const newNode = {
        fullPath: newPath,
        path: newPath,
        parent: currentPath,
        type: 'folder',
        lastUpdated: now(),
      };
      const { fileStructure: newStruct } = addItemsToStructure({
        fileStructure: fileMap,
        items: [newNode],
      });
      newStruct[key].children = [];
      setFileMap(newStruct);
    }
    setShowAddFolder(false);
    return true;
  }, [fileMap, currentPath, isEdit]);

  const showUploadModal = useCallback(() => setShowUpload(true), []);
  const hideUpload = useCallback(() => setShowUpload(false), []);
  const addFile = useCallback((file) => {
    setFiles((oldFiles) => [...oldFiles, file]);
  }, []);

  const removeFile = useCallback((index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  }, [files]);

  const onCreateFiles = useCallback(async (notify) => {
    const currentNode = fileMap[currentPath];
    const newFiles = [];
    const payload = [];
    const fileMetaData = [];

    const prefix = getPathPrefix(currentPath);
    const folderPath = isEdit ? prefix.substring(0, prefix.length - 1) : '';

    files.forEach((file) => {
      const path = `${currentPath}/${file.name}`;
      currentNode.children = (currentNode.children || []).concat([path]);
      newFiles.push({
        name: file.name,
        path: currentPath,
        type: getFileType(file),
        jsFileObject: file,
        timestamp: file.timestamp,
      });

      payload.push({
        jsFileObject: file,
        path: folderPath,
        timestamp: file.timestamp,
      });

      fileMetaData.push({
        type: file.type,
        size: file.size,
      });
    });
    fileMap[currentPath] = { ...currentNode };

    if (isEdit) {
      Analytics.track('Files/UploadFiles', { files: fileMetaData, notify });

      if (!await dispatch(uploadFiles(payload, notify))) {
        return;
      }
    }

    const { fileStructure: newStruct } = addItemsToStructure({
      fileStructure: fileMap,
      items: newFiles,
    });
    setFileMap(newStruct);
    setFiles([]);
    setShowUpload(false);
  }, [fileMap, files, currentPath, isEdit]);

  const onClick = useCallback((selected) => {
    const {
      type, name: selectedName,
    } = selected;

    const fullPath = `${currentPath}/${selectedName}`;

    const encodedPath = `${currentPath}/${encodeURIComponent(selectedName)}`; // for s3 retrieval

    if (type === 'folder') {
      setCurrentPath(fullPath);
    } else {
      const file = fileMap[fullPath];
      if (file) {
        setSelectedFile({
          jsFileObject: file.jsFileObject,
          type: selected.type,
        });

        dispatch(loadImage(encodedPath, type, selectedName, true));
      }
    }
  }, [fileMap, isEdit, currentPath]);

  const onDelete = useCallback(async ({ password }) => {
    if (isEdit) {
      const toBeDeleted = selectedItems[1].map((item) => {
        const {
          type,
          fullPath,
        } = item;

        return {
          name: fullPath + (type === 'folder' ? '/' : ''),
          type,
        };
      });

      if (!await dispatch(deleteItemsAction({ items: toBeDeleted, password }))) {
        return false;
      }
    }

    const toBeDeleted = selectedItems[1].map((item) => {
      const {
        fullPath,
        type,
      } = item;
      return {
        name: fullPath + (type === 'folder' ? '/' : ''),
        type,
      };
    });
    const { fileStructure: newStruct } = deleteItems({
      fileStructure: fileMap,
      deletedItems: toBeDeleted,
    });
    setFileMap(newStruct);
    setSelectedItems([[], []]);
    return true;
  }, [selectedItems, fileMap, isEdit, currentPath]);

  const onShowMoveModal = useCallback(() => {
    setIsCopy(false);
    setShowFileDrawer(true);
  }, []);
  const onShowCopyModal = useCallback(() => {
    setIsCopy(true);
    setShowFileDrawer(true);
  }, []);
  const hideMoveModal = useCallback(() => {
    setFileDestination([]);
    setShowFileDrawer(false);
  }, []);

  const onMoveSubmit = async () => {
    const rootPrefix = getPathPrefix(rootPath);
    const suffix = fileDestination[0] === `${name}/` ? '' : fileDestination[0];
    const destination = `${rootPrefix}${suffix}`;

    if (isEdit) {
      const prefix = getPathPrefix(currentPath);
      const items = selectedItems[1];

      const fullPaths = items.map(({ name: itemName, type, fullPath }) => ({
        fullPath,
        name: itemName,
        type,
      }));

      if (!await dispatch(moveFiles({
        destination,
        source: prefix,
        items: fullPaths,
        copy: isCopy,
      }))) {
        return false;
      }
    }

    const truncatedDestination = suffix.length ? `/${suffix.substring(0, suffix.length - 1)}` : '';
    const ourFiles = selectedItems[1];
    const newFiles = ourFiles;

    const filesToAdd = [];
    const filesToDelete = [];

    // We also have to update the children of any files being moved
    // So we will recursively update the children of any folders
    // Until we have updated all the children
    // This is a costly process if there are a lot of files, but I'm expecting users
    // To not move this lightly (similarily of how it takes a long time in the OS)
    while (newFiles.length) {
      const file = newFiles.pop();
      const { fullPath, parent } = file;
      filesToDelete.push(file);

      const fullFile = fileMap[`${isEdit ? '/files/' : ''}${fullPath}`];
      const newFile = {
        ...fullFile,
        path: `${truncatedDestination}${parent ? parent.replace(currentPath, '') : ''}`,
      };

      if (fullFile.type === 'folder') {
        newFiles.push(...(fullFile.children || []).map((child) => fileMap[child]));
      }
      filesToAdd.push(newFile);
    }

    let { fileStructure: newStruct } = addItemsToStructure({
      fileStructure: fileMap,
      items: filesToAdd,
    });

    if (!isCopy) {
      const toBeDeleted = filesToDelete.map((item) => {
        const {
          fullPath,
          type,
        } = item;
        return {
          name: fullPath + (type === 'folder' ? '/' : ''),
          type,
        };
      });
      const { fileStructure: afterDelete } = deleteItems({
        fileStructure: newStruct,
        deletedItems: toBeDeleted,
      });
      newStruct = afterDelete;
    }

    setFileMap(newStruct);
    setSelectedItems([[], []]);
    hideMoveModal();
    return true;
  };

  useEffect(() => {
    onValuesChanged('fileMap', fileMap);
  }, [fileMap, onValuesChanged]);

  useEffect(() => {
    if (name && !isEdit) {
      const newCrumbs = initialCrumbs.slice(0, initialCrumbs.length).concat([{
        text: name,
        fullPath: rootPath,
        onClick: currentPath !== rootPath ? setCurrentPath : null,
      }]);
      setBaseCrumbs(newCrumbs);
    }
  }, [name, isEdit, currentPath]); // eslint-disable-line
  // (if we add initial crumbs to deps list add folder modal
  // pops up repeatedly)

  const onMoveFolder = async (newName) => {
    const { name: fileName, fullPath } = selectedFileDetail;
    const replaceRegex = new RegExp(`${fileName}$`);
    return dispatch(renameFolder({
      oldName: fullPath,
      newName: fullPath.replace(replaceRegex, newName),
    }));
  };

  const onPhotoClose = useCallback(() => {
    dispatch(hideImage());
    setSelectedFile({});
  }, []);

  const onDownload = useCallback(() => {
    const fullPath = selectedImagePath.replace('/files/', '');
    const fileName = selectedImagePath.split('/').pop();

    downloadFile({
      fileDetails: {
        fullPath,
        name: fileName,
        type: selectedImage.type,
      },
    });
  }, [selectedImagePath, selectedImage]);

  const updateFile = useCallback(async (_, updatedFile) => {
    const result = await uploadFile({
      updatedFile,
      dispatch,
      pathname,
    });

    if (result) onPhotoClose();
  }, [pathname, selectedImageName, onPhotoClose]);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      { !isEdit && showAddFileCheckbox && (
        <Row>
          <Col>
            <Checkbox
              onChange={onShowFilesChanged}
              style={{ marginBottom: 10 }}
              checked={showFiles}
            >
              Add Files?
            </Checkbox>
          </Col>
          <Col flex="auto" />
        </Row>
      )}
      { isEdit && hasPermissions && (
        <Row style={{ float: 'right', paddingRight: 5 }}>
          <OnTraccrButton
            icon={<ShareAltOutlined />}
            title="Manage Permissions"
            onClick={() => setSelectedPermsFile(rootNode)}
          />
        </Row>
      )}
      {showFiles && data.length === 0 && (
        <Row style={{ float: 'right', paddingRight: 5 }}>
          <Col>
            <ProjectAddFileTemplate
              rootPath={rootPath}
              divisionId={divisionId}
              applyTemplate={setFileMap}
              hasFiles={rootFiles.length || isFromProjectAddFileTemplates}
            />
          </Col>
        </Row>
      )}
      {showFiles && (
        <div className="project-files-container">
          <Breadcrumb style={{ fontSize: 14, marginBottom: 10 }}>
            {constructCrumbs(crumbs)}
          </Breadcrumb>
          <FilesList
            data={data}
            hasWritePerms={hasWritePerms && (hasPermissions || !isEdit)}
            onAddFolder={onAddFolder}
            onAddFile={showUploadModal}
            onClick={onClick}
            onDelete={onDelete}
            onMove={onShowMoveModal}
            onCopy={onShowCopyModal}
            onSelect={(...args) => setSelectedItems(args)}
            selected={selectedItems[0]}
            simple={!isEdit}
            promptForPasswordOnDelete={isEdit}
            onBack={onBack}
            onMore={(file) => {
              setSelectedFileDetail(file);
              setSelectedItems([[], []]);
            }}
            onShare={onShowPerms}
            showAddFileButton={showAddFileButton}
            headerOffset={isEdit ? EDIT_HEADER_HEIGHT : VIEW_HEADER_HEIGHT}
          />
          <FolderAddModal
            onClose={onCreateFolder}
            visible={showAddFolder}
          />
          <FileDetailDrawer
            hasWritePerms={hasWritePerms}
            selectedFile={selectedFileDetail}
            onRename={setSelectedFileDetail}
            onClose={() => {
              setSelectedFileDetail();
            }}
            onMoveFolder={onMoveFolder}
          />
          <FileAddView
            visible={showUpload}
            files={files}
            onUploadEnd={hideUpload}
            onAddFile={addFile}
            onFileRemove={removeFile}
            onSave={onCreateFiles}
          />
          <FileMoveDrawer
            visible={showFileDrawer}
            selectedFolders={selectedItems[0]}
            copy={isCopy}
            onClose={hideMoveModal}
            onSelectedDestination={setFileDestination}
            selectedDestination={fileDestination}
            fileStructure={isEdit ? fileStructure : fileMap}
            rootFiles={rootFiles}
            baseName={`${name}/`}
            onSubmit={onMoveSubmit}
            prefixPath={prefix}
          />
          <FilePermsDrawer
            selectedFile={selectedPermsFile}
            onClose={onHidePerms}
          />
          <FullPhoto
            path={selectedImagePath}
            file={selectedFile.jsFileObject}
            url={selectedImage.src}
            type={selectedImage.type || selectedFile.type}
            title={selectedImageName}
            onClose={onPhotoClose}
            onDownload={selectedImagePath ? onDownload : null}
            onSave={updateFile}
            annotatedFileName={selectedImageName || selectedFile?.jsFileObject?.name}
            useApryse
            showMetadata={selectedImagePath}
          />
        </div>
      )}
    </div>
  );
}

ProjectAddFiles.propTypes = {
  name: PropTypes.string,
  onValuesChanged: PropTypes.func.isRequired,
  showFiles: PropTypes.bool,
  onShowFilesChanged: PropTypes.func.isRequired,
  pathname: PropTypes.string,
  isEdit: PropTypes.bool,
  divisionName: PropTypes.string,
  showAddFileButton: PropTypes.bool,
  showAddFileCheckbox: PropTypes.bool,
  divisionId: PropTypes.string,
  defaultFileStructure: PropTypes.shape({}),
  isFromProjectAddFileTemplates: PropTypes.bool,
};

ProjectAddFiles.defaultProps = {
  name: '',
  showFiles: false,
  pathname: '',
  isEdit: false,
  divisionName: null,
  showAddFileButton: true,
  showAddFileCheckbox: true,
  divisionId: null,
  defaultFileStructure: null,
  isFromProjectAddFileTemplates: false,
};
