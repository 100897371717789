import React, { useMemo } from 'react';
import MonthMultiDayEntry from './MonthlySchedule/MonthMultiDayEntry';
import MonthShiftDayEntry from './MonthlySchedule/MonthShiftDayEntry';
import MonthMoreView from './MonthlySchedule/MonthlyMoreView';

import {
  MONTHLY_ENTRY_HEIGHT,
  MONTHLY_HEADER_HEIGHT,
  MONTHLY_ENTRY_PADDING,
} from './schedule.constants';

// Determine shifts to show based off available height of view
const prepareShifts = ({
  multiDay,
  interDay,
  height,
}) => {
  let currentHeight = MONTHLY_HEADER_HEIGHT;

  let i = 0;
  const parsedMulti = [];
  const parsedInter = [];
  let moreCount = 0;

  if (height <= currentHeight) return { parsedMulti, parsedInter, moreCount };

  while (i < multiDay.length && currentHeight <= height) {
    parsedMulti.push(multiDay[i]);
    currentHeight += MONTHLY_ENTRY_HEIGHT;
    i += 1;
  }

  // Too many multi day views, pop latest and replace with "More" view.
  if (currentHeight > height) {
    parsedMulti.pop();
    moreCount = multiDay.length + interDay.length - parsedMulti.length;
    return { parsedMulti, parsedInter, moreCount };
  }

  i = 0;
  while (i < interDay.length && currentHeight <= height) {
    parsedInter.push(interDay[i]);
    currentHeight += MONTHLY_ENTRY_HEIGHT;
    i += 1;
  }

  // Too many inter day views, pop latest and replace with "More" view.
  if (currentHeight > height) {
    parsedInter.pop();
  }
  moreCount = multiDay.length + interDay.length - parsedMulti.length - parsedInter.length;
  return { parsedMulti, parsedInter, moreCount };
};

export default function ScheduleCalendarDay({
  user,
  day,
  endDate,
  weekStart,
  height,
  onShiftSelect,
  multiDay = [],
  interDay = [],
}) {
  const sortedMulti = useMemo(() => (
    [...multiDay].sort((a, b) => (b.endTime - b.startTime) - (a.endTime - a.startTime))
  ), [multiDay]);

  const { parsedMulti, parsedInter, moreCount } = useMemo(() => prepareShifts({
    multiDay: sortedMulti,
    interDay,
    height: height - MONTHLY_ENTRY_PADDING,
  }), [sortedMulti, interDay, height]);

  return (
    <>
      {
        parsedMulti.map((shift) => (
          <MonthMultiDayEntry
            user={user}
            key={shift.id}
            shift={shift}
            day={day}
            onClick={onShiftSelect}
            weekStart={weekStart}
            endDate={endDate}
          />
        ))
      }
      {
        parsedInter.map((shift) => (
          <MonthShiftDayEntry
            user={user}
            key={shift.id}
            shift={shift}
            onClick={onShiftSelect}
          />
        ))
      }
      <MonthMoreView
        user={user}
        day={day}
        moreCount={moreCount}
        multiDayShifts={sortedMulti}
        interDayShifts={interDay}
        onShiftSelect={onShiftSelect}
      />
    </>
  );
}
