import React, { useCallback, useEffect, useState } from 'react';
import { Row, PageHeader, Col, Typography, Spin } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import qs from 'query-string';

import PasswordResetForm from './PasswordResetForm';

import colors from '../constants/Colors';
import LoginFactory from './login/login.factory';

import logo from '../assets/images/Ontraccr-Logo-B-1400x400.png';
const logoAspect = 3.10;
const logoHeight = 100;

const {
  Text,
} = Typography;

const DEFAULT_SETTING = {
  primaryColor: colors.MAIN_BACKGROUND,
  accentColor: colors.ONTRACCR_RED,
  image: logo,
};

export default (props) => {
  const {
    history,
    location:{
      search:resetKey,
    } = {},
    match: {
      params: {
        url,
      } = {},
    } = {},
    hideBack = false,
  } = props;

  const {
    token:ourKey,
  } = qs.parse(resetKey);
  const [showReset,setShowReset] = useState(false);
  const [settings, setSettings] = useState({
    primaryColor: 'white',
    accentColor: 'black',
  });

  const onBack = useCallback(() => {
    history.replace(url ? `/${url}` : '/login')
  },[history, url]);

  useEffect(() => {
    const validateReset = async () => {
      if(ourKey && ourKey.length > 0) {
        const { data } = await LoginFactory.validateToken(ourKey, url);
        if(!data) {
          history.replace(url ? `/${url}` : '/login');
        } else {
          setShowReset(true);
          setSettings(url ? data : DEFAULT_SETTING);
        }
      }
    };
    validateReset();
  }, [ourKey, history, url]);


const backColor = url ? settings.accentColor : 'black';

return (
  <div id='login' style={{
    backgroundColor: settings.primaryColor,
  }}> 
    <Row style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex:0 }} type="flex" justify="center" align="middle">
      {showReset ? <Col style={{
        backgroundColor: 'white',
        borderRadius: 30,
        borderWidth:1,
        borderStyle:'solid',
        padding: '20px 40px',
        maxWidth:'75%'
        }}>
        <Row style={{ width: '100%', color:'white' }} justify='center'>
          <Text style={{
            fontFamily:'roboto-regular',
            fontSize:20,
            color:'black',
            marginBottom:40,
            textAlign: 'center'
          }}>
            {`Reset your${url ? '' : ' OnTraccr'} Password`}
          </Text>
        </Row>
        <Row style={{ width: '100%', color:'white' }} justify='center'>
          <PasswordResetForm 
            buttonColor={settings.accentColor}
            handleSubmit={async ({
              password,
            }) => {
              const { data } = await LoginFactory.resetPassword({
                token:ourKey,
                password,
                url,
              });
              if(data) {
                history.replace(url ? `/${url}` : '/login');
              }
            }}
          />
        </Row>

      </Col> : <Spin />
      }
    </Row>
    {hideBack ? 
      <Row style={{ width: '100%'}} justify='center'>
        {settings.image && <img 
          alt='Logo'
          src={settings.image} 
          style={{
            borderRadius:5,
            padding:6,
            marginBottom:30,
            marginTop: 50,
            ...url
            ? {
              maxHeight: logoHeight,
              objectFit: 'contain',
              backgroundColor:'white',
            }
            : {
              width:logoHeight * logoAspect,
              height:logoHeight,
            }
        }}/>}
      </Row> :
    
    <PageHeader
      className='register-back'
      onBack={onBack}
      backIcon={<ArrowLeftOutlined style={{ color: backColor }} />}
      title={<span  style={{ color: backColor }}>Back to Login</span>}
      style={{
        position: 'absolute',
        top: 0,
      }}
    />}
    
    </div>
  );
}