import React, { useEffect, useState } from 'react'; 
import axios from 'axios';
import publicIp from 'public-ip';
import qs from 'query-string';

import Logo from '../../common/Logo';
import GetStartedMain from './GetStartedMain';
import EmployeeSignup from './EmployeeSignup';
import AdminSignup from './AdminSignup';

import background from '../../assets/images/construction-wallpaper-wide.jpg';

import LoginFactory from '../login/login.factory';
import { request } from '../../helpers/requests';
import analytics from '../../helpers/Analytics';

const getLeft = ({ showEmployee, showAdmin }) => {
  if(showEmployee) return '100vw';
  if(showAdmin) return '-100vw';
  return 0;
}

const ADMIN_PATH = '/register/admin';
const EMPLOYEE_PATH = '/register/employee';
const REG_PATH = '/register';

export default ({
  onBack,
  history,
  pathname,
  search,
  show,
}) => {
  const [showEmployee,setShowEmployee] = useState(pathname === EMPLOYEE_PATH);
  const [showAdmin,setShowAdmin] = useState(pathname === ADMIN_PATH);
  const [referrer,setReferrer] = useState();

  useEffect(()=>{
    (async () => {
      try {
        const ip = await publicIp.v4();
        fetch(`https://ipapi.co/${ip}/country/`)
        .then((response) => {
          return response.text();
        })
        .then((country) => {
          window.sessionStorage.setItem('countryCode', country);
        })
        .catch(() => {
          window.sessionStorage.setItem('countryCode', 'US');
        });
      } catch (error) {
        window.sessionStorage.setItem('countryCode', 'US');
      }
      
    })();
  },[])

  useEffect(() => {
    if(search) {
      const { source, referrer: qsReferrer } = qs.parse(search);
      setReferrer(qsReferrer);
      if(source) {
        analytics.track('GetStarted/RegisterPage',{ Source:source });
      }
    }
  },[search]);

  useEffect(() => {
    if(pathname === '/login') return;
    if(showAdmin) {
      history.replace(ADMIN_PATH);
    } else if(showEmployee) {
      history.replace(EMPLOYEE_PATH);
    } else {
      history.replace(REG_PATH);
    }
  },[showAdmin, showEmployee, history, pathname]);
  return (
    <div
      className='get-started-container'
      style={{
        top: show ? 0 : window.innerHeight,
      }}
    >
      <img 
        alt='OnTraccr Get Started'
        src={background} 
        style={{
          minWidth:'100vw',
          minHeight:'100vh',
          objectFit:'cover',
          backgroundColor:'#FFDC73B3',
      }}>
      </img>
      <div
        style={{
          position:'absolute',
          backgroundColor:'#FFDC73B3',
          top:0,
          left:0,
          right:0,
          bottom:0,
        }}
      />
      <Logo style={{ 
        position:'absolute',
        top: 20,
        left: 20,
        transition:'left 0.5s'
      }}/>
    
      <GetStartedMain
        onBack={onBack}
        style={{
          position:'absolute',
          top:0,
          left:getLeft({ showEmployee, showAdmin }),
          width:'100vw',
          bottom:0,
          transition:'left 0.5s'
        }}
        onEmployeeClick={() => {
          analytics.track('GetStarted/EmployeeSignup');
          setShowEmployee(true);
        }}
        onAdminClick={() => {
          analytics.track('GetStarted/AdminSignup1');
          setShowAdmin(true);
        }}
      />
      <EmployeeSignup
        onLogin={() => {
          setShowEmployee(false);
          setTimeout(onBack,450);
        }}
        onSignUp={() => {
          analytics.track('GetStarted/AdminSignup1');
          setShowEmployee(false);
          setShowAdmin(true);
        }}
        onBack={() => {
          analytics.track('GetStarted/RegisterPage');
          setShowEmployee(false);
        }}
        style={{
          position:'absolute',
          top:0,
          left:showEmployee ? 0 : '-100vw',
          width:'100vw',
          bottom:0,
          transition:'left 0.5s'
        }}
      />
      <AdminSignup
        onLogin={() => {
          setShowAdmin(false);
          setTimeout(onBack,450);
        }}
        onCreate={async ({
          companyName,
          firstName,
          lastName,
          username,
          email,
          password,
          companySize,
          companyTypes,
          industry,
          code,
        }) => {
          const country = window.sessionStorage.getItem('countryCode') || 'US';
          const payload = {
            name:companyName,
            industry,
            companySize:parseInt(companySize),
            companyTypes,
            code,
            admin:{
              username,
              email,
              password,
              name: `${firstName} ${lastName}`,
            },
            country,
            referrer,
          };
          const { data } = await request({
            call:axios.post('/company',payload),
            successMsg:'Account Successfully Created!',
            errMsg:'Account Creation Failed. Company name and username must be unique.'
          });
          if(data) {
            analytics.track('GetStarted/AdminSignupComplete');
            const loggedIn = await LoginFactory.login(username,password);
            if (loggedIn) {
              history.push('/dashboard');
            }
          }
        }}
        onBack={() => {
          analytics.track('GetStarted/RegisterPage');
          setShowAdmin(false);
        }}
        style={{
          position:'absolute',
          top:0,
          left:showAdmin ? 0 : '100vw',
          width:'100vw',
          bottom:0,
          transition:'left 0.5s'
        }}
      />
    </div>
  );
}
