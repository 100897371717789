import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { DatePicker, Form } from 'antd';
import moment from 'moment';
import DisplayText from '../text/DisplayText';

const formStyle = { marginBottom:0, paddingBottom:-8 };
const formLabelStyle = {
  style:{
    paddingBottom:5,
    marginTop:10,
  },
};

/** A simple date picker */
const OnTraccrSimpleDatePicker = ({
  isNotDisplay,
  label,
  name,
  value,
  rules = [],
  allowFutureDates,
}) => {

  const disabledDatesHandler = useCallback((current) => current > moment().endOf('day'), [allowFutureDates]);

  return (
    <Form.Item
      name={name}
      label={label}
      style={formStyle}
      labelCol={formLabelStyle}
      rules={rules}
    >
      {isNotDisplay ? 
        <DatePicker
          style={{ width: 250 }}
          format='MMM Do, YY'
          disabledDate={allowFutureDates ?  null : disabledDatesHandler}
        />
        :
        <DisplayText title={value} />}
    </Form.Item>
  );
};

OnTraccrSimpleDatePicker.propTypes = {
  isNotDisplay: PropTypes.bool.isRequired,
  allowFutureDates: PropTypes.bool,
};

export default OnTraccrSimpleDatePicker;
