import React, { useState, useEffect } from 'react';
import { Row, Col, Checkbox, Tooltip } from 'antd';

const CheckRow = ({
  children,
  style = {},
  childStyle = {},
  disabled,
  onCheckChange,
  onMouseEnter,
  onMouseLeave,
  darkStyle,
}) => (
  <Row gutter={6} align='top' style={{ height:22, margin:0, ...style}} justify='start'>
    <Col style={{ height: 20, lineHeight:'16px' }}>
      <Checkbox
        disabled={disabled}
        onMouseEnter={() => {
          if(disabled && onMouseEnter) onMouseEnter();
        }}
        onMouseLeave={() => {
          if(disabled && onMouseLeave) onMouseLeave();
        }}
        onChange={(e) => {
          const {
            target:{
              checked,
            } = {},
          } = e;
          onCheckChange(checked);
        }}
      />
    </Col>
    <Col style={{ color: darkStyle ? 'white' : 'black', fontSize: 12, lineHeight:'22px',...childStyle }}>
      {children}
    </Col>
</Row>
)

const TCRow = ({ 
  title, 
  link, 
  style = {},
  childStyle = {},
  onLinkClick,
  onCheckChange,
  disabled,
  darkStyle,
}) => {
  const [showTooltip,setShowTooltip] = useState(false);

  useEffect(() => {
    if(showTooltip) {
      setTimeout(() => setShowTooltip(false), 2000);
    }
  }, [ showTooltip ]);
  return (
    <CheckRow 
      darkStyle={darkStyle}
      style={style}
      childStyle={childStyle}
      disabled={disabled}
      onCheckChange={onCheckChange}
      onMouseEnter={() => setShowTooltip(true)}
      onMouseLeave={() => setShowTooltip(false)}
    >
      <div>I agree to OnTraccr's 
        <Tooltip
          visible={disabled && showTooltip}
          color={darkStyle ? 'white' : 'black'}
          title={`Please view our ${title} by clicking here`}
          overlayInnerStyle={{ color: darkStyle ? 'black' : 'white'}}
        >
            <a 
              rel='noopener noreferrer'
              onClick={onLinkClick}
              target='_blank'
              href={link}>
              {` ${title}`}
            </a>
          </Tooltip>
        </div>
    </CheckRow>
  );
};

export default ({
  setAcceptedToS,
  setAcceptedPrivacy,
  darkStyle = false,
  style = {},
}) => {
  const [viewedToS,setViewedToS] = useState(true); // Change to false to force user to click link
  const [viewedPrivacy,setViewedPrivacy] = useState(true);

  return (
    <div style={style}>
      <TCRow 
        darkStyle={darkStyle}
        disabled={!viewedToS}
        onLinkClick={() => setViewedToS(true)}
        onCheckChange={setAcceptedToS}
        title='Terms of Service'
        link='https://ontraccr-public.s3-us-west-2.amazonaws.com/OnTraccr+-+Terms+of+Service+Final+Version+Jan+2021.pdf'
      />
      <TCRow 
        darkStyle={darkStyle}
        disabled={!viewedPrivacy}
        onLinkClick={() => setViewedPrivacy(true)}
        onCheckChange={setAcceptedPrivacy}
        style={{ marginTop: 5 }}
        title='Privacy Policy'
        link='https://ontraccr-public.s3-us-west-2.amazonaws.com/OnTraccr+-+Privacy+Statement+Final+Version+Jan+2021.pdf'
      />
    </div>
  );
}
