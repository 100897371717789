/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
  useMemo,
} from 'react';
import { Row, Col } from 'antd';
import { BranchesOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import { getType } from './workflowHelpers';
import FieldSelector from './selectors/FieldSelector';

const HOVER_TEXT = `
Based on the answer to the selected field, the form will be routed to the next step.
Selecting N/A or leaving fields empty will result in the form not being routed to the next step.
`;

const DISABLED_TEXT = 'Add at least one Yes/No field to the form to use this action.';

export default function WorkflowLogicalYesNoNode({
  setElements,
  setDataMap,
  isDisplay,
  sections = [],
} = {}) {
  // eslint-disable-next-line
  return ({
    id,
    draggable,
    disabled,
    data = {},
  }) => {
    const {
      field: initialSelected = '',
    } = data;
    const [selected, setSelected] = useState(initialSelected);

    const yesNoFields = useMemo(() => {
      const fields = [];

      sections.forEach(({ fields: sectionFields = [], name }) => {
        sectionFields.forEach((field) => {
          const type = getType(field);
          if (type === 'yes-no') {
            fields.push({
              id: field.id,
              label: `${name}: ${field.configProps.title}`,
            });
          }
        });
      });

      return fields;
    }, [sections]);

    const onChange = useCallback((newSelected) => {
      setDataMap((dataMap) => ({
        ...dataMap,
        [id]: { field: newSelected },
      }));
      setSelected(newSelected);
    }, [id]);

    return (
      <WorkflowActionNode
        id={id}
        title={`Logical Yes/No ${disabled ? ' - DISABLED' : ''}`}
        Icon={BranchesOutlined}
        type="logicalYesNo"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
        className="workflow-logic-node"
      >
        {!draggable && (
        <div>
          <FieldSelector
            disabled={isDisplay}
            onChange={onChange}
            fields={yesNoFields}
            selected={selected}
          />
          <Row justify="space-between" style={{ marginBottom: 5, marginLeft: 20, marginRight: 20 }}>
            <Col>
              Yes
            </Col>
            <Col>
              No
            </Col>
          </Row>

          <WorkflowHandle type="target" position="top" disabled={isDisplay} />

          <WorkflowHandle
            type="source"
            position="bottom"
            style={{ left: '20%' }}
            id={`${id}-logicalYes`}
            disabled={isDisplay}
          />
          <WorkflowHandle
            type="source"
            position="bottom"
            style={{ left: '80%' }}
            id={`${id}-logicalNo`}
            disabled={isDisplay}
          />
        </div>
        )}
      </WorkflowActionNode>
    );
  };
}
