import { React, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Form } from 'antd';
import PropTypes from 'prop-types';
import AddDrawer from '../../common/addDrawer';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import CostCodeUploadList from '../../costcodes/costcodeUploadList';

function ProjectAddPhaseCodeDrawer({
  visible,
  onDrawerClosed,
  costcodes,
  onSelectedCostCodesChanged,
  selectedPhase = {},
}) {
  const {
    name: phaseName,
    description: phaseDescription,
    costcodes: selectedCostCodes = [],
  } = selectedPhase;

  const categories = useSelector((state) => state.costcodes.categories);
  const formView = useCallback((props) => (
    <Form
      ref={props.formRef}
      initialValues={{
        phaseName,
        description: phaseDescription,
      }}
    >
      <Form.Item
        name="phaseName"
        label="Phase"
      >
        <OnTraccrTextInput />
      </Form.Item>
      <Form.Item
        name="description"
        label="Description"
      >
        <OnTraccrTextInput />
      </Form.Item>

      <CostCodeUploadList
        selectable
        categories={categories}
        dataSource={costcodes}
        defaultSelected={selectedCostCodes.map((cc) => cc.id)}
        onSelectedChanged={onSelectedCostCodesChanged}
      />
    </Form>
  ), [
    costcodes,
    selectedCostCodes,
    onSelectedCostCodesChanged,
    phaseName,
    phaseDescription,
    categories,
  ]);

  return (
    <AddDrawer
      onClose={onDrawerClosed}
      visible={visible}
      title={`Add Cost Codes to Phase ${phaseName || ''}`}
      isAdd
      formView={formView}
      width={750}
      key={phaseName} // Fresh component every time
    />
  );
}

ProjectAddPhaseCodeDrawer.propTypes = {
  visible: PropTypes.bool,
  onDrawerClosed: PropTypes.func.isRequired,
  costcodes: PropTypes.array.isRequired,
  onSelectedCostCodesChanged: PropTypes.func.isRequired,
  selectedPhase: PropTypes.object,
};

ProjectAddPhaseCodeDrawer.defaultProps = {
  selectedPhase: {},
  visible: false,
};

export default ProjectAddPhaseCodeDrawer;
