import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

import DisplayText from '../common/text/DisplayText';
import { SUBTASK_CATEGORIES } from '../helpers/subtasks.constants';
import { getBoardDetails } from '../boards/state/boards.actions';

export default function SubtaskLinkSelector({
  subtask,
  onChange,
  isDisplay,
}) {
  const {
    cardId,
    equipmentId,
    cardTitle,
    equipmentTitle,
  } = subtask;

  const dispatch = useDispatch();

  const [selectedType, setSelectedType] = useState(SUBTASK_CATEGORIES.GENERAL);
  const [selectedLink, setSelectedLink] = useState();
  const [selectedBoard, setSelectedBoard] = useState();

  const equipment = useSelector((state) => state.equipment.equipment);
  const boards = useSelector((state) => state.boards.boards);
  const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);

  useEffect(() => {
    if (cardId) {
      setSelectedType(SUBTASK_CATEGORIES.CARD);
      setSelectedLink(cardId);
    } else if (equipmentId) {
      setSelectedType(SUBTASK_CATEGORIES.EQUIPMENT);
      setSelectedLink(equipmentId);
    } else {
      setSelectedType(SUBTASK_CATEGORIES.GENERAL);
      setSelectedLink();
    }
  }, [subtask]);

  useEffect(() => {
    if (isDisplay || !selectedBoard || boardDetailsMap[selectedBoard]) {
      return;
    }

    dispatch(getBoardDetails(selectedBoard, false));
  }, [selectedBoard, boardDetailsMap]);

  const categoryOptions = Object.values(SUBTASK_CATEGORIES).map((category) => ({
    value: category,
    label: category,
  }));

  const equipmentOptions = useMemo(() => (
    Object.values(equipment).map((eq) => ({
      value: eq.id,
      label: eq.name,
    }))
  ), [equipment]);

  const boardOptions = useMemo(() => (
    Object.values(boards).map((board) => ({
      value: board.id,
      label: board.title,
    }))
  ), [boards]);

  const cardOptions = useMemo(() => (
    boardDetailsMap[selectedBoard]?.cards.map((card) => ({
      value: card.id,
      label: card.title,
    })) || []
  ), [selectedBoard, boardDetailsMap]);

  const onTypeChange = (newValue) => {
    setSelectedType(newValue);
    setSelectedLink();
    onChange();
  };

  const onBoardChange = (newValue) => {
    setSelectedBoard(newValue);
    setSelectedLink();
  };

  const onEquipmentChange = (newValue) => {
    setSelectedLink(newValue);
    onChange(SUBTASK_CATEGORIES.EQUIPMENT, newValue);
  };

  const onCardChange = (newValue) => {
    setSelectedLink(newValue);
    onChange(SUBTASK_CATEGORIES.CARD, newValue);
  };

  const body = (isDisplay || cardTitle || equipmentTitle)
    ? (
      <DisplayText title={cardTitle || equipmentTitle || 'General'} />
    ) : (
      <>
        <Select
          options={categoryOptions}
          value={selectedType}
          onChange={onTypeChange}
        />
        {selectedType === SUBTASK_CATEGORIES.CARD && (
          <>
            <Select
              style={{ paddingTop: 5 }}
              options={boardOptions}
              value={selectedBoard}
              onChange={onBoardChange}
            />
            { !!selectedBoard && (
              <Select
                style={{ paddingTop: 5 }}
                options={cardOptions}
                value={selectedLink}
                onChange={onCardChange}
              />
            )}
          </>
        )}
        { selectedType === SUBTASK_CATEGORIES.EQUIPMENT && (
          <Select
            style={{ paddingTop: 5 }}
            options={equipmentOptions}
            value={selectedLink}
            onChange={onEquipmentChange}
          />
        )}
      </>
    );

  return (
    <>
      <Form.Item label="Equipment" name="equipmentId" style={{ display: 'none' }} />
      <Form.Item label="Card" name="cardId" style={{ display: 'none' }} />
      <Form.Item
        label="Link"
        name="linkType"
      >
        {body}
      </Form.Item>
    </>
  );
};

SubtaskLinkSelector.propTypes = {
  subtask: PropTypes.shape({
    cardId: PropTypes.string,
    equipmentId: PropTypes.string,
    cardTitle: PropTypes.string,
    equipmentTitle: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool.isRequired,
};
