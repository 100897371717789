import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import CustomModal from './CustomModal';
import OnTraccrButton from '../buttons/OnTraccrButton';
import DisplayText from '../text/DisplayText';

import colors from '../../constants/Colors';

export default function SimpleFormModal({
  title,
  visible,
  onClose,
  onSave,
  errorText,
  disabled,
  children,
  width,
}) {
  const [loading,setLoading] = useState(false);
  const onCancel = useCallback(() => {
    onClose();
  },[onClose]);
  const onSaveClicked = useCallback(async () => {
    setLoading(true);
    await onSave()
    setLoading(false);
  },[onSave]);

  return (
    <CustomModal
      title={title}
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
      width={width}
    >
      <Row style={{ margin: '20px 5px' }}>
        {children}
      </Row>
      <Row justify='center' gutter={12} style={{ marginBottom: 10 }}>
        <Col>
          <OnTraccrButton
            type='cancel'
            title='Cancel'
            roundStyle
            onClick={onCancel}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title='Save'
            roundStyle
            disabled={disabled}
            onClick={onSaveClicked}
            loading={loading}
          />
        </Col>
      </Row>
      <Row>
        <DisplayText
          style={{
            color:'red',
            height: errorText ? 'auto': 0,
            opacity: errorText ? 1 : 0,
          }}
          title={errorText}
        />
      </Row>
    </CustomModal>
  );
}

SimpleFormModal.propTypes = {
  title: PropTypes.string.isRequired,
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  errorText: PropTypes.string,
  disabled: PropTypes.bool,
  width: PropTypes.number,
  children: PropTypes.node,
};

SimpleFormModal.defaultProps = {
  visible: false,
  errorText: '',
  disabled: false,
  width: 400,
  children: null,
};
