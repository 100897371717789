import React from 'react';
import { connect } from 'react-redux';
import {
  Row,
  Col,
  Popover,
} from 'antd';

import { startNuxAction, finishNuxAction } from '../nux/state/nux.actions';

import BorderlessButton from '../common/buttons/BorderlessButton';
import colors from '../constants/Colors';

export default connect(
  (state,ownProps) => ownProps,
  {
    startNuxAction,
    finishNuxAction,
  }
)(
  ({
    style = {},
    text,
    visible,
    nextAction,
    placement = 'right',
    children,
    startNuxAction,
    finishNuxAction,
    showGotIt = true,
  }) => (
    <Popover
      overlayStyle={{
        width:300,
        pointerEvents:'none',
        ...style,
      }}
      content={
        <Row align='bottom'>
          <Col span={showGotIt ? 18 : 24} style={{ pointerEvents:'none'}}>
            {text}
          </Col>
          {showGotIt && <Col span={6}>
            <BorderlessButton
              style={{
                fontStyle:'italic',
                fontFamily:'inherit',
                pointerEvents:'auto',
              }}
              title={<span style={{ color: colors.ONTRACCR_RED }}>Got it</span>}
              onClick={(e) => {
                e.stopPropagation();
                if(nextAction) {
                  startNuxAction(nextAction)
                } else {
                  finishNuxAction();
                }
               
              }}
            />
          </Col>}

        </Row>   
      }
      visible={visible}
      placement={placement}
    >
      {children}
    </Popover>
  )
);