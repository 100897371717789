import React from 'react';
import { useTranslation } from 'react-i18next';

import IntegrationProjectSync from '../IntegrationProjectSync';


const getImportHelpText = (t) => (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new {t('Project', { count: 0})} from QuickBooks
    or <b>Link</b> QuickBooks {t('Project', { count: 0})} to existing Ontraccr {t('Project', { count: 0})}
    <br/><br/> <b>Import:</b> {t('Project', { count: 0})} must have an address to be imported into Ontraccr.
    <br/> <b>Link:</b> Select an existing Ontraccr ${t('Project')} to link with the QuickBooks {t('Project', { count: 0})}.
  </div>
);

export default function QuickbooksProjectSync({
  quickBooksProjects,
  onProjectsChanged,
  importProjects,
  divisionFilter,
}) {
  const { t } = useTranslation();
  const importHelpText = getImportHelpText(t);
  return (
    <IntegrationProjectSync
      integrationProjects={quickBooksProjects}
      title="QuickBooks"
      integrationKey="intuitId"
      onProjectsChanged={onProjectsChanged}
      showAutoImport={false}
      importHelpText={importHelpText}
      importProjects={importProjects}
      divisionFilter={divisionFilter}
    />
  );
}
