import React from 'react';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import WarningHeader from '../common/text/WarningHeader';

const bold = (t) => <span style={{ fontFamily: 'roboto-bold'}}>{t}</span>
export default ({
  onOk,
  statusName,
  workflowName,
}) => (
  CustomConfirmModal({
    title: (
      <WarningHeader
        justify='center'
        title='Change card status?'
      />
    ),
    content:(
      <div>
        Warning: Changing the card to {bold(statusName)} will trigger the {bold(workflowName)} workflow.
        <br/>
        <br/>
        The workflow will move this card to a different board.
      </div>
    ),
    onOk,
  })
)
