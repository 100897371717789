import axios from 'axios';
import { request } from '../../helpers/requests';

const SearchService = {
  getQueries: (type) => request({
    call: axios.get('/search/query', { params: { type } }),
    errMsg: 'Could not get saved queries',
    hideSuccessToast: true,
  }),
  createQuery: (payload) => request({
    call: axios.post('/search/query', payload),
    errMsg: 'Could not create query',
  }),
  updateQuery: (id, payload) => request({
    call: axios.put(`/search/query/${id}`, payload),
    errMsg: 'Could not update query',
  }),
  deleteQuery: (id) => request({
    call: axios.delete(`/search/query/${id}`),
    errMsg: 'Could not delete query',
  }),
  searchForms: (payload) => request({
    call: axios.post('/search/forms', payload),
    errMsg: 'Could not retrieve search results',
    hideSuccessToast: true,
  }),
  searchBoards: (payload) => request({
    call: axios.post('/search/boards', payload),
    errMsg: 'Could not retrieve search results',
    hideSuccessToast: true,
  }),
};

export default SearchService;
