import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import axios from 'axios';
import { Button } from 'antd';

import intuitImage from '../../../assets/images/connect_to_quickbooks.png';

const buttonDimensions = {
  height: 36,
  width: 223,
};

function ConnectToQuickbooks() {
  const divisions = useSelector((state) => state.settings.divisions);

  const divisionIds = useMemo(() => Object.keys(divisions), [divisions]);

  const onClick = useCallback(async () => {
    // This component is only used for single division companies
    const { data: uri } = await axios.post('/quickbooks/connect/initiate', {
      divisions: divisionIds,
      defaultDivisionId: divisionIds[0],
    });
    if (uri) window.location.href = uri;
  }, [divisionIds]);
  return (
    <div style={{ height: 100, paddingTop: 30 }}>
      <Button
        style={{
          ...buttonDimensions,
          border: 'none',
          padding: 0,
        }}
        onClick={onClick}
      >
        <img
          alt="QuickBooks"
          src={intuitImage}
          style={buttonDimensions}
        />
      </Button>
    </div>
  );
}

export default ConnectToQuickbooks;
