import React from 'react';
import PropTypes from 'prop-types';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, Select } from 'antd';
import { REMINDER_OPTIONS } from './reminder.constants';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

/** 
 * Dynamic field which allows user to add an arbitrary number of inputs to an Antd Form 
 */
const DynamicRemindersField = ({
  isDisplay = false,
}) => {
  const defaultReminder = REMINDER_OPTIONS.DEFAULT_REMINDER;
  const selectOptions = Object.values(REMINDER_OPTIONS);

  return (
      <Form.List name="reminders">
        {(fields, { add, remove }) => {
            return (
              <>
                {fields.map((field, index) => (
                  <div key={field.key} className='schedule-form-reminder'>
                    <Form.Item
                      {...field}
                      style={{ marginRight: 4 }}
                      label={index === 0 ? 'Reminders' : ''}
                      className='schedule-form-reminder-item'
                      >
                      <Select
                        style={{ width: 200 }}
                        labelInValue
                        defaultValue={defaultReminder}
                        options={selectOptions}
                        disabled={isDisplay}
                      />
                    </Form.Item>
                    { index !== 0 && <MinusCircleOutlined onClick={() => remove(field.name)} /> }
                  </div>
                ))}
                <Form.Item>
                  <OnTraccrButton
                    title='add reminder'
                    onClick={() => add()}
                    style={{ width: 200 }}
                    icon={<PlusOutlined/>}
                  />
                </Form.Item>
              </>
            );
        }}
      </Form.List>
  );
};

DynamicRemindersField.propTypes = {
  isDisplay: PropTypes.bool,
};

export default DynamicRemindersField;