import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select, Tag } from 'antd';

import DisplayText from '../text/DisplayText';

const { Option } = Select;

const DivisionDisplay = ({ divisionId, divisions = {}, displayStyle = {} }) => {
  const {
    [divisionId]: {
      name,
    } = {},
  } = divisions;
  return (
    <DisplayText
      title={name}
      style={{
        marginBottom: 12,
        fontSize: 13,
        verticalAlign:'middle',
        ...displayStyle
      }}
    />
  );
};

export default ({
  divisionId,
  displayMode,
  onChange,
  mode,
  disabled,
  divisions: propDivisions = [],
  displayStyle = {},
  style = {},
  allowClear = true,
  filter,
}) => {

  const divisions = useSelector((state) => state.settings.divisions);
  let divisionList = Object.values(divisions);

  const isMultiMode = propDivisions && (mode === 'tags' || mode === 'multiple'); // mode's val is never 'tags'

  const filteredList = useMemo(() => {
     if (!filter || !(filter instanceof Set)) return divisionList;
     return divisionList.filter((div) => filter.has(div.id));
  }, [filter, divisionList]);

  if (filteredList.length === 0) return null;
  if (filteredList.length === 1 || displayMode) {
    const [{ id: ourId }] = filteredList;
    if(isMultiMode) {
      // Users can be part of multiple divisions
      const hasPropDivs = Array.isArray(propDivisions) && propDivisions.length > 0
      let ourDivs = hasPropDivs ? propDivisions : [ourId];
      if(!displayMode && !hasPropDivs) {
        onChange([filteredList[0].id])
      }
      return (
        <>
          {ourDivs.map((propDivId) => {
            const {
              [propDivId]: {
                name: divisionName,
              } = {}
            } = divisions;
            if(!divisionName) return null;
            return (<Tag>{divisionName}</Tag>)
          })}
        </>
      )
    }
    if(!displayMode && ourId !== divisionId) {
      onChange(ourId)
    }
    return (
      <DivisionDisplay
        divisionId={divisionId || ourId}
        divisions={divisions}
        displayStyle={displayStyle}
      />
    );
  }

  
  return (
    <Select
      onChange={onChange}
      placeholder='Select a division'
      value={isMultiMode ? propDivisions : divisionId}
      allowClear={allowClear}
      showSearch
      filterOption={(input,option) => (
        option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
      )}
      mode={mode}
      style={style}
      disabled={disabled}
    >
      {
        filteredList.map(({ id, name }) => (
          <Option
            key={id}
            title={name}
            value={id}
          >
            {name}
          </Option>
        ))
      }
    </Select>
  );
};
