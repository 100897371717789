import React, { useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Drawer } from 'antd';
import axios from 'axios';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';

import QuickbooksIntegration from './QuickbooksIntegration';

import {
  updateQuickBooksIntegration,
} from '../../state/settings.actions';

function QuickbooksIntegrationDrawer({
  visible,
  onClose,
  selectedIntegration,
}) {
  const dispatch = useDispatch();
  const [integration, setIntegration] = useState(selectedIntegration);

  const onIntegrationChanged = useCallback((newData) => {
    setIntegration({
      ...(integration ?? {}),
      ...newData,
    });
  }, [integration]);

  const onSubmit = useCallback(async () => {
    if (!integration?.id) {
      const { data: uri } = await axios.post('/quickbooks/connect/initiate', integration);
      if (uri) window.location.href = uri;
    } else {
      const { id, ...details } = integration;
      const updated = await dispatch(updateQuickBooksIntegration(id, details));
      if (updated) onClose();
    }
  }, [onClose, integration]);

  useEffect(() => {
    setIntegration(visible ? selectedIntegration : null);
  }, [visible, selectedIntegration]);

  return (
    <Drawer
      visible={visible}
      width={600}
      onClose={onClose}
      getContainer={() => document.body}
      title={
        selectedIntegration
          ? 'Edit QuickBooks Integration'
          : 'Add QuickBooks Integration'
      }
    >
      <QuickbooksIntegration
        visible={visible}
        selectedIntegration={integration}
        onIntegrationChanged={onIntegrationChanged}
        onClose={onClose}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        canSubmit={!!(integration?.divisions?.length && integration?.defaultDivisionId)}
      />
    </Drawer>
  );
}

QuickbooksIntegrationDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  selectedIntegration: PropTypes.shape({
    divisionIds: PropTypes.arrayOf(PropTypes.string),
  }),
};

QuickbooksIntegrationDrawer.defaultProps = {
  selectedIntegration: null,
};

export default QuickbooksIntegrationDrawer;
