import axios from 'axios';
import { request } from '../../helpers/requests';

const TabletService = {
  getAll: async () => request({
    call: axios.get('/tablets'),
    defaultData: [],
    errMsg: 'Failed to get tablets',
    hideSuccessToast: true,
  }),
  create: async (payload) => request({
    call: axios.post('/tablets', payload),
    errMsg: (error) => {
      if (!error?.message) return 'Failed to create tablet';
      const errorMessage = error.message;
      if (errorMessage.includes('already exists')) return errorMessage;
      return 'Failed to create tablet';
    },
  }),

  update: async (id, payload) => request({
    call: axios.put(`/tablets/${id}`, payload),
    errMsg: (error) => {
      if (!error?.message) return 'Failed to update tablet';
      const errorMessage = error.message;
      if (errorMessage.includes('already exists')) return errorMessage;
      return 'Failed to update tablet';
    },
  }),
  delete: async (id) => request({
    call: axios.delete(`/tablets/${id}`),
    errMsg: 'Failed to delete tablet',
  }),
};

export default TabletService;
