import axios from 'axios';
import { request, parseError } from '../../helpers/requests';

import { constructFormPayloadForAPI } from '../../forms/ResponderHelpers';
import { decorateFormWithFiles } from '../../forms/formHelpers';
import { uploadFiles } from '../../helpers/fileHelpers';

const ScheduleService = {
  getSchedule: (query = {}) => request({
    call: axios.get('/schedule', { params: query }),
    errMsg: 'Could not get schedule',
    hideSuccessToast: true,
  }),
  createShift: (payload) => request({
    call: async () => {
      const newPayload = { ...payload };
      const { files } = newPayload;
      delete newPayload.files;
      const { payloadFiles } = await uploadFiles(files);
      newPayload.files = payloadFiles;
      if (payload.formData) {
        newPayload.formData = await constructFormPayloadForAPI({
          form: payload.formData,
          addSectionId: true,
        });
      }
      return axios.post('/schedule', newPayload);
    },
    errMsg: 'Could not create shift',
    hideSuccessToast: true,
  }),
  updateShift: (id, payload) => request({
    call: async () => {
      const newPayload = { ...payload };
      const { filesToAdd } = newPayload;
      delete newPayload.filesToAdd;
      const { payloadFiles } = await uploadFiles(filesToAdd);
      newPayload.filesToAdd = payloadFiles;

      if (payload.formData) {
        newPayload.formData = await constructFormPayloadForAPI({
          form: payload.formData,
          addSectionId: true,
        });
      }

      return axios.put(`/schedule/${id}`, newPayload);
    },
    errMsg: 'Could not update shift',
    hideSuccessToast: true,
  }),
  updateShiftAfterDrag: (id, payload) => request({
    call: async () => axios.put(`/schedule/${id}`, payload),
    errMsg: 'Could not update shift',
    hideSuccessToast: true,
  }),
  deleteShift: (id, isGroup) => request({
    call: axios.delete(`/schedule/${id}`, { params: { isGroup } }),
    errMsg: 'Could not delete shift',
    hideSuccessToast: true,
  }),
  getShiftFormData: (id) => request({
    call: async () => {
      const { data: form, err } = await axios.get(`/schedule/${id}/formData`);
      if (!form) return { err };
      await decorateFormWithFiles(form);
      return { data: form };
    },
    errMsg: 'Could not get shift form data',
    hideSuccessToast: true,
  }),
  getGanttSchedules: () => request({
    call: axios.get('ganttSchedule'),
    errMsg: parseError('Failed to get gantt schedules'),
    hideSuccessToast: true,
  }),
  createGanttSchedule: (payload) => request({
    call: axios.post('ganttSchedule', payload),
    errMsg: parseError('Failed to create gantt schedule'),
  }),
  updateGanttSchedule: (scheduleId, payload) => request({
    call: axios.put(`ganttSchedule/${scheduleId}`, payload),
    errMsg: parseError('Failed to update gantt schedule'),
  }),
  deleteGanttSchedule: (scheduleId) => request({
    call: axios.delete(`ganttSchedule/${scheduleId}`),
    errMsg: parseError('Failed to delete gantt schedule'),
  }),
  getGanttScheduleRow: (rowId) => request({
    call: axios.get(`ganttSchedule/task/${rowId}`),
    errMsg: parseError('Failed to get gantt schedule row'),
    hideSuccessToast: true,
  }),
  getGanttScheduleRows: (scheduleId) => request({
    call: axios.get(`/ganttSchedule/${scheduleId}/task`),
    errMsg: parseError('Failed to get gantt schedule rows'),
    hideSuccessToast: true,
  }),
  addGanttScheduleRow: (scheduleId, payload) => request({
    call: axios.post(`/ganttSchedule/${scheduleId}/task`, payload),
    errMsg: parseError('Failed to add gantt schedule row'),
  }),
  updateGanttScheduleRow: (scheduleId, rowId, payload) => request({
    call: axios.put(`/ganttSchedule/${scheduleId}/task/${rowId}`, payload),
    errMsg: parseError('Failed to update gantt schedule row'),
  }),
  deleteGanttScheduleRow: (scheduleId, rowId) => request({
    call: axios.delete(`/ganttSchedule/${scheduleId}/task/${rowId}`),
    errMsg: parseError('Failed to delete gantt schedule row'),
  }),
  getGanttScheduleSettings: (scheduleId) => request({
    call: axios.get(`/ganttSchedule/${scheduleId}/settings`),
    errMsg: parseError('Failed to get gantt schedule settings'),
    hideSuccessToast: true,
  }),
  updateGanttScheduleSettings: (scheduleId, payload) => request({
    call: axios.post(`/ganttSchedule/${scheduleId}/settings`, payload),
    errMsg: parseError('Failed to update gantt schedule settings'),
  }),
  getGanttScheduleHolidays: (scheduleId) => request({
    call: axios.get(`/ganttSchedule/${scheduleId}/holidays`),
    errMsg: parseError('Failed to get gantt schedule holidays'),
    hideSuccessToast: true,
  }),
  addGanttScheduleHoliday: (scheduleId, payload) => request({
    call: axios.post(`/ganttSchedule/${scheduleId}/holidays`, payload),
    errMsg: parseError('Failed to add gantt schedule holiday'),
  }),
  updateGanttScheduleHoliday: (scheduleId, holidayId, payload) => request({
    call: axios.put(`/ganttSchedule/${scheduleId}/holidays/${holidayId}`, payload),
    errMsg: parseError('Failed to update gantt schedule holiday'),
  }),
  deleteGanttScheduleHoliday: (scheduleId, holidayId) => request({
    call: axios.delete(`/ganttSchedule/${scheduleId}/holidays/${holidayId}`),
    errMsg: parseError('Failed to delete gantt schedule holiday'),
  }),
  importGanttSchedule: (scheduleId, payload) => request({
    call: axios.post(`/ganttSchedule/${scheduleId}/import`, payload),
    errMsg: parseError('Failed to import gantt schedule'),
  }),
  getGanttScheduleLabels: () => request({
    call: axios.get('/ganttSchedule/labels'),
    errMsg: parseError('Failed to get gantt schedule labels'),
    hideSuccessToast: true,
  }),
  getUserGanttScheduleFilterViews: (scheduleId) => request({
    call: axios.get(`/ganttSchedule/${scheduleId}/filters`),
    errMsg: parseError('Failed to get gantt schedule filter views'),
    hideSuccessToast: true,
  }),
  addUserGanttScheduleFilterView: (scheduleId, payload) => request({
    call: axios.post(`/ganttSchedule/${scheduleId}/filters`, payload),
    errMsg: parseError('Failed to add gantt schedule filter view'),
  }),
  updateUserGanttScheduleFilterView: (scheduleId, filterViewId, payload) => request({
    call: axios.put(`/ganttSchedule/${scheduleId}/filters/${filterViewId}`, payload),
    errMsg: parseError('Failed to update gantt schedule filter view'),
  }),
  deleteUserGanttScheduleFilterView: (scheduleId, filterViewId) => request({
    call: axios.delete(`/ganttSchedule/${scheduleId}/filters/${filterViewId}`),
    errMsg: parseError('Failed to delete gantt schedule filter view'),
  }),
  getUserFieldScheduleFilterViews: () => request({
    call: axios.get('/schedule/filters'),
    errMsg: parseError('Failed to get field schedule filter views'),
    hideSuccessToast: true,
  }),
  addUserFieldScheduleFilterView: (payload) => request({
    call: axios.post('/schedule/filters', payload),
    errMsg: parseError('Failed to add field schedule filter view'),
  }),
  updateUserFieldScheduleFilterView: (filterViewId, payload) => request({
    call: axios.put(`/schedule/filters/${filterViewId}`, payload),
    errMsg: parseError('Failed to update field schedule filter view'),
  }),
  deleteUserFieldScheduleFilterView: (filterViewId) => request({
    call: axios.delete(`/schedule/filters/${filterViewId}`),
    errMsg: parseError('Failed to delete field schedule filter view'),
  }),
};

export default ScheduleService;
