import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import TableAddDrawer from './TableAddDrawer';
import { includesTerm } from '../../../helpers/helpers';
import { getMaterials } from '../../../materials/state/materials.actions';
import MaterialSelect from '../../../materials/MaterialSelect';
import useValidateMaxSelect from '../../../common/hooks/useValidateMaxSelect';

export default function MaterialTableAddDrawer({
  visible,
  onClose,
  onSubmit,
  readOnly = false,
  selected: initialSelected = [],
  showInput,
  materials: materialData,
  maxExistingAllowed,
}) {
  const dispatch = useDispatch();
  const materials = useSelector((state) => materialData || state.materials.materials);
  const [searchStr, setSearchStr] = useState();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState([]);

  const selectedRowKeys = useMemo(() => selected.map((s) => s.id), [selected]);

  const onSubmitClicked = useCallback(() => {
    const relevantSelected = showInput ? selected : selectedRowKeys;

    const isValid = useValidateMaxSelect({
      maxExistingAllowed,
      selected: relevantSelected,
      type: 'material(s)',
    });
    if (isValid) onSubmit(relevantSelected);
  }, [
    selected,
    selectedRowKeys,
    showInput,
    onSubmit,
    maxExistingAllowed,
  ]);

  useEffect(() => {
    if (initialSelected) {
      const initSelected = [];
      initialSelected.forEach((material) => {
        const relevantMaterial = materials[material?.id || material];

        if (!relevantMaterial) {
          return;
        }

        initSelected.push({
          ...relevantMaterial,
          quantity: material.quantity,
        });
      });

      setSelected(initSelected);
    }
  }, [initialSelected, materials]);

  useEffect(() => {
    if (!visible) {
      setSearchStr();
    }
  }, [visible]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getMaterials());
      setLoading(false);
    };
    if (visible && dispatch) load();
  }, [visible]);

  const data = useMemo(() => {
    const materialsList = Object.values(materials)
      .filter(({ active }) => active);

    if (!searchStr) {
      return materialsList;
    }

    return materialsList.filter((material) => (
      includesTerm(material.name, searchStr)
      || includesTerm(material.description, searchStr)
    ));
  }, [materials, searchStr]);

  return (
    <TableAddDrawer
      title="Add Materials"
      visible={visible}
      onClose={onClose}
      onSearch={setSearchStr}
      onSubmit={onSubmitClicked}
      searchStr={searchStr}
      destroyOnClose
    >
      <MaterialSelect
        data={data}
        selected={selected}
        setSelected={setSelected}
        loading={loading}
        readOnly={readOnly}
        showInput={showInput}
        maxExistingAllowed={maxExistingAllowed}
      />
    </TableAddDrawer>
  );
}

MaterialTableAddDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  readOnly: PropTypes.bool,
  selected: PropTypes.arrayOf(PropTypes.string),
  showInput: PropTypes.bool,
  materials: PropTypes.shape(PropTypes.shape),
  maxExistingAllowed: PropTypes.number,
};

MaterialTableAddDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
  onSubmit: () => {},
  readOnly: false,
  selected: [],
  showInput: false,
  materials: null,
  maxExistingAllowed: null,
};
