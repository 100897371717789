import React from 'react';
import { EditOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import colors from '../../constants/Colors';

export default ({
  editable,
  onClick,
  customStyle,
}) => (
  <Button 
    className='dashboard-edit'
    onClick={onClick}
    style={customStyle ? customStyle : {
      display:editable ? 'auto' : 'none',
      backgroundColor:colors.ONTRACCR_DARK_YELLOW,
      borderColor:colors.ONTRACCR_DARK_YELLOW,
    }}>
    Edit
    <EditOutlined />
  </Button>
);
