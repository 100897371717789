import React from 'react';

import PDFDesignerFontPicker from './PDFDesignerFontPicker';

export default ({
  drawOptions,
  onDrawOptionChanged
}) => (
  <>
    <PDFDesignerFontPicker
      drawOptions={drawOptions}
      onDrawOptionChanged={onDrawOptionChanged}
      showFontScaleSelector
    />
  </>
);
