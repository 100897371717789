import React from 'react';
import { Select, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import DisplayText from '../common/text/DisplayText';

const { Option } = Select;

export default function ClockInSelect({
  edit,
  disabled,
  title,
  onChange,
  defaultValue,
  value,
  data,
  displayText,
  optionFormatter = (option) => option.name,
  filterOptions = (input, option) => option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0,
}) {
  const { t } = useTranslation();
  return (
    edit ? (
      <Select
        allowClear
        disabled={disabled}
        showSearch
        style={{ width: 300 }}
        placeholder={`Select ${t(title)}`}
        filterOption={filterOptions}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
      >
        {data.map((item) => (
          <Option
            key={item.id}
            value={item.id}
            name={item.name}
            number={item.number}
          >
              {optionFormatter(item)}
            </Option>
        ))}
      </Select>
    ) : (
      <Row style={{width:'100%'}} justify='center'>
        {displayText && <DisplayText title={displayText} style={{
          fontSize:18,
        }}/>}
      </Row>
      )
  );
}
