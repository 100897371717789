import { ViewMode } from 'web-dashboard-gantt';
import { getGanttZoomScales } from './boards.helpers';

export const VIEW_TYPES = {
  CARD_VIEW: 'board',
  LIST_VIEW: 'list',
  TIMELINE_VIEW: 'timeline',
};

export const ganttViewTypes = [
  {
    value: ViewMode.Day,
    label: 'Day',
  },
  {
    value: ViewMode.Week,
    label: 'Week',
  },
  {
    value: ViewMode.Month,
    label: 'Month',
  },
];

export const ganttZoomScales = getGanttZoomScales({ max: 2 });

export default {};
