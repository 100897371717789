import {
  GET_FILE_STRUCTURE,
  CREATE_FOLDERS,
  DELETE_FILE_ITEMS,
  UPLOAD_FILES,
  LOAD_IMAGE,
  HIDE_IMAGE,
  DESCRIBE_FILE,
  CLOSE_FILE_DRAWER,
  GET_FILE_ACCESS_LIST,
  GRANT_FILE_ACCESS,
  REVOKE_FILE_ACCESS,
  DECREASE_COMPANY_STORAGE_USAGE,
  INCREASE_COMPANY_STORAGE_USAGE,
  SET_COMPANY_STORAGE_USAGE,
  UPDATE_SELECT_FORM_FILES,
} from '../../state/actionTypes';
import FileService from './files.service';

import Analytics from '../../helpers/Analytics';
import Permissions from '../../auth/Permissions';

export const getFileStructure = () => async (dispatch) => {
  const { data } = await FileService.getStructure();
  if (!data) return false;
  dispatch({
    type: GET_FILE_STRUCTURE,
    payload: {
      data,
    },
  });
  dispatch({
    type: SET_COMPANY_STORAGE_USAGE,
    payload: data,
  });
  return true;
};

export const createFolders = ({ names = [], divisionId }) => async (dispatch) => {
  Analytics.track('Files/CreateFolders');
  const { data } = await FileService.createFolders({ names, divisionId });
  if (!data) return false;
  dispatch({
    type: CREATE_FOLDERS,
    payload: data,
  });
  return true;
};

export const deleteItems = ({ items, password }) => async (dispatch) => {
  Analytics.track('Files/DeleteItems');
  const { data } = await FileService.deleteItems({
    payload: { password },
    query: { items },
  });
  if (!data) return false;
  dispatch({
    type: DELETE_FILE_ITEMS,
    payload: {
      items,
    },
  });
  dispatch({
    type: DECREASE_COMPANY_STORAGE_USAGE,
    payload: data,
  });
  return true;
};

export const uploadFiles = (payload, notify) => async (dispatch) => {
  const { data: { files = [], createSize } = {} } = await FileService.uploadFiles(payload, notify);
  if (!files) return false;
  dispatch({
    type: UPLOAD_FILES,
    payload: {
      files,
    },
  });
  dispatch({
    type: INCREASE_COMPANY_STORAGE_USAGE,
    payload: {
      createSize,
    },
  });
  return true;
};

export const moveFiles = (payload) => async (dispatch) => {
  Analytics.track('Files/Move');
  const { data } = await FileService.moveItems(payload);
  if (!data) return false;
  // Lazily just refresh everything.
  const res = await new Promise((resolve) => {
    setTimeout(async () => {
      const { data: files } = await FileService.getStructure();
      if (!files) return resolve(false);
      dispatch({
        type: GET_FILE_STRUCTURE,
        payload: {
          data: files,
        },
      });
      return resolve(true);
    }, 500);
  });
  return res;
};

export const renameFolder = (payload) => async (dispatch) => {
  Analytics.track('Files/RenameFolder');
  const { data } = await FileService.renameFolder(payload);
  if (!data) return false;
  // Lazily just refresh everything.
  const res = await new Promise((resolve) => {
    setTimeout(async () => {
      const { data: files } = await FileService.getStructure();
      if (!files) return resolve(false);
      dispatch({
        type: GET_FILE_STRUCTURE,
        payload: {
          data: files,
        },
      });
      return resolve(true);
    }, 500);
  });
  return res;
};

export const loadImage = (path, type, name = '', suppressError = false) => async (dispatch) => {
  Analytics.track('Files/View', { fileType: type });
  const { data: image } = await FileService.loadImage(path, suppressError);
  if (!image) return false;
  image.type = type;
  dispatch({
    type: LOAD_IMAGE,
    payload: {
      path,
      image,
      name,
    },
  });
  return true;
};


export const hideImage = () => ({
  type: HIDE_IMAGE,
});

export const describeItem = (path) => async (dispatch) => {
  if (!path) return dispatch({ type: DESCRIBE_FILE, payload: { data: {} } });
  const { data } = await FileService.describeItem(path);
  if (!data) return false;
  dispatch({
    type: DESCRIBE_FILE,
    payload: {
      data,
    },
  });
  return true;
};

export const updateFile = (id, payload) => async () => {
  const { data } = await FileService.updateFile(id, payload);
  return !!data;
};

export const getFileAccessList = (id) => async (dispatch) => {
  Analytics.track('Files/Access/GetList');
  const { data } = await FileService.getACL(id);
  if (!data) return false;
  dispatch({
    type: GET_FILE_ACCESS_LIST,
    payload: data,
  });
  return true;
};

export const rotateFile = (id, payload, prevRotation) => async (dispatch) => {
  if (payload?.rotation === -1 || payload?.rotation === prevRotation || !Permissions.has('ROTATE_PHOTO')) {
    return false;
  }
  const { data } = await FileService.rotateFile(id, payload);
  if (!data) return false;
  if (data?.autoSaveId || data?.notAutoSavedYet) {
    return true;
  }
  dispatch({
    type: UPDATE_SELECT_FORM_FILES,
    payload: data,
  });
  return true;
};

export const grantAccess = (payload) => async (dispatch) => {
  Analytics.track('Files/Access/Grant');
  const { data } = await FileService.grantAccess(payload);
  if (!data) return false;
  dispatch({
    type: GRANT_FILE_ACCESS,
    payload,
  });
  return true;
};

export const revokeAccess = (payload) => async (dispatch) => {
  Analytics.track('Files/Access/Revoke');
  const { data } = await FileService.revokeAccess(payload);
  if (!data) return false;
  dispatch({
    type: REVOKE_FILE_ACCESS,
    payload,
  });
  return true;
};

export const togglePublic = ({ id, isPublic }) => async (dispatch) => {
  Analytics.track('Files/Access/TogglePublic');
  const { data } = await FileService.togglePublic(id, isPublic);
  if (!data) return false;
  const { data: files } = await FileService.getStructure();
  if (!files) return false;
  dispatch({
    type: GET_FILE_STRUCTURE,
    payload: {
      data: files,
    },
  });
  return true;
};

export const closeItemDrawer = {
  type: CLOSE_FILE_DRAWER,
};

export default {};
