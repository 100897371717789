import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import liveFeedTime from '../../assets/images/NUX/liveFeedTime.png';
import liveFeedCostCode from '../../assets/images/NUX/liveFeedCostCode.png';
import liveFeedProject from '../../assets/images/NUX/liveFeedProject.png';
import liveFeedMessage from '../../assets/images/NUX/liveFeedMessage.png';
import liveFeedTeam from '../../assets/images/NUX/liveFeedTeam.png';
import liveFeedNote from '../../assets/images/NUX/liveFeedNote.png';


import DisplayText from '../../common/text/DisplayText';

const CustomRow = ({
  contents = [],
  style = {},
}) => (
  <Row style={style} gutter={10} justify='space-between'>
    {
      contents.map(({ title, description, image = {} }) => (
        <Col span={8} key={title}>
          <DisplayText
            title={title}
            style={{
              textAlign:'left',
              fontFamily:'roboto-bold',
              fontSize:18,
              marginBottom: 12
            }}
           />
          <DisplayText
            title={description}
            style={{
              textAlign:'left',
              fontFamily:'roboto-regular',
              fontSize:13,
            }}
          />
          <img
            className='nux-screenshot'
            alt={title}
            style={{
              height:75,
              width:64
            }}
            {...image}
          />
        </Col>
      ))
    }
  </Row>
)

export default function NuxRealTime({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 65px)',
        padding:'0px 40px',
        textAlign:'left',
      }}
      customContent={
        <div>
          <CustomRow
            contents={[
              {
                title:'Time Tracking',
                description:'Whenever any user clocks in, switches task, ends a break, or clocks out.',
                image:{
                  src:liveFeedTime,
                  style: {
                    height:26,
                    width:200,
                  },
                }
              },
              {
                title:'Cost Codes',
                description:'Users can quickly learn more about new cost codes.',
                image:{
                  src:liveFeedCostCode,
                  style: {
                    height:42,
                    width:200,
                  },
                }
              },
              {
                title: t('Project', { count: 2 }),
                description:`Users can quickly learn more about new ${t('Project', { count: 2 }).toLowerCase()}.`,
                image:{
                  src:liveFeedProject,
                  style: {
                    height:48,
                    width:200,
                  },
                }
              }
            ]}
          />
          <CustomRow
            style={{ marginTop: 5, }}
            contents={[
              {
                title:'Messages',
                description:'Users can tag each other in messages. Messages can include photos & files.',
                image:{
                  src:liveFeedMessage,
                  style: {
                    height:80,
                    width:200,
                  },
                }
              },
              {
                title:'New Users & Teams',
                description:`Notifications for every new user or team added to the company's account.`,
                image:{
                  src:liveFeedTeam,
                  style: {
                    height:48,
                    width:200,
                  },
                }
              },
              {
                title:'Field Notes',
                description:'Users can post quick field notes for managers to see.',
                image:{
                  src:liveFeedNote,
                  style: {
                    height:40,
                    width:246
                  },
                }
              }
            ]}
          />
        </div>
      }
    />
  );
}
