import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Row,
  Col,
  Select,
} from 'antd';
import * as XLSX from 'xlsx';

import StepsContainer from '../common/containers/StepsContainer';
import DivisionSelector from '../common/inputs/DivisionSelector';

import CodeCostHeaders from './costcodeHeaders';
import CostCodeUploadList from './costcodeUploadList';

function CostCodeUploadSteps({
  currentStep,
  workbook,
  onData,
  actionButtonEnabled,
}) {
  const [currentSheetName, setCurrentSheetName] = useState();
  const [divisionId, setDivisionId] = useState();
  const [sheetJSONs, setSheetJSONs] = useState({});
  const [data, setData] = useState([]);

  const onHeaderSelect = useCallback(({
    code: cc,
    description: desc,
    name,
  } = {}) => {
    const rows = sheetJSONs[currentSheetName] ?? [];

    const newData = rows.map((row, idx) => (
      {
        code: row[cc],
        description: row[desc],
        name: row[name],
        key: idx,
        divisionId,
      }
    ));
    onData(newData);
    setData(newData);
  }, [onData, sheetJSONs, currentSheetName, divisionId]);

  useEffect(() => {
    if (workbook) {
      const {
        Sheets,
        SheetNames = [],
      } = workbook;
      if (SheetNames.length > 0) {
        if (!currentSheetName) setCurrentSheetName(SheetNames[0]);
      }
      const newSheetMap = {};
      SheetNames.forEach((sheetName) => {
        newSheetMap[sheetName] = XLSX.utils.sheet_to_json(Sheets[sheetName]);
      });
      setSheetJSONs(newSheetMap);
    } else {
      setCurrentSheetName();
      setSheetJSONs({});
    }
  }, [workbook, currentSheetName]);

  const {
    defaultSheetName,
    sheetOpts = [],
  } = useMemo(() => {
    if (!workbook) return {};
    const { SheetNames = [] } = workbook;
    return {
      defaultSheetName: SheetNames[0],
      sheetOpts: SheetNames.map((sheet) => ({ value: sheet, label: sheet }))
    };
  }, [workbook]);

  const ourFirstRow = useMemo(() => {
    const {
      [currentSheetName]: [firstRow = {}] = [],
    } = sheetJSONs;
    return firstRow;
  },[sheetJSONs, currentSheetName]);

  useEffect(() => {
    actionButtonEnabled(divisionId);
  },[divisionId]);

  return (
    <StepsContainer
      currentStep={currentStep}
      steps={[{
        title: 'Select Sheet',
        content: (
          <Row gutter={20}>
            <Col>
              <Select
                style={{ width: 250 }}
                defaultValue={defaultSheetName}
                onSelect={setCurrentSheetName}
                options={sheetOpts}
              />
            </Col>
            <Col>
              <DivisionSelector
                style={{ width: 250 }}
                divisionId={divisionId}
                onChange={setDivisionId}
              />
            </Col>
          </Row>
        ),
        key: 'select'
      },{
        title: 'Select Headers',
        content: <CodeCostHeaders headers={ourFirstRow} onSelect={onHeaderSelect}/>,
        key: 'headers'
      }, {
        title: 'Preview',
        content: (
          <CostCodeUploadList
            dataSource={data}
            actionButtonEnabled={actionButtonEnabled}
            />
        ),
        key: 'preview'
      }]}
    />
  );
};

export default CostCodeUploadSteps;
