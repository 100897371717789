import {
  GET_ALL_CERTIFICATIONS,
  GET_ALL_CERTIFICATION_FOR_ENTITY,
  GET_CERTIFICATION,
  CREATE_CERTIFICATION,
  UPDATE_CERTIFICATION,
  DELETE_CERTIFICATION,
  GET_CERTIFICATION_CUSTOM_FIELD_TEMPLATE,
  UPDATE_CERTIFICATION_CUSTOM_FIELD_TEMPLATE,
  GET_CERTIFICATION_CUSTOM_DATA,
} from '../../state/actionTypes';

const initialState = {
  users: {
    certifications: [],
    selectedCertification: null,
    companyCertifications: [],
    certificationCustomFieldTemplate: {
      fields: [], sections: [],
    },
    certificationCustomData: [],
    certificationCustomDataFiles: {},
  },
  vendors: {
    certifications: [],
    selectedCertification: null,
    companyCertifications: [],
    certificationCustomFieldTemplate: {
      fields: [], sections: [],
    },
    certificationCustomData: [],
    certificationCustomDataFiles: {},
  },
};

export default function certificationActions(state = initialState, action = {}) {
  const { entityType } = action?.payload ?? {};
  switch (action.type) {
    case GET_ALL_CERTIFICATIONS: {
      return {
        ...state,
        [entityType]: {
          ...state[entityType] ?? {},
          companyCertifications: action.payload.data,
        },
      };
    }
    case GET_ALL_CERTIFICATION_FOR_ENTITY: {
      return {
        ...state,
        [entityType]: {
          ...state[entityType] ?? {},
          certifications: action.payload.data,
        },
      };
    }
    case GET_CERTIFICATION: {
      return {
        ...state,
        [entityType]: {
          ...state[entityType] ?? {},
          selectedCertification: action.payload.data?.[0],
        },
      };
    }
    case CREATE_CERTIFICATION:
    case UPDATE_CERTIFICATION:
    case DELETE_CERTIFICATION: {
      const {
        [entityType]: {
          companyCertifications = [],
        } = {},
      } = state;

      const {
        payload: {
          certifications = [],
          userId,
        },
      } = action;

      const userCertifications = certifications.filter((cert) => cert.userId === userId);
      const newCompanyCertifications = companyCertifications.filter(
        (cert) => cert.userId !== userId,
      ).concat(userCertifications);

      return {
        ...state,
        [entityType]: {
          ...state[entityType] ?? {},
          certifications,
          companyCertifications: newCompanyCertifications,
          selectedCertification: null,
        },
      };
    }
    case GET_CERTIFICATION_CUSTOM_FIELD_TEMPLATE:
    case UPDATE_CERTIFICATION_CUSTOM_FIELD_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      return {
        ...state,
        [entityType]: {
          ...state[entityType] ?? {},
          certificationCustomFieldTemplate: template,
        },
      };
    }
    case GET_CERTIFICATION_CUSTOM_DATA: {
      const {
        data: {
          data = [],
          fileMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        [entityType]: {
          ...state[entityType] ?? {},
          certificationCustomData: data,
          certificationCustomDataFiles: fileMap,
        },
      };
    }
    default:
      return state;
  }
}
