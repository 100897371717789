import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col, Table,
} from 'antd';
import {
  DeleteOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import BorderlessButton from '../../../../common/buttons/BorderlessButton';
import OnTraccrNumberInput from '../../../../common/inputs/OnTraccrNumberInput';
import OnTraccrButton from '../../../../common/buttons/OnTraccrButton';
import ManualEntryDatePicker from '../../../../clock/ManualEntry/ManualEntryDatePicker';

import EquipmentTableAddDrawer from './EquipmentTableAddDrawer';
import { currencyFormatter, getIdMap } from '../../../../helpers/helpers';
import { currencyParser } from '../../../../helpers/inputParsers';
import DisplayText from '../../../../common/text/DisplayText';
import { generateId } from '../../../formHelpers';
import useCheckTableMaxRows from '../../../../common/hooks/useCheckTableMaxRows';
import { decorateEquipment } from './EquipmentTable.helpers';
import TimeEntryTableTeamSelection from '../TimeEntryTable/TimeEntryTableTeamSelection';
import useToggle from '../../../../common/hooks/useToggle';

const renderCostColumn = ({
  key,
  val,
  record,
  isDisplay,
  onValueUpdated,
}) => {
  const formattedVal = currencyFormatter(val ?? 0);
  if (isDisplay) return formattedVal;
  return (
    <OnTraccrNumberInput
      precision={2}
      min={0}
      formatter={currencyFormatter}
      parser={currencyParser}
      value={val}
      onChange={(newVal) => onValueUpdated(record.id, key, newVal)}
    />
  );
};

const getColumnMap = ({
  isDisplay,
  onValueUpdated,
  onDateChange,
  requiredColumns,
  preventEdits,
}) => ({
  name: {
    title: <div className={requiredColumns && 'form-required-field'}>Name</div>,
    dataIndex: 'name',
  },
  code: {
    title: <div className={requiredColumns && 'form-required-field'}>ID</div>,
    dataIndex: 'code',
  },
  hours: {
    title: <div className={requiredColumns && 'form-required-field'}>Hours</div>,
    dataIndex: 'hours',
    render: (hours, record) => {
      if (isDisplay) return hours;
      return (
        <OnTraccrNumberInput
          value={hours}
          onChange={(newHours) => onValueUpdated(record.id, 'hours', newHours)}
        />
      );
    },
  },
  hourlyCost: {
    title: <div className={requiredColumns && 'form-required-field'}>Hourly Cost</div>,
    dataIndex: 'hourlyCost',
    render: (hourlyCost, record) => renderCostColumn({
      key: 'hourlyCost',
      val: hourlyCost,
      record,
      isDisplay: isDisplay || preventEdits,
      onValueUpdated,
    }),
  },
  hourlyBillingRate: {
    title: <div className={requiredColumns && 'form-required-field'}>Hourly Billing Rate</div>,
    dataIndex: 'hourlyBillingRate',
    render: (hourlyBillingRate, record) => renderCostColumn({
      key: 'hourlyBillingRate',
      val: hourlyBillingRate,
      record,
      isDisplay: isDisplay || preventEdits,
      onValueUpdated,
    }),
  },
  dailyCost: {
    title: <div className={requiredColumns && 'form-required-field'}>Daily Cost</div>,
    dataIndex: 'dailyCost',
    render: (dailyCost, record) => renderCostColumn({
      key: 'dailyCost',
      val: dailyCost,
      record,
      isDisplay: isDisplay || preventEdits,
      onValueUpdated,
    }),
  },
  dailyBillingRate: {
    title: <div className={requiredColumns && 'form-required-field'}>Daily Billing Rate</div>,
    dataIndex: 'dailyBillingRate',
    render: (dailyBillingRate, record) => renderCostColumn({
      key: 'dailyBillingRate',
      val: dailyBillingRate,
      record,
      isDisplay: isDisplay || preventEdits,
      onValueUpdated,
    }),
  },
  totalHourlyCost: {
    title: <div className={requiredColumns && 'form-required-field'}>Total Hourly Cost</div>,
    dataIndex: 'totalHourlyCost',
    render: (totalHourlyCost, record) => renderCostColumn({
      key: 'totalHourlyCost',
      val: totalHourlyCost,
      record,
      isDisplay: true,
    }),
  },
  totalHourlyBilling: {
    title: <div className={requiredColumns && 'form-required-field'}>Total Hourly Billing</div>,
    dataIndex: 'totalHourlyBilling',
    render: (totalHourlyBilling, record) => renderCostColumn({
      key: 'totalHourlyBilling',
      val: totalHourlyBilling,
      record,
      isDisplay: true,
    }),
  },
  addedDate: {
    title: <div className={requiredColumns && 'form-required-field'}>Date Added</div>,
    dataIndex: 'addedDate',
    width: 250,
    render: (date, record) => {
      if (isDisplay || preventEdits) return date;

      return (
        <ManualEntryDatePicker
          value={date}
          onChange={(newDate) => onDateChange(record.id, newDate)}
        />
      );
    },
  },
});

const getColumns = ({
  onDelete,
  columns,
  isDisplay,
  onValueUpdated,
  onDateChange,
  requiredColumns,
  preventEdits,
}) => {
  const cols = [];
  const colMap = getColumnMap({
    isDisplay,
    onValueUpdated,
    requiredColumns,
    preventEdits,
    onDateChange,
  });
  columns.forEach((col) => {
    if (col.key in colMap) {
      cols.push(colMap[col.key]);
    } else if (col.isCalculation) {
      cols.push({
        title: <div>{col.name}</div>,
        dataIndex: col.name,
      });
    }
  });
  if (!isDisplay) {
    cols.push({
      title: '',
      dataIndex: '',
      width: 100,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    });
  }

  return cols;
};

function EquipmentTablePreview({
  equipment = [],
  columns = [],
  previewProps = {},
  setPreviewProps,
  isDisplay,
  id,
  setResponses,
  responses = {},
  responding = false,
  configProps = {},
  showCondensedView,
  divisions = [],
}) {
  const {
    requiredColumns,
    preventEdits,
    hideAddFromTeam,
  } = configProps ?? {};
  const values = previewProps.values || []; // For Responses
  const {
    selected: previewSelected = [],
  } = previewProps;
  const {
    [id]: {
      values: responseSelected = [],
    } = {},
  } = responses;

  const rawSelected = responding ? responseSelected : previewSelected;

  const teams = useSelector((state) => state.teams.teams);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const equipmentWithOverrideRates = useMemo(() => (
    equipment.map((eq) => {
      const newEquipment = { ...eq };
      const { costcodeId } = newEquipment;

      if (costcodeId) {
        const costcode = costcodeMap?.[costcodeId] ?? {};
        const {
          hourlyWage,
          dailyWage,
          hourlyBillingRate,
          dailyBillingRate,
        } = costcode;
        if (hourlyWage) newEquipment.hourlyCost = hourlyWage;
        if (dailyWage) newEquipment.dailyCost = dailyWage;
        if (hourlyBillingRate) newEquipment.hourlyBillingRate = hourlyBillingRate;
        if (dailyBillingRate) newEquipment.dailyBillingRate = dailyBillingRate;
      }
      return newEquipment;
    })
  ), [equipment, costcodeMap]);

  const equipmentMap = useMemo(() => (
    getIdMap(equipmentWithOverrideRates)
  ), [equipmentWithOverrideRates]);

  const selected = useMemo(() => {
    const newId = generateId();
    return rawSelected?.map((s, idx) => (
      // Backwards Compatability - HARBOUR-4193
      s.equipmentId ? s : { ...s, equipmentId: s.id, id: newId + idx }
    ));
  }, [rawSelected]);

  const [drawerOpen, setDrawerOpen] = useState(false);

  const showDrawer = useCallback(() => setDrawerOpen(true), []);
  const hideDrawer = useCallback(() => setDrawerOpen(false), []);

  const { toggle: toggleTeamSelect, isToggled: showTeamSelect } = useToggle();

  const updateSelected = useCallback((newSelected) => {
    if (responding) {
      setResponses({
        ...responses,
        [id]: {
          ...(responses[id]),
          values: newSelected.map((eq) => decorateEquipment(eq)),
          columns,
        },
      });
    } else {
      setPreviewProps({
        ...previewProps,
        selected: newSelected.map((eq) => decorateEquipment(eq)),
      });
    }
  }, [
    responding,
    responses,
    id,
    columns,
    previewProps,
    setResponses,
    setPreviewProps,
  ]);

  const onSubmitChanges = useCallback((newSelected) => {
    const finalValues = [...selected];
    const newId = generateId();
    newSelected.forEach((s, idx) => {
      finalValues.push({
        ...s,
        equipmentId: s.id,
        id: newId + idx,
        addedDate: TaskHelpers.formatDate(DateTime.local().toMillis()),
      });
    });
    updateSelected(finalValues);
    hideDrawer();
  }, [updateSelected, hideDrawer, selected]);

  const onDelete = useCallback((itemId) => {
    const newSelected = selected.filter((item) => item.id !== itemId);
    updateSelected(newSelected);
  }, [updateSelected, selected]);

  const onValueUpdated = useCallback((rowId, value, key) => {
    const newSelected = selected.map((row) => {
      if (row.id !== rowId) return row;
      return {
        ...row,
        [value]: key,
      };
    });
    updateSelected(newSelected);
  }, [updateSelected, selected]);

  const onDateChange = useCallback((rowId, newDate) => {
    const newSelected = selected.map((row) => {
      if (row.id !== rowId) return row;
      return {
        ...row,
        addedDate: newDate,
      };
    });
    updateSelected(newSelected);
  }, [updateSelected, selected]);

  const onAddFromTeam = useCallback((teamId) => {
    const selectedTeam = teams.find(({ id: tId }) => tId === teamId);
    const { equipment: teamEquipment = [] } = selectedTeam ?? {};
    if (!teamEquipment.length) {
      toggleTeamSelect();
      return;
    }

    const finalValues = [...selected];
    teamEquipment.forEach((eqId, idx) => {
      const fullEquipment = equipmentMap[eqId];
      if (!fullEquipment) return;
      const newId = generateId();
      finalValues.push({
        ...fullEquipment,
        equipmentId: fullEquipment.id,
        id: newId + idx,
        addedDate: TaskHelpers.formatDate(DateTime.local().toMillis()),
      });
    });
    updateSelected(finalValues);
    toggleTeamSelect();
  }, [selected, equipmentMap, teams, updateSelected, toggleTeamSelect]);

  const tableColumns = useMemo(() => (
    getColumns({
      onDelete,
      onValueUpdated,
      onDateChange,
      columns,
      isDisplay,
      requiredColumns,
      preventEdits,
    })
  ), [columns, isDisplay, onDelete, onValueUpdated, onDateChange, requiredColumns, preventEdits]);

  const dataSource = useMemo(() => (
    isDisplay && !responding ? values : selected
  ), [isDisplay, responding, values, selected]);

  const {
    maxExistingAllowed,
    shouldAddButtonBeEnabled = true,
  } = useCheckTableMaxRows({
    configProps,
    currentRowsLength: dataSource?.length,
  });

  return (
    <>
      <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
        {!isDisplay
          && (
            <Row gutter={20} align="middle" style={{ width: '100%', marginBottom: 10 }}>
              <Col>
                <Row gutter={20}>
                  <Col>
                    <OnTraccrButton
                      title="Add"
                      onClick={showDrawer}
                      icon={<PlusOutlined />}
                      disabled={!shouldAddButtonBeEnabled}
                    />
                  </Col>
                </Row>
              </Col>
              {!hideAddFromTeam && (
                <Col>
                  <Row gutter={20}>
                    <OnTraccrButton
                      title="Add from Team"
                      icon={<PlusOutlined />}
                      onClick={toggleTeamSelect}
                    />
                  </Row>
                </Col>
              )}
            </Row>
          )}
        { !showCondensedView || dataSource?.length ? (
          <Table
            style={{ width: '100%', overflow: 'auto' }}
            columns={tableColumns}
            size="small"
            pagination={false}
            dataSource={dataSource}
          />
        ) : (
          <DisplayText title="No Equipment Selected" style={{ marginBottom: 0 }} />
        )}
      </Row>
      {!isDisplay && (
        <>
          <EquipmentTableAddDrawer
            visible={drawerOpen}
            onClose={hideDrawer}
            onSubmit={onSubmitChanges}
            equipment={equipmentWithOverrideRates}
            maxExistingAllowed={maxExistingAllowed}
          />
          <TimeEntryTableTeamSelection
            divisions={divisions}
            visible={showTeamSelect}
            onClose={toggleTeamSelect}
            onAdd={onAddFromTeam}
          />
        </>
      )}
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
EquipmentTablePreview.propTypes = {
  equipment: PropTypes.array,
  columns: PropTypes.array,
  previewProps: PropTypes.object,
  setPreviewProps: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool,
  id: PropTypes.string.isRequired,
  setResponses: PropTypes.func.isRequired,
  responses: PropTypes.object,
  responding: PropTypes.bool,
  configProps: PropTypes.shape({
    requiredColumns: PropTypes.bool,
    preventEdits: PropTypes.bool,
  }),
  showCondensedView: PropTypes.bool,
  divisions: PropTypes.array,
};

EquipmentTablePreview.defaultProps = {
  equipment: [],
  columns: [],
  previewProps: {},
  responses: {},
  isDisplay: false,
  responding: false,
  configProps: {},
  showCondensedView: false,
  divisions: [],
};

export default EquipmentTablePreview;
