import React, {
  useMemo,
  useCallback,
  useRef,
  useState,
  useLayoutEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeList as List } from 'react-window';
import { Row, Col } from 'antd';
import { DateTime } from 'luxon';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { DndProvider } from 'react-dnd';

import { mergeSets, search } from '../../helpers/helpers';
import UserWeek from './UserWeek';

import Permissions from '../../auth/Permissions';
import UserWeeklyDayModal from './UserWeeklyDayModal';
import { setBiweeklyModalConfig } from '../state/schedule.actions';

import { BIWEEKLY_ROW_HEIGHT } from '../schedule.constants';

export default function UserWeeklySchedule({
  date,
  selectedUsers,
  searchText,
  shifts,
  onShiftSelect,
  onShiftEdit,
  editShift,
  onShiftCreate,
  newShift,
  onNewShiftChange,
  startDate,
  endDate,
  onDaySelect,
}) {
  const dispatch = useDispatch();

  const containerRef = useRef(null);
  const listRef = useRef();
  const headerRef = useRef();
  const listContainerRef = useRef();
  const users = useSelector((state) => state.users.users);
  const {
    selectedDivisions = new Set(),
    divisions = {},
  } = useSelector((state) => state.settings);
  const [tableDimensions, setTableDimensions] = useState();

  const handleScroll = () => {
    if (listRef.current) {
      listRef.current.scrollLeft = headerRef?.current?.scrollLeft;
    }
  };

  const listScroll = (e) => {
    if (headerRef.current && e.target.className === 'user-biweekly-list') {
      headerRef.current.scrollLeft = listRef?.current?.scrollLeft;
    }
  };

  useLayoutEffect(() => {
    /*
      https://github.com/bvaughn/react-window/issues/256#issuecomment-735855406
    */
    if (listRef) {
      document.addEventListener('scroll', listScroll, true);
      return () => document.removeEventListener('scroll', listScroll);
    }
    return () => {};
  }, [listRef]);

  const usersFromSelectedDivisions = useMemo(() => {
    const selectedUsersSet = new Set(selectedUsers || []);
    const selectedDivUsers = Object
      .values(divisions)
      .filter(({ id }) => selectedDivisions.has(id))
      .map(({ users: divUsers = new Set() }) => divUsers);
    const mergedSelectedDivUsers = mergeSets(selectedDivUsers);
    return users.filter(({ id: userId, active }) => {
      if (selectedUsersSet.size && !selectedUsersSet.has(userId)) return false;
      return mergedSelectedDivUsers.has(userId) && active;
    });
  }, [users, selectedDivisions, divisions, selectedUsers]);

  const displayedUsers = useMemo(() => {
    const relevantUsers = search({
      data: usersFromSelectedDivisions,
      value: searchText,
      getField: (item) => item.name,
    });
    return [undefined, ...relevantUsers]; // add unassigned row to displayedUsers
  }, [usersFromSelectedDivisions, searchText]);

  const handleShiftSelect = useCallback((shift) => {
    onShiftSelect(shift, Permissions.has('SCHEDULE_WRITE'));
    onShiftEdit(shift);
  }, [onShiftSelect, onShiftEdit]);

  const handleContainerRef = useCallback((element) => {
    if (element !== null) {
      const bounds = element.getBoundingClientRect();
      setTableDimensions(bounds);
      containerRef.current = element;
    }
  }, []);

  const onCloseDayModal = useCallback(() => {
    dispatch(setBiweeklyModalConfig({}));
  }, []);

  const numDays = Math.round(endDate.diff(startDate, 'days').as('days'));
  const days = new Array(numDays).fill(1).map((_, idx) => startDate.plus({ day: idx }));

  /*
    If the list has a vertical scrollbar we
    need to reduce the width of the header to match
  */
  const widthOffset = (
    (listContainerRef?.current?.clientWidth ?? 0)
    - (listRef?.current?.clientWidth ?? 0)
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <ResizeObserver
        onResize={({ height, width }) => {
          setTableDimensions({ height, width });
        }}
      >
        <div
          ref={handleContainerRef}
          className="schedule-user-weekly-view-container"
        >
          {
            tableDimensions
            && (
              <>
                <Row
                  className="schedule-user-weekly-header-container"
                  style={{
                    width: tableDimensions.width,
                    overflow: 'auto',
                    paddingRight: widthOffset,
                  }}
                  ref={headerRef}
                  onScroll={handleScroll}
                >
                  <Col
                    span={3}
                    className="schedule-user-week-day-column"
                    style={{
                      position: 'sticky',
                      top: 0,
                      left: 0,
                      zIndex: 25,
                    }}
                  >
                    <div className="schedule-month-day-header">Users</div>
                  </Col>
                  {
                    days.map((dt, index) => {
                      const prefix = dt.day === 1 && index > 0
                        ? `${dt.toLocaleString({ month: 'short' })} `
                        : '';
                      return (
                        <Col
                          className="schedule-user-week-day-column"
                          key={dt.toSQLDate()}
                        >
                          <div // eslint-disable-line jsx-a11y/no-static-element-interactions
                            className="schedule-biweekly-day-text"
                            onClick={() => onDaySelect(dt)}
                            tabIndex={-1}
                            onKeyDown={() => {}}
                          >
                            {
                            `${prefix}${dt.toLocaleString({ weekday: 'short', day: 'numeric' })}`
                            }
                          </div>
                        </Col>
                      );
                    })
                }
                </Row>
                <Row
                  style={{ width: tableDimensions.width, overflowX: 'auto', overflowY: 'hidden' }}
                  ref={listContainerRef}
                >
                  <List
                    height={tableDimensions.height - 50} // Header height
                    itemCount={displayedUsers.length}
                    itemSize={() => BIWEEKLY_ROW_HEIGHT}
                    width={tableDimensions.width}
                    outerRef={listRef}
                    className="user-biweekly-list"
                  >
                    {({ index, style }) => {
                      const currentUser = displayedUsers[index];
                      return (
                        <UserWeek
                          user={currentUser}
                          key={currentUser?.id || 'unassigned'}
                          date={date}
                          endDate={endDate}
                          numDays={numDays}
                          shifts={shifts}
                          onShiftSelect={handleShiftSelect}
                          style={style}
                        />
                      );
                    }}
                  </List>
                </Row>
                <UserWeeklyDayModal
                  onCloseClicked={onCloseDayModal}
                  onShiftSelect={handleShiftSelect}
                  onShiftEdit={onShiftEdit}
                  editShift={editShift}
                  onShiftCreate={onShiftCreate}
                  newShift={newShift}
                  onNewShiftChange={onNewShiftChange}
                />
              </>
            )
          }
        </div>
      </ResizeObserver>
    </DndProvider>
  );
}

UserWeeklySchedule.propTypes = {
  date: PropTypes.object.isRequired,
  selectedUsers: PropTypes.arrayOf(PropTypes.string),
  searchText: PropTypes.string,
  shifts: PropTypes.object.isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onShiftEdit: PropTypes.func.isRequired,
  editShift: PropTypes.object,
  onShiftCreate: PropTypes.func.isRequired,
  newShift: PropTypes.object,
  onNewShiftChange: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  onDaySelect: PropTypes.func.isRequired,
};

UserWeeklySchedule.defaultProps = {
  selectedUsers: [],
  searchText: undefined,
  editShift: undefined,
  newShift: undefined,
  startDate: DateTime.local(),
  endDate: DateTime.local(),
};
