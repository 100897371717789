import React, {
  useCallback,
  useEffect,
  useMemo,
  useReducer,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  DatePicker,
  Drawer,
  Form,
  Row,
} from 'antd';
import moment from 'moment';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import FormFileInput from '../common/inputs/FormFileInput';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { isNullOrUndefined } from '../helpers/helpers';
import CustomFields from '../common/customFields/CustomFields';
import { formatCustomFieldCreatePayload } from '../helpers/costcodeHelpers';

import {
  getCertification,
  createCertification,
  updateCertification,
  getCertificationCustomData,
} from './state/certifications.actions';

export default function AddEditCertification(props) {
  const dispatch = useDispatch();
  const {
    id,
    certificationId,
    visible,
    onClose,
    certification,
    onDeleteClick,
    entityType,
  } = props;

  const {
    [entityType]: {
      selectedCertification = {},
      certificationCustomData,
      certificationCustomDataFiles,
      certificationCustomFieldTemplate,
    } = {},
  } = useSelector((state) => state.certifications);
  const userDivisions = useSelector((state) => state.users.userDivisions);
  const vendors = useSelector((state) => state.vendors.vendors);
  const [form] = Form.useForm();
  const [files, setFiles] = useState([]);
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);
  const [shouldUpdate, forceUpdate] = useReducer((x) => x + 1, 0);

  const relevantDivisions = useMemo(() => {
    switch (entityType) {
      case 'users': return userDivisions?.[id] ?? [];
      case 'vendors': {
        return vendors?.[id]?.divisions ?? [];
      }
      default: return [];
    }
  }, [entityType, userDivisions, vendors, id]);

  useEffect(() => {
    forceUpdate();
  }, [certificationCustomData]);

  useEffect(() => {
    if (id && certificationId) {
      dispatch(getCertification(entityType, id, certificationId));
    }

    dispatch(getCertificationCustomData(entityType, certificationId));
  }, [entityType, id, certificationId]);

  useEffect(() => {
    setCustomFields(certificationCustomFieldTemplate?.sections);
  }, [certificationCustomFieldTemplate]);

  useEffect(() => {
    if (form) {
      const relevantCertification = selectedCertification || certification;
      form.resetFields();

      if (relevantCertification && certificationId) {
        form.setFieldsValue({
          type: relevantCertification.type,
          acquiredDate: relevantCertification.acquiredDate
            ? moment.unix(relevantCertification.acquiredDate)
            : null,
          expiryDate: relevantCertification.expiryDate
            ? moment.unix(relevantCertification.expiryDate)
            : null,
          issuer: relevantCertification.issuer,
        });
        setFiles(relevantCertification.files);
      }
    }
  }, [form, selectedCertification, certification, certificationId]);

  const onFormSubmit = useCallback(async () => {
    const payload = { ...form.getFieldsValue() };

    // Normalize data for backend
    Object.keys(payload).forEach((key) => {
      if (isNullOrUndefined(payload[key])) {
        payload[key] = null;
      } else if (key === 'acquiredDate' || key === 'expiryDate') {
        payload[key] = payload[key].unix();
      }
    });

    const formattedValues = formatCustomFieldCreatePayload({
      payload,
      customFields,
    });

    if (formattedValues?.error) {
      setErrors(formattedValues.errorMap);
      return;
    }

    setErrors({});
    const result = await (certificationId
      ? dispatch(updateCertification(entityType, id, certificationId, formattedValues, files))
      : dispatch(createCertification(entityType, id, formattedValues, files)));

    if (result) {
      setFiles([]);
      onClose();
    }
  }, [entityType, files, customFields]);

  const onDrawerClose = useCallback(() => {
    setFiles([]);
    onClose();
  }, [setFiles, onClose]);

  return (
    <Drawer
      title={`${certificationId ? 'Edit' : 'Add'} Certification`}
      visible={visible}
      onClose={onDrawerClose}
      width={800}
      maskClosable={false}
      push={false}
    >
      <Form
        layout="vertical"
        form={form}
      >
        <Form.Item
          name="type"
          label="Type"
          rules={[
            { required: true, message: 'Please enter the certification type' },
          ]}
        >
          <OnTraccrTextInput />
        </Form.Item>
        <Form.Item
          name="acquiredDate"
          label="Acquired Date"
        >
          <DatePicker
            disabledDate={(d) => !d || (form && d.isAfter(
              form.getFieldValue('expiryDate') || null,
            ))}
          />
        </Form.Item>
        <Form.Item
          name="expiryDate"
          label="Expiry Date"
        >
          <DatePicker
            disabledDate={(d) => !d || (form && d.isBefore(
              form.getFieldValue('acquiredDate') || null,
            ))}
          />
        </Form.Item>
        <Form.Item
          name="issuer"
          label="Issuer"
        >
          <OnTraccrTextInput />
        </Form.Item>
        <Row className="user-add-certification-form-label">
          Files
        </Row>
        <FormFileInput
          files={files}
          setFiles={setFiles}
          useIconForButton={false}
        />
        { !!customFields?.length && (
          <Form.Item
            name="customData"
            style={{ marginTop: 20, marginBottm: 20, width: '100%' }}
          >
            <CustomFields
              key={`${certificationId}${shouldUpdate}`} // Need to force re-render
              initialValues={certificationCustomData}
              customFields={customFields}
              divisions={relevantDivisions}
              errors={errors}
              fileMap={certificationCustomDataFiles}
              isDisplay={false}
            />
          </Form.Item>
        )}
      </Form>
      <DrawerSubmitFooter
        onClose={onDrawerClose}
        onDelete={() => onDeleteClick(certification)}
        onSubmit={onFormSubmit}
      />
    </Drawer>
  );
}

AddEditCertification.propTypes = {
  id: PropTypes.string.isRequired,
  certificationId: PropTypes.string,
  certification: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    acquiredDate: PropTypes.number,
    expiryDate: PropTypes.number,
    issuer: PropTypes.string,
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onDeleteClick: PropTypes.func,
  entityType: PropTypes.string.isRequired,
};

AddEditCertification.defaultProps = {
  certificationId: null,
  certification: {},
  visible: false,
  onClose: () => {},
  onDeleteClick: () => {},
};
