import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Divider,
  Badge,
  message,
} from 'antd';
import axios from 'axios';

import CompanyEditRow from '../../CompanyEditRow';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../../common/text/DisplayText';

import SalesRabbitWorkflows from './SalesRabbitWorkflows';
import SalesRabbitRegions from './SalesRabbitRegions';

import Debouncer from '../../../helpers/Debouncer';

import Colors from '../../../constants/Colors';

import Permissions from '../../../auth/Permissions';

import { showDisconnectConfirm } from '../integrationHelpers';

import {
  getSalesRabbitWorkflows,
  getSalesRabbitRegions,
} from './state/salesrabbit.actions';

const INPUT_STYLE = {
  marginLeft: 10,
  marginRight: 40,
  marginBottom: 20,
  maxWidth: 440,
};
const DIVIDER_STYLE = {
  margin: 0,
  backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
  maxWidth: 440,
  minWidth: 440,
};

const debouncer = new Debouncer();

function SalesRabbit({
  visible,
}) {
  const dispatch = useDispatch();

  const { userId: ownerId } = useSelector((state) => state.settings.company);

  const [accessToken, setAccessToken] = useState();

  const onTextChanged = useCallback((e) => {
    const {
      target: { value } = {},
    } = e;
    setAccessToken(value);
    debouncer.debounce(async () => {
      if (value) {
        try {
          await axios.put('/salesrabbit/connect', { token: value });
        } catch (err) {
          message.error('Failed to update SalesRabbit access token');
        }
      }
    }, 250);
  }, []);

  const onDisconnect = useCallback(() => {
    showDisconnectConfirm({
      type: 'SalesRabbit',
      onConfirm: async () => {
        try {
          await axios.delete('/salesrabbit/connection');
          setAccessToken();
        } catch (err) {
          message.error('Failed to disconnect SalesRabbit');
        }
      },
    });
  }, []);

  useEffect(() => {
    const getToken = async () => {
      const { data: token } = await axios.get('/salesrabbit/token');
      setAccessToken(token);
    };
    if (visible) {
      getToken();
      dispatch(getSalesRabbitWorkflows());
      dispatch(getSalesRabbitRegions());
    } else {
      setAccessToken();
    }
  }, [visible]);

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <CompanyEditRow
        title="Status"
      >
        <Badge
          color={
            accessToken
              ? 'green'
              : 'red'
          }
          style={{ paddingRight: 10 }}
        />
      </CompanyEditRow>
      <div style={{ width: '100%', position: 'relative', marginTop: 10 }}>
        <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
          <Col>
            <DisplayText
              title="Access Token"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
          </Col>
        </Row>
        <DisplayText
          title={(
            <div>
              This code authorizes Ontraccr to talk to SalesRabbit
              <br />
              <a href="https://developers.salesrabbit.com/docs/public-api/ZG9jOjE1NTkwMDU5-authentication#obtaining-a-token">
                Read more here
              </a>
            </div>
          )}
          style={{
            marginBottom: 10,
            paddingLeft: 10,
            fontFamily: 'roboto-regular',
            color: Colors.ONTRACCR_OPACITY_GRAY,
          }}
        />
        <OnTraccrTextInput
          style={INPUT_STYLE}
          onChange={onTextChanged}
          autoComplete="new-password"
          placeholder="Update Access Token"
          password
          value={accessToken}
        />
      </div>
      {!!accessToken && (
      <>
        <Divider style={DIVIDER_STYLE} />
        <SalesRabbitWorkflows />
        <Divider style={{ ...DIVIDER_STYLE, margin: '10px 0px' }} />
        <SalesRabbitRegions />
        <Divider style={DIVIDER_STYLE} />

        <Row style={{ width: '100%' }} justify="center">
          <OnTraccrButton
            title="Deactivate"
            type={Permissions.id === ownerId ? 'primary' : 'back'}
            disabled={Permissions.id !== ownerId}
            style={{ margin: 10 }}
            onClick={onDisconnect}
          />
          <DisplayText
            style={{
              textAlign: 'center',
              fontFamily: 'roboto-bold',
              color: Colors.ONTRACCR_OPACITY_GRAY,
              height: 32,
            }}
            title="Only the account owner can deactivate"
          />
        </Row>
      </>
      )}
    </div>
  );
}

SalesRabbit.propTypes = {
  visible: PropTypes.bool,
};

SalesRabbit.defaultProps = {
  visible: false,
};

export default SalesRabbit;
