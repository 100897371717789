import React from 'react';
import { Row, Col } from 'antd';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomModal from '../common/modals/CustomModal';

import colors from '../constants/Colors';

export default ({
  title,
  visible,
  onClose,
  onDelete,
}) => {
  const titleText = `Delete "${title}" status?`;
  return (
    <CustomModal
      title={titleText}
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
    >
      <div style={{ fontSize:14, padding: 10 }}>
        <b>Warning:</b> Deleting a status will delete all cards with that status
      </div>
      <Row justify='center' gutter={12} style={{ marginBottom: 10 }}>
        <Col>
          <OnTraccrButton
            type='cancel'
            title='Cancel'
            roundStyle
            onClick={onClose}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title='Delete'
            roundStyle
            onClick={onDelete}
          />
        </Col>
      </Row>
    </CustomModal>
  )
}