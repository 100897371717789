import React from 'react';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import DrawerFooter from '../../common/containers/DrawerFooter';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import TimeCardApprovalButton, { APPROVE_TIMECARD_SUBMISSION, REJECT_TIMECARD_SUBMISSION } from '../../timecards/TimeCardApprovalButton';
import Colors from '../../constants/Colors';

function ManualEntryFooter({
  loading,
  onSubmit,
  onClose,
  isDisplay,
  canEdit,
  onEditClicked,
  day,
  isApprovals,
  userId,
  customDataLoading,
}) {
  const approvalEditStyle = {
    backgroundColor: 'white',
    color: Colors.ONTRACCR_RED,
    margin: 2,
  };

  if (isDisplay) {
    if (!canEdit && !isApprovals) return null;
    return (
      <DrawerFooter>
        {isApprovals && (
          <>
            <TimeCardApprovalButton
              record={day}
              type={APPROVE_TIMECARD_SUBMISSION}
              userId={userId}
              onSubmit={onClose}
            />
            <TimeCardApprovalButton
              record={day}
              type={REJECT_TIMECARD_SUBMISSION}
              style={{ backgroundColor: 'grey', borderColor: 'grey' }}
              userId={userId}
              onSubmit={onClose}
            />
          </>
        )}
        {canEdit && (
          <OnTraccrButton
            title="Edit"
            onClick={onEditClicked}
            style={isApprovals && approvalEditStyle}
          />
        )}
      </DrawerFooter>
    );
  }
  return (
    <DrawerSubmitFooter
      onSubmit={onSubmit}
      onClose={onClose}
      loading={loading}
      submitTitle="Save"
      canSubmit={!customDataLoading}
    />
  );
}

ManualEntryFooter.propTypes = {
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  isDisplay: PropTypes.bool.isRequired,
  canEdit: PropTypes.bool.isRequired,
  day: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  isApprovals: PropTypes.bool,
  userId: PropTypes.string.isRequired,
  customDataLoading: PropTypes.bool,
};

ManualEntryFooter.defaultProps = {
  day: {},
  isApprovals: false,
  customDataLoading: false,
};

export default ManualEntryFooter;
