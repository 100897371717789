import axios from 'axios';
import Analytics from '../../helpers/Analytics';
import { request } from '../../helpers/requests';


const LearningCenterService = {
  getTutorial: async (type) => {
    Analytics.track(`OpenTutorial/${type}`);
    const { data: signedUrl } = await request({
      call: axios.get(`/tutorials/${type}`),
      errMsg: `Could not load ${type} Tutorial`,
      hideSuccessToast: true,
    });
    if (signedUrl) window.open(signedUrl);
  },
  getTutorials: async () => {
    const { data } = await request({
      call: axios.get('/tutorials'),
      errMsg: 'Could not load Tutorials',
      hideSuccessToast: true,
    });
    return data;
  },
};

export default LearningCenterService;
