import * as XLSX from 'xlsx';
import { notification } from 'antd';
import { DateTime } from 'luxon';
import {
  PROGRESS_ROW_TYPE_HOURS,
  PROGRESS_ROW_TYPE_LABOR_COST,
  PROGRESS_ROW_TYPE_MATERIAL_SPEND,
  PROGRESS_ROW_TYPE_SPEND,
} from '../progressConstants';
import { computeProgress, getActualCostForCostCode, getProgressKey } from '../progressHelpers';
import PDFTableExport from '../../../common/pdf/PDFExport/PDFTableExport';
import { getFormattedCurrency, getPhaseCostcodeKey } from '../../../forms/formHelpers';

// CONSTANTS:
// -------------------------------//
export const MATERIAL_EXPORT_TYPE = 'Material Costcodes';
export const OVERHEAD_EXPORT_TYPE = 'Overhead Costcodes';
export const LABOR_EXPORT_TYPE = 'Unphased Labor Costcodes';
export const PHASED_EXPORT_TYPE = 'Phased Labor Costcodes';
export const SUMMARY_EXPORT_TYPE = 'Summary';

export const DEFAULT_SUMMARY_HEADERS = {
  'Total Cost-To-Date': 'totalCostToDate',
  'Material Cost-To-Date': 'materialCostToDate',
  'Overhead Cost-To-Date': 'overheadCostToDate',
  'Labor Cost-To-Date': 'laborCostToDate',
  'Sub-Contract Cost-To-Date': 'subcontractCostToDate',
}

export const DEFAULT_MATERIAL_HEADERS = {
  'Name': 'name',
  'Code': 'code',
  'Estimated Cost': 'estimatedCost',
  'Actual Spend': 'actualSpend',
  'Actual Spend Progress (%)': 'spendProgress',
  'Estimated Quantity': 'estimatedQuantity',
  'Actual Quantity': 'quantityUsed',
};

export const DEFAULT_OVERHEAD_HEADERS = {
  'Name': 'name',
  'Code': 'code',
  'Estimated Cost': 'estimatedCost',
  'Actual Spend': 'actualSpend',
  'Actual Spend Progress (%)': 'spendProgress',
  'Estimated Hours': 'hours',
  'Actual Hours': 'actualHours',
  'Hours Used Progress (%)': 'hoursProgress',
  'Activity Progress (%)': 'activityProgress',
};

export const DEFAULT_UNPHASED_LABOUR_HEADERS = {
  'Name': 'name',
  'Code': 'code',
  'Estimated Hours': 'hours',
  'Actual Hours': 'actualHours',
  'Estimated Cost': 'estimatedCost',
  'Actual Cost': 'actualCost',
  'Hours Used Progress (%)': 'hoursProgress',
  'Activity Progress (%)': 'activityProgress',
  'Actual Cost Progress (%)': 'costProgress',
};

export const DEFAULT_PHASED_COSTCODES_HEADERS = {
  'Name': 'name',
  'Code': 'code',
  'Activity Progress (%)': 'activityProgress',
};

// HELPERS:
// -------------------------------//

/**
 * Retrieves the correct headers depending on type
 * @param {string} type
 * @returns {object} - containing headers
 */
export const getHeaders = (type) => {
  switch (type) {
    case SUMMARY_EXPORT_TYPE:
      return DEFAULT_SUMMARY_HEADERS;
    case MATERIAL_EXPORT_TYPE:
      return DEFAULT_MATERIAL_HEADERS;
    case OVERHEAD_EXPORT_TYPE:
      return DEFAULT_OVERHEAD_HEADERS;
    case LABOR_EXPORT_TYPE:
      return DEFAULT_UNPHASED_LABOUR_HEADERS;
    case PHASED_EXPORT_TYPE:
      return DEFAULT_PHASED_COSTCODES_HEADERS;
    default:
      return {};
  }
};

/**
 * Merges and formats Aoas (array of array)
 * @param {object} aoaObj - maps type to aoa
 * @returns {array} aoa
 */
export const combineAoa = (aoaObj) => {
  let combinedAoa = [];
  Object.entries(aoaObj).forEach(([title, aoa]) => {
    const numCols = aoa[0].length;
    const emptyRow = new Array(numCols).fill(' ');
    const titleRow = new Array(numCols).fill(' ');
    titleRow[0] = title;
    combinedAoa = [...combinedAoa, titleRow, ...aoa, emptyRow, emptyRow ];
  });
  return combinedAoa;
};

/**
 * Converts progress data into aoa (array of array) format
 * @param {string} type - type of data
 * @returns {array} aoa
 */
export const generateAoaFromProgressData = (type, dataToConvert) => {
  const headers = getHeaders(type);
  const body = dataToConvert.map((row) => {
    return Object.entries(headers).map(([_colName, key]) => {
      if ((key === 'spendProgress' || key === 'activityProgress' || key === 'hoursProgress') && !row[key]) return 0;
      return row[key];
    })
});
  const headerArray = Object.keys(headers);
  const combinedAoa = [headerArray].concat(body);
  return {
    headers: headerArray,
    body,
    combinedAoa,
  };
};

/**
 * Exports Spreadsheet
 * @param {string} title
 * @param {string} dateRange
 * @param {array} aoaSummaryData
 * @param {array} aoaMaterialData
 * @param {array} aoaOverheadData
 * @param {array} aoaLaborData
 * @param {array} aoaPhasedData
 */
const exportSpreadsheet = ({
  title,
  dateRange,
  aoaSummaryData,
  aoaMaterialData,
  aoaOverheadData,
  aoaLaborData,
  aoaPhasedData,
}) => {
  const spreadsheetData = {
    'Date Range': [[dateRange]],
    [SUMMARY_EXPORT_TYPE]: aoaSummaryData,
    [MATERIAL_EXPORT_TYPE]: aoaMaterialData,
    [OVERHEAD_EXPORT_TYPE]: aoaOverheadData,
    [LABOR_EXPORT_TYPE]: aoaLaborData,
    [PHASED_EXPORT_TYPE]: aoaPhasedData,
  };

  if (!dateRange) delete spreadsheetData['Date Range'];
  const combinedAoa = combineAoa(spreadsheetData);
  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(combinedAoa);
  worksheet['!cols'] = [{ wch: 21 }];
  XLSX.utils.book_append_sheet(workbook, worksheet, title.slice(0, 31));
  XLSX.writeFile(workbook, `${title}.xlsx`);
};

/**
 * Exports PDF
 * @param {string} title
 * @param {string} dateRange
 * @param {object} companyImageURL
 * @param {array} summaryHeaders
 * @param {array} summaryBody
 * @param {array} materialHeaders
 * @param {array} materialBody
 * @param {array} overheadHeaders
 * @param {array} overheadBody
 * @param {array} laborHeaders
 * @param {array} laborBody
 * @param {array} phasedHeaders
 * @param {array} phasedBody
 */
const exportPDF = async ({
  title,
  dateRange,
  companyImageURL,
  summaryHeaders,
  summaryBody,
  materialHeaders,
  materialBody,
  overheadHeaders,
  overheadBody,
  laborHeaders,
  laborBody,
  phasedHeaders,
  phasedBody,
}) => {
  const pdfExportConstructor = new PDFTableExport({
    styles: {
      header: {
        fontSize: 11,
        bold: true,
        alignment: 'left',
      },
      tableHeader: {
        alignment: 'center',
        fillColor: '#eeeeee',
        fontSize: 8,
        bold: true,
      },
      table: {
        margin: [0, 10, 0, 0],
        alignment: 'center',
        fontSize: 7,
        bold: true,
      },
    },
  });

  let titleRow = title;

  if (dateRange) {
    titleRow = `${title} (${dateRange})`;
  }

  // Prepare Header:
  const logoEntry = await PDFTableExport.getCompanyLogo(companyImageURL);
  const leftColumn = PDFTableExport.createColumnList({
    content: [
      { text: titleRow, style: 'header' },
    ],
  });
  const header = PDFTableExport.createHeader({
    useLogo: true,
    logoEntry,
    columns: [leftColumn],
  });

  // Prepare Summary Table:
  const adjustedSummaryHeaders = summaryHeaders.map((text) => ({ text, style: 'tableHeader' }));
  const summaryTable = PDFTableExport.createTable({
    widths: Array(adjustedSummaryHeaders.length).fill('auto'),
    header: adjustedSummaryHeaders,
    rows: summaryBody,
  });

  // Prepare Materials Table:
  const adjustedMaterialHeaders = materialHeaders.map((text) => ({ text, style: 'tableHeader' }));
  const materialsTable = PDFTableExport.createTable({
    widths: Array(adjustedMaterialHeaders.length).fill('auto'),
    header: adjustedMaterialHeaders,
    rows: materialBody,
  });

  // Prepare Overhead Table:
  const adjustedOverheadHeaders = overheadHeaders.map((text) => ({ text, style: 'tableHeader' }));
  const overheadTable = PDFTableExport.createTable({
    widths: Array(adjustedOverheadHeaders.length).fill('auto'),
    header: adjustedOverheadHeaders,
    rows: overheadBody,
  });

  // Prepare Labor Table:
  const adjustedLaborHeaders = laborHeaders.map((text) => ({ text, style: 'tableHeader' }));
  const laborTable = PDFTableExport.createTable({
    widths: Array(adjustedLaborHeaders.length).fill('auto'),
    header: adjustedLaborHeaders,
    rows: laborBody,
  });

  // Prepare Phased Table:
  const adjustedPhasedHeaders = phasedHeaders.map((text) => ({ text, style: 'tableHeader' }));
  const phasedTable = PDFTableExport.createTable({
    widths: Array(adjustedPhasedHeaders.length).fill('auto'),
    header: adjustedPhasedHeaders,
    rows: phasedBody,
  });

  // Export PDF:
  pdfExportConstructor.export({
    name: title,
    header,
    body: [
      summaryTable,
      materialsTable,
      overheadTable,
      laborTable,
      phasedTable,
    ],
  });
};

const formatDate = (date) => DateTime.fromMillis(date).toLocaleString(DateTime.DATE_FULL);

/**
 * Prepares and generates specified export file if possible, otherwise notifies user
 * @param {boolean} isPDF
 * @param {object} companyImageURL
 * @param {array} summaryData
 * @param {array} laborData
 * @param {array} materialData
 * @param {array} overheadData
 * @param {array} phasedData
 * @param {object} dateRange
 */
export const exportFile = ({
  isPDF,
  companyImageURL,
  summaryData,
  laborData,
  materialData,
  overheadData,
  phasedData,
  title,
  dateRange,
}) => {
  try {
    // Get Aoa data:
    const {
      headers: summaryHeaders,
      body: summaryBody,
      combinedAoa: aoaSummaryData,
    } = generateAoaFromProgressData(SUMMARY_EXPORT_TYPE, summaryData);
    const {
      headers: materialHeaders,
      body: materialBody,
      combinedAoa: aoaMaterialData,
    } = generateAoaFromProgressData(MATERIAL_EXPORT_TYPE, materialData);
    const {
      headers: overheadHeaders,
      body: overheadBody,
      combinedAoa: aoaOverheadData,
    } = generateAoaFromProgressData(OVERHEAD_EXPORT_TYPE, overheadData);
    const {
      headers: laborHeaders,
      body: laborBody,
      combinedAoa: aoaLaborData,
    } = generateAoaFromProgressData(LABOR_EXPORT_TYPE, laborData);
    const {
      headers: phasedHeaders,
      body: phasedBody,
      combinedAoa: aoaPhasedData,
    } = generateAoaFromProgressData(PHASED_EXPORT_TYPE, phasedData);

    const formattedDateRange = dateRange
      ? `${formatDate(dateRange.start)} - ${formatDate(dateRange.end)}`
      : null;

    if (isPDF) {
      exportPDF({
        title,
        dateRange: formattedDateRange,
        companyImageURL,
        summaryHeaders,
        summaryBody,
        materialHeaders,
        materialBody,
        overheadHeaders,
        overheadBody,
        laborHeaders,
        laborBody,
        phasedHeaders,
        phasedBody,
      });
    } else {
      exportSpreadsheet({
        title,
        dateRange: formattedDateRange,
        aoaSummaryData,
        aoaMaterialData,
        aoaOverheadData,
        aoaLaborData,
        aoaPhasedData,
      });
    }
  } catch (error) {
    let message = 'Details:';
    if (error.name) message += ` ${error.name}`;
    if (error.message) message += ` ${error.message}`;
    if (message.length === 8) message += ' Unknown';
    notification.error({
      message: 'Failed to Export Report',
      description: message,
    });
  }
};

/**
 * Gets latest progress details given costcode and progressMap
 * @param {object} costcode
 * @param {object} progressMap
 * @returns {object} - progress, quantityUsed, actualSpend for given costcode
 */
 export const getLatestProgressDataForCostcode = (costcode, progressMap) => {
  const { projectId, phaseId, costcodeId } = costcode;
  const key = getProgressKey({ projectId, phaseId, costcodeId });
  const progressArray = progressMap[key] || [];
  const { length } = progressArray;
  const {
    progress = 0,
    quantityUsed = 0,
    actualSpend = 0,
  } = progressArray[length - 1] || {};
  return {
    progress: parseInt(progress * 100, 10),
    quantityUsed,
    actualSpend,
  };
};

/**
 * Gets the actual hours associated with the given costcode
 * @param {object} costcode
 * @param {object} costcodeDetailsMap
 * @returns {object} - actualHours
 */
export const getActualHoursDataForCostcode = ({ costcode, costcodeDetailsMap }) => {
  const { id: costcodeId, phaseId } = costcode;
  const phaseCostcodeKey = getPhaseCostcodeKey(phaseId, costcodeId);
  const actualHours = costcodeDetailsMap[phaseCostcodeKey] ? costcodeDetailsMap[phaseCostcodeKey].actualHours : 0;
  return {
    actualHours: actualHours || 0,
  };
};

/**
 * Prepares export data
 * @param {array | object} data
 * @param {string} type
 * @param {object} progressMap
 * @param {object} costcodeDetailsMap
 * @param {object} userMap
 * @returns {array}
 */
export const prepareExportData = ({
  data,
  type,
  progressMap,
  costcodeDetailsMap,
  userMap,
  averageWage,
}) => {
  if (
    type !== SUMMARY_EXPORT_TYPE
    && (!data || !progressMap || !costcodeDetailsMap)
  ) {
    return data;
  }
  switch (type) {
    case MATERIAL_EXPORT_TYPE:
      return data.map((costcode) => {
        const { estimatedCost } = costcode;
        const { actualSpend, quantityUsed } = getLatestProgressDataForCostcode(costcode, progressMap);
        const spendProgress = computeProgress({ displayMode: true, type: PROGRESS_ROW_TYPE_MATERIAL_SPEND, estimatedCost, actualSpend });
        return { ...costcode, actualSpend, quantityUsed, spendProgress };
      });
    case OVERHEAD_EXPORT_TYPE:
      return data.map((costcode) => {
        const { estimatedCost, hours: estimatedHours } = costcode;
        const { actualSpend, progress: activityProgress } = getLatestProgressDataForCostcode(costcode, progressMap);
        const { actualHours } = getActualHoursDataForCostcode({ costcode, costcodeDetailsMap });
        const hoursProgress = computeProgress({ displayMode: true, type: PROGRESS_ROW_TYPE_HOURS, actualHours, estimatedHours });
        const spendProgress = computeProgress({ displayMode: true, type: PROGRESS_ROW_TYPE_SPEND, actualSpend, estimatedCost });
        return { ...costcode, actualSpend, actualHours, activityProgress, hoursProgress, spendProgress };
      });
    case LABOR_EXPORT_TYPE:
      return data.map((costcode) => {
        const { hours: estimatedHours, id: costcodeId, phaseId } = costcode;
        const { progress: activityProgress } = getLatestProgressDataForCostcode(costcode, progressMap);
        const { actualHours } = getActualHoursDataForCostcode({ costcode, costcodeDetailsMap });
        const hoursProgress = computeProgress({ displayMode: true, type: PROGRESS_ROW_TYPE_HOURS, actualHours, estimatedHours });
        const estimatedCost = estimatedHours * averageWage;
        const actualCost = getActualCostForCostCode({
          costcodeDetailsMap,
          costcodeId,
          phaseId,
          userMap,
        });
        const costProgress = computeProgress({
          displayMode: true,
          type: PROGRESS_ROW_TYPE_LABOR_COST,
          actualLaborCost: actualCost,
          estimatedLaborCost: estimatedCost,
        });
        return {
          ...costcode,
          actualHours,
          activityProgress,
          hoursProgress,
          estimatedCost,
          actualCost,
          costProgress,
        };
      });
    case PHASED_EXPORT_TYPE: {
      const phasedLaborCostcodes = [];
      Object.values(data).forEach((phase) => {
        if (!phase.children || !Array.isArray(phase.children)) return;
        phase.children.forEach((phasedLaborCostcode) => {
          const {
            progress: activityProgress,
          } = getLatestProgressDataForCostcode(phasedLaborCostcode, progressMap);
          phasedLaborCostcodes.push({ ...phasedLaborCostcode, activityProgress });
        });
      });
      return phasedLaborCostcodes;
    }
    case SUMMARY_EXPORT_TYPE: {
      const {
        estimatedTotalCost,
        actualTotalSpend,
        estimatedMaterialCost,
        actualMaterialSpend,
        estimatedOverheadCost,
        actualOverheadSpend,
        estimatedLaborCost,
        actualLaborSpend,
        estimatedSubcontractCost,
        actualSubcontractSpend,
      } = data[0];

      const formatSummaryDate = (estimatedValue, actualValue) => {
        const fixedEstimatedValue = estimatedValue.toFixed(2);
        const fixedActualValue = actualValue.toFixed(2);

        return `${getFormattedCurrency(fixedActualValue)} / ${getFormattedCurrency(fixedEstimatedValue)}`;
      };

      return [{
        totalCostToDate: formatSummaryDate(estimatedTotalCost, actualTotalSpend),
        materialCostToDate: formatSummaryDate(estimatedMaterialCost, actualMaterialSpend),
        overheadCostToDate: formatSummaryDate(estimatedOverheadCost, actualOverheadSpend),
        laborCostToDate: formatSummaryDate(estimatedLaborCost, actualLaborSpend),
        subcontractCostToDate: formatSummaryDate(
          estimatedSubcontractCost,
          actualSubcontractSpend,
        ),
      }];
    }
    default:
      return data;
  }
};
