import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { Drawer } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import QuickBooksEmployeeSync from './QuickBooksEmployeeSync';
import QuickbooksProjectSync from './QuickbooksProjectSync';
import QuickBooksCostCodeSync from './QuickBooksCostCodeSync';
import QuickBooksAutoSync from './QuickBooksAutoSync';
import QuickBooksContactSync from './QuickBooksContactSync';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DrawerFooter from '../../../common/containers/DrawerFooter';
import StepsContainer from '../../../common/containers/StepsContainer';
import QuickBooksMaterialSync from './QuickBooksMaterialSync';

import { getMaterials } from '../../../materials/state/materials.actions';

export default function QBImportDrawer({
  visible,
  quickBooksUsers = [],
  quickBooksProjects = [],
  quickBooksCostcodes = [],
  quickBooksMaterials = [],
  quickBooksCustomers = [],
  quickBooksVendors = [],
  onClose,
  onSave,
  qbIntegrationPush = true,
  qbIntegrationPull = true,
  divisionFilter,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [currentStep, setCurrentStep] = useState(0);
  const [key, setKey] = useState(0);
  const [importUsers, setImportUsers] = useState([]);
  const [importProjects, setImportProjects] = useState([]);
  const [importCostcodes, setImportCostcodes] = useState([]);
  const [importMaterials, setImportMaterials] = useState([]);
  const [importCustomers, setImportCustomers] = useState([]);
  const [importVendors, setImportVendors] = useState([]);
  const [syncSettings, setSyncSettings] = useState({
    qbIntegrationPush,
    qbIntegrationPull,
  });

  useEffect(() => {
    if (!visible) {
      setKey(key + 1); // Refresh on close
      setCurrentStep(0);
      setSyncSettings({
        qbIntegrationPush,
        qbIntegrationPull,
      });
    } else {
      dispatch(getMaterials());
    }
  }, [visible]);

  useEffect(() => {
    setSyncSettings({
      qbIntegrationPush,
      qbIntegrationPull,
    });
  }, [qbIntegrationPush, qbIntegrationPull]);

  const steps = useMemo(() => [
    {
      title: 'Employees',
      key: 0,
      content: (
        <QuickBooksEmployeeSync
          quickBooksUsers={quickBooksUsers}
          onUsersChanged={setImportUsers}
          importUsers={importUsers}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: t('Project', { count: 2 }),
      key: 1,
      content: (
        <QuickbooksProjectSync
          quickBooksProjects={quickBooksProjects}
          key={`ProjectSync-${key}`}
          onProjectsChanged={setImportProjects}
          importProjects={importProjects}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: 'Cost Codes',
      key: 2,
      content: (
        <QuickBooksCostCodeSync
          quickbooksCostcodes={quickBooksCostcodes}
          key={`CostcodeSync-${key}`}
          onCostcodesChanged={setImportCostcodes}
          importCostcodes={importCostcodes}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: 'Materials',
      key: 3,
      content: (
        <QuickBooksMaterialSync
          quickBooksMaterials={quickBooksMaterials}
          key={`MaterialSync-${key}`}
          onMaterialsChanged={setImportMaterials}
          importMaterials={importMaterials}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: 'Customers',
      key: 4,
      content: (
        <QuickBooksContactSync
          key={`CustomerSync-${key}`}
          type="customer"
          quickBooksContacts={quickBooksCustomers}
          onContactsChanged={setImportCustomers}
          importContacts={importCustomers}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: 'Vendors',
      key: 5,
      content: (
        <QuickBooksContactSync
          key={`VendorSync-${key}`}
          type="vendor"
          quickBooksContacts={quickBooksVendors}
          onContactsChanged={setImportVendors}
          importContacts={importVendors}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: 'Auto-Sync',
      key: 6,
      content: (
        <QuickBooksAutoSync
          syncSettings={syncSettings}
          onChanged={setSyncSettings}
        />
      ),
    },
  ], [
    quickBooksUsers,
    quickBooksProjects,
    quickBooksCostcodes,
    quickBooksMaterials,
    quickBooksCustomers,
    quickBooksVendors,
    importUsers,
    importProjects,
    importCostcodes,
    importMaterials,
    importCustomers,
    importVendors,
    syncSettings,
    divisionFilter,
    key,
  ]);

  return (
    <Drawer
      title='Import from QuickBooks'
      visible={visible}
      width='90%'
      onClose={onClose}
    >
      <div style={{ height: 'calc(100% - 55px)', width: '100%', overflowY: 'scroll' }}>
        <StepsContainer
          currentStep={currentStep}
          steps={steps}
        />
      </div>
      <DrawerFooter>
        {currentStep > 0 && <OnTraccrButton
          type='back'
          title='Back'
          onClick={() => setCurrentStep(currentStep - 1)}
          style={{ marginRight: 8 }}
        />}
        <OnTraccrButton
          title={currentStep === steps.length - 1 ? 'Sync' : 'Next'}
          onClick={() => {
            if (currentStep === steps.length - 1) {
              return onSave({
                importUsers,
                importProjects,
                importCostcodes,
                importMaterials,
                importCustomers,
                importVendors,
                syncSettings,
              });
            }
            setCurrentStep(currentStep + 1);
          }}
        />
      </DrawerFooter>
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
QBImportDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  quickBooksUsers: PropTypes.array,
  quickBooksProjects: PropTypes.array,
  quickBooksCostcodes: PropTypes.array,
  quickBooksMaterials: PropTypes.array,
  quickBooksCustomers: PropTypes.array,
  quickBooksVendors: PropTypes.array,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  qbIntegrationPush: PropTypes.bool,
  qbIntegrationPull: PropTypes.bool,
};

QBImportDrawer.defaultProps = {
  quickBooksUsers: [],
  quickBooksProjects: [],
  quickBooksCostcodes: [],
  quickBooksMaterials: [],
  quickBooksCustomers: [],
  quickBooksVendors: [],
  qbIntegrationPush: true,
  qbIntegrationPull: true,
};
