/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Typography } from 'antd';
import { HistoryOutlined, InfoCircleOutlined, UploadOutlined } from '@ant-design/icons';
import Chart from 'react-google-charts';
import { DateTime } from 'luxon';

// Import Components:
import BorderlessButton from '../../common/buttons/BorderlessButton';
import ProgressRow from './ProgressRow';
import ProgressMaterialUpdate from './ProgressMaterialUpdate';
import ProjectSpendUpdate from './ProjectSpendUpdate';

// Import Helpers:
import { isNullOrUndefined, toTitleCase } from '../../helpers/helpers';
import {
  getProgressRowStyling,
  getFormattedHoursLabel,
  getStackedEstimatesStyling,
  getStackedActualStyling,
  getActualCostForCostCode,
  getActualSpend,
  getBilledAmountForCostCode,
  getBilledFromCostUpdates,
} from './progressHelpers';
import { getFormattedCurrency, getPhaseCostcodeKey, getFormattedDate } from '../../forms/formHelpers';
import { getMarkupString } from '../../materials/materialsHelpers';

// Import Constants:
import {
  PROGRESS_ROW_TYPE_MATERIAL_SPEND,
  PROGRESS_ROW_TYPE_SPEND,
  PROGRESS_ROW_TYPE_HOURS,
  PROGRESS_ROW_TYPE_ACTIVITY,
  PROGRESS_ROW_TYPE_LABOR_COST,
} from './progressConstants';

const { Text } = Typography;

const roundToTwoDecimals = (value) => (Math.round(value * 100) / 100);

// COLUMN COMPONENTS:
// ----------------------------------------------------
const nameColumn = {
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  width: 90,
  align: 'left',
  render: (text, record) => (
    <div style={{
      textAlign: 'left',
      width: '100%',
      paddingLeft: record.subItem ? 55 : 20,
    }}>
      {text}
    </div>
  ),
};

const codeColumn = {
  title: 'Code',
  dataIndex: 'code',
  width: 90,
  align: 'center',
};

const typeColumn = {
  title: 'Type',
  dataIndex: 'type',
  key: 'type',
  width: 70,
  align: 'center',
  render: (text, record) => {
    const { category = 'Cost Code' } = record || {};
    return text === 'costcode' ? category : toTitleCase(text);
  },
};

const estimatedCostColumn = {
  title: 'Estimated Cost',
  dataIndex: 'estimatedCost',
  key: 'estimatedCost',
  width: 90,
  align: 'center',
  render: (_, record) => {
    const { estimatedCost } = record || {};
    return estimatedCost ? `$ ${estimatedCost}` : '-';
  },
};

const estimatedHoursCostColumn = ({ averageWage }) => ({
  title: 'Estimated Hours/Cost',
  key: 'estimatedHoursCost',
  width: 90,
  align: 'center',
  render: (_, record) => {
    const {
      type,
      hours: estimatedHours,
      estimatedCost,
      category,
    } = record || {};
    if (type !== 'costcode') return null;
    let estimatedCostToUse = estimatedCost;
    if (category === 'Labor' && isNullOrUndefined(estimatedCostToUse)) {
      estimatedCostToUse = (estimatedHours * averageWage).toFixed(2);
    }
    const estimatedHoursLabel = estimatedHours ? `${estimatedHours} h` : '-';
    const estimatedCostLabel = estimatedCostToUse ? `$ ${estimatedCostToUse}` : '-';
    const styling = getStackedEstimatesStyling({
      useBothEstimates: estimatedHours && estimatedCostToUse,
    });

    return (
      <div style={{ display: 'grid', ...styling }}>
        {estimatedHours && <span>{estimatedHoursLabel}</span>}
        {estimatedCostToUse ? <span>{estimatedCostLabel}</span> : null}
      </div>
    );
  },
});

const actualSpendColumn = (hasWrite) => ({
  title: 'Actual Spend',
  dataIndex: 'actualSpend',
  key: 'actualSpend',
  align: 'center',
  width: 90,
  render: (_, record) => {
    const { estimatedCost, type, category } = record || {};
    return (type === 'costcode'
      && category === 'Material'
      && estimatedCost
    )
      ? <ProjectSpendUpdate {...record} hasWrite={hasWrite} />
      : null;
  },
});

const quantityUsedColumn = (hasWrite) => ({
  title: 'Quantity Used',
  dataIndex: 'quantity',
  key: 'quantity',
  align: 'center',
  width: 90,
  render: (_, record) => (
    <ProgressMaterialUpdate {...record} hasWrite={hasWrite} />
  ),
});

const actualHoursSpendColumn = ({ projectCostcodeDetailsMap, averageWage, hasWrite }) => ({
  title: 'Actual Hours/Spend',
  key: 'actualHoursCost',
  width: 120,
  align: 'left',
  render: (_, record) => {
    const {
      hours: estimatedHours,
      costcodeId,
      phaseId,
      estimatedCost,
      type,
      category,
      children = [],
    } = record || {};
    if (type === 'phase') {
      let totalPhaseHours = 0;
      const nonMaterialCostcodes = children.filter(({ category: ccCategory }) => ccCategory !== 'Material');
      nonMaterialCostcodes.forEach(({ id: ccId, phaseId: ccPhaseId }) => {
        const phaseHours = getFormattedHoursLabel({
          costcodeDetailsMap: projectCostcodeDetailsMap,
          costcodeId: ccId,
          phaseId: ccPhaseId,
          useLabel: false,
        });

        totalPhaseHours += roundToTwoDecimals(phaseHours);
      });
      return (
        <div style={{ display: 'grid' }}>
          {totalPhaseHours?.toFixed(2)}
          {' '}
          h
        </div>
      );
    }
    const laborEstimatedCost = estimatedHours * averageWage;
    const useActualHoursLabel = type === 'costcode'
      && category !== 'Material'
      && estimatedHours;
    const useActualSpendLabel = type === 'costcode'
      && (estimatedCost || laborEstimatedCost);
    const actualHoursLabel = getFormattedHoursLabel({
      costcodeDetailsMap: projectCostcodeDetailsMap,
      costcodeId,
      phaseId,
    });
    const styling = getStackedActualStyling({
      useBothEstimates: useActualHoursLabel && useActualSpendLabel,
    });
    return (
      <div style={{ display: 'grid', ...styling }}>
        {useActualHoursLabel && <span>{actualHoursLabel}</span>}
        {useActualSpendLabel ? <ProjectSpendUpdate {...record} hasWrite={hasWrite} /> : null}
      </div>
    );
  },
});

const committedCostColumn = (projectCostcodeDetailsMap) => ({
  title: 'Committed Cost',
  key: 'committedCost',
  width: 50,
  align: 'center',
  render: (_, record) => {
    const { costcodeId, phaseId, type } = record || {};
    const phaseCostcodeKey = getPhaseCostcodeKey(phaseId, costcodeId);
    const { committedCost } = projectCostcodeDetailsMap[phaseCostcodeKey] || {};
    const formattedCommittedAmt = getFormattedCurrency(committedCost);
    return type === 'phase' ? null : formattedCommittedAmt;
  },
});

const historyColumn = (onHistory) => ({
  title: 'History',
  dataIndex: 'history',
  key: 'history',
  align: 'center',
  width: 40,
  render: (_, record) => (
    <BorderlessButton
      style={{ padding: 0, width: 40 }}
      iconNode={<HistoryOutlined style={{ marginLeft: 0 }} />}
      onClick={(e) => {
        e.stopPropagation();
        onHistory(record);
      }}
    />
  ),
});

const uploadInvoiceColumn = (onUploadInvoice) => ({
  title: 'Upload Invoice',
  dataIndex: 'invoice',
  key: 'invoice',
  align: 'center',
  width: 40,
  render: (_, record) => {
    const { type } = record ?? {};
    return type !== 'phase' ? (
      <BorderlessButton
        style={{ padding: 0, width: 40 }}
        iconNode={<UploadOutlined style={{ marginLeft: 0 }} />}
        onClick={(e) => {
          e.stopPropagation();
          onUploadInvoice(record);
        }}
      />
    ) : null;
  },
});

const costcodeProgressComponent = ({
  type, // One of Labor, Material, Overhead, Equipment
  isPhased = false,
  projectCostcodeDetailsMap = {},
  userMap = {},
  progresses = {},
  record = {},
  averageWage = 0,
  hasWrite,
}) => {
  const {
    hours: estimatedHours,
    estimatedCost,
    costcodeId,
    phaseId,
    projectId,
  } = record || {};
  const estimatedLaborCost = estimatedHours * averageWage;
  let estimatedCostToUse = estimatedCost;
  if (type === 'Labor' && isNullOrUndefined(estimatedCostToUse)) {
    estimatedCostToUse = estimatedLaborCost;
  }
  const useActualSpendProgressBar = (type !== 'Material') && estimatedCostToUse;
  const useActualHoursProgressBar = !!estimatedHours;
  const useActualLaborCostProgressBar = (type !== 'Material') && !!estimatedHours;
  const actualHoursLabel = getFormattedHoursLabel({
    costcodeDetailsMap: projectCostcodeDetailsMap,
    costcodeId,
    phaseId,
  });
  const styling = getProgressRowStyling({
    type,
    hasActualHoursProgressBar: useActualHoursProgressBar,
    hasActualLaborCostProgressBar: useActualLaborCostProgressBar,
  });

  const actualLaborCost = type !== 'Material' && getActualCostForCostCode({
    costcodeDetailsMap: projectCostcodeDetailsMap,
    costcodeId,
    phaseId,
    userMap,
  });

  const actualSpend = getActualSpend({
    costcodeId,
    phaseId,
    projectId,
    progresses,
  });

  if (type !== 'Material') {
    return (
      <div style={styling}>
        {useActualHoursProgressBar ? (
          <>
            <span>
              Hours Used (
              { actualHoursLabel }
              ):
            </span>
            <ProgressRow
              {...record}
              isPhased={isPhased}
              displayMode
              type={PROGRESS_ROW_TYPE_HOURS}
              isStacked
            />
          </>
        ) : null}
        {useActualSpendProgressBar ? (
          <>
            <span>
              Actual Spend ($
              {actualSpend.toFixed(2)}
              ):
            </span>
            <ProgressRow
              {...record}
              isPhased={isPhased}
              displayMode
              type={PROGRESS_ROW_TYPE_SPEND}
              isStacked
              category={type}
              estimatedLaborCost={estimatedLaborCost}
            />
          </>
        ) : null}
        { useActualLaborCostProgressBar ? (
          <>
            <span>
              Total Cost ($
              {(actualLaborCost + actualSpend).toFixed(2)}
              ):
            </span>
            <ProgressRow
              {...record}
              estimatedLaborCost={estimatedLaborCost}
              actualLaborCost={actualLaborCost}
              isPhased={isPhased}
              displayMode
              type={PROGRESS_ROW_TYPE_LABOR_COST}
              isStacked
              category={type}
            />
          </>
        ) : null}
        <span>Activity Progress:</span>
        <ProgressRow
          {...record}
          isPhased={isPhased}
          type={PROGRESS_ROW_TYPE_ACTIVITY}
          isStacked
          displayMode={!hasWrite}
          hasWrite={hasWrite}
        />
      </div>
    );
  }
  // render material progress bar:
  return (
    <div style={styling}>
      <span>
        Actual Spend ($
        {actualSpend.toFixed(2)}
        ):
      </span>
      <ProgressRow
        {...record}
        isPhased={isPhased}
        displayMode
        type={PROGRESS_ROW_TYPE_MATERIAL_SPEND}
      />
      <span>Activity Progress:</span>
      <ProgressRow
        {...record}
        isPhased={isPhased}
        type={PROGRESS_ROW_TYPE_ACTIVITY}
        isStacked
        displayMode={!hasWrite}
        hasWrite={hasWrite}
      />
    </div>
  );
};

const costcodeProgressColumn = ({
  type,
  projectCostcodeDetailsMap = {},
  userMap = {},
  averageWage = 0,
  progresses = {},
  hasWrite,
}) => ({
  title: 'Progress',
  dataIndex: 'name',
  key: 'name',
  render: (_, record) => costcodeProgressComponent({
    type,
    projectCostcodeDetailsMap,
    record,
    userMap,
    averageWage,
    progresses,
    hasWrite,
  }),
});

const phasedProgressColumn = ({
  projectCostcodeDetailsMap = {},
  userMap,
  progresses = {},
  averageWage,
  hasWrite,
}) => ({
  title: 'Progress',
  dataIndex: 'name',
  key: 'name',
  render: (_, record) => {
    const { type, category } = record || {};
    if (type === 'phase') {
      return <ProgressRow {...record} type={PROGRESS_ROW_TYPE_ACTIVITY} hasWrite={hasWrite} />;
    }
    if (type === 'costcode') {
      return costcodeProgressComponent({
        type: category,
        projectCostcodeDetailsMap,
        record,
        isPhased: true,
        averageWage,
        progresses,
        userMap,
        hasWrite,
      });
    }
    return null;
  },
});

const avgBillingRateColumn = (projectCostcodeDetailsMap = {}) => ({
  title: 'Avg. Billing Rate',
  key: 'avgBillingRate',
  width: 110,
  align: 'center',
  render: (_, record) => {
    const {
      costcodeId,
      phaseId,
      type,
      category,
    } = record;

    if (type === 'phase' || category === 'Material' || category === 'Equipment') return null;

    const totalHours = getFormattedHoursLabel({
      costcodeDetailsMap: projectCostcodeDetailsMap,
      costcodeId,
      phaseId,
      useLabel: false,
    });

    const totalTimeBilling = getBilledAmountForCostCode({
      costcodeDetailsMap: projectCostcodeDetailsMap,
      costcodeId,
      phaseId,
    });

    let avgRate = 0;

    if (totalHours) avgRate = (totalTimeBilling / totalHours).toFixed(2);

    return getFormattedCurrency(avgRate);
  },
});

const totalBillingColumn = (projectCostcodeDetailsMap = {}) => ({
  title: 'Total Billing Amount',
  key: 'totalBillingAmount',
  width: 110,
  align: 'center',
  render: (_, record) => {
    const {
      costcodeId,
      phaseId,
      category,
      type,
      children: phaseCostcodes = [],
      costUpdates = [],
    } = record ?? {};
    // summing phased
    if (type === 'phase') {
      let phasedBillingFromTime = 0;
      let phasedBillingFromCostUpdates = 0;
      phaseCostcodes.forEach((costcode) => {
        if (costcode.category !== 'Material') {
          const billingFromTime = getBilledAmountForCostCode({
            costcodeDetailsMap: projectCostcodeDetailsMap,
            costcodeId: costcode.id,
            phaseId: costcode.phaseId,
          });

          phasedBillingFromTime += roundToTwoDecimals(billingFromTime);
        }

        const { costUpdates: phaseCostUpdates = [] } = costcode;

        phasedBillingFromCostUpdates += getBilledFromCostUpdates({
          updates: phaseCostUpdates,
          type: costcode.category,
        });
      });
      const phasedTotalBilling = phasedBillingFromTime + phasedBillingFromCostUpdates;

      return `$ ${phasedTotalBilling.toFixed(2)}`;
    }
    // unphased
    const totalBillingTime = getBilledAmountForCostCode({
      costcodeDetailsMap: projectCostcodeDetailsMap,
      costcodeId,
      phaseId,
    });

    const totalBillingUpdates = getBilledFromCostUpdates({
      updates: costUpdates,
      type: category,
    });

    const totalBilling = totalBillingTime + totalBillingUpdates;

    return `$ ${totalBilling.toFixed(2)}`;
  },
});

const actualTotalCostColumn = (projectCostcodeDetailsMap = {}, userMap = {}, progresses = {}) => ({
  title: 'Total Cost',
  key: 'totalCost',
  width: 110,
  align: 'center',
  render: (_, record) => {
    const {
      costcodeId,
      phaseId,
      projectId,
      category,
      type,
      children = [],
    } = record || {};
    if (type === 'phase') {
      let phasedTotalActualCost = 0;
      let phasedTotalActualSpend = 0;
      const nonMaterialCostcodes = children.filter(({ category: ccCategory }) => ccCategory !== 'Material');
      nonMaterialCostcodes.forEach(({ id: ccId, phaseId: ccPhaseId }) => {
        const actualCost = getActualCostForCostCode({
          costcodeDetailsMap: projectCostcodeDetailsMap,
          costcodeId: ccId,
          phaseId: ccPhaseId,
          userMap,
        });
        const actualSpend = getActualSpend({
          costcodeId: ccId,
          phaseId: ccPhaseId,
          projectId,
          progresses,
        });

        phasedTotalActualCost += roundToTwoDecimals(actualCost);
        phasedTotalActualSpend += roundToTwoDecimals(actualSpend);
      });
      const phasedTotalCost = phasedTotalActualCost + phasedTotalActualSpend;
      return `$ ${phasedTotalCost.toFixed(2)}`;
    }
    if (category === 'Material') return null;
    const actualCost = getActualCostForCostCode({
      costcodeDetailsMap: projectCostcodeDetailsMap,
      costcodeId,
      phaseId,
      userMap,
    });

    const actualSpend = getActualSpend({
      costcodeId,
      phaseId,
      projectId,
      progresses,
    });

    const totalCost = actualCost + actualSpend;

    return `$ ${totalCost.toFixed(2)}`;
  },
});

const costUpdateNameColumn = {
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  align: 'center',
  width: 150,
  render: (val) => val ?? '-',
};

const costUpdateTotalColumn = {
  title: 'Total',
  dataIndex: 'total',
  key: 'total',
  align: 'center',
  width: 150,
  render: getFormattedCurrency,
};

const costUpdateDateColumn = {
  title: 'Date',
  dataIndex: 'timestamp',
  key: 'timestamp',
  align: 'center',
  width: 150,
  render: getFormattedDate,
};

// COLUMNS:
// ----------------------------------------------------
export const getMaterialColumns = ({
  onHistory,
  onUploadInvoice,
  projectCostcodeDetailsMap = {},
  progresses,
  hasWrite,
  showBilling,
}) => {
  const cols = [
    nameColumn,
    codeColumn,
    estimatedCostColumn,
    costcodeProgressColumn({
      type: 'Material',
      projectCostcodeDetailsMap,
      progresses,
      hasWrite,
    }),
    actualSpendColumn(hasWrite),
    quantityUsedColumn(hasWrite),
    ...showBilling
      ? [totalBillingColumn(projectCostcodeDetailsMap)]
      : [],
    committedCostColumn(projectCostcodeDetailsMap),
    historyColumn(onHistory),
  ];
  if (hasWrite) cols.push(uploadInvoiceColumn(onUploadInvoice));
  return cols;
};

export const getOverheadColumns = ({
  onHistory,
  onUploadInvoice,
  projectCostcodeDetailsMap = {},
  userMap,
  progresses,
  hasWrite,
  showBilling,
}) => {
  const cols = [
    nameColumn,
    codeColumn,
    estimatedHoursCostColumn({ averageWage: null }),
    costcodeProgressColumn({
      type: 'Overhead',
      projectCostcodeDetailsMap,
      progresses,
      userMap,
      hasWrite,
    }),
    actualHoursSpendColumn({ projectCostcodeDetailsMap, hasWrite }),
    actualTotalCostColumn(projectCostcodeDetailsMap, userMap, progresses),
    ...showBilling
      ? [totalBillingColumn(projectCostcodeDetailsMap), avgBillingRateColumn(projectCostcodeDetailsMap)]
      : [],
    committedCostColumn(projectCostcodeDetailsMap),
    historyColumn(onHistory),
  ];
  if (hasWrite) cols.push(uploadInvoiceColumn(onUploadInvoice));
  return cols;
};

export const getEquipmentColumns = ({
  onHistory,
  onUploadInvoice,
  projectCostcodeDetailsMap = {},
  userMap,
  progresses,
  hasWrite,
  showBilling,
}) => {
  const cols = [
    nameColumn,
    codeColumn,
    estimatedHoursCostColumn({ averageWage: null }),
    costcodeProgressColumn({
      type: 'Equipment',
      projectCostcodeDetailsMap,
      progresses,
      userMap,
      hasWrite,
    }),
    actualHoursSpendColumn({ projectCostcodeDetailsMap, hasWrite }),
    actualTotalCostColumn(projectCostcodeDetailsMap, userMap, progresses),
    ...showBilling
      ? [totalBillingColumn(projectCostcodeDetailsMap)]
      : [],
    committedCostColumn(projectCostcodeDetailsMap),
    historyColumn(onHistory),
  ];
  if (hasWrite) cols.push(uploadInvoiceColumn(onUploadInvoice));
  return cols;
};

export const getLaborColumns = ({
  onHistory,
  onUploadInvoice,
  projectCostcodeDetailsMap = {},
  userMap,
  progresses,
  averageWage,
  hasWrite,
  showBilling,
}) => {
  const cols = [
    nameColumn,
    codeColumn,
    estimatedHoursCostColumn({ averageWage }),
    costcodeProgressColumn({
      type: 'Labor',
      projectCostcodeDetailsMap,
      userMap,
      averageWage,
      progresses,
      hasWrite,
    }),
    actualHoursSpendColumn({ projectCostcodeDetailsMap, averageWage, hasWrite }),
    actualTotalCostColumn(projectCostcodeDetailsMap, userMap, progresses),
    ...showBilling
      ? [totalBillingColumn(projectCostcodeDetailsMap), avgBillingRateColumn(projectCostcodeDetailsMap)]
      : [],
    committedCostColumn(projectCostcodeDetailsMap),
    historyColumn(onHistory),
  ];
  if (hasWrite) cols.push(uploadInvoiceColumn(onUploadInvoice));
  return cols;
};

export const getPhasedColumns = ({
  onHistory,
  onUploadInvoice,
  projectCostcodeDetailsMap = {},
  userMap,
  progresses,
  averageWage,
  hasWrite,
  showBilling,
}) => {
  const cols = [
    nameColumn,
    codeColumn,
    typeColumn,
    estimatedHoursCostColumn({ averageWage }),
    phasedProgressColumn({
      projectCostcodeDetailsMap,
      averageWage,
      progresses,
      userMap,
      hasWrite,
    }),
    actualHoursSpendColumn({ projectCostcodeDetailsMap, averageWage, hasWrite }),
    actualTotalCostColumn(projectCostcodeDetailsMap, userMap, progresses),
    ...showBilling
      ? [totalBillingColumn(projectCostcodeDetailsMap), avgBillingRateColumn(projectCostcodeDetailsMap)]
      : [],
    committedCostColumn(projectCostcodeDetailsMap),
    historyColumn(onHistory),
  ];
  if (hasWrite) cols.push(uploadInvoiceColumn(onUploadInvoice));
  return cols;
};

export const getMaterialCostColumns = () => [
  costUpdateNameColumn,
  {
    title: 'Part Number',
    dataIndex: 'partNumber',
    key: 'partNumber',
    align: 'center',
    width: 150,
    render: (val) => val ?? '-',
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: 150,
    render: (val) => val ?? '-',
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    key: 'cost',
    align: 'center',
    width: 150,
    render: (cost, record) => (record.type === 'costcode' ? '-' : getFormattedCurrency(cost)),
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    key: 'quantity',
    align: 'center',
    width: 150,
    render: (val) => val ?? '-',
  },
  {
    title: 'Markup',
    dataIndex: 'markup',
    key: 'markup',
    align: 'center',
    width: 150,
    render: (val) => {
      const markup = getMarkupString(val);

      return markup || '-';
    },
  },
  costUpdateTotalColumn,
  costUpdateDateColumn,
];

export const getEquipmentCostColumns = () => [
  costUpdateNameColumn,
  {
    title: 'Code',
    dataIndex: 'code',
    key: 'code',
    align: 'center',
    width: 100,
    render: (val) => val ?? '-',
  },
  {
    title: 'Daily Cost',
    dataIndex: 'dailyCost',
    key: 'dailyCost',
    align: 'center',
    width: 125,
    render: (cost, record) => (record.type === 'costcode' ? '-' : getFormattedCurrency(cost)),
  },
  {
    title: 'Hourly Cost',
    dataIndex: 'hourlyCost',
    key: 'hourlyCost',
    align: 'center',
    width: 125,
    render: (cost, record) => (record.type === 'costcode' ? '-' : getFormattedCurrency(cost)),
  },
  {
    title: 'Daily Billing Rate',
    dataIndex: 'dailyBillingRate',
    key: 'dailyBillingRate',
    align: 'center',
    width: 125,
    render: (cost, record) => (record.type === 'costcode' ? '-' : getFormattedCurrency(cost)),
  },
  {
    title: 'Hourly Billing Rate',
    dataIndex: 'hourlyBillingRate',
    key: 'hourlyBillingRate',
    align: 'center',
    width: 125,
    render: (cost, record) => (record.type === 'costcode' ? '-' : getFormattedCurrency(cost)),
  },
  {
    title: 'Hours',
    dataIndex: 'hours',
    key: 'hours',
    align: 'center',
    width: 80,
    render: (val) => val ?? '-',
  },
  costUpdateTotalColumn,
  costUpdateDateColumn,
];

export const getBasicCostColumns = () => [
  costUpdateTotalColumn,
  costUpdateDateColumn,
];

export const getUncategorizedInvoiceColumns = () => [
  {
    title: 'Invoice Number',
    dataIndex: 'invoiceNumber',
    key: 'invoiceNumber',
    align: 'center',
    width: 140,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    align: 'center',
    width: 150,
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    render: getFormattedCurrency,
    align: 'center',
    width: 140,
  },
  {
    title: 'Amount Distributed',
    dataIndex: 'amountDistributed',
    key: 'amountDistributed',
    render: getFormattedCurrency,
    align: 'center',
    width: 140,
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    align: 'center',
    width: 100,
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    key: 'vendor',
    align: 'center',
    width: 120,
  },
  {
    title: 'Date Issued',
    dataIndex: 'dateIssued',
    key: 'dateIssued',
    render: getFormattedDate,
    align: 'center',
    width: 120,
  },
  {
    title: 'Due Date',
    dataIndex: 'dueDate',
    key: 'dueDate',
    render: (dueDate, record) => {
      const { status } = record || {};
      const formattedDate = getFormattedDate(dueDate);
      const currentDate = DateTime.local().toMillis();
      const type = dueDate && currentDate > dueDate && status !== 'Paid' ? 'danger' : null;
      return <Text type={type}>{formattedDate}</Text>;
    },
    align: 'center',
    width: 120,
  },
  {
    title: 'From QBO',
    dataIndex: 'fromQBOTimestamp',
    key: 'fromQBOTimestamp',
    align: 'center',
    width: 120,
    render: (timestamp) => (
      timestamp ? getFormattedDate(timestamp) : 'N/A'
    ),
  },
];

export const getSubcontractColumns = ({
  onHistory,
  onInfo,
}) => [
  nameColumn,
  {
    title: 'Number',
    dataIndex: 'number',
    width: 30,
    align: 'center',
  },
  {
    title: 'Vendor',
    dataIndex: 'vendor',
    width: 150,
    align: 'center',
  },
  {
    title: 'Contract Total',
    dataIndex: 'contractTotal',
    width: 50,
    align: 'center',
    render: getFormattedCurrency,
  },
  {
    title: 'Base Contract',
    dataIndex: 'contractAmount',
    width: 50,
    align: 'center',
    render: getFormattedCurrency,
  },
  {
    title: 'Total Changes',
    dataIndex: 'totalChanges',
    width: 50,
    align: 'center',
    render: getFormattedCurrency,
  },
  {
    title: '% Complete',
    dataIndex: 'percentageComplete',
    width: 50,
    align: 'center',
    render: (percent) => `${(percent * 100).toFixed(1)} %`,
  },
  {
    title: 'Cost to Date',
    dataIndex: 'costToDate',
    width: 50,
    align: 'center',
    render: getFormattedCurrency,
  },
  {
    title: 'Committed Cost',
    dataIndex: 'committedCost',
    width: 50,
    align: 'center',
    render: (_, record) => {
      const committedCost = record.contractValue - record.costToDate;
      return getFormattedCurrency(committedCost);
    },
  },
  historyColumn(onHistory),
  {
    title: 'Info',
    dataIndex: 'info',
    key: 'info',
    align: 'center',
    width: 10,
    render: (_, record) => (
      <BorderlessButton
        style={{ padding: 0, width: 10 }}
        iconNode={<InfoCircleOutlined style={{ marginLeft: 0 }} />}
        onClick={(e) => {
          e.stopPropagation();
          onInfo(record);
        }}
      />
    ),
  },
];

const getProgressSummaryChart = (type, estimatedValue, actualValue) => (
  <Chart
    graphId={type}
    chartType="Bar"
    width="170px"
    height="100%"
    style={{ paddingTop: 15 }}
    data={[
      [
        '',
        'Estimated',
        'Actual',
      ],
      [
        type,
        estimatedValue || 0,
        actualValue || 0,
      ],
    ]}
    options={{
      vAxis: {
        minValue: 0,
      },
      curveType: 'linear',
      legend: { position: 'none' },
    }}
  />
);

const getUncategorizedSummaryChart = (uncategorizedCosts) => (
  <Chart
    graphID="Uncategorized"
    chartType="Bar"
    width="120px"
    height="100%"
    style={{ paddingTop: 15 }}
    data={[
      [
        '',
        'Uncategorized',
      ],
      [
        'Uncategorized',
        uncategorizedCosts || 0,
      ],
    ]}
    options={{
      vAxis: {
        minValue: 0,
      },
      curveType: 'linear',
      legend: { position: 'none' },
      colors: ['green'],
    }}
  />
);

const getProgressSummaryColumn = (type, estimatedValue, actualValue) => {
  const style = {
    color: 'green',
    fontWeight: 650,
  };

  let ourEstimatedValue = Number(estimatedValue);
  if (Number.isNaN(ourEstimatedValue)) ourEstimatedValue = 0;

  let ourActualValue = Number(actualValue);
  if (Number.isNaN(ourActualValue)) ourActualValue = 0;

  const parsedEstimatedValue = parseFloat(ourEstimatedValue.toFixed(2));
  const parsedActualValue = parseFloat(ourActualValue.toFixed(2));

  if (parsedActualValue > parsedEstimatedValue) {
    style.color = 'red';
  }

  return (
    <div>
      <span style={style}>
        {getFormattedCurrency(actualValue)} / {getFormattedCurrency(estimatedValue)}
      </span>
      { getProgressSummaryChart(type, parsedEstimatedValue, parsedActualValue) }
    </div>
  );
};

const getUncategorizedSummaryColumn = (uncategorizedCosts) => {
  const style = {
    color: 'red',
    fontWeight: 650,
  };

  const parsedUncategorizedCost = parseFloat(uncategorizedCosts);

  return (
    <div>
      <span style={style}>{getFormattedCurrency(uncategorizedCosts)}</span>
      {getUncategorizedSummaryChart(parsedUncategorizedCost)}
    </div>
  );
};

export const getProgressSummaryColumns = (hasUncategorized) => {
  const summaryColumns = [
    {
      title: 'Total Cost-To-Date',
      dataIndex: 'actualTotalSpend',
      key: 'actualTotalSpend',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Total',
        record.estimatedTotalCost,
        record.actualTotalSpend,
      ),
    },
    {
      title: 'Material Cost-To-Date',
      dataIndex: 'actualMaterialSpend',
      key: 'actualMaterialSpend',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Material',
        record.estimatedMaterialCost,
        record.actualMaterialSpend,
      ),
    },
    {
      title: 'Overhead Cost-To-Date',
      dataIndex: 'actualOverheadSpend',
      key: 'actualOverheadSpend',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Overhead',
        record.estimatedOverheadCost,
        record.actualOverheadSpend,
      ),
    },
    {
      title: 'Labor Cost-To-Date',
      dataIndex: 'actualLaborSpend',
      key: 'actualLaborSpend',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Labor',
        record.estimatedLaborCost,
        record.actualLaborSpend,
      ),
    },
    {
      title: 'Equipment Cost-To-Date',
      dataIndex: 'actualEquipmentSpend',
      key: 'actualEquipmentSpend',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Equipment',
        record.estimatedEquipmentCost,
        record.actualEquipmentSpend,
      ),
    },
    {
      title: 'Burden Cost-To-Date',
      dataIndex: 'actualBurdenCost',
      key: 'actualBurdenCost',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Burden',
        record.estimatedBurdenCost,
        record.actualBurdenCost,
      ),
    },
    {
      title: 'Sub-Contract Cost-To-Date',
      dataIndex: 'actualSubcontractSpend',
      key: 'actualSubcontractSpend',
      width: 180,
      render: (_, record) => getProgressSummaryColumn(
        'Sub-Contract',
        record.estimatedSubcontractCost,
        record.actualSubcontractSpend,
      ),
    },
  ];

  if (hasUncategorized) {
    summaryColumns.push({
      title: 'Uncategorized Costs',
      dataIndex: 'uncategorizedCosts',
      key: 'uncategorizedCosts',
      width: 160,
      render: (_, record) => getUncategorizedSummaryColumn(record.uncategorizedCosts.toFixed(2)),
    });
  }

  return summaryColumns;
};
