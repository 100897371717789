import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'antd';
import {
  CloseOutlined,
  PlusOutlined,
  CheckOutlined,
} from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import NuxPopover from '../../../nux/NuxPopover';

import {
  startNuxAction,
} from '../../../nux/state/nux.actions';

import { 
  FORMS_ADD_FIELDS_STEP_1,
  FORMS_ADD_FIELDS_STEP_1_SECTION_BUTTON_TEXT,
  FORMS_ADD_FIELDS_STEP_2,
  FORMS_ADD_FIELDS_STEP_3,
  FORMS_ADD_FIELDS_STEP_3_SECTION_BUTTON_TEXT,
  FORMS_ADD_FIELDS_STEP_4,
  FORMS_ADD_FIELDS_STEP_PREFIX,
} from '../../../nux/nux.constants';

const NUX_SECTION_NAME = 'Type Form Section Name Here';

export default ({
  activeNuxAction,
  onSave,
}) => {
  const dispatch = useDispatch();

  const [editing,setEditing] = useState(false);
  const [text,setText] = useState();
  const [textRef,setTextRef] = useState();
  const toggleEditing = useCallback(() => {
    setEditing(!editing);
  },[editing]);

  const onTextChange = useCallback((e) => {
    const {
      target:{
        value,
      } = {}
    } = e;
    setText(value);
  },[]);

  const save = useCallback(() => {
    if(!text) return;
    onSave(text);
    setEditing(false);
  },[text,onSave]);

  const onKeyDown = useCallback((e) => {
    if(!editing || e.keyCode !== 27) return;
    e.stopPropagation();
    setEditing(false);
  },[editing]);

  const typeNux = useCallback((i = 0) => {
    // if(i > NUX_SECTION_NAME.length) {
    //   return dispatch(startNuxAction(FORMS_ADD_FIELDS_STEP_3))
    // };
    // setText(NUX_SECTION_NAME.substring(0,i));
    // setTimeout(() => typeNux(i + 1),[50]);
  },[dispatch]);

  useEffect(() => {
    if(!editing) {
      setText();
    } else if(textRef) {
      textRef.focus();
    }
  },[editing,textRef]);

  useEffect(() => {
    if(activeNuxAction === FORMS_ADD_FIELDS_STEP_2) {
      typeNux();
    }
  },[activeNuxAction,typeNux]);

  const showNux = activeNuxAction && activeNuxAction.startsWith(FORMS_ADD_FIELDS_STEP_PREFIX);

  const child = useMemo(() => {
    if(!editing
      && activeNuxAction !== FORMS_ADD_FIELDS_STEP_2
      && activeNuxAction !== FORMS_ADD_FIELDS_STEP_3
    ) {
      const addButton = (
        <BorderlessButton
          title='Add Section'
          iconNode={<PlusOutlined/>}
          onClick={toggleEditing}
          style={{ width: 120, fontSize: 12, }}
        />
      );
      if(!showNux) return addButton;
      return (
        <NuxPopover
          placement='left'
          text={FORMS_ADD_FIELDS_STEP_1_SECTION_BUTTON_TEXT}
          visible={activeNuxAction === FORMS_ADD_FIELDS_STEP_1}
          nextAction={FORMS_ADD_FIELDS_STEP_2}
        >
          {addButton}
        </NuxPopover>
      )
    }
    return (
      <Row align='middle' justify='space-between' gutter={10} style={{ width:'100%'}}>
        <Col style={{ width:'calc(100% - 100px)'}}>
          <OnTraccrTextInput
            style={{
              backgroundColor: 'white',
              border: '1px lightgray dashed',
              borderRadius: 4,
              height:40,
            }}
            onChange={onTextChange}
            value={text}
            placeholder='Enter Section Name'
            onPressEnter={save}
            onRef={setTextRef}
            onKeyDown={onKeyDown}
          />
        </Col>
        <Col style={{ width: 100 }}>
          <NuxPopover
            placement='bottomRight'
            visible={activeNuxAction === FORMS_ADD_FIELDS_STEP_3}
            text={FORMS_ADD_FIELDS_STEP_3_SECTION_BUTTON_TEXT}
            nextAction={FORMS_ADD_FIELDS_STEP_4}
          >
            <Row justify='end' align='middle' gutter={10} style={{ width:'100%'}}>
              <Col span={12}>
              <BorderlessButton
                  style={{padding:0}}
                  iconNode={<CloseOutlined style={{ marginLeft: 0 }}/>}
                  onClick={() => setEditing(false)}
                />
              </Col>
              <Col span={12}>
                <BorderlessButton
                  style={{
                    padding:0, 
                    opacity: text ? 1 : 0,
                    pointerEvents: text && !showNux ? 'auto' : 'none',
                  }}
                  iconNode={<CheckOutlined style={{ marginLeft: 0 }}/>}
                  onClick={save}
                />
              </Col>
            </Row>
          </NuxPopover>
        </Col>
      </Row>
    )
  },[editing, toggleEditing,text, onTextChange, save, onKeyDown, showNux, activeNuxAction]);

  return (
    <Row 
      style={{
        height: 40,
        width:'100%',
      }}
      align='middle'
    >
      {child}
    </Row>
  );
}