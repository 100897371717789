import {
  GET_GLOBAL_ADDRESS_BOOK,
  UPDATE_ADDRESS_BOOK,
  MASS_DELETE_FROM_LOCAL_ADDRESS_BOOKS,
  MASS_DELETE_FROM_GLOBAL_ADDRESS_BOOK,
  ADD_TO_GLOBAL_ADDRESS_BOOK,
  MASS_ADD_TO_LOCAL_ADDRESS_BOOKS,
} from '../../state/actionTypes';
import ContactService from './contacts.service';

export default {};

export const getGlobalAddressBook = () => async (dispatch) => {
  const { data } = await ContactService.getGlobalAddressBook();
  if (!data) return false;
  dispatch({
    type: GET_GLOBAL_ADDRESS_BOOK,
    payload: {
      globalAddressBooks: data,
    },
  });
  return true;
};

export const updateAddressBook = (payload) => async (dispatch) => {
  const { id: addressBookEntryId } = payload;
  const { data } = await ContactService.updateAddressBook(addressBookEntryId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_ADDRESS_BOOK,
    payload: {
      ...payload,
    },
  });
  return true;
};

export const deleteFromLocalAddressBooks = (id, contactId, isVendor) => async (dispatch) => {
  const addressBookEntryIds = [id];
  const customerIds = !isVendor ? [contactId] : [];
  const vendorIds = isVendor ? [contactId] : [];

  const { data } = await ContactService.massDeleteFromLocalAddressBooks(
    addressBookEntryIds,
    customerIds,
    vendorIds,
  );

  if (!data) return false;
  dispatch({
    type: MASS_DELETE_FROM_LOCAL_ADDRESS_BOOKS,
    payload: data,
  });
  return true;
};

export const deleteFromGlobalAddressBook = (id) => async (dispatch) => {
  const addressBookEntryIds = [id];
  const { data } = await ContactService.massDeleteFromGlobalAddressBook(addressBookEntryIds);
  if (!data) return false;
  dispatch({
    type: MASS_DELETE_FROM_GLOBAL_ADDRESS_BOOK,
    payload: {
      addressBookEntryIds,
    },
  });
  return true;
};

export const massDeleteFromLocalAddressBooks = (
  ids,
  customerIds,
  vendorIds,
) => async (dispatch) => {
  const { data } = await ContactService.massDeleteFromLocalAddressBooks(
    ids,
    customerIds,
    vendorIds,
  );
  if (!data) return false;

  dispatch({
    type: MASS_DELETE_FROM_LOCAL_ADDRESS_BOOKS,
    payload: data,
  });
  return true;
};

export const massDeleteFromGlobalAddressBook = (ids) => async (dispatch) => {
  const { data } = await ContactService.massDeleteFromGlobalAddressBook(ids);
  if (!data) return false;
  dispatch({
    type: MASS_DELETE_FROM_GLOBAL_ADDRESS_BOOK,
    payload: {
      addressBookEntryIds: ids,
    },
  });
  return true;
};

export const addToLocalAddressBooks = (id, customerId, vendorId) => async (dispatch) => {
  const ids = [id];
  const customerIds = [customerId];
  const vendorIds = [vendorId];
  const payload = {
    ids,
    customerIds,
    vendorIds,
  };
  const { data } = await ContactService.massAddToLocalAddressBooks(payload);
  if (!data) return false;
  dispatch({
    type: MASS_ADD_TO_LOCAL_ADDRESS_BOOKS,
    payload: data,
  });
  return true;
};

export const addToGlobalAddressBook = (payload) => async (dispatch) => {
  const {
    data: {
      id,
    } = {},
  } = await ContactService.addToGlobalAddressBook(payload);
  if (!id) return false;
  dispatch({
    type: ADD_TO_GLOBAL_ADDRESS_BOOK,
    payload: {
      id,
      ...payload,
    },
  });
  return true;
};

export const massAddToLocalAddressBooks = (ids, customerIds, vendorIds) => async (dispatch) => {
  const payload = {
    addressBookEntryIds: ids,
    customerIds,
    vendorIds,
  };
  const { data } = await ContactService.massAddToLocalAddressBooks(payload);
  if (!data) return false;

  dispatch({
    type: MASS_ADD_TO_LOCAL_ADDRESS_BOOKS,
    payload: data,
  });
  return true;
};
