import React, { useEffect, useReducer, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { Col, Row, Select, Form,Tag } from 'antd';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import DisplayText from '../common/text/DisplayText';
import IntegrationTag from '../common/IntegrationTag';
import TeamTransfer from '../teams/TeamTransfer';
import DivisionSelector from '../common/inputs/DivisionSelector';
import FormColorPicker from '../common/inputs/FormColorPicker';

import FileReelWithUpload from '../common/files/FileReelWithUpload';

import Permissions from '../auth/Permissions';

import Debouncer from '../helpers/Debouncer';

import config from '../config';

import UnionSelectors from './UnionSelectors';
import UserPin from './UserPin';
import LabelSelector from '../labels/LabelSelector';
import { compareToFirstPasswordHelper, validatePasswordFormatHelper } from '../helpers/helpers';
import TimezoneSelector from '../common/inputs/TimezoneSelector';
import { getUserCustomData, getUserCustomFieldTemplate } from './state/users.actions';
import CustomFields from '../common/customFields/CustomFields';

const { Option } = Select;

const debouncer = new Debouncer();

const formLabelStyle = {
  style:{
    paddingBottom:0,
    marginTop:5,
  },
};

const getPositions = (positions) => {
  const options = Object.keys(positions);

  return options
    .filter((option) => Permissions.has(`USERS_${Permissions.formatPosition(option)}`))
    .map((option) => <Option key={option} value={option}>{option}</Option>);
};

const validateUnion = (_, value) => {
  if(value.unionId && value.localId && value.classId) return Promise.resolve();
  if(!value.unionId && !value.localId && !value.classId) return Promise.resolve();
  return Promise.reject('Incomplete union set up');
}

export default function UserInfo({
  form,user = {},
  divisions = [],
  optionalUserEmail = false,
  errors,
  customFields,
  setCustomFields,
}) {
  const dispatch = useDispatch();
  const {
    id,
    name,
    username,
    email,
    phoneNumber,
    pin,
    wage,
    position = 'Worker',
    editing = false,
    projects = [],
    teams = [],
    employeeId,
    intuitId,
    procoreId,
    sageId,
    eclipseId,
    timezone,
  } = user;

  const {
    company: {
      settings: {
        timezone: companyTimezone,
      } = {},
    } = {},
  } = useSelector((state) => state.settings);

  const {
    customData,
    customDataFiles,
    customFieldTemplate,
  } = useSelector((state) => state.users);

  const { formRef, isAdd, positions = {} } = form;

  const [shouldUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [selectedDivisions, setSelectedDivisions] = useState(divisions);

  useEffect(() => {
    setSelectedDivisions(divisions);
  }, [divisions]);

  useEffect(() => {
    dispatch(getUserCustomFieldTemplate());
    dispatch(getUserCustomData(id));
  }, [id]);

  useEffect(() => {
    forceUpdate();
  }, [customData]);

  useEffect(() => {
    setCustomFields(customFieldTemplate?.sections);
  }, [customFieldTemplate]);

  useEffect(() => {
    formRef?.current?.setFieldsValue({
      timezone: timezone || companyTimezone,
    });
  }, [timezone, companyTimezone]);

  const [currentPosition, setCurrentPosition] = useState(position);

  const wagePerm = user.id === Permissions.id
    ? 'USERS_WAGE_SELF'
    : `USERS_WAGE_${Permissions.formatPosition(currentPosition)}`;
  const hasReadWagePerms = Permissions.has(`READ_${wagePerm}`);
  const hasWriteWagePerms = Permissions.has(`WRITE_${wagePerm}`);

  const editPerm = user.id === Permissions.id
  ? 'USERS_SELF'
  : `USERS_${Permissions.formatPosition(currentPosition)}`;

  const hasEditPerm = Permissions.has(editPerm);

  const {
    unions = [],
  } = useSelector(state => state.unions);

  const compareToFirstPassword = compareToFirstPasswordHelper(formRef);
  const validateFormat = validatePasswordFormatHelper(formRef);

   const validateNumber = (rule, value, callback) => {
    if(!value) return callback();
    if (value.length !== 4) return callback('PIN must be 4 numbers');

    const regex = new RegExp(/^\d+$/);
    if(!(regex.test(value))) {
      return callback('PIN can only contain numbers');
    }
    callback();
  };

  const validateUsername = (oldUsername) => async (rule, value) => {
    if (!value) return Promise.reject('Username must be 5 or more characters');
    const trimmed = value.trim();
    if(trimmed.length < 5) return Promise.reject('Username must be 5 or more characters');
    if(oldUsername && trimmed === oldUsername.trim()) return Promise.resolve();
    if(value.replace(/\s/g,'') !== trimmed) {
      return Promise.reject('Username cannot contain spaces');
    }
    const data = await debouncer.checkUsernames([value.trim()]);
    return data && data.length > 0 ? Promise.reject('Username already exists') : Promise.resolve();
  };

  const isNotDisplay = (isAdd || editing);

  return (
    <>
    <Form.Item
      name='name'
      label='Name'
      style={{marginBottom:0,paddingBottom:-8}}
      defaultValue={name}
      labelCol={formLabelStyle}
      rules={[{ required: isAdd, message: 'Please enter a name' }]}
    >
      {isNotDisplay ? <OnTraccrTextInput/> : <DisplayText title={name}/>}
    </Form.Item>
    {config.showDivisions && <Form.Item
      name='divisions'
      label='Divisions'
      style={{ marginBottom:0, paddingBottom:-8 }}
      labelCol={formLabelStyle}
      valuePropName='divisions'
      rules={[{ required: isNotDisplay, message: 'Please select a division' }]}
    >
      <DivisionSelector
        key='userDivisionSelector'
        mode='multiple'
        displayMode={!isNotDisplay}
        divisions={selectedDivisions}
        onChange={setSelectedDivisions}
      />
    </Form.Item>}
    {unions.length > 0 && <Form.Item
      name='union'
      label='Union'
      style={{marginBottom:0,paddingBottom:-8}}
      defaultValue={employeeId}
      labelCol={formLabelStyle}
      rules={[{
        validator: validateUnion,
      }]}
    >
      <UnionSelectors
        isDisplay={!isNotDisplay}
      />
    </Form.Item>}

    <Row gutter={20}>
      <Col span={12}>
        <Form.Item
          name='employeeId'
          label='Employee ID'
          style={{marginBottom:0,paddingBottom:-8}}
          defaultValue={employeeId}
          labelCol={formLabelStyle}
        >
          {isNotDisplay ? <OnTraccrTextInput/> : <DisplayText title={employeeId}/>}
        </Form.Item>

        <Form.Item
          name='email'
          label='Email'
          style={{marginBottom:0}}
          defaultValue={email}
          labelCol={formLabelStyle}
          rules={[
            { required: isAdd && !optionalUserEmail, message: 'Please enter the user\'s email' },
            { type:'email', message: 'Invalid email format', transform: (val) => val ? val.trim() : val },
          ]}
        >
          {isNotDisplay ? <OnTraccrTextInput/> : <DisplayText title={email}/>}
        </Form.Item>
      </Col>
      <Col span={12}>
        <Form.Item
          name='username'
          label='Username'
          style={{marginBottom:0}}
          defaultValue={username}
          labelCol={formLabelStyle}
          rules={[
            { required: isAdd, message: 'Please enter a username' },
            { validator: validateUsername(isAdd ? null : username) }
          ]}
        >
          {isNotDisplay ? <OnTraccrTextInput/> : <DisplayText title={username}/>}
        </Form.Item>

        <Form.Item
          name='phoneNumber'
          label='Phone Number'
          style={{marginBottom:0}}
          defaultValue={phoneNumber}
          labelCol={formLabelStyle}
        >
            {isNotDisplay ? <OnTraccrTextInput/> : <DisplayText title={phoneNumber}/>}
        </Form.Item>
      </Col>
    </Row>
    <Row>
      {hasReadWagePerms && <Col span={8}>
        <Form.Item
          name='wage'
          label='Hourly Wage'
          style={{marginBottom:0}}
          defaultValue={wage}
          labelCol={formLabelStyle}
        >
          {isNotDisplay && hasWriteWagePerms ? <OnTraccrNumberInput
            formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={value => value.replace(/\$\s?|(,*)/g, '')}
            step={0.01}
            precision={2}
            min={0.01}
          /> : <DisplayText title={wage ? `$ ${wage}` : ''}/>}
        </Form.Item>
      </Col>}
      <Col span={hasReadWagePerms ? 16 : 24}>
        <Form.Item
          name='position'
          label='Position'
          style={{marginBottom:0}}
          defaultValue={position}
          labelCol={formLabelStyle}
          rules={[
            { required: isAdd, message: 'Please select a position' },
          ]}
        >
          {isNotDisplay && hasWriteWagePerms ? <Select
            style={{ width: '100%' }}
            onSelect={setCurrentPosition}
            placeholder='Select a position'
          >
            {getPositions(positions)}
          </Select> : <DisplayText title={position}/>}
        </Form.Item>
      </Col>
    </Row>
    {isNotDisplay && <Row style={{width:'100%'}}>
      <Form.Item
        name='password'
        label='Password'
        style={{marginBottom:0,width:'100%'}}
        hasFeedback
        labelCol={formLabelStyle}
        rules={[
          { required: isAdd, message: 'Please enter password' },
          { validator: validateFormat }
        ]}
        >
        <OnTraccrTextInput
          password
          autoComplete="new-password"
        />
      </Form.Item>
      <Form.Item
        name='confirmPassword'
        label='Confirm Password'
        style={{marginBottom:0,width:'100%'}}
        hasFeedback
        labelCol={formLabelStyle}
        rules={[
          { required: isAdd, message: 'Please confirm password' },
          { validator: compareToFirstPassword }
        ]}
        >
          <OnTraccrTextInput
            password
            autoComplete="new-password"
          />
      </Form.Item>
    </Row>}
    <Row style={{width:'100%'}}>
      <Form.Item
        name='pin'
        label='Four Digit PIN'
        style={{marginBottom:0,width:'100%'}}
        hasFeedback
        labelCol={formLabelStyle}
        rules={[
          { validator: validateNumber }
        ]}
        >
          {isNotDisplay
          ?  <OnTraccrTextInput/>
          : <UserPin hasPin={pin} canView={hasEditPerm} userId={id}/>
          }
      </Form.Item>
    </Row>
    <Row style={{marginBottom:40,width:'100%', overflow:'hidden'}}>
      <Form.Item
        name="timezone"
        key="timezone"
        label="Timezone"
        labelCol={formLabelStyle}
        style={{ width: '100%', marginBottom: 0 }}
        rules={[{ required: isAdd, message: 'Please select a timezone' }]}
      >
        {isNotDisplay ? (
          <TimezoneSelector
            defaultValue={timezone}
            style={{ width: 200, textAlign: 'left' }}
          />
        ) : (
          <DisplayText title={timezone} />
        )}
      </Form.Item>
      {isNotDisplay && (
        <Form.Item
          name="labels"
          key="labels"
          label="Labels"
          labelCol={formLabelStyle}
          style={{
            width: '100%',
            marginBottom: 0,
          }}
        >
          <LabelSelector
            type="users"
          />
        </Form.Item>
      )}
      <Form.Item
        name='projects'
        key='projects'
        label='Projects'
        labelCol={formLabelStyle}
        style={{width:'100%',marginBottom:0}}
        >
          {isNotDisplay ? <TeamTransfer
            name='projects'
            key='projects'
            label='Projects'
            initialValue={projects.map(({name}) => name)}
            onChange={() => null} // These are required to propogate values to form
            onDeselect={() => null} // These are required to propogate values to form
          /> : (
            projects.map((project) =>
              <Tag key={project.id} style={{
                maxWidth:325, margin:3,overflow:'hidden'
              }}>{project.name}</Tag>
              )
          )}
        </Form.Item>
        <Form.Item
          name='teams'
          key='teams'
          label='Teams'
          labelCol={formLabelStyle}
          style={{marginBottom:0,width:'100%'}}
          >
            {isNotDisplay ? <TeamTransfer
              name='teams'
              key='teams'
              label='Teams'
              initialValue={teams.map(({name}) => name)}
              onChange={() => null} // These are required to propogate values to form
              onDeselect={() => null} // These are required to propogate values to form
            /> : (
              teams.map((team) =>
                <Tag key={team.id} style={{
                  maxWidth:325, margin:3,overflow:'hidden'
                }}>{team.name}</Tag>
                )
            )}
        </Form.Item>
        {!isNotDisplay &&
          <Form.Item
            name='integrations'
            key='integrations'
            label='Integrations'
            labelCol={formLabelStyle}
            style={{marginBottom:20,width:'100%'}}
            >
              <IntegrationTag id={intuitId} title='QuickBooks'/>
              <IntegrationTag id={procoreId} title='Procore'/>
              <IntegrationTag id={sageId} title='Sage'/>
              <IntegrationTag id={eclipseId} title='Eclipse'/>
          </Form.Item>
        }
        {
          isNotDisplay &&
          <Form.Item
            name='notes'
            key='notes'
            label='Notes'
            labelCol={{
              style: {
                paddingBottom:0,
                marginTop:10,
              }
            }}
            style={{width:'100%'}}
          >
            <OnTraccrTextInput textarea/>
          </Form.Item>
        }
        <Form.Item
            name='scheduleColor'
            key='scheduleColor'
            label='Colour'
            labelCol={{
              style: {
                paddingBottom:0,
                marginTop:10,
              }
            }}
          >
            <FormColorPicker isNotDisplay={isNotDisplay} style={{ minWidth: 100 }}/>
          </Form.Item>

        {
          isNotDisplay &&
          <Form.Item
            name='files'
            key='files'
            label='Files'
            labelCol={{
              style: {
                paddingBottom:0,
                marginTop:10,
              }
            }}
            style={{ width:'100%' }}
            valuePropName='files'
          >
            <FileReelWithUpload isDisplay={false} style={{ marginTop: 0 }}/>
          </Form.Item>
        }
        { !!customFields?.length && (
          <Form.Item
            name="customData"
            style={{ marginBottom: 20, width: '100%' }}
          >
            <CustomFields
              key={`${id}${shouldUpdate}`} // Need to force re-render
              initialValues={customData}
              customFields={customFields}
              divisions={selectedDivisions}
              errors={errors}
              fileMap={customDataFiles}
              isDisplay={!isNotDisplay}
            />
          </Form.Item>
        )}
    </Row>
    </>
  )
}