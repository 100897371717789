import React from 'react';
import { Row } from 'antd';
import IntegrationAutoSync from '../IntegrationAutoSync';
import procoreImage from '../../../assets/images/ProcoreBlackLogo.png';

const pullHelp = (
  <div style={{ width: 300 }}>
    Auto-Sync from Procore to Ontraccr will
    automatically import any new changes you make in Procore
    into <b>already linked</b> Ontraccr Users, Projects and Cost Codes.
  </div>
);

const pushHelp = (
  <div style={{ width: 300 }}>
    Auto-Sync from Ontraccr to Procore will
    automatically export any new changes you make in Ontraccr
    to <b>already linked</b> Ontraccr Users, Projects and Cost Codes into
    the corresponding entries in Procore.
    <br/><br/>
    We will also create new entries in Procore for any new Users, Projects or Costcodes
    created in Ontraccr. 
  </div>
);

export default ({
  onChanged,
  syncSettings,
}) => (
  <IntegrationAutoSync
    title='Procore'
    pullHelp={pullHelp}
    pushHelp={pushHelp}
    onChanged={onChanged}
    syncSettings={syncSettings}
    integrationKey='procore'
    integrationLogo={
      <Row align='top' style={{ height: '100%'}}>
        <img 
          alt='Procore Logo'
          src={procoreImage} 
          style={{
            width:300,
            height:37,
            marginTop:17,
            marginLeft:35,
          }}/>
      </Row>
    }
  />
);