import React, { useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Table, Tag } from 'antd';

import { getUserTeams } from '../users/state/users.actions';

import sortByString, { getIdMap } from '../helpers/helpers';

export default ({
  divisions,
  onSelect,
  selected = [[],[]],
  data = [],
  scroll = {},
  additionalColumns = [],
  disableAll,
}) => {
  const dispatch = useDispatch();

  const positionNames = useSelector((state) => {
    const {
      settings:{
        positionNames = [],
      } = {},
    } = state;
    return positionNames;
  });

  const userTeams = useSelector(state => state.users.userTeams);
  const teams = useSelector(state => state.teams.teams);
  const company = useSelector(state => state.settings.company);
  const userDivisions = useSelector(state => state.users.userDivisions);

  const ourUsers = useMemo(() => (
    divisions ? data.filter((user) => {
      const {
        [user.id]: usersDivs = [],
      } = userDivisions;
      return divisions.some((divisionId) =>  usersDivs.includes(divisionId));
    }) : data
  ),[divisions, data, userDivisions])
  const activeTeams = useMemo(() => teams.filter(team => team.active),[teams]);
  const teamMap = useMemo(() => getIdMap(activeTeams),[activeTeams]);
  const teamNames = useMemo(() => (
    activeTeams.map(team => ({
      value:team.id,
      text:team.name,
    }))
  ),[activeTeams]);
  const cols = useMemo(() => [
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        sorter:sortByString('name'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      },
      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        width:125,
        filters:positionNames.map(({ name }) => ({
          text:name,
          value:name,
        })),
        onFilter: (position, user) => user.position === position,
        sorter:sortByString('position'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      },
      {
        title: 'Teams',
        dataIndex: 'teams',
        key: 'teams',
        width:200,
        render:(_,user) => {
          const {
            [user.id]: ourUsersTeams = [],
          } = userTeams;
          return (
            ourUsersTeams
            .filter((teamId) => teamId in teamMap)
            .map((teamId) => (
              <Tag
                key={teamId} 
                style={{
                  maxWidth:180,
                  overflow:'hidden',
                  textOverflow:'ellipsis'
                }}
              >{teamMap[teamId].name}</Tag>
            ))
          );
        },
        filters:teamNames,
        onFilter: (teamId, user) => user.id in userTeams && userTeams[user.id].includes(teamId),
      },
      ...additionalColumns,
    ],[positionNames,teamNames,teamMap,userTeams,additionalColumns,]);

  useEffect(() => {
    if(dispatch) dispatch(getUserTeams())
  },[dispatch]);

  return (
    <div className='file-list-container' style={{ marginTop: 15 }}>
      <Table
        size='small'
        pagination={false}
        columns={cols}
        dataSource={ourUsers}
        style={{ width:'100%', height:'100%' }}
        scroll={scroll}
        rowSelection={{
          fixed:true,
          onChange:(...args) => onSelect(args),
          selectedRowKeys: selected[0],
          getCheckboxProps:(record) => ({
            disabled: company && company.userId === record.id || disableAll, // Cant remove owner from perms
          }),
          preserveSelectedRowKeys: true
        }}
        rowKey='id'
      />
    </div>
  );
}
