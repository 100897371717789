import React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import { connect } from 'react-redux';
import { withRouter, } from 'react-router-dom';
import {
  message,
  Drawer
} from 'antd';

import {
  archiveUser,
  updateUserById,
  deleteUserById,
} from './state/users.actions';

import { getCompany } from '../settings/state/settings.actions';
import { setTimeRange } from '../timecards/state/timecards.actions';
import TimeTracking from '../state/timeTracking';

import Clock from '../clock/Clock';

import CardGrid from '../common/cardGrid/cardGrid';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import FilterDropdown from '../common/FilterDropdown';

import Permissions from '../auth/Permissions';

import TimeCardsContainer from '../timecards/TimeCardsContainer';

import UserAccountUpgrade, { getBody as getArchiveMessage } from './UserAccountUpgrade';
import UserAddView from './userAddView';
import UsersListItem from  './userListItem';
import UserClockInModal from './UserClockInModal';

import { mergeUsersProjectsCostcodes, hasPermissions, getDisplayColumns } from './userHelpers';
import {
  firstRowFilters,
  secondRowFilters,
  defaultFilters,
} from './userFilter';

import sortByString, { getIdMap, mergeSets } from '../helpers/helpers';
import { dateIsToday } from '../helpers/time';
import {
  findPayrollStartAndEndDates,
  getFirstPayrollDay,
} from '../helpers/payroll';

import WarningHeader from '../common/text/WarningHeader';

import { createNuxEntry } from '../nux/state/nux.actions';
import {
  USER_ATTRIBUTES_TYPE,
  USER_SHORTCUT_TYPE,
} from '../nux/nux.constants';

import config from '../config';
import { formatCustomFieldCreatePayload } from '../helpers/costcodeHelpers';

export default connect(
  (state,ownProps) => {
    return {
      ...ownProps,
      users: state.users.users,
      usersUpdatedAt: state.users.lastUpdated,
      costcodes: state.costcodes.costcodes,
      projects: state.projects.projects,
      phases: state.costcodes.phases,
      teams: state.teams.teams,
      positions:state.settings.positions,
      company: state.settings.company,
      nux:state.nux.nux,
      selectedDivisions: state.settings.selectedDivisions,
      divisions: state.settings.divisions,
      userDivisions: state.users.userDivisions,
      userToLabel: state.users.userToLabel,
      labels: state.labels.filter((label) => label.type === 'users'),
      labelToUser: state.users.labelToUser,
      userFiles: state.users.userFiles,
      unions: state.unions,
      timeEntryUserMap: state.timeTracking.timeEntryUserMap,
      roundingPolicyPositionMap: state.settings.roundingPolicyPositionMap,
    };
  },{
    archiveUser,
    deleteUserById,
    updateUserById,
    clockin: TimeTracking.clockIn,
    clockout: TimeTracking.clockOut,
    switchTask: TimeTracking.switchTask,
    takeBreak: TimeTracking.startBreak,
    endBreak: TimeTracking.endBreak,
    manualEntry: TimeTracking.manualEntry,
    createNuxEntry,
    getCompany,
    setTimeRange,
  }
)(withRouter(class UserList extends React.Component {
  constructor(props) {
    super(props);
    const {
      company:{
        settings:{
          payPeriod = 'Weekly',
          payPeriodDates = '2020-02-02',
          semiMonthlyPayPeriodDates,
        } = {},
      } = {},
      positions = {},
    } = this.props;
    this.props.onUserClicked();

    this.activeFilters = {};
    this.defaultFilters = {};
    // Deep Copy default Filters
    this.deepCopyFilters();

    const currentPayPeriod = findPayrollStartAndEndDates({
      payPeriod,
      payPeriodFirstDay: getFirstPayrollDay({ payPeriod, payPeriodDates, semiMonthlyPayPeriodDates }),
      semiMonthlyPayPeriodDates,
    });

    setTimeRange(currentPayPeriod); // Reset time range
    this.state = {
      users: [],
      showClockIn: false,
      clockInUser: {},
      sortActive: window.localStorage.getItem('usersSortText') || 'Name: A to Z',
      activeFilters: this.activeFilters,
      filterIsActive: false,
      firstDayOfRunningPayPeriod: currentPayPeriod[0],
      cappedUsers: true,
      subscriptionOver: true,
      canAdd: Object.keys(positions).some((positionName) => (
        Permissions.has(`USERS_${Permissions.formatPosition(positionName)}`)
      )),
      errors: {},
      customFields: [],
      isListView: window.localStorage.getItem('usersViewType') === 'true',
    };

    this.clock = new Clock({
      clockin:this.props.clockin,
      clockout:this.props.clockout,
      switchTask:this.props.switchTask,
      takeBreak:this.props.takeBreak,
      endBreak:this.props.endBreak,
      manualEntry: this.props.manualEntry,
    });

    this.onArchive = this.archiveUser.bind(this);
    this.onDelete = this.deleteUser.bind(this);
    this.projectMap = getIdMap(this.props.projects);
    this.costcodeMap = getIdMap(this.props.costcodes);

    this.onUserClock = this.userClocked.bind(this);
    this.onUserClockClose = this.closeUserClock.bind(this);

    this.onAdd = this.addUser.bind(this);
    this.onUpdate = this.updateUser.bind(this);
    this.onClick = this.onWeekViewClick.bind(this);
    this.users = [];
    this.sortedUsers = [];
    this.currentSort = JSON.parse(window.localStorage.getItem('usersSortActive') ?? null);

    this.sortByName = this.sortName.bind(this);
    this.sortByRole = this.sortRole.bind(this);

    this.sortOptions = [{
      title:'Name: A to Z',
      icon: 'sort-ascending',
      action:() => this.sortName(true)
    },{
      title:'Name: Z to A',
      icon: 'sort-ascending',
      action:() => this.sortName(false)
    },{
      title:'Role: A to Z',
      icon: 'sort-ascending',
      action:() => this.sortRole(true)
    },{
      title:'Role: Z to A',
      icon: 'sort-descending',
      action:() => this.sortRole(false)
    }];

    this.onFilter = this.filterUsers.bind(this);
    this.onViewTypeChanged = this.viewTypeChanged.bind(this);
    this.props.getCompany();
  }

  componentDidMount() {
    const {
      nux = new Set(),
      createNuxEntry,
      location:{
        state:{
          targetId:locationTargetId,
        } = {},
        pathname,
      } = {},
      users = [],
      history,
    } = this.props;
    if(!nux.has(USER_ATTRIBUTES_TYPE)) {
      createNuxEntry(USER_ATTRIBUTES_TYPE);
    }

    if(this.cardRef && this.cardRef.displayItem && locationTargetId) {
      const ourUser = users.find((user) => user.id === locationTargetId);
      if(ourUser) {
        setTimeout(() => {
          this.cardRef.displayItem(ourUser);
        },250);
        history?.replace(pathname);  // Clear location state
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      users: prevUsers = [],
      projects: prevProjects = [],
      costcodes: prevCostcodes = [],
      teams: prevTeams = [],
      usersUpdatedAt: lastUserUpdate = moment(),
      positions:prevPositions = {},
      company:{
        subscriptionEnd: prevSubEnd,
        userLimit: prevUserLimit,
        freeUserLimit: prevFreeLimit,
        userId: prevOwner,
        settings: {
          payPeriod: prevPayPeriod,
          payPeriodDates: prevPayPeriodDates,
          roundingInterval: prevRoundingInterval,
          roundingType: prevRoundingType,
          roundingSetting: prevRoundingSetting,
        } = {},
      } = {},
      selectedDivisions: prevSelected,
      unions: prevUnions,
      roundingPolicyPositionMap: prevRoundingPolicyPositionMap,
    } = prevProps;
    const {
      users = [],
      projects = [],
      costcodes = [],
      teams = [],
      phases = [],
      usersUpdatedAt = moment(),
      company:{
        subscriptionEnd = DateTime.local().minus({ days: 1}),
        userLimit,
        freeUserLimit,
        userId:ownerId,
        settings: {
          payPeriod,
          payPeriodDates,
          semiMonthlyPayPeriodDates,
          roundingInterval,
          roundingType,
          roundingSetting,
        } = {},
        settings = {},
      } = {},
      positions = {},
      selectedDivisions = new Set(),
      divisions = {},
      unions = {},
      setTimeRange,
      roundingPolicyPositionMap,
    } = this.props;

    const {
      canAdd,
    } = this.state;

    if (positions !== prevPositions) {
      this.deepCopyFilters();
      const newCanAdd = Object.keys(positions).some((positionName) => (
        Permissions.has(`USERS_${Permissions.formatPosition(positionName)}`)
      ));
      if (newCanAdd !== canAdd) {
        this.setState({
          canAdd: newCanAdd,
        });
      }
    }

    if (payPeriod !== prevPayPeriod || payPeriodDates !== prevPayPeriodDates) {
      const currentPayPeriod = findPayrollStartAndEndDates({
        payPeriod,
        payPeriodFirstDay: getFirstPayrollDay({ payPeriod, payPeriodDates, semiMonthlyPayPeriodDates }),
        semiMonthlyPayPeriodDates,
      });
      this.setState({
        firstDayOfRunningPayPeriod: currentPayPeriod[0],
      });
      setTimeRange(currentPayPeriod);
    }

    if (prevUsers !== users
      || prevCostcodes !== costcodes
      || prevProjects !== projects
      || prevTeams !== teams
      || prevUnions !== unions
      || !lastUserUpdate.isSame(usersUpdatedAt)
      || prevSelected !== selectedDivisions
      || prevOwner !== ownerId
      || prevRoundingPolicyPositionMap !== roundingPolicyPositionMap
      || prevRoundingInterval !== roundingInterval
      || prevRoundingType !== roundingType
      || prevRoundingSetting !== roundingSetting
    ) {
      // Inject user clock in callback

      let divUsers = users;
      if(config.showDivisions) {
        const selectedDivUsers = Object
          .values(divisions)
          .filter(({ id }) => selectedDivisions.has(id))
          .map(({ users = new Set() }) => users);
        const mergedSelectedDivUsers = mergeSets(selectedDivUsers);
        divUsers = users.filter(({ id: userId }) => (
          mergedSelectedDivUsers.has(userId) || userId === ownerId
        ));
      }

      this.usersUpdatedAt = moment();
      const {
        firstDayOfRunningPayPeriod,
      } = this.state;
      const {
        projectMap,
        costcodeMap,
        users: newUsers,
      } = mergeUsersProjectsCostcodes({
        projects,
        costcodes,
        users: divUsers,
        teams,
        onUserClock: this.onUserClock,
        onTimeCardClick: this.onClick,
        firstDayOfRunningPayPeriod,
        roundingInterval,
        roundingSetting,
        roundingType,
        ownerId,
        unions,
      });

      this.projectMap = projectMap;
      this.projectNameMap = getIdMap(projects, 'name');
      this.teamNameMap = getIdMap(teams, 'name');

      this.costcodeMap = costcodeMap;
      this.users = newUsers.filter((user) => Permissions.has(`USERS_VIEW_${Permissions.formatPosition(user.position)}`) || (Permissions.has('USERS_VIEW_SELF') && user.id === Permissions.id));
      this.userMap = getIdMap(this.users);

      const {
        clockInUser,
      } = this.state;
      const clockInUserId = clockInUser ? clockInUser.id : '';

      const newClockInUser = this.users.find((user) => user.id === clockInUserId);
      this.setState({
        clockInUser: newClockInUser || clockInUser,
      });
      this.clock.setUser(newClockInUser || clockInUser);
      this.clock.users = newUsers;
      this.clock.settings = settings;
      this.clock.positions = positions;
      this.clock.roundingPolicyPositionMap = roundingPolicyPositionMap;

      if (this.currentSort) {
        const [sortType, sortAscending] = this.currentSort;
        const sortFunc = sortType === 'name' ? this.sortByName : this.sortByRole;
        sortFunc(sortAscending);
      } else {
        this.sortedUsers = this.users;
      }
      this.applyFilters();
    }

    if((users.length &&  prevUsers !== users)
      || subscriptionEnd !== prevSubEnd
      || userLimit !== prevUserLimit
      || freeUserLimit !== prevFreeLimit
    ) {
      // Determine subscription status
      const activeUserCount = this.users.filter((user) => user.active).length;
      const subscriptionOver = DateTime.local() > subscriptionEnd;

      const cappedUsers = (activeUserCount >= userLimit) || (subscriptionOver && activeUserCount >= freeUserLimit);
      if(cappedUsers !== this.state.cappedUsers || subscriptionOver !== this.state.subscriptionOver) {
        this.setState({
          cappedUsers,
          subscriptionOver,
        });
      }
    }

  }

  deepCopyFilters() {
    const {
      positions = {},
    } = this.props;
    // Deep Copy default Filters
    this.defaultsFromFile = defaultFilters(positions);
    Object.keys(this.defaultsFromFile).forEach((key) => {
      this.activeFilters[key] = new Set([...this.defaultsFromFile[key]]);
      this.defaultFilters[key] =  new Set([...this.defaultsFromFile[key]]);
    });
  }

  updateUser(id,values) {
    const {
      userDivisions = {},
      userToLabel = {},
      userFiles = {}, // Should exist, as its downloaded by userAddView when the edit starts
    } = this.props;
    const {
      customFields,
    } = this.state;
    const ourUser = this.userMap[id];
    if(!ourUser) {
      message.error(`User with id ${id} not found`);
      return false;
    }

    const formattedValues = formatCustomFieldCreatePayload({
      payload: values,
      customFields,
    });

    if (formattedValues?.error) {
      return this.setState({
        errors: formattedValues.errorMap,
      });
    }

    this.setState({
      errors: {},
    });

    return this.props.updateUserById({
      ourUser,
      projectNameMap: this.projectNameMap,
      teamNameMap: this.teamNameMap,
      values: formattedValues,
      userDivisions,
      userToLabel,
      userFiles,
    });
  }

  onWeekViewClick(_tasks, selectedUserId) {
    const {
      nux = new Set(),
      createNuxEntry,
    } = this.props;
    this.setState({
      showTimeCards: true,
      selectedUserId
    });
    if(!nux.has(USER_SHORTCUT_TYPE)) {
      createNuxEntry(USER_SHORTCUT_TYPE);
    }
  }

  async addUser(values) {
    const {
      customFields,
    } = this.state;

    if(!values || !values.username) return;
    const formattedValues = formatCustomFieldCreatePayload({
      payload: values,
      customFields,
    });

    if (formattedValues?.error) {
      return this.setState({
        errors: formattedValues.errorMap,
      });
    }

    this.setState({
      errors: {},
    });


    const payload = { ...formattedValues };
    payload.username = formattedValues.username ? formattedValues.username.trim() : formattedValues.username;
    payload.email = formattedValues.email ? formattedValues.email.trim() : formattedValues.email;
    delete payload.confirmPassword;
    if (formattedValues.union) payload.classId = formattedValues.union.classId;
    delete payload.union;

    return this.props.onUserCreate(payload,this.projectNameMap, this.teamNameMap);
  }

  onItemClicked(user) {
    this.props.history.push(`/users/${user.id}`);
    this.props.onUserClicked(user);
  }

  archiveUser(user) {
    const {
      company:{
        freeUserLimit,
        userLimit = freeUserLimit,
        userId:ownerId,
      } = {},
      history
    } = this.props;
    const {
      cappedUsers,
      subscriptionOver,
    } = this.state;

    if(user.id === ownerId) {
      CustomConfirmModal({
        title: 'You cannot archive the company account owner',
      });
      return;
    }

    let ourModal;
    if(cappedUsers && !user.active) {
      ourModal = CustomConfirmModal({
        title: (
          <div>
            Your account can only have {subscriptionOver ? freeUserLimit : userLimit} active users.
            <br/><br/>
            {getArchiveMessage({
              history, userLimit, ownerId, freeUserLimit, subscriptionOver,
              style:{
                fontFamily:'roboto-regular',
                fontSize:14,
              },
              onClick: () => {
                if(ourModal) ourModal.destroy();
              }
            })}
          </div>
        ),
      });
      return;
    }

    const achiveFunc = this.props.archiveUser;
    const mode = user.active ? 'Archive' : 'Activate';
    CustomConfirmModal({
      title: `${mode} user ${user.name}?`,
      okText: mode,
      cancelText: 'Cancel',
      async onOk() {
        await achiveFunc(user.id,!user.active);
      }
    });
  }

  deleteUser(user) {
    const {
      deleteUserById,
    } = this.props;
    CustomConfirmModal({
      title: `Permenantly delete user ${user.name}?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        await deleteUserById(user.id);
      }
    });
  }

  userClocked(user) {
    const {
      nux = new Set(),
      createNuxEntry,
    } = this.props;
    this.setState({
      clockInUser:user,
      showClockIn:true,
    });
    this.clock.setUser(user);

    if(!nux.has(USER_SHORTCUT_TYPE)) {
      createNuxEntry(USER_SHORTCUT_TYPE);
    }
  }

  closeUserClock() {
    this.setState({
      showClockIn:false,
    });
    setTimeout(() => {
      this.setState({
        clockInUser:null,
      });
      this.clock.setUser(null)
    },250);
  }

  sortName(ascending) {
    const sortTitle = this.sortOptions[ascending ? 0 : 1].title;
    const currentSort = ['name', ascending];
    this.currentSort = currentSort;
    this.sortedUsers = [...this.users].sort((a, b) => (ascending ? 1 : -1) * sortByString('name')(a, b));
    this.applyFilters();
    this.setState({
      sortActive: sortTitle,
    });
    window.localStorage.setItem('usersSortText', sortTitle);
    window.localStorage.setItem('usersSortActive', JSON.stringify(currentSort));
  }

  sortRole(ascending) {
    const sortTitle = this.sortOptions[ascending ? 2 : 3].title;
    const currentSort = ['role', ascending];
    this.currentSort = currentSort;
    this.sortedUsers = [...this.users].sort((a, b) => (ascending ? 1 : -1) * sortByString('position')(a, b));
    this.applyFilters();
    this.setState({
      sortActive: sortTitle,
    });
    window.localStorage.setItem('usersSortText', sortTitle);
    window.localStorage.setItem('usersSortActive', JSON.stringify(currentSort));
  }

  getSort() {
    return {
      sortOptions:this.sortOptions,
      sortActive: this.state.sortActive,
    };
  }

  getFilter() {
    const {
      positions = {},
      labels = [],
    } = this.props;

    return (
      <FilterDropdown
        filters={[firstRowFilters(positions, labels),secondRowFilters,]}
        onFilter={this.onFilter}
        activeFilters={this.state.activeFilters}
        style={{width:500}}
      />
    );
  }

  filterUsers(checkedFilters,filterCategory) {
    const realFilters = checkedFilters.filter((filterName) => filterName !== filterCategory);
    this.activeFilters[filterCategory] = new Set(realFilters);
    this.applyFilters();
  }

  applyFilters() {
    const {
      labelToUser = {},
      timeEntryUserMap,
    } = this.props;
    const activeFilterKeys = Object.keys(this.activeFilters)
    const {
      labels: activeLabels = new Set(),
    } = this.activeFilters;

    const usersFromLabels = new Set();
    if(activeLabels.size > 0) {
      // If labels filter is empty we show everyone
      Array.from(activeLabels).forEach((label) => {
        const {
          [label]: labelUserIds = [],
        } = labelToUser;
        labelUserIds.forEach((userId) => {
          usersFromLabels.add(userId);
        });
      })
    }

    const filteredUsers = this.sortedUsers.filter((user) =>
      activeFilterKeys
        .every((key) => {
          if(key === 'labels') {
            return activeLabels.size === 0 || usersFromLabels.has(user.id)
          }

          if (key==='timecardStatus') {
            if (this.activeFilters[key].size === 2) return true
            if (this.activeFilters[key].size === 0) return false;
            const tasks = timeEntryUserMap[user.id] ?? [];
            if (this.activeFilters[key].has('submittedTimecards')) {
              return tasks.filter(t => t.state!==null && t.startTime>this.state.firstDayOfRunningPayPeriod).length
            } else {
              // unsubmitted only
              return tasks.filter(t => t.state===null && t.startTime>this.state.firstDayOfRunningPayPeriod).length
            }
          }
          if(!this.activeFilters) return false;
          let userData = user[key];
          if (key === 'lastActive') {
            const {
              currentTask,
            } = user;

            const userActiveToday = currentTask
              && (dateIsToday(currentTask.endTime) || dateIsToday(currentTask.startTime));
            userData = userActiveToday ? 'activeToday' : 'inactiveToday';
          }
          if (key === 'position' && user.id === Permissions.id && !this.activeFilters[key].has(userData)) {
            return Permissions.has('USERS_VIEW_SELF');
          }

          return this.activeFilters[key].has(userData);
        })
    );

    this.setState({
      users:filteredUsers,
      filterIsActive:this.filterIsAtDefault(),
      activeFilters:this.activeFilters
    });
  }

  filterIsAtDefault() {
    return Object.keys(this.defaultsFromFile).every((key) => {
      /*
        Find if active filter set matches default. Inefficient, but
        filter set should be small.
      */
      const _difference = new Set(this.activeFilters[key])
      for (const elem of this.defaultFilters[key]) {
          if (_difference.has(elem)) {
              _difference.delete(elem)
          } else {
              _difference.add(elem)
          }
      }
      return _difference.size === 0;
    });
  }

  viewTypeChanged(checked) {
    window.localStorage.setItem('usersViewType', checked);
    this.setState({ isListView: checked });
  }

  render() {
    const {
      positions = {},
      company: {
        settings = {},
        userId:ownerId,
        freeUserLimit,
        userLimit,
      } = {},
      phases = [],
      history,
      userDivisions = {},
      userToLabel = {},
      unions = {},
      divisions,
    } = this.props;
    const {
      clockInUser,
      showClockIn,
      users = [],
      filterIsActive,
      showTimeCards = false,
      selectedUserId,
      cappedUsers,
      subscriptionOver,
      canAdd,
      errors,
      customFields,
      isListView,
    } = this.state;
    const clockInUserId = clockInUser ? clockInUser.id : '';
    const sortData = this.getSort();
    const ourUser = users.find((u) => u.id === selectedUserId);

    const displayColumns = getDisplayColumns({
      divisions,
      userDivisions,
    });

    return (
      <div style={{height:'100%',width:'100%'}}>
        <CardGrid
          onRef={(ref) => this.cardRef = ref}
          //key={`${sortData.sortActive}${this.usersUpdatedAt}`}
          dataSource={users}
          onItemClicked={this.onItemClicked.bind(this)}
          itemView={UsersListItem}
          itemDimensions={{width:285,height:205}}
          gutter={12}
          pageSize={9}
          add={canAdd ? {
            title:'Enter User Details',
            formView:(form) => (
              <UserAddView {...form}
                positions={positions}
                userDivisions={userDivisions}
                userToLabel={userToLabel}
                errors={errors}
                customFields={customFields}
                setCustomFields={(fields) => this.setState({ customFields: fields })}
              />
            ),
            width: 1300,
            push: { distance: 500 },
            onClose: this.onAdd,
            disabled: cappedUsers,
            disabledPopoverContent: (
              <UserAccountUpgrade
                history={history}
                ownerId={ownerId}
                freeUserLimit={freeUserLimit}
                userLimit={userLimit}
                subscriptionOver={subscriptionOver}
              />
            ),
            disabledPopoverTitle: <WarningHeader/>,
          } : null}
          edit={{
            title:'Edit User Details',
            formView:(form,formProps) => (
              <UserAddView {...form}
                positions={positions}
                userDivisions={userDivisions}
                userToLabel={userToLabel}
                user={formProps}
                errors={errors}
                customFields={customFields}
                setCustomFields={(fields) => this.setState({ customFields: fields })}
              />
            ),
            width: 1300,
            onClose: this.onUpdate,
            canEdit: (user) => (
              hasPermissions(user)
                && (user.id !== ownerId || Permissions.id === user.id)
            ),
            canArchive:(user) => hasPermissions(user) && user.id !== ownerId,
            canDelete:(user) => hasPermissions(user) && user.id !== ownerId,
            push: { distance: 500 },
          }}
          onArchive={this.onArchive}
          onDelete={this.onDelete}
          filter={this.getFilter()}
          filterActive={filterIsActive}
          sort={isListView ? {} : sortData}
          isListView={isListView}
          onToggleListViewSwitch
          onViewTypeChanged={this.onViewTypeChanged}
          displayColumns={displayColumns}
        />
        <UserClockInModal
          key={clockInUserId}
          visible={showClockIn}
          user={clockInUser}
          costcodes={this.props.costcodes}
          projects={this.props.projects}
          phases={phases}
          settings={settings}
          unions={unions}
          onCloseClicked={this.onUserClockClose}
          onClockIn={this.clock.onClockIn}
          onClockOut={this.clock.onClockOut}
          onSwitch={this.clock.onSwitch}
          onBreakStart={this.clock.onBreakStart}
          onBreakEnd={this.clock.onBreakEnd}
        />

          <Drawer
            placement='right'
            closable={true}
            onClose={()=> {this.setState({
              showTimeCards: false
            })}}
            width={700}
            title={`${users.filter((u) => u.id === selectedUserId)[0] ? users.filter((u) => u.id === selectedUserId)[0].name : ''} - Current Pay Period`}
            visible={showTimeCards}>
              <TimeCardsContainer
                userId={ourUser?.id}
                hideWeekSelector
              />
          </Drawer>
      </div>
    );
  }
}));
