import React from 'react';

import FormTextInput from '../common/inputs/FormTextInput';

export default ({
  isNotDisplay,
  type,
  name,
  value,
  required,
}) => (
  <FormTextInput
    isNotDisplay={isNotDisplay}
    value={value}
    name={name}
    label={`${type} Email`}
    rules={[
      { required,message: 'Please enter a valid email'},
      { type:'email', message: 'Invalid email format'},
    ]}
  />
);
