import React from 'react';
import { Row, Card } from 'antd';

import completion from '../assets/images/NUX/completion.svg';

export default ({
  title,
  children,
  complete,
  onClick,
}) => (
  <Row justify='center'>
    <Card
      onClick={onClick}
      hoverable
      style={{ border:'none', backgroundColor:'inherit' }}
      bodyStyle={{ padding: 10 }}
    >
    <div style={{ position:'relative'}}>
      {children}
      {complete && 
        <img
          alt={`${title} Complete`}
          src={completion}
          style={{
            position:'absolute',
            width:20,
            height:20,
            top:0,
            right:0,
          }}
        />
      }
    </div>
    <div style={{ width: '100%', textAlign:'center'}}>
      {title}
    </div>
    </Card>
  </Row>
);