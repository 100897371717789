import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { message } from 'antd';
import PropTypes from 'prop-types';

import SimpleFormModal from '../../common/modals/SimpleFormModal';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import {
  createUnionLocal,
  createUnionClass,
  updateUnionClass,
} from '../../unions/state/unions.actions';
import { getIdMap } from '../../helpers/helpers';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import { currencyParser } from '../../helpers/inputParsers';

export default function UnionAddLocalClassModal({
  unionId,
  localId,
  type = '',
  visible,
  onClose,
  editData,
}) {
  const isEdit = !!editData;
  const isLocal = type === 'Local';
  const dispatch = useDispatch();

  const {
    locals = [],
    classes = [],
  } = useSelector((state) => state.unions);

  const nameMap = useMemo(() => {
    if (isLocal) return getIdMap(locals.filter((local) => local.unionId === unionId), 'name');
    return getIdMap(classes.filter((unionClass) => unionClass.localId === localId), 'name');
  }, [isLocal, unionId, localId, locals, classes]);

  const [data, setData] = useState({});

  const onSave = useCallback(async () => {
    const { name } = data;
    if (name in nameMap && nameMap[name].id !== data.id) {
      message.error(`${type} with name ${name} already exists`);
      return true;
    }

    if (isEdit) {
      const payload = {
        name,
        description: data.description,
        wage: data.wage,
      };
      if (await dispatch(updateUnionClass(data.id, payload))) {
        onClose();
      }
      return;
    }

    const func = isLocal ? createUnionLocal : createUnionClass;
    const payload = { ...data };
    if (isLocal) {
      payload.unionId = unionId;
    } else {
      payload.localId = localId;
    }
    if (await dispatch(func(payload))) {
      onClose();
    }
  }, [data, unionId, localId, isLocal, nameMap, isEdit]);

  const onTextChange = useCallback((key) => (e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    setData({ ...data, [key]: newValue });
  }, [data]);

  const onNumberChange = useCallback((key) => (newVal) => {
    setData({ ...data, [key]: newVal });
  }, [data]);

  useEffect(() => {
    if (!visible) setData({});
  }, [visible]);

  useEffect(() => {
    if (visible && editData) {
      setData(editData);
    } else {
      setData({});
    }
  }, [visible, editData]);

  let title = ' ';
  if (visible) {
    title = `${isEdit ? 'Edit' : 'Add'} ${type}`;
  }
  return (
    <SimpleFormModal
      title={title}
      visible={visible}
      onClose={onClose}
      onSave={onSave}
      disabled={!data.name}
    >
      <OnTraccrTextInput
        onChange={onTextChange('name')}
        value={data.name}
        placeholder={`Enter ${type} name`}
      />
      <OnTraccrTextInput
        style={{ marginTop: 10 }}
        onChange={onTextChange('description')}
        value={data.description}
        placeholder={`Enter ${type} description`}
      />
      {!isLocal && (
        <OnTraccrNumberInput
          style={{ marginTop: 10, width: '100%' }}
          placeholder={`Enter ${type} wage`}
          value={data.wage}
          min={0}
          step={0.01}
          precision={2}
          parser={currencyParser}
          onChange={onNumberChange('wage')}
        />
      )}
    </SimpleFormModal>
  );
}

/* eslint-disable react/forbid-prop-types */
UnionAddLocalClassModal.propTypes = {
  unionId: PropTypes.string.isRequired,
  localId: PropTypes.string,
  type: PropTypes.string,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

UnionAddLocalClassModal.defaultProps = {
  type: '',
  localId: null,
  editData: undefined,
};
