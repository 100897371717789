import React, { useCallback, useState } from 'react';
import { removeElements } from 'react-flow-renderer';
import { Row, Col } from 'antd';
import { CloseOutlined, EyeOutlined } from '@ant-design/icons';

import HoverHelp from '../../common/HoverHelp'

export default ({
  draggable,
  type,
  title,
  Icon,
  children,
  id,
  onNodeUpdate,
  onPreview,
  isDisplay,
  hover,
  style = {},
  className = '',
}) => {

  const [hovering,setHovering] = useState();
  const startHover = useCallback(() => setHovering(true),[]);
  const endHover = useCallback(() => setHovering(false),[]);

  const onDragStart = useCallback((event) => {
    const rect = event.target.getBoundingClientRect();
    const x = event.clientX - rect.left; //x position within the element.
    const y = event.clientY - rect.top;  //y position within the element.
    event.dataTransfer.setData('application/reactflow', type);
    event.dataTransfer.setData('application/reactflow/clientX', x);
    event.dataTransfer.setData('application/reactflow/clientY', y);
    event.dataTransfer.effectAllowed = 'move';
  },[type]);

  const onDelete = useCallback(() => {
    onNodeUpdate((el) => removeElements([{ id }], el));
  },[id, onNodeUpdate]);

  return (
    <div
      className={`workflow-action-node ${className}`}
      style={{
        cursor:'grab',
        ...style,
        ...isDisplay ? { pointerEvents:'none' } : {}
      }}
      onMouseOver={!isDisplay ? startHover : null}
      onMouseLeave={!isDisplay ? endHover : null}
      {
        ...draggable
        ? {
          draggable: true,
          onDragStart,
        }
        : {}
      }
    >
      <Row justify='space-between' align='middle'>
        <Col>
          <Row gutter={10} justify='start' align='middle'>
            <Col>
              <Icon/>
            </Col>
            <Col>
              <b>{title}</b>
            </Col>
            {
              !draggable && onPreview &&
              <Col>
                <EyeOutlined
                  className='workflow-pdf-preview-icon'
                  onClick={onPreview}
                />
              </Col>
            }
          </Row>
        </Col>
        <Col>
          {
            !draggable && hover && !isDisplay &&
            <HoverHelp
              placement='topRight'
              content={
                <div style={{ maxWidth: 200 }}>
                  {hover}
                </div>
              }
            />
          }
        </Col>
      </Row>
      {draggable &&
        <div style={{ fontSize: 12, marginTop: 5 }}>
          {hover}
        </div>
      }
      {children}
      {!draggable && hovering && onNodeUpdate
        &&
        <Row
          className='workflow-action-delete-button'
          justify='center'
          align='middle'
          onClick={onDelete}
        >
          <CloseOutlined style={{ color:'white' }}/>
        </Row>
      }
    </div>
  );
}
