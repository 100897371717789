import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Empty,
  Row,
  Col,
  Input,
} from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../buttons/OnTraccrButton';
import ProjectListItem from './ProjectListItem';
import ExistingProjectSelect from './ExistingProjectSelect';
import { includesTerm } from '../../helpers/helpers';
import AddProjectDrawer from './AddProjectDrawer';

export default function ProjectList({
  name,
  customerId,
  isDisplay,
  onAddNew,
  onAddExistingProjects,
  type,
  selectedProjectIds,
  onRemove,
  maxAllowed,
}) {
  const projects = useSelector((state) => state.projects.projects);
  const [searchStr, setSearchStr] = useState();
  const [showExistingDrawer, setShowExisitingDrawer] = useState(false);
  const [showNewDrawer, setShowNewDrawer] = useState(false);

  const ourProjects = useMemo(() => {
    const selectedProjectIdSet = new Set(selectedProjectIds);

    return projects.filter((project) => (
      (!customerId || project.customerId === customerId)
      && (!selectedProjectIds || selectedProjectIdSet.has(project.id))
      && (
        includesTerm(project.name, searchStr)
        || includesTerm(project.number, searchStr)
        || includesTerm(project.address, searchStr)
      )));
  }, [
    projects,
    customerId,
    searchStr,
    selectedProjectIds,
  ]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onAdd = useCallback(() => setShowNewDrawer(true), []);
  const closeNewDrawer = useCallback(() => setShowNewDrawer(false), []);

  const onAddExisting = useCallback(() => setShowExisitingDrawer(true), []);
  const closeExistingDrawer = useCallback(() => setShowExisitingDrawer(false), []);

  const onAddExistingSubmit = useCallback(async (projectIds) => {
    const result = await onAddExistingProjects(projectIds);
    if (result) {
      setShowExisitingDrawer(false);
    }
  }, [onAddExistingProjects]);

  return (
    <div className="customer-project-container">
      <Row className="customer-project-search-row" justify="space-between" align="middle">
        <Col span={10}>
          <Input.Search
            type="search"
            style={{ width: '100%' }}
            className="searchbar"
            placeholder="Search"
            allowClear
            onChange={onSearch}
          />
        </Col>
        {!isDisplay && (
          <Col>
            <Row justify="space-between" gutter={10}>
              <Col>
                <OnTraccrButton
                  title="Add New"
                  onClick={onAdd}
                />
              </Col>
              <Col>
                <OnTraccrButton
                  title="Add Existing"
                  onClick={onAddExisting}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <div className="customer-project-scoller">
        {ourProjects.map((project) => (
          <ProjectListItem
            key={project.id}
            onRemove={onRemove}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...project}
          />
        ))}
        {
          ourProjects.length === 0 && (
            <Row justify="center" align="middle" className="customer-project-empty">
              <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            </Row>
          )
        }
      </div>
      <ExistingProjectSelect
        customerId={customerId}
        selectedProjectIds={selectedProjectIds}
        name={name}
        visible={showExistingDrawer}
        onClose={closeExistingDrawer}
        onSave={onAddExistingSubmit}
        type={type}
        maxAllowed={maxAllowed}
      />
      <AddProjectDrawer
        customerId={customerId}
        name={name}
        visible={showNewDrawer}
        onAddNew={onAddNew}
        onClose={closeNewDrawer}
      />
    </div>
  );
}

ProjectList.propTypes = {
  name: PropTypes.string.isRequired,
  customerId: PropTypes.string,
  isDisplay: PropTypes.bool,
  onAddNew: PropTypes.func,
  onAddExistingProjects: PropTypes.func,
  type: PropTypes.string,
  selectedProjectIds: PropTypes.arrayOf(PropTypes.string),
  onRemove: PropTypes.func,
  maxAllowed: PropTypes.number,
};

ProjectList.defaultProps = {
  customerId: null,
  isDisplay: false,
  onAddNew: () => {},
  onAddExistingProjects: () => {},
  type: 'Customer',
  selectedProjectIds: null,
  onRemove: null,
  maxAllowed: null,
};
