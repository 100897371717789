import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Tabs } from 'antd';

// Import Components:
import FormInvoices from './invoices/FormInvoices';
import PayablesList from '../common/forms/PayablesList';
import BreadcrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import PayableFormDrawer from './PayableFormDrawer';

// Import Helpers:
import Permissions from '../auth/Permissions';
import { getInvoiceStatusLabels } from './invoices/state/invoices.actions';

// CONSTANTS:
const CRUMBS = [{ text: 'Payables', icon: 'payables' }];
export const INVOICES_TAB = 'invoices';
export const PURCHASE_ORDERS_TAB = 'purchaseOrders';
export const SUBCONTRACT_TAB = 'subcontracts';

const { TabPane } = Tabs;

/**
 * Retrieves the initial tab depending on user permissions
 * @returns {string} initial tab
 */
const getInitialTab = () => (Permissions.match('INVOICES') ? INVOICES_TAB : PURCHASE_ORDERS_TAB);

/**
 * Payables Component
 *  NOTE: should only be visible if the user has permissions to at least one of the payable tabs
 */
function Payables() {
  const dispatch = useDispatch();

  const [selectedPayableType, setSelectedPayableType] = useState(false);
  const [activeTab, setActiveTab] = useState(getInitialTab());

  const onTabChange = useCallback((tab) => setActiveTab(tab), []);
  const onAddPoClick = useCallback(() => setSelectedPayableType('PO'), []);
  const onAddSubContractClick = useCallback(() => setSelectedPayableType('Sub-Contract'), []);
  const onDeleteSelectedPayableType = useCallback(() => setSelectedPayableType(), []);

  useEffect(() => {
    dispatch(getInvoiceStatusLabels());
  }, []);

  return (
    <BreadcrumbContainer crumbs={CRUMBS}>
      <Tabs
        onChange={onTabChange}
        activeKey={activeTab}
        tabPosition="left"
        style={{
          position: 'absolute',
          top: 90,
          right: 30,
          bottom: 23,
          left: 20,
        }}
        tabBarStyle={{
          width: 130,
        }}
      >
        {Permissions.match('INVOICES')
        && (
        <TabPane
          tab="Invoices"
          key={INVOICES_TAB}
          style={{
            height: '90vh',
            width: '100%',
          }}
        >
          <FormInvoices
            visible={activeTab === INVOICES_TAB}
            useRange
            rowSize="large"
            height="calc(90vh - 160px)"
          />
        </TabPane>
        )}
        {Permissions.match('PURCHASE_ORDERS')
        && (
          <TabPane
            tab="Purchase Order"
            key={PURCHASE_ORDERS_TAB}
            style={{
              height: '90vh',
              width: '100%',
            }}
          >
            <PayablesList
              visible={activeTab === PURCHASE_ORDERS_TAB}
              topOffset={165}
              hideDate={false}
              option={
                Permissions.has('PURCHASE_ORDERS_WRITE')
                  ? { title: 'Add PO', onClick: onAddPoClick }
                  : null
              }
            />
          </TabPane>
        )}
        {Permissions.match('PURCHASE_ORDERS')
        && (
        <TabPane
          tab="Sub-Contracts"
          key={SUBCONTRACT_TAB}
          style={{
            height: '90vh',
            width: '100%',
          }}
        >
          <PayablesList
            formType="Sub-Contract"
            visible={activeTab === SUBCONTRACT_TAB}
            topOffset={165}
            hideDate={false}
            option={
              Permissions.has('PURCHASE_ORDERS_WRITE')
                ? { title: 'Add Sub-Contract', onClick: onAddSubContractClick }
                : null
            }
          />
        </TabPane>
        )}
      </Tabs>
      <PayableFormDrawer
        type={selectedPayableType}
        onClose={onDeleteSelectedPayableType}
      />
    </BreadcrumbContainer>
  );
}

export default Payables;
