import React from 'react';
import {
  MailOutlined,
} from '@ant-design/icons';
import { Row, Form, Col } from 'antd';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

const fieldWidth = 200;

const buttonStyle = {
  marginTop:10,
  borderRadius:21,
  borderWidth:1.5,
  width: '100%',
  height: 42
};

const Button = ({ loading, title }) => (
  <OnTraccrButton
    htmlType='submit'
    type='primary'
    style={buttonStyle}
    loading={loading}
    title={title}
  />
);

const FormInputs = () => (
  <>
    <Row gutter={20}>
      <Col span={12}>
        <Form.Item
          name='email'
          rules={[
            { type:'email', message: 'Invalid email format'},
          ]}
        >
          <OnTraccrTextInput
            style={{ width: fieldWidth }}
            prefix={<MailOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
            placeholder="Email"
          />
        </Form.Item>
      </Col>
    </Row>
    <Row gutter={20}>
      <Col span={12}>
        <Form.Item
          name='secret'
        >
          <OnTraccrTextInput
            style={{ width: fieldWidth }}
            placeholder="Password"
          />
        </Form.Item>
      </Col>
    </Row>
  </>
);


export default ({
  handleSubmit,
  handleRemove,
  createLoading,
  deleteLoading,
}) => (
   <>
      <Form onFinish={handleSubmit}>
        <FormInputs/>
        <Row justify='center'>
          <Button
            title='Request Demo'
            loading={createLoading}
          />      
        </Row>
      </Form>
    <br/>
    <br/>
    <br/>
    <Form onFinish={handleRemove}>
      <FormInputs/>
      <Row justify='center'>
        <Button
          title='Remove Demo Account'
          loading={deleteLoading}
        />
      </Row>
    </Form>
    <br/>
  </>
);