import { DateTime } from 'luxon';

import {
  SET_TIMECARD_CALENDAR_TYPE,
  SET_TIMECARD_TIME_RANGE,
  GET_COMPANY_SETTINGS,
  SAVE_COMPANY_SETTINGS,
  GET_CUSTOM_TIMECARD_FIELDS,
  SET_CUSTOM_TIMECARD_FIELDS,
  GET_TASK_PHOTOS,
  GET_TIMECARD_HISTORY,
  UPDATE_TIMECARD_FILTERS,
  UPDATE_CURRENT_TIME_ENTRIES_RANGE,
} from '../../state/actionTypes';
import {
  findPayrollStartAndEndDates,
  getFirstPayrollDay,
} from '../../helpers/payroll';

const now = DateTime.local();
const endOfWeek = now.endOf('week').minus({ day: 1 }); // Luxon week starts on Monday
const startOfWeek = now.startOf('week').minus({ day: 1 });

const initialState = {
  tasks: [],
  timecards: {},
  calendarType: 'payPeriod',
  timeRange: [startOfWeek, endOfWeek],
  settingsLoaded: false,
  customFields: {},
  taskPhotos: {},
  history: [],
  filters: null,
  currentTimeEntriesRange: [startOfWeek, startOfWeek],
};

export default function TimecardsActions(state = initialState, { type, payload = {} } = {}) {
  switch (type) {
    case SET_TIMECARD_CALENDAR_TYPE: {
      const {
        calendarType,
      } = payload;
      const {
        timeRange: stateRange = [],
        payPeriod,
        firstPayrollDay,
        semiMonthlyPayPeriodDates,
      } = state;
      const [endDay] = stateRange;
      const now = DateTime.local();
      const realEnd = now > endDay ? now : endDay;
      let newRange = [...stateRange];
      if (calendarType === 'payPeriod') {
        newRange = findPayrollStartAndEndDates({
          startDay: realEnd,
          payPeriodFirstDay: firstPayrollDay,
          payPeriod,
          semiMonthlyPayPeriodDates,
        });
      } else if (calendarType === 'week') {
        newRange = [
          realEnd.startOf('week').minus({ day: 1 }),
          realEnd.endOf('week').minus({ day: 1 }),
        ];
      }
      return {
        ...state,
        calendarType,
        timeRange: newRange,
      };
    }
    case SET_TIMECARD_TIME_RANGE: {
      const {
        timeRange,
      } = payload;
      return {
        ...state,
        timeRange,
      };
    }
    case GET_COMPANY_SETTINGS: {
      const {
        settings: {
          payPeriod,
          payPeriodDates,
          semiMonthlyPayPeriodDates,
        } = {},
      } = payload;
      const { settingsLoaded } = state;
      if (settingsLoaded) return state;
      const firstPayrollDay = getFirstPayrollDay({
        payPeriod,
        payPeriodDates,
        semiMonthlyPayPeriodDates,
      });
      const timeRange = findPayrollStartAndEndDates({
        payPeriodFirstDay: firstPayrollDay,
        payPeriod,
        semiMonthlyPayPeriodDates,
      });
      return {
        ...state,
        settingsLoaded: true,
        timeRange,
        payPeriod, // Save copy here.
        payPeriodDates,
        firstPayrollDay,
        semiMonthlyPayPeriodDates,
        currentTimeEntriesRange: timeRange,
      };
    }
    case SAVE_COMPANY_SETTINGS: {
      const {
        settings: {
          payPeriod,
          payPeriodDates,
          semiMonthlyPayPeriodDates,
        } = {},
      } = payload;
      const {
        timeRange: [startDay] = [],
        payPeriod: statePayPeriod,
        payPeriodDates: statePayPeriodDates,
        semiMonthlyPayPeriodDates: stateSemiPayDays,
      } = state;
      // If user updates other settings, these both will be undefined
      if (!payPeriod && !payPeriodDates) return state;
      const realPayPeriod = payPeriod ?? statePayPeriod;
      const realPayPeriodDates = payPeriodDates ?? statePayPeriodDates;
      const realSemiPayDays = semiMonthlyPayPeriodDates ?? stateSemiPayDays;
      const isSemiMonthly = realPayPeriod;
      if (!startDay || !realPayPeriod) return state;
      if (isSemiMonthly && !realSemiPayDays) return state;
      if (!isSemiMonthly && !realPayPeriodDates) return state;
      const firstPayrollDay = getFirstPayrollDay({
        payPeriod: realPayPeriod,
        payPeriodDates: realPayPeriodDates,
        semiMonthlyPayPeriodDates: realSemiPayDays,
      });
      return {
        ...state,
        timeRange: findPayrollStartAndEndDates({
          startDay,
          payPeriodFirstDay: firstPayrollDay,
          payPeriod: realPayPeriod,
          semiMonthlyPayPeriodDates,
        }),
        payPeriod: realPayPeriod,
        payPeriodDates: realPayPeriodDates,
        firstPayrollDay,
        semiMonthlyPayPeriodDates,
      };
    }
    case GET_CUSTOM_TIMECARD_FIELDS: {
      const {
        fields: customFields = {},
      } = payload;
      return {
        ...state,
        customFields,
      };
    }
    case SET_CUSTOM_TIMECARD_FIELDS: {
      const {
        customFields: stateFields = {},
      } = state;
      const {
        newField = {},
      } = payload;
      const { divisionId } = newField;
      const newFields = {
        ...stateFields,
        [divisionId]: newField,
      };
      return {
        ...state,
        customFields: newFields,
      };
    }
    case GET_TASK_PHOTOS: {
      const {
        taskPhotos = {},
      } = payload;
      return {
        ...state,
        taskPhotos,
      };
    }
    case GET_TIMECARD_HISTORY: {
      const {
        history = [],
      } = payload;
      return {
        ...state,
        history,
      };
    }
    case UPDATE_TIMECARD_FILTERS: {
      const {
        filters,
      } = payload;
      const newTimeCardFilters = filters
        ? { ...state.formFilters, ...filters }
        : {};
      return {
        ...state,
        filters: newTimeCardFilters,
      };
    }
    case UPDATE_CURRENT_TIME_ENTRIES_RANGE: {
      const {
        startDt,
        endDt,
      } = payload;
      return {
        ...state,
        currentTimeEntriesRange: [startDt, endDt],
      };
    }
    default:
      return state;
  }
}
