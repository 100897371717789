export const ganttDateTypes = [
  {
    value: 'Day',
    label: 'Day',
  },
  {
    value: 'Week',
    label: 'Week',
  },
  {
    value: 'Month',
    label: 'Month',
  },
];

const defaultColumns = [
  {
    key: 'name',
    value: 'Name',
  },
  {
    key: 'startDate',
    value: 'Start',
  },
  {
    key: 'endDate',
    value: 'End',
  },
  {
    key: 'status',
    value: 'Status',
  },
];

export const defaultGanttColumns = defaultColumns.map((column) => ({
  label: column.value,
  property: column.key,
}));

export const defaultColumnTreeData = [
  {
    title: 'Default Fields',
    value: 'defaultFields',
    selectable: false,
    children: defaultColumns.map((column) => ({
      title: column.value,
      value: column.key,
    })),
  },
];

export const viewableCustomFields = [
  'yes-no', 'dropdown', 'attribute', 'text', 'dateRange', 'dateTime', 'calculation', 'staticText',
];

export default {};
