/* eslint-disable no-undef */
const permissionsAreEqual = (p1, p2) => {
  if (p1.size !== p2.size) return false;
  return Array.from(p1).every((perm) => p2.has(perm));
};

class Permissions {
  constructor() {
    this.permissions = new Set([]);
    this.id = '';
  }

  set({ permissions, id }) {
    window.sessionStorage.setItem('userId', id);
    this.id = id;
    this.reload(permissions);
  }

  reload(permissions) {
    const newPermissions = new Set(permissions);
    if (permissionsAreEqual(this.permissions, newPermissions)) return false;
    window.sessionStorage.setItem('permissions', permissions);
    this.permissions = new Set(permissions);
    return true;
  }

  add(permission) {
    this.permissions.add(permission);
    window.sessionStorage.setItem('permissions', Array.from(this.permissions));
  }

  delete(permission) {
    this.permissions.delete(permission);
    window.sessionStorage.setItem('permissions', Array.from(this.permissions.values()));
  }

  resync() {
    const storagePerms = window.sessionStorage.getItem('permissions') ?? '';
    this.permissions = new Set(storagePerms.split(','));
    this.id = window.sessionStorage.getItem('userId');
  }

  has(perm) {
    return this.permissions.has(perm);
  }

  hasRead(type) {
    return this.permissions.has(`${type.toUpperCase()}_READ`);
  }

  hasWrite(type) {
    return this.permissions.has(`${type.toUpperCase()}_WRITE`);
  }

  match(regex) {
    return Array.from(this.permissions.values()).some((permission) => permission.includes(regex));
  }

  rename(oldPerm, newPerm) {
    const existing = Array.from(this.permissions);
    this.permissions = new Set(existing.map((perm) => perm.replace(new RegExp(`${oldPerm}$`), newPerm)));
  }

  formatPosition(position) {  // eslint-disable-line
    return position.replace(/ /g, '_').toUpperCase();
  }
}

const permissionSingleton = new Permissions();

export default permissionSingleton;
