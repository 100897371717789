/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback,
} from 'react';
import {
  Checkbox,
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { ganttColumns } from './ganttScheduleHelpers';

const CheckboxGroup = Checkbox.Group;

export default function GanttScheduleColumnsSection(props) {
  const {
    visibleColumns,
    setVisibleColumns,
  } = props;

  const onChange = useCallback((checkedValues) => {
    setVisibleColumns(checkedValues);
  }, []);

  const onDragEnd = useCallback((result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const {
      source: {
        index: sourceIndex,
      },
      destination: {
        index: destinationIndex,
      },
    } = result;

    const newVisibleColumns = [...visibleColumns];
    const tempRow = newVisibleColumns[destinationIndex];
    newVisibleColumns[destinationIndex] = newVisibleColumns[sourceIndex];
    newVisibleColumns[sourceIndex] = tempRow;
    setVisibleColumns(newVisibleColumns);
  }, [visibleColumns]);

  return (
    <>
      <h3>Visible Columns</h3>
      <p>Please select the columns you would like visible in the gantt schedule</p>
      <CheckboxGroup
        value={visibleColumns}
        onChange={onChange}
      >
        <Row>
          {ganttColumns.map((column) => (
            <Col span={8} key={column}>
              <Checkbox value={column}>{column}</Checkbox>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>
      <br />
      <br />
      <h3>Column Order</h3>
      <p>Drag and drop the columns to change the order</p>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {({ innerRef: dropRef, droppableProps }) => (
            <div
              {...droppableProps}
              ref={dropRef}
            >
              <ol>
                {visibleColumns.map((column, index) => (
                  <Draggable key={column} draggableId={column} index={index}>
                    {({ draggableProps, dragHandleProps, innerRef: dragRef }) => (
                      <li
                        ref={dragRef}
                        {...draggableProps}
                        {...dragHandleProps}
                        style={{
                          ...draggableProps.style,
                        }}
                      >
                        {column}
                      </li>
                    )}
                  </Draggable>
                ))}
              </ol>
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

GanttScheduleColumnsSection.propTypes = {
  visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVisibleColumns: PropTypes.func.isRequired,
};
