import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Drawer } from 'antd';

import OnTraccrMap from '../../common/map/OnTraccrMap';

import getGoogleMapKey from '../../common/keys';

import { getIdMap } from '../../helpers/helpers';

function ManualEntryLocation({
  visible,
  onClose,
  options: {
    title,
    projectId,
    startLatitude,
    startLongitude,
    endLatitude,
    endLongitude,
  } = {},
}) {
  const projects = useSelector((state) => state.projects.projects);
  const {
    settings: {
      enableGeofence,
    } = {},
  } = useSelector((state) => state.settings.company ?? {});

  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const {
    latitude,
    longitude,
    address,
    geofence,
  } = useMemo(() => {
    const {
      [projectId]: ourProject,
    } = projectMap;
    if (ourProject) return ourProject;
    return {
      latitude: startLatitude || endLatitude,
      longitude: startLongitude || endLongitude,
    };
  }, [
    projectId,
    projectMap,
    startLatitude,
    startLongitude,
    endLatitude,
    endLongitude,
  ]);

  return (
    <Drawer
      title={title}
      onClose={onClose}
      width={1200}
      visible={visible}
      maskClosable={false}
    >
      <OnTraccrMap
        showSearch={false}
        lat={latitude}
        lng={longitude}
        address={address}
        geofence={enableGeofence ? geofence : null}
        isNotDisplay={false}
        googleMapURL={getGoogleMapKey()}
        loadingElement={<div style={{ height: '100%' }} />}
        showPin={!!address}
        containerElement={(
          <div style={{
            position: 'absolute',
            top: 74,
            left: 24,
            right: 24,
            bottom: 24,
          }}
          />
          )}
        mapElement={<div style={{ height: '100%' }} />}
        taskLocations={[{
          startLatitude,
          startLongitude,
          startOpacity: 1,
          endLatitude,
          endLongitude,
          endOpacity: 1,
        }]}
      />
    </Drawer>
  );
}

export default ManualEntryLocation;
