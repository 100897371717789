import {
  GET_ALL_TABLETS,
  CREATE_TABLET,
  UPDATE_TABLET,
  DELETE_TABLET,
} from '../../state/actionTypes';

export default function tabletActions(state = [], action = {}) {
  switch (action.type) {
    case GET_ALL_TABLETS: {
      return action.payload.tablets;
    }
    case CREATE_TABLET: {
      return [...state].concat([action.payload.tablet]);
    }
    case UPDATE_TABLET: {
      const {
        payload: { tablet = {} } = {},
      } = action;
      return state.map((stateTablet) => {
        if (stateTablet.id !== tablet.id) return stateTablet;
        return {
          ...stateTablet,
          ...tablet,
        };
      });
    }
    case DELETE_TABLET: {
      return state.filter(({ id }) => id !== action.payload.tabletId);
    }
    default:
      return state;
  }
}
