import React, { useMemo, useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Drawer } from 'antd';
import { CheckOutlined } from '@ant-design/icons';

import Permissions from '../../../auth/Permissions';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import WorkSlot from '../../../timecards/TimeCard/WorkSlot';

const checkIcon = <CheckOutlined style={{ marginLeft : 0, color: 'forestgreen', fontSize: 20 }}/>;
export default ({
  visible,
  onClose,
  onSubmit: propSubmit,
}) => {
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const tasks = timeEntryUserMap[Permissions.id] ?? [];
  const topTasks = useMemo(() => tasks.slice(0,10),[tasks]);

  const [selected,setSelected] = useState([]);
  const selectedSet = useMemo(() => new Set(selected.map((s) => s.id)),[selected]);
  
  const toggleSelected = useCallback((selectedTask) => () => {
    const newSelected = [...selected];
    if (selectedSet.has(selectedTask.id)) {
      setSelected(newSelected.filter((s) => s.id !== selectedTask.id))
    } else {
      setSelected(newSelected.concat([selectedTask]));
    }
  },[selected, selectedSet]);

  const onSubmit = useCallback(() => {
    propSubmit(selected);
  },[selected, propSubmit]);

  useEffect(() => {
    setSelected([]);
  },[visible]);
  
  
  
  return (
    <Drawer
      visible={visible}
      title='Add Labour Hours'
      width={400}
      onClose={onClose}
    >
      <div id='labour-add-drawer-scroll'>
        {
          topTasks.map((task) => (
            <Row justify='space-around' className='labour-add-task-row'>
              <Col flex='30px'>
                {selectedSet.has(task.id) &&
                  <Row justify='center' align='middle' style={{ height: '100%' }} >
                    <BorderlessButton
                      iconNode={checkIcon}
                      style={{ width: 30 }}
                      onClick={toggleSelected(task)}
                    />
                  </Row>
                }
              </Col>
              <Col>
                <div
                  key={task.id}
                  className='labour-add-drawer-task'
                  onClick={toggleSelected(task)}
                >
                  <WorkSlot task={task} />
                </div>
              </Col>
              <Col flex='30px'/>
            </Row>
          ))
        }
      </div>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}