import React from 'react';
import { Typography, } from 'antd';
const { Text, } = Typography;

export default ({
  color,
  width,
  runtime,
  style = {}
}) => (
  <Text 
    style={{
      alignSelf:'center',
      color,
      width,
      textAlign:'left',
      fontSize:72,
      fontFamily:'raleway-semibold',
      ...style,
    }}
    >
    {runtime}
    </Text>
);