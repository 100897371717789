import {
  CREATE_NEW_DASHBOARD,
  SELECT_DASHBOARD,
  SET_DASHBOARD_EDIT,
  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  GET_SAVED_DASHBOARDS,
  DELETE_SAVED_DASHBOARD,
  GET_DASHBOARD_ANALYTICS_DATA,
  FAVORITE_DASHBOARD,
} from '../../state/actionTypes';

import { filterData } from '../../analytics/analytics.helpers';

const initialState = {
  savedDashboards: [],
  isEdit: false,
  analyticsData: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SAVED_DASHBOARDS: {
      const {
        payload: newDashboards = [],
      } = action;
      const fullDashboards = [];
      let selectedDashboard;
      newDashboards.forEach((dash) => {
        const parsedDash = {
          ...dash,
          isFavorite: !!dash.isFavorite,
        };
        if (parsedDash.isFavorite) {
          selectedDashboard = parsedDash;
        }
        fullDashboards.push(parsedDash);
      });
      return {
        ...state,
        savedDashboards: fullDashboards,
        selectedDashboard,
      };
    }
    case CREATE_NEW_DASHBOARD: {
      return {
        ...state,
        selectedDashboard: {
          id: 'new',
          title: 'New Dashboard',
          layout: [],
          isEdit: true,
        },
      };
    }
    case SET_DASHBOARD_EDIT: {
      const {
        payload: {
          isEdit,
        } = {},
      } = action;
      return {
        ...state,
        isEdit,
      };
    }
    case SELECT_DASHBOARD: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      if (!id) {
        return {
          ...state,
          selectedDashboard: {},
        };
      }
      const { savedDashboards = [] } = state;
      const ourDash = savedDashboards.find((dash) => dash.id === id);
      if (!ourDash) return state;
      return {
        ...state,
        selectedDashboard: ourDash,
      };
    }
    case CREATE_DASHBOARD: {
      const {
        payload: newDashboard = {},
      } = action;
      const { savedDashboards: existingDashboards = [] } = state;
      const newDashboards = [];
      const dashboardIds = new Set();
      existingDashboards.forEach((dashboard) => {
        newDashboards.push(dashboard);
        dashboardIds.add(newDashboard);
      });
      if (dashboardIds.has(newDashboard.id)) return state;
      newDashboards.push(newDashboard);
      return {
        ...state,
        selectedDashboard: newDashboard,
        savedDashboards: newDashboards,
        isEdit: false,
      };
    }
    case UPDATE_DASHBOARD: {
      const {
        payload: {
          id,
          newData = {},
        },
      } = action;
      const { savedDashboards: existingDashboards = [] } = state;
      const newDashboards = [];
      let newDashboard;
      existingDashboards.forEach((dashboard) => {
        if (dashboard.id !== id) {
          newDashboards.push(dashboard);
          return;
        }
        newDashboard = {
          ...dashboard,
          ...newData,
        };
        newDashboards.push(newDashboard);
      });
      if (!newDashboard) return state;
      return {
        ...state,
        selectedDashboard: newDashboard,
        savedDashboards: newDashboards,
        isEdit: false,
      };
    }
    case DELETE_SAVED_DASHBOARD: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { savedDashboards = [] } = state;
      return {
        ...state,
        selectedDashboard: {},
        savedDashboards: savedDashboards.filter((dash) => dash.id !== id),
      };
    }
    case GET_DASHBOARD_ANALYTICS_DATA: {
      const {
        payload: {
          id,
          data,
          config: {
            breakdown = [],
            filters = {},
          } = {},
        } = {},
      } = action;

      const {
        data: fullData = [],
      } = data;
      const {
        analyticsData: existingAnalyticsData = {},
      } = state;
      const newAnalyticsData = { ...existingAnalyticsData };
      newAnalyticsData[id] = {
        ...data,
        fullData,
        data: filterData({ fullData, breakdown, filters }),
      };
      return {
        ...state,
        analyticsData: newAnalyticsData,
      };
    }
    case FAVORITE_DASHBOARD: {
      const {
        payload: {
          id,
          unfavorite,
        } = {},
      } = action;
      const isFavorite = unfavorite ? 0 : 1;
      const {
        savedDashboards: existingDashboards = [],
        selectedDashboard: existingSelected,
      } = state;
      let newSelected = existingSelected;
      if (newSelected && newSelected.id === id) {
        newSelected = { ...existingSelected, isFavorite };
      }
      return {
        ...state,
        savedDashboards: existingDashboards.map((dash) => {
          if (dash.id !== id) return { ...dash, isFavorite: 0 };
          return {
            ...dash,
            isFavorite,
          };
        }),
        selectedDashboard: newSelected,
      };
    }
    default:
      return state;
  }
};
