export default {};

// constants

export const filterViewFields = [
  {
    field: 'dateRange',
    defaultValue: null,
  },
  {
    field: 'users',
    defaultValue: [],
  },
  {
    field: 'formTemplates',
    defaultValue: [],
  },
  {
    field: 'formNumber',
    defaultValue: null,
  },
  {
    field: 'customers',
    defaultValue: [],
  },
  {
    field: 'projects',
    defaultValue: [],
  },
  {
    field: 'boards',
    defaultValue: [],
  },
  {
    field: 'status',
    defaultValue: [],
  },
];

export const defaultFormValues = {
  dateRange: null,
  users: [],
  formTemplates: [],
  formNumber: null,
  customers: [],
  projects: [],
  boards: [],
  status: [],
};

// helper functions

export const getProjectOptions = (projects, selectedDivisions) => (
  projects
    .filter((project) => project.active && selectedDivisions.has(project.divisionId))
    .map((project) => {
      let label;
      if (project.number) {
        label = `${project.number} - ${project.name}`;
      } else {
        label = `${project.name}`;
      }
      return { label, value: project.id };
    })
);
