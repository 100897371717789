import {
  GET_UNIONS,
  UPDATE_UNION,
  UPDATE_UNION_LOCAL,
  CREATE_UNION,
  CREATE_UNION_LOCAL,
  CREATE_UNION_CLASS,
  DELETE_UNION,
  DELETE_UNION_LOCAL,
  DELETE_UNION_CLASS,
  UPDATE_UNION_CLASS,
} from '../../state/actionTypes';

const { updateArray } = require('../../helpers/stateHelpers');

const initialState = {
  unions: [],
  locals: [],
  classes: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_UNIONS: {
      const {
        payload: {
          unions = {},
        } = {},
      } = action;
      return unions;
    }
    case UPDATE_UNION:
    case UPDATE_UNION_LOCAL:
    case UPDATE_UNION_CLASS: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const {
        unions: stateUnions = [],
        locals: stateLocals = [],
        classes: stateClasses = [],
      } = state;
      if (action.type === UPDATE_UNION) {
        return {
          ...state,
          unions: updateArray({ oldArray: stateUnions, id, newData }),
        };
      }
      if (action.type === UPDATE_UNION_LOCAL) {
        return {
          ...state,
          locals: updateArray({ oldArray: stateLocals, id, newData }),
        };
      }
      return {
        ...state,
        classes: updateArray({ oldArray: stateClasses, id, newData }),
      };
    }
    case CREATE_UNION:
    case CREATE_UNION_LOCAL:
    case CREATE_UNION_CLASS: {
      const {
        payload: newData,
      } = action;
      const {
        unions: stateUnions = [],
        locals: stateLocals = [],
        classes: stateClasses = [],
      } = state;
      if (action.type === CREATE_UNION) {
        return {
          ...state,
          unions: stateUnions.concat([newData]),
        };
      }
      if (action.type === CREATE_UNION_LOCAL) {
        return {
          ...state,
          locals: stateLocals.concat([newData]),
        };
      }
      return {
        ...state,
        classes: stateClasses.concat([newData]),
      };
    }
    case DELETE_UNION:
    case DELETE_UNION_LOCAL:
    case DELETE_UNION_CLASS: {
      const {
        payload: {
          id,
        },
        type,
      } = action;
      const {
        unions: stateUnions = [],
        locals: stateLocals = [],
        classes: stateClasses = [],
      } = state;
      if (type === DELETE_UNION) {
        return {
          ...state,
          unions: stateUnions.filter((item) => item.id !== id),
        };
      }
      if (type === DELETE_UNION_LOCAL) {
        return {
          ...state,
          locals: stateLocals.filter((item) => item.id !== id),
        };
      }
      return {
        ...state,
        classes: stateClasses.filter((item) => item.id !== id),
      };
    }
    default:
      return state;
  }
};
