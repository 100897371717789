export default {};


export const getCroppedDimensions = ({
  img,
  scale,
  height,
  width,
}) => {
  const {
    offsetHeight: imageHeight,
    offsetWidth: imageWidth,
  } = img;

  if (imageHeight < height * scale && imageWidth < width * scale) {
    // Just scale this image down to fit
    return {
      height,
      width,
    };
  }
  // Take some kind of preview crop
  const croppedHeight = (imageHeight / scale);
  const croppedWidth = (imageWidth / scale);
  const deltaW = croppedWidth - width;
  const deltaH = croppedHeight - height;
  return {
    height: croppedHeight,
    width: croppedWidth,
    left: -deltaW / 2,
    top: -deltaH / 2,
  };
};
