import React, { useCallback, useState } from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';
import { withScriptjs } from 'react-google-maps';

import getGoogleMapKey from '../../common/keys';
import OnTraccrMap from '../../common/map/OnTraccrMap';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DrawerFooter from '../../common/containers/DrawerFooter';

const height = 400;
const width = height * 1.5;

function OnTraccrMapDrawer({
  address,
  lat,
  lng,
  name,
  onPlaceChanged,
  disabled,
}) {
  const [showDrawer, setShowDrawer] = useState();
  const [place, setPlace] = useState({ address, lat, lng });

  const onToggleDrawer = useCallback(async () => {
    const newShowDrawer = !showDrawer;
    setShowDrawer(newShowDrawer);
    if (newShowDrawer && address && lat === 0 && lng === 0) {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({
        address,
      }, async (results, status) => {
        if (status === 'OK' && results[0]) {
          const { location } = results[0].geometry || {};
          const geocodeLat = location?.lat();
          const geocodeLng = location?.lng();
          onPlaceChanged({
            address,
            lat: geocodeLat,
            lng: geocodeLng,
          });
        } else {
          // silent fail
        }
      });
    }
  }, [showDrawer, onPlaceChanged, address, lat, lng]);

  const onClose = useCallback(() => {
    setPlace({ address, lat, lng });
    setShowDrawer(false);
  }, [address, lat, lng]);

  const onSave = useCallback(() => {
    onPlaceChanged(place);
    setShowDrawer(false);
  }, [place]);

  return (
    <div>
      <BorderlessButton
        title={(
          <div style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {place.address || 'Add Address'}
          </div>
        )}
        disabled={disabled}
        onClick={onToggleDrawer}
      />
      <Drawer
        visible={showDrawer}
        title={`Add Address for ${name}`}
        width={width + 40}
        onClose={onClose}
      >
        <OnTraccrMap
          key={address || 'no-address-map'}
          address={place.address}
          lat={place.lat}
          lng={place.lng}
          showSearch
          googleMapURL={getGoogleMapKey()}
          loadingElement={<div style={{ height }} />}
          containerElement={<div style={{ width, height: 1.5 }} />}
          mapElement={<div style={{ height }} />}
          onPlaceChanged={setPlace}
        />
        <DrawerFooter>
          <OnTraccrButton title="Save" onClick={onSave} />
        </DrawerFooter>
      </Drawer>
    </div>
  );
}

OnTraccrMapDrawer.propTypes = {
  address: PropTypes.string,
  lat: PropTypes.number.isRequired,
  lng: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  onPlaceChanged: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

OnTraccrMapDrawer.defaultProps = {
  disabled: false,
  address: null,
};

export default withScriptjs(OnTraccrMapDrawer);
