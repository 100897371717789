import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Drawer } from 'antd';
import SearchField from './SearchField';
import BoardsFieldsSearch from './BoardsFieldsSearch';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import {
  searchBoards,
} from './state/search.actions';
import QuerySelector from './QuerySelector';
import BoardsSelector from './BoardsSelector';

export default function BoardsSearchDrawer({ visible, onClose }) {
  const dispatch = useDispatch();

  const [selectedQuery, setSelectedQuery] = useState();
  const [ourQuery, setOurQuery] = useState({});

  const { boardIds } = ourQuery;

  const searchQueryMap = useSelector((state) => state.search.boardQueries);

  const onSelectQuery = useCallback((id) => {
    if (id === selectedQuery) return;
    const q = searchQueryMap[id];
    setSelectedQuery(id);
    setOurQuery(q?.query || {});
  }, [searchQueryMap, selectedQuery]);

  const onNativeQueriesChange = useCallback((key) => (newQueries) => {
    setOurQuery({
      ...ourQuery,
      native: {
        ...(ourQuery?.native || {}),
        [key]: newQueries,
      },
    });
  }, [ourQuery]);

  const onCustomQueriesChange = useCallback((boardId) => (key) => (newQueries) => {
    setOurQuery({
      ...ourQuery,
      custom: {
        ...(ourQuery?.custom || {}),
        [boardId]: {
          ...(ourQuery?.custom?.[boardId] || {}),
          [key]: newQueries,
        },
      },
    });
  }, [ourQuery]);

  const onBoardIdsChanged = useCallback((newBoardIds) => {
    setOurQuery({
      ...ourQuery,
      boardIds: newBoardIds,
    });
  }, [ourQuery]);

  const onSubmit = useCallback(() => {
    const prom = dispatch(searchBoards({
      query: ourQuery,
      queryId: selectedQuery,
    }));
    onClose(prom);
  }, [onClose, ourQuery, selectedQuery]);

  return (
    <Drawer
      title="Search Boards"
      visible={visible}
      onClose={onClose}
      width={800}
    >
      <div
        style={{
          position: 'absolute',
          top: '16px',
          left: 'calc(100% - 650px)',
        }}
      >
        <QuerySelector
          searchQueryMap={searchQueryMap}
          currentQuery={ourQuery}
          selectedQuery={selectedQuery}
          onSelectQuery={onSelectQuery}
          type="boards"
        />
      </div>
      <Col
        style={{
          height: 'calc(100% - 30px)',
          width: '100%',
          overflowY: 'scroll',
          overflowX: 'hidden',
          paddingRight: '10px',
        }}
      >
        <BoardsSelector
          value={boardIds}
          onChange={onBoardIdsChanged}
        />
        <SearchField
          selectedType="text"
          configProps={{
            title: 'Title',
          }}
          queries={ourQuery?.native?.title}
          onQueriesChange={onNativeQueriesChange('title')}
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Board Status',
            title: 'Status',
          }}
          queries={ourQuery?.native?.status}
          onQueriesChange={onNativeQueriesChange('status')}
          boardIds={boardIds}
        />
        <SearchField
          selectedType="number"
          configProps={{
            title: 'Number',
          }}
          queries={ourQuery?.native?.formNumber}
          onQueriesChange={onNativeQueriesChange('formNumber')}
        />
        <SearchField
          selectedType="dateRange"
          configProps={{
            title: 'Created At',
          }}
          queries={ourQuery?.native?.createdAt}
          onQueriesChange={onNativeQueriesChange('createdAt')}
        />
        <SearchField
          selectedType="dateRange"
          configProps={{
            title: 'Last Updated',
          }}
          queries={ourQuery?.native?.lastUpdated}
          onQueriesChange={onNativeQueriesChange('lastUpdated')}
        />
        <SearchField
          selectedType="dateRange"
          configProps={{
            title: 'Card Updated',
          }}
          queries={ourQuery?.native?.dataUpdated}
          onQueriesChange={onNativeQueriesChange('dataUpdated')}
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Users',
            title: 'Users',
          }}
          queries={ourQuery?.native?.user}
          onQueriesChange={onNativeQueriesChange('user')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Customers',
            title: 'Linked Customer',
          }}
          queries={ourQuery?.native?.linkCustomerId}
          onQueriesChange={onNativeQueriesChange('linkCustomerId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Projects',
            title: 'Linked Project',
          }}
          queries={ourQuery?.native?.linkProjectId}
          onQueriesChange={onNativeQueriesChange('linkProjectId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Equipment',
            title: 'Linked Equipment',
          }}
          queries={ourQuery?.native?.linkEquipmentId}
          onQueriesChange={onNativeQueriesChange('linkEquipmentId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Materials',
            title: 'Linked Material',
          }}
          queries={ourQuery?.native?.linkMaterialId}
          onQueriesChange={onNativeQueriesChange('linkMaterialId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Users',
            title: 'Linked User',
          }}
          queries={ourQuery?.native?.linkUserId}
          onQueriesChange={onNativeQueriesChange('linkUserId')}
          multi
        />
        <BoardsFieldsSearch
          boardIds={boardIds}
          query={ourQuery}
          onQueriesChange={onCustomQueriesChange}
        />
      </Col>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

BoardsSearchDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

BoardsSearchDrawer.defaultProps = {
  visible: false,
};
