import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

import DivisionTable from './DivisionTable';
import DivisionsDrawer from './DivisionsDrawer';

import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import SimpleNameRow from '../../common/editable/SimpleNameRow';

import { transferDivisions, renameDivision, updateDivisionCode } from '../state/settings.actions';
import DivisionCodeInput from './DivisionCodeInput';

const { TabPane } = Tabs;

export default function DivisionsCard({
  id,
  name,
  code,
  onDelete,
  users = [],
  teams = [],
  projects = [],
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const divisionCount = useSelector((state) => Object.values(state.settings.divisions).length);
  const [selectedIds, setSelectedIds] = useState([]);
  const [showDrawer, setShowDrawer] = useState(false);
  const [activeTab, setActiveTab] = useState('Users');
  const [isMoveMode, setIsMoveMode] = useState(false);

  const onSelectChange = useCallback((shouldSetMove) => (newSelectedIds) => {
    setSelectedIds(newSelectedIds);
    setIsMoveMode(shouldSetMove && newSelectedIds.length > 0);
  }, []);

  const openDrawer = useCallback(() => setShowDrawer(true), []);
  const hideDrawer = useCallback(() => setShowDrawer(false), []);

  const onSubmit = useCallback(async ({ divisionId, keepOldDivision }) => {
    if (await dispatch(transferDivisions({
      type: activeTab.toLowerCase(),
      ids: selectedIds,
      divisionId: isMoveMode ? divisionId : id,
      keepOldDivision,
      oldDivisionId: id,
    }))) {
      setShowDrawer(false);
    }
  }, [isMoveMode, activeTab, selectedIds, id, dispatch]);

  const onNameChanged = useCallback((newName) => {
    dispatch(renameDivision(id, newName));
  }, [id]);

  const onCodeUpdate = useCallback((newCode) => {
    dispatch(updateDivisionCode(id, newCode));
  }, [id]);

  return (
    <div className="divisions-card">
      <SettingsCardHeader title={name}>
        {onDelete && (
        <OnTraccrButton
          title="Delete"
          type="primary"
          onClick={onDelete}
        />
        )}
      </SettingsCardHeader>

      <CompanySettingsCard
        containerStyle={{
          position: 'absolute',
          top: 150,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <div style={{ height: '100%', borderRadius: 8, overflow: 'hidden' }}>
          <SimpleNameRow
            name={name}
            onNameChanged={onNameChanged}
            title="Division Name"
            type="union"
          />

          <DivisionCodeInput
            code={code}
            onCodeUpdate={onCodeUpdate}
            style={{
              padding: '15px',
            }}
          />

          <Tabs
            style={{ height: 'calc(100% - 53px)' }}
            tabBarExtraContent={
              divisionCount > 1
                ? (
                  <OnTraccrButton
                    title={isMoveMode ? 'Transfer' : 'Add'}
                    type={isMoveMode ? 'back' : 'primary'}
                    onClick={openDrawer}
                  />
                )
                : null
            }
            tabBarStyle={{ padding: '0px 20px', marginBottom: 0 }}
            onChange={setActiveTab}
            activeKey={activeTab}
          >
            <TabPane tab="Users" key="Users">
              <DivisionTable
                data={users}
                type="users"
                onSelectChange={onSelectChange(true)}
              />
            </TabPane>
            <TabPane tab="Teams" key="Teams">
              <DivisionTable
                type="teams"
                data={teams}
                onSelectChange={onSelectChange(true)}
              />
            </TabPane>
            <TabPane tab={t('Project', { count: 0 })} key="Projects">
              <DivisionTable
                type="projects"
                data={projects}
                onSelectChange={onSelectChange(true)}
              />
            </TabPane>
          </Tabs>
        </div>
      </CompanySettingsCard>
      <DivisionsDrawer
        id={id}
        visible={showDrawer}
        onClose={hideDrawer}
        onSubmit={onSubmit}
        type={activeTab}
        isMoveMode={isMoveMode}
        name={name}
        onSelectChange={onSelectChange(false)}
      />
    </div>
  );
}

DivisionsCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  code: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  teams: PropTypes.array.isRequired,
  projects: PropTypes.array.isRequired,
};
