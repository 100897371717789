export default {};

export const defaultFilters = {
  active: [1],
};

export const filterData = [
  [
    {
      title: 'Archive',
      key: 'active',
      children: [
        {
          title: 'Active',
          key: 1,
        },
        {
          title: 'Archived',
          key: 0,
        },
      ],
    },
  ],
];
