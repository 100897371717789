import { getIdMap } from 'ontraccr-common/lib/Common';
import {
  CREATE_SEARCH_QUERY,
  DELETE_SEARCH_QUERY,
  GET_SEARCH_QUERIES,
  SEARCH_BOARDS,
  SEARCH_FORMS,
  UPDATE_SEARCH_QUERY,
} from '../../state/actionTypes';

const initialState = {
  formQueries: {},
  formResults: [],
  boardQueries: {},
  boardResults: [],
};

const queryMap = {
  forms: 'formQueries',
  boards: 'boardQueries',
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case UPDATE_SEARCH_QUERY:
    case CREATE_SEARCH_QUERY: {
      const {
        payload: {
          query,
          type,
        } = {},
      } = action;
      if (!query) return state;
      const key = queryMap[type];
      if (!key) return state;
      const newQueries = { ...state[key] };
      newQueries[query.id] = query;
      return {
        ...state,
        [key]: newQueries,
      };
    }
    case DELETE_SEARCH_QUERY: {
      const {
        payload: {
          id,
          type,
        } = {},
      } = action;
      const key = queryMap[type];
      if (!key) return state;
      const newQueries = { ...state[key] };
      delete newQueries[id];
      return {
        ...state,
        [key]: newQueries,
      };
    }
    case GET_SEARCH_QUERIES: {
      const {
        payload: {
          type,
          queries,
        } = {},
      } = action;
      const newQueries = getIdMap(queries);
      const key = queryMap[type];
      if (!key) return state;
      return {
        ...state,
        [key]: newQueries,
      };
    }
    case SEARCH_FORMS: {
      const {
        payload: {
          results = [],
        } = {},
      } = action;

      return {
        ...state,
        formResults: results,
      };
    }
    case SEARCH_BOARDS: {
      const {
        payload: {
          results = [],
        } = {},
      } = action;

      return {
        ...state,
        boardResults: results,
      };
    }
    default:
      return state;
  }
};
