import React from 'react';
import { Row, Col, Divider, Popover } from 'antd';
import { DateTime } from 'luxon';
import {
  MobileOutlined,
  DesktopOutlined,
  FormOutlined,
  ThunderboltOutlined
} from '@ant-design/icons';

import ProfileCircle from '../common/profile/ProfileCircle';
import colors from '../constants/Colors';

import Permissions from '../auth/Permissions';
import HistoryEntryDiff from '../projects/history/HistoryEntryDiff';
import { initialsFromName } from '../helpers/users';

const boldStyle = { fontFamily: 'roboto-bold'};

const DeviceIcon = ({ device }) => {
  if(!device) return null;
  let text = 'the Web app';
  let icon = <DesktopOutlined/>;
  if(device === 'mobile') {
    text = 'the Mobile app';
    icon = <MobileOutlined/>
  } else if (device === 'form') {
    text = 'a form';
    icon = <FormOutlined/>
  }
  return (
    <Popover content={`Updated from ${text}`}>
      {icon}
    </Popover>
  );
}
const bold = (text) => <span style={boldStyle}>{text}</span>

export default ({
  timestamp = 0,
  oldQuantity,
  diff,
  quantity,
  type,
  userId,
  formName,
  user:{
    name: userName = '',
  } = {},
  name,
  device,
  oldText,
  locationText
}) => {
  const initials = initialsFromName(userName);
  const timeText = DateTime.fromMillis(timestamp)
    .toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY);
  let userText = Permissions.id === userId ? 'You' : userName;
  if (!userId || device === 'form') userText = '';

  const suffix = quantity === 1 ? '' : 's';

  let text = '';
  let customText;
  let verb = '';
  let preposition = ''
  let amount = quantity;
  let icon = (
    <ProfileCircle
    initials={initials}
  />
  )
  if (device === 'form') {
    customText = (
      <span>A {bold(formName)} form triggered a material update workflow step which updated the quantity of {bold(name)} from {bold(oldQuantity)} to {bold(quantity)}</span>
    )
    icon = (
      <ThunderboltOutlined style={{ fontSize: 32 }}/>
    )
  } else {
    switch(type) {
      case 'create':
        verb = 'added';
        preposition = 'to';
        break;
      case 'delete':
        verb = 'removed';
        preposition = 'from';
        amount = oldQuantity;
        break;
      case 'update':
        if (oldText === locationText || !oldText) {
          // Just changed the amount
          customText = (
            <span> updated {bold(name)} from {bold(oldQuantity)} to {bold(quantity)}</span>
          );
          break;
        }
        customText = (
          <span> changed the location from {bold(oldText)} to {bold(locationText)} and the quantity from {bold(oldQuantity)} to {bold(quantity)}
          </span>
        );
        break;
      default:
        break;
    }
  }
 
  text = customText ?? <span> {verb} {bold(amount)} {bold(`${name}${suffix}`)} {preposition} {bold(locationText)}</span>

  return (
    <Row align='top' justify='space-between' gutter={10} style={{ margin: '10px 0px' }}>
      <Col flex='34px'>
        {icon}
      </Col>
      <Col flex='auto' style={{ maxWidth: 'calc(100% - 44px)'}}>
        <Row justify='space-between' align='middle'>
          <Col flex='250px'>
            {bold(userText)}
            {text}
          </Col>
          <Col flex='auto' style={{ maxWidth: 'calc(100% - 250px)'}}>
            <Row justify='end' align='middle'>
              <Col flex='30px'>
                <DeviceIcon device={device}/>
              </Col>
              <Col style={{ maxWidth: 'calc(100% - 30px)'}}>
                <HistoryEntryDiff 
                  value={quantity}
                  diff={diff}
                  lastValue={oldQuantity}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row style={{ fontSize: 10, color: colors.ONTRACCR_OPACITY_GRAY}}>
          {timeText}
        </Row>
      </Col>
      <Divider style={{ marginTop: 20, marginBottom: 5 }}/>
    </Row>
  );
}