import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
} from 'antd';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import axios from 'axios';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import DisplayText from '../../../common/text/DisplayText';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';

import { request } from '../../../helpers/requests';

import Colors from '../../../constants/Colors';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

import Permissions from '../../../auth/Permissions';

const flavourText = (
  <div>
    Deactivating your Zapier integration will disable any
    further syncing between Zapier and Ontraccr.
  </div>
);

const hideCode = new Array(36).fill('•').join('');

const DIVIDER_STYLE = {
  margin: 0,
  backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
  maxWidth: 440,
  minWidth: 440,
};

function Zapier({
  visible,
}) {
  const company = useSelector((state) => state.settings.company);
  const {
    userId: ownerId,
  } = company ?? {};

  const [config, setConfig] = useState();
  const [showCode, setShowCode] = useState(false);

  const toggleShowCode = useCallback(() => {
    setShowCode(!showCode);
  }, [showCode]);

  const onConnect = useCallback(async () => {
    const { data: newConfig } = await request({
      call: axios.put('/zapier/connection'),
    });
    setConfig(newConfig);
  }, []);

  const showConfirm = useCallback(() => (
    CustomConfirmModal({
      title: 'Deactivate Zapier Integration',
      content: (
        <p>
          Are you sure you want to your deactivate Zapier integration?
          <br />
          <br />
          {flavourText}
        </p>
      ),
      async onOk() {
        const { data: passed } = await request({
          call: axios.delete('/zapier/connection'),
        });
        if (passed) {
          setConfig();
        }
      },
      okText: 'Deactivate',
    })
  ), []);

  useEffect(() => {
    const loadConfig = async () => {
      const { data: newConfig } = await axios.get('/zapier/connection');
      if (newConfig?.apiKey) setConfig(newConfig);
    };
    if (visible) {
      loadConfig();
    } else {
      setConfig();
      setShowCode(false);
    }
  }, [visible]);

  if (!config?.apiKey) {
    return (
      <OnTraccrButton
        style={{ margin: '20px 0px' }}
        onClick={onConnect}
        title="Connect"
      />
    );
  }

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <div style={{ width: '100%', position: 'relative' }}>
        <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
          <Col>
            <DisplayText
              title="Authorization Code"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
          </Col>
        </Row>
        <DisplayText
          title="Provide this authorization code to Zapier"
          style={{
            marginBottom: 0,
            maxWidth: 275,
            paddingLeft: 10,
            fontFamily: 'roboto-regular',
            color: Colors.ONTRACCR_OPACITY_GRAY,
          }}
        />
        <div className="integation-auth-code" style={{ marginTop: 15, marginBottom: 15 }}>
          {showCode || !config.apiKey ? config.apiKey : hideCode}
        </div>
        {
        config.apiKey
        && (
        <div id="gmail-visibility-toggle-container">
          <BorderlessButton
            iconNode={showCode ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={toggleShowCode}
            style={{ padding: 0 }}
          />
        </div>
        )
      }
      </div>
      <Divider style={DIVIDER_STYLE} />
      <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
        <Col>
          <DisplayText
            title="Deactivate Zapier Integration"
            style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
          />
        </Col>
      </Row>
      <DisplayText
        title={flavourText}
        style={{
          marginBottom: 20,
          maxWidth: 275,
          paddingLeft: 10,
          fontFamily: 'roboto-regular',
          color: Colors.ONTRACCR_OPACITY_GRAY,
        }}
      />
      <Row style={{ width: '100%', marginBottom: 10 }} justify="center">
        <OnTraccrButton
          title="Deactivate"
          type={Permissions.id === ownerId ? 'primary' : 'back'}
          disabled={Permissions.id !== ownerId}
          style={{ margin: 10 }}
          onClick={showConfirm}
        />
        <DisplayText
          style={{
            textAlign: 'center',
            fontFamily: 'roboto-bold',
            color: Colors.ONTRACCR_OPACITY_GRAY,
            height: 32,
          }}
          title="Only the account owner can deactivate"
        />
      </Row>
    </div>
  );
}

Zapier.propTypes = {
  visible: PropTypes.bool,
};

Zapier.defaultProps = {
  visible: false,
};

export default Zapier;
