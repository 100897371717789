import {
  TOGGLE_FULLSCREEN,
  USER_LOGOUT,
  TOGGLE_MENU_COLLAPSE,
  SET_MAIN_LOADED,
} from '../../state/actionTypes';

export default {};

export const toggleFullscreen = () => ({
  type: TOGGLE_FULLSCREEN,
});

export const toggleMenuCollapse = () => ({
  type: TOGGLE_MENU_COLLAPSE,
});

export const userLogout = () => ({
  type: USER_LOGOUT,
});

export const setMainLoaded = (value) => ({
  type: SET_MAIN_LOADED,
  payload: {
    value,
  },
});
