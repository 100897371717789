import { useMemo } from 'react';

import Permissions from '../../auth/Permissions';

export default function useBoardWriteAssignedPermissions({
  userBoardPermissions,
  cardUsers = [],
}) {
  const canWrite = useMemo(() => {
    const { writePerms, canEdit } = userBoardPermissions ?? {};
    if (canEdit || writePerms === 'writeAll') return true;
    if (writePerms === 'writeAssigned') return cardUsers.includes(Permissions.id);
    return false;
  }, [Permissions, userBoardPermissions, cardUsers]);

  return canWrite;
}
