import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import CompanyEditRow from '../../CompanyEditRow';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import SageProjectPayMappingDrawer from './SageProjectPayMappingDrawer';

import { getIdMap } from '../../../helpers/helpers';

import { deletePayMapping, getSagePayIDs } from '../../../sage/state/sage.actions';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

export default function SageProjectPayMapping({
  selectedIntegration,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const payIdMappings = useSelector((state) => state.sage.payIdMappings);
  const projects = useSelector((state) => state.projects.projects);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState();

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const showDrawer = useCallback(() => setDrawerOpen(true), []);
  const hideDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedMapping();
  }, []);

  const onEditClicked = useCallback((item) => () => {
    setSelectedMapping(item);
    setDrawerOpen(true);
  }, []);

  const onDeleteClicked = useCallback((item) => () => {
    const { projectId } = item;
    const {
      [projectId]: { name: projectName } = {},
    } = projectIdMap;
    CustomConfirmModal({
      title: `Delete pay mapping for ${projectName}?`,
      okText: 'Delete',
      onOk: () => dispatch(deletePayMapping(selectedIntegration?.id, projectId)),
    });
  }, [selectedIntegration, projectIdMap]);

  useEffect(() => {
    if (selectedIntegration?.id) {
      dispatch(getSagePayIDs({ integrationId: selectedIntegration?.id }));
    } else {
      // Clear out the pay IDs if no integration is selected
      dispatch(getSagePayIDs());
    }
  }, [selectedIntegration]);

  const columns = useMemo(() => (
    [
      {
        title: `${t('Project')} Name`,
        dataIndex: 'projectId',
        onHeaderCell,
        render: (projectId) => {
          const { [projectId]: { name: projectName } = {} } = projectIdMap;
          return projectName;
        },
      }, {
        title: 'Sage Pay ID',
        dataIndex: 'sagePayId',
        onHeaderCell,
      }, {
        title: '',
        dataIndex: 'edit',
        onHeaderCell,
        render: (_, item) => (
          <BorderlessButton iconNode={<EditOutlined />} onClick={onEditClicked(item)} />
        ),
      }, {
        title: '',
        dataIndex: 'delete',
        onHeaderCell,
        render: (_, item) => (
          <BorderlessButton iconNode={<DeleteOutlined style={{ color: 'red' }} />} onClick={onDeleteClicked(item)} />
        ),
      },
    ]
  ), [onEditClicked, projectIdMap]);

  return (
    <>
      <div>
        <CompanyEditRow title="Default Project Pay IDs" divider={false} style={{ marginRight: 54 }}>
          <OnTraccrButton
            title="Add"
            onClick={showDrawer}
          />
        </CompanyEditRow>
        <Table
          size="small"
          scroll={{ y: 400 }}
          columns={columns}
          dataSource={payIdMappings}
          pagination={false}
          style={{ marginTop: 10, marginBottom: 1 }}
        />
      </div>
      <SageProjectPayMappingDrawer
        visible={drawerOpen}
        onClose={hideDrawer}
        selectedMapping={selectedMapping}
        selectedIntegration={selectedIntegration}
      />
    </>
  );
}

SageProjectPayMapping.propTypes = {
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
};

SageProjectPayMapping.defaultProps = {
  selectedIntegration: {},
};
