import React, { useCallback } from 'react';
import { Popover } from 'antd';
import { SettingOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../common/buttons/BorderlessButton';

import colors from '../constants/Colors';

const stopPropagation = (event) => {
  event.stopPropagation();
};
export default function DashboardWidgetEditButton({ onConfig, showConfig, configContent }) {
  const onClick = useCallback(() => {
    onConfig(true);
  }, []);

  return (
    <Popover
      content={configContent}
      title="Configure Widget"
      trigger="click"
      open={showConfig}
      onOpenChange={onConfig}
    >
      <BorderlessButton
        className="dashboard-component-edit-btn"
        iconNode={(
          <SettingOutlined style={{ color: colors.ONTRACCR_GRAY, marginLeft: 0, width: 20 }} />
        )}
        onClick={onClick}
        onMouseDown={stopPropagation}
        onTouchStart={stopPropagation}
      />
    </Popover>
  );
}

DashboardWidgetEditButton.propTypes = {
  onConfig: PropTypes.func,
  showConfig: PropTypes.bool,
  configContent: PropTypes.element,
};

DashboardWidgetEditButton.defaultProps = {
  onConfig: () => {},
  showConfig: false,
  configContent: <div />,
};
