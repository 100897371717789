import React, { useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row } from 'antd';

import CompanyEditRow from '../../CompanyEditRow';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';

import SalesRabbitWorkflowDrawer from './SalesRabbitWorkflowDrawer';

import { deleteSalesRabbitWorkflows } from './state/salesrabbit.actions';

import {
  getSettingsColumns,
} from '../../settings.helpers';

import sortByString from '../../../helpers/helpers';

const HELP_TEXT = 'Set up workflows to trigger from SalesRabbit actions';

function SalesRabbitWorkflows() {
  const dispatch = useDispatch();

  const workflows = useSelector((state) => state.salesrabbit.workflows);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWorkflow, setSelectedWorkflow] = useState();

  const onOpenDrawer = useCallback(() => setDrawerOpen(true), []);
  const onCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedWorkflow();
  }, []);

  const onDeleteClicked = useCallback((record) => {
    CustomConfirmModal({
      title: `Delete ${record.title} Sales Rabbit Workflow?`,
      onOk: async () => {
        if (await dispatch(deleteSalesRabbitWorkflows(record.id))) {
          onCloseDrawer();
        }
      },
    });
  }, [dispatch, onCloseDrawer]);

  const workflowList = useMemo(() => {
    const values = Object.values(workflows);
    values.sort(sortByString('name'));
    return values;
  }, [workflows]);

  const columns = useMemo(() => (
    getSettingsColumns({
      onEdit: (record) => {
        setDrawerOpen(true);
        setSelectedWorkflow(record);
      },
      onDelete: onDeleteClicked,
    })
  ), [onDeleteClicked]);

  return (
    <>
      <CompanyEditRow
        title="Workflows"
        helpText={HELP_TEXT}
        divider={false}
      />
      <Row justify="end" style={{ width: '100%' }}>
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={onOpenDrawer}
        />
      </Row>
      <Table
        columns={columns}
        dataSource={workflowList}
        pagination={false}
        size="small"
        scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
      />
      <SalesRabbitWorkflowDrawer
        onClose={onCloseDrawer}
        onDelete={onDeleteClicked}
        visible={drawerOpen}
        selectedWorkflow={selectedWorkflow}
      />
    </>
  );
}

export default SalesRabbitWorkflows;
