/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
  useMemo,
  useContext,
} from 'react';
import { BranchesOutlined } from '@ant-design/icons';
import { Checkbox, Col, Row } from 'antd';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import FieldSelector from './selectors/FieldSelector';
import { updateData } from './workflowHelpers';
import HoverHelp from '../../common/HoverHelp';
import FormWorkflowContext from './FormWorkflowContext';

const HOVER_TEXT = `
The connected workflow will run on each of the selected options in the selected field.
`;

const DISABLED_TEXT = 'Add at least one Choose one or more field to the form to use this action.';

export default function WorkflowLogicalLoopNode({
  setElements,
  setDataMap,
  isDisplay,
  sections = [],
} = {}) {
  return ({
    id,
    draggable,
    disabled,
    data = {},
  }) => {
    const {
      dataMap = {},
    } = useContext(FormWorkflowContext) ?? {};

    const {
      field: initialSelected,
      mapLabelToEquipment: shouldMapEquipmentLabel = false,
    } = dataMap[id] ?? data;
    const [selected, setSelected] = useState(initialSelected);
    const [
      equipmentLabelToggleValue,
      setEquipmentLabelToggleValue,
    ] = useState(shouldMapEquipmentLabel);

    const {
      dropdownFields,
      fieldMap,
    } = useMemo(() => {
      const fields = [];
      const map = {};

      sections.forEach(({ fields: sectionFields = [], name }) => {
        sectionFields.forEach((field) => {
          if (field.selectedType === 'dropdown') {
            map[field.id] = field;

            fields.push({
              id: field.id,
              label: `${name}: ${field.configProps.title}`,
            });
          }
        });
      });

      return {
        dropdownFields: fields,
        fieldMap: map,
      };
    }, [sections]);

    const isSelectedFieldEquipmentLabelDropdown = useCallback((value) => {
      const relevantField = fieldMap[value];
      if (!relevantField) return false;
      const {
        configProps: {
          dataType,
          subDataType = [],
        } = {},
        selectedType,
      } = relevantField;

      return selectedType === 'dropdown'
        && dataType === 'Labels'
        && subDataType?.length === 1
        && subDataType[0] === 'equipment';
    }, [fieldMap]);

    const shouldShowEquipmentLableToggle = useMemo(() => (
      isSelectedFieldEquipmentLabelDropdown(selected)
    ), [isSelectedFieldEquipmentLabelDropdown, selected]);

    const onChange = useCallback((newSelected) => {
      const selectedField = fieldMap[newSelected];
      const newPayload = {
        field: newSelected,
      };

      if (selectedField && !isSelectedFieldEquipmentLabelDropdown(newSelected)) {
        newPayload.mapLabelToEquipment = false;
      }

      setDataMap(updateData(id, newPayload));
      setSelected(newSelected);
    }, [id, setDataMap, isSelectedFieldEquipmentLabelDropdown]);

    const onToggle = useCallback((e) => {
      const value = e.target.checked;
      setDataMap(updateData(id, { mapLabelToEquipment: value }));
      setEquipmentLabelToggleValue(value);
    }, [id, setDataMap]);

    return (
      <WorkflowActionNode
        id={id}
        title={`Logical Loop ${disabled ? ' - DISABLED' : ''}`}
        Icon={BranchesOutlined}
        type="logicalLoop"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
        className="workflow-logic-node"
      >
        {!draggable && (
        <div>
          <FieldSelector
            disabled={isDisplay}
            onChange={onChange}
            fields={dropdownFields}
            selected={selected}
          />
          { shouldShowEquipmentLableToggle && (
            <Row style={{ width: '100%', margin: '5px 0px' }}>
              <Col>
                <Checkbox
                  onChange={onToggle}
                  defaultChecked={shouldMapEquipmentLabel}
                  value={equipmentLabelToggleValue}
                >
                  Loop through associated equipment instead of labels
                  <HoverHelp
                    content={(
                      <div style={{ width: 300 }}>
                        Check this box if you want to loop using the equipment
                        associated with the selected equipment labels.
                      </div>
                    )}
                  />
                </Checkbox>
              </Col>
            </Row>
          )}
          <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          <WorkflowHandle
            type="source"
            position="bottom"
            disabled={isDisplay}
          />
        </div>
        )}
      </WorkflowActionNode>
    );
  };
}
