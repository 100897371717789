import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox, Col, Drawer, Form, Row,
} from 'antd';
import { PropTypes } from 'prop-types';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../common/inputs/FormTextInput';
import FormBuilder from '../../forms/FormBuilder/FormBuilder';

import {
  createBoardCardTemplate,
  updateBoardCardTemplate,
} from '../../boards/state/boards.actions';
import { cardTitleValidator } from '../../boards/boardHelpers';
import HoverHelp from '../../common/HoverHelp';
import FormNumberInput from '../../common/inputs/FormNumberInput';

export default function BoardCardDrawer({
  visible,
  onClose,
  editData,
}) {
  const {
    id,
    title: editTitle,
    useCardNumberAsCardTitle: editUseCardNumberAsCardTitle = false,
    cardNumberAnchor: editCardNumberAnchor = 1,
    fields: initialSections,
  } = editData || {};

  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const cardTemplates = useSelector((state) => state.boards.cardTemplates);

  const [hasErrors, setHasErrors] = useState(true);
  const [sections, setSections] = useState([]);

  const onSubmit = useCallback(async () => {
    const title = await form.getFieldValue('title');
    const useCardNumberAsCardTitle = !!(await form.getFieldValue('useCardNumberAsCardTitle'));
    const cardNumberAnchor = await form.getFieldValue('cardNumberAnchor');
    const payload = {
      title,
      useCardNumberAsCardTitle,
      cardNumberAnchor,
      fields: sections,
    };
    if (id) {
      // Edit,
      if (await dispatch(updateBoardCardTemplate(id, payload))) {
        onClose();
      }
      return;
    }

    if (await dispatch(createBoardCardTemplate(payload))) {
      onClose();
    }
  }, [dispatch, sections, form, id]);

  const onFieldsChange = useCallback(() => {
    const fields = form.getFieldsError();
    setHasErrors(fields.some(({ errors }) => errors.length > 0));
  }, [form]);

  useEffect(() => {
    if (!visible) {
      setHasErrors(true);
      setSections([]);
      form.resetFields();
    }
  }, [visible]);

  useEffect(() => {
    form.setFieldsValue({ title: editTitle });
    form.setFieldsValue({ useCardNumberAsCardTitle: editUseCardNumberAsCardTitle });
    form.setFieldsValue({ cardNumberAnchor: editCardNumberAnchor });

    setHasErrors(!editTitle);
  }, [form, editTitle, editUseCardNumberAsCardTitle, editCardNumberAnchor]);

  const cardTitleMap = useMemo(() => {
    const titleMap = {};
    Object.values(cardTemplates).forEach((card) => {
      const { title: existingTitle = '' } = card;
      titleMap[existingTitle.toLowerCase()] = card;
    });
    return titleMap;
  }, [cardTemplates]);

  const titleValidator = useCallback(cardTitleValidator(id, cardTitleMap), [id, cardTitleMap]);

  return (
    <Drawer
      title={`${id ? 'Edit' : 'Add'} Board Card`}
      visible={visible}
      onClose={onClose}
      width={1000}
      bodyStyle={{
        padding: '0px 24px',
      }}
    >
      <Form layout="vertical" form={form} onFieldsChange={onFieldsChange}>
        <FormTextInput
          name="title"
          label="Card Title"
          rules={[
            { required: true, message: 'Title is required' },
            { validator: titleValidator },
          ]}
          isNotDisplay
        />
        <Row style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 30,
        }}
        >

          <Col style={{
            flexGrow: 1,
          }}
          >
            <Form.Item
              name="useCardNumberAsCardTitle"
              label={(
                <Row gutter={20}>
                  <Col>
                    Use Card Number as Card Title
                  </Col>
                  <Col>
                    <HoverHelp
                      content={(
                        <div style={{ width: 300 }}>
                          If you check this box, cards of this type will
                          set the card title to the automatically generated card number.
                        </div>
                  )}
                    />
                  </Col>
                </Row>
                    )}
              labelCol={{
                style: { marginTop: 10 },
              }}
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </Col>

          <Col style={{
            flexGrow: 3,
          }}
          >
            <FormNumberInput
              isNotDisplay
              min={1}
              label={(
                <Row gutter={20}>
                  <Col>
                    Card Number Anchor
                  </Col>
                  <Col>
                    <HoverHelp
                      content={(
                        <div style={{ width: 300 }}>
                          Any value stored in this field will create an anchor number that all
                          generated numbers will build off from.
                        </div>
                            )}
                    />
                  </Col>
                </Row>
                    )}
              name="cardNumberAnchor"
              value={editCardNumberAnchor}
              rules={[{ required: true, message: 'Please enter an anchor (default 1)' }]}
            />

          </Col>

        </Row>

      </Form>

      <FormBuilder
        style={{ left: 3, right: 3, top: 225 }}
        onSectionsChanged={setSections}
        initialSections={initialSections}
        isBoardCards
        enablePermissions
      />

      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        canSubmit={!hasErrors}
      />
    </Drawer>
  );
}

BoardCardDrawer.propTypes = {
  visible: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  editData: PropTypes.object.isRequired,
};
