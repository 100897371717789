import axios from 'axios';

import { request } from '../../helpers/requests';

const URL_MAP = {
  union: '',
  local: '/locals',
  class: '/classes',
};

export default {
  get: () => request({
    call: axios.get('/unions', { params: { getWebData: true } }),
    errMsg: 'Could not get unions',
    hideSuccessToast: true,
  }),
  update: (id, payload) => request({
    call: axios.put(`/unions/${id}`, payload),
    errMsg: 'Could not update union',
  }),
  updateLocal: (id, payload) => request({
    call: axios.put(`/unions/locals/${id}`, payload),
    errMsg: 'Could not update union local',
  }),
  updateClass: (id, payload) => request({
    call: axios.put(`/unions/classes/${id}`, payload),
    errMsg: 'Could not update work classification',
  }),
  create: (payload) => request({
    call: axios.post('/unions', payload),
    errMsg: 'Could not create union',
  }),
  createLocal: (payload) => request({
    call: axios.post('/unions/locals', payload),
    errMsg: 'Could not create union local',
  }),
  createClass: (payload) => request({
    call: axios.post('/unions/classes', payload),
    errMsg: 'Could not create work classification',
  }),
  delete: (type, id) => request({
    call: axios.delete(`/unions${URL_MAP[type]}/${id}`),
    errMsg: `Could not delete union${type === 'union' ? '' : ` ${type}`}`,
  }),
};
