import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Checkbox,
  Tabs as AntdTabs,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';

import CompanySettingsCard from '../CompanySettingsCard';
import CompanyEditRow from '../CompanyEditRow';
import { updateCompanyCustomizationTabs } from '../state/settings.actions';

const categoryToEntityMap = {
  Projects: 'Project',
};

const tabStyle = { paddingTop: 7, overflowY: 'auto', height: '90vh' };
const { TabPane } = AntdTabs;

function Tabs() {
  const { t } = useTranslation();
  const [categories, setCategories] = useState([]);
  const [categoryToTabMap, setCategoryToTabMap] = useState({});
  const [stateKey, setStateKey] = useState();

  const dispatch = useDispatch();
  const tabs = useSelector((state) => state.settings.tabs);

  useEffect(() => {
    const categoryMap = {};

    tabs.forEach((tab) => {
      const suffix = ` (${tab.subCategory ?? 'General'})`;
      const relevantCategory = t(categoryToEntityMap[tab.category] ?? tab.category) + suffix;

      if (!categoryMap[relevantCategory]) {
        categoryMap[relevantCategory] = [];
      }
      categoryMap[relevantCategory].push(tab);
    });

    setCategories(Object.keys(categoryMap));
    setCategoryToTabMap(categoryMap);
  }, [tabs]);

  const onChange = async (tab, e) => {
    await dispatch(updateCompanyCustomizationTabs(tab.id, {
      enabled: e.target.checked,
    }));
  };

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
      }}
    >
      <AntdTabs
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 135,
          marginRight: 17,
        }}
        style={{ width: '100%' }}
        activeKey={stateKey}
        onChange={setStateKey}
      >
        { categories.map((category) => (
          <TabPane style={tabStyle} tab={category} key={category}>
            <CompanySettingsCard
              title={`${category} Tabs`}
              style={{
                padding: 0,
                height: 'auto',
              }}
            >
              <CompanyEditRow
                key={category}
                divider={false}
              >
                <Row>
                  {categoryToTabMap[category].map((tab) => (
                    <Col span={8} key={tab.tabName}>
                      <Checkbox
                        onChange={(e) => onChange(tab, e)}
                        checked={tab.enabled}
                      >
                        {tab.tabName}
                      </Checkbox>
                    </Col>
                  ))}
                </Row>
              </CompanyEditRow>
            </CompanySettingsCard>
          </TabPane>
        ))}
      </AntdTabs>
    </Row>
  );
}

export default Tabs;
