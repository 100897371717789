import React, { useEffect, useMemo, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col, Input, Empty } from 'antd';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import LiveFeedTextInput from '../dashboard/LiveFeed/LiveFeedTextInput';

import NotesRow from './NotesRow';

import Permissions from '../auth/Permissions';
import { includesTerm, getIdMap } from '../helpers/helpers';
import LiveFeedFileUpload from '../dashboard/LiveFeed/LiveFeedFileUpload';
import PostDrawer from '../dashboard/LiveFeed/PostDrawer';

export default function Notes({
  notes = {},
  id,
  getNotes,
  addNote,
  style = {},
  headerStyle = {},
  authorKey = 'userId',
  users: propUsers = [],
  userTrie: propUserTrie = {},
  allowTags,
  unreadSet = new Set(),
  customerNoteRender,
  canSend = true,
  allowUpload = false,
  type = '',
  getAttachments,
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const users = useSelector(state => state.users.users);
  const [searchStr,setSearchStr] = useState();
  const [files, setFiles] = useState([]);
  const [selectedPost, setSelectedPost] = useState();
  const addFile = (file) => {
    setFiles([...files, file]);
  };
  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  },[]);

  const onSend = useCallback(async ({ text, tags }) => {
    const result = await dispatch(addNote({
      id,
      text,
      tags: (allowTags ? tags : undefined),
      files,
    }));
    if (result) {
      if (getNotes) dispatch(getNotes(id));
      if (getAttachments) dispatch(getAttachments(id));
    }
    return result;
  },[dispatch, id, allowTags, files]);

  useEffect(() => {
    if(getNotes) dispatch(getNotes(id));
  },[dispatch,id]);
  const clearSelectedPost = () => setSelectedPost();
  const [uploadStarted, setUploadStarted] = useState(false);
  const toggleUpload = () => setUploadStarted(!uploadStarted);
  const clearFiles = () => setFiles([]);
  const endUpload = () => setUploadStarted(false);
  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const userMap = useMemo(() => getIdMap(users),[users]);
  const ourNotes = useMemo(() => {
    const {
      [id]: notesList = [],
    } = notes;
    const parsedNotes = [];
    notesList.forEach((noteObject) => {
      const { [authorKey]: authorId, timestamp, note } = noteObject;
      const {
        [authorId]: user = {},
      } = userMap;
      const { name: username = '' } = user;
      const parsedNote = {
        ...noteObject,
        user,
        date: timestamp ? DateTime.fromMillis(timestamp) : null,
        isCurrentUser: authorId === Permissions.id,
      };
      if(includesTerm(note,searchStr)
        || includesTerm(username,searchStr)
        || searchStr === 'me' && parsedNote.isCurrentUser){
        parsedNotes.push(parsedNote);
      }
    });
    parsedNotes.sort((a,b) => b.timestamp - a.timestamp);
    return parsedNotes;
  },[notes,id,searchStr,userMap]);

  return (
    <div className='customer-project-container' style={style}>
      <Row className='customer-project-search-row' justify='space-between' align='middle' style={headerStyle}>
        <Col span={10}>
          <Input.Search
            type='search'
            style={{ width:'100%', maxWidth: 300 }}
            className='searchbar'
            placeholder='Search'
            allowClear
            onChange={onSearch}
          />
        </Col>
      </Row>
      <div className='customer-project-scoller'>
        {ourNotes.map((note, index) => (
          customerNoteRender
          ? customerNoteRender(note, index)
          : <NotesRow onPostClick={setSelectedPost} {...note} hideDivider={index === ourNotes.length - 1} unread={unreadSet.has(note.id)} type={type}/>
        ))}
        {ourNotes.length > 0 && <div style={{ height: 100 }} />}
        {
          ourNotes.length === 0 &&
          <Row justify='center' align='middle' className='customer-project-empty'>
            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
          </Row>
        }
      </div>
      <PostDrawer post={selectedPost} onClose={clearSelectedPost} />
      {canSend &&
      <>
      <LiveFeedFileUpload
        shouldDownload
        onUploadEnd={toggleUpload}
        addFile={addFile}
        visible={uploadStarted}
        customProps={{ getContainer: false }}
      />
      <LiveFeedTextInput
        onSend={onSend}
        history={history}
        files={files}
        users={allowTags ? propUsers : []} // Need this here or LiveFeedTextInput will go into infinite loop
        userTrie={allowTags ? propUserTrie : {}} // Need this here or LiveFeedTextInput will go into infinite loop
        placeholder='Add a note'
        collapsable
        allowUpload={allowUpload}
        onUpload={toggleUpload}
        onUploadEnd={endUpload}
        uploading={uploadStarted}
        onFileRemove={removeFile}
        clearFiles={clearFiles}
      />
      </>}
    </div>
  );
}

Notes.propTypes = {
  notes: PropTypes.object,
  id: PropTypes.string,
  getNotes: PropTypes.func,
  addNote: PropTypes.func,
  style: PropTypes.object,
  headerStyle: PropTypes.object,
  authorKey: PropTypes.string,
  users: PropTypes.array,
  userTrie: PropTypes.object,
  allowTags: PropTypes.bool,
  unreadSet: PropTypes.instanceOf(Set),
  customerNoteRender: PropTypes.func,
  canSend: PropTypes.bool,
  allowUpload: PropTypes.bool,
  type: PropTypes.string,
  getAttachments: PropTypes.func,
};
