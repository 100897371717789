import {
  React,
  useCallback,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Form,
  List,
  Row,
} from 'antd';
import { DateTime } from 'luxon';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Colors from '../../constants/Colors';
import AddDrawer from '../../common/addDrawer';
import DisplayText from '../../common/text/DisplayText';
import WarningHeader from '../../common/text/WarningHeader';
import ProjectAddView from '../../projects/projectAddView';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FormTextInput from '../../common/inputs/FormTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import { generateCostcodePayload } from '../../projects/projectHelpers';
import { createTemplate, deleteTemplateById, updateTemplate } from '../../costcodes/state/costcodes.actions';
import { condenseTemplatePhasesAndCostcodes, generateEditCostcodePayload } from '../../helpers/costcodeHelpers';
import CustomTableListItem from '../Forms/CustomTableListItem';
import CompanySettingsCard from '../CompanySettingsCard';

const deleteModal = (onOk) => CustomConfirmModal({
  title: (
    <WarningHeader
      justify="center"
      title="Are you sure you want to delete this template?"
    />
  ),
  onOk,
});

function JobCosting() {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateValues, setTemplateValues] = useState({});
  const [lastOpen, setLastOpen] = useState(DateTime.local().toMillis());
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const settings = useSelector((state) => state.settings.company.settings);
  const users = useSelector((state) => state.users.users);
  const templates = useSelector((state) => state.costcodes.templates);
  const tabs = useSelector((state) => state.settings.tabs);
  const history = useHistory();
  const dispatch = useDispatch();

  const globalCodes = useMemo(() => costcodes.filter((cc) => cc.type === 'global'), [costcodes]);
  const templateTabs = useMemo(() => tabs.filter(
    (tab) => (tab.tabName === 'Cost Codes' || tab.tabName === 'Budget Tracking') && tab.enabled,
  ), [tabs]);

  const onAddClicked = useCallback(() => {
    setShowDrawer(true);
  });

  // Store all updates from addProjectView in local state
  const onValuesChanged = useCallback((key, value) => {
    setTemplateValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  });

  const clearValues = useCallback(() => {
    setSelectedTemplate();
    setTemplateValues({});
    setShowDrawer(false);
    setLastOpen(DateTime.local().toMillis());
  }, [
    setSelectedTemplate,
    setTemplateValues,
    setLastOpen,
    setShowDrawer,
  ]);

  // Submit and clear values
  const onDrawerClose = useCallback(async (values, submitted) => {
    let success = false;
    if (!submitted) {
      clearValues();
      return success;
    }
    const {
      costcodes: newCostcodes = [],
      phases: newPhases = [],
    } = templateValues;

    const {
      name,
      description,
      divisionId,
    } = values;
    if (!name || !description || !divisionId) {
      return false;
    }

    // Editing
    const ourTemplate = templates.find((template) => template.id === selectedTemplate);
    if (ourTemplate) {
      const {
        data: {
          costcodes: selectedCostcodes = [],
        },
      } = ourTemplate;

      const {
        globalCostcodes,
        createdCostcodes: newFormattedCostcodes,
        createdPhases: phases,
      } = generateEditCostcodePayload({
        oldCostcodes: selectedCostcodes,
        newCostcodes,
        newPhases,
      });

      const payload = {
        name,
        description,
        divisionId,
        globalCostcodes,
        newCostcodes: newFormattedCostcodes,
        phases,
      };
      success = await dispatch(updateTemplate(selectedTemplate, payload));
    } else {
      // Adding
      const {
        fromGlobalCostcodes,
        createdCostcodes,
        createdPhases,
      } = generateCostcodePayload({
        newCostcodes,
        newPhases,
      });

      const payload = {
        name,
        description,
        divisionId,
        globalCostcodes: fromGlobalCostcodes,
        newCostcodes: createdCostcodes,
        phases: createdPhases,
      };

      success = await dispatch(createTemplate(payload));
    }
    if (success) {
      clearValues();
    }
    return success;
  }, [
    templateValues,
    generateCostcodePayload,
    setSelectedTemplate,
    setTemplateValues,
  ]);

  const onDelete = useCallback((id) => deleteModal(() => dispatch(deleteTemplateById(id))));

  const onEdit = useCallback((value) => {
    setSelectedTemplate(value);
    setShowDrawer(true);
  }, [setSelectedTemplate, setShowDrawer]);

  const renderItem = useCallback((item) => (
    <CustomTableListItem
      onDelete={onDelete}
      onEdit={onEdit}
      item={item}
    />
  ), [onDelete, onEdit]);

  const templateDetailsView = useCallback(() => (
    <>
      <Row style={{ width: '100%' }} gutter={20}>
        <Col span={6}>
          <FormTextInput
            isNotDisplay
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Name is required ' },
            ]}
          />
        </Col>
        <Col span={6}>
          <Form.Item
            name="divisionId"
            label="Division"
            rules={[{ required: true, message: 'Please enter a division' }]}
            style={{ marginTop: 7 }}
            valuePropName="divisionId"
          >
            <DivisionSelector />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={12}>
          <FormTextInput
            isNotDisplay
            name="description"
            label="Description"
            textarea
            rules={[
              { required: true, message: 'Description is required ' },
            ]}
          />
        </Col>
      </Row>
    </>
  ), []);

  // Props to be passed into the add view
  const editProps = useMemo(() => {
    const ourTemplate = templates.find((template) => template.id === selectedTemplate);
    if (!ourTemplate) {
      // If adding, pass in nothing
      return { };
    }
    // If we're editing a template, use it's details
    const {
      name,
      description,
      divisionId,
      data: {
        phases: selectedPhases = [],
        costcodes: selectedCostcodes = [],
        phaseCostCodes: selectedPhaseCostcodes = [],
      },
    } = ourTemplate;

    const condensedPhases = condenseTemplatePhasesAndCostcodes(
      selectedPhases,
      selectedPhaseCostcodes,
      selectedCostcodes,
    );

    return {
      editing: true,
      name,
      description,
      divisionId,
      projectCostcodes: selectedCostcodes,
      phases: condensedPhases,
      key: selectedTemplate,
    };
  }, [selectedTemplate, templates]);

  const formView = useCallback((form, formProps) => (
    <ProjectAddView
      key={lastOpen}
      editing
      isAdd
      templateView
      {...form}
      {...formProps}
      costcodes={globalCodes}
      settings={settings}
      history={history}
      users={users}
      onValuesChanged={onValuesChanged}
      tabs={templateTabs}
      createNuxEntry={() => {}}
      customSteps={[{
        content: templateDetailsView(),
        title: 'Template Details',
        key: 'costTemplateInfo',
      }]}
      type={null}
    />
  ), [
    globalCodes,
    settings,
    users,
    onValuesChanged,
    history,
    templateTabs,
    templateDetailsView,
    lastOpen,
  ]);

  return (
    <>
      <CompanySettingsCard title="Job Costing">
        <Row style={{ margin: 10 }} align="bottom">
          <Col span={18}>
            <DisplayText
              title="Cost Code Templates"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
            <DisplayText
              title={(
                <div style={{ width: '100%' }}>
                  Configure costcode templates for
                  {' '}
                  {t('Project', { count: 2 })}
                  .
                  <br />
                  <br />
                  Templates configured here can be added to new
                  {' '}
                  {t('Project', { count: 2 }).toLowerCase()}
                  .
                </div>
              )}
              style={{
                fontFamily: 'roboto-regular',
                marginBottom: 0,
                color: Colors.ONTRACCR_OPACITY_GRAY,
              }}
            />
          </Col>
          <Col span={6}>
            <OnTraccrButton
              disabled={templateTabs.length === 0}
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
            />
          </Col>
        </Row>
        <List
          style={{ marginLeft: 15 }}
          dataSource={templates}
          renderItem={renderItem}
        />
      </CompanySettingsCard>

      <AddDrawer
        key={lastOpen}
        onClose={onDrawerClose}
        isAdd
        title={(selectedTemplate && selectedTemplate.name) ? selectedTemplate.name : 'New Template'}
        visible={showDrawer}
        formView={formView}
        formProps={editProps}
        onDelete={onDelete}
        width={1400}
        numberOfSteps={3}
        isFromTemplateAdd
      />
    </>
  );
}

export default JobCosting;
