import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import { getFormattedCurrency } from '../../forms/formHelpers';

/** Displays the invoice details associated with a history entry */
function HistoryEntryDetails({
  invoiceNumber,
  templateName,
  vendor,
  amount,
  style = {},
  offset = 1,
  dailyBilling = 0,
  hourlyBilling = 0,
}) {
  return (
    <Row style={style}>
      {invoiceNumber ? <Col span={24} offset={offset}>{`Inv# - ${invoiceNumber}`}</Col> : null}
      {vendor ? <Col span={24} offset={offset}>{`Vendor - ${vendor}`}</Col> : null}
      {templateName ? <Col span={24} offset={offset}>{`Form: ${templateName}`}</Col> : null}
      {typeof amount === 'number' && amount !== 0 ? <Col span={24} offset={offset}>{`Amount Allocated - ${getFormattedCurrency(amount)}`}</Col> : null}
      {dailyBilling ? <Col span={24} offset={offset}>{`Daily Billing Rate: $${dailyBilling.toFixed(2)}`}</Col> : null}
      {hourlyBilling ? <Col span={24} offset={offset}>{`Hourly Billing Rate: $ ${hourlyBilling.toFixed(2)}`}</Col> : null}
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
HistoryEntryDetails.propTypes = {
  invoiceNumber: PropTypes.string,
  templateName: PropTypes.string,
  vendor: PropTypes.string,
  amount: PropTypes.number,
  style: PropTypes.object,
  offset: PropTypes.number,
  dailyBilling: PropTypes.number,
  hourlyBilling: PropTypes.number,
};

HistoryEntryDetails.defaultProps = {
  invoiceNumber: null,
  templateName: null,
  vendor: null,
  amount: 0,
  style: {},
  offset: 1,
  dailyBilling: 0,
  hourlyBilling: 0,
};

export default HistoryEntryDetails;
