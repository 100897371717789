import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Tabs, Row } from 'antd';
import PropTypes from 'prop-types';

import FormTypes from './FormTypes';
import CustomTables from './CustomTables';
import Exports from '../Exports/Exports';

const tabStyle = { paddingTop: 7 };

const { TabPane } = Tabs;

export default function FormSettings({
  activeKey,
}) {
  const settings = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: companySettings,
        } = {},
      } = {},
    } = state;
    return companySettings;
  });
  const [stateKey, setStateKey] = useState(activeKey);

  useEffect(() => {
    setStateKey(activeKey);
  }, [activeKey]);
  return (
    <Row justify="start">
      <Tabs
        defaultActiveKey={activeKey}
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 135,
          marginRight: 17,
        }}
        style={{ width: '100%' }}
        activeKey={stateKey}
        onChange={setStateKey}
      >
        <TabPane style={{ ... tabStyle, maxHeight: '90vh' }} tab="Form Types" key="numbering">
          <FormTypes settings={settings} />
        </TabPane>
        <TabPane style={tabStyle} tab="Custom Tables" key="customTables">
          <CustomTables />
        </TabPane>
        <TabPane style={tabStyle} tab="Exports" key="exports">
          <Exports visible={stateKey === 'exports'} type="forms" />
        </TabPane>
      </Tabs>
    </Row>
  );
}

FormSettings.propTypes = {
  activeKey: PropTypes.string,
};

FormSettings.defaultProps = {
  activeKey: 'numbering',
};
