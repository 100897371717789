import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  Table,
  Select,
  Row,
  Col,
  Spin,
} from 'antd';

import AutoImport from './IntegrationAutoImport';
import CanImportCol from './IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from './IntegrationIsConnectedCol';
import { createImportData } from './integrationHelpers';

import sortByString, { includesTerm } from '../../helpers/helpers';
import Debouncer from '../../helpers/Debouncer';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';


const { Panel } = Collapse;
const { Option } = Select;

const debouncer = new Debouncer();

//eslint-disable-next-line no-useless-escape
const emailRegex = new RegExp(/^[^@\s]+@[^@\s\.]+\.[^@\.\s]+$/);

const PositionSelect = (positions,userMap) => (_,record) =>  {
  const options = Object.keys(positions);
  const {
    linkValue,
  } = record;
  const {
    [linkValue]:linkedUser = {}
  } = userMap;
  const {
    position:defaultPosition = 'Worker',
  } = linkedUser;
  return (
    <Select
      style={{ width: '100%' }}
      onChange={(position) => record.position = position}
      defaultValue={defaultPosition}
    >
      {options.map((option) => <Option key={option} value={option}>{option}</Option>)}
    </Select>
  );
};

export default ({
  integrationUsers,
  integrationKey,
  users = [],
  positions = {},
  onUsersChanged,
  title,
  autoImportContent,
  importHelpText,
  importUsers,
  canAutoImport = true,
  loading,
  divisionId,
  divisionFilter,
}) => {
  
  const userDivisions = useSelector(state => state.users.userDivisions);
  const divisions = useSelector(state => state.settings.divisions);
  const divisionList = useMemo(() => (
    Object.values(divisions)
      .filter((division) => !divisionFilter || divisionFilter.has(division.id))
  ), [divisions, divisionFilter]);

  const linkableUsers = useMemo(() => (
    users.filter((user) => {
      if (!user.active) return false;
      if (!divisionFilter) return true;
      const {
        [user.id]: usersDivs = [],
      } = userDivisions;
      return usersDivs.some((divisionId) => divisionFilter.has(divisionId));
    })
  ), [users, divisionFilter, userDivisions]);

  const [activePanels,setActivePanels] = useState(canAutoImport ? [] : ['new']);
  const [stateUsers,setStateUsers] = useState([]);
  const [autoImport,setAutoImport] = useState(canAutoImport);
  const [searchStr,setSearchStr] = useState();
  const [importList,setImportList] = useState(new Set());
  const [connected,setConnected] = useState({});
  const [userMap,setUserMap] = useState({});
  const [employeeIdSet,setEmployeeIdSet] = useState({});
  const [usernameSet,setUsernameSet] = useState({});
  const [emailSet,setEmailSet] = useState({});

  const canImport = (record) => (
    record.name 
    && (!record.email || (
      emailRegex.test(record.email) &&  !(record.email in emailSet)
    ))
    && !(record.username in usernameSet)
    && !(record.usernameConflict)
    && (!record.employeeId || !(record.employeeId in employeeIdSet))
    && (divisionId || record.linkValue || (record.divisions && record.divisions.length > 0) || divisionList.length === 1)
  );

  const updateUsers = (record) => {
    const newData = stateUsers.map((user) => {
      if(user[integrationKey] !== record[integrationKey]) return user;
      return record;
    });
    setStateUsers(newData);
  };
  const resetImportList = (list) => () => {
    const il = new Set(list.map((user) => user[integrationKey]));
    setImportList(il);
  }
 

  useEffect(() => {
    setConnected(createConnectedArray(linkableUsers,integrationKey));
    const newEmployeeIdSet = {};
    const newUsernameSet = {};
    const newEmailSet = {};
    const newUserMap = {};
    users.forEach((user) => {
      const { employeeId, username, id, email } = user;
      if(employeeId) newEmployeeIdSet[employeeId] = user;
      if(username) newUsernameSet[username] = user;
      if(email) newEmailSet[email] = user;
      newUserMap[id] = user;
    });
    setEmployeeIdSet(newEmployeeIdSet);
    setUsernameSet(newUsernameSet);
    setEmailSet(newEmailSet);
    setUserMap(newUserMap);
  }, [ linkableUsers ]);

  useEffect(() => {
    if(integrationUsers?.length) {
      const setInitialStateUsers = async () => {
        const newUsernames = [];
        const intUsers = [];
        integrationUsers.forEach((user) => {
          newUsernames.push(user.username || user.email);
          intUsers.push({
            ...user,
            position: user[integrationKey] in connected ? connected[user[integrationKey]].position : 'Worker',
            username:user.username || user.email,
            key:user[integrationKey],
            wage: user.wage,
            viewType: user[integrationKey] in connected ? 'link' : 'import',
            linkValue: user[integrationKey] in connected ? connected[user[integrationKey]].id : null,
          });
        });
        const existingUsernames = [];
        // const existingUsernames = await debouncer.checkUsernames(newUsernames);

        const existingMap = existingUsernames
          ? new Set(existingUsernames.map(({ username }) => username ? username.toLowerCase() : username))
          : new Set();
        intUsers.forEach((user) => {
           const {
            email,
            username,
          } = user;
          const lowerUser = (username || email || '').toLowerCase();
          user.usernameConflict = existingMap.has(lowerUser);
        });
        // Need to modify the initial import users to prevent auto import from attempting to import
        // users with conflicting usernames
        integrationUsers.forEach((user) => {
          const {
            email,
            username,
          } = user;
          const lowerUser = (username || email || '').toLowerCase();
          user.usernameConflict = existingMap.has(lowerUser); 
        })
        setStateUsers(intUsers);
        resetImportList(intUsers)();
      };

      setInitialStateUsers();
    }
  }, [ integrationUsers, connected ]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const importData = createImportData({
      autoImport,
      newData:integrationUsers ?? [],
      stateData:stateUsers,
      originalData: linkableUsers,
      importList,
      canImport, 
      connected,
      integrationKey,
      divisionList,
      isUser: true,
    });
    onUsersChanged(importData); //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ 
    autoImport,
    stateUsers,
    importList,
    onUsersChanged,
    integrationUsers,
    linkableUsers,
    connected,
    divisionList,
  ]); 

  const renderWage = (editable) => (_,record) => {
    if(!editable) return `$${record.wage}`;
    return (
      <OnTraccrNumberInput
        formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
        parser={value => value.replace(/\$\s?|(,*)/g, '')}
        step={0.01}
        precision={2}
        min={0.01}
        defaultValue={record.wage}
        onChange={(value) => {
          record.wage = value;
          updateUsers(record);
        }}
      />
    );
  };

  const renderFunc = (key,editable, props = {}, checkSet = {}) => (_,record) => {
    if(editable) {
      const isUsername = key === 'username';
      const hasConflict = (
        checkSet[record[key]] &&
        checkSet[record[key]].id !== record.linkValue
      ) || (
        isUsername && record.usernameConflict
      );
      return  (
        <OnTraccrTextInput
          allowClear
          defaultValue={record[key]}
          key={`${record.name}.${key}`}
          onClick={() => 1}
          onChange={async (e) => {
            const {
              target:{
                value,
              } = {}
            } = e;
            record[key] = value;
            if(isUsername) {
              const existingUsers = await debouncer.checkUsernames([value]);
              record.usernameConflict = existingUsers && existingUsers.length > 0;
            }
            updateUsers(record);
          }}
          style={
            hasConflict
            ? {
                borderColor:'red',
                textColor:'red'
              }
            : {}}
          {...props}
        />
      );
    }
    return record[key];
  };
  
  const getCols = (editable = false,) =>  {
    const cols = [
      IsConnectedCol({
        title,
        integrationKey,
        connected,
        onUnLink:(record) => {
          const newConnected = {...connected};
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if(editable) {
            record.linkValue = null;
            updateUsers(record);
          } else {
            setStateUsers(stateUsers.map((user) => {
              if(user[integrationKey] !== record[integrationKey]) return user;
              const newUser = {...user};
              delete newUser.linkValue;
              return newUser;
            }));
          }
        }
      }),
      {
        title:'Name',
        dataIndex:'name',
        key:'name',
        align:'center',
        sorter:sortByString('name'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        fixed:'left',
      },
      {
        title:'Division',
        dataIndex:'division',
        key:'division',
        align:'center',
        fixed:'left',
        width: 170,
        render: (_,record) => {
          const {
            [integrationKey]: integrationId,
            linkValue: recordLink,
            viewType,
            divisions: recordDivs = [],
          } = record;
          const isLink = editable && viewType === 'link';
          const otID = isLink ? recordLink : integrationId;
          const {
            [otID]: usersDivs = [],
          } = userDivisions;
          const editableDivs = isLink ? usersDivs : recordDivs;
          let finalDivs = editable ? editableDivs : usersDivs;
          if (divisionList.length === 1) finalDivs = divisionList.map((d) => d.id);
          if (divisionId) finalDivs = [divisionId];
          return (
            <DivisionSelector
              key={integrationId}
              mode='multiple'
              displayMode={!editable}
              style={{ width: '100%' }}
              displayStyle={{ marginBottom: 0 }}
              onChange={(newDivisions) => {
                record.divisions = newDivisions;
                updateUsers(record);
              }}
              disabled={divisionId || isLink}
              divisions={finalDivs}
              filter={divisionFilter}
            />
          );
        },
      },
      {
        title:'Employee ID',
        dataIndex:'employeeId',
        key:'employeeId',
        align:'center',
        sorter:sortByString('employeeId'),
        showSorterTooltip:false,
        render:renderFunc('employeeId',editable,null,employeeIdSet),
      },
      {
        title:'Username',
        dataIndex:'username',
        key:'username',
        align:'center',
        sorter:sortByString('username'),
        showSorterTooltip:false,
        render:renderFunc('username',editable,null,usernameSet),
      },
      {
        title:'Email',
        dataIndex:'email',
        key:'email',
        align:'center',
        sorter:sortByString('email'),
        showSorterTooltip:false,
        render:renderFunc('email',editable, { type: 'email'}, emailSet),
      },
      {
        title:'Position',
        dataIndex:'position',
        key:'position',
        align:'center',
        sorter:sortByString('position'),
        showSorterTooltip:false,
        render: (_,record) => editable ? PositionSelect(positions,userMap)(_,record) : record.position,
      },
      {
        title:'Phone Number',
        dataIndex:'phoneNumber',
        key:'phoneNumber',
        align:'center',
        render:renderFunc('phoneNumber',editable),
      },
      {
        title:'Wage',
        dataIndex:'wage',
        key:'wage',
        align:'center',
        render:renderWage(editable),
      },
    ];
    if(editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          connected,
          helpText:importHelpText,
          canImport,
          importList,
          setImportList,
          resetImportList: resetImportList(stateUsers),
          onViewTypeChanged:(record,viewType) => {
            record.viewType = viewType;
            updateUsers(record);
          },
          linkOptions: linkableUsers,
          onLinkChanged:(record,linkValue) => {
            const newConnected = {...connected};
            const oldLink = connected[record[integrationKey]];
            if(oldLink) delete oldLink[integrationKey];
            if(linkValue) {
              newConnected[record[integrationKey]] = userMap[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
            } else {
              delete newConnected[record[integrationKey]];
            }

            setConnected(newConnected);

            record.linkValue = linkValue;
            updateUsers(record);
          },
        })
      );
    }
    return cols;
  }

  const activeUsers = useMemo(() => (
    linkableUsers.filter((user) => !searchStr || includesTerm(user.name, searchStr))
  ),[linkableUsers,searchStr]);
  const activeStateUsers = useMemo(() => (
    stateUsers.filter((user) => !searchStr || includesTerm(user.name, searchStr))
  ),[stateUsers,searchStr]);

  if (loading) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }

  return (
    <div style={{ height: 'calc(100% - 55px)', width:'100%', overflowY:'scroll'}}>
      {canAutoImport && <AutoImport
        setActivePanels={setActivePanels}
        setAutoImport={setAutoImport}
        autoImport={autoImport}
        helpContent={autoImportContent}
        importType='User'
        importData={importUsers}
      />}

      {!autoImport && <OnTraccrTextInput 
        allowClear
        style={{
          width: 250,
        }}
        search
        onChange={(e) => {
          const {
            target:{
              value
            } = {}
          } = e;
          setSearchStr(value);
        }}
        onClick={(e) => e.stopPropagation()}
      />}
      {!autoImport && <Collapse activeKey={activePanels} ghost onChange={setActivePanels}>
        <Panel 
          header={`New ${title} Users`}
          key='new' 
          className='ontraccr-collapse-panel'
        >
          <Table 
            bordered
            size='small'
            columns={getCols(true)}
            dataSource={activeStateUsers}
            scroll={{
              y: '40%',
              x:1500,
            }}
            pagination={{
              hideOnSinglePage:true,
              pageSize:20,
              showSizeChanger:false,
            }}
          />
        </Panel>
        <Panel 
          header='Ontraccr Users'
          key='ontraccr'
          className='ontraccr-collapse-panel'
        >
          <Table 
            bordered
            size='small'
            columns={getCols(false)}
            dataSource={activeUsers}
            scroll={{
              y: '40%',
            }}
            pagination={{
              hideOnSinglePage:true,
              pageSize:20,
              showSizeChanger:false,
            }}
          />
        </Panel>
      </Collapse>}
    </div>
  );
}