import React, { useEffect, useRef, useState } from 'react';
import WebViewer from '@pdftron/webviewer';

import { getNewFileName } from './pdfHelpers';

import Analytics from '../../helpers/Analytics';
import config from '../../config';

export default function PDF({
  url,
  file,
  onSave,
  style = {},
  className,
  annotatedFileName,
  onClose,
}) {
  const viewer = useRef(null);
  const [webviewerInstance, setWebviewerInstance] = useState(null);

  useEffect(() => {
    WebViewer(
      {
        path: `${process.env.PUBLIC_URL}/webviewer/lib`,
        licenseKey: config.apryseKey,
      },
      viewer.current,
    ).then((instance) => {
      setWebviewerInstance(instance);

      instance.UI.disableElements([
        'toolbarGroup-Shapes',
        'toolbarGroup-Edit',
        'toolbarGroup-Insert',
        'toolbarGroup-FillAndSign',
        'toolbarGroup-Forms',
        'leftPanelButton',
        'settingsButton',
      ]);

      instance.Core.disableEmbeddedJavaScript();
    });
  }, []);

  useEffect(() => {
    if (!webviewerInstance) return;
    Analytics.track('PDF/View');
    webviewerInstance.Core.documentViewer.loadDocument(url || URL.createObjectURL(file));
  }, [webviewerInstance, file, url]);

  useEffect(() => {
    if (!webviewerInstance || !onClose) return;

    webviewerInstance.UI.setHeaderItems((header) => {
      const defaultHeader = header.getHeader('default');
      const hasCloseButton = defaultHeader.getItems().findIndex((item) => (
        item.dataElement === 'OntraccrCloseButton'
      ));

      if (hasCloseButton === -1) {
        defaultHeader.push({
          type: 'actionButton',
          dataElement: 'OntraccrCloseButton',
          onClick: onClose,
          img: 'ic_close_black_24px',
          title: 'Close',
        });
      }
    });
  }, [webviewerInstance, onClose]);

  useEffect(() => {
    if (!webviewerInstance) return;

    if (!onSave) {
      webviewerInstance.Core.annotationManager.enableReadOnlyMode();
      return;
    }

    webviewerInstance.UI.updateElement('saveAsButton', {
      onClick: async () => {
        const doc = webviewerInstance.Core.documentViewer.getDocument();
        const xfdfString = await webviewerInstance.Core.annotationManager.exportAnnotations();
        const data = await doc.getFileData({ xfdfString });
        const arr = new Uint8Array(data);
        const newName = getNewFileName(file, annotatedFileName);
        const annotatedPDF = new File([arr], newName, { type: 'application/pdf' });
        onSave(file, annotatedPDF);
      },
      label: 'Save',
    });
  }, [webviewerInstance, onSave, file, annotatedFileName]);

  return (
    <div className={className ? `${className}-pdf-container` : 'pdf-container'} style={style}>
      <div className="webviewer" ref={viewer} style={{ width: '100%', height: '100%' }} />
    </div>
  );
}
