import React, { useCallback, useState, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { Divider, Form, Input, message, Select } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { toTitleCase } from '../../helpers/helpers';
import DisplayText from '../../common/text/DisplayText';

/** Invoice Status Selector */
const InvoiceStatusSelector = ({
  visible,
  formRef,
  isNotDisplay,
  status = '',
  formStyle = {},
  formLabelStyle = {},
}) => {
  const statuses = useSelector((state) => state.invoices.statuses);

  const [statusInput, setStatusInput] = useState('');
  const [customList, setCustomList] = useState([]);

  const statusLabelNames = useMemo(() => statuses
    .map(({ title }) => title.toLowerCase()), [statuses]);

  const fullList = useMemo(() => {
    const invoiceStatusOptions = statuses
      .map(({ title, id }) => ({
        key: id,
        value: id,
        label: title,
      }));
    return invoiceStatusOptions.concat(customList);
  }, [statuses, customList]);
  
  const onAddNewStatus = useCallback(() => {
    if (statusLabelNames.includes(statusInput.toLowerCase())
      || customList.includes(statusInput.toLowerCase())
      || !formRef || !formRef.current) {
        message.error('Invalid status');
        return;
      }
    const tempId = DateTime.local().toMillis();
    const refinedStatusName = toTitleCase(statusInput);
    const newStatus = { id: tempId, value: tempId, label: refinedStatusName };
    // Add to new status to form:
    const formValues = formRef.current.getFieldsValue();
    formRef.current.setFieldsValue({
      ...formValues,
      statusId: tempId,
      status: newStatus,
    });
    // Add to custom list:
    setCustomList((prvValues) => [...prvValues, newStatus]);
    // Clear Input:
    setStatusInput('');
  }, [formRef, statusInput, statusLabelNames, customList]);

  const onStatusInputChange = useCallback((e) => {
    if (e.target.value && typeof e.target.value !== 'string') return;
    setStatusInput(e.target.value);
  }, []);

  useEffect(() => {
    if (!visible) {
      setStatusInput('');
      setCustomList([]);
    }
  }, [visible]);

  const dropdownRenderHandler = useCallback((menu) => (
    <div style={{ width: '100%', padding: 5}}>
      {menu}
      <Divider style={{ margin: '4px 0px 8px 0px' }} />
      Custom:
      <Input
        value={statusInput}
        onChange={onStatusInputChange}
        style={{
          borderRadius: '6px',
          marginBottom: '6px',
        }}
      />
      <OnTraccrButton
        icon={ <PlusOutlined />}
        onClick={onAddNewStatus}
        title='Add'
        disabled={!statusInput}
      />
    </div>
  ), [statusInput]);

  const onSelect = useCallback((statusId) => {
    if (!formRef || !formRef.current) return;
    const values = formRef.current.getFieldsValue();
    formRef.current.setFieldsValue({
      ...values,
      statusId,
      status: fullList.find(({ id: optionId }) => statusId === optionId),
    });
  }, [formRef, fullList]);

  return (
    <Form.Item
      name='statusId'
      label='Status'
      style={{ marginBottom: 0, ...formStyle }}
      labelCol={formLabelStyle}
      rules={[{ required: true, message: 'Status is required' }]}
    >
      {isNotDisplay ?
      <Select
        placeholder='Select Status'
        showSearch
        allowClear
        optionFilterProp='label'
        onSelect={onSelect}
        dropdownRender={dropdownRenderHandler}
        options={fullList}
      />
      : <DisplayText title={status}/>}
    </Form.Item>
  );
};

InvoiceStatusSelector.propTypes = {
  visible: PropTypes.bool,
  formRef: PropTypes.object,
  isNotDisplay: PropTypes.bool,
  status: PropTypes.string,
  formStyle: PropTypes.object,
  formLabelStyle: PropTypes.object,
};

export default InvoiceStatusSelector;
