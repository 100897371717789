import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { PlusOutlined } from '@ant-design/icons';

import BorderlessButton from '../common/buttons/BorderlessButton';
import SearchFieldRow from './SearchFieldRow';

export default function SearchField({
  selectedType,
  configProps = {},
  queries = [],
  onQueriesChange,
  multi,
  buttonStyle,
  boardIds,
}) {
  const { title } = configProps;
  const onQueryChange = useCallback((idx) => (newQuery) => {
    const newQueries = [...queries];
    newQueries[idx] = newQuery;
    onQueriesChange(newQueries);
  }, [queries, onQueriesChange]);

  const onAddQuery = useCallback(() => {
    const newQueries = [...queries, {}];
    onQueriesChange(newQueries);
  }, [queries, onQueriesChange]);

  const onDeleteQuery = useCallback((idx) => () => {
    const newQueries = [...queries];
    newQueries.splice(idx, 1);
    onQueriesChange(newQueries);
  }, [queries, onQueriesChange]);

  return (
    <Row>
      <Col>
        <Row justify="start" align="middle">
          <Col
            style={{
              fontFamily: 'roboto-medium',
              fontSize: 13,
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              maxWidth: '80%',
              overflow: 'hidden',
            }}
          >
            {title}
          </Col>
          {(queries?.length === 0 || multi) && (
            <Col>
              <BorderlessButton
                iconNode={<PlusOutlined />}
                onClick={onAddQuery}
                title="Add Query"
                style={buttonStyle}
              />
            </Col>
          )}
        </Row>
        {queries.map((query, idx) => (
          <SearchFieldRow
            // eslint-disable-next-line react/no-array-index-key
            key={idx}
            type={selectedType}
            configProps={configProps}
            query={query}
            onQueryChange={onQueryChange(idx)}
            onDelete={onDeleteQuery(idx)}
            boardIds={boardIds}
          />
        ))}
      </Col>
    </Row>
  );
}

SearchField.propTypes = {
  configProps: PropTypes.shape({}),
  multi: PropTypes.bool,
  onQueriesChange: PropTypes.func.isRequired,
  queries: PropTypes.array.isRequired,
  selectedType: PropTypes.string,
  buttonStyle: PropTypes.shape({}),
  boardIds: PropTypes.array,
};

SearchField.defaultProps = {
  configProps: undefined,
  multi: false,
  selectedType: PropTypes.string,
  buttonStyle: {},
  boardIds: [],
};
