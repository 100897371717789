import analytics from '../../../helpers/Analytics';
import weatherService from './weather.service';

// eslint-disable-next-line import/prefer-default-export
export const getWeather = async (query) => {
  analytics.track('Weather/Get');
  const { data } = await weatherService.getWeather(query);
  if (!data) return false;
  return data;
};
