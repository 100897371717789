import React from 'react';
import { Drawer } from 'antd';

import ActiveInactiveUserLists from '../common/containers/ActiveInactiveUserLists';

export default ({
  onClose,
  visible,
  users = [],
  onUserClick,
}) => (
  <Drawer
    title='Quick User Stats'
    width={800}
    onClose={onClose}
    visible={visible}
    maskClosable={false}
  >
      <ActiveInactiveUserLists
        name='quickState'
        id='quickState'
        users={users}
        onUserClick={onUserClick}
        style={{
          height:'80%'
        }}
      />
  </Drawer>
);
