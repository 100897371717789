import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select, Descriptions } from 'antd';

function BoardCardSelectSheet({
  workbook: {
    SheetNames = [],
  } = {},
  onSheetChanged,
  selectedBoard: {
    statuses = [],
  },
  onStatusChanged,
  statusId,
}) {
  const sheetOptions = useMemo(() => (
    SheetNames.map((sheet) => ({ value: sheet, label: sheet }))
  ), [SheetNames]);

  const statusOptions = useMemo(() => (
    statuses
      .filter((status) => !!status)
      .map((status = {}) => ({ value: status?.id, label: status?.title }))
  ), [statuses]);

  return (
    <Descriptions title="Select Sheet">
      <Descriptions.Item label="Sheet">
        <Select
          style={{ width: 200 }}
          defaultValue={SheetNames[0]}
          onSelect={onSheetChanged}
          options={sheetOptions}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Status">
        <Select
          style={{ width: 200 }}
          defaultValue={statusId}
          onSelect={onStatusChanged}
          options={statusOptions}
        />
      </Descriptions.Item>
    </Descriptions>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardCardSelectSheet.propTypes = {
  workbook: PropTypes.object.isRequired,
  onSheetChanged: PropTypes.func.isRequired,
  selectedBoard: PropTypes.object.isRequired,
  onStatusChanged: PropTypes.func.isRequired,
  statusId: PropTypes.number,
};

BoardCardSelectSheet.defaultProps = {
  statusId: null,
};

export default BoardCardSelectSheet;
