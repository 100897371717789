import {
  GET_SALES_RABBIT_WORKFLOWS,
  CREATE_SALES_RABBIT_WORKFLOW,
  UPDATE_SALES_RABBIT_WORKFLOWS,
  DELETE_SALES_RABBIT_WORKFLOWS,
  GET_SALES_RABBIT_REGIONS,
  CREATE_SALES_RABBIT_REGION,
  UPDATE_SALES_RABBIT_REGION,
  DELETE_SALES_RABBIT_REGION,
} from '../../../../state/actionTypes';

import { getIdMap } from '../../../../helpers/helpers';

const initialState = {
  workflows: {},
  regions: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SALES_RABBIT_WORKFLOWS: {
      const {
        payload: {
          workflows = [],
        } = {},
      } = action;
      return {
        ...state,
        workflows: getIdMap(workflows),
      };
    }
    case CREATE_SALES_RABBIT_WORKFLOW: {
      const {
        payload: {
          workflow = {},
        } = {},
      } = action;
      const newWorklows = { ...state.workflows };
      newWorklows[workflow.id] = workflow;
      return {
        ...state,
        workflows: newWorklows,
      };
    }
    case UPDATE_SALES_RABBIT_WORKFLOWS: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const newWorklows = { ...state.workflows };
      const {
        [id]: exisistingData = {},
      } = newWorklows;
      newWorklows[id] = {
        ...exisistingData,
        ...newData,
      };

      return {
        ...state,
        workflows: newWorklows,
      };
    }
    case DELETE_SALES_RABBIT_WORKFLOWS: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const newWorklows = { ...state.workflows };
      delete newWorklows[id];
      return {
        ...state,
        workflows: newWorklows,
      };
    }
    case GET_SALES_RABBIT_REGIONS: {
      const {
        payload: {
          regions = [],
        } = {},
      } = action;
      return {
        ...state,
        regions: getIdMap(regions),
      };
    }
    case CREATE_SALES_RABBIT_REGION: {
      const {
        payload: {
          region = {},
        } = {},
      } = action;
      const newRegions = { ...state.regions };
      newRegions[region.id] = region;
      return {
        ...state,
        regions: newRegions,
      };
    }
    case UPDATE_SALES_RABBIT_REGION: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const newRegions = { ...state.regions };
      const {
        [id]: exisistingData = {},
      } = newRegions;
      newRegions[id] = {
        ...exisistingData,
        ...newData,
      };

      if (newData.isDefault) {
        Object.keys(newRegions).forEach((regionId) => {
          if (regionId === id) return;
          newRegions[regionId] = {
            ...newRegions[regionId],
            isDefault: false,
          };
        });
      }

      return {
        ...state,
        regions: newRegions,
      };
    }
    case DELETE_SALES_RABBIT_REGION: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const newRegions = { ...state.regions };
      delete newRegions[id];
      return {
        ...state,
        regions: newRegions,
      };
    }
    default:
      return state;
  }
};
