import React, { useCallback } from 'react';
import { Checkbox, Row, Col } from 'antd';
import { PropTypes } from 'prop-types';

import HoverHelp from '../../common/HoverHelp';

export default function FormCheckbox({
  isDisplay,
  onCheckChange: onPropCheckChange,
  value: checked,
  title,
  tooltip,
}) {
  const onCheckChange = useCallback((e) => {
    const {
      target: {
        checked: newChecked,
      } = {},
    } = e;
    onPropCheckChange(newChecked);
  }, []);
  if (isDisplay && !checked) return null;
  return (
    <Row className="form-default-data-row" justify="space-between">
      <Col>
        <Row gutter={10}>
          {!isDisplay && (
          <Col>
            <Checkbox
              onChange={onCheckChange}
              checked={checked}
            />
          </Col>
          )}
          <Col>
            {title}
          </Col>
          <Col>
            {!isDisplay && tooltip && (
            <HoverHelp
              placement="right"
              title={title}
              content={tooltip}
            />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

FormCheckbox.propTypes = {
  isDisplay: PropTypes.bool,
  onCheckChange: PropTypes.func.isRequired,
  value: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.node,
};

FormCheckbox.defaultProps = {
  isDisplay: false,
  tooltip: null,
};
