import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import ClientPortalHeader from './ClientPortalHeader';
import ClientPortalProfile from './ClientPortalProfile';
import ClientPortalHome from './ClientPortalHome';
import ClientPortalComms from './ClientPortalComms';
import ClientPortalFooter from './ClientPortalFooter';

import clientPortalAxiosInstance from './clientPortal.axios';

import {
  removeClientTokenFromStorage,
  getClientPortalData,
} from './state/clientPortal.actions';

export default ({
  style = {},
  previewMode,
}) => {
  const dispatch = useDispatch();

  const [activeTab, setActiveTab] = useState('home');

  const body = useMemo(() => {
    if (activeTab === 'comms') return <ClientPortalComms />
    if (activeTab === 'profile') return <ClientPortalProfile previewMode={previewMode}/>
    return <ClientPortalHome previewMode={previewMode}/>;
  },[activeTab, previewMode]);

  useEffect(() => {
    const reqInterceptor = clientPortalAxiosInstance.interceptors.request.use((conf) => {
      const token = window.sessionStorage.getItem('clientToken');
      if (!token) return conf;
      return {
        ...conf,
        headers: {
          ...(conf.headers || {}),
          Authorization: `Bearer  ${token}`,
        },
      };
    }, (error) => Promise.reject(error));

    const resInterceptor = clientPortalAxiosInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(removeClientTokenFromStorage())
          return Promise.resolve();
        }
        return Promise.reject(error);
      },
    );

    return () => {
      clientPortalAxiosInstance.interceptors.request.eject(reqInterceptor);
      clientPortalAxiosInstance.interceptors.response.eject(resInterceptor);
    }
  },[]);

  useEffect(() => {
    if(!previewMode) dispatch(getClientPortalData());
  },[dispatch, previewMode]);

  return (
    <div id='client-portal-main-container' style={style}>
      <ClientPortalHeader onTabChanged={setActiveTab} activeTab={activeTab} previewMode={previewMode}/>
      <div id='client-portal-main-body'>
        {body}
      </div>
      <ClientPortalFooter/>
    </div>

  )
}