import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import manageFilesPerms from '../../assets/images/NUX/manageFilesPerms.png';
import fileFolderPerms from '../../assets/images/NUX/fileFolderPerms.png';


const permsDesc = (
<div style={{ fontSize: 13 }}>
In order for any user to be able to view or manage files,
they must first have the 'View Files' and/or 'Manage Files' permissions enabled for their role type.
'View Files' lets them view the Files page on the web and mobile app,
while 'Manage Files' lets them add, edit, or delete folders and files.
</div>
);

const fileFolderPermsDesc = `
For any folder or file, you can also set detailed file permissions
where you can either make the selected folder/file public 
(available for everyone to see) or select only the individual users who should have access.
`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 74px)',
      padding:'0px 40px'
    }}
    rows={[{
      title:'Role Permissions',
      description:permsDesc,
      cols:[0,16,8],
      image:{
        src:manageFilesPerms,
        style:{
          height:50,
          width:198,
        }
      },
    },{
      title:'File/Folder Permissions',
      description:fileFolderPermsDesc,
      cols:[0,16,8],
      image:{
        src:fileFolderPerms,
        style:{
          height:120,
          width:166,
        }
      },
    }]}
  />
);
