import { DATATYPES } from 'ontraccr-common/lib/Definitions';

export const ATTRIBUTE_LINK_MAP = {
  Customer: 'Customers',
  Project: 'Projects',
  'Project Contract': 'Projects',
  Vendor: 'Vendors',
  Contact: 'Contacts',
  Costcodes: 'Costcodes',
  User: 'Users',
  'Sub-Contract': 'Sub-Contracts',
  Buckets: 'Buckets',
  PayableSubContracts: 'PayableSubContracts',
};

export const getAttributeOptions = (t) => [
  {
    name: `${t('Customer')}`,
    value: DATATYPES.Customer,
  },
  {
    name: 'Contact',
    value: DATATYPES.Contact,
  },
  {
    name: `${t('Project')}`,
    value: DATATYPES.Project,
  },
  {
    name: `${t('Project')} Contract`,
    value: DATATYPES.ProjectContract,
  },
  {
    name: 'Vendor',
    value: DATATYPES.Vendor,
  },
  {
    name: 'Costcodes',
    value: DATATYPES.Costcodes,
  },
  {
    name: 'User',
    value: DATATYPES.User,
  },
  {
    name: 'SoV Sub-Contract',
    value: DATATYPES.SubContract,
  },
  {
    name: 'Buckets',
    value: DATATYPES.Buckets,
    hasSubDataType: true,
  },
  {
    name: 'Sub-Contract',
    value: DATATYPES.PayableSubContracts,
  },
];

export const getLinkOptions = ({
  dataType,
  sections,
  subDataType,
  hasSubDataType,
}) => (
  dataType
    ? (
      sections.reduce((acc, section) => {
        const { fields = [], name: sectionName } = section;
        return acc.concat(
          fields.reduce((fieldAcc, field) => {
            const {
              id: fieldId,
              selectedType,
              configProps: {
                dataType: fieldDataType,
                title: fieldTitle,
                subDataType: fieldSubDataType,
              } = {},
            } = field;
            if (selectedType !== 'dropdown') return fieldAcc;
            if (
              fieldDataType !== ATTRIBUTE_LINK_MAP[dataType]
              || (hasSubDataType && fieldSubDataType !== subDataType)
            ) return fieldAcc;
            return fieldAcc.concat([{
              label: `${sectionName} - ${fieldTitle}`,
              value: fieldId,
            }]);
          }, []),
        );
      }, [])
    )
    : []
);
