import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form } from 'antd';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../common/inputs/FormTextInput'

import {
  compareToFirstPassword,
  validatePasswordFormat,
} from '../../helpers/validators';

import { generateRandomString } from '../../helpers/helpers';

import {
  createClientPortalAccount,
  updateClientPortalAccount,
} from '../../clientPortal/state/clientPortal.actions';

export default ({
  customerId,
  visible,
  onClose,
  selectedAccount,
}) => {
  const { email: initialEmail, id } = selectedAccount ?? {};
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const portalAccounts = useSelector(state => state.clientPortal.accounts);
  const customers = useSelector(state => state.customers.customers);

  const confirmValidator = useMemo(() => compareToFirstPassword(form),[form]);
  const passValidator = useMemo(() => validatePasswordFormat(form),[form]);
  
  const emailExists = useCallback((_, value) => {
    const exists = portalAccounts.find((acc) => acc.email === value);
    if (exists) {
      const { customerId: accountCustomerId } = exists;
      const {
        [accountCustomerId]: { name: customerName } = {},
      } = customers;
      return Promise.reject(
        new Error(`${value} already has a client portal account${customerName ? ` with ${customerName}` : ''}`)
      );
    }
    return Promise.resolve();
  },[portalAccounts, customers])

  const onSubmitClicked = useCallback(async () => {
    try {
      const {
        email,
        password,
      } = await form.validateFields();
      if (id) {
        // edit
        if (email === initialEmail && !password) {
          // Nothing changed
          return onClose();
        }
        const update = {};
        if (email !== initialEmail) update.email = email;
        if (password) update.password = password;
        if (await dispatch(updateClientPortalAccount(id, { email, password }))) {
          onClose();
        }
      } else {
        if(await dispatch(createClientPortalAccount({ customerId, email, password }))) {
          onClose();
        }
      }
    } catch (err) {
      //
    }
  },[dispatch, form, customerId, initialEmail, id]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else if (selectedAccount) {
      const formVals = { email: selectedAccount.email };
      if (selectedAccount.generatePassword) {
        const genPass = generateRandomString(true);
        formVals.password = genPass;
        formVals.confirmPassword = genPass;
      }
      form.setFieldsValue(formVals);
    }
  },[visible, form, selectedAccount]);

  return (
    <Drawer
      title='Add Client Portal Account'
      width={500}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ padding: '0px 24px' }}
    >
      <Form form={form} layout='vertical' initialValues={{ email: initialEmail }}>
        <FormTextInput
          isNotDisplay
          label='Email'
          name='email'
          rules={[
            { required: true, message: 'Email is required' },
            { type:'email', message: 'Invalid email format', transform: (val) => val ? val.trim() : val },
            { validator: emailExists }
          ]}
        />
        <FormTextInput
          isNotDisplay
          label='Password'
          name='password'
          rules={[
            { required: !selectedAccount, message: 'Please enter password' },
            { validator: passValidator }
          ]}
          password
        />
        <FormTextInput
          isNotDisplay
          label='Confirm Password'
          name='confirmPassword'
          rules={[
            { required: !selectedAccount, message: 'Please confirm password' },
            { validator: confirmValidator }
          ]}
          password
        />
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
      />
    </Drawer>
  )
}