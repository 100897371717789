import {
  SET_ANALYTICS_CONFIG,
  GET_ANALYTICS_DATA,
  CREATE_ANALYTICS_REPORT,
  UPDATE_ANALYTICS_REPORT,
  GET_SAVED_ANALYTICS_REPORTS,
  SELECT_SAVED_REPORT,
  DELETE_ANALYTICS_REPORT,
  UPDATE_ANALYTICS_FILTERS,
} from '../../state/actionTypes';

import {
  dayDiffsToDateRange,
  filterData,
} from '../analytics.helpers';

const initialState = {
  chartType: null,
  aggregate: 'sum',
  fullData: [], // All data, not including filters
  data: [], // Data to display after filtering
  savedReports: {},
  statuses: [],
  filters: {},
  groups: {},
  cardTitles: [],
  boardStatuses: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_ANALYTICS_CONFIG: {
      const {
        payload = {},
      } = action;
      return {
        ...state,
        ...payload,
      };
    }
    case GET_ANALYTICS_DATA: {
      const {
        payload = {},
      } = action;
      const {
        data: fullData = [],
      } = payload;
      const { breakdown = [], filters = {} } = state;
      return {
        ...state,
        ...payload,
        fullData,
        data: filterData({ fullData, breakdown, filters }),
      };
    }
    case GET_SAVED_ANALYTICS_REPORTS: {
      const {
        payload: {
          data: reportList = [],
        } = {},
      } = action;
      const reportMap = {};
      reportList.forEach((report) => {
        const {
          id,
          startTime,
          endTime,
        } = report;
        const fullReport = { ...report };
        const dateRange = dayDiffsToDateRange([startTime, endTime]);
        if (dateRange) {
          fullReport.dateRange = dateRange;
        }
        reportMap[id] = fullReport;
      });
      return {
        ...state,
        savedReports: reportMap,
      };
    }
    case CREATE_ANALYTICS_REPORT: {
      const {
        payload: {
          data = {},
        } = {},
      } = action;
      const reportData = { ...data };
      const { id } = reportData;
      const { savedReports = {} } = state;
      const newSaved = { ...savedReports };
      newSaved[id] = reportData;
      const {
        startTime,
        endTime,
      } = reportData;
      const dateRange = dayDiffsToDateRange([startTime, endTime]);
      const newState = {
        ...state,
        ...reportData,
        savedReports: newSaved,
        selectedReportId: id,
      };
      if (dateRange) {
        newSaved[id].dateRange = dateRange;
        newState.dateRange = dateRange;
      }
      return newState;
    }
    case UPDATE_ANALYTICS_REPORT: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const { savedReports = {} } = state;
      const newSaved = { ...savedReports };
      newSaved[id] = newData;
      const {
        startTime,
        endTime,
      } = newData;
      const newState = {
        ...state,
        ...newData,
        savedReports: newSaved,
        selectedReportId: id,
      };
      const dateRange = dayDiffsToDateRange([startTime, endTime]);
      if (dateRange) {
        newSaved[id].dateRange = dateRange;
        newState.dateRange = dateRange;
      }
      return newState;
    }
    case SELECT_SAVED_REPORT: {
      const {
        payload: { id } = {},
      } = action;
      const {
        savedReports = {},
      } = state;
      if (!id) {
        // New report
        return {
          ...state,
          selectedReportId: id,
        };
      }
      const {
        [id]: ourReport = {},
      } = savedReports;
      const { filters = {} } = ourReport;
      const newState = {
        ...state,
        ...ourReport,
        selectedReportId: id,
        filters,
      };
      if (ourReport.datePreset) {
        delete newState.dateRange;
      } else {
        delete newState.datePreset;
      }
      if (ourReport.boardId) {
        delete newState.formTemplateId;
      } else {
        delete newState.boardId;
      }
      if (ourReport.dateBreakdown) {
        delete newState.fieldBreakdown;
      } else {
        delete newState.dateBreakdown;
      }
      return newState;
    }
    case DELETE_ANALYTICS_REPORT: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { savedReports = {} } = state;
      const newReports = { ...savedReports };
      delete newReports[id];
      return {
        ...state,
        savedReports: newReports,
        selectedReportId: state.selectedReportId === id ? null : state.selectedReportId,
      };
    }
    case UPDATE_ANALYTICS_FILTERS: {
      const {
        payload = {},
      } = action;
      const {
        filters: oldFilters = {},
        fullData = [],
        breakdown = [],
      } = state;

      const newFilters = {
        ...oldFilters,
        ...payload,
      };

      return {
        ...state,
        filters: newFilters,
        data: filterData({ fullData, breakdown, filters: newFilters }),
      };
    }
    default:
      return state;
  }
};
