export default {};

export const filterOptions = ({ labels = [], isVendor }) => {
  const filters = [
    [
      {
        title: 'Labels',
        key: 'labels',
        children: labels.map(({ title, id }) => ({
          title,
          key: id,
        })),
      },
    ],
    [
      {
        title: 'Archive',
        key: 'active',
        children: [
          {
            title: 'Active',
            key: 1,
          },
          {
            title: 'Archived',
            key: 0,
          },
        ],
      },
    ],
    [
      {
        title: 'Unread Messages',
        key: 'unread',
        children: [
          {
            title: 'Has Unread',
            key: 1,
          },
          {
            title: 'No Unread',
            key: 0,
          },
        ],
      },
    ],
  ];
  if (isVendor) {
    filters.push([{
      title: 'Type',
      key: 'type',
      children: [
        {
          title: 'Subcontractor',
          key: 'Subcontractor',
        },
        {
          title: 'Supplier',
          key: 'Supplier',
        },
      ],
    }]);
  }
  return filters;
};
