import {
  GET_GLOBAL_MATERIAL_LOCATIONS,
  CREATE_GLOBAL_MATERIAL_LOCATION,
  UPDATE_GLOBAL_MATERIAL_LOCATION,
  DELETE_GLOBAL_MATERIAL_LOCATION,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import globalMaterialLocationsService from './globalMaterialLocations.service';

export default {};

export const getGlobalMaterialLocations = () => async (dispatch) => {
  const { data } = await globalMaterialLocationsService.get();
  if (!data) return false;
  dispatch({
    type: GET_GLOBAL_MATERIAL_LOCATIONS,
    payload: {
      globalMaterialLocations: data,
    },
  });
  return true;
};

export const createGlobalMaterialLocation = (locationText) => async (dispatch) => {
  Analytics.track('GlobalMaterialLocations/Create');
  const { data } = await globalMaterialLocationsService.create(locationText);
  if (!data) return false;
  dispatch({
    type: CREATE_GLOBAL_MATERIAL_LOCATION,
    payload: {
      newlyCreatedLocation: data,
    },
  });
  return true;
};

export const deleteGlobalMaterialLocation = (id) => async (dispatch) => {
  Analytics.track('GlobalMaterialLocations/Delete');
  const { data } = await globalMaterialLocationsService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_GLOBAL_MATERIAL_LOCATION,
    payload: {
      idToDelete: id,
    },
  });
  return true;
};

export const updateGlobalMaterialLocation = (id, locationText) => async (dispatch) => {
  Analytics.track('GlobalMaterialLocations/Update');
  const { data } = await globalMaterialLocationsService.update(id, locationText);
  if (!data) return false;
  dispatch({
    type: UPDATE_GLOBAL_MATERIAL_LOCATION,
    payload: {
      globalMaterialLocationId: id,
      newLocationText: locationText,
    },
  });
  return true;
};
