import React from 'react';
import { message, notification } from 'antd';
import { AutoBreak, AutoRounding } from 'ontraccr-common';
import { decorateTaskWithDate, getTaskDate } from 'ontraccr-common/lib/Tasks';
import { DateTime } from 'luxon';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import Analytics from '../helpers/Analytics';

export default class Clock {
  constructor(props) {
    this.props = props;
    this.onClockIn = this.clockIn.bind(this);
    this.onClockOut = this.clockOut.bind(this);
    this.onSwitch = this.switchClick.bind(this);
    this.onBreakStart = this.startBreak.bind(this);
    this.onBreakEnd = this.endBreak.bind(this);
  }

  setUser(user) {
    this.user = user;
  }

  async clockIn(projectId, costcodeId, phaseId, classId, sageShiftId) {
    if (!this.user) {
      return message.error('Failed to clock in user');
    }
    const obj = {
      userId: this.user.id,
    };
    if (projectId) obj.projectId = projectId;
    if (costcodeId) obj.costcodeId = costcodeId;
    if (phaseId) obj.phaseId = phaseId;
    if (classId) obj.classId = classId;
    if (sageShiftId) obj.sageShiftId = sageShiftId;
    const decorated = decorateTaskWithDate({
      payload: obj,
      addDayOfWeek: true,
    });
    return this.props.clockin(decorated);
  }

  async clockOut(taskId, tasks) {
    const payload = {};

    const {
      id: clockedInId,
    } = this.user;
    const ourUser = this.users.find((user) => user.id === clockedInId);
    if (!ourUser) {
      return message.error('Cant clock out user');
    }

    let roundedTimes;
    let clockOutTask;
    let roundedTasks = tasks.map((task) => {
      const newEnd = DateTime.local().toMillis();
      if (task.id === taskId) {
        clockOutTask = { ...task, endTime: newEnd };
        return {
          ...task,
          endTime: newEnd,
        };
      }
      return task;
    });

    let day;
    if (clockOutTask) {
      day = getTaskDate(clockOutTask);
      roundedTimes = AutoRounding({
        tasks: roundedTasks,
        settings: this.settings,
        newEntry: {
          ...clockOutTask,
        },
        roundingPolicies: this.roundingPolicyPositionMap?.[ourUser.positionId],
      });

      if (roundedTimes) {
        roundedTasks = tasks.map((task) => {
          if (task.id === taskId) {
            return {
              ...task,
              ...roundedTimes,
            };
          }
          return task;
        });
        payload.endTime = roundedTimes.endTime;
        if (roundedTimes.originalEnd) payload.originalEnd = roundedTimes.originalEnd;
        payload.isAutoRounded = roundedTimes.isAutoRounded;

        // Rounding will only happen on FE now until AutoBreak is moved to server
        payload.disableStartTimeRounding = true;
        payload.disableEndTimeRounding = true;
      }
    }

    const userPermissions = new Set(this.positions?.[ourUser.position] || []);
    const isEnd = this.settings?.autoBreakPlacement === 'End';

    const diff = await AutoBreak({
      settings: this.settings,
      tasks: roundedTasks,
      userPermissions,
      notifyError: () => {
        notification.error({
          key: 'error',
          message: 'Error',
          description:
            'We were unable to add an automatic break to this shift. Please add one manually if applicable.',
          duration: 5,
        });
      },
      day,
      Analytics,
    });

    let shouldAdjustStartTime = true;

    if (diff) {
      payload.disableStartTimeRounding = true;
      const targetTask = diff?.tasksToEdit?.find((task) => (
        task.id === taskId
      ));

      if (targetTask) {
        if (isEnd) {
          payload.disableEndTimeRounding = true;
          payload.endTime = targetTask.endTime;
        } else {
          // We do not adjust start time if auto break modifies the shift already
          shouldAdjustStartTime = false;
          const isSubtract = this.settings?.autoBreakMode === 'Subtract';
          if (isSubtract) {
            // In subtract middle we need to overwrite original start as well
            payload.originalStart = targetTask.originalStart;
          }
        }
      }
    }

    if (shouldAdjustStartTime && roundedTimes?.startTime) {
      payload.startTime = roundedTimes.startTime;
      if (roundedTimes?.originalStart) payload.originalStart = roundedTimes.originalStart;
    }

    if (!payload.isAutoRounded) {
      if (diff) {
        await this.props.manualEntry({
          payload: diff,
          userId: clockedInId,
          useEndDateOvernight: this.settings.useEndDateOvernight,
        });
      }
      return this.props.clockout(taskId, payload, ourUser.id);
    }

    const errorMessage = payload.endTime > DateTime.local().toMillis()
      ? (
        <>
          <br />
          <br />
          <span>
            You will be blocked from clocking in until: {DateTime.fromMillis(payload.endTime).toLocaleString(DateTime.DATETIME_MED)}
          </span>
        </>
      ) : '';

    return new Promise((resolve) => {
      CustomConfirmModal({
        title: 'Confirmation',
        content: (
          <span>
            Clocking out will result in your shift being rounded. Are you sure you want to clock out or did you mean to switch tasks instead?
            {errorMessage}
          </span>
        ),
        okText: 'Clock Out',
        cancelText: 'Cancel',
        onOk: async () => {
          if (diff) {
            await this.props.manualEntry({
              payload: diff,
              userId: clockedInId,
              useEndDateOvernight: this.settings.useEndDateOvernight,
            });
          }
          resolve(this.props.clockout(taskId, payload, ourUser.id));
        },
        onCancel: () => resolve(false),
        additionalOptions: {
          zIndex: 5000,
        },
      });
    });
  }

  async switchClick({
    taskId, projectId, costcodeId, phaseId, classId, sageShiftId,
  }) {
    const {
      id: clockedInId,
    } = this.user;

    const ourUser = this.users.find((user) => user.id === clockedInId);
    if (!ourUser) {
      return message.error('Cant switch task for user');
    }

    const obj = {
      userId: ourUser.id,
    };
    if (projectId) obj.projectId = projectId;
    if (costcodeId) obj.costcodeId = costcodeId;
    if (phaseId) obj.phaseId = phaseId;
    if (classId) obj.classId = classId;
    if (sageShiftId) obj.sageShiftId = sageShiftId;
    const decorated = decorateTaskWithDate({ payload: obj });
    return this.props.switchTask(taskId, decorated);
  }

  async startBreak(breakId) {
    const {
      id: clockedInId,
    } = this.user;
    const ourUser = this.users.find((user) => user.id === clockedInId);
    if (!ourUser) {
      return message.error('Cant start break');
    }

    const decorated = decorateTaskWithDate({
      payload: {
        taskId: breakId,
        userId: clockedInId,
      },
    });
    return this.props.takeBreak(decorated);
  }

  async endBreak(breakId) {
    const {
      id: clockedInId,
    } = this.user;
    const ourUser = this.users.find((user) => user.id === clockedInId);
    if (!ourUser) {
      return message.error('Cant end break');
    }

    const decorated = decorateTaskWithDate({
      payload: {
        userId: ourUser.id,
      },
    });
    return this.props.endBreak(breakId, decorated);
  }
}
