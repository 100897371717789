import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import globalCodeMassUpload from '../../assets/images/NUX/globalCodeMassUpload.png';
import globalCodeCreation from '../../assets/images/NUX/globalCodeCreation.png';


const getGlobalDesc = (t) => `These are companywide cost codes that are usually set by an Admin.
Global cost codes serve as a quick template for all future ${t('Project', { count: 2 })} that are created within Ontraccr
and can be easily imported during a ${t('Project')}'s initial creation.`;

const massDesc = `To make it easier to add a pre-existing set of company cost codes into Ontraccr,
you can simply upload an excel spreadsheet using our provided template format.`;


export default function NuxGlobalCodes({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const globalDesc = getGlobalDesc(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 99px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:`Global Codes`,
        description:globalDesc,
        cols:[0,16,8],
        image:{
          src:globalCodeCreation,
          style:{
            height:100,
            width:194,
          }
        },
      },{
        title:'Mass Upload',
        description:massDesc,
        cols:[0,16,8],
        image:{
          src:globalCodeMassUpload,
          style:{
            height:100,
            width:192,
          }
        },
      }]}
    />
  );
}
