import React, { useState, useCallback } from 'react';
import { Row, Col } from 'antd';
import CustomModal from './CustomModal';
import OnTraccrTextInput from '../inputs/OnTraccrTextInput';
import OnTraccrButton from '../buttons/OnTraccrButton';

import colors from '../../constants/Colors';

export default ({
  onClose,
  onDelete,
  visible,
  title,
  warningHeader,
  warningSubtext,
  promptForPasswordOnDelete = true,
}) => {
  const [text,setText] = useState();
  const onChange = useCallback((e) => {
    const {
      target:{
        value,
      } = {}
    } = e;
    setText(value);
  },[]);
  const onCancel = useCallback(() => {
    onClose();
    setText();
  },[onClose]);

  const onDeleteClicked = useCallback(async () => {
    if (await onDelete({ 
      password: text,
    })) {
      onClose();
      setText();
    }
  },[onDelete,text,onClose]);

  return (
    <CustomModal
      title={title}
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
    >
      <div style={{ fontSize:14, padding: 10 }}>
        <b>Warning:</b> {warningHeader}
        <br/>

        {warningSubtext}
        
        <br/>
        <br/>
        {promptForPasswordOnDelete && 'Please enter your password below to confirm.'}
      </div>
      {promptForPasswordOnDelete && <Row style={{ margin: '20px 5px'}}>
        <OnTraccrTextInput
          password
          autoComplete='new-password'
          placeholder='Enter your password'
          onChange={onChange}
          value={text}
        />
      </Row>}
 
    <Row justify='center' gutter={12} style={{ marginBottom: 10 }}>
      <Col>
        <OnTraccrButton
          type='cancel'
          title='Cancel'
          roundStyle
          onClick={onCancel}
        />
      </Col>
      <Col>
        <OnTraccrButton
          title='Delete'
          roundStyle
          disabled={!text && promptForPasswordOnDelete}
          onClick={onDeleteClicked}
        />
      </Col>
    </Row>
    </CustomModal>
  );
}