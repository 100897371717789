import React, { useMemo, useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select } from 'antd';

import Permissions from '../../../auth/Permissions';

const DEFAULT_EMAIL = 'forms@ontraccr.com';
export default ({
  id,
  data,
  setDataMap,
}) => {
  const { sender: initialSelected } = data || {};
  const [selected,setSelected] = useState(initialSelected || []);
  const integratedEmails = useSelector(state => state.settings.integratedEmails);

  const emailOptions = useMemo(() => (
    [{ email: DEFAULT_EMAIL }].concat(integratedEmails)
  ),[integratedEmails]);

  const selectOptions = useMemo(() => (
    emailOptions.map(({ email, userId }) => {
      return (
      <Select.Option
        value={email}
        key={email}
        disabled={email !== DEFAULT_EMAIL && userId !== Permissions.id}
      >
        {email}
      </Select.Option>
    )})
  ),[emailOptions]);

  const onChange = useCallback((newSender) => {
    setSelected(newSender);
    if(setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            sender: newSender,
          },
        };
      })
    }
  },[id,setDataMap]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Select Sender Email:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          style={{width:'100%'}}
          optionLabelProp='email'
          value={selected}
          dropdownMatchSelectWidth={false}
          onChange={onChange}
        >
          {selectOptions}
        </Select>
      </Row>
    </>
  );
}