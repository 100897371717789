import React, { useCallback, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  DatePicker,
  notification,
  Switch,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';

import MoreButton from '../../common/buttons/MoreButton';
import { updateProjectProgressFilters } from '../state/projects.actions';
import DateFilter from '../../datePicker/DateFilter';
import { getDateRangeFromConfig } from '../../analytics/analytics.helpers';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export default function ProgressHeader({
  onExport,
  onUploadInvoice,
  toggleBillingDisplay,
  showBilling,
}) {
  const dispatch = useDispatch();

  const { datePreset, dateRange } = useSelector((state) => state.projects.projectProgressFilters);
  const [open, setOpen] = useState(false);

  const exportExcel = useCallback(() => onExport(false), [onExport]);
  const exportPDF = useCallback(() => onExport(true), [onExport]);

  const onDateRangeChange = useCallback((newDateRange) => {
    let newDateRangeFilter;

    if (newDateRange) {
      const [startDate, endDate] = newDateRange;
      newDateRangeFilter = {
        start: startDate.startOf('day').unix() * 1000,
        end: endDate.endOf('day').unix() * 1000,
      };

      notification.warn({
        message: 'Date range filter selected',
        description: 'Please note that values on the progress page may not be updated while date range filter is turned on.',
      });
    }
    dispatch(updateProjectProgressFilters({
      dateRange: newDateRangeFilter,
      datePreset: undefined,
    }));
  }, [dispatch]);

  const onPresetClicked = useCallback((newDatePreset) => {
    dispatch(updateProjectProgressFilters({
      dateRange: undefined,
      datePreset: newDatePreset,
    }));
    setOpen(false);
  }, [dispatch]);

  const formattedRange = useMemo(() => {
    if (dateRange) {
      const rangeArray = [dateRange.start, dateRange.end];
      return getDateRangeFromConfig({ dateRange: rangeArray, datePreset });
    }
    return getDateRangeFromConfig({ dateRange, datePreset });
  }, [datePreset, dateRange]);

  return (
    <Row
      style={{ width: '100%', height: 32 }}
      align="middle"
      justify="space-between"
    >
      <Col>
        <DatePicker.RangePicker
          format="MMM Do YY"
          onChange={onDateRangeChange}
          open={open}
          value={formattedRange}
          onOpenChange={setOpen}
          renderExtraFooter={() => (
            <DateFilter datePreset={datePreset} handlePreset={onPresetClicked} />
          )}
        />
        {onUploadInvoice && (
          <OnTraccrButton
            title="Upload Invoice"
            onClick={onUploadInvoice}
            style={{ marginLeft: 10 }}
          />
        )}
      </Col>
      <Col>
        <Row>
          {toggleBillingDisplay && (
            <>
              <Col>
                Display Billing
              </Col>
              <Col style={{ marginLeft: 10, marginRight: 10 }}>
                <Switch
                  checked={showBilling}
                  onChange={toggleBillingDisplay}
                />
              </Col>
            </>
          )}
          <Col>
            <MoreButton
              options={[
                {
                  title: 'Export to Excel',
                  icon: 'file-excel',
                  style: { padding: 0 },
                  onClick: exportExcel,
                },
                {
                  title: 'Export to PDF',
                  icon: 'file-text',
                  style: { padding: 0 },
                  onClick: exportPDF,
                },
              ]}
              btnStyle={{
                marginRight: 15,
                fontSize: 25,
                height: 30,
                paddingTop: 2.5,
              }}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ProgressHeader.propTypes = {
  onExport: PropTypes.func.isRequired,
  onUploadInvoice: PropTypes.func,
  toggleBillingDisplay: PropTypes.func,
  showBilling: PropTypes.bool.isRequired,
};

ProgressHeader.defaultProps = {
  onUploadInvoice: null,
  toggleBillingDisplay: null,
};
