import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row, Col, Menu,
} from 'antd';
import { useLocation, useHistory } from 'react-router-dom';

import SideListSearchHeader from '../common/SideListSearchHeader';
import SideList from '../common/SideList';

import SubmissionHeader from './SubmissionHeader';
import TimeCardsContainer from './TimeCardsContainer';
import TimeCardTeamMenuItem from './TimeCardTeamMenuItem';

import { search, mergeSets, getIdMap } from '../helpers/helpers';
import Permissions from '../auth/Permissions';

const ALL_TEAMS = { name: 'All Teams' };
const DEFAULT_VIEW = 'default';
const SUBMISSION_VIEW = 'submitted';

const renderItem = (item) => (item?.name?.length > 27 ? `${item.name.substr(0, 25)}...` : (item?.name || ''));

function TeamTimeCardContainer({
  onUserSelect: propUserSelect,
}) {
  const history = useHistory();
  const {
    state: { submissions: showSubmissions, user: locationStateUser } = {},
  } = useLocation();

  const approvalCount = useSelector((state) => state.approvals.approvalCount);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const divisions = useSelector((state) => state.settings.divisions);
  const users = useSelector((state) => state.users.users);
  const teams = useSelector((state) => state.teams.teams);
  const approvals = useSelector((state) => state.approvals.approvals);
  const isFullScreen = useSelector((state) => state.main.menuCollapsed);

  const [selectedTeam, setSelectedTeam] = useState(ALL_TEAMS);
  const [selectedSubmitView, setSelectedSubmitView] = useState(DEFAULT_VIEW);
  const [selectedUser, setSelectedUser] = useState();
  const [searchTerm, setSearchTerm] = useState();

  const onUserSelected = useCallback((user) => {
    const {
      name,
    } = user;
    propUserSelect(name);
    setSelectedUser(user);
  }, [propUserSelect]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchTerm(value);
  }, []);

  const submissionSet = useMemo(() => new Set(approvals.map(({ userId }) => userId)), [approvals]);

  const {
    ourTeams: parsedTeams,
    userToTeamMap: userTeamMap,
  } = useMemo(() => {
    const ourTeams = [ALL_TEAMS];
    const userToTeamMap = {};

    teams.forEach((team) => {
      if (!team.active || team?.divisionIds?.every((dId) => !selectedDivisions.has(dId))) return;
      const {
        members = [],
      } = team;

      members.forEach((member) => {
        const {
          id: userId,
          teamRole,
        } = member;

        if (!userToTeamMap[userId]) {
          userToTeamMap[userId] = [];
        }

        userToTeamMap[userId].push({
          teamId: team.id,
          role: teamRole,
        });
      });

      ourTeams.push({
        ...team,
        memberSet: new Set(members.map((member) => member.id)),
      });
    });

    return {
      ourTeams,
      userToTeamMap,
    };
  }, [teams, selectedDivisions]);

  const doesSuperviseUser = useCallback((userId) => {
    const userTeams = userTeamMap[userId];
    if (!userTeams) return false;
    const currentUserTeams = userTeamMap[Permissions.id];
    if (!currentUserTeams) return false;

    const currentUserTeamMap = getIdMap(currentUserTeams, 'teamId');

    return userTeams.some(({ teamId, role }) => (
      currentUserTeamMap[teamId]?.role === 'Supervisor' && role !== 'Supervisor'
    ));
  }, [userTeamMap]);

  const { relevantUsers, fullUsers } = useMemo(() => {
    const {
      name: teamName,
      memberSet = new Set(),
    } = selectedTeam ?? {};
    const userDivSets = mergeSets(
      Object.values(divisions)
        .filter(({ id: divisionId }) => selectedDivisions.has(divisionId))
        .map(({ users: divUsers }) => divUsers),
    );

    const hasPermissionForUser = (user) => (
      (Permissions.id !== user.id && Permissions.has(`TIMECARD_VIEW_${Permissions.formatPosition(user.position)}`))
      || (Permissions.id === user.id && Permissions.has('TIMECARD_VIEW_SELF'))
      || doesSuperviseUser(user.id)
    );

    const usersWithAccess = users.filter((user) => (
      user.active
      && userDivSets.has(user.id)
      && (teamName === ALL_TEAMS.name || memberSet.has(user.id))
      && hasPermissionForUser(user)
    ));

    const filteredUsers = usersWithAccess.filter((user) => (
      selectedSubmitView === DEFAULT_VIEW || submissionSet.has(user.id)
    ));

    return {
      relevantUsers: filteredUsers,
      fullUsers: usersWithAccess,
    };
  }, [
    selectedTeam,
    parsedTeams,
    divisions,
    selectedDivisions,
    users,
    selectedSubmitView,
    submissionSet,
    doesSuperviseUser,
  ]);

  const relevantUsersWithSummary = useMemo(() => (
    [{ name: 'Summary', id: 'summary' }].concat(relevantUsers)
  ), [relevantUsers]);

  const filteredUsers = useMemo(() => (
    search({
      data: relevantUsersWithSummary,
      value: searchTerm,
      getField: (user) => user.name,
    })
  ), [relevantUsersWithSummary, searchTerm]);

  useEffect(() => {
    if (
      relevantUsersWithSummary.length > 0
      && (
        !selectedUser
        || !relevantUsersWithSummary.some((user) => user.id === selectedUser.id)
      )
    ) {
      onUserSelected(relevantUsersWithSummary[0]);
    }
  }, [relevantUsersWithSummary, selectedUser, onUserSelected]);

  useEffect(() => {
    if (locationStateUser) {
      const ourUser = relevantUsers.find((user) => user.id === locationStateUser);
      if (ourUser) {
        onUserSelected(ourUser);
        setSelectedSubmitView(showSubmissions ? SUBMISSION_VIEW : DEFAULT_VIEW);
        history.replace({
          pathname: '/timecards/team',
          state: {},
        });
      }
    }
  }, [locationStateUser, showSubmissions, onUserSelected, relevantUsers]);

  const menu = useMemo(() => (
    <Menu
      selectedKeys={[selectedTeam?.name]}
      mode="horizontal"
      style={{
        height: '50vh',
        overflowY: 'auto',
        overflowX: 'hidden',
      }}
    >
      {parsedTeams.map((t) => (
        <Menu.Item key={t.name}>
          <TimeCardTeamMenuItem
            team={t}
            onTeamSelected={setSelectedTeam}
          />
        </Menu.Item>
      ))}
    </Menu>
  ), [parsedTeams]);

  return (
    <Row style={{ height: '100%', width: '100%' }}>
      {!isFullScreen && (
        <Col span={6} style={{ height: '100%', paddingRight: 10 }}>
          <SideList
            id="team-timecards"
            key="team-timecards"
            header={(
              <SideListSearchHeader
                onChange={onSearch}
                menu={menu}
                activeItem={selectedTeam?.name}
                submissionView={Permissions.has('TEAMS_WRITE')
                  ? (
                    <SubmissionHeader
                      selected={selectedSubmitView}
                      onSelect={setSelectedSubmitView}
                      approvalCount={approvalCount}
                    />
                  )
                  : null}
              />
            )}
            dataSource={filteredUsers}
            headerStyle={{
              height: Permissions.has('TEAMS_WRITE') ? 115 : 80,
            }}
            renderItem={renderItem}
            onClick={onUserSelected}
            selectedItems={new Set([selectedUser])}
            rowHeight={50}
            scrollable
          />
        </Col>
      )}
      <Col span={isFullScreen ? 24 : 18} style={{ height: '100%', paddingLeft: isFullScreen ? 0 : 10 }}>
        {selectedUser && (
          <TimeCardsContainer
            userId={selectedUser?.id === 'summary' ? null : selectedUser.id}
            isApprovals={selectedSubmitView === 'submitted'}
            relevantUsers={relevantUsers}
            fullUsers={fullUsers}
            isSummary={selectedUser?.id === 'summary'}
          />
        )}
      </Col>
    </Row>
  );
}

TeamTimeCardContainer.propTypes = {
  onUserSelect: PropTypes.func.isRequired,
};

export default TeamTimeCardContainer;
