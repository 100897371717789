import React, { useEffect } from 'react';
import { Drawer, Form } from 'antd';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../containers/DrawerSubmitFooter';
import FormColorPicker from '../inputs/FormColorPicker';
import OnTraccrNumberInput from '../inputs/OnTraccrNumberInput';

export default function PDFAnnotationStyleDrawer({
  visible,
  annotation,
  onAnnotationUpdated,
  onClose,
  onDelete,
}) {
  const [form] = Form.useForm();
  const onValuesChange = (changedValues) => {
    onAnnotationUpdated(changedValues);
  };

  const {
    type,
    fontSize,
    color,
    width,
  } = annotation ?? {};

  useEffect(() => {
    if (!form) return;

    form.resetFields();
    form.setFieldsValue({
      fontSize,
      color,
      width,
    });
  }, [annotation, form]);

  return (
    <Drawer
      title="Annotation Style"
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      width={400}
      mask={false}
    >
      <Form
        form={form}
        onValuesChange={onValuesChange}
      >
        { type === 'text' && (
          <Form.Item
            label="Font Size"
            name="fontSize"
            initialValue={fontSize}
            rules={[
              { required: true, message: 'Please select a font size' },
            ]}
          >
            <OnTraccrNumberInput
              min={1}
              max={100}
              step={1}
            />
          </Form.Item>
        )}
        <Form.Item
          label="Color"
          name="color"
          initialValue={color}
          rules={[
            { required: true, message: 'Please select a color' },
          ]}
        >
          <FormColorPicker isNotDisplay />
        </Form.Item>
        { (type === 'pencil' || type === 'highlight') && (
          <Form.Item
            label="Line Width"
            name="width"
            initialValue={width}
            rules={[
              { required: true, message: 'Please select a line width' },
            ]}
          >
            <OnTraccrNumberInput
              min={1}
              max={100}
              step={1}
            />
          </Form.Item>
        )}
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onDelete={onDelete}
      />
    </Drawer>
  );
}

PDFAnnotationStyleDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  annotation: PropTypes.shape({
    type: PropTypes.string.isRequired,
    fontSize: PropTypes.number,
    color: PropTypes.string.isRequired,
    width: PropTypes.number,
  }),
  onAnnotationUpdated: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

PDFAnnotationStyleDrawer.defaultProps = {
  annotation: null,
};
