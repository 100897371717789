/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { getIdMap } from 'ontraccr-common/lib/Common';
import BoardStatusDisplayFieldCard from './BoardStatusDisplayFieldCard';

export default function BoardStatusDisplayFieldSelector({
  value,
  onChange,
  options,
}) {
  const [selectedValues, setSelectedValues] = useState(value);
  const optionsMap = useMemo(() => getIdMap(options, 'value'), [options]);

  useEffect(() => {
    setSelectedValues(value);
  }, [value]);

  const onSelectedValuesChange = (newSelected) => {
    setSelectedValues(newSelected);
    onChange(newSelected);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = reorder(
      selectedValues,
      result.source.index,
      result.destination.index,
    );
    onSelectedValuesChange(items);
  };

  const onDeleteClicked = (id) => {
    const newSelectedValues = selectedValues.filter((val) => val !== id);
    onSelectedValuesChange(newSelectedValues);
  };

  return (
    <>
      <Select
        value={selectedValues}
        onChange={onSelectedValuesChange}
        placeholder="Select data to display on card"
        options={options}
        mode="multiple"
      />
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="parent">
          {({ innerRef, droppableProps, placeholder }) => (
            <div
              ref={innerRef}
              {...droppableProps}
            >
              {selectedValues.map((val, index) => (
                <BoardStatusDisplayFieldCard
                  key={val}
                  id={val}
                  index={index}
                  label={optionsMap?.[val]?.label}
                  onDeleteClicked={() => onDeleteClicked(val)}
                />
              ))}
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </>
  );
}

BoardStatusDisplayFieldSelector.propTypes = {
  value: PropTypes.arrayOf(PropTypes.string),
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
};

BoardStatusDisplayFieldSelector.defaultProps = {
  value: [],
  options: [],
};
