export const weatherReportColumns = (renderStat) => [
  {
    title: 'Temperature',
    children: [
      {
        title: 'Low',
        dataIndex: 'temperatureLow',
        key: 'temperatureLow',
        render: renderStat,
      },
      {
        title: 'High',
        dataIndex: 'temperatureHigh',
        key: 'temperatureHigh',
        render: renderStat,
      },
      {
        title: 'Avg',
        dataIndex: 'temperatureAvg',
        key: 'temperatureAvg',
        render: renderStat,
      },
    ],
  },
  {
    title: 'Precipitation Since',
    children: [
      {
        title: 'Midnight',
        dataIndex: 'precipitation1',
        key: 'precipitation1',
        render: renderStat,
      },
      {
        title: '2 days',
        dataIndex: 'precipitation2',
        key: 'precipitation2',
        render: renderStat,
      },
      {
        title: '3 days',
        dataIndex: 'precipitation3',
        key: 'precipitation3',
        render: renderStat,
      },
    ],
  },
  {
    title: 'Humidity',
    children: [
      {
        title: 'Low',
        dataIndex: 'humidityLow',
        key: 'humidityLow',
        render: renderStat,
      },
      {
        title: 'Avg',
        dataIndex: 'humidityAvg',
        key: 'humidityAvg',
        render: renderStat,
      },
      {
        title: 'High',
        dataIndex: 'humidityHigh',
        key: 'humidityHigh',
        render: renderStat,
      },
    ],
  },
  {
    title: 'Windspeed',
    children: [
      {
        title: 'Avg',
        dataIndex: 'windspeedAvg',
        key: 'windspeedAvg',
        render: renderStat,
      },
      {
        title: 'High',
        dataIndex: 'windspeedHigh',
        key: 'windspeedHigh',
        render: renderStat,
      },
      {
        title: 'Gust',
        dataIndex: 'windspeedGust',
        key: 'windspeedGust',
        render: renderStat,
      },
    ],
  },
];

export const dailySnapshotColumns = (renderHourlyConditions) => [
  {
    title: '6:00am',
    dataIndex: '6am',
    key: '6am',
    render: renderHourlyConditions,
  },
  {
    title: '9:00am',
    dataIndex: '9am',
    key: '9am',
    render: renderHourlyConditions,
  },
  {
    title: '12:00pm',
    dataIndex: '12pm',
    key: '12pm',
    render: renderHourlyConditions,
  },
  {
    title: '3:00pm',
    dataIndex: '3pm',
    key: '3pm',
    render: renderHourlyConditions,
  },
  {
    title: '6:00pm',
    dataIndex: '6pm',
    key: '6pm',
    render: renderHourlyConditions,
  },
  {
    title: '9:00pm',
    dataIndex: '9pm',
    key: '9pm',
    render: renderHourlyConditions,
  },
];
