import React, {
  useCallback,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { notification, Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import { getFileType, downloadFile, getFileDetails } from '../../files/fileHelpers';
import PhotoReel from '../photos/PhotoReel';
import FullPhoto from '../photos/FullPhoto';
import LiveFeedFileUpload from '../../dashboard/LiveFeed/LiveFeedFileUpload';
import colors from '../../constants/Colors';

export default function FormFileInput(props) {
  const {
    files,
    setFiles,
    isReadOnly = false,
  } = props;

  const [showFileUpload, setShowFileUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState({});

  const clearSelectedFile = useCallback(() => setSelectedFile({}), []);
  const toggleFileUpload = () => setShowFileUpload(!showFileUpload);
  const onPreviewClick = useCallback(async (index) => {
    const file = files[index];

    if (!file) return;
    if (file instanceof File) {
      setSelectedFile({
        file,
        type: getFileType(file),
      });
    } else {
      let fileDetails = file;
      if (!file.url) {
        fileDetails = {
          ...await getFileDetails(file),
          ...file,
        };
      }

      setSelectedFile({
        ...fileDetails,
        type: getFileType(fileDetails),
      });
    }
  }, [files]);

  const addFile = (file) => {
    const existingFiles = [...files];

    if (existingFiles.some((existingFile) => file.id && existingFile.id === file.id)) {
      notification.warn({
        message: 'Warning',
        description: 'File already added',
      });
      return;
    }

    setFiles([...files, file]);
    setShowFileUpload(false);
  };

  const updateFile = useCallback((oldFile, updatedFile) => {
    if (!updatedFile) return clearSelectedFile();
    const newFiles = files.map((file) => {
      if (file === oldFile) return updatedFile;
      return file;
    });
    setFiles(newFiles);
    return clearSelectedFile();
  }, [files, clearSelectedFile]);

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  return (
    <>
      { !isReadOnly
        && (
          <Button
            className="form-attachment-add-button"
            icon={(
              <FileAddOutlined
                style={{
                  color: colors.ONTRACCR_RED,
                }}
              />
            )}
            onClick={toggleFileUpload}
          />
        )}
      {files.length > 0
        && (
        <div className="livefeed-file-reel-container">
          <PhotoReel
            files={files}
            shadow
            small
            onDelete={removeFile}
            onClick={onPreviewClick}
          />
        </div>
        )}
      { !isReadOnly
        && (
          <LiveFeedFileUpload
            visible={showFileUpload}
            onUploadEnd={toggleFileUpload}
            addFile={addFile}
            style={{ zIndex: 2 }}
            height="calc(100% - 300px)"
            customProps={{ getContainer: false }}
          />
        )}
      <FullPhoto
        file={selectedFile.file}
        url={selectedFile.url}
        type={selectedFile.type}
        onClose={clearSelectedFile}
        onDownload={() => {
          if (selectedFile && selectedFile.fullPath) {
            downloadFile({ fileDetails: selectedFile });
          } else if (selectedFile.file) {
            downloadFile({ fileObject: selectedFile.file });
          }
        }}
        onSave={updateFile}
      />
    </>
  );
}

FormFileInput.propTypes = {
  files: PropTypes.arrayOf(PropTypes.shape()),
  setFiles: PropTypes.func,
  useIconForButton: PropTypes.bool,
  isReadOnly: PropTypes.bool,
};

FormFileInput.defaultProps = {
  files: [],
  setFiles: () => {},
  useIconForButton: true,
  isReadOnly: false,
};
