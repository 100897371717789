import React from 'react';
import { Divider } from 'antd';

import { version } from '../../package.json';

import colors from '../constants/Colors';

export default function Version() {
  return (
    <div id="menu-version-container">
      <div id="menu-version-divider-container">
        <Divider style={{
          backgroundColor: colors.opacity(colors.LIGHT_GRAY, 0.4),
          height: 1,
          margin: 0,
        }}
        />
      </div>
      <div id="menu-version">{`v${version}`}</div>
    </div>
  );
}
