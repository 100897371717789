import CustomConfirmModal from '../common/modals/CustomConfirmModal';

export default ({
  textInput: content,
  onOk,
  onCancel,
}) => (
  CustomConfirmModal({
    title: 'Create Position',
    content,
    onOk,
    onCancel,
    okText: 'Create',
  })
);
