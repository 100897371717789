import Axios from 'axios';
import { request } from '../../../helpers/requests';

export default {
  getWeather: (query) => request({
    call: Axios.get('/weather', { params: query }),
    errMsg: 'Could not get weather',
    hideSuccessToast: true,
  }),
};
