import React from 'react';
import { Descriptions, Divider } from 'antd';

import ClientPortalField from './ClientPortalField';

export default ({
  name,
  fields = [],
  onFileClick,
}) => (
  <>
    <Descriptions title={<span className='client-portal-description-header'>{name}</span>} column={1}>
      {
        fields.map((field) => {
          const { configProps: { title } = {} } = field;
          return (
            <Descriptions.Item
              label={<span className='client-portal-profile-label'>{title}</span>}
              key={field.id}
            >
              <ClientPortalField {...field} onFileClick={onFileClick}/>
            </Descriptions.Item>
          );
        })
      }
    </Descriptions>
    <Divider style={{ margin: '0px 0px 10px 0px'}}/>
  </>
)