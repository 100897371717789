/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import {
  Checkbox,
  Row,
  Col,
  Popover,
} from 'antd';
import PropTypes from 'prop-types';

import HoverHelp from '../../common/HoverHelp';
import WarningHeader from '../../common/text/WarningHeader';
import { storageUpgradeWarning } from '../../files/fileHelpers';

function ExternalFormCheckbox({
  isDisplay,
  onCheckChange: onPropCheckChange,
  value: checked,
  isExternalFormEnabled,
}) {
  const onCheckChange = useCallback((e) => {
    if (!isExternalFormEnabled) return;
    const {
      target: {
        checked: newChecked,
      } = {},
    } = e;
    onPropCheckChange(newChecked);
  }, [isExternalFormEnabled]);

  const CheckboxWithWarning = useCallback((props) => {
    const warningOpts = {
      title: 'You need to upgrade your account to access this feature',
      action: 'create an external form',
      bodyText: 'Contact your account manager to add this functionality to your account.',
    };

    return isExternalFormEnabled
      ? <Checkbox {...props} />
      : (
        <Popover
          placement="bottomRight"
          trigger="click"
          title={<WarningHeader />}
          content={storageUpgradeWarning(warningOpts)}
        >
          <Checkbox {...props} />
        </Popover>
      );
  }, [isExternalFormEnabled]);

  if (isDisplay && !checked) return null;

  return (
    <Row className="form-default-data-row" justify="space-between">
      <Col>
        <Row gutter={10}>
          {!isDisplay && (
            <Col>
              <CheckboxWithWarning
                onChange={onCheckChange}
                // eslint-disable-next-line react/no-unknown-property
                checked={checked}
              />
            </Col>
          )}
          <Col>
            External Form
          </Col>
          <Col>
            {!isDisplay && (
              <HoverHelp
                placement="right"
                title="External Form"
                content={(
                  <div style={{ maxWidth: 400 }}>
                    Check this box to allow users outside of your company to submit
                    this form from the below URL.
                    <br />
                    <br />
                    When a form is submitted, the workflow will be started and the
                    form will be sent to the first step in the workflow.
                    <br />
                    <br />
                    Please note that certain fields are not supported in external forms
                    and will automatically be removed. These fields include:
                    <br />
                    <br />
                    <ul>
                      <li>Tables (except Custom)</li>
                      <li>Choose One or More (except Custom)</li>
                      <li>Signature</li>
                    </ul>
                    <br />
                    Field triggers are also not supported in external forms.
                  </div>
                )}
              />
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

ExternalFormCheckbox.propTypes = {
  isDisplay: PropTypes.bool,
  onCheckChange: PropTypes.func,
  value: PropTypes.bool,
  isExternalFormEnabled: PropTypes.bool,
};

ExternalFormCheckbox.defaultProps = {
  isDisplay: false,
  onCheckChange: () => {},
  value: false,
  isExternalFormEnabled: false,
};

export default ExternalFormCheckbox;
