import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import VariableTextInput from './VariableTextInput';

const formVariables = [
  {
    key: 'Form Author',
    name: 'Form Author',
  },
  {
    key: 'Project',
    name: 'Project',
  },
  {
    key: 'Date',
    name: 'Date',
  },
  {
    key: 'Created Date',
    name: 'Created Date',
  },
  {
    key: 'Form Name',
    name: 'Form Name',
  },
  {
    key: 'Form Number',
    name: 'Form Number',
  },
];

const tagParser = (startIndex, variable = {}) => {
  const { key, name } = variable;
  return {
    startIndex,
    endIndex: startIndex + name.length,
    key,
    name,
  };
};

export default function FormVariableTextInput({
  textAreaProps,
  onTextChange,
  text,
  visible,
  additionalVariables = [],
}) {
  const allVariables = useMemo(() => (
    [...formVariables, ...additionalVariables]
  ), [additionalVariables]);

  return (
    <VariableTextInput
      textAreaProps={textAreaProps}
      onTextChange={onTextChange}
      text={text}
      variables={allVariables}
      parser={tagParser}
      showAllOptions
      visible={visible}
    />
  );
}

FormVariableTextInput.propTypes = {
  onTextChange: PropTypes.func.isRequired,
  text: PropTypes.string,
  textAreaProps: PropTypes.shape({}),
  visible: PropTypes.bool,
  additionalVariables: PropTypes.arrayOf(PropTypes.shape({})),
};

FormVariableTextInput.defaultProps = {
  text: '',
  textAreaProps: {},
  visible: undefined,
  additionalVariables: [],
};
