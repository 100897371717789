import React, { useState, useEffect } from 'react';
import { Row, Col } from 'antd';

import DowngradeTimer from  './DowngradeTimer';

import {
  downgradeTitle,
  trialTitle,
  downgradeText,
} from './main.text';

import CustomModal from '../common/modals/CustomModal';

const textStyle = {
  fontFamily:'roboto-regular',
  fontSize:14,
  width: 500,
};

export default ({
  visible,
  users = [],
  userLimit = 4,
  subscriptionEnd,
  onCloseClicked
}) => {
  const isDowngrade = userLimit > 0;
  const [requiredArchiveAmount, setRequiredArchiveAmount] = useState(0);

  useEffect(() => {
    const activeCount = users.length;
    setRequiredArchiveAmount(Math.max(activeCount - userLimit,0));
  }, [users, userLimit]);

  return (
    <CustomModal
      title={isDowngrade ? downgradeTitle : trialTitle}
      visible={visible}
      width={600}
      onCloseClicked={onCloseClicked}
    >
      <div style={{ width:'100%' }}>
        <Row style={{ width:'100%', marginBottom: isDowngrade ? 0 : 20 }} justify='center'>
          <Col>
            {isDowngrade && <p style={textStyle}>{downgradeText}</p>}
            {isDowngrade && <p style={textStyle}>
              This can happen if your account owner has reduced your subscription license count, cancelled your subscription, your payment has failed or your free trial has ended.<br/>
            </p>}
            <div style={textStyle}>
              Please instruct your account owner to{isDowngrade ? ' either' : ''} upgrade your account's subscription
              <br/>
              {isDowngrade &&
                <div>
                  OR archive <b style={{ 
                    fontSize: 20,
                    color: requiredArchiveAmount > 0 ? 'red' : 'inherit'
                  }}>{requiredArchiveAmount}</b> more users
                </div>
              }
            </div>
          </Col>
        </Row>
        {isDowngrade && 
          <Row style={{ width:'100%' }} justify='center'>
            <DowngradeTimer subscriptionEnd={subscriptionEnd}/>
          </Row>
        }
        {isDowngrade  && 
          <Row style={{ width:'100%', marginTop: 20 }} justify='center'>
            <p style={textStyle}>
              Please resolve this issue before the grace period expires.
            </p>
            <p style={textStyle}>
              Failure to resolve the issue before the end of the grace period
              will result in automatic archival of the required number of user accounts.
            </p>
          </Row>
        }
      </div>
    </CustomModal>
  );
}
