import React from 'react';
import { Row, Progress } from 'antd';

export default ({
  title,
  percent,
}) => (
  <Row justify='end' style={{ width: 140 }}>
    <div style={{ width: '100%', textAlign: 'right'}}>{title}</div>
    <Row style={{ width: '50%'}}>
      <Progress
        showInfo={false}
        percent={percent}
        strokeWidth={4}
        status='success'
        trailColor='#e5e5e5'
      />
    </Row>
  </Row>
)