import {
  React,
  useEffect,
  useMemo,
} from 'react';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Descriptions } from 'antd';
import { categoryMap, defaultCostcodeHeaders, defaultEstimateHeaders, parseDate } from './ProjectBudgetTrackingImportHelpers';
import ProjectBudgetTrackingUnphased from '../ProjectBudgetTrackingUnphased';
import ProjectBudgetTrackingPhases from '../ProjectBudgetTrackingPhases';

function ProjectBudgetTrackingImportPreview({
  divisionId,
  sheetJSONs,
  parsedData,
  setParsedData,
  estimateSheet,
  costcodeSheet,
  headers,
}) {
  const categories = useSelector((state) => state.costcodes.categories);

  const { phaseCodes = [], unphasedCostcodes = [], estimates = {} } = parsedData || {};

  useEffect(() => {
    // Parse out estimate and costcode data from sheet
    if (sheetJSONs && headers) {
      const estimateRows = sheetJSONs[estimateSheet] ?? [];
      const costcodeRows = sheetJSONs[costcodeSheet] ?? [];

      let hasDates = false;
      // Get estimate data
      const estimateData = {};
      if (estimateRows.length) {
        // Retrieve data from mapped headers
        defaultEstimateHeaders.forEach(({ key: estimateKey }) => {
          const headerKey = headers[estimateKey];
          estimateData[estimateKey] = estimateRows[0][headerKey] || null;
        });
        const { startDate, endDate } = estimateData;
        if (startDate || endDate) {
          hasDates = true;
        }
        estimateData.startDate = parseDate(startDate);
        estimateData.endDate = parseDate(endDate);
      }

      // Get Costcode Data
      const phaseMap = {};
      const unphased = [];
      const idxBase = DateTime.local().toMillis();
      costcodeRows.forEach((row, idx) => {
        const costcode = {
          id: idxBase + idx,
          active: 1,
          divisionId,
          isNew: true,
        };

        // Retrieve data from mapped headers
        defaultCostcodeHeaders.forEach(({ key: costcodeKey }) => {
          const headerKey = headers[costcodeKey];
          costcode[costcodeKey] = row[headerKey] || null;
        });

        const {
          name,
          code,
          category,
          startDate,
          endDate,
          phase,
          description,
        } = costcode;

        // Don't include if invalid
        if (!name || !code || !category || !description) {
          return;
        }

        // parse to string if number
        costcode.name = costcode.name.toString();
        costcode.description = costcode.description.toString();
        costcode.code = costcode.code.toString();

        // Parse category
        const parsedCategoryName = categoryMap[category.toLowerCase()];
        const { id: categoryId } = categories[parsedCategoryName] || {};
        if (!categoryId) {
          return;
        }
        delete costcode.category;
        costcode.categoryId = categoryId;

        // Cleanup invalid fields depending on category
        if (parsedCategoryName === 'Labor') {
          delete costcode.estimatedQuantity;
          delete costcode.units;
        }
        if (parsedCategoryName === 'Material') {
          delete costcode.hours;
        }
        if (parsedCategoryName === 'Overhead') {
          delete costcode.estimatedQuantity;
          delete costcode.units;
        }
        if (parsedCategoryName === 'Equipment') {
          delete costcode.estimatedQuantity;
          delete costcode.units;
        }

        // Parse dates
        if (startDate || endDate) {
          hasDates = true;
        }
        costcode.startDate = parseDate(startDate);
        costcode.endDate = parseDate(endDate);

        // Parse phases
        if (phase) {
          costcode.phased = true;
          delete costcode.phase;

          if (!phaseMap[phase]) {
            phaseMap[phase] = {
              costcodes: [],
              name: phase.toString(),
              description: ' ',
            };
          }
          phaseMap[phase].costcodes.push(costcode);
        } else {
          unphased.push(costcode);
        }
      });

      setParsedData({
        phaseCodes: Object.values(phaseMap),
        unphasedCostcodes: unphased,
        estimates: estimateData,
        hasDates,
      });
    } else {
      setParsedData({});
    }
  }, [divisionId, sheetJSONs, estimateSheet, costcodeSheet, headers]);

  const estimatesSection = useMemo(() => (
    <Descriptions title="Estimates">
      {defaultEstimateHeaders.map((defaultHeader) => (
        <Descriptions.Item label={defaultHeader.title} key={defaultHeader.key}>
          {
            (['startDate', 'endDate'].includes(defaultHeader.key) && estimates[defaultHeader.key])
              ? DateTime.fromMillis(estimates[defaultHeader.key]).toLocaleString(DateTime.DATE_FULL)
              : estimates[defaultHeader.key]
          }
        </Descriptions.Item>
      ))}
    </Descriptions>
  ), [estimates]);

  return (
    <>
      {estimatesSection}
      <ProjectBudgetTrackingUnphased
        categories={categories}
        unphasedCostcodes={unphasedCostcodes}
      />
      <ProjectBudgetTrackingPhases
        dataSource={phaseCodes}
        categories={categories}
      />
    </>
  );
}

ProjectBudgetTrackingImportPreview.propTypes = {
  divisionId: PropTypes.string.isRequired,
  parsedData: PropTypes.object.isRequired,
  setParsedData: PropTypes.func.isRequired,
  sheetJSONs: PropTypes.object,
  estimateSheet: PropTypes.string,
  costcodeSheet: PropTypes.string,
  headers: PropTypes.object,
};

ProjectBudgetTrackingImportPreview.defaultProps = {
  sheetJSONs: null,
  estimateSheet: null,
  costcodeSheet: null,
  headers: null,
};

export default ProjectBudgetTrackingImportPreview;
