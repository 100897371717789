import React from 'react';
import { Row } from 'antd';
import { DateTime } from 'luxon';

import LiveFeedCard from './LiveFeedCard';

export default ({
  dates = [DateTime.local().minus({ day: 14 }),DateTime.local()],
  onClick,
}) => {
  const [startDate,endDate] = dates;
  
  const start = startDate.toLocaleString(DateTime.DATE_MED);
  const end = endDate.toLocaleString(DateTime.DATE_MED);

  return (
    <LiveFeedCard style={{ maxWidth: 210, padding: 5, }} onClick={onClick}>
      <Row style={{
        width: '100%',
        textAlign:'center'
      }} justify='center'>
        {`${start} - ${end}`}
      </Row>
    </LiveFeedCard>
  );
}
