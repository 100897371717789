import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Select } from 'antd';

import DisplayText from '../common/text/DisplayText';
import { getIdMap } from '../helpers/helpers';

const getOpt = (item) => ({ label: item.name, value: item.id });
const getName = (id, map = {}) => {
  const {
    [id]: {
      name,
    } = {},
  } = map;
  return name;
}
export default ({
  isDisplay,
  value = {},
  onChange,
}) => {
  const {
    unions = [],
    locals = [],
    classes = [],
  } = useSelector(state => state.unions);
  const onSelect = useCallback((key) => (id) => {
    if (key === 'unionId') {
      return onChange({ unionId: id });
    }
    if (key === 'localId') {
      return onChange({ unionId: value.unionId, localId: id });
    }
    const newData = {
      ...value,
      [key]: id,
    };
    onChange(newData);
  },[value]);

  const unionMap = useMemo(() => getIdMap(unions),[unions]);
  const localMap = useMemo(() => getIdMap(locals),[locals]);
  const classMap = useMemo(() => getIdMap(classes),[classes]);
  
  const unionName = useMemo(() => getName(value.unionId, unionMap),[value, unionMap]);
  const localName = useMemo(() => getName(value.localId, localMap),[value, localMap]);
  const className = useMemo(() => getName(value.classId, classMap),[value, classMap]);

  const unionOptions = useMemo(() => unions.map(getOpt),[unions])
  const relevantLocals = useMemo(() => (
    value.unionId
    ? locals.filter((local) => local.unionId === value.unionId).map(getOpt)
    : []
  ),[value, locals]);

  const relevantClasses = useMemo(() => (
    value.localId
    ? classes.filter((unionClass) => unionClass.localId === value.localId).map(getOpt)
    : []
  ),[value, classes]);
  
  return (
    <Row justify='space-between' align='middle' gutter={10} style={{ paddingTop: 5 }}>
      <Col span={8}>
        {
          isDisplay 
          ? <DisplayText title={unionName}/>
          :
          <Select
            options={unionOptions}
            onChange={onSelect('unionId')}
            value={value.unionId}
            allowClear
            placeholder='Select Union'
          />
        }
      </Col>
      <Col span={8}>
        {
          isDisplay 
          ? <DisplayText title={localName}/>
          :
          <Select
            options={relevantLocals}
            disabled={!value.unionId}
            onChange={onSelect('localId')}
            value={value.localId}
            allowClear
            placeholder='Select Local'
          />
        }
      </Col>
      <Col span={8}>
        {
          isDisplay 
          ? <DisplayText title={className}/>
          : 
          <Select
            options={relevantClasses}
            disabled={!value.localId}
            onChange={onSelect('classId')}
            value={value.classId}
            allowClear
            placeholder='Select Classification'
          />
        }
      </Col>
    </Row>
  )
}