import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  ARCHIVE_CUSTOMERS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  ADD_CUSTOMER_NOTE,
  GET_CUSTOMER_NOTES,
  GET_CUSTOMER_CLIENT_COMMS,
  ADD_CUSTOMER_CLIENT_COMMS,
  GET_CUSTOMER_CLIENT_COMMS_UNREAD,
  MARK_CUSTOMER_CLIENT_COMM_AS_READ,
  ADD_CUSTOMER_CLIENT_UNREAD_COMM,
  GET_CLIENT_BROADCASTS,
  GET_CUSTOMER_LABELS,
  GET_QUICKBOOKS_CUSTOMERS,
} from '../../../state/actionTypes';

import { getIdMap } from '../../../helpers/helpers';
import { getLabelsState, stateUpdateLabels } from '../../../helpers/labels';

const initialState = {
  customers: {},
  notes: {},
  clientComms: {},
  clientCommBroadcasts: [],
  unreadClientComms: [],
  customerToLabel: {},
  labelToCustomer: {},
  quickBooksCustomers: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_CUSTOMERS: {
      const {
        payload: {
          customers = [],
        } = {},
      } = action;
      return {
        ...state,
        customers: getIdMap(customers),
      };
    }
    case CREATE_CUSTOMER: {
      const {
        payload: {
          customer = {},
          labels = [],
        } = {},
      } = action;
      const {
        customers: stateCustomers = {},
        customerToLabel: stateCustomerToLabel = {},
        labelToCustomer: stateLabelToCustomer = {},
      } = state;
      const existingCustomers = { ...stateCustomers };
      existingCustomers[customer.id] = { ...customer, active: 1 };

      const {
        newItemToLabel,
        newLabelToItem,
      } = stateUpdateLabels({
        id: customer.id,
        stateItemToLabel: stateCustomerToLabel,
        stateLabelToItem: stateLabelToCustomer,
        addedLabels: labels,
      });

      return {
        ...state,
        customers: existingCustomers,
        customerToLabel: newItemToLabel,
        labelToCustomer: newLabelToItem,
      };
    }
    case ARCHIVE_CUSTOMERS: {
      const {
        payload: {
          id,
          active,
        } = {},
      } = action;
      const { customers: stateCustomers = {} } = state;
      const existingCustomers = { ...stateCustomers };
      existingCustomers[id] = {
        ...existingCustomers[id],
        active: active ? 1 : 0,
      };
      return {
        ...state,
        customers: existingCustomers,
      };
    }
    case UPDATE_CUSTOMER: {
      const {
        payload: {
          id,
          newData: customer = {},
          removedLabels = [],
          addedLabels = [],
        } = {},
      } = action;
      const {
        customers: stateCustomers = {},
        customerToLabel: stateCustomerToLabel = {},
        labelToCustomer: stateLabelToCustomer = {},
      } = state;

      const existingCustomers = { ...stateCustomers };
      const updateData = { ...customer };

      existingCustomers[id] = {
        ...existingCustomers[id],
        ...updateData,
      };
      if (updateData.customBillingRate) {
        delete existingCustomers[id].billingRateId;
      } else if (updateData.billingRateId) {
        delete existingCustomers[id].customBillingRate;
      }

      const {
        newItemToLabel,
        newLabelToItem,
      } = stateUpdateLabels({
        id,
        stateItemToLabel: stateCustomerToLabel,
        stateLabelToItem: stateLabelToCustomer,
        addedLabels,
        removedLabels,
      });

      return {
        ...state,
        customers: existingCustomers,
        customerToLabel: newItemToLabel,
        labelToCustomer: newLabelToItem,
      };
    }
    case DELETE_CUSTOMER: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { customers: stateCustomers = {} } = state;
      const existingCustomers = { ...stateCustomers };
      delete existingCustomers[id];
      return {
        ...state,
        customers: existingCustomers,
      };
    }
    case GET_CUSTOMER_NOTES:
    case GET_CUSTOMER_CLIENT_COMMS: {
      const stateKey = action.type === GET_CUSTOMER_NOTES ? 'notes' : 'clientComms';
      const {
        payload: {
          id,
          data: notes = [],
        } = {},
      } = action;
      const { [stateKey]: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      existingNotes[id] = notes;
      existingNotes[id].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        [stateKey]: existingNotes,
      };
    }
    case GET_CLIENT_BROADCASTS: {
      const {
        payload: broadcasts = [],
      } = action;
      return {
        ...state,
        clientCommBroadcasts: broadcasts,
      };
    }
    case ADD_CUSTOMER_NOTE: {
      const {
        payload = {},
      } = action;
      const { customerId } = payload;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [customerId]: existingCustomerNotes = [],
      } = existingNotes;
      existingNotes[customerId] = existingCustomerNotes.concat([payload]);
      existingNotes[customerId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    case ADD_CUSTOMER_CLIENT_COMMS: {
      const {
        payload: newNotes = [],
      } = action;
      const {
        clientComms: stateNotes = {},
        clientCommBroadcasts: oldBroadcasts = [],
      } = state;
      const existingNotes = { ...stateNotes };
      const newBroadcasts = [...oldBroadcasts];
      newNotes.forEach((note) => {
        const { customerId, isBroadcast } = note;
        if (isBroadcast) {
          newBroadcasts.push(note);
        } else if (customerId in existingNotes) {
          existingNotes[customerId].push(note);
          existingNotes[customerId].sort((a, b) => b.timestamp - a.timestamp);
        }
      });
      return {
        ...state,
        clientComms: existingNotes,
        clientCommBroadcasts: newBroadcasts,
      };
    }
    case GET_CUSTOMER_CLIENT_COMMS_UNREAD: {
      const {
        payload: { comms = [] } = {},
      } = action;
      return {
        ...state,
        unreadClientComms: comms,
      };
    }
    case ADD_CUSTOMER_CLIENT_UNREAD_COMM: {
      const {
        payload: note = {},
      } = action;
      const { unreadClientComms: oldComms = [] } = state;
      const commIdSet = new Set(oldComms.map((comm) => comm.id));
      if (commIdSet.has(note.id)) return state;
      return {
        ...state,
        unreadClientComms: oldComms.concat([note]),
      };
    }
    case MARK_CUSTOMER_CLIENT_COMM_AS_READ: {
      const {
        payload: {
          ids = [],
          customerId,
          isExternal,
        } = {},
      } = action;
      if (isExternal) return state;
      const readSet = new Set(ids);
      const {
        unreadClientComms: oldUnread = [],
        clientComms: oldComms = {},
      } = state;
      const newComms = { ...oldComms };
      const {
        [customerId]: oldCustomerComms = [],
      } = newComms;
      newComms[customerId] = oldCustomerComms.map((note) => {
        if (readSet.has(note.id)) {
          return { ...note, userRead: true };
        }
        return note;
      });
      return {
        ...state,
        unreadClientComms: oldUnread.filter((note) => !readSet.has(note.id)),
        clientComms: newComms,
      };
    }
    case GET_CUSTOMER_LABELS: {
      const {
        payload: {
          customerLabels = [],
        } = {},
      } = action;
      const {
        itemToLabel,
        labelToItem,
      } = getLabelsState(customerLabels, 'customerId');
      return {
        ...state,
        customerToLabel: itemToLabel,
        labelToCustomer: labelToItem,
      };
    }
    case GET_QUICKBOOKS_CUSTOMERS: {
      const {
        payload: {
          data: quickBooksCustomers,
        } = {},
      } = action;
      return {
        ...state,
        quickBooksCustomers,
      };
    }
    default:
      return state;
  }
};
