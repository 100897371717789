import React, { useSelector } from 'react-redux';
import { Col, Row } from 'antd';
import PropTypes from 'prop-types';
import OnTraccrButton from '../buttons/OnTraccrButton';
import { getButtonStyle, smallTimerStyle, titleStyle } from './ClockCommon';
import ClockText from './ClockText';
import Permissions from '../../auth/Permissions';

const getBreakButtonText = ({ switchStarted, isOnBreak }) => {
  if (switchStarted) return 'Cancel';
  if (isOnBreak) return 'End Break';
  return 'Take Break';
};

export default function ClockButtons({
  running,
  switchStarted,
  isOnBreak,
  onManualEntry,
  breakTime,
  onBreak,
  onSwitch,
  onSwitchCancel,
}) {
  const settings = useSelector((state) => state.settings);
  const {
    company: {
      settings: {
        autoBreakEnabled = false,
        enableBreaking = true,
      } = {},
    } = {},
  } = settings;

  const isAutoBreakRoleEnabled = (
    autoBreakEnabled && Permissions.has('AUTOMATIC_BREAKS')
  );

  if (!running && !isOnBreak) {
    return (
      <Row type="flex" justify="center" gutter={16} style={{ width: '100%' }}>
        {onManualEntry && (
          <OnTraccrButton
            title="Manual Entry"
            titleStyle={titleStyle}
            type="cancel"
            style={getButtonStyle()}
            onClick={onManualEntry}
          />
        )}
      </Row>
    );
  }

  return (
    <Row type="flex" justify="center" gutter={16} style={{ width: '100%' }}>
      {!isAutoBreakRoleEnabled && enableBreaking
        && !!(switchStarted ? onSwitchCancel : onBreak) && (
          <Col>
            <Row style={{ width: '100%' }}>
              {isOnBreak ? (
                <ClockText
                  style={smallTimerStyle}
                  runtime={breakTime}
                />
              ) : <div style={smallTimerStyle} />}
            </Row>
            <Row>
              <OnTraccrButton
                title={getBreakButtonText({ switchStarted, isOnBreak })}
                onClick={switchStarted ? onSwitchCancel : onBreak}
                type={isOnBreak ? 'secondary' : 'cancel'}
                style={getButtonStyle()}
                titleStyle={titleStyle}
              />
            </Row>
          </Col>
      )}
      {
        !!onSwitch
        && (
          <Col>
            <Row style={{ width: '100%' }}>
              <div style={smallTimerStyle} />
            </Row>
            <Row>
              <OnTraccrButton
                title={switchStarted ? 'Confirm' : 'Switch'}
                onClick={onSwitch}
                type={switchStarted ? 'primary' : 'cancel'}
                style={getButtonStyle()}
                titleStyle={titleStyle}
              />
            </Row>
          </Col>
        )
}
    </Row>
  );
}

ClockButtons.propTypes = {
  running: PropTypes.bool,
  switchStarted: PropTypes.bool,
  isOnBreak: PropTypes.bool,
  onManualEntry: PropTypes.func.isRequired,
  breakTime: PropTypes.number,
  onBreak: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onSwitchCancel: PropTypes.func.isRequired,
};

ClockButtons.defaultProps = {
  running: false,
  switchStarted: false,
  isOnBreak: false,
  breakTime: 0,
};
