import React from 'react';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';


export default ({
  onCancel,
  onSave,
}) => (
  CustomConfirmModal({
    title: 'Save changes?',
    content: (
      <div>
        <span>Click <b style={{ fontWeight: 1000, color: 'black' }}>Yes</b> to save changes to the card.</span>
      </div>
    ),
    okText: 'Yes',
    cancelText: 'No',
    onCancel,
    onOk:onSave,
  })
)