/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState,
  useRef,
  useCallback,
  useEffect,
} from 'react';
import {
  DatePicker,
  Popconfirm,
  Select,
  notification,
} from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';

import Permissions from '../../auth/Permissions';
import { updateGanttScheduleRow } from '../state/schedule.actions';
import { getPopupRef, isNullOrUndefined } from '../../helpers/helpers';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
// eslint-disable-next-line import/no-cycle
import {
  areDatesEqual,
  calculateDuration,
  calculateNewEndDate,
} from './ganttScheduleHelpers';

const simpleEquality = (a, b) => a === b;
/**
 *
 * @param {*} props
 * @param {string} props.type - Type of input to render
 * @param {string} props.title - Title of popover
 * @param {string} props.value - Initial value of input
 * @param {function} props.displayFormatter - Function to format value for display
 * @param {object} props.inputStyle - Style object to apply to input
 * @param {object} props.task - Task object
 * @param {string} props.property - Property of task to update
 * @param {function} props.areEqual - Function to determine if input value is equal to initial value
 * @param {array} props.selectSource - Array of objects to use as select options
 * @param {function} props.selectSourceFilter - Function to filter select options
 * @param {string} props.defaultValue - Default value to use if input is empty
 * @param {array} props.rules - Array of validation rules
 * @param {string} props.width - Width of input
 * @param {boolean} props.readOnly - Whether input is read only
 * @param {string} props.activeTab - Active tab of schedule
 * @returns
 */
function GanttScheduleTableInput(props) {
  const {
    type = 'text',
    title,
    value: initialValue,
    displayFormatter,
    inputStyle,
    task,
    property,
    areEqual = simpleEquality,
    selectSource,
    selectSourceFilter,
    defaultValue,
    rules = [],
    width: inputWidth,
    readOnly = false,
    activeTab,
  } = props;

  const dispatch = useDispatch();
  const {
    ganttScheduleRows: rows,
    ganttScheduleSettings: settings,
    ganttScheduleHolidays: holidays,
  } = useSelector((state) => state.schedule);
  const popconfirmRef = useRef(null);
  const containerRef = useRef(null);
  const [showPopover, setShowPopover] = useState(false);
  const [showDisplay, setShowDisplay] = useState(true);
  const [inputValue, setInputValue] = useState(initialValue);
  const [inputOpen, setInputOpen] = useState(false);

  const isReadOnly = !Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE') || readOnly;

  useEffect(() => {
    if (!showDisplay) {
      const isInputDirty = !areEqual(inputValue, initialValue);
      setShowPopover(isInputDirty);
    }
  }, [showDisplay, inputValue, initialValue, areEqual]);

  useEffect(() => {
    setInputValue(initialValue);
  }, [initialValue]);

  const switchToDisplayMode = () => {
    setShowDisplay(true);
    setShowPopover(false);
  };

  const onCancel = () => {
    switchToDisplayMode();
    setInputValue(initialValue);
  };

  const onConfirm = async () => {
    if (rules && rules.length > 0) {
      const errors = rules.filter((rule) => !rule.validator(inputValue));
      if (errors.length > 0) {
        notification.error({
          message: 'Error',
          description: errors[0].message,
        });
        return;
      }
    }

    const currentRecord = rows.find((row) => row.id === task.id);
    const updatedValues = {
      [property]: isNullOrUndefined(inputValue) && defaultValue ? defaultValue : inputValue,
    };

    if (type === 'date') {
      updatedValues[property] = inputValue.startOf('day').utcOffset(0).valueOf() / 1000;
    }

    if (property === 'startDate' || property === 'duration') {
      updatedValues.endDate = calculateNewEndDate(
        updatedValues.startDate || currentRecord.startDate,
        updatedValues.duration || currentRecord.duration,
        settings.workingDays,
        holidays,
      ).toSeconds();
    }

    if (property === 'endDate') {
      updatedValues.endDate = DateTime
        .fromSeconds(updatedValues.endDate, { zone: 'utc' })
        .plus({ hours: 23, minute: 59 })
        .toSeconds();
      updatedValues.duration = calculateDuration(
        currentRecord.startDate,
        updatedValues.endDate,
        settings.workingDays,
        holidays,
      );
    }

    const updatePayload = {
      name: currentRecord.name,
      color: currentRecord.color,
      duration: currentRecord.duration,
      startDate: currentRecord.startDate,
      endDate: currentRecord.endDate,
      type: 'task',
      priority: 0,
      progress: currentRecord.progress,
      labelId: currentRecord.labelId,
      expectedNumberOfUsers: currentRecord.expectedNumberOfUsers,
      users: currentRecord.users,
      costCodes: currentRecord.costCodes.map((costCode) => {
        const [phaseId, costCodeId] = costCode.split('.');
        return {
          phaseId,
          costCodeId,
        };
      }),
      equipment: currentRecord.equipment,
      materials: currentRecord.materials,
      phaseId: currentRecord.phaseId,
      actualStartDate: currentRecord.actualStartDate,
      actualEndDate: currentRecord.actualEndDate,
      parentDependencies: currentRecord.parentDependencies,
      childDependencies: currentRecord.childDependencies,
      ...updatedValues,
    };

    const result = await dispatch(
      updateGanttScheduleRow(
        currentRecord.ganttScheduleId,
        currentRecord.id,
        updatePayload,
      ),
    );

    if (result) {
      switchToDisplayMode();
    }
  };

  const onChange = (e) => {
    if (type === 'text') {
      setInputValue(e.target.value);
    } else {
      setInputValue(e);
    }

    setInputOpen(false);
  };

  const InputComponent = useCallback((inputProps) => {
    switch (type) {
      case 'number':
        return <OnTraccrNumberInput {...inputProps} />;
      case 'date':
        return <DatePicker {...inputProps} />;
      case 'select': {
        const selectOptions = [];
        selectSource.forEach((data) => {
          if (!selectSourceFilter || selectSourceFilter(data)) {
            selectOptions.push({
              label: data.name,
              value: data.id,
            });
          }
        });

        return <Select {...inputProps} options={selectOptions} />;
      }
      default:
        return <OnTraccrTextInput {...inputProps} />;
    }
  }, [type, selectSource, selectSourceFilter]);

  const onDisplayClick = () => {
    if (!isReadOnly) {
      setShowDisplay(false);
      setInputOpen(true);
    }
  };

  const onKeyDown = (e) => {
    // Prevent arrow keys from moving gantt chart while focused in input
    if (!showDisplay) {
      e.stopPropagation();
    }

    // Close popover on escape
    if (e.key === 'Escape') {
      onCancel();
    }
  };

  const onBlur = (e) => {
    const popupRef = getPopupRef(popconfirmRef);
    if (popupRef && popupRef.current && popupRef.current.contains(e.relatedTarget)) {
      return;
    }

    const notificationContainer = document.querySelector('.ant-notification');
    if (notificationContainer && notificationContainer.contains(e.relatedTarget)) {
      return;
    }

    onCancel();
  };

  const onFocus = () => {
    setInputOpen(true);
  };

  const containerStyle = showDisplay
    ? {}
    : {
      position: 'absolute',
      transform: activeTab === 'list' ? 'translate(-10px, -15px)' : 'translateX(-10px)',
      maxWidth: inputWidth || 250,
      minWidth: inputWidth || 250,
      marginLeft: inputWidth ? 10 : 0,
      zIndex: 1,
    };

  return (
    <div
      ref={containerRef}
      onKeyDown={onKeyDown}
      style={containerStyle}
    >
      <Popconfirm
        visible={showPopover}
        title={title}
        okText="Update"
        onConfirm={onConfirm}
        onCancel={onCancel}
        ref={popconfirmRef}
        placement="left"
      >
        {
          showDisplay
            ? (
              <div
                onClick={onDisplayClick}
                style={{
                  height: 30,
                  display: 'table-cell',
                  verticalAlign: 'middle',
                  maxWidth: inputWidth || 250,
                  minWidth: inputWidth || 250,
                  whiteSpace: 'nowrap',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                }}
              >
                {displayFormatter ? displayFormatter(initialValue) : initialValue}
              </div>
            )
            : (
              <InputComponent
                {...props}
                onChange={onChange}
                onBlur={onBlur}
                onFocus={onFocus}
                onClick={onFocus}
                value={inputValue}
                style={{
                  borderRadius: 6,
                  borderColor: '#323232',
                  height: 30,
                  paddingLeft: 11,
                  width: '100%',
                  ...inputStyle,
                }}
                onPressEnter={onConfirm}
                open={inputOpen}
                autoFocus
                suffixIcon={null}
              />
            )
        }
      </Popconfirm>
    </div>
  );
}

const MemoizedGanttScheduleTableInputComponent = React.memo(
  GanttScheduleTableInput,
  (prevProps, nextProps) => {
    if (prevProps.type === 'date') {
      return areDatesEqual(prevProps.value, nextProps.value);
    }
    return prevProps.value === nextProps.value;
  },
);

export default MemoizedGanttScheduleTableInputComponent;

GanttScheduleTableInput.propTypes = {
  type: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  displayFormatter: PropTypes.func,
  inputStyle: PropTypes.shape({}),
  task: PropTypes.shape({
    id: PropTypes.string,
    projectId: PropTypes.string,
    name: PropTypes.string,
    color: PropTypes.string,
    duration: PropTypes.number,
    realStartDate: PropTypes.number,
    realEndDate: PropTypes.number,
    progress: PropTypes.number,
    labelId: PropTypes.string,
    expectedNumberOfUsers: PropTypes.number,
    users: PropTypes.arrayOf(PropTypes.string),
    costCodes: PropTypes.arrayOf(PropTypes.string),
    equipment: PropTypes.arrayOf(PropTypes.string),
    materials: PropTypes.arrayOf(PropTypes.string),
    phaseId: PropTypes.string,
    actualStartDate: PropTypes.number,
    actualEndDate: PropTypes.number,
    parentDependencies: PropTypes.arrayOf(PropTypes.string),
    childDependencies: PropTypes.arrayOf(PropTypes.string),
    settings: PropTypes.shape({
      workingDays: PropTypes.arrayOf(PropTypes.number),
    }),
    holidays: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  areEqual: PropTypes.func,
  property: PropTypes.string,
  selectSource: PropTypes.arrayOf(PropTypes.shape({})),
  selectSourceFilter: PropTypes.func,
  defaultValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.any,
  ]),
  rules: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
    validator: PropTypes.func,
  })),
  width: PropTypes.number,
  settings: PropTypes.shape({
    workingDays: PropTypes.arrayOf(PropTypes.number),
  }),
  holidays: PropTypes.arrayOf(PropTypes.string),
  readOnly: PropTypes.bool,
  activeTab: PropTypes.string,
};

GanttScheduleTableInput.defaultProps = {
  type: 'text',
  title: '',
  value: '',
  displayFormatter: null,
  inputStyle: {},
  areEqual: simpleEquality,
  selectSource: [],
  selectSourceFilter: null,
  defaultValue: null,
  rules: [],
  width: null,
  settings: null,
  holidays: [],
  readOnly: false,
  property: null,
  activeTab: null,
};
