import React from 'react';
import { Row, } from  'antd';
import colors from '../../constants/Colors';

export default ({
  children,
  style,
  containerStyle={},
  darkMode = false,
}) => (
  <Row style={{
    height:'100%',
    width:'100%',
    ...containerStyle,
    }}>
    <div 
      className='bordered-container-body'
      style={{
        backgroundColor:darkMode ? colors.ONTRACCR_GRAY : 'white',
        ...style,
      }}>
      {children}
    </div>
  </Row>
);
