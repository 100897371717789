import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Input, Row, Col, Select } from 'antd';
import { Trie } from 'ontraccr-common';

import HoverHelp from '../../common/HoverHelp';
import FilePermsUserList from '../../files/FilePermsUserList';

import BoardPermissionsSelector from './BoardPermissionSelector';

import {
  READ_ASSIGNED_PERM,
  WRITE_ALL_PERM,
  WRITE_ASSIGNED_PERM,
} from './boardPermissions.constants';

import sortByString, { getIdMap } from '../../helpers/helpers';

const sortByName = sortByString('name');

export default ({
  value = {},
  onChange,
  divisions,
  creatorId,
  visible,
}) => {
  const users = useSelector(state => state.users.users);
  const userTrie = useSelector(state => state.users.userTrie);
  const company = useSelector(state => state.settings.company);
  const userDivisions = useSelector(state => state.users.userDivisions);

  const [searchTerm, setSearchTerm] = useState();

  useEffect(() => {
    if (!visible) setSearchTerm();
  },[visible]);

  const {
    isPublic,
    users: permissionUsers = [],
  } = value;

  const permMap = useMemo(() => getIdMap(permissionUsers, 'userId'), [value]);

  const selected = useMemo(() => {
    // If public, show all as selected
    const allUsers = users.filter(user => {
      const { id, active } = user;
      const {
        [id]: usersDivs = [],
      } = userDivisions;
      const division = divisions
        ? divisions.some((divisionId) => usersDivs.includes(divisionId))
        : true;
      return active && division;
    }).map((user) => user.id);
    if (isPublic) return [allUsers];
    // Otherwise show selected and default
    const { userId: ownerId } = company || {};
    const newSelected = permissionUsers.map((user) => user.userId);
    if (ownerId && !permMap[ownerId]) newSelected.push(ownerId);
    if (creatorId && !permMap[creatorId] && creatorId !== ownerId) newSelected.push(creatorId);
    return [newSelected];
  },[company, permMap, creatorId]);

  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value: newSearch,
      } = {},
    } = e;
    setSearchTerm(newSearch);
  },[]);

  const onCheckChanged = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    onChange({
      ...value,
      isPublic: checked,
    });
  },[onChange, value]);

  const onSelectChange = useCallback(([selectedKeys]) => {
    const selectedUsers = []
    selectedKeys.forEach((key) => {
      if (permMap[key]) {
        selectedUsers.push(permMap[key]);
      } else {
        selectedUsers.push({
          userId: key,
          formPermission: false,
        })
      }
    })
    onChange({
      ...value,
      users: selectedUsers,
    });
  },[onChange, permMap]);

  const onPermTypeChange = useCallback(({
    user,
    permKey,
    value: newType
  }) => {
    const { id } = user;
    const entryDoesntExist = !permMap[id];
    if (!permMap[id] && !isPublic) return;
    /*
      Can toggle user edit perms in public mode
      when they may not have a user entry yet
    */
    const updatedUsers = permissionUsers.reduce((acc, permUser) => {
      const {
        userId,
      } = permUser;
      if (userId !== id) {
        acc.push(permUser);
        return acc;
      };
      const isRead = permKey === 'readPerms';
      if (isRead && !newType) {
        // If user gets all read removed
        // Remove them entirely
        return acc;
      }
      const updated = {
        ...permUser,
        [permKey]: newType,
      }
      if (isRead
          && newType === READ_ASSIGNED_PERM
          && updated.writePerms === WRITE_ALL_PERM) {
        // Cant write all if only read assigned
        updated.writePerms = WRITE_ASSIGNED_PERM;
      }
      if (updated.writePerms || updated.readPerms) {
        // If both perms are removed, then remove user entirely
        acc.push(updated);
      }
      return acc;
    }, []);
    if (entryDoesntExist) {
      updatedUsers.push({
        userId: id,
        formPermission: false,
        readPerms: 'readAll',
        writePerms: newType,
      });
    }

    onChange({
      ...value,
      users: updatedUsers,
    });
  }, [onChange, permissionUsers, permMap, isPublic]);

  const onFormPermissionChange = useCallback((user) => () => {
    const { id } = user;
    let updatedUsers = []
    if (permMap[id]) {
      updatedUsers = permissionUsers.map((permUser) => {
        const {
          userId,
          formPermission
        } = permUser;
        if (userId === id) {
          const updated = {
            ...permUser,
            formPermission: !formPermission,
          }
          return updated;
        } else {
          return permUser;
        }
      });
    } else {
      updatedUsers = [...permissionUsers];
      updatedUsers.push({
        userId: id,
        formPermission: true,
      });
    }
  
    onChange({
      ...value,
      users: updatedUsers,
    });
  }, [onChange, permMap]);

  const releventData = useMemo(() => {
    const ourUsers = searchTerm ? Trie.searchTrie(userTrie,searchTerm) : users.filter(user => user.active);
    const selectedUsers = [];
    const unselectedUsers = [];
    const owner = [];
    ourUsers.forEach((user) => {
      if((company && company.userId === user.id) || (user.id === creatorId)) {
        owner.push(user);
      }else if(permMap[user.id]) {
        selectedUsers.push(user);
      } else {
        unselectedUsers.push(user);
      }
    });
    selectedUsers.sort(sortByName);
    unselectedUsers.sort(sortByName);
    return owner.concat(selectedUsers).concat(unselectedUsers);
  },[searchTerm,users,userTrie,permMap,company, creatorId]);
  
  const permissionsColumns = useMemo(() => [
    {
      title: 'View',
      dataIndex: 'readPerms',
      key: 'readPerms',
      width: 160,
      render:(_,user) => (
        <BoardPermissionsSelector
          user={user}
          creatorId={creatorId}
          isPublic={isPublic}
          permMap={permMap}
          company={company}
          permKey="readPerms"
          onChange={onPermTypeChange}
        />
      ),
    },
    {
      title: 'Edit',
      dataIndex: 'writePerms',
      key: 'writePerms',
      width: 160,
      render:(_,user) => (
        <BoardPermissionsSelector
          user={user}
          creatorId={creatorId}
          isPublic={isPublic}
          permMap={permMap}
          company={company}
          permKey="writePerms"
          onChange={onPermTypeChange}
        />
      ),
    },
    {
      title: 'Forms',
      dataIndex: 'formPermission',
      key: 'formPermission',
      render:(_,user) => {
        const {
          id,
        } = user;
        const permUser = permMap[id] ?? {};
        const {
          formPermission
        } = permUser;
        // Disable changing permissions for owner
        const isOwnerOrCreator = (company && company.userId === id) || (id === creatorId)
        const val = isOwnerOrCreator || !!formPermission;
        const disabled = isOwnerOrCreator || (!isPublic && (!permMap[id]));
        return (
          <Checkbox
            checked={val}
            onChange={onFormPermissionChange(user)}
            disabled={disabled}
            style={{ marginLeft: 10 }}
          />
        );
      },
    },
  ],[
    permMap,
    creatorId,
    company,
    isPublic,
    onFormPermissionChange,
    onPermTypeChange,
  ])

  return (
    <>
      <Row justify='start' align='middle' gutter={10} style={{ marginBottom: 10, marginTop: 10 }}>
        <Col>
          <Checkbox onChange={onCheckChanged} checked={isPublic}>Public</Checkbox>
        </Col>
        <Col>
          <HoverHelp content={
            <div style={{ maxWidth: 200 }}>
              Public boards can be accessed by all users
            </div>
          }/>
        </Col>
      </Row>
      <Row justify='start' gutter={20}>
        <Col flex='400px'>
          <Input.Search
            type='search'
            disabled={false}
            style={{ width:'100%', }}
            className='searchbar'
            placeholder='Search'
            allowClear
            onChange={onSearchChange}
            value={searchTerm}
          />
        </Col>
      </Row>
      <FilePermsUserList
        divisions={divisions}
        onSelect={onSelectChange}
        selected={selected}
        data={releventData}
        scroll={{ y: 'calc(100vh - 300px)' }}
        additionalColumns={permissionsColumns}
        disableAll={isPublic}
      />
    </>
  )
}
