import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isMobile } from 'react-device-detect';
import { Row, Col, Select } from 'antd';
import { DownOutlined } from '@ant-design/icons';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import ClientPortalHeaderButton from './ClientPortalHeaderButton';
import { demoCards } from './clientPortal.constants';

import { getTextColor } from '../schedule/scheduleHelpers';

import {
  selectClientPortalCard,
  logoutClient,
} from './state/clientPortal.actions';

export default ({
  activeTab,
  onTabChanged,
  previewMode,
}) => {
  const dispatch = useDispatch();

  const {
    primaryColor,
    accentColor,
    image,
    tagline,
  } = useSelector(state => state.clientPortal.settings);
  const clientCards = useSelector(state => state.clientPortal.cards); 
  const selectedCardId = useSelector(state => state.clientPortal.selectedCardId);
  const clientComms = useSelector(state => state.clientPortal.comms);

  const onTabClicked = useCallback((name) => () => onTabChanged(name),[]);
  const onLogout = useCallback(() => {
    const textColor = getTextColor(accentColor);
    CustomConfirmModal({
      title: 'Logout',
      okText: 'Logout',
      okButtonStyle: {
        borderColor: accentColor,
        backgroundColor: accentColor,
        color: textColor,
      },
      onOk: async () => {
        if (previewMode) return;
        return dispatch(logoutClient());
      }
    });
  },[dispatch, previewMode, accentColor]);

  const onCardSelect = useCallback((cardId) => {
    dispatch(selectClientPortalCard(cardId));
  },[dispatch]);

  const unreadCount = useMemo(() => (
    clientComms.filter((comm) => comm.unread).length
  ),[clientComms]);

  const cards = previewMode ? demoCards : clientCards;

  const cardOpts = useMemo(() => (
    Object.values(cards).map((card) => ({ label: card.title, value: card.id }))
  ),[cards, previewMode]);

  useEffect(() => {
    if (cardOpts.length > 0 && !selectedCardId) {
      const [first] = cardOpts;
      dispatch(selectClientPortalCard(first.value));
    } else if (selectedCardId && !cardOpts.find((card) => card.value === selectedCardId)) {
      dispatch(selectClientPortalCard());
    }
  },[dispatch, selectedCardId, cardOpts]);

  return (
    <>
      <Row id='client-portal-header' style={{ backgroundColor: primaryColor }} justify='space-between' align='middle'>
        <Col>
          <Row justify='start' align='middle' gutter={30}>
            <Col>
              <img 
                alt='Logo'
                src={image} 
                style={{
                  maxWidth: isMobile ? 150 : 200,
                  maxHeight: isMobile ? 75 : 100,
                  width: 'auto',
                  height:'auto',
                  borderRadius:5,
                  backgroundColor: 'white',
              }}/>
            </Col>
            <Col>
              {cardOpts.length > 0 && activeTab == 'home' && <Select
                className='client-portal-card-select'
                style={{
                  color: accentColor,
                }}
                options={cardOpts}
                optionFilterProp='label'
                bordered={false}
                value={selectedCardId}
                onChange={onCardSelect}
                suffixIcon={<DownOutlined style={{ color: accentColor, fontSize: 16, fontWeight: 'bold' }}/>}
                dropdownMatchSelectWidth={false}
              />}
            </Col>
          </Row>
        </Col>
        <Col>
          <Row justify='end' gutter={20}>
            {cardOpts.length > 0 && <Col>
              <ClientPortalHeaderButton
                title='Home'
                active={activeTab === 'home'}
                onClick={onTabClicked('home')}
              />
            </Col>}
            <Col>
              <ClientPortalHeaderButton
                title='Messages'
                active={activeTab === 'comms'}
                onClick={onTabClicked('comms')}
                badgeCount={unreadCount}
              />
            </Col>
            <Col>
              <ClientPortalHeaderButton
                title='Profile'
                active={activeTab === 'profile'}
                onClick={onTabClicked('profile')}
              />
            </Col>
            <Col>
              <ClientPortalHeaderButton
                title='Log Out'
                onClick={onLogout}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      {
        tagline
        ? <div id='client-portal-tagline' style={{ backgroundColor: primaryColor, color: accentColor }}>
          {tagline}
        </div>
        : null
      }
    </>
  )
}