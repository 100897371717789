import {
  GET_ALL_CERTIFICATIONS,
  GET_ALL_CERTIFICATION_FOR_ENTITY,
  GET_CERTIFICATION,
  CREATE_CERTIFICATION,
  UPDATE_CERTIFICATION,
  DELETE_CERTIFICATION,
  GET_CERTIFICATION_CUSTOM_FIELD_TEMPLATE,
  UPDATE_CERTIFICATION_CUSTOM_FIELD_TEMPLATE,
  GET_CERTIFICATION_CUSTOM_DATA,
} from '../../state/actionTypes';

import CertificationService from './certifications.service';

import { decorateFormWithFiles } from '../../forms/formHelpers';

export const getAllCertifications = (entityType) => async (dispatch) => {
  const { data } = await CertificationService.getAllCertifications(entityType);
  if (!data) return false;
  dispatch({
    type: GET_ALL_CERTIFICATIONS,
    payload: {
      data,
      entityType,
    },
  });
  return true;
};

export const getAllCertificationsForEntity = (entityType, id) => async (dispatch) => {
  const { data } = await CertificationService.getAllCertificationsForEntity(entityType, id);
  if (!data) return false;
  dispatch({
    type: GET_ALL_CERTIFICATION_FOR_ENTITY,
    payload: {
      data,
      entityType,
    },
  });
  return true;
};

export const getCertification = (entityType, id, certificationId) => async (dispatch) => {
  const { data } = await CertificationService.getCertification(entityType, id, certificationId);
  if (!data) return false;
  dispatch({
    type: GET_CERTIFICATION,
    payload: {
      data,
      entityType,
    },
  });
  return true;
};

export const createCertification = (entityType, id, payload, files) => async (dispatch) => {
  const { data } = await CertificationService.createCertification(entityType, id, payload, files);
  if (!data) return false;
  dispatch({
    type: CREATE_CERTIFICATION,
    payload: {
      certifications: data,
      userId: id,
      entityType,
    },
  });
  return true;
};

export const updateCertification = (
  entityType,
  id,
  certificationId,
  payload,
  files,
) => async (dispatch) => {
  const {
    data,
  } = await CertificationService.updateCertification(
    entityType,
    id,
    certificationId,
    payload,
    files,
  );
  if (!data) return false;
  dispatch({
    type: UPDATE_CERTIFICATION,
    payload: {
      certifications: data,
      userId: id,
      entityType,
    },
  });
  return true;
};

export const deleteCertification = (entityType, id, certificationId) => async (dispatch) => {
  const { data } = await CertificationService.deleteCertification(entityType, id, certificationId);
  if (!data) return false;
  dispatch({
    type: DELETE_CERTIFICATION,
    payload: {
      certifications: data,
      userId: id,
      entityType,
    },
  });
  return true;
};

export const getCertificationCustomFieldTemplate = (entityType) => async (dispatch) => {
  const {
    data: template,
  } = await CertificationService.getCertificationCustomFieldTemplate(entityType);
  dispatch({
    type: GET_CERTIFICATION_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
      entityType,
    },
  });
};

export const updateCertificationCustomFieldTemplate = (entityType, payload) => (
  async (dispatch) => {
    const {
      data: template,
    } = await CertificationService.updateCertificationCustomFieldTemplate(entityType, payload);
    dispatch({
      type: UPDATE_CERTIFICATION_CUSTOM_FIELD_TEMPLATE,
      payload: {
        template,
        entityType,
      },
    });
  }
);

export const getCertificationCustomData = (entityType, id) => async (dispatch) => {
  if (!id) {
    return dispatch({ type: GET_CERTIFICATION_CUSTOM_DATA, payload: { data: [], entityType } });
  }

  const { data } = await CertificationService.getCertificationCustomData(entityType, id);
  if (!data) return false;
  await decorateFormWithFiles({ fileMap: data.fileMap });
  dispatch({
    type: GET_CERTIFICATION_CUSTOM_DATA,
    payload: {
      data,
      entityType,
    },
  });
  return true;
};
