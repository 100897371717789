import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import fileDetails from '../../assets/images/NUX/fileDetails.png';


const updateVersionDesc = (
<div style={{ fontSize: 13 }}>
To update a file to a newer version,
simply upload the new version of the file to the same folder where the previous version is in.
Make sure the file name and file type of the new version exactly
matches that of the previous version (if the name and type don't match,
it will be uploaded as a separate document).
</div>
);

const accessVersionDesc = `
To access any previous version of a file,
open the 'More' slider for the document where you will find the 'Versions'
section for the file. You will be able to view or download any previous versions of the file.
`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 74px)',
      padding:'0px 40px'
    }}
    rows={[
    {
      title:'',
      description:'',
      cols:[0,0,24],
      image:{
        src:fileDetails,
        style:{
          height:250,
          width:278,
          position:'absolute',
          top:0,
          right:0,
        }
      },
    },
    {
      title:'Updating Versions',
      description:updateVersionDesc,
      cols:[0,14,0],
    },{
      title:'Accessing Versions',
      description:accessVersionDesc,
      cols:[0,14,0],
    }]}
  />
);
