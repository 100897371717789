import React from 'react';
import { Select, TreeSelect } from 'antd';

// Import Components
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';

// Import Helpers:
import { getFormattedCurrency } from '../../forms/formHelpers';
import colors from '../../constants/Colors';
import {
  currencyFormatter as formatter,
  currencyParser as parser,
} from '../../helpers/inputParsers';

const getStyle = (invalidDistributionMap, id) => {
  if (invalidDistributionMap[id]) {
    return { border: '1px solid red', borderRadius: 5 };
  }

  return {};
};

export default ({
  isDisplay,
  getCostcodeTreeOptions,
  onCostcodeOptionChange,
  onAmountChange,
  onCostcodeDelete,
  projectOptions,
  onProjectOptionChange,
  isProjectDisabled,
  projectMap = {},
  invalidDistributionMap = {},
}) => [
  {
    title: 'Project',
    dataIndex: 'projectId',
    key: 'projectId',
    align: 'center',
    width: 200,
    render: (projectId, record = {}) => (
      isDisplay || !record?.isNew
        ? projectMap[projectId]?.name ?? ''
        : (
          <Select
            options={projectOptions}
            value={projectId}
            onChange={(val) => onProjectOptionChange(record?.id, val)}
            disabled={isProjectDisabled}
            style={{ width: '95%', ...getStyle(invalidDistributionMap, record?.id) }}
          />
        )
    ),
  },
  {
    title: 'Costcode',
    dataIndex: 'phaseCostcodeKey',
    key: 'phaseCostcodeKey',
    align: 'center',
    width: 200,
    render: (key, record) => {
      const { isNew, id, phaseName, costcodeName, code, projectId } = record || {};
      return (
        isDisplay || !isNew
          ? `${phaseName || 'Unphased'} - ${code} ${costcodeName}`
          : <TreeSelect
              showSearch
              treeDefaultExpandAll
              treeNodeFilterProp='title'
              treeData={getCostcodeTreeOptions(projectId)}
              value={key}
              onSelect={(val) => onCostcodeOptionChange(id, val)}
              style={{ width: '95%', ...getStyle(invalidDistributionMap, id) }}
            />
      );
    },
  },
  {
    title: 'Amount',
    dataIndex: 'amount',
    key: 'amount',
    align: 'center',
    width: 125,
    render: (amount, record) => {
      const { id } = record || {};
      return (
        isDisplay ? getFormattedCurrency(amount)
        : <OnTraccrNumberInput
            style={{ width: '95%', marginTop: 5 }}
            value={amount}
            onChange={(val) => onAmountChange(id, val)}
            formatter={formatter}
            parser={parser}
            precision={2}
            step={0.01}
          />
      );
    }
  },
  {
    key:'action',
    dataIndex: 'id',
    align:'center',
    width: 60,
    render:(id) => {
      return (
        isDisplay ? null
          : <BorderlessButton
              title=''
              icon='delete'
              color={colors.ONTRACCR_RED}
              onClick={() => onCostcodeDelete(id)}
              style={{
                paddingRight:8,
                paddingLeft:0,
              }}
            />
      );
    },
  },
];
