import React, { useEffect, useMemo, useState } from 'react';
import {
  Col,
  Drawer,
  Form,
  Row,
  Select,
  Table,
  notification,
} from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import {
  createRoundingPolicy,
  updateRoundingPolicy,
} from '../state/settings.actions';
import { getTimeColumns } from './timeTrackingConstants';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import { findDateRangeOverlap } from '../../schedule/GanttSchedule/ganttScheduleHelpers';
import { getIdMap } from '../../helpers/helpers';

const getConflictErrorMessage = (startTime, endTime, policyName) => (
  `Time overlaps with times${policyName ? ` in policy ${policyName}` : ''}:
  ${startTime.toLocaleString(DateTime.TIME_SIMPLE)}
  -
  ${endTime.toLocaleString(DateTime.TIME_SIMPLE)}`
);

const getTimeRanges = (policy, times) => times.map((time) => ({
  id: time.id,
  policyId: policy.id,
  policyName: policy.name,
  startTime: DateTime.fromISO(time.startTime),
  endTime: DateTime.fromISO(time.endTime),
  isOvernight: DateTime.fromISO(time.startTime) > DateTime.fromISO(time.endTime),
}));

// Get all time ranges that could have conflicts with the current policy
// This only occurs if they share a position
const getConflictingTimeRanges = ({
  currentPolicy,
  currentPositions,
  timeRanges,
  roundingPolicies,
}) => {
  const conflictingTimeRanges = [];
  if (!timeRanges.length) return conflictingTimeRanges;

  const positions = new Set(currentPositions);
  roundingPolicies.forEach((policy) => {
    const {
      id,
      positions: policyPositions,
      times,
    } = policy;
    if (id === currentPolicy?.id) return;
    if (policyPositions.some((position) => positions.has(position))) {
      conflictingTimeRanges.push(...getTimeRanges(policy, times));
    }
  });

  return conflictingTimeRanges;
};

// Returns a map of time range ids to conflict error messages
const getConflictErrors = ({
  currentPolicy,
  currentPositions,
  timeRanges,
  roundingPolicies,
}) => {
  const conflictErrorMap = {};

  // Look through all time ranges for possible conflicts
  const allTimeRanges = timeRanges.concat(
    getConflictingTimeRanges({
      currentPolicy,
      currentPositions,
      timeRanges,
      roundingPolicies,
    }),
  );

  if (allTimeRanges.length <= 1) return conflictErrorMap;

  allTimeRanges.forEach((timeRange, index) => {
    const {
      startTime: firstStartTime,
      endTime: firstEndTime,
      isOvernight: firstIsOvernight,
      policyName: firstPolicyName,
    } = timeRange;

    allTimeRanges.forEach((compareTimeRange, compareIndex) => {
      if (compareIndex <= index) return;
      const {
        startTime: compareStartTime,
        endTime: compareEndTime,
        isOvernight: compareIsOvernight,
        policyName: comparePolicyName,
      } = compareTimeRange;

      const result = findDateRangeOverlap({
        start: firstStartTime,
        end: firstIsOvernight ? firstEndTime.plus({ days: 1 }) : firstEndTime,
      }, {
        start: compareStartTime,
        end: compareIsOvernight ? compareEndTime.plus({ days: 1 }) : compareEndTime,
      });

      if (result) {
        if (!conflictErrorMap[timeRange.id]) conflictErrorMap[timeRange.id] = [];
        if (!conflictErrorMap[compareTimeRange.id]) conflictErrorMap[compareTimeRange.id] = [];

        conflictErrorMap[timeRange.id].push(
          getConflictErrorMessage(
            compareStartTime,
            compareEndTime,
            comparePolicyName,
          ),
        );
        conflictErrorMap[compareTimeRange.id].push(
          getConflictErrorMessage(
            firstStartTime,
            firstEndTime,
            firstPolicyName,
          ),
        );
      }
    });
  });

  return conflictErrorMap;
};

export default function RoundingPolicyDrawer({
  visible,
  onClose,
  onDelete,
  policy,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const companyPositions = useSelector((state) => state.settings.positionNames);
  const workingHours = useSelector((state) => state.settings.workingHours);
  const roundingPolicies = useSelector((state) => state.settings.roundingPolicies);

  const [times, setTimes] = useState([]);
  const [positions, setPositions] = useState([]);

  const positionMap = useMemo(() => getIdMap(companyPositions), [companyPositions]);

  useEffect(() => {
    if (!visible || !policy) {
      form?.resetFields();
      setPositions([]);
      return setTimes([]);
    }

    form.setFieldsValue(policy);
    setPositions(policy.positions ?? []);
    return setTimes(policy.times ?? []);
  }, [visible, policy, form]);

  const errors = useMemo(() => {
    if (!times?.length || !visible) return null;

    const badRowErrorMap = {};
    const timeRanges = [];

    times.forEach((time) => {
      const {
        id,
        type,
        startTime,
        endTime,
        roundedTime,
      } = time;

      const newTime = {
        id,
        policyId: policy?.id,
        startTime: DateTime.fromISO(startTime),
        endTime: DateTime.fromISO(endTime),
        roundedTime: DateTime.fromISO(roundedTime),
        isOvernight: DateTime.fromISO(startTime) > DateTime.fromISO(endTime),
      };

      timeRanges.push(newTime);

      if (!startTime || !endTime || !roundedTime) {
        badRowErrorMap[id] = {};
      }

      if (!startTime) {
        badRowErrorMap[id].startTime = 'Start time is required';
      }

      if (!endTime) {
        badRowErrorMap[id].endTime = 'End time is required';
      }

      if (!roundedTime) {
        badRowErrorMap[id].roundedTime = 'Rounded time is required';
      }

      // Ensure rounded time would not result in a bad value
      // A bad value in this case is where the start time would end up being > end time
      if (startTime && endTime && roundedTime) {
        const startDT = newTime.isOvernight
          ? newTime.startTime.minus({ days: 1 })
          : newTime.startTime;
        const endDT = newTime.endTime;
        const roundedDT = newTime.roundedTime;

        if (roundedDT < startDT && type === 'clockout') {
          badRowErrorMap[id] = {};
          badRowErrorMap[id].roundedTime = 'Rounded time must be after start time';
        } else if (roundedDT > endDT && type === 'clockin') {
          badRowErrorMap[id] = {};
          badRowErrorMap[id].roundedTime = 'Rounded time must be before end time';
        }
      }
    });

    return {
      conflictErrorMap: getConflictErrors({
        currentPolicy: policy,
        currentPositions: positions,
        timeRanges,
        roundingPolicies,
      }),
      badRowErrorMap,
    };
  }, [times, visible, roundingPolicies, positions, policy]);

  const onSubmit = async () => {
    await form.validateFields();

    if (errors) {
      const {
        conflictErrorMap = {},
        badRowErrorMap = {},
      } = errors;

      if (Object.keys(conflictErrorMap).length || Object.keys(badRowErrorMap).length) {
        return;
      }
    }

    const values = form.getFieldsValue();
    const payload = {
      ...values,
      positions,
      times: times.map((time) => ({
        type: time.type,
        startTime: time.startTime,
        endTime: time.endTime,
        roundedTime: time.roundedTime,
      })),
    };

    let result;

    if (policy) {
      result = await dispatch(updateRoundingPolicy(policy.id, payload));
    } else {
      result = await dispatch(createRoundingPolicy(payload));
    }

    if (result) {
      onClose();
    }
  };

  const onAddTime = () => {
    setTimes([...times, {
      id: DateTime.local().toMillis(), // temp id for now
      type: 'clockin',
      startTime: null,
      endTime: null,
      roundedTime: null,
    }]);
  };

  const onEditTime = (timeToEdit) => {
    setTimes((prevTimes) => (
      prevTimes.map((time) => {
        if (time.id === timeToEdit.id) {
          return timeToEdit;
        }
        return time;
      })
    ));
  };

  const onDeleteTime = (timeToDelete) => {
    CustomConfirmModal({
      title: 'Delete Time',
      content: 'Are you sure you want to delete this time?',
      onOk: () => {
        setTimes(times.filter((time) => time.id !== timeToDelete.id));
      },
    });
  };

  const onPositionChange = (newPositions) => {
    setPositions(newPositions);
    const errorPositions = [];

    newPositions.forEach((positionId) => {
      const position = positionMap[positionId]?.name;
      const positionWorkingHours = workingHours?.[position];
      if (
        positionWorkingHours?.startTime
        && positionWorkingHours?.endTime
        && !positionWorkingHours?.allowOutside
      ) {
        errorPositions.push(position);
      }
    });

    if (errorPositions.length) {
      notification.warn({
        message: 'Warning',
        description: (
          <div>
            The following positions have working hours that do not allow clocking outside of them:
            <ul>
              {errorPositions.map((position) => (
                <li key={position}>{position}</li>
              ))}
            </ul>
          </div>
        ),
      });
    }
  };

  const positionOptions = useMemo(() => companyPositions?.map((position) => ({
    label: position.name,
    value: position.id,
  })), [companyPositions]);

  return (
    <Drawer
      title={policy ? `Edit ${policy.name}` : 'Add Rounding Policy'}
      placement="right"
      closable
      onClose={onClose}
      visible={visible}
      width={800}
    >
      <Form form={form} initialValues={{ ...(policy ?? {}) }}>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Please enter a name' }]}
        >
          <OnTraccrTextInput />
        </Form.Item>
        <Form.Item
          label="Positions"
          name="positions"
        >
          <Select
            options={positionOptions}
            mode="multiple"
            placeholder="Select positions"
            style={{ width: '100%' }}
            optionFilterProp="label"
            onChange={onPositionChange}
          />
        </Form.Item>
        <Row justify="space-between" style={{ paddingBottom: 15 }}>
          <Col>
            Times
          </Col>
          <Col>
            <OnTraccrButton
              icon={<PlusOutlined />}
              title="Add Time"
              type="primary"
              onClick={onAddTime}
            />
          </Col>
        </Row>
        <Table
          columns={getTimeColumns({ onDelete: onDeleteTime, onEdit: onEditTime, errors })}
          dataSource={times}
          pagination={false}
          size="small"
          bordered
        />
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onDelete={onDelete}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

RoundingPolicyDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  policy: PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
    times: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      startTime: PropTypes.string,
      endTime: PropTypes.string,
      roundedTime: PropTypes.string,
    })),
    positions: PropTypes.arrayOf(PropTypes.string),
  }),
  onDelete: PropTypes.func,
};

RoundingPolicyDrawer.defaultProps = {
  policy: null,
  onDelete: null,
};
