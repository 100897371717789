import {
  SAVE_REPORT,
  GET_REPORTS,
  UPDATE_PROGRESS,
  GET_PROGRESS,
  DELETE_REPORT,
  UPDATE_REPORT,
  CREATE_CUSTOM_EXPORT,
  GET_CUSTOM_EXPORTS,
  UPDATE_CUSTOM_EXPORT,
  DELETE_CUSTOM_EXPORT,
} from '../../state/actionTypes';
import ReportsServices from './reports.service';

export default {};

export const saveReport = (payload) => async (dispatch) => {
  const { data } = await ReportsServices.save(payload);
  if (!data) return false;
  const { data: reports } = await ReportsServices.getAll();
  dispatch({
    type: SAVE_REPORT,
    payload: {
      reports,
    },
  });
  return true;
};

export const updateReport = (id, payload) => async (dispatch) => {
  const { data } = await ReportsServices.updateReport(id, payload);
  if (!data) return false;
  const { data: reports } = await ReportsServices.getAll();
  dispatch({
    type: UPDATE_REPORT,
    payload: {
      reports,
    },
  });
  return true;
};

export const getAllReports = () => async (dispatch) => {
  const { data } = await ReportsServices.getAll();
  if (!data) return false;
  dispatch({
    type: GET_REPORTS,
    payload: {
      reports: data,
    },
  });
  return true;
};

export const deleteReport = (id) => async (dispatch) => {
  const { data } = await ReportsServices.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_REPORT,
    payload: {
      reportId: id,
    },
  });
  return true;
};

export const updateProgress = (payload) => async (dispatch) => {
  const { data } = await ReportsServices.updateProgress(payload);
  const { data: progress } = await ReportsServices.getProgress();
  if (!data) return false;
  dispatch({
    type: UPDATE_PROGRESS,
    payload: {
      progress,
    },
  });
  return true;
};

export const getProgress = (query) => async (dispatch) => {
  const { data } = await ReportsServices.getProgress(query);
  if (!data) return false;
  dispatch({
    type: GET_PROGRESS,
    payload: {
      progress: data,
    },
  });
  return true;
};

export const getCustomExports = () => async (dispatch) => {
  const { data } = await ReportsServices.getCustomExports();
  if (!data) return false;
  dispatch({
    type: GET_CUSTOM_EXPORTS,
    payload: {
      customExports: data,
    },
  });
  return true;
};

export const createCustomExport = (customExport) => async (dispatch) => {
  const { data } = await ReportsServices.createCustomExport(customExport);
  if (!data) return false;
  dispatch({
    type: CREATE_CUSTOM_EXPORT,
    payload: {
      customExport: data,
    },
  });
  return true;
};

export const updateCustomExport = (id, customExport) => async (dispatch) => {
  const { data } = await ReportsServices.updateCustomExport(id, customExport);
  if (!data) return false;
  dispatch({
    type: UPDATE_CUSTOM_EXPORT,
    payload: {
      id,
      customExport,
    },
  });
  return true;
};

export const deleteCustomExport = (id) => async (dispatch) => {
  const { data } = await ReportsServices.deleteCustomExport(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CUSTOM_EXPORT,
    payload: {
      id,
    },
  });
  return true;
};
