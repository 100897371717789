
import {
  GET_EMAILS,
} from '../../state/actionTypes';
import EmailService from './email.service';


export default {};

export const getEmails = () => async (dispatch) => {
  const { data } = await EmailService.getAll();
  if (!data) return false;
  dispatch({
    type: GET_EMAILS,
    payload: {
      emails: data,
    },
  });
  return true;
};
