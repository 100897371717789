import React, { useCallback, useEffect, useState } from 'react';
import {
  Drawer,
  Form,
  DatePicker,
} from 'antd';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

const GanttScheduleAddHolidayDrawer = ({
  onClose,
  visible,
  handleSubmit,
}) => {
  const [ formRef, setFormRef ] = useState();

  useEffect(() => {
    if (!visible && formRef) {
      formRef.resetFields();
      formRef.setFieldsValue({
        date: null,
      });
    }
  }, [visible]);

  const onValuesChange = useCallback((changedValues, _) => {
    if (changedValues.date) {
      formRef.setFieldsValue({
        date: changedValues.date.startOf('day').utcOffset(0),
      });
    }
  }, [formRef]);

  const onSubmit = () => {
    const values = formRef.getFieldsValue();
    handleSubmit(values);
  };

  return (
    <Drawer
      title={'Add Holiday'}
      visible={visible}
      onClose={onClose}
      width={750}
      maskClosable={false}
    >
      <Form
        ref={setFormRef}
        layout='vertical'
        className="schedule-of-values-form"
        style={{
          maxWidth:'95%'
        }}
        onValuesChange={onValuesChange}
      >
        <Form.Item
          name='name'
          label='Name'
          rules={[
            { required: true, message: 'Name is required'},
          ]}
        >
          <OnTraccrTextInput
            style={{ width: '100%' }}
            placeholder="Name"
          />
        </Form.Item>
        <Form.Item
          name='date'
          label='Date'
          className='schedule-form-item'
          initialValue={null}
          rules={[
            { required: true, message: 'Date is required'},
          ]}
        >
          <DatePicker format='MMM Do YY' allowClear={false}/>
        </Form.Item>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

export default GanttScheduleAddHolidayDrawer;