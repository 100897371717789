const qrCodeFormatOptions = [
  {
    value: 'qrCode',
    label: 'QR Code',
  },
  {
    value: 'CODE128',
    label: 'CODE128',
  },
  {
    value: 'CODE39',
    label: 'CODE39',
  },
  {
    value: 'EAN13',
    label: 'EAN13',
  },
  {
    value: 'EAN8',
    label: 'EAN8',
  },
  {
    value: 'UPCE',
    label: 'UPC-E',
  },
  {
    value: 'ITF14',
    label: 'ITF14',
  },
];

export default qrCodeFormatOptions;
