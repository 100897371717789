/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useEffect, useState,
} from 'react';
import { PropTypes } from 'prop-types';
import { Gantt } from 'web-dashboard-gantt';
import { Empty } from 'antd';
import { DateTime } from 'luxon';
import {
  Panel,
  PanelGroup,
  PanelResizeHandle,
} from 'react-resizable-panels';
import GanttTable from './GanttTable';
import './GanttView.css';

export default function GanttView({
  className,
  tasks,
  ganttViewType,
  ganttZoomScale,
  canEdit,
  visibleColumns,
  onDoubleClick,
  onEditClick,
  TooltipContent,
}) {
  const [columnWidth, setColumnWidth] = useState(160);
  const [viewDate, setViewDate] = useState(DateTime.local());

  useEffect(() => {
    if (ganttViewType === 'Month') {
      setColumnWidth(150);
    } else {
      setColumnWidth(275);
    }
    setViewDate(viewDate.plus({ seconds: 1 }));
  }, [ganttViewType]);

  const ganttDisplayOptions = {
    viewMode: ganttViewType,
    viewDate,
    readOnly: !canEdit,
    locale: 'en',
    TooltipContent,
  };

  const ganttStylingOptions = {
    todayColor: '#BF381D',
    rowHeight: 39,
    columnWidth: columnWidth * ganttZoomScale,
  };

  const ganttEventOptions = {
    onDoubleClick,
    onListItemClick: onEditClick,
  };

  return (

    <div className={className}>
      <PanelGroup
        direction="horizontal"
        style={{
          overflowY: 'scroll',
          height: 'auto',
        }}
      >
        {tasks.length > 0 && (
        <>
          {visibleColumns.length > 0 && (
            <>
              <Panel
                defaultSize={30}
                minSize={20}
                maxSize={80}
                order={1}
                id="timeline-table"
              >
                <GanttTable
                  tasks={tasks}
                  visibleColumns={visibleColumns}
                  onEditClick={onEditClick}
                  canEdit={canEdit}
                  resizePosition="after"
                />

              </Panel>
              <PanelResizeHandle
                style={{
                  width: '3px',
                }}
              />
            </>
          )}
          <Panel
            defaultSize={70}
            minSize={20}
            maxSize={80}
            order={2}
            id="gantt-container"
          >
            <Gantt
              tasks={tasks}
              {...ganttEventOptions}
              {...ganttDisplayOptions}
              {...ganttStylingOptions}
            />
          </Panel>
        </>
        )}
      </PanelGroup>

      {
        tasks.length === 0
        && (
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
            }}
          />
        )
      }

    </div>
  );
}

GanttView.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    progress: PropTypes.number.isRequired,
    dependencies: PropTypes.string,
  })).isRequired,
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
  })).isRequired,
  tableColumns: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.node,
      dataIndex: PropTypes.string,
      key: PropTypes.string,
      onCell: PropTypes.func,
    }),
  ).isRequired,
  canEdit: PropTypes.bool.isRequired,
  ganttZoomScale: PropTypes.number,
  ganttViewType: PropTypes.string.isRequired,
  onEditClick: PropTypes.func.isRequired,
  onDoubleClick: PropTypes.func.isRequired,
  onListItemClick: PropTypes.func.isRequired,
  TooltipContent: PropTypes.func.isRequired,
  className: PropTypes.string,
};

GanttView.defaultProps = {
  className: '',
  ganttZoomScale: 1,
};
