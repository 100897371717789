import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import translations
import en from './translations/en.json';

const main = () => {
  // Set up i18next
  i18n
    .use(initReactI18next)
    .init({
      resources: {
        en: {
          translation: en,
        },
      },
      lng: 'en',
      fallbackLng: 'en',
      interpolation: {
        escapeValue: false,
      },
    });
};

export default main;
