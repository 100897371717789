import React, { useCallback } from 'react';
import moment from 'moment';
import { Row, DatePicker } from 'antd';
import PropTypes from 'prop-types';

import {
  payrollRows,
  semiMonthlyPayrollRows,
  defaultPayrollPeriods,
} from './timeTrackingConstants';
import TimeTrackingSelect from './TimeTrackingSelect';

import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import DisplayText from '../../common/text/DisplayText';

import SemiMonthlyPayrollDates from './SemiMonthlyPayrollDates';

const EARLIEST_PAYROLL_DATE = moment('Jan 01 1950 00:00:00', 'MMM DD YYYY hh:mm:ss');
function Payroll({
  settings,
  saveCompanySettings,
}) {
  const ourPayperiod = settings.payPeriod || defaultPayrollPeriods[0];
  const isSemiMonthly = ourPayperiod === 'Semi-Monthly';
  const getView = useCallback((index) => {
    switch (index) {
      case 0:
        return (
          <TimeTrackingSelect
            data={defaultPayrollPeriods}
            defaultValue={ourPayperiod}
            onChange={(payPeriod) => saveCompanySettings({
              payPeriod,
            })}
          />
        );
      case 1:
      default:
        return (
          isSemiMonthly
            ? (
              <SemiMonthlyPayrollDates
                settings={settings}
                saveCompanySettings={saveCompanySettings}
              />
            )
            : (
              <DatePicker
                style={{ marginRight: 56 }}
                onChange={(_date, payPeriodDates) => saveCompanySettings({
                  payPeriodDates,
                })}
                defaultValue={moment(settings.payPeriodDates)}
                disabledDate={(current) => (
                  current
                  && (
                    current > moment().endOf('day')
                    || current < EARLIEST_PAYROLL_DATE
                  )
                )}
              />
            )
        );
    }
  }, [settings, isSemiMonthly, ourPayperiod, saveCompanySettings]);
  return (
    <CompanySettingsCard
      title="Payroll"
    >
      <CheckRows
        data={isSemiMonthly ? semiMonthlyPayrollRows : payrollRows}
        getView={getView}
      />
      <Row justify="center" style={{ width: '100%', margin: 10, marginTop: 20 }}>
        <DisplayText
          title="The new changes will take effect immediately"
        />
      </Row>
    </CompanySettingsCard>
  );
}

Payroll.propTypes = {
  settings: PropTypes.shape({
    payPeriod: PropTypes.string,
    payPeriodDates: PropTypes.string,
  }),
  saveCompanySettings: PropTypes.func.isRequired,
};

Payroll.defaultProps = {
  settings: {},
};

export default Payroll;
