import React from 'react';
import { Row, Col } from 'antd';
import { PageSizes } from 'pdf-lib';

import DebouncedPicker from './DebouncedPicker';

const [ pageWidth, pageHeight ] = PageSizes.Letter;

export default ({
  onDrawOptionChanged,
  drawOptions = {},
  style = {},
  isTable,
}) => (
  <>
    <Row justify='space-between' gutter={10}>
      <Col span={12}>
        <DebouncedPicker
          optionKey='x'
          drawOptions={drawOptions}
          style={style}
          max={pageWidth}
          onDrawOptionChanged={onDrawOptionChanged}
        />
      </Col>
      <Col span={12}>
        <DebouncedPicker
          optionKey='y'
          drawOptions={drawOptions}
          style={style}
          max={pageHeight}
          onDrawOptionChanged={onDrawOptionChanged}
        />
      </Col>
    </Row>
    <Row justify='space-between' gutter={10}>
      <Col span={12}>
        <DebouncedPicker
          optionKey='width'
          title='Width'
          drawOptions={drawOptions}
          style={style}
          max={pageWidth}
          onDrawOptionChanged={onDrawOptionChanged}
        />
      </Col>
      <Col span={12}>
        {!isTable && <DebouncedPicker
          optionKey='height'
          title='Height'
          drawOptions={drawOptions}
          style={style}
          max={pageHeight}
          min={0}
          onDrawOptionChanged={onDrawOptionChanged}
        />}
      </Col>
    </Row> 
  </>
);
