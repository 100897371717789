import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getUsersFromSelectedDivisions } from '../../timecards/timecard.helpers';

export default function useDivisionUsers() {
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const users = useSelector((state) => state.users.users);
  const divisions = useSelector((state) => state.settings.divisions);

  const relevantUsers = useMemo(() => getUsersFromSelectedDivisions({
    users,
    selectedDivisions,
    divisions,
  }), [users, selectedDivisions, divisions]);

  return relevantUsers;
}
