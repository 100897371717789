import React from 'react';
import PropTypes from 'prop-types';
import { Row, Checkbox, Col } from 'antd';

import HoverHelp from '../../../common/HoverHelp';

function RequiredColumnsCheckbox({ requiredColumns, onChange, disabled }) {
  return (
    <Row style={{ marginTop: 15 }} gutter={10}>
      <Col>
        <Checkbox
          checked={!disabled && requiredColumns}
          onChange={onChange}
          disabled={disabled}
        >
          Required Columns
        </Checkbox>
      </Col>
      <Col>
        <HoverHelp
          placement="topRight"
          content={(
            <div style={{ width: 250 }}>
              Check this box to make all columns
              {' '}
              <b>required</b>
              .
              <br />
              <br />
              Required columns cannot be left blank by a user filling out the form
            </div>
          )}
        />
      </Col>
    </Row>
  );
}

RequiredColumnsCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  requiredColumns: PropTypes.bool,
  disabled: PropTypes.bool,
};

RequiredColumnsCheckbox.defaultProps = {
  requiredColumns: false,
  disabled: false,
};

export default RequiredColumnsCheckbox;
