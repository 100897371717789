import React, {
  useCallback, useEffect,
} from 'react';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import { Col, Row } from 'antd';
import docusignImage from '../../../assets/images/docusign_logo_white_text_on_black.png';
import { checkIntegrationConnection, disconnectDocusign } from '../../state/settings.actions';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DisplayText from '../../../common/text/DisplayText';
import Colors from '../../../constants/Colors';
import { showDisconnectConfirm } from '../integrationHelpers';
import Permissions from '../../../auth/Permissions';

const imageDimensions = {
  height: 50,
};

const flavourText = `
Disconnecting your Docusign integration will stop any external signature
workflow steps from sending or updating.
`;

export default function Docusign() {
  const {
    connectedToDocusign,
    userId: ownerId,
  } = useSelector((state) => state.settings.company) ?? {};

  const dispatch = useDispatch();
  const checkStatus = useCallback(() => dispatch(checkIntegrationConnection('docusign')), []);
  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  return (
    <div style={{ paddingTop: 30, paddingBottom: 30 }}>
      <div style={imageDimensions}>
        <img
          alt="Connect to Docusign"
          src={docusignImage}
          style={imageDimensions}
        />
      </div>
      {connectedToDocusign ? (
        <>
          <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
            <Col>
              <DisplayText
                title="Disconnect Docusign Integration"
                style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
              />
            </Col>
          </Row>
          <DisplayText
            title={flavourText}
            style={{
              marginBottom: 0,
              maxWidth: 275,
              paddingLeft: 10,
              fontFamily: 'roboto-regular',
              color: Colors.ONTRACCR_OPACITY_GRAY,
            }}
          />
          <Row style={{ width: '100%' }} justify="center">
            <OnTraccrButton
              title="Deactivate"
              type={Permissions.id === ownerId ? 'primary' : 'back'}
              disabled={Permissions.id !== ownerId}
              style={{ margin: 10 }}
              onClick={() => {
                showDisconnectConfirm({
                  type: 'Docusign',
                  flavourText,
                  onConfirm: async () => {
                    await dispatch(disconnectDocusign());
                  },
                });
              }}
            />
            <DisplayText
              style={{
                textAlign: 'center',
                fontFamily: 'roboto-bold',
                color: Colors.ONTRACCR_OPACITY_GRAY,
                height: 32,
              }}
              title="Only the account owner can deactivate"
            />
          </Row>
        </>
      ) : (
        <OnTraccrButton
          style={{
            marginTop: 30,
          }}
          title="Connect"
          onClick={async () => {
            const { data: uri } = await axios.get('/docusign/connect/initiate');
            if (uri) window.location.href = uri;
          }}
        />
      )}
    </div>
  );
}
