import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIdMap } from '../helpers/helpers';
import Labels from '../labels/Labels';

export default function UserLabels({
  userId,
  size,
}) {
  const userToLabel = useSelector((state) => state.users.userToLabel);
  const labels = useSelector((state) => state.labels);
  const userLabels = useMemo(() => labels.filter((label) => label.type === 'users'), [labels]);

  const labelIdMap = useMemo(() => getIdMap(userLabels), [userLabels]);

  const ourFilteredLabels = useMemo(() => {
    const {
      [userId]: ourLabels = [],
    } = userToLabel;
    return ourLabels.map((labelId) => labelIdMap[labelId]).filter((label) => label);
  }, [userToLabel, labelIdMap, userId]);

  return (
    <Labels
      id={userId}
      size={size}
      labels={ourFilteredLabels}
      toLabel={userToLabel}
    />
  );
}
