import axios from 'axios';
import qs from 'query-string';

import { request } from '../../../helpers/requests';
import { parseAndUploadFiles } from '../../../files/fileHelpers';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const fileUploader = axios.create();

const LiveFeedService = {
  get: () => request({
    call: axios.get('events'),
    errMsg: 'Failed to get events',
    hideSuccessToast: true,
  }),
  getPosts: () => request({
    call: axios.get('posts', { params: { public: true, device: 'web' } }),
    errMsg: 'Failed to get posts',
    hideSuccessToast: true,
  }),
  deletePost: (postId) => request({
    call: axios.delete(`posts/${postId}`),
    errMsg: 'Failed to delete posts',
    hideSuccessToast: true,
  }),
  getFileURLs: (payload) => request({
    call: axios.post('/files', payload),
    errMsg: 'Failed to get posts',
    hideSuccessToast: true,
  }),
  createPost: (payload) => request({ // TODO: Add files
    call: async () => {
      // upload and prepare files:
      const {
        files = [],
      } = payload;
      const parsedFiles = await parseAndUploadFiles(files);
      const hasNewFiles = files.some((file) => !file.existing);
      const newPayload = { ...payload, hasNewFiles };
      delete newPayload.files;
      if (parsedFiles) newPayload.files = parsedFiles;
      const { data: post } = await axios.post(`post`, newPayload);
      return {
        data: {
          ...post,
          files: parsedFiles || [],
        },
      };
    },
    errMsg: 'Failed to create post',
    hideSuccessToast: true,
  }),
  getChannels: () => request({
    call: axios.get('/channels'),
    errMsg: 'Failed to get channels',
    hideSuccessToast: true,
  }),
  createCustomChannel: (payload) => request({
    call: axios.post('/channels', payload),
    errMsg: 'Failed to create custom channel',
  }),
  editCustomChannel: (id, payload) => request({
    call: axios.put(`/channels/${id}`, payload),
    errMsg: 'Failed to edit custom channel',
  }),
  deleteCustomChannel: (id) => request({
    call: axios.delete(`/channels/${id}`),
    errMsg: 'Failed to delete custom channel',
  }),
};

export default LiveFeedService;
