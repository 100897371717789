import React from 'react';

import { ANCHOR_RADIUS } from './PDFDesigner.constants';

export default ({ x, y, cursor, onMouseDown }) => (
  <circle
    cx={x}
    cy={y}
    r={ANCHOR_RADIUS}
    stroke='darkgray'
    strokeWidth={1}
    fill='lightgray'
    style={{
      cursor,
    }}
    onMouseDown={onMouseDown}
  />
)