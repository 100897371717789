import React, { useState, useEffect } from 'react';
import { Drawer,} from 'antd';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import DrawerFooter from '../../common/containers/DrawerFooter';
import StepsContainer from '../../common/containers/StepsContainer';
import { isNullOrUndefined } from '../../helpers/helpers';


export default ({
  title,
  visible,
  onClose,
  onSave,
  onNext = () => true,
  onSkip,
  nextDisabled,
  steps = [],
  width = '90%',
  bodyStyle = {},
  onStepChanged,
  currentStep: propStep,
}) => {
  const [loading, setLoading] = useState(false);
  const [currentStep,setCurrentStep] = useState(0);
  const [key,setKey] = useState(0);

  useEffect(() => {
    if (onStepChanged) onStepChanged(currentStep);
  },[currentStep,onStepChanged]);
  useEffect(() => {
    if(!visible) {
      setKey(key + 1); // Refresh on close
      setCurrentStep(0);
    }
  }, [ visible ]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!isNullOrUndefined(propStep)) setCurrentStep(propStep);
  }, [propStep]);

  return (
    <Drawer 
      title={title}
      visible={visible}
      width={width}
      onClose={onClose}
      bodyStyle={bodyStyle}
    >
      <div style={{ height: 'calc(100% - 55px)', width:'100%', overflowY:'scroll'}}>
        <StepsContainer
          key={key}
          currentStep={currentStep}
          steps={steps}
        />
      </div>
      <DrawerFooter>
        {currentStep > 0  && <OnTraccrButton 
          type='back'
          title='Back'
          onClick={() => setCurrentStep(currentStep - 1)}
          style={{ marginRight: 8 }}
        />}
        {
          onSkip
          && (
            <OnTraccrButton
              title="Skip"
              onClick={onSkip}
              style={{ marginRight: 8 }}
            />
          )
        }
        <OnTraccrButton
          disabled={nextDisabled}
          loading={loading}
          title={currentStep === steps.length - 1 ? 'Sync' : 'Next'}
          onClick={async () => {
            setLoading(true);
            if(currentStep === steps.length - 1) {
              await onSave();
            } else if(await onNext(currentStep)) {
              setCurrentStep(currentStep + 1);
            }
            setLoading(false);
          }}
        />
      </DrawerFooter>
    </Drawer>
  );
}