import React, { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row, Spin } from 'antd';

import FullPhoto from '../../common/photos/FullPhoto';
import { constructCompletedFormPDF } from '../formHelpers';

import { getIdMap } from '../../helpers/helpers';

/** PDF Preview Section for Completed Forms */
function PDFPreview({
  sliderVisible,
  formData,
  selectedForm,
}) {
  const {
    company: {
      settings = {},
      companyImageURL: logo,
    } = {},
  } = useSelector((state) => state.settings);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);

  const [PDF, setPDF] = useState();
  const [loading, setLoading] = useState(false);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  useEffect(() => {
    const getAndSetPDF = async () => {
      const {
        templateName,
        userId,
        createdAt = 0,
        data,
        employeeSignature,
        fileMap = {},
        drawOptions = [],
        useStandardTemplate,
        number: formNumber,
        templateSchema,
      } = selectedForm || {};
      const {
        collected = {},
      } = data || {};

      const {
        [userId]: {
          name: submitterName,
          employeeId,
        } = {},
      } = userMap;

      setLoading(true);
      const generatedPDF = await constructCompletedFormPDF({
        title: formNumber ? `${templateName} - ${formNumber}` : templateName,
        useStandardTemplate,
        formData,
        fileMap,
        data,
        createdAt,
        drawOptions,
        settings,
        projectIdMap,
        logo,
        employeeSignature,
        submitterName,
        employeeId,
        collected,
        number: formNumber,
        templateSchema,
      });
      setPDF(generatedPDF);
      setLoading(false);
    };
    if (sliderVisible) {
      getAndSetPDF();
    }
  }, [sliderVisible, formData, settings, logo, userMap, projectIdMap]);

  useEffect(() => {
    if (!sliderVisible) {
      setPDF();
      setLoading(false);
    }
  }, [sliderVisible]);

  return (
    <>
      <FullPhoto
        file={PDF}
        type="pdf"
        className="completed-form-contained"
      />
      {loading
        && (
        <Row justify="center" align="middle" className="form-loading-container">
          <Spin size="large" />
        </Row>
        )}
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
PDFPreview.propTypes = {
  sliderVisible: PropTypes.bool,
  formData: PropTypes.array,
  selectedForm: PropTypes.object,
};

PDFPreview.defaultProps = {
  sliderVisible: false,
  formData: [],
  selectedForm: {},
};

export default PDFPreview;
