import React, { useMemo } from 'react';
import { Form, Select } from 'antd';

import {
  AUTO_COLLECTED_ID,
  collectedDataMap
} from '../../../forms/forms.constants';

const tableFields = { table: true, weather: true };

const collectedToField = ({ id, title }) => ({
  id,
  configProps:{ title },
});

export default ({
  link:{
    sectionId,
    fieldId,
    collectedId,
  } = {},
  style = {},
  sections = [],
  collected = {},
  onLinkChanged,
  isTable,
  disabled = false,
}) => {
  const ourFields = useMemo(() => {
    if(sectionId === AUTO_COLLECTED_ID || collectedId) {
      const collectedFields = [
        collectedToField(collectedDataMap.date),
        collectedToField(collectedDataMap.employeeName),
        collectedToField(collectedDataMap.employeeId)
      ];
      Object.keys(collected).forEach((key) => {
        const { collect } = collected[key];
        if(!collect || !(key in collectedDataMap)) return;
        collectedFields.push(collectedToField(collectedDataMap[key]));
      });
      return collectedFields;
    }


    const chosenSection = sections.find((section) => section.id === sectionId);
    if(!chosenSection) return [];
    const {
      fields = [],
    } = chosenSection;
    return fields.filter(({ selectedType }) => isTable ? tableFields[selectedType] : !tableFields[selectedType]);
  },[sections, sectionId, collected, collectedId, isTable]);

  const ourSectionId = collectedId ? AUTO_COLLECTED_ID : sectionId;
  const ourFieldId = collectedId || fieldId;


  return (
    <>
      <Form.Item
        key='section'
        label='Section'
        style={style}
      >
        <Select
          value={ourSectionId}
          onChange={(newSectionId) => onLinkChanged({ sectionId: newSectionId })}
          allowClear
          disabled={disabled}
        >
          <Select.Option value={AUTO_COLLECTED_ID} key={AUTO_COLLECTED_ID}>
            Automatically Collected
          </Select.Option>
          {
            sections.map(({
              id, name,
            }) => (
              <Select.Option value={id} label={name} key={id}>
                {name}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
      <Form.Item
        key='field'
        label='Field'
        style={style}
      >
        <Select
          value={ourFieldId}
          disabled={disabled || ourFields.length === 0}
          onChange={(newFieldId) => onLinkChanged({ fieldId: newFieldId })}
          allowClear
        >
          {
            ourFields.map(({
              id, configProps: { title } = {},
            }) => (
              <Select.Option value={id} label={title} key={id}>
                {title}
              </Select.Option>
            ))
          }
        </Select>
      </Form.Item>
    </>
  )
}