import React, { useCallback, useState, useEffect, useMemo } from 'react';
import { Row, Col } from 'antd';
import { animated, useSpring } from 'react-spring';
import {
  DownOutlined,
} from '@ant-design/icons';


import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CostCodeUploadList from '../costcodes/costcodeUploadList';

const TABLE_HEIGHT = 300;

export default ({
  title,
  onPhaseChanged,
  onRemove,
  onAddNew,
  onAddExisting,
  onCostcodeEdit,
  isNotDisplay,
  categoryId,
  chosen = [],
}) => {
  const [open,setOpen] = useState(true);

  const [iconStyle,setIconStyle] = useSpring(() => ({ transform: 'rotate(0deg)' }));
  const [tableStyle,setTableStyle] = useSpring(() => ({ height: TABLE_HEIGHT }));
  
  const onCollapseToggle = useCallback(() => setOpen(!open),[open]); 

  useEffect(() => {
    setIconStyle({
      transform: open ? 'rotate(0deg)'  : 'rotate(-90deg)' ,
    });
    setTableStyle({
      height: open ? TABLE_HEIGHT : 1,
    });
  },[open,setIconStyle,setTableStyle]);

  const data = useMemo(() =>
    chosen.filter((cc) => cc.categoryId === categoryId)
  ,[categoryId, chosen]);

  return (
    <div className='project-add-costcode-list-container'>
        <Row
          className='project-add-costcode-list-header'
          justify='space-between'
          align='middle'
        >
          <Col>
            <Row gutter={20} justify='start' align='middle'>
                <animated.div style={{
                  cursor:'pointer',
                  ...iconStyle,
                }} onClick={onCollapseToggle}>
                  <DownOutlined/>
                </animated.div>
                <Col className='project-add-costcode-header-title'>
                  {title}
                </Col>
            </Row>
          </Col>
          <Col>
            {isNotDisplay &&
              <animated.div style={{
                opacity: tableStyle.height.interpolate({
                  range:[0,TABLE_HEIGHT],
                  output:[0,1]
                })
              }}> 
                <Row justify='end' gutter={10}>
                  <Col>
                    <OnTraccrButton
                      title='Add New Code'
                      onClick={onAddNew}
                    />
                  </Col>
                  <Col>
                    <OnTraccrButton
                      title='Add Global Code'
                      onClick={onAddExisting}
                    />
                  </Col>
                </Row>
              </animated.div>
            }
          </Col>
          <animated.div
            style={{
              position:'absolute',
              left: 0,
              right: 0,
              bottom: 0,
              height: 1,
              borderBottom: '1px solid lightgray',
              opacity:tableStyle.height.interpolate({
                range:[0,TABLE_HEIGHT],
                output:[0,1]
              })
            }}
          />
      </Row>
      <animated.div className='project-add-costcode-table' style={tableStyle}>
        <CostCodeUploadList
          dataSource={data}
          phased
          phaseClickable={isNotDisplay}
          onPhaseChanged={onPhaseChanged}
          onRemove={isNotDisplay ? onRemove : null}
          scroll={{
            y: TABLE_HEIGHT - 40,
          }}
          style={{
            height: TABLE_HEIGHT,
          }}
          onEdit={isNotDisplay ? onCostcodeEdit : null}
          shouldSort={false}
          conflictList={chosen}
          // Need this prop to calculate conflicts across code
        />
      </animated.div>
    </div>
  );
}
