import { Typography } from 'antd';
import { DateTime } from 'luxon';
import sortByString from '../../helpers/helpers';

import { getFormattedCurrency, getFormattedDate } from '../../forms/formHelpers';
import SearchFormFilterHeader from '../../forms/CompletedForms/SearchFormFilterHeader';

const { Text } = Typography;

export default function getFormInvoiceColumns({
  projectFilters,
  vendorFilters,
  customerFilters,
  statusFilters,
  t,
}) {
  return [
    {
      title: 'Invoice Number',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      align: 'center',
      width: 150,
      sorter: sortByString('invoiceNumber'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
      width: 150,
      sorter: sortByString('description'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: getFormattedCurrency,
      align: 'center',
      width: 140,
      sorter: (a, b) => a.amount - b.amount,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      width: 120,
      filters: statusFilters,
      onFilter: (value, record) => record.statusId === value,
    },
    {
      title: t('Project'),
      dataIndex: 'project',
      key: 'project',
      align: 'center',
      width: 120,
      filters: projectFilters,
      filterDropdown: SearchFormFilterHeader,
      onFilter: (value, record) => record.projectId === value,
    },
    {
      title: 'Vendor',
      dataIndex: 'vendor',
      key: 'vendor',
      align: 'center',
      width: 120,
      filters: vendorFilters,
      filterDropdown: SearchFormFilterHeader,
      onFilter: (value, record) => record.vendorId === value,
    },
    {
      title: t('Customer'),
      dataIndex: 'customer',
      key: 'customer',
      align: 'center',
      width: 120,
      filters: customerFilters,
      filterDropdown: SearchFormFilterHeader,
      onFilter: (value, record) => record.customerId === value,
    },
    {
      title: 'Date Issued',
      dataIndex: 'dateIssued',
      key: 'dateIssued',
      render: getFormattedDate,
      align: 'center',
      width: 120,
      sorter: (a, b) => a.dateIssued - b.dateIssued,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Due Date',
      dataIndex: 'dueDate',
      key: 'dueDate',
      render: (dueDate, record) => {
        const { status } = record || {};
        const formattedDate = getFormattedDate(dueDate);
        const currentDate = DateTime.local().toMillis();
        const type = dueDate && currentDate > dueDate && status !== 'Paid' ? 'danger' : null;
        return <Text type={type}>{formattedDate}</Text>;
      },
      align: 'center',
      width: 120,
      sorter: (a, b) => a.dueDate - b.dueDate,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'From QBO',
      dataIndex: 'fromQBOTimestamp',
      key: 'fromQBOTimestamp',
      align: 'center',
      width: 120,
      render: (timestamp) => (
        timestamp ? getFormattedDate(timestamp) : 'N/A'
      ),
    },
  ];
}
