import React from 'react';
import PropTypes from 'prop-types';
import { Form, Select } from 'antd';

import DisplayText from '../text/DisplayText';

/** Form Selector Input */
const FormSelectorInput = ({
  isNotDisplay,
  label,
  name,
  value,
  formStyle,
  rules = [],
  selectProps = {},
}) => (
  <Form.Item
    name={name}
    label={label}
    style={formStyle}
    rules={rules}
  >
    {isNotDisplay
    ?
      <Select
        {...selectProps}
      />
    : <DisplayText title={value}/>}
  </Form.Item>
);

FormSelectorInput.propTypes = {
  isNotDisplay: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  formStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ])),
  formLabelStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ])),
  rules: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
  })),
  selectProps: PropTypes.objectOf(PropTypes.shape({})),
};

export default FormSelectorInput;