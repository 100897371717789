import React from 'react';
import {
  DollarOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import WorkflowSimpleMappingNode from './WorkflowSimpleMappingNode';
import WorkflowConfigureDrawer from './WorkflowConfigureDrawer';

const getHoverText = (t) => `
Use this step to add a change order to the ${t('Project').toLowerCase()} contract.
`;

const getDisabledText = (t) => `This step requires at least one Text field and one ${t('Project')} Select field`;

const getChangeOrderFields = (t) => [
  { key: 'description', text: 'Description', type: 'text' },
  { key: 'contractAmount', text: 'Contract Amount', type: 'text' },
  { key: 'projectId', text: t('Project'), type: 'dropdown - Projects' },
  { key: 'subcontractId', text: 'Sub-Contract', type: 'dropdown - Sub-Contracts' },
];

const requiredFields = new Set(['description', 'contractAmount', 'projectId']);

export default function WorkflowUpdateContractNode({
  setElements,
  setDataMap,
  isDisplay,
  name,
  sections = [],
} = {}) {
  return function _({
    id,
    draggable,
    disabled,
    data = {},
  }) {
    const { t } = useTranslation();
    const HOVER_TEXT = getHoverText(t);
    const DISABLED_TEXT = getDisabledText(t);
    const changeOrderFields = getChangeOrderFields(t);
    const title = `Update Contract${disabled ? ' - DISABLED' : ''}`;
    return (
      <WorkflowSimpleMappingNode
        setElements={setElements}
        setDataMap={setDataMap}
        isDisplay={isDisplay}
        name={name}
        sections={sections}
        id={id}
        draggable={draggable}
        disabled={disabled}
        data={data}
        title={title}
        Icon={DollarOutlined}
        type='updateContract'
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7} : {}}
        fields={changeOrderFields}
        requiredFields={requiredFields}
        ConfigureDrawer={WorkflowConfigureDrawer}
      />
    );
  };
}
