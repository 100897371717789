import React, { useMemo, useEffect, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer,
  Table,
  DatePicker,
  TimePicker,
  Select,
  Spin,
  Row,
  Col,
  Popover,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import axios from 'axios';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

import sortByString, { getIdMap } from '../../helpers/helpers';

import {
  downloadSageExportFile,
  constructExportData,
} from '../sageExportHelpers';

const WORK_TYPES = new Set(['work','service']);
const TIME_FORMAT = 'hh:mm a';
const DATE_FORMAT = 'MM/DD/YYYY';

const basicColumn = ({ title, dataIndex }) => ({
  title,
  dataIndex,
  width: 150,
  sorter:sortByString(dataIndex),
  showSorterTooltip:false,
  sortDirections:['descend','ascend'],
});

const selectColumn = ({ title, dataIndex, options, onChange, disabled = () => false }) => ({
  title,
  dataIndex,
  width: 150,
  sorter:sortByString(dataIndex),
  showSorterTooltip:false,
  sortDirections:['descend','ascend'],
  render: (value, record) => (
    <Row justify='space-between' style={{ width: 150 }} align='middle'>
      <Col span={20}>
        <Select
          options={options(record)}
          value={value}
          onChange={onChange(record)}
          style={{ width: 100 }}
          disabled={disabled(record)}
          showSearch
          optionFilterProp='label'
        />
      </Col>
      <Col span={4} style={{ height: 20 }}>
        {
          !value &&
          <Popover title='Warning' content={`Can't export entries to Sage without a ${title}`}>
            <ExclamationCircleOutlined style={{ color: 'red' }}/>
          </Popover>
        }
      </Col>
    </Row>
  ),
})

export default ({
  visible,
  onClose,
  filters = {},
  isRounded,
  rangeValue,
}) => {
  const { t } = useTranslation();
  const [startDate, endDate] = rangeValue;
  const {
    company: {
      settings = {},
    } = {},
  } = useSelector((state) => state.settings);
  const projects = useSelector(state => state.projects.projects);
  const {
    costcodes = [],
    phases = [],
  } = useSelector(state => state.costcodes);
  const unionClasses = useSelector((state) => state.unions.classes);
  const sageShifts = useSelector(state => state.sage.shifts);
  const payIdMappings = useSelector(state => state.sage.payIdMappings);
  const timeEntryUserMap = useSelector(state => state.timeTracking.timeEntryUserMap);
  const selectedDivisions = useSelector(state => state.settings.selectedDivisions);

  const [loading, setLoading] = useState(false);
  const [lastPull, setLastPull] = useState(DateTime.fromMillis(0));
  const [sageData, setSageData] = useState({});
  const [tableData,setTableData] = useState([]);

  const sageCodeToCategoryMap = useMemo(() => {
    const { costcodeMap = {} } = sageData;
    const newMap = {};
    Object.values(costcodeMap).forEach(({ code, category }) => {
      newMap[code] = category;
    });
    return newMap;
  },[sageData]);

  const onChange = useCallback((id, newData = {}) => {
    setTableData(
      tableData.map((row) => {
        if (row.id !== id) return row;
        return {
          ...row,
          ...newData,
        };
      })
    );
  },[tableData]);

  useEffect(() => {
    const now = DateTime.local();
    const getData = async () => {
      setLoading(true);
      try {
        const { data } = await axios.get('/sage/export', { params: { version: 'sage300', divisionIds: Array.from(selectedDivisions) }});
        setLastPull(now);
        setSageData(data);
      } catch (err) {
        Sentry.captureException(err);
      }
      setLoading(false);
    }
    if (visible && now.diff(lastPull).as('minutes') > 15) {
      getData();
    }
  },[visible, lastPull, selectedDivisions]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const {
    localMap,
    classMap,
  } = useMemo(() => {
    const cMap = {};
    const lMap = {};
    unionClasses.forEach((unionClass) => {
      const {
        localId,
        id: classId,
        name
      } = unionClass;
      if (!(localId in lMap)) lMap[localId] = [];
      lMap[localId].push({ label: name, value: classId });

      cMap[classId] = unionClass;
    });
    return { localMap: lMap, classMap: cMap };
  },[unionClasses]);

  const onSubmit = useCallback(async () => {
    await downloadSageExportFile({
      sageData,
      tableData,
      classMap,
      sageShifts,
    });
    onClose();
  },[tableData, sageData, classMap, sageShifts]);

  const sageShiftToOnTraccrId = useMemo(() => {
    const sageShiftMap = {};
    Object.keys(sageShifts).forEach((ontraccrId) => {
      const { sageId } = sageShifts[ontraccrId];
      sageShiftMap[sageId] = ontraccrId;
    });
    return sageShiftMap;
  },[sageShifts]);

  const projectIdToSagePayId = useMemo(() => {
    const pId2SPId = {};
    payIdMappings.forEach(({ projectId, sagePayId }) => {
      pId2SPId[projectId] = sagePayId;
    });
    return pId2SPId;
  },[payIdMappings]);

  useEffect(() => {
    if (!visible) {
      setTableData([]);
      return;
    }
    setTableData(constructExportData({
      isRounded,
      startDate,
      endDate,
      projectIdMap,
      phaseIdMap,
      costcodeIdMap,
      classMap,
      filters,
      sageData,
      settings,
      sageShiftToOnTraccrId,
      projectIdToSagePayId,
      timeEntryUserMap,
    }));
  }, [
    visible,
    filters,
    isRounded,
    startDate,
    endDate,
    settings,
    projectIdMap,
    phaseIdMap,
    costcodeIdMap,
    classMap,
    sageShiftToOnTraccrId,
    sageData,
    projectIdToSagePayId,
    timeEntryUserMap,
  ])


  const sageShiftOptions = useMemo(() => {
    const shiftArr = Object.values(sageShifts);
    shiftArr.sort(sortByString('name'));
    return shiftArr.map((shift) => ({ label: shift.name, value: shift.id }));
  },[sageShifts]);

  const columns = useMemo(() => {
    const {
      jobCodes = [], // Unique job codes in Sage
      jobCostcodeMap = {}, // Map of Sage job codes to associated Sage cost codes.
    } = sageData;
    return [
      {
        title: 'Name',
        dataIndex: 'name',
        fixed: 'left',
        width: 150,
        sorter:sortByString('name'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      },
      {
        title: 'Sage ID',
        dataIndex: 'employeeCode',
        fixed: 'left',
        width: 150,
        sorter:sortByString('employeeCode'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      },
      basicColumn({ title: t('Project'), dataIndex: 'projectName'}),
      selectColumn({
        title: 'Sage Job Code',
        dataIndex: 'jobCode',
        options: () => jobCodes.map((jc) => ({ label: jc, value: jc})),
        onChange: (record) => (newValue) => {
          const { jobCode } = record;
          const newData = { jobCode: newValue };
          if (jobCode !== newValue) {
            newData.sageCostcode = undefined;
          }
          onChange(record.id, newData);
        },
      }),
      basicColumn({ title: 'Cost Code', dataIndex: 'costCode'}),
      selectColumn({
        title: 'Sage Cost Code',
        dataIndex: 'sageCostcode',
        options: (record) => {
          const { jobCode } = record;
          const {
            [jobCode]: sageCostcodes = [],
          } = jobCostcodeMap;
          return sageCostcodes.map((cc) => ({ label: cc, value: cc}));
        },
        disabled: (record) => !record.jobCode,
        onChange: (record) => (newValue) => {
          onChange(record.id, {
            sageCostcode: newValue,
            sageCategory: sageCodeToCategoryMap[newValue],
          });
        },
      }),
      basicColumn({ title: 'Sage Cost Code Category', dataIndex: 'sageCategory'}),
      {
        title: 'Date',
        dataIndex: 'startTime',
        width: 150,
        render: (value, record) => (
          <DatePicker
            value={value}
            format={DATE_FORMAT}
            onChange={(newDate) => {
              onChange(record.id, {
                startTime: newDate.clone().hour(value.hour()).minute(value.minute())
              });
            }}
          />
        ),
        sorter: (a, b) => b.startTime - a.startTime,
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      }, {
        title: 'Start Time',
        dataIndex: 'startTime',
        width: 150,
        render: (value, record) => (
          <TimePicker
            value={value}
            format={TIME_FORMAT}
            use12Hours
            showNow={false}
            onChange={(newDate) => {
              onChange(record.id, {
                startTime: newDate.clone().year(value.year()).month(value.month()).day(value.day())
              });
            }}
          />
        ),
        sorter: (a, b) => b.startTime - a.startTime,
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      }, {
        title: 'End Time',
        dataIndex: 'endTime',
        width: 150,
        render: (value, record) => (
          <TimePicker
          value={value}
          format={TIME_FORMAT}
          use12Hours
          showNow={false}
          onChange={(newDate) => {
            onChange(record.id, {
              endTime: newDate.clone().year(value.year()).month(value.month()).day(value.day())
            });
          }}
        />
        ),
        sorter: (a, b) => b.endTime - a.endTime,
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      }, {
        title: 'Work Classification',
        dataIndex: 'classId',
        sorter: sortByString('className'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        render: (classId, record) => {
          const {
            [classId]: {
              localId,
            } = {},
          } = classMap;
          const {
            [localId]: localClasses = [],
          } = localMap;
          return (
            <Select
              style={{ width: 150 }}
              options={localClasses}
              value={classId}
              onSelect={(newClassId) => onChange(record.id, { classId: newClassId })}
              showSearch
              optionFilterProp='label'
            />
          )
        }
      }, {
        title: 'Pay ID',
        dataIndex: 'payCode',
        width: 150,
        sorter:sortByString('payCode'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        render: (payCode, record) => {
          const { payCodes = [] } = sageData;
          return (
            <Select
              value={payCode}
              options={payCodes.map((pc) => ({ label: pc, value: pc }))}
              dropdownMatchSelectWidth={false}
              onSelect={(newPaycode) => onChange(record.id, { payCode: newPaycode })}
              showSearch
              optionFilterProp='label'
            />
          )
        }
      }, {
        title: 'Shift',
        dataIndex: 'sageShiftId',
        width: 150,
        sorter:sortByString('sageShiftId'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        render: (sageShiftId, record) => {
          return (
            <Select
              style={{ width: '100%' }}
              value={sageShiftId}
              options={sageShiftOptions}
              dropdownMatchSelectWidth={false}
              onSelect={(newShift) => onChange(record.id, { sageShiftId: newShift })}
            />
          )
        }
      },
    ];
  },[classMap, localMap, sageData, sageCodeToCategoryMap, onChange, sageShiftOptions]);
  return (
    <Drawer
      title='Preview Sage Export'
      width='calc(100vw - 150px)'
      visible={visible}
      onClose={onClose}
    >
      {
        loading
        ? <Row style={{ width: '100%', height: '100%'}} justify='center' align='middle'>
          <Col>
            <Spin/>
          </Col>
        </Row>
        :
        <Table
          className='sage-export-preview-table'
          dataSource={tableData}
          columns={columns}
          scroll={{
            y: 'calc(100vh - 213px)',
            x: true
          }}
          size='small'
          pagination={false}
        />
      }
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}
