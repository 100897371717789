import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import fileDefaults from '../../assets/images/NUX/fileDefaults.png';
import fileStorage from '../../assets/images/NUX/fileStorage.png';


const getDefaultDesc = (t) => (
<div style={{ fontSize: 13 }}>
Ontraccr maintains two default,
locked folders to make it easier for you to access and organize files.
The 'Attachments' folder is a running,
organized record of any files or photos that users have ever sent via the Live Feed.
The '{t('Project', { count: 2 })}' folder contains automatically-generated sub-folders for each {t('Project').toLowerCase()} created in the app.
</div>
);

export default function NuxManageDocs({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const defaultDesc = getDefaultDesc(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 74px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:'Default System Folders',
        description:defaultDesc,
        cols:[0,18,6],
        image:{
          src:fileDefaults,
          style:{
            height:75,
            width:168,
          }
        },
      },{
        title:'Manage File Storage',
        description:'',
        cols:[0,10,24],
        image:{
          src:fileStorage,
          style:{
            height:30,
            width:532,
          }
        },
      },{
        title:'',
        description:`Use the storage meter to keep an eye on how much cloud storage you've used and how much you have left. If you require more space, simply click 'upgrade' or contact your Ontraccr account team for assistance.`,
        cols:[0,24,0],
      }]}
    />
  );
}
