import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import ClientPortalLogin from './ClientPortalLogin';
import ClientPortalMainScreen from './ClientPortalMainScreen';

import { loadClientTokenFromStorage } from './state/clientPortal.actions';

export default function ClientPortal() {
  const dispatch = useDispatch();
  const loggedIn = useSelector((state) => state.clientPortal.loggedIn);

  useEffect(() => {
    dispatch(loadClientTokenFromStorage());
  }, [dispatch]);

  return loggedIn
    ? <ClientPortalMainScreen />
    : <ClientPortalLogin />;
}
