import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import * as XLSX from 'xlsx';

import StepsContainer from '../../common/containers/StepsContainer';
import SheetUploadPrompt from '../../common/excel/SheetUploadPrompt';

import SheetHeaderMatcher from '../../common/excel/SheetHeaderMatcher';
import MaterialSheetSelector from './MaterialSheetSelector';
import MaterialUploadPreview from './MaterialUploadPreview';

const defaultHeaders = [
  { key: 'name', title: 'Name' },
  { key: 'description', title: 'Description' },
  { key: 'partNumber', title: 'Part Number' },
  { key: 'supplier', title: 'Supplier' },
  { key: 'cost', title: 'Cost' },
  { key: 'markup', title: 'Markup' },
  { key: 'units', title: 'Units' },
  { key: 'quantity', title: 'Quantity' },
];

export default ({
  visible,
  uploadFile,
  onFileChange,
  currentStep,
  onDivisionChange,
  onMassUploadDataChanged,
}) => {
  const divisions = useSelector((state) => state.settings.divisions);

  const [selectedDivision,setSelectedDivision] = useState();
  const [selectedSheetName,setSelectedSheetName] = useState();
  const [headerMapping,setHeaderMapping] = useState({});
  const sheets = useMemo(() => {
    if(!uploadFile) return {};
    const {
      Sheets = {},
    } = uploadFile;
    return Sheets;
  },[uploadFile]);

  const selectedSheet = useMemo(() => {
    if(!sheets || !selectedSheetName) return [];
    const {
      [selectedSheetName]: fullSheet = {},
    } = sheets;

    return XLSX.utils.sheet_to_json(fullSheet, { defval: '' });
  },[sheets,selectedSheetName]);

  useEffect(() => {
    if (!uploadFile) return;
    const {
      SheetNames = [],
    } = uploadFile;
    if(!selectedSheetName || (SheetNames.length === 1 && selectedSheetName !== SheetNames[0])) {
      setSelectedSheetName(SheetNames[0]);
    }
  },[selectedSheetName, uploadFile]);

  useEffect(() => {
    const divisionList = Object.values(divisions);
    if(divisionList.length === 1 || !selectedDivision) {
      setSelectedDivision(divisionList[0].id);
    }
  },[divisions]);

  useEffect(() => {
    onDivisionChange(selectedDivision);
  },[selectedDivision]);

  useEffect(() => {
    if(!visible) {
      setSelectedDivision();
      setSelectedSheetName();
      setHeaderMapping({})
    }
  },[visible]);

  return (
    <div style={{ padding: 24 }}>
      {uploadFile ?
      <StepsContainer
        currentStep={currentStep}
        steps={[
          {
            title: 'Select Sheet',
            content: (
              <MaterialSheetSelector
                workbook={uploadFile}
                onSheetChanged={setSelectedSheetName}
                divisionId={selectedDivision}
                onDivisionChanged={setSelectedDivision}
              />
            ),
          },
          {
            title: 'Select Headers',
            content: (
              <SheetHeaderMatcher
                headerOptions={defaultHeaders}
                selectedSheet={selectedSheet}
                headerMapping={headerMapping}
                setHeaderMapping={setHeaderMapping}
              />
            ),
          },
          {
            title: 'Preview',
            content: (
              <MaterialUploadPreview
                selectedDivision={selectedDivision}
                selectedSheet={selectedSheet}
                headerMapping={headerMapping}
                onMassUploadDataChanged={onMassUploadDataChanged}
              />
            ),
          }
        ]}
      />
      : <SheetUploadPrompt onFileChange={onFileChange} />}
    </div>
  );
}
