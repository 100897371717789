import {
  GET_UNIONS,
  UPDATE_UNION,
  UPDATE_UNION_LOCAL,
  CREATE_UNION,
  CREATE_UNION_LOCAL,
  CREATE_UNION_CLASS,
  DELETE_UNION,
  DELETE_UNION_LOCAL,
  DELETE_UNION_CLASS,
  UPDATE_UNION_CLASS,
} from '../../state/actionTypes';
import UnionService from './unions.service';

export default {};

export const getUnions = () => async (dispatch) => {
  const { data } = await UnionService.get();
  if (!data) return false;
  dispatch({
    type: GET_UNIONS,
    payload: {
      unions: data,
    },
  });
  return true;
};

export const updateUnion = (id, payload) => async (dispatch) => {
  const { data } = await UnionService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_UNION,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const updateUnionLocal = (id, payload) => async (dispatch) => {
  const { data } = await UnionService.updateLocal(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_UNION_LOCAL,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const updateUnionClass = (id, payload) => async (dispatch) => {
  const { data } = await UnionService.updateClass(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_UNION_CLASS,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const createUnion = (payload) => async (dispatch) => {
  const { data } = await UnionService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_UNION,
    payload: data,
  });
  return true;
};

export const createUnionLocal = (payload) => async (dispatch) => {
  const { data } = await UnionService.createLocal(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_UNION_LOCAL,
    payload: data,
  });
  return true;
};

export const createUnionClass = (payload) => async (dispatch) => {
  const { data } = await UnionService.createClass(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_UNION_CLASS,
    payload: data,
  });
  return true;
};

export const deleteUnion = (type, id) => async (dispatch) => {
  const { data } = await UnionService.delete(type, id);
  if (!data) return false;
  let action;
  switch (type) {
    case 'local':
      action = DELETE_UNION_LOCAL;
      break;
    case 'class':
      action = DELETE_UNION_CLASS;
      break;
    case 'union':
    default:
      action = DELETE_UNION;
      break;
  }
  dispatch({
    type: action,
    payload: {
      id,
    },
  });
  return true;
};
