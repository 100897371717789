export const DEFAULTS_HEADERS = [
  { key: 'title', title: 'Title' },
  { key: 'color', title: 'Color' },
];

export const COLOR_OPTIONS_MAP = {
  red: '#FF0000FF',
  green: '#008000FF',
  blue: '#0000FFFF',
  orange: '#FFA500FF',
  white: '#FFFFFFFF',
  yellow: '##FFFF00FF',
  gray: '#808080FF',
};

export const BASE_COL_WIDTH = 150;

/**
 * Returns the 4-byte Hex string of the color
 * @param {string} hex - hex color string
 * @returns {string} - 4-byte Hex string of the color
 */
export const parseHexString = (hex) => {
  if (hex.length > 9) return null;
  const hexRegex = /^#?([A-Fa-f0-9]{8}|[A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;
  if (!hexRegex.test(hex)) return null;

  let parsedHex = hex;

  // Hex looks like #...... -> we want to remove '#' for parsing
  if (hex.startsWith('#')) {
    parsedHex = hex.substring(1);
  }

  // Hex looks like #FFF -> we want to expand it to #FFFFFF and add the alpha channel
  if (parsedHex.length === 3) {
    parsedHex = parsedHex.split('').map((char) => char + char).join('');
    return `#${parsedHex.toUpperCase()}FF`;
  }

  // Hex looks like #FFFFFF -> we want to add the alpha channel
  if (parsedHex.length === 6) {
    return `#${parsedHex.toUpperCase()}FF`;
  }

  // Hex looks like #FFFFFFFF -> This is already parsed
  return `#${parsedHex.toUpperCase()}`;
};
