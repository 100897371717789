import React from 'react';
import {
  Checkbox,
  Radio,
  Row,
  Select,
} from 'antd';
import PropTypes from 'prop-types';

import Colors from '../../../constants/Colors';
import { filterSelectDropdown } from '../../../helpers/helpers';

export default function DropdownFieldSelect({
  style,
  dropdownStyle,
  placeholder,
  numAnswers,
  openLimit,
  onChange,
  value,
  disabled,
  options,
  actualSelected,
  actualSelectedSet,
}) {
  switch (dropdownStyle) {
    case 'dropdown':
      return (
        <Select
          style={style}
          placeholder={placeholder}
          mode={numAnswers > 1 || openLimit ? 'multiple' : null}
          onChange={onChange}
          value={value}
          optionFilterProp="label"
          showSearch
          allowClear
          disabled={disabled}
          filterOption={filterSelectDropdown}
        >
          {options.map(({
            id: optionId,
            name: optionName,
            number: optionNumber,
            label: optionLabel,
            subNames,
          }) => {
            const label = optionLabel ?? optionName;

            return (
              <Select.Option
                disabled={
                  !openLimit
                  && numAnswers > 1
                  && actualSelected.length >= numAnswers
                  && !actualSelectedSet.has(optionId)
                }
                value={optionId}
                key={optionId}
                label={label}
                name={optionName}
                number={optionNumber}
              >
                {label}
                {subNames?.length ? (
                  subNames.map((subName) => (
                    <div
                      key={subName}
                      style={{ color: Colors.ONTRACCR_OPACITY_GRAY, fontSize: 12 }}
                    >
                      {subName}
                    </div>
                  ))
                ) : null}
              </Select.Option>
            );
          })}
        </Select>
      );
    case 'radio':
    case 'checkbox': {
      const Component = dropdownStyle === 'radio' ? Radio : Checkbox;
      // Display all options in one row if there are less than 5 options
      const shouldBeOneRow = dropdownStyle === 'radio' && options.length <= 5;
      const DivComponent = shouldBeOneRow ? React.Fragment : Row;
      const formattedValue = dropdownStyle === 'radio' && value?.length ? value[0] : value;

      return (
        <Component.Group
          disabled={disabled}
          value={formattedValue}
          onChange={onChange}
        >
          {options.map(({ id, name, label }) => (
            <DivComponent key={id}>
              <Component
                value={id}
                disabled={
                  !openLimit
                  && dropdownStyle === 'checkbox'
                  && actualSelected.length >= numAnswers
                  && !actualSelectedSet.has(id)
                }
              >
                {label ?? name}
              </Component>
            </DivComponent>
          ))}
        </Component.Group>
      );
    }
    default:
      return null;
  }
}

DropdownFieldSelect.propTypes = {
  style: PropTypes.shape({}),
  dropdownStyle: PropTypes.string,
  placeholder: PropTypes.string,
  numAnswers: PropTypes.number,
  openLimit: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  actualSelected: PropTypes.arrayOf(PropTypes.string),
  actualSelectedSet: PropTypes.instanceOf(Set),
  projectIdMap: PropTypes.objectOf(PropTypes.shape({})),
  dataType: PropTypes.string,
  settings: PropTypes.shape({}),
};

DropdownFieldSelect.defaultProps = {
  style: {},
  dropdownStyle: 'dropdown',
  placeholder: '',
  numAnswers: 1,
  openLimit: false,
  value: [],
  disabled: false,
  options: [],
  actualSelected: [],
  actualSelectedSet: new Set(),
  projectIdMap: {},
  dataType: null,
  settings: {},
};
