import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox, Drawer, Form, Select, Row, Col,
} from 'antd';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../../common/inputs/FormTextInput';
import HoverHelp from '../../../common/HoverHelp';

import {
  createSalesRabbitRegion,
  updateSalesRabbitRegion,
} from './state/salesrabbit.actions';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

function SalesRabbitRegionDrawer({
  visible,
  onClose,
  onDelete,
  selectedRegionId,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const workflows = useSelector((state) => state.salesrabbit.workflows);
  const regions = useSelector((state) => state.salesrabbit.regions);

  const selectedRegion = regions[selectedRegionId] ?? {};

  const [loading, setLoading] = useState(false);

  const onDeleteClicked = useCallback(() => {
    if (!onDelete || !selectedRegionId) return;
    onDelete(selectedRegionId);
  }, [onDelete, selectedRegionId]);

  const onSubmit = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      // no-op
    }
    if (!values) return;
    setLoading(true);

    const action = selectedRegionId
      ? dispatch(updateSalesRabbitRegion(selectedRegionId, {
        ...values, isDefault: !!values.isDefault,
      }))
      : dispatch(createSalesRabbitRegion(values));
    const res = await action;
    setLoading(false);
    if (res) onClose();
  }, [selectedRegionId]);

  const workflowOpts = useMemo(() => (
    Object.values(workflows)
      .map((workflow) => ({ value: workflow.id, label: workflow.title }))
  ), [workflows]);

  useEffect(() => {
    if (visible) {
      const newRegion = regions[selectedRegionId] ?? {};
      form.setFieldsValue(newRegion);
    } else {
      form.resetFields();
    }
  }, [form, selectedRegionId, regions, visible]);

  const title = selectedRegion.id
    ? `Edit ${selectedRegion.title}`
    : 'Add SalesRabbit Workflow';

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      width={800}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ marginBottom: 50 }}
        initialValues={selectedRegion}
      >
        <FormTextInput
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Title is required' }]}
          isNotDisplay
        />

        <Form.Item
          name="isDefault"
          label={(
            <Row gutter={10}>
              <Col>
                Make Default Region
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ maxWidth: 200 }}>
                      Any unrecognized cities will be associated to this region.
                      <br />
                      <br />
                      There can only be one default region.
                    </div>
                  )}
                />
              </Col>
            </Row>
          )}
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          name="workflowIds"
          label="Workflows"
          rules={[{ required: true, message: 'Workflows is required' }]}
        >
          <Select
            options={workflowOpts}
            optionFilterProp="label"
            showSearch
            mode="multiple"
          />
        </Form.Item>
        <Form.Item
          name="cities"
          label="Cities"
          rules={[
            { required: true, message: 'Please add at least one city to the region' },
            { min: 1, type: 'array', message: 'Please add at least one city to the region' },
          ]}
        >
          <Select
            mode="tags"
          />
        </Form.Item>

      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
        onDelete={selectedRegionId ? onDeleteClicked : null}
      />
    </Drawer>
  );
}

SalesRabbitRegionDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedRegionId: PropTypes.string,
};

SalesRabbitRegionDrawer.defaultProps = {
  selectedRegionId: null,
};

export default SalesRabbitRegionDrawer;
