import React, { useState, useEffect, useCallback } from 'react';
import { Form } from 'antd';

import OnTraccrNumberInput from '../../inputs/OnTraccrNumberInput';

import Debouncer from '../../../helpers/Debouncer';

export default ({
  optionKey,
  title = optionKey,
  drawOptions,
  style = {},
  max,
  onDrawOptionChanged,
  min = 0,
}) => {
  const {
    [optionKey]: value,
  } = drawOptions;
  const [changeDebouncer,setChangeDebouncer] = useState();
  const [debouncer,setDebouncer] = useState();
  const [controlledValue,setControlledValue] = useState(value);

  const onChange = useCallback((newVal) => {
    changeDebouncer.debounce(() => {
      onDrawOptionChanged({ [optionKey]: newVal });
      setControlledValue(newVal);
    }, 250)
  },[changeDebouncer, optionKey,onDrawOptionChanged]);

  useEffect(() => {
    setDebouncer(new Debouncer());
    setChangeDebouncer(new Debouncer());
  },[optionKey]);

  useEffect(() => {
    // If user is dragging the view around it will attempt to update the Picker
    // very frequently, causing the dragging to lag.
    // Set up a debouncer to only update the picker value after dragging is complete
    if(debouncer) {
      debouncer.debounce(() => setControlledValue(value), 250);
    }
  },[value,debouncer]);
  
  return (
    <Form.Item
      key={optionKey}
      label={title}
      style={style}
    >
      <OnTraccrNumberInput
        style={{ width: '100%' }}
        min={min}
        max={max}
        value={controlledValue}
        onChange={onChange}
      />
    </Form.Item>
  );
}
