import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Col } from 'antd';
import { EyeOutlined, PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import ClientPortalPreview from '../../settings/ClientPortal/ClientPortalPreview';

import CustomerClientAccountAddDrawer from './CustomerClientAccountAddDrawer';

import colors from '../../constants/Colors';

import { deleteClientPortalAccount } from '../../clientPortal/state/clientPortal.actions';

export default ({
  customerId,
}) => {
  const dispatch = useDispatch();
  const portalAccounts = useSelector(state => state.clientPortal.accounts);
  const customers = useSelector(state => state.customers.customers);

  const [previewVisible, setPreviewVisible] = useState(false);
  const [accountDrawerVisible, setAccountDrawerVisible] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState();

  const ourCustomer = useMemo(() => customers[customerId] ?? {}, [customers, customerId]);
  const relevantAccounts = useMemo(() => (
    portalAccounts.filter((acc) => acc.customerId === customerId)
  ), [customerId, portalAccounts]);

  const showPreview = useCallback(() => setPreviewVisible(true),[]);
  const hidePreview = useCallback(() => setPreviewVisible(false),[]);

  const hideAccountDrawer = useCallback(() => {
    setAccountDrawerVisible(false);
    setSelectedAccount();
  },[]);

  const onAddClicked = useCallback(() => {
    if (relevantAccounts.length === 0) {
      const {
        officeEmail,
      } = ourCustomer;
      if (officeEmail) {
        setSelectedAccount({
          email: officeEmail,
          generatePassword: true,
        });
      }
    }
    setAccountDrawerVisible(true)
  },[relevantAccounts, ourCustomer]);
  const onEditClicked = useCallback((record) => {
    setSelectedAccount(record);
    setAccountDrawerVisible(true);
  },[]);

  const onDeleteClicked = useCallback((record) => {
    const { id, email } = record;
    CustomConfirmModal({
      title: `Delete account for ${email}?`,
      okText: 'Delete',
      onOk: () => {
        dispatch(deleteClientPortalAccount(id));
      }
    })
  },[]);

  const columns = useMemo(() => [
    {
      title: 'Email',
      dataIndex:'email'
    }, {
      dataIndex:'edit',
      align:'edit',
      width: 60,
      render:(_,record) => (
        <BorderlessButton
          title=''
          icon='edit'
          color={colors.ONTRACCR_BLACK}
          onClick={() => onEditClicked(record)}
          style={{
            paddingRight:8, 
            paddingLeft:0,
          }}
        />
      ),
    },
    {
      dataIndex:'action',
      key:'action',
      align:'center',
      width: 60,
      render:(_,record) => (
        <BorderlessButton
          title=''
          icon='delete'
          color={colors.ONTRACCR_RED}
          onClick={() => onDeleteClicked(record)}
          style={{
            paddingRight:8, 
            paddingLeft:0,
          }}
        />
      ),
    }
  ],[onDeleteClicked, onEditClicked]);

  return (
    <>
      <div className='customer-portal-container'>
        <Row className='customer-project-search-row' justify='end' align='middle' style={{ border: 'none', marginTop: 2 }}>
        <Col>
            <Row justify='space-between' gutter={10}>
              <Col>
                <OnTraccrButton
                  title='Add'
                  onClick={onAddClicked}
                  icon={<PlusOutlined />}
                />
              </Col>
              <Col>
                <OnTraccrButton
                  title='Preview'
                  onClick={showPreview}
                  icon={<EyeOutlined />}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <div className='file-list-container'>
          <Table
            dataSource={relevantAccounts}
            columns={columns}
            size='small'
            pagination={false}
            scroll={{ y: 'calc(100vh - 220px' }}
          />
        </div>
      </div>
      <ClientPortalPreview visible={previewVisible} onClose={hidePreview}/>
      <CustomerClientAccountAddDrawer
        visible={accountDrawerVisible}
        onClose={hideAccountDrawer}
        customerId={customerId}
        selectedAccount={selectedAccount}
      />
    </>
  )
}
