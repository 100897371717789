import React, {
  useMemo, useState, useCallback, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Divider,
  Popover,
  Table,
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

import CompanySettingsCard from '../../CompanySettingsCard';

import QuickbooksIntegration from './QuickbooksIntegration';
import QuickbooksIntegrationDrawer from './QuickBooksIntegationDrawer';
import QBAuditLogDrawer from './QBAuditLog/QBAuditLogDrawer';

import { getQuickbooksIntegrations } from '../../state/settings.actions';

import useToggle from '../../../common/hooks/useToggle';
import ErrorRecipientSelector from '../ErrorRecipientSelector';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

function QuickBooks({
  visible,
}) {
  const dispatch = useDispatch();

  const divisions = useSelector((state) => state.settings.divisions);
  const integrations = useSelector((state) => state.settings.quickbookIntegrations);
  const [addDrawerOpen, setAddDrawerOpen] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState();

  const {
    isToggled: logDrawerOpen,
    toggle: toggleLogDrawer,
  } = useToggle();

  const closeAddDrawer = useCallback(() => {
    setAddDrawerOpen(false);
    setSelectedIntegration();
  }, []);
  const openAddDrawer = useCallback(() => setAddDrawerOpen(true), []);
  const onRowClick = useCallback((record) => {
    openAddDrawer();
    setSelectedIntegration(record);
  }, [openAddDrawer]);

  useEffect(() => {
    if (visible) {
      dispatch(getQuickbooksIntegrations());
    }
  }, [visible]);

  const columns = useMemo(() => [{
    title: 'Divisions',
    dataIndex: 'divisions',
    onHeaderCell,
    render: (divisionIds) => {
      const {
        names,
        excessCount,
        allNames = [],
      } = divisionIds?.reduce((acc, divId, index) => {
        const { [divId]: { name } = {} } = divisions;
        if (!name) return acc;
        acc.allNames.push(<p>{name}</p>);
        if (index > 2) {
          acc.excessCount += 1;
        } else {
          acc.names += `${index === 0 ? '' : ', '}${name}`;
        }
        return acc;
      }, { names: '', excessCount: 0, allNames: [] }) ?? {};
      if (excessCount === 0) return names;
      const suffix = excessCount === 1 ? '' : 's';
      const fullName = excessCount === 0
        ? names
        : `${names} and ${excessCount} other division${suffix}`;
      return (
        <Popover
          content={(
            <div>
              <span style={{ fontWeight: 'bold', fontSize: 18, color: 'black' }}>Divisions</span>
              <Divider style={{ margin: 0, marginBottom: 5 }} />
              {allNames}
            </div>
          )}
        >
          {fullName}
        </Popover>
      );
    },
  }], [divisions]);

  const isSingleDiv = useMemo(() => Object.keys(divisions).length <= 1, [divisions]);
  return (
    <>
      <CompanySettingsCard
        title="QuickBooks"
        leftHeaderView={(
          <Row justify="end" gutter={10}>
            <Col>
              {
                !integrations?.length
                  ? null
                  : (
                    <OnTraccrButton
                      title="Log"
                      type="primary"
                      onClick={toggleLogDrawer}
                    />
                  )
              }
            </Col>
            <Col>
              {
                isSingleDiv
                  ? null
                  : (
                    <OnTraccrButton
                      title="Add"
                      type="primary"
                      onClick={openAddDrawer}
                    />
                  )
              }
            </Col>
          </Row>
      )}
      >
        {
          isSingleDiv
            ? (
              <QuickbooksIntegration
                selectedIntegration={integrations?.[0]}
                visible={visible}
                isSingleDiv
              />
            ) : (
              <Table
                columns={columns}
                dataSource={integrations}
                pagination={false}
                size="small"
                scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
                onRow={(record) => ({
                  onClick: () => onRowClick(record),
                })}
                rowClassName="quickbooks-integration-row"
              />
            )
        }
      </CompanySettingsCard>
      <CompanySettingsCard title="Settings">
        <ErrorRecipientSelector
          recipientsKey="qboErrorRecipients"
          integration="Quickbooks"
        />
      </CompanySettingsCard>
      <QuickbooksIntegrationDrawer
        visible={addDrawerOpen}
        onClose={closeAddDrawer}
        selectedIntegration={selectedIntegration}
      />
      <QBAuditLogDrawer
        visible={logDrawerOpen}
        onClose={toggleLogDrawer}
      />
    </>
  );
}

QuickBooks.propTypes = {
  visible: PropTypes.bool.isRequired,
};

export default QuickBooks;
