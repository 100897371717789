import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import FormResponseFields from '../../forms/FormResponseFields';

export default function CustomFields({
  initialValues,
  customFields,
  divisions,
  errors,
  fileMap,
  onChange,
  isDisplay,
  value,
}) {
  const [responses, setResponses] = useState({});

  // https://github.com/ant-design/ant-design/issues/37657
  // We cannot add onChange to the deps due to above issue
  useEffect(() => {
    onChange(responses);
  }, [responses]);

  if (!customFields || customFields.length === 0) return null;
  return (
    <div className="custom-field-container">
      <FormResponseFields
        errors={errors}
        sections={customFields}
        visible
        responses={{ ...value, ...responses }}
        onResponsesChanged={setResponses}
        fileMap={fileMap}
        initialData={{ sections: initialValues }}
        divisions={divisions}
        style={{
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'visible',
          padding: 0,
          position: 'relative',
          height: '100%',
          width: '100%',
        }}
        isDisplay={isDisplay}
      />
    </div>
  );
}

CustomFields.propTypes = {
  value: PropTypes.objectOf({
    fieldId: PropTypes.string,
  }),
  initialValues: PropTypes.arrayOf({
    fieldId: PropTypes.string,
  }),
  errors: PropTypes.objectOf({
    fieldId: PropTypes.string,
  }),
  customFields: PropTypes.arrayOf({
    fieldId: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  divisions: PropTypes.arrayOf(PropTypes.string),
  fileMap: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  isDisplay: PropTypes.bool.isRequired,
};

CustomFields.defaultProps = {
  value: {},
  initialValues: [],
  errors: {},
  divisions: [],
};
