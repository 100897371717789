import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Checkbox } from 'antd';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';

export default ({
  onChange,
  value,
  initialValue,
}) => {
  const [showTextInput, setShowTextInput] = useState(!!initialValue);
  const onCheckChange = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setShowTextInput(checked);
    if (!checked) onChange(null);
  },[onChange]);
  useEffect(() => {
    setShowTextInput(!!initialValue);
  },[]);
  return (
    <>
      <Row gutter={10} style={{ height: 32 }} align='middle'>
        <Col>
          <Checkbox
            onChange={onCheckChange}
            checked={showTextInput}
          >
              Requires PIN to lock/unlock?
          </Checkbox>
        </Col>
        <Col flex='auto'>
        {showTextInput &&
          <OnTraccrTextInput
            onChange={onChange}
            value={value}
            password
          />
        }
        </Col>
      </Row>
    </>
  );
}