import {
  GET_EMAILS,
} from '../../state/actionTypes';

import { getIdMap } from '../../helpers/helpers';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_EMAILS: {
      const {
        payload: {
          emails = [],
        } = {},
      } = action;
      return getIdMap(emails, 'email');
    }
    default:
      return state;
  }
};
