import React, {
  useCallback, useMemo, useState, useEffect,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Drawer, Divider, Empty, message,
} from 'antd';
import PropTypes from 'prop-types';

// Import Components:
import FullPhoto from '../../common/photos/FullPhoto';
import HistoryDrawerHeader from '../history/HistoryDrawerHeader';
import HistoryEntry from '../history/HistoryEntry';

// Import Helpers:
import { getProgressFromState, getProgressHistoryEntryDiffValues } from './progressHelpers';
import { getIdMap } from '../../helpers/helpers';
import { getFileType, downloadFile } from '../../files/fileHelpers';

// Import Actions:
import { addProjectProgressHistoryFiles } from '../state/projects.actions';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export default function ProgressHistoryDrawer({
  visible,
  onClose,
  onUploadInvoice,
  record: {
    id,
    projectId,
    phaseId,
    costcodeId,
    formId,
    name,
    children,
    type,
  } = {},
  hasWrite,
  costcodeIdMap = {},
}) {
  const dispatch = useDispatch();
  const progressState = useSelector((state) => state.reports.progress);
  const taskState = useSelector((state) => state.projects.projectCostcodeDetails);

  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const { phases = [], costcodes = [] } = useSelector((state) => state.costcodes);
  const { projectName = '' } = useSelector((state) => {
    const currentProject = state.projects.projects.find((project) => project.id === projectId);
    return {
      projectName: currentProject && currentProject.name,
    };
  });

  const { progressHistory } = useMemo(() => (
    getProgressFromState({
      progressState,
      taskState,
      id,
      projectId,
      phaseId,
      costcodeId,
      formId,
      children,
    })
  ), [
    progressState,
    taskState,
    id,
    projectId,
    phaseId,
    costcodeId,
    formId,
    children,
  ]);

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [notesValue, setNotesValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [showFileUpload, setShowFileUpload] = useState(false);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseMap = useMemo(() => getIdMap(phases), [phases]);
  const ccMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const clearSelectedFile = useCallback(() => setSelectedFile(null), []);
  const toggleFileUpload = () => setShowFileUpload(!showFileUpload);

  const getName = useCallback(({
    projectId: entryProjectId,
    phaseId: entryPhaseId,
    costcodeId: entryCostcodeId,
  }) => {
    let ourMap = {};
    let ourId;
    if (entryCostcodeId) {
      ourId = entryCostcodeId;
      ourMap = ccMap;
    } else if (entryPhaseId) {
      ourId = entryPhaseId;
      ourMap = phaseMap;
    } else if (entryProjectId) {
      ourId = entryProjectId;
      ourMap = projectMap;
    }
    if (!ourId) return '';
    const {
      [ourId]: {
        name: itemName,
      } = {},
    } = ourMap;
    return itemName;
  }, [projectMap, phaseMap, ccMap]);

  const onSubmit = useCallback(async () => {
    if (isLoading) {
      return;
    }

    if ((notesValue === '' || notesValue === null) && files.length === 0) {
      message.error('Please enter a note or add a file.');
      return;
    }

    const update = {
      description: notesValue,
      projectId,
      phaseId,
      costcodeId,
      formId,
    };

    setIsLoading(true);
    const result = await dispatch(addProjectProgressHistoryFiles(projectId, update, projectName, files));

    if (result) {
      setNotesValue('');
      setFiles([]);
    }

    setIsLoading(false);
  }, [notesValue, projectId, phaseId, costcodeId, history, formId, isLoading, files]);

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const onPreviewClick = useCallback((index) => {
    const file = files[index];

    if (file) {
      if (file instanceof File) {
        setSelectedFile({
          file,
          isNewlyAdded: true,
          type: getFileType(file),
        });
      } else {
        setSelectedFile({
          ...file,
          isNewlyAdded: false,
          type: getFileType(file),
        });
      }
    }
  }, [files]);

  const addFile = (file) => {
    setFiles((prvState) => [...prvState, file]);
  };

  // Resets local state:
  const resetInputFields = () => {
    setFiles([]);
    setSelectedFile({});
    setNotesValue('');
    setShowFileUpload(false);
  };

  // Reset add note/file input on drawer close:
  useEffect(() => {
    if (!visible) {
      resetInputFields();
    }
  }, [visible]);

  const title = useMemo(() => {
    const titleString = `History: ${name}`;
    if (!hasWrite || type === 'Sub-Contract') return titleString;
    return (
      <>
        <span>{titleString}</span>
        <OnTraccrButton
          title="Upload Invoice"
          onClick={() => onUploadInvoice({ costcodeId, phaseId })}
          style={{ marginLeft: 10 }}
        />
      </>
    );
  }, [name, onUploadInvoice, costcodeId, phaseId, hasWrite, type]);

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      width={650}
      push={false}
    >
      <Divider style={{ margin: '5px 0px' }} />
      {hasWrite && (
        <HistoryDrawerHeader
          onSubmit={onSubmit}
          isLoading={isLoading}
          notesValue={notesValue}
          setNotesValue={setNotesValue}
          toggleFileUpload={toggleFileUpload}
          files={files}
          removeFile={removeFile}
          onPreviewClick={onPreviewClick}
          showFileUpload={showFileUpload}
          addFile={addFile}
        />
      )}
      <div style={{ overflowY: 'auto' }}>
        {
        progressHistory.map((progressEntry) => {
          const {
            id,
            userId,
            invoiceNumber,
            invoiceDistributedAmount,
            templateName,
            endTime,
            otEndTime,
            doubleOtEndTime,
          } = progressEntry;
          const entryName = getName(progressEntry);
          const {
            [userId]: user = {},
          } = userMap;
          return (
            <HistoryEntry
              {...getProgressHistoryEntryDiffValues(progressEntry)}
              key={id}
              user={user}
              invoiceNumber={invoiceNumber}
              distributedAmount={invoiceDistributedAmount}
              description={entryName}
              isSov={false}
              templateName={templateName}
              costcodeIdMap={costcodeIdMap}
              costcodeId={costcodeId}
              endTime={endTime}
              otEndTime={otEndTime}
              doubleOTEndTime={doubleOtEndTime}
            />
          );
        })
      }
        {
        progressHistory.length === 0
        && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
      }
      </div>
      <FullPhoto
        file={selectedFile?.file}
        type={selectedFile?.type}
        onClose={clearSelectedFile}
        onDownload={() => {
          downloadFile({ fileObject: selectedFile?.file });
        }}
        useApryse
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
ProgressHistoryDrawer.propTypes = {
  onUploadInvoice: PropTypes.func.isRequired,
  hasWrite: PropTypes.bool,
  costcodeIdMap: PropTypes.object,
};

ProgressHistoryDrawer.defaultProps = {
  hasWrite: false,
  costcodeIdMap: {},
};
