import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import TimeCardsContainer from './TimeCardsContainer';

import { getUnions } from '../unions/state/unions.actions';
import { getSageShifts } from '../sage/state/sage.actions';
import Permissions from '../auth/Permissions';

const CRUMBS = [{
  text: 'Time Cards',
  icon: 'timecard',
}, {
  text: 'My Cards',
}];
export default () => {
  const dispatch = useDispatch();
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  useEffect(() => {
    dispatch(getUnions());
  }, []);

  useEffect(() => {
    dispatch(getSageShifts({ divisionIds: Array.from(selectedDivisions) }));
  }, [selectedDivisions]);

  return (
    <BreadCrumbContainer crumbs={CRUMBS} bodyStyle={{ marginTop: 0 }}>
      <TimeCardsContainer
        isIndividual
        userId={Permissions.id}
      />
    </BreadCrumbContainer>
  );
};
