import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Row,
  Col,
} from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';

import DrawerFooter from '../common/containers/DrawerFooter';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import {
  createSubtask,
  updateSubtask,
  deleteSubtask,
  getSubtaskFiles,
  getSubtaskReminders,
  getSubtaskFormData,
  resetSubtaskFormData,
} from './state/subtasks.actions';
import { compareArrays } from '../helpers/helpers';
import SubtaskForm from './SubtaskForm';
import SubtaskTemplateImportDrawer from './SubtaskTemplateImportDrawer';
import RecurringChangeModal from '../common/modals/RecurringChangeModal';
import { getTemplates } from '../forms/state/forms.actions';

export default function SubtaskDrawer({
  selectedTaskId,
  onClose,
  visible,
  query = {},
  editMode,
  onEditModeChange,
  showCategory,
}) {
  const dispatch = useDispatch();
  const {
    subtasks,
    subtaskFiles,
    reminders: subtaskReminders,
    reminderEmails: subtaskReminderEmails,
    formData: subtaskFormData,
  } = useSelector((state) => state.subtasks);

  const [showImportDrawer, setShowImportDrawer] = useState(false);
  const [changeModalMode, setChangeModalMode] = useState();
  const [loading, setLoading] = useState(false);
  const [formData, setFormData] = useState();

  const hideChangeModal = useCallback(() => setChangeModalMode(), []);
  const onShowImportClicked = useCallback(() => setShowImportDrawer(true), []);
  const hideImportDrawer = useCallback(() => setShowImportDrawer(false), []);

  const selectedTask = useMemo(() => {
    if (!selectedTaskId) return { priority: 'Medium', status: 'To Do' };
    return subtasks[selectedTaskId] ?? {};
  }, [subtasks, selectedTaskId]);

  const files = useMemo(() => subtaskFiles[selectedTaskId] ?? [], [subtaskFiles, selectedTaskId]);
  const reminders = useMemo(
    () => subtaskReminders[selectedTaskId] ?? [],
    [subtaskReminders, selectedTaskId],
  );
  const reminderEmails = useMemo(
    () => subtaskReminderEmails[selectedTaskId] ?? [],
    [subtaskReminderEmails, selectedTaskId],
  );

  const {
    title,
    groupId,
  } = selectedTask;

  const isDisplay = !!selectedTaskId && !editMode;

  const [form] = Form.useForm();

  const onDelete = () => {
    if (groupId) {
      setChangeModalMode('delete');
    } else {
      CustomConfirmModal({
        title: `Delete ${title}?`,
        onOk: async () => {
          setLoading(true);
          if (await dispatch(deleteSubtask(selectedTask))) {
            onClose();
          }
          setLoading(false);
        },
        okText: 'Delete',
      });
    }
  };

  const onEdit = () => {
    onEditModeChange(true);
  };

  const onSubmitInner = async (values, isGroup) => {
    setLoading(true);
    const payload = { ...values };
    const { reminders: updatedReminders } = payload;
    if (values.dueDate && moment.isMoment(values.dueDate)) {
      delete payload.dueDate;
      payload.dueDate = values.dueDate.startOf('day').valueOf();
    }

    if (values.repeatEndDate && moment.isMoment(values.repeatEndDate)) {
      delete payload.repeatEndDate;
      payload.repeatEndDate = values.repeatEndDate.startOf('day').valueOf();
    }

    if (updatedReminders && payload.dueDate) {
      payload.reminders = updatedReminders.map((reminder) => ({
        time: reminder.time,
        amount: reminder.amount,
      }));
    } else {
      delete payload.reminders;
    }

    if (!payload.formTemplateId) {
      payload.formTemplateId = null;
    }

    if (!payload.equipmentId) {
      payload.equipmentId = null;
    }

    if (!payload.cardId) {
      payload.cardId = null;
    }

    if (selectedTaskId) {
      // Edit

      const {
        files: newFiles = [],
      } = payload;

      const {
        removed: removedFiles,
        added: addedFiles,
      } = compareArrays(files, newFiles);

      if (await dispatch(updateSubtask(selectedTaskId, {
        ...payload,
        isGroup,
        repeat: isGroup || payload.repeat !== selectedTask.repeat ? payload.repeat : null,
        addedFiles,
        removedFiles: removedFiles.map((file) => file.id),
      }))) {
        setShowImportDrawer(false);
        onClose();
      }
      setChangeModalMode();
      setLoading(false);
      return;
    }

    const newQuery = { ... query };
    delete newQuery.shouldJoinLinks;

    const createPayload = {
      ...payload,
      ...newQuery,
    };

    // Create
    if (await dispatch(createSubtask(createPayload))) {
      setShowImportDrawer(false);
      onClose();
    }
    setChangeModalMode();
    setLoading(false);
  };

  const onSubmit = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      return;
    }

    if (!values) {
      return;
    }

    if (groupId) {
      setChangeModalMode('update');
    } else {
      await onSubmitInner({ ...values, formData });
    }
  }, [form, onSubmitInner, groupId, formData]);

  const onGroupSave = useCallback(async (mode) => {
    const isGroup = mode === 'all';
    setLoading(true);
    if (changeModalMode === 'delete') {
      if (await dispatch(deleteSubtask(selectedTask, isGroup))) {
        onClose();
      }
    } else {
      const values = await form.validateFields();
      if (await onSubmitInner({ ...values, formData }, isGroup)) {
        onClose();
      }
    }
    setChangeModalMode();
    setLoading(false);
  }, [selectedTask, groupId, form, changeModalMode, formData]);

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  useEffect(() => {
    if (!visible && form) {
      setFormData();
      setLoading(false);
      form.resetFields();
      setChangeModalMode();
      dispatch(resetSubtaskFormData());
    }
  }, [visible, form]);

  useEffect(() => {
    if (selectedTaskId) {
      dispatch(getSubtaskFiles(selectedTaskId));
      dispatch(getSubtaskReminders(selectedTaskId));
      dispatch(getSubtaskFormData(selectedTaskId));
    }
  }, [selectedTaskId]);

  useEffect(() => {
    form.setFieldsValue({
      ...selectedTask,
      dueDate: selectedTask.dueDate ? moment(selectedTask.dueDate) : undefined,
      repeatEndDate: selectedTask.repeatEndDate ? moment(selectedTask.repeatEndDate) : undefined,
      files,
      reminderEmails,
      reminders,
    });
  }, [files, reminders, reminderEmails, selectedTask, form]);

  const onFormDataChanged = useCallback((newFormData) => {
    setFormData(newFormData);
  }, []);

  return (
    <Drawer
      title={selectedTaskId ? title : 'Add a task'}
      onClose={onClose}
      visible={visible}
      width={600}
      bodyStyle={{ padding: '0px 24px' }}
    >
      <SubtaskForm
        visible={visible}
        form={form}
        isDisplay={isDisplay}
        selectedTask={selectedTask}
        files={files}
        reminderEmails={reminderEmails}
        reminders={reminders}
        initialFormData={subtaskFormData || {}}
        onFormDataChanged={onFormDataChanged}
        query={query}
        showCategory={showCategory}
      />
      {
        isDisplay
          ? (
            <DrawerFooter>
              <Row justify="space-between" gutter={10} align="middle">
                <Col>
                  <Row justify="start" align="middle" gutter={20}>
                    <OnTraccrButton
                      title="Delete"
                      type="back"
                      onClick={onDelete}
                      style={{ marginLeft: 10 }}
                    />
                  </Row>
                </Col>
                <Col>
                  <OnTraccrButton
                    title="Edit"
                    onClick={onEdit}
                  />
                </Col>
              </Row>
            </DrawerFooter>
          )
          : (
            <DrawerSubmitFooter
              onSubmit={onSubmit}
              onClose={onClose}
              loading={loading}
            />
          )
      }
      <RecurringChangeModal
        mode={changeModalMode}
        onClose={hideChangeModal}
        onSave={onGroupSave}
        type="Task"
      />
      {(!isDisplay && !selectedTaskId) && (
        <>
          <div
            style={{
              position: 'absolute',
              top: '10px',
              left: '370px',
            }}
          >
            <OnTraccrButton title="Import from Template" onClick={onShowImportClicked} />
          </div>
          <SubtaskTemplateImportDrawer
            visible={showImportDrawer}
            onClose={hideImportDrawer}
            onSubmit={onSubmitInner}
          />
        </>
      )}
    </Drawer>
  );
}

SubtaskDrawer.propTypes = {
  selectedTaskId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  query: PropTypes.shape({}),
  editMode: PropTypes.bool,
  onEditModeChange: PropTypes.func,
  showCategory: PropTypes.bool,
};

SubtaskDrawer.defaultProps = {
  selectedTaskId: null,
  query: {},
  editMode: false,
  onEditModeChange: () => {},
  showCategory: false,
};
