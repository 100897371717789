import React, { useCallback, useMemo, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Tree } from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';

const ROOT_TITLE = 'Materials'
const ROOTKEY = '/root';

const { DirectoryTree } = Tree;

const getMaterialFromLevel = (materials = [], groupId = null) => {
  const nodes = [];
  materials.forEach((material) => {
    if(!material.isFolder || !material.active || material.groupId !== groupId) return;
    const node = { title: material.name, key: material.id };
    node.children = getMaterialFromLevel(materials, material.id);
    nodes.push(node);
  });
  return nodes;
};

/**
 * Recursively constructs folder path for a given material
 * @param {object} materialsMap
 * @param {string} id - the material id we are constructing a path
 * @returns {string}
 */
const getFolderPath = (materialsMap = {}, id = '') => {
  // Guard clause:
  if (!id || !materialsMap[id]) return ROOT_TITLE;
  // Base case:
  const currMaterialFolder = materialsMap[id];
  if (!currMaterialFolder.groupId) return `${ROOT_TITLE}/${currMaterialFolder?.name}`;
  const parentFolderId = currMaterialFolder?.groupId;
  const parentFolderName = getFolderPath(materialsMap, parentFolderId);
  return `${parentFolderName}/${currMaterialFolder?.name}`;
}

/**
 * Selects a destination to move/copy selected items
 */
export default ({
  loading,
  isCopy,
  visible,
  onClose,
  onSubmit,
  selected = [],
}) => {
  const materials = useSelector(state => state.materials.materials);

  const [selectedFolder,setSelectedFolder] = useState();
  const [canSubmit,setCanSubmit] = useState(false);

  const onSubmitClicked = useCallback(() => onSubmit(selectedFolder === ROOTKEY ? null : selectedFolder),[selectedFolder]);
  const onSelect = useCallback(([selected]) => {
    setSelectedFolder(selected);
    setCanSubmit(true);
  },[]);

  const selectedFolderPathName = useMemo(() => {
    if (!selectedFolder) {
      return '';
    }
    return getFolderPath(materials, selectedFolder);
  }, [selectedFolder]);

  const tree = useMemo(() => {
    const materialList = Object.values(materials);
    return [{
      title: ROOT_TITLE,
      key: ROOTKEY,
      children: getMaterialFromLevel(materialList),
    }];
  },[materials]);

  useEffect(() => {
    if(!visible) {
      setCanSubmit(false);
      setSelectedFolder();
    }
  },[visible]);

  return (
    <Drawer
      title={`${isCopy ? "Copy" : "Move"} ${selected.length} items`}
      width={400}
      visible={visible}
      onClose={onClose}
    >
      <div style={{ height: 40 }}>
        {selectedFolder ?
          <div style={{ overflow:'hidden', textOverflow: 'ellipsis' }}>
            {isCopy ? 'Copy' : 'Move'} to destination: <b>{selectedFolderPathName}</b> ?
          </div> :
          <div>
            Please select a destination folder
          </div>
        }
      </div>
      <DirectoryTree
        treeData={tree}
        onSelect={onSelect}
        defaultExpandedKeys={[ROOTKEY]}
        selectedKeys={[selectedFolder]}
      />
      <DrawerSubmitFooter
        loading={loading}
        onClose={onClose}
        onSubmit={onSubmitClicked}
        canSubmit={canSubmit}
      />
    </Drawer>
  )
}
