import React, {
  useEffect, useCallback, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Drawer, Form, message } from 'antd';
import * as Sentry from '@sentry/react';

import ContactAddressBookForm from './ContactAddressBookForm';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import { toTitleCase } from '../helpers/helpers';
import { isObjectEmpty } from '../common/helpers';

import {
  addToGlobalAddressBook,
  updateAddressBook,
  deleteFromLocalAddressBooks,
  deleteFromGlobalAddressBook,
} from './state/contacts.actions';

import {
  CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE,
  CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE,
} from './contactConstants';

// HELPERS:
/**
 * Retrieves address book slider title
 * @param {string} mode
 * @param {object} selected
 * @returns {string} address book slider title
 */
const getTitle = (mode, selected) => {
  const { name = '' } = selected || {};
  switch (mode) {
    case CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE:
      return `Contact Details: ${toTitleCase(name)}`;
    case CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE:
      return `Edit Contact Details: ${toTitleCase(name)}`;
    case CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE:
      return 'Add Contact';
    default:
      return '';
  }
};

/**
 * Gets the correct action to dispatch for submissions
 * @param {string} mode
 * @returns {function}
 */
const getSubmitAction = (mode) => {
  switch (mode) {
    case CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE:
      return updateAddressBook;
    case CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE:
      return addToGlobalAddressBook;
    default:
      return null;
  }
};

/**
 * Gets the correct action to dispatch for deletions
 * @param {string} type
 * @returns {function}
 */
const getDeleteAction = (type) => {
  switch (type) {
    case 'local':
      return deleteFromLocalAddressBooks;
    case 'global':
      return deleteFromGlobalAddressBook;
    default:
      return null;
  }
};

/**
 * Prepares/formats payload for address book modifications (additions/updates)
 * @param {boolean} isVendor
 * @param {string} mode
 * @param {string} contactId
 * @param {object} existingValues
 * @param {object} newValues
 */
const prepareAddressBookPayload = ({
  isVendor,
  mode,
  contactId,
  existingValues,
  newValues,
}) => {
  const payload = {
    ...newValues,
    [isVendor ? 'vendorId' : 'customerId']: contactId,
  };

  if (mode === CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE) {
    const { id: addressBookEntryId } = existingValues || {};
    payload.id = addressBookEntryId;
  }

  return payload;
};

/** Contact Address Book Slider Component */
function ContactAddressBookSlider({
  visible,
  mode,
  isVendor,
  contactId,
  selected,
  updateMode,
  onClose,
  deleteMode = 'local',
}) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const trueContactId = useMemo(() => {
    const {
      contactId: selectedContactId,
    } = selected;

    return contactId ?? selectedContactId;
  }, [contactId, selected]);

  const onSubmit = useCallback(async () => {
    try {
      setIsLoading(true);
      await form.validateFields();
      const userFormInputs = form.getFieldsValue(true);
      const preparedPayload = prepareAddressBookPayload({
        isVendor,
        mode,
        contactId: trueContactId,
        existingValues: selected,
        newValues: userFormInputs,
      });

      const submitAction = getSubmitAction(mode);

      if (!submitAction) {
        Sentry.withScope(() => {
          Sentry.captureException(new Error('Invalid Submit Action'));
        });
        return;
      }
      if (!submitAction) {
        setIsLoading(false);
        return;
      }
      const res = await dispatch(submitAction(preparedPayload));
      if (res) {
        form.resetFields();
        onClose();
      }
    } catch (err) {
      const errMsg = mode === CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE
        ? 'Could not add to address book'
        : 'Could not update address book';
      message.error(errMsg);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, onClose, isVendor, mode, trueContactId, selected, form]);

  const onEdit = useCallback(() => updateMode(CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE), [updateMode]);

  const onCloseEdit = useCallback(() => (
    updateMode(CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE)
  ), [updateMode]);

  const onCloseSlider = useCallback(() => {
    form.resetFields();
    onClose();
  }, [form, onClose]);

  const onDelete = useCallback(() => {
    const { id: addressBookEntryId, name = '' } = selected || {};
    if (!addressBookEntryId) return;
    const deleteAction = getDeleteAction(deleteMode);
    CustomConfirmModal({
      title: `Delete '${toTitleCase(name)}' from Address Book?`,
      okText: 'Delete',
      onOk: async () => {
        const res = await dispatch(deleteAction(addressBookEntryId, trueContactId, isVendor));
        if (res) {
          form.resetFields();
          onClose();
        }
      },
    });
  }, [isVendor, trueContactId, selected, form, deleteMode]);

  useEffect(() => {
    if (!selected || isObjectEmpty(selected)
      || (mode !== CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE
        && mode !== CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE)) return;
    const {
      name,
      role,
      phoneNumber,
      email,
    } = selected || {};
    form.setFieldsValue({
      name,
      role,
      phoneNumber,
      email,
    });
  }, [mode, selected, form]);

  return (
    <Drawer
      title={getTitle(mode, selected)}
      visible={visible}
      onClose={onCloseSlider}
      width={600}
    >
      <ContactAddressBookForm
        form={form}
        mode={mode}
        selected={selected}
      />
      <DrawerSubmitFooter
        isLoading={isLoading}
        onDelete={mode !== CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE ? onDelete : null}
        onClose={mode === CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE ? onCloseEdit : null}
        onSubmit={mode === CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE ? onEdit : onSubmit}
        submitTitle={mode === CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE ? 'Edit' : 'Submit'}
      />
    </Drawer>
  );
}

ContactAddressBookSlider.propTypes = {
  visible: PropTypes.bool,
  mode: PropTypes.oneOf([
    CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE,
    CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE,
    CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  ]),
  isVendor: PropTypes.bool,
  contactId: PropTypes.string,
  selected: PropTypes.shape({
    contactId: PropTypes.string,
  }),
  updateMode: PropTypes.func,
  onClose: PropTypes.func,
  deleteMode: PropTypes.oneOf(['global', 'local']),
};

ContactAddressBookSlider.defaultProps = {
  visible: false,
  mode: CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  isVendor: false,
  contactId: null,
  selected: {},
  deleteMode: 'local',
  updateMode: () => null,
  onClose: () => null,
};

export default ContactAddressBookSlider;
