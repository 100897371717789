import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Progress } from 'antd';

import HoverHelp from '../common/HoverHelp';
import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import colors from '../constants/Colors';

import { MB, parseSize } from './fileHelpers';

const helpText = (
  <div style={{ maxWidth: 300 }}>
    This is an estimate and may not accurately reflect any changes in the last 24 hours.
  </div>
);

export default ({
  history,
  height = 50,
}) => {
  const {
    storageUsed = 0,
    storageLimit = 100 * MB,
  } = useSelector(state => {
    const {
      settings:{
        company = {},
      } = {}
    } = state;
    return company;
  });
  const usagePercent = parseInt((storageUsed / storageLimit) * 100);
  const nearThreshold = usagePercent > 85;
  const MyButton = nearThreshold ? OnTraccrButton : BorderlessButton;
  return (
    <Row className='file-footer' style={{ height }} justify='start' align='middle' gutter={20}>
      <Col style={{ fontWeight:'medium'}}>
        Storage Used:
      </Col>
      <Col flex='200px' style={{ paddingBottom: 2 }}>
        <Progress
          percent={usagePercent}
          showInfo={false}
          trailColor='white'
          strokeColor={colors.ONTRACCR_RED}
          />
      </Col>
      <Col>
        {parseSize(storageUsed)} / {parseSize(storageLimit)}
      </Col>

      <Col style={{ padding: 0 }}>
        <HoverHelp
          placement='topLeft'
          content={helpText}
        />
      </Col>
      <Col>
          <MyButton
            title='Upgrade'
            style={{
              padding:5,
              marginLeft:15,
              fontSize:12,
              ...nearThreshold ? {} : { backgroundColor: colors.BREADCRUMB_BACKGROUND },
            }}
            onClick={() => {
              history.push('/settings',{ activeTab: 'billing' });
            }}
          /> 
      </Col>
    </Row>
  );
}
