import React, { useMemo } from 'react';
import { Form, Select } from 'antd';
import PropTypes from 'prop-types';

import sortByString from '../../helpers/helpers';
import useDivisionUsers from '../../common/hooks/useDivisionUsers';
import useDivisionFormTemplates from '../../common/hooks/useDivisionFormTemplates';

const { Option } = Select;

export default function FormImportSheetSelector({
  workbook,
  selectedSheetName,
  onSheetChanged,
  selectedTemplate,
  onTemplateChanged,
  selectedUser,
  onUserChanged,
}) {
  const {
    SheetNames = [],
  } = workbook ?? {};

  const formTemplates = useDivisionFormTemplates();

  const templateOptions = useMemo(() => {
    const tempArr = [...formTemplates];
    tempArr.sort(sortByString('name'));
    return tempArr;
  }, [formTemplates]);

  const userOptions = useDivisionUsers();

  return (
    <>
      { onTemplateChanged && (
        <Form.Item label="Template">
          <Select
            style={{ width: 250 }}
            value={selectedTemplate}
            onChange={onTemplateChanged}
            optionFilterProp="label"
            showSearch
          >
            {templateOptions.map((template) => (
              <Option value={template.id} key={template.id} label={template.name}>
                {template.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      { onUserChanged && (
        <Form.Item label="User">
          <Select
            style={{ width: 250 }}
            value={selectedUser}
            onChange={onUserChanged}
            optionFilterProp="label"
            showSearch
          >
            {userOptions.map((user) => (
              <Option value={user.id} key={user.id} label={user.name}>
                {user.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      )}
      <Form.Item label="Sheet">
        <Select
          style={{ width: 250 }}
          onChange={onSheetChanged}
          value={selectedSheetName ?? SheetNames[0]}
        >
          {SheetNames.map((sheet, idx) => <Option value={sheet} key={idx}>{sheet}</Option>)}
        </Select>
      </Form.Item>
    </>
  );
}

FormImportSheetSelector.propTypes = {
  workbook: PropTypes.shape({
    SheetNames: PropTypes.arrayOf(PropTypes.string),
  }),
  selectedSheetName: PropTypes.string,
  onSheetChanged: PropTypes.func.isRequired,
  selectedTemplate: PropTypes.string,
  onTemplateChanged: PropTypes.func,
  selectedUser: PropTypes.string,
  onUserChanged: PropTypes.func,
};

FormImportSheetSelector.defaultProps = {
  workbook: {},
  selectedSheetName: null,
  selectedTemplate: null,
  selectedUser: null,
  onTemplateChanged: null,
  onUserChanged: null,
};
