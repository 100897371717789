import React from "react";
import { connect } from 'react-redux';
import * as XLSX from 'xlsx';
import { Row } from 'antd';
import Analytics  from '../helpers/Analytics';

import BreadCrumbContainer from "../common/breadcrumbContainer/breadcrumbContainer";
import CostCodesList from "./costcodesList";
import CostCodeProjects from './CostcodeProjects';
import { defaultFilters, filterData } from './costcodeFilter';

import BorderlessButton from '../common/buttons/BorderlessButton';
import FilterDropdown from '../common/FilterDropdown';
import Filter from '../common/Filter';

import { getAllCostCodes, getCategories, } from './state/costcodes.actions';
import { getProjects } from '../projects/state/projects.actions';
import { setBreadcrumb, } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import { Route,Redirect } from "react-router-dom";

import Permissions from '../auth/Permissions';

import sortByString, { sortByCode } from '../helpers/helpers';

const getIdxFromField = (field,value) => field.toLowerCase().indexOf(value);

export default connect(
  (state,ownProps) => {
    return {
      ...ownProps,
      costcodes:state.costcodes.costcodes,
      projects:state.projects.projects,
      crumbs:state.breadcrumb,
      divisions: state.settings.divisions,
    };
  },{
    getAllCostCodes,
    getCategories,
    getProjects,
    setBreadcrumb,
  }
)(class CostCodes extends React.Component {
    constructor(props) {
      super(props);

      const {
        costcodes = []
      } = this.props;

      this.filter = new Filter({
        data:costcodes,
        defaultFilters,
      });

      this.state = {
        costcodes,
        sortActive: window.localStorage.getItem('costcodesSortText') || 'Code: Ascending',
        activeFilters:this.filter.activeFilters,
      }

      this.props.setBreadcrumb([{
        text:'Cost Codes',
        icon:'swap',
      }]);
      this.props.getAllCostCodes();
      this.props.getCategories();
      this.props.getProjects();
      this.onExport = this.export.bind(this);

      this.customSearchRanking = this.searchCostcodes.bind(this);
      this.customSearchTieBreaker = this.searchTieBreaker.bind(this);

      this.currentSort = JSON.parse(window.localStorage.getItem('costcodesSortActive') ?? null)
      this.sortByName = this.sortName.bind(this);
      this.sortByCode = this.sortCode.bind(this);

      this.sortOptions = [{
        title:'Code: Ascending',
        icon: 'sort-ascending',
        action:() => this.sortByCode(true)
      },{
        title:'Code: Descending',
        icon: 'sort-descending',
        action:() => this.sortByCode(false)
      },{
        title:'Name: A to Z',
        icon: 'sort-ascending',
        action:() => this.sortName(true)
      },{
        title:'Name: Z to A',
        icon: 'sort-descending',
        action:() => this.sortName(false)
      }];

      this.onFilter = this.filterCostcodes.bind(this);
    }

    componentDidUpdate(prevProps) {
      const {
        costcodes:prevCodes = [],
      } = prevProps;
      const {
        costcodes = [],
      } = this.props;
      if(prevCodes !== costcodes) {
        if(this.currentSort) {
          const [ sortType, sortAscending ] = this.currentSort;
          const sortFunc = sortType === 'name' ? this.sortByName : this.sortByCode;
          sortFunc(sortAscending);
        } else {
          this.sortByCode(true);
        }
      }
    }

    export(costcodes,fileExtension) {
      const {
        divisions = {},
      } = this.props;
      Analytics.track('Costcode/Export', { ExportFileType: fileExtension });
      const workbook = XLSX.utils.book_new();
      const formattedCostcodes = costcodes.map((costcode) => {
        const {
          [costcode.divisionId]: {
            name: division,
          } = {},
        } = divisions;
        return {
          Name:costcode.name,
          'Cost Code': costcode.code,
          Description: costcode.description,
          Category: costcode.category,
          Division: division,
        };
      });
      const sheet = XLSX.utils.json_to_sheet(formattedCostcodes,{
        header:['Name', 'Cost Code', 'Description', 'Division', 'Category'],
      });
      XLSX.utils.book_append_sheet(workbook,sheet,'Cost Codes');
      XLSX.writeFile(workbook, `OntraccrCostCodes.${fileExtension}`);
    }

    getMore({
      onExportExcel,
      onExportCSV,
    }) {
      return <div>
          <Row>
            <BorderlessButton
              title='Export to Excel'
              icon='file-excel'
              style={{ padding:0 }}
              onClick={onExportExcel}
            />
          </Row>
          <Row>
            <BorderlessButton
              title='Export to CSV'
              icon='file-text'
              style={{ padding:0 }}
              onClick={onExportCSV}
            />
          </Row>
        </div>
    }

    searchCostcodes(costcode,searchTerm) {
      const nameLen = costcode.name.length;
      const codeLen =  costcode.code.length;
      if(searchTerm.match(/^[0-9]+$/)) {
        // Number
        const codeIdx = getIdxFromField(costcode.code,searchTerm);
        if (codeIdx >= 0) return codeIdx;
        const nameIdx =  getIdxFromField(costcode.name,searchTerm);
        if (nameIdx >= 0) return nameIdx + codeLen;
        const descIdx = getIdxFromField(costcode.description,searchTerm);
        return descIdx >= 0 ? descIdx + nameLen + codeLen : -1;
      } else {
        const nameIdx =  getIdxFromField(costcode.name,searchTerm);
        if (nameIdx >= 0) return nameIdx;
        const codeIdx = getIdxFromField(costcode.code,searchTerm);
        if (codeIdx >= 0) return codeIdx + nameLen;
        const descIdx = getIdxFromField(costcode.description,searchTerm);
        return descIdx >= 0 ? descIdx + nameLen + codeLen : -1;
      }
    }

    searchTieBreaker(searchTerm, a, b) {
      return sortByString(searchTerm.match(/^[0-9]+$/) ? 'code' : 'name')(a,b);
    }

    getSort() {
      return {
        sortOptions:this.sortOptions,
        sortActive: this.state.sortActive,
      }
    }

    sortName(ascending) {
      const {
        costcodes = [],
      } = this.props;
      const sortTitle = this.sortOptions[ascending ? 2 : 3].title;
      const currentSort = ['name', ascending];
      this.currentSort = currentSort;
      this.sortedCostcodes = [...costcodes].sort((a, b) => (ascending ? 1 : -1) * sortByString('name')(a, b));
      this.filter.data = this.sortedCostcodes;
      this.setState({
        sortActive: sortTitle,
      });
      this.applyFilters();
      window.localStorage.setItem('costcodesSortText', sortTitle);
      window.localStorage.setItem('costcodesSortActive', JSON.stringify(currentSort));
    }

    sortCode(ascending) {
      const {
        costcodes = [],
      } = this.props;
      const sortTitle = this.sortOptions[ascending ? 0 : 1].title;
      const currentSort = ['code', ascending];
      this.currentSort = currentSort;
      this.sortedCostcodes = [...costcodes].sort((a, b) => (ascending ? 1 : -1) * sortByCode('code')(a, b));
      this.filter.data = this.sortedCostcodes;
      this.setState({
        sortActive: sortTitle,
      });
      this.applyFilters();
      window.localStorage.setItem('costcodesSortText', sortTitle);
      window.localStorage.setItem('costcodesSortActive', JSON.stringify(currentSort));
    }

    getFilter() {
      const {
        activeFilters = {}
      } = this.state;
      return <FilterDropdown
        filters={[filterData]}
        activeFilters={activeFilters}
        onFilter={this.onFilter}
      />
    }

    filterCostcodes(checkedFilters,filterCategory) {
      this.filter.setFilter(checkedFilters,filterCategory);
      this.applyFilters();
    }

    applyFilters() {
      this.setState({
        costcodes:this.filter.applyFilters(),
        filterIsActive:this.filter.filterIsAtDefault(),
        activeFilters:this.filter.activeFilters
      });
    }

    render() {
      if(!Permissions.has('COSTCODES_WRITE')) return <Redirect to='/dashboard'/>
      return (
        <BreadCrumbContainer crumbs={this.props.crumbs}>
          <Route exact path="/costcodes/global" render={props =>
              <CostCodesList
              costcodes={this.state.costcodes}
              setBreadcrumb={this.props.setBreadcrumb}
              onExport={this.onExport}
              more={this.getMore}
              search={{
                customRanking:this.customSearchRanking,
                tieBreaker:this.customSearchTieBreaker,
              }}
              sort={this.getSort()}
              filter={this.getFilter()}
              filterIsActive={this.state.filterIsActive}
            />
          }/>
          <Route exact path='/costcodes/project-specific' render={props =>
            <CostCodeProjects
              costcodes={this.state.costcodes}
              projects={this.props.projects}
              setBreadcrumb={this.props.setBreadcrumb}
              onExport={this.onExport}
              more={this.getMore}
              search={{
                customRanking:this.customSearchRanking,
                tieBreaker:this.customSearchTieBreaker,
              }}
              sort={this.getSort()}
              filter={this.getFilter()}
              filterIsActive={this.state.filterIsActive}
            />
          }/>
        </BreadCrumbContainer>
      );
    }
});
