import { combineReducers } from 'redux';

import timeTracking from 'ontraccr-common/lib/timeTracking/state/timeTracking.reducer';

import users from '../users/state/users.reducers';
import teams from '../teams/state/teams.reducers';
import events from '../events/state/events.reducers';
import breadcrumb from '../common/breadcrumbContainer/state/breadcrumb.reducers';
import settings from '../settings/state/settings.reducers';
import projects from '../projects/state/projects.reducers';
import profile from '../profile/state/profile.reducers';
import costcodes from '../costcodes/state/costcodes.reducers';
import menu from '../menu/state/menu.reducers';
import timecards from '../timecards/state/timecards.reducers';
import main from '../main/state/main.reducers';
import reports from '../reports/state/reports.reducers';
import tablets from '../tablets/state/tablets.reducers';
import approvals from '../clock/state/clockin.reducers';
import nux from '../nux/state/nux.reducers';
import livefeed from '../dashboard/LiveFeed/state/livefeed.reducers';
import notifications from '../notifications/state/notifications.reducers';
import files from '../files/state/files.reducers';
import forms from '../forms/state/forms.reducers';
import emails from '../emails/state/email.reducers';
import schedule from '../schedule/state/schedule.reducers';
import equipment from '../equipment/state/equipment.reducers';
import customers from '../contacts/customers/state/customers.reducers';
import vendors from '../contacts/vendors/state/vendors.reducers';
import contacts from '../contacts/state/contacts.reducers';
import materials from '../materials/state/materials.reducers';
import billingRates from '../billingRates/state/billingRates.reducers';
import unions from '../unions/state/unions.reducers';
import boards from '../boards/state/boards.reducers';
import sage from '../sage/state/sage.reducers';
import clientPortal from '../clientPortal/state/clientPortal.reducers';
import learningCenter from '../learningCenter/state/learningCenter.reducers';
import formLibrary from '../forms/FormLibrary/state/formLibrary.reducers';
import analytics from '../analytics/state/analytics.reducers';
import dashboard from '../dashboard/state/dashboard.reducers';
import globalMaterialLocations from '../globalMaterialLocations/state/globalMaterialLocations.reducers';
import labels from '../labels/state/labels.reducers';
import subtasks from '../subtasks/state/subtasks.reducers';
import invoices from '../payables/invoices/state/invoices.reducers';
import salesrabbit from '../settings/Integrations/SalesRabbit/state/salesrabbit.reducer';
import eclipse from '../settings/Integrations/Eclipse/state/eclipse.reducers';
import projectAnalytics from '../projects/ProjectAnalytics/state/projectAnalytics.reducers';
import boardAnalytics from '../boards/BoardAnalytics/state/boardAnalytics.reducers';
import qrCodes from '../qrCodes/qrCodes.reducers';
import buckets from '../buckets/state/buckets.reducers';
import search from '../search/state/search.reducers';
import certifications from '../certifications/state/certifications.reducers';

import { USER_LOGOUT } from './actionTypes';

const appReducer = combineReducers({
  approvals,
  users,
  teams,
  events,
  breadcrumb,
  settings,
  projects,
  profile,
  costcodes,
  menu,
  timecards,
  main,
  reports,
  tablets,
  nux,
  livefeed,
  notifications,
  files,
  forms,
  emails,
  schedule,
  equipment,
  customers,
  vendors,
  contacts,
  materials,
  billingRates,
  unions,
  boards,
  sage,
  clientPortal,
  learningCenter,
  formLibrary,
  analytics,
  dashboard,
  globalMaterialLocations,
  labels,
  subtasks,
  invoices,
  salesrabbit,
  eclipse,
  projectAnalytics,
  timeTracking,
  boardAnalytics,
  qrCodes,
  buckets,
  search,
  certifications,
});

export default (state, action) => {
  let ourState = state;
  if (action.type === USER_LOGOUT) {
    ourState = undefined;
  }
  return appReducer(ourState, action);
};
