import React, { useCallback } from 'react';
import { Row, Radio, Space } from 'antd';

import HoverHelp from '../../HoverHelp';

const growText = 'Expand height to fit text';
const shrinkText = 'Shrink font size to fit height';

const growHelp = (
  <div style={{ maxWidth: 300 }}>
    Select this mode if you want the height of this view to increase to fit long text entries.
  </div>
);

const shrinkHelp = (
  <div style={{ maxWidth: 300 }}>
    Select this mode if you want the font size of long text entries to shrink to fit inside the height of the view.
  </div>
);
const Option = ({
  value,
  text,
  help
}) => (
  <Radio value={value} style={{ width: '100%' }}>
    {text}
    <HoverHelp
      placement='topRight'
      content={help}
      iconProps={{
        style: { marginLeft: 20 }
      }}
    />
  </Radio>
)
export default ({
  growToFitText = false,
  onChange,
}) => {
  const onRadioSelect = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onChange(value);
  },[onChange]);
  return (
    <Row align='middle' justify='space-between' style={{ margin: '10px 0px'}}>
      <Radio.Group value={growToFitText} onChange={onRadioSelect} style={{ width: '100%' }}>
        <Space direction='vertical' style={{ width: '100%' }}>
          <Option
            value={true}
            text={growText}
            help={growHelp}
          />
          <Option
            value={false}
            text={shrinkText}
            help={shrinkHelp}
          />

        </Space>
      </Radio.Group>
    </Row>
  );
}
