import React from 'react';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';

export default function DropdownFieldSubDataTypeSelect({
  placeholder,
  onChange,
  value,
  mode,
  disabled,
  options,
  rowStyle = {},
  selectStyle={},
}) {
  if (!options.length) return null;

  return (
    <Row style={{ marginTop: 5, ...rowStyle }}>
      <Select
        style={{ width: 350, ...selectStyle }}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        mode={!mode || mode === 'multiple' ? 'multiple' : null}
        disabled={disabled}
        showSearch
        optionFilterProp="label"
        options={options}
      />
    </Row>
  );
}

DropdownFieldSubDataTypeSelect.propTypes = {
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  mode: PropTypes.string,
  disabled: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  })),
  rowStyle: PropTypes.shape({}),
  selectStyle: PropTypes.shape({}),
};

DropdownFieldSubDataTypeSelect.defaultProps = {
  placeholder: '',
  onChange: () => {},
  value: null,
  mode: null,
  disabled: false,
  options: [],
  rowStyle: {},
  selectStyle: {},
};
