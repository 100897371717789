import React from 'react';
import LiveFeedEntry from '../dashboard/LiveFeed/LiveFeedEntry';
import LiveFeedPost from '../dashboard/LiveFeed/LiveFeedPost';

export default ({
  note,
  user,
  date,
  isCurrentUser,
  hideDivider,
  unread,
  outgoing,
}) => (
  <LiveFeedEntry
    unread={unread}
    user={user}
    date={date}
    isCurrentUser={isCurrentUser}
    hideDivider={hideDivider}
    customIcon={<></>}
    extra={
      <LiveFeedPost post={{ text: note }} reverse={!outgoing}/>
    }
    reverse={!outgoing}
    hideIcon
  />
)