import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Drawer,
  Select,
  Form,
  Row,
  Radio,
} from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import DivisionSelector from '../common/inputs/DivisionSelector';

import FormBuilder from '../forms/FormBuilder/FormBuilder';

import {
  getBoardLibrary,
  getBoardLibraryTemplate,
  importFromBoardLibrary,
} from './state/boards.actions';
import { getIdMap } from '../helpers/helpers';
import FormTextInput from '../common/inputs/FormTextInput';
import StatusList from './StatusList';

function BoardLibraryImportDrawer({
  visible,
  onClose,
  onSubmit,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const library = useSelector((state) => state.boards.library);
  const libraryTemplates = useSelector((state) => state.boards.libraryTemplates);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const boardTemplates = useSelector((state) => state.boards.boardTemplates);
  const boardTemplateMap = useMemo(() => {
    const templatesWithCards = boardTemplates.map((template) => {
      const { cardTypeId } = template;
      return {
        ...template,
        card: cardTemplates[cardTypeId] || {},
      };
    });
    return getIdMap(templatesWithCards);
  }, [boardTemplates, cardTemplates]);

  const [source, setSource] = useState('boardLibrary');
  const [selectedBoard, setSelectedBoard] = useState();
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [loading, setLoading] = useState(false);

  const onSourceChange = useCallback((e) => {
    setSource(e.target.value);
    setSelectedBoard();
  }, []);

  const onSubmitClicked = useCallback(async () => {
    setLoading(true);
    const { name } = form.getFieldsValue();
    const key = (source === 'boardTemplates') ? 'templateId' : 'key';
    if (await dispatch(
      importFromBoardLibrary({
        [key]: selectedBoard,
        divisions: selectedDivisions,
        name,
      }),
    )) {
      onSubmit();
    }
    setLoading(false);
  }, [dispatch, onSubmit, form, selectedBoard, selectedDivisions, source]);

  const onTemplateSelected = useCallback(async (boardKey) => {
    if (source !== 'boardTemplates' && !(boardKey in libraryTemplates)) {
      await dispatch(getBoardLibraryTemplate(boardKey));
    }
    setSelectedBoard(boardKey);
  }, [dispatch, source, libraryTemplates]);

  useEffect(() => {
    if (visible && library.length === 0) {
      dispatch(getBoardLibrary());
    }
  }, [dispatch, visible, library]);

  useEffect(() => {
    if (!visible) {
      setSelectedBoard();
      form.resetFields();
    }
  }, [visible, form]);

  // boardTemplateMap has 'name' field, libraryTemplates switches to 'title' field
  const nameKey = (source === 'boardTemplates') ? 'name' : 'title';
  const {
    statuses = [],
    card = {},
    [nameKey]: selectedBoardName,
  } = useMemo(() => {
    const sourceMap = (source === 'boardTemplates') ? boardTemplateMap : libraryTemplates;
    const {
      [selectedBoard]: data = {},
    } = sourceMap;
    return data;
  }, [source, libraryTemplates, boardTemplateMap, selectedBoard]);

  useEffect(() => {
    form.setFieldsValue({ name: selectedBoardName });
  }, [form, selectedBoardName]);

  const templateSelectOptions = useMemo(() => {
    let sourceList = library;
    // library has s3 key, boardTemplates has template id
    let key = 'key';
    if (source === 'boardTemplates') {
      sourceList = boardTemplates;
      key = 'id';
    }
    return sourceList.map((lib) => (
      <Select.Option key={lib[key]} value={lib[key]} label={lib.name}>
        <Row style={{ width: '100%' }}>
          <Row className="form-field-type-title">
            {lib.name}
          </Row>
          <Row className="form-field-type-description">
            {lib.description}
          </Row>
        </Row>
      </Select.Option>
    ));
  }, [source, library, boardTemplates]);

  return (
    <Drawer
      title="Import from Board Templates"
      onClose={onClose}
      visible={visible}
      width={700}
      className="board-import-form"
    >
      <Radio.Group
        buttonStyle="solid"
        onChange={onSourceChange}
        defaultValue="boardLibrary"
      >
        <Radio.Button value="boardLibrary">Board Library</Radio.Button>
        <Radio.Button value="boardTemplates">Board Templates</Radio.Button>
      </Radio.Group>

      <Select
        style={{ width: '100%', marginTop: 20 }}
        showSearch
        optionFilterProp="label"
        value={selectedBoard}
        onSelect={onTemplateSelected}
      >
        { templateSelectOptions }
      </Select>
      {
        selectedBoard && (
          <Form
            form={form}
            layout="vertical"
            style={{
              position: 'absolute',
              top: 165,
              left: 24,
              right: 24,
              bottom: 51,
              overflowY: 'auto',
            }}
            initialValues={{
              name: selectedBoardName,
            }}
          >
            <FormTextInput
              isNotDisplay
              label="Board Name"
              name="name"
              style={{ margin: '10px 0xp' }}
              rules={[{ required: true, message: 'Name is required' }]}
            />
            <Form.Item
              name="divisions"
              label="Divisions"
              labelCol={{ style: { marginTop: 10 } }}
              rules={[
                { required: true, message: 'Please select at least one division' },
                { min: 1, type: 'array', message: 'Please select at least one division' },
              ]}
              valuePropName="divisions"
            >
              <DivisionSelector
                mode="multiple"
                onChange={setSelectedDivisions}
              />
            </Form.Item>
            <Form.Item
              label="Card"
              name="card"
              labelCol={{ style: { marginBottom: -20 } }}
            >
              <FormBuilder
                isDisplay
                initialSections={card.fields}
                style={{
                  position: 'relative',
                  pointerEvents: 'none',
                  marginTop: 10,
                  inset: 0,
                }}
                isBoardCards
                divisions={selectedDivisions}
              />
            </Form.Item>
            <Form.Item
              label="Statuses"
              name="statuses"
              labelCol={{ style: { marginBottom: -20 } }}
            >
              <StatusList statuses={statuses} />
            </Form.Item>
          </Form>
        )
      }

      <DrawerSubmitFooter
        onSubmit={onSubmitClicked}
        onClose={onClose}
        canSubmit={!!(selectedBoard && selectedDivisions.length > 0)}
        loading={loading}
      />
    </Drawer>
  );
}

export default BoardLibraryImportDrawer;
