import {
  React,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Drawer, Row } from 'antd';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import StepsContainer from '../../../common/containers/StepsContainer';
import ProjectBudgetTrackingImportPreview from './ProjectBudgetTrackingImportPreview';
import ProjectBudgetTrackingSelectSheets from './ProjectBudgetTrackingSelectSheets';
import ProjectBudgetTrackingSelectHeaders from './ProjectBudgetTrackingSelectHeaders';
import DrawerFooter from '../../../common/containers/DrawerFooter';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import SheetUploadPrompt from '../../../common/excel/SheetUploadPrompt';

const TEMPLATE_LINK = 'https://ontraccr-public.s3.us-west-2.amazonaws.com/Ontraccr%2BBudget%2BTracking%2B3.31.0.xlsx';

function ProjectBudgetTrackingImportDrawer({
  visible,
  onClose,
  onImport,
  divisionId,
}) {
  const [currentStep, setCurrentStep] = useState();
  const [workbook, setWorkbook] = useState();
  const [width, setWidth] = useState(500);
  const [estimateSheet, setEstimateSheet] = useState();
  const [costcodeSheet, setCostcodeSheet] = useState();
  const [sheetJSONs, setSheetJSONs] = useState({});
  const [headers, setHeaders] = useState({});
  const [parsedData, setParsedData] = useState({});

  useEffect(() => {
    if (!visible) {
      setWorkbook();
      setWidth(500);
      setCurrentStep(0);
    }
  }, [visible]);

  useEffect(() => {
    if (workbook) {
      const {
        Sheets,
        SheetNames = [],
      } = workbook;
      const newSheetMap = {};
      SheetNames.forEach((sheetName) => {
        newSheetMap[sheetName] = XLSX.utils.sheet_to_json(Sheets[sheetName], { defval: '' });
      });
      setSheetJSONs(newSheetMap);
      setWidth(1000);
    } else {
      setCostcodeSheet();
      setEstimateSheet();
      setSheetJSONs({});
      setHeaders({});
    }
  }, [workbook]);

  const onBack = useCallback(() => setCurrentStep(currentStep - 1), [currentStep]);
  const onNext = useCallback(() => {
    if (currentStep === 2) {
      onImport(parsedData);
      onClose();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, parsedData]);

  return (
    <Drawer
      title="Import Budget Tracking"
      visible={visible}
      onClose={onClose}
      width={width}
    >
      <>
        <div style={{ height: 'calc(100% - 55px)', width: '100%', overflowY: 'scroll' }}>
          { !workbook ? (
            <SheetUploadPrompt onFileChange={setWorkbook} templateLink={TEMPLATE_LINK} />
          ) : (
            <StepsContainer
              currentStep={currentStep}
              steps={[
                {
                  title: 'Select Sheets',
                  content: (
                    <ProjectBudgetTrackingSelectSheets
                      workbook={workbook}
                      setEstimateSheet={setEstimateSheet}
                      setCostcodeSheet={setCostcodeSheet}
                    />
                  ),
                },
                {
                  title: 'Select Headers',
                  content: (
                    <ProjectBudgetTrackingSelectHeaders
                      sheetJSONs={sheetJSONs}
                      estimateSheet={estimateSheet}
                      costcodeSheet={costcodeSheet}
                      headers={headers}
                      setHeaders={setHeaders}
                    />
                  ),
                },
                {
                  title: 'Preview',
                  content: (
                    <ProjectBudgetTrackingImportPreview
                      divisionId={divisionId}
                      parsedData={parsedData}
                      setParsedData={setParsedData}
                      sheetJSONs={sheetJSONs}
                      estimateSheet={estimateSheet}
                      costcodeSheet={costcodeSheet}
                      headers={headers}
                    />
                  ),
                },
              ]}
            />
          )}
        </div>
        <DrawerFooter>
          <Row justify="end" align="middle" gutter={8}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              id="customer-add-project-footer-cancel"
              onClick={onClose}
            />
            {currentStep > 0 && (
              <OnTraccrButton
                title="Back"
                type="back"
                id="customer-add-project-footer-back"
                onClick={onBack}
              />
            )}
            <OnTraccrButton
              title={currentStep < 2 ? 'Next' : 'Submit'}
              onClick={onNext}
              disabled={!workbook}
            />
          </Row>
        </DrawerFooter>
      </>
    </Drawer>
  );
}

ProjectBudgetTrackingImportDrawer.propTypes = {
  visible: PropTypes.bool,
  onImport: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  divisionId: PropTypes.string.isRequired,
};

ProjectBudgetTrackingImportDrawer.defaultProps = {
  visible: false,
};

export default ProjectBudgetTrackingImportDrawer;
