import { getFormattedCurrency } from '../../forms/formHelpers';

export default [
  {
    title: 'Form Name',
    dataIndex: 'formName',
    key: 'formName',
    align: 'center',
    width: 180,
  },
  {
    title: 'Form Number',
    dataIndex: 'formNumber',
    key: 'formNumber',
    render: (formNumber) => formNumber || '-',
    align: 'center',
    width: 180,
  },
  {
    title: 'Total PO Cost',
    dataIndex: 'formValue',
    key: 'formValue',
    render: getFormattedCurrency,
    align: 'center',
    width: 180,
  },
  {
    title: 'Total Cost to Date',
    dataIndex: 'costToDate',
    key: 'costToDate',
    render: getFormattedCurrency,
    align: 'center',
    width: 180,
  },
  {
    title: 'Committed Cost',
    dataIndex: 'committedCost',
    key: 'committedCosts',
    align: 'center',
    width: 180,
    render: (_, record) => {
      const committedCost = record.formValue - record.costToDate;

      return getFormattedCurrency(committedCost);
    },
  },
];
