import React, {
  useMemo, useState, useEffect, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Drawer, Row, Table } from 'antd';
import { useTranslation } from 'react-i18next';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

const cols = [{
  title: 'Name',
  dataIndex: 'name',
}];

function ProjectAddExistingEquipmentDrawer({
  visible = false,
  onClose,
  onSubmit,
  divisionId,
  chosen = [],
}) {
  const { t } = useTranslation();
  const equipment = useSelector((state) => state.equipment.equipment);

  const [selected, setSelected] = useState([]);

  const chosenSet = useMemo(() => (
    new Set(chosen.filter((c) => c).map(({ id }) => id))
  ), [chosen]);
  const divisionEquipment = useMemo(() => (
    equipment.filter((eq) => (
      !chosenSet.has(eq.id)
      && eq.active
      && eq.divisionIds.includes(divisionId)
    ))
  ), [divisionId, equipment, chosenSet]);

  const selectedRowKeys = useMemo(() => (
    selected.map(({ id }) => id)
  ), [selected]);

  const onSelectedChange = useCallback((_, newSelected) => setSelected(newSelected), []);

  const onSubmitClicked = useCallback(() => (
    onSubmit(selected)
  ), [selected, onSubmit]);

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setSelected([]);
      }, 450);
    }
  }, [visible]);

  return (
    <Drawer
      title={`Add Existing Equipment to ${t('Project')}`}
      width={400}
      onClose={onClose}
      visible={visible}
    >
      <Table
        columns={cols}
        dataSource={divisionEquipment}
        pagination={false}
        size="small"
        rowSelection={{
          onChange: onSelectedChange,
          selectedRowKeys,
        }}
        rowKey="id"
      />

      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <OnTraccrButton
            title="Cancel"
            type="cancel"
            style={{ marginRight: 8 }}
            onClick={onClose}
          />
          <OnTraccrButton
            title="Submit"
            onClick={onSubmitClicked}
          />
        </Row>
      </div>
    </Drawer>
  );
}

ProjectAddExistingEquipmentDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  divisionId: PropTypes.string,
  chosen: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
};

ProjectAddExistingEquipmentDrawer.defaultProps = {
  visible: false,
  divisionId: '',
};

export default ProjectAddExistingEquipmentDrawer;
