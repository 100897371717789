import colors from '../constants/Colors';

export default {};

export const fallbackPrimaryColor = `${colors.MAIN_BACKGROUND.toUpperCase()}FF`;
export const fallbackAccentColor = `${colors.ONTRACCR_RED.toUpperCase()}FF`;
export const fallbackSecondaryAccentColor = '#808080FF';

export const profileKeys = [
  { key: 'name', title: 'Name' },
  { key: 'officeNumber', title: 'Office Phone Number' },
  { key: 'mobileNumber', title: 'Mobile Phone Number' },
  { key: 'faxNumber', title: 'Fax Number' },
  { key: 'officeEmail', title: 'Office Email' },
  { key: 'billingEmail', title: 'Billing Email' },
  { key: 'website', title: 'Website' },
  { key: 'officeAddress', title: 'Office Address' },
  { key: 'billingAddress', title: 'Billing Address' },
  { key: 'info', title: 'Info' },
];

export const demoProfile = {
  name: 'Acme Inc.',
  officeNumber: '555 555 5555',
  mobileNumber: '+1 123 123 1233',
  officeEmail: 'office@acmeinc.com',
  billingEmail: 'billing@acmeinc.com',
  website: 'www.acmeinc.com',
  officeAddress: '123 Main Street, City, Country',
  info: 'This is a preview of the client portal profile page.',
};

export const demoCards = {
  card1: {
    title: 'Project A',
    id: 'card1',
    assignCount: 1,
    data: [{
      name: 'Section 1',
      fields: [{
        type: 'text', title: 'Text Field', fieldId: 'field-1', response: { value: 'Text information' }, sectionId: 'section-1',
      }, {
        type: 'yes-no', title: 'Yes / No ?', fieldId: 'field-2', response: { value: false }, sectionId: 'section-1',
      }, {
        type: 'dropdown', title: 'Choose one or more', fieldId: 'field-3', response: { values: [{ id: 'a', name: 'Option A' }] }, sectionId: 'section-1',
      }],
    }, {
      name: 'Section 2',
      fields: [{
        type: 'dateRange', title: 'Dates', fieldId: 'field-4', response: { startTime: 1652310000000, endTime: 1652313600000 }, sectionId: 'section-2',
      }],
    }],
    fields: [
      {
        id: 'section-1',
        name: 'Section 1',
        fields: [
          {
            id: 'field-1',
            configProps: {
              title: 'Text Field',
            },
            selectedType: 'text',
          },
          {
            id: 'field-2',
            configProps: {
              title: 'Yes / No ?',
            },
            selectedType: 'yes-no',
          },
          {
            id: 'field-3',
            configProps: {
              title: 'Choose one or more',
              dataType: 'Custom',
              customOptions: [
                {
                  id: 'a',
                  name: 'Option A',
                },
                {
                  id: 'b',
                  name: 'Option B',
                },
                {
                  id: 'C',
                  name: 'Option C',
                },
              ],
            },
            selectedType: 'dropdown',
          },
        ],
      },
      {
        id: 'section-2',
        name: 'Section 2',
        fields: [
          {
            id: 'field-4',
            configProps: {
              title: 'Dates',
              optional: true,
            },
            selectedType: 'dateRange',
          },
        ],
      },
    ],
  },
  card2: {
    title: 'Project B',
    id: 'card2',
    assignCount: 0,
  },
};
