import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Select, Row, Spin } from 'antd';

import { getFormLibraryTemplate } from './state/formLibrary.actions';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

import FormLibraryInstructions from './FormLibraryInstructions';
import FormLibraryAdvancedTips from './FormLibraryAdvancedTips';

import sortByString, { getIdMap } from '../../helpers/helpers';
import FormLibraryPreviewModal from './FormLibraryPreviewModal';
import { createNewStatus } from '../FormWorkflows/workflowHelpers';

const VALID_DEFAULT_VALUE_TYPES = new Set(['approval', 'user', 'status', 'formTrigger']);

export default ({
  visible,
  onClose,
  onSubmit,
  setTemplateStatusesToAdd, // set statuses associated with imported template that do not exist in company
}) => {
  const dispatch = useDispatch();

  const positionNames = useSelector(state => state.settings.positionNames);
  const formLibrary = useSelector((state) => state.formLibrary.library);
  const templates = useSelector((state) => state.formLibrary.templates);
  const formStatuses = useSelector(state => state.forms.statuses);

  const [templateLoading, setTemplateLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();

  const libraryMap = useMemo(() => getIdMap(formLibrary, 'key'),[formLibrary]);
  const positionMap = useMemo(() => getIdMap(positionNames, 'name'), [positionNames]);
  const statusMap = useMemo(() => getIdMap(Object.values(formStatuses), 'status'), [formStatuses]);
  
  const libraryTemplate = useMemo(() => {
    const {
      [selectedTemplateId]: templateData = {},
    } = templates;
    const {
      [selectedTemplateId]: fullTemplate = {},
    } = libraryMap;
    return {
      ...fullTemplate,
      ...templateData,
    };
  },[templates, libraryMap, selectedTemplateId]);
  
  const options = useMemo(() => {
    const optArray = [...formLibrary];
    optArray.sort(sortByString('name'));
    
    return optArray
      .map((template) => (
        <Select.Option key={template.key} label={template.name} value={template.key}>
          <Row style={{ width: '100%' }}>
            <Row className='form-field-type-title'>
              {template.name}
            </Row>
            <Row className='form-field-type-description'>
              {template.tagline}
            </Row>
          </Row>
        </Select.Option>
      ));
  },[formLibrary]);
  
  const onSelect = useCallback((templateId) => {
    if (templateId in templates) {
      setSelectedTemplateId(templateId);
      return;
    }
    setSelectedTemplateId();
    if (!(templateId in libraryMap)) return;
    setTimeout(async () => {
      setTemplateLoading(true);
      if (await dispatch(getFormLibraryTemplate(libraryMap[templateId]))) {
        setSelectedTemplateId(templateId);
      }
      setTemplateLoading(false);

    }, 250);
  },[templates, libraryMap]);

  /**
   * If a library template has assigned roles, adds the corresponding role ids for the given company to the assignedUsers workflow node
   */
  const onSubmitClicked = useCallback(async () => {
    const { template = {}, defaultValues = {} } = libraryTemplate;

    const templateWorkflowNodes = Array.isArray(template?.formData?.workflow?.nodes)
      ? template.formData.workflow.nodes : [];

    const templateStatusesToAdd = [];
    const newTemplateWorkflowNodes = templateWorkflowNodes.map((node) => {
      const { id: nodeId, type: nodeType } = node;
      const { value: defaultValue, type: defaultValueType = '' } = defaultValues[nodeId] || {};

      if (nodeType !== defaultValueType || !VALID_DEFAULT_VALUE_TYPES.has(defaultValueType)) return node;

      // Retrieve the correct company specific ids for default values:
      const key = defaultValueType === 'status' ? 'statusId' : 'users';
      let companyDefaultValues;
      if (key === 'users') {
      // CASE 1: Assigned Users, approval & formTrigger Nodes:
        const companyAssignedRoleIds = [];
        defaultValue.forEach((positionName) => (
          companyAssignedRoleIds.push(positionMap[positionName]?.id
            ? positionMap[positionName].id : positionName)
        ));
        companyDefaultValues = { [key]: companyAssignedRoleIds };
      } else {
      // CASE 2: Status Update Node
        const statusAlreadyExists = !!statusMap[defaultValue]?.id;
        // 2.a) status already exists:
        if (statusAlreadyExists) {
          companyDefaultValues = { [key]: statusMap[defaultValue].id };
        } else {
        // 2.b) status does not exist:
          const newStatus = createNewStatus(defaultValue);
          templateStatusesToAdd.push(newStatus);
          companyDefaultValues = newStatus;
        }
      }

      return {
        ...node,
        data: {
          ...node.data,
          ...companyDefaultValues,
        },
      };
    });

    setTemplateStatusesToAdd(templateStatusesToAdd);
    onSubmit({
      ...template,
      formData: {
        ...template.formData,
        workflow: {
          ...template.formData.workflow,
          nodes: newTemplateWorkflowNodes,
        },
      },
    });
  },[onSubmit, libraryTemplate, positionMap, setTemplateStatusesToAdd]);

  useEffect(() => {
    if (!visible) setSelectedTemplateId();
  },[visible]);

  return (
    <Drawer
      title='Import from From Library'
      width={800}
      visible={visible}
      onClose={onClose}
      bodyStyle={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 53,
        bottom: 0,
        padding: 24
      }}
    >
      <Select
        showSearch
        onSelect={onSelect}
        style={{ width: '100%' }}
        optionFilterProp='label'
        value={selectedTemplateId}
      >
        {options}
      </Select>
      {
        templateLoading &&
        <Row justify='center' align='middle' style={{ height: '80%' }}>
          <Spin/>
        </Row>
      }
      {
        selectedTemplateId &&
        <div className='form-library-add-container'>
          <div className='form-library-add-section-header'>
            Description: 
          </div>
          <div className='form-library-add-text'>
            {libraryTemplate.description}
          </div>
          <FormLibraryInstructions {...libraryTemplate}/>
          <FormLibraryAdvancedTips {...libraryTemplate}/>
        </div>
      }
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
        canSubmit={!!selectedTemplateId}
      />
      {selectedTemplateId && visible &&
        <FormLibraryPreviewModal {...libraryTemplate}/>
      }
    </Drawer>
  )
}