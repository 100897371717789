import { Modal } from 'antd';
import colors from '../../constants/Colors';

const getButton = (color, disabled) => ({
  backgroundColor: color,
  borderColor: color,
  color: 'white',
  borderRadius: 18,
  width: 110,
  height: 36,
  opacity: disabled ? 0.5 : 1,
});

const { confirm } = Modal;
export default ({
  title,
  onOk,
  okText,
  onCancel,
  cancelText,
  style,
  content,
  hideFooter = false,
  hideCancel = false,
  afterClose = () => {},
  maskColor,
  maskClosable = true,
  keyboard = true,
  okButtonDisabled = false,
  okButtonStyle = {},
  cancelButtonStyle = {},
  additionalOptions = {},
  zIndex = 1000,
}) => {
  const options = {
    ...style,
    maskClosable,
    keyboard,
    wrapClassName: 'myModal',
    title,
    content,
    onCancel,
    cancelText,
    onOk,
    okText,
    centered: true,
    zIndex,
    okButtonProps: {
      style: {
        ...getButton(colors.ONTRACCR_RED, okButtonDisabled),
        ...okButtonStyle,
      },
      disabled: okButtonDisabled,
    },
    cancelButtonProps: {
      style: {
        ...getButton('gray'),
        ...cancelButtonStyle,
      },
    },
    afterClose,
    ...additionalOptions,
  };
  if (hideCancel) options.cancelButtonProps = { style: { display: 'none' }, className: 'modal-footer-hidden-button' };
  if (hideFooter) {
    options.okButtonProps = { style: { display: 'none' }, className: 'modal-footer-hidden-button' };
    options.cancelButtonProps = { style: { display: 'none' }, className: 'modal-footer-hidden-button' };
  }

  if (maskColor) {
    options.maskStyle = {
      backgroundColor: maskColor,
    };
  }
  return confirm(options);
};
