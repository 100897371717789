import React from 'react';
import {
  Row, Col, Input, Spin,
} from 'antd';
import PropTypes from 'prop-types';

function FormSearchInput({
  onSearch,
  searchStr,
  searchLoading,
}) {
  return (
    <Row align="middle" gutter={15}>
      <Col>
        <Input.Search
          type="search"
          style={{ width: 250 }}
          className="searchbar"
          placeholder="Search"
          allowClear
          onChange={onSearch}
          value={searchStr}
        />
      </Col>
      <Col style={{ height: 20, width: 20 }}>
        {searchLoading && <Spin size="small" />}
      </Col>
    </Row>
  );
}

FormSearchInput.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchStr: PropTypes.string,
  searchLoading: PropTypes.bool,
};

FormSearchInput.defaultProps = {
  searchStr: undefined,
  searchLoading: false,
};

export default FormSearchInput;
