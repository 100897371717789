import {
  GET_FORM_INVOICES_COST_TO_DATE,
  CREATE_INVOICE,
  DELETE_INVOICE,
  UPDATE_INVOICE,
  GET_INVOICES,
  CREATE_INVOICE_COSTCODE_DISTRIBUTION,
  UPDATE_INVOICE_COSTCODE_DISTRIBUTION,
  DELETE_INVOICE_COSTCODE_DISTRIBUTION,
  GET_INVOICE_STATUS_LABELS,
} from '../../../state/actionTypes';
import sortByString, { getIdMap } from '../../../helpers/helpers';

/**
 * Returns the updated cost to date depending on the type
 * @param {string} type
 * @param {number | undefined} prvAmt
 * @param {number | undefined} newAmt
 * @param {string} formId
 * @param {object} stateFormCostToDate
 * @returns {object} new form cost to date
 */
const getUpdatedCostToDate = ({ type, prvAmt, newAmt, formId, stateFormCostToDate }) => {
  const newFormCostToDate = { ...stateFormCostToDate };
  const {
    [formId]: stateCostToDateObj = {},
  } = newFormCostToDate;
  const existingCostToDate = stateCostToDateObj.costToDate ? stateCostToDateObj.costToDate : 0;
  let updatedCostToDate = existingCostToDate;
  switch (type) {
    case 'create':
      if (typeof newAmt !== 'number') return stateFormCostToDate;
      updatedCostToDate = existingCostToDate + newAmt;
      break;
    case 'delete':
      if (typeof prvAmt !== 'number') return stateFormCostToDate;
      updatedCostToDate = existingCostToDate - prvAmt;
      break;
    case 'update':
      if (typeof prvAmt !== 'number' && typeof newAmt !== 'number') return stateFormCostToDate;
      if (newAmt > prvAmt) {
        updatedCostToDate = existingCostToDate + (newAmt - prvAmt);
      } else {
        updatedCostToDate = existingCostToDate - (prvAmt - newAmt);
      }
      break;
    default:
  }
  newFormCostToDate[formId] = {
    ...stateCostToDateObj,
    costToDate: updatedCostToDate,
  };
  return newFormCostToDate;
};

const initialState = {
  invoices: [],
  statuses: [],
  formCostToDate: {} // formId to costToDate map
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_INVOICE_STATUS_LABELS: {
      const {
        payload: {
          statuses = [],
        } = {},
      } = action;
      return {
        ...state,
        statuses,
      };
    }
    case GET_FORM_INVOICES_COST_TO_DATE: {
      const {
        payload: {
          formData = [],
        } = {},
      } = action;
      return {
        ...state,
        formCostToDate: getIdMap(formData, 'formId'),
      };
    }
    case DELETE_INVOICE: {
      const {
        payload: {
          invoiceId,
          formId,
        } = {},
      } = action;
      const {
        invoices: stateInvoices = [],
        formCostToDate: stateFormCostToDate = {},
      } = state;
      const invoiceToDelete = stateInvoices.find((invoice) => invoice.id === invoiceId);
      if (!invoiceToDelete) return state; // should not occur
      const newFormCostToDate = getUpdatedCostToDate({
        type: 'delete',
        formId,
        prvAmt: invoiceToDelete.amount,
        stateFormCostToDate,
      });
      const newInvoices = stateInvoices
        .filter((invoice) => invoice.id !== invoiceId)
        .sort((a, b) => b.dateIssued - a.dateIssued);
      return {
        ...state,
        invoices: newInvoices,
        formCostToDate: newFormCostToDate,
      };
    }
    case GET_INVOICES: {
      const {
        payload: {
          invoices = [],
        } = {},
      } = action;
      return {
        ...state,
        invoices,
      };
    }
    case CREATE_INVOICE: {
      const {
        payload: {
          formId,
          useRange,
          range,
          newInvoice,
          newlyAddedStatus,
        } = {},
      } = action;
      const [startDate, endDate] = range || [];
      const {
        invoices: stateInvoices = [],
        formCostToDate: stateFormCostToDate = {},
        statuses: stateStatuses = [],
      } = state;

      // Add Invoice and Update Form Cost to Date:
      let newInvoices = [...stateInvoices];
      let newFormCostToDate = { ...stateFormCostToDate };
      // following if-block executes under following conditions (add created invoice to redux state):
      //  CASE 1. not using range (we are not getting subsets of invoices depending on date)
      //  CASE 2. using range, and the newly creatd invoice falls between startDate and endDate
      if (!useRange || (useRange && startDate && endDate
        && (startDate <= newInvoice.dateIssued && newInvoice.dateIssued <= endDate))) {
          newInvoices = [...stateInvoices, newInvoice];
        newFormCostToDate = getUpdatedCostToDate({
          type: 'create',
          newAmt: newInvoice.amount,
          formId,
          stateFormCostToDate,
        });
      }
      newInvoices.sort((a, b) => b.dateIssued - a.dateIssued);

      // Add Status, if necessary:
      let newStatuses = [...stateStatuses];
      if (newlyAddedStatus) {
        newStatuses.push(newlyAddedStatus);
        newStatuses.sort(sortByString('title'));
      }
      return {
        ...state,
        formCostToDate: newFormCostToDate,
        invoices: newInvoices,
        statuses: newStatuses,
      };
    } 
    case UPDATE_INVOICE: {
      const {
        payload: {
          invoiceId,
          formId,
          useRange,
          range,
          updatedInvoice,
          newlyAddedStatus,
        } = {},
      } = action;
      const [startDate, endDate] = range || [];
      const {
        invoices: stateInvoices = [],
        formCostToDate: stateFormCostToDate = {},
        statuses: stateStatuses = [],
      } = state;

      // Update Invoice and Update Form Cost to Date:
      let newInvoices = [...stateInvoices];
      let newFormCostToDate = { ...stateFormCostToDate };
      if (!useRange || (useRange && startDate && endDate
        && (startDate <= updatedInvoice.dateIssued && updatedInvoice.dateIssued <= endDate))) {
        // CASE 1: when not using range or invoice's date issued is within range:
        const existingInvoiceData = stateInvoices.find((invoice) => invoice.id === invoiceId);
        if (!existingInvoiceData) return state; // should not occur
        newInvoices = stateInvoices.map((invoice) => {
          if (invoice.id !== invoiceId) return invoice;
          return updatedInvoice;
        });
        // Update Form Cost to Date:
        newFormCostToDate = getUpdatedCostToDate({
          type: 'update',
          prvAmt: existingInvoiceData.amount,
          newAmt: updatedInvoice.amount,
          formId,
          stateFormCostToDate,
        });
      } else {
        // CASE 2: when range is used but new invoice's date issued is not within range:
        newInvoices = newInvoices.filter(({ id }) => id !== updatedInvoice.id);
      }
      
      newInvoices.sort((a, b) => b.dateIssued - a.dateIssued);

      // Add Status, if necessary:
      let newStatuses = [...stateStatuses];
      if (newlyAddedStatus) {
        newStatuses = [...stateStatuses, newlyAddedStatus];
        newStatuses.sort();
      }

      return {
        ...state,
        formCostToDate: newFormCostToDate,
        invoices: newInvoices,
        statuses: newStatuses,
      };
    }
    case CREATE_INVOICE_COSTCODE_DISTRIBUTION: {
      const {
        payload: {
          id,
          invoiceId,
          phaseId,
          phaseName,
          costcodeId,
          costcodeName,
          code,
          amount,
        } = {},
      } = action;
      const {
        invoices: stateInvoices = []
      } = state;
      const updatedInvoices = stateInvoices.map((invoice) => {
        if (invoice.id !== invoiceId) return invoice;
        const { costcodeDistributions: existingCostcodeDistributions = [] } = invoice;
        const newCostcodeDistribution = { id, phaseId, phaseName, costcodeId, costcodeName, code, amount };
        return {
          ...invoice,
          costcodeDistributions: [...existingCostcodeDistributions, newCostcodeDistribution],
        };
      });
      return {
        ...state,
        invoices: updatedInvoices,
      };
    }
    case UPDATE_INVOICE_COSTCODE_DISTRIBUTION: {
      const {
        payload: {
          invoiceId,
          costcodeDistributionId,
          amount: newAmount,
        } = {},
      } = action;
      const {
        invoices: stateInvoices = []
      } = state;
      const updatedInvoices = stateInvoices.map((invoice) => {
        if (invoice.id !== invoiceId) return invoice;
        const { costcodeDistributions: existingCostcodeDistributions = [] } = invoice;
        const updatedCostcodeDistributions = existingCostcodeDistributions
          .map((distribution) => {
            if (distribution.id !== costcodeDistributionId) return distribution;
            return {
              ...distribution,
              amount: newAmount,
            };
          });
        return {
          ...invoice,
          costcodeDistributions: updatedCostcodeDistributions,
        };
      });
      return {
        ...state,
        invoices: updatedInvoices,
      };
    }
    case DELETE_INVOICE_COSTCODE_DISTRIBUTION: {
      const {
        payload: {
          invoiceId,
          costcodeDistributionId,
        } = {},
      } = action;
      const {
        invoices: stateInvoices = []
      } = state;
      const updatedInvoices = stateInvoices.map((invoice) => {
        if (invoice.id !== invoiceId) return invoice;
        const { costcodeDistributions: existingCostcodeDistributions = [] } = invoice;
        const updatedCostcodeDistributions = existingCostcodeDistributions
          .filter((distribution) => distribution.id !== costcodeDistributionId);
        return {
          ...invoice,
          costcodeDistributions: updatedCostcodeDistributions,
        };
      });
      return {
        ...state,
        invoices: updatedInvoices,
      };
    }
    default:
      return state;
  }
};
