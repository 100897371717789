import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useSelector } from 'react-redux';

import IntegrationProjectSync from '../IntegrationProjectSync';

import EclipseImportHelp from './EclipseImportHelp';

import { request } from '../../../helpers/requests';

function EclipseProjectSync({
  visible,
  setCanNext,
}, ref) {
  const selectedDivision = useSelector((state) => state.eclipse.selectedDivision);
  const [eclipseProjects, setEclipseProjects] = useState([]);
  const [importProjects, setImportProjects] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const {
        data: {
          data = [],
        } = {},
      } = await request({
        call: axios.get('/eclipse/data/projects', { params: { divisionId: selectedDivision } }),
        hideSuccessToast: true,
        errMsg: 'Failed to get eclipse projects',
      });
      setEclipseProjects(data);
      setLoading(false);
    };

    if (visible && selectedDivision) {
      load();
    } else {
      setEclipseProjects([]);
    }
  }, [visible, selectedDivision]);

  useImperativeHandle(ref, () => ({
    onSave: async () => request({
      call: axios.post('/integration/import', {
        importProjects: {
          linkDeletedList: importProjects.linkDeletedList,
          importList: importProjects.importList.map((project) => ({
            ...project,
            divisionId: selectedDivision,
          })),
        },
        integrationKey: 'eclipseId',
      }),
      hideSuccessToast: true,
      errMsg: 'Failed to import eclipse projects',
    }),
  }), [selectedDivision, importProjects]);

  useEffect(() => {
    if (visible) setCanNext(!loading);
  }, [visible, loading, setCanNext]);

  return (
    <IntegrationProjectSync
      integrationProjects={eclipseProjects}
      title="Eclipse"
      integrationKey="eclipseId"
      onProjectsChanged={setImportProjects}
      importHelpText={<EclipseImportHelp type="Projects" />}
      importProjects={importProjects}
      divisionId={selectedDivision}
      loading={loading}
      showNumber
    />
  );
}

const EclipseProjectSyncRef = forwardRef(EclipseProjectSync);
EclipseProjectSyncRef.propTypes = {
  visible: PropTypes.bool.isRequired,
  setCanNext: PropTypes.func.isRequired,
};

export default EclipseProjectSyncRef;