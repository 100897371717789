import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { animated, useSpring } from 'react-spring';
import { Row, Col, Input } from 'antd';
import {
  FolderAddOutlined,
  FileAddOutlined,
  DeleteOutlined,
  SwapOutlined,
  CopyOutlined,
  LeftOutlined,
} from '@ant-design/icons';

import { PropTypes } from 'prop-types';

import FileDeleteModal from './FileDeleteModal';
import FileAddButton from './FileAddButton';

import NuxPopover from '../nux/NuxPopover';
import {
  FILES_PAGE_NUX_STEP_1,
  FILES_PAGE_NUX_STEP_2,
  FILES_PAGE_NUX_STEP_3,
  FILES_PAGE_NUX_STEP_1_SEARCH_TEXT,
  FILES_PAGE_NUX_STEP_1_ADD_TEXT,
  FILES_PAGE_NUX_STEP_3_MOVE_TEXT,
} from '../nux/nux.constants';

import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

const ROW_HEIGHT = 32;
const MARGIN = 8;
const FULL_ROW_HEIGHT = ROW_HEIGHT + 2 * MARGIN;
export default function FileListHeader({
  onSearch,
  searchVal,
  onAddFolder,
  onAddFile,
  onDelete,
  onMove,
  onCopy,
  selected = [],
  onBack,
  hasWritePerms,
  history,
  promptForPasswordOnDelete = true,
  showAddFileButton = true,
}) {
  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);

  const showNuxStep1 = activeNuxAction === FILES_PAGE_NUX_STEP_1;
  const showNuxStep3 = activeNuxAction === FILES_PAGE_NUX_STEP_3;
  const [stateShowNuxStep3, setShowNuxStep3] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [addStyle, setAddStyle] = useSpring(() => ({ }));
  const [selectedStyle, setSelectedStyle] = useSpring(() => ({ }));

  const showDelete = useCallback(() => setShowDeleteModal(true), []);
  const hideDelete = useCallback(() => setShowDeleteModal(false), []);
  const onChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onSearch(value);
  }, [onSearch]);

  useEffect(() => {
    const hasSelected = selected.length || showNuxStep3;
    const transform = `translateY(${hasSelected ? -ROW_HEIGHT : 0}px)`;
    setAddStyle({
      opacity: hasSelected ? 0 : 1,
      transform,
    });
    setSelectedStyle({
      opacity: hasSelected ? 1 : 0,
      transform,
    });
  }, [selected, setAddStyle, setSelectedStyle, showNuxStep3]);

  useEffect(() => {
    if (showNuxStep3) {
      setTimeout(() => setShowNuxStep3(true), 500);
    } else {
      setShowNuxStep3(false);
    }
  }, [showNuxStep3]);

  const selectedModifier = showNuxStep3 || selected.length === 1 ? '' : 's';
  return (
    <div style={{
      height:
      ROW_HEIGHT,
      overflow: 'hidden',
      marginBottom: 15,
      zIndex: showNuxStep1 || showNuxStep3 ? 100 : 1,
      position: 'relative',
      marginTop: 15,
    }}
    >
      <Row align="middle" gutter={10} justify="space-between" style={{ padding: '0px 0xp' }}>
        <animated.div style={{
          flex: 1,
          top: 0,
          padding: '0px 10px',
          overflow: 'hidden',
          ...addStyle,
        }}
        >
          <Row gutter={10} justify="space-between">
            <Col style={{ flexGrow: 1, maxWidth: 550, paddingLeft: 22 }}>
              <Row justify="start" gutter={20}>
                <Col flex="35px">
                  {onBack && (
                    <BorderlessButton
                      style={{ backgroundColor: 'transparent', padding: 0 }}
                      iconNode={<LeftOutlined style={{ padding: 0, margin: 0 }} />}
                      onClick={onBack}
                    />
                  )}
                </Col>
                <Col flex="auto">
                  <NuxPopover
                    placement="bottom"
                    text={FILES_PAGE_NUX_STEP_1_SEARCH_TEXT}
                    visible={showNuxStep1}
                    nextAction={FILES_PAGE_NUX_STEP_2}
                  >
                    <Input.Search
                      type="search"
                      value={searchVal}
                      disabled={selected.length}
                      style={{ width: '100%', pointerEvents: showNuxStep1 ? 'none' : 'auto' }}
                      className="searchbar"
                      placeholder="Search"
                      allowClear
                      onChange={onChange}
                    />
                  </NuxPopover>
                </Col>
              </Row>
            </Col>
            <Col>
              {hasWritePerms
                && (
                <NuxPopover
                  text={FILES_PAGE_NUX_STEP_1_ADD_TEXT}
                  visible={showNuxStep1}
                  nextAction={FILES_PAGE_NUX_STEP_2}
                >
                  <Row justify="end" gutter={12}>
                    <Col>
                      <FileAddButton
                        title="Add Folder"
                        icon={<FolderAddOutlined />}
                        onClick={onAddFolder}
                        disabled={selected.length}
                        style={{ pointerEvents: showNuxStep1 ? 'none' : 'auto' }}
                        history={history}
                      />
                    </Col>
                    { showAddFileButton && (
                    <Col>
                      <FileAddButton
                        title="Add Files"
                        icon={<FileAddOutlined />}
                        onClick={onAddFile}
                        disabled={selected.length}
                        style={{ pointerEvents: showNuxStep1 ? 'none' : 'auto' }}
                        history={history}
                      />
                    </Col>
                    )}
                  </Row>
                </NuxPopover>
                )}
            </Col>
          </Row>
        </animated.div>
      </Row>
      <animated.div style={{
        backgroundColor: 'transparent',
        width: '100%',
        height: FULL_ROW_HEIGHT,
        ...selectedStyle,
      }}
      >

        <Row justify="end" align="middle" gutter={20}>
          <Col>
            Selected
            {' '}
            <b>{showNuxStep3 ? 1 : selected.length}</b>
            {' '}
            item
            {selectedModifier}
          </Col>
          <Col>
            <OnTraccrButton
              type="cancel"
              title="Copy"
              icon={<CopyOutlined />}
              onClick={onCopy}
              disabled={!selected.length}
              style={{ pointerEvents: showNuxStep3 ? 'none' : 'auto' }}
            />
          </Col>
          <Col>
            <NuxPopover
              visible={stateShowNuxStep3}
              text={FILES_PAGE_NUX_STEP_3_MOVE_TEXT}
              placement="bottom"
            >
              <OnTraccrButton
                type="cancel"
                title="Move"
                icon={<SwapOutlined />}
                onClick={onMove}
                disabled={!selected.length}
                style={{ pointerEvents: showNuxStep3 ? 'none' : 'auto' }}
              />
            </NuxPopover>
          </Col>
          <Col>
            <OnTraccrButton
              title="Delete"
              type="back"
              icon={<DeleteOutlined />}
              onClick={showDelete}
              disabled={!selected.length}
              style={{ pointerEvents: showNuxStep3 ? 'none' : 'auto' }}
            />
          </Col>
        </Row>

      </animated.div>
      <FileDeleteModal
        title={`Delete ${selected.length} item${selectedModifier}`}
        onClose={hideDelete}
        visible={showDeleteModal}
        onDelete={onDelete}
        selected={selected}
        promptForPasswordOnDelete={promptForPasswordOnDelete}
      />
    </div>
  );
}

FileListHeader.propTypes = {
  onSearch: PropTypes.func.isRequired,
  searchVal: PropTypes.string,
  onAddFolder: PropTypes.func.isRequired,
  onAddFile: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onMove: PropTypes.func.isRequired,
  onCopy: PropTypes.func.isRequired,
  selected: PropTypes.array,
  history: PropTypes.shape({}),
  onBack: PropTypes.func,
  hasWritePerms: PropTypes.bool.isRequired,
  promptForPasswordOnDelete: PropTypes.bool,
  showAddFileButton: PropTypes.bool,
};

FileListHeader.defaultProps = {
  history: {},
  searchVal: '',
  selected: [],
  onBack: null,
  promptForPasswordOnDelete: true,
  showAddFileButton: true,
};
