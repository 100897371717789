import React, { useMemo } from 'react';
import { Row, Col, Checkbox, Switch } from 'antd';

import NuxPopover from '../../nux/NuxPopover';

import { 
  FORMS_ADD_NUX_STEP_1_SWITCH_TEXT,
} from '../../nux/nux.constants';
import PropTypes from 'prop-types';

export default function FormDefaultData({
  title,
  disabled,
  onEditableChange, // if form field is Collect Signature or Geolocation
  onCheckChange: onPropCheckChange,
  checkName = '',
  values:{
    collect: selected,
    editable
  } = {},
  isDisplay = false,
  showNux = false,
}){
  const onCheckChange =(e) => {
    const {
      target:{
        checked,
      } = {},
    } = e;
    onPropCheckChange(checked);
  };

  const getSubView =  () => {
    if (checkName === 'employeeSignature') {
        return (editable ? 'Load Saved Signature' : 'Disable Saved Signature');
    }
    return (editable ? 'Editable' : 'Not editable')
  }

  const editableView = useMemo(() => {
    const subView = getSubView()
    if(!showNux) return subView;
    return (
      <NuxPopover
        placement='right'
        text={FORMS_ADD_NUX_STEP_1_SWITCH_TEXT}
        visible
      >
        {subView}
      </NuxPopover>
    )
  },[editable,showNux]);

  // Only show selected or default (disabled) options in display mode
  if(isDisplay && !selected && !disabled) return null;

  
  return (
    <Row style={{ height: 25, width:400, }} justify='space-between'>
      <Col>
        <Row gutter={10} >
          {!isDisplay && <Col>
            <Checkbox 
              onChange={onCheckChange} 
              disabled={disabled} 
              checked={selected || disabled}
            />
          </Col>}
          <Col>
            {title}
          </Col>
        </Row>
      </Col>
      
      <Col style={{ width:200 }}>
      {selected && onEditableChange &&
        <Row gutter={10} justify='start'>
          {!isDisplay && <Col>
            <Switch 
              size='small' 
              defaultChecked={editable}
              onChange={(checked) => {
                onEditableChange(checked);
              }}
            />
          </Col>}
          <Col>
            {editableView}
          </Col>
        </Row>
        }
      </Col>
    </Row>
  );
};

FormDefaultData.propTypes = {
  checkName: PropTypes.string,
};