import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import whatAreCostcodes from '../../assets/images/NUX/whatAreCostcodes.png';


const getDescription = (t) => (
<div>
Activity-based costing, or cost coding, is a fast and effective technique to identify the costs of various parts of your {t('Project').toLowerCase()}. Cost codes are unique to each trade/industry but they typically represent specific tasks being done on a {t('Project').toLowerCase()}. For example, for a general contractor, cost codes would be used for activities such as excavation, foundation, framing, drywall, painting, and so on.
<br/><br/>
With Ontraccr's built-in cost code system, you will be able to collect the necessary data on specific tasks needed to evaluate what is efficient enough to increase profit, and what is causing the {t('Project').toLowerCase()} to lose money.
<br/><br/>
Find out more about Cost Codes by viewing the user guides in our Help menu.
</div>);



export default function NuxWhatAreCostcodes({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const description = getDescription(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        padding:'0px 40px',
      }}
      rows={[{
        title:'',
        description,
        cols:[0,14,10],
        image:{
          src:whatAreCostcodes,
          style:{
            boxShadow:'none',
            height:250,
            width:241,
          }
        },
      }]}
    />
  );
}
