import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
} from 'react';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';
import { Row, Col, message } from 'antd';
import {
  DeleteOutlined,
  ImportOutlined,
  LoadingOutlined,
  SaveOutlined,
} from '@ant-design/icons';
import SignatureCanvas from 'react-signature-canvas';
import dataURLtoBlob from 'blueimp-canvas-to-blob';
import axios from 'axios';

import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';

import FileAddView from '../files/FileAddView';
import colors from '../constants/Colors';

const axiosAWSInstance = axios.create();

const saveSignature = async (signatureFile) => {
  try {
    const { data: signedUrl } = await axios.put('/signatures/saved');
    if (signedUrl) {
      const res = await axiosAWSInstance.put(signedUrl, signatureFile, {
        headers: {
          'Content-Type': 'image/png',
        },
      });
      if (res.status === 200) {
        message.success('Signature saved successfully');
      } else {
        message.error('Error occured while saving signature');
      }
    }
    return true;
  } catch (err) {
    message.error('Error occured while saving signature');
    Sentry.withScope(() => {
      Sentry.captureException(new Error('Error uploading signature file when saving'));
    });
    return false;
  }
};

const getSignature = async () => {
  try {
    const { data: signedUrl } = await axios.get('signatures/saved');
    return signedUrl;
  } catch (err) {
    Sentry.withScope(() => {
      Sentry.captureException(new Error('Error occurred while fetching signature'));
    });
    return false;
  }
};

const WIDTH = 500;
const HEIGHT = 350;
export default function SignatureModal({
  visible,
  onClose,
  onSubmit,
  initialData,
  isMultiSignature = false,
  canLoadSaved = true // default able to load signature from saved
}) {
  const canvasRef = useRef(null);

  const [loading, setLoading] = useState();
  const [saveLoading, setSaveLoading] = useState(false);
  const [hasData, setHasData] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const drawImageOnCanvas = useCallback((file) => {
    // Load initial data file
    const canvas = canvasRef?.current?.getCanvas?.();
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const img = new Image();
    img.crossOrigin = 'anonymous';
    img.onload = () => {
      ctx.clearRect(0, 0, WIDTH, HEIGHT);
      const aspectRatio = img.height / img.width;

      if (img.width >= WIDTH) {
        img.width = WIDTH;
        img.height = aspectRatio * WIDTH;
      }

      if (img.height >= HEIGHT) {
        img.height = HEIGHT;
        img.width = aspectRatio / HEIGHT;
      }

      ctx.drawImage(img, 0, 0, WIDTH, HEIGHT);
    };
    img.src = file instanceof File ? URL.createObjectURL(file) : file;
  }, [canvasRef]);

  const imageValidator = useCallback((file) => {
    if (file.type !== 'image/png' && file.type !== 'image/jpeg') {
      message.error('Signature Upload only supports images in PNG or JPEG format');
      return false;
    }
    return true;
  }, []);

  const onImportClicked = () => {
    setShowUpload(true);
  };

  const addFile = useCallback((file) => {
    setSelectedFiles([file]);
  }, []);

  const onSaveFile = useCallback(() => {
    setShowUpload(false);
    if (selectedFiles.length) {
      setHasData(true);
      drawImageOnCanvas(selectedFiles[0]);
    }
  }, [selectedFiles, drawImageOnCanvas]);

  const onClearClicked = useCallback(() => {
    if (!canvasRef || !canvasRef.current) return;
    canvasRef.current.clear();
    setHasData(false);
    setSelectedFiles([]);
  }, [canvasRef]);

  const onCancel = useCallback(() => {
    setSelectedFiles([]);
    setShowUpload(false);
  }, []);

  const createFileFromCanvas = useCallback((trimCanvas) => {
    if (!canvasRef?.current) return false;
    const dataURL = trimCanvas
      ? canvasRef.current.getTrimmedCanvas().toDataURL('image/png')
      : canvasRef.current.toDataURL('image/png');
    const blob = dataURLtoBlob(dataURL);
    return new File([blob], 'signature.png', { type: 'image/png' });
  }, [canvasRef]);

  const onSubmitClicked = useCallback(async () => {
    const signatureFile = createFileFromCanvas(true);
    if (!signatureFile) return;
    setLoading(true);
    await onSubmit(signatureFile);
    setLoading(false);
  }, [onSubmit, createFileFromCanvas]);

  const onSaveClicked = useCallback(async () => { // saves signature
    const signatureFile = createFileFromCanvas(false);
    if (!signatureFile) return;
    setSaveLoading(true);
    const saveStatus = await saveSignature(signatureFile);
    if (!saveStatus) message.error('Error occurred while uploading signature');
    setSaveLoading(false);
  }, [createFileFromCanvas]);

  const onSigStart = useCallback(() => setHasData(true), []);

  const loadSignature = async () => {
    if (!visible || !canvasRef || !canvasRef.current || isMultiSignature || !canLoadSaved) return;
    const signedUrl = await getSignature();
    if (!signedUrl) return;
    setHasData(true);
    drawImageOnCanvas(signedUrl);
  };

  useEffect(() => {
    if (!visible) {
      setLoading(false);
      setHasData(false);
      onClearClicked();
    }
  }, [canvasRef, visible, onClearClicked]);

  useEffect(() => {
    if (visible && initialData && canvasRef && canvasRef.current) {
      drawImageOnCanvas(initialData);
    } else {
      loadSignature();
    }
  }, [canvasRef, visible, initialData, drawImageOnCanvas]);

  return (
    <CustomModal
      title="Submit Form"
      visible={visible}
      onCloseClicked={onClose}
      maskColor={colors.MODAL_MASK_DARK}
      width={600}
    >
      <Row style={{ fontSize: 14 }} justify="center">
        {!isMultiSignature && canLoadSaved && (
          <BorderlessButton
            id="form-signature-import-button"
            title="Import"
            iconNode={<ImportOutlined />}
            onClick={onImportClicked}
          />
        )}
        <p>Please sign below</p>
        <BorderlessButton
          id="form-signature-clear-button"
          title="Clear"
          iconNode={<DeleteOutlined />}
          onClick={onClearClicked}
        />
      </Row>
      <SignatureCanvas
        ref={canvasRef}
        canvasProps={{ width: WIDTH, height: HEIGHT, className: 'form-signature-canvas' }}
        onBegin={onSigStart}
      />
       {canLoadSaved && <Row style={{ fontSize: 14, height: 30, marginTop: -5 }} justify="center">
        {!isMultiSignature && (
          <BorderlessButton
            id="form-signature-save-button"
            title="Save"
            iconNode={saveLoading ? <LoadingOutlined /> : <SaveOutlined />}
            onClick={onSaveClicked}
            disabled={!hasData || saveLoading}
          />
        )}
      </Row>}
      <Row style={{ width: '100%', margin: '20px 0px' }} align="middle" justify="center">
        <Col style={{ marginRight: 10 }}>
          <OnTraccrButton
            title="Cancel"
            type="cancel"
            roundStyle
            onClick={onClose}
          />
        </Col>
        <Col style={{ marginLeft: 10 }}>
          <OnTraccrButton
            title="Submit"
            roundStyle
            onClick={onSubmitClicked}
            loading={loading}
            disabled={!hasData}
          />
        </Col>
      </Row>
      <FileAddView
        visible={showUpload}
        files={selectedFiles}
        onAddFile={addFile}
        showNotifyCheckbox={false}
        onUploadEnd={onCancel}
        onSave={onSaveFile}
        customValidator={imageValidator}
        onFileRemove={() => setSelectedFiles([])}
      />
    </CustomModal>
  );
}

SignatureModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  initialData: PropTypes.object,
  isMultiSignature: PropTypes.bool,
};

SignatureModal.defaultProps = {
  initialData: undefined,
  isMultiSignature: false,
};
