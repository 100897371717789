/* eslint-disable class-methods-use-this */
import mixpanel from 'mixpanel-browser';

const envCheck = process.env.NODE_ENV === 'production';
const mixPanelKey = process.env.REACT_APP_MIXPANEL_KEY;
mixpanel.init(mixPanelKey);

class Analytics {
  identify({ id, companyName, companyId }) {
    this.setProperties({ id, companyName, companyId });
    if (envCheck) {
      mixpanel.identify(id);
      mixpanel.register({ Company: this.company });
      mixpanel.set_group('CompanyId', companyId);
    }
  }

  setProperties({ id, companyName, companyId }) {
    this.id = id;
    this.company = companyName;
    this.companyId = companyId;
  }

  track(name, props = {}) {
    if (envCheck) {
      mixpanel.track(name, props);
    }
  }

  reset() {
    if (envCheck) {
      try {
        mixpanel.reset();
      } catch (err) { /**/ }
    }
  }

  getAnalyticsKey(prefix, userId) {
    return `${prefix}${userId ? 'Other' : 'Self'}`;
  }

  get people() {
    return {
      set: (data) => {
        if (envCheck) {
          mixpanel.people.set({
            Username: data.username,
            $name: data.name,
            Position: data.position,
            Company: this.company,
            $email: data.email,
          });
        }
      },
    };
  }
}
const analytics = new Analytics();
export default analytics;
