import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import {
  DeleteOutlined,
  EditOutlined,
  CloseOutlined,
  CheckOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import CollapseButton from '../../../common/buttons/CollapseButton';
import useToggle from '../../../common/hooks/useToggle';

import colors from '../../../constants/Colors';
import NuxPopover from '../../../nux/NuxPopover';
import {
  FORMS_ADD_FIELDS_STEP_4,
  FORMS_ADD_FIELDS_STEP_4_SECTION_DELETE_BUTTON_TEXT,
} from '../../../nux/nux.constants';
import FormSectionSettingsModal from './FormSectionSettingsModal';

export default function FormSectionHeader({
  id,
  title,
  onClick,
  onDeleteClicked,
  settings,
  onRename,
  onSectionsSettingUpdated,
  style = {},
  open,
  isDisplay = false,
  isBoardCards,
  isExternalForm,
  divisions,
  enablePermissions,
}) {
  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);
  const [editing, setEditing] = useState();
  const [text, setText] = useState(title);
  const { isToggled: isModalVisible, toggle } = useToggle();

  const enableEditing = useCallback((e) => {
    e.stopPropagation();
    setEditing(true);
  }, []);

  const onTextChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setText(value);
  }, []);

  const save = useCallback(() => {
    if (!id) return;
    onRename(id, text);
    setEditing(false);
  }, [id, text, onRename]);

  const onCancel = useCallback(() => {
    setText(title);
    setEditing(false);
  }, [title]);

  const onKeyDown = useCallback((e) => {
    if (!editing || e.keyCode !== 27) return;
    e.stopPropagation();
    onCancel();
  }, [editing, onCancel]);

  useEffect(() => {
    setText(title);
  }, [title]);

  const child = useMemo(() => {
    if (!editing) {
      return (
        <Col
          style={{
            fontFamily: 'roboto-medium',
            fontSize: 18,
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            maxWidth: '80%',
            overflow: 'hidden',
          }}
        >
          {title}
        </Col>
      );
    }
    return (
      <Col style={{ width: 'calc(100% - 80px)' }}>
        <Row>
          <Col style={{ width: 'calc(100% - 60px)' }}>
            <OnTraccrTextInput
              onChange={onTextChange}
              value={text}
              onPressEnter={save}
              onKeyDown={onKeyDown}
              onRef={(ref) => {
                if (ref) ref.focus();
              }}
            />
          </Col>
          <Col>
            <BorderlessButton
              style={{ padding: 0 }}
              iconNode={<CloseOutlined />}
              onClick={onCancel}
            />
          </Col>
          <Col>
            <BorderlessButton
              style={{
                padding: 0,
                opacity: text ? 1 : 0,
                pointerEvents: text ? 'auto' : 'none',
              }}
              iconNode={<CheckOutlined />}
              onClick={save}
            />
          </Col>
        </Row>
      </Col>
    );
  }, [editing, title, text, onCancel, onTextChange, save, onKeyDown]);

  const onSettingsUpdated = useCallback((newSettings) => {
    onSectionsSettingUpdated(id, newSettings);
  }, [id, onSectionsSettingUpdated]);

  return (
    <Row
      style={{
        width: '100%',
        padding: '0px 10px',
        margin: 0,
        ...style,
      }}
      justify="space-between"
      gutter={10}
      align="middle"
    >
      <Col style={{ width: 'calc(100% - 120px)' }}>
        <Row justify="start" align="middle" gutter={20}>
          <Col>
            <CollapseButton
              open={open}
              onClick={onClick}
            />
          </Col>
          {child}
        </Row>
      </Col>
      <Col>
        {!isDisplay && (
          <NuxPopover
            placement="bottomRight"
            visible={activeNuxAction === FORMS_ADD_FIELDS_STEP_4}
            text={FORMS_ADD_FIELDS_STEP_4_SECTION_DELETE_BUTTON_TEXT}
          >
            <Row justify="end" align="middle" gutter={20}>
              { onSectionsSettingUpdated && (
                <Col>
                  <BorderlessButton
                    style={{ padding: 0 }}
                    iconNode={<SettingOutlined />}
                    onClick={toggle}
                  />
                </Col>
              )}
              <Col>
                <BorderlessButton
                  style={{
                    padding: 0,
                    display: editing ? 'none ' : 'block',
                    pointerEvents: editing || activeNuxAction ? 'none' : 'auto',
                  }}
                  iconNode={<EditOutlined />}
                  onClick={enableEditing}
                />
              </Col>
              <Col>
                <BorderlessButton
                  style={{ padding: 0 }}
                  iconNode={<DeleteOutlined style={{ color: colors.ONTRACCR_RED }} />}
                  onClick={onDeleteClicked}
                />
              </Col>
            </Row>
          </NuxPopover>
        )}
      </Col>
      <FormSectionSettingsModal
        visible={isModalVisible}
        onCloseClicked={toggle}
        initialValues={settings}
        onSettingsUpdated={onSettingsUpdated}
        enablePermissions={enablePermissions}
        isBoardCards={isBoardCards}
        isExternalForm={isExternalForm}
        divisions={divisions}
      />
    </Row>
  );
}
