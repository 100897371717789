import React, { useMemo } from 'react';
import moment from 'moment';
import { DatePicker, Table } from 'antd';
import { ArrowRightOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import colors from '../../constants/Colors';

const { RangePicker } = DatePicker;
const FORMAT = 'MMM Do YY';
const DISPLAY_FORMAT = 'MMMM Do YYYY';

export default ({
  id,
  isNotDisplay,
  onEquipmentDateChanged,
  equipmentSchedule = {},
  onDateRemove,
}) => {

  const data = useMemo(() => {
    const ourData = [];
    Object.keys(equipmentSchedule).forEach((eqId) => {
      const {
        [eqId]: {
          range = [],
        } = {},
      } = equipmentSchedule;
      const [start,end] = Array.isArray(range) ? range : [];
      if(eqId.split('.')[0] === id && (isNotDisplay || (moment.isMoment(start) && moment.isMoment(end)))) {
        ourData.push(equipmentSchedule[eqId]);
      }
    });
    return ourData;
  },[equipmentSchedule, id, isNotDisplay ]);

  const columns = useMemo(() => {
    const cols = [
      {
        title: 'On Site Dates',
        dataIndex: 'range',
        align: 'left',
        render: (range, record) => {
          if(!isNotDisplay) {
            const [start,end] = range || [];
            const startText = moment.isMoment(start)
              ? start.format(DISPLAY_FORMAT)
              : null;
            const endText = moment.isMoment(end)
              ? end.format(DISPLAY_FORMAT)
              : null;
            if (!startText && !endText) return null;
            return (
              <span>
                {startText}<ArrowRightOutlined style={{ margin: '0px 10px' }}/>{endText}
              </span>
            );
          }
          return (
            <RangePicker
              key={record.id}
              value={range}
              format={FORMAT}
              onChange={(range) => {
                onEquipmentDateChanged({
                  id: record.id,
                  name: record.name,
                  range,
                })
              }}
            />
          );
        }
      }
    ];
    if(isNotDisplay) {
      cols.push({
        title:'Remove Date',
        dataIndex:'remove',
        align:'center',
        width: 200,
        render:(_,record) => (
          <BorderlessButton
            icon='delete'
            color={colors.ONTRACCR_RED}
            onClick={() => onDateRemove(record)}
            style={{ paddingRight:8, paddingLeft:0}}
          />
        ),
      });
    }
    return cols;
  },[]);

  return (
    <Table
      dataSource={data}
      columns={columns}
      pagination={false}
      size='small'
      style={{ marginBottom: 100 }}
      rowKey='id'
    />
  )
}