import React from 'react';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';

import { downloadFile } from '../files/fileHelpers';
import calculateOT from '../helpers/overtime/calculateOT';
import { timeIsBetween } from '../helpers/time';

export default {};

const WORK_TYPES = new Set(['work', 'service', 'overtime']);

export const failsFilter = ({
  id, map, filter, getKey = (item) => item.name,
}) => {
  if (!id) return !filter.has('None');
  return !(id in map) || !filter.has(getKey(map[id]));
};

export const constructExportData = ({
  isRounded,
  startDate,
  endDate,
  projectIdMap = {},
  phaseIdMap = {},
  costcodeIdMap = {},
  classMap = {},
  filters = {},
  sageData = {},
  settings = {},
  sageShiftToOnTraccrId = {},
  projectIdToSagePayId = {},
  timeEntryUserMap = {},
}) => {
  const range = moment.isMoment(startDate) && moment.isMoment(endDate)
    ? [
      DateTime.fromMillis(startDate.valueOf()).minus({ days: 15 }),
      DateTime.fromMillis(endDate.valueOf()).plus({ days: 15 }),
    ]
    : [];
  const {
    users = [],
    costcodes: costcodeFilter = new Set(),
    phases: phaseFilter = new Set(),
    projects: projectFilter = new Set(),
  } = filters;
  const {
    employeeMap = {},
    jobMap = {},
    costcodeMap: sageCCMap = {},
  } = sageData;
  const data = [];
  users.forEach((user) => {
    const {
      sageId,
      classId: userDefaultClassId,
    } = user;
    if (!sageId) return;
    const tasks = timeEntryUserMap[user?.id] ?? [];
    calculateOT({
      tasks,
      settings: {
        ...settings,
        roundingInterval: isRounded ? settings.roundingInterval : null,
      },
      range,
    }).filter((task) => WORK_TYPES.has(task.type) && task.state === 'approved')
      .forEach((task) => {
        const {
          startTime,
          endTime,
          projectId,
          phaseId,
          costcodeId,
          classId,
          sageShiftId,
          timezone,
        } = task;

        if (!startTime || !endTime) return;
        if (!(timeIsBetween(task, startDate, endDate))) return;
        if (failsFilter({ id: projectId, map: projectIdMap, filter: projectFilter })) return;
        if (failsFilter({ id: phaseId, map: phaseIdMap, filter: phaseFilter })) return;
        if (failsFilter({
          id: costcodeId,
          map: costcodeIdMap,
          filter: costcodeFilter,
          getKey: (cc) => cc.code,
        })) return;

        const defaultedClassId = classId || userDefaultClassId;
        const {
          [defaultedClassId]: {
            name: className,
          } = {},
        } = classMap;
        const {
          [sageId]: {
            employeeCode,
            payCode: employeeDefaultPayCode,
            shiftCode,
          } = {},
        } = employeeMap;
        const {
          [projectId]: {
            sageId: sageProjectId,
          } = {},
        } = projectIdMap;
        const {
          [costcodeId]: {
            sageId: sageCostcodeId,
          } = {},
        } = costcodeIdMap;
        const {
          [sageProjectId]: jobCode = '',
        } = jobMap;
        const {
          [sageCostcodeId]: {
            code: sageCostcode = '',
            category: sageCategory = '',
          } = {},
        } = sageCCMap;

        const {
          [projectId]: projectSpecificPayId = employeeDefaultPayCode,
        } = projectIdToSagePayId;

        const onTraccrShiftId = sageShiftToOnTraccrId[shiftCode];

        const safeSageId = sageShiftId || onTraccrShiftId;
        data.push({
          ...task,
          classId: defaultedClassId,
          name: user.name,
          className,
          payCode: projectSpecificPayId,
          sageShiftId: safeSageId,
          employeeCode,
          jobCode,
          sageCostcode,
          sageCategory,
          startTime: moment(startTime).tz(timezone),
          endTime: moment(endTime).tz(timezone),
        });
      });
  });
  return data;
};

const tryDownload = async ({ fileData = [], title } = {}) => {
  try {
    const file = new File([fileData.join('\n')], title, { type: 'text/plain' });
    await downloadFile({ fileObject: file });
  } catch (err) {
    Sentry.withScope(() => {
      Sentry.captureException(err);
    });
  }
};

export const downloadSageExportFile = async ({
  sageData = {},
  tableData = [],
  classMap = {},
  sageShifts = {},
}) => {
  const now = DateTime.local();
  const title = `sage-export-${now.toSQLDate()}.txt`;
  const {
    unionMap: sageUnionMap = {},
  } = sageData;
  const fileData = [];
  let badRowCount = 0;
  tableData.forEach((row) => {
    const {
      employeeCode = '',
      jobCode = '',
      sageCostcode = '',
      sageCategory = '',
      startTime,
      endTime,
      classId,
      sageShiftId = '',
      payCode = '',
      timezone,
    } = row;
    const {
      [classId]: {
        sageId: sageClassId,
      } = {},
    } = classMap;
    const {
      [sageClassId]: {
        classCode = '',
        localCode = '',
        unionCode = '',
      } = {},
    } = sageUnionMap;

    const {
      [sageShiftId]: {
        sageId: shiftCode = '',
      } = {},
    } = sageShifts;
    if (!employeeCode
      || !jobCode
      || !sageCostcode
      || !sageCategory
      || !startTime
      || !moment.isMoment(startTime)
      || !endTime
      || !moment.isMoment(endTime)
      || !unionCode
      || !localCode
      || !classCode
      || !shiftCode
      || !payCode) {
      badRowCount += 1;
      return;
    }

    const ourTimezone = timezone ?? DateTime.local().zoneName;

    const startClone = startTime.clone().tz(ourTimezone);

    // This format needs to match the OT time entry view in Sage
    fileData.push([
      employeeCode, // Employee
      jobCode, // Job,
      '', // JC extra
      sageCostcode, // JC cost code
      sageCategory, // JC category
      startClone.tz(ourTimezone).format('MM/DD/YYYY'), // Date
      startClone.format('HH:mm'), // Start time
      endTime.clone().tz(ourTimezone).format('HH:mm'), // End time
      unionCode, // Union ID
      localCode, // Union local
      classCode, // Work Classification
      shiftCode, // Shift
      payCode, // Pay ID
      '' // Units (will be filled in by Sage)
    ].join(','));
  });
  if (badRowCount === 0) return tryDownload({ fileData, title });
  return new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Export Data',
      content: (
        <div>
          <b className='sage-export-warning-bold'>Warning: {badRowCount} {badRowCount === 1 ? 'entry' : 'entries'} are missing required data</b>
          <br/>
          <br/>
          Click <b className='sage-export-warning-bold'>Cancel</b> to go back to editing entries
          <br/>
          Click <b className='sage-export-warning-bold'>Confirm</b> to export only valid entries.
        </div>
      ),
      onOk: async () => {
        resolve(await tryDownload({ fileData, title}))
      }
    })
  });
};
