import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getIdMap } from '../helpers/helpers';
import Labels from '../labels/Labels';

export default function ContactLabels({
  contactId,
  isVendor,
  size,
}) {
  const {
    type,
    mapKey,
  } = isVendor ? {
    type: 'vendors',
    mapKey: 'vendorToLabel',
  } : {
    type: 'customers',
    mapKey: 'customerToLabel',
  };
  const contactToLabel = useSelector((state) => state[type][mapKey]);
  const labels = useSelector((state) => state.labels);
  const contactLabels = useMemo(() => labels.filter((label) => label.type === type), [
    type,
    labels,
  ]);

  const labelIdMap = useMemo(() => getIdMap(contactLabels), [contactLabels]);

  const ourFilteredLabels = useMemo(() => {
    const {
      [contactId]: ourLabels = [],
    } = contactToLabel;
    return ourLabels.map((labelId) => labelIdMap[labelId]).filter((label) => label);
  }, [contactToLabel, labelIdMap, contactId]);
  return (
    <Labels
      id={contactId}
      size={size}
      labels={ourFilteredLabels}
    />
  );
}
