const getCountryCode = require('../helpers/getCountryFromTimezone');

const devGoogleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

export default () => {
  // eslint-disable-next-line
  const arr = window.location.href.split('//');
  let key = devGoogleMapsKey;
  if (arr.length > 1) {
    const [host] = arr[1].split(':');
    if (host === 'localhost') {
      // eslint-disable-next-line
      const { googleMapsKey } = require('../localconfig.js');
      key = googleMapsKey;
    }
  }
  const region = getCountryCode();
  const suffix = region ? `&region=${region}` : '';
  return `https://maps.googleapis.com/maps/api/js?key=${key}&libraries=places${suffix}`;
};
