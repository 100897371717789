import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Select, Row, Divider } from 'antd';
import {
  TagOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';

import { toTitleCase } from '../../helpers/helpers';
import { createNewStatus } from './workflowHelpers';

const HOVER_TEXT = 'Use this step to update the status of a form';

export default ({
  setElements,
  setDataMap,
  isDisplay,
  templateStatuses = [], // Statuses associated with a form imported from library
} = {}) => ({
  id,
  draggable,
  style = {},
  data = {},
}) => {
  const {
    statusId,
  } = data;

  const formStatuses = useSelector((state) => state.forms.statuses);
  const statusList = useMemo(() => Object.values(formStatuses), [formStatuses]);

  const [selectedStatus,setSelectedStatus] = useState(statusId)
  const [statuses,setStatuses] = useState(statusList);
  const [newStatus,setNewStatus] = useState();
  const [open,setOpen] = useState(false);
  
  const onDropdownVisibleChange = useCallback((newOpen) => {
    if(!newOpen) setNewStatus();
    setOpen(newOpen);
  },[]);
  const onNewStatusChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setNewStatus(value);
  },[]);

  const updateData = useCallback((newData) => {
    if(setDataMap) setDataMap((dataMap) => ({ ...dataMap, [id]: newData}));
    setSelectedStatus(newData.statusId);
  },[setDataMap, id,]);


  const onChange = useCallback((newSelection) => {
    const status = newSelection === undefined ? null : newSelection;
    updateData({ statusId: status });
  },[updateData]);


  const onAddClicked = useCallback(() => {
    const statusNames = new Set(statuses.map((s) => s.status));
    if(statusNames.has(newStatus)) return;
    const createdStatus = createNewStatus(newStatus);
    const newStatuses = statuses.concat([createdStatus]);
    updateData(createdStatus);
    setStatuses(Array.from(newStatuses));
    setNewStatus();
    setOpen(false);
  },[updateData, newStatus, statuses]);

  // Forms imported from library can have statuses that have not been created yet
  // create new statuses required in form template
  useEffect(() => {
    if (!templateStatuses.length) return;
    setStatuses((existingStatuses) => [...existingStatuses, ...templateStatuses]);
  }, [templateStatuses]);

  return (
    <WorkflowActionNode
      title='Status Update'
      Icon={TagOutlined}
      type='status'
      id={id}
      draggable={draggable}
      onNodeUpdate={setElements}
      style={style}
      isDisplay={isDisplay}
      hover={HOVER_TEXT}
    >
      {!draggable && <div>
        <Row style={{ marginTop: 5 }}>
          Select Status:
        </Row>
        <Row style={{ margin: '10px 0px' }}>
          <Select
            placeholder='Select Status'
            value={selectedStatus}
            onDropdownVisibleChange={onDropdownVisibleChange}
            onChange={onChange}
            open={open}
            dropdownMatchSelectWidth={false}
            dropdownRender={(menu) => (
              <div style={{ width: 200, padding: '0px 5px' }}>
                {menu}
                <Divider style={{ margin: '4px 0' }} />
                New Status:
                <OnTraccrTextInput
                  onChange={onNewStatusChange}
                  value={newStatus}
                  style={{ margin: '5px 0px' }}
                />
                <OnTraccrButton
                  icon={ <PlusOutlined />}
                  onClick={onAddClicked}
                  title='Add'
                  disabled={!newStatus}
                />
              </div>
            )}
          >
            {
              statuses.map((formStatus) => (
                <Select.Option value={formStatus.id} key={formStatus.id}>
                  {toTitleCase(formStatus.status)}
                </Select.Option>
              ))
            }
          </Select>
        </Row>
        <WorkflowHandle type='target' position='top' disabled={isDisplay}/>
        <WorkflowHandle type='source' position='bottom' disabled={isDisplay}/>
      </div>}
    </WorkflowActionNode>
  );
}