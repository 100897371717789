import { DateTime } from 'luxon';
import { stripText } from '../../../common/excel/excelHelpers';

export const defaultEstimateHeaders = [
  { key: 'estimatedLabourHours', title: 'Estimated Labour Hours' },
  { key: 'estimatedLabourCost', title: 'Estimated Labour Cost' },
  { key: 'estimatedMaterialCost', title: 'Estimated Material Cost' },
  { key: 'estimatedOverheadCost', title: 'Estimated Overhead Cost' },
  { key: 'estimatedEquipmentCost', title: 'Estimated Equipment Cost' },
  { key: 'estimatedBurdenRate', title: 'Burden Rate' },
  { key: 'contractValue', title: 'Contract Value' },
  { key: 'startDate', title: 'Start Date' },
  { key: 'endDate', title: 'End Date' },
];

export const defaultCostcodeHeaders = [
  { key: 'name', title: 'Name' },
  { key: 'code', title: 'Code' },
  { key: 'description', title: 'Description' },
  { key: 'category', title: 'Category' },
  { key: 'phase', title: 'Phase' },
  { key: 'hours', title: 'Hours' },
  { key: 'estimatedCost', title: 'Cost' },
  { key: 'estimatedQuantity', title: 'Quantity' },
  { key: 'units', title: 'Units' },
  { key: 'startDate', title: 'Start Date' },
  { key: 'endDate', title: 'End Date' },
];

export const categoryMap = {
  labor: 'Labor',
  labour: 'Labor',
  material: 'Material',
  overhead: 'Overhead',
  equipment: 'Equipment',
};

export const matchHeader = (key, defaultHeaders) => {
  if (!key) {
    return null;
  }
  let match;
  defaultHeaders.forEach((dh) => {
    const rk = stripText(key);
    const strippedTitle = stripText(dh.title);
    if (rk === strippedTitle) match = dh.key;
  });
  return match;
};

export const parseDate = (date) => {
  if (typeof date === 'string') {
    const dt = DateTime.fromFormat(date, 'yyyy/MM/dd');
    if (dt.invalidReason) return null;
    return dt.toMillis();
  }
  if (date?.valueOf) {
    return date.valueOf();
  }
  return null;
};
