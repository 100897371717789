import PropTypes from 'prop-types';
import React, {
  useCallback,
} from 'react';

import EmailRecipientSelector from './EmailRecipientSelector';

const REPLY_TO_HELP = 'The form author\'s email address will be set as the reply to email';

export default function EmailRecipientSelectors({
  id,
  data,
  setDataMap,
  divisionId,
  additionalOptions,
  sections,
  isExternalForm,
}) {
  const {
    users: initialSelected,
    cc: initialSelectedCC,
    bcc: initialSelectedBCC,
    replyTo: initialSelectedReplyTo,
  } = data ?? {};

  const onChange = useCallback((field) => (newSelected) => {
    if (setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            [field]: newSelected,
          },
        };
      });
    }
  }, [id, setDataMap]);

  return (
    <>
      <EmailRecipientSelector
        sections={sections}
        initialSelected={initialSelected}
        divisionId={divisionId}
        onChange={onChange('users')}
        additionalOptions={additionalOptions}
        text="Select Recipient(s):"
        showFormAuthor={!isExternalForm}
      />
      <EmailRecipientSelector
        sections={sections}
        initialSelected={initialSelectedCC}
        divisionId={divisionId}
        onChange={onChange('cc')}
        additionalOptions={additionalOptions}
        text="Select CC Recipient(s):"
        showFormAuthor={!isExternalForm}
      />
      <EmailRecipientSelector
        sections={sections}
        initialSelected={initialSelectedBCC}
        divisionId={divisionId}
        onChange={onChange('bcc')}
        additionalOptions={additionalOptions}
        text="Select BCC Recipient(s):"
        showFormAuthor={!isExternalForm}
      />
      <EmailRecipientSelector
        sections={sections}
        initialSelected={initialSelectedReplyTo}
        divisionId={divisionId}
        onChange={onChange('replyTo')}
        additionalOptions={additionalOptions}
        text="Select Reply-To Address(es):"
        showFormAuthor={!isExternalForm}
        authorHelpText={REPLY_TO_HELP}
      />
    </>
  );
}

EmailRecipientSelectors.propTypes = {
  additionalOptions: PropTypes.array,
  data: PropTypes.shape({}).isRequired,
  divisionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  isExternalForm: PropTypes.bool,
  sections: PropTypes.array,
  setDataMap: PropTypes.func.isRequired,
};

EmailRecipientSelectors.defaultProps = {
  additionalOptions: [],
  isExternalForm: false,
  sections: [],
};
