import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer, Row, Col, Form,
} from 'antd';

import { PropTypes } from 'prop-types';
import Permissions from '../auth/Permissions';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import HoverHelp from '../common/HoverHelp';

import BoardCard from './BoardCardView/BoardCard';
import BoardStatusDisplayFieldSelector from './BoardStatusDisplayFieldSelector';

export default function BoardStatusPreviewConfigureDrawer({
  visible,
  onClose,
  onSubmit,
  title,
  cardTypeId,
  displayFields: initialDisplayFields = [],
}) {
  const [form] = Form.useForm();
  const {
    [cardTypeId]: ourCardTemplate = {},
  } = useSelector((state) => state.boards.cardTemplates);
  const [displayFields, setDisplayFields] = useState(new Set());

  const onValuesChange = useCallback(({ displayFields: newDisplayFields }) => {
    setDisplayFields(new Set(newDisplayFields));
  }, []);

  const onSubmitClicked = useCallback(() => {
    onSubmit(displayFields);
  }, [displayFields]);

  const selectOptions = useMemo(() => {
    const { fields: sections = [] } = ourCardTemplate;
    const opts = [];
    sections.forEach((section) => {
      const { name: sectionName, fields: sectionFields = [] } = section;
      sectionFields.forEach((field) => {
        const {
          configProps: { title: fieldTitle } = {},
          id: fieldId,
          selectedType,
        } = field;
        if (selectedType === 'attachment') return;
        opts.push({ label: `${sectionName} - ${fieldTitle}`, value: fieldId });
      });
    });
    return opts;
  }, [ourCardTemplate]);

  const optionsSet = useMemo(() => new Set(selectOptions.map((opt) => opt.value)), [selectOptions]);

  const filteredInitialDisplayFields = useMemo(() => (
    initialDisplayFields?.filter((fieldId) => optionsSet.has(fieldId)) ?? []
  ), [initialDisplayFields, optionsSet]);

  const FAKE_USERS = useMemo(() => [Permissions.id], [Permissions.id]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  useEffect(() => {
    const safeInitial = filteredInitialDisplayFields || [];
    const fieldSet = new Set(safeInitial);
    form.setFieldsValue({
      displayFields: safeInitial,
    });
    setDisplayFields(fieldSet);
  }, [form, filteredInitialDisplayFields]);

  return (
    <Drawer
      width={500}
      title={`Configure ${title}`}
      visible={visible}
      onClose={onClose}
    >
      <div style={{ height: '100%' }}>
        <Form layout="vertical" form={form} onValuesChange={onValuesChange} initialValues={{ displayFields: filteredInitialDisplayFields || [] }}>
          <Form.Item
            label="Data to display"
            name="displayFields"
          >
            <BoardStatusDisplayFieldSelector options={selectOptions} />
          </Form.Item>
        </Form>
        <div className="board-status-configure-container">
          <Row
            className="form-field-preview-header"
            justify="space-between"
          >
            <Col>
              Preview:
            </Col>
            <Col>
              <HoverHelp placement="topRight" content="This is a preview of how the card will look in this column" />
            </Col>
          </Row>
          <div className="form-field-preview-background" style={{ flex: 1 }}>
            <div className="form-field-preview">
              <BoardCard
                draggable={false}
                data={ourCardTemplate.fields}
                displayFields={displayFields}
                title="Card Title"
                users={FAKE_USERS}
              />
            </div>
          </div>
        </div>
      </div>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
      />
    </Drawer>
  );
}

BoardStatusPreviewConfigureDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  cardTypeId: PropTypes.string.isRequired,
  displayFields: PropTypes.arrayOf(PropTypes.string),
};

BoardStatusPreviewConfigureDrawer.defaultProps = {
  visible: false,
  displayFields: [],
};
