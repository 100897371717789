import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, List, Row, Spin,
} from 'antd';

import FullPhoto from '../../common/photos/FullPhoto';
import { downloadFile, getFileType } from '../../files/fileHelpers';
import { getTaskPhotos } from '../state/timecards.actions';
import TimeCardPhotoEntry from './TimeCardPhotoEntry';

const TimeCardPhotos = function TimeCardPhotos({
  visible,
  user,
  initialEntries,
}) {
  const dispatch = useDispatch();
  const taskPhotos = useSelector((state) => state.timecards.taskPhotos || {});
  const {
    settings: {
      enableFacialCapture,
    } = {},
  } = useSelector((state) => state.settings.company || {});

  const {
    classId: defaultClassId,
  } = user;

  const [loading, setLoading] = useState();
  const [selectedFile, setSelectedFile] = useState();
  const clearSelectedFile = useCallback(() => setSelectedFile(), []);

  const entries = useMemo(() => (
    initialEntries.map((task) => ({
      ...task,
      classId: task.classId ?? defaultClassId,
    }))
  ), [initialEntries, defaultClassId]);

  const renderItem = useCallback((entry) => {
    const { id } = entry;
    const photos = taskPhotos[id];
    if (photos) {
      return (
        <TimeCardPhotoEntry
          entry={entry}
          photos={photos}
          onPreview={setSelectedFile}
        />
      );
    }
    return null;
  }, [taskPhotos]);

  useEffect(() => {
    const load = async () => {
      const ids = initialEntries.map((entry) => entry.id);
      if (ids && ids.length) {
        setLoading(true);
        await dispatch(getTaskPhotos(ids));
        setLoading(false);
      }
    };
    if (visible && enableFacialCapture && !loading) {
      load();
    }
  }, [dispatch, visible]);

  return (
    <div
      style={{
        position: 'absolute',
        top: '115px',
        bottom: '0px',
        left: '24px',
        right: '24px',
        overflowY: 'auto',
      }}
    >
      {
        loading
          ? <Row justify="center" align="middle" style={{ height: '100%' }}><Col><Spin /></Col></Row>
          : (
            <List
              dataSource={entries}
              renderItem={renderItem}
              rowKey={(item) => item.id}
              style={{ height: '100%', overflow: 'auto' }}
            />
          )
      }

      <FullPhoto
        file={selectedFile ? selectedFile.file : null}
        type={selectedFile ? getFileType(selectedFile.file) : null}
        onClose={clearSelectedFile}
        onDownload={() => {
          if (selectedFile.file) {
            downloadFile({ fileObject: selectedFile.file });
          }
        }}
      />
    </div>
  );
};

/* eslint-disable react/forbid-prop-types */
TimeCardPhotos.propTypes = {
  visible: PropTypes.bool,
  user: PropTypes.object,
  initialEntries: PropTypes.array,
};

TimeCardPhotos.defaultProps = {
  visible: false,
  user: {},
  initialEntries: [],
};

export default TimeCardPhotos;
