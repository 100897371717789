import React, { useMemo, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  MobileOutlined,
  SettingOutlined,
  UserOutlined,
} from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import UserAssignmentSelector from './selectors/UserAssignmentSelector';
import WorkflowPushNotificationConfigureDrawer from './WorkflowPushNotificationConfigureDrawer';
import NuxPopover from '../../nux/NuxPopover';

import {
  FORMS_WORKFLOW_STEP_4,
  FORMS_WORKFLOW_STEP_TARGET_TEXT,
} from '../../nux/nux.constants';
import config from '../../config';
import { getIdMap } from '../../helpers/helpers';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { Row } from 'antd';

export default ({
  type,
  id,
  draggable,
  onNodeUpdate,
  setDataMap,
  isDisplay,
  hover,
  divisionId,
  data = {},
  style = {},
  additionalOptions = [],
  children,
  showFormAuthor,
  formAuthorHelperText,
  sections = [],
  isExternalForm,
}) => {
  const {
    users: initialSelected,
  } = data;
  const [selected, setSelected] = useState(initialSelected || []);
  const [showDrawer, setShowDrawer] = useState(false);

  const teams = useSelector((state) => state.teams.teams);
  const divisions = useSelector(state => state.settings.divisions);
  const activeNuxAction = useSelector(state => state.nux.activeNuxAction);
  const { userIds } = useSelector(state => {
    const {
      users: {
        users = [],
      } = {},
    } = state;
    const idSet = new Set();
    users.forEach((user) => {
      if(user.active) {
        idSet.add(user.id);
      }
    });
    return { userIds: idSet };
  });

  const teamsMap = useMemo(() => getIdMap(teams), [teams]);
  const additionalIds = useMemo(() => new Set(additionalOptions.map((addOpt) => addOpt.id)),[additionalOptions]);
  const {
    title,
    Icon,
    tokenSeparators,
    mode = 'multiple',
    text = 'Select Recipient(s):',
    validate,
  } = useMemo(() => {
    if (type === 'users') {
      return {
        title:'Assigned Users',
        Icon: UserOutlined,
        tokenSeparators:null,
      }
    } else {
      return {
        title:'Notify in App',
        Icon: MobileOutlined,
        tokenSeparators:null,
      };
    }
  },[type,userIds,additionalIds]);

  const onOpenDrawer = useCallback(() => setShowDrawer(true), []);
  const onCloseDrawer = useCallback(() => setShowDrawer(false), []);
  const onChange = useCallback((newSelected) => {
    let filteredSelected = newSelected;
    if(validate) {
      filteredSelected = validate(newSelected);
    }

    setSelected(filteredSelected);

    if(setDataMap) {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            ['users']: filteredSelected,
          },
        };
      })
    }
  },[validate,id,setDataMap]);

  useEffect(() => {
    if(initialSelected && divisionId) {
      let ourSelected = initialSelected;
      if(config.showDivisions) {
        const {
          [divisionId]: {
            users: divisionUsers = new Set(),
          } = {},
        } = divisions;
        ourSelected = initialSelected.filter((userId) => userId.length !== 36 || divisionUsers.has(userId) || userId in teamsMap);
      }
      setSelected(ourSelected);

    }
  },[initialSelected, divisionId, divisions, teamsMap]);

  const isNuxStep4 = activeNuxAction === FORMS_WORKFLOW_STEP_4;

  return (
    <WorkflowActionNode
      title={title}
      Icon={Icon}
      type={type}
      id={id}
      draggable={draggable}
      onNodeUpdate={onNodeUpdate}
      style={style}
      isDisplay={isDisplay}
      hover={hover}
    >
      {!draggable && <div>
        {children}
        <UserAssignmentSelector
          isDisplay={isDisplay}
          draggable={draggable}
          divisions={[divisionId]}
          onChange={onChange}
          selected={selected}
          mode={mode}
          showPositions={type === 'users'}
          type={type}
          text={text}
          tokenSeparators={tokenSeparators}
          additionalOptions={additionalOptions}
          showTeams
          showFormAuthor={showFormAuthor && !isExternalForm}
          formAuthorHelperText={formAuthorHelperText}
          isExternalForm={isExternalForm}
        />
        <NuxPopover
          placement='rightTop'
          visible={isNuxStep4}
          text={FORMS_WORKFLOW_STEP_TARGET_TEXT}
        >
          <WorkflowHandle type='target' position='top' disabled={isDisplay}/>
        </NuxPopover>
        {type === 'users' &&
          <WorkflowHandle type='source' position='bottom' disabled/>
        }
        {type === 'push' &&
          <div>
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title='Configure'
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={onOpenDrawer}
              />
            </Row>
            <WorkflowPushNotificationConfigureDrawer
              id={id}
              visible={showDrawer}
              onClose={onCloseDrawer}
              onSubmit={onCloseDrawer}
              data={data}
              setDataMap={setDataMap}
              sections={sections}
            />
          </div>
        }
      </div>}
    </WorkflowActionNode>
  );
}