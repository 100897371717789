import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import SageExportPreview from './SageExportPreview';

export default ({
  isEmployeePayroll,
  rangeValue = [],
  isRounded,
  filters = {},
}) => {
  const {
    company: {
      connectedToSage,
    } = {},
  } = useSelector((state) => state.settings);

  const [drawerVisible,setDrawerVisible] = useState(false); 

  const onCloseDrawer = useCallback(() => setDrawerVisible(false),[]);
  const onClick = useCallback(async () => {
    setDrawerVisible(true);
  },[]);

  if (!connectedToSage || !isEmployeePayroll || rangeValue.length < 2) return null;
  return (
    <>
      <OnTraccrButton
        title='Export to Sage'
        type='cancel'
        onClick={onClick}
      />
      <SageExportPreview
        visible={drawerVisible}
        onClose={onCloseDrawer}
        rangeValue={rangeValue}
        filters={filters}
        isRounded={isRounded}
      />
    </>
  )
}
