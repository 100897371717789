import React from 'react';
import { useTranslation } from 'react-i18next';

import IntegrationProjectSync from '../IntegrationProjectSync';

import {
  importProjectHelpText,
  autoImportHeader,
  autoImportUncheckText,
} from '../integrationHelpers';

const autoImportContent = (
  <div style={{ width: 500 }}>
    {autoImportHeader('Procore', 'Projects')}
    <br/><br/>1. Make sure the Projects in Procore have their address, latitude and longitude set
    <br/><br/>{autoImportUncheckText('Procore')}
  </div>
);


const getImportHelpText = (t) => importProjectHelpText(t, 'Procore', t('Project'));


export default function ProcoreProjectSync({
  procoreProjects,
  onProjectsChanged,
  importProjects,
}) {
  const { t } = useTranslation();
  const importHelpText = getImportHelpText(t);
  return (
    <IntegrationProjectSync
      integrationProjects={procoreProjects}
      title='Procore'
      integrationKey='procoreId'
      onProjectsChanged={onProjectsChanged}
      showCustomer={false}
      showNumber
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      importProjects={importProjects}
    />
  );
}
