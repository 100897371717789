import React from 'react';
import { useSelector } from 'react-redux';
import  { Select } from 'antd';

import DisplayText from '../common/text/DisplayText';

export default ({
  isDisplay,
  displayValue,
  value,
  onChange,
  displayStyle = {},
}) =>  {
  const formTypes = useSelector(state => state.forms.types);
  if(isDisplay) {
    return (
      <DisplayText title={displayValue} style={displayStyle}/>
    )
  }
  return (
    <Select
      placeholder='Select a form type'
      onChange={onChange}
      value={value}
    >
      {
        formTypes.map((type) => (
          <Select.Option value={type.id} key={type.id}>
            {type.name}
          </Select.Option>
        ))
      }
    </Select>
  )
}