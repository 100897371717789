import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import axios from 'axios';

import { request } from '../helpers/requests';

import DisplayText from '../common/text/DisplayText';
import BorderlessButton from '../common/buttons/BorderlessButton';

const ICON_STYLE = { marginLeft: 0 };

function UserPin({
  userId,
  hasPin,
  canView,
}) {
  const [visible, setVisible] = useState(false);
  const [pinText, setPinText] = useState(hasPin ? '••••' : 'None');

  const onToggle = useCallback(async () => {
    if (!visible) {
      // Get pin
      const { data: realPin } = await request({
        call: axios.get(`/users/${userId}/pin`),
        hideSuccessToast: true,
        errMsg: 'Could not get pin',
      });
      if (realPin) {
        setPinText(realPin);
        setVisible(!visible);
      }
    } else {
      setPinText(hasPin ? '••••' : 'None');
      setVisible(!visible);
    }
  }, [visible, hasPin, userId]);

  const isDots = hasPin && !visible;
  return (
    <Row gutter={20}>
      <Col style={{ height: 50 }}>
        <DisplayText
          title={pinText}
          style={{ fontSize: isDots ? 20 : 12, marginTop: isDots ? 0 : 5 }}
        />
      </Col>
      {
        !!(hasPin && canView)
        && (
          <Col>
            <BorderlessButton
              iconNode={
                visible
                  ? <EyeInvisibleOutlined style={ICON_STYLE} />
                  : <EyeOutlined style={ICON_STYLE} />
              }
              onClick={onToggle}
            />
          </Col>
        )
      }
    </Row>
  );
}

UserPin.propTypes = {
  userId: PropTypes.string.isRequired,
  hasPin: PropTypes.bool.isRequired,
  canView: PropTypes.bool.isRequired,
};

export default UserPin;
