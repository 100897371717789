import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

export default ({
  value,
  record,
  onLocationSelect,
}) => {
  const equipment = useSelector(state => state.equipment.equipment);
  const globalMaterialLocations = useSelector(state => state.globalMaterialLocations.globalMaterialLocations);

  const activeEquipment = useMemo(() => equipment.filter((eq) => eq.active), [equipment]);

  const optionsMap = useMemo(() => {
    const opts = {};
    activeEquipment.forEach((eq) => {
      opts[eq.id] = { equipmentId: eq.id, globalMaterialLocationsId: undefined};
    });
    globalMaterialLocations.forEach((location) => {
      opts[location.id] = { equipmentId: undefined, globalMaterialLocationsId: location.id };
    });
    return opts;
  }, [globalMaterialLocations, activeEquipment]);

  const onSelect = useCallback((selectedId) => {
    onLocationSelect(record.id, optionsMap[selectedId])
  }, [onLocationSelect, record, optionsMap]);


  return (
    <Select
      key={record.id}
      value={value}
      onSelect={onSelect}
      showSearch
      optionFilterProp='label'
    >
      {
        activeEquipment.map((eq) => (
          <Select.Option value={eq.id} label={eq.name} key={eq.id}>
            {eq.name}
          </Select.Option>
        ))
      }
      {globalMaterialLocations.map((location) => (
        <Select.Option value={location.id} label={location.locationText} key={location.id}>
          {location.locationText}
        </Select.Option>
      ))
      }
    </Select>
  )
}