import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { Form, Row, Col, Checkbox, DatePicker } from 'antd';
import { DateTime } from 'luxon';

import FormTextInput from '../common/inputs/FormTextInput';
import FormCurrencyInput from '../common/inputs/FormCurrencyInput';
import FormNumberInput from '../common/inputs/FormNumberInput';
import TimeFramePicker from '../common/inputs/TimeFramePicker';
import BillingRateSelector from '../billingRates/BillingRateSelector';

import { percentFormatter } from '../helpers/inputParsers';
import DisplayText from '../common/text/DisplayText';
import { calculateExpirationReminder } from '../projects/projectHelpers';

const formStyle = { marginBottom:0, paddingBottom:-8 };
const formLabelStyle = {
  style:{
    paddingBottom:5,
    marginTop:10,
  },
};

export default ({
  isNotDisplay,
  materialDiscount,
  showContractOptions,
  contractExpirationTimestamp,
  contractReminderTimestamp,
  customBillingRate,
  showPOandContact,
  billingContact,
  poNumber, 
}) => {
  const [hasContract,setHasContract] = useState(!!contractExpirationTimestamp);
  const [hasCustomRate, setHasCustomRate] = useState(false);

  const onHasContractChange = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setHasContract(checked);
  }, []);

  const onCustomRateChecked = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setHasCustomRate(checked);
  }, []);

  useEffect(() => {
    if (customBillingRate) setHasCustomRate(true);
  },[customBillingRate, isNotDisplay]);

  const expirationDisplayValue = useMemo(() => {
    if(!contractExpirationTimestamp) return null;
    return new DateTime.fromMillis(contractExpirationTimestamp).toLocaleString(DateTime.DATE_MED);
  },[contractExpirationTimestamp]);

  const reminderDisplay = useMemo(() => (
    calculateExpirationReminder({
      isNotDisplay,
      contractExpirationTimestamp,
      contractReminderTimestamp
    })
  ),[isNotDisplay,contractExpirationTimestamp, contractReminderTimestamp]);

  const {
    expireStyle = {},
    reminderStyle = {},
  } = useMemo(() => {
    if(isNotDisplay || !contractExpirationTimestamp) return {};
    const now = DateTime.local().startOf('day');
    if (contractExpirationTimestamp < now) return { expireStyle: { color: 'red' } };
    if (contractReminderTimestamp && contractReminderTimestamp < now) return { reminderStyle: { color: 'orange' } };
    return {};
  }, [isNotDisplay,contractExpirationTimestamp, contractReminderTimestamp]);

  const numCols = showPOandContact ? 4 : 2;
  const offset = isNotDisplay ? 4 : 0;
  const colSpan = (24 - offset) / numCols;
  return (
    <>
      <Row justify='start' align='top' style={{ width:'100%' }}>
        {isNotDisplay && <Col style={{ marginTop: 42, maxWidth: 350, }}>
          <Checkbox
            onChange={onCustomRateChecked}
            checked={hasCustomRate}
          >
            Custom Rate?
          </Checkbox>
        </Col>}
        <Col span={colSpan} style={{ paddingRight: 12, maxWidth: 350 }}>
          {hasCustomRate 
          ? 
          <FormCurrencyInput
            isNotDisplay={isNotDisplay}
            label='Billing Rate'
            name='customBillingRate'
            min={0}
            value={customBillingRate ? `$${customBillingRate.toFixed(2)}` : null}
          />
          : <Form.Item 
            name='billingRateId'
            label='Billing Rate'
            valuePropName='billingRateId'
            style={formStyle}
            labelCol={formLabelStyle}
          >
            <BillingRateSelector
              isNotDisplay={isNotDisplay}
              canAddCustom
            />
          </Form.Item>
          }
        </Col>
        <Col span={colSpan} style={{ paddingRight: 30, paddingLeft: 6, maxWidth: 350 }}>
          <FormNumberInput
            isNotDisplay={isNotDisplay}
            label='Material Discount'
            name='materialDiscount'
            value={materialDiscount ? `${materialDiscount * 100} %` : materialDiscount}
            numberInputProps={{
              formatter: percentFormatter,
            }}
          />
        </Col>
        {showPOandContact && <Col span={colSpan} style={{ paddingRight: 30, paddingLeft: 6, maxWidth: 350 }}>
          <FormTextInput
            isNotDisplay={isNotDisplay}
            label='Billing Contact Email'
            name='billingContact'
            rules={[{ type:'email', message: 'Invalid email format', transform: (val) => val ? val.trim() : val }]}
            value={billingContact}
          />
        </Col>}
        {showPOandContact && <Col span={colSpan} style={{ paddingRight: 30, paddingLeft: 6, maxWidth: 350 }}>
          <FormTextInput
            isNotDisplay={isNotDisplay}
            label='PO Number'
            name='poNumber'
            value={poNumber}
          />
        </Col>}
      </Row>
      {showContractOptions && (isNotDisplay || hasContract) &&
        <Row justify='start' align='middle' gutter={30} style={{ minHeight: 70 }}>
          {isNotDisplay && (
            <Col>
              <Form.Item 
                name='hasContract'
                label=''
                style={formStyle}
                labelCol={formLabelStyle}
                initialValue={hasContract}
                valuePropName="checked"
              >
                <Checkbox
                  checked={hasContract}
                  onChange={onHasContractChange}
                  style={{ marginTop: 35 }}
                >
                  Contract ?
                </Checkbox>
              </Form.Item>
            </Col>
          )}
          {hasContract && 
            <Col>
              <Form.Item 
                name='contractExpiration'
                label='Contract Expiration Date'
                style={formStyle}
                labelCol={formLabelStyle}
              >
                {
                  isNotDisplay
                  ? <DatePicker  format='MMM Do YY'/>
                  : <DisplayText title={expirationDisplayValue} style={expireStyle}/>
                }
              </Form.Item>
            </Col>
          }
          {hasContract && 
          <Col>
            <Form.Item 
              name='contractReminder'
              label='Contract Reminder'
              style={formStyle}
              labelCol={formLabelStyle}
            >
            {
              isNotDisplay
              ? <TimeFramePicker />
              : <DisplayText title={reminderDisplay} style={reminderStyle}/>
            }
            </Form.Item>
          </Col>
          }
        </Row>
        }
    </>
  );
}
