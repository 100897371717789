import axios from 'axios';
import React from 'react';
import { Tag } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import formToPDF from 'ontraccr-form-to-pdf';
import { FormHelpers } from 'ontraccr-common';

import colors from '../constants/Colors';

import { INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE } from '../payables/invoices/invoiceConstants';
import { getFileType, removeTrailingSlash } from '../files/fileHelpers';
import sortByString, {
  currencyFormatter,
  getIdMap,
  includesTerm,
  toTitleCase,
} from '../helpers/helpers';
import { sortByObjProperty } from '../common/helpers';

import { getFormById, getTemplateDetails } from './state/forms.actions';
import { decoratePayloadWithLabels } from '../helpers/labels';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const axiosFileInstance = axios.create();

export default {};

export const formatFormTemplateName = (
  formTemplate = {},
  projectIdMap = {},
) => {
  const prefix = formTemplate?.projectId ? `${projectIdMap[formTemplate.projectId]?.name} - ` : '';
  const suffix = formTemplate?.name ?? '';

  return `${prefix}${suffix}`;
};

export const generateId = () => DateTime.local().toMillis();
export const parseFormDates = (form = {}) => {
  const {
    lastUpdated,
    createdAt,
  } = form;

  const lastUpdatedDate = lastUpdated
    ? DateTime.fromMillis(lastUpdated).toLocaleString(DateTime.DATETIME_MED)
    : null;

  const createdAtDate = createdAt
    ? DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATETIME_MED)
    : null;

  return {
    lastUpdatedDate,
    createdAtDate,
  };
};

export const getNumberOfAssignedAndDraftForms = ({
  drafts: {
    assigned: assignedDrafts = {},
  } = {},
  assignedForms = {},
} = {}) => {
  const uniqueForms = new Set(assignedForms.map((form) => form.id));
  Object.keys(assignedDrafts).forEach((formId) => {
    uniqueForms.add(formId);
  });
  return uniqueForms.size;
};

export const decorateFile = async (file, fileId, onlyImages) => {
  if (!file) return;
  try {
    const { url, name } = file;
    const {
      data,
      headers = {},
    } = await axiosFileInstance.get(url, {
      responseType: 'arraybuffer',
    });
    const {
      'content-type': trueType,
    } = headers;
    let type = getFileType({ name });
    if (onlyImages) {
      // PDF Designer only supports these two types.
      // When we try to reupload in createTemplate, we need the full type
      // not just type === 'image'
      type = name.includes('png') ? 'image/png' : 'image/jpeg';
    }
    file.jsFileObject = new File([data], name, { type });
    file.jsFileObject.existing = true;
    file.jsFileObject.id = fileId;
    file.trueType = trueType;
  } catch (e) {
    // noop
  }
};

export const decorateFormWithFiles = async (form, {
  onlyImages, templateSections = form?.formData?.sections,
} = {}) => {
  const { fileMap } = form;
  const fileIds = Object.keys(fileMap);
  if (fileIds.length === 0) return;
  await Promise.all(
    fileIds.map(async (fileId) => {
      try {
        const file = fileMap[fileId];
        await decorateFile(file, fileId, onlyImages);
      } catch (e) {
        // noop
      }
    }),
  );

  templateSections?.forEach?.((section) => {
    const fields = section?.fields ?? [];
    fields?.forEach?.((field) => {
      if (field?.selectedType !== 'staticAttachments') return;
      const configProps = field?.configProps ?? {};
      const fieldFileIds = configProps?.files ?? [];
      field.configProps = { // eslint-disable-line no-param-reassign
        ...configProps,
        files: fieldFileIds.map((fileId) => fileMap[fileId])
          .filter((file) => !!file),
      };
    });
  });
};

/**
 * Gets the phased-costcode key
 * @param {string} phaseId
 * @param {string} costcodeId
 * @returns {string}
 */
export const getPhasedCostcodeKey = (phaseId, costcodeId) => `${phaseId}.${costcodeId}`;

/**
 * If the dataType is 'Costcodes' Reconstructs id by combing phaseId with costcodeId
 * @param {string} dataType
 * @param {array} values
 * @returns {array} parsed costcodes
 */
const parseSelectedDropdowns = (dataType, values = []) => {
  if (dataType !== 'Costcodes') return values;
  return values.map(({ id: costcodeId, phaseId, name }) => ({
    id: phaseId ? getPhasedCostcodeKey(phaseId, costcodeId) : `unphased.${costcodeId}`,
    name,
  }));
};

const getStaticAttachmentField = ({
  field = {},
  configProps = {},
  fileIds = [],
  fileMap = {},
  setSelectedFile,
  setSelectedFileDetails,
  getFromMap,
}) => {
  const fullFiles = getFromMap ? [] : fileIds;
  const fullFileMap = [];
  if (getFromMap) {
    fileIds.forEach((fileId, index) => {
      if (fileId in fileMap && fileMap[fileId].jsFileObject) {
        const file = fileMap[fileId];
        fullFiles.push(file);
        fullFileMap.push(index);
      }
    });
  }

  return {
    configProps: {
      ...field,
      ...configProps,
      files: fullFiles,
      onClick: async (idx) => {
        const file = fullFiles[idx]?.jsFileObject;
        setSelectedFile(file);
        if (setSelectedFileDetails) setSelectedFileDetails({ ...field, index: idx });
        const ourFileIndex = fullFileMap[idx];
        if (ourFileIndex < fileIds.length) {
          const ourFileId = fileIds[ourFileIndex];
          await axios.post('/events', { id: ourFileId });
        }
      },
    },
  };
};

// We want these keys to show up no matter what
const tableKeysToMaintain = new Set([
  'id',
  'name',
  'description',
  'price',
  'hourlyCost',
  'hourlyBillingRate',
  'dailyCost',
  'dailyBillingRate',
]);

/**
 * Gets the difference between two responses. If there is no difference, the response is removed
 * Otherwise we show the new value
 */
const getResponseDiff = ({
  type,
  response = {},
  secondaryResponse,
  fileMap,
}) => {
  const newResponse = {
    ...response,
  };

  // These types should ignore the secondary response if it's null
  const setToIgnoreSecondaryResponseNull = new Set(['multiSig', 'attachment']);

  if (!secondaryResponse && !setToIgnoreSecondaryResponseNull.has(type)) {
    return newResponse;
  }

  switch (type) {
    case 'yes-no': {
      const { value, explanation } = response;
      const { value: secondaryValue, explanation: secondaryExplanation } = secondaryResponse;

      if (secondaryValue === value) {
        newResponse.selected = null;
        newResponse.value = null;
      }

      if (secondaryExplanation === explanation) {
        newResponse.explanation = null;
      }

      return newResponse;
    }
    case 'dropdown': {
      const { selected = [] } = response;
      const { selected: secondarySelected = [] } = secondaryResponse;
      const secondarySet = new Set(secondarySelected.map((val) => val.id));
      newResponse.selected = selected.filter((val) => !secondarySet.has(val.id));
      return newResponse;
    }
    case 'multiSig': {
      const { values = [] } = response;
      const { values: secondaryValues = [] } = secondaryResponse ?? {};
      const secondarySet = new Set(secondaryValues.map((val) => val.sig));
      newResponse.values = values
        .filter((val) => !secondarySet.has(val.sig))
        .map((val) => {
          const { sig } = val;
          const res = { ...val };
          if (sig in fileMap && fileMap[sig].jsFileObject) {
            res.sig = fileMap[sig].jsFileObject;
          }
          return res;
        });
      return newResponse;
    }
    case 'attachment': {
      const { fileIds = [], timestamps } = response;
      const { fileIds: secondaryFileIds = [] } = secondaryResponse ?? {};
      const secondarySet = new Set(secondaryFileIds);
      const relevantFileIds = fileIds.filter((fileId) => !secondarySet.has(fileId));
      const fullFiles = [];
      const fullFileMap = [];
      relevantFileIds.forEach((fileId, index) => {
        if (fileId in fileMap && fileMap[fileId].jsFileObject) {
          fullFiles.push(fileMap[fileId].jsFileObject);
          fullFileMap.push(index);
        }
      });
      return {
        fileIds: relevantFileIds,
        fullFiles,
        fullFileMap,
        timestamps,
      };
    }
    case 'attribute':
    case 'text': {
      const { value } = response;
      const { value: secondaryValue } = secondaryResponse;
      if (secondaryValue === value) {
        newResponse.value = null;
      }

      if (value?.startsWith(secondaryValue)) {
        // Remove the secondary value from the new value + a comma
        newResponse.value = value.slice(secondaryValue.length + 1);
      }

      return newResponse;
    }
    case 'dateRange': {
      const { startTime, endTime } = response;
      const { startTime: secondaryStart, endTime: secondaryEnd } = secondaryResponse;
      if (secondaryStart === startTime) {
        newResponse.startTime = null;
      }

      if (secondaryEnd === endTime) {
        newResponse.endTime = null;
      }

      return newResponse;
    }
    case 'dateTime': {
      const { date, time } = response;
      const { date: secondaryDate, time: secondaryTime } = secondaryResponse;

      // If both values are the same, remove them
      if (secondaryDate === date && secondaryTime === time) {
        newResponse.date = null;
        newResponse.time = null;
      }
      return newResponse;
    }
    case 'table': {
      const { values: responseValues = [] } = response;
      const { values: secondaryValues = [] } = secondaryResponse;

      const secondaryResponseMap = secondaryValues?.reduce((acc, row) => {
        acc[row.id] = row;
        return acc;
      }, {});

      newResponse.values = responseValues.map((row) => {
        const previousRow = secondaryResponseMap[row.id];

        // Row was added
        if (!previousRow) return row;
        const rowDiff = {
          ...row,
        };

        let rowHasDiff = false;

        Object.keys(rowDiff).forEach((key) => {
          if (rowDiff[key] !== previousRow[key]) rowHasDiff = true;

          // If the value is the same, remove it
          if (rowDiff[key] === previousRow[key]) {
            // Unless it's a key we want to maintain
            if (!tableKeysToMaintain.has(key)) rowDiff[key] = null;
            // Otherwise if it's a number, we want to show the difference
          } else if (typeof rowDiff[key] === 'number') {
            rowDiff[key] = (rowDiff[key] ?? 0) - (previousRow[key] ?? 0);
            // Otherwise if it's a string, we want to show the difference
          } else if (typeof rowDiff[key] === 'string' && rowDiff[key]?.startsWith(previousRow[key])) {
            rowDiff[key] = rowDiff[key].slice(previousRow[key].length + 1);
          }
        });
        return rowHasDiff ? rowDiff : null;
      }).filter((row) => row);
      return newResponse;
    }
    default: {
      return newResponse;
    }
  }
};

const getNewConditionalRenderingFormula = (fieldIdMap, sectionId) => (formula) => {
  const relevantField = fieldIdMap[formula.field];
  if (relevantField?.sectionId !== sectionId) {
    const fields = Object.values(fieldIdMap);
    const relevantFieldInSection = fields.find((field) => (
      field.duplicatedParentId === formula.field && field.sectionId === sectionId
    ));

    if (relevantFieldInSection) {
      return {
        ...formula,
        field: relevantFieldInSection.id ?? relevantFieldInSection.fieldId,
      };
    }
  }

  return formula;
};

const parseResponse = ({
  field,
  type,
  response = {},
  fileMap,
  setSelectedFile,
  setSelectedFileDetails,
  templateMap = {},
  configPropsMap = {},
  secondaryFormFieldMap,
  fieldMap,
}) => {
  const {
    fieldId,
    sectionId,
    title: fieldTitle,
    duplicateParentSectionId,
    duplicatedParentId,
  } = field;

  const {
    [duplicateParentSectionId ?? sectionId]: {
      [duplicatedParentId ?? fieldId]: {
        configProps: templateConfig = {},
      } = {},
    } = {},
  } = templateMap;

  if (
    templateConfig?.hasConditionalRendering
    && FormHelpers.isConditionalRenderingFormulaComplete(
      templateConfig.conditionalRenderingFormula,
    )
  ) {
    const formula = templateConfig.conditionalRenderingFormula;

    if (Array.isArray(formula)) {
      templateConfig.conditionalRenderingFormula = formula.map(
        getNewConditionalRenderingFormula(fieldMap, sectionId),
      );
    } else {
      templateConfig.conditionalRenderingFormula = getNewConditionalRenderingFormula(
        fieldMap,
        sectionId,
      )(formula);
    }
  }

  const { response: secondaryResponse } = secondaryFormFieldMap?.[fieldId] ?? {};

  switch (type) {
    case 'yes-no': {
      const { value, explanation } = response;
      let ans;
      // value === undefined if user didnt fill out the field
      // value === null if user selected N/A
      if (value === true) {
        ans = 'yes';
      } else if (value === false) {
        ans = 'no';
      } else if (value === null) {
        ans = 'n/a';
      }

      const parsedResponse = {
        ...response,
        selected: ans,
      };

      return {
        configProps: {
          ...field,
          ...templateConfig,
          ...configPropsMap[fieldId],
          explain: explanation ? [ans] : [],
        },
        response: getResponseDiff({
          type,
          response: parsedResponse,
          secondaryResponse,
        }),
      };
    }
    case 'dropdown': {
      const {
        values = [],
      } = response;
      const {
        dataType,
      } = templateConfig;

      return {
        configProps: {
          ...field,
          ...templateConfig,
          ...configPropsMap[fieldId],
          numAnswers: values.length,
        },
        response: getResponseDiff({
          type,
          response: {
            ...response,
            selected: parseSelectedDropdowns(dataType, values),
          },
          secondaryResponse: secondaryResponse
            ? {
              ...secondaryResponse,
              selected: parseSelectedDropdowns(dataType, secondaryResponse.values),
            } : null,
        }),
      };
    }
    case 'multiSig':
      return {
        configProps: {
          ...field,
          ...configPropsMap[fieldId],
        },
        response: getResponseDiff({
          type,
          response,
          secondaryResponse,
          fileMap,
        }),
      };
    case 'staticAttachments': {
      return getStaticAttachmentField({
        field,
        configProps: {
          ...configPropsMap[fieldId],
          ...templateConfig,
        },
        fileIds: response?.fileIds,
        fileMap,
        setSelectedFile,
        setSelectedFileDetails,
        getFromMap: true,
      });
    }
    case 'attachment': {
      const {
        fileIds,
        fullFiles = [],
        fullFileMap = [],
      } = getResponseDiff({
        type,
        response,
        secondaryResponse,
        fileMap,
      });

      return {
        configProps: {
          ...field,
          ...templateConfig,
          ...configPropsMap[fieldId],
          showButton: false,
        },
        response: {
          ...response,
          files: fullFiles,
          onClick: async (idx) => {
            setSelectedFile(fullFiles[idx]);
            if (setSelectedFileDetails) setSelectedFileDetails({ ...field, index: idx });
            const ourFileIndex = fullFileMap[idx];
            if (ourFileIndex < fileIds.length) {
              const ourFileId = fileIds[ourFileIndex];
              await axios.post('/events', { id: ourFileId });
            }
          },
          hideDelete: true,
        },
      };
    }
    case 'gpsLocation': {
      const {
        values = [],
      } = response;
      return {
        configProps: {
          ...field,
          ...templateConfig,
          ...configPropsMap[fieldId],
          numAnswers: values.length,
        },
        response: {
          ...response,
          values,
        },
      };
    }
    case 'staticText': {
      return {
        configProps: { ...templateConfig, ...configPropsMap[fieldId], title: fieldTitle },
      };
    }
    case 'weather':
    case 'dateRange':
    case 'dateTime':
    case 'attribute':
    case 'table':
    case 'text':
      return {
        configProps: {
          columns: response.columns,
          dataType: response.dataType,
          optional: response.optional,
          ...field,
          ...templateConfig,
          ...configPropsMap[fieldId],
        },
        response: getResponseDiff({
          type,
          response,
          secondaryResponse,
        }),
      };
    case 'calculation':
    default:
      return {
        configProps: {
          ...field,
          ...templateConfig,
          ...configPropsMap[fieldId],
        },
        response,
      };
  }
};

export const parseFormTemplate = ({
  sections = [],
  fileMap = {},
  setSelectedFile,
  setSelectedFileDetails,
}) => (
  sections.map((section) => {
    const fields = section?.fields ?? [];
    return {
      ...section ?? {},
      fields: fields.map((field) => {
        if (field.selectedType !== 'staticAttachments') return field;
        return {
          ...field,
          ...getStaticAttachmentField({
            field: {
              response: {
                fileIds: field.configProps?.files?.map((file) => file.id) ?? [],
              },
            },
            configProps: field.configProps,
            fileIds: field.configProps?.files,
            fileMap,
            setSelectedFile,
            setSelectedFileDetails,
          }),
        };
      }),
    };
  })
);

export const parseCompletedForm = ({
  sections = [],
  fileMap = {},
  templateMap = {},
  setSelectedFile,
  setSelectedFileDetails,
  hideFieldsWithNoTemplate,
  hideTimeCardSection,
  configPropsMap = {},
  secondaryFormFieldMap, // if we want to compare the response to a secondary form
}) => {
  const fieldMap = sections.reduce((acc, section) => {
    const { fields = [] } = section;
    fields?.forEach((field) => {
      const { fieldId } = field;
      acc[fieldId] = field;
    });
    return acc;
  }, {});

  return sections
    .filter((section, sectionIdx) => {
      const {
        fields = [],
      } = section;
      const [{ sectionId = `section-${sectionIdx}` } = {}] = fields;
      if (sectionId === 'timecard' && hideTimeCardSection) return false;
      return !hideFieldsWithNoTemplate || sectionId in templateMap;
    })
    .map((section, sectionIdx) => {
      const {
        name,
        fields = [],
        duplicatedParentId,
        settings = {},
      } = section;
      const [{ sectionId = `section-${sectionIdx}` } = {}] = fields;
      const templateSettings = templateMap[sectionId]?._Settings;
      const ourPermissions = templateSettings
        ? templateSettings.permissions
        : settings?.permissions;

      return {
        id: sectionId,
        name,
        settings: { ...settings, permissions: ourPermissions },
        fields: fields.filter((field) => {
          const { fieldId, sectionId: fieldSection } = field;
          return !hideFieldsWithNoTemplate
          || (fieldSection in templateMap && fieldId in templateMap[fieldSection]);
        })
          .map((field, fieldIdx) => (
            {
              id: field.fieldId ?? `field-${fieldIdx}`,
              fieldId: field.fieldId ?? `field-${fieldIdx}`,
              sectionId: field.sectionId,
              test: configPropsMap[field.fieldId],
              type: field.type,
              selectedType: field.type,
              ...parseResponse({
                field: {
                  ...field,
                  duplicateParentSectionId: duplicatedParentId,
                },
                type: field.type,
                response: field.response,
                fileMap,
                setSelectedFile,
                setSelectedFileDetails,
                templateMap,
                hideFieldsWithNoTemplate,
                configPropsMap,
                secondaryFormFieldMap,
                fieldMap,
              }),
            }
          )),
      };
    });
};

export const createSourceToTargetMap = (targetToSourceMap = {}) => {
  const sourceFieldMap = {};
  Object.keys(targetToSourceMap).forEach((targetId) => {
    const sourceId = targetToSourceMap[targetId];
    if (!(sourceId in sourceFieldMap)) sourceFieldMap[sourceId] = [];
    sourceFieldMap[sourceId].push(targetId);
  });
  return sourceFieldMap;
};

export const createTargetToSourceMap = (sourceToTargetMap = {}) => {
  const targetFieldMap = {};
  Object.keys(sourceToTargetMap).forEach((sourceId) => {
    const targetIds = sourceToTargetMap[sourceId];
    targetIds.forEach((targetId) => {
      targetFieldMap[targetId] = sourceId;
    });
  });
  return targetFieldMap;
};

const linkToDataType = {
  projectId: 'Projects',
  customerId: 'Customers',
  userId: 'Users',
  equipmentId: 'Equipment',
};
export const fillFormFromCard = ({
  formTemplate = {},
  cardData = [],
  mappings = {},
  cardLink = {},
  projects = {},
  customers = {},
  users = {},
  equipment = {},
  cardId,
  cardTitle,
  circularFieldIds = [],
}) => {
  const fieldMappings = createSourceToTargetMap(mappings);

  const {
    formData: {
      name: templateName,
      sections: newTemplateSections = [],
    } = {},
  } = formTemplate;

  const typeDataMap = {
    projectId: projects,
    customerId: customers,
    userId: users,
    equipmentId: equipment,
  };

  const {
    linkType,
    linkId,
  } = cardLink || {};
  let linkAssigned = false;

  const projectIdSet = new Set();
  const customerIdSet = new Set();
  let formVendorId;
  let formCostcodeId;

  const responseTargetMap = {};
  cardData.forEach((section) => {
    const { fields = [] } = section;
    const templateSection = newTemplateSections.find((sec) => sec.name === section.name);
    fields.forEach((field) => {
      const { fieldId, response, type } = field;
      if (fieldId in fieldMappings) {
        const targetFieldIds = fieldMappings[fieldId];
        targetFieldIds.forEach((targetId) => {
          // Removes columns from table fields that are part of a circular mapping
          if (type === 'table' && circularFieldIds.includes(targetId)) {
            const templateField = templateSection?.fields?.find((f) => f.id === targetId);
            const dataType = templateField?.configProps?.dataType;
            const columnsToRemove = FormHelpers.BLANK_MAPPED_COLUMNS[dataType];
            const newResponse = FormHelpers.removeColumnsFromTable(response, columnsToRemove);
            responseTargetMap[targetId] = newResponse;
          } else {
            responseTargetMap[targetId] = response;
          }
        });
      }
    });
  });
  if ('cardTitle' in fieldMappings) {
    const targetFieldIds = fieldMappings.cardTitle;
    targetFieldIds.forEach((targetId) => {
      responseTargetMap[targetId] = { value: cardTitle };
    });
  }

  const responseSections = [];
  newTemplateSections.forEach((section) => {
    const { fields = [], name: sectionName, id: sectionId } = section;
    const fieldResponses = fields.map((field) => {
      const { id, selectedType, configProps: { title, dataType } = {} } = field;
      const {
        [id]: mappingResponse,
      } = responseTargetMap;

      let linkResponse;
      if (!mappingResponse && cardLink && !linkAssigned && selectedType === 'dropdown' && dataType === linkToDataType[linkType]) {
        linkResponse = {
          values: [{ id: linkId, name: typeDataMap[linkType][linkId]?.name || linkId }],
        };
        linkAssigned = true;
      }

      if (selectedType === 'dropdown' && dataType === 'Cards' && cardId) {
        linkResponse = {
          values: [{ id: cardId, name: cardTitle }],
        };
      }

      const ourResponse = mappingResponse || linkResponse || {};

      if (selectedType === 'dropdown') {
        const { values = [] } = ourResponse;
        if (dataType === 'Customers') {
          values.forEach((val) => customerIdSet.add(val?.id ? val?.id : val));
        } else if (dataType === 'Projects') {
          values.forEach((val) => projectIdSet.add(val?.id ? val?.id : val));
        } else if (dataType === 'Vendors' && !formVendorId) {
          const [{ id: firstId } = {}] = values;
          formVendorId = firstId;
        } else if (dataType === 'Costcodes' && !formCostcodeId) {
          const [{ id: firstId, phaseId } = {}] = values;
          if (firstId) {
            formCostcodeId = `${phaseId ?? 'unphased'}.${firstId}`;
          }
        }
      }

      return {
        type: selectedType,
        title,
        fieldId: id,
        sectionId,
        response: ourResponse,
      };
    });

    responseSections.push({
      id: sectionId,
      name: sectionName,
      fields: fieldResponses,
    });
  });

  const projectIds = Array.from(projectIdSet);
  const customerIds = Array.from(customerIdSet);
  const newData = {
    formData: {
      name: templateName,
      sections: responseSections,
    },
    ids: {
      customerIds: customerIds?.length ? customerIds : undefined,
      projectIds: projectIds?.length ? projectIds : undefined,
      vendorId: formVendorId,
      costcodeId: formCostcodeId,
    },
  };
  return newData;
};

/**
 * Generates/styles status tag
 * @param {string} status
 * @returns antd Tag component
 */
export const getStatus = (status) => {
  const title = toTitleCase(status);
  switch (status) {
    case 'submitted': return (
      <Tag
        color={colors.ONTRACCR_DARK_YELLOW}
        style={{ color: 'black' }}
        className="form-status-tag"
      >
        {title}
      </Tag>
    );
    case 'rejected': return <Tag color="error" className="form-status-tag">{title}</Tag>;
    case 'completed': return <Tag color="darkgreen" className="form-status-tag">{title}</Tag>;
    case 'initiated':
    default:
      return <Tag color="default" className="form-status-tag">{title}</Tag>;
  }
};

/**
 * Gets filters associated with form columns
 * @param {array} data
 * @param {object} idMap
 * @param {string} key
 * @returns {array}
 */
export const getFilters = ({ data = [], idMap, key }) => {
  const ids = [];

  data.forEach((td) => {
    const { [key]: val } = td;
    if (Array.isArray(val)) {
      ids.push(...val);
    } else {
      ids.push(val);
    }
  });

  const idSet = new Set(ids);
  return Array.from(idSet).map((id) => {
    const {
      [id]: { name = 'None' } = {},
    } = idMap;
    return { text: name, value: id };
  });
};

/**
 * Checks if a term is included in a set
 * @param {Set<string>} set
 * @param {string} term
 * @returns {boolean}
 */
export const setIncludesTerm = (set, term) => {
  const arr = Array.from(set);
  return arr.some((val) => includesTerm(val, term));
};

/**
 * Form on click handler for manual/assigned forms
 * @param {Function} dispatch
 * @param {boolean} loading
 * @param {Function} setLoading
 * @param {Function} setAssignedForm
 * @param {Function} setShowDetail
 * @param {Function} setSelectedIsEdit
 * @param {Function} setSelectedIsResubmit
 */
export const handleFormClick = async ({
  record,
  dispatch,
  loading,
  setLoading,
  setAssignedForm,
  setShowDetail,
  setSelectedIsEdit,
  setSelectedIsResubmit,
}) => {
  if (!dispatch || loading) return;
  setLoading(true);
  let prom;
  let newAssigned;
  if (record.templateId) {
    if (record.isDraft) {
      // Manual Draft
      newAssigned = { draftId: record.id };
      prom = dispatch(getTemplateDetails(
        record.templateId,
        record.id,
        {
          circularFieldIds: record.data.circularFieldIds,
        },
      ));
    } else {
      // Assigned form
      newAssigned = { id: record.id };
      prom = dispatch(getFormById(record.id));
    }
  } else {
    // Manual Form
    prom = dispatch(getTemplateDetails(
      record.id,
      undefined,
      {
        circularFieldIds: record?.data?.circularFieldIds,
      },
    ));
  }
  if (await prom) {
    setAssignedForm?.(newAssigned);
    setShowDetail(true);
    setSelectedIsEdit?.(!!record.editable);
    setSelectedIsResubmit?.(record.isResubmit);
  }
  setLoading(false);
};

/**
 * Returns true if the distribution is a valid new distribution, false otherwise
 *    - amount must be > 0
 *    - costcode must have been selected/chosen
 * @param {*} distribution
 * @returns
 */
const isValidNewDistribution = (distribution = {}) => {
  const { isNew, amount, costcodeId } = distribution || {};
  return isNew && amount && costcodeId;
};

/**
 * Reformats/prepares attachment path
 * @param {string} path
 * @returns {string}
 */
const prepareAttachmentPath = (path) => (
  path === 'Files/'
    ? ''
    : removeTrailingSlash(path)
);

/**
 * Prepares/creates correctly formatted invoice payload
 * @param {string} type
 * @param {object} formInputs
 * @param {object} existingInvoice
 * @param {object} vendors
 * @param {array} statuses
 * @param {File | null} file
 * @returns {object} payload
 */
export const prepareInvoicePayload = ({
  type,
  formInputs,
  existingInvoice,
  vendors,
  statuses,
  file,
}) => {
  const {
    formId,
    invoiceNumber,
    invoiceAmount,
    invoiceDescription,
    invoiceIssueDate,
    invoiceDueDate,
    vendorId,
    vendor,
    statusId,
    status,
    filePath,
    projectId,
    costcodeDistributions: submittedCostcodeDistributions = [],
    purchaseAccount,
    purchaseAccountType,
    type: invoiceType,
    qboSyncPrevented,
    divisionId,
  } = formInputs;

  // Convert moment to millis:
  const invoiceDateIssuedMillis = invoiceIssueDate ? moment(invoiceIssueDate).valueOf() : null;
  const invoiceDueDateMillis = invoiceDueDate ? moment(invoiceDueDate).valueOf() : null;

  const payload = {
    invoiceNumber,
    amount: invoiceAmount,
    description: invoiceDescription,
    dateIssued: invoiceDateIssuedMillis,
    dueDate: invoiceDueDateMillis,
    purchaseAccount,
    purchaseAccountType,
    type: invoiceType,
    qboSyncPrevented,
    divisionId,
  };

  if (formId) {
    payload.formId = formId;
  } else {
    payload.projectId = projectId;
  }

  // Add correct properties depending on payload type:
  if (type === INVOICE_DRAWER_ADD_MODE) {
    payload.filePath = prepareAttachmentPath(filePath);
    payload.files = file ? [file] : [];
    payload.costcodeDistributions = submittedCostcodeDistributions
      .filter(({ amount, costcodeId } = {}) => amount && costcodeId)
      .map((distribution) => {
        const {
          amount, costcodeId, phaseId, projectId,
        } = distribution || {};
        return {
          amount,
          costcodeId,
          phaseId: phaseId === 'unphased' ? null : phaseId,
          projectId,
        };
      });
  } else if (type === INVOICE_DRAWER_EDIT_MODE) {
    const {
      files: existingFiles = [],
      costcodeDistributions: existingCostcodeDistributions = [],
    } = existingInvoice || {};

    // File Path Details:
    payload.prvFilePath = existingFiles && existingFiles[0] && typeof existingFiles[0].path === 'string' && existingFiles[0].path.length
      ? removeTrailingSlash(existingFiles[0].path)
      : '';
    payload.newFilePath = prepareAttachmentPath(filePath);
    // Files:
    const newFiles = file ? [file] : [];
    const existingFileSet = new Set(existingFiles.map((file) => file.id));
    const newFileSet = new Set(newFiles.filter((file) => file.id)
      .map((file) => file.id));
    const toDeleteSet = new Set(existingFiles
      .filter((file) => file.id && !newFileSet.has(file.id))
      .map((file) => file.id));
    payload.filesToDelete = existingFiles
      .filter((file) => file.id && !newFileSet.has(file.id));
    payload.filesToUpdate = newFiles
      .filter((file) => file.id && !toDeleteSet.has(file.id));
    payload.filesToAdd = newFiles
      .filter((file) => !file.id || !existingFileSet.has(file.id));

    // Costcode Distributions:
    const existingDistributionMap = getIdMap(existingCostcodeDistributions);
    const newDistributionIdSet = new Set(submittedCostcodeDistributions
      .map((distribution) => distribution.id));
    payload.costcodeDistributionsToUpdate = submittedCostcodeDistributions
      .filter(({
        isNew, id, amount: newAmt, costcodeId,
      }) => {
        const { amount: prvAmt } = existingDistributionMap[id] || {};
        return !isNew && newAmt && costcodeId && prvAmt !== newAmt;
      })
      .map(({ id, amount }) => ({ id, amount }));
    payload.costcodeDistributionsToDelete = Object.keys(existingDistributionMap)
      .filter((distributionId) => !newDistributionIdSet.has(distributionId));
    payload.costcodeDistributionsToAdd = submittedCostcodeDistributions
      .filter((distribution) => isValidNewDistribution(distribution))
      .map((distribution) => {
        const {
          amount, costcodeId, phaseId, projectId,
        } = distribution || {};
        return {
          amount,
          costcodeId,
          phaseId: phaseId === 'unphased' ? null : phaseId,
          projectId,
        };
      });
  }

  // On existing vendor chosen:
  if (vendors[vendorId]) {
    payload.vendorId = vendorId;
  }

  // On new vendor create:
  if (vendor) {
    delete vendor.id;
    payload.vendor = decoratePayloadWithLabels(vendor);
  }

  // If status was chosen:
  if (statusId) {
    if (statuses.map(({ id }) => id).includes(statusId)) {
      // Pre-existing status:
      payload.statusId = statusId;
    } else {
      // Newly defined status:
      const { label } = status;
      payload.status = label;
    }
  }

  return payload;
};

/**
 * gets currency formatted number
 * @param {number} val
 * @returns {string} val in currency format
 */
export const getFormattedCurrency = (val) => (val ? currencyFormatter(val) : '$ 0');

/**
 * Gets a formatted date from millis
 * @param {number} date - millis
 * @returns {string}
 */
export const getFormattedDate = (date) => ((date && typeof date === 'number')
  ? `${DateTime.fromMillis(date).toLocaleString(DateTime.DATE_MED)}` : '-');

/**
 * Get invoice-costcode distribution key
 * @param {string | undefined} phaseId
 * @param {string} costcodeId
 * @returns {string} distribution key
 */
export const getInvoiceCostcodeDistributionKey = (phaseId, costcodeId) => `${phaseId}.${costcodeId}`;

/**
 * Gets the proper invoice drawer title depending on the mode
 * @param {object} invoice
 * @param {string} mode
 * @returns {string} invoice drawer title
 */
export const getInvoiceAddDrawerTitle = (invoice, mode) => {
  const { invoiceNumber } = invoice || {};
  switch (mode) {
    case INVOICE_DRAWER_ADD_MODE:
      return 'Add Invoice';
    case INVOICE_DRAWER_EDIT_MODE:
      return invoiceNumber ? `Edit Invoice: ${invoiceNumber}` : 'Edit Invoice';
    case INVOICE_DRAWER_VIEW_MODE:
      return invoiceNumber ? `Invoice: ${invoiceNumber}` : 'Invoice Details';
  }
};

/**
 * Retrieves a descriptive name representing a costcode selected in the TreeSelect
 * @param {string} projectId
 * @param {string} selectedId
 * @param {object} phaseMap
 * @param {object} costcodeMap
 * @returns {string} descriptive costcode name
 */
export const getDescriptivePhaseCostcodeName = ({
  projectId,
  selectedId = '',
  phaseMap = {},
  costcodeMap = {},
}) => {
  const [phaseId, costcodeId] = selectedId.split('.') || [];
  const {
    [costcodeId]: {
      name: costcodeName,
      code,
    } = {},
  } = costcodeMap;
  const {
    [phaseId]: { name: phaseName = projectId ? 'Unphased' : 'Global' } = {},
  } = phaseMap;
  return `${phaseName} - ${code} ${costcodeName}`;
};

/**
 * Gets the tree option title
 * @param {string} phaseName
 * @param {string} code
 * @param {string} costcodeName
 */
const getTreeOptionTitle = ({
  phaseName,
  code = '',
  costcodeName = '',
}) => `${phaseName} - ${code} ${costcodeName}`;

/**
 * Retrieves phased/unphased costcodes in a tree-structure
 * @param {boolean} responding
 * @param {string} projectId
 * @param {array} phases
 * @param {array} costcodes
 * @param {object} costcodeMap
 * @param {object} projectMap
 * @param {boolean} disableOptions
 * @returns {array} costcode/phases tree
 */
export const getPhaseCostcodeTreeData = ({
  responding,
  projectId,
  phases = [],
  costcodes = [],
  costcodeMap = {},
  projectMap = {},
  disableOptions = false,
}) => {
  const uniqueUnphasedCostcodes = new Set();
  const valueKey = projectId ?? 'global';
  const unphasedCostcodes = {
    value: `${valueKey}-unphased`,
    title: projectId ? 'Unphased' : 'Global',
    selectable: false,
    children: [],
  };
  const phasedCostcodesAdded = {};
  const phasedCostcodes = {};
  // Add Unphased Costcodes:
  costcodes.forEach((costcode) => {
    const {
      id: costcodeId,
      name: costcodeName,
      code,
      active,
      projectId: costcodeProjectId,
    } = costcode || {};
    const isGlobalCostcode = !costcodeProjectId;
    // If project id is not defined get active global costcodes, otherwise get active costcodes that have projectId
    if (!active || uniqueUnphasedCostcodes.has(costcodeId)) return;
    if ((!projectId && isGlobalCostcode) || (projectId && projectId === costcodeProjectId)) {
      unphasedCostcodes.children.push({
        value: `unphased.${costcodeId}`,
        title: getTreeOptionTitle({
          costcodeName,
          code,
          phaseName: projectId ? 'Unphased' : 'Global',
        }),
        isLeaf: true,
        disabled: disableOptions,
      });
      uniqueUnphasedCostcodes.add(costcodeId);
    }
  });
  unphasedCostcodes.children.sort(sortByObjProperty('title'));

  // Add Associated Phased Costcodes, only if there is a project selected:
  if (projectId) {
    phases.forEach((phase) => {
      const {
        id: phaseId, name: phaseName, costcodeId, projectId: phaseProjectId,
      } = phase || {};
      const projectName = projectMap[phaseProjectId] ? projectMap[phaseProjectId].name : '';
      if (!phasedCostcodes[phaseId]) {
        const title = projectName ? `${projectName} - ${phaseName}` : phaseName;
        phasedCostcodes[phaseId] = {
          value: phaseId,
          title,
          selectable: false,
          children: [],
        };
        phasedCostcodesAdded[phaseId] = new Set();
      }
      if (!costcodeId || phasedCostcodesAdded[phaseId].has(costcodeId)) return;
      // if there is no projectId then add, otherwise add only if projectId matches
      if (projectId !== phaseProjectId) return;
      const { name: costcodeName, code } = costcodeMap[costcodeId] || {};
      if (!costcodeName || !code) return;
      phasedCostcodes[phaseId].children.push({
        value: `${phaseId}.${costcodeId}`,
        title: getTreeOptionTitle({
          costcodeName, code, phaseName, responding,
        }),
        isLeaf: true,
        disabled: disableOptions,
      });
      phasedCostcodesAdded[phaseId].add(costcodeId);
    });
  }

  // Remove phases that do not have any costcodes assigned:
  const refinedPhases = Object.values(phasedCostcodes)
    .filter((phase) => phase && phase.children && Array.isArray(phase.children) && phase.children.length > 0)
    .map((phase) => {
      const { children = [] } = phase;
      return {
        ...phase,
        children: children.sort(sortByObjProperty('title')),
      };
    })
    .sort(sortByObjProperty('title'));

  return [unphasedCostcodes].concat(refinedPhases);
};

/**
 * Gets the phase-costcode key
 * @param {string | undefined} phaseId
 * @param {string} costcodeId
 * @returns {string} phase-costcode key
 */
export const getPhaseCostcodeKey = (phaseId, costcodeId) => (phaseId ? `${phaseId}.${costcodeId}` : `unphased.${costcodeId}`);

/**
 * Map form config to response
 * @param {object} templateSchema JSON of form template
 * @param {string} templateSchema.name Name of template
 * @param {array} templateSchema.sections Form template sections
 * @returns {object} Template schema mapped to the form response schema
 */
const convertFormConfigToResponse = (templateSchema) => {
  if (!templateSchema) return {};
  const sections = templateSchema.sections ?? [];
  return {
    name: templateSchema.name,
    sections: sections.map((section) => {
      const safeSection = section ?? {};
      const fields = safeSection.fields ?? [];
      return {
        ...safeSection,
        fields: fields.map((field) => {
          const responseField = {
            fieldId: field.id,
            sectionId: safeSection.id,
            title: field?.configProps?.title,
            type: field.selectedType,
          };
          if (field.selectedType === 'staticAttachments') {
            responseField.response = {
              fileIds: field?.configProps?.files?.map?.((file) => file.id)
                .filter((fileId) => !!fileId) ?? [],
            };
          }
          return responseField;
        }),
      };
    }),
  };
};

/**
 * Converts a completed form into a PDF
 * @param {number} useStandardTemplate
 * @param {object[]} formData
 * @param {object} fileMap
 * @param {object} data
 * @param {number} createdAt
 * @param {array} drawOptions
 * @param {string} title
 * @param {string} submitterName
 * @param {string} employeeId
 * @param {*} employeeSignature
 * @param {object} collected
 * @param {object} settings
 * @param {File} logo
 * @param {number} number
 * @returns {Promise<File>} completed form PDF
 */
export const constructCompletedFormPDF = async (params) => {
  const {
    employeeSignature,
    useStandardTemplate,
    formData,
    fileMap,
    data,
    createdAt,
    drawOptions,
    title,
    submitterName,
    employeeId,
    collected,
    settings,
    logo,
    number,
    shouldReturnPDF = false,
    additionalCollectedHeaderItems = [],
    templateSchema = {},
    projectIdMap = {},
  } = params || {};
  const files = [];
  const staticProms = Object.values(fileMap).map(async (file) => {
    const { id: fileId, name, jsFileObject } = file;
    if (!jsFileObject || !(jsFileObject instanceof File)) return Promise.resolve();
    files.push({
      id: fileId,
      name,
      data: await jsFileObject.arrayBuffer(),
    });
  });

  if (logo) {
    files.push({
      id: 'logo',
      name: logo.name,
      data: await logo.arrayBuffer(),
    });
  }

  await Promise.all(staticProms);

  // Need to load files from config
  // for static attachment fields
  const fullData = data ?? convertFormConfigToResponse(templateSchema);

  const completedForm = {
    ...fullData,
    collected: {
      ...collected,
      number: number ? number.toString() : number,
      employeeSignature: {
        collect: collected.employeeSignature,
      },
    },
  };

  const rawPDFData = await formToPDF({
    sections: formData,
    useStandardTemplate,
    name: title,
  }, {
    employeeName: submitterName,
    employeeId,
    timestamp: createdAt,
    completedForm,
    files,
    drawOptions,
    signatureData: employeeSignature ? await employeeSignature.arrayBuffer() : null,
    settings,
    projectIdMap,
    shouldReturnPDF,
    additionalCollectedHeaderItems,
    fileMap,
  });

  return shouldReturnPDF
    ? rawPDFData
    : new File([rawPDFData], `${title}.pdf`, { type: 'application/pdf' });
};

/**
 * Extracts form fields from form sections and constructs options that can be used for antd Select
 * @param {Set} types - input field types to include
 * @param {array} sections - form sections
 * @returns {array} antd select options
 */
export const extractFormFieldOptions = ({
  types = new Set(),
  sections = [],
}) => {
  const selectOptions = [{ value: null, label: 'None' }];
  sections.forEach(({ name: sectionName, fields = [] }) => {
    fields.forEach(({ id: fieldId, selectedType = '', configProps = {} }) => {
      const { title: fieldName } = configProps;
      if (types.has(selectedType)) {
        selectOptions.push({
          value: fieldId,
          label: `Section: ${sectionName} --- Field: ${fieldName}`,
        });
      }
    });
  });
  return selectOptions;
};

export const fieldTypesWithAPI = new Set(['table', 'dropdown', 'multiSig']);

export const removeAPIFieldsFromSections = (sections = []) => {
  const newSections = [];
  sections.forEach(({ fields = [], ...rest }) => {
    const newFields = [];
    fields.forEach((field) => {
      const {
        selectedType,
        configProps = {},
      } = field;

      const {
        dataType,
        fieldTrigger,
      } = configProps;

      if (fieldTypesWithAPI.has(selectedType) && dataType !== 'Custom') return;

      // remove field trigger config props
      const newField = { ...field };
      if (fieldTrigger) {
        const newConfigProps = { ...configProps };
        delete newConfigProps.fieldTrigger;
        delete newConfigProps.fieldTriggerProps;
        newField.configProps = newConfigProps;
      }

      newFields.push(newField);
    });
    newSections.push({ ...rest, fields: newFields });
  });
  return newSections;
};

export const preparePDFFieldsFromSections = ({ sections = [], isExternalForm }) => {
  const newSections = [];
  sections.forEach(({ fields = [], ...rest }) => {
    const newFields = [];
    fields.forEach((field) => {
      const {
        selectedType,
        configProps = {},
      } = field;

      const {
        dataType,
        fieldTrigger,
      } = configProps;

      if (isExternalForm && fieldTypesWithAPI.has(selectedType) && dataType !== 'Custom') return;

      const newField = { ...field };
      const newConfigProps = { ...configProps };

      switch (selectedType) {
        case 'table': {
          const { columns = [] } = configProps;
          const newColumns = columns.filter(({ isHideInPDF }) => !isHideInPDF);
          newConfigProps.columns = newColumns;
          break;
        }
        default:
          break;
      }

      // remove field trigger config props
      if (fieldTrigger) {
        delete newConfigProps.fieldTrigger;
        delete newConfigProps.fieldTriggerProps;
        newField.configProps = newConfigProps;
      }

      newFields.push(newField);
    });
    newSections.push({ ...rest, fields: newFields });
  });
  return newSections;
};

export const getPDF = async ({
  form,
  drawOptions,
  fileMap,
  settings,
  options = {},
}) => {
  const files = await Promise.all(
    Object.values(fileMap).map(async ({ id, name, jsFileObject }) => {
      const data = await jsFileObject.arrayBuffer();
      return {
        id,
        name,
        data,
      };
    }),
  );
  const pdfData = await formToPDF(form, {
    drawOptions, files, settings, fileMap, ...options,
  });
  const file = new File([pdfData], 'file.pdf', { type: 'application/pdf' });
  return file;
};

export const getStaticAttachmentFiles = ({
  sections,
  files = [],
}) => {
  if (!sections) return { idMap: {}, files };
  const fullFiles = [...files];
  const idMap = {};
  sections.forEach((section) => {
    const { fields = [] } = section ?? {};
    fields?.forEach?.((field) => {
      if (field.selectedType !== 'staticAttachments') return;
      const {
        configProps: {
          files: fieldFiles = [],
        } = {},
      } = field;
      fieldFiles.forEach((file) => {
        if (!file.existing && !file.jsFileObject?.existing) {
          idMap[file.uid] = fullFiles.length;
          fullFiles.push(file);
        }
      });
    });
  });
  return { idMap, files: fullFiles };
};

/**
   * Filters non text type custom fields and formats for use
   * with attributes
   * @param {array} fields
   * @returns {array}
   */
const getTextCustomFields = (fields) => (
  fields.filter((field) => field.type === 'text').map((field) => ({
    attr: field.v1ID,
    name: field.title,
    type: 'text',
  }))
);

/**
   * Retrieves the attribute map with custom fields for:
   * Costcode custom fields
   * User custom fields
   * @param {object} attributeMap - base attribute map
   * @param {object} costcodeCustomFields
   * @param {object} userCustomFields
   * @returns {object}
   */
export const getAttributeMapWithCustomFields = ({
  attributeMap,
  costcodeCustomFields,
  userCustomFields,
  bucketTemplates = [],
}) => {
  const { fields: costcodeFields = [] } = costcodeCustomFields;
  const { fields: userFields = [] } = userCustomFields;

  const textCostcodeFields = getTextCustomFields(costcodeFields);
  const textUserFields = getTextCustomFields(userFields);

  const newAttributeMap = { ...attributeMap };
  const {
    Costcodes: {
      fields: ccMapFields = [],
    } = {},
    User: {
      fields: userMapFields = [],
    },
  } = newAttributeMap;

  const newCostcodeMapFields = ccMapFields.concat(textCostcodeFields);
  const newUserMapFields = userMapFields.concat(textUserFields);
  newAttributeMap.Costcodes.fields = newCostcodeMapFields;
  newAttributeMap.User.fields = newUserMapFields;

  bucketTemplates?.forEach(({ id, customFields: { fields = [] } = {} }) => {
    const textFields = getTextCustomFields(fields);
    const newTemplateMapFields = (newAttributeMap[id]?.fields || []).concat(textFields);
    newAttributeMap[id] = {
      fields: newTemplateMapFields,
    };
  });

  return newAttributeMap;
};

/**
 * Checks whether the user, their role, or one of their teams has permission to view a section.
 * User-specific permission settings are prioritized over others
 * @param {object} user - The user being checked (needs id, position and teams)
 * @param {object} positionNameMap - map of positionName to position details
 * @param {object} permissions - map of permission id to permission details
 * @param {string} type - permission being checked (canView or canEdit)
 * @returns {boolean} - if the user has permission
 */
const hasSectionPermissions = ({
  user,
  positionNameMap,
  permissions,
  type,
}) => {
  const userPositionId = positionNameMap[user.position]?.id;
  const userTeams = user.teams?.map((team) => team.id);

  // Empty or not set acts as full permissions
  if (!permissions || !Object.values(permissions)?.length) return true;

  // Check User permission
  if (permissions[user.id]) return permissions[user.id]?.[type];
  // Check Role permission
  if (permissions[userPositionId]?.[type]) return true;
  // Check Team permissions
  return userTeams?.some((teamId) => permissions[teamId]?.[type]);
};

export const getSectionPermissionMap = ({
  sections,
  users,
  positionNames,
  userId,
}) => {
  const positionNameMap = getIdMap(positionNames, 'name');
  const userMap = getIdMap(users);
  const {
    [userId]: user = {},
  } = userMap;
  const sectionPermissionMap = {};
  sections.forEach(({ id, settings }) => {
    sectionPermissionMap[id] = {
      canView: hasSectionPermissions({
        user,
        positionNameMap,
        permissions: settings?.permissions,
        type: 'canView',
      }),
      canEdit: hasSectionPermissions({
        user,
        positionNameMap,
        permissions: settings?.permissions,
        type: 'canEdit',
      }),
    };
  });
  return sectionPermissionMap;
};

export const textBasedFieldTypes = new Set([
  'text',
  'yes-no',
  'calculation',
  'dropdown',
  'dateRange',
  'dateTime',
  'attribute',
]);

export const simpleTextBasedFieldTypes = new Set([
  'text',
  'calculation',
  'attribute',
]);

export const getLinkId = ({
  responses = {},
  defaultValue,
  linkField,
}) => {
  if (!linkField) return defaultValue;
  const {
    [linkField]: {
      values = [],
      value,
    } = {},
  } = responses;
  if (linkField === 'project' || linkField === 'costcode') {
    /*
      Timecard custom field attribute can link
      to timecard project/costcode which only returns a single value

      https://ontraccr.sentry.io/issues/5685831755/?project=5891694
    */
    return value;
  }
  const [{ id: linkId } = {}] = values ?? [];
  return linkId;
};

export const getFormOptions = ({
  formTemplates = {},
  selectedDivisions = new Set(),
  projectIdMap = {},
}) => {
  const formList = Object.values(formTemplates);
  formList.sort(sortByString('name'));
  return formList
    .filter((formTemplate) => formTemplate.active && selectedDivisions.has(formTemplate.divisionId))
    .map((formTemplate) => {
      const {
        projectId,
      } = formTemplate;
      const {
        [projectId]: {
          name: projectName,
        } = {},
      } = projectIdMap;
      const label = projectName ? `${formTemplate.name} - ${projectName}` : formTemplate.name;
      return { value: formTemplate.id, label };
    });
};

export const getCustomFieldTableColumns = (sections = []) => {
  const columns = [];

  sections?.forEach(({ fields = [] }) => {
    fields?.forEach((field) => {
      const {
        type,
        configProps: {
          title,
        } = {},
      } = field;

      if (textBasedFieldTypes.has(type)) {
        columns.push({
          title,
          dataIndex: field.id,
          key: field.id,
        });
      }
    });
  });

  return columns;
};

export const getFieldMap = (sections = [], idKey = 'fieldId') => {
  const fieldMap = {};
  sections.forEach(({ fields = [] }) => {
    fields.forEach((field) => {
      fieldMap[field[idKey]] = field;
    });
  });
  return fieldMap;
};

export const getSnapshotFormData = ({
  snapshot,
  secondarySnapshot = null,
  setSelectedFile,
  setSelectedFileDetails,
}) => {
  const {
    sections = [],
    templateSchema: {
      sections: templateSections = [],
    } = {},
    fileMap = {},
  } = snapshot;
  const templateMap = {};
  templateSections.forEach(({ id: sectionId, fields = [] }) => {
    if (!(sectionId in templateMap)) {
      templateMap[sectionId] = {};
    }
    const subMap = templateMap[sectionId];
    fields.forEach((field) => {
      const { id: fieldId } = field;
      subMap[fieldId] = field;
    });
  });

  const secondarySnapshotFieldMap = getFieldMap(secondarySnapshot?.sections);

  return parseCompletedForm({
    sections,
    fileMap,
    templateMap,
    secondaryFormFieldMap: secondarySnapshot ? secondarySnapshotFieldMap : null,
    setSelectedFile,
    setSelectedFileDetails,
  });
};

export const getRelevantSnapshotData = ({
  formData,
  range,
  snapshots,
  setSelectedFile,
  setSelectedFileDetails,
}) => {
  if (range?.length !== 2) return formData;

  const [
    start,
    end,
  ] = range;

  let firstSnapshot;
  let secondSnapshot;

  // snapshots are ordered from most recent to oldest
  // we want to find the snapshots that are closest to the start and end dates in range
  for (let i = 0; i < snapshots.length; i += 1) {
    const snapshot = snapshots[i];
    const snapshotDate = moment.unix(snapshot.timestamp / 1000);

    // Want to find the second snapshot that is before the end date
    // This is the final change to the form
    if (
      snapshotDate.isSameOrBefore(end.endOf('day'))
      && snapshotDate.isSameOrAfter(start.startOf('day'))
      && !secondSnapshot
    ) {
      secondSnapshot = snapshot;
    }

    // Want to find the first snapshot that is before the start date
    // This is the original form before any changes in date range
    if (snapshotDate.isSameOrBefore(start)) {
      firstSnapshot = snapshot;
      break;
    }
  }

  // If there is no snapshot in range, we don't want to show any form data
  if (!secondSnapshot) return null;
  return getSnapshotFormData({
    snapshot: secondSnapshot,
    secondarySnapshot: firstSnapshot,
    setSelectedFile,
    setSelectedFileDetails,
  });
};

/**
 * Parses the 'filtered' selected values into the actual form response for certain fields.
 * Used for mapping filtered snapshot data into a form
 */
export const parseSelectedIntoResponse = ({
  type,
  response,
}) => {
  const newResponse = {
    ...response,
  };

  switch (type) {
    case 'yes-no': {
      if (newResponse.selected === null) {
        newResponse.value = undefined;
      }
      return newResponse;
    }
    case 'dropdown': {
      newResponse.values = newResponse?.selected ?? [];
      return newResponse;
    }
    case 'attachment': {
      const { files } = newResponse;
      const filteredFileIds = files.map((file) => file.id);
      newResponse.fileIds = filteredFileIds;
      return newResponse;
    }
    default: {
      return newResponse;
    }
  }
};

export const parseFilteredForm = (formData) => (
  formData.map((section) => ({
    ...section,
    fields: section.fields.map((field) => ({
      ...field,
      response: parseSelectedIntoResponse({
        type: field.type,
        response: field.response,
      }),
    })),
  }))
);

const variableRegex = /(\[(?:\w| )+?\])/g;
export const formatVariableText = ({
  text,
  variableMap,
}) => {
  if (!text) return '';
  const splitArr = text.split(variableRegex);
  return splitArr.reduce((acc, word) => {
    const match = word.match(variableRegex);
    if (match !== null) {
      const { 0: fullMatch } = match;
      const tagText = fullMatch.slice(1, fullMatch.length - 1);
      if (!(tagText in variableMap)) return acc + word;
      return acc + variableMap[tagText];
    }
    return acc + word;
  }, '');
};

export const formatFormDropdownList = ({
  formList = [],
  projectIdMap = {},
  vendorIdMap = {},
  projectId,
  shouldFilterByProjects = false,
}) => {
  let filteredList = formList;

  if (shouldFilterByProjects) {
    filteredList = formList.filter((form) => (
      !projectId || form.projects?.includes(projectId)
    ));
  }

  return filteredList
    .map((form) => ({
      id: form.id,
      name: FormHelpers.formatFormName(form),
      subNames: [
        form.lastUpdated ? DateTime.fromMillis(form.lastUpdated).toLocaleString(DateTime.DATETIME_MED) : '',
        form.status ?? '',
        form.projects ? form.projects.map((formProjectId) => projectIdMap[formProjectId]?.name).join(', ') : '',
        form.vendors ? form.vendors.map((formVendorId) => vendorIdMap[formVendorId]?.name).join(', ') : '',
      ],
    }));
};

export const clearConditionalResponses = ({
  responses,
  sections,
}) => {
  const newResponses = { ...responses };

  const fieldsToDelete = [];

  sections.forEach(({ fields = [] }) => {
    fields.forEach(({ id: fieldId, configProps }) => {
      const { hasConditionalRendering, conditionalRenderingFormula } = configProps;

      if (
        hasConditionalRendering
        && FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
        && !FormHelpers.executeConditionalRenderingFormula({
          formula: conditionalRenderingFormula,
          responses,
        })
      ) {
        if (Object.keys(responses).includes(fieldId)) {
          fieldsToDelete.push(fieldId);
        }
      }
    });
  });

  if (fieldsToDelete.length > 0) {
    fieldsToDelete.forEach((fieldId) => {
      delete newResponses[fieldId];
    });
  }

  return newResponses;
};
