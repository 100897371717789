import React from 'react';

export default ({ employeeSignature }) => {
  if (!employeeSignature) return null;
  return (
    <div className='form-signature-container'>
      <div className='form-signature-header'>
        Signature
      </div>
      <div className='form-signature-image-container'>
        <img
          className='drawer-photo form-signature-image'
          src={URL.createObjectURL(employeeSignature)}
          alt='Signature'
        />
      </div>
    </div>
  );
}