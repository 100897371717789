import {
  GET_ECLIPSE_DIVISIONS,
  ECLIPSE_IMPORT_DIVISION,
  ECLIPSE_LINK_DIVISION,
  ECLIPSE_SET_DIVISION,
  CREATE_ECLIPSE_WORKFLOW,
  UPDATE_ECLIPSE_WORKFLOW,
  DELETE_ECLIPSE_WORKFLOW,
  GET_ECLIPSE_WORKFLOWS,
} from '../../../../state/actionTypes';

import { getIdMap } from '../../../../helpers/helpers';

const initialState = {
  divisions: [],
  selectedDivision: null,
  links: [],
  workflows: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_ECLIPSE_DIVISIONS: {
      const {
        payload: { data: divisions = [], links = [] },
      } = action;
      return {
        ...state,
        divisions,
        links,
      };
    }
    case ECLIPSE_LINK_DIVISION:
    case ECLIPSE_IMPORT_DIVISION: {
      const {
        payload: { divisionId, eclipseId },
      } = action;
      let newLinks = [...state.links];
      if (action.type === ECLIPSE_LINK_DIVISION) {
        newLinks = newLinks.filter((link) => link.eclipseId !== eclipseId);
      }
      newLinks.push({ eclipseId, divisionId });
      return {
        ...state,
        links: newLinks,
        selectedDivision: divisionId,
      };
    }
    case ECLIPSE_SET_DIVISION: {
      const {
        payload: { divisionId } = {},
      } = action;
      return {
        ...state,
        selectedDivision: divisionId,
      };
    }
    case GET_ECLIPSE_WORKFLOWS: {
      const {
        payload: { workflows = [] } = {},
      } = action;
      return {
        ...state,
        workflows: getIdMap(workflows),
      };
    }
    case CREATE_ECLIPSE_WORKFLOW: {
      const {
        payload: { workflow } = {},
      } = action;
      const { workflows: stateWorkflows = {} } = state;
      const newWorkflows = { ...stateWorkflows };
      newWorkflows[workflow.id] = workflow;
      return {
        ...state,
        workflows: newWorkflows,
      };
    }
    case UPDATE_ECLIPSE_WORKFLOW: {
      const {
        payload: { id, newData = {} } = {},
      } = action;
      const { workflows: stateWorkflows = {} } = state;
      const newWorkflows = { ...stateWorkflows };
      newWorkflows[id] = {
        ...stateWorkflows[id] ?? {},
        ...newData,
      };

      return {
        ...state,
        workflows: newWorkflows,
      };
    }
    case DELETE_ECLIPSE_WORKFLOW: {
      const {
        payload: { id } = {},
      } = action;
      const { workflows: stateWorkflows = {} } = state;
      const newWorkflows = { ...stateWorkflows };
      delete newWorkflows[id];
      return {
        ...state,
        workflows: newWorkflows,
      };
    }
    default:
      return state;
  }
};
