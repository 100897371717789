import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Checkbox, Switch,
} from 'antd';

import {
  FontSizeOutlined,
  BorderOutlined,
  PictureOutlined,
  TableOutlined,
  FileAddOutlined,
  DeleteOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import PDFDesignerToolbarButton from './PDFDesignerToolbarButton';

import CustomConfirmModal from '../../modals/CustomConfirmModal';
import HoverHelp from '../../HoverHelp';

const showModal = (onDelete) => () => {
  CustomConfirmModal({
    title: 'Delete this page?',
    content: 'Deleting a page will delete all items on the page.',
    okText: 'Delete',
    cancelText: 'Cancel',
    onOk: onDelete,
  });
};

const iconStyle = { marginLeft: 0 };

function PDFDesignerToolbar({
  onItemAdd,
  numberOfPages,
  onPageDeleted,
  onTopOfPageChecked,
  startAtTop,
  useStandardTemplate,
  onUseStandardTemplateChange,
}) {
  const onClick = useCallback((type) => () => onItemAdd(type), [onItemAdd]);
  const onCheckChanged = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    onTopOfPageChecked(checked);
  }, [onTopOfPageChecked]);

  const onSwitch = useCallback((e) => {
    onUseStandardTemplateChange(e);
  }, [onUseStandardTemplateChange]);

  return (
    <div className="pdf-designer-toolbar-container">
      <Row
        justify="space-between"
        align="middle"
        style={{
          height: '100%', width: '100%', paddingLeft: 20, paddingRight: 0,
        }}
        gutter={20}
      >
        <Col style={{ padding: 0 }}>
          <Row justify="start" align="middle">
            <Col>
              <Row justify="start" align="middle" gutter={4}>
                <Col>
                  <Switch
                    checked={useStandardTemplate}
                    onChange={onSwitch}
                  />
                </Col>
                <Col>
                  {useStandardTemplate ? 'Standard' : 'Custom'}
                </Col>
              </Row>
            </Col>
            {!useStandardTemplate
            && (
            <Col>
              <Row justify="start" align="middle">
                <PDFDesignerToolbarButton
                  message="Add Text"
                  onClick={onClick('text')}
                >
                  <FontSizeOutlined style={iconStyle} />
                </PDFDesignerToolbarButton>

                <PDFDesignerToolbarButton
                  message="Add Rectangle"
                  onClick={onClick('rectangle')}
                >
                  <BorderOutlined style={iconStyle} />
                </PDFDesignerToolbarButton>

                <PDFDesignerToolbarButton
                  message="Add Image"
                  onClick={onClick('image')}
                >
                  <PictureOutlined style={iconStyle} />
                </PDFDesignerToolbarButton>

                <PDFDesignerToolbarButton
                  message="Add Table"
                  onClick={onClick('table')}
                >
                  <TableOutlined style={iconStyle} />
                </PDFDesignerToolbarButton>

                <PDFDesignerToolbarButton
                  message="Add Blank Page"
                  onClick={onClick('page')}
                >
                  <FileAddOutlined style={iconStyle} />
                </PDFDesignerToolbarButton>

                <PDFDesignerToolbarButton
                  message="Copy Page"
                  onClick={onClick('copyPage')}
                >
                  <CopyOutlined style={iconStyle} />
                </PDFDesignerToolbarButton>
              </Row>
            </Col>
            )}
          </Row>
        </Col>
        {!useStandardTemplate && (
          <Col style={{ padding: 0 }}>
            <Row justify="end" align="middle">
              <Checkbox checked={startAtTop} onChange={onCheckChanged}>
                Start at top of page?
              </Checkbox>
              <HoverHelp
                content={(
                  <div style={{ maxWidth: 250 }}>
                    Check this box if you want the contents of this page
                    to always start at the top of the page
                  </div>
              )}
                placement="bottomRight"
              />
              {numberOfPages > 1
              && (
              <PDFDesignerToolbarButton
                message="Delete Page"
                onClick={showModal(onPageDeleted)}
                placement="bottomLeft"
                style={{ marginLeft: 20 }}
              >
                <DeleteOutlined style={{ ...iconStyle, color: 'red' }} />
              </PDFDesignerToolbarButton>
              )}
            </Row>
          </Col>
        )}
      </Row>
    </div>
  );
}

PDFDesignerToolbar.propTypes = {
  onItemAdd: PropTypes.func.isRequired,
  numberOfPages: PropTypes.number.isRequired,
  onPageDeleted: PropTypes.func.isRequired,
  onTopOfPageChecked: PropTypes.func.isRequired,
  startAtTop: PropTypes.bool.isRequired,
  useStandardTemplate: PropTypes.bool.isRequired,
  onUseStandardTemplateChange: PropTypes.func.isRequired,
};

export default PDFDesignerToolbar;
