import React from 'react';
import { useSelector } from 'react-redux'

import { HOUR_HEIGHT } from './schedule.constants';

const getText = (id) => {
  if(id === 0) return '12 AM';
  if(id === 12) return 'Noon';
  if(id < 12) return `${id} AM`;
  return `${id - 12} PM`;
}
export default ({ id, hideGrid }) => {
  const scale = useSelector(state => state.schedule.scale);
  return (
    <div
      id={id}
      className={hideGrid ? 'schedule-hour-hidden' : 'schedule-hour'}
      style={{
        height: HOUR_HEIGHT * scale,
      }}
    >
      <div className='schedule-divider'/>
      <div className='schedule-hour-text'>{getText(id)}</div>
    </div>
  );
}