import React, {
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Row,
  Select,
  DatePicker,
  Divider,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import SimpleTextInputModal from '../../common/modals/SimpleTextInputModal';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import { getFilteredUsers } from '../../schedule/GanttSchedule/ganttScheduleHelpers';
import { getFormOptions } from '../formHelpers';
import sortByString, { getIdMap } from '../../helpers/helpers';
import { filterViewFields, defaultFormValues, getProjectOptions } from './formFilters.helpers';

import {
  createUserFormFilterView,
  deleteUserFormFilterView,
  updateFormFilters,
  updateUserFormFilterView,
} from '../state/forms.actions';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import useToggle from '../../common/hooks/useToggle';
import useFilteredBoardList from '../../common/hooks/useFilteredBoardList';

const { RangePicker } = DatePicker;

function FormCustomFilterTab({
  onClose,
  setActiveTab,
  filterView,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const { isToggled: isDatePickerOpen, toggle: toggleDatePicker } = useToggle();
  const { isToggled: isModalOpen, toggle: toggleModal } = useToggle();

  const users = useSelector((state) => state.users.users);
  const formTemplates = useSelector((state) => state.forms.templates);
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);
  const formStatuses = useSelector((state) => state.forms.statuses);
  const activeFilters = useSelector((state) => state.forms.formFilters);
  const { selectedDivisions, divisions } = useSelector((state) => state.settings);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const currentFilters = useMemo(() => {
    if (!filterView || activeFilters?.filterView === filterView.id) return activeFilters;
    return filterView;
  }, [filterView, activeFilters]);

  const userOptions = useMemo(() => getFilteredUsers({
    users,
    divisions,
    selectedDivisions,
  }), [users, divisions, selectedDivisions]);

  const formOptions = useMemo(() => getFormOptions({
    formTemplates,
    selectedDivisions,
    projectIdMap,
  }), [formTemplates, selectedDivisions, projectIdMap]);

  const projectOptions = useMemo(() => (
    getProjectOptions(projects, selectedDivisions)
  ), [projects, selectedDivisions]);

  const customerOptions = useMemo(() => (
    Object.values(customers)
      .filter((customer) => customer.active)
      .map((customer) => ({ label: customer.name, value: customer.id }))
  ), [customers]);

  const boardOptions = useFilteredBoardList({ asOptions: true });

  const statusOptions = useMemo(() => {
    const statusList = Object.values(formStatuses);
    statusList.sort(sortByString(('status')));
    return statusList.map((formStatus) => ({ label: formStatus.status, value: formStatus.id }));
  }, [formStatuses]);

  const onApply = useCallback(() => {
    const values = form.getFieldsValue();
    if (filterView) {
      values.filterView = filterView.id;
    } else {
      values.filterView = null;
      values.filterViewName = null;
    }

    dispatch(updateFormFilters(values));
    onClose();
  }, [filterView]);

  const onSave = useCallback(async () => {
    try {
      const values = form.getFieldsValue();
      if (filterView) {
        await form.validateFields();
        const payload = {
          name: values.filterViewName,
          filters: filterViewFields.map(({ field, defaultValue }) => ({
            filterProperty: field,
            filterValue: JSON.stringify(values[field] || defaultValue),
            filterViewId: filterView.id,
          })),
        };
        dispatch(updateUserFormFilterView(filterView.id, payload));
        onApply();
      } else {
        toggleModal();
      }
    } catch (err) {
      // fail silently
    }
  }, [form, filterView, onApply]);

  const onDelete = useCallback(() => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Filter View',
      content: <p>Are you sure you wish to delete this filter view?</p>,
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        resolve(await dispatch(deleteUserFormFilterView(filterView.id)));

        if (activeFilters.filterView === filterView.id) {
          dispatch(updateFormFilters({ filterView: null, filterViewName: null }));
        } else {
          setActiveTab(activeFilters?.filterView || 'custom');
        }

        toggleModal();
      },
      onCancel() {
        resolve();
      },
    });
  }), [filterView, activeFilters]);

  const onCreate = useCallback(async (filterViewName) => {
    const values = form.getFieldsValue();
    const payload = {
      name: filterViewName,
      filters: filterViewFields.map(({ field, defaultValue }) => ({
        filterProperty: field,
        filterValue: JSON.stringify(values[field] || defaultValue),
      })),
    };

    const { id } = await dispatch(createUserFormFilterView(payload));
    setActiveTab(id);
    dispatch(updateFormFilters({
      ...values,
      filterView: id,
      filterViewName,
    }));
    onClose();
  }, [form, onClose]);

  const resetFields = () => {
    form.setFieldsValue(defaultFormValues);
  };

  const formTitle = useMemo(() => {
    if (filterView) return `Edit ${filterView.filterViewName} View`;
    return 'Custom Filter';
  }, [filterView]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        ...defaultFormValues,
        filterViewName: currentFilters?.filterViewName ?? '',
        users: currentFilters?.users ?? [],
        formTemplates: currentFilters?.formTemplates ?? [],
        formNumber: currentFilters?.formNumber ?? null,
        customers: currentFilters?.customers ?? [],
        projects: currentFilters?.projects ?? [],
        boards: currentFilters?.boards ?? [],
        status: currentFilters?.status ?? [],
        dateRange: currentFilters?.dateRange?.map((date) => moment(date)) ?? null,
      });
    }
  }, [form, currentFilters]);

  return (
    <>
      <div>
        <Row>
          <h3>{formTitle}</h3>
          <OnTraccrButton
            onClick={resetFields}
            style={{ marginLeft: 'auto' }}
            title="Reset"
          />
        </Row>
        <Form
          form={form}
          layout="vertical"
        >
          {filterView && (
            <>
              <Form.Item
                name="filterViewName"
                label="Filter View Name"
                rules={[{ required: true, message: 'Filter View Name is required' }]}
              >
                <OnTraccrTextInput
                  style={{ width: '100%' }}
                  placeholder="Name"
                />
              </Form.Item>
              <Divider />
            </>
          )}
          <Form.Item
            name="dateRange"
            label="Date Range"
            style={{ marginBottom: 16 }}
          >
            <RangePicker
              format="MMM Do YY"
              onOpenChange={toggleDatePicker}
              open={isDatePickerOpen}
            />
          </Form.Item>
          <Form.Item
            name="users"
            label="Users"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={userOptions.map((u) => ({ value: u.id, label: u.name }))}
              placeholder="Users"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="formTemplates"
            label="Form Templates"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Form Templates"
              optionFilterProp="label"
              options={formOptions}
            />
          </Form.Item>
          <Form.Item
            name="formNumber"
            label="Form Number"
            style={{ marginBottom: 16 }}
          >
            <OnTraccrNumberInput
              style={{ width: '100%' }}
              placeholder="Number"
            />
          </Form.Item>
          <Form.Item
            name="customers"
            label="Customers"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Customers"
              optionFilterProp="label"
              options={customerOptions}
            />
          </Form.Item>
          <Form.Item
            name="projects"
            label="Projects"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Projects"
              optionFilterProp="label"
              options={projectOptions}
            />
          </Form.Item>
          <Form.Item
            name="boards"
            label="Boards"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Boards"
              optionFilterProp="label"
              options={boardOptions}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              placeholder="Status"
              optionFilterProp="label"
              options={statusOptions}
            />
          </Form.Item>
        </Form>
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <div style={{ marginRight: 'auto' }}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onClose}
            />
            {filterView && (
              <OnTraccrButton
                title="Delete"
                type="back"
                onClick={onDelete}
                style={{ marginLeft: 8 }}
              />
            )}
          </div>
          <OnTraccrButton
            title={`${!filterView ? 'Create' : 'Save'} and Apply`}
            onClick={onSave}
            style={{ marginRight: 8 }}
          />
          <OnTraccrButton
            title="Apply"
            onClick={onApply}
          />
        </Row>
      </div>
      <SimpleTextInputModal
        title="Add Filter View"
        visible={isModalOpen}
        placeholder="Enter Filter View Name"
        onClose={toggleModal}
        onSave={onCreate}
      />
    </>
  );
}

FormCustomFilterTab.propTypes = {
  onClose: PropTypes.func.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  filterView: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

FormCustomFilterTab.defaultProps = {
  filterView: undefined,
};

export default FormCustomFilterTab;
