import React from 'react';
import colors from '../constants/Colors';
import { Row, Col, Tag } from 'antd';

export const DATE_PRESETS_TYPES = {
  'This Week': 'This Week',
  'This Month': 'This Month',
  'This Quarter': 'This Quarter',
  'This Year': 'This Year',
  'Last 7 Days': 'Last 7 Days',
  'Last 30 Days': 'Last 30 Days',
  'All Time': 'All Time',
};

export const DATE_PRESETS = [
    DATE_PRESETS_TYPES['This Week'],
    DATE_PRESETS_TYPES['This Month'],
    DATE_PRESETS_TYPES['This Quarter'],
    DATE_PRESETS_TYPES['This Year'],
    DATE_PRESETS_TYPES['Last 7 Days'],
    DATE_PRESETS_TYPES['Last 30 Days'],
    DATE_PRESETS_TYPES['All Time'],
  ];

export default ({
  datePreset,
  handlePreset,
}) => {
    return (
        <Row>
        {
          DATE_PRESETS.map((preset) => (
            <Col key={preset}>
              <Tag
                color={datePreset === preset ? colors.ONTRACCR_RED : null}
                onClick={handlePreset ? () => handlePreset(preset) : undefined}
                className='analytics-date-preset-tag'
              >
                {preset}
              </Tag>
            </Col>
          ))
        }
      </Row>
    )
  }

