import {
  React,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  List,
  Row,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import Colors from '../../constants/Colors';
import DisplayText from '../../common/text/DisplayText';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomTableListItem from '../Forms/CustomTableListItem';
import CompanySettingsCard from '../CompanySettingsCard';
import BoardTemplateDrawer from '../../boards/BoardTemplateDrawer';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import WarningHeader from '../../common/text/WarningHeader';
import { deleteBoardTemplate } from '../../boards/state/boards.actions';

const deleteModal = (onOk) => CustomConfirmModal({
  title: (
    <WarningHeader
      justify="center"
      title="Are you sure you want to delete this board template?"
    />
  ),
  onOk,
});

function Boards() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();
  const templates = useSelector((state) => state.boards.boardTemplates);
  const dispatch = useDispatch();

  const onAddClicked = useCallback(() => {
    setShowDrawer(true);
  }, []);

  const clearValues = useCallback(() => {
    setSelectedTemplateId();
    setShowDrawer(false);
  }, []);

  const onSubmit = useCallback((template) => {
    if (template) {
      clearValues();
    }
  }, [
    clearValues,
  ]);

  const onDelete = useCallback(
    (id) => deleteModal(() => dispatch(deleteBoardTemplate(id))),
    [],
  );

  const onEdit = useCallback((value) => {
    setSelectedTemplateId(value);
    setShowDrawer(true);
  }, [setSelectedTemplateId, setShowDrawer]);

  const renderItem = useCallback((item) => (
    <CustomTableListItem
      onDelete={onDelete}
      onEdit={onEdit}
      item={{
        id: item.id,
        name: item.name,
        description: item.description,
      }}
    />
  ), [onDelete, onEdit]);

  return (
    <>
      <CompanySettingsCard title="Boards">
        <Row style={{ margin: 10 }} align="bottom">
          <Col span={18}>
            <DisplayText
              title="Board Templates"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
            <DisplayText
              title={(
                <div style={{ width: '100%' }}>
                  Configure board templates
                </div>
              )}
              style={{
                fontFamily: 'roboto-regular',
                marginBottom: 0,
                color: Colors.ONTRACCR_OPACITY_GRAY,
              }}
            />
          </Col>
          <Col span={6}>
            <OnTraccrButton
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
            />
          </Col>
        </Row>
        <List
          style={{ marginLeft: 15 }}
          dataSource={templates}
          renderItem={renderItem}
        />
      </CompanySettingsCard>

      <BoardTemplateDrawer
        visible={showDrawer}
        onClose={clearValues}
        selectedTemplateId={selectedTemplateId}
        onSubmit={onSubmit}
      />
    </>
  );
}

export default Boards;
