import React, { useMemo } from 'react';
import { Select } from 'antd';
import PropType from 'prop-types';

import DisplayText from '../common/text/DisplayText';

function EquipmentSelectDisplay({
  isDisplay,
  value,
  options,
  onChange,
}) {
  const displayLabel = useMemo(() => (
    options.find((opt) => opt.value === value)
  ), [options, value]);

  if (isDisplay) {
    return (
      <DisplayText
        title={displayLabel?.label}
        style={{
          marginBottom: 12,
          fontSize: 13,
          verticalAlign: 'middle',
        }}
      />
    );
  }

  return (
    <Select
      value={value}
      onChange={onChange}
      options={options}
    />
  );
}

EquipmentSelectDisplay.propTypes = {
  isDisplay: PropType.bool,
  value: PropType.string,
  onChange: PropType.func.isRequired,
  options: PropType.arrayOf(
    PropType.shape({
      value: PropType.string.isRequired,
      label: PropType.string.isRequired,
    }),
  ).isRequired,
};

EquipmentSelectDisplay.defaultProps = {
  isDisplay: false,
  value: null,
};

export default EquipmentSelectDisplay;
