import React from 'react';

import LiveFeedCard from './LiveFeedCard';

export default ({
  task = {}
}) => {
  const {
    note = '',
  } = task;
  return (
    <LiveFeedCard style={{ width:'100%', padding: 15, margin: 10 }} hoverable={false}>
      {note}
    </LiveFeedCard>
  );
}
