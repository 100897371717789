import React from 'react';
import { Row, Col } from 'antd';

export default ({ Icon, title, onClick, style = {} }) => (
  <Row
    align='middle'
    justify='start'
    gutter={8}
    className='header-profile-button'
    onClick={onClick}
    style={style}
  >
    <Col>
      <Icon/>
    </Col>
    <Col>
      {title}
    </Col>
  </Row>
);
