export default {};

export const defaultFilters = {
  active: [1],
  contractExpiration: ['valid', 'soon', 'expired'],
  projectTypeId: [],
};

export const NONE_PROJECT_TYPE = -1;

export const getFilterData = ({
  projectTypes = [],
  showProjectTypeFilter = true,
  t,
}) => {
  const additionalFilters = [[
    {
      title: 'Contract',
      key: 'contractExpiration',
      children: [
        {
          title: 'Valid Contract',
          key: 'valid',
        },
        {
          title: 'Expiring Soon',
          key: 'soon',
        },
        {
          title: 'Expired',
          key: 'expired',
        },
      ],
    },
  ]];

  if (showProjectTypeFilter) {
    additionalFilters.push([
      {
        title: `${t('Project')} Type`,
        key: 'projectTypeId',
        children: projectTypes.map((projectType) => ({
          title: projectType.name,
          key: projectType.id,
        })),
      },
    ]);
  }

  return ([
    [
      [
        {
          title: 'Archive',
          key: 'active',
          children: [
            {
              title: 'Active',
              key: 1,
            },
            {
              title: 'Archived',
              key: 0,
            },
          ],
        },
      ],
    ],
    additionalFilters,
  ]);
};
