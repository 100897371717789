import {
  GET_FORM_LIBRARY,
  GET_FORM_LIBRARY_TEMPLATE,
} from '../../../state/actionTypes';
import FormLibraryService from './formLibrary.service';

export default {};

export const getFormLibrary = () => async (dispatch) => {
  const { data } = await FormLibraryService.getLibrary();
  if (!data) return false;
  dispatch({
    type: GET_FORM_LIBRARY,
    payload: data,
  });
  return true;
};

export const getFormLibraryTemplate = (template) => async (dispatch) => {
  const { data } = await FormLibraryService.getFormLibraryTemplates(template);
  if (!data) return false;
  dispatch({
    type: GET_FORM_LIBRARY_TEMPLATE,
    payload: {
      templateData: data,
      key: template.key,
    },
  });
  return true;
};
