import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';

const { Text } = Typography;

const ofStyle = {
  paddingLeft: '2px',
  paddingRight: '2px',
  width: '2em',
  textAlign: 'center',
  color: '#000000',
};

const headerStyle = {
  paddingTop: '1em',
  marginBottom: '1em',
  fontWeight: 600,
};
export default function HoursRedistributionDisplay({
  title,
  distributedHours,
  originalHours,
  hasError,
  ...rest
}) {
  const cellStyle = {
    padding: '2px',
    outline: '1px solid',
    outlineColor: hasError ? '#ff0000' : '#D3D3D3',
    borderRadius: '0.5em',
    width: '5em',
    textAlign: 'center',
    fontWeight: 200,
    color: hasError ? '#ff0000' : '#000000',
  };

  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Col {...rest}>
      <Row>
        <Text style={headerStyle}> {title} </Text>
      </Row>

      <Row>
        <Col style={cellStyle}>{distributedHours.toFixed(2)}</Col>
        <Col style={ofStyle}>of</Col>
        <Col style={cellStyle}>{(originalHours).toFixed(2)}</Col>
      </Row>
    </Col>

  );
}

HoursRedistributionDisplay.propTypes = {
  title: PropTypes.string.isRequired,
  distributedHours: PropTypes.number.isRequired,
  originalHours: PropTypes.number.isRequired,
  hasError: PropTypes.bool,
};

HoursRedistributionDisplay.defaultProps = {
  hasError: false,
};
