import React, {
  useMemo, useCallback, useRef, useState,
} from 'react';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { Col } from 'antd';

import OnTraccrEmpty from '../../common/OnTraccrEmpty';

import sortByString, { getIdMap, includesTerm } from '../../helpers/helpers';

import {
  splitMultiDayShifts,
} from '../scheduleHelpers';
import ScheduleDayWithHeader from '../ScheduleDayWithHeader';
import useDivisionUsers from '../../common/hooks/useDivisionUsers';

const HEADER_HEIGHT = 25;
function UserSchedule({
  day,
  shifts = [],
  onShiftSelect,
  onShiftCreate,
  onNewShiftChange,
  newShift,
  onShiftEdit,
  editShift,
  searchText,
  selectedUsers,
}) {
  const users = useDivisionUsers();

  const scrollRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(-HEADER_HEIGHT);
  const onScroll = useCallback((e) => {
    const {
      target: {
        scrollTop: targetTop,
      } = {},
    } = e;
    setScrollTop(targetTop - HEADER_HEIGHT);
  }, []);

  const userMap = useMemo(() => getIdMap(users), [users]);

  const sortedUsers = useMemo(() => {
    const sUsers = users.filter((user) => user.active);
    sUsers.sort(sortByString('name'));
    return sUsers;
  }, [users]);

  const {
    multiDay: allDayShifts,
    interDay: interDayShifts,
  } = useMemo(() => splitMultiDayShifts(shifts, day), [shifts, day]);

  const shiftsByUser = useMemo(() => {
    const selectedUserMap = new Set(selectedUsers || []);

    const shiftUserMap = {};
    const addToMap = (userShifts, key) => {
      userShifts.forEach((shift) => {
        const {
          users: shiftUsers = [],
        } = shift;
        if (shiftUsers.length === 0) return;
        shiftUsers.forEach((userId) => {
          if (!(userId in shiftUserMap)) {
            const {
              [userId]: {
                name: userName,
              } = {},
            } = userMap;
            shiftUserMap[userId] = {
              userId,
              name: userName,
              shifts: [],
              allDayShifts: [],
            };
          }
          shiftUserMap[userId][key].push(shift);
        });
      });
    };
    addToMap(allDayShifts, 'allDayShifts');
    addToMap(interDayShifts, 'shifts');

    const finalShifts = [];
    sortedUsers.forEach((user) => {
      if (selectedUserMap.size && !selectedUserMap.has(user.id)) return;

      if (user.id in shiftUserMap) {
        finalShifts.push(shiftUserMap[user.id]);
      } else if (!searchText || includesTerm(user.name, searchText)) {
        finalShifts.push({
          userId: user.id,
          name: user.name,
          shifts: [],
          allDayShifts: [],
        });
      }
    });
    return finalShifts;
  }, [
    allDayShifts,
    interDayShifts,
    userMap,
    searchText,
    sortedUsers,
    selectedUsers,
  ]);

  const styleByIdx = (idx) => {
    const style = { flex: 1 };
    if (idx > 0) style.marginLeft = 10;
    if (idx < shiftsByUser.length - 1) style.marginRight = 10;
    return style;
  };

  return (
    <div className="schedule-user-container">
      <div className="schedule-user-name-header">
        {
          shiftsByUser.length > 0 && shiftsByUser.map((userShiftObj, idx) => (
            <div
              className="schedule-user-column-header"
              key={userShiftObj.userId}
              style={styleByIdx(idx)}
            >
              {userShiftObj.name}
            </div>
          ))
        }
      </div>
      <div
        className="schedule-user-body"
        onScroll={onScroll}
        ref={scrollRef}
      >
        {
          shiftsByUser.length > 0 && shiftsByUser.map((userShiftObj, idx) => {
            const {
              userId,
              shifts: userShifts = [],
              allDayShifts: userAllDay = [],
            } = userShiftObj;
            return (
              <Col key={userId} style={styleByIdx(idx)} className="schedule-user-column">
                <ScheduleDayWithHeader
                  key="daily"
                  userId={userId}
                  day={day}
                  userShifts={userShifts}
                  userAllDay={userAllDay}
                  onShiftCreate={onShiftCreate}
                  newShift={newShift}
                  onNewShiftChange={onNewShiftChange}
                  onShiftSelect={onShiftSelect}
                  editShift={editShift}
                  onShiftEdit={onShiftEdit}
                  scrollTop={scrollTop}
                />
              </Col>
            );
          })
        }
      </div>
      {
        shiftsByUser.length === 0 && <OnTraccrEmpty />
      }
    </div>
  );
}

UserSchedule.propTypes = {
  day: PropTypes.instanceOf(DateTime).isRequired,
  shifts: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onShiftCreate: PropTypes.func.isRequired,
  onShiftEdit: PropTypes.func.isRequired,
  onNewShiftChange: PropTypes.func.isRequired,
  newShift: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  editShift: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
  })).isRequired,
  searchText: PropTypes.string.isRequired,
};

export default UserSchedule;
