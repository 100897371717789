import {
  GET_PROFILE,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  CONNECT_GMAIL,
  GET_GMAIL_CODE,
  CYCLE_GMAIL_CODE,
} from '../../state/actionTypes';

const defaultState = {
  profile: {},
  connectedToGmail: false,
};

export default function setupReducer(state = defaultState, action = {}) {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        profile: action.payload.profile,
      };
    case UPDATE_PROFILE:
      return {
        ...state,
        profile: {
          ...state.profile,
          ...action.payload.profile,
        },
      };
    case CONNECT_GMAIL: {
      return {
        ...state,
        connectedToGmail: true,
      };
    }
    case GET_GMAIL_CODE:
    case CYCLE_GMAIL_CODE: {
      const {
        payload: { code } = {},
      } = action;
      return {
        ...state,
        gmailCode: code,
      };
    }
    case CHANGE_PASSWORD:
    default:
      return state;
  }
}
