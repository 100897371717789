import { useEffect } from 'react';

const useOnClickOutside = (document, ref, handler) => {
  useEffect(
    () => {
      const listener = (event) => {
        try {
          let newRef = ref;

          if (!newRef.current) {
            return;
          }

          // Compromise we make for antd popconfirm api not supporting react refs
          if (newRef.current.popupRef && ref.current.popupRef.current) {
            newRef = ref.current.popupRef.current.popupRef;
          }

          // Do nothing if clicking ref's element or descendent elements
          if (newRef.current.contains(event.target)) {
            return;
          }
        } catch (e) {
          return;
        }

        handler(event);
      };

      document.addEventListener('mousedown', listener);
      document.addEventListener('touchstart', listener);

      return () => {
        document.removeEventListener('mousedown', listener);
        document.removeEventListener('touchstart', listener);
      };
    },

    [document, ref, handler],
  );
};

export default useOnClickOutside;
