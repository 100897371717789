import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Row, Col, Form, Select, Tag } from 'antd';
import {
  CloseOutlined,
  UpOutlined,
  CloseCircleOutlined,
  CheckCircleOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import axios from 'axios';

import SignupCard from './SignupCard';
import ToSRows from './ToSRows';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import colors from '../../constants/Colors';

const FORM_INPUT_STYLE = {
  backgroundColor:colors.ONTRACCR_BLACK,
  color:'white',
  borderColor:'rgba(0,0,0,0)',
  boxShadow:'none',
  width: '100%',
  borderRadius:0,
  borderBottomColor:'white',
  borderBottomStyle:'solid',
  borderBottomWidth:1,
  opacity: 1,
};

const SELECTED_STYLE = {
  backgroundColor: colors.ONTRACCR_BLACK,
  color: 'white',
};

let timer;
const debounce = (func,timeout) => {
  return new Promise((resolve) => {
    if(timer) clearTimeout(timer);
    timer = setTimeout(() => resolve(func()),timeout);
  });
};

const StepCircle = ({ active, number }) => (
  <Row style={{
    width:26,
    height:26,
    borderRadius:13,
    backgroundColor: active ? colors.ONTRACCR_DARK_YELLOW : colors.ONTRACCR_BLACK,
    color: active ? colors.ONTRACCR_BLACK : colors.ONTRACCR_DARK_YELLOW,
    borderColor: colors.ONTRACCR_DARK_YELLOW,
    borderWidth:1,
    borderStyle:'solid',
    fontSize:13,
  }} justify='center' align='middle'>
    {number}
  </Row>
);

const selectTagRender = ({ label, closable, onClose }) => (
  <Tag
    style={SELECTED_STYLE}
    closable={closable}
    onClose={onClose}
    closeIcon={<CloseOutlined style={{ color: 'white'}} />}
  >
    {label}
  </Tag>
);

const FormInput = ({ type = 'text', formProps = {}, inputProps = {}, }) => (
  <div style={{ width: '100%', margin:0, position:'relative'}}>
    <Form.Item
      style={{ 
        width: '100%', 
        margin:0,
        marginBottom:20,
      }}
      {...formProps}
    >
      {type === 'text'
        ? <OnTraccrTextInput 
          className='dark-register'
          style={FORM_INPUT_STYLE}
          bordered={false}
          {...inputProps}
        />
        : <Select
          className='register-select'
          style={FORM_INPUT_STYLE}
          bordered={false}
          showSearch
          optionFilterProp='label'
          mode='multiple'
          tagRender={selectTagRender}
          {...inputProps}
        />
      }
    </Form.Item>
  </div>
);

export default ({
  onBack,
  onNext,
  onCreate,
  number,
  text,
  setFormRef = () => null,
  fields = [],
  style = {},
}) => {
  const [acceptedToS,setAcceptedToS] = useState(false);
  const [acceptedPrivacy,setAcceptedPrivacy] = useState(false);
  const [promoCode,setPromoCode] = useState();
  const [SuffixIcon,setSuffixIcon] = useState();
  const [loading,setLoading] = useState();

  const onFinish = useCallback(async (values) => {
    if(number < 2) return onNext(values);
    const payload = { ...values };
    if(promoCode) payload.code = promoCode;
    setLoading(true);
    await onCreate(payload);
    setLoading(false);

  },[number, onNext, onCreate, promoCode]);

  useEffect(() => {
    if(!promoCode) {
      setSuffixIcon();
    } else {
      setSuffixIcon(LoadingOutlined);
      debounce(async () => {
        const { data } = await axios.get(`/check/promocode?code=${promoCode}`);
        setSuffixIcon(data ? CheckCircleOutlined : CloseCircleOutlined);
      },500);
    
    }
  },[promoCode]);

  const buttonStyle = useMemo(() => {
    if(number < 2) return  {};
    return {
      width: loading ? 160 : 140,
    }
  },[loading,number]);

  return (
    <SignupCard style={{
      color:'white',
      ...style,
    }} color={colors.ONTRACCR_BLACK}>
        <Form
          ref={setFormRef}
          onFinish={onFinish}
          style={{ width: '100%', padding: '0px 80px', margin:0, position:'relative'}}
        >
        <Row style={{ width:'100%', minHeight: 160, marginTop: 70, color:'white' }}>
            {text}
        
            {fields.map((field) => (
              <FormInput
                {...field}
              />
            ))}
          </Row>

          {number === 2 && 
            <div style={{ position:'relative'}}>
              <OnTraccrTextInput
                className='dark-register'
                style={{
                  backgroundColor:colors.ONTRACCR_BLACK,
                  color:'white',
                  borderColor:'rgba(0,0,0,0)',
                  boxShadow:'none',
                  width: '100%',
                  height: 32,
                  borderRadius:0,
                  marginBottom:10,
                  borderBottomColor:'white',
                  borderBottomStyle:'solid',
                  borderBottomWidth:1,
                }}
                placeholder='Promo Code'
                bordered={false}
                onChange={(e) => {
                  const {
                    target:{
                      value,
                    } = {}
                  } = e;
                  setPromoCode(value);
                }}
              />
              {SuffixIcon  && 
                <SuffixIcon style={{ position:'absolute', top: 10, right: 10, color:'white'}}/>
              }
            </div>
          }
          {number === 2  && 
            <ToSRows
              darkStyle
              setAcceptedToS={setAcceptedToS}
              setAcceptedPrivacy={setAcceptedPrivacy}
            />
          }

          <Row 
            style={{
              width: '100%', 
              flexDirection:'column',
              marginBottom:20,
              marginTop:20,
            }} 
            justify='center'
            align='middle'
          >
            <OnTraccrButton
              loading={loading}
              htmlType='submit'
              style={{
                marginTop: 5,
                ...buttonStyle,
              }}
              title={number === 2 ? 'Create Account' : 'Next'}
              roundStyle
              fat
              disabled={number === 2  && (!acceptedToS || !acceptedPrivacy)}
            />
            <Row justify='space-around' style={{ width: 150, marginTop: 30 }}>
              <Col> <StepCircle active={number === 0} number={1}/> </Col>
              <Col> <StepCircle active={number === 1} number={2}/> </Col>
              <Col> <StepCircle active={number === 2} number={3}/> </Col>
            </Row>
          </Row>
      </Form>
      <Row style={{ width: '100%', top: 5, position:'absolute' }} justify='center'>
        {number === 0 ? 'Company Signup' : <UpOutlined onClick={onBack} style={{marginTop: 5 }}/>}
      </Row>
      {number === 0 && <CloseOutlined 
        style={{
          position:'absolute',
          left: 10,
          top: 10,
        }}
        onClick={onBack}
      />}
    </SignupCard>
  );
};

