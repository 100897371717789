import React from 'react';
import { ExclamationCircleFilled } from '@ant-design/icons';
import {
  Row,
  Col,
} from 'antd';


export default ({
  justify = 'start',
  title = 'Warning'
}) => (
  <Row justify={justify} align='middle'>
    <Col>
      <Row gutter={4}>
        <Col span={2}>
          <ExclamationCircleFilled style={{ color: '#faad14', fontWeight:'bold' }}/>
        </Col>
        <Col span={22}>
          <span style={{ paddingLeft: 10 }}> {title} </span>
        </Col>
      </Row>
    </Col>
  </Row>
)