import React, {
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import SageUnionSync from './SageUnionSync';
import SageUserSync from './SageUserSync';
import SageProjectSync from './SageProjectSync';
import SageCostcodeSync from './SageCostcodeSync';

import IntegrationSyncDrawer from '../IntegrationSyncDrawer';

import {
  getSageData,
} from '../../state/settings.actions';

export default function SageImportDrawer({
  visible,
  onClose,
  onSave,
  version,
  selectedIntegration,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.sage.integrations);

  const [importUsers, setImportUsers] = useState([]);
  const [importProjects, setImportProjects] = useState([]);
  const [importCostcodes, setImportCostcodes] = useState([]);
  const [importUnions, setImportUnions] = useState([]);

  const saveClicked = useCallback(() => {
    onSave({
      importUsers,
      importProjects,
      importCostcodes,
      importUnions,
    });
  }, [
    importUsers,
    importProjects,
    importCostcodes,
    importUnions,
  ]);

  const divisionFilter = useMemo(() => (
    new Set(integrations.find(({ id }) => id === selectedIntegration?.id)?.divisions ?? [])
  ), [selectedIntegration, integrations]);

  useEffect(() => {
    if (visible) {
      dispatch(getSageData(selectedIntegration?.id, version));
    }
  }, [visible, version, selectedIntegration]);

  const steps = [
    {
      title: 'Unions',
      key: 0,
      content: (
        <SageUnionSync
          onUnionsChanged={setImportUnions}
          importUnions={importUnions}
          version={version}
        />
      ),
    },
    {
      title: 'Users',
      key: 0,
      content: (
        <SageUserSync
          onUsersChanged={setImportUsers}
          importUsers={importUsers}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: t('Project', { count: 2 }),
      key: 1,
      content: (
        <SageProjectSync
          onProjectsChanged={setImportProjects}
          importProjects={importProjects}
          divisionFilter={divisionFilter}
        />
      ),
    },
    {
      title: 'Cost Codes',
      key: 2,
      content: (
        <SageCostcodeSync
          onCostcodesChanged={setImportCostcodes}
          importCostcodes={importCostcodes}
          divisionFilter={divisionFilter}
        />
      ),
    },
  ];

  return (
    <IntegrationSyncDrawer
      width="auto"
      bodyStyle={{
        width: 1200,
      }}
      nextDisabled={false}
      visible={visible}
      title="Import from Sage"
      onClose={onClose}
      onSave={saveClicked}
      steps={steps}
    />
  );
}

SageImportDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  version: PropTypes.string.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
    divisions: PropTypes.arrayOf(PropTypes.string),
  }),
};

SageImportDrawer.defaultProps = {
  visible: false,
  selectedIntegration: {},
};
