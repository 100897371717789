import React, { useMemo } from 'react';
import {
  Row,
  Col,
  Switch,
  Divider,
} from 'antd';

import DisplayText from '../../common/text/DisplayText';
import HoverHelp from '../../common/HoverHelp';

export default ({
  autoImport,
  setAutoImport,
  setActivePanels,
  helpContent,
  importData,
  importType,
}) => {
  const {
    importCount,
    linkCount,
  } = useMemo(() => {
    const {
      importList = [],
    } = importData;
  
    let iCount = 0;
    let lCount = 0;
    importList.forEach(({ linkValue }) => {
      if(linkValue) {
        lCount += 1;
      } else {
        iCount += 1;
      }
    });
    return { importCount: iCount, linkCount: lCount };
  }, [importData]);
  
  return (
    <Row style={{ width: '100%'}}>
      <Row style={{ width: '100%'}} justify='start' align='middle' gutter={10}>
        <Col>
          <Switch 
            checked={autoImport} 
            onChange={(checked) => {
              if(!checked) {
                setAutoImport(false);
                setTimeout(() => {
                  setActivePanels(['new']);
                }, 250);
              } else {
                setActivePanels([]);
                setTimeout(() => {
                  setAutoImport(true);
                }, 250);
              }
            }}
          />
        </Col>
        <Col>
          <DisplayText
            title='Auto Import'
            style={{
              lineHeight: '22px',
              marginBottom:0,
            }}
          />
        </Col>
        {helpContent
        && (
        <Col style={{ height:20}}>
          <HoverHelp content={helpContent} placement='rightTop'/>
        </Col>
        )}
        <Col style={{ marginLeft: 20,  }}>
          <div>Importing <b>{importCount}</b> {importType}{importCount === 1 ? '' : 's'}</div>
        </Col>
        <Col style={{ marginLeft: 20,  }}>
          <div>Linking <b>{linkCount}</b> {importType}{linkCount === 1 ? '' : 's'}</div>
        </Col>
      </Row>
      <Divider/>
    </Row>
  );
}