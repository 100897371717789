import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Drawer, Row } from 'antd';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';

import LiveFeedTaggedText from './LiveFeedTaggedText';
import PhotoReelPreview from '../../common/photos/PhotoReelPreview';
import FullPhoto from '../../common/photos/FullPhoto';

import Permissions from '../../auth/Permissions';

import { getFileType, downloadFile, getFileDetails } from '../../files/fileHelpers';
import { getIdMap } from 'ontraccr-common/lib/Common';

const WIDTH = 500;
export default ({
  post,
  onClose,
}) => {
  const users = useSelector((state) => state.users.users);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const {
    createdAt = 0,
    userId = {},
    imageURLs = [],
  } = post || {};
  let userName = '';
  if(userId === Permissions.id) {
    userName = 'You'
  } else if (userId in userMap) {
    const {
      name = '',
    } = userMap[userId];
    userName = name;
  }
  const [selectedFile,setSelectedFile] = useState();
  const [currIndex, setCurrIndex] = useState();
  const [fileDetails,setFileDetails] = useState({})
  const clearSelectedFile = useCallback(() => {
    setSelectedFile();
    setCurrIndex();
  },[]);
  const clearFileDetails = useCallback(() => setFileDetails({}),[]);

  const onNextFileClick = useCallback((isRight) => () => {
    const newIndex = isRight ? currIndex + 1 : currIndex - 1;
    const isInvalid = isRight ? newIndex >= imageURLs.length : newIndex < 0;
    if (isInvalid) return;
    const ourFile = imageURLs[newIndex] || {};
    const ourFileType = getFileType(ourFile);
    setSelectedFile({ ...ourFile, type: ourFileType });
    setCurrIndex(newIndex);
  }, [currIndex, imageURLs]);

  useEffect(() => {
    clearSelectedFile();
    clearFileDetails();
  },[post,clearSelectedFile,clearFileDetails]);

  useEffect(() => {
    const getDetails = async (selectedFile) => {
      const details = await getFileDetails(selectedFile);
      if(details) setFileDetails(details);
    }
    if(selectedFile) {
      getDetails(selectedFile);
    } else {
      clearFileDetails();
    }
  },[selectedFile,clearFileDetails]);
  return (
    <Drawer
      title={`${userName} - ${DateTime.fromMillis(createdAt).toLocaleString(DateTime.DATETIME_MED_WITH_WEEKDAY)}`}
      visible={post}
      onClose={onClose}
      width={WIDTH}
    >
      {post && <LiveFeedTaggedText post={post}/>}
      {imageURLs.length > 0 ?
        <Row style={{
          overflowY:'scroll',
          width:'100%',
          marginTop:20,
          maxWidth:WIDTH,
        }} justify='center'>
          {imageURLs.map((file, index) => {
            const fileType = getFileType(file);
            const onClick = () => {
              setSelectedFile({ ...file, type: fileType});
              setCurrIndex(index);
            };
            return (
              fileType === 'image' ? <img
                key={file.url}
                alt='Post'
                style={{
                  width: 'auto',
                  maxWidth:WIDTH,
                  height:'75vh',
                  margin:10,
                  objectFit:'contain',
                  cursor:'pointer',
                }}
                src={file.url}
                onClick={onClick}
              /> : 
              <PhotoReelPreview
                key={file.name}
                type={fileType}
                file={file}
                onClick={onClick}
              />
            )
          })}
        </Row> : null
      }
      <FullPhoto
        url={fileDetails.url}
        title={fileDetails.name}
        rotation={fileDetails.rotation ?? 0}
        type={fileDetails.type}
        onClose={clearSelectedFile}
        onDownload={() => {
          downloadFile({ fileDetails });
        }}
        onLeft={onNextFileClick(false)}
        onRight={onNextFileClick(true)}
        showLeft={selectedFile && currIndex > 0}
        showRight={selectedFile && currIndex < (imageURLs.length - 1)}
        useApryse
      />
    </Drawer>
  );
}