import React, { useEffect } from 'react';
import { useSpring, animated } from 'react-spring';
import { DownOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

function CollapseButton({
  open,
  onClick,
  color,
}) {
  const [iconStyle, setIconStyle] = useSpring(() => ({ transform: 'rotate(0deg)' }));
  useEffect(() => {
    setIconStyle({
      transform: open ? 'rotate(0deg)' : 'rotate(-90deg)',
    });
  }, [open]);

  return (
    <animated.div
      style={{
        cursor: 'pointer',
        ...iconStyle,
      }}
      onClick={onClick}
    >
      <DownOutlined style={{ color }} />
    </animated.div>
  );
}

CollapseButton.propTypes = {
  open: PropTypes.bool.isRequired,
  color: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

CollapseButton.defaultProps = {
  color: null,
};

export default CollapseButton;
