import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Table, Select } from 'antd';
import { SettingOutlined } from '@ant-design/icons';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import EclipsePayCodeMappingDrawer from './EclipsePayCodeMappingDrawer';
import EclipseDistributionOverride from './EclipseDistributionOverride';

import {
  ontraccrTimeFields,
  eclipseTimeFields,
} from './eclipse.constants';

const PAY_CODE_MODE = 'payCode';
const DISTRIBUTION_MODE = 'distributionOverride';
const DRAWER_MODES = {
  pay_code: PAY_CODE_MODE,
  materialUsageDistributionType: DISTRIBUTION_MODE,
  materialTransferDistributionType: DISTRIBUTION_MODE,
};

function EclipseFieldMapping({
  value: mappings,
  onChange: onMappingsChanged,
  divisionId,
}) {
  const customFields = useSelector((state) => state.timecards.customFields);

  const [drawerMode, setDrawerMode] = useState();

  const closeDrawer = useCallback(() => setDrawerMode(), []);

  const onSaveMappings = useCallback((payCodeMappings) => {
    onMappingsChanged({
      ...mappings,
      payCodeMappings,
    });
    setDrawerMode();
  }, [mappings, onMappingsChanged]);

  const onSaveOverrides = useCallback((newOverride) => {
    onMappingsChanged({
      ...mappings,
      overrides: {
        ...mappings?.overrides ?? {},
        [drawerMode]: newOverride,
      },
    });
    setDrawerMode();
  }, [mappings, onMappingsChanged, drawerMode]);

  const timecardFields = useMemo(() => {
    const {
      [divisionId]: {
        fields: sections = [],
      } = {},
    } = customFields;

    // Gross deep copy
    const fieldMap = JSON.parse(JSON.stringify(ontraccrTimeFields));
    sections.forEach((section) => {
      const { fields = [], name: sectionName, hidden } = section;
      if (hidden) return;
      fields.forEach((field) => {
        const {
          id: fieldId,
          selectedType,
          configProps: {
            title,
            dataType,
          } = {},
        } = field;
        const fullType = selectedType === 'table' || selectedType === 'dropdown'
          ? `${selectedType} - ${dataType}`
          : selectedType;
        if (!(fullType in fieldMap)) {
          fieldMap[fullType] = [];
        }
        fieldMap[fullType].push({
          label: `${sectionName} - ${title}`,
          value: fieldId,
        });
      });
    });
    return fieldMap;
  }, [ontraccrTimeFields, customFields, divisionId]);

  const columns = useMemo(() => [
    {
      dataIndex: 'sourceField',
      title: 'Ontraccr Field',
      width: 300,
      render: (_, record) => {
        const { type: fieldType, value } = record;

        const {
          [value]: mappingValue,
        } = mappings ?? {};
        const {
          [fieldType]: fieldOpts = [],
        } = timecardFields;

        if (fieldType === 'input') {
          return (
            <OnTraccrTextInput
              value={mappingValue}
              allowClear
              onChange={(e) => {
                const newMaps = {
                  ...mappings,
                };
                const fieldKey = e?.target?.value;
                if (fieldKey) {
                  newMaps[value] = fieldKey;
                } else {
                  delete newMaps[value];
                }
                onMappingsChanged(newMaps);
              }}
            />
          );
        }

        return (
          <Select
            options={fieldOpts}
            value={mappingValue}
            showSearch
            optionFilterProp="title"
            allowClear={!record.required}
            onChange={(fieldKey) => {
              if (!onMappingsChanged) return;
              const newMaps = {
                ...mappings,
              };
              if (fieldKey) {
                newMaps[value] = fieldKey;
              } else {
                delete newMaps[value];
              }
              onMappingsChanged(newMaps);
            }}
          />
        );
      },
    }, {
      dataIndex: 'targetField',
      title: 'Eclipse Field',
      width: 300,
      render: (_, record) => {
        const isPaycode = record.value === 'pay_code';
        const isDistroType = record.value === 'materialUsageDistributionType'
          || record.value === 'materialTransferDistributionType';
        const className = record.required
          ? 'form-required-field'
          : null;
        return (
          <span className={className}>
            {record.label}
            {
              (isPaycode || isDistroType)
              && (
                <BorderlessButton
                  iconNode={<SettingOutlined style={{ marginLeft: 0 }} />}
                  style={{ width: 20 }}
                  onClick={() => setDrawerMode(record.value)}
                />
              )
            }
          </span>
        );
      },
    },
  ], [mappings, onMappingsChanged, timecardFields]);

  return (
    <>
      <Table
        dataSource={eclipseTimeFields}
        columns={columns}
        pagination={false}
        size="small"
        rowKey="value"
      />
      <EclipsePayCodeMappingDrawer
        visible={DRAWER_MODES[drawerMode] === PAY_CODE_MODE}
        payCodeMappings={mappings?.payCodeMappings}
        onClose={closeDrawer}
        onSaveMappings={onSaveMappings}
      />
      <EclipseDistributionOverride
        visible={DRAWER_MODES[drawerMode] === DISTRIBUTION_MODE}
        override={mappings?.overrides?.[drawerMode]}
        onClose={closeDrawer}
        onSaveOverride={onSaveOverrides}
      />
    </>
  );
}

EclipseFieldMapping.propTypes = {
  value: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  divisionId: PropTypes.string,
};

EclipseFieldMapping.defaultProps = {
  value: {},
  onChange: null,
  divisionId: null,
};

export default EclipseFieldMapping;
