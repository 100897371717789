import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Checkbox } from 'antd';
import DisplayText from '../../common/text/DisplayText';

export default ({
  typeId,
  isDisplay,
  onCheckChange: onPropCheckChange,
  value: {
    collect: checked,
  } = {},
}) => {
  // TODO: Get recent number not anchor
  const {
    [typeId]: anchorNumber,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            formAnchors = {},
          } = {},
        } = {},
      } = {},
    } = state;
    return formAnchors;
  });

  const onCheckChange = useCallback((e) => {
    const {
      target:{
        checked,
      } = {},
    } = e;
    onPropCheckChange(checked);
  },[onPropCheckChange]);
  if(isDisplay && !checked) return null;
  return (
    <Row className='form-default-data-row' justify='space-between'>
      <Col>
        <Row gutter={10}>
        {!isDisplay && <Col>
            <Checkbox
              onChange={onCheckChange}
              checked={checked}
            />
          </Col>}
          <Col>
            Add Number
          </Col>
        </Row>
      </Col>
      {!isDisplay && checked && <Col>
        <DisplayText title={anchorNumber}/>
      </Col>}
    </Row>
  );
}
