import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import DivisionSelector from '../../common/inputs/DivisionSelector';

const { Option } = Select;

export default ({
  workbook: {
    SheetNames = [],
  } = {},
  onSheetChanged,
  divisionId,
  onDivisionChanged,
}) => {
  const divisions = useSelector((state) => state.settings.divisions);
  const showDivisions = useMemo(() => Object.keys(divisions).length > 1, [divisions]);
  return (
    <>
      {showDivisions &&
        <DivisionSelector
          style={{ width: 250, marginBottom: 20, display: 'block' }}
          divisionId={divisionId}
          onChange={onDivisionChanged}
      />}
      <Select style={{ width: 250 }} defaultValue={SheetNames[0]} onSelect={onSheetChanged}>
        {SheetNames.map((sheet,idx) => <Option value={sheet} key={idx}>{sheet}</Option>)}
      </Select>
    </>
  );
}
