import axios from 'axios';

import { request, archive } from '../../../helpers/requests';

export default {
  create: (payload) => request({
    call: axios.post('/customers', payload),
    errMsg: 'Could not create customer',
  }),
  get: () => request({
    call: axios.get('/customers'),
    errMsg: 'Could not get customers',
    hideSuccessToast: true,
  }),
  archive: (id, active) => archive({
    id, active, type: 'customers',
  }),
  update: (id, payload) => request({
    call: axios.put(`/customers/${id}`, payload),
    errMsg: 'Could not update customer',
  }),
  delete: (id) => request({
    call: axios.delete(`/customers/${id}`),
    errMsg: 'Could not delete customer',
  }),
  getNotes: (id) => request({
    call: axios.get(`/customers/${id}/notes`),
    errMsg: 'Could not get customer notes',
    hideSuccessToast: true,
  }),
  addNote: (id, note) => request({
    call: axios.post(`/customers/${id}/note`, { note }),
    errMsg: 'Could not create note',
  }),
  getClientComms: (id) => request({
    call: axios.get(`/clientPortal/comms/${id}/notes`),
    errMsg: 'Could not get client messages',
    hideSuccessToast: true,
  }),
  addClientComm: (payload) => request({
    call: axios.post('/clientPortal/comms/note/outgoing', payload),
    errMsg: 'Could not add client message',
  }),
  getUnreadClientComms: () => request({
    call: axios.get('/clientPortal/comms/unread'),
    errMsg: 'Could not get unread client messages',
    hideSuccessToast: true,
  }),
  markAsRead: (ids) => request({
    call: axios.post('/clientPortal/comms/read/incoming', { ids }),
    errMsg: 'Could not mark messages as read',
    hideSuccessToast: true,
  }),
  getClientBroadcasts: () => request({
    call: axios.get('/clientPortal/comms/broadcasts'),
    errMsg: 'Could not get client broadcast messages',
    hideSuccessToast: true,
  }),
  getCustomerLabels: () => request({
    call: axios.get('/customers/labels'),
    errMsg: 'Could not get customer labels',
    hideSuccessToast: true,
  }),
};
