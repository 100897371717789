import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import SettingsAddCard from '../SettingsAddCard';

import ExportsDrawer from './ExportsDrawer';

import {
  deleteCustomExport,
  getCustomExports,
} from '../../reports/state/reports.actions';
import {
  getCustomFields,
} from '../../timecards/state/timecards.actions';
import { getCustomTables } from '../../forms/state/forms.actions';
import { getUserCustomFieldTemplate } from '../../users/state/users.actions';

function Exports({
  visible,
  type,
}) {
  const dispatch = useDispatch();

  const customExports = useSelector((state) => state.reports.customExports);

  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedExport, setSelectedExport] = useState();

  const allExports = customExports.filter((ex) => ex.type === type);

  const onAddClicked = useCallback(() => setShowDrawer(true), []);
  const onCloseDrawer = useCallback(() => {
    setShowDrawer(false);
    setSelectedExport();
  }, []);

  const onEditClicked = useCallback((record) => {
    setSelectedExport(record);
    setShowDrawer(true);
  }, []);

  const onDeleteClicked = useCallback((record) => {
    CustomConfirmModal({
      title: `Delete ${record.title} ?`,
      onOk: () => dispatch(deleteCustomExport(record.id)),
    });
  }, [dispatch]);

  useEffect(() => {
    if (visible) {
      dispatch(getCustomExports());
      dispatch(getCustomFields());
      dispatch(getCustomTables());
      dispatch(getUserCustomFieldTemplate());
    }
  }, [dispatch, visible]);

  return (
    <SettingsAddCard
      title="Exports"
      onAddClicked={onAddClicked}
      onEditClicked={onEditClicked}
      onDeleteClicked={onDeleteClicked}
      style={{ marginLeft: 10 }}
      dataSource={allExports}
    >
      <ExportsDrawer
        visible={showDrawer}
        onClose={onCloseDrawer}
        selectedExport={selectedExport}
        type={type}
      />
    </SettingsAddCard>
  );
}

Exports.propTypes = {
  visible: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
};

export default Exports;
