import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import MaterialUnitsSelector from '../../common/inputs/MaterialUnitsSelector';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import { 
  percentFormatter,
  currencyFormatter,
  currencyParser,
} from '../../helpers/inputParsers';

const BASE_COL_WIDTH = 150;

const getRowKey = (record) => `${Math.random() * 10000} - ${record.name}-${record.divisionId}`;
const textRender = ({ key, onChange, materialNames = new Set() }) => (value, _, index) => (
  <OnTraccrTextInput
    value={value}
    onChange={(e) => {
      const {
        target: {
          value: newValue,
        } = {}
      } = e;
      onChange(index, { [key]: newValue });
    }}
    style={
      key === 'name' && (!value || materialNames.has(value.toString().toLowerCase()))
      ? { color: 'red', borderColor: 'red' }
      : {}
    }
  />
)

const getColumns = ({
  materialNames,
  onValueChanged,
  onDelete,
}) => [
  {
    title: '',
    dataIndex: '',
    width: 30,
    render: (_,record,index) => (
      <BorderlessButton
        iconNode={<DeleteOutlined style={{ color: 'red', marginLeft: 0 }}/>}
        onClick={() => onDelete(record.id)}
      />
    )
  },
  {
    title: 'Name',
    dataIndex: 'name',
    align:'left',
    width: BASE_COL_WIDTH,
    render:textRender({ key: 'name', onChange: onValueChanged, materialNames })
  },
  {
    title: 'Part Number',
    dataIndex: 'partNumber',
    width: BASE_COL_WIDTH,
    render:textRender({ key: 'partNumber', onChange: onValueChanged })
  },
  {
    title: 'Description',
    dataIndex: 'description',
    width: BASE_COL_WIDTH,
    render:textRender({ key: 'description', onChange: onValueChanged })
  },
  {
    title: 'Supplier',
    dataIndex: 'supplier',
    width: BASE_COL_WIDTH,
    render:textRender({ key: 'supplier', onChange: onValueChanged })
  },
  {
    title: 'Cost',
    dataIndex: 'cost',
    width: BASE_COL_WIDTH,
    render: (cost, _, index) => (
      <OnTraccrNumberInput
        formatter={currencyFormatter}
        value={cost}
        precision={2}
        min={0}
        step={0.01}
        parser={currencyParser}
        onChange={(newValue) => onValueChanged(index, { cost: newValue })}
      />
    )
  },
  {
    title: 'Markup',
    dataIndex: 'markup',
    width: BASE_COL_WIDTH,
    render:(markup, _, index) => (
      <OnTraccrNumberInput
        formatter={percentFormatter}
        value={markup ? markup * 100 : 0}
        min={0}
        step={1}
        onChange={(newValue) => onValueChanged(index, { markup: newValue / 100 })}
      />
    )
  },
  {
    title: 'Labour Cost',
    dataIndex: 'labourCost',
    width: BASE_COL_WIDTH,
    render: (labourCost, _, index) => (
      <OnTraccrNumberInput
        formatter={currencyFormatter}
        value={labourCost}
        precision={2}
        min={0}
        step={0.01}
        parser={currencyParser}
        onChange={(newValue) => onValueChanged(index, { labourCost: newValue })}
      />
    ),
  },
  {
    title: 'Units',
    dataIndex: 'units',
    width: BASE_COL_WIDTH,
    render:(units, _, index) => (
      <MaterialUnitsSelector
        value={units}
        onSelect={(newValue) => onValueChanged(index, { units: newValue })}
      />
    )
  },
  {
    title: 'Quantity',
    dataIndex: 'quantity',
    width: BASE_COL_WIDTH,
    render:(quantity, _, index) => (
      <OnTraccrNumberInput
        min={0}
        step={1}
        value={quantity}
        onChange={(newValue) => onValueChanged(index, { quantity: newValue })}
      />
    )
  },
];

export default ({
  selectedDivision,
  selectedSheet = [],
  headerMapping = {},
  onMassUploadDataChanged,
}) => {
  const materials = useSelector(state => state.materials.materials);
  const [data,setData] = useState([]);
  const onValueChanged = useCallback((index, newData) => {
    setData(
      data.map((datum,i) => {
        if(i !== index) return datum;
        return {
          ...datum,
          ...newData,
        };
      }),
    )
  },[data]);
  const onDelete = useCallback((id) => {
    const newData = [...data];
    setData(newData.filter((datum) => datum.id !== id));
  },[data]);
  const materialNames = useMemo(() => {
    const nameSet = new Set();
    Object.values(materials).forEach((mat) => {
      if(mat.name && mat.divisionId === selectedDivision) nameSet.add(mat.name.toLowerCase());
    });
    return nameSet;
  },[materials, selectedDivision]);

  const columns = useMemo(() => (
    getColumns({ onValueChanged, onDelete, materialNames })
  ),[onValueChanged, onDelete. materialNames]);
  const titleToKeyMap = useMemo(() => {
    // Convert header mappings from key: title to title: key
    const convertedMap = {};
    Object.keys(headerMapping).forEach((key) => {
      const title = headerMapping[key];
      if(!(title in convertedMap)) {
        convertedMap[title] = [];
      }
      convertedMap[title].push(key);
    });
    return convertedMap;
  },[headerMapping]);

  useEffect(() => {
    const newData = selectedSheet.map((row, index) => {
      const parsedRow = {};
      Object.keys(row).forEach((rowTitle) => {
        const {
          [rowTitle]: rowKeys = [],
        } = titleToKeyMap;
        if(rowKeys.length === 0) return;
        rowKeys.forEach((rowKey) => {
          parsedRow[rowKey] = row[rowTitle];
        });
      });
      parsedRow.id = index;
      return parsedRow;
    });
    setData(newData);
  },[titleToKeyMap, selectedSheet]);

  useEffect(() => {
    onMassUploadDataChanged(data);
  },[data]);

  return (
    <Table
      size='small'
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
      rowKey={getRowKey}
    />
  );
}
