import React, {
  useState, useMemo, useCallback, useEffect,
} from 'react';
import { Table, Pagination, Row } from 'antd';
import PropTypes from 'prop-types';

const PAGINATION_HEIGHT = 20;
const PAGE_SIZE = 50;
const DEFAULT_SORT_FILTERS = { filters: {}, sorter: {} };
function PaginatedTable(props) {
  const {
    dataSource = [],
    scroll = {},
    columns = [],
    scrollOffset = 200,
    initialSortFilters = DEFAULT_SORT_FILTERS,
    onFilteredDataChanged,
  } = props;
  const [currentPage, setCurrentPage] = useState(1);
  const [sortFilters, setSortFilters] = useState(initialSortFilters);

  const onSortFilterChange = useCallback((_, filters, sorter) => {
    setSortFilters({ filters, sorter });
  }, []);

  const columnMap = useMemo(() => {
    const cMap = {};
    columns.forEach((col) => {
      const {
        dataIndex,
        key = dataIndex,
      } = col;
      cMap[key] = col;
    });
    return cMap;
  }, [columns]);

  const controlledData = useMemo(() => {
    const {
      filters = {},
      sorter = {},
    } = sortFilters;
    const filteredData = dataSource.filter((datum) => (
      Object.keys(filters).every((filterKey) => {
        const {
          [filterKey]: {
            onFilter = () => true,
          } = {},
        } = columnMap;
        const filterData = filters[filterKey];
        if (!filterData) return true;
        return filterData.some((filterVal) => onFilter(filterVal, datum));
      })
    ));
    if (!sorter.order) return filteredData;
    const {
      column: {
        sorter: colSorter = (a, b) => a - b,
      } = {},
      order,
    } = sorter;
    filteredData.sort((a, b) => colSorter(a, b) * (order === 'ascend' ? 1 : -1));
    return filteredData;
  }, [dataSource, sortFilters, columnMap]);

  const pageData = useMemo(() => (
    controlledData.slice((currentPage - 1) * PAGE_SIZE, (currentPage * PAGE_SIZE) + 1)
  ), [controlledData, currentPage]);

  useEffect(() => {
    if (currentPage * PAGE_SIZE > controlledData.length) {
      setCurrentPage(Math.floor(controlledData.length / PAGE_SIZE) + 1);
    }
  }, [controlledData, currentPage]);

  useEffect(() => {
    if (onFilteredDataChanged) onFilteredDataChanged(controlledData);
  }, [onFilteredDataChanged, controlledData]);

  const showPagination = controlledData.length > PAGE_SIZE;

  return (
    <>
      <div className="file-list-container">
        <Table
          {...props} // eslint-disable-line react/jsx-props-no-spreading
          scroll={{ ...scroll, y: `calc(100vh - ${scrollOffset}px - ${showPagination ? PAGINATION_HEIGHT : 0}px)` }}
          pagination={false}
          dataSource={pageData}
          onChange={onSortFilterChange}
        />
      </div>
      {
      showPagination
        && (
          <Row justify="end" style={{ margin: '10px 0px' }}>
            <Pagination
              pageSize={PAGE_SIZE}
              showSizeChanger={false}
              size="small"
              total={controlledData.length}
              current={currentPage}
              onChange={setCurrentPage}
            />
          </Row>
        )
      }
    </>
  );
}
/* eslint-disable react/forbid-prop-types */
PaginatedTable.propTypes = {
  dataSource: PropTypes.array,
  scroll: PropTypes.object,
  columns: PropTypes.array,
  scrollOffset: PropTypes.number,
  initialSortFilters: PropTypes.object,
  onFilteredDataChanged: PropTypes.func,
};

PaginatedTable.defaultProps = {
  dataSource: [],
  scroll: {},
  columns: [],
  scrollOffset: 200,
  initialSortFilters: DEFAULT_SORT_FILTERS,
  onFilteredDataChanged: null,
};

export default PaginatedTable;
