import React, { useCallback } from 'react';
import { Row, Col, Select } from 'antd';
import PropTypes from 'prop-types';

import { nth } from '../../helpers/time';

const getOptions = (disabledValue) => (
  new Array(31).fill().map((_, i) => {
    const label = `${i + 1}${nth(i + 1)}`;
    return (
      <Select.Option value={i} label={label} disabled={i === disabledValue}>
        {label}
      </Select.Option>
    );
  })
);
function SemiMonthlyPayrollDates({
  settings,
  saveCompanySettings,
}) {
  const {
    semiMonthlyPayPeriodDates = [],
  } = settings;
  const [
    firstDay = 1,
    secondDay = 16,
  ] = semiMonthlyPayPeriodDates;
  const onSelect = useCallback((index) => (value) => {
    const newValues = [...semiMonthlyPayPeriodDates];
    newValues[index] = value;
    saveCompanySettings({
      semiMonthlyPayPeriodDates: newValues,
    });
  }, [saveCompanySettings, semiMonthlyPayPeriodDates]);
  return (
    <>
      <Row align="middle" gutter={6}>
        <Col>
          First Day:
        </Col>
        <Col>
          <Select
            style={{ width: 75 }}
            value={firstDay}
            onSelect={onSelect(0)}
          >
            {getOptions(secondDay)}
          </Select>
        </Col>
      </Row>
      <Row align="middle" gutter={6} style={{ marginTop: 3 }}>
        <Col>
          Second Day:
        </Col>
        <Col>
          <Select
            style={{ width: 75 }}
            value={secondDay}
            onSelect={onSelect(1)}
          >
            {getOptions(firstDay)}
          </Select>
        </Col>
      </Row>
    </>
  );
}

SemiMonthlyPayrollDates.propTypes = {
  settings: PropTypes.shape({
    semiMonthlyPayPeriodDates: PropTypes.arrayOf(PropTypes.number),
  }),
  saveCompanySettings: PropTypes.func.isRequired,
};

SemiMonthlyPayrollDates.defaultProps = {
  settings: {},
};

export default SemiMonthlyPayrollDates;
