import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

import OnTraccrMap from '../map/OnTraccrMap';
import getGoogleMapKey from '../keys';
import OnTraccrButton from '../buttons/OnTraccrButton';

export default function ProjectListItem({
  id,
  name,
  number,
  address,
  latitude,
  longitude,
  onRemove,
}) {
  const history = useHistory();
  const onClick = useCallback(() => {
    history.push('/projects', { targetId: id });
  }, [id]);
  return (
    <Row
      justify="start"
      align="top"
      className="customer-project-item"
      onClick={onClick}
      gutter={10}
    >
      <Col span={6} style={{ marginRight: 10, pointerEvents: 'none', cursor: 'pointer' }}>
        <OnTraccrMap
          showSearch={false}
          lat={latitude}
          lng={longitude}
          googleMapURL={getGoogleMapKey()}
          loadingElement={<div style={{ height: '100%' }} />}
          containerElement={<div style={{ height: 150, width: 200 }} />}
          mapElement={<div style={{ height: '100%' }} />}
          options={{
            fullscreenControl: false,
          }}
          gestureHandling="none"
        />
      </Col>
      <Col span={14}>
        <Typography.Text
          ellipsis
          className="customer-card-title"
        >
          {name}
        </Typography.Text>
        <Typography.Text
          ellipsis
          className="customer-card-detail"
        >
          {number}
        </Typography.Text>
        <Typography.Text
          ellipsis
          className="customer-card-detail"
        >
          {address}
        </Typography.Text>
      </Col>
      { onRemove && (
        <Col span={3}>
          <OnTraccrButton
            title="Remove"
            icon={<DeleteOutlined />}
            onClick={(e) => {
              e.stopPropagation();
              onRemove(id);
            }}
            type="back"
          />
        </Col>
      )}
      <div className="customer-project-item-divider" />
    </Row>
  );
}

ProjectListItem.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  address: PropTypes.string.isRequired,
  latitude: PropTypes.number.isRequired,
  longitude: PropTypes.number.isRequired,
  onRemove: PropTypes.func,
};

ProjectListItem.defaultProps = {
  onRemove: null,
};
