import React from 'react';
import SearchField from './SearchField';

const supportedTypes = {
  text: true,
  attribute: true,
  calculation: true,
  dropdown: true,
  'yes-no': true,
  table: true,
};

export const isSupported = (selectedType, { dataType } = {}) => (
  supportedTypes[selectedType]
  && !(selectedType === 'dropdown' && dataType === 'Sub-Contracts')
);

export const fieldToSearch = (queries, onQueriesChange, isFieldQueries) => function (field) {
  const { id, configProps, selectedType } = field;

  let fieldQueries = queries;
  if (!isFieldQueries) {
    ({ [id]: fieldQueries = [] } = queries || {});
  }
  if (!isSupported(selectedType, configProps)) return null;
  return (
    <SearchField
      configProps={configProps}
      selectedType={selectedType}
      queries={fieldQueries}
      onQueriesChange={onQueriesChange(id)}
      multi
      buttonStyle={{
        backgroundColor: 'transparent',
      }}
    />
  );
};
