import React from 'react';
import {
  Row,
  Col,
  Input,
  Popover,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

export default function SubtasksHeader({
  onAddClicked,
  onSearch,
  filter,
  filterActive
}) {
  const onSearchChanged = (e) => {
    const {
      target: { value } = {},
    } = e;
    onSearch(value);
  };

  return (
    <Row justify="start" style={{ marginBottom: 14 }} gutter={20}>
      <Col>
        <Input.Search
          type="search"
          style={{ width: 250 }}
          className="searchbar"
          placeholder="Search"
          allowClear
          onChange={onSearchChanged}
        />
      </Col>
      <Col>
        {onAddClicked && (
          <OnTraccrButton
            icon={<PlusOutlined />}
            title="Add"
            onClick={onAddClicked}
          />
        )}
      </Col>
      <Col>
        {filter &&
          <Popover trigger='click' placement='bottomRight' content={filter}>
            <OnTraccrButton title='Filter' type={filterActive ? 'primary' : 'back'}/>
          </Popover>
        }
      </Col>
    </Row>
  );
}
