import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Table } from 'antd';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import CompanyEditRow from '../CompanyEditRow';
import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';

import ClientPortalBoardConfigureDrawer from './ClientPortalBoardConfigureDrawer';

import { getBoards } from '../../boards/state/boards.actions';

import {
  updateClientPortalSettings
} from '../../clientPortal/state/clientPortal.actions';
import DisplayText from '../../common/text/DisplayText';

import colors from '../../constants/Colors';

const onHeaderCell = () => ({
  style:{
    backgroundColor:'white'
  }
});

export default () => {
  const dispatch = useDispatch();
  const boards = useSelector(state => state.boards.boards);
  const portalSettings = useSelector(state => state.clientPortal.settings);

  const {
    showCards,
    boards: boardSettings = [],
  } = portalSettings;

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedBoardSetting,setSelectedBoardSetting] = useState();

  const onCheckChanged = useCallback(() => {
    dispatch(updateClientPortalSettings({
      ...portalSettings,
      showCards: !showCards 
    }))
  },[dispatch, portalSettings, showCards]);


  const onSave = useCallback(async (newData) => {
    const newBoards = selectedBoardSetting
    ? boardSettings.map((bs) => {
      if (bs.boardId !== selectedBoardSetting.boardId) return bs;
      return newData;
    })
    : boardSettings.concat(newData);
    if (await dispatch(updateClientPortalSettings({
      ...portalSettings,
      boards: newBoards,
    })))
    setSelectedBoardSetting();
    setDrawerOpen(false);
  },[selectedBoardSetting, boardSettings, dispatch, portalSettings]);

  const onDelete = useCallback((boardId) => {
    const newBoards = boardSettings.filter((bs) => bs.boardId !== boardId);
    dispatch(updateClientPortalSettings({
      ...portalSettings,
      boards: newBoards,
    }))
  },[boardSettings]);

  const onEditStarted = useCallback((editRecord) => {
    setDrawerOpen(true);
    setSelectedBoardSetting(editRecord);
  },[]);

  const showDrawer = useCallback(() => setDrawerOpen(true),[]);
  const hideDrawer = useCallback(() => {
    setSelectedBoardSetting();
    setDrawerOpen(false);
  },[]);

  useEffect(() => {
    dispatch(getBoards());
  },[dispatch]);

  const columns = useMemo(() => [{
    title: 'Name',
    dataIndex: 'boardId',
    onHeaderCell,
    render: (boardId) => {
      const { [boardId]: { title } = {} } = boards;
      return title;
    },
  },
  {
    dataIndex:'edit',
    align:'edit',
    onHeaderCell,
    width: 60,
    render:(_,record) => (
      <BorderlessButton
        title=''
        icon='edit'
        color={colors.ONTRACCR_BLACK}
        onClick={() => {
          onEditStarted(record)
        }}
        style={{
          paddingRight:8, 
          paddingLeft:0,
        }}
      />
    )
  },
  {
    dataIndex:'action',
    key:'action',
    align:'center',
    width: 60,
    onHeaderCell,
    render:(_,record) => (
      <BorderlessButton
        title=''
        icon='delete'
        color={colors.ONTRACCR_RED}
        onClick={() => {
          onDelete(record.boardId)
        }}
        style={{
          paddingRight:8, 
          paddingLeft:0,
        }}
      />
    ),
  }],[onSave, onDelete, onEditStarted, boards]);


  return (
    <>
      <div style={{ marginTop: 10 }}>
      <SettingsCardHeader title='Boards'>
          {showCards && <OnTraccrButton
            title='Add'
            type='primary'
            onClick={showDrawer}
          />}
        </SettingsCardHeader>
        <CompanySettingsCard>
          <CompanyEditRow
            title='Show Cards'
            helpText='Check this box to allow clients to see their linked board cards'
            style={{ paddingLeft: 10, paddingRight: 20 }}
            divider={showCards}
          >
            <Checkbox checked={showCards} onChange={onCheckChanged}/>
          </CompanyEditRow>
          {showCards && 
            <>
              <DisplayText
                title='Configure how board cards are displayed in the client portal'
                style={{
                  fontFamily: 'roboto-regular',
                  margin: '10px 20px',
                  color: colors.ONTRACCR_OPACITY_GRAY
                }}
              />
              <Table
                columns={columns}
                dataSource={boardSettings}
                pagination={false}
                size='small'
                scroll={{ y: 500, x: 'hidden' }}
              />
            </>
          }
        </CompanySettingsCard>
      </div>
      <ClientPortalBoardConfigureDrawer
        visible={drawerOpen}
        onClose={hideDrawer}
        onSubmit={onSave}
        selectedBoardSetting={selectedBoardSetting}
      />
    </>
  )
}