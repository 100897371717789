import React from 'react';

import PasswordPromptModal from '../common/modals/PasswordPromptModal';

const WARNING_HEADER = 'Deleting a board is permanent.';
const WARNING_SUBTEXT = 'Deleting a board will delete all cards on that board';
export default ({
  visible,
  onClose,
  onDelete,
  title,
}) => (
  <PasswordPromptModal
    visible={visible}
    onClose={onClose}
    title={title}
    warningHeader={WARNING_HEADER}
    warningSubtext={WARNING_SUBTEXT}
    onDelete={onDelete}
  />
);