import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import SimpleTextInputModal from '../../common/modals/SimpleTextInputModal';

import { createUnion } from '../../unions/state/unions.actions';

export default function UnionAddModal({
  visible,
  onClose,
}) {
  const dispatch = useDispatch();

  const onSave = useCallback(async (unionName) => {
    if (await dispatch(createUnion({ name: unionName }))) {
      onClose();
    }
  }, []);

  return (
    <SimpleTextInputModal
      title="Add Union"
      visible={visible}
      placeholder="Enter Union name"
      onClose={onClose}
      onSave={onSave}
    />
  );
}

UnionAddModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
