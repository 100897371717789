/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  TimePicker,
} from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import moment from 'moment';

function OnTraccrTimePicker(props) {
  const {
    date,
    timezone,
    format,
    value,
    onChange,
    minuteIncrement = 1,
  } = props;
  const startOfDay = useMemo(() => (
    DateTime.fromISO(date, { zone: timezone }).startOf('day')
  ), [date, timezone]);

  const momentValue = useMemo(() => {
    if (!value) return value;
    const m = moment(value);
    const zoneValue = DateTime.fromMillis(value, { zone: timezone });
    m.hours(zoneValue.hour);
    m.minutes(zoneValue.minute);
    return m;
  }, [value, timezone]);

  const onTimeChanged = useCallback((momentInstance) => {
    if (!momentInstance) return onChange(null);

    const hour = momentInstance.hours() ?? 0;
    const minute = momentInstance.minutes() ?? 0;
    onChange(
      startOfDay.set({ hour, minute }).toMillis(),
    );
  }, [onChange, startOfDay]);

  useEffect(() => {
    if (!value) return;
    const valueDT = DateTime.fromMillis(value);
    if (valueDT.toSQLDate() !== startOfDay.toSQLDate()) {
      const newDate = startOfDay.set({
        hour: valueDT.hour,
        minute: valueDT.minute,
      });
      onChange(newDate.toMillis());
    }
  }, [onChange, startOfDay, value]);

  return (
    <TimePicker
      {...props}
      format={format}
      showNow={false}
      value={momentValue}
      onChange={onTimeChanged}
      minuteStep={minuteIncrement}
    />
  );
}

OnTraccrTimePicker.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  format: PropTypes.string.isRequired,
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  minuteIncrement: PropTypes.number,
};

OnTraccrTimePicker.defaultProps = {
  timezone: DateTime.local().zoneName,
  value: undefined,
  minuteIncrement: 1,
};

export default OnTraccrTimePicker;
