import React from 'react';
import { Row, Col } from 'antd';
import { HomeOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import analytics from '../../helpers/Analytics';

export default ({ onBack, onEmployeeClick, onAdminClick, style = {}, }) => (
  <div style={{ width: '100%', height:'100%', ...style}}>
    <Row style={{
      position:'absolute',
      top:0,
      left:0,
      right:0,
      bottom:0,
    }} align='middle'>

      <Row style={{ 
        width: '100%',
        flexDirection:'column', 
        color: 'black',
        fontSize:34,
        fontFamily:'raleway-light'
      }} justify='center' align='middle'>

        <div style={{
          width:800,
          textAlign:'center'
        }}>
          Welcome to the world of simplified<br/>field & project management.
          <p style={{ marginTop: 20, }}>Let's get you started.</p>
          <p style={{ fontSize: 20, marginTop: 20, fontFamily:'raleway-semibold' }}>
            Who are you?
          </p>
        </div>
        <Row justify='center' gutter={16}>
          <Col>
            <OnTraccrButton
              title='Employee'
              roundStyle
              style={{ 
                width: 120,
                height: 42,
              }}
              onClick={onEmployeeClick}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title='Admin'
              roundStyle
              style={{ 
                width: 120,
                height: 42,
              }}
              onClick={onAdminClick}
            />
          </Col>
        </Row>
      </Row>
    </Row>
    <Row style={{ width: '100%', position:'absolute', top: 40}} justify='center'>
      <Row style={{ width: '100%', }} justify='center'>
        <BorderlessButton
          style={{ width: 100, backgroundColor: 'inherit' }}
          iconNode={<HomeOutlined style={{
            fontSize:24
          }}/>}
          onClick={onBack}
        />
      </Row>
      <Row style={{ width: '100%' }} justify='center'>
        <BorderlessButton
          style={{ width: 100, backgroundColor: 'inherit' }}
          title='Back to Login'
          onClick={onBack}
        />
      </Row>
    </Row>
    <Row style={{ width: '100%', position:'absolute', bottom: 100 }} justify='center'>
      <BorderlessButton
        style={{ width: 100, backgroundColor: 'inherit' }}
        title={
          <Row justify='center' style={{ fontSize: 20 }}>
            Learn more at <span style={{ 
              marginTop: 10,
              fontSize: 20, fontFamily:'roboto-medium'
            }}>OnTraccr.com</span>
          </Row>
        }
        onClick={() => {
          analytics.track('GetStarted/LearnMore');
          window.open('https://www.ontraccr.com');
        }}
      />
    </Row>
  </div>
);