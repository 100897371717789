import React from 'react';

import { PropTypes } from 'prop-types';

export default function Badge({ count, style }) {
  return (
    <div style={{
      backgroundColor: '#BF381D',
      borderRadius: '12px',
      color: 'white',
      font: 'inherit',
      height: '20px',
      minWidth: '20px',
      fontSize: '11px',
      marginLeft: '6px',
      textAlign: 'center',
      paddingTop: '2px',
      paddingRight: '1px',
      paddingBottom: '1px',
      display: 'inline-block',
      ...style,
    }}
    >
      {count}
    </div>
  );
}

Badge.propTypes = {
  count: PropTypes.number.isRequired,
  style: PropTypes.shape({}),
};

Badge.defaultProps = {
  style: {},
};
