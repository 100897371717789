import React from 'react';
import PropTypes from 'prop-types';
import {
  Row, Checkbox, Col,
} from 'antd';

import HoverHelp from '../../../common/HoverHelp';

function DateTimeSelectionCheckbox({
  setHideDate,
  setHideTime,
  setUse24HourTime,
  configProps,
}) {
  const {
    hideDate,
    hideTime,
    use24HourTime,
  } = configProps;

  return (
    <>
      <Row style={{ marginTop: 20 }} gutter={10}>
        <Col>
          Date Time Options:
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={(
              <div style={{ width: 250 }}>
                Toggle which parts of the Date/Time you would like to hide from the user
              </div>
            )}
          />
        </Col>
      </Row>
      <Row
        style={{ marginTop: 5 }}
        gutter={10}
      >
        <Col>
          <Checkbox
            onChange={setHideDate}
            checked={hideDate}
            style={{ width: '100%' }}
          >
            Hide Date
          </Checkbox>
        </Col>
        <Col>
          <Checkbox
            onChange={setHideTime}
            checked={hideTime}
            style={{ width: '100%' }}
          >
            Hide Time
          </Checkbox>
        </Col>
        <Col>
          <Checkbox
            onChange={setUse24HourTime}
            checked={use24HourTime}
            style={{ width: '100%' }}
            disabled={hideTime}
          >
            Use 24 Hour Format
          </Checkbox>
        </Col>
      </Row>
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
DateTimeSelectionCheckbox.propTypes = {
  setHideDate: PropTypes.func.isRequired,
  setHideTime: PropTypes.func.isRequired,
  setUse24HourTime: PropTypes.func.isRequired,
  configProps: PropTypes.object,
};

DateTimeSelectionCheckbox.defaultProps = {
  configProps: {},
};

export default DateTimeSelectionCheckbox;
