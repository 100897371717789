import React, {
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import DynamicSearchWithDropdown from '../common/inputs/DynamicSearchWithDropdown';

import BoardSearchDropdownItem from './BoardSearchDropdownItem';

import { setBoardTargetCard } from './state/boards.actions';

function BoardSearchDropdown({
  onBoardSelect,
}) {
  const dispatch = useDispatch();

  const onCardClick = useCallback(async ({
    id: cardId,
    boardId,
    statusId,
  } = {}) => {
    if (!boardId) return Promise.resolve(false);
    return new Promise((resolve) => {
      setTimeout(() => {
        onBoardSelect(boardId);
        dispatch(setBoardTargetCard({ cardId, statusId, boardId }));
        resolve(true);
      }, 250);
    });
  }, [onBoardSelect]);

  return (
    <DynamicSearchWithDropdown
      path="/boards/search"
      onItemClick={onCardClick}
      Item={BoardSearchDropdownItem}
    />
  );
}

BoardSearchDropdown.propTypes = {
  onBoardSelect: PropTypes.func.isRequired,
};

export default BoardSearchDropdown;
