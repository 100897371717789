import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Collapse, Popover, Table } from 'antd';
import PropTypes from 'prop-types';

import AutoImport from './IntegrationAutoImport';
import CanImportCol from './IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from './IntegrationIsConnectedCol';
import { createImportData, linkInitialData } from './integrationHelpers';

import sortByString, { includesTerm } from '../../helpers/helpers';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';

const { Panel } = Collapse;

const canImport = (divisionList, contactNameSet) => (record) => (
  record.name
  && !contactNameSet.has(record.name)
  && (record.linkValue || record?.divisionIds?.length > 0 || divisionList?.length === 1)
);

export default function IntegrationCustomerVendorSync({
  integrationValues,
  title,
  integrationKey,
  onValuesChanged,
  autoImportContent,
  importHelpText,
  importValues,
  divisionId,
  divisionFilter,
  isVendor,
}) {
  const customers = useSelector((state) => state.customers.customers);
  const vendors = useSelector((state) => state.vendors.vendors);
  const divisions = useSelector((state) => state.settings.divisions);

  const [stateValues, setStateValues] = useState([]);
  const [activePanels, setActivePanels] = useState(['qb']);
  const [searchStr, setSearchStr] = useState();
  const [importList, setImportList] = useState(new Set());
  const [autoImport, setAutoImport] = useState(true);
  const [connected, setConnected] = useState({});

  const divSet = useMemo(() => new Set(Object.values(divisions).map((div) => div.id)), [divisions]);

  const contactNameSet = useMemo(() => {
    if (isVendor) return (new Set(Object?.values(vendors)?.map((vendor) => vendor.name)));
    return (new Set(Object?.values(customers)?.map((customer) => customer.name)));
  }, [isVendor, customers, vendors]);

  const activeValues = useMemo(() => {
    const values = isVendor ? vendors : customers;

    return Object.values(values).filter((val) => (
      val.active
      && val?.divisionIds?.some((divId) => divSet.has(divId))
      && (!divisionFilter || val?.divisionIds?.some((divId) => divisionFilter.has(divId)))
    ));
  }, [
    vendors,
    customers,
    isVendor,
    divisionFilter,
    divSet,
  ]);

  const divisionList = useMemo(() => (
    Object.values(divisions)
      .filter((division) => !divisionFilter || divisionFilter.has(division.id))
  ), [divisions, divisionFilter]);

  const updateValues = (record) => {
    setStateValues(stateValues.map((val) => {
      if (val[integrationKey] !== record[integrationKey]) return val;
      return record;
    }));
  };

  const resetImportList = (list) => () => {
    setImportList(new Set(list.map((cc) => cc[integrationKey])));
  };

  useEffect(() => {
    setConnected(createConnectedArray(activeValues, integrationKey));
  }, [activeValues, integrationKey]);

  useEffect(() => {
    if (integrationValues?.length) {
      setStateValues(linkInitialData({
        items: integrationValues, connected, integrationKey,
      }));
    }
    resetImportList(integrationValues ?? [])();
  }, [integrationValues, connected]);

  useEffect(() => {
    const importData = createImportData({
      integrationKey,
      newData: integrationValues ?? [],
      stateData: stateValues,
      originalData: activeValues,
      importList,
      canImport: canImport(divisionList, contactNameSet),
      connected,
      divisionList,
      isCustomerOrVendor: true,
    });
    onValuesChanged(importData);
  }, [
    autoImport,
    importList,
    stateValues,
    activeValues,
    onValuesChanged,
    integrationValues,
    connected,
    integrationKey,
    divisionList,
    contactNameSet,
  ]);

  const getCols = (editable = false) => {
    const cols = [
      IsConnectedCol({
        integrationKey,
        title,
        connected,
        onUnLink: (record) => {
          const newConnected = { ...connected };
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if (editable) {
            record.linkValue = null;
            updateValues(record);
          } else {
            setStateValues(stateValues.map((val) => {
              if (val[integrationKey] !== record[integrationKey]) return val;
              const newVal = { ...val };
              delete newVal.linkValue;
              return newVal;
            }));
          }
        },
      }),
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: sortByString('name'),
        showSorterTooltip: true,
        sortDirections: ['descend', 'ascend'],
        render: (name, record) => {
          const invalid = contactNameSet.has(name) && !record.linkValue && editable;
          return (
            invalid ? (
              <Popover content="Name must be unique in Ontraccr">
                <div style={{ color: 'red' }}>
                  {name}
                </div>
              </Popover>
            ) : name
          );
        },
      },
      {
        title: 'Division',
        dataIndex: 'divisionId',
        key: 'divisionId',
        align: 'center',
        fixed: 'left',
        width: 400,
        render: (_, record) => {
          const {
            viewType,
            [integrationKey]: integrationId,
            linkValue: recordLink,
            divisionIds: recordDivs = [],
          } = record;
          const isLink = editable && viewType === 'link';
          const otID = isLink ? recordLink : integrationId;
          const ourValues = isVendor ? vendors : customers;
          const {
            [otID]: {
              divisionIds: ourDivisionsIds = [],
            } = {},
          } = ourValues;
          const editableDivs = isLink ? ourDivisionsIds : recordDivs;
          let finalDivs = editable ? editableDivs : ourDivisionsIds;
          if (divisionList.length === 1) finalDivs = divisionList.map((d) => d.id);
          return (
            <DivisionSelector
              key={integrationId}
              mode="multiple"
              displayMode={!editable}
              style={{ width: '100%' }}
              displayStyle={{ marginBottom: 0 }}
              onChange={(newDivisions) => {
                record.divisionIds = newDivisions;
                updateValues(record);
              }}
              disabled={divisionId || isLink}
              filter={(divisionId || isLink) ? null : divisionFilter}
              divisions={finalDivs}
            />
          );
        },
      },
    ];

    if (editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          connected,
          helpText: importHelpText,
          canImport: canImport(divisionList, contactNameSet),
          importList,
          setImportList,
          resetImportList: resetImportList(integrationValues),
          onViewTypeChanged: (record, viewType) => {
            record.viewType = viewType;
            updateValues(record);
          },
          linkOptions: activeValues,
          onLinkChanged: (record, linkValue) => {
            const newConnected = { ...connected };
            const oldLink = connected[record[integrationKey]];
            if (oldLink) delete oldLink[integrationKey];
            if (linkValue) {
              const ourValues = isVendor ? vendors : customers;
              newConnected[record[integrationKey]] = ourValues[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
            } else {
              delete newConnected[record[integrationKey]];
            }

            setConnected(newConnected);

            record.linkValue = linkValue;
            updateValues(record);
          },
        }),
      );
    }
    return cols;
  };

  const activeStateValues = useMemo(() => (
    stateValues.filter((val) => !searchStr || includesTerm(val?.name, searchStr))
  ), [stateValues, searchStr]);

  return (
    <div style={{ height: 'calc(100% - 55px)', width: '100%', overflowY: 'scroll' }}>
      <AutoImport
        setActivePanels={setActivePanels}
        setAutoImport={setAutoImport}
        autoImport={autoImport}
        helpContent={autoImportContent}
        importType={isVendor ? 'Vendor' : 'Customer'}
        importData={importValues}
      />
      {!autoImport && (
      <OnTraccrTextInput
        allowClear
        style={{
          width: 250,
        }}
        search
        onChange={(e) => {
          const {
            target: {
              value,
            } = {},
          } = e;
          setSearchStr(value);
        }}
        onClick={(e) => e.stopPropagation()}
      />
      )}
      {!autoImport && (
      <Collapse ghost activeKey={activePanels} onChange={setActivePanels}>
        <Panel
          header={`New ${title} ${isVendor ? 'Vendors' : 'Customers'}`}
          key="new"
          className="ontraccr-collapse-panel"
        >
          <Table
            bordered
            size="small"
            columns={getCols(true)}
            dataSource={activeStateValues}
            scroll={{
              y: '40%',
              x: 1000,
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              showSizeChanger: false,
            }}
            rowKey={integrationKey}
          />
        </Panel>
        <Panel
          header={`Ontraccr ${isVendor ? 'Vendors' : 'Customers'}`}
          key="ontraccr"
          className="ontraccr-collapse-panel"
        >
          <Table
            bordered
            size="small"
            columns={getCols(false)}
            dataSource={activeValues}
            scroll={{
              y: '40%',
            }}
            pagination={{
              hideOnSinglePage: true,
              pageSize: 20,
              showSizeChanger: false,
            }}
            rowKey="id"
          />
        </Panel>
      </Collapse>
      )}
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
IntegrationCustomerVendorSync.propTypes = {
  integrationValues: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  integrationKey: PropTypes.string.isRequired,
  onValuesChanged: PropTypes.func.isRequired,
  autoImportContent: PropTypes.node.isRequired,
  importHelpText: PropTypes.node.isRequired,
  importValues: PropTypes.array.isRequired,
  divisionFilter: PropTypes.instanceOf(Set),
  isVendor: PropTypes.bool,
  divisionId: PropTypes.string,
};

IntegrationCustomerVendorSync.defaultProps = {
  divisionFilter: null,
  isVendor: false,
  divisionId: null,
};
