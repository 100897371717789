import React from 'react';
import { connect } from 'react-redux';
import '@ant-design/compatible/assets/index.css';
import { Drawer } from 'antd';
import { FormInstance } from 'antd/lib/form';
import OnTraccrButton from './buttons/OnTraccrButton';

import {
  finishNuxAction
} from '../nux/state/nux.actions';


function hasErrors(fieldsError = []) {
  let errCount = 0;
  fieldsError.forEach(({errors = []}) => errCount += errors.length);
  return errCount > 0;
}

const titleByType = ({
  isDisplay,
  isLastStep,
}) => {
  if(isDisplay) return 'Edit';
  return isLastStep ? 'Submit' : 'Next';
};

const shouldShowButton = ({ isDisplay, onEditStarted }) => isDisplay ? onEditStarted : true;

export default connect(
  (state,ownProps) => ownProps,
  {
    finishNuxAction,
  }
)(
  class AddDrawer extends React.Component {
    constructor(props) {
      super(props);
      const {
        numberOfSteps = 1,
        formProps: {
          currentStep = 0,
        } = {},
      } = this.props;
      this.state = {
        currentStep,
        numberOfSteps,
        actionButtonEnabled:true,
        loading: false,
      };

      this.formRef = React.createRef(FormInstance);
      this.onBackClicked = this.goBack.bind(this);
    }

    componentDidUpdate(prevProps) {
      const {
        formProps = {},
        visible,
        numberOfSteps = 1,
      } = this.props;
      const { editing } = formProps;

      const {
        formProps:prevForm = {},
        visible:prevVisible,
      } = prevProps;
      const { editing:prevEditing } = prevForm;

      if(editing !== prevEditing && this.state.open) {
        this.setState({
          lastState: prevEditing ? 'editing' : 'display',
        });
      }
      if(visible && !prevVisible) {
        // Drawer is opening
        this.setState({
          numberOfSteps,
          open:true,
        });
      }

      if(!visible && prevVisible) {
        // Drawer is opening
        this.setState({
          open:false,
        });
      }
    }

    async onClose(values, submitted) {
      this.setState({
        loading: true,
      });
      if(await this.props.onClose(values, submitted)) {
        if (this.formRef.current) {
          this.formRef.current.resetFields();
        }
        this.setState({
          lastState:undefined,
        });
        setTimeout(() => this.setState({
          currentStep:0,
        }),500);
      }
      this.setState({
        loading: false,
      });
    }

    numberOfStepsChanged(numberOfSteps) {
      const {
        currentStep,
      } = this.state;
      this.setState({
        numberOfSteps,
      });
      if(numberOfSteps === 1) {
        this.setState({
          currentStep:0,
        });
      }
      if(currentStep >= numberOfSteps) {
        this.setState({
          currentStep: numberOfSteps - 1,
        });
      }
    }

    validateForm() {
      if(this.props.ignoreValidation) return {};
      const getData = this.state.customValidator || this.formRef.current.getFieldsValue;
      const values = getData();
      return values;
    }

    async goBack() {
      const {
        isAdd = false,
        formProps: {
          editing,
        } = {},
        validateSteps = [],
      } = this.props;
      const { currentStep, numberOfSteps } = this.state;
      const isDisplay = !editing && !isAdd;
      const ourSteps = validateSteps[currentStep];
      const dontClear = (
        isDisplay
          || numberOfSteps <= 1
          || !ourSteps
          || !this.formRef?.current
      );
      if (!dontClear) {
        /*
          If there is a validation error on the second step
          and we go back to the first the next button will be blocked
          need to clear validation on the current step before going back
        */
        const stepSet = new Set(ourSteps);
        const values = await this.formRef.current.getFieldsValue();
        const newValues = Object.keys(values ?? {}).map((key) => ({
          name: key,
          value: values[key],
          errors: [],
          touched: !stepSet.has(key),
        }));
        await this.formRef.current.setFields(newValues);
      }
      this.setState({
        currentStep: currentStep - 1,
        actionButtonEnabled: true,
      });
    }

  render() {
    const {
      formProps = {},
      isAdd = false,
      onEditStarted = () => {},
      onEditCanceled = () => {},
      onArchive,
      onDelete,
      finishNuxAction,
      maskClosable = false,
      push,
      viewOnly = false,
      validateSteps = [],
      customValidator: propCustomValidator,
    } = this.props;
    const { loading } = this.state;
    const { editing, active } = formProps;
    const isDisplay = !editing && !isAdd;

    const getFieldsError = this.formRef.current ? this.formRef.current.getFieldsError : () => [];

    const isLastStep = this.state.currentStep === this.state.numberOfSteps - 1;
    const formView = (this.props.formView) ?
    this.props.formView({
      formRef:this.formRef,
      currentStep:this.state.currentStep,
      numberOfSteps: this.state.numberOfSteps,
      numberOfStepsChanged:this.numberOfStepsChanged.bind(this),
      actionButtonEnabled:(actionButtonEnabled) => {
        this.setState({
          actionButtonEnabled,
        });
      },
      setCustomValidator:(customValidator) => {
        this.setState({
          customValidator,
        });
      },
      currentStepChanged:(currentStep) => {
        this.setState({
          currentStep,
        });
      },
      ...this.props.form,
      isAdd,
      visible:this.props.visible,
    },formProps) : '';

    const title = (
      <div style={{
        fontFamily:'roboto-regular'
      }}>{this.props.title}</div>
    );

    const goToNextStep = async() => {
      if(isDisplay) return onEditStarted();
      const curStep = this.state.currentStep || 0;
      const validationFields = validateSteps[curStep];
      try {
        if(!this.props.ignoreValidation) {
          await this.formRef.current.validateFields(validationFields);
          if (propCustomValidator) {
            const values = this.formRef.current.getFieldsValue();
            propCustomValidator(curStep, values);
          }
        }
        if(isLastStep) {
          this.onClose(this.validateForm(), true);
        } else {
          this.setState({
            currentStep: (curStep) + 1,
          });
        }
      } catch (err) {
        console.log(err)
      }
    }

    const showRightButton = shouldShowButton({ isDisplay, onEditStarted });
    return (<Drawer
      title={title}
      width={this.props.width}
      onClose={() => {
        finishNuxAction();
        if(this.formRef && this.formRef.current) this.formRef.current.resetFields();
        this.onClose();
      }}
      visible={this.props.visible}
      maskClosable={maskClosable}
      push={push}
      className={this.props.className}
      >
      {formView}

      {((!isAdd && onArchive) || showRightButton) && <div
        style={{
          position: 'absolute',
          left: 0,
          bottom: 0,
          width: '100%',
          borderTop: '1px solid #e9e9e9',
          padding: '10px 16px',
          background: '#fff',
          textAlign: 'right',
        }}
      >
        {!isAdd && onArchive && <div
          style={{
            position:'absolute',
            left: 0,
            bottom:0,
            padding: '10px 16px',
          }}
        >
          <OnTraccrButton
            title={active ? 'Archive' : 'Activate'}
            type='back'
            onClick={async () => {
              if(await onArchive(formProps)) {
                this.onClose();
              }
            }}
          />
        </div>}

        {!isAdd && onDelete && !active && <div
          style={{
            position:'absolute',
            left: onArchive ? 90 : 0,
            bottom:0,
            padding: '10px 16px',
          }}
        >
          <OnTraccrButton
            title={'Delete'}
            type='back'
            onClick={async () => {
              if(await onDelete(formProps)) {
                this.onClose();
              }
            }}
          />
        </div>}

        {!isDisplay && <OnTraccrButton
          title='Cancel'
          type='cancel'
          onClick={() => {
            if(this.state.lastState === 'display') return onEditCanceled();
            if(this.formRef && this.formRef.current) this.formRef.current.resetFields();
            this.onClose();
          }}
          style={{ marginRight: 8 }}
        />}

        {this.state.currentStep > 0 && !isDisplay && <OnTraccrButton
          type='back'
          title='Back'
          onClick={this.onBackClicked}
          style={{ marginRight: 8 }}
        /> }
        {showRightButton && !viewOnly &&
          <OnTraccrButton
            title={titleByType({ isLastStep, isDisplay })}
            loading={loading}
            onClick={goToNextStep}
            type="primary"
            disabled={hasErrors(getFieldsError()) || !this.state.actionButtonEnabled}
          />}
      </div>}
    </Drawer>);
    }
});
