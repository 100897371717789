/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import {
  Row, DatePicker, TimePicker, Col,
} from 'antd';
import { DateTime } from 'luxon';
import { PropTypes } from 'prop-types';
import { localToZoneTS } from '../../../../clock/ManualEntry/manualEntryHelpers';
import { DATE_FORMAT, TIME_FORMAT } from './TimeEntryTable.constants';
import { luxonToMoment } from './TimeEntryTable.helpers';

function TimeEntryTableDateTimeInput(props) {
  const {
    configProps = {},
    timezone = DateTime.local().zoneName,
    value,
    onChange,
  } = props;

  const {
    hideDate = false,
    hideTime = false,
  } = configProps;

  const {
    date = null,
    time = null,
  } = value;

  const luxonDate = date ? DateTime.fromMillis(date) : DateTime.local().set({ hour: 0, minute: 0 });
  const luxonTime = time ? DateTime.fromMillis(time) : DateTime.local().set({ hour: 0, minute: 0 });

  const onDateChanged = useCallback((key) => (newDateMoment) => {
    const newDate = newDateMoment
      ? localToZoneTS(newDateMoment.valueOf(), timezone)
      : null;

    onChange({
      ...(value ?? {}),
      [key]: newDate,
    });
  }, [onChange]);

  return (
    <Row
      gutter={5}
    >
      {!hideDate
        && (
          <Col span={12}>
            <DatePicker
              {...(hideTime && props)}
              style={{
                width: '100%',
              }}
              onChange={onDateChanged('date')}
              value={date ? luxonToMoment(luxonDate) : undefined}
              format={DATE_FORMAT}
            />
          </Col>
        )}

      {!hideTime
        && (
          <Col span={12}>
            <TimePicker
              {...(hideDate && props)}
              style={{
                width: '100%',
              }}
              onChange={onDateChanged('time')}
              value={time ? luxonToMoment(luxonTime) : undefined}
              format={TIME_FORMAT}
              use12Hours
            />
          </Col>
        )}
    </Row>
  );
}

TimeEntryTableDateTimeInput.propTypes = {
  configProps: PropTypes.shape({}),
  timezone: PropTypes.string,
  value: PropTypes.shape({
    date: PropTypes.number,
    time: PropTypes.number,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
};

TimeEntryTableDateTimeInput.defaultProps = {
  configProps: {},
  timezone: DateTime.local().zoneName,
  style: {},
};

export default TimeEntryTableDateTimeInput;
