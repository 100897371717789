export default {};

export const defaultFilters = {
  active: [1],
  categoryId: [1, 2, 3, 4],
};

export const filterData = [
  [
    {
      title: 'Archive',
      key: 'active',
      children: [
        {
          title: 'Active',
          key: 1,
        },
        {
          title: 'Archived',
          key: 0,
        },
      ],
    },
  ],
  [
    {
      title: 'Category',
      key: 'categoryId',
      children: [
        {
          title: 'Labor',
          key: 1,
        },
        {
          title: 'Material',
          key: 2,
        },
        {
          title: 'Overhead',
          key: 3,
        },
        {
          title: 'Equipment',
          key: 4,
        },
      ],
    },
  ],
];
