import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

function BoardCardShadowText({
  boardId,
  inDrawer,
  style,
}) {
  const boards = useSelector((state) => state.boards.boards);
  const {
    [boardId]: {
      title,
    } = {},
  } = boards ?? {};
  const hasAccessToTargetBoard = boardId in boards;
  const boardText = hasAccessToTargetBoard
    ? `the ${title}`
    : 'a different';
  const click = inDrawer ? 'here' : 'view';
  const suffix = hasAccessToTargetBoard
    ? ` Click ${click} to open the card in the other board`
    : '';
  const text = `This card is in ${boardText} board.${suffix}`;
  return (
    <div style={style}>
      {text}
    </div>
  );
}

BoardCardShadowText.propTypes = {
  boardId: PropTypes.string.isRequired,
  inDrawer: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

BoardCardShadowText.defaultProps = {
  inDrawer: false,
  style: {},
};

export default BoardCardShadowText;
