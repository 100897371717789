import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';

import { PageHeader, Spin, } from 'antd';

import Logo from '../common/Logo';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

const redirect = (history) => {
  history.replace('/login');
  return <></>;
};

const parsePath = (pathname = '') => {
  const path = pathname.split('/');
  if(path.length < 1) return { shouldRedirect: true };
  const type = path.pop();
  if(type !== 'subscribe' && type !== 'unsubscribe') return { shouldRedirect: true };
  const id = path.pop();
  if(id.length !== 36) return { shouldRedirect: true };
  return { id, type };
};

export default ({
  location: {
    pathname = '',
  } = {},
  history,
}) => {
  const [loading,setLoading] = useState(true);
  useEffect(() => {
    const {
      id,
      type,
      shouldRedirect
    } = parsePath(pathname);
    if(shouldRedirect) return;
    const updateSub = async (id, type) => {
      setLoading(true);
      await axios.put(`/emails/${id}/${type}`);
      setLoading(false);
    };
    updateSub(id,type);
  },[pathname]);

  const {
    type,
    shouldRedirect
  } = parsePath(pathname);
  const isSubscribe = type === 'subscribe';
  const text = isSubscribe ? 'subscribed to' : 'unsubscribed from'
  const title = `Successfully ${text} updates from Ontraccr`;

  const body = useMemo(() => {
    if(loading) {
      return (
        <div id='email-loading'>
          <Spin/>
        </div>
      )
    }
    if(isSubscribe) {
      return (
        <div
          id='email-unsub-text'
          onClick={() => history.replace(pathname.replace('subscribe','unsubscribe'))}
        >
            Click here to unsubscribe.
        </div>
      );
    }
    return (
      <div id='email-sub-button'>
        <OnTraccrButton
          title='Subscribe'
          roundStyle
          onClick={() => history.replace(pathname.replace('unsubscribe','subscribe'))}
        />
      </div>
    )
  },[loading,isSubscribe, history, pathname]);

  if(shouldRedirect) return redirect(history);
  return (
    <div id='email-container'>
      <div id='email-logo-container'>
        <Logo />
      </div>
      <PageHeader
        className='register-back email-page-header'
        onBack={() => {
          history.push('/login')
        }}
        title='Back to Login'
      />
      {!loading && <div id='email-header'>{title}</div>}
      {body}
    </div>
  );
}