import React, { useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Typography, Row, Card, Col } from 'antd';
import { DateTime } from 'luxon';
import { withRouter, } from 'react-router-dom';

import DashboardComponent from './DashboardComponent';

import DashboardUserList from './DashboardUserList';

import { getActiveMember } from '../helpers/users';
import { mergeSets } from '../helpers/helpers'

import color from '../constants/Colors';

const getApprovalMap = (approvals) => {
  const map = {};
  approvals.forEach((approval) => {
    if(!(approval.userId in map)) {
      map[approval.userId] = [];
    }
    map[approval.userId].push(approval);
  });
  return map;
}

const getStat = ({
  columns = [],
  onClick,
}) => (
  <Card onClick={onClick} hoverable={onClick} style={{border:'none', width:'100%', margin:10 }} bodyStyle={{padding:0}}>
    <Row style={{width:'100%'}} justify='space-around' align='middle'>
      {columns.map(({ title, value, onClick }) => (
        <Col key={title} span={24/columns.length}>
          <Card onClick={onClick} hoverable={onClick} style={{border:'none',margin:0,}} bodyStyle={{padding: 10}}>
            <Row style={{marginBottom:0,}}  type='flex' justify='center'>
              <Typography.Text style={{
                fontFamily: 'roboto-regular', 
                color:color.ONTRACCR_GRAY,
                textAlign:'center'
              }}>
                {title}
              </Typography.Text>
            </Row>
            <Row type='flex' justify='center'>
              <Typography.Text style={{
                fontFamily: 'raleway-semibold', 
                fontSize:30,
                color:color.ONTRACCR_DARK_YELLOW,
                lineHeight:'30px',
              }}>
                {value}
              </Typography.Text>
            </Row>
          </Card>
        </Col>
      ))}
    </Row>
  </Card>
);


export default withRouter(({
  users = [],
  onUserClick,
  approvals = [],
  history,
  onDelete,
  isEdit,
}) => {
  const [showUserList,setShowUserList] = useState();

  const divisions = useSelector(state => state.settings.divisions);
  const selectedDivisions = useSelector(state => state.settings.selectedDivisions);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

  const divisionUsers = useMemo(() => (
    mergeSets(
      Object.values(divisions)
      .filter(({ id:divisionId }) => selectedDivisions.has(divisionId))
      .map(({ users }) => users)
    )
  ), [selectedDivisions, divisions]);

  const approvalMap = getApprovalMap(approvals);
  
  let activeUserCount = 0;
  let inactiveUserCount = 0;
  let pendingTimeCards = 0;
  let submittedTimecards = 0;
  let approvedTimecards = 0;

  const twoWeeksAgo = DateTime.local().minus({ weeks:2 });
  
  const relevantUsers = useMemo(() => (
    users.filter((user) => user.active && divisionUsers.has(user.id))
  ),[users,divisionUsers]);
  
  relevantUsers.forEach((user) => {
    if(!user.active) return;
    if(getActiveMember({ user, timeEntryUserMap })) {
      activeUserCount += 1;
    } else {
      inactiveUserCount += 1;
    }
    if(user.id in approvalMap) {
      submittedTimecards += 1;
    } else {
      const tasks = timeEntryUserMap[user?.id] ?? [];
      const recentTask = tasks.filter((task) => {
        const ts = task.endTime || task.startTime || 0;
        return ts > twoWeeksAgo.toMillis();
      });
      if(recentTask.length === 0) return;
      const approved = recentTask.some((task) => task.state === 'approved');
      if(approved.length > 0) {
        approvedTimecards += 1;
      } else {
        pendingTimeCards += 1;
      }
    }
  });

  return (

    <DashboardComponent
      title='Quick Stats'
      onDelete={onDelete}
      isEdit={isEdit}
    >
      <Row 
        type='flex'
        align='middle'
        style={{
          width:'100%',
          paddingBottom:40,
        }}
      >
        <Row style={{width:'100%'}} type='flex' justify='space-around'>
          {getStat({
            columns:[{
              title:'Total Active Users',
              value:activeUserCount,
            },{
              title:'Total Inactive Users',
              value:inactiveUserCount,
            }],
            onClick:() => setShowUserList(true),
          })}
        </Row>

        <Row style={{width:'100%',marginTop:20,}} type='flex' justify='space-around'>
          {getStat({
            columns:[{
              title:'Pending Time Cards',
              value:pendingTimeCards,
            },{
              title:'Submitted Time Cards',
              value:submittedTimecards,
            },{
              title:'Approved Time Cards',
              value:approvedTimecards,
            }],
            onClick:() => history.push('/timecards/team'),
          })}
        </Row>
        <DashboardUserList
          visible={showUserList}
          onClose={() => setShowUserList(false)}
          onUserClick={onUserClick}
          users={relevantUsers}
        />
      </Row>
    </DashboardComponent>
  );
});
