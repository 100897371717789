import {
  PROGRESS_ROW_TYPE_MATERIAL_SPEND,
  PROGRESS_ROW_TYPE_SPEND,
  PROGRESS_ROW_TYPE_HOURS,
  PROGRESS_ROW_TYPE_LABOR_COST,
} from './progressConstants';
import {
  PROGRESS_UPDATE_TYPE_NOTES,
  PROGRESS_UPDATE_TYPE_QUANTITY,
  PROGRESS_UPDATE_TYPE_CURRENCY,
  PROGRESS_UPDATE_TYPE_ACTIVITY,
  PROGRESS_UPDATE_FIELD_NOTES,
  PROGRESS_UPDATE_FIELD_QUANTITY,
  PROGRESS_UPDATE_FIELD_CURRENCY,
  PROGRESS_UPDATE_FIELD_ACTIVITY,
  PROGRESS_UPDATE_TYPE_LOGGED_HOURS,
  PROGRESS_UPDATE_FIELD_LOGGED_HOURS,
  PROGRESS_UPDATE_TYPE_INVOICE_ADD,
  PROGRESS_UPDATE_TYPE_INVOICE_UPDATE,
  PROGRESS_UPDATE_TYPE_INVOICE_DELETE,
  PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD,
} from '../history/historyConstants';
import { getPhaseCostcodeKey } from '../../forms/formHelpers';
import { isNullOrUndefined } from '../../helpers/helpers';

export default {};

export const getProgressKey = ({
  projectId = null, phaseId = null, costcodeId = null, formId = null,
}) => `${projectId}.${phaseId}.${costcodeId}.${formId}`;

const getProgress = ({
  state,
  projectId,
  phaseId,
  costcodeId,
  formId,
}) => {
  const key = getProgressKey({
    projectId, phaseId, costcodeId, formId,
  });
  const {
    [key]: progressArray = [],
  } = state;
  return progressArray;
};

const getProgressTasksArray = ({
  state = {},
  phaseId,
  costcodeId,
}) => {
  const phaseCostcodeKey = getPhaseCostcodeKey(phaseId, costcodeId);
  const {
    [phaseCostcodeKey]: {
      tasks: progressTasksArray = [],
    } = {},
  } = state;
  return progressTasksArray;
};

const getLatestProgress = ({
  state,
  projectId,
  phaseId,
  costcodeId,
  formId,
}) => {
  const progressArray = getProgress({
    state,
    projectId,
    phaseId,
    costcodeId,
    formId,
  });
  const { length } = progressArray;
  const {
    progress = 0,
    quantityUsed = 0,
    actualSpend = 0,
  } = progressArray[length - 1] || {};
  return {
    progress: parseInt(progress * 100, 10),
    quantityUsed,
    actualSpend,
  };
};

export const getProgressFromState = ({
  progressState = {},
  taskState = {},
  type,
  id,
  projectId,
  phaseId,
  costcodeId,
  formId,
  children = [],
}) => {
  // Get Progress:
  const progressArray = getProgress({
    state: progressState, id, projectId, phaseId, costcodeId, formId,
  });

  // Get Logged Hours (tasks):
  const progressTasksArray = getProgressTasksArray({
    state: taskState, phaseId, costcodeId,
  });

  const {
    progress: latestProgressUpdate,
    actualSpend,
    quantityUsed,
  } = getLatestProgress({
    state: progressState,
    type,
    id,
    projectId,
    phaseId,
    costcodeId,
    formId,
  });
  let fullChildrenHistory = [];
  if (children.length > 0) {
    children.forEach((child) => {
      const {
        progressHistory: childHistory,
      } = getProgressFromState({
        ...child,
        progressState,
        taskState,
      });
      fullChildrenHistory = fullChildrenHistory.concat(childHistory);
    });
  }

  const fullHistory = progressArray.concat(progressTasksArray, fullChildrenHistory);
  fullHistory.sort((a, b) => b.timestamp - a.timestamp);
  return {
    progressHistory: fullHistory,
    latestProgressUpdate: parseInt(latestProgressUpdate, 10),
    quantityUsed,
    actualSpend,
  };
};

/**
 * Modifies progress history entry details depending on transaction type (spend or quantity):
 * @returns {object}
 */
export const getProgressHistoryEntryDiffValues = ({
  duration = 0,
  type: progressType = '',
  diffSpend = 0,
  diffQuantity = 0,
  lastProgress,
  progress,
  diff,
  actualSpend,
  lastSpend,
  quantityUsed,
  lastQuantity,
  timestamp = 0,
  device,
  files = [],
  noteDescription = '',
  filteredValues,
}) => {
  const isSpendUpdate = diffSpend !== 0;
  const isQuantityUpdate = diffQuantity !== 0;
  const isNotesUpdate = files?.length > 0 || noteDescription;
  const isLoggedHoursUpdate = duration || progressType === 'logged hours';
  const isInvoiceUpdate = progressType === PROGRESS_UPDATE_TYPE_INVOICE_ADD
    || progressType === PROGRESS_UPDATE_TYPE_INVOICE_UPDATE
    || progressType === PROGRESS_UPDATE_TYPE_INVOICE_DELETE;
  const isUpdateCostStep = progressType === PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD;
  let type;
  let field;
  let pastValue = progress;
  let newValue;
  let ourDiff = diff;

  if (isNotesUpdate) {
    type = PROGRESS_UPDATE_TYPE_NOTES;
    field = PROGRESS_UPDATE_FIELD_NOTES;
    newValue = noteDescription;
  } else if (isQuantityUpdate) {
    type = PROGRESS_UPDATE_TYPE_QUANTITY;
    field = PROGRESS_UPDATE_FIELD_QUANTITY;
    newValue = filteredValues?.quantityUsed || quantityUsed;
    pastValue = filteredValues?.lastQuantity || lastQuantity;
    ourDiff = filteredValues?.diffQuantity || diffQuantity;
  } else if (isSpendUpdate || isInvoiceUpdate || isUpdateCostStep) {
    type = (isInvoiceUpdate || isUpdateCostStep)
      ? progressType : PROGRESS_UPDATE_TYPE_CURRENCY;
    field = PROGRESS_UPDATE_FIELD_CURRENCY;
    newValue = filteredValues?.actualSpend || actualSpend;
    pastValue = filteredValues?.lastSpend || lastSpend;
    ourDiff = filteredValues?.diffSpend || diffSpend;
  } else if (isLoggedHoursUpdate) {
    type = PROGRESS_UPDATE_TYPE_LOGGED_HOURS;
    field = PROGRESS_UPDATE_FIELD_LOGGED_HOURS;
  } else {
    type = PROGRESS_UPDATE_TYPE_ACTIVITY;
    field = PROGRESS_UPDATE_FIELD_ACTIVITY;
    newValue = filteredValues?.progress || progress;
    pastValue = filteredValues?.lastProgress || lastProgress;
    ourDiff = filteredValues?.diff || diff;
  }

  return {
    duration,
    pastValue,
    newValue,
    diff: ourDiff,
    timestamp,
    device,
    files,
    type,
    field,
  };
};

/**
 * Dynamically calculates the styling depending on which progress bars are used
 * @param {string} type - One of Material, Labor, Overhead
 * @param {boolean} hasActualSpendProgressBar
 * @param {boolean} hasActualHoursProgressBar
 * @param {boolean} hasActualLaborCostProgressBar
 * @returns {object} object containing styling
 */
export const getProgressRowStyling = ({
  type,
  hasActualHoursProgressBar,
  hasActualLaborCostProgressBar,
}) => {
  const styling = {
    display: 'grid',
    marginRight: 30,
  };

  if (type !== 'Material') { // Labour, overhead, or material
    // All 3 bars
    if (hasActualLaborCostProgressBar && hasActualHoursProgressBar) {
      styling.paddingTop = 0;
      // Activity bar + Actual hours or Actual Labor Cost bar:
    } else if (hasActualHoursProgressBar || hasActualLaborCostProgressBar) {
      styling.paddingTop = 29;
    } else {
    // Activity bar only:
      styling.paddingBottom = 17;
    }
  } else { // Material
    styling.paddingBottom = 22;
  }

  return styling;
};

/**
 * Retrieves the styling for Estimated Hours/Cost Column
 * @param {boolean} useBothEstimates
 * @returns {object} styling
 */
export const getStackedEstimatesStyling = ({
  useBothEstimates,
}) => (useBothEstimates ? { rowGap: 30, paddingBottom: 50 } : {});

/**
 * Retrieves the styling for Actual Hours/Spend Column
 * @param {boolean} useBothEstimates
 * @returns {object} styling
 */
export const getStackedActualStyling = ({
  useBothEstimates,
}) => (useBothEstimates ? { rowGap: 26, paddingBottom: 46 } : {});

/**
 * Computes the current progress depending on the type
 * @param {string} type - progress bar type
 * @param {boolean} displayMode  - whether progress bar allows user input
 * @param {number} estimatedCost
 * @param {number} estimatedHours
 * @param {number} actualSpend
 * @param {number} actualHours
 * @param {number} latestProgressUpdate
 * @param {number} estimatedLaborCost
 * @param {number} actualLaborCost
 * @returns {number} the current progress
 */
export const computeProgress = ({
  type,
  displayMode,
  estimatedCost,
  estimatedHours,
  actualSpend = 0,
  actualHours = 0,
  latestProgressUpdate,
  actualLaborCost,
}) => {
  if (!displayMode) return latestProgressUpdate;
  switch (type) {
    case PROGRESS_ROW_TYPE_SPEND:
    case PROGRESS_ROW_TYPE_MATERIAL_SPEND:
      if (!estimatedCost) return latestProgressUpdate;
      return parseFloat(((actualSpend / estimatedCost) * 100).toFixed(1));
    case PROGRESS_ROW_TYPE_HOURS:
      if (!estimatedHours) return latestProgressUpdate;
      return parseFloat(((actualHours / estimatedHours) * 100).toFixed(1));
    case PROGRESS_ROW_TYPE_LABOR_COST:
      if (!estimatedCost) return latestProgressUpdate;
      return parseFloat((((actualLaborCost + actualSpend) / estimatedCost) * 100).toFixed(1));
    default:
      return latestProgressUpdate;
  }
};

/**
 * Gets the actual cost for a costcode
 * @param {object} costcodeDetailsMap
 * @param {string} costcodeId
 * @param {string} phaseId
 * @param {object} userMap
 * @returns {number} the actual cost
 */
export const getActualCostForCostCode = ({
  costcodeDetailsMap,
  costcodeId,
  phaseId,
  userMap,
}) => {
  if (!costcodeDetailsMap || !costcodeId) return 0;
  const phaseCostcodeKey = getPhaseCostcodeKey(phaseId, costcodeId);
  const { tasks, costcodeTaskSummary } = costcodeDetailsMap[phaseCostcodeKey] || {};

  if (!isNullOrUndefined(costcodeTaskSummary?.totalCost)) {
    return costcodeTaskSummary.totalCost;
  }
  if (!tasks || !tasks.length) return 0;

  return tasks.reduce((acc, task) => {
    const { duration = 0, userId } = task;
    const { wage = 0 } = userMap[userId] || {};
    const cost = duration * wage;
    return acc + cost;
  }, 0);
};

/**
 * Gets the billed amount for a costcode (from time entries)
 * @param {object} costcodeDetailsMap
 * @param {string} costcodeId
 * @param {string} phaseId
 * @returns {number} the billed amount
 */
export const getBilledAmountForCostCode = ({
  costcodeDetailsMap,
  costcodeId,
  phaseId,
}) => {
  if (!costcodeDetailsMap || !costcodeId) return 0;
  const phaseCostcodeKey = getPhaseCostcodeKey(phaseId, costcodeId);
  const { costcodeTaskSummary } = costcodeDetailsMap[phaseCostcodeKey] || {};
  return costcodeTaskSummary?.totalBilling ?? 0;
};

/**
 * Gets the billed amount for a costcode (from cost updates)
 * @param {array} updates array of update metadata
 * @param {string} type costcode type
 */
export const getBilledFromCostUpdates = ({
  updates,
  type,
}) => {
  if (!updates.length) return 0;

  let totalBilling = 0;

  updates.forEach((update) => {
    if (type === 'Material') {
      const { cost = 0, quantity = 0, markup = 0 } = update;
      totalBilling += ((markup * cost) + cost) * quantity;
    } else if (type === 'Equipment') {
      const { dailyBillingRate = 0, hourlyBillingRate = 0, hours = 0 } = update;
      totalBilling += dailyBillingRate || (hourlyBillingRate * hours);
    } else {
      const { total = 0 } = update;
      totalBilling += total;
    }
  });

  return totalBilling;
};

/**
 * Gets and formats the actual hours for a costcode
 * @param {object} costcodeDetailsMap
 * @param {string} costcodeId
 * @param {string} phaseId
 * @returns {string} formatted actual hours label
 */
export const getFormattedHoursLabel = ({
  costcodeDetailsMap,
  costcodeId,
  phaseId,
  useLabel = true,
}) => {
  if (!costcodeDetailsMap || !costcodeId) return '0 h';
  const phaseCostcodeKey = getPhaseCostcodeKey(phaseId, costcodeId);
  const {
    costcodeTaskSummary: {
      totalHours,
    } = {},
  } = costcodeDetailsMap[phaseCostcodeKey] || {};
  if (!useLabel) return totalHours ?? 0;
  return totalHours ? `${totalHours.toFixed(2)} h` : '0 h';
};

/**
 * Gets the actual spend
 * @param {string} costcodeId
 * @param {string} phaseId
 * @param {string} projectId
 * @param {object} progresses
 * @returns {number} actual spend
 */
export const getActualSpend = ({
  costcodeId,
  phaseId,
  projectId,
  progresses,
}) => {
  const key = getProgressKey({
    projectId,
    phaseId,
    costcodeId,
  });

  const progressArray = progresses[key] || [];
  const {
    actualSpend = 0,
  } = progressArray[progressArray.length - 1] || {};

  return actualSpend;
};
