import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import GanttScheduleHolidayInputField from './GanttScheduleHolidayInputField';

const getGanttScheduleHolidayColumns = ({
  onDelete,
  readOnly = false,
  scheduleId,
}) => {
  const columns = [
    {
      title: 'Holiday',
      dataIndex: 'name',
      width: 300,
      render: (text, record) => (
        !readOnly
          ? (
            <GanttScheduleHolidayInputField
              scheduleId={scheduleId}
              holiday={record}
              field="name"
              type="text"
              title="Name"
            />
          )
          : <div>{text}</div>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'date',
      width: 200,
      render: (text, record) => (
        !readOnly
          ? (
            <GanttScheduleHolidayInputField
              scheduleId={scheduleId}
              holiday={record}
              field="date"
              type="date"
              title="Date"
            />
          )
          : <div>{moment(text).format('MMM DD, YYYY')}</div>
      ),
    },
  ];

  if (!readOnly) {
    columns.push({
      title: 'Delete',
      dataIndex: 'delete',
      key: 'delete',
      align: 'right',
      width: 10,
      render: (_, record) => (
        <BorderlessButton
          style={{ padding: 0, width: 10 }}
          iconNode={<DeleteOutlined style={{ marginLeft: 0, color: '#BF381D' }} />}
          onClick={() => onDelete(record)}
        />
      ),
    });
  }

  return columns;
};

export default getGanttScheduleHolidayColumns;
