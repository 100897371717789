import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Select } from 'antd';
import * as Sentry from '@sentry/react';

import FormsService from '../state/forms.service';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import sortByString from '../../helpers/helpers';

export default ({
  visible,
  onClose,
  onSubmit,
}) => {
  const templates = useSelector((state) => state.forms.templates);
  const templateOptions = useMemo(() => {
    const templateArray = Object.values(templates);
    templateArray.sort(sortByString('name'));
    
    return templateArray
      .filter((template) => template.notDeleted && template.active && !template.projectId)
      .map((template) => ({ value: template.id, label:template.name }));
  },[templates]);
  
  const [loading, setLoading] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState();

  const onSubmitClicked = useCallback(async () => {
    if(!(selectedTemplateId in templates)) {
      // Should never happen
      onClose();
      return;
    }
    try {
      setLoading(true);
      const { data: templateData } = await FormsService.getTemplateDetails(selectedTemplateId);
      await onSubmit(templateData);
    } catch(err) {
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
    setLoading(false);
    
  },[selectedTemplateId, templates]);

  useEffect(() => {
    if (!visible) setSelectedTemplateId();
  },[visible]);
  return (
    <Drawer
      title='Select Global Form'
      width={400}
      visible={visible}
      onClose={onClose}
    >
      <Select
        showSearch
        options={templateOptions}
        onSelect={setSelectedTemplateId}
        style={{ width: '100%' }}
        optionFilterProp='label'
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
        canSubmit={!!selectedTemplateId}
        loading={loading}
      />
    </Drawer>
  )
}