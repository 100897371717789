import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Row,
  Col,
  Input,
  Select,
  DatePicker,
  Radio,
  Switch,
  TreeSelect,
  Dropdown,
  Menu,
} from 'antd';
import {
  FullscreenOutlined,
  FullscreenExitOutlined,
  PlusOutlined,
  ImportOutlined,
  SettingOutlined,
  FilterOutlined,
  FilterFilled,
  CalendarOutlined,
  SortAscendingOutlined,
  ClockCircleOutlined,
  HighlightOutlined,
  SortDescendingOutlined,
  ArrowUpOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import ScheduleZoomButtons from '../ScheduleZoomButtons';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import Permissions from '../../auth/Permissions';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import Debouncer from '../../helpers/Debouncer';
import {
  createGanttSchedule,
  updateGanttScheduleFilters,
} from '../state/schedule.actions';
import { getScheduleTreeData, isFilterActive } from './ganttScheduleHelpers';
import SimpleTextInputModal from '../../common/modals/SimpleTextInputModal';
import colors from '../../constants/Colors';

const dateTypes = [
  {
    value: 'Day',
    label: 'Day',
  },
  {
    value: 'Week',
    label: 'Week',
  },
  {
    value: 'Month',
    label: 'Month',
  },
  {
    value: 'Custom',
    label: 'Custom',
  },
];
const { RangePicker } = DatePicker;
const { Group: RadioGroup } = Radio;

const getSortStyle = (sortType, target) => (
  target === sortType
    ? { backgroundColor: colors.ONTRACCR_RED, color: 'white' }
    : {}
);

export default function GanttScheduleHeader({
  viewType,
  openDrawer,
  onViewTypeChange,
  onImport,
  setProjectId,
  projectId,
  setScheduleId,
  scheduleId,
  schedules,
  toggleFullscreen,
  isFullScreen,
  activeTab,
  setActiveTab,
  isSelected,
  onSort,
  sortType,
}) {
  const { t } = useTranslation();
  const [fsDisable, setFSDisable] = useState(false);
  const [isCustomDateRange, setIsCustomDateRange] = useState(false);
  const [isDatePickerOpen, setIsDatePickerOpen] = useState(false);
  const [isAddScheduleModalOpen, setIsAddScheduleModalOpen] = useState(false);
  const dispatch = useDispatch();
  const debouncer = new Debouncer();

  const isReadOnly = !Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE');

  const activeFilters = useSelector((state) => state.schedule.ganttScheduleFilters);

  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    dispatch(updateGanttScheduleFilters({
      name: value,
    }));
  }, []);

  const FullScreenIcon = isFullScreen ? FullscreenExitOutlined : FullscreenOutlined;

  const onDateRangeChange = useCallback((customDateRange) => {
    dispatch(updateGanttScheduleFilters({
      dateRange: customDateRange ? [...customDateRange] : null,
    }));
  }, []);

  useEffect(() => {
    setIsCustomDateRange(!!activeFilters.dateRange);
  }, [activeFilters]);

  const onDateTypeChange = useCallback((value) => {
    if (value === 'Custom') {
      setIsCustomDateRange(true);
      setIsDatePickerOpen(true);
      return;
    }

    setIsCustomDateRange(false);
    onDateRangeChange(null);
    onViewTypeChange(value);
  }, [onViewTypeChange]);

  const dateTypeRadio = useMemo(() => (
    <RadioGroup
      options={dateTypes}
      onChange={({ target: { value: dateType } }) => {
        onDateTypeChange(dateType);
      }}
      value="Custom"
    />
  ), []);

  const isFiltersActive = useMemo(() => isFilterActive(activeFilters), [activeFilters]);
  const onActiveTabChange = useCallback((e) => {
    setActiveTab(e ? 'list' : 'schedule');
  }, [setActiveTab]);

  const scheduleOptions = useMemo(() => getScheduleTreeData(t, schedules), [schedules]);

  const onScheduleSelect = async (val) => {
    if (val) {
      const [
        selectedProjectId,
        selectedScheduleId,
      ] = val.split('.');
      setProjectId(selectedProjectId);
      setScheduleId(selectedScheduleId);

      if (!selectedScheduleId && !isReadOnly) {
        const newSchedule = await dispatch(createGanttSchedule({
          name: 'Default',
          projectId: selectedProjectId,
        }));

        setScheduleId(newSchedule.id);
      }
    }
  };

  const onAddClicked = useCallback(({ key }) => {
    switch (key) {
      case 'task':
        openDrawer('add');
        break;
      case 'schedule':
        setIsAddScheduleModalOpen(true);
        break;
      default:
        onImport();
    }
  }, [openDrawer]);

  const onAddSchedule = useCallback(async (name) => {
    const newSchedule = await dispatch(createGanttSchedule({
      name,
    }));
    setScheduleId(newSchedule.id);
    setProjectId();
    setIsAddScheduleModalOpen(false);
  }, []);

  return (
    <>
      <Row id="schedule-header-container" justify="space-between" align="middle" gutter={20}>
        <Col span={10}>
          <Row justify="start" gutter={14}>
            <Col span={10}>
              <TreeSelect
                showSearch
                style={{ width: '100%' }}
                treeNodeFilterProp="title"
                treeDefaultExpandAll
                onChange={onScheduleSelect}
                value={projectId || scheduleId ? `${projectId || ''}.${scheduleId || ''}` : null}
                disabled={isSelected}
                treeData={scheduleOptions}
                placeholder="Select a Schedule"
              />
            </Col>
            <Col span={10}>
              <Input.Search
                className="searchbar"
                placeholder="Search"
                onChange={onSearchChange}
                allowClear
                value={activeFilters.name || ''}
              />
            </Col>
            { (projectId || scheduleId)
                && (
                <Col span={2}>
                  <OnTraccrButton
                    title=""
                    icon={
                      isFiltersActive
                        ? <FilterFilled style={{ marginLeft: 0 }} />
                        : <FilterOutlined style={{ marginLeft: 0 }} />
                    }
                    onClick={() => openDrawer('filters')}
                  />
                </Col>
                )}

            {(projectId || scheduleId) && (
              <Col span={2}>
                <Dropdown
                  getPopupContainer={(node) => node.parentNode}
                  overlay={(
                    <Menu onClick={onSort}>
                      <Menu.Item key="default" style={getSortStyle(sortType, 'default')}>
                        <span><ClockCircleOutlined /></span>
                        &nbsp;Default
                      </Menu.Item>
                      <Menu.Item key="color" style={getSortStyle(sortType, 'color')}>
                        <span><HighlightOutlined /></span>
                        &nbsp;By Color
                      </Menu.Item>
                      <Menu.Item key="name-asc" style={getSortStyle(sortType, 'name-asc')}>
                        <span><SortAscendingOutlined /></span>
                        &nbsp;Name: Ascending
                      </Menu.Item>
                      <Menu.Item key="name-desc" style={getSortStyle(sortType, 'name-desc')}>
                        <span><SortDescendingOutlined /></span>
                        &nbsp;Name: Descending
                      </Menu.Item>
                      <Menu.Item key="duration-asc" style={getSortStyle(sortType, 'duration-asc')}>
                        <span><ArrowUpOutlined /></span>
                        &nbsp;Duration: Ascending
                      </Menu.Item>
                      <Menu.Item key="duration-desc" style={getSortStyle(sortType, 'duration-desc')}>
                        <span><ArrowDownOutlined /></span>
                        &nbsp;Duration: Descending
                      </Menu.Item>
                    </Menu>
                  )}
                >
                  <OnTraccrButton
                    title="Sort"
                    icon={<SortAscendingOutlined />}
                    type={sortType === 'default' ? 'cancel' : 'back'}
                  />
                </Dropdown>
              </Col>
            )}
          </Row>
        </Col>
        <Col span={14}>
          <Row justify="end" gutter={14}>
            { activeTab === 'schedule'
              && (
                <Col>
                  <ScheduleZoomButtons />
                </Col>
              )}
            <Col>
              {
                !isCustomDateRange && activeTab === 'schedule'
                  && (
                  <Select
                    className="schedule-date-type-picker"
                    defaultValue={dateTypes[1].value}
                    value={viewType}
                    onSelect={(type) => onDateTypeChange(type)}
                    getPopupContainer={(node) => node.parentNode}
                  >
                    {dateTypes.map((dateType) => (
                      <Select.Option value={dateType.value} key={dateType.value}>
                        {dateType.value}
                      </Select.Option>
                    ))}
                  </Select>
                  )
              }
              {
                isCustomDateRange && activeTab === 'schedule'
                  && (
                    <RangePicker
                      format="MMM Do YY"
                      renderExtraFooter={() => dateTypeRadio}
                      onChange={onDateRangeChange}
                      open={isDatePickerOpen}
                      onOpenChange={(status) => setIsDatePickerOpen(status)}
                      value={activeFilters.dateRange || null}
                    />
                  )
              }
            </Col>
            { !isFullScreen
              && (
                <>
                  <Col>
                    {!isFullScreen && !isReadOnly && (
                      <Dropdown
                        overlay={(
                          <Menu onClick={onAddClicked}>
                            <Menu.Item key="schedule" disabled={isSelected}>
                              <span><CalendarOutlined /></span>
                              &nbsp;Create Schedule
                            </Menu.Item>
                            <Menu.Item key="task" disabled={!projectId && !scheduleId}>
                              <span><PlusOutlined /></span>
                              &nbsp;Add Task
                            </Menu.Item>
                            <Menu.Item key="import" disabled={!projectId && !scheduleId}>
                              <span><ImportOutlined /></span>
                              &nbsp;Import Tasks
                            </Menu.Item>
                          </Menu>
                        )}
                      >
                        <OnTraccrButton
                          title="New"
                          icon={<PlusOutlined />}
                        />
                      </Dropdown>
                    )}
                  </Col>
                  <Col>
                    <OnTraccrButton
                      title=""
                      icon={<SettingOutlined style={{ marginLeft: 0 }} />}
                      onClick={() => openDrawer('settings')}
                      disabled={!projectId && !scheduleId}
                    />
                  </Col>
                </>
              )}
            <Col className="switch-container">
              {`${activeTab === 'list' ? 'List' : 'Schedule'} View `}
              <Switch
                checked={activeTab === 'list'}
                onChange={onActiveTabChange}
              />
            </Col>
            { !isSelected && (
              <Col>
                <BorderlessButton
                  style={{ width: 30, backgroundColor: 'transparent', padding: 0 }}
                  iconNode={<FullScreenIcon style={{ fontSize: 30, marginLeft: 0 }} />}
                  onClick={() => {
                    if (fsDisable) return;
                    setFSDisable(true);
                    dispatch(toggleFullscreen());
                    debouncer.debounce(() => setFSDisable(false), 1000);
                  }}
                />
              </Col>
            )}
          </Row>
        </Col>
      </Row>
      <SimpleTextInputModal
        title="New Schedule Name"
        visible={isAddScheduleModalOpen}
        onClose={() => setIsAddScheduleModalOpen(false)}
        onSave={onAddSchedule}
      />
    </>
  );
}

GanttScheduleHeader.propTypes = {
  viewType: PropTypes.string.isRequired,
  onViewTypeChange: PropTypes.func.isRequired,
  openDrawer: PropTypes.func.isRequired,
  onImport: PropTypes.func.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    project: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  })).isRequired,
  setProjectId: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  setScheduleId: PropTypes.func.isRequired,
  scheduleId: PropTypes.string.isRequired,
  toggleFullscreen: PropTypes.func.isRequired,
  isFullScreen: PropTypes.bool.isRequired,
  activeTab: PropTypes.string.isRequired,
  setActiveTab: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
  onSort: PropTypes.func.isRequired,
  sortType: PropTypes.string.isRequired,
};

GanttScheduleHeader.defaultProps = {
  projectId: null,
};
