import { GET_QR_CODES } from '../state/actionTypes';

const initialState = [];

export default function qrCodesReducers(state = initialState, action) {
  if (action.type === GET_QR_CODES) {
    const {
      payload: {
        data: qrCodes = [],
      } = {},
    } = action;

    return qrCodes;
  }

  return state;
}
