/* eslint-disable react/forbid-prop-types */
import React from 'react';
import {
  Row, Col, Tree, PageHeader,
} from 'antd';

import { PropTypes } from 'prop-types';

export default function FilterDropdown({
  onFilter,
  filters,
  activeFilters,
  style = {},
}) {
  return (
    <div>
      <Row>
        <PageHeader
          title="Filters"
          style={{ padding: 0, marginBottom: 5, fontFamily: 'raleway-medium' }}
        />
      </Row>
      <div style={{ overflow: 'auto', maxHeight: '65vh', maxWidth: 600 }}>
        {filters.map((filterRow, index) => (
          <Row justify="left" style={{ marginTop: index > 0 ? 5 : 0, ...style }} key={index}>
            {filterRow.map((filter) => (
              <Col key={filter[0].key}>
                <Tree
                  checkable
                  selectable={false}
                  defaultExpandAll
                  defaultCheckedKeys={Array.from(activeFilters[filter[0].key] || [])}
                  onCheck={(checked) => onFilter(checked, filter[0].key)}
                  treeData={filter}
                />
              </Col>
            ))}
          </Row>
        ))}
      </div>
    </div>
  );
}

FilterDropdown.propTypes = {
  onFilter: PropTypes.func.isRequired,
  filters: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string.isRequired,
        key: PropTypes.string.isRequired,
        children: PropTypes.arrayOf(
          PropTypes.shape({
            title: PropTypes.string.isRequired,
            key: PropTypes.string.isRequired,
          }),
        ),
      }),
    ),
  ).isRequired,
  activeFilters: PropTypes.object,
  style: PropTypes.object,
};

FilterDropdown.defaultProps = {
  activeFilters: [],
  style: {},
};
