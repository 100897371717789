import PropTypes from "prop-types"
import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';

import BorderlessButton from '../common/buttons/BorderlessButton';

import colors from '../constants/Colors';

const stopPropagation = (event) => {
  event.stopPropagation();
};

export default function DashboardWidgetDeleteButton({ onDelete }) {
  return (
    <BorderlessButton
      className="dashboard-component-delete-btn"
      iconNode={<DeleteOutlined style={{ color: colors.ONTRACCR_RED, marginLeft: 0, width: 20 }} />}
      onClick={onDelete}
      onMouseDown={stopPropagation}
      onTouchStart={stopPropagation}
    />
  );
}

DashboardWidgetDeleteButton.propTypes = {
  onDelete: PropTypes.func.isRequired,
};
