import React, { useCallback, useMemo } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import {
  Col,
  Row,
  Select,
  Typography,
} from 'antd';
import PropTypes from 'prop-types';
import BorderlessButton from '../common/buttons/BorderlessButton';
import RelativeTimeSelector from './RelativeTimeSelctor';

const { Text } = Typography;

function SubtaskRelativeDueDateSelector({
  value = {},
  onChange,
  type = 'Due Date',
  relativeOptions,
}) {
  const {
    amount,
    time,
    relativeTo,
  } = value;

  const onUpdateAmount = useCallback((newAmount) => {
    onChange({
      ...value,
      amount: newAmount,
    });
  }, [onChange, value]);

  const onSelectTime = useCallback((newTime) => {
    onChange({
      ...value,
      time: newTime,
    });
  }, [onChange, value]);

  const onSelectRelative = useCallback((newRelative) => {
    onChange({
      ...value,
      relativeTo: newRelative,
    });
  }, [onChange, value]);

  const addDueDate = useCallback(() => {
    onChange({
      amount: 1,
      time: 'days',
      relativeTo: relativeOptions?.length ? relativeOptions[0].value : undefined,
    });
  }, [onChange]);

  const deleteDueDate = useCallback(() => {
    onChange({});
  }, [onChange]);

  const relativeToSelector = useMemo(() => (
    relativeOptions ? (
      <Row>
        <Text
          style={{
            paddingRight: 8,
            paddingTop: 4,
            height: '100%',
          }}
        >
          after
        </Text>
        <Select
          style={{ width: 150 }}
          options={relativeOptions}
          value={relativeTo}
          onSelect={onSelectRelative}
        />
      </Row>
    ) : (
      <>
        after creation.
      </>
    )
  ), [
    relativeOptions,
    onSelectRelative,
  ]);

  const haveDueDate = !!Object.keys(value).length;
  return (
    <>
      {haveDueDate && (
        <RelativeTimeSelector
          amount={amount}
          time={time}
          onUpdateAmount={onUpdateAmount}
          onSelectTime={onSelectTime}
          text={relativeToSelector}
          onDelete={haveDueDate ? deleteDueDate : null}
        />
      )}
      {!haveDueDate && (
        <Row justify="start" align="middle">
          <Col>
            <BorderlessButton
              iconNode={<PlusOutlined />}
              onClick={addDueDate}
              title={`Add ${type}`}
            />
          </Col>
        </Row>
      )}
    </>
  );
}

SubtaskRelativeDueDateSelector.propTypes = {
  value: PropTypes.objectOf(PropTypes.shape({
    amount: PropTypes.number.isRequired,
    time: PropTypes.string.isRequired,
  })).isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  relativeOptions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
    relativeTo: PropTypes.string,
  }))),
};

export default SubtaskRelativeDueDateSelector;
