import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Row, Col, Drawer, Form,
} from 'antd';
import { EditOutlined } from '@ant-design/icons';

import FormVariableTextInput from '../../../forms/FormWorkflows/selectors/FormVariableTextInput';

import BorderlessButton from '../../buttons/BorderlessButton';
import DrawerSubmitFooter from '../../containers/DrawerSubmitFooter';

function PDFDesignerNameInput({
  onTextChange,
  text,
  isDisplay,
}) {
  const [currentText, setCurrentText] = useState(text);
  const [showDrawer, setShowDrawer] = useState(false);

  const openDrawer = useCallback(() => setShowDrawer(true), []);
  const onCloseDrawer = useCallback(() => {
    setShowDrawer(false);
    setCurrentText(text);
  }, [text]);

  const onSubmit = useCallback(() => {
    onTextChange(currentText);
    setShowDrawer(false);
  }, [currentText]);

  return (
    <>
      <Row
        gutter={10}
        align="middle"
        style={{
          position: 'absolute',
          top: isDisplay ? 105 : 115,
          height: 30,
        }}
      >
        <Col>
          {`File Name: ${text}.pdf`}
        </Col>
        <Col>
          {
            !isDisplay && (
              <BorderlessButton
                iconNode={<EditOutlined />}
                onClick={openDrawer}
              />
            )
          }
        </Col>
      </Row>
      <Form.Item
        name="pdfName"
      />
      <Drawer
        title="Configure PDF Name"
        visible={showDrawer}
        onClose={onCloseDrawer}
        width={400}
      >
        <FormVariableTextInput
          textAreaProps={{
            defaultValue: text,
          }}
          onTextChange={setCurrentText}
          text={currentText}
          visible={showDrawer}
        />
        <DrawerSubmitFooter
          onClose={onCloseDrawer}
          onSubmit={onSubmit}
          canSubmit={currentText?.length > 0}
        />
      </Drawer>
    </>
  );
}

PDFDesignerNameInput.propTypes = {
  onTextChange: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  isDisplay: PropTypes.bool,
  formRef: PropTypes.shape({
    current: PropTypes.shape({
      getFieldsValue: PropTypes.func,
      setFieldsValue: PropTypes.func,
    }),
  }).isRequired,
};

PDFDesignerNameInput.defaultProps = {
  isDisplay: false,
};

export default PDFDesignerNameInput;
