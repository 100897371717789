import React from 'react';
import {  Row, Divider, Col } from 'antd';

import DisplayText from '../../common/text/DisplayText';

export default {};

export const PopRow = ({ title, text }) => (
  <Row style={{ width: '100%'}} justify='space-between'>
    <Col>
      <DisplayText title={title} style={{ fontFamily: 'roboto-medium'}}/>
    </Col>
    <Col>
      <DisplayText title={text}/>
    </Col>
  </Row>
);
export const PopContent = ({ title, rows, }) => (
  <Row style={{ maxWidth: 300}}>
    <Row>
      <DisplayText title={title} style={{ fontSize: 16, margin: 0, fontFamily: 'roboto-bold' }}/>
    </Row>
    <Divider style={{ margin: 10, padding: 0 }}/>
    {rows.map((row) => (
       <PopRow title={row.title} text={row.text}/>
    ))}
  </Row>
);
