import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import AddEditCertification from './AddEditCertification';
import getCertificationColumns from './CertificationsColumns';
import useToggle from '../common/hooks/useToggle';
import { getCustomFieldTableColumns } from '../forms/formHelpers';
import CertificationsTable from './CertificationsTable';

import {
  getAllCertificationsForEntity,
  deleteCertification,
  getCertificationCustomFieldTemplate,
} from './state/certifications.actions';

export default function Certifications({
  entityType,
  id,
}) {
  const dispatch = useDispatch();
  const { isToggled: isExpanded, toggle: toggleExpanded } = useToggle(true);

  const {
    [entityType]: {
      certifications = [],
      certificationCustomFieldTemplate,
    } = {},
  } = useSelector((state) => state.certifications);

  const [
    isAddEditCertificationVisible,
    setIsAddEditCertificationVisible,
  ] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState({});

  useEffect(() => {
    dispatch(getCertificationCustomFieldTemplate(entityType));
  }, [entityType]);

  useEffect(() => {
    dispatch(getAllCertificationsForEntity(entityType, id));
  }, [entityType, id]);

  const onClose = useCallback(() => {
    setSelectedCertification({});
    setIsAddEditCertificationVisible(false);
  }, []);

  const onUpdateClick = (record) => {
    setSelectedCertification(record);
    setIsAddEditCertificationVisible(true);
  };

  const onDeleteClick = useCallback((certification) => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Certification',
      content: (
        <p>
          Are you sure you wish to delete this certification?
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        resolve(await dispatch(deleteCertification(entityType, id, certification.id)));
        onClose();
      },
      onCancel() {
        resolve();
      },
    });
  }), [entityType, id, onClose]);

  const tableColumns = useMemo(() => getCertificationColumns({
    entityType,
    showDelete: true,
    onUpdateClick,
    onDeleteClick,
  }), [onUpdateClick, onDeleteClick, entityType]);

  const onAddButtonClick = () => {
    setIsAddEditCertificationVisible(true);
  };

  const customFieldColumns = useMemo(() => (
    getCustomFieldTableColumns(certificationCustomFieldTemplate?.sections ?? [])
  ), [certificationCustomFieldTemplate]);

  return (
    <>
      <Row justify="space-between" align="middle">
        <Col>
          <OnTraccrButton
            title="Add Certification"
            onClick={onAddButtonClick}
            disabled={false}
            className="user-add-certification-button"
          />
        </Col>
        <Col>
          <OnTraccrButton
            title={isExpanded ? 'Collapse All' : 'Expand All'}
            onClick={toggleExpanded}
            icon={isExpanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
            iconLeft
          />
        </Col>
      </Row>
      <CertificationsTable
        certifications={certifications}
        columns={tableColumns}
        isExpanded={isExpanded}
        customFieldColumns={customFieldColumns}
        entityType={entityType}
      />
      <AddEditCertification
        visible={isAddEditCertificationVisible}
        onClose={onClose}
        id={id}
        certification={selectedCertification}
        certificationId={selectedCertification.id}
        onDeleteClick={onDeleteClick}
        entityType={entityType}
      />
    </>
  );
}

Certifications.propTypes = {
  entityType: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
