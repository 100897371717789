/* eslint-disable react/forbid-prop-types */
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Col, Row } from 'antd';
import { DateTime } from 'luxon';
import ExternalSignatureSigner from './ExternalSignatureSigner';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

export default function ExternalSignatureSigningOrder({
  divisionId,
  sections,
  id,
  data,
  setDataMap,
  additionalOptions,
}) {
  const {
    signers: initialSigners = [{
      id: DateTime.local().toMillis(),
      order: 1,
      emails: [],
    }],
  } = data;

  const [signers, setSigners] = useState(initialSigners || []);

  const updateSigners = useCallback((newSigners = []) => {
    setSigners(newSigners);
    setDataMap((dataMap) => {
      const {
        [id]: oldData = {},
      } = dataMap;
      return {
        ...dataMap,
        [id]: {
          ...oldData,
          signers: newSigners,
        },
      };
    });
  }, [setDataMap]);

  const onSignerDrag = useCallback((result) => {
    const { destination, source } = result;
    if (!destination || destination.index === source.index) return;
    const newSigners = [...signers];
    newSigners.splice(source.index, 1);
    newSigners.splice(destination.index, 0, signers[source.index]);
    let newOrder = 1;
    // If its not moved to the top of the order
    if (destination.index > 0) {
      const priorOrder = newSigners[destination.index - 1].order;
      const nextOrder = newSigners[destination.index + 1]?.order;
      // If its dragged to the bottom,
      // or if its dragged between two items with a gap in their ordering
      // do an increment from the previous
      if (!nextOrder || nextOrder > priorOrder + 1) {
        newOrder = priorOrder + 1;
      } else {
        // If its dragged between two where the order is already incremental
        // Match the previous
        newOrder = priorOrder;
      }
    }
    newSigners[destination.index].order = newOrder;
    updateSigners(newSigners);
  }, [signers, updateSigners]);

  const onSignerDelete = useCallback((deleteId) => {
    const newSigners = signers.filter((item) => item.id !== deleteId);
    updateSigners(newSigners);
  }, [signers, updateSigners]);

  const onSignerChange = useCallback((newSigner) => {
    const newSigners = signers.map((signer) => {
      if (signer.id !== newSigner.id) return signer;
      return {
        ...newSigner,
        order: newSigner.order || 1,
      };
    });
    newSigners.sort((a, b) => a.order - b.order);
    updateSigners(newSigners);
  }, [signers, updateSigners]);

  const onAddSigner = useCallback(() => {
    const newSigners = signers.concat([{
      id: DateTime.local().toMillis(),
      order: signers.length ? signers[signers.length - 1].order + 1 : 1,
      emails: [],
    }]);
    updateSigners(newSigners);
  }, [signers, updateSigners]);

  return (
    <Col style={{ width: '100%' }}>
      <Row>
        <OnTraccrButton
          style={{
            marginTop: 30,
          }}
          title="Add Signing Step"
          onClick={onAddSigner}
        />
      </Row>
      <DragDropContext onDragEnd={onSignerDrag}>
        <Droppable droppableId="parent">
          {({ droppableProps, innerRef, placeholder }) => (
            <div
              // eslint-disable-next-line react/jsx-props-no-spreading
              {...droppableProps}
              ref={innerRef}
              style={{ width: '100%' }}
            >
              {
                signers.map((signer, idx) => (
                  <ExternalSignatureSigner
                    key={signer.id}
                    index={idx}
                    signer={signer}
                    sections={sections}
                    divisionId={divisionId}
                    additionalOptions={additionalOptions}
                    onDeleteClicked={onSignerDelete}
                    onSignerChange={onSignerChange}
                  />
                ))
              }
              {placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </Col>
  );
}

ExternalSignatureSigningOrder.propTypes = {
  data: PropTypes.shape({
    signers: PropTypes.array,
  }),
  divisionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  sections: PropTypes.array,
  setDataMap: PropTypes.func.isRequired,
  additionalOptions: PropTypes.array,
};

ExternalSignatureSigningOrder.defaultProps = {
  data: {},
  sections: [],
  additionalOptions: [],
};
