import { dayDiffsToDateRange, filterData } from '../analytics/analytics.helpers';

// initial state

export const initialReports = {
  chartType: null,
  aggregate: 'sum',
  fullData: [], // All data, not including filters
  data: [], // Data to display after filtering
  savedReports: {},
  statuses: [],
  filters: {},
  groups: {},
  cardTitles: [],
  boardStatuses: [],
};

export const initialDashboard = {
  savedDashboards: [],
  isEdit: false,
  analyticsData: {},
};

// helpers

export const getFilteredReports = ({
  savedReports,
  type,
  projectTypeId,
  cardTypeId,
}) => {
  if (type === 'project') {
    return Object.values(savedReports).filter((report) => report.projectTypeId === projectTypeId);
  }

  return Object.values(savedReports).filter((report) => report.cardTypeId === cardTypeId);
};

// Analytics reducer functions

export const getSavedAnalyticsReports = (action, state) => {
  const {
    payload: {
      data: reportList = [],
    } = {},
  } = action;
  const reportMap = {};
  reportList.forEach((report) => {
    const {
      id,
      startTime,
      endTime,
    } = report;
    const fullReport = { ...report };
    const dateRange = dayDiffsToDateRange([startTime, endTime]);
    if (dateRange) {
      fullReport.dateRange = dateRange;
    }
    reportMap[id] = fullReport;
  });
  return {
    ...state,
    reports: {
      ...state.reports,
      savedReports: reportMap,
    },
  };
};

export const deleteSavedAnalyticsReport = (action, state) => {
  const {
    payload: {
      id,
    } = {},
  } = action;
  const {
    reports: {
      savedReports = {},
      selectedReportId,
    } = {},
  } = state;
  const newReports = { ...savedReports };
  delete newReports[id];
  return {
    ...state,
    reports: {
      ...state.reports,
      savedReports: newReports,
      selectedReportId: selectedReportId === id ? null : selectedReportId,
    },
  };
};

export const createAnalyticsReport = (action, state) => {
  const {
    payload: {
      data = {},
    } = {},
  } = action;
  const reportData = { ...data };
  const { id } = reportData;
  const {
    reports: {
      savedReports = {},
    } = {},
  } = state;
  const newSaved = { ...savedReports };
  newSaved[id] = reportData;
  const {
    startTime,
    endTime,
  } = reportData;
  const dateRange = dayDiffsToDateRange([startTime, endTime]);
  const newReports = {
    ...state.reports,
    ...reportData,
    savedReports: newSaved,
    selectedReportId: id,
  };
  if (dateRange) {
    newSaved[id].dateRange = dateRange;
    newReports.dateRange = dateRange;
  }
  return {
    ...state,
    reports: newReports,
  };
};

export const updateAnalyticsReport = (action, state) => {
  const {
    payload: {
      id,
      newData = {},
    } = {},
  } = action;
  const {
    reports: {
      savedReports = {},
    } = {},
  } = state;
  const newSaved = { ...savedReports };
  newSaved[id] = newData;
  const {
    startTime,
    endTime,
  } = newData;
  const newReports = {
    ...state.reports,
    ...newData,
    savedReports: newSaved,
    selectedReportId: id,
  };
  const dateRange = dayDiffsToDateRange([startTime, endTime]);
  if (dateRange) {
    newSaved[id].dateRange = dateRange;
    newReports.dateRange = dateRange;
  }
  return {
    ...state,
    reports: newReports,
  };
};

export const setAnalyticsConfig = (action, state) => {
  const {
    payload = {},
  } = action;
  return {
    ...state,
    reports: {
      ...state.reports,
      ...payload,
    },
  };
};

export const updateAnalyticsFilters = (action, state) => {
  const {
    payload = {},
  } = action;
  const {
    reports: {
      filters: oldFilters = {},
      fullData = [],
      breakdown = [],
    } = {},
  } = state;

  const newFilters = {
    ...oldFilters,
    ...payload,
  };

  return {
    ...state,
    reports: {
      ...state.reports,
      filters: newFilters,
      data: filterData({ fullData, breakdown, filters: newFilters }),
    },
  };
};

export const selectSavedReport = (action, state) => {
  const {
    payload: { id } = {},
  } = action;
  const {
    reports: {
      savedReports = {},
    } = {},
  } = state;
  if (!id) {
    // new report
    return {
      ...state,
      reports: {
        ...state.reports,
        selectedReportId: id,
      },
    };
  }
  const {
    [id]: ourReport = {},
  } = savedReports;
  const { filters = {} } = ourReport;
  const newReports = {
    ...state.reports,
    ...ourReport,
    selectedReportId: id,
    filters,
  };
  if (ourReport.datePreset) {
    delete newReports.dateRange;
  } else {
    delete newReports.datePreset;
  }
  if (ourReport.boardId) {
    delete newReports.formTemplateId;
  } else {
    delete newReports.boardId;
  }
  if (ourReport.dateBreakdown) {
    delete newReports.fieldBreakdown;
  } else {
    delete newReports.dateBreakdown;
  }
  return {
    ...state,
    reports: newReports,
  };
};

export const getAnalyticsData = (action, state) => {
  const {
    payload = {},
  } = action;
  const {
    data: fullData = [],
  } = payload;
  const {
    reports: {
      breakdown = [],
      filters = {},
    } = {},
  } = state;
  return {
    ...state,
    reports: {
      ...state.reports,
      ...payload,
      fullData,
      data: filterData({ fullData, breakdown, filters }),
    },
  };
};

export const clearAnalyticsConfig = (state) => {
  const {
    reports: {
      savedReports,
    } = {},
  } = state;

  return {
    ...state,
    reports: {
      ...initialReports,
      savedReports,
    },
  };
};

// Dashboard Reducer functions

export const getSavedDashboards = (action, state) => {
  const {
    payload: newDashboards = [],
  } = action;
  const fullDashboards = newDashboards.map((dash) => ({
    ...dash,
    isFavorite: !!dash.isFavorite,
  }));
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      savedDashboards: fullDashboards,
    },
  };
};

export const setDashboardEdit = (action, state) => {
  const {
    payload: {
      isEdit,
    } = {},
  } = action;
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      isEdit,
    },
  };
};

export const selectDashboard = (action, state) => {
  const {
    payload: {
      id,
    } = {},
  } = action;
  if (id === 'new') {
    return {
      ...state,
      dashboard: {
        ...state.dashboard,
        selectedDashboard: {
          id: 'new',
          title: 'New Dashboard',
          layout: [],
        },
        isEdit: true,
      },
    };
  }
  const {
    dashboard: {
      savedDashboards = [],
    } = {},
  } = state;
  const ourDash = savedDashboards.find((dash) => dash.id === id);
  if (!ourDash) return state;
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      selectedDashboard: ourDash,
    },
  };
};

export const createDashboard = (action, state) => {
  const {
    payload: newDashboard = {},
  } = action;
  const {
    dashboard: {
      savedDashboards: existingDashboards = [],
    } = {},
  } = state;
  const newDashboards = [];
  const dashboardIds = new Set();
  existingDashboards.forEach((dash) => {
    newDashboards.push(dash);
    dashboardIds.add(newDashboard);
  });
  if (dashboardIds.has(newDashboard.id)) return state;
  newDashboards.push(newDashboard);
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      selectedDashboard: newDashboard,
      savedDashboards: newDashboards,
      isEdit: false,
    },
  };
};

export const updateDashboard = (action, state) => {
  const {
    payload: {
      id,
      newData = {},
    },
  } = action;
  const {
    dashboard: {
      savedDashboards: existingDashboards = [],
    } = {},
  } = state;
  const newDashboards = [];
  let newDashboard;
  existingDashboards.forEach((dash) => {
    if (dash.id !== id) {
      newDashboards.push(dash);
      return;
    }
    newDashboard = {
      ...dash,
      ...newData,
    };
    newDashboards.push(newDashboard);
  });
  if (!newDashboard) return state;
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      selectedDashboard: newDashboard,
      savedDashboards: newDashboards,
      isEdit: false,
    },
  };
};

export const deleteDashboard = (action, state) => {
  const {
    payload: {
      id,
    } = {},
  } = action;
  const {
    dashboard: {
      savedDashboards = [],
    } = {},
  } = state;
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      selectedDashboard: {},
      savedDashboards: savedDashboards.filter((dash) => dash.id !== id),
    },
  };
};

export const getDashboardAnalyticsData = (action, state) => {
  const {
    payload: {
      id,
      data,
      config: {
        breakdown = [],
        filters = {},
      } = {},
    } = {},
  } = action;

  const {
    data: fullData = [],
  } = data;
  const {
    dashboard: {
      analyticsData: existingAnalyticsData = {},
    } = {},
  } = state;
  const newAnalyticsData = { ...existingAnalyticsData };
  newAnalyticsData[id] = {
    ...data,
    fullData,
    data: filterData({ fullData, breakdown, filters }),
  };
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      analyticsData: newAnalyticsData,
    },
  };
};

export const setInitialDashboard = (action, state) => {
  const {
    payload: {
      projectTypeId,
      cardTypeId,
      analyticsType,
    } = {},
  } = action;
  const {
    dashboard: {
      savedDashboards: existingDashboards = [],
    } = {},
  } = state;
  let selectedDashboard;
  let filtered;
  if (analyticsType === 'project') {
    filtered = existingDashboards.filter((dash) => dash.projectTypeId === projectTypeId);
  } else {
    filtered = existingDashboards.filter((dash) => dash.cardTypeId === cardTypeId);
  }
  selectedDashboard = filtered.find((dash) => dash.isFavorite);
  if (!selectedDashboard) {
    const [firstDash] = filtered;
    selectedDashboard = firstDash ?? {};
  }
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      selectedDashboard,
      isEdit: false,
    },
  };
};

export const favoriteDashboard = (action, state) => {
  const {
    payload: {
      id,
      unfavorite,
      projectTypeId,
      cardTypeId,
      analyticsType,
    } = {},
  } = action;
  const isFavorite = unfavorite ? 0 : 1;
  const {
    dashboard: {
      savedDashboards: existingDashboards = [],
      selectedDashboard: existingSelected,
    } = {},
  } = state;
  let newSelected = existingSelected;
  if (newSelected && newSelected.id === id) {
    newSelected = {
      ...existingSelected,
      isFavorite,
    };
  }
  return {
    ...state,
    dashboard: {
      ...state.dashboard,
      savedDashboards: existingDashboards.map((dash) => {
        if (dash.id !== id) {
          let savedDashIsFavorite;
          if (analyticsType === 'project') {
            savedDashIsFavorite = dash.projectTypeId === projectTypeId ? 0 : dash.isFavorite;
          } else {
            savedDashIsFavorite = dash.cardTypeId === cardTypeId ? 0 : dash.isFavorite;
          }
          return {
            ...dash,
            isFavorite: savedDashIsFavorite,
          };
        }
        return {
          ...dash,
          isFavorite,
        };
      }),
      selectedDashboard: newSelected,
    },
  };
};

export default {};
