import React from 'react';
import { withTranslation } from 'react-i18next';

import { ReportColumn } from './ReportColumnHeader';
import ReportSkeleton from './ReportSkeleton';
import getEmployeeProjectFilters from './EmployeeProjectFilters';

import { getIdMap, getNames, } from '../../helpers/helpers';
import { msToHours, timeIsBetween } from '../../helpers/time';

import { filterOnCols, getUnique, getDateKey } from '../reportHelpers';
import { getRuntime, roundDayRuntime } from '../../timecards/timecard.helpers';

const allColumns = [
  ReportColumn('Employee',{
    fixed:'left',
    key: 'name',
    dataIndex: 'name',
  }),
  ReportColumn('Employee ID', {
    key: 'employeeId',
    dataIndex: 'employeeId',
  }),
  ReportColumn('Date'),
  ReportColumn('Service Location',{
    key:'serviceLocation',
    dataIndex:'serviceLocation',
  }),
  ReportColumn('Reg Hrs',{
    key:'regularHours',
    dataIndex:'regularHours',
  }),
  ReportColumn('OT Hrs',{
    key:'otHours',
    dataIndex:'otHours',
  }),
  ReportColumn('Total Hrs',{
    key:'totalHours',
    dataIndex:'totalHours',
  })
];

class EmployeePayroll extends React.Component {
  constructor(props) {
    super(props);
    const {
      users = [],
      height = 450,
      costcodes = [],
      projects = [],
      phases = [],
      onColumnsChanged,
      columns,
    } = this.props;

    this.checkedColumns = new Set(allColumns.map((col) => col.key).filter(filterOnCols(columns)));
    this.state = {
      columns:allColumns.filter((col) => this.checkedColumns.has(col.key)),
      height,
      users,
      projects:new Set(getNames(projects).concat('None')),
      costcodes:new Set(costcodes.map(cc => cc.code).concat('None')),
      phases:new Set(getUnique(phases,'name').concat('None')),
      workType:new Set(['work','service']),
      enabledFilters: {
        project: true,
        phase: true,
        costcode: true,
        serviceLocation:true,
        date: true,
        approved: true,
        unapproved: true,
      }
    };


    onColumnsChanged(this.state.columns);
    this.onResize = this.resize.bind(this);
    this.onFilterToggle = this.toggleFilter.bind(this);

    this.costcodeIdMap = getIdMap(costcodes);
    this.projectIdMap = getIdMap(projects);

    this.phaseMap = {};
    phases.forEach(({ projectId, costcodeId, name }) => {
      if(!(projectId in this.phaseMap)) {
        this.phaseMap[projectId] = {};
      }
      if(!(costcodeId in this.phaseMap[projectId])) {
        this.phaseMap[projectId][costcodeId] = [];
      }
      this.phaseMap[projectId][costcodeId].push(name);
    });
  }

  componentDidMount() {
    this.props.onRef(this);
    this.resize();
  }

  componentWillUnmount() {
    this.props.onRef(null);
  }

  componentDidUpdate(prevProps) {
    const {
      height:prevHeight,
      users: prevUsers,
    } = prevProps;
    const {
      height,
      users,
    } = this.props;
    if(height !== prevHeight) {
      this.setState({
        height,
      });
    }

    if(users !== prevUsers) {
      this.setState({
        users,
      })
    }
  }

  toggleFilter(item,checked) {
    const {
      onColumnsChanged,
    } = this.props;
    const {
      key:itemKey,
    } = item;

    if(checked) {
      this.checkedColumns.add(itemKey);
    } else {
      this.checkedColumns.delete(itemKey);
    }
    const newCols = allColumns.filter((col) => this.checkedColumns.has(col.key));
    this.setState({
      columns:newCols,
    });
    onColumnsChanged(newCols);
  }

  resize() {
    const {
      height,
    } = this.props;
    this.setState({
      height,
    });
  }

  updateRowFilters(key,checked) {
    this.setState({
      enabledFilters:{
        ...this.state.enabledFilters,
        [key]:checked,
      },
    });
    this.toggleFilter({ key }, checked);
  }

  getColFilters() {
    return [{
      title:'Hours (Regular)',
      key:'regularHours',
      checked:this.checkedColumns.has('regularHours'),
    },{
      title:'Hours (OT)',
      key:'otHours',
      checked:this.checkedColumns.has('otHours'),
    },{
      title:'Total Hours',
      key:'totalHours',
      checked:this.checkedColumns.has('totalHours'),
    }];
  }

  getRowFilters() {
    const {
      users = [],
      t,
    } = this.props;

    const filters = getEmployeeProjectFilters({
      onFilterToggle:(type,checked) => {
        if(type === 'users') {
          this.setState({
            users: users.filter((user) => checked.has(user.name)),
          });
          return;
        }
        if(type === 'workType') {
          const typeMap = {
            Project:'work',
            Service:'service',
          };
          const newWorkType = new Set();
          Array.from(checked).forEach((type) => {
            if(type in typeMap) newWorkType.add(typeMap[type]);
          })
          this.setState({
            workType:newWorkType,
          });
          return;
        }
        this.setState({
          [type]:checked,
        });
      },
      onCheckChanged:(type,checked) => this.updateRowFilters(type,checked),
      users,
      showProject:false,
      showPhase:false,
      showCostcode:false,
      checkedColumns:this.checkedColumns,
      showServiceLocations:true,
      t,
    });

    return filters.concat([
      {
        title:'Date',
        key:'date',
        onCheckChanged:(checked) => this.updateRowFilters('date',checked),
        checked:this.checkedColumns.has('date'),
      }]);
  }

  getAggregateKey(serviceLocationText,taskId,) {
    const {
      enabledFilters: {
        date,
        serviceLocation
      },
    } = this.state;
    const keys = [serviceLocationText,taskId];
    const filters = [date,serviceLocation];
    for (const index in filters) {
      const filter = filters[index];
      if(filter) return keys.join('-');
      keys.pop();
    }
    return 'All';
  }

  failsFilter(filters,item,key = 'name') {
    if(!item && !filters.has('None')) return true;
    if(item && !filters.has(item[key])) return true;
  }

  formatData() {
    const {
      dateRange,
      onDataChanged,
      onFiltersChanged,
      settings,
      timeEntryUserMap = {},
    } = this.props;
    const {
      users = [],
      enabledFilters,
    } = this.state;

    const {
      roundingSetting,
      roundingType,
      roundingInterval,
    } = settings;


    const [startDate,endDate] = dateRange;
    const data = [];
    const dateAggregate = {};

    const roundTaskRuntime = (task) => {
      const runtime = getRuntime(task);
      return roundDayRuntime(runtime, roundingInterval, roundingType, roundingSetting);
    };

    users.forEach((user) => {
      const tasks = timeEntryUserMap[user?.id] ?? [];
      let regularHours = 0
      let otHours = 0
      let doubleOtHours = 0

      tasks.filter((t) =>
        t.type === 'service'
      ).forEach((task) => {
        if (!task.startTime || !task.endTime) return;
        if (!(timeIsBetween(task, startDate, endDate))) return;

        const runTime = msToHours(roundTaskRuntime(task)) //todo task.endTime.diff(task.startTime);
        const date = getDateKey(task, 'MMM Do YYYY');
        const totalCost = (runTime * user.wage)
        const state = task.state || 'Not Submitted'
        const dataObject = {
          name:user.name,
          employeeId: user.employeeId || '',
          runTime,
          regularHours:runTime,
          otHours:0,
          doubleOtHours,
          totalHours: regularHours + otHours + doubleOtHours,
          totalCost: `$${totalCost.toFixed(2)}`,
          date,
          serviceLocation:task.serviceLocation,
          wage:user.wage,
        };


        if(!(user.id in dateAggregate)) {
          dateAggregate[user.id] = {
            ...dataObject,
            values:{}
          }
        }

        const aggKey = this.getAggregateKey(task.serviceLocation,task.id);
        if(!(aggKey in dateAggregate[user.id].values)) {
          dateAggregate[user.id].values[aggKey] = {
            employeeId: user.employeeId || '',
            regularHours:0,
            otHours:0,
            totalHours:0,
            date,
            key:user.id + aggKey,
            serviceLocation:task.serviceLocation,
          }
        }
        dateAggregate[user.id].values[aggKey].regularHours += runTime;
        dateAggregate[user.id].values[aggKey].otHours = 0;
        dateAggregate[user.id].values[aggKey].totalHours += runTime;
        if (state === 'approved') {
          dateAggregate[user.id].values[aggKey].approved += runTime;
        } else {
          dateAggregate[user.id].values[aggKey].unapproved += runTime;
        }

      });
    });

    Object.values(dateAggregate).forEach((obj) => {
      Object.values(obj.values).forEach((hours) => {
        const totalCost = (hours.regularHours * obj.wage) + (hours.otHours * obj.wage * 1.5);
        // only approved selected and the task has approved hours      OR
        // only unapproved selected and the task has unapproved hours  OR  both selected
        if ((enabledFilters.approved && !enabledFilters.unapproved && hours.approved.toFixed(2)>0)    ||
            (enabledFilters.unapproved && !enabledFilters.approved && hours.unapproved.toFixed(2)>0)  ||
            (enabledFilters.unapproved && enabledFilters.approved)) {
          data.push({
            name:obj.name,
            employeeId: obj.employeeId,
            date:hours.date,
            regularHours:hours.regularHours.toFixed(2),
            otHours:hours.otHours.toFixed(2),
            totalHours:hours.totalHours.toFixed(2),
            totalCost: `$${totalCost.toFixed(2)}`,
            key:hours.key,
            serviceLocation:hours.serviceLocation,

            // These four fields are not displayed in the report but used for the export
            regHoursRaw: hours.regularHours,
            regularOTRaw: 0,
            doubleOTRaw: 0,
            wage: `$${obj.wage}`,
          });
        }
      });
    });

    onDataChanged(data);
    onFiltersChanged({
      users,
    });
    return data;
  }

  render() {
    const {
      renderColFilters,
      renderRowFilters,
    } = this.props;
    const {
      columns = [],
      height,
    } = this.state;
    const data = this.formatData();

    return (
      <ReportSkeleton
        data={data}
        columns={columns}
        height={height}
        colFilters={renderColFilters(this.getColFilters())}
        rowFilters={renderRowFilters(this.getRowFilters())}
      />
    );
  }
}

export default withTranslation()(EmployeePayroll);