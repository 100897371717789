import { DateTime } from 'luxon';
import {
  GET_LABELS,
  CREATE_LABEL,
  DELETE_LABEL,
  UPDATE_LABEL,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import LabelService from './labels.service';
import { counters, counterWasRecentlyCalled } from '../../helpers/counters';

const {
  labels: labelCounters,
} = counters;

export default {};

export const getLabels = () => async (dispatch) => {
  if (counterWasRecentlyCalled('labels.getLabels')) return true;
  labelCounters.getLabels = DateTime.local();
  const { data } = await LabelService.get();
  if (!data) {
    labelCounters.getLabels = null;
    return false;
  }
  dispatch({
    type: GET_LABELS,
    payload: data,
  });
  return true;
};

export const createLabel = (payload) => async (dispatch) => {
  Analytics.track('Labels/Create');
  const { data } = await LabelService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_LABEL,
    payload: {
      label: data,
    },
  });
  return true;
};

export const deleteLabel = (id) => async (dispatch) => {
  Analytics.track('Labels/Delete');
  const { data } = await LabelService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_LABEL,
    payload: {
      labelId: id,
    },
  });
  return true;
};

export const updateLabel = (id, payload) => async (dispatch) => {
  Analytics.track('Labels/Update');
  const { data } = await LabelService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_LABEL,
    payload: {
      labelId: id,
      title: payload.title,
      color: payload.color,
    },
  });
  return true;
};
