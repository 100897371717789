import {
  React,
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Form,
  Checkbox,
  DatePicker,
  notification,
  Table,
} from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import DisplayText from '../../common/text/DisplayText';
import MoreButton from '../../common/buttons/MoreButton';

import colors from '../../constants/Colors';

import { currencyFormatter } from '../../helpers/helpers';
import Analytics from '../../helpers/Analytics';
import Permissions from '../../auth/Permissions';

import { PROJECT_BUDGET_TYPE } from '../../nux/nux.constants';

import ProjectCurrencyInput from '../ProjectCurrencyInput';

import ProjectAddPhaseCodeDrawer from './ProjectAddPhaseCodeDrawer';
import ProjectBudgetTrackingPhases from './ProjectBudgetTrackingPhases';
import ProjectBudgetTrackingUnphased from './ProjectBudgetTrackingUnphased';
import ProjectSubcontracts from './ProjectSubcontracts';

import { calculateBuildEstimateValues, hasDates } from '../projectHelpers';
import ProjectBudgetTrackingImportDrawer from './Import/ProjectBudgetTrackingImportDrawer';
import { getMaterialCostColumns, getEquipmentCostColumns } from '../ProjectProgress/ProgressColumns';

function ProjectBudgetTracking({
  id: projectId,
  dataSource = [], // phases with attached costcodes
  costcodes = [],
  style = {},
  onListClear,
  isNotDisplay,
  labourHours,
  labourCost,
  estimatedMaterialCost,
  estimatedOverheadCost,
  estimatedEquipmentCost,
  estimatedBurdenRate,
  contractValue,
  startDate,
  endDate,
  equipment = [],
  onExport,
  unphasedCostcodes = [],
  nux = new Set(),
  createNuxEntry,
  onAdd,
  onRemove,
  onEditCostcodeEstimateChanged,
  onEditPhaseCostCodes,
  onClearDates,
  onClearBudget,
  onEditPhaseDetails,
  templateView,
  divisionId,
  onImportBudget,
  enableBuildEstimate,
  formRef,
}) {
  const [selectedPhase, setSelectedPhase] = useState({});
  const [usePhaseCodes, setUsePhaseCodes] = useState(
    labourHours
      || labourCost
      || startDate
      || endDate
      || dataSource.length
      || estimatedMaterialCost
      || estimatedOverheadCost
      || estimatedEquipmentCost
      || contractValue
      || equipment.length
      || templateView,
  );
  const [useDates, setUseDates] = useState(
    hasDates(dataSource) || startDate || endDate,
  );
  const [useBuildEstimate, setUseBuildEstimate] = useState(enableBuildEstimate);

  const [stateHours, setStateHours] = useState(labourHours);
  const [stateCosts, setStateCosts] = useState(labourCost);
  const [stateMaterialCost, setStateMaterialCost] = useState(estimatedMaterialCost);
  const [stateOverheadCost, setStateOverheadCost] = useState(estimatedOverheadCost);
  const [stateEquipmentCost, setStateEquipmentCost] = useState(estimatedEquipmentCost);
  const [stateBurdenRate, setStateBurdenRate] = useState(estimatedBurdenRate);
  const [stateContractValue, setStateContractValue] = useState(contractValue);
  const [selectedCostCodes, setSelectedCostCodes] = useState([]);
  const [showTransferDrawer, setShowTransferDrawer] = useState();
  const [hoursExceeds, setHoursExceeds] = useState(false);
  const [newPhaseCode, setNewPhaseCode] = useState();
  const [newPhaseDescription, setNewPhaseDescription] = useState();
  const [codeRef, setCodeRef] = useState();
  const [selectedPhaseCostCodes, setSelectedPhaseCostCodes] = useState();
  const [importVisible, setImportVisible] = useState(false);

  const categories = useSelector((state) => state.costcodes.categories);
  const projectCostUpdates = useSelector((state) => state.projects.projectCostUpdates);

  const { uncategorizedMaterialEstimates, uncategorizedEquipmentEstimates } = useMemo(() => {
    const equipmentEstimates = [];
    const materialEstimates = [];

    projectCostUpdates.filter((update) => !update.costcodeId && update.isBudgetUpdate)
      .forEach((update) => {
        if (update.type === 'material') {
          materialEstimates.push(update);
        } else {
          equipmentEstimates.push(update);
        }
      });

    return {
      uncategorizedMaterialEstimates: materialEstimates,
      uncategorizedEquipmentEstimates: equipmentEstimates,
    };
  }, [projectCostUpdates]);

  const categorizedCostcodeUpdateMap = useMemo(() => {
    const categorizedEstimates = projectCostUpdates.filter((update) => (
      update.costcodeId && update.isBudgetUpdate
    ));

    return categorizedEstimates.reduce((acc, update) => {
      const key = `${update?.phaseId ?? 'unphased'}.${update.costcodeId}`;
      if (!(key in acc)) {
        acc[key] = [];
      }
      acc[key].push(update);
      return acc;
    }, {});
  }, [projectCostUpdates]);

  useEffect(() => {
    if (codeRef) codeRef.focus();
  }, []);

  const addPhaseCode = useCallback(() => {
    if (!newPhaseCode || !newPhaseDescription) return;
    onAdd({
      name: newPhaseCode,
      description: newPhaseDescription,
      costcodes: selectedCostCodes,
    });
    setNewPhaseCode(null);
    setNewPhaseDescription(null);
    setSelectedCostCodes([]);
    if (codeRef) codeRef.focus();
  }, [
    newPhaseCode,
    newPhaseDescription,
    selectedCostCodes,
    onAdd,
    codeRef,
  ]);

  const openAddDrawer = useCallback((index) => {
    const newSelectedPhase = index === 0
      ? { // Editing the new phase
        name: newPhaseCode,
        description: newPhaseDescription,
        costcodes: selectedCostCodes,
      } : { ...dataSource[index - 1] }; // Editing the existing phase

    setSelectedPhase(newSelectedPhase);
    setShowTransferDrawer(true);
    setSelectedPhaseCostCodes(newSelectedPhase.costcodes);
  }, [
    newPhaseCode,
    newPhaseDescription,
    selectedCostCodes,
    dataSource,
  ]);

  const addDrawerClosed = useCallback((value) => {
    setShowTransferDrawer(false);

    // If cancel, value == undefined. Else value == {}
    if (value === undefined) {
      // Clear selected cost code if user cancels
      setSelectedPhaseCostCodes([]);
    } else {
      // If any of the data fields changed update them
      if (value.phaseName || value.description) {
        // If it's an existing phase, update it
        if (selectedPhase.id !== undefined) {
          onEditPhaseDetails(selectedPhase.id, {
            description: value.description,
            name: value.phaseName,
          });
        // Otherwise it's a new phase
        } else {
          setNewPhaseCode(value.phaseName);
          setNewPhaseDescription(value.description);
        }
      }

      // Update the selected costcodes
      if (selectedPhase.id !== undefined) {
        // Is edit of an existing
        onEditPhaseCostCodes(selectedPhase.id, selectedPhaseCostCodes);
      } else {
        setSelectedCostCodes(selectedPhaseCostCodes);
      }
    }
  }, [
    selectedPhaseCostCodes,
    selectedPhase,
    onEditPhaseDetails,
    onEditPhaseCostCodes,
  ]);

  const onCheckChanged = useCallback(() => {
    const newUsePhaseCodes = !usePhaseCodes;
    setUsePhaseCodes(!usePhaseCodes);
    Analytics.track(`Project/Budget/${newUsePhaseCodes ? 'Enabled' : 'Disabled'}`);
    if (!newUsePhaseCodes) {
      onClearBudget();
    } else if (!nux.has(PROJECT_BUDGET_TYPE)) {
      createNuxEntry(PROJECT_BUDGET_TYPE);
    }
  }, [
    setUsePhaseCodes,
    usePhaseCodes,
    Analytics,
    onClearBudget,
    nux,
    createNuxEntry,
  ]);

  const onDateCheckChanged = useCallback(() => {
    const newUseDates = !useDates;
    setUseDates(newUseDates);
    Analytics.track(`Project/Dates/${newUseDates ? 'Enabled' : 'Disabled'}`);
    if (!newUseDates) {
      onClearDates();
    }
  }, [
    setUseDates,
    useDates,
    Analytics,
    onClearDates,
  ]);

  const getAllocatedHours = useCallback(() => {
    const notificationKey = 'projectHoursExceed';
    if (!stateHours) return {};
    let totalHours = 0;
    unphasedCostcodes.forEach(({ hours = 0 }) => {
      totalHours += hours;
    });

    selectedCostCodes.forEach(({ hours = 0 }) => {
      totalHours += hours;
    });
    dataSource.forEach(({ costcodes: phasedCostcodes = [] }) => {
      phasedCostcodes.forEach(({ hours = 0 }) => {
        totalHours += hours;
      });
    });

    const newHoursExceeds = totalHours > stateHours;

    if (newHoursExceeds && !hoursExceeds && !useBuildEstimate) {
      notification.warn({
        key: notificationKey,
        message: 'Warning',
        description: 'You have allocated more hours than your top level estimate',
      });
      setHoursExceeds(newHoursExceeds);
    } else if (!newHoursExceeds && hoursExceeds) {
      notification.close(notificationKey);
      setHoursExceeds(newHoursExceeds);
    }

    return { totalHours, color: newHoursExceeds ? 'red' : colors.ONTRACCR_BLACK };
  }, [
    stateHours,
    unphasedCostcodes,
    selectedCostCodes,
    dataSource,
    hoursExceeds,
    notification,
    setHoursExceeds,
    useBuildEstimate,
  ]);

  const exportExcel = useCallback(() => onExport(false), []);
  const exportPDF = useCallback(() => onExport(true), []);
  const importExcel = useCallback(() => setImportVisible(true), []);
  const onImportClose = useCallback(() => setImportVisible(false), []);

  const estimateFunctionMap = {
    estimatedLabourHours: setStateHours,
    estimatedLabourCost: setStateCosts,
    estimatedMaterialCost: setStateMaterialCost,
    estimatedOverheadCost: setStateOverheadCost,
    contractValue: setStateContractValue,
    estimatedBurdenRate: setStateBurdenRate,
    estimatedEquipmentCost: setStateEquipmentCost,
  };

  const onImport = useCallback((parsedData) => {
    const { estimates, hasDates: newHasDates } = parsedData;
    if (!usePhaseCodes) {
      onCheckChanged();
    }
    if (!!useDates !== !!newHasDates) {
      onDateCheckChanged();
    }
    Object.keys(estimates).forEach((key) => {
      if (estimateFunctionMap[key]) {
        estimateFunctionMap[key](estimates[key]);
      }
    });
    return onImportBudget(parsedData);
  }, [
    usePhaseCodes,
    onCheckChanged,
    useDates,
    onDateCheckChanged,
    estimateFunctionMap,
    onImportBudget,
  ]);

  const { totalHours: allocatedHours, color: allocatedColor } = getAllocatedHours();

  const showDateRange = (isNotDisplay && useDates) || (!isNotDisplay && startDate && endDate);
  const checkBoxes = isNotDisplay ? (
    <Row gutter={8}>
      {!templateView && (
      <Col>
        <Checkbox
          onChange={onCheckChanged}
          style={{ marginBottom: 10 }}
          checked={usePhaseCodes}
        >
          Track Budget?
        </Checkbox>
      </Col>
      )}
      {!!usePhaseCodes && (
        <Col>
          <Form.Item
            name="enableBuildEstimate"
            valuePropName="checked"
            initialValue={enableBuildEstimate}
          >
            <Checkbox
              style={{ marginBottom: 10 }}
              onChange={() => setUseBuildEstimate(!useBuildEstimate)}
            >
              Build Estimate?
            </Checkbox>
          </Form.Item>
        </Col>
      )}
      <Col>
        {!!usePhaseCodes && (
        <Checkbox
          onChange={onDateCheckChanged}
          style={{ marginBottom: 10 }}
          checked={useDates}
        >
          Add Dates/Timelines?
        </Checkbox>
        )}
      </Col>
    </Row>
  ) : null;

  const estimatedBurdenCost = stateCosts && stateBurdenRate ? (stateCosts * stateBurdenRate) : 0;

  const expectedProfit = (
    stateContractValue
    && stateMaterialCost
    && stateCosts
    && stateOverheadCost
    && stateEquipmentCost ? currencyFormatter(
        stateContractValue
        - stateMaterialCost
        - stateOverheadCost
        - stateCosts
        - stateEquipmentCost
        - estimatedBurdenCost,
      ) : 'N/A'
  );

  const updateValues = useCallback((setter) => (newValue) => setter(newValue));

  // Update the phase costcodes while maintaining existing estimate values
  const updateSelectedPhaseCostcodes = useCallback((value) => {
    const idMap = {};
    selectedPhaseCostCodes.forEach((costcode) => {
      const { id } = costcode;
      idMap[id] = costcode;
    });

    const newSelectedPhaseCostcodes = [];
    value.forEach((costcode) => {
      const { id } = costcode;
      if (idMap[id]) {
        newSelectedPhaseCostcodes.push(idMap[id]);
      } else {
        newSelectedPhaseCostcodes.push(costcode);
      }
    });
    setSelectedPhaseCostCodes(newSelectedPhaseCostcodes);
  }, [selectedPhaseCostCodes, setSelectedPhaseCostCodes]);

  useEffect(() => {
    if (useBuildEstimate) {
      const {
        buildLabourHours,
        buildLabourCost,
        buildMaterialCost,
        buildOverheadCost,
        buildEquipmentCost,
      } = calculateBuildEstimateValues({ categories, unphasedCostcodes, phases: dataSource });

      setStateMaterialCost(buildMaterialCost);
      setStateOverheadCost(buildOverheadCost);
      setStateEquipmentCost(buildEquipmentCost);
      setStateHours(buildLabourHours);
      setStateCosts(buildLabourCost);
      formRef.current.setFieldsValue({
        estimatedMaterialCost: buildMaterialCost,
        estimatedOverheadCost: buildOverheadCost,
        estimatedLabourHours: buildLabourHours,
        estimatedLabourCost: buildLabourCost,
        estimatedEquipmentCost: buildEquipmentCost,
      });
    }
  }, [useBuildEstimate, unphasedCostcodes, dataSource, categories]);

  const { unphasedWithAttachedCost, phasesWithAttachedCost } = useMemo(() => {
    const unphased = unphasedCostcodes.map((costcode) => ({
      ...costcode,
      costUpdates: categorizedCostcodeUpdateMap?.[`unphased.${costcode.id}`],
    }));

    const phases = dataSource.map((phase) => {
      const { id: phaseId, costcodes: phaseCostcodes = [] } = phase;
      const costcodesWithAttachedCost = phaseCostcodes.map((costcode) => {
        const key = `${phaseId}.${costcode.id}`;
        return {
          ...costcode,
          costUpdates: categorizedCostcodeUpdateMap?.[key] ?? [],
        };
      });
      return {
        ...phase,
        costcodes: costcodesWithAttachedCost,
      };
    });

    return {
      unphasedWithAttachedCost: unphased,
      phasesWithAttachedCost: phases,
    };
  }, [unphasedCostcodes, dataSource, categorizedCostcodeUpdateMap]);

  const hasUncategorizedMaterials = !!uncategorizedMaterialEstimates?.length;
  const hasUncategorizedEquipment = !!uncategorizedEquipmentEstimates?.length;

  return (
    <div style={{
      position: 'absolute',
      top: 120,
      left: 20,
      right: 20,
      bottom: 50,
      overflowX: 'scroll',
    }}
    >
      {checkBoxes}
      {(usePhaseCodes || !isNotDisplay) && (
      <div>
        {!templateView && (
          <div>
            <Row style={{ width: '100%' }} justify="space-between" gutter={20}>

              <Col span={6}>
                <Form.Item
                  name="estimatedLabourHours"
                  label="Estimated Labour Hours"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: false, message: 'Please enter the estimated labour hours' }]}
                >
                  {(isNotDisplay && !useBuildEstimate) ? (
                    <OnTraccrNumberInput
                      min={1}
                      style={{ width: '100%' }}
                      onChange={updateValues(setStateHours)}
                    />
                  ) : <DisplayText title={stateHours ? `${stateHours} hours` : 'N/A'} />}
                </Form.Item>
              </Col>
              <Col span={6}>
                <ProjectCurrencyInput
                  name="estimatedLabourCost"
                  title="Estimated Labor Cost"
                  isNotDisplay={isNotDisplay && !useBuildEstimate}
                  onChange={updateValues(setStateCosts)}
                  value={useBuildEstimate ? stateCosts : labourCost}
                />
              </Col>
              <Col span={6}>
                <Form.Item
                  name="averageLabourRate"
                  label="Average Labour Rate"
                  style={{ marginBottom: 0 }}
                >
                  <DisplayText title={stateCosts && stateHours ? `$ ${(stateCosts / stateHours).toFixed(2)}/hr` : 'N/A'} />
                </Form.Item>
              </Col>
              <Col span={6}>
                { projectId && (
                  <MoreButton
                    options={[
                      {
                        title: 'Export to Excel',
                        icon: 'file-excel',
                        style: { padding: 0 },
                        onClick: exportExcel,
                      },
                      {
                        title: 'Export to PDF',
                        icon: 'file-text',
                        style: { padding: 0 },
                        onClick: exportPDF,
                      },
                      ...(isNotDisplay ? [{
                        title: 'Import from Excel',
                        icon: 'file-excel',
                        style: { padding: 0 },
                        onClick: importExcel,
                      }] : []),
                    ]}
                  />
                )}
              </Col>
            </Row>
            <Row style={{ width: '100%' }} justify="start" gutter={20}>
              <Col span={6}>
                <ProjectCurrencyInput
                  name="estimatedMaterialCost"
                  title="Estimated Material Cost"
                  isNotDisplay={isNotDisplay && !useBuildEstimate}
                  onChange={updateValues(setStateMaterialCost)}
                  value={useBuildEstimate ? stateMaterialCost : estimatedMaterialCost}
                />
              </Col>
              <Col span={6}>
                <ProjectCurrencyInput
                  name="estimatedOverheadCost"
                  title="Estimated Overhead Cost"
                  isNotDisplay={isNotDisplay && !useBuildEstimate}
                  onChange={updateValues(setStateOverheadCost)}
                  value={useBuildEstimate ? stateOverheadCost : estimatedOverheadCost}
                />
              </Col>
              <Col span={6}>
                <Form.Item
                  name="estimatedBurdenRate"
                  label="Burden Rate"
                  style={{ marginBottom: 0 }}
                >
                  {isNotDisplay ? (
                    <OnTraccrNumberInput
                      min={0}
                      max={1}
                      step={0.01}
                      style={{ width: '100%' }}
                      onChange={updateValues(setStateBurdenRate)}
                    />
                  ) : <DisplayText title={stateBurdenRate?.toFixed(2) ?? 'N/A'} />}
                </Form.Item>
              </Col>
              <Col span={6}>
                <Form.Item
                  name="estimatedBurdenCost"
                  label="Estimated Burden Cost"
                  style={{ marginBottom: 0 }}
                >
                  <DisplayText title={estimatedBurdenCost ? currencyFormatter(estimatedBurdenCost) : 'N/A'} />
                </Form.Item>
              </Col>
            </Row>
            <Row style={{ width: '100%' }} justify="space-between" gutter={20}>
              <Col span={6}>
                <ProjectCurrencyInput
                  name="estimatedEquipmentCost"
                  title="Estimated Equipment Cost"
                  isNotDisplay={isNotDisplay && !useBuildEstimate}
                  onChange={updateValues(setStateEquipmentCost)}
                  value={useBuildEstimate ? stateEquipmentCost : estimatedEquipmentCost}
                />
              </Col>
              <Col span={6}>
                <ProjectCurrencyInput
                  name="contractValue"
                  title="Contract Value"
                  isNotDisplay={isNotDisplay}
                  onChange={updateValues(setStateContractValue)}
                  value={contractValue}
                />
              </Col>
              <Col span={6}>
                <Form.Item
                  name="Expected Profit"
                  label="Expected Profit"
                  style={{ marginBottom: 0 }}
                >
                  <DisplayText title={expectedProfit} />
                </Form.Item>
              </Col>
              <Col span={6}>
                {showDateRange && (
                  <Form.Item
                    name="dateRange"
                    label="Start and End Date"
                    style={{ marginBottom: 0 }}
                  >
                    {isNotDisplay ? <DatePicker.RangePicker /> : <DisplayText title={startDate && endDate ? `${startDate} -> ${endDate}` : ''} />}
                  </Form.Item>
                )}
              </Col>
              <Col span={6}>
                {isNotDisplay && !!stateHours && (
                  <Form.Item
                    name="hoursAllocated"
                    label="Hours Allocated"
                    style={{ marginBottom: 0 }}
                  >
                    <DisplayText
                      title={`${allocatedHours} / ${stateHours}`}
                      style={{
                        color: allocatedColor,
                      }}
                    />
                  </Form.Item>
                )}
              </Col>
            </Row>
          </div>
        )}
        <Row
          type="flex"
          justify="end"
          align="middle"
          style={{ marginBottom: 10 }}
        >
          {isNotDisplay && (
            <Col style={{ visibility: dataSource.length > 0 ? 'visible' : 'hidden' }}>
              <BorderlessButton
                title="Clear"
                icon="close-circle"
                color={colors.ONTRACCR_RED}
                onClick={onListClear}
              />
            </Col>
          )}
        </Row>
        {
        Permissions.match('PURCHASE_ORDERS')
          && (
          <ProjectSubcontracts
            projectId={projectId}
            isNotDisplay={isNotDisplay}
          />
          )
        }
        {hasUncategorizedMaterials && (
          <Table
            title={() => 'Uncategorized Material Cost Estimates'}
            columns={getMaterialCostColumns()}
            dataSource={uncategorizedMaterialEstimates}
            size="small"
            pagination={false}
            style={{ marginBottom: 50 }}
            rowKey="id"
          />
        )}
        {hasUncategorizedEquipment && (
          <Table
            title={() => 'Uncategorized Equipment Cost Estimates'}
            columns={getEquipmentCostColumns()}
            dataSource={uncategorizedEquipmentEstimates}
            size="small"
            pagination={false}
            style={{ marginBottom: 50 }}
            rowKey="id"
          />
        )}
        <ProjectBudgetTrackingUnphased
          isNotDisplay={isNotDisplay}
          onEditCostcodeEstimateChanged={onEditCostcodeEstimateChanged}
          unphasedCostcodes={unphasedWithAttachedCost}
          categories={categories}
          useBuildEstimate={useBuildEstimate}
        />
        <ProjectBudgetTrackingPhases
          isNotDisplay={isNotDisplay}
          dataSource={phasesWithAttachedCost}
          selectedPhaseCostCodes={selectedPhaseCostCodes}
          onEditPhaseCostCodes={onEditPhaseCostCodes}
          onEditPhaseDetails={onEditPhaseDetails}
          onRemove={onRemove}
          openAddDrawer={openAddDrawer}
          setSelectedCostCodes={setSelectedCostCodes}
          setNewPhaseDescription={setNewPhaseDescription}
          setCodeRef={setCodeRef}
          setNewPhaseCode={setNewPhaseCode}
          addPhaseCode={addPhaseCode}
          newPhaseCode={newPhaseCode}
          newPhaseDescription={newPhaseDescription}
          useDates={useDates}
          selectedCostCodes={selectedCostCodes}
          categories={categories}
          style={style}
          useBuildEstimate={useBuildEstimate}
        />
      </div>
      )}
      <ProjectAddPhaseCodeDrawer
        onDrawerClosed={addDrawerClosed}
        onSelectedCostCodesChanged={updateSelectedPhaseCostcodes}
        visible={showTransferDrawer}
        selectedPhase={selectedPhase}
        costcodes={costcodes}
      />
      <ProjectBudgetTrackingImportDrawer
        divisionId={divisionId}
        visible={importVisible}
        onClose={onImportClose}
        onImport={onImport}
      />
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
ProjectBudgetTracking.propTypes = {
  id: PropTypes.string,
  dataSource: PropTypes.array,
  costcodes: PropTypes.array,
  style: PropTypes.object,
  onListClear: PropTypes.func.isRequired,
  isNotDisplay: PropTypes.bool.isRequired,
  labourHours: PropTypes.number,
  labourCost: PropTypes.number,
  estimatedMaterialCost: PropTypes.number,
  estimatedOverheadCost: PropTypes.number,
  estimatedEquipmentCost: PropTypes.number,
  estimatedBurdenRate: PropTypes.number,
  contractValue: PropTypes.number,
  startDate: PropTypes.instanceOf(DateTime),
  endDate: PropTypes.instanceOf(DateTime),
  equipment: PropTypes.array,
  onExport: PropTypes.func.isRequired,
  unphasedCostcodes: PropTypes.array,
  nux: PropTypes.instanceOf(Set),
  createNuxEntry: PropTypes.func.isRequired,
  onAdd: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  onEditCostcodeEstimateChanged: PropTypes.func.isRequired,
  onEditPhaseCostCodes: PropTypes.func.isRequired,
  onClearDates: PropTypes.func.isRequired,
  onClearBudget: PropTypes.func.isRequired,
  onEditPhaseDetails: PropTypes.func.isRequired,
  templateView: PropTypes.bool,
  divisionId: PropTypes.string.isRequired,
  onImportBudget: PropTypes.func.isRequired,
  enableBuildEstimate: PropTypes.bool,
  formRef: PropTypes.object.isRequired,
};

ProjectBudgetTracking.defaultProps = {
  id: null,
  dataSource: [],
  costcodes: [],
  style: {},
  equipment: [],
  unphasedCostcodes: [],
  nux: new Set(),
  templateView: false,
  endDate: null,
  startDate: null,
  contractValue: null,
  estimatedOverheadCost: null,
  estimatedMaterialCost: null,
  estimatedEquipmentCost: null,
  estimatedBurdenRate: null,
  labourCost: null,
  labourHours: null,
  enableBuildEstimate: false,
};

export default ProjectBudgetTracking;
