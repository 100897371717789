import React from 'react';

import Badge from '../badge';

const badgeStyle = {
  paddingTop: '0',
  paddingBottom:'1px',
  fontSize: '12px',
  lineHeight:'20px',
  paddingRight:0,
};

export default ({
  title,
  unreadCount,
}) => (
  <div style={{ display: 'flex', flexDirection:'row' }}>
    {title}{unreadCount ? <Badge count={unreadCount} style={badgeStyle}/> : <></>}
  </div>
)