import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, Col, Row } from 'antd';
import PhotoReelPreview from '../../common/photos/PhotoReelPreview';
import ManualEntryCardDetails from '../../clock/ManualEntry/ManualEntryCardDetails';

const TimeCardPhotoEntry = function TimeCardPhotoEntry({
  entry = {},
  photos = {},
  onPreview,
}) {
  const {
    date,
    hourBased,
    startTime,
    endTime,
    breakStartTime,
    breakEndTime,
    otStartTime,
    otEndTime,
    doubleOTStartTime,
    doubleOTEndTime,
    type,
    projectId,
    phaseId,
    costcodeId,
    localId,
    classId,
    sageShiftId,
  } = entry;
  const { clockInPhoto, clockOutPhoto } = photos;

  const onClick = useCallback((photo) => (e) => {
    e.stopPropagation();
    onPreview(photo);
  });

  return (
    <Card
      className="manual-entry-card"
    >
      <Row justify="space-between" span={24}>
        <Col span={12}>
          <ManualEntryCardDetails
            date={date}
            startTime={startTime}
            endTime={endTime}
            otStartTime={otStartTime}
            otEndTime={otEndTime}
            doubleOTStartTime={doubleOTStartTime}
            doubleOTEndTime={doubleOTEndTime}
            breakStartTime={breakStartTime}
            breakEndTime={breakEndTime}
            projectId={projectId}
            phaseId={phaseId}
            costcodeId={costcodeId}
            localId={localId}
            classId={classId}
            sageShiftId={sageShiftId}
            type={type}
            hourBased={hourBased}
          />
        </Col>
        <Col span={2} />
        <Col span={10}>
          <Row span={10} justify="end">
            <Col span={5}>
              <Row justify="center">
                Clock In
              </Row>
              {clockInPhoto && (
                <Row justify="center">
                  <PhotoReelPreview
                    file={clockInPhoto.file}
                    key={clockInPhoto.name}
                    onClick={onClick(clockInPhoto)}
                  />
                </Row>
              )}
            </Col>
            <Col span={5}>
              <Row justify="center">
                Clock Out
              </Row>
              {clockOutPhoto && (
                <Row justify="center">
                  <PhotoReelPreview
                    file={clockOutPhoto.file}
                    key={clockOutPhoto.name}
                    onClick={onClick(clockOutPhoto)}
                  />
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Card>
  );
};

TimeCardPhotoEntry.propTypes = {
  entry: PropTypes.object,
  photos: PropTypes.object,
  onPreview: PropTypes.func,
};

TimeCardPhotoEntry.defaultProps = {
  entry: {},
  photos: {},
  onPreview: () => {},
};

export default TimeCardPhotoEntry;
