import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Row,
  Col,
  Breadcrumb,
  Popover,
  Spin,
} from 'antd';
import {
  FolderOutlined,
  GoldOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import PaginatedTable from '../../../common/containers/PaginatedTable';

import TableAddDrawer from './TableAddDrawer';

import { includesTerm } from '../../../helpers/helpers';

import { getProjects } from '../../../projects/state/projects.actions';
import useValidateMaxSelect from '../../../common/hooks/useValidateMaxSelect';

const cols = [
  {
    title: 'Name',
    dataIndex:'name',
    render: (_,record) => (
      <Row
          gutter={10}
          justify='start'
          style={{ paddingLeft: 10 }}
        >
          <Col>
            {record.isFolder ? <FolderOutlined/> : <GoldOutlined/>}
          </Col>
          <Col>
            {record.name}
          </Col>
        </Row>
    )
  }
];

const BASE_CRUMBS = [{
  title: 'Change Orders',
  projectId: null,
}];

export default function ChangeOrderDrawer({
  visible,
  onClose,
  onSubmit,
  changeOrderMap = {},
  maxExistingAllowed,
}) {
  const dispatch = useDispatch();
  const projects = useSelector(state => state.projects.projects);
  const [ searchStr,setSearchStr ] = useState();
  const [ loading, setLoading ] = useState(false);
  const [ selected, setSelected ] = useState([]);
  const [crumbs, setCrumbs] = useState(BASE_CRUMBS);

  const selectedRowKeys = useMemo(() => selected.map((s) => s.id), [selected]);
  const onSubmitKeys = useMemo(() => selected.map((s) => s.projectRowId), [selected]);

  const onCrumbClicked = useCallback((index) => () => {
    setCrumbs(crumbs.slice(0,index + 1));
  },[crumbs]);

  const onRowClick = (record) => {
    if(record.isFolder) {
      const newCrumbs = crumbs.concat([{
        title: record.name,
        projectId: record.id,
      }]);
      setCrumbs(newCrumbs)
    }
  };

  const onSelectRow = useCallback((record,checked,) => {
    record.projectRowId = `${record.projectId}.${record.id}`;

    if(checked) {
      setSelected(selected.concat(record))
    } else {
      setSelected(selected.filter((s) => s.id !== record.id))
    }
  },[selected]);

  const onSubmitClicked = useCallback(() => {
    const isValid = useValidateMaxSelect({
      maxExistingAllowed,
      selected: onSubmitKeys,
      type: 'change order(s)',
    });
    if (isValid) onSubmit(onSubmitKeys);
  },[onSubmit, onSubmitKeys, maxExistingAllowed]);

  useEffect(() => {
    if(!visible) {
      setSearchStr();
      setCrumbs(BASE_CRUMBS)
    }
  },[visible]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getProjects());
      setLoading(false);
    }
    if(visible && dispatch) load();
  },[dispatch, visible]);

  const data = useMemo(() => {
    if (!changeOrderMap) {
      return [];
    }

    const { projectId } = crumbs[crumbs.length - 1];
    const newChangeOrders = [];
    const projectMap = {};

    if (!projectId) {
      projects.forEach((project) => {
        if (project.id in changeOrderMap) {
          projectMap[project.id] = {
            ...project,
            isFolder: 1,
          };
        }
      });
    }

    const {
      [projectId]: changeOrders = [],
    } = changeOrderMap;

    changeOrders.forEach((changeOrder) => {
      if (changeOrder.projectId !== projectId || !includesTerm(changeOrder.name, searchStr)) return;

      newChangeOrders.push({
        ...changeOrder,
        name: `CO${changeOrder.itemNumber}: ${changeOrder.description}`,
      });
    });

    newChangeOrders.sort((a, b) => a.itemNumber - b.itemNumber);
    return Object.values(projectMap).concat(newChangeOrders);
  },[changeOrderMap, projects, searchStr, crumbs]);

  return (
    <TableAddDrawer
      title='Add Change Orders'
      visible={visible}
      onClose={onClose}
      onSearch={setSearchStr}
      onSubmit={onSubmitClicked}
      searchStr={searchStr}
    >
      <Breadcrumb
        separator={ <span style={{ fontSize: 14 }}>/</span> }
      >
        {
          crumbs.map((crumb,index) => (
            <Breadcrumb.Item
              onClick={index < crumbs.length - 1 ? onCrumbClicked(index) : null}
              className={index < crumbs.length - 1 ? 'material-add-drawer-breadcrumb-clickable' : 'material-add-drawer-breadcrumb'}
            >
              {crumb.title}
            </Breadcrumb.Item>
          ))
        }
      </Breadcrumb>
      {
        loading ?
          <Row justify='center' align='middle' style={{ height: '75%', width: '100%' }}>
            <Spin/>
          </Row>
        : <PaginatedTable
            dataSource={data}
            rowKey='id'
            showHeader={false}
            columns={cols}
            size='small'
            scrollOffset={290}
            onRow={(record) => ({
              onClick:() => onRowClick(record)
            })}
            rowClassName='materials-table-row'
            rowSelection={{
              fixed:true,
              hideSelectAll: true,
              onSelect: onSelectRow,
              selectedRowKeys,
              getCheckboxProps: (record) => ({
                disabled: record.isFolder
              }),
          }}
        />
    }
    <Popover
      placement='bottomRight'
      title='Selected Change Orders'
      content={
        <div style={{ maxHeight: 500, overflowY: 'auto' }}>
          {selected.map((s) => <div>{s.itemNumber + ': ' + s.description}</div>)}
        </div>
      }
    >
      <div style={{ width: 'fit-content', cursor: 'pointer' }}>
        {selected.length} change order{selected.length === 1 ? '' : 's'} selected
      </div>
    </Popover>
    </TableAddDrawer>
  )
}

ChangeOrderDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  changeOrderMap: PropTypes.shape({}),
  maxExistingAllowed: PropTypes.number,
};

ChangeOrderDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
  onSubmit: () => {},
  changeOrderMap: {},
  maxExistingAllowed: null,
};
