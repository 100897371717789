import { Row } from 'antd';
import { DateTime } from 'luxon';

import PhotoReel from '../common/photos/PhotoReel';

import { timestampsToText } from '../boards/boards.helpers';

export default (field = {}) => {
  const {
    selectedType: fieldType,
    response,
    onFileClick,
  } = field;
  switch (fieldType) {
    case 'text':
    case 'calculation':
      return response.value ?? '';
    case 'dropdown': {
      const { values = [] } = response;
      return values.map((val) => val.name).join(', ');
    }
    case 'yes-no':
      const { value, explanation } = response;
      let ans = null;
      // value === undefined if user didnt fill out the field
      if (value === true) {
        ans = 'Yes';
      } else if (value === false) {
        ans = 'No';
      }
      if (explanation) ans += `. ${explanation}`;
      return ans;
    case 'dateRange': return timestampsToText(response);
    case 'dateTime':
      const {
        date,
        time,
        timezone = DateTime.local().zoneName,
      } = response;
      if (!date || Number.isNaN(date)) return null;
      const dt = DateTime.fromMillis(date, { zone: timezone });
      let val = `${dt.toLocaleString(DateTime.DATE_MED)}`;
      if (time && !Number.isNaN(time)) {
        const tt = DateTime.fromMillis(time, { zone: timezone });
        val += ` ${tt.toLocaleString(DateTime.TIME_SIMPLE)}`;
      }
      return val;
    case 'attachment':
      const { files = [] } = response;
      return (
        <Row style={{ width: '100vw' }}>
          <PhotoReel
            small
            files={files}
            onClick={(idx) => {
              if(idx < files.length) {
                onFileClick(files[idx])
              }
            }}
          />
        </Row>
      );
    default:
      return null;
  }
}

