import React from 'react';
import { Menu } from 'antd';
import BorderlessButton from '../buttons/BorderlessButton';

export default {};

export const getSortMenu = (sortOptions,sortActive) => (
<Menu defaultSelectedKeys={sortActive}>
  {sortOptions.map(({
    title,icon,action
  }) => (
    <Menu.Item key={title}>
      <BorderlessButton 
        title={title}
        icon={icon}
        style={{ 
          padding:0,
          fontFamily: title === sortActive ? 'roboto-regular' : 'roboto-light' 
        }}
        onClick={action}
      />
    </Menu.Item>))}
</Menu> 
);
