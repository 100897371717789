import React from 'react';

import { Button, Col, Row } from 'antd';
import { PropTypes } from 'prop-types';
import Colors from '../../constants/Colors';

export default function SelectButton({
  value,
  onChange,
  options = [],
  style = {},
}) {
  const handleClick = (id) => () => {
    if (id === value) {
      onChange(null);
    } else {
      onChange(id);
    }
  };
  return (
    <Row>
      {options.map(((option) => {
        const {
          name,
          id,
        } = option;
        return (
          <Col>
            <Button
              value={id}
              onClick={handleClick(id)}
              style={{
                borderRadius: '0em',
                width: '6em',
                backgroundColor: value === id ? Colors.ONTRACCR_RED : 'transparent',
                color: value === id ? 'white' : 'black',
                ...style,
              }}
            >
              {name}
            </Button>
          </Col>
        );
      }))}
    </Row>
  );
}

SelectButton.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    id: PropTypes.string,
  })),
  style: PropTypes.shape({}),
};

SelectButton.defaultProps = {
  value: null,
  options: [],
  style: {},
};
