import React from 'react';
import { Tag } from 'antd';
import PropTypes from 'prop-types';

export default function IntegrationTag({
  id,
  title,
}) {
  if (!id) return null;

  return (
    <Tag
      key={id}
      style={{
        maxWidth: 325,
        margin: 3,
        overflow: 'hidden',
      }}
    >
      {title}
    </Tag>
  );
}

IntegrationTag.propTypes = {
  id: PropTypes.string,
  title: PropTypes.string.isRequired,
};

IntegrationTag.defaultProps = {
  id: null,
};
