import axios from 'axios';

import { request } from '../../helpers/requests';

export default {
  get: (params) => request({
    call: axios.get('/analytics', { params }),
    errMsg: 'Could not get analytics data',
    hideSuccessToast: true,
  }),
  create: (payload) => request({
    call: axios.post('/analytics', payload),
    errMsg: 'Could not create analytics report',
  }),
  update: (id, payload) => request({
    call: axios.put(`/analytics/${id}`, payload),
    errMsg: 'Could not update analytics report',
  }),
  delete: (id) => request({
    call: axios.delete(`/analytics/${id}`),
    errMsg: 'Could not delete analytics reports',
  }),
  getSaved: (analyticsType) => request({
    call: axios.get('/analytics/saved', { params: { analyticsType } }),
    errMsg: 'Could not get saved analytics reports',
    hideSuccessToast: true,
  }),
};
