import {
  GET_ALL_NOTIFICATIONS,
  CREATE_NOTIFICATION,
  DELETE_NOTIFICATION,
  MARK_READ_NOTIFICATION,
} from '../../state/actionTypes';


const initialState = {
  notifications: [],
};

export default function notificationsActions(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_NOTIFICATIONS:
    case CREATE_NOTIFICATION:
      return {
        ...state,
        notifications: action.payload.notifications,
      };
    case DELETE_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.filter((n) => n.id !== action.payload.id),
      };
    case MARK_READ_NOTIFICATION:
      return {
        ...state,
        notifications: state.notifications.map((n) => (n.id === action.payload.id ? { ...n, read: 1 } : n)),
      };
    default:
      return state;
  }
}
