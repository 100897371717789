import React, { useState, useEffect } from 'react';
import { Tabs } from 'antd';
import PropTypes from 'prop-types';
import ActionCenterCard from './ActionCenterCard';
import { defaultRoles } from './ActionCenterConstants';

const { TabPane } = Tabs;

const compareStrings = (a, b) => a.localeCompare(b);

const sortRoles = (positions) => {
  const positionNames = Object.keys(positions);
  const customRoles = positionNames.filter((name) => !defaultRoles.has(name));
  const sorted = [...customRoles].sort(compareStrings);
  return [...defaultRoles, ...sorted];
};

const ActionCenter = ({ positions: initialPositions, acPositions: initialAcPositions, onCheckChanged, onOrderChanged }) => {
  const [activeKey, setActiveKey] = useState('0');
  const [positions, setPositions] = useState(initialPositions);
  const [acPositions, setAcPositions] = useState(initialAcPositions);

  useEffect(() => {
    setAcPositions(initialAcPositions);
  }, [initialAcPositions]);

  const tabChanged = (tab) => {
    const numPositions = Object.keys(acPositions).length;
    if (tab === numPositions.toString()) return;
    setActiveKey(tab);
  };

  const positionNames = sortRoles(acPositions);

  return (
    <div>
      <Tabs
        defaultActiveKey='0'
        style={{ height: '90vh' }}
        tabPosition={'left'}
        tabBarStyle={{
          marginTop: 15,
          width: 135,
        }}
        onChange={tabChanged}
        activeKey={activeKey}
      >
        {positionNames.map((role, index) => (
          <TabPane tab={role} key={index}>
            <ActionCenterCard
              roleName={role}
              acPositions={acPositions}
              positions={positions}
              onCheckChanged={(permission) => onCheckChanged(role, permission)}
              onOrderChanged={onOrderChanged}
            />
          </TabPane>
        ))}
      </Tabs>
    </div>
  );
};

ActionCenter.propTypes = {
  positions: PropTypes.object.isRequired,
  acPositions: PropTypes.object.isRequired,
  onCheckChanged: PropTypes.func.isRequired,
  onOrderChanged: PropTypes.func.isRequired,
};

export default ActionCenter;
