import React from 'react';
import { Row, Col, List, Select } from 'antd';

import CustomModal from '../common/modals/CustomModal';
import ProfileAvatar from '../common/ProfileAvatar';
import DisplayText from '../common/text/DisplayText';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import colors from '../constants/Colors';

const getButton = (color) => ({
  backgroundColor: color,
  borderColor: color,
  color: 'white',
  borderRadius: 18,
  width: 110,
  height: 36,
});


export default ({
  role,
  visible,
  onCloseClicked,
  users = [],
  roleNames = [],
  onNewRoleChange:onChange = () => 1,
  onSubmit = () => 1,
  newRole,
}) => (
  <CustomModal
    title={`Delete ${role} role?`}
    visible={visible}
    maskColor={'#00000073'}
    onCloseClicked={onCloseClicked}
    afterClose={onCloseClicked}
  >
    <Row style={{width:'100%'}} justify='center'>
      <DisplayText title={`${users.length} Affected User${users.length > 1 ? 's':''}`} style={{ border: 'none'}}/>
      <List
        style={{width:'100%' , marginLeft: 20, marginRight: 20 }}
        dataSource={users}
        renderItem={item => (
          <List.Item>
             <ProfileAvatar key={item.id} {...{...item,role:item.position}} style={{ marginTop:2, marginBottom:2,}}/>
          </List.Item>
        )}
      />
      <Select
        className='OnTraccrSelect'
        key='new-role-select'
        style={{ width: '100%', margin: 20, maxHeight:200 }}
        placeholder='Select new role for above users'
        onChange={onChange}
        optionLabelProp='label'
        value={newRole}
      >
        {roleNames.filter((name) => name !== role).map((item) => 
          <Select.Option key={item} value={item} label={item}>
            {item}
        </Select.Option>)}
      </Select>
      <Row type='flex' justify='center' gutter={16} style={{
          width:'100%',
          marginTop:5,
          marginBottom:25,
        }}>
        <Col>
          <OnTraccrButton
            title='Cancel'
            onClick={onCloseClicked}
            type='Cancel'
            style={getButton('gray')}
            />
        </Col>
        <Col>
          <OnTraccrButton 
            title='Confirm'
            disabled={!newRole}
            onClick={onSubmit}
            type='primary'
            style={getButton(colors.ONTRACCR_RED)}
            />
        </Col>
      </Row>
    </Row>
    
  </CustomModal>
)