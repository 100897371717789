import React from 'react';

import Permissions from '../../auth/Permissions';

import FilteredFormList from '../../forms/FilteredFormList';

export default ({
  id,
  isNotDisplay,
  visible,
}) => (
  <FilteredFormList
    formType='Work Order'
    filterId={id}
    filterType='Customers'
    isNotDisplay={isNotDisplay}
    topOffset={Permissions.hasWrite('customers') ? 240 : 187}
    visible={visible}
  />
);
