import React, {
  useCallback, useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Checkbox, Drawer, Form, Select, message,
} from 'antd';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../../common/inputs/FormTextInput';
import DivisionSelector from '../../../common/inputs/DivisionSelector';
import FormColorPicker from '../../../common/inputs/FormColorPicker';

import SalesRabbitFieldMapping from './SalesRabbitFieldMapping';

import {
  SALES_RABBIT_WORKFLOWS,
  REQUIRED_FIELDS,
  getProjectFields,
} from './salesrabbit.constants';

import {
  TITLE_FIELD,
} from '../../../boards/boardWorkflowConfig';

import { getBoards } from '../../../boards/state/boards.actions';
import {
  createSalesRabbitWorkflows,
  updateSalesRabbitWorkflows,
} from './state/salesrabbit.actions';

import sortByString from '../../../helpers/helpers';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

function SalesRabbitWorkflowDrawer({
  visible,
  onClose,
  onDelete,
  selectedWorkflow,
}) {
  const { t } = useTranslation();
  const title = selectedWorkflow
    ? `Edit ${selectedWorkflow.title}`
    : 'Add SalesRabbit Workflow';

  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const boards = useSelector((state) => state.boards.boards);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const divisions = useSelector((state) => state.settings.divisions);

  const [salesRabbitStatuses, setSalesRabbitStatuses] = useState([]);
  const [salesRabbitFields, setSalesRabbitFields] = useState({});
  const [formValues, setFormValues] = useState({});
  const [lastBoardId, setLastBoardId] = useState(
    selectedWorkflow && selectedWorkflow.createCard
      ? selectedWorkflow.targetBoardId
      : null,
  );
  const [boardStatuses,setBoardStatuses] = useState([]);
  const [loading, setLoading] = useState(false);

  const multiDivision = Object.keys(divisions).length > 1;
  const {
    [formValues.targetBoardId]: {
      cardTypeId,
    } = {},
  } = boards;

  const cardFields = useMemo(() => {
    const {
      [cardTypeId]: {
        fields: sections = [],
      } = {},
    } = cardTemplates;

    const formFields = sections.map((section) => {
      const { fields = [] } = section;
      return fields;
    }).flat();
    return [TITLE_FIELD].concat(formFields).map((field) => {
      const {
        configProps: { title: label } = {},
        selectedType,
        id,
      } = field;
      return { value: id, label, type: selectedType };
    });
  }, [cardTemplates, cardTypeId]);

  const boardList = useMemo(() => {
    const bl = Object.values(boards)
      .filter((board) => board.divisions?.includes?.(formValues.divisionId));
    bl.sort(sortByString('title'));
    return bl.map((board) => ({ value: board.id, label: board.title }));
  }, [boards, formValues]);

  const onDeleteClicked = useCallback(() => {
    if (!onDelete || !selectedWorkflow) return;
    onDelete(selectedWorkflow);
  }, [onDelete, selectedWorkflow]);

  const onSubmit = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      // no-op
    }
    if (!values) return;
    setLoading(true);

    const divIds = Object.keys(divisions);
    let payload = values;
    if (!payload.divisionId) {
      if (divIds.length === 1) {
        payload = { ...values, divisionId: divIds[0] };
      } else {
        message.error('Division is required');
        return;
      }
    }

    const action = selectedWorkflow
      ? dispatch(updateSalesRabbitWorkflows(selectedWorkflow.id, payload))
      : dispatch(createSalesRabbitWorkflows(payload));
    const res = await action;
    setLoading(false);
    if (res) onClose();
  }, [selectedWorkflow, divisions]);

  const onValuesChange = useCallback((_, allValues) => {
    const {
      divisionId: oldDivisionId,
      targetBoardId: oldTargetBoardId,
    } = formValues;
    const {
      divisionId: newDivisionId,
      targetBoardId: newTargetBoardId,
    } = allValues;
    if (oldDivisionId && oldDivisionId !== newDivisionId) {
      // Reset data on division change
      const newValues = {
        title: allValues.title,
        type: allValues.type,
        divisionId: newDivisionId,
        triggerSalesRabbitStatusId: null,
        createProject: false,
        projectMappings: undefined,
        createCard: false,
        targetBoardId: null,
        cardMappings: undefined,
      };
      form.setFieldsValue(newValues);
      setFormValues(newValues);
      return;
    }
    if (oldTargetBoardId !== newTargetBoardId) {
      const newValues = {
        ...allValues,
        initialStatusId: null,
      };
      form.setFieldsValue(newValues);
      setFormValues(newValues);
    }
    setFormValues(allValues);
  }, [form, formValues]);

  useEffect(() => {
    const getSalesRabbitData = async () => {
      try {
        const { data: srStatuses = [] } = await axios.get('/salesrabbit/lead/statuses');
        const { data: fields = [] } = await axios.get('/salesrabbit/customFields');
        setSalesRabbitStatuses(
          srStatuses.map((status) => ({ label: status.name, value: status.id })),
        );
        setSalesRabbitFields(fields);
      } catch (err) {
        setSalesRabbitStatuses([]);
        setSalesRabbitFields([]);
      }
    };

    if (!visible) {
      form.resetFields();
      setBoardStatuses([]);
      setFormValues({});
      setLastBoardId();
    } else {
      getSalesRabbitData();
      dispatch(getBoards());
    }
  }, [visible, form]);

  useEffect(() => {
    if (visible && selectedWorkflow) {
      setFormValues(selectedWorkflow);
      form.setFieldsValue(selectedWorkflow);
    }
  }, [visible, selectedWorkflow, form]);

  useEffect(() => {
    const getStatuses = async (boardId) => {
      try {
        const {
          data: {
            statuses: bStatuses = [],
          } = {},
        } = await axios.get(`/boards/${boardId}`);
        setBoardStatuses(
          bStatuses.map((status) => ({ label: status.title, value: status.id })),
        );
      } catch (err) {
        setBoardStatuses([]);
      }
    };

    if (formValues && formValues.createCard) {
      const currentBoardId = formValues.targetBoardId;
      if (currentBoardId && currentBoardId !== lastBoardId) {
        getStatuses(currentBoardId);
      }
    }
  }, [formValues, lastBoardId]);

  const PROJECT_FIELDS = getProjectFields(t);

  return (
    <Drawer
      title={title}
      visible={visible}
      onClose={onClose}
      width={800}
      maskClosable={false}
    >
      <Form
        form={form}
        onValuesChange={onValuesChange}
        layout="vertical"
        style={{ marginBottom: 50 }}
      >
        <FormTextInput
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Title is required' }]}
          isNotDisplay
        />
        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Type is required' }]}
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
        >
          <Select options={SALES_RABBIT_WORKFLOWS} />
        </Form.Item>

        {multiDivision && (
          <Form.Item
            name="divisionId"
            label="Division"
            rules={[{ required: multiDivision, message: 'Division is required' }]}
            labelCol={formLabelStyle}
            style={{ marginBottom: 0 }}
            valuePropName="divisionId"
          >
            <DivisionSelector allowClear={false} />
          </Form.Item>
        )}

        <Form.Item
          name="triggerSalesRabbitStatusId"
          label="Trigger SalesRabbit Status"
          rules={[{ required: true, message: 'Trigger SalesRabbit Status is required' }]}
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
        >
          <Select options={salesRabbitStatuses} />
        </Form.Item>

        <Form.Item
          name="createProject"
          label="Create Project?"
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        {
          formValues.createProject && (
            <Form.Item
              name="projectMappings"
              label="Project Field Mappings"
              rules={[{ required: formValues.createProject, message: 'Project Mappings are required' }]}
              style={{ marginTop: 15 }}
            >
              <SalesRabbitFieldMapping
                type="Project"
                targetFields={PROJECT_FIELDS}
                salesRabbitFields={salesRabbitFields}
                requiredFields={REQUIRED_FIELDS.project}
              />
            </Form.Item>
          )
        }

        <Form.Item
          name="createCard"
          label="Create Card?"
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>

        {
          formValues.createCard && (
            <>
              <Form.Item
                name="targetBoardId"
                label="Board"
                rules={[{ required: formValues.createCard, message: 'Board is required' }]}
                style={{ marginTop: 15 }}
              >
                <Select
                  options={boardList}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item
                name="initialStatusId"
                label="Initial Status"
                rules={[{ required: formValues.createCard, message: 'Initial Status is required' }]}
                style={{ marginTop: 15 }}
              >
                <Select
                  options={boardStatuses}
                  showSearch
                  optionFilterProp="label"
                />
              </Form.Item>
              <Form.Item
                name="color"
                label="Initial Color"
                rules={[{ required: formValues.createCard, message: 'Initial Color is required' }]}
                style={{ marginTop: 15 }}
              >
                <FormColorPicker isNotDisplay />
              </Form.Item>
              <Form.Item
                name="cardMappings"
                label="Card Mappings"
                rules={[{ required: formValues.createCard, message: 'Card Mappings are required' }]}
                style={{ marginTop: 15 }}
              >
                <SalesRabbitFieldMapping
                  type="Card"
                  targetFields={cardFields}
                  salesRabbitFields={salesRabbitFields}
                  requiredFields={REQUIRED_FIELDS.card}
                />
              </Form.Item>
            </>
          )
        }

      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
        onDelete={selectedWorkflow ? onDeleteClicked : null}
      />
    </Drawer>
  );
}

SalesRabbitWorkflowDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  selectedWorkflow: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    createCard: PropTypes.bool,
    targetBoardId: PropTypes.string,
  }),
};

SalesRabbitWorkflowDrawer.defaultProps = {
  selectedWorkflow: null,
};

export default SalesRabbitWorkflowDrawer;
