import React, {
  useEffect,
} from 'react';
import {
  Form,
  Divider,
} from 'antd';
import PropTypes from 'prop-types';
import GanttScheduleColumnsSection from '../GanttScheduleColumnsSection';
import { ganttColumns } from '../ganttScheduleHelpers';

export default function GanttScheduleAppearanceSettingsTab(props) {
  const {
    userSettings,
    visibleColumns,
    setVisibleColumns,
    visible,
  } = props;

  useEffect(() => {
    if (visible) {
      if (userSettings.ganttScheduleSettings) {
        try {
          setVisibleColumns(JSON.parse(userSettings.ganttScheduleSettings).visibleColumns);
        } catch (e) {
          setVisibleColumns([ganttColumns[0]]);
        }
      } else {
        setVisibleColumns([ganttColumns[0]]);
      }
    }
  }, [userSettings, visible, setVisibleColumns]);


  return (
    <Form
      style={{
        maxWidth: '95%',
      }}
      layout="vertical"
    >
      <h2>User Preferences</h2>
      <Divider style={{ margin: '10px 0px' }} />
      <GanttScheduleColumnsSection
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
      />
    </Form>
  );
}

GanttScheduleAppearanceSettingsTab.propTypes = {
  userSettings: PropTypes.shape({
    ganttScheduleSettings: PropTypes.string,
  }),
  visibleColumns: PropTypes.arrayOf(PropTypes.string).isRequired,
  setVisibleColumns: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
};

GanttScheduleAppearanceSettingsTab.defaultProps = {
  userSettings: {
    ganttScheduleSettings: '',
  },
};
