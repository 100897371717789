import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormsList from '../forms/CompletedForms/FormsList';
import { getForms } from '../forms/state/forms.actions';

export default function BoardCardForms({
  id,
  topOffset = 0,
}) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(getForms({ cardId: id }));
    }
  }, [id]);

  const forms = useSelector((state) => state.forms.forms);

  const formsList = useMemo(() => Object.values(forms).filter((form) => {
    const { cardId: formCardId, subtaskCardId } = form;
    return formCardId === id || subtaskCardId === id;
  }), [forms, id]);

  const query = useMemo(() => ({ cardId: id }), [id]);

  return (
    <div style={{ paddingTop: '10px' }}>
      <FormsList
        topOffset={240 + topOffset}
        forms={formsList}
        hideDate
        query={query}
      />
    </div>
  );
}
