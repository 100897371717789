import React, { useMemo } from 'react';
import { Drawer, Form } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import PDFTextFieldConfigure from './PDFTextFieldConfigure';
import PDFRectangleFieldConfigure from './PDFRectangleFieldConfigure';
import PDFImageConfigure from './PDFImageConfigure';
import PDFTableConfigure from './PDFTableConfigure';

import BorderlessButton from '../../buttons/BorderlessButton';

import { toTitleCase } from '../../../helpers/helpers';

export default ({
  open,
  onClose,
  onDrawOptionChanged,
  onDelete,
  field = {}, //the PDF Item
  page,
  numberOfPages,
  onPageChanged,
  onTextChanged,
  onLinkChanged,
  onIsSectionHeaderChanged,
  onImageAdd,
  typeId, // Form Type
  sections = [], // Form sections
  collected = {},
  useStandardTemplate,
}) =>  {
  const { type = '' } = field;
  const body = useMemo(() => {
    switch(field.type) {
      case 'text':
        return (
          <PDFTextFieldConfigure
            onDrawOptionChanged={onDrawOptionChanged}
            field={field}
            page={page}
            numberOfPages={numberOfPages}
            onPageChanged={onPageChanged}
            onTextChanged={onTextChanged}
            onLinkChanged={onLinkChanged}
            onIsSectionHeaderChanged={onIsSectionHeaderChanged}
            sections={sections}
            collected={collected}
            typeId={typeId}
            useStandardTemplate={useStandardTemplate}
          />
        );
      case 'rectangle':
        return (
          <PDFRectangleFieldConfigure
            onDrawOptionChanged={onDrawOptionChanged}
            field={field}
            page={page}
            numberOfPages={numberOfPages}
            onPageChanged={onPageChanged}
          />
        );
      case 'image':
        return (
          <PDFImageConfigure
            onDrawOptionChanged={onDrawOptionChanged}
            field={field}
            page={page}
            numberOfPages={numberOfPages}
            onPageChanged={onPageChanged}
            onImageAdd={onImageAdd}
            useStandardTemplate={useStandardTemplate}
          />
        );
      case 'table':
        return (
          <PDFTableConfigure
            onDrawOptionChanged={onDrawOptionChanged}
            field={field}
            page={page}
            numberOfPages={numberOfPages}
            onPageChanged={onPageChanged}
            onLinkChanged={onLinkChanged}
            sections={sections}
            collected={collected}
          />
        );
      default:
        return null;
    }
  },[
    field,
    page,
    numberOfPages,
    onPageChanged,
    onTextChanged,
    onLinkChanged,
    onImageAdd,
    onDrawOptionChanged,
    sections,
    collected,
    useStandardTemplate,
  ]);

  const title = useMemo(() => toTitleCase(type),[type]);
  return (
    <Drawer
      title={title}
      placement='right'
      visible={open}
      onClose={onClose}
      mask={false}
      width={375}
    >
      <div className='pdf-designer-drawer-container'>
        <Form layout='vertical' style={{ marginTop: 10, width:'100%' }}>
          {body}
        </Form>
      </div>
      {field.deletable &&
        <div className='pdf-designer-drawer-delete'>
          <BorderlessButton
            onClick={onDelete}
            iconNode={
              <DeleteOutlined style={{ color: 'red', marginLeft: 0 }}/>
            }
          />
        </div>
      }
    </Drawer>
  );
}
