import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
  Badge,
  message,
} from 'antd';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import axios from 'axios';
import { DateTime } from 'luxon';

import CompanyEditRow from '../../CompanyEditRow';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import DisplayText from '../../../common/text/DisplayText';

import { useInterval } from '../../../helpers/helpers';
import Debouncer from '../../../helpers/Debouncer';

import Colors from '../../../constants/Colors';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

const flavourText = (
  <div>
    Deactivating your Sage integration will disable any
    further syncing between Ontraccr and Sage.
    <br />
    <br />
    To disconnect your Sage 50 instance, please contact
    {' '}
    <b>support@ontraccr.com</b>
  </div>
);

const hideCode = new Array(36).fill('•').join('');

const INPUT_STYLE = {
  marginLeft: 10,
  marginRight: 40,
  marginBottom: 20,
  maxWidth: 440,
};
const DIVIDER_STYLE = {
  margin: 0,
  backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
  maxWidth: 440,
  minWidth: 440,
};

const debouncers = {
  username: new Debouncer(),
  password: new Debouncer(),
  file: new Debouncer(),
};

const update = async (payload) => {
  try {
    await axios.put('/sage50/config', payload);
  } catch (err) {
    message.error('Failed to update Sage 50 config');
  }
};

function Sage50({
  visible,
}) {
  const [code, setCode] = useState();
  const [showCode, setShowCode] = useState(false);
  const [lastSync, setLastSync] = useState();
  const [config, setConfig] = useState({});

  const toggleShowCode = useCallback(() => {
    setShowCode(!showCode);
  }, [showCode]);

  const onTextChanged = useCallback((type) => (e) => {
    if (!(type in debouncers)) return;
    const {
      target: { value } = {},
    } = e;
    setConfig({
      ...config,
      [type]: value,
    });

    debouncers[type].debounce(() => {
      if (value) update({ [type]: value });
    }, 250);
  }, [config]);

  useEffect(() => {
    const loadCode = async () => {
      const { data: newCode } = await axios.get('/sage50/key');
      const { data: syncTime } = await axios.get('/sage50/sync/time');
      const { data: newConfig } = await axios.get('/sage50/config');
      if (newCode) setCode(newCode);
      if (syncTime) setLastSync(DateTime.fromMillis(syncTime));
      if (newConfig) setConfig(newConfig);
    };
    if (visible) {
      loadCode();
    } else {
      setCode();
      setLastSync();
      setConfig({});
      setShowCode(false);
    }
  }, [visible]);

  useInterval(() => {
    const getLasySync = async () => {
      const { data: syncTime } = await axios.get('/sage50/sync/time');
      if (syncTime) {
        setLastSync(DateTime.fromMillis(syncTime));
      } else {
        setLastSync();
      }
    };
    if (visible) {
      getLasySync();
    } else {
      setLastSync();
    }
  }, [1000 * 60 * 5]); // Once every 5 minutes

  const now = DateTime.local();

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <div style={{ width: '100%', position: 'relative' }}>
        <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
          <Col>
            <DisplayText
              title="Authorization Code"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
          </Col>
        </Row>
        <DisplayText
          title="Use this code to authorize Sage 50 to connect to Ontraccr"
          style={{
            marginBottom: 0,
            maxWidth: 275,
            paddingLeft: 10,
            fontFamily: 'roboto-regular',
            color: Colors.ONTRACCR_OPACITY_GRAY,
          }}
        />
        <div className="integation-auth-code">
          {showCode || !code ? code : hideCode}
        </div>
        {
        code
        && (
        <div id="gmail-visibility-toggle-container">
          <BorderlessButton
            iconNode={showCode ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={toggleShowCode}
            style={{ padding: 0 }}
          />
        </div>
        )
      }
        <Divider />
      </div>
      <CompanyEditRow
        title="Last Sync"
        helpText="The last time your Sage 50 instance was synced with Ontraccr"
      >
        <Badge
          color={
            !lastSync || now.diff(lastSync).as('minutes') > 30
              ? 'red'
              : 'green'
          }
          style={{ paddingRight: 10 }}
        />
        <span style={{ fontSize: 12 }}>
          {
            lastSync
              ? lastSync.toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
              : 'Never'
          }
        </span>
      </CompanyEditRow>

      <CompanyEditRow
        title="Username"
        helpText="The username of the Ontraccr Sage50 account"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('username')}
        autocomplete="off"
        value={config.username}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="Password"
        helpText="The password of the Ontraccr Sage50 account"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('password')}
        password
        autoComplete="new-password"
        value={config.password}
      />
      <Divider style={DIVIDER_STYLE} />
      <CompanyEditRow
        title="File Location"
        helpText="The file location of your Sage50 data."
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('file')}
        value={config.file}
      />
      <Divider style={DIVIDER_STYLE} />
      <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
        <Col>
          <DisplayText
            title="Deactivate Sage Integration"
            style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
          />
        </Col>
      </Row>
      <DisplayText
        title={flavourText}
        style={{
          marginBottom: 20,
          maxWidth: 275,
          paddingLeft: 10,
          fontFamily: 'roboto-regular',
          color: Colors.ONTRACCR_OPACITY_GRAY,
        }}
      />
    </div>
  );
}

Sage50.propTypes = {
  visible: PropTypes.bool,
};

Sage50.defaultProps = {
  visible: false,
};

export default Sage50;
