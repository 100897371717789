import React, { useCallback, useState, useMemo, useEffect } from 'react';
import { Drawer, Table, Row, Col } from 'antd';
import PropTypes from 'prop-types';
import { DeleteOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import colors from '../../../constants/Colors';

function EclipsePayCodeMappingDrawer({
  visible,
  onClose,
  onSaveMappings,
  payCodeMappings,
}) {
  const [mappings, setMappings] = useState([]);

  const onDelete = useCallback((idx) => {
    setMappings(mappings.toSpliced(idx, 1));
  }, [mappings]);

  const onAddPressed = useCallback(() => {
    setMappings(mappings.concat([{}]));
  }, [mappings]);

  const onSubmit = useCallback(() => {
    const mapObject = {};
    mappings.forEach((mapping) => {
      if (!mapping.sourceValue || !mapping.payCode) return;
      mapObject[mapping.sourceValue] = mapping.payCode;
    });
    onSaveMappings(mapObject);
  }, [onSaveMappings, mappings]);

  const updateMapping = useCallback((updatedIdx, data) => {
    setMappings(
      mappings.map((old, idx) => {
        if (idx !== updatedIdx) return old;
        return {
          ...old,
          ...data,
        };
      }),
    );
  }, [mappings]);

  const errors = useMemo(() => {
    // Source Value has to be unique
    const nameSet = new Set();
    const errSet = new Set();
    mappings.forEach((mapping) => {
      const { sourceValue } = mapping;
      if (!sourceValue) return;
      if (nameSet.has(sourceValue)) {
        errSet.add(sourceValue);
      } else {
        nameSet.add(sourceValue);
      }
    });
    return errSet;
  }, [mappings]);

  const columns = useMemo(() => [
    {
      dataIndex: 'sourceValue',
      title: 'Ontraccr Value',
      width: 300,
      render: (value, _record, idx) => (
        <OnTraccrTextInput
          allowClear
          value={value}
          style={
            errors.has(value)
              ? { borderColor: 'red' }
              : {}
          }
          onChange={(e) => {
            updateMapping(idx, { sourceValue: e?.target?.value });
          }}
        />
      ),
    },
    {
      dataIndex: 'payCode',
      title: 'Eclipse Pay Code',
      width: 300,
      render: (value, _record, idx) => (
        <OnTraccrTextInput
          value={value}
          allowClear
          onChange={(e) => {
            updateMapping(idx, { payCode: e?.target?.value });
          }}
        />
      ),
    },
    {
      dataIndex: 'delete',
      title: '',
      width: 50,
      render: (_value, _record, index) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: colors.ONTRACCR_RED, marginLeft: 0 }} />}
          onClick={() => onDelete(index)}
        />
      ),
    },
  ], [onDelete, updateMapping, mappings, errors]);

  useEffect(() => {
    if (!visible) {
      setMappings([]);
    } else if (payCodeMappings) {
      setMappings(
        Object.keys(payCodeMappings).map((sourceValue) => ({
          sourceValue,
          payCode: payCodeMappings[sourceValue],
        })),
      );
    }
  }, [visible, payCodeMappings]);

  return (
    <Drawer
      title="Map Text to Eclipse Pay Codes"
      visible={visible}
      onClose={onClose}
      width={700}
      bodyStyle={{ padding: 0 }}
    >
      <Row align="middle">
        <Col>
          <OnTraccrButton
            title="Add Mapping"
            onClick={onAddPressed}
            style={{ margin: 20 }}
          />
        </Col>
        <Col style={{ color: 'red' }}>
          {
            errors.size > 0
              ? `Please fix duplicate Ontraccr values: ${Array.from(errors).join(', ')}`
              : null
          }
        </Col>
      </Row>
      <Table
        dataSource={mappings}
        pagination={false}
        columns={columns}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        canSubmit={errors.size === 0}
      />
    </Drawer>
  );
}

EclipsePayCodeMappingDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveMappings: PropTypes.func.isRequired,
  payCodeMappings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

EclipsePayCodeMappingDrawer.defaultProps = {
  payCodeMappings: null,
};

export default EclipsePayCodeMappingDrawer;
