import React, { useState } from 'react';
import { Row, Col, Typography } from 'antd';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';
import BorderlessButton from '../buttons/BorderlessButton';

const { Paragraph } = Typography;
export default ({
  defaultValue = '',
  style = {},
  editing = false,
  showButtons = true,
  onChange,
  name,
  inputNode,
  editable = true,
}) =>  {
  const [hover, setHover] = useState(false);
  const [returnValue, setReturnValue] = useState({name, value: defaultValue});
  const [focus, setFocus] = useState(editing);
  const hasGoodValue = returnValue && returnValue.value && returnValue.value.length > 0;
  return (focus || hover) ? 
    <Row>
      <Col>
        {inputNode({
          setReturnValue,
          setFocus,
          setHover,
          defaultValue:returnValue.value,
        })}
      </Col>
      <Col style={{width:22,}}>
        {showButtons && focus && <BorderlessButton
          style={{padding:0}}
          iconNode={<CloseOutlined/>}
          onClick={() => {
            setReturnValue({ name, value:defaultValue });
            setHover(false);
            setFocus(false);
          }}
        />}
      </Col>
      <Col style={{width:22,}}>
        {showButtons && focus && <BorderlessButton
          style={{padding:0}}
          iconNode={<CheckOutlined/>}
          onClick={() => {
            const { name, value } = returnValue;
            onChange(name,value);
            setHover(false);
            setFocus(false);
          }}
        />}
      </Col>
    </Row>
     :
    <Paragraph
      style={{
        marginRight:56,
        fontFamily:
        'roboto-regular',
        textAlign:'right',
        fontColor: hasGoodValue
          ? 'inherit'
          : 'red',
        opacity: hover ? 0.5 : 1,
        ...style
      }}
      onMouseEnter={() => setHover(editable && true)}
      onMouseLeave={() => setHover(false)}
      onFocus={() => setFocus(editable && true)}
      onClick={() => setFocus(editable && true)}
    >
      {hasGoodValue ? returnValue.value : 'Hover to edit'}
    </Paragraph> 
}
