import axios from 'axios';
import { request } from '../../../../helpers/requests';

const SalesRabbitService = {
  getWorkflows: () => request({
    call: axios.get('salesrabbit/workflows'),
    errMsg: 'Failed to get Sales Rabbit workflows',
    hideSuccessToast: true,
  }),
  createWorkflow: (payload) => request({
    call: axios.post('salesrabbit/workflows', payload),
    errMsg: 'Failed to create Sales Rabbit workflow',
  }),
  updateWorkflow: (id, payload) => request({
    call: axios.put(`salesrabbit/workflows/${id}`, payload),
    errMsg: 'Failed to update Sales Rabbit workflow',
  }),
  deleteWorkflow: (id) => request({
    call: axios.delete(`salesrabbit/workflows/${id}`),
    errMsg: 'Failed to delete Sales Rabbit workflow',
  }),
  getRegions: () => request({
    call: axios.get('salesrabbit/regions'),
    errMsg: 'Failed to get Sales Rabbit regions',
    hideSuccessToast: true,
  }),
  createRegion: (payload) => request({
    call: axios.post('salesrabbit/regions', payload),
    errMsg: 'Failed to create Sales Rabbit region',
  }),
  updateRegion: (id, payload) => request({
    call: axios.put(`salesrabbit/regions/${id}`, payload),
    errMsg: 'Failed to update Sales Rabbit region',
  }),
  deleteRegion: (id) => request({
    call: axios.delete(`salesrabbit/regions/${id}`),
    errMsg: 'Failed to delete Sales Rabbit regions',
  }),
};

export default SalesRabbitService;
