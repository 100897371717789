import React, {
  useRef, useCallback, useState, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Drawer,
  Form,
  Col,
  Row,
  Select,
  Tag,
  Switch,
  DatePicker,
  Button,
} from 'antd';
import { FormOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import { DateTime } from 'luxon';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import * as Sentry from '@sentry/react';

import { Common, Schedule } from 'ontraccr-common';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DisplayText from '../common/text/DisplayText';
import DivisionSelector from '../common/inputs/DivisionSelector';
import FileReelWithUpload from '../common/files/FileReelWithUpload';
import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrDatePicker from '../common/inputs/OnTraccrDatePicker';

import {
  reformatRemindersFromValues,
  timestampToPosition,
  dateRangeValidator,
  getDayKey,
  createShallowShift,
  addShallowShiftToShifts,
  getMaxStartFromRestriction,
  validateExpectedUsers,
} from './scheduleHelpers';
import ScheduleUserSelector from './ScheduleUserSelector';
import ScheduleEventForm from './ScheduleEventForm';

import {
  toggleShiftView, getShiftGroup, getSchedule, getGanttScheduleRow,
} from './state/schedule.actions';

import {
  filterSelectDropdown, getId, getIdMap, isNullOrUndefined,
} from '../helpers/helpers';
import { validateRepeatEndDate } from '../helpers/subtaskHelpers';

import config from '../config';
import ScheduleDateRepeatPopover from './ScheduleDateRepeatPopover';
import FormColorPicker from '../common/inputs/FormColorPicker';
import DynamicRemindersField from './reminder/DynamicRemindersField';
import { REMINDER_OPTIONS } from './reminder/reminder.constants';
import { downloadFiles } from '../helpers/fileHelpers';
import ScheduleDatePicker from './ScheduleDatePicker';
import WeeklySchedule from './WeeklySchedule/WeeklySchedule';
import DailySchedule from './DailySchedule/DailySchedule';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import ScheduleTeamSelector from './ScheduleTeamSelector';
import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';

import Permissions from '../auth/Permissions';
import ScheduleExpectedUsersInput from './ScheduleExpectedUsersInput';
import HoverHelp from '../common/HoverHelp';
import GanttScheduleAddDrawer from './GanttSchedule/GanttScheduleAddDrawer';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 5,
  },
};

const updateForm = ({ ref, data }) => {
  if (!ref) return;
  ref.setFieldsValue(data);
};

const basicOptions = (arr = [], extractor = (item) => item.name) => (
  arr.map((item) => (
    <Select.Option key={item.id} label={extractor(item)} value={item.id} name={item.name} number={item.number}>
      {extractor(item)}
    </Select.Option>
  ))
);

const getFormView = ({ isDisplay, value, input }) => {
  if (isDisplay) return <DisplayText title={value} />;
  return input;
};

const getFromMap = ({
  id,
  ids,
  idMap,
  formatter = (item) => item.name,
  defaultValue = null,
}) => {
  if (ids) {
    return ids.map((mapId) => (mapId in idMap ? formatter(idMap[mapId]) : defaultValue)).join(', ');
  }
  return id in idMap ? formatter(idMap[id]) : defaultValue;
};

export default function ScheduleDrawer({
  isNew,
  visible,
  onClose,
  onSave,
  onDelete,
  shift,
  isDisplay,
  openFormDetail,
  shifts = {},
  shiftMap = {},
  lastDownload = [],
  setLastDownload,
  style = {},
  hideCalendar = false,
  isGanttDispatch = false,
}) {
  const {
    id,
    title,
    day,
    startTime,
    endTime,
    projectId,
    phaseId,
    costcodeId,
    description,
    divisionId,
    users = [],
    expectedUsers,
    files: shiftFiles,
    formId,
    isEvent: shiftIsEvent,
    formTemplateId,
    formTemplateName,
    formNumber,
    color,
    reminders = [],
    repeat,
    repeatEndDate,
    groupId,
    shouldLockClockIn = false,
    teams,
    ganttScheduleRowId,
  } = shift || {};

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const divisions = useSelector((state) => state.settings.divisions);
  const { settings = {} } = useSelector((state) => state.settings.company);
  const stateTeams = useSelector((state) => state.teams.teams);
  const scheduleRestriction = useSelector((state) => state.settings.scheduleRestriction);
  const userToLabel = useSelector((state) => state.users.userToLabel);
  const { ganttScheduleRows = [] } = useSelector((state) => state.schedule);

  const positionMap = useSelector((state) => {
    const {
      settings: {
        positionNames = [],
      } = {},
    } = state;
    return getIdMap(positionNames);
  });

  const userLabelMap = useSelector((state) => {
    const { labels = [] } = state;
    const userLabels = labels.filter((label) => label.type === 'users');
    return getIdMap(userLabels);
  });

  const {
    activeUsers,
    userMap,
  } = useSelector((state) => {
    const {
      users: {
        users: stateUsers = [],
      } = {},
    } = state;
    return {
      activeUsers: stateUsers.filter((user) => user.active),
      userMap: getIdMap(stateUsers),
    };
  });

  const {
    activeProjects,
    projectMap,
  } = useSelector((state) => {
    const {
      projects: {
        projects: stateProjects = [],
      } = {},
    } = state;
    return {
      activeProjects: stateProjects.filter((project) => project.active),
      projectMap: getIdMap(stateProjects),
    };
  });

  const {
    activePhases,
    activeCostcodes,
    costcodeMap,
  } = useSelector((state) => {
    const {
      costcodes: {
        phases: statePhases = [],
        costcodes: stateCostcodes = [],
      } = {},
    } = state;
    return {
      activePhases: statePhases,
      activeCostcodes: stateCostcodes.filter((cc) => cc.active),
      costcodeMap: getIdMap(stateCostcodes),
    };
  });

  const groupShifts = useSelector((state) => state.schedule.groupShifts);
  const originalShift = useMemo(() => {
    if (!groupId || !groupShifts) return false;
    const list = Object.values(groupShifts);
    const ourShifts = list.filter((s) => s.groupId === groupId);
    const orderedShifts = ourShifts.sort((a, b) => (a.startTime - b.startTime));
    return orderedShifts[0];
  }, [groupShifts, groupId]);

  const formRef = useRef(null);
  const [files, setFiles] = useState([]);
  const [selectedProject, setSelectedProject] = useState(projectId);
  const [selectedPhase, setSelectedPhase] = useState(phaseId === null && costcodeId ? 'Unphased' : phaseId);
  const [selectedCC, setSelectedCC] = useState(costcodeId);
  const [dateRange, setDateRange] = useState();
  const [selectedDivision, setSelectedDivision] = useState(divisionId);
  const [isEvent, setIsEvent] = useState(shiftIsEvent);
  const [formData, setFormData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [repeatValue, setRepeatValue] = useState(repeat);
  const [showGanttDrawer, setShowGanttDrawer] = useState(false);

  const [date, setDate] = useState(day || DateTime.local());
  const [viewType, setViewType] = useState('Week');
  const [localShifts, setLocalShifts] = useState(shifts ?? {});
  const [shiftShadow, setShiftShadow] = useState(shift ?? {});
  const [selectedTeams, setSelectedTeams] = useState(teams || []);

  const weeksRestriction = useMemo(() => {
    const ourUser = activeUsers.find((u) => u.id === Permissions.id);
    return scheduleRestriction[ourUser?.position]?.weeks ?? null;
  }, [activeUsers, scheduleRestriction]);

  const activeTeams = useMemo(() => stateTeams.filter(
    (team) => team.active && team.members?.length && selectedDivision === team.divisionId,
  ), [stateTeams, selectedDivision]);
  const teamMap = useMemo(() => getIdMap(stateTeams), [stateTeams]);

  const repeatStartDate = useMemo(() => {
    // Use start date for individual and new new shifts.
    if (!originalShift || !originalShift.startTime) {
      return dateRange ? dateRange[0] : null;
    }
    // Otherwise use original task's start
    return originalShift.startTime || null;
  }, [originalShift, dateRange]);

  const ganttScheduleRow = useMemo(() => {
    if (!ganttScheduleRowId) return {};
    return ganttScheduleRows.find((row) => row.id === ganttScheduleRowId) || {};
  }, [ganttScheduleRowId, ganttScheduleRows]);

  const onFormClick = useCallback(() => {
    if (!formId) return;
    openFormDetail(formId);
  }, [formId, openFormDetail]);

  const onUserClick = useCallback(async (userId) => {
    if (!formRef?.current) return;
    const values = await formRef.current.getFieldsValue();
    const { users: formUsers = [] } = values;
    const uniqueUsers = new Set(formUsers);
    uniqueUsers.add(userId);
    updateForm({ ref: formRef.current, data: { users: Array.from(uniqueUsers) } });
  }, [formRef.current]);

  const onProjectSelect = useCallback((selectedId) => {
    if (selectedId === selectedProject) return;
    setSelectedProject(selectedId);
    setSelectedPhase();
    setSelectedCC();
  }, [selectedProject]);
  const onPhaseSelect = useCallback((selectedId) => {
    if (selectedId === selectedPhase) return;
    setSelectedPhase(selectedId);
    setSelectedCC();
  }, [selectedPhase]);

  const onTeamSelect = useCallback((selectedIds) => {
    setSelectedTeams(selectedIds);
  }, []);

  /**
   * Removes undefined/null reminders
   * @param {array} reminders
   * @returns {array}
   */
  const refineReminders = (newReminders = []) => newReminders.filter((reminder) => reminder);

  const saveShift = async (newShift) => {
    setIsLoading(true);
    if (await onSave(newShift)) {
      setFiles([]);
    }
    setIsLoading(false);
  };

  const onSaveClicked = useCallback(async () => {
    if (!formRef || !formRef.current) return;
    try {
      try {
        await formRef.current.validateFields();
      } catch (e) {
        const relevantErrors = [];
        const fieldsToIgnore = [
          'expectedUsers',
          'users',
        ];

        e.errorFields.forEach((field) => {
          if (field.name.length !== 1 || !fieldsToIgnore.includes(field.name[0])) {
            relevantErrors.push(field);
          }
        });

        if (relevantErrors.length) {
          return;
        }
      }
      const values = await formRef.current.getFieldsValue();
      const {
        phaseId: newPhaseId,
        dateRange: [newStart, newEnd] = [],
        color: newColor = null,
        reminders: newReminders,
        repeatEndDate: newRepeatEndDate,
        teams: newTeams,
        users: newUsers,
        expectedUsers: newExpectedUsers,
      } = values;

      // Create new shift:
      const newShift = {
        ...values,
        reminders: refineReminders(newReminders),
        startTime: newStart,
        endTime: newEnd,
        top: timestampToPosition(newStart, day),
        bottom: timestampToPosition(newEnd, day),
        phaseId: newPhaseId === 'Unphased' ? null : newPhaseId,
        color: newColor,
        formData,
      };

      if (newRepeatEndDate && moment.isMoment(newRepeatEndDate)) {
        delete newShift.repeatEndDate;
        newShift.repeatEndDate = newRepeatEndDate.startOf('day').valueOf();
      }

      const newUserSet = new Set(newUsers);
      const teamsToAdd = [];
      const teamsToRemove = [];
      newTeams?.forEach((teamId) => {
        let hasTeam = false;
        const team = teamMap[teamId];
        team?.members?.forEach(({ id: memberId }) => {
          if (newUserSet.has(memberId)) hasTeam = true;
        });

        if (hasTeam) teamsToAdd.push(teamId);
        else teamsToRemove.push(team?.name);
      });

      newShift.teams = teamsToAdd;

      const parsedExpectedUsers = newExpectedUsers.filter(({ count, type }) => (
        count > 0 || type === 'default'
      ));

      // expected user differences
      const expectedUserGroupsDiff = Schedule.getExpectedUserGroupsDiff({
        prevExpectedUsers: expectedUsers,
        newExpectedUsers: parsedExpectedUsers,
      });

      Object.entries(expectedUserGroupsDiff).forEach(([key, value]) => {
        newShift[key] = value;
      });

      if (teamsToRemove.length) {
        CustomConfirmModal({
          title: 'Save Confirmation',
          content:
            (
              <>
                <span>
                  The team(s) listed below do not have any members assigned to this shift.
                  Do you want to remove these teams?
                </span>
                <br />
                <ul style={{ paddingTop: 10, textAlign: 'left' }}>
                  {teamsToRemove.map((team) => (
                    <li key={team}>{team}</li>
                  ))}
                </ul>
              </>
            ),
          onOk: () => saveShift(newShift),
          onCancel: async () => {
            newShift.teams = newTeams;
            await saveShift(newShift);
          },
          okText: 'Yes',
          cancelText: 'No',
        });
      } else {
        await saveShift(newShift);
      }
    } catch (err) {
      setIsLoading(false);
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
  }, [
    formRef,
    onSave,
    day,
    formData,
    teamMap,
  ]);

  const onFormValuesChanged = useCallback((_, values) => {
    const {
      dateRange: newDateRange = [],
      isEvent: formIsEvent,
      repeat: newRepeat,
    } = values;
    setIsEvent(formIsEvent);
    if (newRepeat) {
      setRepeatValue(newRepeat);
    }
    if (!newDateRange || newDateRange.length < 2) return;
    const [newStart, newEnd] = newDateRange;
    if (!dateRange || dateRange[0] !== newStart || dateRange[1] !== newEnd) {
      setDateRange([newStart, newEnd]);
    }

    const newDate = DateTime.fromMillis(newStart).set({ hour: 0, minute: 0, second: 0 });
    const shadow = createShallowShift({
      ...values,
      startTime: newStart,
      endTime: newEnd,
      id,
    });

    setDate(newDate);
    setShiftShadow(shadow);
  }, [dateRange]);

  useEffect(() => {
    if (visible
        && formRef
        && formRef.current
        && !isNullOrUndefined(startTime)
        && !isNullOrUndefined(endTime)) {
      const newRange = [startTime, endTime];
      setDateRange(newRange);
      formRef.current.setFieldsValue({ dateRange: newRange });
    }
  }, [startTime, endTime, visible, formRef]);

  useEffect(() => {
    const getURLS = async () => {
      const parsedFiles = await downloadFiles(shiftFiles);
      setFiles(parsedFiles);
    };
    if (shiftFiles && Array.isArray(shiftFiles)) {
      getURLS();
    } else {
      setFiles([]);
    }
  }, [shiftFiles]);

  useEffect(() => {
    if (groupId) {
      dispatch(getShiftGroup(groupId));
    }
  }, [groupId]);

  useEffect(() => {
    if (ganttScheduleRowId && !isGanttDispatch) {
      dispatch(getGanttScheduleRow(ganttScheduleRowId));
    }
  }, [ganttScheduleRowId]);

  useEffect(() => {
    setSelectedDivision(divisionId);
  }, [divisionId]);
  useEffect(() => {
    setIsEvent(shiftIsEvent);
  }, [shiftIsEvent]);
  useEffect(() => {
    setRepeatValue(repeat);
  }, [repeat]);

  // Disabling react-hooks/exhaustive-deps
  // ESLint throws a warning saying we dont need formRef.current in the deps array
  // But without it, the form is not updated correctly.
  useEffect(() => {
    updateForm({ ref: formRef.current, data: { projectId: selectedProject } });
  }, [selectedProject, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateForm({ ref: formRef.current, data: { phaseId: selectedPhase } });
  }, [selectedPhase, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateForm({ ref: formRef.current, data: { costcodeId: selectedCC } });
  }, [selectedCC, formRef.current]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    updateForm({ ref: formRef.current, data: { teams: selectedTeams } });
  }, [selectedTeams, formRef.current]);

  useEffect(() => {
    const {
      [selectedProject]: {
        divisionId: projectDivisionId,
      } = {},
    } = projectMap;
    if (!config.showDivisions) return;
    if (projectDivisionId !== selectedDivision) {
      updateForm({
        ref: formRef.current,
        data: { projectId: null, phaseId: null, costcodeId: null },
      });
    }
    if (selectedDivision && formRef && formRef.current) {
      const {
        [selectedDivision]: {
          users: divisionUsers = new Set(),
        } = {},
      } = divisions;

      const teamMembers = [];
      selectedTeams?.forEach((selectedTeam) => {
        const {
          [selectedTeam]: {
            members = [],
          } = {},
        } = teamMap;

        teamMembers.push(...members);
      });

      const teamUsers = new Set(teamMembers.map(getId));
      const formUsers = formRef.current.getFieldValue('users') || [];

      const ourUsers = formUsers
        .map((userId) => userMap[userId])
        .filter((user) => user);
      updateForm({
        ref: formRef.current,
        data: {
          users: ourUsers
            .filter((user) => (
              divisionUsers.has(user.id) && (
                !selectedTeams?.length || teamUsers.has(user.id)
              )
            ))
            .map((user) => user.id),
        },
      });
    }
  }, [
    selectedDivision,
    divisions,
    selectedProject,
    projectMap,
    userMap,
    formRef.current,
    selectedTeams,
    teamMap,
  ]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    dispatch(toggleShiftView(visible));
  }, [dispatch, visible]);

  useEffect(() => {
    if (visible) {
      setSelectedProject(projectId);
      const ourPhase = phaseId === null && costcodeId ? 'Unphased' : phaseId;
      setSelectedPhase(ourPhase);
      setSelectedCC(costcodeId);
      setSelectedTeams(teams || []);
    } else {
      setSelectedProject();
      setSelectedPhase();
      setSelectedCC();
      setDateRange();
      setRepeatValue();
      setSelectedTeams([]);
    }
  }, [visible, projectId, phaseId, costcodeId, teams]);

  const divProjects = useMemo(() => {
    if (!config.showDivisions) return activeProjects;
    return activeProjects.filter((project) => project.divisionId === selectedDivision);
  }, [activeProjects, selectedDivision]);

  const divUsers = useMemo(() => {
    let filteredUsers = activeUsers;
    const teamMembers = [];
    selectedTeams?.forEach((selectedTeam) => {
      const {
        [selectedTeam]: {
          members = [],
        } = {},
      } = teamMap;

      teamMembers.push(...members);
    });

    if (selectedTeams?.length) {
      const teamMemberSet = new Set(teamMembers.map(getId));
      filteredUsers = filteredUsers.filter((user) => teamMemberSet.has(user.id));
    }

    if (!config.showDivisions) return filteredUsers;
    const {
      [selectedDivision]: {
        users: divisionUsers = new Set(),
      } = {},
    } = divisions;
    return filteredUsers.filter((user) => divisionUsers.has(user.id));
  }, [activeUsers, divisions, selectedDivision, selectedTeams, teamMap]);

  const {
    phases,
    phaseMap: phaseIdMap,
  } = useMemo(() => {
    const ccIdMap = getIdMap(activeCostcodes);
    const unphasedCC = activeCostcodes
      .filter(
        (cc) => cc.projectId === selectedProject
        && cc.phased === false,
      );
    const relevantPhases = activePhases
      .filter((phase) => phase.projectId === selectedProject);
    const phaseMap = {};
    if (unphasedCC.length > 0) {
      phaseMap.Unphased = {
        id: 'Unphased',
        name: 'Unphased',
        costcodes: unphasedCC,
      };
    }
    relevantPhases.forEach((phase) => {
      const newPhase = { ...phase };
      delete newPhase.costcodeId;
      if (!(phase.id in phaseMap)) {
        phaseMap[phase.id] = {
          ...newPhase,
          costcodes: [],
        };
      }
      if (phase.costcodeId && phase.costcodeId in ccIdMap) {
        phaseMap[phase.id].costcodes.push(ccIdMap[phase.costcodeId]);
      }
    });
    return {
      phases: Object.values(phaseMap),
      phaseMap,
    };
  }, [selectedProject, activePhases, activeCostcodes]);

  const costcodes = useMemo(() => {
    if (!selectedPhase) return [];
    const {
      [selectedPhase]: {
        costcodes: phaseCC = [],
      } = {},
    } = phaseIdMap;
    return phaseCC;
  }, [selectedPhase, phaseIdMap]);

  const getNewShiftReminders = () => {
    let newShiftReminders = [REMINDER_OPTIONS.DEFAULT_REMINDER];
    try {
      const lastReminderConfig = JSON.parse(window.sessionStorage.getItem('lastReminderConfig'));
      if (lastReminderConfig) newShiftReminders = reformatRemindersFromValues(lastReminderConfig);
    } catch (error) {
      console.error(error);
    }
    return newShiftReminders;
  };

  const article = isEvent ? 'an' : 'a';
  const type = isEvent ? 'Event' : 'Shift';

  const showRepeatStart = repeatValue
    && repeatStartDate
    && originalShift
    && originalShift.id !== id;
  const startDateDT = (repeatStartDate && Number.isInteger(repeatStartDate))
    ? DateTime.fromMillis(repeatStartDate)
    : null;
  const startDateText = startDateDT
    ? startDateDT.toLocaleString(DateTime.DATE_MED)
    : 'Not Set';

  const endDateText = (repeatEndDate && Number.isInteger(repeatEndDate))
    ? DateTime.fromMillis(repeatEndDate).toLocaleString(DateTime.DATE_MED)
    : 'Not Set';
  const parsedRepeatEndDate = useMemo(() => (
    repeatEndDate ? moment(repeatEndDate) : undefined
  ), [
    repeatEndDate,
  ]);
  const repeatEndValidator = useCallback(() => {
    if (!formRef || !formRef.current) return Promise.reject();
    const values = formRef.current.getFieldsValue();
    const {
      dateRange: [newStartTime] = [],
      repeat: newRepeat,
      repeatEndDate: newRepeatEndDateMoment,
    } = values;
    if (!newRepeat) {
      return Promise.resolve();
    }
    const newRepeatEndDate = newRepeatEndDateMoment && moment.isMoment(newRepeatEndDateMoment)
      ? newRepeatEndDateMoment.valueOf()
      : null;
    return validateRepeatEndDate({
      startDate: newStartTime,
      repeat: newRepeat,
      repeatEndDate: newRepeatEndDate,
      type: 'Start Time',
    });
  }, [formRef]);

  const onTeamClick = useCallback((teamId, shouldAdd = false) => {
    if (!formRef || !formRef.current) return;
    const values = formRef.current.getFieldsValue();
    const { users: formUsers = [] } = values;

    let newUsers;

    if (!shouldAdd) {
      const availableTeamMembers = teamMap[teamId]?.members || [];
      const availableTeamMemberSet = new Set(availableTeamMembers.map(getId));
      newUsers = formUsers.filter((userId) => !availableTeamMemberSet.has(userId));
    } else {
      const divUsersSet = new Set(divUsers.map(getId));
      newUsers = [
        ...new Set(
          [
            ...formUsers,
            ...(teamMap[teamId]?.members
              .map(getId)
              ?.filter((userId) => divUsersSet.has(userId)) || []),
          ],
        ),
      ];
    }

    updateForm({ ref: formRef.current, data: { users: Array.from(newUsers) } });
  }, [formRef, divUsers, teamMap]);

  // Side View Calendar-related functions

  useEffect(() => {
    const loadShifts = async ({ startTime: start, endTime: end }) => {
      if (await dispatch(getSchedule({ startTime: start, endTime: end }))) {
        setLastDownload([start, end]);
      }
    };
    if (!visible || hideCalendar) return;
    const startDT = date.startOf('month').minus({ day: 6 });
    const endDT = date.endOf('month').plus({ day: 6 });
    const start = startDT.toMillis();
    const end = endDT.toMillis();

    const hasScheduleRestriction = !isNullOrUndefined(weeksRestriction);
    if (hasScheduleRestriction && getMaxStartFromRestriction(weeksRestriction)) return;

    if ((!lastDownload?.length || lastDownload[0] > start || lastDownload[1] < end)) {
      loadShifts({ startTime: start, endTime: end });
    }
  }, [lastDownload, date, viewType, visible, weeksRestriction]);

  useEffect(() => {
    if (day?.isValid) {
      setDate(day);
    }
  }, [day]);

  useEffect(() => {
    if (hideCalendar) return;
    if (startTime && endTime) {
      const shadow = createShallowShift(shift);
      setShiftShadow(shadow);
    }
  }, [startTime, endTime, shift]);

  useEffect(() => {
    if (hideCalendar) return;
    const { startTime: start, endTime: end } = shiftShadow;

    if (start && end) {
      const newShifts = addShallowShiftToShifts({
        shifts,
        shift: shiftShadow,
        shiftMap,
      });
      setLocalShifts(newShifts);
    }
  }, [shifts, shiftShadow, shiftMap]);

  const onDateChange = useCallback((newDate) => {
    const ts = newDate.valueOf();
    const dt = DateTime.fromMillis(ts);
    setDate(dt);
  }, []);

  const onDaySelect = useCallback((newDay) => {
    setDate(newDay);
    setViewType('Day');
  }, []);

  const typeMap = {
    Day: 'date',
    Week: 'week',
  };

  const header = useMemo(() => {
    const hover = (
      <HoverHelp
        content={`This shift was dispatched from a gantt task ${ganttScheduleRow.name ?? ''}.`}
        Icon={InfoCircleTwoTone}
        iconProps={{ twoToneColor: '#fdb81b', style: { height: 20, fontSize: '18px' } }}
      />
    );

    const label = title || `Schedule ${article} ${type}`;

    if (!ganttScheduleRowId || isGanttDispatch) return label;

    return (
      <Row style={{
        display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
      }}
      >
        <Col style={{ display: 'flex', flexDirection: 'row', gap: 8 }}>
          {Permissions.has('PROJECT_GANTT_SCHEDULE_READ') ? (
            <Button
              type="link"
              onClick={() => setShowGanttDrawer(true)}
              style={{ marginTop: -3 }}
            >
              {hover}
            </Button>
          ) : <div>{hover}</div>}
          {label}
        </Col>

      </Row>
    );
  }, [title, article, type, ganttScheduleRow, ganttScheduleRowId]);

  return (
    <Drawer
      title={header}
      width={hideCalendar ? 750 : 1250}
      visible={visible}
      maskClosable={isDisplay}
      onClose={onClose}
      push={!showGanttDrawer ? { distance: 1250 } : {}}
      style={{
        zIndex: 150,
        ...style,
      }}
    >
      <Row
        gutter={12}
        style={{
          maxHeight: 'calc(100vh - 110px)',
          overflow: 'hidden',
        }}
      >
        {hideCalendar ? null : (
          <Col
            span={12}
            style={{
              maxHeight: 'calc(100vh - 135px)',
              overflow: 'hidden',
              paddingBottom: '4em',
            }}
          >
            <ScheduleDatePicker
              viewType={viewType}
              onViewTypeChange={setViewType}
              onDateChange={onDateChange}
              date={date}
              onVisibleChanged={() => {}}
              typeMap={typeMap}
            />
            {viewType === 'Week' && (
            <WeeklySchedule
              date={date}
              shifts={localShifts}
              onShiftCreate={() => {}}
              onNewShiftChange={() => {}}
              onShiftSelect={() => {}}
              onShiftEdit={() => {}}
              onDaySelect={onDaySelect}
            />
            )}
            {viewType === 'Day' && (
            <DailySchedule
              day={date}
              shifts={localShifts[getDayKey(date)]}
              onShiftCreate={() => {}}
              onNewShiftChange={() => {}}
              onShiftSelect={() => {}}
              onShiftEdit={() => {}}
            />
            )}
          </Col>
        )}

        <Col
          span={hideCalendar ? 24 : 12}
          style={{
            maxHeight: 'calc(100vh - 135px)',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
        >
          <Form
            layout="vertical"
            key={id + files.length + visible}
            ref={formRef}
            style={{
              padding: '5em 0',
            }}
            onValuesChange={onFormValuesChanged}
            initialValues={{
              isEvent: shiftIsEvent,
              dateRange,
              reminders: isNew ? getNewShiftReminders() : reformatRemindersFromValues(reminders),
            }}
          >
            <Form.Item
              name="isEvent"
              label={type}
              className="schedule-form-item"
              initialValue={!!isEvent}
              valuePropName="checked"
              labelCol={{ style: { marginTop: -20 } }}
            >
              {!isDisplay && <Switch />}
            </Form.Item>
            <Form.Item
              name="title"
              label="Title"
              className="schedule-form-item"
              labelCol={formLabelStyle}
              initialValue={title}
              rules={[{ required: !isDisplay, message: 'Please enter a title' }]}
            >
              {getFormView({
                isDisplay,
                value: title,
                input: <OnTraccrTextInput placeholder="Add a title" />,
              })}
            </Form.Item>

            {config.showDivisions
              && (
              <Form.Item
                name="divisionId"
                key="divisionId"
                label="Division"
                style={{ marginBottom: 0 }}
                rules={[{ required: !isDisplay, message: 'Select a division' }]}
                valuePropName="divisionId"
                initialValue={divisionId}
              >
                <DivisionSelector
                  displayMode={isDisplay}
                  onChange={(newDivision) => setSelectedDivision(newDivision)}
                />
              </Form.Item>
              )}
            <Form.Item
              name="dateRange"
              label="Dates"
              className="schedule-form-item"
              labelCol={formLabelStyle}
              rules={[
                { required: !isDisplay, message: 'Please select a start and end date' },
                { validator: dateRangeValidator },
              ]}
            >
              <OnTraccrDatePicker isDisplay={isDisplay} />
            </Form.Item>
            <Form.Item
              name="repeat"
              className="schedule-form-item"
              initialValue={repeat}
            >
              <ScheduleDateRepeatPopover
                isDisplay={isDisplay}
                startTime={repeatStartDate}
                onChange={setRepeatValue}
              />
            </Form.Item>

            { showRepeatStart && (
              <Form.Item
                label="Repeat Start Date"
                style={{ marginTop: -10 }}
              >
                {
                isDisplay
                  ? (
                    <DisplayText
                      label="Repeat Start Date"
                      title={startDateText}
                    />
                  )
                  : (
                    <DatePicker
                      label="Repeat Start Date"
                      format="MMM Do YY"
                      value={moment(repeatStartDate)}
                      disabled
                    />
                  )
                }
              </Form.Item>
            )}
            { repeatValue && (
              <Form.Item
                label="Repeat End Date"
                name="repeatEndDate"
                style={{ marginTop: -10 }}
                rules={[{
                  required: !!repeatValue,
                  validator: repeatEndValidator,
                }]}
                initialValue={parsedRepeatEndDate}
              >
                {isDisplay
                  ? <DisplayText title={endDateText} />
                  : <DatePicker format="MMM Do YY" allowClear />}
              </Form.Item>
            )}

            {!isEvent && <DynamicRemindersField isDisplay={isDisplay} /> }
            {!isEvent && (
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="projectId"
                  label={t('Project')}
                  className="schedule-form-item"
                  labelCol={formLabelStyle}
                  initialValue={projectId}
                >
                  {getFormView({
                    isDisplay,
                    value: getFromMap({ id: projectId, idMap: projectMap }),
                    input: (
                      <Select
                        placeholder={`Select a ${t('Project')}`}
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showSearch
                        onSelect={onProjectSelect}
                        onChange={(selected) => {
                          if (!selected) {
                            setSelectedPhase();
                            setSelectedCC();
                          }
                        }}
                        allowClear
                        filterOption={filterSelectDropdown}
                      >
                        {basicOptions(divProjects, (item) => formatProjectLabelFromCompanySettings({
                          name: item.name,
                          number: item.number,
                          settings,
                        }))}
                      </Select>
                    ),
                  })}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="phaseId"
                  label="Phase"
                  className="schedule-form-item"
                  labelCol={formLabelStyle}
                  initialValue={phaseId}
                >
                  {getFormView({
                    isDisplay,
                    value: getFromMap({
                      id: phaseId,
                      idMap: phaseIdMap,
                      defaultValue: costcodeId ? 'Unphased' : null,
                    }),
                    input: (
                      <Select
                        placeholder="Select a Phase"
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showSearch
                        disabled={!selectedProject}
                        onSelect={onPhaseSelect}
                        onChange={(selected) => {
                          if (!selected) setSelectedCC();
                        }}
                        allowClear
                      >
                        {basicOptions(phases)}
                      </Select>
                    ),
                  })}
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="costcodeId"
                  label="Costcode"
                  className="schedule-form-item"
                  labelCol={formLabelStyle}
                  initialValue={costcodeId}
                >
                  {getFormView({
                    isDisplay,
                    value: getFromMap({
                      id: costcodeId,
                      idMap: costcodeMap,
                      formatter: (cc) => `${cc.code} - ${cc.name}`,
                    }),
                    input: (
                      <Select
                        placeholder="Select a Cost Code"
                        optionLabelProp="label"
                        optionFilterProp="label"
                        showSearch
                        disabled={!selectedProject || !selectedPhase}
                        onSelect={(selectedId) => setSelectedCC(selectedId)}
                        allowClear
                      >
                        {basicOptions(costcodes, ({ code, name }) => `${code} - ${name}`)}
                      </Select>
                    ),
                  })}
                </Form.Item>
              </Col>
            </Row>
            )}
            <Form.Item
              name="color"
              label="Color"
              style={{ marginBottom: isDisplay ? 0 : 10, maxWidth: 150 }}
              labelCol={{
                style: {
                  paddingBottom: 0,
                  marginTop: 5,
                },
              }}
              initialValue={color || undefined}
            >
              <FormColorPicker isNotDisplay={!isDisplay} allowClear />
            </Form.Item>
            <Form.Item
              name="teams"
              label="Team"
              className="schedule-form-item"
              labelCol={formLabelStyle}
              initialValue={teams}
            >
              <ScheduleTeamSelector
                isDisplay={isDisplay}
                teamMap={teamMap}
                getFromMap={getFromMap}
                getFormView={getFormView}
                basicOptions={basicOptions}
                activeTeams={activeTeams}
                onChange={onTeamSelect}
                onTeamClick={onTeamClick}
              />
            </Form.Item>
            <Form.Item
              name="users"
              label="Assigned Users"
              className="schedule-form-item"
              labelCol={formLabelStyle}
              initialValue={users}
              rules={[
                {
                  validator: () => validateExpectedUsers({
                    form: formRef?.current,
                    userToLabel,
                    userLabelMap,
                    userMap,
                    positionMap,
                  }),
                },
              ]}
            >
              {
          isDisplay
            ? users.map((userId) => (
              <Tag key={userId}>{getFromMap({ id: userId, idMap: userMap })}</Tag>
            ))
            : (
              <ScheduleUserSelector
                users={divisionId ? divUsers : activeUsers}
                onUserClick={onUserClick}
                dateRange={dateRange}
                showLabels
              />
            )
        }
            </Form.Item>
            <Form.Item
              name="expectedUsers"
              label="Expected User Groups"
              className="error-warning-form-item"
              initialValue={expectedUsers?.length ? expectedUsers : [{ type: 'default', id: null, count: 0 }]}
              rules={[
                {
                  validator: () => validateExpectedUsers({
                    form: formRef?.current,
                    userToLabel,
                    userLabelMap,
                    userMap,
                    positionMap,
                  }),
                },
              ]}
            >
              <ScheduleExpectedUsersInput readOnly={isDisplay} />
            </Form.Item>
            {!isEvent && (
            <Form.Item
              name="description"
              label="Description"
              className="schedule-form-item"
              labelCol={formLabelStyle}
              initialValue={description}
            >
              {getFormView({
                isDisplay,
                value: description,
                input: <OnTraccrTextInput textarea />,
              })}
            </Form.Item>
            )}
            {!isEvent && (
            <Form.Item
              name="shouldLockClockIn"
              label="Should Lock Clock In Details"
              className="schedule-form-item"
              initialValue={!!shouldLockClockIn}
              valuePropName="checked"
              labelCol={{ style: { marginTop: 20 } }}
            >
              {!isDisplay && <Switch />}
            </Form.Item>
            )}

            {formId && (
            <Form.Item
              label="Form"
              className="schedule-form-item"
              labelCol={formLabelStyle}
            >
              <BorderlessButton
                onClick={onFormClick}
                style={{ textAlign: 'left' }}
                title={(
                  <span>
                    <FormOutlined style={{ marginRight: 10 }} /> {`${formTemplateName}${formNumber ? ` - ${formNumber}` : ''}`}
                  </span>
                )}
              />
            </Form.Item>
            )}

            {!isEvent && (
            <Form.Item
              name="files"
              label="Attachments"
              className="schedule-form-item"
              labelCol={formLabelStyle}
              initialValue={files}
              valuePropName="files"
            >
              <FileReelWithUpload
                isDisplay={isDisplay}
              />
            </Form.Item>
            )}

            {
              isEvent
                ? (
                  <ScheduleEventForm
                    isDisplay={isDisplay}
                    initialValue={formTemplateId}
                    formRef={formRef}
                    onFormDataChanged={setFormData}
                    shiftId={id}
                    isNew={isNew}
                  />
                )
                : null
            }

          </Form>
        </Col>
      </Row>
      {!isDisplay && (
      <div className="drawer-footer">
        <Row justify="space-between" gutter={10}>
          <Col>
            {!isNew && (
            <OnTraccrButton
              title="Delete"
              type="back"
              onClick={() => onDelete(shift)}
            />
            )}
          </Col>
          <Col>
            <Row>
              <OnTraccrButton
                title="Cancel"
                type="cancel"
                style={{ marginRight: 8 }}
                onClick={onClose}
              />
              <OnTraccrButton
                title="Save"
                htmlType="submit"
                loading={isLoading}
                onClick={onSaveClicked}
              />
            </Row>
          </Col>
        </Row>
      </div>
      )}
      <GanttScheduleAddDrawer
        userLabelMap={userLabelMap}
        positionMap={positionMap}
        row={ganttScheduleRow}
        readOnly
        visible={showGanttDrawer && ganttScheduleRow && !Common.isObjectEmpty(ganttScheduleRow)}
        onClose={() => setShowGanttDrawer(false)}
        isShiftConnections
      />
    </Drawer>
  );
}

ScheduleDrawer.propTypes = {
  isNew: PropTypes.bool,
  visible: PropTypes.bool,
  isDisplay: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  openFormDetail: PropTypes.func.isRequired,
  shift: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    day: PropTypes.instanceOf(DateTime),
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    projectId: PropTypes.string,
    phaseId: PropTypes.string,
    costcodeId: PropTypes.string,
    description: PropTypes.string,
    divisionId: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string),
    expectedUsers: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      count: PropTypes.number,
    })),
    files: PropTypes.arrayOf(PropTypes.shape({})),
    formId: PropTypes.string,
    isEvent: PropTypes.bool,
    formTemplateId: PropTypes.string,
    formTemplateName: PropTypes.string,
    formNumber: PropTypes.string,
    color: PropTypes.string,
    reminders: PropTypes.arrayOf(PropTypes.shape({})),
    repeat: PropTypes.string,
    repeatEndDate: PropTypes.number,
    groupId: PropTypes.string,
    shouldLockClockIn: PropTypes.bool,
    ganttScheduleRowId: PropTypes.string,
  }),
  shifts: PropTypes.shape({}),
  shiftMap: PropTypes.shape({}),
  lastDownload: PropTypes.arrayOf(PropTypes.number),
  setLastDownload: PropTypes.func.isRequired,
  style: PropTypes.shape({}),
  hideCalendar: PropTypes.bool,
  isGanttDispatch: PropTypes.bool,
};

ScheduleDrawer.defaultProps = {
  isNew: false,
  visible: false,
  isDisplay: false,
  shift: {
    assignedUsers: [{
      type: 'default',
      id: null,
      count: 0,
    }],
  },
  shifts: {},
  shiftMap: {},
  lastDownload: [],
  style: {},
  hideCalendar: false,
  isGanttDispatch: false,
};
