import React from 'react';

import NuxTabPane from '../NuxTabPane';
import { 
  SETTING_PAYROLL_TYPE,
  TIME_TRACKING_TYPE,
  GPS_TYPE,
  OVERTIME_TYPE,
  APPROVALS_TYPE,
  TABLET_TYPE,
  USER_ROLES_TYPE,
  INTEGRATION_TYPE,
} from '../nux.constants';

import NuxSettingPayroll from './NuxSettingPayroll';
import NuxTimeTracking from './NuxTimeTracking';
import NuxGPS from './NuxGPS';
import NuxOvertime from './NuxOvertime';
import NuxTimeCard from './NuxTimeCard';
import NuxTablet from './NuxTablet';
import NuxPermissions from './NuxPermissions';
import NuxIntegrations from './NuxIntegrations';

import payroll from '../../assets/images/NUX/payroll.svg';
import timeTracking from '../../assets/images/NUX/timeTracking.svg';
import gps from '../../assets/images/NUX/gps.svg';
import overtime from '../../assets/images/NUX/overtime.svg';
import timecardApproval from '../../assets/images/NUX/timecardApproval.svg';
import tabletMode from '../../assets/images/NUX/tabletMode.svg';
import permissions from '../../assets/images/NUX/permissions.svg';
import integrations from '../../assets/images/NUX/integrations.png';

import Analytics from '../../helpers/Analytics';

const settingPayrollTitle = 'Setting Payroll';
const timeTrackingTitle = 'Time Tracking';
const gpsTitle = 'Using GPS';
const overtimeTitle = 'Overtime Rules';
const timecardTitle = 'Time Card Approvers';
const tabletTitle = 'Shared Tablet Mode';
const userPermissionsTitle = 'Setting User Permissions';
const integrationsTitle = 'Integrations';

export default ({
  setDetailView,
  setTitle,
  setDescription,
  resetToDefault,
  history,
  onCloseClicked,
  nux = new Set(),
}) => (
  <NuxTabPane
    setDetailView={setDetailView}
    setTitle={setTitle}
    setDescription={setDescription}
    rows={2}
    buttons={[
      [
        {
          title:settingPayrollTitle,
          description:'There are two main settings needed to set up your company\'s payroll',
          src:payroll,
          complete:nux.has(SETTING_PAYROLL_TYPE),
          detailView:(
           <NuxSettingPayroll
            onBack={resetToDefault}
            onTry={() => {
              Analytics.track('NUX/TryIt', { NuxPage: settingPayrollTitle});
              history.push('/settings', { 
                activeTab: 'time_tracking', 
                activeSubTab: 'payroll'
              });
              onCloseClicked();
            }}
          />
          ),
        },
        {
          title:timeTrackingTitle,
          description:'Select how you want your company to record time:',
          src:timeTracking,
          complete:nux.has(TIME_TRACKING_TYPE),
          detailView:(
            <NuxTimeTracking 
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: timeTrackingTitle});
                history.push('/settings', { 
                  activeTab: 'time_tracking', 
                  activeSubTab: 'clockSettings'
                });
                onCloseClicked();
              }}
            />
          )
        },
        {
          title:gpsTitle,
          description:(<div>
          There are two GPS features you can use, as described below.
          <br/>(Don't worry, we made sure to use GPS in a way that won't
          eat up your phone's battery life)
          </div>),
          src:gps,
          complete:nux.has(GPS_TYPE),
          detailView:(
            <NuxGPS
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: gpsTitle});
                history.push('/settings', { 
                  activeTab: 'time_tracking', 
                  activeSubTab: 'gps'
                });
                onCloseClicked();
              }}
            />
          )
        },
        {
          title:overtimeTitle,
          description:'Configure your company\'s overtime policies:',
          src:overtime,
          complete:nux.has(OVERTIME_TYPE),
          detailView:(
            <NuxOvertime
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: overtimeTitle});
                history.push('/settings', { 
                  activeTab: 'time_tracking', 
                  activeSubTab: 'overtime'
                });
                onCloseClicked();
              }}
            />
          )
        }
      ],
      [
        {
          title: timecardTitle,
          description:`
            The Time Card approval process is an important part of job costing
            as it ensures that employees are clocking in to the correct cost codes. There
            are two options for selecting Time Card approvers:
          `,
          src:timecardApproval,
          complete:nux.has(APPROVALS_TYPE),
          detailView:(
            <NuxTimeCard
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: timecardTitle});
                history.push('/settings', { 
                  activeTab: 'time_tracking', 
                  activeSubTab: 'timeCards'
                });
                onCloseClicked();
              }}
            />
          )
        },
        {
          title: tabletTitle,
          description:`Tablet Mode has been designed specifically
          for workers to share onsite, enabling an experience similiar to traditional
          punch clocks. This can be helpful in case your company policy does not
          allow workers to use their own phones, you can get them to clock in to a
          company-owned tablet device instead.
          `,
          src:tabletMode,
          complete:nux.has(TABLET_TYPE),
          detailView:(
            <NuxTablet
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: tabletTitle});
                history.push('/settings', { 
                  activeTab: 'tablet_mode'
                });
                onCloseClicked();
              }}
            />
          )
        },
        {
          title: userPermissionsTitle,
          description:`Setting specific user role permissions is a major
          piece of customizing your entire Ontraccr experience.
          This is where you decide what each user role can and cannot do.`,
          src:permissions,
          complete:nux.has(USER_ROLES_TYPE),
          detailView:(
            <NuxPermissions
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: userPermissionsTitle});
                history.push('/settings', { 
                  activeTab: 'roles'
                });
                onCloseClicked();
              }}
            />
          )
        },
        {
          title: integrationsTitle,
          description:`Ontraccr integrates with the tools you use daily, 
          streamlining and simplifying workflows to save you time and money.`,
          src: integrations,
          complete:nux.has(INTEGRATION_TYPE),
          detailView:(
            <NuxIntegrations
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: integrationsTitle});
                history.push('/settings', { 
                  activeTab: 'integrations'
                });
                onCloseClicked();
              }}
            />
          )
        },
      ]
    ]}
  />
);
