import React from 'react';
import PropTypes from 'prop-types';

import blackLogo from '../assets/images/Ontraccr-Logo-B-1400x400.png';
import whiteLogo from '../assets/images/Ontraccr-Logo-W-1400x400.png';

const LOGO_ASPECT = 3.1;
const DEFAULT_HEIGHT = 110;

function Logo({
  style = {},
  height = DEFAULT_HEIGHT,
  darkMode,
}) {
  return (
    <img
      alt="Ontraccr Logo"
      src={darkMode ? whiteLogo : blackLogo}
      style={{
        width: height * LOGO_ASPECT,
        height,
        padding: 6,
        ...style,
      }}
    />
  );
}

Logo.propTypes = {
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  height: PropTypes.number,
  darkMode: PropTypes.bool,
};

Logo.defaultProps = {
  style: {},
  height: DEFAULT_HEIGHT,
  darkMode: false,
};

export default Logo;
