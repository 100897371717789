export default {};

export const defaultFilters = (positions) => ({
  position: Object.keys(positions),
  labels: [],
  lastActive: ['activeToday', 'inactiveToday'],
  status: ['Clocked In', 'Clocked Out', 'On Break', 'Inactive'],
  timecardStatus: ['submittedTimecards', 'unsubmittedTimecards'],
  active: [1],
});

export const firstRowFilters = (positions, labels) => [
  [
    {
      title: 'Role',
      key: 'position',
      children: Object.keys(positions).map((position) => ({
        title: position,
        key: position,
      })),
    },
  ],
  [
    {
      title: 'Labels',
      key: 'labels',
      children: labels.map(({ title, id }) => ({
        title,
        key: id,
      })),
    },
  ],
  [
    {
      title: 'Clock In Status',
      key: 'status',
      children: [
        {
          title: 'Clocked In',
          key: 'Clocked In',
        },
        {
          title: 'Clocked Out',
          key: 'Clocked Out',
        },
        {
          title: 'On Break',
          key: 'On Break',
        },
        {
          title: 'Inactive',
          key: 'Inactive',
        },
      ],
    },
  ],
];

export const secondRowFilters = [
  [
    {
      title: 'Timecard Status',
      key: 'timecardStatus',
      children: [
        {
          title: 'Submitted',
          key: 'submittedTimecards',
        },
        {
          title: 'Unsubmitted',
          key: 'unsubmittedTimecards',
        },
      ],
    },
  ],
  [
    {
      title: 'Last Active',
      key: 'lastActive',
      children: [
        {
          title: 'Active Today',
          key: 'activeToday',
        },
        {
          title: 'Inactive Today',
          key: 'inactiveToday',
        },
      ],
    },
  ],
  [
    {
      title: 'Archive',
      key: 'active',
      children: [
        {
          title: 'Active',
          key: 1,
        },
        {
          title: 'Archived',
          key: 0,
        },
      ],
    },
  ],
];
