import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import FormsList from '../forms/CompletedForms/FormsList';
import { getForms } from '../forms/state/forms.actions';

export default ({
  equipmentId,
  topOffset = 0,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (equipmentId) dispatch(getForms({ equipmentId }));
  },[dispatch, equipmentId]);

  const forms = useSelector(state => state.forms.forms);

  const formsList = useMemo(() => (
    Object.values(forms).filter((form) => form.equipmentId === equipmentId || form.cardEquipment?.includes(equipmentId))
  ),[forms, equipmentId]);

  const query = useMemo(() => ({ equipmentId }),[equipmentId]);

  return (
    <div style={{ paddingTop: '10px' }}>
      <FormsList
        topOffset={240 + topOffset}
        forms={formsList}
        hideDate
        query={query}
      />
    </div>
  )
};
