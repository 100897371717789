import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Divider, Form } from 'antd';
import FormExportLocationDrawer from './FormExportLocationDrawer';

import BasicPDF from '../../common/pdf/BasicPDF';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import DisplayText from '../../common/text/DisplayText';

import { getInitialCustomPDFItems } from '../../common/pdf/PDFDesigner/PDFDesignerHelpers';
import { getPDF } from '../formHelpers';

export default ({
  formRef,
  onPDFChange,
  exportLocation: initialExportLocation,
  collected = {},
  sections = [],
  drawOptions = [],
  isDisplay = false,
  fileMap = {},
  useStandardTemplate,
}) => {
  const company = useSelector((state) => state.settings.company);

  const settings = company?.settings;

  const [exportLocation,setExportLocation] = useState(initialExportLocation);
  const [selectedLocation,setSelectedLocation] = useState();
  const [showDrawer,setShowDrawer] = useState();
  const [pdfFile,setPDFFile] = useState();

  const openDrawer = useCallback(() => setShowDrawer(true),[]);
  const closeDrawer = useCallback((save) => () => {
    setShowDrawer(false);
    if(save) {
      setExportLocation(selectedLocation);
    } else {
      setSelectedLocation(exportLocation);
    }

  },[selectedLocation,exportLocation]);

  useEffect(() => {
    if(formRef && formRef.current) {
      formRef.current.setFieldsValue({
        exportLocation,
      });
    }
  },[exportLocation,formRef]);

  useEffect(() => {
    const getPDFFile = async () => {
      const vals = formRef.current.getFieldsValue();
      let ourItems = drawOptions.length === 0
      ? getInitialCustomPDFItems({
        collected, name: vals.name, settings: settings ?? {}, sections
      })
      : drawOptions;

      const newPDFFile = await getPDF({
        form: {
          ...vals,
          collected,
          sections,
          useStandardTemplate,
        },
        drawOptions: ourItems,
        fileMap
      });

      setPDFFile(newPDFFile);
    };

    if(formRef && formRef.current) {
      getPDFFile();
    }
  },[settings, formRef,collected, sections, drawOptions, fileMap, useStandardTemplate]);

  useEffect(() => {
    if(onPDFChange) onPDFChange(pdfFile);
  },[pdfFile,onPDFChange]);

  useEffect(() => {
    setExportLocation(initialExportLocation);
    setSelectedLocation(initialExportLocation);
  },[initialExportLocation]);

  return  (
    <div className='form-drawer-container'>
      <Row gutter={20} align='middle' style={{ height: 32, padding: '0px 24px' }}>
        <Col>
          <Form.Item
            name='exportLocation'
            label='Export Location'
            rules={[
              { required: true, message: 'Please enter the export location' },
            ]}
            style={{ height: 32, marginBottom: 0 }}
          >
            {
              isDisplay
              ? <DisplayText title={exportLocation} style={{ marginBottom: 0, fontSize: 16 }} />
              : <BorderlessButton
                  title={exportLocation || 'Set'}
                  onClick={openDrawer}
                />
            }
          </Form.Item>
        </Col>

      </Row>
      <div className='form-export-preview'>
        <Divider style={{ margin: 0 }} />
        <Row style={{ width: '100%',height: '100%', overflow: 'scroll'}} justify='center' align='middle'>
          <div style={{ paddingLeft: 50 }}>
            {pdfFile &&
              <BasicPDF file={pdfFile}
                hideControls
                initialZoom={0.75}
                darkMode={false}
                canvasStyle={{
                  margin: 0,
                  width: '100%',
                  height: '100%',
                  borderRadius: 4,
                  border: '1px lightgray solid',
                  boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.25)',
                }}
              />
            }
          </div>
        </Row>
      </div>

      <FormExportLocationDrawer
        visible={showDrawer}
        onClose={closeDrawer(false)}
        onSave={closeDrawer(true)}
        selected={[selectedLocation]}
        onSelect={setSelectedLocation}
      />
    </div>
  );
};

