/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Radio, Space, Select } from 'antd';

import PropTypes from 'prop-types';

import SimpleFormModal from '../common/modals/SimpleFormModal';

import { reorderCards } from './state/boards.actions';

function BoardReorderModal({
  visible,
  onClose,
  cardTypeId,
  isSorted,
  useCardNumberAsCardTitle,
}) {
  const dispatch = useDispatch();
  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const sortType = useSelector((state) => state.boards.sortType.type);

  const [selectedType, setSelectedType] = useState('default');
  const [selectedDateTimeField, setSelectedDateTimeField] = useState();

  const {
    fields: sections = [],
  } = useMemo(() => {
    const {
      [cardTypeId]: template = {},
    } = cardTemplates;
    return template;
  }, [cardTypeId, cardTemplates]);

  const dateTimeFields = useMemo(() => {
    const dt = [];
    sections.forEach((section) => {
      const { fields = [] } = section;
      fields.forEach((field) => {
        const { configProps: { title } = {}, selectedType: fieldType, id: fieldId } = field;
        if (fieldType === 'dateTime') {
          dt.push({ value: fieldId, label: title });
        }
      });
    });

    return dt;
  }, [sections]);

  const onReorder = useCallback(async () => {
    if (!selectedBoard || !selectedType) return;
    if (selectedType.startsWith('dateTime') && !selectedDateTimeField) return;
    dispatch(reorderCards({
      type: selectedType,
      field: selectedDateTimeField,
    }));
    onClose();
    const storedSortType = selectedType.startsWith('dateTime')
      ? 'default'
      : selectedType;
    window.localStorage.setItem('boardSortType', storedSortType);
  }, [onClose, selectedType, selectedBoard, selectedDateTimeField]);

  const onTypeSelected = useCallback((e) => {
    const {
      target: { value } = {},
    } = e;
    setSelectedType(value);
  }, []);

  const getDateTimeRadio = useCallback((type) => (
    <Radio value={`dateTime-${type}`}>
      {`Date/Time: ${type === 'asc' ? 'Ascending' : 'Descending'}`}
      {selectedType === `dateTime-${type}` && (
        /*
        wrapping Select in a span with e.preventDefault() seems to fix
        issue when Select is nested within a Radio button
        see: https://github.com/ant-design/ant-design/issues/25959
        */
        <span onClick={(e) => e.preventDefault()}>
          <Select
            style={{ width: 150, marginLeft: 10 }}
            onSelect={setSelectedDateTimeField}
            placeholder="Select Field"
            options={dateTimeFields}
            value={selectedDateTimeField}
          />
        </span>
      )}
    </Radio>
  ), [selectedType, selectedDateTimeField, dateTimeFields]);

  useEffect(() => {
    if (!visible && !isSorted) {
      setSelectedType('default');
      setSelectedDateTimeField();
    }
  }, [visible, isSorted]);

  useEffect(() => {
    setSelectedType(sortType);
  }, [sortType]);

  const titleOrNumber = useMemo(() => (useCardNumberAsCardTitle ? 'number' : 'title'), [useCardNumberAsCardTitle]);

  return (
    <SimpleFormModal
      title="Sort Cards"
      onClose={onClose}
      onSave={onReorder}
      disabled={!selectedType}
      visible={visible}
      width={450}
    >
      <Radio.Group
        onChange={onTypeSelected}
        value={selectedType}
        style={{
          width: '100%',
          marginLeft: 55,
          textAlign: 'left',
        }}
      >
        <Space direction="vertical">
          <Radio value="default">Default</Radio>
          <Radio value={`${titleOrNumber}-asc`}>Title: A to Z</Radio>
          <Radio value={`${titleOrNumber}-desc`}>Title: Z to A</Radio>
          <Radio value="lastMoveTimestamp-asc">Time in Status: Ascending</Radio>
          <Radio value="lastMoveTimestamp-desc">Time in Status: Descending</Radio>
          {dateTimeFields.length > 0 && (
            <>
              {getDateTimeRadio('asc')}
              {getDateTimeRadio('desc')}
            </>
          )}
        </Space>
      </Radio.Group>
    </SimpleFormModal>
  );
}

BoardReorderModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  cardTypeId: PropTypes.number.isRequired,
  isSorted: PropTypes.bool,
  useCardNumberAsCardTitle: PropTypes.bool,
};

BoardReorderModal.defaultProps = {
  isSorted: false,
  useCardNumberAsCardTitle: false,
};

export default BoardReorderModal;
