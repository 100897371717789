import React, { useCallback, useEffect } from 'react';
import {
  Checkbox,
  Col,
  Divider,
  Form,
  Row,
} from 'antd';
import PropTypes from 'prop-types';

import CustomModal from '../../../common/modals/CustomModal';
import HoverHelp from '../../../common/HoverHelp';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import colors from '../../../constants/Colors';
import FormSectionPermissions from './FormSectionPermissions';

export default function FormSectionSettingsModal({
  visible,
  onSettingsUpdated,
  onCloseClicked,
  initialValues = {},
  divisions,
  isBoardCards,
  isExternalForm,
  enablePermissions,
}) {
  const [form] = Form.useForm();

  const onSubmit = useCallback(() => {
    const values = form.getFieldsValue(true);
    onSettingsUpdated(values);
    onCloseClicked();
  }, [form, onSettingsUpdated]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    }
  }, [visible, form]);

  return (
    <CustomModal
      title="Section Settings"
      visible={visible}
      onCloseClicked={onCloseClicked}
      maskColor={colors.MODAL_MASK_DARK}
      width={600}
    >
      <Form
        form={form}
        initialValues={initialValues}
        style={{ paddingLeft: 25, paddingRight: 25 }}
      >
        <Form.Item
          name="defaultCollapsed"
          label={(
            <Row gutter={20}>
              <Col>
                Default Collapsed
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ width: 300 }}>
                      If you check this box,
                      the section will be collapsed by default when
                      a user opens the form
                    </div>
                  )}
                />
              </Col>
            </Row>
          )}
          valuePropName="checked"
        >
          <Checkbox />
        </Form.Item>
        { !isBoardCards && (
          <Form.Item
            name="isDuplicationEnabled"
            label={(
              <Row gutter={20}>
                <Col>
                  Enable Section Duplication
                </Col>
                <Col>
                  <HoverHelp
                    content={(
                      <div style={{ width: 300 }}>
                        If you check this box,
                        the section can be duplicated by another user when filling out the form.
                        Note that calculation fields are not supported and
                        only standard templates can be used.
                      </div>
                    )}
                  />
                </Col>
              </Row>
            )}
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        )}
        <b>
          Warning: Setting this section to default collapsed will make all fields
          in the section optional
        </b>
        {!isExternalForm && enablePermissions && (
          <>
            <Divider />
            <Form.Item
              name="permissions"
              trigger="onPermissionChanged"
              valuePropName="permissions"
            >
              <FormSectionPermissions
                divisions={divisions}
              />
            </Form.Item>
          </>
        )}
      </Form>
      <OnTraccrButton
        title="Save"
        roundStyle
        style={{ margin: 10 }}
        onClick={onSubmit}
      />
    </CustomModal>
  );
}

FormSectionSettingsModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onSettingsUpdated: PropTypes.func.isRequired,
  onCloseClicked: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    defaultCollapsed: PropTypes.bool,
    isDuplicationEnabled: PropTypes.bool,
  }),
  divisions: PropTypes.array,
  isForm: PropTypes.bool,
  isBoardCards: PropTypes.bool,
  isExternalForm: PropTypes.bool,
};

FormSectionSettingsModal.defaultProps = {
  initialValues: {},
  divisions: undefined,
  isForm: false,
  isBoardCards: false,
  isExternalForm: false,
};
