import { DateTime } from 'luxon';
import moment from 'moment';

export const getDay = (end) => (end ? end.toSQLDate() : DateTime.local().toSQLDate());
/*
  This will fail if two concurrent tasks are on the same week but different years
  e.g. one is first week of 2023 and the next is first week of 2024

  Changes made here to migrate to luxon:
  https://bitbucket.org/ontraccr/web-dashboard/pull-requests/2455#Lsrc/helpers/overtime/otHelpers.jsT8

  Only work for luxon > 3.4.4
  https://github.com/moment/luxon/blob/master/CHANGELOG.md#344-2023-11-12

  We're on ^1.25.0
*/
export const getWeek = (end) => (
  end && DateTime.isDateTime(end)
    ? moment(end.toMillis()).week()
    : moment().week()
);

export const hoursToMs = (hours) => hours * (1000 * 60 * 60);
