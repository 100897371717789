import React from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Tabs } from 'antd';

import LoginFormContainer from '../../auth/login/LoginFormContainer';
import ClientPortalMainScreen from '../../clientPortal/ClientPortalMainScreen';

export default ({
  visible,
  onClose,
}) => {
  const {
    primaryColor,
    accentColor,
    image,
  } = useSelector(state => state.clientPortal.settings);

  return (
    <Drawer
      onClose={onClose}
      visible={visible}
      width='90vw'
      title='Client Portal Preview'
      bodyStyle={{ padding: 0 }}
    >
      <Tabs tabBarStyle={{ position: 'absolute', top: 9, left: 250, paddingBottom: 10 }}>
        <Tabs.TabPane tab={<div className='client-portal-preview-tab-title'>Login</div>} key='login'>
          <LoginFormContainer
          style={{ top: 55 }}
          onSubmit={() => 1}
          primaryColor={primaryColor}
          accentColor={accentColor}
          image={
            <img 
              alt='Logo'
              src={image} 
              style={{
                maxWidth: 300,
                maxHeight: 200,
                width: 'auto',
                height:'auto',
                borderRadius:5,
                padding:6,
                marginBottom:30,
            }}/>
          }
        />
        </Tabs.TabPane>
        <Tabs.TabPane tab={<div className='client-portal-preview-tab-title'>Portal</div>} key='portal'>
          <ClientPortalMainScreen style={{ top: 55 }} previewMode/>
        </Tabs.TabPane>
      </Tabs>
      
    </Drawer>
  )
}