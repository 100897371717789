/* eslint-disable react/jsx-props-no-spreading */
import {
  React,
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Form,
  List,
  Row,
} from 'antd';
import { DateTime } from 'luxon';
import { PlusOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import Colors from '../../constants/Colors';
import AddDrawer from '../../common/addDrawer';
import DisplayText from '../../common/text/DisplayText';
import WarningHeader from '../../common/text/WarningHeader';
import ProjectAddView from '../../projects/projectAddView';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FormTextInput from '../../common/inputs/FormTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import CustomTableListItem from '../Forms/CustomTableListItem';
import CompanySettingsCard from '../CompanySettingsCard';
import {
  addProjectFileTemplate,
  deleteProjectFileTemplate,
  getProjectFileTemplates,
  updateProjectFileTemplate,
} from '../../projects/state/projects.actions';

const deleteModal = (onOk) => CustomConfirmModal({
  title: (
    <WarningHeader
      justify="center"
      title="Delete Confirmation"
    />
  ),
  content: (
    <p>Are you sure you wish to delete this template?</p>
  ),
  onOk,
});

export default function ProjectFileTemplates() {
  const { t } = useTranslation();

  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [templateValues, setTemplateValues] = useState({});
  const [lastOpen, setLastOpen] = useState(DateTime.local().toMillis());

  const templates = useSelector((state) => state.projects.projectFileTemplates);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const settings = useSelector((state) => state.settings.company.settings);
  const users = useSelector((state) => state.users.users);
  const tabs = useSelector((state) => state.settings.tabs);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getProjectFileTemplates());
  }, []);

  const templateTabs = useMemo(() => tabs.filter(
    (tab) => tab.tabName === 'Files',
  ), [tabs]);

  const onAddClicked = useCallback(() => {
    setShowDrawer(true);
  }, []);

  // Store all updates from addProjectView in local state
  const onValuesChanged = useCallback((key, value) => {
    setTemplateValues((prevValues) => ({
      ...prevValues,
      [key]: value,
    }));
  }, []);

  const clearValues = useCallback(() => {
    setSelectedTemplate();
    setTemplateValues({});
    setShowDrawer(false);
    setLastOpen(DateTime.local().toMillis());
  }, []);

  // Submit and clear values
  const onDrawerClose = useCallback(async (values, submitted) => {
    let success = false;
    if (!submitted) {
      clearValues();
      return success;
    }
    const {
      fileMap: newFileMap,
    } = templateValues;

    const {
      name,
      description,
      divisionId,
    } = values;

    if (!name || !description || !divisionId) {
      return false;
    }

    const ourTemplate = templates.find((template) => template.id === selectedTemplate);

    const payload = {
      name,
      description,
      divisionId,
      data: JSON.stringify(newFileMap),
    };

    if (ourTemplate) {
      success = await dispatch(updateProjectFileTemplate(selectedTemplate, payload));
    } else {
      success = await dispatch(addProjectFileTemplate(payload));
    }
    if (success) {
      clearValues();
    }
    return success;
  }, [
    templateValues,
    templates,
  ]);

  const onDelete = useCallback((id) => deleteModal(() => dispatch(deleteProjectFileTemplate(id))));

  const onEdit = useCallback((value) => {
    setSelectedTemplate(value);
    setShowDrawer(true);
  }, []);

  const templateDetailsView = useCallback(() => (
    <>
      <Row style={{ width: '100%' }} gutter={20}>
        <Col span={6}>
          <FormTextInput
            name="name"
            label="Name"
            isNotDisplay
            rules={[
              { required: true, message: 'Name is required ' },
            ]}
          />
        </Col>
        <Col span={6}>
          <Form.Item
            name="divisionId"
            label="Division"
            valuePropName="divisionId"
            rules={[{ required: true, message: 'Please enter a division' }]}
            style={{ marginTop: 7 }}
          >
            <DivisionSelector />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={12}>
          <FormTextInput
            name="description"
            label="Description"
            isNotDisplay
            textarea
            rules={[
              { required: true, message: 'Description is required ' },
            ]}
          />
        </Col>
      </Row>
    </>
  ), []);


  const renderItem = useCallback((item) => (
    <CustomTableListItem
      onDelete={onDelete}
      onEdit={onEdit}
      item={item}
    />
  ), [onDelete, onEdit]);

  // Props to be passed into the add view
  const editProps = useMemo(() => {
    const ourTemplate = templates.find((template) => template.id === selectedTemplate);
    const defaultProps = {
      showAddFileButton: false,
      showAddFileCheckbox: false,
      showFiles: true,
    };

    if (!ourTemplate) {
      // If adding, pass in nothing
      return defaultProps;
    }
    // If we're editing a template, use it's details
    const {
      name,
      description,
      divisionId,
      data,
    } = ourTemplate;

    const fileMap = JSON.parse(data);

    return {
      ...defaultProps,
      editing: true,
      name,
      description,
      divisionId,
      fileStructure: fileMap,
      key: selectedTemplate,
    };
  }, [selectedTemplate, templates]);

  const formView = useCallback((form, formProps) => (
    <ProjectAddView
      key={lastOpen}
      editing
      isAdd
      templateView
      {...form}
      {...formProps}
      costcodes={costcodes}
      settings={settings}
      history={history}
      users={users}
      onValuesChanged={onValuesChanged}
      tabs={templateTabs}
      createNuxEntry={() => {}}
      customSteps={[{
        content: templateDetailsView(),
        title: 'Template Details',
        key: 'projectFileTemplateInfo',
      }]}
      type={null}
      isFromProjectAddFileTemplates
    />
  ), [
    costcodes,
    users,
    settings,
    onValuesChanged,
    history,
    templateTabs,
    templateDetailsView,
    lastOpen,
  ]);

  return (
    <>
      <CompanySettingsCard title={`${t('Project')} Files`}>
        <Row style={{ margin: 10 }} align="bottom">
          <Col span={18}>
            <DisplayText
              title={`${t('Project')} Folder Structure Templates`}
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
            <DisplayText
              title={(
                <div style={{ width: '100%' }}>
                  Configure
                  {' '}
                  {t('Project').toLowerCase()}
                  {' '}
                  folder structure for new
                  {' '}
                  {t('Project', { count: 2 }).toLowerCase()}
                  <br />
                  <br />
                  Templates configured here can be added to new
                  {' '}
                  {t('Project', { count: 2 }).toLowerCase()}
                  .
                </div>
              )}
              style={{
                marginBottom: 0,
                fontFamily: 'roboto-regular',
                color: Colors.ONTRACCR_OPACITY_GRAY,
              }}
            />
          </Col>
          <Col span={6}>
            <OnTraccrButton
              disabled={templateTabs.length === 0}
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
            />
          </Col>
        </Row>
        <List
          style={{ marginLeft: 15 }}
          dataSource={templates}
          renderItem={renderItem}
        />
      </CompanySettingsCard>

      <AddDrawer
        key={lastOpen}
        onClose={onDrawerClose}
        isAdd
        title={selectedTemplate?.name || 'New Template'}
        visible={showDrawer}
        formView={formView}
        formProps={editProps}
        onDelete={onDelete}
        width={1400}
        numberOfSteps={2}
      />
    </>
  );
}
