import React from 'react';
import { Row, Col, Divider } from 'antd';
import PropTypes from 'prop-types';

import DisplayText from '../common/text/DisplayText';
import Colors from '../constants/Colors';

export default function CompanyEditRow({
  children,
  title,
  divider = true,
  helpText,
  style = {},
  childColumnStyle = {},
  childrenOnNewLine,
  textColumnMax = 250,
  minWidth = 440,
  maxWidth = 440,
}) {
  return (
    <div style={{ width: '100%' }}>
      <Row style={{ margin: 10, minHeight: 32, ...style }} justify="space-between" align="middle">
        <Col style={{ maxWidth: textColumnMax }}>
          <DisplayText title={title} style={{ fontFamily: 'roboto-medium', marginBottom: 0 }} />
          {helpText && (
          <DisplayText
            title={helpText}
            style={{
              fontFamily: 'roboto-regular', marginBottom: 0, color: Colors.ONTRACCR_OPACITY_GRAY,
            }}
          />
          )}
        </Col>
        {!childrenOnNewLine && (
        <Col style={childColumnStyle}>
          {children}
        </Col>
        )}
      </Row>
      {childrenOnNewLine ? children : null}
      {divider && (
      <Divider style={{
        margin: 0,
        backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
        maxWidth,
        minWidth,
      }}
      />
      )}
    </div>
  );
}

CompanyEditRow.propTypes = {
  children: PropTypes.node,
  title: PropTypes.string,
  divider: PropTypes.bool,
  helpText: PropTypes.string,
  style: PropTypes.shape({}),
  childColumnStyle: PropTypes.shape({}),
  childrenOnNewLine: PropTypes.bool,
  textColumnMax: PropTypes.number,
  minWidth: PropTypes.number,
  maxWidth: PropTypes.number,
};

CompanyEditRow.defaultProps = {
  children: null,
  title: '',
  divider: true,
  helpText: '',
  style: {},
  childColumnStyle: {},
  childrenOnNewLine: false,
  textColumnMax: 250,
  minWidth: 440,
  maxWidth: 440,
};
