import React, { useState } from 'react';
import { Tabs } from 'antd';
import FormsSearch from './FormsSearch';
import BreadcrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import BoardsSearch from './BoardsSearch';

const { TabPane } = Tabs;

const crumbs = [{ text: 'Search', icon: 'search' }];

export default function Search() {
  const [activeTab, setActiveTab] = useState('forms');
  return (
    <BreadcrumbContainer crumbs={crumbs}>
      <Tabs
        onChange={setActiveTab}
        activeKey={activeTab}
        tabPosition="left"
        style={{
          position: 'absolute',
          top: 90,
          right: 30,
          bottom: 23,
          left: 20,
        }}
        tabBarStyle={{
          width: 110,
        }}
      >
        <TabPane
          tab={<span>Forms</span>}
          key="forms"
          className="form-tab-pane"
        >
          <FormsSearch />
        </TabPane>
        <TabPane
          tab={<span>Boards</span>}
          key="boards"
          className="form-tab-pane"
        >
          <BoardsSearch />
        </TabPane>
      </Tabs>
    </BreadcrumbContainer>
  );
}
