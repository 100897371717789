import React, { useCallback } from 'react';
import { Row, Col, Checkbox } from 'antd';
import PropTypes from 'prop-types';

import HoverHelp from '../../common/HoverHelp';

function UpdateAddTimestampCheckbox({
  onChange,
  initialChecked,
}) {
  const onCheckChanged = useCallback((e) => {
    onChange(e?.target?.checked);
  }, [onChange]);
  return (
    <Row style={{ width: '100%', margin: '5px 0px' }}>
      <Col>
        <Checkbox
          onChange={onCheckChanged}
          defaultChecked={initialChecked}
        />
      </Col>
      <Col style={{ padding: '0px 5px' }}>
        Add date/time stamp
      </Col>
      <Col>
        <HoverHelp
          content={(
            <div style={{ width: 300 }}>
              If turned on, new text will include date and time stamps
            </div>
          )}
        />
      </Col>
    </Row>
  );
}

UpdateAddTimestampCheckbox.propTypes = {
  onChange: PropTypes.func.isRequired,
  initialChecked: PropTypes.bool,
};

UpdateAddTimestampCheckbox.defaultProps = {
  initialChecked: false,
};

export default UpdateAddTimestampCheckbox;
