import React from 'react';

function StatusList({
  statuses = [],
}) {
  return (
    statuses.map((status, idx) => (
      <div className="boards-status-card" key={status.id}>
        {idx + 1}
        {'. '}
        {status.title}
      </div>
    ))
  );
}

export default StatusList;
