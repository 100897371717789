import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import { Form } from 'antd';

// Import Components:
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

// Import Actions:
import { approveRejectForm } from './state/forms.actions';
import { constructFormPayloadForAPI, prepareResponsePayload, validateTimeEntryTableResponses } from './ResponderHelpers';
import FormTextInput from '../common/inputs/FormTextInput';

// CONSTANTS:
export const APPROVE_FORM_SUBMISSION = 'Approve';
export const REJECT_FORM_SUBMISSION = 'Reject';

/** Form Approval Button Component */
function FormApprovalButton({
  formId,
  type,
  style = {},
  redirectToHome = null,
  updateData,
  sections,
  responses,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const formApprovals = useSelector((state) => state.forms.approvals);

  const RejectionNote = (
    <Form form={form} initialValues={{ note: null }}>
      <FormTextInput
        name="note"
        isNotDisplay
        placeholder="Enter a rejection message"
        textProps={{
          autoComplete: 'off',
          textarea: true,
          rows: 4,
        }}
      />
    </Form>
  );

  const showModal = useCallback((e) => (buttonType) => {
    e.stopPropagation();
    const {
      [formId]: {
        id: approvalId,
        templateName: name,
        number,
      } = {},
    } = formApprovals;
    const approved = buttonType === APPROVE_FORM_SUBMISSION;
    const title = number
      ? `${buttonType} Form: ${name} #${number}?` : `${buttonType} Form: ${name}?`;

    let timeEntryValidationText = null;

    if (approved && !validateTimeEntryTableResponses({ sections, responses })) {
      timeEntryValidationText = 'A time entry exceeds the warning threshold of hours configured for this form. Please review the entries and make sure they are correct.';
    }

    CustomConfirmModal({
      title,
      okText: 'Confirm',
      cancelText: 'Cancel',
      content: !approved
        ? RejectionNote
        : timeEntryValidationText,
      async onOk() {
        let note = form.getFieldValue('note');
        if (note === '') note = null;
        let formData = {};
        if (updateData) {
          const payload = prepareResponsePayload(updateData);
          formData = await constructFormPayloadForAPI({
            form: payload,
            addSectionId: true,
          });
        }
        await dispatch(approveRejectForm(formId, {
          approvalId,
          approved,
          data: formData.data,
          files: formData.files,
          note,
        }));
        if (redirectToHome) {
          redirectToHome();
        }
      },
    });
  }, [formId, formApprovals, updateData, sections, responses]);

  return (
    <OnTraccrButton
      title={type}
      id={`form-${type === APPROVE_FORM_SUBMISSION ? 'approval' : 'reject'}-button`}
      style={{ margin: 2, ...style }}
      onClick={(e) => showModal(e)(type)}
    />
  );
}

FormApprovalButton.propTypes = {
  formId: PropTypes.string.isRequired,
  type: PropTypes.oneOf([APPROVE_FORM_SUBMISSION, REJECT_FORM_SUBMISSION]).isRequired,
  style: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ])),
  redirectToHome: PropTypes.func,
  updateData: PropTypes.shape({}),
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  responses: PropTypes.shape({}),
};

FormApprovalButton.defaultProps = {
  style: {},
  redirectToHome: null,
  updateData: undefined,
  sections: [],
  responses: {},
};

export default FormApprovalButton;
