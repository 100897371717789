import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import sortByString from '../../helpers/helpers';

export default function useFilteredBoardList({ asOptions } = {}) {
  const boards = useSelector((state) => state.boards.boards);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const boardList = useMemo(() => {
    const blist = Object.values(boards);
    blist.sort(sortByString('title'));
    const filtered = blist.filter((board) => (
      board?.divisions?.some?.((divisionId) => selectedDivisions.has(divisionId))
    ));
    if (!asOptions) return filtered;
    return filtered.map((board) => ({ value: board.id, label: board.title }));
  }, [boards, selectedDivisions, asOptions]);
  return boardList;
}
