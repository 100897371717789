import React, {
  useEffect,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse } from 'antd';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import {
  getUsers,
} from '../../users/state/users.actions';

import {
  getAllCertifications,
  deleteCertification,
  getCertificationCustomFieldTemplate,
} from '../../certifications/state/certifications.actions';

import AddEditCertification from '../../certifications/AddEditCertification';
import getCertificationColumns from '../../certifications/CertificationsColumns';
import { includesTerm, getIdMap } from '../../helpers/helpers';
import { hasPermissions } from '../../users/userHelpers';
import { getCustomFieldTableColumns } from '../../forms/formHelpers';
import CertificationsTable from '../../certifications/CertificationsTable';
import { parseFormResponseToReadableFormat } from '../../forms/ResponderHelpers';

const { Panel } = Collapse;

const APPROACHING_EXPIRY_MONTHS = 3;
// Number of seconds in 3 months
const APPROACHING_EXPIRY_TIME = APPROACHING_EXPIRY_MONTHS * 30 * 24 * 60 * 60;

const TYPE_MAP = {
  users: 'userId',
  vendors: 'vendorId',
};

const createCertificationExportRow = ({
  certification,
  status,
  customFieldColumns,
}) => {
  const baseColumns = [
    status,
    certification.userName,
    certification.type,
    certification.acquiredDate ? DateTime.fromSeconds(certification.acquiredDate).toLocaleString(DateTime.DATE_FULL) : '',
    certification.expiryDate ? DateTime.fromSeconds(certification.expiryDate).toLocaleString(DateTime.DATE_FULL) : '',
    certification.issuer,
  ];

  if (customFieldColumns?.length) {
    customFieldColumns.forEach((column) => {
      baseColumns.push(certification[column.dataIndex] ?? '-');
    });
  }

  return baseColumns;
};

function CertificationsReport({
  searchValue = '',
  exporter,
  entityType,
}) {
  const foreignKey = TYPE_MAP[entityType];
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const {
    [entityType]: {
      companyCertifications = [],
      certificationCustomFieldTemplate,
    } = {},
  } = useSelector((state) => state.certifications);
  const { settings = {} } = useSelector((state) => state.settings.company);
  const projects = useSelector((state) => state.projects.projects);

  const projectIdMap = useMemo(() => getIdMap(projects));

  const [
    isAddEditCertificationVisible,
    setIsAddEditCertificationVisible,
  ] = useState(false);
  const [selectedCertification, setSelectedCertification] = useState({});

  useEffect(() => {
    dispatch(getAllCertifications(entityType));
    dispatch(getUsers());
    dispatch(getCertificationCustomFieldTemplate(entityType));
  }, [entityType]);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const hasManageUserPermission = useCallback(
    (userId) => hasPermissions(userMap[userId]),
    [userMap],
  );

  const [
    expiredCertifications,
    expiringCertifications,
    activeCertifications,
  ] = useMemo(() => {
    const currentTime = DateTime.local().toSeconds();
    const closeToExpiryTime = currentTime + APPROACHING_EXPIRY_TIME;

    const certificationMap = {
      expired: [],
      expiring: [],
      active: [],
    };

    let filteredCertifications = companyCertifications.map((cert) => {
      const formattedCert = {
        ...cert,
      };

      const formResponses = parseFormResponseToReadableFormat({
        templateSections: certificationCustomFieldTemplate?.sections ?? [],
        responseSections: cert?.customData?.sections ?? [],
        projectIdMap,
        settings,
      });

      const stringifiedFormResponses = JSON.stringify(Object.values(formResponses));
      return {
        ...formattedCert,
        ...formResponses,
        formResponses: stringifiedFormResponses,
      };
    });

    if (searchValue && searchValue.trim().length > 0) {
      // Issuer is only non-required field so we need to check if it exists
      filteredCertifications = filteredCertifications.filter((certification) => (
        includesTerm(certification.type, searchValue)
        || (
          certification.issuer
          && includesTerm(certification.issuer, searchValue)
        )
        || includesTerm(certification.userName, searchValue)
        || includesTerm(certification.formResponses, searchValue)
      ));
    }

    filteredCertifications.forEach((certification) => {
      // Do not show inactive users
      const hideUser = !hasManageUserPermission(certification.userId)
        || !certification.userActive;
      if (!certification.vendorId && hideUser) {
        return;
      }

      if (certification.vendorId && !certification.vendorActive) return;

      if (certification.expiryDate) {
        if (certification.expiryDate < currentTime) {
          certificationMap.expired.push(certification);
          return;
        }

        if (certification.expiryDate < closeToExpiryTime) {
          certificationMap.expiring.push(certification);
        }
      }

      certificationMap.active.push(certification);
    });

    return [
      certificationMap.expired,
      certificationMap.expiring,
      certificationMap.active,
    ];
  }, [
    companyCertifications,
    searchValue,
    userMap,
    certificationCustomFieldTemplate,
  ]);

  const onUpdateClick = (record) => {
    setSelectedCertification(record);
    setIsAddEditCertificationVisible(true);
  };

  const onClose = useCallback(() => {
    setSelectedCertification({});
    setIsAddEditCertificationVisible(false);
  }, []);

  const customFieldColumns = useMemo(() => (
    getCustomFieldTableColumns(certificationCustomFieldTemplate?.sections ?? [])
  ), [certificationCustomFieldTemplate]);

  const tableColumns = useMemo(() => getCertificationColumns({
    entityType,
    showUserName: true,
    onUpdateClick,
    customFieldColumns,
  }), [onUpdateClick, customFieldColumns, entityType]);

  const onDeleteClick = useCallback((certification) => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Certification',
      content: (
        <p>
          Are you sure you wish to delete this certification?
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        resolve(await dispatch(
          deleteCertification(entityType, selectedCertification[foreignKey], certification.id),
        ));
        onClose();
      },
      onCancel() {
        resolve();
      },
    });
  }), [entityType, foreignKey, selectedCertification, onClose]);

  useEffect(() => {
    if (!exporter) return false;

    const exportColumns = [
      tableColumns
        .filter((column) => column.title && column.dataIndex !== 'files')
        .map((column) => column.title),
    ];

    exportColumns[0].unshift('Status');

    exporter.setTopLevelColumns(exportColumns);
    const exportRows = [];

    expiredCertifications.forEach((certification) => {
      exportRows.push(createCertificationExportRow({
        certification,
        status: 'Expired',
        customFieldColumns,
      }));
    });

    expiringCertifications.forEach((certification) => {
      exportRows.push(createCertificationExportRow({
        certification,
        status: 'Expiring Soon',
        customFieldColumns,
      }));
    });

    activeCertifications.forEach((certification) => {
      exportRows.push(createCertificationExportRow({
        certification,
        status: 'Active',
        customFieldColumns,
      }));
    });

    exporter.setTopLevelData(exportRows);
    exporter.updateSettings({
      hideBodyRow: true,
      hideSummaryRow: true,
      hideDate: true,
    });

    return () => {
      exporter.resetTopLevel();
      exporter.updateSettings({
        hideBodyRow: false,
        hideSummaryRow: false,
        hideDate: false,
      });
    };
  }, [
    exporter,
    tableColumns,
    customFieldColumns,
    expiredCertifications,
    expiringCertifications,
    activeCertifications,
  ]);

  return (
    <div style={{ width: '100%', height: 'calc(100% - 50px)', overflowY: 'scroll' }}>
      <Collapse defaultActiveKey={['expired', 'expiring', 'active']}>
        <Panel header="Expired" key="expired">
          <CertificationsTable
            certifications={expiredCertifications}
            columns={tableColumns}
            isExpandable={false}
            entityType={entityType}
          />
        </Panel>
        <Panel header="Expiring Soon" key="expiring">
          <CertificationsTable
            certifications={expiringCertifications}
            columns={tableColumns}
            isExpandable={false}
            entityType={entityType}
          />
        </Panel>
        <Panel header="Active" key="active">
          <CertificationsTable
            certifications={activeCertifications}
            columns={tableColumns}
            isExpandable={false}
            entityType={entityType}
          />
        </Panel>
      </Collapse>
      <AddEditCertification
        visible={isAddEditCertificationVisible}
        onClose={onClose}
        id={selectedCertification[foreignKey]}
        certification={selectedCertification}
        certificationId={selectedCertification.id}
        onDeleteClick={onDeleteClick}
        entityType={entityType}
      />
    </div>
  );
}

CertificationsReport.propTypes = {
  searchValue: PropTypes.string,
  exporter: PropTypes.shape({
    setTopLevelColumns: PropTypes.func,
    setTopLevelData: PropTypes.func,
    resetTopLevel: PropTypes.func,
    updateSettings: PropTypes.func,
  }),
  entityType: PropTypes.string.isRequired,
};

CertificationsReport.defaultProps = {
  searchValue: '',
  exporter: null,
};

export default (entityType) => function (props) {
  return (
    <CertificationsReport
      {...props} // eslint-disable-line react/jsx-props-no-spreading
      entityType={entityType}
    />
  );
};
