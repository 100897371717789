import React, { useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  DatePicker,
  TimePicker,
  Checkbox,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import { PropTypes } from 'prop-types';

import { isNullOrUndefined } from '../../helpers/helpers';
import {
  getRangeFormat,
  formatDay,
  formatRange,
  getAllDayValues,
} from '../../helpers/time';

import getFormView from './getFormView';
import { localToZoneTS, zoneToLocalTS } from '../../clock/ManualEntry/manualEntryHelpers';

export default function OnTraccrDatePicker({
  value: [startTime, endTime] = [],
  onChange,
  gutter = 20,
  isDisplay,
  hideCheckbox,
  hideTime,
  singleDay,
  allowClear,
  style = {},
  timezone = DateTime.local().zoneName,
}) {
  const startDT = useMemo(() => (
    isNullOrUndefined(startTime)
      ? null
      : DateTime.fromMillis(startTime, { zone: timezone })
  ), [startTime, timezone]);
  const endDT = useMemo(() => (
    isNullOrUndefined(endTime)
      ? null
      : DateTime.fromMillis(endTime, { zone: timezone })
  ), [endTime, timezone]);
  const startMoment = useMemo(() => (
    isNullOrUndefined(startTime)
      ? null
      : moment(zoneToLocalTS(startTime, timezone))
  ), [startTime]);
  const endMoment = useMemo(() => (
    isNullOrUndefined(endTime)
      ? null
      : moment(zoneToLocalTS(endTime, timezone))
  ), [endTime]);
  const {
    isAllDay,
  } = useMemo(() => getAllDayValues(startDT, endDT), [startDT, endDT]);

  const rangePickerProps = useMemo(() => (
    (isAllDay || hideTime)
      ? false
      : {
        format: 'hh:mm a',
        minuteStep: 15,
        use12Hours: true,
      }
  ), [isAllDay, hideTime]);

  const onDateChanged = useCallback((dateMoment) => {
    if (!moment.isMoment(dateMoment)) {
      onChange([]);
      return;
    }
    const newZoneTs = localToZoneTS(dateMoment.valueOf(), timezone);
    const newDT = DateTime.fromMillis(newZoneTs, { zone: timezone });
    const safeStartDT = startDT || newDT

    const updateObj = { day: newDT.day, month: newDT.month, year: newDT.year };
    const newStartDT = safeStartDT.set(updateObj);
    const newEndDT = endDT ? endDT.set(updateObj) : undefined;
    let newStart = newStartDT.toMillis();
    let newEnd = newEndDT ? newEndDT.toMillis() : undefined;
    if (hideTime) {
      newStart = newStartDT.startOf('day').toMillis();
      newEnd = newStartDT.endOf('day').toMillis();
    }
    onChange([newStart, newEnd]);
  }, [startDT, endDT, onChange, timezone, hideTime]);

  const onRangeChange = useCallback((newRepeat) => {
    const [m1, m2] = newRepeat || [];
    if (!moment.isMoment(m1) || !moment.isMoment(m2)) {
      onChange([]);
      return;
    }
    const startTS = localToZoneTS(m1.valueOf(), timezone);
    const endTS = localToZoneTS(m2.valueOf(), timezone);
    if (!isAllDay && !hideTime) {
      onChange([startTS, endTS]);
      return;
    }
    const newStart = DateTime.fromMillis(startTS, { zone: timezone }).startOf('day');
    const newEnd = DateTime.fromMillis(endTS, { zone: timezone }).endOf('day');
    onChange([newStart.toMillis(), newEnd.toMillis()]);
  }, [isAllDay, hideTime, onChange, timezone]);

  const onTimeChange = useCallback((newTimes) => {
    const [m1, m2] = newTimes || [];
    if (!moment.isMoment(m1) || !moment.isMoment(m2)) {
      onChange([]);
      return;
    }
    const startTs = localToZoneTS(m1.valueOf(), timezone);
    const endTs = localToZoneTS(m2.valueOf(), timezone);
    const newStart = DateTime.fromMillis(startTs, { zone: timezone });
    const newEnd = DateTime.fromMillis(endTs, { zone: timezone });
    if (startDT) {
      onChange([
        startDT.set({ hour: newStart.hour, minute: newStart.minute }).toMillis(),
        startDT.set({ hour: newEnd.hour, minute: newEnd.minute }).toMillis(),
      ]);
      return;
    }
    onChange([newStart.toMillis(), newEnd.toMillis()]);
  }, [startDT, endDT, onChange, timezone]);

  const toggleAllDay = useCallback(() => {
    const nowTs = DateTime.local().toMillis();
    const zoneNow = DateTime.fromMillis(localToZoneTS(nowTs, timezone), { zone: timezone });
    const safeStart = startDT || zoneNow;
    const safeEnd = endDT || zoneNow;
    if (isAllDay) {
      const newStart = safeStart
        .set({ hour: zoneNow.hour, minutes: zoneNow.minute, seconds: 0 })
        .toMillis();
      const newEnd = safeEnd
        .set({ hour: zoneNow.hour, minutes: zoneNow.minute, seconds: 0 })
        .toMillis();
      onChange([newStart, newEnd]);
    } else {
      const newStart = safeStart.startOf('day').toMillis();
      const newEnd = safeEnd.endOf('day').toMillis();
      onChange([newStart, newEnd]);
    }
  }, [startDT, endDT, isAllDay, onChange, timezone]);

  const rangeMode = !singleDay;

  const showTime = !isAllDay && !hideTime;

  return (
    <Row gutter={gutter} align="bottom" style={style}>
      <Col span={rangeMode ? 20 : 10}>
        {rangeMode
          ? getFormView({
            isDisplay,
            value: formatRange(
              [startTime,endTime],
              !showTime ? DateTime.DATE_MED : DateTime.DATETIME_MED,
            ),
            input: (
              <DatePicker.RangePicker
                style={{ width: '80%' }}
                format={getRangeFormat(!showTime)}
                showTime={rangePickerProps}
                onChange={onRangeChange}
                allowClear={allowClear}
                value={[startMoment, endMoment]}
              />
            ),
          })
          : getFormView({
            isDisplay,
            value: formatDay(startTime),
            input:  <DatePicker format='MMM Do YY' allowClear={allowClear} onChange={onDateChanged} value={startMoment}/>
          })
        }
        </Col>
        {!hideTime && !(singleDay && isAllDay) && (
          <Col span={rangeMode ? 0 : 10}>
          {getFormView({
            isDisplay,
              value: formatRange([startTime,endTime], DateTime.TIME_SIMPLE),
              input:  (
                <TimePicker.RangePicker
                  format='hh:mm a'
                  minuteStep={15}
                  use12Hours
                  allowClear={allowClear}
                  onChange={onTimeChange}
                  value={startMoment && endMoment ? [startMoment, endMoment] : null}
                />
              ),
            })}
        </Col>
        )}
        <Col span={4}>
          {!isDisplay && !hideCheckbox && !hideTime && (
            <Checkbox
              checked={isAllDay}
              onChange={toggleAllDay}
              style={{ marginBottom: 10 }}
            >
              All Day?
            </Checkbox>
        )}
      </Col>
    </Row>
  );
}

OnTraccrDatePicker.propTypes = {
  value: PropTypes.arrayOf(PropTypes.number),
  onChange: PropTypes.func.isRequired,
  gutter: PropTypes.number,
  isDisplay: PropTypes.bool,
  hideCheckbox: PropTypes.bool,
  allowClear: PropTypes.bool,
  style: PropTypes.shape({}),
  timezone: PropTypes.string,
};

OnTraccrDatePicker.defaultProps = {
  value: [],
  gutter: 20,
  isDisplay: false,
  hideCheckbox: false,
  allowClear: false,
  style: {},
  timezone: DateTime.local().zoneName,
};
