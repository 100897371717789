import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popover, Divider } from 'antd';
import {
  SmileOutlined,
  LogoutOutlined
} from '@ant-design/icons';

import DrowdownRow from './DropdownRow';

import ProfileCircle from '../profile/ProfileCircle';
import Profile from '../../profile/profile';
import Logout from '../../auth/logout';

import { userLogout } from '../../main/state/main.actions';
import { initialsFromName } from '../../helpers/users';

const Content = ({ onProfileClicked, onLogout }) => (
  <div>
    <DrowdownRow
      Icon={SmileOutlined}
      title='My Profile'
      onClick={onProfileClicked}
    />
    <Divider className='header-dropdown-divider'/>
    <DrowdownRow
      Icon={LogoutOutlined}
      title='Logout'
      onClick={onLogout}
    />
  </div>
);

export default () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const initials = useSelector((state) => {
    const {
      profile: {
        profile:{
          name = '',
        } = {},
      } = {},
    } = state;
    return initialsFromName(name);
  });

  const [showPopover,setShowPopover] = useState(false);
  const [showProfile,setShowProfile] = useState(false);
  const [showLogout,setShowLogout] = useState(false);

  const openProfile = useCallback(() => {
    setShowProfile(true);
    setShowPopover(false);
  },[]);
  const closeProfile = useCallback(() => setShowProfile(false),[]);
  const openLogout = useCallback(() => {
    setShowPopover(false);
    setShowLogout(true);
  },[]);
  const closeLogout = useCallback(() => setShowLogout(false),[]);
  
  const onLogout = useCallback(() => {
    setShowPopover(false);
    history.push('/login');
    return dispatch(userLogout());
  },[dispatch, history]);
  return (
    <>
    <Popover
      placement='bottomRight'
      trigger='click'
      visible={showPopover}
      content={
        <Content
          onProfileClicked={openProfile}
          onLogout={openLogout}
        />
      }
      onVisibleChange={setShowPopover}
    >
      <div> {/* Need to wrap ProfileCircle in div or Popover doesnt work*/}
        <ProfileCircle
          id='header-profile-icon'
          initials={initials}
          radius={13}
          fontSize={12.5}
        />
       </div>
    </Popover>
    <Profile
      visible={showProfile}
      onCloseClicked={closeProfile}
    />
    <Logout
      visible={showLogout}
      onCloseClicked={closeLogout}
      onLogout={onLogout}
    />
    </>
  );
}
