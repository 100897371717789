import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Notes from '../../notes/Notes';

import CustomerCommsRow from './CustomerCommsRow';

import {
  getClientComms,
  addClientComm,
  markClientCommsAsRead,
  getClientBroadcasts,
} from './state/customers.actions';

import Permissions from '../../auth/Permissions';
import Debouncer from '../../helpers/Debouncer';

import { getIdMap } from '../../helpers/helpers';

const debouncer = new Debouncer();
export default ({
  customerId,
  active,
  style = {},
}) => {
  const dispatch = useDispatch();
  const clientComms = useSelector(state => state.customers.clientComms);
  const portalAccounts = useSelector(state => state.clientPortal.accounts);
  const users = useSelector(state => state.users.users);
  const {
    communicators = [],
  } = useSelector(state => state.clientPortal.settings);
  const clientCommBroadcasts = useSelector(state => state.customers.clientCommBroadcasts);
  const company = useSelector(state => state.settings.company);
  const { userId: ownerId } = company ?? {};

  const accountIdMap = useMemo(() => getIdMap(portalAccounts),[portalAccounts]);
  const userIdMap = useMemo(() => getIdMap(users),[users]);

  const unreadIds = useMemo(() => {
    const {
      [customerId]: customerMessages = [],
    } = clientComms;
    return customerMessages
      .filter((note) => !note.userRead)
      .map((note) => note.id);
  },[customerId, clientComms]);

  const mergedComms = useMemo(() => {
    const {
      [customerId]: customerComms = [],
    } = clientComms;
    const mergedData = [...clientCommBroadcasts].concat(customerComms);
    mergedData.sort((a,b) => a.timestamp - b.timestamp);
    return { [customerId]: mergedData };
  },[clientCommBroadcasts, clientComms, customerId]);

  useEffect(() => {
    const updateRead = async () => {
      await debouncer.debounce(() => {
        if (active && customerId && unreadIds.length > 0) {
          dispatch(markClientCommsAsRead({ customerId, ids: unreadIds }));
        }
      }, 1000);
    }
    if (active && customerId) updateRead();
  },[dispatch,customerId, active, unreadIds]);

  useEffect(() => {
    if (active) dispatch(getClientBroadcasts());
  },[dispatch, active]);

  const canSend = useMemo(() => (
    Permissions.id === ownerId || communicators.includes(Permissions.id)
  ),[communicators, ownerId]);

  return (
    <Notes
      style={style}
      id={customerId}
      notes={mergedComms}
      addNote={addClientComm}
      getNotes={getClientComms}
      canSend={canSend}
      customerNoteRender={(note) => {
        const { userId, clientPortalAccountId } = note;
        const {
          [userId]: user = {},
        } = userIdMap;
        const {
          [clientPortalAccountId]: account = {},
        } = accountIdMap;
        return (
          <CustomerCommsRow
            {...note}
            key={note.id}
            isCurrentUser={note.userId === Permissions.id}
            unread={!note.userRead}
            user={userId
              ? user
              : {
                name: account.email,
              }}
          />
        );
      }}
    />
  )
}