import React from 'react';
import { Switch } from 'antd';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import {
  getGPSRows,
} from './timeTrackingConstants';

import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';

export default function GPSSettings({
  settings,
  settings:{
    enableGeofence,
    enableLocationStamps,
  } = {},
  saveCompanySettings,
}) {
  const { t } = useTranslation();
  const gpsRows = getGPSRows(t);
  return (
    <CompanySettingsCard
      title='GPS Location'
      leftHeaderView={
        <Switch
          style={{
            opacity:1
          }}
          checked={enableGeofence || enableLocationStamps}
          disabled
          defaultChecked={enableGeofence || enableLocationStamps}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          onChange={(checked) => console.log(checked)}
        />
      }
    >
      <CheckRows
        data={gpsRows}
        isChecked={(item) => settings[item.key]}
        onChange={(item) => {
            const updates = {
              [item.key]: !(settings[item.key])
            };
            // Automatically disable any child settings
            if (!updates[item.key] && item.childKeys) {
              item.childKeys.forEach((childKey) => {
                updates[childKey] = false;
              });
            }
            // Automatically enable any parent settings
            if (updates[item.key] && item.parentKeys) {
              item.parentKeys.forEach((parentKey) => {
                updates[parentKey] = true;
              })
            }
            return saveCompanySettings(updates)
          }
        }
      />
    </CompanySettingsCard>
  );
}