import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Row,
  Select,
  Form,
} from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';
import PropTypes from 'prop-types';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { getSubTaskTemplates, getSubtaskTemplateFormData, resetSubtaskFormData } from './state/subtasks.actions';
import SubtaskForm from './SubtaskForm';

function SubtaskTemplateImportDrawer({
  visible,
  onSubmit,
  onClose,
  isTemplate,
  isTriggeredTask,
  relativeOptions,
}) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();

  const dispatch = useDispatch();
  const {
    templates,
    formData: templateFormData,
  } = useSelector((state) => state.subtasks);

  const [selectedTemplate, setSelectedTemplate] = useState({});

  const onSubmitClicked = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      return false;
    }
    form.resetFields();
    setSelectedTemplate({});
    return values ? onSubmit({ ...values, formData }) : null;
  }, [form, formData]);

  const onTemplateSelected = useCallback(async (templateId) => {
    form.resetFields();
    const template = templates.find((t) => t.id === templateId) ?? {};
    const {
      dueDate: {
        amount = 0,
        time = 'days',
      } = {},
      repeatEndDate: {
        amount: repeatEndAmount = 0,
        time: repeatEndTime = 'days',
      } = {},
    } = template;
    const formattedTemplateDueDate = isTriggeredTask
      ? { amount, time, relativeTo: 'creation' }
      : DateTime.local().plus({ [time]: amount }).toMillis();
    const formattedTemplateEndDate = isTriggeredTask
      ? { amount: repeatEndAmount, time: repeatEndTime, relativeTo: 'creation' }
      : DateTime.local().plus({ [repeatEndTime]: repeatEndAmount }).toMillis();
    const formattedTemplate = {
      ...template,
      dueDate: formattedTemplateDueDate,
      repeatEndDate: formattedTemplateEndDate,
    };
    setSelectedTemplate(formattedTemplate);
    const formDueDate = isTriggeredTask
      ? formattedTemplate.dueDate
      : moment(formattedTemplate.dueDate);
    const formEndDate = isTriggeredTask
      ? formattedTemplate.repeatEndDate
      : moment(formattedTemplate.repeatEndDate);
    form.setFieldsValue({
      ...formattedTemplate,
      dueDate: formDueDate,
      repeatEndDate: formEndDate,
    });
  }, [templates, isTriggeredTask]);

  useEffect(() => {
    if (visible && templates.length === 0) {
      dispatch(getSubTaskTemplates());
    }
  }, [dispatch, visible, templates]);

  useEffect(() => {
    if (!visible) {
      setSelectedTemplate({});
      setFormData();
      form.resetFields();
      dispatch(resetSubtaskFormData());
    }
  }, [visible]);

  useEffect(() => {
    if (selectedTemplate?.id) {
      dispatch(getSubtaskTemplateFormData(selectedTemplate.id));
    }
  }, [selectedTemplate]);

  useEffect(() => {
    if (templateFormData?.data) {
      setFormData({
        data: templateFormData.data,
      });
    }
  }, [templateFormData]);

  const {
    id: selectedTemplateId,
    reminderEmails = [],
    reminders = [],
  } = useMemo(() => selectedTemplate, [selectedTemplate]);

  return (
    <Drawer
      title="Import from Task Templates"
      onClose={onClose}
      visible={visible}
      width={800}
    >
      <Select
        style={{ width: '100%' }}
        showSearch
        optionFilterProp="label"
        value={selectedTemplateId}
        onSelect={onTemplateSelected}
        placeholder="Select a template"
      >
        {
          templates.map((template) => (
            <Select.Option key={template.id} value={template.id} label={template.templateName}>
              <Row style={{ width: '100%' }}>
                <Row className="form-field-type-title">
                  {template.templateName}
                </Row>
                <Row className="form-field-type-description">
                  {template.templateDescription}
                </Row>
              </Row>
            </Select.Option>
          ))
        }
      </Select>
      {selectedTemplateId && (
        <SubtaskForm
          visible={visible}
          form={form}
          selectedTask={selectedTemplate}
          reminderEmails={reminderEmails}
          reminders={reminders}
          style={{
            position: 'absolute',
            top: 111,
            left: 24,
            right: 24,
            bottom: 51,
            overflowY: 'auto',
          }}
          initialFormData={templateFormData || {}}
          onFormDataChanged={setFormData}
          isTemplate={isTemplate}
          isTriggeredTask={isTriggeredTask}
          relativeOptions={relativeOptions}
        />
      )}
      <DrawerSubmitFooter
        onSubmit={onSubmitClicked}
        onClose={onClose}
        canSubmit={!!selectedTemplateId}
      />
    </Drawer>
  );
}

SubtaskTemplateImportDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isTriggeredTask: PropTypes.bool,
};

SubtaskTemplateImportDrawer.defaultProps = {
  visible: false,
  isTriggeredTask: false,
};

export default SubtaskTemplateImportDrawer;
