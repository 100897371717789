import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Select,
} from 'antd';
import PropTypes from 'prop-types';

import { getIdMap } from '../../helpers/helpers';
import useDivisionFormTemplates from '../../common/hooks/useDivisionFormTemplates';

export default function ExportFormsConfig({
  canChangeTemplate,
  divisionId,
  formTemplateId,
  onFormTemplateSelected,
}) {
  const projects = useSelector((state) => state.projects.projects);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const divTemplates = useDivisionFormTemplates(divisionId);

  return (
    <Row align="middle" gutter={10} style={{ marginTop: 10, marginBottom: 20 }}>
      <Col>
        <Select
          placeholder="Select Form"
          style={{ width: 350 }}
          onSelect={onFormTemplateSelected}
          value={formTemplateId}
          optionFilterProp="label"
          showSearch
          dropdownMatchSelectWidth={false}
          disabled={!canChangeTemplate}
        >
          {
            divTemplates.map((template) => {
              const {
                projectId: templateProjectId,
              } = template;
              let secondRow = null;
              if (templateProjectId && templateProjectId in projectIdMap) {
                const { [templateProjectId]: { name: projectName } = {} } = projectIdMap;
                secondRow = <div style={{ fontSize: 10, fontStyle: 'italic', lineHeight: '12px' }}>{projectName}</div>;
              }
              return (
                <Select.Option key={template.id} value={template.id} label={template.name}>
                  {template.name}
                  {secondRow}
                </Select.Option>
              );
            })
          }
        </Select>
      </Col>
    </Row>
  );
}

ExportFormsConfig.propTypes = {
  canChangeTemplate: PropTypes.bool.isRequired,
  divisionId: PropTypes.string,
  formTemplateId: PropTypes.string,
  onFormTemplateSelected: PropTypes.func.isRequired,
};

ExportFormsConfig.defaultProps = {
  divisionId: null,
  formTemplateId: null,
};
