import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col,
  Row,
  Spin,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';

// Import Components:
import FormDetailView from '../CompletedForms/FormDetailView';
import FormsTable from '../FormsTable';

// Import Actions:
import { getFormApprovals, getFormById, clearFormSelection } from '../state/forms.actions';

// Import Helpers:
import {
  getIdMap,
  includesTerm,
  removeDuplicateArrayValues,
  toTitleCase,
} from '../../helpers/helpers';
import { getFilters, parseFormDates, setIncludesTerm } from '../formHelpers';
import { getApproveFormColumns } from '../FormColumns';
import SheetUploadPrompt from '../../common/excel/SheetUploadPrompt';
import ApproveFormImportDrawer from './ApproveFormImportDrawer';
import FormSearchInput from '../FormSearchInput';

import useFormSearch from '../../common/hooks/useFormSearch';
import useToggle from '../../common/hooks/useToggle';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import { CaretDownOutlined, CaretUpOutlined } from '@ant-design/icons';
import { parseFormResponseToReadableFormat } from '../ResponderHelpers';

/** Approve Forms Section */
function ApproveForms() {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formApprovals = useSelector((state) => state.forms.approvals);
  const customers = useSelector((state) => state.customers.customers);
  const projects = useSelector((state) => state.projects.projects);
  const formStatuses = useSelector((state) => state.forms.statuses);
  const { settings = {} } = useSelector((state) => state.settings.company);
  const templates = useSelector((state) => state.forms.templates);
  const userMap = useSelector((state) => {
    const {
      users: {
        users = [],
      },
    } = state;
    return getIdMap(users);
  });

  const [showApprovalFormDetail, setShowApprovalFormDetail] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchStr, setSearchStr] = useState();
  const [uploadFile, setUploadFile] = useState(null);
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);


  const { isToggled: isExpanded, toggle: toggleExpanded } = useToggle(true);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const searchParams = useMemo(() => ({ searchTerm: searchStr }), [searchStr]);
  const {
    searchSet,
    searchLoading,
  } = useFormSearch(searchParams);

  useEffect(() => {
    dispatch(getFormApprovals());
  }, []);

  // Handlers:
  // -----------------------------------------------------
  const onApproveFormClick = useCallback(async (record) => {
    if (loading) return;
    setLoading(true);
    const result = await dispatch(getFormById(record.formId, { getRecentDraft: true }));
    if (result) {
      dispatch(getFormApprovals());
      setShowApprovalFormDetail(true);
    }
    setLoading(false);
  }, [dispatch, loading]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onFormDetailClose = useCallback(() => {
    setShowApprovalFormDetail(false);
    dispatch(clearFormSelection());
  }, [dispatch]);

  // Prepare data:
  // -----------------------------------------------------
  const approvalData = useMemo(() => Object.values(formApprovals).map(
    (approvalForm) => {
      const {
        users = [],
        userId,
        projects: formProjects = [],
        customers: formCustomers = [],
      } = approvalForm;
      const fullUsers = removeDuplicateArrayValues(users.concat([userId]));
      const userNameList = new Set();
      fullUsers.forEach((assignedUserId) => {
        const {
          [assignedUserId]: { name: userName } = {},
        } = userMap;
        if (userName) userNameList.add(userName);
      });

      const customerNames = [];
      formCustomers.forEach((customerId) => {
        const {
          [customerId]: {
            name: customerName,
          } = {},
        } = customers;

        customerNames.push(customerName);
      });

      const projectNames = [];
      formProjects.forEach((projectId) => {
        const {
          [projectId]: {
            name: projectName,
          } = {},
        } = projectIdMap;

        projectNames.push(projectName);
      });

      const {
        lastUpdatedDate,
        createdAtDate,
      } = parseFormDates(approvalForm);

      return {
        ...approvalForm,
        users: fullUsers,
        customer: customerNames.join(', '),
        project: projectNames.join(', '),
        userNameList,
        lastUpdatedDate,
        createdAtDate,
      };
    },
  )
    .filter(({
      formId,
      templateName,
      number,
      project,
      customer,
      status,
      userNameList,
      editable,
      editableApprovals,
    }) => (
      (
        (!searchStr || searchSet?.has(formId))
      || (templateName && includesTerm(templateName, searchStr))
      || (userNameList && setIncludesTerm(userNameList, searchStr))
      || (number && includesTerm(number.toString(), searchStr))
      || (project && includesTerm(project, searchStr))
      || (customer && includesTerm(customer, searchStr))
      || (status && includesTerm(status, searchStr))
      ) && (!editable || editableApprovals)
    ))
    .sort((a, b) => b.lastUpdated - a.lastUpdated), [
    formApprovals,
    searchStr,
    projectIdMap,
    customers,
  ]);

  // Prepare filters:
  // -----------------------------------------------------
  const approveUsersFilters = useMemo(() => getFilters({ data: approvalData, idMap: userMap, key: 'userId' }), [approvalData, userMap]);
  const approveProjectFilters = useMemo(() => getFilters({ data: approvalData, idMap: projectIdMap, key: 'projects' }), [approvalData, projectIdMap]);
  const approveCustomerFilters = useMemo(() => getFilters({ data: approvalData, idMap: customers, key: 'customers' }), [approvalData, customers]);
  const approveStatusFilters = useMemo(() => (
    Object.values(formStatuses)
      .map((formStatus) => ({ text: toTitleCase(formStatus.status), value: formStatus.id }))
  ), [formStatuses]);

  const relevantTemplates = useMemo(() => {
    const relevantTemplateMap = {};
    const relevantTemplateIds = new Set();
    approvalData.forEach(({
      templateId,
    }) => {
      if (!relevantTemplateIds.has(templateId)) {
        relevantTemplateIds.add(templateId);

        const relevantTemplate = templates[templateId];
        if (!relevantTemplate) return;

        let relevantSections = [];
        try {
          const parsedTemplateData = JSON.parse(relevantTemplate?.jsonFormData);
          relevantSections = parsedTemplateData?.sections ?? [];
        } catch (err) {
          // do nothing
        }

        const fieldMap = relevantSections?.reduce((acc, { fields = [] }) => {
          fields.forEach((field) => {
            acc[field.id] = field;
          });
          return acc;
        }, {});

        relevantTemplateMap[templateId] = {
          ...relevantTemplate,
          sections: relevantSections,
          columns: relevantTemplate?.displayFields
            ?.sort((a, b) => a.orderIndex - b.orderIndex)
            ?.map(({
              fieldId,
            }) => ({
              title: fieldMap[fieldId]?.configProps?.title,
              dataIndex: fieldId,
              key: fieldId,
              render: (value) => {
                if (value) {
                  return value;
                }
                return '-';
              },
            })),
        };
      }
    });

    return relevantTemplateMap;
  }, [approvalData, templates]);


  // Get columns:
  // -----------------------------------------------------
  const approvalColumns = useMemo(() => (
    getApproveFormColumns({
      usersFilters: approveUsersFilters,
      projectFilters: approveProjectFilters,
      customerFilters: approveCustomerFilters,
      statusFilters: approveStatusFilters,
      userMap,
      projectIdMap,
      settings,
      t,
      templates: relevantTemplates,
    })
  ), [
    approveUsersFilters,
    approveProjectFilters,
    approveCustomerFilters,
    approveStatusFilters,
    userMap,
    relevantTemplates,
  ]);

  const rowExpandable = useCallback((record) => {
    const relevantTemplate = relevantTemplates[record.templateId];
    return relevantTemplate?.displayFields?.length;
  }, [relevantTemplates]);

  const expandedRowRender = useCallback((record) => {
    const relevantTemplate = relevantTemplates[record.templateId];
    const formResponses = parseFormResponseToReadableFormat({
      templateSections: relevantTemplate?.sections ?? [],
      responseSections: record?.data?.sections ?? [],
      projectIdMap,
      settings,
    });

    return (
      <Table
        columns={relevantTemplate?.columns ?? []}
        dataSource={[formResponses]}
        pagination={false}
        size="small"
        bordered
      />
    );
  }, [relevantTemplates]);


  useEffect(() => {
    if (isExpanded) {
      setExpandedRowKeys(approvalData.map(({ id }) => id));
    } else {
      setExpandedRowKeys([]);
    }
  }, [approvalData, isExpanded]);

  const onExpand = useCallback((event, { id }) => {
    if (!event) {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== id));
      return;
    }

    setExpandedRowKeys([...expandedRowKeys, id]);
  }, [expandedRowKeys]);

  const expandable = useMemo(() => ({
    defaultExpandAllRows: true,
    rowExpandable,
    expandedRowRender,
    expandedRowKeys,
    onExpand,
  }), [approvalData, rowExpandable, expandedRowRender, expandedRowKeys, onExpand]);

  return (
    <div>
      <Row style={{ marginBottom: 14 }} align="middle" justify="space-between" gutter={20}>
        <Col>
          <FormSearchInput
            searchStr={searchStr}
            searchLoading={searchLoading}
            onSearch={onSearch}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title={isExpanded ? 'Collapse All' : 'Expand All'}
            onClick={toggleExpanded}
            icon={isExpanded ? <CaretUpOutlined /> : <CaretDownOutlined />}
            iconLeft
            style={{ marginRight: 5 }}
          />
          <SheetUploadPrompt
            onFileChange={setUploadFile}
            hideUploadMessage
            showUploadList={false}
          />
        </Col>
      </Row>
      <div>
        <FormsTable
          data={approvalData}
          columns={approvalColumns}
          onClickRow={onApproveFormClick}
          expandable={expandable}
        />
        <FormDetailView
          visible={showApprovalFormDetail}
          onClose={onFormDetailClose}
          userMap={userMap}
          startOnFields
        />
      </div>
      {loading && (
        <Row justify="center" align="middle" className="form-loading-container">
          <Spin size="large" />
        </Row>
      )}
      <ApproveFormImportDrawer
        onClose={() => setUploadFile(null)}
        uploadFile={uploadFile}
      />
    </div>
  );
}

export default ApproveForms;
