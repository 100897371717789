import React from 'react';

import { Row, Col } from 'antd';

import IntegrationUnconnect from './IntegrationUnconnect';

import HoverHelp from '../../common/HoverHelp';


export default ({
  title,
  integrationKey,
  onUnLink,
  connected = {},
}) => ({
  title:(
    <Row justify='center'>
      <Col>
        <Row align='middle' style={{ height: 22}}>
          <HoverHelp
            placement='right'
            content='Connected entries have already been linked to Ontraccr'
            iconProps={{ 
              style:{
                fontSize: 12,
              },
            }}
          />
        </Row>
      </Col>
    </Row>
  ),
  dataIndex:'import',
  key:'import',
  align:'center',
  fixed:'left',
  width:60,
  render:(_,record) => (
    record[integrationKey] && record[integrationKey] in connected ? 
    <IntegrationUnconnect
      title={title}
      name={record.name}
      onUnLink={() => onUnLink(record)}
    />
      : null
  ),
});

export const createConnectedArray = (arr,integrationKey) => {
  const newConnected = {};
  arr.forEach((item) => {
    if(item[integrationKey]) {
      newConnected[item[integrationKey]] = {...item};
    }
  });
  return newConnected;
}
