import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Form,
  Col,
} from 'antd';
import { DateTime } from 'luxon';

import OnTraccrTimePicker from '../../common/inputs/OnTraccrTimePicker';

const TIME_FORMAT = 'hh:mm a';
const HOURS_FORMAT = 'HH:mm';

function ManualEntryTimeHoursInput({
  date,
  timezone,
  hourBased,
  start,
  end,
  hours,
  required,
}) {
  const { settings = {} } = useSelector((state) => state.settings.company || {});
  const {
    enableHourBasedTracking = false,
    minuteIncrement = 1,
  } = settings;

  if (hourBased) {
    return (
      <Col span={12}>
        <Form.Item
          name={end.name}
          label={hours.label}
          rules={[{ required, message: `${hours.label} is required` }]}
          style={{ marginBottom: 5 }}
        >
          <OnTraccrTimePicker
            date={date}
            timezone={timezone}
            format={HOURS_FORMAT}
            isHourBased
            minuteIncrement={minuteIncrement}
          />
        </Form.Item>
      </Col>
    );
  }
  return (
    <>
      <Col span={enableHourBasedTracking ? 6 : 8}>
        <Form.Item
          name={start.name}
          label={start.label}
          rules={[{ required, message: `${start.label} is required` }]}
          style={{ marginBottom: 5 }}
        >
          <OnTraccrTimePicker date={date} timezone={timezone} format={TIME_FORMAT} />
        </Form.Item>
      </Col>
      <Col span={enableHourBasedTracking ? 6 : 8}>
        <Form.Item
          name={end.name}
          label={end.label}
          rules={[{ required, message: `${end.label} is required` }]}
          style={{ marginBottom: 5 }}
        >
          <OnTraccrTimePicker date={date} timezone={timezone} format={TIME_FORMAT} />
        </Form.Item>
      </Col>
    </>
  );
}

ManualEntryTimeHoursInput.propTypes = {
  date: PropTypes.string.isRequired,
  timezone: PropTypes.string,
  hourBased: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.number,
  ]),
  required: PropTypes.bool,
  start: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  end: PropTypes.shape({
    name: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
  }).isRequired,
  hours: PropTypes.shape({
    label: PropTypes.string.isRequired,
  }).isRequired,
};

ManualEntryTimeHoursInput.defaultProps = {
  hourBased: false,
  required: false,
  timezone: DateTime.local().zoneName,
};

export default ManualEntryTimeHoursInput;
