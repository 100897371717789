import React from 'react';
import { Drawer, Tabs } from 'antd';
import { FileOutlined, LaptopOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import FileUpload from '../../common/files/FileUpload';
import FilePicker from '../../common/files/FilePicker';

const { TabPane } = Tabs;

function TabTitle({ title, icon }) {
  return (
    <span>
      {icon}
      {title}
    </span>
  );
}

export default function LiveFeedFileUpload({
  shouldDownload,
  onUploadEnd,
  addFile,
  visible,
  placement = 'bottom',
  style,
  height = 'calc(100% - 100px)',
  customProps = {},
  isExternalForm = false,
}) {
  return (
    <Drawer
      placement={placement}
      visible={visible}
      height={height}
      onClose={onUploadEnd}
      push={false}
      style={{
        position: 'absolute',
        zIndex: 0,
        ...style,
      }}
      bodyStyle={{ padding: '24px 24px 0px 24px', height: '100%' }}
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...customProps}
    >
      <Tabs
        style={{ width: '100%', height: '100%', position: 'relative' }}
        defaultActiveKey="1"
        tabBarStyle={{ margin: 0 }}
      >
        <TabPane
          tab={
            <TabTitle icon={<LaptopOutlined />} title="From Your Computer" />
      }
          key="1"
        >
          <FileUpload
            addFile={addFile}
            style={{ paddingTop: 8, paddingBottom: 24 }}
          />
        </TabPane>

        <TabPane
          disabled={isExternalForm}
          tab={
            <TabTitle icon={<FileOutlined />} title="From Existing Files" />
       }
          key="2"
        >
          <FilePicker
            className="livefeed-file-picker"
            onSelect={addFile}
            shouldDownload={shouldDownload}
          />
        </TabPane>
      </Tabs>
    </Drawer>
  );
}

LiveFeedFileUpload.propTypes = {
  shouldDownload: PropTypes.bool,
  onUploadEnd: PropTypes.func.isRequired,
  addFile: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  placement: PropTypes.string,
  style: PropTypes.shape({}),
  height: PropTypes.string,
  customProps: PropTypes.shape({}),
  isExternalForm: PropTypes.bool,
};

LiveFeedFileUpload.defaultProps = {
  shouldDownload: false,
  placement: 'bottom',
  style: {},
  height: 'calc(100% - 100px)',
  customProps: {},
  isExternalForm: false,
};
