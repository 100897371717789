import React, { useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import DashboardComponent from './DashboardComponent';

import AnalyticsBody from '../analytics/AnalyticsBody';

import { getDashboardAnalytics } from './state/dashboard.actions';
import { getTemplateDetails } from '../forms/state/forms.actions';

export default function DashboardAnalyticsWidget({
  analyticsId,
  onDelete,
  projectId,
  type,
  savedReports,
  analyticsData,
  isEdit,
  cardId,
}) {
  const dispatch = useDispatch();
  const formTemplates = useSelector((state) => state.forms.templates);
  const fullConfig = useMemo(() => {
    if (!analyticsId) return {};
    const {
      [analyticsId]: ourReport,
    } = savedReports;
    const {
      [analyticsId]: ourData,
    } = analyticsData;
    if (!ourReport) return {};
    if (!ourData) return ourReport;
    return {
      ...ourReport,
      ...ourData,
    };
  }, [analyticsId, savedReports, analyticsData]);

  useEffect(() => {
    if (!fullConfig) return;
    const { formTemplateId } = fullConfig;
    if (!formTemplateId) return;
    if (!(formTemplateId in formTemplates) || !formTemplates[formTemplateId].formData) {
      // Get form data from the backend
      dispatch(getTemplateDetails(formTemplateId));
    }
  }, [dispatch, formTemplates, fullConfig]);

  return (
    <DashboardComponent
      title={fullConfig.title}
      onDelete={onDelete}
      isEdit={isEdit}
    >
      <div style={{ height: 'calc(100% - 20px)', width: '100%' }}>
        <AnalyticsBody
          key={analyticsId}
          analyticsConfig={fullConfig}
          getAction={getDashboardAnalytics(analyticsId, fullConfig)}
          projectId={projectId}
          type={type}
          cardId={cardId}
        />
      </div>
    </DashboardComponent>
  );
}

/* eslint-disable react/forbid-prop-types */
DashboardAnalyticsWidget.propTypes = {
  analyticsId: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  projectId: PropTypes.string,
  type: PropTypes.string,
  savedReports: PropTypes.object,
  analyticsData: PropTypes.object,
  isEdit: PropTypes.bool,
  cardId: PropTypes.string,
};

DashboardAnalyticsWidget.defaultProps = {
  projectId: null,
  type: undefined,
  savedReports: {},
  analyticsData: {},
  isEdit: false,
  cardId: undefined,
};
