import Analytics from '../../helpers/Analytics';
import {
  GET_ALL_TEAMS,
  CREATE_TEAM,
  UPDATE_TEAM,
  ARCHIVE_TEAM,
  DELETE_TEAM,
} from '../../state/actionTypes';
import TeamsService from './teams.service';
import Permissions from '../../auth/Permissions';

export const getTeams = () => async (dispatch) => {
  const { data: teams } = await TeamsService.getAll();
  dispatch({
    type: GET_ALL_TEAMS,
    payload: {
      teams,
    },
  });
  return true;
};

export const createTeam = (team) => async (dispatch) => {
  Analytics.track('Teams/Create');
  if (!Permissions.has('TEAMS_WRITE')) return false;
  const payload = {
    ...team,
    members: team?.workers ?? [],
  };
  delete payload.workers;
  const { data } = await TeamsService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_TEAM,
    payload: {
      team: {
        ...data,
        ...payload,
        active: 1,
      },
    },
  });
  return true;
};

export const updateTeam = (id, team) => async (dispatch) => {
  Analytics.track('Teams/Edit');
  if (!Permissions.has('TEAMS_WRITE')) return false;
  const { data } = await TeamsService.update(id, team);
  if (!data) return false;
  dispatch({
    type: UPDATE_TEAM,
    payload: {
      id,
      team,
    },
  });
  return true;
};

export const archiveTeam = (id, active) => async (dispatch) => {
  Analytics.track('Teams/Archive');
  if (!Permissions.has('TEAMS_WRITE')) return false;
  const { data } = await TeamsService.archive(id, active);
  if (!data) return false;
  dispatch({
    type: ARCHIVE_TEAM,
    payload: {
      id,
      active,
    },
  });
  return true;
};

export const deleteTeam = (id) => async (dispatch) => {
  Analytics.track('Teams/Delete');
  if (!Permissions.has('TEAMS_WRITE')) return false;
  const { data } = await TeamsService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_TEAM,
    payload: {
      id,
    },
  });
  return true;
};
