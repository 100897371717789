import React, {
  useCallback, useEffect, useState, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Table,
  message,
  Input,
  Row, Col,
  Spin,
} from 'antd';
import axios from 'axios';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import ScheduleOfValuesInput from '../../../projects/ProjectScheduleOfValues/ScheduleOfValuesInput';

import EclipseUserPayTypeAddDrawer from './EclipseUserPayTypeAddDrawer';

import sortByString, { includesTerm } from '../../../helpers/helpers';
import useToggle from '../../../common/hooks/useToggle';

const tryToUpdate = async (record, updateMap = {}) => {
  try {
    const { err } = await axios.put(`/eclipse/users/${record.userId}/pay_types`, { payType: updateMap[record.userId] });
    if (err) {
      message.error('Failed to update pay type');
      return false;
    }
    message.success('Pay type updated');
    return true;
  } catch (err) {
    message.error('Failed to update pay type');
    return false;
  }
};

function EclipseUserPayTypeDrawer({
  visible,
  onClose,
}) {
  const [userPayTypes, setUserPayTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState();
  const [updateMap, setUpdateMap] = useState({});
  const {
    isToggled: loading,
    toggle: toggleLoading,
  } = useToggle();

  const {
    isToggled: showAddDrawer,
    toggle: toggleAddDrawer,
  } = useToggle();

  const onSearchChange = useCallback((e) => {
    setSearchTerm(e?.target?.value);
  }, []);

  const onSaveNew = useCallback((newValues) => {
    toggleAddDrawer();
    const newPayTypes = userPayTypes.concat([newValues]);
    newPayTypes.sort(sortByString('user'));
    setUserPayTypes(newPayTypes);
  }, [userPayTypes]);

  const onClear = useCallback((record) => () => {
    const newUpdate = { ...updateMap };
    delete newUpdate[record.userId];
    setUpdateMap(newUpdate);
  }, [updateMap]);

  const onEdit = useCallback((record) => async () => {
    if (!updateMap[record.userId]) return;
    const passed = await tryToUpdate(record, updateMap);
    if (!passed) return;
    setUserPayTypes((prev) => (
      prev.map((item) => {
        if (item.userId !== record.userId) return item;
        return {
          ...item,
          payType: updateMap[record.userId],
        };
      })
    ));
    onClear(record)();
  }, [updateMap, onClear]);

  useEffect(() => {
    const load = async () => {
      toggleLoading();
      try {
        const { data } = await axios.get('/eclipse/users/pay_types');
        setUserPayTypes(data);
      } catch (err) {
        message.error(err);
      }
      toggleLoading();
    };
    if (visible) {
      load();
    } else {
      setUserPayTypes([]);
      setUpdateMap({});
    }
  }, [visible]);

  const tableData = useMemo(() => {
    if (!searchTerm) return userPayTypes;
    return userPayTypes.filter((item) => (
      includesTerm(item.user, searchTerm)
    ));
  }, [userPayTypes, searchTerm]);

  const columns = useMemo(() => [
    {
      dataIndex: 'user',
      title: 'Employee',
    },
    {
      dataIndex: 'employeeId',
      title: 'Employee ID',
    },
    {
      dataIndex: 'payType',
      title: 'Pay Type',
      width: 150,
      render: (_, record) => (
        <ScheduleOfValuesInput
          title="Change Pay Type"
          okText="Save"
          placement="left"
          onConfirm={onEdit(record)}
          onCancel={onClear(record)}
          onChange={(e) => {
            setUpdateMap({
              ...updateMap,
              [record.userId]: e?.target?.value,
            });
          }}
          value={updateMap[record.userId] ?? record.payType}
          showPopover={!!updateMap[record.userId]}
        />
      ),
    },
  ], [updateMap, onEdit, onClear]);

  return (
    <Drawer
      title="Eclipse User Pay Types"
      visible={visible}
      onClose={onClose}
      width={800}
      bodyStyle={{ padding: 0 }}
    >
      {
        loading
          ? (
            <Row style={{ width: '100%', height: '100%' }} align="middle" justify="center">
              <Spin />
            </Row>
          )
          : (
            <>
              <Row style={{ margin: 10 }} justify="space-between">
                <Col>
                  <Input.Search
                    type="search"
                    disabled={false}
                    style={{ width: '100%' }}
                    className="searchbar"
                    placeholder="Search"
                    allowClear
                    onChange={onSearchChange}
                    value={searchTerm}
                  />
                </Col>
                <Col>
                  <OnTraccrButton
                    title="Add"
                    icon={<PlusOutlined />}
                    onClick={toggleAddDrawer}
                  />
                </Col>
              </Row>
              <Table
                dataSource={tableData}
                columns={columns}
                size="small"
                pagination={false}
                rowClassName="list-view-row"
              />
            </>
          )
      }
      <EclipseUserPayTypeAddDrawer
        onClose={toggleAddDrawer}
        onSave={onSaveNew}
        visible={showAddDrawer}
        existing={userPayTypes}
      />
    </Drawer>
  );
}

EclipseUserPayTypeDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

EclipseUserPayTypeDrawer.defaultProps = {
  visible: false,
};

export default EclipseUserPayTypeDrawer;
