import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import PropTypes from 'prop-types';
import {
  Dropdown, Menu, Empty, Input, Spin, Row,
} from 'antd';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import Debouncer from '../../helpers/Debouncer';
import { getIdMap } from '../../helpers/helpers';

const debouncer = new Debouncer();

function DynamicSearchWithDropdown({
  onItemClick,
  Item,
  path,
}) {
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [searchStr, setSearchStr] = useState();
  const [loading, setLoading] = useState(false);

  const dataMap = useMemo(() => getIdMap(data), [data]);

  const onChange = useCallback(async (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  useEffect(() => {
    const search = async () => {
      setLoading(true);
      await debouncer.debounce(async () => {
        try {
          const { data: newData = [] } = await axios.get(path, {
            params: { searchTerm: searchStr },
          });
          setData(newData);
        } catch (err) {
          Sentry.withScope(() => {
            Sentry.captureException(err);
          });
        }
        setLoading(false);
      }, 250);
    };
    setVisible(!!searchStr);
    if (searchStr) {
      search();
    } else {
      setData([]);
    }
  }, [searchStr, path]);

  const onClick = useCallback(async ({ key }) => {
    const shouldClose = await onItemClick(dataMap[key]);
    if (shouldClose) {
      setVisible(false);
      setSearchStr();
    }
  }, [onItemClick, dataMap]);

  const menu = useMemo(() => (
    <Menu onClick={onClick}>
      {
        !loading && data.map((item) => (
          <Menu.Item key={item.id} style={{ borderBottom: '1px solid #c5c5c5' }}>
            <Item item={item} />
          </Menu.Item>
        ))
      }
      {
        data.length === 0 && !loading
        && (
        <Menu.Item disabled>
          {' '}
          <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
        </Menu.Item>
        )
      }
      {
        loading && <Row justify="center" align="middle" style={{ height: 100 }}><Spin /></Row>
      }
    </Menu>
  ), [data, loading, onItemClick]);

  return (
    <Dropdown
      visible={visible}
      trigger={[]}
      overlay={menu}
      overlayClassName="board-search-dropdown"
    >
      <Input.Search
        type="search"
        className="searchbar"
        placeholder="Search"
        allowClear
        onChange={onChange}
        value={searchStr}
      />
    </Dropdown>
  );
}

DynamicSearchWithDropdown.propTypes = {
  onItemClick: PropTypes.func.isRequired,
  Item: PropTypes.node.isRequired,
  path: PropTypes.string.isRequired,
};

export default DynamicSearchWithDropdown;
