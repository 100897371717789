import React from 'react';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';

export default ({
  confirm,
  newExtension,
  oldExtension
}) => {
  CustomConfirmModal({
    title: 'Change file extension?',
    content: (
      <p>
        Are you sure you want to change the extension from <b>{oldExtension}</b> to <b>{newExtension}</b>?
        <br/><br/>
        Changing a file extension may change how Ontraccr handles your file.
      </p>
    ),
    onOk:confirm,
    cancelText:`Keep ${oldExtension}`,
    okText: `Use ${newExtension}`,
  });
};