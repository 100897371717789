import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'antd';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import SettingsCardHeader from '../SettingsCardHeader';

import ClientPortalPreview from './ClientPortalPreview';
import ClientPortalBranding from './ClientPortalBranding';
import ClientPortalProfile from './ClientPortalProfile';
import ClientPortalBoards from './ClientPortalBoards';
import ClientPortalCommunication from './ClientPortalCommunication';

import {
  getClientPortalSettings,
} from '../../clientPortal/state/clientPortal.actions';

export default () => {
  const dispatch = useDispatch();
  const [previewOpen, setPreviewOpen] = useState(false);

  const showPreview = useCallback(() => setPreviewOpen(true),[]);
  const hidePreview = useCallback(() => setPreviewOpen(false),[]);

  useEffect(() => {
    dispatch(getClientPortalSettings());
  },[dispatch]);

  return (
    <>
      <Row id='divisions-container' style={{
        width: '100%',
        height: '100%',
        marginLeft: 180,
        marginTop: 30
      }}>
        <SettingsCardHeader title='Client Portal'>
          <OnTraccrButton
            title='Preview'
            type='primary'
            onClick={showPreview}
          />
        </SettingsCardHeader>
        <div id='client-portal-settings-container'>
          <ClientPortalBranding/>
          <ClientPortalProfile/>
          <ClientPortalBoards/>
          <ClientPortalCommunication/>
        </div>
      </Row>
      <ClientPortalPreview visible={previewOpen} onClose={hidePreview}/>
    </>
  );
}
