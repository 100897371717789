import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group' 

export default ({
  children
}) => (
  <CSSTransitionGroup
    transitionName="example"
    transitionEnterTimeout={500}
    transitionLeave={false}
  >
    {children}
  </CSSTransitionGroup>
);
