import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Select } from 'antd';
import { FormOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { updateData } from './workflowHelpers';
import ProjectApproverSelector from '../../common/inputs/ProjectApproverSelector';

import { NONE_PROJECT_TYPE } from '../../projects/projectFilters';
import { getQuickbooksClasses } from '../../settings/state/settings.actions';

const { Option } = Select;

const getProjectFields = (t) => [
  { key: 'name', text: `${t('Project')} Name`, type: 'text' },
  { key: 'number', text: `${t('Project')} Number`, type: 'text' },
  { key: 'address', text: 'Address', types: ['gpsLocation', 'text'] },
  { key: 'geofence', text: 'Geofence Distance (km)', types: ['text'] },
  { key: 'customerId', text: 'Customer', type: 'dropdown - Customers' },
  { key: 'info', text: 'Info', type: 'text' },
  { key: 'billingContact', text: 'Billing Contact Email', type: 'text' },
  { key: 'poNumber', text: 'PO Number', type: 'text' },
  { key: 'estimatedLabourHours', text: 'Estimated Labour Hours', type: 'text' },
  { key: 'estimatedLabourCost', text: 'Estimated Labour Cost', type: 'text' },
  { key: 'estimatedMaterialCost', text: 'Estimated Material Cost', type: 'text' },
  { key: 'estimatedOverheadCost', text: 'Estimated Overhead Cost', type: 'text' },
  { key: 'estimatedEquipmentCost', text: 'Estimated Equipment Cost', type: 'text' },
  { key: 'estimatedBurdenRate', text: 'Burden Rate', type: 'text' },
  { key: 'contractValue', text: 'Contract Value', type: 'text' },
];

const requiredFields = new Set(['name']);
const HOVER_TEXT = 'This step can create a profile based on the fields submitted.';
const DISABLED_TEXT = 'Add at least one Text Field to enable this step';

const getOptions = (arr, divisionId) => (
  arr
    ?.filter((item) => item.divisionId === divisionId)
    ?.map((item) => ({ label: item.name, value: item.id }))
    ?? []
);

export default function WorkflowCreateProfileNode({
  isDisplay,
  setElements,
  setDataMap,
  name,
  sections = [],
  divisionId,
} = {}) {
  return function _({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const {
      fieldMappings: initialFieldMappings = {},
      projectType: initialProjectType,
      firstApprover: initialFirstApprover,
      secondApprover: initialSecondApprover,
      fileTemplate: initialFileTemplate,
      costcodeTemplate: initialCostcodeTemplate,
      qboClassId: initialQBOClassId,
    } = data;

    const title = `Create Profile${disabled ? ' - DISABLED' : ''}`;

    const projectTypes = useSelector((state) => state.projects.projectTypes);
    const fileTemplates = useSelector((state) => state.projects.projectFileTemplates);
    const costCodeTemplates = useSelector((state) => state.costcodes.templates);
    const { connectedToQuickbooks = false } = useSelector((state) => state.settings.company);
    const qboClassMap = useSelector((state) => state.projects.quickbookClassMap);

    const [showDrawer, setShowDrawer] = useState(false);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [selectedProjectType, setSelectedProjectType] = useState(initialProjectType);
    const [selectedFirstApprover, setSelectedFirstApprover] = useState(initialFirstApprover);
    const [selectedSecondApprover, setSelectedSecondApprover] = useState(initialSecondApprover);
    const [selectedFileTemplate, setSelectedFileTemplate] = useState(initialFileTemplate);
    const [selectedCCTemplate, setSelectedCCTemplate] = useState(initialCostcodeTemplate);
    const [selectedQBOClass, setSelectedQBOClass] = useState(initialQBOClassId);

    const openDrawer = useCallback(() => setShowDrawer(true), []);
    const closeDrawer = useCallback(() => setShowDrawer(false), []);

    const onUpdateData = useCallback((key, setterFunc) => (newValue) => {
      if (!setDataMap || !id) return;
      setterFunc(newValue);
      setDataMap(updateData(id, { [key]: newValue }));
    }, [setDataMap, id]);

    const fileTemplateOptions = useMemo(() => (
      getOptions(fileTemplates, divisionId)
    ), [fileTemplates, divisionId]);

    const ccTemplateOptions = useMemo(() => (
      getOptions(costCodeTemplates, divisionId)
    ), [costCodeTemplates, divisionId]);

    const relevantQBOClasses = useMemo(() => (
      qboClassMap?.[divisionId] ?? []
    ), [qboClassMap, divisionId]);

    const qboClassOptions = useMemo(() => (
      relevantQBOClasses.map(({ Id, Name }) => ({
        value: Id, label: Name,
      }))
    ), [relevantQBOClasses]);

    useEffect(() => {
      if (!connectedToQuickbooks) return;
      dispatch(getQuickbooksClasses());
    }, [connectedToQuickbooks]);

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        type="createProfile"
        Icon={FormOutlined}
        draggable={draggable}
        isDisplay={isDisplay || disabled}
        onNodeUpdate={setElements}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : { width: 210 }}
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Project')}
              {' '}
              Type:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onUpdateData('projectType', setSelectedProjectType)}
                value={selectedProjectType}
                showSearch
                optionFilterProp="label"
              >
                <Option key={NONE_PROJECT_TYPE} value={null} label="None">
                  None
                </Option>
                {projectTypes.map((type) => (
                  <Option key={type.id} value={type.id} label={type.name}>
                    {type.name}
                  </Option>
                ))}
              </Select>
            </Row>
            <ProjectApproverSelector
              onChange={onUpdateData('firstApprover', setSelectedFirstApprover)}
              selectedApprover={selectedFirstApprover}
              style={{ margin: '10px 0px' }}
              isFirst
              showLabel
              isNotDisplay
            />
            <ProjectApproverSelector
              onChange={onUpdateData('secondApprover', setSelectedSecondApprover)}
              selectedApprover={selectedSecondApprover}
              style={{ margin: '10px 0px' }}
              showLabel
              isNotDisplay
            />
            <Row style={{ marginTop: 10 }}>
              Select File Template:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onUpdateData('fileTemplate', setSelectedFileTemplate)}
                value={selectedFileTemplate}
                options={fileTemplateOptions}
                showSearch
                optionFilterProp="label"
                placeholder="Select a file template"
                allowClear
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select Cost Code Template:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onUpdateData('costcodeTemplate', setSelectedCCTemplate)}
                value={selectedCCTemplate}
                options={ccTemplateOptions}
                showSearch
                optionFilterProp="label"
                placeholder="Select a cost code template"
                allowClear
              />
            </Row>
            {!!relevantQBOClasses && (
              <>
                <Row>
                  Select Quickbooks Class:
                </Row>
                <Row style={{ margin: '10px 0px' }}>
                  <Select
                    onChange={onUpdateData('qboClassId', setSelectedQBOClass)}
                    value={selectedQBOClass}
                    options={qboClassOptions}
                    showSearch
                    optionFilterProp="label"
                    placeholder="Select a Quickbooks Class"
                    allowClear
                  />
                </Row>
              </>
            )}
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={openDrawer}
              />
            </Row>
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={showDrawer}
              onClose={closeDrawer}
              onSubmit={closeDrawer}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onUpdateData('fieldMappings', setFieldMappings)}
              data={data}
              setDataMap={setDataMap}
              fields={getProjectFields(t)}
              requiredFields={requiredFields}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
}
