import PropTypes from 'prop-types';
import {
  React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Drawer, Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { createBoardTemplate, updateBoardTemplate } from './state/boards.actions';
import BoardTemplateForm from './BoardTemplateForm';

function BoardTemplateDrawer({
  visible,
  onClose,
  selectedTemplateId,
  onSubmit,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const templates = useSelector((state) => state.boards.boardTemplates);

  const drawerTitle = useMemo(() => (
    selectedTemplateId
      ? 'Edit Template'
      : 'Add Template'
  ));

  const selectedTemplate = useMemo(() => {
    if (!selectedTemplateId) return {};
    return templates.find((template) => template.id === selectedTemplateId) ?? {};
  }, [selectedTemplateId, templates]);

  const {
    fields: ourSections = [],
  } = selectedTemplate;

  const [sections, setSections] = useState(ourSections);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    form.setFieldsValue({
      ...selectedTemplate,
    });
  }, [form, selectedTemplate]);

  useEffect(() => {
    if (!visible && form) {
      form.resetFields();
      setSections([]);
      setLoading(false);
    }
  }, [visible, form]);

  const onSubmitClicked = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      //
    }
    if (!values) return;
    setLoading(true);

    const {
      name,
      description,
      cardTypeId,
      cardTitle,
      statuses,
    } = values;
    const payload = {
      name,
      description,
      statuses,
    };

    if (cardTypeId) {
      payload.cardTypeId = cardTypeId;
    } else {
      payload.card = {
        title: cardTitle,
        fields: sections,
      };
    }

    let res;
    if (selectedTemplateId) {
      // Edit
      res = await dispatch(updateBoardTemplate(selectedTemplateId, payload));
    } else {
      // Add
      res = await dispatch(createBoardTemplate(payload));
    }
    setLoading(false);
    onSubmit(res);
  }, [
    form,
    sections,
    selectedTemplateId,
    onSubmit,
  ]);

  return (
    <Drawer
      title={drawerTitle}
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <BoardTemplateForm
        visible={visible}
        form={form}
        selectedTemplate={selectedTemplate}
        sections={sections}
        onCardChanged={setSections}
      />
      <DrawerSubmitFooter
        onSubmit={onSubmitClicked}
        onClose={onClose}
        loading={loading}
      />
    </Drawer>
  );
}

BoardTemplateDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  selectedTemplateId: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
};

BoardTemplateDrawer.defaultProps = {
  visible: false,
  selectedTemplateId: null,
};

export default BoardTemplateDrawer;
