import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Drawer, Row, Col } from 'antd';
import ExternalSignatureSigningOrder from './ExternalSignature/ExternalSignatureSigningOrder';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import EmailSubject from './EmailSubject';
import useFormEmailOptions from '../../common/hooks/useFormEmailOptions';
import EmailRecipientSelector from './selectors/EmailRecipientSelector';
import HoverHelp from '../../common/HoverHelp';

export default function WorkflowExternalSignatureConfigureDrawer({
  id,
  visible,
  onClose,
  onSubmit,
  data,
  setDataMap,
  divisionId,
  sections = [],
}) {
  const {
    additionalOptions,
    subjectOptions,
  } = useFormEmailOptions(sections);

  const {
    additionalOptions: notificationOptions,
  } = useFormEmailOptions(sections, 'notification');

  const {
    errorEmails,
  } = data;

  const updateEmails = useCallback((newSelected = []) => {
    setDataMap((dataMap) => {
      const {
        [id]: oldData = {},
      } = dataMap;
      return {
        ...dataMap,
        [id]: {
          ...oldData,
          errorEmails: newSelected,
        },
      };
    });
  }, [setDataMap]);

  return (
    <Drawer
      title="Configure Envelope Options"
      width={750}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 60 }}
    >
      <Col>
        <EmailRecipientSelector
          sections={sections}
          initialSelected={errorEmails}
          divisionId={divisionId}
          onChange={updateEmails}
          additionalOptions={notificationOptions}
          text={
            (
              <>
                Select Error Notification Recipient(s):
                <HoverHelp
                  iconProps={{
                    style: { paddingTop: 4, paddingLeft: 5 },
                  }}
                  content={(
                    <div style={{ maxWidth: 300 }}>
                      Who will be notified if the envelope fails to create in Docusign.
                      <br />
                      The envelope may fail if
                      <ul>
                        <li>The PDF is larger than Docusign&apos;s limit of 32MB</li>
                        <li>The configured Signers or Subject are not accepted by Docusign</li>
                        <li>There is an internal issue connecting to Docusign</li>
                      </ul>
                    </div>
                  )}
                />
              </>
              )
            }
          showFormAuthor
          authorHelpText="The notification will be sent to the form's author"
          showCompanyOwner
          ownerHelpText="The notification will be sent to the company's owner"
        />
        <EmailSubject
          id={id}
          data={data}
          setDataMap={setDataMap}
          fields={subjectOptions}
          visible={visible}
        />
        <Row>
          <ExternalSignatureSigningOrder
            divisionId={divisionId}
            sections={sections}
            id={id}
            data={data}
            setDataMap={setDataMap}
            additionalOptions={additionalOptions}
          />
        </Row>
      </Col>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

WorkflowExternalSignatureConfigureDrawer.propTypes = {
  data: PropTypes.shape({
    errorEmails: PropTypes.array,
  }).isRequired,
  divisionId: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  sections: PropTypes.array,
  setDataMap: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

WorkflowExternalSignatureConfigureDrawer.defaultProps = {
  sections: [],
  visible: false,
};
