import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import FormFieldAddDrawer from './FormFieldAddDrawer';

import NuxPopover from '../../../nux/NuxPopover';

import {
  FORMS_ADD_FIELDS_STEP_4,
  FORMS_ADD_FIELDS_STEP_4_FIELD_ADD_TEXT,
} from '../../../nux/nux.constants';

import BorderlessButton from '../../../common/buttons/BorderlessButton';

export default function FormFieldAdd({
  onSave,
  field,
  onEdit,
  isAdd,
  sectionId,
  sections = [],
  isBoardCards,
  isExternalForm,
  divisions,
  templateId,
  projectId,
  name,
  showDrawer,
  setShowDrawer,
}) {
  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);

  const save = useCallback((data = {}) => {
    if (data.shouldClose) setShowDrawer(false);
    onSave(data);
  }, [onSave]);

  return (
    <div>
      <NuxPopover
        placement="bottom"
        visible={activeNuxAction === FORMS_ADD_FIELDS_STEP_4}
        text={FORMS_ADD_FIELDS_STEP_4_FIELD_ADD_TEXT}
      >
        <BorderlessButton
          title="Add Field"
          iconNode={<PlusOutlined />}
          onClick={() => setShowDrawer(true)}
          style={{ width: 100, fontSize: 12, backgroundColor: 'transparent' }}
        />
        <FormFieldAddDrawer
          isAdd={isAdd}
          sectionId={sectionId}
          field={field}
          visible={showDrawer}
          onClose={() => {
            setShowDrawer(false);
            onEdit(); 
          }}
          onSave={save}
          onEdit={onEdit}
          sections={sections}
          isBoardCards={isBoardCards}
          isExternalForm={isExternalForm}
          divisions={divisions}
          templateId={templateId}
          projectId={projectId}
          name={name}
        />
      </NuxPopover>
    </div>
  );
}

FormFieldAdd.propTypes = {
  onSave: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  isAdd: PropTypes.bool.isRequired,
  field: PropTypes.shape({}),
  sectionId: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  isBoardCards: PropTypes.bool,
  isExternalForm: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.shape({})),
  templateId: PropTypes.string,
  projectId: PropTypes.string,
  name: PropTypes.string,
  showDrawer: PropTypes.bool.isRequired,
  setShowDrawer: PropTypes.func.isRequired,
};

FormFieldAdd.defaultProps = {
  field: undefined,
  sectionId: null,
  sections: [],
  isBoardCards: false,
  isExternalForm: false,
  divisions: [],
  templateId: null,
  projectId: null,
  name: null,
};
