import PropTypes from 'prop-types'
import React from 'react';
import { Table } from 'antd';
import { weatherReportColumns } from './weathers.constants';

const renderStat = ({ value, units } = {}) => (
  (value || value === 0) ? `${value}${units}` : '-'
);

export default function WeatherReportTable({ raw, data = [], loading } = {}) {
  const columns = weatherReportColumns(renderStat);
  const {
    daily: {
      time = [],
    } = {},
    locationDescriptor,
  } = raw;
  const location = locationDescriptor ? ` - ${locationDescriptor}` : '';
  const date = time.length ? ` - ${time[time.length - 1]}` : '';

  return (
    <Table
      title={() => `Weather Report${date}${location}`}
      pagination={false}
      loading={loading}
      columns={columns}
      dataSource={data}
      bordered
    />
  );
}

WeatherReportTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  raw: PropTypes.shape({
    locationDescriptor: PropTypes.string,
    daily: PropTypes.shape({
      time: PropTypes.array,
    }),
  }),
};

WeatherReportTable.defaultProps = {
  data: [],
  loading: false,
  raw: {},
};
