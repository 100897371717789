import React from 'react';
import PropTypes from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';

function BoardListCardContainer({
  id,
  isShadow,
  orderIndex,
  children,
  isDragDisabled,
}) {
  if (isShadow) {
    return children();
  }
  return (
    <Draggable
      draggableId={id}
      type="CARDS"
      index={orderIndex}
      isDragDisabled={isDragDisabled}
    >
      {children}
    </Draggable>
  );
}

BoardListCardContainer.propTypes = {
  children: PropTypes.node.isRequired,
  id: PropTypes.string.isRequired,
  isShadow: PropTypes.bool.isRequired,
  orderIndex: PropTypes.number.isRequired,
  isDragDisabled: PropTypes.bool.isRequired,
};

export default BoardListCardContainer;
