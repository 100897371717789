import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Select,
  Table,
  Row,
  Col,
  message,
} from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import SimpleNameRow from '../../common/editable/SimpleNameRow';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import UnionAddLocalClassModal from './UnionAddLocalClassModal';

import {
  updateUnion,
  updateUnionLocal,
  deleteUnion,
} from '../../unions/state/unions.actions';
import sortByString, { currencyFormatter, getIdMap } from '../../helpers/helpers';
import BorderlessButton from '../../common/buttons/BorderlessButton';

export default function UnionCard({
  id,
  name,
  onDelete,
}) {
  const dispatch = useDispatch();

  const {
    unions = [],
    locals = [],
    classes = [],
  } = useSelector((state) => state.unions);

  const [selectedLocalId, setSelectedLocalId] = useState();
  const [modalType, setModalType] = useState();
  const [editData, setEditData] = useState();

  const unionLocals = useMemo(() => locals.filter((local) => local.unionId === id), [locals, id]);
  const unionNames = useMemo(() => getIdMap(unions, 'name'), [unions]);
  const localNames = useMemo(() => getIdMap(unionLocals, 'name'), [unionLocals]);

  const localIdMap = useMemo(() => getIdMap(unionLocals), [unionLocals]);
  const classLocalMap = useMemo(() => {
    const cmap = {};
    classes.forEach((unionClass) => {
      const { localId } = unionClass;
      if (!(localId in cmap)) cmap[localId] = [];
      cmap[localId].push(unionClass);
    });
    return cmap;
  }, [classes]);

  const {
    [selectedLocalId]: selectedClasses = [],
  } = classLocalMap;
  const selectedLocal = localIdMap[selectedLocalId];

  const openModal = useCallback((type) => () => setModalType(type), []);
  const closeModal = useCallback(() => {
    setModalType();
    setEditData();
  }, []);

  const deleteByType = useCallback((type, item) => {
    CustomConfirmModal({
      title: `Delete ${type} ${item.name}?`,
      okText: 'Delete',
      onOk: async () => {
        dispatch(deleteUnion(type, item.id));
      },
    });
  }, []);

  const onDeleteLocal = useCallback(() => {
    deleteByType('local', selectedLocal);
  }, [selectedLocal]);

  const onDeleteClass = useCallback((unionClass) => {
    deleteByType('class', unionClass);
  }, []);

  const onUnionNameChanged = useCallback((newName) => {
    if (newName in unionNames && unionNames[newName].id !== id) {
      return message.error(`A Union with name ${newName} already exists`);
    }
    dispatch(updateUnion(id, { name: newName }));
  }, [id, unionNames]);

  const onLocalChanged = useCallback((key) => (newValue) => {
    if (key === 'name' && newValue in localNames && localNames[newValue].id !== selectedLocalId) {
      return message.error(`A Local with name ${newValue} already exists`);
    }
    dispatch(updateUnionLocal(selectedLocalId, { [key]: newValue }));
  }, [selectedLocalId, localNames]);

  useEffect(() => {
    if (!selectedLocalId && unionLocals.length > 0) {
      setSelectedLocalId(unionLocals[0].id);
    } else if (selectedLocalId && unionLocals.length === 0) {
      setSelectedLocalId();
    }
  }, [unionLocals, selectedLocalId]);

  const columns = useMemo(() => (
    [{
      title: <span className="union-class-name-cell">Name</span>,
      dataIndex: 'name',
      render: (name) => <span className='union-class-name-cell'>{name}</span>,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      sorter: sortByString('name'),
      defaultSortOrder: 'ascend',
    }, {
      title: 'Description',
      dataIndex: 'description',
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    }, {
      title: 'Wage',
      dataIndex: 'wage',
      showSorterTooltip: false,
      render: (val) => (val ? currencyFormatter(val) : null),
    }, {
      title: '',
      key: 'edit',
      width: 50,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<EditOutlined style={{ marginLeft: 0 }} />}
          onClick={() => {
            setModalType('Class');
            setEditData(record);
          }}
        />
      ),
    }, {
      title: '',
      key: 'delete',
      width: 50,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ marginLeft: 0, color: 'red' }} />}
          onClick={() => onDeleteClass(record)}
        />
      ),
    }]
  ), [selectedLocalId, onDeleteClass]);

  return (
    <div className="divisions-card">
      <SettingsCardHeader title={name}>
        {onDelete && (
          <OnTraccrButton
            title="Delete"
            type="primary"
            onClick={onDelete}
          />
        )}
      </SettingsCardHeader>
      <CompanySettingsCard
        containerStyle={{
          position: 'absolute',
          top: 150,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <div style={{ height: '100%', borderRadius: 8, overflow: 'hidden' }}>
          <SimpleNameRow
            title="Union Name"
            type="union"
            name={name}
            onNameChanged={onUnionNameChanged}
          />
          <Row className="union-label-row" justify="space-between" align="middle">
            <Row align="middle" gutter={10}>
              <Col>
                Local
              </Col>
              <Col>
                <Select
                  options={unionLocals.map((local) => ({ label: local.name, value: local.id }))}
                  style={{ width: 225 }}
                  optionFilterProp="label"
                  showSearch
                  value={selectedLocalId}
                  onChange={setSelectedLocalId}
                />
              </Col>
              {selectedLocal && (
                <Col>
                  <BorderlessButton
                    style={{ width: 20 }}
                    iconNode={<DeleteOutlined style={{ marginLeft: 0, color: 'red' }} />}
                    onClick={onDeleteLocal}
                  />
                </Col>
              )}
            </Row>
            <Col>
              <OnTraccrButton
                title="Add Local"
                onClick={openModal('Local')}
              />
            </Col>
          </Row>
          <div className="union-select-border" id="union-local-border-top" />
          {selectedLocal && (
            <>
              <SimpleNameRow
                title="Local Name"
                type="local"
                name={selectedLocal.name}
                onNameChanged={onLocalChanged('name')}
                bordered={false}
              />
              <SimpleNameRow
                title="Local Description"
                type="union"
                name={selectedLocal.description}
                onNameChanged={onLocalChanged('description')}
                bordered={false}
              />
              <div className="union-select-border" />
              <Row className="union-label-row" justify="space-between" align="middle">
                <Col>
                  Classes
                </Col>
                <Col>
                  <OnTraccrButton
                    title="Add Class"
                    onClick={openModal('Class')}
                  />
                </Col>
              </Row>
              <div className="union-select-border" />
              <Table
                pagination={false}
                size="small"
                dataSource={selectedClasses}
                columns={columns}
                scroll={{
                  y: 'calc(100vh - 465px)',
                }}
              />
            </>
          )}
        </div>
      </CompanySettingsCard>
      <UnionAddLocalClassModal
        unionId={id}
        localId={selectedLocalId}
        type={modalType}
        visible={!!modalType}
        onClose={closeModal}
        editData={editData}
      />
    </div>
  );
}

UnionCard.propTypes = {
  onDelete: PropTypes.func,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

UnionCard.defaultProps = {
  onDelete: null,
};
