import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Row,
  Col,
  List,
  Descriptions,
  Typography,
  Divider,
} from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import axios from 'axios';

import FileVersion from './FileVersion';
import FileDetailEditButtons from './FileDetailEditButtons';
import FileExtensionChangeModal from './FileExtensionChangeModal';

import {
  parseSize,
  parseUpdated,
  getFileType,
  fileIconMap,
  downloadFile,
} from './fileHelpers';

import CroppedPhoto from '../common/photos/CroppedPhoto';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import FullPhoto from '../common/photos/FullPhoto';

import BorderlessButton from '../common/buttons/BorderlessButton';

import {
  describeItem,
  moveFiles,
} from './state/files.actions';

import {
  createNuxEntry
} from '../nux/state/nux.actions';
import {
  DOCUMENT_VERSION_TYPE
} from '../nux/nux.constants';

import { getIdMap, toTitleCase } from '../helpers/helpers';
import Analytics from '../helpers/Analytics';

const VersionItem = ({
  userIdMap,
  fileType,
  name,
  onClick,
}) => ({ userId, fullPath, lastUpdated, url, version, file, VersionId }) => (
  <FileVersion
    userId={userId}
    userMap={userIdMap}
    date={lastUpdated}
    version={version}
    file={{
      name,
      type:fileType,
      url,
      src:url,
      file,
    }}
    onClick={() => onClick({ VersionId, type:fileType, fullPath })}
    onDownload={() => downloadFile({ fileDetails: { fullPath, name, VersionId, }})}
  />
);

const MAX_TEXT_WIDTH = 180;
const MAX_IMAGE_DIM = 175;

export default ({
  hasWritePerms,
  selectedFile,
  onClose,
  onRename,
  onMoveFolder,
  width = 800,
}) => {
  const {
    name = '',
    fullPath = '',
    isLinkedFolder,
  } = selectedFile || {};

  const fileLocation = fullPath.replace(name,'');
  const fileType = selectedFile ? getFileType(selectedFile) : 'other';
  const FileIcon = fileIconMap[fileType];
  const isFolder = fileType === 'folder';

  const dispatch = useDispatch();
  const details = useSelector((state) => state.files.fileDetails);
  const users = useSelector((state) => state.users.users);
  const nux = useSelector(state => state.nux.nux);

  const [editing,setEditing] = useState(false);
  const [newFileName,setNewFileName] = useState();
  const [selectedVersion,setSelectedVersion] = useState();
  const [versionDetails,setVersionDetails] = useState({});
  const [loading,setLoading] = useState();

  const clearVersionDetails = useCallback(() => setVersionDetails({}),[]);

  const userIdMap = useMemo(() => getIdMap(users),[users]);
  const onTextChanged = useCallback((e) => {
    const {
      target:{
        value,
      } = {},
    } = e;
    setNewFileName(value);
  },[setNewFileName]);

  const onSave = useCallback(async () => {
    const { name, type } = selectedFile;
    if(!newFileName || newFileName === name) return setEditing(false);
    if(type === 'folder') {
      setLoading(true);
      if(await onMoveFolder(newFileName)) {
        const replaceRegex = new RegExp(`${name}$`);
        selectedFile.name = newFileName;
        selectedFile.fullPath = selectedFile.fullPath.replace(replaceRegex,newFileName);
        onRename({...selectedFile });
        setLoading(false);
        return setEditing(false);
      }
      return;
    }
    const location = fileLocation.substring(0,fileLocation.length - 1);
    let ourFileName = newFileName;
    const newLastIndex = newFileName.lastIndexOf('.');
    const oldLastIndex = name.lastIndexOf('.');
    const oldExtension = name.substring(oldLastIndex,name.length);
    if(newLastIndex < 0 && oldLastIndex > 0) {
      // Name has no file extension, reuse old one.
      ourFileName += oldExtension;
    }

    const sendRequest = async () => {
      const payload = {
        destination: fileLocation,
        source:location,
        items:[{
          name:ourFileName,
          type:fileType,
          fullPath: `${location}/${name}`
        }],
        copy:false,
      };
      setLoading(true);
      if(await dispatch(moveFiles(payload))) {
        selectedFile.name = ourFileName;
        selectedFile.fullPath = selectedFile.fullPath.replace(name,ourFileName);
        onRename({...selectedFile });
        setLoading(false);
        setEditing(false);
      };
    }

    const newExtension = newFileName.substring(newLastIndex,newFileName.length);
    if(newLastIndex > 0 && oldLastIndex > 0 && newExtension !== oldExtension) {
      FileExtensionChangeModal({
        newExtension,
        oldExtension,
        confirm:sendRequest,
      })
    } else {
      sendRequest();
    }

    
  },[newFileName,selectedFile, setEditing, dispatch, fileLocation, fileType, onRename, onMoveFolder]);

  useEffect(() => {
    if(dispatch && selectedFile) {
      Analytics.track('Files/More',{ fileType: selectedFile.type, size: selectedFile.size});
      const { type } = selectedFile;
      if(type !== 'folder') {
        dispatch(describeItem(selectedFile.fullPath));
      }
    }
  },[dispatch,selectedFile]);

  useEffect(() => {
    setEditing(false);
  },[selectedFile]);

  useEffect(() => {
    if(
      dispatch && selectedFile && selectedFile.type !== 'folder'
      && ! nux.has(DOCUMENT_VERSION_TYPE)
    ) {
      dispatch(createNuxEntry(DOCUMENT_VERSION_TYPE))
    }
  },[selectedFile,dispatch,nux])

  useEffect(() => {
    const getVersion = async () => {
      const { fullPath, VersionId, type } = selectedVersion;
      try {
        Analytics.track('Files/View/Version', { fileType: type });
        const { data: url } = await axios.get(`download/files/${fullPath}`,{ params: { VersionId, isView: true } });
        setVersionDetails({
          url,
          type,
          fullPath,
          VersionId,
        })
      } catch(err) {
        console.log(err);
      }

    }
    if(selectedVersion) {
      getVersion();
    }
  },[selectedVersion]);

  const {
    versions = [],
  } = details;

  return (
    <Drawer
      title={name}
      width={width}
      visible={selectedFile}
      maskClosable={false}
      onClose={onClose}
    >
      <Row gutter={20}>
        <Col span={6}> 
        {
          isFolder && 
            <Row justify='center' align='middle' style={{ height:'100%'}}>
                <FileIcon style={{ fontSize: 60 }}/>
              </Row>
            }
          {versions.length > 0 &&
            <Row justify='center' align='middle' style={{ height:'100%'}}>
              {fileType.includes('image') ? <CroppedPhoto
                key={versions[0].url}
                alt='Post'
                src={versions[0].url}
                maxWidth={MAX_IMAGE_DIM}
                maxHeight={MAX_IMAGE_DIM}
                onClick={() => setSelectedVersion({ ...versions[0], type:fileType })}
              /> : 
              <FileIcon
                style={{ fontSize: 60 }}
                onClick={() => setSelectedVersion({ ...versions[0], type:fileType })}
              />
              }
            </Row>
          }
        </Col>
        <Col span={18}>
          {selectedFile && 
          <div>

 
          <Descriptions
            title='General Info'
            layout='vertical'
            extra={
              <FileDetailEditButtons
                editing={editing}
                hasWritePerms={!isLinkedFolder && hasWritePerms}
                onCancel={() => {
                  setEditing(false);
                  setNewFileName(selectedFile.name);
                }}
                onSave={onSave}
                onEdit={() => setEditing(true)}
                loading={loading}
              />
            }
          >
            <Descriptions.Item 
              label='Name'
            >
              <Row style={{ minHeight: 32}} align='middle'>
              {editing
                ? <OnTraccrTextInput defaultValue={selectedFile.name} onChange={onTextChanged}/>
                : <Typography.Text ellipsis style={{ width: MAX_TEXT_WIDTH }}>
                  {selectedFile.name}
                </Typography.Text>
              }
              </Row>
            </Descriptions.Item>
            {!isFolder &&  <Descriptions.Item label='Current Version'>{versions.length}</Descriptions.Item>}
            <Descriptions.Item label='Type'>
              <Row gutter={10}>
                <Col>
                  <FileIcon/>
                </Col>
                <Col>
                  {fileType ==='pdf' ? fileType.toUpperCase() : toTitleCase(fileType)}
                </Col>
              </Row>
            </Descriptions.Item>
            <Descriptions.Item label='Location'>
              <Typography.Text ellipsis style={{ width: MAX_TEXT_WIDTH }}>
                {fileLocation}
              </Typography.Text>
            </Descriptions.Item>
            {!isFolder && <Descriptions.Item label='Size'>{parseSize(selectedFile.size)}</Descriptions.Item>}
            <Descriptions.Item label='Last Updated'>{parseUpdated(selectedFile.lastUpdated)}</Descriptions.Item>
          </Descriptions>
          </div>}
        </Col>
      </Row>
      {
        !isFolder && <div>
        <Descriptions title='Versions' style={{ marginTop: 20 }}/>
        <Divider style={{ margin:0 }}/>
        <List
          style={{ height:'calc(100% - 300px)', overflowY:'scroll' }}
          dataSource={versions}
          renderItem={VersionItem({
            userIdMap,
            fileType,
            name,
            onClick:setSelectedVersion,
          })}
        />
        <Divider style={{ margin:0 }}/>
       </div>
      }
      <FullPhoto
        url={versionDetails.url}
        type={versionDetails.type}
        onClose={clearVersionDetails}
        onDownload={() => {
          downloadFile({
            fullPath:versionDetails.fullPath,
            VersionId: versionDetails.VersionId,
            name,
          })
        }}
        useApryse
      />
      <div className='file-download'>

      <BorderlessButton
        style={{
          width:56,
          height:54,
          padding:18,
          margin:0,
        }}
        iconNode={<DownloadOutlined style={{ marginLeft: 0, fontSize: 16, }}/>}
        onClick={async () => downloadFile({ fileDetails: selectedFile })}
      />
      </div>
    </Drawer>
  );
}