import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row, Col, Typography, Card, Tooltip,
} from 'antd';
import {
  WarningOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import { DateTime } from 'luxon';

import ListCard from '../common/ListCard';
import Permissions from '../auth/Permissions';
import { getIdMap } from '../helpers/helpers';

const getCol = ({
  title,
  subtitle,
  dark,
  onClick,
}) => (
  <Col span={12} style={{ textAlign: 'center' }} onClick={onClick}>
    <Card hoverable={onClick} style={{ border: 'none' }} bodyStyle={{ padding: 0 }}>
      <p style={{ marginBottom: 0 }} className={`project-${dark ? 'dark' : 'light'}-title`}>
        {title}
      </p>
      <p style={{ marginBottom: 0 }} className={`project-${dark ? 'dark' : 'light'}-subtitle`}>
        {subtitle}
      </p>
    </Card>
  </Col>
);

function ProjectItemView(props = {}) {
  const {
    divisionId,
    contractExpirationTimestamp,
    contractReminderTimestamp,
    projectTypeId,
    address,
  } = props;
  const divisions = useSelector((state) => state.settings.divisions);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const divisionName = useMemo(() => {
    const {
      [divisionId]: { name } = {},
    } = divisions;
    return name;
  }, [divisionId, divisions]);
  const expireView = useMemo(() => {
    if (!contractExpirationTimestamp) return null;
    const now = DateTime.local().startOf('day');
    if (contractExpirationTimestamp < now) {
      return (
        <Tooltip
          title="Contract has expired"
          placement="topLeft"
        >
          <ExclamationCircleOutlined style={{
            color: 'red', position: 'absolute', top: 2, right: 3,
          }}
          />
        </Tooltip>
      );
    }
    if (contractReminderTimestamp && contractReminderTimestamp < now) {
      // Give warning
      return (
        <Tooltip
          title="Contract will expire soon"
          placement="topLeft"
        >
          <WarningOutlined style={{
            color: 'orange', position: 'absolute', top: 2, right: 3,
          }}
          />
        </Tooltip>
      );
    }
    return null;
  }, [contractReminderTimestamp, contractExpirationTimestamp]);

  const projectTypeMap = useMemo(() => getIdMap(projectTypes), [projectTypes]);
  const projectType = projectTypeMap[projectTypeId]?.name ?? '';

  return (
    <ListCard
      isActive={props.active}
      onEdit={Permissions.has('PROJECTS_WRITE') ? () => props.onEdit(props) : null}
      onArchive={Permissions.has('PROJECTS_WRITE') ? () => props.onArchive(props) : null}
      onDelete={Permissions.has('PROJECTS_WRITE') ? () => props.onDelete(props) : null}
      onClick={() => props.onClick(props)}
      style={props.style}
    >
      <Row justify="space-between" type="flex" align="top" style={{ marginBottom: 5, paddingTop: 10 }}>
        <Col
          style={{ maxWidth: '85%' }}
          onClick={() => props.onClick(props.idx)}
        >
          <Row style={{ height: 17, marginBottom: 3 }}>
            <Typography.Text
              strong
              ellipsis
              className="project-title"
            >
              {props.name}
            </Typography.Text>
          </Row>
          <Row>
            <Typography.Text
              ellipsis
              className="project-subtitle"
            >
              {props.number}
            </Typography.Text>
          </Row>
        </Col>
        <Col>
          <Row style={{ height: 17, marginBottom: 3 }}>
            <Typography.Text
              ellipsis
              className="project-subtitle"
            >
              {divisionName}
            </Typography.Text>
          </Row>
          <Row style={{ float: 'right' }}>
            <Typography.Text
              ellipsis
              className="project-subtitle"
            >
              {projectType}
            </Typography.Text>
          </Row>
        </Col>
      </Row>
      <Row type="flex" justify="start" style={{ marginBottom: 10 }} gutter={4}>
        <Typography.Text
          ellipsis
          className="project-subtitle"
        >
          {address}
        </Typography.Text>
      </Row>
      <Row
        type="flex"
        justify="space-around"
        gutter={4}
      >
        <Card
          hoverable={Permissions.match('TIME_TRACKING')}
          style={{ border: 'none', width: '100%' }}
          bodyStyle={{ padding: 0 }}
          onClick={Permissions.match('TIME_TRACKING') ? (e) => {
            e.stopPropagation();
            props.onUserStatClick(props.id);
          } : null}
        >
          <Row style={{ width: '100%' }}>
            {getCol({
              dark: true,
              title: 'Active Users',
              subtitle: props.activeUsers ? props.activeUsers.length : 0,
            })}
            {getCol({
              title: 'Inactive Users',
              subtitle: props.inactiveUsers ? props.inactiveUsers.length : 0,
            })}
          </Row>
        </Card>
      </Row>
      {expireView}
    </ListCard>
  );
}

ProjectItemView.propTypes = {
  onClick: PropTypes.func.isRequired,
  onUserStatClick: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onArchive: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  number: PropTypes.string.isRequired,
  contractExpirationTimestamp: PropTypes.number,
  contractReminderTimestamp: PropTypes.number,
  projectTypeId: PropTypes.string,
  address: PropTypes.string,
  activeUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  inactiveUsers: PropTypes.arrayOf(PropTypes.string).isRequired,
  idx: PropTypes.number.isRequired,
  active: PropTypes.bool.isRequired,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ProjectItemView.defaultProps = {
  contractExpirationTimestamp: null,
  contractReminderTimestamp: null,
  projectTypeId: null,
  address: null,
  style: {},
};

export default ProjectItemView;
