import React from 'react';
import { StandardFonts } from 'pdf-lib';
import { Form, Select } from 'antd';
import {
  AlignLeftOutlined,
  AlignCenterOutlined,
  AlignRightOutlined,
  VerticalAlignTopOutlined,
  VerticalAlignMiddleOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';

import OnTraccrNumberInput from '../../inputs/OnTraccrNumberInput';

import PDFDesignerColorPicker from './PDFDesignerColorPicker';
import PDFDesignerAlignmentPicker from './PDFDesignerAlignmentPicker';
import PDFDesignerFontScalingMode from './PDFDesignerFontScalingMode';

import { SUPPORTED_FONTS } from './PDFDesigner.constants';

const formStyle = { marginBottom: 5 };

export default ({
  onDrawOptionChanged,
  drawOptions: {
    fontFamily,
    fontSize,
    color,
    textAlign,
    verticalAlign,
    growToFitText, // Either grow the bounds to fit text or shrink text to fit bounds
  } = {},
  showFontScaleSelector
}) => (
  <>
    <Form.Item
      name='font'
      key='font'
      label='Font'
      style={formStyle}
    >
      <Select
        onSelect={(newFontFamily) => onDrawOptionChanged({ fontFamily: newFontFamily })}
        defaultValue={fontFamily}
      >
        {
          Object.keys(StandardFonts)
          .filter((font) => font in SUPPORTED_FONTS)
          .map((font) => (
            <Select.Option
              key={font}
              value={SUPPORTED_FONTS[font]}
            >
              <span style={{
                fontFamily: SUPPORTED_FONTS[font],
              }}>{StandardFonts[font]}</span>
            </Select.Option>
          ))
        }
      
      </Select>
    </Form.Item>
    <Form.Item
      name='fontSize'
      key='fontSize'
      label='Font Size'
      style={formStyle}
    >
      <OnTraccrNumberInput
        style={{ width: '100%' }}
        min={8}
        max={64}
        defaultValue={fontSize}
        onChange={(newFontSize) => onDrawOptionChanged({ fontSize: newFontSize })}
      />
    </Form.Item>
    {
      showFontScaleSelector &&
      <PDFDesignerFontScalingMode
        growToFitText={growToFitText}
        onChange={(newGrowToFit) => onDrawOptionChanged({ growToFitText: newGrowToFit })}
      />
    }
    <PDFDesignerColorPicker
      style={formStyle}
      title='Color'
      value={color}
      onColorChanged={(newColor) => onDrawOptionChanged({ color: newColor })}
    />
    <PDFDesignerAlignmentPicker
      title='Horizontal Alignment'
      value={textAlign}
      onAlignmentChanged={(newAlignment) => onDrawOptionChanged({ textAlign: newAlignment })}
      alignments={[
        { alignment: 'left', Icon: AlignLeftOutlined },
        { alignment: 'center', Icon: AlignCenterOutlined },
        { alignment: 'right', Icon: AlignRightOutlined },
      ]}
      style={formStyle}
    />
  </>
)