import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';
import geofence from '../../assets/images/NUX/geofence.png';
import locationStamps from '../../assets/images/NUX/locationStamps.png';

const getGeoDesc = (t) => `Geofences can be set when creating a ${t('Project').toLowerCase()} and will
only let users clock in to a ${t('Project').toLowerCase()} if they're at the ${t('Project').toLowerCase()}'s location, ensuring
workers are physically onsite. You can easily enable or disable geofences companywide
or even per ${t('Project').toLowerCase()}`;

const locationDesc = `Location Stamps are GPS snapshots collected
whenever a user clocks in or switches a task. This could be useful
if you want to verify the worker's location when reviewing
their time card`;

const warning = `Note: In order for GPS to work, your users
will need to allow Ontraccr to use GPS when first opening the app`;

export default function NuxGPS({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const geoDesc = getGeoDesc(t);

  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      warning={warning}
      style={{
        height: 'calc(100% - 74px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:'Geofencing',
        description:geoDesc,
        cols:[0,18,6],
        image:{
          src:geofence,
          style:{
            height:75,
            width:75,
          }
        },
      },{
        title:'Location Stamps',
        description:locationDesc,
        cols:[0,18,6],
        image:{
          src:locationStamps,
          style:{
            height:75,
            width:156,
          }
        },
      }]}
      tutorialLink={'TT3. Creating Project Geofences.mp4'}
    />
  );
};
