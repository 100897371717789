import colors from '../../constants/Colors';

export const getButtonStyle = (style = {}) => ({
  borderRadius: 20,
  width: 120,
  height: 40,
  borderWidth: 1.5,
  ...style,
});

export const titleStyle = {
  fontFamily: 'roboto-medium',
};

export const smallTimerStyle = {
  textAlign: 'center',
  color: colors.ONTRACCR_GRAY,
  marginBottom: 5,
  fontFamily: 'roboto-bold',
  fontSize: 13,
  minHeight: 20,
  width: '100%',
};
