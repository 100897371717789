import React from 'react';
import { useSelector } from 'react-redux';
import { CheckSquareOutlined } from '@ant-design/icons';
import { Popover, Row, Col, Statistic } from 'antd';

export default ({
  tasks = {},
  placement = 'leftTop'
}) => {
  const isDragging = useSelector((state) => state.boards.isDragging);
  const {
    'To Do': todo = 0,
    'In Progress': inProgress = 0,
    Done: done = 0,
  } = tasks;
  const numTasks = todo + inProgress + done;
  if(numTasks === 0) return null;
  return (
    <Popover
      placement={placement}
      trigger={isDragging ? [] : ['hover']}
      content={
        <div>
          <span style={{ fontWeight: 'bold', fontSize: 20, color: 'black' }}>Tasks</span>
          <div style={{ width: '100%', borderBottom: '1px solid lightgray', margin: '2px 5px 10px 0px'}}/>
          <Row gutter={10}>
            <Col>
              <Statistic title='To Do' value={todo}/>
            </Col>
            <Col>
              <Statistic title='In Progress' value={inProgress}/>
            </Col>
            <Col>
              <Statistic title='Done' value={done}/>
            </Col>
          </Row>
        </div>
      }
    >
      <Row align='middle' className='board-card-days-in-status-container'>
        <CheckSquareOutlined />
        <span className='board-card-days-in-status-text'>{numTasks}</span>
      </Row>
    </Popover>
  )
}