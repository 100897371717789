import React, { useState } from 'react';
import { Row, Col } from 'antd';

import ToSRows from '../auth/GetStarted/ToSRows';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DisplayText from '../common/text/DisplayText';

import timeTracking from '../assets/images/NUX/welcome-timetracking.png';
import realtime from '../assets/images/NUX/welcome-realtimeupdates.png';
import streamlinedPM from '../assets/images/NUX/welcome-streamlined-pm.png';
import advTracking from '../assets/images/NUX/welcome-advanced-tracking.png';
import clientExperience from '../assets/images/NUX/welcome-client-experience.png';
import equipmentTracking from '../assets/images/NUX/welcome-equipmentinventory-tracking.png';


const textStyle = {
  fontFamily:'roboto-regular',
  fontSize:16,
  paddingLeft: 20,
  paddingRight: 20,
};

const description = `We're thrilled to be a part of your team!
Here's what we bring to the table:`;

const WelcomeImage = ({ src, title }) => (
  <Col style={{ width: 175 }}>
    <img
      src={src}
      alt={title}
      style={{
        height:75,
        width:75
      }}
    />
    <DisplayText
      title={title}
      style={{
        marginTop:20,
        fontSize:16,
        fontFamily:'roboto-medium',
        lineHeight:'20px',
      }}
    />
  </Col>
)

export default ({
  onNext,
  acceptedTos: propAccepted,
}) => {
  const [acceptedToS,setAcceptedToS] = useState(false);
  const [acceptedPrivacy,setAcceptedPrivacy] = useState(false);

  return (
    <div style={{ width:'100%', height: 450 }}>
      <p style={textStyle}>{description}</p>
      <Row style={{ width: '100%', height: propAccepted ? 350 : 300}} justify='center' gutter={20} align='middle'>
        <Col>
          <Row>
            <WelcomeImage
              src={timeTracking}
              title={<div>Field Operations<br/>Management</div>}
            />
            <WelcomeImage
              src={realtime}
              title={<div>Project<br/>Management</div>}
            />
            <WelcomeImage
              src={equipmentTracking}
              title={<div>Equipment &<br/>Inventory Tracking</div>}
            />
          </Row>
          <Row>
          <WelcomeImage
              src={streamlinedPM}
              title={<div>Document<br/>Automations</div>}
            />
            <WelcomeImage
              src={advTracking}
              title={<div>Advanced<br/>Tracking</div>}
            />
            <WelcomeImage
              src={clientExperience}
              title={<div>Client<br/>Experience</div>}
            />
          </Row>
        </Col>

      </Row>

      {!propAccepted && 
        <ToSRows 
          style={{
            position:'absolute',
            bottom: 70,
            left:300,
            right:0,
          }}
          setAcceptedToS={setAcceptedToS}
          setAcceptedPrivacy={setAcceptedPrivacy}
        />
      }

      <Row 
        justify='center'
        gutter={20}
        style={{ 
          position:'absolute',
          bottom: 20,
          left:0,
          right:0,
        }}
      >
        <Col>
          <OnTraccrButton
            title='Next'
            onClick={onNext}
            roundStyle
            fat
            disabled={!propAccepted && (!acceptedToS || !acceptedPrivacy)}
          />
        </Col>
      </Row>
    </div>
  );
}