import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import IntegrationUserSync from '../IntegrationUserSync';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Employees from QuickBooks.
    <br />
    <br />
    1. Make sure the Employees in QuickBooks have their email set.
    <br />
    <br />
    2. We will automatically set their Ontraccr username
    and password when importing and send this information to their email.
    <br />
    <br />
    3. All users will be assigned the ‘Worker’
    role after importing, make sure you change each user’s role afterwards as needed.
    <br />
    <br />
    If you would rather see exactly which
    data will be imported from QuickBooks
    and fill in any missing fields, disable Auto Import to do this manually.
  </div>
);

const importHelpText = (
  <div style={{ width: 500 }}>
    Select from this dropdown to either
    {' '}
    <b>Import</b>
    {' '}
    new Employees from QuickBooks
    or
    {' '}
    <b>Link</b>
    {' '}
    QuickBooks Employees to existing Ontraccr Users
    <br />
    <br />
    {' '}
    <b>Import:</b>
    {' '}
    Employees must have an email to be imported into Ontraccr.
    <br />
    {' '}
    <b>Link:</b>
    {' '}
    Select an existing Ontraccr user to link with the QuickBooks Employee.
  </div>
);

function QuickBooksEmployeeSync({
  quickBooksUsers = [],
  onUsersChanged,
  importUsers,
  divisionFilter,
}) {
  const users = useSelector((state) => state.users.users);
  const positions = useSelector((state) => state.settings.positions);
  return (
    <IntegrationUserSync
      title="QuickBooks"
      integrationUsers={quickBooksUsers}
      integrationKey="intuitId"
      users={users}
      positions={positions}
      onUsersChanged={onUsersChanged}
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      importUsers={importUsers}
      divisionFilter={divisionFilter}
    />
  );
}

/* eslint-disable  react/forbid-prop-types */
QuickBooksEmployeeSync.propTypes = {
  quickBooksUsers: PropTypes.array,
  onUsersChanged: PropTypes.func.isRequired,
  importUsers: PropTypes.array,
  divisionFilter: PropTypes.instanceOf(Set),
};

QuickBooksEmployeeSync.defaultProps = {
  quickBooksUsers: [],
  importUsers: [],
  divisionFilter: null,
};

export default QuickBooksEmployeeSync;
