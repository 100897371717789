import React from 'react';
import {
  Row,
  Col,
  Checkbox,
  Select,
} from 'antd';

import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import HoverHelp from '../../../common/HoverHelp';

import FieldTriggerCheckbox from './FieldTriggerCheckbox';
import OptionalRow from './OptionalRow';

import ConditionalRenderingRow from './ConditionalRenderingRow';
import GPSLocationFormFieldPreview from './GPSLocationFormFieldPreview';

const configure = ({
  setConfigProps,
  configProps = {},
  isExternalForm,
  sections = [],
  templateId,
  divisions,
  projectId,
  name,
  isBoardCards,
  setFieldTriggerEditable,
  id,
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  bucketDropdownsWithGPSField = [],
  buckets = [],
} = {}) => {
  const setTitle = (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    const newConfig = {
      ...configProps,
      numAnswers: configProps?.numAnswers ?? 1,
      title: value,
    };
    setConfigProps(newConfig);
  };

  const onNumberChange = (num) => {
    const newConfig = {
      ...configProps,
      numAnswers: num,
    };
    setConfigProps(newConfig);
  };

  const onCheckChanged = (key) => (e) => {
    const { target: { checked } = {} } = e;
    setConfigProps({
      ...configProps,
      [key]: checked,
    });
  };

  const setFieldTrigger = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      fieldTrigger: checked,
    });
  };

  const setConditionalRenderingFormula = (formula) => {
    setConfigProps({
      ...configProps,
      conditionalRenderingFormula: formula,
    });
  };

  const setHasConditionalRendering = (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setConfigProps({
      ...configProps,
      hasConditionalRendering: checked,
    });
  };

  const setBucketField = (newValue) => setConfigProps({
    ...configProps,
    bucketField: newValue,
  });

  const {
    optional,
    openLimit,
    numAnswers = 1,
    hasConditionalRendering = false,
    conditionalRenderingFormula,
    bucketField,
  } = configProps;

  return (
    <div>
      <Row className="form-required-field">
        Title:
      </Row>
      <Row style={{ marginTop: 5, width: 350 }}>
        <OnTraccrTextInput
          textarea
          placeholder="Insert title here"
          onChange={setTitle}
          value={configProps.title}
        />
      </Row>
      <OptionalRow onChange={onCheckChanged('optional')} optional={optional} />
      <ConditionalRenderingRow
        onChange={setHasConditionalRendering}
        onFormulaChange={setConditionalRenderingFormula}
        hasConditionalRendering={hasConditionalRendering}
        conditionalRenderingFormula={conditionalRenderingFormula}
        id={id}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      { !!bucketDropdownsWithGPSField?.length && (
        <>
          <Row style={{ marginTop: 15 }} gutter={10}>
            <Col>
                Select field to map into GPS location:
              </Col>
              <Col>
                <HoverHelp placement="left" content="Select a bucket dropdown to map into this field. Only buckets with a GPS location field can be mapped." />
              </Col>
          </Row>
          <Row>
            <Select
              style={{ width: 350 }}
              onChange={setBucketField}
              allowClear
              options={bucketDropdownsWithGPSField}
              value={bucketField}
            />
          </Row>
        </>
      )}
      <Row style={{ marginTop: 15 }} gutter={10}>
        <Col>
          Number of answers:
        </Col>
        <Col>
          <HoverHelp placement="top" content="Configure how many options the user can select" />
        </Col>
      </Row>
      <Row style={{ marginTop: 5 }} align="middle" gutter={20}>
        <Col style={{ height: 32 }}>
          <OnTraccrNumberInput
            style={{ width: 100 }}
            defaultValue={1}
            value={numAnswers}
            min={1}
            onChange={onNumberChange}
            disabled={openLimit}
          />
        </Col>
        <Col style={{ height: 32 }}>
          <Row align="middle" style={{ height: '100%' }}>
            <Col>
              <Checkbox
                onChange={onCheckChanged('openLimit')}
                checked={openLimit}
              >
                Leave open?
              </Checkbox>
            </Col>
            <Col>
              <HoverHelp placement="top" content="Check this box if you want to remove restrictions on the number of answers" />
            </Col>
          </Row>
        </Col>
      </Row>
      {!isBoardCards && !isExternalForm && (
        <FieldTriggerCheckbox
          onChange={setFieldTrigger}
          onEditableChange={setFieldTriggerEditable}
          sections={sections}
          projectId={projectId}
          templateId={templateId}
          divisions={divisions}
          isExternalForm={isExternalForm}
          configProps={configProps}
          setConfigProps={setConfigProps}
          name={name}
        />
      )}
    </div>
  );
};

export default {
  configure,
  preview: (props = {}) => (<GPSLocationFormFieldPreview {...props} />),
  title: 'GPS Location',
  description: 'User can select one or more GPS locations on a map',
};
