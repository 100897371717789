import React from 'react';

import { 
  LIVE_FEED_REALTIME_TYPE,
  LIVE_FEED_MESSAGE_TYPE,
} from '../nux.constants';

import NuxTabPane from '../NuxTabPane';

import NuxRealTime from './NuxRealTime';
import NuxLiveFeedMessage from './NuxLiveFeedMessage';

import realTimeUpdates from '../../assets/images/NUX/realTimeUpdates.png';
import sendFiles from '../../assets/images/NUX/sendFiles.png';

import Analytics from '../../helpers/Analytics';

const realtimeTitle = (<div>Real-Time<br/>Updates</div>);
const realTimeModalTitle = 'Real-Time Updates';
const sendingTitle = (<div>Sending Files<br/>& Messages</div>);

export default ({
  setDetailView,
  setTitle,
  setDescription,
  resetToDefault,
  history,
  onCloseClicked,
  nux = new Set(),
  createNuxEntry,
}) => (
  <NuxTabPane
    setDetailView={setDetailView}
    setTitle={setTitle}
    setDescription={setDescription}
    rows={1}
    buttons={[
      {
        title: realtimeTitle,
        modalTitle: realTimeModalTitle,
        description:'The Live Feed connects the whole team together, making it easy to share updates between the field and the office, share photos & files, and send messages. Updates include the following:',
        complete:nux.has(LIVE_FEED_REALTIME_TYPE),
        src:realTimeUpdates,
        onClick:() => {
          Analytics.track('NUX/TryIt', { NuxPage: realTimeModalTitle });
          if(!nux.has(LIVE_FEED_REALTIME_TYPE)) {
            createNuxEntry(LIVE_FEED_REALTIME_TYPE);
          }
        },
        detailView:(
          <NuxRealTime
            onBack={resetToDefault}
          />
         ),
      },
      {
        title: sendingTitle,
        modalTitle: 'Messaging',
        description:'By tagging a user in a post, users can message each other privately and send files.',
        complete:nux.has(LIVE_FEED_MESSAGE_TYPE),
        src:sendFiles,
        detailView:(
          <NuxLiveFeedMessage
            onBack={resetToDefault}
            onTry={() => {
              Analytics.track('NUX/TryIt', { NuxPage: 'Messaging' });
              history.push('/dashboard');
              onCloseClicked();
            }}
          />
         ),
      }
    ]}
  />
);
