import React from 'react';
import { WarningOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

export default function GanttScheduleAddEditWarning({
  tasks,
  warningTitle,
  property,
  itemMap,
}) {
  return (
    tasks.length ? (
      <div className="form-warning">
        <p style={{ marginBottom: 0 }}>
          <strong>
            <WarningOutlined />
            {' '}
            {warningTitle}
          </strong>
        </p>
        <ul>
          {tasks.map((task) => (
            <li key={task.id}>
              <strong>{task.name}</strong>
              <br />
              <span>
                {DateTime.fromSeconds(
                  task.overlapStart || task.startDate,
                ).toUTC().toLocaleString()}
                {' - '}
                {DateTime.fromSeconds(
                  task.overlapEnd || task.endDate,
                ).toUTC().toLocaleString()}
              </span>
              <br />
              { property && (
                <span>
                  {task[property].map((item) => itemMap[item].name).join(', ')}
                </span>
              )}
            </li>
          ))}
        </ul>
      </div>
    ) : null
  );
}

GanttScheduleAddEditWarning.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    startDate: PropTypes.number,
    endDate: PropTypes.number,
    overlapStart: PropTypes.number,
    overlapEnd: PropTypes.number,
    overlapUsers: PropTypes.arrayOf(PropTypes.string),
  })),
  itemMap: PropTypes.objectOf(PropTypes.shape({
    name: PropTypes.string,
  })),
  property: PropTypes.string,
  warningTitle: PropTypes.string.isRequired,
};

GanttScheduleAddEditWarning.defaultProps = {
  tasks: [],
  itemMap: {},
  property: '',
};
