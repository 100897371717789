import React from 'react';
import { Row, Col } from 'antd';

import LoginForm from './LoginForm';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import colors from '../../constants/Colors';

export default ({
  style = {},
  isReset,
  onSubmit,
  onResetButtonClicked,
  image,
  primaryColor = colors.MAIN_BACKGROUND,
  accentColor,
  children,
  hideForgotPassword = false
}) => (
  <div className='login-container' style={{ backgroundColor: primaryColor, ...style }}>
    <Row style={{ height: '100vh' }} type='flex' justify='center' align='middle'>
      <Col>
        <Row style={{ paddingBottom: 115 }}>
          <Col className='login-card'>
              <Row type='flex' justify='center'>
                {image}
              </Row>
              {children}
              <Row type='flex' justify='center'>
                <LoginForm
                  isReset={isReset}
                  handleSubmit={onSubmit}
                  accentColor={accentColor}
                />
              </Row>
              {!hideForgotPassword && <Row type='flex' justify='center'>
                <BorderlessButton
                  style={{
                    fontSize:12,
                    fontFamily:'roboto-bold',
                    margin:0,
                  }}
                  title={isReset ? 'Back to Login' : 'Forgot your password?'}
                  onClick={onResetButtonClicked}
                />
              </Row>}
          </Col>
        </Row>
      </Col>
    </Row>
  </div>
);