import React, { useCallback } from 'react';

import CompanyEditRow from '../../settings/CompanyEditRow';

import Editable from './Editable';
import OnTraccrTextInput from '../inputs/OnTraccrTextInput';


export default ({
  title,
  type,
  name,
  onNameChanged,
  bordered = true,
  placeholder = 'Enter Name',
  helpText,
  inputStyle = {},
}) => {

  /*
    onBlur needs a timeout due to a race condition.
    If user clicks checkmark, onBlur will fire, as will onClick for
    the check. These will race, causing the input to not be saved
    if onBlur executes first. We delay by 100ms to allow
    onClick check to execute first.
  */
  const onBlur = useCallback((setFocus) => (
    () => setTimeout(() => setFocus(false),100)
  ),[]);

  const onMouseLeave = useCallback((setHover) => () => setHover(false),[]);
  const onClick = useCallback((setFocus) => () => setFocus(true),[]);

  const onTextChange = useCallback((setReturnValue) => (e) => {
    const {
      target:{
        value,
      },
    } = e;
    if(value.length === 0) return;
    setReturnValue({ name: type, value, })
  },[]);

  const onPressEnter = useCallback(({ setFocus, setReturnValue }) => (e) => {
    const {
      target:{
        value,
      },
    } = e;
    setFocus(false);
    if(value.length === 0) return;
    setReturnValue({ name: type, value, });
    onNameChanged(value);
  },[onNameChanged]);

  const onChange = useCallback((type,value) => {
    if(value.length === 0) return;
    onNameChanged(value)
  },[onNameChanged]);

  return (
    <div className={bordered ? 'division-name-row' : null} style={{ paddingLeft:  10 }}>
      <CompanyEditRow title={title} divider={false} helpText={helpText}>
        <Editable
          key={`${type}-${name}`}
          name={type}
          defaultValue={name}
          style={{ marginBottom: 0 }}
          onChange={onChange}
          editable
          inputNode={({
            setReturnValue,
            setFocus,
            setHover,
            defaultValue,
          }) => 
            <OnTraccrTextInput
              style={{ textAlign:'right', width:300, ...inputStyle }}
              defaultValue={defaultValue}
              placeholder={placeholder}
              onBlur={onBlur(setFocus)} 
              onMouseLeave={onMouseLeave(setHover)}
              onChange={onTextChange(setReturnValue)}
              onClick={onClick(setFocus)}
              onPressEnter={onPressEnter({ setFocus, setReturnValue })}
            />
          }
        />
      </CompanyEditRow>
    </div>
  )
}