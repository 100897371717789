import {
  SET_BREADCRUMB,
} from '../../../state/actionTypes';

export default function usersActions(state = [], action) {
  switch (action.type) {
    case SET_BREADCRUMB:
      return action.payload;
    default:
      return state;
  }
}
