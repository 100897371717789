import React, { useCallback, useState } from 'react';

import { Row } from 'antd';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import WorkflowHandle from './WorkflowHandle';
import WorkflowEmailConfigureDrawer from './WorkflowEmailConfigureDrawer';
import { MailOutlined, SettingOutlined } from '@ant-design/icons';
import WorkflowActionNode from './WorkflowActionNode';

const HOVER_TEXT = (
  <div>
    Emails added to this step will receive an email notification
    about the state of the workflow.
    <br/>
    <br/>
    We will send an invitation from <b>forms@ontraccr.com</b> to the
    email address.<br/><br/> The recipient must accept the invitation before Ontraccr
    will send emails to them.
</div>
);

export default ({ setElements, setDataMap, isDisplay, divisionId, sections = [], isExternalForm, } = {}) => ({
  id,
  draggable,
  data,
}) => {

  const [showDrawer,setShowDrawer] = useState(false);

  const openDrawer = useCallback(() => setShowDrawer(true),[]);
  const closeDrawer = useCallback(() => setShowDrawer(false),[]);

  return (
    <WorkflowActionNode
      title={'Send an Email'}
      Icon={MailOutlined}
      type={'email'}
      id={id}
      draggable={draggable}
      onNodeUpdate={setElements}
      isDisplay={isDisplay}
      hover={HOVER_TEXT}
    >
      {!draggable && <div>
        <Row style={{ margin: '20px 0px' }}>
          <BorderlessButton
            title='Configure'
            style={{ margin: 5 }}
            iconNode={<SettingOutlined/>}
            onClick={openDrawer}
          />
        </Row>

        <WorkflowHandle type='target' position='top' disabled={isDisplay}/>
        <WorkflowEmailConfigureDrawer
          id={id}
          visible={showDrawer}
          onClose={closeDrawer}
          onSubmit={closeDrawer}
          divisionId={divisionId}
          sections={sections}
          data={data}
          setDataMap={setDataMap}
          setElements={setElements}
          isExternalForm={isExternalForm}
        />
      </div>}
    </WorkflowActionNode>
  );
}
