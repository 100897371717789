import React, {
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Tabs,
  Checkbox,
  Row,
  Col,
  message,
  TreeSelect,
} from 'antd';
import PropTypes from 'prop-types';
import { SettingOutlined } from '@ant-design/icons';
import * as Sentry from '@sentry/react';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import FormTextInput from '../common/inputs/FormTextInput';
import HoverHelp from '../common/HoverHelp';

import BoardDeleteButton from './BoardDeleteButton';
import BoardPermissions from './BoardPermissions/BoardPermissions';
import BoardPinInput from './BoardPinInput';
import BoardWorkflows from './BoardWorkflows';
import BoardFormMappings from './BoardFormMappings';

import {
  deleteBoard,
  updateBoard,
} from './state/boards.actions';
import { getEmails } from '../emails/state/email.actions';

import BoardStatusConfigure from './BoardStatusConfigure';

import { pinValidator } from '../helpers/validators';

import Permissions from '../auth/Permissions';
import { getCustomColumnTreeData } from './BoardTimelineView/BoardTimeline.helpers';
import { defaultColumnTreeData } from './BoardTimelineView/BoardTimeline.constants';

const { TabPane } = Tabs;

const DETAILS_KEY = 'details';

export default function BoardSettings({
  title,
  boardId,
  isPublic,
  divisions,
  creatorId,
  hasPin,
  cardTypeId,
  disableCrossColumnSorting,
  useCardNumberAsCardTitle,
  showCardNumber,
  showListIndex,
  shouldSendStatusChangeEmail,
  shouldSendCardUpdateEmail,
  shouldSendAssignedUsersChangeEmail,
  timelineDisplayFields,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const {
    users = [],
    statuses,
    workflows = [],
    id: selectedBoardId,
  } = useSelector((state) => state.boards.selectedBoard || {});
  const company = useSelector((state) => state.settings.company);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [activeTab, setActiveTab] = useState(DETAILS_KEY);

  const {
    [cardTypeId]: cardTemplate = {},
  } = useSelector((state) => state.boards.cardTemplates);

  const { userId: ownerId } = company ?? {};

  const sortedStatuses = useMemo(() => {
    const nStatuses = [...statuses ?? []];
    nStatuses.sort((a, b) => a.orderIndex - b.orderIndex);
    return nStatuses;
  }, [statuses]);

  const [currentStatuses, setCurrentStatuses] = useState(sortedStatuses);

  const columnTreeData = useMemo(() => {
    const customTreeData = getCustomColumnTreeData({ sections: cardTemplate?.fields });
    return [...defaultColumnTreeData, ...customTreeData];
  }, [cardTemplate]);

  const onValuesChange = useCallback((_, { statuses: newStatuses }) => {
    setCurrentStatuses(newStatuses);
  }, []);

  const openDrawer = useCallback(() => setDrawerVisible(true), []);
  const closeDrawer = useCallback(() => {
    setDrawerVisible(false);
    setActiveTab(DETAILS_KEY);
    form.resetFields();
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const {
        title: newTitle,
        permissions: {
          isPublic: newIsPublic,
          users: newUsers = [],
        } = {},
        statuses: newStatuses = [],
        pin,
        disableCrossColumnSorting: newColSort,
        showCardNumber: newShowCardNumber,
        showListIndex: newShowListIndex,
        shouldSendStatusChangeEmail: newShouldSendStatusChangeEmail,
        shouldSendCardUpdateEmail: newShouldSendCardUpdateEmail,
        shouldSendAssignedUsersChangeEmail: newShouldSendAssignedUsersChangeEmail,
        timelineDisplayFields: newTimelineDisplayFields,
        workflows: newWorklows = [],
      } = form.getFieldsValue(true);
      const newUsersPayload = newUsers.map((newUser) => {
        const {
          userId,
          formPermission,
          readPerms,
          writePerms,
        } = newUser;
        return {
          userId,
          readPerms,
          writePerms,
          formPermission: !!formPermission,
        };
      });
      const payload = {
        title: newTitle,
        isPublic: newIsPublic,
        users: newUsersPayload,
        statuses: newStatuses.map((status) => {
          const payloadStatus = { ...status };
          delete payloadStatus.boardId;
          delete payloadStatus.companyId;
          if (payloadStatus.isNew) delete payloadStatus.id;
          return payloadStatus;
        }),
        pin,
        workflows: newWorklows,
        disableCrossColumnSorting: newColSort,
        showCardNumber: newShowCardNumber,
        showListIndex: newShowListIndex,
        shouldSendStatusChangeEmail: newShouldSendStatusChangeEmail,
        shouldSendCardUpdateEmail: newShouldSendCardUpdateEmail,
        shouldSendAssignedUsersChangeEmail: newShouldSendAssignedUsersChangeEmail,
        timelineDisplayFields: newTimelineDisplayFields,
      };
      if (await dispatch(updateBoard(boardId, payload))) {
        closeDrawer();
      }
    } catch (err) {
      message.error('Failed to save board');
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
  }, [form, boardId, closeDrawer, users, hasPin]);

  useEffect(() => {
    dispatch(getEmails());
  }, []);

  const onDelete = useCallback(async ({ password }) => {
    if (await dispatch(deleteBoard(boardId, password))) {
      closeDrawer();
    }
  }, [dispatch, closeDrawer, boardId]);

  useEffect(() => {
    setCurrentStatuses(sortedStatuses);
    form.setFieldsValue({
      title,
      permissions: { isPublic, users },
      statuses: sortedStatuses,
      workflows,
      disableCrossColumnSorting,
      showCardNumber,
      showListIndex,
      shouldSendStatusChangeEmail,
      shouldSendCardUpdateEmail,
      shouldSendAssignedUsersChangeEmail,
    });
  }, [
    boardId,
    title,
    form,
    drawerVisible,
    isPublic,
    users,
    sortedStatuses,
    workflows,
    disableCrossColumnSorting,
    showCardNumber,
    showListIndex,
    shouldSendStatusChangeEmail,
    shouldSendCardUpdateEmail,
    shouldSendAssignedUsersChangeEmail,
  ]);

  const canDelete = Permissions.id === creatorId || Permissions.id === ownerId;

  return (
    <>
      <OnTraccrButton
        type="cancel"
        title="Settings"
        icon={<SettingOutlined />}
        onClick={openDrawer}
        disabled={selectedBoardId !== boardId}
      />
      <Drawer
        title={`${title} Settings`}
        width={1000}
        onClose={closeDrawer}
        visible={drawerVisible}
        bodyStyle={{ padding: '0px 24px', maxWidth: 1000 }}
      >
        <Form
          layout="vertical"
          className="boards-setting-form"
          form={form}
          initialValues={{
            title,
            permissions: {
              isPublic,
              users,
            },
            statuses: sortedStatuses,
            workflows,
            disableCrossColumnSorting,
            showCardNumber,
            showListIndex,
            shouldSendStatusChangeEmail,
            shouldSendCardUpdateEmail,
            shouldSendAssignedUsersChangeEmail,
            timelineDisplayFields,
          }}
          onValuesChange={onValuesChange}
        >
          <Tabs tabBarStyle={{ margin: 0 }} activeKey={activeTab} onChange={setActiveTab}>
            <TabPane tab="Details" key={DETAILS_KEY}>
              <FormTextInput
                isNotDisplay
                label="Title"
                name="title"
                value={title}
                rules={[{ required: true, message: 'Please enter a title' }]}
              />
              <Form.Item
                name="pin"
                label="Lock PIN"
                rules={[
                  { validator: pinValidator },
                ]}
                labelCol={{
                  style: { marginTop: 10 },
                }}
              >
                <BoardPinInput initialValue={hasPin} />
              </Form.Item>
              <Form.Item
                name="disableCrossColumnSorting"
                label={(
                  <Row gutter={20}>
                    <Col>
                      Disable Cross Column Sorting
                    </Col>
                    <Col>
                      <HoverHelp
                        content={(
                          <div style={{ width: 300 }}>
                            Leave this box unchecked if you want to be able to
                            drag and drop to sort cards across columns.
                            <br />
                            <br />
                            If you check this box, new cards will be put at the
                            bottom of the column by default.
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                )}
                labelCol={{
                  style: { marginTop: 10 },
                }}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Row style={{
                paddingRight: 50,
                display: 'flex',
                flexDirection: 'row',
                gap: 30,
              }}
              >
                <Col style={{
                  flexBasis: '50%',
                }}
                >
                  <Form.Item
                    name="showCardNumber"
                    label={(
                      <Row gutter={20}>
                        <Col>
                          Show Card Number
                        </Col>
                        <Col>
                          <HoverHelp
                            content={(
                              <div style={{ width: 300 }}>
                                If you check this box, cards will show a
                                unique number for their type.
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                    )}
                    labelCol={{
                      style: { marginTop: 10 },
                    }}
                    valuePropName="checked"
                  >
                    <Checkbox disabled={useCardNumberAsCardTitle} />
                  </Form.Item>
                  <Form.Item
                    name="showListIndex"
                    label={(
                      <Row gutter={20}>
                        <Col>
                          Show List Index Position
                        </Col>
                        <Col>
                          <HoverHelp
                            content={(
                              <div style={{ width: 300 }}>
                                If you check this box, cards in list view
                                will show their position number.
                              </div>
                            )}
                          />
                        </Col>
                      </Row>
                    )}
                    labelCol={{
                      style: { marginTop: 10 },
                    }}
                    valuePropName="checked"
                  >
                    <Checkbox />
                  </Form.Item>
                </Col>

              </Row>

              <Form.Item
                name="shouldSendStatusChangeEmail"
                label={(
                  <Row gutter={20}>
                    <Col>
                      Send Email on Status Change
                    </Col>
                    <Col>
                      <HoverHelp
                        content={(
                          <div style={{ width: 300 }}>
                            If you check this box, an email will be sent
                            to the assigned users when a card&apos;s status is changed.
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                )}
                labelCol={{
                  style: { marginTop: 10 },
                }}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="shouldSendCardUpdateEmail"
                label={(
                  <Row gutter={20}>
                    <Col>
                      Send Email on Card Update
                    </Col>
                    <Col>
                      <HoverHelp
                        content={(
                          <div style={{ width: 300 }}>
                            If you check this box, an email will be sent
                            to the assigned users when a card is updated.
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                )}
                labelCol={{
                  style: { marginTop: 10 },
                }}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                name="shouldSendAssignedUsersChangeEmail"
                label={(
                  <Row gutter={20}>
                    <Col>
                      Send Email on Card Assigned User Change
                    </Col>
                    <Col>
                      <HoverHelp
                        content={(
                          <div style={{ width: 300 }}>
                            If you check this box, an email will be sent
                            to the all current, added, and removed users
                            when a card&apos;s assigned users is changed.
                          </div>
                        )}
                      />
                    </Col>
                  </Row>
                )}
                labelCol={{
                  style: { marginTop: 10 },
                }}
                valuePropName="checked"
              >
                <Checkbox />
              </Form.Item>
              <Form.Item
                label="Statuses"
                name="statuses"
              >
                <BoardStatusConfigure cardTypeId={cardTypeId} />
              </Form.Item>
              <Form.Item
                label="Timeline Columns"
                name="timelineDisplayFields"
              >
                <TreeSelect
                  treeData={columnTreeData}
                  treeDefaultExpandAll
                  multiple
                  showCheckedStrategy="SHOW_PARENT"
                  placeholder="Select Columns"
                  style={{
                    width: '100%',
                    flexGrow: 4,
                  }}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab="Permissions" key="permissions">
              <Form.Item name="permissions">
                <BoardPermissions
                  divisions={divisions}
                  creatorId={creatorId}
                  visible={drawerVisible}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab="Workflows" key="workflows">
              <Form.Item name="workflows">
                <BoardWorkflows
                  boardId={boardId}
                  cardTypeId={cardTypeId}
                  currentStatuses={currentStatuses}
                />
              </Form.Item>
            </TabPane>
            <TabPane tab="Forms" key="forms">
              <Form.Item name="formMappings">
                <BoardFormMappings
                  boardId={boardId}
                  divisions={divisions}
                  cardTypeId={cardTypeId}
                  currentStatuses={currentStatuses}
                />
              </Form.Item>
            </TabPane>
          </Tabs>
        </Form>
        <DrawerSubmitFooter
          onClose={closeDrawer}
          onSubmit={onSubmit}
        />
        {canDelete && (
          <BoardDeleteButton
            title={title}
            onDelete={onDelete}
          />
        )}
      </Drawer>
    </>
  );
}

BoardSettings.propTypes = {
  title: PropTypes.string,
  boardId: PropTypes.string,
  isPublic: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.string),
  creatorId: PropTypes.string,
  hasPin: PropTypes.number,
  cardTypeId: PropTypes.number,
  disableCrossColumnSorting: PropTypes.bool,
  useCardNumberAsCardTitle: PropTypes.bool,
  showCardNumber: PropTypes.bool,
  showListIndex: PropTypes.bool,
  shouldSendStatusChangeEmail: PropTypes.bool,
  shouldSendCardUpdateEmail: PropTypes.bool,
  shouldSendAssignedUsersChangeEmail: PropTypes.bool,
  timelineDisplayFields: PropTypes.arrayOf(PropTypes.string),
};

BoardSettings.defaultProps = {
  title: null,
  boardId: null,
  isPublic: false,
  divisions: [],
  creatorId: null,
  hasPin: false,
  cardTypeId: null,
  disableCrossColumnSorting: false,
  useCardNumberAsCardTitle: false,
  showCardNumber: false,
  showListIndex: false,
  shouldSendStatusChangeEmail: false,
  shouldSendCardUpdateEmail: false,
  shouldSendAssignedUsersChangeEmail: false,
  timelineDisplayFields: [],
};
