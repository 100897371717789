import { React, useCallback, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Descriptions,
  Select,
} from 'antd';
import { defaultCostcodeHeaders, defaultEstimateHeaders, matchHeader } from './ProjectBudgetTrackingImportHelpers';

const { Option } = Select;

const rowToHeaders = ((row) => {
  const keys = Object.keys(row);
  return keys.map((header, idx) => <Option value={header} key={idx}>{header}</Option>);
});

function ProjectBudgetTrackingSelectHeaders({
  sheetJSONs,
  estimateSheet,
  costcodeSheet,
  headers,
  setHeaders,
}) {
  const {
    estimateHeaders,
    estimateHeaderOptions,
    costcodeHeaders,
    costcodeHeaderOptions,
  } = useMemo(() => {
    const {
      [estimateSheet]: [estimateFirstRow = {}] = [],
      [costcodeSheet]: [costcodeFirstRow = {}] = [],
    } = sheetJSONs;
    return {
      estimateHeaders: Object.keys(estimateFirstRow),
      estimateHeaderOptions: rowToHeaders(estimateFirstRow),
      costcodeHeaders: Object.keys(costcodeFirstRow),
      costcodeHeaderOptions: rowToHeaders(costcodeFirstRow),
    };
  }, [sheetJSONs, estimateSheet, costcodeSheet]);

  const onSelect = useCallback((key) => (newValue) => {
    setHeaders({
      ...headers,
      [key]: newValue,
    });
  }, [headers]);

  useEffect(() => {
    const initialMapping = {};
    estimateHeaders.forEach((header) => {
      const foundMatch = matchHeader(header, defaultEstimateHeaders);
      if (foundMatch) initialMapping[foundMatch] = header;
    });
    costcodeHeaders.forEach((header) => {
      const foundMatch = matchHeader(header, defaultCostcodeHeaders);
      if (foundMatch) initialMapping[foundMatch] = header;
    });
    setHeaders(initialMapping);
  }, [estimateHeaders, costcodeHeaders]);

  return (
    <Col>
      <Descriptions title="Select Estimate Headers">
        {defaultEstimateHeaders.map((defaultHeader) => (
          <Descriptions.Item label={defaultHeader.title} key={defaultHeader.key}>
            <Select
              value={headers[defaultHeader.key]}
              style={{ width: 120 }}
              onSelect={onSelect(defaultHeader.key)}
            >
              {estimateHeaderOptions}
            </Select>
          </Descriptions.Item>
        ))}
      </Descriptions>
      <Descriptions title="Select Costcode Headers">
        {defaultCostcodeHeaders.map((defaultHeader) => (
          <Descriptions.Item label={defaultHeader.title} key={defaultHeader.key}>
            <Select
              value={headers[defaultHeader.key]}
              style={{ width: 120 }}
              onSelect={onSelect(defaultHeader.key)}
            >
              {costcodeHeaderOptions}
            </Select>
          </Descriptions.Item>
        ))}
      </Descriptions>
    </Col>
  );
}

ProjectBudgetTrackingSelectHeaders.propTypes = {
  sheetJSONs: PropTypes.object.isRequired,
  estimateSheet: PropTypes.string,
  costcodeSheet: PropTypes.string,
  headers: PropTypes.object.isRequired,
  setHeaders: PropTypes.func.isRequired,
};

ProjectBudgetTrackingSelectHeaders.defaultProps = {
  estimateSheet: null,
  costcodeSheet: null,
};

export default ProjectBudgetTrackingSelectHeaders;
