import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import { CloudSyncOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import WorkflowSimpleMappingNode from './WorkflowSimpleMappingNode';
import WorkflowQuickBooksConfigureDrawer from './WorkflowQuickBooksConfigureDrawer';
import WorkflowHandle from './WorkflowHandle';

const INCORRECT_TYPE_TEXT = 'This step only works with Invoice or PO forms';

const getDisabledText = (t) => ({
  Invoice: `This step requires at least one Text field and one ${t('Customer')} Select field`,
  PO: 'This step requires at least one Material Table field and one Vendor Select field',
});

const SUPPORTED_FORM_TYPES = new Set([
  'Invoice',
  'PO',
]);

export default ({
  setDataMap,
  setElements,
  isDisplay,
  name,
  sections = [],
  formTypeName,
} = {}) => {
  const WorkflowQuickBookSync = function WorkflowQuickBookSync({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const { t } = useTranslation();
    const DISABLED_TEXT = getDisabledText(t);
    const style = useMemo(() => {
      if (disabled) return { opacity: 0.7 };
      return draggable ? {} : { width: 220 };
    }, [disabled, draggable]);

    const hover = useMemo(() => {
      if (!SUPPORTED_FORM_TYPES.has(formTypeName)) return INCORRECT_TYPE_TEXT;
      return disabled
        ? DISABLED_TEXT[formTypeName]
        : `Use this step to sync a ${formTypeName} with QuickBooks`;
    }, [disabled, formTypeName]);

    const getConfigureDrawer = useCallback((drawerProps) => (
      <WorkflowQuickBooksConfigureDrawer
        {...drawerProps} // eslint-disable-line react/jsx-props-no-spreading
        formTypeName={formTypeName}
      />
    ), [formTypeName]);

    const isInvoice = formTypeName === 'Invoice';

    return (
      <WorkflowSimpleMappingNode
        setElements={setElements}
        setDataMap={setDataMap}
        isDisplay={isDisplay}
        name={name}
        sections={sections}
        id={id}
        draggable={draggable}
        disabled={disabled}
        data={data}
        title="Sync with QuickBooks"
        Icon={CloudSyncOutlined}
        type={isInvoice ? 'qbInvoice' : 'qbPO'}
        hover={hover}
        style={style}
        ConfigureDrawer={getConfigureDrawer}
      >
        {isInvoice && (
          <>
            <Row justify="center" style={{ marginBottom: 5 }}>
              <Col>
                On Payment
              </Col>
            </Row>
            <WorkflowHandle type="source" position="bottom" disabled={isDisplay} />
          </>
        )}
      </WorkflowSimpleMappingNode>
    );
  };
  WorkflowQuickBookSync.propTypes = {
    id: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  WorkflowQuickBookSync.defaultProps = {
    draggable: false,
    disabled: false,
    data: {},
  };
  return WorkflowQuickBookSync;
};
