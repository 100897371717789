import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Drawer,
  Table,
  Row,
  Input,
} from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import DrawerSubmitFooter from '../containers/DrawerSubmitFooter';
import { includesTerm } from '../../helpers/helpers';
import useValidateMaxSelect from '../hooks/useValidateMaxSelect';

const columns = [{
  dataIndex: 'name',
  title: 'Name',
}, {
  dataIndex: 'number',
  title: 'Number',
}, {
  dataIndex: 'address',
  title: 'Address',
}];

export default function ExistingProjectSelect({
  customerId,
  type,
  name,
  onClose,
  onSave,
  visible,
  selectedProjectIds,
  maxAllowed,
}) {
  const { t } = useTranslation();
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);

  const [selected, setSelected] = useState([]);
  const [searchStr, setSearchStr] = useState();

  useEffect(() => {
    setSelected([]);
    setSearchStr();
  }, [visible]);

  const onSearch = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  }, []);

  const onSubmit = useCallback(() => {
    const isValid = useValidateMaxSelect({
      maxExistingAllowed: maxAllowed,
      selected,
      type: `${t('Project', { count: selected.length }).toLowerCase()}`,
    });
    if (isValid) onSave(selected);
  }, [onSave, selected]);

  const ourCustomer = useMemo(() => customers?.[customerId] ?? null, [customers, customerId]);

  const eligibleProjects = useMemo(() => {
    const selectedProjectIdSet = new Set(selectedProjectIds);

    return projects.filter((project) => (
      project.active
        && (!customerId || project.customerId !== customerId)
        && (!selectedProjectIds || !selectedProjectIdSet.has(project.id))
        && (!ourCustomer || ourCustomer?.divisionIds?.some(
          (divisionId) => divisionId === project.divisionId,
        ))
        && (
          includesTerm(project.name, searchStr)
          || includesTerm(project.number, searchStr)
          || includesTerm(project.address, searchStr)
        )
    ));
  }, [projects, searchStr, customerId, selectedProjectIds, ourCustomer]);

  return (
    <Drawer
      title={`Add ${t('Project')} to ${t(type)} ${name}`}
      onClose={onClose}
      visible={visible}
      width={600}
      bodyStyle={{ padding: 0 }}
    >
      <Row className="customer-project-select-search">
        <Input.Search
          type="search"
          style={{ width: 250 }}
          className="searchbar"
          placeholder="Search"
          allowClear
          onChange={onSearch}
          value={searchStr}
        />
      </Row>
      <Table
        columns={columns}
        dataSource={eligibleProjects}
        rowSelection={{
          onChange: (newSelected) => setSelected(newSelected),
          selectedRowKeys: selected,
        }}
        pagination={false}
        rowKey={(project) => project.id}
        size="small"
        style={{ width: '100%', height: 'calc(100vh - 181px)' }}
        scroll={{ y: 'calc(100vh - 220px)' }}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
};

ExistingProjectSelect.propTypes = {
  customerId: PropTypes.string,
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  selectedProjectIds: PropTypes.arrayOf(PropTypes.string),
  maxAllowed: PropTypes.number,
};

ExistingProjectSelect.defaultProps = {
  customerId: null,
  selectedProjectIds: [],
  maxAllowed: null,
};
