import React, {
  useMemo, useState, useCallback,
} from 'react';
import { Row, Col, Table } from 'antd';
import { HistoryOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import colors from '../../constants/Colors';

import EquipmentHistoryDrawer from '../ProjectProgress/EquipmentHistoryDrawer';

import ProjectEquipmentDates from './ProjectEquipmentDates';

function ProjectEquipmentList({
  projectId,
  title,
  onRemove,
  onAddNew,
  onAddExisting,
  isNotDisplay,
  chosen = [],
  equipmentSchedule,
  onEquipmentDateChanged,
  onEquipmentDateRemoved,
  onRowClick,
}) {
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const onCloseEquipmentDrawer = useCallback(() => setSelectedEquipment(), []);

  const onHistoryClick = useCallback((e, record) => {
    e.stopPropagation();
    setSelectedEquipment(record);
  }, []);

  const columns = useMemo(() => {
    const cols = [{
      title: <span style={{ paddingLeft: 10 }}>Name</span>,
      dataIndex: 'name',
      render: (val) => <span style={{ paddingLeft: 10 }}>{val}</span>,
    }];
    if (!isNotDisplay) {
      cols.push({
        title: 'History',
        dataIndex: 'history',
        key: 'history',
        align: 'center',
        width: 150,
        render: (_, record) => (
          <BorderlessButton
            style={{ padding: 0, width: 40 }}
            iconNode={<HistoryOutlined style={{ marginLeft: 0 }} />}
            onClick={(e) => onHistoryClick(e, record)}
          />
        ),
      });
    }
    if (isNotDisplay) {
      cols.push({
        title: '',
        dataIndex: 'add',
        align: 'center',
        width: 200,
        render: (_, record) => (
          <BorderlessButton
            icon="plus"
            title="Add Date"
            onClick={() => {
              onEquipmentDateChanged({
                id: `${record.id}.${Math.floor(new Date())}`,
                name: record.name,
                range: [null, null],
              });
              const newExpanded = [...expandedRowKeys];
              if (!newExpanded.includes(record.id)) {
                newExpanded.push(record.id);
              }
              setExpandedRowKeys(newExpanded);
            }}
            style={{ paddingRight: 8, paddingLeft: 0 }}
          />
        ),
      });
      cols.push({
        title: 'Remove',
        dataIndex: 'remove',
        align: 'center',
        width: 100,
        render: (_, record) => (
          <BorderlessButton
            icon="delete"
            color={colors.ONTRACCR_RED}
            onClick={() => onRemove(record)}
            style={{ paddingRight: 8, paddingLeft: 0 }}
          />
        ),
      });
    }
    return cols;
  }, [isNotDisplay, onRemove, onEquipmentDateChanged, expandedRowKeys]);

  const expandedRowRender = useCallback((record) => (
    <ProjectEquipmentDates
      {...record} // eslint-disable-line react/jsx-props-no-spreading
      isNotDisplay={isNotDisplay}
      equipmentSchedule={equipmentSchedule}
      onEquipmentDateChanged={onEquipmentDateChanged}
      onDateRemove={onEquipmentDateRemoved}
    />
  ), [isNotDisplay, equipmentSchedule, onEquipmentDateChanged, onEquipmentDateRemoved]);

  return (
    <div
      className="project-add-costcode-list-container"
      style={{
        position: 'absolute',
        inset: 0,
        top: isNotDisplay ? 30 : 0,
      }}
    >
      <Row
        className="project-add-equipment-list-header"
        justify="space-between"
        align="middle"
      >
        <Col>
          <Row gutter={20} justify="start" align="middle">
            <Col className="project-add-costcode-header-title">
              {title}
            </Col>
          </Row>
        </Col>
        <Col>
          {isNotDisplay
              && (
              <Row justify="end" gutter={10}>
                <Col>
                  <OnTraccrButton
                    title="Add New"
                    onClick={onAddNew}
                  />
                </Col>
                <Col>
                  <OnTraccrButton
                    title="Add Existing"
                    onClick={onAddExisting}
                  />
                </Col>
              </Row>
              )}
        </Col>
      </Row>
      <div className="project-add-equipment-table">
        <Table
          style={{ height: '100%' }}
          scroll={{
            y: `calc(100vh - ${isNotDisplay ? 312 : 282}px)`,
          }}
          columns={columns}
          dataSource={chosen}
          pagination={false}
          size="small"
          rowKey="id"
          rowClassName={!isNotDisplay && 'project-equipment-table-row'}
          onRow={(record) => ({
            onClick: () => onRowClick(record),
          })}
          expandable={{
            indentSize: 5,
            expandedRowKeys,
            defaultExpandAllRows: false,
            onExpandedRowsChange: setExpandedRowKeys,
            expandedRowRender,
            rowExpandable: (record) => {
              const { id: recordId } = record;
              return Object.keys(equipmentSchedule).some((key) => {
                // Key is like `${recordId}.${timestamp}
                const [idPart] = key.split('.');
                if (idPart !== recordId) return false;
                if (isNotDisplay) return true;
                const {
                  [key]: {
                    range = [],
                  } = {},
                } = equipmentSchedule;
                return range
                  && range?.length === 2
                  && range[0]
                  && range[1];
              });
            },
          }}
        />
      </div>
      <EquipmentHistoryDrawer
        record={selectedEquipment}
        onClose={onCloseEquipmentDrawer}
        projectId={projectId}
      />
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
ProjectEquipmentList.propTypes = {
  projectId: PropTypes.string,
  title: PropTypes.string,
  onRemove: PropTypes.func.isRequired,
  onAddNew: PropTypes.func.isRequired,
  onAddExisting: PropTypes.func.isRequired,
  isNotDisplay: PropTypes.bool,
  chosen: PropTypes.array,
  equipmentSchedule: PropTypes.object,
  onEquipmentDateChanged: PropTypes.func.isRequired,
  onEquipmentDateRemoved: PropTypes.func.isRequired,
  onRowClick: PropTypes.func.isRequired,
};

ProjectEquipmentList.defaultProps = {
  projectId: null,
  title: null,
  isNotDisplay: false,
  chosen: [],
  equipmentSchedule: {},
};

export default ProjectEquipmentList;
