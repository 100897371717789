import React from 'react';
import { Popover } from 'antd';

import { SketchPicker } from 'react-color';

import BorderlessButton from '../buttons/BorderlessButton';
import { rgbToHex } from '../pdf/PDFDesigner/PDFDesignerHelpers';
import { CloseCircleFilled } from '@ant-design/icons';

export default ({
  value,
  onChange,
  isNotDisplay,
  style = {},
  allowClear,
  showText = true,
}) => {
  return (
    <>
      <Popover
        placement='left'
        content={
          <SketchPicker
            width={300}
            color={value}
            onChangeComplete={({ rgb:color }) => onChange(rgbToHex(color))}
          />
        }
        {...isNotDisplay ? {} : { visible : false }}
      >
        <BorderlessButton
          title={showText ? (value || 'Not set') : ''}
          style={{
            color: 'black',
            textAlign: 'left',
            padding: 0,
            pointerEvents: isNotDisplay ? 'auto' : 'none',
            ...style,
          }}
          iconNode={
            <>
              { isNotDisplay && allowClear && value && <BorderlessButton
                style={{
                  width: '5px',
                  height: '5px',
                  top:'2px',
                  right: '35px',
                  position: 'absolute',
                  opacity: 0.5,
                }}
                iconNode={<CloseCircleFilled/>}
                onClick={() => onChange(undefined)}
              /> }
              <span style={{ backgroundColor: value }} className='pdf-designer-color-preview'/>
            </>
          }
        />
      </Popover>
    </>
  );
}
