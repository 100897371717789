import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Row, Tabs } from 'antd';

import LabelTabView from './LabelsTabView';

import { getLabels } from '../../labels/state/labels.actions';
import { getStatuses } from '../../equipment/state/equipment.actions';
import { getProjectTypes } from '../../projects/state/projects.actions';

const { TabPane } = Tabs;

const tabStyle = {
  paddingTop: 7,
  paddingBottom: 7,
  overflowY: 'auto',
  maxHeight: 'calc(100vh - 70px)',
};

const LABEL_KEY = 'labels';
const STATUS_KEY = 'statuses';

function Labels({ visible }) {
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      dispatch(getLabels());
      dispatch(getStatuses());
      dispatch(getProjectTypes());
    }
  }, [dispatch, visible]);

  return (
    <Row justify="start">
      <Tabs
        defaultActiveKey={LABEL_KEY}
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 135,
          marginRight: 17,
          marginBottom: 15,
        }}
        style={{ width: '100%' }}
      >
        <TabPane style={tabStyle} tab="Labels" key={LABEL_KEY}>
          <LabelTabView visible={visible} viewType="label" />
        </TabPane>
        <TabPane style={tabStyle} tab="Statuses" key={STATUS_KEY}>
          <LabelTabView visible={visible} viewType="status" />
        </TabPane>
      </Tabs>
    </Row>
  );
}

Labels.propTypes = {
  visible: PropTypes.bool,
};

Labels.defaultProps = {
  visible: false,
};

export default Labels;
