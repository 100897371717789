import React, {
  useCallback, useState, useRef, useEffect, useMemo,
} from 'react';
import { Row, Col } from 'antd';
import { PropTypes } from 'prop-types';

import { DateTime } from 'luxon';
import WeekScheduleHeader from './WeekScheduleHeader';
import ScheduleDay from '../ScheduleDay';
import ScheduleGrid from '../ScheduleGrid';

import {
  HOUR_HEIGHT,
  INITIAL_SCALE,
  WEEKLY_HEADER_BLOCK_TOTAL_HEIGHT,
} from '../schedule.constants';

import {
  getDayKey,
  getWeekStart,
  splitMultiDayShifts,
} from '../scheduleHelpers';

export default function WeeklySchedule({
  date,
  onShiftCreate,
  newShift,
  onNewShiftChange,
  onShiftSelect,
  editShift,
  onShiftEdit,
  shifts = [],
  onDaySelect,
}) {
  const scrollRef = useRef(null);

  const weekStart = getWeekStart(date);
  const [scrollTop, setScrollTop] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(WEEKLY_HEADER_BLOCK_TOTAL_HEIGHT);
  const onScroll = useCallback((e) => {
    const {
      target: {
        scrollTop: targetTop,
      } = {},
    } = e;
    setScrollTop(targetTop);
  }, []);

  useEffect(() => {
    scrollRef.current.scrollTo({
      top: HOUR_HEIGHT * 7 * INITIAL_SCALE,
    });
  }, []);

  const days = new Array(7).fill(1).map((_, idx) => weekStart.plus({ day: idx }));

  const {
    multiDayShifts,
    interDayShifts,
  } = useMemo(() => {
    const multiDaySet = new Set();
    const multiDay = [];
    const interDay = {};
    days.forEach((day) => {
      const dayKey = getDayKey(day);
      const {
        [dayKey]: dayShifts = [],
      } = shifts;
      const {
        multiDay: dayMulti,
        interDay: dayInter,
      } = splitMultiDayShifts(dayShifts, day);
      dayMulti.forEach((dayShift) => {
        if (!multiDaySet.has(dayShift.id)) {
          multiDay.push(dayShift);
          multiDaySet.add(dayShift.id);
        }
      });
      interDay[dayKey] = dayInter;
    });
    return {
      multiDayShifts: multiDay,
      interDayShifts: interDay,
    };
  }, [shifts, days]);

  return (
    <div className="schedule-view-container" style={{ overflow: 'hidden', display: 'block' }}>
      <WeekScheduleHeader
        weekStart={weekStart}
        onDaySelect={onDaySelect}
        shifts={multiDayShifts}
        onShiftSelect={onShiftSelect}
        onShiftEdit={onShiftEdit}
        onHeightChange={setHeaderHeight}
      />
      <div
        ref={scrollRef}
        className="schedule-view-container"
        onScroll={onScroll}
        style={{
          border: 'none',
          marginTop: 0,
          display: 'block',
        }}
      >
        <div className="schedule-view-inner-container" style={{ paddingTop: 0, paddingBottom: 75 }}>
          <div className="schedule-week-grid">
            <ScheduleGrid hideFirst />
            <div className="schedule-left-vertical-divider" />
          </div>

          <Row className="schedule-week-container">
            {
            days.map((day, idx) => (
              <Col className="schedule-week-day-column" key={idx}>
                <ScheduleDay
                  hideGrid
                  key={idx}
                  isWeek
                  day={day}
                  rightDivider={idx < 6}
                  onShiftCreate={onShiftCreate}
                  newShift={newShift}
                  onNewShiftChange={onNewShiftChange}
                  onShiftSelect={onShiftSelect}
                  editShift={editShift}
                  onShiftEdit={onShiftEdit}
                  scrollTop={scrollTop}
                  shifts={interDayShifts[getDayKey(day)]}
                  headerHeight={headerHeight}
                />
              </Col>
            ))
          }
          </Row>
        </div>
      </div>
    </div>
  );
}

WeeklySchedule.propTypes = {
  date: PropTypes.instanceOf(DateTime).isRequired,
  onShiftCreate: PropTypes.func.isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onShiftEdit: PropTypes.func.isRequired,
  onNewShiftChange: PropTypes.func.isRequired,
  onDaySelect: PropTypes.func.isRequired,
  newShift: PropTypes.shape({}),
  editShift: PropTypes.shape({}),
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
};

WeeklySchedule.defaultProps = {
  shifts: [],
  newShift: null,
  editShift: null,
};
