import React, {
  useMemo, useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import axios from 'axios';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';

import CollapsableContainer from '../common/containers/CollapsableContainer';

import BoardCard from './BoardCardView/BoardCard';

import { getCardsByLink } from './state/boards.actions';

import { parseCompletedForm } from '../forms/formHelpers';

export default function BoardLinkView({
  linkId,
  linkType,
  links = [],
  style = {},
}) {
  const dispatch = useDispatch();
  const history = useHistory();

  const cardUserMap = useSelector((state) => state.boards.cardUserMap);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);

  const [cards, setCards] = useState([]);

  useEffect(() => {
    const getLinks = async () => {
      try {
        const { data } = await axios.get('/boards/cards/links', { 
          params: {
            linkType,
            linkId,
          },
        });
        setCards(data);
      } catch (err) {
        message.error(err);
        setCards([]);
      }
    };
    if (linkId && linkType) {
      dispatch(getCardsByLink(linkType, linkId));
      getLinks();
    } else {
      setCards([]);
    }
  }, [linkId, linkType, dispatch]);

  const onClickCard = useCallback(({ boardId, cardId }) => () => {
    history.push({
      pathname: '/boards',
      state: { boardId, cardId },
    });
  }, [history]);

  const contents = useMemo(() => {
    const boardMap = {};
    cards.forEach((link) => {
      const {
        boardId,
        boardTitle,
        cardTitle,
        cardId,
        data,
        cardTypeId,
      } = link;
      if (!(boardId in boardMap)) {
        boardMap[boardId] = {
          id: boardId, title: boardTitle, cards: [],
        };
      }
      const {
        [cardId]: users = [],
      } = cardUserMap;
      boardMap[boardId].cards.push({
        ...link,
        title: cardTitle,
        id: cardId,
        data: parseCompletedForm({
          sections: data ?? [],
          templateMap: cardTemplates[cardTypeId] ?? {}
        }),
        users,
      });
    });
    return Object.values(boardMap)
      .map((board) => (
        <CollapsableContainer title={board.title} key={board.id}>
          {
            board.cards.map((card) => (
              <BoardCard
                {...card}
                key={card.id}
                onClick={onClickCard({ boardId: board.id, cardId: card.id })}
                isLocked
                displayFields={new Set(card.displayFields)}
              />
            ))
          }
        </CollapsableContainer>
      ));
  }, [cards, cardUserMap, cardTemplates]);
  // We need dragdrop stuff to prevent BoardCard from throwing
  return (
    <div className="board-link-view-container" style={style}>
      <DragDropContext>
        <Droppable>
          {() => contents}
        </Droppable>
      </DragDropContext>
    </div>
  );
}
