import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import {
  DragDropContext,
  Droppable,
} from 'react-beautiful-dnd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import ExportsColumn from './ExportsColumn';

import {
  COLUMN_PROP_TYPE,
} from './exports.constants';

import { generateRandomString } from '../../helpers/helpers';
import { getTableFieldId } from '../../reports/Exports/exports.helpers';

function ExportsColumnHeader({
  divisionId,
  columns,
  onColumnsChanged,
  hasEclipse,
  type,
  formTemplateId,
}) {
  const onDragEnd = useCallback((dragEvent) => {
    const { destination, source } = dragEvent;
    if (!destination) return;
    if (
      destination.droppableId === source.droppableId
      && destination.index === source.index
    ) return;
    const newColumns = [...columns];
    newColumns.splice(source.index, 1);
    newColumns.splice(destination.index, 0, columns[source.index]);
    onColumnsChanged(newColumns);
  }, [columns, onColumnsChanged]);

  const onAddColumn = useCallback(() => {
    const newColumn = {
      id: generateRandomString(),
    };
    onColumnsChanged(columns.concat([newColumn]));
  }, [columns, onColumnsChanged]);

  const onDeleteColumn = useCallback((columnId) => {
    onColumnsChanged(columns.filter((column) => column.id !== columnId));
  }, [columns, onColumnsChanged]);

  const onColumnEdit = useCallback((id, data = {}) => {
    onColumnsChanged(columns.map((column) => {
      if (column.id !== id) return column;
      return {
        ...column,
        ...data,
      };
    }));
  }, [columns, onColumnsChanged]);

  const tableFieldId = useMemo(() => getTableFieldId(columns), [columns]);

  return (
    <Row style={{ width: '100%' }}>
      <Col flex="135px">
        <OnTraccrButton
          icon={<PlusOutlined />}
          title="Add Column"
          onClick={onAddColumn}
          style={{ margin: '0px 10px' }}
        />
      </Col>
      <Col
        flex="auto"
        className="export-column-header-scroller"
      >
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="parent" direction="horizontal">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps} // eslint-disable-line react/jsx-props-no-spreading
                style={{ width: '100%', flexDirection: 'row', display: 'flex' }}
              >
                {
                  columns.map((column, index) => (
                    <ExportsColumn
                      column={column}
                      key={column.id}
                      onDelete={onDeleteColumn}
                      onChange={onColumnEdit}
                      orderIndex={index}
                      divisionId={divisionId}
                      tableFieldId={tableFieldId}
                      hasEclipse={hasEclipse}
                      type={type}
                      formTemplateId={formTemplateId}
                    />
                  ))
                }
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Col>
    </Row>
  );
}

ExportsColumnHeader.propTypes = {
  divisionId: PropTypes.string,
  columns: PropTypes.arrayOf(COLUMN_PROP_TYPE).isRequired,
  onColumnsChanged: PropTypes.func.isRequired,
  hasEclipse: PropTypes.bool,
  type: PropTypes.string.isRequired,
  formTemplateId: PropTypes.string,
};

ExportsColumnHeader.defaultProps = {
  divisionId: null,
  hasEclipse: false,
  formTemplateId: null,
};

export default ExportsColumnHeader;
