import React from 'react';
import { Row, Col, Input,  } from 'antd';

import BorderlessButton from '../common/buttons/BorderlessButton';
import Colors from '../constants/Colors';

const borderRadius = 6;
const buttonStyle = {
  backgroundColor:'inherit',
  height: 26,
  padding:0,
  borderRadius,
  color:'white',
  fontSize:12,
};

export default ({
  onSearch,
  selected,
  onSelect,
}) => (
  <Row style={{
    width: '100%',
    padding: 20,
    height:125,
  }} justify='center' align='middle'>
     <Input.Search
        className='searchbar'
        placeholder="Search"
        onChange={onSearch}
        allowClear
      />
      <Row style={{
          marginLeft: 10,
          marginRight: 10,
          marginTop:20,
          marginBottom: 20,
          width: '100%',
          height:30,
          borderRadius,
          backgroundColor:Colors.DARK_GRAY, padding:2 }}>
        <Col
          span={12}
          style={{
            borderRadius,
            height:'100%',
            backgroundColor:selected === 'default' ? Colors.ONTRACCR_BLACK: 'transparent'
          }}
          >
          <BorderlessButton
            style={buttonStyle}
            title='Company'
            onClick={() => onSelect('default')}
          />
        </Col>
        <Col
          span={12}
          style={{
            borderRadius,
            backgroundColor:selected === 'saved' ? Colors.ONTRACCR_BLACK: 'transparent',
            height:'100%',
          }}>
            <BorderlessButton
              style={buttonStyle}
              title='Saved'
              onClick={() => onSelect('saved')}
            />
        </Col>
      </Row>
  </Row>
);
