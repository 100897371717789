import React from 'react';
import { Select,Row,Col, } from 'antd';

import CostCodeUploadList from '../costcodes/costcodeUploadList';
import BorderlessButton from '../common/buttons/BorderlessButton';

import colors from '../constants/Colors';

const { Option } = Select;

const getContent = ({
  availabe = [],
  chosen = [],
  onSearchSelect,
  onListClear,
  onRemove,
}) => (<div>
  <Row type='flex' justify='space-between' align='middle'
    style={{marginTop:10, marginBottom:20,}}
  >
    <Col>
      <Row gutter={16}>
        <Col>
          <Select
            showSearch
            style={{ width: 300 }}
            placeholder="Select a Global Cost Code"
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            onChange={onSearchSelect}
            value='Select a Global Cost Code'
          >
            {availabe.map(cc =>  (
              <Option 
                key={cc.id}
                value={cc.id}>
                  {`${cc.code} - ${cc.name}`}
                </Option>
            ))}
          </Select>
        </Col>
      </Row>
    </Col>
    
    <Col style={{visibility:chosen.length > 0 ? 'visible' : 'hidden'}}>
      <BorderlessButton
        title='Clear'
        icon='close-circle'
        color={colors.ONTRACCR_RED}
        onClick={onListClear}
      />
    </Col>
  </Row>
  
  <CostCodeUploadList
    dataSource={chosen}
    onRemove={onRemove}
  />
</div>);
export default (props) =>  (
  <div style={{ height: '80%', width: '100%' }}>
    {getContent(props)}
  </div>
);
