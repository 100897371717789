import React, { useEffect, useState } from 'react';
import { Form } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';

import CustomModal from '../modals/CustomModal';
import { describeItem, updateFile } from '../../files/state/files.actions';
import OnTraccrTextInput from '../inputs/OnTraccrTextInput';
import DisplayText from '../text/DisplayText';
import { parseSize } from '../../files/fileHelpers';
import OnTraccrButton from '../buttons/OnTraccrButton';
import Colors from '../../constants/Colors';

export default function PhotoMetadataModal({
  visible,
  onClose,
  path,
}) {
  const dispatch = useDispatch();
  const fileDetails = useSelector((state) => state.files.fileDetails);
  const [form] = Form.useForm();

  const [fileId, setFileId] = useState();
  const [description, setDescription] = useState();
  const [size, setSize] = useState();
  const [latitude, setLatitude] = useState();
  const [longitude, setLongitude] = useState();
  const [timestamp, setTimestamp] = useState();

  useEffect(() => {
    if (!visible) return;
    const trimmedPath = path?.split('/files/')?.[1];
    dispatch(describeItem(trimmedPath));
  }, [visible, path]);

  useEffect(() => {
    if (!visible) {
      setFileId();
      setSize();
      setDescription();
      setLatitude();
      setLongitude();
      setTimestamp();
    }
  }, [visible]);

  useEffect(() => {
    const relevantDetails = fileDetails?.versions?.[0];

    setFileId(relevantDetails?.id);
    setDescription(relevantDetails?.description);
    setSize(relevantDetails?.size);
    setLatitude(relevantDetails?.latitude);
    setLongitude(relevantDetails?.longitude);
    setTimestamp(relevantDetails?.timestamp);

    form.setFieldsValue({
      description: relevantDetails?.description,
      size: relevantDetails?.size,
      latitude: relevantDetails?.latitude,
      longitude: relevantDetails?.longitude,
      timestamp: relevantDetails?.timestamp,
    });
  }, [fileDetails]);

  const onSubmit = async () => {
    const result = await dispatch(updateFile(fileId, {
      description,
    }));

    if (result) onClose();
  };

  return (
    <CustomModal
      title="Photo Information"
      visible={visible}
      onCloseClicked={onClose}
      width={600}
      maskColor={Colors.MODAL_MASK_DARK}
    >
      <Form
        layout="vertical"
        form={form}
        initialValues={{
          description,
          size,
          latitude,
          longitude,
          timestamp,
        }}
        style={{ margin: 20 }}
      >
        <Form.Item
          label="Description"
          name="description"
        >
          <OnTraccrTextInput
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          />
        </Form.Item>
        <Form.Item
          label="Size"
          name="size"
        >
          <DisplayText title={parseSize(size)} style={{ textAlign: 'left' }} />
        </Form.Item>
        <Form.Item
          label="Latitude"
          name="latitude"
        >
          <DisplayText title={latitude ?? 'N/A'} style={{ textAlign: 'left' }} />
        </Form.Item>
        <Form.Item
          label="Longitude"
          name="longitude"
        >
          <DisplayText title={longitude ?? 'N/A'} style={{ textAlign: 'left' }} />
        </Form.Item>
        <Form.Item
          label="Timestamp"
          name="timestamp"
        >
          <DisplayText
            title={timestamp
              ? DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_MED)
              : 'N/A'}
            style={{ textAlign: 'left' }}
          />
        </Form.Item>
        <OnTraccrButton
          title="Save"
          onClick={onSubmit}
          roundStyle
        />
      </Form>
    </CustomModal>
  );
}

PhotoMetadataModal.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  path: PropTypes.string,
};

PhotoMetadataModal.defaultProps = {
  visible: false,
  path: '',
};
