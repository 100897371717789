import React from 'react';
import { Row, Col, Checkbox } from 'antd';

import HoverHelp from '../../common/HoverHelp';

const helpText = (name) => (
  <div style={{ width: 300}}>
    Leave this checked if you want these users to be assigned to
    both the {name} division and the selected division.
    <br/><br/>
    Uncheck this box if you want to remove the assignment to the {name} division for these users.
  </div>
)

export default ({
  name = 'original',
  onCheckChange,
  keepOld,
  style = {},
}) => (
  <Row justify='start' gutter={10} style={{ width:'100%', marginBottom: 20, ...style }}>
    <Col>
      <Checkbox 
        onChange={onCheckChange}
        checked={keepOld}
      >
        {`Keep assignment to ${name} division`}
      </Checkbox>
    </Col>
    <Col>
      <HoverHelp content={helpText(name)} placement='leftBottom'/>
    </Col>
</Row>
);