import React from 'react';
import {
  EnvironmentOutlined,
  CloseOutlined,
  CheckOutlined,
  ArrowDownOutlined,
} from '@ant-design/icons';
import { DateTime } from 'luxon';

export const SETTING_PAYROLL_TYPE = 'SETTING_PAYROLL';
export const TIME_TRACKING_TYPE = 'TIME_TRACKING';
export const GPS_TYPE = 'GPS';
export const OVERTIME_TYPE = 'OVERTIME';
export const APPROVALS_TYPE = 'APPROVALS';
export const TABLET_TYPE = 'TABLET';
export const USER_ROLES_TYPE = 'USER_ROLES';
export const INTEGRATION_TYPE = 'INTEGRATIONS';

export const COMPANY_SETTINGS_TYPES = [
  SETTING_PAYROLL_TYPE,
  TIME_TRACKING_TYPE,
  GPS_TYPE,
  OVERTIME_TYPE,
  APPROVALS_TYPE,
  TABLET_TYPE,
  USER_ROLES_TYPE,
  INTEGRATION_TYPE,
];

export const COSTCODE_WHAT_ARE_TYPE = 'COSTCODE_WHAT_ARE';
export const COSTCODE_PROJECT_SPECIFIC_TYPE = 'COSTCODE_PROJECT_SPECIFIC';
export const COSTCODE_GLOBAL_TYPE = 'COSTCODE_GLOBAL';

export const COSTCODE_TYPES = [
  COSTCODE_WHAT_ARE_TYPE,
  COSTCODE_GLOBAL_TYPE,
  COSTCODE_PROJECT_SPECIFIC_TYPE,
];

export const PROJECT_GENERAL_TYPE = 'PROJECT_GENERAL';
export const PROJECT_COSTCODE_TYPE = 'PROJECT_COSTCODE';
export const PROJECT_BUDGET_TYPE = 'PROJECT_BUDGET';

export const PROJECT_TYPES = [
  PROJECT_GENERAL_TYPE,
  PROJECT_COSTCODE_TYPE,
  PROJECT_BUDGET_TYPE,
];

export const USER_ATTRIBUTES_TYPE = 'USER_ATTRIBUTES';
export const USER_SHORTCUT_TYPE = 'USER_SHORTCUT';

export const USER_TYPES = [
  USER_ATTRIBUTES_TYPE,
  USER_SHORTCUT_TYPE,
];

export const DOCUMENT_MANAGE_TYPE = 'MANAGE_DOCUMENTS';
export const DOCUMENT_ADD_TYPE = 'ADD_DOCUMENTS';
export const DOCUMENT_PERMS_TYPE = 'DOCUMENTS_PERMISSIONS';
export const DOCUMENT_VERSION_TYPE = 'DOCUMENTS_VERSIONING';
export const FORM_CREATE_TYPE = 'FORM_CREATE';

export const DOCUMENT_TYPES = [
  DOCUMENT_MANAGE_TYPE,
  DOCUMENT_ADD_TYPE,
  DOCUMENT_PERMS_TYPE,
  DOCUMENT_VERSION_TYPE,
  FORM_CREATE_TYPE,
];

export const LIVE_FEED_REALTIME_TYPE = 'LIVE_FEED_REAL_TIME';
export const LIVE_FEED_MESSAGE_TYPE = 'LIVE_FEED_MESSAGE';
export const LIVE_FEED_TYPES = [
  LIVE_FEED_REALTIME_TYPE,
  LIVE_FEED_MESSAGE_TYPE,
];

export const GET_START_CLOSE_TYPE = 'GET_STARTED_CLOSE';
export const GET_STARTED_CLOSE_NUX_STEP_1 = 'GET_STARTED_CLOSE_NUX_STEP_1';

export const GET_STARTED_CLOSE_NUX_STEP_1_TEXT = `
The Getting Started menu can be accesssed any time from the help menu in the top right.
`;

export const TIME_CARD_LOCATION_V1_TYPE = 'TIME_CARD_LOCATION_V1';
export const TIME_CARD_LOCATION_V1_STEP1 = 'TIME_CARD_LOCATION_V1_STEP_1';
export const TIME_CARD_LOCATION_V1_STEP2 = 'TIME_CARD_LOCATION_V1_STEP_2';

export const TIME_CARD_LOCATION_V1_STEP1_TEXT = `
This Time Card has location stamps. Click here to check it out.
`;
export const TIME_CARD_LOCATION_V1_STEP2_TEXT = (
  <span>
    This list contains this user's Clock In and Clock Out events for the day.
    Hover over entries with the <EnvironmentOutlined/> icon to highlight the location on the map.
  </span>
);

export const FILES_PAGE_TYPE = 'FILES_PAGE';
export const FILES_MENU_TYPE = 'FILES_MENU';
export const FILES_MENU_NUX_STEP_1 = 'FILES';
export const FILES_MENU_NUX_STEP_1_TEXT = `
  Click here to manage your files.
`;
export const FILES_PAGE_NUX_STEP_1 = 'FILES_PAGE_STEP_1';
export const FILES_PAGE_NUX_STEP_2 = 'FILES_PAGE_STEP_2';
export const FILES_PAGE_NUX_STEP_3 = 'FILES_PAGE_STEP_3';

export const FILES_PAGE_NUX_STEP_1_SEARCH_TEXT = `
  Search by file name or extension here.
`;
export const FILES_PAGE_NUX_STEP_1_ADD_TEXT = `
  Click these buttons to add a folder or files.
`;

export const FILES_PAGE_NUX_STEP_2_FILTER_TEXT = `
  Filter or sort by file type here.
`;
export const FILES_PAGE_NUX_STEP_2_SELECT_TEXT = `
  Check one or more files to move, copy or delete.
`;

export const FILES_PAGE_NUX_STEP_2_MORE_TEXT = `
  Click the More button to see file details and versions.
`;

export const FILES_PAGE_NUX_STEP_3_MOVE_TEXT = `
  The Copy, Move and Delete buttons will only appear
  when you've selected one or more items. Deselect
  all items to make these buttons disappear
`;

export const FILES_NUX_DUMMY_DATA = [
  {
    name:'my_file.png',
    type:'image',
    lastUpdated:DateTime.local().minus({ hour: 1}).toMillis(),
    size: 100000,
  }
];

export const FORMS_ADD_DETAIL_TYPE = 'FORMS_ADD_DETAILS_TYPE';
export const FORMS_ADD_FIELDS_TYPE = 'FORMS_ADD_FIELDS_TYPE';
export const FORMS_ADD_DRAWER_TYPE = 'FORMS_ADD_DRAWER_TYPE';
export const FORMS_ADD_WORKFLOW_TYPE = 'FORMS_ADD_WORKFLOW_TYPE';

export const FORMS_ADD_NUX_STEP_1 = 'FORMS_ADD_STEP_1';

export const FORMS_ADD_FIELDS_STEP_PREFIX = 'FORMS_ADD_FIELD_STEP';
export const FORMS_ADD_FIELDS_STEP_1 = `${FORMS_ADD_FIELDS_STEP_PREFIX}1`;
export const FORMS_ADD_FIELDS_STEP_2 = `${FORMS_ADD_FIELDS_STEP_PREFIX}2`;
export const FORMS_ADD_FIELDS_STEP_3 = `${FORMS_ADD_FIELDS_STEP_PREFIX}3`;
export const FORMS_ADD_FIELDS_STEP_4 = `${FORMS_ADD_FIELDS_STEP_PREFIX}4`;

export const FORMS_ADD_NUX_STEP_1_CHECK_TEXT = `
  Check these boxes to select which data is automatically added by Ontraccr to the form
`;

export const FORMS_ADD_NUX_STEP_1_SWITCH_TEXT = `
  Use this switch to set whether a user can override data automatically added by Ontraccr.
`;

export const FORMS_ADD_FIELDS_STEP_1_SECTION_BUTTON_TEXT = `
  Click this button to create a new section in your form.
`;

export const FORMS_ADD_FIELDS_STEP_3_SECTION_BUTTON_TEXT = (
  <div>
    Click the <CloseOutlined/> button or press <b>Escape</b> to cancel adding a section.
    <br/><br/>
    Click the <CheckOutlined/> button or press <b>Enter</b> to save your new section.
  </div>
);

export const FORMS_ADD_FIELDS_STEP_4_SECTION_DELETE_BUTTON_TEXT = (
  <div>
    Rename or Delete a Section here.
    <br/><br/>
    <b>Be careful, deleting a section will also delete all its fields.</b>
    <br/><br/>
    You can also drag and drop sections and fields to reorder them.
  </div>
);

export const FORMS_ADD_FIELDS_STEP_4_FIELD_ADD_TEXT = `
  Click here to add a field to a section.
`;


export const FORMS_ADD_DRAWER_STEP_PREFIX = 'FORMS_ADD_DRAWER_STEP';
export const FORMS_ADD_DRAWER_STEP_1 = `${FORMS_ADD_DRAWER_STEP_PREFIX}1`;
export const FORMS_ADD_DRAWER_STEP_2 = `${FORMS_ADD_DRAWER_STEP_PREFIX}2`;
export const FORMS_ADD_DRAWER_STEP_3 = `${FORMS_ADD_DRAWER_STEP_PREFIX}3`;
export const FORMS_ADD_DRAWER_STEP_4 = `${FORMS_ADD_DRAWER_STEP_PREFIX}4`;

export const FORMS_ADD_DRAWER_STEP_1_TEXT = `
  Use this dropdown menu to select the type of field you want to add to your form.
`;

export const FORMS_ADD_DRAWER_STEP_2_TEXT = `
  Use this section to configure your field.
`;

export const FORMS_ADD_DRAWER_STEP_3_TEXT = (
  <div>
    The Preview section shows you how this field will function
    <br/><br/>
    You can test the changes you make in the Configure section here.
  </div>
);

export const FORMS_WORKFLOW_STEP_PREFIX = 'FORMS_WORKFLOW_STEP';
export const FORMS_WORKFLOW_STEP_1 = `${FORMS_WORKFLOW_STEP_PREFIX}1`;
export const FORMS_WORKFLOW_STEP_2 = `${FORMS_WORKFLOW_STEP_PREFIX}2`;
export const FORMS_WORKFLOW_STEP_3 = `${FORMS_WORKFLOW_STEP_PREFIX}3`;
export const FORMS_WORKFLOW_STEP_4 = `${FORMS_WORKFLOW_STEP_PREFIX}4`;

export const FORMS_WORKFLOW_STEP_1_TEXT = `
  Drag and drop a block from here to add a step to your workflow.
`;

export const FORMS_WORKFLOW_STEP_2_TEXT = (
  <div>
    The Trigger node allows you to configure how the workflow is triggered.
    <br/><br/>
    A workflow being triggered allows the assigned users to start filling out the form.
    <br/><br/>
    Leave the <b>Manual</b> option checked if you want to allow users to manually start a workflow.
  </div>
);

export const FORMS_WORKFLOW_STEP_3_TEXT = (
  <div>
    This step happens after a user has submitted a completed form.
    <br/><br/>
    Use this node as the starting point for adding workflow steps.
  </div>
);

export const FORMS_WORKFLOW_STEP_TARGET_TEXT = (
  <div>
    This black circle with an <ArrowDownOutlined /> pointing <b>into</b> the node
    means this node accepts <b>incoming connections</b>
    <br/><br/>
    Click and drag from another node to connect into this node
  </div>
);

export const FORMS_WORKFLOW_STEP_SOURCE_TEXT = (
  <div>
  This black circle with an <ArrowDownOutlined /> pointing <b>out</b> of the node
  means this node can have <b>outgoing connections</b>
  <br/><br/>
  Click and drag from this node to another node to connect another step
</div>
);

export const NEW_MENU_TYPE = 'NEW_MENU';
export const NEW_MENU_NUX_STEP_1 = 'NEW_MENU_NUX_STEP_1';
export const NEW_MENU_TYPE_TEXT = `
The Support, App Feedback, Get Started, Profile and Logout menu items have moved up here.
`;

export const TUTORIAL_WATCH_PREFIX = 'TUTORIAL_WATCH_';

export default {};
