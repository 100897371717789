import React, {
  useState,
  useEffect,
  useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import { Tabs, Row } from 'antd';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import ActionCenter from './ActionCenter';

import TabsSettings from './Tabs';
import JobCosting from './JobCosting';
import Subtasks from './Subtasks';
import ScheduleOfValuesTemplates from './ScheduleOfValuesTemplates';
import ProjectFileTemplates from './ProjectFileTemplates';
import Permissions from '../../auth/Permissions';
import Boards from './Boards';
import ProfileNames from './ProfileNames';
import TypesTabView from './TypesTabView';
import CustomFields from './CustomFields';
import BucketTemplateTabView from './BucketTemplateTabView';

const tabStyle = { paddingTop: 7, overflowY: 'auto', height: '90vh' };
const { TabPane } = Tabs;

function CustomizationSettings({
  activeKey,
  acPositions,
  positions,
  onCheckChanged,
  onOrderChanged,
}) {
  const { t } = useTranslation();
  const [stateKey, setStateKey] = useState(activeKey);
  const tabs = useSelector((state) => state.settings.tabs);

  const hasFilesTab = useMemo(() => tabs.some((tab) => tab.tabName === 'Files' && tab.enabled), [tabs]);
  const hasContractTab = useMemo(() => tabs.some((tab) => tab.tabName === 'Contract' && tab.enabled), [tabs]);
  const hasJobCostingTab = useMemo(() => tabs.some(
    (tab) => (tab.tabName === 'Cost Codes' || tab.tabName === 'Budget Tracking') && tab.enabled,
  ), [tabs]);

  useEffect(() => {
    setStateKey(activeKey);
  }, [activeKey]);

  const hasScheduleOfValuesWritePermission = Permissions.has('PROJECT_SCHEDULEOFVALUES_WRITE');
  const hasTranslationWritePermission = Permissions.has('TRANSLATIONS_WRITE');
  const hasProjectWritePermission = Permissions.has('PROJECTS_WRITE');
  const hasBucketsWritePermission = Permissions.has('BUCKETS_WRITE');

  return (
    <Row justify="start" style={{ height: '90%' }}>
      <Tabs
        defaultActiveKey={activeKey}
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 135,
          marginRight: 17,
        }}
        style={{ width: '100%' }}
        activeKey={stateKey}
        onChange={setStateKey}
      >
        <TabPane style={{ ...tabStyle, paddingTop: 0 }} tab="Tabs" key="tabsSettings">
          <TabsSettings />
        </TabPane>
        { hasJobCostingTab && (
          <TabPane style={tabStyle} tab="Job Costing" key="jobCosting">
            <JobCosting />
          </TabPane>
        )}
        <TabPane style={tabStyle} tab="Task Templates" key="taskTemplates">
          <Subtasks />
        </TabPane>
        { hasScheduleOfValuesWritePermission && hasContractTab && (
          <TabPane style={tabStyle} tab={`${t('Project')} Contract`} key="scheduleOfValues">
            <ScheduleOfValuesTemplates />
          </TabPane>
        )}
        <TabPane style={tabStyle} tab="Boards" key="boards">
          <Boards />
        </TabPane>
        { hasFilesTab && (
          <TabPane style={tabStyle} tab={`${t('Project')} Files`} key="projectTemplates">
            <ProjectFileTemplates />
          </TabPane>
        )}
        { hasProjectWritePermission && (
          <TabPane style={tabStyle} tab="Types" key="types">
            <TypesTabView />
          </TabPane>
        )}
        { hasTranslationWritePermission && (
          <TabPane style={tabStyle} tab="Profile Names" key="profileNames">
            <ProfileNames />
          </TabPane>
        )}
        <TabPane style={tabStyle} tab="Custom Fields" key="customFields">
          <CustomFields />
        </TabPane>
        <TabPane style={tabStyle} tab="Action Center" key="actionCenter">
          <ActionCenter
            positions={positions}
            acPositions={acPositions}
            onCheckChanged={onCheckChanged}
            onOrderChanged={onOrderChanged}
          />
        </TabPane>
        { hasBucketsWritePermission && (
          <TabPane style={tabStyle} tab="Buckets" key="bucketTemplates">
            <BucketTemplateTabView />
          </TabPane>
        )}
      </Tabs>
    </Row>
  );
}

CustomizationSettings.propTypes = {
  settings: PropTypes.shape({
  }),
  activeKey: PropTypes.string,
  acPositions: PropTypes.object,
  positions: PropTypes.object,
  onCheckChanged: PropTypes.func,
  onOrderChanged: PropTypes.func,

};
CustomizationSettings.defaultProps = {
  settings: {},
  activeKey: 'tabsSettings',
};

export default CustomizationSettings;
