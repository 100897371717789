import React, { useCallback } from 'react';
import { Form, Tabs, Checkbox, Row, Col } from 'antd';

import PDFDesignerLinkPicker from './PDFDesignerLinkPicker';
import PDFDesignerPositionPicker from './PDFDesignerPositionPicker';
import PDFDesignerColorPicker from './PDFDesignerColorPicker';
import PDFDesignerBorderPicker from './PDFDesignerBorderPicker';
import PDFDesignerPagePicker from './PDFDesignerPagePicker';
import PDFDesignerFontPicker from './PDFDesignerFontPicker';
import PDFDesignerColumns from './PDFDesignerColumns';
import PDFDesignerTableHeightsPickers from './PDFDesignerTableHeightsPickers';

import { defaultDrawOptions } from './PDFDesigner.constants';

import HoverHelp from '../../HoverHelp';

const formStyle = { marginBottom: 5 };

const helpData = (
  <div style={{ width: 250 }}>
    When this box is checked, the table will not be displayed if no data is entered
  </div>
);

export default ({
  onDrawOptionChanged,
  page,
  numberOfPages,
  onPageChanged,
  onLinkChanged,
  field:{
    drawOptions = defaultDrawOptions,
    link = {},
  } = {},
  sections = [],
  collected = {},
}) => {
const {
  backgroundColor,
  headerOptions = {},
  hideEmpty,
} = drawOptions;

const {
  backgroundColor: headerBackgroundColor,
} = headerOptions;

const onHeaderOptionsChanged = useCallback((newOptions = {}) => {
  onDrawOptionChanged({
    ...drawOptions,
    headerOptions: {
      ...headerOptions,
      ...newOptions,
    },
  });
},[drawOptions, headerOptions]);

const onCheckChange = useCallback((e) => {
  const {
    target: {
      checked,
    } =  {},
  } = e;
  onDrawOptionChanged({
    ...drawOptions,
    hideEmpty: checked,
  });
},[drawOptions]);

return (
  <Form layout='vertical' style={{ marginTop: 10, width:'100%' }}>
    <Tabs>
      <Tabs.TabPane tab='General' key='general'>
        <Row justify='start' align='middle' gutter={10} style={formStyle}>
          <Col>
            <Checkbox onChange={onCheckChange} checked={hideEmpty}>Hide if empty</Checkbox>
          </Col>
          <Col>
            <HoverHelp content={helpData} />
          </Col>
        </Row>
        <PDFDesignerLinkPicker
          link={link}
          sections={sections}
          collected={collected}
          onLinkChanged={onLinkChanged}
          style={formStyle}
          isTable
        />
        <PDFDesignerPositionPicker
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
          style={formStyle}
          isTable
        />
        <PDFDesignerTableHeightsPickers
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
          style={formStyle}
        />
        <PDFDesignerPagePicker
          page={page}
          numberOfPages={numberOfPages}
          onPageChanged={onPageChanged}
          style={formStyle}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Header' key='header'>
        <PDFDesignerColorPicker
          style={formStyle}
          title='Background Color'
          value={headerBackgroundColor}
          onColorChanged={(newColor) => onDrawOptionChanged({
            headerOptions: {
              ...headerOptions,
              backgroundColor: newColor,
            },
          })}
        />
        <PDFDesignerFontPicker
          drawOptions={headerOptions}
          onDrawOptionChanged={onHeaderOptionsChanged}
          showFontScaleSelector
        />
        <PDFDesignerBorderPicker
          drawOptions={headerOptions}
          onDrawOptionChanged={onHeaderOptionsChanged}
          style={formStyle}
        />
      </Tabs.TabPane>
      <Tabs.TabPane tab='Columns' key='Columns'>
        <PDFDesignerColorPicker
          style={formStyle}
          title='Background Color'
          value={backgroundColor}
          onColorChanged={(newColor) => onDrawOptionChanged({ backgroundColor: newColor })}
        />
        <PDFDesignerColumns
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
        />
        <PDFDesignerBorderPicker
          drawOptions={drawOptions}
          onDrawOptionChanged={onDrawOptionChanged}
          style={formStyle}
        />
      </Tabs.TabPane>
    </Tabs>
  </Form>
  );
}