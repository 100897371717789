import React from 'react';
import { connect } from 'react-redux';

import {
  archiveCostCode,
  updateCostCode,
  createCostCodes,
  deleteCostCodeById,
} from './state/costcodes.actions';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import CardGrid from '../common/cardGrid/cardGrid';
import CostCodeAddView from './CostCodeAddView';
import CostCodeEditView from './CostCodeEditView';
import CostCodeCard from  './costcodeCard';

import Permissions from '../auth/Permissions';

import { withRouter } from 'react-router-dom';

import Analytics from '../helpers/Analytics';
import { getHighestNumber } from '../helpers/helpers';

import { createNuxEntry } from '../nux/state/nux.actions';
import { COSTCODE_GLOBAL_TYPE } from '../nux/nux.constants';
import { formatCustomFieldCreatePayload, getCostcodeUpdatePayload } from '../helpers/costcodeHelpers';

const isGlobal = ({ projectId = '' }) => !projectId;

export const columnsToDisplay = [
  {
    title: 'Cost Code',
    dataIndex: 'code',
    key: 'code',
    width: 100,
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    width: 100,
  },
  {
    title: 'Description',
    dataIndex: 'description',
    key: 'description',
    width: 200,
  },
  {
    title: 'Division',
    dataIndex: 'division',
    key: 'division',
    width: 100,
  },
  {
    title: 'Category',
    dataIndex: 'category',
    key: 'category',
    width: 100,
  },
]
const filterCodes = ({ costcodes = [], selectedDivisionIds = new Set() }) => (
  costcodes.filter((costcode) => {
    const {
      divisionId = '',
    } = costcode;

    return isGlobal(costcode) && selectedDivisionIds.has(divisionId);
  })
);

export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      selectedDivisionIds: state.settings.selectedDivisions,
      nux: state.nux.nux,
      company: state.settings.company,
    };
  }, {
    archiveCostCode,
    createCostCodes,
    updateCostCode,
    deleteCostCodeById,
    createNuxEntry,
  },
)(withRouter(class CostCodesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      selectedDivisionIds: new Set(),
      showAddModal: false,
      globalCodes: [],
      isListView: window.localStorage.getItem('costCodeViewType') === 'true',
      errors: {},
      customFields: [],
    };
    this.onExportCSV = this.exportCSV.bind(this);
    this.onExportExcel = this.exportExcel.bind(this);

    this.onAdd = this.add.bind(this);
    this.onUpdate = this.update.bind(this);
    this.onArchive = this.archive.bind(this);
    this.onDelete = this.delete.bind(this);
  }

  componentDidMount() {
    const {
      createNuxEntry,
      setBreadcrumb,
      nux = new Set(),
      location: {
        state: {
          targetId: locationTargetId,
        } = {},
      } = {},
      selectedDivisionIds = new Set(),
      costcodes = [],
    } = this.props;

    this.setState({
      selectedDivisionIds,
      globalCodes: filterCodes({ costcodes, selectedDivisionIds }),
    });

    setBreadcrumb([{
      text: 'Cost Codes',
      icon: 'global',
    }, {
      text: 'Global Codes',
    }]);
    if (!nux.has(COSTCODE_GLOBAL_TYPE)) {
      createNuxEntry(COSTCODE_GLOBAL_TYPE);
    }
    if (this.cardRef && locationTargetId) {
      this.cardRef.targetItem = { id: locationTargetId };
    }
  }

  componentDidUpdate(prevProps) {
    const {
      selectedDivisionIds = new Set(),
      costcodes = [],
    } = this.props;

    const {
      selectedDivisionIds: prevSelectedDivisionIds = new Set(),
      costcodes: prevCostcodes = [],
    } = prevProps;

    if (
      prevSelectedDivisionIds !== selectedDivisionIds
      || prevCostcodes !== costcodes
    ) {
      this.setState({
        selectedDivisionIds,
      });

      this.setState({
        globalCodes: filterCodes({ costcodes, selectedDivisionIds }),
      })
    }
  }

  onItemClicked(user) {
    this.props.history.push(`/users/${user.id}`);
    this.props.onUserClicked(user);
  }

  add(values)  {
    Analytics.track('Costcode/Create/Global');
    // Spreadsheet upload
    if (Array.isArray(values)) return this.props.createCostCodes(values);
    const { customFields } = this.state;
    const payload = formatCustomFieldCreatePayload({
      payload: values,
      customFields,
    });

    if (payload?.error || !payload) {
      return this.setState({
        errors: payload?.errorMap,
      });
    }

    this.setState({
      errors: {},
    });

    return this.props.createCostCodes(payload);
  }

  async update(id, payload) {
    const {
      customFields,
      globalCodes: costcodes,
    } = this.state;

    const { updateCostCode } = this.props;

    const res = await getCostcodeUpdatePayload({
      id,
      payload,
      customFields,
      costcodes,
    });

    if (res?.error || !res) {
      return this.setState({
        errors: res?.errorMap,
      });
    }

    this.setState({
      errors: {},
    });

    return updateCostCode(id, res);
  }

  archive(costcode) {
    return new Promise((resolve) => {
      const archiveFunc = this.props.archiveCostCode;
      const mode = costcode.active ? 'Archive' : 'Activate';

      CustomConfirmModal({
        title: `${mode} Cost Code ${costcode.code}- ${costcode.name}?`,
        okText: mode,
        cancelText: 'Cancel',
        async onOk() {
          resolve(archiveFunc(costcode.id, !costcode.active));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  delete(costcode) {
    const {
      deleteCostCodeById,
    } = this.props;

    return new Promise((resolve) => {
      CustomConfirmModal({
        title: `Delete Cost Code ${costcode.code}- ${costcode.name}?`,
        okText: 'Delete',
        cancelText: 'Cancel',
        async onOk() {
          resolve(deleteCostCodeById(costcode.id));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  export(type) {
    const {
      globalCodes,
    } = this.state;
    this.props.onExport(globalCodes, type);
  }

  exportCSV() {
    this.export('csv');
  }

  exportExcel() {
    this.export('xlsx');
  }

  onViewTypeChanged(checked) {
    window.localStorage.setItem('costCodeViewType', checked);
    this.setState({ isListView: checked });
  }

  render() {
    const {
      company: {
        settings: {
          suggestNumbers,
        } = {},
      } = {},
    } = this.props;
    const {
      globalCodes = [],
      errors = {},
      customFields = [],
    } = this.state;

    const initialCode = suggestNumbers ? (getHighestNumber(globalCodes, 'code') + 1).toString() : null;

    const addView = (form) => (
      <CostCodeAddView
        {
          ...{
            ...form,
            initialCode: initialCode,
          }
        }
        errors={errors}
        customFields={customFields}
        setCustomFields={(fields) => this.setState({ customFields: fields })}
      />
    );

    const editView = (form, formProps) => (
      <CostCodeEditView
        form={form}
        formProps={formProps}
        isProjectSpecific
        errors={errors}
        customFields={customFields}
        setCustomFields={(fields) => this.setState({ customFields: fields })}
      />
    );

    return (
      <CardGrid
        onRef={(ref) => this.cardRef = ref}
        dataSource={globalCodes}
        onItemClicked={this.onItemClicked.bind(this)}
        itemView={CostCodeCard}
        itemDimensions={{ width: 239, height: 190 }}
        colCount={4}
        pageSize={12}
        add={Permissions.has('COSTCODES_WRITE') ? {
          title: 'Enter Cost Code Details',
          formView: addView,
          width: 'fit-content',
          onClose: this.onAdd,
        } : null}
        edit={{
          title: 'Edit Cost Code Details',
          formView: editView,
          width: 800,
          onClose: this.onUpdate,
          canEdit: () => Permissions.has('COSTCODES_WRITE'),
          canArchive: () => Permissions.has('COSTCODES_WRITE'),
          canDelete: () => Permissions.has('COSTCODES_WRITE'),

        }}
        onArchive={this.onArchive}
        onDelete={this.onDelete}
        search={this.props.search}
        more={this.props.more({
          onExportExcel: this.onExportExcel,
          onExportCSV: this.onExportCSV,
        })}
        sort={this.props.sort}
        filter={this.props.filter}
        filterActive={this.props.filterIsActive}
        onToggleListViewSwitch={true}
        isListView={this.state.isListView}
        onViewTypeChanged={this.onViewTypeChanged.bind(this)}
        displayColumns={columnsToDisplay}
      />
    );
  }
}));
