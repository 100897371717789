import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  PROJECT_GENERAL_TYPE,
  PROJECT_COSTCODE_TYPE,
  PROJECT_BUDGET_TYPE
} from '../nux.constants';

import NuxTabPane from '../NuxTabPane';

import NuxProjectInfo from './NuxProjectInfo';
import NuxAddingCostcodes from './NuxAddingCostcodes';
import NuxBudgetTracking from './NuxBudgetTracking';

import projectInfo from '../../assets/images/NUX/projectInfo.svg';
import addingCostcodes from '../../assets/images/NUX/addingCostcodes.svg';
import budgetTracking from '../../assets/images/NUX/budgetTracking.svg';

import Analytics from '../../helpers/Analytics';

const getInfoTitle = (t) => `Adding ${t('Project')} Information`;
const costcodeTitle = 'Adding Cost Codes';
const budgetTitle = 'Tracking Budget';

export default function NuxProjects({
  setDetailView,
  setTitle,
  setDescription,
  resetToDefault,
  history,
  onCloseClicked,
  nux = new Set(),
}) {
  const { t } = useTranslation();
  const infoTitle = getInfoTitle(t);

  return (
    <NuxTabPane
      setDetailView={setDetailView}
      setTitle={setTitle}
      setDescription={setDescription}
      buttons={[
        {
          title: infoTitle,
          description:`Start creating a ${t('Project').toLowerCase()} on Ontraccr by adding important information about the project:`,
          complete:nux.has(PROJECT_GENERAL_TYPE),
          src:projectInfo,
          detailView:(
            <NuxProjectInfo
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: infoTitle });
                history.push('/projects');
                onCloseClicked();
              }}
          />
          ),
        },
        {
          title: costcodeTitle,
          description:`
            Although this step is optional,
            we highly recommend adding cost codes to your ${t('Project', { count: 2 }).toLowerCase()}
            to track your jobs effectively and gain complete visibility into your spend!
          `,
          complete:nux.has(PROJECT_COSTCODE_TYPE),
          src:addingCostcodes,
          detailView:(
            <NuxAddingCostcodes
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: costcodeTitle });
                history.push('/projects');
                onCloseClicked();
              }}
            />
          ),
        },
        {
          title: budgetTitle,
          description:(
            <div style={{ fontSize: 14 }}>
              By adding your estimated hours and allocating them to the cost codes from step 2,
              you will be able to ensure that your {t('Project').toLowerCase()} stays within budget and
              also gain the ability to forecast any cost overrun before it is too late.
            </div>
          ),
          complete:nux.has(PROJECT_BUDGET_TYPE),
          src:budgetTracking,
          detailView:(
            <NuxBudgetTracking
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: budgetTitle });
                history.push('/projects');
                onCloseClicked();
              }}
            />
          ),
        }
      ]}
    />
  );
}
