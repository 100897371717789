import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Divider } from 'antd';

import FilteredUserSelector from '../../common/inputs/FilteredUserSelector';

import SettingsCardHeader from '../SettingsCardHeader';
import CompanySettingsCard from '../CompanySettingsCard';
import CompanyEditRow from '../CompanyEditRow';

import Debouncer from '../../helpers/Debouncer';

import { updateClientPortalSettings } from '../../clientPortal/state/clientPortal.actions';
import { getIdMap } from '../../helpers/helpers';

import Colors from '../../constants/Colors';

const filterEmails = (emails = [], userMap = {}) => (
  emails.filter((id) => {
    const { 
      [id]: {
        active,
      } = {},
    } = userMap;
    return active;
  })
);

const debouncer = new Debouncer();
export default () => {
  const dispatch = useDispatch();
  const portalSettings = useSelector(state => state.clientPortal.settings);
  const users = useSelector(state => state.users.users);
  
  const activeUsers = useMemo(() => users.filter((user) => user.active),[users]);
  const userMap = useMemo(() => getIdMap(users),[users]);

  const {
    sendOutgoingEmails,
    incomingEmails = [],
    communicators = [],
  } = portalSettings;

  const [selected, setSelected] = useState(filterEmails(incomingEmails,userMap));
  const [selectedComms, setSelectedComms] = useState(filterEmails(communicators,userMap))

  const onChange = useCallback((key) => async (newValues) => {
    const previous = [...selected];
    setSelected(newValues);
    const passed = await debouncer.debounce(() => (
      dispatch(updateClientPortalSettings({
        ...portalSettings,
        [key]: newValues
      }))
    ), 500);
    if (!passed) setSelected(previous);
  },[dispatch, portalSettings]);

  const onCheckChanged = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    dispatch(updateClientPortalSettings({
      ...portalSettings,
      sendOutgoingEmails: checked
    }))
  },[dispatch, portalSettings]);

  useEffect(() => {
    setSelected(filterEmails(portalSettings.incomingEmails ?? [],userMap));
    setSelectedComms(filterEmails(portalSettings.communicators ?? [],userMap));
  },[portalSettings, userMap]);

  return (
    <div style={{ marginTop: 10 }}>
      <SettingsCardHeader title='Communication' />
      <CompanySettingsCard>
        <CompanyEditRow
          title='Communicators'
          helpText='Choose users that are able to send messages to your clients.'
          style={{ paddingLeft: 10, paddingRight: 20 }}
          divider={false}
        />
        <div id='client-portal-profile-fields-select'>
          <FilteredUserSelector
            onChange={onChange('communicators')}
            users={activeUsers}
            value={selectedComms}
          />
        </div>
        <Divider style={{
           margin:0,
           backgroundColor:Colors.ONTRACCR_OPACITY_GRAY,
           maxWidth:440,
           minWidth:440,
        }}/>
        <CompanyEditRow
          title='Outgoing Messages'
          helpText='Check this box if you want your clients to receive email notifications when you send them messages.'
          style={{ paddingLeft: 10, paddingRight: 20 }}
          divider
        >
          <Checkbox checked={sendOutgoingEmails} onChange={onCheckChanged}/>
        </CompanyEditRow>
        <CompanyEditRow
          title='Incoming Messages'
          helpText='Choose users that will receive email notifications when clients message you from their portal.'
          style={{ paddingLeft: 10, paddingRight: 20 }}
          divider={false}
        />
        <div id='client-portal-profile-fields-select'>
          <FilteredUserSelector
            onChange={onChange('incomingEmails')}
            users={activeUsers}
            value={selected}
          />
        </div>
      </CompanySettingsCard>
    </div>
  );
}