import React, { useState, useCallback, useEffect } from 'react';
import { Row, Col } from 'antd';

import CustomModal from '../common/modals/CustomModal';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import colors from '../constants/Colors';

import { pinValidator } from '../helpers/validators';

export default ({
  onClose,
  onSubmit,
  visible,
  title,
  isLocked,
}) => {
  const [text,setText] = useState();
  const [error, setError] = useState();
  const onChange = useCallback(async (e) => {
    const {
      target:{
        value,
      } = {}
    } = e;
    setText(value);
    try {
      await pinValidator(null,value);
      setError();
    } catch (err) {
      setError(err.message);
    }
  },[]);
  const onCancel = useCallback(() => {
    onClose();
  },[onClose]);

  const onSubmitClicked = useCallback(async () => {
    onSubmit(text);
  },[onSubmit,text]);

  useEffect(() => {
    if (!visible) {
      setText();
      setError();
    }
  },[visible]);

  const prefix = isLocked ? 'Unlock' : 'Lock';
  const fullTitle = `${prefix} ${title}?`
  return (
    <CustomModal
      title={fullTitle}
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
    >
      <div style={{ fontSize:14 }}>
        Please enter the lock PIN for this board
      </div>
      <Row style={{ margin: '20px 5px'}}>
        <OnTraccrTextInput
          password
          autoComplete='new-password'
          placeholder='Enter your password'
          onChange={onChange}
          value={text}
        />
      </Row>
      <div style={{ color: 'red', height: 30, fontSize: 12 }}>
        {error}
      </div>
    <Row justify='center' gutter={12} style={{ marginBottom: 10 }}>
      <Col>
        <OnTraccrButton
          type='cancel'
          title='Cancel'
          roundStyle
          onClick={onCancel}
        />
      </Col>
      <Col>
        <OnTraccrButton
          title={prefix}
          roundStyle
          disabled={!text || error}
          onClick={onSubmitClicked}
        />
      </Col>
    </Row>
    </CustomModal>
  );
}