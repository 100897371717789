import React, { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  QuestionOutlined,
  DesktopOutlined,
  MobileOutlined,
  QuestionCircleOutlined,
  MessageOutlined,
  LikeOutlined,
  PlayCircleOutlined,
 } from '@ant-design/icons';
import { Popover, Divider } from 'antd';

import config from '../../config';

import {
  GET_STARTED_CLOSE_NUX_STEP_1,
  GET_STARTED_CLOSE_NUX_STEP_1_TEXT
} from '../../nux/nux.constants';
import NuxPopover from '../../nux/NuxPopover';

import DrowdownRow from './DropdownRow';

import AppFeedback from '../../feedback/AppFeedback';

import SupportService from '../../support/state/support.service';
import { setMenu } from '../../menu/state/menu.actions';

import Analytics from '../../helpers/Analytics';
 
export default {};

const PORTAL_URL = 'https://projectharbour.atlassian.net/servicedesk/customer/portals';

export const HelpDropdown = ({ visible, iconStyle }) => {
  const activeNuxAction = useSelector(state => state.nux.activeNuxAction);
  const [showPopover,setShowPopover] = useState(false);
  const closePopover = useCallback(() => setShowPopover(false),[]);
  return (
    <Popover 
      {...visible ? {visible, } : {}} 
      content={<HelpMenu closePopover={closePopover}/>} 
      trigger='click' 
      placement='bottomRight'
      visible={showPopover || activeNuxAction === GET_STARTED_CLOSE_NUX_STEP_1}
      onVisibleChange={(visible) => {
        setShowPopover(visible);
        if(visible) {
          Analytics.track('HelpMenu/Hover')
        }
      }}
    >
      <div className='header-container' style={iconStyle}>
        <QuestionOutlined style={{ fontSize: 16 }}/>
      </div>

    </Popover>
  );
};



export const HelpMenu = ({ closePopover }) => {
  const dispatch = useDispatch();
  const selectedKeys = useSelector(state => state.menu.items);
  const activeNuxAction = useSelector(state => state.nux.activeNuxAction);

  const [showKey,setShowKey] = useState(); 

  const getGuide = useCallback((type) => () => {
    closePopover();
    SupportService.getGuide(type)
  },[closePopover]);

  const openNux = useCallback(() => {
    closePopover();
    Analytics.track('NUX/Open',{ Source: 'Help Menu' });
    dispatch(setMenu(selectedKeys.concat(['getStarted'])));
  },[dispatch,closePopover, selectedKeys]);

  const openLearningCenter = useCallback(() => {
    closePopover();
    dispatch(setMenu(selectedKeys.concat(['learningCenter'])));
  },[dispatch,closePopover, selectedKeys])

  const openModal = useCallback((key) => () => {
    closePopover();
    setShowKey(key);
  },[closePopover]);
  const closeModals = useCallback(() => setShowKey(),[]);
  
  return (
    <div>
      { !config.showLearningCenter && 
        <div>
          <DrowdownRow
            title='Web App Guide'
            Icon={DesktopOutlined}
            onClick={getGuide('Web')}
          />
          <Divider className='header-dropdown-divider'/>
          <DrowdownRow
            title='Mobile App Guide'
            Icon={MobileOutlined}
            onClick={getGuide('Mobile')}
          />
          <Divider className='header-dropdown-divider'/>
        </div>
      }
      { config.showLearningCenter && 
        <div>
          <DrowdownRow
            title='Learning Center'
            Icon={PlayCircleOutlined}
            onClick={openLearningCenter}
          />
          <Divider className='header-dropdown-divider'/>
        </div>
      }
      <NuxPopover
        visible={activeNuxAction === GET_STARTED_CLOSE_NUX_STEP_1}
        text={GET_STARTED_CLOSE_NUX_STEP_1_TEXT}
        placement='left'>
          <div>
          <DrowdownRow
            title='Getting Started'
            Icon={QuestionCircleOutlined}
            onClick={openNux}
            style={{
              pointerEvents:activeNuxAction === GET_STARTED_CLOSE_NUX_STEP_1 ? 'none' : 'auto',
            }}
          />
          </div>
      </NuxPopover>
      <Divider className='header-dropdown-divider'/>
      <DrowdownRow
        title='Support'
        Icon={MessageOutlined}
        onClick={() => {
          closePopover();
          Analytics.track('OpenCustomerSupportPortal/Web');
          window.open(PORTAL_URL, "_blank", "noopener");
        }}
      />
      <Divider className='header-dropdown-divider'/>
      <DrowdownRow
        title='App Feedback'
        Icon={LikeOutlined}
        onClick={openModal('feedback')}
      />
      <AppFeedback
        visible={showKey === 'feedback'}
        onCloseClicked={closeModals}
      />
    </div>
  )
};