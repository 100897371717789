import React from 'react';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import Colors from '../constants/Colors';

function ResubmitModal(callback, hasTriggeredChildren) {
  const openSubModal = () => {
    CustomConfirmModal({
      title: 'Preserve current draft data?',
      content: (
        <div>
          <div>
            <span>
              Workflow currently contains triggered forms with drafts.
            </span>
          </div>
          <div>
            <span>
              Click
              {' '}
              <b style={{ fontWeight: 1000, color: 'black' }}>Yes</b>
              {' '}
              to preserve the current draft data.
            </span>
          </div>
          <div>
            <span>
              Click
              {' '}
              <b style={{ fontWeight: 1000, color: 'black' }}>No</b>
              {' '}
              to discard the current draft data.
            </span>
          </div>
        </div>
      ),
      okText: 'Yes',
      cancelText: 'No',
      onCancel: () => callback({ dataOnly: false, clearData: true }),
      onOk: () => callback({ dataOnly: false, clearData: false }),
      cancelButtonStyle: {
        backgroundColor: 'white',
        color: Colors.ONTRACCR_RED,
        borderColor: Colors.ONTRACCR_RED,
      },
    });
  };

  CustomConfirmModal({
    title: 'Submit form?',
    content: (
      <div>
        <div>
          <span>
            Click
            {' '}
            <b style={{ fontWeight: 1000, color: 'black' }}>Submit</b>
            {' '}
            to re-trigger the workflow.
          </span>
        </div>
      </div>
    ),
    okText: 'Submit',
    cancelText: 'Cancel',
    onCancel: () => {},
    onOk: () => (hasTriggeredChildren ? openSubModal() : callback({ dataOnly: false })),
    cancelButtonStyle: {
      backgroundColor: 'white',
      color: Colors.ONTRACCR_RED,
      borderColor: Colors.ONTRACCR_RED,
    },
    zIndex: 1011,
  });
}

export default ResubmitModal;
