import { PDFDocument, StandardFonts, rgb, toDegrees } from 'pdf-lib';
import { DateTime } from 'luxon';
import pdfjsLib from 'pdfjs-dist/build/pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

import Colors from '../../constants/Colors';
import { PDF_SCALE } from './pdfAnnotationTypes.constants';

pdfjsLib.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default {};

export const rotateCoords = ({
  height,
  width,
  clientX,
  clientY,
  rotation,
}) => {
  let x;
  let y;
  switch (rotation) {
    case 90:
      x = clientY;
      y = height - clientX;
      break;
    case 180:
      x = width - clientX;
      y = height - clientY;
      break;
    case 270:
      x = width - clientY;
      y = clientX;
      break;
    case 0:
    default:
      x = clientX;
      y = clientY;
      break;
  }

  return { x, y };
};

const getCoords = ({
  rotation,
  canvas: {
    height,
    width,
  },
  point: {
    x: clientX,
    y: clientY,
  },
}) => rotateCoords({
  height,
  width,
  clientX,
  clientY,
  rotation,
});

export const getNewFileName = (file, name) => {
  const now = DateTime.local();
  const newName = !name
    ? `${file.name.replace('.pdf', '')}-annotated-${now.toLocaleString(DateTime.DATETIME_FULL_WITH_SECONDS)}.pdf`
    : name;

  return newName;
};

export const annotatePDF = async ({
  file,
  url,
  annotations = {},
  annotatedFileName,
}) => {
  if (!annotations) return;
  const annotationKeys = Object.keys(annotations);
  if (annotationKeys.length === 0) return;
  const blob = url
    ? await fetch(url).then((res) => res.arrayBuffer())
    : await file.arrayBuffer();
  const pdfDoc = await PDFDocument.load(blob);
  const courier = await pdfDoc.embedFont(StandardFonts.Courier)
  annotationKeys.forEach((page) => {
    const pdfPage = pdfDoc.getPage(parseInt(page, 10) - 1);
    const size = pdfPage.getSize();
    const {
      [page]: pageAnnotations = [],
    } = annotations;

    const rotate = pdfPage.getRotation();
    const rotation = toDegrees(rotate);

    pageAnnotations.forEach((stroke) => {
      const {
        color,
        opacity = 1,
        width = 2.5,
        points = [],
        type,
        text,
        fontSize,
      } = stroke;

      let rgbColor = Colors.hexToRGB(color);

      if (rgbColor) {
        rgbColor = rgb(
          rgbColor.r / 255,
          rgbColor.g / 255,
          rgbColor.b / 255,
        );
      }

      if (type === 'pencil' || type === 'highlight') {
        points.forEach((point, index) => {
          if (index === 0) return;
          const prevPoint = points[index - 1];
          const {
            x,
            y,
          } = getCoords({
            rotation,
            canvas: size,
            point,
          });
          const {
            x: prevX,
            y: prevY,
          } = getCoords({
            rotation,
            canvas: size,
            point: prevPoint,
          });

          pdfPage.drawLine({
            start: { x: prevX, y: size.height - prevY },
            end: { x, y: size.height - y },
            thickness: width,
            color: rgbColor,
            opacity,
          });
        });
      }

      if (type === 'text') {
        const coord = getCoords({
          rotation,
          canvas: size,
          point: points[0],
        });

        pdfPage.drawText(text, {
          font: courier,
          x: coord.x,
          y: size.height - coord.y,
          size: fontSize * PDF_SCALE,
          color: rgbColor,
          opacity,
          rotate,
        });
      }
    });
  });
  const raw = await pdfDoc.save();
  const newName = getNewFileName(file, annotatedFileName);
  return new File([raw], newName, { type: 'application/pdf' });
};

export const convertPDFtoJPEG = async (id, pdfFile) => {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  const PDF = await pdfjsLib.getDocument(URL.createObjectURL(pdfFile)).promise;
  const page = await PDF.getPage(1);
  const viewport = page.getViewport({ scale: 0.5 });
  canvas.height = viewport.height;
  canvas.width = viewport.width;
  canvas.style.height = viewport.height;
  canvas.style.width = viewport.width;

  await page.render({
    canvasContext: context,
    viewport,
  }).promise;
  const blob = await new Promise((resolve) => canvas.toBlob(resolve, 'image/jpeg', 0.5));
  return new File([blob], `${id}.jpeg`, { type: 'image/jpeg' });
};
