const contactFields = [
  {
    type: 'address',
    name: 'Billing Address',
    attr: 'billingAddress',
  },
  {
    type: 'address',
    name: 'Office Address',
    attr: 'officeAddress',
  },
  {
    type: 'email',
    name: 'Billing Email',
    attr: 'billingEmail',
  }, {
    type: 'email',
    name: 'Office Email',
    attr: 'officeEmail',
  }, {
    type: 'info',
    name: 'Info',
    attr: 'info',
  },
];

const ATTRIBUTE_CONFIG = (t) => ({
  Customer: {
    name: t('Customer'),
    fields: contactFields,
  },
  Contact: {
    name: 'Contact',
    fields: [
      {
        type: 'name',
        name: 'Name',
        attr: 'name',
      },
      {
        type: 'role',
        name: 'Role',
        attr: 'role',
      },
      {
        type: 'phoneNumber',
        name: 'Phone Number',
        attr: 'phoneNumber',
      },
      {
        type: 'email',
        name: 'Email',
        attr: 'email',
      },
    ],
  },
  Project: {
    name: t('Project'),
    fields: [
      {
        type: 'number',
        name: `${t('Project')} Number`,
        attr: 'number',
      },
      {
        type: 'address',
        name: `${t('Project')} Address`,
        attr: 'address',
      },
      {
        type: 'email',
        name: 'Billing Email',
        attr: 'billingContact',
      }, {
        type: 'info',
        name: 'Info',
        attr: 'info',
      }, {
        type: 'equipment',
        name: 'Equipment',
        attr: 'equipment',
      },
    ],
  },
  'Project Contract': {
    name: `${t('Project')} Contract`,
    fields: [
      {
        type: 'currency',
        name: 'Total Base Contract',
        attr: 'totalBaseContract',
      },
      {
        type: 'currency',
        name: 'Total Changes',
        attr: 'totalChanges',
      },
      {
        type: 'currency',
        name: 'Contract Total',
        attr: 'contractTotal',
      },
      {
        type: 'percentage',
        name: 'Total Completed to Date',
        attr: 'totalCompletedToDate',
      },
      {
        type: 'currency',
        name: 'Total Billed to Date',
        attr: 'totalBilledToDate',
      },
      {
        type: 'currency',
        name: 'Total this Billing',
        attr: 'invoiceAmount',
      },
      {
        type: 'percentage',
        name: 'Holdback Percentage',
        attr: 'holdbackPercentage',
      },
      {
        type: 'currency',
        name: 'Total Holdback to Date',
        attr: 'totalHoldbackToDate',
      },
    ],
  },
  Vendor: {
    name: 'Vendor',
    fields: contactFields,
  },
  Costcodes: {
    name: 'Costcodes',
    fields: [
      {
        type: 'currency',
        name: 'Hourly Wage',
        attr: 'hourlyWage',
      },
      {
        type: 'currency',
        name: 'Daily Wage',
        attr: 'dailyWage',
      },
      {
        type: 'currency',
        name: 'Wage Adjustment',
        attr: 'wageAdjustment',
      },
      {
        type: 'number',
        name: 'Wage Multiplier',
        attr: 'wageMultiplier',
      },
      {
        type: 'currency',
        name: 'Hourly Billing Rate',
        attr: 'hourlyBillingRate',
      },
      {
        type: 'currency',
        name: 'Daily Billing Rate',
        attr: 'dailyBillingRate',
      },
    ],
  },
  User: {
    name: 'User',
    fields: [
      {
        type: 'employeeId',
        name: 'Employee ID',
        attr: 'employeeId',
      },
      {
        type: 'email',
        name: 'Email',
        attr: 'email',
      },
      {
        type: 'phoneNumber',
        name: 'Phone Number',
        attr: 'phoneNumber',
      },
    ],
  },
  'Sub-Contract': {
    name: 'Sub-Contract',
    fields: [
      {
        type: 'currency',
        name: 'Contract Amount',
        attr: 'contractAmount',
      },
      {
        type: 'currency',
        name: 'Total Changes',
        attr: 'totalChanges',
      },
      {
        type: 'percentage',
        name: 'Percentage Complete',
        attr: 'percentageComplete',
      },
      {
        type: 'currency',
        name: 'Progress to Date',
        attr: 'progressToDate',
      },
      {
        type: 'currency',
        name: 'Previous Billings',
        attr: 'previousBillings',
      },
      {
        type: 'currency',
        name: 'Holdback Amount',
        attr: 'holdbackAmount',
      },
    ],
  },
  PayableSubContracts: {
    name: 'Sub-Contract',
    fields: [
      {
        type: 'currency',
        name: 'Contract Amount',
        attr: 'contractAmount',
      },
      {
        type: 'currency',
        name: 'Total Changes',
        attr: 'totalChanges',
      },
      {
        type: 'percentage',
        name: 'Percentage Complete',
        attr: 'percentageComplete',
      },
      {
        type: 'currency',
        name: 'Progress to Date',
        attr: 'progressToDate',
      },
    ],
  },
});

export default ATTRIBUTE_CONFIG;
