/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useMemo, useState } from 'react';
import { getIdMap } from 'ontraccr-common/lib/Common';
import { PropTypes } from 'prop-types';
import { Select, TreeSelect } from 'antd';
import { getDropDownOptions } from '../formFieldsHelpers';
import { getRelevantDropdownValues } from './TimeEntryTable.helpers';
import { filterSelectDropdown } from '../../../../helpers/helpers';

export default function TimeEntryTableDropdownInput(props) {
  const {
    task = {},
    configProps = {},
    value,
    onChange,
    responding,
    responses,
    customData,
    customDropdownProps = {},
  } = props;

  const {
    divisionId,
  } = task;

  const {
    dataType,
    openLimit,
    numAnswers,
  } = configProps;

  const {
    projectId,
  } = customDropdownProps;

  const [values, setValues] = useState([]);

  useEffect(() => {
    const parsedValues = !value.length && value ? value.map(({ id }) => id) : values;
    setValues(parsedValues);
  }, []);

  const actualSelectedSet = new Set(values ?? []);

  const interactionValues = useMemo(() => getRelevantDropdownValues(customData), [customData]);

  const { data } = useMemo(() => getDropDownOptions({
    // *** Base Inputs ***
    actualSelectedSet,
    configProps,
    divisionSet: new Set([divisionId]),
    responding,
    responses,
    ...customDropdownProps,
    ...interactionValues,
  }), [
    actualSelectedSet,
    configProps,
    divisionId,
    responding,
    responses,
    customDropdownProps,
  ]);

  const dataMap = useMemo(() => {
    if (!Object.keys(data).length) {
      return {};
    }

    const [{ selectable = true }] = data;

    if (!selectable) {
      const flattenedData = [];

      data.forEach(({ children }) => {
        children.forEach(({ title, value: dataValue }) => (
          flattenedData.push({ id: dataValue, name: title })
        ));
      });

      return getIdMap(flattenedData);
    }
    return getIdMap(data.map(({ id, name }) => ({ id, name })));
  }, [data]);

  const onSelectedChange = (newSelected) => {
    const selectedIds = Array.isArray(newSelected) ? newSelected : [newSelected];
    setValues(selectedIds);
    onChange(selectedIds.map((val) => dataMap[val]));
  };

  return dataType !== 'Costcodes' || !responding ? (
    <Select
      {...props}
      mode={numAnswers > 1 || openLimit ? 'multiple' : null}
      onChange={onSelectedChange}
      value={values}
      filterOption={filterSelectDropdown}
      showSearch
      allowClear
    >
      {data.map(({
        name,
        number,
        label,
        id,
      }) => (
        <Select.Option
          disabled={
                  !openLimit
                  && numAnswers > 1
                  && values.length >= numAnswers
                  && !actualSelectedSet.has(id)
                }
          value={id}
          key={id}
          label={label ?? name}
          name={name}
          number={number}
        >
          {label ?? name}
        </Select.Option>
      ))}
    </Select>
  ) : (
    <TreeSelect
      {...props}
      allowClear
      showSearch
      onChange={onSelectedChange}
      treeDefaultExpandAll={!projectId}
      treeNodeFilterProp="title"
      value={values}
      multiple={numAnswers > 1 || openLimit}
      treeData={data}
      style={{ width: '100%', margin: '0px 10px' }}
    />
  );
}

TimeEntryTableDropdownInput.propTypes = {
  task: PropTypes.shape({
    projectId: PropTypes.string,
  }).isRequired,
  configProps: PropTypes.shape({
    dataType: PropTypes.string,
    subDataType: PropTypes.string,
    customOptions: PropTypes.arrayOf(PropTypes.shape({})),
    openLimit: PropTypes.bool,
  }),
  customData: PropTypes.shape({}),
  customDropdownProps: PropTypes.shape({
    projectIdMap: PropTypes.shape({}),
    projectId: PropTypes.string,
    setCustomerIds: PropTypes.func,
    setProjectIds: PropTypes.func,
    setVendorIds: PropTypes.func,
    setBucketIds: PropTypes.func,
    setUserIds: PropTypes.func,
    setContactIds: PropTypes.func,
    setCostcodeIds: PropTypes.func,
  }),
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  onChange: PropTypes.func.isRequired,
  responding: PropTypes.bool,
  responses: PropTypes.shape({}),
};

TimeEntryTableDropdownInput.defaultProps = {
  configProps: {},
  value: [],
  customDropdownProps: {},
  responding: false,
  responses: {},
  customData: {},
};
