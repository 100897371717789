import React, { useCallback, useState, useMemo } from 'react';
import { Col, Row, Select } from 'antd';
import { GoldOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';

import { getType } from './workflowHelpers';
import HoverHelp from '../../common/HoverHelp';

const HOVER_TEXT = `
Use this step to update material quantities from your material database. This is set to debit by default.
`;

const DISABLED_TEXT = 'This step requires at least one Materials table field';

const quantityOptions = [
  { value: 'quantity', label: 'Quantity On-Hand' },
  { value: 'quantityAllocated', label: 'Quantity Allocated' },
];

const transactionOptions = [
  { value: 'Subtract', label: 'Subtract' },
  { value: 'Add', label: 'Add' },
];

export default function WorkflowMaterialDebitStep({
  setElements,
  setDataMap,
  isDisplay,
  sections = [],
} = {}) {
  return function _({
    id,
    draggable,
    disabled,
    data = {},
  }) {
    const {
      fields: initialSelected = [],
      quantityType: initialQuantityType = 'quantity',
      transaction: initialTransaction,
    } = data;
    const [selected, setSelected] = useState(initialSelected);
    const [quantityType, setQuantityType] = useState(initialQuantityType);
    const [transaction, setTransaction] = useState(initialTransaction);

    const setters = {
      fields: setSelected,
      quantityType: setQuantityType,
      transaction: setTransaction,
    };
    const onChange = useCallback((key) => (newValue) => {
      setDataMap((dataMap) => {
        const {
          [id]: oldData = {},
        } = dataMap;
        return {
          ...dataMap,
          [id]: {
            ...oldData,
            [key]: newValue,
          },
        };
      });
      setters[key](newValue);
    }, [id, setDataMap]);

    const materialTableFields = useMemo(() => (
      sections.reduce((sAcc, section) => {
        const { fields = [] } = section;
        return sAcc.concat(
          fields.reduce((fAcc, field) => {
            const { id: fieldId, configProps: { title } = {} } = field;
            const type = getType(field);
            if (type === 'table - Materials') fAcc.push({ label: title, value: fieldId });
            return fAcc;
          }, []),
        );
      }, [])
    ), [sections]);

    const title = `Update Materials${disabled ? ' - DISABLED' : ''}`;

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        Icon={GoldOutlined}
        type="materialDebit"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable && (
        <div>
          <Row style={{ marginTop: 5 }}>
            Select material table field(s):
          </Row>
          <Row style={{ margin: '20px 0px' }}>
            <Select
              mode="multiple"
              onChange={onChange('fields')}
              value={selected}
              options={materialTableFields}
              optionFilterProp="label"
            />
          </Row>
          <Row style={{ marginTop: 5 }}>
            Select Quantity Type:
          </Row>
          <Row style={{ margin: '20px 0px' }}>
            <Select
              onChange={onChange('quantityType')}
              value={quantityType}
              options={quantityOptions}
              optionFilterProp="label"
            />
          </Row>
          <Row style={{ marginTop: 5 }}>
            <Col>Transaction Type:</Col>
            <Col>
              <HoverHelp
                placement="topRight"
                content={(
                  <div style={{ width: 300 }}>
                    If selected, sets whether the quantity value is added
                    or subtracted from the configured quantity type.
                    This will override the Debit/Credit column if set.
                  </div>
                )}
              />
            </Col>
          </Row>
          <Row style={{ margin: '20px 0px' }}>
            <Select
              onChange={onChange('transaction')}
              value={transaction}
              options={transactionOptions}
              optionFilterProp="label"
              allowClear
            />
          </Row>
          <WorkflowHandle type="target" position="top" disabled={isDisplay} />
        </div>
        )}
      </WorkflowActionNode>
    );
  };
}
