import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { includesTerm, isNullOrUndefined } from 'ontraccr-common/lib/Common';
import { useDispatch, useSelector } from 'react-redux';
import { Checkbox, Table, Tooltip } from 'antd';

import { parseContactConnections } from 'ontraccr-common/lib/Contacts';
import BreadcrumbContainer from '../../common/breadcrumbContainer/breadcrumbContainer';
import {
  ADDRESS_BOOK_COLUMNS,
  CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE,
  CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE,
} from '../contactConstants';
import ContactAddressBookSlider from '../ContactAddressBookSlider';
import { getCustomers } from '../customers/state/customers.actions';
import AddressBookHeader from './AddressBookHeader';
import { getGlobalAddressBook, massDeleteFromGlobalAddressBook } from '../state/contacts.actions';
import { getVendors } from '../vendors/state/vendors.actions';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import AddressBookShareSlider from './AddressBookShareSlider';
import sortByString from '../../helpers/helpers';

const crumbs = [{ text: 'Address Book', icon: 'address-book' }];

export default function AddressBook() {
  const dispatch = useDispatch();

  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const customerMap = useSelector((state) => state.customers.customers);
  const vendorMap = useSelector((state) => state.vendors.vendors);

  const [sliderVisible, setSliderVisible] = useState(false);
  const [sliderMode, setSliderMode] = useState();
  const [selected, setSelected] = useState();
  const [selectedContacts, setSelectedContacts] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [canUseHotkeys, setCanUseHotkeys] = useState(true);

  const searchRef = useRef(null);

  useEffect(() => {
    setSliderVisible(false);
    setSliderMode(CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE);
    setSelected();
    setSelectedContacts([]);
    setSearchInput('');
    dispatch(getCustomers());
    dispatch(getVendors());
    dispatch(getGlobalAddressBook());
  }, []);

  const handleSelectedChange = useCallback((record) => {
    if (!record) return;

    const {
      id,
    } = record;

    const selectedSet = new Set(selectedContacts);
    const existed = selectedSet.delete(id);

    if (existed) setSelectedContacts(Array.from(selectedSet));
    else setSelectedContacts([...selectedContacts, id]);
  }, [selectedContacts]);

  const closeContactSlider = useCallback(() => {
    setSliderVisible(false);
    setSliderMode(CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE);
    setCanUseHotkeys(true);
  }, []);

  const openContactSlider = useCallback((record, mode = CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE) => {
    if (mode !== CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE) {
      setSelected(record);
    }
    if (mode === CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE && !selectedContacts.length) {
      return;
    }
    setCanUseHotkeys(false);
    setSliderVisible(true);
    setSliderMode(mode);
  }, [selectedContacts]);

  const onSearchInputChange = useCallback((e) => setSearchInput(e.target.value), []);

  const onMassDelete = useCallback(() => {
    if (!selectedContacts.length) return;
    setCanUseHotkeys(false);
    CustomConfirmModal({
      title: `Delete ${selectedContacts.length} item${(selectedContacts.length > 1) ? 's' : ''} from Address Book?`,
      okText: 'Delete',
      content: 'Warning: This will permanantly delete the entry from the address book and all connections',
      onOk: async () => {
        if (await dispatch(massDeleteFromGlobalAddressBook(selectedContacts))) {
          setSelectedContacts([]);
          setCanUseHotkeys(true);
        }
      },
      onCancel: async () => {
        setCanUseHotkeys(true);
      },
    });
  }, [selectedContacts]);

  const data = useMemo(() => {
    const addressBook = Object.values(globalAddressBooks) ?? [];
    return addressBook
      .map(({ customers: connectedCustomers, vendors: connectedVendors, ...rest }) => ({
        ...rest,
        connections: parseContactConnections({
          customers: connectedCustomers,
          vendors: connectedVendors,
          customerMap,
          vendorMap,
        }),
      }))
      .filter(({
        connections, name, role, phoneNumber, email,
      }) => (
        !searchInput
        || (!isNullOrUndefined(connections) && includesTerm(connections, searchInput))
        || (!isNullOrUndefined(name) && includesTerm(name, searchInput))
        || (!isNullOrUndefined(role) && includesTerm(role, searchInput))
        || (!isNullOrUndefined(phoneNumber) && includesTerm(phoneNumber, searchInput))
        || (!isNullOrUndefined(email) && includesTerm(email, searchInput))
      ))
      .sort(sortByString('name'));
  }, [globalAddressBooks, searchInput, customerMap, vendorMap]);

  const columns = useMemo(() => ([
    {
      title: '',
      dataIndex: 'check',
      key: 'check',
      align: 'center',
      width: 15,
      onCell: (record) => ({
        onClick: (e) => {
          e?.stopPropagation();
          e?.target?.focus();
          handleSelectedChange(record);
        },
      }),
      render: (_, record) => {
        const {
          id,
        } = record ?? {};

        const value = !isNullOrUndefined(id) && selectedContacts.includes(id);

        return (
          <Checkbox
            checked={value}
            onChange={() => {
              handleSelectedChange(record);
            }}
          />
        );
      },
    },
    {
      title: 'Connections',
      dataIndex: 'connections',
      key: 'connections',
      align: 'center',
      width: 60,
      render: (value) => (
        <Tooltip title={value}>
          <div style={{
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            overflow: 'hidden',
          }}
          >
            {value}
          </div>
        </Tooltip>
      ),
    },
    ...ADDRESS_BOOK_COLUMNS,
  ]), [selectedContacts, handleSelectedChange]);

  const handleTableHotKeys = useCallback(({ e, record, index }) => {
    const {
      key,
      target: {
        previousSibling,
        nextSibling,
      } = {},
    } = e;
    switch (key) {
      case 'ArrowUp': {
        if (index > 0) previousSibling?.focus();
        break;
      }
      case 'Tab':
      case 'ArrowDown': {
        if (index < data.length) nextSibling?.focus();
        break;
      }
      case 'Enter': {
        handleSelectedChange(record);
        break;
      }
      default: {
        break;
      }
    }
  }, [handleSelectedChange, data]);

  useEffect(() => {
    const hotkeys = async (e) => {
      if (!canUseHotkeys || document.activeElement === searchRef.current?.input) return;
      const { key } = e;

      switch (key) {
        case 'Backspace':
        case 'Delete':
        case 'd': {
          onMassDelete();
          break;
        }
        case 'a': {
          openContactSlider({}, CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE);
          break;
        }
        case 's': {
          openContactSlider({}, CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE);
          break;
        }
        default: {
          break;
        }
      }
    };

    document.addEventListener('keydown', hotkeys, true);

    return () => {
      document.removeEventListener('keydown', hotkeys, true);
    };
  }, [canUseHotkeys, onMassDelete, openContactSlider, searchRef]);

  return (
    <BreadcrumbContainer
      crumbs={crumbs}
      bodyStyle={{
        position: 'absolute',
        top: 71,
        left: 15,
        right: 15,
        height: 'auto',
      }}
    >
      <AddressBookHeader
        searchInput={searchInput}
        onSearchInputChange={onSearchInputChange}
        searchRef={searchRef}
        onAddClick={() => openContactSlider({}, CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE)}
        onDeleteClick={onMassDelete}
        onShareClick={() => openContactSlider({}, CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE)}
        selectedContacts={selectedContacts}
      />

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        size="medium"
        rowClassName="address-book-table-row"
        scroll={{
          y: 'calc(100vh - 200px)',
        }}
        onRow={(record, index) => ({
          onClick: () => openContactSlider(record),
          tabIndex: index,
          onKeyDown: (e) => {
            e.preventDefault();
            handleTableHotKeys({ e, record, index });
          },
        })}
      />

      <ContactAddressBookSlider
        visible={sliderVisible && sliderMode !== CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE}
        mode={sliderMode}
        isVendor={false}
        selected={selected}
        updateMode={setSliderMode}
        onClose={closeContactSlider}
        deleteMode="global"
      />

      <AddressBookShareSlider
        visible={sliderVisible && sliderMode === CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE}
        selectedContacts={selectedContacts}
        handleSelectedChange={handleSelectedChange}
        onClose={closeContactSlider}
      />
    </BreadcrumbContainer>
  );
}
