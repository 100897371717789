export default {};

export const USER_CREATE_ACTION = 'USER_CREATE';
export const COST_CODE_CREATE_ACTION = 'COST_CODE_CREATE';
export const PROJECT_CREATE_ACTION = 'PROJECT_CREATE';
export const TEAM_CREATE_ACTION = 'TEAM_CREATE';
export const ADD_NOTE_ACTION = 'ADD_NOTE';
export const CLOCK_IN_ACTION = 'CLOCK_IN';
export const CLOCK_OUT_ACTION = 'CLOCK_OUT';
export const END_BREAK_ACTION = 'END_BREAK';
export const SWITCH_TASK_ACTION = 'SWITCH_TASK';
export const TIME_CARD_SUBMIT_ACTION = 'TIME_CARD_SUBMIT';
export const MANUAL_ENTRY_ACTION = 'MANUAL_ENTRY';
export const BUDGET_ACTION = 'PROJECT_BUDGET';
export const POST_ACTION = 'POST';

export const CLOCK_ACTIONS = new Set([
  CLOCK_IN_ACTION,
  CLOCK_OUT_ACTION,
  END_BREAK_ACTION,
  ADD_NOTE_ACTION,
  SWITCH_TASK_ACTION,
]);
