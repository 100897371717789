import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { Col, Row } from 'antd';
import { Weather } from 'ontraccr-common';
import WeatherReportTable from './WeatherReportTable';
import DailySnapshotTable from './DailySnapshotTable';

export default function OntraccrWeather({
  data = {},
  loading,
  showDailySnapshot,
  showWeatherReport,
} = {}) {
  const weatherReportData = useMemo(() => Weather.parseWeatherReport(data), [data]);
  const dailySnapshotData = useMemo(() => Weather.parseDailySnapshot(data), [data]);

  return (
    <Row>
      <Col>
        {showWeatherReport && (
          <Row style={{ width: '100%' }}>
            <WeatherReportTable raw={data} data={[weatherReportData]} loading={loading} />
          </Row>
        )}
        {showDailySnapshot && (
          <Row style={{ width: '100%' }}>
            <DailySnapshotTable raw={data} data={[dailySnapshotData]} loading={loading} />
          </Row>
        )}
      </Col>
    </Row>
  );
}

OntraccrWeather.propTypes = {
  data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  showDailySnapshot: PropTypes.bool,
  showWeatherReport: PropTypes.bool,
};

OntraccrWeather.defaultProps = {
  data: {},
  loading: false,
  showDailySnapshot: true,
  showWeatherReport: true,
};
