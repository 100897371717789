import Analytics from '../../../helpers/Analytics';
import InvoiceService from './invoices.service';

import {
  CREATE_INVOICE,
  CREATE_INVOICE_COSTCODE_DISTRIBUTION,
  DELETE_INVOICE,
  DELETE_INVOICE_COSTCODE_DISTRIBUTION,
  GET_FORM_INVOICES_COST_TO_DATE,
  GET_INVOICES,
  GET_INVOICE_STATUS_LABELS,
  UPDATE_INVOICE,
  UPDATE_INVOICE_COSTCODE_DISTRIBUTION,
} from '../../../state/actionTypes';

export const getInvoiceStatusLabels = () => async (dispatch) => {
  const { data } = await InvoiceService.getInvoiceStatusLabels();
  if (!data) return false;
  dispatch({
    type: GET_INVOICE_STATUS_LABELS,
    payload: {
      statuses: data,
    },
  });
  return true;
};

export const getInvoices = (query) => async (dispatch) => {
  const { data } = await InvoiceService.getInvoices(query);
  if (!data) return false;
  dispatch({
    type: GET_INVOICES,
    payload: {
      invoices: data,
    },
  });
  return true;
};

export const createInvoice = ({ formId, useRange, range, payload }) => async (dispatch) => {
  Analytics.track('Invoice/create');
  const {
    data: {
      invoice,
      status,
      errors = [],
    } = {},
  } = await InvoiceService.createInvoice(payload);
  if (!invoice) return false;
  dispatch({
    type: CREATE_INVOICE,
    payload: {
      formId,
      useRange,
      range,
      newInvoice: invoice,
      newlyAddedStatus: status,
    },
  });
  return errors;
};

export const deleteInvoice = (invoiceId, formId) => async (dispatch) => {
  Analytics.track('Invoice/delete');
  const { data } = await InvoiceService.deleteInvoice(invoiceId);
  if (!data) return false;
  dispatch({
    type: DELETE_INVOICE,
    payload: {
      invoiceId,
      formId,
    },
  });
  return true;
};

export const updateInvoice = ({ invoiceId, formId, useRange, range, payload }) => async (dispatch) => {
  Analytics.track('Invoice/update');
  const {
    data: {
      invoice,
      status,
      errors = [],
    } = {},
  } = await InvoiceService.updateInvoice(invoiceId, payload);
  if (!invoice) return false;
  dispatch({
    type: UPDATE_INVOICE,
    payload: {
      invoiceId,
      formId,
      useRange,
      range,
      updatedInvoice: invoice,
      newlyAddedStatus: status,
    },
  });
  return errors;
};

/**
 * Retrieves the invoice cost to date for the specified form
 * @param {object} payload
 * @returns {boolean}
 */
export const getFormInvoicesCostToDate = (payload) => async (dispatch) => {
  const { data } = await InvoiceService.getFormInvoicesCostToDate(payload);
  if (!data) return false;
  dispatch({
    type: GET_FORM_INVOICES_COST_TO_DATE,
    payload: {
      formData: data,
    },
  });
  return true;
};

/**
 * Adds a Costcode Distribution to the specified invoice
 * @param {object} payload
 * @returns {boolean}
 */
export const addCostcodeDistribution = (payload = {}) => async (dispatch) => {
  const { data: costcodeDistributionId } = await InvoiceService.addCostcodeDistribution(payload);
  if (!costcodeDistributionId) return false;
  dispatch({
    type: CREATE_INVOICE_COSTCODE_DISTRIBUTION,
    payload: {
      id: costcodeDistributionId,
      ...payload,
    },
  });
  return true;
};

/**
 * Updates an existing Costcode Distribution's amount
 * @param {string} invoiceId
 * @param {string} costcodeDistributionId
 * @param {object} payload
 * @returns {boolean}
 */
export const updateCostcodeDistribution = (invoiceId, costcodeDistributionId, payload = {}) => async (dispatch) => {
  const { data } = await InvoiceService.updateCostcodeDistribution(costcodeDistributionId, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_INVOICE_COSTCODE_DISTRIBUTION,
    payload: {
      invoiceId,
      costcodeDistributionId,
      ...payload,
    },
  });
  return true;
};

/**
 * Deletes an existing Costcode Distribution
 * @param {string} invoiceId
 * @param {string} costcodeDistributionId
 * @returns {boolean}
 */
export const deleteCostcodeDistribution = (invoiceId, costcodeDistributionId) => async (dispatch) => {
  const { data } = await InvoiceService.deleteCostcodeDistribution(costcodeDistributionId);
  if (!data) return false;
  dispatch({
    type: DELETE_INVOICE_COSTCODE_DISTRIBUTION,
    payload: {
      invoiceId,
      costcodeDistributionId,
    },
  });
  return true;
};
