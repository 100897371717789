import React from 'react';
import { Row, Col, Checkbox, Select } from 'antd';

import HoverHelp from '../../common/HoverHelp';

const { Option } = Select;

export default ({
  integrationKey,
  helpText,
  setImportList,
  importList = new Set(),
  resetImportList,
  canImport = () => true,
  onViewTypeChanged,
  linkOptions = [],
  onLinkChanged,
  linkLabel = (option) => option.name,
  getValue = (linkValue) => linkValue,
}) => ({
  title:(
    <Row gutter={10} justify='center'>
      <Col>
        <Checkbox defaultChecked onChange={(e) => {
          const {
            target:{
              checked,
            } = {},
          } = e;
          if(!checked) {
            return setImportList(new Set());
          }
          return resetImportList();
        }}/>
      </Col>
      <Col>
        Import/Link
      </Col>
      <Col>
        <Row align='middle' style={{ height: 22}}>
          <HoverHelp
            placement='left'
            content={helpText}
            iconProps={{ 
              style:{
                fontSize: 12,
              },
            }}
          />
        </Row>
      </Col>
    </Row>
  ),
  dataIndex:'import',
  key:'import',
  align:'center',
  fixed:'right',
  width:250,
  render:(_,record) => (
    <Row style={{ width: '100%'}} justify='end'>
      <Col>
        {
          record.viewType !== 'link' ? 
          <Row style={{ height: 32}} align='middle'>
            <Checkbox 
              disabled={!canImport(record)}
              checked={importList.has(record[integrationKey]) && canImport(record)}
              onChange={(e) => {
                const {
                  target:{
                    checked,
                  } = {},
                } = e;
                if(checked) {
                  importList.add(record[integrationKey]);
                } else {
                  importList.delete(record[integrationKey]);
                }
                setImportList(new Set(importList));
              }}
            /> 
          </Row>
          : 
          <Select
            value={getValue(record.linkValue)}
            key={record[integrationKey]}
            style={{ width: 125 }}
            showSearch
            allowClear
            onChange={(value, opt) => onLinkChanged(record,value,opt)}
            options={linkOptions.map((option) => ({ label: linkLabel(option), value: option.id }))}
            optionFilterProp='label'
            dropdownMatchSelectWidth={false}
            dropdownStyle={{
              flex:1,
              maxWidth:250,
            }}
          />
           
        }
      </Col>
      <Col>
        <Select
          style={{ width: 100 }}
          bordered={false}
          defaultValue={record.linkValue ? 'link' : 'import'}
          onChange={(type) => onViewTypeChanged(record,type)}
        >
          <Option value='import'>Import</Option>
          <Option value='link'>Link</Option>
        </Select>
      </Col>
    </Row>
  ),
});
