import { GET_QR_CODES } from '../state/actionTypes';
import QRCodesService from './qrCodes.service';

// eslint-disable-next-line import/prefer-default-export
export const getQRCodes = () => async (dispatch) => {
  const { data } = await QRCodesService.getQRCodes();
  if (!data) return false;
  dispatch({
    type: GET_QR_CODES,
    payload: {
      data,
    },
  });
  return true;
};
