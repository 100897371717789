/* eslint-disable no-undef */
import React from 'react';

import colors from '../../constants/Colors';


const clockinIcon = require('../../assets/images/Map/clockin.png');
const clockoutIcon = require('../../assets/images/Map/clockout.png');
const { MarkerWithLabel } = require('react-google-maps/lib/components/addons/MarkerWithLabel');

export default ({
  name:key,
  latitude: lat,
  longitude: lng,
  title,
  clockin,
  opacity = 0.25,
}) => {
  return (
    <MarkerWithLabel
      icon={clockin ? clockinIcon : clockoutIcon}
      key={key}
      opacity={opacity}
      position={{ lat, lng }}
      labelAnchor={new google.maps.Point(clockin ? -15 : 135, 25)}
      labelStyle={{ 
        fontSize:'16px',
        fontWeight:'bold',
        opacity,
        color:clockin ? colors.ONTRACCR_BLACK : colors.ONTRACCR_RED,
        'text-shadow': '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
      }}
      labelVisible
    >
      <div>{title}</div>
    </MarkerWithLabel>
  );
};
