export default {};

export const AUTO_COLLECTED_ID = 'autoCollected';
export const collectedDataMap = {
  employeeId: { title: 'Employee ID', id: 'employeeId' },
  employeeName: { title: 'Employee Name', id: 'employeeName' },
  employeeSignature: { title: 'Collect Signature', id: 'employeeSignature' },
  date: { title: 'Date', id: 'date' },
  number: { title: 'Form Number', id: 'number' },
};

export const CURRENCIES = [
  { value: 'cad', label: 'CAD' },
  { value: 'usd', label: 'USD' },
];
