import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Select,
  Row,
  Col,
  Switch,
  Radio,
} from 'antd';
import { useTranslation } from 'react-i18next';

import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';

import { getIdMap } from '../helpers/helpers';

import colors from '../constants/Colors';

export default function EquipmentCheckModal({
  id,
  name,
  currentProjectId,
  currentLocationText,
  onCloseClicked,
  onCheckOut,
  onCheckIn,
  visible,
}) {
  const { t } = useTranslation();
  const equipmentProjects = useSelector((state) => state.equipment.equipmentProjects);
  const equipment = useSelector((state) => state.equipment.equipment);
  const projects = useSelector((state) => state.projects.projects);
  const { settings = {} } = useSelector((state) => state.settings.company) || {};

  const [projectId, setProjectId] = useState(currentProjectId);
  const [location, setLocation] = useState(currentLocationText);
  const [isCustom, setIsCustom] = useState(!!currentLocationText);
  const [showAllProjects, setShowAllProjects] = useState(1);

  const equipmentIdMap = useMemo(() => getIdMap(equipment), [equipment]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const ourEquipment = useMemo(() => equipmentIdMap[id], [id, equipmentIdMap]);

  const divisionSet = useMemo(() => new Set(ourEquipment?.divisionIds ?? []), [ourEquipment]);

  const ourProjects = useMemo(() => {
    const { [id]: ourProjectIds = [] } = equipmentProjects;
    return ourProjectIds.map((projId) => {
      const project = projectIdMap[projId];
      if (!project) return null;
      return {
        value: projId,
        label: formatProjectLabelFromCompanySettings({
          name: project.name,
          number: project.number,
          settings,
        }),
      };
    }).filter((project) => project);
  }, [id, equipmentProjects, projectIdMap, settings]);

  const relevantProjects = useMemo(() => {
    const divProjects = projects
      .filter(({ divisionId }) => divisionSet.has(divisionId))
      .map(({ id: projId, name: projName, number }) => ({
        value: projId,
        label: formatProjectLabelFromCompanySettings({ name: projName, number, settings }),
      }));

    return divProjects;
  }, [projects, divisionSet, settings]);

  const onSave = useCallback(() => {
    const payload = {};
    if (isCustom) {
      payload.location = location;
    } else {
      payload.projectId = projectId;
    }
    onCheckIn({ id, payload });
  }, [id, isCustom, projectId, location]);
  const onCheckoutClicked = useCallback(() => {
    const payload = {};
    if (isCustom) {
      payload.location = location;
    } else {
      payload.projectId = projectId;
    }
    onCheckOut({ id, payload });
  }, [id, isCustom, projectId, location]);
  const onTextChanged = useCallback((e) => {
    const {
      target: { value } = {},
    } = e;
    setLocation(value);
  }, []);

  useEffect(() => {
    setProjectId(currentProjectId);
  }, [currentProjectId]);

  useEffect(() => {
    setLocation(currentLocationText);
    setIsCustom(!!currentLocationText);
  }, [currentLocationText]);

  const isActive = currentProjectId || currentLocationText;

  const onClose = () => {
    onCloseClicked();
    setShowAllProjects(1);
  };

  return (
    <CustomModal
      title={name}
      onCloseClicked={onClose}
      visible={visible}
      maskColor={colors.MODAL_MASK_DARK}
      width={currentProjectId ? 500 : 400}
    >
      <div id="equipment-check-modal-flavour-text">
        {currentProjectId ? 'Switch' : 'Check In'} equipment
        <div id="equipment-check-modal-switch">
          <div className="equipment-check-modal-switch-child">
            <Switch checked={isCustom} onChange={setIsCustom} />
          </div>
          <div className="equipment-check-modal-switch-child">
            {isCustom ? 'Location' : t('Project')}
          </div>
        </div>
      </div>
      {
        isCustom
          ? (
            <OnTraccrTextInput
              className="equipment-check-modal-input"
              value={location}
              onChange={onTextChanged}
            />
          )
          : (
            <Col flex="100%">
              <Row style={{ width: '100%', padding: '0.1em 0.5em' }}>
                <Radio.Group
                  style={{
                    width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between',
                  }}
                  value={showAllProjects}
                  onChange={(e) => setShowAllProjects(e?.target?.value)}
                >
                  <Radio.Button value={0} style={{ width: '100%' }}> Show Equipment Projects </Radio.Button>
                  <Radio.Button value={1} style={{ width: '100%' }}> Show All Projects </Radio.Button>
                </Radio.Group>
              </Row>

              <Row style={{ flexGrow: 1 }}>
                <Select
                  className="equipment-check-modal-input"
                  placeholder={`Select a ${t('Project').toLowerCase()}`}
                  onSelect={setProjectId}
                  value={projectId}
                  options={showAllProjects ? relevantProjects : ourProjects}
                />
              </Row>

            </Col>
          )
      }

      <Row
        className="equipment-check-modal-button-container "
        justify={currentProjectId ? 'space-around' : 'center'}
        gutter={12}
      >
        <Col>
          <OnTraccrButton
            type="cancel"
            title="Cancel"
            roundStyle
            onClick={onClose}
          />
        </Col>
        {isActive
          && (
          <Col>
            <OnTraccrButton
              type="back"
              title="Check Out"
              roundStyle
              onClick={onCheckoutClicked}
            />
          </Col>
          )}
        <Col>
          <OnTraccrButton
            title={isActive ? 'Switch' : 'Check In'}
            roundStyle
            disabled={!projectId && !location}
            onClick={onSave}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}
