import React from 'react';
import { Row,Col } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import SignupCard from './SignupCard';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import colors from '../../constants/Colors';

const steps = [
  'To get started with your employee acccount, check your email inbox.',
  'Look for an email with a username and password that have been provided to you.',
  'If you have any issues, be sure to contact your admin for help.'
];

const SignupRow = ({ number, text }) => (
  <Row style={{ width:'100%',height:50, padding: '0px 20px', marginTop: 15, }} justify='start'>
    <Col>
      <Row style={{ width: '100%', height:'100%'}} justify='center' align='middle'> 
        <Row style={{
          width:30,
          height:30,
          borderRadius:15,
          backgroundColor:colors.ONTRACCR_DARK_YELLOW,
          fontSize:18,
          marginRight:20,
        }} justify='center' align='middle'>
          {number}
        </Row>
      </Row>
     
    </Col>
    <Col style={{
      fontSize: 16,
      width:'calc(100% - 90px)',
      verticalAlign:'middle',
      lineHeight:'25px',
    }}>
      <Row align='middle' style={{ height: '100%'}}>
        {text}
      </Row>
    </Col>
  </Row>
);


export default ({
  onBack,
  onLogin,
  onSignUp,
  style = {},
}) => (
  <SignupCard style={style} cardStyle={{ height: 575 }}>
    <Row style={{ width: '100%', top: 5, position:'absolute' }} justify='center'>
        Employee Signup
    </Row>
    <CloseOutlined 
      style={{
        position:'absolute',
        left: 10,
        top: 10,
      }}
      onClick={onBack}
    />
    <Row style={{
      textAlign:'center',
      fontSize:16,
      paddingLeft: 40,
      paddingRight: 40,
      marginTop: 60,
      height:60,
    }}>
   
      If you're an employee for an existing OnTraccr account,
      your admin has probably created an account for you:
    </Row>
    <Row style={{ width:'100%', position:'absolute', top: 150 }}>
      {steps.map((step,index) => (
        <SignupRow
          number={index + 1}
          text={step}
        />
      ))}
    </Row>

    <Row 
      style={{
        width: '100%', 
        flexDirection:'column',
        fontSize:18,
        fontFamily:'roboto-medium',
        position:'absolute',
        bottom: 120,
      }} 
      justify='center'
      align='middle'
    >
      Already have an account? 
      <OnTraccrButton
        style={{ marginTop: 5 }}
        title='Login'
        roundStyle
        fat
        onClick={onLogin}
      />
    </Row>
    <Row 
      style={{
        width: '100%', 
        flexDirection:'column',
        fontSize:18,
        fontFamily:'roboto-medium',
        position:'absolute',
        bottom: 20,
      }} 
      justify='center'
      align='middle'
    >
      Need to create an account for your company? 
      <OnTraccrButton
        style={{ marginTop: 5 }}
        title='Sign Up'
        roundStyle
        fat
        onClick={onSignUp}
      />
    </Row>
  </SignupCard>
);