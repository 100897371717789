export function mapFromKey(array, key = 'id') {
  const map = {};
  array.forEach((item) => {
    if (item[key]) map[item[key]] = item;
  });
  return map;
}

export const getBase64 = function getBase64(img, callback) {
  const reader = new FileReader();
  reader.addEventListener('load', () => callback(reader.result));
  reader.readAsDataURL(img);
};

/**
 * Sorts alphabetically by object specified property
 * @param {string} propertyName
 * @returns {Function} sort function
 */
export const sortByObjProperty = (propertyName) => (a, b) => a[propertyName].localeCompare(b[propertyName]);

/**
 * Returns true if object is empty, false otherwise
 * @param {object} obj 
 * @returns {boolean}
 */
export const isObjectEmpty = (obj) => Object.keys(obj).length === 0;
