import React, { useState, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Button } from 'antd';
import {
  MessageOutlined,
  SendOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import { TagManager } from 'ontraccr-common';
import { useSpring, animated } from 'react-spring';

import PropTypes from 'prop-types';
import { TagView } from './LiveFeedTaggedText';
import LiveFeedFileAddButton from './LiveFeedFileAddButton';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import ProfileAvatar from '../../common/ProfileAvatar';
import PhotoReel from '../../common/photos/PhotoReel';

import { LIVE_FEED_MESSAGE_TYPE } from '../../nux/nux.constants';
import { createNuxEntry } from '../../nux/state/nux.actions';

import colors from '../../constants/Colors';

const MIN_WIDTH = '0%';
const MAX_WIDTH = '100%';
const MIN_HEIGHT = 34;
const MAX_HEIGHT = 1000;

export default function LiveFeedTextInput({
  onSend,
  onUpload,
  onUploadEnd,
  currChannelId,
  clearFiles,
  onFileRemove,
  onPreviewClick,
  history,
  files = [],
  users = [],
  userTrie = {},
  placeholder = 'Send a message',
  hideTags,
  collapsable = true,
  style = {},
  allowUpload = false,
}) {
  const dispatch = useDispatch();
  const nux = useSelector(state => state.nux.nux);

  const [open,setOpen] = useState(true);
  const [tagManager,setTagManager] = useState();
  const [focused,setFocused] = useState();
  const [text,setText] = useState();
  const [parsedText,setParsedText] = useState();
  const [searchResults,setSearchResults] = useState();
  const [sending,setSending] = useState(false);
  const [containerStyle, setContainerStyle] = useSpring(() => ({ width: MAX_WIDTH, maxHeight: MAX_HEIGHT }))

  const textRef = useRef(null);

  const toggleOpen = () => setOpen(!open);

  const parseTags = (text) => {
    const {
      text:newParsed,
      searchResults:newSearch,
    } = tagManager.parseText(text);
    // add line break
    const withLF = newParsed.map((c) => c === '\r\n' || c === '\n' || c === '\r' ?
     <br/> : c
    );
    setParsedText(withLF);
    setSearchResults(newSearch);
    if(dispatch && newSearch.length > 0 && !nux.has(LIVE_FEED_MESSAGE_TYPE)) {
      dispatch(createNuxEntry(LIVE_FEED_MESSAGE_TYPE));
    }
  };

  const sendPost = async () => {
    if(!text && files.length === 0) return;
    setSending(true);
    if(onUploadEnd) onUploadEnd();
    const tags = tagManager.getTaskMapPayload();
    if(await onSend({
      channelId: currChannelId,
      text,
      tags,
      files,
    })) {
      setText();
      setSearchResults();
      if(clearFiles) clearFiles();
      if(tagManager) tagManager.clearTags();
    }
    setSending(false);
  };

  const focusedText = () => {
    setFocused(true);
    if(tagManager && text) parseTags(text);
  };

  const blurredText = () => {
    setTimeout(setSearchResults,150);
    setFocused(false);
  };
  const textChanged = (e) => {
    const {
      target:{
        value:text,
      } = {}
    } = e;
    setText(text);
  };

  const selectTag = (user) => () => {
    if(!text || !tagManager) return;
    const index = text.lastIndexOf('@');
    const newText = text.slice(0,index) + `@${user.name}`;
    if(tagManager) {
      tagManager.addTag(index,user);
    }
    setTimeout(() => {
      if (!textRef?.current) return;
      textRef.current.focus();
      // Need this line for the cursor to move to the correct location on Safari.
      textRef.current.resizableTextArea.textArea.selectionStart = textRef.current.resizableTextArea.textArea.selectionEnd = newText.length;
    },50);
    setText(newText);
    setSearchResults();
  };

  useEffect(() => {
    setTagManager(
      new TagManager({
        trie:userTrie,
        data:users.filter((user) => user.active),
        basicView:({ children }) => children,
        tagView: TagView,
      })
    );
  },[userTrie,users]);

  useEffect(() => {
    if(tagManager && text) {
      parseTags(text);
    } else {
      setParsedText(text);
      setSearchResults();
    }
  },[text, tagManager]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setContainerStyle({
      width:open ? MAX_WIDTH : MIN_WIDTH,
      maxHeight: open ? MAX_HEIGHT : MIN_HEIGHT
    });
  },[open, setContainerStyle]);

  const interpolatedStyle = {
    maxHeight: containerStyle.maxHeight,
  };
  return (
    <animated.div className='livefeed-container' style={{ ...style, ...containerStyle }}>
      {files.length > 0 && open &&
        <div className='livefeed-file-reel-container'>
          <PhotoReel
            files={files}
            shadow
            small
            onDelete={onFileRemove}
            onClick={onPreviewClick}
          />
        </div>
      }
      <animated.div
        className='livefeed-text-container'
        style={{
          ...interpolatedStyle,
          border:focused && open ? '1px black solid' : undefined,
        }}>
        {!hideTags && <animated.div className='livefeed-text-overlay' style={interpolatedStyle}>
          {open ? parsedText : null}
        </animated.div>}
        <animated.div
          style={interpolatedStyle}
        >
          <OnTraccrTextInput
            ref={textRef}
            className={
              hideTags
              ? 'livefeed-text-input-no-tags'
              : 'livefeed-text-input-tags'
            }
            textarea
            autoSize
            resize='none'
            placeholder={parsedText || !open ?  null : placeholder}
            onFocus={focusedText}
            onBlur={blurredText}
            onChange={textChanged}
            value={text}
            onPressEnter={(e) => {
              if (e.key === 'Enter' && !e.shiftKey) {
                e.preventDefault();
                sendPost();
              }
            }}
          />
        </animated.div>
      </animated.div>

      <div className='livefeed-shadow'/>

      {allowUpload && <LiveFeedFileAddButton
        open={open}
        history={history}
        onClick={onUpload}
      />}

      <animated.div style={{
        opacity:containerStyle.width.interpolate({
          range:[MIN_WIDTH,MAX_WIDTH],
          output:[0,1]
        }),
        width:containerStyle.width.interpolate({
          range:[MIN_WIDTH,MAX_WIDTH],
          output:[0,26]
        }),
      }}>
        <Button
          onClick={sendPost}
          className='livefeed-send-icon'
          icon={
            sending ? <LoadingOutlined style={{ color:colors.ONTRACCR_RED }}/> :
            <SendOutlined
              style={{
                color:(text && text.length) || files.length ? colors.ONTRACCR_RED : 'lightgray'
              }}
            />
          }
          disabled={(!text || !text.length) && files.length === 0}
        />
      </animated.div>
      <Button
        className={
          collapsable
          ? 'livefeed-message-icon-collapsable'
          : 'livefeed-message-icon'
        }
        icon={
          <MessageOutlined />
        }
        onClick={collapsable ? toggleOpen : null}
      />
      {
        searchResults && searchResults.length > 0 &&
        <div className='livefeed-tag-list'>
          {searchResults.map((user) => (
            <div
              key={user.id}
              className='livefeed-tag-row'
              onClick={selectTag(user)}>
              <ProfileAvatar
                name={user.name}
                role={user.position}
                avatarRadius={12}
              />
            </div>

          ))}
        </div>
      }
    </animated.div>
  );
}

LiveFeedTextInput.propTypes = {
  onSend: PropTypes.func.isRequired,
  onUpload: PropTypes.func,
  onUploadEnd: PropTypes.func,
  currChannelId: PropTypes.string,
  clearFiles: PropTypes.func,
  onFileRemove: PropTypes.func,
  onPreviewClick: PropTypes.func,
  history: PropTypes.object,
  files: PropTypes.array,
  users: PropTypes.array,
  userTrie: PropTypes.object,
  placeholder: PropTypes.string,
  hideTags: PropTypes.bool,
  collapsable: PropTypes.bool,
  style: PropTypes.object,
  allowUpload: PropTypes.bool,
};

