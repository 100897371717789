import React, {
  useEffect, useState, useMemo, useCallback,
} from 'react';
import { connect } from 'react-redux';
import {
  Collapse,
  Table,
  Row,
  Col,
  Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';

import OnTraccrMapDrawer from './OnTraccrMapDrawer';
import AutoImport from './IntegrationAutoImport';
import CanImportCol from './IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from './IntegrationIsConnectedCol';
import { createImportData, linkInitialData } from './integrationHelpers';

import sortByString, { getIdMap, includesTerm } from '../../helpers/helpers';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';
import getGoogleMapKey from '../../common/keys';

const { Panel } = Collapse;

export default connect(
  (state, ownProps) => (
    {
      ...ownProps,
      projects: state.projects.projects,
      divisions: state.settings.divisions,
    }
  ),
)(({
  integrationKey,
  title,
  projects,
  integrationProjects,
  onProjectsChanged,
  showCustomer = true,
  showNumber,
  showAutoImport = true,
  autoImportContent,
  importHelpText,
  importProjects,
  divisions,
  loading,
  // these division filters should be XOR
  divisionId, // For single division integrations
  divisionFilter, // Multi division integrations (e.g. QBO)
}) => {
  const { t } = useTranslation();
  const [activePanels, setActivePanels] = useState(showAutoImport ? [] : ['new']);
  const [autoImport, setAutoImport] = useState(showAutoImport);
  const [stateProjects, setStateProjects] = useState(integrationProjects ?? []);
  const [searchStr, setSearchStr] = useState();
  const [importList, setImportList] = useState(new Set());
  const [connected, setConnected] = useState({});

  const numberSet = useMemo(() => (
    new Set(projects?.map((project) => project.number) ?? [])
  ), [projects]);

  const divisionList = useMemo(() => (
    Object.values(divisions)
      .filter((division) => !divisionFilter || divisionFilter.has(division.id))
  ), [divisions, divisionFilter]);
  const projectMap = useMemo(() => getIdMap(projects ?? []), [projects]);

  const linkableProjects = useMemo(() => (
    projects?.filter((project) => (
      project.active
       && (!divisionId || divisionId === project.divisionId)
       && (!divisionFilter || divisionFilter.has(project.divisionId))
    )) ?? []
  ), [projects, divisionId, divisionFilter]);

  const updateProjects = useCallback((record) => {
    setStateProjects(stateProjects.map((project) => {
      if (project[integrationKey] !== record[integrationKey]) return project;
      return record;
    }));
  }, [stateProjects]);

  const canImport = useCallback((record) => (
    record.name
    && (!showNumber || record.number)
    && (!showNumber || !numberSet.has(record.number))
    && (record.linkValue || divisionId || record.divisionId || divisionList.length === 1)
  ), [showNumber, numberSet, divisionList, divisionId]);

  const resetImportList = (list) => () => {
    const il = new Set(list.map((project) => project[integrationKey]));
    setImportList(il);
  };

  useEffect(() => {
    setConnected(createConnectedArray(linkableProjects, integrationKey));
  }, [linkableProjects, integrationKey]);

  useEffect(() => {
    if (integrationProjects?.length) {
      const setInitialImportList = () => {
        const newProj = linkInitialData({ items: integrationProjects, connected, integrationKey });
        setStateProjects(newProj);
        resetImportList(newProj)();
      };
      setInitialImportList();
    }
  }, [integrationProjects, connected]);

  useEffect(() => {
    const importData = createImportData({
      integrationKey,
      newData: integrationProjects ?? [],
      stateData: stateProjects,
      originalData: linkableProjects,
      importList,
      canImport,
      connected,
      divisionList,
    });
    onProjectsChanged(importData);
  }, [
    canImport,
    stateProjects,
    divisionList,
    integrationKey,
    integrationProjects,
    stateProjects,
    linkableProjects,
    importList,
    connected,
  ]);

  const getCols = useCallback((editable) => {
    const cols = [
      IsConnectedCol({
        title,
        integrationKey,
        connected,
        onUnLink: (record) => {
          const newConnected = { ...connected };
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if (editable) {
            record.linkValue = null;
            updateProjects(record);
          } else {
            setStateProjects(stateProjects.map((project) => {
              if (project[integrationKey] !== record[integrationKey]) return project;
              const newUser = { ...project };
              delete newUser.linkValue;
              return newUser;
            }));
          }
        },
      }),
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        align: 'center',
        sorter: sortByString('name'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      }, {
        title: 'Division',
        dataIndex: 'divisionId',
        key: 'divisionId',
        align: 'center',
        fixed: 'left',
        width: 170,
        render: (_, record) => (
          <DivisionSelector
            key={record.id}
            displayMode={!editable}
            style={{ width: '100%' }}
            displayStyle={{ marginBottom: 0 }}
            onChange={(newDivisionId) => {
              if (newDivisionId !== record.divisionId) {
                record.divisionId = newDivisionId;
                updateProjects(record);
              }
            }}
            disabled={divisionId || record.viewType === 'link'}
            divisionId={divisionId || record.divisionId}
            filter={divisionFilter}
          />
        ),
      },
    ];
    if (showNumber) {
      cols.push({
        title: 'Number',
        dataIndex: 'number',
        key: 'number',
        align: 'center',
        sorter: sortByString('number'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
        render: (_, record) => {
          if (!editable) return record.number;
          const hasConflict = numberSet.has(record.number);
          return (
            <OnTraccrTextInput
              allowClear
              defaultValue={record.number}
              key={record.id}
              onClick={() => 1}
              onChange={(e) => {
                const {
                  target: {
                    value,
                  } = {},
                } = e;
                record.number = value;
                updateProjects(record);
              }}
              style={
                editable && hasConflict
                  ? {
                    borderColor: 'red',
                    textColor: 'red',
                  }
                  : {}
                }
            />
          );
        },
      });
    }
    if (showCustomer) {
      cols.push({
        title: t('Customer'),
        dataIndex: 'customer',
        key: 'customer',
        align: 'center',
        sorter: sortByString('customer'),
        showSorterTooltip: false,
        sortDirections: ['descend', 'ascend'],
      });
    }
    cols.push({
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
      align: 'center',
      render: (_, record) => (
        editable ? (
          <OnTraccrMapDrawer
            key={`${record.name}-qb-map`}
            name={record.name}
            address={record.address}
            lat={record.latitude}
            lng={record.longitude}
            onPlaceChanged={({ address, lat, lng }) => {
              record.address = address;
              record.latitude = lat;
              record.longitude = lng;
              updateProjects(record);
            }}
            googleMapURL={getGoogleMapKey()}
            loadingElement={<div style={{ height: '100%' }} />}
          />
        ) : record.address
      ),
    });
    if (editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          connected,
          helpText: importHelpText,
          canImport,
          importList,
          setImportList,
          resetImportList: resetImportList(stateProjects),
          onViewTypeChanged: (record, viewType) => {
            record.viewType = viewType;
            updateProjects(record);
          },
          linkOptions: linkableProjects,
          onLinkChanged: (record, linkValue) => {
            const newConnected = { ...connected };
            const oldLink = connected[record[integrationKey]];
            if (oldLink) delete oldLink[integrationKey];
            if (linkValue && linkValue in projectMap) {
              newConnected[record[integrationKey]] = projectMap[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
            } else {
              delete newConnected[record[integrationKey]];
            }

            setConnected(newConnected);

            record.linkValue = linkValue;
            updateProjects(record);
          },
        }),
      );
    }
    return cols;
  }, [
    title,
    integrationKey,
    importHelpText,
    projects,
    projectMap,
    connected,
    stateProjects,
    numberSet,
    updateProjects,
    canImport,
    divisionId,
    linkableProjects,
    divisionFilter,
  ]);

  const integrationCols = useMemo(() => getCols(true), [getCols]);
  const ontraccrCols = useMemo(() => getCols(false), [getCols]);

  const activeProjects = useMemo(() => (
    linkableProjects
      .filter((project) => (
        !searchStr || includesTerm(project.name, searchStr)
      ))
  ), [linkableProjects, searchStr, divisionId]);
  const activeStateProjects = useMemo(() => (
    stateProjects.filter((proj) => !searchStr || includesTerm(proj.name, searchStr))
  ), [stateProjects, searchStr]);

  if (loading) {
    return (
      <Row style={{ height: '100%', width: '100%' }} justify="center" align="middle">
        <Col>
          <Spin />
        </Col>
      </Row>
    );
  }
  return (
    <div style={{ height: 'calc(100% - 55px)', width: '100%', overflowY: 'scroll' }}>
      {showAutoImport && (
        <AutoImport
          setActivePanels={setActivePanels}
          setAutoImport={setAutoImport}
          autoImport={autoImport}
          helpContent={autoImportContent}
          importType={t('Project')}
          importData={importProjects}
        />
      )}
      {!autoImport && (
        <OnTraccrTextInput
          allowClear
          style={{
            width: 250,
          }}
          search
          onChange={(e) => {
            const {
              target: {
                value,
              } = {},
            } = e;
            setSearchStr(value);
          }}
          onClick={(e) => e.stopPropagation()}
        />
      )}
      {!autoImport && (
        <Collapse ghost activeKey={activePanels} onChange={setActivePanels}>
          <Panel
            header={`New ${title} ${t('Project', { count: 0 })}`}
            key="new"
            className="ontraccr-collapse-panel"
          >
            <Table
              bordered
              size="small"
              columns={integrationCols}
              dataSource={activeStateProjects}
              scroll={{
                y: '40%',
              }}
              rowKey={integrationKey}
              pagination={{
                hideOnSinglePage: true,
                pageSize: 50,
                showSizeChanger: false,
              }}
            />
          </Panel>
          <Panel
            header={`Ontraccr ${t('Project', { count: 0 })}`}
            key="ontraccr"
            className="ontraccr-collapse-panel"
          >
            <Table
              bordered
              size="small"
              columns={ontraccrCols}
              dataSource={activeProjects}
              scroll={{
                y: '40%',
              }}
              rowKey="id"
              pagination={{
                hideOnSinglePage: true,
                pageSize: 50,
                showSizeChanger: false,
              }}
            />
          </Panel>
        </Collapse>
      )}
    </div>
  );
});
