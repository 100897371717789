import React, { useCallback, useState } from 'react';
import { Row } from 'antd';
import {
  SettingOutlined
 } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';

const updateData = (id, newData) => (dataMap = {}) => {
  const {
    [id]: oldData = {}, 
  } = dataMap;
  return {
    ...dataMap,
    [id]: {
      ...oldData,
      ...newData,
    },
  };
};

export default ({
  setElements,
  setDataMap,
  isDisplay,
  name,
  sections = [],  
  id,
  draggable,
  disabled,
  data = {},
  title,
  Icon,
  type,
  hover,
  style = {},
  ConfigureDrawer,
  children,
  fields,
  requiredFields,
}) => {
  const {
    fieldMappings: initialFieldMappings = {},
  } = data;

  const [showDrawer,setShowDrawer] = useState(false);
  const [fieldMappings,setFieldMappings] = useState(initialFieldMappings);

  const openDrawer = useCallback(() => setShowDrawer(true),[]);
  const closeDrawer = useCallback(() => setShowDrawer(false),[]);

  const onFieldMappingsChange = useCallback((newMappings) => {
    if(!setDataMap || !id) return;
    setFieldMappings(newMappings);
    setDataMap(updateData(id, { fieldMappings: newMappings, }));
  },[setDataMap, id]);

  return (
    <WorkflowActionNode
      id={id}
      title={title}
      Icon={Icon}
      type={type}
      draggable={draggable}
      onNodeUpdate={setElements}
      isDisplay={isDisplay || disabled}
      hover={hover}
      style={style}
    >
      {!draggable && <div>
        <Row style={{ margin: '20px 0px' }}>
          <BorderlessButton
            title='Configure'
            style={{ margin: 5 }}
            iconNode={<SettingOutlined/>}
            onClick={openDrawer}
          />
        </Row>
        {children}
            
        <WorkflowHandle type='target' position='top' disabled={isDisplay}/>
        <ConfigureDrawer
          id={id}
          visible={showDrawer}
          onClose={closeDrawer}
          onSubmit={closeDrawer}
          sourceName={name}
          sourceSections={sections}
          fieldMappings={fieldMappings}
          onFieldMappingsChange={onFieldMappingsChange}
          data={data}
          setDataMap={setDataMap}
          fields={fields}
          requiredFields={requiredFields}
        />
      </div>}
    </WorkflowActionNode>
  )
}
