import React from 'react';
import { Row, Col } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import SideListText from '../common/SideList/SideListText';

import colors from '../constants/Colors';

function TeamsSideListItem({
  name,
  members,
}) {
  return (
    <Row
      type="flex"
      align="middle"
      style={{
        width: '100%',
        pointerEvents: 'all',
        cursor: 'pointer',
        height: 30,
      }}
    >
      <Col span={20}>
        <SideListText text={name} scrollable />
      </Col>
      <Col
        span={4}
        style={{
          fontFamily: 'raleway-semibold',
          color: colors.ONTRACCR_DARK_YELLOW,
          pointerEvents: 'none',
          fontSize: 20,
        }}
      >
        {members.length}
        <TeamOutlined style={{ marginLeft: 5 }} />
      </Col>
    </Row>
  );
}

TeamsSideListItem.propTypes = {
  name: PropTypes.string.isRequired,
  members: PropTypes.array.isRequired, // eslint-disable-line react/forbid-prop-types
};

export default TeamsSideListItem;
