import React, { useMemo } from 'react';
import { Form } from 'antd';

import StepsContainer from '../common/containers/StepsContainer';

import ContactGeneralInfo from './ContactGeneralInfo';
import ContactBillingTab from './ContactBillingTab';
import VendorType from './vendors/VendorType';
import { useGetContactLabels } from '../helpers/labels';

const getSteps = ({
  isVendor,
  props,
  editing,
  isAdd,
  customBillingRate,
}) => {
  const steps = [
    {
      title: 'General Info',
      content: <ContactGeneralInfo isVendor= {isVendor} {...props}/>,
      key: 'generalInfo',
    },
  ];

  if (!isVendor) {
    steps.push(
      {
        title: 'Billing',
        content: <ContactBillingTab {...props} isNotDisplay={editing || isAdd} customBillingRate={customBillingRate}/>,
        key: 'billing',
      }
    );
  }

  if (isVendor) {
    steps.push(
      {
        title: 'Type',
        content: <VendorType {...props} isNotDisplay={editing || isAdd} />,
        key: 'type',
      },
    );
  }
  return steps;
};

export default function ContactAddView(props) {
  const {
    id,
    isVendor,
    form: {
      formRef,
      isAdd,
      currentStep,
    } = {},
    editing,
    materialDiscount,
    customBillingRate,
    type: vendorType,
  } = props;

  const initialLabels = useGetContactLabels(id, isVendor);

  const steps = useMemo(() => (
    getSteps({
      isVendor,
      props,
      editing,
      isAdd,
      customBillingRate,
    })
  ), [
    isVendor,
    props,
    editing,
    isAdd,
    customBillingRate,
  ]);

  return (
    <Form
      className={editing || isAdd ? null : 'customer-display-form'}
      ref={formRef}
      layout="vertical"
      initialValues={{
        ...props,
        materialDiscount: materialDiscount ? materialDiscount * 100 : materialDiscount,
        type: vendorType || 'Subcontractor',
        labels: initialLabels,
      }}
    >
      <StepsContainer
        style={{ marginBottom: 0 }}
        currentStep={currentStep}
        steps={steps}
      />
    </Form>
  );
}
