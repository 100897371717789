import React from 'react';
import {
  Row,
  Col,
  Card,
  Typography,
  Popover
} from 'antd';
import colors from '../constants/Colors';

const { Text, } = Typography;

const rowStyle = {width:'100%',lineHeight:'13px'}
const valueStyle = {
  fontFamily:'raleway-semibold',
  fontSize:10,
  color:colors.ONTRACCR_BLACK,
  width:'100%',
}
export default ({
  tasks,
  days,
  userId,
  onClick
}) => (
  <Popover content={'Click to see the whole pay period'} title='This is the current week' trigger="hover">
    <Card 
      hoverable={true}
      onClick={onClick ? (e) => {
        e.stopPropagation();
        onClick(tasks,userId);
      } : null}
      bodyStyle={{
        padding:2,
      }}
      style={{
        borderRadius:5,
        backgroundColor: colors.ONTRACCR_MODAL_BACKGROUND_YELLOW,
        padding:'4px 4px 4px 4px',
        marginLeft: '20px',
        width:70
    }}>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Mon
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[0]}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Tue
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[1]}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Wed
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[2]}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Thu
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[3]}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Fri
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[4]}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Sat
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[5]}
            </Text>
          </Row>
        </Col>
      </Row>
      <Row justify='space-between' >
        <Col span={12}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              Sun
            </Text>
          </Row>
        </Col>
        <Col span={12} style={{ textAlign:'right'}}>
          <Row style={rowStyle}>
            <Text style={valueStyle}>
              {days[6]}
            </Text>
          </Row>
        </Col>
      </Row>
      

    </Card>
  </Popover>
  
);