import React,{ useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Divider, Col } from 'antd';

import FormColorPicker from '../../common/inputs/FormColorPicker';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../common/text/DisplayText';

import CompanySettingsCard from '../CompanySettingsCard';
import CompanyEditRow from '../CompanyEditRow';

import {
  fallbackPrimaryColor,
  fallbackAccentColor,
} from '../../clientPortal/clientPortal.constants';

import {
  updateClientPortalSettings
} from '../../clientPortal/state/clientPortal.actions';

import config from '../../config';

import colors from '../../constants/Colors';
import Debouncer from '../../helpers/Debouncer';

let baseEndpoint = 'https://app.ontraccr.com/';
if (config.env === 'alpha') {
  baseEndpoint = 'https://app.alpha.ontraccr.com/';
}

const debouncer = new Debouncer();
export default () => {
  const dispatch = useDispatch();
  const portalSettings = useSelector(state => state.clientPortal.settings);

  const [footer,setFooter] = useState(portalSettings.footer);

  const defaultPrimaryColor = portalSettings.primaryColor ?? fallbackPrimaryColor
  const defaultAccentColor = portalSettings.accentColor ?? fallbackAccentColor;

  const onChangeData = useCallback((key) => async (value) => {
    const newData = {
      ...portalSettings,
      [key]: value,
    };
   return dispatch(updateClientPortalSettings(newData))
  },[dispatch, portalSettings]);

  const onTextChanged = useCallback((key) => async (e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    if (key === 'footer') setFooter(newValue);
    await debouncer.debounce(async() => {
      const passed = await onChangeData(key)(newValue);
      if (key !== 'footer') return;
      if (!passed) setFooter(portalSettings.footer);
    },1000);
  },[onChangeData, portalSettings]);

  useEffect(() => {
    setFooter(portalSettings.footer);
  },[portalSettings]);

  return (
    <CompanySettingsCard>
       <CompanyEditRow
        title='Portal Address'
        childColumnStyle={{ width: 100 }}
        style={{ paddingLeft: 10, paddingRight: 20 }}
        helpText='This is the link that your clients will use to access their portal'
        divider={false}
      />
      <Row justify='start' align='middle' gutter={2} style={{ padding: 10, marginLeft: 10, marginRight: 20 }}>
        <Col>
          <DisplayText title={baseEndpoint} style={{
            fontFamily: 'roboto-regular', marginBottom:0,color:colors.ONTRACCR_OPACITY_GRAY
          }}/>
        </Col>
        <Col flex='auto'>
          <OnTraccrTextInput
            key={portalSettings.url}
            style={{ width: '100%' }}
            defaultValue={portalSettings.url}
            onChange={onTextChanged('url')}
          />
        </Col>
      </Row>
      <Divider style={{ margin: 0 }}/>
      <CompanyEditRow
        title='Primary Color'
        childColumnStyle={{ width: 100 }}
        style={{ paddingLeft: 10, paddingRight: 20 }}
        helpText='This is the main color for your client portal'
      >
        <FormColorPicker
          isNotDisplay
          onChange={onChangeData('primaryColor')}
          value={portalSettings.primaryColor ?? defaultPrimaryColor}
        />
      </CompanyEditRow>
      <CompanyEditRow
        title='Accent Color'
        childColumnStyle={{ width: 100 }}
        style={{ paddingLeft: 10, paddingRight: 20 }}
        helpText='Your client portal buttons will have this color'
      >
        <FormColorPicker
          isNotDisplay
          onChange={onChangeData('accentColor')}
          value={portalSettings.accentColor ?? defaultAccentColor}
        />
      </CompanyEditRow>
      <CompanyEditRow
        title='Header Text'
        style={{ paddingLeft: 10, paddingRight: 20 }}
        helpText='This text will appear in the client portal header'
        divider={false}
      />
      <OnTraccrTextInput
        key={portalSettings.tagline}
        defaultValue={portalSettings.tagline}
        onChange={onTextChanged('tagline')}
        style={{ margin: '0px 30px 10px 20px', width: 'calc(100% - 50px)' }}
      />
      <Divider style={{ margin: 0 }}/>
      <CompanyEditRow
        title='Footer'
        style={{ paddingLeft: 10, paddingRight: 20 }}
        helpText='This text will appear in the client portal footer'
        divider={false}
      />
      <OnTraccrTextInput
        value={footer}
        textarea
        onChange={onTextChanged('footer')}
        style={{ margin: '0px 30px 20px 20px', width: 'calc(100% - 50px)' }}
      />
    </CompanySettingsCard>
  );
}