import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import moment from 'moment';
import {
  Row,
  Col,
  DatePicker,
  Radio,
} from 'antd';
import PropTypes from 'prop-types';

import DateRangePickerButtons from './DateRangePickerButtons';
import DateFilter from '../../datePicker/DateFilter';
import { getDateRangeFromConfig } from '../../analytics/analytics.helpers';

const invisibleButtonStyle = { opacity: 0, pointerEvents: 'none' };

const getCalendarConfig = (payPeriod, selectedType, startDate) => {
  const calendarMoveCount = !(['Monthly', 'Weekly']).includes(payPeriod) && selectedType === 'payPeriod' ? 2 : 1;
  const calendarMoveRange = payPeriod === 'Monthly' && selectedType === 'payPeriod' ? 'month' : 'w';
  return {
    calendarMoveCount,
    calendarMoveRange,
    leftButtonStyle: selectedType !== 'custom' && startDate ? {} : invisibleButtonStyle,
    rightButtonStyle: selectedType !== 'custom'
      && startDate && startDate.clone().add(calendarMoveCount, calendarMoveRange).isBefore(moment())
      ? {} : invisibleButtonStyle,
  };
};

export default function DateRangePicker({
  onRangeChange,
  hideCustom = false,
}) {
  const { payPeriod } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings = {},
        } = {},
      } = {},
    } = state;
    return settings;
  });
  const [selectedType, setSelectedType] = useState('payPeriod');
  const [dateRange, setDateRange] = useState([
    moment().startOf('day').subtract(7, 'd'),
    moment().endOf('day'),
  ]);
  const [datePreset, setDatePreset] = useState(undefined);

  const formattedRange = useMemo(() => (
    getDateRangeFromConfig({ dateRange, datePreset })
  ), [datePreset, dateRange]);

  useEffect(() => {
    if (onRangeChange
      && formattedRange.length === 2
      && moment.isMoment(formattedRange[0])
      && moment.isMoment(formattedRange[1])
    ) {
      const start = formattedRange[0].valueOf();
      let end = formattedRange[1].valueOf();
      if (selectedType === 'week' || selectedType === 'payPeriod') {
        const {
          calendarMoveCount,
          calendarMoveRange,
        } = getCalendarConfig(payPeriod, selectedType, formattedRange[0]);
        end = formattedRange[0].clone().add(calendarMoveCount, calendarMoveRange).endOf('day').valueOf();
      }
      onRangeChange([start, end]);
    }
  }, [formattedRange, onRangeChange, selectedType, payPeriod]);

  const radios = useMemo(() => {
    const group = [{
      label: 'Pay Period', value: 'payPeriod',
    }, {
      label: 'Week', value: 'week',
    }];
    if (!hideCustom) {
      group.push({
        label: 'Custom', value: 'custom',
      });
    }
    return (
      <Radio.Group
        options={group}
        onChange={(e) => {
          const {
            target: {
              value: type,
            } = {},
          } = e;
          setSelectedType(type);
          if (type !== 'custom') {
            setDatePreset(undefined);
          }
        }}
        value={selectedType}
      />
    );
  }, [hideCustom, selectedType]);

  const onPresetClicked = useCallback((preset) => {
    setDatePreset(preset);
    setSelectedType('custom');
  }, [selectedType]);

  const picker = useMemo(() => {
    switch (selectedType) {
      case 'payPeriod':
      case 'week':
        return (
          <DatePicker
            allowClear={false}
            style={{ width: 250 }}
            format={`[${selectedType === 'week' ? 'Week' : 'Pay Period'} of] MMM Do YY`}
            disabledDate={(current) => current > moment().endOf('day')}
            renderExtraFooter={() => (
              <>
                {radios}
                <DateFilter datePreset={datePreset} handlePreset={onPresetClicked} />
              </>
            )}
            picker="week"
            value={formattedRange[0]}
            onChange={(newVal) => setDateRange([newVal, newVal])}
            getPopupContainer={(node) => node.parentNode}
          />
        );
      default:
        return (
          <DatePicker.RangePicker
            allowClear={false}
            style={{ width: 250 }}
            format="MMM Do YY"
            disabledDate={(current) => current > moment().endOf('day')}
            renderExtraFooter={() => (
              <>
                {radios}
                <DateFilter datePreset={datePreset} handlePreset={onPresetClicked} />
              </>
            )}
            value={formattedRange}
            onChange={(newRange) => {
              /*
                Need to clear preset or the range will be stuck on preset value
                https://projectharbour.atlassian.net/browse/HARBOUR-5152
              */
              setDatePreset(undefined);
              setDateRange(newRange);
            }}
            getPopupContainer={(node) => node.parentNode}
          />
        );
    }
  }, [formattedRange, radios, selectedType, formattedRange]);

  const {
    calendarMoveCount,
    calendarMoveRange,
    leftButtonStyle,
    rightButtonStyle,
  } = getCalendarConfig(payPeriod, selectedType, formattedRange[0]);

  return (
    <Row justify="start" gutter={16} align="middle">
      <Col>
        <DateRangePickerButtons
          left
          onClick={() => {
            const newVal = moment.isMoment(formattedRange[0])
              ? formattedRange[0].clone().subtract(calendarMoveCount, calendarMoveRange)
              : moment();
            setDateRange([newVal, newVal.clone().add(calendarMoveCount, calendarMoveRange)]);
          }}
          style={leftButtonStyle}
        />
      </Col>
      <Col>
        {picker}
      </Col>
      <Col>
        <DateRangePickerButtons
          onClick={() => {
            const newVal = moment.isMoment(formattedRange[0])
              ? formattedRange[0].clone().add(calendarMoveCount, calendarMoveRange)
              : moment();
            setDateRange([newVal, newVal.clone().add(calendarMoveCount, calendarMoveRange)]);
          }}
          style={rightButtonStyle}
        />
      </Col>
    </Row>
  );
}

DateRangePicker.propTypes = {
  onRangeChange: PropTypes.func.isRequired,
  hideCustom: PropTypes.bool,
};

DateRangePicker.defaultProps = {
  hideCustom: false,
};
