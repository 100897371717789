import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import { Upload } from 'antd';
import { CloudDownloadOutlined, UploadOutlined } from '@ant-design/icons';

import OnTraccrButton from '../buttons/OnTraccrButton';

import { checkFileIsSpreadsheet, parseWorkbook } from '../../files/fileHelpers';

function SheetUploadPrompt({
  onFileChange,
  templateLink,
  hideUploadMessage = false,
  showUploadList = true,
}) {
  const [loading, setLoading] = useState(false);

  const onUpload = useCallback((obj) => {
    // Required for antd upload to not barf out.
    setTimeout(() => {
      obj.onSuccess('ok');
    }, 0);
  }, []);

  const onChange = useCallback((info) => {
    const {
      file = {},
    } = info;
    const { status } = file;
    switch (status) {
      case 'removed': {
        break;
      }
      case 'uploading': {
        setLoading(true);
        break;
      }
      case 'done': {
        const { originFileObj } = file;
        parseWorkbook(originFileObj, (workbook) => {
          onFileChange(workbook);
          setLoading(false);
        });
        break;
      }
      case 'error':
      default: {
        setLoading(false);
        break;
      }
    }
  }, []);

  return (
    <>
      <Upload
        customRequest={onUpload}
        onChange={onChange}
        beforeUpload={checkFileIsSpreadsheet}
        style={{ marginBottom: 30 }}
        showUploadList={showUploadList}
      >
        <OnTraccrButton
          title="Upload"
          icon={<UploadOutlined />}
          loading={loading}
        />
        { !hideUploadMessage && (
          <p className="upload-format-text">Supports .xlsx or .xls file types</p>
        )}
      </Upload>
      {templateLink && (
        <div style={{ marginTop: 15, marginBottom: 15 }}>
          <div className="template-link-text">
            Need a template?
          </div>
          <OnTraccrButton
            style={{ marginTop: 10 }}
            type="cancel"
            title="Download"
            icon={<CloudDownloadOutlined />}
            href={templateLink}
          />
        </div>
      )}
    </>
  );
}

SheetUploadPrompt.propTypes = {
  onFileChange: PropTypes.func.isRequired,
  templateLink: PropTypes.string,
  hideUploadMessage: PropTypes.bool,
  showUploadList: PropTypes.bool,
};

SheetUploadPrompt.defaultProps = {
  templateLink: null,
  hideUploadMessage: false,
  showUploadList: true,
};

export default SheetUploadPrompt;
