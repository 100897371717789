import React, { useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Select } from 'antd';

import DisplayText from '../common/text/DisplayText';

const { Option } = Select;

const getRateTitle = ({ name, rate } = {}) => name && rate ? `${name} - $${rate.toFixed(2)}` : '';
export default ({
  billingRateId,
  isNotDisplay,
  onChange,
  onlyNames,
  displayStyle = {},
  style = {},
  customEntries = [],
}) => {
  const rates = useSelector(state => state.billingRates);
  const rateList = useMemo(() => Object.values(rates).concat(customEntries),[rates,customEntries]);
  const ourRateTitle = useMemo(() => {
    const {
      [billingRateId]: ourRate = {},
    } = rates;
    return getRateTitle(ourRate);
  },[rates, billingRateId,]);

  const onBillingRateChange = useCallback((value) => {
    onChange(value ?? null);
  }, [onChange]);

  if(!isNotDisplay) {
    return <DisplayText title={ourRateTitle} style={displayStyle}/>
  }
  return (
    <Select
      onChange={onBillingRateChange}
      placeholder='Select a billing rate'
      value={billingRateId}
      allowClear
      showSearch
      optionFilterProp='title'
      style={style}
    >
      {
        rateList.map(({ id, name, rate }) => {
          const title = onlyNames ? name : getRateTitle({ name, rate });
          return (
            <Option
              key={id}
              title={title}
              value={id}
            >
              {title}
            </Option>
          );
        })
      }
    </Select>
  );
};
