import React, { useEffect, useState, useCallback } from 'react';
import { Spin, Row, Col, Select } from 'antd';
import {
  ZoomOutOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';
import axios from 'axios';

import  { parseExcel } from './excelHelpers';
import BorderlessButton from '../buttons/BorderlessButton';

const zoomLevels = [0.1, 0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2, 4];

const fileGetter = axios.create({
  responseType: 'blob',
});
export default ({ url, file }) => {
  const [stateFile,setStateFile] = useState(file);
  const [excelData,setExcelData] = useState();
  const [selectedSheet,setSelectedSheet] = useState();
  const [sheets,setSheets] = useState([]);
  const [scaleIndex,setScaleIndex] = useState(4);

  const selectSheet = useCallback((sheetName) => () => setSelectedSheet(sheetName),[]);

  useEffect(() => {
    const getFile = async (url) => {
      const res = await fileGetter.get(url);
      const {
        data,
      } = res;
      setStateFile(data);
    };
    if(file && file instanceof File) {
      setStateFile(file)
    } else if (url) {
      getFile(url);
    } else {
      setStateFile();
    }
  },[url,file]);

  useEffect(() => {
    const parseFile = async (data) => {
      if (data?.arrayBuffer) {
        const buffer = await data.arrayBuffer();
        setExcelData(parseExcel(buffer));
      }
    }
    if(stateFile) parseFile(stateFile);
  },[stateFile]);

  useEffect(() => {
    if(excelData) {
      const { sheetNames } = excelData;
      setSelectedSheet(sheetNames[0]);
      setSheets(sheetNames);
    }
  },[excelData]);

  const zoom = zoomLevels[scaleIndex];
  return excelData && selectedSheet ? 
    <div
      className='excel-container' 
      style={{ backgroundColor:'white', overflow:'hidden'}}
    >
      <Row style={{
        height: 'calc(100% - 40px)',
        width:'100%',
        overflow:'scroll',
      }} justify='start' align='top'>
          <table style={{ 
            minWidth:'100%', 
            minHeight:'100%', 
            transform:`scale(${zoom})`,
            transformOrigin:'left top'
            }}>
            <tbody>
              {excelData.tables[selectedSheet]}
            </tbody>
          </table>
      </Row>
      <Row className='excel-footer' justify='space-between'>
        <Col flex='auto'>
          <Row className='excel-sheet-selector' align='bottom'>
            {sheets.map((sheetName) => (
              <Col 
                className={sheetName === selectedSheet ? 'excel-sheet-selected-tab' : 'excel-sheet-tab'}
                onClick={selectSheet(sheetName)}
                key={sheetName}
              >
                {sheetName}
              </Col>
            ))}
          </Row>
        </Col>
        <Col flex='200px'>
          <Row style={{ width:'100%',height:'100%'}} gutter={10} align='middle'>
            <Col>
              <BorderlessButton
                style={{ backgroundColor:'transparent '}}
                iconNode={<ZoomOutOutlined style={{ margin:0 }}/>}
                onClick={() => setScaleIndex(scaleIndex - 1)}
                disabled={scaleIndex === 0}
              />
            </Col>
            <Col flex='55px' style={{ textAlign:'center'}}>
              <Select value={scaleIndex} onChange={setScaleIndex}>
              {zoomLevels.map((level, index) => (
                <Select.Option key={level} value={index}>{`${parseInt(level * 100)} %`}</Select.Option>
              ))}
              </Select>
            </Col>
            <Col>
              <BorderlessButton
                style={{ backgroundColor:'transparent '}}
                iconNode={<ZoomInOutlined style={{ margin:0 }}/>}
                onClick={() => setScaleIndex(scaleIndex + 1)}
                disabled={scaleIndex === zoomLevels.length - 1}
              />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
    : <Spin/>;
}