import React, {
  useState,
  useRef,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Select,
  Switch,
} from 'antd';
import PropTypes from 'prop-types';
import pdfMake from 'pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

import { getCompanyImageURL } from '../../settings/state/settings.actions';
import { basePdfDefinition, getBase64ImageFromURL } from '../../projects/ProjectScheduleOfValues/PDF/sov.pdf.helpers';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import GanttScheduleColumnsSection from './GanttScheduleColumnsSection';
import { escapeValueForCSV, ganttColumns, getVisibleColumnMap } from './ganttScheduleHelpers';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function GanttScheduleExportDrawer(props) {
  const {
    tasks,
    onClose,
    visible,
    userSettings,
  } = props;
  const dispatch = useDispatch();
  const companyImageURL = useSelector((state) => state.settings.company.companyImageURL);

  const [exportFormat, setExportFormat] = useState('pdf');
  const [pdfDefinition, setPdfDefinition] = useState(null);
  const [isCompanyImageOn, setIsCompanyImageOn] = useState(!!companyImageURL);
  const [visibleColumns, setVisibleColumns] = useState([]);
  const [pdf, setPdf] = useState(null);
  const [csvData, setCSVData] = useState();

  const pdfRef = useRef(null);

  useEffect(() => {
    dispatch(getCompanyImageURL());
  }, []);

  useEffect(() => {
    setIsCompanyImageOn(!!companyImageURL);
  }, [companyImageURL]);

  useEffect(() => {
    if (visible) {
      if (userSettings.ganttScheduleSettings) {
        try {
          setVisibleColumns(JSON.parse(userSettings.ganttScheduleSettings).visibleColumns);
        } catch (e) {
          setVisibleColumns([ganttColumns[0]]);
        }
      } else {
        setVisibleColumns([ganttColumns[0]]);
      }
    }
  }, [userSettings, visible, setVisibleColumns]);

  const onCompanyImageToggled = (e) => {
    setIsCompanyImageOn(e);
  };

  useEffect(() => {
    if (
      visible
      && exportFormat === 'pdf'
      && pdfDefinition
      && pdfRef.current
    ) {
      const pdfDocGenerator = pdfMake.createPdf(pdfDefinition);
      pdfDocGenerator.getDataUrl((dataUrl) => {
        pdfRef.current.src = dataUrl;
      });

      setPdf(pdfDocGenerator);
    }
  }, [
    exportFormat,
    visible,
    pdfDefinition,
    pdfRef,
  ]);

  useEffect(() => {
    const generateExportData = async () => {
      let csv = '';
      const newPdfDefinition = {
        ...basePdfDefinition,
        content: [],
        pageOrientation: 'landscape',
      };

      const pdfHeader = {
        columns: [],
        style: 'pdfHeader',
      };

      if (isCompanyImageOn && companyImageURL) {
        let image;
        try {
          image = await getBase64ImageFromURL(companyImageURL);
        } catch (e) {
          // silently fail
        }

        if (image) {
          pdfHeader.columns.push({
            image,
            width: 150,
            maxWidth: 150,
            maxHeight: 100,
          });
        }
      }

      newPdfDefinition.content.push(pdfHeader);

      const tableHeaders = visibleColumns.map((column) => ({
        text: column,
        style: 'tableHeader',
      }));

      csv += `${visibleColumns.join(',')}\r\n`;

      const visibleColumnMap = getVisibleColumnMap({
        tasks,
        activeTab: '',
      });

      const table = {
        headerRows: 1,
        body: [],
      };

      const tableRows = [];
      tasks.forEach((task) => {
        const row = [];
        visibleColumns.forEach((column) => {
          const col = visibleColumnMap[column];
          row.push(
            col.pdfFormat
              ? col.pdfFormat(task[col.property])
              : {
                text: task[col.property],
                style: 'tableCell',
              },
          );
          csv += escapeValueForCSV(
            col.csvFormat
              ? col.csvFormat(task[col.property])
              : task[col.property],
          );
        });
        csv += '\r\n';
        tableRows.push(row);
      });

      table.body.push(tableHeaders, ...tableRows);
      newPdfDefinition.content.push({
        table,
      });
      setPdfDefinition(newPdfDefinition);
      setCSVData(csv);
    };

    generateExportData();
  }, [
    tasks,
    visibleColumns,
    isCompanyImageOn,
  ]);

  const updateExportFormat = (e) => {
    setExportFormat(e);
  };

  const onExport = () => {
    if (exportFormat === 'pdf') {
      pdf.download();
    } else {
      const csv = csvData;
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
      const url = URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.setAttribute('href', url);
      link.setAttribute('download', 'export.csv');
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  };

  return (
    <Drawer
      title="Export"
      visible={visible}
      onClose={onClose}
      width={1100}
      destroyOnClose
    >
      <Form.Item label="Export Format">
        <Select onChange={updateExportFormat} value={exportFormat}>
          <Select.Option value="pdf">PDF</Select.Option>
          <Select.Option value="csv">csv</Select.Option>
        </Select>
      </Form.Item>
      <GanttScheduleColumnsSection
        visibleColumns={visibleColumns}
        setVisibleColumns={setVisibleColumns}
      />
      { exportFormat === 'pdf' && (
        <>
          <Form.Item name="companyImage" label="Load in company image">
            <Switch checked={isCompanyImageOn} onChange={onCompanyImageToggled} />
          </Form.Item>
          <iframe
            title="Gantt PDF"
            ref={pdfRef}
            className="sov-pdf-preview-frame"
            style={{ marginBottom: 25 }}
          />
        </>
      )}
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onExport}
        submitTitle="Export"
      />
    </Drawer>
  );
}

GanttScheduleExportDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    progress: PropTypes.number.isRequired,
    dependencies: PropTypes.arrayOf(PropTypes.string),
    custom_class: PropTypes.string,
  })).isRequired,
  userSettings: PropTypes.shape({
    ganttScheduleSettings: PropTypes.string,
  }).isRequired,
};
