/* eslint-disable */
import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tabs, Form } from 'antd';
import { useTranslation } from 'react-i18next';

import Permissions from '../auth/Permissions';
import BoardLinkView from '../boards/BoardLinkView';
import BadgedTab from '../common/containers/BadgedTab';

import ContactAddView from './ContactAddView';
import ContactGeneralInfo from './ContactGeneralInfo';
import ProjectList from '../common/projects/ProjectList';
import ContactBillingTab from './ContactBillingTab';
import CustomerWorkOrdersTab from './customers/CustomerWorkOrdersTab';
import ContactOtherTab from './ContactOtherTab';
import PayablesList from '../common/forms/PayablesList';
import CustomerClientPortal from './customers/CustomerClientPortal';

import Notes from '../notes/Notes';

import {
  getNotes as customerGetNotes,
  addNote  as customerAddNote,
} from './customers/state/customers.actions';
import {
  getNotes as vendorGetNotes,
  addNote as vendorAddNote,
} from './vendors/state/vendors.actions';

import config from '../config';

import ContactAddressBookTab from './ContactAddressBookTab';
import Certifications from '../certifications/Certifications';

import { useGetContactLabels } from '../helpers/labels';
import { updateProjectsCustomer } from '../projects/state/projects.actions';
import { getBuckets } from '../buckets/state/buckets.actions';

const tabStyle = {
  minHeight:'100%',
  width:'100%',
};

const { TabPane } = Tabs;

const getClassName = (editing) => {
  if(editing) return null;
  return Permissions.has('CUSTOMERS_WRITE')
    ? 'customer-display-form-with-footer'
    : 'customer-display-form-without-footer'
};

const GENERAL_INFO = 'generalInfo';
const PAYABLES = 'payables';
const BILLING = 'billing';
const WORK_ORDERS = 'workOrders';
const OTHER_FORMS = 'otherForms';
const ADDRESS_BOOK = 'addressBook';
const SUB_CONTRACTS = 'subContracts';

const CERT_TYPE = 'vendors';

export default (props) => {
  const {
    id,
    name,
    editing,
    materialDiscount,
    form: {
      formRef,
    } = {},
    isVendor,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const notes = useSelector(state => isVendor ? state.vendors.notes : state.customers.notes);
  const cardLinks = useSelector(state => state.boards.cardLinks);
  const projects = useSelector(state => state.projects.projects);
  const unreadClientComms = useSelector(state => state.customers.unreadClientComms);

  const initialLabels = useGetContactLabels(id, isVendor);

  const [activeTab, setActiveTab] = useState(GENERAL_INFO);

  const unreadCount = useMemo(() => (
    unreadClientComms.filter((note) => note.customerId === id).length
  ),[unreadClientComms, id]);

  const ourLinks = useMemo(() => {
    if (!id || editing) return [];
    const ourProjects = projects.filter((proj) => proj.customerId === id);
    const projectIdSet = new Set(ourProjects.map((proj) => proj.id));
    return cardLinks.filter((cardLink) => (
      cardLink.customerId === id || projectIdSet.has(cardLink.projectId)
    ));
  },[id,editing,cardLinks, projects]);

  useEffect(() => {
    setActiveTab(GENERAL_INFO);
  },[id]);

  useEffect(() => {
    dispatch(getBuckets());
  }, []);

  const onAddExistingProjects = useCallback(async (projectIds) => {
    return dispatch(updateProjectsCustomer({ customerId: id, projectIds }));
  }, [id]);

  const className = getClassName(editing);
  const hasWritePerms = Permissions.has('CUSTOMERS_WRITE');
  const hasVendorRead = Permissions.has('VENDORS_READ');
  if(editing) return <ContactAddView {...props} isVendor={isVendor}/>;
  return (
    <Form
      className={className}
      ref={formRef}
      layout='vertical'
      initialValues={{
        ...props,
        materialDiscount: materialDiscount ? materialDiscount * 100 : materialDiscount,
        labels: initialLabels,
      }}
    >
      <Tabs
        className='customer-tab-container'
        activeKey={activeTab}
        onChange={setActiveTab}
      >
        <TabPane tab='General Info' key={GENERAL_INFO} style={tabStyle}>
          <ContactGeneralInfo {...props}/>
        </TabPane>
        {!isVendor && <TabPane tab={t('Project', { count: 2 })} key='projects' style={tabStyle}>
          <ProjectList
            name={name}
            customerId={id}
            isDisplay={!hasWritePerms}
            onAddExistingProjects={onAddExistingProjects}
            type="Customer"
          />
        </TabPane>}
        <TabPane tab='Notes' key='notes' style={tabStyle}>
          <Notes
            id={id}
            notes={notes}
            addNote={isVendor ? vendorAddNote : customerAddNote}
            getNotes={isVendor ? vendorGetNotes : customerGetNotes}
          />
        </TabPane>
        <TabPane tab='Payables' key={PAYABLES} style={tabStyle}>
          <PayablesList
            {...props}
            /*
              type is not a display text
              it is used to construct the query in FilteredFormList
              so we dont translate here
            */
            type={isVendor ? 'Vendors' : 'Customers'}
            visible={activeTab === PAYABLES}
          />
        </TabPane>
        <TabPane tab='Billing' key={BILLING} style={tabStyle}>
          <ContactBillingTab {...props} visible={activeTab === BILLING} isVendor={isVendor} />
        </TabPane>
        {
          isVendor && (
            <TabPane tab="Sub-Contracts" key={SUB_CONTRACTS} style={tabStyle}>
              <PayablesList
                id={id}
                type="Vendors"
                formType="Sub-Contract"
                visible={activeTab === SUB_CONTRACTS}
                hideDate
              />
            </TabPane>
          )
        }
        {!isVendor && <TabPane tab='Work Orders' key={WORK_ORDERS} style={tabStyle}>
          <CustomerWorkOrdersTab {...props} visible={activeTab === WORK_ORDERS}/>
        </TabPane>}
        <TabPane tab='Other Forms' key={OTHER_FORMS} style={tabStyle}>
          <ContactOtherTab {...props} visible={activeTab === OTHER_FORMS} isVendor={isVendor} />
        </TabPane>
        {ourLinks.length > 0 && !isVendor && <TabPane tab='Cards' key='cards' style={tabStyle}>
          <BoardLinkView links={ourLinks} linkType='customerId' linkId={id}/>
        </TabPane>}
        <TabPane tab='Address Book' key={ADDRESS_BOOK} style={tabStyle}>
          <ContactAddressBookTab {...props} visible={activeTab === ADDRESS_BOOK} />
        </TabPane>
        {
          config.showClientPortal && !isVendor &&
          <TabPane
            key='clientPortalAccounts'
            tab={
              <BadgedTab
                title='Client Portal'
                unreadCount={unreadCount}
              />
            }
            style={tabStyle}>
            <CustomerClientPortal {...props} unreadCount={unreadCount}/>
          </TabPane>
        }
        {isVendor && hasVendorRead && (
          <TabPane tab="Certifications" key="certifications" style={tabStyle}>
            <Certifications
              id={id}
              entityType={CERT_TYPE}
            />
          </TabPane>
        )}
      </Tabs>
    </Form>
  );
}