import React, { useState, useCallback, useEffect } from 'react';
import { Space, Radio, Row, Col } from 'antd';

import CustomModal from './CustomModal';
import OnTraccrButton from '../buttons/OnTraccrButton';

import colors from '../../constants/Colors';

export default function ({
  mode,
  onClose,
  onSave,
  type = 'Shift',
}) {
  const [loading, setLoading] = useState(false);
  const [selection, setSelection] = useState('single');

  const onRadioChanged = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSelection(value);
  });

  const onSaveClicked = useCallback(async () => {
    setLoading(true);
    await onSave(selection);
    setLoading(false);
  }, [selection, onSave]);

  useEffect(() => {
    setSelection('single');
  }, [mode]);

  return (
    <CustomModal
      title={mode === 'delete' ? `Delete recurring ${type}` : `Update recurring ${type}`}
      visible={!!mode}
      onCloseClicked={onClose}
      maskColor={colors.MODAL_MASK_DARK}
    >
      <Radio.Group onChange={onRadioChanged} value={selection}>
        <Space>
          <Radio value="single">
            {`This ${type}`}
          </Radio>
          <Radio value="all">
            {`All ${type}s in group`}
          </Radio>
        </Space>
      </Radio.Group>
      <Row style={{ width: '100%', margin: '20px 0px' }} align="middle" justify="space-around">
        <Col>
          <OnTraccrButton
            title="Cancel"
            type="cancel"
            roundStyle
            onClick={onClose}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title={mode === 'delete' ? 'Delete' : 'Update'}
            roundStyle
            onClick={onSaveClicked}
            loading={loading}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}
