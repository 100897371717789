import {
  getTypeMap,
} from '../../../boards/boardWorkflowConfig';

export default {};

export const SALES_RABBIT_WORKFLOWS = [{
  label: 'Create a card when a SalesRabbit lead changes',
  value: 'salesRabbitLeadStatusChange',
}];

export const REQUIRED_FIELDS = {
  project: new Set(['name']),
  card: new Set(['cardTitle']),
};

export const SALES_RABBIT_DIVISION_FIELD_HELP = 'This workflow will only trigger when the value of the selected field in SalesRabbit matches the division selected above.';

// Align Lat/Long after project fields and before customer fields
export const getProjectFields = (t) => {
  const TYPE_MAP = getTypeMap(t);
  return [
    ...TYPE_MAP.project.slice(0, 13),
    {
      label: 'Latitude', value: 'latitude', type: 'text',
    },
    {
      label: 'Longitude', value: 'longitude', type: 'text',
    },
    ...TYPE_MAP.project.slice(13, TYPE_MAP.project.length - 1),
  ];
};
