import React from 'react';
import { Select, Row } from 'antd';

import DivisionsKeepOldCheckbox from './DivisionsKeepOldCheckbox';


export default ({
  setDestination,
  onCheckChange,
  keepOld,
  name,
  type,
  divisions = {},
}) => (
  <Row style={{ margin: 20 }}>
    {type === 'Users' &&
      <DivisionsKeepOldCheckbox
        name={name}
        onCheckChange={onCheckChange}
        keepOld={keepOld}
      />
    }
    <Select
      placeholder='Select destination division'
      style={{ width: 400 }}
      onSelect={setDestination}
    >
      {
        Object.values(divisions).map(({ name, id }) => (
          <Select.Option
            value={id}
            label={name}
          >
            {name}
          </Select.Option>
        ))
      }
    </Select>
  </Row>
)