import React from 'react';

import PDFDesignerPositionPicker from './PDFDesignerPositionPicker';
import PDFDesignerPagePicker from './PDFDesignerPagePicker';
import PDFDesignerColorPicker from './PDFDesignerColorPicker';
import PDFDesignerBorderPicker from './PDFDesignerBorderPicker';

import { defaultDrawOptions } from './PDFDesigner.constants';

const formStyle = { marginBottom: 5 };

export default ({
  onDrawOptionChanged,
  page,
  numberOfPages,
  onPageChanged,
  field:{
    drawOptions = defaultDrawOptions,
    isHeader = false,
  } = {},
  useStandardTemplate,
}) => {
const {
  backgroundColor,
} = drawOptions;

return (
  <>
    <PDFDesignerPositionPicker
      drawOptions={drawOptions}
      onDrawOptionChanged={onDrawOptionChanged}
      style={formStyle}
    />
    <PDFDesignerPagePicker
      page={page}
      numberOfPages={numberOfPages}
      onPageChanged={onPageChanged}
      style={formStyle}
      disabled={isHeader && useStandardTemplate}
    />
    <PDFDesignerColorPicker
      style={formStyle}
      title='Background Color'
      value={backgroundColor}
      onColorChanged={(newColor) => onDrawOptionChanged({ backgroundColor: newColor })}
    />
    <PDFDesignerBorderPicker
      drawOptions={drawOptions}
      onDrawOptionChanged={onDrawOptionChanged}
      style={formStyle}
    />
  </>
  );
}