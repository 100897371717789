import React from 'react';

import { DateTime } from 'luxon';
import { Tag } from 'antd';

export default {};

const timeRender = (ts) => (
  ts
    ? DateTime.fromMillis(ts).toLocaleString(DateTime.DATETIME_MED_WITH_SECONDS)
    : null
);

const ACTIONS = [
  'Create PO',
  'Create Payment',
  'Create Bill',
  'Update Bill',
  'Create Expense',
  'Update Expense',
  'Create Invoice',
  'Create Time Entry',
];

const STATUSES = [
  'Failed',
  'Partial Success',
  'Succeeded',
];

const colorMap = {
  Failed: 'red',
  'Partial Success': 'orange',
  Succeeded: 'green',
};

export const logColumns = [{
  title: 'Action',
  dataIndex: 'action',
  width: 100,
  filters: ACTIONS.map((name) => ({
    text: name,
    value: name,
  })),
  onFilter: (action, record) => record.action === action,
}, {
  title: 'User',
  dataIndex: 'user',
  width: 100,
}, {
  title: 'Description',
  dataIndex: 'description',
  width: 300,
}, {
  title: 'Integration',
  dataIndex: 'integration',
  width: 100,
}, {
  title: 'Sent At',
  dataIndex: 'startTime',
  width: 100,
  sorter: (a, b) => a.startTime - b.startTime,
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: timeRender,
}, {
  title: 'Response At',
  dataIndex: 'endTime',
  width: 100,
  sorter: (a, b) => a.startTime - b.startTime,
  showSorterTooltip: false,
  sortDirections: ['descend', 'ascend'],
  render: timeRender,
}, {
  title: 'Status',
  dataIndex: 'status',
  width: 100,
  textAlign: 'center',
  filters: STATUSES.map((name) => ({
    text: name,
    value: name,
  })),
  onFilter: (status, record) => record.status === status,
  render: (status) => (
    <Tag color={colorMap[status]}>
      {status ?? 'Failed'}
    </Tag>
  ),
}];
