import React from 'react';
import { Row, Col } from 'antd';

import OnTraccrButton from '../buttons/OnTraccrButton';

export default ({
  onArchive,
  onDelete,
  onEdit,
  active,
}) => (
  <div className='drawer-footer'>
    <Row justify='space-between' gutter={10} align='middle'>
      <Col>
        <Row justify='start' align='middle' gutter={20}>
          <OnTraccrButton
            title={active ? 'Archive' : 'Activate'}
            type='back'
            onClick={onArchive}
            style={{ marginLeft: 10 }}
          />
          {!active && <OnTraccrButton
            title='Delete'
            type='back'
            onClick={onDelete}
            style={{ marginLeft: 10 }}
          />}
        </Row>
      </Col>
      <Col>
        <OnTraccrButton
          title='Edit'
          onClick={onEdit}
        />
      </Col>
    </Row>
  </div>
);