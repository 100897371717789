import React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import { Typography } from 'antd';

import colors from '../../constants/Colors';

const { Text } = Typography;

const DEFAULT_FORMAT = 'hh:mm:ss';

export default class Timer extends React.Component {
  constructor(props) {
    super(props);
    const {
      clockTime = '00:00:00',
      startTime,
    } = props;
    this.state = {
      clockedIn:false,
      clockTime,
      clockColor:colors.ONTRACCR_OPACITY_GRAY,
      textAlign:'center'
    };
    this.timer = null;
    this.pairedTick = null;
    this.startTime = startTime;
    this.running = false;
  }

  componentWillUpdate(prevProps) {
    const {
      endTime: prevEnd,
      id: prevId,
    } = prevProps;

    const {
      startTime,
      endTime,
      id,
    } = this.props;

    if(id !== prevId || (startTime && !this.running && !endTime)) {
      this.start(startTime);
    } else if (endTime !== prevEnd) {
      this.freeze();
    }
  }

  componentWillMount() {
    if (this.props.autoStart || (this.startTime && !this.props.endTime && !this.state.running)) {
      this.start(this.startTime);
    } else if(this.props.onRef) {
      this.props.onRef(this);
    } 
  }

  componentWillUnmount() {
    if (this.props.autoStart) {
      clearInterval(this.timer);
      this.timer = null;
      this.startTime = null;
      this.breakTime = undefined;
    } else {
      //this.props.onRef(null);
    }
  }
  
  start(start = moment(),isPaired = false) {
    const {
      alwaysFrozen,
    } = this.props;
    this.running = true;
    this.startTime = start;
    this.tick();
    if(alwaysFrozen) return;
    this.endBreak(isPaired);
  }

  freeze() {
    clearInterval(this.timer);
    this.timer = null;
    this.startTime = null;
    this.setState({
      clockColor:colors.ONTRACCR_OPACITY_GRAY,
    });
    this.breakTime = undefined;
    return this.state.clockTime;
  }

  stop() {
    this.freeze();
    this.setState({
      clockTime:'00:00:00',
    });
  }

  startBreak(color) {
    this.breakTime = moment();
    if(color) {
      this.setState({
        clockColor:color,
      });
    }
    clearInterval(this.timer);
    this.timer = null;
  }

  restart() {
    this.stop();
    this.start();
  }

  endBreak(isPaired) {
    this.setState({
      clockColor:'black',
    });
    if(!isPaired) {
      if (this.timer) clearInterval(this.timer);
      this.timer = setInterval(() => {
        this.tick();
        if (this.pairedTick) this.pairedTick();
      },1000);
    }
  }

  tick() {
    const {
      format = DEFAULT_FORMAT,
      startTime,
    } = this.props;

    const now = DateTime.local();

    const diff = now.diff(DateTime.fromMillis(startTime));

    this.setState({
      clockTime: diff.toFormat(format),
    });
  }

  render() {
    return (
      <Text 
        style={{
          alignSelf:'center',
          color:this.state.clockColor,
          width:this.state.width,
          textAlign:'left',
          fontSize:72,
          fontFamily:'raleway-semibold',
          ...this.props.style,
        }}
      >
      {this.state.clockTime}
    </Text>
    );
  }
};
