import React from 'react';
import colors from '../../../constants/Colors';
import { ExclamationCircleOutlined, CloseOutlined } from '@ant-design/icons';
import { Button, Row, Col, Badge } from 'antd';
import ProfileCircle from '../../profile/ProfileCircle';

export default ({
  title,
  onClick,
  color = colors.ONTRACCER_BLACK,
  style = {},
  disabled = false,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  onDelete,
  type,
  read,
  initials,
  truncate = true,
}) => (
  <Row
    key={title+read}
    style={{
      boxShadow:'none',
      width:'96%',
      color,
      fontFamily:'roboto-light',
      ...style,
      paddingBottom: 5,
      marginTop: 5,
      borderBottom: '1px solid lightgray'
    }}
  >
 <Col style={{ paddingTop: 8 }}>
  {type==='submission' ? <ProfileCircle initials={initials} radius={17}/> 
  : <ExclamationCircleOutlined style={{fontSize: '32px',display:'block', textAlign:'center'}}/>}
 </Col>
 
 <Col span={18}
      onMouseEnter={onMouseEnter}
      onClick={onClick}>
  <Button 
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
    className='borderlessbtn'
    disabled={disabled}
    style={{
      border: 'none',
      boxShadow: 'none',
      wordWrap: 'break-word',
      whiteSpace: 'break-spaces',
      textAlign: 'left',
      height:'fit-content'
    }}
  >
    {title.length > 60 && truncate ? `${title.substr(0,58)}...` : title}
  </Button>
  </Col>
  <Col span={1}>
        {!read ?
         <Badge
              offset={[0,15]}
              dot={!read}
              style={{ width: 10, height: 10, backgroundColor: colors.ONTRACCR_RED}}
            />:<></>
        } 
  </Col>
  <Col span={2}>
   {onDelete && <Button style={{
     border: 'none', boxShadow: 'none', display:'block', margin:'auto'
    }} 
    onClick={onDelete}><CloseOutlined /></Button>}
  </Col>
  </Row>
);