import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IntegrationProjectSync from '../IntegrationProjectSync';
import {
  importProjectHelpText,
  autoImportHeader,
  autoImportUncheckText,
} from '../integrationHelpers';

const autoImportContent = (
  <div style={{ width: 500 }}>
    {autoImportHeader('Sage', 'Jobs')}
    <br/><br/>1. Make sure the Jobs in Sage have their address and code set
    <br/><br/>{autoImportUncheckText('Sage')}
  </div>
);


const getImportHelpText = (t) => importProjectHelpText(t, 'Sage', 'Jobs');

export default function SageProjectSync({
  onProjectsChanged,
  importProjects,
  divisionFilter,
}) {
  const { t } = useTranslation();
  const {
    sageData: {
      projects: sageProjects,
    } = {},
  } = useSelector((state) => state.settings);
  const importHelpText = getImportHelpText(t);

  return (
    <IntegrationProjectSync
      integrationProjects={sageProjects}
      title="Sage Jobs"
      integrationKey="sageId"
      onProjectsChanged={onProjectsChanged}
      showCustomer={false}
      showNumber
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      importProjects={importProjects}
      divisionFilter={divisionFilter}
    />
  );
}

SageProjectSync.propTypes = {
  onProjectsChanged: PropTypes.func,
  importProjects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  divisionFilter: PropTypes.instanceOf(Set)
};

SageProjectSync.defaultProps = {
  onProjectsChanged: () => {},
  importProjects: [],
  divisionFilter: null,
};
