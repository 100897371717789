import React from "react";
import PropTypes from 'prop-types';

import FormSelectorInput from "../../common/inputs/FormSelectorInput";

const vendorTypeOptions = [
  {
    value: 'Subcontractor',
    label: 'Subcontractor',
  },
  {
    value: 'Supplier',
    label: 'Supplier',
  },
];

const VendorType = ({
  isNotDisplay,
}) => (
  <FormSelectorInput
    isNotDisplay={isNotDisplay}
    label='Type'
    formStyle={{ marginTop: 10, marginBottom: -2 }}
    name='type'
    selectProps={{ options: vendorTypeOptions }}
  />
);

VendorType.propTypes = {
  isNotDisplay: PropTypes.bool.isRequired,
};

export default VendorType;
