import React from 'react';
import { Row, Select, Spin, Result, Form } from 'antd';

export default ({
  companies,
  onCompanySelect,
  company,
  globalCodeLists,
  onGlobalCodesSelect,
  procoreCompanyId,
}) => {
  const ccListIsArr = Array.isArray(globalCodeLists);
  
  //globalCodeLists is a string in the event of an error, such as user uninstalling Ontraccr on Procore.
  const ccIsString = typeof globalCodeLists === 'string';

  const getMainView = () => {
    if(!companies) return (
      <Row style={{ width:'100%', height:'50vh'}} justify='center' align='middle'>
        <Spin/>
      </Row>

    )
    if(companies.length === 0) return (
      <Row justify='center' align='middle' style={{ width:'100%'}}>
          <Result
            status='warning'
            title={
              <div>
              We couldn't find any Procore companies attached to your account
              <br/> <br/>
              At least one company must exist in Procore for us to connect with your Ontraccr company.
              </div>
            }
          />
      </Row>
    );
    return(
      <Row style={{ width: '100%' }}>
        <Form layout='vertical'>
          <Row style={{ width: '100%' }}>
            <Form.Item label='Select a Procore Company'>
            <Select
              style={{ width: 250 }}
              placeholder='Select a Procore Company'
              onSelect={(idx) => onCompanySelect(companies[idx])}
              defaultValue={companies.find((company) => company.id === procoreCompanyId)}
            >
              {
                companies.map((company,index) => 
                  <Select.Option key={company.id} value={index}>
                    {company.name}
                  </Select.Option>
                )
              }
            </Select>
            </Form.Item>
          </Row>
          {ccListIsArr && <Form.Item label='Select a Global Cost Code List'
            style={{
              opacity: company && globalCodeLists && globalCodeLists.length ? 1 : 0,
              height: company && globalCodeLists && globalCodeLists.length ? 'auto' : 0,
              transition:'opacity 0.5s, height 0.5s'
            }}
          >
            <Row style={{
              width: '100%',
            }}>
              <Select
                style={{
                  width: 250,
      
                }}
                placeholder='Select a Global Cost Code List'
                onSelect={(idx) => onGlobalCodesSelect(globalCodeLists[idx])}
              >
                {
                  globalCodeLists.map((codeList,index) => 
                    <Select.Option key={codeList.id} value={index}>
                      {codeList.name}
                    </Select.Option>
                  )
                } 
              </Select>
            </Row>
          </Form.Item>}
          {
            ccIsString && 
            <div style={{ color:'red', }}>
              {globalCodeLists} <br/> <br/>
              {globalCodeLists.includes('uninstalled') &&
                <div>
                Please open the App Management section in your Procore account and verify that<br/>
                Ontraccr is found under the <b>"Installed Apps"</b> section and not in the <b>"Uninstalled Apps"</b> section 
                <br/> <br/>
                </div>
              }
              If these issues continues, support information can be found under the <b>"Support"</b> section in the left menu bar.
            </div>
          }
          
        </Form>
      </Row>
    )
  };
  return (
    <Row style={{
      padding:20,
      width:'100%',
    }} >
      {getMainView()}
    </Row>
  );
}
