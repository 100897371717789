import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { isMobile, isAndroid } from 'react-device-detect';
import { Row, Spin, message } from 'antd';
import axios from 'axios';

import LoginFormContainer from '../auth/login/LoginFormContainer';
import logo from '../assets/images/Ontraccr-Logo-B-1400x400.png';

import {
  getClientPortalSettingsFromURL,
  loginClient,
} from './state/clientPortal.actions';

import colors from '../constants/Colors';
import { getExternalFormTemplates } from '../forms/state/forms.actions';
import ExternalForm from '../forms/ExternalForm/ExternalForm';

export default function ClientPortalLogin() {
  const history = useHistory();
  const {
    pathname,
  } = useLocation();
  const dispatch = useDispatch();

  const portalSettings = useSelector((state) => state.clientPortal.settings);
  const externalFormTemplates = useSelector((state) => state.forms.externalFormTemplates);

  // Remove leading /
  const url = useMemo(() => (
    pathname
      ? pathname.substring(1, pathname.length)
      : null
  ), [pathname]);

  const {
    primaryColor,
    accentColor,
    image,
  } = portalSettings;

  const safePrimaryColor = primaryColor ?? colors.MAIN_BACKGROUND;
  const safeAccentColor = accentColor ?? colors.ONTRACCR_RED;
  const safeImage = image ?? logo;

  const [loading, setLoading] = useState(true);
  const [isReset, setIsReset] = useState(false);

  const onResetButtonClicked = useCallback(() => {
    setIsReset(!isReset);
  }, [isReset]);

  const isUUID = (uuid) => {
    if (uuid.length !== 36) return false;
    const uuidRegex = /^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    return uuidRegex.test(uuid);
  };

  const onSubmit = useCallback(async ({ username: email, password }) => {
    if (isReset) {
      try {
        const { data: passed } = await axios.post('/clientPortal/reset/email', {
          email,
          url,
        });
        if (passed) {
          setIsReset(false);
        }
      } catch (err) {
        //
      }
      message.success('Password reset email sent');
    } else {
      dispatch(loginClient({
        email,
        password,
        url,
      }));
    }
  }, [dispatch, url, isReset]);

  useEffect(() => {
    const checkPath = async () => {
      setLoading(true);
      if (await dispatch(getClientPortalSettingsFromURL(url))) {
        setLoading(false);
      } else if (isUUID(url)) {
        await dispatch(getExternalFormTemplates());
        setLoading(false);
      } else if (isMobile) {
        const link = isAndroid ? 'https://play.google.com/store/apps/details?id=com.ontraccr.prod' : 'itms-apps://itunes.apple.com/app/ontraccr/id1544685886';
        window.location = link;
      } else {
        history.replace('/login');
      }
    };
    if (url && url !== 'reset') checkPath();
  }, [history, url]);

  if (loading) {
    return (
      <Row justify="center" align="middle" style={{ height: '100vh', width: '100vw' }}>
        <Spin />
      </Row>
    );
  }

  if (externalFormTemplates[url]) {
    return <ExternalForm externalFormTemplate={externalFormTemplates[url]} />;
  }

  return (
    <>
      <LoginFormContainer
        style={{ top: 0 }}
        onSubmit={onSubmit}
        primaryColor={safePrimaryColor}
        accentColor={safeAccentColor}
        onResetButtonClicked={onResetButtonClicked}
        isReset={isReset}
        image={(
          <img
            alt="Logo"
            src={safeImage}
            style={{
              maxWidth: 300,
              maxHeight: 200,
              width: 'auto',
              height: 'auto',
              borderRadius: 5,
              padding: 6,
              marginBottom: 30,
            }}
          />
        )}
      />
      <a
        className="client-portal-powered-by-ontraccr"
        href="https://www.ontraccr.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: safeAccentColor }}
      >
        Powered by Ontraccr
      </a>
    </>
  );
}
