import React, { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  Form,
  Select,
  Switch,
  message,
  Row,
  Col,
  Checkbox,
} from 'antd';
import axios from 'axios';
import FormColorPicker from '../common/inputs/FormColorPicker';
import HoverHelp from '../common/HoverHelp';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

export default function BoardMoveWorkflowConfigure({
  cardTypeId,
  targetBoardId,
  currentStatuses = [],
  keepColor = false,
}) {
  const boards = useSelector((state) => state.boards.boards);

  const [targetStatuses, setTargetStatuses] = useState([]);

  const statusOptions = useMemo(() => (
    currentStatuses.map((status) => ({ label: status.title, value: status.id }))
  ), [currentStatuses]);

  const boardNames = useMemo(() => (
    Object.values(boards)
      .filter((board) => board.cardTypeId === cardTypeId)
      .map((board) => ({ value: board.id, label: board.title }))
  ), [boards]);

  useEffect(() => {
    const getTargetStatuses = async () => {
      try {
        const {
          data: rawTargetStatuses = [],
        } = await axios.get(`/boards/${targetBoardId}/statuses`);
        setTargetStatuses(
          rawTargetStatuses.map((status) => ({ value: status.id, label: status.title }))
        );
      } catch (err) {
        message.error('Could not get target board statuses');
      }
    };
    if (targetBoardId) getTargetStatuses();
  }, [targetBoardId]);

  return (
    <>
      <Form.Item
        name="initialStatusId"
        label="Trigger Status"
        rules={[{ required: true, message: 'Trigger Status is required'}]}
        labelCol={formLabelStyle}
        style={{ marginBottom: 0 }}
      >
        <Select options={statusOptions} />
      </Form.Item>
      <Form.Item
        name="createShadow"
        label={(
          <Row gutter={10}>
            <Col>
              Create Shadow?
            </Col>
            <Col>
              <HoverHelp
                content={(
                  <div style={{ maxWidth: 400 }}>
                    Check this box if you want a display
                    only card shadow left in this board when
                    this workflow is triggered.
                  </div>
                )}
              />
            </Col>
          </Row>
        )}
        labelCol={formLabelStyle}
        style={{ marginBottom: 0 }}
        valuePropName="checked"
      >
        <Checkbox />
      </Form.Item>
      <Form.Item
        name="targetBoardId"
        label="Target Board"
        rules={[{ required: true, message: 'Target Board is required' }]}
        labelCol={formLabelStyle}
        style={{ marginBottom: 0 }}
      >
        <Select options={boardNames} />
      </Form.Item>
      {targetBoardId && (
        <Form.Item
          name="targetStatusId"
          label="Target Status"
          rules={[{ required: true, message: 'Target Status is required'}]}
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
        >
          <Select options={targetStatuses} />
        </Form.Item>
      )}
      {targetBoardId && !keepColor && (
        <Form.Item
          name="color"
          label="Target Color"
          labelCol={formLabelStyle}
          style={{ marginBottom: 0, maxWidth: 150 }}
        >
          <FormColorPicker isNotDisplay />
        </Form.Item>
      )}
      {targetBoardId && (
        <Form.Item
          name="keepColor"
          label="Keep Color"
          valuePropName="checked"
          labelCol={formLabelStyle}
          style={{ marginBottom: 0 }}
        >
          <Switch />
        </Form.Item>
      )}
    </>
  );
}