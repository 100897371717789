import {
  CREATE_NEW_DASHBOARD,
  CREATE_DASHBOARD,
  UPDATE_DASHBOARD,
  SELECT_DASHBOARD,
  SET_DASHBOARD_EDIT,
  GET_SAVED_DASHBOARDS,
  DELETE_SAVED_DASHBOARD,
  GET_DASHBOARD_ANALYTICS_DATA,
  FAVORITE_DASHBOARD,
} from '../../state/actionTypes';

import {
  deleteSavedDashboardActionMap,
  favoriteDashboardActionMap,
  getDashboardAnalyticsActionMap,
  getSavedDashboardsActionMap,
  saveDashboardActionMap,
  selectDashboardActionMap,
  setDashboardEditActionMap,
  setInitialDashboardActionMap,
  updateDashboardActionMap,
} from '../dashboard.constants';

import Analytics from '../../helpers/Analytics';
import DashboardService from './dashboard.service';
import AnalyticsService from '../../analytics/state/analytics.service';

export default {};

export const createNewDashboard = () => ({
  type: CREATE_NEW_DASHBOARD,
});

export const setDashboardEdit = ({ type: analyticsType, isEdit }) => ({
  type: analyticsType ? setDashboardEditActionMap[analyticsType] : SET_DASHBOARD_EDIT,
  payload: { isEdit },
});

export const selectDashboard = ({ type: analyticsType, id }) => (dispatch) => {
  dispatch({
    type: analyticsType ? selectDashboardActionMap[analyticsType] : SELECT_DASHBOARD,
    payload: {
      id,
    },
  });
};

export const saveDashboard = ({ type: analyticsType, payload }) => async (dispatch) => {
  Analytics.track(`Dashboard/${analyticsType ?? 'Main'}/Create`);
  const { data } = await DashboardService.create(payload);
  if (!data) return false;
  dispatch({
    type: analyticsType ? saveDashboardActionMap[analyticsType] : CREATE_DASHBOARD,
    payload: data,
  });
  return true;
};

export const updateDashboard = ({ type: analyticsType, payload, id }) => async (dispatch) => {
  Analytics.track(`Dashboard/${analyticsType ?? 'Main'}/Edit`);
  const { data } = await DashboardService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: analyticsType ? updateDashboardActionMap[analyticsType] : UPDATE_DASHBOARD,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const getSavedDashboards = (analyticsType = 'main') => async (dispatch) => {
  const { data } = await DashboardService.get(analyticsType);
  if (!data) return false;
  dispatch({
    type: analyticsType ? getSavedDashboardsActionMap[analyticsType] : GET_SAVED_DASHBOARDS,
    payload: data,
  });
  return true;
};

export const deleteSavedDashboard = ({ type: analyticsType, id }) => async (dispatch) => {
  Analytics.track(`Dashboard/${analyticsType ?? 'Main'}/Delete`);
  const { data } = await DashboardService.delete(id);
  if (!data) return false;
  dispatch({
    type: analyticsType ? deleteSavedDashboardActionMap[analyticsType] : DELETE_SAVED_DASHBOARD,
    payload: {
      id,
    },
  });
  return true;
};

export const getDashboardAnalytics = (id, fullConfig) => ({
  type: analyticsType,
  payload,
}) => async (dispatch) => {
  const { data } = await AnalyticsService.get(payload);
  if (!data) return false;
  dispatch({
    type: analyticsType
      ? getDashboardAnalyticsActionMap[analyticsType]
      : GET_DASHBOARD_ANALYTICS_DATA,
    payload: {
      id,
      data,
      config: fullConfig,
    },
  });
  return true;
};

export const favoriteDashboard = ({
  type: analyticsType,
  isFavorite: unfavorite,
  id,
  projectTypeId,
  cardTypeId,
}) => async (dispatch) => {
  Analytics.track(`Dashboard/${analyticsType ?? 'Main'}/Favorite`);
  const payload = {
    unfavorite: !!unfavorite,
    projectTypeId,
    dashboardType: analyticsType,
    cardTypeId,
  };
  const { data } = await DashboardService.favorite(id, payload);
  if (!data) return false;
  dispatch({
    type: analyticsType ? favoriteDashboardActionMap[analyticsType] : FAVORITE_DASHBOARD,
    payload: {
      id,
      unfavorite,
      projectTypeId,
      cardTypeId,
      analyticsType,
    },
  });
  return true;
};

export const setInitialDashboard = ({
  type: analyticsType,
  projectTypeId,
  cardTypeId,
}) => (dispatch) => {
  dispatch({
    type: setInitialDashboardActionMap[analyticsType],
    payload: {
      projectTypeId,
      cardTypeId,
      analyticsType,
    },
  });
};
