import React from 'react';

import FormNumberInput from './FormNumberInput';

import {
  currencyFormatter as formatter,
  currencyParser as parser
} from '../../helpers/inputParsers';

export default ({
  isNotDisplay,
  label,
  name,
  value,
  rules = [],
  min,
  max,
  step = 0.01,
  ...restProps
}) => (
  <FormNumberInput
    isNotDisplay={isNotDisplay}
    label={label}
    name={name}
    value={value}
    rules={rules}
    numberInputProps={{
      formatter,
      parser,
      precision: 2,
      min,
      max,
      step,
    }}
    {...restProps}
  />
);