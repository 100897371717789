import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import ProcoreCompanySelect from './ProcoreCompanySelect';
import ProcoreUserSync from './ProcoreUserSync';
import ProcoreProjectSync from './ProcoreProjectSync';
import ProcoreCostcodeSync from './ProcoreCostcodeSync';
import ProcoreAutoSync from './ProcoreAutoSync';

import IntegrationSyncDrawer from '../IntegrationSyncDrawer';

import {
  updateProcoreCompany
} from '../../state/settings.actions';

export default connect(
  (state,ownProps) => ({
    ...ownProps,
    procoreData:state.settings.procoreData,
    projects:state.projects.projects,
  }),{
    updateProcoreCompany,
  }
)(({
  companies,
  visible,
  onClose,
  onSave,
  importFromProcore,
  getProcoreGlobalCodesList,
  globalCodeLists,
  procoreData = {},
  projects = [],
  updateProcoreCompany,
  procoreIntegrationPush = false,
  procoreIntegrationPull = true,
}) => {
  const {
    users:procoreUsers = [],
    projects:procoreProjects = [],
    costcodes: procoreCodes = [],
  } = procoreData;

  const [company,setCompany] = useState();
  const [globalCodeList,setGlobalCodeList] = useState();
  const [step,setStep] = useState();
  const [importUsers,setImportUsers] = useState([]);
  const [importProjects,setImportProjects] = useState([]);
  const [importCostcodes,setImportCostcodes] = useState([]);
  const [stateCodes,setStateCodes] = useState([]);
  const [syncSettings,setSyncSettings] = useState({
    procoreIntegrationPush,
    procoreIntegrationPull,
  });

  useEffect(() => {
    if(company) getProcoreGlobalCodesList(company.id);
  },[company,getProcoreGlobalCodesList]);

  useEffect(() => {
    setCompany();
    setGlobalCodeList();
  },[visible]);

  useEffect(() => {
    setSyncSettings({
      procoreIntegrationPush,
      procoreIntegrationPull,
    });
  }, [procoreIntegrationPush,procoreIntegrationPull])


  useEffect(() => {
    if (company && globalCodeList && step === 1) {
      importFromProcore({
        company: company.id,
        globalCodeList: globalCodeList.id,
      });
    }
  }, [globalCodeList,company,step,importFromProcore]);
  
  useEffect(() => {
    const newImportProjectSet = new Set();
    const procoreIdMap = {};
    projects.forEach((project) => {
      if(project.procoreId) {
        newImportProjectSet.add(project.procoreId);
        procoreIdMap[project.id] = project.procoreId
      }
    });
    const {
      importList = [],
      linkDeleteList = [],
    } = importProjects;
    importList.forEach((project) => {
      newImportProjectSet.add(project.procoreId);
    });
    linkDeleteList.forEach((projectId) => {
      if(projectId in procoreIdMap) newImportProjectSet.delete(procoreIdMap[projectId]);
    });

    // Only show costcodes for projects that the user wants to import/links
    const newStateCodes = procoreCodes.filter(
      ({ projectId }) => 
        !projectId || newImportProjectSet.has(projectId)
    );
    setStateCodes(newStateCodes);
    
  },[importProjects,projects,procoreCodes]);

  const steps = [
    {
      title: 'Configure',
      key:0,
      content:(
        <ProcoreCompanySelect
          globalCodeLists={globalCodeLists}
          company={company}
          companies={companies}
          onCompanySelect={setCompany}
          onGlobalCodesSelect={setGlobalCodeList}
          />
      ),
    },
    {
      title: 'Users',
      key:1,
      content:(
        <ProcoreUserSync
          procoreUsers={procoreUsers}
          onUsersChanged={setImportUsers}
          importUsers={importUsers}
        />
      ),
    },
    {
      title: 'Projects',
      key:2,
      content:(
        <ProcoreProjectSync
          procoreProjects={procoreProjects}
          onProjectsChanged={setImportProjects}
          importProjects={importProjects}
        />
      ),
    },
    {
      title: 'Cost Codes',
      key:3,
      content:(
        <ProcoreCostcodeSync
          procoreCodes={stateCodes}
          onCostcodesChanged={setImportCostcodes}
          importCostcodes={importCostcodes}
        />
      ),
    },
    {
      title: 'Auto Sync',
      key:4,
      content:(
        <ProcoreAutoSync
          onChanged={setSyncSettings}
          syncSettings={syncSettings}
        />
      ),
    },
  ];

  return (
    <IntegrationSyncDrawer
      width='auto'
      bodyStyle={{
        transition: 'width 0.5s',
        width:step === 0 ? 800 : 1200
      }}
      nextDisabled={!company || !globalCodeList}
      visible={visible}
      title='Import from Procore'
      onClose={onClose}
      onStepChanged={setStep}
      onSave={async () => {
        const {
          id:procoreCompanyId,
        } = company;
        if(procoreCompanyId && await updateProcoreCompany({ procoreCompanyId,})) {
          onSave({
            importUsers,
            importProjects,
            importCostcodes,
            syncSettings:{
              ...syncSettings,
              syncKey:'procore',
            },
          });
        }
      }}
      steps={steps}
    />
  );
});
