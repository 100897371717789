import { DateTime } from 'luxon';
import {
  CREATE_CUSTOMER,
  GET_CUSTOMERS,
  ARCHIVE_CUSTOMERS,
  UPDATE_CUSTOMER,
  DELETE_CUSTOMER,
  ADD_CUSTOMER_NOTE,
  GET_CUSTOMER_NOTES,
  GET_CUSTOMER_CLIENT_COMMS,
  ADD_CUSTOMER_CLIENT_COMMS,
  GET_CUSTOMER_CLIENT_COMMS_UNREAD,
  MARK_CUSTOMER_CLIENT_COMM_AS_READ,
  GET_CLIENT_BROADCASTS,
  GET_CUSTOMER_LABELS,
} from '../../../state/actionTypes';
import Analytics from '../../../helpers/Analytics';
import CustomerService from './customers.service';
import { decoratePayloadWithLabels } from '../../../helpers/labels';
import { replaceEmptyStrings } from '../../../helpers/contacts';
import { counters, counterWasRecentlyCalled } from '../../../helpers/counters';

const {
  customers: customerCounters,
} = counters;

export default {};

const modifyPayload = (payload = {}, id = null, customerToLabel = {}) => {
  let ourPayload = decoratePayloadWithLabels(payload, id, customerToLabel);
  ourPayload = replaceEmptyStrings(ourPayload, null);
  if (ourPayload.materialDiscount) {
    ourPayload.materialDiscount /= 100;
  }
  return ourPayload;
};

export const createCustomer = (payload) => async (dispatch) => {
  Analytics.track('Customer/Create');
  const ourPayload = modifyPayload(payload);
  const { data } = await CustomerService.create(ourPayload);
  if (!data) return false;
  dispatch({
    type: CREATE_CUSTOMER,
    payload: data,
  });
  return data;
};

export const getCustomers = (shouldIgnoreCounter = false) => async (dispatch) => {
  if (!shouldIgnoreCounter && counterWasRecentlyCalled('customers.getCustomers')) return true;
  customerCounters.getCustomers = DateTime.local();
  const { data } = await CustomerService.get();
  if (!data) {
    customerCounters.getCustomers = null;
    return false;
  }
  dispatch({
    type: GET_CUSTOMERS,
    payload: {
      customers: data,
    },
  });
  return true;
};

export const archiveCustomer = (id, active) => async (dispatch) => {
  const { data } = await CustomerService.archive(id, active);
  if (!data) return false;
  dispatch({
    type: ARCHIVE_CUSTOMERS,
    payload: {
      id,
      active,
    },
  });
  return true;
};

export const updateCustomer = (id, payload, customerToLabel) => async (dispatch) => {
  const ourPayload = modifyPayload(payload, id, customerToLabel);
  const { data } = await CustomerService.update(id, ourPayload);
  if (!data) return false;
  dispatch({
    type: UPDATE_CUSTOMER,
    payload: {
      id,
      newData: ourPayload,
      removedLabels: ourPayload.removedLabels,
      addedLabels: data.labels,
    },
  });
  return true;
};

export const deleteCustomer = (id) => async (dispatch) => {
  const { data } = await CustomerService.delete(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CUSTOMER,
    payload: {
      id,
    },
  });
  return true;
};

export const getNotes = (id) => async (dispatch) => {
  const { data } = await CustomerService.getNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_CUSTOMER_NOTES,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const addNote = ({ id, text }) => async (dispatch) => {
  const { data } = await CustomerService.addNote(id, text);
  if (!data) return false;
  dispatch({
    type: ADD_CUSTOMER_NOTE,
    payload: data,
  });
  return true;
};

export const getClientBroadcasts = () => async (dispatch) => {
  const { data } = await CustomerService.getClientBroadcasts();
  if (!data) return false;
  dispatch({
    type: GET_CLIENT_BROADCASTS,
    payload: data,
  });
  return true;
};

export const getClientComms = (id) => async (dispatch) => {
  const { data } = await CustomerService.getClientComms(id);
  if (!data) return false;
  dispatch({
    type: GET_CUSTOMER_CLIENT_COMMS,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const addClientComm = ({ id, text }) => async (dispatch) => {
  const { data } = await CustomerService.addClientComm({ customerIds: [id], note: text });
  if (!data) return false;
  dispatch({
    type: ADD_CUSTOMER_CLIENT_COMMS,
    payload: data,
  });
  return true;
};

export const broadcastClientComms = (payload) => async (dispatch) => {
  const { data } = await CustomerService.addClientComm(payload);
  if (!data) return false;
  dispatch({
    type: ADD_CUSTOMER_CLIENT_COMMS,
    payload: data,
  });
  return true;
};

export const getUnreadClientComms = () => async (dispatch) => {
  const { data } = await CustomerService.getUnreadClientComms();
  if (!data) return false;
  dispatch({
    type: GET_CUSTOMER_CLIENT_COMMS_UNREAD,
    payload: {
      comms: data,
    },
  });
  return true;
};

export const markClientCommsAsRead = ({ ids, customerId }) => async (dispatch) => {
  const { data } = await CustomerService.markAsRead(ids);
  if (!data) return false;
  dispatch({
    type: MARK_CUSTOMER_CLIENT_COMM_AS_READ,
    payload: {
      ids,
      customerId,
      isExternal: false,
    },
  });
  return true;
};

export const getCustomerLabels = (shouldIgnoreCounter = false) => async (dispatch) => {
  if (!shouldIgnoreCounter && counterWasRecentlyCalled('customers.getCustomerLabels')) return true;
  customerCounters.getCustomerLabels = DateTime.local();
  const { data } = await CustomerService.getCustomerLabels();
  if (!data) {
    customerCounters.getCustomerLabels = null;
    return false;
  }
  dispatch({
    type: GET_CUSTOMER_LABELS,
    payload: {
      customerLabels: data,
    },
  });
  return true;
};
