import React from 'react';

import NuxDetailPane from '../NuxDetailPane';
import payCycle from '../../assets/images/NUX/pay_cycle.png';
import payAlignment from '../../assets/images/NUX/pay_alignment.png';

const payPeriodText = `This is where you set the recurring length of time over
which employee time is recorded and paid. There are three options:
weekly, biweekly and monthly.`;

const payAlignmentText = `In order to align Ontraccr to your
current payroll period, you'll need to select a recent closing date 
for your payroll`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    rows={[{
      number:1,
      title:'Pay Period Cycle',
      description:payPeriodText,
      image:{
        src:payCycle,
        style:{
          height:75,
          width:64
        }
      },
    },{
      number:2,
      title:'Pay Period Alignment',
      description:payAlignmentText,
      image:{
        src:payAlignment,
        style:{
          height:150,
          width:122,
        }
      },
    }]}
  />
);
