/* eslint-disable react/jsx-props-no-spreading */
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import React from 'react';
import { PropTypes } from 'prop-types';
import { Draggable } from 'react-beautiful-dnd';
import BorderlessButton from '../common/buttons/BorderlessButton';

export default function BoardStatusDisplayFieldCard({
  id,
  label,
  index,
  onDeleteClicked = null,
}) {
  return (
    <Draggable draggableId={id} index={index}>
      {({ innerRef, draggableProps, dragHandleProps }) => (
        <div
          ref={innerRef}
          {...draggableProps}
          {...dragHandleProps}
          style={{
            ...draggableProps.style,
            border: 'solid 1px',
            borderColor: '#cccccc',
            borderRadius: '5px',
            margin: '0.5em 0em',
            padding: '0em 0.5em',
          }}
        >
          <Row
            justify="space-between"
            style={{
              alignItems: 'center',
            }}
          >
            <Col flex="18px">
              <Row
                justify="center"
                style={{ height: '100%', paddingRight: 5 }}
                align="middle"
              >
                <DragOutlined />
              </Row>
            </Col>
            <Col flex="auto">
              {label}
            </Col>
            <Col flex="30px">
              <BorderlessButton
                iconNode={<DeleteOutlined style={{ margin: 0, color: 'red' }} />}
                onClick={onDeleteClicked}
              />
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
}

BoardStatusDisplayFieldCard.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDeleteClicked: PropTypes.func,
};

BoardStatusDisplayFieldCard.defaultProps = {
  onDeleteClicked: null,
};
