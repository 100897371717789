import React from 'react';
import { Form, Popover } from 'antd';

import { SketchPicker } from 'react-color';

import { rgbToHex } from './PDFDesignerHelpers';

import BorderlessButton from '../../buttons/BorderlessButton';

export default ({
  title,
  value,
  onColorChanged,
  style = {},
}) => {
  const text = value ? rgbToHex(value) : '#FFFFFFFF';
  return (
    <Form.Item
      name={title}
      key={title}
      label={title}
      style={style}
    >
      <Popover
        placement='left'
        content={
          <SketchPicker
            width={300}
            color={value}
            onChangeComplete={({ rgb:color }) => onColorChanged(color)}
          />
        }
      >
        <BorderlessButton
          title={text}
          style={{ color: 'black', textAlign: 'left', padding: 0 }}
          iconNode={
            <span style={{ backgroundColor: text }} className='pdf-designer-color-preview'/>
          }
        />
      </Popover>
    </Form.Item>
  );
}
