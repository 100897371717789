import React from 'react';
import { 
  Descriptions,
  Select,
} from 'antd';
const { Option } = Select;

const defaultHeaders = [
  'costcode',
  'name',
  'description',
];

const matchHeader = (key) => {
  let match;
  defaultHeaders.forEach((dh) => {
    const rk = key.toLowerCase().trim();
    if (rk === dh) match = dh;
  });
  return match;
};

export default class CostCodeHeaders extends React.Component {
  constructor(props) {
    super(props);
    this.keys = Object.keys(this.props.headers);
    this.values = {
      name:this.keys[0],
      code:this.keys[1],
      description:this.keys[2],
    };
    this.constructHeaderMap();
    this.props.onSelect(this.values);
  }

  onSelect(type,value) {
    this.values[type] = value;
    this.props.onSelect(this.values);
  }

  constructHeaderMap() {
    const keys = Object.keys(this.props.headers);
    const matchedHeaders = {};
    const leftOvers = [];
    keys.forEach((key) => {
      const header = matchHeader(key);
      if(header) {
        matchedHeaders[header] = key;
      } else {
        leftOvers.push(key);
      }
    });
    this.values = {
      name:matchedHeaders.name || leftOvers.pop(),
      code:matchedHeaders.costcode ||  leftOvers.pop(),
      description:matchedHeaders.description || leftOvers.pop(),
    };
  }
  
  render() {
    const keys = Object.keys(this.props.headers);
    const ourHeaders = keys.map((header,idx) => {
      return <Option value={header} key={idx}>{header}</Option>
    });

    return (
      <Descriptions title="Select Column Headers">
        <Descriptions.Item label="Name">
          <Select
            defaultValue={this.values.name}
            style={{ width: 120 }}
            onSelect={(value) => this.onSelect('name',value)}>
            {ourHeaders}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Cost Code">
          <Select 
            defaultValue={this.values.code}
            style={{ width: 120 }}
            onSelect={(value) => this.onSelect('code',value)}>
            {ourHeaders}
          </Select>
        </Descriptions.Item>
        <Descriptions.Item label="Description">
          <Select 
            defaultValue={this.values.description}
            style={{ width: 120 }}
            onSelect={(value) => this.onSelect('description',value)}>
            {ourHeaders}
          </Select>
        </Descriptions.Item>
      </Descriptions>
    );
  }
}
