/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import {
  BrowserView,
  MobileView,
  isAndroid,
} from 'react-device-detect';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { message } from 'antd';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import axios from 'axios';

import whiteIcon from './assets/images/Ontraccr-Fav-W-300x300.png';
import blackIcon from './assets/images/Ontraccr-Fav-B-300x300.png';

import store from './state/store';
import 'antd/dist/antd.css';
import './index.css';
import Login from './auth/login/login';
import Demo from './auth/demo/Demo';
import ResetPassword from './auth/ResetPassword';
import Sheets from './sheets/Sheets';
import Main from './main/main';
import Emails from './emails/Emails';
import config from './config';
import { version } from '../package.json';
import ClientPortal from './clientPortal/ClientPortal';
import FileLinks from './fileLinks/FileLinks';

import translator from './translator';

const sentryTraceOrigin = window.location.host;

Sentry.init({
  environment: config.env,
  dsn: config.sentryDNS,
  integrations: [new Integrations.BrowserTracing({
    tracingOrigins: [sentryTraceOrigin],
  })],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  release: `web-dashboard@${version}`,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
  normalizeDepth: 2,
});

translator();

axios.defaults.baseURL = config.baseURL;
message.config({
  maxCount: 2,
});

function App() {
  const [darkMode, setDarkMode] = useState(
    window?.matchMedia?.('(prefers-color-scheme: dark)')?.matches,
  );

  useEffect(() => {
    const sub = (event) => {
      setDarkMode(event.matches);
    };

    window?.matchMedia?.('(prefers-color-scheme: dark)')?.addEventListener('change', sub);
    return () => window?.matchMedia?.('(prefers-color-scheme: dark)')
      ?.removeEventListener('change', sub);
  }, []);

  const openPlayStore = useCallback(() => {
    const link = isAndroid ? 'https://play.google.com/store/apps/details?id=com.ontraccr.prod' : 'itms-apps://itunes.apple.com/app/ontraccr/id1544685886';
    window.location = link;
    return <div />;
  }, [isAndroid]);

  return (
    <Provider store={store}>
      <Helmet>
        <script async src="https://www.googletagmanager.com/gtag/js?id=AW-432395809" />
        <script>
          {'window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag(\'js\', new Date()); gtag(\'config\', \'AW-432395809\');'}
        </script>
        <title>Ontraccr</title>
        <link id="favicon" rel="icon" type="image/png" href={darkMode ? whiteIcon : blackIcon} sizes="30x30" key={darkMode ? 'white-icon' : 'black-icon'} />
      </Helmet>
      <Router>
        <BrowserView>
          <Switch>
            <Route path="/emails" render={(props) => <Emails {...props} />} />
            <Route path="/login" render={(props) => <Login {...props} />} />
            <Route path="/register" render={(props) => <Login {...props} />} />
            <Route path="/demo" render={(props) => <Demo {...props} />} />
            <Route path={['/:url/reset', '/reset']} render={(props) => <ResetPassword {...props} />} />
            <Route path="/sheets" render={(props) => <Sheets {...props} />} />
            <Route path="/download/:url" render={(props) => <FileLinks {...props} />} />
            <Route path="/" component={Main} />
          </Switch>
        </BrowserView>
        <MobileView>
          <Switch>
            <Route path="/emails" render={(props) => <Emails {...props} />} />
            <Route path={['/:url/reset', '/reset']} render={(props) => <ResetPassword {...props} hideBack />} />
            <Route path="/sheets" render={(props) => <Sheets {...props} />} />
            <Route path="/download/:url" render={(props) => <FileLinks {...props} />} />
            <Route
              exact
              path="/"
              component={openPlayStore}
            />
            <Route path="/*" component={ClientPortal} />
          </Switch>
        </MobileView>
      </Router>
    </Provider>
  );
}

ReactDOM.render(
  <App />,
  document.getElementById('root'),
);
