import React from 'react';
import { Tooltip } from 'antd';

import BorderlessButton from '../../buttons/BorderlessButton';

export default ({
  message,
  children,
  onClick,
  placement = 'bottom',
  style = {},
}) => (
  <Tooltip placement={placement} title={message}>
    <BorderlessButton
      iconNode={children}
      onClick={onClick}
      style={{ width: 40, backgroundColor: 'transparent', padding: 0, ...style }}
    />
  </Tooltip>
)