import React from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';

export default ({ content, placement = 'right', popoverProps = {}, iconProps = {}, Icon = QuestionCircleOutlined }) => (
  <Popover 
    placement={placement}
    content={content}
    zIndex={1100}
    {...popoverProps}
  >
    <Icon style={{ cursor:'help'}} {...iconProps}/>
  </Popover>
)