import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Divider, Descriptions } from 'antd';

import ClientPortalSection from './ClientPortalSection';
import ClientPortalProgressBar from './ClientPortalProgressBar';

import { parseCompletedForm } from '../forms/formHelpers';
import { downloadFile } from '../files/fileHelpers';

import { demoCards } from './clientPortal.constants';
import FullPhoto from '../common/photos/FullPhoto';
import { onNextFileClick, showLeftFileCyleButton, showRightFileCycleButton } from '../helpers/fileHelpers';

export default ({
  previewMode,
}) => {
  const clientCards = useSelector(state => state.clientPortal.cards);
  const fileMap = useSelector(state => state.clientPortal.fileMap);
  const selectedCardId = useSelector(state => state.clientPortal.selectedCardId);

  const [selectedFile, setSelectedFile] = useState();
  const [selectedFileDetails, setSelectedFileDetails] = useState();

  const clearSelectedFile = useCallback(() => {
    setSelectedFile();
    setSelectedFileDetails();
  },[]);

  const onDownloadFile = useCallback(() => {
    if (!selectedFile) return;
    downloadFile({ fileObject: selectedFile });
  },[selectedFile]);

  const onNext = useCallback((isRight) => onNextFileClick({
    isRight,
    selectedFileDetails,
    fileMap,
    setSelectedFile,
    setSelectedFileDetails,
  }), [selectedFileDetails, fileMap, setSelectedFile, setSelectedFileDetails]);

  const showLeft = useMemo(() => showLeftFileCyleButton({ selectedFile, selectedFileDetails }), [selectedFile, selectedFileDetails]);
  const showRight = useMemo(() => showRightFileCycleButton({ selectedFile, selectedFileDetails, fileMap }), [selectedFile, selectedFileDetails, fileMap]);

  const {
    title,
    assignCount,
    fields: sections = [],
    data = [],
    statuses = [],
    showProgress,
    statusId,
    boardId,
  } = useMemo(() => {
    const cardMap = previewMode ? demoCards : clientCards;
    const { [selectedCardId]: ourCard = {} } = cardMap;
    return ourCard;
  },[previewMode, demoCards, selectedCardId, clientCards]);

  const assignText = useMemo(() => (
    assignCount > 0 ? 'Assigned' : 'Not Assigned'
  ),[assignCount]);

  const displaySections = useMemo(() => {
    if (!data || !Array.isArray(data)) return sections;
    const templateMap = {};
    sections.forEach(({ id: sectionId, fields = [] }) => {
      if(!(sectionId in templateMap)) {
        templateMap[sectionId] = {};
      }
      const subMap = templateMap[sectionId];
      fields.forEach((field) => {
        const { id: fieldId } = field;
        subMap[fieldId] = field;
      })
    })
    return parseCompletedForm({ sections: data, fileMap, setSelectedFile, setSelectedFileDetails, templateMap, hideFieldsWithNoTemplate: true });
    
  },[data, sections, fileMap]);

  return (
    <>
      <div>
        <div className='client-portal-description-header' style={{ marginBottom: 10 }}>{title}</div>
        {
          showProgress &&
          <ClientPortalProgressBar
            statuses={statuses}
            activeStatusId={statusId}
            selectedBoardId={boardId}
          />
        }
        <Descriptions>
          <Descriptions.Item label={<span className='client-portal-profile-label'>Assignment</span>}>
            {assignText}
          </Descriptions.Item>
        </Descriptions>
        <Divider style={{ margin: 0, marginBottom: 10 }}/>
        {
          displaySections.map((section) => <ClientPortalSection {...section} key={section.id} onFileClick={setSelectedFile}/>)
        }
      </div>
      <FullPhoto
        file={selectedFile}
        type={selectedFile ? selectedFile.type : null}
        onClose={clearSelectedFile}
        onDownload={onDownloadFile}
        onLeft={onNext(false)}
        onRight={onNext(true)}
        showLeft={showLeft}
        showRight={showRight}
      />
    </>
  );
}