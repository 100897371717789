import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import {
  Drawer,
  Tabs,
} from 'antd';
import PropTypes from 'prop-types';

import { ControlOutlined, DesktopOutlined } from '@ant-design/icons';
import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import Permissions from '../../../auth/Permissions';
import GanttScheduleGeneralSettingsTab from './GanttScheduleGeneralSettingsTab';
import GanttScheduleAppearanceSettingsTab from './GanttScheduleAppearanceSettingsTab';
import { defaultWorkingDays } from '../ganttScheduleHelpers';
import GanttScheduleEditScheduleTab from './GanttScheduleEditScheduleTab';
import { deleteGanttSchedule, updateGanttSchedule } from '../../state/schedule.actions';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import { useDispatch } from 'react-redux';

const { TabPane } = Tabs;

export default function GanttScheduleSettingsDrawer({
  onClose,
  visible,
  handleSubmit,
  handleUserSettingsSubmit,
  settings = {
    workingDays: defaultWorkingDays,
  },
  userSettings = {
    ganttScheduleSettings: '',
  },
  holidays = [],
  scheduleId,
  setScheduleId,
  history,
  schedules = [],
  selectedSchedule = {},
  onExport,
}) {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);
  const [workingDaysList, setWorkingDaysList] = useState([]);
  const [visibleColumns, setVisibleColumns] = useState(['Name']);
  const [scheduleFormData, setScheduleFormData] = useState({});
  const [activeTab, setActiveTab] = useState('general');

  const isReadOnly = !Permissions.has('PROJECT_GANTT_SCHEDULE_WRITE');

  const onSubmit = useCallback(async () => {
    setLoading(true);

    switch (activeTab) {
      case 'general':
        await handleSubmit({
          workingDays: workingDaysList,
        });
        break;
      case 'appearance':
        await handleUserSettingsSubmit({
          ganttScheduleSettings: {
            visibleColumns,
          },
        });
        break;
      case 'schedule':
        await dispatch(updateGanttSchedule(scheduleId, scheduleFormData));
        onClose();
        break;
      default:
        break;
    }
    setLoading(false);
    setActiveTab('general');
  }, [
    visibleColumns,
    workingDaysList,
    scheduleFormData,
    activeTab,
    setActiveTab,
  ]);

  const onDelete = () => {
    CustomConfirmModal({
      title: 'Delete Schedule',
      content: (
        <p>
          Are you sure you wish to delete this schedule?
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        if (await dispatch(deleteGanttSchedule(scheduleId))) {
          setScheduleId(null);
          onClose();
        }
      },
    });
  };

  const onTabChange = useCallback((key) => {
    setActiveTab(key);
  }, [setActiveTab]);

  const canSubmit = useMemo(
    () => activeTab !== 'general' || (workingDaysList.length > 0 && !isReadOnly),
    [activeTab, workingDaysList, isReadOnly],
  );

  const onDrawerClose = useCallback(() => {
    onClose();
    setActiveTab('general');
  }, [onClose]);

  const showDelete = useMemo(() => (
    activeTab === 'schedule' && !selectedSchedule.isMaster
  ), [activeTab, selectedSchedule]);
  return (
    <Drawer
      title="Settings"
      visible={visible}
      onClose={onDrawerClose}
      width={1000}
      maskClosable={false}
      bodyStyle={{ paddingBottom: 60, paddingLeft: 0 }}
    >
      <Tabs
        activeKey={activeTab}
        tabPosition="left"
        destroyInactiveTabPane
        onChange={onTabChange}
      >
        <TabPane
          tab="General"
          key="general"
          icon={<ControlOutlined />}
        >
          <GanttScheduleGeneralSettingsTab
            isReadOnly={isReadOnly}
            settings={settings}
            scheduleId={scheduleId}
            holidays={holidays}
            workingDaysList={workingDaysList}
            setWorkingDaysList={setWorkingDaysList}
            visible={visible}
            history={history}
            onExport={onExport}
          />
        </TabPane>
        <TabPane
          tab="Appearance"
          key="appearance"
          icon={<DesktopOutlined />}
        >
          <GanttScheduleAppearanceSettingsTab
            userSettings={userSettings}
            visibleColumns={visibleColumns}
            setVisibleColumns={setVisibleColumns}
            visible={visible}
          />
        </TabPane>
        { selectedSchedule && !selectedSchedule.projectId && (
          <TabPane
            tab="Update Schedule"
            key="schedule"
          >
            <GanttScheduleEditScheduleTab
              scheduleId={scheduleId}
              setScheduleFormData={setScheduleFormData}
              schedules={schedules}
              visible={visible}
              selectedSchedule={selectedSchedule}
            />
          </TabPane>
        )}
      </Tabs>
      <DrawerSubmitFooter
        onDelete={showDelete ? onDelete : null}
        loading={loading}
        submitTitle="Save"
        canSubmit={canSubmit}
        onClose={onDrawerClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

GanttScheduleSettingsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  handleUserSettingsSubmit: PropTypes.func.isRequired,
  settings: PropTypes.shape({
    workingDays: PropTypes.arrayOf(PropTypes.number),
  }),
  userSettings: PropTypes.shape({
    ganttScheduleSettings: PropTypes.string,
  }),
  holidays: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    date: PropTypes.number,
  })),
  scheduleId: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  setScheduleId: PropTypes.func.isRequired,
  selectedSchedule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isMaster: PropTypes.number,
  }),
  onExport: PropTypes.func.isRequired,
};

GanttScheduleSettingsDrawer.defaultProps = {
  settings: {
    workingDays: defaultWorkingDays,
  },
  userSettings: {
    ganttScheduleSettings: '',
  },
  holidays: [],
  scheduleId: '',
  schedules: [],
  selectedSchedule: {},
};
