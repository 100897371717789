import React, { useCallback, useMemo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Drawer, Tree, Input } from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { constructTreeData } from '../helpers/fileHelpers';

const { DirectoryTree } = Tree;

// CONSTANTS:
export const MATERIAL_DIRECTORY = 'Material';
export const FOLDER_DIRECTORY = 'Folder';

const ROOT_TITLE = 'Materials'
const ROOTKEY = '/root';

// HELPERS:
const getMaterialFromLevel = (materials = [], groupId = null) => {
  const nodes = [];
  materials.forEach((material) => {
    if(!material.isFolder || !material.active || material.groupId !== groupId) return;
    const node = { title: material.name, key: material.id };
    node.children = getMaterialFromLevel(materials, material.id);
    nodes.push(node);
  });
  return nodes;
};

/**
 * Recursively constructs folder path for a given material
 * @param {object} materialsMap
 * @param {string} id - the material id we are constructing a path
 * @returns {string}
 */
const getMaterialFolderPath = (materialsMap = {}, id = '') => {
  // Guard clause:
  if (!id || !materialsMap[id]) return ROOT_TITLE;
  // Base case:
  const currMaterialFolder = materialsMap[id];
  if (!currMaterialFolder.groupId) return `${ROOT_TITLE}/${currMaterialFolder?.name}`;
  const parentFolderId = currMaterialFolder?.groupId;
  const parentFolderName = getMaterialFolderPath(materialsMap, parentFolderId);
  return `${parentFolderName}/${currMaterialFolder?.name}`;
};

/**
 * Selects a destination to move/copy selected items
 */
const DirectoryDestinationDrawer = ({
  loading,
  visible,
  onClose,
  onSubmit,
  selected,
  type,
  drawerTitle,
  operationTitle,
  rootTitle,
  rootKey,
  width = 400,
  zIndex = 1000,
}) => {
  const materials = useSelector((state) => state.materials.materials);
  const fileStructure = useSelector((state) => state.files.fileStructure);
  const rootFiles = useSelector((state) => state.files.rootFiles);

  const [selectedFolder, setSelectedFolder] = useState();
  const [canSubmit, setCanSubmit] = useState(false);
  const [searchTerm, setSearchTerm] = useState();

  const onSubmitClicked = useCallback(() => onSubmit(selectedFolder === rootKey ? null : selectedFolder),[rootKey, selectedFolder]);
  const onSelect = useCallback(([selected]) => {
    setSelectedFolder(selected);
    setCanSubmit(true);
  },[]);

  const onSearchChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchTerm(value);
  }, []);

  const selectedFolderPathName = useMemo(() => {
    if (!selectedFolder) {
      return '';
    }
    return type === MATERIAL_DIRECTORY
      ? getMaterialFolderPath(materials, selectedFolder)
      : selectedFolder;
  }, [selectedFolder]);

  const tree = useMemo(() => {
    switch (type) {
      case MATERIAL_DIRECTORY:
        const materialList = Object.values(materials);
        return [{
          title: rootTitle,
          key: rootKey,
          children: getMaterialFromLevel(materialList),
        }];
      case FOLDER_DIRECTORY:
        return [constructTreeData({
          id: rootKey,
          name: rootTitle,
          fileStructure,
          rootFiles,
          selectable: true,
          onlyFolders: true,
          searchTerm,
        })];
      default:
        return [{
          title: rootTitle,
          key: rootKey,
          children: [],
        }];
    }
  },[type, rootTitle, rootKey, materials, fileStructure, rootFiles, searchTerm]);

  useEffect(() => {
    if (visible) {
      setSelectedFolder(selected);
    }
  }, [visible, selected]);

  useEffect(() => {
    if(!visible) {
      setCanSubmit(false);
      setSelectedFolder();
      setSearchTerm();
    }
  },[visible]);

  return (
    <Drawer
      title={drawerTitle}
      width={width}
      visible={visible}
      onClose={onClose}
      push={false}
      zIndex={zIndex}
    >
      <div style={{ height: 40 }}>
        {selectedFolder ?
          <div style={{ overflow:'hidden', textOverflow: 'ellipsis' }}>
            {operationTitle} to destination: <b>{selectedFolderPathName}</b> ?
          </div> :
          <div>
            Please select a destination folder
          </div>
        }
      </div>
      <Input.Search
        className="searchbar"
        placeholder="Search"
        onChange={onSearchChange}
        allowClear
        value={searchTerm}
      />
      <DirectoryTree
        treeData={tree}
        onSelect={onSelect}
        defaultExpandAll
        height={window.innerHeight - 214}
        selectedKeys={[selectedFolder]}
        style={{ maxHeight: 'calc(100vh - 214px', overflow: 'auto', marginTop: 10 }}
      />
      <DrawerSubmitFooter
        loading={loading}
        onClose={onClose}
        onSubmit={onSubmitClicked}
        canSubmit={canSubmit}
      />
    </Drawer>
  )
}

DirectoryDestinationDrawer.propTypes = {
  loading: PropTypes.bool,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  selected: PropTypes.string,
  type: PropTypes.oneOf([MATERIAL_DIRECTORY, FOLDER_DIRECTORY]),
  drawerTitle: PropTypes.string,
  operationTitle: PropTypes.oneOf(['Move', 'Copy', 'Save']),
  rootTitle: PropTypes.string,
  rootKey: PropTypes.string,
  width: PropTypes.number,
  zIndex: PropTypes.number,
};

DirectoryDestinationDrawer.defaultProps = {
  zIndex: 1000,
};

export default DirectoryDestinationDrawer;
