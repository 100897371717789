import React from 'react';

import FormTextInput from '../common/inputs/FormTextInput';

export default ({
  isNotDisplay,
  type,
  name,
  value,
  required,
}) => (
  <FormTextInput
    isNotDisplay={isNotDisplay}
    value={value}
    name={name}
    label={`${type} Phone Number`}
    rules={[
      { required,message: 'Please enter a phone number'},
    ]}
  />
);
