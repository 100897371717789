import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import IntegrationEquipmentSync from '../IntegrationEquipmentSync';
import EclipseImportHelp from './EclipseImportHelp';

import { request } from '../../../helpers/requests';

import { getEquipment } from '../../../equipment/state/equipment.actions';

function EclipseEquipmentSync({
  visible,
  setCanNext,
}, ref) {
  const dispatch = useDispatch();

  const selectedDivision = useSelector((state) => state.eclipse.selectedDivision);
  const [eclipseEquipment, setEclipseEquipment] = useState([]);
  const [importData, setImportData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getEquipment());
      const {
        data: {
          data = [],
        } = {},
      } = await request({
        call: axios.get('/eclipse/data/equipment', { params: { divisionId: selectedDivision } }),
        hideSuccessToast: true,
        errMsg: 'Failed to get eclipse equipment',
      });
      setEclipseEquipment(data);
      setLoading(false);
    };

    if (visible && selectedDivision) {
      load();
    } else {
      setEclipseEquipment([]);
    }
  }, [visible, selectedDivision]);

  useImperativeHandle(ref, () => ({
    onSave: async () => {
      const { data } = await request({
        call: axios.post('/integration/import', {
          importEquipment: {
            linkDeletedList: importData.linkDeletedList,
            importList: importData.importList.map((eq) => ({
              ...eq,
              divisionId: selectedDivision,
            })),
          },
          integrationKey: 'eclipseId',
        }),
        hideSuccessToast: true,
        errMsg: 'Failed to import eclipse equipment',
      });
      return !!data;
    },
  }), [selectedDivision, importData]);

  useEffect(() => {
    if (visible) setCanNext(!loading);
  }, [visible, loading, setCanNext]);

  return (
    <IntegrationEquipmentSync
      integrationEquipment={eclipseEquipment}
      title="Eclipse"
      integrationKey="eclipseId"
      onEquipmentChanged={setImportData}
      importHelpText={<EclipseImportHelp type="Equipment" />}
      importEquipment={importData}
      divisionId={selectedDivision}
      loading={loading}
    />
  );
}

const EclipseEquipmentSyncRef = forwardRef(EclipseEquipmentSync);
EclipseEquipmentSyncRef.propTypes = {
  visible: PropTypes.bool.isRequired,
  setCanNext: PropTypes.func.isRequired,
};

export default EclipseEquipmentSyncRef;
