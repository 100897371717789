import {
  SET_MENU,
  SET_MENU_OPEN_KEYS,
} from '../../state/actionTypes';

const safeWindowSet = (key, val) => {
  try {
    // eslint-disable-next-line no-undef
    window.sessionStorage.setItem(key, JSON.stringify(val));
  } catch (err) {
    // eslint-disable-next-line no-console
    console.log('Failed to set storage', key, val);
  }
};

export const setMenu = (items = []) => {
  safeWindowSet('menu.selectedKeys', items);
  return ({
    type: SET_MENU,
    payload: {
      items,
    },
  });
};

export const setOpenKeys = (keys = []) => {
  safeWindowSet('menu.openKeys', keys);
  return ({
    type: SET_MENU_OPEN_KEYS,
    payload: {
      openKeys: keys,
    },
  });
};
