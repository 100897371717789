import {
  GET_BILLING_RATES,
  CREATE_BILLING_RATE,
  DELETE_BILLING_RATE,
  UPDATE_BILLING_RATE,
} from '../../state/actionTypes';

import { getIdMap } from '../../helpers/helpers';
import { removeFromObject } from '../../helpers/stateHelpers';

const initialState = {};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_BILLING_RATES: {
      const {
        payload: {
          rates = [],
        } = {},
      } = action;
      return getIdMap(rates);
    }
    case CREATE_BILLING_RATE: {
      const {
        payload: {
          rate = {},
        } = {},
      } = action;
      const newRates = { ...state };
      newRates[rate.id] = {
        ...rate,
        active: 1,
      };
      return newRates;
    }
    case DELETE_BILLING_RATE: {
      const {
        payload: {
          rateId,
        } = {},
      } = action;
      return removeFromObject({ oldObject: state, id: rateId });
    }
    case UPDATE_BILLING_RATE: {
      const {
        payload: {
          id,
          newData = {},
        } = {},
      } = action;
      const newRates = { ...state };
      newRates[id] = {
        ...newRates[id],
        ...newData,
      };
      return newRates;
    }
    default:
      return state;
  }
};
