import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';

import FormTextInput from '../common/inputs/FormTextInput';

import { CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE, CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE, CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE } from './contactConstants';

/** Contact Address Book Form Component */
const ContactAddressBookForm = ({
  form,
  mode,
  selected: {
    name,
    role,
    phoneNumber,
    email,
  },
}) => {
  const isNotDisplay = mode === CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE
    || mode === CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE;
  return (
    <Form
      form={form}
      layout="vertical"
      style={{ width: '100%' }}
    >
      <FormTextInput
        isNotDisplay={isNotDisplay}
        label="Name"
        name="name"
        value={name}
        rules={[{ required: true, message: 'Please input contact name' }]}
      />
      <FormTextInput
        isNotDisplay={isNotDisplay}
        label="Role"
        name="role"
        value={role}
      />
      <FormTextInput
        isNotDisplay={isNotDisplay}
        label="Phone Number"
        name="phoneNumber"
        value={phoneNumber}
      />
      <FormTextInput
        isNotDisplay={isNotDisplay}
        label="Email"
        name="email"
        value={email}
        rules={[{ type: 'email', message: 'Invalid email format' }]}
      />
    </Form>
  );
};

ContactAddressBookForm.propTypes = {
  form: PropTypes.object.isRequired,
  mode: PropTypes.oneOf([
    CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE,
    CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE,
    CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  ]),
  selected: PropTypes.object,
};

ContactAddressBookForm.defaultProps = {
  mode: CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE,
  selected: {},
};

export default ContactAddressBookForm;
