import PropTypes from 'prop-types';

// ---------------------------------------------------
// Type Definitions:
/**
 * @typedef {Object} CostcodeDistribution
 * @param {string} phaseId
 * @param {string} phaseName
 * @param {string} costcodeId
 * @param {string} costcodeName
 * @param {string} code
 * @param {number} amount
 */

// ---------------------------------------------------
// PropTypes:
export const INVOICE_PROP = {
  id: PropTypes.string,
  formId: PropTypes.string,
  vendorId: PropTypes.string,
  companyId: PropTypes.string,
  invoiceNumber: PropTypes.string,
  amount: PropTypes.number,
  description: PropTypes.string,
  dateIssued: PropTypes.number,
  dueDate: PropTypes.number,
  files: PropTypes.array,
};

export const INVOICE_COSTCODE_DISTRIBUTION_PROP = {
  phaseId: PropTypes.string.isRequired,
  phaseName: PropTypes.string,
  costcodeId: PropTypes.string.isRequired,
  costcodeName: PropTypes.string,
  code: PropTypes.string,
  amount: PropTypes.number,
};


// ---------------------------------------------------
// Other:
export const INVOICE_DRAWER_ADD_MODE = 'Add Invoice';
export const INVOICE_DRAWER_EDIT_MODE = 'Edit Invoice';
export const INVOICE_DRAWER_VIEW_MODE = 'View Invoice';
