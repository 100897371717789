/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useCallback, useMemo,
} from 'react';
import { PropTypes } from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getIdMap } from 'ontraccr-common/lib/Common';
import { openCardDrawer, openFormMapDrawer } from '../state/boards.actions';
import { getCardsByStatus } from '../boards.helpers';
import { getCustomColumns, parseCards } from './BoardTimeline.helpers';
import { defaultGanttColumns } from './BoardTimeline.constants';
import GanttView from '../../common/tables/gantt/GanttView';

export default function BoardTimelineView({
  cardTypeId,
  isLocked,
  userBoardPermissions,
  timelineDisplayFields = [],
}) {
  const dispatch = useDispatch();

  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const { type: sortType, field } = useSelector((state) => state.boards.sortType);
  const isFullScreen = useSelector((state) => state.main.menuCollapsed || state.main.fullscreen);
  const filters = useSelector((state) => state.boards.filters);
  const projects = useSelector((state) => state.projects.projects);
  const {
    [cardTypeId]: cardTemplate = {},
  } = useSelector((state) => state.boards.cardTemplates);

  const ganttViewType = useSelector((state) => state.boards.ganttViewType);
  const ganttZoomScale = useSelector((state) => state.boards.ganttZoomScale);

  const {
    cards = [],
    statuses = [],
  } = selectedBoard || {};

  const cardMap = useMemo(() => getIdMap(cards), [cards]);
  const statusMap = useMemo(() => getIdMap(statuses), [statuses]);
  const { canEdit } = userBoardPermissions;

  const columns = useMemo(() => (
    [...defaultGanttColumns, ...getCustomColumns({ sections: cardTemplate?.fields })]
  ), [cardTemplate]);

  const ganttSelectedColumns = useMemo(() => (
    columns.filter(({ property }) => timelineDisplayFields.includes(property))
  ), [columns, timelineDisplayFields]);

  const cardsByStatusId = useMemo(() => (
    getCardsByStatus({
      cards,
      sortType,
      filters,
      field,
      projects,
    }) ?? {}
  ), [cards, sortType, filters, field, projects]);

  const onForm = useCallback((cardId) => {
    const {
      [cardId]: newSelectedCard,
    } = cardMap;
    if (!newSelectedCard) return;
    dispatch(openFormMapDrawer({
      selectedCardId: cardId,
    }));
  }, [dispatch, cardMap]);

  const onCardClick = useCallback((card) => {
    dispatch(openCardDrawer({
      selectedCardId: card.id,
      onForm,
    }));
  }, [dispatch, onForm]);

  const onEditClicked = useCallback((card) => {
    dispatch(openCardDrawer({
      selectedCardId: card.id,
      editMode: canEdit,
      isLocked,
    }));
  }, [dispatch, isLocked]);

  const cardTasks = useMemo(
    () => {
      const flattenedCards = Object.values(cardsByStatusId).flat();
      const parsedCards = parseCards({ cards: flattenedCards, statusMap });
      return parsedCards;
    },
    [cardsByStatusId],
  );

  const renderToolTip = ({
    task,
  }) => (
    <p style={{
      height: '100%',
      width: '100%',
      padding: '0.5em 1em',
      borderRadius: '0.2em',
      background: 'white',
      border: 'solid 1px #ddd',
    }}
    >
      <span style={{ fontWeight: 'bold' }}>{task.name}</span>
      <br />
      Start:
      {' '}
      {task.startDate}
      <br />
      End:
      {' '}
      {task.endDate}
    </p>
  );

  const className = isFullScreen ? 'board-list-view-container-fullscreen' : 'board-list-view-container-regular';

  return (

    <GanttView
      className={className}
      tasks={cardTasks}
      ganttViewType={ganttViewType}
      ganttZoomScale={ganttZoomScale}
      canEdit={canEdit}
      visibleColumns={ganttSelectedColumns}
      onDoubleClick={onCardClick}
      onEditClick={onEditClicked}
      TooltipContent={renderToolTip}
    />
  );
}

BoardTimelineView.propTypes = {
  cardTypeId: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  userBoardPermissions: PropTypes.shape({
    canEdit: PropTypes.bool,
  }),
  timelineDisplayFields: PropTypes.arrayOf(PropTypes.string),
};

BoardTimelineView.defaultProps = {
  userBoardPermissions: {},
  timelineDisplayFields: [],
};
