import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Permissions from '../auth/Permissions';
import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';

import ProjectList from './projectList';
import {
  getProjects,
  getProjectEquipment,
  getProjectFileTemplates,
  getProjectTypes,
} from './state/projects.actions';
import {
  getAllCostCodes,
  getCategories,
  getTemplates as getCostcodeTemplates,
} from '../costcodes/state/costcodes.actions';
import { getProgress } from '../reports/state/reports.actions';
import { getEquipment, getStatuses as getEquipmentStatuses, getEquipmentTypes } from '../equipment/state/equipment.actions';
import { getCustomerLabels, getCustomers } from '../contacts/customers/state/customers.actions';
import { getLabels } from '../labels/state/labels.actions';
import { getBillingRates } from '../billingRates/state/billingRates.actions';
import {
  getTemplates,
  getStatuses,
  getCustomTables,
} from '../forms/state/forms.actions';
import {
  getEmails,
} from '../emails/state/email.actions';
import { getUserLabels } from '../users/state/users.actions';
import { createNuxEntry } from '../nux/state/nux.actions';
import { getCardLinks } from '../boards/state/boards.actions';
import {
  getInvoices,
  getInvoiceStatusLabels,
} from '../payables/invoices/state/invoices.actions';

import { PROJECT_GENERAL_TYPE } from '../nux/nux.constants';
import { getIdMap } from '../helpers/helpers';
import { getSavedAnalyticsReports } from '../analytics/state/analytics.actions';
import { getSavedDashboards } from '../dashboard/state/dashboard.actions';

import { PROJECT_ANALYTICS_TYPE } from '../analytics/analytics.constants';

export default function Projects(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const nux = useSelector((state) => state.nux.nux);
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const [crumbs, setCrumbs] = useState([]);

  const { typeId } = useParams();

  const projectTypeMap = useMemo(() => getIdMap(projectTypes), [projectTypes]);

  useEffect(() => {
    dispatch(getProjects());
    dispatch(getAllCostCodes());
    dispatch(getProgress());
    dispatch(getCategories());
    dispatch(getEquipment());
    dispatch(getProjectEquipment());
    dispatch(getLabels());
    dispatch(getCustomers());
    dispatch(getCustomerLabels());
    dispatch(getBillingRates());
    dispatch(getTemplates());
    dispatch(getEmails());
    dispatch(getStatuses());
    dispatch(getCustomTables());
    dispatch(getUserLabels());
    dispatch(getCardLinks());
    dispatch(getCostcodeTemplates());
    dispatch(getInvoices());
    dispatch(getInvoiceStatusLabels());
    dispatch(getProjectFileTemplates());
    dispatch(getEquipmentStatuses());
    dispatch(getProjectTypes());
    dispatch(getSavedAnalyticsReports(PROJECT_ANALYTICS_TYPE));
    dispatch(getSavedDashboards(PROJECT_ANALYTICS_TYPE));
    dispatch(getEquipmentTypes());
  }, []);

  useEffect(() => {
    const relevantProjectType = projectTypeMap[typeId];
    setCrumbs([{
      text: relevantProjectType?.name ? `${t('Project', { count: 2 })} - ${relevantProjectType?.name}` : t('Project', { count: 2 }),
      icon: relevantProjectType?.icon ?? 'project',
    }]);
  }, [typeId, projectTypeMap]);

  useEffect(() => {
    if (!nux?.has(PROJECT_GENERAL_TYPE)) {
      dispatch(createNuxEntry(PROJECT_GENERAL_TYPE));
    }
  }, [nux]);

  if (!Permissions.has('PROJECTS_WRITE')) return <Redirect to="/dashboard" />;
  return (
    <BreadCrumbContainer crumbs={crumbs}>
      <ProjectList {...props} type={typeId} />
    </BreadCrumbContainer>
  );
}
