import React from 'react';

import BillingRateRow from '../billingRates/BillingRateRow';

import FilteredFormList from '../forms/FilteredFormList';

export default ({
  id,
  isNotDisplay,
  materialDiscount,
  contractExpirationTimestamp,
  contractReminderTimestamp,
  customBillingRate,
  billingContact,
  poNumber,
  visible,
}) => (
  <>
    <BillingRateRow
      isNotDisplay={isNotDisplay}
      materialDiscount={materialDiscount}
      showContractOptions
      contractExpirationTimestamp={contractExpirationTimestamp}
      contractReminderTimestamp={contractReminderTimestamp}
      customBillingRate={customBillingRate}
      showPOandContact
      billingContact={billingContact}
      poNumber={poNumber}
    />
    <FilteredFormList
      formType='Invoice'
      filterId={id}
      filterType='Projects'
      isNotDisplay={isNotDisplay}
      topOffset={contractExpirationTimestamp ? 370 : 309}
      visible={visible}
    />
  </>
)
