import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import {
  Col,
  Drawer,
  Row,
  Tabs,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import moment from 'moment';
import { DateTime } from 'luxon';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import TimeCardPhotos from './TimeCardPhotos';
import ManualEntry from '../../clock/ManualEntry/ManualEntry';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import TimeCardTimeline from './TimeCardTimeline';
import SliderDownloadButton from '../../common/buttons/SliderDownloadButton';
import { constructTimecardPDF, loadManualEntryData } from '../../clock/ManualEntry/manualEntryHelpers';
import { getIdMap } from '../../helpers/helpers';
import { getCustomFields } from '../state/timecards.actions';
import { parseCompletedForm } from '../../forms/formHelpers';
import { downloadFile } from '../../files/fileHelpers';
import { generateConfigPropsMap } from '../../forms/ResponderHelpers';

const { TabPane } = Tabs;

export default function TimeCardSlider({
  visible,
  onClose,
  user,
  personal,
  initialEntries,
  initialHistory,
  defaultDate,
  isDisplay,
  onEditClicked,
  canEditApproved,
  canEditUnapproved,
  day,
  isApprovals,
  showFormDrawer,
  setShowFormDrawer,
  isSummary,
  updateManualEntryData,
  isCopying,
  setIsCopying,
  isRedistributingHours,
  setIsRedistributingHours,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    company: {
      settings = {},
      companyImageURL: logo,
    } = {},
  } = useSelector((state) => state.settings);
  const { classes = [] } = useSelector((state) => state.unions);
  const customFields = useSelector((state) => state.timecards.customFields);
  const users = useSelector((state) => state.users.users);
  const phases = useSelector((state) => state.costcodes.phases);
  const projects = useSelector((state) => state.projects.projects);

  const [isLoading, setIsLoading] = useState();

  const userMap = useMemo(() => getIdMap(users), [users]);
  const phaseIdMap = useMemo(() => getIdMap(phases), [phases]);
  const classMap = useMemo(() => getIdMap(classes), [classes]);
  const projectIdMap = useMemo(() => getIdMap((projects)), [projects]);

  const ourUser = useMemo(() => {
    if (!isSummary) return user;

    const [{ userId } = {}] = initialEntries ?? [];

    const {
      [userId]: entryUser = {},
    } = userMap;
    return entryUser;
  }, [isSummary, user, initialEntries, userMap]);

  const {
    name,
    class: defaultClassId,
    employeeId,
  } = ourUser;

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  const onCloseClicked = useCallback(() => {
    onClose();
  }, [onClose]);

  const onAddClicked = useCallback(() => {
    setShowFormDrawer(true);
  }, []);

  const title = useMemo(() => {
    const prefix = defaultDate && moment.isMoment(defaultDate)
      ? `${defaultDate.format('ddd MMM Do YYYY')} - `
      : '';
    const suffix = name ? `${name}'s time` : 'Your Time';

    return (
      <Row gutter={20} align="middle" justify="space-between" style={{ marginRight: 50, height: 32 }}>
        <Col>
          {`${prefix}${suffix}`}
        </Col>
        <Col>
          {!isDisplay && canEditUnapproved && (
            <OnTraccrButton
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
              disabled={isLoading}
            />
          )}
        </Col>
      </Row>
    );
  }, [name, defaultDate, isDisplay, canEditUnapproved]);

  const manualEntry = useMemo(() => (
    <ManualEntry
      user={ourUser}
      visible={visible}
      personal={personal}
      initialEntries={initialEntries}
      defaultDate={defaultDate}
      isDisplay={isDisplay}
      onEditClicked={onEditClicked}
      canEditApproved={canEditApproved}
      canEditUnapproved={canEditUnapproved}
      showFormDrawer={showFormDrawer}
      setShowFormDrawer={setShowFormDrawer}
      onClose={onClose}
      day={day}
      isApprovals={isApprovals}
      isSummary={isSummary}
      updateManualEntryData={updateManualEntryData}
      isCopying={isCopying}
      setIsCopying={setIsCopying}
      setIsLoading={setIsLoading}
      isRedistributingHours={isRedistributingHours}
      setIsRedistributingHours={setIsRedistributingHours}
    />
  ), [
    ourUser,
    visible,
    personal,
    initialEntries,
    defaultDate,
    isDisplay,
    onEditClicked,
    canEditApproved,
    canEditUnapproved,
    showFormDrawer,
    setShowFormDrawer,
    onClose,
    day,
    isApprovals,
  ]);

  const onDownload = useCallback(async () => {
    const taskIds = initialEntries.map((entry) => entry.id).filter((entry) => !entry.isPending);
    const {
      dataMap: customDataMap,
      fileMap,
    } = await loadManualEntryData(taskIds, true);

    const fullTasks = initialEntries.map((entry) => {
      const {
        [entry.id]: {
          data: initialData = [],
          divisionId,
        } = {},
      } = customDataMap;

      const {
        [divisionId]: { fields: sections = [] } = {},
      } = customFields;

      const configPropsMap = generateConfigPropsMap(sections);
      const parsedForm = parseCompletedForm({
        sections: initialData,
        fileMap,
        configPropsMap,
      });

      const entryClassId = entry.classId ?? defaultClassId;
      return {
        ...entry,
        customData: initialData,
        formattedCustomData: parsedForm,
        divisionId,
        classId: entryClassId,
        className: classMap?.[entryClassId]?.name,
        phase: phaseIdMap?.[entry.phaseId]?.name,
      };
    });

    const rawPDFData = await constructTimecardPDF(fullTasks, {
      employeeId,
      name,
      settings,
      logo,
      fileMap,
      projectIdMap,
    }, t);

    const suffix = defaultDate && moment.isMoment(defaultDate)
      ? `-${defaultDate.format('ddd MMM Do YYYY')}`
      : '';
    const timecardPDF = new File([rawPDFData], `${name}-Time Card${suffix}.pdf`, { type: 'application/pdf' });
    downloadFile({ fileObject: timecardPDF });
  }, [
    initialEntries,
    employeeId,
    name,
    defaultClassId,
    classMap,
    customFields,
    settings,
    logo,
    defaultDate,
    phaseIdMap,
  ]);

  return (
    <Drawer
      width="95vw"
      title={title}
      onClose={onCloseClicked}
      visible={visible}
      bodyStyle={{
        padding: 0,
      }}
      maskClosable={false}
      push={false}
    >
      {isDisplay ? (
        <Tabs
          style={{
            padding: '0px 24px',
          }}
        >
          <TabPane
            tab="Entries"
            key="entries"
          >
            { manualEntry }
          </TabPane>
          <TabPane tab="Photos" key="photos">
            <TimeCardPhotos
              visible={visible}
              user={ourUser}
              initialEntries={initialEntries}
            />
          </TabPane>
          <TabPane tab="Timeline" key="timeline">
            <TimeCardTimeline
              entries={initialHistory}
            />
          </TabPane>
        </Tabs>
      ) : manualEntry}
      { isDisplay && <SliderDownloadButton onDownload={onDownload} />}
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardSlider.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object,
  personal: PropTypes.bool,
  initialEntries: PropTypes.array,
  initialHistory: PropTypes.array,
  defaultDate: PropTypes.string,
  isDisplay: PropTypes.bool,
  onEditClicked: PropTypes.func.isRequired,
  canEditApproved: PropTypes.bool,
  canEditUnapproved: PropTypes.bool,
  day: PropTypes.object,
  isApprovals: PropTypes.bool,
  showFormDrawer: PropTypes.bool,
  setShowFormDrawer: PropTypes.func.isRequired,
  isSummary: PropTypes.bool,
  updateManualEntryData: PropTypes.func.isRequired,
  isCopying: PropTypes.bool.isRequired,
  setIsCopying: PropTypes.func.isRequired,
  isRedistributingHours: PropTypes.bool,
  setIsRedistributingHours: PropTypes.func,
};

TimeCardSlider.defaultProps = {
  visible: false,
  user: {},
  personal: false,
  initialEntries: [],
  initialHistory: [],
  defaultDate: DateTime.local().toSQLDate(),
  isDisplay: false,
  canEditApproved: false,
  canEditUnapproved: false,
  isApprovals: false,
  day: {},
  showFormDrawer: false,
  isSummary: false,
  isRedistributingHours: false,
  setIsRedistributingHours: () => {},
};
