import React, {
  useCallback,
  useState,
  useEffect,
} from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col,
  Row,
  Divider,
} from 'antd';

import OnTraccrButton from '../buttons/OnTraccrButton';
import DashboardContainer from './DashboardContainer';
import AnalyticsSlider from './AnalyticsSlider';
import DashboardAnalyticsHeader from './DashboardAnalyticsHeader';

import { setInitialDashboard } from '../../dashboard/state/dashboard.actions';

function DashboardAnalyticsContainer({
  type,
  dashboardConfig,
  analyticsConfig,
  projectId,
  projectTypeId,
  cardId,
  cardTypeId,
  boardId,
}) {
  const dispatch = useDispatch();

  const {
    selectedDashboard: {
      id: selectedDashboardId,
      layout: selectedLayout,
    } = {},
    isEdit,
    analyticsData,
  } = dashboardConfig;

  const { savedReports } = analyticsConfig;

  const [currentLayout, setCurrentLayout] = useState([]);
  const [layout, setLayout] = useState([]);

  const [showAnalyticsSlider, setShowAnalyticsSlider] = useState(false);

  const openAnalyticsSlider = useCallback(() => setShowAnalyticsSlider(true), []);
  const closeAnalyticsSlider = useCallback(() => setShowAnalyticsSlider(false), []);

  useEffect(() => {
    if (selectedLayout?.length) {
      setCurrentLayout(selectedLayout);
      setLayout(selectedLayout);
    } else {
      setCurrentLayout([]);
      setLayout([]);
    }
  }, [selectedLayout]);

  useEffect(() => {
    dispatch(setInitialDashboard({
      type,
      projectTypeId,
      cardTypeId,
    }));
  }, [type, projectTypeId, cardTypeId]);

  return (
    <>
      <div style={{ height: 'auto', width: '100%' }}>
        <Row type="flex" justify="start" style={{ paddingTop: 14 }}>
          <Col>
            <DashboardAnalyticsHeader
              projectTypeId={projectTypeId}
              currentLayout={currentLayout}
              layout={layout}
              setCurrentLayout={setCurrentLayout}
              savedReports={savedReports}
              analyticsType={type}
              dashboardConfig={dashboardConfig}
              cardTypeId={cardTypeId}
            />
          </Col>
          <Col flex="auto">
            <Row align="middle" justify="end">
              <OnTraccrButton title="Reports" onClick={openAnalyticsSlider} />
            </Row>
          </Col>
        </Row>
        <Divider />
        <DashboardContainer
          currentLayout={currentLayout}
          setCurrentLayout={setCurrentLayout}
          savedReports={savedReports}
          analyticsData={analyticsData}
          selectedDashboardId={selectedDashboardId}
          isEdit={isEdit}
          analyticsType={type}
          projectId={projectId}
          cardId={cardId}
        />
      </div>
      <AnalyticsSlider
        visible={showAnalyticsSlider}
        onClose={closeAnalyticsSlider}
        projectId={projectId}
        projectTypeId={projectTypeId}
        type={type}
        analyticsConfig={analyticsConfig}
        cardId={cardId}
        cardTypeId={cardTypeId}
        boardId={boardId}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
DashboardAnalyticsContainer.propTypes = {
  type: PropTypes.string,
  dashboardConfig: PropTypes.object,
  analyticsConfig: PropTypes.object,
  projectId: PropTypes.string,
  projectTypeId: PropTypes.string,
  cardId: PropTypes.string,
  cardTypeId: PropTypes.number,
  boardId: PropTypes.string,
};

DashboardAnalyticsContainer.defaultProps = {
  type: undefined,
  dashboardConfig: {},
  analyticsConfig: {},
  projectTypeId: null,
  projectId: undefined,
  cardId: undefined,
  cardTypeId: undefined,
  boardId: undefined,
};

export default DashboardAnalyticsContainer;
