import {
  GET_SUBTASKS,
  CREATE_SUBTASK,
  DELETE_SUBTASK,
  UPDATE_SUBTASK,
  GET_SUBTASK_FILES,
  GET_SUBTASK_REMINDERS,
  GET_SUBTASK_TEMPLATES,
  CREATE_SUBTASK_TEMPLATE,
  UPDATE_SUBTASK_TEMPLATE,
  DELETE_SUBTASK_TEMPLATE,
  GET_SUBTASK_TEMPLATE_FORM_DATA,
  GET_SUBTASK_FORM_DATA,
  RESET_SUBTASK_FORM_DATA,
  CHANGE_SUBTASK_STATUS,
} from '../../state/actionTypes';

import { getIdMap, updateState } from '../../helpers/helpers';

const initialState = {
  subtasks: {},
  subtaskFiles: {}, // { [taskId]: [list of files] },
  reminderEmails: {}, // { [taskId]: [list of emails] },
  reminders: {}, // { [taskId]: [list of reminders] },
  templates: [],
  formData: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_SUBTASKS: {
      const {
        payload: {
          subtasks = [],
        } = {},
      } = action;
      return {
        ...state,
        subtasks: getIdMap(subtasks),
      };
    }
    case CREATE_SUBTASK: {
      const {
        payload: {
          newSubtasks = [],
        } = {},
      } = action;

      const {
        subtasks = {},
      } = state;
      const newSubtaskMap = { ...subtasks };
      newSubtasks.forEach((subtask) => {
        if (subtask.id in subtasks) return;
        newSubtaskMap[subtask.id] = subtask;
      });
      return {
        ...state,
        subtasks: newSubtaskMap,
      };
    }
    case UPDATE_SUBTASK: {
      const {
        payload: {
          id,
          newData = [],
        } = {},
      } = action;

      const {
        subtaskFiles: stateFiles = {},
        subtasks = {},
      } = state;

      const newSubtasks = { ...subtasks };
      const newSubtaskFiles = { ...stateFiles };

      newData.forEach((subtask) => {
        const newDetails = { ...subtask };
        const {
          addedFiles = [],
          removedFiles = [],
        } = newDetails;

        const {
          [subtask.id]: oldFiles = [],
        } = newSubtaskFiles;

        delete newDetails.addedFiles;
        delete newDetails.removedFiles;

        const fileDeleteSet = new Set(removedFiles);

        const newEF = oldFiles.filter((file) => !fileDeleteSet.has(file.id)).concat(addedFiles);
        newSubtaskFiles[subtask.id] = newEF;

        newSubtasks[subtask.id] = {
          ...newSubtasks[id] ?? {},
          ...newDetails,
        };
      });

      return {
        ...state,
        subtasks: newSubtasks,
        subtaskFiles: newSubtaskFiles,
      };
    }
    case DELETE_SUBTASK: {
      const {
        payload: {
          subtaskIds,
        } = {},
      } = action;
      const {
        subtasks = {},
        subtaskFiles = {},
      } = state;
      const newSubtasks = { ...subtasks };
      const newFiles = { ...subtaskFiles };
      subtaskIds.forEach((id) => {
        delete newSubtasks[id];
        delete newFiles[id];
      });
      return {
        ...state,
        subtasks: newSubtasks,
        subtaskFiles: newFiles,
      };
    }
    case GET_SUBTASK_FILES: {
      const {
        payload: {
          id,
          files = [],
        },
      } = action;
      const newState = { ...state };
      const { subtaskFiles: oldFiles = {} } = state;
      const newFiles = { ...oldFiles };
      newFiles[id] = files;
      newState.subtaskFiles = newFiles;
      return newState;
    }
    case GET_SUBTASK_REMINDERS: {
      const {
        payload: {
          id,
          reminders = [],
          reminderEmails = [],
        },
      } = action;
      const newState = { ...state };
      const {
        reminderEmails: oldEmails = {},
        reminders: oldReminders = {},
      } = state;
      const newEmails = { ...oldEmails };
      const newReminders = { ...oldReminders };
      newEmails[id] = reminderEmails;
      newReminders[id] = reminders;
      newState.reminderEmails = newEmails;
      newState.reminders = newReminders;
      return newState;
    }
    case GET_SUBTASK_TEMPLATES: {
      const {
        payload: {
          templates = [],
        } = {},
      } = action;
      return {
        ...state,
        templates,
      };
    }
    case CREATE_SUBTASK_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      const copiedTemplates = state.templates.slice();
      copiedTemplates.push(template);
      return {
        ...state,
        templates: copiedTemplates,
      };
    }
    case UPDATE_SUBTASK_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      return {
        ...state,
        templates: updateState(state.templates, template),
      };
    }
    case DELETE_SUBTASK_TEMPLATE: {
      const {
        payload: {
          deletedId,
        } = {},
      } = action;
      return {
        ...state,
        templates: state.templates.filter((template) => template.id !== deletedId),
      };
    }
    case GET_SUBTASK_FORM_DATA:
    case GET_SUBTASK_TEMPLATE_FORM_DATA: {
      const {
        payload: {
          formTemplate: {
            data,
            fileMap,
          } = {},
        } = {},
      } = action;
      return {
        ...state,
        formData: {
          data,
          fileMap,
        },
      };
    }
    case RESET_SUBTASK_FORM_DATA: {
      return {
        ...state,
        formData: {},
      };
    }
    case CHANGE_SUBTASK_STATUS: {
      const {
        payload: {
          id,
          status,
        } = {},
      } = action;

      const { subtasks = {} } = state;
      const newSubtasks = { ...subtasks };

      if (id in newSubtasks) {
        newSubtasks[id] = {
          ...newSubtasks[id] ?? {},
          status,
        };
      }

      return {
        ...state,
        subtasks: newSubtasks,
      };
    }
    default:
      return state;
  }
};
