import React from 'react';
import { Row } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';

import colors from '../../constants/Colors';

export default () => (
  <Row justify='center' align='middle' style={{
    width: 34,
    height: 34,
    borderRadius:17,
    border:'solid',
    borderWidth:2,
    borderColor:colors.ONTRACCR_GRAY,
  }}>
    <CalendarOutlined style={{ fontSize: 18 }}/>
  </Row>
);