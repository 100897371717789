import React, {
  useState, useEffect, useCallback, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Row, Col, Typography, Tooltip } from 'antd';
import { TaskHelpers } from 'ontraccr-common';
import { DateTime } from 'luxon';

import OnTraccrButton from '../buttons/OnTraccrButton';

import ClockButtons from './ClockButtons';
import { getButtonStyle, smallTimerStyle, titleStyle } from './ClockCommon';

import colors from '../../constants/Colors';

import { nowIsWorkingHours } from './clock.helpers';

import { useInterval } from '../../helpers/helpers';

import Permissions from '../../auth/Permissions';

const getClockText = (text, switchStarted) => (
  <div style={{
    ...smallTimerStyle,
    opacity: switchStarted ? 0.25 : 1,
    fontFamily: 'roboto-light',
  }}
  >
    {text}
  </div>
);

const getClockColor = (formattedDay) => {
  const { currentTask = {} } = formattedDay ?? {};
  if (currentTask.startTime && !currentTask.endTime) {
    return currentTask.type === 'break'
      ? colors.ONTRACCR_DARK_YELLOW
      : colors.ONTRACCR_BLACK;
  }
  return colors.ONTRACCR_OPACITY_GRAY;
};

const DEFAULT_CLOCK_TIME = '00:00:00';
const CLOCKIN_KEY = 'clockin';
const CLOCKOUT_KEY = 'clockout';

function ClockInTimer({
  onManualEntry,
  onClockIn,
  onClockOut,
  user,
  onBreakStart,
  onBreakEnd,
  externalSwitchHandling,
  showButtons,
  onSwitch,
  onSwitchStart,
  onSwitchCancel,
  canClock,
}) {
  const { position } = user ?? {};
  const isMe = user?.id === Permissions.id;

  const workingHours = useSelector((state) => state.settings.workingHours);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

  const [running, setRunning] = useState();
  const [switchStarted, setSwitch] = useState();
  const [breakStart, setBreakStart] = useState();
  const [breakTime, setBreakTime] = useState();
  const [clockTime, setClockTime] = useState(DEFAULT_CLOCK_TIME);
  const [clockTimeTooltip, setClockTimeTooltip] = useState();
  const [clockInText, setClockInText] = useState();
  const [clockOutText, setClockOutText] = useState();
  const [buttonLoading, setButtonLoading] = useState();

  const [totalClockTime, setTotalClock] = useState(DEFAULT_CLOCK_TIME);
  const [totalTimeTooltip, setTotalTimeTooltip] = useState();
  const [tickInterval, setTickInterval] = useState(null);
  const myHours = workingHours[position] ?? {};

  const {
    formattedDay,
    todayRoundedTask,
  } = useMemo(() => {
    const tasks = timeEntryUserMap[user?.id] ?? [];
    let todayLastRoundedTask;

    // find the last task today that is auto rounded
    for (let i = tasks.length - 1; i >= 0; i -= 1) {
      const task = tasks[i];
      if (!TaskHelpers.taskIsToday(task)) {
        break;
      }

      if (task.isAutoRounded) {
        todayLastRoundedTask = task;
        break;
      }
    }

    return {
      formattedDay: TaskHelpers.formatTodaysTasks(tasks),
      todayRoundedTask: todayLastRoundedTask,
    };
  }, [timeEntryUserMap, user]);

  const shouldWorkingHoursBlockClockIn = isMe && myHours.startTime && myHours.endTime
    ? !nowIsWorkingHours(myHours)
    : false;

  const shouldRoundedTaskBlockClockIn = todayRoundedTask
    && (todayRoundedTask.endTime > DateTime.local().toMillis());

  const isOnBreak = formattedDay?.currentTask?.type === 'break' && !formattedDay?.currentTask?.endTime;
  const canBreak = isOnBreak ? !!onBreakEnd : !!onBreakStart;
  const canSwitch = (switchStarted && onSwitch) || onSwitchStart;

  const taskId = formattedDay?.currentTask?.id;
  const onBreakHelper = useCallback(async () => {
    if (!isOnBreak) {
      if (await onBreakStart(taskId)) {
        setBreakStart(moment());
      }
    } else if (await onBreakEnd(taskId)) {
      setBreakStart(null);
      setRunning(false);
      setRunning(true);
    }
  }, [
    isOnBreak,
    onBreakStart,
    onBreakEnd,
    taskId,
  ]);

  const onSwitchCancelHelper = useCallback(async () => {
    await onSwitchCancel();
    setSwitch(false);
  }, [onSwitchCancel, setSwitch]);

  const onSwitchHelper = useCallback(async () => {
    if (switchStarted) {
      if (await onSwitch(taskId)) {
        setRunning(false);
        setRunning(true); // Hacky way to reset timer
        if (externalSwitchHandling) return;
        setSwitch(false);
      }
    } else {
      await onSwitchStart();
      if (externalSwitchHandling) return;
      setSwitch(true);
    }
  }, [
    onSwitch,
    switchStarted,
    onSwitchStart,
    externalSwitchHandling,
    taskId,
  ]);

  useEffect(() => {
    const {
      currentTask,
    } = formattedDay;
    if (!currentTask) {
      setRunning(false);
      setClockTime(DEFAULT_CLOCK_TIME);
      setClockTimeTooltip();
      setTotalTimeTooltip();
      setBreakTime();
      setTotalClock();
      return;
    }
    setTickInterval(currentTask.endTime ? null : 250);
    if (!currentTask.endTime) {
      setRunning(true);
      return;
    }
    setRunning(false);
    if (TaskHelpers.taskIsToday(currentTask)) {
      const {
        currentTime,
        totalTime,
        breakTime: newBreakTime,
        formattedTotalTime,
        formattedCurrentTime,
      } = TaskHelpers.getClockTimes({ formattedDay });
      if (clockTime) setClockTime(currentTime);
      if (totalTime) setTotalClock(totalTime);
      if (formattedCurrentTime?.length) setClockTimeTooltip(formattedCurrentTime);
      if (formattedTotalTime?.length) setTotalTimeTooltip(formattedTotalTime);
      setBreakTime(newBreakTime);
    }
  }, [formattedDay]);

  useInterval(() => {
    const {
      currentTime,
      totalTime,
      breakTime: newBreakTime,
      formattedTotalTime,
      formattedCurrentTime,
    } = TaskHelpers.getClockTimes({ formattedDay });
    if (clockTime) setClockTime(currentTime);
    if (totalTime) setTotalClock(totalTime);
    if (formattedCurrentTime?.length) setClockTimeTooltip(formattedCurrentTime);
    if (formattedTotalTime?.length) setTotalTimeTooltip(formattedTotalTime);
    setBreakTime(newBreakTime);
  }, tickInterval);

  const settings = useSelector((state) => state.settings.company.settings);
  const { enableClocking } = settings || {};

  return (
    <Row
      type="flex"
      align="middle"
      justify="center"
      style={{
        width: '100%',
        height: '100%',
        paddingBottom: 32,
      }}
    >
      {
        totalClockTime && (
        <Row style={{ width: '100%' }} justify="center" type="flex">
          <Tooltip title={totalTimeTooltip}>
            <Typography.Text style={{
              fontFamily: 'raleway-semibold',
              fontSize: 16,
              color: colors.ONTRACCR_OPACITY_GRAY,
            }}
            >
              {totalClockTime}
            </Typography.Text>
          </Tooltip>
        </Row>
        )
      }
      <Row style={{ width: '100%' }} justify="center" type="flex">
        <Tooltip title={clockTimeTooltip} placement="bottom">
          <Typography.Text style={{
            fontFamily: 'raleway-semibold',
            fontSize: 48,
            lineHeight: '48px',
            marginBottom: 20,
            color: getClockColor(formattedDay),
          }}
          >
            {clockTime}
          </Typography.Text>
        </Tooltip>
      </Row>
      {showButtons && (
      <Row type="flex" justify="center" gutter={16} style={{ width: '100%', marginBottom: 15 }}>
        <Col>
          {getClockText(clockInText, switchStarted)}
          {enableClocking && onClockIn && (
          <OnTraccrButton
            title="Clock In"
            disabled={
              running
              || !canClock
              || isOnBreak
              || switchStarted
              || !!buttonLoading
              || shouldWorkingHoursBlockClockIn
              || shouldRoundedTaskBlockClockIn
            }
            onClick={async () => {
              setButtonLoading(CLOCKIN_KEY);
              if (await onClockIn()) {
                const {
                  firstSameDayTaskKey,
                  currentTask,
                } = formattedDay;
                const ourTask = firstSameDayTaskKey || currentTask;
                if (!ourTask) return;
                const {
                  startTime,
                } = ourTask;
                setRunning(true);
                setClockInText(moment(startTime).format('h:mm A'));
              }
              setButtonLoading();
            }}
            type={running || isOnBreak ? 'back' : 'primary'}
            style={getButtonStyle(switchStarted ? {
              opacity: 0.25,
            } : {})}
            titleStyle={titleStyle}
            loading={buttonLoading === CLOCKIN_KEY}
          />
          )}
          {
            shouldWorkingHoursBlockClockIn
              && (
                <div style={{ fontSize: 10, width: 120, textAlign: 'center' }}>
                  Can&lsquo;t clock in outside of working hours
                </div>
              )
          }
          {
            shouldRoundedTaskBlockClockIn
              && (
                <div style={{ fontSize: 10, width: 120, textAlign: 'center' }}>
                  Can&lsquo;t clock in until rounded task ends at&nbsp;
                  {
                    DateTime
                      .fromMillis(todayRoundedTask.endTime)
                      .toLocaleString(DateTime.DATETIME_MED)
                  }
                </div>
              )
          }
        </Col>
        <Col>
          {getClockText(clockOutText)}
          {enableClocking && onClockOut && (
          <OnTraccrButton
            title="Clock Out"
            disabled={(!running && !isOnBreak) || switchStarted || !!buttonLoading}
            onClick={async () => {
              setButtonLoading(CLOCKOUT_KEY);
              if (await onClockOut(taskId, timeEntryUserMap[user?.id] ?? [])) { // reference
                setRunning(false);
                setClockOutText(moment().local().format('h:mm A'));
              }
              setButtonLoading();
            }}
            type={running || isOnBreak ? 'primary' : 'back'}
            style={getButtonStyle(switchStarted ? {
              opacity: 0.25,
            } : {})}
            titleStyle={titleStyle}
            loading={buttonLoading === CLOCKOUT_KEY}
          />
          )}
        </Col>
      </Row>
      )}
      {showButtons && (
        <ClockButtons
          onManualEntry={onManualEntry}
          running={running}
          isOnBreak={isOnBreak}
          switchStarted={switchStarted}
          breakStart={breakStart}
          breakTime={breakTime}
          onBreak={canBreak ? onBreakHelper : null}
          onSwitchCancel={onSwitchCancelHelper}
          onSwitch={canSwitch ? onSwitchHelper : null}
        />
      )}
    </Row>
  );
}

ClockInTimer.propTypes = {
  onManualEntry: PropTypes.func,
  onClockIn: PropTypes.func,
  onClockOut: PropTypes.func,
  user: PropTypes.shape({
    id: PropTypes.string,
  }),
  onBreakStart: PropTypes.func,
  onBreakEnd: PropTypes.func,
  externalSwitchHandling: PropTypes.bool,
  showButtons: PropTypes.bool,
  onSwitch: PropTypes.func,
  onSwitchStart: PropTypes.func,
  onSwitchCancel: PropTypes.func,
  canClock: PropTypes.bool,
};

ClockInTimer.defaultProps = {
  onManualEntry: null,
  onClockIn: null,
  onClockOut: null,
  user: {},
  onBreakStart: null,
  onBreakEnd: null,
  externalSwitchHandling: false,
  showButtons: true,
  onSwitch: null,
  onSwitchStart: null,
  onSwitchCancel: null,
  canClock: false,
};

export default ClockInTimer;
