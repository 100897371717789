import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Row, Col, Typography, Popover } from 'antd';
import { MenuOutlined, MenuFoldOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import LiveFeedEntry from './LiveFeedEntry';
import LiveFeedTextInput from './LiveFeedTextInput';
import PostDrawer from './PostDrawer';
import LiveFeedFileUpload from './LiveFeedFileUpload';
import { filterEvents, getRelevantEvents, parseEvents } from './liveFeedHelpers';
import { defaultFilters, filterData } from './LiveFeedFilters';
import FilterDropdown from '../../common/FilterDropdown';
import LiveFeedMenu from './menu/LiveFeedMenu';

import { cropName, getIdMap } from '../../helpers/helpers';
import Analytics from '../../helpers/Analytics';

import BorderedComponent from '../../common/containers/BorderedContainer';
import Badge from '../../common/badge';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FullPhoto from '../../common/photos/FullPhoto';

import DashboardWidgetDeleteButton from '../DashboardWidgetDeleteButton';

import OnTraccrEmpty from '../../common/OnTraccrEmpty';

import colors from '../../constants/Colors';

import { getFileType, downloadFile } from '../../files/fileHelpers';

import {
  getChannels,
  getEvents,
  getPosts,
  getFileURLs,
  createPost,
} from './state/livefeed.actions';

import {
  createNuxEntry,
} from '../../nux/state/nux.actions';
import {
  LIVE_FEED_MESSAGE_TYPE,
} from '../../nux/nux.constants';
import { useTranslation } from 'react-i18next';

const collapsibleMenuIconStyle = {
  color: colors.ONTRACCR_BLACK,
  width: 35,
  height: 35,
  paddingTop: 10,
  paddingRight: 10,
};

const getDefaultFilter = () => {
  const defaultFilterConfig = {
    status: new Set(defaultFilters.status),
    events: new Set(defaultFilters.events),
  };
  try {
    // Retrieve live feed filter config:
    const liveFeedStatusFilterConfig = window.localStorage.getItem('liveFeedStatusFilter');
    const liveFeedEventFilterConfig = window.localStorage.getItem('liveFeedEventFilter');

    // Live feed filter configurations does not exist in local storage:
    if (!liveFeedStatusFilterConfig && !liveFeedEventFilterConfig) return defaultFilterConfig;

    // Previous live feed filter configurations exists in local storage:
    const liveFeedStatusFilterArr = JSON.parse(liveFeedStatusFilterConfig);
    const liveFeedEventFilterArr = JSON.parse(liveFeedEventFilterConfig);

    return {
      status: new Set(liveFeedStatusFilterArr),
      events: new Set(liveFeedEventFilterArr),
    };
  } catch (error) {
    return defaultFilterConfig;
  }
};

export default connect(
  (state, ownProps) => {
    return {
      ...ownProps,
      events:state.livefeed.events,
      users:state.users.users,
      userDivisions: state.users.userDivisions,
      costcodes:state.costcodes.costcodes,
      projects:state.projects.projects,
      teams:state.teams.teams,
      posts:state.livefeed.posts,
      userTrie:state.users.userTrie,
      nux:state.nux.nux,
      selectedDivisions: state.settings.selectedDivisions,
      globalChannel: state.livefeed.globalChannel,
      projectChannels: state.livefeed.projectChannels,
      customChannels: state.livefeed.customChannels,
      timeEntryUserMap: state.timeTracking.timeEntryUserMap,
    };
  }, {
    getChannels,
    getEvents,
    getPosts,
    getFileURLs,
    createPost,
    createNuxEntry,
  },
)(({
  events = [],
  users = [],
  costcodes = [],
  projects = [],
  teams = [],
  timeEntryUserMap = {},
  getChannels,
  getEvents,
  getPosts,
  getFileURLs,
  createPost,
  history,
  createNuxEntry,
  selectedDivisions,
  nux = new Set(),
  posts = {},
  userTrie = {},
  userDivisions = {},
  isEdit,
  onDelete,
  globalChannel = {},
  projectChannels = {},
  customChannels = {},
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState([]);
  const [lastLogin, setLastLogin] = useState(0);
  const [selectedPost, setSelectedPost] = useState();
  const [uploadStarted, setUploadStarted] = useState(false);
  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});
  const [currIndex, setCurrIndex] = useState();
  const [currChannel, setCurrChannel] = useState({});
  const [menuCollapsed, setMenuCollapsed] = useState(true);
  const [activeFilters, setActiveFilters] = useState(getDefaultFilter());
  const toggleMenuCollapse = useCallback(() => setMenuCollapsed((prvCollapse) => !prvCollapse), []);
  const onFilterChange = useCallback((checkedFilters, filterCategory) => {
    try {
      const newFilters = checkedFilters.filter((filterName) => filterName !== filterCategory);
      // Save live-feed configuration to local state:
      setActiveFilters((prvState) => ({
        ...prvState,
        [filterCategory]: new Set(newFilters),
      }));
      // Save live-feed filter configuration to local storage:
      const localStorageFilterCategoryKey = filterCategory === 'status' ? 'liveFeedStatusFilter' : 'liveFeedEventFilter';
      window.localStorage.setItem(localStorageFilterCategoryKey, JSON.stringify(newFilters));
    } catch (error) {
      // pass
    }
  }, []);

  const onSelectedChannelChange = useCallback((newChannel) => {
    try {
      // Save live-feed channel configuration to local state:
      setCurrChannel(newChannel);
      // Save live-feed channel configuration to local storage:
      const channelDetails = JSON.stringify(newChannel);
      window.localStorage.setItem('liveFeedCurrentChannel', channelDetails);
    } catch (error) {
      // pass
    }
  }, []);

  const clearSelectedFile = useCallback(() => {
    setSelectedFile({});
    setCurrIndex();
  }, []);
  const onPreviewClick = useCallback((index) => {
    const ourFile = files[index];
    if (ourFile) {
      setCurrIndex(index);
      if (ourFile instanceof File) {
        setSelectedFile({
          file: ourFile,
          type: getFileType(ourFile),
        });
      } else {
        setSelectedFile({
          ...ourFile,
          type: getFileType(ourFile),
        });
      }
    }
  }, [files]);

  const onNextFileClick = useCallback((isRight) => () => {
    const newIndex = isRight ? currIndex + 1 : currIndex - 1;
    const isInvalid = isRight ? newIndex >= files.length : newIndex < 0;
    if (isInvalid) return;
    const ourFile = files[newIndex] || {};
    setSelectedFile({
      file: ourFile,
      type: getFileType(ourFile),
    });
    setCurrIndex(newIndex);
  }, [currIndex, files]);

  const onPostClick = (post) => {
    setSelectedPost(post);
    const {
      text = '',
      tags = [],
      imageURLs = [],
    } = post;
    Analytics.track('Post/Open', {
      textLength: text ? text.length : 0,
      tags: tags.length,
      files: imageURLs.length,
    });
  };
  const addFile = (file) => {
    setFiles([...files, file]);
    if (!nux.has(LIVE_FEED_MESSAGE_TYPE)) {
      createNuxEntry(LIVE_FEED_MESSAGE_TYPE);
    }
  };
  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };
  const updateFile = useCallback((oldFile, updatedFile) => {
    if (!updatedFile) return clearSelectedFile();
    const newFiles = files.map((file) => {
      if (file === oldFile) return updatedFile;
      return file;
    });
    setFiles(newFiles);
    clearSelectedFile();
  }, [files, clearSelectedFile]);

  const clearFiles = () => setFiles([]);
  const clearSelectedPost = () => setSelectedPost();
  const toggleUpload = () => setUploadStarted(!uploadStarted);
  const endUpload = () => setUploadStarted(false);

  const translatedFilterData = filterData(t);

  const filterDropdown = useMemo(() => (
    <FilterDropdown
      filters={translatedFilterData}
      activeFilters={activeFilters}
      onFilter={onFilterChange}
    />
  ), [filterData, t]);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const ccMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const teamMap = useMemo(() => getIdMap(teams), [teams]);
  const channelMap = useMemo(() => {
    const { id: globalChannelId } = globalChannel;
    return {
      [globalChannelId]: globalChannel,
      ...projectChannels,
      ...customChannels,
    };
  }, [projectChannels, customChannels, globalChannel]);

  const liveFeedTitle = useMemo(() => {
    const { key: currChannelId, type: currChannelType } = currChannel;
    const { name: currChannelName = '', projectName = '', projectNumber } = channelMap[currChannelId] ?? {};
    const defaultChannelName = 'Live Feed';
    switch (currChannelType) {
      case 'global':
        return 'Global';
      case 'project': {
        const title = `${projectName} - ${projectNumber}`;
        const croppedName = cropName(title, 27);
        return projectName ? `${t('Project')}: ${croppedName}` : defaultChannelName;
      }
      case 'custom':
        return currChannelName ? `Custom: ${cropName(currChannelName, 27)}` : defaultChannelName;
      default:
        return defaultChannelName;
    }
  }, [currChannel, channelMap]);

  useEffect(() => {
    if (getChannels) getChannels();
    if (getPosts) getPosts();
    if (getEvents) getEvents();
    try {
      const lastActive = parseInt(window.sessionStorage.getItem('lastActive'), 10);
      setLastLogin(lastActive);
    } catch (err) {
      // pass
    }
    return () => window.sessionStorage.setItem('lastActive', DateTime.local().toMillis())
  }, [getChannels, getEvents, getPosts]);

  useEffect(() => {
    if (ccMap && userMap && projectMap && teamMap) {
      const { type: currChannelType, key: currChannelId } = currChannel || {};
      const channelPosts = posts[currChannelId] ?? {};
      if (events.length === 0 && channelPosts.length === 0) return;
      const channelEvents = getRelevantEvents({
        channelType: currChannelType,
        channelId: currChannelId,
        projectChannels,
        events,
      });
      const parsedEvents = parseEvents({
        events: channelEvents,
        posts: channelPosts,
        userMap,
        userDivisions,
        ccMap,
        projectMap,
        teamMap,
        selectedDivisions,
        lastLogin,
        history,
        timeEntryUserMap,
        onPostClick,
        t,
      });
      const filteredParsedEvents = filterEvents({
        activeFilters,
        events: parsedEvents,
      });
      setData(filteredParsedEvents);
    }
  }, [
    events,
    posts,
    userMap,
    ccMap,
    projectMap,
    teamMap,
    lastLogin,
    history,
    activeFilters,
    selectedDivisions,
    userDivisions,
    currChannel,
    projectChannels,
    timeEntryUserMap,
  ]);

  useEffect(() => {
    const channelPosts = posts[currChannel?.key] ?? {};
    let files = [];
    const postValues = Object.values(channelPosts);
    if (postValues.length) {
      postValues.forEach((post) => {
        if (post.files) files = files.concat(post.files);
      });
    }
    if (files.length) {
      getFileURLs(files.map((file) => ({ id: file.id, path: file.path, name: file.name })));
    }
  }, [posts, currChannel, getFileURLs]);

  const unreadCount = data.filter((event) => event && event.unread).length;
  const containerStyle = isEdit ? { filter: 'blur(1px)', pointerEvents: 'none' } : {};

  return (
    <BorderedComponent
      style={{
        padding: 0,
        overflow: 'hidden',
        position: 'relative',
        ...isEdit
          ? { borderStyle: 'dashed' }
          : {},
      }}
    >
      <Row
        style={{
          width: '100%',
          height: 60,
          backgroundColor: colors.opacity(colors.LIGHT_GRAY, 0.5),
          padding: '10px 20px',
          borderTopLeftRadius: 8,
          borderTopRightRadius: 8,
          borderBottomColor: colors.ONTRACCR_GRAY,
          borderBottomWidth: 1,
          borderBottomStyle: 'solid',
          ...containerStyle,
        }}
        justify="space-between"
        type="flex"
        align="middle"
      >
        <Col>
          <Row align="middle">
            <Col>
              {menuCollapsed
                ? (
                  <MenuOutlined
                    style={collapsibleMenuIconStyle}
                    onClick={toggleMenuCollapse}
                  />
                ) : (
                  <MenuFoldOutlined
                    style={collapsibleMenuIconStyle}
                    onClick={toggleMenuCollapse}
                  />
                )}
            </Col>
            <Col>
              <Typography.Text
                ellipsis
                style={{
                  fontFamily: 'roboto-medium',
                  color: colors.ONTRACCR_BLACK,
                }}
              >
                {liveFeedTitle}
              </Typography.Text>
            </Col>
            <Col>
              <Row align="middle">
                {unreadCount > 0 && (
                  <Badge
                    count={unreadCount}
                    style={{
                      lineHeight: '16px',
                    }}
                  />
                )}
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Popover trigger="click" placement="bottomLeft" content={filterDropdown}>
            <OnTraccrButton title="Filters" type="primary" />
          </Popover>
        </Col>
      </Row>
      <div
        style={{
          height: 'calc(100% - 60px)',
          overflowY: 'scroll',
          paddingBottom: 100,
          filter: uploadStarted ? 'blur(3px)' : 'none',
          ...containerStyle,
        }}
      >
        {data.length > 0 ? (
          data.map((item, index) => (
            item
              ? (
                <LiveFeedEntry key={index} {...item}>
                  {item.child(item.childProps)}
                </LiveFeedEntry>
              ) : null
          ))
        ) : <OnTraccrEmpty />}

      </div>
      {
        isEdit && <DashboardWidgetDeleteButton onDelete={onDelete}/>
      }
      <LiveFeedFileUpload
        shouldDownload
        onUploadEnd={toggleUpload}
        addFile={addFile}
        visible={uploadStarted}
        customProps={{ getContainer: false }}
      />
      <LiveFeedTextInput
        history={history}
        userTrie={userTrie}
        users={users}
        onSend={createPost}
        currChannelId={currChannel?.key}
        files={files}
        onUpload={toggleUpload}
        onUploadEnd={endUpload}
        clearFiles={clearFiles}
        uploading={uploadStarted}
        onFileRemove={removeFile}
        onPreviewClick={onPreviewClick}
        style={containerStyle}
        allowUpload
      />
      <PostDrawer post={selectedPost} onClose={clearSelectedPost} />
      {
        createPortal(
          <FullPhoto
            {...selectedFile}
            file={selectedFile.file}
            type={selectedFile.type}
            onClose={clearSelectedFile}
            onDownload={() => {
              if (selectedFile && selectedFile.fullPath) {
                downloadFile({ fileDetails: selectedFile });
              } else if (selectedFile.file) {
                downloadFile({ fileObject: selectedFile.file });
              }
            }}
            onSave={updateFile}
            showLeft={selectedFile && currIndex > 0}
            showRight={selectedFile && currIndex < (files.length - 1)}
            onLeft={onNextFileClick(false)}
            onRight={onNextFileClick(true)}
            useApryse
          />,
          document.body,
        )
      }
      <LiveFeedMenu
        visible={!menuCollapsed}
        selectedChannelId={currChannel?.key}
        onSelectedChannelChange={onSelectedChannelChange}
        onClose={toggleMenuCollapse}
      />
    </BorderedComponent>
  );
});
