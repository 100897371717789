import React, { useCallback, useMemo } from 'react';
import {
  Row,
  Col,
  Switch,
  Divider
} from 'antd';

import CompanySettingsCard from '../CompanySettingsCard';

import AutomaticOvertime from './AutomaticOvertime';
import ManualOvertime from './ManualOvertime';

import colors from '../../constants/Colors';

export default ({ settings, saveCompanySettings }) => {
  const {
    enableManualOT,
  } = settings;
  const onSwitch = useCallback(() => {
    saveCompanySettings({
      enableManualOT: !enableManualOT
    })
  },[enableManualOT]);

  const body = useMemo(() => (
    enableManualOT
    ? <ManualOvertime settings={settings} saveCompanySettings={saveCompanySettings}/>
    : <AutomaticOvertime settings={settings} saveCompanySettings={saveCompanySettings}/>
  ),[enableManualOT, settings, saveCompanySettings]);

  return (
    <div style={{ height:'90vh', overflow:'scroll', width:'100%', paddingBottom: 50 }}>
      <CompanySettingsCard title='Overtime'>
        <Row style={{ margin: '20px 32px 20px 0px' }} justify='space-between'>
          <Switch checked={enableManualOT} onChange={onSwitch}/>
          <Col>
            {enableManualOT ? 'Manual Overtime' : 'Automatic Overtime'}
          </Col>
        </Row>
        <Divider style={{
          backgroundColor: colors.ONTRACCR_OPACITY_GRAY,
          maxWidth:440,
          minWidth:440,
        }}/>
        {body}
      </CompanySettingsCard>
    </div>
);
}