import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import MaterialUpdateEntry from './MaterialUpdateEntry';

import { getIdMap } from '../helpers/helpers';

import { getMaterialUpdateHistory } from './state/materials.actions';

export default ({
  materialId,
  name,
}) => {
  const dispatch = useDispatch();
  const stateUpdateHistory = useSelector(state => state.materials.updateHistory);
  const users = useSelector(state => state.users.users);

  const userMap = useMemo(() => getIdMap(users),[users]);

  const updateHistory = useMemo(() => {
    const { [materialId]: ourHistory = [] } = stateUpdateHistory;
    return ourHistory.map((history) => {
      const { userId } = history;
      if (!userId) return history;
      const { [userId]: user } = userMap;
      return { ...history, user };
    });
  }, [stateUpdateHistory, materialId, userMap]);

  useEffect(() => {
    if (materialId) dispatch(getMaterialUpdateHistory(materialId));
  }, [dispatch, materialId])
  return (
    <div className='material-history-container'>
      {updateHistory.map((history = {}) => (
        <MaterialUpdateEntry {...history} key={history.id} name={name}/>
      ))}
    </div>
  )
};
