export const STATUS_MAP = {
  'To Do': 0,
  'In Progress': 1,
  Done: 2,
};

export const PRIORITY_MAP = {
  Lowest: 0,
  Low: 1,
  Medium: 2,
  High: 3,
  Highest: 4,
};

export const STATUS_FILTERS = [
  { text: 'To Do', value: 'To Do' },
  { text: 'In Progress', value: 'In Progress' },
  { text: 'Done', value: 'Done' },
];

export const DEFAULT_STATUS_FILTERS = ['To Do', 'In Progress'];

export const PRIORITY_FILTERS = [
  { text: 'Lowest', value: 'Lowest' },
  { text: 'Low', value: 'Low' },
  { text: 'Medium', value: 'Medium' },
  { text: 'High', value: 'High' },
  { text: 'Highest', value: 'Highest' },
];
