import React, { useState, useCallback, useRef } from 'react';

import ShiftBlock from '../ShiftBlock';

import { ITEM_GAP, DAILY_LEFT_OFFSET } from '../schedule.constants';

import Permissions from '../../auth/Permissions';

export default ({
  onClick,
  onShiftSelect,
  onShiftEdit,
  shifts = [],
  style = {},
}) => {
  const containerRef = useRef(null);

  const parentWidth  = (containerRef && containerRef.current)
    ? containerRef.current.clientWidth - DAILY_LEFT_OFFSET
    : 0;
  const totalGap = shifts.length > 1 ? (shifts.length - 1) * ITEM_GAP : 0;
  const size = (parentWidth - totalGap) /shifts.length;
  const [hovering,setHovering] = useState(false);
  const startHover = useCallback(() => setHovering(true),[]);
  const endHover = useCallback(() => setHovering(false),[]);
  return (
    <div
      className='all-day-header'
      onClick={onClick}
      onMouseEnter={startHover}
      onMouseLeave={endHover}
      ref={containerRef}
      style={style}
    >
      <div
        className='all-day-header-text'
        style={hovering ? { fontWeight: 600 } : {}}
      >All Day</div>
      <div className='all-day-header-container'>
      {
        shifts.map((shift, idx) => (
          <ShiftBlock
            key={shift.id}
            shift={shift}
            onClick={(e) => {
              e.stopPropagation();
              onShiftSelect(shift, Permissions.has('SCHEDULE_WRITE'));
              onShiftEdit(shift);
            }}
            style={{
              cursor: 'pointer',
              width: size,
              left: size * idx + (ITEM_GAP * (idx - 1)),
              height: 20,
              top: 2.5,
              bottom: 2.5
            }}
            allDay
          />
        ))
      }
      </div>
    </div>
  );
}