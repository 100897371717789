import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import DashboardComponent from '../dashboard/DashboardComponent';
import SideList from '../common/SideList';
import Timer from '../common/clock/Timer';
import OnTraccrEmpty from '../common/OnTraccrEmpty';

import Permissions from '../auth/Permissions';

import colors from '../constants/Colors';

import { getIdMap } from '../helpers/helpers';
import { getActiveMember as activeMemberHelper } from '../helpers/users';

const hasPerms = ({ id, position } = {}) => {
  if (id === Permissions.id) return Permissions.has('TIME_TRACKING_SELF');
  return Permissions.has(`TIME_TRACKING_${Permissions.formatPosition(position)}`);
};

const getLastTask = (tasks = [{}]) => tasks[tasks.length - 1] || {};

const getActiveMember = (timeEntryUserMap) => (
  (user) => activeMemberHelper({ user, timeEntryUserMap })
);
const getInactiveMember = (timeEntryUserMap) => (
  (user) => !activeMemberHelper({ user, timeEntryUserMap })
);

const getColourByStatus = (status) => {
  switch (status) {
    case 'Clocked In': return colors.ONTRACCR_BLACK;
    case 'On Break': return colors.ONTRACCR_DARK_YELLOW;
    default: return colors.ONTRACCR_OPACITY_GRAY;
  }
};

const renderActive = ({
  name,
  tasks = [{}],
  onClick,
  id,
  status,
}) => {
  const {
    startTime,
    endTime,
    id: taskId,
  } = getLastTask(tasks);

  return (
    <Row
      style={{
        width: '100%',
        cursor: onClick ? 'pointer' : 'default',
      }}
      onClick={onClick}
    >
      <Col
        span={16}
        style={{
          fontFamily: 'roboto-regular',
          color: colors.ONTRACCR_BLACK,
          fontSize: 14,
        }}
      >
        {name}
      </Col>
      <Col
        span={8}
        style={{
          pointerEvents: 'none',
        }}
      >
        <Timer
          key={id}
          startTime={startTime}
          endTime={endTime}
          id={taskId}
          style={{
            fontFamily: 'roboto-regular',
            fontSize: 14,
            pointerEvents: 'none',
            color: getColourByStatus(status),
          }}
        />
      </Col>
    </Row>
  );
};

const renderInactive = ({
  name,
  onClick,
}) => (
  <Row
    onClick={onClick}
    style={{
      width: '100%',
    }}
  >
    <Col
      span={24}
      style={{
        fontFamily: 'roboto-regular',
        color: colors.ONTRACCR_BLACK,
        fontSize: 14,
        cursor: onClick ? 'pointer' : 'default',
      }}
    >
      {name}
    </Col>
  </Row>
);

function TeamDetail({
  team,
  onEdit,
  onUserClick,
}) {
  const {
    name,
    members = [],
    id,
    projects: teamProjects = [],
    divisionIds = [],
    equipment: teamEquipment = [],
  } = team ?? {};
  const { t } = useTranslation();
  const divisions = useSelector((state) => state.settings.divisions);
  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const equipment = useSelector((state) => state.equipment.equipment);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

  const userMap = useMemo(() => getIdMap(users), [users]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);

  const projectText = useMemo(() => {
    if (!teamProjects?.length) return 'None';
    return teamProjects.map(({ id: projectId }) => projectMap[projectId]?.name ?? 'Unknown')
      .filter((projectName) => projectName).join(', ');
  }, [projectMap, teamProjects]);
  const decoratedMembers = useMemo(() => (
    members.map((m) => ({
      ...(userMap[m.id] ?? {}),
      ...m,
    }))
  ), [members, userMap]);

  const equipmentText = useMemo(() => {
    if (!teamEquipment?.length) return 'None';
    return teamEquipment.map((eqId) => equipmentMap[eqId]?.name ?? 'Unknown')
      .filter((eqName) => eqName).join(', ');
  }, [equipmentMap, teamEquipment]);

  const supervisors = useMemo(() => (
    decoratedMembers.filter((user) => user.teamRole === 'Supervisor')
  ), [decoratedMembers]);

  const divisionsText = useMemo(() => (
    divisionIds.map((dId) => divisions[dId]?.name ?? 'Unknown').join(', ') ?? 'Unknown'
  ), [divisions, divisionIds]);

  if (!team) return <OnTraccrEmpty />;
  return (
    <DashboardComponent
      style={{
        padding: 20,
      }}
      title={name ?? 'Team'}
      titleStyle={{
        fontSize: 22,
        fontFamily: 'raleway-semibold',
      }}
      subtitle={`${members.length} Member${members.length === 1 ? '' : 's'}`}
      subtitleStyle={{
        fontSize: 20,
        fontFamily: 'raleway-semibold',
      }}
      onEdit={id && Permissions.has('TEAMS_WRITE') ? onEdit : null}
    >
      <Row style={{ marginTop: 40, paddingLeft: 10, width: '100%' }} type="flex" align="top">
        <Row style={{
          marginBottom: 10, fontSize: 16, fontFamily: 'roboto-regular', width: '100%',
        }}
        >
          {divisionsText ? `Divisions: ${divisionsText}` : ''}
        </Row>
        <Row style={{
          marginBottom: 10, fontSize: 16, fontFamily: 'roboto-regular', width: '100%',
        }}
        >
          {`Supervisors: ${supervisors.map((member) => member.name)}`}
        </Row>
        <Row
          style={{
            marginBottom: 10,
            fontSize: 16,
            fontFamily: 'roboto-regular',
            width: '100%',
          }}
        >
          {`${t('Project', { count: projects.length })}: ${projectText}`}
        </Row>
        <Row style={{ fontSize: 16, fontFamily: 'roboto-regular' }}>
          {`Equipment: ${equipmentText}`}
        </Row>
      </Row>

      <Row
        gutter={24}
        style={{
          marginTop: 30,
          paddingLeft: 10,
          paddingRight: 10,
          height: '60%',
          width: '100%',
        }}
      >
        <Col span={12} style={{ height: '100%' }}>
          <SideList
            id={`${id}-activeTeam`}
            key={`${id}-activeTeam`}
            rowHeight={21}
            scrollable
            footer={false}
            header={(
              <Row
                style={{
                  fontFamily: 'raleway-medium',
                  fontSize: 18,
                }}
                type="flex"
                align="middle"
                justify="center"
              >
                Active Today
              </Row>
          )}
            headerStyle={{
              backgroundColor: colors.ONTRACCR_BLACK,
              color: 'white',
              height: 50,
              borderTopLeftRadius: 9,
              borderTopRightRadius: 9,
            }}
            dataSource={decoratedMembers.filter(getActiveMember(timeEntryUserMap))}
            renderItem={(user) => renderActive({
              ...user,
              onClick: hasPerms(user)
                ? () => onUserClick(user)
                : null,
            })}
          />
        </Col>
        <Col span={12} style={{ height: '100%' }}>
          <SideList
            id={`${id}-inactiveTeam`}
            key={`${id}-inactiveTeam`}
            rowHeight={46}
            scrollable
            footer={false}
            header={(
              <Row
                style={{
                  fontFamily: 'raleway-medium',
                  fontSize: 18,
                }}
                type="flex"
                align="middle"
                justify="center"
              >
                Inactive Today
              </Row>
          )}
            headerStyle={{
              backgroundColor: colors.ONTRACCR_OPACITY_GRAY,
              color: 'white',
              height: 50,
              borderTopLeftRadius: 9,
              borderTopRightRadius: 9,
            }}
            dataSource={decoratedMembers.filter(getInactiveMember(timeEntryUserMap))}
            renderItem={(user) => renderInactive({
              ...user,
              onClick: hasPerms(user)
                ? () => onUserClick(user)
                : null,
            })}
          />
        </Col>

      </Row>
    </DashboardComponent>
  );
}

/* eslint-disable react/forbid-prop-types */
TeamDetail.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.array,
    id: PropTypes.string,
    projects: PropTypes.array,
    divisionId: PropTypes.string,
  }),
  onEdit: PropTypes.func.isRequired,
  onUserClick: PropTypes.func.isRequired,
};

TeamDetail.defaultProps = {
  team: null,
};

export default TeamDetail;
