import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardAnalyticsContainer from '../../common/dashboardAnalytics/DashboardAnalyticsContainer';
import { BOARD_CARD_ANALYTICS_TYPE } from '../../analytics/analytics.constants';

function BoardCardAnalytics({
  cardId,
  cardTypeId,
  boardId,
}) {
  const { dashboard: dashboardConfig } = useSelector((state) => state.boardAnalytics);
  const { reports: analyticsConfig } = useSelector((state) => state.boardAnalytics);

  return (
    <DashboardAnalyticsContainer
      type={BOARD_CARD_ANALYTICS_TYPE}
      dashboardConfig={dashboardConfig}
      analyticsConfig={analyticsConfig}
      cardId={cardId}
      cardTypeId={cardTypeId}
      boardId={boardId}
    />
  );
}

BoardCardAnalytics.propTypes = {
  cardId: PropTypes.string.isRequired,
  cardTypeId: PropTypes.number.isRequired,
  boardId: PropTypes.string.isRequired,
};

export default BoardCardAnalytics;
