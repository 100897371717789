import React, { useState, useCallback } from 'react';
import { Checkbox } from 'antd';

import ProjectAddNewEquipmentDrawer from './ProjectAddNewEquipmentDrawer';
import ProjectAddExistingEquipmentDrawer from './ProjectAddExistingEquipmentDrawer';
import ProjectEquipmentList from './ProjectEquipmentList';
import { attachCustomDataToEquipment } from '../../equipment/Equipment';

export default ({
  isNotDisplay,
  divisionId,
  equipment: chosen = [],
  onCheckChanged,
  showEquipment,
  onAdd,
  onAddExisting: propAddExisting,
  onRemove,
  projectId,
  equipmentSchedule,
  onEquipmentDateChanged,
  onEquipmentDateRemoved,
}) =>  {
  const [visible, setVisible] = useState(false);
  const [showGlobal,setShowGlobal] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState();
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const onAddNew = useCallback(() => setVisible(true),[]);
  const onAddExisting = useCallback(() => setShowGlobal(true),[]);
  const onHideExisting = useCallback(() => setShowGlobal(false),[]);

  const onCloseNew = useCallback((equipment) => {
    if(equipment) {
      const formattedEquipment = attachCustomDataToEquipment({
        equipment,
        customFields,
      });

      if (formattedEquipment.error) {
        setErrors(formattedEquipment.errorMap);
        return;
      }

      onAdd(formattedEquipment);
    }

    setErrors({});
    setCustomFields([]);
    setVisible(false);
    setSelectedEquipment();
  },[onAdd, customFields]);

  const onSubmitExisting = useCallback((equipment) => {
    propAddExisting(equipment);
    setShowGlobal(false);
  },[propAddExisting]);

  const onRowClick = useCallback((record) => {
    if (isNotDisplay) return;
    setSelectedEquipment(record);
    setVisible(true);
  },[]);

  return (
  <div className='project-add-costcode-container'>
    {isNotDisplay && <Checkbox
      onChange={onCheckChanged}
      style={{ marginBottom:10 }}
      checked={showEquipment}
    >
      Add Equipment?
    </Checkbox>}
    {(showEquipment || !isNotDisplay) &&
      <div>
        <ProjectEquipmentList
          title='Equipment'
          isNotDisplay={isNotDisplay}
          onAddNew={onAddNew}
          onAddExisting={onAddExisting}
          onRemove={onRemove}
          collapsible={false}
          chosen={chosen}
          projectId={projectId}
          equipmentSchedule={equipmentSchedule}
          onEquipmentDateChanged={onEquipmentDateChanged}
          onEquipmentDateRemoved={onEquipmentDateRemoved}
          onRowClick={onRowClick}
        />
      </div>
    }
    <ProjectAddNewEquipmentDrawer
      visible={visible}
      onClose={onCloseNew}
      divisionId={divisionId}
      chosen={chosen}
      selectedEquipment={selectedEquipment}
      errors={errors}
      customFields={customFields}
      setCustomFields={setCustomFields}
    />
    <ProjectAddExistingEquipmentDrawer
      visible={showGlobal}
      onClose={onHideExisting}
      onSubmit={onSubmitExisting}
      divisionId={divisionId}
      chosen={chosen}
    />
  </div>);
};
