import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';

import { PropTypes } from 'prop-types';
import {
  Button,
  Drawer, Form, Select, Table,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import { ADDRESS_BOOK_COLUMNS } from '../contactConstants';
import { massAddToLocalAddressBooks, massDeleteFromLocalAddressBooks } from '../state/contacts.actions';
import sortByString, { compareStringArrays, intersectLists } from '../../helpers/helpers';
import Permissions from '../../auth/Permissions';

export default function AddressBookShareSlider({
  visible,
  selectedContacts,
  handleSelectedChange,
  onClose,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks) ?? {};
  const customerMap = useSelector((state) => state.customers.customers) ?? {};
  const vendorMap = useSelector((state) => state.vendors.vendors) ?? {};

  const [isLoading, setIsLoading] = useState(false);

  const customerOptions = useMemo(() => (
    Object.values(customerMap).map(({
      name, id,
    }) => ({
      label: name,
      value: id,
    })).sort(sortByString('label'))
  ), [customerMap]);

  const vendorOptions = useMemo(() => (
    Object.values(vendorMap).map(({
      name, id,
    }) => ({
      label: name,
      value: id,
    })).sort(sortByString('label'))
  ), [vendorMap]);

  const {
    sharedCustomers = [],
    sharedVendors = [],
  } = useMemo(() => {
    if (!visible) return {};
    const customerLists = [];
    const vendorLists = [];

    let customerEmpty = false;
    let vendorEmpty = false;

    selectedContacts.some((addressBookEntryId) => {
      const {
        [addressBookEntryId]: {
          customers = [],
          vendors = [],
        } = {},
      } = globalAddressBooks;

      if (!customers.length) customerEmpty = true;
      if (!vendors.length) vendorEmpty = true;

      customerLists.push(customers);
      vendorLists.push(vendors);

      return customerEmpty && vendorEmpty;
    });

    const customerShared = customerEmpty ? [] : intersectLists(customerLists);
    const vendorShared = vendorEmpty ? [] : intersectLists(vendorLists);

    return {
      sharedCustomers: customerShared,
      sharedVendors: vendorShared,
    };
  }, [globalAddressBooks, selectedContacts, visible]);

  const columns = [
    ...ADDRESS_BOOK_COLUMNS,
    {
      dataIndex: 'delete',
      key: 'role',
      align: 'center',
      width: 10,
      render: (_, record) => (
        <Button
          type="text"
          onClick={() => {
            handleSelectedChange(record);
          }}
          icon={<DeleteOutlined />}
        />
      ),
    },
  ];

  const data = useMemo(() => selectedContacts
    .filter((entryId) => entryId in globalAddressBooks)
    .map((addressBookEntryId) => {
      const {
        [addressBookEntryId]: contact,
      } = globalAddressBooks;

      return contact;
    }), [selectedContacts, globalAddressBooks]);

  useEffect(() => {
    if (!selectedContacts.length) onClose();
  }, [selectedContacts]);

  useEffect(() => {
    if (visible && form) {
      form.setFieldsValue({
        customers: sharedCustomers,
        vendors: sharedVendors,
      });
    }
  }, [visible, sharedCustomers, sharedVendors, form]);

  const onSubmit = useCallback(async () => {
    setIsLoading(true);
    const {
      customers = [],
      vendors = [],
    } = form.getFieldsValue();

    const {
      added: addedCustomers,
      removed: removedCustomers,
    } = compareStringArrays(sharedCustomers, customers);

    const {
      added: addedVendors,
      removed: removedVendors,
    } = compareStringArrays(sharedVendors, vendors);

    if (removedCustomers.length || removedVendors.length) {
      await dispatch(massDeleteFromLocalAddressBooks(
        selectedContacts,
        removedCustomers,
        removedVendors,
      ));
    }
    if (addedCustomers.length || addedVendors.length) {
      await dispatch(massAddToLocalAddressBooks(
        selectedContacts,
        addedCustomers,
        addedVendors,
      ));
    }

    setIsLoading(false);

    onClose();
  }, [form, selectedContacts, sharedCustomers, sharedVendors]);

  return (
    <Drawer
      title={`Share ${selectedContacts.length} Contacts`}
      visible={visible}
      onClose={onClose}
      width={700}
    >

      <Table
        columns={columns}
        dataSource={data}
        pagination={false}
        scroll={{
          x: true,
          y: 'calc(100vh - 400px)',
        }}
        style={{
          marginBottom: '1em',
        }}
      />

      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
        initialValues={{
          customers: sharedCustomers,
          vendors: sharedVendors,
        }}
      >
        { Permissions.match('CUSTOMERS') && (
          <Form.Item label="Customers" name="customers">
            <Select options={customerOptions} mode="multiple" optionFilterProp="label" />
          </Form.Item>
        )}

        { Permissions.match('VENDORS') && (
          <Form.Item label="Vendors" name="vendors">
            <Select options={vendorOptions} mode="multiple" optionFilterProp="label" />
          </Form.Item>
        )}
      </Form>

      <DrawerSubmitFooter
        isLoading={isLoading}
        onClose={onClose}
        onSubmit={onSubmit}
        submitTitle="Share"
      />
    </Drawer>
  );
}

AddressBookShareSlider.propTypes = {
  visible: PropTypes.bool,
  selectedContacts: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func.isRequired,
  handleSelectedChange: PropTypes.func.isRequired,
};

AddressBookShareSlider.defaultProps = {
  visible: false,
  selectedContacts: [],
};
