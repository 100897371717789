import {
  Checkbox,
  PageHeader,
  Popover,
  Row,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { SettingOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { getCustomFields } from './state/timecards.actions';

const nameColumn = { label: 'Name', value: 'name' };
const dateColumn = { label: 'Date', value: 'day' };
const totalTimeColumn = { label: 'Total Time', value: 'totalTime' };
const summaryDateColumn = { label: 'Date', value: 'date' };
const violationColumn = { label: 'Violation', value: 'hasViolation' };
const rejectionColumn = { label: 'Rejection', value: 'hasRejection' };
const overallStartTimeColumn = { label: 'Overall Start Time', value: 'StartTime' };
const overallEndTimeColumn = { label: 'Overall End Time', value: 'EndTime' };
const startTimeColumn = { label: 'Regular Start Time', value: 'RegularStartTime' };
const endTimeColumn = { label: 'Regular End Time', value: 'RegularEndTime' };
const breakStartTimeColumn = { label: 'Break Start Time', value: 'BreakStartTime' };
const breakEndTimeColumn = { label: 'Break End Time', value: 'BreakEndTime' };
const otStartTimeColumn = { label: 'OT Start Time', value: 'OTStartTime' };
const otEndTimeColumn = { label: 'OT End Time', value: 'OTEndTime' };
const doubleOTStartTimeColumn = { label: 'DT OT Start Time', value: 'DoubleOTStartTime' };
const doubleOTEndTimeColumn = { label: 'DT OT End Time', value: 'DoubleOTEndTime' };
const durationColumn = { label: 'Duration', value: 'Duration' };
const typeColumn = { label: 'Type', value: 'Type' };
const projectColumn = (t) => ({ label: t('Project'), value: 'Project' });
const costcodeColumn = { label: 'Cost Code', value: 'CostCode' };
const phaseColumn = { label: 'Phase', value: 'Phase' };
const unionColumn = { label: 'Union', value: 'Union' };
const localClassColumn = { label: 'Local Class', value: 'LocalClass' };
const workClassColumn = { label: 'Work Class', value: 'WorkClass' };
const notesColumn = { label: 'Notes', value: 'Notes' };
const statusColumn = { label: 'Status', value: 'Status' };
const approvedByColumn = { label: 'Approved By', value: 'ApprovedBy' };
const enteredViaColumn = { label: 'Entered Via', value: 'enteredVia' };

const dayEntryDefaultColumns = [
  dateColumn,
  totalTimeColumn,
  overallStartTimeColumn,
  overallEndTimeColumn,
  startTimeColumn,
  endTimeColumn,
  breakStartTimeColumn,
  breakEndTimeColumn,
  otStartTimeColumn,
  otEndTimeColumn,
  doubleOTStartTimeColumn,
  doubleOTEndTimeColumn,
  violationColumn,
  rejectionColumn,
];

const getTimeCardEntryDefaultColumns = (t) => [
  startTimeColumn,
  endTimeColumn,
  breakStartTimeColumn,
  breakEndTimeColumn,
  otStartTimeColumn,
  otEndTimeColumn,
  doubleOTStartTimeColumn,
  doubleOTEndTimeColumn,
  durationColumn,
  typeColumn,
  projectColumn(t),
  costcodeColumn,
  phaseColumn,
  unionColumn,
  localClassColumn,
  workClassColumn,
  notesColumn,
  statusColumn,
  enteredViaColumn,
  approvedByColumn,
];

const getTimeCardSummaryDefaultColumns = (t) => [
  nameColumn,
  summaryDateColumn,
  startTimeColumn,
  endTimeColumn,
  breakStartTimeColumn,
  breakEndTimeColumn,
  otStartTimeColumn,
  otEndTimeColumn,
  doubleOTStartTimeColumn,
  doubleOTEndTimeColumn,
  durationColumn,
  typeColumn,
  projectColumn(t),
  phaseColumn,
  costcodeColumn,
  unionColumn,
  localClassColumn,
  workClassColumn,
  notesColumn,
  violationColumn,
  statusColumn,
  enteredViaColumn,
  approvedByColumn,
];

const validFieldTypes = new Set([
  'yes-no',
  'dropdown',
  'text',
  'dateRange',
  'dateTime',
  'attribute',
  'staticText',
  'table',
  'gpsLocation',
]);

export default function TimeCardsColumnChanger({ isSummary }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const timeCardEntryDefaultColumns = getTimeCardEntryDefaultColumns(t);

  const timeCardSummaryDefaultColumns = getTimeCardSummaryDefaultColumns(t);

  const [selectedSummaryColumns, setSelectedSummaryColumns] = useState(
    timeCardSummaryDefaultColumns.map((column) => column.value),
  );

  const [selectedDayEntryColumns, setSelectedDayEntryColumns] = useState(
    dayEntryDefaultColumns.map((column) => column.value),
  );
  const [selectedTimeCardEntryColumns, setSelectedTimeCardEntryColumns] = useState(
    timeCardEntryDefaultColumns.map((column) => column.value),
  );
  const [selectedCustomFieldColumns, setSelectedCustomFieldColumns] = useState();

  const customFields = useSelector((state) => state.timecards.customFields);

  useEffect(() => {
    dispatch(getCustomFields());
  }, []);

  useEffect(() => {
    const rawDayEntryColumns = window.localStorage.getItem('timeCardDayEntryColumns');
    const rawTimeCardEntryColumns = window.localStorage.getItem('timeCardEntryColumns');
    const rawCustomFieldColumns = window.localStorage.getItem('timeCardCustomFieldColumns');
    const rawSummaryColumns = window.localStorage.getItem('timeCardSummaryColumns');

    if (rawDayEntryColumns) {
      setSelectedDayEntryColumns(JSON.parse(rawDayEntryColumns));
    }

    if (rawTimeCardEntryColumns) {
      setSelectedTimeCardEntryColumns(JSON.parse(rawTimeCardEntryColumns));
    }

    if (rawCustomFieldColumns) {
      setSelectedCustomFieldColumns(JSON.parse(rawCustomFieldColumns));
    }

    if (rawSummaryColumns) {
      setSelectedSummaryColumns(JSON.parse(rawSummaryColumns));
    }
  }, []);

  const onDayEntryChange = (newChecked) => {
    setSelectedDayEntryColumns(newChecked);
    window.localStorage.setItem('timeCardDayEntryColumns', JSON.stringify(newChecked));
    window.dispatchEvent(new CustomEvent('localStorageSetItem', { detail: 'timeCardDayEntryColumns' }));
  };

  const onTimeCardEntryChange = (newChecked) => {
    setSelectedTimeCardEntryColumns(newChecked);
    window.localStorage.setItem('timeCardEntryColumns', JSON.stringify(newChecked));
    window.dispatchEvent(new CustomEvent('localStorageSetItem', { detail: 'timeCardEntryColumns' }));
  };

  const onCustomColumnChange = (newChecked) => {
    setSelectedCustomFieldColumns(newChecked);
    window.localStorage.setItem('timeCardCustomFieldColumns', JSON.stringify(newChecked));
    window.dispatchEvent(new CustomEvent('localStorageSetItem', { detail: 'timeCardCustomFieldColumns' }));
  };

  const onSummaryColumnChange = (newChecked) => {
    setSelectedSummaryColumns(newChecked);
    window.localStorage.setItem('timeCardSummaryColumns', JSON.stringify(newChecked));
    window.dispatchEvent(new CustomEvent('localStorageSetItem', { detail: 'timeCardSummaryColumns' }));
  };

  const customFieldColumns = useMemo(() => {
    const columns = [];

    Object.values(customFields).forEach((customField) => {
      customField?.fields?.forEach?.(({ hidden, fields }) => {
        if (hidden) return;
        fields?.forEach?.((subField) => {
          if (validFieldTypes.has(subField.selectedType)) {
            columns.push({
              label: subField.configProps.title,
              value: subField.id,
            });
          }
        });
      });
    });

    return columns;
  }, [customFields]);

  return (
    <Popover
      trigger="click"
      placement="bottomRight"
      content={(
        <div style={{ width: 250, maxHeight: 500, overflowY: 'auto' }}>
          {!isSummary && (
            <>
              <Row>
                <PageHeader
                  title="Day Entry Columns"
                  style={{
                    padding: 0,
                    marginBottom: 5,
                    fontFamily: 'raleway-medium',
                  }}
                />
              </Row>
              <Checkbox.Group
                className="timecard-column-changer-checkbox-group"
                options={dayEntryDefaultColumns}
                onChange={onDayEntryChange}
                value={selectedDayEntryColumns}
              />
              <br />
              <Row>
                <PageHeader
                  title="Time Card Entry Columns"
                  style={{
                    padding: 0,
                    marginBottom: 5,
                    fontFamily: 'raleway-medium',
                  }}
                />
              </Row>
              <Checkbox.Group
                className="timecard-column-changer-checkbox-group"
                options={timeCardEntryDefaultColumns}
                onChange={onTimeCardEntryChange}
                value={selectedTimeCardEntryColumns}
              />
            </>
          )}
          {isSummary && (
            <>
              <Row>
                <PageHeader
                  title="Summary View Columns"
                  style={{
                    padding: 0,
                    marginBottom: 5,
                    fontFamily: 'raleway-medium',
                  }}
                />
              </Row>
              <Checkbox.Group
                className="timecard-column-changer-checkbox-group"
                options={timeCardSummaryDefaultColumns}
                onChange={onSummaryColumnChange}
                value={selectedSummaryColumns}
              />
              <br />
            </>
          )}
          <Row>
            <PageHeader
              title="Custom Field Columns"
              style={{
                padding: 0,
                marginBottom: 5,
                fontFamily: 'raleway-medium',
              }}
            />
            <Checkbox.Group
              className="timecard-column-changer-checkbox-group"
              options={customFieldColumns}
              onChange={onCustomColumnChange}
              value={selectedCustomFieldColumns}
            />
          </Row>
        </div>
      )}
    >
      <OnTraccrButton
        title="Columns"
        icon={<SettingOutlined />}
        type="cancel"
      />
    </Popover>
  );
}

TimeCardsColumnChanger.propTypes = {
  isSummary: PropTypes.bool,
};

TimeCardsColumnChanger.defaultProps = {
  isSummary: false,
};
