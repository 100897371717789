import axios from 'axios';
import * as Sentry from '@sentry/react';

import {
  FIELDS_WITH_FILTERS,
  FIELDS_WITH_CONFIG,
  FIELDS_WITH_TEXT,
} from '../../settings/Exports/exports.constants';
import { request } from '../../helpers/requests';

export default {};

export const getDataKey = (col = {}) => {
  const { field, filterValue, id: colId } = col;
  if (!filterValue) return field;

  if (FIELDS_WITH_FILTERS.has(col.field) || FIELDS_WITH_CONFIG.has(col.field)) {
    return `${field}-${filterValue}`;
  }
  if (FIELDS_WITH_TEXT.has(col.field)) {
    return colId;
  }
  return field;
};

export const getTableFieldId = (columns) => (
  columns.reduce((acc, { field } = {}) => {
    if (!field) return acc;
    const fieldSplit = field.split('-');
    if (fieldSplit.length < 3) return acc;
    return fieldSplit.slice(0, 2).join('-');
  }, null)
);

export const updateFormNumbers = ({ data = [], formNumberMap = {} }) => {
  const newData = data.map(({ id, ...form }) => {
    const {
      [id]: number,
    } = formNumberMap;

    return {
      ...form,
      id,
      number,
    };
  });

  return newData;
};

export const getBatchNumber = async ({ exportId }) => {
  const { data: newBatchNumber } = await request({
    call: axios.get(`/exports/${exportId}/batchNumber`),
    hideSuccessToast: true,
  });
  if (newBatchNumber) {
    return newBatchNumber;
  }
  Sentry.withScope(() => {
    Sentry.captureException(new Error('Failed to grab batch number during export'));
  });
  return null;
};

export const alterAfterExportFormPayload = ({
  payload = {},
  rows = [],
  generateFormNumber,
}) => {
  const payloadRef = payload;
  payloadRef.generateFormNumber = !!generateFormNumber;
  payloadRef.formIds = Array.from(rows.reduce((acc, datum) => {
    const { id: formId } = datum;
    /*
      If there is a table we append -{index} to give each row
      a unique key.
    */
    const [prefix] = formId?.split?.('-') ?? [];
    if (prefix) acc.add(prefix);
    return acc;
  }, new Set()));
};

export const alterAfterExportTaskPayload = ({
  payload = {},
  rows = [],
}) => {
  const payloadRef = payload;
  payloadRef.taskIds = Array.from(rows.reduce((acc, datum) => {
    const { id: taskId } = datum;
    const [prefix] = taskId?.split?.('-') ?? [];
    acc.add(prefix);
    return acc;
  }, new Set()));
};

export const constructAfterExportPayload = ({
  type,
  rows = [],
  generateFormNumber = false,
  batchNumber,
}) => {
  if (!type) return {};

  const payload = {};

  if (batchNumber) payload.number = batchNumber;

  if (type === 'forms') {
    alterAfterExportFormPayload({
      payload,
      generateFormNumber,
      rows,
    });
  }
  if (type === 'timeEntries') {
    alterAfterExportTaskPayload({
      payload,
      rows,
    });
  }

  return payload;
};
