import React, {
  useEffect, useState, useMemo, useCallback, forwardRef, useImperativeHandle,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Select, Checkbox } from 'antd';

import sortByString, { getIdMap } from '../../../helpers/helpers';

import {
  getEclipseDivisions,
  importEclipseDivision,
  linkEclipseDivision,
  setSelectedEclipseDivision,
} from './state/eclipse.actions';

function EclipseDivisionSync({
  visible,
  setCanNext,
}, ref) {
  const dispatch = useDispatch();

  const divisions = useSelector((state) => state.settings.divisions);
  const eclipseDivisions = useSelector((state) => state.eclipse.divisions);
  const links = useSelector((state) => state.eclipse.links);

  const [selectedDivision, setSelectedDivision] = useState();
  const [createNew, setCreateNew] = useState(true);
  const [linkDivisionId, setLinkDivisionId] = useState();

  const [
    divisionIdToEclipseId,
    eclipseIdToDivisionId,
  ] = useMemo(() => (
    links.reduce((acc, link) => {
      acc[0][link.divisionId] = link.eclipseId;
      acc[1][link.eclipseId] = link.divisionId;
      return acc;
    }, [{}, {}])
  ), [links]);

  const onCreateNewChanged = useCallback((e) => {
    setCreateNew(e.target.checked);
  }, []);

  const onEclipseDivisionSelected = useCallback((newSelected) => {
    setSelectedDivision(newSelected);
    setLinkDivisionId(eclipseIdToDivisionId[newSelected]);
  }, [eclipseIdToDivisionId]);

  useEffect(() => {
    if (visible) {
      dispatch(getEclipseDivisions());
    } else {
      setSelectedDivision();
      setCreateNew(true);
      setLinkDivisionId();
    }
  }, [visible]);

  const eclipseDivOptions = useMemo(() => (
    eclipseDivisions
      .sort(sortByString('name'))
      .map((div) => {
        if (!(div.id in eclipseIdToDivisionId)) {
          return (
            <Select.Option
              value={div.id}
              label={div.name}
              key={div.id}
            >
              {div.name}
            </Select.Option>
          );
        }
        const {
          [div.id]: ontraccrDivId,
        } = eclipseIdToDivisionId;
        const {
          [ontraccrDivId]: { name } = {},
        } = divisions;
        return (
          <Select.Option value={div.id} label={div.name} key={div.id}>
            <div>{div.name}</div>
            <div style={{ fontSize: 10 }}>
              Linked to Ontraccr Division:
              {' '}
              {name}
            </div>
          </Select.Option>
        );
      })
  ), [eclipseDivisions, eclipseIdToDivisionId, divisions]);

  const eclipseMap = useMemo(() => getIdMap(eclipseDivisions), [eclipseDivisions]);

  const divOpts = useMemo(() => {
    const divList = Object.values(divisions);
    divList.sort(sortByString('name'));
    return divList.map((division) => {
      const {
        [division.id]: eclipseId,
      } = divisionIdToEclipseId;
      if (!eclipseId) {
        return <Select.Option value={division.id} key={division.id}>{division.name}</Select.Option>;
      }
      const {
        [eclipseId]: { name: eclipseName } = {},
      } = eclipseMap;
      return (
        <Select.Option
          value={division.id}
          disabled={division.id !== linkDivisionId}
          label={division.name}
          key={division.id}
        >
          <div>{division.name}</div>
          <div style={{ fontSize: 10 }}>
            Linked to Eclipse Division:
            {' '}
            {eclipseName}
          </div>
        </Select.Option>
      );
    });
  }, [divisions, divisionIdToEclipseId, eclipseMap, linkDivisionId]);

  useEffect(() => {
    setCanNext(selectedDivision && (createNew || linkDivisionId));
  }, [selectedDivision, createNew, linkDivisionId]);

  useImperativeHandle(ref, () => ({
    onSave: async () => {
      // Already linked do nothing.
      if (selectedDivision in eclipseIdToDivisionId) {
        dispatch(setSelectedEclipseDivision(eclipseIdToDivisionId[selectedDivision]));
        return true;
      }
      const {
        [selectedDivision]: { name } = {},
      } = eclipseMap;
      if (createNew) {
        return dispatch(importEclipseDivision({
          eclipseId: selectedDivision,
          name,
        }));
      }
      return dispatch(linkEclipseDivision({
        eclipseId: selectedDivision, name, divisionId: linkDivisionId,
      }));
    },
  }), [selectedDivision, createNew, eclipseIdToDivisionId, linkDivisionId]);

  const hasLink = selectedDivision in eclipseIdToDivisionId;

  return (
    <div>
      <div>Select Eclipse Division:</div>
      <Select
        optionFilterProp="label"
        showSearch
        onChange={onEclipseDivisionSelected}
        value={selectedDivision}
        style={{ width: 500 }}
        dropdownMatchSelectWidth={false}
      >
        {eclipseDivOptions}
      </Select>
      {
        selectedDivision
        && (
          <>
            <div style={{ marginTop: 20 }}>
              <Checkbox
                onChange={onCreateNewChanged}
                checked={createNew && !hasLink}
              >
                Create New Division?
              </Checkbox>
            </div>
            {
              (!createNew || hasLink)
              && (
                <div style={{ marginTop: 20 }}>
                  <div>Link to Ontraccr Division: </div>
                  <Select
                    optionFilterProp="label"
                    showSearch
                    onChange={setLinkDivisionId}
                    value={linkDivisionId}
                    style={{ width: 500 }}
                    dropdownMatchSelectWidth={false}
                  >
                    {divOpts}
                  </Select>
                </div>
              )
            }
          </>
        )
      }
    </div>
  );
}

const EclipseDivisionSyncRef = forwardRef(EclipseDivisionSync);
EclipseDivisionSyncRef.propTypes = {
  visible: PropTypes.bool.isRequired,
  setCanNext: PropTypes.func.isRequired,
};

export default EclipseDivisionSyncRef;
