import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Descriptions } from 'antd';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import { getTextColor } from '../schedule/scheduleHelpers';

import ClientPortalPasswordChangeDrawer from './ClientPortalPasswordChangeDrawer';

import { profileKeys, demoProfile } from './clientPortal.constants';

export default ({
  previewMode
}) => {
  const {
    accentColor,
    profileFields = [],
  } = useSelector(state => state.clientPortal.settings);

  const clientProfile = useSelector(state => state.clientPortal.profile);
  const data = previewMode ? demoProfile : clientProfile;

  const [drawerVisible, setDrawerVisible] = useState(false);

  const showDrawer = useCallback(() => setDrawerVisible(true),[]);
  const hideDrawer = useCallback(() => setDrawerVisible(false),[]);

  const fieldSet = useMemo(() => new Set(profileFields),[profileFields]);
  const filteredKeys = useMemo(() => (
    profileKeys.filter(({ key }) => fieldSet.has(key))
  ),[profileKeys,fieldSet]);

  const textColor = useMemo(() => getTextColor(accentColor),[accentColor]);

  return (
    <>
      <Descriptions title={<span className='client-portal-description-header'>Profile</span>}>
        <Descriptions.Item label={<span className='client-portal-profile-label'>Email</span>}>
          {clientProfile.email}
        </Descriptions.Item>
        {
          filteredKeys.map(({ key, title }) => (
            <Descriptions.Item label={<span className='client-portal-profile-label'>{title}</span>} key={key}>
              {data[key]}
            </Descriptions.Item>
          ))
        }
      </Descriptions>
      <OnTraccrButton
        id='client-portal-password-button'
        title='Change Password'
        onClick={showDrawer}
        style={{
          backgroundColor: accentColor,
          borderColor: accentColor,
          color: textColor,
        }}
      />
      <ClientPortalPasswordChangeDrawer
        visible={drawerVisible}
        onClose={hideDrawer}
      />
    </>
  );
}