import axios from 'axios';
import { request } from '../helpers/requests';

const QRCodesService = {
  getQRCodes: () => request({
    call: axios.get('/qrCodes'),
    errMsg: 'Failed to get QR codes',
    hideSuccessToast: true,
  }),
};

export default QRCodesService;
