import React, {
  forwardRef, useEffect, useImperativeHandle, useState,
} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';

import IntegrationMaterialSync from '../IntegrationMaterialSync';
import EclipseImportHelp from './EclipseImportHelp';

import { request } from '../../../helpers/requests';

import { getMaterials } from '../../../materials/state/materials.actions';

function EclipseMaterialSync({
  visible,
  setCanNext,
}, ref) {
  const dispatch = useDispatch();

  const selectedDivision = useSelector((state) => state.eclipse.selectedDivision);
  const [eclipseMaterials, setEclipseMaterials] = useState([]);
  const [importData, setImportData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getMaterials());
      const {
        data: {
          data = [],
        } = {},
      } = await request({
        call: axios.get('/eclipse/data/materials', { params: { divisionId: selectedDivision } }),
        hideSuccessToast: true,
        errMsg: 'Failed to get eclipse materials',
      });
      setEclipseMaterials(data);
      setLoading(false);
    };

    if (visible && selectedDivision) {
      load();
    } else {
      setEclipseMaterials([]);
    }
  }, [visible, selectedDivision]);

  useImperativeHandle(ref, () => ({
    onSave: async () => request({
      call: axios.post('/integration/import', {
        importMaterials: {
          linkDeletedList: importData.linkDeletedList,
          importList: importData.importList.map((project) => ({
            ...project,
            divisionId: selectedDivision,
          })),
        },
        integrationKey: 'eclipseId',
      }),
      hideSuccessToast: true,
      errMsg: 'Failed to import eclipse materials',
    }),
  }), [selectedDivision, importData]);

  useEffect(() => {
    if (visible) setCanNext(!loading);
  }, [visible, loading, setCanNext]);

  return (
    <IntegrationMaterialSync
      integrationMaterials={eclipseMaterials}
      title="Eclipse"
      integrationKey="eclipseId"
      onMaterialsChanged={setImportData}
      importHelpText={<EclipseImportHelp type="Material" />}
      importMaterials={importData}
      divisionId={selectedDivision}
      loading={loading}
    />
  );
}

const EclipseMaterialSyncRef = forwardRef(EclipseMaterialSync);
EclipseMaterialSyncRef.propTypes = {
  visible: PropTypes.bool.isRequired,
  setCanNext: PropTypes.func.isRequired,
};

export default EclipseMaterialSyncRef;
