import React, {
  useMemo, useEffect, useCallback, useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Drawer,
  Divider,
  Table,
  Row,
  Spin,
  Checkbox,
} from 'antd';
import { DateTime } from 'luxon';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

import WorkflowConfigureSourceSelector from '../../forms/FormWorkflows/selectors/WorkflowConfigureSourceSelector';

import { getSourceFieldMap, getType } from '../../forms/FormWorkflows/workflowHelpers';

import { getDrafts, getTemplateDetails } from '../../forms/state/forms.actions';
import FormSelector from '../../forms/FormWorkflows/selectors/FormSelector';
import {
  closeFormMapDrawer,
  getBoardCardFiles,
  openFormDrawer,
  getCardById,
} from '../state/boards.actions';
import { getIdMap } from '../../helpers/helpers';
import { fillFormFromCard } from '../../forms/formHelpers';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import MappingSelector from './MappingSelector';
import FormsTable from '../../forms/FormsTable';

export default function BoardCardFormMappingDrawer({
  visible,
  data = {},
  selectedCardId,
  cardTypeId,
  divisions = [],
  onSubmit,
  onClose,
}) {
  const dispatch = useDispatch();

  const templates = useSelector((state) => state.forms.templates);
  const circularFieldIds = useSelector((state) => state.forms.circularFieldIds);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);
  const formMappings = useSelector((state) => state.boards.formMappings);
  const cardFileMap = useSelector((state) => state.boards.cardFileMap);
  const reduxCard = useSelector((state) => state.boards.selectedCard);
  const projects = useSelector((state) => state.projects.projects);
  const isFiltered = useSelector((state) => state.boards.isFiltered);
  const filteredSnapshotData = useSelector((state) => state.boards.filteredSnapshotData);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const users = useSelector((state) => state.users.users);
  const usersMap = useMemo(() => getIdMap(users), [users]);
  const equipment = useSelector((state) => state.equipment.equipment);
  const equipmentMap = useMemo(() => getIdMap(equipment), [equipment]);
  const customers = useSelector((state) => state.customers.customers);
  const {
    manual,
  } = useSelector((state) => state.forms.drafts);

  const selectedCard = reduxCard?.id === selectedCardId ? reduxCard : null;
  const {
    id,
    data: cardData,
    title: selectedTitle,
    link: cardLink,
  } = selectedCard ?? {};

  const [loading, setLoading] = useState(false);
  const [selectedMapping, setSelectedMapping] = useState();
  const [selectedForm, setOurSelectedForm] = useState();
  const [fieldMappings, setFieldMappings] = useState({});
  const [cardIsLoading, setCardIsLoading] = useState(false);
  const [shouldMapFilteredData, setShouldMapFilteredData] = useState(false);

  const draftList = useMemo(() => (
    Object.values(manual)
      .filter(
        ({ cardId }) => cardId === selectedCardId,
      )
      .map((draft) => {
        const {
          id: draftId,
          lastUpdated,
          templateId,
          data: draftData,
        } = draft;

        return {
          id: draftId,
          name: draftData?.name ?? '',
          lastUpdated,
          templateId,
          circularFieldIds: draftData?.circularFieldIds,
        };
      })
  ), [manual, selectedCardId]);

  const draftListMap = useMemo(() => getIdMap(draftList), [draftList]);

  useEffect(() => {
    if (visible && selectedForm) {
      dispatch(getTemplateDetails(
        selectedForm,
        undefined,
        {
          fieldMappings,
        },
      ));
    }
  }, [visible, selectedForm, fieldMappings]);

  useEffect(() => {
    const loadCard = async () => {
      setCardIsLoading(true);
      await dispatch(getCardById(selectedCardId));
      setCardIsLoading(false);
    };

    if (visible && selectedCardId && reduxCard?.id !== selectedCardId) {
      loadCard();
    }
  }, [visible, reduxCard, selectedCardId]);

  useEffect(() => {
    if (visible && selectedCardId) dispatch(getDrafts({ cardId: selectedCardId }));
  }, [visible, selectedCardId]);

  const ourCardTemplate = useMemo(() => {
    const {
      [cardTypeId]: template = {},
    } = cardTemplates;
    return template;
  }, [cardTypeId, cardTemplates]);

  const {
    fields: sourceSections = [],
  } = ourCardTemplate;

  const ourTemplate = useMemo(() => {
    const {
      [selectedForm]: targetForm = {},
    } = templates;
    return targetForm;
  }, [selectedForm, templates]);

  const fileMap = useMemo(() => {
    const {
      [selectedCardId]: ourFileMap = {},
    } = cardFileMap;
    return ourFileMap;
  }, [cardFileMap, selectedCardId]);

  const {
    formData: {
      name: targetName,
      sections: targetSections = [],
    } = {},
  } = ourTemplate;

  const [mappingName, setMappingName] = useState(targetName);

  const cleanup = () => {
    setOurSelectedForm();
    setFieldMappings({});
    setLoading(false);
    setMappingName();
    setSelectedMapping();
    setCardIsLoading(false);
    setShouldMapFilteredData(false);
  };

  const flattenedTargetFields = useMemo(() => {
    const fields = [];
    targetSections.forEach((section) => {
      const { fields: sectionFields = [], name: sectionName } = section;
      sectionFields.forEach((field) => {
        const {
          configProps: {
            title: fieldTitle,
          } = {},
        } = field;
        fields.push({
          ...field,
          title: `${sectionName} - ${fieldTitle}`,
        });
      });
    });
    return fields;
  }, [targetSections]);

  const sourceFieldMap = useMemo(() => {
    const newSourceFieldMap = getSourceFieldMap(sourceSections);
    const cardNameField = { id: 'cardTitle', title: 'Card Title' };
    if (!newSourceFieldMap.text) {
      newSourceFieldMap.text = [cardNameField];
    } else {
      newSourceFieldMap.text.push(cardNameField);
    }
    return newSourceFieldMap;
  }, [sourceSections, selectedTitle]);

  const columns = useMemo(() => [{
    dataIndex: 'sourceField',
    title: 'Source Field',
    width: 425,
    render: (_, record) => {
      const {
        id: targetId,
      } = record;
      const type = getType(record);
      const {
        [type]: eligibleSourceFields = [],
      } = sourceFieldMap;

      const {
        [targetId]: defaultValue,
      } = fieldMappings;

      return (
        <WorkflowConfigureSourceSelector
          fieldMappings={fieldMappings}
          defaultValue={defaultValue}
          onFieldMappingsChange={setFieldMappings}
          targetId={targetId}
          eligibleSourceFields={eligibleSourceFields}
        />
      );
    },
  }, {
    dataIndex: 'title',
    title: 'Target Field',
  }], [fieldMappings, sourceFieldMap, setFieldMappings, getType]);

  const onCloseDefault = useCallback(() => {
    dispatch(closeFormMapDrawer());
    cleanup();
  }, []);

  const onCloseClicked = useCallback(() => {
    if (onClose) {
      onClose();
      cleanup();
    } else {
      onCloseDefault();
    }
  }, [onClose, onCloseDefault]);

  const onSubmitCreateForm = useCallback(async (selectedDraft) => {
    const relevantDraft = draftListMap[selectedDraft?.id];
    if (relevantDraft) {
      await dispatch(getTemplateDetails(
        relevantDraft.templateId,
        relevantDraft.id,
        {
          circularFieldIds: relevantDraft.circularFieldIds,
        },
      ));
    }

    setLoading(true);
    const {
      formData: filledForm,
      ids,
    } = fillFormFromCard({
      formTemplate: ourTemplate,
      cardData: (isFiltered && shouldMapFilteredData) ? filteredSnapshotData : cardData,
      mappings: fieldMappings,
      cardLink,
      projects: projectMap,
      customers,
      users: usersMap,
      equipment: equipmentMap,
      cardId: id,
      cardTitle: selectedTitle,
      circularFieldIds,
    });

    const config = {
      visible: true,
      cardId: selectedCardId,
      isEdit: false,
      fileMap,
      ids,
    };

    if (relevantDraft) {
      config.assignedForm = { draftId: relevantDraft.id };
    } else {
      config.formData = filledForm;
    }

    await dispatch(openFormDrawer(config));
    dispatch(closeFormMapDrawer());
    cleanup();
    setLoading(false);
  }, [
    id,
    selectedTitle,
    ourTemplate,
    cardData,
    fieldMappings,
    selectedCardId,
    fileMap,
    cardLink,
    projectMap,
    customers,
    usersMap,
    equipmentMap,
    isFiltered,
    filteredSnapshotData,
    shouldMapFilteredData,
    draftListMap,
    circularFieldIds,
  ]);

  const onSubmitClicked = useCallback(() => {
    if (onSubmit) {
      onSubmit({
        selectedForm,
        fieldMappings,
        mappingName,
      });
      cleanup();
    } else {
      onSubmitCreateForm();
    }
  }, [
    onSubmit,
    onSubmitCreateForm,
    selectedForm,
    fieldMappings,
    mappingName,
  ]);

  const onMappingChange = useCallback((mappingId) => {
    const newMapping = formMappings.find((mapping) => {
      const { id } = mapping;
      return id === mappingId;
    });
    if (newMapping) {
      const {
        name,
        fieldMappings = {},
        formTemplateId,
      } = newMapping;
      setMappingName(name);
      setFieldMappings(fieldMappings);
      setOurSelectedForm(formTemplateId);
      setSelectedMapping(mappingId);
    } else {
      cleanup();
    }
  }, [formMappings]);

  const onNameChange = useCallback(async (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setMappingName(value);
  }, []);

  useEffect(() => {
    if (data && data.id) {
      const {
        name,
        fieldMappings = {},
        formTemplateId,
      } = data;
      setMappingName(name);
      setFieldMappings(fieldMappings);
      setOurSelectedForm(formTemplateId);
      setSelectedMapping(data.id);
    }
  }, [data, visible]);

  const onFormChange = useCallback((form) => {
    setSelectedMapping();
    setFieldMappings({});
    setOurSelectedForm(form);
  }, []);

  useEffect(() => {
    if (visible && selectedCardId) {
      dispatch(getBoardCardFiles(selectedCardId));
    }
  }, [visible, selectedCardId]);

  const cardBody = (
    cardIsLoading
      ? (
        <Row style={{ height: '100%', width: '100%' }} align="middle" justify="center">
          <Spin />
        </Row>
      )
      : (
        <MappingSelector
          onChange={onMappingChange}
          selected={selectedMapping}
        />
      )
  );

  const draftTableColumns = [
    {
      title: 'Draft Name',
      dataIndex: 'name',
    },
    {
      title: 'Last Updated',
      dataIndex: 'lastUpdated',
      render: (lastUpdated) => (lastUpdated
        ? DateTime.fromMillis(lastUpdated).toLocaleString(DateTime.DATETIME_SHORT)
        : '-'),
    },
  ];

  return (
    <Drawer
      title="Generate Form from Card"
      width={1000}
      onClose={onCloseClicked}
      visible={visible}
      bodyStyle={{
        padding: '0px 24px',
        marginBottom: 53,
      }}
    >
      { !selectedCardId && (
      <>
        <Row style={{ marginTop: 5 }}>
          Mapping Name
        </Row>
        <Row style={{ margin: '10px 0px' }}>
          <OnTraccrTextInput value={mappingName} onChange={onNameChange} />
        </Row>
      </>
      ) }
      {selectedCardId && (
        cardBody
      )}
      {!cardIsLoading && (
        <FormSelector
          divisions={divisions}
          onChange={onFormChange}
          selected={selectedForm}
        />
      )}
      {selectedForm && (
        <>
          {selectedCardId && (
            <>
              <div>
                Configure which fields from this
                {' '}
                <b>{selectedTitle}</b>
                {' '}
                card are loaded into the fields for the
                {' '}
                <b>{targetName}</b>
                {' '}
                form
                {' '}
              </div>
              <div style={{ marginTop: 10 }}>
                <Checkbox
                  checked={shouldMapFilteredData}
                  onChange={(e) => setShouldMapFilteredData(e.target.checked)}
                  disabled={!isFiltered}
                >
                  Map filtered data into the form
                </Checkbox>
              </div>
            </>
          )}
          <Divider />
          <Table
            dataSource={flattenedTargetFields}
            columns={columns}
            pagination={false}
            size="small"
            rowKey="id"
          />
        </>
      )}
      {!cardIsLoading && selectedCardId && draftList.length > 0 && (
        <FormsTable
          title="Active Drafts"
          data={draftList}
          onClickRow={onSubmitCreateForm}
          columns={draftTableColumns}
          loading={loading}
          style={{ marginTop: 20 }}
        />
      )}
      <DrawerSubmitFooter
        onClose={onCloseClicked}
        onSubmit={onSubmitClicked}
        loading={cardIsLoading || loading || !ourTemplate?.formData}
        submitTitle={!selectedCardId ? 'Save' : 'Continue'}
        canSubmit={!!selectedForm && ourTemplate?.formData && !cardIsLoading}
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardCardFormMappingDrawer.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  selectedCardId: PropTypes.string,
  cardTypeId: PropTypes.string,
  divisions: PropTypes.array,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
};

BoardCardFormMappingDrawer.defaultProps = {
  visible: false,
  data: {},
  selectedCardId: null,
  cardTypeId: null,
  divisions: [],
  onSubmit: null,
  onClose: null,
};
