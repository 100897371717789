import React from 'react';
import { connect } from 'react-redux';
import {
  Layout, Row, Col, Divider,
} from 'antd';

import config from '../config';

import Analytics from '../helpers/Analytics';
import colors from '../constants/Colors';
import AppMenu from './AppMenu';
import NuxModal from '../nux/NuxModal';
import Logout from '../auth/logout';
import Logo from '../common/Logo';

import DivisionsDropdown from './DivisionsDropdown';
import Version from './Version';

import { setOpenKeys, setMenu } from './state/menu.actions';
import { createNuxEntry, startNuxAction } from '../nux/state/nux.actions';
import { acceptToS } from '../users/state/users.actions';

import NuxFocusBackground from '../nux/NuxFocusBackground';

import { getTutorials } from '../learningCenter/state/learningCenter.actions';

import {
  GET_START_CLOSE_TYPE,
  GET_STARTED_CLOSE_NUX_STEP_1,
  NEW_MENU_TYPE,
  NEW_MENU_NUX_STEP_1,
} from '../nux/nux.constants';
import LearningCenterDrawer from '../learningCenter/LearningCenterDrawer';

const {
  Sider,
} = Layout;

export default connect(
  (state,ownProps) => {
    return {
      ...ownProps,
      selectedKeys:state.menu.items,
      openKeys:state.menu.openKeys,
      approvalCount:state.approvals.approvalCount,
      nux:state.nux.nux,
      activeNuxAction:state.nux.activeNuxAction,
      nuxLoaded:state.nux.nuxLoaded,
      tutorials:state.learningCenter.tutorials,
    };
  },{
    setMenu,
    setOpenKeys,
    createNuxEntry,
    acceptToS,
    startNuxAction,
    getTutorials,
  }
)(class Menu extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      selectedKeys:[],
      openKeys:[],
    };
    this.onMenuPicked = this.menuPicked.bind(this);
    this.onOpenChange = this.openChanged.bind(this);
    this.onGetStartedClose = this.getStartedClose.bind(this);
    this.onLearningCenterClose = this.learningCenterClose.bind(this);
    this.sawGetStartedNux = false;
    this.props.getTutorials();
  }

  componentDidMount() {
    const {
      pathname,
      setMenu,
      setOpenKeys,
    } = this.props;
    try {
      const firstLogin = window.sessionStorage.getItem('firstLogin');
      const acceptedTos = window.sessionStorage.getItem('acceptedToS') === '1';

      if(firstLogin === 'true' || !acceptedTos) {
        this.props.setMenu(['getStarted']);
        this.sawGetStartedNux = true;
        return;
      }
    } catch(err) {
      console.log('Failed loading first login screen');
    }
   
    
    const menuItem = pathname.substring(1);

    setMenu([menuItem]);
    if(menuItem === 'users' || menuItem === 'teams') {
      setOpenKeys(['staff'])
    } else if (menuItem.includes('timecards')) {
      setOpenKeys(['timecards'])
    } else if (menuItem.includes('costcodes')) {
      setOpenKeys(['costcode'])
    } else if (menuItem === 'files' || menuItem === 'forms') {
      setOpenKeys(['documents'])
    }

  }

  componentDidUpdate(prevProps) {
    const {
      pathname,
      setMenu,
      nux,
      createNuxEntry,
      startNuxAction,
    } = this.props;
    const {
      pathname:prevPath,
    } = prevProps;

    if(pathname !== prevPath){
      setMenu([pathname.substring(1)]);
    }

    try {
      const { newMenuTimestamp } = config;
      const firstLogin = window.sessionStorage.getItem('firstLogin');
      const lastActive = window.sessionStorage.getItem('lastActive');

      if(
        firstLogin !== 'true'
        && parseInt(lastActive,10) < parseInt(newMenuTimestamp, 10)
        && nux.size > 0
        && !nux.has(NEW_MENU_TYPE)
      ) {
        // Show new menu
        createNuxEntry(NEW_MENU_TYPE);
        startNuxAction(NEW_MENU_NUX_STEP_1);
      }
    } catch(err) {
      console.log('Failed loading first login screen');
    }
  }

  menuPicked(e) {
    const {
      selectedKeys = [],
    } = this.props;
    Analytics.track('PageSelect',{ key: e.key });
    switch(e.key) {
      case 'logout': 
        Logout(this.props.onLogout);
        break;
      case 'support':
      case 'feedback':
      case 'profile':
      case 'getStarted':
        if(e.key === 'getStarted') {
          Analytics.track('NUX/Open',{ Source: 'Menu' });
        }
        this.props.setMenu(selectedKeys.concat([e.key,]));
        break;
      default:
        this.props.setMenu([e.key]);
        this.props.onMenuPicked(e);
    }
  }

  removeMenuSelect(invalid) {
    const {
      selectedKeys = [],
      setMenu,
    } = this.props;
    setMenu(selectedKeys.filter(key => key !== invalid))
  }

  openChanged(open) {
    this.props.setOpenKeys(open.filter((key) => !this.state.openKeys.includes(key)));
  }

  supportCloseClicked() {
    const {
      selectedKeys = [],
      setMenu,
    } = this.props;
    setMenu(selectedKeys.filter(key => key !== 'support'))
  }

  supportClosed() {
  }

  feedbackClosed() {

  }

  feedbackCloseClicked() {
    this.removeMenuSelect('feedback');
  }

  profileClosedClicked() {
    this.removeMenuSelect('profile');
  }
  
  getStartedClose() {
    const {
      nux = new Set(),
      startNuxAction,
      createNuxEntry,
    } = this.props;
    this.removeMenuSelect('getStarted');
    if(!nux.has(GET_START_CLOSE_TYPE)) {
      startNuxAction(GET_STARTED_CLOSE_NUX_STEP_1);
      createNuxEntry(GET_START_CLOSE_TYPE);
    }
  }

  learningCenterClose() {
    this.removeMenuSelect('learningCenter');
  }
  
  render() {
    const {
      selectedKeys = [],
      openKeys = [],
      fullscreen,
      approvalCount,
      history,
      location,
      nux = new Set(),
      createNuxEntry,
      acceptToS,
      activeNuxAction,
    } = this.props;

    return (
      <Sider 
        collapsed={fullscreen}
        collapsedWidth={0}
        style={{ 
          minHeight: '100vh',
          backgroundColor:colors.ONTRACCR_BLACK,
          height: '100vh',
          position: 'fixed',
          left: 0,
        }}
      >
        <NuxFocusBackground show={activeNuxAction}/>
          <Row 
            type='flex'
            justify='center' 
            style={{
              marginTop:19,
              width:'100%',
              marginBottom:22,
            }}
          >
            <Col>
              <Logo
                darkMode
                height={55}
                style={{ padding: 3 }}
              />
            </Col>
          </Row>
          <Row 
            type='flex' 
            justify='center'
            style={{
              paddingLeft:15,
              paddingRight:15,
            }}
          >
            <DivisionsDropdown/>
            <Divider style={{
              backgroundColor:colors.opacity(colors.LIGHT_GRAY,0.4),
              height:1,
              margin:0,
            }}/>
          </Row>
          
        <div id='app-menu-scroll'>
          <div id='app-menu-container'>
          <AppMenu 
            className='Menu'
            onMenuPicked={this.onMenuPicked} 
            onOpenChange={this.onOpenChange}
            selectedKeys={selectedKeys}
            openKeys={openKeys}
            approvalCount={approvalCount}
            nux={nux}
            activeNuxAction={activeNuxAction}
            createNuxEntry={createNuxEntry}
          />
          </div>
          <Version/>
        </div>
        <LearningCenterDrawer
          visible={selectedKeys.indexOf('learningCenter') >= 0}
          onClose={this.onLearningCenterClose}
          tutorials={this.props.tutorials}
          nux={nux}
        />
        <NuxModal
          nux={nux}
          visible={selectedKeys.indexOf('getStarted') >= 0}
          onCloseClicked={this.onGetStartedClose}
          history={history}
          createNuxEntry={createNuxEntry}
          acceptToS={acceptToS}
        />
      </Sider>
    );
  }
});
