import React from 'react';
import { Row, Checkbox, Col, } from 'antd';

import HoverHelp from '../../../common/HoverHelp';

export default ({
  optional,
  onChange,
  disabled,
}) => (
  <Row style={{ marginTop: 15 }} gutter={10}>
    <Col>
      <Checkbox
        checked={disabled || optional}
        onChange={onChange}
        disabled={disabled}
      >
        Optional
      </Checkbox>
    </Col>
    <Col>
      <HoverHelp
        placement='topRight'
        content={
          <div style={{ width:250}}>
            Check this box to make this field <b>optional</b>.
            <br/><br/>
            Optional fields can be left blank by the user filling out the form.
          </div>
        }
      />
    </Col>
  </Row>
);
