import React, { useMemo } from 'react';
import { Drawer, Divider, Table } from 'antd';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

import WorkflowConfigureSourceSelector from './selectors/WorkflowConfigureSourceSelector';

import { getSourceFieldMap, getType } from './workflowHelpers';

export default function WorkflowTimeConfigureDrawer({
  visible,
  onClose,
  onSubmit,
  sourceName,
  sourceSections = [],
  fieldMappings = {},
  onFieldMappingsChange,
  customFields = [],
}) {
  const flattenedTargetFields = useMemo(() => {
    const fields = [];
    customFields.forEach((section) => {
      const { fields: sectionFields = [], name: sectionName } = section;
      sectionFields.forEach((field) => {
        const {
          configProps: {
            title: fieldTitle,
          } = {},
        } = field;
        fields.push({
          ...field,
          title: `${sectionName} - ${fieldTitle}`,
        });
      });
    });
    return fields;
  }, [customFields]);

  const sourceFieldMap = useMemo(() => getSourceFieldMap(sourceSections), [sourceSections]);

  const columns = [{
    dataIndex: 'sourceField',
    title: 'Source Field',
    width: 425,
    render: (_, record) => {
      const {
        id: targetId,
      } = record;
      const type = getType(record);
      const {
        [type]: eligibleSourceFields = [],
      } = sourceFieldMap;

      const {
        [targetId]: defaultValue,
      } = fieldMappings;
      return (
        <WorkflowConfigureSourceSelector
          fieldMappings={fieldMappings}
          defaultValue={defaultValue}
          onFieldMappingsChange={onFieldMappingsChange}
          targetId={targetId}
          eligibleSourceFields={eligibleSourceFields}
        />
      );
    },
  }, {
    dataIndex: 'title',
    title: 'Target Field',
  }];

  return (
    <Drawer
      title="Configure Field Mapping"
      width={1000}
      onClose={onClose}
      visible={visible}
    >
      <span>
        {'Configure which fields from this '}
        <b>{sourceName}</b>
        {' form are loaded into the custom timecard fields.'}
      </span>
      <Divider style={{ marginBottom: 0 }} />
      <Table
        dataSource={flattenedTargetFields}
        columns={columns}
        pagination={false}
        size="small"
        scroll={{ y: 'calc(100vh - 218px)' }}
        rowKey="id"
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}
