import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Tag } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

import config from '../config';

import { setSelectedDivisions } from '../settings/state/settings.actions';


export default () => {
  const dispatch = useDispatch();
  const divisions = useSelector(state => state.settings.divisions);
  const selectedDivisions = useSelector(state => state.settings.selectedDivisions);

  // Divisions is an object with division IDs as key
  const divisionIds = Object.keys(divisions);

  const [hovering,setHovering] = useState(false);

  const onMouseEnter = useCallback(() => setHovering(true),[]);
  const onMouseLeave = useCallback(() => setHovering(false),[]);

  const onChange = useCallback((newSelected) => {
    dispatch(setSelectedDivisions(new Set(newSelected)));
  },[dispatch]);
  const onSelectAll = useCallback(() => {
    dispatch(setSelectedDivisions(new Set(divisionIds)));
  },[dispatch,divisionIds]);
  
  const selectedIDs = useMemo(() => Array.from(selectedDivisions),[selectedDivisions]);

  const labelView = useMemo(() => {
    if(selectedIDs.length >= 5) {
      return (
        <div className='divisions-label-many'>
          {`${selectedIDs.length} divisions selected`}
        </div>
      )
    }

    return (
      <div className='divisions-label-container'>
          {selectedIDs.map((id) => {
            const {
              [id]: {
                name,
              } = {},
            } = divisions;
            return name ? 
            (
              <div key={id}>
              <div className='divisions-label' key={id}> {name} </div>
              <br/>
              </div>
            )
            : null;
          })}
       </div>
    );
  },[selectedIDs, divisions]);

  if(divisionIds.length <= 1 || !config.showDivisions) return null;
  return (
    <div
      id='divisions-dropdown-container'
      onMouseLeave={onMouseLeave}
      onMouseEnter={onMouseEnter}
    >
      <div id='divisions-header-container'>
        <div id='divisions-header-title'>
          Divisions:
        </div>
        {hovering &&
          <div
            id='divisions-all-button'
            onClick={onSelectAll}
          >
          Select All
          </div>
        }
      </div>
      {
      hovering
      ? <Select
        className='divisions-dropdown'
        mode='multiple'
        value={selectedIDs}
        open
        dropdownMatchSelectWidth={false}
        onChange={onChange}
        filterOption={(input, option) => (
           option.title.toLowerCase().indexOf(input.toLowerCase()) >= 0
        )}
        tagRender={(props) => (
          <Tag {...props}
            closable={selectedIDs.length > 1}
            className='division-tag'
            closeIcon={
              <CloseOutlined style={{ color: 'white', fontSize: 10 }}/>
            }
          >
            <span className={
              selectedIDs.length > 1
              ? 'division-tag-label-closeable'
              : 'division-tag-label-uncloseable'
            }>
              {props.label}
            </span>
          </Tag>
        )}
      >
        {
          Object.values(divisions).map(({ id, name }) => (
            <Select.Option
              key={id}
              title={name}
              value={id}
              disabled={selectedIDs.length === 1 && selectedIDs[0] === id}
            >
              {name}
            </Select.Option>
          ))
        }
      </Select>
      : labelView
      }
    </div>
  )
};
