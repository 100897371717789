import React, { useCallback, useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Form, Row, message } from 'antd';

import Permissions from '../auth/Permissions';

import DrawerFooter from '../common/containers/DrawerFooter';
import DrawerArchiveFooter from '../common/containers/DrawerArchiveFooter';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import { getIdMap } from '../helpers/helpers';

import MaterialDrawerTabContainer from './MaterialDrawerTabContainer';

import {
  getPrice,
  hasMarkup,
  getMarkupPercent,
} from './materialsHelpers';

const SMALL_WIDTH = 600;
const LARGE_WIDTH = '80%';
const LAST_UPLOAD_STEPS = 2;

export default function MaterialDrawer ({
  item,
  defaultLocation,
  visible,
  onClose,
  onSubmit,
  isNotDisplay,
  onEdit,
  onArchive,
  onDelete,
  onMassUpload,
}) {
  const {
    name,
    divisionId,
    markup,
    quantity,
    quantityAllocated,
    active,
    locations: initialLocations = defaultLocation,
  } = item || {};
  let title = 'Add Material';
  if (name) {
    title = isNotDisplay ? 'Edit Material' : name;
  }
  const [form] = Form.useForm();

  const materials = useSelector((state) => state.materials.materials);
  const costcodes = useSelector((state) => state.costcodes.costcodes);

  const [selectedDivision, setSelectedDivision] = useState(divisionId);
  const [locations, setLocations] = useState(initialLocations || defaultLocation);
  const [price, setPrice] = useState();
  const [totalQuantity, setTotalQuantity] = useState(quantity);
  const [totalQuantityAllocated, setTotalQuantityAllocated] = useState(quantityAllocated);
  const [activeTab, setActiveTab] = useState('create');
  const [uploadFile, setUploadFile] = useState();
  const [currentStep, setCurrentStep] = useState(0);
  const [massUploadData, setMassUploadData] = useState();
  const [loading, setLoading] = useState(false);
  const isCreate = activeTab === 'create';
  const width = isCreate || !uploadFile ? SMALL_WIDTH : LARGE_WIDTH;

  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const relevantCC = useMemo(() => (
    !selectedDivision
      ? []
      : costcodes.filter((cc) => (
        cc.active
        && !cc.projectId
        && cc.divisionId === selectedDivision
        && cc.category === 'Material'
      ))
  ), [costcodes, selectedDivision]);

  const materialNames = useMemo(() => {
    const nameSet = new Set();
    Object.values(materials).forEach((mat) => {
      if (mat.name && mat.divisionId === selectedDivision) {
        nameSet.add(mat.name.toLowerCase());
      }
    });
    return nameSet;
  }, [materials, selectedDivision]);

  const onSubmitClicked = useCallback(async () => {
    try {
      const values = await form.validateFields();
      onSubmit({
        locations,
        values,
      });
    } catch (err) {
      // Failed
    }
  }, [onSubmit, locations]);

  const onValuesChange = useCallback((_, allValues) => {
    const {
      markup: formMarkup,
      cost: formCost,
    } = allValues;
    const defaultFormMarkup = formMarkup || 0;
    if (hasMarkup(defaultFormMarkup) && formCost) {
      setPrice(getPrice(formCost, defaultFormMarkup));
    }
  }, []);

  const onBack = useCallback(() => setCurrentStep(currentStep - 1), [currentStep]);
  const onNext = useCallback(async () => {
    if (isCreate) {
      onSubmitClicked();
    } else if (currentStep < LAST_UPLOAD_STEPS) {
      setCurrentStep(currentStep + 1);
    } else {
      setLoading(true);

      const uploadMaterialNames = new Set();
      const filteredUpload = [];
      massUploadData.forEach((mat) => {
        if (!mat.name) return;
        const strName = mat.name.toString();
        const lowerName = strName.toLowerCase();
        if (materialNames.has(lowerName)) return;
        if (uploadMaterialNames.has(lowerName)) {
          // Multiple items in this list have the same name?
          return;
        }
        uploadMaterialNames.add(lowerName);
        const newData = {
          ...mat,
          name: strName,
          description: mat.description ? mat.description.toString() : null,
          partNumber: mat.partNumber ? mat.partNumber.toString() : undefined,
          markup: mat.markup && !Number.isNaN(mat.markup) ? mat.markup : undefined,
          supplier: mat.supplier ? mat.supplier.toString() : undefined,
          cost: mat.cost && !Number.isNaN(mat.cost) ? mat.cost : undefined,
          units: mat.units ? mat.units.toString() : undefined,
          quantity: mat.quantity && !Number.isNaN(mat.quantity) ? mat.quantity : 0,
          quantityAllocated: mat.quantityAllocated && !Number.isNaN(mat.quantityAllocated)
            ? mat.quantityAllocated
            : 0,
          divisionId: selectedDivision,
        };
        delete newData.id;
        filteredUpload.push(newData);
      });

      const numConflicts = massUploadData.length - filteredUpload.length;
      if (numConflicts > 0) {
        message.warning(`${numConflicts} materials could not be imported due to name conflicts`);
      }
      await onMassUpload(filteredUpload);
      setLoading(false);
    }
  }, [isCreate, currentStep, onSubmitClicked, massUploadData, onMassUpload, materialNames]);

  useEffect(() => {
    const resetCostcode = async () => {
      const values = await form.getFieldsValue();
      const newValues = { ...values };
      const {
        [newValues.costcodeId]: {
          divisionId: costcodeDivision,
        } = {},
      } = costcodeIdMap;
      if (costcodeDivision !== selectedDivision) {
        newValues.costcodeId = undefined;
        await form.setFieldsValue(newValues);
      }
    };
    if (visible) resetCostcode();
  }, [selectedDivision, form, costcodeIdMap, visible]);

  useEffect(() => {
    const {
      divisionId,
      cost,
      markup,
    } = item || {};
    const defaultMarkup = markup || 0;
    setSelectedDivision(divisionId);
    if (cost && hasMarkup(defaultMarkup)) setPrice(getPrice(cost, defaultMarkup * 100));
  }, [item]);

  useEffect(() => {
    if (initialLocations) {
      setLocations(initialLocations);
    }
  }, [initialLocations]);

  useEffect(() => {
    const resetInitialState = async () => {
      setSelectedDivision();
      setLocations(defaultLocation);
      await form.resetFields();
      setActiveTab('create');
      setUploadFile();
      setCurrentStep(0);
    };
    if (!visible) {
      resetInitialState();
    }
  }, [visible]);

  useEffect(() => {
    if (!locations?.length && quantity) return;

    setTotalQuantity(
      locations.reduce((acc, { quantity: locQuant = 0 }) => (
        acc + (locQuant ? parseInt(locQuant, 10) : 0)
      ), 0),
    );

    setTotalQuantityAllocated(
      locations.reduce((acc, { quantityAllocated: locQuant = 0 }) => (
        acc + (locQuant ? parseInt(locQuant, 10) : 0)
      ), 0),
    );
  }, [locations, quantity]);

  return (
    <Drawer
      title={title}
      width={width}
      visible={visible}
      onClose={onClose}
      // contentWrapperStyle Does not work on our current antd version (4.5.2). Need to upgrade to 4.13.0+
      contentWrapperStyle={{
        transition:'width 0.5s',
        width,
      }}
    >
      <Form
        layout='vertical'
        form={form}
        className={Permissions.hasWrite('MATERIALS') ? 'materials-write-form' : 'materials-readonly-form'}
        initialValues={{
          ...item,
          markup: getMarkupPercent(markup),
        }}
        onValuesChange={onValuesChange}
      >
        <MaterialDrawerTabContainer
          isAdd
          isNotDisplay={isNotDisplay}
          item={item}
          price={price}
          totalQuantity={totalQuantity}
          totalQuantityAllocated={totalQuantityAllocated}
          locations={locations}
          onLocationsChanged={setLocations}
          onDivisionChange={setSelectedDivision}
          relevantCC={relevantCC}
          onTabChange={setActiveTab}
          activeTab={activeTab}
          uploadFile={uploadFile}
          onFileChange={setUploadFile}
          currentStep={currentStep}
          onMassUploadDataChanged={setMassUploadData}
          visible={visible}
        />
      </Form>
      {isNotDisplay &&
        <DrawerFooter>
          <Row justify='end' align='middle' gutter={8}>
            <OnTraccrButton
              title='Cancel'
              type='cancel'
              id='customer-add-project-footer-cancel'
              onClick={onClose}
            />
            {currentStep > 0
              && <OnTraccrButton
                title='Back'
                type='back'
                id='customer-add-project-footer-back'
                onClick={onBack}
              />
            }
            <OnTraccrButton
              title={currentStep < LAST_UPLOAD_STEPS && !isCreate ? 'Next' : 'Submit'}
              onClick={onNext}
              disabled={!isCreate && !uploadFile}
              loading={loading}
            />
          </Row>
        </DrawerFooter>
      }
      {
        !isNotDisplay && Permissions.hasWrite('MATERIALS') &&
          <DrawerArchiveFooter
            active={active}
            onEdit={onEdit}
            onArchive={onArchive}
            onDelete={onDelete}
          />
      }
    </Drawer>
  );
};