import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import AddDrawer from '../../common/addDrawer';
import EquipmentAddView from '../../equipment/EquipmentAddView';

const SMALL_WIDTH = 800;
const LARGE_WIDTH = 1000;

export default ({
  visible,
  onClose,
  divisionId,
  chosen = [],
  selectedEquipment,
  errors,
  customFields,
  setCustomFields,
}) => {
  const { t } = useTranslation();
  const equipment = useSelector(state => state.equipment.equipment);
  return (
    <AddDrawer
      onClose={onClose}
      visible={visible}
      title={selectedEquipment ? `${selectedEquipment.name}` : `Add New Equipment to ${t('Project')}`}
      isAdd={!selectedEquipment}
      viewOnly={selectedEquipment}
      formView={(form,formProps) => (
        <EquipmentAddView
          {...form}
          formProps={formProps}
          equipment={equipment}
          isProjectSpecific
          key={chosen.length}
          errors={errors}
          customFields={customFields}
          setCustomFields={setCustomFields}
        />
      )}
      formProps={{
        editing: !selectedEquipment,
        divisionIds: [divisionId],
        ...selectedEquipment
      }}
      width={selectedEquipment ? LARGE_WIDTH : SMALL_WIDTH}
    />
  );
}