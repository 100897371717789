import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { ClockCircleOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import { Popover, Row } from 'antd';

export default ({
  lastMove = DateTime.local(),
  placement = 'leftBottom'
}) => {
  const isDragging = useSelector((state) => state.boards.isDragging);
  const days = useMemo(() => {
    const now = DateTime.local().startOf('day');
    return Math.ceil(now.diff(lastMove).as('days'), 10);
  }, [lastMove]);

  const sinceText = useMemo(() => (
    lastMove.toLocaleString(DateTime.DATE_MED)
  ),[lastMove]);

  return (
    <Popover
      placement={placement}
      content={`This card has been in this status since ${sinceText}`}
      trigger={isDragging ? [] : ['hover']}
    >
      <Row align='middle' className='board-card-days-in-status-container'>
        <ClockCircleOutlined />
        <span className='board-card-days-in-status-text'>{days}</span>
      </Row>
    </Popover>
  )
}