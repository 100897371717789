import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrTextInput from './OnTraccrTextInput';
import OnTraccrButton from '../buttons/OnTraccrButton';

const BASE_UNITS = [
  'Square Feet',
  'Square Meters',
  'Linear Feet',
  'Linear Meters',
  'Cubic Feet',
  'Cubic Meters',
  'Squares',
  'Tons',
  'Pounds',
  'Kilograms',
  'Each',
  'Gallons',
  'Liters',
];

export default ({
  value,
  onSelect,
  dropdownWidth = 150,
  dropdownMatchSelectWidth = false,
}) => {
  const materials = useSelector(state => state.materials.materials);

  const [units,setUnits] = useState(BASE_UNITS);
  const [newUnit, setNewUnit] = useState();
  const [open, setOpen] = useState(false);

  const materialOptions = useMemo(() => {
    const optionSet = new Set(BASE_UNITS);
    Object.values(materials).forEach((mat) => {
      if(mat.units) optionSet.add(mat.units);
    });
    return Array.from(optionSet);
  },[materials]);
  
  const onDropdownVisibleChange = useCallback((newOpen) => {
    if(!newOpen) setNewUnit();
    setOpen(newOpen);
  },[]);
  const onNewUnitChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setNewUnit(value);
  },[]);

  const onAddClicked = useCallback(() => {
    const ourUnits = new Set([...units]);
    ourUnits.add(newUnit);
    onSelect(newUnit);
    setUnits(Array.from(ourUnits));
    setNewUnit();
    setOpen(false);
  },[units, newUnit, onSelect]);

  const onChange = useCallback((newSelection) => {
    onSelect(newSelection === undefined ? null : newSelection);
  },[onSelect]);

  useEffect(() => {
    setUnits(materialOptions);
  },[materialOptions]);

  return (
    <Select
      allowClear
      placeholder='Select Units'
      value={value}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      open={open}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      dropdownRender={(menu) => (
        <div style={{ width: dropdownWidth, padding: '0px 5px' }}>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          Custom:
          <OnTraccrTextInput
            onChange={onNewUnitChange}
            value={newUnit}
            style={{ margin: '5px 0px' }}
          />
          <OnTraccrButton
            icon={ <PlusOutlined />}
            onClick={onAddClicked}
            title='Add'
            disabled={!newUnit}
          />
        </div>
      )}
    >
      {
        units.map((unit) => (
          <Select.Option key={unit} value={unit}>
            {unit}
          </Select.Option>
        ))
      }
    </Select>
  )
};

