import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { parseFormResponseToReadableFormat } from '../forms/ResponderHelpers';
import { getIdMap } from '../helpers/helpers';

export default function CertificationsTable({
  certifications,
  columns,
  isExpanded,
  customFieldColumns,
  isExpandable = true,
  entityType,
}) {
  const [expandedRowKeys, setExpandedRowKeys] = useState([]);
  const {
    [entityType]: {
      certificationCustomFieldTemplate,
    } = {},
  } = useSelector(
    (state) => state.certifications,
  );
  const { settings = {} } = useSelector((state) => state.settings.company);
  const projects = useSelector((state) => state.projects.projects);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);

  const rowExpandable = useCallback(() => (
    !!customFieldColumns?.length
  ), [customFieldColumns]);

  useEffect(() => {
    if (isExpanded) {
      setExpandedRowKeys(certifications.map(({ id }) => id));
    } else {
      setExpandedRowKeys([]);
    }
  }, [isExpanded, certifications]);

  const onExpand = useCallback((event, { id }) => {
    if (!event) {
      setExpandedRowKeys(expandedRowKeys.filter((key) => key !== id));
      return;
    }

    setExpandedRowKeys([...expandedRowKeys, id]);
  }, [expandedRowKeys]);

  const expandedRowRender = useCallback((record) => {
    const formResponses = record.formResponses ?? parseFormResponseToReadableFormat({
      templateSections: certificationCustomFieldTemplate?.sections ?? [],
      responseSections: record?.customData?.sections ?? [],
      projectIdMap,
      settings,
    });

    return (
      <Table
        columns={customFieldColumns}
        dataSource={[formResponses]}
        pagination={false}
        size="small"
        bordered
      />
    );
  }, [certificationCustomFieldTemplate, customFieldColumns, projectIdMap, settings]);

  const expandable = useMemo(() => ({
    defaultExpandAllRows: true,
    expandedRowKeys,
    onExpand,
    rowExpandable,
    expandedRowRender,
  }), [expandedRowKeys, onExpand, rowExpandable, expandedRowRender]);

  return (
    <Table
      columns={columns}
      dataSource={certifications}
      rowKey="id"
      pagination={false}
      expandable={isExpandable ? expandable : null}
      scroll={{ x: 'max-content' }}
    />
  );
}

CertificationsTable.propTypes = {
  certifications: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
  })),
  columns: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  isExpanded: PropTypes.bool.isRequired,
  customFieldColumns: PropTypes.arrayOf(PropTypes.shape({})),
  isExpandable: PropTypes.bool,
  entityType: PropTypes.string.isRequired,
};

CertificationsTable.defaultProps = {
  certifications: [],
  customFieldColumns: [],
  isExpandable: true,
};
