import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Col, Row, Typography } from 'antd';
import moment from 'moment';
import { DateTime } from 'luxon';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import { getPhaseCostcodeKey, getFormattedCurrency } from '../../forms/formHelpers';

/**
 * Gets the table header component
 * @param {boolean} isDisplay
 * @param {Function} onAddRow
 * @returns {React.ReactElement}
 */
export const getCostcodeDistributionHeader = (isDisplay, onAddRow) => (
  <Row justify='space-between' align='middle'>
    <Col span={5}>
      <Typography.Text
        style={{
          width: '100%',
          fontSize: 14,
          opacity: 0.85,
          color: '#000000',
        }}
      >
        Distribute Cost
      </Typography.Text>
    </Col>
    <Col span={4}>
      {!isDisplay &&
        <OnTraccrButton
          title='Add Row'
          icon={<PlusOutlined />}
          onClick={onAddRow}
        />
      }
    </Col>
  </Row>
);

/**
 * Creates phased-costcode id map, where the key is a concatenation of phaseId and costcodeId
 * @param {array} phasedCostcodes
 * @returns {object} phased-costcode map
 */
export const getPhasedCostcodeMap = (phasedCostcodes = []) => {
  const map = {};
  phasedCostcodes.forEach((entry) => {
    const { phaseId, costcodeId } = entry;
    const key = getPhaseCostcodeKey(phaseId, costcodeId);
    map[key] = { ...entry };
  });
  return map;
};

/**
 * Prepares invoice costcode distribution payload for POST request
 * @param {object} payload
 * @returns {object} refined payload
 */
export const prepareCostcodeDistributionPayload = (payload = {}) => {
  const refinedPayload = { ...payload, device: 'web' };
  if (refinedPayload.phaseId === 'unphased') {
    refinedPayload.phaseId = null;
  }
  // remove unnecessary properties:
  delete refinedPayload.costcodeName;
  delete refinedPayload.code;
  delete refinedPayload.phaseName;
  return refinedPayload;
};

/**
 * Returns true if the total cost distributed among costcodes is within the invoice amount,
 * false otherwise
 * @param {object} userFormInputs
 * @returns {boolean}
 */
export const distributedCostValid = (userFormInputs) => {
  const { invoiceAmount = 0, costcodeDistributions = [] } = userFormInputs || {};
  const totalAmtDistributed = costcodeDistributions
    .reduce((acc, { amount: distributionAmt = 0 }) => acc + distributionAmt, 0);
  return invoiceAmount >= totalAmtDistributed;
};

export const getValueFormsQuery = ({
  range,
  formId,
}) => {
  const [
    startMoment = moment().subtract(2, 'M').startOf('day'),
    endMoment = moment().endOf('day'),
  ] = range || [];
  const startCreationTime = startMoment.startOf('day').valueOf();
  const endCreationTime = endMoment.endOf('day').valueOf();
  const query = {
    types: ['PO', 'Sub-Contract'],
    startCreationTime,
    endCreationTime,
  };
  if (formId) query.formIds = [formId];
  return query;
};

export const getValueFormTitle = ({
  projectName,
  formName,
  number,
  date,
  formValue,
  type,
  t,
}) => {
  const spacer = ' --- ';
  let title = '';
  if (projectName) {
    title += `${t('Project')}: ${projectName}`;
  }
  if (formName) {
    title += spacer;
    title += `Form: ${formName}`;
  }
  if (number) {
    title += spacer;
    title += `Number: ${number}`;
  }
  if (date && typeof date === 'number') {
    title += spacer;
    title += `Date: ${DateTime.fromMillis(date).toLocaleString(DateTime.DATE_SHORT)}`;
  }
  if (formValue && typeof formValue === 'number') {
    title += spacer;
    title += `${type} Value: ${getFormattedCurrency(formValue)}`;
  }
  return title;
};

export const onPOCloseModal = ({
  formName,
  formId,
  onPOCloseClick,
  setPopoverVisible,
  isPO,
}) => (e) => {
  e.stopPropagation();

  if (setPopoverVisible) setPopoverVisible(false);

  const formType = isPO ? 'PO' : 'Sub-Contract';

  CustomConfirmModal({
    title: `Close ${formName}?`,
    content: (
      <div style={{ maxWidth: 500 }}>
        Finalizing and closing this {formType} form will result in the following:
        <br />
        <div style={{ textAlign: 'left', marginTop: 10 }}>
          <ul>
            <li>No further edits or resubmissions will be possible</li>
            <li>Invoices attached to the {formType} will be locked from editing</li>
            <li>Invoices can no longer be uploaded to the {formType}</li>
            <li>All active workflows (approvals, edit requests, triggered forms, etc.) will be removed</li>
          </ul>
        </div>
        <div>
          <b style={{ fontFamily: 'roboto-bold' }}>Warning:</b>
          <br />
          Removing costcodes during {formType} close will not unlink them from existing invoices. Please finalize existing invoices prior to closing the {formType}
        </div>
      </div>
    ),
    onOk: async () => {
      onPOCloseClick(formId);
    },
    cancelText: 'Back',
    okText: 'Continue',
    keyboard: false,
    style: { width: 500 },
  });
};
