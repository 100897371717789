import React from 'react';
import { Row, Select } from 'antd';

import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';
import WorkflowConfigureSourceSelector from './selectors/WorkflowConfigureSourceSelector';
import EmailSenderSelector from './selectors/EmailSenderSelector';

import { CURRENCIES } from '../forms.constants';

const paymentFields = [
  { key: 'email', text: 'Email', type: 'text' },
  { key: 'total', text: 'Total', type: 'text' },
];

const requiredFields = new Set(['email', 'total']);

const fieldRenderMap = {
  email: ({
    fieldMappings,
    sourceFieldMap,
    defaultValue,
    onFieldMappingsChange
  }) => {
    const {
      text = [],
      'dropdown - Customers': customers = [],
      'dropdown - Projects': projects = [],
    } = sourceFieldMap;
    const eligibleSourceFields = projects.concat(customers).concat(text);
    return (
      <WorkflowConfigureSourceSelector
        fieldMappings={fieldMappings}
        defaultValue={defaultValue}
        onFieldMappingsChange={onFieldMappingsChange}
        targetId='email'
        eligibleSourceFields={eligibleSourceFields}
      />
    );
  }
}
export default ({
  id,
  visible,
  onClose,
  onSubmit,
  sourceName,
  sourceSections = [],
  fieldMappings = {},
  onFieldMappingsChange,
  data = {},
  setDataMap,
}) => {
  const {
    currency = 'cad',
  } = data ?? {};

  const onCurrencyChange = (newSelected) => {
    setDataMap((dataMap) => {
      const {
        [id]: oldData = {},
      } = dataMap;
      return {
        ...dataMap,
        [id]: {
          ...oldData,
          currency: newSelected
        },
      };
    });
  };

  return (
    <WorkflowSimpleMappingConfigureDrawer
      visible={visible}
      onClose={onClose}
      onSubmit={onSubmit}
      sourceSections={sourceSections}
      fieldMappings={fieldMappings}
      onFieldMappingsChange={onFieldMappingsChange}
      fields={paymentFields}
      fieldRenderMap={fieldRenderMap}
      requiredFields={requiredFields}
      title={
        <>
        <EmailSenderSelector
          id={id}
          data={data}
          setDataMap={setDataMap}
        />
        <Row style={{ marginTop: 5 }}>
          Select Currency:
        </Row>
        <Row style={{ margin: '10px 0px' }}>
          <Select
            options={CURRENCIES}
            defaultValue={currency}
            onChange={onCurrencyChange}
          />
        </Row>
        <span>Configure which fields from this <b>{sourceName}</b> form are loaded into the payment form.</span>
        </>
      }
      targetHeader='Payment Form Data Field'
    />
  );
}
