import React, { useCallback, useMemo, useState } from 'react';
import { Button, Collapse, Divider, Drawer, Input, List, Row } from 'antd';
import {
  DesktopOutlined,
  MobileOutlined,
  ReadOutlined,
} from '@ant-design/icons';

const { Panel } = Collapse;

import TutorialList from './TutorialList';

import { includesTerm } from '../helpers/helpers';
import SupportService from '../support/state/support.service';
import { TUTORIAL_WATCH_PREFIX } from '../nux/nux.constants';

const guides = [
  {
    title: 'Web App Guide',
    type: 'Web',
  },
  {
    title: 'Mobile App Guide',
    type: 'Mobile',
  }
];

export default ({
  visible,
  onClose,
  tutorials = [],
  nux = new Set(),
}) => {
  const [searchStr, setSearchStr] = useState();

  const getGuide = useCallback((type) => () => {
    SupportService.getGuide(type)
  });

  const tutorialHasSearchStr = ({ video, searchStr }) => {
    const {title, description, audience, section} = video;
    if (includesTerm(title, searchStr)) return true;
    if (includesTerm(description, searchStr)) return true;
    if (includesTerm(section, searchStr)) return true;
    const audienceMatch = audience.some((audienceStr) => includesTerm(audienceStr, searchStr))
    if (audienceMatch) return true;
  };

  const tutorialsBySection = useMemo(() => {
    const tutorialSectionMap = {};
    tutorials.forEach((video) => {
      const { section, key } = video;

      if (nux.has(`${TUTORIAL_WATCH_PREFIX}${key}`)) video.watched = true;
      if (!(section in tutorialSectionMap)) tutorialSectionMap[section] = [];
      if (searchStr && !tutorialHasSearchStr({ video, searchStr })) return;

      tutorialSectionMap[section].push(video);
    });
    return tutorialSectionMap;
  },[tutorials, searchStr, nux]);

  const sectionsCollapse = useMemo(() => {
    const sectionPanels = [];

    for (const [section, sectionTutorials] of Object.entries(tutorialsBySection)) {
      const sectionPanel = (
        <Panel header={section} key={section}>
          <TutorialList
            tutorials={sectionTutorials}
          />
        </Panel>
      )
      sectionPanels.push(sectionPanel);
    }
    return (
      <Collapse>
        {sectionPanels}
      </Collapse>
    )
  }, [tutorialsBySection])

  const onSearchChange = useCallback(async (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setSearchStr(value);
  },[setSearchStr]);

  return (
    <Drawer
      title='Learning Center'
      visible={visible}
      onClose={onClose}
      width={500}
      bodyStyle={{
        padding: '0px 24px'
      }}
    >
      <Divider>User Guides</Divider>
        <List
          size='small'
          dataSource={guides}
          renderItem={(item) =>
            <List.Item key={item.title} actions={[
              <Button className='learning-center-button' shape='circle' icon={<ReadOutlined/>} 
                onClick={getGuide(item.type)}
              />
            ]}>
            <List.Item.Meta
              avatar={item.type === 'Web' ? <DesktopOutlined/> : <MobileOutlined/>}
              title={item.title}
            />
            </List.Item>
          }
        />
      <Divider>Tutorials</Divider>
      <Input.Search
        size='large'
        type='search'
        className='searchbar'
        placeholder='Search'
        allowClear
        onChange={onSearchChange}
      />
      <Row style={{ paddingTop: '20px' }}/>
      { sectionsCollapse }
    </Drawer>
  )
}