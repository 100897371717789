import React from 'react';
import { Row, Typography, Tooltip } from 'antd';
import { fileIconMap, getFileType } from '../../files/fileHelpers';

export default ({ file, onClick, small, location = '' }) => {
  const fileType = getFileType(file);
  const Icon = fileIconMap[fileType];
  const iconSize = small ? 14 : 28;
  return (
    <Row style={{
      padding: 2,
      height:'100%',
      backgroundColor:'white',
      cursor:onClick ? 'pointer' :'auto',
      }} align='middle' onClick={onClick}>
      <Row justify='center' style={{ width:'100%'}}>
        <Icon style={{ fontSize: iconSize }}/>
      </Row>
      <Row style={{ width:'100%'}}>
        <Tooltip placement='bottom' title={`${file.name}, ${location}`}>
          <Typography.Paragraph
            ellipsis={{ rows: small ? 1 : 2 }}
            style={{ fontSize: 10, textAlign:'center', width:'100%' }}>
            {file.name}
          </Typography.Paragraph>
        </Tooltip>
      </Row>
    </Row>
  );
}