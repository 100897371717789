import React from 'react';
import Permissions from '../auth/Permissions';
import sortByString, { getIdMap, getId } from '../helpers/helpers';
import UserListClock from './UserListClock';

export default {};

export const createPhaseMap = (phases, costcodes = []) => {
  const phaseMap = {};
  const costcodeMap = getIdMap(costcodes);
  const unphasedSet = new Set(costcodes.map(getId));

  phases.forEach((phase) => {
    if (!(phase.id in phaseMap)) {
      phaseMap[phase.id] = { ...phase, costcodes: [] };
    }
    const ourCC = phase.costcodeId in costcodeMap ? costcodeMap[phase.costcodeId] : {};
    phaseMap[phase.id].costcodes.push({
      ...ourCC,
      id: phase.costcodeId,
      hours: phase.hours,
      startDate: phase.startDate,
      endDate: phase.endDate,
    });
    unphasedSet.delete(phase.costcodeId);
  });
  const unphasedCC = Array.from(unphasedSet).map((cc) => costcodeMap[cc]);
  return { phaseMap, unphasedCC };
};

export const mergeUsersProjectsCostcodes = ({
  users = [],
  costcodes = [],
  projects = [],
  onUserClock,
  onTimeCardClick,
  firstDayOfRunningPayPeriod,
  roundingInterval,
  roundingSetting,
  roundingType,
  ownerId,
  unions: {
    locals = [],
    classes = [],
  } = {},
}) => {
  const projectMap = getIdMap(projects);
  const costcodeMap = getIdMap(costcodes);

  const localMap = getIdMap(locals);
  const classMap = getIdMap(classes);

  const newUsers = users.map((user) => {
    const userObj = {
      ...user,
      onUserClock,
      onTimeCardClick,
      firstDayOfRunningPayPeriod,
      roundingInterval,
      roundingSetting,
      roundingType,
      isOwner: user.id === ownerId,
      union: {},
    };
    const { classId } = user;

    if (classId) {
      userObj.union.classId = classId;
      const {
        [classId]: {
          localId,
        } = {},
      } = classMap;
      if (localId) {
        userObj.union.localId = localId;
        const {
          [localId]: {
            unionId,
          } = {},
        } = localMap;
        userObj.union.unionId = unionId;
      }
    }
    return userObj;
  });

  return {
    users: newUsers,
    projectMap,
    costcodeMap,
  };
};

export const hasPermissions = (user = {}) => {
  if (!user.id) return false;
  const formattedPosition = Permissions.formatPosition(user.position);
  return (Permissions.id === user.id && Permissions.has('USERS_SELF'))
  || Permissions.has(`USERS_${formattedPosition}`);
};

export const getDisplayColumns = ({
  userDivisions,
  divisions,
}) => {
  const cols = [
    {
      title: 'Name',
      dataIndex: 'name',
      width: 100,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      sorter: sortByString('name'),
    },
    {
      title: 'ID',
      dataIndex: 'employeeId',
      width: 50,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      sorter: sortByString('employeeId'),
    },
  ];
  if (Object.keys(divisions).length > 1) {
    cols.push({
      title: 'Divisions',
      dataIndex: 'id',
      width: 200,
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
      render: (id) => (
        userDivisions[id]?.map((divId) => divisions?.[divId]?.name)
          .filter((divName) => !!divName)
          .join(', ')
      ),
    });
  }
  cols.push({
    title: 'Position',
    dataIndex: 'position',
    width: 100,
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
    sorter: sortByString('position'),
  });
  return cols;
};
