import React from 'react';
import { Form, Row } from 'antd';

import BorderlessButton from '../../buttons/BorderlessButton';

export default ({
  title,
  value,
  onAlignmentChanged,
  alignments = [],
  style = {},
}) => (
  <Form.Item
    name={title}
    key={title}
    label={title}
    style={style}
  >
    <Row justify='space-between'>
      {
        alignments.map(({ alignment, Icon }) => (
          <BorderlessButton
            key={title + alignment}
            onClick={() => onAlignmentChanged(alignment)}
            iconNode={
              <Icon
                className={`pdf-designer-text-align-${value === alignment ? '' : 'un'}selected`}
              />
            }
            style={{ width: 'fit-content'}}
          />
        ))
      }
    </Row>
  </Form.Item>
);
