import React from 'react';
import '@ant-design/compatible/assets/index.css';
import { Select } from 'antd';
import { withTranslation } from 'react-i18next';

import ProfileAvatar from '../common/ProfileAvatar';
import store from '../state/store';
import { search } from '../helpers/helpers';

const { Option } = Select;

const getTagView = (item) => <div style={{fontFamily:'roboto-regular'}}>{item.name}</div>;
const getUserView = (item) =>  <ProfileAvatar key={item.id} {...{...item,role:item.position}} style={{ marginTop:2, marginBottom:2,}}/>;

class TeamTransfer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedKeys: [],
      disabled: false,
      users:[],
      available:[]
    };

    this.onChange = this.handleChange.bind(this);
    this.onDeselect = this.handleDeselect.bind(this);
    this.onSearch = this.handleSearch.bind(this);
  }

  componentDidMount() {
    const {
      users,
      projects,
      teams,
    } = store.getState();
    const {
      initialValue = [],
      name,
    } = this.props;

    this.isProject = name === 'projects';
    this.users = users.users;
    this.projects = projects.projects;
    this.teams = teams.teams;
    this.ourArr = [];
    this.setData();

    this.ourMap = {};
    this.ourArr.forEach(item => {
      if(!item.active) return;
      this.ourMap[item.name] = item;
    });

    const relevant = this.ourArr.filter((item) => initialValue.indexOf(item.name) < 0);
    this.setState({
      available:relevant,
    });
    this.relevant = relevant;
  }

  setData() {
    const {
      name,
      t,
    } = this.props;

    switch(name) {
      case 'projects':
        this.ourArr = this.projects;
        this.placeholder = t('Project', {count: 2}).toLowerCase();
        this.getView = getTagView;
        break;
      case 'teams':
        this.ourArr = this.teams;
        this.placeholder = 'teams';
        this.getView = getTagView;
        break;
      case 'users':
      case 'supervisors':
      case 'workers':
        this.ourArr = this.users;
        this.placeholder = 'team members';
        this.getView = getUserView;
        break;
      default:
        console.error(`Invalid TeamTransfer type ${name} found`);
    }
    this.ourArr = this.ourArr.filter((item) => item.active)
  }

  handleChange(selected) {
    this.props.onChange(selected);
    const filtered = this.relevant.filter((item) => selected.indexOf(item.name) < 0 );
    this.setState({
      available:filtered,
    });
    this.relevant = filtered;
  }

  handleDeselect(deselected) {
    if (!(deselected in this.ourMap)) return;
    const ourItem = this.ourMap[deselected];
    if (this.props.onDeselect) this.props.onDeselect(deselected)
    const relevant = this.relevant.concat([ourItem]);
    this.setState({
      available:relevant,
    });
    this.relevant = relevant;
  }

  handleSearch(value) {
    this.setState({
      available:search({
        data:this.relevant,
        value,
        getField:(user) => user.name,
      }),
    });
  }

  render() {
    const {
      name,
      initialValue = [],
    } = this.props;

    return (
      <Select
        className='OnTraccrSelect'
        key={name}
        mode='multiple'
        style={{ width: '100%' }}
        placeholder={`Select ${this.placeholder}`}
        onChange={this.onChange}
        optionLabelProp='label'
        onDeselect={this.onDeselect}
        onSearch={this.onSearch}
        defaultValue={initialValue}
      >
        {this.state.available.map((item) =>
          <Option key={item.id} value={item.name} label={item.name}>
            {
              this.getView(item)
            }

        </Option>)}
      </Select>
    );
  }
}

export default withTranslation()(TeamTransfer);
