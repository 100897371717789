export default {};

export const NONE_EQUIPMENT_TYPE = -1;

export const filterData = ({
  labels = [],
  statuses = {},
  equipmentTypes = [],
}) => {
  const filters = [[
    [
      {
        title: 'Archive',
        key: 'active',
        children: [
          {
            title: 'Active',
            key: 1,
          },
          {
            title: 'Archived',
            key: 0,
          },
        ],
      },
    ],
    [
      {
        title: 'Availability',
        key: 'availability',
        children: [
          {
            title: 'Available',
            key: 1,
          },
          {
            title: 'Checked Out',
            key: 0,
          },
        ],
      },
    ],
    [
      {
        title: 'Status',
        key: 'status',
        children: Object.values(statuses).map(({ status, id }) => ({
          title: status,
          key: id,
        })),
      },
    ],
  ]];

  const additionalFilters = [];

  if (labels.length > 0) {
    additionalFilters.push(
      [
        {
          title: 'Labels',
          key: 'labels',
          children: labels.map(({ title, id }) => ({
            title,
            key: id,
          })),
        },
      ],
    );
  }

  additionalFilters.push([
    {
      title: 'Equipment Type',
      key: 'equipmentTypeId',
      children: equipmentTypes.map(({ name, id }) => ({
        title: name,
        key: id,
      })).filter((type) => !type.isDefault),
    },
  ]);

  filters.push(additionalFilters);
  return filters;
};
