/* eslint-disable react/sort-comp */
import React from 'react';
import moment from 'moment';
import { notification } from 'antd';
import { FileExcelOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { DateTime } from 'luxon';

import config from '../config';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import CardGrid from '../common/cardGrid/cardGrid';
import Filter from '../common/Filter';
import FilterDropdown from '../common/FilterDropdown';

import Permissions from '../auth/Permissions';

import ProjectAddView from './projectAddView';
import ProjectItemView from './ProjectItemView';
import ProjectUserList from './ProjectUserList';
import exportExcel from './projectExport';
import { displayColumns, FIELDS_BY_EDIT_STEP } from './projects.constants';

import {
  createProject,
  archiveProject,
  updateProject,
  getProjects,
  deleteProject,
  getProjectEquipment,
} from './state/projects.actions';
import {
  mergeProjectsAndCostcodes,
  generateCostcodePayload,
  condensePhasesAndCostcodes,
  generateEquipmentPayload,
  formatContractExpiration,
  prepareProjectPayload,
} from './projectHelpers';
import {
  NONE_PROJECT_TYPE,
  defaultFilters,
  getFilterData,
} from './projectFilters';

import {
  getAllCostCodes,
  getAllPhases,
} from '../costcodes/state/costcodes.actions';

import { setMenu } from '../menu/state/menu.actions';
import TimeTracking from '../state/timeTracking';

import Clock from '../clock/Clock';

import UserClockInModal from '../users/UserClockInModal';

import { projectCardDimensions } from '../constants/Styles';
import sortByString, { getIdMap } from '../helpers/helpers';

import { createNuxEntry } from '../nux/state/nux.actions';
import { getEquipment } from '../equipment/state/equipment.actions';
import { getCompanyCustomizationTabs, getCompanyImageURL } from '../settings/state/settings.actions';
import { decoratePayloadWithLabels } from '../helpers/labels';
import { getCustomerLabels, getCustomers } from '../contacts/customers/state/customers.actions';
import { getBoards } from '../boards/state/boards.actions';
import { formatCustomFieldCreatePayload } from '../helpers/costcodeHelpers';

class ProjectList extends React.Component {
  constructor(props) {
    super(props);

    this.cardGridRef = React.createRef();

    const {
      clockin,
      clockout,
      switchTask,
      takeBreak,
      endBreak,
      manualEntry,
    } = props;

    this.clock = new Clock({
      clockin,
      clockout,
      switchTask,
      takeBreak,
      endBreak,
      manualEntry,
    });

    this.filter = this.getDefaultProjectFilters();
    this.state = {
      sortActive: window.localStorage.getItem('projectsSortText') || 'Code: Ascending',
      activeFilters: this.filter.activeFilters,
      showUserList: false,
      showFiles: false,
      fullProjects: [],
      isListView: window.localStorage.getItem('projectViewType') === 'true',
      errors: {},
      customFields: [],
    };
    this.onValuesChanged = this.valuesChanged.bind(this);
    this.onShowFilesChanged = this.toggleShowFiles.bind(this);
    this.onViewTypeChanged = this.onViewTypeChanged.bind(this);
    this.values = {};

    this.currentSort = JSON.parse(window.localStorage.getItem('projectsSortActive') ?? null);
    this.sortByName = this.sortName.bind(this);
    this.sortByCode = this.sortCode.bind(this);

    this.sortOptions = [{
      title: 'Code: Ascending',
      icon: 'sort-ascending',
      action: () => this.sortByCode(true),
    }, {
      title: 'Code: Descending',
      icon: 'sort-ascending',
      action: () => this.sortByCode(false),
    }, {
      title: 'Name: A to Z',
      icon: 'sort-ascending',
      action: () => this.sortName(true),
    }, {
      title: 'Name: Z to A',
      icon: 'sort-descending',
      action: () => this.sortName(false),
    }];

    this.onFilter = this.filterProjects.bind(this);
    this.onArchive = this.archiveProject.bind(this);
    this.onDelete = this.deleteProject.bind(this);
    this.onEdit = this.editProject.bind(this);
    this.onUserStatClick = this.showUserStats.bind(this);
    this.onEstimatedCostClick = this.showEstimatedCost.bind(this);
    this.onRunningCostClick = this.showRunningCost.bind(this);

    this.onUserListClose = this.closeUserList.bind(this);
    this.onUserClick = this.userClicked.bind(this);
    this.onUserClockClose = this.closeUserClock.bind(this);

    this.onProjectAdd = this.addProject.bind(this);
    this.onValidateCustomFields = this.validateCustomFields.bind(this);
  }

  getDefaultProjectFilters() {
    const {
      projectTypes,
      projects = [],
      type,
    } = this.props;

    const defaultProjectFilters = { ...defaultFilters };
    let defaultProjectTypeFilters = [
      ...projectTypes.map((projectType) => projectType.id),
      NONE_PROJECT_TYPE,
    ];

    if (type) {
      defaultProjectTypeFilters = [type];
    }

    defaultProjectFilters.projectTypeId = defaultProjectTypeFilters;

    return new Filter({
      data: projects,
      defaultFilters: defaultProjectFilters,
      filterManipulators: {
        projectTypeId: (project) => {
          if (project.projectTypeId) return project.projectTypeId;
          return -1;
        },
      },
    });
  }

  componentDidUpdate(prevProps) {
    const {
      costcodes: prevCC,
      users: prevUsers,
      phases: prevPhases,
      selectedDivisions: prevSelected,
      projects: prevProjects,
      equipment: prevEquipment,
      projectTypes: prevProjectTypes,
      type: prevType,
      timeEntryUserMap: prevTimeEntries,
    } = prevProps;
    const {
      costcodes,
      projects,
      users,
      positions = {},
      teams,
      phases = [],
      selectedDivisions = new Set(),
      projectEquipment,
      equipment,
      company: {
        settings = {},
      } = {},
      projectTypes,
      type,
      timeEntryUserMap,
      roundingPolicyPositionMap,
    } = this.props;

    this.clock.users = users;
    this.clock.settings = settings;
    this.clock.positions = positions;
    this.clock.roundingPolicyPositionMap = roundingPolicyPositionMap;

    if (
      this.shouldReload
      || (costcodes.length > 0 && costcodes !== prevCC)
      || (users.length > 0 && users !== prevUsers)
      || (projects.length > 0 && projects !== prevProjects)
      || (phases.length > 0 && prevPhases !== phases)
      || (equipment.length > 0 && prevEquipment !== equipment)
      || (selectedDivisions.size > 0 && selectedDivisions !== prevSelected)
      || (projectTypes.length > 0 && projectTypes !== prevProjectTypes)
      || timeEntryUserMap !== prevTimeEntries
      || type !== prevType
    ) {
      this.shouldReload = false;

      let divProjects = projects;
      if (config.showDivisions) {
        divProjects = projects.filter((project) => selectedDivisions.has(project.divisionId));
      }
      const fullProjects = mergeProjectsAndCostcodes({
        timeEntryUserMap,
        projects: divProjects,
        costcodes,
        users,
        teams,
        phases,
        projectEquipment,
        equipment,
      }).map((proj) => ({
        ...proj,
        onUserStatClick: this.onUserStatClick,
        onEstimatedCostClick: this.onEstimatedCostClick,
        onRunningCostClick: this.onRunningCostClick,
      }));

      this.projectMap = getIdMap(fullProjects);
      this.setState({
        fullProjects,
      }, () => {
        if (this.currentSort) {
          const [ sortType, sortAscending ] = this.currentSort;
          const sortFunc = sortType === 'name' ? this.sortByName : this.sortByCode;
          sortFunc(sortAscending);
        } else {
          this.sortByCode(true);
        }

        this.openProjectFromLocationState(prevProps);
      });
    }

    if (
      (projectTypes.length > 0 && projectTypes !== prevProjectTypes)
      || type !== prevType
    ) {
      this.filter = this.getDefaultProjectFilters();
      this.setState({
        activeFilters: this.filter.activeFilters,
      });
      this.applyFilters();
    }
  }

  async componentDidMount() {
    const {
      getCompanyCustomizationTabs,
      getCompanyImageURL,
      getBoards,
      getTimeEntries,
    } = this.props;

    await Promise.all([
      getCompanyCustomizationTabs(),
      getCompanyImageURL(),
      getBoards(),
      getTimeEntries({
        startTime: DateTime.now().startOf('day').toMillis(),
        endTime: DateTime.now().endOf('day').toMillis(),
        getRunningTasks: true,
      }),
    ]);
  }

  async reloadData() {
    const {
      getProjects,
      getAllCostCodes,
      getAllPhases,
      getEquipment,
      getProjectEquipment,
      getCustomers,
      getCustomerLabels,
    } = this.props;
    await Promise.all([
      getProjects(),
      getAllCostCodes(),
      getAllPhases(),
      getProjectEquipment(true),
      getEquipment(true),
      getCustomers(true),
      getCustomerLabels(true),
    ]);
    this.shouldReload = true;
  }

  openProjectFromLocationState() {
    const {
      location: {
        state: {
          targetId: locationStateProjectId,
        } = {},
      } = {},
      history,
      pathname,
    } = this.props;

    const {
      fullProjects = [],
    } = this.state;

    if (!fullProjects) return;
    const locationStateProject = fullProjects.find((project) => project.id === locationStateProjectId);
    if (locationStateProject && this.cardGridRef) {
      clearTimeout(this.openTimer);
      this.openTimer = setTimeout(() => {
        this.cardGridRef.displayItem(locationStateProject);
      }, 250);
      history?.replace(pathname); // Clear location state
    }
  }

  valuesChanged(key, value) {
    this.values[key] = value;
  }

  onViewTypeChanged(checked) {
    window.localStorage.setItem('projectViewType', checked);
    this.setState({ isListView: checked });
  }

  toggleShowFiles(e) {
    const {
      target: {
        checked: showFiles,
      } = {},
    } = e;
    this.setState({
      showFiles,
    });
  }

  async addProject(values) {
    const {
      createProject,
      divisions = {},
    } = this.props;
    const {
      showFiles,
      customFields,
    } = this.state;

    const {
      payload,
      ourFileMap,
      divisionName,
    } = prepareProjectPayload({
      formValues: values,
      storedValues: this.values,
      showFiles,
      divisions,
      customFields,
    });

    const success = await createProject(payload, ourFileMap, divisionName);

    if (success) {
      this.reloadData(); // Sledgehammer
      this.values = {};
    }
    return success;
  }

  async editProject(id, details) {
    const {
      phases: propPhases = [],
      costcodes: propCostcodes = [],
      t,
    } = this.props;
    const {
      fullProjects = [],
      customFields = [],
    } = this.state;
    const {
      costcodes,
      phases,
      geofence,
      budget = {},
      equipment = [],
      equipmentSchedule = {},
      projectTypeId,
    } = this.values;

    const relevantProjects = fullProjects.filter((project) => project.id === id);
    if (relevantProjects.length !== 1) {
      return console.error(`Updating for project with id ${id}. Project not found in props`);
    }

    const oldPhases = propPhases.filter(({ projectId }) => projectId === id);
    const oldPhaseCondensed = condensePhasesAndCostcodes(oldPhases, propCostcodes);
    const ourProject = relevantProjects[0];

    const data = generateCostcodePayload({
      newCostcodes: costcodes,
      oldCostcodes: ourProject.projectCostcodes,
      newPhases: phases,
      oldPhases: oldPhaseCondensed,
    });

    const equipmentPayload = generateEquipmentPayload({
      newEquipment: equipment,
      oldEquipment: ourProject.equipment,
      equipmentSchedule,
    });

    const ourDetails = formatContractExpiration({
      ...details,
      ...budget,
      projectTypeId,
    });
    const projectDetails = {};
    Object.keys(ourDetails).forEach((key) => {
      if (ourDetails[key] !== undefined) {
        let existingKey = key;
        if (key === 'estimatedLabourCost') {
          existingKey = 'labourCost';
        } else if (key === 'estimatedLabourHours') {
          existingKey = 'labourHours';
        }
        if (ourProject[existingKey] !== ourDetails[key]) {
          let val = ourDetails[key];
          if (key === 'billingContact' && val === '') val = null;
          projectDetails[key] = val && typeof val === 'string'
            ? val.trim()
            : val;
        }
      } else if (key === 'customerId' && ourProject.customerId !== undefined) {
        // This will happen if user clears the customer field
        projectDetails.customerId = null;
      }
    });

    if (projectDetails.materialDiscount) {
      projectDetails.materialDiscount /= 100;
    }

    if (projectDetails.customer) {
      delete projectDetails.customerId;
      delete projectDetails.customer.id;
      projectDetails.customer = decoratePayloadWithLabels(projectDetails.customer);
    } else {
      delete projectDetails.customer;
    }

    if (ourDetails.dateRange && ourDetails.dateRange.length === 2) {
      delete projectDetails.dateRange;
      const [ startDate, endDate ] = ourDetails.dateRange;
      projectDetails.startDate = moment.isMoment(startDate) ? startDate.format('YYYY-MM-DD') : null;
      projectDetails.endDate = moment.isMoment(endDate) ? endDate.format('YYYY-MM-DD') : null;
    }

    if (geofence) {
      projectDetails.geofence = geofence;
    } else if ('geofence' in this.values) {
      // Someone removed
      projectDetails.geofence = null;
    }

    const finalPayload = {};
    if (Object.keys(projectDetails).length > 0) {
      finalPayload.details = projectDetails;
    }
    Object.keys(data).forEach((key) => {
      if (data[key].length > 0) {
        finalPayload[key] = data[key];
      }
    });

    Object.keys(equipmentPayload).forEach((key) => {
      if (equipmentPayload[key].length > 0) {
        finalPayload[key] = equipmentPayload[key];
      }
    });

    const detailsPayload = formatCustomFieldCreatePayload({
      payload: finalPayload?.details,
      customFields,
    });

    finalPayload.details = detailsPayload;

    const success = await this.props.updateProject(id, finalPayload);
    if (success) {
      const notificationKey = `ProjectSaveNotification-${id}`;
      notification.open({
        key: notificationKey,
        message: `Saving ${t('Project')}`,
        description:
          `Click here to save a snapshot of the previous ${t('Project').toLowerCase()} details`,
        onClick: () => {
          exportExcel({
            ...ourProject,
            t,
          });
          notification.close(notificationKey);
        },
        duration: 5,
        icon: <FileExcelOutlined/>,
      });
      this.reloadData(); // Sledgehammer
      this.values = {};
      return true;
    };
    return false;
  }

  archiveProject(project) {
    return new Promise((resolve) => {
      const archiveFunc = this.props.archiveProject;
      const mode = project.active ? 'Archive' : 'Activate';
      CustomConfirmModal({
        title: `${mode} ${this.props.t('Project').toLowerCase()} ${project.name}?`,
        okText: mode,
        cancelText: 'Cancel',
        async onOk() {
          resolve(archiveFunc(project.id, !project.active));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  deleteProject(project) {
    return new Promise((resolve) => {
      const {
        deleteProject,
        t,
      } = this.props;
      CustomConfirmModal({
        title: `Permanently delete ${t('Project').toLowerCase()} ${project.name}?`,
        okText: 'Delete',
        cancelText: 'Cancel',
        async onOk() {
          resolve(deleteProject(project.id));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  getSort() {
    return {
      sortOptions: this.sortOptions,
      sortActive: this.state.sortActive,
    };
  }

  sortName(ascending) {
    const { fullProjects = [] } = this.state;
    const sortTitle = this.sortOptions[ascending ? 2 : 3].title;
    const currentSort = ['name', ascending];
    this.currentSort = currentSort;
    this.filter.data = [...fullProjects];
    this.filter.data.sort((a, b) => (ascending ? 1 : -1) * sortByString('name')(a, b));
    this.setState({
      sortActive: sortTitle,
    });
    this.applyFilters();
    window.localStorage.setItem('projectsSortText', sortTitle);
    window.localStorage.setItem('projectsSortActive', JSON.stringify(currentSort));
  }

  sortCode(ascending) {
    const { fullProjects = [] } = this.state;
    const sortTitle = this.sortOptions[ascending ? 0 : 1].title;
    const currentSort = ['code', ascending];
    this.currentSort = currentSort;
    this.filter.data = [...fullProjects];
    this.filter.data.sort((a, b) => {
      if (isNaN(a.number)) return ascending ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER;
      if (isNaN(b.number)) return ascending ? Number.MIN_SAFE_INTEGER : Number.MAX_SAFE_INTEGER;
      return (a.number - b.number) * (ascending ? 1 : -1);
    });
    this.setState({
      sortActive: sortTitle,
    });
    this.applyFilters();
    window.localStorage.setItem('projectsSortText', sortTitle);
    window.localStorage.setItem('projectsSortActive', JSON.stringify(currentSort));
  }

  getFilter() {
    const {
      activeFilters = {},
    } = this.state;
    const {
      projectTypes = [],
      type,
      t,
    } = this.props;

    // Add none option to filters
    const projectTypesWithNone = [...projectTypes];
    projectTypesWithNone.unshift({
      id: NONE_PROJECT_TYPE,
      name: 'None',
    });

    const filterData = getFilterData({
      projectTypes: projectTypesWithNone,
      showProjectTypeFilter: !type,
      t,
    });

    return <FilterDropdown
      filters={filterData}
      activeFilters={activeFilters}
      onFilter={this.onFilter}
      style={{ width: 350 }}
    />;
  }

  filterProjects(checkedFilters, filterCategory) {
    this.filter.setFilter(checkedFilters, filterCategory);
    this.applyFilters();
  }

  applyFilters() {
    this.setState({
      projects: this.filter.applyFilters(),
      filterIsActive: this.filter.filterIsAtDefault(),
      activeFilters: this.filter.activeFilters,
    });
  }

  showEstimatedCost(projectId) {
    if (this.cardGridRef) {
      this.cardGridRef.displayItem({
        ...this.projectMap[projectId],
        activeTab: '3',
        currentStep: 2,
      });
    }
  }

  showRunningCost() {
    this.props.history.push('/reporting');
    this.props.setMenu(['reporting']);
  }

  showUserStats(projectId) {
    this.setState({
      showUserList: true,
      selectedProject: this.projectMap[projectId],
    });
  }

  closeUserList() {
    this.setState({
      showUserList: false,
    });
  }

  userClicked(user) {
    this.setState({
      clockInUser: user,
      showClockIn: true,
    });
    this.clock.setUser(user);
  }

  closeUserClock() {
    this.setState({
      showClockIn: false,
    });
    setTimeout(() => {
      this.setState({
        clockInUser: null,
      });
      this.clock.setUser(null);
    }, 250);
  }

  validateCustomFields(currentStep, values) {
    if (currentStep !== 0) return;

    const { customFields } = this.state;
    const payload = formatCustomFieldCreatePayload({
      payload: values,
      customFields,
    });

    if (payload?.error || !payload) {
      this.setState({
        errors: payload?.errorMap,
      });

      throw new Error('Validation error with custom fields');
    }

    this.setState({
      errors: {},
    });
  }

  render() {
    const {
      company: {
        settings = {},
        companyImageURL = '',
      } = {},
      users,
      positionNames,
      approverPositions = new Set(),
      projects: propProjects = [],
      createNuxEntry,
      nux = new Set(),
      history,
      divisions = {},
      cardLinks,
      tabs,
      t,
      projectTypes,
      type,
    }  = this.props;

    const {
      projects = [],
      filterIsActive,
      showUserList,
      selectedProject,
      clockInUser = {},
      showClockIn,
      showFiles,
      errors = {},
      customFields = [],
    } = this.state;


    const clockInUserId = clockInUser ? clockInUser.id : '';
    const sortData = this.getSort();

    const globalCodes = this.props.costcodes.filter((cc) => cc.type === 'global');

    return (
      <div style={{ width: '100%', height: '100%' }}>
        <CardGrid
          onRef={(ref) => {
            this.cardGridRef = ref;
            this.openProjectFromLocationState();
          }}
          dataSource={projects}
          itemView={ProjectItemView}
          itemDimensions={projectCardDimensions}
          displayColumns={displayColumns}
          onToggleListViewSwitch
          isListView={this.state.isListView}
          onViewTypeChanged={this.onViewTypeChanged.bind(this)}
          colCount={4}
          search={{
            getField: (project) => project.number
              ? `${project.number} ${project.name}`
              : project.name,
          }}
          add={Permissions.has('PROJECTS_WRITE') ? {
            text: `Add a ${t('Project')}`,
            title: `Enter ${t('Project')} Details`,
            formView:(form,formProps) =>
              <ProjectAddView
                key={formProps.id}
                {...form}
                {...formProps}
                costcodes={globalCodes}
                settings={settings}
                users={users}
                positionNames={positionNames}
                approverPositions={approverPositions}
                projects={propProjects}
                projectTypes={projectTypes}
                onValuesChanged={this.onValuesChanged}
                createNuxEntry={createNuxEntry}
                nux={nux}
                showFiles={showFiles}
                onShowFilesChanged={this.onShowFilesChanged}
                divisions={divisions}
                tabs={tabs}
                companyImageURL={companyImageURL}
                type={type}
                errors={errors}
                customFields={customFields}
                setCustomFields={(fields) => this.setState({ customFields: fields })}
              />,
            width: 1400,
            onClose: this.onProjectAdd,
            customValidator: this.onValidateCustomFields,
          } : null}
          edit={{
            title: `Edit ${t('Project')} Details`,
            push: false,
            formView: (form,formProps) =>
              <ProjectAddView
                key={formProps.id}
                {...form}
                {...formProps}
                costcodes={globalCodes}
                settings={settings}
                users={users}
                projects={propProjects}
                projectTypes={projectTypes}
                approverPositions={approverPositions}
                positionNames={positionNames}
                onValuesChanged={this.onValuesChanged}
                createNuxEntry={createNuxEntry}
                nux={nux}
                history={history}
                divisions={divisions}
                cardLinks={cardLinks}
                tabs={tabs}
                companyImageURL={companyImageURL}
                type={type}
                errors={errors}
                customFields={customFields}
                setCustomFields={(fields) => this.setState({ customFields: fields })}
              />,
            width: 1400,
            className: 'project-edit-drawer',
            onClose: this.onEdit,
            canEdit: () => Permissions.has('PROJECTS_WRITE'),
            canArchive: () => Permissions.has('PROJECTS_WRITE'),
            canDelete: () => Permissions.has('PROJECTS_WRITE'),
            validateSteps: FIELDS_BY_EDIT_STEP,
            customValidator: this.onValidateCustomFields,
          }}
          onArchive={this.onArchive}
          onDelete={this.onDelete}
          sort={sortData}
          filter={this.getFilter()}
          filterActive={filterIsActive}
        />
        <ProjectUserList
          visible={showUserList}
          onClose={this.onUserListClose}
          project={selectedProject}
          onUserClick={this.onUserClick}
        />
        <UserClockInModal
          key={clockInUserId}
          visible={showClockIn}
          user={clockInUser}
          costcodes={this.props.costcodes}
          projects={this.props.projects}
          unions={this.props.unions}
          onCloseClicked={this.onUserClockClose}
          onClockIn={this.clock.onClockIn}
          onClockOut={this.clock.onClockOut}
          onSwitch={this.clock.onSwitch}
          onBreakStart={this.clock.onBreakStart}
          onBreakEnd={this.clock.onBreakEnd}
        />
      </div>
    );
  }
}

export default withTranslation()(
  connect((state, ownProps) => {
    return { ...ownProps,
      projects: state.projects.projects,
      projectEquipment: state.equipment.projectEquipment,
      costcodes: state.costcodes.costcodes,
      users: state.users.users,
      positions: state.settings.positions,
      teams: state.teams.teams,
      company: state.settings.company,
      phases: state.costcodes.phases,
      positionNames: state.settings.positionNames,
      approverPositions: state.settings.approverPositions,
      nux: state.nux.nux,
      selectedDivisions: state.settings.selectedDivisions,
      divisions: state.settings.divisions,
      equipment: state.equipment.equipment,
      unions: state.unions,
      cardLinks: state.boards.cardLinks,
      tabs: state.settings.tabs,
      projectTypes: state.projects.projectTypes,
      timeEntryUserMap: state.timeTracking.timeEntryUserMap,
      roundingPolicyPositionMap: state.settings.roundingPolicyPositionMap,
    };
  }, {
    createProject,
    archiveProject,
    updateProject,
    deleteProject,
    getProjects,
    getAllCostCodes,
    getAllPhases,
    setMenu,
    clockin: TimeTracking.clockIn,
    clockout: TimeTracking.clockOut,
    switchTask: TimeTracking.switchTask,
    takeBreak: TimeTracking.startBreak,
    endBreak: TimeTracking.endBreak,
    manualEntry: TimeTracking.manualEntry,
    createNuxEntry,
    getProjectEquipment,
    getEquipment,
    getCompanyCustomizationTabs,
    getCompanyImageURL,
    getCustomers,
    getCustomerLabels,
    getBoards,
    getTimeEntries: TimeTracking.getTimeEntries,
  })(withRouter(ProjectList)),
);
