import React from 'react';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';

import TimeCard from './TimeCard/TimeCard';

export default function TimeCardRows({
  width,
  days = {},
  onClick,
  onEditClicked,
  isApprovals,
  userId,
}) {
  const {
    roundingInterval,
    roundingType,
    roundingSetting,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          settings: {
            roundingInterval: roundingIntervalSetting,
            roundingType: roundingTypeSetting,
            roundingSetting,
          } = {},
        } = {},
      } = {},
    } = state;
    return {
      roundingInterval: roundingIntervalSetting || 1,
      roundingType: roundingTypeSetting,
      roundingSetting,
    }
  });
  // the magic underneath dynamically resizes the grid (184 << width of timecard + a bit extra for margins)
  const ITEMS_PER_ROW = Math.floor(width/184)
  const rows = []
  const dayArray = Object.keys(days)
  const numRows = Math.floor(dayArray.length/ITEMS_PER_ROW) + 1
  for (let r = 0; r < numRows; r += 1) {
    const rowItems = dayArray.slice(r*ITEMS_PER_ROW,r*ITEMS_PER_ROW+ITEMS_PER_ROW)
    const xtraColumns = []
    for (let c = 0; c < ITEMS_PER_ROW - rowItems.length; c += 1){
      // phantom columns with timecard dimensions
      xtraColumns.push(<Col key={c} style={{ width: '11rem' }}></Col>);
    }
    rows.push(
      <Row
        key={r}
        justify='space-between'
        style={{ marginBottom: '15px' }}
      >
        {rowItems.map((day) =>
          <Col key={day}>
            <TimeCard
              key={day.date}
              day={days[day]}
              onClick={() => onClick(day)}
              onEditClicked={() => onEditClicked(day)}
              roundingInterval={roundingInterval}
              roundingType={roundingType}
              roundingSetting={roundingSetting}
              isApprovals={isApprovals}
              userId={userId}
            />
          </Col>) }
        {xtraColumns}
      </Row>
    );
  }
  return (
    <>
      {rows}
    </>
  );
}
