import React from 'react';

import NuxDetailPane from '../NuxDetailPane';
import pin from '../../assets/images/NUX/pin.png';
import tabletMode from '../../assets/images/NUX/tabletMode.png';

const pinDesc = `In order for users to log in to
Tablet Mode, they will need to be assigned a 4-digit PIN.
This PIN can be assigned directly in the user's profile`;

const accountsDesc = `In order for the company to use Tablet Mode,
special Tablet Mode accounts need to be created. These are the accounts
the site supervisor would log the tablet into to open the Tablet Mode experience`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 99px)',
      padding:'0px 40px'
    }}
    rows={[{
      title:'4-Digit PINs',
      description:pinDesc,
      cols:[0,12,12],
      image:{
        src:pin,
        style:{
          height:40,
          width:245,
        }
      },
    },{
      title:'Tablet Mode Account(s)',
      description:accountsDesc,
      cols:[0,16,8],
      image:{
        src:tabletMode,
        style:{
          height:125,
          width:179,
        }
      },
    }]}
  />
);
