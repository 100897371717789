import React, { useCallback } from  'react';
import { Row, Col } from 'antd';

import DebouncedPicker from './DebouncedPicker';

export default ({
  onDrawOptionChanged,
  drawOptions = {},
  style = {},
}) => {
  const {
    headerOptions = {},
  } = drawOptions;
  const onHeaderHeightChanged = useCallback(({ headerHeight }) => {
    const {
      headerOptions: oldHeaderOptions = {},
    } = drawOptions;
    onDrawOptionChanged({
      ...drawOptions,
      headerOptions: {
        ...oldHeaderOptions,
        headerHeight,
      },
    });
  },[drawOptions]);

  return (
    <Row  justify='space-between' gutter={10}>
      <Col span={12}>
        <DebouncedPicker
          optionKey='headerHeight'
          title='Header Height'
          drawOptions={headerOptions}
          style={style}
          max={500}
          min={10}
          onDrawOptionChanged={onHeaderHeightChanged}
        />
      </Col>
      <Col span={12}>
        <DebouncedPicker
          optionKey='rowHeight'
          title='Row Height'
          drawOptions={drawOptions}
          style={style}
          max={500}
          min={10}
          onDrawOptionChanged={onDrawOptionChanged}
        />
      </Col>
    </Row>
  );
}
