import React from 'react';
import { Row, Col, Typography } from 'antd';
import PropTypes from 'prop-types';
import { EditOutlined } from '@ant-design/icons';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderedComponent from '../common/containers/BorderedContainer';

import DashboardWidgetDeleteButton from './DashboardWidgetDeleteButton';

import colors from '../constants/Colors';
import DashboardWidgetEditButton from './DashboardWidgetEditButton';

export default function DashboardComponent({
  children,
  containerStyle = {},
  style,
  title,
  subtitle,
  titleStyle = {},
  subtitleStyle = {},
  onEdit,
  onDelete,
  onConfig,
  showConfig,
  configContent,
  darkMode = false,
  headerStyle = {},
  headerContent,
  isEdit,
}) {
  return (
    <BorderedComponent
      style={{
        ...style,
        ...isEdit
        ? { borderStyle: 'dashed' }
        : {}
      }}
      containerStyle={containerStyle}
      darkMode={darkMode}
    >
      <Row style={{
        width: '100%',
        height: '100%',
        ...isEdit
        ? { filter:'blur(1px)', pointerEvents:'none' }
        : {}
      }}>
          <Row style={{width:'100%', height: 20, ...headerStyle }} justify='space-between' type='flex'>
            <Col style={{maxWidth:'calc(100% - 100px'}}>
              <Row>
                <Typography.Text ellipsis style={{
                  fontFamily:'roboto-medium',
                  color:darkMode ? 'white' : colors.ONTRACCR_BLACK,
                  ...titleStyle,
                }}>
                {title}
                </Typography.Text>
              </Row>
              <Row>
                <Typography.Text style={{
                  fontFamily:'roboto-medium',
                  color:colors.ONTRACCR_DARK_YELLOW,
                  ...subtitleStyle,
                }}>
                {subtitle}
                </Typography.Text>
              </Row>
            </Col>
            <Col>
              {onEdit ? 
                <OnTraccrButton 
                  title='Edit'
                  icon={<EditOutlined/>}
                  type='secondary'
                  onClick={onEdit}
                /> : null}
            </Col>
            {headerContent && (
              <Col>
                {headerContent}
              </Col>
            )}
          </Row>
          {children}
      </Row>
      {
        isEdit && onConfig && (
          <DashboardWidgetEditButton
            onConfig={onConfig}
            showConfig={showConfig}
            configContent={configContent}
          />
        )
      }
      {
        isEdit && onDelete && (
          <DashboardWidgetDeleteButton onDelete={onDelete} />
        )
      }
    </BorderedComponent>
  );
}

DashboardComponent.propTypes = {
  isEdit: PropTypes.bool,
};

DashboardComponent.defaultProps = {
  isEdit: false,
};
