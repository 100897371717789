import React from 'react';
import { Popconfirm } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import BoardCardShadowText from './BoardCardShadowText';
import BoardCardShadowClearModal from './BoardCardShadowClearModal';

import { setBoardTargetCard, clearShadow } from './state/boards.actions';

function BoardCardShadowPopover({
  boardId,
  cardId,
  statusId,
  onBoardSelected,
  canEdit,
  children,
  placement,
}) {
  const dispatch = useDispatch();

  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const boards = useSelector((state) => state.boards.boards);
  const isDragging = useSelector((state) => state.boards.isDragging);

  const hasAccessToTargetBoard = boardId in boards;

  const onView = hasAccessToTargetBoard
    ? (e) => {
      e.stopPropagation();
      onBoardSelected(boardId);
      dispatch(setBoardTargetCard({ cardId, statusId, boardId }));
    }
    : null;
  const onClear = canEdit
    ? (e) => {
      e.stopPropagation();
      BoardCardShadowClearModal({
        selectedBoard,
        cardId,
        showCancel: hasAccessToTargetBoard,
        onClear: () => dispatch(clearShadow(selectedBoard?.id, cardId)),
      });
    }
    : null;

  return (
    <Popconfirm
      title={(
        <BoardCardShadowText
          boardId={boardId}
          style={{ maxWidth: 250 }}
        />
      )}
      placement={placement}
      cancelText={hasAccessToTargetBoard ? 'View' : 'Cancel'}
      okText={canEdit ? 'Clear Shadow' : 'Ok'}
      onCancel={onView}
      onConfirm={onClear}
      trigger={isDragging ? [] : ['hover']}
    >
      {children}
    </Popconfirm>
  );
}

BoardCardShadowPopover.propTypes = {
  boardId: PropTypes.string.isRequired,
  cardId: PropTypes.string.isRequired,
  statusId: PropTypes.number.isRequired,
  onBoardSelected: PropTypes.func,
  canEdit: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  placement: PropTypes.string,
};

BoardCardShadowPopover.defaultProps = {
  placement: 'topLeft',
  onBoardSelected: () => {},
};

export default BoardCardShadowPopover;
