import React from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

export default function IconPickerModal({
  icons,
  onSelect,
  selectedIcon,
  defaultIcon,
}) {
  return (
    <Row style={{ width: 150 }}>
      {icons.map((icon) => (
        <Col
          span={8}
          key={icon.value}
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            fontSize: 30,
            cursor: 'pointer',
            color: '#1890ff',
            padding: 13,
          }}
          onClick={() => onSelect(icon.value)}
        >
          <icon.label
            style={{
              color: (selectedIcon ?? defaultIcon) === icon.value ? 'rgb(24, 144, 255)' : 'black',
              fontSize: 18,
            }}
          />
        </Col>
      ))}
    </Row>
  );
}

IconPickerModal.propTypes = {
  icons: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.node,
  })),
  onSelect: PropTypes.func,
  selectedIcon: PropTypes.string,
  defaultIcon: PropTypes.string,
};

IconPickerModal.defaultProps = {
  icons: [],
  onSelect: () => {},
  selectedIcon: '',
  defaultIcon: '',
};
