import React from 'react';
import { Row, Col } from 'antd';
import moment from 'moment';

import ReportsContainerHeader from './ReportsContainerHeader';
import ReportsFilterCard from '../ReportsViews/ReportsFilterCard';
import ReportSaveModal from './ReportSaveModal';

import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import { getIdMap } from '../../helpers/helpers';

import Permissions from '../../auth/Permissions';

const ROUNDED_REPORTS = ['Employee Payroll', 'Staff Timesheets'];

const canRound = ({ roundingInterval, title, baseReport }) => (
  !!roundingInterval
  && (ROUNDED_REPORTS.includes(title) || ROUNDED_REPORTS.includes(baseReport))
);

const filtersDidChange = ({ oldFilters, newFilters }) => {
  if ((!oldFilters && newFilters) || (oldFilters && !newFilters)) return true;

  // Make sure keys are the same
  const oldKeys = Object.keys(oldFilters);
  const newKeys = Object.keys(newFilters);
  if (oldKeys.length !== newKeys.length) return true;
  const oldKeySet = new Set(oldKeys);
  const newKeySet = new Set(newKeys);
  if (oldKeys.some((oldKey) => !newKeySet.has(oldKey))) return true;
  if (newKeys.some((newKey) => !oldKeySet.has(newKey))) return true;

  // Check each key value
  return newKeys.some((filterKey) => {
    if (filterKey === 'users') {
      // Values are arrays
      const newUsers = newFilters[filterKey];
      const oldUsers = oldFilters[filterKey];
      const newUserSet = new Set(newUsers.map((user) => user.id));
      const oldUserSet = new Set(oldUsers.map((user) => user.id));
      return newUsers.size !== oldUsers.size
      || newUsers.some((user) => !oldUserSet.has(user.id))
      || oldUsers.some((user) => !newUserSet.has(user.id));
    }
    const newValues = newFilters[filterKey]; // Sets
    const oldValues = oldFilters[filterKey]; // Sets
    return newValues.size !== oldValues.size
    || Array.from(newValues).some((value) => !oldValues.has(value))
    || Array.from(oldValues).some((value) => !newValues.has(value));
  });
};

export default class MainReportsContainer extends React.Component {
  constructor(props) {
    super(props);
    const {
      users = [],
      projects = [],
      phases = [],
      costcodes = [],
      classes = [],
    } = this.props;

    this.state = {
      reportHeight: 450,
      dateRange: [moment().subtract(7, 'd'), moment()],
      showSaveModal: false,
      isRounded: false,
      filters: {},
      searchValue: '',
      usersIdMap: getIdMap(users),
      costcodeIdMap: getIdMap(costcodes),
      projectIdMap: getIdMap(projects),
      phaseMap: getIdMap(phases),
      classMap: getIdMap(classes),
    };

    this.onResize = this.resize.bind(this);
    this.onDateRangeChanged = this.changeDateRange.bind(this);
    this.lastFilters = [];

    this.renderRowFilters = this.getRowFilters.bind(this);
    this.renderColFilters = this.getColFilters.bind(this);
    this.onSaveClicked = this.showSaveModal.bind(this);
    this.onSaveModalClose = this.hideSaveModal.bind(this);
    this.onDelete = this.deleteReport.bind(this);
    this.onRoundedChanged = this.roundedChanged.bind(this);
    this.onFiltersChanged = this.filtersChanged.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    setTimeout(this.onResize, 250);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  componentDidUpdate(prevProps) {
    const {
      users,
      costcodes,
      phases,
      projects,
    } = this.props;
    const {
      users: prevUsers,
      costcodes: prevCostcodes,
      phases: prevPhases,
      projects: prevProjects,
    } = prevProps;
    if (users !== prevUsers) {
      this.setState({
        usersIdMap: getIdMap(users ?? []),
      });
    }
    if (costcodes !== prevCostcodes) {
      this.setState({
        costcodeIdMap: getIdMap(costcodes ?? []),
      });
    }
    if (phases !== prevPhases) {
      this.setState({
        phaseMap: getIdMap(phases ?? []),
      });
    }
    if (projects !== prevProjects) {
      this.setState({
        projectIdMap: getIdMap(projects ?? []),
      });
    }
  }

  resize() {
    const newHeight = this.computeSize();
    if (!newHeight) return;
    this.setState({
      reportHeight: newHeight,
    });
  }

  computeSize() {
    if (!this.containerRef || !this.headerRef || !this.reportRef) return;
    const {
      clientHeight,
    } = this.containerRef;
    const {
      clientHeight: headerHeight,
      clientTop: headerTop,
    } = this.headerRef;
    return clientHeight - (headerTop + headerHeight) - 75;
  }

  roundedChanged(rounded) {
    const {
      onRoundedChanged,
    } = this.props;
    this.setState({
      isRounded: rounded,
    });
    onRoundedChanged(rounded);
  }

  changeDateRange(dateRange) {
    this.setState({
      dateRange,
    });
  }

  getOnCheckChanged(filter) {
    if (filter.onCheckChanged) return filter.onCheckChanged;
    if (!this.reportRef) return null;
    return (checked) => this.reportRef.onFilterToggle(filter, checked);
  }

  getColFilters(filters = []) {
    if (filters.length !== this.lastFilters.length) {
      this.lastFilters = filters;
      setTimeout(this.onResize, 50);
    }
    return (
      <Row justify="start" align="top" ref={(ref) => this.headerRef = ref} gutter={16}>
        {filters.map((filter) => (
          <Col key={filter.title}>
            <ReportsFilterCard
              {...filter}
              key={filter.key}
              onFilterToggle={filter.onFilterToggle}
              onCheckChanged={this.getOnCheckChanged(filter)}
              checked={filter.checked}
            />
          </Col>
        ))}
      </Row>
    );
  }

  getRowFilters(filters = []) {
    return filters.map((filter) => (
      <ReportsFilterCard
        {...filter}
        key={filter.title}
        onFilterToggle={filter.onFilterToggle}
        onCheckChanged={this.getOnCheckChanged(filter)}
        defaultChecked={filter.defaultChecked}
      />
    ));
  }

  showSaveModal() {
    this.setState({
      showSaveModal: true,
    });
  }

  hideSaveModal() {
    this.setState({
      showSaveModal: false,
    });
  }

  deleteReport() {
    const {
      report: {
        name,
        id,
      } = {},
      deleteReport,
    } = this.props;
    CustomConfirmModal({
      title: 'Delete report',
      content: <p>
        Are you sure you want to delete "
        {name}
        " report?
        <br />
        <br />
        If this report is public, all users will lose access.
               </p>,
      async onOk() {
        deleteReport(id);
      },
      okText: 'Delete',
    });
  }

  injectSavedData() {
    const {
      report: {
        baseReport,
        users = [],
        projects = [],
        phases = [],
        costcodes = [],
        columns,
        zeroHoursEnabled = false,
      } = {},
    } = this.props;
    const {
      usersIdMap = {},
      projectIdMap = {},
      phaseMap = {},
      costcodeIdMap = {},
    } = this.state;
    if (!baseReport) return this.props;
    return {
      ...this.props,
      columns,
      zeroHoursEnabled,
      users: users.filter((user) => user !== null && usersIdMap[user]).map((userId) => usersIdMap[userId]),
      projects: projects.filter((proj) => proj !== null).map((projectId) => projectIdMap[projectId]),
      phases: phases.filter((phase) => phase !== null && phase in phaseMap).map((phaseId) => phaseMap[phaseId]),
      costcodes: costcodes.filter((cc) => cc !== null && cc in costcodeIdMap).map((ccId) => costcodeIdMap[ccId]),
    };
  }

  filtersChanged(newFilters) {
    const {
      onFiltersChanged: propFilterCallback,
    } = this.props;
    const { filters: oldFilters } = this.state;
    if (filtersDidChange({ newFilters, oldFilters })) {
      this.setState({
        filters: newFilters,
      });
    }

    propFilterCallback(newFilters);
  }

  render() {
    const {
      report: {
        title,
        name,
        userId: reportOwner,
        isPublic,
        id,
        baseReport,
        hideDate,
        hideSearch = true,
        hideSave = false,
      } = {},
      SelectedReportView,
      onFullScreenToggle,
      isFullScreen,
      onExport,
      onDataChanged,
      onColumnsChanged,
      onSummaryChanged,
      onZeroHoursChanged,
      onSave,
      onDateRangeChanged,
      dateRange,
      reports = [],
      settings = {},
      exporter,
    } = this.props;
    const {
      reportHeight,
      showSaveModal,
      isRounded,
      filters,
      searchValue,
      classMap,
    } = this.state;

    const savedData = this.injectSavedData();
    const isMyReport = reportOwner === Permissions.id;
    const { roundingInterval } = settings;

    const isEmployeePayroll = title === 'Employee Payroll' || baseReport === 'Employee Payroll';

    const onSearch = (e) => {
      this.setState({
        searchValue: e.target.value,
      });
    };

    return (
      <Row style={{ width: '100%', height: '100%' }} ref={(ref) => this.containerRef = ref}>
        <ReportsContainerHeader
          title={title || name}
          onDateRangeChanged={onDateRangeChanged}
          onFullScreenToggle={onFullScreenToggle}
          isFullScreen={isFullScreen}
          hideCustom={title === 'Staff Timesheets'}
          showDate={!hideDate}
          showSearch={!hideSearch}
          showSave={!hideSave}
          onSearch={onSearch}
          onExport={onExport}
          onSave={this.onSaveClicked}
          dateRange={dateRange}
          onDelete={isMyReport && this.onDelete}
          onRoundedChanged={
              canRound({ roundingInterval, title, baseReport })
                ? this.onRoundedChanged
                : null
            }
          isEmployeePayroll={isEmployeePayroll}
          isRounded={isRounded}
          savedData={savedData}
          filters={filters}
        />
        <SelectedReportView
          key={title || name}
          {...savedData}
          height={reportHeight}
          dateRange={dateRange}
          onRef={(ref) => this.reportRef = ref}
          searchValue={searchValue}
          renderColFilters={this.renderColFilters}
          renderRowFilters={this.renderRowFilters}
          onDataChanged={onDataChanged}
          onColumnsChanged={onColumnsChanged}
          onFiltersChanged={this.onFiltersChanged}
          onSummaryChanged={onSummaryChanged}
          onZeroHoursChanged={onZeroHoursChanged}
          settings={settings}
          isRounded={isRounded}
          exporter={exporter}
          classMap={classMap}
        />
        <ReportSaveModal
          reports={reports}
          visible={showSaveModal}
          onCloseClicked={this.onSaveModalClose}
          onSave={onSave}
          isEdit={isMyReport}
          name={name}
          isPublic={!!isPublic}
          reportId={id}
        />
      </Row>
    );
  }
}
