import React, { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Tabs } from 'antd';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';

import UnionCard from './UnionCard';
import UnionAddModal from './UnionAddModal';

import { deleteUnion } from '../../unions/state/unions.actions';

const { TabPane } = Tabs;

export default function Unions() {
  const dispatch = useDispatch();

  const {
    unions = [],
  } = useSelector((state) => state.unions);

  const [modalOpen, setModalOpen] = useState(false);
  const [activeTab, setActiveTab] = useState();

  const closeModal = useCallback(() => setModalOpen(false), []);
  const openModal = useCallback(() => setModalOpen(true), []);

  const onDeleteUnion = useCallback(({ id, name }) => () => (
    CustomConfirmModal({
      title: `Delete union ${name}?`,
      onOk: async () => {
        if (await dispatch(deleteUnion('union', id)) && activeTab === id) {
          const newActive = unions.find((division) => division.id !== id);
          if (newActive && newActive.id) setActiveTab(newActive.id);
        }
      },
    })
  ), [activeTab, unions]);

  const onTabChange = useCallback((tab) => {
    if (tab !== 'add') setActiveTab(tab);
  }, []);

  useEffect(() => {
    const [{ id } = {}] = unions;
    if (!activeTab && id) setActiveTab(id);
  }, [unions, activeTab]);

  return (
    <Row id="divisions-container">
      <Tabs
        style={{
          width: '100%',
          height: '100%',
        }}
        defaultActiveKey="0"
        tabPosition="left"
        tabBarStyle={{
          marginTop: 15,
          width: 150,
        }}
        activeKey={activeTab}
        onChange={onTabChange}
      >
        {unions.map((union) => {
          const { id, name } = union;
          return (
            <TabPane tab={name} key={id}>
              <UnionCard
                {...union}
                onDelete={unions.length > 1 ? onDeleteUnion({ id, name }) : null}
              />
            </TabPane>
          );
        })}
        <TabPane
          key="add"
          tab={<OnTraccrButton title="Add Union" type="primary" style={{ marginTop: 0 }} onClick={openModal} />}
        />
      </Tabs>
      <UnionAddModal
        visible={modalOpen}
        onClose={closeModal}
      />
    </Row>
  );
}
