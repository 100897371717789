import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import { setBreadcrumb } from '../common/breadcrumbContainer/state/breadcrumb.actions';

import DashboardTitle from './DashboardTitle';
import DashboardContainer from '../common/dashboardAnalytics/DashboardContainer';

import UserClockInModal from '../users/UserClockInModal';

import { getProjects } from '../projects/state/projects.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getSageShifts } from '../sage/state/sage.actions';
import { getTeams } from '../teams/state/teams.actions';
import TimeTracking from '../state/timeTracking';

import { mergeUsersProjectsCostcodes } from '../users/userHelpers';
import { getIdMap } from '../helpers/helpers';

import config from '../config';

import Clock from '../clock/Clock';
import {
  saveDashboard,
  getSavedDashboards,
  updateDashboard,
} from './state/dashboard.actions';
import { getSavedAnalyticsReports } from '../analytics/state/analytics.actions';
import { getBoardCardTemplates } from '../boards/state/boards.actions';
import { getUsersFromSelectedDivisions } from '../timecards/timecard.helpers';
import { updateCurrentTimeEntriesRange } from '../timecards/state/timecards.actions';
import { findPayrollStartAndEndDates } from '../helpers/payroll';

const DEFAULT_LAYOUT = [
  {
    i: 'clock', x: 0, y: 0, w: 6, h: 6,
  },
  {
    i: 'quickStats', x: 0, y: 6, w: 6, h: 6,
  },
  {
    i: 'liveFeed', x: 6, y: 0, w: 6, h: 12,
  },
];
class Dashboard extends React.Component {
  constructor(props) {
    const {
      company: {
        settings = {}
      } = {},
    } = props;
    super(props);
    this.clock = new Clock({
      clockin: this.props.clockin,
      clockout: this.props.clockout,
      switchTask: this.props.switchTask,
      takeBreak: this.props.takeBreak,
      endBreak: this.props.endBreak,
      manualEntry: this.props.manualEntry,
    });

    this.userClock = new Clock({
      clockin: this.props.clockin,
      clockout: this.props.clockout,
      switchTask: this.props.switchTask,
      takeBreak: this.props.takeBreak,
      endBreak: this.props.endBreak,
      manualEntry: this.props.manualEntry,
    });

    this.onUserClick = this.openUserClock.bind(this);
    this.onUserClockClose = this.closeUserClock.bind(this);
    this.onSaveClicked = this.onSave.bind(this);
    this.onEditCancelled = this.onEditCancel.bind(this);
    this.onEditWidget = this.editWidget.bind(this);
    this.onAddWidget = this.addWidget.bind(this);
    this.onReloadClicked = this.onReload.bind(this);
    this.onSetCurrentLayout = this.setCurrentLayout.bind(this);

    const {
      profile,
      users = [],
      selectedDashboard: {
        layout = DEFAULT_LAYOUT,
      } = {},
    } = props;

    this.state = {
      profile,
      users,
      selectedUser: null,
      layout,
      currentLayout: layout,
      layoutKey: 0,
    };

    this.clock.users = users;
    this.clock.settings = settings;
    if (users.length > 0 && profile.id) {
      const ourUsers = users.filter((user) => user.id === profile.id);
      if (ourUsers.length > 0) {
        this.state.ourUser = ourUsers[0];
        this.clock.setUser(ourUsers[0]);
      }
    }

    this.props.setBreadcrumb([{
      text: 'Dashboard',
      icon: 'home',
    }]);
  }

  componentDidMount() {
    const {
      getSavedDashboards,
      getProjects,
      getSageShifts,
      getUnions,
      getSavedAnalyticsReports,
      getBoardCardTemplates,
      getTeams,
      selectedDivisions,
    } = this.props;
    getSavedDashboards();
    getProjects();
    getSageShifts({ divisionIds: Array.from(selectedDivisions) });
    getUnions();
    getSavedAnalyticsReports();
    getBoardCardTemplates();
    getTeams();
    this.getTimeEntriesForUsers();
  }

  componentDidUpdate(prevProps) {
    const {
      profile:prevProfile,
      users: prevUsers = [],
      projects: prevProjects = [],
      costcodes: prevCostcodes = [],
      selectedDivisions: prevSelected,
      selectedDashboard: prevSelectedDash,
      divisions: prevDivisions,
      firstPayrollDay: prevFirstPayrollDay,
      payPeriod: prevPayPeriod,
      semiMonthlyPayPeriodDates: prevSemiMonthlyPayPeriodDates,
    } = prevProps;
    const {
      profile,
      users = [],
      positions = {},
      projects = [],
      costcodes = [],
      selectedDivisions = new Set(),
      selectedDashboard,
      company: {
        settings = {}
      } = {},
      roundingPolicyPositionMap,
      divisions,
      firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
    } = this.props;
    const { selectedUser } = this.state;
    this.clock.users = users;
    this.clock.settings = settings;
    this.clock.positions = positions;
    this.clock.roundingPolicyPositionMap = roundingPolicyPositionMap;
    this.userClock.users = users;
    this.userClock.settings = settings;
    this.userClock.positions = positions;
    this.userClock.roundingPolicyPositionMap = roundingPolicyPositionMap;
    if (profile && profile !== prevProfile && !selectedUser) {
      this.setState({
        profile,
      });
      this.setClockUser(users, profile);
    }

    if (users !== prevUsers
      || divisions !== prevDivisions
      || (selectedDivisions.size > 0 && selectedDivisions !== prevSelected)
      || firstPayrollDay !== prevFirstPayrollDay
      || payPeriod !== prevPayPeriod
      || prevSemiMonthlyPayPeriodDates !== semiMonthlyPayPeriodDates
    ) {
      this.getTimeEntriesForUsers();
    }

    if (selectedDashboard !== prevSelectedDash) {
      const {
        layout = DEFAULT_LAYOUT,
      } = selectedDashboard ?? {};
      this.setState({
        layout,
        currentLayout: layout,
      });
    }

    if (prevUsers !== users
      || prevCostcodes !== costcodes
      || prevProjects !== projects
      || (selectedDivisions.size > 0 && selectedDivisions !== prevSelected)
    ) {
      // Inject user clock in callback

      let divProjects = projects;
      if (config.showDivisions) {
        divProjects = projects.filter((project) => selectedDivisions.has(project.divisionId));
      }
      const {
        projectMap,
        costcodeMap,
        phaseMap,
        users: newUsers,
      } = mergeUsersProjectsCostcodes({
        projects: divProjects,
        costcodes,
        users,
        onUserClock: this.onUserClock,
      });

      this.projectMap = projectMap;
      this.projectNameMap = getIdMap(projects, 'name');
      this.phaseMap = phaseMap;
      this.costcodeMap = costcodeMap;
      this.users = newUsers;
      this.setState({
        users: this.users,
      });
      this.userMap = getIdMap(this.users);

      this.clock.users = newUsers;
      this.userClock.users = newUsers;
      this.setClockUser(users, selectedUser || profile);
      if (selectedUser) {
        const newSelectedUser = newUsers.find((user) => user.id === selectedUser.id);
        if (newSelectedUser) this.setState({ selectedUser: newSelectedUser });
      }
    }
  }

  onSave(title) {
    const {
      selectedDashboard: {
        id: selectedDashboardId,
      } = {},
      saveDashboard,
      updateDashboard,
    } = this.props;
    const {
      currentLayout,
    } = this.state;
    if (selectedDashboardId === 'new') {
      // create
      saveDashboard({
        payload: {
          title,
          layout: currentLayout,
        },
      });
    } else {
      // edit
      updateDashboard({
        id: selectedDashboardId,
        payload: {
          title,
          layout: currentLayout,
        },
      });
    }
  }

  onEditCancel() {
    const {
      layout,
    } = this.state;
    this.setState({
      currentLayout: layout,
    });
  }

  onReload() {
    const { layoutKey: stateLayoutKey } = this.state;
    this.setState({
      layoutKey: stateLayoutKey + 1,
    });
  }

  getTimeEntriesForUsers() {
    const {
      selectedDivisions,
      getTimeEntries,
      divisions,
      users,
      updateCurrentTimeEntriesRange,
      firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
    } = this.props;
    const [payPeriodStart, payPeriodEnd] = findPayrollStartAndEndDates({
      payPeriodFirstDay: firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
    });
    const selectedDivUsers = getUsersFromSelectedDivisions({
      users,
      selectedDivisions,
      divisions,
    });
    getTimeEntries({
      startTime: payPeriodStart.toMillis(),
      endTime: payPeriodEnd.toMillis(),
      getRunningTasks: true,
      userIds: selectedDivUsers.map((u) => u.id),
    });
    updateCurrentTimeEntriesRange(payPeriodStart, payPeriodEnd);
  }

  setClockUser(users = [], profile = {}) {
    if (users.length > 0 && profile.id) {
      const ourUsers = users.filter((user) => user.id === profile.id);
      if (ourUsers.length > 0) {
        this.setState({
          ourUser: ourUsers[0],
        });
        this.clock.setUser(ourUsers[0]);
      }
    }
  }

  setCurrentLayout(value) {
    this.setState({
      currentLayout: value,
    });
  }

  openUserClock(user) {
    this.userClock.setUser(user);
    this.setState({
      selectedUser: user,
    });
  }

  closeUserClock() {
    this.setState({
      selectedUser: null,
    });
  }

  addWidget(key) {
    const {
      currentLayout = [],
    } = this.state;

    this.setState({
      currentLayout: currentLayout.concat([{
        i: key, y: Infinity, x: 0, w: 4, h: 4,
      }]),
    });
  }

  editWidget(key) {
    return (settings) => {
      const {
        currentLayout = [],
      } = this.state;
      this.setState({
        currentLayout: currentLayout.map((layout) => (layout.i === key
          ? { ...layout, settings }
          : layout
        )),
      });
    };
  }

  render() {
    const {
      company: {
        settings = {}
      } = {},
      costcodes,
      phases,
      projects,
      history,
      unions = {},
      isEdit,
      selectedDashboard: {
        id: selectedDashboardId = 'new',
      } = {},
      savedReports,
      analyticsData,
    } = this.props;

    const {
      profile: {
        id,
      } = {},
      users = [],
      selectedUser,
      currentLayout,
      layoutKey,
    } = this.state;
    const ourUsers = (users || []).filter((user) => user.id === id);

    return (
      <BreadCrumbContainer
        crumbs={[{
          icon: 'home',
          text: (
            <DashboardTitle
              onSave={this.onSaveClicked}
              onEditCancel={this.onEditCancelled}
              onAddWidget={this.onAddWidget}
              currentLayout={currentLayout}
              onReloadClicked={this.onReloadClicked}
            />
          ),
        }]}
        bodyStyle={{
          position: 'absolute',
          height: 'auto',
          margin: 0,
          top: 71,
          left: 0,
          right: 0,
          bottom: 0,
          overflowY: 'auto',
          padding: 15,
        }}
      >
        <DashboardContainer
          currentLayout={currentLayout}
          setCurrentLayout={this.onSetCurrentLayout}
          savedReports={savedReports}
          analyticsData={analyticsData}
          selectedDashboardId={selectedDashboardId}
          isEdit={isEdit}
          layoutKey={layoutKey}
          rightMargin={20}
          ourUsers={ourUsers}
          history={history}
          onClockIn={this.clock.onClockIn}
          onClockOut={this.clock.onClockOut}
          onSwitch={this.clock.onSwitch}
          onBreakStart={this.clock.onBreakStart}
          onBreakEnd={this.clock.onBreakEnd}
          onUserClick={this.onUserClick}
          onEditWidget={this.onEditWidget}
        />
        <UserClockInModal
          key={selectedUser}
          visible={!!selectedUser}
          user={selectedUser}
          costcodes={costcodes}
          projects={projects}
          phases={phases}
          unions={unions}
          onCloseClicked={this.onUserClockClose}
          onClockIn={this.userClock.onClockIn}
          onClockOut={this.userClock.onClockOut}
          onSwitch={this.userClock.onSwitch}
          onBreakStart={this.userClock.onBreakStart}
          onBreakEnd={this.userClock.onBreakEnd}
        />
      </BreadCrumbContainer>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
Dashboard.propTypes = {
  updateDashboard: PropTypes.func.isRequired,
  savedReports: PropTypes.object,
  analyticsData: PropTypes.object,
};

Dashboard.defaultProps = {
  savedReports: {},
  analyticsData: {},
};

export default connect((state, ownProps) => ({
  ...ownProps,
  projects: state.projects.projects,
  costcodes: state.costcodes.costcodes,
  phases: state.costcodes.phases,
  company: state.settings.company,
  profile: state.profile.profile,
  users: state.users.users,
  positions: state.settings.positions,
  selectedDivisions: state.settings.selectedDivisions,
  unions: state.unions,
  selectedDashboard: state.dashboard.selectedDashboard,
  isEdit: state.dashboard.isEdit,
  savedReports: state.analytics.savedReports,
  analyticsData: state.dashboard.analyticsData,
  roundingPolicyPositionMap: state.settings.roundingPolicyPositionMap,
  divisions: state.settings.divisions,
  payPeriod: state.timecards.payPeriod,
  semiMonthlyPayPeriodDates: state.timecards.semiMonthlyPayPeriodDates,
  firstPayrollDay: state.timecards.firstPayrollDay,
}), {
  setBreadcrumb,
  getProjects,
  clockin: TimeTracking.clockIn,
  clockout: TimeTracking.clockOut,
  switchTask: TimeTracking.switchTask,
  takeBreak: TimeTracking.startBreak,
  endBreak: TimeTracking.endBreak,
  manualEntry: TimeTracking.manualEntry,
  getSageShifts,
  getUnions,
  saveDashboard,
  getSavedDashboards,
  getSavedAnalyticsReports,
  getBoardCardTemplates,
  getTeams,
  updateDashboard,
  getTimeEntries: TimeTracking.getTimeEntries,
  updateCurrentTimeEntriesRange,
})(withRouter(Dashboard));
