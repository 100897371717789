import React from 'react';
import { Checkbox, Row, Typography } from 'antd';
import PropTypes from 'prop-types';

import HoverHelp from '../HoverHelp';

const { Text } = Typography;

export default function OnTraccrCheckbox({
  label, value, onChange, disabled, hoverNode, style,
}) {
  const handleCheckboxChange = (e) => {
    onChange(e?.target?.checked);
  };
  return (
    <Row style={style}>
      <Checkbox
        checked={value}
        onChange={handleCheckboxChange}
        style={{ marginRight: 10 }}
        disabled={disabled}
      >
        <Text>{label}</Text>
      </Checkbox>
      {hoverNode && (
      <HoverHelp
        iconProps={{ style: { marginLeft: -10, marginTop: 5 } }}
        placement="topRight"
        content={hoverNode}
      />
      )}
    </Row>
  );
}

OnTraccrCheckbox.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  hoverNode: PropTypes.node,
  style: PropTypes.shape({}),
};

OnTraccrCheckbox.defaultProps = {
  disabled: false,
  hoverNode: null,
  style: {},
};
