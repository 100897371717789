import {
  SAVE_REPORT,
  UPDATE_REPORT,
  GET_REPORTS,
  GET_PROGRESS,
  DELETE_REPORT,
  UPDATE_PROGRESS,
  ADD_PROJECT_PROGRESS_HISTORY_FILES,
  CREATE_CUSTOM_EXPORT,
  GET_CUSTOM_EXPORTS,
  UPDATE_CUSTOM_EXPORT,
  DELETE_CUSTOM_EXPORT,
} from '../../state/actionTypes';

const initialState = {
  reports: [],
  customExports: [],
};

export default function projectActions(state = initialState, action = {}) {
  switch (action.type) {
    case GET_REPORTS:
    case SAVE_REPORT:
    case UPDATE_REPORT:
      return {
        ...state,
        reports: action.payload.reports,
      };
    case GET_PROGRESS:
    case UPDATE_PROGRESS:
    case ADD_PROJECT_PROGRESS_HISTORY_FILES:
      return {
        ...state,
        progress: action.payload.progress,
      };
    case DELETE_REPORT:
      return {
        ...state,
        reports: state.reports.filter(({ id }) => id !== action.payload.reportId),
      };
    case GET_CUSTOM_EXPORTS: {
      const {
        payload: { customExports = [] } = {},
      } = action;
      return {
        ...state,
        customExports,
      };
    }
    case CREATE_CUSTOM_EXPORT: {
      const {
        payload: { customExport = {} } = {},
      } = action;
      const { customExports = [] } = state;
      return {
        ...state,
        customExports: customExports.concat([customExport]),
      };
    }
    case UPDATE_CUSTOM_EXPORT: {
      const {
        payload: {
          id,
          customExport = {},
        } = {},
      } = action;
      const { customExports = [] } = state;
      return {
        ...state,
        customExports: customExports.map((existingExport) => {
          if (existingExport.id !== id) return existingExport;
          return {
            ...existingExport,
            ...customExport,
          };
        }),
      };
    }
    case DELETE_CUSTOM_EXPORT: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { customExports = [] } = state;
      return {
        ...state,
        customExports: customExports.filter((existingExport) => (
          existingExport.id !== id
        )),
      };
    }
    default:
      return state;
  }
}
