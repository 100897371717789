import { React, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Descriptions, Select } from 'antd';

function ProjectBudgetTrackingSelectSheets({
  workbook,
  setEstimateSheet,
  setCostcodeSheet,
}) {
  const {
    defaultEstimateSheet,
    defaultCostcodeSheet,
    sheetOpts = [],
  } = useMemo(() => {
    if (!workbook) return {};
    const { SheetNames = [] } = workbook;
    const estimateSheet = SheetNames[0];
    const costcodeSheet = SheetNames.length > 1
      ? SheetNames[1]
      : SheetNames[0];

    return {
      defaultEstimateSheet: estimateSheet,
      defaultCostcodeSheet: costcodeSheet,
      sheetOpts: SheetNames.map((sheet) => ({ value: sheet, label: sheet })),
    };
  }, [workbook]);

  useEffect(() => {
    setEstimateSheet(defaultEstimateSheet);
    setCostcodeSheet(defaultCostcodeSheet);
  }, [defaultEstimateSheet, defaultCostcodeSheet]);

  return (
    <Descriptions title="Select Sheets">
      <Descriptions.Item label="Estimates">
        <Select
          style={{ width: 200 }}
          defaultValue={defaultEstimateSheet}
          onSelect={setEstimateSheet}
          options={sheetOpts}
        />
      </Descriptions.Item>
      <Descriptions.Item label="Costcodes">
        <Select
          style={{ width: 200 }}
          defaultValue={defaultCostcodeSheet}
          onSelect={setCostcodeSheet}
          options={sheetOpts}
        />
      </Descriptions.Item>
    </Descriptions>
  );
}

ProjectBudgetTrackingSelectSheets.propTypes = {
  workbook: PropTypes.object.isRequired,
  setEstimateSheet: PropTypes.func.isRequired,
  setCostcodeSheet: PropTypes.func.isRequired,
};

export default ProjectBudgetTrackingSelectSheets;
