import React from 'react';
import { Row } from 'antd';
import { useTranslation } from 'react-i18next';

import IntegrationAutoSync from '../IntegrationAutoSync';
import qbLogo from '../../../assets/images/QuickBooks Logo_Horz.png';

const getQBToOTHelp = (t) => (
  <div style={{ width: 300 }}>
    Auto-Sync from QuickBooks to Ontraccr will
    automatically import any new changes you make in QuickBooks
    into <b>already linked</b> Ontraccr Users, ${t('Project', { count: 0 })} and Cost Codes.
  </div>
);

const getOTtoQBHelp = (t) => (
  <div style={{ width: 300 }}>
    Auto-Sync from Ontraccr to QuickBooks will
    automatically export any new changes you make in Ontraccr
    to <b>already linked</b> Ontraccr Users, ${t('Project', { count: 0 })} and Cost Codes into
    the corresponding entries in QuickBooks.
    <br/><br/>
    We will also create new entries in QuickBooks for any new Users, ${t('Project', { count: 0 })} or Costcodes
    created in Ontraccr.
  </div>
);


export default function QuickbooksAutoSync({
  onChanged,
  syncSettings,
}) {
  const { t } = useTranslation();
  const qbToOTHelp = getQBToOTHelp(t);
  const OTtoQBHelp = getOTtoQBHelp(t);

  return (
    <IntegrationAutoSync
      title='QuickBooks'
      pullHelp={qbToOTHelp}
      pushHelp={OTtoQBHelp}
      onChanged={onChanged}
      syncSettings={syncSettings}
      integrationKey='qb'
      integrationLogo={
        <Row align='top' style={{ height: '100%'}}>
          <img
            alt='QuickBooks Logo'
            src={qbLogo}
            style={{
              width:300,
              height:82,
            }}/>
        </Row>
      }
    />
  );
}