import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';
import customRoles from '../../assets/images/NUX/customRole.png';
import permissions from '../../assets/images/NUX/permissions.png';

const permDesc = `A permission exists for almost every part of a user's
Ontraccr experience. Set permissions for everything from what pages & reports
users can see, to whether or not they are allowed to clock someone else in.`;

const getRoleDesc = (t) => `In addition to using the four default role types (admin,
  manager, supervisor and worker), you can also create custom role types.
  This is helpful in the event you have a temporary worker on your ${t('Project').toLowerCase()} or
  would like to include sub-contractor's workers in your Ontraccr account.`;

export default function NuxPermissions({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const roleDesc = getRoleDesc(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 74px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:'Permissions',
        description:permDesc,
        cols:[0,14,10],
        image:{
          src:permissions,
          style:{
            height:140,
            width:235,
          }
        },
      },{
        title:'Custom Roles',
        description:roleDesc,
        cols:[0,14,10],
        image:{
          src:customRoles,
          style:{
            height:100,
            width:198,
          }
        },
      }]}
    />
  );
}
