import { Drawer, Form } from 'antd';
import {
  React,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import SubtaskForm from '../subtasks/SubtaskForm';

function BoardWorkflowSubtasksDrawer({
  visible,
  onClose,
  triggerTasks = [],
  selectedTaskId,
  onSubmit,
  relativeOptions,
}) {
  const [form] = Form.useForm();
  const [formData, setFormData] = useState();

  const drawerTitle = useMemo(() => (
    selectedTaskId
      ? 'Edit Task'
      : 'Add Task'
  ));

  const selectedTask = useMemo(() => {
    if (!selectedTaskId) return {};
    return triggerTasks.find((task) => task.id === selectedTaskId) ?? {};
  }, [selectedTaskId, triggerTasks]);

  const {
    reminderEmails = [],
    reminders = [],
  } = selectedTask;

  useEffect(() => {
    if (!visible && form) form.resetFields();
  }, [visible, form]);

  useEffect(() => {
    form.setFieldsValue({
      ...selectedTask,
    });
    setFormData(selectedTask?.formData ?? {});
  }, [form, selectedTask]);

  const onSubmitClicked = useCallback(async () => {
    let values;
    try {
      values = await form.validateFields();
    } catch (err) {
      //
    }
    if (!values) return;
    await onSubmit({ ...values, formData });
  }, [
    form,
    selectedTaskId,
    onSubmit,
    formData,
  ]);

  return (
    <Drawer
      title={drawerTitle}
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <SubtaskForm
        visible={visible}
        form={form}
        isDisplay={false}
        isBoardWorkflow
        selectedTask={selectedTask}
        reminderEmails={reminderEmails}
        reminders={reminders}
        isTriggeredTask
        relativeOptions={relativeOptions}
        onFormDataChanged={setFormData}
        initialFormData={formData}
      />
      <DrawerSubmitFooter
        onSubmit={onSubmitClicked}
        onClose={onClose}
      />
    </Drawer>
  );
}

export default BoardWorkflowSubtasksDrawer;
