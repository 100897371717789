import React from 'react';

import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import colors from '../constants/Colors';

export default ({
  onCloseClicked,
}) => (
  CustomConfirmModal({
    title:'Permissions Update',
    content: <p>Your permissions have been updated.<br/>
    Please refresh the application</p>,
    onOk:onCloseClicked,
    okText: 'Refresh',
    maskColor:colors.opacity(colors.MAIN_BACKGROUND,0.95),
    hideCancel:true,
    maskClosable:false,
    keyboard:false,
  })
)
