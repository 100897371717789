import axios from 'axios';
import { request } from '../../helpers/requests';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const axiosFileInstance = axios.create();

export const uploadFilesToS3 = async (files) => {
  let parsedFiles;
  if (files.length > 0) {
    const {
      data: urls = [],
    } = await axios.post('/field_data/file/upload/url', {
      files: files.map(({ jsFileObject, path }) => {
        const { type, name, timestamp } = jsFileObject || {};
        let realType = jsFileObject.type;
        if (type === 'application/octet-stream' && name.endsWith('pdf')) {
          realType = 'application/pdf'; // As of Version 2.6.0 (Fixed 2.6.7), our forms return type 'application/octet-stream', but backend needs application/pdf
        }
        return {
          type: realType,
          name,
          path,
          timestamp,
        };
      }),
    });

    parsedFiles = await Promise.all(urls.map(async ({
      url,
      type,
      id,
      name,
      timestamp,
    }, index) => {
      const jsFile = files[index].jsFileObject;
      await axiosFileInstance.put(url, jsFile, {
        headers: {
          'Content-Type': type,
        },
      });
      return {
        id,
        name,
        path: files[index].path,
        size: jsFile.size,
        timestamp,
      };
    }));
  }

  return parsedFiles;
};

const FileService = {
  getStructure: () => request({
    call: axios.get('/files/structure'),
    errMsg: 'Failed to get file structure',
    hideSuccessToast: true,
  }),
  createFolders: ({ names, divisionId }) => request({
    call: axios.post('/files/folders', { names, divisionId }),
    errMsg: 'Failed to create folders',
  }),
  moveItems: (payload) => request({
    call: axios.post('/files/move', payload),
    errMsg: 'Failed to move files',
  }),
  renameFolder: (payload) => request({
    call: axios.put('/folder/rename', payload),
    errMsg: 'Failed to rename folder',
  }),
  describeItem: (path) => request({
    call: axios.get(`/files/details/${path}`),
    errMsg: 'Failed to get file information',
    hideSuccessToast: true,
  }),
  deleteItems: ({ payload, query }) => request({
    call: axios.delete('/files', {
      data: payload,
      params: query,
    }),
    errMsg: (err) => {
      const {
        message: errorMessage = 'Bad Request',
      } = err;
      return errorMessage !== 'Bad Request' ? errorMessage : 'Could not delete items';
    },
  }),
  getACL: (id) => request({
    call: axios.get(`/files/${id}/access`),
    errMsg: 'Failed to get file access list',
    hideSuccessToast: true,
  }),
  rotateFile: (id, payload) => request({
    call: axios.put(`/files/${id}/rotation`, payload),
    errMsg: 'Failed to update file rotation',
    hideSuccessToast: true,
  }),
  grantAccess: (payload) => request({
    call: axios.post('/files/access', payload),
    errMsg: 'Failed to grant file access',
    hideSuccessToast: true,
  }),
  revokeAccess: (payload) => request({
    call: axios.delete('/files/access', { data: payload }),
    errMsg: 'Failed to revoke file access',
    hideSuccessToast: true,
  }),
  togglePublic: (id, isPublic) => request({
    call: axios.put(`/files/${id}/public`, { public: isPublic }),
    errMsg: 'Failed to toggle public status',
    hideSuccessToast: true,
  }),
  loadImage: (path, suppressError = false) => request({
    call: async () => {
      const { data: signedURL } = await axios.get(`/download${path}`, { params: { isView: true } });
      return new Promise((resolve, reject) => {
        const image = new Image();
        image.crossOrigin = 'ok'; // Prevent CORS on AWS bucket https://developer.mozilla.org/en-US/docs/Web/API/HTMLImageElement/crossOrigin
        image.src = signedURL;
        image.onload = resolve({
          data: image,
        });
        image.onerror = reject();
      });
    },
    errMsg: 'Failed to load image',
    hideSuccessToast: true,
    suppressError,
  }),
  uploadFiles: (files = [], notify = false) => request({
    call: async () => {
      const parsedFiles = await uploadFilesToS3(files);

      const { data: { createSize } = {} } = await axios.post('/files/upload', {
        files: parsedFiles,
        notify,
      });

      return {
        data: {
          createSize,
          files: parsedFiles.map((file, index) => {
            const payloadFile = files[index].jsFileObject || {};
            return {
              ...file,
              size: payloadFile.size,
              lastUpdated: new Date().getTime(),
            };
          }),
        },
      };
    },
    errMsg: 'Failed to upload files',
  }),
  updateFile: (id, payload) => request({
    call: axios.put(`/files/${id}`, payload),
    errMsg: 'Failed to update file',
  }),
};

export default FileService;
