import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';

import {
  READ_ALL_PERM,
  READ_PERM_TYPES,
  WRITE_ALL_PERM,
  WRITE_PERM_TYPES,
} from './boardPermissions.constants';

function BoardPermissionsSelector({
  user,
  creatorId,
  isPublic,
  permMap,
  company,
  permKey,
  onChange,
}) {
  const {
    id,
  } = user;
  const permUser = permMap[id] ?? {};
  const {
    [permKey]: existingPerm,
  } = permUser;

  const isWrite = permKey === 'writePerms';

  // Disable changing permissions for owner
  const isOwnerOrCreator = (company?.userId === id) || (id === creatorId);
  const isPublicAndRead = (isPublic && permKey === 'readPerms');
  const ownerPerm = isWrite ? WRITE_ALL_PERM : READ_ALL_PERM;

  // Everyone gets view all in public boards
  const val = isOwnerOrCreator || isPublicAndRead ? ownerPerm : existingPerm;
  const disabled = isOwnerOrCreator
    || (!isPublic && (!permMap[id]))
    || isPublicAndRead
    || (isWrite && !permUser.readPerms && !isPublic); // Cant add write if no read

  const onPermTypeChange = useCallback((value) => {
    onChange({
      user,
      permKey,
      value,
    });
  }, [onChange, user, permKey]);

  const options = useMemo(() => {
    let fullOptions = isWrite ? WRITE_PERM_TYPES : READ_PERM_TYPES;
    if (isWrite && permUser?.readPerms !== READ_ALL_PERM && !isPublic) {
      // Users with readAssigned
      // Can only writeAssigned
      fullOptions = [WRITE_PERM_TYPES[0]];
    }
    return fullOptions;
  }, [isWrite, permUser, isPublic]);

  return (
    <Select
      options={options}
      value={val}
      disabled={disabled}
      onChange={onPermTypeChange}
      allowClear
      dropdownMatchSelectWidth={false}
      style={{ width: 150 }}
    />
  );
}

BoardPermissionsSelector.propTypes = {
  user: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  creatorId: PropTypes.string,
  isPublic: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
  company: PropTypes.shape({
    userId: PropTypes.string,
  }).isRequired,
  permMap: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  permKey: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

BoardPermissionsSelector.defaultProps = {
  creatorId: null,
  isPublic: false,
  permMap: {},
};

export default BoardPermissionsSelector;
