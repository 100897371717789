import React, { useMemo } from 'react';
import TitleRow from '../forms/FormBuilder/FormFields/TitleRow';

export default ({
  title,
  value,
  showCondensedView,
  valueStyle = {},
  titleStyle = {},
}) => {
  const valueDiv = useMemo(() => (
    <div
      style={{ minHeight: 20, fontSize: 12, ...valueStyle }}
      className='board-card-text-preview'
    >
      {value}
    </div>
  ), [value, valueStyle]);

  return (
    <div>
      <TitleRow
        title={title}
        optional
        filter={showCondensedView ? valueDiv : null}
        showCondensedView={showCondensedView}
        titleStyle={titleStyle}
      />
      { !showCondensedView && valueDiv}
    </div>
  );
}
