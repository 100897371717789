import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Row,
  Col,
  Typography,
  Card,
} from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import { PropTypes } from 'prop-types';
import colors from '../constants/Colors';

import { getIdMap } from '../helpers/helpers';
import BasicTimer from '../common/clock/BasicTimer';

const { Text } = Typography;
const titleStyle = {
  fontFamily: 'roboto-regular',
  fontSize: 8,
  color: colors.MODAL_COLOR,
};

const valueStyle = {
  fontFamily: 'raleway-semibold',
  fontSize: 10,
  color: colors.MODAL_COLOR,
};

export default function EquipmentTimerCard({
  task,
  style,
  onClick,
}) {
  const { t } = useTranslation();
  const projects = useSelector((state) => state.projects.projects);
  const users = useSelector((state) => state.users.users);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const { projectName, userName } = useMemo(() => {
    const { projectId, userId } = task ?? {};
    const {
      [projectId]: {
        name = '--',
      } = {},
    } = projectMap;
    const {
      [userId]: {
        name: ourUsersName = '--',
      } = {},
    } = userMap;
    return { projectName: name, userName: ourUsersName };
  }, [task, projectMap, userMap]);

  const formattedDay = useMemo(() => {
    if (!task) return {};
    const { startTime, endTime } = task;
    if (!endTime) {
      return {
        currentTask: {
          ...task,
          startTime,
          type: 'work',
        },
      };
    }
    // BasicTimer shows current time for closed tasks, relative to NOW.
    const diff = endTime - startTime;
    return {
      currentTask: {
        ...task,
        startTime: DateTime.local().toMillis() - diff,
        type: 'work',
      },
    };
  }, [task]);

  const { location } = task ?? {};
  const isCustom = !!location;

  return (
    <Card
      hoverable={onClick}
      onClick={onClick ? (e) => {
        e.stopPropagation();
        onClick();
      } : null}
      bodyStyle={{
        padding: 0,
      }}
      style={{
        borderRadius: 5,
        padding: '3px 5px 3px 5px',
        color: 'white',
        height: '100%',
        backgroundColor: task.type === 'Check In' ? colors.ONTRACCR_BLACK : colors.ONTRACCR_OPACITY_GRAY,
        ...style,
      }}
    >
      <Row justify="space-between">
        <Col span={12}>
          <Row style={{ width: '100%', lineHeight: '10px' }}>
            <Text style={titleStyle}>
              {isCustom ? 'Location' : t('Project')}
            </Text>
          </Row>
          <Row style={{ width: '100%', lineHeight: '10px' }}>
            <Text ellipsis style={valueStyle}>
              {isCustom ? location : projectName}
            </Text>
          </Row>

        </Col>
        <Col span={12}>
          <Row style={{ width: '100%', lineHeight: '10px' }} justify="end">
            <Text style={titleStyle}>
              User
            </Text>
          </Row>
          <Row style={{ width: '100%', lineHeight: '10px' }} justify="end">
            <Text ellipsis style={valueStyle}>
              {userName}
            </Text>
          </Row>

        </Col>
      </Row>
      <Row justify="space-around" type="flex">
        <BasicTimer
          formattedDay={formattedDay}
          style={{
            fontSize: 16,
            width: '100%',
            textAlign: 'center',
            fontFamily: 'raleway-semibold',
            color: colors.MODAL_COLOR,
          }}
        />
      </Row>
    </Card>
  );
}

EquipmentTimerCard.propTypes = {
  task: PropTypes.object,
  style: PropTypes.object,
  onClick: PropTypes.func.isRequired,
};

EquipmentTimerCard.defaultProps = {
  task: {},
  style: {},
};
