/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row,
  Col,
  Checkbox,
  Select,
} from 'antd';
import {
  GroupOutlined,
  SettingOutlined,
} from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';
import UpdateAddTimestampCheckbox from './UpdateAddTimestampCheckbox';
import WorkflowHandle from './WorkflowHandle';
import FormColorPicker from '../../common/inputs/FormColorPicker';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { getType, updateData } from './workflowHelpers';
import useToggle from '../../common/hooks/useToggle';
import { getBoardDetails } from '../../boards/state/boards.actions';
import { isFieldValid } from './update.constants';
import { getFieldMap } from '../formHelpers';
import HoverHelp from '../../common/HoverHelp';

const { Option } = Select;

const HOVER_TEXT = 'This step can update a board card based on the fields submitted.';
const DISABLED_TEXT = 'Add a Card Choose One or More field to enable this step';

export default function WorkflowCardUpdateNode({
  isDisplay,
  setElements,
  setDataMap,
  name,
  sections = [],
} = {}) {
  return function _({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isToggled, toggle } = useToggle();

    const {
      fieldMappings: initialFieldMappings = {},
      boardId: initialBoardId,
      cardFieldId: initialCardFieldId,
      status: initialStatus,
      color: initialCardColor,
      shouldOverride: initialShouldOverride = true,
      addTimestamps,
    } = data;

    const title = `Update Board Card${disabled ? ' - DISABLED' : ''}`;

    const boards = useSelector((state) => state.boards.boards);
    const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);
    const cardTemplates = useSelector((state) => state.boards.cardTemplates);
    const customTables = useSelector((state) => state.forms.customTables);

    const [selectedCardColor, setSelectedCardColor] = useState(initialCardColor);
    const [selectedBoardId, setSelectedBoardId] = useState(initialBoardId);
    const [selectedCardFieldId, setSelectedCardFieldId] = useState(initialCardFieldId);
    const [selectedFieldMappings, setSelectedFieldMappings] = useState(initialFieldMappings);
    const [selectedStatus, setSelectedStatus] = useState(initialStatus);
    const [override, setOverride] = useState(initialShouldOverride);

    const setMap = {
      color: setSelectedCardColor,
      boardId: setSelectedBoardId,
      cardFieldId: setSelectedCardFieldId,
      fieldMappings: setSelectedFieldMappings,
      status: setSelectedStatus,
    };
    const onDataChange = useCallback((key) => (newValue) => {
      if (!setDataMap || !id) return;
      setMap[key]?.(newValue);
      setDataMap(updateData(id, { [key]: newValue }));
      if (key === 'shouldOverride') {
        setOverride(newValue);
      }
    }, [id, setDataMap]);
    const onCheckChanged = useCallback((key) => (e) => {
      const {
        target: {
          checked,
        } = {},
      } = e;
      onDataChange(key)(checked);
    }, [onDataChange]);

    const onTimestampChanged = useCallback((newValue) => {
      setDataMap(updateData(id, { addTimestamps: newValue }));
    }, [setDataMap, id]);

    const cardTypeId = useMemo(() => boards[selectedBoardId]?.cardTypeId, [
      boards,
      selectedBoardId,
    ]);
    const cardSections = useMemo(() => cardTemplates?.[cardTypeId]?.fields || [], [
      cardTemplates,
      cardTypeId,
    ]);
    const {
      flattenedCardFields,
    } = useMemo(() => {
      const fields = [];
      cardSections.forEach((section) => {
        const { fields: sectionFields = [], name: sectionName } = section;
        sectionFields.forEach((field) => {
          const {
            configProps: {
              title: fieldTitle,
              dataType: fieldDataType,
            } = {},
            selectedType: fieldType,
            id: fieldId,
          } = field;

          if (isFieldValid(field, customTables)) {
            fields.push({
              key: fieldId,
              text: `${sectionName} - ${fieldTitle}`,
              type: fieldType,
              dataType: fieldDataType,
            });
          }
        });
      });
      return {
        flattenedCardFields: fields,
      };
    }, [cardSections, customTables]);

    const cardFieldOptions = useMemo(() => sections.map(({ fields = [] }) => (
      fields.filter((field) => {
        const type = getType(field);
        return type === 'dropdown - Cards';
      })
    ))
      .flat()
      .map((field) => {
        const {
          configProps: { title: fieldTitle } = {},
          id: fieldId,
        } = field;
        return { label: fieldTitle, value: fieldId };
      }), [sections]);

    const fieldMap = useMemo(() => getFieldMap(sections, 'id'), [sections]);
    const cardFieldBoard = useMemo(() => {
      const {
        configProps: {
          subDataType,
        } = {},
      } = fieldMap[selectedCardFieldId] || {};
      return subDataType;
    }, [fieldMap, selectedCardFieldId]);

    const boardOptions = useMemo(() => {
      const options = Object.keys(boards).map((boardId) => (
        <Option key={boardId} value={boardId} label={boards[boardId].title}>
          {boards[boardId].title}
        </Option>
      ));
      return options;
    }, [boards]);

    const statusOptions = useMemo(() => {
      const {
        [selectedBoardId]: boardDetails,
      } = boardDetailsMap;
      if (!boardDetails?.statuses) return [];
      const options = boardDetails.statuses?.map?.((status) => (
        <Option key={status.id} value={status.id} label={status.title}>
          {status.title}
        </Option>
      )) ?? [];
      return options;
    }, [boardDetailsMap, selectedBoardId]);

    useEffect(() => {
      if (selectedBoardId) {
        dispatch(getBoardDetails(selectedBoardId, false));
      }
    }, [selectedBoardId]);

    useEffect(() => {
      if (cardFieldBoard) {
        onDataChange('boardId')(cardFieldBoard);
      }
    }, [cardFieldBoard]);

    useEffect(() => {
      const {
        [selectedBoardId]: boardDetails,
      } = boardDetailsMap;
      if (!boardDetails?.statuses?.some?.((status) => status.id === selectedStatus)) {
        onDataChange('status')(undefined);
      }
    }, [boardDetailsMap, selectedBoardId, selectedStatus]);

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        type="updateBoardCard"
        Icon={GroupOutlined}
        draggable={draggable}
        isDisplay={isDisplay || disabled}
        onNodeUpdate={setElements}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable
          && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Link Card Field
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onDataChange('cardFieldId')}
                value={selectedCardFieldId}
                showSearch
                optionFilterProp="label"
                options={cardFieldOptions}
              />
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Board')}
              {' '}
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onDataChange('boardId')}
                value={selectedBoardId}
                showSearch
                optionFilterProp="label"
                disabled={!selectedCardFieldId || cardFieldBoard}
              >
                {boardOptions}
              </Select>
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Status')}
              {' '}
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onDataChange('status')}
                value={selectedStatus}
                showSearch
                disabled={!selectedBoardId}
                allowClear
              >
                {statusOptions}
              </Select>
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Card Color')}
              {' '}
            </Row>
            <FormColorPicker isNotDisplay allowClear value={selectedCardColor} onChange={onDataChange('color')} />
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={toggle}
              />
            </Row>
            <Row style={{ width: '100%', margin: '5px 0px' }}>
              <Col>
                <Checkbox
                  onChange={onCheckChanged('shouldOverride')}
                  defaultChecked={initialShouldOverride}
                />
              </Col>
              <Col style={{ padding: '0px 5px' }}>
                Override Card
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ width: 300 }}>
                      If turned on, the card data will be overrided with the new data.
                      <br />
                      <br />
                      If turned off, the card data will be appended to the existing data.
                    </div>
                  )}
                />
              </Col>
            </Row>
            {!override && (
              <UpdateAddTimestampCheckbox
                onChange={onTimestampChanged}
                initialChecked={addTimestamps}
              />
            )}
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={isToggled}
              onClose={toggle}
              onSubmit={toggle}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={selectedFieldMappings}
              onFieldMappingsChange={onDataChange('fieldMappings')}
              data={data}
              setDataMap={setDataMap}
              fields={flattenedCardFields}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
          )}
      </WorkflowActionNode>
    );
  };
}
