import React from 'react';
import {
  Row, Col, Input, Dropdown,
} from 'antd';
import { PlusOutlined, DownOutlined } from '@ant-design/icons';
import OnTraccrButton from './buttons/OnTraccrButton';
import colors from '../constants/Colors';

const filterSpan = 5;
const addSpan = 6;

export default function SideListSearchHeader({
  onClick,
  onChange,
  menu,
  activeItem,
  submissionView,
  filterView,
}) {
  let mainSpan = 24;
  if (onClick) mainSpan -= addSpan;
  if (filterView) mainSpan -= filterSpan;

  return (
    <Row
      type="flex"
      align="middle"
      justify="space-around"
      style={{
        height: '100%',
        width: '100%',
      }}
      gutter={2}
    >
      <Col
        span={mainSpan}
        style={{
          padding: 10,
        }}
      >
        <Row style={{ width: '100%', margin: 0 }} type="flex" justify="start" gutter={menu ? 8 : 0}>
          <Col span={menu ? 12 : 24}>
            <Input.Search
              className="searchbar"
              placeholder="Search"
              onChange={onChange}
              allowClear
            />
          </Col>
          <Col span={menu ? 12 : 0}>
            {menu && (
            <Dropdown overlay={menu} trigger={['click']} placement="bottomCenter">
              <OnTraccrButton
                className="ant-dropdown-link"
                onClick={(e) => e.preventDefault()}
                icon={<DownOutlined />}
                style={{
                  width: '100%',
                  backgroundColor: colors.ONTRACCR_DARK_YELLOW,
                  color: colors.ONTRACCR_TITLE_BLACK,
                  borderColor: colors.ONTRACCR_TITLE_BLACK,
                }}
                titleStyle={{
                  width: 'calc(100% - 20px)',
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  verticalAlign: 'top',
                }}
                title={activeItem}
              />
            </Dropdown>
            )}
          </Col>
          {submissionView || <></>}
        </Row>
      </Col>
      {filterView && (
      <Col span={filterSpan}>
        {filterView}
      </Col>
      )}
      {onClick ? (
        <Col span={addSpan}>
          <OnTraccrButton title="Add" icon={<PlusOutlined />} onClick={onClick} />
        </Col>
      ) : null}
    </Row>
  );
}
