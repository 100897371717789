import React, { useEffect, useMemo, useState } from 'react';
import {
  Col, Modal, Select, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { getShifts } from '../state/schedule.actions';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';

export default function FieldShiftModalSelector({
  visible, shiftIds, onSubmit, onClose,
}) {
  const dispatch = useDispatch();

  const shiftMap = useSelector((state) => state.schedule.shiftMap) ?? {};

  const [selectedShift, setSelectedShift] = useState(null);

  const shiftOptions = useMemo(() => shiftIds.map((id) => ({
    key: id,
    value: id,
    label: shiftMap[id]?.title,
  })), [shiftIds, shiftMap]);

  useEffect(() => {
    dispatch(getShifts(shiftIds));
  }, [shiftIds]);

  const footer = useMemo(() => (
    <DrawerSubmitFooter
      onSubmit={() => onSubmit(selectedShift)}
      onClose={onClose}
      canSubmit={!!selectedShift}
    />
  ), [onSubmit, onClose, selectedShift]);

  return (
    <Modal
      bodyStyle={{
        background: 'white', padding: '0.5em', paddingBottom: '2em',
      }}
      onOk={() => onSubmit(selectedShift)}
      visible={visible}
      onCancel={onClose}
      footer={footer}
      zIndex={1001}
    >
      <Col
        justify="left"
        style={{
          width: '100%', display: 'flex', flexDirection: 'column', gap: 6,
        }}
      >
        <Typography.Text style={{
          marginRight: '1em', width: '100%', textAlign: 'left',
        }}
        >Select a shift
        </Typography.Text>
        <Typography.Text style={{
          marginRight: '1em', width: '100%', textAlign: 'left', fontSize: 15,
        }}
        > Select a shift that was dispatched from the Gantt Task
        </Typography.Text>
        <Select options={shiftOptions} value={selectedShift} onChange={setSelectedShift} />

      </Col>
    </Modal>
  );
}

FieldShiftModalSelector.propTypes = {
  shiftIds: PropTypes.arrayOf(PropTypes.string),
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

FieldShiftModalSelector.defaultProps = {
  shiftIds: [],
  visible: false,
};
