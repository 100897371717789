import {
  GET_ALL_NOTIFICATIONS,
  DELETE_NOTIFICATION,
  MARK_READ_NOTIFICATION,
} from '../../state/actionTypes';
import NotificationsService from './notifications.service';


export const createNotification = (notification) => async () => {
  await NotificationsService.create(notification);
};

export const getNotifications = () => async (dispatch) => {
  const notifications = await NotificationsService.getAll();
  dispatch({
    type: GET_ALL_NOTIFICATIONS,
    payload: {
      notifications,
    },
  });
};

export const deleteNotification = (notificationId) => async (dispatch) => {
  const { data: id } = await NotificationsService.delete(notificationId);
  if (!id) return false;
  dispatch({
    type: DELETE_NOTIFICATION,
    payload: {
      id,
    },
  });
  return true;
};

export const markAsRead = (notificationId) => async (dispatch) => {
  const { data: id } = await NotificationsService.markAsRead(notificationId);
  if (!id) return false;
  dispatch({
    type: MARK_READ_NOTIFICATION,
    payload: {
      id,
    },
  });
  return true;
};
