import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { Select, Divider } from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import OnTraccrTextInput from './OnTraccrTextInput';
import OnTraccrButton from '../buttons/OnTraccrButton';

const baseGetTitle = (option) => option.title;
export default function ({
  type,
  options: baseOptions = [],
  value,
  onSelect,
  onAddNew,
  mode,
  renderOption = baseGetTitle,
  dropdownWidth = 150,
  dropdownMatchSelectWidth = false,
}) {
  const [newOption, setNewOption] = useState();
  const [open, setOpen] = useState(false);
  const map = useMemo(() => {
    const innerMap = new Map();
    baseOptions.forEach((e) => innerMap.set(e.title, e.id));
    return innerMap;
  }, [baseOptions])

  const onDropdownVisibleChange = useCallback((newOpen) => {
    if (!newOpen) setNewOption();
    setOpen(newOpen);
  }, []);

  const onNewOptionChange = useCallback((e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    setNewOption(newValue);
  }, []);

  const onAddClicked = useCallback(() => {
    if (!map.has(newOption)) {
      onAddNew(newOption);
      setOpen(false);
      setNewOption();
      return;
    }
    const existingOption = map.get(newOption);
    if (value.find((id) => id === existingOption)) {
      onChange(value);
    } else {
      onChange(value.concat(existingOption));
    }
    setOpen(false);
    setNewOption();
  }, [onAddNew, newOption]);

  const onChange = useCallback((newSelection) => {
    onSelect(newSelection === undefined ? null : newSelection);
  }, [onSelect]);
  const opts = useMemo(() => (
    baseOptions.map((option) => {
      const text = renderOption(option);
      return (
        <Select.Option key={option.id} value={option.id} label={text}>
          {text}
        </Select.Option>
      );
    })
  ), [baseOptions, renderOption]);

  return (
    <Select
      allowClear
      mode={mode}
      placeholder={`Select ${type}`}
      value={value}
      onChange={onChange}
      onDropdownVisibleChange={onDropdownVisibleChange}
      open={open}
      dropdownMatchSelectWidth={dropdownMatchSelectWidth}
      optionFilterProp="label"
      dropdownRender={(menu) => (
        <div style={{ width: dropdownWidth, padding: '0px 5px' }}>
          {menu}
          <Divider style={{ margin: '4px 0' }} />
          Custom:
          <OnTraccrTextInput
            onChange={onNewOptionChange}
            value={newOption}
            style={{ margin: '5px 0px' }}
            onKeyDown={(e) => e.stopPropagation()}
          />
          <OnTraccrButton
            icon={<PlusOutlined />}
            onClick={onAddClicked}
            title="Add"
            disabled={!newOption}
          />
        </div>
      )}
    >
      {opts}
    </Select>
  );
}
