import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';

const BASE_COL_WIDTH = 150;


const getColumns = ({
  options,
  onValueChanged,
  onDelete,
}) => {
  const baseOptions = [
    {
      title: '',
      dataIndex: '',
      width: 30,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red', marginLeft: 0 }} />}
          onClick={() => onDelete(record.id)}
        />
      ),
    },
  ];

  options.forEach((option) => {
    const {
      key,
      title,
    } = option;
    baseOptions.push({
      title,
      dataIndex: key,
      width: BASE_COL_WIDTH,
      render: (value, _, index) => (
        <OnTraccrTextInput
          value={value}
          onChange={(e) => {
            const {
              target: {
                value: newValue,
              } = {},
            } = e;
            onValueChanged(index, { [key]: newValue });
          }}
        />
      ),
    });
  });

  return baseOptions;
};

export default function FormUploadPreview({
  options = [],
  selectedSheet = [],
  headerMapping = {},
  onMassUploadDataChanged,
}) {
  const [data, setData] = useState([]);
  const onValueChanged = useCallback((index, newData) => {
    setData(
      data.map((datum, i) => {
        if (i !== index) return datum;
        return {
          ...datum,
          ...newData,
        };
      }),
    );
  }, [data]);
  const onDelete = useCallback((id) => {
    const newData = [...data];
    setData(newData.filter((datum) => datum.id !== id));
  }, [data]);

  const columns = useMemo(() => (
    getColumns({ options, onValueChanged, onDelete })
  ), [onValueChanged, onDelete, options]);

  const titleToKeyMap = useMemo(() => {
    // Convert header mappings from key: title to title: key
    const convertedMap = {};
    Object.keys(headerMapping).forEach((key) => {
      const title = headerMapping[key];
      if (!(title in convertedMap)) {
        convertedMap[title] = [];
      }
      convertedMap[title].push(key);
    });
    return convertedMap;
  }, [headerMapping]);

  useEffect(() => {
    const newData = selectedSheet.map((row, index) => {
      const parsedRow = {};
      Object.keys(row).forEach((rowTitle) => {
        const {
          [rowTitle]: rowKeys = [],
        } = titleToKeyMap;
        if (rowKeys.length === 0) return;
        rowKeys.forEach((rowKey) => {
          parsedRow[rowKey] = row[rowTitle];
        });
      });
      parsedRow.id = index;
      return parsedRow;
    });
    setData(newData);
  }, [titleToKeyMap, selectedSheet]);

  useEffect(() => {
    onMassUploadDataChanged(data);
  }, [data]);

  return (
    <Table
      size="small"
      columns={columns}
      dataSource={data}
      scroll={{ x: 'max-content' }}
    />
  );
}

FormUploadPreview.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })),
  selectedSheet: PropTypes.arrayOf(PropTypes.shape({})),
  headerMapping: PropTypes.shape({}),
  onMassUploadDataChanged: PropTypes.func,
};

FormUploadPreview.defaultProps = {
  options: [],
  selectedSheet: [],
  headerMapping: {},
  onMassUploadDataChanged: () => {},
};
