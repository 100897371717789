import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Row,
  Table,
} from 'antd';

import SettingsCardHeader from '../SettingsCardHeader';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import useToggle from '../../common/hooks/useToggle';
import IconPicker from '../../common/IconPicker/IconPicker';
import CompanySettingsCard from '../CompanySettingsCard';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import Colors from '../../constants/Colors';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import {
  createBucketTemplate,
  deleteBucketTemplate,
  getBucketTemplates,
  updateBucketTemplate,
} from '../../buckets/state/buckets.actions';
import BucketTemplateAddEditDrawer from './BucketTemplateAddEditDrawer';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

const DEFAULT_ICON = 'AntDesignOutlined';

export default function BucketTemplateTabView() {
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);

  const { isToggled, toggle } = useToggle();

  const [selectedEntry, setSelectedEntry] = useState();
  const [sections, setSections] = useState([]);

  useEffect(() => {
    dispatch(getBucketTemplates());
  }, []);

  const closeModal = useCallback(() => {
    setSelectedEntry();
    toggle();
  }, [toggle]);

  const onSave = useCallback(async (linkMap = {}) => {
    try {
      const payload = await form.validateFields();
      payload.icon = payload.icon || DEFAULT_ICON;
      payload.shouldShowPage = !!payload.shouldShowPage;
      payload.linkMap = linkMap;
      payload.sections = sections;
      const result = selectedEntry
        ? await dispatch(updateBucketTemplate(selectedEntry.id, payload))
        : await dispatch(createBucketTemplate(payload));
      if (result) closeModal();
    } catch (err) {
      // fail silently
    }
  }, [closeModal, selectedEntry, sections]);

  const onDeleteClicked = useCallback(async (record) => new Promise((resolve) => {
    CustomConfirmModal({
      title: `Delete Bucket ${record.name}?`,
      content: 'Are you sure you want to delete this bucket?',
      onOk: async () => {
        await dispatch(deleteBucketTemplate(record.id));
        resolve();
      },
      okText: 'Delete',
    });
  }, []));

  const columns = useMemo(() => [
    {
      title: 'Icon',
      dataIndex: 'icon',
      key: 'icon',
      onHeaderCell,
      render: (icon) => <IconPicker value={icon} isDisplay />,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      onHeaderCell,
    },
    {
      dataIndex: 'edit',
      align: 'edit',
      width: 60,
      onHeaderCell,
      render: (_, record) => {
        const { id } = record || {};
        return (
          <BorderlessButton
            key={id}
            title=""
            icon="edit"
            color={Colors.ONTRACCR_BLACK}
            onClick={() => {
              setSelectedEntry(record);
              toggle();
            }}
            style={{
              paddingRight: 8,
              paddingLeft: 0,
            }}
          />
        );
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      width: 60,
      onHeaderCell,
      render: (_, record) => (
        <BorderlessButton
          title=""
          icon="delete"
          color={Colors.opacity(Colors.ONTRACCR_RED, record.isDefault ? 0.3 : 1)}
          onClick={() => {
            if (record.isDefault) return;
            onDeleteClicked(record);
          }}
          style={{
            paddingRight: 8,
            paddingLeft: 0,
            cursor: record.isDefault ? 'not-allowed' : 'pointer',
          }}
        />
      ),
    },
  ], [onDeleteClicked, toggle]);

  useEffect(() => {
    if (!isToggled) {
      setSelectedEntry();
      setSections([]);
      return form.resetFields();
    }

    return form.setFieldsValue(selectedEntry);
  }, [isToggled, form, selectedEntry]);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
        marginLeft: 0,
        marginTop: 30,
      }}
    >
      <SettingsCardHeader
        title="Buckets"
      >
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={toggle}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        style={{
          position: 'absolute',
          top: 130,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <Table
          columns={columns}
          dataSource={bucketTemplates}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
        />
      </CompanySettingsCard>
      <BucketTemplateAddEditDrawer
        selectedEntry={selectedEntry}
        visible={isToggled}
        toggle={toggle}
        onSave={onSave}
        form={form}
        setSections={setSections}
      />
    </Row>
  );
}
