import React, {
  useState,
  useCallback,
  useMemo,
  useEffect,
} from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import TableAddDrawer from './TableAddDrawer';

import { includesTerm } from '../../../helpers/helpers';
import useValidateMaxSelect from '../../../common/hooks/useValidateMaxSelect';

export default function CustomSavedTableAddDrawer({
  visible,
  onClose,
  onSubmit,
  options,
  identifier,
  maxExistingAllowed,
}) {
  const [searchStr,setSearchStr] = useState();
  const [selected,setSelected] = useState([]);

  const onSubmitClicked = useCallback(() => {
    const isValid = useValidateMaxSelect({
      maxExistingAllowed,
      selected,
      type: 'item(s)',
    });
    if (isValid) onSubmit(selected);
  },[onSubmit, selected, maxExistingAllowed]);

  const onSelect = useCallback((selectedOption, checked) => {
    if(checked) {
      const newSelected = selected.concat([selectedOption.id]);
      setSelected(newSelected);
    } else {
      setSelected(selected.filter((optId) => optId !== selectedOption.id))
    }
  },[selected]);

  const onSelectAll = useCallback((checked) => {
    setSelected(
      checked
      ? options.map((opt) => opt.id)
      : []
    );
  },[]);

  useEffect(() => {
    if(!visible) {
      setSearchStr();
      setSelected([]);
    }
  },[visible]);

  const data = useMemo(() => (
    options.filter((option) => includesTerm(option[identifier],searchStr))
  ),[options, identifier, searchStr]);

  return (
    <TableAddDrawer
      title='Add Option'
      visible={visible}
      onClose={onClose}
      onSearch={setSearchStr}
      onSubmit={onSubmitClicked}
      bodyStyle={{ left: 0, right: 0 }}
      searchStr={searchStr}
    >
      <Table
        columns={[{
          title:'Name',
          dataIndex:identifier,
        }]}
        dataSource={data}
        rowKey='id'
        rowSelection={{
          selectedRowKeys:selected,
          onSelect,
          onSelectAll,
        }}
        pagination={false}
      />
    </TableAddDrawer>
  )
}

CustomSavedTableAddDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  options: PropTypes.arrayOf(PropTypes.shape({})),
  identifier: PropTypes.string,
  maxExistingAllowed: PropTypes.number,
};

CustomSavedTableAddDrawer.defaultProps = {
  visible: false,
  onClose: () => {},
  onSubmit: () => {},
  options: [],
  identifier: '',
  maxExistingAllowed: null,
};
