import React from 'react';
import { Button, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';

import { pdfAnnotationIconTypes } from './pdfAnnotationTypes.constants';

export default function PDFAnnotationButton({
  annotationType,
  annotationMode,
  onAnnotationModeChanged,
}) {
  const isActive = annotationMode === annotationType;
  const onAnnotationModeClicked = (mode) => () => {
    const newMode = isActive ? null : mode;
    onAnnotationModeChanged(newMode);
  };

  const AnnotationIcon = pdfAnnotationIconTypes[annotationType];

  return (
    <Tooltip placement="bottom" title={isActive ? 'End Annotating' : 'Start Annotating'}>
      <Button
        className="pdf-control-button"
        icon={
          isActive ? <CloseOutlined /> : <AnnotationIcon />
        }
        onClick={onAnnotationModeClicked(annotationType)}
      />
    </Tooltip>
  );
}

PDFAnnotationButton.propTypes = {
  annotationType: PropTypes.string.isRequired,
  annotationMode: PropTypes.string.isRequired,
  onAnnotationModeChanged: PropTypes.func.isRequired,
};
