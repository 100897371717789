import React from 'react';
import { Button } from 'antd'; 
import Permissions from '../auth/Permissions';


export const getBody = ({
  history,
  ownerId,
  freeUserLimit = 4,
  userLimit = freeUserLimit,
  style = {},
  onClick,
  subscriptionOver,
}) => {
  if(Permissions.id === ownerId) {
    return (
      <div style={style}>
        If you need more than {subscriptionOver ? freeUserLimit : userLimit} active users,
        navigate to 
        <Button
          type='link'
          style={{ padding: '0px 3px' }}
          onClick={() => {
            if(onClick) onClick();
            history.push('/settings',{ activeTab: 'billing' });
          }}
        > 
        your settings page
        </Button>to upgrade your account.
      </div>
    )
  }
  return (
    <div>
      Contact your account owner to upgrade your account
    </div>
  )
};

export default (props) => {
  const {
    subscriptionOver,
    freeUserLimit = 4,
    userLimit = 4,
  } = props;
  return (<div style={{ maxWidth: 320, overflow:'hidden', overflowWrap:'break-word'}}>
    Your account is limited to {subscriptionOver ? freeUserLimit : userLimit} active users.
    <br/>
    <br/>
    {getBody(props)}
  </div>
);
}