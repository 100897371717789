import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Spin } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrEmpty from '../common/OnTraccrEmpty';

import SubtasksHeader from './SubtasksHeader';
import SubtaskDrawer from './SubtaskDrawer';
import SubtaskTable from './SubtaskTable';

import { getSubTasks } from './state/subtasks.actions';

import { PRIORITY_MAP } from './subtask.constants';

import { includesTerm } from '../helpers/helpers';
import { getCategory, getDueDateCategory } from '../helpers/subtasks';

export default function Subtasks({
  query,
  showCategory = false,
  filter,
  filterActive = false,
  activeFilters,
}) {
  const dispatch = useDispatch();

  const subtasks = useSelector((state) => state.subtasks.subtasks);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [editMode, setEditMode] = useState(false);

  const onAddClicked = () => setDrawerOpen(true);
  const onDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedTaskId();
    setEditMode(false);
  };

  const onTaskEdit = useCallback((taskId) => {
    setSelectedTaskId(taskId);
    setDrawerOpen(true);
    setEditMode(true);
  }, []);

  const onTaskClicked = useCallback((task) => {
    setSelectedTaskId(task.id);
    setDrawerOpen(true);
  }, []);

  const subtaskList = useMemo(() => {
    const list = Object.values(subtasks);
    // Default sort to highest priority
    list.sort((a, b) => PRIORITY_MAP[b.priority] - PRIORITY_MAP[a.priority]);
    return list;
  }, [subtasks]);

  const filteredList = useMemo(() => {
    let relevantList = subtaskList;

    if (searchTerm?.length > 0) {
      relevantList = relevantList.filter((st) => (
        includesTerm(st.title ?? '', searchTerm) || includesTerm(st.description ?? '', searchTerm)
      ));
    }

    if (showCategory) {
      relevantList = relevantList.map((item) => ({
        ...item,
        category: getCategory(item),
        dueDateCategory: getDueDateCategory(item),
      }));
    }

    if (activeFilters) {
      relevantList = relevantList.filter(
        (st) => activeFilters.category.has(st.category),
      );
    }

    return relevantList;
  }, [subtaskList, searchTerm, activeFilters, showCategory]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getSubTasks(query));
      setLoading(false);
    };
    if (query) load();
  }, [query]);

  let body;

  if (filteredList.length === 0 || loading) {
    body = (
      <Row justify='center' align='middle' style={{ width: '100%', height: '100%' }}>
        <Col>
          {loading ? <Spin/> : <OnTraccrEmpty/>}
        </Col>
      </Row>
    );
  } else {
    body = (
      <SubtaskTable
        subtasks={filteredList}
        onTaskClick={onTaskClicked}
        onTaskEdit={onTaskEdit}
        showCategory={showCategory}
      />
    );
  }

  return (
    <div style={{ width: '100%', height: '100%' }}>
      <SubtasksHeader
        onAddClicked={onAddClicked}
        onSearch={setSearchTerm}
        filter={filter}
        filterActive={filterActive}
      />
      {body}
      <SubtaskDrawer
        visible={drawerOpen}
        onClose={onDrawerClose}
        query={query}
        selectedTaskId={selectedTaskId}
        editMode={editMode}
        onEditModeChange={setEditMode}
        showCategory={showCategory}
      />
    </div>
  );
}

Subtasks.propTypes = {
  query: PropTypes.shape({}),
  showCategory: PropTypes.bool,
  filter: PropTypes.func,
  filterActive: PropTypes.bool,
  activeFilters: PropTypes.shape({
    category: PropTypes.instanceOf(Set),
  }),
};

Subtasks.defaultProps = {
  query: null,
  showCategory: false,
  filter: null,
  filterActive: false,
  activeFilters: null,
};
