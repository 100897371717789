import React from 'react';
import PropTypes from 'prop-types';

import DynamicSearchWithDropdown from '../common/inputs/DynamicSearchWithDropdown';

import MaterialSearchDropdownItem from './MaterialSearchDropdownItem';

function MaterialSearchDropdown({
  onMaterialSelect,
}) {
  return (
    <DynamicSearchWithDropdown
      path="/materials/search"
      onItemClick={onMaterialSelect}
      Item={MaterialSearchDropdownItem}
    />
  );
}

MaterialSearchDropdown.propTypes = {
  onMaterialSelect: PropTypes.func.isRequired,
};

export default MaterialSearchDropdown;
