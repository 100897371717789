import React from 'react';
import { Row, Select, Divider } from 'antd';
import { useTranslation } from 'react-i18next';
import Colors from '../../constants/Colors';
import DisplayText from '../../common/text/DisplayText';
import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import {
  getTimeCardRows,
} from './timeTrackingConstants';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import Editable from '../../common/editable/Editable';
const { Option } = Select;
const defaultText = `By signing below I agree that this time card is accurate
and that I was not injured during this duration`;

export default ({ settings, saveCompanySettings, users, positionNames = [], firstApprover, secondApprover,approverPositions }) => {
  const { t } = useTranslation();
  const validApproverPositions = positionNames.filter(({ name }) => approverPositions.has(name));
  const validApproverUsers = users.filter(({ position }) => approverPositions.has(position));

  const timeCardRows = getTimeCardRows(t);

  return (
    <CompanySettingsCard title='Time Cards'>
      <CheckRows
        data={timeCardRows.slice(0,3)}
        isChecked={(item) => settings[item.key]}
        onChange={(item) => saveCompanySettings({
          [item.key]: !settings[item.key],
        })}
      />

      <Row justify='left' style={{width:'100%', margin: 10, }}>
        <DisplayText title={'Companywide Time Card Approver(s)'} style={{ fontFamily: 'roboto-medium', marginBottom:0}}/>
        <DisplayText title={'Identify which users or role types will be able to approve submitted time cards for users who do not already have an assigned  approver within the project settings.'} style={{
            fontFamily: 'roboto-regular', marginBottom:0,color:Colors.ONTRACCR_OPACITY_GRAY
          }}/>
            <DisplayText title={'First Approver'} style={{ fontFamily: 'roboto-regular', marginBottom:0}}/>
            <Select style={{
                width:300
              }}
              defaultValue={firstApprover}
              placeholder={firstApprover || 'Any Admin'}
              onChange={(item) => {
                if (!item) {
                  const adminObject = validApproverPositions.find(({ name }) => name == "Admin");
                  item = adminObject?.id || null;
                }
                saveCompanySettings({
                  firstApprover: item,
                })
              }}
              allowClear
              showSearch
              filterOption={(input, option) => {
                return typeof(option.props.children)=='string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
            >
              {validApproverPositions.map(p=>{
                return (<Option key={p.id+'2'} value={p.id}>Any {p.name}</Option>)
              })}
              {validApproverUsers.map(u=>{
                return (<Option key={u.id+'1'} value={u.id}>{u.name}</Option>)
              })}
            </Select>
            <DisplayText title={'Second Approver'} style={{ fontFamily: 'roboto-regular', marginBottom:0}}/>

            <Select style={{
                width:300
              }}
              defaultValue={secondApprover}
              placeholder={secondApprover || 'Select the second default approver/group'}
              onChange={(item) => {
                saveCompanySettings({
                  secondApprover: item || null,
                })
              }}
              allowClear
              showSearch
              filterOption={(input, option) => {
                return typeof(option.props.children)=='string' && option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              }
            >
              <Option key={'no1apr'} value={null}>None</Option>
              {validApproverPositions.map(p=>{
                return (<Option key={p.id+'22'} value={p.id}>Any {p.name}</Option>)
              })}
              {validApproverUsers.map(u=>{
                return (<Option key={u.id+'12'} value={u.id}>{u.name}</Option>)
              })}
            </Select>
      </Row>
      <Divider style={{
        margin:0,
        backgroundColor:Colors.ONTRACCR_OPACITY_GRAY,
        maxWidth:440,
        minWidth:440,
      }}/>
      <CheckRows
        data={[timeCardRows[3]]}
        isChecked={(item) => settings[item.key]}
        onChange={(item) => saveCompanySettings({
          [item.key]: !settings[item.key],
        })}
      />
      <Row justify='center' style={{width:'100%', margin: 10, marginTop:20 }}>
      <Editable
            key={'disclaimer'}
            name={'disclaimer'}
            defaultValue={settings.disclaimer || defaultText}
            style={{ marginBottom: 0, textAlign:'center',}}
            onChange={(type,value) => saveCompanySettings({
              disclaimer:value,
            })}
            inputNode={({
              setReturnValue,
              setFocus,
              setHover,
              defaultValue,
            }) =>
              <OnTraccrTextInput
                textarea
                style={{textAlign:'center', width:375 }}
                defaultValue={defaultValue}
                placeholder={'Enter Disclaimer Text'}
                /*
                  onBlur needs a timeout due to a race condition.
                  If user clicks checkmark, onBlur will fire, as will onClick for
                  the check. These will race, causing the input to not be saved
                  if onBlur executes first. We delay by 100ms to allow
                  onClick check to execute first.
                */
                onBlur={() => setTimeout(() => setFocus(false),100)}
                onMouseLeave={() => setHover(false)}
                onChange={(e) => setReturnValue({ name:'disclaimer', value:e.target.value })}
                onClick={() => setFocus(true)}
              />
            }
          />
        </Row>
    </CompanySettingsCard>
  );
}
