import {
  SET_MENU,
  SET_MENU_OPEN_KEYS,
} from '../../state/actionTypes';

const initialState = {
  items: [],
  openKeys: [],
};

export default function menuActions(state = initialState, action) {
  switch (action.type) {
    case SET_MENU:
      return {
        ...state,
        items: action.payload.items,
      };
    case SET_MENU_OPEN_KEYS:
      return {
        ...state,
        openKeys: action.payload.openKeys,
      };
    default:
      return state;
  }
}
