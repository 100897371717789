import React, { useMemo, useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Input, Row } from 'antd';
import { useTranslation } from 'react-i18next';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

import CostCodeUploadList from '../costcodes/costcodeUploadList';
import { includesTerm } from '../helpers/helpers';

export default ({
  divisionId,
  categoryId,
  category,
  visible,
  onClose,
  onSubmit,
  chosen = [],
}) => {
  const { t } = useTranslation();
  const costcodes = useSelector(state => state.costcodes.costcodes);

  const [selected,setSelected] = useState([]);
  const [searchText, setSearchText] = useState(null);

  const submit = useCallback(() => onSubmit(selected),[onSubmit,selected]);

  useEffect(() => {
    setSelected([]);
  },[visible]);

  const data = useMemo(() => {
    const chosenSet = new Set(chosen.map(({ id }) => id));
    return costcodes.filter((costcode) => (
      costcode.active
      && !costcode.projectId
      && costcode.divisionId === divisionId
      && !chosenSet.has(costcode.id)
      && costcode.categoryId === categoryId
      && (!searchText
        || includesTerm(costcode.name, searchText)
        || includesTerm(costcode.code, searchText)
        || includesTerm(costcode.description, searchText)
      )
    ));
  },[costcodes,divisionId,categoryId,chosen, searchText]);

  const onSearchChanged = (e) => {
    const {
      target: { value } = {},
    } = e;
    setSearchText(value);
  };

  return (
    <Drawer
      title={`Add New ${category} Global Cost Code to ${t('Project')}`}
      visible={visible}
      onClose={onClose}
      width={600}
    >
      <div className='project-add-global-costcode-list'>
        <Input.Search
          type="search"
          classname="searchbar"
          placeholder="Search"
          allowSearch
          style={{
            borderRadius: 5,
            margin: 10,
            width: '95%',
          }}
          onChange={onSearchChanged}
        />
        <CostCodeUploadList
          dataSource={data}
          selectable
          onSelectedChanged={setSelected}
        />
      </div>
      <div className='drawer-footer'>
        <Row justify='end' gutter={10}>
          <OnTraccrButton
            title='Cancel'
            type='cancel'
            style={{ marginRight: 8 }}
            onClick={onClose}
          />
          <OnTraccrButton
            title='Submit'
            onClick={submit}
          />
        </Row>
      </div>
    </Drawer>
  );
}