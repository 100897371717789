import React from 'react'
import { KeyboardTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from "moment";
import "./Day.css"
import SheetsTimePicker from './SheetsTimePicker'


export default function Day({mobileOnTimeSelected, times, dayIndex}) {

    const getDay = (dayIndex) => moment(dayIndex.toString(), "d").format("dddd")

    const getDailyValue= (dayIndex) => {
        const entry = times[dayIndex]
        if (typeof entry.startTime === "object" &&
            typeof entry.endTime === "object") {
                 return entry.result
            }
        return null 
    }

    const getTimeValue = (pickerType) => {
        const entry = times[dayIndex]
        if (pickerType === "timeOnBreak") {
            if (typeof entry.timeOnBreak === "object") {
                return moment(entry.timeOnBreakString, "HH:mm")  
                }
            // if no value stored for timeOnBreak, return inital value of 1 hour
            return moment("00:00", "HH:mm")
        }
        else if (typeof entry[pickerType] === "object") {
            // if dealing w/ startTime or endTime time picker, if value is stored just return the value 
            return entry[pickerType]
            }
        return moment("00:00", "hh:mm a")
        }

    return(
    <div className="day-container">
        <div className="dayOfWeek">{getDay(dayIndex)}</div>
        <MuiPickersUtilsProvider utils={MomentUtils}> 
            <div className="timePickerGroup">
                <div className="timePickerWrapper">
                    <SheetsTimePicker getTimeValue={getTimeValue} mobileOnTimeSelected={mobileOnTimeSelected} dayIndex={dayIndex} pickerType="startTime"/>
                </div>
                <div className="timePickerWrapper">
                    <SheetsTimePicker getTimeValue={getTimeValue} mobileOnTimeSelected={mobileOnTimeSelected} dayIndex={dayIndex} pickerType="endTime"/>
                </div>
                <div className="timePickerWrapper">

                    <KeyboardTimePicker
                    ampm={false}
                    format="HH:mm"
                    margin="normal"                                
                    className="timePicker"
                    label="Time on Break"
                    value={getTimeValue('timeOnBreak')}
                    onChange={(momentTime) => mobileOnTimeSelected(momentTime, dayIndex, 'timeOnBreak')}
                    KeyboardButtonProps={{
                    'aria-label': 'change time',
                    }}
                />
                </div>
            </div>
        </MuiPickersUtilsProvider> 
        <div className="dailyTotal">Daily Total: {getDailyValue(dayIndex)}</div>
    </div>)
}
