import { useMemo } from "react"
import { useSelector } from 'react-redux';
import { Table } from "antd";
export default function ListView({
  dataSource, onClick, columns
}) {
  const divisions = useSelector((state) => state.settings.divisions);

  const dataEntries = useMemo(() => dataSource.map((entry) => ({
    key: entry.id,
    division: divisions[entry.divisionId]?.name,
    ...entry,
      })), [dataSource, divisions]);

  return (
    <Table 
      columns={columns}
      dataSource={dataEntries}
      size="small"
      pagination={false}
      onRow={(entry) => ({
        onClick: () => onClick(entry),
        })}
      tableLayout="fixed"
      rowClassName="listview-table-scroll"
      scroll={{ y: 'calc(100vh - 200px)' }}
  />
  )
}
