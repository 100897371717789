import React from 'react';
import moment from 'moment';
import { Row, Col, } from 'antd';
import { GlobalOutlined } from '@ant-design/icons';
import SideListText from '../../common/SideList/SideListText';
import HoverHelp from '../../common/HoverHelp';

import { PopContent } from '../ReportsViews/PopoverContent';

import Permissions from '../../auth/Permissions';

export default ({ name, baseReport, userId, owner, createdAt, updatedAt, isPublic }) => {
  const rows = [{
    title:'Owner',
    text: owner,
  }, {
    title:'Report Type',
    text: baseReport,
  }];
  if(userId === Permissions.id) {
    rows.push({
      title:'Access Type',
      text: isPublic ? 'Public' : 'Private',
    });
  }
  if(createdAt) {
    rows.push({
      title:'Created On',
      text: moment(createdAt).format('MMM Do YY')
    });
  }

  if(updatedAt) {
    rows.push({
      title:'Last Updated',
      text: moment(updatedAt).format('MMM Do YY')
    });
  }
  return (
    <Row style={{width:'100%', padding: 5 }} justify='start' align='middle' gutter={6}>
      <Col style={{maxWidth: '85%'}}>
        <SideListText text={name}/>
        <SideListText text={baseReport} style={{ fontSize: 11, color: 'gray'}}/>
      </Col>
      <Col>
        <HoverHelp content={
          <PopContent
            key={name}
            title={name}
            rows={rows}
          />
        } 
        Icon={GlobalOutlined}/>
      </Col>
    </Row>
  );
}