import React from 'react';
import { Row, Col } from 'antd';

export default ({ advancedTips }) => {
  if (!advancedTips || advancedTips.length === 0) return null;
  return (
    <>
      <div className='form-library-add-section-header'>Advanced Tips: </div>
      {
        advancedTips.map((tip, idx) => (
          <Row className='form-library-add-text' key={idx}>
            <Col flex='20px'>-</Col>
            <Col flex='auto' style={{ maxWidth: 'calc(100% - 20px)'}}>{tip}</Col>
          </Row>
        ))
      }
    </>
  )
}