import React from 'react';
import { Table } from 'antd';
import PropTypes from 'prop-types';

import sortByString from '../../helpers/helpers';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
];

export default function DivisionDrawerAddBody({
  data,
  onSelectChange,
  type,
  translatedType,
}) {
  const isUsers = type === 'Users';
  const tableOffset = isUsers ? 40 : 100;
  const scrollOffset = isUsers ? 150 : 210;
  return (
    <>
      {
      !isUsers && <div style={{ margin: 20 }}>
        <b>Warning:</b> This will remove the {translatedType ?? type} from their
        original division
      </div>
      }
      <Table
        style={{ height: `calc(100% - ${tableOffset}px)`, }}
        columns={columns}
        dataSource={data.sort(sortByString('name'))}
        size='small'
        pagination={false}
        scroll={{
          y: `calc(100vh - ${scrollOffset}px)`
        }}
        rowSelection={{
          fixed: true,
          onChange: onSelectChange,
        }}
        rowKey='id'
      />
    </>
  );
}

DivisionDrawerAddBody.defaultProps = {
  data: [],
  onSelectChange: () => {},
  type: '',
  translatedType: '',
};

DivisionDrawerAddBody.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  onSelectChange: PropTypes.func,
  type: PropTypes.string,
  translatedType: PropTypes.string,
};
