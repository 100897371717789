import {
  GET_NUX,
  CREATE_NUX,
  DELETE_NUX,
  START_NUX_ACTION,
  FINISH_NUX_ACTION,
} from '../../state/actionTypes';
import NuxService from './nux.service';

import Analytics from '../../helpers/Analytics';

export const getNuxState = () => async (dispatch) => {
  const { data: nux } = await NuxService.get();
  dispatch({
    type: GET_NUX,
    payload: {
      nux,
    },
  });
};

export const createNuxEntry = (type) => async (dispatch) => {
  Analytics.track('Nux/Complete', { NuxEvent: type });
  const { data } = await NuxService.create(type);
  if (!data) return false;
  dispatch({
    type: CREATE_NUX,
    payload: {
      type,
    },
  });
  return true;
};

export const deleteNuxEntry = (type) => async (dispatch) => {
  const { data } = await NuxService.delete(type);
  if (!data) return false;
  dispatch({
    type: DELETE_NUX,
    payload: {
      type,
    },
  });
  return true;
};

export const startNuxAction = (activeNuxAction) => ({
  type: START_NUX_ACTION,
  payload: {
    activeNuxAction,
  },
});

export const finishNuxAction = () => ({
  type: FINISH_NUX_ACTION,
});

export default {};
