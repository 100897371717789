import React from 'react';

import colors from '../constants/Colors';

const radius = 16;

export default ({ number }) => (
  <div
    style={{
      width: radius * 2,
      height: radius * 2,
      borderRadius: radius,
      backgroundColor:colors.ONTRACCR_DARK_YELLOW,
      fontSize:20,
      textAlign:'center',
      lineHeight:`${radius * 2}px`,
    }}
  >
    {number}
  </div>
);