import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { Col, Row } from 'antd';
import { DeleteOutlined, DragOutlined } from '@ant-design/icons';
import { Draggable } from 'react-beautiful-dnd';
import EmailRecipientSelector from '../selectors/EmailRecipientSelector';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrNumberInput from '../../../common/inputs/OnTraccrNumberInput';
import HoverHelp from '../../../common/HoverHelp';

export default function ExternalSignatureSigner({
  signer,
  index,
  divisionId,
  sections,
  additionalOptions,
  onDeleteClicked,
  onSignerChange,
}) {
  const {
    id,
    emails: initialSelected,
    order,
  } = signer;

  const onChange = useCallback((key) => (newValue) => {
    if (key === 'emails' && (signer[key].length === 0 && newValue?.length === 0)) return;
    const newData = {
      ...signer,
      [key]: newValue,
    };
    onSignerChange(newData);
  }, [signer, onSignerChange]);

  const onDelete = useCallback(() => onDeleteClicked(id), [id, onDeleteClicked]);

  return (
    <Draggable draggableId={id.toString()} index={index}>
      {({ draggableProps, dragHandleProps, innerRef }) => (
        <div
          ref={innerRef}
          // eslint-disable-next-line react/jsx-props-no-spreading
          {...draggableProps}
          className="boards-status-card"
        >
          <Row justify="space-between" align="middle">
            <Col flex="18px">
              <Row justify="center" style={{ height: '100%', paddingRight: 5, paddingLeft: 10 }} align="middle">
                {/* eslint-disable-next-line react/jsx-props-no-spreading */}
                <DragOutlined {...dragHandleProps} className="boards-status-dragger" />
              </Row>
            </Col>
            <Col flex="75px">
              <Row style={{ marginTop: 5 }}>
                Order:
                <HoverHelp
                  iconProps={{
                    style: { paddingTop: 4, paddingLeft: 5 },
                  }}
                  content={(
                    <div style={{ maxWidth: 200 }}>
                      The Docusign signing order.
                      Signers with the same order number will be sent in parallel.
                    </div>
                  )}
                />
              </Row>
              <Row style={{ margin: '10px 0px' }} className="nodrag">
                <OnTraccrNumberInput
                  min={1}
                  value={order}
                  onChange={onChange('order')}
                />
              </Row>
            </Col>
            <Col flex="350px">
              <EmailRecipientSelector
                sections={sections}
                initialSelected={initialSelected}
                divisionId={divisionId}
                onChange={onChange('emails')}
                additionalOptions={additionalOptions}
                text="Select Recipient(s):"
                showFormAuthor
              />
            </Col>
            <Col flex="30px" />
            <Col flex="30px">
              <BorderlessButton
                iconNode={<DeleteOutlined style={{ margin: 0, color: 'red' }} />}
                onClick={onDelete}
              />
            </Col>
          </Row>
        </div>
      )}
    </Draggable>
  );
}

ExternalSignatureSigner.propTypes = {
  additionalOptions: PropTypes.array,
  divisionId: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
  onSignerChange: PropTypes.func.isRequired,
  sections: PropTypes.array,
  signer: PropTypes.shape({
    emails: PropTypes.array,
    id: PropTypes.shape({
      toString: PropTypes.func,
    }),
    order: PropTypes.number,
  }).isRequired,
};

ExternalSignatureSigner.defaultProps = {
  additionalOptions: [],
  sections: [],
};
