import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Form,
  Row,
  Select,
} from 'antd';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

function ActiveUsersFilterDrawer({
  visible,
  setDrawerVisibility,
  roles = [],
  projects = [],
  rolesFilter = [],
  projectsFilter = [],
  setRolesFilter,
  setProjectsFilter,
}) {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      roles: rolesFilter,
      projects: projectsFilter,
    });
  }, [visible]);

  const resetFields = () => {
    form.setFieldsValue({
      roles: [],
      projects: [],
    });
  };

  const closeDrawer = () => {
    setDrawerVisibility(false);
  };

  const onApply = () => {
    const {
      roles: inputtedRoles = [],
      projects: inputtedProjects = [],
    } = form.getFieldsValue();
    setRolesFilter(inputtedRoles);
    setProjectsFilter(inputtedProjects);
    closeDrawer();
  };

  return (
    <Drawer
      title="Filter Users"
      visible={visible}
      onClose={closeDrawer}
      width={450}
      destroyOnClose
    >
      <div>
        <Row>
          <h3>Create Filter</h3>
          <OnTraccrButton
            onClick={resetFields}
            style={{ marginLeft: 'auto' }}
            title="Reset"
          />
        </Row>
        <Form
          form={form}
          layout="vertical"
        >
          <Form.Item
            name="roles"
            label="Roles"
          >
            <Select
              mode="multiple"
              allowClear
              options={roles}
              placeholder="Roles"
            />
          </Form.Item>
          <Form.Item
            name="projects"
            label="Projects"
          >
            <Select
              mode="multiple"
              allowClear
              options={projects}
              placeholder="Projects"
              optionFilterProp="label"
            />
          </Form.Item>
        </Form>
        <div className="drawer-footer">
          <Row justify="end" gutter={10}>
            <div style={{ marginRight: 'auto' }}>
              <OnTraccrButton
                title="Cancel"
                type="cancel"
                onClick={closeDrawer}
              />
            </div>
            <OnTraccrButton
              title="Apply"
              onClick={onApply}
            />
          </Row>
        </div>
      </div>
    </Drawer>
  );
}

ActiveUsersFilterDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  setDrawerVisibility: PropTypes.func.isRequired,
  roles: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
  })),
  projects: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string,
    key: PropTypes.string,
  })),
  rolesFilter: PropTypes.arrayOf(PropTypes.string),
  projectsFilter: PropTypes.arrayOf(PropTypes.string),
  setRolesFilter: PropTypes.func.isRequired,
  setProjectsFilter: PropTypes.func.isRequired,
};

ActiveUsersFilterDrawer.defaultProps = {
  roles: [],
  projects: [],
  rolesFilter: [],
  projectsFilter: [],
};

export default ActiveUsersFilterDrawer;
