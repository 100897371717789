import {
  GET_SAGE_INTEGRATIONS,
  GET_SAGE_SHIFTS,
  CREATE_SAGE_SHIFT,
  UPDATE_SAGE_SHIFT,
  DELETE_SAGE_SHIFT,
  GET_SAGE_PAY_IDS,
  CREATE_SAGE_PAY_MAPPING,
  UPDATE_SAGE_PAY_MAPPING,
  DELETE_SAGE_PAY_MAPPING,
  UPDATE_SAGE_DEFAULT_DIVISION,
  UPDATE_SAGE_DIVISIONS,
  UPDATE_SAGE_NAME,
  UPDATE_SAGE_JOB_STATUSES,
} from '../../state/actionTypes';
import SageService from './sage.service';

export const getSageIntegrations = (version) => async (dispatch) => {
  const { data: integrations = [] } = await SageService.getIntegrations(version);
  if (!integrations) return false;
  dispatch({
    type: GET_SAGE_INTEGRATIONS,
    payload: {
      integrations,
    },
  });
  return integrations;
};

export const getSageShifts = (query) => async (dispatch) => {
  const { data: shifts } = await SageService.getShifts(query);
  if (!shifts) return false;
  dispatch({
    type: GET_SAGE_SHIFTS,
    payload: {
      shifts,
    },
  });
  return true;
};

export const createSageShift = (payload) => async (dispatch) => {
  const { data: newShift } = await SageService.createShift(payload);
  if (!newShift) return false;
  dispatch({
    type: CREATE_SAGE_SHIFT,
    payload: {
      newShift,
    },
  });
  return true;
};

export const updateSageShift = (id, newData) => async (dispatch) => {
  const { data } = await SageService.updateShift(id, newData);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_SHIFT,
    payload: {
      id,
      newData,
    },
  });
  return true;
};

export const deleteSageShift = (id) => async (dispatch) => {
  const { data } = await SageService.deleteShift(id);
  if (!data) return false;
  dispatch({
    type: DELETE_SAGE_SHIFT,
    payload: {
      id,
    },
  });
  return true;
};

export const getSagePayIDs = (query) => async (dispatch) => {
  if (!query) {
    return dispatch({
      type: GET_SAGE_PAY_IDS,
      payload: {
        sagePayIds: [],
        payIdMappings: [],
      },
    });
  }
  const { data } = await SageService.getPayIDs(query);
  if (!data) return false;
  dispatch({
    type: GET_SAGE_PAY_IDS,
    payload: {
      ...data,
    },
  });
  return true;
};

export const createPayMapping = (integrationId, payload) => async (dispatch) => {
  const { data } = await SageService.createPayMapping(integrationId, payload);
  if (!data) return false;
  dispatch({
    type: CREATE_SAGE_PAY_MAPPING,
    payload,
  });
  return true;
};

export const deletePayMapping = (integrationId, projectId) => async (dispatch) => {
  const { data } = await SageService.deletePayMapping(integrationId, projectId);
  if (!data) return false;
  dispatch({
    type: DELETE_SAGE_PAY_MAPPING,
    payload: {
      projectId,
    },
  });
  return true;
};

export const updatePayMapping = (integrationId, projectId, sagePayId) => async (dispatch) => {
  const { data } = await SageService.updatePayMapping(integrationId, projectId, sagePayId);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_PAY_MAPPING,
    payload: {
      projectId,
      sagePayId,
    },
  });
  return true;
};

export const updateSageDefaultDivision = (integrationId, divisionId) => async (dispatch) => {
  const { data } = await SageService.updateDefaultDivision(integrationId, divisionId);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_DEFAULT_DIVISION,
    payload: {
      integrationId,
      divisionId,
    },
  });
  return true;
};

export const updateSageDivisions = (version, integrationId, divisions) => async (dispatch) => {
  const { data } = await SageService.updateDivisions(version, integrationId, divisions);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_DIVISIONS,
    payload: {
      integrationId,
      ...data,
    },
  });
  return true;
};

export const updateSageName = (version, integrationId, name) => async (dispatch) => {
  const { data } = await SageService.updateName(version, integrationId, name);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_NAME,
    payload: {
      integrationId,
      name,
    },
  });
  return true;
};

export const updateSageJobStatuses = (version, integrationId, jobStatuses) => async (dispatch) => {
  const { data } = await SageService.updateJobStatuses(version, integrationId, jobStatuses);
  if (!data) return false;
  dispatch({
    type: UPDATE_SAGE_JOB_STATUSES,
    payload: {
      integrationId,
      jobStatuses,
    },
  });
  return true;
};

export default {};
