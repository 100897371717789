import axios from 'axios';

import { request } from '../../helpers/requests';

export default {
  create: (type, payload) => request({
    call: axios.post(`/${type}/statuses`, payload),
    errMsg: 'Could not create status',
  }),
  delete: (type, id) => request({
    call: axios.delete(`/${type}/statuses/${id}`),
    errMsg: 'Could not delete status',
  }),
  update: (type, id, payload) => request({
    call: axios.put(`/${type}/statuses/${id}`, payload),
    errMsg: 'Could not update status',
  }),
};
