import React, { useMemo } from 'react';
import {
  Table,
} from 'antd';
import { PropTypes } from 'prop-types';
import { EditOutlined } from '@ant-design/icons';

export default function BoardTimelineList({
  tasks,
  style,
  visibleColumns,
  onEditClick,
  canEdit,
}) {
  const tableColumns = useMemo(() => {
    const columns = [];

    if (canEdit) {
      columns.push({
        title: '',
        dataIndex: 'id',
        width: 50,
        render: (text) => <EditOutlined onClick={() => onEditClick({ id: text })} />,
      });
    }

    visibleColumns.forEach((column) => {
      columns.push({
        title: <span className="custom-header">{column.label}</span>,
        dataIndex: column.property,
        key: column.property,
        onCell: () => ({
          style: {
            whiteSpace: 'noWrap',
          },
        }),
      });
    });

    return columns;
  }, [visibleColumns, onEditClick, canEdit]);

  return (
    <div
      id="timeline-table"
      style={{
        overflow: 'scroll',
        ...style,
      }}
    >
      <Table
        columns={tableColumns}
        dataSource={tasks}
        pagination={false}
        rowKey="id"
        size="small"
      />
    </div>

  );
}

BoardTimelineList.propTypes = {
  tasks: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    start: PropTypes.instanceOf(Date).isRequired,
    end: PropTypes.instanceOf(Date).isRequired,
    progress: PropTypes.number.isRequired,
    dependencies: PropTypes.string,
  })).isRequired,
  style: PropTypes.shape({}),
  visibleColumns: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    property: PropTypes.string.isRequired,
  })).isRequired,
  canEdit: PropTypes.bool.isRequired,
  onEditClick: PropTypes.func.isRequired,
};

BoardTimelineList.defaultProps = {
  style: {},
};
