import {
  GET_LIVE_FEED_EVENTS,
  ADD_LIVE_FEED_EVENT,
  GET_POSTS,
  GET_FILE_URLS,
  CREATE_POST,
  DELETE_POSTS,
  CREATE_CUSTOM_CHANNEL,
  EDIT_CUSTOM_CHANNEL,
  GET_CHANNELS,
  DELETE_CUSTOM_CHANNEL,
} from '../../../state/actionTypes';
import LiveFeedService from './livefeed.service';
import Analytics from '../../../helpers/Analytics';

export const getEvents = () => async (dispatch) => {
  const { data: events } = await LiveFeedService.get();
  if (!events) return false;
  dispatch({
    type: GET_LIVE_FEED_EVENTS,
    payload: {
      events,
    },
  });
  return true;
};

export const getPosts = () => async (dispatch) => {
  const { data: posts } = await LiveFeedService.getPosts();
  if (!posts) return false;
  dispatch({
    type: GET_POSTS,
    payload: {
      posts,
    },
  });
  return true;
};

export const deletePost = (channelId, postId) => async (dispatch) => {
  const { data } = await LiveFeedService.deletePost(postId);
  if (!data) return false;
  dispatch({
    type: DELETE_POSTS,
    payload: {
      channelId,
      postToDelete: postId,
    },
  });
  return true;
};

export const getFileURLs = (ids) => async (dispatch) => {
  const { data } = await LiveFeedService.getFileURLs({ ids });
  if (!data) return false;
  dispatch({
    type: GET_FILE_URLS,
    payload: {
      files: data,
    },
  });
  return true;
};

export const createPost = (payload) => async (dispatch) => {
  const {
    text = '',
    tags = [],
    files = [],
  } = payload;
  Analytics.track('Post/Create', {
    textLength: text ? text.length : 0,
    tags: tags.length,
    files: files.length,
  });
  const { data } = await LiveFeedService.createPost(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_POST,
    payload: {
      post: data,
    },
  });
  return true;
};

export const addEvent = (events) => ({
  type: ADD_LIVE_FEED_EVENT,
  payload: {
    events,
  },
});

export const createPostFromSocket = (post) => ({
  type: CREATE_POST,
  payload: {
    post,
  },
});

export const getChannels = () => async (dispatch) => {
  const { data } = await LiveFeedService.getChannels();
  if (!data) return false;
  dispatch({
    type: GET_CHANNELS,
    payload: {
      ...data,
    },
  });
  return true;
};

export const createCustomChannel = (payload) => async (dispatch) => {
  const { data: id } = await LiveFeedService.createCustomChannel(payload);
  if (!id) return false;
  dispatch({
    type: CREATE_CUSTOM_CHANNEL,
    payload: {
      id,
      ...payload,
    },
  });
  return true;
};

export const editCustomChannel = (payload) => async (dispatch) => {
  const { id } = payload;
  delete payload.id;
  const { data } = await LiveFeedService.editCustomChannel(id, payload);
  if (!data) return false;
  dispatch({
    type: EDIT_CUSTOM_CHANNEL,
    payload: {
      id,
      ...payload,
    },
  });
  return true;
};

export const deleteCustomChannel = (id) => async (dispatch) => {
  const { data } = await LiveFeedService.deleteCustomChannel(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CUSTOM_CHANNEL,
    payload: {
      id
    },
  });
  return true;
};

export default {};
