import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Row,
  Col,
  Tag,
  Drawer,
  Form,
  Select,
  Popconfirm,
  Popover,
} from 'antd';
import PropTypes from 'prop-types';
import { PlusOutlined, CloseOutlined } from '@ant-design/icons';

import BorderlessButton from '../common/buttons/BorderlessButton';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FormTextInput from '../common/inputs/FormTextInput';

import colors from '../constants/Colors';

import { getIdMap } from '../helpers/helpers';

import { setAnalyticsConfig } from './state/analytics.actions';

const formStyle = { marginBottom:0, paddingBottom:-8 };
const formLabelStyle = {
  style:{
    paddingBottom:5,
    marginTop:10,
  },
};

export default function AnalyticsGroups({
  analyticsConfig,
  type,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  /*
    Groups look like:
    {
      statusId1: 'Group Name',
      statusId2: 'Group 2',
    }

  */

  const {
    groups,
    statuses: boardStatuses,
    boardId,
  } = analyticsConfig;

  const [drawerOpen, setDrawerOpen] = useState(false);

  const onAddClicked = useCallback(() => setDrawerOpen(true),[]);
  const onDrawerClose = useCallback(() => setDrawerOpen(false),[]);

  const onGroupCreate = useCallback(async () => {
    try {
      const {
        name,
        statuses = [],
      } = await form.validateFields();
      const newGroups = {
        ...groups,
      };
      statuses.forEach((statusId) => {
        newGroups[statusId] = name;
      })
      dispatch(setAnalyticsConfig({
        type,
        payload: { groups: newGroups },
      }));
      setDrawerOpen(false);
    } catch (err) {
      //
    }

  },[dispatch, form, groups]);

  const onCancelDelete = useCallback((e) => {
    e.stopPropagation();
  },[]);
  const onTagDelete = useCallback((tagName) => (e) => {
    e.stopPropagation();
    const newGroups = {};
    Object.keys(groups).forEach((statusId) => {
      if (groups[statusId] !== tagName) {
        newGroups[statusId] = groups[statusId];
      }
    });
    dispatch(setAnalyticsConfig({
      type,
      payload: { groups: newGroups },
    }));
  },[dispatch, groups]);

  const [
    groupList,
    ungroupedStatuses,
  ] = useMemo(() => {
    const statusMap = getIdMap(boardStatuses.filter((status) => status.boardId === boardId));
    const allStatuses = {...statusMap};
    const nameToStatuses = {};
    Object.keys(groups).forEach((statusId) => {
      delete allStatuses[statusId];
      const name = groups[statusId];
      if (!(name in nameToStatuses)) nameToStatuses[name] = [];
      nameToStatuses[name].push(statusId);
    });

    const statusList = Object.values(allStatuses);
    statusList.sort((a,b) => a.orderIndex - b.orderIndex);
    return [
      Object.keys(nameToStatuses).map((groupName) => ({
        groupName,
        statuses: nameToStatuses[groupName]
          .map((statusId) => (statusMap[statusId] ?? {}).title)
          .filter((status) => status)
          .join(', ')
      })),
      statusList.map((status) => ({ value: status.id, label: status.title })),
    ];
  },[groups, boardStatuses]);

  useEffect(() => {
    if (!drawerOpen) {
      form.resetFields();
    }
  },[drawerOpen, form]);

  return (
    <>
      <Row gutter={5} align='middle' style={{ height: 32 }}>
        <Col style={{ height: 20 }}>
          Groups:
        </Col>
        {
          groupList.map(({ groupName, statuses = '' }) => (
            <Col>
              <Tag
                closable
                closeIcon={
                  <Popconfirm
                    placement='bottomRight'
                    title={`Delete group ${groupName}?`}
                    onConfirm={onTagDelete(groupName)}
                    onCancel={onCancelDelete}
                    okText='Delete'
                    cancelText='Cancel'
                    okButtonProps={{ style: { backgroundColor: colors.ONTRACCR_RED, } }}
                  >
                    <CloseOutlined/>
                </Popconfirm>
                }
              >
                <Popover
                  placement='bottomRight'
                  trigger={'hover'}
                  content={statuses}
                  title={groupName}
                >
                  {groupName}
                </Popover>
              </Tag>
            </Col>
          ))
        }
        <Col>
          <BorderlessButton
            style={{ background: 'transparent', height: 20 }}
            iconNode={<PlusOutlined style={{ marginLeft: 0 }}/>}
            onClick={onAddClicked}
          />
        </Col>
      </Row>
      <Drawer
        title='Group Statuses'
        visible={drawerOpen}
        onClose={onDrawerClose}
        width={500}
        bodyStyle={{ padding: '0px 24px' }}
      >
        <Form form={form} layout='vertical'>
          <FormTextInput
            isNotDisplay
            label='Name'
            name='name'
            rules={[{ required: true, message: 'Name is required' }]}
          />
          <Form.Item
            label='Statuses'
            name='statuses'
            style={formStyle}
            labelCol={formLabelStyle}
            rules={[{ required: true, message: 'The group must include at least one status' }]}
          >
            <Select
              options={ungroupedStatuses}
              mode='multiple'
            />
          </Form.Item>
        </Form>
        <DrawerSubmitFooter
          onClose={onDrawerClose}
          onSubmit={onGroupCreate}
        />
      </Drawer>
    </>
  )
}

/* eslint-disable react/forbid-prop-types */
AnalyticsGroups.propTypes = {
  type: PropTypes.string,
  analyticsConfig: PropTypes.object,
};

AnalyticsGroups.defaultProps = {
  type: null,
  analyticsConfig: {},
};
