import React, {
  useCallback, useEffect, useMemo, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import IntegrationSyncDrawer from '../IntegrationSyncDrawer';

import EclipseDivisionSync from './EclipseDivisionSync';
import EclipseProjectSync from './EclipseProjectSync';
import EclipseEquipmentSync from './EclipseEquipmentSync';
import EclipseMaterialSync from './EclipseMaterialSync';
import EclipseUserSync from './EclipseUserSync';

function EclipseConfigureDrawer({
  visible,
  onClose,
}) {
  const { t } = useTranslation();
  const divRef = useRef(null);
  const projectRef = useRef(null);
  const equipmentRef = useRef(null);
  const materialRef = useRef(null);
  const userRef = useRef(null);

  const [currentStep, setCurrentStep] = useState(0);
  const [canNext, setCanNext] = useState(false);
  const onNext = useCallback(async () => {
    switch (currentStep) {
      case 0:
        return divRef?.current ? divRef.current.onSave() : false;
      case 1:
        return projectRef?.current ? projectRef.current.onSave() : false;
      case 2:
        return equipmentRef?.current ? equipmentRef.current.onSave() : false;
      case 3:
        return materialRef?.current ? materialRef.current.onSave() : false;
      case 4: {
        if (!userRef?.current) return false;
        const {
          data: res,
        } = await userRef.current.onSave();
        if (res) {
          onClose();
        }

        return res;
      }
      default:
        return true;
    }
  }, [onClose, currentStep, divRef, projectRef, materialRef, userRef]);

  const steps = useMemo(() => (
    [
      {
        title: 'Divisions',
        key: 0,
        content: (
          <EclipseDivisionSync
            visible={currentStep === 0}
            setCanNext={setCanNext}
            ref={divRef}
          />
        ),
      },
      {
        title: t('Project', { count: 0 }),
        key: 1,
        content: (
          <EclipseProjectSync
            visible={currentStep === 1}
            setCanNext={setCanNext}
            ref={projectRef}
          />
        ),
      },
      {
        title: 'Equipment',
        key: 2,
        content: (
          <EclipseEquipmentSync
            visible={currentStep === 2}
            setCanNext={setCanNext}
            ref={equipmentRef}
          />
        ),
      },
      {
        title: 'Materials',
        key: 3,
        content: (
          <EclipseMaterialSync
            visible={currentStep === 3}
            setCanNext={setCanNext}
            ref={materialRef}
          />
        ),
      },
      {
        title: 'Users',
        key: 4,
        content: (
          <EclipseUserSync
            visible={currentStep === 4}
            setCanNext={setCanNext}
            ref={userRef}
          />
        ),
      },
    ]
  ), [currentStep, divRef, projectRef, equipmentRef, materialRef, userRef]);

  const onSkip = useCallback(() => {
    if (currentStep === steps.length - 1) {
      onClose();
    } else {
      setCurrentStep(currentStep + 1);
    }
  }, [currentStep, steps, onClose]);

  useEffect(() => {
    setCurrentStep(0);
  }, [visible]);

  return (
    <IntegrationSyncDrawer
      title="Configure Eclipse"
      visible={visible}
      onClose={onClose}
      onSave={onNext}
      onNext={onNext}
      onSkip={currentStep > 0 ? onSkip : null}
      nextDisabled={!canNext}
      steps={steps}
      onStepChanged={setCurrentStep}
      currentStep={currentStep}
    />
  );
}

EclipseConfigureDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default EclipseConfigureDrawer;
