import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  DOCUMENT_MANAGE_TYPE,
  DOCUMENT_ADD_TYPE,
  DOCUMENT_PERMS_TYPE,
  DOCUMENT_VERSION_TYPE,
  FORM_CREATE_TYPE,
} from '../nux.constants';

import NuxTabPane from '../NuxTabPane';

import NuxManageDocs from './NuxManageDocs';
import NuxAddFiles from './NuxAddFiles';
import NuxFilePermissions from './NuxFilePermissions';
import NuxFileVersions from './NuxFileVersions';
import NuxFormsCreate from './NuxFormsCreate';
import NuxFormWorkflows from './NuxFormWorkflows';

import manageDocs from '../../assets/images/NUX/manageDocs.png';
import addingFiles from '../../assets/images/NUX/addingFiles.png';
import filePerms from '../../assets/images/NUX/filePerms.png';
import fileVersion from '../../assets/images/NUX/fileVersion.png';
import creatingForms from '../../assets/images/NUX/creatingForms.png';
import formWorkflows from '../../assets/images/NUX/formWorkflows.png';

import Analytics from '../../helpers/Analytics';

const manageTitle = 'Manage Documents';
const addTitle = (<div>Adding Folders<br/>& Files</div>);
const permsTitle = 'User File Permissions';
const versionTitle = (<div>File<br/>Versioning</div>);
const formCreateTitle = (<div>Creating<br/>Forms</div>);
const workflowTitle =(<div>Form<br/>Workflows</div>);

export default function NuxDocuments({
  setDetailView,
  setTitle,
  setDescription,
  resetToDefault,
  history,
  onCloseClicked,
  nux = new Set(),
  createNuxEntry,
}) {
  const { t } = useTranslation();
  return (
    <NuxTabPane
      setDetailView={setDetailView}
      setTitle={setTitle}
      setDescription={setDescription}
      rows={2}
      buttons={[[
        {
          title: manageTitle,
          description:`Share important documents between the field and the office instantly, ensuring that the whole team always has access to the most up-to-date ${t('Project').toLowerCase()} information.`,
          complete:nux.has(DOCUMENT_MANAGE_TYPE),
          src:manageDocs,
          onClick:() => {
            Analytics.track('NUX/TryIt', { NuxPage: manageTitle });
            if(!nux.has(DOCUMENT_MANAGE_TYPE)) {
              createNuxEntry(DOCUMENT_MANAGE_TYPE);
            }
          },
          detailView:(
            <NuxManageDocs
              onBack={resetToDefault}
            />
          ),
        },
        {
          title: addTitle,
          modalTitle: 'Adding Files & Folders',
          description:'Ontraccr makes it easy to store files and keep them organized with folders.',
          complete:nux.has(DOCUMENT_ADD_TYPE),
          src:addingFiles,
          detailView:(
            <NuxAddFiles
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: 'Adding Files & Folders' });
                history.push('/files');
                onCloseClicked();
              }}
            />
          ),
        },
        {
          title: permsTitle,
          description:'Control exactly which users are allowed to view or manage which files/folders.',
          complete:nux.has(DOCUMENT_PERMS_TYPE),
          src:filePerms,
          detailView:(
            <NuxFilePermissions
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: permsTitle });
                if(!nux.has(DOCUMENT_PERMS_TYPE)) {
                  createNuxEntry(DOCUMENT_PERMS_TYPE);
                }
                history.push('/settings', {
                  activeTab: 'roles'
                });
                onCloseClicked();
              }}
            />
          ),
        },
        {
          title: versionTitle,
          modalTitle: 'File Versioning',
          description:'Manage file revisions effortlessly.',
          complete:nux.has(DOCUMENT_VERSION_TYPE),
          src:fileVersion,
          detailView:(
            <NuxFileVersions
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: 'File Versioning' });
                history.push('/files');
                onCloseClicked();
              }}
            />
          ),
        }
      ],
      [
        {
          title: formCreateTitle,
          modalTitle: 'Field Forms',
          description:'Design custom forms for field teams to complete.',
          complete:nux.has(FORM_CREATE_TYPE),
          src:creatingForms,
          detailView:(
            <NuxFormsCreate
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: 'Field Forms' });
                history.push('/forms');
                onCloseClicked();
              }}
            />
          ),
        },
        {
          title: workflowTitle,
          modalTitle: 'Form Workflows',
          description:'Configure exactly where you want forms to go at every step of the workflow.',
          complete:nux.has(FORM_CREATE_TYPE),
          src:formWorkflows,
          detailView:(
            <NuxFormWorkflows
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: 'Field Forms' });
                history.push('/forms');
                onCloseClicked();
              }}
            />
          ),
        }
      ]]}
    />
  );
}
