import React from 'react';
import { Drawer } from 'antd';

import ActiveInactiveUserLists from '../common/containers/ActiveInactiveUserLists';


export default class ProjectUserList extends React.Component {

  render() {
    const {
      project: {
        name,
        id,
        users = [],
      } = {},
      onClose,
      visible,
      onUserClick,
    } = this.props;
    return (
      <Drawer
        title={`${name} Users`}
        width={800}
        onClose={onClose}
        visible={visible}
        maskClosable={false}
      >
         <ActiveInactiveUserLists
          name={name}
          id={id}
          projectId={id}
          users={users}
          onUserClick={onUserClick}
          style={{
            height:'80%'
          }}
         />
      </Drawer>
    );
  }
}
