import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import ProgressUpdateInput from './ProgressUpdateInput';

import { updateProgress } from '../../reports/state/reports.actions';

import { getProgressFromState } from './progressHelpers';

export default function ProjectSpendUpdate({
  id,
  projectId,
  phaseId,
  costcodeId,
  hasWrite,
}) {
  const dispatch = useDispatch();
  const progressState = useSelector((state) => state.reports.progress);

  const {
    projectCostcodeDetails: taskState,
    projectProgressFilters,
  } = useSelector((state) => state.projects);

  const { actualSpend } = useMemo(() => (
    getProgressFromState({
      progressState,
      taskState,
      id,
      projectId,
      phaseId,
      costcodeId,
    })
  ), [
    progressState,
    taskState,
    id,
    projectId,
    phaseId,
    costcodeId,
  ]);

  const [spend, setSpend] = useState(actualSpend);
  const [pastSpend, setPastSpend] = useState(actualSpend);

  const [showPopover, setShowPopover] = useState(false);
  const onChange = useCallback((newValue) => {
    setShowPopover(true);
    setSpend(newValue);
  }, []);

  const onConfirm = useCallback(async () => {
    if (await dispatch(updateProgress({
      projectId,
      phaseId,
      costcodeId,
      actualSpend: spend,
    }))) {
      setPastSpend(spend);
      setShowPopover(false);
    }
  }, [spend, dispatch, projectId, phaseId, costcodeId]);

  const onCancel = useCallback(() => {
    setSpend(pastSpend);
    setShowPopover(false);
  }, [pastSpend]);

  useEffect(() => {
    setSpend(actualSpend);
    setPastSpend(actualSpend);

    setTimeout(() => {
      setShowPopover(false);
    });
  }, [actualSpend]);

  return (
    <Row style={{ height: 32 }} align="middle">
      {
        hasWrite
          ? (
            <ProgressUpdateInput
              min={projectProgressFilters?.dateRange ? Number.MIN_SAFE_INTEGER : 0}
              title="Update Actual Spend?"
              onConfirm={onConfirm}
              onCancel={onCancel}
              onChange={onChange}
              showPopover={showPopover}
              value={spend}
              formatter={(val) => `$ ${val}`}
              disabled={!!projectProgressFilters?.dateRange}
            />
          )
          : `$ ${spend}`
      }
    </Row>
  );
}

ProjectSpendUpdate.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  phaseId: PropTypes.string,
  costcodeId: PropTypes.string,
  hasWrite: PropTypes.bool,
};

ProjectSpendUpdate.defaultProps = {
  projectId: undefined,
  phaseId: undefined,
  costcodeId: undefined,
  hasWrite: true,
};
