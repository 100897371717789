import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Popover } from 'antd';
import {
  MoreOutlined,
  DollarOutlined,
  StopOutlined,
  UploadOutlined,
  IssuesCloseOutlined,
  CopyOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import { onPOCloseModal } from '../../payables/invoices/invoiceHelpers';

import Permissions from '../../auth/Permissions';

import {
  markInvoiceAsPaid,
  cancelWorkflow,
} from '../state/forms.actions';

const buttonStyle = {
  padding: 0,
  height: 20,
  textAlign: 'left',
};
export default function FormMoreButton({
  form,
  onAddInvoiceClick,
  onPOClose: onPOCloseClick,
  onCopy: onCopyClick,
}) {
  const {
    id: formId,
    type: formType,
    templateName,
    number,
    status,
    hasQuickBooksInvoice,
    isClosed,
    users,
  } = form ?? {};
  const dispatch = useDispatch();
  const [popoverVisible, setPopoverVisible] = useState(false);
  const formName = useMemo(() => {
    const nameSuffix = number ? ` - ${number}` : '';
    return `${templateName}${nameSuffix}`;
  }, [templateName, number]);

  const onClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  const onAddInvHandler = useCallback((e) => {
    e.stopPropagation();
    onAddInvoiceClick(formId);
  }, [formId]);

  const onCopy = useCallback((e) => {
    e.stopPropagation();
    onCopyClick(form);
  }, [onCopyClick, form]);

  const onCancel = useCallback((e) => {
    e.stopPropagation();
    CustomConfirmModal({
      title: `Cancel ${formName}?`,
      content: (
        <div style={{ maxWidth: 400 }}>
          Cancelling a form will delete any pending action items:
          <br/>
          <div style={{ textAlign:'left', marginTop: 10, marginLeft: 30 }}>
            - Shifts <br/>
            - <b style={{ fontFamily: 'roboto-bold' }}>Unsubmitted</b> connected forms<br/>
            - Approvals <br/>
            - Edit Requests <br/>
          </div>
 
        </div>
      ),
      onOk: async () => {
        if (await dispatch(cancelWorkflow(formId))) {
          setPopoverVisible(false);
        }
      },
      cancelText: 'Back',
      okText: 'Cancel',
      keyboard: false,
    });
  }, [dispatch, formId, formName]);

  const onPaid = useCallback(async (e) => {
    e.stopPropagation();

    CustomConfirmModal({
      title: `Mark ${formName} as paid?`,
      content: 'This will update the invoice in QuickBooks',
      onOk: async () => {
        if (await dispatch(markInvoiceAsPaid(formId))) {
          setPopoverVisible(false);
        }
      },
      cancelText: 'Back',
      okText: 'Submit',
      keyboard: false,
    });
  }, [dispatch, formId, formName]);

  const canCopy = useMemo(() => (
    users?.includes(Permissions.id)
  ), [users]);

  const isNotCompleted = status !== 'completed' && status !== 'Cancelled' && !isClosed;
  const shouldShowPayableOptions = !isClosed && (formType === 'PO' || formType === 'Sub-Contract');
  const isPO = formType === 'PO';

  const onPOClose = (
    onPOCloseModal({
      formName,
      formId,
      onPOCloseClick,
      setPopoverVisible,
      isPO,
    })
  );

  return (
    <Popover
      visible={popoverVisible}
      onVisibleChange={setPopoverVisible}
      content={
        <div style={{ maxWidth: 300 }}>
          {hasQuickBooksInvoice
            ? <BorderlessButton
                title='Mark as Paid'
                iconNode={<DollarOutlined/>}
                style={buttonStyle}
                onClick={onPaid}
              />
            : null
          }
          {
            isNotCompleted && hasQuickBooksInvoice
            ? <Divider className='header-dropdown-divider'/>
            : null
          }
          {onCopyClick && canCopy
            ? <BorderlessButton
              title='Copy'
              iconNode={<CopyOutlined />}
              style={buttonStyle}
              onClick={onCopy}
            />
            : null
          }
          {isNotCompleted
            ? <BorderlessButton
              title='Cancel'
              iconNode={<StopOutlined style={{ color: 'red' }}/>}
              style={{
                ...buttonStyle,
                color: 'red'
              }}
              onClick={onCancel}
            />
            : null
          }
          {shouldShowPayableOptions
            ? <BorderlessButton
              title='Add Invoice'
              iconNode={<UploadOutlined />}
              style={{
                ...buttonStyle,
              }}
              onClick={onAddInvHandler}
            />
            : null
          }
          {shouldShowPayableOptions && (
            <BorderlessButton
              title={`Close ${isPO ? 'PO' : 'Sub-Contract'}`}
              iconNode={<IssuesCloseOutlined />}
              style={{
                ...buttonStyle,
              }}
              onClick={onPOClose}
            />
          )}
        </div>
      }
      title="Form Actions"
      trigger="click"
      placement="topRight"
    >
      <div onClick={onClick} style={{ width: '100%' }}>
        <BorderlessButton
          style={{ padding:0, margin:0, width: 20 }} // Cell has 8px padding + 10px margin on both sides
          iconNode={<MoreOutlined style={{ margin: 0 }}/>}
        />
      </div>
    </Popover>
  );
}

FormMoreButton.propTypes = {
  onAddInvoiceClick: PropTypes.func.isRequired,
  onPOClose: PropTypes.func.isRequired,
  onCopy: PropTypes.func,
  form: PropTypes.shape({}),
};

FormMoreButton.defaultProps = {
  form: {},
  onCopy: null,
};
