
import React, { useState } from "react";
import SheetsWeb from './SheetsWeb'
import SheetsMobile from './SheetsMobile'
import moment from "moment";
import { isMobile } from 'react-device-detect';
import "./Sheets.css"

export default function Sheets() {
  const [times, setTimes] = useState([
    { day: "Sunday" },
    { day: "Monday" },
    { day: "Tuesday" },
    { day: "Wednesday" },
    { day: "Thursday" },
    { day: "Friday" },
    { day: "Saturday" },
  ]);
  //ex times =  [{day: Monday, startTime: Moment {} , startTimeString: "8:00 am", endTime: Moment {} , endTimeString: "9:00 pm", 
  //              timeOnBreak: Duration {}, timeOnBreakString: "1:00" resultM: 900, result: "15:00"}]

  const [employeeName, setEmployeeName] = useState("")
  const [employeeID, setEmployeeID] = useState("")

  const employeeNameHandler = (e) => {
    setEmployeeName(e.target.value)
  }

  const employeeIDHandler = (e) => {
    setEmployeeID(e.target.value)
  }

  const changeValueInEntries = (optionsObj) => {
    const { entries, index, newData = {} } = optionsObj;
  
    return entries.map((entry, arrIndex) => {
      if (index !== arrIndex) return entry;
      // if index doesn't match, then just return the entry as is and go to the next entry
      const newEntry = { ...entry, ...newData };
      //if does match, then make a variable newEntry and make it equal to an object with the data from entry as is as well as newData
      //and if our newEntry has a startTime, endTime, and timeOnBreak (eg all three have been selected and value for each isn't undefined), 
      // then calculate and spit out a value for time worked that day
      function renderTime(newEntry) {
        //this is a function which takes a newEntry object, 
        //and returns a new object with newEntry spread and properties with values of
        //work time string (eg 04:20) and work duration as minutes (eg 230 mins) extracted from the duration
        //note: we use work duration as minutes later in calculateWeeklyTotal function
        const endStartDiffMS = newEntry.endTime.diff(newEntry.startTime) 
        const endStartDiff = moment.duration(endStartDiffMS)   
        const clone = endStartDiff.clone()
        const workD = clone.subtract(newEntry.timeOnBreak)
        const duration = (typeof newEntry.timeOnBreak) === "object" ? workD : endStartDiff;
        const workHrs = duration.get('hours').toString()
        const workHrsF = workHrs.length === 1 ? `0${workHrs}` : `${workHrs}`;
        const workMins = duration.get('minutes').toString()
        const workMinsF = workMins.length === 1 ? `0${workMins}` : `${workMins}`;
        const workTime = `${workHrsF}:${workMinsF}`
        const workDasMins = duration.as('minutes')
        return {
          ...newEntry, 
          resultM: workDasMins, 
          result: workTime
        }
      }
      if (
        typeof newEntry.startTime === "object" &&
        typeof newEntry.endTime === "object" 
      ) {
        if (newEntry.endTime.isBefore(newEntry.startTime)) {
          newEntry.endTime.add(1, 'day')
        } 
        return renderTime(newEntry)
      }
      return newEntry;   // if both and end time and start time aren't selected, just return newEntry
    })};
    
  const onTimeSelected = (time, timeString, index, pickerType) => {
    const timeSelected = pickerType === 'timeOnBreak' ? moment.duration(timeString) : moment.utc(timeString, "h:mm a")
    const pickerTypeString = `${pickerType}String`
      //this is so we can end up with a key-value pair such as timeOnBreakString: 4:50  in our objects within times array
    setTimes( changeValueInEntries({
      // asssigning times variable to entries parameter
      entries: times,
      index,
      newData: {[pickerType]: timeSelected, [pickerTypeString]: timeString},
    }))};
    
  const mobileOnTimeSelected = (momentTime, index, pickerType) => {
    // this function looks similar to onTimeSelected but can't be abstracted because the parameters the mobile time picker onClicks pass are different than
    // the web time pickersb
    const mobileTimeString = pickerType === 'timeOnBreak' ? momentTime.format("HH:mm") : momentTime.format("hh:mm a")
    const mobileTimeSelected = pickerType === 'timeOnBreak' ? moment.duration(mobileTimeString) : moment.utc(mobileTimeString, "h:mm a")
    const pickerTypeString = `${pickerType}String`
    setTimes(changeValueInEntries({
      entries: times,
      index,
      newData: {[pickerType]: mobileTimeSelected, [pickerTypeString]: mobileTimeString},
  }))}



  const calculateWeeklyTotal = (times) => { 
    // this function takes the result values from our times array and adds them together to get a weekly total time

    const totalMinutes = times.reduce((acc, cur) => {
      return typeof cur.resultM === "number" ? (acc + cur.resultM) : acc;
    }, 0);
    const finalHrs = Math.floor(totalMinutes / 60);
    const finalMins = totalMinutes % 60;
    const stringHrs = String("0" + finalHrs).slice(-2);
    const stringMins = String("0" + finalMins).slice(-2);
    const weeklyTotal = `${stringHrs}:${stringMins}`;
    if (totalMinutes === 0) {
      return ""
    }
    else {
      return weeklyTotal;
    }
  };

  const renderCsvTimesFn = (times) => {
    /* this function takes the time array (an array of objects) and converts it
    into an array of arrays because of the requirements of the xlsx package
    used to export as xlsx file (see ExportCsv.js)
    */
    const incompleteCsvTimes = times.map((entry) => {
      const {
        day,
        startTimeString = "-",
        endTimeString = "-",
        timeOnBreakString = "-",
        result = "-",
      } = entry;
      return [day, startTimeString, endTimeString, timeOnBreakString, result];
    });
    const weeklyTotalRow = ["Week Total:", calculateWeeklyTotal(times)];
    const spacerRow = [""]
    const employeeNameRow = ["Employee Name:", employeeName]
    const employeeIDRow = ["Employee ID:", employeeID]
    const columnHeaders = [
      "Day",
      "Start Time",
      "End Time",
      "Time on Break",
      "Hours Worked",
    ];
    return [
      columnHeaders,
      ...incompleteCsvTimes,
      weeklyTotalRow,
      spacerRow,
      employeeNameRow,
      employeeIDRow];
  };

  const emailFeature = () => {
    let myWorkTimesStr = '';
    times.forEach(entry=> {
      if (typeof entry.result === "string") {
        let str = `On ${entry.day} my total work time was ${entry.result}.%0D%0A`
        myWorkTimesStr += str
      }
    })
    let employeeInfoStr = `My full name is ${employeeName} and my ID is ${employeeID}.`
    let weeklyTotalStr = `Total hours worked this week: ${calculateWeeklyTotal(times)}`
    let endStr = `Generated using  Ontraccr Sheets\u00A9`
    let fullEmailBodyStr = `${employeeInfoStr}%0D%0A${myWorkTimesStr}%0D%0A${weeklyTotalStr}%0D%0A%0D%0A${endStr}%0D%0A`
    return `mailto:name@email.com?&subject=My%20work%20hours%20this%20week%20&body=${fullEmailBodyStr}`
  }

  return(
    <div className="sheets-wrapper">
      
      {isMobile ? 
      <SheetsMobile 
        times={times}
        employeeName={employeeName}
        employeeID={employeeID} 
        employeeNameHandler={employeeNameHandler} 
        employeeIDHandler={employeeIDHandler}
        renderCsvTimesFn={renderCsvTimesFn} 
        changeValueInEntries={changeValueInEntries} 
        calculateWeeklyTotal={calculateWeeklyTotal} 
        mobileOnTimeSelected={mobileOnTimeSelected}
        emailFeature={emailFeature}/> 
        : 
        <SheetsWeb 
        times={times} 
        employeeNameHandler={employeeNameHandler} 
        employeeIDHandler={employeeIDHandler} 
        renderCsvTimesFn={renderCsvTimesFn} 
        changeValueInEntries={changeValueInEntries} 
        calculateWeeklyTotal={calculateWeeklyTotal} 
        onTimeSelected={onTimeSelected} 
        emailFeature={emailFeature}/>
      }



    </div>
  )
}