import React, { useMemo} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Trie } from 'ontraccr-common';

import Notes from '../notes/Notes';

import {
  addNote,
  getNotes,
  getProjectAttachments,
} from './state/projects.actions';

function ProjectNotes({
  projectId,
  divisionId,
}) {
  const notes = useSelector((state) => state.projects.notes);
  const users = useSelector((state) => state.users.users);
  const divisions = useSelector((state) => state.settings.divisions);
  const company = useSelector((state) => state.settings.company);

  const {
    userTrie = {},
    projectUsers = [],
  } = useMemo(() => {
    const { userId: ownerId } = company || {};
    const fullUsers = [];
    const fullUserTrie = {};
    const { users: divUsers = new Set() } = divisions[divisionId] ?? {};
    users.forEach((user) => {
      if (user.active && (divUsers.has(user.id) || user.id === ownerId)) {
        fullUsers.push(user);
        Trie.addToTrie(fullUserTrie, user);
      }
    });
    return {
      projectUsers: fullUsers,
      userTrie: fullUserTrie,
    };
  }, [users, company, divisions, divisionId]);

  return (
    <Notes
      id={projectId}
      notes={notes}
      addNote={addNote}
      getNotes={getNotes}
      style={{
        marginLeft: 24,
        marginRight: 24,
        top: 103,
        bottom: 53,
      }}
      allowUpload
      type="projectNotes"
      getAttachments={getProjectAttachments}
      users={projectUsers}
      userTrie={userTrie}
      allowTags
    />
  );
}

ProjectNotes.propTypes = {
  projectId: PropTypes.string.isRequired,
  divisionId: PropTypes.string.isRequired,
};

export default ProjectNotes;
