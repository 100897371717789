import React from 'react';

import colors from '../../constants/Colors';

export const TagView = ({ children }) => (
  <span style={{
    backgroundColor:colors.OPACITY_LIGHT_GRAY,
    // fontFamily:'roboto-medium',
    color:colors.ONTRACCR_RED,
  }}>
    {children}
  </span>
);

const BasicView = ({ children }) => children;

export default  ({ 
  post:{
    text,
    tags = [],
  },
}) => {
  if(!text) return null;
  if(!tags.length) {
    return (
      <BasicView>
      {text}
      </BasicView>
    );
  }
  let i = 0;
  let tagIndex = 0;
  const components = [];
  while (i < text.length && tagIndex < tags.length) {
    const { startIndex, endIndex, } = tags[tagIndex];
    if(i < startIndex) {
      components.push(text.slice(i,startIndex));
      i = startIndex;
    } else if(i > endIndex) {
      // After last tag
      components.push(text.slice(endIndex + 1,));
      i = text.length;
    } else {
      // replace with tag
      const ourEnd = endIndex+1;
      components.push(
        <TagView key={i}>
          {text.slice(startIndex,ourEnd)}
        </TagView>
      );
      i = ourEnd;
      tagIndex += 1;
    }
  }
  if(i < text.length) components.push(text.slice(i,));
  return (
    <BasicView>
      {components}
    </BasicView>
  )
};
