import React, { useCallback, useRef, } from 'react';
import { Table } from 'antd';
import { DndProvider, useDrag, useDrop } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

const defaultType = 'DraggableBodyRow';

const DraggableBodyRow = ({ index, tableType, moveRow, className, style, dataSource, isDraggable, ...restProps }) => {
  const ref = useRef();
  const [{ isOver, dropClassName }, drop] = useDrop({
    accept: tableType || defaultType,
    collect: monitor => {
      const { index: dragIndex } = monitor.getItem() || {};
      if (dragIndex === index) {
        return {};
      }
      return {
        isOver: monitor.isOver(),
        dropClassName: dragIndex < index ? ' drop-over-downward' : ' drop-over-upward',
      };
    },
    drop: item => {
      moveRow(item.index, index);
    },
    canDrop: () => {
      return isDraggable;
    }
  }, [tableType, index, isDraggable, dataSource]);
  const [, drag] = useDrag({
    type: tableType || defaultType,
    item: { index },
    collect: monitor => ({
      isDragging: monitor.isDragging(),
    }),
    canDrag: () => {
      return isDraggable;
    }
  }, [tableType, index, isDraggable]);
  drop(drag(ref));

  return (
    <tr
      ref={ref}
      className={`${className}${isOver ? dropClassName : ''}`}
      style={{ cursor: isDraggable ? 'move' : 'inherit', ...style }}
      {...restProps}
    />
  );
};

const DraggableTable = ({
  title,
  columns,
  dataSource,
  pagination,
  expandable,
  setData,
  type,
  isDraggable,
}) => {
  const components = {
    body: {
      row: DraggableBodyRow,
    },
  };

  const moveRow = useCallback(
    (dragIndex, hoverIndex) => {
      if (dragIndex === hoverIndex) {
        return;
      }

      const dragRow = dataSource[dragIndex];
      const remaining = dataSource.filter(i => i !== dragRow);
      const sorted = [...remaining.slice(0, hoverIndex), dragRow, ...remaining.slice(hoverIndex)];

      setData(
        type,
        sorted
      );
    },
    [dataSource],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <Table
        title={title}
        columns={columns}
        dataSource={dataSource}
        components={components}
        pagination={pagination}
        expandable={expandable}
        onRow={(record, index) => ({
          index,
          moveRow,
          tableType: type,
          isDraggable,
          dataSource,
        })}
        rowKey="rowId"
      />
    </DndProvider>
  );
};

export default DraggableTable;