import React, { useCallback, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Table, Row, Col, Spin,
} from 'antd';
import { LineChartOutlined } from '@ant-design/icons';
import { VariableSizeGrid as Grid } from 'react-window';
import ResizeObserver from 'rc-resize-observer';

import ReportFilterCard from './ReportsFilterCard';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import colors from '../../constants/Colors';

const margin = 20;
const colWidth = 150;

const ROW_HEIGHT = 65;

export default function ReportSkeleton({
  onDataChanged,
  colFilters,
  rowFilters,
  data,
  columns,
  height,
  showProgress = false,
  onProgressClick,
  summaryConfig,
  zeroHoursConfig,
  loading,
  useVirtualization,
}) {
  const gridRef = useRef();

  const offset = margin + (showProgress ? colWidth + margin : 0);
  const onTableDataChange = useCallback((
    _pagination,
    _filters,
    _sorter,
    { currentDataSource = [], action } = {},
  ) => {
    if (action === 'sort' && onDataChanged) {
      onDataChanged(currentDataSource?.filter((item) => !item.isFooter) || []);
    }
  }, [onDataChanged]);

  const [tableDimensions, setTableDimensions] = useState({ width: 1000, height: 1000 });
  const [connectObject] = useState(() => {
    const obj = {
      scrollTo: gridRef.scrollTo,
    };
    Object.defineProperty(obj, 'scrollLeft', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollLeft;
        }
        return null;
      },
      set: (scrollLeft) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollLeft,
          });
        }
      },
    });

    Object.defineProperty(obj, 'scrollTop', {
      get: () => {
        if (gridRef.current) {
          return gridRef.current?.state?.scrollTop;
        }
        return null;
      },
      set: (scrollTop) => {
        if (gridRef.current) {
          gridRef.current.scrollTo({
            scrollTop,
          });
        }
      },
    });
    return obj;
  });

  const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
    ref.current = connectObject; // eslint-disable-line no-param-reassign
    const totalHeight = rawData.length * ROW_HEIGHT;
    return (
      <Grid
        ref={gridRef}
        className="virtual-grid"
        columnCount={columns.length}
        columnWidth={(index) => {
          const { width } = columns[index] ?? {};
          return totalHeight > tableDimensions.height && index === columns.length - 1
            ? width - scrollbarSize - 1
            : width;
        }}
        height={tableDimensions.height - 39}
        rowCount={rawData.length}
        rowHeight={() => ROW_HEIGHT}
        width={tableDimensions.width}
        onScroll={({ scrollLeft }) => {
          onScroll({
            scrollLeft,
          });
        }}
      >
        {({ columnIndex, rowIndex, style }) => {
          const column = columns[columnIndex];
          const record = rawData[rowIndex] ?? {};
          const value = record[column.dataIndex];
          const body = column.render
            ? column.render(value, record)
            : value;
          return (
            <div
              className="virtual-report-table-cell"
              style={style}
            >
              <div className="virtual-table-cell-inner">
                {body}
              </div>
            </div>
          );
        }}
      </Grid>
    );
  };

  return (
    <div style={{ width: '100%', height: 'calc(100% - 32px)' }}>
      <Row>
        <Col flex="125px" style={{ alignItems: 'right', marginRight: margin }}>
          <Row>
            {summaryConfig && <ReportFilterCard {...summaryConfig} title="Show Summary" />}
          </Row>
        </Col>
        <Col style={{ width: `calc(100% - 125px - ${offset}px` }}>
          {colFilters}
        </Col>
        {showProgress && (
          <Col flex={`${colWidth}px`} style={{ marginLeft: margin, paddingTop: 20 }}>
            <Row justify="end" style={{ width: '100%', height: '100%' }} align="bottom">
              <Col>
                <BorderlessButton
                  title="Show Progress"
                  iconNode={<LineChartOutlined />}
                  style={{
                    color: colors.ONTRACCR_RED,
                    backgroundColor: 'transparent',
                  }}
                  onClick={onProgressClick}
                />
              </Col>
            </Row>
          </Col>
        )}
      </Row>
      <Row style={{ width: '100%' }}>
        <Col
          flex="135px"
          style={{
            alignItems: 'right',
            marginRight: margin,
            padding: 2,
            maxHeight: tableDimensions.height + 35,
          }}
        >
          {zeroHoursConfig && <ReportFilterCard {...zeroHoursConfig} title="Show Zero Hr." />}
          {rowFilters}
        </Col>
        <Col style={{ width: `calc(100% - 135px - ${margin}px` }}>
          <ResizeObserver
            onResize={({ height: newHeight, width }) => {
              setTableDimensions({ height: newHeight, width });
            }}
          >
            <Table
              size="small"
              pagination={false}
              tableLayout="fixed"
              bordered
              style={{
                borderRadius: 6,
                border: '1 solid gray',
                height,
                marginTop: 20,
              }}
              columns={columns}
              dataSource={data}
              scroll={{
                y: height,
                x: 'max-content',
              }}
              onRow={() => ({ height: 40, overflow: 'ellipsis' })}
              rowClassName={() => 'ontraccr-row'}
              onChange={onTableDataChange}
              components={
                useVirtualization
                  ? { body: renderVirtualList }
                  : undefined
              }
            />
          </ResizeObserver>
          {
            loading && (
              <Row
                style={{
                  position: 'absolute',
                  inset: '0',
                }}
                align="middle"
                justify="center"
              >
                <Col>
                  <Spin />
                </Col>
              </Row>
            )
          }
        </Col>
      </Row>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
ReportSkeleton.propTypes = {
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      dataIndex: PropTypes.string,
      render: PropTypes.func,
    }),
  ).isRequired,
  summaryConfig: PropTypes.object,
  zeroHoursConfig: PropTypes.object,
  loading: PropTypes.bool,
  onDataChanged: PropTypes.func,
};

ReportSkeleton.defaultProps = {
  summaryConfig: undefined,
  zeroHoursConfig: undefined,
  loading: false,
  onDataChanged: null,
};
