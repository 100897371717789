import React, { useMemo } from 'react';

import TitleRow from '../forms/FormBuilder/FormFields/TitleRow';

import { timestampsToText } from './boards.helpers';

export default ({
  response: { startTime, endTime, timezone } = {},
  configProps:  { title } = {},
  showCondensedView,
  valueStyle = {},
  titleStyle = {},
}) => {
  const dateText = useMemo(() => {
    const val = timestampsToText({ startTime, endTime, timezone });
    return (
      <span style={{ fontSize: 12, ...valueStyle }}>{val}</span>
    );
  }, [startTime, endTime, timezone, valueStyle]);


  return (
    <div>
      <TitleRow
        title={title}
        optional
        filter={showCondensedView ? dateText : null}
        showCondensedView={showCondensedView}
        titleStyle={titleStyle}
      />
      { !showCondensedView && dateText}
    </div>
  );
}