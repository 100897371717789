import React, {
  useState,
  useCallback,
} from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col, Popconfirm, Form, DatePicker } from 'antd';
import { DateTime } from 'luxon';
import moment from 'moment';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import {
  updateGanttScheduleHoliday,
} from '../state/schedule.actions';

const GanttScheduleHolidayInputField = ({
  scheduleId,
  field,
  holiday,
  type,
  title,
}) => {
  const dispatch = useDispatch();
  const [ showPopover, setShowPopover ] = useState(false);
  const [ value, setValue ] = useState(holiday[field]);

  const onConfirm = useCallback(async () => {
    const updatedHoliday = {
      name: holiday.name,
      date: holiday.date,
      [field]: value,
    };

    // Account for UTC
    if (field === 'date') {
      updatedHoliday[field] = DateTime
        .fromSeconds(value)
        .toUTC(0)
        .startOf('day')
        .toSeconds();
    }

    if (await dispatch(updateGanttScheduleHoliday(scheduleId, holiday.id, updatedHoliday))) {
      setShowPopover(false);
    }
  },[value, dispatch, scheduleId, holiday]);

  const onCancel = useCallback(() => {
    setValue(holiday[field]);
    setShowPopover(false);
  }, [holiday, field]);

  const onChange = useCallback((newValue) => {
    setShowPopover(true);

    let newVal = newValue;

    if (type ==='text') {
      newVal = newValue.target.value;
    } else if (type === 'date') {
      newVal = newValue.valueOf() / 1000;
    }

    if (holiday[field] === newVal) {
      setShowPopover(false);
    }

    setValue(newVal);
  }, [holiday, type]);

  return (
    <Row style={{ height: '100%' }} align='middle' gutter={10}>
      <Col style={{ height: 32, width: '100%' }}>
        <Popconfirm
          visible={showPopover}
          title={`Update ${title}`}
          okText='Update'
          onConfirm={onConfirm}
          onCancel={onCancel}
        >
          {
            type === 'date' ?
              <Form.Item
                name={`date-${holiday.id}`}
                label={''}
                className='schedule-form-item'
                initialValue={holiday.date ? moment.utc(holiday.date * 1000) : null}
              >
                <DatePicker format='MMM Do YY' allowClear={false} onChange={onChange}/>
              </Form.Item>
            :
              <OnTraccrTextInput
                value={value}
                placeholder={title}
                onChange={onChange}
                onPressEnter={onConfirm}
              />
          }
        </Popconfirm>
      </Col>
    </Row>
  );
}

export default GanttScheduleHolidayInputField;