import React from 'react';
import { Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import DisplayText from '../common/text/DisplayText';
import BorderlessButton from '../common/buttons/BorderlessButton';

import RelativeTimeSelector from './RelativeTimeSelctor';

export default function ({
  isDisplay,
  value = [],
  onChange,
}) {
  const onAddNewReminder = () => {
    onChange(value.concat([{ id: DateTime.local().toMillis(), time: 'days', amount: 1 }]));
  };

  const onDeleteReminder = (id) => () => {
    onChange(value.filter((val) => val.id !== id));
  };

  const updateValues = (id, newData = {}) => {
    onChange(
      value.map((val) => {
        if (val.id !== id) return val;
        return { ...val, ...newData };
      }),
    );
  };

  const onUpdateAmount = (id) => (amount) => {
    updateValues(id, { amount });
  };
  const onSelectTime = (id) => (time) => {
    updateValues(id, { time });
  };

  if (isDisplay) {
    return value.map((val) => (
      <DisplayText title={`${val.amount} ${val.time} before due date.`}/>
    ));
  }

  return (
    <>
      {
        value.map((val) => (
          <RelativeTimeSelector
            key={val.id}
            amount={val.amount}
            time={val.time}
            onUpdateAmount={onUpdateAmount(val.id)}
            onSelectTime={onSelectTime(val.id)}
            onDelete={onDeleteReminder(val.id)}
            text="before due date."
          />
        ))
      }
      <Row justify="start" align="middle">
        <Col>
          {!isDisplay && (
            <BorderlessButton
              iconNode={<PlusOutlined />}
              onClick={onAddNewReminder}
              title="Add Reminder"
            />
          )}
        </Col>
      </Row>
    </>
  );
}
