import axios from 'axios';

import { request } from '../../helpers/requests';

const ROUTE = '/globalMaterialLocations';

export default {
  get: () => request({
    call: axios.get(ROUTE),
    errMsg: 'Could not get global locations',
    hideSuccessToast: true,
  }),
  create: (locationText) => request({
    call: axios.post(ROUTE, { locationText }),
    errMsg: 'Could not create global location',
  }),
  delete: (id) => request({
    call: axios.delete(`${ROUTE}/${id}`, { id }),
    errMsg: 'Could not delete global location',
  }),
  update: (id, locationText) => request({
    call: axios.put(`${ROUTE}/${id}`, { locationText }),
    errMsg: 'Could not update global location',
  }),
};
