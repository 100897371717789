import React from 'react';

import { Form, Select } from 'antd';

import OnTraccrNumberInput from '../../inputs/OnTraccrNumberInput';

import PDFDesignerColorPicker from './PDFDesignerColorPicker';

export default ({
  onDrawOptionChanged,
  drawOptions = {},
  style = {},
}) => {
  const {
    borderWidth,
    borderColor,
    borderStyle = 'none',
  } = drawOptions;
  
  const hasBorder = borderStyle !== 'none';
  return (
    <>
    <Form.Item
      name='borderStyle'
      key='borderStyle'
      label='Border Style'
      style={style}
    >
      <Select
        onSelect={(newBorderStyle) => onDrawOptionChanged({ borderStyle: newBorderStyle })}
        defaultValue={borderStyle}
      >
        <Select.Option value='none'> None </Select.Option>
        <Select.Option value='solid'> Solid </Select.Option>
        <Select.Option value='dashed'> Dashed </Select.Option>      
      </Select>
    </Form.Item>

    {hasBorder &&
      <PDFDesignerColorPicker
        style={style}
        title='Border Color'
        value={borderColor}
        onColorChanged={(newColor) => onDrawOptionChanged({ borderColor: newColor })}
      />
    }
    {hasBorder &&
      <Form.Item
        name='borderWidth'
        key='borderWidth'
        label='Border Width'
        style={style}
      >
        <OnTraccrNumberInput
          style={{ width: '100%' }}
          min={1}
          max={6}
          defaultValue={borderWidth}
          onChange={(newBorderWidth) => onDrawOptionChanged({ borderWidth: newBorderWidth })}
        />
      </Form.Item>
      }
    </>
  );
}
