import React, { useState, useEffect, useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Divider,
  message,
} from 'antd';
import {
  EyeOutlined,
  EyeInvisibleOutlined,
} from '@ant-design/icons';
import axios from 'axios';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import DisplayText from '../../../common/text/DisplayText';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

import CompanyEditRow from '../../CompanyEditRow';
import AuroraSolarWorkflowDrawer from './AuroraSolarWorkflowDrawer';

import { request } from '../../../helpers/requests';
import useToggle from '../../../common/hooks/useToggle';

import Colors from '../../../constants/Colors';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';

import Permissions from '../../../auth/Permissions';

import Debouncer from '../../../helpers/Debouncer';

const flavourText = (
  <div>
    Deactivating your Aurora Solar integration will disable any
    further syncing between Ontraccr and Aurora Solar.
  </div>
);

const hideCode = new Array(36).fill('•').join('');

const INPUT_STYLE = {
  marginLeft: 10,
  marginRight: 40,
  marginBottom: 20,
  maxWidth: 440,
};
const DIVIDER_STYLE = {
  margin: 0,
  backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
  maxWidth: 440,
  minWidth: 440,
};

const debouncers = {
  apiKey: new Debouncer(),
  tenantId: new Debouncer(),
};

function AuroraSolar({
  visible,
}) {
  const company = useSelector((state) => state.settings.company);
  const {
    userId: ownerId,
  } = company ?? {};

  const [config, setConfig] = useState();
  const [showCode, setShowCode] = useState(false);

  const {
    isToggled: showDrawer,
    toggle: toggleDrawer,
  } = useToggle();

  const toggleShowCode = useCallback(() => {
    setShowCode(!showCode);
  }, [showCode]);

  const update = useCallback(async (payload) => {
    try {
      const { data: newConfig } = await axios.put(`/aurora/connection/${config.id}`, payload);
      if (newConfig) {
        setConfig({ ...config, ...newConfig });
        return true;
      }
    } catch (err) {
      message.error('Failed to update Aurora Solar config');
    }
    return false;
  }, [config]);

  const onTextChanged = useCallback((type) => (e) => {
    if (!(type in debouncers)) return;
    const {
      target: { value } = {},
    } = e;
    setConfig({
      ...config,
      [type]: value,
    });

    debouncers[type].debounce(() => {
      if (value) update({ [type]: value });
    }, 250);
  }, [config, update]);

  const onConnect = useCallback(async () => {
    const { data: newConfig } = await request({
      call: axios.put('/aurora/connect'),
    });
    setConfig(newConfig);
  }, []);

  const showConfirm = useCallback(() => (
    CustomConfirmModal({
      title: 'Deactivate Aurora Solar Integration',
      content: (
        <p>
          Are you sure you want to your deactivate Aurora Solar integration?
          <br />
          <br />
          {flavourText}
        </p>
      ),
      async onOk() {
        const { data: passed } = await request({
          call: axios.delete('/aurora/connection'),
        });
        if (passed) {
          setConfig();
        }
      },
      okText: 'Deactivate',
    })
  ), []);

  useEffect(() => {
    const loadConfig = async () => {
      const { data: newConfig } = await axios.get('/aurora/connection');
      if (newConfig?.id) setConfig(newConfig);
    };
    if (visible) {
      loadConfig();
    } else {
      setConfig();
      setShowCode(false);
    }
  }, [visible]);

  if (!config?.id) {
    return (
      <OnTraccrButton
        style={{ margin: '20px 0px' }}
        onClick={onConnect}
        title="Connect"
      />
    );
  }

  return (
    <div style={{ width: '100%', overflowY: 'auto' }}>
      <div style={{ width: '100%', position: 'relative' }}>
        <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
          <Col>
            <DisplayText
              title="Authorization Code"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
          </Col>
        </Row>
        <DisplayText
          title="Provide this authorization code to your Aurora Solar Webhook"
          style={{
            marginBottom: 0,
            maxWidth: 275,
            paddingLeft: 10,
            fontFamily: 'roboto-regular',
            color: Colors.ONTRACCR_OPACITY_GRAY,
          }}
        />
        <div className="integation-auth-code">
          {showCode || !config.webhookKey ? config.webhookKey : hideCode}
        </div>
        {
        config.webhookKey
        && (
        <div id="gmail-visibility-toggle-container">
          <BorderlessButton
            iconNode={showCode ? <EyeInvisibleOutlined /> : <EyeOutlined />}
            onClick={toggleShowCode}
            style={{ padding: 0 }}
          />
        </div>
        )
      }
        <Divider />
      </div>

      <CompanyEditRow
        title="API Key"
        helpText="Your Aurora Solar API Key"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('apiKey')}
        value={config.apiKey}
        password
        autoComplete="new-password"
      />

      <CompanyEditRow
        title="Tenant ID"
        helpText="Your Aurora Tenant ID"
        divider={false}
      />
      <OnTraccrTextInput
        style={INPUT_STYLE}
        onChange={onTextChanged('tenantId')}
        value={config.tenantId}
      />
      <CompanyEditRow
        title="Mappings"
        helpText="Configure mappings between Aurora Solar Projects and Ontraccr Board Card Fields"
        divider={false}
      >
        <OnTraccrButton
          title="Configure"
          onClick={toggleDrawer}
        />
      </CompanyEditRow>
      <Divider style={DIVIDER_STYLE} />
      <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
        <Col>
          <DisplayText
            title="Deactivate Aurora Solar Integration"
            style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
          />
        </Col>
      </Row>
      <DisplayText
        title={flavourText}
        style={{
          marginBottom: 20,
          maxWidth: 275,
          paddingLeft: 10,
          fontFamily: 'roboto-regular',
          color: Colors.ONTRACCR_OPACITY_GRAY,
        }}
      />
      <Row style={{ width: '100%', marginBottom: 10 }} justify="center">
        <OnTraccrButton
          title="Deactivate"
          type={Permissions.id === ownerId ? 'primary' : 'back'}
          disabled={Permissions.id !== ownerId}
          style={{ margin: 10 }}
          onClick={showConfirm}
        />
        <DisplayText
          style={{
            textAlign: 'center',
            fontFamily: 'roboto-bold',
            color: Colors.ONTRACCR_OPACITY_GRAY,
            height: 32,
          }}
          title="Only the account owner can deactivate"
        />
      </Row>
      <AuroraSolarWorkflowDrawer
        config={config}
        visible={showDrawer}
        onClose={toggleDrawer}
        update={update}
      />
    </div>
  );
}

AuroraSolar.propTypes = {
  visible: PropTypes.bool,
};

AuroraSolar.defaultProps = {
  visible: false,
};

export default AuroraSolar;
