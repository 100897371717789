import {
  AntDesignOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  BankOutlined,
  BlockOutlined,
  BorderOuterOutlined,
  BoxPlotOutlined,
  DeploymentUnitOutlined,
  DragOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  GatewayOutlined,
  HeatMapOutlined,
  PicCenterOutlined,
  RadarChartOutlined,
  RadiusSettingOutlined,
  StarOutlined,
} from '@ant-design/icons';

const IconValueMap = {
  AntDesignOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  BankOutlined,
  BlockOutlined,
  BorderOuterOutlined,
  BoxPlotOutlined,
  DeploymentUnitOutlined,
  DragOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  GatewayOutlined,
  HeatMapOutlined,
  PicCenterOutlined,
  RadarChartOutlined,
  RadiusSettingOutlined,
  StarOutlined,
};

export default IconValueMap;
