import React, { useState, useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Popover, Row, Col } from 'antd';
import {
  CloseOutlined
} from '@ant-design/icons';
import { DateTime } from 'luxon';

import MonthShiftDayEntry from './MonthShiftDayEntry';
import MonthMultiDayEntrySimple from './MonthMultiDayEntrySimple';

const Content = ({
  user,
  day,
  onShiftSelect,
  multiDayShifts = [],
  interDayShifts = [],
}) => (
  <div className='schedule-month-more-container'>
    {
      multiDayShifts.map((shift) => (
        <MonthMultiDayEntrySimple
          user={user}
          key={shift.id}
          shift={shift}
          day={day}
          onClick={onShiftSelect}
        />
      ))
    }
    {
      interDayShifts.map((shift) => (
        <MonthShiftDayEntry
          user={user}
          key={shift.id}
          shift={shift}
          onClick={onShiftSelect}
        />
      ))
    }
  </div>
);

const Title = ({
  day,
  onClose,
}) => (
  <Row justify='space-between' align='middle'>
    <Col>
      {day.toLocaleString(DateTime.DATE_MED_WITH_WEEKDAY)}
    </Col>
    <Col>
      <CloseOutlined
        className='schedule-month-more-close'
        onClick={onClose}
      />
    </Col>
  </Row>
)

export default ({
  user,
  day,
  moreCount,
  onShiftSelect,
  multiDayShifts = [],
  interDayShifts = [],
}) => {
  const viewingShift = useSelector(state => state.schedule.viewingShift);
  const [visible, setVisible] = useState(false);
  const showPopover = useCallback(() => setVisible(true),[]);
  const hidePopover = useCallback(() => setVisible(false),[]);
  const handleShiftClick = useCallback((shift) => {
    setVisible(false);
    onShiftSelect(shift);
  },[onShiftSelect]);

  useEffect(() => {
    if(viewingShift) setVisible(false);
  },[viewingShift]);

  if(moreCount <= 0) return null;
  return (
    <Popover
      visible={visible}
      placement='leftBottom'
      title={
        <Title
          day={day}
          onClose={hidePopover}
        />
      }
      content={
        <Content
          user={user}
          day={day}
          multiDayShifts={multiDayShifts}
          interDayShifts={interDayShifts}
          onShiftSelect={handleShiftClick}
        />
      }
    >
      <div className='schedule-month-more' onClick={showPopover}>
          <b>{moreCount}</b> more item{moreCount === 1 ? '' : 's'}
      </div>
    </Popover>
  );
}