import React, { useCallback, useEffect, useState } from 'react';
import { useSpring, animated } from 'react-spring';
import { Row, Col } from 'antd';
import { DownOutlined } from '@ant-design/icons';

const COLLAPSED_HEIGHT = 40;
const EXPANDED_HEIGHT = 600;

export default ({
  headerStyle = {},
  title,
  rightView,
  children,
}) => {
  const [style,setStyle] = useSpring(() => ({ maxHeight: EXPANDED_HEIGHT }));
  const [iconStyle,setIconStyle] = useSpring(() => ({ transform: 'rotate(0deg)' }));
  const [open,setOpen] = useState(true);

  const onClick = useCallback(() => {
    setOpen(!open);
  },[open]);

  useEffect(() => {
    setStyle({
      maxHeight:open ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT,
    });
    setIconStyle({
      transform: open ? 'rotate(0deg)'  : 'rotate(-90deg)' ,
    })
  },[open, setIconStyle, setStyle]);

  return (
    <animated.div
      className='collapsable-container'
      style={style}
    >
      <Row 
        className='collapsable-header'
        style={{ 
          height:COLLAPSED_HEIGHT - 1, // Subtract 1 for border or end of animation has a weird snap effect
          ...headerStyle,
        }}
        justify='space-between'
        gutter={10}
        align='middle'
      >
        <Col style={{ width: 'calc(100% - 120px)'}}>
          <Row justify='start' align='middle' gutter={20}>
            <Col>
              <animated.div style={{
                cursor:'pointer',
                ...iconStyle,
              }} onClick={onClick}>
                <DownOutlined/>
              </animated.div>
            </Col>
            {title}
          </Row>
        </Col>
        {rightView}
      </Row>
      <animated.div
        className='collapsable-contents-container'
        style={{ 
          height:style.maxHeight.interpolate({
            range:[COLLAPSED_HEIGHT,EXPANDED_HEIGHT],
            output:[0,'fit-content']
          }),
          maxHeight:EXPANDED_HEIGHT - COLLAPSED_HEIGHT,
        }}
      >
        {children}
      </animated.div>
    </animated.div>
  );
}
