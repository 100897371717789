import React, { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';
import {
  Collapse,
  Table,
  Popover,
} from 'antd';
import { withTranslation } from 'react-i18next';

import AutoImport from './IntegrationAutoImport';
import CanImportCol from './IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from './IntegrationIsConnectedCol';
import { createImportData, linkInitialData } from './integrationHelpers';

import sortByString, { getIdMap } from '../../helpers/helpers';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';

const { Panel } = Collapse;

// Name must be unique per category
// Code must be unique per "type" (e.g. global or project specific)
const getNameKey = (cc) => `${cc.name.toLowerCase()}.${cc.type || 'global'}.${cc.categoryId}`;
const getCodeKey = (cc) => `${cc.code}.${cc.type || 'global'}`;

const canImport = (nameMap, codeMap, divisionList) => (record) => (
  record.code
  && record.name
  && record.description
  && (!(getNameKey(record) in nameMap) || nameMap[getNameKey(record)] === record.linkValue)
  && (!(getCodeKey(record) in codeMap) || codeMap[getCodeKey(record)] === record.linkValue)
  && (record.linkValue || record.divisionId || divisionList.length === 1)
);

const includesTerm = ({
  name = '',
  code = '',
  description = '',
  projectName = '',
}, searchStr, showProject) => {
  if (!searchStr) return true;
  const sLower = searchStr.toLowerCase();
  return name.toLowerCase().includes(sLower)
  || code.toLowerCase().includes(sLower)
  || description.toLowerCase().includes(sLower)
  || (showProject && projectName.toLowerCase().includes(sLower));
};

export default withTranslation()(connect(
  (state, ownProps) => (
    {
      ...ownProps,
      costcodes: state.costcodes.costcodes,
      divisions: state.settings.divisions,
    }
  ),
  {},
)(({
  integrationKey,
  title,
  costcodes = [],
  integrationCodes,
  onCostcodesChanged,
  autoImportContent,
  importHelpText,
  showProject,
  importCostcodes,
  divisions = {},
  divisionFilter,
  t,
}) => {
  const divisionList = useMemo(() => (
    Object.values(divisions)
      .filter((division) => !divisionFilter || divisionFilter.has(division.id))
  ), [divisions, divisionFilter]);

  const linkableCC = useMemo(() => (
    costcodes.filter((cc) => (
      cc.active
      && !cc.projectId
      && (!divisionFilter || divisionFilter.has(cc.divisionId))
    ))
  ), [costcodes, divisionFilter]);

  const [stateCostcodes, setStateCostcodes] = useState(integrationCodes ?? []);
  const [activePanels, setActivePanels] = useState(['qb']);
  const [searchStr, setSearchStr] = useState();
  const [importList, setImportList] = useState(new Set());
  const [nameMap, setNameMap] = useState({});
  const [codeMap, setCodeMap] = useState({});
  const [autoImport, setAutoImport] = useState(true);
  const [connected, setConnected] = useState({});

  const ccMap = useMemo(() => (
    getIdMap(costcodes.filter((cc) => cc.active && !cc.project))
  ), [costcodes]);

  const updateCostCodes = (record) => {
    setStateCostcodes(stateCostcodes.map((user) => {
      if (user[integrationKey] !== record[integrationKey]) return user;
      return record;
    }));
  };

  const resetImportList = (list) => () => {
    setImportList(new Set(list.map((cc) => cc[integrationKey])));
  };

  useEffect(() => {
    setConnected(createConnectedArray(linkableCC, integrationKey));
  }, [linkableCC, integrationKey]);

  useEffect(() => {
    if (integrationCodes?.length) {
      setStateCostcodes(linkInitialData({ items: integrationCodes, connected, integrationKey }));
    }
    resetImportList(integrationCodes ?? [])();
  }, [integrationCodes, connected]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const newNameSet = {};
    const newCodeSet = {};

    costcodes.forEach((cc) => {
      const { id, type } = cc;
      if (type !== 'global') return;
      newNameSet[getNameKey(cc)] = id;
      newCodeSet[getCodeKey(cc)] = id;
    });

    setNameMap(newNameSet);
    setCodeMap(newCodeSet);
  }, [costcodes, integrationCodes, importList, integrationKey]);

  useEffect(() => {
    const importData = createImportData({
      integrationKey,
      newData: integrationCodes ?? [],
      stateData: stateCostcodes,
      originalData: linkableCC,
      importList,
      canImport: canImport(nameMap, codeMap, divisionList),
      connected,
      divisionList,
    });
    onCostcodesChanged(importData);
  }, [
    autoImport,
    importList,
    stateCostcodes,
    linkableCC,
    onCostcodesChanged,
    codeMap,
    nameMap,
    integrationCodes,
    connected,
    integrationKey,
    divisionList,
  ]);

  const getCols = (editable) => {
    const cols = [
      IsConnectedCol({
        integrationKey,
        title,
        connected,
        onUnLink:(record) => {
          const newConnected = {...connected};
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if (editable) {
            record.linkValue = null;
            updateCostCodes(record);
          } else {
            setStateCostcodes(stateCostcodes.map((cc) => {
              if (cc[integrationKey] !== record[integrationKey]) return cc;
              const newUser = {...cc};
              delete newUser.linkValue;
              return newUser;
            }));
          }
        }
      }),
      {
        title:'Code',
        dataIndex:'code',
        key:'code',
        align:'center',
        fixed:'left',
        sorter:sortByString('code'),
        showSorterTooltip:false,
        sortDirections:['descend', 'ascend'],
        render:(_, record) => {
          const key = getCodeKey(record);
          return (
            editable && key in codeMap && codeMap[key] !== record.linkValue ?
              <Popover content='Code must be unique in Ontraccr'>
                <div style={{
                color:'red',
              }}>{record.code}</div>
              </Popover> : record.code
          );
        }
      },
      {
        title:'Name',
        dataIndex:'name',
        key:'name',
        align:'center',
        sorter:sortByString('name'),
        showSorterTooltip:false,
        sortDirections:['descend', 'ascend'],
        render:(_, record) =>  {
          const key = getNameKey(record);
          return (
            editable && key in nameMap && nameMap[key] !== record.linkValue ?
              <Popover content='Name must be unique in Ontraccr'>
                <div style={{
                color:'red',
              }}>{record.name}</div>
              </Popover> : record.name
          );
        }
      },
      {
        title:'Division',
        dataIndex:'divisionId',
        key:'divisionId',
        align:'center',
        fixed:'left',
        width: 170,
        render: (_, record) => (
          <DivisionSelector
            key={record.id}
            displayMode={!editable}
            style={{ width: '100%' }}
            displayStyle={{ marginBottom: 0 }}
            onChange={(newDivisionId) => {
              if (newDivisionId !== record.divisionId) {
                record.divisionId = newDivisionId;
                updateCostCodes(record);
              }
            }}
            divisionId={record.divisionId}
            disabled={record.viewType === 'link'}
            filter={divisionFilter}
          />
        ),
      },
      {
        title:'Description',
        dataIndex:'description',
        key:'description',
        align:'center',
        sorter:sortByString('description'),
        showSorterTooltip:false,
        sortDirections:['descend', 'ascend'],
      }
    ];
    if (showProject) {
      cols.push({
        title: t('Project'),
        dataIndex:'projectName',
        key:'projectName',
        align:'center',
        sorter:sortByString('projectName'),
        showSorterTooltip:false,
        sortDirections:['descend', 'ascend'],
      });
    }
    if (editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          title,
          connected,
          helpText:importHelpText,
          canImport:canImport(nameMap, codeMap, divisionList),
          importList,
          setImportList,
          resetImportList: resetImportList(integrationCodes),
          onViewTypeChanged:(record, viewType) => {
            record.viewType = viewType;
            updateCostCodes(record);
          },
          linkOptions:linkableCC,
          linkLabel:(cc) => `${cc.code} - ${cc.name}`,
          onLinkChanged:(record, linkValue) => {
            const newConnected = {...connected};
            const oldLink = connected[record[integrationKey]];
            if (oldLink) delete oldLink[integrationKey];
            if (linkValue) {
              newConnected[record[integrationKey]] = ccMap[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
            } else {
              delete newConnected[record[integrationKey]];
            }

            setConnected(newConnected);

            record.linkValue = linkValue;
            updateCostCodes(record);
          },
        })
      );
    }
    return cols;
  };

  const activeCostcodes = useMemo(() => (
    linkableCC.filter((cc) => includesTerm(cc, searchStr, showProject))
  ), [linkableCC, searchStr, showProject]);
  const activeStateCostcodes = useMemo(() => (
    stateCostcodes.filter((cc) => includesTerm(cc, searchStr, showProject))
  ), [stateCostcodes, searchStr, showProject]);

  return (
    <div style={{ height: 'calc(100% - 55px)', width:'100%', overflowY:'scroll'}}>
      <AutoImport
        setActivePanels={setActivePanels}
        setAutoImport={setAutoImport}
        autoImport={autoImport}
        helpContent={autoImportContent}
        importType='Cost Code'
        importData={importCostcodes}
      />
      {!autoImport && <OnTraccrTextInput
        allowClear
        style={{
          width: 250,
        }}
        search
        onChange={(e) => {
          const {
            target:{
              value
            } = {}
          } = e;
          setSearchStr(value);
        }}
        onClick={(e) => e.stopPropagation()}
      />}
      {!autoImport && <Collapse ghost activeKey={activePanels} onChange={setActivePanels}>
        <Panel
          header={`New ${title} Costcodes`}
          key='new'
          className='ontraccr-collapse-panel'
        >
          <Table
            bordered
            size='small'
            columns={getCols(true)}
            dataSource={activeStateCostcodes}
            scroll={{
              y: '40%',
              x: 1000,
            }}
            pagination={{
              hideOnSinglePage:true,
              pageSize:20,
              showSizeChanger:false,
            }}
            rowKey={integrationKey}
          />
        </Panel>
        <Panel
          header='Ontraccr Costcodes'
          key='ontraccr'
          className='ontraccr-collapse-panel'
        >
          <Table
            bordered
            size='small'
            columns={getCols(false)}
            dataSource={activeCostcodes}
            scroll={{
              y: '40%',
            }}
            pagination={{
              hideOnSinglePage:true,
              pageSize:20,
              showSizeChanger:false,
            }}
            rowKey='id'
          />
        </Panel>
      </Collapse>}
    </div>
  );
}));
