import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Timeline } from 'antd';
import {
  EditOutlined,
  ThunderboltOutlined,
  SaveOutlined,
  SwapOutlined,
  BgColorsOutlined,
  MailOutlined,
  PlusOutlined,
  FormOutlined,
} from '@ant-design/icons';

import TimelineStepText from '../common/containers/TimelineStepText';

import { getCardEvents } from './state/boards.actions';
import { getIdMap } from '../helpers/helpers';

import Permissions from '../auth/Permissions';
import FormUpdateStep from '../forms/CompletedForms/FormUpdateStep';

const PROP_MAP = {
  create: {
    title: 'Card Created',
    dot: <SaveOutlined />,
  },
  edit: {
    title: 'Card Edited',
    dot: <EditOutlined/>,
  },
  workflowCreate: {
    title: 'Card Created from Workflow',
    dot: <ThunderboltOutlined />
  },
  statusChange: {
    title: 'Card Status Changed',
    dot: <SwapOutlined/>,
  },
  workflowUpdate: {
    title: 'Card Updated from Workflow',
    dot: <FormOutlined />,
  },
};

const bold = (text) => <span style={{ fontFamily:'roboto-regular'}}>{text} </span>;
const Ontraccr = bold('Ontraccr');
const boardPrefix = (board) => board ? `${board}:` : '';
const statusTitle = (board, status) => bold(`${boardPrefix(board)}${status}`);
export default ({
  active,
  cardId,
  canEdit,
}) => {
  const dispatch = useDispatch();
  const cardEvents = useSelector(state => state.boards.cardEvents);
  const selectedBoard = useSelector(state => state.boards.selectedBoard);
  const users = useSelector(state => state.users.users);

  const userMap = useMemo(() => getIdMap(users),[users]);
  const statusMap = useMemo(() => {
    const { statuses = [] } = selectedBoard ?? {};
    return getIdMap(statuses);
  },[selectedBoard]);
  const eventList = useMemo(() => {
    const {
      [cardId]: ourList = [],
    } = cardEvents;
    return ourList.map((event) => {
      const {
        id: eventId,
        type,
        timestamp,
        userId,
        metadata,
        subType,
      } = event;
      const props = PROP_MAP[type] ?? {};
      let { [userId]: { name: userName } = {} } = userMap;
      let text;
      if (Permissions.id === userId) userName = 'You';
      switch(type) {
        case 'create': {
          const { status, board } = metadata;
          const statusText = status ? <span> with status {statusTitle(board, status)}.</span> : '.';
          if (userName) {
            text = <span>{bold(userName)} created the card{statusText}</span>;
          } else {
            text = <span>The card was created{statusText}</span>;
          }
          break;
        }
        case 'edit': {
          text = userName ? <span>{bold(userName)} edited the card.</span> : 'The card was edited.';
          break;
        }
        case 'workflowCreate': {
          const { status, board, triggerName } = metadata;
          const triggerText = triggerName
            ? <span>used the {bold(triggerName)} workflow to create</span>
            : 'created';
          const statusText = status ? <span> with status {statusTitle(board, status)}</span> : '';
          text = <span>{Ontraccr} {triggerText} the card{statusText}</span>;
          break;
        }
        case 'workflowUpdate': {
          const { status, board, triggerName, oldData, newData } = metadata;
          const triggerText = triggerName
            ? <span>used the {bold(triggerName)} workflow to update</span>
            : 'updated';
          const statusText = status ? <span> with status {statusTitle(board, status)}</span> : '';
          text = (
            <>
              <span>{Ontraccr} {triggerText} the card{statusText}</span>
              <FormUpdateStep
                oldData={{ sections: oldData }}
                newData={{ sections: newData }}
              />
            </>
          );
          break;
        }
        case 'statusChange': {
          const {
            board,
            sourceStatus,
            sourceBoard = board,
            destinationStatus,
            destinationBoard = board,
            triggerName,
          } = metadata;
          const destText = destinationStatus
            ? <span> to {statusTitle(destinationBoard, destinationStatus)}</span>
            : '.';

          const statusText = sourceStatus
            ? <span> from {statusTitle(sourceBoard, sourceStatus)}{destText}</span>
            : destText;
          if (triggerName) {
            // Workflow triggered the status change
            text = <span>{Ontraccr} used the {bold(triggerName)} workflow to move the card{statusText}</span>;
          }
          else if (userName) {
            text = <span>{bold(userName)} moved the card{statusText}</span>;
          } else {
            text = <span>The card was moved{statusText}</span>;
          }
          break;
        }
        case 'statusTime': {
          const {
            emails,
            color,
            triggerName,
          } = metadata;

          switch (subType) {
            case 'changeCardColor':
              text = <span>{Ontraccr} used the {bold(triggerName)} workflow to change the card color to: {color}</span>;
              props.title = 'Workflow Trigger - Card Color Changed';
              props.dot = <BgColorsOutlined />
              break;
            case 'sendEmail':
              text = <span>{Ontraccr} used the {bold(triggerName)} workflow to send an email to: {emails.join(', ')}</span>;
              props.title = 'Workflow Trigger - Email Sent';
              props.dot = <MailOutlined />
              break;
            case 'createTask':
              text = <span>{Ontraccr} used the {bold(triggerName)} workflow to create a task.</span>;
              props.title = 'Workflow Trigger - Task Created';
              props.dot = <PlusOutlined />
              break;
            default:
              break;
          }
          break;
        }
        default: break;
      }

      return (
        <Timeline.Item
          color='black'
          key={eventId}
          {...props}
        >
        <TimelineStepText
          {...props}
          timestamp={timestamp}
          text={text}
        />
        </Timeline.Item>
      )
    });
  },[cardId, cardEvents, userMap, statusMap]);

  useEffect(() => {
    if(dispatch && active && cardId) dispatch(getCardEvents(cardId))
  },[dispatch, active, cardId]);
  return (
    <div className={`board-card-timeline-container-${canEdit ? 'write' : 'read'}`}>
      <Timeline>
        {eventList}
      </Timeline>
    </div>
  )
}
