import { useMemo } from 'react';
import PropTypes from 'prop-types';

export default function useGetFormDropdownField({
  sections = [],
}) {
  return useMemo(() => {
    let relevantCompletedField;
    let relevantSubContractField;

    sections?.forEach(({ fields = [] }) => {
      fields?.forEach((field) => {
        const { selectedType, configProps: { dataType } = {} } = field;
        if (selectedType === 'dropdown') {
          if (dataType === 'CompletedForms') relevantCompletedField = field;
          if (dataType === 'PayableSubContracts') relevantSubContractField = field;
        }
      });
    });
    return { relevantCompletedField, relevantSubContractField };
  }, [sections]);
}

useGetFormDropdownField.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({
    fields: PropTypes.arrayOf(PropTypes.shape({
      selectedType: PropTypes.string,
      configProps: PropTypes.shape({
        dataType: PropTypes.string,
        subDataType: PropTypes.string,
      }),
    })),
  })),
};

useGetFormDropdownField.defaultProps = {
  sections: [],
};
