import React, { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Form } from 'antd';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../../common/inputs/FormTextInput';

import { updateSageShift, createSageShift } from '../../../sage/state/sage.actions';

export default function SageShiftMappingDrawer({
  selectedShift,
  selectedIntegration,
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const {
    id,
    name,
    sageId,
  } = selectedShift || {};
  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      // Create or update shift
      const payload = form.getFieldsValue();
      const payloadWithIntegrationId = {
        ...payload,
        integrationId: selectedIntegration?.id,
      };
      let passed;
      if (id) {
        passed = await dispatch(updateSageShift(id, payloadWithIntegrationId));
      } else {
        passed = await dispatch(createSageShift(payloadWithIntegrationId));
      }
      if (passed) onClose();
    } catch (err) {
      // Validate fields throws error
    }
  }, [form, id, onClose, dispatch, selectedIntegration]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      form.setFieldsValue({ name, sageId });
    }
  }, [form, visible, name, sageId]);
  return (
    <Drawer
      title={id ? `Edit ${name} shift` : 'Add Shift'}
      visible={visible}
      onClose={onClose}
      width={500}
      bodyStyle={{ padding: '0px 24px'}}
    >
      <Form form={form} initialValues={{ name, sageId }} layout="vertical">
        <FormTextInput
          label="Name"
          name="name"
          placeholder="Add shift name"
          isNotDisplay
          rules={[{ required: true, message: 'Name is required' }]}
        />
        <FormTextInput
          label="Sage ID"
          name="sageId"
          placeholder="Add Sage ID"
          isNotDisplay
          rules={[{ required: true, message: 'Sage ID is required' }]}
        />
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

SageShiftMappingDrawer.propTypes = {
  selectedShift: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    sageId: PropTypes.string,
  }),
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
};

SageShiftMappingDrawer.defaultProps = {
  selectedShift: {},
  selectedIntegration: {},
  visible: false,
};
