import React from 'react';

import PasswordPromptModal from '../common/modals/PasswordPromptModal';

const WARNING_HEADER = 'Deleting files or folders is permanent.';
const WARNING_SUBTEXT = 'Deleting folders will delete all contents of those folders';

export default ({
  onClose,
  onDelete,
  visible,
  title,
  promptForPasswordOnDelete = true,
}) => (
  <PasswordPromptModal
    visible={visible}
    onClose={onClose}
    title={title}
    warningHeader={WARNING_HEADER}
    warningSubtext={WARNING_SUBTEXT}
    onDelete={onDelete}
    promptForPasswordOnDelete={promptForPasswordOnDelete}
  />
);