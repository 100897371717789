import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';

import FileReelWithUpload from '../common/files/FileReelWithUpload'

export default ({
  userId
}) => {
  const userFiles = useSelector(state => state.users.userFiles);

  const files = useMemo(() => {
    const {
      [userId]: ourFiles = [],
    } = userFiles;
    return ourFiles;
  }, [ userFiles, userId]);

  if(files.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  return (
    <div>
      <FileReelWithUpload files={files} isDisplay/>
    </div>
  );
}