import React from 'react';
import { Row } from 'antd';

import LiveFeedCard from './LiveFeedCard';

import colors from '../../constants/Colors';

export default ({
  team = {},
  onClick,
}) => {
  const {
    name,
    members = [],
  } = team;
  const memberText = `${members.length} Member${members.length === 1 ? '' : 's'}`;
  return (
    <LiveFeedCard style={{ maxWidth: 175, padding: 15, }} onClick={onClick}>
      <Row style={{
        width: '100%',
        fontFamily:'roboto-bold',
      }} justify='start'>
        {name}
      </Row>
      <Row style={{
        width: '100%',
        fontFamily:'raleway-semibold',
        color:colors.ONTRACCR_DARK_YELLOW,
        pointerEvents:'none',
        fontSize:12
      }} justify='start'>
        {memberText}
      </Row>
    </LiveFeedCard>
  );
}
