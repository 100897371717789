import React from 'react';
import PropTypes from 'prop-types';

import IntegrationMaterialsSync from '../IntegrationMaterialSync';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Items from QuickBooks.
    <br />
    <br />
    1.  Make sure the Items have a name and description set.
    <br />
    <br />
    2. Items with names that conflict with existing Ontraccr Materials will not be imported.
    <br />
    <br />
    If you would rather see exactly which data will be imported from QuickBooks and fill in any missing fields, disable Auto Import to do this manually.
  </div>
);

const importHelpText = (
  <div style={{ width: 500 }}>
    Select from this dropdown to either
    {' '}
    <b>Import</b>
    {' '}
    new Items from QuickBooks or
    {' '}
    <b>Link</b>
    {' '}
    QuickBooks Items to existing Ontraccr Materials
    <br />
    <br />
    <b>Import:</b>
    {' '}
    Materials must have a name and description to be imported into Ontraccr.
    <br />
    <b>Link:</b>
    {' '}
    Select an existing Ontraccr Material to link with the QuickBooks Item.
  </div>
);

export default function QuickBooksMaterialSync({
  quickBooksMaterials,
  onMaterialsChanged,
  importMaterials,
  divisionFilter,
}) {
  return (
    <IntegrationMaterialsSync
      integrationMaterials={quickBooksMaterials}
      title="QuickBooks"
      integrationKey="intuitId"
      onMaterialsChanged={onMaterialsChanged}
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      importMaterials={importMaterials}
      divisionFilter={divisionFilter}
    />
  );
}

QuickBooksMaterialSync.propTypes = {
  quickBooksMaterials: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    intuitId: PropTypes.string,
    quantity: PropTypes.number,
    cost: PropTypes.number,
    markup: PropTypes.number,
  })),
  onMaterialsChanged: PropTypes.func.isRequired,
  importMaterials: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string,
    description: PropTypes.string,
    active: PropTypes.bool,
    intuitId: PropTypes.string,
    quantity: PropTypes.number,
    cost: PropTypes.number,
    markup: PropTypes.number,
  })),
  divisionFilter: PropTypes.instanceOf(Set),
};

QuickBooksMaterialSync.defaultProps = {
  quickBooksMaterials: [],
  importMaterials: [],
  divisionFilter: null,
};
