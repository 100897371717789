import { useEffect, useState } from 'react';
import axios from 'axios';

import Debouncer from '../../helpers/Debouncer';

const debouncer = new Debouncer();
const searchForForms = async (params = {}) => (
  debouncer.debounce(async () => {
    try {
      const { data } = await axios.get('/forms', {
        params,
      });
      return new Set(data?.map?.((form) => form.id) ?? []);
    } catch (err) {
      return new Set();
    }
  }, 500)
);

export default function useFormSearch(params = {}) {
  const [searchSet, setSearchSet] = useState();
  const [searchLoading, setSearchLoading] = useState(false);
  useEffect(() => {
    const search = async () => {
      if (!params?.searchTerm) {
        setSearchLoading(false);
        setSearchSet();
      } else {
        setSearchLoading(true);
        setSearchSet(await searchForForms({
          ...params,
          onlyIDs: true,
        }));
        setSearchLoading(false);
      }
    };
    search();
  }, [params]);

  return { searchSet, searchLoading };
}
