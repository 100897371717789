import React from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Row,
} from 'antd';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import ProjectScheduleOfValues from '../../projects/ProjectScheduleOfValues/ProjectScheduleOfValues';
import DrawerFooter from '../../common/containers/DrawerFooter';

export default function ScheduleOfValuesPreview({
  onDrawerClose,
  visible,
  title,
  templateId,
  isReadOnly = true,
}) {
  if (!templateId || !visible) {
    return null;
  }

  return (
    <Drawer
      visible={visible}
      width={1400}
      onClose={onDrawerClose}
      title={title}
      drawerStyle={{ overflow: 'hidden' }}
    >
      <ProjectScheduleOfValues
        containerStyle={{ bottom: 'unset', top: 40, height: '100%' }}
        templateId={templateId}
        readOnly={isReadOnly}
      />
      <DrawerFooter>
        <Row justify="start">
          <OnTraccrButton
            title="Close"
            type="cancel"
            style={{ marginRight: 8 }}
            onClick={onDrawerClose}
          />
        </Row>
      </DrawerFooter>
    </Drawer>
  );
}

ScheduleOfValuesPreview.propTypes = {
  onDrawerClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  title: PropTypes.string,
  templateId: PropTypes.string,
  isReadOnly: PropTypes.bool,
};

ScheduleOfValuesPreview.defaultProps = {
  title: '',
  templateId: null,
  isReadOnly: true,
};
