import PropTypes from 'prop-types';
import React, {
  useCallback, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Alert, Card, Col, Row, Table,
} from 'antd';
import { WarningTwoTone } from '@ant-design/icons';
import { getIdMap } from 'ontraccr-common/lib/Common';
import { DateTime } from 'luxon';
import { getTaskDate } from 'ontraccr-common/lib/Tasks';
import { getTimeText } from '../../../clock/ManualEntry/manualEntryHelpers';
import HoverHelp from '../../../common/HoverHelp';

const comparisonTitle = () => (
  <div>
    <h4>Data Comparison</h4>
    {'This shows the value of the task in Ontraccr compared to the values being sent to Sage.\nNote these are the current values, values may have changed since the sync was attempted.'}
  </div>
);
const statusHistoryTitle = () => (
  <div>
    <h4>Sync Attempts</h4>
    This shows the history of sync attempts for the task,
    along with the status and error message if applicable.
  </div>
);

const messageMap = {
  'The company data has been opened with exclusive access, no other users can access this company data. Message: Company database is currently open in exclusive access mode': 'Company was open in exclusive access mode in Sage, please sign out of exclusive access and retry',
  zeroHours: 'Entries less than 20 seconds long cannot be synced',
};

const statusMap = {
  written: (
    <Alert
      type="warning"
      message={(
        <Row>
          <Col>
            <HoverHelp
              placement="left"
              content={(
                <div style={{ width: 300 }}>
                  {'This entry was written to HH2, but no status was received.\nIt may take up to 20 minutes for a sync to start after approving or retrying.\nIf this entry was older than 20 days when approved, it will not have been synced.\nIf the computer running Sage is/was offline, or the sync took a long time, the request may still have synced and this can be manually resolved'}
                </div>
              )}
              iconProps={{
                style: { paddingRight: 5 },
              }}
            />
          </Col>
          <Col>
            {' Written to HH2'}
          </Col>
        </Row>
      )}
    />
  ),
  writeRetry: (
    <Alert
      type="warning"
      message={(
        <Row>
          <Col>
            <HoverHelp
              placement="left"
              content={(
                <div style={{ width: 300 }}>
                  {'This entry has failed to write to HH2.\nWe are currently attempting automatic retries'}
                </div>
              )}
              iconProps={{
                style: { paddingRight: 5 },
              }}
            />
          </Col>
          <Col>
            {' Writing to HH2 '}
          </Col>
        </Row>
      )}
    />
  ),
  writeFailed: (
    <Alert
      type="error"
      message={(
        <Row>
          <Col>
            <HoverHelp
              placement="left"
              content={(
                <div style={{ width: 300 }}>
                  {'HH2 refused to accept this time entry.\nConfirm that the values are correct, and that the Job and Costcode are linked in sage if applicable.'}
                </div>
              )}
              iconProps={{
                style: { paddingRight: 5 },
              }}
            />
          </Col>
          <Col>
            {' Write to HH2 Failed'}
          </Col>
        </Row>
      )}
    />
  ),
  syncFailed: (
    <Alert
      type="error"
      message={(
        <Row>
          <Col>
            <HoverHelp
              placement="left"
              content={(
                <div style={{ width: 300 }}>
                  {'Sage refused to accept this time entry. Check the status message for more details if applicable. \nYour company may have been open in exclusive access mode, or you may have payroll requirements enabled which prevented the sync.'}
                </div>
              )}
              iconProps={{
                style: { paddingRight: 5 },
              }}
            />
          </Col>
          <Col>
            {' Sync to Sage Failed'}
          </Col>
        </Row>
      )}
    />
  ),
  synced: (
    <Alert
      type="success"
      message="Synced"
    />
  ),
};

const renderStatus = (status) => statusMap[status];

const warningFlag = (message, hover) => (
  <Row>
    <Col>
      {message}
    </Col>
    <Col>
      <HoverHelp
        Icon={WarningTwoTone}
        iconProps={{ twoToneColor: 'red' }}
        placement="left"
        content={(
          <div style={{ width: 300 }}>
            {hover}
          </div>
        )}
      />
    </Col>
  </Row>
);

export default function SageTimeSyncTaskDetails({
  loading,
  selectedTask = {},
}) {
  const {
    sageData: {
      employees: sageEmployees = [],
      projects: sageProjects = [],
      costcodes: sageCostcodes = [],
    } = {},
  } = useSelector((state) => state.settings);
  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const projects = useSelector((state) => state.projects.projects);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const sageEmployeeMap = useMemo(() => getIdMap(sageEmployees, 'sageId'), [sageEmployees]);
  const sageJobMap = useMemo(() => getIdMap(sageProjects, 'sageId'), [sageProjects]);
  const sageCostcodeMap = useMemo(() => getIdMap(sageCostcodes, 'sageId'), [sageCostcodes]);

  const comparisonData = useMemo(() => {
    const {
      sagePayload: {
        Day,
        Value,
        EmployeeId: { Id: EmployeeId } = {},
        JobId: { Id: JobId } = {},
        StandardCostCodeId: { Id: CostcodeId } = {},
      } = {},
      costcodeId,
      projectId,
      userId,
    } = selectedTask;
    return [
      {
        key: 'Employee',
        ontraccr: userMap[userId],
        sage: sageEmployeeMap[EmployeeId],
      },
      {
        key: 'Date',
        ontraccr: getTaskDate(selectedTask)?.toISODate(),
        sage: Day,
      },
      {
        key: 'Value',
        ontraccr: getTimeText(selectedTask, {
          showAbbreviatedDuration: true,
          returnComponent: false,
        }),
        sage: Value,
      },
      {
        key: 'Job',
        ontraccr: projectMap[projectId],
        sage: sageJobMap[JobId],
      },
      {
        key: 'Costcode',
        ontraccr: costcodeMap[costcodeId],
        sage: sageCostcodeMap[CostcodeId],
      },
    ];
  }, [
    selectedTask,
    sageEmployeeMap,
    userMap,
    sageJobMap,
    projectMap,
    sageCostcodeMap,
    costcodeMap,
  ]);

  // Renders the sage column, so use a switch case for each type
  // Handles generating warning flags for different known issues
  const renderSage = useCallback((value, record) => {
    const name = value?.name ?? '-';
    switch (record.key) {
      case 'Employee':
        if (record.ontraccr && !value) {
          return warningFlag('', 'This entry is no longer linked');
        }
        return name;
      case 'Date':
        return value ?? '-';
      case 'Value':
        if (value === '0.00') {
          return warningFlag(value, 'Zero value entries will not be accepted by hh2');
        }
        return value ?? '-';
      case 'Job':
        if (record.ontraccr && !value) {
          let text = 'Selected project is not linked to a Sage Job';
          if (record.ontraccr.sageId) {
            text = 'This project is linked to a Sage Job but wasn\'t included. Validate the costcode is linked properly, as both must be linked and included';
          }
          return warningFlag('', text);
        }
        return name;
      case 'Costcode':
        if (record.ontraccr && !value) {
          let text = 'Selected costcode is not linked to Sage';
          if (record.ontraccr.sageId) {
            text = 'This costcode is linked to a Sage Costcode but wasn\'t included. Validate the job is linked properly, as both must be linked and included';
          }
          return warningFlag('', text);
        }
        return name;
      default:
        return value ?? '-';
    }
  }, [selectedTask]);

  const renderOntraccr = useCallback((value, record) => {
    const name = value?.name ?? '-';
    switch (record.key) {
      case 'Employee':
      case 'Job':
      case 'Costcode':
        return name;
      case 'Date':
      case 'Value':
      default:
        return value ?? '-';
    }
  }, []);

  const comparisonColumns = [
    {
      dataIndex: 'key',
      rowScope: 'row',
    }, {
      title: 'OnTraccr',
      dataIndex: 'ontraccr',
      key: 'ontraccr',
      render: renderOntraccr,
    }, {
      title: 'Sage',
      dataIndex: 'sage',
      key: 'sage',
      render: renderSage,
    },
  ];

  const statusColumns = [
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: renderStatus,
    }, {
      title: 'Timestamp',
      dataIndex: 'lastUpdated',
      key: 'lastUpdated',
      render: (lastUpdated) => (lastUpdated ? DateTime.fromMillis(lastUpdated).toFormat('yyyy LLL dd t') : '-'),
    }, {
      title: 'Message',
      dataIndex: 'message',
      key: 'message',
      render: (message) => (message ? (
        <Alert
          type="error"
          message={messageMap[message] ?? 'Unrecognized error - Please contact our support'}
        />
      ) : '-'),
    },
  ];

  return (
    <Card title="Task Details and Status">
      <Table
        title={comparisonTitle}
        loading={loading}
        dataSource={comparisonData}
        columns={comparisonColumns}
        pagination={false}
        bordered
      />
      <Table
        title={statusHistoryTitle}
        loading={loading}
        dataSource={selectedTask?.statuses}
        columns={statusColumns}
        pagination={false}
        bordered
        style={{
          paddingTop: 10,
        }}
      />
    </Card>
  );
}

SageTimeSyncTaskDetails.propTypes = {
  loading: PropTypes.bool,
  selectedTask: PropTypes.shape({
    costcodeId: PropTypes.string,
    projectId: PropTypes.string,
    userId: PropTypes.string,
  }),
};

SageTimeSyncTaskDetails.defaultProps = {
  loading: false,
  selectedTask: {},
};
