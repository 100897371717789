import {
  SET_ANALYTICS_CONFIG,
  GET_ANALYTICS_DATA,
  CREATE_ANALYTICS_REPORT,
  UPDATE_ANALYTICS_REPORT,
  SELECT_SAVED_REPORT,
  DELETE_ANALYTICS_REPORT,
  UPDATE_ANALYTICS_FILTERS,
} from '../../state/actionTypes';

import {
  setAnalyticsConfigActionMap,
  getAnalyticsDataActionMap,
  getSavedAnalyticsReportsActionMap,
  createAnalyticsReportActionMap,
  updateAnalyticsReportActionMap,
  deleteAnalyticsReportActionMap,
  selectSavedReportActionMap,
  updateAnalyticsFiltersActionMap,
  clearAnalyticsConfigActionMap,
} from '../analytics.constants';

import Analytics from '../../helpers/Analytics';
import AnalyticsService from './analytics.service';

export const setAnalyticsConfig = ({
  type: analyticsType,
  payload = {},
}) => ({
  type: analyticsType ? setAnalyticsConfigActionMap[analyticsType] : SET_ANALYTICS_CONFIG,
  payload,
});

export const getAnalyticsData = ({ type: analyticsType, payload }) => async (dispatch) => {
  Analytics.track(`Analytics/${analyticsType ?? 'Main'}/Get`);
  const { data } = await AnalyticsService.get(payload);
  if (!data) return false;
  dispatch({
    type: analyticsType ? getAnalyticsDataActionMap[analyticsType] : GET_ANALYTICS_DATA,
    payload: data,
  });
  return true;
};

export const getSavedAnalyticsReports = (analyticsType = 'main') => async (dispatch) => {
  const { data = [] } = await AnalyticsService.getSaved(analyticsType);
  if (!data) return false;
  dispatch({
    type: getSavedAnalyticsReportsActionMap[analyticsType],
    payload: {
      data,
    },
  });
  return true;
};

export const createAnalyticsReport = ({ type: analyticsType, payload }) => async (dispatch) => {
  Analytics.track(`Analytics/${analyticsType ?? 'Main'}/CreateReport`);
  const { data } = await AnalyticsService.create(payload);
  if (!data) return false;
  dispatch({
    type: analyticsType ? createAnalyticsReportActionMap[analyticsType] : CREATE_ANALYTICS_REPORT,
    payload: {
      data,
    },
  });
  return true;
};

export const updateAnalyticsReport = ({ type: analyticsType, id, payload }) => async (dispatch) => {
  Analytics.track(`Analytics/${analyticsType ?? 'Main'}/UpdateReport`);
  const { data } = await AnalyticsService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: analyticsType ? updateAnalyticsReportActionMap[analyticsType] : UPDATE_ANALYTICS_REPORT,
    payload: {
      id,
      newData: data,
    },
  });
  return true;
};

export const deleteAnalyticsReport = ({ type: analyticsType, id }) => async (dispatch) => {
  Analytics.track(`Analytics/${analyticsType ?? 'Main'}/DeleteReport`);
  const { data } = await AnalyticsService.delete(id);
  if (!data) return false;
  dispatch({
    type: analyticsType ? deleteAnalyticsReportActionMap[analyticsType] : DELETE_ANALYTICS_REPORT,
    payload: {
      id,
    },
  });
  return true;
};

export const selectSavedReport = ({ type: analyticsType, id }) => ({
  type: analyticsType ? selectSavedReportActionMap[analyticsType] : SELECT_SAVED_REPORT,
  payload: {
    id,
  },
});

export const updateAnalyticsFilters = ({
  type: analyticsType,
  payload = {},
}) => ({
  type: analyticsType ? updateAnalyticsFiltersActionMap[analyticsType] : UPDATE_ANALYTICS_FILTERS,
  payload,
});

export const clearAnalyticsConfig = ({ type: analyticsType }) => ({
  type: clearAnalyticsConfigActionMap[analyticsType],
});

export default {};
