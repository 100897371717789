import React from 'react';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
export const showDisconnectConfirm = ({
  type,
  flavourText,
  onConfirm
}) => {
  CustomConfirmModal({
    title: `Deactivate ${type} Integration`,
    content: <p>
      Are you sure you want to your deactivate {type} integration?
      <br/><br/>{flavourText}
    </p>,
    async onOk() {
      onConfirm();
    },
    okText: 'Deactivate',
  })
};

export const createImportData = ({
  integrationKey,
  newData = [],
  stateData = [],
  importList = new Set(),
  originalData = [],
  autoImport = false,
  canImport = () => true,
  connected = {},
  divisionList = [],
  isUser,
  isCustomerOrVendor,
}) => {
  let createUpdateList = [];
  const linkDeletedList = [];
  if (autoImport) {
    createUpdateList = newData.filter(canImport);
  } else {
    createUpdateList = stateData.filter((item) => (
      (canImport(item) && importList.has(item[integrationKey]) && item.viewType === 'import') || item.linkValue
    ));

    originalData.forEach((item) => {
      const { [integrationKey]: integrationId, id } = item;
      if (!integrationId) return;
      if (!(integrationId in connected) || !connected[integrationId]) {
        linkDeletedList.push(id);
        return;
      }
      const connectedUser = connected[integrationId];
      if (connectedUser.id !== id) {
        linkDeletedList.push(id);
      }
    });
  }

  // Need to decorate the data with division information
  // because divisions are not added to data that is not on the first page.
  // This is because the data is added by DivisionSelector when its rendered in the table
  const importData = {
    importList: createUpdateList.map((data) => {
      if (!isUser && !isCustomerOrVendor) {
        if (data.divisionId || divisionList.length !== 1) return data;
        return {
          ...data,
          divisionId: divisionList[0].id,
        };
      }
      const key = isUser ? 'divisions' : 'divisionIds';
      if ((!data[key] || data[key].length === 0) && divisionList.length === 1) {
        return {
          ...data,
          [key]: [divisionList[0].id],
        };
      }
      return data;
    }),
    linkDeletedList,
  };
  return importData;
};

export const linkInitialData = ({ items, connected = {}, integrationKey, getLinkValue = (obj = {}) => obj.id, divisionList = [] }) => (
  items.map((item) => {
    const newData = {
      ...item,
      viewType: 'import',
      linkValue: null,
    };
    const intId = item[integrationKey];
    if(intId in connected) {
      const { divisionId } = connected[intId];
      newData.viewType = 'link';
      newData.linkValue = getLinkValue(connected[intId]);
      newData.divisionId = divisionId;
    }
    return newData;
  })
);

export const autoImportHeader = (type, name) => `Ontraccr can automatically import ${name} from ${type}.`;
export const autoImportUncheckText = (type) => `If you would rather see exactly which data will be imported from ${type} and fill in any missing fields, disable Auto Import to do this manually.`;
export const importProjectHelpText = (t, type, name) => (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new {name} from {type} {' '}
    or <b>Link</b> {type} {name} to existing Ontraccr {t('Project', { count: 2 })}
    <br/><br/> <b>Import:</b> {name} must have an address to be imported into Ontraccr.
    <br/> <b>Link:</b> Select an existing Ontraccr {t('Project')} to link with the {type} {name}.
  </div>
);

export const importCostcodesHelpText = (t, type) => (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new Cost Codes from {type} {' '}
    or <b>Link</b> {type} Cost Codes to existing Ontraccr Cost Codes
    <br/><br/> <b>Import:</b> Cost Codes will be created as either Global or {t('Project')} Specific Codes
    <br/> <b>Link:</b> Select an existing Ontraccr Cost Codes to link with the {type} Cost Codes.
  </div>
);

