import React from "react";
import PropTypes from 'prop-types';
import { Form, TreeSelect } from "antd";
import DisplayText from "../text/DisplayText";

/** Form Tree Select Input */
const FormTreeSelectInput = ({
  isNotDisplay,
  label,
  name,
  value,
  formLabelStyle = {},
  formStyle = {},
  rules = [],
  treeSelectOptions = {},
}) => (
  <Form.Item
    name={name}
    label={label}
    style={formStyle}
    labelCol={formLabelStyle}
    rules={rules}
  >
    {isNotDisplay ?
      <TreeSelect {...treeSelectOptions} />
      : <DisplayText title={value}/>
    }
  </Form.Item>
);

FormTreeSelectInput.propTypes = {
  isNotDisplay: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  formLabelStyle: PropTypes.object,
  formStyle: PropTypes.objectOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.bool,
    PropTypes.number,
  ])),
  rules: PropTypes.arrayOf(PropTypes.shape({
    message: PropTypes.string,
  })),
  treeSelectOptions: PropTypes.object,
};

export default FormTreeSelectInput;
