import { decorateFile } from '../../forms/formHelpers';
import Analytics from '../../helpers/Analytics';
import {
  GET_SUBTASKS,
  CREATE_SUBTASK,
  DELETE_SUBTASK,
  UPDATE_SUBTASK,
  GET_SUBTASK_FILES,
  GET_SUBTASK_REMINDERS,
  GET_SUBTASK_TEMPLATES,
  CREATE_SUBTASK_TEMPLATE,
  UPDATE_SUBTASK_TEMPLATE,
  DELETE_SUBTASK_TEMPLATE,
  GET_SUBTASK_TEMPLATE_FORM_DATA,
  GET_SUBTASK_FORM_DATA,
  RESET_SUBTASK_FORM_DATA,
  CHANGE_SUBTASK_STATUS,
} from '../../state/actionTypes';
import SubtaskService from './subtasks.service';

export default {};

export const getSubTasks = (query) => async (dispatch) => {
  const { data } = await SubtaskService.get(query);
  if (!data) return false;
  dispatch({
    type: GET_SUBTASKS,
    payload: {
      subtasks: data,
    },
  });
  return true;
};

export const createSubtask = (payload) => async (dispatch) => {
  Analytics.track('Subtask/Create');
  const { data } = await SubtaskService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_SUBTASK,
    payload: {
      newSubtasks: data,
    },
  });
  return true;
};

export const updateSubtask = (id, payload) => async (dispatch) => {
  Analytics.track('Subtask/Update');
  const { data } = await SubtaskService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SUBTASK,
    payload: {
      id,
      newData: data,
    },
  });
  return true;
};

export const deleteSubtask = (subtask, isGroup) => async (dispatch) => {
  const id = isGroup ? subtask.groupId : subtask.id;
  const { data } = await SubtaskService.delete(id, isGroup);
  if (!data) return false;
  dispatch({
    type: DELETE_SUBTASK,
    payload: {
      subtaskIds: data,
    },
  });
  return true;
};

export const getSubtaskFiles = (id) => async (dispatch) => {
  const { data } = await SubtaskService.getFiles(id);
  if (!data) return false;
  await Promise.all(data.map(async (file) => decorateFile(file, file.id)));
  dispatch({
    type: GET_SUBTASK_FILES,
    payload: {
      id,
      files: data,
    },
  });
  return true;
};

export const getSubtaskReminders = (id) => async (dispatch) => {
  const { data } = await SubtaskService.getReminders(id);
  if (!data) return false;
  dispatch({
    type: GET_SUBTASK_REMINDERS,
    payload: {
      id,
      ...data,
    },
  });
  return true;
};

export const getSubTaskTemplates = () => async (dispatch) => {
  const { data } = await SubtaskService.getTemplates();
  if (!data) return false;
  dispatch({
    type: GET_SUBTASK_TEMPLATES,
    payload: {
      templates: data,
    },
  });
  return true;
};

export const createSubtaskTemplate = (payload) => async (dispatch) => {
  Analytics.track('Subtask/CreateTemplate');
  const { data } = await SubtaskService.createTemplate(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_SUBTASK_TEMPLATE,
    payload: {
      template: data,
    },
  });
  return true;
};

export const updateSubtaskTemplate = (id, payload) => async (dispatch) => {
  Analytics.track('Subtask/UpdateTemplate');
  const { data } = await SubtaskService.updateTemplate(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SUBTASK_TEMPLATE,
    payload: {
      template: data,
    },
  });
  return true;
};

export const deleteSubtaskTemplate = (id) => async (dispatch) => {
  Analytics.track('Subtask/DeleteTemplate');
  const didDelete = await SubtaskService.deleteTemplate(id);
  if (!didDelete) return false;
  dispatch({
    type: DELETE_SUBTASK_TEMPLATE,
    payload: {
      deletedId: id,
    },
  });
  return true;
};

export const getSubtaskFormData = (id) => async (dispatch) => {
  const { data } = await SubtaskService.getFormData(id);
  if (!data) return false;
  dispatch({
    type: GET_SUBTASK_FORM_DATA,
    payload: {
      formTemplate: data,
    },
  });
  return true;
};

export const getSubtaskTemplateFormData = (id) => async (dispatch) => {
  const { data } = await SubtaskService.getTemplateFormData(id);
  if (!data) return false;
  dispatch({
    type: GET_SUBTASK_TEMPLATE_FORM_DATA,
    payload: {
      formTemplate: data,
    },
  });
  return true;
};

export const resetSubtaskFormData = () => ({
  type: RESET_SUBTASK_FORM_DATA,
});

export const changeSubtaskStatus = (id, status) => async (dispatch) => {
  Analytics.track('Subtask/ChangeStatus');
  const { data } = await SubtaskService.changeStatus(id, status);
  if (!data) return false;
  dispatch({
    type: CHANGE_SUBTASK_STATUS,
    payload: {
      id,
      status,
    },
  });
  return true;
};
