import React from 'react';

import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import WarningHeader from '../../common/text/WarningHeader';

export default (onOk) => (
  CustomConfirmModal({
    title: (
      <WarningHeader
        justify='center'
        title='Are you sure you want to delete this custom table?'
      />
    ),
    content:(
      <div>
        Warning: Deleting this custom table will affect any form using this table.
        <br/>
        <br/>
        Please review your current forms to make sure none of them are using this table.
      </div>
    ),
    onOk,
  })
)
