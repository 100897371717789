import React, {
  useCallback,
  useMemo,
  useState,
  useContext,
} from 'react';
import { Row, Col, Checkbox } from 'antd';
import { FormOutlined, SettingOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import HoverHelp from '../../common/HoverHelp';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import WorkflowTriggerConfigureDrawer from './WorkflowTriggerConfigureDrawer';
import UpdateAddTimestampCheckbox from './UpdateAddTimestampCheckbox';

import {
  locateLogicalLoopParentElement,
  updateData,
} from './workflowHelpers';
import { createSourceToTargetMap } from '../formHelpers';
import FormWorkflowContext from './FormWorkflowContext';
import useGetFormDropdownField from '../../common/hooks/useGetFormDropdownField';
import { isFieldValid, validFieldTypes } from './update.constants';

const DISABLED_TEXT = 'Add a Form Submissions Dropdown field to enable this step';
const HOVER_TEXT = `
This step requires one Form Submissions Dropdown field. Use this step to update another form.
`;

export default function WorkflowFormUpdateNode({
  setDataMap,
  setElements,
  isDisplay,
  divisionId,
  templateId,
  name,
  sections = [],
} = {}) {
  return function WorkflowFormUpdateNodeComponent({
    id,
    disabled,
    draggable,
    data = {},
  }) {
    const {
      elements = [],
      dataMap,
    } = useContext(FormWorkflowContext) ?? {};

    const parentLogicalLoopNode = useMemo(() => {
      const logicalLoopNode = locateLogicalLoopParentElement(id, elements);

      if (!logicalLoopNode) return logicalLoopNode;

      return {
        ...logicalLoopNode,
        data: dataMap[logicalLoopNode.id] ?? logicalLoopNode.data,
      };
    }, [id, elements, dataMap]);

    const {
      fieldMappings: initialFieldMappings = {},
      shouldOverrideForm: initialShouldOverrideForm = true,
      addTimestamps,
    } = data;

    const { relevantCompletedField: relevantField } = useGetFormDropdownField({ sections });

    const [showDrawer, setShowDrawer] = useState(false);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [override, setOverride] = useState(initialShouldOverrideForm);

    const openDrawer = useCallback(() => setShowDrawer(true), []);
    const closeDrawer = useCallback(() => setShowDrawer(false), []);

    const onFieldMappingsChange = useCallback((newMappings, formId) => {
      if (!setDataMap || !id) return;
      const updatedFieldMappings = {
        ...fieldMappings,
        version: 'v2',
        [formId]: createSourceToTargetMap(newMappings),
      };

      setFieldMappings(updatedFieldMappings);
      setDataMap(
        updateData(id, { fieldMappings: updatedFieldMappings }),
      );
    }, [setDataMap, id, fieldMappings]);

    const onCheckChanged = useCallback((e) => {
      const {
        target: {
          checked,
        } = {},
      } = e;
      setDataMap(updateData(id, { shouldOverrideForm: checked }));
      setOverride(checked);
    }, [setDataMap, id]);

    const onTimestampChanged = useCallback((newValue) => {
      setDataMap(updateData(id, { addTimestamps: newValue }));
    }, [setDataMap, id]);

    const title = `Form Update${disabled ? ' - DISABLED' : ''}`;

    return (
      <WorkflowActionNode
        title={title}
        Icon={FormOutlined}
        type="formUpdate"
        id={id}
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : { }}
      >
        {!draggable && (
          <div>
            <Row style={{ width: '100%', margin: '5px 0px' }}>
              <Col>
                <Checkbox
                  onChange={onCheckChanged}
                  defaultChecked={initialShouldOverrideForm}
                />
              </Col>
              <Col style={{ padding: '0px 5px' }}>
                Override Form
              </Col>
              <Col>
                <HoverHelp
                  content={(
                    <div style={{ width: 300 }}>
                      If turned on, the form data will be overrided with the new data.
                      <br />
                      <br />
                      If turned off, the form data will be appended to the existing data.
                    </div>
                  )}
                />
              </Col>
            </Row>
            {!override && (
              <UpdateAddTimestampCheckbox
                onChange={onTimestampChanged}
                initialChecked={addTimestamps}
              />
            )}
            <BorderlessButton
              title="Configure"
              style={{ margin: 5 }}
              iconNode={<SettingOutlined />}
              onClick={openDrawer}
            />
            <WorkflowTriggerConfigureDrawer
              visible={showDrawer}
              onClose={closeDrawer}
              onSubmit={closeDrawer}
              targetFormId={relevantField?.configProps?.subDataType}
              sourceName={name}
              sourceSections={sections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onFieldMappingsChange}
              divisionId={divisionId}
              templateId={templateId}
              parentLogicalLoopNode={parentLogicalLoopNode}
              validFieldTypes={validFieldTypes}
              isFieldValid={isFieldValid}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
}
