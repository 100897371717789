import { PDFDocument, StandardFonts } from 'pdf-lib';
import { downloadFile } from '../files/fileHelpers';
import logoPNG from '../assets/images/Ontraccr-Logo-B-1400x400.png';

export const renderPdfWorkTimes = (times, employeeName, employeeID, calculateWeeklyTotal) => {
  let dailyWorkTimes = '';
  let employeeInfoStr = '';
  const weeklyHoursValue = calculateWeeklyTotal(times);
  const weeklyHoursString = `Total hours this week: ${weeklyHoursValue}`;

  // render work times
  times.forEach((entry) => {
    if (typeof entry.result === 'string') {
      const str = `${entry.day}: ${entry.result} \n`;
      dailyWorkTimes += str;
    }
  });
  if (employeeName && employeeID) {
    employeeInfoStr = `Name: ${employeeName}       Employee ID: ${employeeID}. `;
  } else {
    employeeInfoStr = 'Please fill out both employee name and employee ID';
  }

  return `${employeeInfoStr} \n${dailyWorkTimes} \n${weeklyHoursString}`;
};

export const createPdf = async (times, employeeName, employeeID, calculateWeeklyTotal) => {
  // Create a new PDFDocument
  const pdfDoc = await PDFDocument.create();

  // Embed the Times Roman font
  const timesRomanFont = await pdfDoc.embedFont(StandardFonts.TimesRoman);

  // Add a blank page to the document
  const page = pdfDoc.addPage();

  // Get the width and height of the page
  const { height } = page.getSize();

  // Draw a string of text toward the top of the page
  const fontSize = 14;
  page.drawText(`${renderPdfWorkTimes(times, employeeName, employeeID, calculateWeeklyTotal)}`, {
    x: 50,
    y: height - 20 * fontSize,
    size: fontSize,
    font: timesRomanFont,
  });

  // render copyright string
  const endStr = 'Generated using  Ontraccr Sheets\n';
  page.drawText(`${endStr}`, {
    x: 50,
    y: 150,
    size: fontSize,
    font: timesRomanFont,
  });
  const pngImageBytes = await fetch(logoPNG).then((res) => res.arrayBuffer());
  const OnTraccrImg = await pdfDoc.embedPng(pngImageBytes);
  const pngDims = OnTraccrImg.scale(0.1);

  // render logo
  page.drawImage(OnTraccrImg, {
    x: 50,
    y: 100,
    width: pngDims.width,
    height: pngDims.height,
    opacity: 0.75,
  });

  // convert binary to pdf file
  const pdfBytes = await pdfDoc.save();
  const pdfBlob = new Blob([pdfBytes], { type: 'application/pdf' });
  const myFile = new File([pdfBlob], 'myFile.pdf', { type: 'application/pdf', lastModified: new Date() });
  return downloadFile({ fileObject: myFile });
};
