import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Select } from 'antd';
import { PropTypes } from 'prop-types';
import CustomModal from '../../../../common/modals/CustomModal';
import OnTraccrButton from '../../../../common/buttons/OnTraccrButton';
import Permissions from '../../../../auth/Permissions';
import Colors from '../../../../constants/Colors';

export default function TimeEntryTableTeamSelection({
  visible,
  onAdd,
  onClose,
  divisions,
}) {
  const ourId = Permissions.id;
  const userTeams = useSelector((state) => state.users.userTeams);
  const teams = useSelector((state) => state.teams.teams);

  const [selectedTeam, setSelectedTeam] = useState();

  const onAddClick = useCallback(() => {
    onAdd(selectedTeam);
  });

  const teamOptions = useMemo(() => {
    const divSet = new Set(divisions);
    const ourTeamIds = new Set(userTeams[ourId]);
    if (!ourTeamIds?.size) return [];
    const filteredTeams = teams.filter(({ active, id, divisionIds = [] }) => (
      active
        && ourTeamIds.has(id)
        && divisionIds?.some((dId) => divSet.has(dId))
    ));
    return filteredTeams.map(({ name, id }) => ({ label: name, value: id }));
  }, [userTeams, teams, divisions]);

  return (
    <CustomModal
      title="Select Team"
      visible={visible}
      hideCloseButton
      width={400}
      maskColor={Colors.MODAL_MASK_DARK}
    >
      <Row justify="center" style={{ margin: '20px 5px' }}>
        <Select
          style={{ minWidth: 250, maxWidth: 300 }}
          options={teamOptions}
          value={selectedTeam}
          onChange={setSelectedTeam}
        />
      </Row>
      <Row justify="center" gutter={12} style={{ marginBottom: 10 }}>
        <Col>
          <OnTraccrButton
            type="cancel"
            title="Cancel"
            roundStyle
            onClick={onClose}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title="Add"
            roundStyle
            onClick={onAddClick}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}

TimeEntryTableTeamSelection.propTypes = {
  visible: PropTypes.bool,
  onAdd: PropTypes.func,
  onClose: PropTypes.func,
  divisions: PropTypes.arrayOf(PropTypes.string),
};

TimeEntryTableTeamSelection.defaultProps = {
  visible: false,
  onAdd: () => {},
  onClose: () => {},
  divisions: [],
};
