import { DateTime } from 'luxon';
import * as Sentry from '@sentry/react';
import { TaskHelpers, Payroll } from 'ontraccr-common';

import { msToHours } from './time';
import calculateOT from './overtime/calculateOT';

import { defaultPayrollPeriods } from '../settings/TimeTrackingSettings/timeTrackingConstants';
import { isNullOrUndefined } from './helpers';
import { sortByStartTime } from './tasks';
import { decorateTasks } from '../timecards/timecard.helpers';

export default {};

const VALID_PAY_PERIODS = new Set(defaultPayrollPeriods);

/**
  Given a payroll start day, finds either the next or previous payroll start date
  * @param {DateTime} firstDay // Payroll start date
  * @param {string} payPeriod // One of VALID_PAY_PERIODS. How long the pay period is
  * @param {boolean} forward // True for next period, false for previous period
  * @param {int[]} semiMonthlyPayPeriodDates // Pay period days
  * @returns {DateTime} // Next or previous payroll start date
* */
export const getNextPayroll = ({
  firstDay,
  payPeriod = 'Weekly',
  forward = true,
  semiMonthlyPayPeriodDates,
} = {}) => {
  if (!firstDay || !DateTime.isDateTime(firstDay)) return DateTime.local();
  const newDate = firstDay.startOf('day');
  const isSemiMonthly = payPeriod === 'Semi-Monthly';
  const calendarMoveCount = (['Monthly', 'Weekly']).includes(payPeriod) ? 1 : 2;
  const calendarMoveRange = payPeriod === 'Monthly' ? 'month' : 'week';

  if (isSemiMonthly) {
    const current = Payroll.findSemiMonthlyPayPeriod({
      startDay: newDate,
      semiMonthlyPayPeriodDates,
    });
    return Payroll.findSemiMonthlyPayPeriod({
      startDay: forward ? current[1].plus({ day: 1 }) : current[0].minus({ day: 1 }),
      semiMonthlyPayPeriodDates,
    })[0];
  }
  const moveObj = { [calendarMoveRange]: calendarMoveCount };
  return forward
    ? newDate.plus(moveObj)
    : newDate.minus(moveObj);
};

/**
  Finds the start and end dates of a payroll period from a given date.
  * @param {DateTime} startDay // The date whose payroll start/end we want to find.
  * @param {string} payPeriod // One of VALID_PAY_PERIODS. How long the pay period is
  * @param {DateTime} payPeriodFirstDay // Reference day for the first pay period for the company
  * @param {int[]} semiMonthlyPayPeriodDates // Pay period days
  * @returns {DateTime[]} // The start and end dates for the pay period.
* */
export const findPayrollStartAndEndDates = ({
  startDay = DateTime.local(),
  payPeriodFirstDay,
  payPeriod,
  semiMonthlyPayPeriodDates = [1, 16],
}) => {
  if (payPeriod === 'Semi-Monthly') {
    return Payroll.findSemiMonthlyPayPeriod({
      startDay,
      semiMonthlyPayPeriodDates,
    });
  }
  if (!payPeriodFirstDay) return [startDay.minus({ days: 7 }), startDay];
  let firstDay = payPeriodFirstDay;
  const past = startDay < firstDay;

  let upperBound = getNextPayroll({ payPeriod, firstDay }).minus({ day: 1 }).endOf('day');

  let counter = 0;
  while (startDay > upperBound || startDay < firstDay) {
    if (past) {
      upperBound = firstDay.minus({ day: 1 }).endOf('day');
      firstDay = getNextPayroll({ payPeriod, firstDay, forward: false }).startOf('day');
    } else {
      firstDay = upperBound.plus({ day: 1 }).startOf('day');
      upperBound = getNextPayroll({ payPeriod, firstDay, forward: true }).minus({ day: 1 }).endOf('day');
    }
    counter += 1;
    if (counter > 2500) break; // Fail safe
  }
  return [firstDay.startOf('day'), getNextPayroll({ payPeriod, firstDay, forward: true }).minus({ day: 1 }).endOf('day')];
};

/**
  Gets the first payroll period for the current payroll
  * @param {string} payPeriod // One of VALID_PAY_PERIODS. How long the pay period is
  * @param {string} payPeriodDates // Reference date for payroll close. Format yyyy-MM-dd
  * @returns {DateTime} // Current payroll start date
* */
export const getFirstPayrollDay = ({
  payPeriod,
  payPeriodDates,
  semiMonthlyPayPeriodDates = [1, 16],
} = {}) => {
  if (!payPeriod || !VALID_PAY_PERIODS.has(payPeriod)) return DateTime.local();
  const isSemiMonthly = payPeriod === 'Semi-Monthly';
  if (isSemiMonthly) {
    return Payroll.findSemiMonthlyPayPeriod({
      semiMonthlyPayPeriodDates,
    })[0];
  }
  if (!payPeriodDates) return DateTime.local();
  const firstDay = DateTime.fromFormat(payPeriodDates, 'yyyy-MM-dd')
    .startOf('day')
    .plus({ day: 1 }); // payPeriodDates is closing payroll date

  if (!firstDay.isValid) return DateTime.local();

  return findPayrollStartAndEndDates({
    payPeriodFirstDay: firstDay,
    payPeriod,
  })[0];
};

/**
 Finds daily time boundary (min/max) in ms
 *
 * @param {number} timestamp // epoch
 * @param {string} timezone //IANA zone
 */
const getDayTimeBoundary = (timestamp, { timezone } = {}) => {
  const dt = DateTime.fromMillis(timestamp, { zone: timezone });
  return {
    ms: dt.millisecond
      + (dt.second * 1000)
      + (dt.minute * 1000 * 60)
      + (dt.hour * 1000 * 60 * 60),
    dt,
  };
};

const getBoundaryValueBasedOnTimes = (
  currentMin,
  currentMax,
  startTime,
  endTime,
  timezone,
) => {
  let min = currentMin;
  let max = currentMax;

  const minBoundary = startTime ? getDayTimeBoundary(startTime, { timezone }) : null;
  const maxBoundary = endTime ? getDayTimeBoundary(endTime, { timezone }) : null;

  if (minBoundary && (!currentMin || minBoundary.ms < currentMin.ms)) {
    min = minBoundary;
  }

  if (maxBoundary && (!currentMax || maxBoundary.ms > currentMax.ms)) {
    max = maxBoundary;
  }

  return {
    min,
    max,
  };
};

const getNewBoundaryValues = (
  currentDay,
  task,
  showOriginalTimes,
) => {
  let regularMin;
  let regularMax;

  const realStart = showOriginalTimes && task.originalStart
    ? task.originalStart
    : task.startTime;
  const realEnd = showOriginalTimes && task.originalEnd
    ? task.originalEnd
    : task.endTime;

  const { min, max } = getBoundaryValueBasedOnTimes(
    currentDay.minTS,
    currentDay.maxTS,
    TaskHelpers.getStartTime({
      ...task,
      startTime: realStart,
    }),
    TaskHelpers.getEndTime({
      ...task,
      endTime: realEnd,
    }),
    task.timezone,
  );

  if (task.type !== 'overtime' && task.type !== 'break') {
    const { min: regMin, max: regMax } = getBoundaryValueBasedOnTimes(
      currentDay.minRegularTS,
      currentDay.maxRegularTS,
      realStart,
      realEnd,
      task.timezone,
    );
    regularMin = regMin;
    regularMax = regMax;
  }

  const {
    min: breakMin,
    max: breakMax,
  } = getBoundaryValueBasedOnTimes(
    currentDay.minBreakTS,
    currentDay.maxBreakTS,
    task.type === 'break' ? task.startTime : task.breakStartTime,
    task.type === 'break' ? task.endTime : task.breakEndTime,
    task.timezone,
  );

  const {
    min: overtimeMin,
    max: overtimeMax,
  } = getBoundaryValueBasedOnTimes(
    currentDay.minOvertimeTS,
    currentDay.maxOvertimeTS,
    task.type === 'overtime' ? task.startTime : task.otStartTime,
    task.type === 'overtime' ? task.endTime : task.otEndTime,
    task.timezone,
  );

  const {
    min: doubleOTMin,
    max: doubleOTMax,
  } = getBoundaryValueBasedOnTimes(
    currentDay.minDoubleOvertimeTS,
    currentDay.maxDoubleOvertimeTS,
    task.doubleOTStartTime,
    task.doubleOTEndTime,
    task.timezone,
  );

  return {
    min,
    max,
    regularMin,
    regularMax,
    breakMin,
    breakMax,
    overtimeMin,
    overtimeMax,
    doubleOTMin,
    doubleOTMax,
  };
};

const getDay = (dt, additionalProperties = {}) => ({
  dayTasks: [],
  dayHistory: [],
  total: 0,
  minTS: null,
  maxTS: null,
  minRegularTS: null,
  maxRegularTS: null,
  minOvertimeTS: null,
  maxOvertimeTS: null,
  minBreakTS: null,
  maxBreakTS: null,
  minDoubleOvertimeTS: null,
  maxDoubleOvertimeTS: null,
  weekday: dt.weekdayLong,
  month: dt.monthLong,
  dayOfMonth: dt.day,
  year: dt.year,
  date: dt.toSQLDate(),
  ...additionalProperties,
});

/**
  Parses tasks into day based time cards
  * @param {DateTime[]} timeRange // Start and End date to filter tasks on
  * @param {DateTime} firstDayOfRunningPayPeriod // First day of the current payroll period
  * @param {Object[]} tasks // List of tasks to parse
  * @param {Object[]} taskHistory // List of task history to parse
  * @param {boolean} isIndividual // If the timecards are for the current user or a different user
  * @param {boolean} isApprovals // Whether we are processing approvals only or all tasks
  * @param {boolean} showOriginalTimes // Show original or modified times
  * @param {boolean} useEndDateOvernight // Start or end time for date calculation
  * @returns {Object} // Timecard days and filtered tasks
* */
export const parseTimecards = ({
  timeRange: [
    startDay = DateTime.local().minus({ days: 7 }),
    endDay = DateTime.local(),
  ] = [],
  firstDayOfRunningPayPeriod,
  tasks = [],
  taskHistory = [],
  isIndividual,
  isApprovals,
  showOriginalTimes,
  useEndDateOvernight,
}) => {
  const days = {};
  const payrollTasks = [];
  if (!startDay || !endDay) return days;
  const firstDayTs = firstDayOfRunningPayPeriod ? firstDayOfRunningPayPeriod.toMillis() : 0;
  const numDays = Math.ceil(endDay.endOf('day').diff(startDay.startOf('day'), 'days').as('days'));

  if (!isApprovals) {
    for (let i = 0; i < numDays; i += 1) {
      const dt = startDay.plus({ days: i });
      days[dt.toLocaleString(DateTime.DATE_MED)] = getDay(dt, { submittable: false });
    }
  }

  tasks.forEach((t) => {
    const dt = TaskHelpers.getTaskDate(t);
    const localDT = DateTime.fromISO(dt?.toSQLDate());

    const startTime = TaskHelpers.getStartTime(t);
    const endTime = TaskHelpers.getEndTime(t);
    if (
      !startTime
      || (!isApprovals && (localDT < startDay || localDT > endDay))
    ) return;
    payrollTasks.push(t);
    const dateKey = dt.toLocaleString(DateTime.DATE_MED);

    if (!(dateKey in days)) {
      days[dateKey] = getDay(dt);
    }

    if (isIndividual
        && !t.state
        && startTime >= firstDayTs
        && days[dateKey]
        && endTime > 0
        && !t.isPending
    ) {
      days[dateKey].submittable = true;
    }

    // this determines whether the tasks get displayed with rounded time
    const runtimes = TaskHelpers.getRuntimes(t, false);
    const runtime = runtimes.regularTime + runtimes.overtime + runtimes.doubleOT;
    days[dateKey].dayTasks.push(t);
    days[dateKey].total += t.type !== 'break' ? runtime : 0;
    if (!t.hourBased && !t.isPending) {
      const {
        min,
        max,
        regularMin,
        regularMax,
        breakMin,
        breakMax,
        overtimeMin,
        overtimeMax,
        doubleOTMin,
        doubleOTMax,
      } = getNewBoundaryValues(
        days[dateKey],
        t,
        showOriginalTimes,
      );

      days[dateKey].minTS = min;
      days[dateKey].maxTS = max;
      days[dateKey].minRegularTS = regularMin;
      days[dateKey].maxRegularTS = regularMax;
      days[dateKey].minBreakTS = breakMin;
      days[dateKey].maxBreakTS = breakMax;
      days[dateKey].minOvertimeTS = overtimeMin;
      days[dateKey].maxOvertimeTS = overtimeMax;
      days[dateKey].minDoubleOvertimeTS = doubleOTMin;
      days[dateKey].maxDoubleOvertimeTS = doubleOTMax;
    }
  });

  taskHistory.forEach((t) => {
    const ts = t.endTime && useEndDateOvernight ? t.endTime : t.startTime;
    if (ts < startDay || ts > endDay) return;
    const dt = DateTime.fromMillis(ts);
    const dateKey = dt.toLocaleString(DateTime.DATE_MED);

    if (!(dateKey in days) && !isApprovals) {
      days[dateKey] = getDay(dt);
    }

    days[dateKey]?.dayHistory.push(t);
  });

  return { days, payrollTasks };
};

// Debounce Sentry messages
let lastSentryMessage = DateTime.fromMillis(0);

/**
  Calculates payroll hours based off tasks, company overtime settings and user wage
  * @param {Object[]} tasks // List of tasks to parse
  * @param {Object} settings // Company settings object
  * @param {Object} user // The user whose payroll is being calculater
  * @param {Number} user.wage // The users wage.
  * @param {Object} costcodeMap // Map of costcodeId to costcode object
  * @param {Object} classMap // Map of union classId to class object
  * @param {Array} range // Start and End date to calculate hours for
  * @returns {Object} // Payroll hours and totals
* */
export const calculatePayrollHours = ({
  tasks = [],
  settings = {},
  user: {
    wage = 0,
  } = {},
  costcodeMap = {},
  classMap = {},
  range,
}) => {
  const {
    saturdayPay = 1,
    sundayPay = 1,
    enableManualOT,
    manualOTModifier = 1.5,
  } = settings;
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  const otModifier = enableManualOT ? manualOTModifier : 1.5;
  const dailyWageDays = new Set();
  const {
    regularHours = 0,
    otHours = 0,
    doubleOtHours = 0,
    saturdayHours = 0,
    saturdayOTHours = 0,
    saturdayDoubleOTHours = 0,
    sundayHours = 0,
    sundayOTHours = 0,
    sundayDoubleOTHours = 0,
    pay = 0,
  } = calculateOT({ tasks, settings, range }).reduce((acc, task) => {
    if (task.type === 'break' || !TaskHelpers.getEndTime(task)) return acc;
    let relevantWage = wage;
    let dailyWage = 0;
    const costcode = costcodeMap[task.costcodeId];
    const unionClass = classMap[task?.classId];

    // Aggregate key to check if a user has already been paid for a day (for cost code)
    // This is to prevent double payment for daily wage
    const aggKey = `${task.userId}-${task.costcodeId}-${task.date}`;

    if (!isNullOrUndefined(unionClass?.wage)) {
      relevantWage = unionClass.wage;
    } else if (!isNullOrUndefined(costcode?.dailyWage)) {
      relevantWage = 0;
      if (!dailyWageDays.has(aggKey)) {
        dailyWage = costcode.dailyWage;
        dailyWageDays.add(aggKey);
      }
    } else if (!isNullOrUndefined(costcode?.hourlyWage)) {
      relevantWage = costcode.hourlyWage;
    } else if (!isNullOrUndefined(costcode?.wageMultiplier)) {
      relevantWage = wage * costcode.wageMultiplier;
    } else if (!isNullOrUndefined(costcode?.wageAdjustment)) {
      relevantWage = wage + costcode.wageAdjustment;
    }

    const parsedRegularHours = msToHours(task.regularTime);
    const parsedOTHours = msToHours(task.overtime);
    const parsedDoubleOtHours = msToHours(task.doubleOT);
    const parsedSaturdayHours = msToHours(task.saturdayTime ?? 0);
    const parsedSaturdayOTHours = msToHours(task.saturdayOT ?? 0);
    const parsedSaturdayDoubleOTHours = msToHours(task.saturdayDoubleOT ?? 0);
    const parsedSundayHours = msToHours(task.sundayTime ?? 0);
    const parsedSundayOTHours = msToHours(task.sundayOT ?? 0);
    const parsedSundayDoubleOTHours = msToHours(task.sundayDoubleOT ?? 0);

    acc.regularHours += parsedRegularHours;
    acc.otHours += parsedOTHours;
    acc.doubleOtHours += parsedDoubleOtHours;
    acc.saturdayHours += parsedSaturdayHours;
    acc.saturdayOTHours += parsedSaturdayOTHours;
    acc.saturdayDoubleOTHours += parsedSaturdayDoubleOTHours;
    acc.sundayHours += parsedSundayHours;
    acc.sundayOTHours += parsedSundayOTHours;
    acc.sundayDoubleOTHours += parsedSundayDoubleOTHours;

    acc.pay += (
      parsedRegularHours * relevantWage
      + parsedOTHours * relevantWage * otModifier
      + parsedDoubleOtHours * relevantWage * 2
      + (
        parsedSaturdayHours + parsedSaturdayOTHours * 1.5 + parsedSaturdayDoubleOTHours * 2
      ) * (saturdayPay) * relevantWage
      + (
        parsedSundayHours + parsedSundayOTHours * 1.5 + parsedSundayDoubleOTHours * 2
      ) * (sundayPay) * relevantWage
    ) + dailyWage;

    return acc;
  }, {
    regularHours: 0,
    otHours: 0,
    doubleOtHours: 0,
    saturdayHours: 0,
    saturdayOTHours: 0,
    saturdayDoubleOTHours: 0,
    sundayHours: 0,
    sundayOTHours: 0,
    sundayDoubleOTHours: 0,
    pay: 0,
  });

  let totalPay = formatter.format(pay);
  if (Number.isNaN(pay)) {
    totalPay = formatter.format(0);
    const now = DateTime.local();
    if (now > lastSentryMessage.plus({ minutes: 30 })) {
      lastSentryMessage = now;
      Sentry.withScope((scope) => {
        scope.setExtra('settings', {
          wage,
          regularHours,
          otHours,
          doubleOtHours,
          saturdayHours,
          saturdayOTHours,
          saturdayDoubleOTHours,
          saturdayPay,
          sundayHours,
          sundayOTHours,
          sundayDoubleOTHours,
          sundayPay,
        });
        Sentry.captureException(new Error('Timecard calculation failed'));
      });
    }
  }
  return {
    regularHours,
    otHours,
    doubleOtHours,
    saturdayHours,
    saturdayOTHours,
    saturdayDoubleOTHours,
    sundayHours,
    sundayOTHours,
    sundayDoubleOTHours,
    totalPay,
  };
};

/**
 * Finds the todays tasks and decorates them for ManualEntryDrawer
 * @param {string} payPeriod // e.g. Weekly, Monthly, etc
 * @param {Array} semiMonthlyPayPeriodDates // Pay days for Semi-Month pay period
 * @param {DateTime} firstPayrollDay // Reference date for the first day of payroll
 * @param {Array} timeRange // Start and end date to use [DateTime, DateTime]
 * @param {object} user // User object
 * @param {object} projectIdMap // { [projectId]: project }
 * @param {object} costcodeIdMap // { [costcodeId]: costcode }
 * @param {boolean} isIndividual // Whether the tasks are for the currently logged in user
 * @param {boolean} canEdit // Whether the currently logged in user can edit these tasks
 * @returns {Array} // Decorated tasks
 */
export const getTodaysManualEntries = ({
  payPeriod,
  semiMonthlyPayPeriodDates,
  firstPayrollDay,
  timeRange,
  user = {},
  timeEntryUserMap = {},
  projectIdMap = {},
  costcodeIdMap = {},
  isIndividual,
  canEdit,
}) => {
  const tasks = timeEntryUserMap[user?.id] ?? [];
  if (!payPeriod) return [];
  const [firstDayOfRunningPayPeriod] = findPayrollStartAndEndDates({
    payPeriodFirstDay: firstPayrollDay,
    payPeriod,
    semiMonthlyPayPeriodDates,
  });

  const { days = {} } = parseTimecards({
    timeRange,
    firstDayOfRunningPayPeriod,
    tasks: tasks.filter(TaskHelpers.taskIsToday),
  });
  const now = DateTime.local().toLocaleString(DateTime.DATE_MED);
  const {
    [now]: {
      dayTasks = [],
    } = {},
  } = days;
  dayTasks.sort(sortByStartTime);
  return decorateTasks({
    tasks: dayTasks,
    checkGeofence: false,
    projectIdMap,
    costcodeIdMap,
    isIndividual,
    user,
    canEdit,
  });
};
