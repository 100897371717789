import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import * as XLSX from 'xlsx';
import { UploadOutlined, CloudDownloadOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Tabs, Upload, Form } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DivisionSelector from '../common/inputs/DivisionSelector';

import CostCodeUploadSteps from './costcodeUploadSteps';
import CostCodeProjectsAddGlobal from './CostCodeProjectsAddGlobal';
import CostcodeCategorySelector from './CostcodeCategorySelector';

import { sortByNumber, getIdMap, doesNumberExist } from '../helpers/helpers';
import { checkFileIsSpreadsheet } from '../files/fileHelpers';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import CustomFields from '../common/customFields/CustomFields';
import { getCostcodeCustomFieldTemplate } from './state/costcodes.actions';

const { TabPane } = Tabs;

const SMALL_TAB_WIDTH = 400;
const MEDIUM_TAB_WIDTH = 600;
const LARGE_TAB_WIDTH = 1000;

const costcodeTemplateURL = 'https://ontraccr-public.s3-us-west-2.amazonaws.com/OnTraccr%2BCost%2BCodes.xlsx';

const getWidthForTab = (tab) => {
  switch (tab) {
    case '1':
      return SMALL_TAB_WIDTH;
    case '0':
    case '2':
      return MEDIUM_TAB_WIDTH;
    default:
      return SMALL_TAB_WIDTH;
  }
};

const parseWorkbook = function parseWorkbook(file, callback) {
  const reader = new FileReader();
  reader.onload = (e) => {
    const data = new Uint8Array(e.target.result);
    const workbook = XLSX.read(data, { type: 'array' });
    callback(workbook);
  };
  reader.readAsArrayBuffer(file);
};

export default function CostCodeAddView({
  costcodes = [],
  formRef,
  isProjectSpecific,
  actionButtonEnabled,
  initialCode = 1,
  numberOfStepsChanged,
  setCustomValidator,
  onStepChange,
  currentStep,
  errors,
  customFields,
  setCustomFields,
}) {
  const dispatch = useDispatch();
  const {
    customFieldTemplate,
  } = useSelector((state) => state.costcodes);

  const [availableCostcodes, setAvailableCostcodes] = useState(costcodes);
  const [chosenCostcodes, setChosenCostcodes] = useState([]);
  const [formValues, setFormValues] = useState();
  const [width, setWidth] = useState(MEDIUM_TAB_WIDTH);
  const [formRequired, setFormRequired] = useState(true);
  const [data, setData] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [workbook, setWorkbook] = useState();
  const [currentTab, setCurrentTab] = useState('0');
  const [selectedDivisionId, setSelectedDivisionId] = useState();

  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const sortedcc = useMemo(() => [...costcodes].sort(sortByNumber('code')), [costcodes]);

  useEffect(() => {
    setCustomFields(customFieldTemplate?.sections);
  }, [customFieldTemplate]);

  useEffect(() => {
    dispatch(getCostcodeCustomFieldTemplate());
  }, []);

  const onGlobalSearchSelect = useCallback((value) => {
    if (!(value in costcodeMap)) return;
    const newChosenCostcodes = chosenCostcodes
      .concat([costcodeMap[value]])
      .sort(sortByNumber('code'));

    setChosenCostcodes(newChosenCostcodes);
    setAvailableCostcodes(availableCostcodes.filter((cc) => cc.id !== value).sort(sortByNumber('code')));
    setData(newChosenCostcodes);
  }, [costcodeMap, chosenCostcodes, availableCostcodes]);

  const clearCostCodeList = useCallback(() => {
    setAvailableCostcodes(sortedcc);
    setChosenCostcodes([]);
    setData([]);
  }, [sortedcc]);

  const removeCostCode = useCallback((record) => {
    const filteredCodes = chosenCostcodes.filter((cc) => cc.id !== record.id).sort(sortByNumber('code'));
    setChosenCostcodes(filteredCodes);
    setAvailableCostcodes(availableCostcodes.concat([record]).sort(sortByNumber('code')));
    setData(filteredCodes);
  }, [chosenCostcodes, availableCostcodes]);

  const costcodeValidator = () => {
    if (data.length <= 0) throw new Error('No cost codes found');
    return data;
  };

  const getCustomValidator = (tab) => {
    switch (tab) {
      case '0':
        return null;
      case '1':
      case '2':
        return costcodeValidator;
      default:
        return null;
    }
  };

  useEffect(() => {
    setCustomValidator(getCustomValidator(currentTab));
  }, [data, currentTab]);

  const onValuesChanged = useCallback((_, values) => {
    setFormValues(values);
  }, []);

  const onTabChange = useCallback((tab) => {
    const isUploadStep = tab === '1';

    setCurrentTab(tab);
    setWidth(getWidthForTab(tab));
    setUploading(false);
    setFormRequired(tab === '0'); // This will turn off requirement for fields in tab 0,
    setFormValues(null);
    numberOfStepsChanged(isUploadStep ? 3 : 1);
    actionButtonEnabled(!isUploadStep);
    setCustomValidator(null);
  }, []);

  const onUpload = useCallback(async (obj) => {
    // Required for antd upload to not barf out.
    setTimeout(() => {
      obj.onSuccess('ok');
    }, 0);
  }, []);

  const handleChange = useCallback(({
    file: {
      status,
      originFileObj,
    },
  }) => {
    switch (status) {
      case 'uploading':
        setUploading(true);
        break;
      case 'done':
        parseWorkbook(originFileObj, (parsedWorkbook) => {
          setUploading(true);
          setWorkbook(parsedWorkbook);
          setWidth(LARGE_TAB_WIDTH);
        });
        break;
      default:
        setUploading(false);
    }
  }, []);

  const isEquipment = formValues?.categoryId == 4;

  return (
    <Form
      layout="vertical"
      style={{ width, transition: 'width 0.5s' }}
      ref={formRef}
      initialValues={{
        code: initialCode,
      }}
      onValuesChange={onValuesChanged}
    >
      <Tabs
        defaultActiveKey="0"
        onChange={onTabChange}
        tabPosition="left"
      >
        <TabPane tab="Create" key="0">
          <Form.Item
            name="code"
            label="Cost Code"
            style={{ marginBottom: 20 }}
            rules={[
              { required: formRequired, message: 'Please enter a cost code' },
              { max: 12, message: 'Code cannot exceed 12 characters ' },
            ]}
          >
            <OnTraccrTextInput
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="name"
            label="Name"
            style={{ marginBottom: 20 }}
            rules={[{ required: formRequired, message: 'Please enter a name' }]}
          >
            <OnTraccrTextInput
              style={{ width: '100%' }}
            />
          </Form.Item>

          <Form.Item
            name="description"
            label="Description"
            style={{ marginBottom: 20 }}
            rules={[{ required: formRequired, message: 'Please enter a description' }]}
          >
            <OnTraccrTextInput
              style={{ width: '100%' }}
            />
          </Form.Item>

          {!isProjectSpecific ? (
            <Form.Item
              name="divisionId"
              label="Division"
              valuePropName="divisionId"
              style={{ marginBottom: 20 }}
              rules={[{ required: formRequired, message: 'Please enter a division' }]}
            >
              <DivisionSelector divisionId={selectedDivisionId} onChange={setSelectedDivisionId} />
            </Form.Item>
          ) : null }
          <Form.Item
            name="categoryId"
            key="categoryId"
            label="Category"
            style={{ marginBottom: 20, width: '100%' }}
            valuePropName="categoryId"
            rules={[
              { required: formRequired, message: 'Please select a category' },
            ]}
          >
            <CostcodeCategorySelector />
          </Form.Item>

          {
            !doesNumberExist(formValues?.wageMultiplier)
            && !doesNumberExist(formValues?.dailyWage)
            && !doesNumberExist(formValues?.wageAdjustment)
            && (
              <Form.Item
                name="hourlyWage"
                label={isEquipment ? 'Hourly Cost' : 'Hourly Wage'}
                key="hourlyWage"
                style={{ marginBottom: 20, width: '100%' }}
              >
                <OnTraccrNumberInput
                  style={{ width: '100%' }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  step={0.01}
                  precision={2}
                  min={0}
                />
              </Form.Item>
            )
          }

          {
            !doesNumberExist(formValues?.wageMultiplier)
            && !doesNumberExist(formValues?.hourlyWage)
            && !doesNumberExist(formValues?.wageAdjustment)
            && (
              <Form.Item
                name="dailyWage"
                label={isEquipment ? 'Daily Cost' : 'Daily Wage'}
                key="dailyWage"
                style={{ marginBottom: 20, width: '100%' }}
              >
                <OnTraccrNumberInput
                  style={{ width: '100%' }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  step={0.01}
                  precision={2}
                  min={0}
                />
              </Form.Item>
            )
          }

          {
            !isEquipment
            && !doesNumberExist(formValues?.wageMultiplier)
            && !doesNumberExist(formValues?.hourlyWage)
            && !doesNumberExist(formValues?.dailyWage)
            && (
              <Form.Item
                name="wageAdjustment"
                label="Wage Adjustment"
                key="wageAdjustment"
                style={{ marginBottom: 20, width: '100%' }}
              >
                <OnTraccrNumberInput
                  style={{ width: '100%' }}
                  formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                  parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  step={0.01}
                  precision={2}
                />
              </Form.Item>
            )
          }

          {
            !isEquipment
            && !doesNumberExist(formValues?.hourlyWage)
            && !doesNumberExist(formValues?.dailyWage)
            && !doesNumberExist(formValues?.wageAdjustment)
            && (
              <Form.Item
                name="wageMultiplier"
                label="Wage Multiplier"
                key="wageMultiplier"
                style={{ marginBottom: 20, width: '100%' }}
              >
                <OnTraccrNumberInput
                  style={{ width: '100%' }}
                  step={0.01}
                  precision={2}
                  min={0}
                />
              </Form.Item>
            )
          }

          { !doesNumberExist(formValues?.dailyBillingRate) && (
            <Form.Item
              name="hourlyBillingRate"
              label="Hourly Billing Rate"
              key="hourlyBillingRate"
              style={{ marginBottom: 20, width: '100%' }}
            >
              <OnTraccrNumberInput
                style={{ width: '100%' }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                step={0.01}
                precision={2}
                min={0}
              />
            </Form.Item>
          )}

          { !doesNumberExist(formValues?.hourlyBillingRate) && (
            <Form.Item
              name="dailyBillingRate"
              label="Daily Billing Rate"
              key="dailyBillingRate"
              style={{ marginBottom: 20, width: '100%' }}
            >
              <OnTraccrNumberInput
                style={{ width: '100%' }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                step={0.01}
                precision={2}
                min={0}
              />
            </Form.Item>
          )}

          { !!customFields?.length && (
            <Form.Item
              name="customData"
              style={{ marginBottom: 20 }}
            >
              <CustomFields
                customFields={customFields}
                divisions={[selectedDivisionId]}
                errors={errors}
              />
            </Form.Item>
          )}
        </TabPane>
        <TabPane tab="Upload" key="1">
          <Upload
            customRequest={onUpload}
            onChange={handleChange}
            beforeUpload={checkFileIsSpreadsheet}
            style={{ marginBottom: 30 }}
          >
            <OnTraccrButton
              title="Upload"
              icon={<UploadOutlined />}
            />
            <p className="upload-format-text">Supports .xlsx or .xls file types</p>
          </Upload>
          <div style={{ marginTop: 15, marginBottom: 15 }}>
            <div>
              <a className="template-link-text" href={costcodeTemplateURL}>Need a template?</a>
            </div>
            <OnTraccrButton
              style={{ marginTop: 10 }}
              type="cancel"
              title="Download"
              icon={<CloudDownloadOutlined />}
              href={costcodeTemplateURL}
            />
          </div>
          {uploading ? (
            <CostCodeUploadSteps
              workbook={workbook}
              onStepChange={onStepChange}
              currentStep={currentStep}
              onData={setData}
              actionButtonEnabled={actionButtonEnabled}
            />
          ) : ''}
        </TabPane>
        {isProjectSpecific && (
          <TabPane
            tab="Add Global"
            key="2"
          >
            <CostCodeProjectsAddGlobal
              availabe={availableCostcodes}
              chosen={chosenCostcodes}
              onSearchSelect={onGlobalSearchSelect}
              onListClear={clearCostCodeList}
              onRemove={removeCostCode}
            />
          </TabPane>
        )}
      </Tabs>
    </Form>
  );
}

CostCodeAddView.propTypes = {
  costcodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    code: PropTypes.string,
    name: PropTypes.string,
    description: PropTypes.string,
    divisionId: PropTypes.number,
    categoryId: PropTypes.number,
    hourlyWage: PropTypes.number,
    wageMultiplier: PropTypes.number,
    hourlyBillingRate: PropTypes.number,
    dailyBillingRate: PropTypes.number,
  })),
  formRef: PropTypes.shape({}),
  isProjectSpecific: PropTypes.bool,
  actionButtonEnabled: PropTypes.func.isRequired,
  initialCode: PropTypes.number,
  numberOfStepsChanged: PropTypes.func.isRequired,
  setCustomValidator: PropTypes.func.isRequired,
  onStepChange: PropTypes.func.isRequired,
  currentStep: PropTypes.number,
  errors: PropTypes.shape({}),
  customFields: PropTypes.arrayOf(PropTypes.shape({})),
  setCustomFields: PropTypes.func.isRequired,
};

CostCodeAddView.defaultProps = {
  costcodes: [],
  formRef: {},
  isProjectSpecific: false,
  initialCode: 1,
  currentStep: 0,
  errors: {},
  customFields: [],
};
