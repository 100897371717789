import React, {
  useEffect, useState, useCallback, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  Col, Drawer, message, Row,
} from 'antd';
import moment from 'moment/moment';
import * as Sentry from '@sentry/react';
import { DateTime } from 'luxon';

// Import Components:
import InvoiceAddDrawerFooter from './InvoiceAddDrawerFooter';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import InvoiceAddForm from './InvoiceAddForm';
import DirectoryDestinationDrawer, { FOLDER_DIRECTORY } from '../../common/DirectoryDestinationDrawer';
import PDFUploadView from './PDFUploadView';

// Import Actions:
import { createInvoice, deleteInvoice, updateInvoice } from './state/invoices.actions';

// Import Helpers/Constants:
import {
  INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE, INVOICE_PROP,
} from './invoiceConstants';
import { DEFAULT_FILE_PATH } from './FileDestinationSelect';
import { getInvoiceAddDrawerTitle, prepareInvoicePayload } from '../../forms/formHelpers';
import { downloadFiles } from '../../helpers/fileHelpers';
import { distributedCostValid } from './invoiceHelpers';
import Permissions from '../../auth/Permissions';

/**
 * Adjusts file path formatting
 * @param {string}
 * @returns {string}
 */
const adjustFilePath = (path) => {
  let pathName = DEFAULT_FILE_PATH;
  if (typeof path === 'string' && path.length) pathName = `${path}/`;
  return pathName;
};

/** Invoice Drawer Component used to add and edit invoices */
export default function InvoiceAddDrawer({
  formId,
  invoice,
  visible,
  mode,
  useRange,
  range,
  updateMode,
  closeDrawer,
  projectId,
  costcode,
  onInvoiceSubmit,
  zIndex,
  hasWrite,
  divisionId,
}) {
  const dispatch = useDispatch();
  const vendors = useSelector((state) => state.vendors.vendors);
  const statuses = useSelector((state) => state.invoices.statuses);
  const forms = useSelector((state) => state.forms.forms);

  const [isLoading, setIsLoading] = useState(false);
  const [isFormValueLoading, setIsFormValueLoading] = useState(false);
  const [destinationDrawerOpen, setDestinationDrawerOpen] = useState(false);
  const [selectedFilePath, setSelectedFilePath] = useState(DEFAULT_FILE_PATH);
  const [selectedFile, setSelectedFile] = useState(null);
  const [invalidDistributionMap, setInvalidDistributionMap] = useState({});

  const isDisplay = mode === INVOICE_DRAWER_VIEW_MODE;
  const form = useRef();

  // On-click Handlers:
  // ------------------------------------------------------------
  const onFileRemove = useCallback(() => setSelectedFile(null), []);
  const onFileSelect = useCallback((file) => {
    // open destination selector if we are in add mode and still have deafault path selected
    if (mode === INVOICE_DRAWER_ADD_MODE && selectedFilePath === DEFAULT_FILE_PATH) {
      setDestinationDrawerOpen(true);
    }

    /*
      https://projectharbour.atlassian.net/browse/HARBOUR-4487
      Need to give unique name so files dont collide
    */
    // eslint-disable-next-line no-param-reassign
    const newFile = new File([file], `${DateTime.local().toMillis()}-${file.name}`,{
      type: file.type,
    });
    setSelectedFile(newFile);
  }, [mode, selectedFilePath]);
  const openDestinationDrawer = useCallback(() => setDestinationDrawerOpen(true), []);
  const onCloseDestinationDrawer = useCallback(() => setDestinationDrawerOpen(false), []);
  const onFileDestinationSave = useCallback((path) => {
    if (!form || !form.current) return;
    const values = form.current.getFieldsValue(true);
    form.current.setFieldsValue({
      ...values,
      filePath: path,
    });
    setDestinationDrawerOpen(false);
  }, [form]);
  const onDeleteInvoiceClick = useCallback(() => {
    const { invoiceNumber, id: invoiceId } = invoice || {};
    CustomConfirmModal({
      title: invoiceNumber ? `Delete Invoice ${invoiceNumber}?` : 'Delete Invoice?',
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: async () => {
        setIsLoading(true);
        const res = await dispatch(deleteInvoice(invoiceId, formId));
        setIsLoading(false);
        if (res) {
          form.current.resetFields();
          closeDrawer();
        }
      },
    });
  }, [invoice, formId, form]);

  const getInvalidDistributions = useCallback(({ costcodeDistributions = [] } = {}) => {
    const invalidDistributions = costcodeDistributions?.filter(
      ({ projectId: distributionProjectId, costcodeId }) => !distributionProjectId || !costcodeId,
    ).map(({ id }) => id);

    return invalidDistributions;
  }, []);

  const onInvoiceSubmitClick = useCallback(async (mode) => {
    try {
      if (!form || !form.current) return;
      setIsLoading(true);
      await form.current.validateFields();
      const userFormInputs = form.current.getFieldsValue(true);
      if (!distributedCostValid(userFormInputs)) {
        message.error('The amount distributed exceeds the total invoice amount');
        return;
      }

      const invalidDistributions = getInvalidDistributions(userFormInputs);
      if (invalidDistributions.length) {
        setInvalidDistributionMap(
          invalidDistributions.reduce((acc, id) => ({ ...acc, [id]: true }), {}),
        );
        message.error('Please select a project and costcode for each distribution');
        return;
      }

      setInvalidDistributionMap({});

      const { id: invoiceId } = invoice || {};
      const { formId: selectedFormId } = userFormInputs || {};
      const preparedPayload = prepareInvoicePayload({
        type: mode,
        formInputs: userFormInputs,
        existingInvoice: invoice,
        vendors,
        statuses,
        file: selectedFile,
      });
      const res = await dispatch(
        mode === INVOICE_DRAWER_ADD_MODE
          ? createInvoice({
            formId: selectedFormId,
            useRange,
            range: useRange ? range : null,
            payload: preparedPayload,
          })
          : updateInvoice({
            invoiceId,
            formId: selectedFormId,
            useRange,
            range,
            payload: preparedPayload,
          }),
      );
      if (res) {
        form.current.resetFields();
        if (onInvoiceSubmit) onInvoiceSubmit(); // for project progress page
        const uploadError = res?.[0] ?? null;
        if (uploadError) message.error(`QBO Attachment Upload Failed: ${uploadError}`);
        closeDrawer();
      }
    } catch (error) {
      const errMsg = mode === INVOICE_DRAWER_ADD_MODE
        ? 'Could not create new invoice'
        : 'Could not update invoice';
      message.error(errMsg);
      console.error(error);
      Sentry.withScope(() => {
        Sentry.captureException(error);
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    dispatch,
    form,
    vendors,
    statuses,
    selectedFile,
    invoice,
    useRange,
    range,
    mode,
    onInvoiceSubmit,
  ]);

  const onInvoiceModeChange = useCallback((mode) => updateMode(mode), []);

  // Side-effects:
  // ------------------------------------------------------------
  // Sets form values when drawer becomes visible, and clears form values when drawer closed:
  useEffect(() => {
    if (!form || !form.current) return;
    const initializeFormFields = async () => {
      setIsFormValueLoading(true);
      const {
        invoiceNumber,
        amount,
        description,
        dateIssued,
        dueDate,
        vendorId,
        customerId,
        projectId: invoiceProjectId,
        statusId,
        files,
        filePath,
        costcodeDistributions,
        formId: invoiceFormId,
        qboSyncPrevented,
      } = invoice || {};
      const parsedFiles = await downloadFiles(files);
      const adjustedFilePath = adjustFilePath(filePath);
      form.current.setFieldsValue({
        invoiceNumber,
        invoiceAmount: amount,
        invoiceDescription: description,
        invoiceIssueDate: dateIssued ? moment(dateIssued) : null,
        invoiceDueDate: dueDate ? moment(dueDate) : null,
        vendorId,
        customerId,
        projectId: projectId ?? invoiceProjectId,
        statusId,
        filePath: adjustedFilePath,
        costcodeDistributions,
        formId: formId ?? invoiceFormId,
        qboSyncPrevented,
      });
      setSelectedFilePath(adjustedFilePath);
      if (parsedFiles && parsedFiles.length) setSelectedFile(parsedFiles[0]);
      setIsFormValueLoading(false);
    };

    if (visible) {
      if ((mode === INVOICE_DRAWER_EDIT_MODE
          || mode === INVOICE_DRAWER_VIEW_MODE)
          || (mode === INVOICE_DRAWER_ADD_MODE && (formId || projectId))) {
        initializeFormFields();
      }
    } else {
      form.current.resetFields();
      setSelectedFile(null);
      setDestinationDrawerOpen(false);
      setSelectedFilePath(DEFAULT_FILE_PATH);
    }
  }, [visible, invoice, mode, form]);

  const displayPDFViewer = selectedFile || mode !== INVOICE_DRAWER_VIEW_MODE;

  const linkedPoIsClosed = useMemo(() => {
    const { formId: poFormId } = invoice ?? {};
    if (!poFormId) return false;

    return forms?.[poFormId]?.isClosed;
  }, [invoice, forms]);

  return (
    <>
      <Drawer
        title={getInvoiceAddDrawerTitle(invoice, mode)}
        onClose={closeDrawer}
        visible={visible}
        width={1300}
        zIndex={zIndex}
      >
        <Row gutter={[24, 0]} className="invoice-add-drawer">
          {displayPDFViewer
            && (
            <Col span={12} className="invoice-add-drawer-upload">
              <PDFUploadView
                mode={mode}
                selectedFile={selectedFile}
                onFileSelect={onFileSelect}
                removeSelectedFile={onFileRemove}
              />
            </Col>
            )}
          <Col span={displayPDFViewer ? 12 : 24} className="invoice-add-drawer-form">
            <InvoiceAddForm
              formRef={form}
              formId={formId}
              invoice={invoice}
              isDisplay={isDisplay}
              mode={mode}
              visible={visible}
              showSaveDestination={displayPDFViewer}
              openDestinationDrawer={openDestinationDrawer}
              loading={isFormValueLoading}
              projectId={projectId}
              costcode={costcode}
              zIndex={zIndex}
              invalidDistributionMap={invalidDistributionMap}
              divisionId={divisionId}
            />
          </Col>
        </Row>
        {Permissions.has('INVOICES_WRITE') && hasWrite && (
        <InvoiceAddDrawerFooter
          mode={mode}
          isLoading={isLoading}
          onDeleteInvoiceClick={onDeleteInvoiceClick}
          onInvoiceSubmitClick={onInvoiceSubmitClick}
          onInvoiceModeChange={onInvoiceModeChange}
          linkedPoIsClosed={linkedPoIsClosed}
        />
        )}
      </Drawer>
      <DirectoryDestinationDrawer
        visible={destinationDrawerOpen}
        onClose={onCloseDestinationDrawer}
        onSubmit={onFileDestinationSave}
        type={FOLDER_DIRECTORY}
        drawerTitle="Choose Save Destination"
        operationTitle="Save"
        rootTitle={DEFAULT_FILE_PATH}
        rootKey="files"
        selected={selectedFilePath}
        width={600}
        zIndex={1002}
      />
    </>
  );
}

InvoiceAddDrawer.propTypes = {
  formId: PropTypes.string,
  invoice: PropTypes.shape(INVOICE_PROP),
  visible: PropTypes.bool,
  mode: PropTypes.oneOf([
    INVOICE_DRAWER_ADD_MODE,
    INVOICE_DRAWER_EDIT_MODE,
    INVOICE_DRAWER_VIEW_MODE,
  ]).isRequired,
  useRange: PropTypes.bool,
  range: PropTypes.array,
  updateMode: PropTypes.func,
  closeDrawer: PropTypes.func,
  projectId: PropTypes.string,
  costcode: PropTypes.object,
  onInvoiceSubmit: PropTypes.func,
  zIndex: PropTypes.number,
  hasWrite: PropTypes.bool,
  divisionId: PropTypes.string,
};

InvoiceAddDrawer.defaultProps = {
  onInvoiceSubmit: null,
  zIndex: 1000,
  hasWrite: true,
  divisionId: null,
};
