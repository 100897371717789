import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { DateTime } from 'luxon';

import FilteredFormList from '../../forms/FilteredFormList';
import { getStatuses, getTemplates } from '../../forms/state/forms.actions';
import { getCustomerLabels, getCustomers } from '../../contacts/customers/state/customers.actions';
import { getVendorLabels, getVendors } from '../../contacts/vendors/state/vendors.actions';
import { getLabels } from '../../labels/state/labels.actions';

/** Purchse Order List Component */
function PayablesList({
  id,
  type,
  isNotDisplay,
  hideDate = true,
  visible,
  topOffset = 240,
  option,
  formType = 'PO',
  hideWhenEmpty,
}) {
  const dispatch = useDispatch();

  const [range, setRange] = useState([
    DateTime.local().minus({ days: 7 }).toMillis(),
    DateTime.local().toMillis(),
  ]);

  useEffect(() => {
    if (visible) {
      dispatch(getTemplates());
      dispatch(getStatuses());
      dispatch(getLabels());
      dispatch(getCustomers());
      dispatch(getCustomerLabels());
      dispatch(getVendors());
      dispatch(getVendorLabels());
    } else {
      // Reset range to default
      setRange([
        DateTime.local().minus({ days: 7 }).toMillis(),
        DateTime.local().toMillis(),
      ]);
    }
  }, [visible]);

  return (
    <FilteredFormList
      formType={formType}
      filterId={id}
      filterType={type}
      topOffset={topOffset}
      isNotDisplay={isNotDisplay}
      visible={visible}
      hideDate={hideDate}
      range={range}
      onRangeChange={setRange}
      option={option}
      hideWhenEmpty={hideWhenEmpty}
    />
  );
}

PayablesList.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  isNotDisplay: PropTypes.bool,
  visible: PropTypes.bool,
  hideDate: PropTypes.bool,
  topOffset: PropTypes.number,
  option: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  formType: PropTypes.string,
  hideWhenEmpty: PropTypes.bool,
};

PayablesList.defaultProps = {
  id: null,
  type: null,
  isNotDisplay: false,
  visible: false,
  hideDate: true,
  topOffset: 240,
  option: null,
  formType: 'PO',
  hideWhenEmpty: false,
};

export default PayablesList;
