import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';

import PayablesList from '../../common/forms/PayablesList';

import PayableFormDrawer from '../../payables/PayableFormDrawer';

import Permissions from '../../auth/Permissions';

function ProjectSubcontracts({
  projectId,
  isNotDisplay,
}) {
  const [drawerVisible, setDrawerVisible] = useState(false);
  const onAddSubContractClick = useCallback(() => setDrawerVisible(true), []);
  const closeDrawer = useCallback(() => setDrawerVisible(false), []);
  if (isNotDisplay) return null;
  return (
    <>
      <div style={{ marginBottom: 10, marginLeft: 10 }}>Sub-Contracts</div>
      <PayablesList
        id={projectId}
        type="Projects"
        formType="Sub-Contract"
        /*
          Need to toggle visible on PayableFormDrawer close or else forms created in
          PayableFormDrawer wont appear in list
        */
        visible={!drawerVisible}
        topOffset={400}
        hideDate
        option={
          Permissions.has('PURCHASE_ORDERS_WRITE')
            ? { title: 'Add Sub-Contract', onClick: onAddSubContractClick }
            : null
        }
        hideWhenEmpty
      />
      <PayableFormDrawer
        projectId={projectId}
        type={drawerVisible ? 'Sub-Contract' : null}
        onClose={closeDrawer}
      />
    </>
  );
}

ProjectSubcontracts.propTypes = {
  projectId: PropTypes.string.isRequired,
  isNotDisplay: PropTypes.bool.isRequired,
};

export default ProjectSubcontracts;
