import React, { useCallback, useMemo } from 'react';
import {
  Row, Col, Checkbox, Popover,
} from 'antd';
import {
  CalendarOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import { DAYS } from './formTrigger.constants';

function WorkflowTriggerCheckbox({
  onCheckChange,
  children,
  checked,
  disabled,
  showTimeOptions,
  triggerDays = [],
  onTriggerDaysChanged,
}) {
  const triggerDaySet = useMemo(() => new Set(triggerDays), [triggerDays]);
  const onChange = useCallback(onCheckChange ? (e) => {
    const {
      target: {
        checked: newChecked,
      } = {},
    } = e;
    onCheckChange(newChecked);
  } : null, [onCheckChange]);

  const onDayChange = useCallback((day) => (e) => {
    if (!onTriggerDaysChanged) return;
    const {
      target: {
        checked: newChecked,
      } = {},
    } = e;
    const newDays = newChecked
      ? triggerDays.concat([day])
      : triggerDays.filter((existingDay) => existingDay !== day)
    onTriggerDaysChanged(newDays);
  }, [onTriggerDaysChanged, triggerDays]);

  return (
    <Row style={{ margin: '0px 5px', width: '100%' }}>
      <Col>
        <Checkbox
          checked={checked}
          onChange={onChange}
          style={{ color: 'white' }}
          disabled={disabled}
        >
          {children}
        </Checkbox>
      </Col>
      {
        showTimeOptions
        && (
        <Col>
          <Popover
            placement="right"
            trigger={['click']}
            content={(
              <div style={{ maxWidth: 300 }}>
                {DAYS.map((day) => (
                  <Checkbox
                    checked={triggerDaySet.has(day)}
                    onChange={onDayChange(day)}
                  >
                    {day}
                  </Checkbox>
                ))}
              </div>
            )}
          >
            <BorderlessButton
              style={{
                height: 20, padding: 0, background: 'transparent', color: 'white',
              }}
              iconNode={<CalendarOutlined />}
            />
          </Popover>
        </Col>
        )
      }
    </Row>
  );
}

WorkflowTriggerCheckbox.propTypes = {
  onCheckChange: PropTypes.func,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  checked: PropTypes.bool.isRequired,
  disabled: PropTypes.bool,
  showTimeOptions: PropTypes.bool.isRequired,
  triggerDays: PropTypes.arrayOf(PropTypes.string),
  onTriggerDaysChanged: PropTypes.func,
};

WorkflowTriggerCheckbox.defaultProps = {
  onCheckChange: null,
  disabled: false,
  triggerDays: [],
  onTriggerDaysChanged: null,
};

export default WorkflowTriggerCheckbox;
