/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, {
  useCallback, useEffect, useRef, useState, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { PageSizes, PDFDocument } from 'pdf-lib';
import { getStandardTemplate } from 'ontraccr-form-to-pdf';
import { WarningOutlined } from '@ant-design/icons';

import PDFDesignerItem from './PDFDesignerItem';
import PDFDesignerDrawer from './PDFDesignerDrawer';
import PDFDesignerToolbar from './PDFDesignerToolbar';
import PDFDesignerNameInput from './PDFDesignerNameInput';
import PDFPageControls from '../PDFPageControls';

import {
  handleMouseMove,
  addNewItem,
  getInitialCustomPDFItems,
  pointDiff,
  resizeTableColumns,
  ONTRACCR_LOGO,
  formatTablePDFItemRows,
} from './PDFDesignerHelpers';
import { MIN_COLUMN_WIDTH } from './PDFDesigner.constants';

import {
  AUTO_COLLECTED_ID,
} from '../../../forms/forms.constants';
import { preparePDFFieldsFromSections } from '../../../forms/formHelpers';
import { isNullOrUndefined } from '../../../helpers/helpers';

const [width, height] = PageSizes.Letter;
const SCALE = 1.0;

const PDF_HEADER_HEIGHT = 133;
const PDF_DUPLICATE_HEIGHT = 143;

const decorateDrawOptionsWithFiles = ({ drawOptions = [], fileMap = {} }) => (
  drawOptions.map((page) => (
    page.map((item) => {
      const { type, image } = item;
      const newItem = { ...item, deletable: true };
      if (type !== 'image' || !image || !(image in fileMap)) return newItem;
      const {
        [image]: {
          jsFileObject,
        } = {},
      } = fileMap;
      return {
        ...newItem,
        image: jsFileObject,
      };
    })
  ))
);

const getInitialStartAtTop = (drawOptions = []) => {
  const newStart = [];
  drawOptions.forEach((page) => {
    const [{ startAtTop = false } = {}] = page;
    newStart.push(startAtTop);
  });
  return newStart;
};

export default function PDFDesigner({
  formRef,
  name,
  typeId,
  collected,
  sections,
  isDisplay,
  currentStep,
  onDrawOptionsChanged: updateDrawOptions,
  drawOptions = [],
  fileMap,
  useStandardTemplate,
  onUseStandardTemplateChange,
  isExternalForm,
  prevUseStandardTemplate,
  setPrevUseStandardTemplate,
  pdfName,
  setPDFName,
}) {
  const ref = useRef();
  const {
    settings,
    companyImageURL: logo,
  } = useSelector((state) => state.settings.company);

  const [items, setItems] = useState(decorateDrawOptionsWithFiles({ drawOptions, fileMap }));
  const [editIndex, setEditIndex] = useState(null);
  const [editing, setEditing] = useState(false);
  const [moveIndex, setMoveIndex] = useState(null);
  const [moveOffset, setMoveOffset] = useState();
  const [resizeIndex, setResizeIndex] = useState(null);
  const [resizeDirection, setResizeDirection] = useState();
  const [didMove, setDidMove] = useState(false);
  const [copyItem, setCopyItem] = useState();
  const [activePage, setActivePage] = useState(0);
  // Track whether page should start at top of a new page.
  const [startAtTopArray, setStartAtTopArray] = useState(getInitialStartAtTop(drawOptions));
  const [realItems, setRealItems] = useState([]);
  const [pdfDoc, setPDFDoc] = useState();
  const [shouldReloadDrawOptions, setShouldReloadDrawOptions] = useState(true);

  const isVisible = useMemo(() => (isDisplay || currentStep === 3), [isDisplay, currentStep]);

  const relevantSections = useMemo(() => (
    preparePDFFieldsFromSections({ sections, isExternalForm })
  ), [isExternalForm, sections]);

  const configPropsMap = useMemo(() => {
    const map = {};
    relevantSections.forEach((section) => {
      const { fields = [] } = section;
      fields.forEach(({ configProps, fieldId, id }) => {
        const realId = fieldId || id;
        map[realId] = configProps;
      });
    });
    return map;
  }, [relevantSections]);

  const onMoveStart = useCallback((index) => (offset) => {
    setMoveIndex(index);
    setMoveOffset(offset);
  }, []);

  const onResize = useCallback((index) => ({ direction }) => {
    setResizeIndex(index);
    setResizeDirection(direction);
  }, []);
  const onMouseUp = useCallback(() => {
    setMoveIndex(null);
    setMoveOffset({});
    setResizeIndex(null);
    setResizeDirection();
    if (moveIndex !== null && !didMove) {
      setEditing(true);
      setEditIndex(moveIndex);
    }
    setDidMove(false);
  }, [moveIndex, didMove]);

  const onMouseMove = useCallback((e) => {
    if (!isVisible || (isNullOrUndefined(moveIndex) && isNullOrUndefined(resizeIndex))) return;
    if (moveOffset) {
      // Consider a mouse move action as a 'move' if moving more than 20 px
      const { clientX, clientY } = e;
      const diffFromStart = pointDiff({ x: clientX, y: clientY }, moveOffset);
      setDidMove(diffFromStart > 20);
    }
    const newItems = [...items];
    const pageItems = newItems[activePage];
    const newPageItems = handleMouseMove({
      e, ref, moveIndex, moveOffset, resizeIndex, resizeDirection, items: pageItems,
    });
    newItems[activePage] = newPageItems;
    setItems(newItems);
  }, [
    items,
    activePage,
    moveIndex,
    moveOffset,
    resizeIndex,
    resizeDirection,
    useStandardTemplate,
    isVisible,
  ]);

  const toggleEditing = useCallback(() => {
    setEditIndex(null);
    setTimeout(() => setEditing(false), [500]);
  }, []);

  const onDrawOptionChanged = useCallback((data = {}) => {
    const newItems = [...items];
    const ourItem = newItems[activePage][editIndex];
    newItems[activePage][editIndex] = {
      ...resizeTableColumns({
        data,
        ourItem,
      }),
      drawOptions: { ...ourItem.drawOptions, ...data },
      isUpdated: true,
    };

    setItems(newItems);
  }, [editIndex, items, activePage]);

  const onItemAdd = useCallback((type) => {
    const newItems = [...items];
    if (type === 'page') {
      newItems.push([]);
      setItems(newItems);
      setStartAtTopArray(startAtTopArray.concat([false]));
      return;
    }

    if (type === 'copyPage') {
      const ourPage = [...items[activePage]];
      newItems.push(ourPage);
      setItems(newItems);
      setStartAtTopArray(startAtTopArray.concat([false]));
      return;
    }

    const newItem = addNewItem(type);
    if (!newItem) return;
    const pageItems = [...newItems[activePage]];
    pageItems.push(newItem);
    newItems[activePage] = pageItems;
    setItems(newItems);
    toggleEditing();
  }, [items, startAtTopArray, activePage, toggleEditing]);

  const onDelete = useCallback(() => {
    if (editIndex === null) return;
    const newItems = [...items];
    newItems[activePage].splice(editIndex, 1);
    setItems(newItems);
    toggleEditing();
  }, [items, activePage, editIndex, toggleEditing]);

  const onTextChanged = useCallback((index) => (text) => {
    const newItems = [...items];
    newItems[activePage][index] = {
      ...items[activePage][index],
      isUpdated: true,
      text,
    };
    setItems(newItems);
  }, [items, activePage]);

  const onIsSectionHeaderChanged = useCallback((index) => (isSectionHeader) => {
    const newItems = [...items];
    newItems[activePage][index] = {
      ...items[activePage][index],
      isUpdated: true,
      isSectionHeader,
    };
    setItems(newItems);
  }, [items, activePage]);

  const onImageAdd = useCallback((index) => (image) => {
    const newItems = [...items];
    newItems[activePage][index] = {
      ...items[activePage][index],
      isUpdated: true,
      image,
    };
    setItems(newItems);
  }, [items, activePage]);

  const onPageChanged = useCallback((newPage) => {
    setActivePage(newPage - 1);
    toggleEditing();
  }, [toggleEditing]);

  const onPageDeleted = useCallback(() => {
    const newItems = [...items];
    newItems.splice(activePage, 1);
    setItems(newItems);

    const newSetArray = [...startAtTopArray];
    newSetArray.splice(activePage, 1);
    setStartAtTopArray(newSetArray);

    const newActivePage = activePage >= newItems.length ? newItems.length - 1 : activePage;
    setActivePage(newActivePage);
  }, [items, startAtTopArray, activePage]);

  const onTopOfPageChecked = useCallback((checked) => {
    setStartAtTopArray(
      startAtTopArray.map((val, idx) => {
        if (idx === activePage) return checked;
        return val;
      }),
    );
  }, [startAtTopArray, activePage]);

  const onItemPageChanged = useCallback((newPage) => {
    const newItems = [...items];
    const editItem = newItems[activePage][editIndex];
    newItems[activePage].splice(editIndex, 1);
    newItems[newPage].push(editItem);
    setActivePage(newPage);
    setEditIndex(newItems[newPage].length - 1);
  }, [activePage, editIndex, items]);

  const onLinkChanged = useCallback(({ sectionId, fieldId }) => {
    const newItems = [...items];
    const editItem = newItems[activePage][editIndex];
    const newItem = { ...editItem };
    if (sectionId) {
      newItem.link = { sectionId };
    } else if (fieldId) {
      const fieldKey = newItem.link.sectionId === AUTO_COLLECTED_ID ? 'collectedId' : 'fieldId';
      newItem.link = { sectionId: newItem.link.sectionId, [fieldKey]: fieldId };
    } else {
      // Cleared sectionId
      newItem.link = {};
    }

    if (newItem.type === 'table' && newItem.link.sectionId && newItem.link.fieldId) {
      // Apply field columns to table
      const {
        fields: ourFields = [],
      } = relevantSections.find((section) => section.id === newItem.link.sectionId) || {};
      const {
        configProps: {
          columns,
        } = {},
      } = ourFields.find((field) => field.id === newItem.link.fieldId) || {};
      const {
        drawOptions: {
          width: totalWidth,
        } = {},
      } = newItem;
      if (columns) {
        const colCount = columns.length > 0 ? columns.length : 1;
        const columnWidth = totalWidth / colCount;
        newItem.rows = [
          { cols: columns.map((col) => ({ value: col.name, width: columnWidth })), isHeader: true },
          { cols: columns.map(() => ({ value: 'a' })) },
        ];
      }
    }

    newItems[activePage][editIndex] = newItem;
    setItems(newItems);
  }, [activePage, editIndex, items, relevantSections]);

  const onTableResize = useCallback((itemIndex) => ({ index: columnIndex, deltaX }) => {
    const newItems = [...items];
    const ourItem = newItems[activePage][itemIndex];
    const {
      rows = [],
      drawOptions: {
        width: totalWidth,
      } = {},
    } = ourItem;
    let widthChange = deltaX;
    const newRows = rows.map((row, rIndex) => {
      if (rIndex > 0) return row;
      const {
        cols = [],
      } = row;
      const colCount = cols.length;
      const newCols = cols.map((col, cIndex) => {
        if (cIndex !== columnIndex) return col;
        if (col.width) {
          const limitedWidth = parseInt(Math.max(col.width + deltaX, MIN_COLUMN_WIDTH), 10);
          widthChange = limitedWidth - col.width;
          return {
            ...col,
            width: limitedWidth,
          };
        }
        const percentWidth = totalWidth / (colCount > 0 ? colCount : 1);
        const limitedWidth = parseInt(Math.max(percentWidth + deltaX, MIN_COLUMN_WIDTH), 10);
        widthChange = limitedWidth - col.width;
        return {
          ...col,
          width: limitedWidth,
        };
      });
      return {
        ...row,
        cols: newCols,
      };
    });

    newItems[activePage][itemIndex] = {
      ...ourItem,
      rows: newRows,
      drawOptions: {
        ...ourItem.drawOptions,
        width: totalWidth + widthChange,
      },
    };
    setItems(newItems);
  }, [activePage, items]);

  useEffect(() => {
    const loadPDF = async () => {
      const pdf = await PDFDocument.create();
      setPDFDoc(pdf);
    };

    loadPDF();
  }, []);

  useEffect(() => {
    const callback = () => {
      if (editIndex === null) return;
      setCopyItem({
        item: items[activePage][editIndex],
        page: activePage,
      });
    };

    document.addEventListener('copy', callback);
    return () => document.removeEventListener('copy', callback);
  }, [editIndex, items, activePage]);

  useEffect(() => {
    const callback = () => {
      if (!copyItem) return;
      const {
        item: copiedItem,
        page: copyPage,
      } = copyItem;
      const newItems = [...items];
      const pageItems = newItems[activePage];
      const newItem = {
        ...copiedItem,
        // Need to spread or changes will affect both items
        drawOptions: { ...copiedItem.drawOptions },
        deletable: true,
      };
      if (copyPage === activePage) {
        // If copying to same page, offset copied item so it doesnt exactly overlap
        newItem.drawOptions.x += 50;
        newItem.drawOptions.y += 50;
      }
      pageItems.push(newItem);
      setItems(newItems);
    };

    document.addEventListener('paste', callback);
    return () => document.removeEventListener('paste', callback);
  }, [copyItem, items, activePage]);

  useEffect(() => {
    const lenStart = startAtTopArray.length;
    const mergedItems = realItems.map((page, pageIdx) => {
      if (pageIdx >= lenStart) {
        // Something weird happened.
        // Both arrays should be the same length
        return page;
      }
      if (page.length === 0) return page;
      const startAtTop = startAtTopArray[pageIdx];
      return page.map((item) => ({ ...item, startAtTop }));
    });

    updateDrawOptions(mergedItems);
  }, [realItems, startAtTopArray, updateDrawOptions]);

  useEffect(() => {
    setActivePage(0);
  }, [currentStep]);

  useEffect(() => {
    if (currentStep === 3) setShouldReloadDrawOptions(true);
  }, [currentStep]);

  const isSectionDuplicationEnabled = useMemo(() => sections?.find((section) => {
    const {
      settings: {
        isDuplicationEnabled = false,
      } = {},
    } = section;
    return isDuplicationEnabled;
  }), [sections]);

  /**
   * If the user opens an existing form template and makes changes to the visible columns of a
   * table, the drawOptions do not update to reflect the changes. To counter this, the below
   * useEffect will update all table fields with an updated version of the rows field based on the
   * columns array in the configProps of each respective table field
   *
   * The state shouldReloadDrawOptions is used to prevent looping the useEffect and to ensure it is
   * only run once when the user opens the PDF Designer step. This also allows rerunning the
   * useEffect if the user decides to go back to the Fields tab and update the visible columns
   * before submitting the template changes
   */
  useEffect(() => {
    if (useStandardTemplate
        || !shouldReloadDrawOptions
        || currentStep !== 3
        || !items?.length
    ) return;

    const newItems = items.map((pages) => {
      const newPage = pages.map((item) => {
        const {
          type,
          link: {
            fieldId,
          } = {},
          rows,
        } = item;
        if (type !== 'table') return item;
        const {
          [fieldId]: {
            columns = [],
          } = {},
        } = configPropsMap ?? {};
        const newRows = formatTablePDFItemRows(columns, rows);
        if (!newRows) return item;
        return {
          ...item,
          rows: newRows,
        };
      });

      return newPage;
    });

    setItems(newItems);
    setShouldReloadDrawOptions(false);
  }, [items, useStandardTemplate, currentStep]);

  /**
   * If the user toggles the standard template, we need to reset the draw options
   * This handles the initial load for custom templates
   *
   * The reason why we compare to the previous state is because we do not want to reset
   * the draw options if the user is editing the form or if they edit a form that already is using
   * custom draw options
   */
  useEffect(() => {
    // We can only use the custom template if there are no sections with duplication enabled
    if (isSectionDuplicationEnabled) {
      return onUseStandardTemplateChange(true);
    }

    if (
      (!items.length || prevUseStandardTemplate !== useStandardTemplate)
      && currentStep === 3
      && !useStandardTemplate
    ) {
      let ourName = name;
      if (!isDisplay && formRef && formRef.current) {
        // Need to dynamically pull the user entered name for the form
        // when editing or adding a form
        const { name: formName } = formRef.current.getFieldsValue();
        ourName = formName;
      }

      const newItems = getInitialCustomPDFItems({
        collected,
        name: ourName,
        settings,
        sections: relevantSections,
        logo,
      });
      setItems(newItems);
      setPrevUseStandardTemplate(useStandardTemplate);
    }
  }, [
    collected,
    name,
    formRef,
    isSectionDuplicationEnabled,
    relevantSections,
    settings,
    isDisplay,
    currentStep,
    logo,
    items,
    useStandardTemplate,
    prevUseStandardTemplate,
    setPrevUseStandardTemplate,
  ]);

  // Initial load of standard template
  useEffect(() => {
    const initPDF = async () => {
      if (useStandardTemplate && pdfDoc) {
        let ourName = name;

        if (!isDisplay && formRef?.current) {
          const { name: formName } = formRef.current.getFieldsValue();
          ourName = formName;
        }

        const newItems = await getStandardTemplate({
          settings: {
            ...settings,
            logo: logo || ONTRACCR_LOGO,
            showHiddenFields: true,
          },
          name: ourName,
          sections: relevantSections,
          collected,
          pdfDoc,
          originalDrawOptions: items,
        });

        setRealItems(newItems);
        return;
      }

      setRealItems(items);
    };

    initPDF();
  }, [
    useStandardTemplate,
    collected,
    isDisplay,
    formRef,
    name,
    settings,
    relevantSections,
    logo,
    items,
    fileMap,
    pdfDoc,
    isExternalForm,
  ]);

  const activeItems = useMemo(() => realItems[activePage] ?? [], [realItems, activePage]);
  const editField = useMemo(() => {
    const relevantField = editIndex !== null ? realItems[activePage]?.[editIndex] : undefined;
    if (useStandardTemplate && !relevantField?.isHeader) return undefined;
    return relevantField;
  }, [realItems, activePage, editIndex, useStandardTemplate]);

  const pdfDesignerStyle = useMemo(() => {
    if (isDisplay || !isSectionDuplicationEnabled) return {};
    return { top: isDisplay ? PDF_HEADER_HEIGHT : PDF_DUPLICATE_HEIGHT };
  }, [isDisplay, isSectionDuplicationEnabled]);

  return (
    <>
      <PDFDesignerNameInput
        isDisplay={isDisplay}
        isSectionDuplicationEnabled={isSectionDuplicationEnabled}
        onTextChange={setPDFName}
        text={pdfName}
      />
      {!isDisplay && !isSectionDuplicationEnabled && (
        <PDFDesignerToolbar
          onItemAdd={onItemAdd}
          numberOfPages={realItems.length}
          onPageDeleted={onPageDeleted}
          onTopOfPageChecked={onTopOfPageChecked}
          startAtTop={activePage < startAtTopArray.length ? startAtTopArray[activePage] : false}
          useStandardTemplate={useStandardTemplate}
          onUseStandardTemplateChange={onUseStandardTemplateChange}
        />
      )}
      <div
        className={isDisplay ? 'pdf-designer-container-display' : 'pdf-designer-container'}
        style={pdfDesignerStyle}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        { isSectionDuplicationEnabled && !isDisplay && (
          <div style={{ position: 'absolute', top: 10 }}>
            <WarningOutlined style={{ color: 'orange' }} />
            <span>
              &nbsp;Section duplication is enabled. Custom PDF templates are not available.
            </span>
          </div>
        )}
        <div
          style={{
            flexDirection: 'column',
            height: 'fit-content',
            pointerEvents: 'auto',
          }}
        >
          <div
            ref={ref}
            style={{
              height: height * SCALE,
              width: width * SCALE,
            }}
            id="pdf-designer-canvas"
          >
            { activeItems.map((item, index) => (
              <PDFDesignerItem
                isDisplay={isDisplay || (!item.isHeader && useStandardTemplate)}
                key={index}
                editing={editIndex === index}
                onMoveStart={onMoveStart(index)}
                onResize={onResize(index)}
                onEditStop={toggleEditing}
                onTextChanged={onTextChanged(index)}
                onImageAdd={onImageAdd(index)}
                onTableResize={onTableResize(index)}
                // eslint-disable-next-line react/jsx-props-no-spreading
                {...item}
              />
            ))}
          </div>
        </div>
        <PDFPageControls
          numberOfPages={realItems.length}
          activePage={activePage + 1}
          onActivePageChange={onPageChanged}
        />
        {editing && (
          <PDFDesignerDrawer
            open={editIndex !== null}
            onClose={toggleEditing}
            onDrawOptionChanged={onDrawOptionChanged}
            onDelete={onDelete}
            field={editField}
            page={activePage}
            numberOfPages={realItems.length}
            onPageChanged={onItemPageChanged}
            onTextChanged={onTextChanged(editIndex)}
            onIsSectionHeaderChanged={onIsSectionHeaderChanged(editIndex)}
            onImageAdd={onImageAdd(editIndex)}
            onLinkChanged={onLinkChanged}
            sections={relevantSections}
            collected={collected}
            typeId={typeId}
            useStandardTemplate={useStandardTemplate}
          />
        )}
      </div>
    </>
  );
}
