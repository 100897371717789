import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'antd';

import FileMoveDrawer from './FileMoveDrawer';
import FileDetailDrawer from './FileDetailDrawer';
import FilesList from './FilesList';
import FolderAddModal from './FolderAddModal';
import FileAddView from './FileAddView';
import FilePermsDrawer from './FilePermsDrawer';

import {
  getBaseCrumbs,
  getLevelFromPath,
  constructFullPath,
  parseFile,
  downloadFile,
  getPathPrefix,
  uploadFile,
} from './fileHelpers';

import {
  getFileStructure,
  createFolders,
  deleteItems,
  uploadFiles,
  moveFiles,
  loadImage,
  hideImage,
  closeItemDrawer,
  renameFolder,
} from './state/files.actions';

import {
  createNuxEntry,
  startNuxAction,
} from '../nux/state/nux.actions';

import {
  FILES_MENU_TYPE,
  FILES_PAGE_TYPE,
  FILES_PAGE_NUX_STEP_1,
  DOCUMENT_ADD_TYPE,
} from '../nux/nux.constants';

import FullPhoto from '../common/photos/FullPhoto';
import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import { setBreadcrumb, } from '../common/breadcrumbContainer/state/breadcrumb.actions';

import Permissions from '../auth/Permissions';
import Analytics from '../helpers/Analytics';

export default ({
  history,
  location:{
    pathname,
  } = {},
}) => {
  const hasWritePerms = Permissions.has('FILES_WRITE');
  const dispatch = useDispatch();
  const fileStructure = useSelector((state) => state.files.fileStructure);
  const rootFiles = useSelector((state) => state.files.rootFiles);
  const selectedPhoto = useSelector((state) => state.files.selectedPhoto);
  const nux = useSelector((state) => state.nux.nux);
  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);

  const {
    image: selectedImage = {},
    path: selectedImagePath,
    name: selectedImageName,
  } = selectedPhoto || {};
  const prefix = getPathPrefix(pathname);

  const [crumbs,setCrumbs] = useState(getBaseCrumbs());
  const [files,setFiles] = useState([]);
  const [showFolderAdd, setShowFolderAdd] = useState(false);
  const [showFileDrawer,setShowFileDrawer] = useState(false);
  const [selectedPermsFile,setSelectedPermsFile] = useState(false);
  const [selectedFolders,setSelectedFolders] = useState([[],[]]);
  const [showUploadView,setShowUploadView] = useState(false);
  const [fileDestination,setFileDestination] = useState([]);
  const [isCopy,setIsCopy] = useState(false);
  const [data,setData] = useState([]);
  const [selectedFile,setSelectedFile] = useState();
  const [currFileIndex, setCurrFileIndex] = useState();

  // Files in current path/directory
  const directoryFiles = useMemo(() => data.filter((datum) => datum.type !== 'folder'), [data]);

  const onShowPerms = useCallback((permsFile) => setSelectedPermsFile(permsFile),[]);
  const onHidePerms = useCallback(() => setSelectedPermsFile(),[]);

  const onBack = () => history.push(pathname.substring(0,pathname.lastIndexOf('/')));
  const showUpload = useCallback(() => setShowUploadView(true),[]);
  const hideUpload = useCallback(() => {
    setShowUploadView(false);
    setFiles([]);
  },[]);

  const onAddFolder = useCallback(() => setShowFolderAdd(true),[]);
  const hideMoveModal = useCallback(() => {
    setFileDestination([]);
    setShowFileDrawer(false);
  },[]);
  const onShowMoveModal = useCallback(() => {
    setIsCopy(false);
    setShowFileDrawer(true);
  },[]);
  const onShowCopyModal = useCallback(() => {
    setIsCopy(true);
    setShowFileDrawer(true);
  },[]);

  const onCreateFolder = async (folderName) => {
    if(!folderName) {
      setShowFolderAdd(false);
      return;
    };
    const prefix = getPathPrefix(pathname);
    const name = constructFullPath(prefix,folderName);
    if(await dispatch(createFolders({ names: [name] }))){
      setShowFolderAdd(false);
      if(!nux.has(DOCUMENT_ADD_TYPE)) {
        dispatch(createNuxEntry(DOCUMENT_ADD_TYPE));
      }
      return true;
    };
    return false;
  };

  const onCreateFiles = useCallback(async (notify) => {
    if(files.length === 0) return hideUpload();
    const prefix = getPathPrefix(pathname);
    const path = prefix.substring(0,prefix.length - 1);
    const payload = [];
    const fileMetadata = [];
    files.forEach((file) => {
      payload.push({
        jsFileObject:file,
        path,
        timestamp: file.timestamp,
      });
      fileMetadata.push({
        type:file.type,
        size:file.size,
      })
    });
    Analytics.track('Files/UploadFiles',{ files: fileMetadata, notify });
    if(await dispatch(uploadFiles(payload, notify))) {
      hideUpload();
      if(!nux.has(DOCUMENT_ADD_TYPE)) {
        dispatch(createNuxEntry(DOCUMENT_ADD_TYPE));
      }
      return true;
    }
    return false;
  },[files,dispatch,pathname,hideUpload,nux]);

  const onDelete = async ({ password }) => {
    const prefix = getPathPrefix(pathname);
    const toBeDeleted = selectedFolders[1].map((item) => {
      const { name, type } = item;
      const fullPath = constructFullPath(prefix,name,type === 'folder');
      return {
        name:fullPath,
        type,
      };
    });
    if(await dispatch(deleteItems({
      items: toBeDeleted,
      password,
    }))) {
      setSelectedFolders([[],[]]);
      return true;
    }
    return false;
  };


  const onMoveSubmit = async () => {
    const prefix = getPathPrefix(pathname);
    const items = selectedFolders[1];
    const fullPaths = items.map(({ name, type }) => ({
      fullPath:constructFullPath(prefix,name,type === 'folder'),
      name,
      type,
    }));
    const destination = fileDestination[0];

    if(await dispatch(moveFiles({
      destination: destination === 'Files/' ? '' : destination,
      source:prefix,
      items:fullPaths,
      copy:isCopy,
    }))) {
      setSelectedFolders([[],[]]);
      hideMoveModal();
    };
  };

  const onMoveFolder = async (newName) => {
    const { name, fullPath } = selectedFile;
    const replaceRegex = new RegExp(`${name}$`);
    return dispatch(renameFolder({
      oldName:fullPath,
      newName:fullPath.replace(replaceRegex,newName),
    }));
  };

  const addFile = useCallback((file) => {
    setFiles((oldFiles) => [...oldFiles, file]);
  }, []);

  const removeFile = useCallback((index) => {
    const newFiles = [...files];
    newFiles.splice(index,1);
    setFiles(newFiles);
  },[files]);


  const onSelect = useCallback((selected) => {
    const { id: selectedId, type, name, children = [], } = selected;
    const newPath = `${pathname}/${encodeURIComponent(name)}`;
    if(type === 'folder') {
      const newCrumbs = crumbs.concat({
        text:name,
      });
      setCrumbs(newCrumbs);
      history.push(newPath);
      const newData = [];
      children.forEach((child) => {
        if(child in fileStructure) {
          const file = fileStructure[child];
          newData.push(parseFile(file));
        }
      });
      setData(newData);
    } else {
      dispatch(loadImage(newPath, type, name));
      const selectedIndex = directoryFiles.findIndex((file) => file.id === selectedId);
      setCurrFileIndex(selectedIndex);
    }
  },[crumbs, directoryFiles, pathname, history, dispatch, fileStructure]);

  const onHideImage = useCallback(() => {
    dispatch(hideImage());
  },[dispatch]);

  const onNextFileClick = useCallback((isRight) => () => {
    const newIndex = isRight ? currFileIndex + 1 : currFileIndex - 1;
    const isInvalid = isRight ? newIndex >= directoryFiles.length : newIndex < 0;
    if (isInvalid) return;
    const { type, name } = directoryFiles[newIndex] || {};
    const newPath = `${pathname}/${encodeURIComponent(name)}`;
    dispatch(loadImage(newPath, type, name));
    setCurrFileIndex(newIndex);
  }, [dispatch, pathname, currFileIndex, directoryFiles]);

  useEffect(() => {
    if(dispatch && nux && nux.size &&  nux.has && !nux.has(FILES_PAGE_TYPE)) {
      dispatch(createNuxEntry(FILES_MENU_TYPE));
      dispatch(createNuxEntry(FILES_PAGE_TYPE));
      dispatch(startNuxAction(FILES_PAGE_NUX_STEP_1));
    }
  },[dispatch,nux]);

  useEffect(() => {
    if(dispatch) {
      setCrumbs(getBaseCrumbs());
      dispatch(getFileStructure());
    }
  },[dispatch]);

  useEffect(() => {
    if(dispatch) dispatch(setBreadcrumb(crumbs));
  },[crumbs,dispatch]);

  useEffect(() => {
    if(rootFiles.length && fileStructure) {
      const newData = [];
      rootFiles.forEach((file) => {
        newData.push(parseFile(file));
      });
      if(newData) setData(newData);
    }
  },[fileStructure,rootFiles]);

  useEffect(() => {
    if(fileStructure) {
      const { crumbs, path } = getLevelFromPath({
        fileStructure,
        pathname:decodeURIComponent(pathname),
      });
      let fileData = [];

      const isRoot = !path || path.length === 0;
      if(isRoot) {
        fileData = rootFiles;
      } else {
        const {
          [path]:{
            children = [],
          } = {}
        } = fileStructure;
        fileData = children;
      }

      const newData = [];
      fileData.forEach((child) => {
        if(isRoot) {
          newData.push(parseFile(child));
        } else if(child in fileStructure) {
          const file = fileStructure[child];
          newData.push(parseFile(file));
        }
      });
      setData(newData);
      setCrumbs(crumbs);
    }

  },[pathname,fileStructure,history,rootFiles]);

  const updateFile = useCallback(async (_, updatedFile) => {
    const result = await uploadFile({
      updatedFile,
      dispatch,
      pathname,
    });

    if (result) onHideImage();
  },[pathname, selectedImageName, onHideImage]);

  if(!Permissions.has('FILES_READ')) {
    history.replace('/dashboard');
    return <></>;
  }

  return (
    <BreadCrumbContainer
      crumbs={
        activeNuxAction ? [
          {...crumbs[0], style:{ backgroundColor:'white', borderRadius:4, padding: 2 }}
        ] : crumbs
      }
      crumbStyle={{
        zIndex: activeNuxAction ? 100 : 1,
        position: 'relative',
      }}
    >
      <Row style={{ width: '100%', height:'100%'}} gutter={10}>
        <FilesList
          data={data}
          onClick={onSelect}
          onAddFolder={onAddFolder}
          onAddFile={showUpload}
          onMore={(file) => {
            setSelectedFile(file);
            setSelectedFolders([[],[]]);
          }}
          onShare={onShowPerms}
          onSelect={(...args) => setSelectedFolders(args)}
          selected={selectedFolders[0]}
          onDelete={onDelete}
          onMove={onShowMoveModal}
          onCopy={onShowCopyModal}
          pathname={pathname}
          history={history}
          hasWritePerms={hasWritePerms}
          onBack={pathname !== '/files' ? onBack : null}
        />
      </Row>
      <FolderAddModal
        visible={showFolderAdd}
        onClose={onCreateFolder}
      />
       <FileMoveDrawer
        visible={showFileDrawer}
        selectedFolders={selectedFolders[0]}
        prefixPath={prefix}
        onSelectedDestination={setFileDestination}
        selectedDestination={fileDestination}
        onClose={hideMoveModal}
        onSubmit={onMoveSubmit}
        copy={isCopy}
        fileStructure={fileStructure}
        rootFiles={rootFiles}
      />
      <FileDetailDrawer
        hasWritePerms={hasWritePerms}
        selectedFile={selectedFile}
        onRename={setSelectedFile}
        onClose={() => {
          setSelectedFile();
          dispatch(closeItemDrawer);
        }}
        onMoveFolder={onMoveFolder}
      />
      <FileAddView
        visible={showUploadView}
        files={files}
        onUploadEnd={hideUpload}
        onAddFile={addFile}
        onFileRemove={removeFile}
        onSave={onCreateFiles}
      />
      <FilePermsDrawer
        selectedFile={selectedPermsFile}
        onClose={onHidePerms}
      />
      <FullPhoto
        path={selectedImagePath}
        url={selectedImage.src}
        type={selectedImage.type}
        onClose={onHideImage}
        title={selectedImageName}
        onLeft={onNextFileClick(false)}
        onRight={onNextFileClick(true)}
        showLeft={selectedPhoto && currFileIndex > 0}
        showRight={selectedPhoto && currFileIndex < (directoryFiles.length - 1)}
        onDownload={() => {
          const fullPath = selectedImagePath.replace('/files/','');
          const name = selectedImagePath.split('/').pop();
          downloadFile({
            fileDetails:{
              fullPath,
              name,
              type:selectedImage.type,
            },
          });
        }}
        onSave={updateFile}
        annotatedFileName={selectedImageName}
        useApryse
        showMetadata
      />
    </BreadCrumbContainer>
  );
}
