import React, { useCallback, useMemo } from 'react';
import {
  AntDesignOutlined,
  ApartmentOutlined,
  ApiOutlined,
  AppstoreOutlined,
  BankOutlined,
  BlockOutlined,
  BorderOuterOutlined,
  BoxPlotOutlined,
  DeploymentUnitOutlined,
  DragOutlined,
  EnvironmentOutlined,
  ExclamationCircleOutlined,
  GatewayOutlined,
  HeatMapOutlined,
  PicCenterOutlined,
  RadarChartOutlined,
  RadiusSettingOutlined,
  StarOutlined,
} from '@ant-design/icons';
import { Popover } from 'antd';
import PropTypes from 'prop-types';

import useToggle from '../hooks/useToggle';
import BorderlessButton from '../buttons/BorderlessButton';
import IconPickerModal from './IconPickerModal';
import IconValueMap from './iconPickerMap';

const Icons = [
  { value: 'AntDesignOutlined', label: AntDesignOutlined },
  { value: 'ApartmentOutlined', label: ApartmentOutlined },
  { value: 'ApiOutlined', label: ApiOutlined },
  { value: 'AppstoreOutlined', label: AppstoreOutlined },
  { value: 'BankOutlined', label: BankOutlined },
  { value: 'BlockOutlined', label: BlockOutlined },
  { value: 'BorderOuterOutlined', label: BorderOuterOutlined },
  { value: 'BoxPlotOutlined', label: BoxPlotOutlined },
  { value: 'DeploymentUnitOutlined', label: DeploymentUnitOutlined },
  { value: 'DragOutlined', label: DragOutlined },
  { value: 'EnvironmentOutlined', label: EnvironmentOutlined },
  { value: 'ExclamationCircleOutlined', label: ExclamationCircleOutlined },
  { value: 'GatewayOutlined', label: GatewayOutlined },
  { value: 'HeatMapOutlined', label: HeatMapOutlined },
  { value: 'PicCenterOutlined', label: PicCenterOutlined },
  { value: 'RadarChartOutlined', label: RadarChartOutlined },
  { value: 'RadiusSettingOutlined', label: RadiusSettingOutlined },
  { value: 'StarOutlined', label: StarOutlined },
];

export default function IconPicker({
  isDisplay,
  value,
  onChange,
  defaultValue = 'AntDesignOutlined',
}) {
  const { isToggled, toggle } = useToggle();

  const SelectedIcon = useMemo(() => IconValueMap[value] ?? IconValueMap[defaultValue], [value]);

  const onIconSelect = useCallback((newIcon) => {
    onChange(newIcon);
    toggle();
  }, [onChange, toggle]);

  const popoverContent = useMemo(() => (
    <IconPickerModal
      icons={Icons}
      onSelect={onIconSelect}
      selectedIcon={value}
      defaultIcon={defaultValue}
    />
  ), [value, defaultValue, onIconSelect]);

  return (
    <Popover
      content={popoverContent}
      trigger="click"
      open={isToggled}
    >
      <BorderlessButton
        style={{ width: 50, background: 'transparent' }}
        iconNode={(
          <SelectedIcon
            style={{
              fontSize: 24,
              color: 'black',
            }}
          />
        )}
        disabled={isDisplay}
        onClick={toggle}
      />
    </Popover>
  );
}

IconPicker.propTypes = {
  isDisplay: PropTypes.bool,
  value: PropTypes.string,
  onChange: PropTypes.func,
  defaultValue: PropTypes.string,
};

IconPicker.defaultProps = {
  isDisplay: false,
  value: null,
  onChange: () => {},
  defaultValue: 'AntDesignOutlined',
};
