import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Popover, Tag } from 'antd';
import {
  CheckCircleFilled,
  ExclamationCircleFilled,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import colors from '../../constants/Colors';

const getIcon = (email) => {
  const {
    allow = 1,
  } = email ?? {};
  switch (allow) {
    case 0: return {
      iconNode: (
        <ExclamationCircleFilled
          style={{ color: colors.ONTRACCR_RED }}
          className="workflow-email-icon"
        />
      ),
      popoverContent: (
        <div style={{ width: 250 }}>
          This person has
          {' '}
          <b style={{ color: colors.ONTRACCR_RED }}>unsubscribed</b>
          {' '}
          from Ontraccr emails.
        </div>
      ),
    };
    case 1:
    default:
      return {
        iconNode: (
          <CheckCircleFilled
            style={{ color: 'forestgreen' }}
            className="workflow-email-icon"
          />
        ),
      };
  }
};

export default function WorkflowTag({
  itemProps,
  label,
  closable,
  type,
}) {
  const emails = useSelector((state) => state.emails);
  const icon = useMemo(() => {
    if (type !== 'email' || typeof label !== 'string' || !label.includes('@')) return null;
    const {
      [label]: email,
    } = emails;
    const { iconNode, popoverContent } = getIcon(email);
    if (!popoverContent) return iconNode;
    return (
      <Popover
        content={popoverContent}
      >
        {iconNode}
      </Popover>
    );
  }, [type, label, emails]);
  return (
    <Tag
      {...itemProps} // eslint-disable-line react/jsx-props-no-spreading
      closable={closable}
      icon={icon}
    >
      <div className="workflow-email-tag-label">
        {label}
      </div>
    </Tag>
  );
}

WorkflowTag.propTypes = {
  itemProps: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  label: PropTypes.string.isRequired,
  closable: PropTypes.bool,
  type: PropTypes.string.isRequired,
};

WorkflowTag.defaultProps = {
  itemProps: {},
  closable: false,
};
