import React, { useMemo } from 'react';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import DisplayText from '../text/DisplayText';
import { getIdMap } from '../../helpers/helpers';

const { Option } = Select;

export default function ProjectApproverSelector({
  onChange,
  selectedApprover,
  isFirst,
  showLabel,
  isNotDisplay,
  style,
}) {
  const positionNames = useSelector((state) => state.settings.positionNames);
  const approverPositions = useSelector((state) => state.settings.approverPositions);
  const users = useSelector((state) => state.users.users);

  const usersMap = useMemo(() => getIdMap(users), [users]);
  const positionMap = useMemo(() => getIdMap(positionNames), [positionNames]);

  const validApproverPositions = useMemo(() => (
    positionNames?.filter(({ name: positionName }) => approverPositions?.has(positionName)) ?? []
  ), [positionNames, approverPositions]);

  const validApproverUsers = useMemo(() => (
    users?.filter(({ position }) => approverPositions?.has(position)) ?? []
  ), [users, approverPositions]);

  const isUser = useMemo(() => typeof selectedApprover === 'string' && selectedApprover.length === 36, [selectedApprover]);

  const selectedApproverLabel = useMemo(() => {
    if (isUser) return usersMap[selectedApprover]?.name;

    const positionLabel = positionMap[selectedApprover]?.name ? `Any ${positionMap[selectedApprover].name}` : undefined;

    return positionLabel;
  }, [selectedApprover, usersMap, positionMap, isUser]);

  return (
    <>
      {showLabel && (
        <Row style={{ marginTop: 5 }}>
          Select
          {' '}
          {isFirst ? 'First' : 'Second'}
          {' '}
          Approver:
        </Row>
      )}
      <Row style={style}>
        {isNotDisplay ? (
          <Select
            placeholder={selectedApproverLabel || 'Select Approver/Group'}
            onChange={onChange}
            value={isUser || !selectedApprover ? selectedApprover : Number(selectedApprover)}
            showSearch
            optionFilterProp="label"
          >
            <Option key={isFirst ? 'no1apr' : 'no2apr'} value={null} label="None">None</Option>
            {validApproverPositions.map((p) => (
              <Option key={`${p.id}${isFirst ? '1' : '2'}`} value={p.id} label={p.name}>
                Any
                {' '}
                {p.name}
              </Option>
            ))}
            {validApproverUsers.map((u) => (
              <Option key={`${u.id}${isFirst ? '1' : '2'}`} value={u.id} label={u.name}>
                {u.name}
              </Option>
            ))}
          </Select>
        ) : <DisplayText title={selectedApproverLabel || 'Not Set'} /> }
      </Row>
    </>
  );
}

ProjectApproverSelector.propTypes = {
  onChange: PropTypes.func.isRequired,
  selectedApprover: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isFirst: PropTypes.bool,
  showLabel: PropTypes.bool,
  isNotDisplay: PropTypes.bool,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

ProjectApproverSelector.defaultProps = {
  selectedApprover: null,
  isFirst: false,
  showLabel: false,
  isNotDisplay: false,
  style: {},
};
