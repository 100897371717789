import axios from 'axios';

import { request, archive } from '../../helpers/requests';

const TeamsService = {
  getAll: async () => request({
    call: axios.get('/teams'),
    errMsg: 'Could not get teams',
    defaultData: [],
    hideSuccessToast: true,
  }),
  create: async (team) => request({
    call: axios.post('/teams', team),
    errMsg: 'Could not create team',
  }),
  update: async (id, payload) => request({
    call: axios.put(`/teams/${id}`, payload),
    errMsg: 'Could not update team',
  }),
  archive: async (id, active = false) => archive({
    id, active, type: 'teams',
  }),
  delete: async (id) => request({
    call: axios.delete(`/teams/${id}`),
    errMsg: 'Could not delete team',
  }),
};

export default TeamsService;
