import React, { useCallback, } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'antd';
import {
  PlayCircleOutlined,
} from '@ant-design/icons';

import LearningCenterService from './state/learningCenter.service';

import { createNuxEntry } from '../nux/state/nux.actions';
import { TUTORIAL_WATCH_PREFIX } from '../nux/nux.constants';

export default ({
    tutorial
}) => {
  const dispatch = useDispatch();

  const getTutorial = useCallback((type) => () => {
    dispatch(createNuxEntry(`${TUTORIAL_WATCH_PREFIX}${type}`));
    LearningCenterService.getTutorial(type)
  });

  return (
    <Button className='learning-center-button' 
    shape='circle' icon={<PlayCircleOutlined/>} 
    onClick={getTutorial(tutorial)} size='large'>
    </Button>
  );
}