import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Select } from 'antd';
import { FormOutlined, SettingOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import { updateData } from './workflowHelpers';
import UserAssignmentSelector from './selectors/UserAssignmentSelector';
import { getBoardDetails } from '../../boards/state/boards.actions';
import FormColorPicker from '../../common/inputs/FormColorPicker';

const { Option } = Select;

const getCardFields = (cardTemplate) => {
  const {
    fields: sections,
  } = cardTemplate ?? {};
  const cardFields = [];
  cardFields.push({
    key: 'title', text: 'Title', type: 'text', dataType: undefined,
  });
  sections?.forEach((section) => {
    const { fields, name: sectionName } = section;
    fields?.forEach((field) => {
      if (!field?.id || !field?.configProps) return;
      cardFields.push({
        key: field.id,
        text: `${sectionName} - ${field.configProps.title}`,
        type: field.selectedType,
        dataType: field.configProps.dataType,
      });
    });
  });
  return cardFields;
};

const requiredFields = new Set(['title']);
const HOVER_TEXT = 'This step can create a board card based on the fields submitted.';
const DISABLED_TEXT = 'Add at least one Text Field to enable this step';
const AUTHOR_HELP = 'The card will be assigned to the form\'s author';

const LINK_TYPE_MAP = {
  Projects: 'projectId',
  Customers: 'customerId',
  Materials: 'materialId',
  Users: 'userId',
  Equipment: 'equipmentId',
};

export default function WorkflowCreateBoardCardNode({
  isDisplay,
  setElements,
  setDataMap,
  divisionId,
  name,
  sections = [],
  isExternalForm,
} = {}) {
  return function _({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const { t } = useTranslation();

    const {
      fieldMappings: initialFieldMappings = {},
      board: initialBoard,
      status: initialStatus,
      cardColor: initialCardColor = '#FFFFFFFF',
      users: initialUsers = [],
      cardTypeFields: initialCardTypeFields = [],
      cardTypeId: initialCardTypeId,
      linkField: initialLinkField,
    } = data;

    const title = `Create Board Card${disabled ? ' - DISABLED' : ''}`;

    const boards = useSelector((state) => state.boards.boards);
    const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);
    const cardTemplates = useSelector((state) => state.boards.cardTemplates);
    const dispatch = useDispatch();
    const [showDrawer, setShowDrawer] = useState(false);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [selectedBoard, setSelectedBoard] = useState(initialBoard);
    const [selectedStatus, setSelectedStatus] = useState(initialStatus);
    const [selectedCardColor, setSelectedCardColor] = useState(initialCardColor);
    const [selectedUsers, setSelectedUsers] = useState(initialUsers);
    const [mappedCardFields, setMappedCardFields] = useState([]);
    const [cardTypeFields, setCardTypeFields] = useState(initialCardTypeFields);
    const [cardTypeId, setCardTypeId] = useState(initialCardTypeId);
    const [linkField, setLinkField] = useState(initialLinkField);

    const openDrawer = useCallback(() => setShowDrawer(true), []);
    const closeDrawer = useCallback(() => setShowDrawer(false), []);

    const onFieldMappingsChange = useCallback((newMappings) => {
      if (!setDataMap || !id) return;
      setFieldMappings(newMappings);
      setDataMap(updateData(id, { fieldMappings: newMappings }));
    }, [setDataMap, id]);

    const onBoardChange = useCallback((newBoard) => {
      if (!setDataMap || !id) return;
      setSelectedBoard(newBoard);
      setDataMap(updateData(id, { board: newBoard }));
    }, [setDataMap, id]);

    const onStatusChange = useCallback((newStatus) => {
      if (!setDataMap || !id) return;
      setSelectedStatus(newStatus);
      setDataMap(updateData(id, { status: newStatus }));
    }, [setDataMap, id]);

    const onColorChange = useCallback((newColor) => {
      if (!setDataMap || !id) return;
      setSelectedCardColor(newColor);
      setDataMap(updateData(id, { cardColor: newColor }));
    }, [setDataMap, id]);

    const onLinkFieldChange = useCallback((newLinkFieldId, newLinkField) => {
      if (!setDataMap || !id) return;
      if (!newLinkField || !(newLinkField.dataType in LINK_TYPE_MAP)) {
        setLinkField();
        setDataMap(updateData(id, { linkField: null, linkFieldType: null }));
      } else {
        setLinkField(newLinkFieldId);
        setDataMap(updateData(id, {
          linkField: newLinkFieldId,
          linkFieldType: LINK_TYPE_MAP[newLinkField.dataType],
        }));
      }
    }, [setDataMap, id]);

    const onUsersChange = useCallback((newSelectedUsers) => {
      setDataMap((dataMap) => ({
        ...dataMap,
        [id]: { ...dataMap[id], users: newSelectedUsers },
      }));
      setSelectedUsers(newSelectedUsers);
    }, [id]);

    const mappedBoards = useMemo(() => {
      const options = Object.keys(boards).map((boardId) => (
        <Option key={boardId} value={boardId} label={boards[boardId].title}>
          {boards[boardId].title}
        </Option>
      ));
      return options;
    }, [boards]);

    useEffect(() => {
      if (selectedBoard) {
        dispatch(getBoardDetails(selectedBoard, false));
        const selectedCardType = boards[selectedBoard]?.cardTypeId;
        if (selectedCardType) {
          setCardTypeId(selectedCardType);
          setMappedCardFields(getCardFields(cardTemplates[selectedCardType]));
          setCardTypeFields(cardTemplates[selectedCardType]?.fields);
        }
      }
    }, [selectedBoard]);

    useEffect(() => {
      if (!setDataMap || !id) return;
      setDataMap(updateData(id, { cardTypeId }));
    }, [cardTypeId]);

    useEffect(() => {
      if (!setDataMap || !id) return;
      setDataMap(updateData(id, { cardTypeFields }));
    }, [cardTypeFields]);

    useEffect(() => {
      if (!setDataMap || !id) return;
      setDataMap(updateData(id, { linkField }));
    }, [linkField]);

    const mappedStatuses = useMemo(() => {
      const {
        [selectedBoard]: boardDetails,
      } = boardDetailsMap;
      if (!boardDetails?.statuses) return [];
      const options = boardDetails.statuses?.map?.((status) => (
        <Option key={status.id} value={status.id} label={status.title}>
          {status.title}
        </Option>
      )) ?? [];
      if (!boardDetails.statuses?.some?.((status) => status.id === selectedStatus)) {
        onStatusChange(undefined);
      }

      return options;
    }, [boardDetailsMap, selectedBoard, selectedStatus, onStatusChange]);

    const sourceSections = useMemo(() => (
      [{
        name: 'Card Data',
        fields: [{
          id: 'cardNumber',
          configProps: {
            title: 'Card Number',
          },
          selectedType: 'text',
        }],
      }].concat(sections)
    ), [sections]);

    const linkSections = useMemo(() => {
      const ls = [];
      sections.forEach(({ name: sectionName, fields = [] }) => {
        fields.forEach((field) => {
          const {
            configProps: {
              title: fieldName,
              dataType,
            } = {},
            selectedType,
            id: fieldId,
          } = field;
          if (selectedType !== 'dropdown' || !(dataType in LINK_TYPE_MAP)) return;
          ls.push({
            value: fieldId,
            label: `${sectionName} - ${fieldName}`,
            dataType,
          });
        });
      });
      return ls;
    }, [sections]);

    return (
      <WorkflowActionNode
        id={id}
        title={title}
        type="createBoardCard"
        Icon={FormOutlined}
        draggable={draggable}
        isDisplay={isDisplay || disabled}
        onNodeUpdate={setElements}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
      >
        {!draggable
          && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Board')}
              {' '}
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onBoardChange}
                value={selectedBoard}
                showSearch
                optionFilterProp="label"
              >
                {mappedBoards}
              </Select>
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Status')}
              {' '}
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onStatusChange}
                value={selectedStatus}
                showSearch
                disabled={!selectedBoard}
              >
                {mappedStatuses}
              </Select>
            </Row>
            <Row style={{ marginTop: 10 }}>
              Select
              {' '}
              {t('Card Color')}
              {' '}
            </Row>
            <FormColorPicker isNotDisplay value={selectedCardColor} onChange={onColorChange} />
            <UserAssignmentSelector
              text="Assign user(s):"
              type="user"
              isDisplay={isDisplay}
              draggable={draggable}
              divisions={[divisionId]}
              onChange={onUsersChange}
              selected={selectedUsers}
              showFields
              sections={sections}
              showFormAuthor={!isExternalForm}
              formAuthorHelperText={AUTHOR_HELP}
            />
            <Row style={{ marginTop: 10 }}>
              Select Link Field
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                onChange={onLinkFieldChange}
                value={linkField}
                showSearch
                options={linkSections}
                dropdownMatchSelectWidth={false}
                allowClear
              />
            </Row>
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={openDrawer}
              />
            </Row>
            <WorkflowSimpleMappingConfigureDrawer
              id={id}
              visible={showDrawer}
              onClose={closeDrawer}
              onSubmit={closeDrawer}
              sourceName={name}
              sourceSections={sourceSections}
              fieldMappings={fieldMappings}
              onFieldMappingsChange={onFieldMappingsChange}
              data={data}
              setDataMap={setDataMap}
              fields={mappedCardFields}
              requiredFields={requiredFields}
            />
            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
          )}
      </WorkflowActionNode>
    );
  };
}

WorkflowCreateBoardCardNode.propTypes = {
  isDisplay: PropTypes.bool,
  setElements: PropTypes.func,
  setDataMap: PropTypes.func,
  divisionId: PropTypes.string,
  name: PropTypes.string,
  sections: PropTypes.arrayOf(PropTypes.string),
  isExternalForm: PropTypes.bool,
};
