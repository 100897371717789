import React, {
  useCallback, useState, useEffect, useRef, useMemo,
} from 'react';
import { DateTime } from 'luxon';
import { PropTypes } from 'prop-types';

import ScheduleDay from '../ScheduleDay';
import DailyScheduleHeader from './DailyScheduleHeader';

import { HOUR_HEIGHT, INITIAL_SCALE } from '../schedule.constants';
import {
  splitMultiDayShifts,
} from '../scheduleHelpers';

export default function DailySchedule({
  day,
  onShiftCreate,
  newShift,
  onNewShiftChange,
  onShiftSelect,
  editShift,
  onShiftEdit,
  shifts = [],
}) {
  const scrollRef = useRef(null);
  const [scrollTop, setScrollTop] = useState(0);
  const onScroll = useCallback((e) => {
    const {
      target: {
        scrollTop: targetTop,
      } = {},
    } = e;
    setScrollTop(targetTop);
  }, []);

  const onAllDayClick = useCallback((e) => {
    e.stopPropagation();
    const startTime = day.startOf('day').toMillis();
    const endTime = day.endOf('day').toMillis();
    const allDayShift = {
      id: DateTime.local().toMillis(),
      day,
      startTime,
      endTime,
    };
    onShiftCreate(allDayShift);
    onNewShiftChange(allDayShift);
  }, [day, onShiftCreate, onNewShiftChange]);

  useEffect(() => {
    scrollRef.current.scrollTo({
      top: HOUR_HEIGHT * 7 * INITIAL_SCALE,
    });
  }, []);

  const {
    multiDay: allDayShifts,
    interDay: interDayShifts,
  } = useMemo(() => splitMultiDayShifts(shifts, day), [shifts, day]);

  return (
    <div
      className="schedule-view-container"
      onScroll={onScroll}
      ref={scrollRef}
    >
      <ScheduleDay
        key="daily"
        day={day}
        onShiftCreate={onShiftCreate}
        newShift={newShift}
        onNewShiftChange={onNewShiftChange}
        onShiftSelect={onShiftSelect}
        editShift={editShift}
        onShiftEdit={onShiftEdit}
        scrollTop={scrollTop}
        shifts={interDayShifts}
      />
      <DailyScheduleHeader
        onClick={onAllDayClick}
        shifts={allDayShifts}
        onShiftSelect={onShiftSelect}
        onShiftEdit={onShiftEdit}
      />
    </div>
  );
}

DailySchedule.propTypes = {
  day: PropTypes.instanceOf(DateTime).isRequired,
  onShiftCreate: PropTypes.func.isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onShiftEdit: PropTypes.func.isRequired,
  onNewShiftChange: PropTypes.func.isRequired,
  newShift: PropTypes.shape({}),
  editShift: PropTypes.shape({}),
  shifts: PropTypes.arrayOf(PropTypes.shape({})),
};

DailySchedule.defaultProps = {
  shifts: [],
  newShift: null,
  editShift: null,
};
