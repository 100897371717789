export default {};

export const timeKeyToTitle = {
  regularTime: 'Regular',
  breakTime: 'Break',
  overtime: 'Overtime',
  doubleOT: 'Double OT',
};

export const timeKeyToDBKey = {
  regularTime: '',
  breakTime: 'break',
  overtime: 'ot',
  doubleOT: 'doubleOT',
};
