import { message } from 'antd';
import { DateTime } from 'luxon';
import pdfMake from 'pdfmake';
import { getBase64ImageFromURL } from '../../../projects/ProjectScheduleOfValues/PDF/sov.pdf.helpers';
import pdfFonts from 'pdfmake/build/vfs_fonts';

pdfMake.vfs = pdfFonts.pdfMake.vfs;

// TYPE DEFINITIONS:
// ------------------------------------------------------
/**
 * @typedef {Object | string} TableCell
 * @property {number | undefined} colSpan
 * @property {string | undefined} text
 * @property {string | undefined} bold
 * @property {string | undefined} fillColor
 * @property {string | undefined} fillOpacity
 * @property {boolean[] | undefined} border - [left, top, right, bottom]
 */

// CONSTANTS:
// ------------------------------------------------------
const DEFAULT_LOGO_STYLE = {
  width: 120,
  maxWidth: 120,
  maxHeight: 80,
  margin: [8, 0, 0, 5],
};

const DEFAULT_STYLES = {
  header: {
    fontSize: 11,
    bold: true,
  },
  subheader: {
    fontSize: 9,
    bold: true,
  },
  tableHeader: {
    alignment: 'center',
    fillColor: '#eeeeee',
    fontSize: 9,
    bold: true,
  },
  table: {
    margin: [0, 10, 0, 0],
    alignment: 'center',
    fontSize: 8,
    bold: true,
  },
};

// ------------------------------------------------------
/** Handles PDF Creation and Export */
export default class PDFTableExport {
  /**
   * Constructor
   * @param {object} styles
   * @param {object} globalStyles
   */
  constructor({
    styles = {},
    globalStyles = {},
  } = {}) {
    this.styles = styles;
    this.globalStyles = globalStyles;
  }

  /**
   * Gets the time range label
   * @param {number} start - millis
   * @param {number} end - millis
   * @returns {string}
   */
  static getTimeRangeLabel(start = 0, end = 0) {
    try {
      const startLabel = DateTime.fromMillis(start).toLocaleString(DateTime.DATE_SHORT);
      const endLabel = DateTime.fromMillis(end).toLocaleString(DateTime.DATE_SHORT);
      return `${startLabel} - ${endLabel}`;
    } catch (error) {
      return '';
    }
  }

  /**
   * If there is an company image associated with the URL return pdf image entry containing image,
   * else return empty pdf entry
   * @param {string} imageURL
   * @param {object} styles
   * @returns {Promise<Object>} pdf image entry
   */
  static async getCompanyLogo(imageURL, styles = DEFAULT_LOGO_STYLE) {
    let image;
    try {
      image = await getBase64ImageFromURL(imageURL);
    } catch (error) {
      // pass
    }

    if (!image) return { text: '' };
    return { image, ...styles };
  }

  /**
   * Creates and returns a PDF column entry
   * @param {string} type
   * @param {string | string[]} seperator
   * @param {string} color - color of column items
   * @param {string} markedColor - color of label (e.g. bullet point, numbering)
   * @param {boolean} useOrderedList
   * @param {array} content
   * @returns {object}
   */
  static createColumnList({
    type = 'none',
    seperator,
    color,
    markedColor,
    useOrderedList = false,
    content = [],
  }) {
    const pdfColumn = {
      type,
    };
    if (seperator) pdfColumn.seperator = seperator;
    if (color) pdfColumn.color = color;
    if (markedColor) pdfColumn.markedColor = markedColor;
    if (useOrderedList) {
      pdfColumn.ol = content;
    } else {
      pdfColumn.ul = content;
    }
    return pdfColumn;
  }

  /**
   * Creates and returns a PDF header
   * @param {string} style
   * @param {string} alignment
   * @param {array} columns
   * @param {boolean} useLogo
   * @param {object} logoEntry
   * @returns {array} header components
   */
  static createHeader({
    style = 'header',
    alignment = 'justify',
    columns = [],
    useLogo,
    logoEntry = { text: '' },
  }) {
    const headerComponents = [];
    if (useLogo && logoEntry) headerComponents.push(logoEntry);
    headerComponents.push({
      style,
      alignment,
      columns,
    });
    return headerComponents;
  }

  /**
   * Creates and returns a PDF table title text
   * @param {string} text
   * @returns {object}
   */
  static createTableTitle(text) {
    return {
      text,
      style: {
        fontSize: 12,
        bold: true,
      },
      margin: [0, 10, 0, 0],
      alignment: 'left',
    };
  }

  /**
   * Creates and returns a PDF table entry
   * @param {string} layout
   * @param {string} style
   * @param {array} widths - table column width e.g. [100, '*', 'auto']
   * @param {number} headerRows - number of rows considered part of header (will appear at top of every page)
   * @param {TableCell[]} header
   * @param {TableCell[][]} rows
   * @returns {object}
   */
  static createTable({
    layout = 'headerLineOnly',
    style = 'table',
    margin = [0, 5, 0, 0],
    widths,
    headerRows = 1,
    header = [],
    rows = [],
  }) {
    const defaultWidths = new Array (header.length).fill('*');
    const tablePDFEntry = {
      layout,
      style,
      table: {
        headerRows,
        margin,
        widths: widths || defaultWidths,
        body: [
          header,
          ...rows,
        ],
      },
    };
    return tablePDFEntry;
  }

  /**
   * Generates PDF export
   * @param {string} name - export file name
   * @param {object[]} header
   * @param {object[]} body
   * @param {string} pageOrientation
   * @param {string} pageSize
   */
  export({
    name,
    header = [],
    body = [],
    pageOrientation = 'portrait',
    pageSize = 'A4',
  }) {
    try {
      const content = [];
      if (Array.isArray(header) && header.length > 0) header.forEach((component) => content.push(component));
      if (Array.isArray(body) && body.length > 0) body.forEach((section) => content.push(section));
      const docDefinition = {
        content,
        pageOrientation,
        pageSize,
        styles: {
          ...DEFAULT_STYLES,
          ...this.styles,
        },
        defaultStyle: {
          ...this.globalStyles,
        },
      };
      pdfMake.createPdf(docDefinition).download(name);
    } catch (error) {
      message.error('Unable to export PDF');
    }
  }
}
