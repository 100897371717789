import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Row,
  Col,
  Typography,
  Popover,
} from 'antd';
import { ExclamationOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import './TimeCard.css';
import Scroller from './Scroller';

import ListCard from '../../common/ListCard';
import colors from '../../constants/Colors';

export default function TimeCard({
  day,
  onClick,
  roundingType,
  roundingInterval,
  roundingSetting,
  onEditClicked,
  isApprovals,
  userId,
}) {
  const {
    dayTasks = [],
    dayHistory = [],
  } = day;
  const hasViolation = dayTasks.some((task) => task.geofenceViolation);
  const hasOutsideWorkingHours = dayTasks.some((task) => task.outsideWorkingHours);
  const isAutoRounded = dayTasks.some((task) => task.isAutoRounded);


  const latestTimeCardEvent = useMemo(() => (
    dayHistory[dayHistory.length - 1] ?? {}
  ), [dayHistory]);

  const isRecentlyRejected = useMemo(() => (
    latestTimeCardEvent?.type === 'rejected'
  ), [latestTimeCardEvent]);

  const popoverContent = useMemo(() => {
    if (
      !isRecentlyRejected
      && !hasViolation
      && !hasOutsideWorkingHours
      && !isAutoRounded
    ) return null;
    const { timestamp = 0, note } = latestTimeCardEvent;
    const date = DateTime.fromMillis(timestamp).toLocaleString(DateTime.DATETIME_MED);
    const noteMsg = note ? ` with a note: ${note}` : '.';

    return (
      <div style={{ width: 300 }}>
        {isRecentlyRejected && (
          <div style={{ marginBottom: hasViolation && 20 }}>
            <Row>
              <b>{`Timecard Rejected - ${date}`}</b>
            </Row>
            <Row>
              {`This Timecard was rejected${noteMsg}`}
            </Row>
          </div>
        )}
        {hasViolation && (
          <div>
            <Row>
              <b>Geofence Breach:</b>
            </Row>
            <Row>
              This Timecard has a Geofence Breach.
            </Row>
          </div>
        )}
        {hasOutsideWorkingHours && (
          <div>
            <Row>
              This Timecard is outside working hours
            </Row>
          </div>
        )}
        { !!isAutoRounded && (
          <div>
            <Row>
              This Timecard has been auto-rounded
            </Row>
          </div>
        )}
      </div>
    );
  }, [
    hasViolation,
    isRecentlyRejected,
    hasOutsideWorkingHours,
    latestTimeCardEvent,
    isAutoRounded,
  ]);

  return (
    <ListCard
      style={{
        width: '11rem',
        height: isApprovals ? '19.5rem' : '16.88rem',
        border: day.submittable ? '2px #BF381D solid' : '1px #000 solid',
      }}
      bodyStyle={{ height: '100%' }}
      onClick={() => onClick({
        day,
      })}
    >
      <Row
        style={{
          height: '30px',
          minWidth: '100%',
          paddingBottom: 0,
          paddingTop: 6,
          overflowWrap: 'anywhere',
        }}
        type="flex"
        justify="space-between"
      >
        <Col span={18}>
          <Row style={{ width: '100%', lineHeight: '9px' }}>
            <Typography.Text
              ellipsis
              style={{
                width: '100%',
                fontFamily: 'raleway-medium',
                fontSize: 10,
                color: colors.ONTRACCR_BLACK,
              }}
            >
              {`${day.weekday},`}
            </Typography.Text>
          </Row>
          <Row style={{ width: '100%', lineHeight: '9px' }}>
            <Typography.Text
              ellipsis
              style={{
                width: '100%',
                fontFamily: 'raleway-medium',
                fontSize: 10,
                color: colors.ONTRACCR_BLACK,
              }}
            >
              {`${day.month} ${day.dayOfMonth}`}
            </Typography.Text>
          </Row>
        </Col>
        <Col span={6}>
          <Typography.Text
            ellipsis
            style={{
              width: '100%',
              fontFamily: 'raleway-medium',
              fontSize: 10,
              color: colors.ONTRACCR_BLACK,
              textAlign: 'right',
            }}
          >
            {day.year}
          </Typography.Text>
        </Col>
      </Row>

      <Row
        style={{
          minHeight: 100,
          alignContent: 'center',
        }}
        type="flex"
      >
        <Scroller
          day={day}
          onEditClicked={onEditClicked}
          roundingType={roundingType}
          roundingInterval={roundingInterval}
          roundingSetting={roundingSetting}
          isApprovals={isApprovals}
          userId={userId}
        />
      </Row>
      {(hasViolation || isRecentlyRejected || hasOutsideWorkingHours || isAutoRounded) && (
        <Popover
          trigger="hover"
          content={popoverContent}
          placement="topRight"
        >
          <ExclamationOutlined
            style={{
              position: 'absolute',
              right: 3,
              top: 3,
              color: 'white',
              backgroundColor: colors.ONTRACCR_RED,
              padding: 1,
              height: 12,
              width: 12,
              borderRadius: 6,
              fontSize: 10,
            }}
          />
        </Popover>
      )}
    </ListCard>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCard.propTypes = {
  day: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  roundingType: PropTypes.string,
  roundingInterval: PropTypes.number.isRequired,
  roundingSetting: PropTypes.string,
  onEditClicked: PropTypes.func.isRequired,
  isApprovals: PropTypes.bool,
  userId: PropTypes.string.isRequired,
};

TimeCard.defaultProps = {
  roundingType: null,
  isApprovals: false,
  roundingSetting: undefined,
};
