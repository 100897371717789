import {
  CREATE_STATUS,
  DELETE_STATUS,
  UPDATE_STATUS,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import StatusService from './statuses.service';

export default {};

export const createStatus = (type, payload) => async (dispatch) => {
  Analytics.track('Status/Create');
  const { data } = await StatusService.create(type, payload);
  if (!data) return false;
  dispatch({
    type: CREATE_STATUS,
    payload: {
      type,
      status: data,
    },
  });
  return true;
};

export const deleteStatus = (type, id) => async (dispatch) => {
  Analytics.track('Status/Delete');
  const { data } = await StatusService.delete(type, id);
  if (!data) return false;
  dispatch({
    type: DELETE_STATUS,
    payload: {
      type,
      id,
    },
  });
  return true;
};

export const updateStatus = (type, id, payload) => async (dispatch) => {
  Analytics.track('Status/Update');
  const { data } = await StatusService.update(type, id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_STATUS,
    payload: {
      ...payload,
      id,
      type,
    },
  });
  return true;
};
