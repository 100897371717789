import React, {
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Collapse, Table } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { getAllCostCodes, getCostcodeBillingData } from '../../costcodes/state/costcodes.actions';
import { currencyFormatter, getIdMap, includesTerm } from '../../helpers/helpers';

const { Panel } = Collapse;

export default function CostCodeBilling({
  searchValue = '',
  dateRange,
  onColumnsChanged,
  onFiltersChanged,
  onDataChanged,
  exporter,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const projects = useSelector((state) => state.projects.projects);
  const {
    costcodes = [],
    costcodeBillingData,
  } = useSelector((state) => state.costcodes);

  useEffect(() => {
    dispatch(getAllCostCodes());
  }, []);

  useEffect(() => {
    const [start, end] = dateRange;

    const query = {
      startTime: start.valueOf(),
      endTime: end.valueOf(),
    };

    dispatch(getCostcodeBillingData(query));
  }, [dateRange]);

  const decoratedProjects = useMemo(() => (
    projects.map((project) => ({
      ...project,
      ...(costcodeBillingData?.projects?.[project.id] ?? {}),
    }))
  ), [projects, costcodeBillingData]);

  const filteredProjects = useMemo(() => decoratedProjects.filter((project) => (
    includesTerm(project.name, searchValue)
    && project.active
  )), [decoratedProjects, searchValue]);

  const decoratedCostCodes = useMemo(() => (
    costcodes.map((costcode) => ({
      ...costcode,
      ...(costcodeBillingData?.costcodes?.[costcode.id] ?? {}),
    }))
  ), [costcodes, costcodeBillingData]);

  const formattedCostcodes = useMemo(() => (
    decoratedCostCodes.filter((cc) => cc.active)
  ), [decoratedCostCodes, searchValue]);

  const globalCostcodes = useMemo(() => formattedCostcodes.filter(
    (cc) => !cc.projectId
    && (includesTerm(cc.name, searchValue)
    || includesTerm(cc.code, searchValue)
    || includesTerm(cc.category, searchValue)),
  ), [formattedCostcodes]);

  /**
   * See: https://github.com/ant-design/ant-design/issues/13825#issuecomment-449889241
   * Issue with columns not respecting width when using expandable and long words
   */
  const projectColumns = useMemo(() => [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 250,
      render: (val) => (
        <div className="antd-table-cell-render">
          {val}
        </div>
      ),
    },
    {
      title: 'Billing Total',
      dataIndex: 'totalBilling',
      key: 'totalBilling',
      width: 150,
      render: (val) => (
        <div className="antd-table-cell-render">
          {currencyFormatter(val)}
        </div>
      ),
    },
    {
      title: 'Cost Total',
      dataIndex: 'totalCost',
      key: 'totalCost',
      width: 150,
      render: (val) => (
        <div className="antd-table-cell-render">
          {currencyFormatter(val)}
        </div>
      ),
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      width: 150,
      render: (_, record) => (
        <div className="antd-table-cell-render">
          {currencyFormatter(
            (record.totalBilling ?? 0) - (record.totalCost ?? 0),
          )}
        </div>
      ),
    },
  ], []);

  const costcodeColumns = useMemo(() => [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      width: 75,
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      width: 80,
    },
    {
      title: 'Category',
      dataIndex: 'category',
      key: 'category',
      width: 80,
    },
    {
      title: 'Billed Hours',
      dataIndex: 'totalBillingHours',
      key: 'totalBillingHours',
      width: 80,
      render: (val) => (
        <div className="antd-table-cell-render">
          {`${(val ?? 0).toFixed(2)} hours`}
        </div>
      ),
    },
    {
      title: 'Billed Days',
      dataIndex: 'totalBillingDays',
      key: 'totalBillingDays',
      width: 80,
      render: (val) => (
        <div className="antd-table-cell-render">
          {`${(val ?? 0).toFixed(2)} days`}
        </div>
      ),
    },
    {
      title: 'Billed Amount',
      dataIndex: 'totalBilling',
      key: 'totalBilling',
      width: 80,
      render: (val) => (
        <div className="antd-table-cell-render">
          {currencyFormatter(val)}
        </div>
      ),
    },
    {
      title: 'Cost Hours',
      dataIndex: 'totalCostHours',
      key: 'totalCostHours',
      width: 80,
      render: (val) => (
        <div className="antd-table-cell-render">
          {`${(val ?? 0).toFixed(2)} hours`}
        </div>
      ),
    },
    {
      title: 'Cost Days',
      dataIndex: 'totalCostDays',
      key: 'totalCostDays',
      width: 80,
      render: (val) => (
        <div className="antd-table-cell-render">
          {`${(val ?? 0).toFixed(2)} days`}
        </div>
      ),
    },
    {
      title: 'Cost Amount',
      dataIndex: 'totalCost',
      key: 'totalCost',
      width: 80,
      render: (val) => (
        <div className="antd-table-cell-render">
          {currencyFormatter(val)}
        </div>
      ),
    },
    {
      title: 'Profit',
      dataIndex: 'profit',
      key: 'profit',
      width: 80,
      render: (_, record) => (
        <div className="antd-table-cell-render">
          {currencyFormatter(
            (record.totalBilling ?? 0) - (record.totalCost ?? 0),
          )}
        </div>
      ),
    },
  ], []);

  useEffect(() => {
    if (!exporter) return false;

    exporter.setTopLevelColumns([projectColumns.map((column) => column.title)]);
    const projectExportRows = [];
    filteredProjects.forEach((project) => {
      const row = [
        project.name,
        currencyFormatter(project.billingTotal),
        currencyFormatter(project.runningTotal),
        currencyFormatter((project.billingTotal ?? 0) - (project.runningTotal ?? 0)),
      ];
      projectExportRows.push(row);
    });

    exporter.setTopLevelData(projectExportRows);
    exporter.updateSettings({
      hideBodyRow: true,
      hideSummaryRow: true,
    });

    return () => {
      exporter.resetTopLevel();
      exporter.updateSettings({
        hideBodyRow: false,
        hideSummaryRow: false,
      });
    };
  }, [exporter, projectColumns, filteredProjects]);

  useEffect(() => {
    onFiltersChanged({});
  }, [onFiltersChanged]);

  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  useEffect(() => {
    onColumnsChanged(['Project'].concat(costcodeColumns.map((column) => column.title)));
    onDataChanged(formattedCostcodes.map((costcode) => [
      projectMap[costcode.projectId]?.name ?? 'Global',
      costcode.code,
      costcode.name,
      costcode.category,
      `${(costcode.totalBillingHours ?? 0).toFixed(2)} hours`,
      `${(costcode.totalBillingDays ?? 0).toFixed(2)} days`,
      currencyFormatter(costcode.totalBilling),
      `${(costcode.totalCostHours ?? 0).toFixed(2)} hours`,
      currencyFormatter(costcode.totalCost),
      currencyFormatter((costcode.totalBilling ?? 0) - (costcode.totalCost ?? 0)),
    ]));
  }, [
    onColumnsChanged,
    onDataChanged,
    costcodeColumns,
    formattedCostcodes,
    projectMap,
  ]);

  const expandedRowRender = useCallback((project) => {
    const relevantCostcodes = formattedCostcodes.filter(
      (costcode) => costcode.projectId === project.id,
    );
    return (
      <Table
        columns={costcodeColumns}
        dataSource={relevantCostcodes}
        pagination={false}
        size="small"
      />
    );
  }, [costcodeColumns, formattedCostcodes]);

  return (
    <div style={{ width: '100%', height: 'calc(100% - 50px)', overflowY: 'scroll' }}>
      <Collapse defaultActiveKey={['project']}>
        <Panel header={`${t('Project')} Codes`} key="project">
          <Table
            columns={projectColumns}
            dataSource={filteredProjects}
            expandable={{ expandedRowRender }}
            rowKey="id"
            pagination={false}
            style={{ width: '100%' }}
          />
        </Panel>
        <Panel header="Global Codes" key="global">
          <Table
            columns={costcodeColumns}
            dataSource={globalCostcodes}
            rowKey="id"
            pagination={false}
            style={{ width: '100%' }}
          />
        </Panel>
      </Collapse>
    </div>
  );
}

CostCodeBilling.propTypes = {
  searchValue: PropTypes.string,
  dateRange: PropTypes.arrayOf(PropTypes.shape({})),
  exporter: PropTypes.shape({
    setTopLevelColumns: PropTypes.func,
    setTopLevelData: PropTypes.func,
    updateSettings: PropTypes.func,
    resetTopLevel: PropTypes.func,
  }),
};

CostCodeBilling.defaultProps = {
  searchValue: '',
  dateRange: [],
  exporter: null,
};
