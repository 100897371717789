import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Divider } from 'antd';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import CompanyEditRow from '../../CompanyEditRow';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

import SageShiftMappingDrawer from './SageShiftMappingDrawer';

import sortByString from '../../../helpers/helpers';

import { deleteSageShift, getSageShifts } from '../../../sage/state/sage.actions';

import Colors from '../../../constants/Colors';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

export default function SageShiftMapping({
  selectedIntegration,
}) {
  const dispatch = useDispatch();

  const sageShifts = useSelector((state) => state.sage.shifts);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedShift, setSelectedShift] = useState();

  const showDrawer = useCallback(() => setDrawerOpen(true), []);
  const hideDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedShift();
  }, []);

  const onEditClicked = useCallback((item) => () => {
    setSelectedShift(item);
    setDrawerOpen(true);
  }, []);

  const onDeleteClicked = useCallback((item) => () => {
    CustomConfirmModal({
      title: `Delete ${item.name}?`,
      okText: 'Delete',
      onOk: () => dispatch(deleteSageShift(item.id)),
    });
  }, [dispatch]);

  useEffect(() => {
    if (selectedIntegration?.id) dispatch(getSageShifts({ integrationId: selectedIntegration?.id }));
  }, [selectedIntegration]);

  const data = useMemo(() => {
    const shiftArray = Object.values(sageShifts);
    shiftArray.sort(sortByString('name'));
    return shiftArray;
  }, [sageShifts]);
  const columns = useMemo(() => (
    [
      {
        title: 'Name',
        dataIndex: 'name',
        onHeaderCell,
      }, {
        title: 'Sage Shift ID',
        dataIndex: 'sageId',
        onHeaderCell,
      }, {
        title: '',
        dataIndex: 'edit',
        onHeaderCell,
        render: (_, item) => (
          <BorderlessButton iconNode={<EditOutlined />} onClick={onEditClicked(item)} />
        ),
      }, {
        title: '',
        dataIndex: 'delete',
        onHeaderCell,
        render: (_, item) => (
          <BorderlessButton iconNode={<DeleteOutlined style={{ color: 'red' }} />} onClick={onDeleteClicked(item)} />
        ),
      },
    ]
  ), [onEditClicked]);

  return (
    <>
      <div>
        <CompanyEditRow title="Sage Shifts" divider={false} style={{ marginRight: 54 }}>
          <OnTraccrButton
            title="Add"
            onClick={showDrawer}
          />
        </CompanyEditRow>
        <Table
          size="small"
          scroll={{ y: 400 }}
          columns={columns}
          dataSource={data}
          pagination={false}
          style={{ marginTop: 10, marginBottom: 1 }}
        />
        <Divider
          style={{
            margin: 0,
            backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
            maxWidth: 440,
            minWidth: 440,
          }}
        />
      </div>
      <SageShiftMappingDrawer
        visible={drawerOpen}
        onClose={hideDrawer}
        selectedShift={selectedShift}
        selectedIntegration={selectedIntegration}
      />
    </>
  );
}

SageShiftMapping.propTypes = {
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
};

SageShiftMapping.defaultProps = {
  selectedIntegration: {},
};
