import React, { useState } from 'react';
import {
  LockOutlined,
} from '@ant-design/icons';
import { Row, Form, Button } from 'antd';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';

import colors from '../constants/Colors';

const fieldWidth = 200;


const validatePasswordFormat = (rule, value) => {
  if(!value) return Promise.resolve();
  if (value.length < 8) return Promise.reject('Must be 8 or more characters');

  const regex = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/);
  if(!(regex.test(value))) {
    return Promise.reject(`Password must contain:
    one uppercase character,
    one lowercase character
    and one number`)
  }
  return Promise.resolve();
};

export default ({
  handleSubmit,
  buttonColor = colors.ONTRACCR_RED,
}) => {
  const [formRef,setFormRef] = useState();

  const compareToFirstPassword = (rule, value) => {
    if(!formRef) return;
    if (value && value !== formRef.getFieldValue('password')) {
      return Promise.reject('Passwords must match');
    } 
    return Promise.resolve();
  };

  return (
    <Form
      onFinish={handleSubmit} 
      ref={setFormRef}
      className="login-form"
      style={{
        maxWidth:'95%'
      }}
    >
      <Form.Item
        name='password'
        rules={[
          { required: true, message: 'Password is required'},
          { validator: validatePasswordFormat }
        ]}
      >
        <OnTraccrTextInput
          style={{ width: 20 + fieldWidth * 2,  maxWidth:'100%' }}
          password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="New Password"
        />

      </Form.Item>
      <Form.Item
        name='confirmPassword'
        rules={[
          { required: true, message: 'Confirm Password is required'},
          { validator: compareToFirstPassword }
        ]}
      >
        <OnTraccrTextInput
          style={{ width: 20 + fieldWidth * 2, maxWidth:'100%' }}
          password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Confirm Password"
        />

      </Form.Item>
    <Row justify='center'>
      <Button
        htmlType='submit'
        className='login-form-button'
        type='primary'
        style={{  
          marginTop:10,
          borderRadius:21,
          borderWidth:1.5,
          width: 140,
          height: 42,
          backgroundColor: buttonColor,
          borderColor: buttonColor,
        }}

      >
        Reset Password
      </Button>
    </Row>
  </Form>
  );
}