import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';
import PropTypes from 'prop-types';

import SideList from '../common/SideList';
import SideListSearchHeader from '../common/SideListSearchHeader';
import FilterDropdown from '../common/FilterDropdown';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import TeamsSideListItem from './TeamsSideListItem';

import {
  filterData,
} from './teamFilters';

import Permissions from '../auth/Permissions';

import { search } from '../helpers/helpers';

function TeamsSideList({
  onAddClicked,
  selectedTeam,
  onTeamSelected,
}) {
  const teams = useSelector((state) => state.teams.teams);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  const [activeFilters, setActiveFilters] = useState(new Set([1]));
  const [searchTerm, setSearchTerm] = useState();

  const onFilter = useCallback((checkedFilters) => {
    setActiveFilters(new Set(checkedFilters));
  }, []);

  const onSearch = useCallback((e) => {
    setSearchTerm(e?.target?.value);
  }, []);

  const filterIsAtDefault = activeFilters.size === 1 && activeFilters.has(1);

  const divTeams = useMemo(() => (
    teams.filter(({ divisionIds = [] } = {}) => (
      divisionIds?.some((id) => selectedDivisions.has(id))
    ))
  ), [teams, selectedDivisions]);

  const filteredTeams = useMemo(() => (
    search({
      value: searchTerm,
      data: divTeams.filter((team) => activeFilters.has(team.active ? 1 : 0)),
      getField: (team) => team.name,
    })
  ), [activeFilters, divTeams, searchTerm]);

  const selectedSet = useMemo(() => (
    selectedTeam
      ? new Set([selectedTeam])
      : new Set()
  ), [selectedTeam]);

  useEffect(() => {
    if (
      !selectedTeam
      || (selectedTeam && !divTeams.find((team) => team.id === selectedTeam.id))
    ) {
      onTeamSelected(divTeams?.[0]);
    }
  }, [divTeams, selectedTeam, onTeamSelected]);

  return (
    <SideList
      key="teamlist"
      id="teamlist"
      name="teamlist"
      header={(
        <SideListSearchHeader
          onClick={Permissions.has('TEAMS_WRITE') ? onAddClicked : null}
          onChange={onSearch}
          filterView={(
            <Popover
              trigger="click"
              placement="bottom"
              content={(
                <FilterDropdown
                  filters={[filterData]}
                  activeFilters={{ active: activeFilters }}
                  onFilter={onFilter}
                />
              )}
            >
              <OnTraccrButton
                title="Filter"
                type={filterIsAtDefault ? 'primary' : 'back'}
              />
            </Popover>
        )}
        />
      )}
      dataSource={filteredTeams}
      renderItem={TeamsSideListItem}
      onClick={onTeamSelected}
      selectedItems={selectedSet}
      rowHeight={30}
      scrollable
    />
  );
}

/* eslint-disable react/forbid-prop-types */
TeamsSideList.propTypes = {
  onAddClicked: PropTypes.func.isRequired,
  onTeamSelected: PropTypes.func.isRequired,
  selectedTeam: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    members: PropTypes.array,
  }),
};

TeamsSideList.defaultProps = {
  selectedTeam: null,
};

export default TeamsSideList;
