export default {};

export const PATH_MAP = {
  forms: '/exports/data/forms',
  timeEntries: '/exports/data/tasks',
};

export const fixedDecimalKeys = [
  'cost',
  'totalHours',
  'workHours',
  'breakHours',
  'regularHours',
  'otHours',
  'doubleOT',
  'saturdayTime',
  'saturdayOT',
  'saturdayDoubleOT',
  'sundayTime',
  'sundayOT',
  'sundayDoubleOT',
  'burdenCost',
  'calculatedWage',
  'user.wage',
  'costcodes.hourlywage',
  'class.hourlywage',
];

export const LINE_NUMBER_COLUMN = {
  title: '',
  render: (_1, _2, index) => index + 1,
  width: 50,
};
