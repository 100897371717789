import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select } from 'antd';

export default ({
  selected,
  onChange,
}) => {
  const formMappings = useSelector(state => state.boards.formMappings);

  const selectOptions = useMemo(() => (
    formMappings.map((mapping) => {
      const { id, name } = mapping;
      return (
      <Select.Option
        value={id}
        key={id}
        label={name}
      >
        {name}
      </Select.Option>
    )})
  ),[formMappings]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Select Form Mapping
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          style={{width:'100%'}}
          allowClear
          value={selected}
          dropdownMatchSelectWidth={false}
          onChange={onChange}
        >
          {selectOptions}
        </Select>
      </Row>
    </>
  );
}