import React from 'react';
import FilteredFormList from '../forms/FilteredFormList';

export default ({
  id,
  isNotDisplay,
  type,
  exclude,
  visible,
}) => (
  <FilteredFormList
    formType={type}
    exclude={exclude}
    filterId={id}
    filterType='Projects'
    isNotDisplay={isNotDisplay}
    topOffset={240}
    visible={visible}
  />
)
