import React, {
  useState, useEffect, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';

import PDFZoomControls, { zoomLevels } from '../pdf/PDFZoomControls';

const BASE_WIDTH = 1000;
const BASE_HEIGHT = 1000;

export default function PhotoPreview({
  url,
  file,
  rotation = 0,
  setRotation,
  initialZoom = 1,
  className,
}) {
  const [zoom, setZoom] = useState(initialZoom);
  const [loaded, setLoaded] = useState(false);
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 });

  const parentRef = useRef();

  useEffect(() => {
    setZoom(initialZoom);
    setLoaded(false);
  }, [url, file]);

  const resetOverflow = () => {
    if (!parentRef?.current) return;
    parentRef.current.style.overflow = 'hidden';
    setTimeout(() => {
      parentRef.current.style.overflow = 'auto';
    }, 50);
  };

  const getImageDimensions = (width, height) => {
    let imageWidth = width;
    let imageHeight = height;
    const ratio = imageWidth / imageHeight;

    if (height > BASE_HEIGHT) {
      imageHeight = BASE_HEIGHT;
      imageWidth = imageHeight * ratio;

      if (imageWidth > BASE_WIDTH) {
        imageWidth = BASE_WIDTH;
        imageHeight = imageWidth / ratio;
      }
    } else if (width > BASE_WIDTH) {
      imageWidth = BASE_WIDTH;
      imageHeight = imageWidth / ratio;

      if (imageHeight > BASE_HEIGHT) {
        imageHeight = BASE_HEIGHT;
        imageWidth = imageHeight * ratio;
      }
    }

    return {
      imageWidth,
      imageHeight,
    };
  };

  const onImageLoad = (e) => {
    const {
      clientHeight,
      clientWidth,
    } = parentRef?.current ?? {};
    const {
      naturalWidth,
      naturalHeight,
    } = e?.target ?? {};

    const {
      imageWidth,
      imageHeight,
    } = getImageDimensions(naturalWidth, naturalHeight);

    setImageDimensions({
      width: imageWidth,
      height: imageHeight,
    });
    if (!clientHeight || !clientWidth) {
      setLoaded(true);
      return;
    }
    const modH = imageHeight / clientHeight;
    const modW = imageWidth / clientWidth;
    const maxMod = Math.max(modH, modW);
    if (maxMod > 1) {
      const target = 1 / maxMod;
      if (target < zoomLevels[0]) {
        setZoom(zoomLevels[0]);
      } else {
        const appropriateZoom = zoomLevels.findLast((level) => (
          level < target
        ));
        setZoom(appropriateZoom ?? initialZoom);
      }
    }

    setLoaded(true);
  };

  useEffect(() => {
    resetOverflow();
  }, [rotation, zoom]);

  const src = useMemo(() => {
    if (url) return url;
    return file instanceof File ? URL.createObjectURL(file) : null;
  }, [url, file]);

  const wrapperStyle = useMemo(() => ({
    width: imageDimensions?.width ? imageDimensions.width * zoom : '100%',
    height: imageDimensions?.height ? imageDimensions.height * zoom : '100%',
    transformOrigin: rotation % 180 === 0 ? 'center' : 'right center',
    marginLeft: 'auto',
    marginRight: 'auto',
    transform: `rotate(${rotation}deg)`,
  }), [
    imageDimensions,
    zoom,
    rotation,
  ]);

  return (
    <div
      className="pdf-container basic-pdf"
      style={{
        display: 'block',
        overflow: 'auto',
      }}
      ref={parentRef}
    >

      <div style={wrapperStyle}>
        <img
          key={url}
          className="full-photo"
          src={src}
          onLoad={onImageLoad}
          alt="Full Page"
          style={{
            width: '100%',
            height: '100%',
            maxWidth: 'unset',
            maxHeight: 'unset',
            visibility: loaded ? 'visible' : 'hidden',
          }}
        />
      </div>
      <PDFZoomControls
        editable={false}
        annotationMode={false}
        onAnnotationModeChanged={null}
        zoom={zoom}
        onZoomChanged={setZoom}
        onRotateRight={() => setRotation((rotation + 90) % 360)}
        onRotateLeft={() => setRotation((rotation - 90) % 360)}
        onUndo={null}
        onSave={null}
        className={className}
        style={{
          zIndex: 1002,
        }}
      />
    </div>
  );
}

PhotoPreview.propTypes = {
  url: PropTypes.string,
  file: PropTypes.instanceOf(File),
  rotation: PropTypes.number,
  setRotation: PropTypes.func.isRequired,
  initialZoom: PropTypes.number,
  className: PropTypes.string,
};

PhotoPreview.defaultProps = {
  url: undefined,
  file: undefined,
  initialZoom: 1,
  rotation: 0,
  className: undefined,
};
