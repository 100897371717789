export default {};

export function replaceEmptyStrings(obj, repStr) {
  const objCpy = {
    ...obj,
  };

  Object.keys(objCpy).forEach((key) => {
    if (objCpy[key] === '') {
      objCpy[key] = repStr;
    }
  });

  return objCpy;
}
