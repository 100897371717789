import React, { useEffect, useCallback } from 'react';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';
import { connect } from 'react-redux';

import { toggleFullscreen } from '../main/state/main.actions';


export default connect(
  (state,ownProps) => {
    return { ...ownProps, };
  },{
    toggleFullscreen,
  }
)(({ children, fullscreen, toggleFullscreen }) => {
  const handle = useFullScreenHandle();
  /* eslint-disable react-hooks/exhaustive-deps */ 
  useEffect(() => {
    setTimeout(() => {
      if(fullscreen) {
        if(!handle || handle.active) return;
        handle.enter();
      } else {
        if(!handle || !handle.active) return;
        handle.exit();
      }
    }, 500);
  },[fullscreen]); //ESLint wants us to pass handle here,
  // but this will cause `handle.exit` to crash if user presses `esc` in fullscreen.

  const reportChange = useCallback((state) => {
    if(fullscreen !== state) {
      toggleFullscreen();
    }
  }, [fullscreen,toggleFullscreen]);
  return (
    <FullScreen style={{ minHeight: '100vh' }} handle={handle} onChange={reportChange}>
      {children}
    </FullScreen>
  );
});
  
