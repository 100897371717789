import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Table, TreeSelect } from 'antd';

const constructTreeData = ({
  salesRabbitFields: {
    leadFields = [],
    customFields = [],
  } = {},
  type,
}) => {
  const leadOpts = leadFields.filter((field) => field.type === type)
    .map((field) => ({ value: field.id, title: field.name }));
  const customOpts = customFields.filter((field) => field.type === type)
    .map((field) => ({ value: field.id, title: field.name }));
  const treeData = [];

  if (leadOpts.length > 0) {
    treeData.push({
      value: 'lead',
      title: 'Lead Fields',
      children: leadOpts,
      selectable: false,
    });
  }
  if (customOpts.length > 0) {
    treeData.push({
      value: 'custom',
      title: 'Custom Fields',
      children: customOpts,
      selectable: false,
    });
  }

  return treeData;
};

function SalesRabbitFieldMapping({
  salesRabbitFields,
  targetFields,
  value: mappings,
  onChange: onMappingsChanged,
  type,
  requiredFields,
}) {
  const columns = useMemo(() => [
    {
      dataIndex: 'salesRabbitField',
      title: 'SalesRabbit Field',
      width: 300,
      render: (_, record) => {
        const { type: fieldType, value } = record;

        const treeData = constructTreeData({ salesRabbitFields, type: fieldType });
        const {
          [value]: mappingValue,
        } = mappings;
        return (
          <TreeSelect
            treeData={treeData}
            value={mappingValue}
            treeDefaultExpandAll
            showSearch
            treeNodeFilterProp="title"
            onSelect={(fieldKey) => {
              if (!onMappingsChanged) return;
              onMappingsChanged({
                ...mappings,
                [value]: fieldKey,
              });
            }}
          />
        );
      },
    }, {
      dataIndex: 'targetField',
      title: `${type} Field`,
      width: 300,
      render: (_, record) => {
        const className = requiredFields.has(record.value)
          ? 'form-required-field'
          : null;
        return <span className={className}>{record.label}</span>;
      },
    },
  ], [salesRabbitFields, targetFields, mappings, onMappingsChanged, requiredFields]);

  return (
    <Table
      dataSource={targetFields}
      columns={columns}
      pagination={false}
      size="small"
      rowKey="value"
    />
  );
}

SalesRabbitFieldMapping.propTypes = {
  salesRabbitFields: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  targetFields: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
  })),
  value: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onChange: PropTypes.func,
  type: PropTypes.string.isRequired,
  requiredFields: PropTypes.instanceOf(Set),
};

SalesRabbitFieldMapping.defaultProps = {
  salesRabbitFields: {},
  targetFields: [],
  value: {},
  requiredFields: new Set(),
  onChange: null,
};

export default SalesRabbitFieldMapping;
