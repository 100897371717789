import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Tag } from 'antd';

import { getCategories } from './state/costcodes.actions';

export default ({
  onChange,
  displayMode,
  categoryId,
}) => {
  const dispatch = useDispatch();
  const categoryMap = useSelector(state => state.costcodes.categories);

  useEffect(() => {
    dispatch(getCategories())
  },[dispatch])

  const body = useMemo(() => 
    Object.values(categoryMap).map(({ id, name }) => (
      <Select.Option key={id} id={id} value={id}>
        {name}
      </Select.Option>
    ))
  ,[categoryMap]);

  const categoryName = useMemo(() => {
    const { name } = Object.values(categoryMap).find((category) => category.id === categoryId) || {};
    return name;
  },[categoryMap,categoryId]);

  if(displayMode) return <Tag>{categoryName}</Tag>;
  return (
    <Select
      placeholder='Select a Cost Code Category'
      onChange={onChange}
      value={categoryId}
    >
      {body}
    </Select>
  )
}
