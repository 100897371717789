import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';


export default () => {
  const {
    primaryColor,
    accentColor,
    footer,
  } = useSelector(state => state.clientPortal.settings);
  const lines = useMemo(() => {
    const l = footer ? footer.split('\n') : [];
    return l.map((line, idx) => <p style={{ margin: 0 }} key={idx}>{line}</p>)
  }, [footer]);
  return (
    <Row style={{ backgroundColor: primaryColor }} className='client-portal-footer' justify='center'>
      <div style={{ color: accentColor, padding: '10px 0px', textAlign:'center', paddingBottom: 50 }} >
        {lines}
      </div>
      <a
        className='client-portal-powered-by-ontraccr'
        href='https://www.ontraccr.com'
        target='_blank'
        rel='noopener noreferrer'
        style={{ color: accentColor }}
      >
          Powered by Ontraccr
      </a>
    </Row>
  );
}