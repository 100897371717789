import React from 'react';
import { Col, Row } from 'antd';
import { TaskHelpers, Common } from 'ontraccr-common';
import { Duration } from 'luxon';

import { TextRow } from '../clock/ManualEntry/manualEntryHelpers';

export default {};

export const sortByStartTime = (a, b) => (
  (TaskHelpers.getStartTime(a)) - (TaskHelpers.getStartTime(b))
);

export const sortByNameAndStartTime = (userMap) => (a, b) => {
  const userA = userMap?.[a?.userId]?.name ?? '';
  const userB = userMap?.[b?.userId]?.name ?? '';
  const userComparison = userA.localeCompare(userB);

  if (userComparison === 0) {
    return sortByStartTime(a, b);
  }

  return userComparison;
};

export const getEnteredViaText = ({
  textFlag = null,
  metadata,
}) => {
  if (!metadata) return null;

  const {
    deviceText,
    submitterText,
  } = metadata;

  const title = textFlag ? `${textFlag}` : '';
  const text = `${submitterText} (${deviceText})`;

  return (
    <Row>
      <Col style={{ fontSize: 12 }}>
        <Row>
          <Col>
            {title ? (
              <TextRow title={title} text={text} />
            ) : (
              <Row>{text}</Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export const getEnteredViaTableText = (metadata) => {
  const {
    entered,
    start,
    end,
  } = metadata ?? {};

  if (entered) {
    return getEnteredViaText({ metadata: entered });
  }

  const startText = getEnteredViaText({ metadata: start, textFlag: 'Start' });

  const endText = getEnteredViaText({ metadata: end, textFlag: 'End' });

  return (
    <div>
      {startText}
      {endText}
    </div>
  );
};

export const getTaskDurationText = ({
  startTime,
  endTime,
} = {}, {
  showAbbreviatedDuration = true,
} = {}) => {
  if (Common.isNullOrUndefined(endTime) || Common.isNullOrUndefined(startTime)) return null;

  const duration = Duration.fromMillis(endTime - startTime).shiftTo('hours', 'minutes');
  let text = '';
  const {
    hours,
    minutes,
  } = duration.toObject();
  const hInt = parseInt(hours, 10);
  const mInt = parseInt(minutes, 10);
  if (hInt > 0) text += `${hInt}${showAbbreviatedDuration ? 'h' : ' hour(s)'}`;
  if (mInt > 0) {
    if (text.length > 0) text += ' ';
    text += `${mInt}${showAbbreviatedDuration ? 'm' : ' minute(s)'}`;
  }
  return text;
};

export const getDisplayStart = (task = {}, showOriginalTimes = false) => (
  showOriginalTimes && task?.originalStart
    ? task?.originalStart
    : task?.startTime ?? null
);

export const getDisplayEnd = (task = {}, showOriginalTimes = false) => (
  showOriginalTimes && task?.originalEnd
    ? task?.originalEnd
    : task?.endTime ?? null
);
