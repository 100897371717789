import React from 'react';
import { connect } from 'react-redux';
import IntegrationUserSync from '../IntegrationUserSync';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Users from Procore.
    <br/><br/>1. We will automatically set their Ontraccr username and password when importing and send this information to their email.
    <br/><br/>2. All users will be assigned the ‘Worker’ role after importing, make sure you change each user’s role afterwards as needed.
    <br/><br/>If you would rather see exactly which data will be imported from Procore and fill in any missing fields, disable Auto Import to do this manually.
  </div>
);

const importHelpText = (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new Users from Procore
    or <b>Link</b> Procore Users to existing Ontraccr Users
    <br/><br/> <b>Import:</b> User will be created in Ontraccr.
    <br/> <b>Link:</b> Select an existing Ontraccr User to link with the Procore User.
  </div>
);

export default connect(
  (state,ownProps) => ({
    ...ownProps,
    users:state.users.users,
    positions:state.settings.positions,
  }) 
)(
  ({
    users = [],
    positions = [],
    procoreUsers = [],
    onUsersChanged,
    importUsers,
  }) => (
    <IntegrationUserSync
      title='Procore Users'
      integrationUsers={procoreUsers}
      integrationKey='procoreId'
      users={users}
      positions={positions}
      onUsersChanged={onUsersChanged}
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      importUsers={importUsers}
    />
  )
);

