import React, { useMemo } from 'react';
import { Drawer } from 'antd';
import moment from 'moment';
import ScheduleOfValuesTable from './ScheduleOfValuesTable';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import {
  getSubContractsWithTotalChanges,
} from './helpers';

export default ({
  snapshot = {},
  currentHoldbackPercentage,
  visible,
  onClose,
  files,
  onPreviewClick,
  removeFile,
  addFile,
  setFiles,
  selectedFile,
  clearSelectedFile,
  updateFile,
}) => {
  const {
    baseContractValues,
    changeOrderValues,
    nonHoldbackValues,
    liveSummaryValues,
    subContractValues,
    sectionValueMap,
    subContractCOs,
    timestamp,
  } = snapshot;

  const date = useMemo(() => moment(timestamp).format('MM/DD/YYYY hh:mm A'), [timestamp]);

  const subContractsWithTotalChanges = useMemo(() => (
    getSubContractsWithTotalChanges({
      subContractValues,
      subContractCOs,
    })
  ), [subContractValues, subContractCOs]);

  return (
    <Drawer
      title={`Review Progress Bill ${timestamp ? date : ''}`}
      visible={visible}
      onClose={onClose}
      width={1300}
      push={false}
      className='schedule-of-values-review-drawer'
      bodyStyle={{ paddingBottom: 53}}
    >
      <ScheduleOfValuesTable
        readOnly
        baseContractValues={baseContractValues}
        changeOrderValues={changeOrderValues}
        nonHoldbackValues={nonHoldbackValues}
        liveSummaryValues={liveSummaryValues}
        sectionValueMap={sectionValueMap}
        subContractsWithTotalChanges={subContractsWithTotalChanges}
        subContractCOs={subContractCOs}
        currentHoldbackPercentage={currentHoldbackPercentage}
        files={files}
        onPreviewClick={onPreviewClick}
        removeFile={removeFile}
        addFile={addFile}
        setFiles={setFiles}
        selectedFile={selectedFile}
        clearSelectedFile={clearSelectedFile}
        updateFile={updateFile}
      />
      <div className='footer'>
        <OnTraccrButton title="Cancel" type='back' onClick={onClose}/>
        <OnTraccrButton title='Edit' onClick={null} disabled={true}/>
      </div>
    </Drawer>
  )
};
