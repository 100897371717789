import {
  TOGGLE_FULLSCREEN,
  TOGGLE_MENU_COLLAPSE,
  SET_MAIN_LOADED,
} from '../../state/actionTypes';


const initialState = {
  fullscreen: false,
  menuCollapsed: false,
  loaded: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_FULLSCREEN: {
      const newFS = !state.fullscreen;
      return {
        ...state,
        fullscreen: newFS,
        menuCollapsed: newFS,
      };
    }
    case TOGGLE_MENU_COLLAPSE: {
      return {
        ...state,
        menuCollapsed: !state.menuCollapsed,
      };
    }
    case SET_MAIN_LOADED: {
      const {
        payload: {
          value,
        } = {},
      } = action;
      return {
        ...state,
        loaded: value,
      };
    }
    default:
      return state;
  }
};
