import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer } from 'antd';
import UserAssignmentTable from './UserAssignmentTable';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import { reassignForm, reassignDraft } from './state/forms.actions';

import Permissions from '../auth/Permissions';

function FormReassignmentDrawer({
  visible,
  onClose,
  onCloseForm,
  assignedFormId,
  assignedDraftId,
  formUsers = [],
}) {
  const dispatch = useDispatch();
  const [selectedUsers, setSelectedUsers] = useState([]);
  const isDraft = !assignedFormId && assignedDraftId;

  useEffect(() => {
    if (!visible) {
      setSelectedUsers([]);
    } else if (formUsers.length) {
      setSelectedUsers(formUsers);
    } else if (isDraft) {
      setSelectedUsers([Permissions.id]);
    }
  }, [visible, formUsers, Permissions]);

  const onSubmit = useCallback(async () => {
    let res;
    if (assignedFormId) {
      res = await dispatch(reassignForm(assignedFormId, {
        users: selectedUsers,
      }));
    } else if (assignedDraftId) {
      res = await dispatch(reassignDraft(assignedDraftId, {
        users: selectedUsers,
      }));
    }
    if (res) {
      onClose();
      if (!selectedUsers.includes(Permissions.id) || !assignedFormId) onCloseForm();
    }
  }, [
    assignedFormId,
    assignedDraftId,
    selectedUsers,
    onClose,
    onCloseForm,
    Permissions,
  ]);

  return (
    <Drawer
      visible={visible}
      title="Reassign Form"
      onClose={onClose}
      width={500}
    >
      <UserAssignmentTable
        selectedUsers={selectedUsers}
        onUsersChanged={setSelectedUsers}
        scroll={{ y: 'calc(100vh - 300px)' }}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        canSubmit={selectedUsers.length}
      />
    </Drawer>
  );
}

export default FormReassignmentDrawer;
