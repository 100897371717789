import { isObject } from '../../../helpers/helpers';
import {
  GET_LIVE_FEED_EVENTS,
  ADD_LIVE_FEED_EVENT,
  GET_POSTS,
  DELETE_POSTS,
  GET_FILE_URLS,
  CREATE_POST,
  GET_CHANNELS,
  CREATE_CUSTOM_CHANNEL,
  EDIT_CUSTOM_CHANNEL,
  DELETE_CUSTOM_CHANNEL,
} from '../../../state/actionTypes';

import { getEventId } from '../liveFeedHelpers';

const ensureUnique = (events = []) => {
  const eventSet = new Set();
  events.forEach((event) => {
    const eventId = getEventId(event);
    if (!eventSet.has(eventId)) eventSet.add(event);
  });
  return Array.from(eventSet);
};

const initialState = {
  events: [],
  posts: {}, // {[channelId]: { [postId]: post, .... }, .... }
  files: {},
  globalChannel: {},
  customChannels: {},
  projectChannels: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_LIVE_FEED_EVENTS:
      return {
        ...state,
        events: ensureUnique(action.payload.events),
      };
    case ADD_LIVE_FEED_EVENT: {
      const {
        events = [],
      } = state;
      const {
        events: stateEvents = [],
      } = action.payload;

      const newEvents = ensureUnique([...events].concat(stateEvents));
      return {
        ...state,
        events: newEvents.sort((a, b) => b.timestamp - a.timestamp),
      };
    }
    case GET_POSTS: {
      const {
        payload: {
          posts = {},
        },
      } = action;
      return {
        ...state,
        posts,
      };
    }
    case DELETE_POSTS: {
      const {
        payload: {
          channelId = '',
          postToDelete = '',
        } = {},
      } = action;
      const {  posts: statePosts = {} } = state;
      const newPosts = { ...statePosts };
      if (!newPosts?.[channelId]?.[postToDelete]) return state;
      delete newPosts[channelId][postToDelete];
      return {
        ...state,
        posts: newPosts,
      };
    }
    case GET_FILE_URLS: {
      const {
        payload: {
          files = [],
        } = {},
      } = action;
      const {
        files: oldFiles = {},
      } = state;
      const newFiles = { ...oldFiles };
      files.forEach((file) => {
        newFiles[file.id] = file;
      });
      return {
        ...state,
        files: newFiles,
      };
    }
    case CREATE_POST: {
      const {
        payload: {
          post = {},
        } = {},
      } = action;
      const newMap = { ...state.posts };
      // If specified channel does not exist:
      if (!isObject(newMap[post?.channelId])) return state;
      newMap[post.channelId][post.id] = post;
      return {
        ...state,
        posts: newMap,
      };
    }
    case GET_CHANNELS: {
      const {
        payload: {
          globalChannel = {},
          customChannels = {},
          projectChannels = {},
        } = {},
      } = action;
      return {
        ...state,
        globalChannel,
        customChannels,
        projectChannels,
      };
    }
    case CREATE_CUSTOM_CHANNEL: {
      const {
        payload: {
          id,
          name,
          users = [],
        } = {},
      } = action;
      const { customChannels: stateCustomChannels, posts: statePostsMap } = state;
      const newCustomChannels = {
        ...stateCustomChannels,
        [id]: {
          id,
          name,
          users,
        },
      };
      const newPostsMap = {
        ...statePostsMap,
        [id]: {},
      };
      return {
        ...state,
        customChannels: newCustomChannels,
        posts: newPostsMap,
      };
    }
    case EDIT_CUSTOM_CHANNEL: {
      const {
        payload: {
          id,
          name,
          users = [],
        } = {},
      } = action;
      const { customChannels: stateCustomChannels } = state;
      const newCustomChannels = { ...stateCustomChannels };
      newCustomChannels[id] = {
        id,
        name,
        users,
      };
      return {
        ...state,
        customChannels: newCustomChannels,
      };
    }
    case DELETE_CUSTOM_CHANNEL: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { customChannels: stateCustomChannels } = state;
      const newCustomChannels = { ...stateCustomChannels };
      delete newCustomChannels[id];
      return {
        ...state,
        customChannels: newCustomChannels,
      };
    }
    default:
      return state;
  }
};
