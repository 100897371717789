import React, { useCallback, useState } from 'react';
import { EditOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import UserAssignmentSelector from './selectors/UserAssignmentSelector.js'

const AUTHOR_HELP = 'An edit request will be sent to the form\'s author';

export default ({
  setElements,
  setDataMap,
  isDisplay,
  divisionId,
  sections = [],
  isExternalForm,
} = {}) => ({
  id,
  draggable,
  data = {},
}) => {
  const {
    users: initialSelected = [],
  } = data;
  const [selected,setSelected] = useState(initialSelected);

  const onChange = useCallback((newSelected) => {
    setDataMap((dataMap) => ({
      ...dataMap,
      [id]: { users: newSelected }
    }));
    setSelected(newSelected);
  },[id]);

  return (
    <WorkflowActionNode
      id={id}
      title={'Edit'}
      Icon={EditOutlined}
      type='edit'
      draggable={draggable}
      onNodeUpdate={setElements}
      isDisplay={isDisplay}
      hover={`
        Users or roles added to this step can edit a submitted form.
      `}
    >
      {!draggable && <div>
        <UserAssignmentSelector
          onChange={onChange}
          selected={selected}
          showPositions={true}
          isDisplay={isDisplay}
          divisions={[divisionId]}
          showFields
          sections={sections}
          showFormAuthor={!isExternalForm}
          formAuthorHelperText={AUTHOR_HELP}
        />
        <WorkflowHandle type='target' position='top' disabled={isDisplay}/>
        <WorkflowHandle
          type='source'
          position='bottom'
          id={`${id}-edited`}
          disabled={isDisplay}
        />
      </div>}
    </WorkflowActionNode>
  )
}
