import {
  CREATE_COSTCODE,
  CREATE_MANY_COSTCODE,
  GET_ALL_COSTCODES,
  DELETE_COSTCODE_BY_ID,
  UPDATE_COSTCODE,
  ARCHIVE_COSTCODE,
  GET_PHASES,
  GET_QUICKBOOKS_COSTCODES,
  GET_COSTCODE_CATEGORIES,
  CREATE_COSTCODE_TEMPLATE,
  GET_COSTCODE_TEMPLATES,
  UPDATE_COSTCODE_TEMPLATE,
  DELETE_COSTCODE_TEMPLATE_BY_ID,
  GET_COSTCODE_BILLING_DATA,
  GET_COSTCODE_CUSTOM_FIELD_TEMPLATE,
  UPDATE_COSTCODE_CUSTOM_FIELD_TEMPLATE,
  GET_COSTCODE_CUSTOM_DATA,
} from '../../state/actionTypes';

import { updateState, getIdMap, sortByCode } from '../../helpers/helpers';

const initialState = {
  costcodes: [],
  phases: [],
  quickBooksCostcodes: [],
  categories: {},
  costcodeBillingData: {},
  customFieldTemplate: {},
  customData: [],
  customDataFiles: {},
};

export default function costCodeActions(state = initialState, action) {
  switch (action.type) {
    case CREATE_COSTCODE:
    case CREATE_MANY_COSTCODE: {
      const {
        payload: {
          costcodes = [],
        } = {},
      } = action;
      const {
        categories = {},
        costcodes: stateCostcodes = [],
      } = state;
      const oldCC = stateCostcodes.slice();
      const costCodeSet = new Set(oldCC.map((cc) => cc.id));
      const categoryIdMap = getIdMap(Object.values(categories));
      const newCostCodes = costcodes.map((cc) => {
        const {
          categoryId,
        } = cc;
        const {
          [categoryId]: {
            name: category,
          } = {},
        } = categoryIdMap;
        return {
          ...cc,
          active: 1,
          category,
        };
      }).filter((cc) => !costCodeSet.has(cc.id));
      const updatedCC = oldCC.concat(newCostCodes);
      updatedCC.sort(sortByCode('code'));

      return {
        ...state,
        costcodes: updatedCC,
      };
    }
    case GET_ALL_COSTCODES: {
      const updatedCC = action.payload.costcodes.map((cc) => ({
        ...cc,
        phased: Boolean(cc.phased),
      }));
      updatedCC.sort(sortByCode('code'));
      return {
        ...state,
        costcodes: updatedCC,
      };
    }
    case GET_PHASES: {
      return {
        ...state,
        phases: action.payload.phases,
      };
    }
    case DELETE_COSTCODE_BY_ID: {
      return {
        ...state,
        costcodes: state.costcodes.filter((cc) => cc.id !== action.payload.deletedId),
      };
    }
    case ARCHIVE_COSTCODE: {
      const {
        payload: {
          costcodeId,
          active,
        },
      } = action;
      return {
        ...state,
        costcodes: state.costcodes.map((cc) => {
          if (cc.id !== costcodeId) return cc;
          const newCC = { ...cc };
          newCC.active = active ? 1 : 0;
          return newCC;
        }),
      };
    }
    case UPDATE_COSTCODE: {
      const {
        payload: {
          costcode,
        },
      } = action;

      const {
        categories = {},
      } = state;
      const { categoryId } = costcode;
      const newCC = {
        ...costcode,
      };
      if (categoryId) {
        const categoryIdMap = getIdMap(Object.values(categories));
        const {
          [categoryId]: {
            name: category,
          } = {},
        } = categoryIdMap;
        newCC.category = category;
      }
      return {
        ...state,
        costcodes: updateState(state.costcodes, newCC),
      };
    }
    case GET_QUICKBOOKS_COSTCODES: {
      const {
        data: quickBooksCostcodes,
      } = action.payload;
      return {
        ...state,
        quickBooksCostcodes,
      };
    }
    case GET_COSTCODE_CATEGORIES: {
      const {
        payload: {
          categories = [],
        } = {},
      } = action;
      return {
        ...state,
        categories: getIdMap(categories, 'name'),
      };
    }
    case CREATE_COSTCODE_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      const copiedTemplates = state.templates.slice();
      copiedTemplates.push(template);
      return {
        ...state,
        templates: copiedTemplates,
      };
    }
    case GET_COSTCODE_TEMPLATES: {
      const {
        payload: {
          templates = [],
        } = {},
      } = action;
      return {
        ...state,
        templates,
      };
    }
    case UPDATE_COSTCODE_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      return {
        ...state,
        templates: updateState(state.templates, template),
      };
    }
    case DELETE_COSTCODE_TEMPLATE_BY_ID: {
      const {
        payload: {
          deletedId,
        } = {},
      } = action;
      return {
        ...state,
        templates: state.templates.filter((cc) => cc.id !== deletedId),
      };
    }
    case GET_COSTCODE_BILLING_DATA: {
      const {
        payload: {
          billingData = {},
        } = {},
      } = action;
      return {
        ...state,
        costcodeBillingData: billingData,
      };
    }
    case GET_COSTCODE_CUSTOM_FIELD_TEMPLATE:
    case UPDATE_COSTCODE_CUSTOM_FIELD_TEMPLATE: {
      const {
        payload: {
          template = {},
        } = {},
      } = action;
      return {
        ...state,
        customFieldTemplate: template,
      };
    }
    case GET_COSTCODE_CUSTOM_DATA: {
      const {
        data: {
          data = [],
          fileMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        customData: data,
        customDataFiles: fileMap,
      };
    }
    default:
      return state;
  }
}
