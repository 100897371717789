import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Popover, Row, Col } from 'antd';
import { FlagOutlined } from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import FieldTriggerPopover from './FieldTriggerPopover';
import HoverHelp from '../../../common/HoverHelp';
import Colors from '../../../constants/Colors';

function FieldTriggerFlag({
  sections,
  isExternalForm,
  templateId,
  projectId,
  divisions,
  configProps,
  responding,
  id,
  fieldTriggerMap,
  setFieldTriggerMap,
  name,
}) {
  const title = (
    <Row>
      <Col>Trigger Form</Col>
      <Col flex="auto" justify="center">
        <Row justify="end">
          <HoverHelp
            content={(
              <div style={{ width: 250 }}>
                Trigger a form from this field.
                Please note that the forms will only
                be triggered once this form is submitted.
              </div>
            )}
          />
        </Row>
      </Col>
    </Row>
  );

  const isFieldTriggered = useMemo(() => !!fieldTriggerMap[id], [fieldTriggerMap, id]);

  return (
    <Popover
      title={title}
      placement="left"
      trigger="click"
      content={(
        <FieldTriggerPopover
          sections={sections}
          isExternalForm={isExternalForm}
          templateId={templateId}
          projectId={projectId}
          divisions={divisions}
          configProps={configProps}
          responding={responding}
          id={id}
          fieldTriggerMap={fieldTriggerMap}
          setFieldTriggerMap={setFieldTriggerMap}
          name={name}
        />
      )}
    >
      <BorderlessButton
        iconNode={<FlagOutlined />}
        style={{
          width: 'auto',
          height: 20,
          padding: 0,
          marginRight: 10,
          color: isFieldTriggered ? Colors.ONTRACCR_RED : 'inherit',
        }}
      />
    </Popover>
  );
}

/* eslint-disable react/forbid-prop-types */
FieldTriggerFlag.propTypes = {
  sections: PropTypes.arrayOf(PropTypes.shape({})),
  isExternalForm: PropTypes.bool,
  templateId: PropTypes.string,
  projectId: PropTypes.string,
  divisions: PropTypes.arrayOf(PropTypes.string),
  configProps: PropTypes.object,
  responding: PropTypes.bool,
  id: PropTypes.string,
  fieldTriggerMap: PropTypes.object,
  setFieldTriggerMap: PropTypes.func,
  name: PropTypes.string,
};

FieldTriggerFlag.defaultProps = {
  sections: [],
  isExternalForm: false,
  templateId: null,
  projectId: null,
  divisions: [],
  configProps: {},
  responding: false,
  id: null,
  fieldTriggerMap: {},
  setFieldTriggerMap: null,
  name: null,
};

export default FieldTriggerFlag;
