import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Checkbox, Col, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import AddDrawer from '../common/addDrawer';

import Analytics from '../helpers/Analytics';
import { getHighestNumber } from '../helpers/helpers';

import ProjectAddCostCodeList from './ProjectAddCostCodeList';
import ProjectAddGlobalCostCodeDrawer from './ProjectAddGlobalCostCodeDrawer';
import ProjectAddCostCodeTemplate from './ProjectAddCostCodeTemplate';
import CostCodeEditView from '../costcodes/CostCodeEditView';
import { formatCustomFieldCreatePayload } from '../helpers/costcodeHelpers';
import { CATEGORIES } from './projectHelpers';

const getCategoryId = ({ name, categories = {} }) => {
  const {
    [name]: {
      id,
    } = {},
  } = categories;
  return id;
};

export default function ProjectAddCostCode(props) {
  const {
    isNotDisplay,
    divisionId,
    chosen = [],
    settings: {
      suggestNumbers,
    } = {},
    templateView,
    onCostcodeAdd,
    onCostcodeEdit,
  } = props;

  const { t } = useTranslation();

  const categories = useSelector((state) => state.costcodes.categories);

  const [visible, setVisible] = useState(false);
  const [showGlobal, setShowGlobal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState();
  const [errors, setErrors] = useState({});
  const [customFields, setCustomFields] = useState([]);

  const initialCode = suggestNumbers ? (getHighestNumber(chosen, 'code') + 1).toString() : null;
  const categoryId = useMemo(() => (
    getCategoryId({ categories, name: selectedCategory })
  ), [categories, selectedCategory]);

  const onAddNew = useCallback((type) => () => {
    setSelectedCategory(type);
    // categoryId is a key for the drawer
    // So we need to delay visible change to allow the drawer to load before opening
    setTimeout(() => {
      setVisible(true);
    }, 150);
  }, []);

  const onAddExisting = useCallback((type) => () => {
    setSelectedCategory(type);
    // categoryId is a key for the drawer
    // So we need to delay visible change to allow the drawer to load before opening
    setTimeout(() => {
      setShowGlobal(true);
    }, 150);
  }, []);

  const CostCodeAddView = useCallback((form, formProps) => (
    <CostCodeEditView
      form={form}
      formProps={{
        key: chosen.length,
        costcodes: chosen,
        code: initialCode,
        categoryId: categoryId,
        ...formProps,
      }}
      isProjectAdd
      errors={errors}
      customFields={customFields}
      setCustomFields={setCustomFields}
    />
  ), [chosen, initialCode, categoryId, errors, customFields]);

  const onAdd = useCallback((values) => {
    if (values) {
      // Global add
      if (Array.isArray(values)) {
        setShowGlobal(false);
        return onCostcodeAdd(values);
      }
      Analytics.track('Costcode/Create/ProjectSpecific/FromProjectPage');

      // Add new cost code
      const payload = formatCustomFieldCreatePayload({
        payload: values,
        customFields,
      });

      if (payload?.error || !payload) {
        return setErrors(payload?.errorMap);
      }

      onCostcodeAdd([payload]);
    }

    setErrors({});
    setVisible(false);
  }, [onCostcodeAdd, customFields]);


  return (
    <div className="project-add-costcode-container">
      {(isNotDisplay && !templateView) && <Row style={{ width: '100%'}}>
        <Col>
          <Checkbox
            onChange={props.onCheckChanged}
            style={{ marginBottom: 10 }}
            checked={props.showList}
          >
            Cost Code Required?
          </Checkbox>
        </Col>
        <Col flex="auto"/>
        <Col>
          <ProjectAddCostCodeTemplate
            divisionId={divisionId}
            hasCostcodes={chosen && chosen.length}
            applyTemplate={props.onApplyTemplate}
          />
        </Col>
      </Row>}
      {(props.showList || !isNotDisplay || templateView) &&
        <div>
          {
            CATEGORIES.map((categoryName) => (
              <ProjectAddCostCodeList
                {...props}
                key={categoryName}
                title={categoryName}
                onPhaseChanged={props.onPhaseChanged}
                categoryId={getCategoryId({ categories, name: categoryName})}
                onAddNew={onAddNew(categoryName)}
                onAddExisting={onAddExisting(categoryName)}
                onCostcodeEdit={onCostcodeEdit}
              />
            ))
          }
        </div>
      }
      <AddDrawer
        onClose={onAdd}
        visible={visible}
        title={`Add New ${selectedCategory} Cost Code to ${t('Project')}`}
        isAdd
        formView={CostCodeAddView}
        formProps={{
          editing: true,
        }}
        key={categoryId}
        width={800}
        // Add categoryId as key to load properly in form view
      />
      <ProjectAddGlobalCostCodeDrawer
        visible={showGlobal}
        onClose={() => setShowGlobal(false)}
        category={selectedCategory}
        categoryId={categoryId}
        divisionId={divisionId}
        chosen={props.chosen}
        onSubmit={onAdd}
      />
    </div>
  );
};

ProjectAddCostCode.propTypes = {
  isNotDisplay: PropTypes.bool,
  divisionId: PropTypes.string,
  chosen: PropTypes.array, // eslint-disable-line react/forbid-prop-types
  settings: PropTypes.shape({
    suggestNumbers: PropTypes.bool,
  }),
  templateView: PropTypes.bool,
  onCostcodeAdd: PropTypes.func,
  onCostcodeEdit: PropTypes.func,
};

ProjectAddCostCode.defaultProps = {
  isNotDisplay: false,
  divisionId: null,
  chosen: [],
  settings: PropTypes.shape({
    suggestNumbers: false,
  }),
  templateView: false,
  onCostcodeAdd: undefined,
  onCostcodeEdit: undefined,
};
