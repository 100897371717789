import React, { useCallback, useState, useEffect } from 'react';
import { Drawer, Row } from 'antd';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

function EclipseDistributionOverride({
  visible,
  onClose,
  onSaveOverride,
  override: initialOverride,
}) {
  const [override, setOverride] = useState(initialOverride);

  const onTextChange = useCallback((e) => {
    setOverride(e.target.value);
  }, []);

  const onSubmit = useCallback(() => {
    onSaveOverride(override);
  }, [onSaveOverride, override]);

  useEffect(() => {
    if (!visible) {
      setOverride();
    } else if (initialOverride) {
      setOverride(initialOverride);
    }
  }, [visible, initialOverride]);

  return (
    <Drawer
      title="Configure Distribution Type Override"
      visible={visible}
      onClose={onClose}
      width={700}
    >
      <Row align="middle" style={{ marginBottom: 20 }}>
        Use this field if you want stock transactions to have a different Distribution Type
        based off of the time entries Cost Code.
        Ontraccr will use the field value
        from the Eclipse Cost Code Master table as the Distribution Type.
        <br />
        <br />
        Leave blank if you want all stock transactions to use the same distribution type.
      </Row>
      <OnTraccrTextInput
        value={override}
        onChange={onTextChange}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

EclipseDistributionOverride.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSaveOverride: PropTypes.func.isRequired,
  override: PropTypes.string,
};

EclipseDistributionOverride.defaultProps = {
  override: null,
};

export default EclipseDistributionOverride;
