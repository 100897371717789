import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { getTextColor } from '../../schedule/scheduleHelpers';

import { setBoardTargetCard } from '../state/boards.actions';

function LargeBoardCard({
  card: {
    id,
    number: cardNumber,
    title,
    color: cardColor,
    status,
  } = {},
  index,
  style = {},
  onClick,
}) {
  const backgroundColor = cardColor || '#FFFFFFFF';
  const color = backgroundColor ? getTextColor(backgroundColor) : 'black';

  const dispatch = useDispatch();

  const {
    cardId: targetCardId,
  } = useSelector((state) => state.boards.targetCard);

  const [highlight, setHighlight] = useState(false);

  const onRowClick = useCallback(() => {
    onClick(index);
  }, [index]);

  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      onClick(index);
    }
  }, [index]);

  useEffect(() => {
    if (targetCardId === id) {
      setHighlight(true);
      setTimeout(() => {
        setHighlight(false);
        dispatch(setBoardTargetCard({}));
      }, 1000 * 10);
    } else if (targetCardId && targetCardId !== id) {
      setHighlight(false);
    }
  }, [targetCardId, id, title]);

  return (
    <div
      className="board-large-list-row"
      key={id}
      style={{
        ...style,
        backgroundColor,
        color,
        ...highlight ? { fontWeight: 'bold' } : {},
      }}
      onClick={onRowClick}
      onKeyDown={onKeyDown}
      role="button"
      tabIndex={0}
    >
      {cardNumber || (index + 1)}
      {'. '}
      {title}
      {' - '}
      { status }
    </div>
  );
}

LargeBoardCard.propTypes = {
  card: PropTypes.objectOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    status: PropTypes.string.isRequired,
  })).isRequired,
  index: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
};

LargeBoardCard.defaultProps = {
  index: 0,
};

export default LargeBoardCard;
