import React, { useState } from 'react';
import { Row, Spin } from 'antd';
export default (props) => {
  const imageProps = {...props};
  const maxWidth = imageProps.maxWidth;
  delete imageProps.maxWidth;

  const maxHeight = imageProps.maxHeight;
  delete imageProps.maxHeight;

  const [style,setStyle] = useState({ opacity: 0 });
  const [loading,setLoading] = useState(true);
  const onImageLoad = ({ target:img }) => {
    const {
      offsetHeight: imageHeight,
    } = img;
    setStyle({
      maxWidth:maxWidth,
      maxHeight:maxHeight,
      height: Math.min(maxHeight,imageHeight),
      width:'auto',
      opacity:1,
    });
    setLoading(false);
  }
  return (
    <Row style={{ height: maxHeight }}>
    {loading &&
      <Row justify='center' align='middle' style={{ height: maxHeight }}>
        <Spin/>
      </Row>
    }
    <img
      className='drawer-photo'
      onLoad={onImageLoad}
      style={style}
      alt='Post'
      {...props}
    />
    </Row>
  );
};