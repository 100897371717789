import React, { forwardRef } from 'react';
import { Input } from 'antd';

export default forwardRef((props, ref) => {
  const {
    password = false,
    textarea = false,
    search = false,
    onRef,
  } = props;
  const ourProps = { ...props };
  let Component = Input;
  if (password) {
    Component = Input.Password;
  } else if (textarea) {
    Component = Input.TextArea;
    ourProps.textarea = ourProps.value;
  } else if (search) {
    Component = Input.Search;
  }

  let children = ourProps.children;
  // If we dont delete onRef, will throw an error, as onRef will get passed as a prop
  if (onRef) delete ourProps.onRef;
  if (search) delete ourProps.search;
  if (children) delete ourProps.children;
  return (
    <Component
      className="OnTraccrInput"
      {...ourProps}
      ref={onRef ? ((inputRef) => onRef(inputRef)) : ref}
    >
      {children}
    </Component>
  );
});
