import React, { useMemo } from 'react';
import { Empty, Popover, Tag } from 'antd';

export default function Labels({
  size,
  labels = [],
  showEmpty = true,
}) {
  const labelTags = useMemo(() => labels.map(({ title: label } = {}) => (
    <Tag 
      key={label}
      style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: 250,
      }}
    >
      {label}
    </Tag>
  )), [labels]);

  if (size === 'small') {
    if (labels.length === 0) return null;
    return (
      <Popover
        title="Labels"
        placement="bottom"
        content={(
          <div style={{ maxWidth: 250 }}>
            {labelTags}
          </div>
        )}
      >
        <div
          style={{
            maxWidth: 140,
            maxHeight: 38,
            overflow: 'hidden',
            fontSize: 10,
          }}
        >
          {
            labels.map((label) => (
              <Tag
                style={{
                  fontSize: 10,
                  height: 16,
                  lineHeight: '12px',
                  padding: '0px 2px',
                  margin: 2,
                  maxWidth: 138,
                  textOverflow: 'ellipsis',
                  overflow: 'hidden',
                  float: "left"
                }}
                key={label.id}
              >
                {label.title}
              </Tag>
            ))
          }
        </div>
      </Popover>
    );
  }

  if (showEmpty && labels.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  return (
    <div id="user-label-container">
      {labelTags}
    </div>
  );
}
