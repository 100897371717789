export default {};

export const defaultFilters = {
  status: ['read', 'unread'],
  events: ['projectCreate', 'clockIn', 'clockOut', 'taskSwitch', 'messages', 'formCreate'],
};

export const filterData = (t) => [
  [
    [
      {
        title: 'Status',
        key: 'status',
        children: [
          {
            title: 'Read',
            key: 'read',
          },
          {
            title: 'Unread',
            key: 'unread',
          },
        ],
      },
    ],
    [
      {
        title: 'Events',
        key: 'events',
        children: [
          {
            title: 'Break',
            key: 'break',
          },
          {
            title: 'Budget',
            key: 'budget',
          },
          {
            title: 'Clock In',
            key: 'clockIn',
          },
          {
            title: 'Clock Out',
            key: 'clockOut',
          },
          {
            title: 'Costcode Create',
            key: 'costcodeCreate',
          },
          {
            title: 'End Break',
            key: 'endBreak',
          },
          {
            title: 'Add Note',
            key: 'addNote',
          },
          {
            title: 'Messages',
            key: 'messages',
          },
          {
            title: 'Manual Entries',
            key: 'manualEntries',
          },
          {
            title: `${t('Project')} Create`,
            key: 'projectCreate',
          },
          {
            title: 'Task Switch',
            key: 'taskSwitch',
          },
          {
            title: 'Team Create',
            key: 'teamCreate',
          },
          {
            title: 'Time Card Submit',
            key: 'timecardSubmit',
          },
          {
            title: 'User Create',
            key: 'userCreate',
          },
        ],
      },
    ],
  ],
];
