import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import liveFeedTag from '../../assets/images/NUX/liveFeedTag.png';
import liveFeedFile from '../../assets/images/NUX/liveFeedFile.png';

import colors from '../../constants/Colors';


const taggingUsersDesc = (
<div style={{ fontSize: 13 }}>
  To tag users, simply start by typing in the @ symbol in the text field. 
  You will then see a list of users pop up, allowing you to pick the specific user(s) you want to message. 
  You can tag as many users as you want in a message.
  <br/><br/>
  <div style={{
    color: colors.ONTRACCR_RED,
  }}>
    Note: If a message is sent without tagging a user, it will be posted as a 'field note' for all managers and admins to see.  
  </div>
</div>
);

const fileAttachDesc = `
You can attach a file to the message by uploading it directly from your computer,
or you can select files that already exist within Ontraccr
`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 74px)',
      padding:'0px 40px'
    }}
    rows={[{
      title:'Tagging Users',
      description:taggingUsersDesc,
      cols:[0,16,8],
      image:{
        src:liveFeedTag,
        style:{
          height:63,
          width:150,
        }
      },
    },{
      title:'Attaching Files',
      description:fileAttachDesc,
      cols:[0,16,8],
      image:{
        src:liveFeedFile,
        style:{
          height:125,
          width:156,
        }
      },
      style: { marginTop: 20 },
    }]}
  />
);
