import React from 'react';
import { Row, Col } from 'antd';

import NuxDetailPane from '../NuxDetailPane';

import DisplayText from '../../common/text/DisplayText';

import userCardShortcut from '../../assets/images/NUX/userCardShortcut.png';
const clockDesc = `This will display the user's 
real-time clock so you can view their current task and time for the day. 
Click this to update the user's clock and/or add a manual entry.`;

const weekDesc = `This displays a quick summary of the user's 
time entries for the past week. 
Click this to open the user's full set of Time Cards for the current pay period.`;

const UserRow = ({ title, description }) => (
  <Row>
    <DisplayText 
      title={title}
      style={{ 
        textAlign:'left',
        fontFamily:'roboto-bold',
        fontSize:18,
        marginBottom:8,
      }}
    />
    <DisplayText 
      title={description} 
      style={{ 
        textAlign:'left',
        fontFamily:'roboto-regular',
        fontSize:14,
      }}
    />
  </Row>
);


export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      padding:'0px 40px'
    }}
    customContent={
      <Row style={{ width: '100%', height:300,}} align='middle' gutter={10}>
        <Col span={16}>
          <UserRow title='Clock' description={clockDesc}/>
          <UserRow title='Weekly Summary' description={weekDesc}/>
        </Col>
        <Col span={8}>
          <img
            // className='nux-screenshot'
            src={userCardShortcut}
            alt='User Card Shortcut'
            style={{
              height:175,
              width:239,
            }}
          />
        </Col>
      </Row>
    }
    tutorialLink={'SM1. Managing Users.mp4'}
  />
);
