import axios from 'axios';
import * as Sentry from '@sentry/react';

import Permissions from './Permissions';
import Analytics from '../helpers/Analytics';
import SocketService from '../sockets/SocketService';

import { resetCounters } from '../helpers/counters';

export default {};

let inactiveTimer;

export const logout = async (callback) => {
  await axios.delete('/logout');
  // eslint-disable-next-line no-undef
  if (callback) callback(true);
  Analytics.reset();
  window.sessionStorage.removeItem('token');
  window.sessionStorage.removeItem('wage');
  window.sessionStorage.removeItem('companyId');
  window.sessionStorage.removeItem('userId');
  window.sessionStorage.removeItem('seenDowngradeModal');
  window.localStorage.removeItem('selectedDivisions');
  window.localStorage.removeItem('selectedBoardId');
  delete axios.defaults.headers.common.Authorization;
  window?.productFruits?.services?.destroy?.();
  resetCounters();
};

const resetInactiveTimer = () => {
  if (inactiveTimer) clearTimeout(inactiveTimer);
  inactiveTimer = setTimeout(() => {
    logout();
    window.location.reload();
  }, 1000 * 60 * 30); // 30 minutes
};

export const setUpAfterLogin = (data = {}) => {
  window.sessionStorage.setItem('token', data.token);
  window.sessionStorage.setItem('companyId', data.companyId);
  window.sessionStorage.setItem('companyName', data.companyName);
  window.sessionStorage.setItem('position', data.position);
  window.sessionStorage.setItem('wage', data.wage);
  window.sessionStorage.setItem('id', data.id);
  window.sessionStorage.setItem('firstLogin', data.firstLogin);
  window.sessionStorage.setItem('lastActive', data.lastActive);
  window.sessionStorage.setItem('acceptedToS', data.acceptedToS);
  window.sessionStorage.setItem('username', data.username);

  Permissions.set(data);
  SocketService.connect(data);

  // Track in Mixpanel
  Analytics.identify(data);
  Analytics.people.set(data);
  Analytics.track('User/Login');

  Sentry.setUser({
    id: data.id,
    companyName: data.companyName,
    companyId: data.companyId,
    username: data.username,
    name: data.name,
  });
};
