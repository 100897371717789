import React from 'react';
import { Row, Col } from 'antd';

import HoverHelp from '../../common/HoverHelp';

export const AdditionalItem = ({ title, help }) => (
  <div>
    {title}
    <br/>
    <span className='form-trigger-subtext'>
      {help}
    </span>
  </div>
);

export const AdditionalLabel = ({ title, help, isEmailSubjectBodyLabel }) => (
  <Row justify='space-between' align='middle'>
    <Col className='form-trigger-label-title' style={isEmailSubjectBodyLabel && { maxWidth: 400 }}>
      {title}
    </Col>
    <Col style={{ margin:'0px 7px', height: (isEmailSubjectBodyLabel ? 30 : 23) }}>
      <HoverHelp content={
        <div className='form-trigger-help'>
          {help}
        </div>
      }/>
    </Col>
  </Row>
);