import { DateTime } from 'luxon';

import sortByString from '../helpers/helpers';
import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';
import { getId } from '../forms/FormBuilder/FormFields/DropdownField';

export default {};

export const timestampsToText = ({
  startTime,
  endTime,
  timezone = DateTime.local().zoneName,
}) => {
  const startDT = startTime ? DateTime.fromMillis(startTime, { zone: timezone }) : null;
  const endDT = endTime ? DateTime.fromMillis(endTime, { zone: timezone }) : null;
  if (!startDT || !endDT) return '';
  const endOfEndDay = endDT.endOf('day');
  const isAllDay = endDT.equals(endOfEndDay);
  const isMultiDay = !endDT.hasSame(startDT, 'day');
  if (isMultiDay) {
    const format = isAllDay ? DateTime.DATE_MED : DateTime.DATETIME_MED;
    return `${startDT.toLocaleString(format)} -> ${endDT.toLocaleString(format)}`;
  }
  if (isAllDay) return startDT.toLocaleString(DateTime.DATE_MED);
  return `${startDT.toLocaleString(DateTime.DATE_MED)} ${startDT.toLocaleString(DateTime.TIME_SIMPLE)} -> ${endDT.toLocaleString(DateTime.TIME_SIMPLE)}`;
};

export const parseDateField = ({ date, time, timezone = DateTime.local().zoneName }) => {
  let timeString = '';
  if (date && !Number.isNaN(date)) {
    const dt = DateTime.fromMillis(date, { zone: timezone });
    timeString = dt.toLocaleString(DateTime.DATE_MED);
  }
  if (time && !Number.isNaN(time)) {
    const prefix = timeString.length > 0 ? ' ' : '';
    const tt = DateTime.fromMillis(time, { zone: timezone });
    timeString += `${prefix}${tt.toLocaleString(DateTime.TIME_SIMPLE)}`;
  }
  return timeString;
};

const sortByDateTimeField = (aDateTime, bDateTime) => {
  const {
    date: aDate = 0,
    time: aTime = 0,
    timezone: aTimezone = DateTime.local().zoneName,
  } = aDateTime;
  const {
    date: bDate = 0,
    time: bTime = 0,
    timezone: bTimezone = DateTime.local().zoneName,
  } = bDateTime;

  // Get the iso date of both and compare in one timezone.
  // Otherwise anything on the same day submitted from an earlier timezone would be sorted
  // before other tasks from a later timezone, even if their times were earlier
  const aISO = DateTime.fromMillis(aDate, { zone: aTimezone }).toISODate();
  const bISO = DateTime.fromMillis(bDate, { zone: bTimezone }).toISODate();

  const aDay = DateTime.fromISO(aISO).startOf('day').valueOf();
  const bDay = DateTime.fromISO(bISO).startOf('day').valueOf();

  // first compare the day
  if (aDay < bDay) return -1;
  if (aDay > bDay) return 1;

  // compare time if day is the same
  if (aTime < bTime) return -1;
  if (aTime > bTime) return 1;

  return 0;
};

const getLastMoveTimestamp = ({ lastMoveTimestamp, createdAt }) => lastMoveTimestamp ?? createdAt;

const sortCardArrayByKey = (cardArray, sortType, cardDateTimeMap) => {
  const [key, dir] = sortType.split('-');
  const mod = dir === 'asc' ? 1 : -1;
  cardArray.sort((a, b) => {
    switch (key) {
      case 'title':
        return sortByString(key)(a, b) * mod;
      case 'number':
        return (a.number - b.number) * mod;
      case 'lastMoveTimestamp':
        return (getLastMoveTimestamp(b) - getLastMoveTimestamp(a)) * mod;
      case 'dateTime':
        return sortByDateTimeField(cardDateTimeMap[a.id], cardDateTimeMap[b.id]) * mod;
      default:
        return a.orderIndex - b.orderIndex;
    }
  });
};

const areValuesInFilter = (values, filterValuesSet) => (
  values?.some((value) => filterValuesSet?.has(getId(value))
));


export const getCardsByStatus = ({
  cards,
  sortType,
  filters,
  field,
  projects = [],
}) => {
  const {
    user: filterUser,
    link: filterLink,
    ...customFieldFilters
  } = filters ?? {};

  const customFieldMapToVerify = {};

  Object.keys(customFieldFilters).forEach((fieldId) => {
    if (customFieldFilters[fieldId]?.length) {
      customFieldMapToVerify[fieldId] = new Set(customFieldFilters[fieldId]);
    }
  });

  const {
    linkType: filterLinkType,
    linkId: filterLinkId,
  } = filterLink ?? {};
  const isCustomerLink = filterLinkType === 'customerId' && filterLinkId;
  const projectSet = new Set();
  if (isCustomerLink) {
    projects.forEach((project) => {
      if (project.customerId === filterLinkId) {
        projectSet.add(project.id);
      }
    });
  }
  // if (filterLinkType === 'Customer')
  const cardStatusMap = {};
  const cardDateTimeMap = {};
  const cardCustomFieldFilterSet = new Set();

  const shouldSearchDateTime = sortType.startsWith('dateTime') && field;
  const shouldSearchCustomField = Object.keys(customFieldMapToVerify).length > 0;

  if (shouldSearchDateTime || shouldSearchCustomField) {
    cards.forEach((card) => {
      const { id: cardId, data: sections = [] } = card;

      const flatFields = sections.flatMap((section) => section.fields);
      flatFields.forEach((formField) => {
        if (shouldSearchDateTime && formField?.fieldId === field) {
          cardDateTimeMap[cardId] = formField?.response ?? {};
        }

        const selectedValues = formField?.response?.values ?? [];

        if (
          shouldSearchCustomField
          && customFieldMapToVerify[formField?.fieldId]
          && !cardCustomFieldFilterSet.has(formField?.fieldId)
          && areValuesInFilter(selectedValues, customFieldMapToVerify[formField?.fieldId])
        ) {
          cardCustomFieldFilterSet.add(cardId);
        }
      });
    });
  }
  cards.forEach((card) => {
    const { statusId, users, link, id: cardId } = card;
    if (shouldSearchCustomField && !cardCustomFieldFilterSet.has(cardId)) return;
    if (!(statusId in cardStatusMap)) cardStatusMap[statusId] = [];
    if (filterUser && !users.includes(filterUser)) return;
    if (
      filterLinkType
      && filterLinkId
    ) {
      let linkMatches = (
        link?.linkType === filterLinkType
        && link?.linkId === filterLinkId
      );
      if (isCustomerLink && link?.linkType === 'projectId') {
        linkMatches = projectSet.has(link.linkId);
      }
      if (!linkMatches) return;
    }
    cardStatusMap[statusId].push(card);
  });
  Object.values(cardStatusMap).forEach((cardArray) => {
    sortCardArrayByKey(cardArray, sortType, cardDateTimeMap);
  });
  return cardStatusMap;
};

export const getTemplateMap = (cardTemplate = {}) => {
  const tMap = {};
  const { fields: sections = [] } = cardTemplate;
  sections.forEach((section) => {
    const { fields = [], id: sectionId } = section;
    if (!(sectionId in tMap)) tMap[sectionId] = {};
    fields.forEach((field) => {
      tMap[sectionId][field.id] = field;
    });
  });
  return tMap;
};

export const parseYesNo = (response = {}) => {
  const { value, explanation } = response;
  let ans = null;
  // value === undefined if user didnt fill out the field
  if (value === true) {
    ans = 'Yes';
  } else if (value === false) {
    ans = 'No';
  }
  if (explanation) ans += `. ${explanation}`;
  return ans;
};

export const parseDropdown = ({ values = [] } = {}, config = {}) => {
  const {
    dataType,
    settings = {},
    projectIdMap = {},
  } = config;
  return (
    values.reduce((acc, val) => {
      let label = val?.name;
      if (dataType === 'Projects') {
        const {
          [val.id]: {
            name,
            number,
          } = {},
        } = projectIdMap;
        label = formatProjectLabelFromCompanySettings({
          name,
          number,
          settings,
        });
      }
      if (label) return `${acc}${acc.length > 0 ? ', ' : ''}${label}`;
      return acc;
    }, '')
  );
};

export const getLastMove = (lastMoveTimestamp) => (lastMoveTimestamp ? DateTime.fromMillis(lastMoveTimestamp) : DateTime.local());

export const getUnreadCommCount = ({
  link,
  unreadClientComms = [],
  projectIdMap = {},
}) => {
  if (!link) return 0;
  let customerId;
  const { linkType, linkId } = link;
  if (linkType === 'customerId') customerId = linkId;
  if (linkType === 'projectId') {
    const { [linkId]: { customerId: projectCustomer } = {} } = projectIdMap;
    customerId = projectCustomer;
  }
  if (!customerId) return 0;
  return unreadClientComms.filter((note) => note.customerId === customerId).length;
};

export const handleCardMove = ({
  disableCrossColumnSorting,
  cardsByStatusId = {},
  dragEvent: {
    destination,
    source,
    type,
    draggableId,
  } = {},
}) => {
  if (!destination) return {};
  const {
    droppableId: destinationId,
    index: destinationIndex,
  } = destination;
  const {
    droppableId: sourceId,
    index: sourceIndex,
  } = source;
  if (
    destinationId === sourceId
    && destinationIndex === sourceIndex
  ) return {};
  if (type === 'CARDS') {
    const {
      [destinationId]: destinationCards = [],
    } = cardsByStatusId;
    let destIdx = destinationIndex;
    if (disableCrossColumnSorting && sourceId !== destinationId) {
      const bottomCard = destinationCards.length
        ? destinationCards[destinationCards.length - 1]
        : {};
      const {
        orderIndex: highestIndex = -1,
      } = bottomCard;
      destIdx = highestIndex + 1;
    }

    return {
      draggableId,
      destinationId,
      destinationIndex: destIdx,
      sourceId,
      sourceIndex,
    };
  }
};

export const parseCustomResponses = ({
  field = {},
  projectIdMap,
  settings = {},
}) => {
  let displayData = '';
  const {
    selectedType,
    type,
    response = {},
    configProps: {
      dataType,
    } = {},
  } = field;
  const actualType = selectedType ?? type;
  switch (actualType) {
    case 'text': {
      displayData = response.value ?? '';
      break;
    }
    case 'dateRange': {
      displayData = timestampsToText(response);
      break;
    }
    case 'dateTime': {
      displayData = parseDateField(response);
      break;
    }
    case 'dropdown': {
      const config = {
        dataType,
        projectIdMap,
        settings,
      };
      displayData = parseDropdown(response, config);
      break;
    }
    case 'yes-no': {
      displayData = parseYesNo(response);
      break;
    }
    default:
      displayData = response?.value ?? '';
      break;
  }
  return displayData;
};

export const getGanttZoomScales = ({
  max,
}) => {
  const numbers = [];
  for (let i = 0.25; i <= max; i += 0.25) {
    numbers.push(i);
  }

  return numbers.map((num) => ({
    value: num,
    label: `${num * 100}%`,
  }));
};
