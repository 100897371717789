
/** TYPE DEFINITIONS: */
/**
 * @typedef Reminder
 * @property {string} id
 * @property {string} key
 * @property {number | null} value - the number of minutes before
 * @property {string} label
 */

// -----------------------------------------------------//
/** CONSTANTS: */

/**
 * @constant
 * @type {{[key: string]: Reminder}}
 * @default
 */
export const REMINDER_OPTIONS = {
  DEFAULT_REMINDER: {
    value: null,
    label: 'None',
  },
  AT_TIME_OF_REMINDER: {
    value: 0,
    label: 'At time of event',
  },
  FIVE_MINUTES_BEFORE_REMINDER: {
    value: 5,
    label: '5 minutes before',
  },
  TEN_MINUTES_BEFORE_REMINDER: {
    value: 10,
    label: '10 minutes before',
  },
  FIFTEEN_MINUTES_BEFORE_REMINDER: {
    value: 15,
    label: '15 minutes before',
  },
  THIRTY_MINUTES_BEFORE_REMINDER: {
    value: 30,
    label: '30 minutes before',
  },
  ONE_HOUR_BEFORE_REMINDER: {
    value: 60,
    label: '1 hour before',
  },
  TWO_HOUR_BEFORE_REMINDER: {
    value: 120,
    label: '2 hours before',
  },
  ONE_DAY_BEFORE_REMINDER: {
    value: 1440,
    label: '1 day before',
  },
  TWO_DAYS_BEFORE_REMINDER: {
    value: 2880,
    label: '2 days before',
  },
};