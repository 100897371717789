import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import formsAutoData from '../../assets/images/NUX/formsAutoData.png';
import formAddField from '../../assets/images/NUX/formAddField.png';


const autoDataDesc = (
<div style={{ fontSize: 13 }}>
Ontraccr forms will automatically collect contextual data whenever a user completes the form,
including date/time, employee name, and employee ID.
You also have the option to choose some additional data fields
you'd like to record with each form submission.
</div>
);

const addingDesc = (
  <div style={{ fontSize: 13 }}>
    Organize the form by creating different sections if needed.
    Add fields for information you'd like the user(s)
    to enter for the form, with many different input options available.
    When creating a field, you can use the 'Preview' section to preview how the field
    will function in the form.
  </div>
);

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 74px)',
      padding:'0px 40px'
    }}
    rows={[{
      title:'Automatically Collected Data',
      description:autoDataDesc,
      cols:[0,14,10],
      image:{
        src:formsAutoData,
        style:{
          height:125,
          width:206,
        }
      },
    },{
      title:'Adding Forms Sections & Fields',
      description:addingDesc,
      cols:[0,14,10],
      image:{
        src:formAddField,
        style:{
          height:125,
          width:225,
        }
      },
    }]}
    tutorialLink={'F4. Creating Forms Part 1 - Form Details _ Fields.mp4'}
  />
);
