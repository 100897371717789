import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Checkbox, Table } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';

import UserAssignmentSelector from '../../FormWorkflows/selectors/UserAssignmentSelector';
import sortByString, { getIdMap } from '../../../helpers/helpers';
import BorderlessButton from '../../../common/buttons/BorderlessButton';

const types = [
  'User',
  'Role',
  'Team',
];

export default function FormSectionPermissions({
  onPermissionChanged,
  permissions,
  divisions,
}) {
  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const positionNames = useSelector((state) => state.settings.positionNames);
  const positionMap = useMemo(() => getIdMap(positionNames), [positionNames]);
  const teams = useSelector((state) => (state.teams.teams));
  const teamMap = useMemo(() => getIdMap(teams), [teams]);

  const onAdd = useCallback((newId) => {
    if (!newId) return;
    let type;
    if (teamMap[newId]) {
      type = 'Team';
    }
    if (positionMap[newId]) {
      type = 'Position';
    }
    if (userMap[newId]) {
      type = 'User';
    }
    onPermissionChanged({
      ...permissions,
      [newId]: {
        id: newId,
        canView: true,
        canEdit: false,
        type,
      },
    });
  }, [onPermissionChanged, permissions, userMap, positionMap, teamMap]);

  const onToggle = useCallback((newId, key) => () => {
    if (!permissions[newId]) return;
    let newView = (key === 'canView' ? !permissions[newId].canView : permissions[newId].canView);
    let newEdit = (key === 'canEdit' ? !permissions[newId].canEdit : permissions[newId].canEdit);
    if (key === 'canView' && !newView) {
      newEdit = false;
    }
    if (key === 'canEdit' && newEdit) {
      newView = true;
    }
    onPermissionChanged({
      ...permissions,
      [newId]: {
        ...permissions[newId],
        canView: newView,
        canEdit: newEdit,
      },
    });
  }, [onPermissionChanged, permissions]);

  const onDelete = useCallback(({ id } = {}) => () => {
    if (!id) return;
    const newValue = { ...permissions };
    delete newValue[id];
    onPermissionChanged(newValue);
  }, [onPermissionChanged, permissions]);

  const tableValues = useMemo(() => (
    Object.values(permissions).map((record) => {
      let name;
      switch (record.type) {
        case 'Team':
          name = teamMap[record.id]?.name;
          break;
        case 'Position':
          name = positionMap[record.id]?.name;
          break;
        case 'User':
          name = userMap[record.id]?.name;
          break;
        default:
          name = '';
          break;
      }
      return {
        ...record,
        name,
      };
    })
  ), [permissions, userMap, positionMap, teamMap]);

  const cols = useMemo(() => [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      width: 125,
      filters: types.map((name) => ({
        text: name,
        value: name,
      })),
      onFilter: (type, record) => record.type === type,
      sorter: sortByString('type'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      sorter: sortByString('name'),
      showSorterTooltip: false,
      sortDirections: ['descend', 'ascend'],
    },
    {
      title: 'Can View',
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.canView}
          onChange={onToggle(record.id, 'canView')}
          style={{ marginLeft: 10 }}
        />
      ),
    },
    {
      title: 'Can Edit',
      width: 100,
      render: (_, record) => (
        <Checkbox
          checked={record.canEdit}
          onChange={onToggle(record.id, 'canEdit')}
          style={{ marginLeft: 10 }}
        />
      ),
    },
    {
      title: '',
      dataIndex: '',
      width: 50,
      render: (_1, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={onDelete(record)}
        />
      ),
    },
  ], [onToggle, onDelete]);

  return (
    <>
      <UserAssignmentSelector
        text="Restrict Permissions"
        type="users"
        divisions={divisions}
        onChange={onAdd}
        value={[]}
        mode="single"
        showPositions
        showTeams
      />
      <div className="file-list-container" style={{ marginTop: 15 }}>
        <Table
          size="small"
          pagination={false}
          columns={cols}
          dataSource={tableValues}
          style={{ width: '100%', height: '100%' }}
          rowKey="id"
          locale={{ emptyText: 'No restrictions' }}
        />
      </div>
    </>
  );
}

FormSectionPermissions.propTypes = {
  divisions: PropTypes.array,
  onPermissionChanged: PropTypes.func,
  permissions: PropTypes.object,
};

FormSectionPermissions.defaultProps = {
  divisions: undefined,
  permissions: {},
  onPermissionChanged: () => {},
};
