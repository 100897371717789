import React, { useMemo, forwardRef, useImperativeHandle } from 'react';
import { Table } from 'antd';
import { DateTime } from 'luxon';
import * as XLSX from 'xlsx';

import {
  formatDate,
  getNumBuckets,
  getRowName,
  formatTimeInStatus,
  getDateRangeFromConfig,
} from './analytics.helpers';

import sortByString from '../helpers/helpers';

import { getWeekStart } from '../schedule/scheduleHelpers';

export default forwardRef(({
  fieldTitle,
  analyticsConfig = {},
}, ref) => {
  const {
    data = [],
    columns: dataColumns = [],
    dateRange: selectedRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    breakdown = [],
    fieldId,
    chartType,
  } = analyticsConfig;

  const isTimeInStatus = fieldId === 'timeInStatus';
  const isList = chartType === 'list';


  const dateRange = useMemo(() => (
    getDateRangeFromConfig({ dateRange: selectedRange, datePreset })
  ),[selectedRange, datePreset]);

  const numBuckets = useMemo(() => {
    if (fieldBreakdown) return dataColumns.length;
    if (!dateRange || dateRange.length < 2 || !dateBreakdown || dateBreakdown === 'none') return 0;
    return getNumBuckets(dateRange, dateBreakdown)
  },[dateRange, dateBreakdown, fieldBreakdown, dataColumns]);


  useImperativeHandle(ref, () => ({
    export: (name) => {
      const workbook = XLSX.utils.book_new();
      const rows = [];
      if (isList) {
        const breakdownByUser = breakdown.includes('user');
        const breakdownByStatus = breakdown.includes('status');
        const header = ['Title', 'Date'];
        if (breakdownByUser) header.push('User');
        if (breakdownByStatus) header.push('Status');
        header.push('Value');
        rows.push(header);
        data.forEach((rawRow) => {
          const row = [
            rawRow.title ?? '',
            rawRow.timestamp ? DateTime.fromMillis(rawRow.timestamp).toLocaleString(DateTime.DATETIME_MED) : '',
          ];

          if (breakdownByUser) row.push(rawRow.user ?? '');
          if (breakdownByStatus) row.push(rawRow.status ?? '');
          row.push(rawRow.value ?? '');
          rows.push(row);
        });
      } else {
        if (numBuckets && dateRange && dateRange.length === 2) {
          let startDT = DateTime.fromMillis(dateRange[0].valueOf());
          if (dateBreakdown === 'week') {
            startDT = getWeekStart(startDT);
          }
  
          const header = new Array(numBuckets + 1).fill('');
          for(let i = 0; i < numBuckets; i += 1) {
            const dt = startDT.plus({ [dateBreakdown]: i });
            header[i+1] = formatDate(dt, dateBreakdown);
          }
          rows.push(header);
          data.forEach((record) => {
            const row = new Array(numBuckets + 1).fill('');
            row[0] = getRowName({ breakdown, fieldTitle, record });
            for(let i = 0; i < numBuckets; i += 1) {
              if (record && record.values && i < record.values.length) {
                row[i+1] = record.values[i];
              }
            }
            rows.push(row);
          });
        }
      }
      const sheetTitle = fieldTitle
        .replace(/[:\^\\\/?\*\[\]]/g,'')
        .slice(0, 31);
      const sheet = XLSX.utils.aoa_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, sheet, sheetTitle);
      XLSX.writeFile(workbook, `${name}.xlsx`);
    }
  }), [fieldTitle, numBuckets, data, dateRange, dateBreakdown, breakdown, isList]);

  const columns = useMemo(() => {
    if (isList) {
      const breakdownByUser = breakdown.includes('user');
      const breakdownByStatus = breakdown.includes('status');
      const breakdownByColor = breakdown.includes('color');
      const breakdownByBoardCard = breakdown.includes('boardCard');
      const cols = [{
        title: 'Title',
        dataIndex: 'title',
      }, {
        title: 'Date',
        render:(_, record) => {
          if (!record.timestamp) return '';
          return DateTime.fromMillis(record.timestamp).toLocaleString(DateTime.DATETIME_MED);
        },
        sorter: (a,b) => a.timestamp - b.timestamp,
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      }];
      if (breakdownByUser) {
        cols.push({
          title: 'User',
          dataIndex: 'user',
          sorter: sortByString('user'),
          showSorterTooltip:false,
          sortDirections:['descend','ascend'],
        });
      }
      if (breakdownByStatus) {
        cols.push({
          title: 'Status',
          dataIndex: 'status',
          sorter: sortByString('status'),
          showSorterTooltip:false,
          sortDirections:['descend','ascend'],
        });
      }
      if (breakdownByColor) {
        cols.push({
          title: 'Color',
          dataIndex: 'color',
        });
      }

      if (breakdownByBoardCard) {
        cols.push({
          title: 'Board Card',
          dataIndex: 'cardTitle',
        });
      }
      cols.push({ title: 'Value', dataIndex: 'value' });
      return cols;
    }
    if (!numBuckets) return [];
    let startDT = DateTime.fromMillis(dateRange[0].valueOf());
    if (dateBreakdown === 'week') {
      startDT = getWeekStart(startDT);
    }
    const cols = [{
      title: 'Title',
      render: (_, record) => getRowName({ breakdown, fieldTitle, record }),
      width: 250,
      fixed: 'left',
    }];
    for(let i = 0; i < numBuckets; i += 1) {
      let title = '';
      if (fieldBreakdown) {
        title = dataColumns[i];
      } else {
        const dt = startDT.plus({ [dateBreakdown]: i });
        title = formatDate(dt, dateBreakdown);
      }
      cols.push({
        title,
        render: (_,record) => {
          if (!record || !record.values || i >= record.values.length) return '';
          const value = record.values[i];
          return isTimeInStatus ? formatTimeInStatus(value) : value;
        },
        width: 100,
      })
    }
    return cols;
  },[fieldTitle, numBuckets, dateRange, dateBreakdown, fieldBreakdown, dataColumns, breakdown, isTimeInStatus, isList]);

  return (
    <Table
      scroll={{ x: 'fit-content' }}
      dataSource={data}
      columns={columns}
      pagination={false}
      size='small'
      style={{ width: '100%' }}
    />
  )
});
