import * as XLSX from 'xlsx';
import moment from 'moment';
import PDFTableExport from '../common/pdf/PDFExport/PDFTableExport';
import { isNullOrUndefined } from '../helpers/helpers';

// CONSTANTS:
const getSummaryHeader = (t) => [
  'Estimated Labour Hours',
  'Estimated Labour Cost',
  'Average Labour Rate',
  `${t('Project')} Start Date`,
  `${t('Project')} End Date`,
];

const UNPHASED_COSTCODE_HEADER = [
  'NAME',
  'CODE',
  'DESCRIPTION',
  'HOURS',
  'START DATE',
  'END DATE',
];

const PHASED_COSTCODE_HEADER = [
  'PHASE',
  'DESCRIPTION',
  'NAME',
  'CODE',
  'DESCRIPTION',
  'HOURS',
  'START DATE',
  'END DATE',
];

const formatDate = (timestamp) => {
  if (!timestamp || typeof timestamp !== 'number') return '';
  return moment(timestamp).format('MMM Do YYYY');
};

/**
 * Creates PDF export
 * @param {string} title
 * @param {number} startDate - millis
 * @param {number} endDate - millis
 * @param {object} companyImageURL
 * @param {array} infoTableBody
 * @param {array} unphasedTableBody
 * @param {array} phasedTableBody
 */
const exportPDF = async ({
  title,
  startDate,
  endDate,
  companyImageURL,
  infoTableBody,
  unphasedTableBody,
  phasedTableBody,
  t,
}) => {
  const SUMMARY_HEADER = getSummaryHeader(t);
  const pdfExportConstructor = new PDFTableExport();

  // Prepare Headers:
  const logoEntry = await PDFTableExport.getCompanyLogo(companyImageURL);
  const leftColumn = PDFTableExport.createColumnList({
    content: [
      { text: title, style: 'header' },
    ],
  });
  const rightColumn = PDFTableExport.createColumnList({
    content: [
      !isNullOrUndefined(startDate) && !isNullOrUndefined(endDate)
        ? { text: `${startDate} - ${endDate}`, style: 'header' }
        : { text: '', style: 'header' },
    ],
  });
  const header = PDFTableExport.createHeader({
    useLogo: true,
    logoEntry,
    columns: [leftColumn, rightColumn],
  });

  // Prepare Info Table:
  const adjustedInfoHeader = SUMMARY_HEADER.map((text) => ({ text, style: 'tableHeader' }));
  const infoTable = PDFTableExport.createTable({
    widths: Array(adjustedInfoHeader.length).fill('auto'),
    header: adjustedInfoHeader,
    rows: infoTableBody,
  });

  // Prepare Unphased Costcode Table:
  const adjustedUnphasedHeader = UNPHASED_COSTCODE_HEADER.map((text) => ({ text, style: 'tableHeader' }));
  const uphasedTable = PDFTableExport.createTable({
    widths: Array(adjustedUnphasedHeader.length).fill('auto'),
    header: adjustedUnphasedHeader,
    rows: unphasedTableBody,
  });

  // Prepare Phased Costcode Table:
  const adjustedPhasedHeader = PHASED_COSTCODE_HEADER.map((text) => ({ text, style: 'tableHeader' }));
  const phasedTable = PDFTableExport.createTable({
    widths: Array(adjustedPhasedHeader.length).fill('auto'),
    header: adjustedPhasedHeader,
    rows: phasedTableBody,
  });

  // Export PDF:
  pdfExportConstructor.export({
    name: title,
    header,
    body: [
      infoTable,
      uphasedTable,
      phasedTable,
    ],
  });
};

/**
 * Creates Excel Export
 * @param {string} title
 * @param {string} hoursStr
 * @param {number} startDate
 * @param {number} endDate
 * @param {string} costStr
 * @param {string} rateStr
 * @param {array} formattedUnphased
 * @param {array} formattedPhased
 */
const exportExcel = ({
  title,
  hoursStr,
  startDate,
  endDate,
  costStr,
  rateStr,
  formattedUnphased,
  formattedPhased,
  t,
}) => {
  let rows = [
    ['Estimated Labour Hours', hoursStr, '', `${t('Project')} Start Date`, isNullOrUndefined(startDate) ? '' : formatDate(startDate)],
    ['Estimated Labour Cost', costStr, '', `${t('Project')} End Date`, isNullOrUndefined(endDate) ? '' : formatDate(endDate)],
    ['Average Labour Rate', rateStr],
    [],
    ['UNPHASED COST CODES'],
    [],
    UNPHASED_COSTCODE_HEADER,
  ];
  rows = rows.concat(formattedUnphased);
  rows = rows.concat([
    [],
    ['PHASED COST CODES'],
    [],
    PHASED_COSTCODE_HEADER,
  ]);
  rows = rows.concat(formattedPhased);
  rows.push([]);
  const workbook = XLSX.utils.book_new();
  const sheet = XLSX.utils.aoa_to_sheet(rows);
  // Sheet name cannot exceed 31 char.
  XLSX.utils.book_append_sheet(workbook, sheet, title.slice(0, 31));
  XLSX.writeFile(workbook, `${title}.xlsx`);
};

export default function projectExport({
  isPDF,
  startDate,
  endDate,
  companyImageURL,
  name,
  number,
  labourCost,
  labourHours,
  unphasedCostcodes = [],
  phaseCodes = [],
  t,
}) {
  const title = `${number} ${name.replace(/[/*[\]]/g, '')} ${t('Project')} Details`;
  const hoursStr = labourHours || 'N/A';
  const costStr = !isNullOrUndefined(labourCost) ? `$ ${labourCost}` : 'N/A';
  const rateStr = labourHours && labourCost ? `$ ${(labourCost / labourHours).toFixed(2)} / hr` : 'N/A';
  const projectInfo = [
    hoursStr,
    costStr,
    rateStr,
    isNullOrUndefined(startDate) ? '' : startDate,
    isNullOrUndefined(endDate) ? '' : endDate,
  ];

  const formattedUnphased = unphasedCostcodes.map(({
    name: ccName, code, description, hours = '', startDate = '', endDate = '',
  }) => [ccName, code, description, isNullOrUndefined(hours) ? '' : hours, formatDate(startDate), formatDate(endDate)]);

  let formattedPhased = [];
  phaseCodes.forEach((phase) => {
    const {
      name: phaseName,
      description: phaseDescription,
      costcodes = [],
    } = phase;
    formattedPhased = formattedPhased.concat(
      costcodes.map(({
        name: ccName, code, description, hours = '', startDate = '', endDate = '',
      }) => [phaseName, phaseDescription, ccName, code, description, isNullOrUndefined(hours) ? '' : hours, formatDate(startDate), formatDate(endDate)]),
    );
  });

  if (isPDF) {
    exportPDF({
      title,
      startDate,
      endDate,
      companyImageURL,
      infoTableBody: [projectInfo],
      unphasedTableBody: formattedUnphased,
      phasedTableBody: formattedPhased,
      t,
    });
  } else {
    exportExcel({
      title,
      hoursStr,
      startDate,
      endDate,
      costStr,
      rateStr,
      formattedUnphased,
      formattedPhased,
      t,
    });
  }
};
