import React from 'react';
import { Modal,Row, } from 'antd';
import { CloseOutlined, } from '@ant-design/icons';
import colors from '../../constants/Colors';
export default ({
  title,
  afterClose,
  onCloseClicked,
  visible,
  children,
  width = 400,
  maskColor = colors.opacity(colors.MAIN_BACKGROUND,0.95),
  hideCloseButton = false,
  CloseIcon = CloseOutlined,
  getContainer = () => document.body,
  destroyOnClose = false,
  zIndex = 1010,
}) => (
  <Modal
    visible={visible}
    footer={null}
    afterClose={afterClose}
    maskStyle={{
      backgroundColor:maskColor,
    }}
    maskClosable
    closable={false}
    onCancel={onCloseClicked}
    width={width}
    centered
    getContainer={getContainer}
    destroyOnClose={destroyOnClose}
    zIndex={zIndex}
  >
    <Row
      style={{
        width: '100%',
        fontFamily:'roboto-regular',
        marginBottom:30
      }}
      >
        {!hideCloseButton && <CloseIcon
          onClick={onCloseClicked}
          style={{
            position:'absolute',
            paddingLeft:5,
            paddingTop:5,
          }}
        />}
        <Row style={{ marginLeft: 40, width: 'calc(100% - 80px)',textAlign:'center',display:'block'}}>
            {title}
        </Row>

    </Row>
    {children}
  </Modal>
);
