import axios from 'axios';
import Analytics from '../../helpers/Analytics';
import { request } from '../../helpers/requests';


const SupportService = {
  getGuide: async (type) => {
    Analytics.track(`OpenGuide/${type}`);
    const { data: signedUrl } = await request({
      call: axios.get(`/guide/${type}`),
      errMsg: `Could not load ${type} Guide`,
      hideSuccessToast: true,
    });
    if (signedUrl) window.open(signedUrl);
  },
};

export default SupportService;
