import { notification } from 'antd';
import { isNullOrUndefined } from 'ontraccr-common/lib/Common';

export default function useValidateMaxSelect({
  maxExistingAllowed,
  selected,
  type,
}) {
  const notificationKey = `max-${type}`;
  notification.close(notificationKey);

  if (!isNullOrUndefined(maxExistingAllowed) && selected.length > maxExistingAllowed) {
    notification.error({
      key: notificationKey,
      message: 'Error',
      description: `You can only select up to ${maxExistingAllowed} ${type}.`,
    });
    return false;
  }

  return true;
}
