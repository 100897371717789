import React from 'react';
import { Row, Col } from 'antd';

import LiveFeedCard from './LiveFeedCard';

export default ({
  project = {},
  onClick,
}) => {
  const {
    name,
    number,
    address
  } = project;
  return (
    <LiveFeedCard style={{ maxWidth: 175, padding: 15, }} onClick={onClick}>
      <Row style={{
        width: '100%',
      }} justify='space-between'>
        <Col style={{ 
          fontFamily:'roboto-bold',
          overflow:'hidden',
          textOverflow:'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {name}
        </Col>
        <Col style={{ 
          overflow:'hidden',
          textOverflow:'ellipsis',
          whiteSpace: 'nowrap',
        }}>
          {number}
        </Col>
      </Row>
      <Row style={{
        width: '100%',
        fontSize:12,
      }} justify='start'>
        {address}
      </Row>
    </LiveFeedCard>
  );
}
