import React, { useState, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';

import CompanyEditRow from '../../CompanyEditRow';

import SalesRabbitRegionDrawer from './SalesRabbitRegionDrawer';

import colors from '../../../constants/Colors';

import { deleteSalesRabbitRegion } from './state/salesrabbit.actions';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

function SalesRabbitRegions() {
  const dispatch = useDispatch();

  const regions = useSelector((state) => state.salesrabbit.regions);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedRegionId, setSelectedRegionId] = useState();

  const onOpenDrawer = useCallback(() => setDrawerOpen(true), []);
  const onCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedRegionId();
  }, []);

  const onDelete = useCallback((regionId) => {
    const region = regions[regionId];
    if (!region) return;
    CustomConfirmModal({
      title: `Delete region: ${region.title}?`,
      onOk: () => dispatch(deleteSalesRabbitRegion(regionId)),
    });
  }, [regions]);

  const cols = useMemo(() => ([
    {
      title: '',
      dataIndex: 'title',
      key: 'Region',
      onHeaderCell,
    }, {
      dataIndex: 'action',
      key: 'action',
      onHeaderCell,
      width: 120,
      render: (_, region) => (
        <Row justify="end" style={{ width: '100%' }}>
          <Col>
            <BorderlessButton
              title=""
              iconNode={
                <EditOutlined />
            }
              onClick={() => {
                setSelectedRegionId(region.id);
                setDrawerOpen(true);
              }}
              style={{
                paddingRight: 8,
                paddingLeft: 0,
              }}
            />
          </Col>
          <Col>
            <BorderlessButton
              title=""
              icon="delete"
              color={colors.ONTRACCR_RED}
              onClick={() => onDelete(region.id)}
              style={{
                paddingRight: 8,
                paddingLeft: 0,
              }}
            />
          </Col>
        </Row>
      ),
    },
  ]), [onDelete]);

  const regionArray = useMemo(() => Object.values(regions), [regions]);
  return (
    <>
      <CompanyEditRow
        title="Regions"
        helpText="Regions are used to determine which workflow is triggered."
        divider={false}
      >
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={onOpenDrawer}
        />
      </CompanyEditRow>
      <Table
        size="small"
        columns={cols}
        dataSource={regionArray}
        rowKey="id"
        pagination={false}
        style={{
          marginBottom: 50,
          width: '100%',
          height: '100%',
          borderRadius: 8,
        }}
      />
      <SalesRabbitRegionDrawer
        visible={drawerOpen}
        onClose={onCloseDrawer}
        onDelete={onDelete}
        selectedRegionId={selectedRegionId}
      />
    </>
  );
}

export default SalesRabbitRegions;
