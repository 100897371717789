import React, { useEffect, useState, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  Collapse,
  Table,
  Popover,
} from 'antd';

import AutoImport from '../IntegrationAutoImport';
import CanImportCol from '../IntegrationCanImportCol';
import IsConnectedCol, { createConnectedArray } from '../IntegrationIsConnectedCol';
import { createImportData, linkInitialData } from '../integrationHelpers';

import sortByString, { getIdMap } from '../../../helpers/helpers';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

const integrationKey = 'sageId';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Unions from Sage.
    <br/><br/>If you would rather see exactly which data will be imported from Sage and fill in any missing fields, disable Auto Import to do this manually.
  </div>
);

const importHelpText = (
  <div style={{ width: 500 }}>
    Select from this dropdown to either <b>Import</b> new Unions from Sage
    or <b>Link</b> Sage Unions to existing Ontraccr Unions
    <br/><br/> <b>Import:</b> Union will be created in Ontraccr.
    <br/> <b>Link:</b> Select an existing Ontraccr Union to link with the Sage Union.
  </div>
);

const { Panel } = Collapse;

const canImport300 = (unionNames, localNames, classNames) => (record) => (
  record.unionName && 
  record.unionDescription &&
  record.localName && 
  record.localDescription &&
  record.className && 
  record.classDescription &&
  (!(record.unionName in unionNames) || (record.linkValue && unionNames[record.unionName].id === record.linkValue.unionId))  &&
  (!(record.localName in localNames) || (record.linkValue && localNames[record.localName].id === record.linkValue.localId))  &&
  (!(record.className in classNames) || (record.linkValue && classNames[record.className].id === record.linkValue.classId))
);

const canImport100 = (unionNames) => (record) => (
  record.unionName
  && (
    !(record.unionName in unionNames)
    || (record.linkValue && unionNames[record.unionName].id === record.linkValue.unionId)
  )
);

const includesTerm = ({
  unionName = '',
  unionDescription = '',
  localName = '',
  localDescription = '',
  className = '',
  classDescription = '',
}, searchStr) => {
  if (!searchStr) return true;
  const sLower = searchStr.toLowerCase();
  return unionName?.toLowerCase().includes(sLower)
  || unionDescription?.toLowerCase().includes(sLower)
  || localName?.toLowerCase().includes(sLower)
  || localDescription?.toLowerCase().includes(sLower)
  || className?.toLowerCase().includes(sLower)
  || classDescription?.toLowerCase().includes(sLower)
}

const NameRender = ({
  type,
  linkKey,
  editable,
  record,
  val,
  nameMap = {}
}) => (
  editable && val in nameMap && (!record.linkValue || nameMap[val].id !== record.linkValue[linkKey]) ? 
  <Popover content={`${type} name must be unique in Ontraccr`}>
    <div style={{
    color:'red',
  }}>{val}</div>
  </Popover> : val || ''
);

const getLinkId = (link) => link ? `${link.unionId}-${link.localId}-${link.classId}` : null;
export default function SageUnionSync({
  onUnionsChanged,
  importUnions,
  version,
}) {
  const {
    sageData: {
      unions: sageUnions,
    } = {},
  } = useSelector((state) => state.settings);
  const {
    unions = [],
    locals = [],
    classes = [],
  } = useSelector((state) => state.unions);

  const unionNames = useMemo(() => getIdMap(unions, 'name'), [unions]);
  const localNames = useMemo(() => getIdMap(locals, 'name'), [locals]);
  const classNames = useMemo(() => getIdMap(classes, 'name'), [classes]);

  const linkOptions = useMemo(() => {
    const localMap = getIdMap(locals);
    const unionMap = getIdMap(unions);
    return classes.map((unionClass) => {
      const {
        localId,
        name: className,
        description: classDescription,
        id: classId
      } = unionClass;
      const {
        [localId]: {
          unionId,
          name: localName,
          description: localDescription,
        } = {}
      } = localMap;
      const {
        [unionId]: {
          name: unionName,
        } = {},
      } = unionMap;
      return {
        unionId,
        unionName,
        localId,
        localName,
        localDescription,
        classId,
        className,
        classDescription,
        name: `${unionName} - ${localName} - ${className}`,
        id: getLinkId({ unionId, localId, classId }),
      };
    });
  },[classes, locals, unions]);
  const linkMap = useMemo(() => getIdMap(linkOptions), [linkOptions]);

  const [stateUnions,setStateUnions] = useState(sageUnions ?? []);
  const [activePanels,setActivePanels] = useState(['qb']);
  const [searchStr,setSearchStr] = useState();
  const [importList,setImportList] = useState(new Set());
  const [autoImport,setAutoImport] = useState(true);
  const [connected,setConnected] = useState({});

  const canImport = useMemo(() => (version === 'sage100' ? canImport100 : canImport300), [version]);

  const updateUnion = (record) => {
    setStateUnions(stateUnions.map((union) => {
      if(union[integrationKey] !== record[integrationKey]) return union;
      return record;
    }));
  };

  const resetImportList = (list) => () => {
    setImportList(new Set(list.map((cc) => cc[integrationKey])));
  }

  useEffect(() => {
    setConnected(createConnectedArray(linkOptions,integrationKey));
  },[linkOptions,integrationKey]);

  useEffect(() => {
    setStateUnions( linkInitialData({
      items: sageUnions ?? [],
      connected, integrationKey,
      getLinkValue: ({ unionId, localId, classId }) => ({ unionId, localId, classId })
    }));
    resetImportList(sageUnions ?? [])();
  },[sageUnions, connected]);

  useEffect(() => {
    const importData = createImportData({
      autoImport,
      newData:sageUnions ?? [],
      stateData:stateUnions,
      originalData:linkOptions,
      importList,
      canImport: canImport(unionNames, localNames, classNames),
      connected,
      integrationKey,
    });
    onUnionsChanged(importData); //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ 
    autoImport,
    stateUnions,
    importList,
    onUnionsChanged,
    sageUnions,
    linkOptions,
    connected,
    integrationKey,
  ]); 

  const getCols = (editable) => {
    const cols = [
      IsConnectedCol({ 
        integrationKey,
        title: 'Sage',
        connected,
        onUnLink:(record) => {
          const newConnected = {...connected};
          delete newConnected[record[integrationKey]];
          setConnected(newConnected);

          if(editable) {
            record.linkValue = null;
            updateUnion(record);
          } else {
            setStateUnions(stateUnions.map((union) => {
              if(union[integrationKey] !== record[integrationKey]) return union;
              const newUnion = {...union};
              delete newUnion.linkValue;
              return newUnion;
            }));
          }
        }
      }),
      {
        title:'Union Name',
        dataIndex:'unionName',
        align:'center',
        sorter:sortByString('unionName'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        render:(val,record) =>  (
          <NameRender
            type='Union'
            linkKey='unionId'
            val={val}
            record={record}
            nameMap={unionNames}
            editable={editable}
          />
        ),
      },
      {
        title:'Local Name',
        dataIndex:'localName',
        align:'center',
        sorter:sortByString('localName'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        render:(val,record) =>  (
          <NameRender
            type='Local'
            linkKey='localId'
            val={val}
            record={record}
            nameMap={localNames}
            editable={editable}
          />
        ),
      },
      {
        title:'Local Description',
        dataIndex:'localDescription',
        align:'center',
        sorter:sortByString('localDescription'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      },
      {
        title:'Class Name',
        dataIndex:'className',
        align:'center',
        sorter:sortByString('className'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
        render:(val,record) =>  (
          <NameRender
            type='Class'
            linkKey='classId'
            val={val}
            record={record}
            nameMap={classNames}
            editable={editable}
          />
        ),
      },
      {
        title:'Class Description',
        dataIndex:'classDescription',
        align:'center',
        sorter:sortByString('classDescription'),
        showSorterTooltip:false,
        sortDirections:['descend','ascend'],
      }
    ];
    if(editable) {
      cols.push(
        CanImportCol({
          integrationKey,
          title: 'Sage',
          connected,
          helpText:importHelpText,
          canImport: canImport(unionNames, localNames, classNames),
          importList,
          setImportList,
          resetImportList: resetImportList(sageUnions ?? []),
          onViewTypeChanged:(record,viewType) => {
            record.viewType = viewType;
            updateUnion(record);
          },
          linkOptions,
          getValue: getLinkId,
          onLinkChanged:(record,linkValue) => {
            const newConnected = {...connected};
            const oldLink = connected[record[integrationKey]];
            if(oldLink) delete oldLink[integrationKey];
            if(linkValue) {
              const [linkUnionId, linkLocalId, linkClassId] = linkValue.split('-');
              newConnected[record[integrationKey]] = linkMap[linkValue];
              newConnected[record[integrationKey]][integrationKey] = record[integrationKey];
              record.linkValue = {
                unionId: linkUnionId,
                localId: linkLocalId,
                classId: linkClassId,
              };
            } else {
              delete newConnected[record[integrationKey]];
              record.linkValue = null;
            }

            setConnected(newConnected);

            updateUnion(record);
          },
        })
      );
    }
    return cols;
  }

  const activeStateUnions = useMemo(() => (
    stateUnions.filter((union) => includesTerm(union, searchStr))
  ),[stateUnions,searchStr]);

  return (
    <div style={{ height: 'calc(100% - 55px)', width:'100%', overflowY:'scroll'}}>
      <AutoImport
        setActivePanels={setActivePanels}
        setAutoImport={setAutoImport}
        autoImport={autoImport}
        helpContent={autoImportContent}
        importType='Union'
        importData={importUnions}
      />
      {!autoImport && <OnTraccrTextInput 
        allowClear
        style={{
          width: 250,
        }}
        search
        onChange={(e) => {
          const {
            target:{
              value
            } = {}
          } = e;
          setSearchStr(value);
        }}
        onClick={(e) => e.stopPropagation()}
      />}
      {!autoImport && <Collapse ghost activeKey={activePanels} onChange={setActivePanels}>
        <Panel 
          header='New Sage Unions'
          key='new' 
          className='ontraccr-collapse-panel'
        >
          <Table 
            bordered
            size='small'
            columns={getCols(true)}
            dataSource={activeStateUnions}
            scroll={{
              y: '40%',
              x: 1000,
            }}
            pagination={{
              hideOnSinglePage:true,
              pageSize:20,
              showSizeChanger:false,
            }}
            rowKey={integrationKey}
          />
        </Panel>
        <Panel 
          header='Ontraccr Unions'
          key='ontraccr'
          className='ontraccr-collapse-panel'
        >
          <Table 
            bordered
            size='small'
            columns={getCols(false)}
            dataSource={linkOptions}
            scroll={{
              y: '40%',
            }}
            pagination={{
              hideOnSinglePage:true,
              pageSize:20,
              showSizeChanger:false,
            }}
            rowKey='id'
          />
        </Panel>
      </Collapse>}
    </div>
  )
}
