import React from 'react';
import { Drawer, Divider } from 'antd';

import DisplayText from '../../common/text/DisplayText';

import EquipmentHistoryList from '../../equipment/EquipmentHistoryList';

export default ({
  onClose,
  projectId,
  record: {
    id,
    name,
  } = {},
}) => (
  <Drawer
    title='Equipment History'
    visible={id !== null && id !== undefined}
    onClose={onClose}
    width={650}
  >
    <DisplayText title={name} style={{ fontSize: 18, fontFamily: 'roboto-regular' }}/>
    <Divider style={{ margin: '5px 0px' }}/>
    <EquipmentHistoryList
      id={id}
      projectId={projectId}
      style={{ padding: 5 }}
    />
  </Drawer>
);