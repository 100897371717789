import PropTypes from 'prop-types'
import React from 'react';
import { Table } from 'antd';
import { dailySnapshotColumns } from './weathers.constants';
import HourlyConditions from './HourlyConditions';

const renderHourlyConditions = (props) => (
  // eslint-disable-next-line react/jsx-props-no-spreading
  <HourlyConditions {...props} />
);

export default function DailySnapshotTable({ data = [], loading } = {}) {
  const columns = dailySnapshotColumns(renderHourlyConditions);

  return (
    <Table
      style={{ width: '100%' }}
      title={() => 'Daily Snapshot'}
      pagination={false}
      loading={loading}
      columns={columns}
      dataSource={data}
      bordered
    />
  );
}

DailySnapshotTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
};

DailySnapshotTable.defaultProps = {
  data: [],
  loading: false,
};
