import React, { useState } from 'react';
import {
  Col,
  Drawer,
  Row,
  Table,
} from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DrawerFooter from '../common/containers/DrawerFooter';
import { getBucketColumns } from './bucketHelpers';

export default function AddExistingBucketModal({
  title,
  bucketOptions,
  visible,
  onClose,
  onSave,
  downstreamKey,
}) {
  const [selectedBuckets, setSelectedBuckets] = useState([]);

  const onSubmit = () => {
    onSave(selectedBuckets);
    onClose();
  };

  const columns = getBucketColumns();

  return (
    <Drawer
      title={title}
      onClose={onClose}
      visible={visible}
      width={600}
      bodyStyle={{ padding: 20 }}
    >
      <Table
        columns={columns}
        dataSource={bucketOptions}
        rowSelection={{
          onChange: (newSelected) => setSelectedBuckets(newSelected),
          selectedRowKeys: selectedBuckets,
        }}
        pagination={false}
        rowKey={({ id }) => id}
        size="small"
        style={{ width: '100%', height: 'calc(100vh - 181px)' }}
        scroll={{ y: 'calc(100vh - 220px)' }}
      />
      <DrawerFooter style={{ borderRadius: '0 0 20px 20px' }}>
        <Row justify="space-between" align="middle">
          <Col>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onClose}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title="Save"
              onClick={onSubmit}
              disabled={!selectedBuckets?.length}
            />
          </Col>
        </Row>
      </DrawerFooter>
    </Drawer>
  );
}

AddExistingBucketModal.propTypes = {
  title: PropTypes.string.isRequired,
  bucketOptions: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    description: PropTypes.string,
  })).isRequired,
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  downstreamKey: PropTypes.string.isRequired,
};
