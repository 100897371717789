import React, {
  useState, useEffect, useMemo, useCallback,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { CloseOutlined } from '@ant-design/icons';

import ManualEntryDrawer from '../clock/ManualEntry/ManualEntryDrawer';

import MainClockIn from './MainClockIn';

import { createPhaseMap } from './userHelpers';

import CustomModal from '../common/modals/CustomModal';

import Permissions from '../auth/Permissions';

import { getIdMap } from '../helpers/helpers';
import { getTodaysManualEntries } from '../helpers/payroll';

function UserClockInModal({
  user = {},
  afterClose,
  visible,
  onCloseClicked,
  onClockIn,
  onClockOut,
  onSwitch,
  onBreakStart,
  onBreakEnd,
  costcodes = [],
  projects = [],
  phases = [],
  settings = {},
  unions = {},
  defaultManualOpen = false,
  switchOpen = false,
}) {
  const ourUser = user || {};
  const { id: userId } = ourUser;

  const {
    timeRange,
    firstPayrollDay,
    semiMonthlyPayPeriodDates,
    payPeriod,
  } = useSelector((state) => state.timecards);
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);

  const [isManual, setManual] = useState(defaultManualOpen);
  const canManual = (
    settings.enableManualEntry && ourUser.position && (
      (ourUser.id === Permissions.id && Permissions.has('MANUAL_ENTRY_SELF'))
      || Permissions.has(`MANUAL_ENTRY_${Permissions.formatPosition(ourUser.position)}`)
    )
  );

  const hideManualEntryDrawer = useCallback(() => setManual(false), []);

  const canManualCallback = useCallback(() => setManual(true), []);

  const nonMaterialCodes = useMemo(() => (
    costcodes.filter((code) => code.category !== 'Material')
  ), [costcodes]);

  const { phaseMap, unphasedCC } = useMemo(() => (
    createPhaseMap(phases, nonMaterialCodes)
  ), [nonMaterialCodes, phases]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const canEditUnapproved = useMemo(() => {
    if (userId === Permissions.id && Permissions.has('TIMECARD_EDIT_SELF')) return true;
    if (!ourUser || !ourUser.position) return false;
    return Permissions.has(`TIMECARD_EDIT_${Permissions.formatPosition(user.position)}`);
  }, [ourUser]);

  const initialEntries = useMemo(() => (
    getTodaysManualEntries({
      payPeriod,
      semiMonthlyPayPeriodDates,
      firstPayrollDay,
      timeRange,
      user: ourUser,
      timeEntryUserMap,
      projectIdMap,
      costcodeIdMap,
      isIndividual: userId === Permissions.id,
      canEdit: canEditUnapproved,
    })
  ), [
    timeRange,
    firstPayrollDay,
    payPeriod,
    semiMonthlyPayPeriodDates,
    ourUser,
    timeEntryUserMap,
    projectIdMap,
    costcodeIdMap,
    canEditUnapproved,
  ]);

  useEffect(() => {
    if (visible) setManual(defaultManualOpen);
  }, [visible, defaultManualOpen]);

  return (
    <>
      <CustomModal
        title={`${ourUser.name}'s Clock`}
        visible={visible && !isManual}
        onCloseClicked={onCloseClicked}
        afterClose={afterClose}
        maskColor="#00000073"
        CloseIcon={CloseOutlined}
        width={400}
      >
        <MainClockIn
          user={user}
          settings={settings}
          onClockIn={onClockIn}
          onClockOut={onClockOut}
          onSwitch={onSwitch}
          onBreakStart={onBreakStart}
          onBreakEnd={onBreakEnd}
          costcodes={nonMaterialCodes}
          unions={unions}
          defaultClassId={ourUser.classId}
          unphasedCostcodes={unphasedCC}
          projects={projects}
          phaseMap={phaseMap}
          setManual={canManual ? canManualCallback : null}
          switchOpen={switchOpen}
        />
      </CustomModal>
      <ManualEntryDrawer
        visible={isManual}
        onClose={hideManualEntryDrawer}
        user={ourUser}
        canEditUnapproved={canEditUnapproved}
        initialEntries={initialEntries}
      />
    </>
  );
}

UserClockInModal.propTypes = {
  user: PropTypes.shape({ id: PropTypes.string }),
  afterClose: PropTypes.func,
  visible: PropTypes.bool,
  onCloseClicked: PropTypes.func.isRequired,
  onClockIn: PropTypes.func.isRequired,
  onClockOut: PropTypes.func.isRequired,
  onSwitch: PropTypes.func.isRequired,
  onBreakStart: PropTypes.func.isRequired,
  onBreakEnd: PropTypes.func.isRequired,
  costcodes: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  projects: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  phases: PropTypes.arrayOf(PropTypes.shape({ id: PropTypes.string })).isRequired,
  settings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  unions: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  defaultManualOpen: PropTypes.bool,
  switchOpen: PropTypes.bool,
};

UserClockInModal.defaultProps = {
  user: {},
  afterClose: null,
  visible: false,
  settings: {},
  defaultManualOpen: false,
  switchOpen: false,
};

export default UserClockInModal;
