import React from 'react';
import PropTypes from 'prop-types';
import { FolderOutlined, GoldOutlined } from '@ant-design/icons';
import { Row, Col } from 'antd';

function MaterialSearchDropdownItem({
  item: {
    name,
    partNumber,
    description,
    isFolder,
  } = {},
}) {
  return (
    <>
      <Row gutter={4}>
        <Col>
          {isFolder ? <FolderOutlined /> : <GoldOutlined /> }
        </Col>
        <Col>
          <div className="board-search-item-title">{name}</div>
        </Col>
      </Row>
      <div>
        {partNumber}
      </div>
      <div>
        {description}
      </div>
    </>
  );
}

MaterialSearchDropdownItem.propTypes = {
  item: PropTypes.shape({
    title: PropTypes.string,
    number: PropTypes.number,
    board: PropTypes.string,
    status: PropTypes.string,
  }).isRequired,
};

export default MaterialSearchDropdownItem;
