/* eslint-disable class-methods-use-this */
/* eslint-disable no-undef */
import axios from 'axios';
import { message } from 'antd';

import { setUpAfterLogin } from '../authHelpers';

import { request } from '../../helpers/requests';

class LoginFactory {
  async login(username, password, token) {
    try {
      const { data } = (
        !token
          ? await axios.post('/login', {
            username,
            password,
          })
          : await axios.post('/demoLogin', {
            token,
          })
      );
      if (data === 400) return false;
      setUpAfterLogin(data);
      return true;
    } catch (err) {
      const msg = (err && err.response && err.response.status < 500)
        ? 'Login Failed: Incorrect Username and/or Password.' : 'Error: Cannot reach servers.';
      message.error(msg);
      return false;
    }
  }

  forgotPassword(username) {
    return request({
      call: axios.post('/password/reset/email', { username }),
      successMsg: 'Password reset email sent.',
      errMsg: 'Password reset failed.',
    });
  }

  validateToken(token, url) {
    return request({
      call: axios.post('/password/reset/validate', { token, url }),
      hideSuccessToast: true,
    });
  }

  resetPassword(payload) {
    return request({
      call: axios.post('/password/reset', payload),
      hideSuccessToast: true,
    });
  }
}

export default new LoginFactory();
