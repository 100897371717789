import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Row, Select } from 'antd';
import {
  FieldTimeOutlined,
  SettingOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import WorkflowHandle from './WorkflowHandle';
import WorkflowActionNode from './WorkflowActionNode';
import { getType, updateData } from './workflowHelpers';
import WorkflowTimeConfigureDrawer from './WorkflowTimeConfigureDrawer';

import CheckboxRow from '../FormBuilder/FormFields/CheckboxRow';

const HOVER_TEXT = (
  <div>
    Allows the creation of time card entries submitted via a Time Entry table.
  </div>
);

const DISABLED_TEXT = 'Add a Time Entry table to enable this step';

export default function WorkflowCreateTimeNode({
  setElements,
  setDataMap,
  isDisplay,
  divisionId,
  sections = [],
  name,
} = {}) {
  // eslint-disable-next-line react/function-component-definition
  const WorkflowCreateTimeNodeComponent = function WorkflowCreateTimeNodeComponent({
    id,
    draggable,
    disabled,
    data = {},
  }) {
    const {
      fieldMappings: initialFieldMappings = {},
      tableFieldId,
      autoSubmit: initialAutoSubmit,
      autoApprove: initialAutoApprove,
      preventDuplicates: initialPreventDuplicates,
    } = data;

    const customFields = useSelector((state) => state.timecards.customFields);

    const ourCustomFields = useMemo(() => {
      const {
        [divisionId]: { fields = [] } = {},
      } = customFields;
      return fields.filter((section) => section.id !== 'timecard');
    }, [customFields, divisionId]);

    const title = `Create Time Entries${disabled ? ' - DISABLED' : ''}`;

    const [showDrawer, setShowDrawer] = useState(false);
    const [fieldMappings, setFieldMappings] = useState(initialFieldMappings);
    const [timeTableId, setTimeTableId] = useState(tableFieldId);
    const [autoSubmit, setAutoSubmit] = useState(initialAutoSubmit);
    const [autoApprove, setAutoApprove] = useState(initialAutoApprove);
    const [preventDuplicates, setPreventDuplicates] = useState(initialPreventDuplicates);

    const openDrawer = useCallback(() => setShowDrawer(true), []);
    const closeDrawer = useCallback(() => setShowDrawer(false), []);

    const onCheckChanged = useCallback((key) => (e) => {
      const newChecked = e?.target?.checked;
      const update = { [key]: newChecked };
      if (key === 'autoSubmit') {
        setAutoSubmit(newChecked);
        if (!newChecked) {
          update.autoApprove = false;
          setAutoApprove(false);
        }
      } else if (key === 'autoApprove') {
        setAutoApprove(newChecked);
      } else if (key === 'preventDuplicates') {
        setPreventDuplicates(newChecked);
      }
      setDataMap(updateData(id, update));
    }, [setDataMap, id]);
    const onFieldMappingsChange = useCallback((newMappings) => {
      if (!setDataMap || !id) return;
      setFieldMappings(newMappings);
      setDataMap(updateData(id, { fieldMappings: newMappings }));
    }, [setDataMap, id]);

    const onTimeTableSelect = useCallback((newFieldId) => {
      if (!setDataMap || !id) return;
      setTimeTableId(newFieldId);
      setDataMap(updateData(id, { tableFieldId: newFieldId }));
    }, [setDataMap, id]);

    const timeTables = useMemo(() => (
      sections.map(({ fields = [] }) => (
        fields.filter((field) => {
          const type = getType(field);
          return type === 'table - TimeEntry';
        })
      ))
        .flat()
        .map((field) => {
          const {
            configProps: { title: fieldTitle } = {},
            id: fieldId,
          } = field;
          return { label: fieldTitle, value: fieldId };
        })
    ), [sections]);

    return (
      <WorkflowActionNode
        title={title}
        Icon={FieldTimeOutlined}
        type="createTime"
        id={id}
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : { }}
      >
        {!draggable && (
          <div>
            <Row style={{ marginTop: 10 }}>
              Select Table:
            </Row>
            <Row style={{ margin: '10px 0px' }}>
              <Select
                options={timeTables}
                optionFilterProp="label"
                value={timeTableId}
                onChange={onTimeTableSelect}
              />
            </Row>
            <CheckboxRow
              checked={autoSubmit}
              onChange={onCheckChanged('autoSubmit')}
              title="Auto Submit?"
              hoverText={(
                <>
                  Check this box if you want the time entries to automatically be submitted
                  <br />
                  <br />
                  Leave this box unchecked if you want
                  {' '}
                  users to submit the time entries manually.
                </>
              )}
            />

            <CheckboxRow
              checked={autoSubmit && autoApprove}
              disabled={!autoSubmit}
              onChange={onCheckChanged('autoApprove')}
              title="Auto Approve?"
              hoverText={(
                <>
                  Check this box if you want the time entries to automatically be approved
                  <br />
                  <br />
                  Leave this box unchecked if you want
                  {' '}
                  these time entries to be approved manual in the time cards page.
                </>
              )}
            />
            <CheckboxRow
              checked={preventDuplicates}
              onChange={onCheckChanged('preventDuplicates')}
              // Title overflows if font is bigger
              title={<span style={{ fontSize: 13 }}>Prevent Duplicates?</span>}
              hoverText={(
                <>
                  Check this box if you want Ontraccr to ignore new time entries that conflict
                  {' '}
                  with existing time entries
                  <br />
                  <br />
                  Leave this box unchecked if you want
                  {' '}
                  Ontraccr to create all entries, even if they conflict with existing entries.
                </>
              )}
            />
            {/* HARBOUR-4287 BACKWARDS COMPATIBILITY */}
            { ourCustomFields && (
              <Row style={{ margin: '20px 0px' }}>
                <BorderlessButton
                  title="Configure"
                  style={{ margin: 5 }}
                  iconNode={<SettingOutlined />}
                  onClick={openDrawer}
                />

                <WorkflowTimeConfigureDrawer
                  visible={showDrawer}
                  onClose={closeDrawer}
                  onSubmit={closeDrawer}
                  divisionId={divisionId}
                  customFields={ourCustomFields}
                  sourceName={name}
                  sourceSections={sections}
                  fieldMappings={fieldMappings}
                  onFieldMappingsChange={onFieldMappingsChange}
                />
              </Row>
            )}

            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
  WorkflowCreateTimeNodeComponent.propTypes = {
    id: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
    disabled: PropTypes.bool,
    data: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  };

  WorkflowCreateTimeNodeComponent.defaultProps = {
    draggable: false,
    disabled: false,
    data: {},
  };

  return WorkflowCreateTimeNodeComponent;
}
