import React, { useMemo, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Statistic } from 'antd';
import * as XLSX from 'xlsx';

import {
  getRowName,
  formatTimeInStatus
} from './analytics.helpers';

import colors from '../constants/Colors';

export default forwardRef(({
  fieldTitle,
  analyticsConfig = {},
}, ref) => {

  const {
    data = [],
    breakdown = [],
    fieldId,
  } = analyticsConfig;

  const isTimeInStatus = fieldId === 'timeInStatus';


  useImperativeHandle(ref, () => ({
    export: (name) => {
      const workbook = XLSX.utils.book_new();
      const rows = data.map((record) => {
        const title = getRowName({ breakdown, record, fieldTitle });
        const {
          values = [],
        } = record;
        const [value = 0] = values;
        return [title, value];
      })

      const sheet = XLSX.utils.aoa_to_sheet(rows);
      XLSX.utils.book_append_sheet(workbook, sheet, fieldTitle.slice(0, 31));
      XLSX.writeFile(workbook, `${name}.xlsx`);
    }
  }), [fieldTitle, data, breakdown]);

  const body = useMemo(() => (
    data.map((record) => {
      const title = getRowName({ breakdown, record, fieldTitle });
      const {
        values = [],
      } = record;
      const [value = 0] = values;
      const realValue = isTimeInStatus ? formatTimeInStatus(value) : value;
      return (
        <Col key={title} className='analytics-number-col'>
          <Statistic
            title={
              <span className='analytics-number-title'>
                {title}
              </span>
            }
            value={realValue}
            valueStyle={{ color: colors.ONTRACCR_RED, fontSize: 44, }}
          />
        </Col>
      );
    })
  ),[data, breakdown, fieldTitle, isTimeInStatus]);

  return (
    <Row className='analytics-number-container' gutter={30} align='top' justify='start'>
      {body}
    </Row>
  );
});
