import React from 'react';
import './login.css';
import { Form } from '@ant-design/compatible';
import '@ant-design/compatible/assets/index.css';
import LoginFactory from './login.factory';

import GetStarted from '../GetStarted/GetStarted';
import LoginFormContainer from './LoginFormContainer';

import Logo from '../../common/Logo';

import analytics from '../../helpers/Analytics';

const LoginPage = Form.create({ name: 'normal_login' })(
  class Login extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loggedIn: false,
        loading: false,
        username: '',
        password: '',
        isReset: false,
        showRegister: false,
      };
      this.onSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
      const {
        location: {
          pathname,
        } = {},
      } = this.props;
      this.setState({
        showRegister: pathname.includes('/register'),
      });
      if (pathname.includes('/register')) {
        analytics.track('GetStarted/RegisterPage');
      } else if (pathname.includes('/login')) {
        if (pathname.includes('token=')) {
          const token = pathname.substr(pathname.indexOf('token=') + 6);
          this.handleToken(token);
        } else {
          analytics.track('GetStarted/LoginPage');
        }
      }
    }

    async handleToken(token) {
      const loggedIn = await LoginFactory.login(null, null, token);
      if (loggedIn) this.props.history.push('/dashboard');
    }

    async handleSubmit({
      username,
      password,
    }) {
      const {
        isReset,
      } = this.state;
      if (!username) return;
      if (isReset) {
        const { data } = await LoginFactory.forgotPassword(username);
        if (data) {
          this.setState({
            isReset: false,
          });
        }
      } else {
        if (!password) return;
        const loggedIn = await LoginFactory.login(username, password);
        if (loggedIn) this.props.history.push('/dashboard');
      }
    }

    render() {
      const {
        history,
        location: {
          pathname,
          search,
        } = {},
      } = this.props;
      const {
        isReset,
        showRegister,
      } = this.state;
      return (
        <div style={{
          width: '100vw', height: '100vh', overflow: 'hidden', position: 'absolute',
        }}
        >
          <LoginFormContainer
            style={{ top: showRegister ? -window.innerHeight : 0 }}
            isReset={isReset}
            onSubmit={this.onSubmit}
            onResetButtonClicked={() => {
              analytics.track(`GetStarted/${isReset ? 'LoginPage' : 'ForgotPW'}`);
              this.setState({
                isReset: !isReset,
              });
            }}
            image={(
              <Logo style={{ marginBottom: 30 }}/>
            )}
          />
          <GetStarted
            history={history}
            pathname={pathname}
            search={search}
            onBack={() => {
              analytics.track('GetStarted/LoginPage');
              history.replace('/login');
              this.setState({
                showRegister: false,
              });
            }}
            show={showRegister}
          />
        </div>
      );
    }
  },
);

export default LoginPage;
