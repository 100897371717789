import React from 'react';
import { useSelector } from 'react-redux';
import { Empty } from 'antd';

import FileReelWithUpload from '../common/files/FileReelWithUpload'

export default ({
  equipmentId
}) => {
  const equipmentFiles = useSelector(state => state.equipment.equipmentFiles);
  const {
    [equipmentId]: files = [],
  } = equipmentFiles;

  if(files.length === 0) return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
  return (
    <div>
      <FileReelWithUpload files={files} isDisplay/>
    </div>
  );
}