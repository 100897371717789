import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import * as XLSX from 'xlsx';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Row, notification } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';

import StepsContainer from '../../common/containers/StepsContainer';
import FormImportSheetSelector from '../FormImport/FormImportSheetSelector';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FormUploadPreview from '../FormImport/FormUploadPreview';
import SheetHeaderMatcher from '../../common/excel/SheetHeaderMatcher';
import DrawerFooter from '../../common/containers/DrawerFooter';
import { massApproveRejectForms } from '../state/forms.actions';

const LAST_UPLOAD_STEPS = 2;
const NOTIFICATION_WARN_KEY = 'approvalFormImportKey';

const headerOptions = [
  { key: 'formName', title: 'Form Name' },
  { key: 'formNumber', title: 'Form Number' },
  { key: 'approveOrReject', title: 'Approve/Reject (A/R)' },
];
const MAPPING_STEP = 1;

export default function ApproveFormImportDrawer({
  uploadFile,
  onClose,
}) {
  const dispatch = useDispatch();
  const formApprovalMap = useSelector((state) => state.forms.approvals);

  const [selectedSheetName, setSelectedSheetName] = useState(null);
  const [headerMapping, setHeaderMapping] = useState({});
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [massUploadData, setMassUploadData] = useState([]);

  const formApprovals = useMemo(() => {
    const tempArr = Object.values(formApprovalMap);
    return tempArr;
  }, [formApprovalMap]);

  const sheets = uploadFile?.Sheets ?? null;

  const selectedSheet = useMemo(() => {
    if (!sheets || !selectedSheetName) return [];
    const {
      [selectedSheetName]: fullSheet = {},
    } = sheets;

    return XLSX.utils.sheet_to_json(fullSheet, { defval: '' });
  }, [sheets, selectedSheetName]);

  useEffect(() => {
    if (!uploadFile) return;
    const {
      SheetNames = [],
    } = uploadFile;
    if (!selectedSheetName || (SheetNames.length === 1 && selectedSheetName !== SheetNames[0])) {
      setSelectedSheetName(SheetNames[0]);
    }
  }, [selectedSheetName, uploadFile]);

  const resetValues = () => {
    setCurrentStep(0);
    setSelectedSheetName(null);
    setHeaderMapping({});
    setMassUploadData([]);
  };

  const onMassUpload = useCallback(async (uploadData) => {
    const formApprovalKeyMap = formApprovals.reduce((acc, formApproval) => {
      const {
        templateName,
        number = '',
      } = formApproval;
      const key = `${templateName}-${number ?? ''}`;
      if (!acc[key]) {
        acc[key] = [];
      }

      acc[key].push(formApproval);
      return acc;
    }, {});

    const approvalIds = [];
    const rejectedIds = [];
    const idToFormIdMap = {};

    uploadData.forEach((data) => {
      const {
        formName,
        formNumber = '',
        approveOrReject,
      } = data;

      const key = `${formName}-${formNumber?.toString() ?? ''}`;
      const relevantApprovals = formApprovalKeyMap[key];
      if (!relevantApprovals?.length) return;

      relevantApprovals.forEach((formApproval) => {
        const {
          id,
          formId,
        } = formApproval;

        idToFormIdMap[id] = formId;

        if (approveOrReject?.toLowerCase() === 'a') {
          approvalIds.push(id);
        } else if (approveOrReject?.toLowerCase() === 'r') {
          rejectedIds.push(id);
        }
      });
    });

    return dispatch(massApproveRejectForms({
      approvalIds,
      rejectedIds,
      idToFormIdMap,
    }));
  }, [formApprovals]);

  const validateHeaderMappings = useCallback(() => {
    let isValid = true;
    headerOptions.forEach(({ key }) => {
      if (!headerMapping[key]) {
        isValid = false;
      }
    });

    if (!isValid) {
      notification.warn({
        key: NOTIFICATION_WARN_KEY,
        message: 'Warning',
        description: 'Please map all headers.',
      });
    }
    return isValid;
  }, [headerMapping, headerOptions]);

  const onBack = useCallback(() => setCurrentStep(currentStep - 1), [currentStep]);
  const onNext = useCallback(async () => {
    notification.close(NOTIFICATION_WARN_KEY);
    if (currentStep === MAPPING_STEP) {
      const isValid = validateHeaderMappings();
      if (!isValid) return;
    }

    if (currentStep < LAST_UPLOAD_STEPS) {
      setCurrentStep(currentStep + 1);
      return;
    }

    setLoading(true);
    const parsedData = massUploadData.map((data) => {
      const newData = {
        ...data,
      };
      delete newData.id;
      return newData;
    });
    const result = await onMassUpload(parsedData);
    if (!result) {
      notification.warn({
        key: NOTIFICATION_WARN_KEY,
        message: 'Warning',
        description: 'Upload failed. Please try again.',
      });
    } else {
      notification.open({
        key: NOTIFICATION_WARN_KEY,
        message: 'Success',
        description: 'Upload successful.',
        icon: <CheckCircleOutlined style={{ color: 'green' }} />,
      });
      onClose();
    }

    setLoading(false);
  }, [currentStep, massUploadData, onMassUpload, validateHeaderMappings]);

  useEffect(() => {
    if (!uploadFile) {
      resetValues();
    }
  }, [uploadFile]);

  return (
    <Drawer
      title="Import Approvals"
      visible={!!uploadFile}
      onClose={onClose}
      width="80%"
    >
      <StepsContainer
        currentStep={currentStep}
        steps={[
          {
            title: 'Select Sheet',
            content: (
              <>
                <p>
                  Please select the sheet you want to import.
                  Please note that the form name must match the name in
                  OnTraccr exactly (case sensitive).
                </p>
                <FormImportSheetSelector
                  workbook={uploadFile}
                  selectedSheetName={selectedSheetName}
                  onSheetChanged={setSelectedSheetName}
                />
              </>
            ),
          },
          {
            title: 'Select Headers',
            content: (
              <SheetHeaderMatcher
                headerOptions={headerOptions}
                selectedSheet={selectedSheet}
                headerMapping={headerMapping}
                setHeaderMapping={setHeaderMapping}
              />
            ),
          },
          {
            title: 'Preview',
            content: (
              <FormUploadPreview
                options={headerOptions}
                selectedSheet={selectedSheet}
                headerMapping={headerMapping}
                onMassUploadDataChanged={setMassUploadData}
              />
            ),
          },
        ]}
      />
      <DrawerFooter>
        <Row justify="end" align="middle" gutter={8}>
          {currentStep > 0 && (
            <OnTraccrButton
              title="Back"
              type="back"
              id="customer-add-project-footer-back"
              onClick={onBack}
            />
          )}
          <OnTraccrButton
            title={currentStep < LAST_UPLOAD_STEPS ? 'Next' : 'Submit'}
            onClick={onNext}
            disabled={!uploadFile}
            loading={loading}
          />
        </Row>
      </DrawerFooter>
    </Drawer>
  );
}

ApproveFormImportDrawer.propTypes = {
  uploadFile: PropTypes.shape({
    SheetNames: PropTypes.arrayOf(PropTypes.string),
    Sheets: PropTypes.shape({}),
  }),
  onClose: PropTypes.func.isRequired,
};

ApproveFormImportDrawer.defaultProps = {
  uploadFile: null,
};
