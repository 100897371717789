import React from 'react';
import { Row } from 'antd';

import LiveFeedCard from './LiveFeedCard';

import ProfileAvatar from '../../common/ProfileAvatar';

export default ({
  user = {},
  onClick
}) => {
  const {
    name = '',
    position,
  } = user;

  return (
    <LiveFeedCard style={{ width: 200 }} onClick={onClick}>
      <Row style={{
        padding: '15px 0px',
        width: '100%',
      }}>
        <ProfileAvatar
          name={name}
          role={position}
        />
      </Row>
    </LiveFeedCard>
  );
}
