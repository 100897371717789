import React, {
  useCallback,
  useRef,
  useEffect
} from 'react';
import { Form } from 'antd';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import { DeleteOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import {
  getProjectScheduleOfValuePDFRows,
  addProjectScheduleOfValuePDFRows,
  deleteProjectScheduleOfValuePDFRows,
} from '../../state/projects.actions';
import ScheduleOfValuesPDFPreviewFormInput from './ScheduleOfValuesPDFPreviewFormInput';

const ScheduleOfValuesPDFPreviewForm = ({
  projectId,
  templateId,
}) => {
  const dispatch = useDispatch();
  const scheduleOfValuePDFRows = useSelector(state => state.projects.scheduleOfValuePDFRows);
  const formRef = useRef(null);

  useEffect(() => {
    dispatch(getProjectScheduleOfValuePDFRows({
      projectId,
      templateId,
    }));
  }, [projectId, templateId]);

  const addFormItem = useCallback(() => {
    dispatch(addProjectScheduleOfValuePDFRows({
      projectId,
      templateId,
      payload: {
        key: '',
        value: '',
      },
    }));
  }, [projectId, templateId]);

  const removeFormItem = useCallback((rowId) => {
    return () => {
      dispatch(deleteProjectScheduleOfValuePDFRows({
        projectId,
        templateId,
        rowId,
      }));
    };
  }, [projectId, templateId]);

  return (
    <>
      <span>Fields will be added to the top of the pdf.</span>
      <OnTraccrButton style={{ float: 'right' }} title="Add field to form" onClick={addFormItem}/>
      <Form ref={formRef}>
        <div className="schedule-of-value-pdf-form">
          {
            scheduleOfValuePDFRows.map((item, i) => (
              <div key={item.id} className="schedule-of-value-pdf-form-row">
                <span className="schedule-of-value-pdf-form-row-label">{`${i + 1}. `}</span>
                <Form.Item>
                  <ScheduleOfValuesPDFPreviewFormInput
                    title='Label'
                    projectId={projectId}
                    templateId={templateId}
                    pdfRow={item}
                    type={'text'}
                    property={'key'}
                  />
                </Form.Item>
                <Form.Item>
                  <ScheduleOfValuesPDFPreviewFormInput
                    title='Value'
                    projectId={projectId}
                    templateId={templateId}
                    pdfRow={item}
                    type={'text'}
                    property={'value'}
                  />
                </Form.Item>
                <DeleteOutlined onClick={removeFormItem(item.id)}/>
              </div>
            ))
          }
        </div>
      </Form>
    </>
  );
};

export default ScheduleOfValuesPDFPreviewForm;