import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { compareArrays, getIdMap } from './helpers';

export default {};

export const stateUpdateLabels = ({
  id,
  stateItemToLabel = {},
  stateLabelToItem = {},
  addedLabels = [],
  removedLabels = [],
}) => {
  const newItemToLabel = { ...stateItemToLabel };
  const newLabelToItem = { ...stateLabelToItem };
  const {
    [id]: oldLabels = [],
  } = newItemToLabel;
  const oldUserLabelSet = new Set(oldLabels);
  addedLabels.forEach((label) => {
    const { labelId } = label;
    if (!(labelId in newLabelToItem)) {
      newLabelToItem[labelId] = [id];
    } else {
      newLabelToItem[labelId].push(id);
    }

    if (!oldUserLabelSet.has(labelId)) {
      const {
        [id]: oldUserLabelArray = [],
      } = newItemToLabel;
      newItemToLabel[id] = oldUserLabelArray.concat([labelId]);
      oldUserLabelSet.add(labelId);
    }
  });

  removedLabels.forEach((labelId) => {
    newLabelToItem[labelId] = (newLabelToItem[labelId] || [])
      .filter((itemId) => itemId !== id);
    if (newLabelToItem[labelId].length === 0) {
      delete newLabelToItem[labelId];
    }
    const {
      [id]: oldItemLabels = [],
    } = newItemToLabel;
    newItemToLabel[id] = oldItemLabels.filter((itemLabel) => itemLabel !== labelId);
  });

  return {
    newItemToLabel,
    newLabelToItem,
  };
};

export const getLabelsState = (labels, key) => {
  const itemToLabel = {};
  const labelToItem = {};
  labels.forEach((label) => {
    const { [key]: itemId, labelId } = label;
    if (!(itemId in itemToLabel)) {
      itemToLabel[itemId] = [];
    }
    itemToLabel[itemId].push(labelId);

    if (!(labelId in labelToItem)) {
      labelToItem[labelId] = [];
    }
    labelToItem[labelId].push(itemId);
  });

  return {
    itemToLabel,
    labelToItem,
  };
};

/**
 * Formats payload labels for api calls
 * @param {object} payload - payload to be edited
 * @param {string} id - optional (needed for updates), id of object being updated
 * @param {object} toLabelMap - optional (needed for updates), map of ids of items to labels
 * @returns {object} - copy of payload, labels removed, addedLabels and/or removedLabels added
 */
export const decoratePayloadWithLabels = (payload = {}, id = null, toLabelMap = {}) => {
  const ourPayload = {
    ...payload,
  };
  if (ourPayload.labels) {
    const {
      [id]: oldLabels = [],
    } = toLabelMap;

    const { labels: newLabels } = ourPayload;

    // oldLabels is an array of labelIds
    // newLabels is an array of objects with id property
    const {
      removed: removedLabels,
      added: addedLabels,
    } = compareArrays(oldLabels.map((l) => ({ id: l })), newLabels, 'id');

    if (addedLabels?.length) {
      ourPayload.addedLabels = addedLabels.map(({ id: labelId, title }) => (
        { labelId, title }
      ));
    }
    if (removedLabels?.length) {
      ourPayload.removedLabels = removedLabels.map(({ id: labelId }) => (
        labelId
      ));
    }
    delete ourPayload.labels;
  }
  return ourPayload;
};

export const useGetContactLabels = (id, isVendor) => {
  const allLabels = useSelector((state) => (state.labels));
  const labels = useMemo(() => allLabels.filter((label) => label.type === (isVendor ? 'vendors' : 'customers')), [isVendor, allLabels]);
  const customerToLabel = useSelector((state) => state.customers.customerToLabel);
  const vendorToLabel = useSelector((state) => state.vendors.vendorToLabel);

  const labelIdMap = useMemo(() => getIdMap(labels), [labels]);

  const {
    [id]: customerLables = [],
  } = customerToLabel;
  const {
    [id]: vendorLabels = [],
  } = vendorToLabel;

  const initialLabels = useMemo(() => (
    (isVendor
      ? vendorLabels
      : customerLables
    ).map((labelId) => labelIdMap[labelId]).filter((label) => label)
  ), [
    isVendor,
    customerLables,
    labelIdMap,
  ]);

  return initialLabels;
};
