import React from 'react';
import { Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import userAttributes from '../../assets/images/NUX/userAttributes.png';

import DisplayText from '../../common/text/DisplayText';

const UserCol = ({ title, description }) => (
  <Col span={12}>
    <DisplayText
      title={title}
      style={{
        textAlign:'left',
        fontFamily:'roboto-bold',
        fontSize:18,
        marginBottom:8,
      }}
    />
    <DisplayText
      title={description}
      style={{
        textAlign:'left',
        fontFamily:'roboto-regular',
        fontSize:14,
      }}
    />
  </Col>
);

const UserRow = ({ content }) => (
  content.map(([col1,col2]) => (
    <Row gutter={16}>
      <UserCol {...col1}/>
      <UserCol {...col2}/>
    </Row>
  ))
);

const getContent = (t) => [
  [
    {
      title: 'Username',
      description: 'Must be unique across all Ontraccr accounts.'
    },
    {
      title: 'Email',
      description: 'Must be a valid email address. Needed to send the user their login info and other resources.',
    },
  ],
  [
    {
      title: 'Hourly Wage',
      description: 'This is used for various reports, including payroll and budget tracking.',
    },
    {
      title: 'Position',
      description:'Choose the role for the user. Each role has a different set of permissions.',
    },
  ],
  [
    {
      title: 'Four Digit PIN',
      description:'This is needed for the user to log in to Shared Tablet Mode.',
    },
    {
      title: `${t('Project', { count: 2 })} & Teams`,
      description:`Choose to assign the user to any ${t('Project', { count: 2 }).toLowerCase()} and/or teams.`,
    },
  ],
];

export default function NuxUserAttributes({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const content = getContent(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        padding:'0px 40px'
      }}
      customContent={
        <Row style={{ width: '100%', height:300,}} align='top'>
          <Col span={16}>
            <UserRow content={content}/>
          </Col>
          <Col span={8}>
            <img
              className='nux-screenshot'
              src={userAttributes}
              alt='User Attributes'
              style={{
                height:300,
                width:181,
              }}
            />
          </Col>
        </Row>
      }
      tutorialLink={'SM1. Managing Users.mp4'}
    />
  );
}
