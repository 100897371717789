export default {};

export const fields = {
  text: [
    { label: 'Project Name', value: 'name', type: 'text' },
    { label: 'Customer First Name', value: 'customer_first_name', type: 'text' },
    { label: 'Customer Last Name', value: 'customer_last_name', type: 'text' },
    { label: 'Customer Full Name', value: 'customer_full_name', type: 'text' },
    { label: 'Customer Email', value: 'customer_email', type: 'text' },
    { label: 'Customer Phone', value: 'customer_phone', type: 'text' },
    { label: 'Address', value: 'location.property_address', type: 'text' },
    { label: 'Mailing Address', value: 'mailing_address', type: 'text' },
    { label: 'Project Type', value: 'project_type', type: 'text' },
  ],
  gpsLocation: [
    { label: 'GPS Coordinates', value: 'location.coordinates', type: 'gpsLocations' },
  ],
};
