import React from 'react';
import { Row, Col } from 'antd';

import BorderlessButton from '../common/buttons/BorderlessButton';
import Colors from '../constants/Colors';
import Badge from '../common/badge'

const borderRadius = 6;
const buttonStyle = {
  backgroundColor:'inherit',
  height: 26,
  padding:0,
  borderRadius,
  color:'white',
  fontSize:12,
};

export default ({
  selected,
  onSelect,
  approvalCount
}) => (
<Row style={{
          marginLeft: 10,
          marginRight: 10,
          marginTop:20,
          marginBottom: 20,
          width: '100%',
          height:30,
          borderRadius,
          backgroundColor:Colors.DARK_GRAY, padding:2 }}>
        <Col
          span={12}
          style={{
            borderRadius,
            height:'100%',
            backgroundColor:selected === 'submitted' ? Colors.ONTRACCR_BLACK: 'transparent'
          }}
          >
          <BorderlessButton
            style={buttonStyle}
            onClick={() => onSelect('submitted')}
            title={`Submitted`}
            iconNode={approvalCount ? <Badge count={approvalCount}/> :null}
          >
          </BorderlessButton>
        </Col>
        <Col
          span={12}
          style={{
            borderRadius,
            backgroundColor:selected === 'default' ? Colors.ONTRACCR_BLACK: 'transparent',
            height:'100%',
          }}>
            <BorderlessButton
              style={buttonStyle}
              title='All'
              onClick={() => onSelect('default')}
            />
        </Col>
      </Row>
);
