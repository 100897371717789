import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Drawer, Row, Table,
} from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import {
  getSageData, getSageTimeSync, resolveSageTime, writeSageTime,
} from '../../state/settings.actions';
import { getIdMap } from '../../../helpers/helpers';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import SageTimeSyncTaskDetails from './SageTimeSyncTaskDetails';

const resolvedTitle = () => <h3>Successful / Resolved</h3>;
const unresolvedTitle = () => <h3>Errors / Unresolved</h3>;
const inProgressTitle = () => <h3>In Progress</h3>;

const seconds = (ms) => ms * 1000;
const minutes = (s) => seconds(s) * 60;

const writtenThreshold = minutes(15) * 4;
export default function SageTimeSyncDrawer({
  visible,
  onClose,
  selectedIntegration,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    sageTime: {
      [selectedIntegration?.id]: sageTimeMap = {},
    } = {},
  } = useSelector((state) => state.settings);
  const users = useSelector((state) => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const projects = useSelector((state) => state.projects.projects);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const costcodeMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const [selected, setSelected] = useState([]);
  const [selectedTask, setSelectedTask] = useState();
  const [loading, setLoading] = useState();

  const {
    unresolvedTasks,
    inProgressTasks,
    resolvedTasks,
  } = useMemo(() => {
    const unresolved = [];
    const inProgress = [];
    const resolved = [];
    const now = DateTime.local().toMillis();
    Object.values(sageTimeMap).sort((a, b) => (a.statuses[0] && b.statuses[0]
      ? a.statuses[0].lastUpdated - b.statuses[0].lastUpdated : 1))
      .forEach((task = {}) => {
        const { statuses = [] } = task;
        if (!statuses?.length) return;
        if (statuses[0]?.resolved || statuses[0]?.status === 'synced') resolved.push(task);
        else if (statuses[0]?.status === 'written' && (now - statuses[0].lastUpdated < writtenThreshold )) inProgress.push(task);
        else if (statuses[0]?.status === 'writeRetry') inProgress.push(task);
        else unresolved.push(task);
      });
    return {
      unresolvedTasks: unresolved,
      inProgressTasks: inProgress,
      resolvedTasks: resolved,
    };
  }, [sageTimeMap]);

  useEffect(() => {
    if (visible) {
      const loadData = async () => {
        setLoading(true);
        await Promise.all([
          dispatch(getSageData(selectedIntegration?.id, 'sage100')),
          dispatch(getSageTimeSync({ integrationId: selectedIntegration?.id })),
        ]);
        setLoading(false);
      };
      loadData();
    } else {
      setSelectedTask();
      setLoading(false);
      setSelected([]);
    }
  }, [visible, selectedIntegration]);

  useEffect(() => {
    if (!selectedTask) {
      if (unresolvedTasks?.length) setSelectedTask(unresolvedTasks[0]);
      else if (resolvedTasks?.length) setSelectedTask(resolvedTasks[0]);
    }
  }, [unresolvedTasks, resolvedTasks]);

  const postSelected = useCallback(async () => {
    const selectedTasks = new Set(selected);
    setLoading(true);
    if (selectedTasks.size) {
      await dispatch(writeSageTime(selectedIntegration?.id, Array.from(selectedTasks)));
    }
    setLoading(false);
  }, [selected, selectedIntegration]);

  const resolveSelected = useCallback(async () => {
    const selectedTasks = new Set(selected);
    setLoading(true);
    if (selectedTasks.size) {
      await dispatch(resolveSageTime(selectedIntegration?.id, Array.from(selectedTasks), true));
    }
    setLoading(false);
  }, [selected, selectedIntegration]);

  const refreshData = useCallback(async () => {
    setLoading(true);
    await dispatch(getSageTimeSync({ integrationId: selectedIntegration?.id }));
    setLoading(false);
  }, [selectedIntegration]);

  const renderUserId = useCallback((userId) => (userMap[userId]?.name ?? 'Unknown'), [userMap]);
  const renderDate = useCallback((_, { sagePayload: { Day } = {} } = {}) => Day, []);
  const renderHours = useCallback((_, { sagePayload: { Value } = {} } = {}) => Value, []);
  const renderProject = useCallback((_, { projectId } = {}) => projectMap[projectId]?.name ?? '-', [projectMap]);
  const renderCostcode = useCallback((_, { costcodeId } = {}) => costcodeMap[costcodeId]?.name ?? '-', [costcodeMap]);

  const taskColumns = useMemo(() => ([
    {
      title: 'User',
      dataIndex: 'userId',
      key: 'userId',
      render: renderUserId,
    }, {
      title: 'Date',
      render: renderDate,
    }, {
      title: 'Hours',
      render: renderHours,
    }, {
      title: t('Project'),
      render: renderProject,
    }, {
      title: t('Costcode'),
      render: renderCostcode,
    },
  ]), [
    renderUserId,
    renderHours,
    renderProject,
    renderCostcode,
  ]);

  const resolvedEntriesSelected = useMemo(() => {
    const resolvedMap = getIdMap(resolvedTasks);
    return selected.some((id) => resolvedMap[id]);
  }, [selected, resolvedTasks]);

  const rowClassName = useCallback((record) => ((record?.id === selectedTask?.id) ? 'selected-row' : 'ontraccr-row'), [selectedTask]);

  return (
    <Drawer
      title="Sage 100 Time Entry Syncing"
      visible={visible}
      width={1300}
      onClose={onClose}
      style={{
        height: '100%',
      }}
    >
      <div
        style={{
          height: '100%',
        }}
      >
        <Row
          gutter={[16, 16]}
          style={{
            height: '100%',
          }}
        >
          <Col
            span={13}
            style={{
              height: '100%',
            }}
          >
            <Row>
              <Col
                flex={1}
                style={{
                  borderBottom: '1px solid #e9e9e9',
                  background: '#fff',
                  paddingBottom: 10,
                }}
              >
                <OnTraccrButton
                  title="Refresh"
                  style={{ marginRight: 45 }}
                  icon={<ReloadOutlined />}
                  disabled={loading}
                  onClick={refreshData}
                />
                <OnTraccrButton
                  title="Retry Selected"
                  style={{ marginRight: 45 }}
                  disabled={loading || !selected?.length}
                  onClick={postSelected}
                />
                <OnTraccrButton
                  title="Resolve Selected"
                  style={{ marginRight: 45 }}
                  disabled={loading || !selected?.length || resolvedEntriesSelected}
                  onClick={resolveSelected}
                />
              </Col>
            </Row>
            <Row
              style={{
                height: '100%',
              }}
            >
              <Col
                flex={1}
                style={{
                  height: '100%',
                  overflowY: 'scroll',
                  paddingTop: 10,
                  paddingRight: 20,
                  paddingBottom: 10,
                }}
              >
                <Row>
                  <Col flex={1}>
                    <Table
                      title={unresolvedTitle}
                      loading={loading}
                      rowKey="id"
                      rowSelection={{
                        type: 'checkbox',
                        onChange: (newSelected) => setSelected(newSelected),
                        selectedRowKeys: selected,
                        hideSelectAll: true,
                      }}
                      onRow={(entry) => ({
                        onClick: () => setSelectedTask(entry),
                      })}
                      rowClassName={rowClassName}
                      pagination={false}
                      dataSource={unresolvedTasks}
                      columns={taskColumns}
                      locale={{ emptyText: 'No unresolved errors' }}
                      bordered
                      style={{
                        paddingTop: 10,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col flex={1}>
                    <Table
                      title={inProgressTitle}
                      loading={loading}
                      rowKey="id"
                      rowSelection={{
                        type: 'checkbox',
                        onChange: (newSelected) => setSelected(newSelected),
                        selectedRowKeys: selected,
                        hideSelectAll: true,
                      }}
                      onRow={(entry) => ({
                        onClick: () => setSelectedTask(entry),
                      })}
                      rowClassName={rowClassName}
                      pagination={false}
                      dataSource={inProgressTasks}
                      columns={taskColumns}
                      locale={{ emptyText: 'No syncs in progress' }}
                      bordered
                      style={{
                        paddingTop: 10,
                      }}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col flex={1}>
                    <Table
                      title={resolvedTitle}
                      loading={loading}
                      rowKey="id"
                      rowSelection={{
                        type: 'checkbox',
                        onChange: (newSelected) => setSelected(newSelected),
                        selectedRowKeys: selected,
                        hideSelectAll: true,
                      }}
                      onRow={(entry) => ({
                        onClick: () => setSelectedTask(entry),
                      })}
                      rowClassName={rowClassName}
                      pagination={{
                        hideOnSinglePage: true,
                        pageSize: 20,
                        showSizeChanger: true,
                      }}
                      dataSource={resolvedTasks}
                      columns={taskColumns}
                      bordered
                      style={{
                        paddingTop: 10,
                      }}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
          <Col
            span={11}
            style={{
              overflowY: 'scroll',
              height: '100%',
              paddingTop: 10,
            }}
          >
            <SageTimeSyncTaskDetails
              loading={loading}
              selectedTask={selectedTask}
            />
          </Col>
        </Row>
      </div>
    </Drawer>
  );
}

SageTimeSyncDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
  visible: PropTypes.bool,
};

SageTimeSyncDrawer.defaultProps = {
  selectedIntegration: {},
  visible: false,
};
