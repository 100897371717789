import React from 'react';
import PropTypes from 'prop-types';
import { Col, Input, Row } from 'antd';
import { DeleteOutlined, PlusOutlined, ShareAltOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

function AddressBookHeader({
  searchInput,
  onSearchInputChange,
  onAddClick,
  onShareClick,
  onDeleteClick,
  selectedContacts,
  searchRef,
}) {
  return (
    <Row
      type="flex"
      gutter={14}
      style={{
        height: 32,
        marginBottom: 14,
      }}
    >
      <Col justify="start" style={{ flexGrow: 1 }}>
        <Input.Search
          ref={searchRef}
          type="search"
          className="searchbar"
          placeholder="Search"
          value={searchInput}
          style={{ width: '40%' }}
          onChange={onSearchInputChange}
          allowClear
        />
      </Col>
      <Col justify="end">
        <Row gutter={14}>
          <Col>
            <OnTraccrButton
              title="Add"
              onClick={onAddClick}
              icon={<PlusOutlined />}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title="Share"
              onClick={onShareClick}
              icon={<ShareAltOutlined />}
              disabled={!selectedContacts.length}
            />
          </Col>
          <Col>
            <OnTraccrButton
              title="Delete"
              onClick={onDeleteClick}
              icon={<DeleteOutlined />}
              disabled={!selectedContacts.length}
            />
          </Col>
        </Row>
      </Col>
    </Row>
  );
}

AddressBookHeader.propTypes = {
  searchInput: PropTypes.string,
  onSearchInputChange: PropTypes.func.isRequired,
  onAddClick: PropTypes.func.isRequired,
  onShareClick: PropTypes.func.isRequired,
  onDeleteClick: PropTypes.func.isRequired,
  selectedContacts: PropTypes.arrayOf(PropTypes.string),
  searchRef: PropTypes.node.isRequired,
};

AddressBookHeader.defaultProps = {
  searchInput: '',
  selectedContacts: [],
};

export default AddressBookHeader;
