import React, { useCallback, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Drawer, Row, Select } from 'antd';

// Import Components:
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import HoverHelp from '../../common/HoverHelp';

// Import Helpers
import { extractFormFieldOptions } from '../formHelpers';
import FormVariableTextInput from './selectors/FormVariableTextInput';

const CUSTOM_MSG_HOVER_TEXT = 'The default message will be used if the message field is left empty';
const MSG_FIELD_SELECT_HOVER_TEXT = 'If an input field is selected, it will take precedence over custom messages';

/** Push Notification Configurator Component */
const WorkflowPushNotificationConfigureDrawer = ({
  id,
  visible,
  onClose,
  onSubmit,
  data,
  setDataMap,
  sections,
}) => {
  const { message, messageField } = data || {};
  const [selectedMessageField, setSelectedMessageField] = useState(messageField);

  const messageFieldOptions = useMemo(() => extractFormFieldOptions({ types: new Set(['text']), sections }), [sections]);

  const onChange = useCallback((field) => (param) => {
    if (!setDataMap) return;
    let newVal;
    if (field === 'message') newVal = param;
    if (field === 'messageField') {
      setSelectedMessageField(param);
      newVal = param;
    }
    setDataMap((dataMap) => {
      const {
        [id]: oldData = {},
      } = dataMap;
      return {
        ...dataMap,
        [id]: {
          ...oldData,
          [field]: newVal,
        },
      };
    });
  }, [id, setDataMap]);

  return (
    <Drawer
      title="Configure Push Notification Options"
      width={750}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 60 }}
    >
      <Row style={{ marginTop: 10 }} justify="space-between">
        <Col>
          Custom Message:
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={
              <div style={{ maxWidth: 200 }}>
                {CUSTOM_MSG_HOVER_TEXT}
              </div>
            }
          />
        </Col>
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <FormVariableTextInput
          textAreaProps={{
            defaultValue: message,
          }}
          onTextChange={onChange('message')}
          text={message}
          visible={visible}
        />
      </Row>
      <Row style={{ marginTop: 10 }} justify="space-between">
        <Col>
          Select Field Input as Custom Message:
        </Col>
        <Col>
          <HoverHelp
            placement="topRight"
            content={
              <div style={{ maxWidth: 200 }}>
                {MSG_FIELD_SELECT_HOVER_TEXT}
              </div>
            }
          />
        </Col>
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          allowClear
          showSearch
          value={selectedMessageField}
          options={messageFieldOptions}
          onSelect={onChange('messageField')}
          style={{ width: '100%' }}
          optionFilterProp="label"
        />
      </Row>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  )
};

WorkflowPushNotificationConfigureDrawer.propTypes = {
  id: PropTypes.string,
  visible: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  data: PropTypes.shape({}),
  setDataMap: PropTypes.func,
  sections: PropTypes.array,
};

WorkflowPushNotificationConfigureDrawer.defaultProps = {
  id: '',
  visible: false,
  onClose: () => null,
  onSubmit: () => null,
  data: {},
  setDataMap: () => null,
  sections: [],
};

export default WorkflowPushNotificationConfigureDrawer;
