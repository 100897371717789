import {
  GET_SAVED_PROJECT_DASHBOARDS,
  SET_PROJECT_DASHBOARD_EDIT,
  SELECT_PROJECT_DASHBOARD,
  CREATE_PROJECT_DASHBOARD,
  UPDATE_PROJECT_DASHBOARD,
  DELETE_PROJECT_DASHBOARD,
  FAVORITE_PROJECT_DASHBOARD,
  GET_PROJECT_DASHBOARD_ANALYTICS_DATA,
  SET_INITIAL_PROJECT_DASHBOARD,
  GET_SAVED_PROJECT_ANALYTICS_REPORTS,
  DELETE_PROJECT_ANALYTICS_REPORT,
  CREATE_PROJECT_ANALYTICS_REPORT,
  UPDATE_PROJECT_ANALYTICS_REPORT,
  SET_PROJECT_ANALYTICS_CONFIG,
  UPDATE_PROJECT_ANALYTICS_FILTERS,
  SELECT_SAVED_PROJECT_REPORT,
  GET_PROJECT_ANALYTICS_DATA,
  CLEAR_PROJECT_ANALYTICS_CONFIG,
} from '../../../state/actionTypes';

import {
  initialReports,
  initialDashboard,
  createAnalyticsReport,
  deleteSavedAnalyticsReport,
  getAnalyticsData,
  getSavedAnalyticsReports,
  selectSavedReport,
  setAnalyticsConfig,
  updateAnalyticsFilters,
  updateAnalyticsReport,
  clearAnalyticsConfig,
  getSavedDashboards,
  setDashboardEdit,
  selectDashboard,
  createDashboard,
  updateDashboard,
  deleteDashboard,
  getDashboardAnalyticsData,
  setInitialDashboard,
  favoriteDashboard,
} from '../../../helpers/dashboardAnalytics';

const initialState = {
  dashboard: initialDashboard,
  reports: initialReports,
};

export default function projectAnalyticsActions(state = initialState, action) {
  switch (action.type) {
    case GET_SAVED_PROJECT_DASHBOARDS: {
      return getSavedDashboards(action, state);
    }
    case SET_PROJECT_DASHBOARD_EDIT: {
      return setDashboardEdit(action, state);
    }
    case SELECT_PROJECT_DASHBOARD: {
      return selectDashboard(action, state);
    }
    case CREATE_PROJECT_DASHBOARD: {
      return createDashboard(action, state);
    }
    case UPDATE_PROJECT_DASHBOARD: {
      return updateDashboard(action, state);
    }
    case DELETE_PROJECT_DASHBOARD: {
      return deleteDashboard(action, state);
    }
    case FAVORITE_PROJECT_DASHBOARD: {
      return favoriteDashboard(action, state);
    }
    case GET_PROJECT_DASHBOARD_ANALYTICS_DATA: {
      return getDashboardAnalyticsData(action, state);
    }
    case SET_INITIAL_PROJECT_DASHBOARD: {
      return setInitialDashboard(action, state);
    }
    case GET_SAVED_PROJECT_ANALYTICS_REPORTS: {
      return getSavedAnalyticsReports(action, state);
    }
    case DELETE_PROJECT_ANALYTICS_REPORT: {
      return deleteSavedAnalyticsReport(action, state);
    }
    case CREATE_PROJECT_ANALYTICS_REPORT: {
      return createAnalyticsReport(action, state);
    }
    case UPDATE_PROJECT_ANALYTICS_REPORT: {
      return updateAnalyticsReport(action, state);
    }
    case SET_PROJECT_ANALYTICS_CONFIG: {
      return setAnalyticsConfig(action, state);
    }
    case UPDATE_PROJECT_ANALYTICS_FILTERS: {
      return updateAnalyticsFilters(action, state);
    }
    case SELECT_SAVED_PROJECT_REPORT: {
      return selectSavedReport(action, state);
    }
    case GET_PROJECT_ANALYTICS_DATA: {
      return getAnalyticsData(action, state);
    }
    case CLEAR_PROJECT_ANALYTICS_CONFIG: {
      return clearAnalyticsConfig(state);
    }
    default:
      return state;
  }
}
