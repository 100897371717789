import React from 'react';
import {
  Row,
  Col,
  Checkbox,
  Popover,
  Input,
} from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { withTranslation } from 'react-i18next';
import { FixedSizeList as List } from 'react-window';

import DisplayText from '../../common/text/DisplayText';
import BorderlessButton from '../../common/buttons/BorderlessButton';

class ReportFilterCard extends React.Component {
  constructor(props) {
    super(props);
    const {
      content = [],
    } = this.props;
    this.state = {
      data: content,
      checked:new Set(content),
    };
  }

  componentDidUpdate(prevProps) {
    const {
      content: prevContent,
    } = prevProps;
    const {
      content,
    } = this.props;
    if (prevContent !== content) {
      const {
        checked: oldChecked,
      } = this.state;
      const newState = {
        data: content,
      };
      if (
        prevContent?.every((item) => oldChecked?.has(item))
        && oldChecked.size === prevContent?.length
      ) {
        // If user hasnt modified the checked
        // And new item is added we auto check it
        newState.checked = new Set(content);
      }
      this.setState(newState);
    }
  }

  generateMenu() {
    const {
      content = [],
      onFilterToggle,
      t,
    } = this.props;
    const {
      data = [],
      checked,
    } = this.state;
    return (
      <div style={{width: 350}}>
        <Row>
          <Col>
            <Input.Search
              className='searchbar'
              placeholder="Search"
              onChange={(e) => {
                const {
                  target: {
                    value,
                  } = {}
                } = e;
                if(!value) {
                  return this.setState({ data: content });
                }
                this.setState({
                  data: content.filter(item => item.toLowerCase().includes(value.toLowerCase())),
                });
              }}
              allowClear
              style={{
                marginBottom:10,
                width:150,
              }}
            />
          </Col>
          <Col>
            <BorderlessButton
              title='Clear All'
              onClick={() => {
                const checked = new Set();
                onFilterToggle(checked)
                this.setState({
                  checked,
                });
            }}
            />
          </Col>
          <Col>
            <BorderlessButton
              title='Select All'
              onClick={() => {
                const checked = new Set(content);
                onFilterToggle(checked)
                this.setState({
                  checked,
                })
              }}
            />
          </Col>
        </Row>
        <List
          height={Math.min(data.length * 25, 300)}
          itemCount={data.length}
          itemSize={25}
          width={350}
        >
         {
          ({ index, style }) => {
            const item = data[index];
            return (
              <Row gutter={8} align='middle' key={item + index} style={style}>
                <Col>
                  <Checkbox
                    checked={checked.has(item)}
                    onChange={(e) => {
                      const newChecked = new Set(checked);
                      if(e.target.checked) {
                        newChecked.add(item);
                      } else {
                        newChecked.delete(item);
                      }
                      this.setState({
                        checked:newChecked,
                      });
                      onFilterToggle(newChecked);
                    }}
                  />
                </Col>
                <Col>
                  <DisplayText
                    title={t(item)}
                    style={{
                      marginBottom: 0,
                      maxWidth: 300,
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      whiteSpace: 'nowrap'
                    }}
                  />
                </Col>
              </Row>
            );
          }
         }
        </List>
      </div>
    );
  }

  renderContent() {
    const { content = [], title } = this.props;
    return content.length > 0 ?
      <Popover
        content={this.generateMenu()}
        trigger='click'
        placement='bottomRight'
        getPopupContainer={node => node.parentNode}
      >
        <BorderlessButton
          title={title}
          style={{marginBottom:0, padding: 0,fontSize: 12, height: 22}}
          iconNode={<DownOutlined/>}
        />
      </Popover> :
      <DisplayText title={title} style={{margin:0}}/>
  }
  render() {
    const {
      onCheckChanged,
      checkDisabled,
      checked = true,
      hoverHelp = null,
    } = this.props;
    return <Row 
      style={{
        borderRadius: 6,
        borderWidth: 1,
        borderColor: 'gray',
        borderStyle: 'solid',
        padding: 5,
        marginTop: 20,
        backgroundColor: 'white',
      }}
      align="middle"
      gutter={4}
    >
      <Col>
        <Checkbox
          disabled={checkDisabled}
          onChange={(e) => onCheckChanged(e.target.checked)}
          checked={checked}
        />
      </Col>
      <Col>
        {this.renderContent()}
      </Col>
      {hoverHelp && (
        <Col>
          {hoverHelp}
        </Col>
      )}
    </Row>;
  }
}

export default withTranslation()(ReportFilterCard);
