/* eslint-disable default-param-last */
import React, { useState, useCallback } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import PropTypes from 'prop-types';
import { FormHelpers } from 'ontraccr-common';

import FormFieldAdd from './FormFieldAdd';
import FormField from './FormField';

export default function FormFieldList({
  id,
  projectId,
  fields = [],
  onSave,
  onDelete,
  isDisplay = false,
  isAdd = false,
  sections = [],
  isBoardCards,
  isExternalForm,
  divisions,
  templateId,
  name,
  shouldHideCustomRenderingFields = false,
  responses = {},
  isDetailView = false,
}) {
  const [showDrawer, setShowDrawer] = useState(false);
  const [editField, setEditField] = useState();
  const onEdit = useCallback((field) => () => {
    const {
      configProps: {
        shouldSavePresetTableSelections = false,
        presetData = {},
      },
    } = field;

    const initializedField = { ...field };
    initializedField.previewProps = shouldSavePresetTableSelections
      ? presetData
      : {};
    setShowDrawer(true);
    setEditField(initializedField);
  }, []);

  const onEditComplete = useCallback(() => {
    setEditField();
  }, []);

  return (
    <div
      style={{
        height: '100%',
        width: '100%',
        overflow: 'scroll',
        padding: 10,
      }}
    >
      <Droppable droppableId={id} type="FIELD" key={id}>
        {({ droppableProps, innerRef, placeholder }) => (
          // eslint-disable-next-line react/jsx-props-no-spreading
          <div {...droppableProps} ref={innerRef}>
            {fields.map((field = {}, idx) => {
              const {
                configProps: {
                  hasConditionalRendering = false,
                  conditionalRenderingFormula,
                } = {},
              } = field ?? {};

              // If a field has conditional rendering, and the formula is complete,
              // and the formula returns false, we don't render the field
              if (
                shouldHideCustomRenderingFields
                && hasConditionalRendering
                && FormHelpers.isConditionalRenderingFormulaComplete(conditionalRenderingFormula)
                && !FormHelpers.executeConditionalRenderingFormula({
                  formula: conditionalRenderingFormula,
                  responses,
                })
              ) {
                return null;
              }

              return (
                <FormField
                  // eslint-disable-next-line react/no-array-index-key
                  key={idx}
                  projectId={projectId}
                  sectionId={id}
                  field={field}
                  index={idx}
                  onDelete={onDelete}
                  onEdit={onEdit}
                  isDisplay={isDisplay}
                  isExternalForm={isExternalForm}
                  divisions={divisions}
                  templateId={templateId}
                  name={name}
                  isDetailView={isDetailView}
                />
              );
            })}
            {placeholder}
            {!isDisplay && (
              <FormFieldAdd
                field={editField}
                sectionId={id}
                onSave={onSave}
                onEdit={onEditComplete}
                isAdd={isAdd}
                sections={sections}
                isBoardCards={isBoardCards}
                isExternalForm={isExternalForm}
                divisions={divisions}
                templateId={templateId}
                projectId={projectId}
                name={name}
                showDrawer={showDrawer}
                setShowDrawer={setShowDrawer}
              />
            )}
          </div>
        )}
      </Droppable>
    </div>
  );
}

FormFieldList.propTypes = {
  id: PropTypes.string,
  projectId: PropTypes.string,
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.arrayOf(PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  onSave: PropTypes.func,
  onDelete: PropTypes.func,
  isDisplay: PropTypes.bool,
  isAdd: PropTypes.bool,
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  isBoardCards: PropTypes.bool,
  divisions: PropTypes.arrayOf(PropTypes.string),
  templateId: PropTypes.string,
  isExternalForm: PropTypes.bool,
  name: PropTypes.string,
  shouldHideCustomRenderingFields: PropTypes.bool,
  responses: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({}),
  }),
  isDetailView: PropTypes.bool,
};

FormFieldList.defaultProps = {
  id: null,
  projectId: null,
  fields: [],
  onSave: null,
  onDelete: null,
  isDisplay: false,
  isAdd: false,
  sections: [],
  isBoardCards: false,
  divisions: [],
  templateId: null,
  isExternalForm: false,
  name: null,
  shouldHideCustomRenderingFields: false,
  responses: {},
  isDetailView: false,
};
