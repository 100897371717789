import React from 'react';
import cc from 'country-code';
import LocalCurrency from 'locale-currency';
import { Select } from 'antd';
const { Option } = Select;
export default (props) => {
  const currencyMap = {};
  Object.keys(cc.countries).forEach((country) => {
    const { alpha2 } = cc.countries[country];
    const currency = LocalCurrency.getCurrency(alpha2);
    if(currency) currencyMap[currency] = country;
  });
  return (<Select
    style={{ width: 100, textAlign:'right'}}
    showSearch
    {...props}
  >
    {Object.keys(currencyMap).map((key) => {
      return (<Option value={key} key={key}>
        <span className={`flag-icon flag-icon-${key.toLowerCase()}`}></span>
        {key}
      </Option>);
    })}
  </Select>);
}
