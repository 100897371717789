import axios from 'axios';

import { request } from '../../helpers/requests';

export default {
  getAllApprovals: () => (
    request({
      call: axios.get('/approvals'),
      errMsg: 'Could not get approvals',
      defaultData: [],
      hideSuccessToast: true,
    })
  ),
};
