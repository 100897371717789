import React from 'react';
import { useSelector } from 'react-redux';
import { Popover, Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import WarningHeader from '../../common/text/WarningHeader';

import colors from '../../constants/Colors';
import { MB, storageUpgradeWarning } from '../../files/fileHelpers';
import Permissions from '../../auth/Permissions';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export default function LiveFeedFileAddButton(props) {
  const {
    open,
    history,
    onClick,
    style,
    useIcon = true,
  } = props;

  const {
    isOwner,
    storageUsed = 0,
    storageLimit = 100 * MB,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          storageLimit: stateStorageLimit,
          storageUsed: stateStorageUsed,
          userId: ownerId,
        } = {},
      } = {},
    } = state;
    return {
      storageLimit: stateStorageLimit,
      storageUsed: stateStorageUsed,
      isOwner: ownerId === Permissions.id,
    };
  });
  const overThresh = storageUsed >= storageLimit;
  const button = useIcon
    ? (
      <Button
        onClick={overThresh ? null : onClick}
        className="livefeed-file-icon"
        icon={(
          <FileAddOutlined
            style={{
              color: overThresh ? 'lightgray' : colors.ONTRACCR_RED,
            }}
          />
          )}
        style={{
          width: open ? 24 : 0,
          opacity: open ? 1 : 0,
          transition: 'width 0.5s, opacity 0.5s',
          overflow: 'hidden',
          ...style,
        }}
      />
    )
    : (
      <OnTraccrButton
        title="Add File"
        onClick={overThresh ? null : onClick}
        style={{ ...style }}
      />
    );

  if (!overThresh) return button;
  return (
    <Popover
      placement="topRight"
      trigger="click"
      disabled={!overThresh}
      title={<WarningHeader />}
      content={storageUpgradeWarning({
        title: 'Your account has reached its storage limit',
        history,
        isOwner,
      })}
    >
      {button}
    </Popover>
  );
}

LiveFeedFileAddButton.propTypes = {
  open: PropTypes.bool,
  history: PropTypes.shape({}),
  onClick: PropTypes.func,
  style: PropTypes.shape({}),
  useIcon: PropTypes.bool,
};

LiveFeedFileAddButton.defaultProps = {
  open: false,
  history: {},
  onClick: () => {},
  style: {},
  useIcon: true,
};
