import PropTypes from 'prop-types';

export default function useCheckTableMaxRows({
  configProps,
  currentRowsLength,
}) {
  const { openLimit = true, numAnswers = 1 } = configProps;
  if (openLimit) {
    return {
      maxExistingAllowed: Number.MAX_SAFE_INTEGER,
      shouldAddButtonBeEnabled: true,
    };
  }

  let maxAllowed = numAnswers - currentRowsLength;

  // Case should never occur, but just in case
  if (maxAllowed < 0) {
    maxAllowed = 0;
  }

  return {
    maxExistingAllowed: maxAllowed,
    shouldAddButtonBeEnabled: maxAllowed > 0,
  };
}

useCheckTableMaxRows.propTypes = {
  configProps: PropTypes.shape({
    openLimit: PropTypes.bool,
    numAnswers: PropTypes.number,
  }),
  currentRowsLength: PropTypes.number,
};

useCheckTableMaxRows.defaultProps = {
  configProps: {},
  currentRowsLength: 0,
};
