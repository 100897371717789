import React from 'react';
import { Drawer } from 'antd';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import ConditionalRenderingRow from '../FormBuilder/FormFields/ConditionalRenderingRow';

export default function WorkflowLogicalConditionNodeConfigureDrawer({
  sections = [],
  onChange,
  onSave,
  formulas = [],
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
  onClose,
  visible,
}) {
  const onSubmit = () => {
    onSave(formulas);
  };

  return (
    <Drawer
      title="Configure Logical Node"
      width={1300}
      onClose={onClose}
      visible={visible}
      bodyStyle={{ paddingBottom: 60 }}
      onMouseDown={(e) => e.stopPropagation()}
      push={{ distance: 550 }}
    >
      <ConditionalRenderingRow
        onFormulaChange={onChange}
        conditionalRenderingFormula={formulas}
        hasConditionalRendering
        showCheckbox={false}
        sections={sections}
        customers={customers}
        projects={projects}
        users={users}
        costcodes={costcodes}
        phases={phases}
        projectIdMap={projectIdMap}
        vendors={vendors}
        equipment={equipment}
        formTemplates={formTemplates}
        labels={labels}
        contactAddressBooks={contactAddressBooks}
        buckets={buckets}
      />
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

WorkflowLogicalConditionNodeConfigureDrawer.propTypes = {
  onChange: PropTypes.func.isRequired,
  sections: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  formulas: PropTypes.arrayOf(PropTypes.shape({
    field: PropTypes.string,
    operator: PropTypes.string,
    value: PropTypes.string,
  })),
  customers: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  projects: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  users: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  costcodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  phases: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  projectIdMap: PropTypes.shape({}),
  vendors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  equipment: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  formTemplates: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  labels: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
  })),
  buckets: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    bucketTemplateId: PropTypes.string,
  })),
  contactAddressBooks: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onSave: PropTypes.func.isRequired,
};

WorkflowLogicalConditionNodeConfigureDrawer.defaultProps = {
  sections: [],
  formulas: [],
  customers: [],
  projects: [],
  users: [],
  costcodes: [],
  phases: [],
  projectIdMap: {},
  vendors: [],
  equipment: [],
  formTemplates: [],
  labels: [],
  contactAddressBooks: {},
  buckets: [],
};
