import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Col,
  Dropdown,
  Menu,
  Row,
  Select,
} from 'antd';
import {
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  PlusOutlined,
  SaveOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import OnTraccrTextInput from '../inputs/OnTraccrTextInput';
import BorderlessButton from '../buttons/BorderlessButton';
import Colors from '../../constants/Colors';
import OnTraccrButton from '../buttons/OnTraccrButton';
import CustomConfirmModal from '../modals/CustomConfirmModal';

import {
  deleteSavedDashboard,
  favoriteDashboard,
  selectDashboard,
  setDashboardEdit,
  saveDashboard,
  updateDashboard,
} from '../../dashboard/state/dashboard.actions';

import { getFilteredReports } from '../../helpers/dashboardAnalytics';

function DashboardAnalyticsHeader({
  projectTypeId,
  currentLayout,
  layout,
  setCurrentLayout,
  savedReports,
  analyticsType,
  cardTypeId,
  dashboardConfig,
}) {
  const dispatch = useDispatch();

  const {
    selectedDashboard: {
      id: dashboardId,
      title,
      isFavorite,
    } = {},
    savedDashboards = [],
    isEdit,
  } = dashboardConfig;

  const [currentTitle, setCurrentTitle] = useState('');

  const filteredDashboards = useMemo(() => {
    if (analyticsType === 'project') {
      return savedDashboards.filter((dash) => dash.projectTypeId === projectTypeId);
    }

    return savedDashboards.filter((dash) => dash.cardTypeId === cardTypeId);
  }, [savedDashboards, projectTypeId, analyticsType, cardTypeId]);

  const filteredReports = useMemo(() => (
    getFilteredReports({
      savedReports,
      type: analyticsType,
      projectTypeId,
      cardTypeId,
    })
  ), [savedReports, analyticsType, projectTypeId, cardTypeId]);

  const widgets = useMemo(() => {
    const layoutSet = new Set(currentLayout.map((l) => l.i));
    return filteredReports.filter((l) => !layoutSet.has(l.id));
  }, [filteredReports, currentLayout]);

  const onEditClicked = useCallback((e) => {
    e.stopPropagation();
    dispatch(setDashboardEdit({
      type: analyticsType,
      isEdit: true,
    }));
  }, [analyticsType]);

  const onEditCancel = useCallback((e) => {
    e.stopPropagation();
    dispatch(setDashboardEdit({
      type: analyticsType,
      isEdit: false,
    }));
    setCurrentTitle(title);
    setCurrentLayout(layout);
  }, [title, layout, analyticsType]);

  const onTextChange = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setCurrentTitle(value);
  }, []);

  const onDeleteClicked = useCallback((e) => {
    e.stopPropagation();
    CustomConfirmModal({
      title: `Delete Dashboard '${currentTitle}'?`,
      onOk: () => {
        dispatch(deleteSavedDashboard({
          type: analyticsType,
          id: dashboardId,
        }));
      },
    });
  }, [currentTitle, dashboardId, analyticsType]);

  const onSaveClicked = useCallback((e) => {
    e.stopPropagation();
    if (dashboardId === 'new') {
      dispatch(saveDashboard({
        type: analyticsType,
        payload: {
          title: currentTitle,
          layout: currentLayout,
          dashboardType: analyticsType,
          projectTypeId,
          cardTypeId,
        },
      }));
    } else {
      dispatch(updateDashboard({
        type: analyticsType,
        id: dashboardId,
        payload: {
          title: currentTitle,
          layout: currentLayout,
          dashboardType: analyticsType,
          projectTypeId,
          cardTypeId,
        },
      }));
    }
  }, [currentTitle, dashboardId, currentLayout, projectTypeId, analyticsType, cardTypeId]);

  const onFavoriteToggle = useCallback((e) => {
    e.stopPropagation();
    dispatch(favoriteDashboard({
      type: analyticsType,
      id: dashboardId,
      isFavorite,
      projectTypeId,
      cardTypeId,
    }));
  }, [isFavorite, dashboardId, projectTypeId, analyticsType, cardTypeId]);

  const onAddWidget = useCallback(({ key, domEvent: e }) => {
    e.stopPropagation();
    const newCurrentLayout = currentLayout.concat([{
      i: key, y: Infinity, x: 0, w: 6, h: 11,
    }]);
    setCurrentLayout(newCurrentLayout);
  }, [currentLayout]);

  const onDashboardSelected = useCallback((dashId) => {
    dispatch(selectDashboard({
      type: analyticsType,
      id: dashId,
    }));
  }, [analyticsType]);

  useEffect(() => {
    if (title) {
      setCurrentTitle(title);
    } else {
      setCurrentTitle('');
    }
  }, [title]);

  return (
    <Row align="middle" gutter={20}>
      <Col>
        {isEdit
          ? (
            <OnTraccrTextInput
              autoFocus
              style={{ width: 300 }}
              value={currentTitle}
              onClick={(e) => e.stopPropagation()}
              onChange={onTextChange}
            />
          ) : (
            <Select
              style={{ minWidth: 300 }}
              onSelect={onDashboardSelected}
              value={currentTitle}
            >
              <Select.Option value="new" key="new">
                <b>New Dashboard</b>
                {' '}
                <span><PlusOutlined /></span>
              </Select.Option>
              {filteredDashboards.map((dashboard) => (
                <Select.Option value={dashboard.id} key={dashboard.id}>
                  {dashboard.title}
                  {dashboard.isFavorite
                    ? (
                      <span style={{ marginLeft: 5 }}>
                        <StarFilled style={{ color: 'gold' }} />
                      </span>
                    ) : null}
                </Select.Option>
              ))}
            </Select>
          )}

      </Col>
      {!isEdit && dashboardId && dashboardId !== 'new' && (
        <Col>
          <BorderlessButton
            style={{ padding: 0 }}
            onClick={onFavoriteToggle}
            iconNode={isFavorite ? <StarFilled style={{ color: 'gold' }} /> : <StarOutlined />}
          />
        </Col>
      )}
      {!isEdit && dashboardId && (
        <Col>
          <BorderlessButton
            style={{ padding: 0 }}
            iconNode={<EditOutlined />}
            onClick={onEditClicked}
          />
        </Col>
      )}
      {!isEdit && dashboardId && dashboardId !== 'new' && (
        <Col>
          <BorderlessButton
            style={{ color: Colors.ONTRACCR_RED, padding: 0 }}
            iconNode={<DeleteOutlined />}
            onClick={onDeleteClicked}
          />
        </Col>
      )}
      {isEdit && (
        <Col>
          <BorderlessButton
            style={{ padding: 0 }}
            iconNode={<CloseOutlined />}
            onClick={onEditCancel}
          />
        </Col>
      )}
      {isEdit && currentTitle.length > 0 && (
        <Col>
          <BorderlessButton
            style={{ padding: 0 }}
            iconNode={<SaveOutlined />}
            onClick={onSaveClicked}
          />
        </Col>
      )}
      {isEdit && currentLayout.length < 9 && (
        <Col style={{ marginLeft: 40 }}>
          <Dropdown
            trigger={['click']}
            onClick={(e) => e.stopPropagation()}
            overlay={(
              <Menu onClick={onAddWidget}>
                {widgets.map((widget) => (
                  <Menu.Item key={widget.id}>
                    {widget.title}
                  </Menu.Item>
                ))}
              </Menu>
            )}
            disabled={widgets.length === 0}
          >
            <OnTraccrButton title="Add Widget" icon={<PlusOutlined />} />
          </Dropdown>
        </Col>
      )}
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
DashboardAnalyticsHeader.propTypes = {
  projectTypeId: PropTypes.string,
  currentLayout: PropTypes.array,
  layout: PropTypes.array,
  setCurrentLayout: PropTypes.func.isRequired,
  savedReports: PropTypes.object,
  analyticsType: PropTypes.string,
  cardTypeId: PropTypes.number,
  dashboardConfig: PropTypes.object,
};

DashboardAnalyticsHeader.defaultProps = {
  projectTypeId: null,
  currentLayout: [],
  layout: [],
  savedReports: {},
  analyticsType: undefined,
  cardTypeId: undefined,
  dashboardConfig: {},
};

export default DashboardAnalyticsHeader;
