import React from 'react';
import { Card } from 'antd';

import colors from '../../constants/Colors';

export default ({ style = {}, children, hoverable = true, onClick  }) => (
  <Card 
    hoverable={hoverable}
    onClick={onClick}
    style={{
      width:'100%',
      borderRadius: 8,
      borderColor:colors.LIGHT_GRAY,
      borderWidth:1,
      borderStyle:'solid',
      ...style,
    }}
    bodyStyle={{ padding: 0 }}
  >
    {children}
  </Card>
);