import React, { useCallback, useMemo } from 'react';
import { Select } from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { updateSageJobStatuses } from '../../../sage/state/sage.actions';

/*
(1) Unstarted:
  1 - Bid
(2) InProgress:
  3 - Contract
  4 - Current
(3) Closed:
  2 - Refused
  6 - Closed
(4) Complete:
  5 - Complete
*/
const statuses = [
  {
    id: 1,
    label: 'Unstarted',
    detailText: '(\'Bid\')',
  },
  {
    id: 2,
    label: 'In Progress',
    detailText: '(\'Contract\', \'Current\')',
  },
  {
    id: 3,
    label: 'Closed',
    detailText: '(\'Refused\', \'Closed\')',
  },
  {
    id: 4,
    label: 'Complete',
    detailText: '(\'Complete\')',
  },
];

const options = statuses.map((status) => (
  <Select.Option
    key={status.id}
    value={status.id}
    label={status.label}
    detailText={status.detailText}
  >
    {status.label}
  </Select.Option>
));

const labelRender = (props) => `${props.label} ${props.detailText}`;

export default function SageJobStatusSelector({
  version,
  selectedIntegration,
}) {
  const dispatch = useDispatch();
  const integrations = useSelector((state) => state.sage.integrations);

  const {
    jobStatuses: selectedStatuses = [],
  } = useMemo(() => (
    integrations.find(({ id }) => id === selectedIntegration?.id) ?? {}
  ), [selectedIntegration, integrations]);

  const onChange = useCallback((newSelected) => {
    if (selectedIntegration) {
      dispatch(updateSageJobStatuses(version, selectedIntegration.id, newSelected));
    }
  }, [selectedIntegration]);

  return (
    <Select
      mode="multiple"
      onChange={onChange}
      style={{ width: '100%' }}
      value={selectedStatuses}
      showSearch
      optionFilterProp="label"
      labelRender={labelRender}
    >
      {options}
    </Select>
  );
}

SageJobStatusSelector.propTypes = {
  version: PropTypes.string.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  }),
};

SageJobStatusSelector.defaultProps = {
  selectedIntegration: {},
};
