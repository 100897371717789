import React, { useState, useCallback, useEffect } from 'react';

import OnTraccrTextInput from '../inputs/OnTraccrTextInput';

import SimpleFormModal from './SimpleFormModal';

export default ({
  title,
  visible,
  validator,
  placeholder,
  onClose,
  onSave,
}) => {
  const [errorText,setErrorText] = useState();
  const [value,setValue] = useState();

  const onTextChange = useCallback((e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    if (validator) setErrorText(validator(newValue));
    setValue(newValue);
  },[validator]);

  const onSaveClicked = useCallback(() => onSave(value),[onSave,value]);

  useEffect(() => {
    if (!visible) {
      setErrorText();
      setValue();
    }
  },[visible]);

  return (
    <SimpleFormModal
      title={title}
      visible={visible}
      errorText={errorText}
      disabled={errorText || !value}
      onClose={onClose}
      onSave={onSaveClicked}
    >
      <OnTraccrTextInput
        onChange={onTextChange}
        value={value}
        placeholder={placeholder}
      />
    </SimpleFormModal>
  )
}