import React, { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Select } from 'antd';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';

import { updatePayMapping, createPayMapping } from '../../../sage/state/sage.actions';

export default function SageProjectPayMappingDrawer({
  selectedIntegration,
  selectedMapping,
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const payIds = useSelector((state) => state.sage.payIds);
  const projects = useSelector((state) => state.projects.projects);

  const {
    sagePayId,
    projectId,
  } = selectedMapping || {};
  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      // Create or update shift
      const payload = form.getFieldsValue();
      const { sagePayId: payId } = payload;
      let passed;
      if (projectId) {
        passed = await dispatch(updatePayMapping(selectedIntegration?.id, projectId, payId));
      } else {
        passed = await dispatch(createPayMapping(selectedIntegration?.id, payload));
      }
      if (passed) onClose();
    } catch (err) {
      // Validate fields throws error
    }
  }, [form, projectId, onClose, selectedIntegration]);

  useEffect(() => {
    if (!visible) {
      form.resetFields();
    } else {
      form.setFieldsValue({ sagePayId, projectId });
    }
  }, [form, visible, sagePayId, projectId]);

  const projectOptions = useMemo(() => (
    projects
      .filter((project) => project.active)
      .map((project) => ({ label: project.name, value: project.id }))
  ), [projects]);

  return (
    <Drawer
      title={`${projectId ? 'Edit' : 'Add'} mapping`}
      visible={visible}
      onClose={onClose}
      width={500}
    >
      <Form form={form} initialValues={{ sagePayId, projectId }} layout="vertical">
        <Form.Item
          name="projectId"
          label="Project"
          rules={[{ required: true, message: 'Project is required' }]}
        >
          <Select options={projectOptions} optionFilterProp="label" showSearch disabled={projectId} />
        </Form.Item>

        <Form.Item
          name="sagePayId"
          label="Sage Pay ID"
          rules={[{ required: true, message: 'Sage Pay ID' }]}
        >
          <Select options={payIds} optionFilterProp="label" showSearch />
        </Form.Item>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

SageProjectPayMappingDrawer.propTypes = {
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
  selectedMapping: PropTypes.shape({
    projectId: PropTypes.string,
    sagePayId: PropTypes.string,
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func,
};

SageProjectPayMappingDrawer.defaultProps = {
  selectedIntegration: {},
  selectedMapping: {},
  visible: false,
  onClose: () => {},
};
