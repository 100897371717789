import React, {
  useCallback,
  useMemo,
  useEffect,
  useState,
} from 'react';
import {
  Row,
  Col,
  Breadcrumb,
  Popover,
  Spin,
} from 'antd';
import {
  FolderOutlined,
  GoldOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';

import PaginatedTable from '../common/containers/PaginatedTable';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import { getIdMap } from '../helpers/helpers';

const BASE_CRUMBS = [{
  title: 'Materials',
  groupId: null,
}];

export default function MaterialSelect(props) {
  const {
    selected,
    setSelected,
    data,
    loading = false,
    isReadOnly = false,
    showInput = false,
  } = props;

  const [crumbs, setCrumbs] = useState(BASE_CRUMBS);

  useEffect(() => {
    setCrumbs(BASE_CRUMBS);
  }, []);

  const onCrumbClicked = useCallback((index) => () => {
    setCrumbs(crumbs.slice(0, index + 1));
  }, [crumbs]);

  const onSelectRow = useCallback((record, checked) => {
    if (checked) {
      setSelected(selected.concat(record));
    } else {
      setSelected(selected.filter((s) => s.id !== record.id));
    }
  }, [selected, setSelected]);

  const selectedMap = useMemo(() => getIdMap(selected), [selected]);

  const onInputChange = useCallback((record, value) => {
    const newSelected = selected.filter((s) => s.id !== record.id);

    if (value) {
      newSelected.push({
        ...record,
        quantity: value,
      });
    }

    setSelected(newSelected);
  }, [selected]);

  const onRowClick = (record) => {
    if (record.isFolder) {
      const newCrumbs = crumbs.concat([{
        title: record.name,
        groupId: record.id,
      }]);
      setCrumbs(newCrumbs);
    }
  };

  const fileStructureData = useMemo(() => {
    const materialsList = data;
    const { groupId } = crumbs[crumbs.length - 1];
    const newFolders = [];
    const newMaterials = [];
    materialsList.forEach((material) => {
      if (material.groupId !== groupId) return;
      if (material.isFolder) {
        newFolders.push(material);
      } else {
        newMaterials.push(material);
      }
    });
    return newFolders.concat(newMaterials);
  }, [data, crumbs]);

  const selectedRowKeys = useMemo(() => selected.map((s) => s.id), [selected]);

  const cols = useMemo(() => {
    const tableCols = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (_, record) => (
          <>
            <Row
              gutter={10}
              justify="start"
              style={{ paddingLeft: 10 }}
            >
              <Col>
                {record.isFolder ? <FolderOutlined /> : <GoldOutlined />}
              </Col>
              <Col>
                {record.name}
              </Col>
            </Row>
            <div style={{ fontSize: 11, color: 'gray', paddingLeft: 35 }}>
              {record.description}
            </div>
          </>
        ),
      },
    ];

    if (showInput) {
      tableCols.unshift({
        title: '',
        dataIndex: 'input',
        render: (_, record) => {
          if (record.isFolder) {
            return null;
          }

          const material = selectedMap[record.id];
          return (
            <OnTraccrNumberInput
              min={0}
              default={0}
              value={material ? material.quantity : 0}
              onChange={(val) => onInputChange(record, val)}
            />
          );
        },
        width: 80,
      });
    }

    return tableCols;
  }, [showInput, selectedMap, onInputChange]);

  const popoverDescription = useMemo(() => (
    selected.map((s) => {
      let suffix = '';

      if (showInput) {
        suffix = `: ${s.quantity || 0}`;
      }

      return (
        <div key={s.id}>
          {`${s.name}${suffix}`}
        </div>
      );
    })
  ), [selected, showInput]);

  return (
    <>
      <Popover
        placement="bottomRight"
        title="Selected Materials"
        content={(
          <div style={{ maxHeight: 500, overflowY: 'auto' }}>
            {popoverDescription}
          </div>
        )}
      >
        <div style={{ width: 'fit-content', cursor: 'pointer' }}>
          {selected.length}
          {' '}
          material
          {selected.length === 1 ? '' : 's'}
          {' '}
          selected
        </div>
      </Popover>
      <Breadcrumb
        separator={<span style={{ fontSize: 14 }}>/</span>}
      >
        {
          crumbs.map((crumb, index) => (
            <Breadcrumb.Item
              key={crumb.title}
              onClick={index < crumbs.length - 1 ? onCrumbClicked(index) : null}
              className={index < crumbs.length - 1 ? 'material-add-drawer-breadcrumb-clickable' : 'material-add-drawer-breadcrumb'}
            >
              {crumb.title}
            </Breadcrumb.Item>
          ))
        }
      </Breadcrumb>
      {
        loading
          ? (
            <Row justify="center" align="middle" style={{ height: '75%', width: '100%' }}>
              <Spin />
            </Row>
          )
          : (
            <PaginatedTable
              showHeader={false}
              dataSource={fileStructureData}
              columns={cols}
              size="small"
              rowKey="id"
              scrollOffset={290}
              onRow={(record) => ({
                onClick: () => onRowClick(record),
              })}
              rowClassName="materials-table-row"
              rowSelection={showInput ? null : {
                fixed: true,
                hideSelectAll: true,
                onSelect: onSelectRow,
                selectedRowKeys,
                getCheckboxProps: (record) => ({
                  disabled: record.isFolder || isReadOnly,
                }),
              }}
            />
          )
      }
    </>
  );
}

MaterialSelect.propTypes = {
  selected: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
  })),
  setSelected: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isFolder: PropTypes.number,
  })).isRequired,
  loading: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  showInput: PropTypes.bool,
};

MaterialSelect.defaultProps = {
  selected: [],
  setSelected: () => {},
  loading: true,
  isReadOnly: false,
  showInput: false,
};
