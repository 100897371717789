import React, { useMemo } from 'react';
import { Select } from 'antd';

export default function WorkflowConfigureSourceSelector({
  fieldMappings,
  defaultValue,
  value,
  onFieldMappingsChange,
  targetId,
  eligibleSourceFields = [],
  formId,
  maxWidth = 425 - 16, // parent is 425 and has 8px padding on both sides
}) {
  const sourceFieldMap = useMemo(() => {
    const map = {};
    eligibleSourceFields.forEach((field) => {
      map[field.id] = field;
    });

    return map;
  }, [eligibleSourceFields]);

  return (
    <Select
      placeholder='Select source field'
      dropdownMatchSelectWidth={false}
      style={{ width: '100%', maxWidth }}
      className={{ 'select-warning': !!sourceFieldMap[value]?.displayWarning }}
      allowClear
      defaultValue={defaultValue}
      value={value}
      onChange={(sourceId) => {
        const newData = {
          ...fieldMappings,
        };
        if(sourceId) {
          newData[targetId] = sourceId;
        } else {
          delete newData[targetId];
        }

        onFieldMappingsChange(newData, formId);
      }}
    >
      {
        eligibleSourceFields.map((field) => (
          <Select.Option
            value={field.id}
            key={field.id}
          >
            {field.title}
            <div className='form-trigger-subtext'>
              {field.helpText}
            </div>
          </Select.Option>
        ))
      }
    </Select>
  );
};