import axios from 'axios';

import { request } from '../../helpers/requests';

export default {
  getGlobalAddressBook: () => request({
    call: axios.get('/contacts/addressBooks/global'),
    errMsg: 'Could not get all address book entries',
    hideSuccessToast: true,
  }),
  updateAddressBook: (id, payload) => request({
    call: axios.put(`/contacts/addressBooks/${id}`, payload),
    errMsg: 'Could not update address book entry',
  }),
  addToGlobalAddressBook: (payload) => request({
    call: axios.post('/contacts/addressBooks', payload),
    errMsg: 'Could not add entry into address book',
  }),
  massAddToLocalAddressBooks: (payload) => request({
    call: axios.post('/contacts/addressBooks/connections', payload),
    errMsg: 'Could not add selected entries to selected address books',
  }),
  massDeleteFromGlobalAddressBook: (ids) => request({
    call: axios.delete('/contacts/addressBooks', {
      params: {
        ids,
      },
    }),
    errMsg: 'Could not delete selected entries from address book',
  }),
  massDeleteFromLocalAddressBooks: (ids, customerIds, vendorIds) => request({
    call: axios.delete('/contacts/addressBooks/connections', {
      params: {
        addressBookEntryIds: ids,
        customerIds,
        vendorIds,
      },
    }),
    errMsg: 'Could not remove selected entries from selected address books',
  }),

};
