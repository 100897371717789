import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Table, Select } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import CompanySettingsCard from '../CompanySettingsCard';
import SettingsCardHeader from '../SettingsCardHeader';

import {
  createLabel,
  deleteLabel,
  updateLabel,
} from '../../labels/state/labels.actions';
import {
  createStatus,
  updateStatus,
  deleteStatus,
} from '../../statuses/state/statuses.actions';

import { getIdMap } from '../../helpers/helpers';

import colors from '../../constants/Colors';
import FormColorPicker from '../../common/inputs/FormColorPicker';
import SimpleFormModal from '../../common/modals/SimpleFormModal';

import {
  getLabelTypes,
  STATUS_TYPES,
} from './label.constants';

const DEFAULT_INVOICE_STATUS_LABELS = ['Hold', 'Received', 'Paid'];

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

export default function Labels({
  viewType, // label or status
  visible,
}) {
  const { t } = useTranslation();
  const isLabels = viewType === 'label';
  const textKey = isLabels ? 'title' : 'status';
  const LABEL_TYPES = getLabelTypes(t);
  const labelOptions = isLabels
    ? LABEL_TYPES
    : STATUS_TYPES;
  const dispatch = useDispatch();

  const labels = useSelector((state) => state.labels);
  const equipmentStatuses = useSelector((state) => state.equipment.statuses);
  const labelIdMap = useMemo(() => getIdMap(labels), [labels]);
  const statusIdMap = useMemo(() => ({
    equipment: equipmentStatuses,
  }), [equipmentStatuses]);
  const statusList = useMemo(() => Object.values(equipmentStatuses), [equipmentStatuses]);

  const [modalOpen, setModalOpen] = useState(false);
  const [addPayload, setAddPayload] = useState({});

  const closeModal = useCallback(() => {
    setModalOpen(false);
    setAddPayload({});
  }, []);
  const openModal = useCallback(() => setModalOpen(true), []);

  const [type, setType] = useState(labelOptions[0].value);
  const [editData, setEditData] = useState({});

  const filteredLabels = useMemo(() => (
    labels.filter((label) => label.type === type)
  ), [labels, type]);

  const onNameChange = useCallback((editId) => (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    if (editId) {
      const newEditData = { ...editData };
      newEditData[editId][textKey] = value;
      setEditData(newEditData);
    }
  }, [editData, textKey]);

  const onColorChange = useCallback((editId) => (color) => {
    if (editId) {
      const newEditData = { ...editData };
      newEditData[editId].color = color;
      setEditData(newEditData);
    }
  }, [editData]);

  const onSave = useCallback(async () => {
    const createProm = isLabels
      ? createLabel({ type, ...addPayload })
      : createStatus(type, { status: addPayload.title });

    if (await dispatch(createProm)) {
      closeModal();
    }
  }, [closeModal, type, addPayload, isLabels]);

  const onSaveEdit = useCallback(async (editId) => {
    const {
      [editId]: {
        [textKey]: title,
        color,
      } = {},
    } = editData;
    const payload = { title, color };
    const updateProm = isLabels
      ? updateLabel(editId, payload)
      : updateStatus(type, editId, { status: title });
    if (await dispatch(updateProm)) {
      const newEditData = { ...editData };
      delete newEditData[editId];
      setEditData(newEditData);
    }
  }, [editData, isLabels, textKey, type]);

  const onDelete = useCallback((id) => {
    dispatch(
      isLabels
        ? deleteLabel(id)
        : deleteStatus(type, id),
    );
  }, [isLabels, type]);

  const onDeleteClicked = useCallback(({ id, [textKey]: title }) => {
    CustomConfirmModal({
      title: `Delete ${title} ${isLabels ? 'label' : 'status'}?`,
      okText: 'Delete',
      cancelText: 'Cancel',
      onOk: () => onDelete(id),
    });
  }, [onDelete, isLabels, textKey]);

  const onEditStarted = useCallback((editId) => {
    const newEditData = { ...editData };
    const existing = isLabels
      ? labelIdMap[editId]
      : statusIdMap?.[type]?.[editId] ?? {};
    newEditData[editId] = { ...existing };
    setEditData(newEditData);
  }, [editData, labelIdMap, statusIdMap, isLabels, type]);

  const onEditCancel = useCallback((editId) => {
    const newEditData = { ...editData };
    delete newEditData[editId];
    setEditData(newEditData);
  }, [editData]);

  const onFormChange = useCallback((property, newValue) => {
    setAddPayload({
      ...addPayload,
      [property]: newValue,
    });
  }, [addPayload]);

  const isInputClean = useCallback((record) => {
    const isEdit = record.id in editData;

    const {
      [record.id]: {
        [textKey]: editTitle,
        color: editColor,
      } = {},
    } = editData;

    const { [textKey]: existingTitle, color: existingColor } = record;
    return isEdit && (editTitle === existingTitle && editColor === existingColor);
  }, [editData, textKey]);

  useEffect(() => {
    if (!visible) setEditData({});
  }, [visible]);

  const columns = useMemo(() => {
    const labelColumns = [];
    if (type === 'projectGanttSchedule') {
      labelColumns.push({
        title: '',
        dataIndex: 'color',
        onHeaderCell,
        width: 30,
        render: (_, record) => {
          const isEdit = record.id in editData;
          const {
            [record.id]: {
              color: editColor,
            } = {},
          } = editData;
          const color = isEdit ? editColor : record.color;
          return (
            <FormColorPicker
              showText={false}
              value={color || '#000000'}
              isNotDisplay={isEdit}
              onChange={onColorChange(record.id)}
            />
          );
        },
      });
    }

    labelColumns.push(
      {
        title: 'Name',
        dataIndex: textKey,
        onHeaderCell,
        render: (text, record) => {
          const isDirty = !isInputClean(record);
          const isEdit = record.id in editData;

          const {
            [record.id]: {
              [textKey]: editTitle,
            } = {},
          } = editData;
          if (isEdit) {
            return (
              <Row align="middle">
                <OnTraccrTextInput
                  key={record.id}
                  value={editTitle}
                  onChange={onNameChange(record.id)}
                  style={!isDirty ? { borderColor: 'red' } : {}}
                />
              </Row>
            );
          }
          return text;
        },
      },
      {
        dataIndex: 'edit',
        align: 'edit',
        onHeaderCell,
        width: 60,
        render: (_, record) => {
          const { id, title } = record || {};
          // If it is default invoice statuses, dont allow action
          if (type === 'invoices' && DEFAULT_INVOICE_STATUS_LABELS.includes(title)) return null;
          const isEdit = id in editData;
          const icon = isEdit ? 'close' : 'edit';
          return (
            <BorderlessButton
              key={id}
              title=""
              icon={icon}
              color={colors.ONTRACCR_BLACK}
              onClick={() => {
                if (isEdit) {
                  onEditCancel(id);
                } else {
                  onEditStarted(id);
                }
              }}
              style={{
                paddingRight: 8,
                paddingLeft: 0,
              }}
            />
          );
        },
      },
      {
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 60,
        onHeaderCell,
        render: (_, record) => {
          const { id, title } = record || {};
          // If it is default invoice statuses, dont allow action
          if (type === 'invoices' && DEFAULT_INVOICE_STATUS_LABELS.includes(title)) return null;
          const isEdit = id in editData;
          const icon = isEdit ? 'check' : 'delete';
          const color = isEdit ? colors.ONTRACCR_BLACK : colors.ONTRACCR_RED;

          return !isInputClean(record)
            ? (
              <BorderlessButton
                title=""
                icon={icon}
                color={color}
                onClick={() => {
                  if (isEdit) {
                    onSaveEdit(id);
                  } else {
                    onDeleteClicked(record);
                  }
                }}
                style={{
                  paddingRight: 8,
                  paddingLeft: 0,
                }}
              />
            ) : null;
        },
      },
    );
    return labelColumns;
  }, [
    onSave,
    onDelete,
    onNameChange,
    onEditCancel,
    onEditStarted,
    editData,
    type,
    isLabels,
    textKey,
  ]);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
        marginLeft: 0,
        marginTop: 30,
      }}
    >
      <SettingsCardHeader
        title={(
          <Select
            style={{ width: 350 }}
            options={labelOptions}
            value={type}
            onSelect={setType}
          />
            )}
      >
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={openModal}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        style={{
          position: 'absolute',
          top: 130,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <Table
          columns={columns}
          dataSource={isLabels ? filteredLabels : statusList}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
        />
      </CompanySettingsCard>
      <SimpleFormModal
        title={`Add ${isLabels ? 'Label' : 'Status'}`}
        visible={modalOpen}
        onSave={onSave}
        onClose={closeModal}
      >
        <div style={{ width: '100%', display: 'inline-flex' }}>
          {
            type === 'projectGanttSchedule'
            && (
              <FormColorPicker
                showText={false}
                value={addPayload.color || '#000000'}
                onChange={(newVal) => onFormChange('color', newVal)}
                isNotDisplay
                style={{ marginRight: 5, width: 22 }}
              />
            )
          }
          <OnTraccrTextInput
            onChange={({ target: { value: newVal } }) => onFormChange('title', newVal)}
            value={addPayload.title}
            placeholder={`Enter ${isLabels ? 'Label' : 'Status'} name`}
          />
        </div>
      </SimpleFormModal>
    </Row>
  );
}

Labels.propTypes = {
  visible: PropTypes.bool.isRequired,
  viewType: PropTypes.string.isRequired,
};
