import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  COSTCODE_WHAT_ARE_TYPE,
  COSTCODE_GLOBAL_TYPE,
  COSTCODE_PROJECT_SPECIFIC_TYPE,
} from '../nux.constants';

import NuxTabPane from '../NuxTabPane';

import NuxWhatAreCostcodes from './NuxWhatAreCostcodes';
import NuxProjectCostcode from './NuxProjectCostcode';
import NuxGlobalCodes from './NuxGlobalCodes';

import whatAreCostcodes from '../../assets/images/NUX/whatAreCostcodes.svg';
import globalCodes from '../../assets/images/NUX/globalCodes.svg';
import projectCodes from '../../assets/images/NUX/projectCodes.svg';

import Analytics from '../../helpers/Analytics';

const whatTitle = 'What are Cost Codes?';
const globalTitle = 'Using Global Codes';
const getProjectTitle = (t) => `${t('Project')}-Specific Code`;

export default function NuxCostcodes({
  setDetailView,
  setTitle,
  setDescription,
  resetToDefault,
  history,
  onCloseClicked,
  nux = new Set(),
  createNuxEntry,
}) {
  const { t } = useTranslation();
  const projectTitle = getProjectTitle(t);
  return (
    <NuxTabPane
      setDetailView={setDetailView}
      setTitle={setTitle}
      setDescription={setDescription}
      buttons={[
        {
          title: whatTitle,
          description:'Have you ever wished you had more detail about what is going on at your job site?',
          complete:nux.has(COSTCODE_WHAT_ARE_TYPE),
          src:whatAreCostcodes,
          onClick:() => {
            Analytics.track('NUX/TryIt', { NuxPage: whatTitle});
            if(!nux.has(COSTCODE_WHAT_ARE_TYPE)) {
              createNuxEntry(COSTCODE_WHAT_ARE_TYPE);
            }
          },
          detailView:(
            <NuxWhatAreCostcodes
              onBack={resetToDefault}
            />
          ),
        },
        {
          title: globalTitle,
          description:`Cost Codes are the foundation of effective job costing and
          are critical to the way you are able to measure success on ${t('Project', { count: 2 })}.`,
          complete:nux.has(COSTCODE_GLOBAL_TYPE),
          src:globalCodes,
          detailView:(
            <NuxGlobalCodes
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: globalTitle});
                history.push('/costcodes/global');
                onCloseClicked();
              }}
            />
          ),
        },
        {
          title: projectTitle,
          description:`
          In addition to using global (companywide) cost codes, you can also
          create cost codes that are unique to a specific ${t('Project')}. These unique ${t('Project')}
          codes can also consist of global cost codes that were added during initial ${t('Project')}
          creation. There are two ways to create ${t('Project')}-specific cost codes:
          `,
          complete:nux.has(COSTCODE_PROJECT_SPECIFIC_TYPE),
          src:projectCodes,
          detailView:(
            <NuxProjectCostcode
              onBack={resetToDefault}
              onTry={() => {
                Analytics.track('NUX/TryIt', { NuxPage: projectTitle});
                history.push('/costcodes/project-specific');
                onCloseClicked();
              }}
          />
          ),
        }
      ]}
    />
  );
}
