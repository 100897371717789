import React, { useMemo } from 'react';
import { Form, Select } from 'antd';

import DisplayText from '../../text/DisplayText';

export default ({
  page,
  numberOfPages,
  onPageChanged,
  style = {},
  disabled = false,
}) => {
  const pages = useMemo(() => new Array(numberOfPages).fill(1),[numberOfPages]);
  return (
    <>
      <Form.Item
        key='font'
        label='Page'
        style={style}
      >
        {numberOfPages > 1 ?
        <Select
          defaultValue={page}
          onSelect={onPageChanged}
          disabled={disabled}
        >
          {
            pages.map((_,index) =>
              <Select.Option
                key={index}
                value={index}
              >{`Page ${index + 1}`}</Select.Option>
            )
          }
        </Select> :
        <DisplayText title='Page 1'/>
      }
      </Form.Item>
    </>
  );
}