import React from 'react';
import * as XLSX from 'xlsx';

export default {};

const parseSheet = (sheet,name) => {
  const html = [];
  const ourCSV = XLSX.utils.sheet_to_csv(sheet);
  const arr = ourCSV.split('\n');
  arr.forEach((row, index) => {
    const isHeader = index === 0;
    const innerHTML = [];
    let curString = '';
    let insideQuotes = false;
    for(let i = 0; i < row.length; i += 1) {
      const c = row.charAt(i);
      if(c !== ',' && c !== '"') {
        curString += c;
      } else if(c === '"'){
        insideQuotes = !insideQuotes;
      } else if(insideQuotes){
        curString += c; // Reached a comma inside a quoted string.
      } else {
        innerHTML.push(isHeader ? 
          <th key={i} className='excel-data-header'>{curString}</th>
          : <td key={i} className='excel-data'>{curString}</td>);
        curString = '';
      }

      if(i === row.length - 1) {
        const endKey = `${i}.end`;
        innerHTML.push(
          isHeader ?
          <th key={endKey} className='excel-data-header'>{curString}</th>
          : <td key={endKey} className='excel-data'>{curString}</td>
        )
      }
    }
    html.push(<tr key={`${name}.${index}`} className={isHeader ? 'excel-header' : ''}>{innerHTML}</tr>);
  });
  return html;
};

export const parseExcel = (buffer) => {
  const tables = {};
  let sheetNames = [];
  try {
    const remoteFile = XLSX.read(new Uint8Array(buffer), { type: 'array' });
    sheetNames = remoteFile.SheetNames;
    Object.keys(remoteFile.Sheets).forEach((sheetName) => {
      tables[sheetName] = parseSheet(remoteFile.Sheets[sheetName], sheetName);
    });
  } catch(err) {
    console.error(err)
  }
  return { tables, sheetNames };
};

export const stripText = (s) => s.toLowerCase().replace(/ /g, '');
