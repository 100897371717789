import {
  CREATE_BOARD_CARD_DASHBOARD,
  CREATE_PROJECT_DASHBOARD,
  DELETE_BOARD_CARD_DASHBOARD,
  DELETE_PROJECT_DASHBOARD,
  FAVORITE_BOARD_CARD_DASHBOARD,
  FAVORITE_PROJECT_DASHBOARD,
  GET_BOARD_CARD_DASHBOARD_ANALYTICS_DATA,
  GET_PROJECT_DASHBOARD_ANALYTICS_DATA,
  GET_SAVED_BOARD_CARD_DASHBOARDS,
  GET_SAVED_DASHBOARDS,
  GET_SAVED_PROJECT_DASHBOARDS,
  SELECT_BOARD_CARD_DASHBOARD,
  SELECT_PROJECT_DASHBOARD,
  SET_BOARD_CARD_DASHBOARD_EDIT,
  SET_INITIAL_BOARD_CARD_DASHBOARD,
  SET_INITIAL_PROJECT_DASHBOARD,
  SET_PROJECT_DASHBOARD_EDIT,
  UPDATE_BOARD_CARD_DASHBOARD,
  UPDATE_PROJECT_DASHBOARD,
} from '../state/actionTypes';

// type to action maps

export const setDashboardEditActionMap = {
  project: SET_PROJECT_DASHBOARD_EDIT,
  boardCard: SET_BOARD_CARD_DASHBOARD_EDIT,
};

export const selectDashboardActionMap = {
  project: SELECT_PROJECT_DASHBOARD,
  boardCard: SELECT_BOARD_CARD_DASHBOARD,
};

export const saveDashboardActionMap = {
  project: CREATE_PROJECT_DASHBOARD,
  boardCard: CREATE_BOARD_CARD_DASHBOARD,
};

export const updateDashboardActionMap = {
  project: UPDATE_PROJECT_DASHBOARD,
  boardCard: UPDATE_BOARD_CARD_DASHBOARD,
};

export const getSavedDashboardsActionMap = {
  project: GET_SAVED_PROJECT_DASHBOARDS,
  boardCard: GET_SAVED_BOARD_CARD_DASHBOARDS,
  main: GET_SAVED_DASHBOARDS,
};

export const deleteSavedDashboardActionMap = {
  project: DELETE_PROJECT_DASHBOARD,
  boardCard: DELETE_BOARD_CARD_DASHBOARD,
};

export const getDashboardAnalyticsActionMap = {
  project: GET_PROJECT_DASHBOARD_ANALYTICS_DATA,
  boardCard: GET_BOARD_CARD_DASHBOARD_ANALYTICS_DATA,
};

export const favoriteDashboardActionMap = {
  project: FAVORITE_PROJECT_DASHBOARD,
  boardCard: FAVORITE_BOARD_CARD_DASHBOARD,
};

export const setInitialDashboardActionMap = {
  project: SET_INITIAL_PROJECT_DASHBOARD,
  boardCard: SET_INITIAL_BOARD_CARD_DASHBOARD,
};

export default {};
