export default {};

export const pinValidator = async (rule, value) => {
  if (!value) return Promise.resolve();
  if (value.length !== 4) return Promise.reject(new Error('PIN must be 4 numbers'));

  const regex = new RegExp(/^\d+$/);
  if (!(regex.test(value))) {
    return Promise.reject(new Error('PIN can only contain numbers'));
  }
  return Promise.resolve();
};

export const compareToFirstPassword = (form) => (_, value) => {
  if (!value) return Promise.resolve(); // Handled by required: true,
  const pwValue = form.getFieldValue('password');
  if (value && value !== pwValue) {
    return Promise.reject(new Error('Passwords must match'));
  }
  return Promise.resolve();
};

export const validatePasswordFormat = (form) => async (_, value) => {
  if (!value) return Promise.resolve(); // Handled by required: true,
  if (value.length < 8) return Promise.reject(new Error('Password must be 8 or more characters'));

  const regex = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/);
  if (!(regex.test(value))) {
    return Promise.reject(new Error('Password must contain one uppercase character, one lowercase character and one number'));
  }
  try {
    await form.validateFields(['confirmPassword']);
  } catch (err) {
    // Squash
  }
  return Promise.resolve();
};
