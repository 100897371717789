import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Typography, Popconfirm, } from 'antd';
import { FormOutlined, CopyOutlined } from '@ant-design/icons';

import ListCard from '../common/ListCard';
import colors from '../constants/Colors';

import Permissions from '../auth/Permissions';

import { copyFormTemplate } from './state/forms.actions';

export default ({
  id,
  name,
  active,
  type,
  typeId,
  onArchive,
  onEdit,
  onDelete,
  onClick,
  style,
}) => {
  const hasWritePerms = Permissions.has('FORMS_WRITE');
  const dispatch = useDispatch();

  const templatePreviews = useSelector((state) => state.forms.templatePreviews);

  const archive = useCallback(() => onArchive({ id, active, name }),[onArchive, id, active, name]);

  const onCopy = useCallback((e) => {
    e.stopPropagation();
    dispatch(copyFormTemplate(id));
  },[dispatch, id]);
  
  const stopProp = useCallback((e) => e.stopPropagation(),[]);

  const preview = templatePreviews?.[id]?.file;

  const previewStyle = useMemo(() => {
    if(preview) {
      return  {  marginTop: -30 };
    }
    return {
      height: `calc(100% - ${hasWritePerms ? 32 : 64}px)`
    }
  },[hasWritePerms,preview]);

  return (
    <ListCard 
      style={style}
      onArchive={hasWritePerms ? archive : null}
      onEdit={hasWritePerms ? () => onEdit({ id, name, active, preview, type, typeId }) : null}
      onDelete={hasWritePerms ? () => onDelete({ id, name }) : null}
      onClick={() => onClick({ id, name, active, preview, type, typeId })}
      isActive={active}
      bodyStyle={{ 
        overflow:'hidden',
        height: hasWritePerms ? 'calc(100% - 35px)' : '100%',
        borderBottom:hasWritePerms ? `1px ${colors.LIGHT_GRAY} solid` : null,
      }}
    >
      <Row style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        borderTopRightRadius:8,
        borderTopLeftRadius: 8,
        height:32,
        width:'100%',
        paddingTop:6,
        overflowWrap:'anywhere',
        backgroundColor:'white',
        zIndex:100,
        borderBottom: `1px ${colors.LIGHT_GRAY} solid`,
        }} type='flex' justify='center'>
        <Col style={{ backgroundColor:'white', width:'100%',height:'100%', textAlign:'center' }}>
          <Typography.Text
            ellipsis
            style={{
              width:'100%',
              fontFamily:'roboto-bold',
              fontSize:12,
              color:colors.ONTRACCR_BLACK,
            }}>
            {name}
          </Typography.Text>
        </Col>
        {hasWritePerms && 
            <Col className='form-item-more-button'>
              <Popconfirm
                placement='rightTop'
                title={`Create a copy of ${name}?`}
                onConfirm={onCopy}
                onCancel={stopProp}
                trigger='click'
              >
                <CopyOutlined onClick={stopProp}/>
              </Popconfirm>
            </Col>}
      </Row>
      <Row 
        style={{ 
          marginBottom:10, 
          paddingTop:35, 
          ...previewStyle,
        }} 
        justify='center' 
        align='middle'
      >
        {!preview || !(preview instanceof File)
          ? <FormOutlined style={{ fontSize: 50 }}/>
          : <img
            style={{ width:'100%', height:'100%', }}
            src={URL.createObjectURL(preview)}
            alt={`Preview of form template: ${name}`}
          />
        }
      </Row>
    </ListCard>
  );
}