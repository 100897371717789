import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DashboardAnalyticsContainer from '../../common/dashboardAnalytics/DashboardAnalyticsContainer';
import { PROJECT_ANALYTICS_TYPE } from '../../analytics/analytics.constants';

function ProjectAnalytics({
  projectId,
  projectTypeId,
}) {
  const { dashboard: dashboardConfig } = useSelector((state) => state.projectAnalytics);
  const { reports: analyticsConfig } = useSelector((state) => state.projectAnalytics);

  return (
    <DashboardAnalyticsContainer
      type={PROJECT_ANALYTICS_TYPE}
      dashboardConfig={dashboardConfig}
      analyticsConfig={analyticsConfig}
      projectId={projectId}
      projectTypeId={projectTypeId}
    />
  );
}

ProjectAnalytics.propTypes = {
  projectId: PropTypes.string.isRequired,
  projectTypeId: PropTypes.string,
};

ProjectAnalytics.defaultProps = {
  projectTypeId: null,
};

export default ProjectAnalytics;
