import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import SideList from '../SideList';
import Timer from '../clock/Timer';

import colors from '../../constants/Colors';

import { getLastTask, getActiveMember } from '../../helpers/users';

const getColourByStatus = (status) => {
  switch (status) {
    case 'Clocked In': return colors.ONTRACCR_BLACK;
    case 'On Break': return colors.ONTRACCR_DARK_YELLOW;
    default: return colors.ONTRACCR_OPACITY_GRAY;
  }
};

const renderActive = ({
  name,
  tasks = [{}],
  onClick,
  id,
  status,
}) => {
  const {
    startTime,
    endTime,
    id: taskId,
  } = getLastTask(tasks);

  return (
    <Row
      style={{
        width: '100%',
        cursor: 'pointer',
      }}
      onClick={onClick}
    >
      <Col
        span={16}
        style={{
          fontFamily: 'roboto-regular',
          color: colors.ONTRACCR_BLACK,
          fontSize: 14,
        }}
      >
        {name}
      </Col>
      <Col
        span={8}
        style={{
          pointerEvents: 'none',
        }}
      >
        <Timer
          key={id}
          startTime={startTime}
          endTime={endTime}
          id={taskId}
          style={{
            fontFamily: 'roboto-regular',
            fontSize: 14,
            pointerEvents: 'none',
            color: getColourByStatus(status),
          }}
        />
      </Col>
    </Row>
  );
};

const renderInactive = ({
  name,
  onClick,
}) => (
  <Row
    onClick={onClick}
    style={{
      width: '100%',
    }}
  >
    <Col
      span={24}
      style={{
        fontFamily: 'roboto-regular',
        color: colors.ONTRACCR_BLACK,
        fontSize: 14,
        cursor: 'pointer',
      }}
    >
      {name}
    </Col>
  </Row>
);

function ActiveInactiveUserLists({
  id,
  projectId,
  users = [],
  onUserClick,
  style = {},
}) {
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  return (
    <Row
      gutter={24}
      style={{
        marginTop: 30,
        paddingLeft: 10,
        paddingRight: 10,
        height: '60%',
        width: '100%',
        ...style,
      }}
    >
      <Col span={12} style={{ height: '100%' }}>
        <SideList
          id={`${id}-activeTeam`}
          key={`${id}-activeTeam`}
          rowHeight={21}
          scrollable
          footer={false}
          header={(
            <Row
              style={{
                fontFamily: 'raleway-medium',
                fontSize: 18,
              }}
              type="flex"
              align="middle"
              justify="center"
            >
              Active Today
            </Row>
        )}
          headerStyle={{
            backgroundColor: colors.ONTRACCR_BLACK,
            color: 'white',
            height: 50,
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          }}
          dataSource={users.filter((user) => getActiveMember({
            user,
            projectId,
            timeEntryUserMap,
          }))}
          renderItem={(user) => renderActive({
            ...user,
            tasks: timeEntryUserMap[user.id] ?? [],
            onClick: () => onUserClick(user),
          })}
        />
      </Col>
      <Col span={12} style={{ height: '100%' }}>
        <SideList
          id={`${id}-inactiveTeam`}
          key={`${id}-inactiveTeam`}
          rowHeight={46}
          scrollable
          footer={false}
          header={(
            <Row
              style={{
                fontFamily: 'raleway-medium',
                fontSize: 18,
              }}
              type="flex"
              align="middle"
              justify="center"
            >
              Inactive Today
            </Row>
        )}
          headerStyle={{
            backgroundColor: colors.ONTRACCR_OPACITY_GRAY,
            color: 'white',
            height: 50,
            borderTopLeftRadius: 9,
            borderTopRightRadius: 9,
          }}
          dataSource={users.filter((user) => !getActiveMember({
            user,
            projectId,
            timeEntryUserMap,
          }))}
          renderItem={(user) => renderInactive({
            ...user,
            tasks: timeEntryUserMap[user.id] ?? [],
            onClick: () => onUserClick(user),
          })}
        />
      </Col>

    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
ActiveInactiveUserLists.propTypes = {
  id: PropTypes.string,
  projectId: PropTypes.string,
  users: PropTypes.array,
  onUserClick: PropTypes.func,
  style: PropTypes.object,
};

ActiveInactiveUserLists.defaultProps = {
  id: null,
  projectId: null,
  users: [],
  onUserClick: () => {},
  style: {},
};

export default ActiveInactiveUserLists;
