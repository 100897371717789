import React from 'react';
import { Steps,Empty } from 'antd';
import PropTypes from 'prop-types';
import { CheckOutlined } from '@ant-design/icons';

import colors from '../../constants/Colors';

const { Step } = Steps;

export default function StepsContainer({
  currentStep = 0,
  steps = [],
  style = {},
  containerStyle = {},
}) {
  return (
    <div style={{ height: '100%', width: '100%', ...containerStyle }}>
      <Steps
        size="small"
        current={currentStep}
        style={{ marginBottom:30, ...style }}
      >
        {steps.map(({title}, index) => {
          const style = { height: 24, width: 24, lineHeight: '24px', borderRadius: 24, marginRight: 5 };
          let body = index + 1;
          if (currentStep === index) {
            style.backgroundColor = colors.ONTRACCR_DARK_YELLOW;
            style.color = 'black';
          } else if (currentStep > index) {
            style.border = `1px solid ${colors.ONTRACCR_DARK_YELLOW}`;
            body = <CheckOutlined style={{ color: colors.ONTRACCR_DARK_YELLOW, transform:'translateY(-1px)' }}/>
          } else {
            style.border = '1px solid lightgray';
          }
          return (
            <Step
              key={title}
              title={title}
              icon={
                <span
                  className='ant-steps-small ant-steps-item-icon'
                  style={style}
                >
                  {body}
                </span>
              }
            />
          )
        })}
      </Steps>
      {
        steps.map(({content}, index) => <div key={index} style={{display:index === currentStep ? 'block' : 'none'}}>
        {content}
        </div>).concat(<Empty key='steps-empty' style={{display:steps ? 'none' : 'block'}} image={Empty.PRESENTED_IMAGE_SIMPLE}/>)
      }
    </div>
  );
}

StepsContainer.propTypes = {
  currentStep: PropTypes.number,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      content: PropTypes.node,
    }),
  ),
  style: PropTypes.object,
  containerStyle: PropTypes.object,
};

StepsContainer.defaultProps = {
  currentStep: 0,
  steps: [],
  style: {},
  containerStyle: {},
};
