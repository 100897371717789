export const DEFAULT_PROFILE_NAMES = [
  { label: 'Project', key: 'Project' },
  { label: 'Projects', key: 'Project_other' },
  { label: 'Customer', key: 'Customer' },
  { label: 'Customers', key: 'Customer_other' },
  { label: 'Project Group', key: 'ProjectGroup' },
  { label: 'Project Groups', key: 'ProjectGroup_other' },
];

export const keyMap = {
  Project: ['Project', 'Project_one'],
  Project_other: ['Project_other'],
  Customer: ['Customer', 'Customer_one'],
  Customer_other: ['Customer_other'],
  ProjectGroup: ['ProjectGroup', 'ProjectGroup_one'],
  ProjectGroup_other: ['ProjectGroup_other'],
};
