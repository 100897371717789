import React, {
  useCallback,
  useState,
  useMemo,
  useEffect,
} from 'react';
import {
  Form,
  Select,
  TreeSelect,
  Divider,
  Row,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getIdMap } from 'ontraccr-common/lib/Common';
import PropTypes from 'prop-types';

import { filterSelectDropdown, getProperty } from '../../helpers/helpers';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import SimpleTextInputModal from '../../common/modals/SimpleTextInputModal';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import {
  addUserFieldScheduleFilterView,
  deleteUserFieldScheduleFilterView,
  updateFieldScheduleFilters,
  updateUserFieldScheduleFilterView,
} from '../state/schedule.actions';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import { FILTER_VIEW_FIELDS } from '../schedule.constants';
import { getPhaseOptions, getProjectOptions } from '../../clock/ManualEntry/manualEntryHelpers';
import { getCostcodeDropdownOptions } from '../../forms/FormBuilder/FormFields/formFieldsHelpers';
import SelectButton from '../../common/inputs/SelectButton';

export default function FieldScheduleCustomFilterTab({
  users = [],
  projects = [],
  phases = [],
  costcodes = [],
  divisions = [],
  formTemplates = [],
  onClose,
  filterView = {},
  setActiveTab,
}) {
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  const { fieldScheduleFilters: activeFilters } = useSelector((state) => state.schedule);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedPhases, setSelectedPhases] = useState([]);
  const [selectedCostcodes, setSelectedCostcodes] = useState([]);
  const [selectedDivisions, setSelectedDivisions] = useState([]);
  const [selectedEventType, setSelectedEventType] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);

  const currentFilters = useMemo(() => {
    if (!filterView || activeFilters.filterView === filterView.id) {
      return activeFilters;
    }
    return filterView;
  }, [filterView, activeFilters]);

  const formTitle = useMemo(() => {
    if (filterView) {
      return `Edit ${filterView.filterViewName} View`;
    }

    return 'Custom Filter';
  }, [filterView]);

  // ----------------
  // Dropdown Options
  // ----------------

  const divisionOptions = useMemo(() => (
    divisions.map(({ name, id }) => ({ label: name, value: id }))
  ), [divisions]);

  const projectOptions = useMemo(() => {
    const activeProjects = projects.filter(({ active }) => active);

    return getProjectOptions({
      activeProjects,
      divisionIds: selectedDivisions,
      settings,
    });
  }, [projects, selectedDivisions]);

  const costcodeOptions = useMemo(() => {
    const divisionSet = new Set(selectedDivisions);
    const data = getCostcodeDropdownOptions({
      costcodes,
      divisionSet,
      projectIds: selectedProjects,
      phases,
      costcodeIdMap,
      projectIdMap,
      openLimit: true,
      responding: true,
    });
    return data;
  }, [selectedDivisions, costcodes, selectedProjects, phases, costcodeIdMap, projectIdMap]);

  const phaseOptions = useMemo(() => {
    const activeCostcodes = costcodes.map(({ active }) => active);
    const parsedPhases = [];

    selectedProjects.forEach((projectId) => {
      const options = getPhaseOptions({
        activeCostcodes,
        activePhases: phases,
        projectId,
      });

      parsedPhases.push(...options);
    });

    return parsedPhases;
  }, [costcodes, phases, selectedProjects]);

  const userOptions = useMemo(() => (
    users.map(({ name, id }) => ({ label: name, value: id }))
  ), [users]);

  const formOptions = useMemo(() => {
    const options = Object.values(formTemplates)
      .filter((ft) => ft.notDeleted)
      .map((ft) => {
        const {
          projectId: templateProjectId,
        } = ft;
        let secondRow = null;
        if (templateProjectId && templateProjectId in projectIdMap) {
          const { [templateProjectId]: { name: projectName } = {} } = projectIdMap;
          secondRow = <div style={{ fontSize: 10, fontStyle: 'italic', lineHeight: '12px' }}>{projectName}</div>;
        }
        return (
          {
            value: ft.id,
            label: (
              <>
                {ft.name}
                {secondRow}
              </>
            ),
          }
        );
      });
    return options;
  }, [formTemplates, projectIdMap]);

  const defaultFormShiftValues = {
    projects: [],
    costcodes: [],
    phases: [],
  };

  const defaultFormEventValues = {
    forms: [],
  };

  const defaultFormValues = {
    title: '',
    users: [],
    divisions: [],
    eventType: null,
  };

  const resetShiftFields = () => {
    form.setFieldsValue(defaultFormShiftValues);
    setSelectedProjects([]);
    setSelectedPhases([]);
    setSelectedCostcodes([]);
  };

  const resetEventFields = () => {
    form.setFieldsValue(defaultFormEventValues);
  };

  const resetFields = () => {
    form.setFieldsValue(defaultFormValues);
    setSelectedEventType(null);

    resetShiftFields();
    resetEventFields();
  };

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        ...defaultFormValues,
        filterViewName: getProperty(currentFilters, 'filterViewName', ''),
        users: getProperty(currentFilters, 'users', []),
        divisions: getProperty(currentFilters, 'divisions', []),
        projects: getProperty(currentFilters, 'projects', []),
        costcodes: getProperty(currentFilters, 'costcodes', []),
        phases: getProperty(currentFilters, 'phases', []),
        title: getProperty(currentFilters, 'title', ''),
        forms: getProperty(currentFilters, 'forms', []),
        eventType: getProperty(currentFilters, 'eventType', null),
      });

      setSelectedDivisions(
        getProperty(currentFilters, 'divisions', []),
      );
      setSelectedProjects(
        getProperty(currentFilters, 'projects', []),
      );
      setSelectedPhases(
        getProperty(currentFilters, 'phases', []),
      );
      setSelectedCostcodes(
        getProperty(currentFilters, 'costcodes', []),
      );
      setSelectedEventType(
        getProperty(currentFilters, 'eventType', null),
      );
    }

    return () => {
      resetFields();
    };
  }, [activeFilters, filterView]);

  const onSelectChange = useCallback((val, option) => {
    form.setFieldsValue({
      [option]: val,
    });

    switch (option) {
      case 'divisions': {
        setSelectedDivisions(val);
        break;
      }
      case 'projects': {
        setSelectedProjects(val);
        break;
      }
      case 'costcodes': {
        setSelectedCostcodes(val);
        break;
      }
      case 'phases': {
        setSelectedPhases(val);
        break;
      }
      case 'eventType': {
        if (val === 'shift') {
          resetEventFields();
        } else {
          resetShiftFields();
        }
        setSelectedEventType(val);
        break;
      }
      default: {
        break;
      }
    }
  }, [form]);

  const onApply = useCallback(() => {
    const values = form.getFieldsValue();

    if (filterView) {
      values.filterView = filterView.id;
    } else {
      values.filterView = null;
      values.filterViewName = null;
    }

    dispatch(updateFieldScheduleFilters(values));
    onClose();
  }, [filterView]);

  const onSave = useCallback(() => {
    const values = form.getFieldsValue();
    if (filterView) {
      dispatch(updateUserFieldScheduleFilterView(filterView.id, {
        name: values.filterViewName,
        filters: FILTER_VIEW_FIELDS.map(({ field, defaultValue }) => ({
          filterProperty: field,
          filterValue: JSON.stringify(values[field] || defaultValue),
          filterViewId: filterView.id,
        })),
      }));
      onApply();
    } else {
      setIsModalOpen(true);
    }
  }, [
    filterView,
    activeFilters,
  ]);

  const onDelete = useCallback(() => new Promise((resolve) => {
    CustomConfirmModal({
      title: 'Delete Filter View',
      content: (
        <p>
          Are you sure you wish to delete this filter view?
        </p>
      ),
      okText: 'Delete',
      cancelText: 'Cancel',
      async onOk() {
        resolve(await dispatch(
          deleteUserFieldScheduleFilterView(filterView.id),
        ));

        if (activeFilters.filterView === filterView.id) {
          dispatch(updateFieldScheduleFilters({
            filterView: null,
            filterViewName: null,
          }));
        } else {
          setActiveTab(activeFilters.filterView || 'custom');
        }

        setIsModalOpen(false);
      },
      onCancel() {
        resolve();
      },
    });
  }), [
    activeFilters,
    filterView,
    onClose,
  ]);

  const addNewFilterView = useCallback(async (filterViewName) => {
    const values = form.getFieldsValue();

    const {
      id,
      filterViewName: newFilterViewName,
    } = await dispatch(addUserFieldScheduleFilterView({
      name: filterViewName,
      filters: FILTER_VIEW_FIELDS.map(({ field, defaultValue }) => ({
        filterProperty: field,
        filterValue: JSON.stringify(values[field] || defaultValue),
      })),
    }));

    onApply();
    setActiveTab(id);
    dispatch(updateFieldScheduleFilters({
      filterView: id,
      filterViewName: newFilterViewName,
    }));
  }, [
    form,
    onClose,
    setActiveTab,
  ]);

  return (
    <>
      <div>
        <Row>
          <h3>{formTitle}</h3>
          <OnTraccrButton
            onClick={resetFields}
            style={{ marginLeft: 'auto' }}
            title="Reset"
          />
        </Row>
        <Form
          form={form}
          layout="vertical"
        >
          {
            filterView
              && (
                <>
                  <Form.Item
                    name="filterViewName"
                    label="Filter View Name"
                    rules={[
                      { required: true, message: 'Filter View Name is required' },
                    ]}
                  >
                    <OnTraccrTextInput
                      style={{ width: '100%' }}
                      placeholder="Name"
                    />
                  </Form.Item>
                  <Divider />
                </>
              )
            }

          <Form.Item
            name="eventType"
          >
            <SelectButton
              onChange={(val) => onSelectChange(val, 'eventType')}
              options={[
                {
                  name: 'Event',
                  id: 'event',
                },
                {
                  name: 'Shift',
                  id: 'shift',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="title"
            label="Title"
          >
            <OnTraccrTextInput
              style={{ width: '100%' }}
              placeholder="Title"
            />
          </Form.Item>
          <Form.Item
            name="users"
            label="Users"
          >
            <Select
              mode="multiple"
              onChange={(val) => onSelectChange(val, 'users')}
              allowClear
              options={userOptions}
              placeholder="Users"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="divisions"
            label="Divisions"
          >
            <Select
              onChange={(val) => onSelectChange(val, 'divisions')}
              allowClear
              options={divisionOptions}
              placeholder="Divisions"
              mode="multiple"
              optionFilterProp="label"
            />
          </Form.Item>
          {selectedEventType === 'shift' && (
            <Form.Item
              name="projects"
              label="Projects"
            >
              <Select
                onChange={(val) => onSelectChange(val, 'projects')}
                allowClear
                options={projectOptions}
                placeholder="Projects"
                mode="multiple"
                filterOption={filterSelectDropdown}
              />
            </Form.Item>
          )}
          {
            selectedEventType === 'shift' && phaseOptions.length > 0
              && (
                <Form.Item
                  name="phases"
                  label="Phases"
                >
                  <Select
                    onChange={(val) => onSelectChange(val, 'phases')}
                    allowClear
                    options={phaseOptions}
                    placeholder="Phases"
                    mode="multiple"
                    optionFilterProp="label"
                  />
                </Form.Item>
              )
          }
          {selectedEventType === 'shift' && (
            <Form.Item
              name="costcodes"
              label="Costcodes"
            >
              <TreeSelect
                onChange={(val) => onSelectChange(val, 'costcodes')}
                allowClear
                treeData={costcodeOptions}
                multiple
                treeNodeFilterProp="title"
                placeholder="Costcodes"
                values={selectedCostcodes}
                treeDefaultExpandAll={selectedPhases}
              />
            </Form.Item>
          )}
          {selectedEventType === 'event' && (
            <Form.Item
              name="forms"
              label="Forms"
            >
              <Select
                onChange={(val) => onSelectChange(val, 'forms')}
                allowClear
                options={formOptions}
                placeholder="Forms"
                mode="multiple"
                optionFilterProp="label"
              />
            </Form.Item>
          )}
        </Form>
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <div style={{ marginRight: 'auto' }}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onClose}
            />
            {
              filterView
                && (
                  <OnTraccrButton
                    title="Delete"
                    type="back"
                    onClick={onDelete}
                    style={{ marginLeft: 8 }}
                  />
                )
            }
          </div>
          <OnTraccrButton
            title={`${!filterView ? 'Create' : 'Save'} and Apply`}
            onClick={onSave}
            style={{ marginRight: 8 }}
          />
          <OnTraccrButton
            title="Apply"
            onClick={onApply}
          />
        </Row>
      </div>
      <SimpleTextInputModal
        title="Add Filter View"
        visible={isModalOpen}
        placeholder="Enter Filter View name"
        onClose={() => setIsModalOpen(false)}
        onSave={addNewFilterView}
      />
    </>
  );
}

FieldScheduleCustomFilterTab.propTypes = {
  formTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  divisions: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  costcodes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  users: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  ),
  phases: PropTypes.arrayOf(
    PropTypes.shape({
      [PropTypes.string]: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    }),
  ),
  onClose: PropTypes.func.isRequired,
  filterView: PropTypes.shape({
    filterViewName: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string),
    equipment: PropTypes.arrayOf(PropTypes.string),
    materials: PropTypes.arrayOf(PropTypes.string),
    phases: PropTypes.arrayOf(PropTypes.string),
    costcodes: PropTypes.arrayOf(PropTypes.string),
    labels: PropTypes.arrayOf(PropTypes.string),
    dateRange: PropTypes.arrayOf(PropTypes.number),
  }),
  schedule: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setActiveTab: PropTypes.func.isRequired,
};

FieldScheduleCustomFilterTab.defaultProps = {
  formTemplates: [],
  divisions: [],
  projects: [],
  costcodes: [],
  users: [],
  phases: [],
  filterView: null,
};
