import React, { useMemo } from 'react';
import { Select } from 'antd';
import {
  LineChartOutlined,
  BarChartOutlined,
  TableOutlined,
  NumberOutlined,
  PieChartOutlined,
  UnorderedListOutlined,
  AreaChartOutlined,
} from '@ant-design/icons';

import { getValidCharts } from './analytics.helpers';

const FULL_OPTS = [
  {
    value: 'line',
    node: (
      <Select.Option value="line" key="line">
        <div>
          <span className="analytics-chart-type-icon">
            <LineChartOutlined />
          </span>
          Line
        </div>
      </Select.Option>
    ),
  },
  {
    value: 'stackedLine',
    node: (
      <Select.Option value="stackedLine" key="stackedLine">
        <div>
          <span className="analytics-chart-type-icon">
            <AreaChartOutlined />
          </span>
          Stacked Line
        </div>
      </Select.Option>
    ),
  },
  {
    value: 'bar',
    node: (
      <Select.Option value="bar" key="bar">
        <div>
          <span className="analytics-chart-type-icon">
            <BarChartOutlined />
          </span>
          Bar
        </div>
      </Select.Option>
    ),
  },
  {
    value: 'pie',
    node: (
      <Select.Option value="pie" key="pie">
        <div>
          <span className="analytics-chart-type-icon">
            <PieChartOutlined />
          </span>
          Pie
        </div>
      </Select.Option>
    ),
  },
  {
    value: 'table',
    node: (
      <Select.Option value="table" key="table">
        <div>
          <span className="analytics-chart-type-icon">
            <TableOutlined />
          </span>
          Table
        </div>
      </Select.Option>
    ),
  },
  {
    value: 'number',
    node: (
      <Select.Option value="number" key="number">
        <div>
          <span className="analytics-chart-type-icon">
            <NumberOutlined />
          </span>
          Number
        </div>
      </Select.Option>
    ),
  },
  {
    value: 'list',
    node: (
      <Select.Option value="list" key="list">
        <div>
          <span className="analytics-chart-type-icon">
            <UnorderedListOutlined />
          </span>
          List
        </div>
      </Select.Option>
    ),
  },
];

export default function ({
  fieldType,
  selectedChartType,
  onSelect,
}) {
  const opts = useMemo(() => {
    const validCharts = getValidCharts(fieldType);
    return (
      FULL_OPTS
        .filter((opt) => validCharts.has(opt.value))
        .map((opt) => opt.node)
    );
  }, [fieldType]);
  return (
    <Select style={{ width: 150 }} value={selectedChartType} onSelect={onSelect}>
      {opts}
    </Select>
  );
}
