/* eslint-disable react/prop-types */
import React, {
  useCallback,
  useState,
} from 'react';
import { Row, Col } from 'antd';
import { BranchesOutlined, SettingOutlined } from '@ant-design/icons';

import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import useToggle from '../../common/hooks/useToggle';
import WorkflowLogicalConditionNodeConfigureDrawer from './WorkflowLogicalConditionNodeConfigureDrawer';

const HOVER_TEXT = `
Based on the condition set, the form will proceed to the next step of the workflow.
Leaving conditions empty in this step will result in the form not proceeding further.
`;

const DISABLED_TEXT = 'Add at least one Choose one or more field, Text, Attribute, Yes/No or Table Field `to the form to use this action.';

export default function WorkflowLogicalConditionNode({
  setElements,
  setDataMap,
  isDisplay,
  sections = [],
  customers = [],
  projects = [],
  users = [],
  costcodes = [],
  phases = [],
  projectIdMap = {},
  vendors = [],
  equipment = [],
  formTemplates = [],
  labels = [],
  contactAddressBooks = {},
  buckets = [],
} = {}) {
  // eslint-disable-next-line
  return ({
    id,
    draggable,
    disabled,
    data = {},
  }) => {
    const {
      formulas = [],
    } = data;
    const [nodeFormulas, setNodeFormulas] = useState(formulas);
    const { toggle, isToggled } = useToggle();

    const onChange = useCallback((newFormulas) => {
      setDataMap((dataMap) => ({
        ...dataMap,
        [id]: { formulas: newFormulas },
      }));
      setNodeFormulas(newFormulas);
      toggle();
    }, [id, toggle]);

    const onClose = useCallback(() => {
      toggle();
      setNodeFormulas(nodeFormulas);
    }, [toggle, nodeFormulas]);

    return (
      <WorkflowActionNode
        id={id}
        title={`Logical Condition Check ${disabled ? ' - DISABLED' : ''}`}
        Icon={BranchesOutlined}
        type="logicalCondition"
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={disabled ? DISABLED_TEXT : HOVER_TEXT}
        style={disabled ? { opacity: 0.7 } : {}}
        className="workflow-logic-node"
      >
        {!draggable && (
        <div>
          <Row style={{ margin: '20px 0px' }}>
            <BorderlessButton
              title={formulas?.length ? `${formulas.length} Condition(s)` : 'Configure'}
              style={{ margin: 5, background: 'transparent' }}
              iconNode={<SettingOutlined />}
              onClick={toggle}
            />
          </Row>

          <Row justify="space-between" style={{ marginBottom: 5, marginLeft: 20, marginRight: 20 }}>
            <Col>
              True
            </Col>
            <Col>
              False
            </Col>
          </Row>

          <WorkflowHandle type="target" position="top" disabled={isDisplay} />

          <WorkflowHandle
            type="source"
            position="bottom"
            style={{ left: '20%' }}
            id={`${id}-logicalConditionTrue`}
            disabled={isDisplay}
          />
          <WorkflowHandle
            type="source"
            position="bottom"
            style={{ left: '80%' }}
            id={`${id}-logicalConditionFalse`}
            disabled={isDisplay}
          />
          <WorkflowLogicalConditionNodeConfigureDrawer
            formulas={nodeFormulas}
            sections={sections}
            customers={customers}
            projects={projects}
            users={users}
            costcodes={costcodes}
            phases={phases}
            projectIdMap={projectIdMap}
            vendors={vendors}
            equipment={equipment}
            formTemplates={formTemplates}
            labels={labels}
            contactAddressBooks={contactAddressBooks}
            buckets={buckets}
            onClose={onClose}
            onChange={setNodeFormulas}
            onSave={onChange}
            visible={isToggled}
          />
        </div>
        )}
      </WorkflowActionNode>
    );
  };
}
