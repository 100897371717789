import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Typography } from 'antd';
import {
  HomeOutlined,
  DollarOutlined,
  PhoneOutlined,
} from '@ant-design/icons';

import Badge from '../common/badge';

import Permissions from '../auth/Permissions';

import ListCard from '../common/ListCard';
import colors from '../constants/Colors';
import ContactLabels from './ContactLabels';

export default function ContactCard(props) {
  const {
    id,
    name,
    type: vendorType,
    officeAddress,
    officeNumber,
    officeEmail,
    billingEmail,
    active,
    onArchive,
    onEdit,
    onDelete,
    onClick,
    style,
  } = props;
  const item = {...props};
  delete item.onArchive;
  delete item.onEdit;
  delete item.onDelete;
  delete item.onClick;
  delete item.style;
  
  const projects = useSelector(state => state.projects.projects);
  const unreadClientComms = useSelector(state => state.customers.unreadClientComms);

  const projectsWithThisCustomer = useMemo(() => (
    projects.filter(({ customerId }) => id === customerId)
  ),[id,projects]);

  const unreadCount = useMemo(() => (
    unreadClientComms.filter((note) => note.customerId === id).length
  ),[id, unreadClientComms])

  const hasWrite = vendorType ? Permissions.has('VENDORS_WRITE') : Permissions.has('CUSTOMERS_WRITE');

  return (
  <ListCard 
    style={style}
    onArchive={hasWrite ? () => onArchive(item) : null}
    onEdit={hasWrite ? () => onEdit(item): null}
    onDelete={hasWrite && projectsWithThisCustomer.length === 0 ? () => onDelete(item) : null}
    onClick={() => onClick(item)}
    isActive={active}
    bodyStyle={{ height: '100%' }}
  >
    <Row style={{
      height:17,
      width:'100%',
      paddingBottom:25,
      paddingTop:6,
      overflowWrap:'anywhere',
      }} type='flex'>
      <Typography.Text
          ellipsis
          style={{
            width:'100%',
            fontFamily:'roboto-bold',
            fontSize:16,
            color:colors.ONTRACCR_BLACK,
          }}>
          {name}
        </Typography.Text>
    </Row>
    <Row>
      <ContactLabels
        size="small"
        contactId={id}
        isVendor={!!vendorType}
      />
    </Row>
    {vendorType
      ? <Row>
        <Typography.Text
          ellipsis
          className='customer-card-detail'
        >
            {vendorType}
        </Typography.Text>
      </Row>
    : null}
    <Row>
      <Typography.Paragraph
        ellipsis={{ rows: 2 }}
        className='customer-card-detail'
      >
          {officeAddress}
        </Typography.Paragraph>
    </Row>
    {officeNumber
    ? <Row>
      <Typography.Text
        ellipsis
        className='customer-card-detail'
      >
        <PhoneOutlined /> {officeNumber}
        </Typography.Text>
    </Row>
    : null }
    {officeEmail
    ? <Row>
      <Typography.Text
        ellipsis
        className='customer-card-detail'
      >
        <HomeOutlined/>   {officeEmail}
      </Typography.Text>
    </Row>
    : null}
    {billingEmail ?
    <Row>
      <Typography.Text
        ellipsis
        className='customer-card-detail'
      >
        <DollarOutlined/> {billingEmail}
      </Typography.Text>
    </Row>
    : null}
    {
      unreadCount > 0
      && <Badge
        count={unreadCount}
        style={{
          position:'absolute',
          right: 2,
          top: 2,
          lineHeight: '15px',
          marginLeft: 0,
          paddingLeft: 0,
          paddingRight: 0,
        }}
      />
    }
  </ListCard>
)};