import {
  deleteFromGlobalAddressBook,
  deleteFromLocalAddressBooks,
  addToGlobalAddressBook,
  addToLocalAddressBook,
  updateAddressBookEntry,
} from 'ontraccr-common/lib/Contacts';

import {
  GET_GLOBAL_ADDRESS_BOOK,
  UPDATE_ADDRESS_BOOK,
  ADD_TO_GLOBAL_ADDRESS_BOOK,
  MASS_ADD_TO_LOCAL_ADDRESS_BOOKS,
  MASS_DELETE_FROM_LOCAL_ADDRESS_BOOKS,
  MASS_DELETE_FROM_GLOBAL_ADDRESS_BOOK,
} from '../../state/actionTypes';

const initialState = {
  globalAddressBooks: {},
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_GLOBAL_ADDRESS_BOOK: {
      const {
        payload: {
          globalAddressBooks = {},
        } = {},
      } = action;
      return {
        ...state,
        globalAddressBooks,
      };
    }
    case UPDATE_ADDRESS_BOOK: {
      const {
        payload: {
          id: addressBookEntryId,
          name,
          role,
          phoneNumber,
          email,
        } = {},
      } = action;

      const {
        globalAddressBooks: stateGlobalAddressBooks = {},
      } = state;

      const updatedEntry = {
        id: addressBookEntryId,
        name,
        role,
        phoneNumber,
        email,
      };

      const globalAddressBooks = updateAddressBookEntry({
        addressBookEntryId,
        globalAddressBooks: stateGlobalAddressBooks,
        updatedEntry,
      });

      return {
        ...state,
        globalAddressBooks,
      };
    }
    case MASS_ADD_TO_LOCAL_ADDRESS_BOOKS: {
      const {
        payload: {
          customerIds = [],
          vendorIds = [],
          addressBookEntryIds = [],
        } = {},
      } = action;

      const {
        globalAddressBooks: stateGlobalAddressBooks = {},
      } = state;

      let newGlobalAddressBooks = { ...stateGlobalAddressBooks };

      addressBookEntryIds.forEach((addressBookEntryId) => {
        customerIds.forEach((customerId) => {
          const updatedGlobalAddressBooks = addToLocalAddressBook({
            addressBookEntryId,
            globalAddressBooks: newGlobalAddressBooks,
            customerId,
          });
          newGlobalAddressBooks = { ...updatedGlobalAddressBooks };
        });
        vendorIds.forEach((vendorId) => {
          const updatedGlobalAddressBooks = addToLocalAddressBook({
            addressBookEntryId,
            globalAddressBooks: newGlobalAddressBooks,
            vendorId,
          });
          newGlobalAddressBooks = { ...updatedGlobalAddressBooks };
        });
      });

      return {
        ...state,
        globalAddressBooks: newGlobalAddressBooks,
      };
    }
    case ADD_TO_GLOBAL_ADDRESS_BOOK: {
      const {
        payload: {
          customerId,
          vendorId,
          id,
          name,
          role,
          phoneNumber,
          email,
        } = {},
      } = action;

      const {
        globalAddressBooks: stateGlobalAddressBooks = {},
      } = state;

      const entry = {
        id,
        name,
        role,
        phoneNumber,
        email,
      };

      const globalAddressBooks = addToGlobalAddressBook({
        addressBookEntryId: id,
        globalAddressBooks: stateGlobalAddressBooks,
        entry,
        customerId,
        vendorId,
      });

      return {
        ...state,
        globalAddressBooks,
      };
    }
    case MASS_DELETE_FROM_LOCAL_ADDRESS_BOOKS: {
      const {
        payload: {
          addressBookEntryIds,
          contactIds,
        } = {},
      } = action;

      const {
        globalAddressBooks: stateGlobalAddressBooks = {},
      } = state;

      let newGlobalAddressBooks = { ...stateGlobalAddressBooks };

      addressBookEntryIds.forEach((addressBookEntryId) => {
        contactIds.forEach((contactId) => {
          const updatedGlobalAddressBooks = deleteFromLocalAddressBooks({
            addressBookEntryId,
            globalAddressBooks: newGlobalAddressBooks,
            contactId,
          });
          newGlobalAddressBooks = { ...updatedGlobalAddressBooks };
        });
      });

      return {
        ...state,
        globalAddressBooks: newGlobalAddressBooks,
      };
    }
    case MASS_DELETE_FROM_GLOBAL_ADDRESS_BOOK: {
      const {
        payload: {
          addressBookEntryIds,
        } = {},
      } = action;

      const {
        globalAddressBooks: stateGlobalAddressBooks = {},
      } = state;

      let newGlobalAddressBooks = { ...stateGlobalAddressBooks };

      addressBookEntryIds.forEach((addressBookEntryId) => {
        const updatedGlobalAddressBooks = deleteFromGlobalAddressBook({
          addressBookEntryId,
          globalAddressBooks: newGlobalAddressBooks,
        });

        newGlobalAddressBooks = { ...updatedGlobalAddressBooks };
      });

      return {
        ...state,
        globalAddressBooks: newGlobalAddressBooks,
      };
    }
    default:
      return state;
  }
};
