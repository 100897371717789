import React from 'react';
import PropTypes from 'prop-types';

import CheckRows from '../../CheckRows';

import { SETTINGS_TEXT } from './quickbooks.constants';

function QuickBooksSyncTypes({
  type,
  disabled,
  selectedIntegration,
  onIntegrationUpdate,
  divider,
}) {
  return (
    <CheckRows
      isChecked={({ key }) => selectedIntegration?.[key]}
      onChange={({ key }) => onIntegrationUpdate({
        [key]: !selectedIntegration?.[key],
      })}
      checkBoxDisabled={() => disabled}
      data={[
        {
          key: `${type}Materials`,
          helpText: SETTINGS_TEXT[`${type}Materials`],
          divider,
          style: { marginLeft: 20 },
        },
      ]}
    />
  );
}

QuickBooksSyncTypes.propTypes = {
  type: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultDivisionId: PropTypes.string,
    defaultTaxCode: PropTypes.string,
    defaultPurchaseAccount: PropTypes.string,
    pullEnabled: PropTypes.bool,
    pushEnabled: PropTypes.bool,
    divisions: PropTypes.arrayOf(PropTypes.string),
  }),
  onIntegrationUpdate: PropTypes.func.isRequired,
  divider: PropTypes.bool,
};

QuickBooksSyncTypes.defaultProps = {
  selectedIntegration: null,
  divider: true,
};

export default QuickBooksSyncTypes;
