import PropTypes from 'prop-types';
import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import { animated, useSpring } from 'react-spring';
import FormSectionHeader from '../forms/FormBuilder/FormSections/FormSectionHeader';
import Colors from '../constants/Colors';

const border = '1px solid lightgray';
const COLLAPSED_HEIGHT = 40;
const EXPANDED_HEIGHT = 600;

export default function CustomFieldsSearchSection({
  fieldToSearch,
  section,
}) {
  const { fields: sectionFields = [], name: sectionName, id } = section;

  const [style, setStyle] = useSpring(() => ({ maxHeight: EXPANDED_HEIGHT }));
  const [open, setOpen] = useState(true);
  const onClick = useCallback(() => {
    setOpen(!open);
  }, [open]);

  useEffect(() => {
    setStyle({
      maxHeight: open ? EXPANDED_HEIGHT : COLLAPSED_HEIGHT,
    });
  }, [open]);

  return (
    <animated.div
      style={{
        border,
        marginBottom: 5,
        borderRadius: 4,
        overflow: 'hidden',
        backgroundColor: 'white',
        marginTop: 10,
        ...style,
      }}
    >
      <FormSectionHeader
        id={id}
        title={sectionName}
        style={{
          // Subtract 1 for border or end of animation has a weird snap effect
          height: COLLAPSED_HEIGHT - 1,
          borderBottom: border,
        }}
        open={open}
        onClick={onClick}
        isDisplay
      />
      <animated.div
        style={{
          height: style.maxHeight.interpolate({
            range: [COLLAPSED_HEIGHT, EXPANDED_HEIGHT],
            output: [0, 'fit-content'],
          }),
          maxHeight: EXPANDED_HEIGHT - COLLAPSED_HEIGHT,
          backgroundColor: Colors.OPACITY_LIGHT_GRAY,
          paddingBottom: 0,
          overflow: 'scroll',
        }}
      >
        <div style={{ marginLeft: 10 }}>
          {sectionFields.map(fieldToSearch)}
        </div>
      </animated.div>
    </animated.div>
  );
}

CustomFieldsSearchSection.propTypes = {
  fieldToSearch: PropTypes.func.isRequired,
  section: PropTypes.shape({
    fields: PropTypes.array,
    id: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
};
