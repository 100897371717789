import React from 'react';
import { useSelector } from 'react-redux';
import { Button } from 'antd';

import Badge from '../common/badge';

import { getTextColor } from '../schedule/scheduleHelpers';

export default ({
  title,
  onClick,
  active,
  badgeCount,
}) => {
  const {
    primaryColor,
    accentColor,
  } = useSelector(state => state.clientPortal.settings);

  const unselectedColor = getTextColor(primaryColor);

  return (
    <Button
      onClick={onClick}
      className='client-portal-header-button'
      style={{
        color: active ? accentColor : unselectedColor, border: 'none',
        fontWeight: active ? 'bold' : 'normal',
      }}
    >
      {title} {badgeCount > 0 && <Badge count={badgeCount}/>}
    </Button>
  )
}