import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import {
  UpOutlined,
  DownOutlined,
} from '@ant-design/icons';

import OnTraccrTextInput from '../inputs/OnTraccrTextInput';

export default ({
  numberOfPages,
  activePage,
  onActivePageChange,
  darkMode,
  className,
}) => {
  const [statePage,setStatePage] = useState(activePage);
  let editTimer = useRef(null).current;

  const onTextChange = useCallback((e) => {
    const {
      target:{
        value,
      } = {}
    } = e;

    setStatePage(value);
    if(editTimer) clearTimeout(editTimer);
    // For some reason this gives a linter error saying to make editTimer use 'useRef', which it does...
    // eslint-disable-next-line
    editTimer = setTimeout(() => {
      try {
        const int = parseInt(value);
        if(Number.isInteger(int) && int > 0 && int <= numberOfPages) {
          onActivePageChange(int);
        }
      } catch(err) {}
    },500);
  },[editTimer,numberOfPages,activePage]); // Need to pass activePage dep or onActivePageChange references old active page.

  useEffect(() => {
    setStatePage(activePage);
  },[activePage]);
  if(!numberOfPages) return null;
  return (
    <Row className={className ? `${className}-pdf-controls-container` : 'pdf-controls-container'} justify='center' align='top' gutter={[0,20]}>
      <Col>
        <Row justify='center'>
          <Button
            className={activePage > 1 ? 'pdf-control-button' : 'pdf-control-button-disabled'}
            icon={
              <UpOutlined/>
            }
            onClick={() => onActivePageChange(activePage - 1)}
          />
        </Row>
        <Row className='pdf-page-number' align='middle'>
          <OnTraccrTextInput
            value={statePage}
            style={{ padding:0, textAlign:'center', height:'40px' }}
            onChange={onTextChange}
          />
          <div className={`pdf-page-number-suffix-${darkMode ? 'dark' : 'light'}`}>
            {`/ ${numberOfPages}`}
          </div>
        </Row>
        <Row justify='center'>
          <Button
            className={activePage < numberOfPages ? 'pdf-control-button' : 'pdf-control-button-disabled'}
            icon={
              <DownOutlined/>
            }
            onClick={() => onActivePageChange(activePage + 1)}
          />
        </Row>
      </Col>
    </Row>
  );
}
