import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';

import costcodeRequired from '../../assets/images/NUX/costcodeRequired.png';
import addCostcode from '../../assets/images/NUX/addCostcode.png';
import phasedCostcode from '../../assets/images/NUX/phasedCostcode.png';

const getStartDesc = (t) => `To add cost codes to the ${t('Project').toLowerCase()},
start by checking the 'Cost Code Required?' box.`;

const getAddingDesc = (t) => (
<div style={{ fontSize: 13 }}>
Choose to import codes from your company's
global cost code list via the drop-down menu or you can add
brand new cost codes specific to the {t('Project').toLowerCase()} via the 'Add' button.
</div>
);

const getPhasedDesc = (t) => (
<div style={{ fontSize: 13 }}>
If you'd like any of the added cost codes to
belong to a specific 'phase' of the {t('Project').toLowerCase()}, such as 'Level 1',
'Level 2', etc, check the 'Phased' box next to the cost code.
Phases are configured in a {t('Project').toLowerCase()}'s Budget Tracking section (step 3).
</div>);

export default function NuxAddingCostcodes({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const startDesc = getStartDesc(t);
  const addingDesc = getAddingDesc(t);
  const phasedDesc = getPhasedDesc(t);

  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 74px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:'',
        description:startDesc,
        cols:[0,12,12],
        image:{
          src:costcodeRequired,
          style:{
            height:50,
            width:303,
          }
        },
      },{
        title:`Adding Global/${t('Project')} Codes`,
        description:addingDesc,
        cols:[0,17,7],
        image:{
          src:addCostcode,
          style:{
            height:20,
            width:175,
          }
        },
      },{
        title:'Select Phased Codes',
        description:phasedDesc,
        cols:[0,20,4],
        image:{
          src:phasedCostcode,
          style:{
            height:50,
            width:45,
          }
        },
      }]}
      tutorialLink={'PM2. Job Costing _ Budget Tracking.mp4'}
    />
  );
}
