import React, { useState, useMemo, useCallback } from 'react';
import { useSelector } from 'react-redux';

import SelectOrAdd from '../common/inputs/SelectOrAdd';

import { getIdMap } from '../helpers/helpers';
import Labels from './Labels';

export default function LabelSelector({
  onChange,
  value = [],
  isDisplay,
  type,
}) {
  const labels = useSelector((state) => state.labels);
  const filteredLabels = useMemo(() => (
    labels.filter((label) => label.type === type)
  ), [type, labels]);

  const [customValues, setCustomValues] = useState([]);

  const labelIdMap = useMemo(() => getIdMap(filteredLabels),[filteredLabels]);

  const labelSet = useMemo(() => new Set(filteredLabels.map((l) => l.title)), [filteredLabels]);

  const onSelect = useCallback((newValues) => {
    onChange(
      newValues.map((stringOrId) => {
        if (stringOrId in labelIdMap) return labelIdMap[stringOrId];
        return { title: stringOrId };
      }),
    );
  }, [labelIdMap]);

  const onAddNew = useCallback((newLabel) => {
    onChange(value.concat({ title: newLabel }));
    if (!labelSet.has(newLabel)) {
      setCustomValues(customValues.concat([{ id: newLabel, title: newLabel }]));
    }
  }, [setCustomValues, customValues, value, labelSet]);

  const fullOptions = useMemo(() => (
    filteredLabels.map((l) => ({ id: l.id, title: l.title })).concat(customValues)
  ), [filteredLabels, customValues]);

  const parsedValues = useMemo(() => (
    value.map((val) => val.id || val.title)
  ), [value]);

  return isDisplay ? (
    <Labels
      labels={value}
      showEmpty={false}
    />
  ) : (
    <SelectOrAdd
      type="Labels"
      options={fullOptions}
      mode="multiple"
      value={parsedValues}
      onSelect={onSelect}
      onAddNew={onAddNew}
      dropdownMatchSelectWidth
      dropdownWidth={350}
    />
  );
}
