import React from 'react';
import { Row, Col, } from 'antd';
import SideListText from '../../common/SideList/SideListText';
import HoverHelp from '../../common/HoverHelp';

export default ({ title, helpText }) => (
  <Row style={{width:'100%', padding: 5 }} justify='start' align='middle' gutter={6}>
    <Col style={{maxWidth: '90%'}}>
      <SideListText text={title}/>
    </Col>
    <Col>
      <HoverHelp content={helpText}/>
    </Col>
  </Row>
);
