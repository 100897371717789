import React from 'react';
import { DateTime } from 'luxon';
import { message } from 'antd';

import LiveFeedUserCard from './LiveFeedUserCard';
import LiveFeedProjectCard from './LiveFeedProjectCard';
import LiveFeedCostcodeCard from './LiveFeedCostcodeCard';
import LiveFeedTeamCard from './LiveFeedTeamCard';
import LiveFeedTimeCardCard from './LiveFeedTimeCardCard';
import LiveFeedTimeCardIcon from './LiveFeedTimeCardIcon';
import LiveFeedClockCard from './LiveFeedClockCard';
import LiveFeedNote from './LiveFeedNote';
import LiveFeedPost from './LiveFeedPost';

import {
  USER_CREATE_ACTION,
  COST_CODE_CREATE_ACTION,
  PROJECT_CREATE_ACTION,
  TEAM_CREATE_ACTION,
  ADD_NOTE_ACTION,
  CLOCK_IN_ACTION,
  CLOCK_OUT_ACTION,
  END_BREAK_ACTION,
  SWITCH_TASK_ACTION,
  TIME_CARD_SUBMIT_ACTION,
  MANUAL_ENTRY_ACTION,
  BUDGET_ACTION,
  POST_ACTION,
  CLOCK_ACTIONS,
} from './liveFeed.constants';

import Permissions from '../../auth/Permissions';
import Analytics from '../../helpers/Analytics';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import config from '../../config';

export default {};

const getClockAction = ({
  verb,
  modifier,
  possessive,
  firstPersonVerb,
  thirdPersonVerb,
}) => function ({ metadata = {}, userId, userMap = {} }) {
  const {
    userId: taskUserId,
  } = metadata;
  const {
    [taskUserId]: {
      name = '',
    } = {},
  } = userMap;

  const isOwnAction = userId === taskUserId;
  let ourVerb = verb;
  if (isOwnAction && firstPersonVerb) {
    ourVerb = firstPersonVerb;
  } else if (!isOwnAction && thirdPersonVerb) {
    ourVerb = thirdPersonVerb;
  }

  const objectName = `${name}${possessive ? '\'s' : ''}`;
  return (
    <span>
      {ourVerb}
      {' '}
      <b>{userId === taskUserId ? '' : objectName}</b>
      {' '}
      {modifier}
    </span>
  );
};

const getClockProps = (type) => ({
  metadata,
  targetId,
  history,
  timeEntryUserMap = {},
}) => {
  const {
    userId,
  } = metadata;
  const tasks = timeEntryUserMap[userId] ?? [];
  const relevantTask = tasks.find((task) => task.id === targetId) || {};
  const {
    projectId,
    costcodeId,
    serviceLocation,
  } = relevantTask;
  return {
    task: {
      projectId,
      costcodeId,
      serviceLocation,
    },
    projectId,
    costcodeId,
    type,
    onClick: () => {
      Analytics.track('LiveFeed/Clock', { Type: type });
      history.push('/users', { targetId: userId });
    },
  };
};

const getProject = (event) => {
  const { projectId } = getClockProps()(event);
  return projectId;
};

const actionMap = {
  [COST_CODE_CREATE_ACTION]: {
    action: ({ groupId }) => `added ${groupId ? '' : 'a '}new cost code${groupId ? 's' : ''}`,
    child: (props) => <LiveFeedCostcodeCard {...props} />,
    childProps: ({ ccMap, targetId, history }) => {
      const {
        [targetId]: costcode,
      } = ccMap;
      if (!costcode) return null;
      const {
        projectId,
      } = costcode;
      return {
        costcodes: [costcode],
        onClick: () => {
          Analytics.track('LiveFeed/Costcode');
          history.push(`/costcodes/${projectId ? 'project-specific' : 'global'}`, { targetId, projectId });
        },
      };
    },
    project: ({ ccMap, targetId }) => {
      const {
        [targetId]: {
          projectId,
        } = {},
      } = ccMap;
      return projectId;
    },
  },
  [USER_CREATE_ACTION]: {
    action: () => 'added a new user',
    child: (props) => <LiveFeedUserCard {...props} />,
    childProps: ({ userMap, targetId, history }) => {
      const {
        [targetId]: user = {},
      } = userMap;
      if (!user) return null;
      return {
        user,
        onClick: () => {
          Analytics.track('LiveFeed/User');
          history.push('/users', { targetId });
        },
      };
    },
  },
  [PROJECT_CREATE_ACTION]: {
    action: ({ t }) => `added a new ${t('Project').toLowerCase()}`,
    child: (props) => <LiveFeedProjectCard {...props} />,
    childProps: ({ projectMap, targetId, history }) => {
      const {
        [targetId]: project = {},
      } = projectMap;
      if (!project) return null;
      return {
        project,
        onClick: () => {
          Analytics.track('LiveFeed/Project');
          history.push('/projects', { targetId });
        },
      };
    },
    project: ({ targetId }) => targetId,
  },
  [TEAM_CREATE_ACTION]: {
    action: () => 'added a new team',
    child: (props) => <LiveFeedTeamCard {...props} />,
    childProps: ({ teamMap, targetId, history }) => {
      const {
        [targetId]: team = {},
      } = teamMap;
      if (!team) return null;
      return {
        team,
        onClick: () => {
          Analytics.track('LiveFeed/Team');
          history.push(`/teams/${targetId}`);
        },
      };
    },
  },
  [ADD_NOTE_ACTION]: {
    action: () => 'added a note',
    child: () => null,
    childProps: () => ({}),
    extra: ({ timeEntryUserMap, targetId, userId }) => {
      const tasks = timeEntryUserMap[userId] ?? [];
      const relevantTask = tasks.find((task) => task.id === targetId) || {};
      const {
        note,
      } = relevantTask;
      if (!note) return null;
      return (
        <LiveFeedNote task={{ note }} />
      );
    },
    project: (event) => {
      const { projectId } = getClockProps()({ ...event, metadata: { userId: event.userId } });
      return projectId;
    },
  },
  [CLOCK_IN_ACTION]: {
    action: getClockAction({ verb: 'clocked', modifier: 'in' }),
    child: (props) => <LiveFeedClockCard {...props} />,
    childProps: getClockProps('CLOCK_IN'),
    project: getProject,
  },
  [CLOCK_OUT_ACTION]: {
    action: getClockAction({ verb: 'clocked', modifier: 'out' }),
    child: (props) => <LiveFeedClockCard {...props} />,
    childProps: getClockProps('CLOCK_OUT'),
    project: getProject,
  },
  [END_BREAK_ACTION]: {
    action: getClockAction({
      verb: 'ended',
      modifier: 'break',
      possessive: true,
      firstPersonVerb: 'took a',
    }),
    child: (props) => <LiveFeedClockCard {...props} />,
    childProps: getClockProps('END_BREAK'),
    project: getProject,
  },
  [SWITCH_TASK_ACTION]: {
    action: getClockAction({
      verb: 'switched',
      modifier: 'tasks',
      possessive: true,
    }),
    child: (props) => <LiveFeedClockCard {...props} />,
    childProps: getClockProps('SWITCH_TASK'),
    project: getProject,
  },
  [TIME_CARD_SUBMIT_ACTION]: {
    action: ({ userId, metadata = {}, userMap = {} }) => {
      const {
        userId: targetId,
      } = metadata;
      const isOwn = targetId === userId;
      const {
        [targetId]: {
          name,
        } = {},
      } = userMap;
      if (!name) {
        // This can happen if the target user is in a division
        // that the current user does not have access to
        return null;
      }
      return isOwn ? 'submitted time cards' : (
        <span>
          submitted
          {' '}
          <b>
            {name}
            &apos;s
          </b>
          {' '}
          time cards
        </span>
      );
    },
    child: LiveFeedTimeCardCard,
    customIcon: <LiveFeedTimeCardIcon />,
    childProps: ({ metadata = {}, userId, history }) => {
      const { start, end, userId: user } = metadata;
      if (!start || !end) return null;
      return {
        dates: [DateTime.fromMillis(start), DateTime.fromMillis(end)],
        onClick: () => {
          const type = userId === Permissions.id ? 'my' : 'team';
          Analytics.track('LiveFeed/Timecard', { Type: type });
          history.push(`timecards/${type}`, {
            start,
            user,
          });
        },
      };
    },
  },
  [POST_ACTION]: {
    action: () => null,
    child: () => null,
    childProps: () => ({}),
    extra: (post) => {
      if (!post) return null;
      return (
        <LiveFeedPost post={post} />
      );
    },
    project: (post) => post.projectId,
  },
};

const shouldHideBasedOffDivision = ({
  userId,
  action,
  projectId,
  projectMap = {},
  userDivisions = {},
  selectedDivisions = new Set(),
  hasDivision = () => false,
}) => {
  if (!config.showDivisions) return false;
  const {
    [userId]: postUsersDivisions = [],
  } = userDivisions;
  if (action === POST_ACTION || !projectId) {
    return !postUsersDivisions.some((divisionId) => selectedDivisions.has(divisionId));
  }
  const {
    [projectId]: {
      divisionId,
    } = {},
  } = projectMap;

  return (
    !selectedDivisions.has(divisionId)
    && !hasDivision(selectedDivisions)
  );
};

/**
 * Returns the channel type
 * @param {string} key
 * @param {object} projectChannels
 * @param {object} customChannels
 * @returns {string} channel type
 */
export const getChannelType = ({ key, projectChannels, customChannels }) => {
  if (key in projectChannels) return 'project';
  if (key in customChannels) return 'custom';
  return 'global';
};

/**
 * Gets events relevant to a specific channel
 *  Global channel:
 *    - all events except project specific clock in/outs
 *  Project channels:
 *    - clock in events
 *    - clock out events
 *  Custom Channels:
 *    - no events
 * @param {string} channelType - global, project, custom
 * @param {string} channelId
 * @param {object[]} events
 * @param {object} projectChannels
 * @returns {object[]} relevant events
 */
export const getRelevantEvents = ({
  channelType,
  channelId,
  events = [],
  projectChannels = {},
}) => {
  const { projectId: channelProjectId } = projectChannels[channelId] ?? {};
  switch (channelType) {
    case 'custom':
      return [];
    case 'project': {
      const projectSpecificEvents = events.filter(({
        action,
        metadata: { projectId } = {},
      }) => (
        CLOCK_ACTIONS.has(action)
        && channelProjectId === projectId
      ));
      return projectSpecificEvents;
    }
    case 'global': {
      const globalEvents = events.filter((event) => (
        !CLOCK_ACTIONS.has(event.action) || !event.metadata?.projectId
      ));
      return globalEvents;
    }
    default:
      return events;
  }
};

export const parseEvents = ({
  events = [],
  posts = {},
  userMap = {},
  ccMap = {},
  projectMap = {},
  teamMap = {},
  lastLogin = 0,
  history,
  onPostClick,
  selectedDivisions = new Set(),
  userDivisions = {},
  timeEntryUserMap = {},
  t,
}) => {
  const fullData = events.concat(
    Object.values(posts).map((post) => ({
      ...post,
      timestamp: post.createdAt,
      action: POST_ACTION,
    })),
  ).sort((a, b) => b.timestamp - a.timestamp);
  return (
    fullData.map((event = {}) => {
      const {
        id,
        userId,
        timestamp,
        action,
        channelId,
      } = event;
      const {
        [userId]: user = {},
      } = userMap;

      const ourItem = actionMap[action];
      if (!ourItem) return null; // Unsupported action
      const {
        action: actionFunc,
        child,
        childProps,
        extra = () => null,
        project = () => null,
        customIcon = null,
        hasDivision = () => false,
      } = ourItem;

      const itemChildProps = childProps({
        ...event,
        ccMap,
        userMap,
        timeEntryUserMap,
        projectMap,
        teamMap,
        history,
      });
      const itemAction = actionFunc({
        ...event,
        userMap,
        t,
      });

      if (!itemChildProps
        || (!itemAction && action !== POST_ACTION)) return null; // Event target has been deleted

      const projectId = project({
        ...event, userMap, ccMap, timeEntryUserMap,
      });

      if (shouldHideBasedOffDivision({
        userId,
        action,
        projectId,
        projectMap,
        userDivisions,
        selectedDivisions,
        hasDivision,
      })) {
        return null;
      }
      return {
        id,
        type: action,
        user,
        isCurrentUser: userId === Permissions.id,
        unread: timestamp > lastLogin,
        action: itemAction,
        date: timestamp ? DateTime.fromMillis(timestamp) : DateTime.local(),
        child,
        childProps: itemChildProps,
        extra: extra({
          ...event,
          timeEntryUserMap,
          userMap,
          onPostClick,
        }),
        project: projectId,
        customIcon,
        channelId,
      };
    })
  );
};

export const getEventId = (event) => `${event.action}-${event.targetId}`;

export const getNewEvent = ({
  oldEvents = [],
  newEvents = [],
}) => {
  const eventSet = new Set(oldEvents.map(getEventId));
  const [mostRecent] = newEvents.filter((event) => !eventSet.has(getEventId(event) && event.userId !== Permissions.id)).sort((a, b) => b.timestamp - a.timestamp);
  return mostRecent;
};

const getToastMessages = (t) => ({
  [USER_CREATE_ACTION]: 'A new user has been added',
  [PROJECT_CREATE_ACTION]: `A new ${t('Project').toLowerCase()} has been created`,
  [TEAM_CREATE_ACTION]: 'A new team has been created',
  [COST_CODE_CREATE_ACTION]: 'A new cost code has been added',
  [CLOCK_IN_ACTION]: 'A user has just clocked in',
  [SWITCH_TASK_ACTION]: 'A user has just switched tasks',
  [CLOCK_OUT_ACTION]: 'A user has just clocked out',
  [ADD_NOTE_ACTION]: 'A user has just added a note',
  [MANUAL_ENTRY_ACTION]: 'A user has just added a manual entry',
  [TIME_CARD_SUBMIT_ACTION]: 'A user has just submitted time cards',
  [BUDGET_ACTION]: 'A budget milestone has just been reached',
});

export const showLiveFeedToast = ({ history, event = {}, t }) => {
  const {
    action = '',
  } = event;

  const ourMsg = getToastMessages(t)[action];
  if (!ourMsg) return;

  message.info({
    content: (
      <BorderlessButton
        title={ourMsg}
        onClick={() => {
          Analytics.track('LiveFeed/Toast');
          history.push('/dashboard');
          message.destroy();
        }}
        style={{ width: 'auto' }}
      />
    ),
    duration: 5,
  });
};

const actionToFilterKeyMap = {
  [END_BREAK_ACTION]: 'break',
  [BUDGET_ACTION]: 'budget',
  [CLOCK_IN_ACTION]: 'clockIn',
  [CLOCK_OUT_ACTION]: 'clockOut',
  [COST_CODE_CREATE_ACTION]: 'costcodeCreate',
  [END_BREAK_ACTION]: 'endBreak',
  [POST_ACTION]: 'messages',
  [MANUAL_ENTRY_ACTION]: 'manualEntries',
  [PROJECT_CREATE_ACTION]: 'projectCreate',
  [SWITCH_TASK_ACTION]: 'taskSwitch',
  [TEAM_CREATE_ACTION]: 'teamCreate',
  [TIME_CARD_SUBMIT_ACTION]: 'timeCardSubmit',
  [USER_CREATE_ACTION]: 'userCreate',
  [ADD_NOTE_ACTION]: 'addNote',
};

/**
 * Filters events by project, status, event type
 * @param {object} activeFilters - status & event type filters
 * @param {array} events
 * @returns {array} filtered events
 */
export const filterEvents = ({ activeFilters, events = [] }) => {
  const {
    status: activeStatusFilterKeys = new Set(),
    events: activeEventFilterKeys = new Set(),
  } = activeFilters || {};
  return events.filter((event) => event
    // filter by status:
    && ((activeStatusFilterKeys.has('unread') && activeStatusFilterKeys.has('read'))
      || (activeStatusFilterKeys.has('unread') && event.unread)
      || (activeStatusFilterKeys.has('read') && !event.unread))
    // filter by event type:
    && activeEventFilterKeys.has(actionToFilterKeyMap[event?.type]));
};
