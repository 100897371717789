import React, { useCallback } from 'react';
import { Drawer, Input } from 'antd';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';

export default ({
  title,
  searchStr,
  onSearch,
  children,
  visible,
  onClose,
  onSubmit,
  showFooter = true,
  bodyStyle = {},
  destroyOnClose = false,
}) => {
  const onSearchChanged = useCallback((e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    onSearch(value);
  }, []);

  return (
    <Drawer
      title={title}
      visible={visible}
      width={600}
      onClose={onClose}
      destroyOnClose={destroyOnClose}
    >
      <Input.Search
        type='search'
        style={{ width:'100%' }}
        className='searchbar'
        placeholder='Search'
        allowClear
        onChange={onSearchChanged}
        value={searchStr}
      />
      <div className='table-add-scroll' style={{ bottom: showFooter ? 53 : 0, ...bodyStyle }}>
        {children}
      </div>
      {showFooter && <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />}
    </Drawer>
  )
}