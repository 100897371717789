import React from 'react';

import ClockTimeCard from '../../common/clock/ClockTimeCard';

import colors from '../../constants/Colors';

export const getCardColor = (type) => {
  switch(type) {
    case 'TAKE_BREAK':
    case 'END_BREAK':
      return colors.ONTRACCR_DARK_YELLOW;
    case 'CLOCK_IN':
    case 'SWITCH_TASK':
      return colors.ONTRACCR_BLACK;
    default:
      return colors.ONTRACCR_OPACITY_GRAY;
  }
};

export default ({
  task = {},
  type,
  onClick
}) => (
  <ClockTimeCard
    style={{ 
      width: 150, 
      padding: 10, 
      backgroundColor:getCardColor(type),
    }}
    onClick={onClick}
    task={task}
    formattedDay={{}}
    showClock={false}
  />
);
