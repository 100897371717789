import React from 'react';
import moment from 'moment-timezone';
import { Select } from 'antd';
const { Option } = Select;
export default (props) => {
  
  return (<Select
    style={{ width: 200, textAlign:'right' }}
    showSearch
    virtual={false}
    {...props}
  >
    {moment.tz.names().map((tz) =>
      (<Option value={tz} key={tz}>
        {tz}
      </Option>)
    )}
  </Select>);
}
