import axios from 'axios';
import { request } from '../../helpers/requests';

const FeedbackService = {
  upload: async (text) => request({
    call: axios.post('/feedback', { text }),
    errMsg: 'Failed to upload feedback',
  }),
};

export default FeedbackService;
