import React from 'react';
import { Popover } from 'antd';

export default ({ 
  disabled,
  content,
  title,
  children: child,
}) => {
  if (!disabled) return child;
  return (
    <Popover
      placement='right'
      trigger='click'
      title={title}
      content={content}
    >
      {child}
    </Popover>
  )
}
