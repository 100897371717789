import React, { useCallback } from 'react';
import { Row, List } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import colors from '../../constants/Colors';

const buttonStyle = {
  pointerEvents: 'auto',
  width: 30,
  padding: 0,
};

export default ({
  item: {
    id,
    name,
    description
  } = {},
  onDelete,
  onEdit,
}) => {
  const onEditClicked = useCallback(() => onEdit(id), [id, onEdit]);
  const onDeleteClicked = useCallback(() => onDelete(id), [id, onEdit]);
  return (
    <List.Item style={{ position: 'relative', width: '100%' }}>
      <Row style={{ width: '100%' }}>
        <Row className='form-field-type-title'>
          {name}
        </Row>
        <Row className='form-field-type-description'>
          {description}
        </Row>
      </Row>
      <BorderlessButton
        style={{ position: 'absolute', right: 40, top: 5, ...buttonStyle }}
        iconNode={<EditOutlined style={{ marginLeft:0 }}/>}
        onClick={onEditClicked}
      />
      <BorderlessButton
        style={{ position: 'absolute', right: 10, top: 5, ...buttonStyle }}
        iconNode={<DeleteOutlined style={{ color:colors.ONTRACCR_RED, marginLeft:0,}}/>}
        onClick={onDeleteClicked}
      />
    </List.Item>
  );
}