import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import {
  Form,
  Tabs,
  Spin,
  Row,
} from 'antd';

import colors from '../constants/Colors';

import Permissions from '../auth/Permissions';

import UserInfo from './UserInfo';
import UserLabels from './UserLabels';
import UserFiles from './UserFiles';
import Notes from '../notes/Notes';

import {
  getUserFiles,
  addNote,
  getNotes,
} from './state/users.actions';
import { getIdMap, generateRandomString } from '../helpers/helpers';
import BoardLinkView from '../boards/BoardLinkView';
import Certifications from '../certifications/Certifications';
import { hasPermissions } from './userHelpers';
import AssignedForms from '../forms/AssignedForms/AssignedForms';
import { getTemplates } from '../forms/state/forms.actions';

const { TabPane } = Tabs;

const tabStyle = {
  minHeight: '100%',
  width: '100%',
};

const CERT_TYPE = 'users';
export default function UserAddView(props) {
  const dispatch = useDispatch();

  const [
    userFiles,
    userNotes,
    labels,
    cardLinks,
  ] = useSelector((state) => [
    state.users.userFiles,
    state.users.notes,
    state.labels.filter((label) => label.type === 'users'),
    state.boards.cardLinks,
  ], shallowEqual);

  const labelIdMap = useMemo(() => getIdMap(labels), [labels]);

  const {
    company: {
      settings: {
        suggestUsernames = false,
        optionalUserEmail = false,
      } = {},
    } = {},
  } = useSelector((state) => state.settings);
  const [loading, setLoading] = useState(false);

  const {
    formRef,
    isAdd,
    userDivisions = {},
    userToLabel = {},
    user = {},
    errors,
    customFields,
    setCustomFields,
  } = props;
  const {
    name,
    username,
    email,
    phoneNumber,
    wage,
    position,
    editing = false,
    projects = [],
    teams = [],
    id,
    employeeId,
    union = {},
    scheduleColor,
  } = user;
  const {
    [id]: divisions = [],
  } = userDivisions;

  const {
    [id]: userLabels = [],
  } = userToLabel;

  const files = useMemo(() => {
    const {
      [id]: stateFiles = [],
    } = userFiles;
    return stateFiles;
  }, [userFiles, id]);

  const initialLabels = useMemo(() => (
    userLabels.map((labelId) => labelIdMap[labelId]).filter((label) => label)
  ), [userLabels, labelIdMap]);

  const ourLinks = useMemo(() => {
    if (!id || editing) return [];
    return cardLinks.filter((cardLink) => (
      cardLink.userId === id
    ));
  }, [cardLinks, id, editing]);

  const initialValues = {
    name,
    username,
    email,
    phoneNumber,
    wage,
    position,
    projects: projects.map((p) => p.name),
    teams: teams.map((t) => t.name),
    employeeId,
    divisions,
    labels: initialLabels,
    files,
    union,
    scheduleColor: scheduleColor || `${colors.ONTRACCR_GRAY}FF`,
  };

  if (isAdd && suggestUsernames) {
    initialValues.username = generateRandomString();
    initialValues.password = generateRandomString(true);
    initialValues.confirmPassword = initialValues.password;
  }

  const isNotDisplay = (isAdd || editing);
  const hasFileRead = Permissions.has('FILES_READ');
  const hasManageUserPermissions = hasPermissions(user);
  const hasFormsClearPermissions = Permissions.has('FORMS_CLEAR');

  useEffect(() => {
    const getFiles = async () => {
      setLoading(true);
      await dispatch(getUserFiles(id));
      setLoading(false);
    };
    if (dispatch && id) getFiles();
  }, [dispatch, id]);

  useEffect(() => {
    dispatch(getTemplates());
  }, []);

  if (loading) {
    return (
      <Row justify="center" style={{ marginTop: '30%', width: '100%' }}>
        {' '}
        <Spin />
        {' '}
      </Row>
    );
  }

  return (
    <Form
      layout="vertical"
      ref={formRef}
      initialValues={initialValues}
      // hack to make form reload when files are loaded into state for the user.
      key={id + files.length}
      className="user-add-form"
    >
      {
        isNotDisplay
          ? (
            <UserInfo
              form={props}
              user={user}
              optionalUserEmail={optionalUserEmail}
              errors={errors}
              customFields={customFields}
              setCustomFields={setCustomFields}
              divisions={divisions}
            />
          ) : (
            <Tabs
              key={id}
              tabBarStyle={{ marginBottom: 10 }}
            >
              <TabPane tab="General Info" key="general" style={tabStyle}>
                <UserInfo
                  form={props}
                  user={user}
                  errors={errors}
                  customFields={customFields}
                  setCustomFields={setCustomFields}
                  divisions={divisions}
                />
              </TabPane>
              <TabPane tab="Labels" key="labels" style={tabStyle}>
                <UserLabels userId={id} />
              </TabPane>
              {hasFileRead && (
              <TabPane tab="Files" key="files" style={tabStyle}>
                <UserFiles userId={id} />
              </TabPane>
              )}
              <TabPane tab="Notes" key="notes" style={tabStyle}>
                <Notes
                  id={id}
                  notes={userNotes}
                  addNote={addNote}
                  getNotes={getNotes}
                  headerStyle={{ paddingLeft: 24 }}
                  authorKey="authorId"
                />
              </TabPane>
              {ourLinks.length > 0 && (
              <TabPane tab="Cards" key="cards" style={tabStyle}>
                <BoardLinkView links={ourLinks} linkType="userId" linkId={id} />
              </TabPane>
              )}
              {hasManageUserPermissions && (
                <TabPane tab="Certifications" key="certifications" style={tabStyle}>
                  <Certifications
                    id={id}
                    entityType={CERT_TYPE}
                  />
                </TabPane>
              )}
              {hasFormsClearPermissions && (
                <TabPane tab="Assigned Forms" key="assignedForms" style={tabStyle}>
                  <AssignedForms isUserAssigned userId={id} />
                </TabPane>
              )}
            </Tabs>
          )
      }
    </Form>
  );
}
