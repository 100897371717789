import React from 'react';
import { Col, Row } from 'antd';

export default function HourlyConditions({ temp, weather = {}, units } = {}) {
  return (
    <Row flex={1} align="middle">
      <Col flex={1} justify="center">
        <Row flex={1} justify="center" align="middle">
          <Col justify="center">
            {weather?.description || '-'}
          </Col>
        </Row>
        <Row flex={1} justify="center" align="middle">
          <Col justify="center">
            <img src={weather?.image} alt={weather?.description} />
          </Col>
        </Row>
        <Row flex={1} justify="center" align="middle">
          <Col justify="center">
            {temp ? `${temp}${units}` : '-'}
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
