import React from "react";
import moment from "moment";
import { Table, TimePicker, Typography, Input } from "antd";
import "./SheetsWeb.css"

import Sidebar from './Sidebar'
import ExportXLSX from "./ExportXLSX";
import ReactToPdf from "react-to-pdf";
import { PrinterOutlined, MailOutlined } from '@ant-design/icons'
import OnTraccrButton from '../common/buttons/OnTraccrButton'

import Logo from "../common/Logo";


const { Text } = Typography;

export default function SheetsWeb({times, calculateWeeklyTotal, onTimeSelected, renderCsvTimesFn, employeeIDHandler, employeeNameHandler, emailFeature}) {

  const columns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (_, record, index, type="start") => {
        return (
          <TimePicker   
            use12Hours
            key={index}
            defaultOpenValue={moment("00:00", "h:mm")}
            format={"h:mm a"}
            onChange={(time, timeString) => {
              onTimeSelected(time, timeString, index, 'startTime');
            }}
          />
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (_, record, index) => {
        return (
          <TimePicker 
            use12Hours
            key={index}
            defaultOpenValue={moment("00:00", "h:mm")}
            format={"h:mm a"}
            onChange={(time, timeString) => {
              onTimeSelected(time, timeString, index, 'endTime');
            }}
          />
        );
      },
    },
    {
      title: "Time on Break",
      dataIndex: "timeOnBreak",
      key: "timeOnBreak",
      render: (_, record, index) => {
        return (
          <TimePicker 
            use12Hours={false}
            showNow={false}
            key={index}
            defaultOpenValue={moment("00:00", "HH:mm")}
            format={"H:mm"}
            onChange={(time, timeString) => {
              onTimeSelected(time, timeString, index, 'timeOnBreak');
            }}
          />
        );
      },
    },
    {
      title: "Time Worked",
      dataIndex: "result",
      key: "result",
    },
  ];

  const ref = React.createRef();
  const options = {
    orientation: "landscape",
  };

  return (
    <div className="sheetsw"  >

        <div className="sheetsw-leftSideWrapper">
            <div className="sheetsw-container">

                <div className="sheetsw-headingWrapper">
                    <Logo
                      height={90}
                      style={{
                        backgroundColor: 'white',
                        borderRadius: 8,
                      }}
                    />
                    <div className="heading">Sheets</div>
                </div>

                <div className="sheetsw-exportableWrapper" ref={ref}>
                

                    <div className="sheetswInputAndTotalWrapper">
                        <Input
                        type="text"
                        className="sheetswInput sheetswInputEmployeeName"
                        placeholder="Full Name"
                        onChange={employeeNameHandler}
                         />
                        <Input
                        type="text"
                        className="sheetswInput sheetswInputEmployeeID"
                        placeholder="Employee ID"
                        onChange={employeeIDHandler}
                          /> 

                        <div className="weeklyTotalSection">
                            <Text className="weeklyTotal-text">Weekly Total:</Text>
                            <Text className="weeklyTotal-number">{calculateWeeklyTotal(times)}</Text>
                        </div>
                    </div>
            

                    <div className="sheetsw-tableWrapper">
						
                        <Table className="sheetsw-table"
                            dataSource={times}
                            columns={columns}
                            pagination={false}
                       	  />
						
                    </div> 

                </div>

				<div className="sheetsw-buttonGroup">
					<a className="ant-btn OnTraccrBtn-primary ant-btn-primary" href={emailFeature()}>Email
						<MailOutlined style={{marginLeft: '10px'}}/>
					</a>

					<ExportXLSX 
						style={{marginLeft: '10px'}}
						csvData={renderCsvTimesFn(times)}
					/>
					
					<ReactToPdf
						targetRef={ref}
						filename="timesheet.pdf"
						options={options}
						x={15}
						y={15}
						scale={0.7} 
						>       

						{({ toPdf }) => (
							<OnTraccrButton style={{marginLeft: '10px'}}title='Print' type='cancel' icon={<PrinterOutlined/>}  onClick={toPdf}/>
						
							)}
					</ReactToPdf> 
				</div>

        	</div>

       		<div className="sheetsw-copyright">2023 Ontraccr Technologies Inc. All rights reserved</div>

    	</div>

		<Sidebar/>


    </div>
  );
}

