import React, { useEffect, useMemo, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Row, Spin, List } from 'antd';
import { MailOutlined } from '@ant-design/icons';

import FullPhoto from '../../common/photos/FullPhoto';

import BoardCardEmailEntry from './BoardCardEmailEntry';

import { getCardEmailThread } from '../state/boards.actions';

import { getFileType, downloadFile } from '../../files/fileHelpers';
import BorderlessButton from '../../common/buttons/BorderlessButton';

export default ({
  visible,
  onClose,
  id: threadId,
  subject,
}) => {
  const dispatch = useDispatch();
  const threads = useSelector(state => state.boards.cardEmailThreads);
  const cardEmailFiles = useSelector(state => state.boards.cardEmailFiles);

  const [loading, setLoading] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState();

  const selectedFile = useMemo(() => {
    const {
      [selectedFileId]: { jsFileObject } = {},
    } = cardEmailFiles;
    return jsFileObject;
  },[cardEmailFiles, selectedFileId]);
  const type = useMemo(() => selectedFile ? getFileType(selectedFile) : null,[selectedFile]);


  const onDownload = useCallback(() => {
    downloadFile({ fileObject: selectedFile });
  },[selectedFile]);

  const onCloseFile = useCallback(() => setSelectedFileId(),[]);

  const onFileClick = useCallback(async (file) => {
    const { attachmentId } = file;
    if (!(attachmentId in cardEmailFiles)) {
      await dispatch(getCardEmailFile(file))
    }
    setSelectedFileId(attachmentId);
  },[dispatch, cardEmailFiles]);
  
  const onOpen = useCallback(() => {
    window.open(`https://mail.google.com/mail/u/0/#all/${threadId}`, '_blank');   
  },[threadId]);

  const fullThread = useMemo(() => {
    const { [threadId]: ft  = [] } = threads;
    const sorted = [...ft];
    sorted.sort((a,b) => a.timestamp - b.timestamp);
    return sorted;
  },[threadId, threads]);

  useEffect(() => {
    const getThread = async () => {
      setLoading(true);
      await dispatch(getCardEmailThread(threadId));
      setLoading(false);
    }
    if (visible && threadId) {
      if (!(threadId in threads)) {
        getThread();
      }
    }
  },[dispatch, visible, threadId, threads]);

  useEffect(() => {
    if (!visible) {
      setSelectedFileId();
    }
  },[visible]);

  return (
    <Drawer
      title={subject}
      onClose={onClose}
      visible={visible}
      width={700}
    >
      {
        loading
          ? <Row align='center' justify='middle' style={{ height: '100%', width: '100%'}}><Spin spinning/></Row>
          : (
            <>
              <BorderlessButton
                id='board-card-open-email-button'
                iconNode={<MailOutlined />}
                onClick={onOpen}
              />
              <List
                dataSource={fullThread}
                renderItem={(item, idx) => (
                  <BoardCardEmailEntry
                    {...item}
                    onFileClick={onFileClick}
                    threadId={threadId}
                    isLast={idx === fullThread.length - 1}
                  />
                )}
              />
            </>
          )

      }
      <FullPhoto
        onDownload={onDownload}
        file={selectedFile}
        onClose={onCloseFile}
        type={type}
      />
    </Drawer>
  )
}