/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { loadFromSigned } from '../../files/fileHelpers';
import { request } from '../../helpers/requests';

class TimeCardsService {
  async getSignatureURL({ userId, signatureReferenceId }) {
    return request({
      call: axios.get(`/signatures/${userId}/${signatureReferenceId}`),
      errMsg: 'Failed to get signature',
      hideSuccessToast: true,
    });
  }

  getCustomFields() {
    return request({
      call: axios.get('/timecard/fields'),
      errMsg: 'Failed to get custom TimeCard fields',
      defaultData: {},
      hideSuccessToast: true,
    });
  }

  setCustomFields(payload) {
    return request({
      call: axios.put('/timecard/fields', payload),
      errMsg: 'Failed to update custom TimeCard fields',
    });
  }

  getTaskPhotos(taskIds) {
    return request({
      call: async () => {
        const res = await axios.get('/tasks/pictures', { params: { taskIds } });
        const { data: { taskPhotos = [] } } = res;
        const taskPhotoMap = {};
        await Promise.all(
          taskPhotos.map(async (task) => {
            const { taskId, clockInPhoto, clockOutPhoto } = task;
            const entry = {};
            if (clockInPhoto) {
              const { id, photo } = clockInPhoto;
              const image = await loadFromSigned({
                signedURL: photo,
                name: id,
                id,
              });
              entry.clockInPhoto = {
                ...clockInPhoto,
                name: id,
                url: photo,
                file: image,
              };
            }
            if (clockOutPhoto) {
              const { id, photo } = clockOutPhoto;
              const image = await loadFromSigned({
                signedURL: photo,
                name: id,
                id,
              });
              entry.clockOutPhoto = {
                ...clockOutPhoto,
                name: id,
                url: photo,
                file: image,
              };
            }
            taskPhotoMap[taskId] = entry;
          }),
        );
        return {
          ...res,
          data: taskPhotoMap,
        };
      },
      errMsg: 'Could not get task photos',
      hideSuccessToast: true,
    });
  }

  getTimecardHistory(userId) {
    return request({
      call: axios.get('/timecard/history', { params: { userId } }),
      errMsg: 'Could not get task history',
      hideSuccessToast: true,
    });
  }
}

export default new TimeCardsService();
