import {
  GET_FORM_LIBRARY,
  GET_FORM_LIBRARY_TEMPLATE,
} from '../../../state/actionTypes';

const initialState = {
  library: [],
  templates: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FORM_LIBRARY: {
      const {
        payload: newLibrary = [],
      } = action;
      return { ...state, library: newLibrary };
    }
    case GET_FORM_LIBRARY_TEMPLATE: {
      const {
        payload: {
          key,
          templateData = {},
        } = {},
      } = action;
      const { templates: oldTemplates = {} } = state;
      const newTemplates = { ...oldTemplates };
      newTemplates[key] = templateData;
      return { ...state, templates: newTemplates };
    }
    default:
      return state;
  }
};
