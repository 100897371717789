import React, { useMemo } from 'react';
import { DateTime } from 'luxon';
import { Row, Col } from 'antd';
import he from 'he';

import BoardCardEmailAttachments from './BoardCardEmailAttachments';

export default ({
  timestamp,
  from = '',
  subject,
  snippet,
  files = [],
  onFileClick,
  onClick,
}) => {
  const date = useMemo(() => {
    const dt = timestamp ? DateTime.fromMillis(timestamp) : DateTime.local();
    return dt.toFormat('MMM d t');
  },[timestamp]);

  const shortSender = useMemo(() => {
    // From includes email (e.g. Name <email@domain.com>)
    let fullFrom = from;
    if (from.length > 0 && from[0] !== '<') {
      const [name = ''] = from.split('<');
      fullFrom = name;
    }
    return he.decode(fullFrom.trim()); 
  },[from]);

  const decodedSubject = useMemo(() => he.decode(subject),[subject]);
  const decodedSnippet = useMemo(() => he.decode(snippet),[snippet]);
  
  return (
    <Row align='top' className='board-card-email-row' gutter={10} onClick={onClick}>
      <Col span={4} className='board-card-ellipsis'>
        <div className='board-card-ellipsis'>{shortSender}</div>
        <div className='board-card-email-date'>{date}</div>
      </Col>
      <Col span={20}>
        <div className='board-card-email-body'>
          <span className='board-card-email-subject'>{decodedSubject}</span> - <span className='board-card-email-snippet'>{decodedSnippet}</span>
        </div>
        <BoardCardEmailAttachments 
          files={files}
          onFileClick={onFileClick}
        />
      </Col>
    </Row>
  )
}