import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import { Row, PageHeader } from 'antd';
import { EyeOutlined, EyeInvisibleOutlined, } from '@ant-design/icons'

import AdminSignupCard from './AdminSignupCard';
import analytics from '../../helpers/Analytics';

const COMPANY_TYPE_OPTIONS = [
  { label: 'General Contractor', value: 'generalContractor' },
  { label: 'Service Contractor', value: 'serviceContractor' },
  { label: 'Sub Contractor', value: 'subContractor' },
  { label: 'Other', value: 'other' },
];

const passwordIconRender = (visible) => (
  visible ? 
    <EyeOutlined style={{ color: 'white'}}/> :
    <EyeInvisibleOutlined style={{ color: 'white'}}/>
);

const isNumeric = (str) => {
  if (typeof str != "string") return false // we only process strings!  
  return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
         !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
}

const validateCompanySize =  (rule, value) => {
  if(!value)  return Promise.resolve();
  if(!isNumeric(value)) return Promise.reject('Company Size must be a number');
  const num = Number.parseInt(value);
  if (num === 0) return Promise.reject('Company Size cannot be 0');

  return Promise.resolve();
};

const validateUsername =  (rule, value) => {
  if (!value || value.length < 5) return Promise.reject('Must be 5 or more characters');
  return Promise.resolve();
};



const FlavourText = ({ children }) => (
  <Row style={{
    width: '100%',
    fontSize: 14,
    fontFamily:'roboto-light',
    textAlign:'center' 
  }} justify='center'>
    {children}
  </Row>
);

export default ({
  onBack,
  onLogin,
  onCreate,
  style = {},
}) => {

  const [activeCard,setActiveCard] = useState(0);
  const [formData,setFormData] = useState({});
  const [formRef0,setFormRef0] = useState();
  const [formRef1,setFormRef1] = useState();
  const [formRef2,setFormRef2] = useState();

  const validatePasswordFormat = (rule, value) => {
    if(!value) return Promise.resolve();
    if (value.length < 8) return Promise.reject('Must be 8 or more characters');
  
    const regex = new RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/);
    if(!(regex.test(value))) {
      return Promise.reject(`Password must contain:
      one uppercase character,
      one lowercase character
      and one number`)
    }
    formRef2.validateFields(['confirmPassword']);
    return Promise.resolve();
  };


  const compareToFirstPassword = (rule, value) => {
    if(!formRef2) return;
    if (value && value !== formRef2.getFieldValue('password')) {
      return Promise.reject('Passwords must match');
    } 
    return Promise.resolve();
  };

  const resetFields = () => {
    setFormData({});
    if(formRef0) formRef0.resetFields();
    if(formRef1) formRef1.resetFields();
    if(formRef2) formRef2.resetFields();
  };

 
  return (
    <div style={style}>
      {
        activeCard === 2 && 
        <Helmet>
          {// <!-- Event snippet for Website lead conversion page -->
          }
          <script> 
            {`function gtag_report_conversion(url) { var callback = function () { if (typeof(url) != 'undefined') { window.location = url; } }; gtag('event', 'conversion', { 'send_to': 'AW-432395809/lDalCOvOgPUBEKGsl84B', 'event_callback': callback }); return false; }`}
          </script>
        </Helmet>
      }
      <AdminSignupCard
        setFormRef={setFormRef0}
        onBack={() => {
          resetFields();
          onBack();
        }}
        onNext={(values) => {
          setFormData({
            ...formData,
            ...values,
          });
          analytics.track('GetStarted/AdminSignup2');
          setActiveCard(1);
        }}
        number={0}
        text={
          <FlavourText>
          <p>
            Tell us a bit about yourself.<br/>
            Please fill in the fields below.
          </p>
          </FlavourText>
        }
        style={{
          position:'absolute',
          top: `${-100 * activeCard}vh`,
          left:0,
          right:0,
          height:'100vh',
          transition:'top 0.5s',
        }}
        fields={[
          {
            inputProps:{
              placeholder:'First Name',
            },
            formProps:{
              name:'firstName',
              rules:[{
                required:true, message: 'First Name is required'
              }]
            },
          },
          {
            inputProps:{
              placeholder:'Last Name',
            },
            formProps:{
              name:'lastName',
              rules:[{
                required:true, message: 'Last Name is required'
              }]
            },
          },{
            inputProps:{
              placeholder:'Email',
            },
            formProps:{
              name:'email',
              rules:[
                { required:true, message: 'Email is required' },
                { type:'email', message: 'Invalid email format'},
              ]
            },
          }
        ]}
      />
      <AdminSignupCard
        setFormRef={setFormRef1}
        onBack={() => {
          analytics.track('GetStarted/AdminSignup1');
          setActiveCard(0)
        }}
        number={1}
        onNext={(values) => {
          setFormData({
            ...formData,
            ...values,
          });
          analytics.track('GetStarted/AdminSignup3');
          setActiveCard(2)
        }}
        text={
          <FlavourText>
          <p>
            Help us learn more about your business<br/>
            so we can help you cut costs.
          </p>
          </FlavourText>
        }
        style={{
          position:'absolute',
          top: `${100 - (100 * activeCard)}vh`,
          left:0,
          right:0,
          height:'100vh',
          transition:'top 0.5s',
        }}
        fields={[
          {
            inputProps:{
              placeholder:'Company Name',
            },
            formProps:{
              name:'companyName',
              rules:[{
                required:true, message: 'Company Name is required'
              }]
            },
          },
          {
            inputProps:{
              placeholder:'Company Size',
              maxLength:10,
            },
            formProps:{
              name:'companySize',
              rules:[
                { validator: validateCompanySize }
              ]
            },
          },{
            inputProps:{
              placeholder:'Industry',
            },
            formProps:{
              name:'industry',
            },
          },{
            type:'select',
            inputProps:{
              placeholder:'Type',
              options:COMPANY_TYPE_OPTIONS,
            },
            formProps:{
              name:'companyTypes',
              rules:[{
                required:true, message:'Company type is required',
              }],
            },
          },
        ]}
      />
      <AdminSignupCard
        setFormRef={setFormRef2}
        onBack={() => {
          analytics.track('GetStarted/AdminSignup2');
          setActiveCard(1)
        }}
        onCreate={(values) => {
          const fullData = {
            ...formData,
            ...values,
          };
          setFormData(fullData);
          return onCreate(fullData);
        }}
        number={2}
        text={
          <FlavourText>
            <p>
              Time to pick your username<br/>
              and get started!
            </p>
          </FlavourText>
        }
        style={{
          position:'absolute',
          top: `${200 - (100 * activeCard)}vh`,
          left:0,
          right:0,
          height:'100vh',
          transition:'top 0.5s',
        }}
        fields={[
          {
            inputProps:{
              placeholder:'Username',
            },
            formProps:{
              name:'username',
              rules:[
                { required:true, message: 'Username is required' },
                { validator: validateUsername }
              ]
            },
          },
          {
            inputProps:{
              placeholder:'Password',
              password:true,
              iconRender:passwordIconRender,
              inputPrefixCls:'register-password ant-input' 
              // Weird hack to force antd to inject our class to the password input.
              // If we don't do this the text will be black
            },
            formProps:{
              name:'password',
              rules:[
                { required: true, message: 'Password is required'},
                { validator: validatePasswordFormat }
              ]
            },
          },{
            inputProps:{
              placeholder:'Confirm Password',
              password:true,
              iconRender:passwordIconRender,
              inputPrefixCls:'register-password ant-input'
            },
            formProps:{
              name:'confirmPassword',
              rules:[
                { required: true, message: 'Confirm Password is required'},
                { validator: compareToFirstPassword }
              ]
            },
          }
        ]}
      />
      {activeCard > 0 && <PageHeader
        style={{
          top:100,
          width:400,
        }}
        className='register-back'
        onBack={() => {
          onLogin();
          resetFields();
          setTimeout(() => setActiveCard(0),500);
        }}
        title='Back to Login'
      />}
    </div>
  );
}