import React, { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { notification } from 'antd';

import ProjectScheduleOfValuesSubContract from '../ProjectScheduleOfValues/ProjectScheduleOfValuesSubContract';
import { getFileType } from '../../files/fileHelpers';
import { deleteProgressSubContractUpdate, swapRowsProgressSubContract, updateProgressSubContract } from '../state/projects.actions';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import { isNullOrUndefined } from '../../helpers/helpers';

export default function ProgressSubContractInfoSlider({
  visible,
  onClose,
  record,
  hasWrite,
  subContractCOs,
  setSubContractCOs,
  projectId,
  newItemNumbers,
  setNewItemNumbers,
  liveSummaryValues,
}) {
  const dispatch = useDispatch();

  const [files, setFiles] = useState([]);
  const [selectedFile, setSelectedFile] = useState({});

  const clearSelectedFile = useCallback(() => setSelectedFile({}), []);

  const addFile = (file) => {
    setFiles([...files, file]);
  };

  const removeFile = (index) => {
    const newFiles = [...files];
    newFiles.splice(index, 1);
    setFiles(newFiles);
  };

  const onPreviewClick = useCallback((index) => {
    const file = files[index];

    if (file) {
      if (file instanceof File) {
        setSelectedFile({
          file,
          type: getFileType(file),
        });
      } else {
        setSelectedFile({
          ...file,
          type: getFileType(file),
        });
      }
    }
  }, [files]);

  const updateFile = useCallback((oldFile, updatedFile) => {
    if (!updateFile) return clearSelectedFile();
    const newFiles = files.map((file) => {
      if (file === oldFile) return updatedFile;
      return file;
    });
    setFiles(newFiles);
    clearSelectedFile();
    return null;
  }, [files, clearSelectedFile]);

  const onSetData = useCallback((type, newData) => {
    const updatedData = newData.map((item, index) => ({
      ...item,
      oldItemNumber: isNullOrUndefined(item.oldItemNumber)
        ? item.itemNumber
        : item.oldItemNumber,
      itemNumber: index + 1,
      updatedField: 'itemNumber',
    }));

    dispatch(swapRowsProgressSubContract({
      projectId,
      updatedRows: updatedData,
    }));

    if (type === 'subContractChangeOrders') {
      const newSubContractCOs = { ...subContractCOs };
      const [{ formId } = {}] = updatedData;
      newSubContractCOs[formId] = {
        ...newSubContractCOs[formId] ?? {},
        changes: updatedData,
      };
      setSubContractCOs(newSubContractCOs);
    }
  }, [projectId, subContractCOs]);

  const onAddChangeOrder = useCallback(async (payload) => {
    const updatePayload = {
      ...payload,
      formId: record.id,
    };

    const result = await dispatch(updateProgressSubContract({
      projectId,
      payload: updatePayload,
    }));

    if (result) {
      notification.close('addRowKey');
      const newVals = { ...newItemNumbers };
      newVals.subContractCOs += 1;
      setNewItemNumbers(newVals);
    }
    return result;
  }, [record, projectId, newItemNumbers]);

  const onDeleteClick = useCallback((selectedRecord) => {
    const onDelete = () => {
      dispatch(deleteProgressSubContractUpdate({
        projectId,
        rowId: selectedRecord.rowId,
      }));
    };

    return new Promise((resolve) => {
      CustomConfirmModal({
        title: 'Delete Confirmation',
        content: (
          <p>
            Are you sure you wish to delete this row?
          </p>
        ),
        okText: 'Delete',
        cancelText: 'Cancel',
        async onOk() {
          resolve(await onDelete());
        },
        onCancel() {
          resolve();
        },
      });
    });
  }, [projectId]);

  return (
    <ProjectScheduleOfValuesSubContract
      visible={visible}
      onClose={onClose}
      record={record}
      isReadOnly={!hasWrite}
      subContractCOs={subContractCOs}
      liveSummaryValues={liveSummaryValues}
      isScheduleOfValueLocked={!hasWrite}
      onDeleteClick={onDeleteClick}
      onAddChangeOrder={onAddChangeOrder}
      newItemNumbers={newItemNumbers}
      files={files}
      onPreviewClick={onPreviewClick}
      removeFile={removeFile}
      addFile={addFile}
      setFiles={setFiles}
      updateFile={updateFile}
      selectedFile={selectedFile}
      clearSelectedFile={clearSelectedFile}
      onSetData={onSetData}
      isProgress
    />
  );
}

/* eslint-disable react/forbid-prop-types */
ProgressSubContractInfoSlider.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  record: PropTypes.object,
  hasWrite: PropTypes.bool.isRequired,
  subContractCOs: PropTypes.object,
  setSubContractCOs: PropTypes.func.isRequired,
  projectId: PropTypes.string.isRequired,
  newItemNumbers: PropTypes.object,
  setNewItemNumbers: PropTypes.func.isRequired,
  liveSummaryValues: PropTypes.array,
};

ProgressSubContractInfoSlider.defaultProps = {
  visible: false,
  record: {},
  subContractCOs: {},
  newItemNumbers: {},
  liveSummaryValues: [],
};
