import React from 'react';

import Permissions from '../auth/Permissions';

import BillingRateRow from '../billingRates/BillingRateRow';

import FilteredFormList from '../forms/FilteredFormList';

export default ({
  id,
  isNotDisplay,
  isVendor,
  materialDiscount,
  customBillingRate,
  visible
}) => {
  const customerWritePerm = Permissions.hasWrite('customers');
  return (
    <>
      {!isVendor && <BillingRateRow
        isNotDisplay={isNotDisplay}
        materialDiscount={materialDiscount}
        customBillingRate={customBillingRate}
      />}
      <FilteredFormList
        formType='Invoice'
        filterId={id}
        filterType={isVendor ? 'Vendors' : 'Customers'}
        isNotDisplay={isNotDisplay}
        topOffset={isVendor ? 240 : (customerWritePerm ? 309 : 256)}
        visible={visible}
      />
    </>
  );
}

