import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Select,
  Checkbox,
  Table,
  Row,
  Col
} from 'antd';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import HoverHelp from '../../common/HoverHelp';

import { getBoardDetails } from '../../boards/state/boards.actions';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

const DRAWER_WIDTH = 800;
export default ({
  visible,
  onClose,
  onSubmit,
  selectedBoardSetting,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const boards = useSelector(state => state.boards.boards);
  const cardTemplates = useSelector(state => state.boards.cardTemplates);
  const {
    boards: boardSettings = []
  } = useSelector(state => state.clientPortal.settings);
  const selectedBoard = useSelector(state => state.boards.selectedBoard);

  const { statuses = [] } = selectedBoard ?? {};
  
  const [boardId,setBoardId] = useState();
  const [showProgress, setShowProgress] = useState(selectedBoardSetting
      ? selectedBoardSetting.showProgress
      : false);
  const [statusMappings, setStatusMappings] = useState(
    selectedBoardSetting && selectedBoardSetting.statusMappings
      ? selectedBoardSetting.statusMappings
      : {}
    );

  const onValuesChange = useCallback((_, allValues) => {
    const { boardId: formBoardId, showProgress: formShowProgress } = allValues;
    setBoardId(formBoardId);
    setShowProgress(formShowProgress);
    const values = { ...allValues };
    if (formBoardId !== boardId) {
      values.fields = [];
    }
    form.setFieldsValue(values);
  },[form, boardId]);

  const onSubmitClicked = useCallback(async () => {
    try {
      const values = await form.validateFields();
      onSubmit({
        ...values,
        statusMappings,
      });
    } catch (err) {
      //
    }
  },[form, onSubmit, statusMappings]);

  const existingSet = useMemo(() => (
    new Set(boardSettings.map((bs) => bs.boardId))
  ),[boardSettings])

  const boardOptions = useMemo(() => (
    Object.values(boards)
      .map((board) => ({ label: board.title, value: board.id, disabled: existingSet.has(board.id) }))
  ),[boards, existingSet]);

  const ourCardTemplate = useMemo(() => {
    const {
      [boardId]: {
        cardTypeId,
      } = {}
    } = boards;
    const {
      [cardTypeId]: ourTemplate = {},
    } = cardTemplates;
    return ourTemplate;
  },[cardTemplates, boards, boardId]);

  const relevantFields = useMemo(() => {
    const { fields: sections = [] } = ourCardTemplate;
    return sections.map((section) => {
      const { fields = [], name: sectionName } = section;
      return fields.map((field) => {
        const { id: fieldId, configProps: { title } = {} } = field;
        return { label: `${sectionName} - ${title}`, value: fieldId };
      })
    }).flat();
  },[ourCardTemplate]);

  const onMappingChanged = useCallback((key, id) => (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    const {
      [id]: oldMapping = {},
    } = statusMappings;
    setStatusMappings({
      ...statusMappings,
      [id]: {
        ...oldMapping,
        [key]: value,
      },
    });
  },[statusMappings]);

  const columns = useMemo(() => [
    {
      title: <span style={{ paddingLeft: 10 }}>Internal Title</span>,
      dataIndex: 'title',
      render:(val) => <span style={{ paddingLeft: 10 }}>{val}</span>
    },
    {
      title: 'Client Facing Title',
      dataIndex: 'portalTitle',
      render: (_, record) => {
        const { id: statusId } = record;
        const {
          [statusId]: {
            portalTitle = record.title,
          } = {},
        } = statusMappings;
        return (
          <OnTraccrTextInput
            value={portalTitle}
            onChange={onMappingChanged('portalTitle', statusId)}
          />
        )
      }
    },
    {
      title: 'Description',
      dataIndex: 'description',
      render: (_, record) => {
        const { id: statusId } = record;
        const {
          [statusId]: {
            description,
          } = {},
        } = statusMappings;
        return (
          <OnTraccrTextInput
            textarea
            value={description}
            onChange={onMappingChanged('description', statusId)}
          />
        )
      }
    }
  ],[statusMappings, onMappingChanged]);

  useEffect(() => {
    if (!visible) {
      setBoardId();
      setStatusMappings({});
      form.resetFields();
    }
  },[visible, form]);

  useEffect(() => {
    if (!selectedBoardSetting) {
      form.resetFields();
      setShowProgress(false);
    } else {
      setBoardId(selectedBoardSetting.boardId);
      setShowProgress(selectedBoardSetting.showProgress);
      setStatusMappings(selectedBoardSetting.statusMappings ?? {});
      form.setFieldsValue(selectedBoardSetting);
    }
  },[form, selectedBoardSetting]);


  useEffect(() => {
    if (boardId) {
      dispatch(getBoardDetails(boardId));
    }
  },[boardId]);

  const sortedStatuses = useMemo(() => {
    const sorted = [...statuses];
    sorted.sort((a,b) => a.orderIndex - b.orderIndex);
    return sorted;
  },[statuses])

  return (
    <Drawer
      title='Configure board for client portal'
      visible={visible}
      onClose={onClose}
      width={DRAWER_WIDTH}
    >
      <Form form={form} layout='vertical' onValuesChange={onValuesChange} initialValues={selectedBoardSetting}>
        <Form.Item
          label='Board'
          name='boardId'
          rules={[{ required: true, message: 'Please select a board'}]}
        >
          <Select
            options={boardOptions}
            optionFilterProp='label'
          />
        </Form.Item>
        {
          boardId &&
          <Form.Item
            label='Fields to display in client portal'
            name='fields'
          >
            <Select
              options={relevantFields}
              optionFilterProp='label'
              mode='multiple'
            />
          </Form.Item>
        }
        {
          boardId &&
          <Form.Item
            label='Show Progress'
            name='showProgress'
            valuePropName='checked'
          >
            <Checkbox />
          </Form.Item>
        }
        {
          boardId && showProgress &&
          <>
            <Row gutter={20}>
              <Col style={{ color: 'rgba(0,0,0,0.85)'}}>Progress Tracker</Col>
              <Col>
                <HoverHelp content='Use this table to configure how board statuses are displayed to your clients'/>
              </Col>
            </Row>
            <Table
              style={{ margin: '10px -24px', maxWidth: DRAWER_WIDTH }}
              columns={columns}
              dataSource={sortedStatuses}
              pagination={false}
              size='small'
              scroll={{ y: 'calc(100vh - 460px)' }}
            />
          </>
        }
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
      />
    </Drawer>
  );
}