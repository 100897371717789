import React from 'react';
import { Form } from 'antd';

import OnTraccrNumberInput from './OnTraccrNumberInput';
import DisplayText from '../text/DisplayText';

const formStyle = { marginBottom: 0, paddingBottom: -8 };
const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};

export default function FormNumberInput({
  isNotDisplay,
  label,
  name,
  value,
  rules = [],
  numberInputProps = {},
  displayStyle = {},
  ...restProps
}) {
  return (
    <Form.Item
      name={name}
      label={label}
      style={formStyle}
      labelCol={formLabelStyle}
      rules={rules}
    >
      {isNotDisplay
        ? (
          <OnTraccrNumberInput
            style={{ width: '100%' }}
            {...numberInputProps}
            {...restProps}
          />
        )
        : <DisplayText title={value} style={displayStyle} />}
    </Form.Item>
  );
}
