import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Row, Col, Progress } from 'antd';
import PropTypes from 'prop-types';

// Import Components:
import ProgressUpdateInput from './ProgressUpdateInput';

// Import Helpers:
import { getProgressFromState, computeProgress } from './progressHelpers';
import { isNullOrUndefined } from '../../helpers/helpers';
import { getPhaseCostcodeKey } from '../../forms/formHelpers';

// Import Actions:
import { updateProgress } from '../../reports/state/reports.actions';

// Import Constants:
import colors from '../../constants/Colors';
import {
  PROGRESS_ROW_TYPE_SPEND,
  PROGRESS_ROW_TYPE_HOURS,
  PROGRESS_ROW_TYPE_LABOR_COST,
} from './progressConstants';

const clamp = (x) => Math.max(Math.min(x, 100), 0);

export default function ProgressRow({
  id,
  type, // {string} - the type of progress bar (listed above)
  isStacked, // {boolean} - specifies whether this progress row is stacked on another
  isPhased,
  projectId,
  phaseId,
  costcodeId,
  displayMode,
  estimatedCost,
  hours: estimatedHours,
  children,
  estimatedLaborCost,
  actualLaborCost,
  category,
  hasWrite,
}) {
  const dispatch = useDispatch();
  const progressState = useSelector((state) => state.reports.progress);
  const {
    projectCostcodeDetails: projectCostcodeDetailsMap,
    projectProgressFilters,
  } = useSelector((state) => state.projects);

  const { latestProgressUpdate, actualSpend: userInputActualSpend } = useMemo(() => (
    getProgressFromState({
      progressState,
      taskState: projectCostcodeDetailsMap,
      id,
      children: children ?? [],
      projectId,
      phaseId,
      costcodeId,
    })
  ), [
    progressState,
    projectCostcodeDetailsMap,
    id,
    children,
    projectId,
    phaseId,
    costcodeId,
  ]);

  const phaseCostcodeKey = useMemo(() => (
    getPhaseCostcodeKey(phaseId, costcodeId)
  ), [phaseId, costcodeId]);

  const estimatedCostToUse = useMemo(() => {
    if (category === 'Labor' && isNullOrUndefined(estimatedCost)) {
      return estimatedLaborCost;
    }

    return estimatedCost;
  }, [category, estimatedCost, estimatedLaborCost]);

  const ourProgress = computeProgress({
    type,
    displayMode,
    estimatedCost: estimatedCostToUse,
    estimatedHours,
    actualSpend: userInputActualSpend,
    actualHours: projectCostcodeDetailsMap[phaseCostcodeKey]?.costcodeTaskSummary?.totalHours ?? 0,
    latestProgressUpdate,
  });

  const [progress, setProgress] = useState(ourProgress);
  const [pastProg, setPastProg] = useState(ourProgress);

  const [showPopover, setShowPopover] = useState(false);
  const onChange = useCallback((newValue) => {
    setShowPopover(true);
    setProgress(clamp(newValue));
  }, []);

  const onConfirm = useCallback(async () => {
    const result = await dispatch(updateProgress({
      projectId,
      phaseId,
      costcodeId,
      progress,
    }));

    if (result) {
      setPastProg(progress);
      setShowPopover(false);
    }
  }, [progress, dispatch, projectId, phaseId, costcodeId]);

  const onCancel = useCallback(() => {
    setProgress(pastProg);
    setShowPopover(false);
  }, [pastProg]);

  useEffect(() => {
    const newProgress = computeProgress({
      type,
      displayMode,
      estimatedCost: estimatedCostToUse,
      estimatedHours,
      actualSpend: userInputActualSpend,
      actualHours: (
        projectCostcodeDetailsMap[phaseCostcodeKey]?.costcodeTaskSummary?.totalHours ?? 0
      ),
      latestProgressUpdate,
      actualLaborCost,
    });
    setProgress(newProgress);
    setPastProg(newProgress);
  }, [
    latestProgressUpdate,
    displayMode,
    userInputActualSpend,
    estimatedHours,
    projectCostcodeDetailsMap,
    phaseCostcodeKey,
    actualLaborCost,
    estimatedCostToUse,
  ]);

  const noInput = (
    displayMode
    || type === PROGRESS_ROW_TYPE_HOURS
    || type === PROGRESS_ROW_TYPE_SPEND
    || type === PROGRESS_ROW_TYPE_LABOR_COST
  );

  return (
    <Row style={{ width: '100%' }} align="middle" gutter={10}>
      <Col flex="auto">
        <Progress
          percent={progress}
          showInfo={false}
          trailColor={colors.BREADCRUMB_BACKGROUND}
          strokeColor={colors.ONTRACCR_RED}
        />
      </Col>
      <Col
        flex={noInput || isStacked || isPhased ? '40px' : '80px'}
        style={{
          width: noInput || isStacked || isPhased ? 40 : 80,
          height: 30,
          alignContent: 'center',
          whiteSpace: 'nowrap',
        }}
      >
        {
          hasWrite
            ? (
              <ProgressUpdateInput
                min={projectProgressFilters?.dateRange ? Number.MIN_SAFE_INTEGER : 0}
                title="Update Progress?"
                onConfirm={onConfirm}
                onCancel={onCancel}
                onChange={onChange}
                value={progress}
                showPopover={showPopover}
                hasChildren={noInput}
                formatter={(val) => `${val} %`}
                max={100}
                disabled={!!projectProgressFilters?.dateRange}
              />
            )
            : `${progress} %`
        }
      </Col>
    </Row>
  );
}

ProgressRow.propTypes = {
  id: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isStacked: PropTypes.bool,
  isPhased: PropTypes.bool,
  projectId: PropTypes.string.isRequired,
  phaseId: PropTypes.string,
  costcodeId: PropTypes.string,
  displayMode: PropTypes.bool,
  estimatedCost: PropTypes.number,
  hours: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.string),
  estimatedLaborCost: PropTypes.number,
  actualLaborCost: PropTypes.number,
  category: PropTypes.string,
  hasWrite: PropTypes.bool,
};

ProgressRow.defaultProps = {
  isStacked: false,
  isPhased: false,
  phaseId: undefined,
  costcodeId: undefined,
  displayMode: false,
  estimatedCost: 0,
  hours: 0,
  children: [],
  estimatedLaborCost: 0,
  actualLaborCost: 0,
  category: undefined,
  hasWrite: false,
};
