import React, {
  useState,
  useMemo,
  useCallback,
  useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select, Form } from 'antd';
import { FormOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import FormResponder from '../../forms/FormResponder';
import BorderlessButton from '../buttons/BorderlessButton';
import DisplayText from '../text/DisplayText';

import {
  getTemplateDetails,
} from '../../forms/state/forms.actions';
import { constructFormPayloadForAPI } from '../../forms/ResponderHelpers';
import { getIdMap } from '../../helpers/helpers';

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 5,
  },
};
export default function SelectFormInput({
  isDisplay,
  initialForm,
  initialFormData,
  onFormDataChanged,
  isRequired = false,
}) {
  const dispatch = useDispatch();

  const formTemplates = useSelector((state) => state.forms.templates);
  const projects = useSelector((state) => state.projects.projects);

  const [showResponder, setShowResponder] = useState(false);
  const [formTemplate, setFormTemplate] = useState(initialForm);
  const [formData, setFormData] = useState(initialFormData);

  const displayValue = useMemo(() => {
    const {
      [initialForm]: { name = '' } = {},
    } = formTemplates;
    return name;
  }, [formTemplates, initialForm]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const formArray = useMemo(() => (
    Object.values(formTemplates)
      .filter((ft) => ft.notDeleted)
      .map((ft) => {
        const {
          projectId: templateProjectId,
        } = ft;
        let secondRow = null;
        if (templateProjectId && templateProjectId in projectIdMap) {
          const { [templateProjectId]: { name: projectName } = {} } = projectIdMap;
          secondRow = <div style={{ fontSize: 10, fontStyle: 'italic', lineHeight: '12px' }}>{projectName}</div>;
        }
        return (
          <Select.Option key={ft.id} value={ft.id} label={ft.name}>
            {ft.name}
            {secondRow}
          </Select.Option>
        );
      })
  ), [formTemplates, projectIdMap]);

  const onFormButtonClick = useCallback(async () => {
    if (!formTemplate) return;
    if (await dispatch(getTemplateDetails(formTemplate))) {
      setShowResponder(true);
    }
  }, [formTemplate]);
  const closeResponder = useCallback(() => setShowResponder(false), []);

  const onFormDataSave = useCallback(async (newFormData) => {
    if (onFormDataChanged) onFormDataChanged(newFormData);
    const parsedData = await constructFormPayloadForAPI({
      form: newFormData,
      shouldUploadFiles: false,
      addSectionId: true,
    });
    const {
      files = [],
    } = parsedData;
    const fileMap = {};
    files.forEach((jsFileObject) => {
      const id = jsFileObject.id || jsFileObject.uid;
      fileMap[id] = {
        jsFileObject: {
          ...jsFileObject,
          id,
        },
        trueType: jsFileObject.type,
      };
    });
    setFormData({
      ...parsedData,
      fileMap,
    });
    setShowResponder(false);
    return true;
  }, []);

  useEffect(() => {
    setFormTemplate(initialForm);
  }, [initialForm]);

  useEffect(() => {
    setFormData(initialFormData);
  }, [initialFormData]);

  return (
    <>
      <Form.Item
        name="formTemplateId"
        label="Form"
        className="schedule-form-item"
        labelCol={formLabelStyle}
        rules={[{ required: isRequired, message: 'Please select a form' }]}
        initialForm={initialForm}
      >
        {
          isDisplay
            ? <DisplayText title={displayValue} />
            : (
              <Select
                showSearch
                optionFilterProp="label"
                onChange={setFormTemplate}
                allowClear={!isRequired}
              >
                {formArray}
              </Select>
            )
        }
      </Form.Item>
      {!isDisplay && formTemplate && (
        <BorderlessButton
          title="Enter Form Details"
          iconNode={<FormOutlined />}
          onClick={onFormButtonClick}
          style={{ width: 200, textAlign: 'left', paddingLeft: 5 }}
        />
      )}
      <FormResponder
        visible={showResponder}
        onClose={closeResponder}
        controlledMode
        controlledData={formData}
        onSubmit={onFormDataSave}
        draftDisabled
      />
    </>
  );
}

SelectFormInput.propTypes = {
  isDisplay: PropTypes.bool,
  initialForm: PropTypes.string,
  initialFormData: PropTypes.shape({}),
  onFormDataChanged: PropTypes.func,
  isRequired: PropTypes.bool,
};

SelectFormInput.defaultProps = {
  isDisplay: false,
  initialForm: null,
  initialFormData: {},
  onFormDataChanged: null,
  isRequired: false,
};
