import axios from 'axios';
import { message } from 'antd';
import qs from 'query-string';
import { request } from '../../helpers/requests';

// Need to create a new axios instance,
// because our default attaches a Bearer Token to all requests
const fileHandler = axios.create();

const SettingsService = {
  async uploadCompanyImage(file = {}) {
    try {
      const { type } = file;
      const { data: signedURL, err } = await axios.post('/company/image/upload', { type });
      if (!signedURL || err) return { err };
      await fileHandler.put(signedURL, file, {
        headers: {
          'Content-Type': type,
          'Content-Encoding': 'base64',
        },
      });
      message.success('Image Successfully Uploaded');
      return {};
    } catch (err) {
      message.error('Could not upload company image');
      return { err };
    }
  },
  async getCompanyImageURL(companyId, getUrl = false) {
    let silenceError = false;
    try {
      const { data } = await axios.get(`/company/image/${companyId}`);
      if (getUrl) {
        return data;
      }
      silenceError = true;
      const { data: bytes, headers = {} } = await fileHandler.get(data, {
        responseType: 'arraybuffer',
      });
      const { 'content-type': contentType } = headers;
      let suffix = '';
      if (contentType === 'image/png') {
        suffix = '.png';
      } else if (contentType === 'image/jpeg' || contentType === 'image/jpg') {
        suffix = '.jpeg';
      }
      return new File([bytes], `companyImage${suffix}`, { type: contentType });
    } catch (err) {
      if (!silenceError) message.error('Could not retrieve company profile image');
      return '';
    }
  },
  async getCompanySettings() {
    try {
      const { data } = await axios.get('/company/settings');
      return data;
    } catch (err) {
      message.error('Could not retrieve company settings');
      return {};
    }
  },
  async saveCompanySettings(payload) {
    try {
      await axios.post('/company/settings', payload);
      return 'Update Successful';
    } catch (err) {
      message.error('Could not update company settings');
      return {};
    }
  },
  clockOutAll: () => request({
    call: axios.put('/tasks/end'),
    defaultData: {},
    errMsg: 'Failed to clock out shifts',
    hideSuccessToast: false,
    successMsg: 'Clocked out all shifts',
  }),
  endBreakAll: () => request({
    call: axios.put('/break/end'),
    defaultData: {},
    errMsg: 'Failed to end all active breaks',
    hideSuccessToast: false,
    successMsg: 'Ended all active breaks',
  }),
  getCompany: () => request({
    call: axios.get('/company'),
    defaultData: {},
    errMsg: 'Failed to get company information',
    hideSuccessToast: true,
  }),

  updateCompanyOwner: (userId) => request({
    call: axios.put('/company/owner', { userId }),
    defaultData: {},
    errMsg: 'Failed to update company owner',
  }),

  getPositions: () => request({
    call: axios.get('/company/positions'),
    defaultData: {},
    errMsg: 'Failed to get company positions',
    hideSuccessToast: true,
  }),

  getACPositions: () => request({
    call: axios.get('/company/acPositions'),
    defaultData: {},
    errMsg: 'Failed to get company ac positions',
    hideSuccessToast: true,
  }),

  resetPermissions: (position) => request({
    call: axios.put(`company/permissions/${position}/reset`),
    defaultData: {},
    errMsg: 'Failed to get reset permissions',
  }),

  togglePermission: ({ position, permission, enabled }) => request({
    call: axios.put(`company/permissions/${position}`, { permission, enabled }),
    defaultData: {},
    errMsg: 'Failed to set permission',
    hideSuccessToast: true,
  }),

  toggleACPermission: ({ position, acPermission, enabled }) => request({
    call: axios.put(`company/acPermissions/${position}`, { acPermission, enabled }),
    defaultData: {},
    errMsg: 'Failed to set action center permission',
    hideSuccessToast: true,
  }),

  reorderAC: ({
    position, acPermName, sourceIndex, destinationIndex,
  }) => request({
    call: axios.put(`company/acPermissions/reorder/${position}`, { acPermName, sourceIndex, destinationIndex }),
    defaultData: {},
    errMsg: 'Failed to reorder action center permission',
    hideSuccessToast: true,
  }),

  createPosition: (name) => request({
    call: axios.post('company/positions', { name }),
    defaultData: {},
    errMsg: `Failed to create position '${name}'`,
  }),

  renamePosition: ({ oldName, newName }) => request({
    call: axios.put(`/company/positions/${oldName}/rename`, { newName }),
    defaultData: {},
    errMsg: `Failed to rename position '${oldName}'`,
  }),

  deletePosition: ({ position, newRole }) => request({
    call: axios.delete(`/company/positions/${position}`, { data: { newRole } }),
    defaultData: {},
    errMsg: `Failed to delete position '${position}'`,
  }),

  viewBilling: () => request({
    call: axios.post('/billing/portal'),
    errMsg: 'Failed to open billing portal',
    hideSuccessToast: true,
  }),

  checkIntegrationConnection: (type, id) => request({
    call: axios.get(`/${type}/${id ? `${id}/` : ''}connection/status`),
    errMsg: 'Failed to get integration connection status',
    hideSuccessToast: true,
  }),

  deactivateIntegration: (type) => request({
    call: axios.delete(`/${type}/connection`),
    errMsg: `Failed to deactivate ${type} integration`,
  }),

  connectQuickbooks: (payload) => request({
    call: axios.post('/quickbooks/connect/complete', payload),
    errMsg: 'Failed to connect QuickBooks',
    hideSuccessToast: true,
  }),

  getQuickbooksIntegrations: () => request({
    call: axios.get('/quickbooks/integrations'),
    errMsg: 'Failed to get QuickBooks Integrations',
    hideSuccessToast: true,
  }),

  getQuickbooksEmployees: (id) => request({
    call: axios.get(`/quickbooks/${id}/employees`),
    errMsg: 'Failed to get QuickBooks Employees',
    hideSuccessToast: true,
  }),

  getQuickbooksProjects: (id) => request({
    call: axios.get(`/quickbooks/${id}/projects`),
    errMsg: 'Failed to get QuickBooks Projects',
    hideSuccessToast: true,
  }),

  getQuickbooksCostcodes: (id) => request({
    call: axios.get(`/quickbooks/${id}/costcodes`),
    errMsg: 'Failed to get QuickBooks Costcodes',
    hideSuccessToast: true,
  }),

  getQuickbooksMaterials: (id) => request({
    call: axios.get(`/quickbooks/${id}/materials`),
    errMsg: 'Failed to get QuickBooks Materials',
    hideSuccessToast: true,
  }),

  getQuickbooksCustomers: (id) => request({
    call: axios.get(`/quickbooks/${id}/customers`),
    errMsg: 'Failed to get Quickbooks Customers',
    hideSuccessToast: true,
  }),

  getQuickbooksVendors: (id) => request({
    call: axios.get(`/quickbooks/${id}/vendors`),
    errMsg: 'Failed to get Quickbooks Vendors',
    hideSuccessToast: true,
  }),

  getQuickbooksClasses: () => request({
    call: axios.get('/quickbooks/classes'),
    errMsg: 'Failed to get QuickBooks Classes',
    hideSuccessToast: true,
  }),

  importQuickbooksData: (payload) => request({
    call: axios.post('/quickbooks/import', payload),
    errMsg: 'Failed to import QuickBooks Data',
  }),

  disconnectQuickBooks: (id) => request({
    call: axios.get(`/quickbooks/${id}/disconnect`),
    errMsg: 'Failed to disconnect QuickBooks',
    hideSuccessToast: true,
  }),

  updateQuickBooksIntegration: (id, payload) => request({
    call: axios.put(`/quickbooks/${id}`, payload),
    errMsg: 'Failed to update QuickBook integration',
    hideSuccessToast: true,
  }),

  connectDocusign: (payload) => request({
    call: axios.post('/docusign/connect/complete', payload),
    errMsg: 'Failed to connect Docusign',
    hideSuccessToast: true,
  }),

  disconnectDocusign: () => request({
    call: axios.put('/docusign/disconnect'),
    errMsg: 'Failed to disconnect Docusign',
    hideSuccessToast: true,
  }),

  connectProcore: (payload) => request({
    call: axios.post('/procore/connect/complete', payload),
    errMsg: 'Failed to connect Procore',
    hideSuccessToast: true,
  }),

  disconnectProcore: () => request({
    call: axios.put('/procore/disconnect'),
    errMsg: 'Failed to disconnect Procore',
    hideSuccessToast: true,
  }),

  getProcoreCompanies: () => request({
    call: axios.get('/procore/companies'),
    errMsg: 'Failed to import companies from Procore',
    hideSuccessToast: true,
  }),

  getProcoreGlobalCodesList: (company) => request({
    call: axios.get(`/procore/standard/codes?company=${company}`),
    errMsg: (err) => {
      const {
        statusCode,
        message: errorMesage,
      } = err;
      if (statusCode !== 403) return 'Failed to import global codes from Procore';
      return errorMesage;
    },
    hideSuccessToast: true,
  }),

  importFromProcore: (query) => request({
    call: axios.get(`/procore/data?${qs.stringify(query)}`),
    errMsg: 'Failed to import data from Procore',
    hideSuccessToast: true,
  }),

  importIntegrationData: (payload) => request({
    call: axios.post('/integration/import', payload),
    errMsg: 'Failed to import Data',
  }),

  updateProcoreCompany: (payload) => request({
    call: axios.put('/procore/companies', payload),
    errMsg: 'Failed to update Procore company',
    hideSuccessToast: true,
  }),

  getDivisions: () => request({
    call: axios.get('/divisions'),
    defaultData: [],
    errMsg: 'Unable to get divisions',
    hideSuccessToast: true,
  }),

  createDivision: async (divisionName) => request({
    call: axios.put('/divisions', { divisionName }),
    errMsg: `Failed to create division '${divisionName}'`,
  }),

  deleteDivision: async (divisionId) => request({
    call: axios.delete(`/divisions/${divisionId}`, {}),
    errMsg: 'Failed to delete division',
  }),

  transferDivisions: async ({
    type,
    divisionId,
    ids,
    keepOldDivision,
    oldDivisionId,
  }) => request({
    call: axios.post(`/divisions/transfer/${type}`, {
      divisionId,
      ids,
      keepOldDivision,
      oldDivisionId,
    }),
    errMsg: `Failed to transfer ${type}`,
  }),

  renameDivision: async (id, name) => request({
    call: axios.put(`/divisions/${id}`, { name }),
    errMsg: 'Failed to rename division',
  }),

  updateDivisionCode: async (id, code) => request({
    call: axios.put(`/divisions/${id}/code`, { code }),
    errMsg: 'Failed to update division code',
  }),

  getSageData: (integrationId, version) => request({
    call: axios.get('/sage/data', { params: { integrationId, version } }),
    errMsg: 'Failed to get data from Sage',
    hideSuccessToast: true,
  }),

  connectMicrosoft365: (payload) => request({
    call: axios.post('/microsoft365/connect/complete', payload),
    errMsg: 'Failed to connect Microsoft 365',
    hideSuccessToast: true,
  }),

  getIntegratedEmails: () => request({
    call: axios.get('/microsoft365/emails'),
    errMsg: 'Failed to integrated emails',
    hideSuccessToast: true,
  }),

  getCompanyCustomizationTabs: () => request({
    call: axios.get('/settings/customizationTabs'),
    errMsg: 'Failed to get customization tabs',
    hideSuccessToast: true,
  }),

  updateCompanyCustomizationTabs: (id, payload) => request({
    call: axios.put(`/settings/customizationTabs/${id}`, payload),
    errMsg: 'Failed to update customization tabs',
  }),

  getCompanyTranslations: () => request({
    call: axios.get('/translations'),
    errMsg: 'Failed to get company translations',
    hideSuccessToast: true,
  }),

  updateCompanyTranslations: (payload) => request({
    call: axios.post('/translations', payload),
    errMsg: 'Failed to update company translations',
  }),

  createCustomType: (type, payload) => request({
    call: axios.post(`/customTypes/${type}`, payload),
    errMsg: `Failed to create custom type: ${type}`,
  }),
  updateCustomType: (type, id, payload) => request({
    call: axios.put(`/customTypes/${type}/${id}`, payload),
    errMsg: `Failed to update custom type: ${type}`,
  }),
  deleteCustomType: (type, id) => request({
    call: axios.delete(`/customTypes/${type}/${id}`),
    errMsg: `Failed to delete custom type: ${type}`,
  }),
  getWorkingHours: () => request({
    call: axios.get('/workingHours'),
    errMsg: 'Failed to get working hours',
    hideSuccessToast: true,
  }),
  updateWorkingHours: (payload) => request({
    call: axios.put('/workingHours', payload),
    errMsg: 'Failed to update working hours',
    hideSuccessToast: true,
  }),
  getSageTimeSync: (query) => request({
    call: axios.get('/sage100/tasks', { params: query }),
    errMsg: 'Failed to retrieve time sync state',
    hideSuccessToast: true,
  }),
  writeSageTime: (integrationId, taskIds) => request({
    call: axios.post('/sage100/tasks', { integrationId, taskIds }),
    errMsg: 'Failed to write time',
  }),
  resolveSageTime: (integrationId, taskIds, resolved) => request({
    call: axios.post('/sage100/tasks/resolve', { integrationId, taskIds, resolved }),
    errMsg: 'Failed to resolve entries',
  }),
  getSageJobMappings: (integrationId) => request({
    call: axios.get('/sage100/jobmappings', { params: { integrationId } }),
    errMsg: 'Failed to retrieve time sync state',
    hideSuccessToast: true,
  }),
  updateSageJobMappings: (integrationId, mappings) => request({
    call: axios.post('/sage100/jobmappings', { integrationId, mappings }),
    errMsg: 'Failed to write time',
  }),
  getRoundingPolicies: () => request({
    call: axios.get('/roundingPolicies'),
    errMsg: 'Failed to get rounding policies',
    hideSuccessToast: true,
  }),
  createRoundingPolicy: (payload) => request({
    call: axios.post('/roundingPolicies', payload),
    errMsg: 'Failed to create rounding policy',
  }),
  updateRoundingPolicy: (id, payload) => request({
    call: axios.put(`/roundingPolicies/${id}`, payload),
    errMsg: 'Failed to update rounding policy',
  }),
  deleteRoundingPolicy: (id) => request({
    call: axios.delete(`/roundingPolicies/${id}`),
    errMsg: 'Failed to delete rounding policy',
  }),
  updateScheduleRestrictions: (payload) => request({
    call: axios.put('/scheduleRestrictions', payload),
    errMsg: 'Failed to update schedule restrictions',
    hideSuccessToast: true,
  }),
  getScheduleRestrictions: () => request({
    call: axios.get('/scheduleRestrictions'),
    errMsg: 'Failed to get schedule restrictions',
    hideSuccessToast: true,
  }),

};

export default SettingsService;
