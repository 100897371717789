import { DateTime } from "luxon";

export const MAX_RECURRING = {
  daily: 750,
  weekly: 750,
  monthlyNumber: 750,
  monthlyDay: 750,
  annually: 100,
  weekdays: 750,
};

// Count the number of weekdays between the two dates);
const numberOfWeekdaysBetweenDates = (startDT, endDT) => {
  let num = 0;
  let curDT = startDT;
  while (curDT <= endDT) {
    if (curDT.weekday < 6) {
      num += 1;
    }
    curDT = curDT.plus({ days: 1 });
  }
  return num;
};

// Get the monthlyDay repeat count
// ie: Second Friday
const getMonthlyDayCount = (startDT, endDT) => {
  // We have the weekday easily
  // So first we need to get the week number we're targetting
  let monthStart = startDT.startOf('month');
  let targetWeekNum = 0;
  while (monthStart.day <= startDT.day) {
    if (monthStart.weekday === startDT.weekday) targetWeekNum += 1;
    if (monthStart.day === startDT.day) break;
    monthStart = monthStart.plus({ day: 1 });
  }

  // Now we need to get the count.
  // Since we could do things like fourth friday, and not every month will have a fourth friday,
  // we need to check each month individually
  let i = 0;
  let count = 0;
  let curStartDT = startDT.startOf('month');
  // We start with the curent month, and increment
  while (curStartDT <= endDT) {
    // Start from the start of the month
    curStartDT = startDT.plus({ month: i }).startOf('month');
    const originalMonth = curStartDT.month;
    let elapsedWeeks = 0;
    // Iterate day by day
    while (elapsedWeeks < targetWeekNum) {
      // Each time we hit our day of the week, iterate the week number
      if (curStartDT.weekday === startDT.weekday) elapsedWeeks += 1;
      // If we've reached our target number, break
      if (elapsedWeeks === targetWeekNum) break;
      curStartDT = curStartDT.plus({ day: 1 });
    }
    // Set the hour/minute, since that was lost when we set ourselves to the start of the month
    // Relevant for DST offset.
    curStartDT = curStartDT.set({
      hour: startDT.hour,
      minute: startDT.minute,
    });
    // Check that the date we found is actually in our original month
    // And that its before our endDate
    if (curStartDT.month === originalMonth && curStartDT <= endDT) {
      count += 1;
    }
    // Continue the loop
    // If we've passed the end date, the loop will end
    i += 1;
  }
  return count;
};

export const checkCountViolation = (startDate, endDate, repeat) => {
  /*
    Need to account for DST or diff will be off by one hour
    e.g.
      createdDT: 'March 13, 2023, 12:00 AM PDT',
    startDT: 'March 6, 2023, 12:00 AM PST'

    return 6.958333333333333 days diff. Which Math.floor() returns as 6.
  */
  const offsetDiff = (startDate.offset / 60) - (endDate.offset / 60);
  const startDT = startDate.plus({ hour: offsetDiff }).startOf('day');
  const endDT = endDate.startOf('day');
  switch (repeat) {
    case 'daily':
      return (1 + endDT.diff(startDT).as('days')) <= MAX_RECURRING[repeat];
    case 'weekly':
      return (1 + endDT.diff(startDT).as('weeks')) <= MAX_RECURRING[repeat];
    case 'weekdays':
      return numberOfWeekdaysBetweenDates(startDT, endDT) <= MAX_RECURRING[repeat];
    case 'monthlyNumber':
    case 'monthlyDay':
      return getMonthlyDayCount(startDT, endDT) <= MAX_RECURRING[repeat];
    case 'annually':
      return (1 + endDT.diff(startDT).as('years')) <= MAX_RECURRING[repeat];
    case null:
    default:
      return true;
  }
};

export const validateRepeatEndDate = ({
  startDate,
  repeat,
  repeatEndDate,
  relativeEndDate,
  isTemplate,
  isTriggeredTask,
  type = 'Start Date',
}) => {
  if ((!startDate && !isTriggeredTask) || !repeat || (isTemplate && !isTriggeredTask)) {
    return Promise.resolve();
  }
  if (isTriggeredTask && repeat
      && relativeEndDate?.time && relativeEndDate?.relativeTo
  ) {
    return Promise.resolve();
  }
  if (repeat && !repeatEndDate) {
    return Promise.reject(new Error('Repeat End Date is required'));
  }
  const endDateAfterStart = DateTime.fromMillis(repeatEndDate).startOf('day') < DateTime.fromMillis(startDate).startOf('day');
  if (endDateAfterStart) {
    return Promise.reject(new Error(`End Date must be after ${type}`));
  }
  const withinCountLimit = checkCountViolation(
    DateTime.fromMillis(startDate),
    DateTime.fromMillis(repeatEndDate),
    repeat,
  );
  if (!withinCountLimit) {
    return Promise.reject(new Error('End Date is too far in the future'));
  }
  return Promise.resolve();
};
