import React from 'react';

import NuxDetailPane from '../NuxDetailPane';
import integrationSetup from '../../assets/images/NUX/integrationSetup.png';
import integrationManage from '../../assets/images/NUX/integrationManage.png';

import colors from '../../constants/Colors';

const setupDesc = (
  <div>
    To setup and manage your integrations,
    simply connect to the supported integrated application.
    <br/>
    <div style={{
      color:colors.ONTRACCR_RED,
      fontSize:12,
    }}>
      Note: Currently, Ontraccr integrates with Quickbooks Online and Procore. 
      If you require additional integrations, please contact your Ontraccr account team.
    </div>
  </div>
);

const manageDesc = `Once connected to the integrated application,
you can manage your integration settings easily to adjust things
such as the data you choose to import into Ontraccr and your Auto-Sync preferences.`;

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 60px)',
      padding:'0px 40px'
    }}
    rows={[{
      title:'Integration Setup',
      description:setupDesc,
      cols:[0,14,10],
      image:{
        src:integrationSetup,
        style:{
          height:75,
          width:216,
        }
      },
    },{
      title:'Managing Integrations',
      description:manageDesc,
      cols:[0,16,8],
      image:{
        src:integrationManage,
        style:{
          height:150,
          width:154,
        }
      },
    }]}
  />
);
