import React, { memo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Card } from 'antd';

import LiveFeedTaggedText from './LiveFeedTaggedText';

import PhotoReel from '../../common/photos/PhotoReel';

export default connect(
  (state,ownProps) => ({
    ...ownProps,
    fileMap: state.livefeed.files,
    cardFileMap: state.boards.notesFileMap,
    projectFileMap: state.projects.notesFileMap,
  }),
  {}
)(
  memo(({
    post = {},
    fileMap = {},
    reverse,
    cardFileMap = {},
    projectFileMap = {},
    type,
  }) => {
    const [imageURLs,setImageURLs] = useState();
    useEffect(() => {
      const {
        files = [],
      } = post;
      let selectedFileMap = fileMap;
      if (type === 'cardNotes') {
        selectedFileMap = cardFileMap;
      } else if (type === 'projectNotes') {
        selectedFileMap = projectFileMap;
      }
      if(files.length && Object.keys(selectedFileMap).length) { // Gaurd against infinite loop
        const newURLs = [];
        files.forEach((file) => {
          if(file.id in selectedFileMap) {
            newURLs.push({ ...file, ...selectedFileMap[file.id]});
          }
        });
        setImageURLs(newURLs);
      } else {
        setImageURLs([]);
      }
    },[post, type, fileMap, cardFileMap, projectFileMap]);
    const { onPostClick } = post;
    return (
      <Card 
        style={{ 
          width:'100%', 
          margin:'10px 0px 0px 0px',
          border:'none',
          padding:0,
          textAlign: reverse ? 'right' : 'left'
        }}
        bodyStyle={{
          padding:'0px 10px 10px 10px',
        }}
        onClick={() => {
          if (onPostClick) {
            post.onPostClick({
              ...post,
              imageURLs,
            })
          }
        }}
        hoverable={!!onPostClick}
        key={post.id}
      >
        <LiveFeedTaggedText
          post={post}
        />
        <PhotoReel photos={imageURLs} key={post.id}/>
        {/* <Row style={{ width:'100%'}} justify='end'>
          <BorderlessButton style={{
            fontSize:12,
            fontFamily:'roboto-light',
            width:100,
            padding:0,
          }} title='0 replies'/>
        </Row> */} 
      </Card>
    );
  })
);
