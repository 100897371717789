import React from 'react';
import { Row, Col } from 'antd';

export default ({
  title,
  optional,
  filter,
  showCondensedView,
  style = {},
  titleStyle = {},
}) => (
  <Row
    {...!optional ? { className:'form-required-field'} : {}}
    justify='start'
    style={{ width: '100%', ...style }}
  >
    <Col style={titleStyle}>{title}</Col>
    <Col flex='auto'>
      <Row justify={showCondensedView ? '' : 'end'}>
        {filter}
      </Row>
    </Col>
  </Row>
);
