import React, {
  useState, useCallback, useMemo, useEffect,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Row, Col } from 'antd';
import { EditOutlined } from '@ant-design/icons';

import BorderlessButton from '../../../common/buttons/BorderlessButton';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';

import CompanyEditRow from '../../CompanyEditRow';

import EclipseWorkflowDrawer from './EclipseWorkflowDrawer';

import colors from '../../../constants/Colors';

import { deleteEclipseWorkflow, getEclipseWorkflows } from './state/eclipse.actions';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

function EclipseWorkflows() {
  const dispatch = useDispatch();

  const workflows = useSelector((state) => state.eclipse.workflows);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedWorkflowId, setSelectedWorkflowId] = useState();

  const onOpenDrawer = useCallback(() => setDrawerOpen(true), []);
  const onCloseDrawer = useCallback(() => {
    setDrawerOpen(false);
    setSelectedWorkflowId();
  }, []);

  const onDelete = useCallback((workflowId) => {
    const workflow = workflows[workflowId];
    if (!workflow) return;
    CustomConfirmModal({
      title: `Delete workflow: ${workflow.title}?`,
      onOk: () => dispatch(deleteEclipseWorkflow(workflowId)),
    });
  }, [workflows]);

  const cols = useMemo(() => ([
    {
      title: '',
      dataIndex: 'title',
      key: 'Workflow',
      onHeaderCell,
    }, {
      dataIndex: 'action',
      key: 'action',
      onHeaderCell,
      width: 120,
      render: (_, workflow) => (
        <Row justify="end" style={{ width: '100%' }}>
          <Col>
            <BorderlessButton
              title=""
              iconNode={
                <EditOutlined />
            }
              onClick={() => {
                setSelectedWorkflowId(workflow.id);
                setDrawerOpen(true);
              }}
              style={{
                paddingRight: 8,
                paddingLeft: 0,
              }}
            />
          </Col>
          <Col>
            <BorderlessButton
              title=""
              icon="delete"
              color={colors.ONTRACCR_RED}
              onClick={() => onDelete(workflow.id)}
              style={{
                paddingRight: 8,
                paddingLeft: 0,
              }}
            />
          </Col>
        </Row>
      ),
    },
  ]), [onDelete]);

  const workflowArray = useMemo(() => Object.values(workflows), [workflows]);

  useEffect(() => {
    dispatch(getEclipseWorkflows());
  }, []);
  return (
    <>
      <CompanyEditRow
        title="Workflows"
        helpText="Use workflows to write time from OnTraccr to Eclipse"
        divider={false}
      >
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={onOpenDrawer}
        />
      </CompanyEditRow>
      <Table
        size="small"
        columns={cols}
        dataSource={workflowArray}
        rowKey="id"
        pagination={false}
        style={{
          marginBottom: 50,
          width: '100%',
          height: '100%',
          borderRadius: 8,
        }}
      />
      <EclipseWorkflowDrawer
        visible={drawerOpen}
        onClose={onCloseDrawer}
        onDelete={onDelete}
        selectedWorkflowId={selectedWorkflowId}
      />
    </>
  );
}

export default EclipseWorkflows;
