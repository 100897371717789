import React from 'react';
import { Row } from 'antd';

import colors from '../../constants/Colors';

export default ({
  children,
  color = colors.LIGHT_GRAY,
  style = {},
  cardStyle = {},
}) => (
  <Row style={{ width: '100%', height:'100%', ...style}} justify='center' align='middle'>
    <Row 
     className='signup-card'
      style={{
        backgroundColor:color,
        ...cardStyle,
      }}
    >
      {children}
    </Row>
  </Row>
);