import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Form,
  Row,
  Select,
  Table,
} from 'antd';
import { useTranslation } from 'react-i18next';

import {
  createCustomType,
  deleteCustomType,
  updateCustomType,
} from '../state/settings.actions';
import SettingsCardHeader from '../SettingsCardHeader';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import SimpleFormModal from '../../common/modals/SimpleFormModal';
import useToggle from '../../common/hooks/useToggle';
import IconPicker from '../../common/IconPicker/IconPicker';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import CompanySettingsCard from '../CompanySettingsCard';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import Colors from '../../constants/Colors';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import { getEquipmentTypes } from '../../equipment/state/equipment.actions';
import TypesAddEditDrawer from './TypesAddEditDrawer';

const getTypeOptions = (t) => [
  { value: 'projects', label: t('Project', { count: 2 }) },
  { value: 'equipment', label: 'Equipment' },
];

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

const DEFAULT_ICON = 'AntDesignOutlined';

export default function TypesTabView() {
  const { t } = useTranslation();
  const [form] = Form.useForm();

  const dispatch = useDispatch();
  const projectTypes = useSelector((state) => state.projects.projectTypes);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);

  const { isToggled, toggle } = useToggle();

  const TYPES_OPTIONS = getTypeOptions(t);

  const [type, setType] = useState(TYPES_OPTIONS[0].value);
  const [selectedEntry, setSelectedEntry] = useState();
  const [sections, setSections] = useState([]);

  const showIcon = type === 'projects';

  useEffect(() => {
    dispatch(getEquipmentTypes());
  }, []);

  const closeModal = useCallback(() => {
    setSelectedEntry();
    toggle();
  }, [toggle]);

  const onSave = useCallback(async () => {
    const payload = form.getFieldsValue();
    payload.icon = payload.icon || DEFAULT_ICON;
    payload.sections = sections;
    const result = selectedEntry
      ? await dispatch(updateCustomType(type, selectedEntry.id, payload))
      : await dispatch(createCustomType(type, payload));

    if (result) closeModal();
  }, [closeModal, selectedEntry, type, sections]);

  const onDeleteClicked = useCallback(async (record) => new Promise((resolve) => {
    CustomConfirmModal({
      title: `Delete Type ${record.name}?`,
      content: 'Are you sure you want to delete this type?',
      onOk: async () => {
        await dispatch(deleteCustomType(type, record.id));
        resolve();
      },
      okText: 'Delete',
    });
  }, []));

  const columns = useMemo(() => {
    const baseColumns = [];
    if (showIcon) {
      baseColumns.push({
        title: 'Icon',
        dataIndex: 'icon',
        key: 'icon',
        onHeaderCell,
        render: (icon) => <IconPicker value={icon} isDisplay />,
      });
    }

    baseColumns.push(...[
      {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        onHeaderCell,
      },
      {
        dataIndex: 'edit',
        align: 'edit',
        width: 60,
        onHeaderCell,
        render: (_, record) => {
          const { id } = record || {};
          return (
            <BorderlessButton
              key={id}
              title=""
              icon="edit"
              color={Colors.ONTRACCR_BLACK}
              onClick={() => {
                setSelectedEntry(record);
                toggle();
              }}
              style={{
                paddingRight: 8,
                paddingLeft: 0,
              }}
            />
          );
        },
      },
      {
        dataIndex: 'action',
        key: 'action',
        align: 'center',
        width: 60,
        onHeaderCell,
        render: (_, record) => (
          <BorderlessButton
            title=""
            icon="delete"
            color={Colors.opacity(Colors.ONTRACCR_RED, record.isDefault ? 0.3 : 1)}
            onClick={() => {
              if (record.isDefault) return;
              onDeleteClicked(record);
            }}
            style={{
              paddingRight: 8,
              paddingLeft: 0,
              cursor: record.isDefault ? 'not-allowed' : 'pointer',
            }}
          />
        ),
      },
    ]);

    return baseColumns;
  }, [onDeleteClicked, toggle, type]);

  useEffect(() => {
    if (!isToggled) {
      setSections([]);
      setSelectedEntry();
      return form.resetFields();
    }

    return form.setFieldsValue(selectedEntry);
  }, [isToggled, form, selectedEntry]);

  const dataSource = useMemo(() => {
    switch (type) {
      case 'equipment':
        return equipmentTypes;
      default:
        return projectTypes;
    }
  }, [type, projectTypes, equipmentTypes]);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
        marginLeft: 0,
        marginTop: 30,
      }}
    >
      <SettingsCardHeader
        title={(
          <Select
            style={{ width: 350 }}
            options={TYPES_OPTIONS}
            value={type}
            onSelect={setType}
          />
        )}
      >
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={toggle}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        style={{
          position: 'absolute',
          top: 130,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
        />
      </CompanySettingsCard>
      <SimpleFormModal
        title={`${selectedEntry ? 'Edit' : 'Add'} Type`}
        visible={isToggled && type !== 'equipment'}
        onSave={onSave}
        onClose={closeModal}
      >
        <div style={{ width: '100%', display: showIcon ? 'inline-flex' : 'block' }}>
          <Form form={form} layout="inline">
            { showIcon && (
              <Form.Item name="icon">
                <IconPicker />
              </Form.Item>
            )}
            <Form.Item
              name="name"
              label="Name"
              style={{ width: showIcon ? 'inherit' : '100%' }}
            >
              <OnTraccrTextInput placeholder="Enter Name" />
            </Form.Item>
          </Form>
        </div>
      </SimpleFormModal>
      <TypesAddEditDrawer
        selectedEntry={selectedEntry}
        visible={isToggled && type === 'equipment'}
        toggle={toggle}
        showIcon={showIcon}
        setSections={setSections}
        onSave={onSave}
        form={form}
      />
    </Row>
  );
}
