import React from 'react';
import { Typography } from 'antd';

import colors from '../../constants/Colors';

export default ({ 
  text,
  style = {},
}) => (
  <Typography.Text ellipsis style={{
    width:'100%',
    fontFamily:'roboto-regular',
    color:colors.ONTRACCR_BLACK,
    pointerEvents:'none',
    fontSize:16,
    ...style,
  }}>
    {text}
  </Typography.Text>
);