import React, { useMemo, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { TreeSelect } from 'antd';
import moment from 'moment';

import {
  getDateRangeFromConfig,
  getFieldType,
} from './analytics.helpers';
import { FIELDS_CAN_BREAKDOWN } from './analytics.constants';

import { setAnalyticsConfig } from './state/analytics.actions';

export default function AnalyticsDateFieldBreakdownSelector({
  fieldBreakdownOptions = [],
  fieldMap = {},
  analyticsConfig,
  type,
}) {
  const dispatch = useDispatch();
  const {
    dateRange: selectedRange,
    datePreset,
    dateBreakdown,
    fieldBreakdown,
    fieldId,
    chartType,
  } = analyticsConfig;

  const onSelect = useCallback((value) => {
    const isField = value.startsWith('field');
    const update = isField
      ? { fieldBreakdown: value, dateBreakdown: undefined }
      : { fieldBreakdown: undefined, dateBreakdown: value };
    dispatch(setAnalyticsConfig({
      type,
      payload: update,
    }));
  }, [type]);

  const fieldType = useMemo(() => getFieldType(fieldId, fieldMap), [fieldId, fieldMap]);

  const dateRange = useMemo(() => (
    getDateRangeFromConfig({ dateRange: selectedRange, datePreset })
  ), [selectedRange, datePreset]);

  const dateBreakdownOptions = useMemo(() => {
    if (!dateRange || dateRange.length < 2) return [];
    const [startMoment, endMoment] = dateRange;
    const diff = moment.duration(endMoment.diff(startMoment));

    const opts = [{ label: 'Day', value: 'day' }];
    if (diff.asWeeks() >= 1) opts.push({ label: 'Week', value: 'week' });
    if (diff.asMonths() >= 1) opts.push({ label: 'Month', value: 'month' });
    if (diff.asMonths() >= 3) opts.push({ label: 'Quarter', value: 'quarter' });
    if (diff.asYears() >= 1) opts.push({ label: 'Year', value: 'year' });
    return opts;
  }, [dateRange]);

  const treeOpts = useMemo(() => {
    const fullOpts = [
      {
        value: 'byDate',
        title: 'By Date',
        children: dateBreakdownOptions,
        selectable: false,
      },
    ];
    if (fieldBreakdownOptions.length > 0 && fieldId && FIELDS_CAN_BREAKDOWN.has(fieldType)) {
      fullOpts.push({
        value: 'byField',
        title: 'By Field',
        children: fieldBreakdownOptions,
        selectable: false,
      });
    }
    return fullOpts;
  }, [dateBreakdownOptions, fieldBreakdownOptions, fieldId, fieldType]);

  return (
    <TreeSelect
      treeData={treeOpts}
      style={{ width: 200 }}
      disabled={chartType === 'number' || chartType === 'pie' || chartType === 'list'}
      value={dateBreakdown || fieldBreakdown}
      treeDefaultExpandAll
      onSelect={onSelect}
      dropdownMatchSelectWidth={false}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
AnalyticsDateFieldBreakdownSelector.propTypes = {
  fieldBreakdownOptions: PropTypes.array,
  fieldMap: PropTypes.object,
  analyticsConfig: PropTypes.object,
  type: PropTypes.string,
};

AnalyticsDateFieldBreakdownSelector.defaultProps = {
  fieldBreakdownOptions: [],
  fieldMap: {},
  analyticsConfig: {},
  type: null,
};
