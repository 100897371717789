import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import BorderlessButton from '../common/buttons/BorderlessButton';

const getTopSelectOptions = (t) => [
  { label: 'Users', value: 'userId' },
  { label: t('Project', { count: 2 }), value: 'projectId' },
  { label: t('Customer', { count: 2 }), value: 'customerId' },
  { label: 'Equipment', value: 'equipmentId' },
  { label: 'Materials', value: 'materialId' },
];

const getPrefixMap = (t) => ({
  userId: 'Users',
  projectId: t('Project', { count: 2 }),
  customerId: t('Customer', { count: 2 }),
  equipmentId: 'Equipment',
  materialId: 'Materials',
});

const linkMap = {
  userId: 'users',
  projectId: 'projects',
  customerId: 'contacts/customers',
  equipmentId: 'equipment',
  materialId: 'materials',
};

export default function BoardLinkSelector({
  isDisplay,
  value,
  onChange,
}) {
  const {
    linkType,
    linkId,
  } = value || {};
  const history = useHistory();
  const { t } = useTranslation();

  const users = useSelector((state) => state.users.users);
  const projects = useSelector((state) => state.projects.projects);
  const customers = useSelector((state) => state.customers.customers);
  const materials = useSelector((state) => state.materials.materials);
  const equipment = useSelector((state) => state.equipment.equipment);

  const topSelectOptions = getTopSelectOptions(t);
  const prefixMap = getPrefixMap(t);

  const onSelectType = useCallback((newLinkType) => {
    onChange({
      linkType: newLinkType,
    });
  }, [onChange]);

  const onSelectId = useCallback((newLinkId) => {
    onChange({
      linkType,
      linkId: newLinkId,
    });
  }, [linkType, onChange]);

  const onLinkClick = useCallback(() => {
    const {
      [linkType]: path,
    } = linkMap;
    if (!path) return;
    history.push({
      pathname: path.toLowerCase(),
      state: { targetId: linkId },
    });
  }, [linkType, linkId, history]);

  const bottomSelectOptions = useMemo(() => {
    let arr = [];
    switch (linkType) {
      case 'userId':
        arr = users;
        break;
      case 'projectId':
        arr = projects;
        break;
      case 'customerId':
        arr = Object.values(customers);
        break;
      case 'equipmentId':
        arr = equipment;
        break;
      case 'materialId':
        arr = Object.values(materials);
        break;
      default:
        break;
    }

    return arr
      .filter((item) => item.active)
      .map((item) => ({ value: item.id, label: item.name }));
  }, [linkType, users, projects, customers, materials, equipment]);

  const displayText = useMemo(() => {
    if (!linkType || !linkId) return '';
    const {
      [linkType]: prefix = '',
    } = prefixMap;
    const {
      label = '',
    } = bottomSelectOptions.find((opt) => opt.value === linkId) || {};
    return label.length > 0 && prefix.length > 0
      ? `${prefix} - ${label}`
      : '';
  }, [linkType, linkId, bottomSelectOptions]);

  const displayView = displayText.length > 0
    ? (
      <BorderlessButton
        title={displayText}
        onClick={onLinkClick}
        id="board-card-link-button"
        style={{ width: 'fit-content' }}
      />
    ) : null;
  return (
    isDisplay
      ? displayView
      : (
        <>
          <Select
            options={topSelectOptions}
            onChange={onSelectType}
            placeholder="Select a link type"
            value={linkType}
            allowClear
          />
          {
            linkType && (
              <Select
                options={bottomSelectOptions}
                style={{ marginTop: 5 }}
                placeholder="Select a link"
                showSearch
                optionFilterProp="label"
                onChange={onSelectId}
                value={linkId}
                allowClear
              />
            )}
        </>
      )
  )
}

BoardLinkSelector.propTypes = {
  isDisplay: PropTypes.bool,
  value: PropTypes.shape({
    linkType: PropTypes.string,
    linkId: PropTypes.string,
  }),
  onChange: PropTypes.func.isRequired,
};

BoardLinkSelector.defaultProps = {
  isDisplay: false,
  value: null,
};
