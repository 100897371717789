/* eslint-disable no-fallthrough */
import {
  GET_GLOBAL_MATERIAL_LOCATIONS,
  CREATE_GLOBAL_MATERIAL_LOCATION,
  DELETE_GLOBAL_MATERIAL_LOCATION,
  UPDATE_GLOBAL_MATERIAL_LOCATION,
} from '../../state/actionTypes';

const initialState = {
  globalMaterialLocations: [], // E.g. [{id: '123', companyId: '321', locationText: 'van'}, ...]
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_GLOBAL_MATERIAL_LOCATIONS: {
      const {
        payload: {
          globalMaterialLocations = [],
        } = {},
      } = action;
      return {
        globalMaterialLocations,
      };
    }
    case CREATE_GLOBAL_MATERIAL_LOCATION: {
      const {
        payload: {
          newlyCreatedLocation = {},
        } = {},
      } = action;
      const {
        globalMaterialLocations: stateGlobalMaterialLocations,
      } = state;
      const newGlobalMaterialLocations = [...stateGlobalMaterialLocations];
      newGlobalMaterialLocations.push(newlyCreatedLocation);
      return {
        globalMaterialLocations: newGlobalMaterialLocations,
      };
    }
    case DELETE_GLOBAL_MATERIAL_LOCATION: {
      const {
        payload: {
          idToDelete = '',
        } = {},
      } = action;
      const {
        globalMaterialLocations: stateGlobalMaterialLocations,
      } = state;
      const newGlobalMaterialLocations = [...stateGlobalMaterialLocations];
      return {
        globalMaterialLocations: newGlobalMaterialLocations
          .filter((location) => location.id !== idToDelete),
      };
    }
    case UPDATE_GLOBAL_MATERIAL_LOCATION: {
      const {
        payload: {
          globalMaterialLocationId = '',
          newLocationText = '',
        } = {},
      } = action;
      const {
        globalMaterialLocations: stateGlobalMaterialLocations,
      } = state;
      const newGlobalMaterialLocations = [...stateGlobalMaterialLocations];
      return {
        globalMaterialLocations: newGlobalMaterialLocations.map((location) => {
          if (location.id !== globalMaterialLocationId) {
            return location;
          }
          return {...location, locationText: newLocationText}
        })
      };
    }
    default:
      return state;
  }
};
