import React, {
  useCallback, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Select,
  message,
} from 'antd';
import axios from 'axios';

import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../../common/inputs/FormTextInput';
import useToggle from '../../../common/hooks/useToggle';
import { getIdMap } from '../../../helpers/helpers';

const formStyle = { marginBottom: 0, paddingBottom: -8 };
const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 10,
  },
};
function EclipseUserPayTypeDrawer({
  visible,
  onClose,
  onSave,
  existing = [],
}) {
  const [form] = Form.useForm();
  const users = useSelector((state) => state.users.users);

  const {
    isToggled: loading,
    toggle: toggleLoading,
  } = useToggle();

  const userMap = useMemo(() => getIdMap(users), [users]);
  const onSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      toggleLoading();
      const { err } = await axios.post('/eclipse/users/pay_types', values);
      toggleLoading();
      if (err) {
        throw new Error(err);
      }
      const {
        [values.userId]: { name: user, employeeId } = {},
      } = userMap;
      onSave({
        ...values,
        user,
        employeeId,
      });
    } catch (_err) {
      message.error('Could not add Eclipse Pay Type');
    }
  }, [form, userMap, onSave]);

  const options = useMemo(() => {
    const existingSet = new Set(
      existing.map((user) => user.userId),
    );
    return users.filter((user) => !existingSet.has(user.id))
      .map((user) => {
        const suffix = user.employeeId ? ` - ${user.employeeId}` : '';
        return {
          label: `${user.name}${suffix}`,
          value: user.id,
        };
      });
  }, [existing, users]);

  return (
    <Drawer
      title="Add Eclipse User Pay Type"
      visible={visible}
      onClose={onClose}
      width={800}
    >
      <Form form={form} layout="vertical">
        <Form.Item
          label="User"
          name="userId"
          style={formStyle}
          labelCol={formLabelStyle}
          rules={[{ required: true, message: 'Please select a user' }]}
        >
          <Select
            options={options}
            showSearch
            optionFilterProp="label"
          />
        </Form.Item>
        <FormTextInput
          isNotDisplay
          label="Eclipse Pay Type"
          name="payType"
          rules={[{ required: true, message: 'Eclipse Pay Type is required' }]}
        />

      </Form>
      <DrawerSubmitFooter
        onSubmit={onSubmit}
        onClose={onClose}
        loading={loading}
      />
    </Drawer>
  );
}

EclipseUserPayTypeDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  existing: PropTypes.arrayOf(PropTypes.shape({
    userId: PropTypes.string,
  })).isRequired,
};

EclipseUserPayTypeDrawer.defaultProps = {
  visible: false,
};

export default EclipseUserPayTypeDrawer;
