import React, { useState, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Tag } from 'antd';
import { FilterFilled } from '@ant-design/icons';
import PropTypes from 'prop-types';

import SearchFormFilterHeader from '../forms/CompletedForms/SearchFormFilterHeader';

import colors from '../constants/Colors';

import { toTitleCase } from '../helpers/helpers';

import { updateAnalyticsFilters } from './state/analytics.actions';

export default function AnalyticsBreakDownTag(props) {
  const {
    value: filterKey,
    type,
    analyticsConfig: {
      statuses,
      boardStatuses,
      boardId,
      formTemplateId,
      filters,
      cardTitles,
    } = {},
    isClosable = true,
  } = props;
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const formStatuses = useSelector((state) => state.forms.statuses);

  const {
    [filterKey]: selectedKeys = [],
  } = filters;
  const [showDropdown, setShowDropdown] = useState(false);

  const onOpenFilter = useCallback(() => setShowDropdown(true),[]);

  const onCloseDropdown = useCallback(() => {
    setShowDropdown(false);
  },[]);

  const onClear = useCallback(() => {
    setShowDropdown(false);
    dispatch(updateAnalyticsFilters({
      type,
      payload: { [filterKey]: [] },
    }));
  },[dispatch, filterKey]);

  const onKeysUpdate = useCallback((newKeys = []) => {
    dispatch(updateAnalyticsFilters({
      type,
      payload: { [filterKey]: newKeys },
    }));
  },[dispatch, filterKey]);

  const cardTitleFilters = useMemo(() => (
    cardTitles.map((title) => ({ text: title, value: title }))
  ), [cardTitles]);
  const userFilters = useMemo(() => users.map((user) => ({ text: user.name, value: user.id })),[users]);

  const boardStatusFilters = useMemo(() => (
    boardStatuses
    .map((status) => ({ text: status, value: status }))
  ), [boardStatuses, boardId]);

  const statusFilters = useMemo(() => {
    if (formTemplateId) {
      return Object.values(formStatuses).map((status) => ({ text: toTitleCase(status.status), value: status.id }));
    }
    return statuses
    .filter((status) => status.boardId === boardId)
    .map((status) => ({ text: status.title, value: status.id }));
  }, [statuses, boardId, formTemplateId, formStatuses]);

  const searchFilters = useMemo(() => {
    switch (filterKey) {
      case 'user': return userFilters;
      case 'status': return statusFilters;
      case 'boardCard': return cardTitleFilters;
      case 'boardStatus': return boardStatusFilters;
      default: return [];
    }
  },[filterKey, userFilters, statusFilters, cardTitleFilters, boardStatusFilters]);

  return (
    <>
    {
      showDropdown && <div className='analytics-tag-background' onClick={onCloseDropdown}/>
    }
      <Dropdown
        visible={showDropdown}
        overlay={() => (
          <SearchFormFilterHeader
            filters={searchFilters}
            confirm={onCloseDropdown}
            setSelectedKeys={onKeysUpdate}
            selectedKeys={selectedKeys}
            clearFilters={onClear}
            visible={showDropdown}
          />
        )}
      >
        <Tag {...props} closable={isClosable}>
          <div className='workflow-email-tag-label'>
            {props.label}
            <span className='analytics-tag-filter-icon' onClick={onOpenFilter}>
              <FilterFilled
                style={selectedKeys.length > 0
                  ? { color: colors.ONTRACCR_RED }
                  : {}
              }
              />
            </span>
          </div>
        </Tag>
      </Dropdown>
    </>
  )
}

/* eslint-disable react/forbid-prop-types */
AnalyticsBreakDownTag.propTypes = {
  type: PropTypes.string,
  analyticsConfig: PropTypes.object,
  value: PropTypes.string.isRequired,
  isClosable: PropTypes.bool,
};

AnalyticsBreakDownTag.defaultProps = {
  type: null,
  analyticsConfig: {},
  isClosable: true,
};
