import {
  EditOutlined,
  FontSizeOutlined,
  HighlightOutlined,
} from '@ant-design/icons';

const pdfAnnotationIconTypes = {
  text: FontSizeOutlined,
  pencil: EditOutlined,
  highlight: HighlightOutlined,
};

const PDF_SCALE = 2;

const defaultHightlightAnnotationStyles = {
  opacity: 0.5,
  width: 10 * PDF_SCALE,
};

export {
  pdfAnnotationIconTypes,
  defaultHightlightAnnotationStyles,
  PDF_SCALE,
};
