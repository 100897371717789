import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import formWorkflow from '../../assets/images/NUX/formWorkflow.png';

const triggerDesc = (
  <div style={{ fontSize: 13 }}>
    Define which specific action will trigger the form to be sent to users.
</div>
);
const stepDesc = (
  <div style={{ fontSize: 13 }}>
    Steps represent events within the form's workflow.
    Steps exist for things like approvals, email notifications, form exports, and more.
</div>
);
const connectionDesc = (
  <div style={{ fontSize: 13 }}>
    Each step has input and/or output connections available.
    Connect steps together by connecting outputs to inputs and vice-versa.
    You can set up multiple connections for each step input or output.
</div>
);

export default ({
  onBack,
  onTry,
}) => (
  <NuxDetailPane
    onBack={onBack}
    onTry={onTry}
    style={{
      height: 'calc(100% - 74px)',
      padding:'0px 40px',
    }}
    rows={[
    {
      title:'',
      description:'',
      cols:[0,0,24],
      image:{
        src:formWorkflow,
        style:{
          height:300,
          width:211,
          position:'absolute',
          top:0,
          right:0,
        }
      },
    },
    {
      title:'Trigger',
      description: triggerDesc,
      cols:[0,14,0],
    },{
      title:'Steps',
      description: stepDesc,
      cols:[0,14,0],
    },{
      title:'Connections',
      description:connectionDesc,
      cols:[0,14,0],
    }]}
    tutorialLink={'F5. Creating Forms Part 2 - Designing Workflows.mp4'}
  />
);
