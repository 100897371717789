import React, { useCallback, useEffect, } from 'react';
import { Drawer, Form } from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FormTextInput from '../common/inputs/FormTextInput';
import DivisionSelector from '../common/inputs/DivisionSelector';

const formLabelStyle = {
  style:{
    paddingBottom:5,
    marginTop:10,
  },
};

export default ({
  item,
  visible,
  onClose,
  onSubmit,
}) => {
  const [form] = Form.useForm();

  const onSubmitClicked = useCallback(async () => {
    try {
      const values = await form.validateFields();
      onSubmit(values);
    } catch (err) {
      // Failed
    }
  },[onSubmit]);

  useEffect(() => {
    const reset = async () => {
      await form.resetFields();
    }
    if(!visible) reset();
  },[visible,form]);

  return (
    <Drawer
      title='Add Folder'
      width={400}
      visible={visible}
      onClose={onClose}
    >
      <Form
        layout='vertical'
        form={form}
        className='materials-add-drawer-form'
        initialValues={item}
      >
        <FormTextInput
          name='name'
          label='Name'
          isNotDisplay
          rules={[
            { required: true, message: 'Please enter a name' }
          ]}
        />
       
        <Form.Item
          name='divisionId'
          label='Division'
          style={{ marginBottom:0, paddingBottom:-8 }}
          labelCol={formLabelStyle}
          rules={[{ required: true, message: 'Please enter a division' }]}
          valuePropName='divisionId'
          >
            <DivisionSelector/>
        </Form.Item>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
      />
    </Drawer>
  )
}