import React from 'react';
import { Row } from 'antd';
import {
  LeftOutlined,
  RightOutlined,
} from '@ant-design/icons';

import BorderlessButton from '../buttons/BorderlessButton';

import colors from '../../constants/Colors';

export default ({ left, onClick, style = {} }) => (
  <Row justify='center' align='middle'>
    <BorderlessButton
      style={{ width: 30,
        backgroundColor:colors.ONTRACCR_DARK_YELLOW,
        padding:0,
        borderRadius:4,
        ...style
      }}
      iconNode={
        left
        ? <LeftOutlined className='date-range-picker-icon'/>
        : <RightOutlined className='date-range-picker-icon'/>
      }
      onClick={onClick}
    />
  </Row>
);
