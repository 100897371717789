import React, { useState, useRef, useEffect } from 'react';
import { Row } from 'antd';

import MonthDay from './MonthDay';

import { getDayKey } from '../scheduleHelpers';

const days = new Array(7).fill(1);
export default ({
  showBorder,
  weekStart,
  monthStart,
  monthEnd,
  onDaySelect,
  onShiftSelect,
  shifts = {},
}) => {
  const containerRef = useRef(null);
  const [height,setHeight] = useState();
  const className = (
    showBorder
      ? 'schedule-month-week-border-bottom'
      : 'schedule-month-week'
  );
  useEffect(() => {
    const updateSize = () => {
      if(containerRef && containerRef.current) {
        const { clientHeight } = containerRef.current;
        setHeight(clientHeight);
      }
    }
    updateSize();
    window.addEventListener('resize',updateSize);
    return () =>  window.removeEventListener('resize',updateSize);
  },[]);
  const weekDays = days.map((_,idx) => weekStart.plus({ days: idx }));
  return (
    <Row
      ref={containerRef}
      className={className}
    >
      {weekDays.map((day) => (
        <MonthDay
          key={day.toLocaleString()}
          day={day}
          monthStart={monthStart}
          monthEnd={monthEnd}
          onDaySelect={onDaySelect}
          shifts={shifts[getDayKey(day)]}
          onShiftSelect={onShiftSelect}
          weekStart={weekStart}
          height={height}
        />
      ))}
    </Row>
  )
}