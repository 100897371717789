import React from 'react';
import PropTypes from 'prop-types';
import { Timeline } from 'antd';
import TimelineStepText from './TimelineStepText';
import OnTraccrButton from '../buttons/OnTraccrButton';

export default function Step({
  Icon,
  title,
  timestamp,
  text,
  color = 'black',
  textColor,
  action = null,
  prefixCls,
  note,
  children,
  IconNode,
}) {
  return (
    <Timeline.Item
      dot={IconNode ?? <Icon />}
      color={color}
      prefixCls={prefixCls}
    >
      <TimelineStepText
        title={title}
        timestamp={timestamp}
        text={text}
        note={note}
        color={textColor}
      />
      {action ? (
        <OnTraccrButton
          onClick={action.onClick}
          title={action.title}
          disabled={action.disabled || action.title === '✓'}
          loading={action.loading}
          style={{
            marginTop: 10,
          }}
        />
      ) : null}
      {children}
    </Timeline.Item>
  );
}

Step.propTypes = {
  Icon: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  timestamp: PropTypes.number.isRequired,
  text: PropTypes.string.isRequired,
  color: PropTypes.string,
  textColor: PropTypes.string,
  action: PropTypes.shape({
    title: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    loading: PropTypes.bool,
    disabled: PropTypes.bool,
  }),
  prefixCls: PropTypes.string,
  note: PropTypes.string,
  children: PropTypes.node,
};

Step.defaultProps = {
  color: 'black',
  textColor: null,
  action: null,
  prefixCls: null,
  note: null,
  children: null,
};
