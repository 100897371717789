import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Switch, Table, Row, Col,
} from 'antd';
import { CloseOutlined, CheckOutlined, EditOutlined } from '@ant-design/icons';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import CompanySettingsCard from '../CompanySettingsCard';
import CheckRows from '../CheckRows';
import TimeTrackingSelect from '../TimeTrackingSettings/TimeTrackingSelect';
import TabletSettingsDrawer from './TabletSettingsDrawer';

import colors from '../../constants/Colors';

const getCols = ({
  onDelete,
  onEditClicked,
}) => [{
  title: '',
  dataIndex: 'username',
  key: 'username',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
},
{
  dataIndex: 'action',
  key: 'action',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
  width: 120,
  render: (_, _record, index) => (
    <Row justify="end" style={{ width: '100%' }}>
      <Col>
        <BorderlessButton
          title=""
          iconNode={
            <EditOutlined />
            }
          onClick={() => onEditClicked(index)}
          style={{
            paddingRight: 8,
            paddingLeft: 0,
          }}
        />
      </Col>
      <Col>
        <BorderlessButton
          title=""
          icon="delete"
          color={colors.ONTRACCR_RED}
          onClick={() => onDelete(index)}
          style={{
            paddingRight: 8,
            paddingLeft: 0,
          }}
        />
      </Col>
    </Row>

  ),
}];

const autoLogoutValues = [1, 2, 3, 4, 5];

const autoLogRows = [
  {
    key: 'enableTabletAutoLogout',
    title: 'Enable Auto Logout',
    helpText: 'Logout tablet user after a period of inactivity',
    divider: false,
  }, {
    key: 'tabletAutoLogoutPeriod',
    title: 'Auto Logout Period',
    style: {
      paddingLeft: 30,
    },
  },
  {
    key: 'tabletUsers',
    title: 'Tablet Account Credentials',
    helpText: (
      <div>
        Accounts used to log into tablet mode
        {' '}
        <br />
        <br />
        Username must be greater than 5 characters.
        Password must be greater than 8 characters.
      </div>
    ),
    divider: false,
  },
];

function TabletSettings({
  settings: {
    enableTabletAutoLogout,
    tabletAutoLogoutPeriod,
    enableTabletMode,
  } = {},
  saveCompanySettings,
  deleteTablet,
  tablets = [],
}) {
  const [editData, setEditData] = useState();
  const [showDrawer, setShowDrawer] = useState(false);

  const onEditClicked = useCallback((index) => {
    setShowDrawer(true);
    setEditData(tablets[index]);
  }, [tablets]);

  const onOpenDrawer = useCallback(() => setShowDrawer(true), []);
  const onCloseDrawer = useCallback(() => {
    setEditData();
    setShowDrawer(false);
  }, []);

  return (
    <>
      <Row style={{
        width: '100%', maxHeight: '100vh', marginLeft: 177, marginTop: 7,
      }}
      >
        <Col style={{ width: '100%', height: '100%' }}>
          <CompanySettingsCard
            title="Tablet Mode"
            leftHeaderView={(
              <Switch
                style={{
                  opacity: 1,
                }}
                checked={enableTabletMode}
                checkedChildren={<CheckOutlined />}
                unCheckedChildren={<CloseOutlined />}
                onChange={() => {
                  saveCompanySettings({
                    enableTabletMode: !enableTabletMode,
                  });
                }}
              />
            )}
            style={{
              width: '100%',
              height: '100%',
              paddingLeft: 0,
            }}
            containerStyle={{ borderRadius: 8 }}
          >
            <CheckRows
              data={autoLogRows}
              onChange={async () => {
                if (await saveCompanySettings({
                  enableTabletAutoLogout: !enableTabletAutoLogout,
                })) {
                  if (!tabletAutoLogoutPeriod && !enableTabletAutoLogout) {
                    saveCompanySettings({
                      tabletAutoLogoutPeriod: autoLogoutValues[0],
                    });
                  }
                }
              }}
              isChecked={() => enableTabletAutoLogout}
              checkBoxDisabled={() => !enableTabletMode}
              getView={(index) => { // eslint-disable-line react/no-unstable-nested-components
                if (index === 0) return null; // Use default checkbox
                if (index === autoLogRows.length - 1) return <div />;
                return (
                  <TimeTrackingSelect
                    disabled={!enableTabletAutoLogout || !enableTabletMode}
                    data={autoLogoutValues}
                    defaultValue={tabletAutoLogoutPeriod || autoLogoutValues[0]}
                    format={(value) => `${value} minute${value !== 1 ? 's' : ''}`}
                    onChange={(logoutValue) => saveCompanySettings({
                      tabletAutoLogoutPeriod: logoutValue,
                    })}
                  />
                );
              }}
            />
            <Row justify="end" style={{ width: '100%' }}>
              <OnTraccrButton
                title="Add"
                type="primary"
                onClick={onOpenDrawer}
              />
            </Row>
            <Table
              disabled={!enableTabletMode}
              size="small"
              columns={getCols({
                onDelete: async (index) => {
                  const ourTablet = tablets[index];
                  if (!ourTablet) return;
                  deleteTablet(ourTablet.id);
                },
                onEditClicked,
              })}
              dataSource={tablets}
              rowKey="id"
              pagination={false}
              style={{
                marginBottom: 50,
                width: '100%',
                height: '100%',
                borderRadius: 8,
                pointerEvents: enableTabletMode ? 'auto' : 'none',
                opacity: enableTabletMode ? 1 : 0.7,
                cursor: enableTabletMode ? 'auto' : 'not-allowed',
              }}
            />
          </CompanySettingsCard>
        </Col>
      </Row>
      <TabletSettingsDrawer
        visible={showDrawer}
        selectedTablet={editData}
        onClose={onCloseDrawer}
      />
    </>
  );
}

/* eslint-disable react/forbid-prop-types */
TabletSettings.propTypes = {
  settings: PropTypes.object.isRequired,
  saveCompanySettings: PropTypes.func.isRequired,
  createTablet: PropTypes.func.isRequired,
  deleteTablet: PropTypes.func.isRequired,
  tablets: PropTypes.array.isRequired,
};

export default TabletSettings;
