import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import sanitizeHtml from 'sanitize-html';
import { Row, Col } from 'antd';
import { DateTime } from 'luxon';

import BoardCardEmailAttachments from './BoardCardEmailAttachments';

import { getCardEmailFile } from '../state/boards.actions';

const sanitationConfig = {
  allowedTags: sanitizeHtml.defaults.allowedTags.concat([ 'img', 'center' ]),
  allowedSchemesByTag: {
    img: [ 'blob', 'https', 'http', 'data']
  },
  allowedAttributes: {
    ...sanitizeHtml.defaults.allowedAttributes,
    table: ['style']
  }
};

export default ({
  threadId,
  to,
  from,
  body,
  snippet,
  timestamp,
  files,
  onFileClick,
  isLast,
}) => {
  const dispatch = useDispatch();
  const cardEmailFiles = useSelector(state => state.boards.cardEmailFiles);
  
  const [collapsed, setCollapsed] = useState(!isLast);

  const onCollapseToggle = useCallback(() => {
    setCollapsed(!collapsed);
  },[collapsed]);

  const {
    sanitized,
    attachments = [],
  } = useMemo(() => {
    let realBody = body;
    const attachedFiles = [];
    if (files) {
      files.forEach((file) => {
        const { name, attachmentId, contentId = name } = file;
        const {
          [attachmentId]: {
            jsFileObject,
          } = {},
        } = cardEmailFiles;
        if (jsFileObject) {
          let before = realBody;
          realBody = realBody.replaceAll(`src="cid:${contentId}"`, `src=${URL.createObjectURL(jsFileObject)}`);
          if (realBody === before) {
            attachedFiles.push(file);
          }
        }
      })
    }
    return {
      sanitized: sanitizeHtml(realBody, sanitationConfig),
      attachments: attachedFiles,
    }
  },[files, body, cardEmailFiles]);

  const dateText = useMemo(() => {
    const dt = timestamp ? DateTime.fromMillis(timestamp) : DateTime.local();
    return dt.toLocaleString(DateTime.DATETIME_MED);
  },[timestamp]);

  const [fromName, fromEmail] = useMemo(() => {
    if (!from) return [];
    const splitIdx = from.indexOf('<');
    if (splitIdx <= 0) return [from]; // If < is first char (i.e. there is no name), return just from
    return [from.substring(0, splitIdx), from.substring(splitIdx, from.length)];
  },[from]);

  useEffect(() => {
    const getFiles = async () => {
      if (!files) return;
      const filesToGet = files.filter((file) => !(file.attachmentId in cardEmailFiles));
      if (filesToGet.length > 0) {
        await Promise.all(
          filesToGet.map((file) => dispatch(getCardEmailFile({ threadId, file })))
        )  
      }
    }
    getFiles();
  },[dispatch, files, cardEmailFiles, threadId]);
  const headerClass = `board-card-email-entry-header${isLast ? '' : '-clickable'}`;
  return (
    <Row className='board-card-email-entry'>
      <Row justify='space-between' align='top' className={headerClass} onClick={isLast ? null : onCollapseToggle}>
        <Col>
          <div>
            <span className='board-card-email-from'>{fromName}</span>
            {!collapsed && <span className='board-card-email-small-text'>{fromEmail}</span>}
          </div>
          {!collapsed && <div className='board-card-email-small-text'>to: {to}</div>}
        </Col>
        <Col className='board-card-email-small-text'>
          {dateText}
        </Col>
      </Row>
      {
        collapsed 
        ? <div className='board-card-email-small-text'>{snippet}</div>
        : (
          <>
            <div dangerouslySetInnerHTML={{ __html: sanitized }} style={{ width: '100%'}} />
            <BoardCardEmailAttachments
              files={attachments}
              onFileClick={onFileClick}
            />
          </>
        )
      }
    </Row>
  )
}