import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Button, Col, Row, notification,
} from 'antd';
import {
  withScriptjs,
} from 'react-google-maps';
import { DateTime } from 'luxon';
import { AimOutlined, SendOutlined } from '@ant-design/icons';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import OntraccrWeather from '../../../common/weather/OntraccrWeather';
import TitleRow from './TitleRow';
import Colors from '../../../constants/Colors';
import { getWeather } from '../../../common/weather/state/weather.actions';
import { isNullOrUndefined } from '../../../helpers/helpers';

function WeatherFieldPreview({
  previewProps = {},
  configProps = {},
  id,
  responses = {},
  setResponses,
  responding = false,
  isDisplay,
  projectId,
  projects,
}) {
  const {
    optional,
    title = 'Title goes here',
    isEditable,
    prefillFromProject,
    linkField,
    units,
    showDailySnapshot = true,
    showWeatherReport = true,
  } = configProps;

  const {
    value: previewValue,
  } = previewProps;

  const {
    [id]: {
      value,
    } = {},
  } = responses ?? {};

  const {
    latitude: currentLatitude,
    longitude: currentLongitude,
    locationDescriptor: currentLocationDescriptor,
  } = value ?? {};

  const [locationValue, setLocationValue] = useState();
  const [loading, setLoading] = useState();
  const [lastProjectId, setLastProjectId] = useState();
  const [date, setDate] = useState();
  const [lastDate, setLastDate] = useState();

  const onLocationValueChange = (e) => {
    const {
      target: {
        value: newValue,
      } = {},
    } = e;
    setLocationValue(newValue);
  };

  const fetchWeather = useCallback(async ({
    latitude, longitude, locationDescriptor,
  }) => {
    setLoading(true);
    const payload = { latitude, longitude, units };
    if (date) {
      payload.date = DateTime.fromMillis(date).toSQLDate();
      setLastDate(date);
    }
    const weatherData = await getWeather(payload);
    if (weatherData) weatherData.locationDescriptor = locationDescriptor;
    if (responding) {
      setResponses((prev) => ({
        ...prev,
        [id]: {
          ...(prev[id]),
          value: weatherData,
          showDailySnapshot,
          showWeatherReport,
        },
      }));
    }
    setLoading(false);
  }, [responding, setResponses, date, units, showDailySnapshot, showWeatherReport]);

  useEffect(() => {
    if (!linkField) return;
    const {
      [linkField]: {
        date: linkFieldDate,
      } = {},
    } = responses;
    if (date === linkFieldDate) return;
    setDate(linkFieldDate);
  }, [
    responses,
    date,
    linkField,
  ]);

  useEffect(() => {
    if (
      responding
      && (
        responses?.[id]?.showDailySnapshot !== showDailySnapshot
        || responses?.[id]?.showWeatherReport !== showWeatherReport
      )
    ) {
      setResponses((oldResponses) => ({
        ...oldResponses,
        [id]: {
          ...oldResponses[id] ?? {},
          showDailySnapshot,
          showWeatherReport,
        },
      }));
    }
  }, [
    responses,
    id,
    responding,
    showDailySnapshot,
    showWeatherReport,
  ]);

  useEffect(() => {
    if (
      responding
      && prefillFromProject
      && projectId
      && (projectId !== lastProjectId)
    ) {
      const project = projects?.find((r) => r.id === projectId);
      if (!project) return;
      const { latitude, longitude, address } = project;
      if (isNullOrUndefined(latitude) || isNullOrUndefined(longitude)) return;
      setLastProjectId(projectId);
      fetchWeather({ latitude, longitude, locationDescriptor: address });
    }
  }, [projects, projectId, lastProjectId, fetchWeather]);

  useEffect(() => {
    if (responding && currentLatitude && currentLongitude && lastDate !== date) {
      fetchWeather({
        latitude: currentLatitude,
        longitude: currentLongitude,
        locationDescriptor: currentLocationDescriptor,
      });
    }
  }, [
    responding,
    fetchWeather,
    date,
    lastDate,
    currentLatitude,
    currentLongitude,
    currentLocationDescriptor,
  ]);

  const setManualLocation = useCallback(async () => {
    setLoading(true);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({
      address: locationValue,
    }, async (results, status) => {
      if (status === 'OK' && results[0]) {
        const { location } = results[0].geometry || {};
        const latitude = location?.lat();
        const longitude = location?.lng();
        await fetchWeather({
          latitude,
          longitude,
          locationDescriptor: locationValue,
        });
        setLoading(false);
      } else {
        setLoading(false);
        notification.error({
          key: 'error',
          message: 'Error',
          description:
            `Unable to retrieve weather data for ${locationValue}`,
          duration: 5,
        });
      }
    });
  }, [locationValue, fetchWeather]);

  // Pull browser location and fetch updated weather data
  const setUserLocation = useCallback(() => {
    setLoading(true);
    if (!navigator.geolocation) {
      setLoading(false);
      return notification.error({
        key: 'error',
        message: 'Error',
        description:
          'Geolocation is not supported by this browser',
        duration: 5,
      });
    }

    return navigator.geolocation.getCurrentPosition(async (position) => {
      await fetchWeather(position.coords);
      setLoading(false);
    }, () => {
      setLoading(false);
      notification.error({
        key: 'error',
        message: 'Error',
        description:
          'Unable to retrieve location',
        duration: 5,
      });
    });
  }, [fetchWeather]);

  return (
    <div>
      <TitleRow
        title={title}
        optional={optional}
      />
      <Row style={{ marginTop: 15 }}>
        <Col>
          { (isEditable && !isDisplay) && (
            <Row>
              <Col>
                <Row>
                  <Col>
                    <OnTraccrTextInput
                      placeholder="Location"
                      onChange={onLocationValueChange}
                      value={locationValue}
                    />
                  </Col>
                  <Col
                    style={{
                      paddingTop: 3,
                      paddingLeft: 5,
                    }}
                  >
                    <Button
                      onClick={setManualLocation}
                      icon={(
                        <SendOutlined
                          style={{
                            color: (locationValue && locationValue.length) ? Colors.ONTRACCR_RED : 'lightgray',
                          }}
                        />
                      )}
                      disabled={!responding || loading || !locationValue || !locationValue.length}
                    />
                  </Col>
                </Row>
              </Col>
              <Col
                style={{
                  paddingTop: 3,
                  paddingLeft: 5,
                }}
              >
                <Button
                  icon={(<AimOutlined />)}
                  onClick={setUserLocation}
                  disabled={!responding || loading}
                />
              </Col>
            </Row>
          )}
          <Row>
            <OntraccrWeather
              data={responding ? value : previewValue}
              loading={loading}
              showDailySnapshot={showDailySnapshot}
              showWeatherReport={showWeatherReport}
            />
          </Row>
        </Col>
      </Row>
    </div>
  );
}

WeatherFieldPreview.propTypes = {
  previewProps: PropTypes.shape({
    value: PropTypes.shape({}),
  }),
  configProps: PropTypes.shape({
    isEditable: PropTypes.bool,
    optional: PropTypes.bool,
    prefillFromProject: PropTypes.bool,
    title: PropTypes.string,
  }),
  id: PropTypes.string,
  isDisplay: PropTypes.bool,
  projectId: PropTypes.string,
  projects: PropTypes.arrayOf(PropTypes.shape({})),
  responding: PropTypes.bool,
  responses: PropTypes.shape({}),
  setResponses: PropTypes.func,
};

WeatherFieldPreview.defaultProps = {
  previewProps: {},
  configProps: {},
  id: undefined,
  isDisplay: undefined,
  projectId: undefined,
  projects: undefined,
  responding: undefined,
  responses: undefined,
  setResponses: undefined,
};

export default withScriptjs(WeatherFieldPreview);
