import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Table } from 'antd';
import DisplayText from '../../../common/text/DisplayText';
import { getLinkId } from '../../formHelpers';
import { isNullOrUndefined } from '../../../helpers/helpers';
import { getEquipmentCustomDataMap } from '../../../equipment/state/equipment.actions';
import { generateResponseMap } from '../../ResponderHelpers';

export default function AttributeTablePreview({
  divisions,
  equipment,
  projectEquipment,
  projectId,
  columns,
  previewProps,
  isDisplay,
  id,
  responses,
  setResponses,
  responding,
  configProps,
  showCondensedView,
}) {
  const {
    requiredColumns,
    attrType,
    type,
    linkField,
  } = configProps ?? {};

  const selected = useMemo(() => (
    responding && responses[id]
      ? responses[id].values
      : previewProps.values || []
  ), [
    responding,
    responses,
    previewProps,
  ]);

  const dispatch = useDispatch();
  const projects = useSelector((state) => state.projects.projects);

  const divSet = useMemo(() => new Set(divisions), [divisions]);

  const [lastProjectId, setLastProjectId] = useState();

  const linkedProjectId = useMemo(() => (
    getLinkId({ responses, defaultValue: projectId, linkField })
  ), [responses, projectId, linkField]);

  const setAttr = (val) => {
    if (!setResponses) return;
    const oldValues = responses[id] ? responses[id].values : [];
    const newValues = isNullOrUndefined(val) ? [] : val;
    if (newValues !== oldValues) {
      setResponses((oldResponses = {}) => ({
        ...oldResponses,
        [id]: {
          ...(oldResponses[id]),
          values: newValues,
        },
      }));
    }
  };

  useEffect(() => {
    const update = async () => {
      switch (attrType) {
        case 'Project': {
          if (selected?.length && linkedProjectId && !lastProjectId) {
            setLastProjectId(linkedProjectId);
            break;
          }
          if (linkedProjectId === lastProjectId) break;
          if (projects && linkedProjectId) {
            const project = projects.find((r) => r.id === linkedProjectId);
            if (project) {
              if (type === 'equipment') {
                const filteredEquipment = equipment.filter((eq) => (
                  eq.active
                    && eq.divisionIds.some((divisionId) => divSet.has(divisionId))
                    && !!projectEquipment[linkedProjectId]?.[eq.id]
                ));
                const res = await dispatch(
                  getEquipmentCustomDataMap({ projectId: linkedProjectId }),
                );
                const { customDataMap } = res || {};
                const filteredEquipmentWithResponses = filteredEquipment.map((eq) => {
                  const { sections } = customDataMap[eq.id] ?? {};
                  const responseMap = sections ? generateResponseMap(sections) : {};
                  return {
                    ...eq,
                    customData: responseMap,
                  };
                });
                setAttr(filteredEquipmentWithResponses);
              } else {
                setAttr([]);
              }
            }
            setLastProjectId(linkedProjectId);
          }
          break;
        }
        default:
          break;
      }
    };
    update();
  }, [
    selected,
    attrType,
    type,
    linkedProjectId,
    lastProjectId,
    equipment,
    projects,
  ]);

  const dataSource = useMemo(() => (
    isDisplay && !responding
      ? previewProps.values || []
      : selected
  ), [isDisplay, responding, previewProps, selected]);

  const tableColumns = useMemo(() => {
    const cols = columns.map((col) => ({
      title: <div className={requiredColumns && 'form-required-field'}>{col.name}</div>,
      dataIndex: col.key,
      render: (text, record) => (
        col.key.startsWith('field-')
          ? record.customData?.[col.key]?.value
          : record[col.key]
      ),
    }));
    return cols;
  }, [isDisplay, columns, requiredColumns]);

  return (
    <Row style={{ marginTop: showCondensedView ? 0 : 15 }}>
      { !showCondensedView || dataSource?.length ? (
        <Table
          style={{ width: '100%', overflow: 'auto' }}
          columns={tableColumns}
          size="small"
          pagination={false}
          dataSource={dataSource}
        />
      ) : (
        <DisplayText title="No Values" style={{ marginBottom: 0 }} />
      )}
    </Row>
  );
}

AttributeTablePreview.propTypes = {
  columns: PropTypes.array,
  configProps: PropTypes.shape({}),
  divisions: PropTypes.array,
  equipment: PropTypes.array,
  equipmentTypeMap: PropTypes.shape({}),
  id: PropTypes.string,
  isDisplay: PropTypes.bool,
  previewProps: PropTypes.shape({}),
  projectEquipment: PropTypes.shape({}),
  projectId: PropTypes.string,
  responding: PropTypes.bool,
  responses: PropTypes.shape({}),
  setResponses: PropTypes.func,
  showCondensedView: PropTypes.bool,
};

AttributeTablePreview.defaultProps = {
  columns: [],
  previewProps: {},
  id: null,
  isDisplay: false,
  setResponses: null,
  responses: {},
  responding: false,
  projectId: null,
  divisions: [],
  configProps: {},
  showCondensedView: false,
  projectEquipment: {},
  equipmentTypeMap: {},
};
