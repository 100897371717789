import React, { useState } from 'react';
import axios from 'axios';
import { Row, PageHeader, Col, Typography } from 'antd';
import analytics from "../../helpers/Analytics";
import DemoForm from './DemoForm';

import colors from '../../constants/Colors';
import { request } from '../../helpers/requests';

const {
  Text,
} = Typography;

export default ({
  history,

}) => {
  const [createLoading,setCreateLoading] = useState(false);
  const [deleteLoading,setDeleteLoading] = useState(false);
  return (
    <div id ='login'> 
    <Row style={{ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex:0 }} type="flex" justify="center" align="middle">
      <Col style={{
        backgroundColor: colors.ONTRACCR_BLACK,
        borderRadius: 30,
        borderWidth:1,
        borderStyle:'solid',
        padding: '20px 40px'
        }}>
        <Row style={{ width: '100%', color:'white' }} justify='center'>
          <Text style={{
            fontFamily:'roboto-regular',
            fontSize:20,
            color:'white',
            marginBottom:40,
          }}>
            Try OnTraccr
          </Text>
        </Row>

        <DemoForm 
          createLoading={createLoading}
          deleteLoading={deleteLoading}
        handleSubmit={async ({email, secret}) => {
          setCreateLoading(true);
          const payload = {
            email,
            secret
          };
          analytics.track('Demo/Requested', {email})
          await request({
            call:axios.post('/demo',payload),
            successMsg:'Demo Account Successfully Created!',
            errMsg:'Demo Account Creation Failed.'
          });
          setCreateLoading(false);
        }}
        handleRemove={async ({email, secret}) => {
          setDeleteLoading(true);
          const payload = {
            email,
            secret
          };
          await request({
            call:axios.post('/demo/remove',payload),
            successMsg:`Demo Account ${email} Successfully Removed!`,
            errMsg:'Demo Account Remove Failed.'
          });
          setDeleteLoading(false);
        }}
        />
      </Col>

    </Row>
    <PageHeader
      className='register-back'
      onBack={() => history.replace('/login')}
      title='Back to Login'
    />
    
    </div>
  );
}
