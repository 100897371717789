import React, {
  useState,
  useCallback,
  useEffect,
} from 'react';
import {
  Drawer,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { submitProjectScheduleOfValues } from '../state/projects.actions';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import LiveFeedFileAddButton from '../../dashboard/LiveFeed/LiveFeedFileAddButton';
import PhotoReel from '../../common/photos/PhotoReel';
import LiveFeedFileUpload from '../../dashboard/LiveFeed/LiveFeedFileUpload';
import { downloadFile } from '../../files/fileHelpers';
import FullPhoto from '../../common/photos/FullPhoto';
import ScheduleOfValuesTable from './ScheduleOfValuesTable';

const ScheduleOfValuesSubmissionReview = ({
  projectId,
  baseContractValues,
  changeOrderValues,
  nonHoldbackValues,
  liveSummaryValues,
  sectionValueMap,
  currentHoldbackPercentage,
  subContractsWithTotalChanges,
  visible,
  onClose,
  files,
  onPreviewClick,
  removeFile,
  addFile,
  setFiles,
  selectedFile,
  clearSelectedFile,
  updateFile,
  onSubmitSuccess,
  subContractCOs,
}) => {
  const dispatch = useDispatch();

  const [ notesValue, setNotesValue ] = useState('');
  const [ isLoading, setIsLoading ] = useState(false);
  const [ showFileUpload, setShowFileUpload ] = useState(false);

  const { projectName = '' } = useSelector((state) => {
    const currentProject = state.projects.projects.find(project => project.id === projectId);
    return {
      projectName: currentProject && currentProject.name,
    };
  });

  const toggleFileUpload = () => setShowFileUpload(!showFileUpload);

  const onSubmit = useCallback(async (e) => {
    if (isLoading) {
      return;
    }

    const sovSnapshot = JSON.parse(JSON.stringify({
      baseContractValues,
      changeOrderValues,
      liveSummaryValues,
      nonHoldbackValues,
      sectionValueMap,
      currentHoldbackPercentage,
      subContractValues: subContractsWithTotalChanges,
      subContractCOs,
    }));

    setIsLoading(true);
    const result = await dispatch(submitProjectScheduleOfValues(projectId, {
      notes: notesValue,
    }, projectName, files));

    setIsLoading(false);

    if (result) {
      setNotesValue('');
      setFiles([]);
      onClose();
      onSubmitSuccess(sovSnapshot);
    }
  }, [
    notesValue,
    projectId,
    isLoading,
    files,
    baseContractValues,
    changeOrderValues,
    liveSummaryValues,
    nonHoldbackValues,
    sectionValueMap,
    currentHoldbackPercentage,
    subContractsWithTotalChanges,
    sectionValueMap,
  ]);

  useEffect(() => {
    if (!visible) setNotesValue('');
  },[visible]);

  return (
    <Drawer
      title={'Review Changes'}
      visible={visible}
      onClose={onClose}
      width={1300}
      push={false}
      className='schedule-of-values-review-drawer'
      bodyStyle={{ paddingBottom: 53}}
    >
      <div>
        <div>
          <p>Please note that all invoice amounts will be zeroed out after submission.</p>
          <br/>
          <h3>Notes:</h3>
          <br/>
          <OnTraccrTextInput
            style={{ width: '100%', paddingRight: 24 }}
            textarea
            resize='none'
            autoSize
            value={notesValue}
            onChange={(e) => setNotesValue(e.target.value)}
          />
          <LiveFeedFileAddButton
            open={true}
            onClick={toggleFileUpload}
            style={{
              left: 'calc(100% - 30px)',
              position: 'relative',
              top: -30,
            }}
          />
          {files.length > 0 && open &&
            <div className='livefeed-file-reel-container'>
              <PhotoReel
                files={files}
                shadow
                small
                onDelete={removeFile}
                onClick={onPreviewClick}
              />
            </div>
          }
        </div>
        <br/>
        <ScheduleOfValuesTable
          readOnly
          baseContractValues={baseContractValues}
          changeOrderValues={changeOrderValues}
          nonHoldbackValues={nonHoldbackValues}
          liveSummaryValues={liveSummaryValues}
          sectionValueMap={sectionValueMap}
          subContractsWithTotalChanges={subContractsWithTotalChanges}
          currentHoldbackPercentage={currentHoldbackPercentage}
          files={files}
          onPreviewClick={onPreviewClick}
          removeFile={removeFile}
          addFile={addFile}
          setFiles={setFiles}
          selectedFile={selectedFile}
          clearSelectedFile={clearSelectedFile}
          updateFile={updateFile}
          subContractCOs={subContractCOs}
        />
      </div>
      <div className='footer'>
        <OnTraccrButton title="Cancel" type='back' onClick={onClose}/>
        <OnTraccrButton title='Confirm' onClick={onSubmit} loading={isLoading}/>
      </div>
      <LiveFeedFileUpload
        visible={showFileUpload}
        onUploadEnd={toggleFileUpload}
        addFile={addFile}
        style={{ zIndex: 2 }}
        height={'calc(100% - 300px)'}
        customProps={{ getContainer: false }}
      />
      <FullPhoto
        {...selectedFile ?? {}}
        onClose={clearSelectedFile}
        onDownload={() => {
          if (selectedFile && selectedFile.fullPath) {
            downloadFile({ fileDetails: selectedFile });
          } else if (selectedFile.file) {
            downloadFile({ fileObject: selectedFile.file });
          }
        }}
        onSave={updateFile}
      />
    </Drawer>
  );

};

export default ScheduleOfValuesSubmissionReview;