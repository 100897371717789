import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Form } from 'antd';
import PropTypes from 'prop-types';

import SimpleFormModal from '../../common/modals/SimpleFormModal';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import { createScheduleOfValueSection, updateScheduleOfValueSection } from '../state/projects.actions';
import { isNullOrUndefined } from '../../helpers/helpers';

export default function ScheduleOfValuesAddSectionModal({
  section,
  visible,
  onClose,
  orderIndex,
  projectId,
  templateId,
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!section) form.resetFields();
    else form.setFieldsValue(section);
  }, [section]);

  const onSave = async () => {
    const payload = form.getFieldsValue();
    let result;

    if (section) {
      const updatePayload = { ...payload };
      if (!isNullOrUndefined(updatePayload.shouldBeInCalculations)) {
        updatePayload.shouldBeInCalculations = !!updatePayload.shouldBeInCalculations;
      }

      result = await dispatch(updateScheduleOfValueSection({
        projectId,
        templateId,
        sectionId: section.id,
        payload: updatePayload,
      }));
    } else {
      result = await dispatch(createScheduleOfValueSection({
        projectId,
        templateId,
        payload: { ...payload, orderIndex },
      }));
    }

    if (result) onClose();
  };

  return (
    <SimpleFormModal
      title={`${section ? 'Edit' : 'Add'} Section`}
      visible={visible}
      onSave={onSave}
      onClose={onClose}
    >
      <div style={{ width: '100%' }}>
        <Form form={form}>
          <Form.Item
            name="name"
            label="Name"
            style={{ width: '100%' }}
          >
            <OnTraccrTextInput placeholder="Enter Name" />
          </Form.Item>
          <Form.Item
            name="shouldBeInCalculations"
            label="Should be in calculations"
            valuePropName="checked"
          >
            <Checkbox />
          </Form.Item>
        </Form>
      </div>
    </SimpleFormModal>
  );
}

ScheduleOfValuesAddSectionModal.propTypes = {
  section: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    shouldBeInCalculations: PropTypes.bool,
    orderIndex: PropTypes.number,
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  orderIndex: PropTypes.number,
  projectId: PropTypes.string,
  templateId: PropTypes.string,
};

ScheduleOfValuesAddSectionModal.defaultProps = {
  section: null,
  visible: false,
  orderIndex: 0,
  projectId: null,
  templateId: null,
};
