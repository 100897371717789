import React from 'react';
import { Popconfirm } from 'antd';
import { RetweetOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';
export default ({
  title,
  name,
  onUnLink
}) => (
  <Popconfirm
    placement='right'
    title={
      <div style={{ width: 300}}>
        <b>{`Unlink ${name}?`}</b>
        <br/><br/>
        Entries that are unlinked can no longer be synced between Ontraccr and {title}.
      </div>
    }
    onConfirm={onUnLink}
    okText='Unlink'
    cancelText='Cancel'
  >
    <BorderlessButton
      iconNode={<RetweetOutlined style={{ margin: 0}}/>}
      style={{ padding: 0 }}
    /> 
  </Popconfirm>
);