import React from 'react';
import moment from 'moment';
import { Table, Tag, Popover, Row, Divider, Col } from 'antd';

import DisplayText from '../../common/text/DisplayText';
import colors from '../../constants/Colors';

const isValid = (d) => d && moment.isMoment(d) && d.isValid();

const getActualProgressColours = (projectSetupCorrectly) => {
  if(projectSetupCorrectly) {
    return 'forestgreen';
  } else {
    return 'gold';
  }
}

const monthDiff = (d1,d2) => Math.ceil(d2.diff(d1,'month',true));


const PopRow = ({ title, text }) => (
  <Row style={{ width: '100%'}} justify='space-between'>
    <Col>
      <DisplayText title={title} style={{ fontFamily: 'roboto-medium'}}/>
    </Col>
    <Col>
      <DisplayText title={text}/>
    </Col>
  </Row>
);
const PopContent = ({ project }) => (
  <Row style={{ maxWidth: 300}}>
    <Row>
      <DisplayText title={project.name} style={{ fontSize: 16, margin: 0, fontFamily: 'roboto-bold' }}/>
    </Row>
    <Divider style={{ margin: 10, padding: 0 }}/>
    {isValid(project.startDate) && <PopRow title='Start Date' text={project.startDate.format('MMM Do YYYY')}/>}
    {isValid(project.endDate) && <PopRow title='End Date' text={project.endDate.format('MMM Do YYYY')}/>}
    {project.labourHours && <PopRow title='Estimated Hours' text={project.labourHours}/>}
    {project.actualHours && <PopRow title='Actual Hours' text={project.actualHours.toFixed(1)}/>}
  </Row>
);

const renderContent = (date) => (project) => {
 
  const projectSetupCorrectly = project.startDate && project.endDate;

  const mainTagStyle = {
    position:'relative',
    textOverflow:'ellipsis',
    width: '100%',
    textAlign:'center',
    overflow:'auto'
  };
  
  if(projectSetupCorrectly) {
    mainTagStyle.color = 'white';
    mainTagStyle.backgroundColor = colors.ONTRACCR_TITLE_BLACK;
  }

  const {
    minDate,
    maxDate,
    lastTask,
    firstTask,
    totalDuration,
    startDate,
    endDate,
  } = project;

  const hasHours = firstTask && lastTask;

  let actualStart;
  let actualWidth;
  if(hasHours) {
    actualStart = (monthDiff(minDate,firstTask) / totalDuration) * 100;
    actualWidth = (((totalDuration - monthDiff(lastTask,maxDate)) / totalDuration) * 100) - actualStart;
  }

  let estimatedStart = 0;
  let estimatedWidth = 100;
  if(projectSetupCorrectly && isValid(startDate) && isValid(endDate)) {
    estimatedStart = (monthDiff(minDate,startDate) / totalDuration) * 100;
    estimatedWidth = (((totalDuration- monthDiff(endDate,maxDate)) / totalDuration) * 100) - estimatedStart;
  }
  

  const obj = {
    children: (
      <div style={{width:'100%'}}>
        <Popover
          content={<PopContent project={project}
          percentComplete={project.percentComplete}
        />}>
        <Tag
          style={{
            ...mainTagStyle,
            left:`${Math.trunc(estimatedStart)}%`,
            width:`${Math.trunc(estimatedWidth)}%`,
          }}>{project.name}
          </Tag>
        </Popover>
        {hasHours && <Tag
          style={{
            height:1,
            position:'relative',
            left:`${Math.trunc(actualStart)}%`,
            width:`${Math.trunc(actualWidth)}%`,
            borderColor:getActualProgressColours(projectSetupCorrectly),
          }}/>}
      </div>
    ),
    props: {},
    key:project.id,
  };
  
  if(date.isSame(project.minDate,'month')) {
    obj.props.colSpan = project.totalDuration;
  } else if (date.isAfter(project.minDate,'month') && date.isSameOrBefore(project.maxDate,'month')) {
    obj.props.colSpan = 0;
  } else {
    obj.children = ''
  }
  return obj;
};

const generateCols = ({ minProjectStart, maxProjectEnd }) => {
  const cols = [];
  const timeIndex = moment.isMoment(minProjectStart) ? 
    minProjectStart.clone().subtract(1,'M') : moment();
  while(timeIndex.isBefore(maxProjectEnd)) {
    cols.push({
      title: timeIndex.format('MMM YY'),
      dataIndex: '',
      width:150,
      render:renderContent(timeIndex.clone())
    });
    timeIndex.add(1,'M');
  }
  return cols;
};

export default ({ minProjectStart, maxProjectEnd, projects, height }) => {
  return (
    <Table
      style={{width:'100%'}}
      columns={generateCols({ minProjectStart, maxProjectEnd })}
      dataSource={projects}
      pagination={false}
      expandable={{
        expandedRowRender: record => <p style={{ margin: 0 }}>{record.description}</p>,
        rowExpandable: record => record.name !== 'Not Expandable',
        expandRowByClick:true,
      }}
      scroll={{
        y:height,
        x:'max-content'
      }}
    />
  )
}
