import React, { useState, useRef } from 'react';
import { Popconfirm, Row } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import useOnClickOutside from '../../common/hooks/useOnClickOutside';

function ScheduleOfValuesInput({
  title,
  showPopover,
  onCancel,
  onConfirm,
  onChange,
  onBlur,
  value,
  hasChildren,
  formatter,
  max,
  min = 0,
  style = {},
  type,
  placeholder,
  okText = 'Update',
  placement,
}) {
  const popconfirmRef = useRef(null);
  const inputRef = useRef(null);
  const [textInput, setTextInput] = useState(null);

  useOnClickOutside(document, popconfirmRef, (e) => {
    if (showPopover) {
      // We do not want to close the popover if the user clicked on the relevant input
      const notification = document.querySelector('.ant-notification');
      if (notification && notification.contains(e.target)) {
        return;
      }

      if (inputRef?.current) {
        if (inputRef.current.input.contains(e.target)) {
          return;
        }

        inputRef.current.blur();
      }

      if (textInput) {
        if (textInput.input.contains(e.target)) {
          return;
        }
      }

      onCancel();
    }
  });

  const body = (
    type === 'number'
      ? (
        <OnTraccrNumberInput
          min={min}
          max={max}
          value={value}
          formatter={formatter}
          onChange={onChange}
          onBlur={onBlur}
          style={{
            borderRadius: 6,
            borderColor: '#323232',
            ...style,
          }}
          onPressEnter={onConfirm}
          ref={inputRef}
        />
      )
      : (
        <OnTraccrTextInput
          value={value}
          placeholder={placeholder}
          onChange={onChange}
          onBlur={onBlur}
          onPressEnter={onConfirm}
          onRef={setTextInput}
          style={{
            ...style,
          }}
        />
      )
  );

  return (
    <div
      draggable
      onDragStart={(e) => {
        e.preventDefault();
        e.stopPropagation();
      }}
    >
      <Popconfirm
        visible={showPopover}
        title={title}
        okText={okText}
        onConfirm={onConfirm}
        onCancel={onCancel}
        ref={popconfirmRef}
        placement={placement}
      >
        {
        hasChildren
          ? (
            <Row align="middle" style={{ height: '100%', paddingLeft: 10 }}>
              {`${value}%`}
            </Row>
          )
          : body
        }
      </Popconfirm>
    </div>
  );
}

ScheduleOfValuesInput.propTypes = {
  title: PropTypes.string.isRequired,
  showPopover: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  value: PropTypes.string.isRequired,
  hasChildren: PropTypes.bool,
  formatter: PropTypes.func,
  max: PropTypes.number,
  min: PropTypes.number,
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  type: PropTypes.string,
  placeholder: PropTypes.string,
  okText: PropTypes.string,
  placement: PropTypes.string,
};

ScheduleOfValuesInput.defaultProps = {
  showPopover: false,
  onBlur: undefined,
  hasChildren: false,
  formatter: undefined,
  max: undefined,
  min: undefined,
  style: {},
  type: undefined,
  placeholder: undefined,
  okText: 'Update',
  placement: undefined,
};

export default ScheduleOfValuesInput;
