import { useMemo } from 'react';
import { useSelector } from 'react-redux';

export default function useDivisionFormTemplates(divisionId) {
  const formTemplates = useSelector((state) => state.forms.templates);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  const divTemplates = useMemo(() => (
    Object.values(formTemplates).filter((template) => (
      template.notDeleted
      && template.active
      && (
        (divisionId && template.divisionId === divisionId)
        || (!divisionId && selectedDivisions.has(template.divisionId))
      )
    ))
  ), [formTemplates, selectedDivisions, divisionId]);
  return divTemplates;
}
