// Import Components:
import OnTraccrButton from "../../common/buttons/OnTraccrButton";
import OnTraccrTextInput from "../../common/inputs/OnTraccrTextInput";
import LiveFeedFileAddButton from "../../dashboard/LiveFeed/LiveFeedFileAddButton";
import PhotoReel from "../../common/photos/PhotoReel";
import LiveFeedFileUpload from "../../dashboard/LiveFeed/LiveFeedFileUpload";

const HistoryDrawerHeader = ({
  onSubmit,
  isLoading,
  notesValue,
  setNotesValue,
  toggleFileUpload,
  files,
  removeFile,
  onPreviewClick,
  showFileUpload,
  addFile,
}) => {
  return (
    <>
      <div className="history-notes-header">
        <h3>Notes:</h3>
        <OnTraccrButton title='Save' onClick={onSubmit} loading={isLoading}/>
      </div>
      <div className="history-notes-container">
        <OnTraccrTextInput
          style={{ width: '100%', paddingRight: 24 }}
          textarea
          resize='none'
          autoSize
          value={notesValue}
          onChange={(e) => setNotesValue(e.target.value)}
        />
        <LiveFeedFileAddButton
          open={true}
          onClick={toggleFileUpload}
          style={{
            left: 'calc(100% - 30px)',
            position: 'relative',
            top: -30,
          }}
        />
        {files.length > 0 && open &&
          <div className='livefeed-file-reel-container'>
            <PhotoReel
              files={files}
              shadow
              small
              onDelete={removeFile}
              onClick={onPreviewClick}
            />
          </div>
        }
      </div>
      <LiveFeedFileUpload 
        visible={showFileUpload}
        onUploadEnd={toggleFileUpload}
        addFile={addFile}
        style={{ zIndex: 2 }}
        height={'calc(100% - 300px)'}
        customProps={{ getContainer: false }}
      />
    </>
  );
}

export default HistoryDrawerHeader;