import React, {
  useEffect, useMemo, useState, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Checkbox, Row, Table, notification,
} from 'antd';
import PropTypes from 'prop-types';

import OnTraccrNumberInput from '../../common/inputs/OnTraccrNumberInput';
import Debouncer from '../../helpers/Debouncer';

import CompanySettingsCard from '../CompanySettingsCard';
import CompanyEditRow from '../CompanyEditRow';

import { getFormTypes, createCustomType } from '../../forms/state/forms.actions';
import { saveCompanySettings } from '../state/settings.actions';

import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import SimpleFormModal from '../../common/modals/SimpleFormModal';

const debouncer = new Debouncer();

const cols = (onAnchorChange, onCheckChange) => [{
  title: 'Type',
  dataIndex: 'name',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
}, {
  title: 'Anchor Number',
  dataIndex: 'anchor',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
  render: (defaultValue, record) => (
    <OnTraccrNumberInput
      min={0}
      defaultValue={defaultValue}
      onChange={onAnchorChange(record)}
    />
  ),
}, {
  title: 'Project Unique',
  dataIndex: 'projectUnique',
  align: 'center',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
  render: (_, record) => (
    <Checkbox
      onChange={onCheckChange({ record, key: 'formAnchorsUnique' })}
      style={{ marginBottom: 10 }}
      checked={record.projectUnique}
    />
  ),
}, {
  title: 'Add Project Number',
  dataIndex: 'addProjectNumber',
  align: 'center',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
  render: (_, record) => (
    <Checkbox
      onChange={onCheckChange({ record, key: 'formAnchorsAddProject' })}
      style={{ marginBottom: 10 }}
      checked={record.addProjectNumber}
      disabled={!record.projectUnique}
    />
  ),
}];

export default function FormTypes({
  settings = {},
}) {
  const {
    formAnchors = {},
    formAnchorsUnique = {},
    formAnchorsAddProject = {},
  } = settings;
  const dispatch = useDispatch();
  const formTypes = useSelector((state) => state.forms.types);
  useEffect(() => {
    dispatch(getFormTypes());
  }, [dispatch]);

  const onAnchorChange = useCallback((type) => (value) => {
    if (Number.isNaN(value)) return;
    debouncer.debounce(() => {
      const { id: typeId } = type;
      const {
        [typeId]: oldValue,
      } = formAnchors;
      if (oldValue !== value) {
        dispatch(saveCompanySettings({
          formAnchors: {
            ...formAnchors,
            [typeId]: value,
          },
        }));
      }
    }, [500]);
  }, [debouncer, formAnchors, dispatch]);

  const onCheckChange = useCallback(({ record: type, key }) => async () => {
    const { id: typeId } = type;
    const oldSettings = settings[key] ?? {};
    const {
      [typeId]: oldValue,
    } = oldSettings;
    dispatch(saveCompanySettings({
      [key]: {
        ...oldSettings,
        [typeId]: !oldValue,
      },
    }));
  }, [dispatch, debouncer, settings]);

  const columns = useMemo(() => cols(onAnchorChange, onCheckChange), [
    onAnchorChange,
    onCheckChange,
  ]);
  const data = useMemo(() => formTypes.map((type) => {
    const { id: typeId } = type;
    const {
      [typeId]: settingsValue = 1,
    } = formAnchors;
    const {
      [typeId]: isProjectUnique = false,
    } = formAnchorsUnique;
    const {
      [typeId]: addProjectNumber = false,
    } = formAnchorsAddProject;
    return {
      ...type,
      anchor: settingsValue,
      projectUnique: isProjectUnique,
      addProjectNumber,
    };
  }), [formTypes, formAnchors, formAnchorsUnique, formAnchorsAddProject]);

  const [modalOpen, setModalOpen] = useState(false);
  const [addPayload, setAddPayload] = useState({});

  const openModal = useCallback(() => setModalOpen(true), []);
  const closeModal = useCallback(() => {
    setModalOpen(false);
    setAddPayload({});
  }, []);

  const onSave = useCallback(async () => {
    if (!addPayload.name?.trim().length) {
      notification.warn({
        message: 'Warning',
        description: 'Please enter a name for your custom type',
      });
    }
    if (await dispatch(createCustomType({ ...addPayload }))) {
      closeModal();
    }
  }, [dispatch, closeModal, addPayload]);

  const onFormChange = useCallback((property, newValue) => {
    setAddPayload({
      ...addPayload,
      [property]: newValue,
    });
  }, [addPayload]);

  const onChange = useCallback(({ target: { value: newVal } }) => onFormChange('name', newVal));

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
        marginLeft: 20,
        marginTop: 20,
        flex: 1,
        display: 'flex',
      }}
    >
      <CompanySettingsCard
        title="Form Types"
        containerStyle={{
          height: 'auto',
        }}
        leftHeaderView={(
          <OnTraccrButton
            title="Add Custom Type"
            onClick={openModal}
          />
      )}
      >
        <SimpleFormModal
          title="Add Custom Type"
          visible={modalOpen}
          onSave={onSave}
          onClose={closeModal}
          disabled={!addPayload?.name?.length}
        >
          <div style={{ width: '100%', display: 'inline-flex' }}>
            <OnTraccrTextInput
              onChange={onChange}
              value={addPayload.name}
              placeholder="Enter Custom Name"
            />
          </div>
        </SimpleFormModal>
        <CompanyEditRow
          title="Set Form Number Anchors"
          helpText={(
            <div>
              Form number anchors set the baseline number for each form type.
              <br />
              <br />
              Ontraccr will increment the number of each form by type (e.g. Invoice).
              <br />
              <br />
              If Project Unique is selected, numbers for that type will increment uniquely per-project
            </div>
        )}
          divider={false}
        />
        <Table
          columns={columns}
          dataSource={data}
          size="small"
          pagination={false}
          style={{
            width: '100%',
            height: '100%',
            borderRadius: 8,
          }}
          rowKey="id"
        />
      </CompanySettingsCard>
    </Row>
  );
}

FormTypes.propTypes = {
  settings: PropTypes.object, // eslint-disable-line react/forbid-prop-types
};

FormTypes.defaultProps = {
  settings: {},
};
