import React from 'react';
import { useSelector } from 'react-redux';

import WorkflowHandle from './WorkflowHandle'

import {
  FORMS_WORKFLOW_STEP_3,
  FORMS_WORKFLOW_STEP_3_TEXT,
  FORMS_WORKFLOW_STEP_4,
} from '../../nux/nux.constants';

import NuxPopover from '../../nux/NuxPopover';

export default ({ isExternalForm }) => () => {
  const activeNuxAction = useSelector(state => state.nux.activeNuxAction);
  const isNuxStep3 = activeNuxAction === FORMS_WORKFLOW_STEP_3;
  return (
    <div className='workflow-complete-node'>
      <NuxPopover
        placement='right'
        visible={isNuxStep3}
        text={FORMS_WORKFLOW_STEP_3_TEXT}
        nextAction={FORMS_WORKFLOW_STEP_4}
      >
        User Submits Form
      </NuxPopover>

      { !isExternalForm && <WorkflowHandle type='target' position='top' disabled/> }
      <WorkflowHandle type='source' position='bottom'/>
    </div>
  )
};