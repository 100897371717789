import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';

import SettingsCardHeader from '../SettingsCardHeader';
import CompanySettingsCard from '../CompanySettingsCard';
import CompanyEditRow from '../CompanyEditRow';

import Debouncer from '../../helpers/Debouncer';

import { profileKeys } from '../../clientPortal/clientPortal.constants';

import { updateClientPortalSettings } from '../../clientPortal/state/clientPortal.actions';

const selectOpts = profileKeys.map(({ key, title }) => ({ label: title, value: key }));

const debouncer = new Debouncer();
export default () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const portalSettings = useSelector(state => state.clientPortal.settings);

  const {
    profileFields = [],
  } = portalSettings;


  const [selected, setSelected] = useState(profileFields);

  const onChange = useCallback(async (newValues) => {
    const previous = [...selected];
    setSelected(newValues);
    const passed = await debouncer.debounce(() => (
      dispatch(updateClientPortalSettings({
        ...portalSettings,
        profileFields: newValues
      }))
    ), 500);
    if (!passed) setSelected(previous);
  },[selected, portalSettings]);

  useEffect(() => {
    setSelected(portalSettings.profileFields ?? []);
  },[portalSettings]);

  return (
    <div style={{ marginTop: 10 }}>
      <SettingsCardHeader title='Profile' />
      <CompanySettingsCard>
        <CompanyEditRow
          title='Fields'
          helpText={`Configure which fields show in the portal's ${t('Customer').toLowerCase()} profile`}
          style={{ paddingLeft: 10, paddingRight: 20 }}
          divider={false}
        />
        <div id='client-portal-profile-fields-select'>
          <Select
            mode='multiple'
            options={selectOpts}
            optionFilterProp='label'
            style={{ width: '100%' }}
            value={selected}
            onChange={onChange}
          />
        </div>
      </CompanySettingsCard>
    </div>
  )
}