import React, { useCallback, useMemo, useEffect } from 'react';
import {
  Checkbox,
  Descriptions,
  Select,
} from 'antd';
import PropTypes from 'prop-types';

import { stripText } from './excelHelpers';

const { Option } = Select;

const matchHeader = (headerOptions, key) => {
  let match;
  headerOptions?.forEach((dh) => {
    const rk = stripText(key);
    const strippedTitle = stripText(dh.title);
    if (rk === strippedTitle) match = dh.key;
  });
  return match;
};

export default function SheetHeaderMatcher({
  selectedSheet = [],
  headerMapping,
  setHeaderMapping,
  headerOptions = [],
  shouldSaveMapping = false,
  setShouldSaveMapping,
}) {
  const onSelect = useCallback((key) => (newValue) => {
    setHeaderMapping({
      ...headerMapping,
      [key]: newValue,
    });
  }, [headerMapping]);

  const headers = useMemo(() => {
    if (!selectedSheet || selectedSheet.length === 0) return [];
    // This will be slow for big sheets that have many sparse rows
    const firstRow = selectedSheet.find((row = {}) => Object.keys(row).length > 0);
    return Object.keys(firstRow);
  }, [selectedSheet]);

  useEffect(() => {
    const initialMapping = {};
    headers.forEach((header) => {
      const foundMatch = matchHeader(headerOptions, header);
      if (foundMatch) initialMapping[foundMatch] = header;
    });
    setHeaderMapping(initialMapping);
  }, [headers, headerOptions]);

  return (
    <>
      <Descriptions title="Select Column Headers">
        {
          headerOptions.map((defaultHeader) => (
            <Descriptions.Item label={defaultHeader.title}>
              <Select
                value={headerMapping[defaultHeader.key]}
                style={{ width: 120 }}
                onSelect={onSelect(defaultHeader.key)}
              >
                {headers.map((header) => (
                  <Option value={header} key={header}>{header}</Option>
                ))}
              </Select>
            </Descriptions.Item>
          ))
        }
      </Descriptions>
      { !!setShouldSaveMapping && (
        <Checkbox
          checked={shouldSaveMapping}
          onChange={(e) => setShouldSaveMapping(e.target.checked)}
          style={{ marginTop: 10, marginBottom: 10 }}
        >
          Save Mapping
        </Checkbox>
      )}
    </>
  );
}

SheetHeaderMatcher.propTypes = {
  selectedSheet: PropTypes.arrayOf(PropTypes.shape({
    // eslint-disable-next-line react/forbid-prop-types
    [PropTypes.string]: PropTypes.any,
  })),
  headerMapping: PropTypes.shape({
    [PropTypes.string]: PropTypes.string,
  }),
  setHeaderMapping: PropTypes.func,
  headerOptions: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.string,
    title: PropTypes.string,
  })),
  shouldSaveMapping: PropTypes.bool,
  setShouldSaveMapping: PropTypes.func,
};

SheetHeaderMatcher.defaultProps = {
  selectedSheet: [],
  headerMapping: {},
  setHeaderMapping: () => {},
  headerOptions: [],
  shouldSaveMapping: false,
  setShouldSaveMapping: null,
};
