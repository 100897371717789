import React, { useCallback, useState } from 'react';
import {
  Drawer,
  Row,
  Form,
  Checkbox,
  Divider,
  Col,
} from 'antd';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import {
  calculateNewEndDate,
  defaultWorkingDays,
} from './ganttScheduleHelpers';

const CheckboxGroup = Checkbox.Group;

export default function GanttScheduleImportDrawer({
  onClose,
  visible,
  handleSubmit,
  tasksToImport = [],
  existingHolidays = [],
  newHolidays = [],
  existingSettings = {
    workingDays: defaultWorkingDays,
  },
  newSettings = {
    workingDays: defaultWorkingDays,
  },
}) {
  const [checkedList, setCheckedList] = useState([]);
  const [checkedHolidays, setCheckedHolidays] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [indeterminateHoliday, setIndeterminateHoliday] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [checkAllHolidays, setCheckAllHolidays] = useState(false);
  const [overrideRows, setOverrideRows] = useState(false);
  const [overrideHolidays, setOverrideHolidays] = useState(false);
  const [importSettings, setImportSettings] = useState(false);
  const [loading, setLoading] = useState(false);

  const onChange = useCallback((list) => {
    setCheckedList(list);
    setIndeterminate(!!list.length && list.length < tasksToImport.length);
    setCheckAll(list.length === tasksToImport.length);
  }, [tasksToImport]);

  const onHolidayChange = useCallback((list) => {
    setCheckedHolidays(list);
    setIndeterminateHoliday(!!list.length && list.length < newHolidays.length);
    setCheckAllHolidays(list.length === newHolidays.length);
  }, [newHolidays]);

  const onCheckAllChange = useCallback((e) => {
    setCheckedList(e.target.checked ? tasksToImport.map((task) => task.id) : []);
    setIndeterminate(false);
    setCheckAll(e.target.checked);
  }, [tasksToImport]);

  const onHolidayCheckAllChange = useCallback((e) => {
    setCheckedHolidays(e.target.checked ? newHolidays.map((task) => task.id) : []);
    setIndeterminateHoliday(false);
    setCheckAllHolidays(e.target.checked);
  }, [newHolidays]);

  const onOverrideTaskChange = (e) => {
    setOverrideRows(e.target.checked);
  };

  const onOverrideHolidayChange = (e) => {
    setOverrideHolidays(e.target.checked);
  };

  const onSettingsChange = (e) => {
    setImportSettings(e.target.checked);
  };

  const addDays = useCallback((
    date,
    days,
    validDaysInWeek,
    holidaysToAdd,
  ) => calculateNewEndDate(date, days, validDaysInWeek, holidaysToAdd), [existingHolidays]);

  const onSubmit = useCallback(() => {
    setLoading(true);
    const holidays = new Set(checkedHolidays);
    const holidaysToAdd = [];

    newHolidays.forEach((holiday) => {
      if (holidays.has(holiday.id)) {
        holidaysToAdd.push({
          date: holiday.date,
          name: holiday.name,
        });
      }
    });

    const checkedTasks = new Set(checkedList);
    const tasksToSubmit = [];
    tasksToImport.forEach((task) => {
      if (checkedTasks.has(task.id)) {
        const taskStart = DateTime.fromISO(task.start, { zone: 'utc' });
        tasksToSubmit.push({
          type: 'Task',
          name: task.name,
          startDate: taskStart.toSeconds(),
          endDate: addDays(
            taskStart.toSeconds(),
            parseInt(task.duration, 10),
            importSettings
              ? newSettings.workingDays
              : existingSettings.workingDays,
            overrideHolidays
              ? holidaysToAdd
              : existingHolidays.concat(holidaysToAdd),
          ).toSeconds(),
          duration: parseInt(task.duration, 10),
          color: task.color,
          priority: parseInt(task.priority, 10),
          minDate: parseInt(task['thirdDate-constraint'], 10)
            ? DateTime.fromISO(task.thirdDate, { zone: 'utc' }).toSeconds()
            : 0,
          progress: parseInt(task.complete, 10).toFixed(1),
        });
      }
    });

    handleSubmit({
      newRows: tasksToSubmit,
      overrideRows,
      newHolidays: holidaysToAdd,
      overrideHolidays,
      settings: importSettings ? newSettings : {},
    });
  }, [
    checkedList,
    checkedHolidays,
    overrideRows,
    overrideHolidays,
    importSettings,
    existingSettings,
    existingHolidays,
    tasksToImport,
    newHolidays,
  ]);

  return (
    <Drawer
      title="Import"
      visible={visible}
      onClose={onClose}
      width={750}
      maskClosable={false}
      bodyStyle={{ paddingBottom: 60 }}
    >
      <Form
        style={{
          maxWidth: '95%',
        }}
      >
        <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={checkAll}>
          Check all tasks
        </Checkbox>
        <Checkbox
          indeterminate={indeterminateHoliday}
          onChange={onHolidayCheckAllChange}
          checked={checkAllHolidays}
        >
          Check all holidays
        </Checkbox>
        <Checkbox onChange={onSettingsChange} checked={importSettings}>
          Import settings
        </Checkbox>
        <br />
        <Checkbox onChange={onOverrideTaskChange} checked={overrideRows}>
          Replace existing tasks
        </Checkbox>
        <Checkbox onChange={onOverrideHolidayChange} checked={overrideHolidays}>
          Replace existing holidays
        </Checkbox>
        <Divider />
        <h3>Tasks</h3>
        <CheckboxGroup
          value={checkedList}
          onChange={onChange}
        >
          {tasksToImport.map((task) => (
            <Row key={task.id}>
              <Col span={24}>
                <Checkbox value={task.id}>{task.name}</Checkbox>
              </Col>
            </Row>
          ))}
        </CheckboxGroup>
        <Divider />
        <h3>Holidays</h3>
        <CheckboxGroup
          value={checkedHolidays}
          onChange={onHolidayChange}
        >
          {newHolidays.map((holiday) => (
            <Row key={holiday.id}>
              <Col span={24}>
                <Checkbox value={holiday.id}>
                  {`${holiday.name} (${DateTime.fromSeconds(holiday.date, { zone: 'utc' }).toLocaleString(DateTime.DATE_FULL)})`}
                </Checkbox>
              </Col>
            </Row>
          ))}
        </CheckboxGroup>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
        canSubmit={checkedList.length > 0 || checkedHolidays.length > 0}
      />
    </Drawer>
  );
}

GanttScheduleImportDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  tasksToImport: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    start: PropTypes.string.isRequired,
    duration: PropTypes.string.isRequired,
    color: PropTypes.string.isRequired,
    priority: PropTypes.number.isRequired,
    'thirdDate-constraint': PropTypes.string.isRequired,
    thirdDate: PropTypes.string.isRequired,
  })).isRequired,
  existingHolidays: PropTypes.arrayOf(PropTypes.shape({
    date: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  newHolidays: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number.isRequired,
    date: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  })).isRequired,
  existingSettings: PropTypes.shape({
    workingDays: PropTypes.arrayOf(PropTypes.number),
  }),
  newSettings: PropTypes.shape({
    workingDays: PropTypes.arrayOf(PropTypes.number),
  }),
};

GanttScheduleImportDrawer.defaultProps = {
  existingSettings: {
    workingDays: [],
  },
  newSettings: {
    workingDays: [],
  },
};
