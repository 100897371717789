import React from 'react';

import NuxDetailPane from '../NuxDetailPane';

import projectCodePage from '../../assets/images/NUX/projectCodePage.png';
import projectCodeEdit from '../../assets/images/NUX/projectCodeEdit.png';
import { useTranslation } from 'react-i18next';


const getSpecificDesc = (t) => `You can easily add unique cost codes to
a specific ${t('Project')} here.`;

const getProjectDesc = (t) => `In the cost code section (step 2)
of a ${t('Project')}'s profile, you can add existing global codes into
the ${t('Project')} or create unique ${t('Project')} codes.`;


export default function NuxProjectCostcode({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const specificDesc = getSpecificDesc(t);
  const projectDesc = getProjectDesc(t);

  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      style={{
        height: 'calc(100% - 99px)',
        padding:'0px 40px'
      }}
      rows={[{
        title:`Via the '${t('Project')} Specific' Page`,
        description:specificDesc,
        cols:[0,12,12],
        image:{
          src:projectCodePage,
          style:{
            height:100,
            width:176,
          }
        },
      },{
        title:`During ${t('Project')} Creation/Edit`,
        description:projectDesc,
        cols:[0,12,12],
        image:{
          src:projectCodeEdit,
          style:{
            height:75,
            width:253,
          }
        },
      }]}
    />
  );
}
