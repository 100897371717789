import React from 'react'
import './Sidebar.css'
import OnTraccrButton from '../common/buttons/OnTraccrButton'

import Logo from '../common/Logo'

import multiDevicePNG from '../assets/images/multi-device-Q2.png'
import timeTrackingPNG from '../assets/images/welcome-timetracking.png'
import realTimeUpdatesPNG from '../assets/images/welcome-realtimeupdates.png'
import analyticsPNG from '../assets/images/welcome-analytics.png'
import costSavingsPNG from '../assets/images/welcome-costsavings.png'
import analytics from "../helpers/Analytics";
import config from '../config.js'

const createFreeAccount = () => {
    analytics.track('GetStarted/RegisterPage', { Source: 'Ontraccr Sheets' });
    const modifier = config.env === 'prod' ? '' : `alpha.`;
    const url = `https://app.${modifier}ontraccr.com/register`;
    window.open(url, "_blank");   
  }

export default function Sidebar() {
    return(
        <div className="sheetsw-rightBannerWrapper">
			<Logo height={75} />
			<div className="sheetsw-bannerHeader">Simplified Field Management</div>
			<OnTraccrButton title="Create Free Account" type="primary" onClick={createFreeAccount}/>  
			<img src={multiDevicePNG} alt="mobile, tablet, and laptop screens" className="sheetsw-multiDevicePNG"/>
			<div className="sheetsw-bannerFeaturesContainer">
				<img src={timeTrackingPNG} alt="mobile phone"className="sheetsw-timeTrackingPNG"/>
				<div className="sheetsw-bannerText">Mobile Time Tracking</div>
				<img src={realTimeUpdatesPNG} alt="laptop" className="sheetsw-realTimeUpdatesPNG"/>
				<div className="sheetsw-bannerText">Real-Time <br />Communications</div>
				<img src={analyticsPNG} alt="data" className="sheetsw-analyticsPNG"/>
				<div className="sheetsw-bannerText">Actionable Analytics</div>
				<img src={costSavingsPNG} alt="money in jar" className="sheetsw-costSavingsPNG"/>
				<div className="sheetsw-bannerText">Project Costing</div>
			</div>
        </div>
    ) 
}
