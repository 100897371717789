import React from 'react';
import { Row, Col } from 'antd';
import BorderedComponent from '../common/containers/BorderedContainer';
import DisplayText from '../common/text/DisplayText';

import Colors from '../constants/Colors';

export default class CompanySettingsCard extends React.Component {
  render() {
    const {
      title,
      children,
      style = {},
      leftHeaderView,
      onRef,
      containerStyle = {},
    } = this.props;
    return (
      <div style={{ padding: 10, ...style}} ref={onRef}>
        <Row justify='space-between' align='middle' style={{ width: 500 }}>
          <Col>
            <DisplayText title={title} style={{
              fontSize: 16,
              marginBottom: 20,
              marginTop: 20,
              color:Colors.ONTRACCR_GRAY
            }}/>
          </Col>
          <Col>
            {leftHeaderView}
          </Col>
        </Row>

        <BorderedComponent
          containerStyle={{ height: 'inherit' }}
          style={{
            width: 500,
            paddingTop:0,
            paddingBottom:0,
            paddingLeft:30,
            paddingRight:5,
            ...containerStyle
          }}
        >
          {children}
        </BorderedComponent>
      </div>
    );
  }
}