import React from 'react';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';

const options = [
  {
    value: 'dropdown',
    label: 'Dropdown',
  },
  {
    value: 'radio',
    label: 'Radio',
  },
  {
    value: 'checkbox',
    label: 'Checkbox',
  },
];

export default function DropdownFieldStyleSelect({
  value,
  onChange,
}) {
  return (
    <>
      <Row style={{ marginTop: 15 }}>
        Styling options:
      </Row>
      <Row style={{ marginTop: 5 }}>
        <Select
          style={{ width: 350 }}
          options={options}
          value={value}
          defaultValue={options[0].value}
          optionFilterProp="label"
          onChange={onChange}
        />
      </Row>
    </>
  );
}

DropdownFieldStyleSelect.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
};

DropdownFieldStyleSelect.defaultProps = {
  value: 'dropdown',
};