import React, { useState, useEffect } from 'react';
import { Row,Input } from 'antd';
import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import colors from '../constants/Colors';

import { upload } from './state/feedback.actions';

const buttonStyle = {
  borderRadius: 21,
  width: 110,
  height: 42,
  margin:20,
};

const textStyle = {
  fontFamily:'roboto-regular',
  fontSize:14,
};

const inputStyle = { 
  width: 500,
  height: 200,
  borderRadius:20,
  borderColor:colors.ONTRACCR_BLACK,
  margin:20,
  marginTop:10,
  marginBottom:10,  
  resize:'none',
  paddingLeft:15,
  paddingRight:15,
  paddingTop:10,
  paddingBottom:10,
};

export default ({
  afterClose,
  visible,
  onCloseClicked
}) => {
  const [text,setText] = useState();

  useEffect(() => {
    if(!visible) setText();
  },[visible])
  return (
    <CustomModal
      title='App Feedback'
      visible={visible}
      onCloseClicked={onCloseClicked}
      afterClose={afterClose}
      width={500}
    >
      <div style={{ width:'100%' }}>
        <p style={textStyle}>At Ontraccr, we're always excited to receive feedback. Good or bad.</p>
        <p style={textStyle}>
          Our mission is to create the best time tracking experience out there.<br/>
          So please tell us what you need!.<br/>
        </p>
        <Row style={{width:'100%'}} type='flex' justify='center'>
          <Input.TextArea 
            value={text}
            style={inputStyle}
            onChange={(e) => {
              const {
                target:{
                  value,
                } = {}
              } = e;
              setText(value);
            }}
            maxLength={1000}
          />
          <OnTraccrButton
            title='Submit'
            style={buttonStyle}
            disabled={!text || text.length === 0}
            onClick={async () => {
              if(await upload(text)) {
                onCloseClicked();
              }
            }}
          />
        </Row>
      </div>
    </CustomModal>
  );
}
