import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { Row,Col} from 'antd';
import { connect } from 'react-redux';

import ProcoreImportDrawer from './ProcoreImportDrawer';

import { showDisconnectConfirm } from '../integrationHelpers';

import CheckRows from '../../CheckRows';
import CompanyEditRow from '../../CompanyEditRow';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DisplayText from '../../../common/text/DisplayText';

import Colors from '../../../constants/Colors';

import Permissions from '../../../auth/Permissions';

import procoreImage from '../../../assets/images/ProcoreBlackLogo.png';

import {
  disconnectProcore,
  importFromProcore,
  getProcoreCompanies,
  getProcoreGlobalCodesList,
  importIntegrationData,
  saveCompanySettings,
  checkIntegrationConnection,
} from '../../state/settings.actions';

const buttonDimensions = { 
  height:20,
  width:154,
};

const flavourText = `
Deactivating your Procore integration will disable any
further syncing between Ontraccr and Procore.
`;

export default connect(
  (state,ownProps) => ({
    ...ownProps,
    procoreCompanies:state.settings.procoreCompanies,
    procoreGlobalCodeLists: state.settings.procoreGlobalCodeLists,
    company:state.settings.company,
  }),
  {
    disconnectProcore,
    importFromProcore,
    getProcoreCompanies,
    getProcoreGlobalCodesList,
    importIntegrationData,
    saveCompanySettings,
    checkIntegrationConnection
  }
) (({
    connectedToProcore,
    ownerId,
    disconnectProcore,
    importFromProcore,
    getProcoreCompanies,
    procoreCompanies,
    procoreGlobalCodeLists,
    getProcoreGlobalCodesList,
    importIntegrationData,
    saveCompanySettings,
    checkIntegrationConnection,
    company = {},
  }) => {
    const checkStatus = useCallback(() => setTimeout(() => checkIntegrationConnection('procore'), 1000),[checkIntegrationConnection]);
    const {
      settings = {},
    } = company;
    const {
      procoreIntegrationPush = false,
      procoreIntegrationPull = true,
    } = settings;
    const [showImportDrawer,setShowImport] = useState();
    const showImport = () => setShowImport(true);
    const hideImport = () => {
      setShowImport(false);
      checkStatus();
    };

    useEffect(() => {
      checkStatus();
    },[checkStatus]);
    if(!connectedToProcore) {
      return (
        <div style={{ paddingTop: 30, paddingBottom:30}}>
          <div style={buttonDimensions}>
            <img 
                alt='Connect to Procore'
                src={procoreImage} 
                style={buttonDimensions}
              />
          </div>
          <OnTraccrButton
            style={{
              marginTop:30,
            }}
            title='Connect'
            onClick={async () => {
              const { data: uri } = await axios.get('/procore/connect/initiate');
              if(uri) window.location.href = uri;
            }}
          />
        </div>
      );
    }

    return (
      <div style={{ width:'100%'}}>
      <CompanyEditRow
        title='Import from Procore'
        helpText='Import Users, Projects and Cost Codes from Procore into Ontraccr.'
      >
        <OnTraccrButton 
          title='Set Up' 
          style={{ marginRight: 45 }}
          onClick={async () => {
            if(await getProcoreCompanies()) {
              showImport();
            }
          }}
        />
      </CompanyEditRow>

      <CheckRows
        isChecked={({ key }) => settings[key]}
        onChange={({ key }) => saveCompanySettings({
          [key]:!settings[key],
        })}
        data={[
          {
            key:'procoreIntegrationPull',
            title:'Auto-Sync from Procore to Ontraccr',
            helpText:(
              <div style={{ width: 300 }}>
                Auto-Sync from Procore to Ontraccr will
                automatically import any new changes you make in Procore
                into Ontraccr Users, Projects and Cost Codes.
              </div>
            ),
          },
          {
            key:'procoreIntegrationPush',
            title:'Auto-Sync from Ontraccr to Procore',
            helpText:(
              <div style={{ width: 300 }}>
                Auto-Sync from Ontraccr to Procore will
                automatically push any new changes you make in Ontraccr
                into Procore Users, Project and Cost Codes.
              </div>
            ),
          }
        ]}
      />

      <Row style={{minHeight:32, width:'100%', paddingLeft: 10}} justify='space-between' align='middle'>
          <Col>
            <DisplayText
              title='Deactivate Procore Integration'
              style={{ fontFamily: 'roboto-medium', marginBottom:0}}/>
          </Col>
        </Row>
        <DisplayText
          title={flavourText}
          style={{
            marginBottom:0,
            maxWidth:275,
            paddingLeft:10,
            fontFamily:'roboto-regular',
            color:Colors.ONTRACCR_OPACITY_GRAY
          }}
        />
        <Row style={{width:'100%', }} justify='center'>
          <OnTraccrButton
            title='Deactivate'
            type={Permissions.id === ownerId ? 'primary' : 'back'}
            disabled={Permissions.id !== ownerId}
            style={{ margin: 10, }}
            onClick={() => {
              showDisconnectConfirm({
                type:'Procore',
                flavourText,
                onConfirm:async () => {
                  await disconnectProcore();
                }
              });
            }}
          />
          <DisplayText
            style={{
              textAlign:'center',
              fontFamily:'roboto-bold',
              color:Colors.ONTRACCR_OPACITY_GRAY,
              height:32,
            }}
            title='Only the account owner can deactivate'
          />
        </Row>
        <ProcoreImportDrawer
          visible={showImportDrawer}
          onClose={hideImport}
          onSave={async (res) => {
            const payload = {
              ...res,
              integrationKey:'procoreId',
            };
            if(await importIntegrationData(payload)) {
              hideImport();
            }
          }}
          companies={procoreCompanies}
          importFromProcore={importFromProcore}
          getProcoreGlobalCodesList={getProcoreGlobalCodesList}
          globalCodeLists={procoreGlobalCodeLists}
          procoreIntegrationPush={procoreIntegrationPush}
          procoreIntegrationPull={procoreIntegrationPull}
        />
    </div>
    );
  }
);
