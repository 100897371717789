import React, { useCallback, useEffect, useMemo, useReducer } from 'react';
import {
  Col,
  Form,
  Row,
  Select,
} from 'antd';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Buckets } from 'ontraccr-common';

import getGoogleMapKey from '../common/keys';
import OnTraccrMap from '../common/map/OnTraccrMap';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import DisplayText from '../common/text/DisplayText';
import { bucketLinkTypeToDisplayName, getUpstreamName } from './bucketHelpers';
import { getIdMap } from '../helpers/helpers';
import { getBucketCustomData } from './state/buckets.actions';
import CustomFields from '../common/customFields/CustomFields';

export default function BucketDetailView({
  id,
  name,
  description,
  upstreamTypes,
  upstreamIdMap,
  downstreamIds,
  isDisplay,
  isAdd,
  formRef,
  projectMap,
  bucketTemplateMap,
  errors,
  customFields,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const customers = useSelector((state) => state.customers.customers);
  const buckets = useSelector((state) => state.buckets.buckets);
  const customData = useSelector((state) => state.buckets.customData);
  const customDataFiles = useSelector((state) => state.buckets.customDataFiles);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);

  const [shouldUpdate, forceUpdate] = useReducer((x) => x + 1, 0);

  const bucketMap = useMemo(() => getIdMap(buckets), [buckets]);
  const markers = useMemo(() => downstreamIds?.map((id) => {
    const project = projectMap[id];
    if (!project) return null;

    return {
      lat: project?.latitude,
      lng: project?.longitude,
      title: project?.name,
      address: project?.address,
      id: project?.id,
    };
  }).filter(Boolean), [downstreamIds, projectMap]);

  useEffect(() => {
    dispatch(getBucketCustomData(id));
  }, [id]);

  useEffect(() => {
    forceUpdate();
  }, [customData]);

  const getUpstreamSource = useCallback((upstreamType) => {
    switch (upstreamType) {
      case 'customer-one':
      case 'customer-many':
        return Object.values(customers).map((customer) => ({
          value: customer.id,
          label: customer.name,
        }));
      default: {
        const bucketTemplateId = Buckets.getTemplateIdFromType(upstreamType, null);
        if (!bucketTemplateId) return [];
        return buckets.filter(
          (bucket) => bucket.bucketTemplateId === bucketTemplateId
        ).map((bucket) => ({
          value: bucket.id,
          label: bucket.name,
        }));
      }
    }
  }, [buckets, customers]);

  return (
    <Row style={{ width: '100%', height: '100%' }} gutter={20}>
      <Col span={isAdd || !markers?.length ? 24 : 14}>
        <Form
          layout="vertical"
          ref={formRef}
          initialValues={{
            name,
            description,
            ...upstreamIdMap,
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            style={{ marginBottom: 20 }}
            rules={[{ required: true, message: 'Please input a name' }]}
          >
            { !isDisplay ? (
              <OnTraccrTextInput style={{ width: '100%' }} />
            ) : <DisplayText title={name} />}
          </Form.Item>
          <Form.Item
            label="Description"
            name="description"
            style={{ marginBottom: 20 }}
          >
            { !isDisplay ? (
              <OnTraccrTextInput style={{ width: '100%' }} />
            ) : <DisplayText title={description} />}
          </Form.Item>
          { !!upstreamTypes?.length && upstreamTypes.map((upstreamType) => (
            <Form.Item
              key={upstreamType}
              label={bucketLinkTypeToDisplayName(t, upstreamType, bucketTemplateMap)}
              name={upstreamType}
              style={{ marginBottom: 20 }}
            >
              { !isDisplay ? (
                <Select
                  options={getUpstreamSource(upstreamType)}
                  mode={upstreamType.includes('many') ? 'multiple' : null}
                  style={{ width: '100%' }}
                  optionFilterProp="label"
                />
              ) : (
                <DisplayText
                  title={
                    getUpstreamName({
                      upstreamType,
                      upstreamIds: upstreamIdMap[upstreamType] ?? [],
                      customers,
                      bucketMap,
                      bucketTemplateMap,
                      t,
                    })
                  }
                />
              )}
            </Form.Item>
          ))}
          {!!customFields?.length && (
            <Form.Item
              name="customData"
              style={{ marginTop: 10 }}
            >
              <CustomFields
                key={`${id}${shouldUpdate}`} // Need to force re-render
                initialValues={customData}
                customFields={customFields}
                divisions={Array.from(selectedDivisions)}
                errors={errors}
                fileMap={customDataFiles}
                isDisplay={isDisplay}
              />
            </Form.Item>
          )}
        </Form>
      </Col>
      {!isAdd && !!markers?.length && (
        <Col span={10}>
          <OnTraccrMap
            isNotDisplay={false}
            googleMapURL={getGoogleMapKey()}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={(
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  height: 250,
                }}
              />
            )}
            markers={markers}
            mapElement={<div style={{ height: '100%' }} />}
            showPin={false}
          />
        </Col>
      )}
    </Row>
  );
}

BucketDetailView.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  description: PropTypes.string,
  upstreamTypes: PropTypes.arrayOf(PropTypes.string),
  upstreamIdMap: PropTypes.shape({ [PropTypes.string]: PropTypes.arrayOf(PropTypes.string) }),
  downstreamIds: PropTypes.arrayOf(PropTypes.string),
  isDisplay: PropTypes.bool,
  isAdd: PropTypes.bool,
  formRef: PropTypes.shape({}),
  projectMap: PropTypes.shape({}),
  bucketTemplateMap: PropTypes.shape({
    [PropTypes.string]: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }),
  }),
  errors: PropTypes.shape({}).isRequired,
  customFields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

BucketDetailView.defaultProps = {
  id: null,
  name: null,
  description: null,
  upstreamTypes: [],
  upstreamIdMap: {},
  downstreamIds: [],
  isDisplay: false,
  isAdd: false,
  formRef: {},
  projectMap: {},
  bucketTemplateMap: {},
};
