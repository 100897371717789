import React from 'react';
import { List, Pagination, Row, } from 'antd';
import colors from '../constants/Colors';

export default class SideList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      listHeight:400,
      currentPage:1,
      dataSource:[]
    };

    this.onResize = this.resizeList.bind(this);
    this.listRender = this.renderListItem.bind(this);
    this.getPageSize = this.pageSize.bind(this);
    this.onPageChange = this.pageChanged.bind(this);
  }

  getRowHeight() { 
    const {
      rowHeight = 30,
    } = this.props;
    return rowHeight + 24;
  }
  
  showFooter() {
    const {
      footer = true,
      dataSource = [],
      headerStyle = {
        height:80,
      },
      scrollable
    } = this.props;
    if(!this.container) return footer;
    const listHeightWithoutFooter =  this.container.clientHeight - headerStyle.height - 80;
    const totalItems = dataSource.length;
    const actualRowHeight = this.getRowHeight(); //12 Padding

    return (footer && !scrollable && (totalItems * actualRowHeight) > listHeightWithoutFooter);
  }

  componentDidMount() {
    const {
      dataSource = [],
    } = this.props;

    window.addEventListener('resize', this.onResize);
    this.setState({
      dataSource,
    });
    this.resizeList();

  }

  componentDidUpdate(prevProps) {
    const {
      dataSource:prevData = [],
    } = prevProps;
    const {
      dataSource = [],
      footer = true,
    } = this.props;
    const {
      dataSource:stateData = []
    } = this.state;

    if(prevData !== dataSource) {
      this.setState({
        dataSource,
      });
      this.resizeList(true);
    }
    if(!footer && stateData !== dataSource) {
      this.setState({
        dataSource,
      });
    }
  }

  pageSize(height = this.state.listHeight) {
    const {
      footer = true,
      dataSource = [],
      scrollable
    } = this.props;
    if(!footer || scrollable) return dataSource.length;
    return parseInt(Math.floor(height /  this.getRowHeight()));
  }

  resizeList(force) {
    const {
      headerStyle = {
        height:80,
      },
      footer = true,
    } = this.props;

    if(!this.container) return;   

    const listHeight = this.container.clientHeight - headerStyle.height - (this.showFooter() ? 80 : 0);
    const newPageSize = this.getPageSize(listHeight);

    this.setState({ listHeight, });
    if(footer && (newPageSize !== this.currentPageSize || force)) {
      this.currentPageSize = newPageSize;
      this.onPageChange(this.state.currentPage);
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize',this.onResize)
  }

  renderListItem(item,idx) {
    const {
      renderItem = (item) => item,
      onClick,
      selectedItems = new Set([]),
    } = this.props;
    const pageSize = this.getPageSize();
    if(idx >= pageSize) return <div/>
    const showFooter = this.showFooter();
    const bottomBorder = (idx === pageSize - 1 && showFooter) ? 0 : 1;
    const borderWidth = `0px 0px ${bottomBorder}px 0px`;
    let key = item.key;
    if (item.id) key = item.id;
    if (!key) key = item.toString();
    const summaryStyle = item.id === 'summary' ? {
      backgroundColor: colors.TIME_CARD_STATS_YELLOW,
      fontWeight: 'bold',
    } : {};
    return (<List.Item 
      key={key}
      className={onClick ? 'sidelist-item-hoverable' : 'sidelist-item'}
      style={{
        paddingLeft: 20,
        width: '100%',
        backgroundColor: selectedItems.has(item) ? colors.opacity(colors.LIGHT_GRAY, 0.35) : 'white',
        border: 'solid',
        borderColor: colors.ONTRACCR_BLACK,
        borderWidth,
        cursor: onClick ? 'pointer' : 'auto',
        marginBottom: idx === pageSize - 1 ? 60 : 0,
        ...summaryStyle,
      }}
      onClick={() => {
        if(onClick) {
          onClick(item,idx)
        }
      }}>
      {renderItem(item)}
    </List.Item>);
  }

  pageChanged(page) {
    const {
      dataSource = [],
      footer = true,
    } = this.props;
    if(!footer) return;
    const pageSize = this.getPageSize();
    const numPages = parseInt(Math.ceil(dataSource.length / pageSize));

    // We dont use Math.min, becauase on initial load numPages = 0, which is an invalid page
    let ourPage = page;
    if (numPages !== 0 && page > numPages) {
      ourPage = numPages;
    }
    this.setState({
      currentPage:ourPage,
      dataSource:this.props.dataSource.slice((ourPage-1)*pageSize,ourPage*pageSize),
    });
  }

  render() {
    const {
      header,
      headerStyle = {
        height:80
      },
      dataSource = [],
      scrollable = false,
    } = this.props;
    const showFooter = this.showFooter();
    return (
      <Row 
        className='card-shadow'
        type='flex'
        style={{
          height:'100%',
          width:'100%',
          backgroundColor:'white',
          border:'solid',
          borderRadius:10,
          borderColor:colors.ONTRACCR_GRAY,
          borderWidth:1,
          overflow:'hidden'
      }}>
        <div 
          ref={(ref) => this.container = ref}
          style={{ width:'100%',height:'100%'}}
        >
          <Row 
            type='flex'
            align='middle'
            justify='center'
            style={{  
              height:'15%',
              width:'100%',
              backgroundColor:colors.LIGHT_GRAY,
              borderTopLeftRadius:10,
              borderTopRightRadius:10,
              border:'solid',
              borderColor:colors.ONTRACCR_BLACK,
              borderBottomWidth:1,
              borderTopWidth:0,
              borderLeftWidth:0,
              borderRightWidth:0,
              ...headerStyle,
          }}>
            {header}
          </Row>

          <Row 
            // type='flex'
            align='top'
            style={{
              width:'100%',
              flex:1,
              height:`calc(100% - ${showFooter ? 80 : 0}px)`
            }}
          >
            <div 
              ref={(ref) => this.list = ref}
              style={{ width:'100%',height:'100%', }}
            >
              <List
                style={{
                  height: scrollable ? '90%' : '100%',
                  width:'100%',
                  overflowY: scrollable ? 'scroll' : 'hidden',
                }}
                dataSource={this.state.dataSource}
                renderItem={this.listRender}
              />
            </div>
          </Row>
          {showFooter ? <Row 
            type='flex'
            align='middle'
            justify='center'
            style={{  
              height:80,
              width:'100%',
              backgroundColor:colors.LIGHT_GRAY,
              borderBottomLeftRadius:10,
              borderBottomRightRadius:10,
              border:'solid',
              borderColor:colors.ONTRACCR_BLACK,
              borderBottomWidth:0,
              borderTopWidth:1,
              borderLeftWidth:0,
              borderRightWidth:0,
          }}>
            <Pagination
              defaultCurrent={1}
              current={this.state.currentPage}
              total={dataSource.length} 
              pageSize={this.getPageSize()}
              hideOnSinglePage
              onChange={this.onPageChange}
              showSizeChanger={false}
            />
          </Row> : null}
                    
        </div>
      </Row>
    );
  }
}
