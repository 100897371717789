import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import PropType from 'prop-types';

import EquipmentSelectDisplay from './EquipmentSelectDisplay';

function EquipmentStatusSelector({
  isDisplay,
  value,
  onChange,
}) {
  const statuses = useSelector((state) => state.equipment.statuses);

  const statusOptions = useMemo(() => (
    Object.values(statuses)
      .map((status) => ({ value: status.id, label: status.status }))
  ), [statuses]);

  return (
    <EquipmentSelectDisplay
      isDisplay={isDisplay}
      value={value}
      onChange={onChange}
      options={statusOptions}
    />
  );
}

EquipmentStatusSelector.propTypes = {
  isDisplay: PropType.bool,
  value: PropType.string,
  onChange: PropType.func.isRequired,
};

EquipmentStatusSelector.defaultProps = {
  isDisplay: false,
  value: null,
};

export default EquipmentStatusSelector;
