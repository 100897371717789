import React from 'react';

import { DateTime } from 'luxon';
import { Badge } from 'antd';
import { DeleteOutlined, EditOutlined, FileOutlined } from '@ant-design/icons';

import Colors from '../constants/Colors';
import BorderlessButton from '../common/buttons/BorderlessButton';

const NAME_KEY_MAP = {
  users: 'userName',
  vendors: 'vendor',
};

export default function getCertificationColumns({
  entityType,
  showUserName = false,
  showDelete = false,
  onUpdateClick,
  onDeleteClick,
  customFieldColumns = [],
}) {
  const columns = [
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Acquired Date',
      dataIndex: 'acquiredDate',
      key: 'acquiredDate',
      render: (text) => (
        text ? DateTime.fromSeconds(text).toLocaleString(DateTime.DATE_FULL) : ''
      ),
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expiryDate',
      key: 'expiryDate',
      render: (text, record) => {
        const isExpired = DateTime.local().valueOf() > (record.expiryDate * 1000);
        return (
          <div className={isExpired ? 'user-expired-certification-label' : ''}>
            {text ? DateTime.fromSeconds(text).toLocaleString(DateTime.DATE_FULL) : ''}
          </div>
        );
      },
    },
    {
      title: 'Issuer',
      dataIndex: 'issuer',
      key: 'issuer',
    },
    {
      title: 'Files',
      dataIndex: 'files',
      key: 'files',
      render: (_, record) => (
        <Badge count={record.files.length} showZero>
          <FileOutlined style={{ fontSize: '25px' }} />
        </Badge>
      ),
    },
  ];

  if (customFieldColumns?.length) {
    columns.push(...customFieldColumns);
  }

  columns.push(
    {
      title: '',
      dataIndex: 'delete',
      key: 'delete',
      align: 'right',
      width: 10,
      render: (_, record) => (
        <>
          <BorderlessButton
            iconNode={<EditOutlined />}
            onClick={() => onUpdateClick(record)}
          />
          { showDelete
            && (
              <BorderlessButton
                iconNode={<DeleteOutlined />}
                color={Colors.ONTRACCR_RED}
                onClick={() => onDeleteClick(record)}
              />
            )}
        </>
      ),
    },
  );

  if (showUserName) {
    const key = NAME_KEY_MAP[entityType] ?? 'name';
    columns.unshift({
      title: 'Name',
      dataIndex: key,
      key,
    });
  }

  return columns;
}
