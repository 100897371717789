import axios from 'axios';
import { message } from 'antd';

import { request } from '../../helpers/requests';
import { decorateFormWithFiles } from '../../forms/formHelpers';

import clientPortalAxiosInstance from '../clientPortal.axios';

export default {
  getSettings: () => request({
    call: axios.get('/clientPortal/settings'),
    errMsg: 'Could not get client portal settings',
    hideSuccessToast: true,
  }),
  getSettingsFromURL: (url) => request({
    call: axios.get('/clientPortal/config', { params: { url } }),
    errMsg: 'Could not get client portal',
    hideSuccessToast: true,
    suppressError: true,
  }),
  updateSettings: (payload) => request({
    call: async () => {
      try {
        if (payload.url) {
          const { data: exists } = await axios.get('/clientPortal/url/exists', { params: { url: payload.url } });
          if (exists) {
            return message.error(`Portal Address ${payload.url} already in use. Please choose another Address`);
          }
        }
      } catch (err) {
        return {};
      }
      return axios.put('/clientPortal/settings', payload);
    },
    errMsg: 'Could not update client portal settings',
  }),
  getAccounts: () => request({
    call: axios.get('/clientPortal/accounts'),
    errMsg: 'Could not get client portal accounts',
    hideSuccessToast: true,
  }),
  createAccount: (payload) => request({
    call: axios.post('/clientPortal/accounts', payload),
    errMsg: 'Could not create client portal account',
  }),
  deleteAccount: (id) => request({
    call: axios.delete(`/clientPortal/accounts/${id}`),
    errMsg: 'Could not delete client portal account',
  }),
  updateAccount: (id, payload) => request({
    call: axios.put(`/clientPortal/accounts/${id}`, payload),
    errMsg: 'Could not update client portal account',
  }),
  login: (payload) => request({
    call: clientPortalAxiosInstance.post('/clientPortal/login', payload),
    errMsg: 'Login Failed',
  }),
  logout: () => request({
    call: clientPortalAxiosInstance.delete('/clientPortal/logout'),
    errMsg: 'Logout Failed',
  }),
  getData: () => request({
    call: async () => {
      const { data = {}, err } = await clientPortalAxiosInstance.get('/clientPortal/data');
      if (!data) return { err };
      const { fileMap = {} } = data;
      await decorateFormWithFiles({ fileMap });
      return { data };
    },
    errMsg: 'Could not get data',
    hideSuccessToast: true,
  }),
  sendMessage: (note) => request({
    call: clientPortalAxiosInstance.post('/clientPortal/comms/note/incoming', { note }),
    errMsg: 'Could not send message',
  }),
  markAsRead: (ids) => request({
    call: clientPortalAxiosInstance.post('/clientPortal/comms/read/outgoing', { ids }),
    errMsg: 'Could not mark messages as read',
    hideSuccessToast: true,
  }),
};
