import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row, message } from 'antd';
import { useSelector } from 'react-redux';

import SideList from '../../common/SideList';
import colors from '../../constants/Colors';

const renderActive = ({
  name,
  onClick,
}) => (
  <Row
    style={{
      width: '100%',
      cursor: 'pointer',
    }}
    onClick={onClick}
  >
    <Col
      span={16}
      style={{
        fontFamily: 'roboto-regular',
        color: colors.ONTRACCR_BLACK,
        fontSize: 14,
      }}
    >
      {name}
    </Col>
    <Col
      span={8}
      style={{
        pointerEvents: 'none',
      }}
    />
  </Row>
);
function MapUsersList({
  activeUsers = [],
  onUserClick,
}) {
  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  return (
    <SideList
      rowHeight={21}
      scrollable
      footer={false}
      header={(
        <Row
          style={{
            fontFamily: 'raleway-medium',
            fontSize: 18,
          }}
          type="flex"
          align="middle"
          justify="center"
        >
          Active Today
        </Row>
      )}
      headerStyle={{
        backgroundColor: colors.ONTRACCR_BLACK,
        color: 'white',
        height: 50,
        borderTopLeftRadius: 9,
        borderTopRightRadius: 9,
      }}
      dataSource={activeUsers}
      renderItem={(user) => {
        const timeEntries = timeEntryUserMap[user.id] ?? [];
        const lastEntry = timeEntries.length ? timeEntries[timeEntries.length - 1] : {};
        return renderActive({
          name: user.name,
          onClick: (lastEntry.startLatitude && lastEntry.startLongitude)
            ? () => onUserClick(user.id)
            : () => message.warn("User hasn't shared their location"),
        });
      }}
    />
  );
}
MapUsersList.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  activeUsers: PropTypes.arrayOf(PropTypes.object).isRequired,
  onUserClick: PropTypes.func.isRequired,
};
export default MapUsersList;