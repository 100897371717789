import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Row, Col, Tooltip } from 'antd';

import ProfileCircle from '../common/profile/ProfileCircle';

import { getTextColor } from '../schedule/scheduleHelpers';

import { getIdMap } from '../helpers/helpers';
import { initialsFromName } from '../helpers/users';

export default ({
  users: cardUsers = [],
  style = {},
  justify='end',
  tooltipPlacement = 'right',
}) => {
  const users = useSelector(state => state.users.users);
  const userMap = useMemo(() => getIdMap(users), [users]);
  const userView = useMemo(() => {
    return cardUsers.map((assigneeId) => {
      const {
        [assigneeId]: {
          name = '',
          scheduleColor,
        } = {},
      } = userMap;
      if (!name) return null;
      const initials = initialsFromName(name);
      const circleStyle = scheduleColor
        ? { backgroundColor: scheduleColor }
        : {};
      const textColor = scheduleColor ? getTextColor(scheduleColor) : undefined;
      return (
        <Col key={assigneeId}>
          <Tooltip title={name} placement={tooltipPlacement}>
            <div>
              <ProfileCircle
                initials={initials}
                radius={10}
                className='boards-card-profile-circle'
                style={circleStyle}
                accentColor={textColor}
              />
            </div>
          </Tooltip>
        </Col>
      );
    });
  },[tooltipPlacement, cardUsers, userMap]);

  return (
    <Row style={{ maxWidth: '25%', overflow:'hidden', ...style }} justify={justify} gutter={5}>
      {userView}
    </Row>
  );
}