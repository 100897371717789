import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Col,
  Input,
  Row,
  Spin,
} from 'antd';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import ResizeObserver from 'rc-resize-observer';
import PropTypes from 'prop-types';
import * as Sentry from '@sentry/react';

import DashboardComponent from './DashboardComponent';
import SubtaskDrawer from '../subtasks/SubtaskDrawer';
import { includesTerm } from '../helpers/helpers';
import { getSubTasks } from '../subtasks/state/subtasks.actions';
import OnTraccrEmpty from '../common/OnTraccrEmpty';
import SubtaskTable from '../subtasks/SubtaskTable';
import { PRIORITY_MAP } from '../subtasks/subtask.constants';
import DashboardTaskWidetsConfig from './DashboardTasksWidgetConfig';

export default function DashboardTasksWidget({
  layout = {},
  onDelete,
  onEdit,
  isEdit,
}) {
  const {
    settings,
    settings: {
      visibleColumns,
    } = {},
  } = layout;

  const dispatch = useDispatch();
  const subtasks = useSelector((state) => state.subtasks.subtasks);

  const [drawerOpen, setDrawerOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState();
  const [selectedTaskId, setSelectedTaskId] = useState();
  const [editMode, setEditMode] = useState(false);
  const [tableDimensions, setTableDimensions] = useState({ width: 0, height: 0 });
  const [showConfig, setShowConfig] = useState(false);

  const onDrawerClose = () => {
    setDrawerOpen(false);
    setSelectedTaskId();
    setEditMode(false);
  };

  const onTaskEdit = useCallback((taskId) => {
    setSelectedTaskId(taskId);
    setDrawerOpen(true);
    setEditMode(true);
  }, []);

  const onTaskClicked = useCallback((task) => {
    setSelectedTaskId(task.id);
    setDrawerOpen(true);
  }, []);

  const subtaskList = useMemo(() => {
    const list = Object.values(subtasks);
    // Default sort to highest priority
    list.sort((a, b) => PRIORITY_MAP[b.priority] - PRIORITY_MAP[a.priority]);
    return list;
  }, [subtasks]);

  const filteredList = useMemo(() => {
    if (!searchTerm || searchTerm.length === 0) return subtaskList;
    return subtaskList.filter((st) => (
      includesTerm(st.title ?? '', searchTerm) || includesTerm(st.description ?? '', searchTerm)
    ));
  }, [subtaskList, searchTerm]);

  const onSearchChanged = useCallback((e) => {
    const {
      target: { value } = {},
    } = e;
    setSearchTerm(value);
  }, []);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getSubTasks());
      setLoading(false);
    };
    load().catch((err) => {
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    });
  }, []);

  const onConfig = useCallback(() => {
    setShowConfig(true);
  }, []);

  const body = useMemo(() => {
    if (filteredList.length === 0 || loading) {
      return (
        <Row justify="center" align="middle" style={{ width: '100%', height: '100%' }}>
          <Col>
            {loading ? <Spin /> : <OnTraccrEmpty />}
          </Col>
        </Row>
      );
    }
    return (
      <div
        style={{
          display: 'flex',
          flex: 1,
          overFlowY: 'auto',
          height: '100%',
          width: '100%',
        }}
      >
        <SubtaskTable
          subtasks={filteredList}
          onTaskClick={onTaskClicked}
          onTaskEdit={onTaskEdit}
          scroll={{
            y: tableDimensions.height - 60,
            x: 'fit-content',
          }}
          visibleColumns={visibleColumns}
          defaultHideDone
        />
      </div>
    );
  }, [subtaskList, filteredList, loading, tableDimensions, visibleColumns]);

  const configContent = useMemo(() => (
    <DashboardTaskWidetsConfig
      settings={settings}
      onSave={onEdit}
    />
  ), [settings, onEdit]);

  const headerSearch = useMemo(() => (
    <Row align="middle" justify="end">
      <Col>
        <Input.Search
          type="search"
          style={{ width: 'min(100% - 250px, 250px))', right: 0 }}
          className="searchbar"
          placeholder="Search"
          allowClear
          onChange={onSearchChanged}
        />
      </Col>
    </Row>
  ), [onSearchChanged]);

  return (
    <DashboardComponent
      title="Tasks"
      onDelete={onDelete}
      onConfig={onConfig}
      showConfig={showConfig}
      configContent={configContent}
      headerContent={headerSearch}
      headerStyle={{ height: null }}
      isEdit={isEdit}
    >
      <ResizeObserver onResize={setTableDimensions}>
        <div
          style={{
            height: 'calc(100% - 40px)',
            width: '100%',
          }}
        >
          {body}
        </div>
      </ResizeObserver>
      <SubtaskDrawer
        visible={drawerOpen}
        onClose={onDrawerClose}
        selectedTaskId={selectedTaskId}
        editMode={editMode}
        onEditModeChange={setEditMode}
      />
    </DashboardComponent>
  );
}

DashboardTasksWidget.propTypes = {
  layout: PropTypes.object,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  isEdit: PropTypes.bool,
};

DashboardTasksWidget.defaultProps = {
  layout: { settings: { visibleColumns: null } },
  onDelete: () => {},
  onEdit: () => {},
  isEdit: false,
};
