import React from 'react';
import { Row, Col } from 'antd';

import LiveFeedCard from './LiveFeedCard';

export default ({
  costcodes = [],
  onClick,
}) => {
 
  return (
    <Row style={{ width: 200, maxWidth: 200}}>
      {costcodes.map((costcode, index) => {
          const {
            name,
            code,
            id,
          } = costcode;
          return (
            <LiveFeedCard
              onClick={onClick}
              key={id}
              style={{
                maxWidth: 200,
                padding: 15,
                marginTop: index > 0 ? 10 : 0 }}
              >
              <Row
                key={id}
                style={{
                  width: '100%',
                }}
                justify='space-between'
              >
                <Col style={{ 
                  fontFamily:'roboto-bold',
                }}>
                  {name}
                </Col>
                <Col>
                  {code}
                </Col>
              </Row>
            </LiveFeedCard>
          );
        })}
    </Row>
  );
}
