import React, { useState, useCallback } from 'react';

import {
  getSmoothStepPath,
  getMarkerEnd,
  useStoreState,
  removeElements
} from 'react-flow-renderer';

import colors from '../../constants/Colors';

const RADIUS = 5;
const OFFSET = 35;

const getXBound = ({
  nodes,
  min,
  highCutoff = 0,
  lowCutoff = 0,
}) => (
  nodes.reduce((current,{ 
    __rf: { position: { x, y } = {}, width = 0, } = {}
  } = {}) => {
    if(y > lowCutoff || y < highCutoff) return current;
    return (
      min
      ? Math.min(current, x - (width / 2))
      : Math.max(current, x + width)
    );
  },min ? Number.MAX_SAFE_INTEGER : Number.MIN_SAFE_INTEGER)
);


const constructPath = ({
  sourceX,
  sourceY,
  targetX,
  targetY,
  nodes = [],
}) => {
  const lowest = sourceY + OFFSET;
  const highest = targetY - OFFSET;
  
  const cy = sourceY - (sourceY - targetY) / 2;
  let cx;

  // Go down from source
  let path = `M ${sourceX},${sourceY} L${sourceX},${lowest}`;
  const newY = lowest + RADIUS;
  if (targetX > sourceX) {
    // Go Left
    path += `Q ${sourceX},${newY} ${sourceX - RADIUS},${newY}`;
    const minX = getXBound({ 
      nodes,
      min: true,
      lowCutoff: sourceY,
      highCutoff: targetY,
    });
    const newX = minX - RADIUS;

    // Go Left around nodes
    path += `L ${minX},${newY} Q${newX},${newY} ${newX},${lowest}`;

    // Go up above target
    const newHigh = highest - RADIUS;
    path += `L ${newX},${highest} Q${newX},${newHigh} ${newX + RADIUS},${newHigh}`;
    cx = newX;

    // Go to right to target 
    path += `L ${targetX - RADIUS},${newHigh} Q${targetX},${newHigh} ${targetX},${newHigh + RADIUS}`;
  } else {
    // Go Right
    path += `Q ${sourceX},${newY} ${sourceX + RADIUS},${newY}`;
    const maxX = getXBound({
      nodes,
      min: false,
      lowCutoff: sourceY,
      highCutoff: targetY,
    }) + 50;
    const newX = maxX + RADIUS;

    // Go Right around nodes
    path += `L ${maxX},${newY} Q${newX},${newY} ${newX},${lowest}`;

    // Go up above target
    const newHigh = highest - RADIUS;
    path += `L ${newX},${highest} Q${newX},${newHigh} ${newX - RADIUS},${newHigh}`;
    cx = newX;

    // Go to right to target 
    path += `L ${targetX + RADIUS},${newHigh} Q${targetX},${newHigh} ${targetX},${newHigh + RADIUS}`;
  }
  // Close
  path += `L ${targetX},${targetY}`;
  return { path, cx, cy };
};

export default ({ setElements, isDisplay }) => ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  arrowHeadType,
  markerEndId,
}) => {
  const nodes = useStoreState(store => store.nodes);
  const [hovering,setHovering] = useState(false);
  const startHover = useCallback(() => setHovering(true),[]);
  const endHover = useCallback(() => setHovering(false),[]);

  let edgePath;
  let cX;
  let cY;
  if(sourceY > targetY) {
    // If we're going back up need to do some custom logic to avoid nodes
    const { path, cx:circleX, cy:circleY } = constructPath({sourceX, sourceY, targetX, targetY, nodes });
    edgePath = path;
    cX = circleX;
    cY = circleY;
  } else {
    edgePath = getSmoothStepPath({ sourceX, sourceY, sourcePosition, targetX, targetY, targetPosition });
    cX = sourceX + (targetX - sourceX) / 2;
    cY = sourceY + (targetY - sourceY) / 2
  }

  const markerEnd = getMarkerEnd(arrowHeadType, markerEndId);

  

  return (
    <>
    <path
      className='react-flow__edge-path'
      id={id}
      d={edgePath}
      markerEnd={markerEnd}
      style={style}
    />
    {!isDisplay && <circle
      cx={cX}
      cy={cY}
      r={hovering ? 15 : 14}
      fill={colors.ONTRACCR_RED}
      stroke='white'
      strokeWidth={1}
      onMouseOver={startHover}
      onMouseOut={endHover}
      style={{
        pointerEvents:'all',
        cursor:'pointer',
      }}
      onClick={() => {
        setElements((existingElements) => removeElements([{ id }], existingElements))
      }}
    />}
    {!isDisplay && <line x1={cX - 6} y1 ={cY - 6} x2={cX + 6} y2={cY + 6} stroke='white' strokeWidth={1.25}/>}
    {!isDisplay && <line x1={cX + 6} y1 ={cY - 6} x2={cX - 6} y2={cY + 6} stroke='white' strokeWidth={1.25}/>}
    </>
  );
}