import React from 'react';
import { Row, Col } from 'antd';

import BorderlessButton from '../common/buttons/BorderlessButton';

import colors from '../constants/Colors';

export default ({
  icon,
  onClick,
  visible,
}) => {
  if (!visible) return <Col style={{width:30}} />
  return (
    <Col>
      <Row justify='center' align='middle'>
        <BorderlessButton
          style={{ width: 30,
            backgroundColor:colors.ONTRACCR_DARK_YELLOW,
            padding:0,
            borderRadius:4,
          }}
          iconNode={icon}
          onClick={onClick}
        />
      </Row>
    </Col>
  );
}