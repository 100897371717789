import React, { useState } from "react";
import ExportXLSX from "./ExportXLSX";
import { Typography, Input } from "antd";

import Day from './Day'
import { MailOutlined, PrinterOutlined } from '@ant-design/icons'

import { createPdf} from './SheetsHelpers'
import "./SheetsMobile.css"

const { Text } = Typography;
//next have to do export csv functionality
//and then export pdf functionality

export default function SheetsMobile({
  times, 
  calculateWeeklyTotal, 
  mobileOnTimeSelected, 
  renderCsvTimesFn, 
  employeeName, 
  employeeID, 
  employeeNameHandler, 
  employeeIDHandler,
  emailFeature
}) {

    const [activeTab, setActiveTab] = useState(0)

    return(
        <div className="sheetsm-container">

          <div className="sheetsm-tab-container">
            <div className={activeTab === 0 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(0)}>Su</div>
            <div className={activeTab === 1 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(1)}>M</div>
            <div className={activeTab === 2 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(2)}>T</div>
            <div className={activeTab === 3 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(3)}>W</div>
            <div className={activeTab === 4 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(4)}>Th</div>
            <div className={activeTab === 5 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(5)}>F</div>
            <div className={activeTab === 6 ? "sheetsm-tab active-tab" : "sheetsm-tab"} onClick={()=> setActiveTab(6)}>Sa</div>
          </div>
          
          <Day dayIndex={activeTab} times={times} mobileOnTimeSelected={mobileOnTimeSelected} />
          {/* a dayIndex of 0 represents Monday, 6 represent Sunday */}

          <div className="sheetsm-weeklyTotalSection">
              <Text className="sheetsm-weeklyTotal-text">Weekly Total: </Text>
              <Text className="sheetsm-weeklyTotal-number">{calculateWeeklyTotal(times)}</Text>
          </div>

         <div className="inputGroup">

            <Input
              className="sheetsmInput sheetsmInputemployeeName"
              placeholder="Full Name"
              value={employeeName}
              onChange={employeeNameHandler}/>
            
            <Input
              className="sheetsmInput sheetsmInputEmployeeID"
              placeholder="Employee ID"
              value={employeeID}
              onChange={employeeIDHandler}/> 

          </div>

          <div className="sheetsm-buttonGroup">

            <button className="pdf-export ant-btn OnTraccrBtn-cancel " onClick={()=> createPdf(times, employeeName, employeeID, calculateWeeklyTotal)}>PDF

            <PrinterOutlined style={{marginLeft: '10px'}}/></button>

            <ExportXLSX
              csvData={renderCsvTimesFn(times)}
            />

            <a className="ant-btn OnTraccrBtn-primary ant-btn-primary" href={emailFeature()}>Email

              <MailOutlined style={{marginLeft: '10px'}}/>

            </a>

          </div>

        </div>    
    )}