import React, { useMemo } from 'react';
import { Row, Select } from 'antd';
import PropTypes from 'prop-types';

export default function FieldSelector(props) {
  const {
    fields = [],
    onChange,
    disable,
    selected,
  } = props;

  const options = useMemo(() => (
    fields.map((field) => (
      <Select.Option
        key={field.id}
        value={field.id}
        label={field.label}
      >
        {field.label}
      </Select.Option>
    ))
  ), [fields]);

  return (
    <>
      <Row style={{ marginTop: 5 }}>
        Select Field:
      </Row>
      <Row style={{ margin: '10px 0px' }}>
        <Select
          onChange={onChange}
          style={disable ? { pointerEvents: 'none', width: '100%' } : { width: '100%' }}
          dropdownMatchSelectWidth={false}
          value={selected}
          showSearch
          optionFilterProp="label"
        >
          {options}
        </Select>
      </Row>
    </>
  );
}

FieldSelector.propTypes = {
  fields: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    label: PropTypes.string,
  })),
  onChange: PropTypes.func,
  disable: PropTypes.bool,
  selected: PropTypes.string,
};

FieldSelector.defaultProps = {
  fields: [],
  onChange: () => {},
  disable: false,
  selected: '',
};
