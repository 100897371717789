import React from 'react';

import WorkflowNotificationNode from './WorkflowNotificationNode';

const HOVER_TEXT = `
Assigned users are the people you want to fill out the form.
`;

export default ({ setDataMap, isDisplay, divisionId, isExternalForm, }) => ({
  id,
  draggable,
  data,
}) => (
  <WorkflowNotificationNode
    type='users'
    id={id}
    draggable={draggable}
    data={data}
    style={{
      width: 300,
    }}
    setDataMap={setDataMap}
    isDisplay={isDisplay}
    hover={HOVER_TEXT}
    divisionId={divisionId}
    isExternalForm={isExternalForm}
  />
);
