import { Col, Divider } from "antd";
import PhotoReel from "../../common/photos/PhotoReel";

const HistoryEntryFileDisplay = ({
  files, onPreviewClick,
}) => {
  return (
    <>
      <Col span={24}>
        {files.length > 0 &&
          <div className='livefeed-file-reel-container'>
            <PhotoReel
              files={files}
              shadow
              small
              onClick={onPreviewClick}
            />
          </div>
        }
      </Col>
      <Divider style={{ marginTop: 20, marginBottom: 5 }}/>
    </>
  );
}

export default HistoryEntryFileDisplay;