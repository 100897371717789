import React, { useMemo, useState, useEffect } from 'react';
import { Col, Form, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';

import CustomModal from '../../common/modals/CustomModal';
import StepsContainer from '../../common/containers/StepsContainer';
import { getIdMap } from '../../helpers/helpers';
import DrawerFooter from '../../common/containers/DrawerFooter';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import Colors from '../../constants/Colors';

export default function SimpleBucketTemplateModal({
  visible,
  templateName,
  linkIds,
  onSave,
  onClose,
}) {
  const bucketTemplates = useSelector((state) => state.buckets.bucketTemplates);
  const [currentStep, setCurrentStep] = useState(0);
  const [linkMap, setLinkMap] = useState({});

  const bucketTemplateMap = useMemo(() => getIdMap(bucketTemplates), [bucketTemplates]);

  useEffect(() => {
    if (!visible) {
      setCurrentStep(0);
      setLinkMap({});
    }
  }, [visible]);

  const steps = useMemo(() => (
    linkIds.map((linkId) => {
      const bucketTemplateLinkName = bucketTemplateMap[linkId]?.name || 'Unknown';
      return {
        id: linkId,
        title: bucketTemplateLinkName,
        content: (
          <>
            <span style={{ fontSize: 14 }}>
              It looks like you are trying to create a new link for the {templateName} bucket.
              Please select whether {bucketTemplateLinkName} bucket should link to either one or more {templateName} buckets.
            </span>
            <Form.Item
              label="Link Type"
              style={{ width: '100%' }}
            >
              <Select
                placeholder="Select Link Type to Template"
                onChange={(newValue) => {
                  setLinkMap((prev) => ({
                    ...prev,
                    [linkId]: newValue,
                  }));
                }}
              >
                <Select.Option value="one">One {templateName}</Select.Option>
                <Select.Option value="many">Many {templateName}(s)</Select.Option>
              </Select>
            </Form.Item>
          </>
        ),
      };
    })
  ), [linkIds, bucketTemplateMap, linkMap, templateName]);

  const onBack = () => setCurrentStep(currentStep - 1);
  const onNext = () => {
    if (currentStep < linkIds.length - 1) {
      setCurrentStep(currentStep + 1);
    } else {
      onSave(linkMap);
    }
  };

  if (!visible) return null;

  return (
    <CustomModal
      title="Select Link Type"
      visible={visible}
      width="50%"
      hideCloseButton
      maskColor={Colors.MODAL_MASK_DARK}
    >
      <StepsContainer
        currentStep={currentStep}
        steps={steps}
        containerStyle={{
          padding: 20,
          height: 250,
        }}
        style={{ marginBottom: 15 }}
      />
      <DrawerFooter style={{ borderRadius: '0 0 20px 20px' }}>
        <Row justify="space-between" align="middle" gutter={8}>
          <Col>
            <OnTraccrButton
              title="Cancel"
              type="back"
              onClick={onClose}
            />
          </Col>
          <Col>
            {currentStep > 0 && (
              <OnTraccrButton
                title="Back"
                type="back"
                id="customer-add-project-footer-back"
                onClick={onBack}
              />
            )}
            <OnTraccrButton
              title={currentStep < (linkIds.length - 1) ? 'Next' : 'Submit'}
              onClick={onNext}
              disabled={!linkMap[steps[currentStep].id]}
            />
          </Col>
        </Row>
      </DrawerFooter>
    </CustomModal>

  );
}

SimpleBucketTemplateModal.propTypes = {
  visible: PropTypes.bool,
  linkIds: PropTypes.arrayOf(PropTypes.string),
  onSave: PropTypes.func.isRequired,
  templateName: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

SimpleBucketTemplateModal.defaultProps = {
  visible: false,
  linkIds: [],
};
