import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import { getErrorsFromResponses, prepareResponsePayload } from '../forms/ResponderHelpers';
import { momentToTimestamp } from '../projects/projectHelpers';
import { getIdMap } from './helpers';

/**
 * Split out global codes and format data without splitting
 * into new/modified and keeping unmodified codes
 * @param {array} oldCostcodes from before editing
 * @param {array} newPhases all phases present after editing
 * @param {array} newCostcodes all costcodes present after editing
 * @returns {object} global costcodes, normal costcodes, and phases
 */
export const generateEditCostcodePayload = ({
  oldCostcodes,
  newPhases,
  newCostcodes,
}) => {
  const oldCostcodeMap = getIdMap(oldCostcodes);
  const globalCostcodes = [];
  const createdCostcodes = [];

  if (newCostcodes) {
    newCostcodes.forEach((costcode) => {
      if (costcode.isNew || costcode.id in oldCostcodeMap) {
        createdCostcodes.push({
          id: costcode.id,
          code: costcode.code,
          name: costcode.name,
          description: costcode.description,
          phased: costcode.phased,
          hours: costcode.hours,
          estimatedCost: costcode.estimatedCost,
          estimatedQuantity: costcode.estimatedQuantity,
          units: costcode.units,
          categoryId: costcode.categoryId,
          hourlyWage: costcode.hourlyWage,
          dailyWage: costcode.dailyWage,
          wageAdjustment: costcode.wageAdjustment,
          wageMultiplier: costcode.wageMultiplier,
          hourlyBillingRate: costcode.hourlyBillingRate,
          dailyBillingRate: costcode.dailyBillingRate,
          customData: costcode.customData,
          intuitId: costcode.intuitId,
        });
      } else {
        globalCostcodes.push({
          id: costcode.id,
          phased: costcode.phased,
          hours: costcode.hours,
          estimatedCost: costcode.estimatedCost,
          estimatedQuantity: costcode.estimatedQuantity,
          units: costcode.units,
          hourlyWage: costcode.hourlyWage,
          dailyWage: costcode.dailyWage,
          wageAdjustment: costcode.wageAdjustment,
          wageMultiplier: costcode.wageMultiplier,
          hourlyBillingRate: costcode.hourlyBillingRate,
          dailyBillingRate: costcode.dailyBillingRate,
        });
      }
    });
  }

  const createdPhases = [];
  if (newPhases) {
    newPhases.forEach((phase) => {
      createdPhases.push({
        name: phase.name,
        description: phase.description,
        estimatedLabourHours: phase.estimatedLabourHours,
        estimatedLabourCost: phase.estimatedLabourCost,
        estimatedOverheadHours: phase.estimatedOverheadHours,
        estimatedMaterialCost: phase.estimatedMaterialCost,
        estimatedOverheadCost: phase.estimatedOverheadCost,
        estimatedEquipmentHours: phase.estimatedEquipmentHours,
        estimatedEquipmentCost: phase.estimatedEquipmentCost,
        costcodes: phase.costcodes.map((cc) => ({
          id: cc.id,
          hours: cc.hours,
          estimatedCost: cc.estimatedCost,
          estimatedQuantity: cc.estimatedQuantity,
          units: cc.units,
          startDate: momentToTimestamp(cc.startDate),
          endDate: momentToTimestamp(cc.endDate),
        })),
      });
    });
  }

  return {
    globalCostcodes,
    createdCostcodes,
    createdPhases,
  };
};

// Split out global codes and format data
// without splitting into new/modified and keeping unmodified
/**
 * Adds all costcodes that belong to each phase into an array
 * @param {array} phases
 * @param {array} phaseCodes
 * @param {array} costcodes
 * @returns {array} phases with costcodes property added
 */
export const condenseTemplatePhasesAndCostcodes = (phases, phaseCodes, costcodes) => {
  const phaseMap = {};
  const costcodeMap = getIdMap(costcodes);
  phases.forEach((phase) => {
    const {
      id,
      name,
      description,
      estimatedLabourHours,
      estimatedLabourCost,
      estimatedOverheadHours,
      estimatedOverheadCost,
      estimatedEquipmentHours,
      estimatedEquipmentCost,
      estimatedMaterialCost,
    } = phase;
    phaseMap[id] = {
      id,
      name,
      description,
      estimatedLabourHours,
      estimatedLabourCost,
      estimatedOverheadHours,
      estimatedOverheadCost,
      estimatedEquipmentHours,
      estimatedEquipmentCost,
      estimatedMaterialCost,
      costcodes: [],
    };
  });
  phaseCodes.forEach((pc) => {
    const {
      phaseId,
      costcodeId,
      hours,
      estimatedCost,
      estimatedQuantity,
      units,
      startDate,
      endDate,
    } = pc;
    if (phaseMap[phaseId] && costcodeMap[costcodeId]) {
      const cc = costcodeMap[costcodeId];
      phaseMap[phaseId].costcodes.push({
        ...cc,
        hours,
        estimatedCost,
        estimatedQuantity,
        units,
        startDate,
        endDate,
      });
    }
  });
  return Object.values(phaseMap);
};

export const prepareResponsesOnPayload = ({
  payload,
  responses,
  customFields,
}) => {
  const { responses: preparedResponses } = prepareResponsePayload({
    sections: customFields,
    responses,
  });

  return {
    ...payload,
    customData: preparedResponses,
  };
};

export const formatCustomFieldCreatePayload = ({
  payload = {},
  customFields,
}) => {
  const responses = payload.customData;

  const {
    errorMap,
  } = getErrorsFromResponses({ sections: customFields, responses });
  if (Object.keys(errorMap).length) {
    return {
      error: true,
      errorMap,
    };
  }

  return prepareResponsesOnPayload({
    payload,
    responses,
    customFields,
  });
};

export const getCostcodeUpdatePayload = ({
  id,
  payload,
  customFields,
  costcodes,
}) => {
  const warningKeys = new Set([
    'hourlyWage',
    'dailyWage',
    'wageAdjustment',
    'wageMultiplier',
    'hourlyBillingRate',
    'dailyBillingRate',
  ]);

  const responses = payload.customData;

  const {
    errorMap,
  } = getErrorsFromResponses({ sections: customFields, responses });
  if (Object.keys(errorMap).length) {
    return {
      error: true,
      errorMap,
    };
  }

  const relevantCostcode = costcodes.find((cc) => cc.id === id);

  // Only display warning if wage history is empty
  // and one of the warning keys is being updated
  if (
    !relevantCostcode?.wageHistory?.length
    && Object.keys(payload).some((key) => warningKeys.has(key))
  ) {
    return new Promise((resolve) => {
      CustomConfirmModal({
        title: 'Warning',
        content: 'Are you sure you want to edit this cost code? This will update all existing tasks with the new wage and billing rates.',
        okText: 'Save',
        cancelText: 'Cancel',
        async onOk() {
          resolve(prepareResponsesOnPayload({
            payload,
            responses,
            customFields,
          }));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  return prepareResponsesOnPayload({
    payload,
    responses,
    customFields,
  });
};
