import React from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'antd';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export const DEFAULT_FILE_PATH = 'Files/';

const FileDestinationSelect = ({
  isDisplay,
  filePath,
  openDestinationDrawer,
}) => {
  const fileDestinationDescription = (
    <span>Attachment Destination: <b>{filePath ?? DEFAULT_FILE_PATH}</b></span>
  );
  return (
    <>
      {isDisplay
        ? fileDestinationDescription
        : <Row align='middle' justify='space-between' gutter={[8, 0]}>
          <Col>
            {fileDestinationDescription}
          </Col>
          <Col style={{ marginRight: 5 }}>
            <OnTraccrButton
              title='Browse..'
              onClick={openDestinationDrawer}
            />
          </Col>
        </Row>}
    </>
  );
};

FileDestinationSelect.propTypes = {
  isDisplay: PropTypes.bool,
  filePath: PropTypes.string,
  openDestinationDrawer: PropTypes.func,
};

export default FileDestinationSelect;
