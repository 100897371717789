import React from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import IntegrationCostcodeSync from '../IntegrationCostcodeSync';
import {
  autoImportHeader,
  autoImportUncheckText,
  importCostcodesHelpText,
} from '../integrationHelpers';

const autoImportContent = (
  <div style={{ width: 500 }}>
    {autoImportHeader('Sage', 'Cost Codes')}
    <br/><br/>1. You can only import two levels of Cost Codes into Ontraccr
    <br/><br/>{autoImportUncheckText('Sage')}
  </div>
);

const getImportHelpText = (t) => importCostcodesHelpText(t, 'Sage');

export default function SageCostcodeSync({
  onCostcodesChanged,
  importCostcodes,
  divisionFilter,
}) {
  const { t } = useTranslation();
  const {
    sageData: {
      costcodes: sageCostcodes,
    } = {},
  } = useSelector((state) => state.settings);
  const importHelpText = getImportHelpText(t);

  return (
    <IntegrationCostcodeSync
      integrationCodes={sageCostcodes}
      title="Sage"
      integrationKey="sageId"
      onCostcodesChanged={onCostcodesChanged}
      showCustomer={false}
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      showProject
      importCostcodes={importCostcodes}
      divisionFilter={divisionFilter}
    />
  );
}

SageCostcodeSync.propTypes = {
  onCostcodesChanged: PropTypes.func,
  importCostcodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
  })),
  divisionFilter: PropTypes.instanceOf(Set),
};

SageCostcodeSync.defaultProps = {
  onCostcodesChanged: () => {},
  importCostcodes: [],
  divisionFilter: null,
};
