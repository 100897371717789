import {
  GET_VENDORS,
  DELETE_VENDOR,
  CREATE_VENDOR,
  UPDATE_VENDOR,
  ARCHIVE_VENDOR,
  GET_VENDOR_NOTES,
  ADD_VENDOR_NOTE,
  GET_VENDOR_LABELS,
  GET_QUICKBOOKS_VENDORS,
} from '../../../state/actionTypes';

import { getIdMap } from '../../../helpers/helpers';
import { getLabelsState, stateUpdateLabels } from '../../../helpers/labels';

const initialState = {
  vendors: {},
  notes: {},
  clientComms: {},
  unreadVendorComms: [],
  vendorToLabel: {},
  labelToVendor: {},
  quickBooksVendors: [],
};

export default (state = initialState, action = {}) => {
  switch (action.type) {
    case GET_VENDORS: {
      const {
        payload: {
          vendors = [],
        } = {},
      } = action;
      return {
        ...state,
        vendors: getIdMap(vendors),
      };
    }
    case CREATE_VENDOR: {
      const {
        payload: {
          vendor = {},
          labels,
        } = {},
      } = action;
      const {
        vendors: stateVendors = {},
        vendorToLabel: stateVendorToLabel = {},
        labelToVendor: stateLabelToVendor = {},
      } = state;
      const existingVendors = { ...stateVendors };
      existingVendors[vendor.id] = { ...vendor, active: 1 };

      const {
        newItemToLabel,
        newLabelToItem,
      } = stateUpdateLabels({
        id: vendor.id,
        stateItemToLabel: stateVendorToLabel,
        stateLabelToItem: stateLabelToVendor,
        addedLabels: labels,
      });

      return {
        ...state,
        vendors: existingVendors,
        vendorToLabel: newItemToLabel,
        labelToVendor: newLabelToItem,
      };
    }
    case UPDATE_VENDOR: {
      const {
        payload: {
          id,
          newData = {},
          addedLabels,
          removedLabels,
        } = {},
      } = action;
      const {
        vendors: stateVendors = {},
        vendorToLabel: stateVendorToLabel = {},
        labelToVendor: stateLabelToVendor = {},
      } = state;
      const existingVendors = { ...stateVendors };
      const updateData = { ...newData };
      existingVendors[id] = {
        ...existingVendors[id],
        ...updateData,
      };

      const {
        newItemToLabel,
        newLabelToItem,
      } = stateUpdateLabels({
        id,
        stateItemToLabel: stateVendorToLabel,
        stateLabelToItem: stateLabelToVendor,
        addedLabels,
        removedLabels,
      });

      return {
        ...state,
        vendors: existingVendors,
        vendorToLabel: newItemToLabel,
        labelToVendor: newLabelToItem,
      };
    }
    case DELETE_VENDOR: {
      const {
        payload: {
          id,
        } = {},
      } = action;
      const { vendors: stateVendors = {} } = state;
      const existingVendors = { ...stateVendors };
      delete existingVendors[id];
      return {
        ...state,
        vendors: existingVendors,
      };
    }
    case ARCHIVE_VENDOR: {
      const {
        payload: {
          id,
          active,
        } = {},
      } = action;
      const { vendors: stateVendors = {} } = state;
      const existingVendors = { ...stateVendors };
      existingVendors[id] = {
        ...existingVendors[id],
        active: active ? 1 : 0,
      };
      return {
        ...state,
        vendors: existingVendors,
      };
    }
    case GET_VENDOR_NOTES: {
      const stateKey = action.type === GET_VENDOR_NOTES ? 'notes' : 'clientComms';
      const {
        payload: {
          id,
          data: notes = [],
        } = {},
      } = action;
      const { [stateKey]: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      existingNotes[id] = notes;
      existingNotes[id].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        [stateKey]: existingNotes,
      };
    }
    case ADD_VENDOR_NOTE: {
      const {
        payload = {},
      } = action;
      const { vendorId } = payload;
      const { notes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [vendorId]: existingVendorNotes = [],
      } = existingNotes;
      existingNotes[vendorId] = existingVendorNotes.concat([payload]);
      existingNotes[vendorId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        notes: existingNotes,
      };
    }
    case GET_VENDOR_LABELS: {
      const {
        payload: {
          vendorLabels = [],
        } = {},
      } = action;
      const {
        itemToLabel,
        labelToItem,
      } = getLabelsState(vendorLabels, 'vendorId');
      return {
        ...state,
        vendorToLabel: itemToLabel,
        labelToVendor: labelToItem,
      };
    }
    case GET_QUICKBOOKS_VENDORS: {
      const {
        payload: {
          data: quickBooksVendors,
        } = {},
      } = action;
      return {
        ...state,
        quickBooksVendors,
      };
    }
    default:
      return state;
  }
};
