import React, { useMemo, useCallback } from 'react';
import { Tooltip, Typography } from 'antd';
import { DateTime, IANAZone } from 'luxon';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { TaskHelpers } from 'ontraccr-common';

import { getTaskDurationText } from '../../helpers/tasks';

const {
  Text,
} = Typography;

const TIME_FORMAT = 'hh:mm a';
const TIME_ZONE_FORMAT = 'hh:mm a ZZZZ';

const formatTimes = (startDT, endDT, zone) => {
  if (!startDT && !endDT) return '';
  // If a time range, only add zone to the end time
  const startTimeFormat = (endDT || !zone) ? TIME_FORMAT : TIME_ZONE_FORMAT;
  const startTimeText = startDT ? startDT.toFormat(startTimeFormat) : '';

  const endTimeFormat = zone ? TIME_ZONE_FORMAT : TIME_FORMAT;
  const endTimeText = endDT ? `${endDT.toFormat(endTimeFormat)}` : '';

  const connector = startDT && endDT ? ' -> ' : '';

  return `${startTimeText}${connector}${endTimeText}`;
};

export default function DateTimeWithZone({
  id,
  type,
  startTime,
  endTime,
  originalStart,
  originalEnd,
  timezone = '',
  style = {},
  ellipsis,
  hideSuffix,
  addTypeAsSuffix = false,
  addDurationToTimeBased,
}) {
  const company = useSelector((state) => state.settings.company);

  const {
    differentTimezone,
    localZoneTime,
    zoneTime,
  } = useMemo(() => {
    const {
      settings: {
        showOriginalTimes = false,
      } = {},
    } = company ?? {};

    const ourTimezone = DateTime.local().zoneName;
    const tz = timezone?.trim();
    const zone = IANAZone.isValidZone(tz) ? IANAZone.create(tz) : DateTime.local().zone;

    const realStart = TaskHelpers.getDisplayStart({ startTime, originalStart }, showOriginalTimes);
    const realEnd = TaskHelpers.getDisplayEnd({ endTime, originalEnd }, showOriginalTimes);

    const localStartDt = realStart ? DateTime.fromMillis(realStart) : null;
    const localEndDt = realEnd ? DateTime.fromMillis(realEnd) : null;
    const zoneStartDt = realStart ? DateTime.fromMillis(realStart, { zone }) : null;
    const zoneEndDt = realEnd ? DateTime.fromMillis(realEnd, { zone }) : null;

    const isDifferent = timezone && ourTimezone !== timezone;

    return {
      differentTimezone: isDifferent,
      localZoneTime: formatTimes(localStartDt, localEndDt, isDifferent),
      zoneTime: formatTimes(zoneStartDt, zoneEndDt, !hideSuffix),
    };
  }, [
    startTime,
    originalStart,
    endTime,
    originalEnd,
    timezone,
    hideSuffix,
    company,
  ]);

  const durationText = useMemo(() => {
    if (!addDurationToTimeBased) return null;
    return getTaskDurationText({
      startTime,
      endTime,
    }, { showAbbreviatedDuration: true });
  }, [
    addDurationToTimeBased,
    startTime,
    endTime,
  ]);

  const TimeText = useCallback(() => {
    const prefix = !addTypeAsSuffix && type ? `${type}: ` : '';
    const suffix = addTypeAsSuffix && type ? ` (${type})` : '';
    return (
      <Text
        id={id}
        ellipsis={!!ellipsis}
        style={style}
      >
        <span className="bold-text">{prefix}</span>
        {differentTimezone ? zoneTime : localZoneTime}
        {suffix}
        {durationText ? ` (${durationText})` : null}
      </Text>
    );
  }, [differentTimezone, zoneTime, localZoneTime, type, id, ellipsis, style, durationText]);

  return (
    (differentTimezone ? (
      <Tooltip title={localZoneTime}>
        <div>
          <TimeText />
        </div>
      </Tooltip>
    ) : (
      <TimeText />
    ))
  );
}

/* eslint-disable react/forbid-prop-types */
DateTimeWithZone.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  startTime: PropTypes.number,
  endTime: PropTypes.number,
  originalStart: PropTypes.number,
  originalEnd: PropTypes.number,
  timezone: PropTypes.string,
  style: PropTypes.object,
  ellipsis: PropTypes.object,
  hideSuffix: PropTypes.bool,
  addTypeAsSuffix: PropTypes.bool,
  addDurationToTimeBased: PropTypes.bool,
};

DateTimeWithZone.defaultProps = {
  id: null,
  type: null,
  startTime: null,
  endTime: null,
  originalStart: null,
  originalEnd: null,
  timezone: null,
  style: {},
  ellipsis: null,
  hideSuffix: false,
  addTypeAsSuffix: false,
  addDurationToTimeBased: false,
};
