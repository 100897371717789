import React from 'react';
import { Row } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../buttons/OnTraccrButton';

export default function DrawerSubmitFooter({
  onClose,
  onSubmit,
  onDelete,
  onAction,
  loading,
  canSubmit = true,
  submitTitle = 'Submit',
  actionTitle = 'Action',
  closeTitle = 'Cancel',
  submitButtonStyle = {},
  cancelButtonStyle = {},
  actionButtonStyle = {},
}) {
  return (
    <div className="drawer-footer">
      <Row justify="space-between">
        <Row justify="start" gutter={10}>

          {onDelete
            && (
            <OnTraccrButton
              style={{ marginRight: 8 }}
              title="Delete"
              type="back"
              onClick={onDelete}
              loading={loading}
            />
            )}
          {onAction && (
            <OnTraccrButton
              title={actionTitle}
              type="back"
              style={actionButtonStyle}
              onClick={onAction}
            />
          )}
        </Row>

        <Row justify="end" gutter={10}>

          {onClose && (
            <OnTraccrButton
              title={closeTitle}
              type="cancel"
              style={{ marginRight: 8, ...cancelButtonStyle }}
              onClick={onClose}
            />
          )}
          {onSubmit && (
            <OnTraccrButton
              title={submitTitle}
              disabled={!canSubmit}
              onClick={onSubmit}
              loading={loading}
              style={submitButtonStyle}
            />
          )}
        </Row>
      </Row>

    </div>
  );
}

DrawerSubmitFooter.propTypes = {
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  onDelete: PropTypes.func,
  onAction: PropTypes.func,
  loading: PropTypes.bool,
  canSubmit: PropTypes.bool,
  submitTitle: PropTypes.string,
  actionTitle: PropTypes.string,
  closeTitle: PropTypes.string,
  submitButtonStyle: PropTypes.shape({}),
  cancelButtonStyle: PropTypes.shape({}),
  actionButtonStyle: PropTypes.shape({}),
};

DrawerSubmitFooter.defaultProps = {
  onClose: null,
  onSubmit: null,
  onDelete: null,
  onAction: null,
  loading: false,
  canSubmit: true,
  submitTitle: 'Submit',
  actionTitle: 'Action',
  closeTitle: 'Cancel',
  submitButtonStyle: {},
  cancelButtonStyle: {},
  actionButtonStyle: {},
};
