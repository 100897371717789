import React from 'react';
import { Row } from 'antd';
import { SettingOutlined, SignatureOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import useToggle from '../../common/hooks/useToggle';
import WorkflowActionNode from './WorkflowActionNode';
import WorkflowHandle from './WorkflowHandle';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import WorkflowExternalSignatureConfigureDrawer from './WorkflowExternalSignatureConfigureDrawer';

const getText = (isConnected) => (
  <div>
    This step will generate a Docusign envelope for the form
    and send it to the configured signers.
    {!isConnected && (
      <>
        <br />
        <br />
        The Docusign integration must be configured.
      </>
    )}
  </div>
);

export default function WorkflowExternalSignatureNode({
  setDataMap,
  setElements,
  isDisplay,
  divisionId,
  sections = [],
  isExternalForm,
} = {}) {
  return function _({
    id,
    draggable,
    data,
    disabled,
  } = {}) {
    const title = `Collect External Signature${disabled ? ' - DISABLED' : ''}`;

    const {
      connectedToDocusign,
    } = useSelector((state) => state.settings.company) ?? {};

    const {
      isToggled: showDrawer,
      toggle: toggleDrawer,
    } = useToggle();

    return (
      <WorkflowActionNode
        title={title}
        Icon={SignatureOutlined}
        type="externalSignature"
        id={id}
        draggable={draggable}
        onNodeUpdate={setElements}
        isDisplay={isDisplay || disabled}
        hover={getText(connectedToDocusign)}
        style={disabled ? { opacity: 0.7 } : { width: 250 }}
      >
        {!draggable && (
          <div>
            <Row style={{ margin: '20px 0px' }}>
              <BorderlessButton
                title="Configure"
                style={{ margin: 5 }}
                iconNode={<SettingOutlined />}
                onClick={toggleDrawer}
              />
            </Row>

            <WorkflowHandle type="target" position="top" disabled={isDisplay} />
            <WorkflowExternalSignatureConfigureDrawer
              id={id}
              visible={showDrawer}
              onClose={toggleDrawer}
              onSubmit={toggleDrawer}
              divisionId={divisionId}
              sections={sections}
              data={data}
              setDataMap={setDataMap}
              setElements={setElements}
              isExternalForm={isExternalForm}
            />
          </div>
        )}
      </WorkflowActionNode>
    );
  };
}
