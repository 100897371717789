import React from 'react';

import BorderlessButton from '../common/buttons/BorderlessButton';

import colors from '../constants/Colors';

export default {};

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

export const getSettingsColumns = ({
  editData = {},
  onEdit,
  onDelete,
}) => [{
  title: 'Name',
  dataIndex: 'title',
  onHeaderCell,
},
{
  dataIndex: 'edit',
  align: 'edit',
  onHeaderCell,
  width: 60,
  render: (_, record) => {
    const isEdit = record.labelId in editData;
    const icon = isEdit ? 'close' : 'edit';
    return (
      <BorderlessButton
        title=""
        icon={icon}
        color={colors.ONTRACCR_BLACK}
        onClick={() => onEdit(record)}
        style={{
          paddingRight: 8,
          paddingLeft: 0,
        }}
      />
    );
  },
},
{
  dataIndex: 'action',
  key: 'action',
  align: 'center',
  width: 60,
  onHeaderCell,
  render: (_, record) => (
    <BorderlessButton
      title=""
      icon="delete"
      color="red"
      onClick={() => onDelete(record)}
      style={{
        paddingRight: 8,
        paddingLeft: 0,
      }}
    />
  ),
}];
