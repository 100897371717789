import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Row, Col, Spin,
} from 'antd';
import PropTypes from 'prop-types';

import Logo from '../common/Logo';

import colors from '../constants/Colors';

function FileLinks({
  match: {
    params: {
      url,
    } = {},
  } = {},
}) {
  const [result, setResult] = useState();
  useEffect(() => {
    const download = async () => {
      try {
        const res = await axios.get('/files/link', { params: { url } });
        const {
          data: link,
        } = res;
        if (!link) {
          setResult({
            statusCode: 400,
            message: 'Error downloading file.',
          });
        } else {
          window.location.href = link;
          setResult({
            statusCode: 200,
            message: 'File Downloaded',
          });
        }
      } catch (err) {
        const message = err?.response?.data?.message;
        setResult({
          statusCode: 400,
          message: message ?? 'Error downloading file.',
        });
      }
    };
    download();
  }, [url]);

  return (
    <Row
      justify="center"
      align="top"
      style={{
        backgroundColor: colors.MAIN_BACKGROUND,
        height: '100vh',
        paddingTop: 100,
      }}
    >
      <Col>
        <Logo />
        {
          !result && (
            <Row justify="center">
              <Spin />
            </Row>
          )
        }
        {
          result && (
            <div
              style={{
                marginTop: 75,
                fontFamily: 'roboto-bold',
                fontSize: 24,
                color: result.statusCode === 200 ? 'black' : colors.ONTRACCR_RED,
                marginBottom: 40,
                textAlign: 'center',
              }}
            >
              {result.message}
            </div>
          )
        }
      </Col>
    </Row>
  );
}

FileLinks.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      url: PropTypes.string,
    }),
  }).isRequired,
};

export default FileLinks;
