import PropTypes from 'prop-types';
import React, {
  useCallback,
  useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import {
  Col, Collapse, TreeSelect,
} from 'antd';

import { fieldToSearch, isSupported } from './searchHelpers';

export default function BoardsFieldsSearch({
  boardIds,
  query,
  onQueriesChange,
}) {
  const boards = useSelector((state) => state.boards.boards);
  const cardTemplates = useSelector((state) => state.boards.cardTemplates);

  const boardIdsSet = useMemo(() => (
    new Set(boardIds)
  ), [boardIds]);

  const boardList = useMemo(() => (
    Object.values(boards).filter(({ id }) => (!boardIdsSet.size || boardIdsSet.has(id)))
  ), [boards, boardIdsSet]);

  const ourFieldMap = useMemo(() => {
    const map = {};
    boardList.forEach(({ id, cardTypeId }) => {
      const { fields: sections = [] } = cardTemplates[cardTypeId];
      map[id] = {};
      sections.forEach(({ fields = [] }) => {
        fields.forEach((field) => {
          map[id][field.id] = field;
        });
      });
    });
    return map;
  }, [boardList, cardTemplates]);

  const boardIdSet = useMemo(() => (new Set(boardIds)), [boardIds]);

  const ourSearchFields = useMemo(() => (
    Object.entries(query?.custom || {}).map(([boardId, boardFieldQueries = {}]) => {
      if (boardIdSet.size && !boardIdSet.has(boardId)) return undefined;
      const fieldComponents = [];
      Object.entries(boardFieldQueries).forEach(([fieldId, fieldQueries]) => {
        const ourField = ourFieldMap[boardId]?.[fieldId];
        if (ourField) {
          fieldComponents.push(
            fieldToSearch(fieldQueries, onQueriesChange(boardId), true)(ourField),
          );
        }
      });
      return (
        <Collapse.Panel
          header={boards[boardId]?.title}
          key={boardId}
        >
          <div
            style={{
              width: '100%',
              paddingLeft: '10px',
              paddingBottom: '10px',
            }}
          >
            {fieldComponents}
          </div>
        </Collapse.Panel>
      );
    })
  ), [query, onQueriesChange, ourFieldMap, boards, boardIdSet]);

  const treeData = useMemo(() => boardList.map(({ id: boardId, title: boardTitle, cardTypeId }) => {
    const { fields: sections = [] } = cardTemplates[cardTypeId] || {};
    const option = {
      value: boardId,
      title: boardTitle,
      selectable: false,
      children: [],
    };
    sections.forEach(({ id: sectionId, name: sectionTitle, fields = [] }) => {
      const sectionOption = {
        value: `${boardId}_${sectionId}`,
        title: sectionTitle,
        selectable: false,
        children: [],
      };
      fields.forEach(({ id: fieldId, selectedType, configProps: { title: fieldTitle = '', dataType } = {} }) => {
        if (isSupported(selectedType, { dataType })) {
          sectionOption.children.push({
            value: `${boardId}_${fieldId}`,
            title: fieldTitle,
            selectable: true,
            isLeaf: true,
          });
        }
      });
      if (sectionOption.children.length) option.children.push(sectionOption);
    });
    return option;
  }), [cardTemplates, boardList]);

  const onFieldAdd = useCallback((value) => {
    const [boardId, fieldId] = value.split('_');
    onQueriesChange(boardId)(fieldId)([]);
  }, [onQueriesChange]);

  return (
    <Col style={{ width: '100%', marginTop: '10px', marginRight: '10px' }}>
      <TreeSelect
        showSearch
        onChange={onFieldAdd}
        treeNodeFilterProp="title"
        placeholder="Add custom field queries"
        treeData={treeData}
        style={{
          width: '100%',
          margin: '0px 10px',
          paddingRight: '10px',
          paddingBottom: '10px',
        }}
        value={null}
      />
      <Collapse>
        {ourSearchFields}
      </Collapse>
    </Col>
  );
}

BoardsFieldsSearch.propTypes = {
  boardIds: PropTypes.array,
  query: PropTypes.shape({ custom: PropTypes.shape({}) }),
  onQueriesChange: PropTypes.func.isRequired,
};

BoardsFieldsSearch.defaultProps = {
  boardIds: [],
  query: {},
};
