import React from 'react';
import { useSelector } from 'react-redux';

import 'antd/dist/antd.css';
import '../../index.css';
import { Link, useHistory } from 'react-router-dom';
import {
  CalendarOutlined,
  SwapOutlined,
  GlobalOutlined,
  HomeOutlined,
  UserOutlined,
  FormOutlined,
  ProjectOutlined,
  SettingOutlined,
  TeamOutlined,
  ShareAltOutlined,
  BarChartOutlined,
  CarryOutOutlined,
  FileOutlined,
  FieldTimeOutlined,
  ToolOutlined,
  ContactsOutlined,
  GoldOutlined,
  GroupOutlined,
  ExperimentOutlined,
  TableOutlined,
  DollarOutlined,
  EnvironmentOutlined,
  SearchOutlined,
  ProfileOutlined,
} from '@ant-design/icons';
import {
  Breadcrumb,
  Layout,
  Divider,
  Row,
  Col,
} from 'antd';

import { HelpDropdown } from './HelpMenu';
import { NotificationsDropdown } from './notifications/Notifications';
import ProfileIcon from './ProfileIcon';

import NuxFocusBackground from '../../nux/NuxFocusBackground';
import NuxPopover from '../../nux/NuxPopover';
import IconPickerMap from '../IconPicker/iconPickerMap';

import {
  GET_STARTED_CLOSE_NUX_STEP_1,
  NEW_MENU_NUX_STEP_1,
  NEW_MENU_TYPE_TEXT,
} from '../../nux/nux.constants';

import colors from '../../constants/Colors';

const { Content, } = Layout;

const iconStyle = {
  marginRight:'5px',
  fontSize:20,
};

const getIcon = ({ icon }) => {
  switch (icon) {
    case 'timecard': return FieldTimeOutlined;
    case 'calendar': return CalendarOutlined;
    case 'swap': return SwapOutlined;
    case 'global': return GlobalOutlined;
    case 'home': return HomeOutlined;
    case 'user': return UserOutlined;
    case 'project': return ProjectOutlined;
    case 'setting': return SettingOutlined;
    case 'team': return TeamOutlined;
    case 'share-alt': return ShareAltOutlined;
    case 'bar-chart': return BarChartOutlined;
    case 'carry-out': return CarryOutOutlined;
    case 'file': return FileOutlined;
    case 'form': return FormOutlined;
    case 'equipment': return ToolOutlined;
    case 'address-book':
    case 'customers':
      return ContactsOutlined;
    case 'vendors': return CarryOutOutlined;
    case 'materials': return GoldOutlined;
    case 'group': return GroupOutlined;
    case 'analytics': return ExperimentOutlined;
    case 'table': return TableOutlined;
    case 'payables': return DollarOutlined;
    case 'environment': return EnvironmentOutlined;
    case 'search': return SearchOutlined;
    case 'profile': return ProfileOutlined;
    default: return IconPickerMap[icon];
  }
};

export default ({
  bodyStyle = {},
  fullscreen = false,
  crumbStyle = {},
  crumbs = [],
  children,
  showHeader = true,
}) => {
  const activeNuxAction = useSelector(state => state.nux.activeNuxAction);
  const history = useHistory();
  let crumbsView = [];
  crumbs.forEach((crumb,idx) => {
    const OurIcon = getIcon(crumb);
    const { style = {} } = crumb;
    crumbsView.push(
      (crumb.link) ?
      <Breadcrumb.Item key={idx.toString()} style={style}>
        <Link to={crumb.link} className='breadcrumb-link'>
          {OurIcon ? <OurIcon style={iconStyle}/> : ''}
          {crumb.text}
        </Link>
      </Breadcrumb.Item> :
      <Breadcrumb.Item key={idx.toString()} style={style}>
        {OurIcon ? <OurIcon style={iconStyle}/> : ''}
        {crumb.text}
      </Breadcrumb.Item>
    );
  });
  const isGetStartedNuxStep1 = activeNuxAction === GET_STARTED_CLOSE_NUX_STEP_1;
  const isNewMenuNux = activeNuxAction === NEW_MENU_NUX_STEP_1;
  return (
    <Content style={{ position:'relative', margin: '0 0',paddingLeft:20,paddingRight:20, }}>
      <NuxFocusBackground show={activeNuxAction}/>
      { showHeader && (
        <>
          <Row
            flex='type'
            justify='space-between'
            style={{height:fullscreen ? 0: 'auto', transition:'0.5s', overflow:'hidden' }}
          >
            <Col span={18}>
              <Breadcrumb style={{ margin: '16px 24px', ...crumbStyle }}>
                {crumbsView}
              </Breadcrumb>
            </Col>
            <Col
              span={6}
              style={isNewMenuNux ? {
                zIndex: 100,
                backgroundColor: 'white',
              } : {}}
            >
              <NuxPopover
                visible={isNewMenuNux}
                text={NEW_MENU_TYPE_TEXT}
                placement='bottomRight'
              >
                <Row
                  type='flex'
                  justify='end'
                  style={{
                    margin: '16px 24px',
                  }}
                  align='middle'
                  gutter={16}
                >
                  <Col>
                    <div className='header-container' onClick={() => { history.push('/search') }}>
                      <SearchOutlined style={{ fontSize: 16 }} />
                    </div>
                  </Col>
                  <Col>
                    <NotificationsDropdown/>
                  </Col>
                  <Col>
                    <HelpDropdown
                      iconStyle={isGetStartedNuxStep1 ? { zIndex: 100, } : {}}
                      {...isGetStartedNuxStep1 ? { visible: true } : {}}
                    />

                  </Col>
                  <Col>
                    <ProfileIcon/>
                  </Col>
                </Row>
              </NuxPopover>
            </Col>
          </Row>

          <Row style={{
            width:'100%',
            height:fullscreen ? 0 : 1,
            opacity: fullscreen ? 0 : 1,
          }}>
            <Divider style={{
              backgroundColor:colors.ONTRACCR_BLACK,
              height:1,
              marginTop:0,
            }}/>
          </Row>
        </>
      )}

      <div style={{
          margin: 15,
          minHeight: 280,
          height: fullscreen ? 'calc(100vh - 30px)' : '84vh',
          ...bodyStyle,
      }}>
          {children}
      </div>
      </Content>
  );
};