import React from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'antd';
import { withRouter } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import CostCodeCard from './costcodeCard';
import CostCodeAddView from './CostCodeAddView';
import CostCodeEditView from './CostCodeEditView';
import {
  archiveCostCode,
  createCostCodes,
  updateCostCode,
  deleteCostCodeById,
} from './state/costcodes.actions';
import { createNuxEntry } from '../nux/state/nux.actions';
import SideList from '../common/SideList';
import SideListSearchHeader from '../common/SideListSearchHeader';
import SideListText from '../common/SideList/SideListText';
import CardGrid from '../common/cardGrid/cardGrid';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import WarningHeader from '../common/text/WarningHeader';

import sortByString, { search, getHighestNumber } from '../helpers/helpers';
import Analytics from '../helpers/Analytics';

import Permissions from '../auth/Permissions';

import {
  COSTCODE_PROJECT_SPECIFIC_TYPE,
} from '../nux/nux.constants';
import { columnsToDisplay } from './costcodesList';
import { formatCustomFieldCreatePayload, getCostcodeUpdatePayload } from '../helpers/costcodeHelpers';

const renderItem = ({
  name,
  number,
}) => (
  <Row
    type="flex"
    align="middle"
    style={{
      width: '100%',
      pointerEvents: 'all',
      cursor: 'pointer',
      height: 30,
    }}
  >
    <SideListText
      text={number ? `${number} - ${name}` : name}
    />
  </Row>
);

class CostCodeProjects extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projects: [],
      selectedDivisionIds: new Set(),
      isListView: window.localStorage.getItem('ccViewTypeProjects'),
      errors: {},
      customFields: [],
    };

    this.onSearch = this.searchList.bind(this);
    this.onProjectSelected = this.projectSelected.bind(this);
    this.onCreateCostCode = this.createCostCodeWithProject.bind(this);
    this.onUpdate = this.update.bind(this);
    this.onArchive = this.archiveCostcode.bind(this);
    this.onDelete = this.deleteCostcode.bind(this);
    this.onExportCSV = this.exportCSV.bind(this);
    this.onExportExcel = this.exportExcel.bind(this);
    this.onViewTypeChanged = this.onViewTypeChanged.bind(this);
  }

  componentDidMount() {
    const {
      createNuxEntry,
      nux = new Set(),
      location: {
        state: {
          targetId: locationTargetId,
          projectId: locationProjectId,
        } = {},
      } = {},
      projects = [],
      selectedDivisionIds = new Set(),
      t,
      setBreadcrumb,
    } = this.props;

    this.setState({
      selectedDivisionIds,
    });

    setBreadcrumb([{
      text: 'Cost Codes',
      icon: 'share-alt',
    }, {
      text: `${t('Project')} Specific`,
    }]);
    this.setInitialProjectState();
    if (!nux.has(COSTCODE_PROJECT_SPECIFIC_TYPE)) {
      createNuxEntry(COSTCODE_PROJECT_SPECIFIC_TYPE);
    }

    if (locationTargetId) {
      if (locationProjectId) {
        const ourProject = projects.find((project) => project.id === locationProjectId);
        if (ourProject) {
          this.setState({
            selectedProject: ourProject,
          });
        }
      }
      if (this.cardRef && locationTargetId) {
        this.cardRef.targetItem = { id: locationTargetId };
      }
    }
  }

  componentDidUpdate(prevProps) {
    const {
      projects = [],
      costcodes = [],
      selectedDivisionIds = new Set(),
    } = this.props;
    const {
      projects: prevProjects = [],
      costcodes: prevCostCodes = [],
      selectedDivisionIds: prevSelectedDivisionIds = [],
    } = prevProps;

    if (selectedDivisionIds !== prevSelectedDivisionIds) {
      this.updateProjectState(projects, selectedDivisionIds)
    }

    if (projects !== prevProjects || !this.state.projects) {
      this.setInitialProjectState();
    }

    if (costcodes !== prevCostCodes) {
      this.filterCostCodes(this.state.selectedProject);
    }
  }

  setInitialProjectState() {
    const {
      projects = [],
    } = this.props;

    this.projects = projects.sort(sortByString('name'));
    this.setState({
      projects,
    });

    if (!this.state.selectedProject) {
      this.setState({
        selectedProject: this.projects[0],
      });
      this.filterCostCodes(this.projects[0]);
    }
  }

  updateProjectState(projects, selectedDivisionIds) {
    const {
      selectedProject: {
        divisionId: selectedProjectDivisionId,
      } = {},
    } = this.state;

    const filteredProjects = projects.filter(({ divisionId }) =>
      selectedDivisionIds.has(divisionId),
    );
    this.projects = filteredProjects;
    this.setState({
      projects: filteredProjects,
      selectedDivisionIds,
    });

    if (!selectedDivisionIds.has(selectedProjectDivisionId)) {
      const [project] = filteredProjects;
      this.projectSelected(project);
    }
  }

  searchList(e) {
    const searchTerm = e.target.value.toLowerCase();
    this.setState({
      projects: search({
        data: this.projects,
        value: searchTerm,
        getField: (project) => project.number
          ? `${project.number} - ${project.name}`
          : project.name,
      }),
    });
  }

  projectSelected(project) {
    this.setState({
      selectedProject: project,
    });
    this.filterCostCodes(project);
  }

  filterCostCodes(selectedProject = {}) {
    this.setState({
      costcodes: this.props.costcodes
        .filter((costcode) => costcode.projectId === selectedProject.id),
    });
  }

  createCostCodeWithProject(values) {
    const {
      selectedProject,
      customFields,
    } = this.state;
    if (!selectedProject) {
      console.error('No Project selected', this.state);
    }
    Analytics.track('Costcode/Create/ProjectSpecific');

    let newValues;
    if (values && values instanceof Array) {
      newValues = values.map((val) => ({ ...val, divisionId: selectedProject.divisionId }));
    } else if (values) {
      const payload = formatCustomFieldCreatePayload({
        payload: values,
        customFields,
      });
      if (payload?.error || !payload) {
        return this.setState({
          errors: payload?.errorMap,
        });
      }

      newValues = payload;
    }

    this.setState({
      errors: {},
    });

    return this.props.createCostCodes(newValues, selectedProject.id);
  }

  async update(id, payload) {
    const {
      customFields,
      costcodes,
    } = this.state;

    const { updateCostCode } = this.props;

    const res = await getCostcodeUpdatePayload({
      id,
      payload,
      customFields,
      costcodes,
    });

    if (res?.error || !res) {
      return this.setState({
        errors: res?.errorMap,
      });
    }

    this.setState({
      errors: {},
    });

    return updateCostCode(id, res);
  }

  archiveCostcode(costcode) {
    return new Promise((resolve) => {
      const archiveFunc = this.props.archiveCostCode;
      const mode = costcode.active ? 'Archive' : 'Activate';

      CustomConfirmModal({
        title: `${mode} Cost Code ${costcode.code}- ${costcode.name}?`,
        okText: mode,
        cancelText: 'Cancel',
        async onOk() {
          resolve(archiveFunc(costcode.id, !costcode.active));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  deleteCostcode(costcode) {
    const {
      deleteCostCodeById,
    } = this.props;
    return new Promise((resolve) => {
      CustomConfirmModal({
        title: `Delete Cost Code ${costcode.code}- ${costcode.name}?`,
        content: (
          <div>
            <WarningHeader justify="center" />
            <br />
            Deleting a cost code will unlink all tracked entries for that cost code
          </div>
        ),
        okText: 'Delete',
        cancelText: 'Cancel',
        async onOk() {
          resolve(deleteCostCodeById(costcode.id));
        },
        onCancel() {
          resolve();
        },
      });
    });
  }

  exportCSV() {
    const {
      costcodes = [],
    } = this.state;
    this.props.onExport(costcodes, 'csv');
  }

  exportExcel() {
    const {
      costcodes = [],
    } = this.state;
    this.props.onExport(costcodes, 'xlsx');
  }

  onViewTypeChanged(checked) {
    window.localStorage.setItem('ccViewTypeProjects', checked);
    this.setState({ isListView: checked });
  }

  render() {
    const {
      projects = [],
      costcodes = [],
      selectedProject,
      errors = {},
      customFields = [],
    } = this.state;

    const {
      costcodes: propCostcodes = [],
      company: {
        settings: {
          suggestNumbers,
        } = {},
      } = {},
    } = this.props;
    const globalCodes = propCostcodes.filter((costcode) => !(costcode.projectId));

    const selectedSet = new Set([]);

    if (selectedProject) {
      const ourProj = this.state.projects.filter((project) => project.id === selectedProject.id);
      if (ourProj.length > 0) {
        selectedSet.add(ourProj[0]);
      }
    }

    const initialCode = suggestNumbers ? (getHighestNumber(costcodes, 'code') + 1).toString() : null;

    const addView = (form, formProps) => (
      <CostCodeAddView
        {
          ...{
            ...form,
            ...formProps,
            isProjectSpecific: true,
            costcodes: globalCodes,
            initialCode: initialCode,
          }
        }
        errors={errors}
        customFields={customFields}
        setCustomFields={(fields) => this.setState({ customFields: fields })}
      />
    );

    const editView = (form, formProps) => (
      <CostCodeEditView
        form={form}
        formProps={formProps}
        isProjectSpecific
        errors={errors}
        customFields={customFields}
        setCustomFields={(fields) => this.setState({ customFields: fields })}
      />
    );

    return (
      <Row style={{ height: '100%', width: '100%' }}>
        <Col span={6} style={{ height: '100%', paddingRight: 10 }}>
          <SideList
            id="project-specific-costcodes"
            key="project-specific-costcodes"
            header={(
              <SideListSearchHeader
                onChange={this.onSearch}
              />
            )}
            dataSource={projects.filter((proj) => proj.active)}
            renderItem={renderItem}
            onClick={this.onProjectSelected}
            selectedItems={selectedSet}
            rowHeight={30}
            scrollable
          />
        </Col>
        <Col span={18} style={{ height: '100%', paddingLeft: 10 }}>
          <CardGrid
            onRef={(ref) => this.cardRef = ref}
            dataSource={costcodes}
            itemView={CostCodeCard}
            itemDimensions={{ width: 239, height: 190 }}
            colCount={3}
            pageSize={6}
            add={{
              title: 'Enter Cost Code Details',
              formView: addView,
              width: 'fit-content',
              onClose: this.onCreateCostCode,
            }}
            edit={{
              title: 'Edit Cost Code Details',
              formView: editView,
              width: 800,
              onClose: this.onUpdate,
              canEdit: () => Permissions.has('COSTCODES_WRITE'),
              canArchive: () => Permissions.has('COSTCODES_WRITE'),
              canDelete: () => Permissions.has('COSTCODES_WRITE'),
            }}
            onArchive={this.onArchive}
            onDelete={this.onDelete}
            search={this.props.search}
            more={this.props.more({
              onExportExcel: this.onExportExcel,
              onExportCSV: this.onExportCSV,
            })}
            sort={this.props.sort}
            filter={this.props.filter}
            filterActive={this.props.filterIsActive}
            onToggleListViewSwitch
            isListView={this.state.isListView}
            onViewTypeChanged={this.onViewTypeChanged}
            displayColumns={columnsToDisplay}
          />
        </Col>
      </Row>
    );
  }
}

export default withTranslation()(
  connect((state, ownProps) => ({
    ...ownProps,
    nux: state.nux.nux,
    company: state.settings.company,
    selectedDivisionIds: state.settings.selectedDivisions,
  }), {
    createCostCodes,
    updateCostCode,
    archiveCostCode,
    deleteCostCodeById,
    createNuxEntry,
  })(withRouter(CostCodeProjects))
);
