import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { DragDropContext } from 'react-beautiful-dnd';
import { Row, Spin } from 'antd';

import { PropTypes } from 'prop-types';

import BoardColumn from './BoardColumn';

import {
  moveCard,
  openCardDrawer,
  openFormMapDrawer,
  setCardDragging,
} from '../state/boards.actions';

import {
  getCardsByStatus,
  handleCardMove,
} from '../boards.helpers';

import { getIdMap } from '../../helpers/helpers';

export default function BoardView({
  id: boardId,
  cardTypeId,
  isLocked,
  disableCrossColumnSorting,
  showCardNumber,
  useCardNumberAsCardTitle,
  userBoardPermissions,
  loading,
  onBoardSelected,
}) {
  const dispatch = useDispatch();

  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const { type: sortType, field } = useSelector((state) => state.boards.sortType);
  const {
    [cardTypeId]: cardTemplate = {},
  } = useSelector((state) => state.boards.cardTemplates);
  const isFullScreen = useSelector((state) => state.main.menuCollapsed || state.main.fullscreen);
  const filters = useSelector((state) => state.boards.filters);
  const projects = useSelector((state) => state.projects.projects);

  const {
    statuses = [],
    cards = [],
  } = selectedBoard || {};

  const cardMap = useMemo(() => getIdMap(cards), [cards]);
  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const cardsByStatusId = useMemo(() => (
    getCardsByStatus({
      cards,
      sortType,
      filters,
      field,
      projects,
    })
  ), [cards, sortType, filters, field, projects]);

  const onAddCardWithStatus = useCallback((statusId) => {
    const {
      [statusId]: ourCards = [],
    } = cardsByStatusId;
    dispatch(openCardDrawer({
      statusId,
      cards: ourCards,
    }));
  }, [dispatch, cardsByStatusId]);

  const onDragStart = useCallback(() => {
    dispatch(setCardDragging(true));
  }, []);

  const onDragEnd = useCallback((dragEvent) => {
    dispatch(setCardDragging(false));
    const {
      draggableId,
      destinationId,
      destinationIndex,
      sourceId,
      sourceIndex,
    } = handleCardMove({
      disableCrossColumnSorting,
      cardsByStatusId,
      dragEvent,
    });
    const { color: originalColor } = cardMap[draggableId] || {};
    if (!draggableId) return;
    dispatch(moveCard(draggableId, {
      boardId,
      destinationId,
      destinationIndex,
      sourceId,
      sourceIndex,
      color: originalColor,
    }));
  }, [dispatch, boardId, disableCrossColumnSorting, cardsByStatusId, cardMap]);

  const onForm = useCallback((cardId) => {
    const {
      [cardId]: newSelectedCard,
    } = cardMap;
    if (!newSelectedCard) return;
    dispatch(openFormMapDrawer({
      selectedCardId: cardId,
    }));
  }, [dispatch, cardMap]);

  const onClick = useCallback((cardId) => {
    const {
      [cardId]: newSelectedCard,
    } = cardMap;
    if (!newSelectedCard) return;
    dispatch(openCardDrawer({
      selectedCardId: cardId,
      onForm,
    }));
  }, [dispatch, cardMap, onForm]);

  const onEdit = useCallback((cardId) => {
    const {
      [cardId]: newSelectedCard,
    } = cardMap;
    if (!newSelectedCard) return;
    dispatch(openCardDrawer({
      selectedCardId: cardId,
      editMode: true,
      onForm,
    }));
  }, [dispatch, cardMap, onForm]);

  const columns = useMemo(() => {
    const ourColumns = [...statuses];
    ourColumns.sort((a, b) => a.orderIndex - b.orderIndex);
    return ourColumns.map((status) => {
      const {
        [status.id]: ourCards = [],
      } = cardsByStatusId;
      return (
        <BoardColumn
          key={status.id}
          isLocked={isLocked}
          showCardNumber={useCardNumberAsCardTitle ? false : showCardNumber}
          {...status}
          onAdd={onAddCardWithStatus}
          cards={ourCards}
          onClick={onClick}
          onEdit={onEdit}
          onForm={onForm}
          cardTemplate={cardTemplate}
          userBoardPermissions={userBoardPermissions}
          projectIdMap={projectIdMap}
          onBoardSelected={onBoardSelected}
        />
      );
    });
  }, [statuses, isLocked, onAddCardWithStatus, cardsByStatusId,
    onClick, onEdit, cardTemplate, userBoardPermissions, projectIdMap, onBoardSelected]);

  const className = isFullScreen ? 'boards-container-fullscreen' : 'boards-container-regular';

  if (!selectedBoard || loading) {
    return (
      <Row justify="center" align="middle" style={{ height: '100%', paddingBottom: 125 }}>
        <Spin />
      </Row>
    );
  }
  return (
    <div className={className}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        {columns}
      </DragDropContext>
    </div>
  );
}

BoardView.propTypes = {
  id: PropTypes.string.isRequired,
  cardTypeId: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  disableCrossColumnSorting: PropTypes.bool.isRequired,
  showCardNumber: PropTypes.bool,
  useCardNumberAsCardTitle: PropTypes.bool,
  userBoardPermissions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  onBoardSelected: PropTypes.func.isRequired,
};

BoardView.defaultProps = {
  showCardNumber: false,
  useCardNumberAsCardTitle: false,
  userBoardPermissions: {},
  loading: false,
};
