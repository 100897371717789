import { React, useCallback } from 'react';
import { Row } from 'antd';
import OnTraccrNumberInput from './OnTraccrNumberInput';

function VerticallyCenteredNumberInput(props) {
  const onChange = useCallback((value) => {
    if (!Number.isNaN(value) && props.asFloat) {
      const valueTwoDecimals = Number.parseFloat(value).toFixed(2);
      props.onChange(Number.parseFloat(valueTwoDecimals));
    } else if (Number.isInteger(value) && !props.asFloat) {
      props.onChange(value);
    }
  }, [props]);

  return (
    <Row style={{ height: '100%' }} align="middle">
      <OnTraccrNumberInput
        {...props}
        onChange={onChange}
      />
    </Row>
  );
}

export default VerticallyCenteredNumberInput;
