import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import PropTypes from 'prop-types';
import {
  Col, DatePicker, Row, Select,
  TreeSelect,
} from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import moment from 'moment';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import { currencyFormatter, getIdMap, toTitleCase } from '../helpers/helpers';
import { getTableColumnType, hiddenColumns } from './search.constants';
import { formatCardTitle } from '../boards/boardHelpers';
import Colors from '../constants/Colors';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import { currencyParser } from '../helpers/inputParsers';
import BorderlessButton from '../common/buttons/BorderlessButton';
import { formatFormDropdownList } from '../forms/formHelpers';

export default function SearchFieldRow({
  type,
  configProps,
  query,
  onQueryChange,
  onDelete,
  boardIds,
}) {
  const {
    column,
    operator = '=',
    value: queryValue,
  } = query || {};

  const {
    columns,
    dataType,
    subDataType,
  } = configProps || {};

  const projects = useSelector((state) => state.projects.projects);
  const users = useSelector((state) => state.users.users);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const phases = useSelector((state) => state.costcodes.phases);
  const customersMap = useSelector((state) => state.customers.customers);
  const vendorsMap = useSelector((state) => state.vendors.vendors);
  const customTables = useSelector((state) => state.forms.customTables);
  const equipment = useSelector((state) => state.equipment.equipment);
  const formTemplates = useSelector((state) => state.forms.templates);
  const labels = useSelector((state) => state.labels);
  const globalAddressBooks = useSelector((state) => state.contacts.globalAddressBooks);
  const formStatuses = useSelector((state) => state.forms.statuses);
  const boards = useSelector((state) => state.boards.boards);
  const boardDetailsMap = useSelector((state) => state.boards.boardDetailsMap);
  const {
    locals = [],
    classes = [],
  } = useSelector((state) => state.unions);
  const divisions = useSelector((state) => state.settings.divisions);
  const globalMaterialLocations = useSelector((state) => (
    state.globalMaterialLocations.globalMaterialLocations
  ));
  const forms = useSelector((state) => state.forms.forms);
  const bucketTemplateMap = useSelector((state) => state.buckets.bucketTemplateMap);
  const materials = useSelector((state) => state.materials.materials);

  const formList = useMemo(() => Object.values(forms), [forms]);
  const customers = useMemo(() => Object.values(customersMap), [customersMap]);
  const vendors = useMemo(() => Object.values(vendorsMap), [vendorsMap]);

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const phaseMap = useMemo(() => getIdMap(phases), [phases]);

  const [columnType, setColumnType] = useState();
  const [columnDataType, setColumnDataType] = useState();

  useEffect(() => {
    if (type !== 'table') return;
    if (dataType in customTables) {
      setColumnType('text');
      setColumnDataType();
    } else {
      const { type: colType, dataType: colDataType } = getTableColumnType(dataType, column);
      setColumnType(colType);
      setColumnDataType(colDataType);
    }
  }, [type, dataType, customTables, column]);

  const ourType = type === 'table' ? columnType : type;
  const ourDataType = type === 'table' ? columnDataType : dataType;

  const onDataChanged = useCallback((key) => (newVal) => {
    const newQuery = { ...query, [key]: newVal };
    onQueryChange(newQuery);
  }, [query, onQueryChange]);

  const formatCostcodeName = useCallback((costcode) => {
    const { projectId, name, phaseId } = costcode;
    const project = projectIdMap[projectId];
    const phase = phaseMap[phaseId];
    return `${project?.name ?? 'Global'} - ${phase?.name ?? 'Unphased'} - ${name}`;
  }, [projectIdMap, phaseMap]);

  const boardIdsSet = useMemo(() => (
    new Set(boardIds)
  ), [boardIds]);

  const dropdownData = useMemo(() => {
    if (Array.isArray(ourDataType)) {
      return ourDataType;
    }
    switch (ourDataType) {
      case 'Form Status':
        return Object.values(formStatuses)
          .map((formStatus) => ({ name: toTitleCase(formStatus.status), id: formStatus.id }));
      case 'Divisions':
        return divisions;
      case 'MaterialLocations':
        return globalMaterialLocations.map((loc) => ({ id: loc.id, name: loc.locationText }));
      case 'Locals':
        return locals;
      case 'Classes':
        return classes;
      case 'Customers':
        return customers.filter((c) => c.active);
      case 'Projects':
        return projects.filter((p) => p.active);
      case 'Time Phases':
        return []; // Need project from ??, disabled for now
      case 'Time Costcodes':
        return []; // Need project from ??, + global? disabled for now
      case 'Users':
        return users.filter((u) => u.active);
      case 'Costcodes':
        return costcodes
          .filter((c) => c.active)
          .map((c) => ({
            id: c.id,
            name: formatCostcodeName(c),
          }));
      case 'Vendors':
        return vendors.filter((v) => v.active);
      case 'Equipment':
        return equipment.filter((e) => e.active);
      case 'Forms':
        return formTemplates.filter((f) => f.active);
      case 'CompletedForms':
        return formatFormDropdownList({
          formList,
          projectIdMap,
          shouldFilterByProjects: false,
        });
      case 'Labels':
        return labels.map((label) => ({ id: label.id, name: label.title }));
      case 'Contacts':
        return Object.values(globalAddressBooks);
      case 'Custom':
        return (configProps.customOptions || []);
      case 'Cards': {
        const statusMap = getIdMap(boardDetailsMap?.[subDataType]?.statuses);
        return boardDetailsMap?.[subDataType]?.cards?.map((card) => ({
          id: card.id,
          name: formatCardTitle(card),
          subNames: [
            statusMap[card.statusId]?.title ?? '',
            card.lastUpdated ? DateTime.fromMillis(card.lastUpdated).toLocaleString(DateTime.DATETIME_MED) : '',
          ],
        })) ?? [];
      }
      case 'Buckets': {
        return bucketTemplateMap?.[subDataType] ?? [];
      }
      case 'Materials': {
        return Object.values(materials);
      }
      case 'Board Status': {
        return Object.values(boardDetailsMap)
          .filter(({ id }) => (!boardIdsSet.size || boardIdsSet.has(id)) && boards[id])
          .map((board) => {
            const option = {
              value: board.id,
              title: boards[board.id].title,
              selectable: false,
              children: [],
            };
            board.statuses?.forEach((status) => {
              option.children.push({
                value: status.id,
                title: status.title,
                selectable: true,
                isLeaf: true,
              });
            });
            return option;
          });
      }
      case 'PayableSubContracts': {
        const filteredFormList = formList.filter((form) => form.type === 'Sub-Contract');
        return formatFormDropdownList({
          formList: filteredFormList,
          projectIdMap,
          shouldFilterByProjects: false,
        });
      }
      default:
        return [];
    }
  }, [
    ourDataType,
    customers,
    projects,
    users,
    costcodes,
    vendors,
    equipment,
    formTemplates,
    labels,
    globalAddressBooks,
    formatCostcodeName,
    boardDetailsMap,
    subDataType,
    locals,
    classes,
    divisions,
    formStatuses,
    globalMaterialLocations,
    formList,
    bucketTemplateMap,
    boards,
    boardIdsSet,
  ]);

  const formattedRange = useMemo(() => {
    if (ourType !== 'dateRange' || !queryValue?.length) return [];
    const [startTime, endTime] = queryValue;
    return [
      startTime ? moment(startTime) : undefined,
      endTime ? moment(endTime) : undefined,
    ];
  }, [queryValue, ourType]);

  const ValueInput = useMemo(() => {
    if (!ourType) return null;
    switch (ourType) {
      case 'boolean':
        return (
          <Select
            style={{ width: 350 }}
            value={queryValue}
            onChange={onDataChanged('value')}
          >
            <Select.Option value>True</Select.Option>
            <Select.Option value={false}>False</Select.Option>
          </Select>
        );
      case 'yes-no':
        return (
          <Select
            style={{ width: 350 }}
            value={queryValue}
            onChange={onDataChanged('value')}
          >
            <Select.Option value="yes">Yes</Select.Option>
            <Select.Option value="no">No</Select.Option>
            <Select.Option value="n/a">N/A</Select.Option>
          </Select>
        );
      case 'dropdown': {
        if (ourDataType === 'Board Status') {
          return (
            <TreeSelect
              showSearch
              onChange={onDataChanged('value')}
              treeNodeFilterProp="label"
              treeData={dropdownData}
              style={{ width: 350 }}
              value={queryValue}
            />
          );
        }
        return (
          <Select
            style={{ width: 350 }}
            value={queryValue}
            allowClear
            onChange={onDataChanged('value')}
            optionFilterProp="label"
            showSearch
          >
            {dropdownData.map(({ id: value, name: label, subNames }) => (
              <Select.Option key={value} value={value} label={label}>
                {label}
                {subNames?.length ? (
                  subNames.map((subName) => (
                    <div
                      key={subName}
                      style={{ color: Colors.ONTRACCR_OPACITY_GRAY, fontSize: 12 }}
                    >
                      {subName}
                    </div>
                  ))
                ) : null}
              </Select.Option>
            ))}
          </Select>
        );
      }
      case 'dateRange':
        return (
          <DatePicker.RangePicker
            allowClear={false}
            style={{ width: 250 }}
            format="MMM Do YY"
            minDate
            value={formattedRange}
            onChange={onDataChanged('value')}
          />
        );
      case 'cost':
        return (
          <OnTraccrNumberInput
            precision={2}
            min={0}
            formatter={currencyFormatter}
            parser={currencyParser}
            value={queryValue}
            onChange={onDataChanged('value')}
          />
        );
      case 'calculation':
      case 'number':
        return (
          <OnTraccrNumberInput
            min={0}
            value={queryValue}
            onChange={onDataChanged('value')}
          />
        );
      case 'attribute':
      case 'text':
      default:
        return (
          <OnTraccrTextInput
            style={{ width: 150 }}
            value={queryValue}
            onChange={(e) => onDataChanged('value')(e.target.value)}
          />
        );
    }
  }, [
    query,
    ourType,
    ourDataType,
    dropdownData,
    onDataChanged,
    formattedRange,
  ]);

  const possibleOperators = useMemo(() => {
    if (ourType === 'dateTime' || ourType === 'dateRange') {
      return [
        {
          value: '=',
          label: 'within',
        },
      ];
    }
    const equalityTerm = ourType === 'dropdown' ? 'include' : 'equal';
    const baseOperators = [
      {
        value: '=',
        label: `${toTitleCase(equalityTerm)}s`,
      },
      {
        value: '!=',
        label: `Does not ${equalityTerm}`,
      },
    ];

    if (ourType === 'yes-no') return baseOperators;
    if (ourType === 'number' || ourType === 'calculation') {
      baseOperators.push({
        value: '<',
        label: 'Less than',
      }, {
        value: '>',
        label: 'Greater Than',
      });
      return baseOperators;
    }
    if (ourType === 'text' || ourType === 'attribute') {
      baseOperators.push(
        {
          value: 'has',
          label: 'Includes',
        },
        {
          value: '!has',
          label: 'Does not include',
        },
      );
      return baseOperators;
    }

    return baseOperators;
  }, [ourType]);

  return (
    <Row gutter={16} style={{ paddingTop: 15 }}>
      {columns && (
        <Col>
          <Select
            style={{ width: 150 }}
            value={column}
            onChange={onDataChanged('column')}
            dropdownMatchSelectWidth={false}
          >
            {columns.filter(({ key }) => !hiddenColumns[dataType]?.[key]).map(({ key, name }) => (
              <Select.Option key={key} value={key}>
                {name}
              </Select.Option>
            ))}
          </Select>
        </Col>
      )}

      <Col>
        <Select
          style={{ width: 100 }}
          value={operator}
          onChange={onDataChanged('operator')}
          dropdownMatchSelectWidth={false}
        >
          {possibleOperators.map(({ value, label }) => (
            <Select.Option key={value} value={value}>
              {label}
            </Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        {ValueInput}
      </Col>
      <Col>
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={onDelete}
        />
      </Col>
    </Row>
  );
}

SearchFieldRow.propTypes = {
  configProps: PropTypes.shape({}),
  onQueryChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  query: PropTypes.shape({}),
  type: PropTypes.string,
  boardIds: PropTypes.array,
};

SearchFieldRow.defaultProps = {
  configProps: {},
  query: {},
  type: PropTypes.string,
};
