import moment from 'moment';
import {
  parseCustomResponses,
} from '../boards.helpers';
import { viewableCustomFields } from './BoardTimeline.constants';

export const getGanttZoomTypes = ({
  max,
}) => {
  const numbers = [];
  for (let i = 0.25; i <= max; i += 0.25) {
    numbers.push(i);
  }

  return numbers.map((num) => ({
    value: num,
    label: `${num * 100}%`,
  }));
};

export const getCustomColumns = ({
  sections = {},
}) => {
  const sectionsList = Object.values(sections);
  const customColumns = [];
  sectionsList.forEach((section) => {
    const { fields } = section;
    fields?.forEach((field) => {
      const { configProps, selectedType, id } = field;
      if (!viewableCustomFields.includes(selectedType)) {
        return;
      }
      const column = {
        property: id,
        label: configProps?.title,
      };
      customColumns.push(column);
    });
  });
  return customColumns;
};

export const getCustomColumnTreeData = ({
  sections = {},
}) => {
  const sectionsList = Object.values(sections);
  const customTreeData = [
    {
      value: 'customFields',
      title: 'Custom Fields',
      selectable: false,
      children: sectionsList.map((section) => {
        const { fields, name, id } = section;
        return {
          value: id,
          title: name,
          selectable: false,
          children: fields
            ?.filter((field) => viewableCustomFields.includes(field?.selectedType))
            .map((field) => {
              const { configProps, id: fieldId } = field;
              return {
                value: fieldId,
                title: configProps?.title,
              };
            }) ?? [],
        };
      }),
    },
  ];

  return customTreeData;
};

export const getCustomData = ({
  sections = [],
}) => {
  const customData = {};
  sections.forEach((section) => {
    const { fields } = section;
    fields?.forEach((field) => {
      const { fieldId } = field;
      customData[fieldId] = parseCustomResponses({ field });
    });
  });

  return customData;
};

export const parseCards = ({
  cards = [],
  statusMap = {},
}) => {
  let maxEndDate = 0;
  const parsedCards = cards
    .filter((card) => card.startDate)
    .map((card) => {
      const {
        data,
        startDate,
        endDate,
        id,
        title,
        number,
        color,
        statusId,
      } = card;

      const customData = getCustomData({ sections: data });
      const { title: status } = statusMap[statusId];

      const start = new Date(startDate);
      const end = endDate ? new Date(endDate) : null;

      start.setHours(0, 0, 0, 0);

      if (end) {
        end.setHours(23, 59, 59, 999);
      }

      if (endDate > maxEndDate) {
        maxEndDate = endDate;
      }

      const startDateString = moment(startDate).format('YYYY/MM/DD');
      const endDateString = endDate ? moment(endDate).format('YYYY/MM/DD') : 'n/a';

      return ({
        id,
        name: title ?? number,
        type: 'task',
        start,
        end,
        startDate: startDateString,
        endDate: endDateString,
        status,
        ...customData,
        styles: {
          backgroundColor: (color === '#FFFFFFFF') ? '#000' : color,
        },
      });
    });

  const formattedEndDate = new Date(maxEndDate);
  formattedEndDate.setHours(23, 59, 59, 999);

  const parsedCards2 = parsedCards.map((card) => {
    const { start, end } = card;
    if (!end) {
      if (start < maxEndDate) {
        return {
          ...card,
          end: formattedEndDate,
        };
      }
      const endDate = start;
      endDate.setHours(23, 59, 59, 999);
      return {
        ...card,
        end: endDate,
      };
    }
    return card;
  });

  return parsedCards2;
};

export default {};
