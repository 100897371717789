import React from 'react';

import ReportListHeader from './ReportsListHeader';
import ReportListItemView from './ReportListViews/ReportListItemView';
import SideList from '../common/SideList';


export default ({
  onTypeChanged,
  reports,
  selected,
  onSearch,
  onSelect,
  selectedReports,
}) => {
  return <SideList
    key='reportList'
    id='reportList'
    name='reportList'
    header={
      <ReportListHeader
        onSelect={onTypeChanged}
        selected={selected}
        onSearch={(e) => onSearch(e.target.value)}
      />}
    renderItem={ReportListItemView}
    headerStyle={{ height: 125}}
    dataSource={reports}
    onClick={onSelect}
    selectedItems={selectedReports}
    rowHeight={36}
    scrollable={true}
  />
};
