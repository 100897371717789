/* eslint-disable no-shadow */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Row, Col, Select, Divider, Checkbox,
} from 'antd';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import QBImportDrawer from './QBImportDrawer';
import ConnectToQuickbooks from './ConnectToQuickbooks';
import QuickBooksSyncTypes from './QuickBooksSyncTypes';

import CheckRows from '../../CheckRows';
import CompanyEditRow from '../../CompanyEditRow';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DisplayText from '../../../common/text/DisplayText';
import CustomConfirmModal from '../../../common/modals/CustomConfirmModal';
import DivisionSelector from '../../../common/inputs/DivisionSelector';

import {
  getQuickbooksEmployees,
  getQuickbooksProjects,
  getQuickbooksCostcodes,
  getQuickbooksMaterials,
  disconnectQuickBooks,
  importIntegrationData,
  checkIntegrationConnection,
  updateQuickBooksIntegration,
  getQuickbooksCustomers,
  getQuickbooksVendors,
} from '../../state/settings.actions';

import { getUsers } from '../../../users/state/users.actions';
import { getProjects } from '../../../projects/state/projects.actions';
import { getAllCostCodes } from '../../../costcodes/state/costcodes.actions';

import Colors from '../../../constants/Colors';

import Permissions from '../../../auth/Permissions';
import { getCustomers } from '../../../contacts/customers/state/customers.actions';
import { getVendors } from '../../../contacts/vendors/state/vendors.actions';

import { toTitleCase } from '../../../helpers/helpers';

const flavourText = `
Deactivating your QuickBooks integration will disable any
further syncing between Ontraccr and QuickBooks.
`;

const dividerStyle = {
  margin: 0,
  backgroundColor: Colors.ONTRACCR_OPACITY_GRAY,
  maxWidth: 440,
  minWidth: 440,
};

const PROFILE_TYPES = [
  'projects',
  'customers',
  'vendors',
  'costcodes',
  'materials',
];

function QuickbooksIntegration({
  visible,
  onClose,
  selectedIntegration,
  onIntegrationChanged,
  isSingleDiv,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const quickBooksUsers = useSelector((state) => state.users.quickBooksUsers);
  const quickBooksProjects = useSelector((state) => state.projects.quickBooksProjects);
  const quickBooksCostcodes = useSelector((state) => state.costcodes.quickBooksCostcodes);
  const quickBooksMaterials = useSelector((state) => state.materials.quickBooksMaterials);
  const quickBooksCustomers = useSelector((state) => state.customers.quickBooksCustomers);
  const quickBooksVendors = useSelector((state) => state.vendors.quickBooksVendors);
  const company = useSelector((state) => state.settings.company);
  const {
    userId: ownerId,
  } = company ?? {};

  const checkStatus = useCallback(() => {
    if (!selectedIntegration?.id) return;
    setTimeout(() => dispatch(checkIntegrationConnection('quickbooks', selectedIntegration.id)), 1000);
  }, [checkIntegrationConnection, selectedIntegration]);

  const [showSync, setShowSync] = useState(false);
  const [taxCodes, setTaxCodes] = useState([]);
  const [purchaseAcounts, setPurchaseAccounts] = useState([]);
  const [importDrawerLoading, setImportDrawerLoading] = useState(false);

  const onIntegrationUpdate = useCallback((newData) => {
    if (!isSingleDiv && onIntegrationChanged) {
      onIntegrationChanged(newData);
    } else if (selectedIntegration?.id) {
      const { id, ...details } = selectedIntegration;
      const fullDetails = {
        ...details,
        ...newData,
      };
      dispatch(updateQuickBooksIntegration(id, fullDetails));
    }
  }, [onIntegrationChanged, selectedIntegration]);

  const onDivisionsChanged = useCallback((newDivisions) => {
    const newData = {
      divisions: newDivisions,
    };
    if (!selectedIntegration?.defaultDivisionId && newDivisions.length > 0) {
      [newData.defaultDivisionId] = newDivisions;
    } else if (selectedIntegration?.defaultDivisionId
      && !newDivisions.includes(selectedIntegration?.defaultDivisionId)) {
      // Need to clear default division ID if its not in division list
      newData.defaultDivisionId = newDivisions.length ? newDivisions[0] : null;
    }
    onIntegrationUpdate(newData);
  }, [onIntegrationUpdate, selectedIntegration]);

  const onDefaultTaxCodeChanged = useCallback((newTaxCode) => (
    onIntegrationUpdate({ defaultTaxCode: newTaxCode })
  ), [onIntegrationUpdate]);

  const onDefaultPurchaseAccountChanged = useCallback((accId) => {
    const ourAccount = purchaseAcounts.find((acc) => acc.Id === accId);
    if (!ourAccount) return;
    onIntegrationUpdate({
      defaultPurchaseAccount: ourAccount.Id,
      defaultPurchaseAccountType: ourAccount.AccountType,
    });
  }, [onIntegrationUpdate, purchaseAcounts]);

  const showConfirm = useCallback(() => (
    CustomConfirmModal({
      title: 'Deactivate QuickBooks Integration',
      content: (
        <p>
          Are you sure you want to your deactivate QuickBooks integration?
          <br />
          <br />
          {flavourText}
        </p>
      ),
      async onOk() {
        if (await dispatch(disconnectQuickBooks(selectedIntegration?.id))) {
          if (onClose) onClose();
        }
      },
      okText: 'Deactivate',
    })
  ), [selectedIntegration, onClose]);

  const loadData = () => {
    const promises = [
      dispatch(getQuickbooksEmployees(selectedIntegration?.id)),
      dispatch(getQuickbooksCostcodes(selectedIntegration?.id)),
      dispatch(getQuickbooksMaterials(selectedIntegration?.id)),
      dispatch(getQuickbooksCustomers(selectedIntegration?.id)),
      dispatch(getQuickbooksVendors(selectedIntegration?.id)),
      dispatch(getQuickbooksProjects(selectedIntegration?.id)),
    ];

    return Promise.all([promises]);
  };

  useEffect(() => {
    const getTaxCodes = async () => {
      try {
        const { data: newTaxCodes = [] } = await axios.get(`/quickbooks/${selectedIntegration.id}/taxcodes`);
        setTaxCodes(
          newTaxCodes.map((newTaxCode) => ({ label: newTaxCode.Name, value: newTaxCode.Id })),
        );
      } catch (err) {
        // no op
      }
    };

    const getExpenseAccounts = async () => {
      try {
        const { data: newPurchaseAccounts = [] } = await axios.get('/quickbooks/accounts/purchase', { params: { id: selectedIntegration.id } });
        setPurchaseAccounts(newPurchaseAccounts);
      } catch (err) {
        // no op
      }
    };

    if (visible && selectedIntegration?.id) {
      getTaxCodes();
      getExpenseAccounts();
    }
  }, [visible, selectedIntegration]);

  useEffect(() => {
    checkStatus();
  }, [checkStatus]);

  useEffect(() => {
    if (!showSync) checkStatus();
  }, [showSync, checkStatus]);

  const divisionFilter = useMemo(() => (
    new Set(selectedIntegration?.divisions ?? [])
  ), [selectedIntegration]);

  const purchaseAccountOptions = useMemo(() => (
    purchaseAcounts.map((acc) => ({ label: acc.Name, value: acc.Id }))
  ), [purchaseAcounts]);

  return (
    <div style={{ width: '100%' }}>
      {!isSingleDiv
      && (
      <div style={{ width: '100%', padding: 10 }}>
        <DisplayText
          title="Divisions"
          style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
        />
        <DisplayText
          title="Select the divisions to link with this QuickBooks integration"
          style={{
            fontFamily: 'roboto-regular',
            color: Colors.ONTRACCR_OPACITY_GRAY,
            marginBottom: 0,
            maxWidth: 400,
          }}
        />
        <Row justify="start">
          <DivisionSelector
            divisions={selectedIntegration?.divisions}
            onChange={onDivisionsChanged}
            mode="multiple"
            style={{ width: '100%', margin: 10, maxWidth: 400 }}
          />
        </Row>
        <Divider style={dividerStyle} />
      </div>
      )}

      {
        selectedIntegration?.id
          && (
            <CompanyEditRow
              title="Import from QuickBooks"
              helpText="Import Employees, Projects, etc. from QuickBooks into Ontraccr."
            >
              <OnTraccrButton
                title="Set Up"
                style={{ marginRight: 45 }}
                loading={importDrawerLoading}
                disabled={importDrawerLoading}
                onClick={async () => {
                  setImportDrawerLoading(true);
                  if (await loadData()) {
                    setShowSync(true);
                  }
                  setImportDrawerLoading(false);
                }}
              />
            </CompanyEditRow>
          )
      }

      {
        isSingleDiv && !selectedIntegration?.id
        && (
          <ConnectToQuickbooks />
        )
      }

      {selectedIntegration?.id
      && (
        <>
          <CheckRows
            isChecked={({ key }) => selectedIntegration?.[key]}
            onChange={({ key }) => onIntegrationUpdate({
              [key]: !selectedIntegration?.[key],
            })}
            data={[
              {
                key: 'pullEnabled',
                title: 'Auto-Sync from QuickBooks to Ontraccr',
                divider: false,
                helpText: (
                  <div style={{ width: 300 }}>
                    Auto-Sync from QuickBooks to Ontraccr will
                    automatically import any new changes you make in QuickBooks
                    into Ontraccr Users,
                    {' '}
                    {t('Project', { count: 2 })}
                    {' '}
                    , Materials and Cost Codes.
                  </div>
                ),
              },
            ]}
          />
          <QuickBooksSyncTypes
            onIntegrationUpdate={onIntegrationUpdate}
            disabled={!selectedIntegration?.pullEnabled}
            type="pull"
            selectedIntegration={selectedIntegration}
          />
          <CheckRows
            isChecked={({ key }) => selectedIntegration?.[key]}
            onChange={({ key }) => onIntegrationUpdate({
              [key]: !selectedIntegration?.[key],
            })}
            data={[
              {
                key: 'pushEnabled',
                title: 'Auto-Sync from Ontraccr to QuickBooks',
                divider: false,
                helpText: (
                  <div style={{ width: 300 }}>
                    Auto-Sync from Ontraccr to QuickBooks will
                    automatically push any new changes you make in Ontraccr
                    into QuickBooks Employees and Items.
                  </div>
                ),
              },
            ]}
          />
          <QuickBooksSyncTypes
            onIntegrationUpdate={onIntegrationUpdate}
            disabled={!selectedIntegration?.pushEnabled}
            type="push"
            selectedIntegration={selectedIntegration}
            divider={!isSingleDiv}
          />
        </>
      )}
      {selectedIntegration?.id && (
        <CompanyEditRow
          title="Dynamic Profile Types Configuration"
          helpText="Select which profile types to dynamically create in QuickBooks if they do not exist during syncing"
          childrenOnNewLine
        >
          <Row>
            {PROFILE_TYPES.map((profile) => (
              <Col key={profile}>
                <Checkbox
                  key={profile}
                  style={{ marginTop: 10, marginBottom: 10 }}
                  checked={selectedIntegration?.[`dynamic${toTitleCase(profile)}`]}
                  onChange={() => {
                    const key = `dynamic${toTitleCase(profile)}`;
                    onIntegrationUpdate({
                      [key]: !selectedIntegration?.[key],
                    });
                  }}
                >
                  {toTitleCase(profile)}
                </Checkbox>
              </Col>
            ))}
          </Row>
        </CompanyEditRow>
      )}
      {!isSingleDiv
      && (
      <div style={{ width: '100%', padding: 10 }}>
        <DisplayText
          title="Default Division"
          style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
        />
        <DisplayText
          title="Select the default division for QuickBooks items to be created in."
          style={{
            fontFamily: 'roboto-regular',
            color: Colors.ONTRACCR_OPACITY_GRAY,
            marginBottom: 0,
            maxWidth: 275,
          }}
        />
        <Row justify="start">
          <DivisionSelector
            divisionId={selectedIntegration?.defaultDivisionId}
            disabled={!selectedIntegration?.divisions?.length}
            onChange={(newDivisionId) => onIntegrationUpdate({ defaultDivisionId: newDivisionId })}
            filter={divisionFilter}
            style={{ width: '100%', margin: 10, maxWidth: 400 }}
          />
        </Row>
        <Divider style={dividerStyle} />
      </div>
      )}
      {
      selectedIntegration?.id
      && (
        <>
          <div style={{ width: '100%', padding: 10 }}>
            <DisplayText
              title="Default Tax Code"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
            <DisplayText
              title="This field is required when syncing Form Invoices between Ontraccr and QuickBooks"
              style={{
                fontFamily: 'roboto-regular',
                color: Colors.ONTRACCR_OPACITY_GRAY,
                marginBottom: 0,
                maxWidth: 275,
              }}
            />
            <Row justify="start">
              <Select
                style={{ width: '100%', margin: 10, maxWidth: 400 }}
                placeholder="Select tax code"
                options={taxCodes}
                onSelect={onDefaultTaxCodeChanged}
                value={selectedIntegration?.defaultTaxCode}
              />
            </Row>
            <Divider style={dividerStyle} />
          </div>

          <div style={{ width: '100%', padding: 10 }}>
            <DisplayText
              title="Default Purchase Account"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
            <DisplayText
              title="This field is required when syncing payable Invoices between Ontraccr and QuickBooks"
              style={{
                fontFamily: 'roboto-regular',
                color: Colors.ONTRACCR_OPACITY_GRAY,
                marginBottom: 0,
                maxWidth: 275,
              }}
            />
            <Row justify="start">
              <Select
                style={{ width: '100%', margin: 10, maxWidth: 400 }}
                placeholder="Select purchase account"
                options={purchaseAccountOptions}
                onSelect={onDefaultPurchaseAccountChanged}
                value={selectedIntegration?.defaultPurchaseAccount}
              />
            </Row>
            <Divider style={dividerStyle} />
          </div>
        </>
      )
    }
      {
      selectedIntegration?.id
      && (
        <>
          <Row style={{ minHeight: 32, width: '100%', paddingLeft: 10 }} justify="space-between" align="middle">
            <Col>
              <DisplayText
                title="Deactivate QuickBooks Integration"
                style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
              />
            </Col>
          </Row>
          <DisplayText
            title={flavourText}
            style={{
              fontFamily: 'roboto-regular',
              color: Colors.ONTRACCR_OPACITY_GRAY,
              marginBottom: 0,
              maxWidth: 275,
              paddingLeft: 10,
            }}
          />

          <Row style={{ width: '100%', marginBottom: 10 }} justify="center">
            <OnTraccrButton
              title="Deactivate"
              type={Permissions.id === ownerId ? 'primary' : 'back'}
              disabled={Permissions.id !== ownerId}
              style={{ margin: 10 }}
              onClick={showConfirm}
            />
            <DisplayText
              style={{
                textAlign: 'center',
                fontFamily: 'roboto-bold',
                color: Colors.ONTRACCR_OPACITY_GRAY,
                height: 32,
              }}
              title="Only the account owner can deactivate"
            />
          </Row>
        </>
      )
      }

      <QBImportDrawer
        qbIntegrationPush={selectedIntegration?.pushEnabled}
        qbIntegrationPull={selectedIntegration?.pullEnabled}
        quickBooksUsers={quickBooksUsers}
        quickBooksProjects={quickBooksProjects}
        quickBooksCostcodes={quickBooksCostcodes}
        quickBooksMaterials={quickBooksMaterials}
        quickBooksCustomers={quickBooksCustomers}
        quickBooksVendors={quickBooksVendors}
        visible={showSync}
        divisionFilter={divisionFilter}
        width={400}
        onClose={() => setShowSync(false)}
        onSave={async (res) => {
          const {
            syncSettings,
            ...payload
          } = res;
          payload.integrationKey = 'intuitId';

          const {
            qbIntegrationPush: pushEnabled,
            qbIntegrationPull: pullEnabled,
          } = syncSettings ?? {};

          const newIntegration = {
            ...selectedIntegration ?? {},
            pushEnabled: !!pushEnabled,
            pullEnabled: !!pullEnabled,
          };
          delete newIntegration.id;

          if (await dispatch(importIntegrationData(payload))) {
            if (selectedIntegration?.id
              && await dispatch(
                updateQuickBooksIntegration(selectedIntegration.id, newIntegration),
              )) {
              if (onIntegrationChanged) onIntegrationChanged(newIntegration);
              Promise.all([
                dispatch(getUsers(true)),
                dispatch(getProjects()),
                dispatch(getAllCostCodes()),
                dispatch(getCustomers(true)),
                dispatch(getVendors()),
              ]);
              setShowSync(false);
            }
          }
        }}
      />
    </div>
  );
}

QuickbooksIntegration.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string.isRequired,
    defaultDivisionId: PropTypes.string,
    defaultTaxCode: PropTypes.string,
    defaultPurchaseAccount: PropTypes.string,
    pullEnabled: PropTypes.bool,
    pushEnabled: PropTypes.bool,
    divisions: PropTypes.arrayOf(PropTypes.string),
  }),
  onIntegrationChanged: PropTypes.func,
  isSingleDiv: PropTypes.bool,
};

QuickbooksIntegration.defaultProps = {
  selectedIntegration: null,
  onClose: null,
  onIntegrationChanged: null,
  isSingleDiv: false,
};

export default QuickbooksIntegration;
