import React, { useEffect, useMemo, useReducer, useState } from 'react';
import {
  Form, Checkbox, Slider,
  Col, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Buckets } from 'ontraccr-common';
import { withScriptjs } from 'react-google-maps';

import '@ant-design/compatible/assets/index.css';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import FormTextInput from '../common/inputs/FormTextInput';
import OnTraccrMap from '../common/map/OnTraccrMap';
import DisplayText from '../common/text/DisplayText';
import IntegrationTag from '../common/IntegrationTag';
import DivisionSelector from '../common/inputs/DivisionSelector';
import getGoogleMapKey from '../common/keys';
import ContactSelector from '../common/inputs/ContactSelector';
import ProjectApproverSelector from '../common/inputs/ProjectApproverSelector';

import config from '../config';
import { getIdMap } from '../helpers/helpers';
import { getQuickbooksClasses } from '../settings/state/settings.actions';
import {
  geocodeProject,
  getProjectCostUpdates,
  getProjectCustomData,
  getProjectCustomFieldTemplate,
} from './state/projects.actions';
import { getBucketSelect } from '../buckets/bucketHelpers';
import CustomFields from '../common/customFields/CustomFields';

const { Option } = Select;

const getGeofence = (props) => {
  const {
    geofence,
    geofenceEnabled,
    geofenceSize,
    settings,
  } = props;
  if (!settings.enableGeofence) return null;
  if (geofenceEnabled) return geofenceSize * 1000;
  if (geofence) return geofence;
  return null;
};

const validateKey = (t, id, projects, key) => (rule, value, callback) => {
  if (projects.some((project) => project[key] === value && id !== project.id)) {
    return callback(`${t('Project')} ${key} ${value} is in use by another ${t('Project').toLowerCase()}`);
  }

  callback();
};

function ProjectAddGeneral({
  marker,
  onPlaceChanged,
  geofenceEnabled,
  onGeofenceEnableChanged,
  onGeofenceSizeChanged,
  geofenceSize,
  isAdd,
  isNotDisplay,
  id,
  name,
  number,
  customer,
  info,
  geofence,
  latitude,
  longitude,
  address,
  settings = {},
  firstApprover,
  secondApprover,
  projectTypeId,
  qboClassId,
  onApproverChanged1,
  onApproverChanged2,
  onProjectTypeChanged,
  projects = [],
  intuitId,
  procoreId,
  sageId,
  eclipseId,
  formRef,
  projectTypes,
  type,
  divisionId,
  hideBucketDropdown,
  errors,
  customFields,
  setCustomFields,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    connectedToQuickbooks = false,
  } = useSelector((state) => state.settings.company);
  const buckets = useSelector((state) => state.buckets.buckets);
  const {
    customData,
    customDataFiles,
    customFieldTemplate,
    quickbookClassMap: qboClassMap,
  } = useSelector((state) => state.projects);

  const [selectedDivisionId, setSelectedDivisionId] = useState(divisionId);
  const [shouldUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  const [stateLatitude, setStateLatitude] = useState(latitude);
  const [stateLongitude, setStateLongitude] = useState(longitude);

  useEffect(() => {
    if (!connectedToQuickbooks) return;
    dispatch(getQuickbooksClasses());
  }, [connectedToQuickbooks]);

  useEffect(() => {
    dispatch(getProjectCustomFieldTemplate());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getProjectCostUpdates(id));
    }

    dispatch(getProjectCustomData(id));
  }, [id]);

  useEffect(() => {
    setCustomFields(customFieldTemplate?.sections);
  }, [customFieldTemplate]);

  useEffect(() => {
    forceUpdate();
  }, [customData]);

  const relevantQBOClasses = qboClassMap[selectedDivisionId];
  const selectedQBOClass = useMemo(() => (
    qboClassMap[divisionId]?.find(({ Id }) => Id === qboClassId)
  ), [qboClassMap, qboClassId]);
  const projectMap = getIdMap(projects);
  const bucketMap = getIdMap(buckets);

  const {
    upstreamBucketIds,
  } = useMemo(() => (
    Buckets.getLinkedBuckets(buckets, [id], ['projects'])
  ), [id, buckets]);

  const projectTypeMap = getIdMap(projectTypes);
  const projectText = t('Project');
  const projectLower = projectText.toLowerCase();

  const onDivisionChanged = (value) => {
    setSelectedDivisionId(value);
    formRef?.current?.setFieldsValue({ qboClassId: null });
  };

  const BucketSelect = useMemo(() => (
    getBucketSelect({
      selectedBuckets: upstreamBucketIds,
      buckets,
      bucketMap,
      projectMap,
      type: 'projects',
      getDownstream: true,
      isDisplay: !isNotDisplay,
    })
  ), [
    upstreamBucketIds,
    buckets,
    projectMap,
    isNotDisplay,
  ]);

  useEffect(() => {
    const shouldGeocode = id
      && address
      && longitude === 0
      && latitude === 0
      && intuitId;

    if (shouldGeocode) {
      const getGeocodeProject = async () => {
        const geocoder = new window.google.maps.Geocoder();
        geocoder.geocode({
          address,
        }, async (results, status) => {
          if (status === 'OK' && results[0]) {
            const { location } = results[0].geometry || {};
            const lat = location?.lat();
            const lng = location?.lng();
            const coords = await dispatch(geocodeProject(id, { lat, lng }));
            if (coords) {
              setStateLatitude(coords.lat);
              setStateLongitude(coords.lng);
            }
          } else {
            // silent fail
          }
        });
      };
      getGeocodeProject();
    }
  }, [
    id,
    address,
    longitude,
    latitude,
    intuitId,
  ]);

  const scrollOffset = isNotDisplay ? 190 : 165;
  return (
    <div style={{ height: `calc(100vh - ${scrollOffset}px`, overflowY: 'auto' }}>
      <Row style={{ width: '100%' }} gutter={20}>
        <Col span={14}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item
                name="name"
                label={`${projectText} Name`}
                style={{ marginBottom: 0 }}
                rules={[
                  { required: isAdd, message: `Please enter the ${projectLower} name` },
                ]}
              >
                {isNotDisplay ? (
                  <OnTraccrTextInput
                    style={{ width: '100%' }}
                  />
                ) : <DisplayText title={name} /> }
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="number"
                label={`${projectText} Number`}
                style={{ marginBottom: 0 }}
                rules={[
                  { validator: validateKey(t, id, projects, 'number') },
                ]}
              >
                {isNotDisplay ? (
                  <OnTraccrTextInput
                    style={{ width: '100%' }}
                  />
                ) : <DisplayText title={number} /> }
              </Form.Item>
            </Col>
            <Col span={8}>
              {config.showDivisions ? (
                <Form.Item
                  name="divisionId"
                  key="divisionId"
                  label="Division"
                  style={{ marginBottom: 0 }}
                  rules={[{ required: isAdd, message: 'Select a division' }]}
                  valuePropName="divisionId"
                >
                  <DivisionSelector
                    displayMode={!isNotDisplay}
                    onChange={onDivisionChanged}
                  />
                </Form.Item>
              ) : (
                <Form.Item
                  name="customer"
                  label="Customer"
                  style={{ marginBottom: 0 }}
                >
                  {isNotDisplay
                    ? <Select />
                    : <DisplayText title={customer} /> }
                </Form.Item>
              )}
            </Col>
          </Row>
          <Row gutter={20} style={{ marginTop: 30, height: 62 }}>
            <Col span={8}>
              <Form.Item
                name="firstApprover"
                label="First Approver"
                valuePropName="selectedApprover"
                style={{ marginBottom: 0 }}
              >
                <ProjectApproverSelector
                  onChange={onApproverChanged1}
                  isNotDisplay={isNotDisplay}
                  selectedApprover={firstApprover}
                  isFirst
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item
                name="secondApprover"
                label="Second Approver"
                valuePropName="selectedApprover"
                style={{ marginBottom: 0 }}
              >
                <ProjectApproverSelector
                  onChange={onApproverChanged2}
                  isNotDisplay={isNotDisplay}
                  selectedApprover={secondApprover}
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              {config.showDivisions && (
                <ContactSelector
                  isNotDisplay={isNotDisplay}
                  contact={customer}
                  formRef={formRef}
                  isAdd={isAdd}
                  divisionId={selectedDivisionId}
                  shouldFilterByDivision
                />
              )}
            </Col>
          </Row>
          <Row
            style={{ height: isNotDisplay ? 90 : 36, marginTop: 30 }}
            align="middle"
            gutter={20}
          >
            <Col span={8} style={{ height: '100%' }}>
              <Form.Item
                name="projectTypeId"
                label="Type"
                style={{ marginBottom: 0 }}
              >
                { isNotDisplay ? (
                  <Select
                    showSearch
                    onChange={onProjectTypeChanged}
                    disabled={!!type}
                  >
                    <Option key="-1" value={null}>None</Option>
                    {projectTypes.map((type) => (
                      <Option key={type.id} value={type.id}>
                        {type.name}
                      </Option>
                    ))}
                  </Select>
                ) : <DisplayText title={projectTypeMap[projectTypeId]?.name ?? 'None'} />}
              </Form.Item>
            </Col>
            <Col span={isNotDisplay ? 6 : 8} style={{ height: '100%' }}>
              {settings.enableGeofence && (
                <Row style={{ height: '100%', width: '100%' }} align="middle">
                  {isNotDisplay ? (
                    <Checkbox
                      onChange={onGeofenceEnableChanged}
                      checked={geofenceEnabled}
                      style={{ marginBottom: 0 }}
                    >
                      Enable Geofencing?
                    </Checkbox>
                  ) : (
                    <Form.Item
                      name="geofence"
                      label="Geofence"
                      style={{ marginBottom: 0 }}
                    >
                      <DisplayText title={geofence ? `${(geofence / 1000)} km` : 'Disabled'} />
                    </Form.Item>
                  )}
                </Row>
              )}
            </Col>
            {!isNotDisplay && (
              <Col style={{ height: '100%' }} span={8}>
                <Form.Item
                  name="integrations"
                  key="integrations"
                  label="Integrations"
                  style={{ marginBottom: 0 }}
                >
                  <IntegrationTag id={intuitId} title="QuickBooks" />
                  <IntegrationTag id={procoreId} title="Procore" />
                  <IntegrationTag id={sageId} title="Sage" />
                  <IntegrationTag id={eclipseId} title="Eclipse" />
                </Form.Item>
              </Col>
            )}
          </Row>
          <Row>
            <Col span={18}>
              <Row
                style={{
                  height: '100%',
                  width: '100%',
                  flexDirection: 'row-reverse',
                  margin: 0,
                }}
                gutter={16}
                justify="start"
                align="middle"
              >
                <Col flex="80px" style={{ padding: 0 }}>
                  <Row style={{ height: '100%', width: '100%' }} align="middle">
                    {geofenceEnabled && isNotDisplay && (
                      <OnTraccrNumberInput
                        style={{ width: '100%' }}
                        min={0.1}
                        max={5.0}
                        step={0.1}
                        onChange={onGeofenceSizeChanged}
                        value={geofenceSize}
                        formatter={(value) => `${value} km`}
                        parser={(value) => value.replace(/[k,m ]/g, '')}
                        defaultValue={3}
                      />
                    )}
                  </Row>
                </Col>
                <Col flex="auto">
                  {geofenceEnabled && isNotDisplay && (
                    <Slider
                      min={0.1}
                      max={5.0}
                      step={0.1}
                      tooltipVisible={false}
                      onChange={onGeofenceSizeChanged}
                      value={geofenceSize}
                    />
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col span={10} style={{ height: 250 }}>
          <OnTraccrMap
            showSearch={isNotDisplay}
            lat={stateLatitude}
            lng={stateLongitude}
            address={address}
            marker={marker}
            isNotDisplay={isNotDisplay}
            googleMapURL={getGoogleMapKey()}
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={(
              <div
                style={{
                  position: 'absolute',
                  inset: 0,
                  height: 250,
                }}
              />
            )}
            mapElement={<div style={{ height: '100%' }} />}
            onPlaceChanged={onPlaceChanged}
            geofence={getGeofence({
              settings,
              geofence,
              geofenceEnabled,
              geofenceSize,
            })}
          />
        </Col>
      </Row>

      <Row gutter={20} style={{ marginTop: 30 }}>
        <Col span={8}>
          <FormTextInput
            isNotDisplay={isNotDisplay}
            value={info}
            name="info"
            label="Info"
            textarea
            autoSize
            supportMultiLine
          />
        </Col>
        { !hideBucketDropdown && (
          <Col span={8} style={{ paddingTop: 10 }}>
            <Form.Item
              name="upstreamBucketIds"
              key="upstreamBucketIds"
              label="Buckets"
              style={{
                width: '100%',
                marginBottom: 0,
              }}
            >
              {BucketSelect}
            </Form.Item>
          </Col>
        )}
        { !!relevantQBOClasses && (
          <Col span={8}>
            <Form.Item
              name="qboClassId"
              label="QuickBooks Class"
              style={{ marginBottom: 0 }}
            >
              {isNotDisplay ? (
                <Select
                  showSearch
                  optionFilterProp="label"
                >
                  <Option key="-1" value={null}>None</Option>
                  { relevantQBOClasses?.map(({ Id, Name }) => (
                    <Option key={Id} value={Id} label={Name}>
                      {Name}
                    </Option>
                  ))}
                </Select>
              ) : <DisplayText title={selectedQBOClass?.Name || 'Not Set'} /> }
            </Form.Item>
          </Col>
        )}
      </Row>

      {/* Hidden fields for map info */}
      {isNotDisplay && (
        <>
          <Form.Item
            name="address"
            rules={[{ required: isAdd, message: 'Please enter an address' }]}
            style={{
              position: 'absolute',
              top: 360,
              left: 800,
              pointerEvents: 'none',
            }}
          >
            <div hidden />
          </Form.Item>
          <Form.Item name="lat">
            <div hidden />
          </Form.Item>
          <Form.Item name="lng">
            <div hidden />
          </Form.Item>
        </>
      )}

      { !!customFields?.length && (
        <Row>
          <Form.Item
            name="customData"
            style={{ marginBottom: 20, width: '100%' }}
          >
            <CustomFields
              key={`${id}${shouldUpdate}`} // Need to force re-render
              initialValues={customData}
              customFields={customFields}
              divisions={[selectedDivisionId]}
              errors={errors}
              fileMap={customDataFiles}
              isDisplay={!isNotDisplay}
            />
          </Form.Item>
        </Row>
      )}
    </div>
  );
}

export default withScriptjs(ProjectAddGeneral);
