import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import PaginatedTable from '../common/containers/PaginatedTable';

import Permissions from '../auth/Permissions';

import { getIdMap } from '../helpers/helpers';

import getColumns from './materialTableColumns';

const INITIAL_SORT_FILTERS = {
  filters: {
    status: ['1'],
  },
  sorter: {},
};
export default ({
  onFolderEdit,
  onRowClick,
  onSelect,
  data,
  selectedColumns,
  selected,
  onShare,
}) => {
  const costcodes = useSelector(state => state.costcodes.costcodes);
  const equipment = useSelector(state => state.equipment.equipment);
  const materials = useSelector(state => state.materials.materials);
  const globalMaterialLocations = useSelector(state => state.globalMaterialLocations.globalMaterialLocations);

  const [filteredData, setFilteredData] = useState(data);

  const equipmentMap = useMemo(() => getIdMap(equipment),[equipment]);
  const costcodeIdMap = useMemo(() => getIdMap(costcodes), [costcodes]);
  const globalMaterialLocationsMap = useMemo(() => getIdMap(globalMaterialLocations), [globalMaterialLocations]);
  const selectedRowKeys = useMemo(() => selected.filter((s) => !!s).map((s) => s.id),[selected]);

  const onSelectRow = useCallback((item, checked) => {
    if (checked) {
      onSelect(selected.concat([item]))
    } else {
      onSelect(selected.filter((i) => i.id !== item.id))
    }
  },[onSelect, selected, selectedRowKeys]);
  const onSelectAll = useCallback((checked) => {
    onSelect(checked ? filteredData : []);
  },[filteredData]);

  const uniqueLocations = useMemo(() => {
    const locationMap = {};
    
    Object.values(materials).forEach(({ locations = [] }) => {
      locations.forEach(({ globalMaterialLocationsId, equipmentId }) => {
        const {
          [equipmentId]: {
            name,
          } = {},
        } = equipmentMap;
        const {
          [globalMaterialLocationsId]: {
            locationText: globalMaterialLocationsText,
          } = {},
        } = globalMaterialLocationsMap;
        if(!(name in locationMap)) {
          locationMap[name || globalMaterialLocationsText] = { 
            text: name || globalMaterialLocationsText,
             value: equipmentId || globalMaterialLocationsId 
            };
        }
      });
    });

    return locationMap;
  },[materials, equipmentMap, globalMaterialLocationsMap]);

  const columns = useMemo(() => (
    getColumns({
      costcodeIdMap,
      onFolderEdit,
      selectedColumns,
      equipmentMap,
      globalMaterialLocationsMap,
      uniqueLocations,
      onShare,
    })
  ), [costcodeIdMap, onFolderEdit, selectedColumns, equipmentMap, uniqueLocations]);

  useEffect(() => {
    if (selected.length) {
      const filteredIdSet = new Set(
        filteredData.map((data) => data.id)
      );
      const newSelected = selected.filter((s) => filteredIdSet.has(s.id));
      if (newSelected.length !== selected.length) onSelect(newSelected);
    }
  }, [filteredData, selected]);

  return (
    <PaginatedTable
      scroll={{ x:'max-content' }}
      size='small'
      rowKey='id'
      columns={columns}
      dataSource={data}
      initialSortFilters={INITIAL_SORT_FILTERS}
      rowClassName={(record) => (
        (record.quantity && record.quantity <= record.quantityThreshold) ?
        'materials-table-row-threshold' :
        'materials-table-row'
      )}
      onRow={(record) => ({
        onClick:() => onRowClick(record)
      })}
      rowSelection={Permissions.hasWrite('MATERIALS') ? {
        fixed:true,
        onSelect: onSelectRow,
        onSelectAll,
        selectedRowKeys,
      } : null}
      onFilteredDataChanged={setFilteredData}
    />
  );
}
