import React, { useCallback, } from 'react';
import { useDispatch } from 'react-redux';
import { Checkbox, Col, List, Row, Tooltip } from 'antd';

import LearningCenterPlayButton from './LearningCenterPlayButton';

import { createNuxEntry, deleteNuxEntry } from '../nux/state/nux.actions';
import { TUTORIAL_WATCH_PREFIX } from '../nux/nux.constants';

export default ({
    tutorials,
    header,
}) => {
  const dispatch = useDispatch();

  const changeWatched = useCallback((item) => (e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    item.watched = checked;
    if (checked) {
      dispatch(createNuxEntry(`${TUTORIAL_WATCH_PREFIX}${item.key}`));
    } else {
      dispatch(deleteNuxEntry(`${TUTORIAL_WATCH_PREFIX}${item.key}`));
    }
    
  });

  return (
    <div>
      <div className='learning-center-list'>
        <List
          header={header}
          dataSource={tutorials}
          itemLayout='vertical'
          locale={{emptyText: 'No Matching Search Results'}}
          renderItem={(item) => 
            <List.Item 
              key={item.title}
              extra={
                <div style={{ height:'100%' }}>
                  <Row justify='end' style={{ height: '10%'}}>
                    <Tooltip placement="top" title={item.watched ? 'Watched' : 'Unwatched'}>
                      <Checkbox checked={item.watched} onChange={changeWatched(item)}/>
                    </Tooltip>
                  </Row>
                  <Row style={{ height:'25%' }}></Row>
                  <Row>
                    <Col>
                      <LearningCenterPlayButton tutorial={item.key}/>
                    </Col>
                  </Row>
                </div>
              }
            >
              <List.Item.Meta
                title={item.title}
                description={<div><b>Audience:</b> {item.audience ? item.audience.toString() : ''}</div>}
              />
              <div>{item.description}</div>
            </List.Item>
          }
        />
      </div>
    </div>
  );
}