import React from 'react';
import { useTranslation } from 'react-i18next';

import IntegrationCostcodeSync from '../IntegrationCostcodeSync';
import {
  autoImportHeader,
  autoImportUncheckText,
  importCostcodesHelpText
} from '../integrationHelpers';

const autoImportContent = (
  <div style={{ width: 500 }}>
    {autoImportHeader('Procore', 'Cost Codes')}
    <br/><br/>1. You can only import two levels of Cost Codes into Ontraccr
    <br/><br/>{autoImportUncheckText('Procore')}
  </div>
);

const getImportHelpText = (t) => importCostcodesHelpText(t, 'Procore');

export default function ProcoreCostcodeSync({
  procoreCodes,
  onCostcodesChanged,
  importCostcodes
}) {
  const { t } = useTranslation();
  const importHelpText = getImportHelpText(t);

  return (
    <IntegrationCostcodeSync
      integrationCodes={procoreCodes}
      title='Procore'
      integrationKey='procoreId'
      onCostcodesChanged={onCostcodesChanged}
      showCustomer={false}
      autoImportContent={autoImportContent}
      importHelpText={importHelpText}
      showProject
      importCostcodes={importCostcodes}
    />
  );
}
