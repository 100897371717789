import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Drawer } from 'antd';
import SearchField from './SearchField';
import FormFieldsSearch from './FormFieldsSearch';
import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FormSelector from '../forms/FormWorkflows/selectors/FormSelector';
import {
  searchForms,
} from './state/search.actions';
import QuerySelector from './QuerySelector';

export default function FormsSearchDrawer({ visible, onClose }) {
  const dispatch = useDispatch();

  const [ourTemplateId, setOurTemplateId] = useState();
  const [selectedQuery, setSelectedQuery] = useState();
  const [ourQuery, setOurQuery] = useState({});

  const searchQueryMap = useSelector((state) => state.search.formQueries);

  const onSelectQuery = useCallback((id) => {
    if (id === selectedQuery) return;
    const q = searchQueryMap[id];
    setSelectedQuery(id);
    setOurQuery(q?.query || {});
  }, [searchQueryMap, selectedQuery]);

  const onNativeQueriesChange = useCallback((key) => (newQueries) => {
    setOurQuery({
      ...ourQuery,
      native: {
        ...(ourQuery?.native || {}),
        [key]: newQueries,
      },
    });
  }, [ourQuery]);

  const onCustomQueriesChange = useCallback((key) => (newQueries) => {
    setOurQuery({
      ...ourQuery,
      custom: {
        ...(ourQuery?.custom || {}),
        [key]: newQueries,
      },
    });
  }, [ourQuery]);

  const onTemplateChange = useCallback((newSelected) => {
    if (ourTemplateId !== newSelected) {
      setOurTemplateId(newSelected);
      setOurQuery({
        ...ourQuery,
        templateId: newSelected,
        custom: {},
      });
    }
  }, [ourTemplateId, ourQuery]);

  const onSubmit = useCallback(() => {
    const prom = dispatch(searchForms({
      query: ourQuery,
      queryId: selectedQuery,
    }));
    onClose(prom);
  }, [onClose, ourQuery, selectedQuery]);

  return (
    <Drawer
      title="Search Forms"
      visible={visible}
      onClose={onClose}
      width={800}
    >
      <div
        style={{
          position: 'absolute',
          top: '16px',
          left: 'calc(100% - 650px)',
        }}
      >
        <QuerySelector
          searchQueryMap={searchQueryMap}
          currentQuery={ourQuery}
          selectedQuery={selectedQuery}
          onSelectQuery={onSelectQuery}
          type="forms"
        />
      </div>
      <Col style={{ height: 'calc(100% - 30px)', width: '100%', overflowY: 'scroll' }}>
        <FormSelector
          onChange={onTemplateChange}
          selected={ourTemplateId}
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Form Status',
            title: 'Status',
          }}
          queries={ourQuery?.native?.status}
          onQueriesChange={onNativeQueriesChange('status')}
        />
        <SearchField
          selectedType="number"
          configProps={{
            title: 'Number',
          }}
          queries={ourQuery?.native?.formNumber}
          onQueriesChange={onNativeQueriesChange('formNumber')}
        />
        <SearchField
          selectedType="dateRange"
          configProps={{
            title: 'Created At',
          }}
          queries={ourQuery?.native?.createdAt}
          onQueriesChange={onNativeQueriesChange('createdAt')}
        />
        <SearchField
          selectedType="dateRange"
          configProps={{
            title: 'Last Updated',
          }}
          queries={ourQuery?.native?.lastUpdated}
          onQueriesChange={onNativeQueriesChange('lastUpdated')}
        />
        <SearchField
          selectedType="dateRange"
          configProps={{
            title: 'Form Update Triggered',
          }}
          queries={ourQuery?.native?.dataUpdated}
          onQueriesChange={onNativeQueriesChange('dataUpdated')}
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Customers',
            title: 'Customer',
          }}
          queries={ourQuery?.native?.customerId}
          onQueriesChange={onNativeQueriesChange('customerId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Projects',
            title: 'Project',
          }}
          queries={ourQuery?.native?.projectId}
          onQueriesChange={onNativeQueriesChange('projectId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Cards',
            title: 'Card',
          }}
          queries={ourQuery?.native?.cardId}
          onQueriesChange={onNativeQueriesChange('cardId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Equipment',
            title: 'Equipment',
          }}
          queries={ourQuery?.native?.equipmentId}
          onQueriesChange={onNativeQueriesChange('equipmentId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Vendors',
            title: 'Vendor',
          }}
          queries={ourQuery?.native?.vendorId}
          onQueriesChange={onNativeQueriesChange('vendorId')}
          multi
        />
        <SearchField
          selectedType="dropdown"
          configProps={{
            dataType: 'Costcodes',
            title: 'Costcode',
          }}
          queries={ourQuery?.native?.costcodeId}
          onQueriesChange={onNativeQueriesChange('costcodeId')}
          multi
        />
        <FormFieldsSearch
          templateId={ourTemplateId}
          query={ourQuery}
          onQueriesChange={onCustomQueriesChange}
        />
      </Col>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
      />
    </Drawer>
  );
}

FormsSearchDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
};

FormsSearchDrawer.defaultProps = {
  visible: false,
};
