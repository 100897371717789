export default {};

export const DATE_FORMAT = 'MMM D';

export const TYPE_MAP = {
  Project: ['work', 'overtime'],
  Service: ['service'],
};

export const DEFAULT_ENABLED_FILTERS = {
  project: true,
  costcode: true,
  serviceLocation: true,
};

export const DEFAULT_WORK_TYPE = new Set(['work', 'service', 'overtime']);