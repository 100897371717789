import React from 'react';
import { Form } from 'antd';

import OnTraccrTextInput from './OnTraccrTextInput';
import DisplayText from '../text/DisplayText';

const formStyle = { marginBottom:0, paddingBottom:-8 };
const formLabelStyle = {
  style:{
    paddingBottom:5,
    marginTop:10,
  },
};

export default ({
  isNotDisplay,
  label,
  name,
  value,
  rules = [],
  textarea,
  autoSize,
  placeholder,
  password,
  textProps = {},
  style = {},
  supportMultiLine,
}) => (
  <Form.Item
    name={name}
    label={label}
    style={{ ...formStyle, ... style }}
    labelCol={formLabelStyle}
    rules={rules}
  >
    {isNotDisplay
    ?
      <OnTraccrTextInput
        textarea={textarea}
        autoSize={autoSize}
        placeholder={placeholder}
        password={password}
        {...textProps}
      />
    : <DisplayText title={value} supportMultiLine={supportMultiLine} />}
  </Form.Item>
);