import React from 'react';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import '@ant-design/compatible/assets/index.css';
import { Row, Form, Divider, Button } from 'antd';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import colors from '../../constants/Colors';

import { getTextColor } from '../../schedule/scheduleHelpers';

const animationDuraiton = 0.5;


export default ({
  handleSubmit,
  isReset,
  accentColor = colors.ONTRACCR_RED,
}) => (
    <Form onFinish={handleSubmit} className="login-form" >
      <Form.Item
        name='username'
        style={{ marginBottom:0 }}
      >
        <OnTraccrTextInput
          style={{ 
            borderColor:'rgba(0,0,0,0)',
            boxShadow:'none',
            width: 300,
            borderRadius:0,
          }}
          bordered={false}
          prefix={<UserOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          placeholder="Username"
          prefixCls='login-input ant-input' // Override child input class
        />
      </Form.Item>
      <Divider style={{ margin: 0, marginBottom: 20, }} />
      <Form.Item
        name='password'
        style={{
          marginBottom:0,
          height: isReset ? 0 : 32,
          transition:`height ${animationDuraiton}s`
        }}
      >
        <OnTraccrTextInput
          key={isReset} // Force password to hide again to prevent displaying raw pw when going back to login 
          style={{
            borderColor:'rgba(0,0,0,0)',
            boxShadow:'none',
            width: 300,
            height: isReset ? 0 : 32,
            opacity: isReset ? 0 : 1,
            borderRadius:0,
            transition:`height ${animationDuraiton}s, opacity ${animationDuraiton / 2}s`
          }}
          bordered={false}
          password
          prefix={<LockOutlined style={{ color: 'rgba(0,0,0,.25)' }} />}
          type="password"
          placeholder="Password"
          inputPrefixCls='login-input ant-input' // Override child input class
        />

      </Form.Item>
      <Divider style={{
        margin: 0,
        marginBottom: isReset ? 0 : 20,
        opacity: isReset ? 0 : 1,
        transition:`margin-bottom ${animationDuraiton}s, opacity ${animationDuraiton}s`
      }} />
    <Form.Item
      name='remember'
      valuePropName='checked'
    >
      <Row justify='center'>
          <Button
            htmlType='submit'
            className='login-form-button'
            type='primary'
            style={{  
              marginTop:10,
              borderRadius:21,
              borderWidth:1.5,
              width: isReset ? 140 : 120,
              height: 42,
              transition:`width ${animationDuraiton/4}s`,
              backgroundColor: accentColor,
              borderColor: accentColor,
              color: getTextColor(accentColor),
            }}
          >
            {isReset ? 'Reset Password' : 'Login'}
          </Button>
        </Row>
      </Form.Item>
    </Form>
  );
