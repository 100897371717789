import React from 'react';
import { useTranslation } from 'react-i18next';

import NuxDetailPane from '../NuxDetailPane';
import realTime from '../../assets/images/NUX/realTime.png';
import manualEntry from '../../assets/images/NUX/manualEntry.png';

const getRealTimeDesc = (t) => `When your users clock in live, do you want it to
be mandatory for them to clock in to a specific ${t('Project')} and/or
cost code? Here's where you set that.`;

const manualDesc = `Do you want to give your users the option
to add time manually? This could be helpful in case
anyone forgets to clock in live.`;

export default function NuxTimeTracking({
  onBack,
  onTry,
}) {
  const { t } = useTranslation();
  const realTimeDesc = getRealTimeDesc(t);
  return (
    <NuxDetailPane
      onBack={onBack}
      onTry={onTry}
      rows={[{
        title:'Real-Time Clock',
        description:realTimeDesc,
        cols:[0,10,14],
        image:{
          src:realTime,
          style:{
            height:100,
            width:360,
          }
        },
      },{
        title:'Manual Entry',
        description:manualDesc,
        cols:[0,10,14],
        image:{
          src:manualEntry,
          style:{
            height:75,
            width:377,
          }
        },
      }]}
    />
  );
}
