import React, { useCallback } from 'react';
import {
  Col, DatePicker, Row,
} from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';

export default function BoardDatePicker({
  isDisplay,
  value,
  onChange,
}) {
  const {
    startDate,
    endDate,
  } = value || {};

  const parsedStartDate = startDate ? moment(startDate) : null;
  const parsedEndDate = endDate ? moment(endDate) : null;

  const onStartDateChange = useCallback((newStartDate) => {
    if (!newStartDate) {
      onChange({
        startDate: null,
        endDate: null,
      });
      return;
    }
    const startDateEpoch = newStartDate.valueOf();
    if (startDateEpoch > endDate) {
      onChange({
        startDate: startDateEpoch,
      });
    } else {
      onChange({
        startDate: newStartDate.valueOf(),
        endDate,
      });
    }
  }, [onChange]);

  const onEndDateChange = useCallback((newEndDate) => {
    if (!newEndDate) {
      onChange({
        startDate,
        endDate: null,
      });
      return;
    }
    const endDateEpoch = newEndDate.valueOf();
    if (endDateEpoch > startDate) {
      onChange({
        startDate,
        endDate: endDateEpoch,
      });
    }
  }, [onChange]);

  const displayPreview = (
    <Row
      justify="space-between"
      align="middle"
      gutter={29}
      style={{
        display: 'flex',
        marginTop: -20,
      }}
    >
      <Col
        style={{
          flexGrow: 1,
        }}
      >
        {parsedStartDate?.format('MMMM DD, YYYY')}
      </Col>
      <Col
        style={{
          flexGrow: 1,
        }}
      >
        {parsedEndDate?.format('MMMM DD, YYYY')}
      </Col>
    </Row>
  );

  return (
    isDisplay ? displayPreview
      : (
        <Row
          justify="space-between"
          align="middle"
          gutter={10}
          style={{
            display: 'flex',
          }}
        >
          <Col
            style={{
              flexGrow: 1,
            }}
          >
            <DatePicker
              value={parsedStartDate}
              onChange={onStartDateChange}
              placeholder="Select Start Date"
              style={{
                width: '100%',
              }}
            />
          </Col>
          <Col
            style={{
              flexGrow: 1,
            }}
          >
            <DatePicker
              value={parsedEndDate}
              onChange={onEndDateChange}
              placeholder="Select End Date"
              disabled={!startDate}
              style={{
                width: '100%',
              }}
            />
          </Col>
        </Row>
      )
  );
}

BoardDatePicker.propTypes = {
  isDisplay: PropTypes.bool,
  value: PropTypes.shape({
    startDate: PropTypes.number,
    endDate: PropTypes.number,
  }),
  onChange: PropTypes.func.isRequired,
};

BoardDatePicker.defaultProps = {
  isDisplay: false,
  value: null,
};
