import axios from 'axios';

import { request } from '../../helpers/requests';

export default {
  create: (payload) => request({
    call: axios.post('/dashboards', payload),
    errMsg: 'Could not create dashboard',
  }),
  get: (dashboardType) => request({
    call: axios.get('/dashboards', { params: { dashboardType } }),
    errMsg: 'Could not get dashboards',
    hideSuccessToast: true,
  }),
  delete: (id) => request({
    call: axios.delete(`/dashboards/${id}`),
    errMsg: 'Could not delete dashboard',
  }),
  update: (id, payload) => request({
    call: axios.put(`/dashboards/${id}`, payload),
    errMsg: 'Could not update dashboard',
  }),
  favorite: (id, payload) => request({
    call: axios.put(`/dashboards/${id}/favorite`, payload),
    errMsg: 'Could not update favorite dashboards',
  }),
};
