import React, { useState, useCallback } from 'react';
import { Row, Col, Select } from 'antd';

import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import CustomModal from '../../common/modals/CustomModal';

import colors from '../../constants/Colors';

const { Option } = Select;

export default ({
  typeMap,
  visible,
  onCloseClicked,
  onSave,
}) => {
  const [selectedType,setSelectedType] = useState('weekdays');
  const save = useCallback(() => onSave(selectedType),[selectedType, onSave]);
  return (
    <CustomModal
      title='Select Time Trigger'
      visible={visible}
      onCloseClicked={onCloseClicked}
      width={500}
      maskColor={colors.MODAL_MASK_DARK}
    >
      <Row style={{ width:'100%', marginBottom: 20  }} justify='center' align='middle'>
        <Select style={{ width: 350 }} onSelect={setSelectedType}>
          {Object.keys(typeMap).map((type) => (
             <Option
              value={type}
              key={type}
            >{typeMap[type]}</Option>
          ))}
        </Select>
      </Row>
      <Row justify='center' gutter={12} style={{ marginBottom: 10 }}>
        <Col>
          <OnTraccrButton
            type='cancel'
            title='Cancel'
            roundStyle
            onClick={onCloseClicked}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title='Save'
            roundStyle
            onClick={save}
          />
        </Col>
      </Row>
    </CustomModal>
  );
}