import React, {
  useEffect,
  useMemo,
} from 'react';
import {
  Checkbox,
  Form,
} from 'antd';
import PropTypes from 'prop-types';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';

const CheckboxGroup = Checkbox.Group;

export default function GanttScheduleEditScheduleTab(props) {
  const {
    scheduleId,
    selectedSchedule = {},
    setScheduleFormData,
    visible,
    schedules,
  } = props;

  const [form] = Form.useForm();

  useEffect(() => {
    if (selectedSchedule.id && visible) {
      const formData = {
        name: selectedSchedule.name,
        scheduleIds: selectedSchedule.schedules,
      };

      form.setFieldsValue(formData);
      setScheduleFormData(formData);
    } else {
      form.resetFields();
    }
  }, [selectedSchedule, visible]);

  const filteredSchedules = useMemo(() => {
    const relevantSchedules = [];

    schedules.forEach((s) => {
      if (s.id === scheduleId || !s.id) {
        return;
      }

      relevantSchedules.push({
        value: s.id,
        label: s.project ? s.project.name : s.name,
      });
    });

    return relevantSchedules.sort((a, b) => a.label.localeCompare(b.label));
  }, [schedules, scheduleId]);

  const onFormChange = (_, allValues) => {
    setScheduleFormData(allValues);
  };

  return (
    <Form
      form={form}
      onValuesChange={onFormChange}
      layout="vertical"
    >
      <Form.Item
        label="Name"
        name="name"
      >
        <OnTraccrTextInput disabled={selectedSchedule.isMaster} />
      </Form.Item>
      { !!selectedSchedule.isMaster && (
        <Form.Item
          label="Schedules"
          name="scheduleIds"
        >
          <CheckboxGroup options={filteredSchedules} className="gantt-schedule-checkbox-group" />
        </Form.Item>
      )}
    </Form>
  );
}

GanttScheduleEditScheduleTab.propTypes = {
  setScheduleFormData: PropTypes.func,
  visible: PropTypes.bool.isRequired,
  scheduleId: PropTypes.string.isRequired,
  schedules: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    isMaster: PropTypes.number,
  })).isRequired,
  selectedSchedule: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string,
    schedules: PropTypes.arrayOf(PropTypes.string),
    isMaster: PropTypes.number,
  }),
};

GanttScheduleEditScheduleTab.defaultProps = {
  setScheduleFormData: () => {},
  selectedSchedule: {},
};
