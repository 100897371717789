import React, { useState, useEffect } from 'react';
import { Row, Button, Col, Table, Transfer } from 'antd';

import DowngradeTimer from  './DowngradeTimer';

import {
  downgradeTitle,
  trialTitle,
  downgradeText,
  downgradeAction,
  trialAction,
} from './main.text';

import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import sortByString from '../helpers/helpers';

const difference = (arr1,arr2) => {
  const s2 = new Set(arr2);
  return arr1.filter((value) => !s2.has(value));
}

const buttonStyle = {
  borderRadius: 21,
  width: 110,
  height: 42,
  margin:20,
};

const textStyle = {
  fontFamily:'roboto-regular',
  fontSize:14,
  width: 500,
};

export default ({
  visible,
  onCloseClicked,
  history,
  ownerId,
  users = [],
  positionNames = [],
  userLimit = 4,
  subscriptionEnd,
}) => {
  const isDowngrade = userLimit > 0;
  const [targetKeys,setTargetKeys] = useState([]);
  const [requiredArchiveAmount, setRequiredArchiveAmount] = useState(0);

  useEffect(() => {
    setTargetKeys(users.filter((user) => !user.active).map((user) => user.id));
  },[ users ]);

  useEffect(() => {
    const activeCount = users.length - targetKeys.length;
    setRequiredArchiveAmount(Math.max(activeCount - userLimit,0));
  }, [users, targetKeys, userLimit]);

  const cols = [
    {
      title:'Name',
      dataIndex:'name',
      key:'name',
      align:'center',
      sorter:sortByString('name'),
      showSorterTooltip:false,
      sortDirections:['descend','ascend']
    },
    {
      title:'Position',
      dataIndex:'position',
      key:'position',
      align:'center',
      sorter:sortByString('position'),
      showSorterTooltip:false,
      filters:positionNames.map(({ name }) => ({
        text:name,
        value:name,
      })),
      onFilter: (position, user) => user.position === position,
    },
    {
      title:'Wage',
      dataIndex:'wage',
      key:'wage',
      align:'center',
      render:(_,user) => `$${user.wage}`,
      sorter:(a,b) => a.wage - b.wage,
      showSorterTooltip:false,
    }
  ];
  return (
    <CustomModal
      title={isDowngrade ? downgradeTitle : trialTitle}
      visible={visible}
      hideCloseButton
      width={isDowngrade ? 1000 : 600}
    >
      <div style={{ width:'100%' }}>
        <Row style={{ width:'100%', marginBottom: isDowngrade ? 0 : 20 }} justify='center'>
          <Col>
            {isDowngrade && <p style={textStyle}>{downgradeText}</p>}
            {isDowngrade && <p style={textStyle}>
              This can happen if you have reduced your subscription license count, cancelled your subscription or your payment has failed.<br/>
            </p>}
            <div style={textStyle}>
              Please navigate to <Button
                type='link'
                style={{ padding: '0px 3px' }}
                onClick={() => {
                  history.push('/settings',{ activeTab: 'billing' });
                }}
              > the billing portal
              </Button>{isDowngrade ? downgradeAction : trialAction}
              <br/>
              {isDowngrade &&
                <div>
                  OR archive <b style={{ 
                    fontSize: 20,
                    color: requiredArchiveAmount > 0 ? 'red' : 'inherit'
                  }}>{requiredArchiveAmount}</b> more users
                </div>
              }
            </div>
          </Col>
        </Row>
        {isDowngrade &&
          <div>
            <Row style={{ width:'100%' }} justify='center'>
              <DowngradeTimer subscriptionEnd={subscriptionEnd}/>
            </Row>
            <Row style={{ width:'100%', marginTop: 20 }} justify='center'>
              <p style={textStyle}>
                Please resolve this issue before the grace period expires.
              </p>
              <p style={textStyle}>
                Failure to resolve the issue before the end of the grace period
                will result in automatic archival of the required number of user accounts.
              </p>
            </Row>
            <Row style={{width:'100%', height: 400}} type='flex' justify='center'>
              <Transfer 
                style={{width:'100%', height: 300, margin:50 }}
                dataSource={users}
                showSearch 
                locale={{
                  itemUnit:'Users',
                  itemsUnit:'Users',
                  searchPlaceholder:'Search'
                }}
                showSelectAll={false} 
                pagination={false}
                operations={['Archive', 'Activate']}
                filterOption={(searchTerm,user) => user.name && user.name.toLowerCase().includes(searchTerm.toLowerCase()) }
                onChange={setTargetKeys}
                titles={['Active Users','Archived Users']}
                rowKey={(user) => user.id}
                targetKeys={targetKeys}
              >
              {({
                direction,
                filteredItems,
                onItemSelectAll,
                onItemSelect,
                selectedKeys: listSelectedKeys,
              }) => {

                

                return (<Table
                  style={{ width: '100%'}}
                  size='small'
                  columns={cols}
                  dataSource={filteredItems}
                  rowKey={(user) => user.id}
                  rowSelection={{
                    selectedRowKeys: listSelectedKeys,
                    onSelectAll:(selected,selectedRows,changeRows) => {
                      const treeSelectedKeys = selectedRows
                        .map(({ key }) => key);
                      const diffKeys = selected
                        ? difference(treeSelectedKeys, listSelectedKeys)
                        : difference(listSelectedKeys, treeSelectedKeys);
                      onItemSelectAll(diffKeys, selected);
                    },
                    onSelect({ id }, selected) {
                      onItemSelect(id, selected);
                    },
                    getCheckboxProps:(user) => ({
                      disabled:user.id === ownerId
                    })
                  }}
                  pagination={false}
                  scroll={{
                    y:160,
                  }}
                />)
              }}
              </Transfer>
            </Row>

            <Row style={{width:'100%'}} type='flex' justify='center'>
              <OnTraccrButton
                title='Submit'
                style={buttonStyle}
                disabled={requiredArchiveAmount > 0}
                onClick={async () => {
                  onCloseClicked(targetKeys);
                }}
              />
            </Row>
          </div>
        }
      </div>
    </CustomModal>
  );
}
