import React from 'react';
import { Form } from 'antd';

import FileUpload from '../../files/FileUpload';
import PDFRectangleFieldConfigure from './PDFRectangleFieldConfigure';

import { imageValidator } from './PDFDesignerHelpers';
import { defaultDrawOptions } from './PDFDesigner.constants';

const formStyle = { marginBottom: 5 };
export default ({
  onDrawOptionChanged,
  page,
  numberOfPages,
  onPageChanged,
  onImageAdd,
  field = {},
  useStandardTemplate,
}) => (
  <>
    <PDFRectangleFieldConfigure
      field={field}
      onDrawOptionChanged={onDrawOptionChanged}
      page={page}
      numberOfPages={numberOfPages}
      onPageChanged={onPageChanged}
      style={formStyle}
      useStandardTemplate={useStandardTemplate}
    />
    <Form.Item key='value' label='Change Image' style={formStyle}>
        <div className='pdf-designer-image-configure-container'>
          <FileUpload
            style={{ top: 0, }}
            addFile={onImageAdd}
            customValidator={imageValidator}
          />
        </div>
    </Form.Item>
  </>
)