import React from 'react';
import { Row, Col, Divider } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';

import { DateTime } from 'luxon';
import Permissions from '../auth/Permissions';

import ProfileCircle from '../common/profile/ProfileCircle';
import PhotoReelPreview from '../common/photos/PhotoReelPreview';

import BorderlessButton from '../common/buttons/BorderlessButton';

import colors from '../constants/Colors';
import { initialsFromName } from '../helpers/users';

export default ({
  userId,
  userMap,
  version,
  date = 0,
  onClick,
  onDownload,
  file,
}) => {
  const {
    [userId]:user = {},
  } = userMap;
  const {
    name = 'Someone',
  } = user;
  const initials = initialsFromName(name);
  const isCurrentUser = Permissions.id === userId;
  return (
    <Row style={{ width: '100%'}}>
      <Row style={{ padding: '20px 0px', width: '100%' }}>
        <Col flex='60px'>
          <ProfileCircle
            initials={initials}
          />
        </Col>
        <Col flex='auto'>
          <Row>
            <div>
              <span style={{ fontFamily: 'roboto-bold'}}>{isCurrentUser ? 'You' : name}</span> uploaded version {version}
            </div>
          </Row>
          <Row style={{ fontSize: 10, color: colors.ONTRACCR_OPACITY_GRAY}}>
            {DateTime.fromMillis(date).toLocaleString(DateTime.DATETIME_MED)}
          </Row>
        </Col>
        <Col flex='100px' /* Photo is 100px wide, this gives us a 50px right margin*/>
          <PhotoReelPreview file={file} small onClick={onClick}/>
        </Col>
        <Col flex='50px'>
          <Row style={{ width:'100%', height:'100%'}} justify='center' align='middle'>
            <BorderlessButton
              style={{
                margin:0,
              }}
              iconNode={<DownloadOutlined style={{ marginLeft: 0, fontSize: 20, }}/>}
              onClick={onDownload}
            />
          </Row>
        </Col>
      </Row>
      <Divider style={{ 
        backgroundColor:colors.LIGHT_GRAY,
        margin: 0,
        width:'100%',
        minWidth:'100%',
      }}/>
    </Row>
  );
}