/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useMemo } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { Row } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

import { parseCompletedForm } from '../../forms/formHelpers';
import BorderlessButton from '../../common/buttons/BorderlessButton';

import BoardCard from './BoardCard';
import { getTemplateMap } from '../boards.helpers';

export default function BoardColumn({
  id,
  title,
  isLocked,
  showCardNumber,
  style = {},
  onAdd,
  cards = [],
  onClick,
  onEdit,
  onForm,
  displayFields = [],
  cardTemplate = {},
  userBoardPermissions,
  projectIdMap,
  onBoardSelected,
}) {
  const draggableId = id ? id.toString() : 'null';

  const {
    canEdit,
  } = userBoardPermissions;

  const onAddClicked = useCallback(() => {
    onAdd(id);
  }, [id, onAdd]);
  const displaySet = useMemo(() => new Set(displayFields), [displayFields]);
  const fullTitle = `${title} ${cards.length}`;

  const templateMap = useMemo(() => getTemplateMap(cardTemplate), [cardTemplate]);
  const {
    cards: cardViews,
    shadows,
  } = useMemo(() => (
    cards.reduce((acc, card) => {
      const { data = [] } = card;
      const fullData = parseCompletedForm({
        sections: data,
        templateMap,
      });
      const comp = (
        <BoardCard
          key={card.id}
          {...card}
          onClick={onClick}
          isLocked={isLocked}
          showCardNumber={showCardNumber}
          displayFields={displaySet}
          data={fullData}
          onEdit={onEdit}
          onForm={onForm}
          userBoardPermissions={userBoardPermissions}
          projectIdMap={projectIdMap}
          onBoardSelected={onBoardSelected}
        />
      );
      if (card.isShadow) {
        acc.shadows.push(comp);
      } else {
        acc.cards.push(comp);
      }
      return acc;
    }, { cards: [], shadows: [] })
  ), [cards, onClick, onEdit, isLocked, displaySet, templateMap, projectIdMap, onBoardSelected]);

  let cardStyle = {};
  if (cardViews.length === 0) {
    cardStyle = shadows.length === 0
      ? { height: '100%' }
      : { minHeight: 1 };
  }
  return (
    <div className="boards-column" style={style}>
      <div className="boards-column-inner-container">
        <Row justify="center" className="boards-column-header">
          {fullTitle}
          {canEdit && (
            <BorderlessButton
              iconNode={<PlusOutlined />}
              style={{
                position: 'absolute',
                right: 3,
                width: 20,
                height: 20,
                padding: 0,
              }}
              onClick={onAddClicked}
            />
          )}
        </Row>
        <div
          style={{
            height: 'calc(100% - 23px)',
            width: '100%',
            overflowY: 'auto',
          }}
        >
          <Droppable
            droppableId={draggableId}
            type="CARDS"
            direction="vertical"
          >
            {({ droppableProps, innerRef, placeholder }) => (
              <div
                {...droppableProps}
                ref={innerRef}
                style={cardStyle}
              >
                {cardViews}
                {placeholder}
              </div>
            )}
          </Droppable>
          {shadows}
        </div>
      </div>
    </div>
  );
}

/* eslint-disable react/forbid-prop-types */
BoardColumn.propTypes = {
  id: PropTypes.number,
  title: PropTypes.string,
  isLocked: PropTypes.number,
  style: PropTypes.shape({
    width: PropTypes.string,
  }),
  onAdd: PropTypes.func,
  cards: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    orderIndex: PropTypes.number,
    onClick: PropTypes.func,
    onEdit: PropTypes.func,
    onForm: PropTypes.func,
    isLocked: PropTypes.number,
    color: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      avatar: PropTypes.string,
    })),
    draggable: PropTypes.bool,
    data: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      type: PropTypes.string,
      value: PropTypes.string,
    })),
  })),
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onForm: PropTypes.func,
  displayFields: PropTypes.arrayOf(PropTypes.string),
  cardTemplate: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      fields: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        type: PropTypes.string,
        value: PropTypes.string,
      })),
    })),
  }),
  userBoardPermissions: PropTypes.shape({
    canEdit: PropTypes.bool,
  }),
  showCardNumber: PropTypes.number,
  projectIdMap: PropTypes.object,
  onBoardSelected: PropTypes.func.isRequired,
};

BoardColumn.defaultProps = {
  id: null,
  title: '',
  isLocked: 0,
  style: {},
  onAdd: null,
  cards: [],
  onClick: null,
  onEdit: null,
  onForm: null,
  displayFields: [],
  cardTemplate: {},
  userBoardPermissions: {
    canEdit: false,
  },
  showCardNumber: false,
  projectIdMap: {},
};
