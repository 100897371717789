import React from 'react';
import { Row, Col } from 'antd';

import NuxStepNumber from './NuxStepNumber';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DisplayText from '../common/text/DisplayText';
import LearningCenterPlayButton from '../learningCenter/LearningCenterPlayButton';

import colors from '../constants/Colors';

const NuxRow = ({
  number,
  title,
  description,
  image,
  cols = [number ? 2 : 0, number ? 14 : 16, 8],
  style = {},
}) => (
  <Row style={{ width: '100%', maxHeight: 150, ...style }}>
    {number && <Col span={cols[0]}>
      <NuxStepNumber number={number}/>
    </Col>}
    <Col span={cols[1]}>
      <DisplayText 
        title={title}
        style={{ 
          textAlign:'left',
          fontFamily:'roboto-bold',
          fontSize:18,
        }}
      />
      <DisplayText 
        title={description} 
        style={{ 
          textAlign:'left',
          fontFamily:'roboto-regular',
          fontSize:14,
        }}
      />
    </Col>
    <Col span={cols[2]}>
      <Row style={{ width: '100%', height: '100%'}} align='middle' justify='center'>
        <img
          className='nux-screenshot'
          alt={title}
          style={{
            height:75,
            width:64
          }}
          {...image}
        />
      </Row>
    </Col>
  </Row>
);


export default ({
  onBack,
  onTry,
  rows = [],
  warning,
  style = {},
  customContent,
  tutorialLink
}) => (
  <Row style={{
    width: '100%',
    height: 'calc(100% - 46px)',
    position:'relative',
    padding: 40,
    ...style,
  }}>
    {customContent ? customContent : <Row style={{ width: '100%', height: 150 * rows.length, maxHeight:300}} align='top'>
      {rows.map(NuxRow)}
    </Row>}
    {warning && 
      <Row 
        justify='start' 
        style={{
          position:'absolute',
          bottom: 80,
          left:40,
          right:40,
          color:colors.ONTRACCR_RED,
          fontSize:14,
        }}>
      {warning}
    </Row>}
    <Row 
      justify='center'
      gutter={20}
      style={{ 
        position:'absolute',
        bottom: 20,
        left:0,
        right:0,
      }}
    >
      <Col>
        <OnTraccrButton
          title='Go Back'
          type='cancel'
          onClick={onBack}
          roundStyle
          fat
        />
      </Col>
      {tutorialLink && <Col>
        <LearningCenterPlayButton tutorial={tutorialLink}/>
      </Col>}
      {onTry && <Col>
        <OnTraccrButton
          title='Try It'
          onClick={onTry}
          roundStyle
          fat
        />
      </Col>}
    </Row>
  </Row>
);