import { DateTime } from 'luxon';
import { SUBTASK_CATEGORIES, SUBTASK_DUE_DATE_CATEGORIES } from './subtasks.constants';

export const getCategory = (subtask) => {
  if (subtask.equipmentId) return SUBTASK_CATEGORIES.EQUIPMENT;
  if (subtask.cardId) return SUBTASK_CATEGORIES.CARD;
  return SUBTASK_CATEGORIES.GENERAL;
};

export const getDueDateCategory = (subtask) => {
  const startOfToday = DateTime.local().startOf('day').toMillis();
  const endOfToday = DateTime.local().endOf('day').toMillis();

  if (!subtask.dueDate) return SUBTASK_DUE_DATE_CATEGORIES.UNSCHEDULED;
  if (subtask.dueDate < startOfToday) return SUBTASK_DUE_DATE_CATEGORIES.OVERDUE;
  if (subtask.dueDate < endOfToday) return SUBTASK_DUE_DATE_CATEGORIES.TODAY;
  return SUBTASK_DUE_DATE_CATEGORIES.NEXT;
};
