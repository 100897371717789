import React, { useState, useCallback } from 'react';
import { Row, Col } from 'antd';
import CustomModal from '../common/modals/CustomModal';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import { logout } from './authHelpers';

export default ({
  visible,
  onCloseClicked,
  onLogout,
}) => {
  const [loading,setLoading] = useState(false);
  const onLogoutClicked = useCallback(() => {
    setLoading(true);
    setTimeout(async () => {
      await logout(async (didLogout) => {
        if(didLogout) {
          await onLogout();
          setLoading(false);
        }
      });
    }, 250);
  },[onLogout]);
  return (
    <CustomModal
      title='Log Out'
      visible={visible}
      onCloseClicked={onCloseClicked}
    >
      <div style={{ fontSize: 14 }}>
        <p>Are you sure you want to logout?</p>
      </div>
      <Row style={{ width:'100%', margin: '20px 0px' }} align='middle' justify='space-around'>
        <Col>
          <OnTraccrButton
            title='Cancel'
            type='cancel'
            roundStyle
            onClick={onCloseClicked}
          />
        </Col>
        <Col>
          <OnTraccrButton
            title='Log Out'
            roundStyle
            onClick={onLogoutClicked}
            loading={loading}
          />
        </Col>
      </Row>
    </CustomModal>
  )
};
