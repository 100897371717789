import React from 'react';
import { connect } from 'react-redux';
import { Route, withRouter, Redirect } from 'react-router-dom';

import BreadCrumbContainer from '../common/breadcrumbContainer/breadcrumbContainer';
import UserList from './userList';

import TimeTracking from '../state/timeTracking';
import { getUsers, createUser, getUserLabels } from './state/users.actions';
import { setBreadcrumb } from '../common/breadcrumbContainer/state/breadcrumb.actions';
import { getUnions } from '../unions/state/unions.actions';
import { getCardLinks } from '../boards/state/boards.actions';
import { getSageShifts } from '../sage/state/sage.actions';
import { getLabels } from '../labels/state/labels.actions';
import { getUsersFromSelectedDivisions } from '../timecards/timecard.helpers';

import Permissions from '../auth/Permissions';
import { findPayrollStartAndEndDates } from '../helpers/payroll';
import { updateCurrentTimeEntriesRange } from '../timecards/state/timecards.actions';

export default connect(
  (state,ownProps) => {
    return {
      ...ownProps,
      users: state.users.users,
      costcodes: state.costcodes.costcodes,
      projects: state.projects.projects,
      crumbs: state.breadcrumb,
      selectedUser: state.users.selectedUser,
      selectedDivisions: state.settings.selectedDivisions,
      divisions: state.settings.divisions,
      payPeriod: state.timecards.payPeriod,
      semiMonthlyPayPeriodDates: state.timecards.semiMonthlyPayPeriodDates,
      firstPayrollDay: state.timecards.firstPayrollDay,
    };
  }, {
    getUsers,
    createUser,
    setBreadcrumb,
    getUserLabels,
    getUnions,
    getCardLinks,
    getSageShifts,
    getLabels,
    getTimeEntries: TimeTracking.getTimeEntries,
    updateCurrentTimeEntriesRange,
  },
)(withRouter(class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
    }
    this.defaultCrumbs = [{
      text:'Staff',
      icon:'user',
    },{
      text:'Manage Users',
    }
    ];

    this.props.setBreadcrumb(this.defaultCrumbs);
    this.props.getUsers();
    this.props.getUserLabels();
    this.props.getLabels();
    this.props.getUnions();
    this.props.getCardLinks();
    this.props.getSageShifts({ divisionIds: Array.from(this.props.selectedDivisions) });
  }

  componentDidMount() {
    this.getTimeEntriesForUsers();
  }

  componentDidUpdate(prevProps) {
    const {
      users: prevUsers = [],
      selectedDivisions: prevSelected,
      divisions: prevDivisions,
      firstPayrollDay: prevFirstPayrollDay,
      payPeriod: prevPayPeriod,
      semiMonthlyPayPeriodDates: prevSemiMonthlyPayPeriodDates,
    } = prevProps;
    const {
      divisions,
      selectedDivisions,
      users,
      firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
    } = this.props;

    if (users !== prevUsers
      || divisions !== prevDivisions
      || (selectedDivisions.size > 0 && selectedDivisions !== prevSelected)
      || firstPayrollDay !== prevFirstPayrollDay
      || payPeriod !== prevPayPeriod
      || prevSemiMonthlyPayPeriodDates !== semiMonthlyPayPeriodDates
    ) {
      this.getTimeEntriesForUsers();
    }
  }

  async onUserCreate(user,projectMap,teamMap) {
    const success = await this.props.createUser(user,projectMap,teamMap);
    if(success) {
      await this.props.getUsers();
    }
    return success;
  }

  async onUserClicked(user) {
    // Need to change crumb state with store.
      if (user) {
        this.props.setBreadcrumb(this.defaultCrumbs.concat([{
          text:user.name
        }]));
      } else {
        this.props.setBreadcrumb(this.defaultCrumbs);
      }
  }

  getTimeEntriesForUsers() {
    const {
      divisions,
      selectedDivisions,
      users,
      getTimeEntries,
      firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
      updateCurrentTimeEntriesRange,
    } = this.props;
    const [payPeriodStart, payPeriodEnd] = findPayrollStartAndEndDates({
      payPeriodFirstDay: firstPayrollDay,
      payPeriod,
      semiMonthlyPayPeriodDates,
    });
    const selectedDivUsers = getUsersFromSelectedDivisions({
      users,
      selectedDivisions,
      divisions,
    });
    getTimeEntries({
      startTime: payPeriodStart.toMillis(),
      endTime: payPeriodEnd.toMillis(),
      getRunningTasks: true,
      userIds: selectedDivUsers.map((u) => u.id),
    });
    updateCurrentTimeEntriesRange(payPeriodStart, payPeriodEnd);
  }

  render() {
    if(!Permissions.match('USERS')) return <Redirect to='/dashboard'/>;
    return (
      <BreadCrumbContainer crumbs={this.props.crumbs}>
        <Route path='/users' render={props =>
          <UserList
            onUserClicked={this.onUserClicked.bind(this)}
            onUserCreate={this.onUserCreate.bind(this)}
            onReload={this.props.getUsers}
          />
        }/>
      </BreadCrumbContainer>
    );
  }
}));
