import axios from 'axios';
import { request } from '../../helpers/requests';

const EmailService = {
  getAll: () => request({
    call: axios.get('emails'),
    errMsg: 'Failed to get emails',
    hideSuccessToast: true,
  }),
};

export default EmailService;
