import * as Sentry from '@sentry/react';

import {
  GET_ALL_APPROVALS,
} from '../../state/actionTypes';

const initialState = {
  approvals: [],
  approvalCount: 0,
};

export default function ClockinActions(state = initialState, action = {}) {
  switch (action.type) {
    case GET_ALL_APPROVALS: {
      const {
        payload: {
          approvals = [],
        } = {},
      } = action;
      if (!approvals) return state;
      const seen = new Set();
      let duplicateId;
      const deduplicatedApprovals = [];
      approvals.forEach((task) => {
        if (seen.has(task.id)) {
          duplicateId = task.id;
          return;
        }
        seen.add(task.id);
        deduplicatedApprovals.push(task);
      });

      if (duplicateId) {
        Sentry.withScope(() => {
          Sentry.captureException('duplicate approval', { extra: { duplicateId } });
        });
      }
      const uniqueId = [...new Set(deduplicatedApprovals.map(({ userId }) => `${userId}`))];
      const data = {
        ...state,
        approvals: deduplicatedApprovals,
        approvalCount: uniqueId.length,
      };

      return data;
    }
    default:
      return state;
  }
}
