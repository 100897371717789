import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col, Select } from 'antd';
import {
  ZoomOutOutlined,
  ZoomInOutlined,
} from '@ant-design/icons';

import { setScale } from './state/schedule.actions';

import BorderlessButton from '../common/buttons/BorderlessButton';

const zoomLevels = [0.25, 0.5, 0.75, 1, 1.25, 1.5, 1.75, 2];

const changeZoom = (zoom, direction) => {
  const currIndex = zoomLevels.findIndex((item) => item === zoom);
  if (currIndex < 0) return 1;
  return zoomLevels[currIndex + direction];
};

export default function ScheduleZoomButtons() {
  const dispatch = useDispatch();
  const scale = useSelector((state) => state.schedule.scale);
  const onScaleChange = (newScale) => {
    dispatch(setScale(newScale));
  };

  return (
    <Row justify="end">
      <Col>
        <BorderlessButton
          iconNode={<ZoomOutOutlined style={{ marginLeft: -10 }} />}
          disabled={scale <= zoomLevels[0]}
          style={{
            backgroundColor: 'transparent',
          }}
          onClick={() => onScaleChange(changeZoom(scale, -1))}
        />
      </Col>
      <Col>
        <Select
          value={scale}
          onChange={(val) => onScaleChange(val)}
          style={{ width: 80 }}
          getPopupContainer={(node) => node.parentNode}
        >
          {zoomLevels.map((level) => (
            <Select.Option key={level} value={level}>{`${parseInt(level * 100)} %`}</Select.Option>
          ))}
        </Select>
      </Col>
      <Col>
        <BorderlessButton
          disabled={scale >= zoomLevels[zoomLevels.length - 1]}
          iconNode={<ZoomInOutlined style={{ marginLeft: 0, marginRight: -10 }} />}
          style={{ backgroundColor: 'transparent' }}
          onClick={() => onScaleChange(changeZoom(scale, 1))}
        />
      </Col>
    </Row>
  );
}
