import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Drawer, Form, Row, Col, Select, Table } from 'antd';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';

import FormTextInput from '../../common/inputs/FormTextInput';
import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import HoverHelp from '../../common/HoverHelp';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

import {
  createCustomTable,
  updateCustomTable,
} from '../../forms/state/forms.actions'; 
import BorderlessButton from '../../common/buttons/BorderlessButton';

const DRAWER_WIDTH = 900;
const FormHeader = ({
  title,
  help,
  onAdd
}) => (
  <Row
    className='form-required-field'
    style={{ marginTop: 15, marginLeft: 0, }}
    justify='start'
    gutter={20}
  >
    <Col style={{ paddingLeft: 0, color: 'rgba(0,0,0,0.85)' }}>
      {title}
    </Col>
    <Col>
      <HoverHelp
        placement='rightBottom'
        content={
          <div style={{ width: 300}}>
            {help}
          </div>
        }
      />
    </Col>
    <Col flex='auto'>
     <Row justify='end'>
      {
        onAdd &&
        <OnTraccrButton
          title='Add'
          icon={<PlusOutlined/>}
          onClick={onAdd}
        />
      }
      </Row>
    </Col>
  </Row>
);

const parseOptions = (columns = [], options = []) => {
  const colSet = new Set(columns.map((col) => col.key));
  return options.map((opt) => {
    const parsedOpt = { ...opt };
    Object.keys(parsedOpt).forEach((key) => {
      if(!colSet.has(key) && key !== 'id') delete parsedOpt[key];
    });
    return parsedOpt;
  });
}

const getBaseOptions = () => [{ id: DateTime.local().toMillis() }];
export default ({
  selectedTable,
  onClose,
  visible,
}) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [loading,setLoading] = useState(false);
  const [columns,setColumns] = useState([]);
  const [options,setOptions] = useState(getBaseOptions());
  const [identifier, setIdentifier] = useState();

  const onDelete = useCallback((id) => () => {
    setOptions(
      options.filter((opt) => opt.id !== id)
    );
  },[options]);

  const onAdd = useCallback(() => {
    setOptions(options.concat([{ id: DateTime.local().toMillis() }]));
  },[options]);

  const onCustomOptionDeselect = useCallback((key) => {
    setColumns(
      columns.filter(item => item.key !== key),
    );
  },[columns]);
  const onCustomOptionSelect = useCallback((key) => {
    const existing = new Set(columns.map((col) => col.key));
    if(existing.has(key)) return;
    setColumns(
      columns.concat([{
        key,
        name: key,
      }])
    );
  },[columns]);

  const onDataChanged = useCallback(({ key, id }) => (e) => {
    const {
      target: {
        value,
      } = {},
    } = e;
    setOptions(
      options.map((opt) => {
        if(opt.id !== id) return opt;
        return { ...opt, [key]: value };
      })
    );
  },[options]);

  const onSubmit = useCallback(async () => {
    const values = form.getFieldsValue();
    const payload = {
      ...values,
      data: {
        columns,
        options: parseOptions(columns, options),
        identifier,
      },
    };
    setLoading(true);
    const { id: selectedId } = selectedTable || {};
    if (selectedId) {
      if(await dispatch(updateCustomTable(selectedId, payload))) {
        onClose();
      }
    } else if(await dispatch(createCustomTable(payload))) {
      onClose();
    }
    setLoading(false);

  },[form, selectedTable, columns, options, identifier]);

  useEffect(() => {
    if(selectedTable) {
      form.setFieldsValue({ ...selectedTable })
      const {
        columns: dataColumns = [],
        options: dataOptions = [],
        identifier: dataIdentifier,
      } = selectedTable;
      setColumns(dataColumns);
      setOptions(dataOptions);
      setIdentifier(dataIdentifier);
    } else {
      form.resetFields();
      setColumns([]);
      setOptions(getBaseOptions());
      setIdentifier();
    }
  },[form,selectedTable]);

  const tableColumns = useMemo(() => (
    columns.map(({ key }) => ({
      title: key,
      dataIndex: key,
      width: 250,
      render:(value, item) => (
        <OnTraccrTextInput
          textarea key={key + item.id}
          value={value}
          onChange={onDataChanged({ key, id: item.id })}
        />
      ),
    })).concat([{
      title:'',
      key:'delete',
      render:(_,item) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ marginLeft:0, color: 'red' }}/>}
          onClick={onDelete(item.id)}
        />
      )
    }])
  ),[columns, onDelete, onDataChanged]);

  const canSubmit = useMemo(() => {
    if(!identifier) return;
    const values = form.getFieldsValue();
    if (!values || !values.name || !values.description) return false;
    if (columns.length === 0) return false;
    const colMap = new Set(columns.map((col) => col.key));
    const relevantOptions = options.filter((opt = {}) => (
      Object.keys(opt).some((key) => (
        colMap.has(key) && opt[key]
      ))
    ));
    return relevantOptions.length > 0;
  },[form,columns, options, identifier]);

  return (
    <Drawer
      title='Add Custom Form List'
      visible={visible}
      onClose={onClose}
      width='fit-content'
      bodyStyle={{
        maxWidth: '100vw',
        width: 250 * columns.length,
        minWidth: DRAWER_WIDTH
      }}
    >
      <Form
        layout='vertical'
        form={form}
        className='custom-form-table-add-drawer'
      >
        <FormTextInput
          isNotDisplay
          name='name'
          label='Name'
          rules={[
            { required: true, message: 'Name is required '}
          ]}
        />
        <FormTextInput
          isNotDisplay
          name='description'
          label='Description'
          textarea
          rules={[
            { required: true, message: 'Description is required '}
          ]}
        />
        <FormHeader
          title='Columns'
          help='Columns should be entered in the order you want them to appear (left to right).'
        />
        <Row style={{ marginTop: 5, marginLeft:0, marginRight:0 }} gutter={10}>
          <Select
            style={{ width: '100%' }}
            mode='tags'
            tokenSeparators={[',']}
            placeholder='Enter column names'
            onSelect={onCustomOptionSelect}
            onDeselect={onCustomOptionDeselect}
            value={columns.map((col) => col.key)}
            open={false}
          />
        </Row>
        <FormHeader
          title='Identifier'
          help='Select which column is the identifier for each option'
        />
        <Select
          style={{ width: '100%', marginTop: 5, }}
          placeholder='Select Identifier'
          onSelect={setIdentifier}
          value={identifier}
        >
          {
            columns.map((col) => (
              <Select.Option key={col.key} value={col.key}>
                {col.name}
              </Select.Option>
            ))
          }
        </Select>
        <FormHeader
          title='Options'
          help='Enter the options that users can select from.'
          onAdd={columns.length > 0 ? onAdd : null}
        />
        <Table
          dataSource={columns.length > 0 ? options : []}
          columns={tableColumns}
          pagination={false}
          scroll={{ x: 'max-content' }}
        />
      </Form>
      <DrawerSubmitFooter
        onSubmit={onSubmit}
        onClose={onClose}
        loading={loading}
        canSubmit={canSubmit}
      />
    </Drawer>
  )
}