import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Drawer,
  Form,
  Row,
  Select,
  TreeSelect,
} from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { updateTimeCardFilters } from './state/timecards.actions';
import { getProjectOptions } from '../forms/CompletedForms/formFilters.helpers';
import { getIdMap, mergeSets } from '../helpers/helpers';
import { getPhaseCostcodeTreeData } from '../forms/formHelpers';

const defaultFormValues = {
  users: [],
  type: [],
  projects: [],
  costcodes: [],
  status: [],
  enteredViaUser: [],
  enteredViaTablet: [],
};

const taskStatuses = [
  {
    label: 'Approved',
    value: 'approved',
  },
  {
    label: 'Submitted',
    value: 'submitted',
  },
  {
    label: 'Unsubmitted',
    value: 'unsubmitted',
  },
];

const taskTypes = [
  {
    label: 'Work',
    value: 'work',
  },
  {
    label: 'Break',
    value: 'break',
  },
  {
    label: 'Overtime',
    value: 'overtime',
  },
  {
    label: 'Service',
    value: 'service',
  },
];

export default function TimeCardFilterDrawer({
  visible,
  onClose,
  relevantUsers = [],
}) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const timeCardFilters = useSelector((state) => state.timecards.filters);
  const projects = useSelector((state) => state.projects.projects);
  const selectedDivisions = useSelector((state) => state.settings.selectedDivisions);
  const divisions = useSelector((state) => state.settings.divisions);
  const tablets = useSelector((state) => state.tablets);
  const users = useSelector((state) => state.users.users);
  /*
    relevantUsers containers users filtered by team
    we want all users for approverBy filter
  */

  const {
    activePhases,
    costcodes,
  } = useSelector((state) => {
    const {
      costcodes: {
        phases: statePhases = [],
        costcodes: stateCostcodes = [],
      } = {},
    } = state;
    return {
      activePhases: statePhases,
      costcodes: stateCostcodes,
    };
  });

  const [selectedProjects, setSelectedProjects] = useState([]);

  const activeCostcodes = useMemo(() => (
    costcodes.filter((cc) => cc.active)
  ), [costcodes]);

  const activeCostcodesMap = useMemo(() => getIdMap(activeCostcodes), [activeCostcodes]);
  const projectMap = useMemo(() => getIdMap(projects), [projects]);

  const userOptions = useMemo(() => (
    relevantUsers.map((user) => ({ value: user.id, label: user.name }))
  ), [relevantUsers]);

  const approverOptions = useMemo(() => {
    const userDivSets = mergeSets(
      Object.values(divisions)
        .filter(({ id: divisionId }) => selectedDivisions.has(divisionId))
        .map(({ users: divUsers }) => divUsers),
    );
    return users
      .filter((user) => user.active && userDivSets.has(user.id))
      .map((user) => ({
        value: user.id,
        label: user.name,
      }));
  }, [users, divisions, selectedDivisions]);

  const projectOptions = useMemo(() => (
    getProjectOptions(projects, selectedDivisions)
  ), [projects, selectedDivisions]);

  const tabletOptions = useMemo(() => (
    tablets.map((tablet) => ({ value: tablet.id, label: tablet.username }))
  ), [tablets]);

  const costcodeTreeData = useMemo(() => {
    if (!selectedProjects.length) {
      return getPhaseCostcodeTreeData({
        projectId: null,
        phases: activePhases,
        costcodes: activeCostcodes,
        costcodeMap: activeCostcodesMap,
        projectMap,
      });
    }

    const projectsTree = [];

    selectedProjects.forEach((projectId) => {
      const treeData = getPhaseCostcodeTreeData({
        projectId,
        phases: activePhases,
        costcodes: activeCostcodes,
        costcodeMap: activeCostcodesMap,
        projectMap,
      });

      const ourProject = projectMap?.[projectId] ?? {};

      projectsTree.push({
        value: ourProject?.id,
        title: ourProject?.name,
        children: treeData,
        selectable: false,
      });
    });
    return projectsTree;
  }, [selectedProjects, activePhases, activeCostcodes, activeCostcodesMap, projectMap]);

  const onApply = useCallback(() => {
    const values = form.getFieldsValue();
    dispatch(updateTimeCardFilters(values));
    onClose();
  }, [onClose]);

  const resetFields = () => {
    form.setFieldsValue(defaultFormValues);
  };

  const onValuesChange = useCallback((_, newValues = {}) => {
    const {
      projects: newSelectedProjects = [],
      costcodes: selectedCostcodes = [],
    } = newValues;

    const newSelectedProjectsSet = new Set(newSelectedProjects);
    let newSelectedCostcodes = [];

    newSelectedCostcodes = selectedCostcodes.filter((ccId) => {
      const splitCostcodeId = ccId.split('.') || [];
      const costcodeId = splitCostcodeId?.[1] ?? null;
      const ourCostcode = activeCostcodesMap?.[costcodeId] ?? {};

      if (newSelectedProjects.length) {
        return newSelectedProjectsSet.has(ourCostcode?.projectId);
      }

      return !ourCostcode.projectId;
    });

    const formUpdate = { ...newValues };
    formUpdate.costcodes = newSelectedCostcodes;

    setSelectedProjects(newSelectedProjects);

    form.setFieldsValue(formUpdate);
  }, [form, activeCostcodesMap]);

  useEffect(() => {
    if (form) {
      form.setFieldsValue({
        ...defaultFormValues,
        users: timeCardFilters?.users ?? [],
        type: timeCardFilters?.type ?? [],
        projects: timeCardFilters?.projects ?? [],
        costcodes: timeCardFilters?.costcodes ?? [],
        status: timeCardFilters?.status ?? [],
        enteredViaUser: timeCardFilters?.enteredViaUser ?? [],
        enteredViaTablet: timeCardFilters?.enteredViaTablet ?? [],
        approvedBy: timeCardFilters?.approvedBy ?? [],
      });
    }
  }, [form, timeCardFilters]);

  return (
    <Drawer
      title="Filters for Time Cards Summary"
      visible={visible}
      onClose={onClose}
      width={700}
      destroyOnClose
    >
      <div>
        <Form
          form={form}
          layout="vertical"
          onValuesChange={onValuesChange}
        >
          <Form.Item
            name="users"
            label="Users"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={userOptions}
              placeholder="Users"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="type"
            label="Type"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={taskTypes}
              placeholder="Type"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="projects"
            label="Projects"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={projectOptions}
              placeholder="Projects"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="costcodes"
            label="Costcodes"
            style={{ marginBottom: 16 }}
          >
            <TreeSelect
              allowClear
              showSearch
              treeDefaultExpandAll
              treeNodeFilterProp="title"
              placeholder="Costcodes"
              multiple
              treeData={costcodeTreeData}
            />
          </Form.Item>
          <Form.Item
            name="status"
            label="Status"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={taskStatuses}
              placeholder="Status"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="enteredViaUser"
            label="Entered Via (User)"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={userOptions}
              placeholder="Users"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="enteredViaTablet"
            label="Entered Via (tablet)"
            style={{ marginBottom: 16 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={tabletOptions}
              placeholder="Tablets"
              optionFilterProp="label"
            />
          </Form.Item>
          <Form.Item
            name="approvedBy"
            label="Approved By"
            style={{ marginBottom: 50 }}
          >
            <Select
              mode="multiple"
              allowClear
              options={approverOptions}
              placeholder="Approver"
              optionFilterProp="label"
            />
          </Form.Item>
        </Form>
      </div>
      <div className="drawer-footer">
        <Row justify="end" gutter={10}>
          <div style={{ marginRight: 'auto' }}>
            <OnTraccrButton
              title="Cancel"
              type="cancel"
              onClick={onClose}
            />
            <OnTraccrButton
              onClick={resetFields}
              title="Reset"
              style={{ marginLeft: 8 }}
            />
          </div>
          <OnTraccrButton
            title="Apply"
            onClick={onApply}
          />
        </Row>
      </div>
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
TimeCardFilterDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  relevantUsers: PropTypes.array,
};

TimeCardFilterDrawer.defaultProps = {
  relevantUsers: [],
};
