import React from 'react';
import { Row } from 'antd';
import colors from '../../constants/Colors';

export default ({
  radius:avatarRadius = 17,
  initials = '',
  style,
  className,
  id,
  fontSize = avatarRadius * 0.85,
  accentColor = colors.ONTRACCR_GRAY
}) => (
  <div style={{
    width:avatarRadius * 2,
    height:avatarRadius * 2,
    backgroundColor:colors.MAIN_BACKGROUND,
    borderRadius:avatarRadius,
    border:'solid',
    borderWidth:0.2,
    borderColor: accentColor,
    overflow:'hidden',
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    ...style
  }} className={className} id={id}>
    <Row 
      type='flex'
      justify='center'
      align='middle'
      style={{
        pointerEvents:'none',
        color: accentColor,
        fontFamily:'roboto-regular',
        fontSize,
        userSelect:'none',
      }}
    >
      {initials.toUpperCase()}
    </Row>
  </div>
);