import CustomConfirmModal from './CustomConfirmModal';

export default ({
  type,
  item,
  onOk,
}) => {
  if (!item || !item.id) return;
  const mode = item.active ? 'Archive' : 'Activate';

  CustomConfirmModal({
    title: `${mode} ${type} '${item.name}'?`,
    okText: mode,
    cancelText: 'Cancel',
    onOk() {
      onOk();
    },
  });
};
