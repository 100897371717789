import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import {
  Drawer,
  Checkbox,
  Row,
  Col,
  Input,
} from 'antd';

import { Trie } from 'ontraccr-common';

import { useSpring, animated } from 'react-spring';

import {
  togglePublic,
  getMaterialAccessList,
  grantAccess,
  revokeAccess,
} from './state/materials.actions';

import HoverHelp from '../common/HoverHelp';
import sortByString from '../helpers/helpers';
import Debouncer from '../helpers/Debouncer';

import MaterialPermsUserList from '../files/FilePermsUserList';

const debouncer = new Debouncer();

const publicHelpText = (
  <div style={{ width: 250 }}>
    Public folders can be viewed by all users in its corresponding division
    <br />
    <br />
    Keep this box unchecked if you want to set which users can see this folder.
  </div>
);

const sortByName = sortByString('name');

function MaterialPermsDrawer({ onClose, selectedMaterial }) {
  const {
    name,
    divisionId,
    id: materialId,
    isPublic: publicState,
  } = selectedMaterial || {};

  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const userTrie = useSelector((state) => state.users.userTrie);
  const company = useSelector((state) => state.settings.company);
  const materialAccessLists = useSelector((state) => state.materials.materialAccessLists);

  const [isPublic, setIsPublic] = useState(publicState);
  const [search, setSearch] = useState();
  const [selected, setSelected] = useState([[], []]);
  const [permsMap, setPermsMap] = useState(new Set());

  const [tableStyle, setTableStyle] = useSpring(() => ({ opacity: 1 }));

  const onChecked = useCallback(async (e) => {
    const { checked } = e.target;
    if (await dispatch(togglePublic({
      id: materialId,
      isPublic: checked,
    }))) {
      setIsPublic(checked);
    }
  }, [materialId]);

  const debounceSearch = useCallback(async (searchTerm) => {
    await debouncer.debounce(() => setSearch(searchTerm), 250);
  }, []);

  const onSearchChange = useCallback((e) => {
    debounceSearch(e.target.value);
  }, []);

  const addUsersToPerms = useCallback(async (userIds = []) => {
    if (await dispatch(grantAccess({ materialId, userIds }))) {
      const newPerms = new Set(permsMap);
      userIds.forEach((userId) => {
        newPerms.add(userId);
      });
      setPermsMap(newPerms);
    }
  }, [permsMap, materialId]);

  const removeUsersFromPerms = useCallback(async (userIds = []) => {
    if (await dispatch(revokeAccess({ materialId, userIds }))) {
      const newPerms = new Set(permsMap);
      userIds.forEach((userId) => {
        newPerms.delete(userId);
      });
      setPermsMap(newPerms);
    }
  }, [permsMap, materialId]);

  const onSelectChange = useCallback(([selectedKeys]) => {
    const added = [];
    const removed = [];
    const currentKeySet = new Set();

    selectedKeys.forEach((userId) => {
      currentKeySet.add(userId);
      if (!permsMap.has(userId)) added.push(userId);
    });

    Array.from(permsMap).forEach((userId) => {
      if (!currentKeySet.has(userId)) removed.push(userId);
    });

    if (added.length) addUsersToPerms(added);
    if (removed.length) removeUsersFromPerms(removed);
  }, [permsMap, addUsersToPerms, removeUsersFromPerms]);

  const relevantData = useMemo(() => {
    const ourUsers = search
      ? Trie.searchTrie(userTrie, search)
      : users.filter((user) => user.active);
    const selectedUsers = [];
    const unselectedUsers = [];
    const owner = [];

    ourUsers.forEach((user) => {
      if (company && company.userId === user.id) {
        owner.push(user);
      } else if (permsMap.has(user.id)) {
        selectedUsers.push(user);
      } else {
        unselectedUsers.push(user);
      }
    });
    return owner.concat(
      [...selectedUsers].sort(sortByName)
        .concat([...unselectedUsers].sort(sortByName)),
    );
  }, [search, users, userTrie, permsMap, company]);

  useEffect(() => {
    const selectedKeys = [];
    const selectedUsers = [];
    users.forEach((user) => {
      if (permsMap.has(user.id) || (company && company.userId === user.id)) {
        selectedKeys.push(user.id);
        selectedUsers.push(user);
      }
    });
    setSelected([selectedKeys, selectedUsers]);
  }, [permsMap, users, company]);

  useEffect(() => {
    setTableStyle({
      opacity: isPublic ? 0 : 1,
      height: isPublic ? 0 : 'auto',
      overflow: 'hidden',
    });
  }, [isPublic]);

  useEffect(() => {
    if (selectedMaterial) {
      setIsPublic(selectedMaterial.isPublic);
    }
  }, [selectedMaterial]);

  useEffect(() => {
    if (materialId) dispatch(getMaterialAccessList(materialId));
  }, [materialId]);

  useEffect(() => {
    const {
      [materialId]: newACL = new Set(),
    } = materialAccessLists;

    setPermsMap(new Set(newACL));
  }, [materialAccessLists, materialId]);

  return (
    <Drawer
      title={`Permissions - ${name}`}
      width={700}
      visible={selectedMaterial}
      maskClosable={false}
      onClose={onClose}
    >
      <div
        style={{
          height: '100%',
          width: '100%',
          display: 'flex',
          justifyContent: 'start',
          flexDirection: 'column',
        }}
      >
        <Row
          justify="start"
          gutter={10}
          style={{
            width: '100%',
            marginBottom: 0,
          }}
        >
          <Col>
            <Checkbox
              style={{ marginBottom: 10 }}
              checked={isPublic}
              onChange={onChecked}
            >
              Public
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp content={publicHelpText} placement="rightTop" />
          </Col>
        </Row>
        <Row style={{ marginBottom: 15 }}>
          Granting access to a folder will give users access to all materials in that folder
        </Row>
        <animated.div style={tableStyle}>
          <Row justify="start" gutter={20}>
            <Col flex="400px">
              <Input.Search
                type="search"
                disabled={false}
                style={{ width: '100%' }}
                className="searchbar"
                placeholder="Search"
                allowClear
                onChange={onSearchChange}
              />
            </Col>
          </Row>
          <MaterialPermsUserList
            divisions={[divisionId]}
            selected={selected}
            data={relevantData}
            onSelect={onSelectChange}
            scroll={{ y: 'calc(100vh - 255px)' }}
          />
        </animated.div>
      </div>
    </Drawer>
  );
}

MaterialPermsDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedMaterial: PropTypes.shape(),
};

MaterialPermsDrawer.defaultProps = {
  selectedMaterial: null,
};

export default MaterialPermsDrawer;
