/* eslint-disable react/jsx-props-no-spreading */
import {
  React,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Col,
  Form,
  List,
  Row,
  Dropdown,
  Menu,
} from 'antd';
import { DateTime } from 'luxon';
import { PlusOutlined, EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import BorderlessButton from '../../common/buttons/BorderlessButton';

import Colors from '../../constants/Colors';
import AddDrawer from '../../common/addDrawer';
import DisplayText from '../../common/text/DisplayText';
import WarningHeader from '../../common/text/WarningHeader';
import ProjectAddView from '../../projects/projectAddView';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import FormTextInput from '../../common/inputs/FormTextInput';
import DivisionSelector from '../../common/inputs/DivisionSelector';
import CustomConfirmModal from '../../common/modals/CustomConfirmModal';
import CompanySettingsCard from '../CompanySettingsCard';
import {
  getScheduleOfValueTemplates,
  addScheduleOfValueTemplate,
  updateScheduleOfValueTemplate,
  deleteScheduleOfValueTemplate,
} from '../../projects/state/projects.actions';
import ScheduleOfValuesPreview from './ScheduleOfValuesPreview';

const deleteModal = (onOk) => CustomConfirmModal({
  title: (
    <WarningHeader
      justify="center"
      title="Delete Confirmation"
    />
  ),
  content: (
    <p>Are you sure you wish to delete this template?</p>
  ),
  onOk,
});

function ScheduleOfValuesTemplates() {
  const { t } = useTranslation();
  const [showDrawer, setShowDrawer] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState();
  const [lastOpen, setLastOpen] = useState(DateTime.local().toMillis());
  const settings = useSelector((state) => state.settings.company.settings);
  const {
    newTemplate,
    scheduleOfValueTemplates: templates,
  } = useSelector((state) => state.projects);

  const tabs = useSelector((state) => state.settings.tabs);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getScheduleOfValueTemplates());
  }, []);

  const templateTabs = useMemo(() => tabs.filter(
    (tab) => (tab.tabName === 'Contract'),
  ), [tabs]);

  const onAddClicked = useCallback(async () => {
    dispatch(addScheduleOfValueTemplate({
      name: 'New Template',
      description: 'New Template',
    }));
    setShowDrawer('add');
  }, []);

  const clearValues = () => {
    setSelectedTemplate();
    setShowDrawer(false);
    setLastOpen(DateTime.local().toMillis());
  };

  const onDrawerClose = useCallback(async (values, submitted) => {
    let success = false;

    if (!submitted) {
      clearValues();
      return success;
    }

    const {
      name,
      description,
      divisionId,
    } = values;

    if (!name || !description || !divisionId) {
      return false;
    }

    if (selectedTemplate?.id) {
      const payload = {
        name,
        description,
        divisionId,
      };
      success = await dispatch(updateScheduleOfValueTemplate(selectedTemplate.id, payload));
    }
    if (success) {
      clearValues();
    }
    return success;
  }, [
    selectedTemplate,
  ]);

  const onDelete = useCallback((id) => (
    deleteModal(() => dispatch(deleteScheduleOfValueTemplate(id)))
  ), []);

  const onEditClicked = useCallback((key, value) => {
    const template = templates.find((t) => t.id === value);

    if (template) {
      setSelectedTemplate(template);
      setShowDrawer(key);
    }
  }, [templates]);

  const renderItem = useCallback((item) => {
    const {
      id,
      name,
      description,
    } = item;

    const buttonStyle = {
      pointerEvents: 'auto',
      width: 30,
      padding: 0,
    };

    return (
      <List.Item style={{ position: 'relative', width: '100%', borderBottom: 'none' }}>
        <Row style={{ width: '100%' }}>
          <Row className="form-field-type-title">
            {name}
          </Row>
          <Row className="form-field-type-description">
            {description}
          </Row>
        </Row>
        <Dropdown
          placement="bottomRight"
          overlay={(
            <Menu onClick={({ key }) => onEditClicked(key, id)}>
              <Menu.Item key="edit">
                &nbsp;Edit Template
              </Menu.Item>
              <Menu.Item key="editSOV">
                &nbsp;Edit Schedule of Values
              </Menu.Item>
            </Menu>
          )}
        >
          <BorderlessButton
            style={{
              position: 'absolute',
              right: 40,
              top: 5,
              ...buttonStyle,
            }}
            iconNode={<EditOutlined style={{ marginLeft: 0 }} />}
          />
        </Dropdown>
        <BorderlessButton
          style={{
            position: 'absolute',
            right: 10,
            top: 5,
            ...buttonStyle,
          }}
          iconNode={<DeleteOutlined style={{ color: Colors.ONTRACCR_RED, marginLeft: 0 }} />}
          onClick={() => onDelete(id)}
        />
      </List.Item>
    );
  }, [onDelete, onEditClicked]);

  const templateDetailsView = useCallback(() => (
    <>
      <Row style={{ width: '100%' }} gutter={20}>
        <Col span={6}>
          <FormTextInput
            isNotDisplay
            name="name"
            label="Name"
            rules={[
              { required: true, message: 'Name is required ' },
            ]}
          />
        </Col>
        <Col span={6}>
          <Form.Item
            name="divisionId"
            label="Division"
            rules={[{ required: true, message: 'Please enter a division' }]}
            style={{ marginTop: 7 }}
            valuePropName="divisionId"
          >
            <DivisionSelector />
          </Form.Item>
        </Col>
      </Row>
      <Row style={{ width: '100%' }}>
        <Col span={12}>
          <FormTextInput
            isNotDisplay
            name="description"
            label="Description"
            textarea
            rules={[
              { required: true, message: 'Description is required ' },
            ]}
          />
        </Col>
      </Row>
    </>
  ), []);

  // Props to be passed into the add view
  const editProps = useMemo(() => {
    const ourTemplate = templates.find((template) => template.id === selectedTemplate?.id);
    if (!ourTemplate) {
      // If adding, pass in nothing
      return {};
    }

    // If we're editing a template, use it's details
    const {
      name,
      description,
      divisionId,
    } = ourTemplate;

    return {
      editing: true,
      name,
      description,
      divisionId,
      key: selectedTemplate?.id,
    };
  }, [selectedTemplate, templates]);

  useEffect(() => {
    if (newTemplate?.id) {
      setSelectedTemplate(newTemplate);
    }
  }, [newTemplate]);

  const formView = useCallback((form, formProps) => (
    <ProjectAddView
      key={lastOpen}
      editing
      isAdd
      templateView
      {...form}
      {...formProps}
      costcodes={[]}
      users={[]}
      onValuesChanged={() => {}}
      settings={settings}
      history={history}
      tabs={templateTabs}
      templateId={selectedTemplate?.id}
      createNuxEntry={() => {}}
      customSteps={[{
        content: templateDetailsView(),
        title: 'Template Details',
        key: 'scheduleOfValueTemplateInfo',
      }]}
      showContractStep={showDrawer === 'add'}
      type={null}
    />
  ), [
    showDrawer,
    settings,
    history,
    templateTabs,
    templateDetailsView,
    lastOpen,
    selectedTemplate,
  ]);

  return (
    <>
      <CompanySettingsCard title="Schedule of Values">
        <Row style={{ margin: 10 }} align="bottom">
          <Col span={18}>
            <DisplayText
              title="Schedule of Value Templates"
              style={{ fontFamily: 'roboto-medium', marginBottom: 0 }}
            />
            <DisplayText
              title={(
                <div style={{ width: '100%' }}>
                  Configure schedule of value templates for
                  {' '}
                  {t('Project', { count: 2 }).toLowerCase()}
                  <br />
                  <br />
                  Templates configured here can be added to new
                  {' '}
                  {t('Project', { count: 2 }).toLowerCase()}
                  .
                </div>
              )}
              style={{
                fontFamily: 'roboto-regular',
                marginBottom: 0,
                color: Colors.ONTRACCR_OPACITY_GRAY,
              }}
            />
          </Col>
          <Col span={6}>
            <OnTraccrButton
              disabled={templateTabs.length === 0}
              title="Add"
              icon={<PlusOutlined />}
              onClick={onAddClicked}
            />
          </Col>
        </Row>
        <List
          style={{ marginLeft: 15 }}
          dataSource={templates}
          renderItem={renderItem}
        />
      </CompanySettingsCard>
      <AddDrawer
        key={lastOpen}
        onClose={onDrawerClose}
        isAdd
        title={selectedTemplate?.name || 'New Template'}
        visible={showDrawer === 'add' || showDrawer === 'edit'}
        formView={formView}
        formProps={editProps}
        onDelete={onDelete}
        width={1400}
        numberOfSteps={showDrawer === 'add' ? 2 : 1}
      />
      <ScheduleOfValuesPreview
        visible={showDrawer === 'editSOV'}
        onDrawerClose={onDrawerClose}
        templateId={selectedTemplate?.id}
        title={selectedTemplate?.name}
        isReadOnly={false}
      />
    </>
  );
}

export default ScheduleOfValuesTemplates;
