import { DateTime } from 'luxon';

import {
  GET_MATERIALS,
  CREATE_MATERIALS,
  ARCHIVE_MATERIALS,
  UPDATE_MATERIAL,
  DELETE_MATERIAL,
  BULK_UPDATE_MATERIALS,
  CREATE_MATERIALS_FOLDER,
  UPDATE_MATERIALS_FOLDER,
  MOVE_MATERIALS,
  COPY_MATERIALS,
  GET_MATERIAL_LOCATIONS,
  MASS_UPLOAD_MATERIALS,
  MASS_ARCHIVE_MATERIALS,
  MASS_DELETE_MATERIALS,
  GET_MATERIAL_UPDATE_HISTORY,
  GET_MATERIAL_ACCESS_LIST,
  GRANT_MATERIAL_ACCESS,
  REVOKE_MATERIAL_ACCESS,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import MaterialsService from './materials.service';

export const createMaterial = (payload) => async (dispatch) => {
  Analytics.track('Materials/Create');
  const { data } = await MaterialsService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_MATERIALS,
    payload: {
      material: data,
    },
  });
  return true;
};

export const uploadMaterials = (payload) => async (dispatch) => {
  Analytics.track('Materials/Upload');
  const { data } = await MaterialsService.massUpload(payload);
  if (!data) return false;
  dispatch({
    type: MASS_UPLOAD_MATERIALS,
    payload: {
      materials: data,
    },
  });
  return true;
};

export const getMaterials = () => async (dispatch, getState) => {
  const {
    materials: {
      lastUpdated = 0,
    } = {},
  } = getState();
  const lastUpdateDT = DateTime.fromMillis(lastUpdated);
  const now = DateTime.local();
  if (now.diff(lastUpdateDT).as('minutes') < 5) return true;
  const { data } = await MaterialsService.get(lastUpdated);
  if (!data) return false;
  dispatch({
    type: GET_MATERIALS,
    payload: data,
  });
  return true;
};

export const archiveMaterial = (id, active = false) => async (dispatch) => {
  Analytics.track('Materials/Archive', { active });
  const didArchive = await MaterialsService.archive(id, active);
  if (!didArchive) return false;
  dispatch({
    type: ARCHIVE_MATERIALS,
    payload: {
      id,
      active,
    },
  });
  return true;
};

export const deleteMaterial = (id) => async (dispatch) => {
  Analytics.track('Materials/Delete');
  const didDelete = await MaterialsService.deleteMaterial(id);
  if (!didDelete) return false;
  dispatch({
    type: DELETE_MATERIAL,
    payload: {
      id,
    },
  });
  return true;
};

export const massDelete = (ids) => async (dispatch) => {
  Analytics.track('Materials/MassDelete');
  const { data: didDelete } = await MaterialsService.massDelete(ids);
  if (!didDelete) return false;
  dispatch({
    type: MASS_DELETE_MATERIALS,
    payload: {
      materialIds: ids,
    },
  });
  return true;
};

export const updateMaterial = (id, payload) => async (dispatch) => {
  Analytics.track('Materials/Update');
  const { data: newData } = await MaterialsService.update(id, payload);
  if (!newData) return false;
  dispatch({
    type: UPDATE_MATERIAL,
    payload: {
      id,
      newData,
    },
  });
  return true;
};

export const bulkUpdateMaterials = (payload) => async (dispatch) => {
  Analytics.track('Materials/BulkUpdate');
  const { data } = await MaterialsService.bulkUpdate(payload);
  if (!data) return false;
  dispatch({
    type: BULK_UPDATE_MATERIALS,
    payload,
  });
  return true;
};

export const createMaterialsFolder = (payload) => async (dispatch) => {
  Analytics.track('Materials/CreateFolder');
  const { data } = await MaterialsService.createFolder(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_MATERIALS_FOLDER,
    payload: {
      material: data,
    },
  });
  return true;
};

export const updateMaterialsFolder = (id, payload) => async (dispatch) => {
  Analytics.track('Materials/UpdateFolder');
  const { data } = await MaterialsService.updateFolder(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_MATERIALS_FOLDER,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const moveMaterials = (payload) => async (dispatch) => {
  Analytics.track('Materials/Move');
  const { data } = await MaterialsService.move(payload);
  if (!data) return false;
  dispatch({
    type: MOVE_MATERIALS,
    payload,
  });
  return true;
};

export const copyMaterials = (payload) => async (dispatch, getState) => {
  Analytics.track('Materials/Copy');
  const {
    materials: {
      lastUpdated = 0,
    } = {},
  } = getState();
  const { data } = await MaterialsService.copy(payload, lastUpdated);
  if (!data) return false;
  dispatch({
    type: COPY_MATERIALS,
    payload: data,
  });
  return true;
}

export const getLocations = (id) => async (dispatch) => {
  const { data: locations } = await MaterialsService.getLocations(id);
  if (!locations) return false;
  dispatch({
    type: GET_MATERIAL_LOCATIONS,
    payload: {
      id,
      locations,
    },
  });
  return true;
};

export const massArchive = (materialIds) => async (dispatch) => {
  Analytics.track('Materials/MassArchive');
  const { data } = await MaterialsService.massArchive({ materialIds });
  if (!data) return false;
  dispatch({
    type: MASS_ARCHIVE_MATERIALS,
    payload: {
      materialIds,
    },
  });
  return true;
};

export const getMaterialUpdateHistory = (id) => async (dispatch) => {
  const { data } = await MaterialsService.getUpdateHistory(id);
  if (!data) return false;
  dispatch({
    type: GET_MATERIAL_UPDATE_HISTORY,
    payload: {
      id,
      history: data,
    },
  });
  return true;
};

export const togglePublic = ({ id, isPublic }) => async (dispatch) => {
  Analytics.track('Materials/Access/TogglePublic');
  const { data } = await MaterialsService.togglePublic(id, isPublic);
  if (!data) return false;
  dispatch({
    type: UPDATE_MATERIALS_FOLDER,
    payload: {
      id,
      newData: { isPublic },
    },
  });
  return true;
};

export const getMaterialAccessList = (id) => async (dispatch) => {
  Analytics.track('Materials/Access/GetList');
  const { data } = await MaterialsService.getAccessList(id);
  if (!data) return false;
  dispatch({
    type: GET_MATERIAL_ACCESS_LIST,
    payload: data,
  })
  return true;
};

export const grantAccess = (payload) => async (dispatch) => {
  Analytics.track('Materials/Access/Grant');
  const { data } = await MaterialsService.grantAccess(payload);
  if (!data) return false;
  dispatch({
    type: GRANT_MATERIAL_ACCESS,
    payload,
  });
  return true;
};

export const revokeAccess = (payload) => async (dispatch) => {
  Analytics.track('Materials/Access/Revoke');
  const { data } = await MaterialsService.revokeAccess(payload);
  if (!data) return false;
  dispatch({
    type: REVOKE_MATERIAL_ACCESS,
    payload,
  });
  return true;
}

