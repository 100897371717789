import React, { useCallback } from 'react';

import PDFDesignerAnchor from './PDFDesignerAnchor';

import { ANCHOR_RADIUS } from './PDFDesigner.constants';


export default ({
  width,
  height,
  onResize,
  type,
}) => {
  const isTable = type === 'table';
  const anchorWidth = width + ANCHOR_RADIUS * 1.5;
  const anchorHeight = height + ANCHOR_RADIUS * 1.5;
  const minDim = -ANCHOR_RADIUS * 0.25;

  const onMouseDown = useCallback((direction) => (e) => {
    e.stopPropagation();
    onResize({ direction, e });
  },[onResize]);

  return (
    <svg className='pdf-designer-svg' width={width} height={height}>
      {!isTable && <PDFDesignerAnchor
        x={minDim}
        y={minDim}
        cursor='nw-resize'
        onMouseDown={onMouseDown('nw')}
      />}
      {!isTable && <PDFDesignerAnchor
        x={anchorWidth / 2}
        y={minDim}
        cursor='n-resize'
        onMouseDown={onMouseDown('n')}
      />}
      {!isTable && <PDFDesignerAnchor
        x={anchorWidth}
        y={minDim}
        cursor='ne-resize'
        onMouseDown={onMouseDown('ne')}
      />}
      <PDFDesignerAnchor
        x={anchorWidth}
        y={anchorHeight / 2}
        cursor='e-resize'
        onMouseDown={onMouseDown('e')}
      />
      {!isTable && <PDFDesignerAnchor
        x={anchorWidth}
        y={anchorHeight}
        cursor='se-resize'
        onMouseDown={onMouseDown('se')}
      />}
      {!isTable && <PDFDesignerAnchor
        x={anchorWidth / 2}
        y={anchorHeight}
        cursor='s-resize'
        onMouseDown={onMouseDown('s')}
      />}
      {!isTable && <PDFDesignerAnchor
        x={minDim}
        y={anchorHeight}
        cursor='sw-resize'
        onMouseDown={onMouseDown('sw')}
      />}
      <PDFDesignerAnchor
        x={minDim}
        y={anchorHeight / 2}
        cursor='w-resize'
        onMouseDown={onMouseDown('w')}
      />
    </svg>
  )
}