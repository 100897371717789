/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';

function EclipseImportHelp({
  type,
}) {
  return (
    <div style={{ width: 500 }}>
      Select from this dropdown to either
      {' '}
      <b>Import</b> a new {type} from Eclipse or
      {' '}
      <b>Link</b> an Eclipse {type} to existing Ontraccr {type}
    </div>
  );
}

EclipseImportHelp.propTypes = {
  type: PropTypes.string.isRequired,
};

export default EclipseImportHelp;
