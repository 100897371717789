import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Checkbox,
  Drawer,
  Form,
  Select,
  Row,
  Col,
  notification,
} from 'antd';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';

import HoverHelp from '../../common/HoverHelp';

import LiveFeedTextInput from '../../dashboard/LiveFeed/LiveFeedTextInput';

import CustomerCommsRow from './CustomerCommsRow';

import {
  broadcastClientComms,
  getClientBroadcasts,
} from './state/customers.actions';
import { getIdMap } from '../../helpers/helpers';

import Permissions from '../../auth/Permissions';

export default ({
  visible,
  onClose,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const history = useHistory();
  const dispatch = useDispatch();

  const customers = useSelector(state => state.customers.customers);
  const portalAccounts = useSelector(state => state.clientPortal.accounts);
  const clientCommBroadcasts = useSelector(state => state.customers.clientCommBroadcasts);
  const users = useSelector(state => state.users.users);

  const uniqueCustomers = useMemo(() => {
    const customerIdSet = new Set(portalAccounts.map((acc) => acc.customerId));
    return Object.values(customers).filter((customer) => (
      customer.active && customerIdSet.has(customer.id)
    )).map((customer) => ({ label: customer.name, value: customer.id }));
  },[portalAccounts, customers]);

  const [isBroadcast, setIsBroadCast] = useState(false);

  const onValuesChange = useCallback((_, newValues) => {
    setIsBroadCast(newValues.isBroadcast);
  },[]);

  const onSend = useCallback(async ({ text: note, }) => {
    const { isBroadcast, customerIds = [] } = form.getFieldsValue();
    const payload = { note };
    if (!isBroadcast && customerIds.length === 0) {
      notification.warn({
        message: `No ${t('Customer', { count: 2 })} Selected`,
        description: `Please select at least one ${t('Customer').toLowerCase()} to send a message`,
      })
      return;
    }
    if (isBroadcast) {
      payload.isBroadcast = isBroadcast;
    } else {
      payload.customerIds = customerIds;
    }
    return dispatch(broadcastClientComms(payload));
  },[form, dispatch]);

  useEffect(() => {
    if(!visible) {
      setIsBroadCast(false);
      form.resetFields();
    }
  },[visible,form]);

  useEffect(() => {
    if (visible) dispatch(getClientBroadcasts());
  },[dispatch, visible]);

  const userMap = useMemo(() => getIdMap(users),[users]);
  const broadcasts = useMemo(() => {
    const casts = [];
    clientCommBroadcasts.forEach((note) => {
      const { timestamp, userId, } = note;
      casts.push({
        ...note,
        user: userMap[userId],
        date: timestamp ? DateTime.fromMillis(timestamp) : null,
        isCurrentUser: userId === Permissions.id,
      })
    });
    casts.sort((a,b) => a.timestamp - b.timestamp);
    return casts;
  },[clientCommBroadcasts, userMap]);

  return (
    <Drawer
      title={`Broadcast Message to ${t('Customer', { count: 2 })}`}
      width={600}
      onClose={onClose}
      visible={visible}
    >
      <Form form={form} layout='vertical' onValuesChange={onValuesChange}>
        <Form.Item
          name='isBroadcast'
          label={
            <Row gutter={20}>
              <Col>
                Broadcast
              </Col>
              <Col>
                <HoverHelp
                  placement='right'
                  content='Broadcast messages will be visible to all client portal accounts'
                />
              </Col>
            </Row>
          }
          valuePropName='checked'
        >
          <Checkbox />
        </Form.Item>

        <Form.Item
          name='customerIds'
          label={t('Customer', { count: 2 })}
        >
          <Select
            options={uniqueCustomers}
            mode='multiple'
            optionFilterProp='label'
            disabled={isBroadcast}
          />
        </Form.Item>
        <Form.Item label='History' labelCol={{ style: { marginBottom: 10, paddingBottom: 0 }}}>
          <div className='customer-broadcast-history'>
            {broadcasts.map((note, idx) => (
              <CustomerCommsRow
                {...note}
                key={note.id}
                hideDivider={idx === broadcasts.length - 1}
              />
            ))}
          </div>
        </Form.Item>
      </Form>

      <LiveFeedTextInput
        onSend={onSend}
        history={history}
        files={[]} // Need this here or LiveFeedTextInput will go into infinite loop
        users={[]} // Need this here or LiveFeedTextInput will go into infinite loop
        userTrie={ {}} // Need this here or LiveFeedTextInput will go into infinite loop
        placeholder='Enter message'
        hideTags
      />

    </Drawer>
  )
}