import sortByString, { sortByNumber } from '../helpers/helpers';

export const CONTACT_ADDRESS_BOOK_SLIDER_VIEW_MODE = 'view';
export const CONTACT_ADDRESS_BOOK_SLIDER_EDIT_MODE = 'edit';
export const CONTACT_ADDRESS_BOOK_SLIDER_ADD_MODE = 'add';
export const CONTACT_ADDRESS_BOOK_SLIDER_SHARE_MODE = 'share';

export const ADDRESS_BOOK_COLUMNS = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
    align: 'center',
    width: 60,
    sorter: sortByString('name'),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Role',
    dataIndex: 'role',
    key: 'role',
    align: 'center',
    width: 60,
    sorter: sortByString('role'),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Phone Number',
    dataIndex: 'phoneNumber',
    key: 'phoneNumber',
    align: 'center',
    width: 100,
    sorter: sortByNumber('phoneNumber'),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  },
  {
    title: 'Email',
    dataIndex: 'email',
    key: 'email',
    align: 'center',
    width: 100,
    sorter: sortByString('email'),
    showSorterTooltip: false,
    sortDirections: ['descend', 'ascend'],
  },
];
