import {
  Col, Input, Select, Table,
} from 'antd';
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Common } from 'ontraccr-common';
import { useSelector } from 'react-redux';
import { DeleteOutlined } from '@ant-design/icons';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';
import Colors from '../constants/Colors';

export default function ScheduleExpectedUsersInput({
  value = [],
  onChange,
  readOnly,
}) {
  const positionNames = useSelector((state) => state.settings.positionNames);
  const userLabels = useSelector((state) => state.labels);

  const idSet = useMemo(() => new Set(value.map(({ id }) => id)), [value]);

  const userLabelOptions = useMemo(() => userLabels
    .filter(({ type }) => type === 'users')
    .map((label) => ({
      value: label.id,
      label: label.title,
    })), [userLabels, idSet]);

  const positionOptions = useMemo(() => positionNames
    .map((position) => ({
      value: position.id,
      label: position.name,
    })), [positionNames, idSet]);

  const addNewGroup = () => {
    let type = null;
    let group = null;
    const filteredUserLabelOptions = userLabelOptions.filter(({ value: val }) => !idSet.has(val));
    const filteredPositionOptions = positionOptions.filter(({ value: val }) => !idSet.has(val));

    if (filteredUserLabelOptions.length) {
      type = 'label';
      group = filteredUserLabelOptions[0].value;
    } else if (filteredPositionOptions.length) {
      type = 'position';
      group = filteredPositionOptions[0].value;
    }

    if (Common.isNullOrUndefined(group)) {
      return;
    }

    onChange([
      ...value,
      {
        id: group,
        count: 0,
        type,
      },
    ]);
  };

  const canAddGroup = userLabelOptions.length || positionOptions.length;

  const onExpectedUsersChange = ({ record, val }) => {
    const idx = value.findIndex(({ id }) => id === record.id);

    if (idx === -1) return;

    const newValue = [...value];

    newValue[idx] = {
      ...record,
      count: val,
    };

    onChange(newValue);
  };

  const onGroupChange = ({ record, val }) => {
    const idx = value.findIndex(({ id }) => id === record.id);

    if (idx === -1) return;

    const newValue = [...value];

    newValue[idx] = {
      ...record,
      id: val,
    };
    onChange(newValue);
  };

  const onTypeChange = ({ record, val }) => {
    const idx = value.findIndex(({ id }) => id === record.id);

    if (idx === -1) return;

    const newValue = [...value];

    const options = val === 'label' ? userLabelOptions : positionOptions;

    const filteredOptions = options.filter(({ value: v }) => !idSet.has(v));

    newValue[idx] = {
      type: val,
      id: filteredOptions[0].value,
      count: 0,
    };

    onChange(newValue);
  };

  const userGroupOptions = [
    { value: 'label', label: 'Label' },
    { value: 'position', label: 'Role' },
  ];

  const defaultGroupOptions = [
    { value: 'default', label: 'Unlabelled' },
  ];

  const columns = useMemo(() => {
    const cols = [
      {
        title: 'Type',
        dataIndex: 'type',
        key: 'type',
        width: 200,
        render: (_, record) => {
          if (readOnly) {
            switch (record.type) {
              case 'label':
                return 'Label';
              case 'position':
                return 'Role';
              default:
                return 'Unlabelled';
            }
          }

          return (
            <Select
              value={record.type}
              options={record.type === 'default' ? defaultGroupOptions : userGroupOptions}
              onChange={(val) => onTypeChange({ record, val })}
              disabled={record.type === 'default'}
            />
          );
        },
      },
      {
        title: 'Group',
        dataIndex: 'id',
        key: 'id',
        width: 200,
        render: (_, record) => {
          const options = record.type === 'label' ? userLabelOptions : positionOptions;
          let label = '';
          const filteredOptions = options.filter(({
            value: val,
            label: lbl,
          }) => {
            if (val === record.id) label = lbl;
            return !idSet.has(val) || val === record.id;
          });

          if (readOnly) return label;

          return (
            <Select
              value={record.id}
              options={filteredOptions}
              onChange={(val) => onGroupChange({ record, val })}
              disabled={record.type === 'default'}
            />
          );
        },
      },
      {
        title: 'Expected Users',
        dataIndex: 'count',
        key: 'count',
        render: (_, record) => {
          if (readOnly) return record.count;
          return (
            <Input
              value={record.count}
              onChange={(e) => onExpectedUsersChange({ record, val: e.target.value })}
            />
          );
        },
      }];

    if (!readOnly) {
      cols.push({
        title: '',
        key: 'delete',
        render: (_, record) => record.type !== 'default' && (
          <BorderlessButton
            title=""
            iconNode={<DeleteOutlined style={{ color: Colors.ONTRACCR_RED }} />}
            onClick={() => {
              onChange(value.filter(({ id }) => record.id !== id));
            }}
          />
        ),
      });
    }

    return cols;
  }, [value, userLabelOptions, positionOptions, idSet, readOnly]);

  return (
    <Col style={{ display: 'flex', gap: 10, flexDirection: 'column' }}>
      {!readOnly ? (
        <OnTraccrButton
          type="primary"
          onClick={addNewGroup}
          title="Add User Group"
          disabled={!canAddGroup}
          style={{ width: 150 }}
        />
      ) : null}

      <Table
        dataSource={value}
        columns={columns}
        pagination={false}
        rowKey="id"
      />
    </Col>
  );
}

ScheduleExpectedUsersInput.propTypes = {
  value: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    count: PropTypes.number,
    type: PropTypes.oneOf(['label', 'position', 'default']),
  })),
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

ScheduleExpectedUsersInput.defaultProps = {
  value: [{ id: null, count: 0, type: 'default' }],
  readOnly: false,
};
