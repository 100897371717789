import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import axios from 'axios';

import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import DisplayText from '../../../common/text/DisplayText';

import Colors from '../../../constants/Colors';

import {
  checkIntegrationConnection,
  deactivateIntegration,
} from '../../state/settings.actions';

export default ({
  visible,
}) => {
  const dispatch = useDispatch();
  const { connectedToMicrosoft365 } = useSelector(state => state.settings.company) || {};

  const [loading,setLoading] = useState(false);

  useEffect(() => {
    if (visible) dispatch(checkIntegrationConnection('microsoft365'));
  },[visible, dispatch]);

  const onClick = useCallback(async () => {
    if (!connectedToMicrosoft365) {
      const { data: uri } = await axios.get('/microsoft365/connect/initiate');
      if(uri) window.location.href = uri;
    } else {
      setLoading(true);
      await dispatch(deactivateIntegration('microsoft365'))
      setLoading(false);
    }
  },[dispatch, connectedToMicrosoft365]);

  const {
    title,
    flavourText,
  } = useMemo(() => ({
    title: connectedToMicrosoft365
      ? 'Deactivate Microsoft 365 Integration'
      : 'Connect Microsoft 365 to Ontraccr',
    flavourText: connectedToMicrosoft365
      ? `Deactivating your Microsoft 365 integration will cause all forms and workflows that are
        configured to send emails through your Microsoft 365 account to send from forms@ontraccr.com instead.`
      : `Connect your Microsoft 365 account to allow Ontraccr to send emails on your behalf.`
  }),[connectedToMicrosoft365]);

  return (
    <div style={{ paddingTop: 30, paddingBottom:30}}>
      <DisplayText
        title={title}
        style={{ fontFamily: 'roboto-medium', marginBottom:0}}/>
      <DisplayText
        title={flavourText}
        style={{
          fontFamily:'roboto-regular',
          color:Colors.ONTRACCR_OPACITY_GRAY,
          marginBottom:0,
          paddingRight: 25,
        }}
      />
      <OnTraccrButton
        style={{ marginTop: 10 }}
        title={connectedToMicrosoft365 ? 'Deactivate' : 'Connect'}
        onClick={onClick}
        loading={loading}
      />
    </div>
  )
}