import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col } from 'antd';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Payroll } from 'ontraccr-common';

import ReportDateSelector from '../reports/MainReportsContainer/ReportDateSelector';

import TimeCardDatePickerButton from './TimeCardDatePickerButton';

import {
  setCalendarType,
  setTimeRange,
} from './state/timecards.actions';

import {
  getFirstPayrollDay,
  findPayrollStartAndEndDates,
  getNextPayroll,
} from '../helpers/payroll';

const headerIconStyle = { fontSize: 20, marginLeft:0, color: 'black' };

export default () => {
  const dispatch = useDispatch();
  const {
    calendarType,
    timeRange,
  } = useSelector(state => state.timecards);
  const {
    settings: {
      payPeriod,
      payPeriodDates,
      semiMonthlyPayPeriodDates,
    } = {},
  } = useSelector(state => state.settings.company ?? {});

  const firstDayOfPayroll = useMemo(() => (
    getFirstPayrollDay({ payPeriod, payPeriodDates, semiMonthlyPayPeriodDates })
  ), [payPeriod, payPeriodDates, semiMonthlyPayPeriodDates]);

  const [startTime, endTime] = timeRange;
  const now = DateTime.local();
  const isCustom = calendarType === 'custom';

  const onTypeChange = useCallback((newType) => {
    dispatch(setCalendarType(newType));
  },[dispatch]);

  const onPrevious = useCallback(() => {
    if (calendarType === 'week') {
      return dispatch(setTimeRange([startTime.minus({ days: 7 }), startTime.minus({ days: 1 })]));
    }
    const startDay = getNextPayroll({
      payPeriod,
      firstDay: startTime,
      forward: false,
      semiMonthlyPayPeriodDates,
    });
    const endDay = startTime.minus({ day: 1 }).endOf('day');
    dispatch(setTimeRange([startDay, endDay]));
  },[calendarType, startTime, payPeriod, semiMonthlyPayPeriodDates]);

  const onNext = useCallback(() => {
    if (calendarType === 'week') {
      return dispatch(setTimeRange([startTime.plus({ days: 7 }), startTime.plus({ days: 14 })]));
    }
    const startDay = getNextPayroll({
      payPeriod,
      firstDay: payPeriod === 'Semi-Monthly' ? endTime : startTime,
      forward: true,
      semiMonthlyPayPeriodDates,
    }).startOf('day');
    const endDay = payPeriod === 'Semi-Monthly'
      ? Payroll.findSemiMonthlyPayPeriod({
        startDay,
        semiMonthlyPayPeriodDates,
      })[1]
     : getNextPayroll({
        payPeriod,
        firstDay: startDay,
        forward: true,
        semiMonthlyPayPeriodDates,
      }).minus({ day: 1 }).endOf('day');
    dispatch(setTimeRange([startDay, endDay]));
  },[calendarType, startTime, endTime, payPeriod, semiMonthlyPayPeriodDates]);

  const onChange = useCallback((newStart) => {
    const dt = DateTime.fromMillis(newStart.valueOf());
    if (calendarType === 'week') {
      if (dt.weekday === 7) {
        // 7 === Sunday.
        return dispatch(setTimeRange([
          dt,
          dt.plus({ days: 7 })
        ]));
      }
      return dispatch(setTimeRange([
        dt.startOf('week').minus({ day: 1 }),
        dt.endOf('week').minus({ day: 1 }),
      ]));
    }
    // Has to be pay period here. Custom range uses onRangeChange
    dispatch(setTimeRange(findPayrollStartAndEndDates({
      startDay: dt,
      payPeriodFirstDay: firstDayOfPayroll,
      payPeriod,
      semiMonthlyPayPeriodDates,
    })));
  },[calendarType, payPeriod, firstDayOfPayroll, semiMonthlyPayPeriodDates]);

  const onRangeChange = useCallback((newRange) => {
    if (newRange.length !== 2 || !newRange.every(moment.isMoment)) return;
    const [start,end] = newRange;
    const startDT = DateTime.fromMillis(start.valueOf()).startOf('day');
    const endDT = DateTime.fromMillis(end.valueOf()).endOf('day');
    dispatch(setTimeRange([startDT, endDT]));
  },[calendarType]);

  const range = useMemo(() => timeRange.map((dt) => moment(dt.toMillis())),[timeRange]);

  return (
    <>
      { !isCustom && (
        <TimeCardDatePickerButton
          icon={<LeftOutlined style={headerIconStyle}/>}
          onClick={onPrevious}
          visible
        />
      )}
      <Col>
        <ReportDateSelector
          onTypeChange={onTypeChange}
          selectedType={calendarType}
          value={range[0]}
          hideCustom={false}
          onChange={onChange}
          range={range}
          onRangeChange={onRangeChange}
          allowClear={false}
        />
      </Col>
      { (!isCustom && endTime < now) && (
        <TimeCardDatePickerButton
          icon={<RightOutlined style={headerIconStyle}/>}
          onClick={onNext}
          visible
        />
      )}
    </>
  );
}