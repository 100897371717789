import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { message } from 'antd';

import { Common } from 'ontraccr-common';
import ScheduleDrawer from '../ScheduleDrawer';
import { getId, refineReminders } from '../scheduleHelpers';
import { createShift, getShifts } from '../state/schedule.actions';
import { parseHexString } from '../../boards/BoardCardUpload/BoardCardUploadHelpers';

export default function GanttScheduleDispatchDrawer({
  visible,
  onClose,
  row = {},
  shiftId,
  isDisplay,
}) {
  const dispatch = useDispatch();

  const shifts = useSelector((state) => state.schedule.shifts) ?? [];
  const shiftMap = useSelector((state) => state.schedule.shiftMap) ?? {};
  const { settings = {} } = useSelector((state) => state.settings.company) ?? {};

  const [lastDownload, setLastDownload] = useState([]);

  const { shiftConnections = [] } = row;

  useEffect(() => {
    if (shiftConnections?.length && !shiftMap[shiftId]) {
      dispatch(getShifts(shiftConnections));
    }
  }, [shiftConnections, shiftId, shiftMap]);

  const shift = useMemo(() => {
    if (shiftId) {
      return shiftMap?.[shiftId] ?? {};
    }
    if (!row || Common.isObjectEmpty(row) || !visible) return {};
    const startTime = DateTime.fromMillis(row.startDate * 1000).startOf('day').toMillis();
    const endTime = DateTime.fromMillis(row.endDate * 1000).endOf('day').toMillis();
    const color = parseHexString(row.color);
    const newShift = {
      title: row.name ?? row.title,
      expectedUsers: row.expectedUsers,
      startTime,
      endTime,
      color,
      users: row.users,
      description: row.notes ?? row.note,
    };

    return newShift;
  }, [row, visible, shiftMap, shiftId]);

  const onShiftSave = useCallback(async (newShift) => {
    const { emailNotification, pushNotification } = settings;
    const {
      reminders = [],
    } = newShift;

    const refinedReminders = refineReminders(reminders);
    window.sessionStorage.setItem('lastReminderConfig', JSON.stringify(refinedReminders));

    if (!row.id) {
      message.error('Could not connect task to shift. Please try again.');
      return;
    }

    const toCreate = {
      id: getId(),
      ...newShift,
      reminders: refinedReminders,
      emailNotification,
      pushNotification,
      ganttScheduleRowId: row.id,
    };
    if (dispatch(createShift(toCreate))) {
      onClose();
    }
  }, [
    settings,
    row,
  ]);

  return (
    <ScheduleDrawer
      isNew
      visible={visible}
      onSave={onShiftSave}
      onClose={onClose}
      style={{ zIndex: 1001 }}
      lastDownload={lastDownload}
      setLastDownload={setLastDownload}
      shift={shift}
      shifts={shifts}
      shiftMap={shiftMap}
      isDisplay={isDisplay}
      hideCalendar={isDisplay}
      isGanttDispatch
    />
  );
}

GanttScheduleDispatchDrawer.propTypes = {
  visible: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  row: PropTypes.shape({
    title: PropTypes.string,
    expectedUsers: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      id: PropTypes.string,
      count: PropTypes.number,
    })),
    startTime: PropTypes.number,
    endTime: PropTypes.number,
    color: PropTypes.string,
    users: PropTypes.arrayOf(PropTypes.string),
    description: PropTypes.string,
  }),
  shiftId: PropTypes.string,
  isDisplay: PropTypes.bool,
};

GanttScheduleDispatchDrawer.defaultProps = {
  visible: false,
  row: {},
  shiftId: null,
  isDisplay: false,
};
