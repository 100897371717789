import React from 'react';
import colors from '../../constants/Colors';
import { Icon as LegacyIcon } from '@ant-design/compatible';
import { Button } from 'antd';
export default ({
  id,
  title,
  icon,
  iconNode,
  onClick,
  color = colors.ONTRACCER_BLACK,
  style = {},
  disabled = false,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  className = '',
}) => (
  <Button 
    id={id}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
    className={`borderlessbtn ${className}`}
    disabled={disabled}
    style={{
      color,
      ...style,
    }}
    onClick={onClick}
  >
    {title} {iconNode ? iconNode : <LegacyIcon type={icon} />}
  </Button>
);