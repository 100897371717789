import React, { useCallback, useMemo } from 'react';
import {useSelector} from 'react-redux';
import { DateTime } from 'luxon';
import { useDrag } from 'react-dnd';
import Permissions from '../../auth/Permissions';

// Import helpers:
import { getTextColor, getBackgroundColor } from '../scheduleHelpers';
import { getIdMap } from '../../helpers/helpers';

export default ({
  user,
  shift,
  day,
  onClick,
}) => {
  const handleClick = useCallback((e) => {
    e.stopPropagation();
    onClick(shift)
  },[shift,onClick]);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MonthMultiDayEntrySimple',
    item: { id: shift?.id, userId: user?.id, shift },
    isDragging: (monitor) => shift?.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: shift?.id === monitor?.getItem()?.id,
    }),
  }));

  const {
    startTime,
    title,
    users = [],
    color,
    isEvent
  } = shift;
  const startDay = DateTime.fromMillis(startTime);
  const isStartDay = startDay.hasSame(day,'day');
  const stateUsers = useSelector(state => state.users.users);
  const userMap = useMemo(() => getIdMap(stateUsers),[stateUsers]);
  const isMyShift = useMemo(() => users && users.includes(Permissions.id),[users]);

  const timeText = startTime && isStartDay && !startDay.equals(startDay.startOf('day'))
  ? `${startDay.toLocaleString(DateTime.TIME_SIMPLE)}` : '';
  const backgroundColor = getBackgroundColor(users, userMap, color, isMyShift, isEvent);
  const textColor = getTextColor(backgroundColor);

  return (
    <div
      className='schedule-month-multiday-entry'
      onClick={handleClick}
      style={{
        width: 'calc(100% - 8px)',
        backgroundColor,
        color: textColor,
        opacity: isDragging ? 0.5 : 1,
      }}
      ref={drag}
    >
      <span>{timeText} {title}</span>
    </div>
  );
}