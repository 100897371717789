import React, { useMemo } from 'react';
import { Row, Col } from 'antd';

import ShiftBlock from '../ShiftBlock';

import Permissions from '../../auth/Permissions';

import {
  WEEKLY_HEADER_BLOCK_HEIGHT,
  WEEKLY_HEADER_BASE_HEIGHT,
} from '../schedule.constants';

import { calculateHeights } from '../scheduleHelpers';

const MultiDayBlock = ({
  shift = {},
  dayDuration,
  firstDay,
  top,
  onClick
}) => {
  const width = 100 * (dayDuration / 7);
  const left = 100 * (firstDay / 7);

  return (
    <ShiftBlock
      key={shift.id}
      shift={shift}
      onClick={(e) => {
        e.stopPropagation();
        onClick();
      }}
      style={{
        cursor: 'pointer',
        width: `${width}%`,
        maxWidth: '100%',
        left: `${left}%`,
        top,
        height: WEEKLY_HEADER_BLOCK_HEIGHT,
      }}
      allDay
    />
  );
};

export default ({
  weekStart,
  onDaySelect,
  onShiftSelect,
  onShiftEdit,
  onHeightChange,
  shifts = [],
}) => {
  const days = useMemo(() => 
    new Array(7).fill(1).map((_,idx) => weekStart.plus({ day: idx })
  ),[weekStart]);

  const {
    multiDayShifts,
    height,
  }= useMemo(() => {
    // Largest blocks on top
    const {
      blocks,
      height,
    } = calculateHeights({
      shifts,
      weekStart,
    });
    onHeightChange(height);
    return {
      height,
      multiDayShifts:blocks.map(({ shift, dayDuration, top, firstDay }) => (
        <MultiDayBlock
          key={shift.id}
          weekStart={weekStart}
          shift={shift}
          dayDuration={dayDuration}
          firstDay={firstDay}
          top={top}
          onClick={() => {
            onShiftEdit(shift);
            onShiftSelect(shift, Permissions.has('SCHEDULE_WRITE'));
          }}
        />
      )),
    }
  },[shifts, weekStart, onShiftEdit, onShiftSelect, onHeightChange]);

  return (
    <Row className='schedule-week-header-container' style={{ height }}>
      {
        days.map((day, idx) => (
          <Col className='schedule-week-day-column' key={idx}>
            <div
              className='schedule-week-day-header'
              onClick={() => onDaySelect(day)}
              style={{ height }}
            >
              {day.toLocaleString({ weekday: 'short', day: 'numeric' })}
              {idx < 6 && <div className='schedule-week-day-header-right-border'/>}
              {idx === 0 && <div className='schedule-week-day-header-left-border'/>}
            </div>
          </Col>
        ))
      }
      <div className='all-day-week-header' style={{ height: height - WEEKLY_HEADER_BASE_HEIGHT }}>
        {multiDayShifts}
      </div>
    </Row>
  );
}
