import React, {
  useState, useCallback, useRef, useMemo,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Row } from 'antd';
import { DateTime } from 'luxon';

import CustomModal from '../../common/modals/CustomModal';
import colors from '../../constants/Colors';
import ScheduleDayWithHeader from '../ScheduleDayWithHeader';

import { splitMultiDayShifts, getDayKey } from '../scheduleHelpers';

const HEADER_HEIGHT = 25;
export default function UserWeeklyDayModal({
  onCloseClicked,
  onShiftSelect,
  onShiftEdit,
  editShift,
  onShiftCreate,
  newShift,
  onNewShiftChange,
}) {
  const scrollRef = useRef(null);

  const [scrollTop, setScrollTop] = useState(-HEADER_HEIGHT);
  const [boundingRectY, setBoundingRectY] = useState(0);

  const {
    userId,
    date,
    visible,
  } = useSelector((state) => state.schedule.biweeklyModalConfig);
  const shifts = useSelector((state) => state.schedule.shifts);
  const users = useSelector((state) => state.users.users);

  const selectedUser = useMemo(() => users.find((user) => user.id === userId), [users, userId]);
  const {
    multiDay: multiDayShifts,
    interDay: interDayShifts,
  } = useMemo(() => {
    if (!visible) return {};
    const dateKey = getDayKey(date);
    const allShiftsForDay = shifts[dateKey] ?? [];
    const userShifts = userId
      ? allShiftsForDay.filter((shift) => new Set(shift.users).has(userId))
      : allShiftsForDay.filter((shift) => shift.users.length === 0);
    return splitMultiDayShifts(userShifts, date);
  }, [shifts, date, userId, visible]);

  const onScroll = useCallback((e) => {
    const {
      target: {
        scrollTop: targetTop,
      } = {},
    } = e;
    setScrollTop(targetTop - HEADER_HEIGHT);
  }, []);

  const handleScrollRef = useCallback((node) => {
    if (node !== null) {
      setBoundingRectY(node.getBoundingClientRect().y - HEADER_HEIGHT);
      scrollRef.current = node;
    }
  }, []);

  return (
    <CustomModal
      title="Select a Time Block"
      visible={visible}
      onCloseClicked={onCloseClicked}
      maskColor={colors.MODAL_MASK_DARK}
      destoryOnClose
      width={600}
      zIndex={15}
    >
      <Row
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop: -20,
        }}
      >
        {`${selectedUser?.name || 'Unassigned'} - ${date?.toLocaleString(DateTime.DATE_FULL)}`}
      </Row>
      <Row>
        <div
          style={{
            position: 'relative',
            width: '100%',
            height: '70vh',
            overflowY: 'scroll',
            overflowX: 'hidden',
          }}
          ref={handleScrollRef}
          onScroll={onScroll}
        >
          <ScheduleDayWithHeader
            key="daily"
            userId={selectedUser?.id}
            day={date}
            userShifts={interDayShifts}
            userAllDay={multiDayShifts}
            onShiftCreate={onShiftCreate}
            onShiftEdit={onShiftEdit}
            newShift={newShift}
            onNewShiftChange={onNewShiftChange}
            onShiftSelect={onShiftSelect}
            editShift={editShift}
            scrollTop={scrollTop}
            containerY={boundingRectY}
          />
        </div>
      </Row>
    </CustomModal>
  );
}

/* eslint-disable react/forbid-prop-types */
UserWeeklyDayModal.propTypes = {
  onCloseClicked: PropTypes.func.isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  onShiftEdit: PropTypes.func.isRequired,
  editShift: PropTypes.object,
  onShiftCreate: PropTypes.func.isRequired,
  newShift: PropTypes.object,
  onNewShiftChange: PropTypes.func.isRequired,
};

UserWeeklyDayModal.defaultProps = {
  editShift: undefined,
  newShift: undefined,
};
