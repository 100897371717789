import React from 'react';
import { 
  notification,
} from 'antd';
import { UndoOutlined } from '@ant-design/icons';

export default class DelayedEdit {
  constructor({
    delay = 1750,
    onExecute,
    onCancel,
    nameMap,
  }) {
    this.delay = delay;
    this.onExecute = onExecute;
    this.onCancel = onCancel;
    this.nameMap = nameMap;

    this.edits = {};
    this.oldValues = {};
    this.timers = {};
  }

  getName(type) {
    return this.nameMap ? this.nameMap[type] : type;
  }

  openNotification(type,value) {
    notification.open({
      key: type,
      message: 'Undo Edit',
      description:
        `Click here to undo ${this.getName(type)} edit`,
      onClick: () => this.onUndo(type,value),
      duration:1.5,
      icon:<UndoOutlined style={{color:'red'}}/>
    });
  }

  trigger(type,value) {
    if (value === null || value === undefined) return;
    this.openNotification(type,value);

    this.oldValues[type] = this.edits[type];
    this.edits[type] = value;
    this.timers[type] = setTimeout(async () => this.onExecute(type,value),this.delay);
  }

  onUndo(type,value) {
    if(!(type in this.timers)) return;
    clearTimeout(this.timers[type]);
    notification.close(type);
    this.onCancel(type,value);
  }
}
