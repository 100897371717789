import React from 'react';
import { Form, Row, Col } from 'antd';
import { useTranslation } from 'react-i18next';

import getGoogleMapKey from '../../common/keys';
import DisplayText from '../../common/text/DisplayText';
import OnTraccrMap from '../../common/map/OnTraccrMap';

export default function FormDetailGeneralInfo({
  userName,
  employeeId,
  number,
  collected = {},
}) {
  const { t } = useTranslation();
  const {
    projectName,
    projectNumber,
    geolocation: {
      lat,
      lng,
    } = {},
  } = collected;

  const hasNumber = number || number === 0;

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <Row gutter={20} align='bottom'>
        {hasNumber && <Col>
          <Form.Item
              name='number'
              label='Number'
              style={{marginBottom: 0}}
            >
            <DisplayText title={number} style={{ marginBottom: 0 }}/>
          </Form.Item>
        </Col>}
        <Col>
          <Form.Item
              name='name'
              label='Employee Name'
              style={{marginBottom: 0}}
            >
            <DisplayText title={userName} style={{ marginBottom: 0 }}/>
          </Form.Item>
        </Col>
        <Col>
          <Form.Item
            name='employeeId'
            label='Employee ID'
            style={{marginBottom: 0}}
          >
            <DisplayText title={employeeId} style={{ marginBottom: 0 }}/>
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={20} align='bottom'>
        {projectName && <Col span={12}>
          <Form.Item
              name='projectName'
              label={`${t('Project')} Name`}
              style={{marginBottom: 0}}
            >
            <DisplayText title={projectName} style={{ marginBottom: 0 }}/>
          </Form.Item>
        </Col>}
        {projectNumber && <Col span={12}>
          <Form.Item
            name='projectNumber'
            label={`${t('Project')} Number`}
            style={{marginBottom: 0}}
          >
            <DisplayText title={projectNumber} style={{ marginBottom: 0 }}/>
          </Form.Item>
        </Col>}
      </Row>
        <Row style={{width: '100%', height: '100%', marginTop: 20 }}>
          {lat && lng && <OnTraccrMap
              showSearch={false}
              lat={lat}
              lng={lng}
              address='Form Submitted Here'
              isNotDisplay={false}
              googleMapURL={getGoogleMapKey()}
              loadingElement={<div style={{ height: `100%` }} />}
              containerElement={
                <div className='form-map-container'
                  style={{
                    left: 24,
                  }}
                />
              }
              mapElement={<div style={{ height: `100%` }} />}
            />}
        </Row>
    </div>
  );
}