/* eslint-disable react/jsx-one-expression-per-line */
import React, {
  useEffect,
  useMemo,
  useCallback,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Table } from 'antd';
import PropTypes from 'prop-types';

import SageImportDrawer from './SageImportDrawer';
import useToggle from '../../../common/hooks/useToggle';

import sageImage from '../../../assets/images/sage_green.jpeg';

import {
  importIntegrationData, saveCompanySettings,
} from '../../state/settings.actions';
import { getUnions } from '../../../unions/state/unions.actions';
import { getSageIntegrations } from '../../../sage/state/sage.actions';
import SageSettings from './SageSettings';
import CompanySettingsCard from '../../CompanySettingsCard';
import CheckRows from '../../CheckRows';
import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import ErrorRecipientSelector from '../ErrorRecipientSelector';

const buttonDimensions = {
  height: 33,
  width: 77,
  marginBottom: 10,
};
export default function Sage({
  connectedToSage,
  version = 'sage300',
  visible = false,
}) {
  const dispatch = useDispatch();

  const {
    settings: {
      enableSageShiftSelection,
    } = {},
  } = useSelector((state) => state.settings.company || {});
  const integrations = useSelector((state) => state.sage.integrations);

  const {
    toggle: toggleImport,
    isToggled: isImportToggled,
  } = useToggle();

  const [selectedIntegration, setSelectedIntegration] = useState(null);

  const {
    product,
    integrationKey,
  } = useMemo(() => {
    if (version === 'sage300') {
      return {
        product: 'Sage 300 CRE',
        integrationKey: 'sageId',
      };
    }
    if (version === 'sage100') {
      return {
        product: 'Sage 100',
        integrationKey: 'sage100',
      };
    }
    return {};
  }, [version]);

  useEffect(() => {
    const loadIntegrations = async () => {
      const sageIntegrations = await dispatch(getSageIntegrations(version));
      if (sageIntegrations.length === 1) {
        setSelectedIntegration(sageIntegrations[0]);
      }
    };

    if (connectedToSage && version && visible) {
      loadIntegrations();
    }
  }, [connectedToSage, version, visible]);

  useEffect(() => {
    if (connectedToSage) {
      dispatch(getUnions());
    }
  }, [connectedToSage]);

  const onSettingToggle = useCallback(() => {
    dispatch(saveCompanySettings({ enableSageShiftSelection: !enableSageShiftSelection }));
  }, [enableSageShiftSelection]);

  const columns = useMemo(() => [{
    title: 'Integrations',
    dataIndex: 'name',
    onHeaderCell: () => ({
      style: {
        backgroundColor: 'white',
      },
    }),
    render: (name) => name,
  }], []);

  const onRowClick = useCallback((record) => {
    setSelectedIntegration(record);
  }, []);

  if (!visible) return null;

  if (!connectedToSage) {
    return (
      <CompanySettingsCard title={product}>
        <div style={{ paddingTop: 30, paddingBottom: 30 }}>
          <div style={buttonDimensions}>
            <img
              alt="Connect to Sage"
              src={sageImage}
              style={buttonDimensions}
            />
          </div>
          <div>
            To connect your {product} instance, please contact <b>support@ontraccr.com</b>
          </div>
        </div>
      </CompanySettingsCard>
    );
  }

  return (
    <>
      <CompanySettingsCard title={product}>
        <SageImportDrawer
          version={version}
          visible={isImportToggled}
          onClose={toggleImport}
          selectedIntegration={selectedIntegration}
          onSave={async (res) => {
            const payload = {
              ...res,
              integrationKey,
              integrationId: selectedIntegration?.id,
            };
            if (await dispatch(importIntegrationData(payload))) {
              toggleImport();
            }
          }}
        />
        { integrations.length === 1 && (
          <SageSettings
            visible
            showImport={toggleImport}
            version={version}
            product={product}
            selectedIntegration={selectedIntegration}
          />
        )}
        { integrations.length > 1 && (
          <Table
            columns={columns}
            dataSource={integrations}
            pagination={false}
            size="small"
            scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
            onRow={(record) => ({
              onClick: () => onRowClick(record),
            })}
            rowClassName="quickbooks-integration-row"
          />
        )}
      </CompanySettingsCard>
      <CompanySettingsCard title="Settings">
        { (version === 'sage300') && (
          <CheckRows
            data={[{
              key: 'enableSageShiftSelection',
              title: 'Enable Sage Shift Selection',
              helpText: 'Allow users to select which shift they will clock in to',
            }]}
            onChange={onSettingToggle}
            isChecked={() => !!enableSageShiftSelection}
          />
        )}
        { (version === 'sage100') && (
          <ErrorRecipientSelector
            recipientsKey="sageErrorRecipients"
            integration="Sage"
          />
        )}
      </CompanySettingsCard>
      <Drawer
        title="Sage Settings"
        visible={!!selectedIntegration && integrations.length > 1}
        onClose={() => setSelectedIntegration(null)}
        width={600}
      >
        <SageSettings
          visible={visible}
          showImport={toggleImport}
          version={version}
          product={product}
          selectedIntegration={selectedIntegration}
          isDrawer
        />
        <DrawerSubmitFooter onClose={() => setSelectedIntegration(null)} />
      </Drawer>
    </>
  );
}

Sage.propTypes = {
  connectedToSage: PropTypes.bool,
  version: PropTypes.string,
  visible: PropTypes.bool,
};

Sage.defaultProps = {
  connectedToSage: false,
  version: 'sage300',
  visible: false,
};
