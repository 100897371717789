import React, { useState, useCallback, useMemo, useRef, useEffect } from 'react';
import FileUpload from '../../files/FileUpload';

import PDFDesignerAnchorSet from './PDFDesignerAnchorSet';
import PDFDesignerTable from './PDFDesignerTable';

import { defaultDrawOptions, ANCHOR_RADIUS } from './PDFDesigner.constants';
import { rgba, imageValidator } from './PDFDesignerHelpers';

export default ({
  text,
  image,
  rows,
  type,
  editing,
  drawOptions = defaultDrawOptions,
  onMoveStart,
  onEditStop,
  onResize,
  onTextChanged,
  onImageAdd,
  onTableResize,
  isDisplay,
}) => {
  const {
    x,
    y,
    width,
    height,
    rowHeight,
    color,
    backgroundColor,
    fontFamily,
    fontSize,
    textAlign,
    verticalAlign,
    borderColor,
    borderStyle,
    borderWidth,
    headerOptions = {},
  } = drawOptions;

  const {
    backgroundColor: headerBackgroundColor,
    color: headerFontColor,
  } = headerOptions;

  const ref = useRef();
  const inputRef = useRef();
  const [dims,setDims] = useState({ width: 0, height: 0 });
  const [hovering,setHovering] = useState(false);
  const [mouseDown,setMouseDown] = useState();

  const onClick = useCallback(isDisplay ? null : (e) => {
    const { clientX, clientY, } = e;
    const { x: parentX, y: parentY } = ref.current.getBoundingClientRect();

    const offsetX = clientX - parentX;
    const offsetY = clientY - parentY;

    onMoveStart({ x: offsetX, y: offsetY });
    setMouseDown(true);
  },[onMoveStart, isDisplay]);

  const onMouseEnter = useCallback(isDisplay ? null : () => {
    setHovering(true);
  },[isDisplay]);
  const onMouseLeave = useCallback(isDisplay ? null : () => {
    if(!mouseDown) {
      setHovering(false);
    }
  },[mouseDown,isDisplay]);
  const onMouseUp = useCallback(isDisplay ? null : () => {
    setMouseDown(false);
    setHovering(false);
  },[isDisplay]);
  const handleResize = useCallback(isDisplay ? null : (resizeOpts) => {
    setMouseDown(true);
    onResize(resizeOpts);
  },[onResize,isDisplay]);

  const handleTextChange = useCallback(isDisplay ? null : (e) => {
    const {
      target: {
        value,
      } = {}
    } = e;
    onTextChanged(value);
  },[onTextChanged,isDisplay]);

  const handleTableResize = useCallback(isDisplay ? null : onTableResize,[onTableResize]);

  useEffect(() => {
    if(ref && ref.current) {
      const { width: boundWidth, height: boundHeight } = ref.current.getBoundingClientRect();
      let ourWidth = boundWidth > 0 ? boundWidth : width;
      let ourHeight = boundHeight > 0 ? boundHeight : height;
      setDims({
        width: ourWidth - ANCHOR_RADIUS * 2,
        height: ourHeight - ANCHOR_RADIUS * 2
      });
    }
  },[ref, fontFamily, fontSize, width, height, isDisplay]);

  useEffect(() => {
    if(editing && inputRef && inputRef.current) {
      setTimeout(inputRef.current.focus(), 500);
    }
  },[editing, inputRef]);

  const className = useMemo(() => {
    if(isDisplay) return 'pdf-designer-text-display';
    return editing || hovering
      ? 'pdf-designer-text-edit' : 'pdf-designer-text';
  },[editing, hovering, isDisplay]);

  const itemStyle = {
    left: x,
    top: y,
    width,
    height: type === 'table' ? rowHeight * 2 : height,
    backgroundColor: rgba(backgroundColor),
  };

  if(type !== 'table') itemStyle.justifyContent = verticalAlign;

  const tableStyle = {
    fontFamily,
    fontSize,
    textAlign,
    color: rgba(color),
    justifyContent: verticalAlign,
    width,
  };

  const tableHeaderStyle = {
    ...headerOptions
  };
  if(headerFontColor) tableHeaderStyle.color = rgba(headerFontColor);
  if(headerBackgroundColor) tableHeaderStyle.backgroundColor = rgba(headerBackgroundColor);

  if(borderStyle !== 'none') {
    if(type === 'table') {
      tableStyle.borderColor = rgba(borderColor);
      tableStyle.borderStyle = borderStyle;
      tableStyle.borderWidth = borderWidth;
    } else {
      itemStyle.borderColor = rgba(borderColor);
      itemStyle.borderStyle = borderStyle;
      itemStyle.borderWidth = borderWidth;
    }
  }

  return (
    <>
    {editing && <div
      className='pdf-designer-edit-stop'
      onClick={onEditStop}
    />}
    <div
      ref={ref}
      className={className}
      onMouseDown={onClick}
      onMouseUp={onMouseUp}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      style={itemStyle}
    >
      {(editing || hovering) &&
        <PDFDesignerAnchorSet
          {...dims}
          onResize={handleResize}
          type={type}
        />
      }
      {type === 'text' && <textarea
        ref={inputRef}
        className='pdf-designer-text-container'
        value={text}
        onChange={handleTextChange}
        style={{
          pointerEvents: editing ? 'auto' : 'none',
          zIndex: editing ? 2 : undefined,
          fontFamily,
          fontSize,
          textAlign,
          color: rgba(color),
          lineHeight: `${fontSize + 2}px`,
          paddingTop: 5,
        }}
        wrap='hard'
      />}
      {
        type === 'image' && !image &&
        <FileUpload
          style={{ top: 0, }}
          addFile={onImageAdd}
          customValidator={imageValidator}
        />
      }
      {
        type === 'image' && image &&
        <img
          className='pdf-designer-image'
          src={image instanceof File ? URL.createObjectURL(image) : image}
          draggable={false}
          alt='Ontraccr PDF Designer'
        />
      }
      {
        type === 'table' &&
        <PDFDesignerTable
          rows={rows}
          style={tableStyle}
          rowHeight={rowHeight}
          headerOptions={tableHeaderStyle}
          onResize={handleTableResize}
        />
      }
    </div>
    </>
  )
}