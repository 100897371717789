import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Form, Checkbox } from 'antd';

import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import DisplayText from '../common/text/DisplayText';
import FormTextInput from '../common/inputs/FormTextInput';

const FOOTER_HEIGHT = 53;

const formStyle = { marginBottom: FOOTER_HEIGHT, paddingBottom: 0 };

const formLabelStyle = {
  style: {
    paddingBottom: 5,
    marginTop: 20,
  },
};

const hasBilling = (billingAddress) => billingAddress !== undefined && billingAddress !== null;
export default ({
  isNotDisplay,
  officeAddress,
  billingAddress,
}) => {
  const [billingDisabled,setBillingDisabled] = useState(!hasBilling(billingAddress));
  
  const onCheckChanged = useCallback((e) => {
    const {
      target: {
        checked,
      } = {},
    } = e;
    setBillingDisabled(checked);
  },[]);

  useEffect(() => {
    setBillingDisabled(!hasBilling(billingAddress));
  },[billingAddress]);

  const billingInput = useMemo(() => {
    if(isNotDisplay && billingDisabled) return null;
    if(isNotDisplay) return <OnTraccrTextInput/>;
    return <DisplayText title={billingAddress || officeAddress}/>
  },[isNotDisplay,billingDisabled]);

  return (
    <>
      <FormTextInput
        isNotDisplay={isNotDisplay}
        value={officeAddress}
        name='officeAddress'
        label='Office Address'
      />
      <div className='customer-billing-address-container'>
        {isNotDisplay && <Checkbox
            checked={billingDisabled}
            className='customer-billing-address-checkbox'
            onChange={onCheckChanged}
          >
          Use office address
        </Checkbox>}
        <Form.Item 
          name='billingAddress'
          label='Billing Address'
          style={formStyle}
          labelCol={formLabelStyle}
        >
          {billingInput}
        </Form.Item>
      </div>
    </>
  );
}