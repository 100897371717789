import React from 'react';
import PropTypes from 'prop-types';
import { Col, Popover, Row } from 'antd';
import { TeamOutlined } from '@ant-design/icons';
import { toTitleCase } from '../../helpers/helpers';

const DEFAULT_POPOVER_STYLE = {
  maxWidth: 300,
  maxHeight: 300,
  overflow: 'auto',
  padding: 10,
};

const MultiValuePopoverColumn = ({
  valueMap,
  record,
  type,
  icon,
  popoverStyle,
}) => {
  const { [type]: valueArr = [], id } = record || {};
  if (!valueArr.length) return null;
  if (valueArr.length === 1) {
    const singleId = valueArr[0];
    const {
      [singleId]: {
        name: singleValueName,
      } = {},
    } = valueMap || {};
    return <span>{singleValueName}</span>;
  }
  return (
    <Popover
      content={(
        <div
          key={id}
          style={popoverStyle}
        >
          {
            valueArr.map((valueId) => {
              const {
                [valueId]: {
                  name: valueName = '',
                } = {},
              } = valueMap || {};
              return <div key={valueId}>{valueName}</div>;
            })
          }
        </div>
      )}
    >
      <Row justify="center">
        <Col>
          {icon}
        </Col>
        <Col>
          {`${valueArr.length} ${toTitleCase(type)}`}
        </Col>
      </Row>
    </Popover>
  );
};

MultiValuePopoverColumn.propTypes = {
  valueMap: PropTypes.object,
  record: PropTypes.object,
  type: PropTypes.string,
  icon: PropTypes.element,
  popoverStyle: PropTypes.object,
};

MultiValuePopoverColumn.defaultProps = {
  valueMap: {},
  record: {},
  type: '',
  icon: <TeamOutlined />,
  popoverStyle: DEFAULT_POPOVER_STYLE,
};

export default MultiValuePopoverColumn;
