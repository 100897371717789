import { Drawer, List } from 'antd';
import React, { useState, useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { condenseTemplatePhasesAndCostcodes } from '../helpers/costcodeHelpers';

export default function ProjectAddCostCodeTemplate({
  divisionId,
  hasCostcodes,
  applyTemplate,
}) {
  const { t } = useTranslation();
  const templates = useSelector((state) => state.costcodes.templates);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const [showDrawer, setShowDrawer] = useState(false);

  const ourTemplates = useMemo(() => (templates
    ? templates.filter((t) => t.divisionId === divisionId)
    : []
  ), [divisionId, templates]);

  const globalCodes = useMemo(() => (
    new Set(
      costcodes
        .filter((cc) => !cc.projectId)
        .map((cc) => cc.id),
    )
  ), [costcodes]);

  const onAddTemplate = useCallback(() => {
    setShowDrawer(true);
  }, []);

  const onClose = useCallback(() => {
    setShowDrawer(false);
  });

  const onSelectTemplate = useCallback((template) => () => {
    // Format data and send to ProjectAddView
    const {
      data: {
        phases: selectedPhases = [],
        costcodes: selectedCostcodes = [],
        phaseCostCodes: selectedPhaseCostcodes = [],
      },
    } = template;
    const condensedPhases = condenseTemplatePhasesAndCostcodes(
      selectedPhases,
      selectedPhaseCostcodes,
      selectedCostcodes,
    );

    const formattedCostcodes = selectedCostcodes.map((costcode) => ({
      ...costcode,
      isNew: !globalCodes.has(costcode.id),
    }));

    applyTemplate({
      costcodes: formattedCostcodes,
      phases: condensedPhases,
    });
    setShowDrawer(false);
  }, [applyTemplate, globalCodes]);

  const renderTemplate = useCallback((item) => (
    <List.Item
      key={item.id}
      extra={(
        <div style={{ height: '100%' }}>
          <OnTraccrButton
            title="Apply"
            onClick={onSelectTemplate(item)}
          />
        </div>
      )}
    >
      <List.Item.Meta
        title={item.name}
      />
      <div>{item.description}</div>
    </List.Item>
  ), [onSelectTemplate]);

  return (
    <>
      <OnTraccrButton
        title="Apply Costing Template"
        onClick={onAddTemplate}
        disabled={hasCostcodes}
      />
      <Drawer
        title={`Apply Costcode Template to ${t('Project')}`}
        visible={showDrawer}
        onClose={onClose}
        width={400}
      >
        <List
          dataSource={ourTemplates}
          itemLayout="vertical"
          locale={{ emptyText: 'No templates found for this division' }}
          renderItem={renderTemplate}
        />
      </Drawer>
    </>
  );
};
