import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form } from 'antd';

import DrawerSubmitFooter from '../common/containers/DrawerSubmitFooter';
import FormTextInput from '../common/inputs/FormTextInput';

import { request } from '../helpers/requests';
import getWindowDimensions from '../helpers/getWindowDimensions';

import { getTextColor } from '../schedule/scheduleHelpers';

import clientInstance from './clientPortal.axios';

import {
  compareToFirstPassword,
  validatePasswordFormat,
} from '../helpers/validators';

export default ({
  visible,
  onClose,
}) => {
  const {
    accentColor,
  } = useSelector(state => state.clientPortal.settings);
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const { width: screenWidth } = getWindowDimensions();

  const confirmValidator = useMemo(() => compareToFirstPassword(form),[form]);
  const passValidator = useMemo(() => validatePasswordFormat(form),[form]);

  const textColor = useMemo(() => getTextColor(accentColor),[accentColor]);

  const onSubmitClicked = useCallback(async () => {
    try {
      const {
        oldPassword,
        password: newPassword
      } = await form.validateFields();
      const { data: passed } = await request({
        call: clientInstance.post('/clientPortal/accounts/password/reset', {
          newPassword,
          oldPassword,
        }),
        successMsg: 'Password changed',
        errMsg: 'Password change failed',
      });
      if (passed) onClose();
    } catch (err) {
      //
    }
  },[dispatch, form]);

  return (
    <Drawer
      title='Change Password'
      onClose={onClose}
      visible={visible}
      width={Math.min(500, screenWidth - 50)}
      bodyStyle={{ padding: '0px 24px '}}
    >
      <Form form={form} layout='vertical'>
        <FormTextInput
          label='Old Password'
          name='oldPassword'
          rules={[
            { required: true, message: 'Please enter your old passsword' },
          ]}
          isNotDisplay
          password
        />

        <FormTextInput
          label='New Password'
          name='password'
          rules={[
            { validator: passValidator },
            { required: true, message: 'Please enter your new passsword' },
          ]}
          isNotDisplay
          password
        />

        <FormTextInput
          label='Confirm Password'
          name='confirmPassword'
          rules={[
            { validator: confirmValidator },
            { required: true, message: 'Please confirm your new passsword' },
          ]}
          isNotDisplay
          password
        />
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmitClicked}
        submitButtonStyle={{
          backgroundColor: accentColor,
          borderColor: accentColor,
          color: textColor,
        }}
      />
    </Drawer>
  )
}