import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useSelector } from 'react-redux';
import { Row } from 'antd';

import WorkflowTimeTriggerModal from './WorkflowTimeTriggerModal';
import WorkflowHandle from './WorkflowHandle';
import WorkflowTriggerCheckbox from './WorkflowTriggerCheckbox';

import { DEFAULT_TRIGGER_DAYS } from './formTrigger.constants';

import {
  FORMS_WORKFLOW_STEP_2,
  FORMS_WORKFLOW_STEP_2_TEXT,
  FORMS_WORKFLOW_STEP_3,
  FORMS_WORKFLOW_STEP_4,
  FORMS_WORKFLOW_STEP_SOURCE_TEXT,
} from '../../nux/nux.constants';

import NuxPopover from '../../nux/NuxPopover';

import BorderlessButton from '../../common/buttons/BorderlessButton';

const timeTriggerTypeMap = {
  weekdays: 'Every weekday (Monday to Friday)',
  weekly: 'Monday of every week',
  monthly: 'First Monday of every month',
};

export default ({
  setTriggers,
  setTriggerDays,
  isDisplay,
  workflow: {
    triggers: initialTriggers,
    triggerDays,
  } = {},
  isExternalForm,
}) => function WorkflowTriggerNode() {
  const defaultTriggers = isExternalForm ? ['external'] : ['manual'];

  const activeNuxAction = useSelector((state) => state.nux.activeNuxAction);

  const [timeSelected, setTimeSelected] = useState(false);
  const [shouldShowModal, setShowModal] = useState(false);
  const [timeInterval, setTimeInterval] = useState();
  const [localTriggers, setLocalTriggers] = useState(initialTriggers || defaultTriggers);
  const [localTriggerDays, setLocalTriggerDays] = useState(triggerDays || DEFAULT_TRIGGER_DAYS);

  const showModal = useCallback(() => setShowModal(true), []);
  const hideModal = useCallback(() => setShowModal(false), []);
  const saveTimeInterval = useCallback((newTimeInterval) => {
    setTimeInterval(newTimeInterval);
    setShowModal(false);
  }, []);

  const onCheckChange = useCallback((key) => (checked) => {
    if (key === 'timeInterval') {
      setTimeSelected(checked);
      if (!checked) setTimeInterval();
      return;
    }
    let newTriggers;
    if (checked) {
      newTriggers = localTriggers.concat([key]);
    } else {
      newTriggers = localTriggers.filter((trigger) => trigger !== key);
    }
    setLocalTriggers(newTriggers);
  }, [setTimeInterval, localTriggers]);

  const onTriggerDaysChanged = useCallback((key) => (days) => {
    const newTriggers = {
      ...localTriggerDays,
      [key]: days,
    };
    setTriggerDays(newTriggers);
    setLocalTriggerDays(newTriggers);
  }, [setTriggerDays, localTriggerDays]);

  useEffect(() => {
    if (initialTriggers?.length) {
      const hasExternal = initialTriggers.includes('external');

      if ((isExternalForm && !hasExternal) || (!isExternalForm && hasExternal)) {
        setLocalTriggers(defaultTriggers);
      }
    }
  }, [initialTriggers, isExternalForm]);

  useEffect(() => {
    setTriggers(localTriggers);
  }, [localTriggers]);

  useEffect(() => {
    setTriggers(initialTriggers || defaultTriggers);
  }, []);

  useEffect(() => {
    // Make sure triggers have really changed or
    // This will cause infinite loop
    // since localTriggers is a dependancy and being updated by setLocalTriggers
    let shouldUpdate = false;
    let needsPush = true;
    const newTriggers = [];
    localTriggers.forEach((trigger) => {
      if (trigger in timeTriggerTypeMap && trigger !== timeInterval) {
        shouldUpdate = true;
      } else {
        newTriggers.push(trigger);
        if (trigger === timeInterval) needsPush = false;
      }
    });
    if (timeSelected && timeInterval && needsPush) {
      shouldUpdate = true;
      newTriggers.push(timeInterval);
    }
    if (shouldUpdate) setLocalTriggers(newTriggers);
  }, [timeInterval, localTriggers, timeSelected]);

  const isNuxStep2 = activeNuxAction === FORMS_WORKFLOW_STEP_2;
  const isNuxStep4 = activeNuxAction === FORMS_WORKFLOW_STEP_4;

  const containerStyle = useMemo(() => {
    const newStyle = {};
    if (isDisplay || isNuxStep2 || isNuxStep4) newStyle.pointerEvents = 'none';
    if (isNuxStep2 || isNuxStep4) newStyle.zIndex = 1000;
    return newStyle;
  }, [isNuxStep2, isNuxStep4]);

  return (
    <div
      className="workflow-trigger-node"
      style={containerStyle}
    >
      <NuxPopover
        placement="right"
        visible={isNuxStep2}
        text={FORMS_WORKFLOW_STEP_2_TEXT}
        nextAction={FORMS_WORKFLOW_STEP_3}
      >
        <Row>
          <b>Triggers</b>
        </Row>
      </NuxPopover>
      <Row style={{ padding: 5, fontSize: 12, marginBottom: 10 }}>
        Select which events will start this workflow.
        {' '}
        <br />
        {' '}
        <br />
        When one of the checked events occurs, we'll send this form to the correct users.
      </Row>
      { !isExternalForm && (
        <>
          <WorkflowTriggerCheckbox
            checked={localTriggers.includes('manual')}
            onCheckChange={onCheckChange('manual')}
          >
            Manual

          </WorkflowTriggerCheckbox>
          <WorkflowTriggerCheckbox
            checked={localTriggers.includes('clockIn')}
            onCheckChange={onCheckChange('clockIn')}
            showTimeOptions
            triggerDays={localTriggerDays?.clockIn ?? []}
            onTriggerDaysChanged={onTriggerDaysChanged('clockIn')}
          >
            On Clock In

          </WorkflowTriggerCheckbox>
          <WorkflowTriggerCheckbox
            checked={localTriggers.includes('clockOut')}
            onCheckChange={onCheckChange('clockOut')}
            showTimeOptions
            triggerDays={localTriggerDays?.clockOut ?? []}
            onTriggerDaysChanged={onTriggerDaysChanged('clockOut')}
          >
            On Clock Out

          </WorkflowTriggerCheckbox>
        </>
      )}
      { !!isExternalForm && (
        <WorkflowTriggerCheckbox
          checked
          onCheckChange={onCheckChange('external')}
          disabled
        >
          <span style={{ color: 'white' }}>External Form</span>
        </WorkflowTriggerCheckbox>
      )}
      {/* <TriggerCheckbox
        defaultChecked={localTriggers.includes('timeInterval')}
        onCheckChange={onCheckChange('timeInterval')}
      >On Time Interval:</TriggerCheckbox> */}
      {timeSelected
        && (
        <Row style={{ margin: 10, color: 'black' }}>
          <BorderlessButton
            title={
              timeInterval in timeTriggerTypeMap
                ? timeTriggerTypeMap[timeInterval]
                : 'Select a Time Interval'
            }
            onClick={showModal}
          />
        </Row>
        )}
      <NuxPopover
        placement="rightBottom"
        visible={isNuxStep4}
        text={FORMS_WORKFLOW_STEP_SOURCE_TEXT}
      >
        <WorkflowHandle
          type="source"
          position="bottom"
          disabled
        />
      </NuxPopover>
      <WorkflowTimeTriggerModal
        typeMap={timeTriggerTypeMap}
        visible={shouldShowModal}
        onCloseClicked={hideModal}
        onSave={saveTimeInterval}
      />
    </div>
  );
};
