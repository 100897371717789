import React, { useMemo, useCallback } from 'react';
import { Row, Col, Typography } from 'antd';
import { useSelector } from 'react-redux';

import { PropTypes } from 'prop-types';

import ListCard from '../common/ListCard';
import colors from '../constants/Colors';

import Permissions from '../auth/Permissions';

import EquipmentTimerCard from './EquipmentTimerCard';
import { getIdMap } from '../helpers/helpers';

export default function EquipmentCard({
  id,
  name,
  divisionIds,
  code,
  currentProjectId,
  currentLocationText,
  lastTask = {},
  statusId,
  labels = [],
  status,
  eclipseId,
  image,
  active,
  userId,
  hourlyCost,
  hourlyBillingRate,
  dailyCost,
  dailyBillingRate,
  equipmentTypeId,
  qrCode,
  costcodeId,
  onArchive,
  onEdit,
  onDelete,
  onClick,
  style,
  onTimerClick,
}) {
  const divisions = useSelector((state) => state.settings.divisions);
  const equipmentTypes = useSelector((state) => state.equipment.equipmentTypes);

  const divisionNames = useMemo(() => divisionIds
    .filter((divisionId) => divisions[divisionId])
    .map((divisionId) => divisions[divisionId].name)
    .join(', '), [divisions, divisionIds]);

  const equipmentTypeMap = useMemo(() => getIdMap(equipmentTypes), [equipmentTypes]);
  const equipmentType = equipmentTypeMap[equipmentTypeId];

  const onCardClick = useCallback(active ? () => {
    onTimerClick({
      id, name, currentProjectId, currentLocationText,
    });
  } : null, [id, name, active, currentProjectId, currentLocationText]);

  const equipmentFullName = code ? `${code} - ${name}` : name;

  const hasWritePerms = Permissions.has('EQUIPMENT_WRITE');

  return (
    <ListCard
      style={style}
      onArchive={hasWritePerms ? () => onArchive({
        name, id, active,
      }) : null}
      onEdit={hasWritePerms ? () => onEdit({
        id,
        name,
        active,
        divisionIds,
        code,
        statusId,
        labels,
        image,
        eclipseId,
        userId,
        hourlyCost,
        hourlyBillingRate,
        equipmentTypeId,
        dailyCost,
        dailyBillingRate,
        qrCode,
        costcodeId,
      }) : null}
      onDelete={hasWritePerms ? () => onDelete({
        id, name, active,
      }) : null}
      onClick={() => onClick({
        id,
        name,
        active,
        divisionIds,
        code,
        statusId,
        labels,
        image,
        eclipseId,
        userId,
        hourlyCost,
        hourlyBillingRate,
        equipmentTypeId,
        dailyCost,
        dailyBillingRate,
        qrCode,
        costcodeId,
      })}
      isActive={active}
      bodyStyle={{ height: '100%' }}
    >
      <Row
        style={{
          height: 17,
          width: '100%',
          paddingBottom: 25,
          paddingTop: 6,
          overflowWrap: 'anywhere',
        }}
        type="flex"
      >
        <Typography.Text
          ellipsis
          style={{
            width: '100%',
            fontFamily: 'roboto-bold',
            fontSize: 16,
            color: colors.ONTRACCR_BLACK,
          }}
        >
          {equipmentFullName}
        </Typography.Text>
      </Row>
      <Row>
        <Typography.Text
          ellipsis
          style={{
            width: '100%',
            fontFamily: 'roboto-light',
            fontSize: 12,
            color: colors.DARK_GRAY,
            lineHeight: '12px',
          }}
        >
          {divisionNames}
        </Typography.Text>
      </Row>
      <Row space>
        {status && (
        <Col span={10}>
          <Typography.Text
            ellipsis
            style={{
              width: '100%',
              fontFamily: 'roboto-light',
              fontSize: 10,
              color: colors.DARK_GRAY,
              lineHeight: '12px',
            }}
          >
            {status}
          </Typography.Text>
        </Col>
        )}
        <Col flex="auto">
          <Typography.Text
            ellipsis
            style={{
              width: '100%',
              fontFamily: 'roboto-light',
              fontSize: 10,
              color: colors.DARK_GRAY,
              textAlign: 'right',
              lineHeight: '12px',
            }}
          >
            {equipmentType?.name ?? 'None'}
          </Typography.Text>
        </Col>
      </Row>
      <Row
        align={active ? 'middle' : 'top'}
        justify="center"
        style={{ marginTop: active ? 0 : 5, height: 'calc(100% - 100px)' }}
      >
        <Col
          span={24}
          style={{
            height: '100%',
            maxHeight: 55,
            maxWidth: '90%',
          }}
        >
          <EquipmentTimerCard
            task={lastTask}
            onClick={onCardClick}
          />
        </Col>
      </Row>

    </ListCard>
  );
}

/* eslint-disable react/forbid-prop-types */
EquipmentCard.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  divisionIds: PropTypes.array,
  code: PropTypes.string,
  currentProjectId: PropTypes.string,
  currentLocationText: PropTypes.string,
  lastTask: PropTypes.object,
  statusId: PropTypes.string.isRequired,
  labels: PropTypes.array,
  status: PropTypes.node.isRequired,
  eclipseId: PropTypes.string,
  image: PropTypes.node,
  active: PropTypes.number,
  userId: PropTypes.string,
  hourlyCost: PropTypes.number.isRequired,
  hourlyBillingRate: PropTypes.number.isRequired,
  dailyCost: PropTypes.number.isRequired,
  dailyBillingRate: PropTypes.number.isRequired,
  equipmentTypeId: PropTypes.string.isRequired,
  qrCode: PropTypes.object.isRequired,
  costcodeId: PropTypes.string,
  onArchive: PropTypes.func.isRequired,
  onEdit: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  onTimerClick: PropTypes.func.isRequired,
};

EquipmentCard.defaultProps = {
  currentProjectId: null,
  currentLocationText: null,
  divisionIds: [],
  costcodeId: null,
  image: undefined,
  code: '',
  eclipseId: '',
  userId: '',
  lastTask: {},
  active: 0,
  labels: [],
  style: {},
};
