import {
  UPDATE_CLIENT_PORTAL_SETTINGS,
  GET_CLIENT_PORTAL_SETTINGS,
  CLIENT_PORTAL_SELECT_CARD,
  GET_CLIENT_PORTAL_ACCOUNTS,
  CREATE_CLIENT_PORTAL_ACCOUNT,
  DELETE_CLIENT_PORTAL_ACCOUNT,
  UPDATE_CLIENT_PORTAL_ACCOUNT,
  CLIENT_PORTAL_LOGIN,
  CLIENT_PORTAL_LOGOUT,
  CLIENT_PORTAL_GET_DATA,
  CLIENT_PORTAL_SEND_MESSAGE,
  MARK_CUSTOMER_CLIENT_COMM_AS_READ,
} from '../../state/actionTypes';
import Analytics from '../../helpers/Analytics';
import ClientPortalService from './clientPortal.service';

import {
  fallbackPrimaryColor,
  fallbackAccentColor,
} from '../clientPortal.constants';

export default {};

export const updateClientPortalSettings = (payload) => async (dispatch) => {
  Analytics.track('ClientPortal/UpdateSettings');
  const newData = { ...payload };
  if (!newData.primaryColor) newData.primaryColor = fallbackPrimaryColor;
  if (!newData.accentColor) newData.accentColor = fallbackAccentColor;
  if (newData.companyId) delete newData.companyId;
  if (newData.image) delete newData.image;
  const { data } = await ClientPortalService.updateSettings(newData);
  if (!data) return false;
  dispatch({
    type: UPDATE_CLIENT_PORTAL_SETTINGS,
    payload: newData,
  });
  return true;
};

export const getClientPortalSettings = () => async (dispatch) => {
  const { data } = await ClientPortalService.getSettings();
  if (!data) return false;
  dispatch({
    type: GET_CLIENT_PORTAL_SETTINGS,
    payload: data,
  });
  return true;
};

export const getClientPortalSettingsFromURL = (url) => async (dispatch) => {
  const { data } = await ClientPortalService.getSettingsFromURL(url);
  if (!data) return false;
  dispatch({
    type: GET_CLIENT_PORTAL_SETTINGS,
    payload: data,
  });
  return true;
};

export const selectClientPortalCard = (cardId) => ({
  type: CLIENT_PORTAL_SELECT_CARD,
  payload: {
    cardId,
  },
});

export const getClientPortalAccounts = () => async (dispatch) => {
  const { data } = await ClientPortalService.getAccounts();
  if (!data) return false;
  dispatch({
    type: GET_CLIENT_PORTAL_ACCOUNTS,
    payload: {
      accounts: data,
    },
  });
  return true;
};

export const createClientPortalAccount = (payload) => async (dispatch) => {
  const { data } = await ClientPortalService.createAccount(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_CLIENT_PORTAL_ACCOUNT,
    payload: {
      newAccount: data,
    },
  });
  return true;
};

export const deleteClientPortalAccount = (id) => async (dispatch) => {
  const { data } = await ClientPortalService.deleteAccount(id);
  if (!data) return false;
  dispatch({
    type: DELETE_CLIENT_PORTAL_ACCOUNT,
    payload: {
      accountId: id,
    },
  });
  return true;
};

export const updateClientPortalAccount = (id, payload) => async (dispatch) => {
  const { data } = await ClientPortalService.updateAccount(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_CLIENT_PORTAL_ACCOUNT,
    payload: {
      accountId: id,
      newData: payload.email ? { email: payload.email } : {},
    },
  });
  return true;
};

export const loadClientTokenFromStorage = () => {
  const token = window.sessionStorage.getItem('clientToken');
  return {
    type: token ? CLIENT_PORTAL_LOGIN : CLIENT_PORTAL_LOGOUT,
  };
};

export const loginClient = (payload) => async (dispatch) => {
  const { data: token } = await ClientPortalService.login(payload);
  if (!token) return false;
  window.sessionStorage.setItem('clientToken', token);
  dispatch({
    type: CLIENT_PORTAL_LOGIN,
  });
  return true;
};

export const logoutClient = () => async (dispatch) => {
  const { data } = await ClientPortalService.logout();
  if (!data) return false;
  setTimeout(() => {
    window.sessionStorage.removeItem('clientToken');
    dispatch({
      type: CLIENT_PORTAL_LOGOUT,
    });
  }, 350);
  return true;
};

export const removeClientTokenFromStorage = () => {
  window.sessionStorage.removeItem('clientToken');
  return {
    type: CLIENT_PORTAL_LOGOUT,
  };
};

export const getClientPortalData = () => async (dispatch) => {
  const { data } = await ClientPortalService.getData();
  if (!data) return false;
  dispatch({
    type: CLIENT_PORTAL_GET_DATA,
    payload: data,
  });
  return true;
};

export const sendMessage = ({ text }) => async (dispatch) => {
  const { data } = await ClientPortalService.sendMessage(text);
  if (!data) return false;
  dispatch({
    type: CLIENT_PORTAL_SEND_MESSAGE,
    payload: data,
  });
  return true;
};

export const markClientCommsAsRead = ({ ids, customerId }) => async (dispatch) => {
  const { data } = await ClientPortalService.markAsRead(ids);
  if (!data) return false;
  dispatch({
    type: MARK_CUSTOMER_CLIENT_COMM_AS_READ,
    payload: {
      ids,
      customerId,
      isExternal: true,
    },
  });
  return true;
};
