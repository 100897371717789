import {
  GET_SALES_RABBIT_WORKFLOWS,
  CREATE_SALES_RABBIT_WORKFLOW,
  UPDATE_SALES_RABBIT_WORKFLOWS,
  DELETE_SALES_RABBIT_WORKFLOWS,
  GET_SALES_RABBIT_REGIONS,
  CREATE_SALES_RABBIT_REGION,
  UPDATE_SALES_RABBIT_REGION,
  DELETE_SALES_RABBIT_REGION,
} from '../../../../state/actionTypes';

import SalesRabbitService from './salesrabbit.service';

export default {};

export const getSalesRabbitWorkflows = () => async (dispatch) => {
  const { data } = await SalesRabbitService.getWorkflows();
  if (!data) return false;
  dispatch({
    type: GET_SALES_RABBIT_WORKFLOWS,
    payload: {
      workflows: data,
    },
  });
  return true;
};

export const createSalesRabbitWorkflows = (payload) => async (dispatch) => {
  const { data } = await SalesRabbitService.createWorkflow(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_SALES_RABBIT_WORKFLOW,
    payload: {
      workflow: data,
    },
  });
  return true;
};

export const updateSalesRabbitWorkflows = (id, payload) => async (dispatch) => {
  const { data } = await SalesRabbitService.updateWorkflow(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SALES_RABBIT_WORKFLOWS,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const deleteSalesRabbitWorkflows = (id) => async (dispatch) => {
  const { data } = await SalesRabbitService.deleteWorkflow(id);
  if (!data) return false;
  dispatch({
    type: DELETE_SALES_RABBIT_WORKFLOWS,
    payload: {
      id,
    },
  });
  return true;
};

export const getSalesRabbitRegions = () => async (dispatch) => {
  const { data } = await SalesRabbitService.getRegions();
  if (!data) return false;
  dispatch({
    type: GET_SALES_RABBIT_REGIONS,
    payload: {
      regions: data,
    },
  });
  return true;
};

export const createSalesRabbitRegion = (payload) => async (dispatch) => {
  const { data } = await SalesRabbitService.createRegion(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_SALES_RABBIT_REGION,
    payload: {
      region: data,
    },
  });
  return true;
};

export const updateSalesRabbitRegion = (id, payload) => async (dispatch) => {
  const { data } = await SalesRabbitService.updateRegion(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_SALES_RABBIT_REGION,
    payload: {
      id,
      newData: payload,
    },
  });
  return true;
};

export const deleteSalesRabbitRegion = (id) => async (dispatch) => {
  const { data } = await SalesRabbitService.deleteRegion(id);
  if (!data) return false;
  dispatch({
    type: DELETE_SALES_RABBIT_REGION,
    payload: {
      id,
    },
  });
  return true;
};
