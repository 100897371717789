import React from 'react';
import { Card, Row, Col, } from 'antd';
import colors from '../constants/Colors';
import BorderlessButton from './buttons/BorderlessButton';

export default ({
  children,
  onArchive,
  onDelete,
  onEdit,
  onClick = () => {},
  style = {},
  isActive = true,
  bodyStyle = {},
}) => (
  <Card 
    hoverable={onClick}
    style={{ 
      borderRadius:8,
      borderWidth:1,
      borderColor:colors.ONTRACCR_GRAY,
      ...style,
    }}
    size='small'
    bodyStyle={{paddingTop:0,paddingBottom:5, ...bodyStyle}}
  >
    <div 
      onClick={onClick}
      style={{
        width:'100%',
        height:'100%'
      }}
    >
    
      {children}

      <Row justify='center' type='flex' 
        style={{
          marginTop:5,
          width:'100%',
          position:'absolute',
          bottom:1,
          left:0,
      }}>
        <Row style={{maxWidth:350}} justify='center'>
          <Col style={{alignContent:'center'}}>
            {onEdit && <BorderlessButton
              title='Edit'
              icon='edit'
              onClick={(e) => {
                e.stopPropagation();
                onEdit();
              }}
            />}
          </Col>
          <Col>
            {onArchive && <BorderlessButton
              title={isActive ? 'Archive' : 'Activate'}
              icon={isActive ? 'delete' : 'undo'} 
              color={colors.ONTRACCR_RED}
              onClick={(e) => {
                e.stopPropagation();
                onArchive()
              }}
            />}
          </Col>
          <Col>
            {onDelete && !isActive && <BorderlessButton
              title={'Delete'}
              icon={'delete'} 
              color={colors.ONTRACCR_RED}
              onClick={(e) => {
                e.stopPropagation();
                onDelete()
              }}
            />}
          </Col>
        </Row>
      </Row>
    </div>
  </Card>
);
