/* eslint-disable no-undef */
import React from 'react';
import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Circle,
  Marker,
  InfoWindow,
} from 'react-google-maps';
import PropTypes from 'prop-types';

import colors from '../../constants/Colors';
import TaskIcon from './TaskIcon';
import mapStyles from './MapConstants';

const { MarkerWithLabel } = require('react-google-maps/lib/components/addons/MarkerWithLabel');
const { SearchBox } = require('react-google-maps/lib/components/places/SearchBox');

const extendBounds = (bounds, lat, long) => {
  const place = new google.maps.LatLng(lat, long);
  bounds.extend(place);
};

class OnTraccrMap extends React.Component {
  constructor(props) {
    super(props);
    const {
      lat = 49.2578263,
      lng = -123.1939442,
    } = this.props;
    const { bounds, labels } = this.parseTaskLocations();
    this.onMarkerClick = this.markerClicked.bind(this);
    const { bounds: markerBounds, labels: markerLabels } = this.parseMarkers();

    this.state = {
      bounds: bounds || markerBounds,
      labels: labels || markerLabels,
      lat,
      lng,
      visibleMarkerInfoWindow: null,
      shouldUpdateBounds: true,
    };
    this.onPlacesChanged = this.placeChanged.bind(this);
  }

  componentDidUpdate(prevProps) {
    const {
      lat,
      lng,
      taskLocations = [],
      markers,
      selectedMarkerId,
    } = this.props;

    const {
      lat: prevLat,
      lng: prevLng,
      taskLocations: prevTaskLocations = [],
      selectedMarkerId: prevSelectedMarker,
    } = prevProps;
    if (lng !== prevLng || lat !== prevLat) {
      this.setState({
        lng, lat,
      });
    }

    if (selectedMarkerId && selectedMarkerId !== prevSelectedMarker) {
      this.onMarkerClick(selectedMarkerId)();
    }

    if (taskLocations.length && taskLocations !== prevTaskLocations) {
      const { labels, bounds } = this.parseTaskLocations();
      this.setState({
        labels,
        bounds,
      });
    }

    if (markers !== prevProps.markers) {
      const { labels, bounds } = this.parseMarkers();
      this.setState({
        labels,
        bounds,
        shouldUpdateBounds: false,
      });
    }
  }

  placeChanged() {
    const {
      onPlaceChanged,
    } = this.props;
    const places = this.searchRef.getPlaces();
    const bounds = new google.maps.LatLngBounds();
    if (places.length > 0 && places[0].formatted_address) {
      const place = places[0];

      if (place.geometry.viewport) {
        bounds.union(place.geometry.viewport);
      } else {
        bounds.extend(place.geometry.location);
      }
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.setState({
        lat,
        lng,
      });
      onPlaceChanged({
        lat,
        lng,
        address: place.formatted_address,
      });
    }
  }

  markerClicked(markerId) {
    return () => {
      const {
        visibleMarkerInfoWindow = null,
      } = this.state;

      const {
        setSelectedMarker = null,
      } = this.props;

      if (visibleMarkerInfoWindow === markerId) return;

      this.setState({
        visibleMarkerInfoWindow: markerId,
      }, () => {
        // re-render markers
        const { bounds, labels } = this.parseMarkers();
        this.setState({
          bounds,
          labels,
          shouldUpdateBounds: false,
        });
      });

      if (setSelectedMarker) setSelectedMarker(markerId);
    };
  }

  parseMarkers() {
    const {
      markers,
      markerRender,
      markersAreDraggable,
      onMarkerDrag,
    } = this.props;

    const {
      visibleMarkerInfoWindow = null,
    } = this.state ?? {};

    if (!markers?.length) return {};
    const labels = [];
    const bounds = new google.maps.LatLngBounds();
    extendBounds(bounds, markers[0].lat, markers[0].lng);
    markers.forEach((marker) => {
      extendBounds(bounds, marker.lat, marker.lng);
      labels.push(
        <Marker
          position={{ lat: marker.lat, lng: marker.lng }}
          title={marker.title}
          onClick={this.onMarkerClick(marker.id)}
          key={marker.id}
          draggable={markersAreDraggable}
          onDragEnd={(e) => {
            const latLng = e.latLng?.toJSON?.();
            if (markersAreDraggable && onMarkerDrag && latLng) {
              onMarkerDrag({ ...latLng, id: marker.id });
            }
          }}
        >
          { visibleMarkerInfoWindow === marker.id && (
            <InfoWindow
              onCloseClick={this.onMarkerClick(null)}
            >
              {markerRender
                ? markerRender(marker)
                : (
                  <div>
                    <h3>{marker.title}</h3>
                    <p>{marker.address}</p>
                  </div>
                )}
            </InfoWindow>
          )}
        </Marker>,
      );
    });
    return { labels, bounds };
  }

  parseTaskLocations() {
    const {
      taskLocations = [],
      lat,
      lng,
    } = this.props;
    if (taskLocations.length === 0) return {};
    const labels = [];
    const bounds = new google.maps.LatLngBounds();
    extendBounds(bounds, lat, lng);
    taskLocations.forEach((task) => {
      const taskName = 'Task';
      if (task.startLatitude && task.startLongitude) {
        extendBounds(bounds, task.startLatitude, task.startLongitude);
        labels.push(
          <TaskIcon
            opacity={task.startOpacity}
            key={`${task.id}-start`}
            mapRef={this.mapRef}
            name={`${task.id}-start`}
            latitude={task.startLatitude}
            longitude={task.startLongitude}
            title={`${taskName} - Clock In`}
            clockin
          />,
        );
      }
      if (task.endLatitude && task.endLongitude) {
        extendBounds(bounds, task.endLatitude, task.endLongitude);
        labels.push(
          <TaskIcon
            opacity={task.endOpacity}
            key={`${task.id}-end`}
            mapRef={this.mapRef}
            name={`${task.id}-end`}
            latitude={task.endLatitude}
            longitude={task.endLongitude}
            title={`${taskName} - Clock Out`}
            clockin={false}
          />,
        );
      }
    });
    return { labels, bounds };
  }

  render() {
    const {
      gestureHandling = 'cooperative',
      geofence,
      showSearch,
      address,
      isNotDisplay,
      showPin = true,
      defaultZoom = 13,
      searchDisabled,
      options = {},
      onClick,
      lockBounds,
    } = this.props;
    const {
      lat,
      lng,
      labels,
      bounds,
      shouldUpdateBounds,
    } = this.state;

    const showGeofence = !!+geofence;
    return (
      <GoogleMap
        className="ontraccr-map"
        ref={(ref) => {
          this.mapRef = ref;
          if (bounds && this.mapRef && shouldUpdateBounds && !lockBounds) {
            this.mapRef.fitBounds(bounds, 100);
          }
        }}
        defaultZoom={defaultZoom}
        center={bounds?.getCenter() || { lat, lng }}
        options={{
          gestureHandling,
          streetViewControl: false,
          mapTypeControl: false,
          styles: mapStyles,
          ...options,
        }}
        onClick={(e) => {
          const latLng = e.latLng?.toJSON?.();
          if (onClick && latLng) onClick(latLng);
        }}
      >
        {!!(showPin && lat && lng) && (
          <MarkerWithLabel
            position={{ lat, lng }}
            labelAnchor={new google.maps.Point(-15, 25)}
            labelStyle={{
              fontSize: '16px',
              color: colors.ONTRACCR_RED,
              'text-shadow': '-1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff',
            }}
            labelVisible={!isNotDisplay && !!address}
          >
            <div>{address}</div>
          </MarkerWithLabel>
        )}
        {!!showGeofence && (
          <Circle
            center={{ lat, lng }}
            radius={geofence}
            options={{
              strokeColor: colors.ONTRACCR_RED,
              fillColor: colors.ONTRACCR_RED,
              fillOpacity: 0.25,
            }}
          />
        )}
        {labels}
        {showSearch && (
          <SearchBox
            ref={(ref) => {
              this.searchRef = ref;
            }}
            bounds={bounds}
            controlPosition={google.maps.ControlPosition.TOP_LEFT}
            onPlacesChanged={this.onPlacesChanged}
          >
            <input
              type="text"
              placeholder="Enter Address"
              defaultValue={address}
              style={{
                boxSizing: 'border-box',
                border: '1px solid transparent',
                width: '500px',
                height: '32px',
                marginTop: 10,
                padding: '0 12px',
                marginLeft: 10,
                borderRadius: '3px',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                fontSize: '14px',
                outline: 'none',
                textOverflow: 'ellipses',
                readonly: 'readonly',
                opacity: searchDisabled ? 0.75 : 1,
                pointerEvents: searchDisabled ? 'none' : 'auto',
              }}
            />
          </SearchBox>
        )}
      </GoogleMap>
    );
  }
}

/* eslint-disable react/forbid-prop-types */
OnTraccrMap.propTypes = {
  lat: PropTypes.number,
  lng: PropTypes.number,
  gestureHandling: PropTypes.string,
  geofence: PropTypes.number,
  showSearch: PropTypes.bool,
  address: PropTypes.string,
  isNotDisplay: PropTypes.bool,
  showPin: PropTypes.bool,
  defaultZoom: PropTypes.number,
  searchDisabled: PropTypes.bool,
  options: PropTypes.object,
  onClick: PropTypes.func,
  taskLocations: PropTypes.array,
  markers: PropTypes.array,
  markerRender: PropTypes.func,
  markersAreDraggable: PropTypes.bool,
  onMarkerDrag: PropTypes.func,
  selectedMarkerId: PropTypes.string,
  setSelectedMarker: PropTypes.func,
  onPlaceChanged: PropTypes.func,
  lockBounds: PropTypes.bool,
};

OnTraccrMap.defaultProps = {
  lat: 49.2578263,
  lng: -123.1939442,
  gestureHandling: 'cooperative',
  geofence: null,
  showSearch: false,
  address: '',
  isNotDisplay: false,
  showPin: true,
  defaultZoom: 13,
  searchDisabled: false,
  options: {},
  onClick: null,
  taskLocations: [],
  markers: [],
  markerRender: null,
  markersAreDraggable: false,
  onMarkerDrag: null,
  selectedMarkerId: null,
  setSelectedMarker: null,
  onPlaceChanged: () => 1,
  lockBounds: false,
};

export default withScriptjs(withGoogleMap(OnTraccrMap));
