import React from 'react';

import WorkflowSimpleMappingConfigureDrawer from './WorkflowSimpleMappingConfigureDrawer';

export default ({
  visible,
  onClose,
  onSubmit,
  sourceName,
  sourceSections = [],
  fieldMappings = {},
  onFieldMappingsChange,
  fields,
  requiredFields,
}) => (
  <WorkflowSimpleMappingConfigureDrawer
    visible={visible}
    onClose={onClose}
    onSubmit={onSubmit}
    sourceSections={sourceSections}
    fieldMappings={fieldMappings}
    onFieldMappingsChange={onFieldMappingsChange}
    fields={fields}
    requiredFields={requiredFields}
    title={
      <span>Configure which fields from this <b>{sourceName}</b> form are loaded into the shift data fields.</span>
    }
    targetHeader='Shift Data Field'
  />
);
