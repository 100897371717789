import { TaskHelpers } from 'ontraccr-common';
import { DateTime } from 'luxon';
import {
  roundTotalRuntime,
} from '../time';

import calculateDaysOT from './calculateDaysOT';

import {
  getDay,
  getWeek,
  hoursToMs,
} from './otHelpers';

const isGoodTime = (startTime, endTime) => (
  startTime
  && endTime
  && endTime > startTime + 1000
);

export const isGoodTask = (task) => (
  isGoodTime(task.startTime, task.endTime)
  || isGoodTime(task.otStartTime, task.otEndTime)
  || isGoodTime(task.breakStartTime, task.breakEndTime)
  || isGoodTime(task.doubleOTStartTime, task.doubleOTEndTime)
);

/**
 * Function adjusts the task time by the given amount
 * If a task only has regular time then only regular time will be adjusted
 * If a task only has overtime then only overtime will be adjusted
 * If a task has both regular time and overtime then both will be adjusted
 * @param {Object} arg
 * @param {Object} arg.task Task to adjust
 * @param {Number} arg.regularTimeToAdjust Amount of regular time to adjust
 * @param {Number} arg.overtimeToAdjust Amount of overtime to adjust
 * @returns {Object} Object containing the new task, the amount of regular and overtime adjusted
 */
const adjustTaskTime = ({
  task,
  regularTimeToAdjust,
  overtimeToAdjust,
}) => {
  const result = {
    adjustedRegularTime: 0,
    adjustedOvertime: 0,
  };

  const newTask = { ...task };

  if (regularTimeToAdjust > 0 && newTask.regularTime > 0) {
    newTask.endTime += regularTimeToAdjust;
    newTask.regularTime += regularTimeToAdjust;
    result.adjustedRegularTime = regularTimeToAdjust;
  }

  if (regularTimeToAdjust < 0 && newTask.regularTime > 0) {
    const runtime = TaskHelpers.getRuntime(newTask.startTime, newTask.endTime);
    if (runtime <= -regularTimeToAdjust) {
      newTask.endTime = newTask.startTime;
      newTask.regularTime = 0;
      result.adjustedRegularTime = -runtime;
    } else {
      newTask.endTime -= -regularTimeToAdjust;
      newTask.regularTime -= -regularTimeToAdjust;
      result.adjustedRegularTime = regularTimeToAdjust;
    }
  }

  if (overtimeToAdjust > 0 && newTask.overtime > 0) {
    if (newTask.otStartTime && newTask.otEndTime) {
      newTask.otEndTime += overtimeToAdjust;
    } else {
      newTask.endTime += overtimeToAdjust;
    }

    newTask.overtime += overtimeToAdjust;
    result.adjustedOvertime = overtimeToAdjust;
  }

  if (overtimeToAdjust < 0 && newTask.overtime > 0) {
    let startTimeKey = 'startTime';
    let endTimeKey = 'endTime';
    if (newTask.otStartTime && newTask.otEndTime) {
      startTimeKey = 'otStartTime';
      endTimeKey = 'otEndTime';
    }

    const runtime = TaskHelpers.getRuntime(newTask[startTimeKey], newTask[endTimeKey]);
    if (runtime <= -overtimeToAdjust) {
      newTask[endTimeKey] = newTask[startTimeKey];
      newTask.overtime = 0;
      result.adjustedOvertime = -runtime;
    } else {
      newTask[endTimeKey] -= -overtimeToAdjust;
      newTask.overtime -= -overtimeToAdjust;
      result.adjustedOvertime = overtimeToAdjust;
    }
  }

  return {
    ...result,
    newTask,
  };
};

const roundDaysTask = ({
  day,
  roundingInterval,
  dailyOvertime,
  dailyDouble,
  enableManualOT,
  roundingType,
  roundingSetting,
}) => {
  const totalTime = day.regularTime + day.overtime + day.doubleOT;
  const roundedTime = roundTotalRuntime(
    totalTime,
    roundingInterval,
    roundingType,
    roundingSetting,
  );

  if (roundedTime === totalTime) {
    return calculateDaysOT({
      day,
      dailyOvertime,
      dailyDouble,
      enableManualOT,
    });
  }
  const currentDay = { ...day };
  let toAdd = roundedTime - totalTime;
  let i = currentDay.tasks.length - 1;

  let regularTimeToAdjust = toAdd;
  let overtimeToAdjust = 0;

  if (enableManualOT) {
    // If we have overtime and no regular time
    // We want to update the overtime instead of regular time of a task
    if (day.regularTime === 0 && day.overtime > 0) {
      overtimeToAdjust = toAdd;
      regularTimeToAdjust = 0;
    // If we have to round down regular time
    // But there is not enough regular time in the day to round down
    // ie. regular time is 13 minutes, OT is 31 minutes. Round by 30 minutes.
    // In this case we would have to round regular time by 14 minutes
    // but do not have enough time to do so, so we adjust overtime as well by 1 minute
    } else if (toAdd < 0 && day.regularTime < -toAdd) {
      overtimeToAdjust = day.regularTime + toAdd;
      regularTimeToAdjust = -day.regularTime;
    }
  }

  // If we are rounding up
  if (toAdd > 0) {
    // Adjust each task starting from the end
    // We need to adjust each task appropriately
    while (i >= 0 && toAdd > 0) {
      const dayTask = currentDay.tasks[i];
      const {
        newTask,
        adjustedRegularTime,
        adjustedOvertime,
      } = adjustTaskTime({
        task: dayTask,
        regularTimeToAdjust,
        overtimeToAdjust,
      });

      toAdd -= (adjustedRegularTime + adjustedOvertime);
      currentDay.tasks[i] = newTask;
      i -= 1;
    }

    return calculateDaysOT({
      day: currentDay,
      dailyOvertime,
      dailyDouble,
      enableManualOT,
    });
  }
  let toRemove = -toAdd;
  while (i >= 0 && toRemove > 0) {
    const dayTask = currentDay.tasks[i];
    const {
      newTask,
      adjustedRegularTime,
      adjustedOvertime,
    } = adjustTaskTime({
      task: dayTask,
      regularTimeToAdjust,
      overtimeToAdjust,
    });

    toRemove += (adjustedRegularTime + adjustedOvertime);
    regularTimeToAdjust -= adjustedRegularTime;
    overtimeToAdjust -= adjustedOvertime;
    currentDay.tasks[i] = newTask;
    i -= 1;
  }
  return calculateDaysOT({
    day: currentDay,
    dailyOvertime,
    dailyDouble,
    enableManualOT,
  });
};

const parseWeek = ({
  days = [],
  regularTime,
  saturdayTime,
  sundayTime,
}, weeklyOvertime) => {
  const weeklyOTMS = hoursToMs(weeklyOvertime || 0);
  const totalTime = regularTime + saturdayTime + sundayTime;

  let weeklyOT = totalTime - weeklyOTMS;

  if (weeklyOvertime && weeklyOT > 0) {
    let i = days.length - 1;

    while (i >= 0) {
      const { tasks: dayTasks = [] } = days[i];
      let j = dayTasks.length - 1;
      while (j >= 0) {
        const task = dayTasks[j];
        j -= 1;
        if (task.type === 'break') {
          continue;
        }
        const runtime = task.regularTime;
        const toAdd = Math.min(weeklyOT, runtime);
        task.regularTime = runtime - toAdd;
        task.overtime += toAdd;
        weeklyOT -= toAdd;
      }
      i -= 1;
    }
  }
  return days.map(({ tasks = [] }) => tasks.map((task) => {
    if (task.isSaturday) {
      return {
        ...task,
        saturdayTime: task.regularTime,
        saturdayOT: task.overtime,
        saturdayDoubleOT: task.doubleOT,
        regularTime: 0,
        overtime: 0,
        doubleOT: 0,
      };
    }

    if (task.isSunday) {
      return {
        ...task,
        sundayTime: task.regularTime,
        sundayOT: task.overtime,
        sundayDoubleOT: task.doubleOT,
        regularTime: 0,
        overtime: 0,
        doubleOT: 0,
      };
    }
    return task;
  })).flat();
};

export default ({
  tasks = [],
  settings: {
    dailyOvertime,
    dailyDouble,
    weeklyOvertime,
    roundingInterval,
    saturdayPay,
    sundayPay,
    enableManualOT,
    roundingType,
    roundingSetting,
  } = {},
  range: [
    startDT,
    endDT,
  ] = [
    DateTime.local().minus({ days: 15 }).startOf('day'),
    DateTime.local().plus({ days: 5 }).endOf('day'),
  ],
}) => {
  let currentDay = {
    tasks: [],
    regularTime: 0,
    overtime: 0,
    doubleOT: 0,
    isSaturday: false,
    isSunday: false,
  };
  let currentWeek = {
    days: [],
    regularTime: 0, // Tracks sum of days regular time
    overtime: 0, // Tracks sum of days overtime
    doubleOT: 0, // Tracks sum of days doubleOT
    saturdayTime: 0,
    saturdayOT: 0,
    saturdayDoubleOT: 0,
    sundayTime: 0,
    sundayOT: 0,
    sundayDoubleOT: 0,
  };
  let parsedTasks = [];
  const filteredTasks = tasks.filter((task) => {
    if (!isGoodTask(task)) return false;
    const date = TaskHelpers.getTaskDate(task);
    return !(date < startDT || date > endDT);
  });
  filteredTasks.sort((a, b) => (
    TaskHelpers.getStartTime(a) - TaskHelpers.getStartTime(b)
  ));

  filteredTasks
    .forEach((fTask, idx) => {
      const task = { ...fTask };
      const taskDate = TaskHelpers.getTaskDate(task);
      const day = getDay(taskDate);
      const week = getWeek(taskDate);
      const hasNext = idx < filteredTasks.length - 1;
      const nextDay = hasNext ? getDay(TaskHelpers.getTaskDate(filteredTasks[idx + 1])) : 'none';
      const nextWeek = hasNext ? getWeek(TaskHelpers.getTaskDate(filteredTasks[idx + 1])) : 'none';
      const runtimes = TaskHelpers.getRuntimes(task, false);
      const dayOfWeek = taskDate.weekday;
      const isSaturday = dayOfWeek === 6;
      const isSunday = dayOfWeek === 7;

      if (saturdayPay && isSaturday) {
        currentDay.isSaturday = true;
      } else if (sundayPay && isSunday) {
        currentDay.isSunday = true;
      }

      const newTask = {
        ...task,
        ...runtimes,
        isSaturday: !!(saturdayPay && isSaturday),
        isSunday: !!(sundayPay && isSunday),
      };

      if (!enableManualOT && task.type === 'overtime') {
        newTask.overtime = 0;
        newTask.regularTime = runtimes.overtime;
      }

      currentDay.tasks.push(newTask);
      if (task.type !== 'break') {
        currentDay.regularTime += newTask.regularTime;
        currentDay.overtime += newTask.overtime;
        currentDay.doubleOT += newTask.doubleOT;
      }

      if (day !== nextDay) {
        currentDay = roundDaysTask({
          day: currentDay,
          dailyOvertime,
          dailyDouble,
          roundingInterval,
          enableManualOT,
          roundingType,
          roundingSetting,
        });

        currentWeek.days.push(currentDay);
        if (currentDay.isSaturday) {
          currentWeek.saturdayTime += currentDay.regularTime;
          currentWeek.saturdayOT += currentDay.overtime;
          currentWeek.saturdayDoubleOT += currentDay.doubleOT;
        } else if (currentDay.isSunday) {
          currentWeek.sundayTime += currentDay.regularTime;
          currentWeek.sundayOT += currentDay.overtime;
          currentWeek.sundayDoubleOT += currentDay.doubleOT;
        } else {
          currentWeek.regularTime += currentDay.regularTime;
          currentWeek.overtime += currentDay.overtime;
          currentWeek.doubleOT += currentDay.doubleOT;
        }

        currentDay = {
          tasks: [],
          regularTime: 0,
          overtime: 0,
          doubleOT: 0,
          isSaturday: false,
          isSunday: false,
        };
      }
      if (week !== nextWeek) {
        parsedTasks = parsedTasks.concat(parseWeek(currentWeek, weeklyOvertime));
        currentWeek = {
          days: [],
          regularTime: 0,
          overtime: 0,
          doubleOT: 0,
          saturdayTime: 0,
          saturdayOT: 0,
          saturdayDoubleOT: 0,
          sundayTime: 0,
          sundayOT: 0,
          sundayDoubleOT: 0,
        };
      }
    });
  return parsedTasks;
};
