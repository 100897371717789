import React, {
  forwardRef, useEffect, useState, useImperativeHandle,
} from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import axios from 'axios';

import IntegrationUserSync from '../IntegrationUserSync';

import EclipseImportHelp from './EclipseImportHelp';

import { request } from '../../../helpers/requests';

const autoImportContent = (
  <div style={{ width: 500 }}>
    Ontraccr can automatically import Employees from Eclipse.
    <br />
    <br />
    1. We will automatically set their Ontraccr username and password
    when importing and send this information to their email.
    <br />
    <br />
    2. All users will be assigned the ‘Worker’ role after importing,
    make sure you change each user’s role afterwards as needed.
    <br />
    <br />
    If you would rather see exactly which data will be imported from Eclipse
    and fill in any missing fields,
    disable Auto Import to do this manually.
  </div>
);

function EclipseProjectSync({
  visible,
  setCanNext,
}, ref) {
  const {
    positions,
  } = useSelector((state) => state.settings);
  const users = useSelector((state) => state.users.users);
  const selectedDivision = useSelector((state) => state.eclipse.selectedDivision);

  const [eclipseUsers, setEclipseUsers] = useState([]);
  const [importUsers, setImportUsers] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      const {
        data: {
          data = [],
        } = {},
      } = await request({
        call: axios.get('/eclipse/data/employees', { params: { divisionId: selectedDivision } }),
        hideSuccessToast: true,
        errMsg: 'Failed to get eclipse employees',
      });
      setEclipseUsers(data);
      setLoading(false);
    };

    if (visible && selectedDivision) {
      load();
    } else {
      setEclipseUsers([]);
    }
  }, [visible, selectedDivision]);

  useImperativeHandle(ref, () => ({
    onSave: async () => request({
      call: axios.post('/integration/import', {
        importUsers: {
          linkDeletedList: importUsers.linkDeletedList,
          importList: importUsers.importList.map((project) => ({
            ...project,
            divisions: [selectedDivision],
          })),
        },
        integrationKey: 'eclipseId',
      }),
      hideSuccessToast: true,
      errMsg: 'Failed to import eclipse users',
    }),
  }), [selectedDivision, importUsers]);

  useEffect(() => {
    if (visible) setCanNext(!loading);
  }, [visible, loading, setCanNext]);

  return (
    <IntegrationUserSync
      title="Eclipse"
      integrationUsers={eclipseUsers}
      integrationKey="eclipseId"
      users={users}
      positions={positions}
      onUsersChanged={setImportUsers}
      autoImportContent={autoImportContent}
      importHelpText={<EclipseImportHelp type="Users" />}
      importUsers={importUsers}
      divisionId={selectedDivision}
      loading={loading}
    />
  );
}

const EclipseProjectSyncRef = forwardRef(EclipseProjectSync);
EclipseProjectSyncRef.propTypes = {
  visible: PropTypes.bool.isRequired,
  setCanNext: PropTypes.func.isRequired,
};

export default EclipseProjectSyncRef;
