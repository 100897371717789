import React from 'react';
import { Row, Drawer } from 'antd';

import FilePicker from '../../common/files/FilePicker';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';

export default ({
  visible,
  onSelect,
  onClose,
  onSave,
  selected = [],
}) => {
  return (
    <Drawer
      title='Select Form Export Location'
      visible={visible}
      width={500}
      maskClosable={false}
      onClose={onClose}
      bodyStyle={{ padding: 24 }}
    >
      <div className='form-export-file-location-scroll'>
        <FilePicker
          selectedKeys={selected}
          onSelect={onSelect}
          onlyFolders
        />
      </div>
      <div className='drawer-footer'>
        <Row justify='end' gutter={10}>
          <OnTraccrButton
            title='Cancel'
            type='cancel'
            style={{ marginRight: 8 }}
            onClick={onClose}
          />
          <OnTraccrButton
            title='Save'
            disabled={selected.length !== 1}
            onClick={onSave}
          />
        </Row>
      </div>
    </Drawer>
  );
};
