import axios from 'axios';

import { request, archive } from '../../helpers/requests';

export default {
  create: (payload) => request({
    call: axios.post('/materials', { ...payload, device: 'web' }),
    errMsg: 'Could not create material',
  }),
  massUpload: (payload) => request({
    call: axios.post('/materials/upload', payload),
    errMsg: 'Could not upload materials',
  }),
  get: (lastUpdated) => request({
    call: axios.get('/materials', { params: { timestamp: lastUpdated } }),
    errMsg: 'Could not get materials',
    hideSuccessToast: true,
  }),
  archive: (id, active) => archive({
    id, active, type: 'materials',
  }),
  deleteMaterial: (id) => request({
    call: axios.delete(`/materials/${id}`),
    errMsg: 'Could not delete material',
  }),
  massDelete: (ids) => request({
    call: axios.delete('/materials', { params: { materialIds: ids } }),
    errMsg: 'Could not delete materials',
  }),
  update: (id, payload) => request({
    call: axios.put(`/materials/${id}`, { ...payload, device: 'web' }),
    errMsg: 'Could not update material',
  }),
  bulkUpdate: (payload) => request({
    call: axios.put('/materials/bulkupdate', payload),
    errMsg: 'Could not update materials',
  }),
  createFolder: (payload) => request({
    call: axios.post('/materials/folder', payload),
    errMsg: 'Could not create material folder',
  }),
  updateFolder: (id, payload) => request({
    call: axios.put(`/materials/folder/${id}`, payload),
    errMsg: 'Could not update material folder',
  }),
  move: (payload) => request({
    call: axios.put('/materials/move', payload),
    errMsg: 'Could not move materials',
  }),
  copy: (payload, lastUpdated) => request({
    call: axios.post('/materials/copy', { ...payload, device: 'web', timestamp: lastUpdated}),
    errMsg: 'Could not copy materials',
  }),
  getLocations: (id) => request({
    call: axios.get(`/materials/${id}/locations`),
    errMsg: 'Could not get material locations',
    hideSuccessToast: true,
  }),
  massArchive: (payload) => request({
    call: axios.put('/materials/archive', payload),
    errMsg: 'Could not archive materials',
  }),
  getUpdateHistory: (id) => request({
    call: axios.get(`/materials/${id}/history`),
    errMsg: 'Could not get material update history',
    hideSuccessToast: true,
  }),
  togglePublic: (id, isPublic) => request({
    call: axios.put(`/materials/folder/${id}/public`, { public: isPublic }),
    errMsg: 'Failed to toggle folder public status',
    hideSuccessToast: true,
  }),
  getAccessList: (id) => request({
    call: axios.get(`/materials/folder/${id}/access`),
    errMsg: 'Failed to get material folder access list',
    hideSuccessToast: true,
  }),
  grantAccess: (payload) => request({
    call: axios.post('/materials/folder/access', payload),
    errMsg: 'Failed to grant material folder access',
    hideSuccessToast: true,
  }),
  revokeAccess: (payload) => request({
    call: axios.delete('/materials/folder/access', { data: payload }),
    errMsg: 'Failed to revoke material folder access',
    hideSuccessToast: true,
  })
};
