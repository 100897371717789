import React, {
  useState, useCallback, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import ProgressUpdateInput from './ProgressUpdateInput';
import { getProgressFromState } from './progressHelpers';
import { updateProgress } from '../../reports/state/reports.actions';

export default function ProgressMaterialUpdate({
  id,
  projectId,
  phaseId,
  costcodeId,
  units,
  estimatedQuantity,
  hasWrite,
}) {
  const dispatch = useDispatch();

  const progressState = useSelector((state) => state.reports.progress);
  const {
    projectCostcodeDetails: taskState,
    projectProgressFilters,
  } = useSelector((state) => state.projects);

  const { quantityUsed } = useMemo(() => (
    getProgressFromState({
      progressState,
      taskState,
      id,
      projectId,
      phaseId,
      costcodeId,
    })
  ), [
    progressState,
    taskState,
    id,
    projectId,
    phaseId,
    costcodeId,
  ]);

  const [showPopover, setShowPopover] = useState(false);
  const [value, setValue] = useState(quantityUsed);

  const onConfirm = useCallback(async () => {
    if (await dispatch(updateProgress({
      projectId, phaseId, costcodeId, quantityUsed: value,
    }))) {
      setShowPopover(false);
    }
  }, [value, dispatch, projectId, phaseId, costcodeId]);

  const onCancel = useCallback(() => {
    setShowPopover(false);
    setValue(quantityUsed);
  }, [quantityUsed]);

  const onChange = useCallback((newValue) => {
    setShowPopover(true);
    setValue(newValue);
  }, []);

  useEffect(() => {
    setValue(quantityUsed);

    setTimeout(() => {
      setShowPopover(false);
    });
  }, [quantityUsed]);

  const isOver = estimatedQuantity && value && value > estimatedQuantity;
  const overStyle = isOver ? { color: 'red' } : {};
  return (
    <Row style={{ height: '100%' }} align="middle" gutter={10}>
      <Col style={{ height: 32 }}>
        {
          hasWrite
            ? (
              <ProgressUpdateInput
                min={projectProgressFilters?.dateRange ? Number.MIN_SAFE_INTEGER : 0}
                title="Update Material Usage"
                showPopover={showPopover}
                onConfirm={onConfirm}
                onCancel={onCancel}
                onChange={onChange}
                value={value}
                isOver={isOver}
                style={overStyle}
                disabled={!!projectProgressFilters?.dateRange}
              />
            )
            : value
        }

      </Col>
      {
        estimatedQuantity && (
          <Col
            style={{
              maxWidth: 80,
              fontSize: 12,
              ...overStyle,
            }}
          >
            {`/ ${estimatedQuantity}`}
          </Col>
        )
      }
      {
        units && (
          <Col style={{ maxWidth: 80, fontSize: 12 }}>
            {units}
          </Col>
        )
      }
    </Row>
  );
}

ProgressMaterialUpdate.propTypes = {
  id: PropTypes.string.isRequired,
  projectId: PropTypes.string,
  phaseId: PropTypes.string,
  costcodeId: PropTypes.string,
  units: PropTypes.string,
  estimatedQuantity: PropTypes.number,
  hasWrite: PropTypes.bool,
};

ProgressMaterialUpdate.defaultProps = {
  projectId: undefined,
  phaseId: undefined,
  costcodeId: undefined,
  units: undefined,
  estimatedQuantity: undefined,
  hasWrite: false,
};
