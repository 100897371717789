import React, { useState, useEffect } from 'react';
import { DateTime } from 'luxon';

import ClockText from '../common/clock/ClockText';

import colors from '../constants/Colors';
import { useInterval } from '../helpers/helpers';

const getRuntime = (subscriptionEnd) => {
  if(!subscriptionEnd) return;
  const now = DateTime.local();
  const graceEnd = subscriptionEnd.plus({ day: 1});
  let graceEndPST = graceEnd.setZone('UTC-8').startOf('day');
  if(graceEnd > graceEndPST) graceEndPST = graceEndPST.plus({ day: 1});
  const difference = graceEndPST.diff(now);
  return difference < 0 ? '00:00:00' : difference.toFormat('hh:mm:ss');
}

export default ({
  subscriptionEnd,
}) => {
  const [tickRate,setTickRate] = useState(null);
  const [runtime,setRuntime] = useState('00:00:00');

  useEffect(() => {
    const now = DateTime.local();
    if(subscriptionEnd && subscriptionEnd < now) {
      setTickRate(900);
      setRuntime(getRuntime(subscriptionEnd));
    }
  },[ subscriptionEnd ]);

  useInterval(() => {
    setRuntime(getRuntime(subscriptionEnd));
  },[ tickRate ]);
  return (
    <ClockText 
      runtime={runtime}
      color={runtime === '00:00:00' ? 'red' : colors.ONTRACCR_BLACK}
    />
  );
};