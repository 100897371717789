import {
  GET_PROFILE,
  UPDATE_PROFILE,
  CHANGE_PASSWORD,
  CONNECT_GMAIL,
  DISCONNECT_GMAIL,
  GET_GMAIL_CODE,
  CYCLE_GMAIL_CODE,
} from '../../state/actionTypes';
import ProfileService from './profile.service';


export const getProfile = () => async (dispatch) => {
  const profile = await ProfileService.get();
  dispatch({
    type: GET_PROFILE,
    payload: {
      profile,
    },
  });
};

export const updateProfile = (details) => async (dispatch) => {
  const { data } = await ProfileService.update(details);
  if (!data) return false;
  dispatch({
    type: UPDATE_PROFILE,
    payload: {
      profile: details,
    },
  });
  return true;
};

export const changePassword = (payload) => async (dispatch) => {
  const { data } = await ProfileService.changePassword(payload);
  if (!data) return false;
  dispatch({
    type: CHANGE_PASSWORD,
  });
  return true;
};

export const connectGmail = (payload) => async (dispatch) => {
  const { data } = await ProfileService.connectGmail(payload);
  if (!data) return false;
  dispatch({
    type: CONNECT_GMAIL,
  });
  return true;
};

export const getGmailCode = () => async (dispatch) => {
  const { data: code } = await ProfileService.getGmailCode();
  if (!code) return false;
  dispatch({
    type: GET_GMAIL_CODE,
    payload: { code },
  });
  return true;
};

export const cycleGmailCode = () => async (dispatch) => {
  const { data: code } = await ProfileService.cycleGmailCode();
  if (!code) return false;
  dispatch({
    type: CYCLE_GMAIL_CODE,
    payload: { code },
  });
  return true;
};


export const disconnectGmail = () => async (dispatch) => {
  const { data } = await ProfileService.disconnectGmail();
  if (!data) return false;
  dispatch({
    type: DISCONNECT_GMAIL,
  });
  return true;
};

export default {};
