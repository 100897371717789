import React, {
  useCallback, useState, useMemo, useEffect,
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { Drawer, Form, Select } from 'antd';

import DrawerSubmitFooter from '../../common/containers/DrawerSubmitFooter';
import FormTextInput from '../../common/inputs/FormTextInput';

import DivisionSelector from '../../common/inputs/DivisionSelector';

import {
  createTablet,
  updateTablet,
} from '../../tablets/state/tablets.actions';

const validateUser = ({ id, tablets = [] }) => (_, newUser) => {
  if (tablets.some((t) => newUser && t.users?.[0] === newUser && t.id !== id)) {
    return Promise.reject(new Error('User is already assigned to a Tablet Account'));
  }
  return Promise.resolve();
};

function TabletSettingsDrawer({
  selectedTablet,
  visible,
  onClose,
}) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const projects = useSelector((state) => state.projects.projects);
  const teams = useSelector((state) => state.teams.teams);
  const users = useSelector((state) => state.users.users);
  const userDivisions = useSelector((state) => state.users.userDivisions);

  const tablets = useSelector((state) => state.tablets);

  const [divisionId, setDivisionId] = useState();
  const [loading, setLoading] = useState(false);

  const onValuesChange = useCallback((_, allValues) => {
    const { divisionId: newDivisionId } = allValues;
    if (divisionId === newDivisionId) return;
    setDivisionId(newDivisionId);
    form.setFieldsValue({
      ...allValues,
      projects: [],
      teams: [],
    });
  }, [divisionId, form]);

  const onSubmit = useCallback(async () => {
    try {
      await form.validateFields();
      const values = form.getFieldsValue();

      setLoading(true);
      let passed = false;
      if (selectedTablet) {
        const payload = { ...values };
        if (!values.divisionId) {
          payload.divisionId = null; // Allow clearing
        }
        passed = await dispatch(updateTablet(selectedTablet.id, payload));
      } else {
        passed = await dispatch(createTablet(values));
      }
      setLoading(false);
      if (passed) onClose();
    } catch (err) {
      // fail silently
    }
  }, [onClose, form, selectedTablet]);

  const projectOptions = useMemo(() => (
    projects.filter((project) => project.active && project.divisionId === divisionId)
      .map((project) => ({ label: project.name, value: project.id }))
  ), [divisionId, projects]);

  const teamOptions = useMemo(() => (
    teams.filter((team) => team.active && team.divisionId === divisionId)
      .map((team) => ({ label: team.name, value: team.id }))
  ), [divisionId, teams]);

  const userOptions = useMemo(() => (
    users.filter((user) => (
      user.active && !!userDivisions?.[user.id]?.find((divId) => divId === divisionId)
    )).map((user) => ({ label: user.name, value: user.id }))
  ), [divisionId, users, userDivisions]);

  useEffect(() => {
    if (!visible || !selectedTablet) {
      setDivisionId();
      form.resetFields();
    } else {
      form.setFieldsValue(selectedTablet);
      setDivisionId(selectedTablet.divisionId);
    }
  }, [visible, selectedTablet, form]);

  const { id } = selectedTablet ?? {};

  return (
    <Drawer
      width={500}
      onClose={onClose}
      visible={visible}
      title={selectedTablet ? `Edit ${selectedTablet.username}` : 'Add Tablet'}
      bodyStyle={{ padding: '0px 24px' }}
    >
      <Form
        form={form}
        layout="vertical"
        onValuesChange={onValuesChange}
        initialValues={selectedTablet}
      >
        <FormTextInput
          isNotDisplay
          label="Username"
          name="username"
          rules={[{ required: true, message: 'Username is required' }]}
          placeholder="Enter Username"
          textProps={{ autoComplete: 'chrome-off' }}
        />
        <FormTextInput
          isNotDisplay
          label="Password"
          name="password"
          rules={[{ required: !selectedTablet, message: 'Password is required' }]}
          placeholder="Enter Password"
          password
          textProps={{ autoComplete: 'chrome-off' }}
        />
        <Form.Item
          name="divisionId"
          label="Division"
          style={{ margin: '20px 0xp' }}
          valuePropName="divisionId"
          labelCol={{
            style: {
              paddingBottom: 5,
              marginTop: 10,
            },
          }}
        >
          <DivisionSelector />
        </Form.Item>

        <Form.Item
          name="projects"
          label="Projects"
          labelCol={{
            style: {
              paddingBottom: 5,
              marginTop: 10,
            },
          }}
        >
          <Select
            options={projectOptions}
            mode="multiple"
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="teams"
          label="Teams"
          labelCol={{
            style: {
              paddingBottom: 5,
              marginTop: 10,
            },
          }}
        >
          <Select
            options={teamOptions}
            mode="multiple"
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          name="users"
          label="Assigned User"
          labelCol={{
            style: {
              paddingBottom: 5,
              marginTop: 10,
            },
          }}
          rules={[
            { validator: validateUser({ id, tablets }) },
          ]}
        >
          <Select
            options={userOptions}
            mode="single"
            optionFilterProp="label"
            allowClear
          />
        </Form.Item>
      </Form>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        loading={loading}
      />
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
TabletSettingsDrawer.propTypes = {
  selectedTablet: PropTypes.shape({
    id: PropTypes.string,
    username: PropTypes.string,
    divisionId: PropTypes.string,
    users: PropTypes.array,
  }),
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

TabletSettingsDrawer.defaultProps = {
  selectedTablet: null,
};

export default TabletSettingsDrawer;
