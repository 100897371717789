import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { List } from 'antd';

import FullPhoto from '../../common/photos/FullPhoto';

import BoardCardEmailThreadDrawer from './BoardCardEmailThreadDrawer';
import BoardCardEmailThread from './BoardCardEmailThread';

import { getCardEmailFile } from '../state/boards.actions';

import { downloadFile, getFileType } from '../../files/fileHelpers';


export default ({ visible, emails = [] }) => {
  const dispatch = useDispatch();
  const cardEmailFiles = useSelector(state => state.boards.cardEmailFiles);

  const [selectedFileId, setSelectedFileId] = useState();
  const [selectedThread, setSelectedThread] = useState();

  const selectedFile = useMemo(() => {
    const {
      [selectedFileId]: { jsFileObject } = {},
    } = cardEmailFiles;
    return jsFileObject;
  },[cardEmailFiles, selectedFileId]);

  const sortedEmails = useMemo(() => {
    const sorted = [...emails];
    sorted.sort((a,b) => b.timestamp - a.timestamp);
    return sorted;
  },[emails]);
  

  const onFileClick = useCallback((thread) => async (file) => {
    const { attachmentId } = file;
    const { id: threadId } = thread;
    if (!(attachmentId in cardEmailFiles)) {
      await dispatch(getCardEmailFile({ file, threadId }))
    }
    setSelectedFileId(attachmentId);
  },[dispatch, cardEmailFiles, selectedThread]);

  const onThreadSelect = useCallback((thread) => () => {
    setSelectedThread(thread);
  },[]);

  const closeSelectedThread = useCallback(() => {
    setSelectedThread(false);
  },[]);

  const onDownload = useCallback(() => {
    downloadFile({ fileObject: selectedFile });
  },[selectedFile]);

  const onClose = useCallback(() => setSelectedFileId(),[]);

  useEffect(() => {
    if (!visible) setSelectedFileId();
  },[visible]);

  const type = useMemo(() => selectedFile ? getFileType(selectedFile) : null,[selectedFile]);

  return (
    <>
      <List
        className='board-card-emails'
        dataSource={sortedEmails}
        renderItem={(item) => (
          <BoardCardEmailThread
            {...item}
            onFileClick={onFileClick(item)}
            onClick={onThreadSelect(item)}
          />
        )}
      />
      <FullPhoto
        onDownload={onDownload}
        file={selectedFile}
        onClose={onClose}
        type={type}
      />
      <BoardCardEmailThreadDrawer
        {...selectedThread}
        visible={!!selectedThread}
        onClose={closeSelectedThread}
      />
    </>
  )
}