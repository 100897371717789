import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Row, Table } from 'antd';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import BorderlessButton from '../common/buttons/BorderlessButton';

import SettingsCardHeader from './SettingsCardHeader';
import CompanySettingsCard from './CompanySettingsCard';

import colors from '../constants/Colors';

const onHeaderCell = () => ({
  style: {
    backgroundColor: 'white',
  },
});

const BUTTON_STYLE = {
  paddingRight: 8,
  paddingLeft: 0,
};

function SettingsAddCard({
  dataSource = [],
  onAddClicked,
  onEditClicked,
  onDeleteClicked,
  children,
  title,
  style = {},
}) {
  const columns = useMemo(() => [{
    title: 'Name',
    dataIndex: 'title',
    onHeaderCell,
  },
  {
    dataIndex: 'edit',
    align: 'edit',
    onHeaderCell,
    width: 60,
    render: (_, record) => (
      <BorderlessButton
        title=""
        icon="edit"
        color={colors.ONTRACCR_BLACK}
        onClick={() => {
          onEditClicked(record);
        }}
        style={BUTTON_STYLE}
      />
    ),
  },
  {
    dataIndex: 'action',
    key: 'action',
    align: 'center',
    width: 60,
    onHeaderCell,
    render: (_, record) => (
      <BorderlessButton
        title=""
        icon="delete"
        color="red"
        onClick={() => {
          onDeleteClicked(record);
        }}
        style={BUTTON_STYLE}
      />
    ),
  }], [onEditClicked, onDeleteClicked]);

  return (
    <Row
      id="divisions-container"
      style={{
        width: '100%',
        height: '100%',
        marginLeft: 180,
        marginTop: 30,
        ...style,
      }}
    >
      <SettingsCardHeader title={title}>
        <OnTraccrButton
          title="Add"
          type="primary"
          onClick={onAddClicked}
        />
      </SettingsCardHeader>
      <CompanySettingsCard
        style={{
          position: 'absolute',
          top: 130,
          bottom: 20,
          padding: 0,
          height: 'auto',
        }}
      >
        <Table
          columns={columns}
          dataSource={dataSource}
          pagination={false}
          size="small"
          scroll={{ y: 'calc(100vh - 220px', x: 'hidden' }}
          rowKey="id"
        />
      </CompanySettingsCard>
      {children}
    </Row>
  );
}

SettingsAddCard.propTypes = {
  title: PropTypes.string.isRequired,
  dataSource: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  })),
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.node,
  ]),
  style: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  onAddClicked: PropTypes.func.isRequired,
  onEditClicked: PropTypes.func.isRequired,
  onDeleteClicked: PropTypes.func.isRequired,
};

SettingsAddCard.defaultProps = {
  dataSource: [],
  children: [],
  style: {},
};

export default SettingsAddCard;
