import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isMobile, useMobileOrientation } from 'react-device-detect';
import { Steps } from 'antd';
import { CheckCircleFilled, CheckCircleOutlined, MinusCircleOutlined } from '@ant-design/icons';

export default ({
  statuses,
  activeStatusId,
  selectedBoardId,
}) => {
  const {
    accentColor,
    boards,
  } = useSelector(state => state.clientPortal.settings);
  const { isPortrait } = useMobileOrientation();

  const isMobilePortrait = isMobile && isPortrait;

  const activeIndex = useMemo(() => {
    const idx = statuses.findIndex((status) => status.id === activeStatusId);
    return idx < 0 ? statuses.length : idx;
  },[statuses, activeStatusId]);

  const { statusMappings = {} } = useMemo(() => (
    (boards ?? []).find((board) => board.boardId === selectedBoardId) ?? {}
  ),[boards, selectedBoardId]);

  const {
    steps,
    description,
    title,
  } = useMemo(() => {
    let displayDescription = null;
    let displayTitle = null;
    const displaySteps = statuses.map((status, idx) => {
      const { id: statusId, title } = status;
      const {
        [statusId]: {
          portalTitle = title,
          description,
        } = {},
      } = statusMappings;

      let stepStatus = 'wait';
      let textStyle = {};
      let icon = <MinusCircleOutlined />;
      if (idx < activeIndex) {
        stepStatus = 'finish';
        icon = <CheckCircleOutlined style={{ color: accentColor }}/>;
        textStyle = { color: accentColor }
      } else if (idx === activeIndex) {
        stepStatus = 'process';
        icon = <CheckCircleFilled style={{ color: accentColor }}/>;
        textStyle = { color: accentColor }
        displayDescription = description;
        displayTitle = portalTitle;
      }
      return (
        <Steps.Step
          key={statusId}
          status={stepStatus}
          icon={icon}
          {
            ...(isMobilePortrait && idx === activeIndex)
            ? { title: displayTitle, description: displayDescription }
            : {}
          }
        />
      )
    });
    return {
      steps: displaySteps,
      description: displayDescription,
      title: displayTitle,
    };
  },[statuses, statusMappings, accentColor, activeIndex, isMobilePortrait]);

  const {
    marginLeft,
    textAlign,
  } = useMemo(() => {
    const numSteps = steps.length;
    let ta = 'left';
    let ml = 0;
    if (numSteps > 1) {
      if (activeIndex > 0 && activeIndex < numSteps - 1) {
        /*
          Calculate margin-left for the text based off number of steps and position
          Body has 20px horizontal padding (40px total) + 32px for the last step icon.
          Each segment is (100vw - 72px) / (${numSteps} - 1) width
          +12px offsets the view to the center of the icon
          -150px offsets the text view (width 300px) so that text is center aligned under icon
          min/max bound the view so it doesnt go offscreen.
        */
        ta = 'center';
        ml = `calc(
          min(
            max(
              (${activeIndex} * (100vw - 72px) / (${numSteps} - 1)) + 12px - 150px,
              0px
            ),
            100vw - 340px
          )
        )`;
      } else if (activeIndex === numSteps - 1) {
        ta = 'right';
        ml = 'calc(100vw - 340px)'
      }
    }

    return { marginLeft: ml, textAlign: ta };
  },[steps, activeIndex]);

  return (
    <>
      <Steps
        className='client-portal-progress'
        direction={isMobilePortrait ? 'vertical' : 'horizontal'}
      >{steps}
      </Steps>
      {!isMobilePortrait &&
        <div className='client-portal-progress-text-container' style={isMobilePortrait ? {} : { marginLeft, textAlign }}>
          <div className='client-portal-progress-title'>
            {title}
          </div>
          <div className='client-portal-progress-description'>
            {description}
          </div>
        </div>
      }
    </>
  );
}