import React, { useState, useEffect, useCallback } from 'react';
import { Row, Col } from 'antd';
import CustomModal from '../common/modals/CustomModal';
import OnTraccrTextInput from '../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DisplayText from '../common/text/DisplayText';

import colors from '../constants/Colors';

const errorText = `Folder names cannot contain the following characters: /, \\, [, ], \`, ~, #, {, }, |, <, >, %, ^`;
export default ({
  onClose,
  visible,
}) => {
  const [folderName,setFolderName] = useState();
  const [textIsInvalid,setTextIsInvalid] = useState(false);
  const onChange = useCallback((e) => {
    const {
      target:{
        value,
      } = {}
    } = e;
    setFolderName(value);
  },[]);
  const onCancel = useCallback(() => {
    onClose();
    setFolderName();
  },[onClose]);
  const onSave = useCallback(async () => {
    if(await onClose(folderName)) {
      setFolderName();
    }
  },[folderName,onClose]);

  useEffect(() => {
    if(folderName) {
      const isInvalid = folderName.match(/[\/\\\[\]%\~`\{}#|<>^]/g); //eslint-disable-line  no-useless-escape 
      setTextIsInvalid(isInvalid);
    }
  },[folderName]);

  return (
    <CustomModal
      title='Add Folder'
      visible={visible}
      hideCloseButton
      maskColor={colors.MODAL_MASK_DARK}
    >
      <Row style={{ margin: '20px 5px'}}>
        <OnTraccrTextInput
          placeholder='Enter a folder name'
          onChange={onChange}
          value={folderName}
        />
    </Row>
    <Row justify='center' gutter={12} style={{ marginBottom: 10 }}>
      <Col>
        <OnTraccrButton
          type='cancel'
          title='Cancel'
          roundStyle
          onClick={onCancel}
        />
      </Col>
      <Col>
        <OnTraccrButton
          title='Save'
          roundStyle
          disabled={!folderName || textIsInvalid}
          onClick={onSave}
        />
      </Col>
    </Row>
    <Row>
      <DisplayText 
        style={{
          color:'red',
          height:textIsInvalid ? 'auto': 0,
          opacity:textIsInvalid ? 1 : 0,
        }}
        title={errorText}
      />
    </Row>
    </CustomModal>
  );
}