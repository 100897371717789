import { isNullOrUndefined, getIdMap } from '../helpers/helpers';
import { NONE_EQUIPMENT_TYPE } from './equipmentFilters';

const equipmentFieldToTextMap = {
  name: 'Name',
  code: 'Code',
  statusId: 'Status',
  userId: 'User',
  dailyCost: 'Daily Cost',
  dailyBillingRate: 'Daily Billing Rate',
  hourlyCost: 'Hourly Cost',
  hourlyBillingRate: 'Hourly Billing Rate',
  equipmentTypeId: 'Equipment Type',
  divisionIds: 'Divisions',
  projects: 'Projects',
  labels: 'Labels',
};

const getItemsString = (arr = [], map = {}, key = 'name', type = 'added') => {
  let str;
  if (arr.length) {
    str = `${type} `;
    const arrValues = arr.map((id) => map[id]?.[key] ?? 'Unknown');
    const arrString = arrValues.join(', ');
    str += arrString;
  }
  return str;
};

const getAddedRemovedString = (type, addedArr = [], removedArr = [], map = {}, key = 'name') => {
  const addedStr = getItemsString(addedArr, map, key, 'added');
  const removedStr = getItemsString(removedArr, map, key, 'removed');
  const infix = (addedStr && removedStr) ? ' and ' : '';
  const suffix = removedStr ? ' from ' : ' to ';
  return `${addedStr ?? ''}${infix}${removedStr ?? ''}${suffix}${equipmentFieldToTextMap[type] ?? type}`;
};

const getCustomUpdateText = ({
  metadata,
}) => {
  const {
    type,
    title,
    old,
    new: newVal,
  } = metadata;

  switch (type) {
    case 'text':
    case 'attribute': {
      const { value: oldText } = old ?? {};
      const { value: newText } = newVal ?? {};
      const oldExists = (!isNullOrUndefined(oldText) && oldText !== '');
      const newExists = (!isNullOrUndefined(newText) && newText !== '');
      if (oldExists && newExists) {
        return `updated the ${title} from ${oldText} to ${newText}`;
      }
      if (oldExists && !newExists) {
        return `deleted the value of ${oldText} from ${title}`;
      }
      return `updated ${title} to ${newText}`;
    }
    case 'yes-no': {
      const { value } = newVal ?? {};
      if (typeof value === 'boolean') {
        return `updated ${title} to ${value}`;
      }
      return `updated ${title} to N/A`;
    }
    case 'dropdown': {
      const { values: oldDropdown = [] } = old ?? {};
      const { values: newDropdown = [] } = newVal ?? {};
      const dropdownMap = getIdMap(oldDropdown.concat(newDropdown));
      const oldDropdownSet = new Set(oldDropdown.map(({ id }) => id));
      const newDropdownSet = new Set(newDropdown.map(({ id }) => id));
      const addedDropdown = newDropdown.filter(({ id }) => !oldDropdownSet.has(id))
        .map(({ id }) => id);
      const removedDropdown = oldDropdown.filter(({ id }) => !newDropdownSet.has(id))
        .map(({ id }) => id);
      return getAddedRemovedString(title, addedDropdown, removedDropdown, dropdownMap, 'name');
    }
    default: {
      return `updated ${title}`;
    }
  }
};

export const getEquipmentUpdateText = ({
  metadata,
  userMap,
  equipmentTypesMap,
  statuses,
  projectMap,
  divisions,
  labelMap,
}) => {
  const {
    key,
    old,
    new: newVal,
    addedProjects = [],
    deletedProjects = [],
    addedLabels = [],
    deletedLabels = [],
  } = metadata;

  switch (key) {
    case 'statusId': {
      return `updated the status from ${statuses[old]?.status ?? 'Unknown'} to ${statuses[newVal]?.status ?? 'Unknown'}`;
    }
    case 'userId': {
      const {
        [old]: { name: oldAssigned = 'Unknown' } = {},
        [newVal]: { name: newAssigned = 'Unknown' } = {},
      } = userMap;
      const oldExists = (!isNullOrUndefined(old) && old !== '');
      const newExists = (!isNullOrUndefined(newVal) && newVal !== '');
      if (oldExists && newExists) {
        return `updated the assigned user from ${oldAssigned} to ${newAssigned}`;
      }
      if (oldExists && !newExists) {
        return `removed the assigned user: ${oldAssigned}`;
      }
      return `added ${newAssigned} as an assigned user`;
    }
    case 'equipmentTypeId': {
      let oldId = old;
      let newId = newVal;
      if (old === null) oldId = NONE_EQUIPMENT_TYPE;
      if (newVal === null) newId = NONE_EQUIPMENT_TYPE;
      return `updated the equipment type from ${equipmentTypesMap[oldId]?.name ?? 'Unknown'} to ${equipmentTypesMap[newId]?.name ?? 'Unknown'}`;
    }
    case 'divisionIds': {
      const oldDivisionsSet = new Set(old ?? []);
      const newDivisionsSet = new Set(newVal ?? []);
      const addedDivisions = (newVal ?? []).filter((divisionId) => (
        !oldDivisionsSet.has(divisionId)
      ));
      const removedDivisions = (old ?? []).filter((divisionId) => !newDivisionsSet.has(divisionId));
      return getAddedRemovedString(key, addedDivisions, removedDivisions, divisions, 'name');
    }
    case 'projects': {
      return getAddedRemovedString(key, addedProjects, deletedProjects, projectMap, 'name');
    }
    case 'labels': {
      return getAddedRemovedString(key, addedLabels, deletedLabels, labelMap, 'title');
    }
    case 'name':
    case 'code':
    case 'dailyCost':
    case 'dailyBillingRate':
    case 'hourlyCost':
    case 'hourlyBillingRate': {
      const oldExists = (!isNullOrUndefined(old) && old !== '');
      const newExists = (!isNullOrUndefined(newVal) && newVal !== '');
      if (oldExists && newExists) {
        return `updated the ${equipmentFieldToTextMap[key]} from ${old} to ${newVal}`;
      }
      if (oldExists && !newExists) {
        return `deleted the value of ${old} from ${equipmentFieldToTextMap[key]}`;
      }
      return `updated the ${equipmentFieldToTextMap[key]} to ${newVal}`;
    }
    default: {
      return getCustomUpdateText({ metadata });
    }
  }
};

export default {};
