import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Table, Tag } from 'antd';

import { PropTypes } from 'prop-types';
import sortByString from '../../helpers/helpers';

const columns = [
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Active',
    dataIndex: 'active',
    key: 'active',
    align: 'center',
    width: 100,
    filters: [{ text: 'Active', value: 1 }, { text: 'Archived', value: 0 }],
    onFilter: (value, record) => record.active === value,
    render: (_, record) => (
      <Tag className={record.active ? 'division-active-tag' : 'division-inactive-tag'}>
        {record.active ? 'Active' : 'Archived'}
      </Tag>
    ),
  },
];

export default function DivisionTable({ data = [], type, onSelectChange }) {
  const divisionCount = useSelector((state) => Object.values(state.settings.divisions).length);
  const ownerId = useSelector((state) => {
    const {
      settings: {
        company: {
          userId,
        } = {},
      } = {},
    } = state;
    return userId;
  });

  const hasMultiple = divisionCount > 1;

  const sortedData = useMemo(() => {
    const activeData = [];
    const archivedData = [];
    data
      .filter((item) => !!item)
      .sort(sortByString('name'))
      .forEach((item) => {
        if (item.active) {
          activeData.push(item);
        } else {
          archivedData.push(item);
        }
      });

    return activeData.concat(archivedData);
  }, [data]);

  return (
    <div style={{ height: '100%', overflowY: 'hidden' }}>
      <Table
        style={{ height: '100%', paddingLeft: hasMultiple ? 0 : 10 }}
        columns={columns}
        dataSource={sortedData}
        size="small"
        pagination={false}
        scroll={{
          y: 'calc(100vh - 310px)',
        }}
        rowSelection={
          hasMultiple
            ? {
              fixed: true,
              onChange: onSelectChange,
              getCheckboxProps: (record) => ({
                disabled: type === 'users' && record.id === ownerId,
              }),
            }
            : null
        }
        rowKey="id"
      />
    </div>
  );
}

DivisionTable.propTypes = {
  data: PropTypes.array,
  type: PropTypes.string.isRequired,
  onSelectChange: PropTypes.func.isRequired,
};

DivisionTable.defaultProps = {
  data: [],
};
