import React, { useState } from 'react';
import OnTraccrButton from '../common/buttons/OnTraccrButton';

import BoardDeleteModal from './BoardDeleteModal';

export default ({
  title,
  onDelete,
}) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <>
      <OnTraccrButton
        title='Delete'
        onClick={() => setShowModal(true)}
        type='back'
        style={{
          position:'absolute',
          left: 0,
          bottom:0,
          margin: '10px 16px',
        }}
      />
      <BoardDeleteModal
        title={`Delete ${title}?`}
        visible={showModal}
        onClose={() => setShowModal(false)}
        onDelete={onDelete}
      />
    </>
  )
}