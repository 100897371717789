import { React, useCallback, useEffect, useMemo } from 'react';
import { PropTypes } from 'prop-types';
import { Select, Table, Typography } from 'antd';

import CompanySettingsCard from '../CompanySettingsCard';
import { useDispatch, useSelector } from 'react-redux';
import { getType } from '../../forms/FormWorkflows/workflowHelpers';
import { getCustomFields } from '../../timecards/state/timecards.actions';

const cols = (divisionMap, divisionMultisigFields, summaryExportFields, onFieldChange) => [{
  title: 'Division',
  dataIndex: 'divisionId',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
  render: (divisionId) => divisionMap[divisionId]?.name,
}, {
  title: 'Field',
  dataIndex: 'fieldId',
  onHeaderCell: () => ({
    style: {
      backgroundColor: 'white',
    },
  }),
  render: (_, record) => (
    <Select
      options={divisionMultisigFields[record.divisionId]}
      optionFilterProp="label"
      value={summaryExportFields[record.divisionId]}
      onChange={onFieldChange(record.divisionId)}
      style={{ width: 200 }}
      allowClear
      placeholder="None"
    />
  ),
}];
function SummaryExport({
  visible,
  settings: {
    summaryExportFields = {},
  } = {},
  saveCompanySettings,
}) {
  const dispatch = useDispatch();
  const divisions = useSelector((state) => state.settings.divisions);
  const customFields = useSelector((state) => state.timecards.customFields);

  const divisionMultisigFields = useMemo(() => (
    Object.entries(customFields).reduce((acc, [divisionId, { fields: sections = [] }]) => {
      acc[divisionId] = sections.map(({ fields = [] }) => (
        fields.filter((field) => {
          const type = getType(field);
          return type === 'multiSig';
        })
      ))
        .flat()
        .map((field) => {
          const {
            configProps: { title: fieldTitle } = {},
            id: fieldId,
          } = field;
          return { label: fieldTitle, value: fieldId };
        });
      return acc;
    }, {})
  ), [customFields]);

  const onFieldChange = useCallback((divisionId) => (value) => {
    saveCompanySettings({
      summaryExportFields: {
        ...summaryExportFields,
        [divisionId]: value,
      },
    });
  }, [saveCompanySettings, summaryExportFields]);

  const data = useMemo(() => Object.keys(divisions)
    .map((divisionId) => ({ divisionId })), [divisions]);

  const columns = useMemo(() => cols(
    divisions,
    divisionMultisigFields,
    summaryExportFields,
    onFieldChange,
  ), [divisions, divisionMultisigFields, summaryExportFields, onFieldChange]);

  useEffect(() => {
    if (visible) {
      dispatch(getCustomFields());
    }
  }, [dispatch, visible]);

  return (
    <CompanySettingsCard
      title="Summary Export"
    >
      <div
        style={{ marginTop: 10 }}
      >
        <Typography.Text>
          Configure the multi-signature field included per-division
        </Typography.Text>
      </div>
      <Table
        columns={columns}
        dataSource={data}
        size="small"
        pagination={false}
        style={{
          width: '100%',
          height: '100%',
          borderRadius: 8,
        }}
        rowKey="id"
      />
    </CompanySettingsCard>
  );
}

SummaryExport.propTypes = {
  visible: PropTypes.bool,
  settings: PropTypes.shape({}),
  saveCompanySettings: PropTypes.func.isRequired,
};
SummaryExport.defaultProps = {
  settings: {},
  visible: false,
};

export default SummaryExport;
