import React from 'react';
import { useSelector } from 'react-redux';
import { Popover } from 'antd';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import WarningHeader from '../common/text/WarningHeader';

import Permissions from '../auth/Permissions';

import { storageUpgradeWarning, MB } from './fileHelpers';

export default (props) => {
  const newProps = {...props};
  const history = newProps.history;
  delete newProps.history;

  const {
    isOwner,
    documentManagementEnabled,
    overThresh,
  } = useSelector((state) => {
    const {
      settings: {
        company: {
          userId:ownerId,
          documentManagementEnabled,
          storageUsed = 0,
          storageLimit = 100 * MB,
        } = {},
      } = {},
    } = state;
    return {
      isOwner: ownerId === Permissions.id,
      documentManagementEnabled,
      overThresh: storageUsed >= storageLimit,
    };
  });
  

  if (documentManagementEnabled && !overThresh) return <OnTraccrButton {...props} />;
  const warningOpts = { 
    isOwner,
    history,
    title: (
      !documentManagementEnabled
      ? 'You need to upgrade your account to access this feature'
      : 'Your account has reached its storage limit'
    ),
  };
  return (
    <Popover
      placement='bottomRight'
      trigger='click'
      title={<WarningHeader/>}
      content={storageUpgradeWarning(warningOpts)}
    >
      <OnTraccrButton {...props} onClick={null}/>
    </Popover>
  )
}
