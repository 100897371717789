import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import * as Sentry from '@sentry/react';

import FormLinks from './FormLinks';

function FormTriggeredItems({
  formId,
  visible,
}) {
  const [triggeredItems, setTriggeredItems] = useState({});
  const [loading, setLoading] = useState([]);

  const getLinks = async () => {
    try {
      setLoading(true);
      const {
        data = [],
      } = await axios.get(`/forms/${formId}/links`, { params: { getShifts: true } });
      setTriggeredItems(data);
    } catch (err) {
      setTriggeredItems({});
      Sentry.withScope(() => {
        Sentry.captureException(err);
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    if (formId && visible) {
      getLinks();
    } else {
      setTriggeredItems({});
    }
  }, [visible, formId]);

  return (
    <FormLinks
      formId={formId}
      triggeredItems={triggeredItems}
      loading={loading}
      onShiftChanged={getLinks}
    />
  );
}

FormTriggeredItems.propTypes = {
  formId: PropTypes.string,
  visible: PropTypes.bool.isRequired,
};

FormTriggeredItems.defaultProps = {
  formId: null,
};

export default FormTriggeredItems;
