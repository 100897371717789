import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import { Drawer, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import { createFolders } from '../files/state/files.actions';

export default function ProjectAddFileTemplate({
  divisionId,
  applyTemplate,
  hasFiles,
  rootPath,
}) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const templates = useSelector((state) => state.projects.projectFileTemplates);
  const [showDrawer, setShowDrawer] = useState(false);

  const ourTemplates = useMemo(() => (templates
    ? templates.filter((t) => t.divisionId === divisionId)
    : []
  ), [divisionId, templates]);

  const onSelectTemplate = useCallback((template) => () => {
    // Format data and send to ProjectAddView
    const {
      data,
    } = template;
    const newData = {};
    const parsedData = JSON.parse(data);
    if (rootPath && !parsedData[rootPath]) {
      const newRootPath = rootPath.replace(/^\/files\//, '');
      for (let key in parsedData) {
        newData[`${newRootPath}${key}/`] = {
          ...parsedData[key],
          fullPath: newRootPath + parsedData[key].fullPath,
        };
      }
      delete newData.newRootPath;
      dispatch(createFolders({ names: Object.keys(newData) }));
    } else {
      applyTemplate(parsedData);
    }
    setShowDrawer(false);
  }, [applyTemplate]);

  const onAddTemplate = useCallback(() => {
    setShowDrawer(true);
  }, []);

  const onClose = useCallback(() => {
    setShowDrawer(false);
  });

  const renderTemplate = useCallback((item) => (
    <List.Item
      key={item.id}
      extra={(
        <div style={{ height: '100%' }}>
          <OnTraccrButton
            title="Apply"
            onClick={onSelectTemplate(item)}
          />
        </div>
      )}
    >
      <List.Item.Meta
        title={item.name}
      />
      <div>{item.description}</div>
    </List.Item>
  ), [onSelectTemplate]);

  return (
    <>
      <OnTraccrButton
        title={`Apply ${t('Project')} File Template`}
        onClick={onAddTemplate}
        disabled={hasFiles}
      />
      <Drawer
        title={`Apply ${t('Project')} File Template to ${t('Project')}`}
        visible={showDrawer}
        onClose={onClose}
        width={400}
      >
        <List
          dataSource={ourTemplates}
          itemLayout="vertical"
          locale={{ emptyText: 'No templates found for this division' }}
          renderItem={renderTemplate}
        />
      </Drawer>
    </>
  );
}

ProjectAddFileTemplate.propTypes = {
  rootPath: PropTypes.string,
  divisionId: PropTypes.string.isRequired,
  applyTemplate: PropTypes.func.isRequired,
  hasFiles: PropTypes.bool.isRequired,
};
