import {
  GET_ALL_EVENTS,
  CREATE_EVENT,
} from '../../state/actionTypes';


const initialState = {
  events: [],
};

export default function eventsActions(state = initialState, action) {
  switch (action.type) {
    case GET_ALL_EVENTS:
    case CREATE_EVENT:
      return {
        ...state,
        events: action.payload.events,
      };
    default:
      return state;
  }
}
