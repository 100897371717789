import React, { useState, useEffect } from 'react';
import { Row,Col,Checkbox } from 'antd';

import CustomModal from '../../common/modals/CustomModal';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import OnTraccrButton from '../../common/buttons/OnTraccrButton';
import HoverHelp from '../../common/HoverHelp';
import DisplayText from '../../common/text/DisplayText';

const helpText = (
  <div style={{ width: 300, textOverflow:'wrap'}}>
    Public reports will be visible to all users that have 
    access to the base report type (e.g. Employee Payroll).
    <br/><br/>
    As owner of this saved report you can edit it or make it private at any time.
  </div>
);

const editHelpText = (
  <div style={{ width: 300, textOverflow:'wrap'}}>
    Clicking "Save a Copy" will create a new saved report, and leave the old one intact.
    <br/><br/>
    If you leave this unchecked, the original saved report will be overwritten.
  </div>
);


export default ({
  visible,
  onCloseClicked,
  onSave,
  isEdit,
  name:defaultName,
  isPublic:defaultPublic = false,
  reports = [],
  reportId,
}) => {

  const [error,setError] = useState();
  const [name,setName] = useState(defaultName);
  const [isPublic,setPublic] = useState(defaultPublic);
  const [saveCopy,setSaveCopy] = useState(false);

  useEffect(() => {
    setName(defaultName);
    setPublic(defaultPublic);
  },[visible, defaultName, defaultPublic]);

  useEffect(() => {
    const nameClash = reports.some((report) => (
      report.name === name && (report.id !== reportId || saveCopy)
    ));
    setError(nameClash ? `Report with name '${name}' already exists` : null);
  },[ name, reportId, saveCopy, reports ]);

  return (
    <CustomModal
      title={`${isEdit ?  'Edit' : 'Save'} Report?`}
      visible={visible}
      maskColor={'#00000073'}
      onCloseClicked={onCloseClicked}
      afterClose={() => null}
      getContainer={false}
    >
      <Row style={{ width: '100%', padding: 20,}} justify='center'>
        <OnTraccrTextInput
          placeholder='Enter Report Name'
          value={name}
          defaultValue={defaultName}
          onChange={(e) => {
            const {
              target:{
                value,
              } = {},
            } = e;
            setName(value);
          }}
          style={error ? { borderColor: 'red '} : {}}
        />
      <Row style={{ width: '100%', height: 30, paddingTop: 2}} justify='start'>
        <DisplayText
          title={error}
          style={{
            color:'red',
            marginBottom:0,
            paddingLeft:15,
            width:undefined
          }}
        />
      </Row>
        <Row align='middle' gutter={6} style={{ width: '100%', margin: '0px 20px 20px 20px' }} justify='space-between'>
          <Col>
            <Row align='midde' justify='start' gutter={6}>
              <Col>
                <Checkbox 
                  checked={isPublic}
                  onChange={(e) => {
                    const {
                      target: {
                        checked,
                      } = {},
                    } = e;
                    setPublic(checked);
                  }}
                >
                  Public
                </Checkbox>
              </Col>
              <Col>
                <HoverHelp 
                  content={helpText}
                  iconProps={{ 
                    style:{
                      fontSize:12,
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>
          {isEdit && <Col>
            <Row align='midde' justify='start' gutter={6}>
              <Col>
                <Checkbox 
                  checked={saveCopy}
                  onChange={(e) => {
                    const {
                      target: {
                        checked,
                      } = {},
                    } = e;
                    setSaveCopy(checked);
                  }}
                >
                  Save a Copy
                </Checkbox>
              </Col>
              <Col>
                <HoverHelp 
                  content={editHelpText}
                  iconProps={{ 
                    style:{
                      fontSize:12,
                    }
                  }}
                />
              </Col>
            </Row>
          </Col>}
        </Row>
        <Row style={{ width: '100%', marginBottom:10}} justify='space-around'>
          <Col>
            <OnTraccrButton
              title='Cancel'
              roundStyle
              type='cancel'
              onClick={onCloseClicked}
            />
          </Col>  
          <Col>
            <OnTraccrButton
              title='Confirm'
              roundStyle
              disabled={!name || error}
              onClick={async () => {
                const res = await onSave(name,isPublic,saveCopy);
                if(res) {
                  onCloseClicked();
                }
              }}
            />
          </Col>
        </Row>
      </Row>
    </CustomModal>
  )
}