import moment from 'moment';

export default {};

export const getLastTask = (tasks = [{}]) => tasks[tasks.length - 1] || {};
export const getActiveMember = ({
  user, projectId, timeEntryUserMap = {},
}) => {
  const tasks = timeEntryUserMap[user?.id] ?? [];
  const {
    startTime = moment().year(0).dayOfYear(0).valueOf(),
    projectId: taskProjectId,
  } = getLastTask(tasks);
  if (!startTime) return false;
  if (projectId && projectId !== taskProjectId) return false;
  if (user?.status === 'Clocked In') return true;
  const startMoment = moment(startTime);
  const now = moment();
  return startMoment.dayOfYear() === now.dayOfYear() && startMoment.year() === now.year();
};

export const initialsFromName = (name) => {
  const [first = ' ', last = ' '] = (name || '').split(' ').filter((word) => word && word.length);
  const initials = first[0] + last[0];
  return initials;
};
