import {
  GET_TUTORIALS,
} from '../../state/actionTypes';

const initialState = {
  tutorials: [],
};

export default function learningCenterActions(state = initialState, action) {
  switch (action.type) {
    case GET_TUTORIALS: {
      const {
        tutorials = [],
      } = action.payload;
      return {
        ...state,
        tutorials,
      };
    }
    default:
      return state;
  }
}
