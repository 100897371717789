import PropTypes from 'prop-types';
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, Drawer, Row, Select, Spin, Table,
} from 'antd';
import { DateTime } from 'luxon';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import DrawerSubmitFooter from '../../../common/containers/DrawerSubmitFooter';
import OnTraccrButton from '../../../common/buttons/OnTraccrButton';
import OnTraccrTextInput from '../../../common/inputs/OnTraccrTextInput';
import BorderlessButton from '../../../common/buttons/BorderlessButton';
import { getSageJobMappings, updateSageJobMappings } from '../../state/settings.actions';

export default function SageJobTypeDrawer({
  visible,
  onClose,
  selectedIntegration,
}) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const {
    sageJobTypeMappings: {
      [selectedIntegration?.id]: initialJobTypeMappings,
    } = {},
  } = useSelector((state) => state.settings);
  const stateProjectTypes = useSelector((state) => state.projects.projectTypes);

  const projectTypeOptions = useMemo(() => (
    stateProjectTypes.map((type) => ({ label: type.name, value: type.id }))
  ), [stateProjectTypes]);

  const [loading, setLoading] = useState();
  const [jobTypeMappings, setJobTypeMappings] = useState(initialJobTypeMappings ?? []);

  const addMapping = useCallback(() => {
    setJobTypeMappings([
      ...jobTypeMappings,
      {
        id: `${DateTime.local().toMillis()}`,
        isNew: true,
        type: 'prefix',
        value: '',
        projectTypeId: projectTypeOptions?.[0]?.value,
      },
    ]);
  }, [jobTypeMappings, projectTypeOptions]);

  const deleteMapping = useCallback((deleteId) => {
    const newMappings = jobTypeMappings.filter(({ id }) => id !== deleteId);
    setJobTypeMappings(newMappings);
  }, [jobTypeMappings]);

  const isValid = useMemo(() => (
    jobTypeMappings.every((mapping) => (
      mapping.type && mapping.value && mapping.projectTypeId
    ))
  ), [jobTypeMappings]);

  const onSubmit = useCallback(async () => {
    if (!isValid || !selectedIntegration) return false;
    await dispatch(updateSageJobMappings(selectedIntegration?.id, jobTypeMappings));
    onClose();
    return true;
  }, [selectedIntegration, isValid, jobTypeMappings]);

  useEffect(() => {
    const load = async () => {
      setLoading(true);
      await dispatch(getSageJobMappings(selectedIntegration.id));
      setLoading(false);
    };
    if (visible && selectedIntegration) {
      load();
    } else {
      setLoading(false);
    }
  }, [visible, selectedIntegration]);

  useEffect(() => {
    if (initialJobTypeMappings) setJobTypeMappings(initialJobTypeMappings);
  }, [initialJobTypeMappings]);

  const onValueChanged = useCallback((id, newData) => {
    setJobTypeMappings(
      jobTypeMappings.map((datum) => {
        if (datum.id !== id) return datum;
        return {
          ...datum,
          ...newData,
        };
      }),
    );
  }, [jobTypeMappings]);

  const columns = useMemo(() => ([
    {
      title: 'Type',
      dataIndex: 'type',
      width: 100,
      render: (type, record) => (
        <Select
          value={type}
          options={[
            { label: 'Prefix', value: 'prefix' },
            { label: 'Suffix', value: 'suffix' },
          ]}
          onChange={(newValue) => onValueChanged(record.id, { type: newValue })}
          style={{ width: '100%' }}
        />
      ),
    }, {
      title: 'Value',
      dataIndex: 'value',
      render: (value, record) => (
        <OnTraccrTextInput
          value={value}
          onChange={(e) => {
            const {
              target: {
                value: newValue,
              } = {},
            } = e;
            onValueChanged(record.id, { value: newValue });
          }}
          style={value === '' ? { color: 'red', borderColor: 'red' } : {}}
        />
      ),
    }, {
      title: `${t('Project')} Type`,
      dataIndex: 'projectTypeId',
      width: 200,
      render: (projectTypeId, record) => (
        <Select
          value={projectTypeId}
          options={projectTypeOptions}
          onChange={(newValue) => onValueChanged(record.id, { projectTypeId: newValue })}
          style={{ width: '100%' }}
          popupMatchSelectWidth={false}
        />
      ),
    }, {
      title: '',
      width: 70,
      render: (_, record) => (
        <BorderlessButton
          iconNode={<DeleteOutlined style={{ color: 'red' }} />}
          onClick={() => deleteMapping(record.id)}
        />
      ),
    },
  ]), [projectTypeOptions, onValueChanged]);

  return (
    <Drawer
      title={`Sage 100 Job to ${t('Project')} Type Mapping`}
      visible={visible}
      width={600}
      onClose={onClose}
      style={{
        height: '100%',
      }}
    >
      <Col style={{ width: '100%' }}>
        <Row justify="end">
          <OnTraccrButton title="Add" icon={<PlusOutlined />} onClick={addMapping} />
        </Row>
        <Row style={{ width: '100%' }}>
          {loading ? (
            <Spin />
          ) : (
            <Table
              dataSource={jobTypeMappings}
              columns={columns}
              pagination={false}
              size="small"
              rowKey="id"
              style={{
                width: '100%',
                position: 'relative',
                paddingTop: 10,
                paddingBottom: 50,
              }}
            />
          )}
        </Row>
      </Col>
      <DrawerSubmitFooter
        onClose={onClose}
        onSubmit={onSubmit}
        canSubmit={isValid}
      />
    </Drawer>
  );
}

SageJobTypeDrawer.propTypes = {
  onClose: PropTypes.func.isRequired,
  selectedIntegration: PropTypes.shape({
    id: PropTypes.string,
  }),
  visible: PropTypes.bool,
};

SageJobTypeDrawer.defaultProps = {
  selectedIntegration: {},
  visible: false,
};
