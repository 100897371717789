import { Table } from 'antd';
import { React, useCallback } from 'react';
import PropTypes from 'prop-types';
import BorderlessButton from '../../common/buttons/BorderlessButton';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';

import colors from '../../constants/Colors';
import CostCodeUploadList from '../../costcodes/costcodeUploadList';
import { sortByNumber } from '../../helpers/helpers';
import config from '../../config';
import {
  CATEGORIES,
  categoriesWithHours,
  costCodeCategoryMap,
  hasDates,
  totalCategoryValues,
} from '../projectHelpers';
import { currencyFormatter, currencyParser } from '../../helpers/inputParsers';
import VerticallyCenteredNumberInput from '../../common/inputs/VerticallyCenteredNumberInput';

const categoriesSet = new Set(CATEGORIES);

const title = () => 'Phases';
function ProjectBudgetTrackingPhases({
  isNotDisplay,
  dataSource,
  onEditPhaseCostCodes,
  onEditPhaseDetails,
  onRemove,
  openAddDrawer,
  setSelectedCostCodes,
  setNewPhaseDescription,
  setCodeRef,
  setNewPhaseCode,
  addPhaseCode,
  newPhaseCode,
  newPhaseDescription,
  useDates,
  selectedCostCodes,
  categories,
  style,
  useBuildEstimate,
}) {
  const ourData = (isNotDisplay ? [{ id: 'add' }] : []).concat(dataSource);
  const weHaveDates = hasDates(ourData);

  const onEstimateChanged = useCallback((phase, costcode, data) => {
    if (phase.id !== 'add') {
      // Editing existing
      const updatedPhaseCostCodes = phase.costcodes.map((cc) => {
        if (cc.id === costcode.id) {
          const updateObj = { ...cc, ...data };
          if ('hours' in data && !!useBuildEstimate) {
            const { hourlyWage = 0 } = costcode;
            const calculatedEstimatedCost = hourlyWage * (data.hours);
            updateObj.estimatedCost = calculatedEstimatedCost;
          }
          return updateObj;
        }
        return cc;
      });
      onEditPhaseCostCodes(phase.id, updatedPhaseCostCodes);
    } else {
      const updatedPhaseCostCodes = selectedCostCodes.map((cc) => {
        if (cc.id === costcode.id) {
          return {
            ...cc,
            ...data,
          };
        }
        return cc;
      });
      setSelectedCostCodes(updatedPhaseCostCodes);
    }
  }, [
    selectedCostCodes,
    onEditPhaseCostCodes,
    setSelectedCostCodes,
    useBuildEstimate,
  ]);

  const getCostCodeByIndex = useCallback(
    (index, record) => (index === 0
      ? selectedCostCodes
      : record.costcodes),
    [
      selectedCostCodes,
    ],
  );

  const getPhaseCostcodeLists = useCallback((record, index) => {
    const ourCodes = (index === 0 && isNotDisplay
      ? selectedCostCodes
      : record.costcodes
    ).sort(sortByNumber('code'));

    const categoryMap = costCodeCategoryMap(ourCodes);
    return CATEGORIES.map((categoryName) => {
      const {
        [categoryName]: {
          id: categoryId,
        } = {},
      } = categories;
      const {
        [categoryId]: categoryCostCodes = [],
      } = categoryMap;
      if (!categoryCostCodes.length) return null;
      return (
        <CostCodeUploadList
          key={categoryName}
          categories={categories}
          cost={categoriesSet.has(categoryName)}
          onEstimateChanged={
            isNotDisplay
              ? (costcode, value) => onEstimateChanged(record, costcode, value)
              : undefined
          }
          hours={categoriesWithHours.has(categoryName)}
          quantity={categoryName === 'Material'}
          units={categoryName === 'Material'}
          dates={useDates || (!isNotDisplay && weHaveDates)}
          dataSource={categoryCostCodes}
          style={{ marginTop: 15 }}
          showCostEstimates
        />
      );
    });
  }, [
    isNotDisplay,
    onEstimateChanged,
    useDates,
    weHaveDates,
    selectedCostCodes,
    sortByNumber('code'),
  ]);

  const onActionClick = useCallback((record, index) => () => (
    index === 0 ? addPhaseCode() : onRemove(record)
  ), [
    addPhaseCode,
    onRemove,
  ]);

  const onClickCostcodes = useCallback((index) => () => openAddDrawer(index), [openAddDrawer]);
  const onChangeValue = useCallback((fn) => (e) => fn(e.target.value), []);

  const onEditPhaseEstimates = useCallback((id, key) => (newValue) => (
    onEditPhaseDetails(id, { [key]: newValue })
  ), [onEditPhaseDetails]);

  const getNumberInput = useCallback((value, id, key, isCurrency = false) => {
    const currencyProps = isCurrency
      ? { formatter: currencyFormatter, parser: currencyParser }
      : { };

    return (
      <VerticallyCenteredNumberInput
        min={0}
        value={value}
        style={{ width: 120 }}
        onChange={onEditPhaseEstimates(id, key)}
        asFloat
        {...currencyProps}
      />
    );
  }, [onEditPhaseEstimates]);

  const getCols = useCallback(() => [
    {
      title: 'Phase',
      dataIndex: 'name',
      key: 'name',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) {
          return (
            <OnTraccrTextInput
              onRef={setCodeRef}
              value={newPhaseCode}
              onChange={onChangeValue(setNewPhaseCode)}
              onPressEnter={addPhaseCode}
            />
          );
        }
        return record.name || text;
      },
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) {
          return (
            <OnTraccrTextInput
              value={newPhaseDescription}
              onChange={onChangeValue(setNewPhaseDescription)}
              onPressEnter={addPhaseCode}
            />
          );
        }
        return text;
      },
    },
    {
      title: 'Estimated Labour Hours',
      dataIndex: 'estimatedLabourHours',
      key: 'estimatedLabourHours',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedLabourHours');
        return text;
      },
    },
    {
      title: 'Estimated Labour Cost',
      dataIndex: 'estimatedLabourCost',
      key: 'estimatedLabourCost',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedLabourCost', true);
        return currencyFormatter(text);
      },
    },
    {
      title: 'Estimated Overhead Hours',
      dataIndex: 'estimatedOverheadHours',
      key: 'estimatedOverheadHours',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedOverheadHours');
        return text;
      },
    },
    {
      title: 'Estimated Overhead Cost',
      dataIndex: 'estimatedOverheadCost',
      key: 'estimatedOverheadCost',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedOverheadCost', true);
        return currencyFormatter(text);
      },
    },
    {
      title: 'Estimated Material Cost',
      dataIndex: 'estimatedMaterialCost',
      key: 'estimatedMaterialCost',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedMaterialCost', true);
        return currencyFormatter(text);
      },
    },
    {
      title: 'Estimated Equipment Hours',
      dataIndex: 'estimatedEquipmentHours',
      key: 'estimatedEquipmentHours',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedEquipmentHours');
        return text;
      },
    },
    {
      title: 'Estimated Equipment Cost',
      dataIndex: 'estimatedEquipmentCost',
      key: 'estimatedEquipmentCost',
      render: (text, record, index) => {
        if (index === 0 && isNotDisplay) return '';
        if (isNotDisplay) return getNumberInput(text, record.id, 'estimatedEquipmentCost', true);
        return currencyFormatter(text);
      },
    },
    {
      title: (isNotDisplay ? 'Cost Codes' : ''),
      dataIndex: 'costcodes',
      key: 'costcodes',
      align: 'center',
      render: (text, record, index) => {
        if (!isNotDisplay) return null;
        return (
          <BorderlessButton
            title={getCostCodeByIndex(index, record).length === 0 ? 'Add' : 'Edit'}
            icon={null}
            color={colors.ONTRACCR_BLACK}
            onClick={onClickCostcodes(index)}
            style={{
              paddingRight: 8,
              paddingLeft: 0,
            }}
          />
        );
      },
    },
    {
      dataIndex: 'action',
      key: 'action',
      align: 'center',
      render: (_, record, index) => {
        if (!isNotDisplay) return null;
        return (
          <BorderlessButton
            title={index === 0 ? 'Save' : ''}
            icon={index === 0 ? null : 'delete'}
            color={index === 0 ? colors.ONTRACCR_BLACK : colors.ONTRACCR_RED}
            onClick={onActionClick(record, index)}
            style={{
              paddingRight: 8,
              paddingLeft: 0,
              visibility: index === 0 && !(newPhaseCode && newPhaseDescription) ? 'hidden' : 'visible',
            }}
          />
        );
      },
    },
  ], [
    isNotDisplay,
    setCodeRef,
    newPhaseCode,
    setNewPhaseCode,
    addPhaseCode,
    newPhaseDescription,
    setNewPhaseDescription,
    getCostCodeByIndex,
    openAddDrawer,
    onRemove,
    getNumberInput,
  ]);

  return (
    <Table
      title={title}
      expandable={{
        rowExpandable: (record) => (
          record.id === 'add' && selectedCostCodes.length > 0)
          || (record.costcodes && record.costcodes.length > 0),
        expandedRowRender: getPhaseCostcodeLists,
        // defaultExpandAllRows: true,
      }}
      size="small"
      columns={getCols()}
      dataSource={ourData.map((item) => ({
        ...item,
        key: item.id,
        estimatedLabourHours: item?.estimatedLabourHours ?? totalCategoryValues(item.costcodes, categories, 'Labor', 'hours'),
        estimatedLabourCost: item?.estimatedLabourCost ?? totalCategoryValues(item.costcodes, categories, 'Labor', 'estimatedCost'),
        estimatedOverheadHours: item?.estimatedOverheadHours ?? totalCategoryValues(item.costcodes, categories, 'Overhead', 'hours'),
        estimatedOverheadCost: item?.estimatedOverheadCost ?? totalCategoryValues(item.costcodes, categories, 'Overhead', 'estimatedCost'),
        estimatedMaterialCost: item?.estimatedMaterialCost ?? totalCategoryValues(item.costcodes, categories, 'Material', 'estimatedCost'),
        estimatedEquipmentCost: item?.estimatedEquipmentCost ?? totalCategoryValues(item.costcodes, categories, 'Equipment', 'estimatedCost'),
        estimatedEquipmentHours: item?.estimatedEquipmentHours ?? totalCategoryValues(item.costcodes, categories, 'Equipment', 'hours'),
      }))}
      pagination={false}
      style={{
        marginBottom: config.showEquipment ? 50 : 100,
        ...style,
      }}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
ProjectBudgetTrackingPhases.propTypes = {
  dataSource: PropTypes.array.isRequired,
  isNotDisplay: PropTypes.bool.isRequired,
  selectedPhaseCostCodes: PropTypes.array,
  onEditPhaseCostCodes: PropTypes.func.isRequired,
  onEditPhaseDetails: PropTypes.func.isRequired,
  onRemove: PropTypes.func.isRequired,
  openAddDrawer: PropTypes.func.isRequired,
  setSelectedCostCodes: PropTypes.func.isRequired,
  setNewPhaseDescription: PropTypes.func.isRequired,
  setCodeRef: PropTypes.func.isRequired,
  setNewPhaseCode: PropTypes.func.isRequired,
  addPhaseCode: PropTypes.func.isRequired,
  newPhaseCode: PropTypes.string,
  newPhaseDescription: PropTypes.string,
  useDates: PropTypes.bool,
  selectedCostCodes: PropTypes.array.isRequired,
  categories: PropTypes.object.isRequired,
  style: PropTypes.object.isRequired,
  useBuildEstimate: PropTypes.bool.isRequired,
};

ProjectBudgetTrackingPhases.defaultProps = {
  useDates: false,
  newPhaseDescription: null,
  newPhaseCode: null,
  selectedPhaseCostCodes: [],
};

export default ProjectBudgetTrackingPhases;
