import React, { useState, useCallback } from 'react';
import { Row, Col, Button } from 'antd';
import { FileAddOutlined } from '@ant-design/icons';
import axios from 'axios';

import PhotoReel from '../photos/PhotoReel';
import FullPhoto from '../photos/FullPhoto';

import LiveFeedFileUpload from '../../dashboard/LiveFeed/LiveFeedFileUpload';

import colors from '../../constants/Colors';

import { downloadFile, getFileType } from '../../files/fileHelpers';

export default ({
  files = [],
  onChange,
  isDisplay,
  style = {},
  downloadEnabled,
}) => {

  const [showUpload, setShowUpload] = useState(false);
  const [selectedFile, setSelectedFile] = useState();
  const [currIndex, setCurrIndex] = useState();

  const addFile = useCallback((file) => {
    onChange([...files,file]);
  },[files]);

  const removeFile = useCallback((index) => {
    const newFiles = [...files];
    newFiles.splice(index,1);
    onChange(newFiles);
  },[files]);

  const onFileClick = async (idx) => {
    const ourFile = files[idx];
    setSelectedFile(ourFile);
    setCurrIndex(idx);
    const { id: fileId } = ourFile;
    if(fileId) {
      // Let the backend know the user opened a file
      // fileId is undefined for files the user just added, that are not uploaded yet
      await axios.post('/events', { id: fileId });
    }
  };

  const closePhotoPreview = useCallback(() => setSelectedFile(),[]);
  const onNextFileClick = useCallback((isRight) => () => {
    const newIndex = isRight ? currIndex + 1 : currIndex - 1;
    const isInvalid = isRight ? newIndex >= files.length : newIndex < 0;
    if (isInvalid) return;
    const ourFile = files[newIndex] || {};
    setSelectedFile(ourFile);
    setCurrIndex(newIndex);
  }, [currIndex, files]);

  const onDownload = useCallback(() => {
    downloadFile({ fileObject: selectedFile.jsFileObject });
  }, [selectedFile]);

  return (
    <>
      <Row style={{ marginTop: 5, ...style }}>
        {!isDisplay && <Col style={{ marginTop: 10, width:60 }}>
          <Row style={{ height: 80}} align='bottom'>
            <Row style={{ height:70}} align='top'>
              <Button
                className='form-attachment-add-button'
                icon={
                  <FileAddOutlined
                    style={{
                      color:colors.ONTRACCR_RED,
                    }}
                  /> 
                }
                onClick={() => setShowUpload(true)}
              />
            </Row>
          </Row>
        </Col>}
        <Col style={{ width: isDisplay ? '100%' : 'calc(100% - 60px)'}}>
          <PhotoReel
            small
            files={files}
            onDelete={isDisplay ? null : removeFile}
            onClick={onFileClick}
          />
        </Col>
      </Row>
      <LiveFeedFileUpload
        visible={showUpload}
        onUploadEnd={() => setShowUpload(false)}
        placement='right'
        customProps={{
          style:{},
          getContainer: undefined,  // Need to override default props or drawer opens in a different DOM node
          width: 500
        }}
        addFile={addFile}
      />
      <FullPhoto
        url={selectedFile ? selectedFile.url : null}
        file={selectedFile}
        type={selectedFile ? getFileType(selectedFile) : null}
        onClose={closePhotoPreview}
        onLeft={onNextFileClick(false)}
        onRight={onNextFileClick(true)}
        showLeft={selectedFile && currIndex > 0}
        showRight={selectedFile && currIndex < (files.length - 1)}
        onDownload={downloadEnabled ? onDownload : undefined}
        useApryse
      />
    </>
  );
}