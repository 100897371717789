import {
  GET_TUTORIALS,
} from '../../state/actionTypes';
import LearningCenterService from './learningCenter.service';

export const getTutorials = () => async (dispatch) => {
  const tutorials = await LearningCenterService.getTutorials();
  dispatch({
    type: GET_TUTORIALS,
    payload: {
      tutorials,
    },
  });
  return true;
};
