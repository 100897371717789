import React from 'react';
import { DeleteOutlined } from '@ant-design/icons';
import { Col, Row, Select } from 'antd';
import PropTypes from 'prop-types';
import BorderlessButton from '../common/buttons/BorderlessButton';
import OnTraccrNumberInput from '../common/inputs/OnTraccrNumberInput';
import Colors from '../constants/Colors';
import { TIME_OPTS } from '../helpers/subtasks.constants';

function RelativeTimeSelector({
  amount,
  time,
  text,
  onUpdateAmount,
  onSelectTime,
  onDelete,
}) {
  return (
    <Row align="middle" gutter={8} style={{ margin: '10px 0px' }}>
      <Col style={{ height: 30, width: 75 }}>
        <OnTraccrNumberInput min={0} value={amount} onChange={onUpdateAmount} style={{ width: '100%' }} />
      </Col>
      <Col style={{ height: 30 }}>
        <Select
          style={{ width: 120 }}
          options={TIME_OPTS}
          value={time}
          onSelect={onSelectTime}
        />
      </Col>
      <Col style={{
        height: 30,
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
      >
        {text}
      </Col>
      {onDelete && (
        <Col style={{ height: 30 }}>
          <BorderlessButton
            style={{ height: 30 }}
            iconNode={<DeleteOutlined style={{ marginLeft: 0, color: Colors.ONTRACCR_RED }} />}
            onClick={onDelete}
          />
        </Col>
      )}
    </Row>
  );
}

RelativeTimeSelector.propTypes = {
  amount: PropTypes.number.isRequired,
  time: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  onUpdateAmount: PropTypes.func.isRequired,
  onSelectTime: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default RelativeTimeSelector;
