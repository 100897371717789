import React, { useMemo } from 'react';
import { Row, Col } from 'antd';
import { DollarCircleOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';

import WorkflowSimpleMappingNode from './WorkflowSimpleMappingNode';
import WorkflowPaymentConfigureDrawer from './WorkflowPaymentConfigureDrawer';
import WorkflowHandle from './WorkflowHandle';

const HOVER_TEXT = `
Use this step to send a payment link.
`;
const NOT_INVOICE_TEXT = 'This step only works with Invoice forms';
const getDisabledText = (t) => `This step requires at least one Text field and one ${t('Customer')} Select field`;

export default function WorkflowPaymentNode({
  setDataMap,
  setElements,
  isDisplay,
  name,
  sections = [],
  isInvoice,
} = {}) {
  return function _({
    id,
    draggable,
    data = {},
    disabled,
  }) {
    const { t } = useTranslation();
    const DISABLED_TEXT = getDisabledText(t);

    const style = useMemo(() => {
      if(disabled) return { opacity: 0.7 };
      return draggable ? {} : { width: 200 }
    },[disabled, draggable]);

    const hover = useMemo(() => {
      if(!isInvoice) return NOT_INVOICE_TEXT;
      return disabled ? DISABLED_TEXT : HOVER_TEXT;
    },[disabled,isInvoice]);

    return (
      <WorkflowSimpleMappingNode
        setElements={setElements}
        setDataMap={setDataMap}
        isDisplay={isDisplay}
        name={name}
        sections={sections}
        id={id}
        draggable={draggable}
        disabled={disabled}
        data={data}
        title='Collect Payment'
        Icon={DollarCircleOutlined}
        type='stripePayment'
        hover={hover}
        style={style}
        ConfigureDrawer={WorkflowPaymentConfigureDrawer}
      >
        <Row justify='center' style={{ marginBottom: 5 }}>
          <Col>
            On Payment
          </Col>
        </Row>
        <WorkflowHandle type='source' position='bottom' disabled={isDisplay} />
      </WorkflowSimpleMappingNode>
    );
  }
}
