import React from 'react';
import { connect } from 'react-redux';
import { Row } from 'antd';

import OnTraccrButton from './buttons/OnTraccrButton';
import DisplayText from './text/DisplayText';

import colors from '../constants/Colors';

import noData from '../assets/images/noData.svg';
import { setMenu } from '../menu/state/menu.actions';

import Analytics from '../helpers/Analytics';

export default connect(
(state,ownProps) => ({
  ...ownProps,
  selectedKeys:state.menu.items,
}),{
  setMenu
})(({
  setMenu,
  selectedKeys = [],
}) => (
  <Row style={{ width:'100%', height:'100%' }} justify='center' align='middle'>
    <Row justify='center'>
      <img
        src={noData}
        alt='No Data'
        style={{
          width:100,
          height:100,
          marginBottom:20,
        }}
      />
      <DisplayText 
        title={
          <div>
            You have no data here yet.<br/>
            Need Help?
          </div>
        }
        style={{ 
          textAlign: 'center',
          fontSize:20,
          fontFamily:'roboto-regular',
          color:colors.DARK_GRAY,
        }}/>
      <OnTraccrButton
        title='Getting Started'
        type='cancel'
        onClick={() => {
          Analytics.track('NUX/Open',{ Source: 'No Data' });
          setMenu(selectedKeys.concat(['getStarted']));
        }}
      />
    </Row>
  </Row>
));
