import React, {
  useState, useMemo, useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { useDrop } from 'react-dnd';
import { DateTime } from 'luxon';

import { prepareDragShiftUpdatePayload, splitMultiDayShifts } from '../scheduleHelpers';

import ScheduleCalendarDay from '../ScheduleCalendarDay';
import {
  setBiweeklyModalConfig,
  updateShift,
} from '../state/schedule.actions';

export default function UserDay({
  user,
  day,
  endDate,
  weekStart,
  shifts,
  height,
  onShiftSelect,
}) {
  const dispatch = useDispatch();

  const companySettings = useSelector((state) => state.settings.company);
  const userDivisions = useSelector((state) => state.users.userDivisions);

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: ['MonthShiftDayEntry', 'MonthMultiDayEntry', 'MonthMultiDayEntrySimple'],
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: ({ shift }) => {
      if (!user) return true; // Unassign;
      const userDivs = userDivisions[user?.id] ?? [];
      return userDivs.includes(shift?.divisionId);
    },
    drop: ({ userId, shift }) => {
      const shiftStartDT = DateTime.fromMillis(shift.startTime);
      if (userId === user?.id && shiftStartDT.hasSame(day, 'day')) return;

      const { emailNotification, pushNotification } = companySettings?.settings ?? {};
      const payload = prepareDragShiftUpdatePayload({
        shift,
        startOfNewDay: day,
        previousUserId: userId,
        newUserId: user?.id,
        emailNotification,
        pushNotification,
        updateUsers: true,
      });
      dispatch(updateShift(shift.id, payload));
    },
  }));

  const [showAddButton, setShowAddButton] = useState(false);

  const { multiDay, interDay } = useMemo(() => {
    const sortedShifts = shifts.sort((a, b) => a.startTime - b.startTime);
    return splitMultiDayShifts(sortedShifts, day);
  }, [shifts, day]);

  const onAddClicked = useCallback(() => {
    dispatch(setBiweeklyModalConfig({
      visible: true,
      userId: user?.id,
      date: day,
    }));
  }, [user, day]);

  return (
    <div
      className="schedule-user-week-day-block"
      onMouseEnter={() => setShowAddButton(true)}
      onMouseLeave={() => setShowAddButton(false)}
      ref={drop}
      style={{
        boxShadow: isOver && canDrop ? '0px 0px 10px lightgray' : '',
      }}
    >
      <div
        className="schedule-user-week-day-header"
        style={{ visibility: showAddButton ? 'visible' : 'hidden' }}
        onClick={onAddClicked}
        role="button"
        onKeyDown={() => {}}
        tabIndex={-1}
      >
        <PlusOutlined />
      </div>
      <ScheduleCalendarDay
        user={user}
        day={day}
        endDate={endDate}
        weekStart={weekStart}
        shifts={shifts}
        height={height}
        onShiftSelect={onShiftSelect}
        multiDay={multiDay}
        interDay={interDay}
      />
    </div>
  );
}

UserDay.propTypes = {
  user: PropTypes.object,
  day: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  weekStart: PropTypes.object.isRequired,
  shifts: PropTypes.arrayOf(PropTypes.object).isRequired,
  height: PropTypes.number,
  onShiftSelect: PropTypes.func.isRequired,
};

UserDay.defaultProps = {
  user: undefined,
  height: undefined,
};
