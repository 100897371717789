import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useSpring, animated } from 'react-spring'
import {
  Drawer,
  Checkbox,
  Row,
  Col,
  Input,
} from 'antd';
import { Trie } from 'ontraccr-common';

import FilePermsUserList from './FilePermsUserList';

import HoverHelp from '../common/HoverHelp';

import {
  grantAccess,
  getFileAccessList,
  revokeAccess,
  togglePublic,
} from './state/files.actions';
import { isDefaultPublic } from './fileHelpers';

import sortByString from '../helpers/helpers';

const publicHelpText = (
  <div style={{ width: 250 }}>
    Public files and folders in a division folder can be viewed by all users in that division.
    <br/><br/>
    Public files and folders outside of a division folder can be viewed by all users in your company
    <br/><br/>
    Keep this box unchecked if you want to set which users can see this file/folder.
  </div>
);

const sortByName = sortByString('name');
export default ({
  selectedFile,
  onClose,
}) => {
  const {
    name = '',
    type,
    id: fileId,
    isPublic:filePublicState,
    fullPath,
  } = selectedFile || {};

  const isFolder = type === 'folder';
  const defaultPub = isDefaultPublic(selectedFile || {});

  const dispatch = useDispatch();

  const users = useSelector(state => state.users.users);
  const userTrie = useSelector(state => state.users.userTrie);
  const fileAccessLists = useSelector(state => state.files.fileAccessLists);
  const fileStructure = useSelector(state => state.files.fileStructure);
  const company = useSelector(state => state.settings.company);
  const divisions = useSelector(state => state.settings.divisions);

  const [isPublic,setPublic] = useState(filePublicState || defaultPub);
  const [searchTerm,setSearchTerm] = useState();
  const [selected,setSelected] = useState([[],[]]);
  const [permsMap,setPermsMap] = useState(new Set());

  const [tableStyle,setTableStyle] = useSpring(() => ({ opacity: 1 }));

  const onCheckChange = useCallback(async (e) => {
    const {
      target:{
        checked,
      } = {},
    } = e;
    if(await dispatch(togglePublic({
      path: fullPath,
      id: fileId,
      isPublic: checked,
    }))) {
      setPublic(checked);
    }

  },[dispatch, fullPath, fileId]);
  const onSearchChange = useCallback((e) => {
    const {
      target:{
        value,
      } = {},
    } = e;
    setSearchTerm(value);
  },[]);


  const addUsersToPerms = useCallback(async (userIds = []) => {
    const { fullPath } = selectedFile;
    const fullFile = fileStructure[fullPath];
    if(!fullFile) return;
    if(await dispatch(grantAccess({ fileIds:[fullFile.id], userIds }))) {
      const newPerms = new Set(permsMap);
      userIds.forEach((userId) => {
        newPerms.add(userId);
      });
      setPermsMap(newPerms);
    }
  },[permsMap, dispatch, selectedFile, fileStructure]);

  const removeUsersFromPerms = useCallback(async (userIds = []) => {
    const { fullPath } = selectedFile;
    const fullFile = fileStructure[fullPath];
    if(!fullFile) return;
    if(await dispatch(revokeAccess({ fileIds:[fullFile.id], userIds }))) {
      const newPerms = new Set(permsMap);
      userIds.forEach((userId) => {
        newPerms.delete(userId);
      });
      setPermsMap(newPerms);
    }
  },[permsMap, dispatch, selectedFile, fileStructure]);

  const onSelectChange = useCallback(([selectedKeys]) => {
    const added = [];
    const removed = [];
    const currentKeySet = new Set();
    selectedKeys.forEach((userId) => {
      currentKeySet.add(userId);
      if(!permsMap.has(userId)) added.push(userId);
    });
    Array.from(permsMap).forEach((userId) => {
      if(!currentKeySet.has(userId)) removed.push(userId);
    });
    if(added.length) addUsersToPerms(added);
    if(removed.length) removeUsersFromPerms(removed);
  },[permsMap,addUsersToPerms,removeUsersFromPerms]);

  useEffect(() => {
    if(dispatch && fileId) dispatch(getFileAccessList(fileId));
  },[dispatch,fileId]);

  useEffect(() => {
    setTableStyle({
      opacity: isPublic ? 0 : 1,
      height: isPublic ? 0 : 'auto',
      overflow:'hidden'
    });
  },[isPublic, setTableStyle]);

  useEffect(() => {
    const selectedKeys = [];
    const selectedUsers = [];
    users.forEach((user) => {
      if(permsMap.has(user.id) || (company && company.userId === user.id)) {
        selectedKeys.push(user.id);
        selectedUsers.push(user);
      }
    });

    setSelected([selectedKeys,selectedUsers]);
  },[permsMap,users,company]);

  useEffect(() => {
    const {
      [fileId]:newACL = new Set(),
    } = fileAccessLists;

    setPermsMap(new Set(newACL));
  },[fileAccessLists,fileId]);

  useEffect(() => {
    if(selectedFile) {
      const fileIsDefaultPub = isDefaultPublic(selectedFile);
      setPublic(fileIsDefaultPub || selectedFile.isPublic);
    }
  },[selectedFile]);


  const releventData = useMemo(() => {
    const ourUsers = searchTerm ? Trie.searchTrie(userTrie,searchTerm) : users.filter(user => user.active);
    const selectedUsers = [];
    const unselectedUsers = [];
    const owner = [];
    ourUsers.forEach((user) => {
      if(company && company.userId === user.id) {
        owner.push(user);
      }else if(permsMap.has(user.id)) {
        selectedUsers.push(user);
      } else {
        unselectedUsers.push(user);
      }
    });
    return owner.concat(selectedUsers.sort(sortByName).concat(unselectedUsers.sort(sortByName)));
  },[searchTerm,users,userTrie,permsMap,company]);

  const fileDivisions = useMemo(() => {
    if(!fullPath) return [];
    const divArray = Object.values(divisions);
    const prefix = fullPath.split('/')[0];
    const ourDiv = divArray
      .find(({ name: divisionName }) => divisionName === prefix);
    return ourDiv ? [ourDiv.id] : divArray.map((div) => div.id);
  },[fullPath, divisions]);

  return (
    <Drawer
      title={`Permissions - ${name}`}
      width={700}
      visible={selectedFile}
      maskClosable={false}
      onClose={onClose}
    >
      <div style={{ height:'100%', width:'100%', display:'flex', justifyContent:'start', flexDirection:'column' }}>
        <Row justify='start' gutter={10} style={{ width:'100%', marginBottom: isFolder ? 0 : 15 }}>
          <Col>
            <Checkbox
              onChange={onCheckChange}
              style={{ marginBottom:10 }}
              checked={isPublic}
              disabled={defaultPub}
            >
              Public
            </Checkbox>
          </Col>
          <Col>
            <HoverHelp content={publicHelpText} placement='rightTop'/>
          </Col>
        </Row>
        {isFolder &&
          <div style={{ marginBottom: 15 }}>
            Granting access to a folder will give users access to all items in that folder
          </div>
        }
        <animated.div style={tableStyle}>
          <Row justify='start' gutter={20}>
            <Col flex='400px'>
              <Input.Search
                type='search'
                disabled={false}
                style={{ width:'100%', }}
                className='searchbar'
                placeholder='Search'
                allowClear
                onChange={onSearchChange}
              />
            </Col>
          </Row>
          <FilePermsUserList
            divisions={fileDivisions}
            onSelect={onSelectChange}
            selected={selected}
            data={releventData}
            scroll={{ y: `calc(100vh - ${isFolder ? 255 : 235}px)` }}
          />
        </animated.div>
      </div>
    </Drawer>
  );
}