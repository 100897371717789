// Progress Update Types:
export const PROGRESS_UPDATE_TYPE_NOTES = 'text';
export const PROGRESS_UPDATE_TYPE_QUANTITY = 'quantity';
export const PROGRESS_UPDATE_TYPE_CURRENCY = 'currency';
export const PROGRESS_UPDATE_TYPE_ACTIVITY = 'percent';
export const PROGRESS_UPDATE_TYPE_LOGGED_HOURS = 'logged hours';
export const PROGRESS_UPDATE_TYPE_INVOICE_ADD = 'invoice distribution add';
export const PROGRESS_UPDATE_TYPE_INVOICE_UPDATE = 'invoice distribution update';
export const PROGRESS_UPDATE_TYPE_INVOICE_DELETE = 'invoice distribution delete';
export const PROGRESS_UPDATE_TYPE_UPDATE_COST_ADD = 'update cost add';

// Progress Update Fields:
export const PROGRESS_UPDATE_FIELD_NOTES = 'Notes';
export const PROGRESS_UPDATE_FIELD_QUANTITY = 'Progress Quantity';
export const PROGRESS_UPDATE_FIELD_CURRENCY = 'Progress Currency';
export const PROGRESS_UPDATE_FIELD_ACTIVITY = 'Progress Activity';
export const PROGRESS_UPDATE_FIELD_LOGGED_HOURS = 'Progress Logged Hours';
