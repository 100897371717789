/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import moment from 'moment';
import { DateTime } from 'luxon';
import { TaskHelpers } from 'ontraccr-common';

import calculateOT, { isGoodTask } from '../../../helpers/overtime/calculateOT';
import { formatProjectLabelFromCompanySettings } from '../../../projects/projectHelpers';
import { msToHours } from '../../../helpers/time';

import {
  getDivision,
} from '../../reportHelpers';

export default {};

export const getPhaseMap = (phases = []) => {
  const phaseMap = {};
  phases.forEach(({ projectId, costcodeId, name }) => {
    if (!(projectId in phaseMap)) {
      phaseMap[projectId] = {};
    }
    if (!(costcodeId in phaseMap[projectId])) {
      phaseMap[projectId][costcodeId] = [];
    }
    phaseMap[projectId][costcodeId].push(name);
  });
  return phaseMap;
};

export const computeData = ({
  onDataChanged,
  onFiltersChanged,
  settings,
  isRounded,
  userDivisions,
  timeEntryUserMap = {},
  users = [],
  enabledFilters,
  projects,
  costcodes,
  phases,
  workType,
  zeroHoursEnabled,
  isValidTask,
  getAggregateKey,
  divisionMap,
  projectIdMap,
  costcodeIdMap,
  classIdMap,
  phaseMap,
  dateRange,
}) => {
  const {
    enableManualOT,
    manualOTModifier = 1.5,
  } = settings;
  const otModifier = enableManualOT ? manualOTModifier : 1.5;

  const [startMoment, endMoment] = dateRange ?? [];

  const range = moment.isMoment(startMoment) && moment.isMoment(endMoment)
    ? [
      DateTime.fromMillis(startMoment.valueOf()).minus({ days: 15 }),
      DateTime.fromMillis(endMoment.valueOf()).plus({ days: 15 }),
    ]
    : [];
  const data = [];
  const dateAggregate = {};
  const userDateCostCodeSet = new Set();

  // zero hours
  if (zeroHoursEnabled) {
    const usersWithNoHours = [];

    users.forEach((user) => {
      const tasks = timeEntryUserMap[user?.id] ?? [];

      const validTasks = tasks.filter((task) => (
        isGoodTask(task)
          && workType.has(task.type)
          && isValidTask(task)
      ));

      // if user has any valid tasks, then they have time logged
      if (!validTasks.length) {
        usersWithNoHours.push(user);
      }
    });

    const aggKey = getAggregateKey('None', 'None', 'None', null, null);

    const usersWithNoHoursData = usersWithNoHours.map((user) => ({
      name: user.name,
      employeeId: user.employeeId ?? '',
      division: getDivision({
        project: {}, divisionMap, userDivisions, user,
      }),
      key: user.id + aggKey,
      notes: 'No Hours Logged',
      rawNotes: 'No Hours Logged',
    }));

    onDataChanged(usersWithNoHoursData);
    onFiltersChanged({
      users,
      projects,
      costcodes,
      phases,
    });

    return usersWithNoHoursData;
  }

  // non zero-hours
  users.forEach((user) => {
    const tasks = timeEntryUserMap[user?.id] ?? [];
    // only showing work for employee payroll report
    calculateOT({
      tasks,
      settings: {
        ...settings,
        roundingInterval: isRounded ? settings.roundingInterval : null,
      },
      range,
    }).filter((t) => workType.has(t.type)).forEach((task) => {
      if (!isValidTask(task)) return;
      const project = projectIdMap[task.projectId];
      const costcode = costcodeIdMap[task.costcodeId];
      const unionClass = classIdMap[task.classId];

      let phaseName;

      if (project
        && costcode
        && project.id in phaseMap
        && costcode.id in phaseMap[project.id]
        && phaseMap[project.id][costcode.id]
      ) {
        [phaseName] = phaseMap[project.id]?.[costcode.id] ?? [];
      }

      const date = TaskHelpers.getTaskDate(task)?.toLocaleString(DateTime.DATE_MED) ?? '';

      const runTime = task.endTime - task.startTime;

      const state = task.state || 'Not Submitted';

      const projectLabel = project ? formatProjectLabelFromCompanySettings({
        name: project.name,
        number: project.number,
        settings,
      }) : null;

      if (!(user.id in dateAggregate)) {
        const dataObject = {
          name: user.name,
          employeeId: user.employeeId ?? '',
          wage: user.wage, // TODO: use wage history here
          division: getDivision({
            project, divisionMap, userDivisions, user,
          }),
          project: projectLabel,
          costcode: costcode ? costcode.code : null,
          runTime,
          regularHours: runTime,
          otHours: 0,
          doubleOtHours: 0,
          saturdayHours: 0,
          saturdayOTHours: 0,
          saturdayDoubleOTHours: 0,
          sundayHours: 0,
          sundayOTHours: 0,
          sundayDoubleOTHours: 0,
          totalHours: 0,
          totalCost: '$0.00',
          date,
          unapproved: runTime,
          approved: 0,
          state,
          serviceLocation: task.serviceLocation,
          type: task.type,
        };

        dateAggregate[user.id] = {
          ...dataObject,
          values: {},
        };
      }

      const userDateCostCodeKey = `${user.id}-${date}-${costcode?.id}`;

      const costCode = costcode ? costcode.code : 'None';
      const phase = phaseName ?? 'None';
      const aggKey = getAggregateKey(
        projectLabel ?? 'None',
        phase,
        costCode,
        task.serviceLocation,
        task.id,
      );
      let wage = 0;
      let dailyWage = 0;

      // union class wage priority
      if (unionClass?.wage) {
        wage = unionClass?.wage;
      } else if (costcode?.dailyWage) {
        if (!userDateCostCodeSet.has(userDateCostCodeKey)) {
          dailyWage = costcode?.dailyWage;
          userDateCostCodeSet.add(userDateCostCodeKey);
        }
      } else {
        wage = (
          (
            (costcode?.hourlyWage ?? user.wage)
            + (costcode?.wageAdjustment ?? 0)
          )
          * (costcode?.wageMultiplier ?? 1)
        );
      }

      if (!(aggKey in dateAggregate[user.id].values)) {
        dateAggregate[user.id].values[aggKey] = {
          employeeId: user.employeeId ?? '',
          wage,
          dailyWage,
          regularHours: 0,
          breakHours: 0,
          otHours: 0,
          satOTHours: 0,
          sunOTHours: 0,
          totalHours: 0,
          unapproved: 0,
          approved: 0,
          costcode: costcode ? costcode.code : null,
          project: projectLabel,
          date,
          phase: phaseName,
          key: user.id + aggKey,
          serviceLocation: task.serviceLocation,
          regOT: 0,
          doubleOT: 0,
          saturday: 0,
          saturdayOT: 0,
          saturdayDoubleOT: 0,
          sunday: 0,
          sundayOT: 0,
          sundayDoubleOT: 0,
          notes: [],
          rawNotes: '',
          weightedAverageWage: 0,
          type: task.type,
          totalDailyCost: 0,
        };
      }
      const isWeekday = !task.isSaturday && !task.isSunday;
      const hrReg = isWeekday ? msToHours(task.regularTime) : 0;
      const hourOT = isWeekday ? msToHours(task.overtime) : 0;
      const hourDouble = isWeekday ? msToHours(task.doubleOT) : 0;
      const satHrReg = msToHours(task.saturdayTime || 0);
      const satHourOT = msToHours(task.saturdayOT || 0);
      const satHourDouble = msToHours(task.saturdayDoubleOT || 0);
      const sunHrReg = msToHours(task.sundayTime || 0);
      const sunHourOT = msToHours(task.sundayOT || 0);
      const sunHourDouble = msToHours(task.sundayDoubleOT || 0);

      const oldNote = dateAggregate[user.id].values[aggKey].notes;

      const totalHours = (
        hrReg + hourOT + hourDouble
        + satHrReg + sunHrReg + satHourOT + satHourDouble
        + sunHourOT + sunHourDouble
      );
      dateAggregate[user.id].values[aggKey].weightedAverageWage += wage * totalHours;

      if (task.state === 'approved') {
        dateAggregate[user.id].values[aggKey].approved += totalHours;
      } else {
        dateAggregate[user.id].values[aggKey].unapproved += totalHours;
      }

      if (task.type === 'break') {
        const breakHours = msToHours(runTime);
        dateAggregate[user.id].values[aggKey].breakHours += breakHours;
        dateAggregate[user.id].values[aggKey].totalHours += breakHours;
      } else {
        dateAggregate[user.id].values[aggKey].regularHours += hrReg;
        dateAggregate[user.id].values[aggKey].saturday += satHrReg;
        dateAggregate[user.id].values[aggKey].sunday += sunHrReg;
        dateAggregate[user.id].values[aggKey].otHours += hourOT + hourDouble;
        dateAggregate[user.id].values[aggKey].satOTHours += satHourOT + satHourDouble;
        dateAggregate[user.id].values[aggKey].sunOTHours += sunHourOT + sunHourDouble;
        dateAggregate[user.id].values[aggKey].saturdayOT += satHourOT;
        dateAggregate[user.id].values[aggKey].saturdayDoubleOT += satHourDouble;
        dateAggregate[user.id].values[aggKey].sundayOT += sunHourOT;
        dateAggregate[user.id].values[aggKey].sundayDoubleOT += sunHourDouble;
        dateAggregate[user.id].values[aggKey].regOT += hourOT;
        dateAggregate[user.id].values[aggKey].doubleOT += hourDouble;
        dateAggregate[user.id].values[aggKey].totalHours += totalHours;
        dateAggregate[user.id].values[aggKey].totalDailyCost += dailyWage;
      }

      if (task.note) {
        if (oldNote.length > 0) {
          dateAggregate[user.id].values[aggKey].rawNotes += '\n\n';
          dateAggregate[user.id].values[aggKey].notes.push(<><br /><br /></>);
        }
        dateAggregate[user.id].values[aggKey].rawNotes += task.note;
        dateAggregate[user.id].values[aggKey].notes.push(<span key={task.id}>{task.note}</span>);
      }
    });
  });

  let totalOfTotals = 0;

  Object.values(dateAggregate).forEach((obj) => {
    Object.values(obj.values).forEach((hours) => {
      const relevantWage = hours.totalHours
        ? hours.weightedAverageWage / hours.totalHours
        : 0;
      let totalCost = (
        hours.regularHours
          + hours.regOT * otModifier
          + hours.doubleOT * 2
      ) * relevantWage
          + (settings.saturdayPay ? (
            hours.saturday * settings.saturdayPay
            + hours.satOTHours * 1.5 * settings.saturdayPay
            + hours.saturdayDoubleOT * 2 * settings.saturdayPay
          ) * relevantWage : 0)
          + (settings.sundayPay ? (
            hours.sunday * settings.sundayPay
            + hours.sunOTHours * 1.5 * settings.sundayPay
            + hours.sundayDoubleOT * 2 * settings.sundayPay
          ) * relevantWage : 0) + hours.totalDailyCost;

      const computeWage = hours.totalHours
        ? totalCost / hours.totalHours
        : (hours.wage ?? 0);

      const wage = `$${computeWage.toFixed(2)}`;
      if (Number.isNaN(totalCost)) totalCost = 0;
      totalOfTotals += totalCost;
      // only approved selected and the task has approved hours      OR
      // only unapproved selected and the task has unapproved hours  OR  both selected
      if (
        (enabledFilters.approved
          && !enabledFilters.unapproved && hours.approved.toFixed(2) > 0
        )
        || (
          enabledFilters.unapproved
          && !enabledFilters.approved && hours.unapproved.toFixed(2)
           > 0
        )
        || (enabledFilters.unapproved && enabledFilters.approved)) {
        data.push({
          name: obj.name,
          employeeId: obj.employeeId,
          date: hours.date,
          division: obj.division,
          wage,
          project: hours.project,
          costcode: hours.costcode,
          breakHours: hours.breakHours.toFixed(2),
          regularHours: hours.regularHours.toFixed(2),
          otHours: hours.regOT.toFixed(2),
          doubleOT: hours.doubleOT.toFixed(2),
          saturday: hours.saturday.toFixed(2),
          sunday: hours.sunday.toFixed(2),
          satOTHours: hours.satOTHours.toFixed(2),
          sunOTHours: hours.sunOTHours.toFixed(2),
          totalHours: hours.totalHours.toFixed(2),
          totalCost: `$${totalCost.toFixed(2)}`,
          unapproved: hours.unapproved.toFixed(2),
          approved: hours.approved.toFixed(2),
          phase: hours.phase,
          key: hours.key,
          serviceLocation: hours.serviceLocation,
          breakHoursRaw: hours.breakHours,
          regHoursRaw: hours.regularHours,
          regularOTRaw: hours.regOT,
          doubleOTRaw: hours.doubleOT,
          satHoursRaw: hours.saturday,
          sundayHoursRaw: hours.sunday,
          saturdayOTRaw: hours.saturdayOT,
          sundayOTRaw: hours.sundayOT,
          saturdayDoubleOTRaw: hours.saturdayDoubleOT,
          sundayDoubleOTRaw: hours.sundayDoubleOT,
          notes: hours.notes,
          rawNotes: hours.rawNotes,
        });
      }
    });
  });

  onDataChanged(data);
  onFiltersChanged({
    users,
    projects,
    costcodes,
    phases,
  });
  return data.concat([{
    name: <b>Total</b>,
    totalCost: `$${totalOfTotals.toFixed(2)}`,
    isFooter: true,
  }]);
};
