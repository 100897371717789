import React from 'react';
import { Result,Row, } from 'antd';
import { FieldTimeOutlined } from '@ant-design/icons';

import colors from '../constants/Colors'

export default () => (
  <Row style={{ height:'100%',width:'100%' }} align='middle' justify='center'>
    <Result
      title='Coming Soon'
      icon={<FieldTimeOutlined style={{color:colors.ONTRACCR_BLACK}}/>}
    />
  </Row>
);
