const tintColor = '#2f95dc';

export default {
  tintColor,
  tabIconDefault: '#ccc',
  tabIconSelected: tintColor,
  tabBar: '#fefefe',
  errorBackground: 'red',
  errorText: '#fff',
  warningBackground: '#EAEB5E',
  warningText: '#666804',
  noticeBackground: tintColor,
  noticeText: '#fff',
  MODAL_COLOR: '#F8F8F8',
  MAIN_BACKGROUND: '#FFDC73',
  ONTRACCR_RED: '#BF381D',
  ONTRACCR_BLACK: '#323232',
  ONTRACCR_TITLE_BLACK: '#33332D',
  ONTRACCR_OPACITY_GRAY: 'rgba(92, 92, 86, 0.65)',
  ONTRACCR_GRAY: '#5c5c56',
  LIGHT_GRAY: '#C6C6C6',
  OPACITY_LIGHT_GRAY: 'rgba(198, 198, 198, 0.15)',
  DARK_GRAY: '#85857F',
  FADED_ONTRACCR_RED: '#D4897A',
  ONTRACCR_DARK_YELLOW: '#F2BD1E',
  ONTRACCR_MODAL_BACKGROUND_YELLOW: '#F2BD1EC0',
  MODAL_MASK_DARK: '#00000073',
  BREADCRUMB_BACKGROUND: '#f0f2f5',
  DIVIDER_COLOR: '#f0f0f0',
  TIME_CARD_STATS_YELLOW: '#F3C33D',
  opacity(color, opacity) {
    const ourOpacity = Math.round(Math.min(Math.max(opacity || 1, 0), 1) * 255);
    return color + ourOpacity.toString(16).toUpperCase();
  },
  hexToRGB(hex) {
    if (!hex) return null;

    let parsedHex = hex;

    // We want to ignore the opacity
    if (hex.length > 7) {
      parsedHex = hex.substring(0, 7);
    }

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(parsedHex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16),
    } : null;
  },
};
