import React from 'react';
import { useSelector } from 'react-redux';
import { MailOutlined } from '@ant-design/icons';
import { Popover } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

function BoardCardNotesIcon({
  count,
}) {
  const isDragging = useSelector((state) => state.boards.isDragging);
  const { t } = useTranslation();
  const content = `This card has ${count} ${t('note', { count })}`;
  return (
    <Popover
      content={content}
      trigger={isDragging ? [] : ['hover']}
    >
      <MailOutlined />
      <span className="board-card-days-in-status-text">{count}</span>
    </Popover>
  );
}

BoardCardNotesIcon.propTypes = {
  count: PropTypes.number.isRequired,
};

export default BoardCardNotesIcon;
