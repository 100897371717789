import React, { useCallback, useMemo } from 'react';
import { Form } from 'antd';
import PropTypes from 'prop-types';

import OnTraccrButton from '../common/buttons/OnTraccrButton';
import CustomConfirmModal from '../common/modals/CustomConfirmModal';
import FormSelectorInput from '../common/inputs/FormSelectorInput';

function FormSubmitChildButton({
  childForms,
  onSubmitChildClicked,
  setChildSubmitMode,
  style = {},
}) {
  const initialValues = { childForm: null };
  const [form] = Form.useForm();

  const formContent = useMemo(() => (
    <Form form={form} initialValues={initialValues}>
      <FormSelectorInput
        name="childForm"
        isNotDisplay
        rules={[{ required: true, message: 'Please select a child form' }]}
        selectProps={{
          mode: 'single',
          allowClear: true,
          showSearch: true,
          options: childForms,
          optionFilterProp: 'label',
        }}
      />
    </Form>
  ), [childForms]);

  const showModal = useCallback((e) => {
    e.stopPropagation();

    CustomConfirmModal({
      title: 'Select a Child Form Template',
      okText: 'Confirm',
      cancelText: 'Cancel',
      content: formContent,
      onOk: async () => {
        try {
          await form.validateFields();
          const { childForm } = form.getFieldsValue();
          setChildSubmitMode(true);
          onSubmitChildClicked(childForm);
        } catch {
          // fail silently
        }
      },
    });
  }, [formContent]);

  return (
    <OnTraccrButton
      title="Submit Child"
      style={{ margin: 2, ...style }}
      onClick={(e) => showModal(e)}
    />
  );
}

/* eslint-disable react/forbid-prop-types */
FormSubmitChildButton.propTypes = {
  childForms: PropTypes.array,
  style: PropTypes.object,
  onSubmitChildClicked: PropTypes.func.isRequired,
  setChildSubmitMode: PropTypes.func.isRequired,
};

FormSubmitChildButton.defaultProps = {
  childForms: [],
  style: {},
};

export default FormSubmitChildButton;
