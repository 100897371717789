import React, {
  useCallback,
} from 'react';
import { Checkbox, Col, Row } from 'antd';
import PropTypes from 'prop-types';

const CheckboxGroup = Checkbox.Group;

const columns = [
  'Title',
  'Assigned User',
  'Status',
  'Priority',
  'Due Date',
];

export default function DashboardTaskWidetsConfig({
  settings = {},
  onSave,
}) {
  const {
    visibleColumns = columns,
  } = settings;

  const onChange = useCallback((newChecked) => {
    onSave({
      ...settings,
      visibleColumns: newChecked,
    });
  }, [settings, onSave]);

  return (
    <>
      <h4>Visible Columns</h4>
      <CheckboxGroup
        value={visibleColumns}
        onChange={onChange}
      >
        <Row>
          {columns.map((column) => (
            <Col span={8} key={column}>
              <Checkbox value={column}>{column}</Checkbox>
            </Col>
          ))}
        </Row>
      </CheckboxGroup>
    </>
  );
}

DashboardTaskWidetsConfig.propTypes = {
  settings: PropTypes.shape({
    visibleColumns: PropTypes.array,
  }),
  onSave: PropTypes.func,
};

DashboardTaskWidetsConfig.defaultProps = {
  onSave: () => {},
  settings: { visibleColumns: columns },
};
