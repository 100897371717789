import React from 'react';
import { Row, Col } from 'antd';

import NuxIcon from './NuxIcon';

import Analytics from '../helpers/Analytics';

const padding = 20;

const NuxButton = ({ src, title, span = 6, complete, onClick }) => (
  <Col span={span} style={{ minHeight: 120}}>
     <NuxIcon title={title} complete={complete} onClick={onClick}>
      <img style={{ width: 64, height: 80, objectFit:'contain' }} src={src} alt={title}/>
    </NuxIcon>
  </Col>
);

const getButtons = (buttons, {  
  setDetailView,
  setTitle,
  setDescription,
}) => (
  buttons.map(({
    title,
    modalTitle = title, // Sometimes title is a <div> for spacing purposes. So modalTitle is just the bare string
    src,
    complete,
    description,
    detailView = <div/>,
    onClick,
  }) => (
    <NuxButton
      key={title}
      title={title}
      src={src}
      span={24 / buttons.length}
      complete={complete}
      onClick={() => {
        Analytics.track('Nux/PageView', { NuxPage: modalTitle });
        if(onClick) onClick();
        setTitle(modalTitle);
        setDescription(description);
        setDetailView(detailView);
      }}
    />
  ))
)

export default ({
  buttons = [],
  rows = 1,
  setDetailView,
  setTitle,
  setDescription,
}) => (
  rows === 2 ? <Row style={{ width: '100%', height:'100%'}} align='middle'>
    <Row style={{ width: '100%', height:'50%', paddingBottom: padding}} align='bottom'>
      {getButtons(buttons[0], {  
        setDetailView,
        setTitle,
        setDescription, 
      })}
    </Row>
    <Row style={{ width: '100%', height:'50%', paddingTop: padding}}  align='top'>
      {getButtons(buttons[1], {  
        setDetailView,
        setTitle,
        setDescription, 
      })}
    </Row>
  </Row> :
  <Row style={{ width: '100%', height:'80%'}} align='middle'>
     {getButtons(buttons, {  
        setDetailView,
        setTitle,
        setDescription, 
      })}
  </Row>
);
