import {
  GET_BUCKET_TEMPLATES,
  CREATE_BUCKET_TEMPLATE,
  UPDATE_BUCKET_TEMPLATE,
  DELETE_BUCKET_TEMPLATE,
  GET_BUCKETS,
  CREATE_BUCKET,
  UPDATE_BUCKET,
  DELETE_BUCKET,
  GET_BUCKET_NOTES,
  ADD_BUCKET_NOTE,
  GET_BUCKET_CUSTOM_DATA,
} from '../../state/actionTypes';

const initialState = {
  buckets: [],
  bucketTemplates: [],
  bucketNotes: {},
  bucketTemplateMap: {},
  customData: [],
  customDataFiles: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_BUCKET_TEMPLATES:
    case CREATE_BUCKET_TEMPLATE:
    case UPDATE_BUCKET_TEMPLATE:
    case DELETE_BUCKET_TEMPLATE:
      return {
        ...state,
        bucketTemplates: action.payload,
      };
    case GET_BUCKETS: {
      const {
        payload: {
          query = {},
          data: buckets = [],
        } = {},
      } = action;

      const { bucketTemplateMap = {} } = state;

      if (query.bucketTemplateIds) {
        const newBucketTemplateMap = { ...bucketTemplateMap };

        query.bucketTemplateIds.forEach((bucketTemplateId) => {
          newBucketTemplateMap[bucketTemplateId] = buckets.filter(
            (bucket) => bucket.bucketTemplateId === bucketTemplateId,
          );
        });
        return {
          ...state,
          bucketTemplateMap: newBucketTemplateMap,
        };
      }

      return {
        ...state,
        buckets,
      };
    }
    case CREATE_BUCKET:
    case UPDATE_BUCKET:
    case DELETE_BUCKET: {
      const { create, update, delete: deletePayload } = action.payload;

      let newBuckets = [...state.buckets];

      if (create) {
        newBuckets.push(create);
      }

      if (update) {
        newBuckets = newBuckets.map((bucket) => {
          if (!update[bucket.id]) return bucket;
          return {
            ...bucket,
            ...update[bucket.id],
          };
        });
      }

      if (deletePayload) {
        newBuckets = newBuckets.filter((bucket) => !deletePayload[bucket.id]);
      }

      return {
        ...state,
        buckets: newBuckets,
      };
    }
    case GET_BUCKET_NOTES: {
      const {
        payload: {
          id,
          data: notes = [],
        } = {},
      } = action;
      const { bucketNotes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      existingNotes[id] = notes;
      existingNotes[id].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        bucketNotes: existingNotes,
      };
    }
    case ADD_BUCKET_NOTE: {
      const {
        payload = {},
      } = action;
      const { bucketId } = payload;
      const { bucketNotes: stateNotes = {} } = state;
      const existingNotes = { ...stateNotes };
      const {
        [bucketId]: existingUserNotes = [],
      } = existingNotes;
      existingNotes[bucketId] = existingUserNotes.concat([payload]);
      existingNotes[bucketId].sort((a, b) => b.timestamp - a.timestamp);
      return {
        ...state,
        bucketNotes: existingNotes,
      };
    }
    case GET_BUCKET_CUSTOM_DATA: {
      const {
        data: {
          data = [],
          fileMap = {},
        } = {},
      } = action.payload;
      return {
        ...state,
        customData: data,
        customDataFiles: fileMap,
      };
    }
    default:
      return state;
  }
};
