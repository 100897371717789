import React, { useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Spin } from 'antd';
import { DragDropContext } from 'react-beautiful-dnd';

import { PropTypes } from 'prop-types';

import BoardListSection from './BoardListSection';

import { moveCard, setCardDragging } from '../state/boards.actions';

import { getCardsByStatus, handleCardMove } from '../boards.helpers';
import { getIdMap } from '../../helpers/helpers';

const styleByIndex = ({ idx, statuses = [] }) => {
  const style = {};
  if (idx === 0) return style;
  style.marginTop = 20;
  if (idx < statuses.length - 1) return style;
  style.marginBottom = 50;
  return style;
};

export default function BoardListView({
  id: boardId,
  cardTypeId,
  isLocked,
  disableCrossColumnSorting,
  showCardNumber,
  showListIndex,
  useCardNumberAsCardTitle,
  userBoardPermissions,
  loading,
  onBoardSelected,
}) {
  const dispatch = useDispatch();

  const selectedBoard = useSelector((state) => state.boards.selectedBoard);
  const { type: sortType, field } = useSelector((state) => state.boards.sortType);
  const {
    [cardTypeId]: cardTemplate = {},
  } = useSelector((state) => state.boards.cardTemplates);
  const isFullScreen = useSelector((state) => state.main.menuCollapsed || state.main.fullscreen);
  const filters = useSelector((state) => state.boards.filters);
  const projects = useSelector((state) => state.projects.projects);

  const {
    statuses = [],
    cards = [],
  } = selectedBoard ?? {};

  const projectIdMap = useMemo(() => getIdMap(projects), [projects]);
  const cardsByStatusId = useMemo(() => (
    getCardsByStatus({
      cards,
      sortType,
      filters,
      field,
      projects,
    })
  ), [cards, sortType, filters, field, projects]);

  const onDragStart = useCallback(() => {
    dispatch(setCardDragging(true));
  }, []);

  const onDragEnd = useCallback((dragEvent) => {
    dispatch(setCardDragging(false));
    const {
      draggableId,
      destinationId,
      destinationIndex,
      sourceId,
      sourceIndex,
    } = handleCardMove({
      disableCrossColumnSorting,
      cardsByStatusId,
      dragEvent,
    });
    if (!draggableId) return;
    const originalCard = cards.find((card) => card.id === draggableId);
    if (!originalCard) return;
    const { color: originalColor } = originalCard;
    dispatch(moveCard(draggableId, {
      boardId,
      destinationId,
      destinationIndex,
      sourceId,
      sourceIndex,
      color: originalColor,
    }));
  }, [cardsByStatusId, cards, disableCrossColumnSorting, boardId]);

  const sortedStatuses = useMemo(() => {
    const sorted = statuses;
    sorted.sort((a, b) => a.orderIndex - b.orderIndex);
    return sorted;
  }, [statuses]);

  if (!selectedBoard || loading) {
    return (
      <Row justify="center" align="middle" style={{ height: '100%', paddingBottom: 125 }}>
        <Spin />
      </Row>
    );
  }
  const className = isFullScreen ? 'board-list-view-container-fullscreen' : 'board-list-view-container-regular';
  return (
    <div className={className}>
      <DragDropContext
        onDragEnd={onDragEnd}
        onDragStart={onDragStart}
      >
        {
        sortedStatuses.map((status, idx) => (
          <BoardListSection
            key={status.id}
            status={status}
            showCardNumber={useCardNumberAsCardTitle ? false : showCardNumber}
            showListIndex={showListIndex}
            cardTemplate={cardTemplate}
            cards={cardsByStatusId[status.id] ?? []}
            style={styleByIndex({ idx, statuses })}
            isLocked={isLocked}
            userBoardPermissions={userBoardPermissions}
            projectIdMap={projectIdMap}
            onBoardSelected={onBoardSelected}
          />
        ))
      }
      </DragDropContext>
    </div>
  );
}
BoardListView.propTypes = {
  id: PropTypes.string.isRequired,
  cardTypeId: PropTypes.string.isRequired,
  isLocked: PropTypes.bool.isRequired,
  disableCrossColumnSorting: PropTypes.bool.isRequired,
  showCardNumber: PropTypes.bool,
  showListIndex: PropTypes.bool,
  useCardNumberAsCardTitle: PropTypes.bool,
  userBoardPermissions: PropTypes.object, // eslint-disable-line react/forbid-prop-types
  loading: PropTypes.bool,
  onBoardSelected: PropTypes.func.isRequired,
};

BoardListView.defaultProps = {
  showCardNumber: false,
  showListIndex: false,
  useCardNumberAsCardTitle: false,
  userBoardPermissions: {},
  loading: false,
};
