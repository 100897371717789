import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';

// Import Components:
import FullPhoto from '../../common/photos/FullPhoto';
import FileUpload from '../../common/files/FileUpload';

// Import Helpers/Constants:
import { downloadFile, getFileType } from '../../files/fileHelpers';
import { INVOICE_DRAWER_ADD_MODE, INVOICE_DRAWER_EDIT_MODE, INVOICE_DRAWER_VIEW_MODE } from './invoiceConstants';

/** Component that allows user to upload a PDF and immediately view it */
const PDFUploadView = ({
  mode,
  selectedFile,
  onFileSelect,
  removeSelectedFile,
}) => {
  const selectedFileType = useMemo(() => selectedFile ? getFileType(selectedFile) : null, [selectedFile]);

  const onDownload = useCallback(() => downloadFile({ fileObject: selectedFile }), [selectedFile]);

  return (
    <>
      {selectedFile || mode === INVOICE_DRAWER_VIEW_MODE
      ? <FullPhoto
        file={selectedFile}
        type={selectedFileType}
        allowClose={mode !== INVOICE_DRAWER_VIEW_MODE}
        onClose={removeSelectedFile}
        onDownload={onDownload}
        className='invoice-upload-contained'
        pdfStyle={{
          display: 'relative',
          width: '80vh',
          height: '78vh',
        }}
      />
      : <FileUpload
        addFile={onFileSelect}
        style={{
          position: 'static',
          width: '100%',
          height: '100%',
        }}
      />}
    </>
  );
};

PDFUploadView.propTypes = {
  mode: PropTypes.oneOf([
    INVOICE_DRAWER_ADD_MODE,
    INVOICE_DRAWER_EDIT_MODE,
    INVOICE_DRAWER_VIEW_MODE,
  ]),
  selectedFile: PropTypes.object,
  onFileSelect: PropTypes.func,
  removeSelectedFile: PropTypes.func,
};

export default PDFUploadView;
