import React, {
  useMemo,
} from 'react';
import { Row, Col } from 'antd';
import PropTypes from 'prop-types';

import UserDay from './UserDay';
import { getWeekStart, getDayKey } from '../scheduleHelpers';
import { BIWEEKLY_ROW_HEIGHT } from '../schedule.constants';

export default function UserWeek({
  user,
  date,
  endDate,
  shifts,
  onShiftSelect,
  numDays,
  style,
}) {
  const days = useMemo(() => new Array(numDays).fill(1), [numDays]);
  const startOfWeek = useMemo(() => getWeekStart(date), [date]);

  const dayShiftMap = useMemo(() => {
    const shiftMap = {};
    days.forEach((_, idx) => {
      const dayKey = getDayKey(startOfWeek.plus({ days: idx }));
      const allShiftsForDay = shifts[dayKey] ?? [];
      shiftMap[idx] = user
        ? allShiftsForDay.filter((shift) => new Set(shift.users).has(user.id))
        : allShiftsForDay.filter((shift) => shift.users.length === 0);
    });
    return shiftMap;
  }, [startOfWeek, shifts, days, user]);

  const dayViews = useMemo(() => (
    days.map((_, idx) => {
      const dt = startOfWeek.plus({ day: idx });
      return (
        <Col span={3} key={dt.toSQLDate()}>
          <UserDay
            user={user}
            day={dt}
            weekStart={startOfWeek}
            endDate={endDate}
            shifts={dayShiftMap[idx]}
            onShiftSelect={onShiftSelect}
            height={BIWEEKLY_ROW_HEIGHT}
          />
        </Col>
      );
    })
  ), [startOfWeek, days]);

  return (
    <Row
      className="schedule-user-week-row"
      style={style}
    >
      <Col span={3} className="schedule-user-week-row-user-name">
        {user ? user.name : 'Unassigned'}
      </Col>
      {dayViews}
    </Row>
  );
}

/* eslint-disable react/forbid-prop-types */
UserWeek.propTypes = {
  user: PropTypes.object,
  date: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  shifts: PropTypes.object.isRequired,
  onShiftSelect: PropTypes.func.isRequired,
  numDays: PropTypes.number.isRequired,
  style: PropTypes.object,
};

UserWeek.defaultProps = {
  user: undefined,
  style: undefined,
};
