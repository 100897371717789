import React, { useCallback, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { DateTime } from 'luxon';
import { useDrag } from 'react-dnd';

import Permissions from '../../auth/Permissions';
import { getIdMap } from '../../helpers/helpers';
import { getBackgroundColor } from '../scheduleHelpers';

export default ({
  user,
  shift = {},
  onClick
}) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'MonthShiftDayEntry',
    item: { id: shift?.id, userId: user?.id, shift },
    isDragging: (monitor) => shift?.id === monitor.getItem().id,
    collect: (monitor) => ({
      isDragging: shift?.id === monitor?.getItem()?.id,
    })
  }))

  const {
    startTime,
    title,
    users = [],
    isEvent,
    color,
  } = shift;
  const timeText = startTime
    ? `${DateTime.fromMillis(startTime).toLocaleString(DateTime.TIME_SIMPLE)}` : '';
  
  const stateUsers = useSelector(state => state.users.users);

  const handleClick = useCallback((e) => {
    e.stopPropagation();
    onClick(shift)
  },[shift,onClick]);

  const userMap = useMemo(() => getIdMap(stateUsers),[stateUsers]);
  const isMyShift = useMemo(() => users && users.includes(Permissions.id),[users]);
  const backgroundColor = getBackgroundColor(users, userMap, color, isMyShift, isEvent);

  return (
    <div
      className='schedule-month-day-entry'
      onClick={handleClick}
      ref={drag}
      style={{ opacity: isDragging ? 0.5 : 1}}
    >
      <div className={`schedule-month-day-entry-dot-${isMyShift ? 'mine' : 'other'}`} style={{backgroundColor}}/>
      <span>{timeText} <b>{title}</b></span>
    </div>
  );
}
