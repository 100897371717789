import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Common, TaskHelpers } from 'ontraccr-common';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatProjectLabelFromCompanySettings } from '../projects/projectHelpers';

const { Text } = Typography;
const COSTCODE_HEADER = 'Costcode: ';

function UserListClock({
  id,
}) {
  const { t } = useTranslation();

  const timeEntryUserMap = useSelector((state) => state.timeTracking.timeEntryUserMap);
  const costcodes = useSelector((state) => state.costcodes.costcodes);
  const projects = useSelector((state) => state.projects.projects);
  const { settings = {} } = useSelector((state) => state.settings.company);

  const costcodeIdMap = useMemo(() => Common.getIdMap(costcodes), [costcodes]);
  const projectIdMap = useMemo(() => Common.getIdMap(projects), [projects]);

  const formattedDay = useMemo(() => TaskHelpers
    .formatTodaysTasks(timeEntryUserMap[id]), [timeEntryUserMap, id]);

  const { currentTask } = formattedDay ?? {};

  const { header: projectHeader, text: projectText } = useMemo(() => {
    const {
      projectId,
      serviceLocation,
    } = currentTask ?? {};
    if (serviceLocation) {
      // Task is a service task
      return {
        header: 'Service Location: ',
        text: serviceLocation,
      };
    }
    return {
      header: `${t('Project')}: `,
      text: formatProjectLabelFromCompanySettings({
        name: projectIdMap[projectId]?.name ?? '--',
        number: projectIdMap[projectId]?.number,
        settings,
      }),
    };
  }, [currentTask, projectIdMap, settings]);
  const costcodeText = costcodeIdMap[currentTask?.costcodeId]?.name
    ?? '--';

  return currentTask ? (
    <Row gutter={5}>
      <Col>
        <Row>
          <Text style={{ fontFamily: 'raleway-semibold' }}>
            {projectHeader}
          </Text>
        </Row>
        <Row>
          <Text style={{ fontFamily: 'raleway-semibold' }}>
            {COSTCODE_HEADER}
          </Text>
        </Row>
      </Col>
      <Col flex="auto">
        <Row flex="auto" justify="center">
          <Text ellipsis>
            {projectText}
          </Text>
        </Row>
        <Row flex="auto" justify="center">
          <Text ellipsis>
            {costcodeText}
          </Text>
        </Row>
      </Col>
    </Row>
  ) : (
    'Inactive'
  );
}

export default UserListClock;
