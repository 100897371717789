import axios from 'axios';
import { request } from '../../helpers/requests';

const NuxService = {
  create: (type) => request({
    call: axios.post('/nux', { type }),
    hideSuccessToast: true,
  }),

  delete: (type) => request({
    call: axios.delete(`/nux/${type}`),
    hideSuccessToast: true,
  }),

  get: () => request({
    call: axios.get('/nux'),
    defaultData: [],
    hideSuccessToast: true,
  }),
};

export default NuxService;
