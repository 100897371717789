import React, { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Drawer, Tabs } from 'antd';
import PropTypes from 'prop-types';
import FormCustomFilterTab from './FormCustomFilterTab';

const { TabPane } = Tabs;

function FormFilterDrawer({
  visible,
  onClose,
}) {
  const [activeTab, setActiveTab] = useState('custom');

  const formFilterViews = useSelector((state) => state.forms.formFilterViews);
  const activeFilters = useSelector((state) => state.forms.formFilters);

  useEffect(() => {
    setActiveTab(activeFilters?.filterView || 'custom');
  }, [activeFilters]);

  const onTabChange = useCallback((key) => {
    setActiveTab(key);
  }, []);

  const onDrawerClose = useCallback(() => {
    onClose();
    setActiveTab(activeFilters?.filterView || 'custom');
  }, [onClose, activeFilters]);

  return (
    <Drawer
      title="Filters for Forms Log"
      visible={visible}
      onClose={onDrawerClose}
      width={900}
      destroyOnClose
    >
      <Tabs
        activeKey={activeTab}
        tabPosition="left"
        onChange={onTabChange}
        className="filter-drawer-tabs"
        destroyInactiveTabPane
      >
        <TabPane
          tab="Custom View"
          key="custom"
          style={{ marginBottom: 50 }}
        >
          <FormCustomFilterTab
            onClose={onDrawerClose}
            setActiveTab={setActiveTab}
          />
        </TabPane>
        {formFilterViews.map((view) => (
          <TabPane
            style={{ marginBottom: 50 }}
            tab={view.filterViewName}
            key={view.id}
          >
            <FormCustomFilterTab
              onClose={onDrawerClose}
              setActiveTab={setActiveTab}
              filterView={view}
            />
          </TabPane>
        ))}
      </Tabs>
    </Drawer>
  );
}

FormFilterDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default FormFilterDrawer;
