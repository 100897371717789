import { DateTime } from 'luxon';
import {
  CLOCKIN,
  START_BREAK,
  END_BREAK,
  SWITCH_TASK,
  CLOCKOUT,
  MANUAL_ENTRY,
} from 'ontraccr-common/lib/timeTracking/state/actionTypes';

import Analytics from '../../helpers/Analytics';
import {
  GET_ALL_USERS,
  GET_USERS_BY_ID,
  CREATE_USER,
  UPDATE_USER_BY_ID,
  DELETE_USER_BY_ID,
  ARCHIVE_USER,
  MASS_ARCHIVE_USERS,
  ACCEPT_TOS,
  GET_USER_TEAMS,
  GET_USER_LABELS,
  GET_USER_FILES,
  ADD_USER_NOTE,
  GET_USER_NOTES,
  GET_USER_SETTINGS,
  UPDATE_USER_SETTINGS,
  GET_USER_CUSTOM_FIELD_TEMPLATE,
  UPDATE_USER_CUSTOM_FIELD_TEMPLATE,
  GET_USER_CUSTOM_DATA,
} from '../../state/actionTypes';
import UserService from './users.service';

import {
  compareArrays,
  compareStringArrays,
  getId,
} from '../../helpers/helpers';
import { decorateFormWithFiles } from '../../forms/formHelpers';

import { counters, counterWasRecentlyCalled } from '../../helpers/counters';

const {
  users: userCounters,
} = counters;

function receiveUsers(users) {
  return {
    type: GET_ALL_USERS,
    payload: {
      users,
    },
  };
}

export const getUsers = (shouldIgnoreCounter = false) => async (dispatch) => {
  if (!shouldIgnoreCounter && counterWasRecentlyCalled('users.getUsers')) return true;
  userCounters.getUsers = DateTime.local();
  const { data: users } = await UserService.getAll();
  if (!users) {
    userCounters.getUsers = null;
    return false;
  }
  dispatch(receiveUsers(users));
  return true;
};

export const getUserById = (id) => async (dispatch) => {
  const selectedUser = await UserService.get(id);
  dispatch({
    type: GET_USERS_BY_ID,
    payload: {
      selectedUser,
    },
  });
  return true;
};

export const createUser = (user, projectNameMap, teamNameMap) => async (dispatch) => {
  const {
    projects: newProjectNames = [],
    teams: newTeamNames = [],
    labels: newLabels = [],
  } = user;
  const newProjects = [];
  const newTeams = [];

  newProjectNames.forEach((projectName) => {
    if (projectName in projectNameMap) {
      newProjects.push(projectNameMap[projectName].id);
    }
  });

  newTeamNames.forEach((teamName) => {
    if (teamName in teamNameMap) {
      newTeams.push(teamNameMap[teamName].id);
    }
  });

  const payload = {
    ...user,
  };
  if (newLabels.length > 0) {
    payload.labels = newLabels.map(({ id: labelId, title }) => ({ labelId, title }));
  }
  if (newProjects.length > 0) payload.projects = newProjects;
  if (newTeams.length > 0) payload.teams = newTeams;
  if (payload.phoneNumber === '') delete payload.phoneNumber;
  Analytics.track('User/Create');
  const { data } = await UserService.create(payload);
  if (!data) return false;
  dispatch({
    type: CREATE_USER,
    payload: {
      user: {
        ...user,
        id: data.id,
        labels: data.labels,
      },
    },
  });
  return data;
};

export const updateUserById = ({
  ourUser,
  projectNameMap,
  teamNameMap,
  values,
  userDivisions = {},
  userToLabel = {},
  userFiles = {},
}) => async (dispatch) => {
  const {
    id,
  } = ourUser;
  const {
    [id]: oldDivisions = [],
  } = userDivisions;
  const {
    [id]: oldLabels = [],
  } = userToLabel;

  const {
    [id]: oldFiles = [],
  } = userFiles;

  const {
    projects: newProjectNames = [],
    teams: newTeamNames = [],
    divisions: newDivisions = [],
    labels: newLabels = [],
    files: newFiles = [],
  } = values;
  const newProjects = [];
  const newTeams = [];

  newProjectNames.forEach((projectName) => {
    if (projectName in projectNameMap) {
      newProjects.push(projectNameMap[projectName]);
    }
  });

  newTeamNames.forEach((teamName) => {
    if (teamName in teamNameMap) {
      newTeams.push(teamNameMap[teamName]);
    }
  });

  const { removed, added } = compareArrays(ourUser.projects, newProjects);
  const { removed: removedTeams, added: addedTeams } = compareArrays(ourUser.teams, newTeams);

  // Cant use compareArrays because it requires an array
  // of objects. Divisions is an array of ids
  const {
    removed: removedDivisions,
    added: addedDivisions,
  } = compareStringArrays(oldDivisions, newDivisions);

  // oldLabels is an array of labelIds
  // newLabels is an array of objects with id property
  const {
    removed: removedLabels,
    added: addedLabels,
  } = compareArrays(oldLabels.map((l) => ({ id: l })), newLabels, 'id');

  // Files will not have an id if theyre new
  const {
    removed: removedFiles,
    added: addedFiles,
  } = compareArrays(oldFiles, newFiles);

  const payload = {
    ...values,
  };
  if (values.username) payload.username = values.username.trim();
  if (values.email) payload.email = values.email.trim();
  if (removed.length) payload.removedProjects = removed.map(getId);
  if (added.length) payload.addedProjects = added.map(getId);
  if (removedTeams.length) payload.removedTeams = removedTeams.map(getId);
  if (addedTeams.length) payload.addedTeams = addedTeams.map(getId);
  if (removedDivisions.length) payload.removedDivisions = removedDivisions;
  if (addedDivisions.length) payload.addedDivisions = addedDivisions;
  if (removedLabels.length) {
    payload.removedLabels = removedLabels.map(({ id: labelId }) => labelId);
  }
  if (addedLabels.length) {
    payload.addedLabels = addedLabels.map(({ id: labelId, title }) => ({ labelId, title }));
  }
  if (removedFiles.length) payload.removedFiles = removedFiles;
  if (addedFiles.length) payload.addedFiles = addedFiles;

  if (payload.projects) delete payload.projects;
  if (payload.teams) delete payload.teams;
  if (payload.divisions) delete payload.divisions;
  if (payload.labels) delete payload.labels;

  if (values.union) payload.classId = values.union.classId ?? null;
  delete payload.union;

  if (payload.pin === undefined) delete payload.pin;
  if (payload.timezone === undefined) delete payload.timezone;

  if (!Object.keys(payload).length) return true;
  if (payload.confirmPassword) delete payload.confirmPassword;
  Analytics.track('User/Edit');
  const { data } = await UserService.update(id, payload);
  if (!data) return false;
  dispatch({
    type: UPDATE_USER_BY_ID,
    payload: {
      user: {
        ...payload,
        id,
        removedProjects: removed,
        addedProjects: added,
        removedTeams,
        addedTeams,
        removedDivisions,
        addedDivisions,
        addedLabels: data.labels,
        removedLabels,
      },
    },
  });
  return data;
};

export const deleteUserById = (id) => async (dispatch) => {
  Analytics.track('User/Delete');
  await UserService.delete(id);
  dispatch({
    type: DELETE_USER_BY_ID,
    payload: {
      userId: id,
    },
  });
  return true;
};

export const archiveUser = (id, active = false) => async (dispatch) => {
  Analytics.track('User/Archive');
  await UserService.archive(id, active);
  dispatch({
    type: ARCHIVE_USER,
    payload: {
      userId: id,
      active,
    },
  });
  return true;
};

export const massArchive = (ids) => async (dispatch) => {
  Analytics.track('Account/Downgrade');
  const { data } = await UserService.massArchive(ids);
  if (!data) return false;
  dispatch({
    type: MASS_ARCHIVE_USERS,
    payload: {
      userIds: ids,
    },
  });
  return true;
};

export const acceptToS = () => async (dispatch) => {
  Analytics.track('ToS/Accept');
  const { data } = await UserService.acceptToS();
  if (!data) return false;
  dispatch({
    type: ACCEPT_TOS,
  });
  return true;
};

export const getUserTeams = () => async (dispatch) => {
  if (counterWasRecentlyCalled('users.getUserTeams')) return true;
  userCounters.getUserTeams = DateTime.local();
  const { data } = await UserService.getUserTeams();
  if (!data) {
    userCounters.getUserTeams = null;
    return false;
  }
  dispatch({
    type: GET_USER_TEAMS,
    payload: {
      userTeams: data,
    },
  });
  Analytics.track('Users/GetTeams');
  return true;
};

export const getUserLabels = () => async (dispatch) => {
  const { data } = await UserService.getUserLabels();
  if (!data) return false;
  dispatch({
    type: GET_USER_LABELS,
    payload: {
      userLabels: data,
    },
  });
  return true;
};

export const getUserFiles = (id) => async (dispatch) => {
  const { data } = await UserService.getUserFiles(id);
  if (!data) return false;
  dispatch({
    type: GET_USER_FILES,
    payload: {
      userId: id,
      files: data,
    },
  });
  return true;
};

export const updateUserClockInState = (payload) => ({
  type: CLOCKIN,
  payload,
});

export const updateUserClockOut = (payload) => ({
  type: CLOCKOUT,
  payload,
});

export const goOnBreak = (data) => {
  const {
    previousEnd,
    ...newEntry
  } = data;
  return {
    type: START_BREAK,
    payload: {
      newEntry,
      previousEnd,
    },
  };
};

export const endBreak = (data) => {
  const {
    previousEnd,
    ...newEntry
  } = data;
  return {
    type: END_BREAK,
    payload: {
      newEntry,
      previousEnd,
    },
  };
};

export const switchTask = ({
  previousEnd,
  ...newEntry
}) => ({
  type: SWITCH_TASK,
  payload: {
    newEntry,
    previousEnd: previousEnd || DateTime.local().toMillis(),
  },
});

export const handleMultipleTasks = (payload) => ({
  type: MANUAL_ENTRY,
  payload,
});

export const getNotes = (id) => async (dispatch) => {
  const { data } = await UserService.getNotes(id);
  if (!data) return false;
  dispatch({
    type: GET_USER_NOTES,
    payload: {
      id,
      data,
    },
  });
  return true;
};

export const addNote = ({ id, text }) => async (dispatch) => {
  const { data } = await UserService.addNote(id, text);
  if (!data) return false;
  dispatch({
    type: ADD_USER_NOTE,
    payload: data,
  });
  return true;
};

export const getUserSettings = () => async (dispatch) => {
  const { data } = await UserService.getUserSettings();
  if (!data) return false;
  dispatch({
    type: GET_USER_SETTINGS,
    payload: data[0],
  });
  return true;
};

export const updateUserSettings = (payload) => async (dispatch) => {
  const { data } = await UserService.updateUserSettings(payload);
  if (!data || !data.length) return false;
  dispatch({
    type: UPDATE_USER_SETTINGS,
    payload: data[0],
  });
  return true;
};

export const getUserCustomFieldTemplate = () => async (dispatch) => {
  const { data: template } = await UserService.getCustomFieldTemplate();
  dispatch({
    type: GET_USER_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
    },
  });
};

export const updateUserCustomFieldTemplate = (payload) => async (dispatch) => {
  const { data: template } = await UserService.updateCustomFieldTemplate(payload);
  dispatch({
    type: UPDATE_USER_CUSTOM_FIELD_TEMPLATE,
    payload: {
      template,
    },
  });
};

export const getUserCustomData = (id) => async (dispatch) => {
  if (!id) return dispatch({ type: GET_USER_CUSTOM_DATA, payload: { data: [] } });

  const { data } = await UserService.getCustomData(id);
  await decorateFormWithFiles({ fileMap: data.fileMap });

  if (!data) return false;
  dispatch({
    type: GET_USER_CUSTOM_DATA,
    payload: {
      data,
    },
  });
  return true;
};