import React, { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Form } from 'antd';

// Import Components:
import SimpleFormModal from '../../../common/modals/SimpleFormModal';
import FormTextInput from '../../../common/inputs/FormTextInput';
import FormSelectorInput from '../../../common/inputs/FormSelectorInput';

// Other:
import Permissions from '../../../auth/Permissions';
import { createCustomChannel, editCustomChannel } from '../state/livefeed.actions';

/** Live Feed Add Channel Modal */
const LiveFeedChannelModal = ({
  visible,
  selectedId,
  onClose,
}) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const users = useSelector((state) => state.users.users);
  const customChannels = useSelector((state) => state.livefeed.customChannels);

  const userOptions = useMemo(() => users
    .filter(({ id }) => id !== Permissions.id)
    .map(({ name, id }) => ({ label: name, value: id })), [users]);
  const existingCustomChannelNames = useMemo(() => new Set(Array.from(customChannels).map(({ name }) => name)), [customChannels]);

  const validateChannelName = useCallback((_rule, value) => {
    if (existingCustomChannelNames.has(value)) return Promise.reject('Channel names must be unique');
    return Promise.resolve();
  }, []);

  const onSaveHandler = useCallback(async () => {
    const { name: channelName, users: channelUsers = [] } = form.getFieldsValue();
    const action = selectedId ? editCustomChannel : createCustomChannel;
    const payload = {
      name: channelName,
      users: channelUsers,
    };
    if (selectedId) payload.id = selectedId;
    const res = await dispatch(action(payload));
    if (res && onClose) onClose();
  }, [form, selectedId, onClose]);

  // On edit, set initial values:
  useEffect(() => {
    if (!selectedId) form.resetFields();
    const {
      name: existingChannelName,
      users: existingChannelUsers = [],
    } = customChannels[selectedId] || {};
    const otherChannelUsers = existingChannelUsers.filter((id) => id !== Permissions.id);
    form.setFieldsValue({
      name: existingChannelName,
      users: otherChannelUsers,
    });
  }, [selectedId, customChannels, form]);

  return (
    <SimpleFormModal
      title={selectedId ? 'Edit Existing Channel' : 'Create New Channel'}
      visible={visible}
      onClose={onClose}
      onSave={onSaveHandler}
    >
      <Form
        form={form}
        layout="vertical"
        style={{ width: '100%' }}
      >
        <FormTextInput
          label="Name"
          name="name"
          isNotDisplay
          rules={[
            { required: true, message: 'Please specify channel name' },
            { validator: validateChannelName },
          ]}
        />
        <FormSelectorInput
          label="Users"
          name="users"
          isNotDisplay
          rules={[{ required: true, message: 'Please select other users to add' }]}
          selectProps={{
            mode: 'multiple',
            allowClear: true,
            showSearch: true,
            options: userOptions,
            optionFilterProp: 'label'
          }}
        />
      </Form>
    </SimpleFormModal>
  );
};

LiveFeedChannelModal.propTypes = {
  visible: PropTypes.bool,
  selectedId: PropTypes.string,
  onClose: PropTypes.func,
};

LiveFeedChannelModal.defaultProps = {
  visible: false,
  selectedId: null,
  onClose: () => null,
};

export default LiveFeedChannelModal;
