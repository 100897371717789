import React from 'react';
import { Row, Col, Switch, } from 'antd';
import { SwapLeftOutlined } from '@ant-design/icons';

import BorderlessButton from '../../common/buttons/BorderlessButton';
import DisplayText from '../../common/text/DisplayText';
import HoverHelp from '../../common/HoverHelp';

import Logo from '../../common/Logo';

export default ({
  title,
  integrationKey,
  onChanged,
  syncSettings = {},
  showLogos = true,
  integrationLogo,
  pushHelp,
  pullHelp,
}) => {
  const pushKey = `${integrationKey}IntegrationPush`;
  const pullKey = `${integrationKey}IntegrationPull`;
  const {
    [pushKey]:pushSetting,
    [pullKey]:pullSetting,
  } = syncSettings;
  return (
    <Row style={{ width: '100%', margin:0,  }} justify='center' align='middle' gutter={30}>
      <Row style={{ width: '100%'}} gutter={10}>
        <Col>
          <Switch checked={pullSetting} onChange={() => onChanged({
            [pullKey]:!pullSetting,
            [pushKey]:pushSetting
          })}/>
        </Col>
        <Col>
          <DisplayText
            title={`Auto-Sync from ${title} to Ontraccr`}
            style={{
              lineHeight: '22px',
              marginBottom:0,
            }}
          />
        </Col>
        <Col style={{ height:20}}>
          <HoverHelp content={pullHelp} placement='rightTop'/>
        </Col>
      </Row>
      <Row style={{ width: '100%', marginTop: 15 }} gutter={10}>
        <Col>
          <Switch checked={pushSetting} onChange={() => onChanged({
            [pullKey]:pullSetting,
            [pushKey]:!pushSetting
          })}/>
        </Col>
        <Col>
          <DisplayText
            title={`Auto-Sync from Ontraccr to ${title}`}
            style={{
              lineHeight: '22px',
              marginBottom:0,
            }}
          />
        </Col>
        <Col style={{ height:20}}>
          <HoverHelp content={pushHelp} placement='rightTop'/>
        </Col>
      </Row>
      {showLogos && <Row style={{ width: '100%', marginTop: 100 }} justify='center' align='middle' gutter={20}>
        <Col>
          <Logo height={95}/>
        </Col>
        <Col>
          <Row>
            <BorderlessButton
              iconNode={
                <SwapLeftOutlined
                  style={{
                    margin:0,
                    fontSize: 40,
                    color: pullSetting ? 'forestgreen' : 'lightgray',
                  }}
                />
              }
              onClick={() => onChanged({
                [pullKey]:!pullSetting,
                [pushKey]:pushSetting
              })}
            />
          </Row>
          <Row>
            <BorderlessButton
              iconNode={
                <SwapLeftOutlined
                  style={{ 
                    margin:0,
                    fontSize: 40,
                    color: pushSetting ? 'forestgreen' : 'lightgray',
                  }}
                  rotate={180}/>
              }
              onClick={() => onChanged({
                [pullKey]:pullSetting,
                [pushKey]:!pushSetting
              })}
            />
          </Row>
        </Col>
        <Col>
          {integrationLogo}
        </Col>
      </Row>}
    </Row>
  )
};
