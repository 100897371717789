import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { Row } from 'antd';
import axios from 'axios';

import CompanySettingsCard from './CompanySettingsCard';
import DisplayText from '../common/text/DisplayText';

import OnTraccrButton from '../common/buttons/OnTraccrButton';

const helpText = `If you need access to the billing portal please contact your Primary Account Owner or
contact support@ontraccr.com for support.
`;

const TitleAndSubtitle = ({
  title,
  subTitle,
}) => (
  <>
    <DisplayText
      title={title}
      style={{ fontFamily: 'roboto-medium', marginBottom:0, marginTop: 10, minHeight: 32 }}
    />
      <DisplayText
      title={subTitle}
      style={{
        fontFamily: 'roboto-light',
        marginBottom:0,
      }}
    />
  </>
)
export default ({ billingURL, isOwner = false, }) => {
  const [accountInfo,setAccountInfo] = useState({});
  const [loading, setLoading] = useState(false);

  const openBillingPortal = useCallback(() => {
    if(billingURL) window.location.href = billingURL;
  },[billingURL]);

  const configurePaymentProcessing = useCallback(async () => {
    let url;
    setLoading(true);
    if (accountInfo.account && accountInfo.active) {
      url = 'https://dashboard.stripe.com/';
    } else {
      const { data: configureURL } = await axios.post('/payments/configure');
      url = configureURL;
    }
    setLoading(false);
    window.location.href = url;
  },[accountInfo]);

  useEffect(() => {
    const getInfo = async () => {
      const { data: newAccountInfo } = await axios.get('/payments/status');
      setAccountInfo(newAccountInfo);
    }
    if(isOwner) getInfo();
  },[isOwner]);

  const paymentButtonTitle = useMemo(() => {
    const { account, active } = accountInfo;
    if (!account) return 'Set up Payment Processing'
    return active
      ? 'View Payments Dashboard'
      : 'Continue Setup';
  },[accountInfo])

  const mainStyle = {
    width: '100%',
    height: '100%',
    marginLeft:177,
    marginTop:7
  };
  return (
    <Row 
      style={mainStyle}
      justify='start'
      align='top'
    >
      <CompanySettingsCard title='Billing'>
        {
          isOwner ?
          <>
            <TitleAndSubtitle
              title='Open Billing Portal'
              subTitle='Use the Billing Portal to view and manage your billing details.'
            />
            <Row style={{ marginTop: 20, marginBottom: 40 }} justify='center'>
              <OnTraccrButton
                title='Open Billing Portal'
                onClick={openBillingPortal}
              />
            </Row>
            <TitleAndSubtitle
              title='Process Payments with Ontraccr'
              subTitle='Set up Ontraccr to process payments for your account.'
            />
            <Row style={{ margin: '20px 0px' }} justify='center'>
              <OnTraccrButton
                title={paymentButtonTitle}
                onClick={configurePaymentProcessing}
                loading={loading}
              />
            </Row>
          </>
          :
          <>
            <DisplayText
              title='Only the Primary Account Owner can view the billing portal'
              style={{ fontFamily: 'roboto-medium', marginBottom:0, marginTop: 10, minHeight: 32 }}
            />

            <DisplayText
              title={helpText}
              style={{
                fontFamily: 'roboto-light',
                marginBottom:0,
                height:150,
              }}
            />
          </>
        }
       
      </CompanySettingsCard>
    </Row>
  )
};
