import React from 'react';
import { Select, Row, Col } from 'antd';
 
import CustomModal from '../common/modals/CustomModal';
import ProfileAvatar from '../common/ProfileAvatar';
import OnTraccrButton from '../common/buttons/OnTraccrButton';
import DisplayText from '../common/text/DisplayText';

import colors from '../constants/Colors';

const warningText = 'Warning: This action cannot be undone. If you need ownership back, this setting will have to be updated by the new owner.'

const getButton = (color) => ({
  backgroundColor: color,
  borderColor: color,
  color: 'white',
  borderRadius: 18,
  width: 110,
  height: 36,
});

export default ({
  visible,
  onCloseClicked,
  afterClose,
  adminUsers = [],
  onChange,
  canSubmit,
  onSubmit
}) => (
  <CustomModal
    title={'Transfer Ownership'}
    visible={visible}
    onCloseClicked={onCloseClicked}
    afterClose={afterClose}
    maskColor={'#00000073'}
  >
    <div style={{
      width:'100%',
      padding:20,
    }}>
      <Select
        className='OnTraccrSelect'
        key='company-owner-select'
        style={{ width: '100%' }}
        placeholder='Select new owner'
        onChange={onChange}
        optionLabelProp='label'
      >
        {adminUsers.map((item) => 
          <Select.Option key={item.id} value={item.name} label={item.name}>
            {
              <ProfileAvatar key={item.id} {...{...item,role:item.position}} style={{ marginTop:2, marginBottom:2,}}/>
            }
        </Select.Option>)}
      </Select>
      <Row type='flex' style={{ width: '100%', marginTop: 15}}>
        <DisplayText title={warningText} style={{
          fontFamily: 'roboto-bold', 
          fontSize:14,
          color: colors.ONTRACCR_TITLE_BLACK
        }}/>
      </Row>
      <Row type='flex' justify='center' gutter={16} style={{
          width:'100%',
          marginTop:15
        }}>
        <Col>
          <OnTraccrButton
            title='Cancel'
            onClick={onCloseClicked}
            type='Cancel'
            style={getButton('gray')}
            />
        </Col>
        <Col>
          <OnTraccrButton 
            title='Confirm'
            disabled={!canSubmit}
            onClick={onSubmit}
            type='primary'
            style={getButton(colors.ONTRACCR_RED)}
            />
        </Col>
      </Row>
    </div>
  </CustomModal>
);