import React from 'react';
import { Checkbox, Row, Col } from 'antd';
import { formatPerm, isMultiCheckboxDisabled, writeControlledPermissions } from './permissionConstants';

const isChecked = (key, role, position = []) => (
  position.some((perm) => perm === formatPerm(key, role))
);

export default function RoleCheckboxes({
  myRole,
  positions = {},
  callback,
  key,
  reports,
  disabled = false,
  t,
}) {
  const isReports = key === 'reports_view';
  const splitRows = !isReports ? [['Self']] : [[]];
  const roles = isReports ? reports : Object.keys(positions);

  roles.forEach((row) => {
    let last = splitRows.length - 1;
    if (splitRows[last].length === 5) {
      splitRows.push([]);
      last += 1;
    }
    splitRows[last].push(row);
  });
  return splitRows.map((row, index) => (
    <Row key={index}>
      {row.map((role) => (
        <Col key={role}>
          <Checkbox
            checked={isChecked(key, role, positions[myRole])}
            key={role}
            onChange={() => {
              callback(formatPerm(key, role));
              const perms = positions[myRole];
              if (writeControlledPermissions.has(key)) {
                let writePerm;
                const readPerm = formatPerm(key, role);
                if (key === 'users_view') {
                  writePerm = formatPerm('users', role);
                } else {
                  writePerm = readPerm.replace('VIEW', 'EDIT');
                }
                let hasRead;
                let hasWrite;

                perms.forEach((perm) => {
                  if (perm === readPerm) {
                    hasRead = true;
                  } else if (perm === writePerm) {
                    hasWrite = true;
                  }
                });

                if (hasRead && hasWrite) callback(writePerm);
              }
            }}
            style={{ marginTop: 10 }}
            disabled={disabled || isMultiCheckboxDisabled(key, role, positions[myRole])}
          >
            {t(role, { project: t('Project') })}
          </Checkbox>
        </Col>
      ))}
    </Row>
  ));
}
