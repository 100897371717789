import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Drawer,
  Row,
  Form,
  Button,
  notification,
} from 'antd';

import FormCurrencyInput from '../../common/inputs/FormCurrencyInput';
import OnTraccrTextInput from '../../common/inputs/OnTraccrTextInput';
import DisplayText from '../../common/text/DisplayText';

import {
  getNumberText
} from './helpers';

function ScheduleOfValuesAddRow({
  templateId,
  newItemNumber,
  onClose,
  visible,
  handleSubmit,
  liveSummaryValues,
  isSubContract,
  isChangeOrder,
  isNonHoldback,
}) {
  const [formRef, setFormRef] = useState();
  const [max, setMax] = useState(Number.MAX_VALUE);

  useEffect(() => {
    if (formRef) {
      formRef.resetFields();
      formRef.setFieldsValue({
        itemNumber: newItemNumber.toString(),
      });
    }
  }, [formRef, newItemNumber, isChangeOrder]);

  useEffect(() => {
    if (liveSummaryValues.length) {
      setMax(liveSummaryValues[0].totalContractAmount - liveSummaryValues[0].currentContractValue);
    }
  }, [liveSummaryValues]);

  const validateContractAmount = useCallback((value) => {
    if (value > max && !isChangeOrder && !isNonHoldback && !templateId) {
      notification.warn({
        key: 'addRowKey',
        message: 'Warning',
        description: 'Contract amount is greater than available contract amount',
      });
    } else {
      notification.close('addRowKey');
    }
  }, [templateId, max, isChangeOrder, isNonHoldback]);

  const title = useMemo(() => (
    getNumberText({ isChangeOrder, isSubContract, itemNumber: newItemNumber })
  ), [isSubContract, isChangeOrder, newItemNumber]);

  return (
    <Drawer
      title="Add Row"
      visible={visible}
      onClose={onClose}
      width={750}
      maskClosable={false}
    >
      <Form
        onFinish={handleSubmit}
        ref={setFormRef}
        className="schedule-of-values-form"
        style={{
          maxWidth: '95%',
        }}
      >
        <Form.Item
          name="itemNumber"
          label="Item Number"
        >
          <DisplayText title={title} />
        </Form.Item>
        <Form.Item
          name="description"
          label="Description"
          rules={[
            { required: true, message: 'Description is required' },
          ]}
        >
          <OnTraccrTextInput
            style={{ width: '100%' }}
            placeholder="Description"
          />
        </Form.Item>
        <FormCurrencyInput
          isNotDisplay
          name="contractAmount"
          label="Contract Amount"
          placeholder={0}
          rules={[
            { required: true, message: 'Contract amount is required.' },
          ]}
          onChange={(value) => { validateContractAmount(value); }}
        />
        <Row justify="center">
          <Button
            htmlType="submit"
            className="login-form-button"
            type="primary"
            style={{
              marginTop: 10,
              borderRadius: 21,
              borderWidth: 1.5,
              width: 140,
              height: 42,
            }}
          >
            Add
          </Button>
        </Row>
      </Form>
    </Drawer>
  );
}

/* eslint-disable react/forbid-prop-types */
ScheduleOfValuesAddRow.propTypes = {
  templateId: PropTypes.string,
  newItemNumber: PropTypes.number.isRequired,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool,
  handleSubmit: PropTypes.func.isRequired,
  liveSummaryValues: PropTypes.array,
  isSubContract: PropTypes.bool,
  isChangeOrder: PropTypes.bool,
  isNonHoldback: PropTypes.bool,
};

ScheduleOfValuesAddRow.defaultProps = {
  templateId: null,
  visible: false,
  liveSummaryValues: [],
  isSubContract: false,
  isChangeOrder: false,
  isNonHoldback: false,
};

export default ScheduleOfValuesAddRow;
