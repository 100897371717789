import React, {
  useState,
  useCallback,
} from 'react';
import { Row, Col } from 'antd';
import { useDispatch } from 'react-redux';
import ScheduleOfValuesInput from '../ScheduleOfValuesInput';
import { updateProjectScheduleOfValuePDFRows } from '../../state/projects.actions';

const ScheduleOfValuesPDFPreviewFormInput = ({
  projectId,
  templateId,
  pdfRow,
  property,
  title
}) => {
  const dispatch = useDispatch();
  const [ showPopover, setShowPopover ] = useState(false)
  const [ value, setValue ] = useState(pdfRow[property]);

  const onConfirm = useCallback(async () => {
    let updatedRow = {
      key: pdfRow.key,
      value: pdfRow.value,
      [property]: value,
    };

    if(await dispatch(updateProjectScheduleOfValuePDFRows({
      projectId,
      templateId,
      rowId: pdfRow.id,
      payload: updatedRow,
    }))) {
      setShowPopover(false);
    }
  },[value, dispatch, projectId, templateId, pdfRow]);

  const onCancel = useCallback(() => {
    setValue(pdfRow[property]);
    setShowPopover(false);
  }, [pdfRow]);

  const onChange = useCallback((newValue) => {
    setShowPopover(true);

    if (pdfRow[property] === newValue.target.value) {
      setShowPopover(false);
    }

    setValue(newValue.target.value);
  }, [pdfRow]);

  return (
    <Row style={{ height: '100%' }} align='middle' gutter={10}>
      <Col style={{ height: 32 }}>
        <ScheduleOfValuesInput
          title={`Update ${title}`}
          showPopover={showPopover}
          onConfirm={onConfirm}
          onCancel={onCancel}
          onChange={onChange}
          value={value}
          type={'text'}
          placeholder={title}
          style={{ width: 300 }}
        />
      </Col>
    </Row>
  );
}

export default ScheduleOfValuesPDFPreviewFormInput;
