import React from 'react';
import { Button } from 'antd';

const getButtonStyle = (style = {}, fat = false) => ({
  borderRadius:20,
  width:120,
  height:fat ? 42 : 40,
  borderWidth:1.5,
  ...style,
});

const OnTraccrButton = ({
  id,
  onClick,
  title,
  icon,
  type = 'primary',
  disabled = false,
  style = {},
  iconLeft = false,
  href,
  titleStyle = {},
  roundStyle = false,
  fat = false,
  onMouseEnter,
  onMouseLeave,
  onFocus,
  htmlType,
  className = '',
  loading
}) => (
  <Button
    id={id}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onFocus={onFocus}
    className={`OnTraccrBtn-${type} ${className}`}
    type='primary'
    style={roundStyle ? getButtonStyle(style,fat) : {...style, }}
    onClick={onClick}
    disabled={disabled}
    href={href}
    htmlType={htmlType}
    loading={loading}
  >
    {iconLeft ? icon : ''}
    {title && <span style={titleStyle}>{title}</span>}
    {iconLeft ? '' : icon}
  </Button>
);

export default OnTraccrButton;
